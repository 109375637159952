import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {AllergyModel} from "../../models/allergy.model";

@Component({
    selector: 'app-current-allergies-list-modal',
    templateUrl: './current-allergies-list-modal.html',
  })
  export class CurrentAllergiesListModalComponent {
      allergies: AllergyModel[];

      constructor(
          private readonly bsModalRef: BsModalRef
      ) {
      }

      close() {
          this.bsModalRef.hide();
      }
  }