import angular, {IModule} from "angular";

// import './ng1.module'
// import './ng1.rootController'
// import '../api';
// import '../certificates';
// import '../components';
// import '../documents';
// import '../layout/common';
// import '../portal';
// import '../utils';
//
// // Translations. These are converted by webpack loader to ng1 "gettext" module.
// import '../po/en.po'
// import '../po/lt.po'

import {LicenseManager} from "ag-grid-enterprise";
import {setAngularJSGlobal, UpgradeModule} from "@angular/upgrade/static";
import {setUpLocationSync} from "@angular/router/upgrade";
import {ApplicationRef} from "@angular/core";
import 'ag-grid-enterprise';

LicenseManager.setLicenseKey(process.env['ESPBI_AG_GRID_LICENSE_KEY']);

export function bootstrapUpgrade(upgrade: UpgradeModule, element: Element, appRef?: ApplicationRef) : angular.IModule {
    setAngularJSGlobal(angular);

    console.debug(`Bootstrapping/Upgrading NG1 module: ${ng1App.name}`)

    upgrade.bootstrap(element, [ng1App.name], { strictDi: true });
    setUpLocationSync(upgrade, 'path');

    return ng1App
}
