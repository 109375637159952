<div class="fw-bold">
    {{'doc.pregnancyDashboard.e096Part.partogram.startDate' | translate}}: {{startTime | datetime}}
</div>

<div class="d-flex overflow-x-scroll">
    <div class="d-flex px-4 part-table flex-grow-1">
        <app-e096-part-table-latent
            class="d-block"
            [showDescription]="showDescription"
            [style.flex]="!showDescription ? latentColumns?.length : latentColumns?.length + 3"
            [mainColumns]="latentColumns"
            [initialFormValue]="latentData" />
        <app-e096-part-table-active
            class="d-block"
            [style.flex]="activeColumns?.length"
            [mainColumns]="activeColumns"
            [initialFormValue]="activeData"
            [firstActiveColumn]="firstActiveColumn"
            [lastLatentColumn]="lastLatentColumn"
        />
    </div>
</div>
