ng1App.controller('document.E063EditCtrl',
    ['$scope', '$routeParams', 'DocumentModificationActions', 'MessageService', 'MedicationsApiFactory', 'EntitiesApiFactory', 'OrdersApiFactory', '$filter',
     function ($scope, $routeParams, DocumentModificationActions, MessageService, MedicationsApiFactory, EntitiesApiFactory, OrdersApiFactory, $filter) {
    $scope.automated = $routeParams.automated && $routeParams.automated == 'true'? true : false;

    $scope.routes = EntitiesApiFactory.getList({classCode: 'immunization-route'});
    $scope.covidNpakidChanged = function(selected){
        MedicationsApiFactory.search({id: selected.medicationId, packageSearch : true}, function (meds) {
            if (meds && meds.length != 0) {
                $scope.form.immunization.medicationData = meds[0];
                $scope.form.immunization.expirationDate = new Date(selected.validTo).getTime();
                $scope.form.immunization.vaccineSerie = selected.seriesNo;
                $scope.form.immunization.vaccineDose = selected.doseSize;
                $scope.routes.$promise.then(function(r){
                    _.each(r, function (route) {
                        if (route.code == selected.routeCode) {
                            $scope.form.immunization.vaccinationMethod = route;
                        }
                    });
                });
            }
        });
    }

    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(6);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'};

    MessageService.clearAll() ;
    $scope.data = {};

    var encounterId = $routeParams.encounterId;
    var patientId = $routeParams.patientId;

    // TODO unused atm, should be name instead of vapris id if retrieval is not changed.
    $scope.loadPlannedVaccinations = function(){
        if(encounterId && patientId && $scope.form.immunization && $scope.form.immunization.vaccinationDate && $scope.form.immunization.medicationData && $scope.form.immunization.medicationData.vaprisId){
            OrdersApiFactory.getForE063({
                encounterId: encounterId,
                patientId: patientId,
                date: $scope.form.immunization.vaccinationDate//,
                ///vaprisId: $scope.form.immunization.medicationData.vaprisId
            }, function(data){
                console.log(data)
                var result = [];
                _.each(data, function (value) {
                    result.push(value.item.vacData);
                });
                $scope.form.plannedVaccinations = result;
            });
        } else {
            $scope.form.plannedVaccinations = [];
        }
    }

    $scope._shared.customValidator =  function(documentForm, skipRequired){
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        MessageService.clear('vaccine-info-region');
        MessageService.clear('vaccination-reaction-region');
        if(($scope.form.vaccinationReactionDisplay && !$scope.form.vaccinationReaction) ||
            ($scope.form.vaccinationReactionDisplay && $scope.form.vaccinationReaction && !((($scope.form.vaccinationReaction.code + ' ' +$scope.form.vaccinationReaction.name) == $scope.form.vaccinationReactionDisplay.label) ||
            ($scope.form.vaccinationReaction.code + ' ' +$scope.form.vaccinationReaction.name) == $scope.form.vaccinationReactionDisplay))){
            MessageService.add('error', 'Klaidingai įvesta reakcija į skiepą', 'vaccination-reaction-region');
            if (res) {
                _.scrollTo('[msg-region="vaccination-reaction-region"]');
            }
            res = false;
        }
        if(!$scope.form.immunization.medicationData){
            MessageService.add('error', 'Nenurodytas NPAKID.', 'vaccine-info-region');
            if (res) {
                _.scrollTo('[msg-region="vaccine-info-region"]');
            }
            res = false;
        }
        if(!$scope.form.immunization.vaccinationMethod || !$scope.form.immunization.vaccinationMethod.code){
            if (res) {
                _.scrollTo('[msg-region="vaccination-info-region"]');
            }
            res = false;
        }
        return res;
    }
}]);