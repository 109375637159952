ng1App.controller('doctor.patient.cert.e106ViewCtrl', 
    ['$scope', 'AsideBlockModel',
     function ($scope, AsideBlockModel) {
    $scope.showIncidentData = function () {
    	if ($scope.certificate.deathType) {
    		return ($scope.certificate.deathType.code == '3') 
    			|| ($scope.certificate.deathType.code == '4') 
    			|| ($scope.certificate.deathType.code == '5') 
    			|| ($scope.certificate.deathType.code == '6');
    	}
    	return false;
    };
	$scope._shared = {
		contentList: [
			new AsideBlockModel('cer.e106.mai.inf'),
            new AsideBlockModel('cer.e106.und.are', function() {
                var variable = $scope.certificate.hypotheticalAge;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106.rep', function() {
                var variable = $scope.certificate.replacedCertificate;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106.dea'),
            new AsideBlockModel('cer.e106.inf'),
            new AsideBlockModel('cer.e106.new', function() {
                var variable = $scope.certificate.pregnancyCompletion;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106.pla'),
            new AsideBlockModel('cer.e106.acc', $scope.showIncidentData),
            new AsideBlockModel('cer.e106.rea'),
            new AsideBlockModel('cer.e106.mai'),
            new AsideBlockModel('cer.e106.oth', function() {
                var variable = $scope.certificate.otherConditions;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106.ope'),
            new AsideBlockModel('cer.e106.wri')

        ]
	};
}]);