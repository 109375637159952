<nhr-record-widget closable="true" closed="false" title="{{'Recepto duomenys' | translate}}" mixed="true">
    <primary-health-care class="d-block border-bottom border-secondary-subtle"
        [practitioner]="practitioner"
        [organization]="organization">
    </primary-health-care>
    <div class="p-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Recepto numeris' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center gap-2">
                <a class="m-1" href="#" (click)="onRecipeNoClick()" (keypress)="onRecipeNoClick()">
                    {{ docId }}
                </a>
                <a href="#" class="m-1" (click)="onDownloadClick()" (keypress)="onDownloadClick()">
                    {{ 'Atsisiųsti' | translate }}
                </a>
                <span class="badge rounded-pill text-bg-primary text-white"
                      [ngClass]="getStatusPillColor()">
                    {{ getStatusTranslation(prescription?.prescriptionData?.status?.code) }}
                </span>
            </small>
        </div>
        <div class="row">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Išrašymo data' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                <strong>
                    {{ prescription?.prescriptionData?.dateWritten | date: 'yyyy-MM-dd' }}
                </strong>
            </small>
        </div>
        <div class="row">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Galioja' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                <strong>
                    {{ 'nuo' | translate }}{{ ' ' }}
                    {{ prescription?.prescriptionData?.dispenseData?.validFrom | date:'yyyy-MM-dd' }}
                    {{ ' ' }}{{ 'iki' | translate }}{{ ' ' }}
                    {{ prescription?.prescriptionData?.dispenseData?.validUntil | date:'yyyy-MM-dd' }}
                    {{ '(' }}{{ getDaysDiff() }}{{ ' d.)' }}
                </strong>
            </small>
        </div>
        <div class="row" *ngIf="prescription?.prescriptionData?.reason">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Diagnozė' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                {{ prescription?.prescriptionData?.reason?.code }} {{ prescription?.prescriptionData?.reason?.displayValue }}
            </small>
        </div>
        <div class="row">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Kompensacija' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                <strong>
                    {{
                        (prescription?.prescriptionData?.dispenseData?.compensation ?
                            prescription?.prescriptionData?.dispenseData?.compensation?.code + ' ' + prescription?.prescriptionData?.dispenseData?.compensation?.displayValue :
                            'Nėra') | translate
                    }}
                </strong>
            </small>
        </div>
        <div class="row">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Žymos' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                <div *ngIf="prescription?.prescriptionData?.dispenseData?.prescriptionTags?.gkkTag">
                    {{ 'GKK sprendimas' | translate }}
                </div>
                <div *ngIf="prescription?.prescriptionData?.dispenseData?.prescriptionTags?.longTag">
                    {{ 'Tęstinis/Ilgalaikis gydymas' | translate }}
                </div>
                <div *ngIf="prescription?.prescriptionData?.dispenseData?.prescriptionTags?.specialTag">
                    {{ 'Ypatingas paskyrimas' | translate }}
                </div>
            </small>
        </div>
        <div class="row">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Rp' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8"
                   *ngIf="prescriptionType === ErxPrescriptionTypeEnum.VA">
                <div *ngIf="medicationData?.activeIngredients">
                    {{ medicationData?.activeIngredients }}
                </div>
                <div *ngIf="medicationData?.strength">
                    {{ medicationData?.strength }}
                </div>
                <div *ngIf="medicationData?.pharmForm?.displayValue">
                    {{ medicationData?.pharmForm?.displayValue }}
                </div>
                <div *ngIf="medicationData?.productName">
                    {{ medicationData?.productName }}
                </div>
            </small>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center"
                   *ngIf="prescriptionType === ErxPrescriptionTypeEnum.EV && !isActiveSubstanceType">
                <div *ngIf="medicationData?.activeIngredients">
                    {{ medicationData?.activeIngredients }}
                </div>
                <div *ngIf="medicationData?.productName">
                    {{ medicationData?.productName }}
                </div>
                <div *ngIf="medicationData?.description">
                    {{ medicationData?.description }}
                </div>
            </small>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center"
                   *ngIf="prescriptionType === ErxPrescriptionTypeEnum.EV && isActiveSubstanceType">
                <div *ngIf="extempDrugName">
                    {{ extempDrugName }}
                </div>
                <div *ngIf="extempDrugDescription">
                    {{ extempDrugDescription }}
                </div>
            </small>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center"
                   *ngIf="prescriptionType === ErxPrescriptionTypeEnum.MPP">
                <div *ngIf="medicationData?.atc?.code">
                    {{ medicationData?.atc?.code }}
                </div>
                <div *ngIf="medicationData?.activeIngredients">
                    {{ medicationData?.activeIngredients }}
                </div>
                <div *ngIf="medicationData?.productName">
                    {{ medicationData?.productName }}
                </div>
                <div *ngIf="medicationData?.description">
                    {{ medicationData?.description }}
                </div>
            </small>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center"
                   *ngIf="prescriptionType === ErxPrescriptionTypeEnum.MPA">
                <div *ngIf="medicationData?.lenses?.opticDevice">
                    {{ medicationData?.lenses?.opticDevice }}
                </div>
                <div *ngIf="medicationData?.lenses?.type">
                    {{ medicationData?.lenses?.type }}
                </div>
                <div *ngIf="medicationData?.lenses?.note">
                    {{ medicationData?.lenses?.note }}
                </div>
            </small>
        </div>
        <div class="row"
             *ngIf="prescriptionType === ErxPrescriptionTypeEnum.VA || (prescriptionType === ErxPrescriptionTypeEnum.EV && !isActiveSubstanceType)">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Kontroliuojamas' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                {{ (medicationData?.controlledType?.displayValue ? medicationData?.controlledType?.displayValue : 'Nėra') | translate }}
            </small>
        </div>
        <div class="row" *ngIf="prescriptionType !== ErxPrescriptionTypeEnum.MPP &&
                         prescription?.prescriptionData?.dispenseData?.dispenseQuantity?.value &&
                         prescription?.prescriptionData?.dispenseData?.dispenseQuantity?.unit?.displayValue">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ prescriptionType === ErxPrescriptionTypeEnum.MPP ? 'Kiekis' : 'Dozuočių sk.' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                <strong>
                    {{ prescription?.prescriptionData?.dispenseData?.dispenseQuantity?.value }} {{ prescription?.prescriptionData?.dispenseData?.dispenseQuantity?.unit?.displayValue }}
                </strong>
            </small>
        </div>
        <div class="row"
             *ngIf="prescriptionType !== ErxPrescriptionTypeEnum.MPP && prescriptionType !== ErxPrescriptionTypeEnum.MPA">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Dozavimas' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                {{ getDosageString() }}
            </small>
        </div>
        <div class="row" *ngIf="prescription?.prescriptionData?.dispenseData?.dispenseExpectedSupplyDuration">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Gydymo trukmė' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                {{ prescription?.prescriptionData?.dispenseData?.dispenseExpectedSupplyDuration + ' d.' }}
            </small>
        </div>
        <div class="row" *ngIf="prescription?.prescriptionData?.dosageInstructionData?.additionalInstructions">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Informacija pacientui' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                {{ prescription?.prescriptionData?.dosageInstructionData?.additionalInstructions }}
            </small>
        </div>
        <div class="row" *ngIf="prescription?.prescriptionData?.dispenseData?.dispenserNote">
            <div class="col-12 col-md-5 col-lg-4">
                <small class="form-control-plaintext py-1 text-start text-md-end text-secondary">
                    {{ 'Informacija farmacininkui' | translate }}
                </small>
            </div>
            <small class="col-12 col-md-7 col-lg-8 d-flex align-items-center">
                {{ prescription?.prescriptionData?.dispenseData?.dispenserNote }}
            </small>
        </div>
    </div>
</nhr-record-widget>