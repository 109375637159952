import { Component, DestroyRef, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment/moment";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ApiV2Entities } from "../../../../../shared/services/api-v2-entities";
import {
    distinctUntilChanged,
    forkJoin,
    map,
    Observable,
    Observer,
    switchMap,
} from "rxjs";
import { EntityModel } from "../../../../../shared/models/entity.model";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import {AllergyModel} from "../../../../../shared/models/allergy.model";
import {DpSessionContext} from 'src/app/doctor/services/dp-session-context.service';
import { PractitionerModel } from 'src/app/shared/models/practitioner.model';
import { EncounterModel } from 'src/app/shared/models/encounter.model';
import { RouteParams } from 'src/app/ng1.routeParams';

@Component({
  selector: 'app-edit-allergy-modal',
  templateUrl: './edit-allergy-modal.component.html',
})
export class EditAllergyModalComponent implements OnInit {
    allergy!: AllergyModel;
    types: EntityModel[] = [];
    statuses: EntityModel[] = [];
    verificationStatuses: EntityModel[] = [];
    severities: EntityModel[] = [];
    intoleranceCriticalities: EntityModel[] = [];
    categories: EntityModel[] = [];
    formGroup!: FormGroup;
    onClose: EventEmitter<AllergyModel> = new EventEmitter<AllergyModel>();
    suggestions$: Observable<EntityModel[]>;
    selectedDiagnose: EntityModel;
    isEditInitiated: boolean = false;
    practitionerData: PractitionerModel;
    encounter: EncounterModel;

    constructor(
        readonly bsModalRef: BsModalRef,
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly destroyRef: DestroyRef,
        private sessionContext: DpSessionContext,
        private params: RouteParams
    ) {
        forkJoin([
            this.apiV2Entities.getEntitiesList('allergy-intolerance-clinical-status'),
            this.apiV2Entities.getEntitiesList('condition-verification-status'),
            this.apiV2Entities.getEntitiesList('condition-severity'),
            this.apiV2Entities.getEntitiesList('allergy-intolerance-criticality'),
            this.apiV2Entities.getEntitiesList('allergy-crivitality'),
            this.apiV2Entities.getEntitiesList('allergy-category')
        ])
            .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.statuses = res[0];
                this.verificationStatuses = res[1];
                this.severities = res[2];
                this.intoleranceCriticalities = res[3];
                this.types = res[4];
                this.categories = res[5];
            });
    }

    ngOnInit() {

        this.sessionContext.practitionerData$.subscribe(data => {
            this.practitionerData = data;
        });

        this.sessionContext.encounterData$.subscribe(data => {
            this.encounter = data;
        });

        const diagnose = this.allergy?.manifestation ? `${this.allergy?.manifestation?.code} ${this.allergy?.manifestation?.name}` : null;
        this.formGroup = this.fb.group({
            clinicalStatus: this.fb.control(this.allergy?.clinicalStatus?.id ?? null, Validators.required),
            verificationStatus: this.fb.control(this.allergy?.verificationStatus?.id ?? null, Validators.required),
            severity: this.fb.control(this.allergy?.severity?.id ?? null),
            criticality: this.fb.control(this.allergy?.criticality?.id ?? null),
            type: this.fb.control(this.allergy?.type?.id ?? null, Validators.required),
            category: this.fb.control(this.allergy?.category?.id ?? null),
            notes: this.fb.control(this.allergy?.notes ?? null, Validators.required),
            onsetDate: this.fb.control(this.allergy?.onsetDate ? moment(this.allergy?.onsetDate).toDate() : null, Validators.required),
            lastOccurrence: this.fb.control(this.allergy?.lastOccurrence ? moment(this.allergy?.lastOccurrence).toDate() : null),
            recordedDate: this.allergy.recordedDate ? this.allergy.recordedDate : moment().toDate(),
            manifestation: this.fb.control(diagnose, Validators.required),
            renewalDate: [this.isEditInitiated ? moment().toDate() : null],
        })
        this.getDiagnoses()
    }

    getDiagnoses() {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.formGroup.get('manifestation').value
                );
            }
        ).pipe(
                distinctUntilChanged(),
                switchMap((query: string | undefined) => {
                    const request = !query
                        ? this.apiV2Entities.getMostUsedDiagnoses({count: 10})
                        : this.apiV2Entities.getEntitiesList('tlk-10-am', {searchCriteria: query});

                    return request.pipe(map(items => items.map(p => {
                        p['fullDisplay'] = `${p.code} ${p.name}`;
                        return p;
                    })))
                }),
                takeUntilDestroyed(this.destroyRef)
            );
    }

    close(success?: boolean) {
        if (success) {
            if (this.formGroup.invalid) return;
            const newData = {
                ...this.formGroup.value,
                modelVersion: "v29",
                manifestation: this.selectedDiagnose || this.allergy?.manifestation,
                clinicalStatus: this.statuses.find(clinicalStatus => this.formGroup.get('clinicalStatus').value === clinicalStatus.id),
                verificationStatus: this.verificationStatuses.find(verificationStatus => this.formGroup.get('verificationStatus').value === verificationStatus.id),
                severity: this.severities.find(severity => this.formGroup.get('severity').value === severity.id),
                criticality: this.intoleranceCriticalities.find(criticality => this.formGroup.get('criticality').value === criticality.id),
                type: this.types.find(type => this.formGroup.get('type').value === type.id),
                category: this.categories.find(category => this.formGroup.get('category').value === category.id),
                subject: `Patient/${this.params.patientId}`,
                recorder: this.practitionerData.fullId,
                encounter: `Encounter/${this.params.encounterId}`,
            }
            this.onClose.emit({...this.allergy, ...newData});
        }
        this.bsModalRef.hide();
    }

    selectDiagnose(e: TypeaheadMatch) {
        this.selectedDiagnose = e.item;
    }
}
