import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, map, of, switchMap } from "rxjs";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import { FilterGroupModel } from "src/app/shared/models/filter-group.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { ApiV2Patients } from "../../../services/api-v2-patients";
import { FilteredDataModel } from "../../../models/filtered-data.model";
import { PatientSearchModel } from "../../../models/patient-search.model";
import { PatientModel } from "../../../models/patient.model";

@Component({
    selector: 'nursingServiceOrderList-filter',
    templateUrl: './nursingServiceOrderList-filter.component.html'
})
export class NursingServiceOrderListFilterComponent implements OnInit {

    @Input() selected: string;
    @Input() filtersGroup$: Observable<FilterGroupModel[]>;
    @Output() onSearch = new EventEmitter<any>();
    filters: FilterGroupModel[];
    noResult = false;

    patientSuggestions$?: Observable<PatientSearchModel[]>;
    orderPractitionerSuggestions$: Observable<any[]>;
    appointmentPractitionerSuggestions$: Observable<any[]>;

    count = 10;
    form: FormGroup;
    public readonly states = States;

    constructor(private formBuilder: FormBuilder,
        private apiV2Patients: ApiV2Patients,
        private apiV2Practitioners: ApiV2Practitioners) {
        this.form = this.formBuilder.group({
            dateFrom: "",
            dateTo: "",
            patient: "",
            status: null,
            orderPractitioner: "",
            appointmentPractitioner: "",
            myAppointments: false,
            urgent: false
        });
    }


    ngOnInit(): void {
        this.initializePatientSuggestions();
        this.initializeOrderPractitionerSuggestions();
        this.initializeAppointmentPractitionerSuggestions();
    }

    initializePatientSuggestions() {
        this.patientSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get('patient').value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Patients.search({ q: query })
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(pat => this.getPersonCredentials(pat) || [])));
                }
                return of([]);
            })
        );
    }

    initializeOrderPractitionerSuggestions() {
        this.orderPractitionerSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get('orderPractitioner').value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners.find({ q: query })
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(psm => this.getPersonCredentials(psm)) || []));
                }
                return of([]);
            })
        );
    }

    initializeAppointmentPractitionerSuggestions() {
        this.appointmentPractitionerSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get('appointmentPractitioner').value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners.find({ q: query })
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(psm => this.getPersonCredentials(psm)) || []));
                }
                return of([]);
            })
        );
    }

    getPersonCredentials(person: PatientModel | PractitionerModel) {
        return (person.namePrefix ? person.namePrefix + " " : "") + person.givenName + " " + person.familyName + " " + person.personalCode;
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    // retrieves selected practitioner object and get its id
    typeaheadOnSelectOrderPractitioner(e: TypeaheadMatch): void {
        //this.form.controls['practitioner'].setValue(e.item.id);
    }

    typeaheadOnSelectAppointmentPractitioner(e: TypeaheadMatch): void {
        //this.form.controls['practitioner'].setValue(e.item.id);
    }

    // retrieves selected patient object and get its id
    typeaheadOnSelectPatient(e: TypeaheadMatch): void {
        //this.form.controls['patient'].setValue(e.item.id);
    }

    searchAdvanced() {
        const query: Object = this.form.touched ? this.form.value : undefined;
        this.onSearch.emit(query);
    }

    clear() {
        this.form.reset();
    }
}

export enum States {
    Active = "Galiojantis",
    Fulfilled = "Atliktas",
    Cancelled = "Atšauktas"
}