import angular from "angular";
import ngSanitize from 'angular-sanitize'
import 'ng-file-upload/dist/angular-file-upload-all.min'
import 'angular-gettext'

import medvaisDoctorModule from './medvais/doctor'
import medvaisApiModule from "./api";
import medvaisUtilsModule from "./medvais-utils";
import medvaisDocumentsModule from "./medvais/documents";
import medvaisReportsModule from "./medvais/reports";
import medvaisComponentsModule from "./medvais/components";
import medvaisServicesModule from "./medvais-utils/medvais-services";

import './medvais-translations'
import jq from "jquery";

$ = jq.noConflict();

angular.module('medvais', [
    medvaisDoctorModule.name,
    medvaisApiModule.name,
    medvaisUtilsModule.name,
    medvaisDocumentsModule.name,
    medvaisReportsModule.name,
    medvaisComponentsModule.name,
    medvaisServicesModule.name,
    ngSanitize,
    'gettext',
    'angularFileUpload'
]);

angular.module('medvais').controller('medvaisCtrl', ["$scope", function ($scope){
}]);
