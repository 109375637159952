import {Component, Input} from '@angular/core';
import {SpanModel} from "../../../models/span.model";

@Component({
    selector: 'doc-ref-consent-span',
    templateUrl: './doc-ref-consent-span.component.html'
})
export class DocRefConsentSpanComponent {

    @Input() span: SpanModel

}