angular.module("erx.api").factory("ErxSettingsApiFactory", ["$resource", "erxApiConfig", function($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + "/erx/doctor/Settings";

  return $resource("", {},
    {
      getAll: {
        method: "GET",
        url: URL_BASE + "/"
      }
    }
  );
}]);
