ng1App.controller('doctor.patient.EncountersHistoryCtrl', 
    ['$scope', '$routeParams', '$location', 'EncountersApiFactory', 'FormLoaderService', 'OrganizationsApiFactory', 'EncounterViewDataHandler',
     function ($scope, $routeParams, $location, EncountersApiFactory, FormLoaderService, OrganizationsApiFactory, EncounterViewDataHandler) {
    var encounterId = $routeParams.documentId;
    var historyId = $routeParams.historyId;
    var patientId = $routeParams.patientId;

    $scope.goBack = function () {
        window.history.back();
    };

    var load = function(){
        FormLoaderService.startFormLoading();
        $scope.data = EncountersApiFactory.encounterHistoryItem({encounterId: encounterId, historyId: historyId}, function(res){
            var data = res.encounter;
            $scope.form = data;
            EncounterViewDataHandler($scope, data);
        });
        $scope.data.$promise.finally(function (){
            FormLoaderService.endFormLoading();
        });
    };

    load();
}]);