ng1App.directive('editProfession', 
    ['$modal', '$filter', 'AccountSettingsApiFactory', 'DpSessionContext',
     function ($modal, $filter, AccountSettingsApiFactory, DpSessionContext) {
    var modal: any = {};
    var createModal = function (scope) {
        return $modal({
            scope: scope,
            title: $filter('translate')('ChangeProfession'),
            contentTemplate: '/templates/portal/doctor/patient/documents/modal/edit_profession.html'
        });
    };
    return {
        scope: {qualificationList: '=editProfession', selected: '=editProfSelected'},
        link: function (scope: any, element, attrs) {
            modal = createModal(scope);
            scope.res = {selected: ''};
            element.on('click', function () {
                scope.res.selected = scope.selected.licenseCode;
                modal.$promise.then(function () {
                    modal.show();
                });
            });

            scope.hideMsgAboutQualification = true;

            DpSessionContext.getSettings(function (data) {
                scope.hideMsgAboutQualification = data.active_qualification;
            });            

            scope.submit = function () {
                scope.selected = _.find(scope.qualificationList, function (i) {
                    return i.licenseCode === scope.res.selected;
                });
                AccountSettingsApiFactory.save({}, {last_qualification: scope.selected.licenseCode}, function(){
                    DpSessionContext.getSettings().last_qualification = scope.selected.licenseCode;
                });
                modal.$promise.then(function () {
                    modal.hide();
                });
            };
        }
    };
}]);