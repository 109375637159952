<div class="mb-4 d-flex flex-column-reverse d-sm-block">
    <div class="float-sm-end">
<!--            right-side header actions-->
    </div>
    <div>
        <h1 class="mb-1">{{'pp.dia.sea.title' | translate}}</h1>
        <back-link [path]="backUrl" [subTitle]="subTitle" linkClass="text-decoration-none"/>
    </div>
</div>
<aspn-activity-home-environment-assessment-form [editable]="!viewMode" [data]="loadedData" />
