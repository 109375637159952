import {Component, OnInit, ViewChild} from "@angular/core";
import {DispenseListComponent} from "../dispenseList/dispense-list.component";

@Component({
    selector: 'dispense-history-page',
    templateUrl: './dispense-history-page.component.html'
})
export class DispenseHistoryPageComponent implements OnInit {
    @ViewChild(DispenseListComponent) dispenseListComponent: DispenseListComponent;
    lastFilters: any;

    constructor() {
    }

    ngOnInit(): void {

    }

    updateFilters(filters: any) {
        if (!filters) {
            return;
        }

        this.lastFilters = filters;
        this.dispenseListComponent.updateResults(filters);
    }

}