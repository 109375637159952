import {lastValueFrom, Observable} from "rxjs";

export class PromiseDecorator<Type> implements Promise<Type> {
    readonly [Symbol.toStringTag]: string;

    constructor(private promise: Promise<Type>) {
    }

    public get $promise() {
        return this.promise;
    }


    catch<TResult = never>(onrejected?: ((reason: any) => (PromiseLike<TResult> | TResult)) | undefined | null): Promise<Type | TResult> {
        return this.promise.catch(onrejected)
    }

    finally(onfinally?: (() => void) | undefined | null): Promise<Type> {
        return this.promise.finally(onfinally)
    }

    then<TResult1 = Type, TResult2 = never>(onfulfilled?: ((value: Type) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onrejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null): Promise<TResult1 | TResult2> {
        return this.promise.then(onfulfilled)
    }
}

export function toPromise<Type>(o: Observable<Type>, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<Type> {
    // make compatible with AngularJS $resource
    let promise = new PromiseDecorator(lastValueFrom(o))

    if (onSuccess) {
        promise.then(value => onSuccess(value))
    }
    if (onError) {
        promise.catch(reason => onError(reason))
    }
    if (onFinally) {
        promise.finally(onFinally())
    }

    return promise;
}