import { Component, OnInit } from "@angular/core";
import { Subscription, firstValueFrom, map } from "rxjs";
import { ApiV2Patients } from "src/app/shared/services/api-v2-patients";
import {
    DpAspnContextService
} from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { PatientModel } from "src/app/shared/models/patient.model";
import { ApiV2Documents } from "../../../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../../../ng1.routeParams";
import { DocumentTagEnum } from "../../../../../../shared/models/document-tag.enum";
import { NursingAndCarePlanModel } from "../../../../model/nursingAndCarePlan";


@Component({
    selector: "nursing-and-care-plan-view",
    templateUrl: "./nursing-and-care-plan-view.component.html",
})
export class NursingAndCarePlanView implements OnInit {
    documentTag: DocumentTagEnum = DocumentTagEnum.spp;
    private subscription: Subscription = new Subscription();
    nursingAndCarePlan: NursingAndCarePlanModel;
    patient: PatientModel;
    prefillData: any;

    constructor(
        private dpAspnContextService: DpAspnContextService,
        private params: RouteParams,
        private apiV2Patients: ApiV2Patients,
        private apiV2Documents: ApiV2Documents
    ) { }

    async ngOnInit(): Promise<void> {
        await firstValueFrom(this.apiV2Patients.getPatient(this.params.patientId)).then(val => {
            this.patient = val;
        });

        this.subscription.add(this.getPlanInfo());
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    private getPlanInfo(): Subscription {
        return this.dpAspnContextService
            .getNursingAndCarePlanByPatientId(this.params.patientId)
            .subscribe((data: NursingAndCarePlanModel) => {
                this.nursingAndCarePlan = data;
            });
    }

}