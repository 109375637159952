import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Auth extends ConfigService {
    path = this.apiUrl + '/pub/users';

    doIpasasAuth(data: any): Observable<any> {
        const url = `${this.path}/auth/ipasas`;
        return this.http.post<any>(url, data);
    }

    doLogout(data: any): Observable<any> {
        const url = `${this.path}/session/logout`;
        return this.http.post<any>(url, data);
    }
}
