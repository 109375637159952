ng1App.controller('doctor.patient.EncountersEditCtrl',
    ['$window', '$scope', '$routeParams', '$location', '$modal', '$upload', '$modalConfirm', '$filter', 'MessageService', 'PatientsApiFactory', 'EntitiesApiFactory', 'EncountersApiFactory', 'TypeaheadSearchHandlers', 'apiUrl', 'ClassifiersApiFactory', 'SessionContext', 'FormLoaderHelper', 'EncounterActions', 'OrganizationsApiFactory', 'PatientInsuranceService',
        function ($window, $scope, $routeParams, $location, $modal, $upload,
                  $modalConfirm, $filter, MessageService, PatientsApiFactory, EntitiesApiFactory,
                  EncountersApiFactory, TypeaheadSearchHandlers, apiUrl, ClassifiersApiFactory, SessionContext,
                  FormLoaderHelper, EncounterActions, OrganizationsApiFactory, PatientInsuranceService) {
            var formLoaderHelper = new FormLoaderHelper();
            $scope.$emit('setHelpPage', 'apsilankymo-redagavimas');

            MessageService.clearAll();
            $scope.patientEncounters = [];
            $scope.patientOrders = [];
            $scope.relatedPersons = [];
            $scope.noMoreResults = false;
            $scope.pageNumber = 1;
            $scope.showPatientUpdateLink = true;
            $scope.loading = true;

            var linkedOrder;
            var orderEventType;

            var getPatient = function () {
                return PatientsApiFactory.get({id: $routeParams.patientId}, function (data) {
                    $scope.patient = data;
                });
            };

            formLoaderHelper.addPromise(getPatient());

            $scope.searchOrganizations = TypeaheadSearchHandlers.ORGANIZATIONS;
            $scope.searchOrganizationsChange = function (v) {
                if (v && v.id) {
                    $scope.form.emsOrganizationRef = v.fullId;
                }
            };
            $scope.searchPaperOrderOrganizationsChange = function (v) {
                if (v && v.id) {
                    $scope.form.paperOrderOrganizationRef = v.fullId;
                }
            };

            $scope.searchPractitioners = function (v) {
                return TypeaheadSearchHandlers.PRACTITIONERS(v, SessionContext.organization.id);
            };
            $scope.searchPractitionersChange = function (v) {
                if (v && v.id) {
                    $scope.form.specialistRef = v.fullId;
                }
            };

            $scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;
            $scope.searchEmsDiagnosisChange = function (v) {
                if (v && v.code) {
                    $scope.form.emsCondition = v;
                }
            };
            $scope.searchPaperOrderDiagnosisChange = function (v) {
                if (v && v.code) {
                    $scope.form.paperOrderDiagnosis = v;
                }
            };

            $scope.searchCountries = TypeaheadSearchHandlers.COUNTRIES;
            $scope.searchCountriesChange = function (v) {
                if (v && v.code) {
                    $scope.form.insuranceData.country = v;
                }
            };

            // TODO: Does not make a sense to get it from event types...
            // $scope.events = formLoaderHelper.addPromise(EntitiesApiFactory.getList({classCode: 'visit-events'}));
            $scope.events = [
                {code: "ambulatory", name: "Atvykimas į SPĮ gauti ambulatorinių paslaugų"},
                {code: "inpatient", name: "Atvykimas į SPĮ gauti stacionarinių paslaugų"},
                {code: "virtual", name: "Paslaugų teikimas pacientui neatvykus į SPĮ"},
                {code: "home", name: "Paslaugos paciento namuose"},
            ]


            $scope.insuranceDocTypes = formLoaderHelper.addPromise(EntitiesApiFactory.getList({classCode: 'insurance-doc-type'}));
            $scope.serviceTypes = formLoaderHelper.addPromise(EntitiesApiFactory.getList({classCode: 'encounter-service-type'}));
            $scope.insuranceTypes = formLoaderHelper.addPromise(EntitiesApiFactory.getList({classCode: 'insurance-other-country'}));
            $scope.encounterReasons = formLoaderHelper.addPromise(EntitiesApiFactory.getList({classCode: 'encounter-reason'}));

            $scope.updateInsuranceInfo = function () {
                $scope.checkingInsurance = true;
                PatientInsuranceService.getInsurance($scope.patient.id, true)
                    .then(function (insurancePSD) {
                        $scope.form.insuranceData.insurancePSD = insurancePSD;
                    })
                    .finally(function () {
                        $scope.checkingInsurance = false;
                    });
            };

            $scope.loadEncounters = function () {
                $scope._encounters_loading = true;
                EncountersApiFactory.getEncountersHistory({patient: $routeParams.patientId}, function (encounters) {
                    $scope._encounters_loading = false;
                    $scope.patientEncounters = encounters;
                }, function () {
                    $scope._encounters_loading = false
                });
            };

            $scope.loadEncounters();

            $scope.loadOrders = function (loadLinked) {
                $scope._orders_loading = true;
                var isVirtual = $scope.form.event.code == 'virtual';
                EncountersApiFactory.getUnusedPatientOrders({
                    patient: $routeParams.patientId,
                    page: $scope.pageNumber++,
                    isVirtual: isVirtual
                }, function (orders) {
                    $scope._orders_loading = false;
                    for (var i = 0; i < orders.items.length; i++) {
                        var o = orders.items[i];
                        if (!$scope.form.order || !$scope.form.order.id || $scope.form.order.id != o.item.id) {
                            $scope.patientOrders.push(o);
                        }
                    }
                    if (orders.total <= $scope.patientOrders.length) {
                        $scope.noMoreResults = true;
                    }
                    if (loadLinked) {
                        var linkedOrderMissing = true;
                        for (var i = 0; i < $scope.patientOrders.length; i++) {
                            if ($scope.form.order && $scope.patientOrders[i].item.id == $scope.form.order.id) {
                                linkedOrderMissing = false;
                            }
                        }
                        if (linkedOrderMissing && linkedOrder && orderEventType == $scope.form.event.code) {
                            EncountersApiFactory.getOrderForEdit({
                                "id": linkedOrder,
                                isVirtual: isVirtual
                            }, function (data) {
                                $scope.patientOrders.unshift(data);
                            });
                        }
                    }
                }, function () {
                    $scope._orders_loading = false
                });
            };

            $scope.loadMoreOrders = function () {
                $scope.loadOrders();
            };

            $scope.reloadOrders = function () {
                if ($scope.form.isByOrder) {
                    $scope.form.paperOrder = false;
                    $scope.form.orderRef = null;
                    $scope.form.order = null;
                    $scope.pageNumber = 1;
                    $scope.patientOrders = [];
                    $scope.noMoreResults = false;
                    $scope.loadOrders(true);
                }
                if ($scope.form.reason) {
                    $scope.form.reason.code = undefined;
                }
            };

            $scope.changeByOrderFlag = function () {
                if ($scope.form.isByOrder) {
                    $scope.form.isByOrder = false;
                } else {
                    $scope.form.isByOrder = true;
                    $scope.reloadOrders();
                }
            };

            $scope.selectOrder = function (id) {
                $scope.form.orderRef = id;
                console.debug(id);
            };

            $scope.selectServiceType = function (item) {
                $scope.form.serviceType = item;
            };

            $scope.refresh = function () {
                $scope.loading = true;
                MessageService.clearAll();
                //_.markAllItems(false, $scope.reasons, false);
                $scope.agreementFiles = {};
                EncountersApiFactory.getForEdit({"id": $routeParams.documentId}, function (data) {
                    $scope.form = data;
                    $scope.organization = data.organizationData;
                    $scope.practitioner = data.practitionerData;
                    $scope.patient = data.patientData;
                    if (data.insuranceData.country) {
                        data.insuranceData.countryDisplay = data.insuranceData.country.name;
                    }
                    if (data.insuranceData.document) {
                        $scope.insuranceDocTypes.$promise.then(function (items) {
                            data.insuranceData.document = _.find(items, function (i) {
                                return i.name == data.insuranceData.document.name;
                            });
                        });
                    }
                    if (data.emsOrganization) {
                        data.emsOrganizationRefDisplay = data.emsOrganization.name;
                    }
                    if (data.emsCondition) {
                        data.emsConditionCodeDisplay = TypeaheadSearchHandlers.entities_label(data.emsCondition);
                    }
                    if (data.specialist) {
                        data.specialistRefDisplay = $filter('capitalize')(_.getFullName(data.specialist));
                    }
                    if (data.paperOrderOrganizationRef) {
                        OrganizationsApiFactory.getById({organizationId: _.getIdFromReference(data.paperOrderOrganizationRef)}, function (org) {
                            data.paperOrderOrganizationDisplay = org ? org.name : '';
                        });
                    }
                    if (data.paperOrderDiagnosis) {
                        data.paperOrderDiagnosisDisplay = TypeaheadSearchHandlers.entities_label(data.paperOrderDiagnosis);
                    }

                    if ($scope.form.isByOrder) {
                        linkedOrder = data.order.id;
                        orderEventType = data.event.code;
                        $scope.loadOrders(!data.paperOrder);
                    }
                    $scope.loading = false;
                });
            };
            EncountersApiFactory.eventRestricted({"id": $routeParams.documentId}, function (data) {
                $scope.eventRestricted = data.hasDocuments;
            });
            PatientsApiFactory.getWithRelatedPersons({id: $routeParams.patientId}, function (data) {
                $scope.relatedPersons = [];
                _.each(data.relatedPersons, function (value) {
                    if (!value.deathDate) {
                        $scope.relatedPersons.push(value);
                    }
                });
            });
            $scope.refresh();

            $scope.save = function () {
                console.debug('form:', $scope.form);
                EncounterActions.validateForm($scope.filterForm, $scope.form, 'edit', $scope.agreementIsMandatory, null, function () {
                    $scope.formValidated = true;
                    $scope._save_loading = true;
                    $scope.form.patientData = $scope.patient;
                    $scope.form.patientRef = $scope.patient.fullId;
                    $scope.form.practitionerData = $scope.practitioner;
                    $scope.form.organizationData = $scope.organization;
                    var files = [];
                    var fileKeys = [];
                    angular.forEach($scope.agreementFiles, function (val, key) {
                        if (val) {
                            files.push(val);
                            fileKeys.push('file_' + key);
                        }
                    });
                    if (!$scope.form.insuranceData.insuranceOtherCountry || $scope.form.insuranceData.insuranceOtherCountry.code != '1') {
                        $scope.form.insuranceData.country = null;
                    }
                    $scope.upload = $upload.upload({
                        url: apiUrl + '/encounters',
                        method: 'POST',
                        data: {form: $scope.form},
                        file: files,
                        fileFormDataName: fileKeys
                    }).progress(function (evt) {
                        ///console.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total));
                    }).success(function (resp) {
                        $scope._save_loading = false;
                        MessageService.registerSuccessEvent();
                        $location.path('dp/patients/' + $routeParams.patientId);
                    }).error(function (resp) {
                        console.debug('percent: ' + resp);
                        $scope._save_loading = false;
                        angular.forEach(resp.fieldErrors, function (item) {
                            if ('esiNumber' == item.propertyPath) {
                                MessageService.add('error', item.msg, 'arrival-region');
                            } else {
                                MessageService.add('error', item.msg, 'info-region');
                            }
                        });
                        angular.forEach(resp.globalErrors, function (item) {
                            MessageService.error(item.msg);
                        });
                        _.scrollTo('#skip-link');
                    });
                });
            };

// modals
            var modal = $modal({
                scope: $scope,
                contentTemplate: '/templates/portal/doctor/patient/encounters/patient_info_modal.html'
            });
            $scope.enterPatientInfo = function () {
                $scope.cancelErr = 0;
                modal.$promise.then(function () {
                    $scope.patientInfo = angular.copy($scope.patient);
                    modal.show();
                });
            };


            $scope.searchCountry = TypeaheadSearchHandlers.COUNTRIES;
            $scope.searchResidenceCountryChange = function (c) {
                if (c && c.code) {
                    $scope.patientInfo.foreignAddress.country = c;
                } else if (!c) {
                    $scope.patientInfo.foreignAddress.country = undefined;
                }
            };
            $scope.updatePatientInfo = function () {
                PatientsApiFactory.updateContacts($scope.patientInfo, function () {
                    MessageService.success("Paciento duomenys sėkmingai atnaujinti");
                    getPatient();
                    modal.hide();
                }, function (error) {
                    angular.forEach(error.data.fieldErrors, function (e) {
                        MessageService.error(e.propertyPath + ' ' + e.msg);
                    });
                });
            };

            var EMImodal = $modal({
                scope: $scope,
                contentTemplate: '/templates/portal/doctor/patient/encounters/EMI_change_modal.html'
            });
            $scope.changeEMI = function () {
                $scope.cancelErr = 0;
                EMImodal.$promise.then(function () {
                    $scope.EMImodal = {};
                    $scope.EMImodal.esiNumber = $scope.form.esiNumber;
                    EMImodal.show();
                });
                $scope.EMImodalSave = function () {
                    $scope.form.esiNumber = $scope.EMImodal.esiNumber;
                    EMImodal.hide();
                }
            };

            $scope.getPatientAge = function () {
                if ($scope.patient) {
                    return _.age($scope.patient.birthDate, $scope.patient.deathDate);
                }
                return '';
            };


            ClassifiersApiFactory.getAgreementsTypesForEncounter({}, function (data) {
                var hideAgreements = false;
                var agreementIsMandatory = false;
                angular.forEach(data.items, function (value) {
                    hideAgreements = _.find(value.relations, function (item) {
                        return item.docId = 21 && item.relation == 0;
                    });
                    agreementIsMandatory = _.find(value.relations, function (item) {
                        return item.docId = 21 && item.relation == 2;
                    });
                });
                $scope.hideAgreements = hideAgreements;
                $scope.agreementIsMandatory = agreementIsMandatory;
            });

            formLoaderHelper.finally();

            $scope.goBack = function () {
                $window.history.back();
            };
        }]);