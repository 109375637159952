<button
        class="btn {{btnClass}} {{toggle && pressedState ? toggleClass : ''}}"
        (click)="buttonCLick()"
>
    <ng-container *ngIf="!toggle || (toggle && !pressedState); else pressedText">
        <ng-content></ng-content>
    </ng-container>
    <ng-template #pressedText>
        {{toggleText}}
    </ng-template>
    <i *ngIf="(!toggle && icon) || (toggle && !pressedState && icon)" [class]="icon" class="ms-2"></i>
    <i *ngIf="toggle && pressedState && toggleIcon" [class]="toggleIcon" class="ms-2"></i>
</button>
