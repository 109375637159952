import {ErxPrescriptionTypeEnum} from "../../../../../../shared/models/erx-prescription-type.enum";

angular.module('erx').directive('erxViewDispenseRo', function () {
    var viewDispenseCtrl = function ($scope, $routeParams, ErxDispenseApiFactory, erxApiConfig, EntitiesApiFactory, DosageSummaryService) {
    var signedPdfUrl = "";

    $scope.dispenseId = $routeParams.id;

    $scope.dispense = ErxDispenseApiFactory.getById({id: $scope.dispenseId, includeRelated: true}, function (res) {
      $scope.data = res.dispenseData;
        $scope.prescription = res.prescription;
        $scope.prescription.docNumber = $scope.data.prescription.docNumber;
        $scope.prescription.metadata = res.metadata;
        const ptCode = res.prescription.prescriptionType.code;
        $scope.prescriptionType = ErxPrescriptionTypeEnum[ptCode ? String(ptCode).toUpperCase() : undefined];
        $scope.prescriptionId = res.dispenseData.dispenserRef?.split('/')[1];
      $scope.practitionerRef = res.dispenseData.dispenserRef;
      $scope.patient = res.patient;
      $scope.practitioner = res.practitioner;
      $scope.organization = res.organization;
      $scope.pickedUpBy = res.pickedUpBy;
      $scope.practitionerQualification = res.practitionerQualification;
    });

        $scope.dispense.$promise.then(d => {
            loadClassifiers();
        });


    $scope.canGetSignedPdf = function () {
      return $scope.dispense.metadata
              && $scope.dispense.metadata.docStatus === 'Signed';
    }

    $scope.getSignedPdf = function () {
      if (!signedPdfUrl) {
        signedPdfUrl = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Document/' + $scope.dispense.metadata.docId + '/signedPdf';
      }
      return signedPdfUrl;
    }

        var loadClassifiers = function () {
            $scope.intakeTimes = [
                {code: 'beforeMeal', displayValue: 'Prieš valgį'},
                {code: 'duringMeal', displayValue: 'Valgio metu'},
                {code: 'afterMeal', displayValue: 'Po valgio'},
                {code: 'independMeal', displayValue: 'Nepriklausomai nuo valgymo'},
                {code: 'beforeSleep', displayValue: 'Prieš miegą'}
            ];

            $scope.frequencyMeasurements = EntitiesApiFactory.getList({classCode: 'units-of-time'}, {})

            $scope.dosageSummary = DosageSummaryService.buildDosageSummary(
                $scope.dosageSummary,
                $scope.data.dispense.dosage,
                $scope.frequencyMeasurements,
                $scope.intakeTimes
            );
        };

  }
    viewDispenseCtrl.$inject = ["$scope", "$routeParams", "ErxDispenseApiFactory", "erxApiConfig", "EntitiesApiFactory", "DosageSummaryService"];
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/erx/patient-history/view-dispense/viewDispense.html',
    controller: viewDispenseCtrl
  };
});
