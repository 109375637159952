import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2AccountSettings extends ConfigService {
    path = this.apiUrl + '/account-settings';

    get(): Observable<any> {
        return this.http.get<any>(this.path);
    }

    save(settings: any): Observable<any> {
        return this.http.post<any>(this.path, settings);
    }
}
