import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
})
export class ButtonComponent {
    @Input() icon: string;
    @Input() btnClass: string;
    @Input() toggle: boolean;
    @Input() toggleText: string;
    @Input() toggleClass: string;
    @Input() toggleIcon: string;
    @Output() btnClick: EventEmitter<void | boolean> = new EventEmitter<void | boolean>()

    pressedState: boolean;

    buttonCLick() {
        this.pressedState = this.toggle && !this.pressedState;
        this.btnClick.emit(this.toggle ? this.pressedState : null);
    }
}
