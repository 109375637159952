import { ApiV2EncountersPromiseDecorator } from "../../../../app/shared/services/api-v2-encounters-promise-decorator.service";

ng1App.controller("doctor.patient.RecordsCtrl", [
    "$scope",
    "$rootScope",
    "$location",
    "$window",
    "$modalConfirm",
    "$routeParams",
    "$filter",
    "DpPatientContext",
    "PatientsApiFactory",
    "DocumentsApiFactory",
    "EntitiesApiFactory",
    "EncounterService",
    "MessageService",
    "EncountersApiFactory",
    "SessionContext",
    "SearchController",
    "IntegratedComponentContext",
    "DpSessionContext",
    "DpSessionContext2",
    function (
        $scope,
        $rootScope,
        $location,
        $window,
        $modalConfirm,
        $routeParams,
        $filter,
        DpPatientContext,
        PatientsApiFactory,
        DocumentsApiFactory,
        EntitiesApiFactory,
        EncounterService,
        MessageService,
        EncountersApiFactory: ApiV2EncountersPromiseDecorator,
        SessionContext,
        SearchController,
        IntegratedComponentContext,
        DpSessionContext,
        DpSessionContext2
    ) {
        $scope.$emit("setHelpPage", "perzireti-paciento-sveikatos-istorija");
        $scope.dashboardUrl = "/dp/patients/" + $routeParams.patientId;
        var docTypes = [
            "18842-5",
            "57133-1",
            "11488-4",
            "34108-1",
            "X-LAB-ORDER",
            "11502-2",
            "X-PATH-ORDER",
            "11526-1",
            "11369-6",
            "444561001",
            "270101008",
            "307930005",
            "274410002",
            "275674000",
            "171363009",
            "184822001",
            "270370004",
        ];
        $scope.canCreateDoc = $filter("acWrite")("Composition", docTypes);
        $scope.sameOrg = function (encounter) {
            var orgId = encounter.organizationRef.split("/")[1];
            var practOrgId = $rootScope.currentUser.organizationReference.split("/")[1];

            return orgId === practOrgId;
        };
        var matchedDocs = [];

        MessageService.clearAll();
        MessageService.showSuccessEventMessage();
        MessageService.unregisterSuccessEvent();

        $scope._filterDefs = [
            { title: "dp.use.tem.rec.all", query: { type: "all" } },
            { title: "dp.use.tem.rec.amb", query: { type: "e025" } },
            { title: "dp.use.tem.rec.inp", query: { type: "e003" } },
            { title: "dp.use.tem.rec.sen", query: { type: "e027" } },
            { title: "dp.use.tem.rec.ext", query: { type: "e027p" } },
            { title: "dp.use.tem.rec.sena", query: { type: "e027a" } },
            { title: "dp.use.tem.rec.lab", query: { type: "e200" } },
            { title: "dp.use.tem.rec.labr", query: { type: "e200a" } },
            { title: "dp.use.tem.rec.pat", query: { type: "e014" } },
            { title: "dp.use.tem.rec.pata", query: { type: "e014a" } },
            { title: "dp.use.tem.rec.vac", query: { type: "e063" } },
        ];

        $scope.searchCtrl = new SearchController(
            "dp_patient_history_list",
            "/templates/portal/doctor/patient/records/filter.html",
            10,
            function (scope) {
                scope.docStatuses = [
                    { code: "IN_PROGRESS", name: "doc.in_progress" },
                    { code: "COMPLETED", name: "doc.completed" },
                    { code: "CANCELED_SIGNED", name: "doc.entered_in_error" },
                    { code: "CANCELED_UNSIGNED", name: "doc.entered_in_error_unsigned" },
                    { code: "INACTIVE", name: "doc.inactive" },
                    { code: "AMENDED_SIGNED", name: "doc.amended" },
                    { code: "AMENDED_UNSIGNED", name: "doc.amended_unsigned" },
                    { code: "PRELIMINARY", name: "doc.preliminary" },
                    { code: "FINAL_UNSIGNED", name: "doc.final" },
                    { code: "FINAL_SIGNED", name: "doc.signed" },
                ];
                EntitiesApiFactory.getList({ classCode: "doctypes" }, function (doctypes) {
                    scope.docTypes = _.without(doctypes, _.findWhere(doctypes, { code: "patient_summary" }));
                });
                EntitiesApiFactory.getList({ classCode: "encounter-status" }, function (result) {
                    angular.forEach(result, function (status) {
                        switch (status.code) {
                            case "in progress":
                                status.code = "inProgress";
                                status.name = "Aktyvūs";
                                break;
                            case "finished":
                                status.name = "Baigti";
                                break;
                            case "cancelled":
                                status.name = "Atšaukti";
                                break;
                        }
                    });
                    scope.encounterStatuses = result;
                });

                EntitiesApiFactory.getList({ classCode: "visit-events" }, function (result) {
                    scope.eventTypes = result;
                });

                EntitiesApiFactory.getList({ classCode: "insurance-other-country" }, function (result) {
                    scope.insuranceOtherCountry = result;
                });

                scope.checkAll = function (fromList, toList, property) {
                    angular.forEach(fromList, function (item) {
                        toList[item[property]] = true;
                    });
                };

                scope.uncheckAll = function (list) {
                    angular.forEach(list, function (val, key) {
                        list[key] = false;
                    });
                };
            },
            function () {
                var date = new Date();
                var firstDay = new Date(
                    date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear(),
                    date.getMonth() === 0 ? 11 : date.getMonth() - 1,
                    date.getDate()
                );
                return {
                    filter: $scope._filterDefs[0],
                    sdate: $filter("date")(firstDay, "yyyy-MM-dd"),
                    searchBy: "enc",
                    docStatuses: {},
                    docTypes: {},
                    encounterStatuses: {},
                    insuranceOtherCountry: {},
                    isEms: null,
                    eventTypes: {},
                    practitioners: [],
                    organizations: [],
                    orderBy: "byDate",
                };
            },
            null,
            true
        );

        $scope.searchCtrl.getSearchFunc = function (form) {
            switch (form._searchType) {
                case "quick":
                    return EncountersApiFactory.getPatientEncountersByFTQuery.bind(EncountersApiFactory);
                case "filter":
                    if (form.filter.query.type !== "all") {
                        return EncountersApiFactory.getByDocType.bind(EncountersApiFactory);
                    }
                default:
                    if (form.searchBy === "enc") {
                        return EncountersApiFactory.getPatientEncounters.bind(EncountersApiFactory);
                    } else {
                        return EncountersApiFactory.getByDocData.bind(EncountersApiFactory);
                    }
            }
        };

        if (
            $routeParams.filterType &&
            $scope._filterDefs.find((obj) => {
                return obj.title === $routeParams.filterType;
            })
        ) {
            $scope.searchCtrl._form._searchType = "filter";
            $scope.searchCtrl._form.filter = $scope._filterDefs.find((obj) => {
                return obj.title === $routeParams.filterType;
            });
        } else {
            $scope.searchCtrl._form._searchType = "all";
        }

        $scope.searchCtrl.handleResult = function (result) {
            SearchController.prototype.handleResult.call(this, result);
            matchedDocs = result.metaData && result.metaData.matchedDocs ? result.metaData.matchedDocs : [];
        };

        $scope.searchCtrl.setPagingInfo = function (query) {
            query.page = this.getPage();
            query.pageNumber = this.getPage();
            query.count = this.getCount();
        };

        $scope.searchCtrl.formToQuery = function (form) {
            var filterKeys = function (obj) {
                var list = [];
                angular.forEach(obj, function (val, key) {
                    if (val === true) {
                        list.push(key);
                    }
                });
                return list;
            };
            var query: any = {
                patient: DpPatientContext.id,
                searchDocsCount: "true",
                sortBy: form.orderBy,
            };
            switch (form._searchType) {
                case "quick":
                    query.q = form.ftq;
                    query.filter = $scope._filterDefs[0];
                    break;
                case "filter":
                    if (form.filter.query.type !== "all") {
                        query.docType = form.filter.query.type;
                        query.messages = "false";
                        if (query.docType === "e027p") {
                            query.docType = "e027";
                            query.messages = "true";
                        }
                    }
                    break;
                case "advanced":
                    query.filter = $scope._filterDefs[0];
                    form.ftq = undefined;
                    if (form.searchBy === "enc") {
                        query.sdate = _.addDayStartTime(form.sdate);
                        query.edate = _.addDayEndTime(form.edate);
                        query.specialistId = _.map(form.practitioners, function (i) {
                            return i.id;
                        });
                        query.organizationId = _.map(form.organizations, function (i) {
                            return i.id;
                        });
                        query.status = filterKeys(form.encounterStatuses);
                        query.eventCode = filterKeys(form.eventTypes);
                        query.insurance = filterKeys(form.insuranceOtherCountry);
                        query.isInsuredPSD = form.isInsured ? true : undefined;
                        query.isByOrder = form.isByOrder ? true : undefined;
                        query.isEms = form.isEms ? true : undefined;
                    } else {
                        query.comment = form.isComment ? form.comment : undefined;
                        query.hasAgreement = form.isAgreement ? true : undefined;
                        query.hasAttachment = form.isFile ? true : undefined;
                        query.status = filterKeys(form.docStatuses);
                        query.docType = filterKeys(form.docTypes);
                        query.diagnosis = _.map(form.diagnosis, function (i) {
                            return i.id;
                        });
                        query.allergies = _.map(form.allergies, function (i) {
                            return i.id;
                        });
                    }
                    break;
            }

            for (const key in query) {
                if (
                    query.hasOwnProperty(key) &&
                    (query[key] === null || query[key] === undefined || query[key] === "" || query[key]?.length === 0)
                ) {
                    delete query[key];
                }
            }

            return query;
        };

        $scope.changeFilter = function (filter) {
            $scope.searchCtrl.clear();
            $scope.searchCtrl._form.filter = filter;
            $scope.searchCtrl.searchFilter();
        };

        $scope.$on("changeFilter", function (event, filterType) {
            $scope.changeFilter(
                $scope._filterDefs.find((obj) => {
                    return obj.title === filterType;
                })
            );
        });

        _.each($scope._filterDefs, function (f) {
            var docTypeParam = f.query.type;
            var messages = null;
            if (f.query.type === "e027") {
                messages = "false";
            }
            if (f.query.type === "e027p") {
                docTypeParam = "e027";
                messages = "true";
            }
            if (f.query.type === "all") {
                docTypeParam = [
                    "e025",
                    "e003",
                    "e027",
                    /*'e027p', */ "e027a",
                    "e200",
                    "e200a",
                    "e014",
                    "e014a",
                    "e063",
                ];
                messages = "true";
            }
            DocumentsApiFactory.getDocumentCount(
                {
                    patient: DpPatientContext.id,
                    docType: docTypeParam,
                    messages: messages,
                },
                function (res) {
                    f.count = res.value;
                }
            );
        });

        $scope.matchesSearch = function (document) {
            return matchedDocs && matchedDocs.indexOf(document.id) > -1;
        };

        var close = function (id) {
            EncounterService.closeEncounter(
                id,
                function () {
                    MessageService.registerEncounterCloseSuccessEvent();
                    $location.path("dp/patients/" + $routeParams.patientId);
                    DpSessionContext2.getLatestEncounter();
                },
                function () {
                    $window.scrollTo(0, 10);
                }
            );
        };

        $scope.closeEncounter = function (item) {
            var message = "Ar tikrai norite užregistruoti paciento atvykimo pabaigos faktą.";
            if ("Organization/" + item.patientData.providerOrganization !== item.practitionerData.organizationRef) {
                message =
                    "ESPBI IS užregistravus paciento atvykimo pabaigos faktą, ESPBI IS automatiškai panaikina prieigos prie paciento ESI teises.";
            }
            $modalConfirm.show({ title: "Atvykimo uždarymas", content: message }).then(function () {
                close(item.id);
                // TODO: Not sure what it is...
                // alert.hide();
            });
        };

        $scope.findEncounterDocuments = function (encounterIndex) {
            var encounter = $scope.searchCtrl._list[encounterIndex];
            if (!encounter._docs_loaded) {
                // load only if needed..
                encounter.documentList = DocumentsApiFactory.searchForList(
                    {
                        encounterId: encounter.id,
                        count: -1,
                        withDrafts: true,
                    },
                    function () {
                        encounter._docs_loaded = true;
                    }
                );
            }
        };

        $scope.findEncounterAllergiesAndDiagnosis = function (encounterIndex) {
            var encounter = $scope.searchCtrl._list[encounterIndex];
            if (!encounter._all_loaded || !encounter._dia_loaded) {
                // load only if needed..
                PatientsApiFactory.getAllergiesForListByEncounter(
                    {
                        id: $routeParams.patientId,
                        practitionerId: SessionContext.practitioner.id,
                        encounterId: encounter.id,
                        count: 100,
                    },
                    function (allergies) {
                        encounter.allergies = allergies;
                        encounter._all_loaded = true;
                    }
                );
                PatientsApiFactory.getDiagnosisForListByEncounter(
                    {
                        id: $routeParams.patientId,
                        practitionerId: SessionContext.practitioner.id,
                        encounterId: encounter.id,
                    },
                    function (diagnosis) {
                        encounter.diagnosis = diagnosis;
                        encounter._dia_loaded = true;
                    }
                );
            }
        };

        $scope.openRelatedDocument = function (documentId) {
            var url = "/dp/patients/" + $routeParams.patientId + "/documents/" + documentId;
            $window.location.href = url;
        };

        $scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();
        if ($scope.isIntegratedComponent) {
            if (!!$routeParams["hideDocCancel"]) {
                IntegratedComponentContext.setHideDocCancelAction();
            }
        }

        DpSessionContext.getSettings(function (data) {
            $scope.searchCtrl.setCount(data.count_per_page || 10);
            $scope.searchCtrl.searchLastOrAll();
        });

        $scope.updatePagination = function (pagination) {
            if (pagination?.page) {
                $scope.searchCtrl.setPage(pagination.page);
                $scope.searchCtrl.handleSearch(false);
            }
        };
    },
]);
