angular.module('medvais.documents').directive('medvaisViewDoc027va', function () {

    return {
        restrict: 'E',
        scope: {options: '='},
        templateUrl: 'modules/medvais/documents/view-027va.html',
        controller: 'medvaisViewDoc027vaCtrl'
    };

});


angular.module('medvais.documents').controller('medvaisViewDoc027vaCtrl', ["$scope", "$rootScope", "$routeParams", "$location", "$q", "$anchorScroll", "$window", "$filter", "$log", "MessageService", "MedvaisDocumentApiFactory", "MedvaisImagingStudyApiFactory", "MedvaisPractitionerApiFactory", "MedvaisPatientApiFactory", "MedvaisOrganizationApiFactory", "MedvaisEncounterApiFactory", "MedvaisMedvaisApiFactory", "MedvaisOrderApiFactory", "MedvaisDocStateService", "FormLoaderService", "MedvaisUtils", "$medvaisCancelDocMdl", function (
        $scope,
        $rootScope,
        $routeParams,
        $location,
        $q,
        $anchorScroll,
        $window,
        $filter,
        $log,
        MessageService,
        MedvaisDocumentApiFactory,
        MedvaisImagingStudyApiFactory,
        MedvaisPractitionerApiFactory,
        MedvaisPatientApiFactory,
        MedvaisOrganizationApiFactory,
        MedvaisEncounterApiFactory,
        MedvaisMedvaisApiFactory,
        MedvaisOrderApiFactory,
        MedvaisDocStateService,
        FormLoaderService,
        MedvaisUtils,
        $medvaisCancelDocMdl) {

    MessageService.clearAll();
    $anchorScroll();

    var practitionerId;
    var practitionerRef;
    var organizationId;
    var organizationRef;
    var organizationJAR;
    var token;
    var clientIP;
    $scope.documentId = $routeParams.documentId;
    $scope.documentData = {};
    $scope.meddreamUrl = '';
    $scope.pdfDownload = '/medvais-api/document/' + $routeParams.documentId + '/signedPdf';
    $scope.docMainInfo = {patient: null, practitioner: null, organization: null, practitionerQualification: null};
    $scope.telemedicineInfo = {};
    $scope.docMenu = {};


    $q.all([$scope.options.getPractitioner(), $scope.options.getOrganization(), $scope.options.getUserInfo()]).then(function(result){
        var pract = result[0];
        var org = result[1];
        var user = result[2];

        if (user) {
            token = user.token;
            clientIP = user.clientIP;
        }
        if (pract) {
            practitionerId = pract.id;
            practitionerRef = pract.fullId;
        }
        if (org) {
            organizationJAR = org.jarCode;
            organizationRef = org.fullId;
            organizationId = org.id;
        }

        console.log("View form. token: " + token);
        console.log("View form. Client IP: " + clientIP);
        console.log("View form. practitionerId: " + practitionerId);
        console.log("View form. practitioner ref: " + practitionerRef);
        console.log("View form. organizationId: " + organizationId);

        initData();
    });

    MedvaisMedvaisApiFactory.getMeddreamUrl().$promise.then(
        function (response) {
            $scope.meddreamUrl = response.url;
        }
    );


    $scope.getEncounterList = function(patientId) {
        MedvaisEncounterApiFactory.getByPatient({patientId: patientId}).$promise.then(
            function (encounters) {
                $scope.encountersList = encounters;
            }
        );
    };

    $scope.getOrderList = function(patientId, organizationId) {
        MedvaisOrderApiFactory.getForE027VA({patientId: patientId, organizationId: organizationId}).$promise.then(
            function (orders) {
                $scope.ordersList = orders;
            }
        );
    };

    var initData = function() {
        FormLoaderService.startFormLoading();
        console.log("Document id: " + $scope.documentId);

        loadDocumentData()
            .then(loadImagingStudyData)
            .then(loadRelatedImagingStudiesData)
            .then(
                function() {
                    $scope.docMainInfo.practitioner = $scope.documentData.metadata.practitioner;
                    $scope.docMainInfo.patient = $scope.documentData.metadata.patient;
                    $scope.docMainInfo.organization = $scope.documentData.metadata.custodian;
                    $scope.docMainInfo.practitionerQualification = $scope.documentData.practitionerQualification;
                    initMenu();
                    $log.debug('027va view form initialization completed.');
                }
            )
            .catch(
                function() {

                }
            )
            .finally(function () {
                $log.debug('Finally part of 027-va view data initialization executed.');
                FormLoaderService.endFormLoading();
            });
    };


    var loadDocumentData = function () {
        return MedvaisDocumentApiFactory.get027vaDocPreviewData({id: $scope.documentId}).$promise.then(
            function(doc) {
                $scope.documentData.conclusion = doc.conclusion;
                $scope.documentData.imagingStudyDescription = doc.imagingStudyDescription;
                $scope.documentData.radiation = doc.radiation;
                $scope.documentData.practitioner = doc.metadata.practitioner;
                $scope.documentData.mainDiagnosis = doc.mainDiagnosis;
                $scope.documentData.relatedDiagnoses = doc.relatedDiagnoses;
                $scope.documentData.achiCode = doc.achiCode;
                $scope.documentData.encounterRef = doc.encounterRef;
                $scope.documentData.relatedImagingStudies = [];
                $scope.documentData.order = doc.order;
                $scope.documentData.encounter = doc.encounter;
                $scope.documentData.mediaList = doc.mediaList;
                $scope.documentData.metadata = doc.metadata;
                $scope.documentData.telemedicineInfo = doc.telemedicineInfo;
                $scope.documentData.practitionerQualification = doc.practitionerQualification;

                $log.debug("Signed: " + $scope.documentData.metadata.signed);
                $log.debug('Document data loaded.');
                return doc;
            }
//            function(err) {
//                $log.error('Document data load error.', err);
//            }
        );
    };


    var loadImagingStudyData = function(doc) {
        return MedvaisImagingStudyApiFactory.getImagingStudyById({id: doc.imagingStudy.id}).$promise.then(
            function (imagingStudy) {
                $scope.documentData.imagingStudy = imagingStudy;
                $scope.documentData.patientId = $scope.documentData.imagingStudy.patient.id;
                $scope.getEncounterList($scope.documentData.patientId);
                $scope.getOrderList($scope.documentData.patientId, $scope.documentData.metadata.custodian.id);
                $log.debug('Imaging study data loaded.');
                return doc;
            }
        );
    };


    var loadRelatedImagingStudiesData = function(doc) {
        for (var i = 0; i < doc.relatedImagingStudies.length; i++) {
            MedvaisImagingStudyApiFactory.getImagingStudyById({id: doc.relatedImagingStudies[i].id}).$promise.then(
                function(imgStudy){
                    $scope.documentData.relatedImagingStudies.push(imgStudy);
                }
            );
        }
        $log.debug('Related imaging study data loaded.');
    };


    var initMenu = function() {
        $scope.docMenu = {
            main: [
                {
                    type: 'main',
                    title: 'e027va.view.download',
                    // isDisabled: function () {
                    //     return $scope.docSpinnerHelper.loading;
                    // },
                    isVisible: function () {
                        return $scope.documentData.metadata.signed;

                    },
                    url: $scope.pdfDownload
                }
            ],
            dropDown: [
                {
                    type: 'ctx',
                    title: 'e027va.view.download',
                    isVisible: function () {
                        return $scope.documentData.metadata.signed;
                    },
                    url: $scope.pdfDownload
                }, {
                    type: 'ctx',
                    title: 'medvais.e027va.cancel',
                    isVisible: function () {
                        return MedvaisDocStateService.canCancel($scope.documentData.metadata.status)
                            && practitionerId
                                && $scope.documentData.metadata.practitioner.id === practitionerId;
                    },
                    action: $scope.cancel
                }, {
                    type: 'ctx',
                    title: 'medvais.e027va.edit',
                    isVisible: function () {
                        return MedvaisDocStateService.canEdit($scope.documentData.metadata.status)
                                && practitionerId;
                    },
                    action: $scope.edit
                }
            ]
        };
    };


    $scope.return = function () {
        console.log('Old url: ' + $rootScope.medvaisOldUrl);
        if ($rootScope.medvaisOldUrl && $rootScope.medvaisOldUrl.search('/sign') >= 0) {
            $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
        } else {
            $window.history.back();
        }
    };


    $scope.openImagingStudyView = function(uid) {
        window.open($scope.meddreamUrl + '?study=' + uid.replace('urn:oid:', '') + '&token=' + token);
    };

    $scope.getFullName = function (p) {

        if (!p) {
            return null;
        }

        return ((_.exists(p.namePrefix) && !_.isBlank(p.namePrefix)) ? p.namePrefix + ' ' : '') +
                (p.givenName ? p.givenName + ' ' : '') +
                (p.familyName ? p.familyName : '');
    };

    $scope.getAge = function (p) {

        if (!p) {
            return null;
        }

        return $scope.calcAge(p.birthDate, new Date().getTime());

    };

    $scope.calcAge = function (from, to) {
        if (from && to) {
            var fromM = moment(Number(from));
            var toM = moment(Number(to));
            var diffDays = toM.diff(fromM, 'days');
            if (diffDays <= 31) {
                return diffDays + ' ' + $filter('translate')('medvais.abbr.days');
            }
            var diffMonths = toM.diff(fromM, 'months');
            if (diffMonths <= 12) {
                return diffMonths + ' ' + $filter('translate')('medvais.abbr.months');
            }
            if (diffMonths <= 24) {
                return '1 ' + $filter('translate')('medvais.abbr.years') + ' ' + (diffMonths - 12) + ' ' + $filter('translate')('medvais.abbr.months');
            }
            return toM.diff(fromM, 'years') + ' ' + $filter('translate')('medvais.abbr.years');
        }
        return '';
    };

    $scope.signDocument = function() {
        console.log("Signing document..." + $scope.documentId);
        $scope.options.redirectToSignPage($scope.documentId);
    };

    $scope.getDocStateLabel = function(state, isSigned) {
        return MedvaisDocStateService.getStatusLabel(state, isSigned);
    };

    $scope.getAttachmentUrl = function(ref) {
        return '/medvais-api/media/file?mediaRef=' + ref;
    };

    $scope.cancel = function() {
        $medvaisCancelDocMdl.show().then(
            function(reasonText){
                if (reasonText) {
                    MedvaisDocumentApiFactory.cancel({id: $scope.documentId}, {reason: reasonText}).$promise.then(
                        function(res){
                            $scope.options.redirectToSignPage(res.compositionId);
                        },
                        function(err) {
                            MessageService.clearAll();
                            MessageService.error($filter('translate')('medvais.doc.cancel.failure.msg'));
                        }
                    );
                }
            }
        );
    };

    $scope.edit = function() {
        $location.path($scope.options.getEdit027vaDocUrl($scope.documentId)).replace();
    };

}]);

