<div class="widget">
    <div class="widget-body" [class.d-none]="!showFilters">
        <div class="row row-gap-3">
            <div class="col-sm-12 col-md-3 col-lg-3">
                <div *ngFor="let item of filterOption" class="row">
                    <app-radio-button
                        radioName="filterByVisitData"
                        [id]="item.name"
                        [labelText]="item.name"
                        [radioTitle]="item.name"
                        [radioValue]="item.value"
                        [fControl]="formGroup.get('filterByVisitData')"
                    ></app-radio-button>
                </div>
            </div>

            <div class="col-sm-12 col-md-9 col-lg-9">
                <div *ngIf="formGroup.get('filterByVisitData').value" class="row row-gap-3">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Apsilankymo data" | translate }}</strong>
                        </div>
                        <div class="d-flex gap-3 d-inline-block flex-column flex-sm-row">
                            <app-date-picker
                                [fControl]="formGroup.get('dateFrom')"
                                inputId="dateFrom"
                                [placeholder]="'pp.dia.sea.dat.from' | translate"
                                suffixIcon="fa fa-calendar"
                            ></app-date-picker>
                            <app-date-picker
                                [fControl]="formGroup.get('dateTo')"
                                inputId="dateTo"
                                [placeholder]="'pp.dia.sea.dat.to' | translate"
                                suffixIcon="fa fa-calendar"
                            ></app-date-picker>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "SPĮ Pavadinimas" | translate }}</strong>
                        </div>
                        <div>
                            <input
                                [formControl]="formGroup.get('nameSPI1')"
                                typeaheadOptionField="nameAndJarCode"
                                [typeahead]="suggestionsOrg$"
                                [typeaheadAsync]="true"
                                [typeaheadItemTemplate]="customItemTemplate"
                                (typeaheadOnSelect)="typeaheadOnSelect($event, true)"
                                (typeaheadNoResults)="typeaheadNoResults($event, true)"
                                placeholder="Įveskite įstaigos pavadinimą"
                                class="form-control"
                            />
                            <ng-template #customItemTemplate let-model="item">
                                <strong>{{ model.name }}</strong> - {{ model.jarCode }}
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Apsilankymo būsena" | translate }}</strong>
                        </div>
                        <app-select
                            id="visitStatus"
                            [placeholder]="null"
                            [values]="encounterStatuses"
                            [fControl]="formGroup.get('visitStatus')"
                        />
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Priežastis" | translate }}</strong>
                        </div>
                        <app-select
                            id="visitReason"
                            [placeholder]="null"
                            [values]="this.visitEvents"
                            [fControl]="formGroup.get('visitReason')"
                        />
                    </div>
                </div>
                <div *ngIf="!formGroup.get('filterByVisitData').value" class="row row-gap-3">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Nėštumas" | translate }}</strong>
                        </div>
                        <app-input id="pregnancy" type="number" [fControl]="formGroup.get('pregnancy')" />
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Dokumento būsena" | translate }}</strong>
                        </div>
                        <app-multiselect-dropdown
                            class="d-block"
                            idField="code"
                            textField="name"
                            [dropdownDataList]="documentStatuses"
                            [singleSelection]="false"
                            [allowSearchFilter]="false"
                            [fControl]="formGroup.get('status')"
                        ></app-multiselect-dropdown>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Dokumentas" | translate }}</strong>
                        </div>
                        <app-multiselect-dropdown
                            class="d-block"
                            idField="value"
                            textField="name"
                            [dropdownDataList]="ngnDocumentsTypes"
                            [singleSelection]="false"
                            [allowSearchFilter]="false"
                            [fControl]="formGroup.get('docType')"
                        ></app-multiselect-dropdown>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="form-label">
                            <strong>{{ "Profesinė kvalifikacija / specializacija" | translate }}</strong>
                        </div>
                        <div>
                            <input
                                [formControl]="formGroup.get('targetQualificationCodes1')"
                                typeaheadOptionField="name"
                                [typeahead]="suggestionsQualification$"
                                [typeaheadAsync]="true"
                                [typeaheadItemTemplate]="qualificationTemplate"
                                (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                (typeaheadNoResults)="typeaheadNoResults($event)"
                                class="form-control"
                            />
                            <ng-template #qualificationTemplate let-model="item">
                                <strong>{{ model.name }}</strong>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-3 justify-content-end">
                <button class="btn btn-sm btn-outline-primary" (click)="clearFilters()">
                    {{ "pp.dia.sea.clearFilt" | translate }}
                </button>
                <button class="btn btn-sm btn-primary" (click)="applyFilters()">{{ "pp.dia.sea.srch" | translate }}</button>
            </div>
        </div>
    </div>
</div>
