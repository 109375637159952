import { AfterViewInit, Component, DestroyRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, forkJoin, take } from 'rxjs';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { clearFormArray, formatDateForDatePickers, updateFormArray } from '../../e096-utils/utils';
import { E096PartPartogramComponent } from './e096-part-sub-forms/e096-part-partogram/e096-part-partogram.component';
import { E096PartEntriesComponent } from './e096-part-sub-forms/e096-part-entries/e096-part-entries.component';

@Component({
    selector: 'app-e096-part-form',
    templateUrl: './e096-part-form.component.html',
})
export class E096PartFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: {[key: string]: any}) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);

        this.patchFormWithInitialData(this._initialFormValue);
    }

    @ViewChild(E096PartPartogramComponent) partogramComponent: E096PartPartogramComponent;
    @ViewChild(E096PartEntriesComponent) entriesComponent: E096PartEntriesComponent;

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: {[key: string]: any};
    private dateFields: string[] = ['regularOnsetOfContractions', 'timeOfAmniocentesis', 'timeOfEntry'];

    private updateFormArray = updateFormArray.bind(this) as typeof updateFormArray;
    private clearfArray = clearFormArray.bind(this) as typeof clearFormArray;

    get fruitLength(): string[] {
        return this.partogramComponent?.fruitLength;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly destroyRef: DestroyRef
    ) {
        forkJoin({
            fetalFluidColor: this.apiV2Entities.getEntitiesList('fetal-fluid-color'),
            fetalPrimordial: this.apiV2Entities.getEntitiesList('fetal-primordial'),
            childbirthMedicalPainManagement: this.apiV2Entities.getEntitiesList('childbirth-medical-pain-management'),
            cardiotocogramResults: this.apiV2Entities.getEntitiesList('cardiotocogram-results'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            partogram: this.partogramComponent.formGroup,
            entries: this.entriesComponent.formGroup
        });
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }


    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.patchFormWithInitialData(this._initialFormValue);
    }

    private patchFormWithInitialData(_initialFormValue: {[key: string]: any}) {
        this.formGroup.valueChanges.pipe(
            debounceTime(100),
            take(1)
        ).subscribe(() => {
            this.formGroup.patchValue(this._initialFormValue);
            this.clearfArray(`entries`);
            this.updateFormArray(this._initialFormValue, `entries`);
            if (!this.editable) {
                this.formGroup.disable();
            }
        });
        this.formGroup.patchValue(this._initialFormValue);
    }
}
