<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e097Gd.appointmentsForm.title' | translate"
>
    <div class="card-body">
        <div class="row">
            <div class="row req" *ngFor="let appointment of appointments">
                <fieldset class="col-6 col-lg-3 d-flex flex-column">
                    <legend class="float-none col-form-label mt-3" [class.norq]="!appointment.required" *ngIf="appointment.label">
                        {{appointment.label | translate}}:
                    </legend>
                    <app-checkbox
                        *ngIf="appointment.type !== 'radio'"
                        class="mt-3 col-form-label norq"
                        formClass="mb-0"
                        [inputId]="'gd-' + appointment.formGroup"
                        [labelText]="appointment.name | translate"
                        [fControl]="formGroup.get(appointment.formGroup + '.result')"
                    ></app-checkbox>
                    <div class="input-group gap-3" *ngIf="appointment.type === 'radio'">
                        <app-radio-button
                            *ngFor="let option of appointment.radioValues; let i = index"
                            [radioName]="appointment.formGroup + i"
                            [fControl]="formGroup.get(appointment.formGroup + '.result')"
                            [radioValue]="option.name"
                            [id]="appointment.formGroup + i"
                            class="col-auto"
                            formClass="mb-0"
                            [labelText]="option.name | translate"
                            labelClass="norq"
                        ></app-radio-button>
                    </div>
                    <app-form-control-validation [fControl]="formGroup.get(appointment.formGroup + '.result')" />
                </fieldset>
                <app-input
                    *ngIf="controlValue(appointment.formGroup + '.result')"
                    labelText="{{'doc.pregnancyDashboard.e097Gd.InspectionsForm.comment' | translate}}:"
                    class="form-group col-6 col-lg-9"
                    labelClass="col-form-label norq"
                    type="textarea"
                    textAreaRows="3"
                    [fControl]="formGroup.get(appointment.formGroup + '.comment')"
                ></app-input>
            </div>
        </div>
        <div class="row pb-4">
            <h4 class="mt-4">
                Antibiotikai:
            </h4>
            <app-antibiotics [editable]="editable" [formArray]="formGroup.get('antibiotics')" />
        </div>
    </div>
</nhr-record-widget>