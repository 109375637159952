ng1App.controller('document.E027EditCtrl',
    ['$scope', '$routeParams', 'DocumentCtx', 'MessageService', '$filter', 'DocumentModificationActions',
     function ($scope, $routeParams, DocumentCtx, MessageService, $filter, DocumentModificationActions) {

    MessageService.clearAll();

    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(11);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'};
    $scope.currentDiagnoses = $scope.form.partDiagnosis;

    $scope.handleValidationFailure = function(isValid) {
        $scope.diagnosisValid = isValid;
    };

    $scope.handleDiagnosesChange = function(event) {
        $scope.currentDiagnoses = event;
        $scope.form.partDiagnosis = event;
    };

    $scope.data = {};

    $scope.orderType = $routeParams.orderType;

    if(!$scope.orderType){
        $scope.form.$promise.then(function(form){
            if(form.immunizationOrders && form.immunizationOrders.length > 0){
                $scope.orderType = form.immunizationOrders[0].type.code;
            }else if(form.orderData && form.orderData.item && form.orderData.item.type){
                $scope.orderType = form.orderData.item.type.code;
            }
        });
    }

    if (DocumentCtx.getMode() === 'edit') {
        console.log('edit mode unsupported!');
    }

    $scope._shared.customValidator = function(documentForm, skipRequired) {
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        MessageService.clear('diagnosis-region');
         if (!$scope.form.partDiagnosis || $scope.form.partDiagnosis.length == 0) {
            MessageService.add('error', 'Nenurodyta nė viena diagnozė.', 'diagnosis-region');
            if (res) {
                _.scrollTo('#diagnoses');
            }
            res = false;
        }
        return res;
    };
}]);