<nhr-record-widget
    class="h-auto"
    [mixed]="true"
    [closed]="closed"
    [closable]="closable"
    [showIcon]="showIcon"
    [iconClass]="iconClass"
    [iconBgClass]="iconBgClass"
    [title]="title | translate"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngFor="let d of (data || [])">
        <div *ngIf="title === CardTitles.SuspectedAdverseReactions">
            <span class="small">
                <span> {{ d?.date | date : "yyyy-MM-dd" }}</span>
            </span>

            <p>
                <span *ngIf="d?.name">{{ d?.name }}, </span>
                <span *ngIf="d?.activeSubstance">{{ d?.activeSubstance }}</span>
                <span *ngIf="d?.strength">{{ d?.strength }}</span>
                <span *ngIf="d?.form">{{ d?.form }}</span>
            </p>

            <span class="small">
                {{ "Registravo" | translate }}:
                <span *ngIf="d?.practitioner?.specialty">{{ d?.practitioner?.specialty }}, </span>
                <span *ngIf="d?.practitioner?.fullName">{{ d?.practitioner?.fullName }}</span></span
            >
        </div>
        <div *ngIf="title === CardTitles.UpcomingEvents || title === CardTitles.ResearchResults">
            <small> {{ d?.date | date : "yyyy-MM-dd" }}</small>
            <div>
                <span *ngIf="d?.name">{{ d?.name }}, </span>
                <span *ngIf="d?.description">{{ d?.description }}</span>
            </div>
        </div>
        <div *ngIf="title === CardTitles.Hospital">
            <p class="fw-bold mb-0">
                <span> {{ d?.title }}:</span>
            </p>

            <span class="small">
                <span *ngIf="d?.name">{{ d?.name }} </span>
                <span *ngIf="d?.telNr"> <span class="vr"></span>{{ d?.telNr }} </span>
                <span *ngIf="d?.practitioner"> <span class="vr"></span>{{ d?.practitioner }}</span>
            </span>
        </div>
        <div *ngIf="title === CardTitles.Representations || title === CardTitles.HealthServicesConsents">
            <div class="row">
                <div
                    class="small align-content-center"
                    [ngClass]="{ 'col-8': title === CardTitles.Representations }"
                >
                    <span
                        *ngIf="title === CardTitles.HealthServicesConsents"
                        class="m-2 badge rounded-pill text-bg-dark"
                        >{{ d?.status }}</span
                    >
                    <span class="fw-bold mb-0">{{ d?.title }}</span
                    >, {{ "prašymas atstovauti nuo" | translate }} {{ d?.dateFrom | date : "yyyy-MM-dd" }}
                    {{ "iki" | translate }} {{ d?.dateTo | date : "yyyy-MM-dd" }}
                </div>
                <div *ngIf="title === CardTitles.Representations" class="col-4">
                    <button
                        type="button"
                        class="d-block w-100 btn btn-sm btn-secondary mb-1"
                        (click)="accept()"
                    >
                        {{ "Sutinku" | translate }}
                    </button>
                    <button
                        type="button"
                        class="d-block w-100 btn btn-sm btn-danger"
                        (click)="decline()"
                    >
                        {{ "Nesutinku" | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="title === CardTitles.PrescribedMedication" class="row mb-2">
            <div class="col-3">
                <span
                    class="badge rounded-pill text-bg-primary text-white"
                    [ngClass]="{
                        'text-bg-dark': d.status !== 'completed',
                        'text-bg-success': d.status === 'completed'
                    }"
                >
                    {{ d.status | translate }} </span
                ><br />
                <span class="badge rounded-pill badge-outline text-primary mt-3">
                    {{ (d.compensationTag ? "ap.med.cat.compensated" : "ap.med.cat.not_compensated") | translate }}
                </span>
            </div>
            <div class="col-9">
                <div class="fw-bold mb-0" *ngIf="d.medication">
                    <span *ngIf="d.prescriptionTagsNominalTag" class="fw-bold mb-0">
                        {{ "vv" | translate }}{{ " - " }}
                    </span>
                    <span *ngIf="showMedicationName(d)" class="fw-bold mb-0">
                        {{ d.medication.name }}
                    </span>
                    <span *ngIf="showMedicationName(d) && d.medication?.activeSubstances" class="vr"></span>
                    <span *ngIf="d.medication?.activeSubstances" class="fw-bold mb-0">
                        {{ d.medication?.activeSubstances }}
                    </span>
                    <span *ngIf="d.medication?.strength" class="vr"></span>
                    <span *ngIf="d.medication?.strength" class="fw-bold mb-0">
                        {{ d.medication?.strength }}
                    </span>
                    <span *ngIf="d.medication?.form" class="vr"></span>
                    <span *ngIf="d.medication?.form" class="fw-bold mb-0">
                        {{ d.medication?.form?.display }}
                    </span>
                </div>
                <div>
                    <span class="mb-0" *ngIf="d.status !== 'completed'">
                        {{ "valid.until" | translate }}{{ ": " }}{{ d["validityPeriod.end"] }}
                    </span>
                    <span class="mb-0" *ngIf="d.status === 'completed'">
                        {{ "Pakanka iki" | translate }}{{ ": " }}{{ d.dispenseDueDate }}
                    </span>
                </div>
            </div>
        </div>
    </li>

    <li class="last list-group-item" *ngIf="!isArray(data) && !data">
        <p class="text-center mb-0">{{ "pp.das.vis.notExist" | translate }}</p>
    </li>
    <li class="last list-group-item" *ngIf="!isArray(data) && data">
        <div *ngIf="title === CardTitles.LastVisit">
            <div class="fw-bold">
                <span class="text-info" title="{{ data }}">
                    <encounter-type-icon [encounter]="data"></encounter-type-icon>
                </span>
                <a href="/pp/encounter/{{ data.id }}">{{ data?.organizationData?.name }} </a>
            </div>
            <div>
                <span *ngIf="data?.practitionerData?.qualificationList[0]?.name"
                    >{{ data?.practitionerData?.qualificationList[0]?.name }}&nbsp;
                </span>
                <a href="{{ data.practitionerData | practitionerLink }}">{{
                    data?.practitionerData | fullName
                }}</a>
                <br />
                <div class="small">{{ data?.arrivalDate | date : "yyyy-MM-dd HH:mm" }}
                    <span *ngIf="data?.serviceType?.name">
                        <span class="vr"></span>{{ data?.serviceType?.name }}
                    </span>
                    <span *ngIf="data?.isByOrder">
                        <span class="vr"></span><span>{{ "pp.use.rec.lis.ord" | translate }}</span>
                    </span>
                    <span *ngIf="data?.isEms">
                        <span class="vr"></span><span>{{ "pp.use.rec.lis.bri" | translate }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="title === CardTitles.Contacts">
            <div class="fw-bold">
                {{ data?.givenName }} {{ data?.familyName }}, {{ data?.birthDate | date : "yyyy-MM-dd" }}
            </div>
            <div *ngIf="data?.esiNr">ESI nr. {{ data?.esiNr }}</div>
            <div *ngIf="data?.declaredAddress?.text">{{ data?.declaredAddress?.text }}</div>
            <div *ngIf="data?.emails">{{ data?.emails?.toString() }}</div>
            <div *ngIf="data?.phones">{{ data?.phones?.toString() }}</div>
        </div>
    </li>

    <li *ngIf="!loading && buttons?.length" class="list-group-item p-0">
        <div class="row justify-content-center" [ngClass]="{
            'row-cols-': buttons.length,
            'row-cols-1': !buttons.length
        }">
            <div class="col-6 align-self-center text-center" *ngFor="let button of buttons">
                <a [ngClass]="{
                        'btn btn-secondary d-block m-2 fw-normal': button.accent,
                        'text-decoration-none hover text-center py-2 px-2_5': !button.accent
                    }"
                    type="button"
                    [href]="button.href"
    
                >
                    {{ button.title | translate }}
                </a>
            </div>
        </div>
    </li>

</nhr-record-widget>
