import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { debounceTime, merge } from 'rxjs';

@Component({
    selector: 'aspn-sbvf-measurements-form',
    templateUrl: './sbvf-measurements-form.component.html',
})
export class SbvfMeasurementsFormComponent {
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    formGroup = this.fb.group({
        bloodPressureSystolic: this.fb.control(null, [Validators.min(0)]),
        bloodPressureDiastolic: this.fb.control(null, [Validators.min(0)]),
        bloodOxygenSaturation: this.fb.control(null),
        glucose: this.fb.control(null),
        cholesterol: this.fb.control(null),
        coagulation: this.fb.control(null),
        heartRate: this.fb.control(null, [Validators.min(0)]),
        temperature: this.fb.control(null),
        weight: this.fb.control(null, [Validators.min(0)]),
    });
    constructor(
        private readonly fb: FormBuilder
    ) {
        merge(
            this.formGroup.get('bloodPressureSystolic').valueChanges,
            this.formGroup.get('bloodPressureDiastolic').valueChanges
        )
        .pipe(debounceTime(200)).subscribe(() => {
            this.bloodPressureValidator();
        })
    }

    private bloodPressureValidator(): void {
        const firstValue = this.formGroup.get('bloodPressureSystolic').value;
        const secondValue = this.formGroup.get('bloodPressureDiastolic').value;
        let bloodPressureSystolicValidators = this.getSystolicValidators(firstValue, secondValue);
        let bloodPressureDiastolicValidators = this.getDiastolicValidators(firstValue, secondValue);

        this.formGroup.get('bloodPressureSystolic').clearValidators();
        this.formGroup.get('bloodPressureDiastolic').clearValidators();
        this.formGroup.get('bloodPressureSystolic').setValidators(bloodPressureSystolicValidators);
        this.formGroup.get('bloodPressureDiastolic').setValidators(bloodPressureDiastolicValidators);

        this.formGroup.get('bloodPressureSystolic').updateValueAndValidity({emitEvent: false});
        this.formGroup.get('bloodPressureDiastolic').updateValueAndValidity({emitEvent: false});
    }

    private getSystolicValidators(firstValue: number | null, secondValue: number | null): ValidatorFn[] {
        const validators = [];

        if (firstValue || secondValue) {
            validators.push(Validators.required);
            if (secondValue) {
                validators.push(Validators.min(secondValue));
            } else {
                validators.push(Validators.min(0));
            }
        } else {
            validators.push(Validators.min(0));
        }

        return validators;
    }

    private getDiastolicValidators(firstValue: number | null, secondValue: number | null): ValidatorFn[] {
        const validators = [Validators.min(0)];

        if (firstValue || secondValue) {
            validators.push(Validators.required);
            if (firstValue) {
                validators.push(Validators.max(firstValue));
            }
        }

        return validators;
    }
}
