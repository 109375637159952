angular.module('erx.api').factory('ErxAnalyticsApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
    var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Analytics';
    return $resource('', {}, {
        // Organizacijos gavimas pagal id
        getByDiagnosis: {
            method: 'GET',
            url: URL_BASE,
            isArray: true
        },
        getPrescriptionsByAgeGroup: {
            method: 'GET',
            url: URL_BASE + '/prescriptionsByAgeGroup',
            isArray: true
        },
        getOrganizations: {
            method: 'GET',
            url: URL_BASE + '/organizations',
            isArray: true
        },
        getPrescriptionsByOrganization: {
            method: 'GET',
            url: URL_BASE + '/prescriptionsByOrganization',
            isArray: true
        }
        
    });
}]);