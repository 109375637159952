<nhr-record-widget
        [closable]="true"
        [closed]="false"
        [title]="'doc.pregnancyDashboard.uterineDiagram.title' | translate"
        id="pregnancyChart"
        class="h-auto"
>
    <div class="card-body">
        <div class="mb-5 mx-4">
            <app-uterine-growth-curve-diagram [data]="data"></app-uterine-growth-curve-diagram>
        </div>
        <div class="m-auto col-md-10 col-12 mt-5 pt-3 mb-5 px-4">
            <ag-grid-angular
                    style="width: 100%; height: 500px"
                    class="ag-theme-main-grid"
                    [defaultColDef]="defaultColDef"
                    [columnDefs]="colDefs"
                    [headerHeight]="100"
                    [detailRowAutoHeight]="true"
            ></ag-grid-angular>
        </div>
    </div>
</nhr-record-widget>
