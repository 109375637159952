import {IScope} from "angular";

ng1App.directive('viewEditComments', 
    ['AlertsApiFactory', 'MessageService', 'DpPatientContext', '$filter', '$q', 'SessionContext',
     function (AlertsApiFactory, MessageService, DpPatientContext, $filter, $q, SessionContext) {
    return {
        templateUrl: '/templates/components/comments/view_edit_comments.html',
        restrict: 'E',
        replace: false,
        scope: {
            compositionId: "=",
            docType: "=",
            patientPortal: "="
        },
        transclude: true,
        link: function (scope: any, element, attrs) {
            var PAGE_SIZE = 7;

            scope.formType = attrs.formType;
            scope.closed = attrs.closed ? attrs.closed : 'false';
            scope.formInline = attrs.formInline ? attrs.formInline : false;
            scope.search = attrs.search ? attrs.search : false;
            scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'green';
            scope.iconClass = attrs.iconClass ? attrs.iconClass : 'fa-comments-o';
            scope.title = attrs.title ? attrs.title :  $filter('translate')('doc.com');
            scope.comments = [];
            scope.initDataLoaded = false;
			scope.filter = {};
			scope.permissions = [];

            scope.searchComments = function() {
                scope.page = 0;
                scope.comments = [];
                scope.loadComments();
            }
			scope.loadComments = function() {
                scope.page = scope.page + 1;
                var query = {
					count : PAGE_SIZE,
                    page :  scope.page,
					patientId: scope.patientId,
					compositionId: scope.compositionId
				}
				for ( var attrname in scope.filter) {
					query[attrname] = scope.filter[attrname];
				}
				 scope.commentsLoaded = false;
				 AlertsApiFactory.getComments(query, function(result) {
					 //scope.comments.push(result.items);
                     Array.prototype.push.apply(scope.comments, result.items);
					 scope.commentsLoaded = true;
                     scope.commentsTotal = result.total;
				 });
			}

            scope.addComment = function () {
                this.commentEditForm.submitted = true;
                var form = this.commentEditForm;
                if (this.commentEditForm.$invalid) {
                    MessageService.add('error', $filter('translate')('err.formHasErrors'), 'documentComments');
                    return;
                }
                //adding required visibilities
                _.each(scope.comment.permission.requiredVisibilities, function (val) {
                    scope.comment.visibilities.push(val);
                });

                scope.comment.commentType = scope.comment.permission.commentType;
                AlertsApiFactory.addComment(scope.comment, function (data) {
                    MessageService.clear('documentComments');
                    MessageService.add('success', $filter('translate')('inf.commentAddSuccess'), 'documentComments');
                    scope.comment.id = data.commentId;
                    scope.comments.push(scope.comment);
                    if(scope.patientPortal){
                        scope.comment.authorPatient = scope.patient;
                    }
                    scope.comment = newComment();
                    form.$setPristine(true);
                    form.submitted = false;
                    scope.commentsTotal = scope.commentsTotal + 1;
                    scope.showViewTab();
                },      function () {
                            MessageService.add('error', $filter('translate')('err.commentAddFailure'), 'documentComments');
                        })
            }
            var newComment = function () {
                return {
                    active: true,
                    dateCreated: new Date().getTime(),
                    visibilities: [],
                    visibleFor: {},
                    patientRef: scope.patient.fullId,
                    compositionId: scope.compositionId,
                    permission: scope.permissions[0],
                    practitioner: scope.patientPortal ? null: scope.author

                };
            };

            scope.$on('widget_toggle', function (event, data) {
                if (!data) {
                    init();
                }
            });

            scope.onVisibilityChange = function () {
                scope.comment.visibilities = [];
                _.each(scope.comment.visibleFor, function (val, key) {
                    var type = findVisibilityType(key);
                    if (type) {
                        scope.comment.visibilities.push(type);
                    }
                });
            };

            var findVisibilityType = function (code) {
                return _.find(scope.comment.permission.allowedVisibilities, function (val) {
                    return code == val.code
                });
            };

            scope.termlessChange = function () {
                if (scope.comment.termless) {
                    scope.comment.validTo = undefined;
                } else {
                    this.commentEditForm.validTo.$setPristine(true);
                }
            };

            var init = function () {              
                var patientId = null;
                if (scope.patientPortal) {
                    patientId = 'current';
                    scope.patient = scope.$parent.user;
                    scope.author = scope.patient;
                } else {
                    patientId = DpPatientContext.data.id;
                    scope.patient = DpPatientContext.data;
                    scope.author = SessionContext.practitioner;
                }
                scope.patientId = patientId;
                var comments = AlertsApiFactory.getComments({count: PAGE_SIZE, patientId: patientId, compositionId: scope.compositionId});

                var permissions = AlertsApiFactory.getCommentsPermissions({patientId: patientId, docType: scope.docType});
                $q.all([comments.$promise, permissions.$promise]).then(function () {
                    scope.page = 1;
                    scope.initDataLoaded = true;
                    scope.commentsLoaded = true;
                    scope.commentsTotal = comments.total;
                    scope.comments = comments.items;

                    scope.permissions = permissions;

                    if (comments.total > 0 || permissions.length === 0 || !$filter('acWrite')('Alert', ['4'])) {
                        scope.showViewTab();
                    } else {
                        scope.showEditTab();
                    }
                    if (permissions.length > 0) {
                        scope.comment = newComment();
                    }
                });
                scope.visibilities = AlertsApiFactory.getCommentsVisibilities();   
            }

            scope.areMore = function() {
                return scope.commentsLoaded && scope.commentsTotal > scope.comments.length;
            }

            scope.showEditTab = function () {
                MessageService.clear('documentComments');
                scope.selected = 2;
            }
            scope.showViewTab = function () {
                scope.selected = 1;
            }
        }
    };
}]);