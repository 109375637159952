import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-joints-form',
    templateUrl: './sbvf-joints-form.component.html',
})
export class SbvfJointsFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        this._viewMode = value;
        if (value) {
            this.formGroup.disable();
        }
    };

    jointsRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        jointMobility: this.fb.control(null, [Validators.required]),
        localization: this.fb.control(null),
        jointPainScale: this.fb.control({value: null, disabled: true}),
        signsOfInflammation: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });

    private _viewMode: boolean;
    constructor(
        private readonly fb: FormBuilder
    ) {
        this.formGroup.get('jointMobility').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('jointPainScale').disable();
                    return;
                }
                if (value === '3') {
                    this.formGroup.get('jointPainScale').enable();
                } else {
                    this.formGroup.get('jointPainScale').reset();
                    this.formGroup.get('jointPainScale').disable();
                }
            });
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.jointsRows = [
            {
                title: 'Sąnarių paslankumas',
                type: 'choise',
                options: value['jointMobility'],
                formControl: this.formGroup.get('jointMobility')
            },
            {
                title: 'Lokalizacija (jeigu sunkus/skausmingas)',
                type: 'text',
                formControl: this.formGroup.get('localization')
            },
            {
                title: 'Skausmo skalė',
                type: 'choise',
                options: value['scale'],
                customClass: 'w-auto',
                formControl: this.formGroup.get('jointPainScale')
            },
            {
                title: 'Uždegimo požymiai',
                type: 'choise',
                options: value['signsInflammation'],
                formControl: this.formGroup.get('signsOfInflammation')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
