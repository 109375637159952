import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { debounceTime, distinctUntilChanged, Observable, Observer, of, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PractitionerModel } from '../../../models/practitioner.model';


@Component({
  selector: 'app-find-and-prefill-info',
  templateUrl: './find-and-prefill-info.component.html',
})
export class FindAndPrefillInfoComponent<T> {
    @Input() obs: (query: string) => Observable<T>;
    @Input() set disable(value: boolean) {
        if (typeof value !== 'boolean') return;
        if (value) {
            this.searchValue.disable();
        } else {
            this.searchValue.enable();
        }
    };

    @Output() selectData: EventEmitter<any> = new EventEmitter<any>();

    searchValue = this.fb.control(null);
    selectedData: PractitionerModel;
    suggestions$: Observable<any>;

    constructor(
        private fb: FormBuilder,
        private destroyRef: DestroyRef
    ) {
        this.getSpecialists();
        this.searchValue.valueChanges.pipe(
            debounceTime(50),
            takeUntilDestroyed()
        ).subscribe(r => {
            if (!this.selectedData || r === this.selectedData?.['fullDisplay']) return;
            this.selectedData = null;
        })
    }
    choseData(e: TypeaheadMatch) {
        this.selectedData = e.item;
        delete this.selectedData['fullDisplay'];
        this.selectData.emit(this.selectedData)
    }

    private getSpecialists() {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.searchValue.value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                return !query
                    ? of([])
                    : this.obs(query)
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }
}
