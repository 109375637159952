import {Component, EventEmitter, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {forkJoin} from "rxjs";
import {ErecListFilterModel} from "../../../models/erec-list-filter.model";
import {ApiV2Entities} from "../../../services/api-v2-entities";
import {EntityModel} from "../../../models/entity.model";
import {DocumentStateStatusEnum} from "../../../models/document-state-status.enum";
import {RecipeFormEnum} from "../../../models/recipe-form.enum";

@Component({
    selector: 'erec-list-filter',
    templateUrl: './erec-list-filter.component.html'
})
export class ErecListFilterComponent {
    @Output() filtering: EventEmitter<ErecListFilterModel> = new EventEmitter<ErecListFilterModel>();
    statuses: any[] = [
        {id: DocumentStateStatusEnum.ACTIVE, name: 'Galiojantys'},
        {id: DocumentStateStatusEnum.STOPPED, name: 'Sustabdyti'},
        {id: DocumentStateStatusEnum.ON_HOLD_RESERVED, name: 'Rezervuoti'},
        {id: DocumentStateStatusEnum.COMPLETED, name: 'Įsigyti'},
        {id: DocumentStateStatusEnum.ON_HOLD_FOR_REVIEW, name: 'Negaliojantys'},
        {id: DocumentStateStatusEnum.ENTERED_IN_ERROR, name: 'Atšaukti'},
    ];
    types: EntityModel[] = [];
    forms: any[] = [
        {id: RecipeFormEnum.EL, name: 'Elektroninė'},
        {id: RecipeFormEnum.PP, name: 'Popierinė'}
    ];

    formGroup: FormGroup = this.fb.group({
        fromDate: this.fb.control(null),
        toDate: this.fb.control(null),
        status: this.fb.control(null),
        isUsing: this.fb.control(null),
        myPrescriptions: this.fb.control(null),
        id: this.fb.control(null),
        type: this.fb.control(null),
        form: this.fb.control(null),
        prescribed: this.fb.control(null),
    })

    constructor(
        private readonly fb: FormBuilder,
        private apiV2Entities: ApiV2Entities
    ) {
        forkJoin([
            this.apiV2Entities.getEntitiesList('medication-prescription-type'),
        ])
            .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.types = res[0]?.map(t => {
                    t.id = t.id ? t.id : t.code;
                    t.name = t.name ? t.name : t.displayValue;
                    return t;
                });
            });
    }

    clearFilters(): void {
        this.formGroup.reset();
        this.filtering.emit(this.formGroup.value);
    }

    applyFilters(): void {
        this.filtering.emit(this.formGroup.value);
    }
}
