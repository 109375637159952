<nhr-record-widget
    [closed]="false"
    [title]="'inr.drugDescriptionForm.title' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <div class="row mb-3 req">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugDescriptionForm.title' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('description')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('description')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugDescriptionForm.start' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-date-picker
                    inputId="startYear"
                    suffixIcon="fa fa-calendar"
                    minMode="year"
                    dateInputFormat="YYYY"
                    [maxDate]="maxDate"
                    [fControl]="formGroup.get('startYear')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('startYear')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugDescriptionForm.end' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-date-picker
                    inputId="endYear"
                    suffixIcon="fa fa-calendar"
                    minMode="year"
                    dateInputFormat="YYYY"
                    [maxDate]="maxDate"
                    [fControl]="formGroup.get('endYear')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('endYear')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugDescriptionForm.treatmentShouldBeApplied' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('treatmentShouldBeApplied')"
                ></app-input>
            </div>
        </div>
        <ng-container *ngIf="!isPatient">
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.severeConsequences' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of yesNoOptions; let i = index"
                            radioName="severeConsequences-{{i}}"
                            [fControl]="formGroup.get('severeConsequences')"
                            [radioValue]="option.name"
                            id="severeConsequences-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('severeConsequences')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.severeConsequencesEffect' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of severeConsequencesEffects; let i = index"
                            radioName="severeConsequencesEffect-{{i}}"
                            [fControl]="formGroup.get('severeConsequencesEffect')"
                            [radioValue]="option.name"
                            id="severeConsequencesEffect-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('severeConsequencesEffect')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.shortSevereConsequencesDescription' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-input
                            inputGroupClass="mt-auto"
                            [fControl]="formGroup.get('shortSevereConsequencesDescription')"
                        ></app-input>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="row mb-3 req">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugDescriptionForm.outcome' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <div>
                    <app-radio-button
                        *ngFor="let option of outcomeOptions; let i = index"
                        radioName="outcome-{{i}}"
                        [fControl]="formGroup.get('outcome')"
                        [radioValue]="option.name"
                        id="outcome-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('outcome')" />
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isPatient">
            <div class="row mb-3 req">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.causedByMedicationError' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of yesNoOptions; let i = index"
                            radioName="causedByMedicationError-{{i}}"
                            [fControl]="formGroup.get('causedByMedicationError')"
                            [radioValue]="option.name"
                            id="causedByMedicationError-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('causedByMedicationError')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.medicationError' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of medicalErrors; let i = index"
                            radioName="medicationError-{{i}}"
                            [fControl]="formGroup.get('medicationError')"
                            [radioValue]="option.name"
                            id="medicationError-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('medicationError')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.shortSevereConsequencesDescription' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="formGroup.get('shortMedicationErrorDescription')"
                    ></app-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.occuredDue' | translate}}:</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <ng-container *ngFor="let option of occuredList; let i = index">
                            <app-radio-button
                                radioName="occuredDue-{{i}}"
                                [fControl]="formGroup.get('occuredDue')"
                                [radioValue]="option.name"
                                id="occuredDue-{{i}}"
                                class="w-100"
                                [labelText]="option.name"
                            ></app-radio-button>
                            <div class="ms-3" *ngIf="i === 0">
                                <app-checkbox
                                    *ngFor="let option of offLabelConditions; let i = index"
                                    inputId="offLabelConditions{{i}}"
                                    class="mt-1"
                                    formClass="mb-0"
                                    [labelText]="option.name"
                                    [checkboxValue]="option.name"
                                    [checkboxDisabled]="!(editable && !controlDisabled('offLabelConditions'))"
                                    [checkboxChecked]="checkboxIsChecked(option.name, 'offLabelConditions')"
                                    (checkboxChange)="updateControl(option.name, 'offLabelConditions')"
                                ></app-checkbox>
                            </div>
                        </ng-container>
                        <app-form-control-validation [fControl]="formGroup.get('occuredDue')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.causalRelationshipINRMedicinalProduct' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of yesNoOptions; let i = index"
                            radioName="causalRelationshipINRMedicinalProduct-{{i}}"
                            [fControl]="formGroup.get('causalRelationshipINRMedicinalProduct')"
                            [radioValue]="option.name"
                            id="causalRelationshipINRMedicinalProduct-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('causalRelationshipINRMedicinalProduct')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.itWasIneffective' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of ineffectiveList; let i = index"
                            radioName="itWasIneffective-{{i}}"
                            [fControl]="formGroup.get('itWasIneffective')"
                            [radioValue]="option.name"
                            id="itWasIneffective-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('itWasIneffective')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugDescriptionForm.shortSevereConsequencesDescription' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="formGroup.get('shortEffectiveDescription')"
                    ></app-input>
                </div>
            </div>
        </ng-container>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugDescriptionForm.moreInr' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <div>
                    <app-radio-button
                        *ngFor="let option of yesNoOptions; let i = index"
                        radioName="moreInr-{{i}}"
                        [fControl]="formGroup.get('moreInr')"
                        [radioValue]="option.name"
                        id="moreInr-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('moreInr')" />
                </div>
            </div>
        </div>


    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>