import { DocumentTagEnum } from '../../../../shared/models/document-tag.enum';
import { getTemplateComponent } from '../../../../../utils/hypridHelper';
import { E096Component } from './e096.component';
import { E096NtPageComponent } from './e096-nt/e096-nt-page/e096-nt-page.component';
import { E096PtklPageComponent } from './e096-ptkl/e096-ptkl-page/e096-ptkl-page.component';
import { E096NPageComponent } from './e096-n/e096-n-page/e096-n-page.component';
import { E096GePageComponent } from './e096-ge/e096-ge-page/e096-ge-page.component';
import { E096NaPageComponent } from './e096-na/e096-na-page/e096-na-page.component';
import { E096CpoPageComponent } from './e096-cpo/e096-cpo-page/e096-cpo-page.component';
import { E096PartPageComponent } from './e096-part/e096-part-page/e096-part-page.component';


ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding e096 routes");

        $routeSegmentProvider
        .when(`/dp/patients/:patientId/e096-view`, "DP.DP_PATIENT3L.NGN_E096_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_nt}/view/:id`, "DP.DP_PATIENT3L.NGN_NT_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_ptkl}/view/:id`, "DP.DP_PATIENT3L.NGN_PGKL_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_n}/view/:id`, "DP.DP_PATIENT3L.NGN_N_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_ge}/view/:id`, "DP.DP_PATIENT3L.NGN_GE_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_na}/view/:id`, "DP.DP_PATIENT3L.NGN_NA_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_cpo}/view/:id`, "DP.DP_PATIENT3L.NGN_CPO_VIEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_part}/view/:id`, "DP.DP_PATIENT3L.NGN_PART_VIEW")

        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_nt}`, "DP.DP_PATIENT2LREVNEW.NGN_NT_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_nt}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_NT_EDIT")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_ptkl}`, "DP.DP_PATIENT2LREVNEW.NGN_PGKL_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_ptkl}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_PGKL_EDIT")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_n}`, "DP.DP_PATIENT2LREVNEW.NGN_N_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_n}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_N_EDIT")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_ge}`, "DP.DP_PATIENT2LREVNEW.NGN_GE_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_ge}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_GE_EDIT")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_na}`, "DP.DP_PATIENT2LREVNEW.NGN_NA_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_na}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_NA_EDIT")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_cpo}`, "DP.DP_PATIENT2LREVNEW.NGN_CPO_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_cpo}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_CPO_EDIT")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_part}`, "DP.DP_PATIENT2LREVNEW.NGN_PART_NEW")
        .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e096_part}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_PART_EDIT");


        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT3L")
            .segment("NGN_E096_VIEW", { template: getTemplateComponent(E096Component) })
            .segment("NGN_NT_VIEW", { template: getTemplateComponent(E096NtPageComponent) })
            .segment("NGN_PGKL_VIEW", { template: getTemplateComponent(E096PtklPageComponent) })
            .segment("NGN_N_VIEW", { template: getTemplateComponent(E096NPageComponent) })
            .segment("NGN_GE_VIEW", { template: getTemplateComponent(E096GePageComponent) })
            .segment("NGN_NA_VIEW", { template: getTemplateComponent(E096NaPageComponent) })
            .segment("NGN_CPO_VIEW", { template: getTemplateComponent(E096CpoPageComponent) })
            .segment("NGN_PART_VIEW", { template: getTemplateComponent(E096PartPageComponent) });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2LREVNEW")
            .segment("NGN_NT_NEW", { template: getTemplateComponent(E096NtPageComponent) })
            .segment("NGN_NT_EDIT", { template: getTemplateComponent(E096NtPageComponent) })
            .segment("NGN_PGKL_NEW", { template: getTemplateComponent(E096PtklPageComponent) })
            .segment("NGN_PGKL_EDIT", { template: getTemplateComponent(E096PtklPageComponent) })
            .segment("NGN_N_NEW", { template: getTemplateComponent(E096NPageComponent) })
            .segment("NGN_N_EDIT", { template: getTemplateComponent(E096NPageComponent) })
            .segment("NGN_GE_NEW", { template: getTemplateComponent(E096GePageComponent) })
            .segment("NGN_GE_EDIT", { template: getTemplateComponent(E096GePageComponent) })
            .segment("NGN_NA_NEW", { template: getTemplateComponent(E096NaPageComponent) })
            .segment("NGN_NA_EDIT", { template: getTemplateComponent(E096NaPageComponent) })
            .segment("NGN_CPO_NEW", { template: getTemplateComponent(E096CpoPageComponent) })
            .segment("NGN_CPO_EDIT", { template: getTemplateComponent(E096CpoPageComponent) })
            .segment("NGN_PART_NEW", { template: getTemplateComponent(E096PartPageComponent) })
            .segment("NGN_PART_EDIT", { template: getTemplateComponent(E096PartPageComponent) });
        },
]);
