<div class="context info-header">
    <nav *ngIf="patient" class="navbar navbar-expand-lg px-3 px-lg-5 py-3" role="navigation" data-bs-theme="dark" aria-label="'Encounter navigation' | translate">
        <div class="container-fluid d-flex justify-content-end px-0 d-lg-none">
            <a [href]="patient | patientLink" class="nav-link flex-grow-1" *ngIf="patient">
                <div class="text-truncate">
                    <span class="fs-3 fw-bold">
                        {{patient | fullName | capitalize}}{{", "}}{{patient | age}}
                    </span>
                </div>
            </a>
            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#patient-context-collapse" [title]="'Show navigation'| translate">
                <span class="sr-only">{{'Show navigation'| translate}}</span>
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="container-fluid px-0">
            <div id="patient-context-collapse" class="collapse navbar-collapse">
                <div class="d-flex flex-column flex-lg-row gap-3 w-100">
                    <div *ngIf="patient" class="d-flex flex-grow-1 gap-3 flex-column flex-lg-row">
                        <div class="d-flex flex-grow-1 gap-1 flex-row flex-lg-column">
                            <a [href]="patient | patientLink" class="nav-link d-none d-lg-block" *ngIf="patient">
                                <div class="text-truncate">
                                    <span class="fs-3 fw-bold">
                                        {{patient | fullName | capitalize}}{{", "}}{{patient | age}}
                                    </span>
                                </div>
                            </a>
                            <div class="d-flex flex-wrap gap-1">
                                <span [class]="'badge align-self-center' + (isInsured() ? ' text-bg-success' : ' text-bg-danger')">
                                    {{isInsured() ? "Draustas" : "Nedraustas"}}
                                </span>
                                <span *ngIf="hasAllergies" class="badge align-self-center text-bg-warning">Alergijos</span>
                                <span *ngIf="tumors" class="badge align-self-center text-bg-warning">Navikai</span>
                                <span *ngIf="pregnancy" class="badge align-self-center text-bg-warning">Nėštumas</span>
                                <span *ngIf="aspn" class="badge align-self-center text-bg-warning">ASPN</span>
                                <span>
                                    {{(patient.personalCode ? "a.k. " + patient.personalCode : "esi nr.: " + patient.esiNr)}}
                                    {{(patientPhone && " | " + patientPhone)}}
                                    {{(patientEmail && " | " + patientEmail)}}
                                    {{(additionalData && " | 1.5. Papildomi duomenys: ")}}
                                </span>
                            </div>
                        </div>
                        <div class="align-self-lg-center d-flex gap-2 flex-column flex-lg-row">
                            {{(links && "Nuorodos")}}
                        </div>
                    </div>
                    <!-- <button class="btn btn-lg btn-outline-light text-nowrap align-self-lg-center align-self-stretch" data-bs-toggle="modal" data-bs-target="#documentType" (click)="onModalOpen()">Naujas ESI įrašas</button> -->
                </div>
            </div>
        </div>
    </nav>
</div>
<nhr-msg-global></nhr-msg-global>
<document-type-select modalId="documentType" [loading]="modalIsLoading" [allowedTypes]="allowedTypes" [allowedCerts]="allowedCerts" [patient]="patient" [encounter]="encounter"/>