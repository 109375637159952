import { Component, Input, OnInit } from "@angular/core";
import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction';
import ltLocale from "@fullcalendar/core/locales/lt";
import { ApiV2PatientCalendar } from "src/api/api-v2-patient-calendar";
import { RouteParams } from "src/app/ng1.routeParams";

@Component({
  selector: 'patient-calendar',
  templateUrl: './patient-calendar.component.html'
})
export class PatientCalendarComponent implements OnInit {
    calendarOptions: CalendarOptions;
    @Input() patientId: string;

    constructor(private readonly params: RouteParams, private apiV2PatientCalendar: ApiV2PatientCalendar) { }

    ngOnInit() {
        this.calendarOptionsDisplay(this.patientId);
    }

    calendarOptionsDisplay(patientId: string){
        this.calendarOptions = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            initialView: 'timeGridWeek',
            headerToolbar: {
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            eventSources: [
                {
                    events: (fetchInfo, successCallback, failureCallback) => {
                        this.apiV2PatientCalendar.getPatientCalendar(patientId).then(events => {
                            const transformedEvents = events.map(event => {
                                // Parse the start and end times from the event data
                                const startTime = new Date(event.eventDate);
                                const endTime = event.eventEndDate ? new Date(event.eventEndDate) : new Date(startTime.getTime() + 30*60000); // Fallback to +30min if no end time
                                // Calculate the duration in minutes
                                const durationMinutes = (endTime.getTime() - startTime.getTime()) / 60000;
                                // Determine display mode based on whether an explicit end time is provided
                                let displayMode = 'long'; // Default to 'long'
                                if (!event.eventEndDate) {
                                    displayMode = 'short'; // No explicit end time, assume 'short'
                                } else if (durationMinutes >= 30) {
                                    displayMode = 'long'; // Explicit duration is 30 min or more
                                }
                                return {
                                    ...event,
                                    title: event.eventTypeName,
                                    start: event.eventDate,
                                    end: event.eventEndDate || new Date(startTime.getTime() + 30*60000).toISOString(), // Use ISO string for consistency
                                    durationMinutes,
                                    displayMode,
                                    tooltipContent: this.getEventTypeDescription(event),
                                };
                            });
                            successCallback(transformedEvents);
                        }).catch(error => {
                            console.error("Failed to fetch events:", error);
                        });
                    },
                },
            ],
            weekends: true, // show or hide weekends,
            locale: ltLocale,
            allDaySlot: false,
            slotDuration: '00:30:00',
            defaultTimedEventDuration: '00:30:00',
            eventOverlap: false,
        };

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 0);
    }

    getEventTypeDescription(extendedProps) {
        const eventTypeDescription = extendedProps.eventTypeDescription;
        let descriptionParts = [];

        if (extendedProps.eventTypeCode === 'visit-doctor') {
            if (eventTypeDescription.medicalServiceName) {
                descriptionParts.push(`${eventTypeDescription.medicalServiceName}`);
            }
            if (eventTypeDescription.organizationName) {
                descriptionParts.push(`${eventTypeDescription.organizationName}`);
            }
        } else if (extendedProps.eventTypeCode === 'planned-immunization') {
            if (eventTypeDescription.disease) {
                descriptionParts.push(`${eventTypeDescription.disease}`);
            }
            if (eventTypeDescription.vaccineType) {
                descriptionParts.push(` - ${eventTypeDescription.vaccineType}`);
            }
        } else if (extendedProps.eventTypeCode === 'prescription-validity') {
            if (eventTypeDescription.activeSubstance) {
                descriptionParts.push(`${eventTypeDescription.activeSubstance}`);
            }
        } else if (extendedProps.eventTypeCode === 'dispense-due-date') {
            if (eventTypeDescription.medicationName) {
                descriptionParts.push(`${eventTypeDescription.medicationName}`);
            }
        }
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 0);

    return descriptionParts.join(' ');
    }

    getBadgeClass(status: string) {
        switch(status) {
          case 'Suplanuotas':
            return 'text-bg-success text-white';
          case 'Patvirtintas':
            return 'text-bg-success text-white';
          case 'Laukia patvirtinimo':
            return 'text-bg-primary text-white';
          case 'Įvykęs':
            return 'bg-dark-subtle text-dark';
          case 'Atšauktas':
            return 'text-bg-danger text-white';
          case 'Įsigytas':
            return 'bg-dark-subtle text-dark';
          case 'Sustabdytas':
            return 'bg-dark-subtle text-dark';
          case 'Rezervuotas':
            return 'bg-dark-subtle text-dark';
          case 'Negalioja':
            return 'bg-dark-subtle text-dark';
          case 'Galioja':
            return 'text-bg-success text-white';
          case 'Atmestas':
            return 'bg-dark-subtle text-dark';
          default:
            return 'bg-dark-subtle text-dark';
        }
    }

    getCardClass(status: string) {
        switch(status) {
          case 'Suplanuotas':
            return 'bg-success-subtle bg-opacity-50';
          case 'Patvirtintas':
            return 'bg-success-subtle bg-opacity-50';
          case 'Laukia patvirtinimo':
            return 'bg-primary-subtle bg-opacity-50';
          case 'Atšauktas':
            return 'bg-danger-subtle bg-opacity-50';
          case 'Galioja':
            return 'bg-success-subtle bg-opacity-50';
          default:
            return '';
        }
    }

}