import { CommonModule, DatePipe } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SharedLibrary } from '../../../../shared/shared.module';
import { CapitalizePipe } from '../../../../shared/pipes/capitalize.pipe';
import { DowngradeAdapter } from '../../../../../utils/hypridHelper';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import "./e030.routes";
import { E030Component } from './e030.component';
import {
    E030PsychiatricRiskFactorsComponent
} from './e030-forms/e030-psychiatric-risk-factors/e030-psychiatric-risk-factors.component';
import { E030MedicalDataComponent } from './e030-forms/e030-medical-data/e030-medical-data.component';
import { E030ConsultationPlanComponent } from './e030-forms/e030-consultation-plan/e030-consultation-plan.component';
import {
    E030DiagnosesDescriptionComponent
} from './e030-forms/e030-diagnoses-description/e030-diagnoses-description.component';
import { E030TreatmentAppliedComponent } from './e030-forms/e030-treatment-applied/e030-treatment-applied.component';
import { E030RecomendationsComponent } from './e030-forms/e030-recomendations/e030-recomendations.component';
import { E030GeneralDataComponent } from './e030-forms/e030-general-data/e030-general-data.component';
import { E030AdditionalComponent } from './e030-additional-component/e030-additional.component';
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {E030ViewComponent} from "./e030-view.component";

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        E030Component,
        E030ViewComponent,
        E030PsychiatricRiskFactorsComponent,
        E030MedicalDataComponent,
        E030ConsultationPlanComponent,
        E030DiagnosesDescriptionComponent,
        E030TreatmentAppliedComponent,
        E030RecomendationsComponent,
        E030GeneralDataComponent,
        E030AdditionalComponent
    ],
    providers: [CapitalizePipe, DatePipe],
    imports: [
        CommonModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        TimepickerModule,
        TypeaheadModule,
    ],
})
export class E030Module {}

new DowngradeAdapter(ng1App)
    .downgradeComponent(E030Component)
    .downgradeComponent(E030ViewComponent)
    .downgradeComponent(E030AdditionalComponent, 'appE030Additional');
