import { TestQuestions } from '../../../../models/risk-factor.model';

export const AuditCQuestions: TestQuestions[] = [
    {
        question: '1. Kaip dažnai geriate alkoholinius gėrimus?',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Kartą per mėnesį ar rečiau', score: 1 },
            { answer: '2–4 kartus per mėnesį', score: 2 },
            { answer: '2–3 kartus per savaitę', score: 3 },
            { answer: '4 ar daugiau kartų per savaitę', score: 4 }
        ]
    },
    {
        question: '2. Kiek standartinių alkoholio vienetų įprastai išgeriate tą dieną, kai geriate?',
        answers: [
            { answer: '1 ar 2', score: 0 },
            { answer: '3 ar 4', score: 1 },
            { answer: '5 ar 6', score: 2 },
            { answer: '7-9', score: 3 },
            { answer: '10 ar daugiau', score: 4 },
        ]
    },
    {
        question: '3. Kaip dažnai per pastaruosius 12 mėnesių per vieną kartą išgerdavote 6 ar daugiau standartinių alkoholio vienetų?',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Rečiau nei kartą per mėnesį', score: 1 },
            { answer: 'Kartą per mėnesį', score: 2 },
            { answer: 'Kartą per savaitę', score: 3 },
            { answer: 'Kasdien arba beveik kasdien', score: 4 },
        ]
    },
    {
        question: '4. Kaip dažnai per pastaruosius 12 mėnesių pastebėjote, kad pradėję gerti nebegalite liautis?',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Rečiau nei kartą per mėnesį', score: 1 },
            { answer: 'Kartą per mėnesį', score: 2 },
            { answer: 'Kartą per savaitę', score: 3 },
            { answer: 'Kasdien arba beveik kasdien', score: 3 },
        ]
    },
    {
        question: '5. Kaip dažnai per pastaruosius 12 mėnesių dėl gėrimo neatlikote to, ko iš jūsų paprastai tikimasi? ',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Rečiau nei kartą per mėnesį', score: 1 },
            { answer: 'Kartą per mėnesį', score: 2 },
            { answer: 'Kartą per savaitę', score: 3 },
            { answer: 'Kasdien arba beveik kasdien', score: 4 },
        ]
    },
    {
        question: '6. Kaip dažnai per pastaruosius 12 mėnesių pagirioms palengvinti ryte reikėjo išgerti alkoholio?',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Rečiau nei kartą per mėnesį', score: 1 },
            { answer: 'Kartą per mėnesį', score: 2 },
            { answer: 'Kartą per savaitę', score: 3 },
            { answer: 'Kasdien arba beveik kasdien', score: 4 },
        ]
    },
    {
        question: '7. Kaip dažnai po išgėrimo per pastaruosius 12 mėnesių jautėte kaltę ar sąžinės priekaištus?',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Rečiau nei kartą per mėnesį', score: 1 },
            { answer: 'Kartą per mėnesį', score: 2 },
            { answer: 'Kartą per savaitę', score: 3 },
            { answer: 'Kasdien arba beveik kasdien', score: 4 },
        ]
    },
    {
        question: '8. Kaip dažnai per pastaruosius 12 mėnesių neprisiminėte, kas įvyko praėjusios dienos išgertuvėse?',
        answers: [
            { answer: 'Niekada', score: 0 },
            { answer: 'Rečiau nei kartą per mėnesį', score: 1 },
            { answer: 'Kartą per mėnesį', score: 2 },
            { answer: 'Kartą per savaitę', score: 3 },
            { answer: 'Kasdien arba beveik kasdien', score: 4 },
        ]
    },
    {
        question: '9. Ar dėl jūsų gėrimo jūs pats ar kas nors kitas buvo sužeistas?',
        answers: [
            { answer: 'Ne', score: 0 },
            { answer: 'Taip, bet ne per pastaruosius 12 mėnesių', score: 2 },
            { answer: 'Taip, per pastaruosius 12 mėnesių', score: 4 },
        ]
    },
    {
        question: '10. Ar giminaitis, draugas, gydytojas ar kitas sveikatos priežiūros specialistas kada nors yra išreiškęs susirūpinimą dėl jūsų gėrimo ar paraginęs gerti mažiau?',
        answers: [
            { answer: 'Ne', score: 0 },
            { answer: 'Taip, bet ne per pastaruosius 12 mėnesių', score: 2 },
            { answer: 'Taip, per pastaruosius 12 mėnesių', score: 4 },
        ]
    }
];