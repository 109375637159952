angular.module('medvais.components').directive('medvaisDocStatus', function () {
    return {
        templateUrl: 'modules/medvais/components/common/medvais-doc-status.html',
        restrict: 'E',
        replace: false,
        scope: {status: '='},
        link: function (scope: any, element, attrs) {
            scope.showTitle = attrs.showtitle === true || attrs.showtitle === 'true';
        }
    };
});