ng1App.controller("doctor.patient.VaccinesCtrl", [
    "$scope",
    "$location",
    "$modal",
    "$filter",
    "$modalConfirm",
    "EncountersApiFactory",
    "OrderSelectModalHelper",
    "DpSessionContext",
    "IntegratedComponentContext",
    "$routeParams",
    "DpPatientContext",
    "PatientsApiFactory",
    "EntitiesApiFactory",
    "MessageService",
    "VaccinationsApiFactory",
    "OrdersApiFactory",
    "FormLoaderService",
    function (
        $scope,
        $location,
        $modal,
        $filter,
        $modalConfirm,
        EncountersApiFactory,
        OrderSelectModalHelper,
        DpSessionContext,
        IntegratedComponentContext,
        $routeParams,
        DpPatientContext,
        PatientsApiFactory,
        EntitiesApiFactory,
        MessageService,
        VaccinationsApiFactory,
        OrdersApiFactory,
        FormLoaderService
    ) {
        FormLoaderService.startFormLoading();
        $scope.dashboardUrl = "/dp/patients/" + $routeParams.patientId;
        $scope.patientId = $routeParams.patientId;
        $scope.$emit("setHelpPage", "perzireti-skiepu-irasus-saraso-forma");

        $scope.canEdit = false;
        $scope.canAccessOrders = false;
        EncountersApiFactory.hasActiveEncounter({ patientId: $scope.patientId }, function (data) {
            $scope.canEdit = data.value && $filter("acWrite")("ImmunizationSchedule");
            $scope.canAccessOrders = data.value && $filter("acWrite")("Composition", ["11369-6"]);
        });

        var PAGE_SIZE = 10;
        var setupForm = function (field?) {
            return {
                id: DpPatientContext.id,
                dateFrom: null,
                dateTo: null,
                page: 1,
                offset: 0,
                offsetField: field,
                diseaseCodes: [],
                count: PAGE_SIZE,
                ascendingOrder: true,
                vaccineTypeQuery: undefined,
            };
        };
        $scope.data = {};
        $scope.rowsCount = 0;
        $scope.pageCount = 0;
        $scope.form = setupForm("month");
        $scope.searchForm = setupForm();
        MessageService.clearAll();
        MessageService.showSuccessEventMessage();
        MessageService.unregisterSuccessEvent();
        $scope.plannedVaccinations = [];
        $scope.vaccineTypes = [];

        $scope.simpleSearch = function () {
            $scope.filterResults();
        };

        $scope.filterResults = function () {
            if ($scope.data.q) {
                $scope.filteredVaccinations = $filter("listFilter")($scope.plannedVaccinations, $scope.data.q, [
                    "immunizationRecommendation.recommendations[0].plannedDate|date",
                    "immunizationRecommendation.disease.name",
                    "immunizationRecommendation.recommendations[0].medicationName",
                ]);
            } else {
                $scope.filteredVaccinations = $scope.plannedVaccinations;
            }
        };

        $scope.loadVaccinations = function () {
            $scope.loading = true;
            $scope.form.count = PAGE_SIZE;
            return PatientsApiFactory.getVaccinations($scope.form, function (res) {
                var vaccination = res["result"];

                $scope.plannedVaccinations = [];

                var rowNumberToLoad = ($scope.form.page - 1) * 10;
                var repeating = rowNumberToLoad + 10;
                var repeatingTill = repeating > vaccination.length ? vaccination.length : repeating;

                for (var i = rowNumberToLoad; i < repeatingTill; i++) {
                    $scope.plannedVaccinations.push(vaccination[i]);
                }

                if (vaccination.length == 0 /*|| vaccination.length < $scope.form.count*/) {
                    $scope.noMoreRecords = true;
                } else {
                    $scope.noMoreRecords = false;
                }

                $scope.period = res["period"];
                $scope.year = moment($scope.period).year();
                // $scope.form.page = res["currentPage"];
                $scope.filterResults();
                $scope.loading = false;

                $scope.rowsCount = vaccination.length;
                $scope.pageCount = Math.ceil(vaccination.length / 10);
            });
        };

        $scope.loadMoreVaccinations = function () {
            $scope.form.page = $scope.form.page + 1;
            $scope.loadVaccinations();
        };

        $scope.loadNext = function () {
            FormLoaderService.startFormLoading();
            $scope.form.page = 1;
            $scope.plannedVaccinations = [];
            $scope.form.offset = $scope.form.offset + 1;
            var promise = $scope.loadVaccinations().$promise;
            FormLoaderService.endFormLoading(promise);
        };
        $scope.loadPrevious = function () {
            FormLoaderService.startFormLoading();
            $scope.form.page = 1;
            $scope.plannedVaccinations = [];
            $scope.form.offset = $scope.form.offset - 1;
            var promise = $scope.loadVaccinations().$promise;
            FormLoaderService.endFormLoading(promise);
        };

        $scope.showPageSeparator = function (index) {
            return (index + 1) % PAGE_SIZE === 0;
        };

        DpSessionContext.getSettings(function (data) {
            PAGE_SIZE = data.count_per_page || 10;
            FormLoaderService.endFormLoading($scope.loadVaccinations().$promise);
        });

        var refreshCount = function () {
            PatientsApiFactory.getVaccinationsCount({ id: DpPatientContext.id }, function (res) {
                $scope.vaccinationCount = res.value;
                $scope.vaccinationCountPlanned = res.planned;
                $scope.vaccinationCountOverdue = res.overdue;
                $scope.vaccinationCountDone = res.done;
                $scope.vaccinationCountUnderway = res.underway;
                $scope.vaccinationCountIrrelevant = res.irrelevant;
            });
        };
        refreshCount();
        var createModal = function () {
            return $modal({
                scope: $scope,
                title: "Planuojamo skiepo įvedimo tipas",
                contentTemplate: "/templates/portal/doctor/patient/vaccines/vaccine_type_select.html",
            });
        };

        var modal = createModal();

        $scope.selectDocumentType = function () {
            modal = createModal();
            modal.$promise.then(function () {
                modal.show();
            });
        };

        $scope.createVaccine = function (documentType) {
            if (documentType != null) {
                modal.hide();
                if (documentType == "manual") {
                    $location.path("/dp/patients/" + DpPatientContext.id + "/vaccines/new/");
                } else {
                    $location.path("/dp/patients/" + DpPatientContext.id + "/vaccines/new/" + documentType);
                }
            }
        };

        $scope.mark = function (id, rec) {
            $modalConfirm
                .show({
                    title: $filter("translate")("inf.ale.vac.mark.tit"),
                    content: $filter("translate")("inf.ale.vac.mark"),
                })
                .then(function () {
                    VaccinationsApiFactory.getMarkAsIgnored({ id: id }, null, function () {
                        MessageService.success("Skiepas sėkmingai pažymėtas kaip neaktualus");
                        rec.status.code = "entered in error";
                        FormLoaderService.startFormLoading();
                        refreshCount();
                        $scope.form.page = 1;
                        $scope.plannedVaccinations = [];
                        var promise = $scope.loadVaccinations().$promise;
                        FormLoaderService.endFormLoading(promise);
                    });
                });
        };

        var searchModal = $modal({
            scope: $scope,
            contentTemplate: "/templates/portal/doctor/patient/vaccines/search.html",
        });

        $scope.advancedSearch = function () {
            $scope.cancelErr = 0;
            $scope.documentCancelReason = {};
            searchModal.$promise.then(function () {
                if ($scope.vaccineTypes.length == 0) {
                    $scope.vaccineTypes = EntitiesApiFactory.getList({ classCode: "vaccine-type" });
                }
                searchModal.show();
            });
        };

        $scope.clear = function () {
            _.markAllItems(false, $scope.vaccineTypes, false);
            $scope.searchForm = setupForm();
        };

        $scope.filteredVaccineTypes = function (query) {
            return $filter("filter")($scope.vaccineTypes, query);
        };

        $scope.search = function (searchType) {
            switch (searchType) {
                case "all":
                    if ($scope.vaccinationCount == 0) {
                        return;
                    }
                    $scope.form = setupForm();
                    break;
                case "planned":
                    if ($scope.vaccinationCountPlanned == 0) {
                        return;
                    }
                    $scope.form = setupForm();
                    $scope.form.isPlanned = true;
                    $scope.form.isActive = true;
                    $scope.form.isDone = false;
                    break;
                case "overdue":
                    if ($scope.vaccinationCountOverdue == 0) {
                        return;
                    }
                    $scope.form = setupForm();
                    $scope.form.isOverdue = true;
                    $scope.form.isActive = true;
                    break;
                case "done":
                    if ($scope.vaccinationCountDone == 0) {
                        return;
                    }
                    $scope.form = setupForm();
                    $scope.form.isDone = true;
                    $scope.form.isActive = true;
                    break;
                case "underway":
                    if ($scope.vaccinationCountUnderway == 0) {
                        return;
                    }
                    $scope.form = setupForm();
                    $scope.form.isUnderway = true;
                    $scope.form.isActive = true;
                    break;
                case "irrelevant":
                    if ($scope.vaccinationCountIrrelevant == 0) {
                        return;
                    }
                    $scope.form = setupForm();
                    $scope.form.isIgnored = true;
                    $scope.form.isActive = false;
                    break;
                case "advanced":
                    if ($scope.searchForm.isIgnored == undefined) {
                        // do not set anything
                    } else if ($scope.searchForm.isIgnored) {
                        $scope.searchForm.isActive = false;
                    } else {
                        $scope.searchForm.isActive = true;
                    }
                    $scope.searchForm.page = 1;
                    $scope.form = $scope.searchForm;
                    $scope.form.diseaseCodes = _.collectSelectedItems($scope.vaccineTypes, function (item) {
                        return item.code;
                    });
                    searchModal.hide();
                    break;
                default:
                    console.log("invalid search type: " + searchType);
                    return;
            }
            $scope.plannedVaccinations = [];
            $scope.activeBlock = searchType;
            $scope.loadVaccinations();
        };

        $scope.clearAndSearch = function () {
            $scope.plannedVaccinations = [];
            $scope.activeBlock = undefined;
            $scope.form = setupForm("month");
            $scope.data.q = undefined;
            $scope.clear();
            $scope.loadVaccinations();
        };

        $scope.selectVaccineOrder = function () {
            var orders = OrdersApiFactory.getForE063({ patientId: DpPatientContext.id });
            $scope.e063Modal = OrderSelectModalHelper.getModal(
                "Skiepų skyrimai",
                "Daugiau skyrimų",
                "e063",
                4,
                undefined,
                DpPatientContext.id,
                orders
            );

            $scope.e063Modal.$promise.then(function () {
                $scope.e063Modal.show();
            });
        };

        $scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();

        $scope.updatePagination = function (pagination) {
            if (pagination?.page) {
                $scope.form.page = pagination.page;
                $scope.loadVaccinations();
            }
        };
    },
]);
