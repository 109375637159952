import {Component, Input, OnInit} from "@angular/core";
import {PractitionerModel} from "../../../../shared/models/practitioner.model";
import {OrganizationModel} from "../../../../shared/models/organization.model";
import _ from "underscore";

@Component({
    selector: 'registrator-info',
    templateUrl: './registrator-info.component.html'
})
export class RegistratorInfoComponent implements OnInit {

    protected readonly _ = _;
    @Input() practitioner: PractitionerModel;
    @Input() organization: OrganizationModel;

    constructor() {

    }

    ngOnInit(): void {

    }

    onPractitionerClick() {
        window.location.href = `/dp/specialist/${this.practitioner.id}`
    }
}