import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, distinctUntilChanged, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { onCheckboxChange } from "../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { OutpatientVisitDescriptionModel } from "../../models/outpatientVisitDescription.model";
import { YesNoOptions } from "../pregnancy-dashboard.constants";
import { DocumentCaptionModel, NGNCaptionModel } from "../shared/models/shared.model";
import {
    AdditionalSpecialistConsultation,
    FetusMovements,
    FetusPathology,
    FetusPosition,
    MaintenancePlanWeeks,
} from "./outpatient-visit-description.constants";

@Component({
    selector: "app-outpatient-visit-description",
    templateUrl: "./outpatient-visit-description.component.html",
})
export class OutpatientVisitDescriptionComponent {
    public formGroup: FormGroup;
    public maintenancePlan: FormGroup;

    public readonly fetusMovements = Object.values(FetusMovements);
    public readonly fetusPathology = Object.values(FetusPathology);
    public readonly fetusPosition = Object.values(FetusPosition);
    public readonly additionalSpecialistConsultation = Object.values(AdditionalSpecialistConsultation);
    public readonly maintenancePlanWeeks = MaintenancePlanWeeks;

    public currentDate = new Date();
    public onCheckboxChange = onCheckboxChange;

    public womanWeightAtStart = 65;
    public currentWomenWeight = 0;
    public editableMaintenancePlanIndex: number;

    public complaints = YesNoOptions;
    public documentTag: DocumentTagEnum = DocumentTagEnum.e113_ap;
    prefillData: any;
    public documentCaptionData: DocumentCaptionModel;
    public ngnCaptionData: NGNCaptionModel;

    public get getGainWeightDuringPregnancy(): string {
        const gainWeight = this.currentWomenWeight - this.womanWeightAtStart;
        return `${gainWeight > 0 ? "+" : ""}${gainWeight.toFixed(3)}`;
    }

    public get getFetusDescriptionFormArray(): FormArray {
        return this.formGroup?.get("fetusDescription") as FormArray;
    }

    public get getMaintenancePlanFormArray(): FormArray {
        return this.formGroup?.get("maintenancePlan") as FormArray;
    }

    public get getChildCountArray(): { letter: string }[] {
        const countControl = this.formGroup?.get("womanAmbulatoryDescription.childCount");
        const count = Math.min(Math.max(countControl?.value || 0, 0), 20);

        return count > 0
            ? Array.from({ length: count }, (_, index) => ({
                letter: String.fromCharCode(65 + index),
            }))
            : [];
    }

    public get isDisableAddMaintenancePlan(): boolean {
        return !(this.maintenancePlan.get("periodFrom").valid && this.maintenancePlan.get("periodTo").valid);
    }

    private patientId: string;
    private isEdit: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();
        this.maintenancePlan = this._fb.group({
            additionalSpecialistConsultation: this._fb.control(null),
            periodFrom: this._fb.control(null, Validators.required),
            periodTo: this._fb.control(null, Validators.required),
            note: this._fb.control(null),
        });

        if (this.isEdit) {
            this.subscription.add(this.patchForm());
        }

        this.subscription.add(this.subscribeWomanAmbulatoryDescriptionWeight());
        this.subscription.add(this.subscribeChildCountChanges());
        this.subscription.add(this.subscribeDocumentCaptionData());
        this.subscription.add(this.subscribeNGNCaptionData());
        this.formGroup.markAllAsTouched();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    public async saveForm(): Promise<void> {
        const payload: OutpatientVisitDescriptionModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.saveOutpatientVisitDescription(payload);

        this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${DocumentTagEnum.e113_ap}`]);
    }

    public async submitForm(): Promise<void> {
        const payload: OutpatientVisitDescriptionModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.submitOutpatientVisitDescription(payload);
    }

    public async signingSubmitForm(): Promise<void> {
        const payload: OutpatientVisitDescriptionModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signingSubmitOutpatientVisitDescription(payload);
    }

    public async sealConfirmForm(): Promise<void> {
        const payload: OutpatientVisitDescriptionModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.sealConfirmOutpatientVisitDescription(payload);
    }

    public async signForm(): Promise<void> {
        const payload: OutpatientVisitDescriptionModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signOutpatientVisitDescription(payload);
    }

    public clearForm(): void {
        this.formGroup.reset();
        this.getMaintenancePlanFormArray.clear();
    }

    public addMaintenancePlan(): void {
        const { additionalSpecialistConsultation, periodFrom, periodTo, note } = this.maintenancePlan.value;

        if (this.editableMaintenancePlanIndex >= 0 && !!this.editableMaintenancePlanIndex) {
            this.getMaintenancePlanFormArray
                .at(this.editableMaintenancePlanIndex)
                .patchValue({ additionalSpecialistConsultation, periodFrom, periodTo, note });
            this.editableMaintenancePlanIndex = null;
        } else {
            this.getMaintenancePlanFormArray.push(
                this._fb.group({
                    additionalSpecialistConsultation: this._fb.control(additionalSpecialistConsultation),
                    periodFrom: this._fb.control(periodFrom),
                    periodTo: this._fb.control(periodTo),
                    note: this._fb.control(note),
                })
            );
        }

        this.maintenancePlan.reset();
    }

    public editMaintenancePlan(index: number): void {
        const maintenancePlanByIndex = this.getMaintenancePlanFormArray.at(index).value;

        this.maintenancePlan.patchValue(maintenancePlanByIndex);
        this.editableMaintenancePlanIndex = index;
    }

    public removeMaintenancePlan(index: number): void {
        this.getMaintenancePlanFormArray.removeAt(index);

        if (this.editableMaintenancePlanIndex === index) {
            this.editableMaintenancePlanIndex = null;
        }
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            womanAmbulatoryDescription: this._fb.group({
                assessmentDate: this._fb.control(null, Validators.required),
                isComplaints: this._fb.control(true, Validators.required),
                complaints: this._fb.control(null),
                weight: this._fb.control(null),
                bloodPressureLeftUp: this._fb.control(null),
                bloodPressureLeftDown: this._fb.control(null),
                bloodPressureRightUp: this._fb.control(null),
                bloodPressureRightDown: this._fb.control(null),
                pulse: this._fb.control(null),
                temperature: this._fb.control(null),
                gda: this._fb.control(null),
                childCount: this._fb.control(null),
                recommendations: this._fb.control(null, Validators.required),
                appointmentsTreatment: this._fb.control(null, Validators.required),
            }),
            fetusDescription: this._fb.array([]),
            maintenancePlan: this._fb.array([]),
        });
    }

    private patchFetusDescription(count: number): void {
        const fetusDescription = this.getFetusDescriptionFormArray;
        const existingFetusDescriptionLenght = fetusDescription.value.length;

        if (count > 0 && count > existingFetusDescriptionLenght) {
            for (let i = 0; i < count - existingFetusDescriptionLenght; i++) {
                fetusDescription.push(
                    this._fb.group({
                        letter: this._fb.control(this.getChildCountArray[existingFetusDescriptionLenght]?.letter),
                        heartbeat: this._fb.control(null),
                        movements: this._fb.control(null, Validators.required),
                        pathology: this._fb.control(null),
                        position: this._fb.control(null),
                    })
                );
            }
        }

        if (existingFetusDescriptionLenght > count) {
            for (let i = count; i <= existingFetusDescriptionLenght; i++) {
                fetusDescription.removeAt(i);
            }
        }

        fetusDescription.updateValueAndValidity();
    }

    private patchForm(): Subscription {
        return this._apiV2PregnancyDashboard
            .getOutpatientVisitDescription(this.patientId)
            .subscribe((data: OutpatientVisitDescriptionModel) => {
                const outpatienttVisitDescription = {
                    ...data,
                    womanAmbulatoryDescription: {
                        ...data.womanAmbulatoryDescription,
                        assessmentDate: new Date(data.womanAmbulatoryDescription.assessmentDate),
                    },
                };
                this.formGroup.patchValue(outpatienttVisitDescription);

                outpatienttVisitDescription.maintenancePlan?.forEach((plan) =>
                    this.getMaintenancePlanFormArray.push(
                        this._fb.group({
                            additionalSpecialistConsultation: this._fb.control(plan.additionalSpecialistConsultation),
                            periodFrom: this._fb.control(plan.periodFrom),
                            periodTo: this._fb.control(plan.periodTo),
                            note: this._fb.control(plan.note),
                        })
                    )
                );
            });
    }

    private subscribeWomanAmbulatoryDescriptionWeight(): Subscription {
        return this.formGroup
            ?.get("womanAmbulatoryDescription.weight")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe((weight) => {
                this.currentWomenWeight = weight ? Number(weight) : 0;
            });
    }

    private subscribeChildCountChanges(): Subscription {
        return this.formGroup
            ?.get("womanAmbulatoryDescription.childCount")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe((childCount) => {
                this.patchFetusDescription(childCount);
            });
    }

    private subscribeDocumentCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getDocumentCaptionData(this.patientId).subscribe((data) => {
            this.documentCaptionData = data;
        });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).subscribe((data) => {
            this.ngnCaptionData = data;
        });
    }
}
