import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

export interface SubFormCustomCol {
    cellClass: string;
    type: 'radioButton' | 'inputText';
    formControlName?: string;
    colName: string;
}

export interface SubFormCustomRow {
    formGroup?: FormGroup;
    label: string;
    labelCellClass: string;
}

export interface SubFormLayout {
    rows: SubFormCustomRow[];
    sharedCells: SubFormCustomCol[];
}

export interface CustomTableRowsData {
    row: {
        label: string
        key: string;
    }[],
    labelStyle: string,
    radioCellStyle: string
}
@Component({
    selector: "aspn-activity-home-environment-assessment-sub-form",
    templateUrl: "./home-environment-assessment-sub-form.component.html",
})
export class HomeEnvironmentAssessmentSubFormComponent {
    @Input() title: string;
    @Input() layout: SubFormLayout;
    @Input() noLastRowBorder: boolean;
    @Input() editable: boolean = true;
}
