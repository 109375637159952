<!-- ??? off-topic in this component, but related with issue: -->
<!-- ??? do we need a title? -->
<!-- ??? Where are view and download buttons -->
<!-- ??? button more / or pagination -->

<!-- ??? what buttons we should choose here? -->
<div
    id="{{data.id}}"
    slat-item clickable edit
    (slatItemClick)="onCardClick(data.id, data.documentType)"
    (editButtonClick)="onEditClick(data.id, data.documentType)"
>
    <div class="row">
        <div class=" text">
            <span class="text-secondary">{{data.fileCreatedTime | date:'yyyy-MM-dd'}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col mb-1">
            <span class="fw-bold">{{data.cardName}}</span>
        </div>
    </div>
    <div class="text">
        <span class="text">{{data.diagnosis[0].code}} </span>
        <span class="text">{{data.diagnosis[0].name}}</span>
    </div>
    <div class="text">
        <span class="text">{{data.institution.name}}, </span>
        <span class="text">JAR kodas: {{data.institution.jarCode}}, </span>
        <span class="text">SVEIDRA nr. {{data.institution.sveidraId}}</span>
    </div>
</div>