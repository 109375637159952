<div class="d-flex flex-column flex-sm-row justify-content-between gap-2 flex-wrap container" id="modification-buttons">
    <div class="row row-cols-12 row-cols-sm-auto row-gap-2 d-flex flex-column flex-sm-row gap-2 flex-wrap">
        <ng-content select="[form-btn-left]"></ng-content>
    </div>
    <div class="row row-cols-12 row-cols-sm-auto row-gap-2 d-flex gap-2 align-items-center">
        <div class="d-flex d-sm-inline w-100 w-sm-auto align-items-center justify-content-center" *ngIf="showSpinner">
            <span class="fa fa-spinner fa-spin w-auto"></span>
        </div>
        <ng-content></ng-content>
    </div>
</div>
