<div class="">
    <nhr-record-widget [closable]="true" [closed]="false" [title]="'doc.visited.title' | translate" [bodyClass]="'p-0'">
        <ol class="slats hover" *ngIf="showWidget">
            <li *ngFor="let item of documentsList; let i = index" class="list-check">
                <div class="form-check list-check-item" tabindex="0" (click)="updateControl(item.id, documentsChecked)" (keyup)="updateControl(item.id, documentsChecked)">
                    <app-checkbox
                        *ngIf="isItemMarkable(item)"
                        [inputId]="'document-' + i"
                        [checkboxValue]="item.id"
                        [checkboxChecked]="checkboxIsChecked(item.id, documentsChecked)"
                    ></app-checkbox>
                </div>
                <div class="list-check-title" role="button" tabindex="0" (click)="navigateToDoc(item.id)" (keypress)="navigateToDoc(item.id)">
                    <strong>{{ item.title }}</strong>
                    <div>
                        <ng-container [ngSwitch]="item.documentStatus">
                            <span *ngSwitchCase="'DRAFT'">{{'doc.draft'|translate}}</span>
                            <span *ngSwitchCase="'PRELIMINARY'">{{'doc.preliminary'|translate}}</span>
                            <span *ngSwitchCase="'IN_PROGRESS'">{{'doc.in_progress'|translate}}</span>
                            <span *ngSwitchCase="'COMPLETED'">{{'doc.completed'|translate}}</span>
                            <span *ngSwitchCase="'FINAL_UNSIGNED'">{{'doc.final'|translate}}</span>
                            <span *ngSwitchCase="'FINAL_SIGNED'">{{'doc.signed'|translate}}</span>
                            <span *ngSwitchCase="'CANCELED_UNSIGNED'">{{'doc.entered_in_error_unsigned'|translate}}</span>
                            <span *ngSwitchCase="'CANCELED_SIGNED'">{{'doc.entered_in_error'|translate}}</span>
                            <span *ngSwitchCase="'INACTIVE'">{{'doc.inactive'|translate}}</span>
                            <span *ngSwitchCase="'AMENDED_SIGNED'">{{'doc.amended'|translate}}</span>
                            <span *ngSwitchCase="'AMENDED_UNSIGNED'">{{'doc.amended_unsigned'|translate}}</span>
                            <span *ngSwitchDefault>Unknown status [{{item.status}}]</span>
                        </ng-container>
                    </div>
                </div>
            </li>
            <li class="footer">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="form-check lh-1 mb-0 ps-0" style="min-height: auto;">
                        <button class="btn btn-sm btn-link px-0" (click)="markAll()">Žymėti visus</button>
                        <!-- Toggle -->
                        <!-- <label class="form-check-label" for="visitDocsMarkAll">
                            {{'Pažymėti visus' | translate}}
                        </label>
                        <input class="form-check-input mt-0" type="checkbox" id="visitDocsMarkAll"  (click)="markAll()" (keypress)="markAll()" /> -->
                    </div>
                    <div class="btn-group btn-group-sm">
                        <button type="button" class="btn btn-primary" [disabled]="formGroup.get(documentsChecked).value.length === 0" (click)="confirmAction(batchSignDocuments())">{{ 'Pasirašyti' | translate }}
                        </button>
                        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                [disabled]="formGroup.get(documentsChecked).value.length === 0"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="visually-hidden">{{ 'Pasirašyti' | translate }}</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let action of actions">
                                <a class="dropdown-item" tabindex="0" (click)="confirmAction(action.method)"
                                (keypress)="confirmAction(action.method)">{{ action.name | translate }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ol>
        <ol class="slats hover" *ngIf="!showWidget">
            <li class="text-center"><i class="fa fa-spinner fa-spin"></i>&nbsp;Kraunasi vizito dokumentai</li><!--Kraunasi dokumentai-->
        </ol>
    </nhr-record-widget>
</div>
