import { Component, Input } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

interface ICustomHeaderParams {
    icon: string;
    additionalClass: string;
    tooltip: string;
}
@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
})
export class IconComponent  implements ICellRendererAngularComp {
    @Input() icon: string;
    @Input() additionalClass: string;
    @Input() tooltip: string = null;

    agInit(params: ICellRendererParams & ICustomHeaderParams): void {
        this.icon = params.icon;
        this.additionalClass = params.value;
        this.tooltip = params.tooltip ? params.tooltip : this.tooltip;
    }

    refresh(params: ICellRendererParams<any>): boolean {
        return false;
    }
}
