<table class="table table-bordered table-hover w-100 overflow-x-scroll mb-0 shadow-none">
    <tbody>
    <ng-container *ngFor="let row of rowsCounter; let i = index">
            <tr class="text-center d-flex part-table-row border-bottom part-table-row position-relative"
            >
                <div *ngIf="phase === 'latent'" class="position-absolute end-100">
                    {{5 - i}}
                </div>
                <td *ngFor="let column of mainColumns" class="p-0 flex-1 h-0 part-table-cell">
                    <ng-container *ngTemplateOutlet="tdCells; context: {column, value: 5 - i }"></ng-container>
                </td>
            </tr>
    </ng-container>
    </tbody>
</table>

<ng-template #tdCells let-column="column"let-value="value">
    <div class="d-flex border-light h-100 fs-4 text-black">
        <div class="p-0 flex-1 position-relative">
            <div class="d-flex align-items-center justify-content-center h-100"
                 [innerHTML]="getDataByHour(column, 1, value)">
            </div>
        </div>
        <div class="border-start p-0 flex-1 position-relative">
            <div class="d-flex align-items-center justify-content-center h-100"
                 [innerHTML]="getDataByHour(column, 2, value)">
            </div>
        </div>
        <div class="border-start p-0 flex-1 position-relative">
            <div class="d-flex align-items-center justify-content-center h-100"
                 [innerHTML]="getDataByHour(column, 3, value)">
            </div>
        </div>
        <div class="border-start p-0 flex-1 position-relative">
            <div class="d-flex align-items-center justify-content-center h-100"
                 [innerHTML]="getDataByHour(column, 4, value)">
            </div>
        </div>
    </div>
</ng-template>
