import { Component, OnInit } from "@angular/core";
import { PortalTypeEnum } from "src/app/shared/models/portalType.enum";

@Component({
    selector: 'dp-doc-ref-f025-view',
    templateUrl: './dp-doc-ref-f025-form-view.component.html'
})
export class DpDocRefF025ViewComponent implements OnInit {

    portalType: PortalTypeEnum = PortalTypeEnum.DP;

    ngOnInit(): void {
    }
}