ng1App.controller('doctor.patient.erx_pr.ViewCtrl', 
    ['$scope', '$routeParams', 'ErxPaths', 'ErxIntegration', '$location', 'ErxHistoryPaths',
     function ($scope, $routeParams, ErxPaths, ErxIntegration, $location, ErxHistoryPaths) {

    $scope.prescriptionViewFormOptions = {
        // route to return to from the view prescription form
        getReturnUrl: function() {
            return ErxPaths.list();
        },
        // the prescription id
        prescriptionId: $routeParams.prescriptionId,
        // the result of signing. This option should only be passed on return from prescription sign page
        // the string value of this parameter is set by a function in the sign page
        signStatus: $routeParams.signStatus,
        // implement this function to redirect to the page with the sign-prescription directive (for the same prescription)
        redirectToSignPage: function() {
            $location.path($location.path() + '/sign/');
        },
        // implement this function to redirect to the page with the sign-prescription directive (for the same prescription) and pass docIds
        redirectToSignPageWithDocIds: function(docIds) {
            $location.path($location.path() + '/sign/').search('docIds', docIds);
        },
        // path to edit prescription form by id (for the same encounter)
        getEditPrescriptionUrl: function(prescriptionId){
            return ErxPaths.edit(prescriptionId);
        },
        // redirect to another view prescription form for the same encounter with hideDocInfo = false
        redirectToViewPrescriptionDoc: function(prescriptionId){
            $location.url(ErxPaths.viewDoc(prescriptionId));
        },
        // redirect to another view prescription form for the same encounter with hideDocInfo = true
        redirectToViewPrescriptionForm: function(prescriptionId){
            $location.url(ErxPaths.view(prescriptionId));
        },
        // redirect to a page with erx-edit-prescription-doc directive for the same encounter
        redirectToEditPrescriptionDoc: function(prescriptionId){
            $location.path(ErxPaths.editDoc(prescriptionId));
        },
        // the view prescription form has 2 modes - with hidden document info and visible
        // which one should be set depends on where the user navigates from
        // for example, erx-prescription-list has 2 configurable functions that should return
        // paths to the same view prescription form with different hideDocInfo values
        hideDocInfo: $routeParams.hideDoc == 1 || !$routeParams.hideDoc ? true : false,
        //returns integration object with getters for classifier values and displayGlobalMessage function
        getIntegrationData: ErxIntegration.getIntegrationDataObject,
        redirectToViewDsp: function (patientId, dispenseId) {
            $location.url(ErxHistoryPaths.viewPatDsp(patientId, dispenseId));
        },
        getCompositionId: function(){
            return $routeParams.documentId;
        }
    };



}]);