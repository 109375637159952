ng1App.directive('sveidraDepartments', 
    ['SveidraListSubForm', 'TypeaheadSearchHandlers', 'SveidraApiFactory',
     function (SveidraListSubForm, TypeaheadSearchHandlers, SveidraApiFactory) {
	
	// Departments
	var initDepartment = function (controller) {
		controller._data.departmentFilter = null;
        try {
            controller._data.errorsas = 'AR VEIKIA?';
            controller._data.departments = SveidraApiFactory.getDepartments();
        } catch (e) {
            controller._data.errorsas = 'KAŠKAS BLOGAI';
            controller._data.errorsas2 = e;
        }
	};
	
	// Service
	var initService = function (controller) {
		controller._data.serviceSearhParam = null;
		
		controller.searchForService = TypeaheadSearchHandlers.HEALTH_SERVICE;
		
		controller.changeService = function (service) {
			if (service) {
				if (service.code) {
					this._form.service = service;
				}
			} else {
				this._form.service = null;
			}
		};
	};
	
	// Diagnosis
	var initDiagnosis = function (controller, diagnoses) {
		controller._data.diagnosisFilter = null;
		controller._data.diagnoses = diagnoses;
	};
	
	// Practitioner
	var initPractitioner = function (controller) {
		controller._data.practitionerSearchParam = null;
		controller.searchForPractitioner = TypeaheadSearchHandlers.PRACTITIONERS;
		
		controller.changePractitioner = function (practitioner) {
			if (practitioner) {
				if (practitioner.id) {
					this._form.practitionerId = practitioner.id;
					this._form.practitioner = practitioner;
				}
			} else {
				this._form.practitionerId = null;
				this._form.practitioner = null;
			}
		};
	};
	
	// Compensation
	var initCompensation = function (controller) {
		controller._data.compensationType = 'N';
		
		controller.compensationTypeChanged = function () {
			this._form.baseCostCompensationPercentage = null;
			this._form.paidBedDays = null;
			
			if (this._data.compensationType == 'BC') {
				this._form.baseCostCompensationPercentage = 0;
			}
		};
		
		controller._data.baseCostCompensations = [
		    {value: 0, label: '0%'},
		    {value: 30, label: '30%'},
		    {value: 50, label: '50%'},
		    {value: 100, label: '100%'},
		    {value: 200, label: '200%'}
		];
	};
	
	var clearAdditionalData = function (controller) {
        controller._data.departmentFilter = null;
        controller._data.serviceSearhParam = null;
        controller._data.diagnosisFilter = null;
        controller._data.practitionerSearchParam = null;
        controller._data.compensationType = 'N';
	};
	
	var resolveDataForEdit = function (form, data) {
		// Department
		for (var i = 0; i < data.departments.length; i++) {
			if (form.department.id == data.departments[i].id) {
				form.department = data.departments[i];
				break;
			}
		}
		
		// Service
		data.serviceSearhParam = _.getCodeName(form.service);
		
		// Diagnosis
		for (var i = 0; i < data.diagnoses.length; i++) {
			if (form.diagnosis.code == data.diagnoses[i].diagnosis.code) {
				form.diagnosis = data.diagnoses[i].diagnosis;
				break;
			}
		}
		
		// Practitioner
		data.practitionerSearchParam = _.getFullName(form.practitioner) + ' - ' + form.practitioner.stampCode;
		
		// Compensation type
		if (form.baseCostCompensationPercentage) {
			data.compensationType = 'BC';
		} else if (form.paidBedDays) {
			data.compensationType = 'BD';
		}
	};
	
	var initForm = function (scope) {
		scope.formCtrl = new SveidraListSubForm(scope.stage.departments, 'departments');
		
		initDepartment(scope.formCtrl);
		initService(scope.formCtrl);
		initDiagnosis(scope.formCtrl, scope.diagnoses);
		initPractitioner(scope.formCtrl);
		initCompensation(scope.formCtrl);
		
		scope.formCtrl.before = function (action) {
            if (action == 'cancel') {
                this.clear();
                clearAdditionalData(this);
            }
        };
        
        scope.formCtrl.after = function (action) {
        	if ((action == 'add' || action == 'save') && !this._validator.hasErrors()) {
        		clearAdditionalData(this);
        	} else if (action == 'edit') {
        		resolveDataForEdit(this._form, this._data);
        	}
        };
		
        scope.formCtrl.validateValue = function (form, validator) {
			if (!form.department || !form.department.id) {
				validator.add('department');
			}
			if (!form.from) {
				validator.add('from');
			}
			if (!form.service) {
				validator.add('service');
			}
			if (!form.diagnosis){
				validator.add('diagnosis');
			}
			if (!form.practitionerId){
				validator.add('practitioner');
			}
    	};
		
		scope.formCtrl.init();
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/departments.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '=',
			diagnoses: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			scope.stage.$promise.then(function () {
				scope.$watch('diagnoses', function (newValue) {
					if (newValue.$promise) {
						newValue.$promise.then(function () {
							initForm(scope);
						});
					}
				});
			});
		}
	};
}]);