import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, forkJoin } from "rxjs";
import { ApiV2AccountSettings } from "../../../../api/api-v2-account-settings";
import { ApiV2Alerts } from "../../../../api/api-v2-alerts";
import { ApiV2Links } from "../../../../api/api-v2-links";
import { ApiV2Reports } from "../../../../api/api-v2-reports";
import { RouteParams } from "../../../ng1.routeParams";
import { PatientModel } from "../../../shared/models/patient.model";
import { ApiV2Encounters } from "../../../shared/services/api-v2-encounters";
import { ApiV2PatientSummary } from "../../../shared/services/api-v2-patient-summary";
import { ApiV2Patients } from "../../../shared/services/api-v2-patients";
import { PatientStatusModel } from "../../models/patient-status.model";
import { DpSessionContext } from "../../services/dp-session-context.service";
import {DocumentTypeSelectService} from "../../services/document-type-select.service";

@Component({
    selector: "dp-patient-context",
    templateUrl: "patient-context.component.html",
})
export class DpPatientContextComponent implements OnInit, OnDestroy {
    menu: any[];
    showReport: boolean;
    unreadCount: number;
    isQualificationActive: (q: any) => boolean;
    updateActiveQualification: (q: any) => void;
    reportClass: string;
    @Input() iprUrl: string;
    @Input() token: any;
    @Input() patient: PatientModel;

    patientId: string;
    settings: any;
    allowedTypes: any;
    allowedCerts: any;
    hasActiveEncounter: boolean;
    encounter: any;
    modalIsLoading: boolean;

    patientPhone: string;
    patientEmail: string;
    additionalData: string[];
    links: string[];
    patientStatus: PatientStatusModel;

    public readonly encounterData$ = this.dpContext.encounterData$;

    private subscription: Subscription = new Subscription();

    constructor(
        private apiV2Alerts: ApiV2Alerts,
        private apiV2AccountSettings: ApiV2AccountSettings,
        private apiV2Links: ApiV2Links,
        private apiV2Reports: ApiV2Reports,
        private dpContext: DpSessionContext,
        private apiV2Encounters: ApiV2Encounters,
        private apiV2Patients: ApiV2Patients,
        private apiV2PatientSummary: ApiV2PatientSummary,
        private route: RouteParams,
        private router: Router,
        private documentTypeSelectService: DocumentTypeSelectService
    ) {}

    ngOnInit(): void {
        this.patientId = this.route.patientId;

        this.documentTypeSelectService.openModal$.subscribe((encounter) => {
            this.onModalOpen(encounter);
        });

        if (this.patientId) {
            this.apiV2Patients.getPatient(this.patientId).subscribe((data) => {
                this.dpContext.setPatient(data);
                this.dpContext.getLatestEncounter();
                this.patient = data;
                this.dpContext.encounterData$.subscribe((data) => (this.encounter = data));
            });
            this.apiV2PatientSummary
                .getPatientStatus(this.patientId)
                .subscribe((res: PatientStatusModel) => (this.patientStatus = res));
        }

        this.menu = [
            {
                text: "dp.nav.current.menu.records",
                url: "/dp/records",
                show: () => {
                    return this.acRead("MyDocumentList");
                },
            },
            {
                text: "dp.nav.current.menu.patients",
                url: "/dp/patients",
                show: () => {
                    return (
                        this.acRead("AllPatientList") ||
                        this.acRead("OrganizationPatientList") ||
                        this.acRead("OrganizationTodayPatientList") ||
                        this.acRead("MyTodayPatientList") ||
                        this.acRead("MyPatientList")
                    );
                },
            },
        ];

        this.reloadUnreadCount();
        this.getIPR();
        this.checkQualification();
        this.getDoctorInfo();
        this.token = { token: "" };

        this.subscription.add(this.subscribeEncounterData());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    acRead(permission: string): boolean {
        // TODO: Implement acRead logic here
        return true; // Placeholder return value
    }

    reloadUnreadCount(): void {
        this.apiV2Alerts.getUnreadCount().subscribe((data) => {
            this.unreadCount = data.unread;
        });
    }

    onReportsClick(event: Event): void {
        const leftOffset = (event.target as HTMLElement).getBoundingClientRect().left;
        this.reportClass = window.innerWidth - leftOffset > window.innerWidth / 2 ? "" : "dropdown-to-left";
    }

    updateUnreadMsgCount(data: any): void {
        if (data) {
            this.unreadCount = data;
        } else {
            this.reloadUnreadCount();
        }
    }

    getIPR(): void {
        this.apiV2Links.getIPR().subscribe((result) => {
            this.iprUrl = result.value;
        });
    }

    checkQualification(): void {
        this.apiV2AccountSettings.get().subscribe((settings: any) => {
            const activeQualification = settings.active_qualification;
            this.isQualificationActive = (q: any) => activeQualification === q.licenseCode;
            this.updateActiveQualification = (q: any) => {
                const setting = { active_qualification: q.licenseCode };
                this.apiV2AccountSettings.save(setting).subscribe(() => {
                    settings.active_qualification = q.licenseCode;
                });
            };
        });
    }

    getDoctorInfo(): void {
        if (!this.showReport) {
            this.apiV2Reports.getDoctorInfo().subscribe((res) => {
                if (res && res.isFamilyDoctor) {
                    this.showReport = res.isFamilyDoctor;
                }
            });
        }
    }

    onModalOpen(encounter?: any) {
        this.modalIsLoading = true;
        const patientIdQuery = { patientId: this.patient.id };

        this.dpContext.encounterData$.subscribe((data) => {
            this.encounter = encounter ? encounter : data;
            if (this.encounter) {
                forkJoin([
                    this.apiV2Encounters.getAllowedDocumentTypes(this.encounter?.id, patientIdQuery),
                    this.apiV2Encounters.getAllowedCertificatesTypes(this.encounter?.id, patientIdQuery),
                ]).subscribe(([allowedTypesData, allowedCertsData]) => {
                    this.allowedTypes = allowedTypesData;
                    this.allowedCerts = allowedCertsData;
                    this.modalIsLoading = false;
                });
            }
        });
    }

    isInsured(): boolean {
        return this.patientStatus?.indicators?.insured;
    }

    navigateToPage(path: string) {
        this.router.navigate([`dp/patients/${this.patientId}/${path}`]);
    }

    public openNewVisit() {
        this.router.navigate([`dp/patients/${this.patientId}/encounters/new`]);
    }

    private subscribeEncounterData(): Subscription {
        return this.dpContext.encounterData$.subscribe((encounterData) => {
            this.encounter = encounterData;
            this.hasActiveEncounter = encounterData !== undefined && encounterData !== null;
        });
    }
}
