ng1App.controller('document.E025EditCtrl',
    ['$log', '$scope', '$rootScope', 'EntitiesApiFactory', 'PatientsApiFactory', 'MessageService', 'TypeaheadSearchHandlers', 'DocumentModificationActions', '$filter', 'CertificateService',
     function ($log, $scope, $rootScope, EntitiesApiFactory, PatientsApiFactory, MessageService, TypeaheadSearchHandlers, DocumentModificationActions, $filter, CertificateService,) {
    MessageService.clearAll() ;
    if($scope.form.partAllergies){
        $scope.currentAllergies = $scope.form.partAllergies.allergies;
    } else {
        $scope.currentAllergies = [];
    }
    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(17);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'};
    $scope.currentDiagnoses = $scope.form.partDiagnosis;
         $scope.newRisks = $scope.form.partRiskFactors;
    // ============================= NEW LOGIC ============================
    $scope.types = {
        diagnosisTypes: EntitiesApiFactory.getList({classCode: 'condition-disease-type'}),
        orderTypes: EntitiesApiFactory.getList({classCode: 'order-type', count: 100}, function(res){
            for(var i = 0; i<res.length;i++){
                var code = res[i].code;
                if(['1','2','3'].indexOf(code) == -1 ){
                    res.splice(i--,1);
                }
            }
        }),
        sampleCollMethods: EntitiesApiFactory.getList({classCode: 'specimen-collection-method'}),
        prohibitAlertTypes: EntitiesApiFactory.getList({classCode: 'prohibit-alert-type'})
    };

    $scope.handleValidationFailure = function(isValid) {
        $scope.diagnosisValid = isValid;
    };

    $scope.handleDiagnosesChange = function(event) {
        MessageService.clear('diagnosis-region');
        $scope.currentDiagnoses = event;
        $scope.form.partDiagnosis = event;
    };

    $scope.handleAllergiesChange = function(event) {
        $scope.currentAllergies = event;
        $scope.form.partAllergies.allergies = event;
    };

    $scope.handleRiskChange = function(event) {
        $scope.newRisks = event;
        $scope.form.partRiskFactors = event;
    };

    $scope._shared.customValidator = function(documentForm, skipRequired) {
        // Logging the state of documentForm at the beginning of the function
        //console.log("customValidator: documentForm at start", documentForm);

        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        // Logging the result of validateFormSimple
        //console.log("customValidator: Result from validateFormSimple", res);

        MessageService.clear('diagnosis-region');
         if (!$scope.form.partDiagnosis || $scope.form.partDiagnosis.length == 0) {
            //console.log("$scope.diagnosisValid",$scope.diagnosisValid);
            MessageService.add('error', 'Nenurodyta nė viena diagnozė.', 'diagnosis-region');
            // Logging diagnosis validation failure
            //console.log("customValidator: Diagnosis validation failed. No diagnoses found.");

            if (res) {
                // _.scrollTo('[msg-region="diagnosis-region"]');
                //_.scrollTo('app-diagnoses-form');
                _.scrollTo('#diagnoses');
                // Logging that the page will scroll to the diagnosis region
                //console.log("customValidator: Scrolling to diagnosis-region");
            }
            res = false;
        }

        // Logging the final result of the custom validation
        //console.log("customValidator: Final result of validation", res);

        return res;
    };

    $scope.clear = function () {
      MessageService.clearAll() ;
      $scope.documentCancelReason = {} ;
    };

    $scope.form.$promise.then(function () {
    	CertificateService.prefillDocumentDiagnosis($scope.form, $scope);
    });

    $scope.isAdvancedPharmacist = $rootScope.currentUser.roleCode == 'advanced_pharmacist';
}]);
