import medvaisApiModule from "./medvais-api";'./medvais-api';

import './medvais-api-imaging-study';
import './medvais-api-media';
import './medvais-api-order';
import './medvais-api-medvais';
import './medvais-api-patient';
import './medvais-api-document';
import './medvais-api-encounter';
import './medvais-api-classifier';
import './medvais-api-organization';
import './medvais-api-practitioner';

export default medvaisApiModule;