import {
    AdditionalSpecialistConsultation,
    FetusMovements,
    FetusPathology,
    FetusPosition,
} from "../components/outpatient-visit-description/outpatient-visit-description.constants";
import { OutpatientVisitDescriptionModel } from "../models/outpatientVisitDescription.model";

export const outpatientVisitDescriptionDataMock: OutpatientVisitDescriptionModel[] = [
    {
        womanAmbulatoryDescription: {
            assessmentDate: new Date("2023-09-07"),
            isComplaints: true,
            complaints: "Kita",
            weight: "78",
            bloodPressureLeftUp: "120",
            bloodPressureLeftDown: "80",
            bloodPressureRightUp: "130",
            bloodPressureRightDown: "70",
            pulse: "125",
            temperature: "36.5",
            gda: "15",
            childCount: "2",
            recommendations: "Bla",
            appointmentsTreatment: "Kitas",
        },
        fetusDescription: [
            {
                letter: "A",
                heartbeat: "120",
                movements: FetusMovements.Sufficient,
                pathology: FetusPathology.Head,
                position: FetusPosition.Longitudinal,
            },
            {
                letter: "B",
                heartbeat: "125",
                movements: FetusMovements.DoesntFeel,
                pathology: FetusPathology.Others,
                position: FetusPosition.Volatile,
            },
        ],
        maintenancePlan: [
            // {
            //     additionalSpecialistConsultation: AdditionalSpecialistConsultation.Level,
            //     periodFrom: "<11",
            //     periodTo: "28",
            //     note: "BlaBla",
            // },
            // {
            //     additionalSpecialistConsultation: AdditionalSpecialistConsultation.Code2012,
            //     periodFrom: "28",
            //     periodTo: "40",
            //     note: "Pastaba kla",
            // },
        ],
        id: "12345679781",
    },
    {
        womanAmbulatoryDescription: {
            assessmentDate: new Date("2023-09-07"),
            isComplaints: true,
            complaints: "Kita",
            weight: "78",
            bloodPressureLeftUp: "120",
            bloodPressureLeftDown: "80",
            bloodPressureRightUp: "130",
            bloodPressureRightDown: "70",
            pulse: "125",
            temperature: "36.5",
            gda: "15",
            childCount: "2",
            recommendations: "Bla",
            appointmentsTreatment: "Kitas",
        },
        fetusDescription: [
            {
                letter: "A",
                heartbeat: "120",
                movements: FetusMovements.Sufficient,
                pathology: FetusPathology.Head,
                position: FetusPosition.Longitudinal,
            },
            {
                letter: "B",
                heartbeat: "125",
                movements: FetusMovements.DoesntFeel,
                pathology: FetusPathology.Others,
                position: FetusPosition.Volatile,
            },
        ],
        maintenancePlan: [
            {
                additionalSpecialistConsultation: AdditionalSpecialistConsultation.Level,
                periodFrom: "<11",
                periodTo: "28",
                note: "BlaBla",
            },
            {
                additionalSpecialistConsultation: AdditionalSpecialistConsultation.Code2012,
                periodFrom: "28",
                periodTo: "40",
                note: "Pastaba kla",
            },
        ],
        id: "12345679781",
    },
];
