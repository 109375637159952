ng1App.controller('doctor.patient.erx.PatientHistoryViewDspCtrl', 
    ['$scope', '$routeParams', 'ErxHistoryPaths', '$location',
     function ($scope, $routeParams, ErxHistoryPaths, $location) {

    $scope.erxPatientHistoryViewDspOptions = {
        // Viewed dispense id
        getDispenseId: function () {
            return  $routeParams.id;
        },
        // redirect to page with erx-view-prescription-doc
        redirectToViewPrsDoc: function (patientId, prescriptionId) {
            $location.url(ErxHistoryPaths.viewPrsDoc2(patientId, prescriptionId));
        }

    };

}]);