import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { map } from "rxjs";
import { RouteParams } from "src/app/ng1.routeParams";
import { EntityModel } from "src/app/shared/models/entity.model";
import { DirectiveReset } from "src/app/shared/services/directive-reset";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";

@Component({
    selector: 'dp-doc-ref-110-GMP',
    templateUrl: './doc-ref-110-GMP-form.component.html'
})
export class DpDocRef110GMPComponent implements OnInit {

    backPath: string;
    patientId = null;
    currentDate = new Date();
    statusValue: string;
    emitedValues = null;
    institutionValue = null;
    surgicalArray = null;
    diagnosisArray = null;
    documentTag = DocumentTagEnum.e027a
    XMLfileSelected: boolean = false;
    PDFfileSelected: boolean = false;
    XMLfileName: string = '';
    PDFfileName: string = '';
    XMLformData = new FormData;
    PDFformData = new FormData;

    prefillData: any;

    documentType: EntityModel = {
        id: "4",
        code: "4",
        name: "e110"
    };

    constructor(
        private params: RouteParams,
        private fb: FormBuilder,
        private directiveReset: DirectiveReset,
        private apiV2Documents: ApiV2Documents,
    ) { }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/e110/list`;
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    onChanges(event) {
        this.emitedValues = event;
    }

    onInstitutionChanges(event) {
        this.institutionValue = event;
    }

    onDiagnosisChanges(event) {
        this.diagnosisArray = event;
    }

    onSurgicalChanges(event) {
        this.surgicalArray = event;
    }

    onXMLFileButtonClick() {
        // Trigger the file input click event programmatically
        const fileInput = document.getElementById('XMLfileInput') as HTMLInputElement;
        fileInput.click();
    }

    onXMLFileSelected(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            // Check if the file is an XML file
            if (file.type === 'application/xml' || file.type === 'text/xml') {
                this.XMLfileName = file.name;
                this.XMLformData.append('file', file);
                this.XMLfileSelected = true;
            }
        }
    }

    // onXMLFileSelected(event: any){
    //     if(event.target.files.length > 0){
    //       const file = event.target.files[0];
    //         this.XMLfileName = file.name;
    //         this.XMLformData.append('file', file);
    //         this.XMLfileSelected = true;
    //     }
    // }

    // onXMLFileRemove() {
    //   this.XMLfileSelected = false;
    //   this.XMLfileName = '';
    // }

    onPDFFileButtonClick() {
        // Trigger the file input click event programmatically
        const fileInput = document.getElementById('PDFfileInput') as HTMLInputElement;
        fileInput.click();
    }

    // onPDFFileSelected(event: any) {
    //     if (event.target.files.length > 0) {
    //         const file = event.target.files[0];
    //         this.PDFfileName = file.name;
    //         this.PDFformData.append('file', file);
    //         this.PDFfileSelected = true;
    //     }
    // }

    onPDFFileSelected(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.type === 'application/pdf') {
                this.PDFfileName = file.name;
                this.PDFformData.append('file', file);
                this.PDFfileSelected = true;
            }
        }
    }

    // onPDFFileRemove() {
    //   this.PDFfileSelected = false;
    //   this.PDFfileName = '';
    // }

    public saveForm(): void {
        //var prefillJson = this.gatherInfoFromEmittedData();
        //const mergeJSON= {...formValues,...prefillJson};
        var prefillJson = this.gatherInfoFromEmittedData();
        const surgicalProcedures = this.surgicalArray;
        const institution = this.institutionValue;
        const diagnosis = this.diagnosisArray;
        const fileCreatedTime = new Date();
        const mergeJSON = { ...prefillJson, institution, diagnosis, surgicalProcedures, fileCreatedTime };
        console.log(mergeJSON);
        //this.ApiV2F090.savef090Form(mergeJSON, this.patientId).subscribe();
    }

    public submitForm(): void {
        //const formValues = { ...this.form.value };
        //var prefillJson = this.gatherInfoFromEmittedData();
        //const mergeJSON= {...formValues,...prefillJson};
        //delete formValues.confirmedDiagnosis;
        //this.ApiV2F090.submitf090Form(mergeJSON, this.patientId).subscribe();
    }

    public signingSubmitForm(): void {
        //const formValues = { ...this.form.value };
        //var prefillJson = this.gatherInfoFromEmittedData();
        //const mergeJSON= {...formValues,...prefillJson};
        //delete formValues.confirmedDiagnosis;
        //this.ApiV2F090.signingSubmitf090Form(mergeJSON, this.patientId).subscribe();
    }

    public sealConfirmForm(): void {
        //const formValues = { ...this.form.value };
        //var prefillJson = this.gatherInfoFromEmittedData();
        //const mergeJSON= {...formValues,...prefillJson};
        //delete formValues.confirmedDiagnosis;
        //this.ApiV2F090.sealf090Form(mergeJSON, this.patientId).subscribe();
    }

    public signForm(): void {
        //const formValues = { ...this.form.value };
        //var prefillJson = this.gatherInfoFromEmittedData();
        //const mergeJSON= {...formValues,...prefillJson};
        //delete formValues.confirmedDiagnosis;
        //this.ApiV2F090.signf090Form(mergeJSON, this.patientId).subscribe();
    }

    public clearForm(): void {
        // Trigger the reset in the service
        this.XMLfileSelected = null;
        this.PDFfileSelected = null;
        this.directiveReset.triggerReset();
    }

    // cleanMethodOfConfirmingDiagnosis(){
    //   return (<FormArray> this.form.get('methodOfConfirmingDiagnosis')).controls = [];
    // }

    gatherInfoFromEmittedData() {
        var encounterRef = this.emitedValues.encounter.fullId;
        var patientRef = this.emitedValues.encounter.patientRef;
        var patientData = this.emitedValues.encounter.patientData;
        var authorRef = this.emitedValues.item.practitionerRef;
        var authorData = this.emitedValues.practitioner;
        var organizationRef = this.emitedValues.item.organizationRef;
        var organizationData = this.emitedValues.item.organization;
        var cardName = "110/a GMP kortelė";
        const combinedJSON = {
            encounterRef: encounterRef,
            patientRef: patientRef,
            patient: patientData,
            authorRef: authorRef,
            author: authorData,
            custodianRef: organizationRef,
            custodian: organizationData,
            cardName: cardName,
            documentType: this.documentType
        }
        return combinedJSON;
    }
}