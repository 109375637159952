<div class="row req">
    <div class="col-6 col-lg-3">
        <app-select
            class="form-group flex-shrink-1"
            labelText="doc.pregnancyDashboard.e096Ge.formAntibiotics.activeSubstance"
            id="ge-activeSubstance"
            [fControl]="substanceGroup.get('activeSubstance')"
            [showErrors]="true"
            [values]="selectionValuesFromEntity(substancesList)"
        ></app-select>
        <app-input
            type="text"
            class="form-group flex-shrink-1"
            labelText="doc.pregnancyDashboard.e096Ge.formAntibiotics.dose"
            [showErrors]="true"
            [fControl]="substanceGroup.get('dose')"
        ></app-input>
        <app-input
            type="text"
            class="form-group flex-shrink-1"
            labelText="doc.pregnancyDashboard.e096Ge.formAntibiotics.numberOfDoses"
            [showErrors]="true"
            [fControl]="substanceGroup.get('numberOfDoses')"
        ></app-input>
        <button *ngIf="editable" class="btn btn-outline-primary mx-auto mt-4 d-block" [disabled]="substanceGroup?.invalid || !editable" (click)="addSubstance()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    </div>
    <div class="col-6 col-lg-9">
        <div class="border-start ms-2 h-100 px-2">
            <div
                *ngFor="let substance of substances?.value; let i = index" [class.mt-3]="i !== 0"
                [class.border-bottom]="i + 1 !== substances?.length"
                [class.bg-primary-subtle]="i === editSubstanceId"
                [style.width]="'fit-content'"
                class="border-light p-2 w-100"
            >
                <div class="fw-bold">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.activeSubstance' | translate}}: </div>
                <span>{{substance.activeSubstance}}</span>
                <div class="mt-2 fw-bold">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.dose' | translate}}:</div>
                <span>{{substance.dose}}</span>
                <div class="mt-2 fw-bold">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.numberOfDoses' | translate}}:</div>
                <span>{{substance.numberOfDoses}}</span>

                <div
                    *ngIf="editable"
                    class="mt-2 d-flex"
                >
                    <button class="btn btn-block text-primary text-capitalize" (click)="editSubstance(i)"><i class="fa fa-pencil"></i>&nbsp;{{'doc.dia.edt' | translate}}</button>
                    <button class="btn btn-block text-primary" (click)="delete(i)"><i class="fa fa-trash"></i>&nbsp;{{'doc.dia.dlt' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>