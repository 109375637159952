import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {MaterialDataModel} from "../../../models/material-data.model";
import {Environment} from "../../../../../environments/environment-properties";
import PortalType = Environment.PortalType;
import {EnvironmentSettings} from "../../../../../environments/env";
import {ApiV2PharmMedications} from "../../../services/api-v2-pharm-medications";
import {ApiV2ErxMedications} from "../../../../../api/api-v2-erx-medications";


@Component({
    selector: "safety-and-education-data",
    templateUrl: "./safety-and-education-data.component.html",
})
export class SafetyAndEducationDataComponent implements OnInit, OnChanges {

    @Input() params: any;
    materialData: MaterialDataModel[] = [];

    constructor(private apiV2PharmMedications: ApiV2PharmMedications,
                private apiV2ErxMedications: ApiV2ErxMedications) {
    }

    ngOnInit(): void {

    }

    getData() {
        if (EnvironmentSettings.getPortalType() === PortalType.PHARMACIST) {
            this.apiV2PharmMedications.getMedicationMaterials(this.params).subscribe(d => {
                this.materialData = d;
            })
        } else if (EnvironmentSettings.getPortalType() === PortalType.DOCTOR) {
            this.apiV2ErxMedications.getMedicationMaterials(this.params).subscribe(d => {
                this.materialData = d;
            })
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params) {
            changes.params.currentValue !== undefined ? this.getData() : this.materialData = [];
        }
    }

    onDocumentClick(link: any) {
        window.open(link, '_blank');
    }
}