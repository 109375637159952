<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngIf="medicalRecommendations !== undefined">
        <span class="mb-0">
            <small>
                {{ medicalRecommendations.date }}
            </small>
        </span>
        <span class="vr"></span>
        <span class="mb-0">
            <small>
                {{ medicalRecommendations.documentType }}
            </small>
        </span>
        <div class="mb-0">
            {{ medicalRecommendations.description }}
        </div>
    </li>
</nhr-record-widget>