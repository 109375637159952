ng1App.controller('doctor.patient.CertificatesDraftEditCtrl', 
    ['$scope', '$routeParams', 'CertificatesApiFactory', 'CertificateService', '$window', '$location', 'DocumentSpinnerHelper', 'MessageService', '$filter', '$modal', '$log', 'CertificatesViewService', 'DraftsApiFactory',
     function ($scope, $routeParams, CertificatesApiFactory, CertificateService,
                                                                        $window, $location, DocumentSpinnerHelper, MessageService, $filter, $modal, $log, CertificatesViewService, DraftsApiFactory) {
	MessageService.clear();
	DocumentSpinnerHelper.reset();

	$scope.certificate = DraftsApiFactory.getDraftForPreview({id: $routeParams.id}, function (data) {
    	CertificateService.populateCommonData($scope, data);
    	
    	// Additional data
		CertificatesViewService.fillDataByType($scope, data.type, $routeParams.patientId, $routeParams.id);
    });
    
    $scope.goBack = function () {
//    	$window.history.back();
    	// For now return to records
    	$location.path('/dp/patients/' + $routeParams.patientId + '/records').search({});
    };
    
    $scope.goToEdit = function () {
    	$location.path($scope.editUrl).search({});
    };
    
}]);