<!-- <h4 class="modal-title fs-5" id="dpp.rea.res.mod.fil">{{'dp.rec.mod.fil' | translate}}</h4>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-sm-6 col-xl-3">
            <div class="form-group">
                <strong class="form-label">Nuo</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateForm" [fControl]="form?.get('dateFrom')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-3">
            <div class="form-group">
                <strong class="form-label">Iki</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateTo" [fControl]="form?.get('dateTo')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-6">
            <div class="form-group">
                <strong class="form-label">Gavėjo pavadinimas</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input formControlName="organization" typeaheadOptionField="organization"
                            [typeahead]="organizationSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectOrganization($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos pavadinimą" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-4">
            <div class="form-group">
                <strong class="form-label">Specialisto vardas, pavardė</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="practitioner" formControlName="practitioner"
                            typeaheadOptionField="practitioner"
                            [typeahead]="practitionerSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectPractitioner($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos Specialisto pavardę"
                            class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-4">
            <div class="form-group">
                <strong class="form-label">Pacientas</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="patient" formControlName="patient" typeaheadOptionField="patient"
                            [typeahead]="patientSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectPatient($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos P pavardę" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-4">
            <div class="form-group">
                <strong class="form-label">ASPN</strong>
                <div class="w-100">
                    <select id="category" class="form-select" formControlName="aspnType">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>

                        <option *ngFor="let aspnType of aspnTypes" [value]="aspnType.code">
                            {{aspnType.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="d-flex justify-content-end gap-2">
    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="searchAdvanced()">{{'search' |
        translate}}</button>
    <button class="btn btn-secondary" (click)="clear()">{{ 'clean' | translate }}</button>
</div>
