angular.module('erx.api').factory('ErxClassifiersApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Classifiers';

  return $resource('', {},
    {
      getMedicationPrescriptionTags: {
          method: 'GET',
          url: URL_BASE + '/medication-prescription-tag',
      },
      getDivisiblePharmForms: {
          method: 'GET',
          url: URL_BASE + '/divisible-pharm-forms',
          transformResponse: function (data) {return {list: angular.fromJson(data)} }
      },
      getPrcPharmFormMeasureUnits: {
          method: 'GET',
          url: URL_BASE + '/prc-pharm-form-measure-unit',
          isArray: true
      },
      getPharmFormMeasureUnitsByPFCode: {
          method: 'GET',
          url: URL_BASE + '/pharm-form-measure-unit?code=:code',
          isArray: true
      }
      
    });
}]);

