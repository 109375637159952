import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ApiV2AttachedUnsorted } from "src/api/api-v2-doc-ref-attached-unsorted";
import { ApiV2DocRefConsent } from "src/api/api-v2-doc-ref-consent";
import { RouteParams } from "src/app/ng1.routeParams";
import { DocRefAttachedUnsortedModel } from "src/app/shared/models/doc-ref-attached-unsorted.model";
import { DocRefConsentFilterEnum } from "src/app/shared/models/doc-ref-consent-filter.enum";
import { FilterGroupModel } from "src/app/shared/models/filter-group.model";
import { FilterModel } from "src/app/shared/models/filter.model";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { PortalTypeEnum } from "src/app/shared/models/portalType.enum";
import { QueryModel } from "src/app/shared/models/query.model";
import { FilterService } from "src/app/shared/services/filter.service";
import { Pagination } from "../../list/pagination-bar/pagination-bar.constants";

@Component({
    selector: "doc-ref-attached-unsorted-list",
    templateUrl: "./doc-ref-attached-unsorted-list.component.html",
})
export class DocRefAttachedUnsortedListComponent implements OnInit {
    backPath: string;
    @Input() docRefConsents: DocRefAttachedUnsortedModel[];
    @Input() filters: FilterModel[];
    @Input() currentFilter: FilterModel;
    @Input() portalType: PortalTypeEnum;
    @Input() patientId: string;
    @Input() filtersGroup$: Observable<FilterGroupModel[]>;
    filterValue = "";
    working: boolean = false;
    count: number;
    pagesCount: number;
    page: number = 1;
    loadMoreActive = false;

    constructor(
        public apiV2DocRefConsent: ApiV2DocRefConsent,
        public apiV2DocRefAttachedUnsorted: ApiV2AttachedUnsorted,
        private router: Router,
        private params: RouteParams
    ) {}

    ngOnInit(): void {
        this.backPath = this.portalType;
        this.setFilters();
        this.setPredefinedFilters();
        this.loadDocRefConsentList(this.currentFilter.query);
        this.patientId = this.params.patientId;
    }

    loadDocRefConsentList(query?: any, add?: boolean) {
        //console.log("query aaaaaaaaaaa",Date.parse(query.dateFrom));
        //this.filterValue = query;
        this.apiV2DocRefAttachedUnsorted
            .getAttachedUnsortedList(query ? query : this.currentFilter.query)
            .subscribe((data: DocRefAttachedUnsortedModel[] | FilteredDataModel) => {
                const filteredData: FilteredDataModel = data as FilteredDataModel;
                FilterService.setFilterCount(this.filters, filteredData);
                //console.log("filteredData",filteredData);
                filteredData.items.forEach((d) => (d.type = "DocRefConsentModel"));
                if (add) {
                    filteredData.items.forEach((item) => this.docRefConsents.push(item));
                } else {
                    this.docRefConsents = [];

                    const rowNumberToLoad = (this.page - 1) * 10;
                    const repeating = rowNumberToLoad + 10;
                    const repeatingTill = repeating > filteredData.items.length ? filteredData.items.length : repeating;

                    for (let i = rowNumberToLoad; i < repeatingTill; i++) {
                        this.docRefConsents.push(filteredData.items[i]);
                    }
                }

                this.count = filteredData.items.length;
                this.loadMoreActive = this.count < filteredData.total;
                this.working = false;

                const pagesCount = filteredData.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
    }

    setFilters() {
        this.filtersGroup$ = this.apiV2DocRefConsent.getFilters();
    }

    setPredefinedFilters() {
        this.filters = FilterService.createFilters(DocRefConsentFilterEnum);
        if (this.filters) {
            this.currentFilter = this.filters.find((filter) => filter.title === DocRefConsentFilterEnum.TOTAL);
        }
    }

    canCreate(): boolean {
        return this.portalType === PortalTypeEnum.DP;
    }

    // createDocRefConsent() {
    //     this.router.navigate([this.portalType + '/doc-ref-consent/create']);
    // }

    onCardClick(id: string): void {
        this.router.navigate([this.portalType, "patients", this.patientId, "doc-unsorted", "view", id]);
    }

    onEditClick(id: string): void {
        console.log("edit");
        this.router.navigate([this.portalType, "patients", this.patientId, "doc-unsorted", "edit", id]);
    }

    loadMore() {
        this.working = true;
        this.page++;
        this.loadDocRefConsentList({ count: 5, page: this.page } as QueryModel, true);
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        this.loadDocRefConsentList({ count: pagination.count, page: pagination.page } as QueryModel);

        this.page = pagination.page;
    }
}
