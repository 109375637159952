import {DocumentModificationActions} from "./document-modification-service";

export abstract class DocumentOperationStatus {
    savePartial: boolean
    sign: boolean
    modify: boolean
    amend: boolean
}

export abstract class DocumentContext {

    abstract reset(): void;

    abstract setMode(mode): void;

    abstract setPatientId(patientId): void;

    abstract setDocumentType(documentType): void;

    abstract defineDocumentTypeSpecific(documentType): void;

    abstract setDocument(document): void;

    abstract setDocumentId(documentId): void;

    abstract getMode(): string;

    abstract getDocument(): any;

    abstract getDocumentType(): string;

    abstract getDocumentRealType(): string;

    abstract getDocumentStatus(): string;

    abstract getDocumentStatus2(): string;

    abstract getOperationsSupported(): DocumentOperationStatus;

    abstract canSaveDraft(): boolean;

    abstract isEditable(): boolean;

    abstract isAmendable(): boolean;

    abstract isSigned(): boolean;

    abstract isSubmitedForSign(): boolean;
}


export function documentContextFactory(i: any) {
    return i.get('DocumentCtx');
}

export const documentContextService = {
    provide: DocumentContext,
    useFactory: documentContextFactory,
    deps: ['$injector']
};

