angular.module('erx.utils').directive('erxUnits', function () {
	return {
		templateUrl: 'modules/erx-utils/components/erx-foreign/erx-units/erxUnits.html',
		restrict: 'E',
		replace: false,
		scope: {
			entity: '=',
			label: '='
		},
	};
});
