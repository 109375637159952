ng1App.controller('doctor.patient.F025alkEditCtrl', 
    ['$scope', '$window', '$routeParams', 'MessageService', 'SveidraApiFactory', '$routeSegment', '$modalConfirm', '$filter', 'EntitiesApiFactory', 'CommonFunctionService', 'SubFormListController', 'TypeaheadSearchHandlers', '$location', 'DocumentsApiFactory', 'DocumentSpinnerHelper', 'SveidraCommons', 'FormLoaderService', '$q',
     function ($scope, $window, $routeParams, MessageService, SveidraApiFactory, $routeSegment,
                                                              $modalConfirm, $filter, EntitiesApiFactory, CommonFunctionService, SubFormListController,
                                                              TypeaheadSearchHandlers, $location, DocumentsApiFactory, DocumentSpinnerHelper, SveidraCommons,
                                                              FormLoaderService, $q) {
    
	FormLoaderService.startFormLoading();
	
	$scope.$emit('setHelpPage', 'tvarkyti-korteles-duomenis');
    
    $scope.goBack = function(){
        $window.history.back();
    }; 
	
	MessageService.clearAll();
	
	$scope.mode = $routeParams.formId ? 'edit' : 'new';
	$scope.formId = $routeParams.formId;
	
	$scope.loadingSpinnerHelper = DocumentSpinnerHelper;
	
	if ($scope.mode == 'edit') {
		$scope.form = SveidraApiFactory.getFormForEdit({id: $scope.formId});
	} else {
		$scope.form = SveidraApiFactory.getPrefilledForm({patientId: $routeParams.patientId, type: 'FORM_F025', documentId: $routeParams.documentId});
	}
    
	// Load entities
	$scope.purposes = EntitiesApiFactory.getList({classCode: 'f025alk-purpose'});
	$scope.injuryCauses = EntitiesApiFactory.getList({classCode: 'injuries-cause'});
	$scope.defaultDiagnosisType = EntitiesApiFactory.getList({classCode: 'condition-disease-type', byCode: true});
	
	FormLoaderService.endFormLoading($q.all([$scope.form.$promise, $scope.purposes.$promise, $scope.injuryCauses.$promise, $scope.defaultDiagnosisType.$promise]));
	
	// Perform actions after form is loaded
	$scope.form.$promise.then(function () {
		fillData(true);
	});
	
	var fillData = function (reset) {
		if ($scope.form.purpose == null) {
			$scope.purposes.$promise.then(function () {
				$scope.form.purpose = $scope.purposes[0];
			});
		} else {
			CommonFunctionService.getTrueEntityValue($scope.form, 'purpose', $scope.purposes);
		}
		
		if (reset) {
			// Load diagnoses
			var diagnoses = DocumentsApiFactory.getDiagnoses({documentId: $scope.form.documentId});
		
			$scope.finalDiagnosesCtrl = buildFinalDiagnosesCtrl($scope.form.finalDiagnoses, 
															$scope.injuryCauses, 
															diagnoses, 
															$scope.defaultDiagnosisType);
		
			$scope.patientData = $scope.form.patient;
			$scope.authorData = $scope.form.author;
			$scope.organizationData = $scope.form.organization;
		}
	};
	
	$scope.save = function () {
		$scope.cardForm.submitted = true;
		if ($scope.cardForm.$invalid) {
			return;
		}
		
		$scope.loadingSpinnerHelper.startLoading();
		if ($scope.mode == 'edit') {
			SveidraApiFactory.updateForm({id: $scope.formId}, $scope.form, saveSuccessHandler, saveErrorHandler);
		} else {
			SveidraApiFactory.createForm({type: 'FORM_F025'}, $scope.form, saveSuccessHandler, saveErrorHandler);
		}
	};
	
	$scope.purposeChanged = function () {
		if ($scope.form.purpose.code == '3') {
			$scope.form.relatedDocument = null;
		} else if ($scope.form.purpose.code == '6') {
			var index = 0;
			while (index < $scope.form.finalDiagnoses.length) {
				if ($scope.form.finalDiagnoses[index].id) {
					$scope.form.finalDiagnoses[index].removed = true;
					index++;
            	} else {
            		$scope.form.finalDiagnoses.splice(index, 1);
            	}
			}
		}
	};
	
	var saveSuccessHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleSaveWithWarnings(result);
		if (result.withWarnings) {
			$scope.mode = 'edit';
			$scope.formId = result.id;
			$scope.form = SveidraApiFactory.getFormForEdit({id: $scope.formId}, function () {
				fillData(false);
			});
		} else {
			MessageService.registerSuccessEvent();
			$location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms').search({});
		}
	};
	
	var saveErrorHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleErrors(result, 'err.cer.025.sav');
	};
	
	$scope.clean = function () {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')}).then(function() {
        	$routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
	};
	
	// Final diagnoses edit controller
	var buildFinalDiagnosesCtrl = function (valueList, injuryCauses, diagnoses, defaultDiagnosisType) {
		var controller = new SubFormListController(valueList, 'final-diagnoses');
		controller._appendTop = true;
		controller._data.injuryDiagnosis = null;
		controller._data.diagnoses = diagnoses;
		controller._data.injuryCauses = injuryCauses;
		controller._data.defaultDiagnosisType = defaultDiagnosisType;
		controller.searchForDiagnosis = TypeaheadSearchHandlers.ICD10;
		controller.changeInjuryDiagnosis = function (diagnosis) {
			if (diagnosis) {
				if (diagnosis.id) {
					if (this._form.injuryDiagnosis == null) {
						this._form.injuryDiagnosis = {};
					}
					this._form.injuryDiagnosis.diagnosis = diagnosis;
				}
			} else {
				this._form.injuryDiagnosis = null;
			}
		};
		controller.validateForm = function (form) {
			this.clear();
			if (!form.diagnosis) {
				this._validator.add('diagnosis');
			}
			if (form.injuryCause && !form.injuryDiagnosis) {
				this._validator.add('injuryDiagnosis');
			}
            return !this._validator.hasErrors();
		};
        controller.before = function (action) {
            if (action == 'cancel') {
                this.clear();
            } else if (action == 'add' || action == 'save') {
            	if (this._form.injuryDiagnosis) {
            		// Add modification date (just for display)
            		this._form.injuryDiagnosis.date = new Date().getTime();
            		// Set default diagnosis type (just for display)
            		this._form.injuryDiagnosis.type = this._data.defaultDiagnosisType[0];
            	}
            }
        };
        controller.after = function (action) {
        	if (action == 'edit') {
        		// Resolve values
        		var _this = this;
        		_.each(this._data.diagnoses, function (d) {
        			if (_this._form.diagnosis.diagnosis.code == d.diagnosis.code) {
        				_this._form.diagnosis = d;
        			}
        		});
        		if (this._form.injuryCause) {
        			_.each(this._data.injuryCauses, function (c) {
        				if (_this._form.injuryCause.code == c.code) {
        					_this._form.injuryCause = c;
        				}
        			});
        		}
        		if (this._form.injuryDiagnosis) {
        			this._data.injuryDiagnosis = _.getCodeName(this._form.injuryDiagnosis.diagnosis);
        		}
        	} else if (action == 'add' || action == 'save') {
        		this._data.injuryDiagnosis = null;
        	}
        };
        controller.editValue = function (index) {
        	this.edit(index);
        	CommonFunctionService.gotoAnchor('edit');
        };
        controller.deleteValue = function (index) {
            this.before('delete');
            var _this = this;
            $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
            	if (_this._list[index].id) {
            		_this._list[index].removed = true;
            	} else {
            		var removed = _this._list.splice(index, 1);
            		_this.onDelete(removed[0]);
            		_this.after('delete');
            	}
            });
        };
        controller.init();
        return controller;
	};

}]);