import { Component, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {DpSessionContext} from "../../../doctor/services/dp-session-context.service";

@Component({
    selector: 'app-encounter-block',
    templateUrl: './encounter-block.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EncounterBlockComponent {
    encounterData: any;
    constructor(
        private dpController: DpSessionContext,
    ) {
        this.dpController.encounterData$.pipe(
            takeUntilDestroyed(),
        ).subscribe(res => {
            this.encounterData = res;
        })
    }
    getEncounterData = () => {
        const name = this.encounterData?.organizationData.name
        const jarCode = this.encounterData?.organizationData.jarCode ? 'JAR kodas ' + this.encounterData?.organizationData.jarCode : undefined
        const sveidra = this.encounterData?.organizationData.sveidraId ? 'SVEIDRA Nr. ' + this.encounterData?.organizationData.sveidraId : undefined
        const personalDetails = [
            this.encounterData?.practitionerData.speciality.name,
            this.encounterData?.practitionerData.givenName,
            this.encounterData?.practitionerData.familyName
        ].filter(a => !!a).join(' ')
        return [name, jarCode, sveidra, personalDetails].filter(a => !!a).join(', ')
    }
}
