import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-body-temp-form',
    templateUrl: './sbvf-body-temp-form.component.html',
})
export class SbvfBodyTempFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    bodyTempRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        roomTemperature: this.fb.control(null, [Validators.required]),
        clothing: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.bodyTempRows = [
            {
                title: 'Kambario temperatūra',
                type: 'choise',
                options: value['roomTemp'],
                formControl: this.formGroup.get('roomTemperature')
            },
            {
                title: 'Apranga',
                type: 'choise',
                options: value['clothing'],
                customClass: 'w-auto',
                formControl: this.formGroup.get('clothing')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
