import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DiagnosisModel, ExtendedDiagnosisModel} from "../../../models/diagnosis.model";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {RouteParams} from "../../../../ng1.routeParams";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiV2Diagnosis} from "../../../services/api-v2-diagnosis";
import moment from "moment";
import {EntityModel} from "../../../models/entity.model";
import {ApiV2Entities} from "../../../services/api-v2-entities";

@Component({
    selector: 'diagnoses-close-modal',
    templateUrl: './diagnoses-close-modal.component.html',
})
export class DiagnosesCloseModalComponent implements OnInit, AfterViewInit {
    @Input() patientId: string;
    @Input() currentDiagnoses: ExtendedDiagnosisModel[];
    @Output() onClose = new EventEmitter<DiagnosisModel[]>();
    statuses: EntityModel[] = [];
    selectAll = false;
    formGroup!: FormGroup;

    constructor(private params: RouteParams, private apiV2Diagnosis: ApiV2Diagnosis, private apiV2Entities: ApiV2Entities,
                private readonly fb: FormBuilder, private modalRef: BsModalRef, private modalService: BsModalService) {
    }

    ngAfterViewInit(): void {
        //this.openCloseDiagnoseModal();
    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;

        this.formGroup = this.fb.group({
            circumstances: this.fb.control(null, Validators.required),
        })

        this.apiV2Entities.getEntitiesList('eHDSI_StatusCode')
            .subscribe(res => this.statuses = res);
        this.currentDiagnoses = this.currentDiagnoses.filter(d => {
            return d.category?.code !== "2" && d.clinicalStatus?.code !== "90734009";
        });
    }

    onDiagnosisSelectionChange(): void {
        this.updateSelectAllState();
    }

    updateSelectAllState(): void {
        this.selectAll = this.currentDiagnoses.every(diagnose => diagnose.selected);
    }

    saveDiagnoses() {
        if (this.formGroup.valid) {
            const selectedDiagnoses = this.currentDiagnoses
                .filter(d => d.selected)
                .map(d => {
                    d.onsetPeriodEnd = moment().format("YYYY-MM-DD");
                    d.clinicalStatus = this.statuses.find(s => s.code === "73425007");
                    d.decisionCircumstances = this.formGroup.get("circumstances").value;
                    return d;
                });
            console.log(selectedDiagnoses);
            //this.apiV2Diagnosis.saveConditions(this.patientId, selectedDiagnoses).subscribe();
            this.close();
        }
    }

    isAnySelected(): boolean {
        return this.currentDiagnoses.filter(d => d.selected).length !== 0;
    }

    close(): void {
        this.modalRef.hide();
    }

    openCloseDiagnoseModal() {
        const modalRef = this.modalService.show(DiagnosesCloseModalComponent, {
            initialState: {
                currentDiagnoses: this.currentDiagnoses
            },
            animated: true,
            class: 'modal-xl'
        });
    }
}