ng1App.controller('doctor.patient.070alkViewCtrl', 
    ['$scope', 'MessageService', '$window', '$routeParams', 'SveidraApiFactory', '$location', '$modalConfirm', '$filter', 'FormLoaderService',
     function ($scope, MessageService, $window, $routeParams, SveidraApiFactory, $location,
                                                             $modalConfirm, $filter, FormLoaderService) {
    $scope.$emit('setHelpPage', 'medicinines-apskaitos-dokumento-perzira');
	MessageService.clearAll();
    
    $scope.goBack = function(){
       $window.history.back();
    };
   
    $scope.formId = $routeParams.formId;
    $scope.patientId = $routeParams.patientId;
   
    FormLoaderService.startFormLoading();
    $scope.form = SveidraApiFactory.getForm({id: $scope.formId}, function (result) {
		$scope.patientData = $scope.form.patient;
		$scope.authorData = $scope.form.cardRegistrator;
		$scope.organizationData = $scope.form.organization;
		$scope.responsiblePersonData = $scope.form.author;
    });
    FormLoaderService.endFormLoading($scope.form.$promise);
	
	$scope.deleteForm = function () {
        $modalConfirm.show({title: $filter('translate')('inf.del.rec.tit'), content: $filter('translate')('inf.del.rec')}).then(function() {
        	SveidraApiFactory.deleteForm({formId: $scope.formId}, function () {
        		$location.path('/dp/patients/' + $scope.patientId + '/aLK_forms').search({});
        	});
        });
	};
	
	// Load related forms
	$scope.loadRelatedForms = function (callback) {
		SveidraApiFactory.getList({id: $scope.form.documentId}, function (result) {
			callback(result.items);
		});
	};
}]);
