import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";

@Injectable({
    providedIn: 'root',
})
export class ApiV2ProblemStatusClassifier extends ConfigService {

    path = this.apiUrl + '/problem-status-classifier';

    getClassifierList(): Observable<any[]> {
        const url = `${this.path}/`;
        return this.http.get<any[]>(url);
    }
}