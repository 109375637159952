<app-title [backPath]="backPath">
    {{'090/a - Pranešimas apie diagnozuotą onkologinį susirgimą'| translate}}
</app-title>
<!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
<app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
    (valueChange)="onChanges($event)"></app-common-patient-widget>
<app-diagnoses-form (currentDiagnosesChange)="onDiagnosisChanges($event)"
    [isRequiredFieldInForm]=" true"></app-diagnoses-form>
<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true"
    [title]="'doc-ref-058-089-151a-form.component'|translate">
    <div class="widget-body">
        <form [formGroup]="form">
            <div class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Naviko
                    morfologija</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle" formControlName="tumorMorphology"
                        maxlength="2000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Kodas</label>
                <div class="col-md-6">
                    <input type="text" class="form-control" id="templateTitle" formControlName="tumorMorphologyCode"
                        maxlength="200" />
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Naviko
                    diferencialinis laipsnis</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="tumorDifferentialGrade" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Kodas</label>
                <div class="col-md-6">
                    <input type="text" class="form-control" id="templateTitle"
                        formControlName="tumorDifferentialGradeCode" maxlength="200" />
                </div>
            </div>
            <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Klinikinis:</label>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">T </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="clinicalT" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let clinicalT of clinicalTArray" [value]="clinicalT.code">
                            {{clinicalT.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">N </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="clinicalN" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let clinicalN of clinicalNArray" [value]="clinicalN.code">
                            {{clinicalN.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">M </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="clinicalM" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let clinicalM of clinicalMArray" [value]="clinicalM.code">
                            {{clinicalM.name}}
                        </option>
                    </select>
                </div>
            </div>
            <label for="templateTitle"
                class="col-md-5 col-form-label text-xs-start text-md-end">Patologinis:</label>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">T </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="pathologicalT" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let pathologicalT of clinicalTArray" [value]="pathologicalT.code">
                            {{pathologicalT.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">N </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="pathologicalN" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let pathologicalN of clinicalNArray" [value]="pathologicalN.code">
                            {{pathologicalN.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">M </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="pathologicalM" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let pathologicalM of clinicalMArray" [value]="pathologicalM.code">
                            {{pathologicalM.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Naviko
                    stadija</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="tumorStage"
                        [ngClass]="{ 'is-invalid': form.get('tumorStage')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let tumorStage of tumorStagesArray" [value]="tumorStage.code">
                            {{tumorStage.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Onkologinės ligos
                    diagnozės nustatymo aplinkybės</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="circumstancesForDiagnosis"
                        [ngClass]="{ 'is-invalid': form.get('circumstancesForDiagnosis')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let circumstancesForDiagnosis of cancerDiagnosisCircumstancesArray"
                            [value]="circumstancesForDiagnosis.code">
                            {{circumstancesForDiagnosis.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Onkologinės
                    ligos diagnozės
                    patvirtinimo būdas (nurodyti vieną ar daugiau) </label>
                <div class="col-md-6 d-flex flex-row align-items-start">
                    <select id="category" class="form-select" formControlName="confirmedDiagnosis"
                        [ngClass]="{ 'is-invalid': form.get('confirmedDiagnosis')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let methodOfConfirmingDiagnosis of methodOfConfirmingDiagnosisArray"
                            [ngValue]="methodOfConfirmingDiagnosis.code">
                            {{methodOfConfirmingDiagnosis.name}}
                        </option>
                    </select>
                    <button class='btn btn-secondary btn-sm ms-2'
                        (click)="addRecipient(form.get('confirmedDiagnosis').value)">+&nbsp;Pridėti</button>
                </div>
            </div>
            <div class="form-group row" *ngFor="let p of getRecipientFormControls(); let i = index">
                <div class="offset-md-5 col-md-6">
                    <div class="d-flex justify-content-between">
                        <div>
                            {{p.value.name}}
                        </div>
                        <button class='btn btn-secondary btn-sm' (click)="removeRecipient(i)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Pranešimo
                    užpildymo data</label>
                <div class="col-md-6">
                    <app-date-picker inputId="reportDate" [maxDate]="currentDate"
                        [fControl]="form?.get('reportDate')">
                    </app-date-picker>
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Pastabos vėžio
                    registrui</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="cancerRegistryNotes" maxlength="4000" rows="4"></textarea>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
    (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
    [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
    [submitButtonDisabled]="true"></app-modification-actions>
