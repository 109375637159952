ng1App.controller('doctor.patient.RepresentativeEditCtrl', 
    ['$scope', '$routeParams',
     function ($scope, $routeParams)  {
    
	$scope.patientId = $routeParams.patientId;
	$scope.representationId = $routeParams.id;
    
    if($scope.representationId){
        $scope.$emit('setHelpPage', 'redaguoti-atstovavimo-informacija1');
    }else{
        $scope.$emit('setHelpPage', 'kurti-atstovavima1');
    }
    
	$scope.backUrl = '/dp/patients/' + $routeParams.patientId + '/representatives';
	$scope.successUrl = '/dp/patients/'+ $routeParams.patientId + '/representatives';
}]);