ng1App.directive('editPositionAndQualification', 
    ['$modal', '$location', '$filter',
     function ($modal, $location, $filter) {
    var modal: any = {};
    var createModal = function (scope) {
        return $modal({
            scope: scope,
            title: $filter('translate')('ChangeProfessionPosition'),
            contentTemplate: '/templates/portal/doctor/patient/certificates/modals/edit_position_and_qualification.html'
        });
    };
    return {
        scope: {
            positionList: '=',
            qualificationList: '=',
            selectedPosition: '=',
            selectedQualification: '='
        },
        link: function (scope: any, element, attrs) {
            modal = createModal(scope) as any;
            scope.res = {};

            element.on('click', function () {
                scope.res = {
                    selectedPosition: scope.resolveValueFromListByCode(scope.selectedPosition, scope.positionList),
                    selectedQualification: scope.resolveValueFromListByCode(scope.selectedQualification, scope.qualificationList)
                };
                modal.$promise.then(function () {
                    modal.show();
                });
            });
            scope.submit = function () {
                scope.selectedPosition = scope.res.selectedPosition;
                scope.selectedQualification = scope.res.selectedQualification;
                modal.$promise.then(function () {
                    modal.hide();
                });
            };
            scope.resolveValueFromListByCode = function (value, list) {
                if (!value || !list) {
                    return null;
                }
                for (var i = 0; i < list.length; i++) {
                    if (value.code === list[i].code) {
                        return list[i];
                    }
                }
            }



        }

    };
}]);