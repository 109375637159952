ng1App.factory("ArchiveHistoryApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/archive-history';

    return $resource('', {}, {
        getHistory: {
            method: 'GET',
            url: URL_BASE
        },
        getDocTypes: {
            method: 'GET',
            url: URL_BASE + '/doc-types',
            isArray: true
        },
        retry: {
            method: 'POST',
            url: URL_BASE + '/retry'
        },
        retryAll: {
            method: 'POST',
            url: URL_BASE + '/retryAll'
        }
    });
}]);