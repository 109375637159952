import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../config/config.service";
import { DiagnosisModel } from "../models/diagnosis.model";

@Injectable({
    providedIn: "root",
})
export class ApiV2Diagnosis extends ConfigService {
    path = this.apiUrl;

    getDiagnosisList(patientId: string): Observable<DiagnosisModel[]> {
        const url = `${this.path}/patients/${patientId}/diagnosisForList`;
        return this.http.get<DiagnosisModel[]>(url);
    }

    // TODO: add search by user id?
    getCurrentUserDiagnosis(patientId: string): Observable<DiagnosisModel[]> {
        const url = `${this.path}/patients/${patientId}/lastDiagnosis`;
        return this.http.get<DiagnosisModel[]>(url);
    }

    getUserDiagnosisHistory(patientId: string): Observable<DiagnosisModel[]> {
        const url = `${this.path}/conditions/allByPatient/${patientId}`;
        return this.http.get<DiagnosisModel[]>(url);
    }

    getFilteredDiagnosisHistory(
        patientId: string,
        filters: { [key: string]: any }
    ): Observable<{ items: DiagnosisModel[]; total: number }> {
        // const url = `${this.path}/conditions/allByPatient/${patientId}`;
        // return this.http.post<DiagnosisModel[]>(url, {filters});

        const url = `${this.apiUrl}/conditions/list`;
        return this.http.get<{ items: DiagnosisModel[]; total: number }>(url, {
            params: { ...filters, patient: patientId },
        });
    }

    searchDiagnose(searchStr: string): Observable<DiagnosisModel> {
        const url = `${this.path}/search`;
        let params = new HttpParams();
        params = params.append("search", `${searchStr}`);
        return this.http.get<DiagnosisModel>(url, { params: params });
    }

    saveConditions(patientId: string, conditions: DiagnosisModel[]): Observable<DiagnosisModel[]> {
        const url = `${this.path}/conditions/${patientId}`;
        return this.http.put<DiagnosisModel[]>(url, conditions);
    }
}
