ng1App.factory('AutomationApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/automation';
    return $resource('', {}, {

        autoSubmiE025ForCovid19: {
            method: 'GET',
            url: URL_BASE + '/vaccination/covid-19?patientId=:patientId&vaccine=:vaccine&dose=:doseNo'
        }
    });
}]);