ng1App.factory("NcpApiFactory", ['$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/ncp';

    return $resource('', {}, {
        getCountries: {
            method: 'GET',
            url: URL_BASE + '/countries',
        },
    });
}]);
