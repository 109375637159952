import {Injectable} from '@angular/core';
import {FullNamePipe} from "../pipes/full-name.pipe";
import {PatientModel} from "../models/patient.model";
import {PractitionerModel} from "../models/practitioner.model";
import {DocRefAbstractService} from "./doc-ref.abstract-service";
import {TranslateService} from "@ngx-translate/core";
import {DocRefConsentDocStatusEnum} from "../models/doc-ref-consent-docStatus.enum";

@Injectable({
    providedIn: 'root'
})
export class DocRefConsentService extends DocRefAbstractService {

    constructor(public fullNamePipe: FullNamePipe, private translatePipe: TranslateService) {
        super();
    }

    getAgreementInfo(patient: PatientModel, docStatus: string, date: Date): string {
        const agreementInfo = this.fullNamePipe.transform(patient) + " - " + date.toString();
        const agreed = this.translatePipe.instant('comp.docRefConsent.view.agreement.signedBy');
        switch (docStatus) {
            case DocRefConsentDocStatusEnum.VALID:
            case DocRefConsentDocStatusEnum.CANCELED:
                return agreed + " " + agreementInfo;
            default:
                return agreed + ' -';
        }
    }

    getDocStatusDescription(docStatus: string): string {
        switch (docStatus) {
            case DocRefConsentDocStatusEnum.VALID:
                return 'comp.docRefConsent.view.agreement.valid';
            case DocRefConsentDocStatusEnum.CANCELED:
                return 'comp.docRefConsent.view.agreement.canceled';
            default:
                return 'comp.docRefConsent.view.agreement.unsigned';
        }
    }

    getPractitioner(practitioner: PractitionerModel): string {
        return this.fullNamePipe.transform(practitioner);
    }

    getPatient(patient: PatientModel): string {
        return this.fullNamePipe.transform(patient);
    }
}