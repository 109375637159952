<app-inr-notification-type-subform [editable]="editable" *ngIf="!patient" />
<app-inr-notification-form-subform [editable]="editable" />
<app-inr-reporting-person-info-subform [editable]="editable" [patient]="patient" />
<app-inr-whom-person-info-subform [editable]="editable" [patient]="patient" />
<ng-container *ngIf="controlValue('notificationForm') === 'Pranešimas dėl vaistinio preparato'">
    <app-inr-drugs-caused-inr-subform [editable]="editable" />
    <app-inr-description-drugs-subform [editable]="editable" [patient]="patient" />
</ng-container>
<ng-container *ngIf="controlValue('notificationForm') === 'Pranešimas dėl vakcinos'">
    <app-inr-vaccine-caused-inr-subform [editable]="editable" />
    <app-inr-description-vaccine-subform [editable]="editable" [patient]="patient" />
</ng-container>
<app-inr-other-used-medical-products-subform [editable]="editable" />
<app-inr-additional-info-subform [editable]="editable" />
<app-inr-doctor-informing-subform [editable]="editable" *ngIf="patient" />
