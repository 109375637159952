import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColDef, FirstDataRenderedEvent, GridOptions, GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { Observable, firstValueFrom } from "rxjs";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { DocumentTagEnum } from "../../../../../shared/models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { AgActionsCell } from "src/app/shared/components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { ApiV2Session } from "../../../../../../api/api-v2-session";
import { AgPaginationBarComponent } from "../../../../../shared/components/ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";
import { AgGridCommonConfig } from "../../../../../shared/configs/ag-grid-common.config";
import { FilteredDataModel } from "../../../../../shared/models/filtered-data.model";
import { States } from "./nursingServiceOrderList.constants";

@Component({
    selector: "aspn-nursing-service-order-list",
    templateUrl: "./nursingServiceOrderList.component.html",
})
export class NursingServiceOrderListComponent implements OnInit {
    @ViewChild(AgPaginationBarComponent) paginationBar: AgPaginationBarComponent;

    constructor(
        private dpAspnContextService: DpAspnContextService,
        private apiV2Session: ApiV2Session,
        private params: RouteParams,
        private router: Router
    ) { }

    private organizationId: string;

    ngOnInit(): void {
        this.apiV2Session.getOrganization().subscribe((data) => {
            this.organizationId = data.id
        });
    }

    public nursingServiceOrderListColDefs: ColDef[] = [
        { field: "appointmentDate", headerName: "Paskyrimo data" },
        {
            field: "status",
            headerName: "Būsena",
            valueFormatter: (params) => {
                return States[params.value];
            },
            cellRenderer: statusCellRender,
            //cellStyle: { justifyContent: 'center' }
        },
        {
            field: "urgent", width: 100, headerName: "Skuba", cellRenderer: urgentCellRender, cellStyle: { justifyContent: 'center' }
        },
        {
            field: "orderPractitioner", headerName: "Paskyręs specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            field: "appointmentPractitioner", headerName: "Vykdantis specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            field: "procedures",
            width: 130,
            headerName: "Procedūros",
            cellRenderer: proceduresCellRender,
            cellStyle: { justifyContent: 'center' }
        },
        {
            field: "patient", headerName: "Pacientas", valueFormatter: (params) => {
                return params.value.givenName + " " + params.value.familyName + "\n" + params.value.declaredAddress.text + "\n" + params.value.phones
            }
        },
        {
            field: "actionButton",
            headerName: "Planuoti apsilankymą",
            cellClass: "ag-right-aligned-cell",
            type: "rightAligned",
            cellStyle: { direction: "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: false,
                    viewable: false,
                    copyable: false,
                    createable: true,
                    urlPathCreate: `/dp/aspn/order/${params.data.orderId}/${DocumentTagEnum.spv}`,
                };
            },
        },
    ];

    public gridOptions: GridOptions = {
        ...AgGridCommonConfig,
    }

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        autoHeight: true,
        wrapText: true,
        suppressMenu: true,
    };

    public nursingServiceOrderListData$!: Observable<any[] | FilteredDataModel>;

    onGridReady(params: GridReadyEvent) {
        firstValueFrom(this.apiV2Session.getOrganization()).then((data) => {
            this.organizationId = data.id;
            this.nursingServiceOrderListData$ = this.dpAspnContextService.getNursingServiceOrderListByOrganizationId(this.organizationId);
        });
        this.paginationBar.initForAgGrid(params);
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    loadFilteredList(query?: any) {
        this.nursingServiceOrderListData$ = this.dpAspnContextService.getNursingServiceOrderListByOrganizationId(this.organizationId);
    }

}

function statusCellRender(params: ValueFormatterParams) {
    if (params.value === "Active")
        return `<span class="badge rounded-pill text-bg-primary text-white" style="width: 120px;">${States[params.value]}</span>`;
    if (params.value === "Cancelled")
        return `<span class="badge rounded-pill text-bg-secondary text-white" style="width: 120px;">${States[params.value]}</span>`;
    if (params.value === "Fulfilled")
        return `<span class="badge rounded-pill text-bg-success text-white" style="width: 120px;">${States[params.value]}</span>`;
}

function urgentCellRender(params: ValueFormatterParams) {
    for (let i of params.data.procedures) {
        if (i.urgent == true) {
            //return `<span class="badge rounded-pill bg-danger text-bg-danger text-danger">.</span>`;
            return `<span class="position-absolute translate-middle p-2 bg-danger rounded-circle">
                        <span class="visually-hidden">${i.urgent}</span>
                    </span>`;
        }
    }
}

function proceduresCellRender(params: ValueFormatterParams) {
    var txt = "";
    for (var p of params.value) {
        for (var n of p.procedures) {
            if (txt != "") { txt += ", " }
            txt += n.name
        }
    }
    return `<span class="badge rounded-pill text-bg-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="${txt}">?</span>`;
}