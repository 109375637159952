<div class="sveik-grid">
    <ag-grid-angular
        style="width: 100%"
        class="ag-theme-main-grid"
        [columnDefs]="colDefs"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [rowSelection]="'single'"
        [rowModelType]="'serverSide'"
        [serverSideDatasource]="datasource"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel]="true"
        (gridReady)="onGridReady($event)"
    />
</div>
<pagination-bar
    [rowsCount]="dataCount"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>