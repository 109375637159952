<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e010_1_1.formDeath.title' | translate"
>
    <div class="card-body">
        <div class="row" *ngFor="let row of rowsData">
            <div class="py-4 bg-light fw-bold mb-3" *ngIf="row.name">{{row.name | translate}}</div>
            <div
                class="col-12"
                *ngFor="let item of row.rows">
                <div *ngIf="item?.name" class="mb-1 fw-bold">{{item?.name | translate}}</div>
                <ul [class.ps-0]="row.style !== 'list'">
                    <ng-container *ngIf="item.value && valueIsArray(item.value)">
                        <ng-container *ngFor="let value of item.value">
                            <li *ngIf="!!value && row.style === 'list'">{{value}}</li>
                            <div *ngIf="!!value && row.style !== 'list'">{{value}}</div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="item.value && !valueIsArray(item.value)">
                        <li *ngIf="row.style === 'list'">{{item.value}}</li>
                        <div *ngIf="row.style !== 'list'">{{item.value}}</div>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</nhr-record-widget>