<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item">
        <div class="mb-1">
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.level' | translate }}{{ ': ' }}
            </span>
            <span class="mb-0" *ngIf="disability.level">
                {{ disability.level?.display }}
            </span>
            <span class="vr"></span>
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.from' | translate }}
            </span>
            <span class="mb-0">
                {{ disability.start }}
            </span>
            <span class="vr"></span>
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.to' | translate }}
            </span>
            <span class="mb-0">
                {{ disability.end }}
            </span>
        </div>
        <div class="mb-1">
            <span class="fw-bold mb-0" *ngIf="disability.certificateNumber">
                {{ 'Neįgalumo pažymėjimo nr.' | translate }}{{ ': ' }}
            </span>
            <span class="mb-0" *ngIf="disability.certificateNumber">
                {{ disability.certificateNumber }}
            </span>
        </div>
        <div class="mb-1">
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.wdis.level' | translate }}{{ ': ' }}
            </span>
            <span class="mb-0" *ngIf="workability.workabilityPercentage">
                {{ workability.workabilityPercentage }}{{ '%' }}
            </span>
            <span class="vr"></span>
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.from' | translate }}
            </span>
            <span class="mb-0">
                {{ workability.start }}
            </span>
            <span class="vr"></span>
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.to' | translate }}
            </span>
            <span class="mb-0">
                {{ workability.end }}
            </span>
        </div>
        <div class="mb-1">
            <span class="fw-bold mb-0">
                {{ 'reason' | translate }}{{ ': ' }}
            </span>
            <span class="mb-0" *ngIf="workability.interpretation">
                {{ workability.interpretation.display }}
            </span>
        </div>
        <div class="mb-1">
            <span class="fw-bold mb-0">
                {{ 'Specialieji poreikiai' | translate }}{{ ': ' }}
            </span>
            <span class="mb-0" *ngIf="specialNeeds.needType">
                {{ specialNeeds.needType.display }}
            </span>
            <span class="vr"></span>
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.from' | translate }}
            </span>
            <span class="mb-0">
                {{ specialNeeds.start }}
            </span>
            <span class="vr"></span>
            <span class="fw-bold mb-0">
                {{ 'dpp.pat.sum.dis.to' | translate }}
            </span>
            <span class="mb-0">
                {{ specialNeeds.end }}
            </span>
        </div>
    </li>
</nhr-record-widget>