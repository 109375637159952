ng1App.directive('sveidraMainStageData', 
    ['EntitiesApiFactory', '$filter', 'CommonFunctionService',
     function (EntitiesApiFactory, $filter, CommonFunctionService) {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/main_stage_data.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			
		    scope.additional = {
		    	ageType: null
			};
			
			scope.treatmentTypes = EntitiesApiFactory.getList({classCode: 'f025alk-treatment-type'}, function (result) {
				_.each(result, function (entity) {
					entity.label = _.getCodeName(entity, ' - ');
				});
			});
			
			scope.daySurgeryGroups = [1, 2, 3, 4, 5, 6];
			
			scope.daySurgeryChanged = function () {
				if (!scope.stage.daySurgery) {
					scope.stage.daySurgeryGroup = null;
				}
			};
			
		    scope.ageTypes = [
		        {
		            value: true,
		            label: $filter('translate')('day')
		        },
		        {
		        	value: false, 
		        	label: $filter('translate')('years')
		        }
		    ];
		    
		    scope.ageTypeChanged = function () {
		    	scope.stage.ageInDays = scope.additional.ageType.value;
		    };
		    
		    scope.needMass = function () {
		    	return scope.stage.ageInDays && scope.stage.age < 28;
		    };
		    
			// Resolve values
			scope.stage.$promise.then(function () {
				scope.additional.ageType = scope.stage.ageInDays ? scope.ageTypes[0] : scope.ageTypes[1];
			});
			
			// Resolve treatment type
			CommonFunctionService.getTrueEntityValue(scope.stage, 'treatmentType', scope.treatmentTypes);
			
			// Validation
			scope.integerFieldHasErrors = function (field) {
				if (scope.form[field]) {
					return ((scope.form.submitted || scope.form[field].$dirty)&& scope.form[field].$error.required)
							|| scope.form[field].$error.integer || scope.form[field].$error.lessThan
							|| scope.form[field].$error.moreThan;
				}
				return false;
			};
			
			scope.integerFieldHasRequiredError = function (field) {
				if (scope.form[field]) {
					return (scope.form.submitted || scope.form[field].$dirty) && scope.form[field].$error.required 
							&& !(scope.form[field].$error.integer || scope.form[field].$error.lessThan || scope.form[field].$error.moreThan);
				}
			};
		}
	};
}]);