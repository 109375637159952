import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
    selector: 'quick-search',
    templateUrl: './quick-search.component.html'
})
export class QuickSearchComponent implements OnInit {

    @Input() name: string;
    @Input() formName: string;
    @Input() formV2: FormGroup;
    @Input() form: any;
    @Input() propertyName: string;
    @Input() loading: boolean;
    @Input() isAdvanced: boolean;
    @Input() placeholder: string;

    @Output() quick = new EventEmitter<any>();
    @Output() advanced = new EventEmitter<void>();
    @Output() clear = new EventEmitter<any>();
    @Output() filterService = new EventEmitter<any>();

    constructor(private formBuilder: FormBuilder) {
        this.formV2 = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.formName = this.name + '_form';
        this.formV2.addControl(this.name, new FormControl(null));
        if (this.filterService.observed) {
            this.form = this.formBuilder.group({q: ''});
        }
    }

    onQuick() {
        if (this.filterService.observed) {
            const query: Object = this.formV2?.value;
            this.filterService.emit(query);
        } else {
            this.quick.emit();
        }
    }

    onAdvanced() {
        this.advanced.emit();
    }

    onClear() {
        if (this.filterService.observed) {
            this.formV2.reset();
            this.onQuick();
        } else {
            this.clear.emit();
        }
    }
}