import { Component } from "@angular/core";
import { RouteParams } from '../../../../ng1.routeParams';
import { E097FormsNames } from './e097.constants';
import { ApiV2E097 } from '../../../../../api/api-v2-doc-ref-e097';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';

@Component({
    selector: "app-e097",
    templateUrl: "./e097.component.html",
})
export class E097Component {
    public e097FormsNames = E097FormsNames;
    editable: boolean;
    private patientId: string;
    initialATFormValue: { [key: string]: any }[];
    initialGDFormValue: { [key: string]: any }[];

    constructor(
        private params: RouteParams,
        private apiV2E097: ApiV2E097

    ) {
        this.getPatientId();
        forkJoin({
            at: this.apiV2E097.getE097FormView('at', this.patientId),
            gd: this.apiV2E097.getE097FormView('gd', this.patientId),
        })
        .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.initialATFormValue = res.at;
                this.initialGDFormValue = res.gd;
            });
    }


    getPatientId(){
        this.patientId = this.params.patientId;
        this.editable = !window.location.href.includes('/view');
    }
}
