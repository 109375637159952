import { Component, Input } from '@angular/core';
import moment from 'moment';
import { Entry } from '../../../../../models/e096.model';
import { E010_Row, E010_RowsGroup } from '../../../e010-forms/e010-forms.model';

@Component({
    selector: 'app-e010-1-1-form-childbirth',
    templateUrl: './e010-1-1-form-childbirth.component.html',
})
export class E01011FormChildbirthComponent {
    @Input() set data(value: {[key: string]: any}) {
        if(!value) return;
        this.placeOfBirthData = [
            {
                value: value.e096N.generalData.placeOfBirth
            }
        ];
        this.deliveryData = [
            {
                value: 'Robsono grupė, -  ' + (value.e096Ge.laborProgress.robsonGroup || value.e096Cpo.generalInformation.robsonGroup || 'nenustatyta')
            },
            {
                value:  value.e096Ge.administrationOfAntibiotics.ruptureBirthCanal === 'Rasta' ? 'Plyšimų susiuvimas' : ''
            },
            {
                value:  value.e096Ge.administrationOfAntibiotics.episiotomy ? 'Epiziotomija ir susiuvimas' : ''
            },
            {
                value:  value.e096Ge.administrationOfAntibiotics.placentaSeparate === 'Atskirta ranka' ? 'Placentos pašalinimas ranka' : ''
            },
            {
                value:  value.e096Ge.administrationOfAntibiotics.interventions?.includes('Gimdos ertmės patikrinimas ranka') ? 'Gimdos ertmės patikrinimas ranka' : ''
            },
            {
                // TODO: 5.6.2.6.1. - 5.6.2.6.2 CPO approved/no approved
                subGroup: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.giveBirth',
                name: value.e096Ge.laborProgress.born === 'Natūraliais takais' || value.e096Ge.laborProgress.born === 'Natūraliais takais, taikant pagalbines priemones' ? 'Natūraliais takais' : '',
            },
            {
                value: value.e096Ge.laborProgress.born === 'Natūraliais takais, taikant pagalbines priemones' ? value.e096Ge.laborProgress.bornAssistanceMethod : ''
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.cesareanOperation',
                value: [
                    value.e096Cpo.generalInformation.urgencyOfOperation,
                    (value.e096Cpo.generalInformation.caesareanSectionIndicationGroup === 'Pakartotinė operacija'
                        ? value.e096Cpo.generalInformation.caesareanSectionIndicationValue
                        : null)
                ]
            },
        ];
        this.bereavementData = [
            {
                value: value.e096An.cases
            }
        ];
        this.deathData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.mainCauseOfDeath',
                boldTitle: true,
                value: value.e096An.causeOfDeath
            },
        ];

        value.e096N.fruits.forEach((_, i) => this.generatechildbirthDurationData(value, i))

        this.rowsData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.birthPlace',
                rows: this.placeOfBirthData,
                style: 'list'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.deliveryMode',
                rows: this.deliveryData,
                style: 'list'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.bereavementCases',
                rows: this.bereavementData,
                style: 'list'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.womenDeathDuringPregnancy',
                rows: this.deathData,
                style: 'list'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.childbirthDuration',
                rows: this.childbirthDurationData,
                style: 'list'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formChildBirth.waterlessPeriod',
                rows: this.waterlessPeriodData,
                style: 'list'
            }
        ]
    };

    rowsData: E010_RowsGroup[];

    private placeOfBirthData: E010_Row[];
    private deliveryData: E010_Row[];
    private bereavementData: E010_Row[];
    private deathData: E010_Row[];
    private childbirthDurationData: E010_Row[] = [];
    private waterlessPeriodData: E010_Row[] = [];

    constructor() {}

    private diffTime(date1: string | Date, date2: string | Date): string {
        const duration = moment.duration(moment(date2).diff(moment(date1)));
        const hours = Math.floor(Math.abs(duration.asHours()));
        const minutes = Math.floor(Math.abs(duration.asMinutes())) % 60;

        return `${hours} val. ${minutes} min.`;
    }

    private generatechildbirthDurationData(value: { [p: string]: any }, i) {
        const sortArray = (arr: Entry[]): Entry[] => arr.sort((a, b) =>
            new Date(a.timeOfEntry).getTime() - new Date(b.timeOfEntry).getTime());

        this.childbirthDurationData.push(
            {
                subGroup: `Vaisius ${value.e096N.fruits[i].fruit}`,
                value: [
                    this.diffTime(
                        sortArray(value.e096Part.entries).find(entry => +entry.cervixOpening >= 10).timeOfEntry,
                        value.e096Part.partogram.regularOnsetOfContractions
                    ),
                    this.diffTime(
                        value.e096N.fruits[i].birthTime,
                        sortArray(value.e096Part.entries).find(entry => +entry.cervixOpening >= 10).timeOfEntry
                    ),
                    this.diffTime(
                        value.e096Ge.laborProgress.placentaTimeDelivery,
                        value.e096N.fruits[i].birthTime
                    ),
                ]
            }
        );

        this.waterlessPeriodData.push(
            {
                subGroup: `Vaisius ${value.e096N.fruits[i].fruit}`,
                value: this.diffTime(value.e096N.fruits[i].birthTime, value.e096Part.partogram.fruits[i].timeOfAmniocentesis)
            }
        )
    }
}
