export enum CardTitles {
    UpcomingEvents = "Artimiausi įvykiai",
    LastVisit = "Paskutinis apsilankymas",
    ResearchResults = "Tyrimų rezultatai",
    PrescribedMedication = "Paskirti vaistai ir MPP",
    Hospital = "Mano gydymo įstaiga",
    Contacts = "Mano kontaktiniai duomenys",
    Representations = "Įgaliojimai/atstovavimai",
    HealthServicesConsents = "Sutikimai sveikatos paslaugoms",
    SuspectedAdverseReactions = "Įtariamos nepageidaujamos reakcijos",
}
