import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterModel} from "../../models/filter.model";

@Component({
    selector: 'predefined-filters',
    templateUrl: 'predefined_filters.html',
})
export class PredefinedFiltersComponent implements OnInit {
    @Input() filters: any;
    @Input() currentFilter: any;
    @Input() filterChanged: (filter: any) => void;
    @Input() title: string;
    @Input() loading: string;
    @Input() titleName: string;
    @Output() filterService = new EventEmitter<any>();

    constructor() {

    }

    ngOnInit(): void {
        this.titleName = this.title ? this.title : 'lis.fil.rec';
        this.changeFilter = this.changeFilter.bind(this);
    }

    changeFilter(filter: FilterModel) {
        if (this.filterService.observed) {
            this.filterService.emit(filter);
            this.currentFilter = filter;
        } else if (filter.count > 0 || filter.noCount) {
            this.filterChanged(filter);
        }
    }
}