import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {VaccinesSummaryModel} from "../../../models/vaccines-summary.model";

@Component({
    selector: 'vaccines-summary',
    templateUrl: './vaccines-summary.component.html'
})
export class VaccinesSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() vaccines: VaccinesSummaryModel[] = [];
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]);
    }
}