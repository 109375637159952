<app-title [backPath]="backUrl" backSubtitle="pp.dia.sea.title" showFilterButton="true">
    {{ "dpp.all.tit" | translate }}
</app-title>
<div class="widget">
    <app-page-filters
        [options]="filterOptions"
        (filtering)="updateFilters($event)"
    ></app-page-filters>
    <div class="widget-body" [class]="allergyHistory?.length ? ' p-0' : ''">
        <ol *ngIf="allergyHistory?.length" class="slats hover">
            <!-- TODO-design-slat-item: check if edit button works -->
            <li
                slat-item edit
                (editButtonClick)="openEditAllergyModal(i)"
                *ngFor="let allergy of allergyHistory; let i = index">
                <div class="d-flex gap-3">
                    <div class="flex-grow-1 mb-1">
                        <div class="row flex-wrap-reverse flex-sm-wrap">
                            <div class="col-sm-auto text-sm-center text-secondary text-nowrap mb-1 mb-sm-0">
                                {{ allergy.renewalDate | date : "YYYY-MM-dd" }}
                            </div>
                            <div class="col ps-sm-0 mb-sm-1">
                                <strong>{{ allergy.manifestation.code + " " + allergy.manifestation.name }}</strong>
                            </div>
                        </div>
                        <div class="flex-grow-1 row small">
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap d-flex align-items-center">
                                <span class="text-secondary me-2">{{ "doc.dia.sts" | translate }}: </span>
                                <ng-container *ngIf="allergy.verificationStatus?.name">
                                    <div class="d-inline position-relative p-2">
                                        <span class="position-absolute p-2 translate-middle rounded-circle bg-success">
                                            <span
                                                class="visually-hidden">{{ allergy.verificationStatus.name | capitalize }}</span>
                                        </span>
                                    </div>
                                    <!-- TODO-design: should we use an icon instead? If not delete this comment -->
                                    <!-- <i class="fa-regular fa-circle-dot text-success fs-3 my-n1"></i> -->
                                    <strong class="ps-1">{{ allergy.verificationStatus?.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!allergy.verificationStatus?.name">
                                    <strong>-</strong>
                                </ng-container>
                            </div>

                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{ "Code" | translate }}: </span>
                                <ng-container *ngIf="allergy.reaction">
                                    <strong>{{ allergy.reaction | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!allergy.reaction">
                                    <strong>-</strong>
                                </ng-container>
                            </div>

                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{ "doc.dia.dia.typ" | translate }}: </span>
                                <ng-container *ngIf="allergy.crivitality?.name">
                                    <strong>{{ allergy.crivitality?.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!allergy.crivitality?.name">
                                    <strong>-</strong>
                                </ng-container>
                            </div>

                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{ "doc.dia.cat" | translate }}: </span>
                                <ng-container *ngIf="allergy.category?.name">
                                    <strong>{{ allergy.category?.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!allergy.category?.name">
                                    <strong>-</strong>
                                </ng-container>
                            </div>

                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{ "doc.dia.con" | translate }}: </span>
                                <ng-container *ngIf="allergy.verificationStatus?.name">
                                    <strong>{{ allergy.verificationStatus?.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!allergy.verificationStatus?.name">
                                    <strong>-</strong>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{ "doc.dia.sev" | translate }}: </span>
                                <ng-container *ngIf="allergy.severity?.name">
                                    <strong>{{ allergy.severity?.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!allergy.severity?.name">
                                    <strong>-</strong>
                                </ng-container>
                            </div>
                        </div>
                        <div class="description text-secondary small mt-2">{{ allergy.notes ?? "-" }}</div>
                    </div>
                </div>
            </li>
        </ol>
        <ng-container *ngIf="!allergyHistory?.length">
            <span>{{
                (allergyFullHistory?.length ? "doc.all.noAllergiesForFilter" : "doc.all.noAllergies") | translate
            }}</span>
        </ng-container>
    </div>
</div>

<pagination-bar
    #paginationBar
    [rowsCount]="total"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>
