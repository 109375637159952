import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    controlIsDisabled,
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-cpo-caesarean-procedure',
    templateUrl: './e096-cpo-caesarean-procedure.component.html',
})
export class E096CpoCaesareanProcedureComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    currentTime = new Date();
    formGroup: FormGroup;
    abdominalWallIncisedOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Skersiniu pjūviu'
        },
        {
            id: '1',
            code: '1',
            name: 'Išilginiu pjūviu'
        }
    ];
    bladderOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Nustumta žemyn'
        },
        {
            id: '1',
            code: '1',
            name: 'Nenustumta žemyn'
        }
    ];
    uterusIsIncisedOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Skersai'
        },
        {
            id: '1',
            code: '1',
            name: 'Išilgai'
        }
    ];
    placeOfUterineIncisionOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Istminėje dalyje'
        },
        {
            id: '1',
            code: '1',
            name: 'Istmikokorporalinėje dalyje'
        }
    ];
    placentaStatusOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Ištraukta (-os) už virkštelės'
        },
        {
            id: '1',
            code: '1',
            name: 'Pašalinta (-os) ranka'
        }
    ];
    attachmentOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Dugne'
        },
        {
            id: '1',
            code: '1',
            name: 'Užpakalinėje'
        },
        {
            id: '2',
            code: '2',
            name: 'Priekinėje sienoje'
        },
        {
            id: '3',
            code: '3',
            name: 'Pjūvio srityje'
        },
        {
            id: '4',
            code: '4',
            name: 'Ties vidiniais žiomenimis'
        }
    ];
    uterineMuscleSuturedOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '1 aukšto siūle'
        },
        {
            id: '1',
            code: '1',
            name: '2 aukštų siūle'
        }
    ];
    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];
    abdominalOrgansOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Nepakitę'
        },
        {
            id: '1',
            code: '1',
            name: 'Pakitę'
        }
    ];
    urineThroughCatheterOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Neteka'
        },
        {
            id: '1',
            code: '1',
            name: 'Teka'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    getCntrlValue = getControlValue.bind(this) as typeof getControlValue;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            abdominalWallIncised: this.fb.control(null, [Validators.required]),
            bladder: this.fb.control(null, [Validators.required]),
            uterusIsIncised: this.fb.control(null, [Validators.required]),
            placeOfUterineIncision: this.fb.control(null, [Validators.required]),
            otherFeatures: this.fb.control(null),
            placentaStatus: this.fb.control(null, [Validators.required]),
            attachments: this.fb.control([]),
            examinationOfPlacentaIsOrdered: this.fb.control(null, [Validators.required]),
            uterineMuscleSutured: this.fb.control(null, [Validators.required]),
            abdominalOrgans: this.fb.control(null, [Validators.required]),
            alteredOrgansDescriptions: this.fb.control(null),
            lostBloodAmount: this.fb.control(null, [Validators.required]),
            lostBloodAmountDetail: this.fb.control(null),
            urineThroughCatheter: this.fb.control(null, [Validators.required]),
            urinaryState: this.fb.control([]),
            atypicalMoments: this.fb.control(null)
        });

        this.changeStatusOfControl({
            controlNameForListen: 'lostBloodAmount',
            controlNameForUpdate: 'lostBloodAmountDetail',
            enableValue: 'daugiau kaip 1000 ml',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'abdominalOrgans',
            controlNameForUpdate: 'alteredOrgansDescriptions',
            enableValue: 'Pakitę',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'urineThroughCatheter',
            controlNameForUpdate: 'urinaryState',
            enableValue: 'Teka',
            addValidators: [Validators.required],
            resetValue: []
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }
}
