import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';

@Component({
    selector: 'app-e097-gd-inspections',
    templateUrl: './e097-gd-inspections.component.html',
})
export class E097GdInspectionsComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup = this.fb.group({});
    currentTime = new Date();

    inspections = [
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.generalCondition',
            formGroup: 'generalCondition'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.skin',
            formGroup: 'skin'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.mucousMembranesVisible',
            formGroup: 'mucousMembranesVisible'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.umbilicalCord',
            formGroup: 'umbilicalCord'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.head',
            formGroup: 'head'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.palate',
            formGroup: 'palate'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.chest',
            formGroup: 'chest'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.lungCondition',
            formGroup: 'lungCondition'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.breathingRate',
            formGroup: 'breathingRate'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.heart',
            formGroup: 'heart'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.pulseRate',
            formGroup: 'pulseRate'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.tone',
            formGroup: 'tone'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.nerveSystem',
            formGroup: 'nerveSystem'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.abdominalOrgans',
            formGroup: 'abdominalOrgans'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.defecation',
            formGroup: 'defecation'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.urinating',
            formGroup: 'urinating'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.genitals',
            formGroup: 'genitals'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.analOpening',
            formGroup: 'analOpening'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.InspectionsForm.hipJointCondition',
            formGroup: 'hipJointCondition'
        },
    ];

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.inspections.forEach(inspection => {
            this.formGroup.addControl(inspection.formGroup, this.fb.group({
                result: this.fb.control('Norma'),
                comment: this.fb.control(null)
            }))
        })
    }
}
