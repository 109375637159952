<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e010_1_1.formMother.title' | translate"
>
    <div class="card-body">
        <div class="row">
            <div class="col-6 mb-3" *ngFor="let item of motherData">
                <div class="col-auto fw-bold">{{item.name | translate}}</div>
                <div class="col-auto">{{item.value}}</div>
            </div>
        </div>
    </div>
</nhr-record-widget>