ng1App.controller('doctor.patient.CertificatesViewCtrl', 
    ['$scope', '$rootScope', '$routeParams', 'CertificatesApiFactory', 'CertificateService', 'IntegratedComponentContext', '$location', 'DocumentSpinnerHelper', 'MessageService', '$filter', '$modal', '$modalConfirm', 'CertificatesViewService', 'EncountersApiFactory', 'apiUrl', '$route', 'FormLoaderService', '$routeSegment', 'DocumentsApiFactory', 'FormReloadService',
     function ($scope, $rootScope, $routeParams, CertificatesApiFactory, CertificateService, IntegratedComponentContext,
                                                                   $location, DocumentSpinnerHelper, MessageService, $filter, $modal, $modalConfirm, CertificatesViewService, EncountersApiFactory, apiUrl, $route, FormLoaderService,
                                                                   $routeSegment, DocumentsApiFactory, FormReloadService) {
    FormLoaderService.startFormLoading();
    $scope.$emit('setHelpPage', 'perzireti-elektronini-dokumenta');

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    var sgnst = $routeParams['sgnst'];
    if (sgnst) {
        if (sgnst == 'ok') {
            MessageService.add('success', 'Dokumentas pasirašytas sėkmingai');
        } else if (sgnst == 'canceled') {
            MessageService.add('warning', 'Dokumento pasirašymas atšauktas');
        } else if (sgnst == 'fail') {
            var errorid = $routeParams['msgid'];
            MessageService.add('error', 'Nepavyko pasirašyti dokumento. Klaidos ID: ' + errorid);
        }

    }

    DocumentSpinnerHelper.reset();
    $scope.documentCancelReason = {};
    $scope.cancelErr = 0;
    $scope.certificateId = $routeParams.id;

    var modalCancel = $modal({
        scope: $scope,
        title: $filter('translate')('mdl.add.rea.doc'),
        contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel.html'
    });

    $scope.$on('$locationChangeStart', function (next, current) {
        if ($route.current.regexp.test($location.path())) {
            _.each($routeSegment.chain, function (c) {
                c.reload();
            });
        }
    });

    $scope.certificate = CertificatesApiFactory.getByComposition({id: $routeParams.id}, function (data) {
        CertificateService.populateCommonData($scope, data);
        if (data.type === 'e027_1_I') {
            $scope.e027val = 1;
        }
        if (data.type === 'e027_1_II') {
            $scope.e027val = 2;
        }
        // z data
        CertificatesViewService.fillDataByType($scope, data.type, $routeParams.patientId, $routeParams.id);
        $scope.canEdit = checkEditablity(data.status, data.type, data.signed);
        $scope.canCancel = data.status != 'enteredInError';
        if ($scope.certificate.encounterId) {
            $scope.encounter = EncountersApiFactory.get({id: $scope.certificate.encounterId});
            $scope.encounter.$promise.then(function () {
                var event = $scope.encounter.event.code;
                $scope.canConnectToActive = false;
                if (event === 'inpatient') {
                    var encounterDocsCounts = EncountersApiFactory.countActiveAndCanceled({encounterId: $scope.encounter.id, docType: 'e003'});
                    encounterDocsCounts.$promise.then(function (data) {
                        if (data.active > 0 || data.activeSigned || data.canceled > 0) {
                            console.log(data);
                            $scope.e003Exists = true;
                            $scope.canConnectToActive = true;
                        }
                    });

                    switch (data.type) {
                        case 'e103_1':
                        case 'e106':
                            $scope.canCreateRelated = (data.documentStatus === 'FINAL_SIGNED' || data.documentStatus === 'AMENDED_SIGNED') && !$scope.e003Exists;
                            break;
                        case 'e106_2_1':
                            $scope.canCreateRelated = (data.documentStatus === 'FINAL_SIGNED' || data.documentStatus === 'AMENDED_SIGNED') && !$scope.e003Exists;
                            break;
                        case 'e083_1':
                        case 'e049':
                        case 'e048_practitioner':
                        case 'e047_practitioner':
                            $scope.canCreateRelated = data.documentStatus === 'FINAL_SIGNED';
                            break;
                        default:
                            $scope.canCreateRelated = false;
                    }

                } else {
                    var s = data.documentStatus;
                    switch (data.type) {
                        case 'e027_1_I':
                        case 'e027_1_II':
                            $scope.canCreateRelated = s == 'FINAL_SIGNED' || s == 'IN_PROGRESS' || s == 'COMPLETED';
                            break;
                        default:
                            $scope.canCreateRelated = s == 'FINAL_SIGNED';
                    }
                }
            });
        }
        if ((data.type === 'e027_1_I' || data.type === 'e027_1_II') && data.relatedPartId) {
            $scope.relatedCertificate = CertificatesApiFactory.getByComposition({id: data.relatedPartId});
        }

        CertificatesApiFactory.getPublicViewCode({certificateId: $scope.certificate.compositionId}, function (data) {
            $scope.publicViewCode = data.viewCode;
        });
    });

    $scope.canSendToEDelivery = function () {
        return false;
        //disabled while VA file attachment is not working
        //return SessionContext.practitioner.eDeliveryAddress && $scope.certificate && $scope.certificate.documentStatus === 'FINAL_SIGNED';
    };

    $scope.sendToEDelivery = function () {
        $location.path('/dp/messages/new').search({documentId: $routeParams.id});
    };

    FormLoaderService.endFormLoading($scope.certificate.$promise);

    $scope.showGenerateViewCode = function () {
        var result = true;
        //2020-04-26 AS modified 
        if ($scope.certificate.type === 'e027_1_I' || $scope.certificate.type === 'e027_1_II') {
			if ($scope.isCompositionProfileVersion('v1')) {
				result = $scope.relatedCertificate && $scope.relatedCertificate.documentStatus === 'FINAL_SIGNED';
			}
        }
        return result && $scope.certificate.documentStatus === 'FINAL_SIGNED';
    };

    $scope.pdfDownload = apiUrl + '/documents2/' + $routeParams.id + '/signedPdf';

    $scope.goBack = function () {
        if (!$rootScope.restrictedMode) {
            //    	$window.history.back();
            // For now return to records
            $location.path('/dp/patients/' + $routeParams.patientId + '/records').search({});
        } else {
            $location.path('/dp/patients/' + $routeParams.patientId + '/certificates').search({});
        }
    };

    $scope.sign = function (doSign) {
        doSign = (doSign === undefined || doSign === null) ? true : doSign;
        if ($scope.certificate.status == 'preliminary') {
            CertificatesApiFactory.makeFinal({compositionRef: $scope.certificate.compositionRef}, {}, function () {
                if (doSign) {
                    CertificateService.toSignPage($routeParams.patientId, $routeParams.id, $scope.certificate.type);
                } else {
                    FormReloadService.reloadForm(false)
                }
            }, function (res) {
                MessageService.add('error', $filter('translate')('err.cer.initSign'));
                if (res.data.fieldErrors && res.data.globalErrors) {
                    _.each(res.data.fieldErrors, function (i) {
                        var errorMessage = $filter('translate')('cer.field.' + i.propertyPath);
                        errorMessage += ": " + $filter('translate')('err.cer.' + i.msg);
                        MessageService.error(errorMessage);
                    });
                    _.each(res.data.globalErrors, function (i) {
                        MessageService.error(i.msg);
                    });
                }
            });
        } else {
            if (doSign) {
                CertificateService.toSignPage($routeParams.patientId, $routeParams.id, $scope.certificate.type);
            }
        }
    };

    $scope.createCode = function () {
        $modalConfirm.show({
            content: 'Vienu metu galioja tik vienas pažymos peržiūros kodas. Sukūrus naują pažymos peržiūros kodą, ankstesnis pažymos peržiūros kodas nustoja galioti.'
        }).then(function () {
            MessageService.clearAll();
            CertificatesApiFactory.createPublicViewCode({certificateId: $routeParams.id}, {}, function (data) {
                $scope.publicViewCode = data.viewCode;
            }, function (error) {
                _.each(error.data.globalErrors, function (e) {
                    MessageService.error(e.msg);
                });
            });
        });
    };

    $scope.cancel = function (doSignCancel) {
        $scope.doSignCancel = (doSignCancel === undefined || doSignCancel === null) ? true : doSignCancel;
        $scope.cancelErr = 0;
        $scope.documentCancelReason = {};
        $scope.errors = [];
        $scope.docSpinnerHelper = DocumentSpinnerHelper;
        modalCancel.$promise.then(function () {
            modalCancel.show();
        });
    };

    $scope.createRelated = function () {
        if ($scope.e003Exists) {
            MessageService.error($filter('translate')('err.cer.relatedeE003exists'));
            return;
        }
        var relatedDocType = 'e003';
        if ($scope.encounter.event.code === 'ambulatory') {
            relatedDocType = 'e025';
        }
        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/new/' + relatedDocType).search({
            encounterId: $scope.certificate.encounterId,
            certificateId: $scope.certificate.compositionId
        });
    };

    $scope.connectToActive = function () {
        if (!$scope.e003Exists) {
            MessageService.error($filter('translate')('err.cer.relatedeE003missing'));
            return;
        }
        var relatedDocType = 'e003';
        DocumentsApiFactory.searchForList({
            encounterId: $scope.certificate.encounterId,
            count: 1,
            withDrafts: true,
            docType: 'e003'
        }, function (encounterDocs) {
            console.log(encounterDocs);
            $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + encounterDocs[0].id + '/edit').search({
                certificateId: $scope.certificate.compositionId
            });
        });
    };

    $scope.doCancel = function () {
        MessageService.clearAll();
        var cancelReasonText = $scope.documentCancelReason.text;

        if (cancelReasonText && cancelReasonText != null) {
            $scope.docSpinnerHelper.startLoading();
            CertificatesApiFactory.cancel({id: $routeParams.id}, {cancelReason: cancelReasonText}, function () {
                $scope.docSpinnerHelper.endLoading();
                modalCancel.hide();
                MessageService.registerCancellationSuccessEvent();
                if ($scope.doSignCancel) {
                    CertificateService.toSignPage($routeParams.patientId, $routeParams.id, $scope.certificate.type);
                } else {
                    FormReloadService.reloadForm(false);
                }
            }, function (error) {
                $scope.docSpinnerHelper.endLoading();
                MessageService.error($filter('translate')('err.cer.can.fai'));
                if (error && error.data) {
                    angular.forEach(error.data.fieldErrors, function (e) {
                        MessageService.error(e.msg);
                    });
                    angular.forEach(error.data.globalErrors, function (e) {
                        MessageService.error(e.msg);
                    });
                }
                modalCancel.hide();
            });
        } else {
            $scope.cancelErr = 1;
        }
    };

    $scope.goToEdit = function () {
        $location.path($scope.editUrl).search({});
    };

    var checkEditablity = function (status, type, signed) {
        if (signed || (status == 'enteredInError') || (status == 'final') || (status == 'final_')) {
            return false;
        }
        return (type == 'e083_1') || (type == 'e047_practitioner') || (type == 'e048_practitioner') || (type == 'e049');
    };

    $scope.e027TypeChange = function () {
        $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/' + $scope.certificate.relatedPartId + '').search({});
    };

    if ($routeParams.encounterId && !IntegratedComponentContext.isInitialized()) {
        IntegratedComponentContext.setData($routeParams.encounterId);
    }
    $scope.docMenu = {
        main: [
            {
                title: 'doc.download',
                target: '_blank',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.signed;
                },
                url: $scope.pdfDownload
            }, {
                title: 'sign',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canSign && !$scope.certificate.signed && $scope.certificate.type !== 'e047_employer' && $scope.certificate.type !== 'e048_employer';
                },
                action: $scope.sign
            }
        ],
        dropDown: [
            {
                title: 'doc.download',
                target: '_blank',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.signed;
                },
                url: $scope.pdfDownload
            }, {
                title: 'sign',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canSign && !$scope.certificate.signed && $scope.certificate.type !== 'e047_employer' && $scope.certificate.type !== 'e048_employer';
                },
                action: $scope.sign
            }, {
                title: 'doc.submit.batch.sign',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canSign && !$scope.certificate.signed && $scope.certificate.type !== 'e047_employer' && $scope.certificate.type !== 'e048_employer' && $scope.certificate.documentStatus !== 'FINAL_UNSIGNED';
                },
                action: function () {
                    $scope.sign(false);
                }
            }, {
                title: 'cer.cre.view.code',
                isVisible: function () {
                    return $filter('acWrite')('CertificateCode') && $scope.showGenerateViewCode();
                },
                action: $scope.createCode
            }, {
                title: 'edit',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canEdit && $scope.canEdit;
                },
                action: $scope.goToEdit
            }, {
                title: 'cancel',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canCancel && $scope.canCancel;
                },
                action: $scope.cancel
            }, {
                title: 'batch.cancel',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canCancel && $scope.canCancel && $scope.certificate.documentStatus !== 'CANCELED_UNSIGNED';
                },
                action: function () {
                    $scope.cancel(false);
                }
            }, {type: 'separator'}, {
                title: 'createRelatedDoc',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canCreateRelated && $scope.canCreateRelated;
                },
                action: $scope.createRelated
            }, {
                title: 'connectActiveDoc',
                isVisible: function () {
                    return $scope.certificate.$resolved && $scope.certificate.acMeta.canLinkWithActiveDoc && $scope.canConnectToActive;
                },
                action: $scope.connectToActive
            }, {
                title: 'sendToEdelivery',
                isVisible: function () {
                    return $scope.canSendToEDelivery();
                },
                action: $scope.sendToEDelivery
            }
        ]
    };
}]);

ng1App.service('CertificatesViewService', function () {
    var CERTIFICATES_WITH_TASKS = ['e047_practitioner', 'e048_practitioner', 'e049', 'e083_1'];
    return {
        fillDataByType: function (scope, type, patientId, id) {
            scope.editUrl = 'dp/patients/' + patientId + '/certificates/' + id + '/edit';
            scope.templateUrl = '/templates/certificates/' + type + '/view_' + type + '.html';
            scope.showTasks = _.contains(CERTIFICATES_WITH_TASKS, type);
        }
    };
});