import { Component } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-e096-part-table-modal',
  templateUrl: './e096-part-table-modal.component.html',
})
export class E096PartTableModalComponent {

    constructor(
        readonly bsModalRef: BsModalRef,
    ) {}
}
