<nhr-record-widget [closable]="false" [closed]="false" [title]="'Dokumento duomenys' | translate" id="ngnCaption">
    <div class="card mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Data" | translate }}</div>
                    <div class="row">{{ captionData?.date | date : "YYYY-MM-dd" }}</div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Įvykio id" | translate }}</div>
                    <div class="row">{{ captionData?.eventId }}</div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Dokumento id" | translate }}</div>
                    <div class="row">{{ captionData?.documentId }}</div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Būsena" | translate }}</div>
                    <div class="row">{{ captionData?.status }}</div>
                </div>
            </div>
        </div>
    </div></nhr-record-widget
>
