<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e097At.checkDoneForm.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-lg-6 pe-4">
                <app-e097-at-verification
                    [initData]="editVerificationValue"
                    [editable]="editable"
                    (addVerification)="addVerification($event)"
                    (cancelVerification)="cancelVerification()"
                />
            </div>
            <div class="col-12 col-lg-6 ps-4 border-start h-100">
                <div
                    *ngFor="let verification of controlsArray; let i = index"
                    [class.mt-3]="i !== 0"
                >
                    <div class="card-body border p-3 bg-light" [class.bg-primary-subtle]="i === editVerificationId">
                        <h4>{{verification.verificationType}}</h4>
                        <div class="row mt-3">
                            <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.examinationDate' | translate}}:</div>
                            <div>{{verification.verificationDate | date: 'YYYY-MM-dd'}}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.examination' | translate}}:</div>
                            <div>{{verification.verificationStatus}}</div>
                        </div>
                        <ng-container
                            [ngTemplateOutlet]="verification.verificationType ===  'Dėl ĮKŠY SpO2(%)' ? hip
                                : verification.verificationType ===  'Dėl paveldimų medžiagų apykaitos ligų (PMAL)' ? hereditaryMetabolic
                                    : plusMinus"
                            [ngTemplateOutletContext]="{data: verification}"
                        ></ng-container>
                        <div class="row mt-3">
                            <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.nonPerformanceReason' | translate}}:</div>
                            <div>{{verification.nonPerformanceReason || '-'}}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.needRepeat' | translate}}:</div>
                            <div>{{(verification.repeatDate | date: 'YYYY-MM-dd') || '-'}}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.performed' | translate}}:</div>
                            <div class="row align-items-center mb-1" *ngFor="let specialist of verification.performed">
                                <span class="w-auto">{{specialist.givenName}} {{specialist.familyName}}, {{specialist.personalCode}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 d-flex" *ngIf="editable">
                        <button class="btn btn-block text-primary text-capitalize" (click)="editVerification(i)"><i class="fa fa-pencil"></i>&nbsp;{{'doc.dia.edt' | translate}}</button>
                        <button class="btn btn-block text-primary" (click)="deleteVerification(i)"><i class="fa fa-trash"></i>&nbsp;{{'doc.dia.dlt' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>

<div class="d-flex gap-3 mt-3" *ngIf="editable">
    <button class="btn btn-primary flex-grow-1" [disabled]="verifications?.invalid" (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary flex-grow-1" [disabled]="verifications?.invalid" (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="verifications?.invalid" (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="verifications?.invalid" (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="verifications?.invalid" (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger flex-grow-1" (click)="resetForm()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>

<ng-template #plusMinus let-data="data">
    <div class="row mt-3">
        <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.resultLeft' | translate}}:</div>
        <div>{{data.resultLeft}}</div>
    </div>
    <div class="row mt-3">
        <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.resultRight' | translate}}:</div>
        <div>{{data.resultRight}}</div>
    </div>
</ng-template>

<ng-template #hip let-data="data">
    <div class="row mt-3">
        <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.resultRightHand' | translate}}:</div>
        <div>{{data.resultRightHand}}</div>
    </div>
    <div class="row mt-3">
        <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.resultRightLeg' | translate}}:</div>
        <div>{{data.resultRightLeg}}</div>
    </div>
</ng-template>

<ng-template #hereditaryMetabolic let-data="data">
    <div class="row mt-3">
        <div class="fw-bolder">{{'doc.pregnancyDashboard.e097At.checkDoneForm.result' | translate}}:</div>
        <div>{{data.result}}</div>
    </div>
</ng-template>