export enum UltrasoundStages {
    First = "Vaisiaus ultragarsinis tyrimas 11+0–13+6 nėštumo savaitę",
    Second = "Vaisiaus ultragarsinis tyrimas 18+0–20+0 nėštumo savaitę",
    Third = "Vaisiaus ultragarsinis tyrimas II–III nėštumo trečdalį (esant indikacijų)",
}

export enum ResearchConditions {
    Good = "Geros",
    AggravatedByTools = "Apsunkintos dėl įrangos kokybės",
    AggravatedByBody = "Apsunkintos dėl moters kūno ypatumų",
    AggravatedByFetusPosition = "Apsunkintos dėl vaisiaus padėties",
    Other = "Kita",
}

export enum ResearchPurpose {
    FetalGrowthAssessment = "Vaisiaus (-ių) augimo vertinimas",
    FetalConditionAssessment = "Vaisiaus būklės vertinimas",
    CirculationAssessment = "Kraujotakos vertinimas",
    AmnioticFluidContentMeasurement = "Vaisiaus vandenų kiekio matavimas",
    CervicalMeasurement = "Gimdos kaklelio matavimas",
    FetalDevelopmentClarification = "Vaisiaus vystymosi (anatomijos) tikslinimas",
    MarkersEvaluationFetalChromosomalAbnormalities = "Vaisiaus chromosominių anomalijų žymenų vertinimas",
    FetalEchocardiography = "Vaisiaus echokardiografija",
    FetalDynamicsPathologyAssessment = "Vertinimas dinamikoje dėl vaisiaus patologijos",
    Other = "Kita",
}

export enum AppearanceUterineAppendages {
    Norm = "Norma",
    NotStudied = "Netirta",
    Pathology = "Patalogija",
}

export enum Conclusions {
    Norm = "Norma, atliktas visas ištyrimas",
    NotFinished = "Norma, atliktas ne visas ištyrimas",
    Pathology = "Patalogija",
}

export enum PlacentaLocalization {
    Front = "Priekinėje sienoje",
    Back = "Užpakalinėje sienoje",
    High = "Aukštai",
    Low = "Žemai",
    onGround = "Dugne",
}

export enum Placenta {
    NotCover = "Nedengia vidinių gimdos kaklelio žiočių",
    Cover = "Dengia vidines gimdos kaklelio žiotis",
    Other = "Kita",
}

export enum PlacentaPathologicalAttachment {
    No = "Nėra",
    Suspected = "Įtariamas",
    Yes = "Yra",
}

export enum FetalPosition {
    Longitudinal = "Išilginė",
    Diagonal = "Įstrižinė",
    Transverse = "Skersinė",
    Fickle = "Nepastovi",
}

export enum FetalForerunner {
    Head = "Galvos",
    Buttocks = "Sėdmenų",
    Legs = "Kojų",
    Other = "Kita",
}

export enum BloodFlowTest {
    Norm = "Norma",
    Low = "Žema diastolė",
    Zero = "Nulinė kraujotaka",
    Reversible = "Reversinė (grįžtamoji)",
}
