ng1App.factory('AttachmentsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/attachments';
    return $resource('', {}, {
        getAttachments: {
            method: 'GET',
            url: URL_BASE + '/:compositionId'
        },
        removeAttachment: {
            method: 'PUT',
            url: URL_BASE + '/:attachmentId'
        }
    });
}]);

ng1App.service('AttachmentsUploader', ['$upload', 'apiUrl', function ($upload, apiUrl) {
    this.upload = function (compositionId, form, file, successCb, errorCb) {
        $upload.upload({
            url: apiUrl + '/attachments/' + compositionId + '/add-file',
            data: {data: form},
            file: file
        }).progress(function (evt) {
        }).success(function (data, status, headers, config) {
            (successCb || angular.noop)();
        }).error(function () {
            (errorCb || angular.noop)();
        });
    };
}]);