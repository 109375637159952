import { Component, OnInit } from "@angular/core";
import {
    DpAspnContextService,
    QuestionaireClassifier,
    QuestionaireClassifiers,
    QuestionaireDto,
} from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RouteParams } from "../../../../../ng1.routeParams";
import { ApiV2Patients } from "src/app/shared/services/api-v2-patients";
import { PatientModel } from "src/app/shared/models/patient.model";
import { firstValueFrom } from "rxjs";
import { ApiV2Documents } from "src/api/api-v2-documents";
import { EntityModel } from "src/app/shared/models/entity.model";
import { log } from "console";
import { ActivatedRoute } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { ApiV2Session } from "src/api/api-v2-session";
import { ApiV2Drafts } from "src/api/api-V2-drafts";

@Component({
    selector: "dp-nursing-needs-assessment-questionnaire",
    templateUrl: "./nursing-needs-assessment-questionnaire.component.html",
})
export class NursingNeedsAssessmentQuestionnaireComponent implements OnInit {
    public questionaireClassifiers: QuestionaireClassifiers;
    public resultString: string;
    public result: number;
    public questionnaire: FormGroup;
    patient: PatientModel;

    questionnaireId: string;
    encounterId: string;
    practitionerQualification: any;

    minDate: Date = new Date();

    fgClsMap: Map<string, string> = new Map<string, string>();

    constructor(
        private dpAspnContextService: DpAspnContextService,
        private params: RouteParams,
        private apiV2Patients: ApiV2Patients,
        private apiV2Documents: ApiV2Documents,
        private apiV2Session: ApiV2Session,
        private apiV2Drafts: ApiV2Drafts
    ) {
        this.fgClsMap?.set("selfCare", "nursing-self-care-assessment");
        this.fgClsMap?.set("eatingDrinking", "nursing-eat-drink-self-care-assessment");
        this.fgClsMap?.set("hygiene", "nursing-hygene-self-care-assessment");
        this.fgClsMap?.set("dressingUp", "nursing-dressing-self-care-assessment");
        this.fgClsMap?.set("vision", "nursing-vision-assessment");
        this.fgClsMap?.set("hearing", "nursing-hearing-assessment");
        this.fgClsMap?.set("bodyPosition", "nursing-body-position-assessment");
        this.fgClsMap?.set("movement", "nursing-body-mobility-assessment");
        this.fgClsMap?.set("fallingRisk", "nursing-body-falling-risk-assessment");
        this.fgClsMap?.set("movementCoordination", "nursing-body-movements-coordination-assessment");
        this.fgClsMap?.set("balance", "nursing-balance-assessment");
        this.fgClsMap?.set("timeOrentation", "nursing-time-orientation-assessment");
        this.fgClsMap?.set("spaceOrentation", "nursing-space-orientation-assessment");
        this.fgClsMap?.set("selfAwareness", "nursing-self-perception-assessment");
        this.fgClsMap?.set("consciousness", "nursing-consciousness-assessment");
        this.fgClsMap?.set("language", "nursing-speach-assessment");
        this.fgClsMap?.set("thoughtsExpression", "nursing-thoughts-expression-assessment");
        this.fgClsMap?.set("infoPerception", "nursing-information-perception-assessment");
        this.fgClsMap?.set("decisionMaking", "nursing-decision-making-assessment");
        this.fgClsMap?.set("medicationUse", "nursing-medication-use-assessment");
        this.fgClsMap?.set("enviromentAdaptation", "nursing-environment-adaptation-assessment");
        this.fgClsMap?.set("specCare", "nursing-special-care-assessment");
        this.fgClsMap?.set("nursingServices", "nurse-service-assessment");
        this.fgClsMap?.set("nurseServicesRegularity", "nurse-service-regularity-assessment");
        this.fgClsMap?.set("nursingAssistantServices", "nurse-assistant-service-assessment");
        this.fgClsMap?.set("nurseAssistanrServicesRegularity", "nurse-assistant-service-regularity-assessment");
        this.fgClsMap?.set("physiotherapistServices", "nursing-physiotherapist-service-assessment");
        this.fgClsMap?.set("socialServices", "nursing-social-service-assessment");
        this.fgClsMap?.set("relativesPersonalCare", "nursing-relative-care-assessment");
        this.fgClsMap?.set("aspnRequirement", "nursing-need-assessment");
        this.fgClsMap?.set("totalScore", "totalScore");
        this.fgClsMap?.set("date", "date");
    }

    compareByEntityCodeAsc(a: EntityModel, b: EntityModel) {
        return Number(a.code) - Number(b.code);
    }

    getParamValueQueryString(paramName) {
        const url = window.location.href;
        let paramValue;
        if (url.includes("?")) {
            const httpParams = new HttpParams({ fromString: url.split("?")[1] });
            paramValue = httpParams.get(paramName);
        }
        return paramValue;
    }

    async ngOnInit(): Promise<void> {
        this.encounterId = this.getParamValueQueryString("encounterId");
        this.apiV2Session.getRepresentative();

        await firstValueFrom(this.apiV2Patients.getPatient(this.params.patientId)).then((val) => {
            this.patient = val;
        });

        this.questionnaireId = this.params.questionnaireId;
        // this.encounterId = this.route.snapshot.queryParamMap.get('filter');

        await firstValueFrom(this.apiV2Documents.getPrefilledForm("nsaq")).then((val) => {
            this.questionaireClassifiers = (val as any).assessmentOptions;
            this.practitionerQualification = (val as any).practitionerQualification;
            for (const [key, value] of Object.entries(this.questionaireClassifiers)) {
                value.sort(this.compareByEntityCodeAsc);
            }
        });

        //this.questionaireClassifiers = await this.dpAspnContextService.getQuestionaireClassifiers();

        this.questionnaire = new FormGroup({
            selfCare: new FormControl(this.questionaireClassifiers["nursing-self-care-assessment"][0].code),
            eatingDrinking: new FormControl(
                this.questionaireClassifiers["nursing-eat-drink-self-care-assessment"][0].code
            ),
            hygiene: new FormControl(this.questionaireClassifiers["nursing-hygene-self-care-assessment"][0].code),
            dressingUp: new FormControl(this.questionaireClassifiers["nursing-dressing-self-care-assessment"][0].code),
            vision: new FormControl(this.questionaireClassifiers["nursing-vision-assessment"][0].code),
            hearing: new FormControl(this.questionaireClassifiers["nursing-hearing-assessment"][0].code),
            bodyPosition: new FormControl(this.questionaireClassifiers["nursing-body-position-assessment"][0].code),
            movement: new FormControl(this.questionaireClassifiers["nursing-body-mobility-assessment"][0].code),
            fallingRisk: new FormControl(this.questionaireClassifiers["nursing-body-falling-risk-assessment"][0].code),
            movementCoordination: new FormControl(
                this.questionaireClassifiers["nursing-body-movements-coordination-assessment"][0].code
            ),
            balance: new FormControl(this.questionaireClassifiers["nursing-balance-assessment"][0].code),
            timeOrentation: new FormControl(
                this.questionaireClassifiers["nursing-time-orientation-assessment"][0].code
            ),
            spaceOrentation: new FormControl(
                this.questionaireClassifiers["nursing-space-orientation-assessment"][0].code
            ),
            selfAwareness: new FormControl(this.questionaireClassifiers["nursing-self-perception-assessment"][0].code),
            consciousness: new FormControl(this.questionaireClassifiers["nursing-consciousness-assessment"][0].code),
            language: new FormControl(this.questionaireClassifiers["nursing-speach-assessment"][0].code),
            thoughtsExpression: new FormControl(
                this.questionaireClassifiers["nursing-thoughts-expression-assessment"][0].code
            ),
            infoPerception: new FormControl(
                this.questionaireClassifiers["nursing-information-perception-assessment"][0].code
            ),
            decisionMaking: new FormControl(this.questionaireClassifiers["nursing-decision-making-assessment"][0].code),
            medicationUse: new FormControl(this.questionaireClassifiers["nursing-medication-use-assessment"][0].code),
            enviromentAdaptation: new FormControl(
                this.questionaireClassifiers["nursing-environment-adaptation-assessment"][0].code
            ),
            specCare: new FormControl(false),
            nursingServices: new FormControl(this.questionaireClassifiers["nurse-service-assessment"][0].code),
            nurseServicesRegularity: new FormControl(
                this.questionaireClassifiers["nurse-service-regularity-assessment"][0].code
            ),
            nursingAssistantServices: new FormControl(
                this.questionaireClassifiers["nurse-assistant-service-assessment"][0].code
            ),
            nurseAssistanrServicesRegularity: new FormControl(
                this.questionaireClassifiers["nurse-assistant-service-regularity-assessment"][0].code
            ),
            physiotherapistServices: new FormControl(
                this.questionaireClassifiers["nursing-physiotherapist-service-assessment"][0].code
            ),
            socialServices: new FormControl(this.questionaireClassifiers["nursing-social-service-assessment"][0].code),
            relativesPersonalCare: new FormControl(
                this.questionaireClassifiers["nursing-relative-care-assessment"][0].code
            ),
            aspnRequirement: new FormControl(this.questionaireClassifiers["nursing-need-assessment"][0].code),
            totalScore: new FormControl(2),
            date: new FormControl(new Date().toISOString(), [Validators.required]),
        });

        if (this.questionnaireId) {
            await firstValueFrom(this.apiV2Documents.getByComposition(this.questionnaireId)).then((val) => {
                this.questionnaire = new FormGroup({
                    selfCare: new FormControl(val.assessments["nursing-self-care-assessment"].code),
                    eatingDrinking: new FormControl(val.assessments["nursing-eat-drink-self-care-assessment"].code),
                    hygiene: new FormControl(val.assessments["nursing-hygene-self-care-assessment"].code),
                    dressingUp: new FormControl(val.assessments["nursing-dressing-self-care-assessment"].code),
                    vision: new FormControl(val.assessments["nursing-vision-assessment"].code),
                    hearing: new FormControl(val.assessments["nursing-hearing-assessment"].code),
                    bodyPosition: new FormControl(val.assessments["nursing-body-position-assessment"].code),
                    movement: new FormControl(val.assessments["nursing-body-mobility-assessment"].code),
                    fallingRisk: new FormControl(val.assessments["nursing-body-falling-risk-assessment"].code),
                    movementCoordination: new FormControl(
                        val.assessments["nursing-body-movements-coordination-assessment"].code
                    ),
                    balance: new FormControl(val.assessments["nursing-balance-assessment"].code),
                    timeOrentation: new FormControl(val.assessments["nursing-time-orientation-assessment"].code),
                    spaceOrentation: new FormControl(val.assessments["nursing-space-orientation-assessment"].code),
                    selfAwareness: new FormControl(val.assessments["nursing-self-perception-assessment"].code),
                    consciousness: new FormControl(val.assessments["nursing-consciousness-assessment"].code),
                    language: new FormControl(val.assessments["nursing-speach-assessment"].code),
                    thoughtsExpression: new FormControl(val.assessments["nursing-thoughts-expression-assessment"].code),
                    infoPerception: new FormControl(val.assessments["nursing-information-perception-assessment"].code),
                    decisionMaking: new FormControl(val.assessments["nursing-decision-making-assessment"].code),
                    medicationUse: new FormControl(val.assessments["nursing-medication-use-assessment"].code),
                    enviromentAdaptation: new FormControl(
                        val.assessments["nursing-environment-adaptation-assessment"].code
                    ),
                    specCare: new FormControl(
                        val.assessments["nurse-service-assessment"].value === "4" ? "true" : "false"
                    ),
                    nursingServices: new FormControl(val.assessments["nurse-service-assessment"].code),
                    nurseServicesRegularity: new FormControl(
                        val.assessments["nurse-service-regularity-assessment"].code
                    ),
                    nursingAssistantServices: new FormControl(
                        val.assessments["nurse-assistant-service-assessment"].code
                    ),
                    nurseAssistanrServicesRegularity: new FormControl(
                        val.assessments["nurse-assistant-service-regularity-assessment"].code
                    ),
                    physiotherapistServices: new FormControl(
                        val.assessments["nursing-physiotherapist-service-assessment"].code
                    ),
                    socialServices: new FormControl(val.assessments["nursing-social-service-assessment"].code),
                    relativesPersonalCare: new FormControl(val.assessments["nursing-relative-care-assessment"].code),
                    aspnRequirement: new FormControl(val.assessments["nursing-need-assessment"].code),
                    totalScore: new FormControl(2),
                    date: new FormControl(val.assessmentResult.date, [Validators.required]),
                });
            });
            // this.fromQuestionaireDto(await this.apiV2Documents.apiUrlgetQuestionaireById(this.questionnaireId));
        }
        // else if(this.encounterId) {
        //     // this.resolveFormInputsValue(this.questionaireClassifiers);
        // } else {

        // }

        this.countResult();
    }

    isSpecCareCheked = false;

    getResultStringByResult(result: number): string {
        let resultString = result + " balai. ";
        this.questionaireClassifiers["nursing-need-assessment"].forEach((val, i, arr) => {
            const fromTo = val.value.split("-");
            if (fromTo.length > 1 && Number(fromTo[0].trim()) <= result && Number(fromTo[1].trim()) >= result) {
                resultString = resultString + val.name + ".";
                this.questionnaire.getRawValue().aspnRequirement = val.code;
            }
        });
        return resultString;
    }

    countResult() {
        (this.questionnaire.controls.specCare.value && (this.result = 4)) || (this.result = 0);
        Object.getOwnPropertyNames(this.questionnaire.controls).forEach((pn) => {
            pn !== "specCare" &&
            pn !== "aspnRequirement" &&
            pn !== "date" &&
            pn !== "totalScore" &&
            this.questionaireClassifiers[this.fgClsMap.get(pn)].length > 0 &&
            (this.result += Number(
                (this.questionaireClassifiers[this.fgClsMap.get(pn)] as Array<QuestionaireClassifier>).find(
                    (cv) => cv.code === this.questionnaire.controls[pn].value
                ).value
            ));
        });
        this.questionnaire.get("totalScore").setValue(this.result);
        this.resultString = this.getResultStringByResult(this.result);
        this.apiV2Drafts.findDrafts(this.patient.id.toString()).subscribe((val) => {});
    }

    onSave() {
        if (this.questionnaireId) {
            this.apiV2Documents.updatePartial(this.toFormDto(), "nsaq").subscribe((val) => {});
        } else if (this.encounterId) {
            this.apiV2Documents.createPartial(this.toFormDto(), "nsaq").subscribe((val) => {});
        }
        console.log("Resultatai: ", this.toQuestionaireDto());
        console.log("Resultatai (formGroup): ", this.questionnaire.value);
    }

    onDraftSave() {
        this.apiV2Drafts.saveDraft(this.toFormDto(), "nsaq", this.patient.id.toString()).subscribe((val) => {});

        // console.log("Resultatai: ", this.toQuestionaireDto());
        console.log("Resultatai (formGroup): ", this.questionnaire.value);
    }

    toQuestionaireDto(): QuestionaireDto {
        return {
            patient: this.patient,
            selfCare: this.questionaireClassifiers.selfCare.find(
                (item) => item.code === this.questionnaire.controls.selfCare.value
            ),
            eatingDrinking: this.questionaireClassifiers.eatingDrinking.find(
                (item) => item.code === this.questionnaire.controls.eatingDrinking.value
            ),
            hygiene: this.questionaireClassifiers.hygiene.find(
                (item) => item.code === this.questionnaire.controls.hygiene.value
            ),
            dressingUp: this.questionaireClassifiers.dressingUp.find(
                (item) => item.code === this.questionnaire.controls.dressingUp.value
            ),
            vision: this.questionaireClassifiers.vision.find(
                (item) => item.code === this.questionnaire.controls.vision.value
            ),
            hearing: this.questionaireClassifiers.hearing.find(
                (item) => item.code === this.questionnaire.controls.hearing.value
            ),
            bodyPosition: this.questionaireClassifiers.bodyPosition.find(
                (item) => item.code === this.questionnaire.controls.bodyPosition.value
            ),
            movement: this.questionaireClassifiers.movement.find(
                (item) => item.code === this.questionnaire.controls.movement.value
            ),
            fallingRisk: this.questionaireClassifiers.fallingRisk.find(
                (item) => item.code === this.questionnaire.controls.fallingRisk.value
            ),
            movementCoordination: this.questionaireClassifiers.movementCoordination.find(
                (item) => item.code === this.questionnaire.controls.movementCoordination.value
            ),
            balance: this.questionaireClassifiers.balance.find(
                (item) => item.code === this.questionnaire.controls.balance.value
            ),
            timeOrentation: this.questionaireClassifiers.timeOrentation.find(
                (item) => item.code === this.questionnaire.controls.timeOrentation.value
            ),
            spaceOrentation: this.questionaireClassifiers.spaceOrentation.find(
                (item) => item.code === this.questionnaire.controls.spaceOrentation.value
            ),
            selfAwareness: this.questionaireClassifiers.selfAwareness.find(
                (item) => item.code === this.questionnaire.controls.selfAwareness.value
            ),
            consciousness: this.questionaireClassifiers.consciousness.find(
                (item) => item.code === this.questionnaire.controls.consciousness.value
            ),
            language: this.questionaireClassifiers.language.find(
                (item) => item.code === this.questionnaire.controls.language.value
            ),
            thoughtsExpression: this.questionaireClassifiers.thoughtsExpression.find(
                (item) => item.code === this.questionnaire.controls.thoughtsExpression.value
            ),
            infoPerception: this.questionaireClassifiers.infoPerception.find(
                (item) => item.code === this.questionnaire.controls.infoPerception.value
            ),
            decisionMaking: this.questionaireClassifiers.decisionMaking.find(
                (item) => item.code === this.questionnaire.controls.decisionMaking.value
            ),
            medicationUse: this.questionaireClassifiers.medicationUse.find(
                (item) => item.code === this.questionnaire.controls.medicationUse.value
            ),
            enviromentAdaptation: this.questionaireClassifiers.enviromentAdaptation.find(
                (item) => item.code === this.questionnaire.controls.enviromentAdaptation.value
            ),
            specCare: this.questionnaire.controls.specCare.value,
            nursingServices: this.questionaireClassifiers.nursingServices.find(
                (item) => item.code === this.questionnaire.controls.nursingServices.value
            ),
            nurseServicesRegularity: this.questionaireClassifiers.nurseServicesRegularity.find(
                (item) => item.code === this.questionnaire.controls.nurseServicesRegularity.value
            ),
            nursingAssistantServices: this.questionaireClassifiers.nursingAssistantServices.find(
                (item) => item.code === this.questionnaire.controls.nursingAssistantServices.value
            ),
            nurseAssistanrServicesRegularity: this.questionaireClassifiers.nurseAssistanrServicesRegularity.find(
                (item) => item.code === this.questionnaire.controls.nurseAssistanrServicesRegularity.value
            ),
            physiotherapistServices: this.questionaireClassifiers.physiotherapistServices.find(
                (item) => item.code === this.questionnaire.controls.physiotherapistServices.value
            ),
            socialServices: this.questionaireClassifiers.socialServices.find(
                (item) => item.code === this.questionnaire.controls.socialServices.value
            ),
            relativesPersonalCare: this.questionaireClassifiers.relativesPersonalCare.find(
                (item) => item.code === this.questionnaire.controls.relativesPersonalCare.value
            ),
            aspnRequirement: this.questionaireClassifiers.aspnRequirement.find(
                (item) => item.code === this.questionnaire.controls.aspnRequirement.value
            ),
            totalScore: this.questionnaire.controls.totalScore.value,
            date: this.questionnaire.controls.date.value,
        };
    }

    toFormDto(): any {
        const formData: any = {};
        Object.getOwnPropertyNames(this.questionnaire.controls).forEach((c) => {
            if (c !== "specCare" && c !== "aspnRequirement" && c !== "date" && c !== "totalScore") {
                formData[this.fgClsMap.get(c)] = this.questionaireClassifiers[this.fgClsMap.get(c)].find(
                    (q) => q.code == this.questionnaire.controls[c].value
                );
            }
        });

        this.questionaireClassifiers["nursing-special-care-assessment"] && this.questionnaire.controls["specCare"].value
            ? (formData["nursing-special-care-assessment"] =
                this.questionaireClassifiers["nursing-special-care-assessment"][1])
            : (formData["nursing-special-care-assessment"] =
                this.questionaireClassifiers["nursing-special-care-assessment"][0]);
        return {
            patientId: this.patient.id,
            encounterRef: this.encounterId,
            assessments: formData,
            practitionerQualification: this.practitionerQualification,
            assessmentResult: {
                date: this.questionnaire.controls.date.value,
                score: this.questionnaire.controls.totalScore.value,
                result: this.questionaireClassifiers["nursing-need-assessment"].find(
                    (item) => item.code === this.questionnaire.controls.aspnRequirement.value
                ),
            },
        };
    }

    fromQuestionaireDto(val: QuestionaireDto): void {
        this.questionnaire.controls.selfCare.setValue(val.selfCare.code);
        this.questionnaire.controls.eatingDrinking.setValue(val.eatingDrinking.code);
        this.questionnaire.controls.hygiene.setValue(val.hygiene.code);
        this.questionnaire.controls.dressingUp.setValue(val.dressingUp.code);
        this.questionnaire.controls.vision.setValue(val.vision.code);
        this.questionnaire.controls.hearing.setValue(val.hearing.code);
        this.questionnaire.controls.bodyPosition.setValue(val.bodyPosition.code);
        this.questionnaire.controls.movement.setValue(val.movement.code);
        this.questionnaire.controls.fallingRisk.setValue(val.fallingRisk.code);
        this.questionnaire.controls.movementCoordination.setValue(val.movementCoordination.code);
        this.questionnaire.controls.balance.setValue(val.balance.code);
        this.questionnaire.controls.timeOrentation.setValue(val.timeOrentation.code);
        this.questionnaire.controls.spaceOrentation.setValue(val.spaceOrentation.code);
        this.questionnaire.controls.selfAwareness.setValue(val.selfAwareness.code);
        this.questionnaire.controls.consciousness.setValue(val.consciousness.code);
        this.questionnaire.controls.language.setValue(val.language.code);
        this.questionnaire.controls.thoughtsExpression.setValue(val.thoughtsExpression.code);
        this.questionnaire.controls.infoPerception.setValue(val.infoPerception.code);
        this.questionnaire.controls.decisionMaking.setValue(val.decisionMaking.code);
        this.questionnaire.controls.medicationUse.setValue(val.medicationUse.code);
        this.questionnaire.controls.enviromentAdaptation.setValue(val.enviromentAdaptation.code);
        this.questionnaire.controls.specCare.setValue(val.specCare);
        this.questionnaire.controls.nurseServicesRegularity.setValue(val.nurseServicesRegularity.code);
        this.questionnaire.controls.nursingAssistantServices.setValue(val.nursingAssistantServices.code);
        this.questionnaire.controls.nurseAssistanrServicesRegularity.setValue(
            val.nurseAssistanrServicesRegularity.code
        );
        this.questionnaire.controls.physiotherapistServices.setValue(val.physiotherapistServices.code);
        this.questionnaire.controls.socialServices.setValue(val.socialServices.code);
        this.questionnaire.controls.relativesPersonalCare.setValue(val.relativesPersonalCare.code);
        this.questionnaire.controls.aspnRequirement.setValue(val.aspnRequirement.code);
        this.questionnaire.controls.date.setValue(new Date(val.date));
    }
}

interface QuestionaireValue {
    selfCare?: string;
    eatingDrinking?: string;
    hygiene?: string;
    dressingUp?: string;
    vision?: string;
    hearing?: string;
    bodyPosition?: string;
    movement?: string;
    fallingRisk?: string;
    movementCoordination?: string;
    balance?: string;
    timeOrentation?: string;
    spaceOrentation?: string;
    selfAwareness?: string;
    consciousness?: string;
    language?: string;
    thoughtsExpression?: string;
    infoPerception?: string;
    decisionMaking?: string;
    medicationUse?: string;
    enviromentAdaptation?: string;
    specCare?: boolean;
    nursingServices?: string;
    nurseServicesRegularity?: string;
    nursingAssistantServices?: string;
    nurseAssistanrServicesRegularity?: string;
    physiotherapistServices?: string;
    socialServices?: string;
    relativesPersonalCare?: string;
    aspnRequirement?: string;
    date?: string;
}
