import {ErrorHandler, Injectable, NgZone} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "./utils/message.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private messages: MessageService,
        private zone: NgZone
    ) {}

    handleError(error: any) {
        // Check if it's an error from an HTTP response
        if (!(error instanceof HttpErrorResponse)) {
            error = error.rejection; // get the error object
        }

        this.zone.run(() =>
            this.messages.handleError(error?.error)
        );

        console.error('Error from global error handler', error);
    }
}