import { Component, Input } from '@angular/core';
import { ApiV2E096 } from '../../../../../../../api/api-v2-doc-ref-e096';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { E096PartData, Entry } from '../../../../models/e096.model';
import moment from 'moment';

@Component({
    selector: 'app-e096-part-table',
    templateUrl: './e096-part-table.component.html',
})
export class E096PartTableComponent {
    @Input() showDescription: boolean = false;

    initialFormValue: Entry[];
    startTime: string | Date;
    phase: string;
    latentColumns: string[] = [];
    activeColumns: string[] = [];
    latentData: Entry[] = [];
    activeData: Entry[] = [];
    firstActiveColumn: number;
    lastLatentColumn: number;

    private patientId: string;

    constructor(
        private apiV2E096: ApiV2E096
    ) {
        this.apiV2E096.getE096FormView('part', this.patientId).pipe(takeUntilDestroyed())
            .subscribe((res: E096PartData) => {
                const sortArray = (arr: Entry[]): Entry[] => arr.sort((a, b) => new Date(b.timeOfEntry).getTime() - new Date(a.timeOfEntry).getTime())
                this.initialFormValue = sortArray(res.entries);
                this.activeData = res.entries.filter(item => +item.cervixOpening >= 4);
                this.latentData = res.entries.filter(item => +item.cervixOpening < 4).reverse();
                this.latentData.push(this.activeData.at(-1));

                const timeOfStartActivePhase = moment(this.activeData.at(-1).timeOfEntry)
                    .subtract('hours', +this.activeData.at(-1).cervixOpening - 4).toISOString();
                const additionalHourByPeriod = this.getPeriod(this.activeData.at(-1).timeOfEntry) > 1 ? 11 : 10;
                const durationOfActivePhase = Math.abs(moment(this.activeData.at(-1).timeOfEntry).diff(moment(this.activeData.at(0).timeOfEntry), 'hours'));
                const endOfActivePhase = Math.max(durationOfActivePhase, additionalHourByPeriod);

                this.latentColumns = this.latentData.map(item => moment(item.timeOfEntry).hours().toString());
                this.activeColumns = this.generateHourArray(timeOfStartActivePhase, endOfActivePhase);
                this.startTime = this.initialFormValue.at(-1).timeOfEntry;

                this.firstActiveColumn = this.activeColumns.indexOf(`${moment(this.activeData.at(-1).timeOfEntry).hours()}`);
                this.lastLatentColumn = this.latentColumns.indexOf(`${moment(this.activeData.at(-1).timeOfEntry).hours()}`);
            });
    }

    private generateHourArray(startTime: string | Date, limit: number = 10): Array<string> {
        const hoursArray: string[] = [];
        let currentHour = moment(startTime).hours();

        for (let i = 0; i < limit; i++) {
            hoursArray.push(`${currentHour % 24}`);
            currentHour = (currentHour + 1) % 24;
        }

        return hoursArray;
    }

    private getPeriod(time: string | Date): number {
        return Math.floor((moment(time).minutes() % 60) / 15) + 1;
    }
}
