<table
    class="table table-bordered table-hover w-100 overflow-x-scroll mb-0 position-relative shadow-none"
    appLineConnector
    [connections]="connections"
>
    <tbody>
    <ng-container *ngFor="let row of rowsCounter; let i = index">
            <tr *ngIf="!i" class="text-center d-flex part-table-row border-0" [class.mt-2]="!i">
                <td *ngFor="let column of mainColumns" class="p-0 flex-1 border-0 part-table-cell">
                    <div class="d-flex border-0 h-100">
                        <div class="p-2 px-1 flex-1">
                            15
                        </div>
                        <div class="p-2 px-1 flex-1">
                            45
                        </div>
                    </div>
                </td>
            </tr>

            <div class="position-absolute m-2 z-1 bg-white" *ngIf="!i">
                {{ (phase === 'latent'
                ? 'doc.pregnancyDashboard.e096Part.partogram.latentPhase'
                : 'doc.pregnancyDashboard.e096Part.partogram.activePhase') | translate}}
            </div>
            <tr class="text-center d-flex part-table-row border-bottom"
                [class.part-table-row]="!!i"
                [class.border-0]="!i"
                [style.height.px]="!i && 0"
            >
                <ng-container *ngTemplateOutlet="leftYAxis; context: {i}" />

                <td *ngFor="let column of mainColumns; let j = index" class="p-0 flex-1 h-0 part-table-cell">
                    <ng-container *ngTemplateOutlet="linesTitle; context: {i, j}" />
                    <ng-container *ngTemplateOutlet="tdCells;
                    context: {row, column, id: phase + 'Cell-' + j, value: 10 - i }"></ng-container>
                </td>

                <ng-container *ngTemplateOutlet="rightYAxis; context: {i}" />
            </tr>
    </ng-container>
    </tbody>
</table>

<ng-template #tdCells let-row="row" let-column="column" let-id="id" let-value="value">
    <div class="d-flex border-light h-100">
        <div [class.p-2]="row != 0" class="px-1 flex-1 position-relative" [id]="id + '-' + 1 + '-' + value">
            <div class="part-table-cell-mark" *ngIf="getDataByHour(column, 1, value)">
                <i class="fa fa-times fs-3" aria-hidden="true"></i>
            </div>
            <ng-container *ngTemplateOutlet="headLevel; context: {column, value, period: 1}" />
        </div>
        <div [class.p-2]="row != 0" class="border-start px-1 flex-1 position-relative" [id]="id + '-' + 2 + '-' + value">
            <div class="part-table-cell-mark" *ngIf="getDataByHour(column, 2, value)">
                <i class="fa fa-times fs-3" aria-hidden="true"></i>
            </div>
            <ng-container *ngTemplateOutlet="headLevel; context: {column, value, period: 2}" />
        </div>
        <div [class.p-2]="row != 0" class="border-start px-1 flex-1 position-relative" [id]="id + '-' + 3 + '-' + value">
            <div class="part-table-cell-mark" *ngIf="getDataByHour(column, 3, value)">
                <i class="fa fa-times fs-3" aria-hidden="true"></i>
            </div>
            <ng-container *ngTemplateOutlet="headLevel; context: {column, value, period: 3}" />
        </div>
        <div [class.p-2]="row != 0" class="border-start px-1 flex-1 position-relative" [id]="id + '-' + 4 + '-' + value">
            <div class="part-table-cell-mark" *ngIf="getDataByHour(column, 4, value)">
                <i class="fa fa-times fs-3" aria-hidden="true"></i>
            </div>
            <ng-container *ngTemplateOutlet="headLevel; context: {column, value, period: 4}" />
        </div>
    </div>
</ng-template>

<ng-template #headLevel let-column="column" let-value="value" let-period="period">
    <div class="part-table-cell-mark fs-3" *ngIf="getHeadLevelDataByHour(column, period, value) as val">
        <span *ngIf="val === 'head'">&#9711;</span>
        <img *ngIf="val === 'seat'" src="assets/img/seat.png" alt="seat" width="24px">
    </div>
</ng-template>

<ng-template #leftYAxis let-i="i">
    <div *ngIf="phase === 'latent'" class="position-absolute end-100" [class.part-table-value-on-border]="i" [class.part-table-value-on-border-first]="!i">
        {{10 - i}}
    </div>
</ng-template>

<ng-template #rightYAxis let-i="i">
    <div *ngIf="phase === 'active' " class="position-absolute start-100 part-table-value-on-border">
        {{getKeyByValue(10 - i)}}
    </div>
</ng-template>

<ng-template #linesTitle let-i="i" let-j="j">
    <div class="position-absolute ms-3 mt-4 p-0 z-1 bg-white" *ngIf="i === 4 && phase === 'active' && j === 0">
        {{'doc.pregnancyDashboard.e096Part.partogram.attentionLine' | translate}}
    </div>
    <div class="position-absolute ms-3 mt-4 p-0 z-1 bg-white" *ngIf="i === 4 && phase === 'active' && j === 4">
        {{'doc.pregnancyDashboard.e096Part.partogram.actionLine' | translate}}
    </div>
</ng-template>
