import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {PatientModel} from "../../../shared/models/patient.model";
import {ApiV2Practitioners} from "../../../../api/api-v2-practitioners";
import {PractitionerModel} from "../../../shared/models/practitioner.model";
import {DpSessionContext} from "../../services/dp-session-context.service";
import {OrganizationModel} from "../../../shared/models/organization.model";
import {DocumentTagEnum} from "../../../shared/models/document-tag.enum";

@Component({
    selector: 'certificate-modal',
    templateUrl: 'certificate-modal.component.html'
})
export class CertificateModalComponent implements OnInit {
    @Input() parentModalId: string;
    @Input() parentModalCloseId: string;
    certificateModalId: string = "certificateModal";
    certificateModalCloseId: string = "certificateClose";
    createDialogErr = {msg: null};
    @Input() patient: PatientModel;
    practitioners: PractitionerModel[];
    parentClose: HTMLElement;
    childClose: HTMLElement;
    finalCertificateAuthor = null;
    finalAuthorSameAsCurrent = true;
    finalCertificateAuthorQualification;
    additionalParams: Object;
    selectedCert: string;
    organization: OrganizationModel;

    constructor(private router: Router, private dpMainController: DpSessionContext, private apiV2Practitioners: ApiV2Practitioners) {
    }

    ngOnInit(): void {
        this.dpMainController.organizationData$.subscribe(data => {
            this.organization = data;
        });
    }

    openModal(selectedCert: string, additionalParams?: Object) {
        const childModalElement = document.getElementById(this.certificateModalId);
        const childModal = new bootstrap.Modal(childModalElement);
        this.parentClose = document.getElementById(this.parentModalCloseId);
        this.childClose = document.getElementById(this.certificateModalCloseId);

        if (childModalElement) {
            this.selectedCert = selectedCert;
            this.additionalParams = additionalParams;
            const query: Object = {
                organization: this.organization.id,
                _excludeCurrentUser: true
            };
            this.apiV2Practitioners.find(query).subscribe(data => {
                this.practitioners = data.items;
                this.parentClose.click();
                childModal.show();
            });
        }
    }

    createCertificate() {
        if (!this.finalAuthorSameAsCurrent && (!this.finalCertificateAuthor || !this.finalCertificateAuthor.id)) {
            this.createDialogErr = {msg: 'Būtina nurodyti ištyrimą atliekantį specialistą'};
            return;
        }

        let searchParams: any = {};
        _.each(this.additionalParams, function (value, key) {
            searchParams[key] = value;
        });

        if (this.finalCertificateAuthor) {
            searchParams.finalCertificateAuthor = this.finalCertificateAuthor.id;
            searchParams.qualification = this.finalCertificateAuthorQualification.code;
        }

        let url: string;
        switch (this.selectedCert) {
            case DocumentTagEnum.e047:
            case DocumentTagEnum.e048:
                url = `/dp/patients/${this.patient.id}/certificates/${this.selectedCert}_practitioner/new`;
                break;
            default:
                url = `/dp/patients/${this.patient.id}/certificates/${this.selectedCert}/new`;
                break;
        }

        this.router.navigate([url], {queryParams: searchParams});

        this.parentClose.click();
        this.childClose.click();
    }

    finalCertificateAuthorChange(practitioner, qualification) {
        this.finalCertificateAuthor = practitioner;
        this.finalCertificateAuthorQualification = qualification;
    }

    finalAuthorSameAsCurrentChange(value) {
        this.finalAuthorSameAsCurrent = value;
        this.finalCertificateAuthor = null;
    }

    searchPractitionersChange(event) {
        const query: Object = {
            search: event.target.value,
            organization: this.organization.id,
            _excludeCurrentUser: true
        };
        this.apiV2Practitioners.find(query).subscribe(data => {
            this.practitioners = data.items;
        });
    }
}