import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ConfigService} from "../../config/config.service";
import {MaterialDataModel} from "../models/material-data.model";

@Injectable({
    providedIn: "root",
})
export class ApiV2PharmMedications extends ConfigService {
    path = this.apiUrl + "/Medication";

    getMedications(params: any): Observable<any[]> {
        const url = `${this.path}/search`;
        return this.http.get<any[]>(url, {params: params});
    }

    getAllMedications(params: any): Observable<any[]> {
        const url = `${this.path}/search/all`;
        return this.http.get<any[]>(url, {params: params});
    }

    getSubstances(params: any): Observable<any[]> {
        const url = `${this.path}/substance/search`;
        return this.http.get<any[]>(url, {params: params});
    }

    getPackagesForPrescription(params: any): Observable<any[]> {
        const url = `${this.path}/packages`;
        return this.http.get<any[]>(url, {params: params});
    }

    getMedicationPackages(params: any): Observable<any[]> {
        const url = `${this.path}/packages/search`;
        return this.http.get<any[]>(url, {params: params});
    }

    getNcpProductPackages(params: any): Observable<any[]> {
        const url = `${this.path}/packages/ncp/search`;
        return this.http.get<any[]>(url, {params: params});
    }

    getCatalogue(params: any): Observable<any> {
        const url = `${this.path}/catalogue`;
        return this.http.get<any>(url, {params: params});
    }

    getMedicationMaterials(query?: any): Observable<MaterialDataModel[]> {
        const url = `${this.path}/material-document`;
        return this.http.get<MaterialDataModel[]>(url, {params: query});
    }
}