ng1App.directive('sveidraCommonMedications', 
    ['EntitiesApiFactory', 'SveidraListSubForm', 'TypeaheadSearchHandlers',
     function (EntitiesApiFactory, SveidraListSubForm, TypeaheadSearchHandlers) {
	
	// Practitioner
	var initPractitioner = function (controller) {
		controller._data.practitionerSearchParam = null;
		controller.searchForPractitioner = TypeaheadSearchHandlers.PRACTITIONERS;
		
		controller.changePractitioner = function (practitioner) {
			if (practitioner) {
				if (practitioner.id) {
					this._form.practitionerId = practitioner.id;
					this._form.practitioner = practitioner;
				}
			} else {
				this._form.practitionerId = null;
				this._form.practitioner = null;
			}
		};
	};
	
	var clearAdditionalData = function (controller) {
		controller._data.practitionerSearchParam = null;
	};
	
	var resolveDataForEdit = function (form, data) {
		// Medication
		_.each(data.medications, function (medication) {
			if (form.medication.code == medication.code) {
				form.medication = medication;
			}
		});
		
		// Practitioner
		data.practitionerSearchParam = _.getFullName(form.practitioner) + ' - ' + form.practitioner.stampCode;
	};
	
	var initForm = function (scope) {
		scope.formCtrl = new SveidraListSubForm(scope.stage[scope.listProperty], scope.region);
		
		initPractitioner(scope.formCtrl);
		
		scope.formCtrl._data.medications = EntitiesApiFactory.getList({classCode: scope.classifier, count: -1}, function (result) {
			_.each(result, function (medication) {
				medication.label = _.getCodeName(medication);
			});
		});
		
		scope.formCtrl.before = function (action) {
            if (action == 'cancel') {
                this.clear();
                clearAdditionalData(this);
            }
        };
		
		scope.formCtrl.after = function (action) {
        	if ((action == 'add' || action == 'save') && !this._validator.hasErrors()) {
        		clearAdditionalData(this);
        	} else if (action == 'edit') {
        		resolveDataForEdit(this._form, this._data);
        	}
        };
		
        scope.formCtrl.validateValue = function (form, validator) {
			if (!form.medication) {
				validator.add('medication');
			}
			if (!form.practitionerId) {
				validator.add('practitioner');
			}
			if (!form.date) {
				validator.add('date');
			} else if (!dateInDepartmentInterval(form.date, scope.stage.departments)) {
				validator.add('dateInterval');
			}
    	};
		
		scope.formCtrl.init();
	};
	
	// Validate against department intervals
	var dateInDepartmentInterval = function (date, departments) {
		if (departments) {
			var dateObject = new Date(date);
			var isInInterval = false;
			_.each(departments, function (department) {
				isInInterval ||= dateObject >= new Date(department.from) && (!department.to || dateObject <= new Date(department.to));
			});
			return isInInterval;
		}
		return false;
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/common_medications.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '=',
			listProperty: '@',
			region: '@',
			classifier: '@',
			componentName: '@',
			title: '@',
            font: '@',
            icon: '@'
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			scope.stage.$promise.then(function () {
				initForm(scope);
			});
		}
	};
}]);