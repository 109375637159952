<div class="modal-body">
    <div class="row" *ngIf="modalService.config.initialState.periodVisitId && modalService.config.initialState.visitId">
        <div class="col"><strong>Ar norite koreguoti tik šį apsilankymą ar apsilankymų seriją?</strong></div>
    </div>
    <div class="row"
        *ngIf="!modalService.config.initialState.periodVisitId && modalService.config.initialState.visitId">
        <div class="col"><strong>Ar norite koreguoti apsilankymą?</strong></div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="modalService.config.initialState.periodVisitId" type="button" class="btn btn-primary"
        data-bs-dismiss="modal"
        (click)="action(modalService.config.initialState.periodVisitId)">{{'Seriją'|translate}}</button>
    <button *ngIf="modalService.config.initialState.visitId" type="button" class="btn btn-primary"
        data-bs-dismiss="modal"
        (click)="action(modalService.config.initialState.visitId)">{{'Apsilankymą'|translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="modalService.hide()">{{'cancel'|translate}}</button>
</div>