import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'doctor-context',
    template: `
        <dp-navigation ng-hide="restrictedMode"></dp-navigation>
        <!--<dp-doctor-context ng-hide="restrictedMode"></dp-doctor-context>-->
        <nhr-msg-global></nhr-msg-global>
        <div id="wrap">
            <div id="content" role="main" class="container">
                <div app-view-segment="1"></div>
                <spinner [working]="formLoading"></spinner>
            </div>
        </div>
    `,
})
export class DpDoctorContextMainComponent implements OnInit {

    @Input() formLoading: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }
}
