ng1App.factory("SubFormListController", 
    ['$modalConfirm', 'SubFormValidator', 'MessageService',
     function ($modalConfirm, SubFormValidator, MessageService) {

    function SubFormListController(list, region) {
        this._list = list;
        this._region = region;
        this._validator = new SubFormValidator(region);
        this._index = -1;
        this._editMode = false;
        this._data = {};
        this._appendTop = false;
    }

    SubFormListController.prototype = {
        init: function () {
            this.clear();
            this._form = this.initForm();
        },
        clear: function () {
            this._validator.clear();
            MessageService.clear(this._region);
        },
        initForm: function () {
            return {};
        },
        validateForm: function (form) {
            return true;
        },
        invalidField: function (name) {
            return this._validator.invalidFields[name];
        },
        onValidateFail: function () {
            this._validator.showErrorsAndScroll();
        },
        before: function (action) {
        },
        after: function (action) {
        },
        onDelete: function (removedObj) {
        },
        add: function () {
            this.before('add');
            this.clear();
            if (this.validateForm(this._form)) {
                this._form.changed = true;
                if (this._appendTop) {
                	this._list.unshift(this._form);
                } else {
                	this._list.push(this._form);
                }
                this._form = this.initForm();
            } else {
                this.onValidateFail();
            }
            this.after('add');
        },
        save: function () {
            this.before('save');
            this.clear();
            if (this.validateForm(this._form)) {
                this._form.changed = !angular.equals(this._list[this._index], this._form);
                this._list[this._index] = this._form;
                this._form = this.initForm();
                this._editMode = false;
            } else {
                this.onValidateFail();
            }
            this.after('save');
        },
        edit: function (index) {
            this.before('edit');
            this.clear();
            this._index = index;
            this._form = angular.copy(this._list[index]);            
            this._editMode = true;
            this.after('edit');
        },
        cancel: function () {
            this.before('cancel');
            this._form = this.initForm();
            this._editMode = false;
            this.after('cancel');
        },
        handleDelete:function(index){
            this._list.splice(index, 1);
        },
        delete: function (index) {
            this.before('delete');
            var _this = this;
            $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                _this.handleDelete(index);
                _this.onDelete(_this._list[index]);
                _this.after('delete');
            });
        }
    };

    return (SubFormListController);
}]);


