ng1App.factory('SessionContext', 
    ['SessionApiFactory',
     function (SessionApiFactory) {
  return {
    practitioner: null,
    organization: null,
    init: function() {
      var self = this;
      self.practitioner = SessionApiFactory.getPractitioner({}, function (practitioner) {
        practitioner.fullName = _.getFullName(practitioner);
        self.practitioner = practitioner;
      });
      self.organization = SessionApiFactory.getOrganization({}, function (organization) {
        self.organization = organization;
      });
      return this;
    }
  };
}]);