import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";

@Injectable({
    providedIn: 'root',
})
export class ApiV2E010 extends ConfigService {

    path = this.apiUrl + '/e010';

    getE010FormView(form: string): Observable<any> {
        return this.http.get<any>(`${this.path}/${form}`);
    }
}