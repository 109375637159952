ng1App.controller('doctor.patient.cert.e049EditCtrl', 
    ['$scope', '$routeParams', '$filter', 'MessageService', 'CertificatePayment', 'OrganizationsApiFactory', 'TypeaheadSearchHandlers', 'CertificateService',
     function ($scope, $routeParams, $filter, MessageService,
                                                                CertificatePayment, OrganizationsApiFactory, TypeaheadSearchHandlers, CertificateService) {
    $scope.certificate.initialSave = $scope.initialSave;

    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.e049.mai.inf'),
                id: 'cer_e049_mai_inf'
            },
            {
                title: $filter('translate')('cer.e049.doc'),
                id: 'cer_e049_doc'
            },
            {
                title: $filter('translate')('cer.e049.har'),
                id: 'cer_e049_har'
            },
            {
                title: $filter('translate')('cer.e049.pri.pro'),
                id: 'cer_e049_pri_pro'
            },
            {
                title: $filter('translate')('cer.e049.mai.kno'),
                id: 'cer_e049_mai_kno'
            },
            {
                title: $filter('translate')('cer.e047.cons'),
                id: 'cer_e047_cons',
                show: function () {
                    if ($scope.certificate.initialSave) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e049.cons'),
                id: 'cer_e049_cons',
                show: function () {
                    if (!$scope.certificate.initialSave) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e049.fin.cons'),
                id: 'cer_e049_fin_cons'
            }
        ]
    };
    $scope.$emit('setHelpPage', 'kurti-pazyma-e049-asmens-privalomojo-sveikatos-tikrinimo-kortele');

    var certificateType = 'e049';
    CertificatePayment.showPaymentMessage(certificateType);
    $scope.compositionId = $routeParams.id;
    $scope.patientId = $routeParams.patientId;

    $scope.additional = {
        finalAuthorSameAsCurrent: false,
        occupationCode: {label: ""}
    };

    if ($scope.initialSave) {
        if (!$routeParams.finalCertificateAuthor) {
            $scope.additional.finalAuthorSameAsCurrent = true;
        }

    } else {
        if (!$scope.certificate.finalCertificateAuthor) {
            MessageService.add('error', $filter('translate')('err.cer.e049.noOrWrongFinalCertificateTask'));
        }
        if ($scope.certificate.finalCertificateAuthor && $scope.certificate.finalCertificateAuthor.id === $scope.practitioner.id) {
            $scope.additional.finalAuthorSameAsCurrent = true;
        }
    }
    $scope.conclusions = [{name: 'Dėl sveikatos būklės gali įsigyti ir turėti ginklus', id: 'conc1'},{name: 'Dėl sveikatos būklės negali įsigyti ir turėti ginklų', id: 'conc2'}];
    $scope.saveAndSign = function (doSign) {
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    $scope.savePartial = function () {
        MessageService.clear('cer-consultations-region');
        var mustBeEntities = [
            {
                // value: $scope.certificate.occupation.code,
                messageRegion: 'edit-employer',
                inputName: $filter('translate')('cer.e049.doc.prof.cod'),
                // inputValue: $scope.certificateForm.occupationCode.$viewValue
            }
        ];
        CertificateService.savePartial(certificateType, $scope.certificate, $scope.certificateForm, mustBeEntities);
    };

    var currentDate = new Date();
    var expireDate = new Date(new Date().setFullYear(currentDate.getFullYear() + 5));
    $scope.maxCreationDate = new Date();
    $scope.minExpireDate = new Date();

    //TODO move to prefill
    if (!$scope.certificate.expireDate) {
        $scope.certificate.expireDate = expireDate.getTime();
    }

}]);