import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { OutpatientVisitDescriptionModel } from "../../../../models/outpatientVisitDescription.model";

@Component({
    selector: "app-outpatient-visit-description-template",
    templateUrl: "./outpatient-visit-description-template.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutpatientVisitDescriptionTemplateComponent {
    @Input() description?: {
        headerData: { name: string; date: string; bloodPressure: string; pulse: string };
        footerLabel: string;
        data: OutpatientVisitDescriptionModel;
    };
    @Input() closed: boolean;
    @Output() widgetToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    public womanWeightAtStart = 65;
    public currentWomenWeight = 0;

    public get getGainWeightDuringPregnancy(): string {
        const gainWeight = this.currentWomenWeight - this.womanWeightAtStart;
        return `${gainWeight > 0 ? "+" : ""}${gainWeight}`;
    }

    public toggle() {
        this.closed = !this.closed;
        this.widgetToggle.emit(this.closed);
    }
}
