<div *ngIf="formGroup">
    <h1>E097-NPA Naujagimio pirma apžiūra</h1>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Naujagimio pirma apžiūra' | translate"
        id="checkupInfo">
        <div class="row">
            <div class="col-6 col-md-2">
                <app-date-picker inputId="checkupDate" [fControl]="formGroup?.get('checkupInfo.checkupDate')"
                    [maxDate]="today" [labelText]="'Data' | translate" />
            </div>
            <div class="col-6 col-md-2">
                <app-time-picker inputId="checkupTime" [fControl]="formGroup?.get('checkupInfo.checkupTime')"
                    [labelText]="'Laikas' | translate" />
            </div>
            <div class="col-12 col-md-4">
                <app-select [id]="checkupPlaces" labelClass="fw-bold" [labelText]="'Vieta' | translate"
                    [values]="checkupPlaces" [fControl]="formGroup?.get('checkupInfo.checkupPlace')" />
            </div>
            <div class="col-12 col-md-4">
                <div>
                    <label for="specialist_name" class="form-label">{{ "Apžiūrą atliko:" | translate }}</label>
                    <div class="d-flex">
                        <input [formControl]="formGroup?.get('specialistName')" id="spceialist_name" class="form-grow-1"
                            typeaheadOptionField="fullDisplay" [typeahead]="suggestions$" [typeaheadAsync]="true"
                            [typeaheadItemTemplate]="customItemTemplate2"
                            (typeaheadOnSelect)="typeaheadOnSelectP($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)" placeholder="Įveskite specialistą"
                            (typeaheadOnBlur)="onBlurtypeahead($event)" class="form-control" />
                        <ng-template #customItemTemplate2 let-model="item">
                            <span>
                                <strong>{{ model.fullDisplay }}</strong>
                            </span>
                        </ng-template>
                        <button class="btn btn-secondary btn-sm ms-2" (click)="onClickAddSpecialist()">
                            +&nbsp;Pridėti
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <ol class="slats">
                    <li slat-item delete
                        (delete-button-click)="onClickRemoveSpecialist(i)"
                        *ngFor="let p of getSpecialistFormControls(); let i = index">
                        {{ p.fullDisplay | json }}
                    </li>
                </ol>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Apžiūros rezultatai' | translate"
        id="checkupResults">
        <div class="card mb-3">
            <div class="card-body">
                <div *ngFor="let r of getResultsArray.controls; let j = index" class="row my-3">
                    <div class="col-12 col-md-4">
                        <fieldset class="row">
                            <legend class="fw-bold form-label">{{ r.value.name | translate }}</legend>
                            <div *ngFor="let item of pathology; let i = index" class="col">
                                <app-radio-button radioName="{{ r.value.shortName }}"
                                    id="{{ r.value.shortName }}_{{ item.code }}" labelText="{{ item.name | translate }}"
                                    radioValue="{{ item.code }}" [fControl]="r.controls.selection"></app-radio-button>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12 col-md-8">
                        <app-input labelText="Komentaras" labelClass="fw-bold" type="textarea"
                            [fControl]="r.controls.comment" />
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <div class="row my-3">
                    <div class="col-12 col-md-4">
                        <fieldset class="row">
                            <legend class="fw-bold form-label">{{ "Nustatyti sklaidos trūkumai / raidos ydos" |
                                translate }}</legend>
                            <div *ngFor="let item of developmentDefectsOptions" class="col">
                                <app-radio-button radioName="developmentDefects" [labelText]="item.name"
                                    [radioTitle]="item.name" id="{{ item.name }}_{{ item.code }}"
                                    [radioValue]="item.value"
                                    [fControl]="formGroup.get('checkupResults.developmentDefects')"></app-radio-button>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12 col-md-8">
                        <app-input labelText="Komentaras" labelClass="fw-bold" type="textarea"
                            [fControl]="formGroup?.get('checkupResults.developmentDefectsInfo')" />
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-4 flex-column flex-md-row">
            <div class="card mb-3 flex-shrink-1">
                <div class="card-body py-3">
                    <fieldset>
                        <legend class="fw-bold">{{ "Naujagimis stebimas dėl" | translate }}</legend>
                        <div *ngFor="let item of newbornMonitoredFor">
                            <app-checkbox inputId="newbornMonitoredFor_{{ item.code }}"
                                (change)="onCheckboxChange(formGroup, $event, 'checkupResults', 'newbornMonitoredFor')"
                                [checkboxValue]="item.id" [labelText]="item.name" [checkboxTitle]="item.name"
                                [checkboxChecked]="
                                    formGroup.get('checkupResults.newbornMonitoredFor').value.includes(item.id)
                                "></app-checkbox>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div class="card mb-3 flex-grow-1 gap-4" [class]="
                formGroup.get('checkupResults.newbornExaminationPlan').value.includes('8') ||
                formGroup.get('checkupResults.newbornExaminationPlan').value.includes('9') ? 'flex-shrink-1' : 'flex-grow-1'
            ">
                <div class="card-body py-3">
                    <div class="d-flex gap-0 gap-lg-4 flex-column flex-lg-row">
                        <fieldset>
                            <legend class="fw-bold">{{ "Tyrimų planas" | translate }}</legend>
                            <div *ngFor="let item of newbornExaminationPlan" col-sm-12 col-md-8 d-flex flex-column>
                                <app-checkbox (change)="onCheckboxChange(
                                            formGroup,
                                            $event,
                                            'checkupResults',
                                            'newbornExaminationPlan')" inputId="newbornExaminationPlan_{{ item.code }}"
                                    [checkboxValue]="item.id" [labelText]="item.name" [checkboxTitle]="item.name"
                                    [checkboxChecked]="
                                        formGroup
                                            .get('checkupResults.newbornExaminationPlan')
                                            .value.includes(item.id)"></app-checkbox>
                            </div>
                        </fieldset>
                        <div class="flex-grow-1">
                            <fieldset
                                *ngIf="formGroup.get('checkupResults.newbornExaminationPlan').value.includes('8')">
                                <legend class="fw-bold">{{ "Ultragarsiniai tyrimai" | translate }}</legend>
                                <div *ngFor="let item of ultrasoundExaminations">
                                    <app-checkbox (change)="onCheckboxChange(
                                                formGroup,
                                                $event,
                                                'checkupResults',
                                                'ultrasoundExaminations'
                                            )" inputId="ultrasoundExaminations_{{ item.code }}" [checkboxValue]="item.id"
                                        [labelText]="item.name" [checkboxTitle]="item.name" [checkboxChecked]="
                                            formGroup
                                                .get('checkupResults.ultrasoundExaminations')
                                                .value.includes(item.id)
                                        "></app-checkbox>
                                </div>
                            </fieldset>
                            <div *ngIf="formGroup.get('checkupResults.newbornExaminationPlan').value.includes('9')">
                                <app-input labelText="Kiti tyrimai" labelClass="fw-bold" type="textarea"
                                    [fControl]="formGroup?.get('checkupResults.otherExaminationsInfo')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Paskyrimai' | translate" id="appointments">
        <div class="row">
            <div class="col-12 col-md-6 d-flex">
                <div class="card mb-3 flex-grow-1">
                    <div class="card-body py-3">
                        <fieldset>
                            <legend class="fw-bold">{{ "Režimas" | translate }}</legend>
                            <div *ngFor="let item of newbornAppointedRegime; let i = index" class="col-md-4 col-sm-6">
                                <app-radio-button radioName="newbornAppointedRegime"
                                    id="{{ item.name }}_{{ item.code }}" labelText="{{ item.name | translate }}"
                                    radioValue="{{ item.code }}"
                                    [fControl]="formGroup?.get('appointments.newbornAppointedRegime')"></app-radio-button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card mb-3 flex-grow-1">
                    <div class="card-body py-3">
                        <fieldset>
                            <legend class="fw-bold">{{ "Dieta" | translate }}</legend>
                            <div *ngFor="let item of newbornAppointedDiet" col-sm-12 col-md-8 d-flex flex-column>
                                <app-checkbox inputId="newbornAppointedDiet_{{ item.code }}"
                                    (change)="onCheckboxChange(formGroup, $event, 'appointments', 'newbornAppointedDiet')"
                                    [checkboxValue]="item.id" [labelText]="item.name" [checkboxTitle]="item.name"
                                    [checkboxChecked]="
                                        formGroup.get('appointments.newbornAppointedDiet').value.includes(item.id)
                                    "></app-checkbox>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex">
                <div class="card flex-grow-1">
                    <div class="card-body py-3">
                        <div class="d-flex gap-0 gap-md-4 flex-column flex-md-row">
                            <fieldset>
                                <legend class="fw-bold">{{ "Gydymas" | translate }}</legend>
                                <div *ngFor="let item of newbornAppointedTreatments" col-sm-12 col-md-8 d-flex
                                    flex-column>
                                    <app-checkbox *ngIf="item.id == '1'" (change)="
                                            onCheckboxChangeVitaminK(
                                                formGroup,
                                                $event,
                                                'appointments',
                                                'newbornAppointedTreatments'
                                            )
                                        " [checkboxValue]="item.id" inputId="newbornAppointedTreatments_{{ item.id }}"
                                        [labelText]="item.name" [checkboxTitle]="item.name" [checkboxChecked]="
                                            formGroup
                                                .get('appointments.newbornAppointedTreatments')
                                                .value.includes(item.id)
                                        "></app-checkbox>
                                    <app-checkbox *ngIf="item.id != '1'" (change)="
                                            onCheckboxChange(
                                                formGroup,
                                                $event,
                                                'appointments',
                                                'newbornAppointedTreatments'
                                            )
                                        " [checkboxValue]="item.id" inputId="newbornAppointedTreatments_{{ item.id }}"
                                        [labelText]="item.name" [checkboxTitle]="item.name" [checkboxChecked]="
                                            formGroup
                                                .get('appointments.newbornAppointedTreatments')
                                                .value.includes(item.id)
                                        "></app-checkbox>
                                    <div *ngIf="
                                            item.id == '1' &&
                                            formGroup.get('appointments.newbornAppointedTreatments').value.includes('1')
                                        " class="col">
                                        <div *ngFor="let v of vitaminK; let i = index" class="col-md-4 col-sm-6"
                                            style="padding-left: 20px">
                                            <app-radio-button radioName="vitaminK" [labelText]="v.name"
                                                [radioTitle]="v.name" id="vitaminK_{{ v.value }}" [radioValue]="v.value"
                                                [fControl]="formGroup.get('appointments.vitaminK')"></app-radio-button>
                                            <app-form-control-validation [fControl]="formGroup.get('appointments.vitaminK')" />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div class="flex-grow-1">
                                <app-input
                                    *ngIf="formGroup.get('appointments.newbornAppointedTreatments').value.includes('6')"
                                    labelText="Kiti paskyrimai" labelClass="fw-bold" type="textarea"
                                    [fControl]="formGroup?.get('appointments.newbornAppointedTreatmentsOtherInfo')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <app-modification-actions (sign)="onSave()" (sealConfirm)="onSave()" (signingSubmit)="onSave()" (submit)="onSave()"
        (save)="onSave()" (clear)="clearForm()"></app-modification-actions>
</div>