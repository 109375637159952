<div class="form-check" [class]="formClass">
  <input
    type="radio"
    [id]="id + uniqId"
    class="form-check-input"
    [class]="inputClass"
    [value]="radioValue"
    [name]="radioName"
    (change)="radioChange.emit($event)"
    [checked]="fControl?.value === radioValue || radioChecked"
    [title]="radioTitle"
    [formControl]="fControl"
    [attr.disabled]="radioDisabled || null"
    [ngClass]="{ 'is-invalid': (fControl?.invalid && fControl?.touched) }"
  />
  <label [for]="id + uniqId" [class]="labelClass" class="form-check-label">{{ labelText | translate }}</label>
</div>
