import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment/moment";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable, forkJoin, map, of, switchMap } from "rxjs";
import {DiagnosisModel} from "../../../models/diagnosis.model";
import {EntityModel} from "../../../models/entity.model";
import {ApiV2Entities} from "../../../services/api-v2-entities";
import { RouteParams } from 'src/app/ng1.routeParams';
import { PractitionerModel } from 'src/app/shared/models/practitioner.model';
import { EncounterModel } from 'src/app/shared/models/encounter.model';
import {DpSessionContext} from "../../../../doctor/services/dp-session-context.service";

@Component({
  selector: 'app-edit-diagnose-modal',
  templateUrl: './edit-diagnose-modal.component.html',
})
export class EditDiagnoseModalComponent implements OnInit {
    diagnose!: DiagnosisModel;
    statuses: EntityModel[] = [];
    confidences: EntityModel[] = [];
    severities: EntityModel[] = [];
    categories: EntityModel[] = [];
    types: EntityModel[] = [];
    practitionerId: string;
    formGroup!: FormGroup;
    onClose: EventEmitter<DiagnosisModel> = new EventEmitter<DiagnosisModel>();
    isEditInitiated: boolean = false;
    practitionerData: PractitionerModel;
    encounter: EncounterModel;
    enableTypesEdit: boolean = true;

    constructor(
        readonly bsModalRef: BsModalRef,
        private readonly fb: FormBuilder,
        private apiV2Entities: ApiV2Entities,
        private params: RouteParams,
        private sessionContext: DpSessionContext,
    ) {
        forkJoin([
            this.apiV2Entities.getEntitiesList('eHDSI_StatusCode'),
            this.apiV2Entities.getEntitiesList('condition-verification-status'),
            this.apiV2Entities.getEntitiesList('condition-severity'),
            this.apiV2Entities.getEntitiesList('condition-category'),
            this.apiV2Entities.getEntitiesList('condition-diagnosis-type'),
        ])
            .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.statuses = res[0];
                this.confidences = res[1];
                this.severities = res[2];
                this.categories = res[3];
                this.types = res[4];
            });
    }

    ngOnInit() {
        this.sessionContext.practitionerData$.subscribe(data => {
            this.practitionerData = data;
        });

        this.sessionContext.encounterData$.subscribe(data => {
            this.encounter = data;
        });

        this.initializeFormGroup();
    }

    initializeFormGroup() {
        this.formGroup = this.fb.group({
            diagnosisStatus: this.fb.control(this.diagnose?.clinicalStatus?.code ?? null, Validators.required),
            diagnosisConfidence: this.fb.control(this.diagnose?.verificationStatus?.code ?? null, Validators.required),
            diagnosisSeverity: this.fb.control(this.diagnose?.severity?.code ?? null),
            diagnosisCategory: this.fb.control(this.diagnose?.category?.code ?? null, Validators.required),
            diagnosisType: this.fb.control(this.diagnose?.type?.code ?? null, this.enableTypesEdit ? Validators.required : null),
            notes: this.fb.control(this.diagnose?.notes ?? null),
            circumstances: this.fb.control(this.diagnose?.decisionCircumstances ?? null),
            onsetDate: this.fb.control(this.diagnose?.onsetPeriodStart ? moment(this.diagnose?.onsetPeriodStart).toDate() : null, Validators.required),
            endDate: this.fb.control(this.diagnose?.onsetPeriodEnd ? moment(this.diagnose?.onsetPeriodEnd).toDate() : null),
            dateAsserted: [this.isEditInitiated ? moment().toDate() : null],
        })
    }

    close(success?: boolean) {
        if (success) {
            if (this.formGroup.invalid) return;
            const newData = {
                modelVersion: "v29",
                clinicalStatus: this.statuses.find(status => this.formGroup.get('diagnosisStatus').value === status.code),
                verificationStatus: this.confidences.find(confidence => this.formGroup.get('diagnosisConfidence').value === confidence.code),
                severity: this.severities.find(severity => this.formGroup.get('diagnosisSeverity').value === severity.code),
                category: this.categories.find(category => this.formGroup.get('diagnosisCategory').value === category.code),
                type: this.types.find(type => this.formGroup.get('diagnosisType').value === type.code),
                subject: `Patient/${this.params.patientId}`,
                encounter: `Encounter/${this.params.encounterId}`,
                asserter: this.practitionerData.fullId,
                recordedDate: this.diagnose.recordedDate ? this.diagnose.recordedDate : moment().toDate(),
                dateAsserted: this.formGroup.get('dateAsserted').value,
                notes: this.formGroup.get('notes').value,
                decisionCircumstances: this.formGroup.get('circumstances').value,
                onsetPeriodStart: this.formGroup.get('onsetDate').value,
                onsetPeriodEnd: this.formGroup.get('endDate').value
                }
            this.onClose.emit({...this.diagnose, ...newData});
        }
        this.bsModalRef.hide();
    }
}
