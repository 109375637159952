<table class="table table-hover">
    <thead>
    <tr>
        <td></td>
        <td>{{ 'Sfera (SPH)' | translate }}</td>
        <td>{{ 'Cilindras (CYL)' | translate }}</td>
        <td>{{ 'Ašis (AXIS)' | translate }}</td>
        <td>{{ 'Adidacija (ADD) / Degresija' | translate }}</td>
        <td *ngIf="isGlens()">{{ 'Prizmė (PRISM)' | translate }}</td>
        <td *ngIf="isGlens()">{{ 'PD artumui' | translate }}</td>
        <td *ngIf="isGlens()">{{ 'PD tolumui' | translate }}</td>
        <td *ngIf="!isGlens()">{{ 'Bazė kreivė (BC)' | translate }}</td>
        <td *ngIf="!isGlens()">{{ 'Diametras (DIA)' | translate }}</td>
    </tr>
    </thead>
    <tbody>
    <tr>
        <th scope="row">{{ 'OD - dešinė akis' | translate }}</th>
        <td>{{ lenses?.rightSphere }}</td>
        <td>{{ lenses?.rightCylinder }}</td>
        <td>{{ lenses?.rightCylinderAxis }}</td>
        <td>{{ lenses?.rightAdd }}</td>
        <td *ngIf="isGlens()">{{ lenses?.rightPrismBase?.code }}</td>
        <td *ngIf="isGlens()">{{ lenses?.rightNearPD }}</td>
        <td *ngIf="isGlens()">{{ lenses?.rightDistancePD }}</td>
        <td *ngIf="!isGlens()">{{ lenses?.rightBackCurve }}</td>
        <td *ngIf="!isGlens()">{{ lenses?.rightDiameter }}</td>
    </tr>

    <tr>
        <th scope="row">{{ 'OS - kairė akis' | translate }}</th>
        <td>{{ lenses?.leftSphere }}</td>
        <td>{{ lenses?.leftCylinder }}</td>
        <td>{{ lenses?.leftCylinderAxis }}</td>
        <td>{{ lenses?.leftAdd }}</td>
        <td *ngIf="isGlens()">{{ lenses?.leftPrismBase?.code }}</td>
        <td *ngIf="isGlens()">{{ lenses?.leftNearPD }}</td>
        <td *ngIf="isGlens()">{{ lenses?.leftDistancePD }}</td>
        <td *ngIf="!isGlens()">{{ lenses?.leftBackCurve }}</td>
        <td *ngIf="!isGlens()">{{ lenses?.leftDiameter }}</td>
    </tr>
    </tbody>
</table>