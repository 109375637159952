import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";

@Injectable({
    providedIn: 'root',
})
export class ApiV2E030 extends ConfigService {

    path = this.apiUrl + '/e030';

    getE030FormView(): Observable<any> {
        return this.http.get<any>(`${this.path}`);
    }

    saveE030Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/save/' + id, payload);
    }

    submitE030Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/create/' + id, payload);
    }

    signE030Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/sign/' + id, payload);
    }

    signingSubmitE030Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/signingSubmit/' + id, payload);
    }

    sealConfirmE030Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/seal/' + id, payload);
    }
}