ng1App.controller('doctor.patient.cert.II.e047EditCtrl', 
    ['$scope', '$routeParams', '$filter', 'CertificatesApiFactory', 'MessageService', 'CertificatePayment', 'TasksApiFactory', 'CertificateService', 'ConsultationCertificateService',
     function ($scope, $routeParams, $filter, CertificatesApiFactory, MessageService, CertificatePayment, TasksApiFactory, CertificateService, ConsultationCertificateService) {
    $scope.certificate.initialSave = $scope.initialSave;

    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.e047.mai.inf'),
                id: 'cer_e047_mai_inf'
            },
            {
                title: $filter('translate')('cer.e048.ini.per'),
                id: 'cer_e048_ini_per'
            },
            {
                title: $filter('translate')('cer.e047.doc'),
                id: 'cer_e047_doc'
            },
            {
                title: $filter('translate')('cer.e048.har'),
                id: 'cer_e048_har',
                show: function () {
                    if ($scope.e047EmployerCertificate.harmfulFactors &&
                            (($scope.e047EmployerCertificate.harmfulFactors.items && $scope.e047EmployerCertificate.harmfulFactors.items.length)
                                    || $scope.e047EmployerCertificate.harmfulFactors.remarks)) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e047.cons'),
                id: $scope.certificate.initialSave ? 'cer_e047_cons' : 'cer_e047_cons2'
            },
            {
                title: $filter('translate')('cer.e048.con'),
                id: 'cer_e048_con'
            }
        ]
    };

    $scope.$emit('setHelpPage', 'kurti-pazyma-e047-privalomojo-sveikatos-patikrinimo-medicinine-pazyma-gydytojo-pildoma-dali');

    var certificateType = 'e047_practitioner';
    var currentDate = new Date();
    $scope.patientId = $routeParams.patientId;

    CertificatePayment.showPaymentMessage(certificateType);
    $scope.compositionId = $routeParams.id;

    //demo data show modal
    $scope.practitioner = {
        validQualificationList: []
    };
    //init data
    if (!$scope.certificate.nextHealthCheckDate) {
        $scope.certificate.nextHealthCheckDate = new Date().setDate(new Date().getDate() + 365);
    }

    if ($scope.initialSave) {
        if (!$scope.certificate.relatedTask || !$scope.certificate.relatedTask.taskId) {
            $scope.certificate.relatedTask = {
                taskId: $routeParams.taskId
            };
        }

        $scope.e047EmployerCertificate = CertificatesApiFactory.getFromRelatedTask({
            taskId: $scope.certificate.relatedTask.taskId
        }, function (data) {
            $scope.certificate.employerCertificateCompositionId = data.compositionId;
        });

        //checking referenced task
        TasksApiFactory.getById({
            id: $scope.certificate.relatedTask.taskId
        }, function (data) {
            if (!(data.status == 'SUBMITTED' || data.status == 'ABORTED')) {
                MessageService.add('error', $filter('translate')('err.tasks.wrongTaskStatus'));
            }
            if (!data.taskType || data.taskType.code !== 'E047-create-initial-certificate') {
                MessageService.add('error', $filter('translate')('err.tasks.wrongTaskType'));
            }
        },
                function (error) {
                    MessageService.add('error', $filter('translate')('err.tasks.taskNotFound'));
                });
    } else {
        $scope.e047EmployerCertificate = $scope.certificate.employerCertificate;
        if (!$scope.certificate.finalCertificateAuthor) {
            MessageService.add('error', $filter('translate')('err.cer.e047.noOrWrongFinalCertificateTask'));
        }
    }

    $scope.saveAndSign = function (doSign) {
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    $scope.savePartial = function () {
        CertificateService.savePartial(certificateType, $scope.certificate, $scope.certificateForm);
    };

    $scope.maxCreationDate = currentDate;

    $scope.showNextHealthCheckDateWarning = function () {
        return ConsultationCertificateService.showNextHealthCheckDateWarning($scope.certificate.creationTime, $scope.certificate.nextHealthCheckDate);
    };
}]);