import { Component, DestroyRef, Input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { debounceTime, distinctUntilChanged, map, Observable, Observer, of, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiV2Practitioners } from '../../../../api/api-v2-practitioners';
import { PractitionerModel } from '../../models/practitioner.model';
import { addObjToFormArray } from '../../../doctor/ngn/components/e096/e096-utils/utils';

@Component({
  selector: 'app-find-specialists',
  templateUrl: './find-specialists.component.html',
})
export class FindSpecialistsComponent {
    @Input() formArray: FormArray<any> = this.fb.array([]);
    @Input() customDisplaying: boolean = false;
    @Input() editable: boolean = true;
    @Input() set disable(value: boolean) {
        if (typeof value !== 'boolean') return;

        this._disableControl = value;
        if (value) {
            this.searchValue.disable();
        } else {
            this.searchValue.enable();
        }
    };

    get disable(): boolean {
        return this._disableControl;
    }

    searchValue = this.fb.control(null);
    controlWasTouched = false;
    selectedSpecialist: PractitionerModel;
    suggestions$: Observable<PractitionerModel[]>;

    private _disableControl: boolean = false;
    private addObjectToFormArray = addObjToFormArray;

    get specialistsControl() {
        return this.formArray.controls;
    }

    constructor(
        private fb: FormBuilder,
        private destroyRef: DestroyRef,
        private apiV2Practitioners: ApiV2Practitioners
    ) {
        this.getSpecialists();
        this.searchValue.valueChanges.pipe(
            debounceTime(50),
            takeUntilDestroyed()
        ).subscribe(r => {
            if (!this.selectedSpecialist || r === this.selectedSpecialist?.['fullDisplay']) return;
            this.selectedSpecialist = null;
        })
    }
    selectSpecialist(e: TypeaheadMatch) {
        this.selectedSpecialist = e.item;
    }

    addSpecialistToList(): void {
        delete this.selectedSpecialist['fullDisplay'];
        const formGroup = this.addObjectToFormArray(this.selectedSpecialist);
        this.formArray.push(formGroup, {emitEvent: false});
        this.searchValue.reset();
        this.selectedSpecialist = null;
    }

    deleteSpecialist(i: number) {
        this.formArray.removeAt(i, {emitEvent: false})
    }

    private getSpecialists() {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.searchValue.value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                return !query
                    ? of([])
                    : this.apiV2Practitioners.find({q: query}).pipe(
                        map(item => item.items
                            .filter(p => !this.formArray.getRawValue()?.some(s => s.id === p.id))
                            .map(p => {
                                const qualification = this.getSpecialistQualifications(p);
                                p['fullDisplay'] = `${p.givenName} ${p.familyName}, ${qualification}, ${p.personalCode}`;
                                return p;
                            })
                        ))
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    getErrorMessage() {
        if (this.formArray.errors?.['maxlength']) {
            return `Max number of specialist must be ${this.formArray.errors?.['maxlength'].requiredLength}`;
        }
        if (this.formArray.errors?.['required']) {
            return `This field is required`;
        }
        return null;
    }

    getSpecialistQualifications(specialist: PractitionerModel): string {
        return Object.values(specialist?.qualificationList)?.map(q => q?.name)?.join(',');
    }
}
