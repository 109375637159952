import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { ApiV2f058 } from "src/api/api-v2-doc-ref-f058";
import { ApiV2ProblemStatusClassifier } from "src/api/api-v2-doc-ref-problem-status-classifier";
import { DocumentTagEnum } from "../../../models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";

@Component({
    selector: 'doc-ref-f058-view',
    templateUrl: './doc-ref-f058-form-view.component.html'
})
export class DocRef058ViewComponent implements OnInit {

    backPath: string;
    patientId = null;
    form: FormGroup;
    currentDate = new Date();
    statusValue: string;
    problemTypeArray: string[];
    selectedDiseaseForm: string;
    emitedValues = null;
    viewData = null;
    documentTag = DocumentTagEnum.e027a;
    prefillData: any;
    isViewOnlyMode = true;
    isLoading = true;
    diagnosisArray = null;

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private fb: FormBuilder,
        private apiV2ProblemStatusClassifier: ApiV2ProblemStatusClassifier,
        private ApiV2ApiV2f058: ApiV2f058,
        private router: Router
    ) {
        this.form = this.fb.group({
            attendanceDate: [],
            gettingSickDate: [],
            checkInDate: [],
            //TODO this date will be taken from diagnosis
            diagnosisDate: [],
            reportDate: [],
            hospitalized: [false],
            //diagnosisStatus: [],
            reasonForDeath: [false],
            TLK_10_AM: [],
            tumorDiagnosis: [],
            additionalDiagnosisCode: [],
            additionalDiagnosisName: [],
            relevantInformation: [],
            animalKnown: [false],
            diseaseForm: [],
            writeIfUnknown: [],
            newInfo: [],
        });
        this.form.get('attendanceDate').disable();
        this.form.get('gettingSickDate').disable();
        this.form.get('checkInDate').disable();
        this.form.get('reportDate').disable();
        this.form.get('hospitalized').disable();
        this.form.get('reasonForDeath').disable();
        this.form.get('relevantInformation').disable();
        this.form.get('animalKnown').disable();
        this.form.get('diseaseForm').disable();
        this.form.get('writeIfUnknown').disable();
        this.form.get('newInfo').disable();
    }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/f090/list`;
        this.getDiagnosticStatusClassifierList();
        const id = this.params.viewId;
        this.load058_089_151a(id);
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    load058_089_151a(id: string) {
        return this.ApiV2ApiV2f058.getf058FormView(id).subscribe((data: any) => {
            this.viewData = data;
            if (data) {
                this.isViewOnlyMode = true;
                this.initializeData(data);
                this.diagnosisArray = data.diagnosis;
                this.isLoading = false;
            }
        });
    }

    initializeData(data) {
        this.form.controls['attendanceDate'].setValue(new Date(data.attendanceDate));
        this.form.controls['gettingSickDate'].setValue(new Date(data.gettingSickDate));
        this.form.controls['checkInDate'].setValue(new Date(data.checkInDate));
        this.form.controls['diagnosisDate'].setValue(new Date(data.diagnosisDate));
        this.form.controls['reportDate'].setValue(new Date(data.reportDate));
        this.form.controls['hospitalized'].setValue(data.hospitalized.code);
        this.form.controls['reasonForDeath'].setValue(data.reasonForDeath.code);
        this.form.controls['TLK_10_AM'].setValue(data.TLK_10_AM);
        this.form.controls['tumorDiagnosis'].setValue(data.tumorDiagnosis);
        this.form.controls['additionalDiagnosisCode'].setValue(data.additionalDiagnosisCode);
        this.form.controls['additionalDiagnosisName'].setValue(data.additionalDiagnosisName);
        this.form.controls['relevantInformation'].setValue(data.relevantInformation);
        this.form.controls['animalKnown'].setValue(data.animalKnown.code);
        this.selectedDiseaseForm = data.diseaseForm;
        this.form.controls['diseaseForm'].setValue(data.diseaseForm.code);
        this.form.controls['writeIfUnknown'].setValue(data.writeIfUnknown);
        this.form.controls['newInfo'].setValue(data.newInfo);
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    getDiagnosticStatusClassifierList() {
        this.apiV2ProblemStatusClassifier.getClassifierList().pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.problemTypeArray = response;
            }
        });
    }

    onChanges(event) {
        this.emitedValues = event;
    }

    undoClick(): void {
        this.router.navigate([`dp/patients/${this.patientId}/f090/list`]);
    }
}