ng1App.controller('doctor.user.TemplatesCtrl', 
    ['$scope', '$routeParams', '$filter', '$modalConfirm', 'TemplatesApiFactory', 'MessageService', 'SearchController', 'DpSessionContext',
     function ($scope, $routeParams, $filter, $modalConfirm, TemplatesApiFactory, MessageService, SearchController, DpSessionContext) {

    $scope.$emit('setHelpPage', 'perzireti-specialisto-ruosinius');

    $scope.menu = [
        {type: '', title: 'dp.use.tem.rec.all'},
        {type: 'e025', title: 'dp.use.tem.rec.amb'},
        {type: 'e003', title: 'dp.use.tem.rec.inp'},
        {type: 'e027', title: 'dp.use.tem.rec.sen'},
        {type: 'e027a', title: 'dp.use.tem.rec.sena'},
        {type: 'e200', title: 'dp.use.tem.rec.lab'},
        {type: 'e014', title: 'dp.use.tem.rec.pat'},
        {type: 'e200a', title: 'dp.use.tem.rec.labr'},
        {type: 'e014a', title: 'dp.use.tem.rec.pata'},
        {type: 'e063', title: 'dp.use.tem.rec.vac'},
        {type: 'sk_va', title: 'Vaisto skyrimas'},
        {type: 'sk_mpp', title: 'MPP skyrimas'},
        {type: 'sk_ev', title: 'Ekstemporalaus vaisto skyrimas'},
        {type: 'sk_kp', title: 'Kito produkto skyrimas'}
    ];

    $scope.searchCtrl = new SearchController({
        viewId: 'DP_TEMPLATES',
        count: 10,
        initForm: function () {
            return {
                //filter: $scope.menu[0],
                sortOrder: 'title'
            };
        },
        searchApi: TemplatesApiFactory.findForList,
        showPageCounter: true
    });

    $scope.searchCtrl.formToQuery = function (form) {
        var query: any = {};
        query.sortOrder = form.sortOrder;
        switch (form._searchType) {
            case 'quick':
                query.text = form.q;
                break;
            case 'filter':
                query.type = form.filter.type;
                break;
        }

        return query;
    };

    $scope.searchCtrl.setPagingInfo = function (query) {
        query.count = this.getCount();
        query.from = this.getCount() * (this.getPage() - 1);
    };

    $scope.changeFilter = function (f) {
        $scope.searchCtrl.clear();
        $scope.searchCtrl._form.filter = f;
        $scope.searchCtrl.searchFilter();
    };

    var reloadCounts = function () {
        $scope.counts = {};
        TemplatesApiFactory.countsByType({}, function (res) {
            _.each(res, function (c) {
                if ('all' === c.type) {
                    $scope.menu[0].count = c.count;
                } else {
                    _.each($scope.menu, function (m) {
                        if (m.type === c.type) {
                            m.count = c.count;
                        }
                    });
                }
                $scope.counts[c.type] = c.count;
            });
        });
    };

    $scope.handlers = {
        selected: {},
        deleteOne: function (id) {
            $modalConfirm.show({title: $filter('translate')('err.ale.tit.tem.del'), content: $filter('translate')('err.ale.tem.del')}).then(function () {
                dropItems([id]);
            });
        }
    };

    var toggleAll = function (seleced) {
        _.each($scope.searchCtrl._list, function (i) {
            $scope.handlers.selected[i.id] = seleced;
        });
    };

    $scope.selectAll = function () {
        toggleAll(true);
    };

    $scope.deselectAll = function () {
        toggleAll(false);
    };

    var dropItems = function (toBeRemoved) {
        TemplatesApiFactory.delete({id: toBeRemoved}, function () {
            $scope.handlers.selected = {};
            $scope.searchCtrl.searchLastOrAll();
            reloadCounts();
            MessageService.clearAll();
            MessageService.add('success', 'Įrašai pašalinti');
        });
    };

    $scope.delete = function () {
        $modalConfirm.show({title: $filter('translate')('err.ale.tit.16'), content: $filter('translate')('err.ale.16')}).then(function () {
            var toBeRemoved = [];
            for (var id in $scope.handlers.selected) {
                if ($scope.handlers.selected[id]) {
                    toBeRemoved.push(id);
                }

            }
            dropItems(toBeRemoved);
        });
    };

    reloadCounts();

    DpSessionContext.getSettings(function (data) {
        $scope.searchCtrl.setCount(data.count_per_page || 10);

        var initialFilter = $scope.menu[0];
        _.each($scope.menu, function(m) {
            if (m.type == $routeParams.type) { initialFilter = m; }
        });
        $scope.searchCtrl._form.filter = initialFilter;

        $scope.searchCtrl.searchFilter();
        // $scope.searchCtrl.searchLastOrAll();
    });

}]);