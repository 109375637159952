import {ErxPrescriptionTypeEnum} from "../../../../../../shared/models/erx-prescription-type.enum";

angular.module('erx.prs').directive('erxViewPrescriptionForm', function () {

  var viewPrescriptionFormCtrl = function ($scope, $filter, $location, $erxModalConfirm,
    $erxModalMedinfo, $erxModalReason, ErxPrescriptionsApiFactory, erxPrescriptionsConfig,
                                           erxApiConfig, MessageService, gettextCatalog) {
    $scope.medicationPrescription = {};
      $scope.safetyEducationParams;

      MessageService.clear('drug-region');
      MessageService.clear('named-drug-region');
      MessageService.clear('mpp-region');
      MessageService.clear('em-drug-region');
      MessageService.clear('prescription-region');

    if (angular.isDefined($scope.options)) {
      angular.extend($scope.options, erxPrescriptionsConfig.options);
    } else {
      $scope.options = erxPrescriptionsConfig.options;
    }

    if ($scope.options.signStatus) {
      if ($scope.options.signStatus === 'ok') {
        $scope.options.getIntegrationData().displayGlobalMessage('success', gettextCatalog.getString('Dokumentas pasirašytas sėkmingai'));
      } else if ($scope.options.signStatus === 'canceled') {
        $scope.options.getIntegrationData().displayGlobalMessage('info', gettextCatalog.getString('Dokumento pasirašymas atšauktas'));
      } else if ($scope.options.signStatus === 'fail') {
        $scope.options.getIntegrationData().displayGlobalMessage('error', gettextCatalog.getString('Nepavyko pasirašyti dokumento'));
      }
    }

    $scope.hideDocInfo = $scope.options.hideDocInfo;

    // Formos duomenų užkrovimas iš REST API bei paruošimas atvaizdavimui
    var loadForm = function () {

      if ($scope.options.prescriptionId) {

        ErxPrescriptionsApiFactory.getDocument(
          {
            id: $scope.options.prescriptionId,
            includeRelated: !$scope.hideDocInfo
          },
          function (res) {
              $scope.prescription = res;
            $scope.patientId = res.prescriptionData.patientRef;
            $scope.doctorId = res.prescriptionData.prescriberRef;
            $scope.patient = res.patient;
            $scope.practitioner = res.practitioner;
            $scope.organization = res.organization;
            $scope.practitionerQualification = res.practitionerQualification;
              $scope.medicationPrescription = res.prescriptionData;
              $scope.medicationPrescription.metadata = res.metadata;
              $scope.medicationPrescription.dispenseInfo = res.dispenseInfo;
              $scope.medicationPrescription.prescriptionType = res.prescriptionData.prescriptionType.code == 'vv' ? 'va' : res.prescriptionData.prescriptionType.code;
              const ptCode = $scope.medicationPrescription.prescriptionType;
              $scope.prescriptionType = ErxPrescriptionTypeEnum[ptCode ? String(ptCode).toUpperCase() : undefined];
            $scope.prescriptionFound = true;

            if (res.prescriptionData.medicationRef) {
              $scope.medicationPrescription.medicationRef = res.prescriptionData.medicationRef;
            }

            $scope.medicationPrescription.drug = angular.copy(res.prescriptionData.medicationData);
            if ($scope.isNamedMedicineForm()) {
              if (angular.isObject(res.prescriptionData.medicationData.pharmForm)) {
                $scope.medicationPrescription.drug.pharmFormText = res.prescriptionData.medicationData.pharmForm.displayValue;
              }
            }

            if ($scope.isExtemporalForm()) {
              if (res.prescriptionData.medicationData.ingredients) {
                $scope.mainIngredient = res.prescriptionData.medicationData.ingredients[0].medication ? 'va' : 'vm';

                if (res.prescriptionData.medicationData.ingredients[0].medication) {
                  $scope.medicationPrescription.drug.productName = res.prescriptionData.medicationData.ingredients[0].medication.productName;
                  $scope.medicationPrescription.drug.strength = res.prescriptionData.medicationData.ingredients[0].medication.strength;
                  $scope.medicationPrescription.drug.controlledType = res.prescriptionData.medicationData.ingredients[0].medication.controlledType;
                  $scope.medicationPrescription.drug.activeIngredients = res.prescriptionData.medicationData.ingredients[0].medication.activeIngredients;
                  $scope.medicationPrescription.drug.pharmForm = res.prescriptionData.medicationData.ingredients[0].medication.pharmForm;
                  $scope.medicationPrescription.drug.pharmFormText = res.prescriptionData.medicationData.ingredients[0].medication.pharmFormText;
                }
              }
            }

            if ($scope.isMPPForm()) {
              $scope.medicationPrescription.lensFlag = angular.isObject(res.prescriptionData.dispenseData.lenses);
            }

              $scope.getSecurityAndEducationalData();
          },
          function (error) {
            $scope.errorMsg = error.data;
            $scope.prescriptionFound = false;
          }
        );
      }
    }

      $scope.getSecurityAndEducationalData = function () {
          if (($scope.isExtemporalForm() || $scope.isNamedMedicineForm() || $scope.isMedicineForm()) && $scope.medicationPrescription.drug !== undefined) {
              $scope.safetyEducationParams = {
                  atc: $scope.medicationPrescription.drug?.atc?.code
              };
          }
      }

    $scope.timesOfDay = [
      { code: 'morning', displayValue: 'Ryte' },
      { code: 'noon', displayValue: 'Per pietus' },
      { code: 'evening', displayValue: 'Vakare' },
      { code: 'asNeeded', displayValue: 'Pagal poreikį' }];


    var integrationObject = $scope.options.getIntegrationData();
    $scope.intakeTimes = [
      { code: 'beforeMeal', displayValue: 'Prieš valgį' },
      { code: 'duringMeal', displayValue: 'Valgio metu' },
      { code: 'afterMeal', displayValue: 'Po valgio' },
      { code: 'independMeal', displayValue: 'Nepriklausomai nuo valgymo' },
      { code: 'beforeSleep', displayValue: 'Prieš miegą' }
    ];

    $scope.prescriptionTags = integrationObject.getPrescriptionTags();

    // Ar skirtas vaistas
    $scope.isMedicineForm = function () {
      return ($scope.medicationPrescription.prescriptionType === 'va')
        && !$scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    };

    // Ar skirtas vardinis vaistas
    $scope.isNamedMedicineForm = function () {
      return ($scope.medicationPrescription.prescriptionType === 'va')
        && $scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    };

    // Ar skirta MPP
    $scope.isMPPForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'mpp';
    };

    // Ar skirta MP
    $scope.isMPSForm = function () {
        return $scope.medicationPrescription.prescriptionType === 'mpa';
    };

    // Ar skirtas ekstemporalus vaistas
    $scope.isExtemporalForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'ev';
    };

    // Ar skirtas kitas produktas
    $scope.isOtherForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'kp';
    };

    $scope.getPrescriptionTitle = function () {
      if ($scope.isMedicineForm())
        return gettextCatalog.getString('Vaisto skyrimas');
      if ($scope.isNamedMedicineForm())
        return gettextCatalog.getString('Vardinio vaisto skyrimas');
      if ($scope.isMPSForm())
        return gettextCatalog.getString('MP skyrimas');
      if ($scope.isMPPForm())
        return gettextCatalog.getString('MPP skyrimas');
      if ($scope.isExtemporalForm())
        return gettextCatalog.getString('Ekstemporalaus vaisto skyrimas');
      if ($scope.isOtherForm())
        return gettextCatalog.getString('Kito produkto skyrimas');
    }

    $scope.getDisplayValueForBoolean = function (val) {
      switch (val) {
        case undefined:
          return gettextCatalog.getString('Nenurodyta');
        case false:
          return gettextCatalog.getString('Ne');
        case true:
          return gettextCatalog.getString('Taip');
        default:
          return gettextCatalog.getString('Nenurodyta');
      }
    };

    $scope.getPrescriptionTagDisplayValue = function () {
      var result = $filter('filter')($scope.prescriptionTags,
        { code: $scope.medicationPrescription.dispenseData.prescriptionTag });
      if (result.length > 0) {
        return result[0].displayValue;
      }
    };
    // Vartojimo dažnumo žymų pavertimas į tinkamą atvaizdavimui formą
    $scope.getDosageFrequency = function () {
      var ret = '';
      for (var i = 0; i < $scope.timesOfDay.length; i++) {
        if ($scope.medicationPrescription.dosageInstructionData[$scope.timesOfDay[i].code]) {
          if (ret !== '')
            ret += ', ';
          ret += gettextCatalog.getString($scope.timesOfDay[i].displayValue);
        }
        if (i === $scope.timesOfDay.length - 1 && ret !== '')
          ret += '.';
      }
      return ret;
    };

      // Vartojimo dažnumo kartų per "x" pavertimas į tinkamą atvaizdavimui formą
    $scope.getDosageFrequencyPerDay = function () {

      if (!$scope.medicationPrescription.dosageInstructionData.schedule ||
        !$scope.medicationPrescription.dosageInstructionData.schedule.frequency ||
        !$scope.medicationPrescription.dosageInstructionData.schedule.units) {
        return "";
      }
      var ret = "";
      if (angular.isDefined($scope.medicationPrescription.dosageInstructionData.schedule.frequency)
        && angular.isDefined($scope.medicationPrescription.dosageInstructionData.schedule.units)) {
        var frequency = $scope.medicationPrescription.dosageInstructionData.schedule.frequency;
        ret += frequency;
        ret += $scope.getPluralForm(frequency, ' kartas per ', ' kartai per ', ' kartų per ');
        ret += $filter('filter')($scope.frequencyMeasurements, { code: $scope.medicationPrescription.dosageInstructionData.schedule.units })[0].displayValue;
        //ret += $scope.medicationPrescription.dosageInstructionData.schedule.units;
      }
      return ret;
    }

    // Vartojimo dažnumo kas "x" valandų pavertimas į tinkamą atvaizdavimui formą
    $scope.getDosageFrequencyEveryXHours = function () {
      var ret = "";
      if (angular.isDefined($scope.medicationPrescription.dosageInstructionData.timeQuantity)) {
        ret += gettextCatalog.getString('kas ');
        ret += $scope.medicationPrescription.dosageInstructionData.timeQuantity;
        ret += gettextCatalog.getString(' valandas');
      }
      return ret;
    }

    // Vartojimo trukmės pavertimas į tinkamą atvaizdavimui formą
    $scope.getTreatmentDuration = function () {
      var ret = "";
      if (angular.isDefined($scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration)) {
        ret += $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration + ' d.';
      }
      return ret;
    }

    // Vartojimo laiko pavertimas į tinkamą atvaizdavimui formą
    $scope.getIntakeTimes = function () {
      var ret = '';
      for (var i = 0; i < $scope.intakeTimes.length; i++) {
        if ($scope.medicationPrescription.dosageInstructionData[$scope.intakeTimes[i].code]) {
          if (ret !== '')
            ret += ', ';
          ret += gettextCatalog.getString($scope.intakeTimes[i].displayValue);
        }
        if (i === $scope.intakeTimes.length - 1 && ret !== '')
          ret += '.';
      }
      return ret;
    };

    // Navigacija į pasirašymo puslapį
    $scope.initSign = function () {
      //$scope.options.redirectToSignPage();
      $scope.options.redirectToSignPageWithDocIds([$scope.medicationPrescription.metadata.docId]);
    };

    $scope.showMedInfo = function () {
      $erxModalMedinfo.show({ ref: $scope.medicationPrescription.medicationRef });
    };

    $scope.canSign = function () {
      if (!$scope.hideDocInfo) {
        if (angular.isDefined($scope.medicationPrescription.metadata)) {
          return $scope.medicationPrescription.metadata.docStatus !== 'Signed'
            && $scope.medicationPrescription.metadata.docStatus !== 'entered in error'
            && $scope.medicationPrescription.prescriptionStatus
            && $scope.medicationPrescription.prescriptionStatus.code === 'active'
            && $scope.medicationPrescription.dispenseData
            && $scope.medicationPrescription.dispenseData.prescriptionTag === 'ier'
        };
        return true;
      } else {
        return false;
      }
    };

    $scope.canEdit = function () {
      return $scope.hideDocInfo && $scope.medicationPrescription.prescriptionStatus
        && $scope.medicationPrescription.prescriptionStatus.code === 'valid';
    }

    $scope.canEditPrescriptionDoc = function () {
      return !$scope.hideDocInfo && !$scope.medicationPrescription.status &&
        (!$scope.medicationPrescription.prescriptionStatus || $scope.medicationPrescription.prescriptionStatus.code !== 'entered in error');
    }

    $scope.canCancel = function () {
      return $scope.hideDocInfo && $scope.medicationPrescription.prescriptionStatus && $scope.medicationPrescription.prescriptionStatus.code === 'active';
    }

    $scope.canCancelDocument = function () {
      return !$scope.hideDocInfo && $scope.medicationPrescription.status
        && ($scope.medicationPrescription.status.code === 'active'
          || $scope.medicationPrescription.status.code === 'on hold');
    }

      $scope.canStopDocument = function() {
      return !$scope.hideDocInfo && $scope.medicationPrescription.status
        && ($scope.medicationPrescription.status.code === 'active'
          || $scope.medicationPrescription.status.code === 'on hold');
    }

    $scope.canRenew = function () {
      return !$scope.hideDocInfo && $scope.medicationPrescription.status
        && $scope.medicationPrescription.status.code === 'on hold'
        && $scope.medicationPrescription.status.reasonCode === 'for review';
    }

    $scope.canCorrect = function () {
      return $scope.hideDocInfo && $scope.medicationPrescription.prescriptionStatus
        && $scope.medicationPrescription.prescriptionStatus.code === 'active';
    }

    $scope.hasIer = function () {
      return $scope.medicationPrescription.dispenseData.prescriptionTag == 'ier';
    }

    // Skyrimo atšaukimas
    $scope.cancelPrescription = function () {
      $erxModalReason.show({}).then(function (res) {
        ErxPrescriptionsApiFactory.cancel({ id: $scope.options.prescriptionId }, { reason: res, docOnly: false }, function (res2) {
          $scope.options.getIntegrationData().displayGlobalMessage('success', 'Skyrimas atšauktas');
          loadForm();
        });
      });
    }

    // Recepto atšaukimas
    $scope.cancelDocument = function () {
      $erxModalReason.show({}).then(function (res) {
        ErxPrescriptionsApiFactory.cancel({ id: $scope.options.prescriptionId }, { reason: res, docOnly: true }, function (res2) {
          $scope.options.getIntegrationData().displayGlobalMessage('success', 'Receptas atšauktas');
          loadForm();
        });
      });
    }

      // Recepto sustabdymas
    $scope.stopDocument = function () {
      $erxModalReason.show({ reasonTitle: 'Sustabdymo priežastis', confirmTitle: 'Sustabdyti' }).then(function (res) {
        ErxPrescriptionsApiFactory.stop({ id: $scope.options.prescriptionId }, res, function (res2) {
          $scope.options.getIntegrationData().displayGlobalMessage('success', 'Receptas sustabdytas');
          loadForm();
        });
      });
    }

    // Skyrimo atnaujinimo kvietimas ir navigacija
    $scope.renewPrescription = function () {
      $erxModalReason.show({ reasonTitle: 'Atnaujinimo priežastis', confirmTitle: 'Atnaujinti' }).then(function (reason) {
        ErxPrescriptionsApiFactory.renew({ id: $scope.options.prescriptionId }, { cancel: true, reason: reason }, function (res2) {
          $scope.options.getIntegrationData().displayGlobalMessage('success', 'Skyrimas atnaujintas');
          loadForm();
        });
      });
    };

    $scope.correctPrescription = function () {
      $erxModalReason.show({ reasonTitle: 'Tikslinimo priežastis', confirmTitle: 'Tikslinti' }).then(function (reason) {

        ErxPrescriptionsApiFactory.cancel({ id: $scope.options.prescriptionId }, { reason: reason, createCopyAsDraft: true }, function (res2) {
          if (res2.id) {
            $location.url($scope.options.getEditPrescriptionUrl(res2.id));
          }
        });
      });
    };


    $scope.canDelete = function () {
      return $scope.hideDocInfo
        && $scope.medicationPrescription.prescriptionStatus
        && $scope.medicationPrescription.prescriptionStatus.code === 'valid';
    };

    $scope.canDeletePrescriptionDoc = function () {
      return !$scope.hideDocInfo
        && !$scope.medicationPrescription.status;
    };

    // Skyrimo šalinimas
    $scope.deletePrescription = function () {
      if ($scope.medicationPrescription.metadata.id) {
        $erxModalConfirm.show({ content: 'Ar tikrai norite pašalinti?' }).then(function () {
          ErxPrescriptionsApiFactory.deleteMedPrescription({ id: $scope.medicationPrescription.metadata.id }).$promise.then(
            function () {
              $location.url($scope.options.getReturnUrl());
              $scope.options.getIntegrationData().displayGlobalMessage('success', 'Skyrimas pašalintas');
            }
          );
        });
      }
    };

    // Recepto šalinimas
    $scope.deletePrescriptionDoc = function () {
      //TODO
      return;
    }

    $scope.getEditPrescriptionLink = function () {
      return $scope.options.getEditPrescriptionUrl($scope.options.prescriptionId);
    };

    $scope.canViewPrescriptionDoc = function () {
      return $scope.medicationPrescription.prescriptionStatus
        && ($scope.medicationPrescription.prescriptionStatus.code === 'valid'
          || $scope.medicationPrescription.prescriptionStatus.code === 'active')
        && $scope.medicationPrescription.dispenseData.prescriptionTag === 'ier'
        && $scope.hideDocInfo;
    };

    $scope.canCreatePrescriptionDoc = function () {
      return !$scope.isOtherForm() && !$scope.hasIer() && (
        !$scope.medicationPrescription.prescriptionStatus || $scope.medicationPrescription.prescriptionStatus.code != 'entered in error'
      );
    };

    // Navigacija į recepto koregavimą
    $scope.goToEditDoc = function () {
      $scope.options.redirectToEditPrescriptionDoc($scope.options.prescriptionId);
    };

    // Navigacija į recepto peržiūrą
    $scope.goToDoc = function () {
      $scope.options.redirectToViewPrescriptionDoc($scope.options.prescriptionId);
    };

      $scope.frequencyMeasurements = [
      {code: 's', displayValue: 'sekundę'},
      {code: 'min', displayValue: 'minutę'},
      {code: 'h', displayValue: 'valandą'},
      {code: 'd', displayValue: 'dieną'},
      {code: 'wk', displayValue: 'savaitę'},
      {code: 'mo', displayValue: 'mėnesį'},
      {code: 'a', displayValue: 'metus'}
    ];

      //Todo move to utils or use standard functions
    $scope.getPluralForm = function(n, first, second, third) {
        switch (n%10==1 && n%100!=11 ? 0 : n%10>=2 && (n%100<10 || n%100>=20) ? 1 : 2) {
        case 0:
            return gettextCatalog.getString(first);
        case 1:
            return gettextCatalog.getString(second);
        case 2:
            return gettextCatalog.getString(third);
        }
    }

    $scope.getStep = function () {
      if ((!$scope.medicationPrescription.prescriptionStatus ||
        !$scope.medicationPrescription.prescriptionStatus.code)
        && !$scope.options.prescriptionId) {
        return 0;
      } else if ((!$scope.medicationPrescription.prescriptionStatus ||
        !$scope.medicationPrescription.prescriptionStatus.code)
        && $scope.options.prescriptionId) {
        return 1;
      } else if ($scope.medicationPrescription.prescriptionStatus &&
        $scope.medicationPrescription.prescriptionStatus.code === 'active'
        && $scope.medicationPrescription.metadata
        && $scope.medicationPrescription.metadata.docStatus !== 'Signed'
        && $scope.medicationPrescription.metadata.docStatus !== 'entered in error'
        && $scope.medicationPrescription.prescriptionStatus
        && $scope.medicationPrescription.prescriptionStatus.code === 'active') {
        return 2;
      } else {
        return -1;
      }
    }


    var signedPdfUrl = "";
    $scope.getSignedPdf = function () {
      if (!signedPdfUrl) {
        signedPdfUrl = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Document/' + $scope.medicationPrescription.metadata.docId + '/signedPdf';
      }
      return signedPdfUrl;
    }

    loadForm();
  };
    viewPrescriptionFormCtrl.$inject = ["$scope", "$filter", "$location", "$erxModalConfirm", "$erxModalMedinfo", "$erxModalReason", "ErxPrescriptionsApiFactory", "erxPrescriptionsConfig", "erxApiConfig", "MessageService", "gettextCatalog"];

  return {
    restrict: 'E',
    scope: { options: '=' },
    templateUrl: 'modules/erx/prs/prescription_form/view_prescription_form.html',
    controller: viewPrescriptionFormCtrl
  };
});

