import { CommonModule, DatePipe } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SharedLibrary } from '../../../../shared/shared.module';
import { CapitalizePipe } from '../../../../shared/pipes/capitalize.pipe';
import { DowngradeAdapter } from '../../../../../utils/hypridHelper';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import './e010.routes';
import { E01011Component } from './e010-1-1/e010-1-1.component';
import {
    E01011FormNewbornComponent
} from './e010-1-1/e010-1-1-forms/e010-1-1-form-newborn/e010-1-1-form-newborn.component';
import { E01021Component } from './e010-2-1/e010-2-1.component';
import { E010FormMotherComponent } from './e010-forms/e010-form-mother/e010-form-mother.component';
import {
    E010FormPrevPregnanciesComponent
} from './e010-forms/e010-form-prev-pregnancies/e010-form-prev-pregnancies.component';
import { E010FormRiskFactorsComponent } from './e010-forms/e010-form-risk-factors/e010-form-risk-factors.component';
import {
    E01021FormNewbornComponent
} from './e010-2-1/e010-2-1-forms/e010-2-1-form-newborn/e010-2-1-form-newborn.component';
import { E010FormDeathComponent } from './e010-forms/e010-form-death/e010-form-death.component';
import {
    E01011FormChildbirthComponent
} from './e010-1-1/e010-1-1-forms/e010-1-1-form-childbirth/e010-1-1-form-childbirth.component';
import {
    E01021FormChildbirthComponent
} from './e010-2-1/e010-2-1-forms/e010-2-1-form-childbirth/e010-2-1-form-childbirth.component';
import {
    E01011FormObstetricProceduresComponent
} from './e010-1-1/e010-1-1-forms/e010-1-1-form-obstetric-procedures/e010-1-1-form-obstetric-procedures.component';
import {
    E01021FormObstetricProceduresComponent
} from './e010-2-1/e010-2-1-forms/e010-2-1-form-obstetric-procedures/e010-2-1-form-obstetric-procedures.component';
import { E010FormDataComponent } from './e010-forms/e010-form-data/e010-form-data.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        E01011Component,
        E010FormMotherComponent,
        E010FormPrevPregnanciesComponent,
        E010FormRiskFactorsComponent,
        E01011FormNewbornComponent,
        E01011FormChildbirthComponent,
        E01011FormObstetricProceduresComponent,
        E010FormDeathComponent,
        E01021Component,
        E01021FormNewbornComponent,
        E01021FormChildbirthComponent,
        E01021FormObstetricProceduresComponent,
        E010FormDataComponent
    ],
    providers: [CapitalizePipe, DatePipe],
    imports: [
        CommonModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        TimepickerModule,
    ],
})
export class E010Module {}

new DowngradeAdapter(ng1App)
    .downgradeComponent(E01011Component)
    .downgradeComponent(E01021Component);
