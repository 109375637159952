import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl } from '@angular/forms';
import {
    controlIsDisabled,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
@Component({
    selector: "app-inr-additional-info-subform",
    templateUrl: "./inr-additional-info-subform.component.html",
})
export class InrAdditionalInfoSubformComponent {
    @Input() editable: boolean = true;

    formGroup: FormControl = this.fb.control(null);
    maxDate = new Date();

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    constructor(
        private fb: FormBuilder
    ) {
    }
}
