<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto">
            <div class="form-group col-12 col-lg-8">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.title' | translate}}
                </span>
                <app-checkbox
                    *ngFor="let option of answersVariants?.['e096ptActions']; let i = index"
                    inputId="actionsTaken{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('actionsTaken'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'actionsTaken')"
                    (checkboxChange)="updateControl(option.name, 'actionsTaken')"
                ></app-checkbox>
            </div>
            <div
                class="form-group col-12 col-lg-4"
                [class.d-none]="!checkboxIsChecked('Paimta kraujo laboratoriniams tyrimams', 'actionsTaken')"
            >
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.bloodWasTaken' | translate}}
                </span>
                <app-checkbox
                    *ngFor="let option of bloodForTestsOptions; let i = index"
                    inputId="bloodForTests{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('bloodForTests'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'bloodForTests')"
                    (checkboxChange)="updateControl(option.name, 'bloodForTests')"
                ></app-checkbox>
            </div>
        </div>
        <div class="row mx-auto">
            <app-date-picker
                labelText="doc.pregnancyDashboard.e096Ptkl.formActionsTaken.teamArrived"
                inputId="anesthetistTeamArrived"
                class="form-group col-8 col-lg-4 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [fControl]="formGroup.get('anesthetistTeamArrived')"
            ></app-date-picker>
        </div>
        <div class="row mx-auto">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.bleedUterineAtony' | translate}}
                </span>
                <div class="row align-items-baseline">
                    <div class="col-12 ms-auto">
                        <ng-container *ngFor="let option of answersVariants?.['uterineAtonyBleeding']; let i = index">
                            <app-checkbox
                                inputId="bleedingUterineAtonyBUA{{i}}"
                                class="mt-1 col-auto"
                                formClass="mb-0"
                                [labelText]="option.name"
                                [checkboxValue]="option.name"
                                [checkboxDisabled]="!(editable && !controlDisabled('bleedingUterineAtony'))"
                                [checkboxChecked]="checkboxIsChecked(option.name, 'bleedingUterineAtony')"
                                (checkboxChange)="updateParentControl(option, 'bleedingUterineAtony', 'BUA')"
                            ></app-checkbox>
                            <div
                                *ngIf="option.result?.length"
                                class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap align-items-baseline"
                                [class.d-none]="!checkboxIsChecked(option.name, 'bleedingUterineAtony')"
                            >
                                <ng-container *ngIf="option.name === 'Skirta misoprostolio'">
                                    <app-select
                                        class="me-3"
                                        placeholder="kiekis"
                                        [id]="'lostBloodAmount'"
                                        [fControl]="formGroup.get('amountOfMisoprostolSelect')"
                                        [values]="amountOfMisoprostol"
                                    ></app-select>
                                </ng-container>
                                <app-radio-button
                                    *ngFor="let childValue of option.result; let j = index"
                                    radioName="{{generateChildControlName(option.name, 'BUA')}}{{j}}"
                                    [fControl]="dynamicChildControl(generateChildControlName(option.name, 'BUA'))"
                                    [radioValue]="childValue.name"
                                    class="mt-1 me-3"
                                    id="{{generateChildControlName(option.name, 'BUA')}}{{j}}"
                                    [labelText]="childValue.name"
                                ></app-radio-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-auto">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.traumaBirthCanal' | translate}}
                </span>
                <ng-container
                    *ngTemplateOutlet="nestedCheckboxList; context: {
                            list: answersVariants?.['birthCanalTraumaBleeding'],
                            formControlName: 'bleedingBirthCanal',
                            uniqSuffix: 'BBC'
                            }"
                ></ng-container>
            </div>
        </div>
        <div class="row mx-auto">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.placentalRemnants'}}
                </span>
                <ng-container
                    *ngTemplateOutlet="nestedCheckboxList; context: {
                            list: answersVariants?.['pacentaRemnantsBleeding'],
                            formControlName: 'bleedingPlacentalRemnants',
                            uniqSuffix: 'BPR'
                            }"
                ></ng-container>
            </div>
        </div>
        <div class="row mx-auto">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.bloodCoagulationDisorder' | translate}}
                </span>
                <ng-container *ngFor="let option of answersVariants?.['clottingDisorderBleeding']; let i = index">
                    <app-checkbox
                        inputId="bleedingCoagulationDisorderBCD{{i}}"
                        class="mt-1 col-auto"
                        formClass="mb-0"
                        [labelText]="option.name"
                        [checkboxValue]="option.name"
                        [checkboxDisabled]="!(editable && !controlDisabled('bleedingCoagulationDisorder'))"
                        [checkboxChecked]="checkboxIsChecked(option.name, 'bleedingCoagulationDisorder')"
                        (checkboxChange)="updateParentControl(option, 'bleedingCoagulationDisorder', 'BCD')"
                    ></app-checkbox>
                    <div
                        *ngIf="option.result?.length"
                        class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap align-items-baseline"
                        [class.d-none]="!checkboxIsChecked(option.name, 'bleedingCoagulationDisorder')"
                    >
                        <app-checkbox
                            *ngFor="let childValue of option.result; let j = index"
                            inputId="{{generateChildControlName(option.name, 'BCD')}}{{j}}"
                            class="mt-1 me-3"
                            formClass="mb-0"
                            [labelText]="childValue.name"
                            [checkboxValue]="childValue.name"
                            [checkboxDisabled]="!(editable && checkboxIsChecked(option.name, 'bleedingCoagulationDisorder') && !controlDisabled('bleedingCoagulationDisorder'))"
                            [checkboxChecked]="checkboxIsChecked(childValue.name, generateChildControlName(option.name, 'BCD'))"
                            (checkboxChange)="updateControl(childValue.name, generateChildControlName(option.name, 'BCD'))"
                        ></app-checkbox>
                        <app-input
                            *ngIf="option.name === 'Atlikta hemotransfuzija'"
                            [class.d-none]="!checkboxIsChecked('Kita', generateChildControlName(option.name, 'BCD'))"
                            [fControl]="formGroup.get('hemotransfusionOther')"
                        ></app-input>
                    </div>

                    <div
                        *ngIf="option.name === 'Nustatoma kraujo krešumo sutrikimo priežastis'"
                        class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap align-items-baseline"
                        [class.d-none]="!checkboxIsChecked(option.name, 'bleedingCoagulationDisorder')"
                    >
                        <app-input
                            [fControl]="formGroup.get('causeOfBloodClottingDisorder')"
                        ></app-input>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row mx-auto">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.heavyBleedingRemains' | translate}}:
                </span>
                <app-checkbox
                    *ngFor="let option of heavyBleedingRemainsOptions; let i = index"
                    inputId="heavyBleedingRemains{{i}}"
                    class="mt-1 col-auto"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('heavyBleedingRemains'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'heavyBleedingRemains')"
                    (checkboxChange)="updateControl(option.name, 'heavyBleedingRemains')"
                ></app-checkbox>
            </div>
        </div>
        <div class="row mx-auto">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.transferToOperation' | translate}}:"
                inputId="transferredToOperatingRoom"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('transferredToOperatingRoom')"
            ></app-date-picker>
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.laparotomyStarted' | translate}}:"
                inputId="laparotomyStarted"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('laparotomyStarted')"
            ></app-date-picker>
        </div>

        <div class="row mx-auto">
            <div class="form-group col-12 col-lg-6">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.laparotomy' | translate}}:
                </span>
                <app-checkbox
                    *ngFor="let option of answersVariants?.['laparathomy']; let i = index"
                    inputId="laparotomy{{i}}"
                    class="mt-1 col-auto"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('laparotomy'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'laparotomy')"
                    (checkboxChange)="updateControl(option.name, 'laparotomy')"
                ></app-checkbox>
            </div>
            <div
                class="form-group col-12 col-lg-6"
                [class.d-none]="!checkboxIsChecked('Atlikta palaipsninė gimdos devaskuliarizacija', 'laparotomy')"
            >
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.devascularizationUterus' | translate}}:
                </span>
                <app-checkbox
                    *ngFor="let option of gradualDevascularizationOfUterusOptions; let i = index"
                    inputId="gradualDevascularizationOfUterus{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('gradualDevascularizationOfUterus'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'gradualDevascularizationOfUterus')"
                    (checkboxChange)="updateControl(option.name, 'gradualDevascularizationOfUterus')"
                ></app-checkbox>
            </div>
        </div>
    </div>
</nhr-record-widget>

<ng-template #nestedCheckboxList let-list="list" let-formControlName="formControlName" let-uniqSuffix="uniqSuffix">
    <ng-container *ngFor="let option of list; let i = index">
        <app-checkbox
            inputId="{{formControlName}}{{uniqSuffix}}{{i}}"
            class="mt-1 col-auto"
            formClass="mb-0"
            [labelText]="option.name"
            [checkboxValue]="option.name"
            [checkboxDisabled]="!(editable && !controlDisabled(formControlName))"
            [checkboxChecked]="checkboxIsChecked(option.name, formControlName)"
            (checkboxChange)="updateParentControl(option, formControlName, uniqSuffix)"
        ></app-checkbox>
        <div
            *ngIf="option.result?.length"
            class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap"
            [class.d-none]="!checkboxIsChecked(option.name, formControlName)"
        >
            <app-checkbox
                *ngFor="let childValue of option.result; let j = index"
                inputId="{{generateChildControlName(option.name, uniqSuffix)}}{{j}}"
                class="mt-1 me-3"
                formClass="mb-0"
                [labelText]="childValue.name"
                [checkboxValue]="childValue.name"
                [checkboxDisabled]="!(editable && checkboxIsChecked(option.name, formControlName) && !controlDisabled(formControlName))"
                [checkboxChecked]="checkboxIsChecked(childValue.name, generateChildControlName(option.name, uniqSuffix))"
                (checkboxChange)="updateControl(childValue.name, generateChildControlName(option.name, uniqSuffix))"
            ></app-checkbox>
        </div>
    </ng-container>
</ng-template>