import {Component, Input, OnInit} from '@angular/core';
import {FilterGroupModel} from "../../../models/filter-group.model";
import {FormGroup} from "@angular/forms";
import {InputTypeEnum} from "../../../models/input-type.enum";

@Component({
    selector: 'filters-group',
    templateUrl: './filters-group.component.html'
})
export class FiltersGroupComponent implements OnInit {

    @Input() filters: FilterGroupModel[];
    @Input() form: FormGroup;

    constructor() {
    }

    ngOnInit(): void {

    }

    protected readonly InputTypeEnum = InputTypeEnum;
}