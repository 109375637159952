import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class BrowserSessionStorageService {
    static key = 'portal_bsid';

    getBsid() {
        return sessionStorage.getItem(BrowserSessionStorageService.key)
    }

    generateBsid() {
        var bsid = _.random(123456789, 912345678) + '' + new Date().getTime();
        sessionStorage.setItem(BrowserSessionStorageService.key, bsid);
    }

    ensureBsidExist() {
        if (!this.getBsid()) {
            this.generateBsid();
        }
    }
}