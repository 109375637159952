ng1App.controller('doctor.patient.cert.e103-1EditCtrl', 
    ['$scope', '$filter', 'MessageService', 'CertificatePayment', 'EntitiesApiFactory', 'CertificateService',
     function ($scope, $filter, MessageService, CertificatePayment, EntitiesApiFactory, CertificateService) {
    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.1031.mai.inf'),
                id: 'cer_1031_mai_inf'
            },
            {
                title: $filter('translate')('cer.1031.doc'),
                id: 'cer_1031_doc'
            }
        ]
    };

    $scope.$emit('setHelpPage', 'kurti-pazyma-e103-1-vaiko-gimimo-pazymejimas');

    var certificateType = 'e103_1';
    MessageService.clearAll();

    CertificatePayment.showPaymentMessage('e103-1');

    $scope.currentDate = new Date();


    $scope.certificate.$promise.then(function () {
        $scope.mother = $scope.certificate.mother;
        $scope.babyBirthDeliveryPlaces = EntitiesApiFactory.getList({classCode: 'baby-birth-delivery-place'}, function (data) {
            if(!$scope.certificate.babyBirthDeliveryPlace){
                $scope.certificate.babyBirthDeliveryPlace = data[0];
            } else {
                angular.forEach(data, function (item) {
                    if($scope.certificate.babyBirthDeliveryPlace.code === item.code){
                        $scope.certificate.babyBirthDeliveryPlace = item;
                    }
                });
            }
        });
    });

    $scope.saveAndSign = function (doSign) {
        console.log('save and sign');
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    // TODO implement this
    $scope.clear = function () {
        $scope.certificateForm.submitted = false;
        alert('Not implemented');
    };

}]);