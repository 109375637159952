import { Component, Input } from '@angular/core';
import moment from 'moment';
import { Entry, PartTableRowConfig } from '../../../../../models/e096.model';
import { LineConnection } from '../line-connector.directive';

@Component({
    selector: 'app-e096-part-table-cervix',
    templateUrl: './e096-part-table-cervix.component.html',
})
export class E096PartTableCervixComponent {
    @Input() set initialFormValue(value: Entry[]) {
        value.forEach(data => this.generateTableDataByTime(data));
    };
    @Input() mainColumns: string[] = [];
    @Input() row: PartTableRowConfig;
    @Input() drawLine: boolean;
    @Input() firstActiveColumn: number;
    @Input() lastLatentColumn: number;
    @Input() connections: LineConnection[] = [];
    @Input() showDescription: boolean = false;
    @Input({required: true}) phase: 'latent' | 'active' = null;

    rowsCounter = this.generateArray(0, 10);
    sortedData: {
        [key: string]: Entry
    } = {};
    headLevelValue = {
        '3': '2',
        '2': '3',
        '1': '4',
        '0': '5',
        '-1': '6',
        '-2': '7',
        '-3': '8',
    };

    constructor() {
    }

    getDataByHour(hour: string, period: number, value: number): any {
        return +this.sortedData[`cell-${hour}-${period}`]?.cervixOpening === +value;
    }

    getHeadLevelDataByHour(hour: string, period: number, value: number): any {
        if (+this.headLevelValue[this.sortedData[`cell-${hour}-${period}`]?.fetalRecords.find(value => !!value['levelOfThefirstPart']).levelOfThefirstPart] !== +value) return;
        const res = this.sortedData[`cell-${hour}-${period}`]?.fetalRecords.find(value => !!value['firstPart']).firstPart;
        return res === 'Sėdmenys' ? 'seat' : res === 'Galva' ? 'head' : '';
    }

    getKeyByValue(value: string | number): string {
        for (const key in this.headLevelValue) {
            if (this.headLevelValue.hasOwnProperty(key) && this.headLevelValue[key] === `${value}`) {
                return key;
            }
        }
        return null;
    }

    private generateArray<T>(start, end, specificValue?: T): Array<string | T> {
        return Array.from({length: end - start + 1}, (_, index) => specificValue ?? String(start + index));
    }

    private generateTableDataByTime(data: Entry): void {
        const hour = moment(data.timeOfEntry).hours();
        const period = Math.floor((moment(data.timeOfEntry).minutes() % 60) / 15) + 1;
        if (!this.sortedData?.[`cell-${hour}-${period}`]) {
            this.sortedData[`cell-${hour}-${period}`] = data;
        } else {
            this.sortedData[`cell-${hour}-${period}`] = moment(data.timeOfEntry) > moment(this.sortedData[`cell-${ hour }-${ period }`].timeOfEntry)
                ? data : this.sortedData[`cell-${ hour }-${ period }`];
        }
    }
}
