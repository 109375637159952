angular.module('erx').directive('erxViewPrescriptionDocRo', function () {
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/erx/patient-history/view-prescription/public/erxViewPrescriptionDoc.html',
    controller: ["$scope", function($scope) {
      $scope.options.hideDocInfo = false;
      $scope.options.noActions = true;
    }]
  };
});