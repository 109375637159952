<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e097Gd.InspectionsForm.title' | translate"
>
    <div class="card-body">
        <div class="row" *ngFor="let inspection of inspections">
            <fieldset class="form-group col-6 col-lg-3">
                <legend class="float-none form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{inspection.name | translate}}:
                </legend>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of answersVariants?.['inspectResults']; let i = index"
                        radioName="gd-{{inspection.formGroup}}-{{i}}"
                        [fControl]="formGroup.get(inspection.formGroup + '.result')"
                        [radioValue]="option.name"
                        id="gd-{{inspection.formGroup}}-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
            </fieldset>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e097Gd.InspectionsForm.comment' | translate}}:"
                class="form-group col-6 col-lg-9"
                labelClass="col-form-label"
                type="textarea"
                textAreaRows="3"
                [fControl]="formGroup.get(inspection.formGroup + '.comment')"
            ></app-input>
        </div>
    </div>
</nhr-record-widget>