angular.module('medvais.components').service('$medvaisCreateTelemedicineEncounter', ["$modal", "$rootScope", "$q", "$filter", "MedvaisOrderApiFactory", "MedvaisEncounterApiFactory", "MessageService", "MedvaisDateUtilsService", function (
        $modal, 
        $rootScope, 
        $q, 
        $filter,
        MedvaisOrderApiFactory,
        MedvaisEncounterApiFactory,
        MessageService,
        MedvaisDateUtilsService ) {
            
    var scope = $rootScope.$new();
    var deferred;
    
    scope.searchForm = {};
    scope.searchForm.dateFrom = '';
    scope.searchForm.dateTo = '';
    
    scope.data = {};
    scope.data.errorMsg = '';
    scope.data.organizationId;
    scope.data.organizationRef;
    scope.data.practitionerId;
    scope.data.practitionerRef;
    scope.data.telemedicineOrdersList = {};
    scope.loading = false;
    scope.needRefreshStudyList = false;
    
    scope.loadTelemedicineOrders = function() {
//        scope.data.telemedicineOrdersList = {};
        console.log("Date from: " + scope.searchForm.dateFrom);
        console.log("Date to: " + scope.searchForm.dateTo);
        scope.loading = true;
        MedvaisOrderApiFactory.getTelemedicineOrdersWithoutEncounter({
            sdate: scope.searchForm.dateFrom,
            edate: scope.searchForm.dateTo,
            practitionerId: scope.data.practitionerId,
            organizationId: scope.data.organizationId
        }).$promise.then(
            function(result){
                scope.data.telemedicineOrdersList = result;
            },
            function(error){
                MessageService.clearAll();
                scope.data.errorMsg = $filter('translate')('medvais.failed.read.telemedicine.orders.without.encounter.msg');
            }
        ).finally(function () {
            scope.loading = false;
        });
        
    };
    
    scope.createVirtualEncounter = function(index) {
        console.log(scope.data.telemedicineOrdersList[index].order);
        MedvaisEncounterApiFactory.createVirtual({organizationRef: scope.data.organizationRef, practitionerRef: scope.data.practitionerRef}, scope.data.telemedicineOrdersList[index].order).$promise.then(
            function(){
                scope.needRefreshStudyList = true;
                scope.loadTelemedicineOrders();
            },
            function(error){
                MessageService.clearAll();
                scope.data.errorMsg = $filter('translate')('medvais.failed.create.virtual.encounter.msg');
            }
        ).finally(function () {
        });        
        
    };
    
    scope.search = function() {
        scope.loadTelemedicineOrders();
    };
    
    scope.close = function() {
        if (scope.needRefreshStudyList) {
            deferred.resolve();
        }
        modal.hide();
    };
    
    
    var modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-create-telemedicine-encounter.html',
        title: $filter('translate')('medvais.telemedicine.orders.without.encounter'),
        scope: scope,
        show: false
    });
    var parentShow = modal.show;
    
    modal.show = function(organizationId, organizationRef, practitionerId, practitionerRef) {
        scope.searchForm.dateFrom = MedvaisDateUtilsService.get2DaysFromTodayStr();
        scope.searchForm.dateTo = MedvaisDateUtilsService.getTodayStr();
        scope.data.errorMsg = '';
        scope.data.organizationId = organizationId;
        scope.data.organizationRef = organizationRef;
        scope.data.practitionerId = practitionerId;
        scope.data.practitionerRef = practitionerRef;
        scope.loadTelemedicineOrders();
        
        parentShow();
        deferred = $q.defer();
        return deferred.promise;
    };
    return modal;
            
}]);