<div>
    <h1>Galiojantis paciento slaugos ir priežiūros planas</h1>
    <back-link [path]="" />

    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <div *ngIf="nursingAndCarePlan">
        <form>
            <!-- <nhr-record-widget [closable]="true" [closed]="false" [title]="'Slaugos ir priežiūros planas' | translate"
                id="header">
                <div class="row">
                    <div class="col-md-2 fw-bold">{{'Pacientas' | translate}}</div>
                    <div class="col-md-4">
                        {{patient.givenName+' '+patient.familyName}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-2 fw-bold">{{'Sudarymo data' | translate}}</div>
                    <div class="col-md-4">
                        {{nursingAndCarePlan.header.createDate}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-2 fw-bold">{{'Planą sudarė' | translate}}</div>
                    <div class="col-md-4">
                        {{nursingAndCarePlan.header.specialist}}
                    </div>
                </div>
            </nhr-record-widget> -->
            <div *ngFor="let nursingSolution of nursingAndCarePlan.nursingSolutions; let i = index; let last = last">
                <nhr-record-widget [closable]="true" [closed]="false"
                    [title]="'Slaugos problema ir sprendimo metodai' | translate" id="nursingSolutions">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.problem !== ''">
                            <div class="fw-bold">{{ "Būklės vertinime identifikuota slaugos problema" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.problem }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.justification !== ''">
                            <div class="fw-bold">{{ "Pagrindimas" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.justification }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.diagnosis !== ''">
                            <div class="fw-bold">{{ "Slaugos diagnozė" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.diagnosis }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.assessment !== ''">
                            <div class="fw-bold">{{ "Vertinimas" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.assessment }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.purpose !== ''">
                            <div class="fw-bold">{{ "Slaugos tikslas" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.purpose }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.procedures !== ''">
                            <div class="fw-bold">{{ "Procedūros" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.procedures }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="nursingSolution.visits !== ''">
                            <div class="fw-bold">{{ "Vizitai ir vizito trukmė (7 d.d.)" | translate }}
                            </div>
                            <div>
                                {{ nursingSolution.visits }}
                            </div>
                        </div>
                    </div>
                </nhr-record-widget>
            </div>

            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Supažindinimas' | translate"
                id="introduction">
                <div class="row">
                    <div class="col-md-4 fw-bold">{{'Pacientas supažindintas su planu' | translate}}</div>
                    <div class="col-md-1">
                        <app-checkbox inputId="patientIntroduction"
                            [checkboxChecked]="nursingAndCarePlan.planIntroduction.patient" checkboxDisabled="true">
                        </app-checkbox>
                    </div>
                    <div class="col">
                        {{nursingAndCarePlan.planIntroduction.patientFalseReason}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 fw-bold">{{'Artimieji supažindinti su planu' | translate}}</div>
                    <div class="col-md-1">
                        <app-checkbox inputId="relativesIntroduction"
                            [checkboxChecked]="nursingAndCarePlan.planIntroduction.relatives" checkboxDisabled="true">
                        </app-checkbox>
                    </div>
                    <div class="col">
                        {{nursingAndCarePlan.planIntroduction.relativesFalseReason}}
                    </div>
                </div>
            </nhr-record-widget>
        </form>


    </div>
</div>