<div *ngIf="viewData">
    <app-title [backPath]="backPath">
        {{'025-5/a - ASIS klausimynas'| translate}}
    </app-title>
    <!--<h1 class="mb-0">{{'Naujas sutikimas'| translate}}</h1> -->
    <!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
        (valueChange)="onChanges($event)"></app-common-patient-widget>
    <app-diagnoses-form *ngIf="!isLoading" (currentDiagnosesChange)="onDiagnosisChanges($event)"
        [viewOnly]="isViewOnlyMode" [currentDiagnoses]="diagnosisArray"
        [isRequiredFieldInForm]=" true"></app-diagnoses-form>
    <nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
        [closed]="false" [mixed]="true" [title]="'I. Bendrieji duomenys'">
        <div class="widget-body">
            <form [formGroup]="form1">
                <div class="mb-2 row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Vizito
                        data <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.visitDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label pt-0 text-xs-start text-md-end" for="diseaseType">Formos tipas
                        <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseFormType" id="diseaseType"
                                formControlName="diseaseFormType" [value]="true" />
                            <label class="form-check-label" for="diseaseType">
                                Pirminė
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseFormType" id="diseaseType"
                                formControlName="diseaseFormType" [value]="false" />
                            <label class="form-check-label" for="diseaseType">
                                Patikslinta
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kas nukreipė
                        gydymo paslaugoms? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.whoDirectedForTreatment.name}}
                    </div>
                </div>
                <div *ngIf="form1.get('whoDirectedForTreatment').value === '9'" class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Jei
                        pasirinkta "Kitais" įrašykite</label>
                    <div class="col-md-6">
                        {{viewData.otherOptionsWhoDirectedForTreatment}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar nors kartą
                        gyvenime gydytas
                        bet kurioje sveikatos priežiūros įstaigoje
                        dėl narkotinių ir psichotropinių
                        medžiagų vartojimo? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.possibleRehabForSubstanceAbuse.name}}
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pirmo
                        gydymo pradžios data</label>
                    <div class="col-md-6">
                        {{viewData.BeginningOfFirstTreatmentDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar nors kartą
                        gyvenime teiktas
                        pakaitinis gydymas opioidiniais
                        vaistiniais preparatais?</label>
                    <div class="col-md-6">
                        {{viewData.opioidSubstitutionTherapy.name}}
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pirmo
                        gydymo data</label>
                    <div class="col-md-6">
                        {{viewData.firstTreatmentDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
        [closed]="false" [mixed]="true" [title]="'II. Socialinė demografinė informacija'">
        <div class="widget-body">
            <form [formGroup]="form2">
                <div class="row mb-2">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Faktinė
                        gyvenamoji vieta
                        (savivaldybės kodas) <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{emitedValues.patient.declaredAddress.municipalityId}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Duomenys apie
                        gyvenamąją vietą <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.dataAboutPlaceOfResidence.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar gyvena su
                        nepilnamečiais vaikais? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.livesWithMinors.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Su kuo šiuo
                        metu gyvena? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.livesWithWho.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Užimtumas šiuo
                        metu <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.currentEmployment.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Įgytas
                        išsilavinimas <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.receivedEducation.name}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
        [closed]="false" [mixed]="true" [title]="'III. Duomenys apie psichoaktyviųjų medžiagų vartojimą'">
        <div class="widget-body">
            <form [formGroup]="form3">
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pagrindinė
                        psichoaktyvioji medžiaga, dėl kurios asmuo kreipėsi į sveikatos priežiūros įstaigą <span
                            class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.substanceForSeekingHelp.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pagrindinės
                        psichoaktyviosios medžiagos vartojimo būdas šiuo metu: <span
                            class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.mainWayOfUsingSubstance.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kada pirmą
                        kartą gyvenime pavartojo
                        bet kokią narkotinę ar psichotropinę
                        medžiagą? (įrašykite amžių) <span class="text-danger">*</span> </label>
                    <div class="col-md-6">
                        {{viewData.firstTimeUseAge}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kada pirmą
                        kartą gyvenime pavartojo
                        pagrindinę narkotinę ar psichotropinę
                        medžiagą? (įrašykite amžių) <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.firstTimeUseAgeForMainSubstance}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pagrindinės
                        psichoaktyviosios medžiagos vartojimo dažnumas per pastarąsias 30 dienų: <span
                            class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.frequencyOfSubstanceUse.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar vartoja
                        skirtingas psichoaktyviąsias medžiagas? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.useOfDifferentSubstances.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kitos per
                        pastarąsias 30 dienų vartotos psichoaktyviosios medžiagos (pirma pagal dažnumą po pagrindinės
                        psichoaktyviosios medžiagos):</label>
                    <div class="col-md-6">
                        {{viewData.useOfOtherSubstancesFirst.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kitos per
                        pastarąsias 30 dienų vartotos psichoaktyviosios medžiagos (antra pagal dažnumą po pagrindinės
                        psichoaktyviosios medžiagos):</label>
                    <div class="col-md-6">
                        {{viewData.useOfOtherSubstancesSecond.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar kada nors
                        gyvenime švirkštėsi narkotines ir psichotropines medžiagas:</label>
                    <div class="col-md-6">
                        {{viewData.useOfInjectableSubstances.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kiek laiko
                        praėjo nuo pirmojo gyvenime narkotinės ar psichotropinės medžiagos švirkštimosi?</label>
                    <div class="col-md-6">
                        {{viewData.timeSpanFromLastInjection.name}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
        [closed]="false" [mixed]="true" [title]="'IV. Rizikingas elgesys/užkrečiamosios ligos'">
        <div class="widget-body">
            <form [formGroup]="form4">
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar kada nors
                        skolinosi iš kitų ar skolino kitiems naudotas adatas/švirkštus ar kitas švirkštimosi priemones?
                        <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.useOfBorrowedEquipment.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar užsikrėtęs
                        ŽIV? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.HIVpositive.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar tyrėsi dėl
                        ŽIV?</label>
                    <div class="col-md-6">
                        {{viewData.checkedOutForHIV.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar buvo siųstas
                        tirtis dėl ŽIV?</label>
                    <div class="col-md-6">
                        {{viewData.sentToCheckForHIV.name}}
                    </div>
                </div>
                <div *ngIf="form4.get('sentToCheckForHIV').value === '3'" class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Jei
                        pasirinkta "Kitais" įrašykite</label>
                    <div class="col-md-6">
                        {{viewData.otherOptionsForHIV}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar šiuo metu
                        serga hepatitu B (HBV)? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.hepatitisB.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar tyrėsi dėl
                        hepatito B (HBV)?</label>
                    <div class="col-md-6">
                        {{viewData.checkedOutForHepatitisB.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar buvo siųstas
                        tirtis dėl hepatito B (HBV)?</label>
                    <div class="col-md-6">
                        {{viewData.sentToCheckForHepatitisB.name}}
                    </div>
                </div>
                <div *ngIf="form4.get('sentToCheckForHepatitisB').value === '3'" class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Jei
                        pasirinkta "Kitais" įrašykite</label>
                    <div class="col-md-6">
                        {{viewData.otherOptionsForHepatitisB}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar skiepytas
                        nuo hepatito B:</label>
                    <div class="col-md-6">
                        {{viewData.vaccinatedForHepatitisB.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar šiuo metu
                        serga hepatitu C (HCV)? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.hepatitisC.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar tyrėsi dėl
                        hepatito C (HCV)?</label>
                    <div class="col-md-6">
                        {{viewData.checkedOutForHepatitisC.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar buvo siųstas
                        tirtis dėl hepatito C (HCV)?</label>
                    <div class="col-md-6">
                        {{viewData.sentToCheckForHepatitisC.name}}
                    </div>
                </div>
                <div *ngIf="form4.get('sentToCheckForHepatitisC').value === '3'" class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Jei
                        pasirinkta "Kitais" įrašykite</label>
                    <div class="col-md-6">
                        {{viewData.otherOptionsForHepatitisC}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar serga
                        tuberkulioze? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.tuberculosis.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar tyrėsi dėl
                        tuberkuliozės? <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.checkedOutForTuberculosis.name}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar buvo siųstas
                        tirtis dėl tuberkuliozės?</label>
                    <div class="col-md-6">
                        {{viewData.sentToCheckForTuberculosis.name}}
                    </div>
                </div>
                <div *ngIf="form4.get('sentToCheckForTuberculosis').value === '3'" class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Jei
                        pasirinkta "Kitais" įrašykite</label>
                    <div class="col-md-6">
                        {{viewData.otherOptionsForTuberculosis}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
        [closed]="false" [mixed]="true" [title]="'V. Diagnozė, gydymas, suteiktos paslaugos'">
        <div class="widget-body">
            <form [formGroup]="form5">
                <div class="form-group row">
                    <label class="col-md-5 col-form-label pt-0 text-xs-start text-md-end"
                        for="patientCareType">Pacientui teikiamų asmens sveikatos
                        priežiūros paslaugų tipas: <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="patientCareServiceType"
                                id="patientCareType" formControlName="patientCareServiceType" [value]="true" />
                            <label class="form-check-label" for="patientCareType">
                                Ambulatorinės paslaugos
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="patientCareServiceType"
                                id="patientCareType" formControlName="patientCareServiceType" [value]="false" />
                            <label class="form-check-label" for="patientCareType">
                                Stacionarinės paslaugos
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kokios
                        suteiktos paslaugos?
                        (įrašykite paslaugos (-ų) kodą (-us)) <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.providedServices}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kokiais
                        vaistiniais preparatais gydomas šiame gydymo epizode?</label>
                    <div class="col-md-6">
                        {{viewData.treatmentUsedAtThisStage.name}}
                    </div>
                </div>
                <div *ngIf="form5.get('treatmentUsedAtThisStage').value === '5'" class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Jei
                        pasirinkta "Kitais" įrašykite</label>
                    <div class="col-md-6">
                        {{viewData.otherTreatmentUsedAtThisStage}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
        [closed]="false" [mixed]="true"
        [title]="'VI. Išrašymo iš asmens sveikatos priežiūros įstaigos priežastis/gydymo baigtis'">
        <div class="widget-body">
            <form [formGroup]="form6">
                <div class="form-group row">
                    <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Išrašymo iš
                        asmens sveikatos
                        priežiūros įstaigos
                        priežastis/gydymo baigtis</label>
                    <div class="col-md-6">
                        {{viewData.dischargedFromHealthInstitution.name}}
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Vizito
                        data <span class="text-danger">*</span></label>
                    <div class="col-md-6">
                        {{viewData.formFillDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div class="form-group row">
                    <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Papildoma
                        informacija</label>
                    <div class="col-md-6">
                        {{viewData.otherInformation}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <undo-actions (undo)="undoClick()"></undo-actions>
</div>