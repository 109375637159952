/**
 * @deprecated since migration to angular.
 * Now using predefined-filters.component.ts downgraded component.
 */
ng1App.directive('_predefinedFilters', 
    ['$filter',
     function ($filter) {
	return {
        templateUrl: '/templates/components/lists/predefined_filters.html',
        restrict: 'E',
        replace: false,
        scope: {
            filters: '=',
            currentFilter: '=',
            filterChanged: '&',
            title: '@',
            loading: '='
        },
        link: function (scope: any, element, attrs) {
            scope.titleName = attrs.title ? attrs.title : $filter('translate')('lis.fil.rec');
        	scope.changeFilter = function (filter) {
        		if (filter.count > 0 || filter.noCount) {
        			scope.filterChanged()(filter);
        		}
        	};
                scope.predicate = function (filter) {
        		return filter.show === undefined || filter.show === null || filter.show;
        	};
        }
	};
}]);

ng1App.directive('btnLoadMore', function () {
    return {
        templateUrl: '/templates/components/lists/btn_load_more.html',
        restrict: 'E',
        replace: false,
        scope: {
            loadMore: '&',
            loading: '=',
            title:'@'
        }
    };
});