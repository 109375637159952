import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    controlIsDisabled,
    generateNameForChildControl,
    multiCheckboxIsChecked,
    updateCheckboxControl, updateParentControlAndCreateChild
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-ptkl-vacuum-extraction',
    templateUrl: './e096-ptkl-vacuum-extraction.component.html',
})
export class E096PtklVacuumExtractionComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    mainFormGroup: FormGroup;
    useVacuumExtractionControl: FormControl = this.fb.control(null, [Validators.required]);
    useVacuumExtractionOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Panaudotos'
        },
        {
            id: '1',
            code: '1',
            name: 'Nepanaudotos'
        }
    ];
    maxDate = new Date();
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    generateChildControlName = generateNameForChildControl.bind(this) as typeof generateNameForChildControl;
    updateParentControl = updateParentControlAndCreateChild.bind(this) as typeof updateParentControlAndCreateChild;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.mainFormGroup = this.fb.group({
            startOfProcedure: this.fb.control({value: null, disabled: true}),
            indicationForVacuumExtraction: this.fb.control({value: [], disabled: true}),
            conditionsForVacuumExtraction: this.fb.control({value: [], disabled: true}),
            actionsTakenWithVacuumExtraction: this.fb.control({value: [], disabled: true}),
            endOfProcedure: this.fb.control({value: null, disabled: true}),
            notes: this.fb.control({value: null, disabled: true}),
            performedProcedure: this.fb.array([], [Validators.maxLength(1)])
        })
        this.formGroup = this.fb.group({
            useVacuumExtraction: this.useVacuumExtractionControl,
            ...this.mainFormGroup.controls
        });

        this.useVacuumExtractionControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(value => {
                if ('Panaudotos' === value) {
                    this.mainFormGroup.enable({emitEvent: false});
                } else if (this.mainFormGroup.enabled) {
                    this.mainFormGroup.reset();
                    this.mainFormGroup.disable({emitEvent: false});
                }
            });
        this.formGroup.get('startOfProcedure').valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(value => {
                if (value && this.formGroup.get('endOfProcedure').value && value > this.formGroup.get('endOfProcedure').value) {
                    this.formGroup.get('endOfProcedure').reset();
                }
            });
        this.formGroup.get('endOfProcedure').valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(value => {
                this.maxDate = new Date(Math.min(Date.now(), new Date(value)?.getTime()));
            });
    }
}
