ng1App.controller('doctor.user.medvais.ImagingStudySignCtrl', 
    ['$scope', 'SessionApiFactory', 'DpSessionContext', 'MedvaisPaths', '$routeParams',
     function ($scope, SessionApiFactory, DpSessionContext, MedvaisPaths, $routeParams) {
    $scope.medvaisSignDocOptions = {

        // the id of the document for signing
        documentId: $routeParams.documentId,

        getPractitioner: function(){
            return DpSessionContext.practitioner.$promise;
        },
        getOrganization: function(){
            return DpSessionContext.organization.$promise;
        },

        // the url that will be passed for callback to the gosign endpoint.
        // it should be a page with the medvais-unisign-callback directive
        getUnisignCallbackUrl: function () {
            return MedvaisPaths.unisignCallback($routeParams.documentId);
        },

        // return url to document
        getReturnToDocUrl: function () {
            return MedvaisPaths.view($routeParams.documentId);
        },
        // to get active qualification
        getAccountSettings: function () {
            return DpSessionContext.getSettings();
        }
    };
}]);


