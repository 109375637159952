ng1App.directive('sveidraRelatedDocuments', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/sveidra_related_documents.html',
		restrict: 'E',
		scope: {
			title: '@',
			load: '&',
			fromSveidra: '@',
			patientId: '='
		},
		link: function (scope: any, element, attr) {
			scope.documentsLoaded = false;
			scope.$on('widget_toggle', function (event, data) {
				if (!scope.dataLoaded) {
					scope.load()(response);
				}
			});
			
			var response = function (documents) {
				scope.relatedDocuments = documents;
				scope.documentsLoaded = true;
			};
		}
	};
});