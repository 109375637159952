import {Injectable} from '@angular/core';
import {ConfigService} from "../app/config/config.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Tasks extends ConfigService {
    path = this.apiUrl + '/tasks';

    getActiveCount(params: any): Observable<any> {
        const url = `${this.path}/count`;
        return this.http.get<any>(url, {params: params});
    }

}
