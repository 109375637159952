<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngFor="let prs of prescriptions">
        <div class="row mb-2">
            <div class="col-3">
                <span class="badge rounded-pill text-bg-primary text-white"
                      [ngClass]="{'text-bg-dark': prs.status !== 'completed', 'text-bg-success': prs.status === 'completed'}">
                    {{ prs.status | translate }}
                </span><br />
                <span class="badge rounded-pill badge-outline text-primary mt-3">
                    {{ (prs.compensationTag ? 'ap.med.cat.compensated' : 'ap.med.cat.not_compensated') | translate }}
                </span>
            </div>
            <div class="col-9">
                <div class="fw-bold mb-0" *ngIf="prs.medication">
                    <span *ngIf="prs.prescriptionTagsNominalTag" class="fw-bold mb-0">
                        {{ 'vv' | translate }}{{ ' - ' }}
                    </span>
                    <span *ngIf="showMedicationName(prs)"
                          class="fw-bold mb-0">
                        {{ prs.medication.name }}
                    </span>
                    <span *ngIf="showMedicationName(prs) && prs.medication?.activeSubstances" class="vr"></span>
                    <span *ngIf="prs.medication?.activeSubstances" class="fw-bold mb-0">
                        {{ prs.medication?.activeSubstances }}
                    </span>
                    <span *ngIf="prs.medication?.strength" class="vr"></span>
                    <span *ngIf="prs.medication?.strength" class="fw-bold mb-0">
                        {{ prs.medication?.strength }}
                    </span>
                    <span *ngIf="prs.medication?.form" class="vr"></span>
                    <span *ngIf="prs.medication?.form" class="fw-bold mb-0">
                        {{ prs.medication?.form?.display }}
                    </span>
                </div>
                <div>
                    <span class="mb-0" *ngIf="prs.status !== 'completed'">
                        {{ 'valid.until' | translate }}{{ ': ' }}{{ moment(prs['validityPeriod.end']).format("YYYY-MM-DD") }}
                    </span>
                    <span class="mb-0" *ngIf="prs.status === 'completed'">
                        {{ 'Pakanka iki' | translate }}{{ ': ' }}{{ moment(prs.dispenseDueDate).format("YYYY-MM-DD") }}
                    </span>
                </div>
            </div>
        </div>
    </li>
    <li *ngIf="!loading" class="list-group-item p-0">
        <div class="row p-0">
            <div class="col">
                <a [href]="getRecipesUrl()" class="d-block text-decoration-none hover text-center px-2_5 py-2">
                    {{ 'Visi receptai' | translate }}
                </a>
            </div>
            <div class="col">
                <a [href]="getDispensesUrl()"
                   class="d-block text-decoration-none hover text-center px-2_5 py-2">
                    {{ 'Visi išdavimai' | translate }}
                </a>
            </div>
        </div>
    </li>
</nhr-record-widget>