<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item mouse-pointer" *ngFor="let diagnosis of diagnoses"
        (click)="showDocument(diagnosis.referencedDocumentId)"
        (keypress)="showDocument(diagnosis.referencedDocumentId)"
        tabindex="0">
        <p class="mb-0">
            <small class=text-secondary>
                {{ diagnosis.date }}
            </small>
        </p>
        <span *ngIf="diagnosis.orpha" class="fw-bold mb-0">
            {{ diagnosis.orpha?.code }}
        </span>
        <span *ngIf="diagnosis.orpha" class="vr"></span>
        <span *ngIf="diagnosis.tlk" class="fw-bold mb-0">
            {{ diagnosis.tlk.code }}{{ ' - ' }}{{ diagnosis.tlk.displays }}
        </span>
        <div class="mb-0">
            <div class="text-truncate">
                {{ diagnosis.notes }}
            </div>
        </div>
    </li>
    <li *ngIf="!loading" class="list-group-item p-0">
        <a href="/dp/patients/{{patientId}}/diagnosis2/{{formFilter()}}"
            class="d-block text-decoration-none hover text-center py-2 px-2_5">
            {{ 'show.more' | translate }}
        </a>
    </li>
</nhr-record-widget>