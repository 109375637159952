import {Pipe, PipeTransform} from '@angular/core';
import {CapitalizePipe} from "./capitalize.pipe";

@Pipe({name: 'fullName'})
export class FullNamePipe implements PipeTransform {
    constructor(private capitalizePipe: CapitalizePipe) {
    }

    transform(person: any): any {
        return this.capitalizePipe.transform(_.getFullName(person));
    }
}