<div>
    <h1>Slaugos paslaugų užsakymas (Paskyrimas)</h1>
    <back-link [path]="'/dp'" />
    <div *ngIf="nursingServiceOrder">
        <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
            [prefillData]="prefillData"></app-common-patient-widget>
        <form [formGroup]="nursingServiceOrder" (submit)="onSave()">

            <nhr-record-widget [closable]="true" [closed]="!route.order27Id"
                [title]="'Siuntimo informacija' | translate" id="f027Info">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <app-input id="practitioner" viewMode="true"
                            value="{{nursingServiceOrderF027Order && nursingServiceOrderF027Order.orderPractitionierFullName}}"
                            labelText="{{'Siuntimą išrašęs specialistas' | translate}}" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-input id="aspnNeed" viewMode="true"
                            value="{{nursingServiceOrderF027Order && nursingServiceOrderF027Order.aspnNeedValue}}"
                            labelText="{{'ASPN Poreikis' | translate}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-input id="organization" viewMode="true"
                            value="{{nursingServiceOrderF027Order && nursingServiceOrderF027Order.orderOrganizationName}}"
                            labelText="{{'Siuntimą išrašiusi įstaiga' | translate}}" />

                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-input id="nursingServicesType" viewMode="true"
                            value="{{nursingServiceOrderF027Order && nursingServiceOrderF027Order.nursingServiceType}}"
                            labelText="{{'Slaugos paslaugų tipas' | translate}}" />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <label for="nursingServicesType">{{'Siuntimo tikslas' |
                            translate}}</label>
                        <textarea class="form-control form-outline w-100" aria-label="With textarea" readonly="true"
                            id="nursingServicesType"
                            rows="4">{{nursingServiceOrderF027Order ? nursingServiceOrderF027Order.otherInfo : ""}}</textarea>
                    </div>
                </div>
            </nhr-record-widget>
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Paskyrimo duomenys' | translate"
                id="orderData">
                <div class="row">
                    <div class="col-sm-12 col-md-3">
                        <app-input id="appointmentDate_s" type="text" labelText="Paskyrimo data"
                            [fControl]="nursingServiceOrder.get('appointmentDate')"
                            value="{{nursingServiceOrder.get('appointmentDate').value}}" [readonly]="true" />
                    </div>


                    <div class="col-sm-12 col-md-6">
                        <label class="form-label flex-grow-1 col-form-label" for="appointmentPractitioner">{{
                            'Specialistas' | translate }}
                        </label>


                        <input id="appointmentPractitioner" formControlName="appointmentPractitionerDisplay"
                            typeaheadOptionField="fullDisplay" [typeahead]="suggestions$" [typeaheadAsync]="true"
                            [typeaheadMinLength]="0" [typeaheadItemTemplate]="customItemTemplate2"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            (typeaheadOnSelect)="typeaheadOnSelectP($event)" (typeaheadOnBlur)="onBlurtypeahead($event)"
                            placeholder="Įveskite specialistą" autocomplete="off" class="form-control" />
                        <ng-template #customItemTemplate2 let-model="item">
                            <span>{{model.familyName}} {{model.givenName}} ({{model.qualificationList &&
                                model.qualificationList[0].name }}) </span>
                        </ng-template>




                    </div>


                    <div class="col-sm-12 col-md-3 align-self-end">

                        <button type="button" class="btn btn-info text-nowrap mt-3 mt-md-0"
                            (click)="openModal(modalTemplate)" [disabled]="!practitionierSelected">{{'Specialisto
                            užimtumas' | translate}} </button>
                        <ng-template #modalTemplate>
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="specTimetableModalLabel">Specialisto užimtumas</h1>
                                <button type="button" class="close btn-close pull-right" aria-label="Close"
                                    (click)="modalRef?.hide()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="modal-body">
                                    <full-calendar *ngIf="calendarOptions" [options]="calendarOptions">
                                        <ng-template #eventContent let-arg>
                                            <div class="fc-custom-event {{arg.event._def.extendedProps.status ?? ''}}">
                                                <!-- TODO: Fix tooltip, should work on long names and small event blocks -->
                                                <div class="event-content"
                                                    tooltip="{{arg.event._def.extendedProps.patientFullName}}">
                                                    <strong>Pacientas</strong>
                                                    <div>{{arg.event._def.extendedProps.patientFullName}}</div>
                                                </div>
                                                <strong class="status">
                                                    <span
                                                        class="badge rounded-pill {{badgeByStatus[arg.event._def.extendedProps.status]}}">
                                                        {{labelByStatus[arg.event._def.extendedProps.status]}}
                                                    </span>
                                                </strong>
                                            </div>
                                        </ng-template>

                                    </full-calendar>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                </div>
            </nhr-record-widget>

            <div *ngFor="let procedure of proceduresControls; let i = index; let last = last">
                <nhr-record-widget [closable]="true" [closed]="false" [title]="'Procedūra' | translate"
                    [id]="'procedure-'+i">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 form-group req">
                            <app-select id="{{'procedureGroup-' + i}}" labelText="{{'Procedūrų grupė' | translate}}"
                                [fControl]="procedure.get('procedureGroup')" [values]="procedureGoupOpts"
                                [showErrors]="true"
                                (onChange)="onProcedureGroupChange($event, i)" [inputClass]="req" />

                        </div>
                        <div class="col-sm-12 col-md-4 form-group">
                            <app-multiselect-dropdown placeholder="Pasirinkite"
                                [dropdownDataList]="placeInBodyProcedureOpts" idField="id" textField="name"
                                elementId="placeInBodyProcedure" itemsShowLimit="{{itemsShowLimit}}"
                                [fControl]="procedure.get('placeInBodyProcedure')"
                                labelText="{{'Procedūros vieta' | translate}}" />
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-date-picker inputId="{{'procedureFromDate-' + i}}"
                                [fControl]="procedure.get('procedureFromDate')" [minDate]="minDate"
                                labelText="{{'Procedūrą atlikti nuo' | translate}}" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-4 form-group req">
                            <app-multiselect-dropdown placeholder="Pasirinkite" [dropdownDataList]="procedureOpts[i]"
                                idField="id" textField="name" elementId="procedures" itemsShowLimit="{{itemsShowLimit}}"
                                [fControl]="procedure.get('procedures')" labelText="{{'Procedūros' | translate}}" />
                            <app-form-control-validation [fControl]="procedure.get('procedures')" />
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-multiselect-dropdown placeholder="Pasirinkite" [dropdownDataList]="medicinesOpts"
                                idField="id" textField="name" elementId="medicines" itemsShowLimit="{{itemsShowLimit}}"
                                [fControl]="procedure.get('medicines')"
                                labelText="{{'Priemonės/Medikamentai' | translate}}"
                                (onFilterChange)="onFilterChange($event)" />

                        </div>
                        <div class="col-sm-12 col-md-4">
                            <app-date-picker inputId="{{'procedureToDate-' + i}}"
                                [fControl]="procedure.get('procedureToDate')" [minDate]="minDate"
                                labelText="{{'Procedūrą atlikti iki' | translate}}" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-2 form-group req">
                            <app-select id="{{'periodicity-' + i}}" labelText="{{'Periodiškumas' | translate}}"
                                [fControl]="procedure.get('periodicity')" [values]="periodicityOpts"
                                [showErrors]="true"
                                (onChange)="onPeriodicityChange($event, i)" />
                        </div>
                        <div class="col-sm-12 col-md-2 form-group req">
                            <app-input id="{{'frequency' + i}}" type="number" [fControl]="procedure.get('frequency')"
                                labelText="Dažnumas" [showErrors]="true" />
                        </div>
                        <div class="col-sm-12 col-md-8">
                            <app-input id="{{'recommendations' + i}}" type="text" [showErrors]="true"
                                [fControl]="procedure.get('recommendations')" labelText="Rekomendacijos ir pastabos" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <app-checkbox inputId="{{'urgent-' + i}}" labelText='{{ "Skubu" | translate }}'
                                [fControl]="procedure.get('urgent')"></app-checkbox>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-end">
                            <button *ngIf="last" class="btn btn-primary m-1" type="button"
                                (click)="addProcedureFormGroup()">Prideti</button>
                            <button *ngIf="i != 0 || !last" class="btn btn-primary m-1" type="button"
                                (click)="removeProcedureFormGroup(i)">Atimti</button>
                        </div>
                    </div>
                </nhr-record-widget>
            </div>

            <app-modification-actions [signButton]="true" [signingSubmitButton]="true" (save)="onSave()"
                (clear)="onClear()"></app-modification-actions>
        </form>
    </div>
</div>