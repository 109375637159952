ng1App.controller('doctor.patient.VaccineRecordEditCtrl', 
    ['$scope', '$routeParams', '$window', 'VaccinationRecordsApiFactory', 'VaccinationUpdateService', 'MessageService', 'SubFormValidator',
     function ($scope, $routeParams, $window, VaccinationRecordsApiFactory, VaccinationUpdateService, MessageService, SubFormValidator) {
    $scope.record = {};

    $scope.fileDocument = null;
    $scope.fileDocumentName = null;
    $scope.documentFileId = null;
    $scope.maxFileSize = 0;

    $scope.loadMaxFileSize = function() {
        VaccinationRecordsApiFactory.getMaxFileSize({}, function(data) {
            console.log("Data: " + Object.entries<any>(data));
            console.log("Data next: " + data['MaxFileSize']);
            $scope.maxFileSize = data['MaxFileSize']/ 1024 / 1024;
            console.log("Max file size first: " + $scope.maxFileSize);
        })
    }
    $scope.loadMaxFileSize();

    $scope.loadRecords = function() {
        VaccinationRecordsApiFactory.getRecordById({isVaccinationDoc: true, recordId: $routeParams.recordId}, function(data) {
            $scope.record = data;
            angular.forEach($scope.record.o_faktai, function (child) {
                $scope.fileDocumentName = angular.element(child).attr('vaccineDocumentFileName');
            });
        });
    };
    $scope.loadRecords();

    $scope.cancelVaccineRecord = function(){
        VaccinationRecordsApiFactory.cancelRecord({recordType: true, recordId: $routeParams.recordId});
        $window.location.href = "/dp/patients/" +  $routeParams.patientId + "/pgr_vaccines_records/edit";
    };

    $scope.onFileSelect = function (file) {
        if (file) {
            $scope.fileDocument = file;
            $scope.fileDocumentName = file.name;
        }
    };

    $scope.onFileDelete = function (docfileid) {
        $scope.fileDocumentName = null;
        $scope.fileDocument = null;
        $scope.documentFileId = docfileid;
    };

    $scope.form = {
        doctype: true,
        recordId: $routeParams.recordId
    };

    $scope.validator = new SubFormValidator('record-region');
    var validateRecordForm = function () {
        MessageService.clearAll();
        $scope.validator.clear();

        var Docfile = $scope.fileDocument;
        if (Docfile == null && $scope.fileDocumentName == null) {
            $scope.validator.add('docfile');
        } else if (Docfile != null && Docfile.size/ 1024 / 1024 > $scope.maxFileSize) {
            $scope.validator.add('docfileSize');
        } else if (Docfile != null && Docfile.type != "image/gif" && Docfile.type != "image/png" && Docfile.type != "image/bmp" && Docfile.type != "image/jpeg" && Docfile.type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && Docfile.type != "application/msword" && Docfile.type != "application/pdf") {
            $scope.validator.add('docfileType');
        }
        return !$scope.validator.hasErrors();
    };

    $scope.updateRecord = function() {
        if (validateRecordForm()) {
            MessageService.registerSuccessEvent();
            $scope.button={};
            $scope.button.disabled=true;
            VaccinationUpdateService.updateRecord($scope.form, $scope.documentFileId, $scope.fileDocument, function (data) {
                $scope.goBack();
            },function (error) {
                angular.forEach(error.globalErrors, function (e) {
                    MessageService.error(e.msg);
                });
                $window.scrollTo(0, 10);
            });
        }
    };

    $scope.goBack = function () {
        window.history.back();
    };
}]);