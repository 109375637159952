ng1App.factory('AddressesApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/addresses';
    return $resource('', {}, {
        getLocations: {
            method: 'GET',
            url: URL_BASE + '/location?searchQuery=:searchQuery&usingHouseNo=:usingHouseNo&count=:count',
            isArray: true
        },
        getAddresses: {
            method: 'GET',
            url: URL_BASE + '/address',
            isArray: true
        },
        getAddressByAobId: {
            method: 'GET',
            url: URL_BASE + '/address-aob',
            isArray: true
        }
    });

}]);