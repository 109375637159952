import {Component, OnInit, ViewChild} from "@angular/core";
import {ErecListComponent} from "../erecList/erec-list.component";

@Component({
    selector: 'erec-history-page',
    templateUrl: './erec-history-page.component.html'
})
export class ErecHistoryPageComponent implements OnInit {
    @ViewChild(ErecListComponent) erecListComponent: ErecListComponent;
    lastFilters: any;

    ngOnInit(): void {

    }

    updateFilters(filters: any) {
        if (!filters) {
            return;
        }

        this.lastFilters = filters;
        this.erecListComponent.updateResults(filters);
    }
}