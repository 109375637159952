ng1App.factory("ImportersApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/importers';

    return $resource('', {}, {
        get: {
            method: 'GET',
            url: URL_BASE
        },
        getImporter: {
            method: 'GET',
            url: URL_BASE + '/:importerUri'
        },
        getImporterStatuses: {
            method: 'GET',
            url: URL_BASE + '/:importerUri/statuses'
        },
        runImporter: {
            method: 'POST',
            url: URL_BASE + '/:importerUri/run'
        },
        stopImporterSchedule: {
            method: 'POST',
            url: URL_BASE + '/:importerUri/stop-schedule'
        },
        scheduleImporter: {
            method: 'POST',
            url: URL_BASE + '/:importerUri/schedule'
        }

    });
}]);