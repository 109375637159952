ng1App.controller('CommonPatientRepresentativeEditCtrl', 
    ['$scope', '$filter', 'PersonRepresentationsApiFactory', '$modalConfirm', 'RepresentationCommons', 'MessageService', '$routeSegment',
     function ($scope, $filter, PersonRepresentationsApiFactory, $modalConfirm, RepresentationCommons, MessageService, $routeSegment) {
    MessageService.clearAll();

    $scope.mode = $scope.representationId ? 'edit' : 'new';

    if ($scope.mode == 'edit') {
        $scope.configuration = PersonRepresentationsApiFactory.getPatientConfiguration({patientId: $scope.patientId}, function () {
            $scope.representation = PersonRepresentationsApiFactory.get({id: $scope.representationId});
        });
    } else {
        $scope.configuration = PersonRepresentationsApiFactory.getPatientConfiguration({patientId: $scope.patientId}, function (data) {
            $scope.representation = {
                authorizationTypes: [],
                person: {
                    fhirId: $scope.patientId
                },
                validFrom: data.dateFrom,
                type: 'PATIENT',
                representativeType: Array.isArray(data.representativeTypes) ? data.representativeTypes[0] : data.representativeTypes
            };
            _.each(data.representativeTypes, function (i) {
                if (i.code == '4') {
                    $scope.representation.representativeType = i;
                }
            });
        });
    }

    $scope.save = function () {
        MessageService.clearAll();
        RepresentationCommons.save($scope.representation, $scope.mode, $scope.successUrl, $scope.representationForm, customValidation);
    };

    var customValidation = function () {
        var isValid = !!$scope.representation.representative && !!$scope.representation.representative.fhirId;
        isValid = isValid && !($scope.configuration.authorizationNeeded.indexOf($scope.representation.representativeType.code) >= 0 &&
            $scope.representation.authorizationTypes.length == 0);

        return isValid;
    };

    $scope.clean = function () {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')}).then(function () {
            $routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
    };
}]);