import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    changeStatusOfChild,
    controlIsDisabled,
    multiCheckboxIsChecked,
    updateCheckboxControl
} from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-ptkl-fetal-shoulder-impingement',
    templateUrl: './e096-ptkl-fetal-shoulder-impingement.component.html',
})
export class E096PtklFetalShoulderImpingementComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    options = [
        {
            formControlName: 'fetalShoulderDystociaWasDiagnosed',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.fetalShoulderDystociaDiagnosed',
            child: {
                formControlName: 'fetalShoulderDystociaWasDiagnosedDetail',
                values: ['Gimus vaisiaus galvai, kito sąrėmio metu negimsta vaisiaus pečiai', 'Stebimas "vėžlio simptomas"']
            }
        },
        {
            formControlName: 'help',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.help',
            child: {
                formControlName: 'helpDetail',
                values: ['Du akušeriai', 'Gydytojas akušeris ginekologas', 'Neonantologų komanda']
            }
        },
        {
            formControlName: 'episiotomy',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.episiotomy',
            child: {
                formControlName: 'episiotomyDetail',
                values: ['Yra epiduralinė analgezija', 'Sukelta vietinė nejautra', 'Atlikta epiziotomija']
            }
        },
        {
            formControlName: 'legs',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.legs',
            input: {
                formControlName: 'legsActionDuration',
                unit: 's'
            }
        },
        {
            formControlName: 'pressure',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.pressure',
            input: {
                formControlName: 'pressureDuration',
                unit: 's'
            },
            child: {
                formControlName: 'pressureDetail',
                values: ['Vienodai arba spiruokliuojant', 'Fiksuotas laikas']
            }
        },
        {
            formControlName: 'enter',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.enter',
            input: {
                formControlName: 'enterDuration',
                unit: 's'
            },
            child: {
                formControlName: 'enterDetail',
                values: [
                    'Vienos rankos pirštai (1) įkišti per makštį nuo vaisiaus užpakalinės dalies, o petys arčiau spaustuko prispaudžiamas prie krūtinės (Rubin II)',
                    'Kita ranka (2) du pirštai iš priekio pasiekia lapkotį arčiau kryžkaulio ir prispaudžiame link nugaros (Woods metodas – abiem rankomis (1 ir 2))',
                    'Ištiesta ranka (2) rankos pirštai (1) pasiekia vaisiaus užpakalinę dalį ir spaudžia petį arčiau kryžkaulio link krūtinės (atvirkštinis Vudso metodas)'
                ]
            }
        },
        {
            formControlName: 'remove',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.remove',
            input: {
                formControlName: 'removeDuration',
                unit: 's'
            },
            child: {
                formControlName: 'removeDetail',
                values: [
                    'Ranka įkišama į makštį ir randama užpakalinė vaisiaus plaštaka, apčiuopiama priešais krūtinę ir ištraukiama suėmus už rankos.',
                    'Ranka įkišama į makštį ir randama užpakalinė vaisiaus ranka, randamas dilbis, vaisiaus ranka sulenkta per alkūnę ir skersai priekinės krūtinės dalies, ištraukiama prausimosi judesiu.',
                ]
            }
        },
        {
            formControlName: 'roll',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.roll',
            input: {
                formControlName: 'rollDuration',
                unit: 's'
            },
            child: {
                formControlName: 'rollDetail',
                values: [
                    'Vaisiaus galva švelniai pakreipiama žemyn ir išlaisvinamas užpakalinis vaisiaus petys',
                    'Pakartotinis vidinis vaisiaus peties sukimasis per makštį (Rubin II. Woods)',
                    'Kartotas užpakalinės rankos išlaisvinimas'
                ]
            }
        },
        {
            formControlName: 'extremeMethod',
            label: 'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.extremeMethod',
            input: {
                formControlName: 'extremeMethodDuration',
                unit: 's'
            }
        }
    ];
    maxDate = new Date();

    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    changeChildStatus = changeStatusOfChild.bind(this) as typeof changeStatusOfChild;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            diagnosesDate: this.fb.control(null),
            actionsTaken: this.fb.group({
                fetalShoulderDystociaWasDiagnosed: this.fb.control(false),
                fetalShoulderDystociaWasDiagnosedDetail: this.fb.control({value: [], disabled: true}),
                help: this.fb.control(false),
                helpDetail: this.fb.control({value: [], disabled: true}),
                episiotomy: this.fb.control(false),
                episiotomyDetail: this.fb.control({value: [], disabled: true}),
                legs: this.fb.control(false),
                legsActionDuration: this.fb.control({value: null, disabled: true}),
                pressure: this.fb.control(false),
                pressureDuration: this.fb.control({value: null, disabled: true}),
                pressureDetail: this.fb.control({value: [], disabled: true}),
                enter: this.fb.control(false),
                enterDuration: this.fb.control({value: null, disabled: true}),
                enterDetail: this.fb.control({value: [], disabled: true}),
                remove: this.fb.control(false),
                removeDuration: this.fb.control({value: null, disabled: true}),
                removeDetail: this.fb.control({value: [], disabled: true}),
                roll: this.fb.control(false),
                rollDuration: this.fb.control({value: null, disabled: true}),
                rollDetail: this.fb.control({value: [], disabled: true}),
                extremeMethod: this.fb.control(false),
                extremeMethodDuration: this.fb.control({value: null, disabled: true}),
            }),
            fixedTimeOfBirth: this.fb.control(null),
            performedProcedure: this.fb.array([], [Validators.maxLength(1)])
        });
    }

    getActionsTaken(formControlName: string): FormControl {
        return this.formGroup.get('actionsTaken').get(formControlName) as FormControl;
    }

    changeStatusOfChild(value: boolean, option: {child?: {formControlName?: string}, input: {formControlName?: string}}): void {
        if (!option.child?.formControlName && !option.input?.formControlName) {
            return;
        }
        const checkboxControlName = option.child?.formControlName;
        const inputControlName = option.input?.formControlName;
        this.changeChildStatus(value, `actionsTaken.${checkboxControlName}`);
        this.changeChildStatus(value, `actionsTaken.${inputControlName}`);
    }
}
