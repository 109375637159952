ng1App.controller('doctor.patient.VaccineRecordsCtrl', 
    ['$scope', '$routeParams', 'VaccinationRecordsApiFactory',
     function ($scope, $routeParams, VaccinationRecordsApiFactory) {

    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope.patientId = $routeParams.patientId;

    $scope.records = {}

    $scope.loadRecords = function() {
        VaccinationRecordsApiFactory.getRecords({patientId: $routeParams.patientId}, function(data) {
            $scope.records = data;
        });
    };
    $scope.loadRecords();
}]);