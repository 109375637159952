import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DocRefConsentFilterModel} from "../../../models/doc-ref-consent-filter.model";
import {ApiV2DocRefConsentTemplate} from "../../../../../api/api-v2-doc-ref-consent-template";
import {DocRefConsentDocStatusTranslationsEnum} from "../../../models/doc-ref-consent-docStatus-translations.enum";

@Component({
    selector: 'doc-ref-consent-template-filter',
    templateUrl: './doc-ref-consent-template-filter.component.html'
})
export class DocRefConsentTemplateFilterComponent implements OnInit {
    protected readonly DocRefConsentDocStatusTranslationsEnum = DocRefConsentDocStatusTranslationsEnum;
    @Input() form: FormGroup;
    @Output() onSearch = new EventEmitter<any>();
    filters: DocRefConsentFilterModel;

    constructor(private formBuilder: FormBuilder, private apiV2DocRefConsentTemplate: ApiV2DocRefConsentTemplate) {
        this.form = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.form.addControl("category", new FormControl(null));
        this.form.addControl("status", new FormControl(null));
        this.apiV2DocRefConsentTemplate.getFilters().subscribe(data => {
            this.filters = data;
        });
    }

    onSearchClick(event) {
        this.onSearch.emit(event);
    }
}