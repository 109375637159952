<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item mouse-pointer" *ngFor="let vaccine of vaccines"
        (click)="showDocument(vaccine.documentId)"
        (keypress)="showDocument(vaccine.documentId)"
        tabindex="0">
        <p class="mb-0">
            <small class="text-secondary">
                {{ vaccine.date }}
            </small>
        </p>
        <span *ngIf="vaccine.vaccineMedicationPackage" class="fw-bold mb-0">
            {{ vaccine.vaccineMedicationPackage?.name }}
        </span>
        <span *ngIf="vaccine.doseSequence" class="vr"></span>
        <span *ngIf="vaccine.doseSequence" class="fw-bold mb-0">
                {{ vaccine.doseSequence }}{{' '}}{{'doc.vis.res' | translate}}
        </span>
        <div class="mb-0">
            <div class="text-truncate">
                {{ vaccine.notes }}
            </div>
        </div>
    </li>
    <li *ngIf="!loading" class="list-group-item p-0">
        <a href="/dp/patients/{{patientId}}/vaccines" class="d-block text-decoration-none hover text-center px-2_5 py-2">
            {{ 'show.more' | translate }}
        </a>
    </li>
</nhr-record-widget>