import { HttpParams } from "@angular/common/http";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { addDays, addWeeks, format, parse } from "date-fns";
import { Pagination } from "../../components/list/pagination-bar/pagination-bar.constants";
import { EntitiesQueryParamsModel } from "../../models/entitiesQueryParams.model";

export const onCheckboxChange = (
    formGroup: FormGroup,
    e: any,
    fGroup: string,
    controlName: string,
    checkboxName?: string
): void => {
    const checkBoxArray: FormArray = controlName
        ? (formGroup.get(`${fGroup}.${controlName}`) as FormArray)
        : (formGroup.get(`${fGroup}`) as FormArray);

    if (checkboxName) {
        const checkBox = checkBoxArray.controls.find((x) => x.value.name === checkboxName) as FormGroup;

        checkBox.controls.checked.patchValue(e.target.checked);
        checkBox.controls.value.patchValue(null);
    } else {
        if (e.target.checked) {
            checkBoxArray.push(new FormControl(e.target.value));
        } else {
            const index = checkBoxArray.controls.findIndex((x) => x.value === e.target.value);
            checkBoxArray.removeAt(index);
        }
    }
};

export const isoDateFormat = "yyyy-MM-dd";
export const zonedDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const isoLocalDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const emptySelectOptionValue = "";

export const addDaysToDate = (date: Date, days: number) => addDays(date, days);
export const addWeeksToDate = (date: Date, weeks: number) => addWeeks(date, weeks);

export function toDateDto(value: Date): string;
export function toDateDto(value: Date | undefined): string | undefined;
export function toDateDto(value?: Date): string | undefined {
    return value ? format(value, isoDateFormat) : undefined;
}

export function fromDateDto(value: string): Date;
export function fromDateDto(value: string | undefined): Date | undefined;
export function fromDateDto(value?: string): Date | undefined {
    return value ? parse(value, isoDateFormat, new Date()) : undefined;
}

export function toDateTimeDto(value: Date): string;
export function toDateTimeDto(value: Date | undefined): string | undefined;
export function toDateTimeDto(value?: Date): string | undefined {
    return value ? format(value, isoLocalDateTimeFormat) : undefined;
}

export function fromZonedDateTimeDto(value: string): Date;
export function fromZonedDateTimeDto(value: string | undefined): Date | undefined;
export function fromZonedDateTimeDto(value?: string): Date | undefined {
    return value ? parse(value, zonedDateTimeFormat, new Date()) : undefined;
}

export function toLocalDateTimeDto(value: Date): string;
export function toLocalDateTimeDto(value: Date | undefined): string | undefined;
export function toLocalDateTimeDto(value?: Date): string | undefined {
    return value ? format(value, isoLocalDateTimeFormat) : undefined;
}

export function fromLocalDateTimeDto(value: string): Date;
export function fromLocalDateTimeDto(value: string | undefined): Date | undefined;
export function fromLocalDateTimeDto(value?: string): Date | undefined {
    return value ? parse(value, isoLocalDateTimeFormat, new Date()) : undefined;
}

export function generateHttpParams(paramsObj?: EntitiesQueryParamsModel): HttpParams | undefined {
    if (!paramsObj) {
        return;
    }
    let params = new HttpParams();
    Object.entries(paramsObj).forEach((entr) => {
        if (entr[1] !== undefined && entr[1] !== null) {
            params = params.append(entr[0], `${entr[1]}`);
        }
    });
    return params;
}

export const DocStatuses = [
    { code: "IN_PROGRESS", name: "doc.in_progress" },
    { code: "COMPLETED", name: "doc.completed" },
    { code: "CANCELED_SIGNED", name: "doc.entered_in_error" },
    { code: "CANCELED_UNSIGNED", name: "doc.entered_in_error_unsigned" },
    { code: "INACTIVE", name: "doc.inactive" },
    { code: "AMENDED_SIGNED", name: "doc.amended" },
    { code: "AMENDED_UNSIGNED", name: "doc.amended_unsigned" },
    { code: "PRELIMINARY", name: "doc.preliminary" },
    { code: "FINAL_UNSIGNED", name: "doc.final" },
    { code: "FINAL_SIGNED", name: "doc.signed" },
];

export const EncounterStatuses = [
    { code: "in progress", name: "Aktyvūs" },
    { code: "finished", name: "Baigti" },
    { code: "cancelled", name: "Atšaukti" },
];

export const PaginationStaticData: Pagination = {
    count: 10,
    page: 1,
};

export function markControlsAsDirty(control: AbstractControl): void {
    control.markAllAsTouched();

    if (control instanceof FormGroup) {
        control.markAsDirty();

        Object.values(control.controls).forEach(innerControl => {
            markControlsAsDirty(innerControl);
        });
    } else if (control instanceof FormArray) {
        control.markAsDirty();

        control.controls.forEach(innerControl => {
            markControlsAsDirty(innerControl);
        });
    } else {
        control.markAsDirty();
        control.updateValueAndValidity();
    }
}
