import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColDef, FirstDataRenderedEvent, GridOptions, GridReadyEvent } from "ag-grid-community";
import { Observable, firstValueFrom } from "rxjs";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { DocumentTagEnum } from "../../../../../shared/models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { AgActionsCell } from "src/app/shared/components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { ApiV2Session } from "../../../../../../api/api-v2-session";
import { AgPaginationBarComponent } from "../../../../../shared/components/ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";
import { AgGridCommonConfig } from "../../../../../shared/configs/ag-grid-common.config";
import { FilteredDataModel } from "../../../../../shared/models/filtered-data.model";

@Component({
    selector: "aspn-f027-list",
    templateUrl: "./aspnF027list.component.html",
})
export class AspnF027ListComponent implements OnInit {
    @ViewChild(AgPaginationBarComponent) paginationBar: AgPaginationBarComponent;

    constructor(
        private dpAspnContextService: DpAspnContextService,
        private apiV2Session: ApiV2Session,
        private params: RouteParams,
        private router: Router
    ) { }

    private organizationId: string;

    ngOnInit(): void {
        this.apiV2Session.getOrganization().subscribe((data) => {
            console.log('organas', data.id);

            this.organizationId = data.id
        });
    }

    public aspnF027ListColDefs: ColDef[] = [
        { field: "orderDate", headerName: "Siuntimo data" },
        {
            field: "orderPractitioner", headerName: "Siuntimą išrašęs specialistas", valueFormatter: (params) => {
                console.log('valll', params.value);
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        { field: "orderOrganization", headerName: "Siuntimą išrašiusi įstaiga", valueFormatter: (params) => { return params.value.name + "\nJAR kodas " + params.value.jarCode } },
        { field: "aspnType", headerName: "Slaugos paslaugų tipas" },
        { field: "nursingNeed", headerName: "Slaugos poreikis" },
        {
            field: "patient", headerName: "Pacientas", valueFormatter: (params) => {
                return params.value.givenName + " " + params.value.familyName + "\n" + params.value.declaredAddress.text + "\n" + params.value.phones
            }
        },
        {
            field: "actionButton",
            headerName: "Kurti Slaugos paskyrimą",
            cellClass: "ag-right-aligned-cell",
            type: "rightAligned",
            cellStyle: { direction: "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: false,
                    viewable: false,
                    copyable: false,
                    createable: true,
                    createButtonTitle: "Kurti slaugos paskyrimą",
                    urlPathCreate: `/dp/patients/${params.data.patient.dik}/aspn/order/${params.data.orderId}/${DocumentTagEnum.spu}`,
                };
            },
        },
    ];

    public gridOptions: GridOptions = {
        ...AgGridCommonConfig,
    }

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        autoHeight: true,
        wrapText: true,
    };

    public aspnF027ListData$!: Observable<any[] | FilteredDataModel>;

    onGridReady(params: GridReadyEvent) {
        firstValueFrom(this.apiV2Session.getOrganization()).then((data) => {
            console.log('organas', data.id);

            this.organizationId = data.id;
            this.aspnF027ListData$ = this.dpAspnContextService.getAspnF027ListByOrganizationId(this.organizationId);
        });
        this.paginationBar.initForAgGrid(params);
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    loadAspnList(query?: any, add?: boolean) {
        this.aspnF027ListData$ = this.dpAspnContextService.getAspnF027ListByOrganizationId(this.organizationId, query);
    }
}

