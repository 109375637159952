ng1App.controller('document.E025ChangeCtrl',
    ['$scope', 'EntitiesApiFactory', 'MessageService', 'TypeaheadSearchHandlers', 'DocumentModificationActions', '$filter',
     function ($scope, EntitiesApiFactory, MessageService, TypeaheadSearchHandlers, DocumentModificationActions, $filter) {
    MessageService.clearAll() ;

    // Side content list
    // jus fill array with something
    $scope.currentDiagnoses = $scope.form.partDiagnosis
    $scope.newRisks = $scope.form.partRiskFactors;
    if($scope.form.partAllergies){
        $scope.currentAllergies = $scope.form.partAllergies.allergies;
    } else {
        $scope.currentAllergies = [];
    }
    $scope._shared.contentList = _.range(19);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'};

    // ============================= NEW LOGIC ============================
    $scope.types = {
        diagnosisTypes: EntitiesApiFactory.getList({classCode: 'condition-disease-type'}),
        orderTypes: EntitiesApiFactory.getList({classCode: 'order-type'}, function(res){
            //XXX
            res.splice(3);
        }),
        sampleCollMethods: EntitiesApiFactory.getList({classCode: 'specimen-collection-method'})
    };


    $scope._shared.customValidator =  function(documentForm, skipRequired){
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        MessageService.clear('diagnosis-region');
        if(!$scope.form.partDiagnosis || $scope.form.partDiagnosis.length==0){
            MessageService.add('error', 'Nenurodyta nė viena diagnozė.', 'diagnosis-region');
            if (res) {
                _.scrollTo('[msg-region="diagnosis-region"]');
            }
            res = false;
        }
        return res;
    }

    $scope.handleDiagnosesChange = function(event) {
        MessageService.clear('diagnosis-region');
        $scope.currentDiagnoses = event;
        $scope.form.partDiagnosis = event;
    };

    $scope.handleAllergiesChange = function(event) {
        $scope.currentAllergies = event;
        $scope.form.partAllergies.allergies = event;
    };

    $scope.handleRiskChange = function(event) {
        $scope.newRisks = event;
        $scope.form.partRiskFactors = event;
    };

    $scope.clear = function () {
      MessageService.clearAll() ;
      $scope.documentCancelReason = {} ;
    };

}]);
