import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    clearFormArray, formatDateForDatePickers,
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl,
    updateFormArray
} from '../../../../../e096/e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e097-at-verification',
    templateUrl: './e097-at-verification.component.html',
})
export class E097AtVerificationComponent implements OnInit {
    @Input() editable: boolean = true;
    @Input() set initData(value: any) {
        if (value === null || value instanceof Date) {
            this.cancel();
            return;
        }
        if (!value) return;
        value = formatDateForDatePickers(value, this.dateFields);
        this.formGroup.patchValue(value);
        this.updatePerformedSpecialist(this.formGroup, value);
    };
    @Output() addVerification: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelVerification: EventEmitter<void> = new EventEmitter<void>();
    formGroup: FormGroup;
    currentTime = new Date();
    dateFields: string[] = ['verificationDate', 'repeatDate'];
    verificationTypeOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Akių dugno raudono reflekso tyrimas (+/–)'
        },
        {
            id: '1',
            code: '1',
            name: 'Klausos tikrinimas OAE (+/–)'
        },
        {
            id: '2',
            code: '2',
            name: 'Dėl ĮKŠY SpO2(%)'
        },
        {
            id: '3',
            code: '3',
            name: 'Dėl paveldimų medžiagų apykaitos ligų (PMAL)'
        }
    ];
    verificationStatusOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Atliktas'
        },
        {
            id: '1',
            code: '1',
            name: 'Neatliktas'
        }
    ];
    plusMinusOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '+'
        },
        {
            id: '1',
            code: '1',
            name: '-'
        }
    ];
    percentOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '< 90%'
        },
        {
            id: '1',
            code: '1',
            name: '90–94% arba skirtumas > 3%'
        },
        {
            id: '2',
            code: '2',
            name: '≥ 95% ir skirtumas ≤ 3%'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    clearFArray = clearFormArray.bind(this) as typeof clearFormArray;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            verificationType: this.fb.control(null, [Validators.required]),
            verificationDate: this.fb.control({value: null, disabled: true}, [Validators.required]),
            verificationStatus: this.fb.control({value: null, disabled: true}, [Validators.required]),
            nonPerformanceReason: this.fb.control({value: null, disabled: true}),
            repeatDate: this.fb.control({value: null, disabled: true}, [Validators.required]),
            performed: this.fb.array([], [Validators.required, Validators.maxLength(1)]),
        });
        this.formGroup.get('verificationType').valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.createResultFormControls();
            });
        this.changeStatusOfControl({
            controlNameForListen: 'verificationType',
            controlNameForUpdate: ['verificationDate', 'verificationStatus', 'nonPerformanceReason', 'repeatDate'],
            disableValue: ''
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        if (!this.editable) {
            this.formGroup.get('verificationType').disable();
        }
    }

    submit(): void {
        const group = this.fb.group(this.formGroup.getRawValue());
        this.updatePerformedSpecialist(group, this.formGroup.getRawValue());
        this.addVerification.emit(group);
        this.clearFArray('performed');
        this.formGroup.reset();
    }

    cancel(): void {
        this.clearFArray('performed');
        this.formGroup.reset();
        this.cancelVerification.emit();
    }

    private updatePerformedSpecialist(group: FormGroup, data: any): void {
        group.removeControl('performed', {emitEvent: false})
        updateFormArray(data, 'performed', group);
    }

    private createResultFormControls(): void {
        switch (this.formGroup.get('verificationType').value) {
            case 'Akių dugno raudono reflekso tyrimas (+/–)':
            case 'Klausos tikrinimas OAE (+/–)':
                this.addRemoveResultsControls(['resultLeft', 'resultRight'], ['resultRightHand', 'resultRightLeg', 'result']);
                return;
            case 'Dėl ĮKŠY SpO2(%)':
                this.addRemoveResultsControls(['resultRightHand', 'resultRightLeg'], ['resultLeft', 'resultRight', 'result']);
                return;
            case 'Dėl paveldimų medžiagų apykaitos ligų (PMAL)':
                this.addRemoveResultsControls(['result'], ['resultRightHand', 'resultRightLeg', 'resultRight', 'resultLeft']);
                return;
            default:
                this.addRemoveResultsControls([], ['resultRightHand', 'resultRightLeg', 'resultRight', 'resultLeft', 'result']);
                return;
        }
    }

    private addRemoveResultsControls(addControls: string[], removeControls: string[]): void {
        addControls.forEach(key => {
            this.formGroup.addControl(key, this.fb.control(null, [Validators.required]), {emitEvent: false});
        });
        removeControls.forEach(key => {
            this.formGroup.removeControl(key, {emitEvent: false});
        });
    }
}
