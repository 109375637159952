import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { debounceTime, forkJoin, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { clearFormArray, formatDateForDatePickers, updateFormArray } from '../../../e096/e096-utils/utils';
import {
    E097GdGeneralInspectionComponent
} from './e097-gd-sub-forms/e097-gd-general-inspection/e097-gd-general-inspection.component';
import { E097GdInspectionsComponent } from './e097-gd-sub-forms/e097-gd-inspections/e097-gd-inspections.component';
import { E097GdResearchComponent } from './e097-gd-sub-forms/e097-gd-research/e097-gd-research.component';
import { E097GdAppointmentsComponent } from './e097-gd-sub-forms/e097-gd-appointments/e097-gd-appointments.component';
import { E097GdDiagnosisComponent } from './e097-gd-sub-forms/e097-gd-diagnosis/e097-gd-diagnosis.component';


@Component({
    selector: 'app-e097-gd-form',
    templateUrl: './e097-gd-form.component.html',
})
export class E097GdFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: {[key: string]: any}) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);

        this.patchFormWithInitialData(this._initialFormValue);
    }

    @ViewChild(E097GdGeneralInspectionComponent) generalInspectionComponent: E097GdGeneralInspectionComponent;
    @ViewChild(E097GdInspectionsComponent) inspectionsComponent: E097GdInspectionsComponent;
    @ViewChild(E097GdResearchComponent) researchComponent: E097GdResearchComponent;
    @ViewChild(E097GdAppointmentsComponent) appointmentsComponent: E097GdAppointmentsComponent;
    @ViewChild(E097GdDiagnosisComponent) diagnosisComponent: E097GdDiagnosisComponent;

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: {[key: string]: any};
    private dateFields: string[] = ['performanceDate'];

    private updateFormArray = updateFormArray.bind(this) as typeof updateFormArray;
    private clearfArray = clearFormArray.bind(this) as typeof clearFormArray;

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
    ) {
        forkJoin({
            inspectResults: this.apiV2Entities.getEntitiesList('ngn-inspect-results'),
            bloodGroup: this.apiV2Entities.getEntitiesList('blood-group'),
            newbornDiet: this.apiV2Entities.getEntitiesList('newborn-diet'),
            newbornRegimen: this.apiV2Entities.getEntitiesList('newborn-regimen'),
            medicinalPreparationsAndReimbursableMPPs: this.apiV2Entities.getEntitiesList('medicinal-preparations-and-reimbursable-MPPs'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            generalInspection: this.generalInspectionComponent?.formGroup,
            inspections: this.inspectionsComponent?.formGroup,
            research: this.researchComponent?.formGroup,
            appointments: this.appointmentsComponent?.formGroup,
            diagnosis: this.diagnosisComponent?.formGroup,
        });
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }

    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.patchFormWithInitialData(this._initialFormValue);
    }

    private patchFormWithInitialData(_initialFormValue: {[key: string]: any}) {
        this.formGroup.valueChanges.pipe(
            debounceTime(10),
            take(1)
        ).subscribe(() => {
            this.updateParticipantsAndDiagnosisList({...this._initialFormValue});
            this.formGroup.patchValue(_initialFormValue);
            if (!this.editable) {
                this.formGroup.disable();
            }
        });
        this.formGroup.patchValue(_initialFormValue);
    }

    private updateParticipantsAndDiagnosisList(data: { [key: string]: any }) {
        this.clearfArray('generalInspection.inspectionWasCarriedOut');
        this.clearfArray('appointments.antibiotics');
        this.clearfArray('diagnosis.diagnosis');
        this.updateFormArray(data, 'generalInspection.inspectionWasCarriedOut');
        this.updateFormArray(data, 'appointments.antibiotics');
        this.updateFormArray(data, 'diagnosis.diagnosis');
    }
}
