<div class="modal-header">
    <h4 class="modal-title pull-left">{{'doc.all.form.registeredAllergies'| translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body p-0">
    <ol *ngIf="allergies?.length" class="slats hover">
        <li *ngFor="let allergy of allergies; let i = index">
            <div class="name mt-2">
                <strong>
                    {{allergy.code.name}}
                    <span class="vr"></span>
                    {{allergy.substance1.name}}
                    <span class="vr"></span>
                    {{allergy.manifestation.code}} {{allergy.manifestation.name}}
                </strong>
            </div>
            <div class="details my-1">
                <smap class="text-secondary me-1">{{ allergy.onsetDate | date: 'YYYY-MM-dd'}} - {{ allergy.lastOccurrence | date: 'YYYY-MM-dd' }}</smap>
                <span class="vr"></span>
                <small class="text-secondary" *ngIf="allergy.type?.name">{{'doc.dia.dia.typ' | translate}}: <strong class="text-body">{{ allergy.type?.name | capitalize }}</strong></small>
                <span class="vr"></span>
                <small class="text-secondary" *ngIf="allergy.clinicalStatus?.name">{{'doc.dia.sts' | translate}}: <strong class="text-body">{{ allergy.clinicalStatus?.name | capitalize }}</strong></small>
                <span class="vr"></span>
                <small class="text-secondary" *ngIf="allergy.verificationStatus?.name">{{'doc.dia.con' | translate}}: <strong class="text-body">{{ allergy.verificationStatus?.name | capitalize }}</strong></small>
                <span class="vr"></span>
                <small class="text-secondary" *ngIf="allergy.severity?.name">{{'doc.dia.sev' | translate}}: <strong class="text-body">{{ allergy.severity?.name | capitalize }}</strong></small>
                <span class="vr"></span>
                <small class="text-secondary" *ngIf="allergy.criticality?.name">{{'doc.all.crit' | translate}}: <strong class="text-body">{{ allergy.criticality?.name | capitalize }}</strong></small>
                <span class="vr"></span>
                <small class="text-secondary" *ngIf="allergy.category?.name">{{'doc.dia.cat' | translate}}: <strong class="text-body">{{ allergy.category?.name | capitalize }}</strong></small>
            </div>
            <small>{{ allergy.notes ?? '-' }}</small>
        </li>
    </ol>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="close()">{{'exit' | translate}}</button>
</div>