import { MenstrualCycles } from "../components/general-pregnancy-termination-info/general-pregnancy-termination-info.constants";
import { PregnancyTerminationInfoModel } from "../models/pregnancyTerminationInfo.model";

export const allergies = [
    {
        date: new Date("2023-02-01 15:30"),
        name: "Anaphylaxis (39579001)",
    },
    {
        date: new Date("2023-03-01 16:30"),
        name: "Anaphylaxis (39579001)",
    },
    {
        date: new Date("2023-04-01 17:30"),
        name: "Anaphylaxis (39579001)",
    },
];

export const vaccinations = [
    {
        date: new Date("2023-05-01 18:30"),
        name: "Kokliušas (A37)",
    },
    {
        date: new Date("2023-06-01 19:30"),
        name: "Gripas, virusas nenustatytas (J11)",
    },
];

export const generalPregnancyInfoMock: PregnancyTerminationInfoModel = {
    pregnancyInfo: {
        firstVisitDate: new Date("2023-12-05T12:51:16.000Z"),
        pregnancy: 6,
        birth: 4,
        foilUsing: {
            until: false,
            onGoing: true,
            over: true,
        },
        menstrualCycle: MenstrualCycles.Regular,
        lastMenstruationPeriod: new Date("2023-07-05T12:51:16.000Z"),
        menstruationDuration: 3,
        cycleDuration: 2,
        confirmedDeadline: new Date("2023-12-05T12:51:16.000Z"),
        ultrasoundDate: new Date("2023-12-05T12:51:16.000Z"),
    },
    anamnesis: {
        familyAnamnesis: [
            {
                name: "Cukrinis diabetas",
                checked: true,
                value: "trtrt",
            },
            {
                name: "Sklaidos trūkumai",
                checked: true,
                value: "ggg",
            },
            {
                name: "Z63.0",
                checked: false,
                value: null,
            },
            {
                name: "Kita",
                checked: false,
                value: null,
            },
        ],
        womanDiseases: ["Cukrinis diabetas", "Urologinė patologija"],
        womanAddictions: [
            {
                name: "Rūkymas",
                beforePregnancy: true,
                duringPregnancy: false,
            },
            {
                name: "Pasyvus rūkymas",
                beforePregnancy: true,
                duringPregnancy: true,
            },
            {
                name: "Alkoholio vartojimas",
                beforePregnancy: false,
                duringPregnancy: true,
            },
            {
                name: "Narkotikų vartojimas",
                beforePregnancy: true,
                duringPregnancy: false,
            },
        ],
        biologicalFather: {
            age: 34,
            addictions: ["Piknaudžiauja alkoholiu", "Rūko"],
        },
        womanAllergies: "xcvxcvx",
        womanKmi: {
            weight: 78,
            height: 180,
        },
    },
    previousPregnancies: [
        {
    //     childbirthMode: null,
    //     auxiliaryProcedures: [],
        informationFromPatientEHR: {
            pregnancyOutcome: [{
                code: "276507005",
                name: "Nesivystantis nėštumas / vaisiaus žūtis"
            }],
            pregnancyWeek: 14
        },
        informationProvidedByPatient: {},
    },
    {
        informationFromPatientEHR: {},
        informationProvidedByPatient: {
            pregnancyOutcome: [{
                code: "17369002",
                name: "Savaiminis persileidimas"
            }],
            pregnancyWeek: 36
        },
    },
    {
        informationFromPatientEHR: {},
        informationProvidedByPatient: {
            pregnancyOutcome: [{
                code: "237364002",
                name: "Negyvagimis"
            }],
            pregnancyWeek: 23
        },
    },
    {
        informationFromPatientEHR: {},
        informationProvidedByPatient: {
            pregnancyOutcome: [
                {
                    code: "3",
                    name: "Mirė 0-6 parų"
                },
            ],
            pregnancyWeek: 27
        },
    },
    ],
    id: "1",
};
