ng1App.factory('OrderSelectModalHelper', 
    ['$rootScope', '$modal', '$location',
     function ($rootScope, $modal, $location) {
    var scope = $rootScope.$new();

    var reduce = function (memo, val) {
        return memo || val;
    };

    var isValidSelection = function (orderType) {
        return (orderType === '4' && scope.selected.orders && _.reduce(_.values(scope.selected.orders), reduce, false))
                || (orderType !== '4' && scope.selected.order && scope.selected.order.item);
    };

    scope.selected = {orders:[]};
    scope.orderListOps = {};
    scope.filterPaths = [
        'documentTitle',
        'item.patData.sampleCollectionMethod.name',
        'item.date|datetime',
        'item.date|datetime',
        'documentDate|datetime',
        'item.practitioner.givenName',
        'item.practitioner.familyName',
        'organization.name'
    ];
    scope.ordersLimit = 7;

    scope.createOrderOrAnswer = function () {
        var documentType = scope.documentType;
        var orderType = scope.orderType;

        scope.createDialogErr = {};

        if (isValidSelection(orderType)) {
            var queryParams: any = {
                encounterId: scope.encounterId ? scope.encounterId : scope.selected.order.encounterId,
                orderId: scope.selected.order.item.id,
                parentDocumentId: scope.selected.order.compositionId
            };

console.log(scope.selected.orders,documentType,orderType );
            if (documentType === 'e027' && orderType == '4') {
                var orders = [];
                angular.forEach(_.keys(scope.selected.orders), function (val) {
                    if (scope.selected.orders[val]) {
                        var start = val.indexOf('/') + 1;
                        var end = val.indexOf('/', start);
                        orders.push(val.substring(start, end));
                    }
                });
                queryParams.orderId = orders;
                queryParams.orderType = 4;
            }

            $location.path('/dp/patients/' + scope.patientId + '/documents/new/' + documentType).search(queryParams);
            return true;
        } else {
            scope.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas siuntimas.';
            return false;
        }

    };

    scope.loadMoreOrders = function () {
        scope.ordersLimit += 7;
    };

    scope.isMoreOrders = function () {
        return scope.ordersLimit < scope.orderListOps.filtered;
    };

    return {
        getModal: function (title, orderLoadMoreTitle, documentType, orderType, encounterId, patientId, orders, selectedOrder) {
            scope.createDialogErr = {};
            scope.documentType = documentType;
            scope.orderList = orders;
            scope.orderType = orderType;
            scope.encounterId = encounterId;
            scope.patientId = patientId;
            scope.orderLoadMoreTitle = orderLoadMoreTitle;
            scope.selected = {orders:[]};
            scope.selected.order = selectedOrder;

            return $modal({
                scope: scope,
                title: title,
                contentTemplate: '/templates/portal/doctor/patient/records/modals/order_select_modal.html'
            });
        }};
}]);


