angular.module('erx.utils').service('$erxShortcutTabsDosageM', ["$modal", "$rootScope", "$q", "gettextCatalog", function ($modal, $rootScope, $q, gettextCatalog) {
  var scope = $rootScope.$new();
  var deferred;
  scope.data = {};
  scope.selectedShortcuts = {};
  scope.shortcutData = {};

  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-shortcut-customization/erxShortcutTabsDosageM.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (opts, onSave, afterHide) {
    scope.title = opts.title ? opts.title : gettextCatalog.getString('Suflerių nustatymai');
    scope.accountSettings = opts.selectedData;
    scope.selectedShortcuts.frequencyDosageData = scope.accountSettings['shortcuts_dosage_instruction'].filter(d => d.group === "frequencyDosage");
    scope.selectedShortcuts.dayDosageData = scope.accountSettings['shortcuts_dosage_instruction'].filter(d => d.group === "dayDosage");
    scope.selectedShortcuts.disposableDosageData = scope.accountSettings['shortcuts_dosage_instruction'].filter(d => d.group === "disposableDosage");
    scope.selectedShortcuts.frequencyDosageData.forEach(shortcut => scope.data[shortcut.id] = shortcut);
    scope.selectedShortcuts.dayDosageData.forEach(shortcut => scope.data[shortcut.id] = shortcut);
    scope.selectedShortcuts.disposableDosageData.forEach(shortcut => scope.data[shortcut.id] = shortcut);

    scope.shortcutData.frequencyDosageData = opts.data.frequencyDosage;
    scope.shortcutData.dayDosageData = opts.data.dayDosage;
    scope.shortcutData.disposableDosageData = opts.data.disposableDosage;
    scope.onSave = onSave;
    scope.afterHide = afterHide;

    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };

  scope.saveShortcuts = function() {
    let dataToSave = Object.assign(scope.accountSettings, {"shortcuts_dosage_instruction": Object.values(scope.data).map((d: any) => {
        d.title = d.quantity !== undefined ? d.quantity + " " + d.field.displayValue : d.field.displayValue;
        return d;
      })
    });
    scope.onSave(dataToSave).$promise.then(function (res) {
      scope.afterHide();
    });
  }

  return confirm;
}]);