ng1App.controller('doctor.patient.VaccineRecordsEditCtrl', 
    ['$scope', '$routeParams', '$window', 'apiUrl', 'VaccinationRecordsApiFactory',
     function ($scope, $routeParams, $window, apiUrl, VaccinationRecordsApiFactory) {
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope.patientId = $routeParams.patientId;

    $scope.records = VaccinationRecordsApiFactory.getRecords({patientId: $routeParams.patientId});

    $scope.downloadDocFile = function(docType, docImageId) {
        $window.open(apiUrl + "/vaccination/records/documentFile/?docType=" + docType + "&docImageId=" + docImageId);
    };
}]);