import {AG_GRID_LOCALE_LT} from "../translate/agGridLocalization/locale.lt";
import {GridOptions} from "ag-grid-enterprise";
import {
    AgOverlayLoadingComponent
} from "../components/ag-grid/components/ag-overlay-loading/ag-overlay-loading.component";

export const AgGridCommonConfig: GridOptions = {
    animateRows: false,
    localeText: AG_GRID_LOCALE_LT,
    suppressContextMenu: true,
    loadingOverlayComponent: AgOverlayLoadingComponent
}