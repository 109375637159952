import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../../api/api-v2-doc-pregnancy-dashboard";
import { RouteParams } from "../../../../../ng1.routeParams";
import { RiskFactor, RisksView } from "../../../models/riskFactors.model";
import {
    NaturalWayAndCaesareanOperationMediumRisks,
    PrematureBirthRiskLowRisks,
    getRiskLevel,
} from "../risk-factors.constants";

@Component({
    selector: "app-risk-factors-view",
    templateUrl: "./risk-factors-view.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskFactorsViewComponent implements OnInit, OnDestroy {
    @Input() closed?: boolean;

    public readonly prematureBirthRiskLowRisks = Object.values(PrematureBirthRiskLowRisks);

    public unfavorableObstetricHistory = new BehaviorSubject<RiskFactor[]>([]);
    public pregnancyPathology = new BehaviorSubject<RiskFactor[]>([]);
    public unfavorablePregnantCondition = new BehaviorSubject<RiskFactor[]>([]);
    public fetalPathology = new BehaviorSubject<RiskFactor[]>([]);
    public pregnantDiseases = new BehaviorSubject<RiskFactor[]>([]);

    public prematureBirthRisk: RisksView = {
        risks: [],
        riskLevel: "",
        riskLevelStyle: "",
    };

    public birthRisk: RisksView = {
        risks: [],
        riskLevel: "",
        riskLevelStyle: "",
    };

    public postpartumHemorrhageRisk: RisksView = {
        risks: [],
        riskLevel: "",
        riskLevelStyle: "",
    };

    public thromboembolismPrevention: RisksView = {
        risks: [],
        riskLevel: "",
        riskLevelStyle: "",
    };

    public thromboembolismPreventionAfterChildbirth: RisksView = {
        risks: [],
        riskLevel: "",
        riskLevelStyle: "",
    };

    public getRiskLevel = getRiskLevel;

    public childbirthType: string;
    private patientId: string;
    private subscription: Subscription = new Subscription();

    constructor(private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard, private params: RouteParams) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.subscription.add(this.getRiskFactors());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        localStorage.clear();
    }

    public getRiskLevelStyle(riskTypeName: string, low: string[], medium: string[], high: string[]): string {
        switch (riskTypeName) {
            case "prematureBirthRisk":
                if (high.length > 0) {
                    return "bg-danger";
                } else if (low.length === this.prematureBirthRiskLowRisks.length) {
                    return "bg-success";
                } else {
                    return "";
                }

            case "birthRisk":
                if (high.length > 0 || medium.length > 2) {
                    return "bg-danger";
                } else if (medium.length === 1 || medium.length === 2) {
                    return "bg-warning";
                } else if (low.length === 4) {
                    return "bg-warning";
                }

            case "postpartumHemorrhageRisk":
                if (high.length > 0 || medium.length > 1) {
                    return "bg-danger";
                } else if (medium.length > 0) {
                    return "bg-warning";
                }

            case "thromboembolismPrevention":
                if (high.length > 0) {
                    return "bg-danger";
                } else if (low.length > 2 || medium.length > 0) {
                    return "bg-warning";
                } else {
                    return "bg-success";
                }

            case "thromboembolismPreventionAfterChildbirth":
                if (
                    high.length > 0 ||
                    medium.length >= 4 ||
                    (medium.length >= 3 &&
                        medium?.includes(NaturalWayAndCaesareanOperationMediumRisks.AfterCaesareanOperation))
                ) {
                    return "bg-danger";
                } else if (
                    (medium.length >= 2 &&
                        medium?.includes(NaturalWayAndCaesareanOperationMediumRisks.AfterCaesareanOperation)) ||
                    medium.length >= 3
                ) {
                    return "bg-warning";
                } else {
                    return "bg-success";
                }

            default:
                return "bg-success";
        }
    }

    private getRiskFactors(): Subscription {
        return this._apiV2PregnancyDashboard.getRiskFactors(this.patientId).subscribe((x) => {
            const savedData = localStorage.getItem("riskFactors");
            let data = null;
            if (savedData) {
                data = JSON.parse(savedData);
            } else {
                data = x;
            }

            this.childbirthType = data.childbirthType;

            this.unfavorableObstetricHistory.next(
                data.highRiskFactors.unfavorableObstetricHistory.filter(
                    (unfavorableObstetricHistory) => unfavorableObstetricHistory.checked
                )
            );

            this.pregnancyPathology.next(
                data.highRiskFactors.pregnancyPathology.filter((pregnancyPathology) => pregnancyPathology.checked)
            );

            this.unfavorablePregnantCondition.next(
                data.highRiskFactors.unfavorablePregnantCondition.filter(
                    (unfavorablePregnantCondition) => unfavorablePregnantCondition.checked
                )
            );

            this.fetalPathology.next(
                data.highRiskFactors.fetalPathology.filter((fetalPathology) => fetalPathology.checked)
            );

            this.pregnantDiseases.next(
                data.highRiskFactors.pregnantDiseases.filter((pregnantDisease) => pregnantDisease.checked)
            );

            this.pushPrematureBirthRiskRisks(data.prematureBirthRisk.low, [], data.prematureBirthRisk.high);

            this.pushBirthRisks(data.birthRisk.low, data.birthRisk.medium, data.birthRisk.high);

            this.pushPostpartumHemorrhageRisks(
                data.postpartumHemorrhageRisk.low,
                data.postpartumHemorrhageRisk.medium,
                data.postpartumHemorrhageRisk.high
            );

            this.pushThromboembolismPreventionRisks(
                data.thromboembolismPrevention.low,
                data.thromboembolismPrevention.medium,
                data.thromboembolismPrevention.high
            );

            this.pushThromboembolismPreventionAfterChildbirthRisks(
                data.thromboembolismPreventionAfterChildbirth.low,
                data.thromboembolismPreventionAfterChildbirth.medium,
                data.thromboembolismPreventionAfterChildbirth.high
            );
        });
    }

    private pushPrematureBirthRiskRisks(low: string[], medium: string[], high: string[]): void {
        const riskLevelStyle = this.getRiskLevelStyle("prematureBirthRisk", low, medium, high);

        this.prematureBirthRisk.riskLevel = this.getRiskLevel(riskLevelStyle);
        this.prematureBirthRisk.riskLevelStyle = riskLevelStyle;

        if (low.length) {
            this.prematureBirthRisk.risks.push({
                label: "Maža rizika, - kai pažymėti visi veiksniai iš žemiau pateikto sąrašo:",
                value: low,
            });
        }

        if (high.length) {
            this.prematureBirthRisk.risks.push({
                label: "Didelė rizika, - kai pažymėtas bent vienas veiksnys iš žemiau pateikto sąrašo:",
                value: high,
            });
        }
    }

    private pushBirthRisks(low: string[], medium: string[], high: string[]): void {
        const riskLevelStyle = this.getRiskLevelStyle("birthRisk", low, medium, high);

        this.birthRisk.riskLevel = this.getRiskLevel(riskLevelStyle);
        this.birthRisk.riskLevelStyle = riskLevelStyle;

        if (low.length) {
            this.birthRisk.risks.push({
                label: "Maža rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
                value: low,
            });
        }

        if (medium.length) {
            this.birthRisk.risks.push({
                label: "Vidutinė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
                value: medium,
            });
        }

        if (high.length) {
            this.birthRisk.risks.push({
                label: "Didelė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
                value: high,
            });
        }
    }

    private pushPostpartumHemorrhageRisks(low: string[], medium: string[], high: string[]): void {
        const riskLevelStyle = this.getRiskLevelStyle("postpartumHemorrhageRisk", low, medium, high);

        this.postpartumHemorrhageRisk.riskLevel = this.getRiskLevel(riskLevelStyle);
        this.postpartumHemorrhageRisk.riskLevelStyle = riskLevelStyle;

        if (low.length) {
            this.postpartumHemorrhageRisk.risks.push({
                label: "Maža rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
                value: low,
            });
        }

        if (medium.length) {
            this.postpartumHemorrhageRisk.risks.push({
                label: "Vidutinė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
                value: medium,
            });
        }

        if (high.length) {
            this.postpartumHemorrhageRisk.risks.push({
                label: "Didelė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
                value: high,
            });
        }
    }

    private pushThromboembolismPreventionRisks(low: string[], medium: string[], high: string[]): void {
        const riskLevelStyle = this.getRiskLevelStyle("thromboembolismPrevention", low, medium, high);

        this.thromboembolismPrevention.riskLevel = this.getRiskLevel(riskLevelStyle);
        this.thromboembolismPrevention.riskLevelStyle = riskLevelStyle;

        if (low.length) {
            this.thromboembolismPrevention.risks.push({
                label: "Maža rizika, - kai nustatyti mažiau nei 3 rizikos veiksniai iš žemiau pateikto sąrašo:",
                value: low,
            });
        }

        if (medium.length) {
            this.thromboembolismPrevention.risks.push({
                label: "Vidutinė rizika, - kai nustatyti 3 ir daugiau mažos rizikos veiksniai arba bent vienas iš žemiau:",
                value: medium,
            });
        }

        if (high.length) {
            this.thromboembolismPrevention.risks.push({
                label: "Didelė rizika, - anksčiau buvęs vienas GVT epizodas, kai yra:",
                value: high,
            });
        }
    }

    private pushThromboembolismPreventionAfterChildbirthRisks(low: string[], medium: string[], high: string[]): void {
        const riskLevelStyle = this.getRiskLevelStyle("thromboembolismPreventionAfterChildbirth", low, medium, high);

        this.thromboembolismPreventionAfterChildbirth.riskLevel = this.getRiskLevel(riskLevelStyle);
        this.thromboembolismPreventionAfterChildbirth.riskLevelStyle = riskLevelStyle;

        if (low.length) {
            this.thromboembolismPreventionAfterChildbirth.risks.push({
                label: "Maža rizika",
                value: low,
            });
        }

        if (medium.length) {
            this.thromboembolismPreventionAfterChildbirth.risks.push({
                label: "Vidutinė rizika, - kai nustatyti 3 ir daugiau mažos rizikos veiksniai arba bent vienas iš žemiau:",
                value: medium,
            });
        }

        if (high.length) {
            this.thromboembolismPreventionAfterChildbirth.risks.push({
                label: "Didelė rizika, - kai pažymėti 4 ir daugiau Vidutinės rizikos veiksniai arba vienas ar daugiau Didelės rizikos veiksniai",
                value: high,
            });
        }
    }
}
