// Direktyva, skirta tik recepto duomenų koregavimui
angular.module('erx.prs').directive('erxEditPrescriptionDoc', ["$rootScope, erxApiConfig", function ($rootScope, erxApiConfig) {

  var viewPrescriptionFormCtrl = function ($scope, $filter, $location, $erxModalConfirm,
                                           MessageService, $erxHelpers, ErxPrescriptionsApiFactory, erxPrescriptionsConfig, gettextCatalog) {
    $scope.medicationPrescription = {};
    if (angular.isDefined($scope.options)) {
      angular.extend($scope.options, erxPrescriptionsConfig.options);
    } else {
      $scope.options = erxPrescriptionsConfig.options;
    }
    var integrationObject = $scope.options.getIntegrationData();

    // Formos duomenų užkrovimas iš REST API bei paruošimas atvaizdavimui ir koregavimui
    var loadForm = function () {
      $scope.medicationPrescription.additional = {};


      if ($scope.options.prescriptionId) {
        ErxPrescriptionsApiFactory.getDocument(
          { id: $scope.options.prescriptionId, includeRelated: true },
          function (res) {
            $scope.backup = angular.copy(res);

            integrationObject.getCompositionData($scope.options.getCompositionId()).then(function (res) {
              var compositionData = res;
              $scope.backup.practitionerQualification = {
                code: '',
                displayValue: ''
              };
              $scope.backup.practitionerQualification.code = compositionData.activeQualification.code;
              if (compositionData.activeQualification.name) {
                $scope.backup.practitionerQualification.displayValue = compositionData.activeQualification.name;
              }

            });

            $scope.patientId = res.prescriptionData.patientRef;
            $scope.doctorId = res.prescriptionData.prescriberRef;
            $scope.patient = res.patient;
            $scope.practitioner = res.practitioner;
            $scope.organization = res.organization;
            $scope.practitionerQualification = res.practitionerQualification;

              $scope.medicationPrescription.prescriptionType = res.prescriptionData.prescriptionType.code == 'vv' ? 'va' : res.prescriptionData.prescriptionType.code;
            $scope.medicationPrescription.dispenseData = res.prescriptionData.dispenseData;
            $scope.medicationPrescription.dosageInstructionData = res.prescriptionData.dosageInstructionData;
            $scope.medicationPrescription.metadata = res.metadata;
            $scope.medicationPrescription.medicationData = res.prescriptionData.medicationData;
            $scope.medicationPrescription.reason = res.prescriptionData.reason;
            $scope.medicationPrescription.prescriptionStatus = res.prescriptionData.prescriptionStatus;
            $scope.medicationPrescription.status = res.prescriptionData.status;
            $scope.medicationPrescription.dateWritten = res.prescriptionData.dateWritten;

            if ($scope.medicationPrescription.dispenseData &&
              $scope.medicationPrescription.dispenseData.compensation &&
              $scope.medicationPrescription.dispenseData.compensation.code) {
              try {
                $scope.medicationPrescription.dispenseData.compensation.code =
                  parseInt($scope.medicationPrescription.dispenseData.compensation.code);
              } catch (e) {

              }
            }

            if (res.prescriptionData.dispenseData.aagaSgasNumber) {
              $scope.medicationPrescription.additional.aagaSgasNumber = res.prescriptionData.dispenseData.aagaSgasNumber;
            }

            $scope.validToChanged();

            if (res.prescriptionData.medicationRef) {
              $scope.medicationPrescription.medicationRef = res.prescriptionData.medicationRef;
            }

            if ($scope.isMPPForm()) {
              $scope.medicationPrescription.lensFlag = angular.isObject(res.prescriptionData.dispenseData.lenses);
            }

            $scope.medicationPrescription.drug = res.prescriptionData.medicationData;
            if ($scope.isNamedMedicineForm()) {
              if (angular.isObject(res.prescriptionData.medicationData.pharmForm)) {
                $scope.medicationPrescription.drug.pharmFormText = res.prescriptionData.medicationData.pharmForm.displayValue;
              }
            }
            if ($scope.isNamedMedicineForm()) {
              if (angular.isObject(res.prescriptionData.medicationData.pharmForm)) {
                $scope.medicationPrescription.drug.pharmFormText = res.prescriptionData.medicationData.pharmForm.displayValue;
              }
            }
            $scope.prescriptionFound = true;
          },
          function (error) {
            $scope.prescriptionFound = false;
          }
        );
      }

      var signedPdfUrl = "";
      $scope.getSignedPdf = function () {
        if (!signedPdfUrl) {
          signedPdfUrl = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Document/' + $scope.medicationPrescription.metadata.docId + '/signedPdf';
        }
        return signedPdfUrl;
      }
    }

    $scope.intakeTimes = [
      { code: 'beforeMeal', displayValue: 'Prieš valgį' },
      { code: 'duringMeal', displayValue: 'Valgio metu' },
      { code: 'afterMeal', displayValue: 'Po valgio' },
      { code: 'independMeal', displayValue: 'Nepriklausomai nuo valgymo' },
      { code: 'beforeSleep', displayValue: 'Prieš miegą' }
    ];

    $scope.timesOfDay = [
      { code: 'morning', displayValue: 'Ryte' },
      { code: 'noon', displayValue: 'Per pietus' },
      { code: 'evening', displayValue: 'Vakare' },
      { code: 'asNeeded', displayValue: 'Pagal poreikį' }];


    integrationObject.getCompensationTypeCodes().$promise.then(function (res) {
      angular.forEach(res, function (value) {
        try {
          value.code = parseInt(value.code);
        } catch (e) {

        }
      })
      $scope.compensationTypeCodes = res;
    });

    $scope.prescriptionTags = integrationObject.getPrescriptionTags();
    $scope.frequencyMeasurements = integrationObject.getUnitsOfTime();
    $scope.urgencyTags = integrationObject.getUrgencyTags();


    // Ar skirtas vaistas
    $scope.isMedicineForm = function () {
      return ($scope.medicationPrescription.prescriptionType === 'va')
        && !$scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    };

    // Ar skirtas vardinis vaistas
    $scope.isNamedMedicineForm = function () {
      return ($scope.medicationPrescription.prescriptionType === 'va')
        && $scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    };

    // Ar skirta MPP
    $scope.isMPPForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'mpp';
    };

    // Ar skirtas ekstemporalus vaistas
    $scope.isExtemporalForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'ev';
    };

    // Ar skirtas kitas produktas
    $scope.isOtherForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'kp';
    };

    $scope.getPrescriptionTitle = function () {
      if ($scope.isMedicineForm())
        return gettextCatalog.getString('Vaisto skyrimas');
      if ($scope.isNamedMedicineForm())
        return gettextCatalog.getString('Vardinio vaisto skyrimas');
      if ($scope.isMPPForm())
        return gettextCatalog.getString('MPP skyrimas');
      if ($scope.isExtemporalForm())
        return gettextCatalog.getString('Ekstemporalaus vaisto skyrimas');
      if ($scope.isOtherForm())
        return gettextCatalog.getString('Kito produkto skyrimas');
    }

    $scope.getDisplayValueForBoolean = function (val) {
      switch (val) {
        case undefined:
          return 'Nenurodyta';
        case false:
          return 'Ne';
        case true:
          return 'Taip';
      }
    };

    $scope.getPrescriptionTagDisplayValue = function () {
      var result = $filter('filter')($scope.prescriptionTags,
        { code: $scope.medicationPrescription.dispenseData.prescriptionTag });
      if (result.length > 0) {
        return result[0].displayValue;
      }
    };

    // Vartojimo dažnumo žymų pavertimas į tinkamą atvaizdavimui formą
    $scope.getDosageFrequency = function () {
      var ret = '';
      for (var i = 0; i < $scope.timesOfDay.length; i++) {
        if ($scope.medicationPrescription.dosageInstructionData[$scope.timesOfDay[i].code]) {
          if (ret !== '')
            ret += ', ';
          ret += gettextCatalog.getString($scope.timesOfDay[i].displayValue);
        }
        if (i === $scope.timesOfDay.length - 1 && ret !== '')
          ret += '.';
      }
      return ret;
    };

    // Vartojimo dažnumo kartų per "x" pavertimas į tinkamą atvaizdavimui formą
    $scope.getDosageFrequencyPerDay = function () {

      if (!$scope.medicationPrescription.dosageInstructionData.schedule ||
        !$scope.medicationPrescription.dosageInstructionData.schedule.frequency ||
        !$scope.medicationPrescription.dosageInstructionData.schedule.units) {
        return "";
      }
      var ret = "";
      if (angular.isDefined($scope.medicationPrescription.dosageInstructionData.schedule.frequency)
        && angular.isDefined($scope.medicationPrescription.dosageInstructionData.schedule.units)) {
        ret += $scope.medicationPrescription.dosageInstructionData.schedule.frequency;
        ret += ' kartai per ';
        ret += $filter('filter')($scope.frequencyMeasurements, { code: $scope.medicationPrescription.dosageInstructionData.schedule.units })[0].displayValue;
        //ret += $scope.medicationPrescription.dosageInstructionData.schedule.units;
      }
      return ret;
    }

    // Vartojimo dažnumo kas "x" valandų pavertimas į tinkamą atvaizdavimui formą
    $scope.getDosageFrequencyEveryXHours = function () {
      var ret = "";
      if (angular.isDefined($scope.medicationPrescription.dosageInstructionData.timeQuantity)) {
        ret += 'kas ';
        ret += $scope.medicationPrescription.dosageInstructionData.timeQuantity;
        ret += ' valandas';
      }
      return ret;
    }

    // Vartojimo trukmės pavertimas į tinkamą atvaizdavimui formą
    $scope.getTreatmentDuration = function () {
      var ret = "";
      if (angular.isDefined($scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration)) {
        ret += $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration + ' d.';
      }
      return ret;
    }

    // Vartojimo laiko pavertimas į tinkamą atvaizdavimui formą
    $scope.getIntakeTimes = function () {
      var ret = '';
      for (var i = 0; i < $scope.intakeTimes.length; i++) {
        if ($scope.medicationPrescription.dosageInstructionData[$scope.intakeTimes[i].code]) {
          if (ret !== '')
            ret += ', ';
          ret += gettextCatalog.getString($scope.intakeTimes[i].displayValue);
        }
        if (i === $scope.intakeTimes.length - 1 && ret !== '')
          ret += '.';
      }
      return ret;
    };

    // Navigacija į pasirašymo puslapį
    $scope.initSign = function () {
      $scope.options.redirectToSignPageWithDocIds([$scope.medicationPrescription.metadata.docId]);
    };

    $scope.canSign = function () {
      if (angular.isDefined($scope.medicationPrescription.metadata)) {
        return $scope.medicationPrescription.prescriptionStatus
          && $scope.medicationPrescription.metadata.docStatus !== 'Signed'
          && ($scope.medicationPrescription.prescriptionStatus.code === 'valid'
            || $scope.medicationPrescription.prescriptionStatus.code === 'active')
          && $scope.medicationPrescription.dispenseData
          && $scope.medicationPrescription.dispenseData.prescriptionTag === 'ier';
      }
      ;
      return true;
    }

    $scope.canEdit = function () {
      return $scope.medicationPrescription.prescriptionStatus.code !== 'active'
        && $scope.medicationPrescription.prescriptionStatus.code !== 'entered in error';
    }

    $scope.canDelete = function () {
      return true;
    }

    // Skyrimo trynimas
    $scope.deletePrescription = function () {
      if ($scope.medicationPrescription.metadata.id) {
        $erxModalConfirm.show({ content: 'Ar tikrai norite pašalinti?' }).then(function () {
          ErxPrescriptionsApiFactory.deleteMedPrescription({ id: $scope.medicationPrescription.metadata.id }).$promise.then(
            function (res) {
              $location.url($scope.options.getReturnUrl());
              $scope.options.getIntegrationData().displayGlobalMessage('success', 'Receptas pašalintas');
            }
          );
        });
      }
    };

    $scope.canViewPrescriptionDoc = function () {
      return ($scope.backup.prescriptionData.status
        &&
        ($scope.backup.prescriptionData.status.code === 'valid'
          || $scope.backup.prescriptionData.status.code === 'active')
        && $scope.backup.prescriptionData.dispenseData.prescriptionTag === 'ier');
    }

    $scope.goToDoc = function () {
      $scope.options.redirectToViewPrescriptionDoc($scope.options.prescriptionId);
    }


    // Recepto galiojimo periodo pasikeitimo logika
    $scope.validForChanged = function () {
      if ($scope.medicationPrescription.dispenseData.validFrom)
        if (!$scope.medicationPrescription.additional.validFor)
          $scope.medicationPrescription.dispenseData.validUntil = null;
        else {
          $scope.medicationPrescription.dispenseData.validUntil =
            moment($scope.medicationPrescription.dispenseData.validFrom).add('days', $scope.medicationPrescription.additional.validFor - 1).valueOf();
        }
    };
    // Recepto galiojimo periodo pasikeitimo logika
    $scope.validToChanged = function () {
      if ($scope.medicationPrescription.dispenseData.validUntil) {
        try {
          $scope.medicationPrescription.additional.validFor = "";
          $scope.medicationPrescription.additional.validFor = 1 +
            Math.floor((new Date($scope.medicationPrescription.dispenseData.validUntil).getTime() - new Date($scope.medicationPrescription.dispenseData.validFrom).getTime()) / 86400000);
        } catch (err) {
        }
      } else if (angular.isDefined($scope.medicationPrescription.additional)) {
        $scope.medicationPrescription.additional.validFor = null;
      }
    };
    // Ar recepto galiojimo periodas validus
    $scope.dateValid = function () {
      var from = $scope.medicationPrescription.dispenseData.validFrom;
      var to = $scope.medicationPrescription.dispenseData.validUntil;
      return from === undefined || to === undefined || from === '' || to === '' || from <= to;
    }

    var validateForm = function (form) {
        MessageService.clear('prescription-region');
      var invalidWidgets = []
      /*if (!$scope.medicationPrescription.dispenseData.compensationTag && (!$scope.medicationPrescription.additional.aagaSgasYear ||
              !$scope.medicationPrescription.additional.aagaSgasNr ||
              !$scope.medicationPrescription.additional.aagaSgasSuffix) &&
              ($scope.medicationPrescription.additional.aagaSgasYear ||
                      $scope.medicationPrescription.additional.aagaSgasNr ||
                      $scope.medicationPrescription.additional.aagaSgasSuffix)) {
        MessageService.add('error', 'AAGA/SGAS Nr. privalo būti užpildytas pilnai arba nepildomas', 'prescription-region');
        invalidWidgets.push('[msg-region="prescription-region"]');
      }*/
      if (form.$valid && invalidWidgets.length === 0) {
        return true;
      }
      $erxHelpers.setAllInputsDirty(form);
      $erxHelpers.scrollToFirstInvalidOrCustom(invalidWidgets);
      return false;
    };

    var saveMpr = function (docForm) {
      return ErxPrescriptionsApiFactory.submitMedPrescription(docForm,
        function (res) {

        },
        function (err) {
          if (err.data && err.data.messages) {
            var warnings = [];
            angular.forEach(err.data.messages, function (value, key) {
              if (value.type === 'warning') {
                warnings.push({
                  issue:
                  {
                    code: value.code,
                    displayValue: value.content
                  }
                });
              }
            });
            if (warnings.length > 0) {
              $scope.medicationPrescription.issues = warnings;
            }
          }
        });
    }

    // Recepto išsaugojimas
    $scope.save = function (form, signTag) {
      if (!validateForm(form)) {
        return;
      }
      var prs = $scope.backup;
      prs.prescriptionData.dispenseData = $scope.medicationPrescription.dispenseData;
      if ($scope.options.getCompositionId) {
        prs.metadata.relCompId = $scope.options.getCompositionId();
      }

      /*if (angular.isDefined($scope.medicationPrescription.additional) && angular.isDefined($scope.medicationPrescription.additional.aagaSgasNr)) {
        prs.prescriptionData.dispenseData.aagaSgasNumber =
          $scope.medicationPrescription.additional.aagaSgasYear + '/' +
          $scope.medicationPrescription.additional.aagaSgasNr + '/' +
          $scope.medicationPrescription.additional.aagaSgasSuffix;
      }*/
      if (angular.isDefined($scope.medicationPrescription.additional) && angular.isDefined($scope.medicationPrescription.additional.aagaSgasNumber)) {
        prs.prescriptionData.dispenseData.aagaSgasNumber = $scope.medicationPrescription.additional.aagaSgasNumber;
      }

      $scope.actionsDisabled = true;
      saveMpr(prs).$promise.then(function (res) {
        $scope.options.getIntegrationData().displayGlobalMessage('success', gettextCatalog.getString('Receptas pateiktas'));
        if (!signTag) {
          $location.url($scope.options.getReturnUrl());
        } else if (signTag) {
          $scope.options.redirectToViewPrescriptionDoc(res.id);
        }
      }).finally(function () {
        $scope.actionsDisabled = false;
      });
    }

    $scope.saveDraft = function (form) {
      if (!validateForm(form)) {
        return;
      }
      var prs = $scope.backup;
      prs.prescriptionData.dispenseData = $scope.medicationPrescription.dispenseData;
      if ($scope.options.getCompositionId) {
        prs.metadata.relCompId = $scope.options.getCompositionId();
      }

      /*if (angular.isDefined($scope.medicationPrescription.additional) && angular.isDefined($scope.medicationPrescription.additional.aagaSgasNr)) {
        prs.prescriptionData.dispenseData.aagaSgasNumber =
          $scope.medicationPrescription.additional.aagaSgasYear + '/' +
          $scope.medicationPrescription.additional.aagaSgasNr + '/' +
          $scope.medicationPrescription.additional.aagaSgasSuffix;
      }*/
      if (angular.isDefined($scope.medicationPrescription.additional) && angular.isDefined($scope.medicationPrescription.additional.aagaSgasNumber)) {
        prs.prescriptionData.dispenseData.aagaSgasNumber = $scope.medicationPrescription.additional.aagaSgasNumber;
      }

      $scope.actionsDisabled = true;
      ErxPrescriptionsApiFactory.createMedPrescription(prs, function (res) {
        $location.url($scope.options.getReturnUrl());
        $scope.options.getIntegrationData().displayGlobalMessage('success', 'Receptas išsaugotas');
      }).$promise.finally(function () {
        $scope.actionsDisabled = false;
      });
    }

    // Recepto žymos pasikeitimo logika
    $scope.prescriptionTagChanged = function () {
      delete $scope.medicationPrescription.dispenseData.validFrom;
      delete $scope.medicationPrescription.dispenseData.validUntil;
      delete $scope.medicationPrescription.dispenseData.paperPresCompLotNumber;
      delete $scope.medicationPrescription.dispenseData.paperPresCompNumber;
      delete $scope.medicationPrescription.dispenseData.paperPresNPNumber;
      delete $scope.medicationPrescription.dispenseData.paperPresNPLotNumber;
      delete $scope.medicationPrescription.dispenseData.dispenserNote;
      $scope.medicationPrescription.additional = {};
      if (angular.isDefined($scope.medicationPrescription.dispenseData.prescriptionTags)) {
        delete $scope.medicationPrescription.dispenseData.prescriptionTags.numberOfRepeatsAllowed;
      }
      if (angular.isDefined($scope.medicationPrescription.additional)) {
        delete $scope.medicationPrescription.additional.validFor;
      }
      if ($scope.medicationPrescription.dispenseData.prescriptionTag === 'ier') {
        var currDate = new Date();
        $scope.medicationPrescription.dispenseData.validFrom = currDate.getTime();
      }
    };

    /*$scope.aagaPasted = function (event) {
      var data = event.originalEvent.clipboardData.getData('text/plain').split('/');
      if (data.length === 3) {
        if (data[0].length === 4 && data[1].length <= 7 && data[2].length === 2) {
          $scope.medicationPrescription.additional.aagaSgasYear = data[0];
          $scope.medicationPrescription.additional.aagaSgasNr = data[1];
          $scope.medicationPrescription.additional.aagaSgasSuffix = data[2];
        }
      }
    }*/

    loadForm();
  };
    viewPrescriptionFormCtrl.$inject = ["$scope", "$filter", "$location", "$erxModalConfirm", "MessageService", "$erxHelpers", "ErxPrescriptionsApiFactory", "erxPrescriptionsConfig", "gettextCatalog"];

  return {
    restrict: 'E',
    scope: { options: '=' },
    templateUrl: 'modules/erx/prs/prescription_form/edit_prescription_doc/edit_prescription_doc.html',
    controller: viewPrescriptionFormCtrl
  };
}]);

