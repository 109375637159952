ng1App.controller('doctor.patient.VaccineViewCtrl', 
    ['$scope', '$modalConfirm', '$filter', '$routeParams', 'MessageService', 'DpPatientContext', 'VaccinationsApiFactory', 'EncountersApiFactory',
     function ($scope, $modalConfirm, $filter, $routeParams, MessageService, DpPatientContext, VaccinationsApiFactory, EncountersApiFactory) {
    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();
    $scope.patientId = DpPatientContext.id;
    $scope.currentTime = _.truncateToDays(new Date().getTime());

    $scope.canEdit = false;
    EncountersApiFactory.hasActiveEncounter({patientId: $scope.patientId}, function (data) {
        $scope.canEdit = data.value && $filter('acWrite')('ImmunizationSchedule');
    });

    $scope.$emit('setHelpPage', 'perzireti-skiepo-irasa');

    $scope.form = VaccinationsApiFactory.getVaccination({id: $routeParams.id});
    $scope.goBack = function () {
        window.history.back();
    };
    $scope.mark = function () {
        $modalConfirm.show(
                {title: $filter('translate')('inf.ale.vac.mark.tit'), content: $filter('translate')('inf.ale.vac.mark')}).then(function () {
            VaccinationsApiFactory.getMarkAsIgnored({"id": $routeParams.id}, null, function () {
                MessageService.success("Skiepas sėkmingai pažymėtas kaip neaktualus");
                $scope.form = VaccinationsApiFactory.getVaccination({id: $routeParams.id});
            });
        });
    };
}]);