import { Component, Input, TemplateRef } from '@angular/core';
import { EntityModel } from '../../models/entity.model';
import { AbstractControl, FormControl, Validators } from '@angular/forms';

export interface PatientHealthAssessmentsFormRow {
    title: string;
    type: 'choise' | 'text' | 'date' | 'text-select';
    textType?: string;
    options?: EntityModel[];
    otherValueId?: string;
    formControl?: FormControl | AbstractControl;
    otherFormControl?: FormControl | AbstractControl;
    textInputLabel?: string;
    customClass?: string;
}

@Component({
    selector: 'patient-health-assessments-reusable-form',
    templateUrl: './patient-health-assessments-reusable-form.component.html',
})
export class PatientHealthAssessmentsReusableFormComponent {
    @Input() title: string = '';
    @Input() rows: Array<PatientHealthAssessmentsFormRow | TemplateRef<any>>;
    @Input() contextData: any;

    constructor(
    ) {
    }

    isTemplateRef(value: TemplateRef<any> | PatientHealthAssessmentsFormRow): value is TemplateRef<any> {
        return value instanceof TemplateRef;
    }

    generateRadioName(row: PatientHealthAssessmentsFormRow, pos: number): string {
        return this.title.split(/\s+/m) + this.getControlName(row.formControl)?.split(/[\s-]+/).join('') + pos;
    }

    generateSelectionValuesFromEntity(values: EntityModel[]): string[] {
        if (!values?.length) {
            return [];
        }
        return values.map(item => item.name);
    }

    isControlRequired(row: PatientHealthAssessmentsFormRow): boolean {
        return row?.formControl?.hasValidator(Validators.required);
    }

    private getControlName(control: FormControl | AbstractControl): string {
        let group = control?.parent;
        if (!group) {
            return;
        }

        let name: string;
        Object.keys(group.controls).forEach(key => {
            if (control !== group.get(key)) {
                return;
            }

            name = key;
        })
        return name;
    }
}
