<app-title
    [backPath]="backUrl"
    [backSubtitle]="'inr.listPage.subtitle' | translate"
>
    {{ "pp.dia.sea.title" | translate }}
    <aside>
        <button class="btn btn-primary" (click)="createNewInr()">{{'inr.listPage.newInr' | translate}}</button>
    </aside>
</app-title>
<div class="widget">
    <div class="widget-body" [class]="inrHistory?.length ? ' p-0' : ''">
        <ol *ngIf="inrHistory?.length" class="slats hover">
            <li slat-item view clickable
                (slatItemClick)="openInr(i)"
                *ngFor="let inr of inrHistory; let i = index"
                (viewButtonClick)="openInr(i)"
            >
                <div class="row">
                    <div class="col-sm-auto text-md-end text-secondary mb-1 mb-sm-0">
                        {{ inr.updateDate | date : "YYYY-MM-dd" }}
                    </div>
                    <div class="col ps-sm-0 mb-sm-1">
                        <strong>{{inrName(inr)}}</strong>
                    </div>
                </div>
                <div class="row small">
                    <div class="col-sm-auto text-md-end text-secondary mb-1 mb-sm-0">
                        <span class="text-secondary me-2">{{ "inr.listPage.registeredBy" | translate }}: </span>
                    </div>
                    <div class="col ps-sm-1 mb-sm-1 d-flex align-items-center">
                        <strong>{{inr.reportingPerson?.fullName}}<span *ngIf="inr.reportingPerson.qualification">, {{inr.reportingPerson.qualification}}</span></strong>
                    </div>
                </div>
            </li>
        </ol>
        <ng-container *ngIf="!inrHistory?.length">
            <span>{{ "inr.listPage.noInr" | translate }}</span>
        </ng-container>
    </div>
</div>

<pagination-bar
    #paginationBar
    [rowsCount]="total"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>
