import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, tap } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { MedicationModel } from "src/app/shared/models/medication.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2Medications extends ConfigService {

    path = this.apiUrl + '/medications';

    search(query?: HttpParams): Observable<MedicationModel[]> {
        const url = `${this.path}`;
        return this.http.get<MedicationModel[]>(url, {params: query});
    }
}