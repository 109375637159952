console.debug("Setting up NG1-ESPBI doctor application")

import {IModule} from "angular";
import {configNg1Module, initNg1Module} from "../ng1.module";

import '../erxDoctor/ng1.app';
import '../medvais/ng1.app'

import {EnvironmentSettings} from "../../environments/env";
import {Environment} from "../../environments/environment-properties";
import PortalType = Environment.PortalType;
EnvironmentSettings.setEnvironment(PortalType.DOCTOR)

const ng1EspbiMod : IModule = initNg1Module(['erx', 'medvais'])
configNg1Module(ng1EspbiMod)
EnvironmentSettings.initNg1Environment(ng1EspbiMod)

// populate globally the root application
window.ng1App = ng1EspbiMod
ng1App = ng1EspbiMod
