<form-buttons-layout>
    <button
        *ngIf="!undoButton"
        class="btn btn-primary m-1"
        type="button"
        (click)="undo.emit($event)"
        [disabled]="signButtonDisabled"
    >
    {{ "Atšaukti" | translate }}
    </button>
</form-buttons-layout>
