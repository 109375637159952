<div>
    <h1>{{ "E113-TP Tyrimai ir procedūros nėštumo metu" | translate }}</h1>
    <app-document-caption [captionData]="documentCaptionData"></app-document-caption>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <app-ngn-caption [captionData]="ngnCaptionData"></app-ngn-caption>
    <app-encounter-block></app-encounter-block>
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Tyrimai' | translate" id="researches">
        <ng-container *ngTemplateOutlet="
                repeaterTemplate;
                context: { isResearches: true, selectValues: researchesArray, arrayData: getResearchesFormArray }
            ">
        </ng-container>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Procedūros' | translate" id="procedures">
        <ng-container *ngTemplateOutlet="
                repeaterTemplate;
                context: { isResearches: false, selectValues: proceduresArray, arrayData: getProceduresFormArray }
            ">
        </ng-container>
    </nhr-record-widget>

    <app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
        (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
        [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
        [submitButtonDisabled]="true" [saveButtonDisabled]="!formGroup.valid"></app-modification-actions>
</div>

<ng-template #repeaterTemplate let-isResearches="isResearches" let-selectValues="selectValues"
    let-arrayData="arrayData">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="row mb-2">
                <app-select [id]="isResearches ? 'researches' : 'procedures'" [values]="selectValues"
                    [fControl]="isResearches ? formGroup.get('researchSelect') : formGroup.get('procedureSelect')" />
            </div>
            <div *ngIf="isResearches ? formGroup.get('researchSelect').value : formGroup.get('procedureSelect').value"
                class="row mb-2 g-2">
                <div class="card">
                    <div class="card-body">
                        <div class="col">
                            <div *ngIf="showDateInput(isResearches)" class="row g-3" [ngClass]="{
                                    req:
                                        !isResearchVisibleBySelect(Researches.BloodTest) &&
                                        !isResearchVisibleBySelect(Researches.GBS) &&
                                        !isResearchVisibleBySelect(Researches.Urine)
                                }">
                                <div [class]="
                                        !isResearches && isProcedureVisibleBySelect(Procedures.AntiDImmunoglobulin)
                                            ? 'col-lg-9 col-md-9 col-sm-9'
                                            : ''
                                    ">
                                    <app-date-picker inputId="Date"
                                        [labelText]="getFieldName(isResearches, Fields.Date)" labelClass="fw-bold"
                                        [showErrors]="true"
                                        dateInputFormat="YYYY-MM-DD" [maxDate]="currentDate" [fControl]="
                                            isResearches
                                                ? researchFormGroup.get('date')
                                                : procedureFormGroup.get('date')
                                        "></app-date-picker>
                                </div>
                                <div *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.AntiDImmunoglobulin)"
                                    class="col-lg-3 col-md-3 col-sm-3 d-flex align-items-end">
                                    <app-time-picker id="time" [fControl]="procedureFormGroup.get('time')"
                                        [disabled]="!procedureFormGroup.get('date').value"></app-time-picker>
                                </div>
                            </div>
                            <!-- Researches -->
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.BloodTests)">
                                <div class="row req">
                                    <app-select id="pregnantBloodType"
                                        [labelText]="ResearchesFieldsTitles.PregnantBloodType" [values]="BloodGroups"
                                        [showErrors]="true"
                                        [fControl]="researchFormGroup.get('pregnantBloodType')" />
                                </div>
                                <div class="row">
                                    <app-select id="fatherBloodGroup"
                                        [labelText]="ResearchesFieldsTitles.FatherBloodGroup" [values]="BloodGroups"
                                        [showErrors]="true"
                                        [fControl]="researchFormGroup.get('fatherBloodGroup')" />
                                </div>
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.Antibody)">
                                <fieldset class="row">
                                    <legend class="fw-bold">
                                        {{ ResearchesFieldsTitles.AntibodyTestResult | translate }}
                                    </legend>
                                    <div *ngFor="let item of YesNoOptions" class="col-md-6 col-sm-6 req">
                                        <app-radio-button [addUniqId]="true" radioName="antibodyTestResult" [labelText]="item.name"
                                            [radioTitle]="item.name" [id]="item.name" [radioValue]="item.value"
                                            [fControl]="researchFormGroup.get('antibodyTestResult')"></app-radio-button>
                                    </div>
                                    <app-form-control-validation [fControl]="researchFormGroup.get('antibodyTestResult')" />
                                </fieldset>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-label fw-bold">
                                        {{ ResearchesFieldsTitles.AntibodyTiter | translate }}
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <app-input id="antibodyTiter" type="number"
                                            [fControl]="researchFormGroup.get('antibodyTiter')" />
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <app-input id="antibodyTiter2" type="number"
                                            [fControl]="researchFormGroup.get('antibodyTiter2')" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.BloodTest)">
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="bloodErythrocytes"
                                            [labelText]="ResearchesFieldsTitles.BloodErythrocytes" labelClass="fw-bold"
                                            type="number" units="*10^12/l" [showErrors]="true"
                                            [fControl]="researchFormGroup.get('bloodErythrocytes')" />
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="bloodHemoglobin"
                                            [labelText]="ResearchesFieldsTitles.BloodHemoglobin" labelClass="fw-bold"
                                            type="number" units="g/l" [showErrors]="true"
                                            [fControl]="researchFormGroup.get('bloodHemoglobin')" />
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="bloodHematocrit"
                                            [labelText]="ResearchesFieldsTitles.BloodHematocrit" labelClass="fw-bold"
                                            [showErrors]="true"
                                            type="number" [fControl]="researchFormGroup.get('bloodHematocrit')" />
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="bloodLeukocytes"
                                            [labelText]="ResearchesFieldsTitles.BloodLeukocytes" labelClass="fw-bold"
                                            type="number" units="*10^9/l" [showErrors]="true"
                                            [fControl]="researchFormGroup.get('bloodLeukocytes')" />
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="bloodPlatelets"
                                            [labelText]="ResearchesFieldsTitles.BloodPlatelets" labelClass="fw-bold"
                                            type="number" units="*10^9/l" [showErrors]="true"
                                            [fControl]="researchFormGroup.get('bloodPlatelets')" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.Syphilis)">
                                <div class="row">
                                    <div class="col">
                                        <fieldset class="row req">
                                            <legend class="fw-bold col-form-label">
                                                {{ ResearchesFieldsTitles.RPRTestResult | translate }}
                                            </legend>
                                            <div *ngFor="let item of YesNoOptions" class="col-md-6 col-sm-6">
                                                <app-radio-button [addUniqId]="true" radioName="RPRTestResult" [labelText]="item.name"
                                                    [radioTitle]="item.name" [id]="item.name"
                                                    [radioValue]="item.value"
                                                    [fControl]="researchFormGroup.get('RPRTestResult')"></app-radio-button>
                                            </div>
                                            <app-form-control-validation [fControl]="researchFormGroup.get('RPRTestResult')" />
                                        </fieldset>
                                    </div>
                                    <div class="col">
                                        <app-input id="RPRStudyTiter" [labelText]="ResearchesFieldsTitles.RPRStudyTiter"
                                            labelClass="fw-bold" type="number"
                                            [viewMode]="!researchFormGroup.get('RPRTestResult').value"
                                            [fControl]="researchFormGroup.get('RPRStudyTiter')" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.HIV)" class="row">
                                <div class="col">
                                    <fieldset class="row req">
                                        <legend class="fw-bold col-form-label">
                                            {{ ResearchesFieldsTitles.HIVTestResult | translate }}
                                        </legend>
                                        <div *ngFor="let item of YesNoOptions" class="col-md-6 col-sm-6">
                                            <app-radio-button [addUniqId]="true" radioName="HIVTestResult" [labelText]="item.name"
                                                [radioTitle]="item.name" [id]="item.name" [radioValue]="item.name"
                                                [fControl]="researchFormGroup.get('HIVTestResult')"></app-radio-button>
                                        </div>
                                        <app-form-control-validation [fControl]="researchFormGroup.get('HIVTestResult')" />
                                    </fieldset>
                                </div>
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.Glucose)" class="row req">
                                <app-input id="bloodPlasmaGlucoseTestResult"
                                    [labelText]="ResearchesFieldsTitles.BloodPlasmaGlucoseTestResult"
                                    labelClass="fw-bold" type="number" units="mmol/l"
                                    [showErrors]="true"
                                    [fControl]="researchFormGroup.get('bloodPlasmaGlucoseTestResult')" />
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.HepatitisB)" class="row">
                                <div class="col">
                                    <fieldset class="row req">
                                        <legend class="fw-bold col-form-label">
                                            {{ ResearchesFieldsTitles.HepatitisBResult | translate }}
                                        </legend>
                                        <div *ngFor="let item of HepatitisBOptions" class="col-md-6 col-sm-6">
                                            <app-radio-button [addUniqId]="true" radioName="hepatitisBResult" [labelText]="item.name"
                                                [radioTitle]="item.name" [id]="item.name" [radioValue]="item.value"
                                                [fControl]="researchFormGroup.get('hepatitisBResult')"></app-radio-button>
                                        </div>
                                        <app-form-control-validation [fControl]="researchFormGroup.get('hepatitisBResult')" />
                                    </fieldset>
                                </div>
                            </div>
                            <div *ngIf="
                                    isResearches && isResearchVisibleBySelect(Researches.UrineAsymptomaticBacteriuria)
                                " class="row req">
                                <app-select id="asymptomaticBacteriuriaResult"
                                    [labelText]="ResearchesFieldsTitles.AsymptomaticBacteriuriaResult"
                                    [placeholder]="null" [values]="asymptomaticBacteriuriaResultsArray"
                                    [showErrors]="true"
                                    [fControl]="researchFormGroup.get('asymptomaticBacteriuriaResult')" />
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.GTM)">
                                <div class="row">
                                    <div class="col">
                                        <fieldset class="row">
                                            <legend class="fw-bold">
                                                {{ ResearchesFieldsTitles.GTMResult | translate }}
                                            </legend>
                                            <div *ngFor="let item of YesNoOptions" class="col-md-6 col-sm-6">
                                                <app-radio-button [addUniqId]="true" radioName="GTMResult" [labelText]="item.name"
                                                    [radioTitle]="item.name" [id]="item.name"
                                                    [radioValue]="item.value"
                                                    [fControl]="researchFormGroup.get('GTMResult')"></app-radio-button>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="GTMI" [labelText]="ResearchesFieldsTitles.GTMI"
                                            labelClass="fw-bold" type="number" units="mmol/l"
                                            [showErrors]="true"
                                            [fControl]="researchFormGroup.get('GTMI')" />
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="GTMII" [labelText]="ResearchesFieldsTitles.GTMII"
                                            labelClass="fw-bold" type="number" units="mmol/l"
                                            [showErrors]="true"
                                            [fControl]="researchFormGroup.get('GTMII')" />
                                    </div>
                                </div>
                                <div class="row req">
                                    <div class="col">
                                        <app-input id="GTMIII" [labelText]="ResearchesFieldsTitles.GTMIII"
                                            labelClass="fw-bold" type="number" units="mmol/l"
                                            [showErrors]="true"
                                            [fControl]="researchFormGroup.get('GTMIII')" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.GBS)" class="row">
                                <fieldset class="row req">
                                    <legend class="fw-bold col-form-label">
                                        {{ ResearchesFieldsTitles.CropBGSTestResults | translate }}
                                    </legend>
                                    <div *ngFor="let item of cropBGSTestArray" class="col-md-6 col-sm-6 mb-2">
                                        <app-radio-button [addUniqId]="true" [id]="item" radioName="cropBGSTest" [labelText]="item"
                                            [radioTitle]="item" [id]="item" [radioValue]="item"
                                            [fControl]="researchFormGroup.get('cropBGSTest')"></app-radio-button>
                                    </div>
                                    <app-form-control-validation [fControl]="researchFormGroup.get('cropBGSTest')" />
                                </fieldset>
                                <app-select
                                    *ngIf="researchFormGroup.get('cropBGSTest').value === CropBGSTest.Investigated"
                                    id="cropBGSTestResults" [values]="cropBGSTestInvestigatedArray" [placeholder]="null"
                                    [fControl]="researchFormGroup.get('cropBGSTestResults')" />

                                <app-select
                                    *ngIf="researchFormGroup.get('cropBGSTest').value === CropBGSTest.NotInvestigated"
                                    id="cropBGSTestResults1" [values]="cropBGSTestNotInvestigatedResultArray"
                                    [placeholder]="null" [fControl]="researchFormGroup.get('cropBGSTestResults')" />
                            </div>
                            <div *ngIf="isResearches && isResearchVisibleBySelect(Researches.Urine)" class="row req">
                                <app-input id="urineProtein" [labelText]="ResearchesFieldsTitles.UrineProtein"
                                    labelClass="fw-bold" type="number" [showErrors]="true"
                                    [fControl]="researchFormGroup.get('urineProtein')" />
                                <app-input id="urineLeukocytes" [labelText]="ResearchesFieldsTitles.UrineLeukocytes"
                                    labelClass="fw-bold" type="number" [showErrors]="true"
                                    [fControl]="researchFormGroup.get('urineLeukocytes')" />
                                <app-input id="urineErythrocytes" [labelText]="ResearchesFieldsTitles.UrineErythrocytes"
                                    labelClass="fw-bold" type="number" [showErrors]="true"
                                    [fControl]="researchFormGroup.get('urineErythrocytes')" />
                                <app-input id="urineGlucose" [labelText]="ResearchesFieldsTitles.UrineGlucose"
                                    labelClass="fw-bold" type="number" [showErrors]="true"
                                    [fControl]="researchFormGroup.get('urineGlucose')" />
                                <app-input id="urineKetones" [labelText]="ResearchesFieldsTitles.UrineKetones"
                                    labelClass="fw-bold" type="number" [showErrors]="true"
                                    [fControl]="researchFormGroup.get('urineKetones')" />
                                <app-input id="urinaryNitrites" [labelText]="ResearchesFieldsTitles.UrinaryNitrites"
                                    labelClass="fw-bold" type="number" [showErrors]="true"
                                    [fControl]="researchFormGroup.get('urinaryNitrites')" />
                            </div>
                            <!-- Procedures -->
                            <div *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.AntiDImmunoglobulin)">
                                <fieldset class="row req">
                                    <legend class="fw-bold col-form-label">
                                        {{ ProceduresFieldsTitles.InjectedAntiDImmunoglobulin | translate }}
                                    </legend>
                                    <div *ngFor="let item of YesNoOptions" class="col-md-6 col-sm-6">
                                        <app-radio-button [addUniqId]="true" radioName="injectedAntiDImmunoglobulin"
                                            [labelText]="item.name" [radioTitle]="item.name" [id]="item.name"
                                            [radioValue]="item.value"
                                            [fControl]="procedureFormGroup.get('injectedAntiDImmunoglobulin')"></app-radio-button>
                                    </div>
                                    <app-form-control-validation [fControl]="procedureFormGroup.get('injectedAntiDImmunoglobulin')" />
                                </fieldset>
                            </div>
                            <div *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.PrenatalScreening)">
                                <app-select [labelText]="ProceduresFieldsTitles.PrenatalScreeningResult"
                                    id="prenatalScreeningResult" [values]="prenatalScreeningResultsArray"
                                    [fControl]="procedureFormGroup.get('prenatalScreeningResult')" />
                            </div>
                            <div *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.PrenatalDiagnostics)">
                                <app-date-picker inputId="amniocentesisDiagnosisDate"
                                    [labelText]="ProceduresFieldsTitles.AmniocentesisDiagnosisDate" labelClass="fw-bold"
                                    [fControl]="procedureFormGroup.get('amniocentesisDiagnosisDate')"></app-date-picker>
                                <app-date-picker inputId="Date"
                                    [labelText]="ProceduresFieldsTitles.CordocentesisDiagnosisDate" labelClass="fw-bold"
                                    [fControl]="procedureFormGroup.get('cordocentesisDiagnosisDate')"></app-date-picker>
                            </div>
                            <div
                                *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.FetalSurgicalProcedures)">
                                <app-select [labelText]="ProceduresFieldsTitles.StandardFetalSurgicalProcedure"
                                    id="standardFetalSurgicalProcedure" [values]="standardFetalSurgicalProceduresArray"
                                    [fControl]="procedureFormGroup.get('standardFetalSurgicalProcedure')" />
                                <app-input id="otherFetalSurgicalProcedure"
                                    [labelText]="ProceduresFieldsTitles.OtherFetalSurgicalProcedure"
                                    labelClass="fw-bold" type="text"
                                    [fControl]="procedureFormGroup.get('otherFetalSurgicalProcedure')" />
                            </div>
                            <div *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.FetalLungMaturation)">
                                <fieldset class="row">
                                    <legend class="fw-bold">
                                        {{ ProceduresFieldsTitles.FetalLungMaturation | translate }}
                                    </legend>
                                    <div *ngFor="let item of FetalLungMaturationOptions" class="col-md-6 col-sm-6">
                                        <app-radio-button [addUniqId]="true" radioName="fetalLungMaturation" [labelText]="item.name"
                                            [radioTitle]="item.name" [id]="item.name" [radioValue]="item.value"
                                            [fControl]="procedureFormGroup.get('fetalLungMaturation')"></app-radio-button>
                                    </div>
                                </fieldset>
                                <div [class]="procedureFormGroup.get('fetalLungMaturation').value ? 'row req' : 'row'">
                                    <div class="col">
                                        <app-input id="pregnancyWeek" [labelText]="ProceduresFieldsTitles.PregnancyWeek"
                                            labelClass="fw-bold" type="number"
                                            [showErrors]="true"
                                            [fControl]="procedureFormGroup.get('pregnancyWeek')" />
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.TocolysisDuringPregnancy)">
                                <fieldset class="row">
                                    <legend class="fw-bold">
                                        {{ Procedures.TocolysisDuringPregnancy | translate }}
                                    </legend>
                                    <div *ngFor="let item of YesNoOptions" class="col-md-6 col-sm-6">
                                        <app-radio-button [addUniqId]="true" radioName="tocolysisDuringPregnancy" [labelText]="item.name"
                                            [radioTitle]="item.name" [id]="item.name" [radioValue]="item.value"
                                            [fControl]="procedureFormGroup.get('tocolysisDuringPregnancy')"></app-radio-button>
                                    </div>
                                </fieldset>
                            </div>
                            <div *ngIf="!isResearches && isProcedureVisibleBySelect(Procedures.Other)">
                                <app-input id="otherInfo" [labelText]="Procedures.Other" labelClass="fw-bold"
                                    type="text" [fControl]="procedureFormGroup.get('otherInfo')" />
                            </div>
                            <div class="row mt-2">
                                <div class="col text-end">
                                    <button class="btn btn-secondary me-2" type="submit"
                                        [disabled]="disableAddElement(isResearches)"
                                        (click)="addElementToArray(isResearches)">
                                        {{ getAddButtonName(isResearches) | translate }}
                                    </button>
                                    <button class="btn btn-outline-danger" (click)="cancelAdding(isResearches)">
                                        {{ "Atšaukti" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="arrayData.length" class="col-lg-6 col-md-12 col-sm-12">
            <div class="row mb-2 g-2">
                <div class="card">
                    <div class="card-body">
                        <div *ngFor="let researchOrProcedure of arrayData.value; let i = index"
                            class="row mb-2 g-2 border-bottom">
                            <div class="col">
                                <div class="fw-bold mb-3">{{ researchOrProcedure.name }}</div>
                                <div *ngIf="researchOrProcedure.date" class="row">
                                    <div class="fw-bold col-md-8">
                                        {{
                                        getFieldName(isResearches, Fields.Date, researchOrProcedure.name)
                                        | translate
                                        }}:
                                    </div>
                                    <div class="col-md-4">
                                        {{
                                        researchOrProcedure.date
                                        | date
                                        : (isProcedureVisibleBySelect(
                                        Procedures.AntiDImmunoglobulin,
                                        researchOrProcedure.name
                                        )
                                        ? "yyyy-MM-dd HH:mm"
                                        : "yyyy-MM-dd")
                                        }}
                                    </div>
                                </div>
                                <!-- Researches -->
                                <div *ngIf="isResearchVisibleBySelect(Researches.BloodTests, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.PregnantBloodType | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.pregnantBloodType }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.FatherBloodGroup | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.fatherBloodGroup }}</div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.Antibody, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.AntibodyTestResult | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{
                                            researchOrProcedure.antibodyTestResult
                                            ? ("Taip" | translate)
                                            : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                    <div *ngIf="researchOrProcedure.antibodyTestResult" class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.AntibodyTiter | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.antibodyTiter }} :
                                            {{ researchOrProcedure.antibodyTiter2 }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.BloodTest, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.BloodErythrocytes | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.bloodErythrocytes }} {{ "*10^12/l" | translate }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.BloodHemoglobin | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.bloodHemoglobin }} {{ "g/l" | translate }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.BloodHematocrit | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.bloodHematocrit }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.BloodLeukocytes | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.bloodLeukocytes }} {{ "*10^9/l" | translate }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.BloodPlatelets | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.bloodPlatelets }} {{ "*10^9/l" | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.Syphilis, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.RPRTestResult | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{
                                            researchOrProcedure.RPRTestResult
                                            ? ("Taip" | translate)
                                            : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.RPRStudyTiter | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.RPRStudyTiter }}</div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.HIV, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.HIVTestResult | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.HIVTestResult }}</div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.Glucose, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.BloodPlasmaGlucoseTestResult | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.bloodPlasmaGlucoseTestResult }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.HepatitisB, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.HepatitisBResult | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{
                                            researchOrProcedure.hepatitisBResult
                                            ? ("Teigiamas" | translate)
                                            : ("Neigiamas" | translate)
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="
                                        isResearchVisibleBySelect(
                                            Researches.UrineAsymptomaticBacteriuria,
                                            researchOrProcedure.name
                                        )
                                    ">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.AsymptomaticBacteriuriaResult | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.asymptomaticBacteriuriaResult }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.GTM, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.GTMResult | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{
                                            researchOrProcedure.GTMResult
                                            ? ("Taip" | translate)
                                            : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.GTMI | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.GTMI }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.GTMII | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.GTMII }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.GTMIII | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.GTMIII }}</div>
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.GBS, researchOrProcedure.name)"
                                    class="row">
                                    <div class="fw-bold col-md-8">
                                        {{ ResearchesFieldsTitles.CropBGSTestResults | translate }}:
                                    </div>
                                    <div class="col-md-4">
                                        {{ researchOrProcedure.cropBGSTest }} -
                                        {{ researchOrProcedure.cropBGSTestResults }}
                                    </div>
                                </div>
                                <div *ngIf="isResearchVisibleBySelect(Researches.Urine, researchOrProcedure.name)">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.UrineProtein | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.urineProtein }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.UrineLeukocytes | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.urineLeukocytes }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.UrineErythrocytes | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.urineErythrocytes }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.UrineGlucose | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.urineGlucose }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.UrineKetones | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.urineKetones }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ResearchesFieldsTitles.UrinaryNitrites | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.urinaryNitrites }}</div>
                                    </div>
                                </div>
                                <!-- Procedures -->
                                <div *ngIf="
                                        isProcedureVisibleBySelect(
                                            Procedures.AntiDImmunoglobulin,
                                            researchOrProcedure.name
                                        )
                                    " class="row">
                                    <div class="fw-bold col-md-8">
                                        {{ ProceduresFieldsTitles.InjectedAntiDImmunoglobulin | translate }}:
                                    </div>
                                    <div class="col-md-4">
                                        {{
                                        researchOrProcedure.injectedAntiDImmunoglobulin
                                        ? ("Taip" | translate)
                                        : ("Ne" | translate)
                                        }}
                                    </div>
                                </div>
                                <div *ngIf="
                                        isProcedureVisibleBySelect(
                                            Procedures.PrenatalScreening,
                                            researchOrProcedure.name
                                        )
                                    " class="row">
                                    <div class="fw-bold col-md-8">
                                        {{ ProceduresFieldsTitles.PrenatalScreeningResult | translate }}:
                                    </div>
                                    <div class="col-md-4">{{ researchOrProcedure.prenatalScreeningResult }}</div>
                                </div>
                                <div *ngIf="
                                        isProcedureVisibleBySelect(
                                            Procedures.PrenatalDiagnostics,
                                            researchOrProcedure.name
                                        )
                                    ">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ProceduresFieldsTitles.AmniocentesisDiagnosisDate | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.amniocentesisDiagnosisDate | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ProceduresFieldsTitles.CordocentesisDiagnosisDate | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.cordocentesisDiagnosisDate | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="
                                        isProcedureVisibleBySelect(
                                            Procedures.FetalSurgicalProcedures,
                                            researchOrProcedure.name
                                        )
                                    ">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ProceduresFieldsTitles.StandardFetalSurgicalProcedure | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.standardFetalSurgicalProcedure }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ProceduresFieldsTitles.OtherFetalSurgicalProcedure | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{ researchOrProcedure.otherFetalSurgicalProcedure }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="
                                        isProcedureVisibleBySelect(
                                            Procedures.FetalLungMaturation,
                                            researchOrProcedure.name
                                        )
                                    ">
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ProceduresFieldsTitles.FetalLungMaturation | translate }}:
                                        </div>
                                        <div class="col-md-4">
                                            {{
                                            researchOrProcedure.fetalLungMaturation
                                            ? ("Atliktas" | translate)
                                            : ("Neatliktas" | translate)
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-8">
                                            {{ ProceduresFieldsTitles.PregnancyWeek | translate }}:
                                        </div>
                                        <div class="col-md-4">{{ researchOrProcedure.pregnancyWeek }}</div>
                                    </div>
                                </div>
                                <div *ngIf="
                                        isProcedureVisibleBySelect(
                                            Procedures.TocolysisDuringPregnancy,
                                            researchOrProcedure.name
                                        )
                                    " class="row">
                                    <div class="fw-bold col-md-8">
                                        {{ Procedures.TocolysisDuringPregnancy | translate }}:
                                    </div>
                                    <div class="col-md-4">
                                        {{
                                        researchOrProcedure.tocolysisDuringPregnancy
                                        ? ("Taip" | translate)
                                        : ("Ne" | translate)
                                        }}
                                    </div>
                                </div>
                                <div *ngIf="isProcedureVisibleBySelect(Procedures.Other, researchOrProcedure.name)"
                                    class="row mb-2">
                                    <div class="fw-bold col-md-8">{{ Procedures.Other | translate }}:</div>
                                    <div class="col-md-4">{{ researchOrProcedure.otherInfo }}</div>
                                </div>
                                <div class="block my-2">
                                    <button class="btn btn-sm btn-hover-outline text-primary m-1" type="button"
                                        [disabled]="disabledEditButton(i, isResearches)"
                                        (click)="editElement(i, researchOrProcedure, isResearches)">
                                        <i class="fa fa-pencil"></i> {{ "Koreguoti" | translate }}
                                    </button>
                                    <button class="btn btn-sm btn-hover-outline text-primary m-1" type="submit"
                                        (click)="removeArrayElement(i, isResearches)">
                                        <i class="fa fa-trash"></i> {{ "Pašalinti" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>