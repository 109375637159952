angular.module('medvais.utils').service('$medvaisWidgetService', function () {
  this.messages = {};
  this.add = function (type, content, region) {
    if (!this.messages[region]) {
      this.messages[region] = [];
    }
    this.messages[region].push({type: type, content: content});
  }

  this.remove = function (index, region) {
    if (this.messages[region] && angular.isNumber(index)) {
      this.messages[region].splice(index, 1);
    }
  }
  
  this.clear = function (region) {
    this.messages[region] = [];
  }
});


