ng1App.factory('PersonRepresentationsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/representations';
    return $resource('', {}, {
        get: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        create: {
        	method: 'POST',
        	url: URL_BASE
        },
        update: {
        	method: 'PUT',
        	url: URL_BASE + '/:id'
        },
        getList: {
        	method: 'GET',
        	url: URL_BASE
        },
        cancel: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/cancel'
        },
        approve: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/approve'
        },
        refuse: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/refuse'
        },
        getPractitionerConfiguration: {
        	method: 'GET',
        	url: URL_BASE + '/practitioner-configuration'
        },
        getPatientConfiguration: {
        	method: 'GET',
        	url: URL_BASE + '/patient-configuration'
        },
        initSigning: {
        	method: 'GET',
        	url: URL_BASE + '/initSigning'
        },
        signResult: {
        	method: 'GET',
        	url: URL_BASE + '/signResult'
        },
        block: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/block'
        },
        unblock: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/unblock'
        },
        getRepresentativeTypeMessage: {
          method: 'GET',
          url: URL_BASE + '/messages/representativeType/:representativeTypeId'
        }
    });
}]);