import {Component, Input, OnInit} from '@angular/core';
import {DocRefConsentModel} from "../../../models/doc-ref-consent.model";
import {DocRefConsentTemplateModel} from "../../../models/doc-ref-consent-template.model";
import {DocRefConsentService} from "../../../services/doc-ref-consent.service";
import {DocRefConsentTemplateService} from "../../../services/doc-ref-consent-template.service";
import {PortalTypeEnum} from "../../../models/portalType.enum";
import {Router} from "@angular/router";
import {RouteParams} from "../../../../ng1.routeParams";

@Component({
    selector: 'doc-ref-consent-card',
    templateUrl: './doc-ref-consent-card.component.html'
})
export class DocRefConsentCardComponent implements OnInit {

    @Input() data: DocRefConsentModel | DocRefConsentTemplateModel;
    @Input() badgeColor: string;
    @Input() badgeTitle: string;
    @Input() dateBetween: string;
    @Input() practitionerFullName: string;
    @Input() patientFullName: string;
    @Input() portalType: PortalTypeEnum;
    @Input() patientId: string;
    @Input() onCardClick: (id: string) => void;
    @Input() onEditClick: (id: string) => void;

    constructor(
        private router: Router,
        private params: RouteParams,
        private docRefConsentService: DocRefConsentService,
        private docRefConsentTemplateService: DocRefConsentTemplateService
    ) {
    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        if (this.data.type === "DocRefConsentModel") {
            this.badgeColor = this.docRefConsentService.getBadgeColor(this.data.docStatus.code);
            this.badgeTitle = this.docRefConsentService.getDocStatus(this.data.docStatus.code);
            this.dateBetween = this.docRefConsentService.getDateBetween(this.data.period.start, this.data.period.end);
            this.practitionerFullName = this.docRefConsentService.getPractitioner(this.data.practitioner);
            this.patientFullName = this.docRefConsentService.getPatient(this.data.patient);
        } else {
            this.badgeColor = this.docRefConsentTemplateService.getBadgeColor(this.data.docStatus.code);
            this.badgeTitle = this.docRefConsentTemplateService.getDocStatus(this.data.docStatus.code);
            this.dateBetween = this.docRefConsentTemplateService.getDateBetween(this.data.period.start, this.data.period.end);
        }
    }

    canEdit(): boolean {
        return this.portalType === PortalTypeEnum.AP;
    }
}
