import { DocumentCaptionModel } from "../components/shared/models/shared.model";

export enum DocumentStatus {
    Preliminary = "Preliminarus",
    Final = "Galutinis",
    Amended = "Iš dalies pakeistas",
    EnteredInError = "Per klaidą įvestas",
}

export const DocumentCaptionMock: DocumentCaptionModel = {
    eventId: "118479299",
    documentId: "118479288",
    date: new Date("2023-09-28"),
    status: DocumentStatus.Final,
};
