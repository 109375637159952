ng1App.controller('doctor.patient.DocumentsViewCtrl',
    ['$scope', '$routeParams', '$location', '$modal', '$rootScope', '$filter', '$route', '$routeSegment', 'DocumentCtx', 'DocumentsApiFactory', 'MessageService', 'FormReloadService', 'EncountersApiFactory', 'DocumentTypeSelector', 'DocumentSpinnerHelper', 'apiUrl', 'DocumentModificationActions', 'FormLoaderService', 'IntegratedComponentContext', 'SveidraApiFactory', 'AagaSgasApiFactory',
     function ($scope, $routeParams, $location, $modal, $rootScope, $filter, $route, $routeSegment,
                                                                DocumentCtx, DocumentsApiFactory, MessageService, FormReloadService, EncountersApiFactory, DocumentTypeSelector,
                                                                DocumentSpinnerHelper, apiUrl, DocumentModificationActions, FormLoaderService,
                                                                IntegratedComponentContext, SveidraApiFactory, AagaSgasApiFactory) {
    $scope.$emit('setHelpPage', 'perzireti-elektronini-dokumenta');

    $scope.docSpinnerHelper = DocumentSpinnerHelper;

    $scope._loadDocument = function () {
        FormLoaderService.startFormLoading();
        DocumentCtx.reset();
        DocumentCtx.setPatientId($routeParams.patientId);
        DocumentCtx.setDocumentId($routeParams.documentId);
        DocumentSpinnerHelper.reset();

        $scope.documentCancelReason = {};
        $scope.cancelErr = 0;

        MessageService.clearAll();
        MessageService.showSuccessEventMessage();
        MessageService.unregisterSuccessEvent();

        $scope.form = DocumentsApiFactory.getByComposition({id: $routeParams.documentId}, function (d) {
            DocumentCtx.setDocument(d);
            $scope.prefillData = d;
            $scope.patientData = d.metadata.patient;
            $scope.organization = d.metadata.custodian;
            $scope.practitioner = d.metadata.practitioner;
            $scope.encounterData = d.metadata.encounter;
            $scope.encounterActive = EncountersApiFactory.isActive({encounterId: $scope.encounterData.id});
        });

        AagaSgasApiFactory.getAagaSgasForDocument({id: $routeParams.documentId}, function (aagaSgasResponse) {
            $scope.aagaSgas = aagaSgasResponse.aagaSgas;
        });

        FormLoaderService.endFormLoading($scope.form.$promise);

        var sgnst = $routeParams['sgnst'];
        if (sgnst) {
            if (sgnst == 'ok') {
                MessageService.add('success', 'Dokumentas pasirašytas sėkmingai');
            } else if (sgnst == 'canceled') {
                MessageService.add('warning', 'Dokumento pasirašymas atšauktas');
            } else if (sgnst == 'fail') {
                var errorid = $routeParams['msgid'];
                MessageService.add('error', 'Nepavyko pasirašyti dokumento. Klaidos ID: ' + errorid);
            }

        }
    };
    $scope._loadDocument();
    $scope.docCtx = DocumentCtx;

    $scope.$on('$locationChangeStart', function (next, current) {
        if ($route.current.regexp.test($location.path())) {
            _.each($routeSegment.chain, function (c) {
                console.log(c);
                c.reload();
            })
        }
    });

    var modalCancel = $modal({
        scope: $scope,
        title: 'Atšaukimo priežastis',
        contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel.html'
    });

    $scope.editDocument = function () {
        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + $routeParams.documentId + '/edit');
    };

    $scope.amendDocument = function () {
        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + $routeParams.documentId + '/amend');
    };

    $scope.enterCancelReason = function (doSign) {
        $scope.doSignCancel = (doSign === undefined || doSign === null) ? true : doSign;
        $scope.cancelErr = 0;
        $scope.documentCancelReason = {};
        $scope.errors = [];
        $scope.docSpinnerHelper = DocumentSpinnerHelper;
        modalCancel.$promise.then(function () {
            modalCancel.show();
        });
    };

    $scope.isCancelable = function () {
        var doc = DocumentCtx.getDocument();
        if (doc) {
            return _.contains(['final_', 'preliminary', 'amended'], doc.metadata.status);
        }
        return false;
    };

    $scope.hasErrors = function () {
        return $scope.errors && $scope.errors.length > 0;
    };

    DocumentsApiFactory.hasActiveE025Related({id: $routeParams.documentId}, function (data) {
        $scope.hasActiveE025Related = data.hasActiveE025Related;
    });

    $scope.needsExternalCancellation = function () {
        return !$scope.hasActiveE025Related;
    };

    $scope.getDocumentType = function () {
        return DocumentCtx.getDocumentType();
    };

    $scope.doCancel = function () {
        var metadata = DocumentCtx.getDocument().metadata;

        MessageService.clearAll();
        $scope.cancelErr = 0;
        $scope.errors = [];
        var cancelReasonText = $scope.documentCancelReason.text;
        if (cancelReasonText != null && cancelReasonText != '') {
            $scope.docSpinnerHelper.startLoading();
            $scope.cancelErr = 0;
            var cancelRequest = {
                compositionId: metadata.compositionId,
                cancelReason: cancelReasonText
            };
            var onSuccess = function (data, status) {
                $scope.docSpinnerHelper.endLoading();
                console.log('Document cancel success:' + data + ';' + status);
                //Location for signing
                var compositionId = $routeParams.documentId;
                if (compositionId != null) {
                    MessageService.registerCancellationSuccessEvent();
                    if ($scope.doSignCancel) {
                        redirectToSignPage($routeParams.patientId, compositionId);
                    } else {
                        FormReloadService.reloadForm(false);
                    }
                }
                modalCancel.hide();
            };
            var onError = function (data, status) {
                console.log(data);
                $scope.docSpinnerHelper.endLoading();
                if (data.status == 500) {
                    console.log('Document cancel failed:' + data + ';' + status);
                    MessageService.handleError(data, MessageService);
                } else if (data.status == 400) {
                    angular.forEach(data.data.globalErrors, function (error) {
                        $scope.errors.push(error);
                        if (error.msg === 'HAS_NOT_CANCELED_CHILDREN') {
                            error.labelCode = 'err.cancel.doc.have_childs';
                        } else if (error.msg === 'HAS_ACTIVE_ENCOUNTER') {
                            error.labelCode = 'err.cancel.doc.have_encounter';
                        }
                    });
                }
                if (!$scope.hasErrors()) {
                    modalCancel.hide();
                }
            };
            DocumentsApiFactory.cancel({documentId: $routeParams.documentId}, cancelRequest, onSuccess, onError);
        } else {
            $scope.cancelErr = 1;
        }
    };

    var redirectToSignPage = function (patientId, compositionId) {
        $location.path('/dp/patients/' + patientId + '/documents/' + compositionId + '/sign').search({});
    };

    $scope.sign = function (doSign) {
        var callback = function () {
            if (doSign === false) {
                FormReloadService.reloadForm(false);
            }
        };

        DocumentModificationActions.makeFinalAndSign(DocumentCtx.getDocument(), callback, null, doSign);
    };

    $scope.createRelatedDocument = function () {
        //TODO
        //alert(DocumentCtx.getDocument().encounter.id) ;
        MessageService.clearAll();
        var compositionId = $routeParams.documentId;
        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/new/e027').search({parentDocumentId: compositionId});

    };

    $scope.pdfDownload = apiUrl + '/documents2/' + $routeParams.documentId + '/signedPdf';
    $scope.historyListUrl = '/dp/patients/' + $routeParams.patientId + '/records#search';
    $scope.prescriptionsUrl = '/dp/patients/' + $routeParams.patientId + '/documents/' + $routeParams.documentId + '/erx/prescriptions';

    $scope.sameOrg = function () {
        if (!$scope.encounterData) {
            return false;
        }
        var orgId = $scope.encounterData.organizationRef.split('/')[1];
        var practOrgId = $rootScope.currentUser.organizationReference.split('/')[1];

        return orgId === practOrgId;
    };

    $scope.isEncounterActive = function () {
        if (!$scope.encounterActive) {
            return false;
        }
        return $scope.encounterActive; // TODO: check if this temporary fix is enough
    };

    $scope.canCreatePrescritions = function () {
        var ac = $filter('acWrite')('MedicationPrescription') || $filter('acWrite')('Composition', ['57833-6'], null, 'SIGN');
        ac = ac && $scope.sameOrg() && $scope.isEncounterActive();
        return ac && (DocumentCtx.getDocumentType() === 'e003' || DocumentCtx.getDocumentType() === 'e025');
    };

    // Certificates
    $scope.createIncapacityCertificate = function () {
        $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/incapacity/new').search({documentId: $routeParams.documentId});
    };

    $scope.createPregnancyVacationCertificate = function () {
        $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/nesp/new').search({documentId: $routeParams.documentId});
    };

    $scope.canCreateCertificate = function (certificateType) {
        if (((DocumentCtx.getDocumentType() != 'e003') && (DocumentCtx.getDocumentType() != 'e025'))
                || DocumentCtx.isSigned()) {
            return false;
        }

        return hasDummyCertificate(certificateType);
    };

    $scope.getLinkToCreateRelated = function () {
        var patientId = $scope.form.patientId;
        var encounterId = $scope.form.metadata.encounter.id;
        switch ($scope.form.metadata.encounter.event.code) {
            case 'ambulatory':
                return '/dp/patients/' + patientId + '/documents/new/e025?encounterId=' + encounterId;
            case 'inpatient':
                return '/dp/patients/' + patientId + '/documents/new/e003?encounterId=' + encounterId;
            default :
                return '';
        }
    };

    var docTypes = [
        '18842-5', '57133-1', '11488-4', '34108-1',
        'X-LAB-ORDER', '11502-2', 'X-PATH-ORDER', '11526-1',
        '11369-6', '444561001', '270101008', '307930005',
        '274410002', '275674000', '171363009', '184822001',
        '270370004'
    ];
    $scope.canCreateDoc = $filter('acWrite')('Composition', docTypes);

    $scope.canCreateRelatedFromDoc = function () {
        if ($scope.form.metadata) {
            if ($scope.form.metadata.encounter.status !== 'in progress') {
                return false;
            }
            return true;
        } else {
            return false;
        }
    };

    function hasDummyCertificate(type) {
        var hasDummyCertificate = false;

        _.each(DocumentCtx.getDocument().partCertificates.certificates, function (certificate) {
            if (!certificate.certificateNo && (certificate.type == type)) {
                hasDummyCertificate = true;
            }
        });

        return hasDummyCertificate;
    }

    // Sveidra forms
    var hasSveidraForm = true;	// Before we get data from server assume that document has form
    var sveidraFormSearch = {
        formType: DocumentCtx.getDocumentType() == 'e025' ? 'FORM_F025' : 'FORM_F066',
        documentId: 1000089136,
        count: 0
    };
    SveidraApiFactory.getList(sveidraFormSearch, function (result) {
        if (result.total) {
            hasSveidraForm = result.total > 0;
        }
    });

    $scope.canCreateSveidraForm = function () {
        return !hasSveidraForm
                && (DocumentCtx.getDocumentType() == 'e025' && DocumentCtx.isSigned() || DocumentCtx.getDocumentType() == 'e003');
    };

    $scope.createSveidraForm = function () {
        var type = null;
        if (DocumentCtx.getDocumentType() == 'e025') {
            type = 'f025alk';
        } else if (DocumentCtx.getDocumentType() == 'e003') {
            type = 'f066alk';
        }
        if (type) {
            $location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms/' + type + '/new').search({documentId: $routeParams.documentId});
        }
    };

    $scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();
    $scope.hideDocCancel = IntegratedComponentContext.isHideDocCancelAction();


    $scope.canSendToEDelivery = function () {
        return false;
        //disabled while VA file attachment is not working
        //return DpSessionContext.practitioner.eDeliveryAddress && $scope.form && $scope.form.metadata
        //    && $scope.form.metadata.documentStatus === 'FINAL_SIGNED';
    };

    $scope.sendToEDelivery = function () {
        $location.path('/dp/messages/new').search({documentId: $routeParams.documentId});
    };

    $scope.docMenu = {
        main: [
            {
                type: 'main',
                title: 'sign',
                action: $scope.sign,
                isDisabled: function () {
                    return $scope.docSpinnerHelper.loading;
                },
                isVisible: function () {
                    return $scope.form.$resolved && !$scope.isIntegratedComponent && !DocumentCtx.isSigned() && $scope.form.acMeta?.canSign;
                }
            }, {
                type: 'main',
                title: 'doc.download',
                isVisible: function () {
                    return DocumentCtx.isSigned();
                },
                url: $scope.pdfDownload
            }
        ],
        dropDown: [
            {
                type: 'ctx',
                title: 'sign',
                action: $scope.sign,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.form.$resolved && !DocumentCtx.isSigned() && $scope.form.acMeta?.canSign;
                }
            }, {
                type: 'ctx',
                title: 'doc.submit.batch.sign',
                action: function () {
                    $scope.sign(false);
                },
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.form.$resolved && !DocumentCtx.isSigned() && $scope.form.acMeta?.canSign;
                }
            }, {
                type: 'ctx',
                title: 'doc.download',
                isVisible: function () {
                    return DocumentCtx.isSigned();
                },
                url: $scope.pdfDownload
            }, {
                type: 'ctx',
                title: 'edit',
                action: $scope.editDocument,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.form.$resolved && $scope.isEncounterActive() && DocumentCtx.isEditable() && $scope.form.acMeta?.canEdit;
                }
            }, {
                type: 'ctx',
                title: 'edit',
                action: $scope.amendDocument,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.form.$resolved && $scope.isEncounterActive() && DocumentCtx.isAmendable() && $scope.form.acMeta?.canEdit;
                }
            }, {
                type: 'ctx',
                title: 'doc.create',
                action: function () {
                    DocumentTypeSelector.select($scope.form.metadata.encounter);
                },
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.form.$resolved && $scope.canCreateRelatedFromDoc() && $scope.canCreateDoc && $scope.sameOrg($scope.form.metadata.encounter);
                }
            }, {
                type: 'ctx',
                title: 'doc.cre.sve.for',
                action: $scope.createSveidraForm,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.canCreateSveidraForm();
                }
            }, {
                type: 'ctx',
                title: 'cancel',
                action: $scope.enterCancelReason,
                isVisible: function () {
                    return $scope.form.$resolved && $scope.form.acMeta?.canCancel && $scope.isCancelable() && !$scope.hideDocCancel;
                }
            }, {
                type: 'ctx',
                title: 'batch.cancel',
                action: function () {
                    $scope.enterCancelReason(false);
                },
                isVisible: function () {
                    return $scope.form.$resolved && $scope.form.acMeta?.canCancel && $scope.isCancelable() && !$scope.hideDocCancel && $scope.form.documentStatus !== 'CANCELED_UNSIGNED';
                }
            }, {
                type: 'separator'
            }, {
                type: 'ctx',
                title: 'doc.create_incapacity',
                action: $scope.createIncapacityCertificate,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.canCreateCertificate('incapacity');
                }
            }, {
                type: 'ctx',
                title: 'doc.create_nesp',
                action: $scope.createPregnancyVacationCertificate,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && $scope.canCreateCertificate('pregnancy');
                }
            }, {
                type: 'ctx',
                title: 'doc.create_mmp',
                url: $scope.prescriptionsUrl,
                isVisible: function () {
                    return !$scope.isIntegratedComponent && !$scope.canCreatePrescritions(); // added !$scope.canCreatePrescritions() to disable it instead of deleting
                }
            }, {
                type: 'ctx',
                title: 'sendToEdelivery',
                action: $scope.sendToEDelivery,
                isVisible: function () {
                    return  $scope.canSendToEDelivery();
                }
            }
        ]
    };

}]);
