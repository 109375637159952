import { Component } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import {RiskFactorModel} from "../../../doctor/models/risk-factor.model";

@Component({
  selector: 'app-current-risks-list-modal',
  templateUrl: './current-risks-list-modal.component.html',
})
export class CurrentRisksListModalComponent {
    risks: RiskFactorModel[];

    constructor(
        private readonly bsModalRef: BsModalRef
    ) {
    }

    close() {
        this.bsModalRef.hide();
    }
}
