ng1App.controller('doctor.patient.cert.IncapacityViewCtrl', 
    ['$scope', '$routeParams', '$filter', 'EptsCertificatesApiFactory', 'MessageService', 'PatientsApiFactory', 'SessionApiFactory', '$q',
     function ($scope, $routeParams, $filter, EptsCertificatesApiFactory, MessageService, PatientsApiFactory, SessionApiFactory, $q) {
	MessageService.clear();
    
	$scope.$emit('setHelpPage', 'perzireti-nedarbingumo-pazymejima');
    
	// Make sure all data is loaded
	$scope.practitioner = SessionApiFactory.getPractitioner({}, function (practitioner) {
		$scope.practitioner.fullName = _.getFullName(practitioner);
    });
	$scope.organization = SessionApiFactory.getOrganization({});
	$scope.patientData = PatientsApiFactory.get({id: $routeParams.patientId});
	
	$q.all([$scope.organization.$promise, $scope.patientData.$promise]).then(function () {
		var certificates = EptsCertificatesApiFactory.getIncapacityCertificates({personalCode: $scope.patientData.personalCode, 
														birthDate: $scope.patientData.birthDate,
														organizationCode: $scope.organization.jarCode,
														number: $routeParams.id},
				function (incapacityCertificates) {
					$scope.incapacityCertificate = incapacityCertificates[0];
					
					if ($scope.incapacityCertificate.status != 'SIGNED') {
						MessageService.add('warning', $filter('translate')('warn.cer.sign'));
					}
					
					$scope.incapacityCertificate.submissionDate = $routeParams.submissionDate;
				});
		
		$scope.certificateHistoryData = EptsCertificatesApiFactory.getIncapacityCertificateHistory({personalCode: $scope.patientData.personalCode, 
													birthDate: $scope.patientData.birthDate,
													organizationCode: $scope.organization.jarCode});
		
		$q.all([certificates.$promise, $scope.certificateHistoryData]).then(function () {
			if ($scope.certificateHistoryData.latestIncapacityStartDate) {
				$scope.incapacityCertificate.totalIncapacityTime = $scope.getDayCount($scope.certificateHistoryData.latestIncapacityStartDate, 
																						$scope.certificateHistoryData.latestIncapacityEndDate);
			} else {
				$scope.incapacityCertificate.totalIncapacityTime = 0;
			}
		});
	});
	
	var hcpConclusionTypes = [
	    {
	        code: '1',
	        name: $filter('translate')('cerr.inc.hcp_conclusion_type.able-bodied')
	    },
	    {
	    	code: '2',
	    	name: $filter('translate')('cerr.inc.hcp_conclusion_type.temporary_disabled')
	    },
	    {
	    	code: '3',
	    	name: $filter('translate')('cerr.inc.hcp_conclusion_type.directed_to_dcao')
	    }
	];
    
    // Get day count between two dates
	$scope.getDayCount = function (start, end) {
		if (!_.exists(start) || _.isBlank(start) || !_.exists(end) || _.isBlank(end) || (end < start)) {
			return '0';
		}
		
		return Math.floor((end - start) / 86400000) + 1;
	};
	
	$scope.getHcpConclusionDescription = function (code) {
		var description = '';
		
		_.each(hcpConclusionTypes, function (conclusion) {
			if (conclusion.code == code) {
				description = conclusion.name;
			}
		});
		
		return description;
	};
}]);