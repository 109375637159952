<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Cpo.generalForm.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row">
            <app-select
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column justify-content-between"
                labelText="{{'doc.pregnancyDashboard.e096Cpo.generalForm.operationUrgency' | translate}}:"
                labelClass="form-label flex-grow-1 col-form-label"
                id="urgencyOfOperation"
                [fControl]="formGroup.get('urgencyOfOperation')"
                [values]="selectionValuesFromEntity(urgencyOfOperationOptions)"
            ></app-select>
            <app-select
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column justify-content-between"
                labelText="{{'doc.pregnancyDashboard.e096Ge.formLabor.robsonGroup' | translate}}:"
                labelClass="form-label flex-grow-1 col-form-label"
                id="robsonGroup"
                [fControl]="formGroup.get('robsonGroup')"
                [values]="selectionValuesFromEntity(answersVariants?.['robsonGroups'])"
            ></app-select>
            <div class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column justify-content-between">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Cpo.generalForm.thromboembolismRisk' | translate}}:
                </span>
                <div class="fs-3">
                    Maža
                </div>
                <span class="fs-6 fst-italic">
                    {{'doc.pregnancyDashboard.e096Cpo.generalForm.thromboembolismRiskDesc' | translate}}
                </span>
            </div>
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Cpo.generalForm.timeOfDischarge' | translate}}"
                labelClass="form-label flex-grow-1 col-form-label"
                inputId="timeOfDischarge"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="currentTime"
                [fControl]="formGroup.get('timeOfDischarge')"
            ></app-date-picker>
        </div>
        <div class="row">
            <div class="form-group col-12">
                    <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                        {{'pp.mod.dia.dia' | translate}}
                    </span>
                    <ng-container [ngTemplateOutlet]="diagnoseSearch"></ng-container>
            </div>
        </div>
        <div class="row req">
            <div class="form-group col-6 flex-shrink-1">
                <app-select
                    labelText="doc.pregnancyDashboard.e096Cpo.generalForm.cesareanSectionIndication"
                    labelClass="form-label flex-grow-1 col-form-label"
                    id="caesareanSectionIndication"
                    [fControl]="formGroup.get('caesareanSectionIndicationGroup')"
                    [values]="selectionValuesFromEntity(caesareanSectionIndicationOptions)"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('caesareanSectionIndicationGroup')" />
            </div>
            <div class="form-group col-6 flex-shrink-1 d-flex flex-column justify-content-end">
                <ng-container *ngIf="!!getCntrlValue('caesareanSectionIndicationGroup') && getCntrlValue('caesareanSectionIndicationGroup') !== 'Nėščiosios liga'">
                    <app-select
                        [id]="getIndicationDetail()"
                        [fControl]="formGroup.get('caesareanSectionIndicationValue')"
                        [values]="selectionValuesFromEntity(answersVariants?.[getIndicationDetail()])"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('caesareanSectionIndicationValue')" />
                </ng-container>
            </div>
            <div class="form-group col-6">
                <ng-container *ngIf="getCntrlValue('caesareanSectionIndicationGroup') === 'Nėščiosios liga'">
                    <app-input
                        type="textarea"
                        labelText="doc.pregnancyDashboard.e096Cpo.generalForm.pregnancyDisease"
                        [fControl]="formGroup.get('pregnancyDisease')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('pregnancyDisease')" />
                </ng-container>
            </div>
        </div>
        <div class="row req">
            <fieldset class="form-group col-6 col-lg-3">
                    <legend class="float-none form-label flex-grow-1 col-form-label mt-3 fw-bold">
                        {{'doc.pregnancyDashboard.e096Cpo.generalForm.anestesia' | translate}}
                    </legend>
                <app-checkbox
                    *ngFor="let option of answersVariants?.['anesthesia']; let i = index"
                    inputId="anasthesia{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('anasthesia'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'anasthesia')"
                    (checkboxChange)="updateControl(option.name, 'anasthesia')"
                ></app-checkbox>
                <app-form-control-validation [fControl]="formGroup.get('anasthesia')" />
            </fieldset>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-date-picker
                    labelText="doc.pregnancyDashboard.e096Cpo.generalForm.initiationOfAnesthesia"
                    labelClass="form-label flex-grow-1 col-form-label"
                    inputId="initiationOfAnesthesia"
                    suffixIcon="fa fa-calendar"
                    dateInputFormat="YYYY-MM-DD HH:mm"
                    [withTimepicker]="true"
                    [maxDate]="currentTime"
                    [fControl]="formGroup.get('initiationOfAnesthesia')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('initiationOfAnesthesia')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-date-picker
                    labelText="doc.pregnancyDashboard.e096Cpo.generalForm.startOfOperation"
                    labelClass="form-label flex-grow-1 col-form-label"
                    inputId="startOfOperation"
                    suffixIcon="fa fa-calendar"
                    dateInputFormat="YYYY-MM-DD HH:mm"
                    [withTimepicker]="true"
                    [maxDate]="currentTime"
                    [fControl]="formGroup.get('startOfOperation')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('startOfOperation')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-date-picker
                    labelText="doc.pregnancyDashboard.e096Cpo.generalForm.endOfOperation"
                    labelClass="form-label flex-grow-1 col-form-label"
                    inputId="endOfOperation"
                    suffixIcon="fa fa-calendar"
                    dateInputFormat="YYYY-MM-DD HH:mm"
                    [withTimepicker]="true"
                    [maxDate]="currentTime"
                    [minDate]="formGroup.get('startOfOperation')?.value"
                    [fControl]="formGroup.get('endOfOperation')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('endOfOperation')" />
            </div>
        </div>
    </div>
</nhr-record-widget>

<ng-template #diagnoseSearch>
    <div class="row col-6">
        <div class="input-group" *ngIf="editable">
            <input
                [formControl]="searchDiagnose"
                [typeahead]="suggestions$"
                [typeaheadAsync]="true"
                [typeaheadItemTemplate]="customItemTemplate"
                [typeaheadMinLength]="0"
                [typeaheadOptionsLimit]="10"
                [typeaheadWaitMs]="200"
                typeaheadOptionField="fullDisplay"
                (typeaheadOnSelect)="selectDiagnose($event)"
                class="form-control h-[38px]"
            />
            <ng-template #customItemTemplate let-model="item">
                <span>{{model.code}} {{model.name}}</span>
            </ng-template>
            <button class='btn btn-outline-primary btn-sm ms-2' [disabled]="!selectedDiagnose" (click)="addDiagnoseToList()">+&nbsp;Pridėti</button>
        </div>

        <div class="row mx-auto" *ngIf="diagnosisControl?.length">
            <div class="row align-items-center mt-1 px-0" *ngFor="let control of diagnosisControl; let i = index">
                <span class="w-auto">{{control.value.code}} {{control.value.name}}</span>
                <div class="mt-2 flex-shrink-1 w-auto" *ngIf="editable">
                    <button class="btn btn-block text-primary btn-sm fs-4" (click)="deleteDiagnose(i)"><i class="fa fa-trash"></i>&nbsp;{{'doc.dia.dlt' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>