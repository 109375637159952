import { Component, Input, ViewChild } from "@angular/core";
import {
    Chart,
    ChartData,
    ChartDataset,
    ChartOptions,
    ChartType,
} from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UterineGrowthCurve } from "../../../models/uterineGrowthCurve";
import { debounceTime, filter, fromEvent, map, pairwise, startWith } from "rxjs";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
    selector: "app-uterine-growth-curve-diagram",
    templateUrl: "./uterine-growth-curve-diagram.component.html",
    providers: [TranslatePipe]
})
export class UterineGrowthCurveDiagramComponent {
    @Input() set data(value: UterineGrowthCurve[]) {
        console.log(value)
        this.loadData(value);
    };

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    chartType: ChartType = 'line';
    chartLabels: string[] = this.generateArray(16, 42);
    chartData: ChartData = {
        datasets: [],
        labels: this.chartLabels,
    }
    chartOptions: ChartOptions = {
        elements: {
            point: {
                radius: 0
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    autoSkip: false,
                    color: 'white',
                },
            },
            xAxes: {
                position: 'top',
                ticks: {
                    autoSkip: false,
                    color: 'white',
                },
            },
            y: {
                grid: {
                    display: true
                },
                ticks: {
                    padding: 5,
                    autoSkip: false,
                    stepSize: 0.5,
                    color: 'white',
                    callback: (v) => (Number.isInteger(v) ? v : '')
                },
                min: 10,
                max: 45,
            },
            yAxes: {
                position: 'right',
                ticks: {
                    padding: 40,
                    autoSkip: false,
                    stepSize: 0.5,
                    color: 'white',
                    callback: (v) => (Number.isInteger(v) ? v : '')
                },
                min: 10,
                max: 45,
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                align: 'right',
                formatter: (v, context) => {
                    return context.datasetIndex !== 5 ? context.dataIndex === context.dataset.data.length - 1 ? context.dataset.label : '' : '';
                }
            },
            tooltip: {
                mode: 'index',
                callbacks: {
                    title: (tooltipItems) => {
                        return `${this.translate.transform('doc.pregnancyDashboard.uterineDiagram.tooltip')} ${tooltipItems[0].label}`
                    },
                    label(tooltipItem): string | string[] | void {
                        return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                    }
                },
            },
        },
        layout: {
            padding: {
                right: -27,
                left: 5,
            },
        }

    };

    private staticLineData: ChartDataset[] = [
        { data: [18.5, 19.5, 20.5, 21.5, 22.6, 23.6, 24.7, 25.7, 26.8, 27.8, 28.9, 29.9, 30.9, 31.9, 32.9, 33.9, 34.8, 35.8, 36.7, 37.6, 38.5, 39.3, 40.2, 41, 41.8, 42.5, 43.2], label: '97th', borderColor: 'red', pointBackgroundColor: 'red', fill: false, borderWidth: 1, hitRadius: 0, order: 2 },
        { data: [17.6, 18.6, 19.6, 20.7, 21.7, 22.7, 23.8, 24.8, 25.8, 26.8, 27.9, 28.9, 29.9, 30.9, 31.8, 32.8, 33.8, 34.7, 35.6, 36.5, 37.3, 38.2, 39, 39.8, 40.5, 41.3, 42], label: '90th', borderColor: 'black', pointBackgroundColor: 'black', fill: false, borderWidth: 1, hitRadius: 0, order: 2 },
        { data: [15.8, 16.8, 17.8, 18.8, 19.8, 20.8, 21.8, 22.8, 23.8, 24.7, 25.7, 26.7, 27.7, 28.6, 29.6, 30.5, 31.4, 32.3, 33.2, 34, 34.9, 35.7, 36.5, 37.2, 38, 38.7, 39.3], label: '50th', borderColor: 'green', pointBackgroundColor: 'green', fill: false, borderWidth: 2, hitRadius: 0, order: 2 },
        { data: [14, 14.9, 15.9, 16.9, 17.8, 18.8, 19.8, 20.7, 21.7, 22.7, 23.6, 24.6, 25.5, 26.4, 27.3, 28.2, 29.1, 30, 30.8, 31.6, 32.4, 33.2, 33.9, 34.7, 35.4, 36, 36.9], label: '10th', borderColor: 'black', pointBackgroundColor: 'black', fill: false, borderWidth: 1, hitRadius: 0, order: 2 },
        { data: [13.2, 14.1, 15, 16, 16.9, 17.9, 18.8, 19.8, 20.7, 21.7, 22.6, 23.5, 24.5, 25.4, 26.3, 27.1, 28, 28.9, 29.7, 30.5, 31.3, 32, 32.8, 33.5, 34.1, 34.8, 35.4], label: '3rd', borderColor: 'red', pointBackgroundColor: 'red', fill: false, borderWidth: 1, hitRadius: 0, order: 2 },
    ];
    private pointsData: ChartDataset = {
        data: [],
        label: `${this.translate.transform('doc.pregnancyDashboard.uterineDiagram.distance')} (cm)`,
        pointBackgroundColor: 'blue',
        pointRadius: 5,
        fill: false,
        type: "scatter",
        order: 1
    };
    private dotsArrayData = this.generateArray(16, 42, 0);

    constructor(
        private translate: TranslatePipe
    ) {
        Chart.register(ChartDataLabels);
        fromEvent(window, 'resize')
            .pipe(
                startWith(window.innerWidth),
                debounceTime(200),
                map(() => window.innerWidth),
                pairwise(),
                filter(([prev, cur]) => !prev || prev > cur),
                takeUntilDestroyed()
            )
            .subscribe(() => this.fitChart());
    }

    private generateArray<T>(start, end, specificValue?: T): Array<string | T> {
        return Array.from({length: end - start + 1}, (_, index) => specificValue ?? String(start + index));
    }

    private addPoint(obj: UterineGrowthCurve): void {
        if (obj.week < 16 || obj.week > 42) {
            return;
        }

        const index = obj.week - 16;
        this.dotsArrayData[index] = obj.distance
    }

    private loadData(data: UterineGrowthCurve[]) {
        if (!data?.length) {
            return;
        }
        data.forEach(item => {
            this.addPoint(item);
        })
        this.pointsData.data = this.dotsArrayData as number[];
        this.chart.data.datasets = [...this.staticLineData, this.pointsData];
        this.chart?.update();
    }

    private fitChart() {
        this.chart?.render();
    }
}
