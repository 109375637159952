ng1App.controller('doctor.patient.CertificatesHistoryCtrl',
        ['$scope', '$routeParams', 'CertificatesApiFactory', 'EncountersApiFactory', 'DocumentsApiFactory', 'CertificateService', 'CertificatesViewService',
        function ($scope, $routeParams, CertificatesApiFactory, EncountersApiFactory, DocumentsApiFactory, CertificateService, CertificatesViewService) {
            $scope.goBack = function () {
                window.history.back();
            };

            $scope.certificate = CertificatesApiFactory.getForHistoryPreview({compositionId: $routeParams.id, historyId: $routeParams.historyId}, function (data) {
                CertificateService.populateCommonData($scope, data);

                // Additional data
                CertificatesViewService.fillDataByType($scope, data.type, $routeParams.patientId, $routeParams.id);
                $scope.encounter = EncountersApiFactory.get({id: $scope.certificate.encounterId});

                DocumentsApiFactory.documentHistory({compositionId: $routeParams.id}, function (data) {
                    angular.forEach(data.items, function (i) {
                        if (i.historyId == $routeParams.historyId) {
                            $scope.itemType = i.type;
                        }
                    });
                });
            });

        }]);
