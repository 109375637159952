import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../../api/api-v2-doc-pregnancy-dashboard";
import { RouteParams } from "../../../../../ng1.routeParams";
import { Procedure, Research } from "../../../models/proceduresDuringPregnancy.model";
import {
    Procedures,
    ProceduresViewHeaderTitles,
    Researches,
    ResearchesViewHeaderTitles,
} from "../procedures-during-pregnancy.constants";

@Component({
    selector: "app-procedures-during-pregnancy-view",
    templateUrl: "./procedures-during-pregnancy-view.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProceduresDuringPregnancyViewComponent {
    @Input() closed?: boolean;

    public readonly Procedures = Procedures;
    public readonly Researches = Researches;
    public researches = new BehaviorSubject<{ name: string; headerData: string[]; data: Research[] }[]>([]);
    public procedures = new BehaviorSubject<{ name: string; headerData: string[]; data: Procedure[] }[]>([]);

    private patientId: string;
    private subscription: Subscription = new Subscription();

    constructor(private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard, private params: RouteParams) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.subscription.add(this.getResearchesAndProceduresDuringPregnancy());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        localStorage.clear();
    }

    public groupByName(data: any[], research?: boolean): { name: string; headerData: string[]; data: any[] }[] {
        const groupedData: { [name: string]: any[] } = {};

        data.forEach((item) => {
            const name = item.name;
            if (!groupedData[name]) {
                groupedData[name] = [];
            }

            groupedData[name].push({
                ...item,
                enteredBySpecialist:
                    "VŠĮ Antakalnio poliklinika | Slaugos ir akušerijos specialistas | Jonas Jonaitis | 2023-03-14  15:36",
            });
        });

        return Object.keys(groupedData).map((name) => ({
            name,
            headerData: research ? ResearchesViewHeaderTitles[name] : ProceduresViewHeaderTitles[name],
            data: groupedData[name],
        }));
    }

    public headerStyle(columnCount: number): string {
        let count = columnCount;

        if (count === 5 || count > 6) {
            count = 6;
        }

        const columnSize = 12 / count;
        return `col-xxl-${columnSize} col-xl-${columnSize} col-lg-${columnSize} col-md-${columnSize} col-sm-${columnSize} align-middle`;
    }

    private getResearchesAndProceduresDuringPregnancy(): Subscription {
        return this._apiV2PregnancyDashboard
            .getResearchesAndProceduresDuringPregnancy(this.patientId)
            .subscribe((x) => {
                const savedData = localStorage.getItem("researchesAndProceduresDuringPregnancy");
                let data = null;
                if (savedData) {
                    data = JSON.parse(savedData);
                } else {
                    data = x;
                }

                this.researches.next(this.groupByName(data.researches, true));
                this.procedures.next(this.groupByName(data.procedures));
            });
    }
}
