import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    controlIsRequired,
    generateSelectionValuesFromEntity,
    listenAndChangeStatusOfControl
} from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-nt-maternity-care-plan',
    templateUrl: './e096-nt-maternity-care-plan.component.html',
})
export class E096NtMaternityCarePlanComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    formGroup: FormGroup;
    cesareanSectionIndicationsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pakartotinė operacija'
        },
        {
            id: '1',
            code: '1',
            name: 'Distocija'
        },
        {
            id: '2',
            code: '2',
            name: 'Vaisiaus būklės ypatumai'
        },
        {
            id: '3',
            code: '3',
            name: 'Vaisiaus sėdmeninė pirmeiga'
        },
        {
            id: '4',
            code: '4',
            name: 'Kitos gimdymo indikacijos'
        }
    ];

    controlRequired = controlIsRequired.bind(this) as typeof controlIsRequired;
    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;

    get cesareanSectionIndicationsValues(): string[] {
        switch (this.formGroup?.get('cesareanSectionIndications')?.value) {
            case 'Pakartotinė operacija':
                return this.selectionValuesFromEntity(this.answersVariants?.['repeatOperation']);
            case 'Distocija':
                return this.selectionValuesFromEntity(this.answersVariants?.['dystocia']);
            case 'Vaisiaus būklės ypatumai':
                return this.selectionValuesFromEntity(this.answersVariants?.['fetusConditionIssues']);
            case 'Vaisiaus sėdmeninė pirmeiga':
                return this.selectionValuesFromEntity(this.answersVariants?.['fetusBreechPresentation']);
            case 'Kitos gimdymo indikacijos':
                return this.selectionValuesFromEntity(this.answersVariants?.['otherIndications']);
        }
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            childbirth: this.fb.control(null, [Validators.required]),
            deliveryType: this.fb.control({value:null, disabled: true}),
            cesareanSectionIndications: this.fb.control({value:null, disabled: true}),
            cesareanSectionIndicationsValue: this.fb.control({value:null, disabled: true}),
            pregnancyDisease: this.fb.control({value:null, disabled: true})
        });

        this.changeStatusOfControl({
            controlNameForListen: 'childbirth',
            controlNameForUpdate: 'deliveryType',
            enableValue: 'Planuojamas',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'deliveryType',
            controlNameForUpdate: 'cesareanSectionIndications',
            enableValue: 'Cezario pjūvio operacija',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'deliveryType',
            controlNameForUpdate: 'cesareanSectionIndicationsValue',
            enableValue: 'Cezario pjūvio operacija',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'cesareanSectionIndicationsValue',
            controlNameForUpdate: 'pregnancyDisease',
            enableValue: 'Nėščiosios liga',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }
}
