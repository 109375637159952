import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../models/entity.model';
import {
    getControlValue,
    listenAndChangeStatusOfControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
import { map, Observable } from 'rxjs';
import { ApiV2Inr } from '../../../services/api-v2-inr';
@Component({
    selector: "app-inr-drugs-caused-inr-subform",
    templateUrl: "./inr-drugs-caused-inr-subform.component.html",
})
export class InrDrugsCausedInrSubformComponent {
    @Input() editable: boolean = true;

    drugsSelectionVariants: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pasirinkti vaistinį preparatą iš pardavimo dokumento'
        },
        {
            id: '1',
            code: '1',
            name: 'Pasirinkti vaistinį preparatą iš katalogo'
        },
        {
            id: '2',
            code: '2',
            name: 'Įvesti pačiam vaistinio preparato duomenis'
        }
    ];
    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];

    formGroup: FormGroup;
    drugsSelectionVariant: FormControl = this.fb.control(null);
    maxDate = new Date();

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    getDrugsObs = this.getDrugs.bind(this);
    constructor(
        private fb: FormBuilder,
        private apiV2Inr: ApiV2Inr
    ) {
        this.formGroup = this.fb.group({
            medicinalProductName: this.fb.control(null, [Validators.required]),
            activeSubstanceName: this.fb.control(null, [Validators.required]),
            pharmaceuticalForm: this.fb.control(null),
            strength: this.fb.control(null),
            administrationMethod: this.fb.control(null),
            dailyDose: this.fb.control(null),
            medicinalProductSerialNumber: this.fb.control(null),
            startedUsingDate: this.fb.control(null),
            endUsingDate: this.fb.control(null),
            therapeuticIndications: this.fb.control(null),
            reactionCausedByOtherDrugs: this.fb.control(null),
        });
    }

    getDrugs(query: string): Observable<any> {
        return this.apiV2Inr.getMockDrugs({q: query}).pipe(
            map(item => item
                .map(p => {
                    p['fullDisplay'] =`${ p.medicinalProductName } ${ p.dailyDose }mg/ml (${ p.administrationMethod }) ${ p.activeSubstanceName } ${ p.medicinalProductSerialNumber }`;
                    return p;
                })
            ))
    }

    selectDrug(drug: any) {
        this.formGroup.patchValue(drug);
    }
}
