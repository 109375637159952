<div>
    <app-title [backPath]="backPath" showFilterButton="true">
        Paciento slaugos ir priežiūros plano istorija
    </app-title>
    <nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
        [title]="'Paciento slaugos ir priežiūros plano istorija' | translate" id="nursingAndCarePlanHistory">
        <custom-search before-title [name]="'quick-search'" [isAdvanced]="true"
            (filterService)="loadNursingAndCarePlanHistoryList($event)">
            <nursingAndCarePlanHistory-filter
                (onSearch)="loadNursingAndCarePlanHistoryList($event)"></nursingAndCarePlanHistory-filter>
        </custom-search>
        <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid"
            [columnDefs]="nursingAndCarePlanHistoryColDefs" [defaultColDef]="defaultColDef"
            [rowData]="nursingAndCarePlanHistoryData$ | async" [rowSelection]="'multiple'"
            (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
            [domLayout]="'autoHeight'" [context]="getContext()" [gridOptions]="gridOptions" [pagination]="true"
            [paginationPageSize]="10" [suppressPaginationPanel]="true" />
    </nhr-record-widget>
    <ag-pagination-bar></ag-pagination-bar>
</div>