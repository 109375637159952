import {Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {FormControl} from "@angular/forms";
import {EntityModel} from "../../models/entity.model";
import {distinctUntilChanged, map, Observable, Observer, Subscription, switchMap, take} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ApiV2Diagnosis} from "../../services/api-v2-diagnosis";
import {ApiV2Entities} from "../../services/api-v2-entities";
import {RouteParams} from "src/app/ng1.routeParams";
import {DirectiveReset} from "../../services/directive-reset";
import {EditDiagnoseModalComponent} from "./edit-diagnose-modal/edit-diagnose-modal.component";
import {DiagnosesListModalComponent} from "./diagnoses-list-modal/diagnoses-list-modal.component";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {DiagnosisModel, ExtendedDiagnosisModel} from "../../models/diagnosis.model";
import {DiagnosisByEncounterComponent} from "./diagnoses-by-encounter/diagnoses-by-encounter";
import {Form0255aStatusModel} from "../../models/form-025-5a-status.model";
import {ApiV2Documents} from "../../../../api/api-v2-documents";
import {DocumentTagEnum} from "../../models/document-tag.enum";
import moment from "moment";
import {MessageService} from "../../services/utils/message.service";
import {TranslateService} from "@ngx-translate/core";
import {DiagnosisTypeEnum} from "../../models/diagnosisType.enum";
import {ApiV2Session} from "../../../../api/api-v2-session";
import {ApiV2Patients} from "../../services/api-v2-patients";
import {DpSessionContext} from "../../../doctor/services/dp-session-context.service";
import {DateTypeEnum} from "../../models/date-type.enum";

@Component({
    selector: 'app-diagnoses-form',
    templateUrl: './diagnoses-form.component.html'
})
export class DiagnosesFormComponent implements OnInit, OnDestroy {
    @Output() validationFailed = new EventEmitter<boolean>();
    @Input() documentType: string;
    @Input() singleDiagnosis: boolean;
    @Input() currentDiagnoses: DiagnosisModel[];
    @Input() isRequiredFieldInForm: boolean;
    @Output() currentDiagnosesChange = new EventEmitter<DiagnosisModel[]>();
    @Input() viewOnly: boolean = false; // hides card buttons and typeahead search field
    @Input() noType: boolean = false;
    @Input() notIncludedDiagnosis: boolean = false; // boolean inside directive that requires not included diagnosis list
    @Input() onlyOneLeadingTypeDiagnosis: boolean = false;
    @Input() changedTitleText: string; // if in directive input a new name is declared it replaces diagnosis
    @Input() dateFormatType: DateTypeEnum = DateTypeEnum.ISO;
    diagnosesForNotIncluded: ExtendedDiagnosisModel[];
    messageRegion: string = 'diagnosis-region';
    searchDiagnose: FormControl<string | null> = new FormControl(null);
    searchErr: string;
    types: EntityModel[] = [];
    patientId: string;
    encounterId: string;
    practitionerId: string;
    form0255aStatus: Form0255aStatusModel = { mustExist: false };
    suggestions$: Observable<EntityModel[]>;
    private subscription: Subscription = new Subscription();

    constructor(
        private modalService: BsModalService,
        private apiV2Diagnosis: ApiV2Diagnosis,
        private apiV2Patients: ApiV2Patients,
        private apiV2Entities: ApiV2Entities,
        private apiV2Session: ApiV2Session,
        private apiV2Documents: ApiV2Documents,
        private params: RouteParams,
        private destroyRef: DestroyRef,
        private directiveReset: DirectiveReset,
        private messageService: MessageService,
        private translateService: TranslateService,
        private context: DpSessionContext
    ) {
    }

    ngOnInit(): void {
        this.encounterId = this.params.encounterId;
        this.patientId = this.params.patientId;

        if(this.notIncludedDiagnosis){
            this.apiV2Session.getPractitioner().subscribe(practitioner => {
                this.practitionerId = practitioner.id;
                this.loadDiagnoses(this.patientId, this.practitionerId, this.encounterId);
            });
        }

        if(!this.currentDiagnoses && !this.viewOnly) {
            this.subscription.add(this.subscribeCurrentDiagnoses());
        }

        this.subscription.add(this.subscribeConditionDiagnosisType());
        this.listenDiagnoseSearchEvent();

        this.directiveReset.reset$.subscribe(() => {
            this.resetForm();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    openAddDiagnoseModal(diagnoseCode: EntityModel) {
        const initialState = {
            diagnose: {
                code: diagnoseCode
            } as DiagnosisModel,
            isEditInitiated: false
        };
        const modalRef = this.modalService.show(EditDiagnoseModalComponent, {
            initialState,
            animated: true,
            class: 'modal-xl'
        });

        modalRef.content!.onClose.subscribe((result: DiagnosisModel) => {
            this.makeLeadingDiagnoseTypeAsUnique(result);
            this.currentDiagnoses.push(result);
            this.emitCurrentDiagnoses();
            this.validationFailed.emit(true);

            if (result.type.code === this.types[0].code && this.documentType === 'e003') {

                this.currentDiagnoses = this.currentDiagnoses.map(diagnose => {
                    if (diagnose.code.id !== result.code.id && diagnose.type.code === this.types[0].code) {
                        return { ...diagnose, type: this.types[1] };
                    }
                    return diagnose;
                });
            }
        });
    }

    openEditDiagnoseModal(i: number) {
        const initialState = {
            diagnose: this.currentDiagnoses[i],
            isEditInitiated: true
        };
        const ref = this.modalService.show(EditDiagnoseModalComponent, {
            initialState,
            animated: true,
            class: 'modal-xl'
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: DiagnosisModel) => {
            this.currentDiagnoses[i] = {...result};
            this.emitCurrentDiagnoses();
            this.validationFailed.emit(true);
            if (result.type.code === this.types[0].code && this.documentType === 'e003') {
                this.currentDiagnoses = this.currentDiagnoses.map(diagnose => {
                    if (diagnose.code.id !== result.code.id && diagnose.type.code === this.types[0].code) {
                        return {...diagnose, type: this.types[1]};
                    }
                    return diagnose;
                });
            }

        });
    }

    openUploadDiagnoseModal() {
        const ref = this.modalService.show(DiagnosesListModalComponent, {
            animated: true,
            class: 'modal-xl'
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: any[]) => {
            this.currentDiagnoses.push(...result);
            this.checkForFormE0255a();
        });
    }

    delete(i: number) {
        this.currentDiagnoses.splice(i, 1);
        this.emitCurrentDiagnoses();
        if(this.currentDiagnoses.length === 0){
            this.validationFailed.emit(false);
        }
    }

    selectDiagnose(event: TypeaheadMatch) {
        this.openAddDiagnoseModal(event.item);
    }

    private listenDiagnoseSearchEvent(): void {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.searchDiagnose.value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                const request = !query
                    ? this.apiV2Entities.getMostUsedDiagnoses({count: 10})
                    : this.apiV2Entities.getEntitiesList('tlk-10-am', {searchCriteria: query});

                return request.pipe(map(items => items.map(p => {
                    p['displayValue'] = `${p.code} ${p.name}`;
                    return p;
                })))
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private subscribeCurrentDiagnoses(){
        this.currentDiagnoses = [];
    }

    private subscribeConditionDiagnosisType(): Subscription {
        return this.apiV2Entities.getEntitiesList('condition-diagnosis-type')
            .subscribe(res => {
                this.types = res;
            })
    }

    private emitCurrentDiagnoses(): void {
        this.currentDiagnosesChange.emit(this.mapDiagnosesModel(this.currentDiagnoses));
        this.checkForFormE0255a();
        this.validateDiagnosesTypes();
      }

      resetForm(): void {
        this.currentDiagnoses = [];
        this.currentDiagnosesChange.emit(this.currentDiagnoses);
    }

    openUploadNotIncludedDiagnosisModal() {
        const modalRef: BsModalRef = this.modalService.show(DiagnosisByEncounterComponent, {
            animated: true,
            class: 'modal-xl modal-dialog-scrollable ngx-bootstrap-modal',
            initialState: {
                selectAll: true,
                currentDiagnoses: this.diagnosesForNotIncluded
            }
        });

        modalRef.content.onClose.subscribe((selectedDiagnoses: DiagnosisModel[]) => {
            this.currentDiagnoses.push(...selectedDiagnoses);
            this.emitCurrentDiagnoses();
        });
    }

    checkForFormE0255a(): void {
        let mustExist: boolean = false;
        let docExist: boolean = undefined;
        let date: string = undefined;
        this.apiV2Entities.getEntitiesList('asis-questionnaire-list').subscribe(res => {
            mustExist = this.currentDiagnoses.find(diagnosis => {
                return res.map(r => r.code).find(code => code === diagnosis.code?.code) !== undefined;
            }) !== undefined;

            this.messageService.remove(this.form0255aStatus.message, this.messageRegion);
            if(mustExist){
                this.apiV2Documents.searchForList({encounterId: this.encounterId, withDrafts: false}).subscribe(res => {
                    const documentF025: any = res.find(r => {
                        return r.docType === DocumentTagEnum.f025
                    });

                    docExist = documentF025 !== undefined;
                    if(docExist){
                        date = moment(documentF025.date).format('YYYY-MM-DD');
                    }

                    this.form0255aStatus = {
                        mustExist: mustExist,
                        exist: docExist,
                        date: date,
                        message: docExist ?
                            `${this.translateService.instant('form.025_5a.message.success')}${date}` :
                            this.translateService.instant('form.025_5a.message.warning')
                    };

                    docExist ?
                        this.messageService.success(this.form0255aStatus.message, this.messageRegion) :
                        this.messageService.warning(this.form0255aStatus.message, this.messageRegion);
                });
            }
        });
    }

    makeLeadingDiagnoseTypeAsUnique(newDiagnose: DiagnosisModel) {
        if (this.onlyOneLeadingTypeDiagnosis && newDiagnose.type.code === DiagnosisTypeEnum.PRIMARY) {
            const leadingType = this.types.find(t => t.code === DiagnosisTypeEnum.PRIMARY);
            const followingType = this.types.find(t => t.code === DiagnosisTypeEnum.SECONDARY);
            if (leadingType !== undefined && followingType !== undefined) {
                this.currentDiagnoses = this.currentDiagnoses.map(d => {
                    if (d.type.code === DiagnosisTypeEnum.PRIMARY) {
                        d.type = followingType
                    }
                    return d;
                });
            }
        }
    }

    validateDiagnosesTypes() {
        if (this.onlyOneLeadingTypeDiagnosis) {
            const leadingDiagnoses: number = this.currentDiagnoses.filter(d => d.type.code === DiagnosisTypeEnum.PRIMARY).length;
            const moreLeadingMessage: string = "Tik viena iš įvestų diagnozių gali būti pagrindinė!";
            const noLeadingMessage: string = "Viena iš įvestų diagnozių privalo būti pagrindinė!";
            if (this.hasDiagnosesErrors()) {
                this.messageService.remove(moreLeadingMessage, this.messageRegion);
                this.messageService.remove(noLeadingMessage, this.messageRegion);
            }

            if (leadingDiagnoses > 1) {
                this.messageService.error(moreLeadingMessage, this.messageRegion);
            } else if (leadingDiagnoses === 0 && this.currentDiagnoses.length !== 0) {
                this.messageService.error(noLeadingMessage, this.messageRegion);
            }
        }
    }

    hasDiagnosesErrors(): boolean {
        return this.messageService.hasRegionErrors(this.messageRegion);
    }

    loadDiagnoses(patientId: string, practitionerId: string, encounterId: string): void {
        this.apiV2Patients.getDiagnosisByEncounter(patientId, practitionerId, encounterId)
            .subscribe(response => {
                if (response.length === 0) {
                    return;
                }

                this.diagnosesForNotIncluded = response.map(item => {
                    const diagnosis = item.diagnose;
                    return {
                        ...diagnosis,
                        code: diagnosis.code ? this.mapToEntityModel(diagnosis.code) : {
                            id: diagnosis.id,
                            code: diagnosis?.diagnosisCode,
                            name: diagnosis?.diagnosisText,
                            displayValue: diagnosis?.diagnosisText
                        } as EntityModel,
                        clinicalStatus: this.mapToEntityModel(diagnosis.clinicalStatus),
                        category: this.mapToEntityModel(diagnosis.category),
                        verificationStatus: this.mapToEntityModel(diagnosis.verificationStatus),
                        severity: this.mapToEntityModel(diagnosis.severity),
                        type: this.mapToEntityModel(diagnosis.type),
                        selected: true // Maintain this for UI selection logic
                    } as ExtendedDiagnosisModel;
                });

                this.openUploadNotIncludedDiagnosisModal();
            });
    }

    private mapToEntityModel(entity: any): EntityModel {
        if (!entity) {
            return {
                id: '',
                code: '',
                name: '-',
                displayValue: '',
                result: []
            };
        }
        return {
            id: entity.id,
            code: entity.code,
            name: entity.name || entity.displayValue,
            displayValue: entity.displayValue || entity.name || '', // Provide default value if displayValue is undefined
            result: entity.result ? entity.result.map(res => this.mapToEntityModel(res)) : []
        };
    }

    private mapDiagnosesModel(diagnoses: DiagnosisModel[]) {
        return diagnoses.map(d => {
            d.code = this.mapToEntityModel(d.code);
            d.type = this.mapToEntityModel(d.type);
            d.category = this.mapToEntityModel(d.category);
            d.clinicalStatus = this.mapToEntityModel(d.clinicalStatus);
            d.severity = this.mapToEntityModel(d.severity);
            d.verificationStatus = this.mapToEntityModel(d.verificationStatus);
            d.onsetPeriodStart = this.getDateByType(d.onsetPeriodStart);
            d.onsetPeriodEnd = this.getDateByType(d.onsetPeriodEnd);
            d.recordedDate = this.getDateByType(d.recordedDate);
            d.dateAsserted = this.getDateByType(d.dateAsserted);
            return d;
        });
    }

    private getDateByType(date: string | number | undefined): string | number | undefined {
        if (!date) {
            return date;
        }
        return this.dateFormatType === DateTypeEnum.ISO ? date : moment(date).valueOf();
    }
}
