<div class="d-flex align-items-center gap-2">
    <button *ngIf="!path" type="button" class="btn btn-link lh-1 p-0 text-decoration-none text-nowrap {{linkClass}}" (click)="goBack()">
        ← <span class="text-decoration-underline">{{(title ? title : 'return') | translate}}</span>
    </button>
    <a *ngIf="path" class="btn btn-link lh-1 p-0 text-decoration-none text-nowrap {{linkClass}}" [href]="path">
        ← <span class="text-decoration-underline">{{(title ? title : 'return') | translate}}</span>
    </a>
    <ng-container *ngIf="badgeText">
        <span class="badge rounded-pill{{ badgeClass ? ' ' + badgeClass : '' }}">{{badgeText}}</span>
    </ng-container>
    <ng-container *ngIf="subTitle">
        <span class="vr" *ngIf="!badgeText"></span>
        <ng-container *ngIf="Array.isArray(subTitle)">
            <span *ngFor="let titleText of subTitle" class="text-secondary small">{{titleText | translate}}</span>
        </ng-container>
        <ng-container *ngIf="!Array.isArray(subTitle)">
            <span class="text-secondary small">{{subTitle | translate}}</span>
        </ng-container>
    </ng-container>
</div>
