import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { Formf025Model } from "src/app/shared/models/f025_5.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2f025 extends ConfigService {

    path = this.apiUrl + '/f025';

    getF025FormView(id: string): Observable<Formf025Model> {
        return this.http.get<Formf025Model>(this.path + '/view/' + id);
    }

    submitf025Form(payload: Formf025Model, id: string): Observable<Formf025Model> {
        return this.http.post<Formf025Model>(this.path + '/create/' + id, payload);
    }

    savef025Form(payload: Formf025Model, id: string): Observable<Formf025Model> {
        return this.http.post<Formf025Model>(this.path + '/save/' + id, payload);
    }

    signf025Form(payload: Formf025Model, id: string): Observable<Formf025Model> {
        return this.http.post<Formf025Model>(this.path + '/sign/' + id, payload);
    }

    signingSubmitf025Form(payload: Formf025Model, id: string): Observable<Formf025Model> {
        return this.http.post<Formf025Model>(this.path + '/signingSubmit/' + id, payload);
    }

    sealf025Form(payload: Formf025Model, id: string): Observable<Formf025Model> {
        return this.http.post<Formf025Model>(this.path + '/seal/' + id, payload);
    }
}