ng1App.service('CommonFunctionService', 
    ['$q', '$location', '$anchorScroll',
     function ($q, $location, $anchorScroll) {
// for common functions that require angular modules
	
	this.getTrueEntityValue = function (object, property, list) {
		if (!object) {
			return;
		}
		$q.all([object.$promise, list.$promise]).then(function () {
			if (object[property]) {
				_.each(list, function (value) {
					if (object[property].code === value.code) {
						object[property] = value;
					}
				});
			}
		});
	};
	
	this.gotoAnchor = function (newHash) {
    	if ($location.hash() !== newHash) {
    		var old = $location.hash();
            $location.hash(newHash);
            $anchorScroll();
                
            $location.hash(old);;
    	} else {
    		$anchorScroll();
    	}
	};
}]);