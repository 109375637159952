import { Component, DestroyRef, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TestQuestions } from '../../../../models/risk-factor.model';
import { AuditCQuestions } from './audit-c';
import { FagerstormQuestions } from './fagerstorm';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-risk-test',
    templateUrl: './risk-test.component.html'
})
export class RiskTestComponent {
    @Input() set test(value: 'AUDIT-C' | 'Fagerstorm' | null) {
        if (!value) return;
        this._test = value;
        this.questions = this.generateIdForQuestions(value === 'AUDIT-C' ? AuditCQuestions : FagerstormQuestions);
        this.createForm();
    }
    @Output() onTestComplete = new EventEmitter<number>();
    formGroup: FormGroup;
    questions: Array<TestQuestions & {id: string}>;
    private _test: 'AUDIT-C' | 'Fagerstorm';
    private cacheSumOfAnswers: {[key: string]: number} = {};
    private formValueChangesSubscription: Subscription;
    constructor(
        private fb: FormBuilder,
        private destroyRef: DestroyRef
    ) {
    }

    isRequiredControl(id: string) {
        return this.formGroup.get(id).hasValidator(Validators.required);
    }

    private createForm() {
        const formGroupConfig = {};

        this.questions.forEach(question => {
            formGroupConfig[question.id] = this.fb.control('', [Validators.required]);
        });

        this.formGroup = this.fb.group(formGroupConfig);

        this.listenForSpecialConditions();
    }

    private calculateScore(): number {
        let score = 0;

        this.questions.forEach(question => {
            const control = this.formGroup.get(question.id);

            const answer = question.answers.find(answer => answer.answer === this.formGroup.get(question.id).value);
            if (answer) {
                score += answer.score;

                if (this.formValueChangesSubscription) {
                    this.formValueChangesSubscription.unsubscribe();
                }

                // control.disable();

                this.listenForSpecialConditions();
            }
        })

        return score;
    }

    private generateIdForQuestions(questions: TestQuestions[]): Array<TestQuestions & {id: string}> {
        return questions.map((question, i) => ({...question, id: this._test.toLocaleLowerCase() + `-${i}`}))
    }

    private listenForSpecialConditions() {
            this.formValueChangesSubscription = this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(() => {
                    this.auditCConditions();

                    const score = this.calculateScore();

                    if (this.formGroup.valid || this.formGroup.disabled) {
                        this.onTestComplete.emit(score || 0);
                        return;
                    }
                });
    }

    private sumOfAnswers(controlsId: string[]): number | boolean {
        if (this.cacheSumOfAnswers.hasOwnProperty(controlsId.join('-'))) {
            return this.cacheSumOfAnswers[controlsId.join('-')];
        }
        let sum = 0;

        for(const id of controlsId) {
            const control = this.formGroup.get(id);

            if (!control || !control.value) {
                return null;
            }

            const question = this.questions.find(q => q.id === id);
            const answer = question?.answers.find(answer => answer.answer === this.formGroup.get(id).value);
            if (answer) {
                sum += answer.score;
            }
        }

        this.cacheSumOfAnswers[controlsId.join('-')] = sum;
        return sum;
    }

    private clearValidatorFromControls(controlsId: string[]): void {
        this.formValueChangesSubscription.unsubscribe();

        controlsId.forEach(id => {
            this.formGroup.get(id).clearValidators();
            // this.formGroup.get(id).disable();
            this.formGroup.updateValueAndValidity({emitEvent: false});
        });

        this.listenForSpecialConditions();
    }

    private auditCConditions(): void {
        if (this._test === 'AUDIT-C') {
            if (this.formGroup.get('audit-c-0').value === 'Niekada') {
                this.clearValidatorFromControls(['audit-c-1', 'audit-c-2', 'audit-c-3', 'audit-c-4',
                    'audit-c-5', 'audit-c-6', 'audit-c-7']);
            }
            if (this.sumOfAnswers(['audit-c-1', 'audit-c-2']) === 0) {
                this.clearValidatorFromControls(['audit-c-3', 'audit-c-4',
                    'audit-c-5', 'audit-c-6', 'audit-c-7']);
            }
        }
    }
}
