import { getTemplateComponent } from "../../../utils/hypridHelper";
import { DocumentTagEnum } from "../../shared/models/document-tag.enum";
import { AspnF027ListComponent } from "./components/doctor/aspnF027list/aspnF027list.component";
import { AspnVisitsCalendarComponent } from "./components/doctor/aspnVisitsCalendar/aspnVisitsCalendar.component";
import { NursingServiceOrderListComponent } from "./components/doctor/nursingServiceOrderList/nursingServiceOrderList.component";
import { AspnVisitEditComponent } from "./components/doctor/nursingServiceVisit/nursing-service-visit-edit.component";
import {
    HomeEnvironmentAssessmentComponent
} from "./components/patient/homeEnvironmentAssessment/home-environment-assessment.component";
import { NursingAndCarePlan } from "./components/patient/nursingAndCarePlan/nursing-and-care-plan.component";
import { NursingAndCarePlanView } from "./components/patient/nursingAndCarePlan/view/nursing-and-care-plan-view.component";
import { NursingNeedsAssessmentQuestionnaireComponent } from "./components/patient/nursingNeedsAssessmentQuestionnaire/nursing-needs-assessment-questionnaire.component";
import { NursingServiceOrderCreateComponent } from "./components/patient/nursingServiceOrder/nursing-service-order-create.component";
import { PatientHealthAssessmentsComponent } from "./components/patient/patient-health-assessments/patient-health-assessments.component";
import { ActivityAndHomeEnvironmentHistoryComponent } from "./components/patient/summary/activityAndHomeEnvironmentHistory/activity-and-home-environment-history.component";
import { AllVisitsComponent } from "./components/patient/summary/allVisits/all-visits.component";
import { HealthAssesmentHistoryComponent } from "./components/patient/summary/healthAssesmentHistory/health-assesment-history.component";
import { NursingAndCarePlanHistoryComponent } from "./components/patient/summary/nursingAndCarePlanHistory/nursing-and-care-plan-history.component";
import { NursingNeedsHistoryComponent } from "./components/patient/summary/nursingNeedsHistory/nursing-needs-history.component";
import { NursingSummaryComponent } from "./components/patient/summary/nursingSummary.component";
import { PatientAppointmentsComponent } from "./components/patient/summary/patientAppointments/patient-appointments.component";

// export const AspnPatientRoutes: Routes = [
//   {
//     path: "aspn/summary",
//     component: SummaryComponent,
//     pathMatch: "full",
//   },
//   {
//     path: `aspn/${DocumentTagEnum.spnk}/:questionnaireId`,
//     component: NursingNeedsAssessmentQuestionnaireComponent,
//     pathMatch: "full",
//   },
//   {
//     path: `aspn/${DocumentTagEnum.spnk}`,
//     component: NursingNeedsAssessmentQuestionnaireComponent,
//     pathMatch: "full",
//   },
// ];

ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding ASPN routes");

        // Patient routes
        // DP.DP_PATIENT2L -> Patient + 2 column layout
        // DP.DP_PATIENT3L -> Patient + 3 column layout

        $routeSegmentProvider
            .when(`/dp/patients/:patientId/aspn/summary`, "DP.DP_PATIENT3L.ASPN_SUMMARY")
            .when(`/dp/patients/:patientId/aspn/nusingNeedsHistory`, "DP.DP_PATIENT3L.ASPN_NURSING_NEEDS_HISTORY")
            .when(`/dp/patients/:patientId/aspn/nursingAndCarePlanHistory`, "DP.DP_PATIENT3L.ASPN_PLAN_HISTORY")
            .when(`/dp/patients/:patientId/aspn/activityAndHomeEnvironmentHistory`, 'DP.DP_PATIENT3L.ASPN_ACTIVITY_AND_HOME_ENVIRONMENT_HISTORY')
            .when(`/dp/patients/:patientId/aspn/healthAssesmentHistory`, 'DP.DP_PATIENT3L.ASPN_HEALTH_ASSESMENT_HISTORY')
            .when(`/dp/patients/:patientId/aspn/allVisits`, 'DP.DP_PATIENT3L.ASPN_ALL_VISITS')
            .when(`/dp/patients/:patientId/aspn/patientAppointments`, 'DP.DP_PATIENT3L.ASPN_PATIENT_APPOINTMENTS')
            .when(
                `/dp/patients/:patientId/aspn/${DocumentTagEnum.spnk}/:questionnaireId`,
                "DP.DP_PATIENT2LREVNEW.ASPN_NEED_QST_EDIT"
            )
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.spnk}`, "DP.DP_PATIENT2LREVNEW.ASPN_NEED_QST_EDIT")
            .when(
                `/dp/patients/:patientId/aspn/${DocumentTagEnum.spnk}/:questionnaireId/view`,
                "DP.DP_PATIENT3L.ASPN_NEED_QST_VIEW"
            )
            .when(
                `/dp/patients/:patientId/aspn/order/:order27Id/${DocumentTagEnum.spu}`,
                "DP.DP_PATIENT2LREVNEW.ASPN_ORDER_EDIT"
            )
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.spu}`, "DP.DP_PATIENT2LREVNEW.ASPN_ORDER_EDIT")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.spu}/:orderId`, "DP.DP_PATIENT2LREVNEW.ASPN_ORDER_EDIT")
            .when(
                `/dp/patients/:patientId/aspn/${DocumentTagEnum.spu}/:orderId/view`,
                "DP.DP_PATIENT3L.ASPN_ORDER_VIEW"
            )
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.spp}`, "DP.DP_PATIENT2LREVNEW.ASPN_PLAN_EDIT")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.spp}/:planId`, "DP.DP_PATIENT2LREVNEW.ASPN_PLAN_EDIT")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.spp}/:planId/view`, "DP.DP_PATIENT3L.ASPN_PLAN_VIEW")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.vnapvf}`, "DP.DP_PATIENT2LREVNEW.ASPN_ASSESSMENT_EDIT")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.vnapvf}/:id`, "DP.DP_PATIENT2LREVNEW.ASPN_ASSESSMENT_EDIT")
            .when(
                `/dp/patients/:patientId/aspn/${DocumentTagEnum.vnapvf}/:id/view`,
                "DP.DP_PATIENT3L.ASPN_ASSESSMENT_VIEW"
            )
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.sbvf}`, "DP.DP_PATIENT2LREVNEW.ASPN_HEALTH_EDIT")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.sbvf}/:id`, "DP.DP_PATIENT2LREVNEW.ASPN_HEALTH_EDIT")
            .when(`/dp/patients/:patientId/aspn/${DocumentTagEnum.sbvf}/:id/view`, "DP.DP_PATIENT3L.ASPN_HEALTH_VIEW")
            // Doctor routes
            // DP.DP_USER2L -> Doctor + 2 column layout
            // DP.DP_USER3L -> Doctor + 3 column layout
            .when(`/dp/aspn/order/:orderId/${DocumentTagEnum.spv}`, "DP.DP_USER2L.ASPN_VISIT_EDIT")
            .when(`/dp/aspn/${DocumentTagEnum.spv}/:visitId`, "DP.DP_USER2L.ASPN_VISIT_EDIT")
            .when(`/dp/aspn/${DocumentTagEnum.spv}/:visitId/view`, "DP.DP_USER2L.ASPN_VISIT_VIEW")
            .when(`/dp/aspn/${DocumentTagEnum.spv}/:visitId/view`, "DP.DP_USER2L.ASPN_VISIT_VIEW")
            .when(`/dp/aspn/${DocumentTagEnum.spol}/`, "DP.DP_USER2L.ASPN_F027_ORDER_LIST")
            .when(`/dp/aspn/${DocumentTagEnum.spups}/`, "DP.DP_USER2L.ASPN_NURSING_ORDER_LIST")
            .when(`/dp/aspn/${DocumentTagEnum.spak}/`, "DP.DP_USER2L.ASPN_VISITS_CALENDAR_VIEW");
        /*
            $routeSegmentProvider
                .when('/dp/user/:patientId/doc-unsorted', 'DP.DP_USER3L.DOC_UNSORTED')
        */

        // 3 col layout Patient
        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2LREVNEW")
            .segment("ASPN_NEED_QST_EDIT", {
                template: getTemplateComponent(NursingNeedsAssessmentQuestionnaireComponent),
            })
            .segment("ASPN_ORDER_EDIT", { template: getTemplateComponent(NursingServiceOrderCreateComponent) })
            .segment("ASPN_ASSESSMENT_EDIT", {
                template: getTemplateComponent(HomeEnvironmentAssessmentComponent),
            })
            .segment("ASPN_NEED_QST_EDIT", {
                template: getTemplateComponent(NursingNeedsAssessmentQuestionnaireComponent),
            })
            .segment("ASPN_ORDER_EDIT", { template: getTemplateComponent(NursingServiceOrderCreateComponent) })
            .segment("ASPN_PLAN_EDIT", { template: getTemplateComponent(NursingAndCarePlan) })
            .segment("ASPN_HEALTH_EDIT", { template: getTemplateComponent(PatientHealthAssessmentsComponent) });

        // 2 col layout Patient
        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT3L")
            .segment("ASPN_SUMMARY", { template: getTemplateComponent(NursingSummaryComponent) })
            .segment("ASPN_NURSING_NEEDS_HISTORY", { template: getTemplateComponent(NursingNeedsHistoryComponent) })
            .segment("ASPN_PLAN_HISTORY", { template: getTemplateComponent(NursingAndCarePlanHistoryComponent) })
            .segment('ASPN_ACTIVITY_AND_HOME_ENVIRONMENT_HISTORY', { template: getTemplateComponent(ActivityAndHomeEnvironmentHistoryComponent) })
            .segment('ASPN_HEALTH_ASSESMENT_HISTORY', { template: getTemplateComponent(HealthAssesmentHistoryComponent) })
            .segment('ASPN_ALL_VISITS', { template: getTemplateComponent(AllVisitsComponent) })
            .segment('ASPN_PATIENT_APPOINTMENTS', { template: getTemplateComponent(PatientAppointmentsComponent) })
            .segment("ASPN_NEED_QST_VIEW", {
                template: getTemplateComponent(NursingNeedsAssessmentQuestionnaireComponent),
            })
            .segment("ASPN_NEED_QST_VIEW", {
                template: getTemplateComponent(NursingNeedsAssessmentQuestionnaireComponent),
            })
            .segment("ASPN_ASSESSMENT_VIEW", {
                template: getTemplateComponent(HomeEnvironmentAssessmentComponent),
            })
            .segment("ASPN_ORDER_VIEW", { template: getTemplateComponent(NursingServiceOrderCreateComponent) })
            .segment("ASPN_PLAN_VIEW", { template: getTemplateComponent(NursingAndCarePlanView) })
            .segment("ASPN_HEALTH_VIEW", { template: getTemplateComponent(PatientHealthAssessmentsComponent) });

        $routeSegmentProvider
            .within("DP")
            .within("DP_USER2L")
            .segment("ASPN_F027_ORDER_LIST", { template: getTemplateComponent(AspnF027ListComponent) })
            .segment("ASPN_NURSING_ORDER_LIST", { template: getTemplateComponent(NursingServiceOrderListComponent) })
            .segment("ASPN_VISIT_EDIT", { template: getTemplateComponent(AspnVisitEditComponent) })
            .segment("ASPN_ORDER_VIEW", { template: getTemplateComponent(AspnVisitEditComponent) })
            .segment("ASPN_VISITS_CALENDAR_VIEW", { template: getTemplateComponent(AspnVisitsCalendarComponent) });
    },
]);
