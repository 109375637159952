import {Component, Input, OnInit} from "@angular/core";
import {DiagnosisSummaryModel} from "../../../models/diagnosis-summary.model";
import {Router} from "@angular/router";

@Component({
    selector: 'diagnosis-summary',
    templateUrl: './diagnosis-summary.component.html'
})
export class DiagnosisSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() diagnoses: DiagnosisSummaryModel[] = [];
    @Input() patientId: string;
    @Input() chronicDiagnosis: boolean = false;
    statusChronicId: string = "90734009";
    statusActiveId: string = "55561003";

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        if (docId) {
        this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]);
    }
    }

    formFilter(): string {
        return `?status=${this.chronicDiagnosis ? this.statusChronicId : this.statusActiveId}`;
    }
}