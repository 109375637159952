import { Component, Input, OnChanges, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import {
    AspnPractitionierTimetableEvent,
    DpAspnContextService,
} from "src/app/doctor/aspn/services/dp-aspn-context.service";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { SelectOption } from "src/app/shared/components/select/select.component";
import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ltLocale from "@fullcalendar/core/locales/lt";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { Observable, Observer, switchMap, map, of, firstValueFrom } from "rxjs";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import { HttpParams } from "@angular/common/http";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { ApiV2Medications } from "src/api/api-v2-medications";
import { RouteParams } from "src/app/ng1.routeParams";
import { MedicationModel } from "src/app/shared/models/medication.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { ColDef, Column, ColumnApi, FirstDataRenderedEvent, GridApi, GridReadyEvent, ICellRendererParams, IRowNode, RowNode } from "ag-grid-community";
import { AgGridCheckboxFgCell } from "../../../../../shared/components/ag-grid/components/ag-checkbox-cell/agGrid-checkbox-fg-cell";
import { AgGridModalOpenLinkCell } from "../../../../../shared/components/ag-grid/ag-modal-open-link-cell/ag-modal-open-link.component";
import { fromDateDto, fromLocalDateTimeDto, toDateDto } from "src/app/shared/services/utils/utils";
import { AspnOrderDto, AspnOrderProcedureDto, toAspnOrderProcedureDto } from "../../../model/aspnOrder.model";
import { ApiV2Session } from "src/api/api-v2-session";
import { DpAspnVisitService } from "../../../services/dpAspnVisit.service";
import { AspnVisitModalComponent } from "./nursing-service-visit-modal.component";
import { AspnOrderClassifier, DpAspnOrderService } from "../../../services/dpAspnOrder.service";
import {
    AspnVisitClassifiers,
    AspnVisitDto,
    AspnVisitProcedureDto,
    AspnVisitProcedureValue,
    AspnVisitValue,
    fromAspnVisitProcedureDto,
} from "../../../model/aspnVisit.model";
import { param } from "jquery";
import { AgGridSelectFgCell } from "src/app/shared/components/ag-grid/components/ag-select-cell/agGrid-select-fg-cell";
import { AgGridInputFgCell } from "src/app/shared/components/ag-grid/components/ag-input-cell/agGrid-input-fg-cell";
import { AgActionsCell } from "src/app/shared/components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { AgGridAngular } from "ag-grid-angular";

@Component({
    selector: "nursing-service-visit-edit",
    templateUrl: "./nursing-service-visit-edit.component.html",
})
export class AspnVisitEditComponent implements OnInit, OnChanges {
    @ViewChild("confirmationModal", { read: TemplateRef }) confirmationModal: TemplateRef<any>;
    @ViewChild('visitProcedureGrid') visitProcedureGrid!: AgGridAngular;
    private agGridApi: GridApi;
    private gridApi!: GridApi;
    private agVisitProcedureGridApi: GridApi;
    private columnAgGridApi: ColumnApi;
    private columnVisitProcedureAgGridApi: ColumnApi;
    public aspnVisitF: FormGroup;
    public aspnVisitDto: AspnVisitDto;
    public isVisistWithPeriod: boolean = true;
    public visitClassifiers: AspnVisitClassifiers;
    public periodicityOpts: SelectOption[];

    public procedureGoupOpts: SelectOption[];
    public procedureOpts: AspnOrderClassifier[];
    public placeInBodyProcedureOpts: AspnOrderClassifier[];
    public medicinesOpts: Partial<MedicationModel>[];
    bsModalRef?: BsModalRef;
    calendarModalRef?: BsModalRef;
    visitOrderProceduresGridData: any[];
    visitProceduresGridData: any[];
    public procedureByOrderColDefs: ColDef[];
    public visitProcedureColDefs: ColDef[];
    visitsDates: Date[] = [];
    // Paskyrimo id
    orderId: string;
    aspnPatient: Partial<PatientModel>;
    aspnOrders: AspnOrderDto;
    aspnPatientOrders: AspnOrderDto[] = [];
    aspnPatientOrdersProcedures: AspnOrderProcedureDto[] = [];
    visitDateFromMinDate: Date = new Date();
    visitDateFromMinDateP: Date = new Date();
    visitDateToMaxDateP: Date = new Date();
    sessionPractitionier: PractitionerModel;
    //visitOrderProceduresPlaned: AspnVisitProcedureDto[] = [];

    labelByStatus = {
        success: "Įvykęs",
        info: "Suplanuotas",
        extra: "Skubus",
    };
    badgeByStatus = {
        success: "bg-dark-subtle text-dark",
        info: "text-bg-success text-white",
        extra: "text-bg-danger text-white",
    };

    events: AspnPractitionierTimetableEvent[] = [
        {
            title: "testaa",
            start: `2023-11-18T08:30:00`,
            end: `2023-11-18T12:30:00`,
            status: "success",
            patientFullName: "Vardenis Pavardenis",
        },
    ];
    calendarOptions: CalendarOptions = {
        initialView: "timeGridWeek",
        plugins: [dayGridPlugin, timeGridPlugin],
        events: this.events,
        headerToolbar: {
            left: "prev,next",
            center: "title",
            right: "",
        },
        eventMinHeight: 80,
        weekends: true,
        allDaySlot: false,
        locale: ltLocale,
        height: 700,
    };
    visitingPractitionierSelected: boolean = false;
    noResult = false;

    suggestions$?: any;

    constructor(
        private dpAspnContextService: DpAspnContextService,
        private dbAspnVisitService: DpAspnVisitService,
        private dbAspnOrderService: DpAspnOrderService,
        private route: RouteParams,
        private modalService: BsModalService,
        private apiV2Session: ApiV2Session,
        private apiV2Practitioners: ApiV2Practitioners,
        private apiV2Medications: ApiV2Medications,
        private fb: FormBuilder
    ) {}

    async ngOnInit(): Promise<void> {
        this.visitClassifiers = await this.dbAspnVisitService.getAspnVisitClassifiers();
        this.periodicityOpts = this.visitClassifiers.periodicity.map((v) => {
            return {
                text: v.name,
                value: v.id,
            };
        });

        this.procedureGoupOpts = this.visitClassifiers.proceduresGroup.map((v) => {
            return {
                text: v.name,
                value: v.id,
            };
        });

        this.placeInBodyProcedureOpts = this.visitClassifiers.placeInBodyProcedure;

        await firstValueFrom(this.apiV2Session.getPractitioner()).then(async (val) => {
            this.sessionPractitionier = val;
            await this.dpAspnContextService.getNursingServiceOrderPractTimetable(val.id).then((v) => {
                this.events = v;
            });
            this.calendarOptions.events = this.events;
            this.visitingPractitionierSelected = true;
        });

        this.aspnVisitF = this.fb.group({
            visitDate: [new Date(), Validators.required],
            visitTimeFrom: ["", [Validators.required, this.timeRangeValidator]],
            visitTimeTo: ["", [Validators.required, this.timeRangeValidator]],
            visitingSpecialist: [this.sessionPractitionier, Validators.required],
            visitingSpecialistDisplay: [
                this.sessionPractitionier.familyName + " " + this.sessionPractitionier.givenName,
                Validators.required,
            ],
            comment: "",
            periodicVisit: this.fb.group({
                periodicity: "",
                weekDays: this.fb.group({
                    mon: [false, [this.weekDayCheckboxesValidator]],
                    tue: [false, [this.weekDayCheckboxesValidator]],
                    wed: [false, [this.weekDayCheckboxesValidator]],
                    thu: [false, [this.weekDayCheckboxesValidator]],
                    fri: [false, [this.weekDayCheckboxesValidator]],
                    sat: [false, [this.weekDayCheckboxesValidator]],
                    sun: [false, [this.weekDayCheckboxesValidator]],
                }),
                monthDay: [NaN, [this.perMonthInputsValidator]],
                every: ["", [this.perMonthInputsValidator]],
                everyWeekDay: ["", [this.perMonthInputsValidator]],
                deadlineDate: [null, [this.deadlineDateValidator]],
                deadlineVisitCount: [null, [this.deadlineCountValidator]],
            }),
            orderProcedures: this.fb.array([]),
            visitProcedures: this.fb.array([]),
        });

        this.procedureByOrderColDefs = [
            {
                field: "planedVisit",
                headerName: "Suplanuotas apsilankymas",
                cellRenderer: AgGridCheckboxFgCell,
                width: 110,
            },
            {
                field: "procedures",
                headerName: "Procedūros",
                width: 250,
            },
            {
                field: "order",
                headerName: "Paskyrimas",
                cellRenderer: (param) => {
                    if (param.value) {
                        return `<a class="btn btn-link px-0" href="${param.value}" role="button"  target="_blank">${param.linkString}</a>`;
                    } else {
                        return ``;
                    }
                },
                cellRendererParams: {
                    linkString: "Atidaryti",
                },
                width: 100,
            },
            { field: "urgent", headerName: "Skuba", width: 60 },
            { field: "medicines", headerName: "Priemonės ir medikamentai" },
            { field: "periodisity", headerName: "Periodiškumas", width: 110 },
            { field: "finishFromTo", headerName: "Atlikti(Nuo/Iki)", width: 100 },
            {
                field: "moreInfo",
                headerName: "",
                cellRenderer: AgGridModalOpenLinkCell,
                cellRendererParams: {
                    linkTitle: "Daugiau",
                },
                width: 90,
            },
        ];

        this.visitProcedureColDefs = [

            {
                field: "procedures",
                headerName: "Procedūros",
                cellRenderer: (params:ICellRendererParams) => {
                   return params.getValue()?.map((p) => p.name).join(", ");
                }
            },
            {
                field: "relatedMedication",
                headerName: "Priemonės ir medikamentai",
                cellRenderer: (params:ICellRendererParams) => {
                    return params.getValue()?.map((p) => p.name).join(", ");
                 }
            },
            {
                field: "moreInfo",
                headerName: "",
                cellRenderer: AgGridModalOpenLinkCell,
                cellRendererParams: {
                    linkTitle: "Daugiau",
                },
            },
            {
                field: "actionButton",
                type: "rightAligned",
                headerName: "",
                width: 90,
                cellRenderer: AgActionsCell,
                cellRendererParams(params: ICellRendererParams) {
                    return {
                        rowId: params.node.rowIndex,
                        editable: false,
                        viewable: false,
                        copyable: false,
                        createable: false,
                        deleteable: true,
                    };
                },
            },
        ]

        if (this.route.visitId) {
            this.procedureByOrderColDefs = [
                {
                    field: "planedVisit",
                    headerName: "Suplanuotas apsilankymas",
                    cellRenderer: AgGridCheckboxFgCell,
                    width: 110,
                },
                {
                    field: "procedures",
                    headerName: "Procedūros",
                    width: 250,
                },
                {
                    field: "order",
                    headerName: "Paskyrimas",
                    cellRenderer: (param) => {
                        if (param.value) {
                            return `<a class="btn btn-link px-0" href="${param.value}" role="button"  target="_blank" >${param.linkString}</a>`;
                        } else {
                            return ``;
                        }
                    },
                    cellRendererParams: {
                        linkString: "Atidaryti",
                    },
                    width: 100,
                },
                { field: "urgent", headerName: "Skuba", width: 60 },
                { field: "medicines", headerName: "Priemonės ir medikamentai" },
                {
                    field: "moreInfo",
                    headerName: "",
                    cellRenderer: AgGridModalOpenLinkCell,
                    cellRendererParams: {
                        linkTitle: "Daugiau",
                    },
                    width: 90,
                },
                {
                    field: "procedureState",
                    headerName: "Procedūros atlikimas",
                    cellRenderer: AgGridSelectFgCell,
                    width: 110,
                },
                {
                    field: "cancelNote",
                    headerName: "Neatlikimo priežastis",
                    cellRenderer: AgGridInputFgCell,
                    width: 110,
                },
            ];

            this.visitProcedureColDefs = [

                {
                    field: "procedures",
                    headerName: "Procedūros",
                    // width: 250,
                    cellRenderer: (params:ICellRendererParams) => {
                       return params.getValue()?.map((p) => p.name).join(", ");
                    }
                },
                {
                    field: "relatedMedication",
                    headerName: "Priemonės ir medikamentai",
                    cellRenderer: (params:ICellRendererParams) => {
                        return params.getValue()?.map((p) => p.name).join(", ");
                     }
                },
                {
                    field: "moreInfo",
                    headerName: "",
                    cellRenderer: AgGridModalOpenLinkCell,
                    cellRendererParams: {
                        linkTitle: "Daugiau",
                    },
                                    },
                {
                    field: "procedureState",
                    headerName: "Procedūros atlikimas",
                    cellRenderer: AgGridSelectFgCell,
                },
                {
                    field: "cancelNote",
                    headerName: "Neatlikimo priežastis",
                    cellRenderer: AgGridInputFgCell,

                },
                {
                    field: "actionButton",
                    type: "rightAligned",
                    headerName: "",
                    width: 90,
                    cellRenderer: AgActionsCell,
                    cellRendererParams(params: ICellRendererParams) {
                        return {
                            rowId: params.node.rowIndex,
                            editable: false,
                            viewable: false,
                            copyable: false,
                            createable: false,
                            deleteable: true,
                        };
                    },
                },
            ]
            await this.dbAspnVisitService.getAspnVisitById(Number(this.route.visitId)).then(async (val) => {
                this.aspnVisitDto = val;
                !val.repeatPeriod && (this.isVisistWithPeriod = false);
                if (val.patient) {
                    this.aspnPatient = val.patient;
                    await this.dbAspnVisitService.getAspnVisitPatientOrders(this.aspnPatient.id).then((o) => {
                        this.aspnPatientOrders.push(...o);
                        this.aspnPatientOrders.forEach((o) => {
                            this.aspnPatientOrdersProcedures.push(...o.procedures);
                        });
                    });
                }
                const formData = this.dtoToForm(val);
                this.visitProceduresGridData = val.visitProcedures ? val.visitProcedures:[];

                this.aspnVisitF.patchValue(formData);
                this.aspnVisitF.get("periodicVisit").patchValue(formData.periodicVisit);
                this.aspnVisitF.get("periodicVisit.weekDays").patchValue(formData.periodicVisit.weekDays);

                formData.visitProcedures.forEach((vp, i) => {
                    this.addVisitProcedureFormGroup(vp, i);
                });
                this.aspnVisitF.get("visitProcedures").patchValue(formData.visitProcedures);
                this.visitOrderProceduresGridData = [];
                this.aspnVisitDto.orderProcedures.forEach((vp) => {
                    this.visitOrderProceduresGridData.push({
                        orderProcedureId: vp.id,
                        planedVisit: true,
                        procedures: vp.procedures.map((pro) => pro.name).join(", "),
                        order: ``, // TODO modal view need !!!
                        urgent: vp.urgent,
                        medicines: vp.relatedMedication.map((m) => m.name).join(", "),
                        periodisity: vp.frequency ? vp.frequency + "k. " + vp.repeatPeriod.name : vp.repeatPeriod.name,
                        finishFromTo:
                            (vp.procedureFromDate && vp.procedureFromDate.substring(0, 10)) +
                            " - " +
                            (vp.procedureToDate && vp.procedureToDate.substring(0, 10)),
                        procedureState: null,
                        cancelNote: "",
                        recommendations: vp.recommendations,
                        bodySite: vp.bodySite.map((pro) => pro.name),

                    });
                });
                this.updateOrderProcedures();
            });
        } else if (this.route.orderId) {
            await this.dbAspnVisitService.getAspnVisitPatientOrderByOrderId(Number(this.route.orderId)).then((val) => {
                this.aspnPatient = val.patient;
                this.aspnPatientOrders.push(val);
                this.aspnPatientOrders.forEach((o) => {
                    this.aspnPatientOrdersProcedures.push(...o.procedures);
                });
                this.visitOrderProceduresGridData = [];
                this.visitProceduresGridData = [];
                this.updateOrderProcedures();
            });
        }
        this.visitDateToMaxDateP.setFullYear(this.visitDateToMaxDateP.getFullYear() + 1);

        this.aspnVisitF.valueChanges.subscribe((val) => {
            this.aspnVisitF.get("visitTimeFrom").updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF.get("visitTimeTo").updateValueAndValidity({ onlySelf: true, emitEvent: false });

            (<FormGroup[]>(<FormArray>this.aspnVisitF.get("orderProcedures")).controls).forEach((fg) => {
                fg.get("planedVisit").updateValueAndValidity({ onlySelf: false, emitEvent: false });
                fg.get("procedureState").updateValueAndValidity({ onlySelf: false, emitEvent: false });
                fg.get("cancelNote").updateValueAndValidity({ onlySelf: false, emitEvent: false });
            });

            (<FormGroup[]>(<FormArray>this.aspnVisitF.get("visitProcedures")).controls).forEach((fg) => {
                fg.get("procedureState").updateValueAndValidity({ onlySelf: false, emitEvent: false });
                fg.get("cancelNote").updateValueAndValidity({ onlySelf: false, emitEvent: false });
            });

            this.aspnVisitF.get("periodicVisit.every").updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.everyWeekDay")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.deadlineDate")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.deadlineVisitCount")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.mon")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.tue")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.wed")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.thu")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.fri")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.sat")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF
                .get("periodicVisit.weekDays.sun")
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF.get("periodicVisit").updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.aspnVisitF.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        firstValueFrom(this.apiV2Medications.search(new HttpParams())).then(
            (val) =>
                (this.medicinesOpts = val.map((m) => {
                    return {
                        id: m.id,
                        name: m.name,
                    } as Partial<MedicationModel>;
                }))
        );

        (this.aspnVisitF.get("visitProcedures") as FormArray).length < 1 && this.addVisitProcedureFormGroup();
        this.getPractitioniersByOrgId("123456789");
        //
    }

    updateOrderProcedures() {
        this.aspnPatientOrders.forEach((order) => {
            order.procedures.forEach((proc) => {
                if (this.visitOrderProceduresGridData.find((p) => p.orderProcedureId === proc.id)) {
                    this.visitOrderProceduresGridData.find(
                        (p) => p.orderProcedureId === proc.id
                    ).order = `dp/patients/${order.patient.id}/aspn/spu/${order.id}`;
                } else {
                    this.visitOrderProceduresGridData.push({
                        orderProcedureId: proc.id,
                        planedVisit: false,
                        procedures: proc.procedures.map((pro) => pro.name).join(", "),
                        order: `dp/patients/${order.patient.id}/aspn/spu/${order.id}`,
                        urgent: proc.urgent,
                        medicines: proc.medicines.map((m) => m.name).join(", "),
                        periodisity: proc.frequency
                            ? proc.frequency + "k. " + proc.periodicity.name
                            : proc.periodicity.name,
                        finishFromTo:
                            (proc.procedureFromDate && proc.procedureFromDate.substring(0, 10)) +
                            " - " +
                            (proc.procedureToDate && proc.procedureToDate.substring(0, 10)),
                            recommendations: proc.recommendations,
                        bodySite: proc.placeInBodyProcedure.map((pro) => pro.name),
                    });
                }
            });
        });
    }

    ngOnChanges() {
        // this.agGridApi.refreshCells();
    }

    onVisitDateChange(e: Date) {
        this.visitDateFromMinDateP = e;
    }

    getPractitioniersByOrgId(organizationId: string) {
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.aspnVisitF.controls["visitingSpecialist"].value);
        }).pipe(
            switchMap((query: string) => {
                let params = new HttpParams();
                params = params.append("q", String((query ? query : "") + "*"));
                params = params.append("organization", organizationId);
                ["aspn_coordinator", "nurse", "LIC_specialistas", "KT_med"].map(
                    (r) => (params = params.append("role", r))
                );
                return this.apiV2Practitioners.find(params).pipe(
                    map((practitioniers: FilteredDataModel) => {
                        return practitioniers.items.map((p) => {
                            p.fullDisplay = p.givenName + " " + p.familyName;
                            return p;
                        });
                    })
                );
            })
        );
    }

    // --- specialis typeahead ----
    async typeaheadOnSelectP(e: TypeaheadMatch): Promise<void> {
        if (e) {
            this.aspnVisitF.controls["visitingSpecialist"].setValue(e.item);

            await this.dpAspnContextService
                .getNursingServiceOrderPractTimetable(e.item.id)
                .then((v) => (this.events = v));
            this.calendarOptions.events = this.events;
            this.visitingPractitionierSelected = true;
        } else {
            this.aspnVisitF.controls["visitingSpecialist"].setValue(null);
            this.events = [];
            //this.calendarOptions.events = this.events;
            this.visitingPractitionierSelected = false;
        }
    }
    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    onBlurtypeahead(event) {
        if (
            !event ||
            event.item.givenName + " " + event.item.familyName !==
                this.aspnVisitF.controls["visitingSpecialistDisplay"].value
        ) {
            this.aspnVisitF.controls["visitingSpecialistDisplay"].setValue("");
            this.aspnVisitF.controls["visitingSpecialist"].setValue(null);
            this.visitingPractitionierSelected = false;
        }
    }
    //----------------------------

    // ------ AG-GRID conf --------
    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        wrapText: true,
        autoHeight: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
    };

    openVizitsCalendarModal(template: TemplateRef<any>) {
        const modOp: ModalOptions = {
            class: "modal-xl",
        };
        this.calendarModalRef = this.modalService.show(template, modOp);
    }

    openModal (rowData: any) {
        let bodySites = [];
        if(rowData.bodySite && rowData.bodySite.length > 0 && rowData.bodySite[0].id){
            bodySites =  rowData.bodySite.map((s)=> s.name)
        } else {
            bodySites = rowData.bodySite;
        }
        const initialState: ModalOptions = {
            initialState: {
                procedureLocations:bodySites,
                recommendations: rowData.recommendations,
                title: "Papildoma procedūrų informacija",
            },
            ignoreBackdropClick: false,
        };
        this.bsModalRef = this.modalService.show(AspnVisitModalComponent, {
            ...initialState,
            ...Object.assign({}, { class: "modal-lg" }),
        });
        this.bsModalRef.content.closeBtnName = "Uždaryti";
    }
    openModalVisitProcedureMoreInfo(rowData: any) {
        const initialState: ModalOptions = {
            initialState: {
                procedureLocations:rowData.bodySite.map((bs) => bs.name),
                recommendations: rowData.recommendations,
                title: "Papildoma procedūrų informacija",
            },
            ignoreBackdropClick: false,
        };
        this.bsModalRef = this.modalService.show(AspnVisitModalComponent, {
            ...initialState,
            ...Object.assign({}, { class: "modal-lg" }),
        });
        this.bsModalRef.content.closeBtnName = "Uždaryti";
    }

    onGridReady(params: GridReadyEvent) {
        this.agGridApi = params.api;
        this.columnAgGridApi = params.columnApi;
        this.refreshFormControls();
        this.agGridApi.sizeColumnsToFit();
    }

    onVisitProcedureGridReady(params: GridReadyEvent) {
        this.agVisitProcedureGridApi = params.api;
        this.columnVisitProcedureAgGridApi = params.columnApi;
        this.refreshVisitProceduresFormControls();
        this.agVisitProcedureGridApi.sizeColumnsToFit();
    }

    private refreshVisitProceduresFormControls() {
        if (this.agVisitProcedureGridApi) {
            this.createVisitProceduresFormControls();
            this.agVisitProcedureGridApi.refreshCells({ force: true });
        }
    }

    private refreshFormControls() {
        if (this.agGridApi) {
            this.createFormControls();
            this.agGridApi.refreshCells({ force: true });
        }
    }

    private createFormControls() {
        let columns = this.columnAgGridApi.getColumns();
        const orderProcedures = <FormArray>this.aspnVisitF.controls["orderProcedures"];
        orderProcedures.clear();

        this.agGridApi.forEachNode((rowNode: RowNode, i) => {
            orderProcedures.push(
                new FormGroup({
                    procedureId: new FormControl(rowNode.data.orderProcedureId),
                    planedVisit: new FormControl(rowNode.data.planedVisit, [this.planedVisitsValidator]),
                    procedureState: new FormControl(null, [this.procedureApprovValidator]),
                    cancelNote: new FormControl("", [this.cancelNoteValidator]),
                })
            );
        });
    }

    private createVisitProceduresFormControls() {
        const visitProcedures = <FormArray>this.aspnVisitF.controls["visitProcedures"];
        visitProcedures.clear();
        this.agVisitProcedureGridApi.forEachNode((rowNode: RowNode, i) => {
            visitProcedures.push(
                new FormGroup({
                    procedureState: new FormControl(null, [this.visitProcedureApprovValidator]),
                    cancelNote: new FormControl("", [this.cancelNoteValidator]),
                })
            );
        });
    }
    private createKey(rowNodeData: any, columnName: string): any {
        return rowNodeData[columnName];
    }

    getContext() {
        return {
            formGroupArray: this.aspnVisitF.controls["orderProcedures"],
            createKey: this.createKey,
            parentComp: this,
            openModal: this.openModal,
            formControlName: "planedVisit",
            formControlNameSelect: "procedureState",
            formControlNameInput: "cancelNote",
        };
    }

    getVisitProcedureContext() {
        return {
            formGroupArray: this.aspnVisitF.controls["visitProcedures"],
            createKey: this.createKey,
            parentComp: this,
            formControlNameSelect: "procedureState",
            formControlNameInput: "cancelNote",
        };
    }

    addVisitProcedureRow() {
        if(this.visitProcedureFG.valid){
            const newItems =[{
                id: null,
                procedureGroup: {code:this.visitProcedureFG.get('procedureGroup').value, name:this.procedureGoupOpts.find((v)=> v.value === this.visitProcedureFG.get('procedureGroup').value ).text },
                procedures: this.visitProcedureFG.get('procedures').value,
                bodySite: this.visitProcedureFG.get('bodySite').value,
                relatedMedication: this.visitProcedureFG.get('relatedMedication').value,
                recommendations: this.visitProcedureFG.get('recommendations').value,
                procedureState: null,
                cancelNote: "",
            }];

            this.agVisitProcedureGridApi?.applyTransaction({ add: newItems });
            setTimeout(() => {this.refreshVisitProceduresFormControls()}, 1);
            this.modalService.hide();
        }
    }

    getRowNodeId(data: any) {
        return data.orderProcedureId;
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        this.refreshFormControls();
        this.refreshVisitProceduresFormControls();
        params.api.sizeColumnsToFit();
    }
    //-------------------------

    // ----- visit procedures handling

    get visitProceduresControls() {
        return (<FormGroup>this.aspnVisitF.get("visitProcedures")).controls;
    }

    addVisitProcedureFormGroup(procedure?: AspnVisitProcedureValue, index?: number) {
        // const fg = new FormGroup({
        //     procedureGroup: new FormControl(null),
        //     procedures: new FormControl([], [this.visitProcedureValidator]),
        //     bodySite: new FormControl([]),
        //     procedureFromDate: new FormControl(),
        //     relatedMedication: new FormControl([]),
        //     procedureToDate: new FormControl(),
        //     recommendations: new FormControl(""),
        //     id: new FormControl(undefined),
        //     procedureState: new FormControl(null, [this.visitProcedureApprovValidator]),
        //     cancelNote: new FormControl("", [this.cancelNoteValidator]),
        // });

        // if (procedure) {
        //     fg.patchValue(procedure);
        //     this.procedureOpts[index] = this.visitClassifiers.proceduresGroup.find(
        //         (p) => p.id === procedure.procedureGroup
        //     ).children;
        // }
        // (<FormArray>this.aspnVisitF.get("visitProcedures")).push(fg);
    }

    removeVisitProcedureFormGroup(index: number) {
        (<FormArray>this.aspnVisitF.get("visitProcedures")).removeAt(index);
    }

    onVisitProcedureGroupChange(event: any) {
        const procGroup = this.visitClassifiers.proceduresGroup.find((val) => val.code == event.target.value);
        // Clear values of procedures
        this.visitProcedureFG.get("procedures").setValue([]);

        if (procGroup) {
            this.procedureOpts = procGroup.children;
        } else {
            this.procedureOpts = [];
        }
    }
    //-------------------------

    // --- vizit periodicity -----------

    onPeriodicityChange(e) {
        (<FormGroup>this.aspnVisitF.get("periodicVisit.weekDays")).reset(false);
        this.aspnVisitF.get("periodicVisit.every").reset();
        this.aspnVisitF.get("periodicVisit.everyWeekDay").reset();
        this.aspnVisitF.get("periodicVisit.monthDay").reset();
    }

    onDeadlineDateChange(e) {
        e && this.aspnVisitF.get("periodicVisit.deadlineVisitCount").reset();
    }
    onDeadlineVisitCountChange(e) {
        e && this.aspnVisitF.get("periodicVisit.deadlineDate").reset();
    }

    onMonthDayChange(e) {
        e && this.aspnVisitF.get("periodicVisit.every").reset();
        e && this.aspnVisitF.get("periodicVisit.everyWeekDay").reset();
    }

    onEveryChange(e) {
        e && this.aspnVisitF.get("periodicVisit.monthDay").reset();
    }

    // ---------------------------------------------------

    calculateVisits() {
        if (this.aspnVisitF.get("periodicVisit.deadlineDate").value) {
            return this.calculateVisitsByDate(
                this.aspnVisitF.get("visitDate").value,
                this.aspnVisitF.get("periodicVisit.deadlineDate").value
            );
        }
        if (this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value) {
            return this.calculateVisitsByCount(
                this.aspnVisitF.get("visitDate").value,
                this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value
            );
        }
        return [];
    }

    calculateVisitsByCount(dateFrom: Date, totalVisits: number) {
        let startDate = new Date(dateFrom);
        startDate.setHours(0, 0, 0, 0);
        const visitsDates: Date[] = [];

        if (dateFrom && totalVisits) {
            dateFrom.setHours(0, 0, 0, 0);

            if (this.aspnVisitF.get("periodicVisit.periodicity").value === "1") {
                for (let i = 0; i < totalVisits; i++) {
                    visitsDates.push(new Date(startDate));
                    startDate.setDate(startDate.getDate() + 1);
                }
            } else if (this.aspnVisitF.get("periodicVisit.periodicity").value === "2") {
                for (let i = 0; i < totalVisits; i++) {
                    visitsDates.push(new Date(startDate));
                    startDate.setDate(startDate.getDate() + 2);
                }
            } else if (this.aspnVisitF.get("periodicVisit.periodicity").value === "3") {
                while (visitsDates.length < totalVisits) {
                    this.aspnVisitF.get("periodicVisit.weekDays.mon").value &&
                        startDate.getDay() === 1 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.tue").value &&
                        startDate.getDay() === 2 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.wed").value &&
                        startDate.getDay() === 3 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.thu").value &&
                        startDate.getDay() === 4 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.fri").value &&
                        startDate.getDay() === 5 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.sat").value &&
                        startDate.getDay() === 6 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.sun").value &&
                        startDate.getDay() === 0 &&
                        visitsDates.push(new Date(startDate));
                    startDate.setDate(startDate.getDate() + 1);
                }
            } else if (this.aspnVisitF.get("periodicVisit.periodicity").value === "4") {
                if (
                    this.aspnVisitF.get("periodicVisit.every").value &&
                    this.aspnVisitF.get("periodicVisit.everyWeekDay").value
                ) {
                    for (let i = 0; i < totalVisits; i++) {
                        let rezDate = this.weekNumberWeekDayOfMonth(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            this.getWeekdayByName(this.aspnVisitF.get("periodicVisit.everyWeekDay").value),
                            Number(this.aspnVisitF.get("periodicVisit.every").value)
                        );
                        if (rezDate.getTime() < startDate.getTime()) {
                            const dateFromMoment = moment(startDate).add(1, "month");
                            dateFromMoment.set("date", 1);
                            dateFromMoment.set("hour", 0);
                            dateFromMoment.set("minute", 0);
                            dateFromMoment.set("second", 0);
                            dateFromMoment.set("millisecond", 0);
                            startDate = dateFromMoment.toDate();
                            rezDate = this.weekNumberWeekDayOfMonth(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                this.getWeekdayByName(this.aspnVisitF.get("periodicVisit.everyWeekDay").value),
                                Number(this.aspnVisitF.get("periodicVisit.every").value)
                            );
                        }
                        visitsDates.push(new Date(rezDate));
                        const dateFromMoment = moment(startDate).add(1, "month");
                        dateFromMoment.set("date", 1);
                        dateFromMoment.set("hour", 0);
                        dateFromMoment.set("minute", 0);
                        dateFromMoment.set("second", 0);
                        dateFromMoment.set("millisecond", 0);
                        startDate = dateFromMoment.toDate();
                    }
                } else if (this.aspnVisitF.get("periodicVisit.monthDay").value) {
                    const selectedDateDay = this.aspnVisitF.get("periodicVisit.monthDay").value;
                    for (let i = 0; i < totalVisits; i++) {
                        if (
                            dateFrom.getFullYear() == startDate.getFullYear() &&
                            dateFrom.getMonth() == startDate.getMonth() &&
                            startDate.getDate() > selectedDateDay
                        ) {
                            const dateFromMoment = moment(startDate).add(1, "month");
                            dateFromMoment.set("date", 1);
                            dateFromMoment.set("hour", 0);
                            dateFromMoment.set("minute", 0);
                            dateFromMoment.set("second", 0);
                            dateFromMoment.set("millisecond", 0);
                            startDate = dateFromMoment.toDate();
                        }
                        startDate.setDate(selectedDateDay);
                        visitsDates.push(new Date(startDate));
                        const dateFromMoment = moment(startDate).add(1, "month");
                        dateFromMoment.set("date", 1);
                        dateFromMoment.set("hour", 0);
                        dateFromMoment.set("minute", 0);
                        dateFromMoment.set("second", 0);
                        dateFromMoment.set("millisecond", 0);
                        startDate = dateFromMoment.toDate();
                    }
                }
            }
        }
        return visitsDates.filter((d) => {
            return !this.isYearRange(d, dateFrom);
        });
    }

    getWeekdayByName(weekDayName: string) {
        switch (weekDayName) {
            case "mon":
                return 1;
            case "tue":
                return 2;
            case "wed":
                return 3;
            case "thu":
                return 4;
            case "fri":
                return 5;
            case "sat":
                return 6;
            case "sun":
                return 0;
            default:
                return undefined;
        }
    }

    calculateVisitsByDate(dateFrom: Date, dateTo: Date) {
        const startDate = new Date(dateFrom);
        startDate.setHours(0, 0, 0, 0);
        const visitsDates = [];
        if (dateFrom && dateTo) {
            dateFrom.setHours(0, 0, 0, 0);
            dateTo.setHours(23, 59, 59, 999);
            if (this.aspnVisitF.get("periodicVisit.periodicity").value === "1") {
                while (startDate < dateTo) {
                    visitsDates.push(new Date(startDate));
                    startDate.setDate(startDate.getDate() + 1);
                }
            } else if (this.aspnVisitF.get("periodicVisit.periodicity").value === "2") {
                while (startDate < dateTo) {
                    visitsDates.push(new Date(startDate));
                    startDate.setDate(startDate.getDate() + 2);
                }
            } else if (this.aspnVisitF.get("periodicVisit.periodicity").value === "3") {
                while (startDate < dateTo) {
                    this.aspnVisitF.get("periodicVisit.weekDays.mon").value &&
                        startDate.getDay() === 1 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.tue").value &&
                        startDate.getDay() === 2 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.wed").value &&
                        startDate.getDay() === 3 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.thu").value &&
                        startDate.getDay() === 4 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.fri").value &&
                        startDate.getDay() === 5 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.sat").value &&
                        startDate.getDay() === 6 &&
                        visitsDates.push(new Date(startDate));
                    this.aspnVisitF.get("periodicVisit.weekDays.sun").value &&
                        startDate.getDay() === 0 &&
                        visitsDates.push(new Date(startDate));
                    startDate.setDate(startDate.getDate() + 1);
                }
            } else if (this.aspnVisitF.get("periodicVisit.periodicity").value === "4") {
                if (this.aspnVisitF.get("periodicVisit.monthDay").value) {
                    const selectedDate = Number(this.aspnVisitF.get("periodicVisit.monthDay").value);
                    if (selectedDate < startDate.getDate()) {
                        if (this.getMonthLastDay(startDate) < selectedDate) {
                            startDate.setDate(this.getNextMonthLastDay(startDate));
                        }
                        startDate.setMonth(startDate.getMonth() + 1);
                    }
                    if (selectedDate > this.getMonthLastDay(startDate)) {
                        startDate.setDate(this.getMonthLastDay(startDate));
                    } else {
                        startDate.setDate(selectedDate);
                    }
                    while (startDate < dateTo) {
                        const dateLastMonthDaydDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth() + 1,
                            0,
                            0,
                            0,
                            0
                        ).getDate();

                        if (dateLastMonthDaydDate < selectedDate) {
                            visitsDates.push(
                                new Date(
                                    startDate.getFullYear(),
                                    startDate.getMonth(),
                                    dateLastMonthDaydDate,
                                    0,
                                    0,
                                    0,
                                    0
                                )
                            );
                        } else {
                            visitsDates.push(
                                new Date(startDate.getFullYear(), startDate.getMonth(), selectedDate, 0, 0, 0, 0)
                            );
                        }
                        const dateNextLastMonthDayDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth() + 2,
                            0,
                            0,
                            0,
                            0,
                            0
                        ).getDate();
                        if (dateNextLastMonthDayDate < selectedDate) {
                            startDate.setDate(dateNextLastMonthDayDate);
                        }
                        startDate.setMonth(startDate.getMonth() + 1);
                    }
                } else if (
                    this.aspnVisitF.get("periodicVisit.every").value &&
                    this.aspnVisitF.get("periodicVisit.everyWeekDay").value
                ) {
                    const startDate = moment(dateFrom);
                    const endDate = moment(dateTo);
                    while (startDate.isBefore(endDate) || startDate.toDate().getTime() == dateTo.getTime()) {
                        const rezDate = this.weekNumberWeekDayOfMonth(
                            startDate.year(),
                            startDate.month(),
                            this.getWeekdayByName(this.aspnVisitF.get("periodicVisit.everyWeekDay").value),
                            Number(this.aspnVisitF.get("periodicVisit.every").value)
                        );
                        rezDate.getTime() >= dateFrom.getTime() &&
                            rezDate.getTime() <= dateTo.getTime() &&
                            visitsDates.push(new Date(rezDate));
                        startDate.add(1, "month");
                        startDate.set("date", 1);
                        startDate.set("hour", 0);
                        startDate.set("minute", 0);
                        startDate.set("second", 0);
                        startDate.set("millisecond", 0);
                    }
                }
            }
        }

        return visitsDates.filter((d) => !this.isYearRange(d, dateFrom));
    }

    getMonthLastDay(date: Date): number {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }
    getNextMonthLastDay(date: Date): number {
        return new Date(date.getFullYear(), date.getMonth() + 2, 0).getDate();
    }

    weekNumberWeekDayOfMonth(year, month, weekDay: number, weekNumb: number) {
        if (weekNumb < 5) {
            let founded = false;
            let weekCount = 0;
            const monthFirstDayDate = new Date(year, month, 1);
            const monthLastDayDate = new Date(year, month + 1, 0);
            while (!founded) {
                monthFirstDayDate.getDate() >= monthLastDayDate.getDate() && (founded = true);
                if (monthFirstDayDate.getDay() === weekDay) {
                    weekCount++;
                    if (weekCount === weekNumb) {
                        return monthFirstDayDate;
                    }
                }
                monthFirstDayDate.setDate(monthFirstDayDate.getDate() + 1);
            }
        } else {
            return this.lastWeekDayOfMonth(year, month, weekDay);
        }
    }

    lastWeekDayOfMonth(year, month, weekDay) {
        const lastDay = new Date(year, month + 1, 0);
        if (lastDay.getDay() < weekDay) {
            lastDay.setDate(lastDay.getDate() - 7);
        }
        lastDay.setDate(lastDay.getDate() - (lastDay.getDay() - weekDay));
        return lastDay;
    }

    onSave() {
        if (this.bsModalRef) {
            this.bsModalRef.hide();
        }
    }

    getAspnVisitProcedureDtosFromGrid(){
        let visitProceduresDtos: Partial<AspnVisitProcedureDto>[] = [] ;
        this.agVisitProcedureGridApi.forEachNode((node, i) => {
            visitProceduresDtos.push({
                bodySite: node.data.bodySite,
                procedureGroup: node.data.procedureGroup,
                procedures: node.data.procedures,
                relatedMedication: node.data.relatedMedication,
                recommendations: node.data.recommendations,

            })
        })

        return visitProceduresDtos;
    }

    formToDto(): AspnVisitDto {

        return {
            id: this.aspnVisitDto && this.aspnVisitDto.id,
            // 1: Suplanuotas, 2: Įvykęs, 3: Atšauktas
            status: { code: "1" },
            patient: this.aspnPatient,
            visitingPractitioner: this.aspnVisitF.get("visitingSpecialist").value,
            comment: this.aspnVisitF.get("comment").value,
            repeatPeriod: this.aspnVisitF.get("periodicVisit.periodicity").value && {
                code: this.aspnVisitF.get("periodicVisit.periodicity").value,
            },
            weekDays: Array.from(this.aspnVisitF.get("periodicVisit.weekDays").value)
                .filter(([key, value]) => value)
                .map(([key, value]) => {
                    return { code: key };
                }),
            everyMonthDay:
                this.aspnVisitF.get("periodicVisit.monthDay").value &&
                this.aspnVisitF.get("periodicVisit.monthDay").value,
            everyMonthWeekDaySequence: this.aspnVisitF.get("periodicVisit.every").value && {
                code: this.aspnVisitF.get("periodicVisit.every").value,
            },
            everyMonthWeekDayName: this.aspnVisitF.get("periodicVisit.everyWeekDay").value && {
                code: this.aspnVisitF.get("periodicVisit.everyWeekDay").value,
            },
            repeatUntilDate:
                this.aspnVisitF.get("periodicVisit.deadlineDate").value &&
                toDateDto(this.aspnVisitF.get("periodicVisit.deadlineDate").value),
            repeatUntilCount:
                this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value &&
                this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value,
            visitStartDate: toDateDto(this.visitsDates.at(0)),
            visitTimeFrom: this.aspnVisitF.get("visitTimeFrom").value && this.aspnVisitF.get("visitTimeFrom").value,
            visitTimeTo: this.aspnVisitF.get("visitTimeTo").value && this.aspnVisitF.get("visitTimeTo").value,
            periodVisits: this.repeatVisits(this.visitsDates),
            orderProcedures: this.toOrderProcedures(),
            visitProcedures: this.getAspnVisitProcedureDtosFromGrid(),
            deletingVisits: [],
        };
    }

    dtoToForm(dto: AspnVisitDto): AspnVisitValue {
        return {
            visitDate: fromDateDto(dto.visitStartDate),
            visitTimeFrom: dto.visitTimeFrom,
            visitTimeTo: dto.visitTimeTo,
            visitingSpecialist: dto.visitingPractitioner,
            visitingSpecialistDisplay: dto.visitingPractitioner
                ? dto.visitingPractitioner.familyName + " " + dto.visitingPractitioner.givenName
                : "",
            comment: dto.comment,
            periodicVisit: {
                periodicity: dto.repeatPeriod?.code,
                weekDays: {
                    mon: dto.weekDays?.find((p) => p.code === "mon") ? true : false,
                    tue: dto.weekDays?.find((p) => p.code === "tue") ? true : false,
                    wed: dto.weekDays?.find((p) => p.code === "wed") ? true : false,
                    thu: dto.weekDays?.find((p) => p.code === "thu") ? true : false,
                    fri: dto.weekDays?.find((p) => p.code === "fri") ? true : false,
                    sat: dto.weekDays?.find((p) => p.code === "sat") ? true : false,
                    sun: dto.weekDays?.find((p) => p.code === "sun") ? true : false,
                },
                monthDay: dto.everyMonthDay,
                every: dto.everyMonthWeekDaySequence?.code,
                everyWeekDay: dto.everyMonthWeekDayName?.code,
                deadlineDate: fromLocalDateTimeDto(dto.repeatUntilDate),
                deadlineVisitCount: dto.repeatUntilCount,
            },
            visitProcedures: [],
        };
    }

    isYearRange(dateEnd: Date, dateStart: Date) {
        const nextYearDate = new Date(dateStart);
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
        if (dateEnd.getTime() > nextYearDate.getTime()) {
            return true;
        }
        return false;
    }

    repeatVisits(visitDates: Date[]): Partial<AspnVisitDto>[] {
        return visitDates.map((d) => {
            return {
                id: null,
                status: { code: "1" },
                patient: this.aspnPatient,
                visitingPractitioner:
                    this.aspnVisitF.get("visitingSpecialist").value && this.aspnVisitF.get("visitingSpecialist").value,
                comment: this.aspnVisitF.get("comment").value,
                repeatPeriod: this.aspnVisitF.get("periodicVisit.periodicity").value && {
                    code: this.aspnVisitF.get("periodicVisit.periodicity").value,
                },
                weekDays: Array.from(this.aspnVisitF.get("periodicVisit.weekDays").value)
                    .filter(([key, value]) => value)
                    .map(([key, value]) => {
                        return { code: key };
                    }),
                everyMonthDay:
                    this.aspnVisitF.get("periodicVisit.monthDay").value &&
                    this.aspnVisitF.get("periodicVisit.monthDay").value,
                everyMonthWeekDaySequence: this.aspnVisitF.get("periodicVisit.every").value && {
                    code: this.aspnVisitF.get("periodicVisit.every").value,
                },
                everyMonthWeekDayName: this.aspnVisitF.get("periodicVisit.everyWeekDay").value && {
                    code: this.aspnVisitF.get("periodicVisit.everyWeekDay").value,
                },
                repeatUntilDate:
                    this.aspnVisitF.get("periodicVisit.deadlineDate").value &&
                    toDateDto(this.aspnVisitF.get("periodicVisit.deadlineDate").value),
                repeatUntilCount:
                    this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value &&
                    this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value,
                visitStartDate: toDateDto(d),
                visitTimeFrom: this.aspnVisitF.get("visitTimeFrom").value && this.aspnVisitF.get("visitTimeFrom").value,
                visitTimeTo: this.aspnVisitF.get("visitTimeTo").value && this.aspnVisitF.get("visitTimeTo").value,
                orderProcedures: this.toOrderProcedures(),
                visitProcedures:this.getAspnVisitProcedureDtosFromGrid(),
            };
        });
    }

    toOrderProcedures(): AspnVisitProcedureDto[] {
        // TODO need to recalculate !!!!!!!!!!!!!!!!!!!!!!!! This code is not good... Not all needed procedures added to the dto.
        return (
            this.aspnVisitDto &&
            this.aspnVisitDto.orderProcedures.filter((pp) => {
                return (<FormGroup[]>(<FormArray>this.aspnVisitF.get("orderProcedures")).controls).find(
                    (fp) => fp.get("procedureId").value === pp.id && fp.get("planedVisit").value
                );
            })
        );
    }

    //---------- form ValidatorFn functions

    deadlineDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (this.aspnVisitF) {
            if (this.aspnVisitF.get("periodicVisit.periodicity").value) {
                if (
                    this.aspnVisitF.get("periodicVisit.deadlineDate").value ||
                    this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value
                ) {
                    return null;
                } else {
                    return { inputIsRequired: "Privalomas laukas" };
                }
            }
        }
        return null;
    };

    deadlineCountValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (this.aspnVisitF) {
            if (this.aspnVisitF.get("periodicVisit.periodicity").value) {
                if (
                    this.aspnVisitF.get("periodicVisit.deadlineDate").value ||
                    this.aspnVisitF.get("periodicVisit.deadlineVisitCount").value
                ) {
                    return null;
                } else {
                    return { inputIsRequired: "Privalomas laukas" };
                }
            }
        }
        return null;
    };

    weekDayCheckboxesValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (this.aspnVisitF && this.aspnVisitF.get("periodicVisit.periodicity").value == 3) {
            const weekDays = (<FormGroup>this.aspnVisitF.get("periodicVisit.weekDays")).controls;
            if (
                !weekDays["mon"].value &&
                !weekDays["tue"].value &&
                !weekDays["wed"].value &&
                !weekDays["thu"].value &&
                !weekDays["fri"].value &&
                !weekDays["sat"].value &&
                !weekDays["sun"].value
            ) {
                return { weekDayCheckboxes: "Nors viena iš savaitės dienų privaloma pasirinkti." };
            }
        }
        return null;
    };

    perMonthInputsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (this.aspnVisitF && this.aspnVisitF.get("periodicVisit.periodicity").value == 4) {
            const monthDayControl = this.aspnVisitF.get("periodicVisit.monthDay");
            const everyControl = this.aspnVisitF.get("periodicVisit.every");
            const everyWeekDayControl = this.aspnVisitF.get("periodicVisit.everyWeekDay");
            if (monthDayControl.value || (everyControl.value && everyWeekDayControl.value)) {
                return null;
            } else {
                return { perMonthInputsError: "Privaloma pasirinkti." };
            }
        }
        return null;
    };

    planedVisitsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (this.aspnVisitF) {
            let visitProceduresEmpty = true;
            this.agVisitProcedureGridApi.forEachNode((node, i) => {
                visitProceduresEmpty =  false;
            })

            if (
                visitProceduresEmpty &&
                (<FormGroup[]>(<FormArray>this.aspnVisitF.get("orderProcedures")).controls).findIndex(
                    (fg) => fg.get("planedVisit").value
                ) == -1
            ) {
                return { noSelectedProcedures: "Nepasirinkta nei viena procedūra." };
            }
        }
        return null;
    };

    visitProcedureValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;

        if (visitProcedureFg) {
            if (visitProcedureFg.get("procedureGroup").value && visitProcedureFg.get("procedures").value.length < 1) {
                return { visitProcedureError: "Nepasirinkta procedūra." };
            }
        }
        return null;
    };

    timeRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            const timeFrom: string = visitProcedureFg.get("visitTimeFrom").value;
            const timeTo: string = visitProcedureFg.get("visitTimeTo").value;
            if (timeFrom && timeTo && Number(timeFrom.replace(":", "")) >= Number(timeTo.replace(":", ""))) {
                return { timeRangeError: "Blogai nurodytas laiko tarpas" };
            }
        }

        return null;
    };

    procedureApprovValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (
                (<FormGroup[]>(<FormArray>this.aspnVisitF.get("orderProcedures")).controls).findIndex(
                    (fg) => fg.get("procedureState").value && fg.get("planedVisit").value
                ) !== -1 ||
                (<FormGroup[]>(<FormArray>this.aspnVisitF.get("visitProcedures")).controls).findIndex(
                    (fg) => fg.get("procedureState").value
                ) !== -1
            ) {
                if (visitProcedureFg.get("planedVisit").value && !control.value) {
                    return { error: "Butinas" };
                }
            }
        }

        return null;
    };
    visitProcedureApprovValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (
                (<FormGroup[]>(<FormArray>this.aspnVisitF.get("orderProcedures")).controls).findIndex(
                    (fg) => fg.get("procedureState").value && fg.get("planedVisit").value
                ) !== -1 ||
                (<FormGroup[]>(<FormArray>this.aspnVisitF.get("visitProcedures")).controls).findIndex(
                    (fg) => fg.get("procedureState").value
                ) !== -1
            ) {
                if (!control.value) {
                    return { error: "Butinas" };
                }
            }
        }

        return null;
    };


    cancelNoteValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (visitProcedureFg.get("procedureState").value === "N" && !control.value) {
                return { error: "Butinas" };
            }
        }

        return null;
    };

    /// -------------------------------------------------

    canSubmitE025() {
        if (
            (<FormGroup[]>(<FormArray>this.aspnVisitF.get("orderProcedures")).controls).findIndex(
                (fg) => fg.get("procedureState").value && fg.get("planedVisit").value
            ) !== -1
            ||
            (<FormGroup[]>(<FormArray>this.aspnVisitF.get("visitProcedures")).controls).findIndex(
                (fg) => fg.get("procedureState").value
            ) !== -1
        ) {
            return true;
        }
        return false;
    }

    openConfirmModal() {
        console.log("updatingVisitDto periodinis ---->", this.aspnVisitF);
        this.aspnVisitF.markAllAsTouched();
        if (this.aspnVisitF.valid) {
            if (this.aspnVisitDto && this.aspnVisitDto.repeatPeriod) {
                this.visitsDates = this.calculateVisits();
                if (this.visitsDates.length < 1) {
                    this.visitsDates.push(new Date(this.aspnVisitF.get("visitDate").value));
                }
                const startDateVisits = this.aspnVisitDto.visitStartDate;
                const updatingVisitDto = this.formToDto();
                updatingVisitDto.periodVisits = updatingVisitDto.periodVisits.concat(
                    this.aspnVisitDto.periodVisits.filter((v) => {
                        if (fromDateDto(v.visitStartDate) < fromDateDto(startDateVisits) || v.status.code !== "1") {
                            return true;
                        }
                        return false;
                    })
                );
                updatingVisitDto.deletingVisits = this.aspnVisitDto.periodVisits.filter((v) => {
                    if (fromDateDto(v.visitStartDate) >= fromDateDto(startDateVisits) && v.status.code == "1") {
                        return true;
                    }
                    return false;
                });
                console.log("updatingVisitDto periodinis ---->", updatingVisitDto);
            } else {
                console.log("formToDto vienetinis --->", this.formToDto());
            }
            this.bsModalRef = this.modalService.show(this.confirmationModal);
        }
    }

    visitProcedureFG = new FormGroup({
        procedureGroup: new FormControl(null, [Validators.required]),
        procedures: new FormControl([], [this.visitProcedureValidator, Validators.required]),
        bodySite: new FormControl([]),
        relatedMedication: new FormControl([]),
        recommendations: new FormControl(""),
    });
    openVisitProcedureeditor(template: TemplateRef<any>) {
        this.visitProcedureFG.reset();
        this.bsModalRef = this.modalService.show(template);
    }
}
