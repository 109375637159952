import {Component, Input, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {Router} from "@angular/router";

@Component({
    selector: 'back-link',
    templateUrl: './back-link.component.html'
})
export class BackLinkComponent implements OnInit {

    @Input() title: string;
    @Input() subTitle: string | string[];
    @Input() path: string;
    @Input() linkClass: string;
    @Input() badgeText: string | undefined;
    @Input() badgeClass: string | undefined;

    protected readonly Array = Array;

    constructor(private location: Location, private router: Router) {

    }

    ngOnInit(): void {

    }

    goBack(): void {
        this.location.back();
    }
}
