import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EntityModel } from "../../../../../shared/models/entity.model";
import { forkJoin } from "rxjs";
import { ApiV2Entities } from "../../../../../shared/services/api-v2-entities";
import {DiagnosisFilters} from "../../../../../shared/models/diagnosis.model";

@Component({
    selector: 'app-diagnosis-page-filters',
    templateUrl: './diagnosis-page-filters.component.html'
})
export class DiagnosisPageFiltersComponent {
    @Output() filtering: EventEmitter<DiagnosisFilters> = new EventEmitter<DiagnosisFilters>();

    statuses: EntityModel[] = [];
    confidences: EntityModel[] = [];
    severities: EntityModel[] = [];
    categories: EntityModel[] = [];
    types: EntityModel[] = [];

    formGroup: FormGroup = this.fb.group({
        fromDate: this.fb.control(null),
        toDate: this.fb.control(null),
        diagnosisStatus: this.fb.control(null),
        diagnosisConfidence: this.fb.control(null),
        diagnosisSeverity: this.fb.control(null),
        diagnosisCategory: this.fb.control(null),
        diagnosisType: this.fb.control(null),
    })

    constructor(
        private readonly fb: FormBuilder,
        private apiV2Entities: ApiV2Entities
    ) {
        forkJoin([
            this.apiV2Entities.getEntitiesList('eHDSI_StatusCode'),
            this.apiV2Entities.getEntitiesList('condition-verification-status'),
            this.apiV2Entities.getEntitiesList('condition-severity'),
            this.apiV2Entities.getEntitiesList('condition-category'),
            this.apiV2Entities.getEntitiesList('condition-diagnosis-type'),
        ])
            .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.statuses = res[0];
                this.confidences = res[1];
                this.severities = res[2];
                this.categories = res[3];
                this.types = res[4];
            });
        }

    clearFilters(): void {
        this.formGroup.reset();
        this.filtering.emit(this.formGroup.value);
    }

    applyFilters(): void {
        this.filtering.emit(this.formGroup.value);
    }
}
