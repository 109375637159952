import { EntityModel } from "src/app/shared/models/entity.model";
import { MedicationModel } from "src/app/shared/models/medication.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { fromDateDto, fromLocalDateTimeDto, toDateDto, toLocalDateTimeDto } from "src/app/shared/services/utils/utils";

export interface AspnVisitClassifiers {
    periodicity?: AspnVisitClassifier[];
    proceduresGroup?: AspnVisitClassifier[];
    placeInBodyProcedure?: AspnVisitClassifier[];
    procedureState?: AspnVisitClassifier[];
}

export interface AspnVisitClassifier extends EntityModel {
    children?: AspnVisitClassifier[];
}

export interface AspnVisitProcedureDto {
    id: number;
    procedureGroup: Partial<EntityModel>;
    procedures: Partial<EntityModel>[];
    bodySite: Partial<EntityModel>[];
    relatedMedication: Partial<MedicationModel>[];
    urgent: boolean;
    repeatPeriod: Partial<EntityModel>;
    frequency: number;
    procedureFromDate: string;
    procedureToDate: string;
    recommendations: string;
}

export interface AspnVisitProcedureValue {
    id: number;
    procedureGroup: string;
    procedures: Partial<EntityModel>[];
    bodySite: Partial<EntityModel>[];
    relatedMedication: Partial<MedicationModel>[];
    urgent: boolean;
    repeatPeriod: Partial<EntityModel>;
    frequency: number;
    procedureFromDate: Date;
    procedureToDate: Date;
    recommendations: string;
}

export interface AspnVisitDto {
    id: number;
    status: Partial<EntityModel>;
    patient: Partial<PatientModel>;
    visitingPractitioner: Partial<PractitionerModel>;
    comment: string;
    repeatPeriod: Partial<EntityModel>;
    weekDays: Partial<EntityModel>[];
    everyMonthDay: number;
    everyMonthWeekDaySequence: Partial<EntityModel>;
    everyMonthWeekDayName: Partial<EntityModel>;
    repeatUntilDate: string;
    repeatUntilCount: number;
    visitStartDate: string;
    visitTimeFrom: string;
    visitTimeTo: string;
    orderProcedures: AspnVisitProcedureDto[];
    visitProcedures: Partial<AspnVisitProcedureDto>[];
    periodVisits: Partial<AspnVisitDto>[];
    deletingVisits: Partial<AspnVisitDto>[];
}

export function toAspnVisitProcedureDto(val: AspnVisitProcedureValue): AspnVisitProcedureDto {
    return {
        procedureGroup: toEntityModel(val.procedureGroup),
        urgent: val.urgent,
        repeatPeriod: val.repeatPeriod,
        frequency: val.frequency,
        relatedMedication: val.relatedMedication,
        procedures: val.procedures,
        bodySite: val.bodySite,
        procedureFromDate: toLocalDateTimeDto(val.procedureFromDate),
        procedureToDate: toLocalDateTimeDto(val.procedureFromDate),
        recommendations: val.recommendations,
        id: val.id,
    };
}

export function fromAspnVisitProcedureDto(val: AspnVisitProcedureDto): AspnVisitProcedureValue {
    return {
        procedureGroup: fromEntityModel(val.procedureGroup),
        urgent: val.urgent,
        repeatPeriod: val.repeatPeriod,
        frequency: val.frequency,
        relatedMedication: val.relatedMedication,
        procedures: val.procedures,
        bodySite: val.bodySite,
        procedureFromDate: fromLocalDateTimeDto(val.procedureFromDate),
        procedureToDate: fromLocalDateTimeDto(val.procedureFromDate),
        recommendations: val.recommendations,
        id: val.id,
    };
}

export function toEntityModel(code: string): Partial<EntityModel> {
    if (code) {
        return { code: code };
    }
    return undefined;
}

export function fromEntityModel(entMod: Partial<EntityModel>): string {
    return entMod.code;
}

interface AspnVisitPeriodValue {
    periodicity: string;
    weekDays: {
        mon: boolean;
        tue: boolean;
        wed: boolean;
        thu: boolean;
        fri: boolean;
        sat: boolean;
        sun: boolean;
    };
    monthDay: number;
    every: string;
    everyWeekDay: string;
    deadlineDate: Date;
    deadlineVisitCount: number;
}

export interface AspnVisitValue {
    visitDate: Date;
    visitTimeFrom: string;
    visitTimeTo: string;
    visitingSpecialist: Partial<PractitionerModel>;
    visitingSpecialistDisplay: string;
    comment: string;
    periodicVisit: Partial<AspnVisitPeriodValue>;
    orderProcedures?: {
        procedureId?: string;
        planedVisit?: boolean;
    }[];

    visitProcedures?: AspnVisitProcedureValue[];
}

export interface AspnVisitsCalendarEvent {
    title: string;
    start: string;
    end: string;
    status: Partial<EntityModel>,
    patientFullName: string;
    visitId: number;
    periodVisitId: number;
    procedures: Partial<EntityModel>[];
    editableVisit: boolean;
}
