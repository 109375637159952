import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'predicate'})
export class PredicatePipe implements PipeTransform {
    constructor() {
    }

    transform(filter: any): any {
        //return filter.show === undefined || filter.show === null || filter.show;
        if (filter.show === undefined || filter.show === null) {
            return filter;
        }
        return filter.show;
    }
}