ng1App.controller('doctor.patient.CertificatesCtrl', 
    ['$scope', '$routeParams', 'MessageService', 'IntegratedComponentContext',
     function($scope, $routeParams, MessageService, IntegratedComponentContext) {
    $scope.$emit('setHelpPage', 'perzireti-paciento-pazymu-sarasa');
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;

    $scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

}]);