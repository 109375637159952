ng1App.controller('document.E014viewCtrl', 
    ['$scope', '$routeParams', '$filter',
     function ($scope, $routeParams, $filter) {
    $scope.data = {};
    $scope.result = {};
    $scope.patientId = $routeParams.patientId;
    
    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: _.range(7)
    }
    
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'}; 
}]);