<div>
    <app-title backPath="/dp" backTitle="{{'Mano darbalaukis' | translate}}" showFilterButton="true">
        {{'Siuntimai ASPN' | translate}}
    </app-title>
    <nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
        id="aspnF027ListComponent">
        <custom-search before-title [name]="'quick-search'" [isAdvanced]="true" (filterService)="loadAspnList($event)" class="border-0 border-dashed-bottom">
            <aspnF027list-filter (onSearch)="loadAspnList($event)"></aspnF027list-filter>
        </custom-search>
        <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid" [columnDefs]="aspnF027ListColDefs"
            [defaultColDef]="defaultColDef" [rowData]="aspnF027ListData$ | async" [rowSelection]="'multiple'"
            (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
            [domLayout]="'autoHeight'" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
            [suppressPaginationPanel]="true" />
    </nhr-record-widget>
    <ag-pagination-bar></ag-pagination-bar>
</div>