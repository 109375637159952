import {Component} from '@angular/core';
import {ILoadingOverlayAngularComp} from 'ag-grid-angular';
import {ILoadingOverlayParams} from 'ag-grid-community';

export interface ICustomLoadingOverlayParams {
    loadingMessage: string;
}

@Component({
    selector: 'ag-overlay-loading-component',
    templateUrl: './ag-overlay-loading.component.html',
})
export class AgOverlayLoadingComponent implements ILoadingOverlayAngularComp {
    public params!: ILoadingOverlayParams & ICustomLoadingOverlayParams;

    agInit(params: ILoadingOverlayParams & ICustomLoadingOverlayParams): void {
        this.params = params;
    }
}