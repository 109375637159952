<div class="widget" [class]="classDisabled" [ngClass]="closed ? 'closed' : undefined" [class]="class">
    <div class="widget-title" (click)="toggle()" (keypress)="toggle()" tabindex="0">
        <div class="text">
            <a class="float-end chevron" [ngClass]="closeable ? '' : 'd-none'">
                <span class="text-body" [ngClass]="closeable && closed ? 'up' : 'down'"></span>
                <span class="sr-only" translate>Collapse/Expand</span>
            </a>
            {{title}}
        </div>
    </div>

    <ng-template #widgetData>
        <ng-content/>
    </ng-template>

    <ol *ngIf="list && !mixed" class="slats" [hidden]="closed">
        <ng-container *ngTemplateOutlet="widgetData"/>
    </ol>

    <div class="widget-body {{bodyClass}}" *ngIf="!list && !mixed" [hidden]="closeable && closed">
        <ng-container *ngTemplateOutlet="widgetData"/>
    </div>

    <div class="{{bodyClass}}" *ngIf="mixed" [hidden]="closed">
        <ng-container *ngTemplateOutlet="widgetData"/>
    </div>
</div>