ng1App.factory("NumberGenerationRulesApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/sequences';

    return $resource('', {}, {
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        list: {
            method: 'GET',
            url: URL_BASE + '/',
            isArray: true
        },
        getByCode: {
            method: 'GET',
            url: URL_BASE + '/byCode/:code'
        }
    });
}]);
