<div class="modal-header">
    <h4 class="modal-title pull-left">{{'doc.dia.dia' | translate}} - {{ diagnose.code.code + ' ' + diagnose.code.name }}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="formGroup">
    <div class="row align-items-center">
        <div class="col">
            <div class="row">
                <div class="col text-nowrap">
                    <span class="fw-bold">{{'doc.dia.mod.reg' | translate}}: </span>
                    <ng-container *ngIf="diagnose?.recordedDate; else noRecordedDate">
                        {{ diagnose.recordedDate | date:'yyyy-MM-dd' }}
                    </ng-container>
                    <ng-template #noRecordedDate>-</ng-template>
                </div>
                <div class="col text-nowrap">
                    <span class="fw-bold">{{'doc.dia.mod.ren' | translate}}: </span>
                    <ng-container *ngIf="diagnose?.dateAsserted; else noRenewalDateDate">
                        {{ diagnose.dateAsserted | date:'yyyy-MM-dd' }}
                    </ng-container>
                    <ng-template #noRenewalDateDate>-</ng-template>
                </div>
            </div>
        </div>
        <div class="col-sm-auto req d-flex flex-column flex-sm-row align-items-sm-center">
            <app-date-picker [fControl]="formGroup.get('onsetDate')" class="col-form-label d-flex" inputId="fromDate" [placeholder]="'doc.dia.mod.dat' | translate"></app-date-picker>
            <div class="mx-sm-3">-</div>
            <app-date-picker [fControl]="formGroup.get('endDate')" class="col-form-label d-flex" inputId="toDate" [placeholder]="'doc.dia.mod.dat' | translate"></app-date-picker>
        </div>
    </div>
    <fieldset class="row mb-3" *ngIf="enableTypesEdit">
        <div class="col-12 req">
            <legend class="float-none col-form-label text-nowrap mt-0">{{'doc.dia.mod.typ' | translate}}</legend>
        </div>
        <div *ngFor="let type of types; let i = index" class="col-6 col-xl-3">
            <app-radio-button
                    id="type{{type.code}}"
                radioName="type{{ i }}"
                labelText='{{ type.name | capitalize }}'
                    [radioValue]="type.code"
                [fControl]="formGroup.get('diagnosisType')"
            />
        </div>
    </fieldset>
    <hr class="mt-0 mb-2 mx-n3">
    <div class="row">
        <fieldset class="col-sm-6 col-xl req">
            <legend class="float-none field-required col-form-label pt-0">{{'doc.dia.sts' | translate}}</legend>
            <ng-container *ngFor="let status of statuses; let i = index">
                <app-radio-button
                    id="status{{status.code}}"
                    radioName="status{{ i }}"
                    labelText='{{ status.name | capitalize }}'
                    formClass="req"
                    [radioValue]="status.code"
                    [fControl]="formGroup.get('diagnosisStatus')"
                />
            </ng-container>
        </fieldset>
        <fieldset class="col-sm-6 col-xl req">
            <legend class="float-none field-required col-form-label pt-0">{{'doc.dia.con' | translate}}</legend>
            <ng-container *ngFor="let confidence of confidences; let i = index">
                <app-radio-button
                    id="confidence{{confidence.code}}"
                    radioName="confidence{{ i }}"
                    labelText='{{ confidence.name | capitalize }}'
                    [radioValue]="confidence.code"
                    [fControl]="formGroup.get('diagnosisConfidence')"
                />
            </ng-container>
        </fieldset>
        <fieldset class="col-sm-6 col-xl">
            <legend class="float-none col-form-label pt-0">{{'doc.dia.sev' | translate}}</legend>
            <ng-container *ngFor="let severity of severities; let i = index">
                <app-radio-button
                    id="severity{{severity.code}}"
                    radioName="severity{{ i }}"
                    labelText='{{ severity.name | capitalize }}'
                    [radioValue]="severity.code"
                    [fControl]="formGroup.get('diagnosisSeverity')"
                />
            </ng-container>
        </fieldset>

        <fieldset class="col-sm-6 col-xl req">
            <legend class="float-none col-form-label field-required pt-0">{{'doc.dia.cat' | translate}}</legend>
            <ng-container *ngFor="let category of categories; let i = index">
                <app-radio-button
                    id="category{{category.code}}"
                    radioName="category{{ i }}"
                    labelText='{{ category.name | capitalize }}'
                    formClass="mb-0 req"
                    [radioValue]="category.code"
                    [fControl]="formGroup.get('diagnosisCategory')"
                />
            </ng-container>
        </fieldset>
    </div>
    <app-input
        id="descriptionTextarea"
        type="textarea"
        [labelText]="'doc.dia.lis.des' | translate"
        class="d-block mb-3"
        [fControl]="formGroup?.get('notes')"
    />
    <app-input
        id="circumstancesTextarea"
        type="textarea"
        [labelText]="'doc.dia.lis.cir' | translate"
        [fControl]="formGroup?.get('circumstances')"
    />
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid" (click)="close(true)">{{'doc.dia.btn.con' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="close()">{{'doc.dia.btn.can' | translate}}</button>
</div>
