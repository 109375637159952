ng1App.controller('doctor.TasksCtrl', 
    ['$scope', '$routeParams', '$modal', '$filter', 'TasksApiFactory', 'SessionApiFactory', 'TypeaheadSearchHandlers', 'IntegratedComponentContext', 'MessageService', 'FormLoaderService', 'DpSessionContext', 'SearchController',
     function ($scope, $routeParams, $modal, $filter, TasksApiFactory, SessionApiFactory, TypeaheadSearchHandlers, IntegratedComponentContext, MessageService, FormLoaderService, DpSessionContext, SearchController)  {
    $scope.$emit('setHelpPage', 'perzireti-uzduociu-sarasa');
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;

	$scope._patientMode = $routeParams.patientId && true;
	var practitioner = SessionApiFactory.getPractitioner();

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    practitioner.$promise.then(function () {
        var defaultQ = {
            practitionerId: ($scope._patientMode ? null : practitioner.id),
            patientId: ($scope._patientMode ? $routeParams.patientId : null)
        };
        $scope.filters2 = [
            {title: 'dp.use.tas.rec.all', query: angular.extend({statuses: ['SUBMITTED', 'ABORTED', 'PENDING', 'COMPLETE', 'CANCELLED']}, defaultQ) },
            {title: 'dp.use.tas.rec.my', query: angular.extend({targetPractitionerId: practitioner.id}, defaultQ)},
            {title: 'dp.use.tas.rec.myc', query: angular.extend({authorPractitionerId: practitioner.id}, defaultQ)},
            {title: 'dp.use.tas.rec.act', query: angular.extend({statuses: ['SUBMITTED', 'ABORTED']}, defaultQ)},
            {title: 'dp.use.tas.rec.sto', query: angular.extend({statuses: ['PENDING']}, defaultQ)},
            {title: 'dp.use.tas.rec.com', query: angular.extend({statuses: ['COMPLETE']}, defaultQ)},
            {title: 'dp.use.tas.rec.can', query: angular.extend({statuses: ['CANCELLED']}, defaultQ)}
        ];
        if (!$scope._patientMode) {
            // Add additional filter
            $scope.filters2.splice(3, 0, {
                title: 'dp.use.tas.rec.myp',
                query: {
                    careProviderId: practitioner.id
                }
            });
        }
        _.each($scope.filters2, function (filter) {
            TasksApiFactory.getCount(filter.query, function (data) {
                filter.count = data.value;
            }, function () {
                filter.count = 0;
            });
        });

        $scope.searchCtrl = new SearchController({
            viewId: 'DPP_TASKS_' + ($scope._patientMode ? 'patient' : 'user') ,
            dialogTpl: '/templates/portal/doctor/tasks/modals/search.html',
            count: 10,
            initScope: function (scope) {
                scope.patientMode = $scope._patientMode;
                scope.docTypes = [
                    { code: 'e025', id: 'type1', name: 'E025 Ambulatorinio apsilankymo aprašymas' },
                    { code: 'e003', id: 'type2', name: 'E003 Stacionaro epikrizė' },
                    { code: 'e027', id: 'type3', name: 'E027 Medicinos dokumentų išrašas / siuntimas' },
                    { code: 'e027a', id: 'type4', name: 'E027-ats Atsakymas į siuntimą' },
                    { code: 'e014', id: 'type5', name: 'E014 Patologijos tyrimo užsakymas' },
                    { code: 'e014a', id: 'type6', name: 'E014-ats Patologijos tyrimo atsakymas' },
                    { code: 'e200', id: 'type7',  name: 'E200 Laboratorinio tyrimo užsakymas' },
                    { code: 'e200a', id: 'type8', name: 'E200-ats Laboratorinio tyrimo rezultatų (duomenų) protokolas' },
                    { code: 'e027_1', id: 'type9', name: 'E027-1 Vaiko sveikatos pažymėjimas' },
                    { code: 'e047', id: 'type10', name: 'E047 Privalomojo sveikatos patikrinimo medicininė pažyma' },
                    { code: 'e048', id: 'type11', name: 'E048 Asmens medicininė knygelė (sveikatos pasas)' },
                    { code: 'e049', id: 'type12', name: 'E049 Asmens privalomojo sveikatos tikrinimo kortelė' },
                    { code: 'e083_1', id: 'type13', name: 'E083-1 Vairuotojo sveikatos patikrinimo medicininė pažyma' }
                ];
                scope.toggleDocTypes = function (checked) {
                    angular.forEach(scope.docTypes, function (val) {
                        scope.form.docTypes[val.code] = checked;
                    });
                };
                scope.searchPatient = TypeaheadSearchHandlers.PATIENTS;
                scope.searchPatientChange = function (val) {
                    if (val && val.id) {
                        scope.form.patientId = val.id;
                    }
                };
                scope.searchAuthor = function (text) {
                    if (scope.form._authorType == 'patient') {
                        return scope.searchPatient(text);
                    } else {
                        return TypeaheadSearchHandlers.PRACTITIONERS(text);
                    }
                };
                scope.searchAuthorChange = function (author) {
                    if (author && author.id) {
                        if (scope.form._authorType == 'patient') {
                            scope.form.authorPatientId = author.id;
                        } else {
                            scope.form.authorPractitionerId = author.id;
                        }
                    }
                };
                scope.searchTargetChange = function (target) {
                    if (target && target.id) {
                        if (scope.form._targetType == 'practitioner') {
                            scope.form.targetPractitionerId = target.id;
                        } else {
                            scope.form.targetOrganizationId = target.id;
                        }
                    }
                };
                scope.searchTarget = function (text) {
                    if (scope.form._targetType == 'practitioner') {
                        return TypeaheadSearchHandlers.PRACTITIONERS(text);
                    } else {
                        return TypeaheadSearchHandlers.ORGANIZATIONS(text);
                    }
                };
            },
            initForm: function () {
                return {
                    filter: $scope.filters2[0],
                    docTypes: {},
                    _targetType: 'practitioner',
                    _authorType: 'patient'
                };
            },
            searchApi: TasksApiFactory.getList,
            showPageCounter: true,
            formToQuery: function (form) {
                var query: any = {
                    docTypes: []
                };
                switch (form._searchType) {
                    case 'quick':
                        form.filter = undefined;
                        query.ftq = form.ftq;
                        break;
                    case 'filter':
                        angular.extend(query, form.filter.query);
                        break;
                    case 'advanced':
                    default:
                        query.practitionerId = ($scope._patientMode ? null : practitioner.id);
                        query.dateFrom = form.dateFrom;
                        query.dateTo = form.dateTo;
                        if (form._targetType == 'practitioner') {
                            query.targetPractitionerId = form.targetPractitionerId;
                        } else {
                            query.targetOrganizationId = form.targetOrganizationId;
                        }
                        if (form._authorType == 'patient') {
                            query.authorPatientId = form.authorPatientId;
                        } else {
                            query.authorPractitionerId = form.authorPractitionerId;
                        }
                        query.patientId = $scope._patientMode ? $routeParams.patientId : form.patientId;

                        // TODO: angular.forEach notation kind of requires key to be number? Error in the code?
                        angular.forEach(form.docTypes, function (val, key: any) {
                            if(val){
                                switch(key){
                                    case 'e027_1':
                                        query.docTypes.push('e027_1_I');
                                        query.docTypes.push('e027_1_II');
                                        break;
                                    case 'e047':
                                        query.docTypes.push('e047_employer');
                                        query.docTypes.push('e047_practitioner');
                                        break;
                                    case 'e048':
                                        query.docTypes.push('e048_employer');
                                        query.docTypes.push('e048_practitioner');
                                        break;
                                    default:
                                        query.docTypes.push(key);
                                }
                            }
                        });

                }
                return query;
            }
        });

        $scope.changeFilter = function (filter) {
            $scope.searchCtrl.clear();
            $scope.searchCtrl._form.filter = filter;
            $scope.searchCtrl.searchFilter();
        };

        DpSessionContext.getSettings(function (data) {
            $scope.searchCtrl.setCount(data.count_per_page);
            $scope.searchCtrl.searchLastOrAll();
        });

    });


    //set integrated component data
	$scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();
    if($scope.isIntegratedComponent) {
        if(!IntegratedComponentContext.isInitialized()) {
            IntegratedComponentContext.setData($routeParams.encounterId);
        }
    }

}]);