<h1>E096 Nėštumo ir gimdymo istorija</h1>
<nhr-record-widget closable="true" [title]="e096FormsNames.NT">
    <app-e096-nt-form [initialFormValue]="initialNTFormValue"></app-e096-nt-form>
</nhr-record-widget>
<nhr-record-widget closable="true" [title]="e096FormsNames.GE">
    <app-e096-ge-form [initialFormValue]="initialGEFormValue"></app-e096-ge-form>
</nhr-record-widget>
<nhr-record-widget
    closable="true"
    [title]="e096FormsNames.PART"
    headerAdditionalButtonIcon="fa-solid fa-arrows-up-down-left-right fs-4 rotate-45"
    headerAdditionalButtonName="Expand partogram"
    (headerAdditionalButtonClick)="openPartogram()"
>
    <app-e096-part-table></app-e096-part-table>
</nhr-record-widget>
<nhr-record-widget closable="true" [title]="e096FormsNames.PTKL">
    <app-e096-ptkl-form [initialFormValue]="initialPTKLFormValue"></app-e096-ptkl-form>
</nhr-record-widget>
<nhr-record-widget closable="true" [title]="e096FormsNames.CPO">
    <app-e096-cpo-form [initialFormValue]="initialCPOFormValue"></app-e096-cpo-form>
</nhr-record-widget>
<nhr-record-widget closable="true" [title]="e096FormsNames.N">
    <app-e096-n-form [initialFormValue]="initialNFormValue"></app-e096-n-form>
</nhr-record-widget>
<nhr-record-widget closable="true" [title]="e096FormsNames.NA">
    <app-e096-na-form [initialFormValue]="initialNAFormValue"></app-e096-na-form>
</nhr-record-widget>
