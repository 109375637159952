ng1App.controller('doctor.user.RepresentativeEditCtrl', 
    ['$scope', '$filter', '$modalConfirm', '$routeParams', 'PersonRepresentationsApiFactory', 'SessionContext', 'MessageService', 'RepresentationCommons', '$routeSegment',
     function ($scope, $filter, $modalConfirm, $routeParams, PersonRepresentationsApiFactory, SessionContext, MessageService, RepresentationCommons, $routeSegment)  {
	MessageService.clearAll();
	
	$scope.organization = SessionContext.organization;
	$scope.mode = $routeParams.id ? 'edit' : 'new';
	$scope.practitioner = SessionContext.practitioner;
	$scope.practitioner.$promise.then(function (practitioner) {
		if ($scope.mode == 'edit') {
            $scope.$emit('setHelpPage', 'redaguoti-atstovavimo-informacija');
			$scope.representation = PersonRepresentationsApiFactory.get({id: $routeParams.id});
			$scope.configuration = {};
		} else {
            $scope.$emit('setHelpPage', 'kurti-atstovavima');
			$scope.representation = {ips: []};
			if (practitioner.role) {
				$scope.configuration = PersonRepresentationsApiFactory.getPractitionerConfiguration({practitionerRole: practitioner.role.code}, function (data) {
                                        $scope.representation.person = {fhirId: practitioner.id};
					$scope.representation.validFrom = data.dateFrom;
					$scope.representation.type = 'PRACTITIONER';
					$scope.representation.representativeType = data.permittedRepresentativeTypes[0];
				});
			} else {
				MessageService.error($filter('translate')('err.per.rep.pra.no.rol'));
			}
		}
	});
	
	$scope.save = function () {
		MessageService.clearAll();
		RepresentationCommons.save($scope.representation, $scope.mode, '/dp/representatives', $scope.representationForm);
	};
	
	$scope.clean = function(){
        $modalConfirm.show({ title : $filter('translate')('inf.clean.form.tit'), content : $filter('translate')('inf.clean.form') } ).then(function() {
        	$routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
    };

}]);