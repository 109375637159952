ng1App.directive("unfinishedRecords", [
    "$modal",
    "UnfinishedRecordsApiFactory",
    "TypeaheadSearchHandlers",
    "SessionContext",
    "FormLoaderService",
    "DpSessionContext",
    function (
        $modal,
        UnfinishedRecordsApiFactory,
        TypeaheadSearchHandlers,
        SessionContext,
        FormLoaderService,
        DpSessionContext
    ) {
        return {
            templateUrl: "/templates/portal/doctor/common/unfinished_records_list.html",
            restrict: "E",
            replace: false,
            scope: {
                organizationId: "=",
                patientId: "=",
                onResult: "=",
                onSearch: "=",
            },
            link: function (scope: any, element, attrs) {
                FormLoaderService.startFormLoading();
                var PAGE_SIZE = 10;

                scope.page = 1;
                scope._ = _;
                scope.loading = false;
                scope.documents = [];
                scope.rowsCount = 0;
                scope.pageCount = 0;
                scope.filter = {};
                scope.form = {};
                scope.additional = {};
                scope.selectedPractitioners = [];
                scope.noCount = true;
                // -------- FILTER DEFS -----------
                scope._filterDefs = [
                    // {title: 'dpp.unf.rec.rec.all', type: null},
                    { title: "dpp.unf.rec.rec.prepare", type: "queued_for_signing", noCount: scope.noCount },
                    {
                        title: "dpp.unf.rec.rec.prepare.doctor",
                        type: "queued_for_signing",
                        currentPractitioner: true,
                        noCount: scope.noCount,
                    },
                    { title: "dpp.unf.rec.rec.notPrepareSign", type: "preliminary", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.ob", type: "order", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.ref", type: "sending", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.refex", type: "messages", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.ans", type: "e027a", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.cer", type: "certificates", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.patientIn", type: "e003", noCount: scope.noCount },
                    { title: "dpp.unf.rec.rec.amb", type: "e025", noCount: scope.noCount },
                ];

                scope.currentFilter = scope._filterDefs[1];

                DpSessionContext.practitioner.$promise.then(countAll);

                function countAll() {
                    if (!scope.noCount) {
                        _.each(scope._filterDefs, function (f) {
                            UnfinishedRecordsApiFactory.count(
                                {
                                    patient: scope.patientId,
                                    type: f.type,
                                    organization: scope.organizationId,
                                    practitioner: f.currentPractitioner ? DpSessionContext.practitioner.id : null,
                                },
                                function (res) {
                                    f.count = res.val;
                                }
                            );
                        });
                    }
                }

                // -------- end FILTER DEFS -----------

                scope.showPageSeparator = function (index) {
                    return (index + 1) % PAGE_SIZE === 0;
                };

                scope.selectedPatients = [];
                scope.search = function (query) {
                    scope.page = 1;
                    // scope.documents = [];
                    (scope.onSearch || angular.noop)();
                    // return scope.loadMore();
                    return loadUnfinishedDocuments();
                };

                scope.docsNotFound = function () {
                    return scope.documents.length === 0 && !scope.loading;
                };

                scope.loadMore = function () {
                    var result = loadUnfinishedDocuments();
                    scope.page++;
                    return result;
                };

                var loadUnfinishedDocuments = function () {
                    var params: any = {
                        page: scope.page,
                        count: PAGE_SIZE,
                        patient: scope.patientId,
                        organization: scope.organizationId,
                    };

                    var type = scope.currentFilter.type;
                    if (!type || type === "none") {
                        for (var attrname in scope.filter) {
                            params[attrname] = scope.filter[attrname];
                        }
                        if (scope.selectedPractitioners.length > 0) {
                            params.practitioner = scope.selectedPractitioners.map(function (val) {
                                return val.id;
                            });
                        }
                        if (scope.selectedPatients.length > 0) {
                            params.patient = scope.selectedPatients.map(function (val) {
                                return val.id;
                            });
                        }
                    } else {
                        params.type = type;
                        if (scope.currentFilter.currentPractitioner) {
                            params.practitioner = DpSessionContext.practitioner.id;
                        }
                    }
                    scope.loading = true;
                    return UnfinishedRecordsApiFactory.search(params, function (res) {
                        scope.loading = false;
                        scope.documents = [];

                        if (type != scope.currentFilter.type) {
                            // params changed.. ignore results
                            return;
                        }

                        (scope.onResult || angular.noop)(res);

                        var rowNumberToLoad = (scope.page - 1) * 10;
                        var repeating = rowNumberToLoad + 10;
                        var repeatingTill = repeating > res.length ? res.length : repeating;

                        for (var i = rowNumberToLoad; i < repeatingTill; i++) {
                            scope.documents.push(res[i]);
                        }

                        // scope.documents = scope.documents.concat(res);
                        console.log("scope.documents", scope.documents);
                        // scope.showLoadMore = res.length >= PAGE_SIZE;
                        scope.rowsCount = res.length;
                        scope.pageCount = Math.ceil(res.length / 10);
                    });
                };
                scope.showLoadMore = true;

                DpSessionContext.getSettings(function (data) {
                    PAGE_SIZE = data.count_per_page || 10;
                    FormLoaderService.endFormLoading(scope.search().$promise);
                });

                scope.changeType = function (f) {
                    scope.currentFilter = f;
                    // scope.documents = [];
                    scope.filter = [];
                    scope.page = 0;
                    scope.search();
                };

                var modal = $modal({
                    scope: scope,
                    title: "testas", //'Išplėstinė paieška',
                    contentTemplate: "/templates/portal/doctor/common/unfinished_records_search_modal.html",
                });
                scope.showAdvancedSearch = function () {
                    scope.cancelErr = 0;
                    scope.documentCancelReason = {};
                    modal.$promise.then(function () {
                        modal.show();
                    });

                    scope.submitSearch = function () {
                        scope.currentFilter = scope._filterDefs[0];
                        modal.hide();
                        scope.search(scope.filter);
                    };
                };

                scope.clearFilter = function () {
                    scope.filter = {
                        certificateType: [],
                        status: [],
                    };
                    scope.currentFilter = scope._filterDefs[0];
                    scope.selectedPractitioners = [];
                    scope.selectedPatients = [];
                    scope.search(scope.filter);
                };

                scope.searchPractitioners = function (query) {
                    return TypeaheadSearchHandlers.PRACTITIONERS(query, SessionContext.organization.id);
                };
                scope.searchPractitionersChange = function (v) {
                    if (v && v.id) {
                        scope.selectedPractitioners.push(v);
                        scope.additional.practitioner = null;
                    }
                };

                scope.removeSelectedPractitioner = function (practitionerId) {
                    removeById(scope.selectedPractitioners, practitionerId);
                };

                scope.searchPatients = TypeaheadSearchHandlers.PATIENTS;

                scope.searchPatientsChange = function (v) {
                    if (v && v.id) {
                        scope.selectedPatients.push(v);
                        scope.additional.patient = null;
                    }
                };

                scope.removeSelectedPatient = function (patientId) {
                    removeById(scope.selectedPatients, patientId);
                };

                var removeById = function (list, id) {
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].id === id) {
                            list.splice(i, 1);
                        }
                    }
                };

                scope.updatePagination = function (pagination) {
                    console.log("pagination", pagination);
                    if (pagination?.page) {
                        scope.page = pagination.page;
                        loadUnfinishedDocuments();
                    }
                };
            },
        };
    },
]);
