import { Component, OnInit } from "@angular/core";
import { PortalTypeEnum } from "src/app/shared/models/portalType.enum";

@Component({
    selector: 'dp-doc-ref-f058-view',
    templateUrl: './dp-doc-ref-f058-form-view.component.html'
})
export class DpDocRef058ViewComponent implements OnInit {

    portalType: PortalTypeEnum = PortalTypeEnum.DP;

    ngOnInit(): void {
    }
}