import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {

    @Input() title: string;
    @Input() noTitle: boolean;
    @Input() working: boolean;
    @Input() inside: boolean = false;
    @Input() smallSize: boolean = false;
    classes: string;

    constructor() {

    }

    ngOnInit(): void {
        this.classes = !this.inside ? "sveik-spinner" : "";
    }
}
