import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../../api/api-v2-doc-pregnancy-dashboard";
import { RouteParams } from "../../../../../ng1.routeParams";
import { FetusUltrasoundList } from "../../../models/fetusUltrasoundExamination.model";

@Component({
    selector: "app-fetus-ultrasound-examination-view",
    templateUrl: "./fetus-ultrasound-examination-view.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FetusUltrasoundExaminationViewComponent implements OnInit, OnDestroy {
    @Input() closed?: boolean;

    public researchDateFormControl: FormControl = new FormControl(null);
    public selectedFetus: FormControl = new FormControl(null);
    public researchDates = new BehaviorSubject<string[]>(null);
    public ultrasoundData = new BehaviorSubject<
        {
            [x: string]: FetusUltrasoundList;
        }[]
    >([]);

    public get researchDateFormControlValue(): string {
        return this.researchDateFormControl.value;
    }

    private patientId: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private datePipe: DatePipe,
        private params: RouteParams
    ) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.subscription.add(this.getFetusUltrasoundExaminationList());
        this.subscription.add(this.subscribeResearchDateFormControl());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        localStorage.clear();
    }

    private getFetusUltrasoundExaminationList(): Subscription {
        return this._apiV2PregnancyDashboard.getFetusUltrasoundExaminationList(this.patientId).subscribe((x) => {
            const savedData = localStorage.getItem("fetusUltrasoundExamination");
            let data = null;
            if (savedData) {
                data = JSON.parse(savedData);
            } else {
                data = x;
            }

            const researchDates: string[] = [];
            const ultrasoundData: {
                [x: string]: {};
            }[] = [];

            data.fetusUltrasoundExaminationList?.forEach((x) => {
                const data = x;
                data.researchDate = this.datePipe.transform(new Date(x.researchDate), "yyyy-MM-dd HH:mm");
                ultrasoundData.push({ [data.researchDate]: data });
                researchDates.push(data.researchDate);
            });

            const transformedUltrasoundData = {
                ...Object.assign({}, ...Object.values(ultrasoundData)),
            };

            this.ultrasoundData.next(transformedUltrasoundData);
            this.researchDates.next(researchDates);
            const firstResearchDate = this.researchDates.getValue()?.[0];
            this.researchDateFormControl.patchValue(firstResearchDate);
        });
    }

    private subscribeResearchDateFormControl(): Subscription {
        return this.researchDateFormControl.valueChanges.subscribe((date) => {
            this.selectedFetus.patchValue(
                (this.ultrasoundData.getValue()[date] as FetusUltrasoundList).fetusData[0].generalInfo.fetusLetter
            );
        });
    }
}
