import angular from "angular";

export const medvaisUtilsModule: angular.IModule = angular.module('medvais.utils', []);
export default medvaisUtilsModule;


angular.module('medvais.utils').directive('medvaisFileStudyButton', function () {
    return {
        restrict: 'A',
        link: function (scope: any, element, attributes) {
            var acceptType = attributes.fileAccept || '.dcm';
            var accept = '';
            if ('all' != acceptType) {
                accept = 'accept="' + acceptType + '"';
            }

            var hint = '';
            hint = 'title="' + attributes.fileHint + '"';

            scope.addFile = true;
            element.bind("change", function (changeEvent: any) {
                scope.$apply(function () {
                    scope.fileread = changeEvent.target.files[0];
                    if (angular.isDefined(attributes['onSelect'])) {
                        scope.$eval(attributes['onSelect']);
                    }
                    scope.addFile = false;
                });
            });
            var el = angular.element(element);
            var button = el.children()[0];

            el.css({
                position: 'relative',
                overflow: 'hidden',
//                width: button.offsetWidth,
                height: button.offsetHeight
            });

            var fileInput = angular.element('<input type="file" ' + hint + ' ng-file-select="" ' + accept + ' onclick="this.value=null;">');
            fileInput.css({
                position: 'absolute',
                top: 0,
                left: 0,
                'z-index': '2',
                opacity: '0',
                cursor: 'pointer',
                width: '100%',
                height: '100%'
            });
            el.append(fileInput);
        }
    };
});


angular.module('medvais.utils').directive('medvaisFileButton', function () {
    return {
        restrict: 'A',
        link: function (scope: any, element, attributes) {
            var acceptType = attributes.fileAccept || 'image/*';
            var accept = '';
            if ('all' != acceptType) {
                accept = 'accept="' + acceptType + '"';
            }

            var hint = '';
            hint = 'title="' + attributes.fileHint + '"';

            scope.addFile = true;
            element.bind("change", function (changeEvent: any) {
                scope.$apply(function () {
                    scope.fileread = changeEvent.target.files[0];
                    if (angular.isDefined(attributes['onSelect'])) {
                        scope.$eval(attributes['onSelect']);
                    }
                    scope.addFile = false;
                });
            });
            var el = angular.element(element);
            var button = el.children()[0];
            var fileInput = angular.element('<input type="file" ' + hint + ' ng-file-select="" ' + accept + ' onclick="this.value=null;">');
            fileInput.css({
                position: 'absolute',
                top: 0,
                left: 0,
                'z-index': '2',
                opacity: '0',
                cursor: 'pointer'

//                top: '0px',
//                bottom: '0px',
//                left: '0px',
//                right: '0px',
//                width: '100%',
//                opacity: 0,
//                position: 'absolute'
            });
            el.append(fileInput);
        }
    };
});


angular.module('medvais.utils').directive('medvaisHasError', function () {
    return {
        restrict: 'A',
        scope: {
            medvaisHasError: '='
        },
        link: function (scope: any, element, attributes) {
            scope.$watch('medvaisHasError', function () {
                if (scope.medvaisHasError) {
                    element.addClass('has-error');
                } else {
                    element.removeClass('has-error');
                }
            });
        }
    };
});


//angular.module('medvais.utils').directive('hasError', function () {
//    return {
//        restrict: 'A',
//        scope: {
//            hasError: '='
//        },
//        link: function (scope, element, attributes) {
//            scope.$watch('hasError', function () {
//                if (scope.hasError) {
//                    element.addClass('has-error');
//                } else {
//                    element.removeClass('has-error');
//                }
//            });
//        }
//    };
//});


angular.module('medvais.utils').service('MedvaisUtils', function () {

    this.getIdFromReference = function (reference) {
        if (!reference) {
            return null;
        }
        if (reference.indexOf("/_history") > -1) {
            var str = reference.split("/_history")[0];
            return str.substring(str.lastIndexOf("/") + 1);
        }
        return reference.substring(reference.lastIndexOf("/") + 1);
    };

    this.getFullName = function (person) {
        console.log('getFullName() executed.', person);

        if (!person) {
            return null;
        }

        return ((_.exists(person.namePrefix) && !_.isBlank(person.namePrefix)) ? person.namePrefix + ' ' : '') +
            (person.givenName ? person.givenName + ' ' : '') +
            (person.familyName ? person.familyName : '');
    };

});
