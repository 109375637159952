ng1App.controller('doctor.patient.ViewSummaryCtrl', 
    ['$scope', '$routeParams', '$modalConfirm', 'PatientsApiFactory', 'PatientSummaryApiFactory', 'DraftsApiFactory', 'MessageService', '$filter', 'FormLoaderService', 'IntegratedComponentContext', 'apiUrl', 'PatientInsuranceService',
     function ($scope, $routeParams, $modalConfirm, PatientsApiFactory, PatientSummaryApiFactory,
                                                              DraftsApiFactory, MessageService, $filter, FormLoaderService, IntegratedComponentContext, apiUrl,
                                                              PatientInsuranceService) {
    // Side content list
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope._shared = {
        contentList: [
            {
                title: $filter('translate')('dpp.pat.sum.oth'),
                id: 'dpp_pat_sum_oth'
            },
            {
                title: $filter('translate')('dpp.pat.sum.pat'),
                id: 'dpp_pat_sum_pat'
            },
            {
                title: $filter('translate')('dpp.pat.sum.rep'),
                id: 'dpp_pat_sum_rep',
                show: function () {
                    if ($scope.summary.relatedPersons && $scope.summary.relatedPersons.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.all'),
                id: 'dpp_pat_sum_all',
                show: function () {
                    if ($scope.summary.allergies) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.nreg.all'),
                id: 'dpp_pat_sum_nreg_all',
                show: function () {
                    if ($scope.summary.remarks && $scope.summary.remarks.allergiesRemarks) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.dia.new'),
                id: 'dpp_pat_sum_dia_new',
                show: function () {
                    if ($scope.summary.currentConditions) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.dia.new'),
                id: 'dpp_pat_sum_dia_new',
                show: function () {
                    if ($scope.summary.currentConditions) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.nreg.dia'),
                id: 'dpp_pat_sum_nreg_dia',
                show: function () {
                    if ($scope.summary.remarks && $scope.summary.remarks.currentDiseasesRemarks) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.dia.old'),
                id: 'dpp_pat_sum_dia_old',
                show: function () {
                    if ($scope.summary.lastConditions) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.nreg.las.con'),
                id: 'dpp_pat_sum_nreg_las_con',
                show: function () {
                    if ($scope.summary.remarks && $scope.summary.remarks.pastDiseasesRemarks) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.sur.new'),
                id: 'dpp_pat_sum_sur_new',
                show: function () {
                    if ($scope.summary.proceduresIn6Months) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.sur.old'),
                id: 'dpp_pat_sum_sur_old',
                show: function () {
                    if ($scope.summary.proceduresBefore6Months) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.phy'),
                id: 'dpp_pat_sum_phy',
                show: function () {
                    if ($scope.summary.medicalData) {
                        return true;
                    } else {
                        return false;
                    }

                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.rec'),
                id: 'dpp_pat_sum_rec',
                show: function () {
                    if ($scope.summary.recomendation) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.vac'),
                id: 'dpp_pat_sum_vac',
                show: function () {
                    if ($scope.summary.vaccinations) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.notr.vacc'),
                id: 'dpp_pat_notr_vacc',
                show: function () {
                    if ($scope.summary.remarks && $scope.summary.remarks.vaccinationsRemarks) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.vac.med'),
                id: 'dpp_pat_sum_vac_med'
            }
        ]
    };

    MessageService.clear();
    $scope.isDraft = $routeParams.draftId ? true : false;
    $scope.patientId = $routeParams.patientId;
    $scope.draftId = $routeParams.draftId;
    $scope.hasHistory = false;

    $scope.pdfDownload = apiUrl + '/patient-summary/' + $routeParams.patientId + '/summaryPdf';
    
    $scope.$emit('setHelpPage', 'paciento-sveikatos-istorijos-suvestine');

    $scope.checkInsurance = function () {
        $scope.insuranceLoading = true;
        PatientInsuranceService.getInsurance($scope.summary.patient.id, true)
            .then(function (data) {
                if (!$scope.summary.encounter) {
                    $scope.summary.encounter = {insuranceData: {}};
                }
                $scope.summary.encounter.insuranceData.insurancePSD = data;
            })
            .finally(function(){
                $scope.insuranceLoading = false;
            });
    };

    $scope.relatedPerson = function (ref) {
        return $scope.summary.relatedPersons[ref];
    };

    $scope.partialPath = function (file) {
        return '/portal/doctor/patient/patient_summary/partials/' + file;
    };

    $scope.partialPathCommon = function (file) {
        return '/templates/portal/common/patient_info/partials/summary/' + file;
    };

    $scope.twoColumns = function () {
        var need2 = ($scope.isDraft ? false : $scope.hasHistory)
                || $filter('isNotEmpty')($scope.summary.implants)
                || $filter('isNotEmpty')($scope.summary.badHabits)
                || $filter('isNotEmpty')($scope.summary.planedBirthDate)
                || $filter('isNotEmpty')($scope.summary.disabilityData)
                || $filter('isNotEmpty')($scope.summary.workDisabilityData)
                || $filter('isNotEmpty')($scope.summary.specialNeedsData)
                || $filter('isNotEmpty')($scope.summary.bloodGroup);

        return need2 ? true : false;
    };

    $scope.cancelDraft = function () {
        $modalConfirm.show({
            content: 'Ar tikrai norite pašalinti juodraštį?'
        }).then(function () {
            DraftsApiFactory.invalidateDraft({id: $routeParams.draftId});
            MessageService.registerSuccessEvent();
            $scope.goBack();
            MessageService.showSuccessEventMessage();
        });
    };

    $scope.reload = function (generate, historyId) {
        FormLoaderService.startFormLoading();
        if ($scope.isDraft) {
            $scope.summary = PatientSummaryApiFactory.getDraft({patientId: $scope.patientId, draftId: $routeParams.draftId});
        } else {
            $scope.summary = PatientSummaryApiFactory.getSummary({patientId: $scope.patientId, generateNew: generate, historyId: historyId}, function (data) {
                $scope.checkInsurance();
                $scope.hasHistory = data.id;
            });
        }

        $scope.summary.$promise.finally(function () {
            FormLoaderService.endFormLoading();
        });
    };

    $scope.openHistoryLink = function (compositionId, historyId) {
        $scope.reload(false, historyId);
    };

    $scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();

    $scope.reload(false, $routeParams.historyId);

}]);