import { AfterViewInit, Component, DestroyRef, Input } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../../models/entity.model';
import {
    getControlValue,
    listenAndChangeStatusOfControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
import { ApiV2Session } from '../../../../../api/api-v2-session';
@Component({
    selector: "app-inr-reporting-person-info-subform",
    templateUrl: "./inr-reporting-person-info-subform.component.html",
})
export class InrReportingPersonInfoSubformComponent implements AfterViewInit {
    @Input() editable: boolean = true;
    @Input() patient: boolean;

    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];

    formGroup: FormGroup;

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    constructor(
        private fb: FormBuilder,
        private apiV2Session: ApiV2Session,
        private destroyRef: DestroyRef
    ) {
        this.formGroup = this.fb.group({
            fullName: this.fb.control(null),
            qualification: this.fb.control(null),
            organization: this.fb.control(null),
            phone: this.fb.control(null),
            email: this.fb.control(null),
            agreeSharePersonalData: this.fb.control(null)
        });


    }

    ngAfterViewInit() {
        if (this.editable) {
            this.apiV2Session.getCurrent().pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(data => {
                    this.formGroup.patchValue({
                        fullName: data?.name,
                        phone: data?.number
                    })
                    if (!this.patient) {
                        this.formGroup.patchValue({
                            qualification: data?.roleName,
                            organization: data?.organizationName
                        })
                    }
                });
        }
    }

}
