ng1App.controller('doctor.patient.cert.I.e027-1ViewCtrl', 
    ['$scope', 'CertificateService', '$filter', 'AsideBlockModel',
     function ($scope, CertificateService, $filter, AsideBlockModel) {
    //2020-04-23 AS modified	
	$scope.certificate.$promise.then(function () {
		CertificateService.calcValidUntilDate($scope);
	});

	$scope._shared = {
        contentList: [
            new AsideBlockModel('cer.I0271.mai.inf'),
            new AsideBlockModel('cer.I0271.doc'),
            new AsideBlockModel('cer.I0271.hea'),
            new AsideBlockModel('cer.I0271.vis'),
            new AsideBlockModel('cer.I0271.blo'),
            new AsideBlockModel('cer.I0271.bre'),
            new AsideBlockModel('cer.I0271.ner'),
            new AsideBlockModel('cer.I0271.dig'),
            new AsideBlockModel('cer.I0271.uro'),
            new AsideBlockModel('cer.I0271.end'),
            new AsideBlockModel('cer.I0271.ske'),
            new AsideBlockModel('cer.I0271.lif'),
            new AsideBlockModel('cer.I0271.ski'),
            new AsideBlockModel('cer.I0271.con')
        ]
    };
}]);