angular.module('medvais.components').service('$medvaisModalInfo', ["$modal", "$rootScope", "$q", "$filter", "$log", function($modal, $rootScope, $q, $filter, $log) {
    
    var scope = $rootScope.$new();
    var deferred;
    
    var modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-info.html',
        scope: scope,
        show: false
    });
    var parentShow = modal.show;
    
    modal.show = function(title, content) {
        $log.debug('Info modal show.');
        scope.title = title ? title : '';
        scope.content = content ? content : '';
        deferred = $q.defer();
        parentShow();
        return deferred.promise;
    };
    
    return modal;
}]);

