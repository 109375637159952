import {isDevMode} from "@angular/core";

if (isDevMode()) {
    if (false && process.env["ESPBI_debugRoutes"]) {
        // Print out routing events in dev
        // AJS routing events
        ng1App.run(['$rootScope', function ($rootScope) {
            $rootScope.$on('$locationChangeStart', (event: any, ...args: any[]) => {
                console.trace("ng1$locationChangeStart: %O, args: %O", event, args)
            });
            $rootScope.$on('$locationChangeSuccess', (event: any, ...args: any[]) => {
                console.trace("ng1$locationChangeSuccess: %O, args: %O", event, args)
            });
            $rootScope.$on('$routeChangeStart', (event: any, ...args: any[]) => {
                console.trace("ng1$routeChangeStart: %O, args: %O", event, args)
            });
            $rootScope.$on('$routeChangeSuccess', (event: any, ...args: any[]) => {
                console.trace("ng1$routeChangeSuccess: %O, args: %O", event, args)
            });
            $rootScope.$on('$routeChangeError', (event: any, ...args: any[]) => {
                console.trace("ng1$routeChangeError: %O, args: %O", event, args)
            });
            $rootScope.$on('$routeUpdate', (event: any, ...args: any[]) => {
                console.trace("ng1$routeUpdate: %O, args: %O", event, args)
            });
        }]);
        ng1App.run(['$rootScope', ($rootScope) => {
            console.debug('Running AngularJS application');
            // ui-router stuff. does not apply to ESPBI with angular-route-segment
            // $rootScope.$on('$stateChangeStart', (e, toState, toParams) => {
            //     console.trace('$stateChangeStart', toState, toParams);
            // });
        }]);
    }
}

ng1App.config([
    '$routeSegmentProvider', '$routeProvider', '$locationProvider',
    function ($routeSegmentProvider, $routeProvider, $locationProvider) {

        //$routeSegmentProvider.options.autoLoadTemplates = true;
        // $locationProvider.html5Mode(true).hashPrefix('!');

        // BASE
        var tb_root = '/templates/portal/';
        $routeSegmentProvider
            .when('/', 'MAIN')
            .when('/404', 'NOT_FOUND')
            .when('/unisign_callback', 'UNISIGN_CALLBACK')
            .segment('MAIN', {templateUrl: tb_root + 'main.html', controller: 'MainCtrl'})
            .segment('NOT_FOUND', {templateUrl: tb_root + '404.html'})
            .segment('UNISIGN_CALLBACK', {
                templateUrl: tb_root + 'unisign_callback.html',
                controller: 'UnisignCallbackCtrl'
            })

        if (isDevMode()) {
            $routeSegmentProvider
                .when('/mock', 'MOCK')
                .when('/today', 'TODAY')
                .when('/change_role', 'CHANGE_ROLE')
                .when('/patient_summary', 'PATIENT_SUMMARY')
                .when('/patient_appointments', 'PATIENT_APPOINTMENTS')
                .segment('MOCK', {templateUrl: tb_root + 'mock.html', controller: 'Mock'})
                .segment('TODAY', {templateUrl: tb_root + 'today.html', controller: 'MockupCtrl'})
                .segment('CHANGE_ROLE', {templateUrl: tb_root + 'change_role.html', controller: 'MockupCtrl'})
                .segment('PATIENT_SUMMARY', {templateUrl: tb_root + 'patient_summary.html', controller: 'MockupCtrl'})
                .segment('PATIENT_APPOINTMENTS', {
                    templateUrl: tb_root + 'patient_appointments.html',
                    controller: 'MockupCtrl'
                });
        }

        $routeProvider.otherwise('/404');
        //
        // $routeProvider.otherwise({
        //     redirectTo: function (routeParams, path, params) {
        //         console.debug("NG1 route otherwise triggered for path: %s", path)
        //         return '/404';
        //         // return isUrlDefined(path) ? path : '/404';
        //     }
        // });
    }]);
