ng1App.factory('DpEncounterContext', function () {
    return {
        data: {}
    };
});

ng1App.directive('dpEncounterContext', 
    ['$rootScope', '$location', '$filter', 'DpEncounterContext', 'EncountersApiFactory', '$routeParams', 'LinkApiFactory', 'DpPatientContext', 'SessionContext',
     function ($rootScope, $location, $filter, DpEncounterContext, EncountersApiFactory, $routeParams, LinkApiFactory, DpPatientContext, SessionContext) {
    return {
        templateUrl: '/templates/portal/doctor/patient/layout/encounter_context.html',
        restrict: 'E',
        replace: true,
        scope: true,
        link: function (scope: any) {
            scope.patient = {
                patientId: $routeParams.patientId
            };

            var init = function(){
                EncountersApiFactory.hasActiveEncounter({patientId: $routeParams.patientId}, function (data) {
                    scope.hasActiveEncounter = data.value || (DpPatientContext.data && SessionContext.practitioner ? DpPatientContext.data.providerPractitioner == SessionContext.practitioner.id : false);
                });
                scope.getClass = function (item) {
                    var result = '';

                    function checkActive(item) {
                        if (item.url.length > 0 && $location.path().substr(0, item.url.length) === item.url) {
                            result = 'active';
                        }
                    }

                    if (item.children) {
                        _.forEach(item.children, checkActive)
                    } else {
                        checkActive(item);
                    }

                    if (item.disabled) {
                        result += ' disabled-link';
                    }
                    return result;
                };

                scope.data = DpEncounterContext.data;
                LinkApiFactory.getFullMenu(function(data){
                    var addParams = function(url){
                        return url.replace("PID", scope.patient.patientId)
                    }
                    scope.menu = [
                        {
                            text: $filter('translate')('Healthdata'),
                            children: []
                        }
                    ];
                    scope.iprLinkWithPatient = addParams(data.iprLinkWithPatient);
                    data.menu.forEach(function(item){
                        scope.menu[0].children.push({text: $filter('translate')(item.textCode),url: addParams(item.urlPrefix + item.url),acComponent:{component:item.component},disabled:item.disabled,params:item.param});
                    });
                });
            }
            init();

            $rootScope.$on('encounterCloseEvent', function(event, data){
                init();
            });
        }
    };
}]);