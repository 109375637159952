import {Injectable} from '@angular/core';
import moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    messages: { [key: string]: any[] } = {'global': [], 'global_error': []};
    private successEvent: number = 0;
    private errorMessage: string | undefined;
    private warningMessage: string | undefined;

    constructor() {
    }

    private setFlush(type, message) {
        sessionStorage.setItem('flushMessage', JSON.stringify({'type': type, 'content': message}));
    };

    private processFlush() {
        var message: any = sessionStorage.getItem('flushMessage');
        if (message !== null) {
            message = JSON.parse(message);
            sessionStorage.removeItem('flushMessage');
            this.add(message.type, message.content);
        }
    };

    private surviveMessages(current: any[]): any[] {
        let survived: any[] = [];
        current?.filter(m => m && m.options && m.options.surviveOnce)
            .forEach(m => {
                m.options.surviveOnce = false;
                survived.push(m)
            });

        if (survived.length > 0) {
            console.debug('survived:', survived);
        }

        return survived;
    }

    clearAll(): void {
        console.debug("Clear all");
        this.messages = {
            'global': this.surviveMessages(this.messages['global']),
            'global_error': this.surviveMessages(this.messages['global_error'])
        };
    }

    clear(region: string): void {
        console.debug("Clear");

        if (!region) {
            region = 'global';
        }

        this.messages[region] = this.surviveMessages(this.messages[region]);
    }

    remove(arg: number | any, region: string): void {
        console.debug("Remove");
        if (!region) {
            region = 'global';
        }
        if (typeof arg === 'number') {
            this.messages[region]?.splice(arg, 1);
        } else {
            this.messages[region] = this.messages[region]?.filter(message => {
                const msg: string[] = this.getMessage(message?.text);
                return msg.length > 1 && msg[1] !== arg;
            });
        }
    }

    handleError(messages: string | null): void {
        if (messages) {
            try {
                const err = JSON.parse(messages.split("error:")[1]);
                err.forEach(item => this.error(item.msg));
            } catch (e) {
                // treat it as regular string to display
                this.error(messages)
            }
        } else {
            this.error('Klaida išsaugant dokumentą.');
        }
    }

    registerSuccessEvent(): void {
        this.successEvent = 1;
    }

    registerCancellationSuccessEvent(): void {
        this.successEvent = 2;
    }

    registerBlockingEvent(): void {
        this.successEvent = 3;
    }

    registerUnblockingEvent(): void {
        this.successEvent = 4;
    }

    registerActionSuccessEvent(): void {
        this.successEvent = 5;
    }

    registerEncounterCloseSuccessEvent(): void {
        this.successEvent = 6;
    }

    registerEncounterPartialSaved(msg: string): void {
        this.successEvent = 7;
        this.errorMessage = msg;
    }

    registerSuccessEventWithWarning(msg: string): void {
        this.successEvent = 8;
        this.warningMessage = msg;
    }

    unregisterSuccessEvent(): void {
        this.successEvent = 0;
    }

    showSuccessEventMessage(): void {
        console.debug("showSuccessEventMessage");
        switch (this.successEvent) {
            case 1:
                this.success('Įrašas sėkmingai išsaugotas ESPBI IS.');
                break;
            case 2:
                this.success('Įrašas atšauktas sėkmingai.');
                break;
            case 3:
                this.success('Įrašas sėkmingai užblokuotas');
                break;
            case 4:
                this.success('Įrašo blokavimas sėkmingai pašalintas');
                break;
            case 5:
                this.success('Veiksmas sėkmingai atliktas');
                break;
            case 6:
                this.success('Apsilankymas sėkmingai uždarytas');
                break;
            case 7:
                this.success('Apsilankymas sėkmingai užregistruotas');
                if (this.errorMessage) {
                    this.error(this.errorMessage);
                    this.errorMessage = undefined;
                }
                break;
            case 8:
                this.success('Įrašas sėkmingai išsaugotas ESPBI IS.');
                if (this.warningMessage) {
                    this.warning(this.warningMessage);
                    this.warningMessage = undefined;
                }
                break;
        }
    }

    error(text: string, arg1?: any, arg2?: any): void {
        this.add("error", text, arg1, arg2);
    }

    warning(text: string, arg1?: any, arg2?: any): void {
        this.add("warning", text, arg1, arg2);
    }

    success(text: string, arg1?: any, arg2?: any): void {
        this.add("success", text, arg1, arg2);
    }

    info(text: string, arg1?: any, arg2?: any): void {
        this.add("info", text, arg1, arg2);
    }

    add(type: string, text: string, arg1?: any, arg2?: any): void {
        let options;
        let region = 'global';

        if (arg2) {
            region = arg2;
        }

        if (typeof arg1 === 'object') {
            options = arg1;
        } else if (typeof arg1 === 'string') {
            region = arg1;
        }

        const errorTime = type === "error" ? '[' + moment().format('YYYY-MM-DD HH:mm:ss') + '] ' : '';

        const newItem = {
            type: type,
            errorTime: errorTime,
            text: errorTime + text,
            content: errorTime + text,
            getHtml: function () {
                return errorTime + text;
            },
            options: options
        };

        if (!this.messages[region]) {
            this.messages[region] = [];
        }

        this.messages[region].push(newItem);
        console.debug("Žinutė:" + newItem.text);
    }

    hasRegionErrors(region: string): boolean {
        const regionMessages = this.messages[region];
        return regionMessages !== undefined &&
            regionMessages.length !== 0 &&
            regionMessages.filter(m => m.type === "error").length !== 0;
    }

    messageExists(messageToFind: string, region: string): boolean {
        return this.messages[region]?.find(message => {
            const msg: string[] = this.getMessage(message?.text);
            return msg.length > 1 && msg[1] === messageToFind;
        }) !== undefined;
    }

    private getMessage(message: string): string[] {
        return message !== undefined ? message.split('] ') : [];
    }
}