import {Component} from "@angular/core";
import {IFloatingFilterAngularComp} from "ag-grid-angular";
import {FilterChangedEvent, IFloatingFilterParams} from "ag-grid-community";
import {AgGridFilterValidationService} from "../../../../services/ag-grid-filter-validation.service";

export interface ICustomColFilterTextParams {
    tooltip: string;
    placement: string;
}

@Component({
    selector: "ag-col-filter-text",
    templateUrl: "./ag-col-filter-text.component.html",
})
export class AgColFilterText implements IFloatingFilterAngularComp {
    public params!: IFloatingFilterParams & ICustomColFilterTextParams;
    filterText: string | null = null;
    classes: Object = {};
    min: number = 3;
    tooltip: string = null;
    placement: any = "bottom";
    defaultTooltipText: string = "Įveskite " + this.min + " arba daugiau simbolių";

    constructor(private validationService: AgGridFilterValidationService) {
    }

    agInit(params: IFloatingFilterParams & ICustomColFilterTextParams): void {
        this.params = params;

        this.placement = params.placement ? params.placement : this.placement;
    }

    onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
        if (!parentModel) {
            this.filterText = null;
        } else {
            this.filterText = parentModel.filter;
        }
        this.validate();
    }

    onType() {
        this.validate();
        if (!this.filterText) {
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }

        this.params.parentFilterInstance((instance: any) => {
            instance.onFloatingFilterChanged('contains', this.filterText);
        });
    }

    validate() {
        const isValid: boolean = this.filterText === null || this.filterText.length >= this.min || this.filterText.length === 0;
        this.validationService.validateFilter(isValid, this.params.context.filtersErrors, this.params.column.getColId());

        if (isValid) {
            this.classes = {};
            this.tooltip = null;
        } else {
            this.classes = {'border-danger': true};
            this.tooltip = this.params.tooltip ? this.params.tooltip : this.defaultTooltipText;
        }
    }
}