<ol class="slats hover">
    <li class="hide-pointer">
        <div class="d-block card-body p-3 mouse-pointer" tabindex="0" (click)="onPractitionerClick()" (keypress)="onPractitionerClick()">
            <small class="text-secondary d-block">
                <i class="fa-solid fa-stethoscope"></i>
                {{ practitioner?.speciality?.name ? practitioner?.speciality?.name : practitioner?.speciality?.displayValue }}
            </small>
            <strong class="card-text fs-3 mb-1">
                {{ practitioner | fullName }}
            </strong>
            <p class="lh-sm mb-0">
                {{ organization.name }}
            </p>
            <p class="card-text mt-2" *ngIf="organization?.phone">
                <a href="tel:{{ organization?.phone }}"
                   class="lh-1 d-inline-flex gap-2 align-items-center p-0 text-primary">
                    <span class="btn btn-sm btn-square btn-square-sm btn-secondary text-primary bg-transparent">
                        <i class="fa-regular fa-phone"></i>
                    </span>
                    <strong>
                        {{ organization?.phone }}
                    </strong>
                </a>
            </p>
            <p class="card-text" *ngIf="organization?.phones?.length > 0">
                <a *ngFor="let phone of organization?.phones" href="tel:{{ phone }}"
                   class="lh-1 d-flex gap-2 align-items-center d-inline-flex" aria-label="{{ phone }}">
                    <span class="btn btn-sm btn-square btn-square-sm btn-secondary">
                        <i class="fa-regular fa-phone"></i>
                    </span>
                    <strong class="text-primary">
                        {{ phone }}
                    </strong>
                </a>
            </p>
        </div>
    </li>
</ol>