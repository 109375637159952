import { Component, EventEmitter, Input, Output } from "@angular/core";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: "app-checkbox",
    templateUrl: "./checkbox.component.html",
})
export class CheckboxComponent {
    @Output() public checkboxChange: EventEmitter<Event> = new EventEmitter();
    @Output() public checkboxClick: EventEmitter<MouseEvent> = new EventEmitter();

    @Input({ required: true }) inputId: string;
    @Input() fControl: AbstractControl;
    @Input() checkboxClass: string;
    @Input() labelClass: string;
    @Input() checkboxChecked: boolean;
    @Input() checkboxName: string;
    @Input() checkboxValue: string;
    @Input() checkboxDataValue: string;
    @Input() checkboxTitle: string = "";
    @Input() checkboxDisabled: boolean;
    @Input() labelText: string;
    @Input() readOnly: boolean;
    @Input() required: boolean;
    @Input() formClass: string;
    @Input() addUniqId: boolean;

    private _uniqId = _.uniqueId();

    get uniqId(): string {
        return this.addUniqId ? `_${this._uniqId}` : '';
    }

    constructor() {}
}
