angular.module('medvais.components').service('$medvaisSendStudy', ["$modal", "$rootScope", "$q", "$window", "$filter", "MedvaisMedvaisApiFactory", "MedvaisOrderApiFactory", "MessageService", function ($modal, $rootScope, $q, $window, $filter,
    MedvaisMedvaisApiFactory, MedvaisOrderApiFactory, MessageService) {

    var scope = $rootScope.$new();
    var deferred;
    
    scope.params = {};
    scope.params.orderInfo = {};
    scope.params.selectedAET;
    scope.params.devicesList = []; 

    scope.sendImagingStudy = function () {
        scope.params.orderInfo.aet = scope.params.selectedAET;
        console.log("AE title: " + scope.params.orderInfo.aet);
        MedvaisOrderApiFactory.orderSendStudy(scope.params.orderInfo).$promise.then(
            function () {
                deferred.resolve("true");
                modal.hide();
            }, 
            function(err) {
                MessageService.clearAll();
                MessageService.add('error', $filter('translate')('medvais.err.msg.study.send.registration.failed'));
                $window.scrollTo(0, 10);
                modal.hide();
            }
        );
    };
    
    scope.deviceChange = function (aet) {
        console.log("Device selected. AET: " + aet);
        scope.params.selectedAET = aet;
    };
    

    var modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-send-study.html',
        title: $filter('translate')('medvais.send.study.modal.title'),
        scope: scope,
        show: false
    });

    var parentShow = modal.show;

    modal.show = function(orderInfo) {
        scope.params.orderInfo = orderInfo;
        scope.params.selectedAET = null;
        scope.params.devicesList = MedvaisMedvaisApiFactory.getOrganizationDevices({jarCode: orderInfo.jarCode});
        parentShow();
        deferred = $q.defer();
        return deferred.promise;
    };

    return modal;
}]);