ng1App.directive('practitionerRepresentativeItem', 
    ['RepresentationCommons',
     function (RepresentationCommons) {
    return {
        templateUrl: '/templates/portal/doctor/user/representatives/practitioner_item.html',
        restrict: 'E',
        replace: true,
        scope: {
            data: '='
        },
        link: function (scope: any, element, attrs) {
            var observeData = function () {
                scope.data.representative.photoId = _.getIdFromReference(scope.data.representative.photoReference);
            };
            attrs.$observe("data", observeData);

            scope.getStatusKey = RepresentationCommons.getStatusTitleKey;
            scope.getStatusColor = RepresentationCommons.getStatusColor;
            scope.cancel = RepresentationCommons.cancel;
        }
    };
}]);