<patient-health-assessments-reusable-form title="doc.aspn.healthAssessment.leather" [rows]="leatherRows"
                                          [contextData]="{risk: subGroup, wound: woundGroup}" />

<ng-template #woundSizeRow let-data="data">
    <div class="d-flex align-items-baseline">
        <div class="col-3 py-2">Žaizdos dydis</div>
        <div class="col-9 ps-2 row d-flex gap-3 align-items-center">
            <app-input
                    [fControl]="data.wound.get('length')"
                    labelText="Ilgis, cm"
                    labelClass="text-nowrap fw-light"
                    class="align-self-center col-3 d-flex h-[38px] gap-2 align-items-center"
            ></app-input>
            <app-input
                    [fControl]="data.wound.get('depth')"
                    labelText="Gylis, cm"
                    labelClass="text-nowrap fw-light"
                    class="align-self-center col-3 d-flex h-[38px] gap-2 align-items-center"
            ></app-input>
            <app-input
                    [fControl]="data.wound.get('width')"
                    labelText="Plotis, cm"
                    labelClass="text-nowrap fw-light"
                    class="align-self-center col-3 d-flex h-[38px] gap-2 align-items-center"
            ></app-input>
        </div>
    </div>
</ng-template>

<ng-template #subTable let-data="data">
    <div class="d-flex mb-3 align-items-baseline">
        <div class="col-3 py-2">Pragulų atsiradimo rizika</div>
        <div class="col-9 ps-2 row">
            <app-input [fControl]="data.risk.get('riskOfLeaks')" class="w-50"></app-input>
        </div>
    </div>
    <div class="d-flex mb-5">
        <div class="col-2">Suskaičiuoti balų sumą pagal šią lentelę</div>
        <div class="col-10 px-2 row">
            <div class="border-bottom border-top border-start border-end">
                <div class="fs-4 row align-items-stretch">
                    <div class="col-2 flex-grow-1 border-end text-center d-grid align-items-center">Objektyvi sveikatos būklė</div>
                    <div class="col-2 flex-grow-1 border-end text-center d-grid align-items-center">Psichikos būklė</div>
                    <div class="col-2 flex-grow-1 border-end text-center d-grid align-items-center">Bendras aktyvumas</div>
                    <div class="col-2 flex-grow-1 border-end text-center d-grid align-items-center">Judrumas</div>
                    <div class="col-2 flex-grow-1 text-center d-grid align-items-center">Šlapimo / išmatų nelaikymas</div>
                </div>
                <div class="fs-5 row align-items-stretch border-top">
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="4"
                                [id]="'stateOfHealth4'"
                                [radioName]="'stateOfHealth4'"
                                labelText="Gera 4"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('stateOfHealth')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="4"
                                [id]="'mentalState4'"
                                radioName="mentalState4"
                                labelText="Žvalus 4"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('mentalState')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="4"
                                [id]="'generalActivity4'"
                                [radioName]="'generalActivity4'"
                                labelText="Vaikšto laisvai 4"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('generalActivity')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="4"
                                [id]="'agility4'"
                                [radioName]="'agility4'"
                                labelText="Neribotas 4"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('agility')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="4"
                                [id]="'urinaryFaecalIncontinence4'"
                                [radioName]="'urinaryFaecalIncontinence4'"
                                labelText="Nėra 4"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('urinaryFaecalIncontinence')"
                        ></app-radio-button>
                    </div>
                </div>
                <div class="fs-5 row align-items-stretch border-top">
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="3"
                                [id]="'stateOfHealth3'"
                                [radioName]="'stateOfHealth3'"
                                labelText="Patenkinama 3"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('stateOfHealth')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="3"
                                [id]="'mentalState3'"
                                [radioName]="'mentalState3'"
                                labelText="Apatiškas 3"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('mentalState')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="3"
                                [id]="'generalActivity3'"
                                [radioName]="'generalActivity3'"
                                labelText="Vaikšto padedamas 3"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('generalActivity')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="3"
                                [id]="'agility3'"
                                [radioName]="'agility3'"
                                labelText="Nežymiai ribotas 3"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('agility')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="3"
                                [id]="'urinaryFaecalIncontinence3'"
                                [radioName]="'urinaryFaecalIncontinence3'"
                                labelText="Atsitiktinis 3"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('urinaryFaecalIncontinence')"
                        ></app-radio-button>
                    </div>
                </div>
                <div class="fs-5 row align-items-stretch border-top">
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="2"
                                [id]="'stateOfHealth2'"
                                [radioName]="'stateOfHealth2'"
                                labelText="Bloga 2"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('stateOfHealth')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="2"
                                [id]="'mentalState2'"
                                [radioName]="'mentalState2'"
                                labelText="Pritemusi sąmonė 2"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('mentalState')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="2"
                                [id]="'generalActivity2'"
                                [radioName]="'generalActivity2'"
                                labelText="Sėdi krėsle 2"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('generalActivity')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="2"
                                [id]="'agility2'"
                                [radioName]="'agility2'"
                                labelText="Labai ribotas 2"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('agility')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="2"
                                [id]="'urinaryFaecalIncontinence2'"
                                [radioName]="'urinaryFaecalIncontinence2'"
                                labelText="Dažnas 2"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('urinaryFaecalIncontinence')"
                        ></app-radio-button>
                    </div>
                </div>
                <div class="fs-5 row align-items-stretch border-top">
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="1"
                                [id]="'stateOfHealth1'"
                                [radioName]="'stateOfHealth1'"
                                labelText="Labai bloga 1"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('stateOfHealth')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="1"
                                [id]="'mentalState1'"
                                [radioName]="'mentalState1'"
                                labelText="Stuporas 1"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('mentalState')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="1"
                                [id]="'generalActivity1'"
                                [radioName]="'generalActivity1'"
                                labelText="Guli lovoje 1"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('generalActivity')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 border-end text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="1"
                                [id]="'agility1'"
                                [radioName]="'agility1'"
                                labelText="Visai nejudrus 1"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('agility')"
                        ></app-radio-button>
                    </div>
                    <div class="col-2 flex-grow-1 text-center align-items-center d-flex py-2">
                        <app-radio-button
                                radioValue="1"
                                [id]="'urinaryFaecalIncontinence1'"
                                [radioName]="'urinaryFaecalIncontinence1'"
                                labelText="Nuolatinis 1"
                                formClass="mb-0 d-flex flex-row-reverse justify-content-end gap-3 ps-2"
                                labelClass="me-auto"
                                inputClass="ms-0"
                                class="w-100"
                                [fControl]="data.risk.get('urinaryFaecalIncontinence')"
                        ></app-radio-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-baseline">
        <div class="col-3">Odos defekto nuotraukos</div>
        <div class="col-9 ps-2 row">
            <app-input type="file" accept="image/*" [multiple]="true" [fControl]="data.risk.get('images')" class="w-auto"/>
        </div>
    </div>
</ng-template>
