import { Component, DestroyRef, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslatePipe } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Observer, distinctUntilChanged, forkJoin, map, of, switchMap, take } from "rxjs";
import { ApiV2Documents } from "../../../../api/api-v2-documents";
import { ApiV2Organizations } from "../../../../api/api-v2-organizations";
import { RouteParams } from "../../../ng1.routeParams";
import { PaginationBarComponent } from "../../../shared/components/list/pagination-bar/pagination-bar.component";
import { Pagination } from "../../../shared/components/list/pagination-bar/pagination-bar.constants";
import { FilterOptionsInterface } from "../../../shared/components/page-filters/page-filters.component";
import { EntityModel } from "../../../shared/models/entity.model";
import { OrganizationModel } from "../../../shared/models/organization.model";
import { PractitionerModel } from "../../../shared/models/practitioner.model";
import { ApiV2Entities } from "../../../shared/services/api-v2-entities";
import { DocStatuses } from "../../../shared/services/utils/utils";

@Component({
    selector: "all-records-list",
    templateUrl: "./all-records-list.component.html",
})
export class AllRecordsListComponent implements OnInit {
    @ViewChild("paginationBar") paginationBar: PaginationBarComponent;

    public records$ = new BehaviorSubject<[]>([]);
    public filterOptions: FilterOptionsInterface[] = [];
    public backUrl: string;
    public count: number;
    public pagesCount: number;
    public page: number = 1;
    public isLoaded: boolean = true;

    public formGroup: FormGroup = this.fb.group({
        diagnosis: this.fb.control(null),
        allergy: this.fb.control(null),
        targetQualificationCodes: this.fb.control(null),
        nameSPI: this.fb.control(null),
    });

    public suggestionsOrg$?: any;
    public suggestionsAlergies$?: any;
    public suggestionsDiagnosis$?: any;
    public suggestionsQualification$?: any;
    public asychicAddendum = [
        { id: "1", code: "yes", name: "Yra" },
        { id: "2", code: "no", name: "Nėra" },
        { id: "3", code: "unknown", name: "Nesvarbu" },
    ];

    public documentStatuses = DocStatuses.map((status) => ({
        ...status,
        id: status.code,
        name: this.translatePipe.transform(status.name),
    }));

    private lastFilters: { [key: string]: any } = {};
    private patientId: string;

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Documents: ApiV2Documents,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly apiV2Organizations: ApiV2Organizations,
        private readonly route: RouteParams,
        private readonly router: Router,
        private readonly destroyRef: DestroyRef,
        private readonly translatePipe: TranslatePipe
    ) {
        this.loadFilterValues();
    }

    public ngOnInit(): void {
        this.patientId = this.route.patientId;
        this.backUrl = "/dp/patients/" + this.patientId;

        this.getDiagnoses();
        this.getAllergies();
        this.getOrganizationsList();
        this.getQualificationCodesList();
    }

    public updateFilters(filters: any) {
        if (!filters) {
            return;
        }

        const updatedfilters = { ...filters, page: 1 };

        this.filterList(updatedfilters);

        this.paginationBar.currentPage = 0;
        this.paginationBar.realCurrentPage = 1;
    }

    public filterList(filters?: any): void {
        const equal = _.isEqual(filters, this.lastFilters);

        if (equal) {
            return;
        }

        if (filters && Object.values(filters).some((value) => value !== null && value !== undefined && value !== "")) {
            this.lastFilters = { ...filters, count: 10 };
        }

        const params = {
            dateFrom: filters?.dateFrom ? new Date(filters?.dateFrom).getTime() : "",
            dateTo: filters?.dateTo ? new Date(filters?.dateTo).getTime() : "",
            docType: (filters?.docType || []).map((type) => type.code),
            status: (filters?.status || []).map((type) => type.id),
            diagnosisCodes: filters?.diagnosis?.code,
            researchCodes: filters?.allergy?.code,
            asychicAddendum: filters?.asychicAddendum?.code,
            targetQualificationCodes: filters?.targetQualificationCodes?.code,
            organizationId: filters?.nameSPI?.id,
        };

        for (const key in params) {
            if (
                params.hasOwnProperty(key) &&
                (params[key] === null || params[key] === undefined || params[key] === "" || params[key]?.length === 0)
            ) {
                delete params[key];
            }
        }

        this.isLoaded = false;

        this.loadList(params);
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        const filter = { ...this.lastFilters, page: pagination.page };

        this.filterList(filter);
    }

    public viewDocument(documentId: string): void {
        this.router.navigate([`/dp/patients/${this.patientId}/documents/${documentId}`]);
    }

    private loadList(params?: { [key: string]: any }): void {
        this.apiV2Documents
            .searchForListWithTotals({ ...params, patient: this.patientId })
            .pipe(take(1))
            .subscribe({
                next: (res) => {
                    const records = res.items.map((doc) => {
                        const conditions = doc.conditions.map((condition) => condition.diagnosisText).join("; ");

                        const specialist: PractitionerModel = {
                            ...doc.practitioner,
                            practitionerOrganization: doc.organization,
                        };

                        return { ...doc, conditions, specialist };
                    });

                    this.records$.next(records as any);
                    this.count = res.total;

                    const pagesCount = res.total / this.lastFilters.count;
                    this.pagesCount = Math.ceil(pagesCount);

                    this.isLoaded = true;
                },
                error: () => {
                    this.records$.next([]);
                    this.isLoaded = true;
                },
            });
    }

    private loadFilterValues(): void {
        forkJoin([
            this.apiV2Entities.getEntitiesList("composition-status"),
            this.apiV2Entities.getEntitiesList("doctypes"),
        ])
            .pipe(takeUntilDestroyed())
            .subscribe((res) => {
                this.filterOptions = [
                    {
                        title: "pp.dia.sea.dat",
                        type: "date-range",
                    },
                    {
                        title: "Dokumento būsena",
                        formControlName: "status",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: this.documentStatuses,
                    },
                    {
                        title: "Dokumentas",
                        formControlName: "docType",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[1].map((type: EntityModel & { datasetCode: string }) => {
                            const displayValue = `${type.datasetCode} ${type.name}`;

                            return { ...type, displayValue };
                        }),
                        multiSelectSettings: {
                            idField: "code",
                            textField: "displayValue",
                        },
                    },
                    {
                        title: "Diagnozės",
                        formControlName: "diagnosis",
                        type: "typeahead",
                        placeholder: "dpp.pat.dra.sea.fill",
                        typeaheadSettings: {
                            typeaheadformControl: this.formGroup.get("diagnosis") as FormControl,
                            suggestionsService: this.suggestionsDiagnosis$,
                            inputTextItem: "displayValue",
                        },
                    },
                    {
                        title: "Alergijos",
                        formControlName: "allergy",
                        type: "typeahead",
                        placeholder: "dpp.pat.dra.sea.fill",
                        typeaheadSettings: {
                            typeaheadformControl: this.formGroup.get("allergy") as FormControl,
                            suggestionsService: this.suggestionsAlergies$,
                            inputTextItem: "name",
                        },
                    },
                    {
                        title: "Psichikos priedas",
                        formControlName: "asychicAddendum",
                        type: "multi-select",
                        placeholder: "Įveskite",
                        multiSelectSettings: {
                            singleSelection: true,
                        },
                        multiSelectOptions: this.asychicAddendum,
                    },
                    {
                        title: "Autoriaus profesinė kvalifikacija",
                        formControlName: "targetQualificationCodes",
                        type: "typeahead",
                        placeholder: "dpp.pat.dra.sea.fill",
                        typeaheadSettings: {
                            typeaheadformControl: this.formGroup.get("targetQualificationCodes") as FormControl,
                            suggestionsService: this.suggestionsQualification$,
                            inputTextItem: "name",
                        },
                    },
                    {
                        title: "SPĮ pavadinimas",
                        formControlName: "nameSPI",
                        type: "typeahead",
                        placeholder: "dpp.pat.dra.sea.fill",
                        typeaheadSettings: {
                            typeaheadformControl: this.formGroup.get("nameSPI") as FormControl,
                            suggestionsService: this.suggestionsOrg$,
                            inputTextItem: "nameAndJarCode",
                        },
                    },
                ];
            });
    }

    private getOrganizationsList(): void {
        this.suggestionsOrg$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("nameSPI").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Organizations.getOrganizations(query, 10, "ROOT_ONLY").pipe(
                        map((newDisplay: OrganizationModel[]) => {
                            return newDisplay.map((org) => ({
                                ...org,
                                nameAndJarCode:
                                    org.name +
                                    ", JAR kodas " +
                                    org.jarCode +
                                    ", SVEIDRA Nr. " +
                                    org.sveidraId +
                                    " " +
                                    org.registrationAddress.text,
                            }));
                        })
                    );
                }
                return of([]);
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getQualificationCodesList(): void {
        this.suggestionsQualification$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("targetQualificationCodes").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    const queryParams = { searchCriteria: query };
                    return this.apiV2Entities.getEntitiesList("qualification-code", queryParams).pipe(
                        map((newDisplay: EntityModel[]) => {
                            return newDisplay.map((org) => ({
                                ...org,
                            }));
                        })
                    );
                }
                return of([]);
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getDiagnoses(): void {
        this.suggestionsDiagnosis$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("diagnosis").value);
        }).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                const request = !query
                    ? this.apiV2Entities.getMostUsedDiagnoses({ count: 10 })
                    : this.apiV2Entities.getEntitiesList("tlk-10-am", { searchCriteria: query });

                return request.pipe(
                    map((items) =>
                        items.map((p) => {
                            p["displayValue"] = `${p.code} ${p.name}`;
                            return p;
                        })
                    )
                );
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getAllergies(): void {
        this.suggestionsAlergies$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("allergy").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Entities
                        .getEntitiesList("allergen", { searchCriteria: query })
                        .pipe(map((data: any[]) => (data || []).map((entities) => this.getEntityInfo(entities) || [])));
                }
                return of([]);
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getEntityInfo(entities: EntityModel) {
        return {
            id: entities.id,
            code: entities.code,
            name: entities.name,
        };
    }
}
