ng1App.factory('PatientUpdateStatusHandler', ['MessageService', PatientUpdateStatusHandler]);

function PatientUpdateStatusHandler(MessageService) {

    function handleResponse(responseBody, surviveMessageClean) {
        var updateStatus = responseBody.status;
        if (updateStatus) {
            var errorMsg;
            var errorCode = updateStatus.errorCodes;
            switch (updateStatus.status) {
                case 'NOT_FOUND':
                    // will be handled by controller?
                    break;
                case 'NO_CHANGES':
                case 'UPDATED':
                case 'CREATED':
                    if (updateStatus.failedUpdaters && updateStatus.failedUpdaters.length > 0) {
                        var updaters = _.map(updateStatus.failedUpdaters, function (item) {
                            return item.toUpperCase();
                        });
                        errorMsg = 'Nepavyko pilnai atnaujinti paciento duomenų iš registrų: ' + updaters.join(', ');
                    }
                    break;
                case 'FAIL':
                    errorMsg = 'Nepavyko atnaujinti paciento duomenų';
                    break;
            }
            if (errorMsg || errorCode) {
                MessageService.error(errorMsg + (errorCode ? ' (' + errorCode + ')' : ''), {surviveOnce: surviveMessageClean}, 'global');
            }
        }
    }

    return {
        handleResponse: handleResponse
    };
}