ng1App.directive('addComment', 
    ['$modal', '$location', '$filter',
     function ($modal, $location, $filter) {
    var modal: any = {},
        createModal = function (scope) {
            return $modal({
                scope: scope,
                title:  $filter('translate')('mod.add.com'),
                contentTemplate: '/templates/portal/doctor/patient/documents/modal/add_comment.html'
            });
        };
    return {
        link: function (scope: any, element, attrs) {
            element.on('click', function () {
                modal = createModal(scope);
                scope.createDialogErr = {};
                modal.$promise.then(function () {
                    modal.show();
                });
            }); 
        }
    };
}]);