import {Injectable} from '@angular/core';
import {RouteParams} from "../../ng1.routeParams";

@Injectable({
    providedIn: 'root'
})
export class ErxPathsService {

    constructor(
        // private route: ActivatedRoute
        private params: RouteParams
    ) {
    }

    private docUrl(documentId?: string, patientId?: string): string {
        const currentPatientId = patientId || this.params.patientId;
        return `/dp/patients/${currentPatientId}`;
    }

    private baseUrl(documentId?: string, patientId?: string): string {
        return `${this.docUrl(documentId, patientId)}/erx/prescriptions`;
    }

    backToDoc(): string {
        return this.docUrl();
    }

    view(prescriptionId: string, documentId: string, patientId: string): string {
        return `${this.baseUrl(documentId, patientId)}/${prescriptionId}?hideDoc=1`;
    }

    viewDoc(prescriptionId: string): string {
        return `${this.baseUrl()}/${prescriptionId}?hideDoc=0`;
    }

    new(prescriptionType: string): string {
        return `${this.baseUrl()}/new?prescriptionType=${prescriptionType}`;
    }

    edit(prescriptionId: string): string {
        return `${this.baseUrl()}/${prescriptionId}/edit`;
    }

    editDoc(prescriptionId: string): string {
        return `${this.baseUrl()}/${prescriptionId}/editDoc`;
    }

    sign(prescriptionId: string, docIds: string): string {
        let url = `${this.baseUrl()}/${prescriptionId}/sign`;
        if (docIds) {
            url += `?docIds=${docIds}`;
        }
        return url;
    }

    list(signStatus?: string): string {
        let url = this.baseUrl();
        if (signStatus) {
            url += `?signStatus=${signStatus}`;
        }
        return url;
    }

    unisignCallback(prescriptionId: string): string {
        return `${this.baseUrl()}/${prescriptionId}/signcallback`;
    }

    printPrescriptions(id: string): string {
        return `${this.baseUrl()}/print`;
    }
}