ng1App.controller('doctor.patient.ReportsViewCtrl', 
    ['$scope', 'apiUrl', '$http', '$routeParams', '$rootScope', '$location', '$window',
     function($scope, apiUrl,
                                                             $http, $routeParams, $rootScope, $location, $window) {
	$scope.reportUrl = apiUrl + '/reports/vaccines/?patientId=' +  $routeParams.patientId;

	$scope.goBack = function() {
		$window.history.back();
	};

}]);
