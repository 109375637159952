ng1App.controller('doctor.patient.cert.e106.1.2.ViewCtrl', 
    ['$scope', 'AsideBlockModel', 'MessageService',
     function ($scope, AsideBlockModel, MessageService) {
    $scope.certificate.$promise.then(function () {
        var bornDeadDeathTypeCode = '1';
        var cer = $scope.certificate;
        if (cer.deathType != null && cer.deathType.code === bornDeadDeathTypeCode) {
            $scope.mother = cer.patient;
            $scope.patient = {
                givenName: cer.givenName,
                familyName: cer.familyName,
                sex: cer.administrativeGender
            };
        } else {
            $scope.mother = $scope.certificate.mother;
        }
        if ($scope.certificate.deathType && $scope.certificate.deathType.code == '1') {
            MessageService.info('Pažyma saugoma motinos elektroninėje sveikatos istorijoje', 'patient_pract_data_region');
        } else {
            MessageService.info('Pažyma saugoma mirusiojo naujagimio elektroninėje sveikatos istorijoje', 'patient_pract_data_region');
        }
    });

    $scope.codes = {
        birthPlaceHospital: '1',
        deathPlaceHospital: '1'
    };

    $scope.showBornDeadData = function () {
        return $scope.certificate.height || $scope.certificate.weight || $scope.certificate.pregnancyCompletion
    };
    $scope._shared = {
        contentList: [
            new AsideBlockModel('cer.e106_2_1.mai.inf'),
            new AsideBlockModel('cer.e106_2_1.und.are', function () {
                var variable = $scope.certificate.hypotheticalAge;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106_2_1.rep', function () {
                var variable = $scope.certificate.replacedCertificate;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106_2_1.dea'),
            new AsideBlockModel('cer.e106_2_1.bor'),
            new AsideBlockModel('cer.e106_2_1.inf'),
            new AsideBlockModel('cer.e106_2_1.mot', function () {
                var variable = $scope.certificate.motherData;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e106_2_1.new', $scope.showBornDeadData),
            new AsideBlockModel('cer.e106_2_1.new.rea'),
            new AsideBlockModel('cer.e106_2_1.mot.rea'),
            new AsideBlockModel('cer.e106_2_1.oth'),
            new AsideBlockModel('cer.e106_2_1.wri')
        ]
    };
}]);