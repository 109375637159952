angular.module('medvais.api').factory('MedvaisDocumentApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/document';
    
    return $resource('', {}, {
        
        createPartial: {
            method: 'POST',
            url: URL_BASE + '/savePartial'
        },
        
        updatePartial: {
            method: 'PUT',
            url: URL_BASE + '/:id/savePartial'
        },
        
        createFinal: {
            method: 'POST',
            url: URL_BASE + '/saveFinal'
        },
        
        updateFinal: {
            method: 'PUT',
            url: URL_BASE + '/:id/saveFinal'
        },
        
//        makeFinal: {
//            method: 'POST',
//            url: URL_BASE + '/makeFinal?compositionId=:compositionId'
//        },
        
        makeFinal: {
            method: 'POST',
            url: URL_BASE + '/makeFinal'
        },

        cancel: {
            method: 'POST',
            url: URL_BASE + '/:id/cancelDocument'
        },
        
        get027vaDocListForImagingStudy: {
            method: 'GET',
            url: URL_BASE + '/027va/list?imagingStudyId=:imagingStudyId',
            isArray: true
        },
        
        get027vaDocEditData: {
            method: 'GET',
            url: URL_BASE + '/:id/edit'
        },
        
        get027vaDocPreviewData: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        
        initSigning: {
            method: 'GET',
            url: URL_BASE + '/:id/initSigning?confirmUrl=:confirmUrl'
        },
        
        confirmSigning: {
            method: 'GET',
            url: URL_BASE + '/:id/signResult'
        },
        
        getDocumentReferenceData: {
            method: 'GET',
            url: URL_BASE + '/reference/:compositionId'
        }
        
    });
    
}]);

