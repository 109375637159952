ng1App.controller('document.E200aViewCtrl', 
    ['$scope', '$routeParams', '$filter',
     function ($scope, $routeParams, $filter) {
     // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: _.range(4)
    }
    
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'}; 
    
    $scope.documentId = $routeParams.documentId;
    $scope.patientId = $routeParams.patientId;

    $scope.byOrder = true;
    $scope.form.$promise.then(function (form) {
        $scope.byOrder = !form.provider.department;
    });
}]);