ng1App.controller('doctor.user.NewMessageCtrl',
    ['$scope', '$location', '$routeParams', '$filter', '$modalConfirm', 'PractitionersApiFactory', 'TypeaheadSearchHandlers', 'AlertsApiFactory', 'MessageService', 'DpMessagesUtil', 'DpSessionContext', 'PatientsApiFactory',
     function ($scope, $location, $routeParams, $filter, $modalConfirm, PractitionersApiFactory, TypeaheadSearchHandlers, AlertsApiFactory, MessageService, DpMessagesUtil, DpSessionContext, PatientsApiFactory) {
    // Init
    $scope.$emit('setHelpPage', 'kurti-pranesima');
    $scope.message = {
        subjectRef: [],
        receiverRef: []
    };

    DpSessionContext.practitioner.$promise.then(function (a) {
        $scope.practitionerId = DpSessionContext.practitioner.id;
    });

    $scope.searchFilter = {};
    var cleanAdditional = function(){
        $scope.additional = {
            search: {
                givenName: null,
                familyName: null,
                personalCode: null
            },
            searchingPatient: false,
        };
    }
    cleanAdditional();
    $scope.util = DpMessagesUtil;

    var defaultReceiverType = function () {
        return $filter('acWrite')('Alert', ['3']) ? 'spe' : 'pac';
    };

    $scope.rec = {
        type: defaultReceiverType(),
        selected: []
    };

    var needsEDeliveryAddress = $routeParams.documentId != null;

    $scope.showSendCopyToEDelivery = function () {
        return DpSessionContext.practitioner.eDeliveryAddress;
    };
    // Handlers
    MessageService.clearAll();

    if ($routeParams.practitioner) {
        PractitionersApiFactory.getFull({id: $routeParams.practitioner}, function (data) {
            var spe = data,
                    org = spe.practitionerOrganization;

            TypeaheadSearchHandlers.ORGANIZATIONS_label(org);
            TypeaheadSearchHandlers.PRACTITIONERS_QLF_label(spe);

            $scope.searchFilter.selected_org = org;
            $scope.searchFilter.selected_spe = spe;
            $scope.searchFilter.tmp_org = org;
            $scope.searchFilter.tmp_spe = spe;
        });
    }

    if ($routeParams.documentId) {
        $scope.documentId = $routeParams.documentId;
        AlertsApiFactory.prefillMessage({documentId: $routeParams.documentId}, function (data) {
            for (var key in data) {
                //copy all the fields
                $scope.message[key] = data[key];
            }
        });
    }

    $scope.addReceiver = function () {

        MessageService.clearAll();
        var rForm = $scope.messageForm.receiversForm;
        rForm.submitted = true;

        if ($scope.rec.type == 'spe') {
            validateEntity(rForm.org, $scope.searchFilter.selected_org);
            validateEntity(rForm.spe, $scope.searchFilter.selected_spe);
            // validateEntity(rForm.speSub, $scope.searchFilter.selected_speSub);
            if (rForm.$valid) {
                if ($scope.searchFilter.selected_spe.id == $scope.practitionerId) {
                    MessageService.add('error', $filter('translate')('err.messages.receiverSelf'), 'receivers-region');
                } else {
                    if (needsEDeliveryAddress) {
                        if (!$scope.searchFilter.selected_spe.eDeliveryAddress) {
                            MessageService.error($filter('translate')('mess.error.noEDeliveryAddress'), "receivers-region");
                            return;
                        }
                    }
                    $scope.rec.selected.push({
                        spe: $scope.searchFilter.selected_spe,
                        speSub: $scope.searchFilter.selected_speSub,
                        org: $scope.searchFilter.selected_org
                    });
                    $scope.searchFilter = {};
                    cleanAdditional();
                    rForm.$setPristine();
                }
            }
        } else {
            //validateEntity(rForm.ptn, $scope.searchFilter.selected_ptn);
            if (rForm.$valid) {
                if (needsEDeliveryAddress) {
                    if (!$scope.searchFilter.selected_ptn.eDeliveryAddress) {
                        MessageService.error($filter('translate')('mess.error.noEDeliveryAddress'), "receivers-region");
                        return;
                    }
                }
                $scope.rec.selected.push({
                    ptn: $scope.searchFilter.selected_ptn
                });
                $scope.searchFilter = {};
                cleanAdditional();
                rForm.$setPristine();
            }
        }

    };

    $scope.removeReceiver = function (index) {
        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
            $scope.rec.selected.splice(index, 1);
        });
    };

    $scope.removeSelected = function (type) {
        var tmpName = 'tmp_' + type,
            selectedName = 'selected_' + type;
        $scope.searchFilter[tmpName] = null;
        $scope.searchFilter[selectedName] = null;
        cleanAdditional();
        $scope.messageForm.receiversForm.$setPristine();
    };

    $scope.clean = function () {
        MessageService.clearAll();
        $scope.message.subjectRef = [];
        $scope.message.receiverRef = [];
        $scope.rec.type = defaultReceiverType();
        $scope.rec.selected = [];
        $scope.searchFilter = {};

        cleanAdditional();

        $scope.messageForm.receiversForm.org.$setValidity("valueIsEntity", true);
        $scope.messageForm.receiversForm.spe.$setValidity("valueIsEntity", true);
        $scope.messageForm.receiversForm.speSub.$setValidity("valueIsEntity", true);
        $scope.messageForm.receiversForm.ptn.$setValidity("valueIsEntity", true);

        $scope.messageForm.receiversForm.submitted = false;
        $scope.messageForm.receiversForm.$setPristine();
        $scope.messageForm.submitted = false;
        $scope.messageForm.$setPristine();

    };

    // $scope.searchPatients = TypeaheadSearchHandlers.PATIENTS_BD;
    $scope.searchPractitioners = TypeaheadSearchHandlers.PRACTITIONERS_QLF;
    $scope.searchOrganizations = TypeaheadSearchHandlers.ORGANIZATIONS;

    $scope.searchForPatient = function (type) {
        var tmpName = 'tmp_' + type;
        MessageService.clear('receivers-region');
        if ($scope.additional.search.givenName
            && $scope.additional.search.familyName
            && $scope.additional.search.personalCode) {
            $scope.additional.search.alive = true;

            $scope.additional.searchingPatient = true;
            PatientsApiFactory.findPatient($scope.additional.search, function (result) {
                $scope.additional.searchingPatient = false;
                var patient = null;
                if (result.id) {
                    patient = result;
                }
                if (patient) {
                    $scope.searchFilter[tmpName] = patient;
                    $scope.validateFormEntity(type, true)
                } else {
                    MessageService.error($filter('translate')('err.not_found'), 'receivers-region');
                }
            },function(result){
                $scope.additional.searchingPatient = false;
            });
        } else {
            MessageService.error($filter('translate')('err.all.fie.req'), 'receivers-region');
        }
    };

    $scope.validateFormEntity = function (inputName, skipValidity) {
        var tmpName = 'tmp_' + inputName,
                selectedName = 'selected_' + inputName,
                tmpEntity = $scope.searchFilter[tmpName],
                ctrl = $scope.messageForm.receiversForm[inputName];

        if (!tmpEntity) {
            $scope.searchFilter[selectedName] = null;
        } else if (tmpEntity.id) {
            if(skipValidity){
                //NOPE
            } else {
                console.log('I check ' + inputName);
                ctrl.$setValidity("valueIsEntity", true);
            }
            $scope.searchFilter[selectedName] = tmpEntity;
        }
    };


    $scope.fixOnBlur = function (inputName) {
        var tmpName = 'tmp_' + inputName,
                selectedName = 'selected_' + inputName,
                tmpEntity = $scope.searchFilter[tmpName],
                ctrl = $scope.messageForm.receiversForm[inputName];

        if (!tmpEntity) {
            $scope.searchFilter[selectedName] = null;
        } else if ($scope.searchFilter[selectedName]) {
            tmpEntity.label = $scope.searchFilter[selectedName].label;
        }
    };

    var validateEntity = function (ctrl, entity) {
        var isValid = entity && entity.id != null;
        ctrl.$setValidity("valueIsEntity", isValid);
    };

    $scope.sendMessage = function () {

        MessageService.clearAll();
        $scope.messageForm.receiversForm.$setPristine();
        $scope.messageForm.submitted = true;

        var hasReceivers = $scope.rec.selected.length != 0;
        if (!hasReceivers) {
            MessageService.add('error', $filter('translate')('err.messages.noReceivers'), 'receivers-region');
        }

        if ($scope.messageForm.$valid && hasReceivers) {
            angular.forEach($scope.rec.selected, function (p) {
                if (p.spe) {
                    $scope.message.receiverRef.push({
                        subjectRef: p.speSub?.fullId,
                        receiverRef: p.spe.fullId,
                    });
                } else {
                    $scope.message.subjectRef.push(p.ptn.fullId);
                }
            });

            AlertsApiFactory.sendMessage($scope.message, function (data) {
                $location.hash('success');
                $location.path('/dp/messages/' + data.messageId);
            }, function (error) {
                MessageService.add('error', error.message);
            });
        }
    };

}]);