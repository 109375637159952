import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { ApiV2AccountSettings } from 'src/api/api-v2-account-settings';
import { ApiV2Documents } from 'src/api/api-v2-documents';
import {DpSessionService} from '../../../doctor/services/dp-session.service';
import { RouteParams } from 'src/app/ng1.routeParams';
import { OrganizationModel } from 'src/app/shared/models/organization.model';
import { PatientModel } from 'src/app/shared/models/patient.model';
import { PractitionerModel } from 'src/app/shared/models/practitioner.model';

@Component({
    selector: 'app-common-patient-widget',
    templateUrl: './common-patient-widget.component.html',
    providers: [DatePipe]
})
export class CommonPatientWidgetComponent implements OnInit {

    //nhr widget inputs
    @Input() filters: any;
    @Input() currentFilter: any;
    @Input() filterChanged: (filter: any) => void;
    @Input() title: string;
    @Input() loading: string;
    @Input() titleName: string;
    @Output() filterService = new EventEmitter<any>();
    //main form variables
    @Output() valueChange = new EventEmitter();
    @Input() modeModification: boolean;
    //@Input() documentType: any;
    @Input() prefillData: any;
    @Input() closed: boolean = true;

    patientData: PatientModel = null;
    patientDataFullName: string = null;
    practitioner: PractitionerModel = null;
    practitionerFullName: string = null;
    organization: OrganizationModel = null;
    patientAge: number;
    qualificationName: string;
    patientBirthDate: any;
    aagaSgas = null;
    //variables needed for Modal window to work
    qualificationList: any;
    hideMsgAboutQualification: any;
    chosenProfession: string = null;

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private apiV2AccountSettings: ApiV2AccountSettings,
        private dpSessionContextService: DpSessionService
    ) { }

    ngOnInit(): void {
        this.loadPrefillData();
        this.hideMsgAboutQualification = true;
        this.getActiveQualification();
    }

    //TODO: the same thing with certificates
    loadPrefillData() {
        this.getPatient(this.prefillData);
        this.getSpecialist(this.prefillData);
        this.getOrganization(this.prefillData);
        this.dataCollected(this.prefillData);
    }

    getActiveQualification() {
        this.apiV2AccountSettings.get().pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.hideMsgAboutQualification = response.active_qualification;
            }
        });
    }

    getPatient(response) {
        this.patientData = response.metadata.patient;
        this.calculatePatientAge();
        this.patientDataFullName = this.formatFullName(response.metadata.patient);
    }

    getSpecialist(response) {
        this.practitioner = response.metadata.practitioner;
        this.qualificationName = response.practitionerQualification.name;
        this.practitionerFullName = this.formatFullName(response.metadata.practitioner);
        this.qualificationList = response.metadata.practitioner.validQualificationList
    }

    getOrganization(response) {
        this.organization = response.metadata.custodian;
    }

    submitProfession() {
        for (let i = 0; i < this.qualificationList.length; i++) {
            if (this.chosenProfession === this.qualificationList[i].licenseCode) {
                this.qualificationName = this.qualificationList[i].name;
            }
        }

        this.apiV2AccountSettings.save(this.chosenProfession).subscribe({
            next: (response) => {
                this.dpSessionContextService.getSettings().last_qualification = response;
            }
        });
    }

    calculatePatientAge() {
        this.patientAge = moment().diff(this.patientData.birthDate, 'years');
    }

    formatFullName(response) {
        var formatName = _.getFullName(response);
        return formatName;
    }

    dataCollected(response) {
        var emitedResponse = { ...response.practitionerQualification, ...response.orderData, ...response.metadata }
        this.valueChange.emit(emitedResponse);
    }

}