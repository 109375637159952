<div class="context info-header">
    <nav
        class="navbar navbar-expand-lg px-3 px-lg-5 py-3"
        role="navigation"
        data-bs-theme="dark"
        aria-label="'Encounter navigation' | translate"
    >
        <div class="container-fluid d-flex justify-content-end px-0 d-lg-none">
            <a [href]="patient | patientLink" class="nav-link flex-grow-1" *ngIf="patient">
                <div class="text-truncate">
                    <span class="fs-3 fw-bold">
                        {{ patient | fullName | capitalize }}{{ ", " }}{{ patient | age }}
                    </span>
                </div>
            </a>
            <button
                type="button"
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#patient-context-collapse"
                [title]="'Show navigation' | translate"
            >
                <span class="sr-only">{{ "Show navigation" | translate }}</span>
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="container-fluid px-0">
            <div id="patient-context-collapse" class="collapse navbar-collapse">
                <div class="d-flex flex-column flex-lg-row gap-3 w-100">
                    <div *ngIf="patient" class="d-flex flex-grow-1 gap-3 flex-column flex-lg-row">
                        <div class="d-flex flex-grow-1 gap-1 flex-column">
                            <div
                                class="d-flex flex-wrap gap-1 gap-lg-3 mt-3 mt-lg-n1 flex-column flex-lg-row align-items-start align-items-lg-center"
                            >
                                <a
                                    [href]="hasActiveEncounter ? (patient | patientLink) : (patient | patientLink) + '/view'"
                                    class="nav-link d-none d-lg-block focus-ring focus-ring-light"
                                    *ngIf="patient"
                                >
                                    <div class="text-truncate">
                                        <span class="fs-3 fw-bold">
                                            {{ patient | fullName | capitalize }}{{ ", " }}{{ patient | age }}
                                        </span>
                                    </div>
                                </a>
                                <a [href]="(patient | patientLink) + '/view'" class="nav-link" *ngIf="patient">
                                    <small>
                                        {{ "Paciento informacijos santrauka" | translate }}
                                    </small>
                                </a>
                                <a [href]="(patient | patientLink) + '/events'" class="nav-link"
                                   *ngIf="patient && (encounterData$ | async)">
                                    <small>
                                        {{ "Kalendorius" | translate }}
                                    </small>
                                </a>

                                <div class="dropdown" *ngIf="patient && (encounterData$ | async)" data-bs-theme="light">
                                    <button
                                        class="nav-link flex-shrink-1"
                                        data-bs-toggle="dropdown"
                                        type="button"
                                        aria-expanded="false"
                                        *ngIf="patient"
                                    >
                                        <small
                                            ><i
                                                class="fa fa-solid fa-ellipsis-h"
                                                aria-label="Toggle patient menu"
                                                style="color: #ffffff"
                                            ></i
                                        ></small>
                                    </button>
                                    <ul
                                        id="main-secondary-dropdown"
                                        class="dropdown-menu dropdown-menu-start dropdown-menu-lg-end shadow pb-2"
                                    >
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + '/tasks'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Susijusios užduotys" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + '/unfinished/records'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Nebaigti pildyti dokumentai" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <!--/dp/patients/{{patientId}}/drafts-->
                                            <a
                                                [href]="(patient | patientLink) + '/drafts'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "dp.pat.das.dra" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + '/doc-ref-consent/list'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Sutikimai" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + '/imaging/study/list'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Sutikimai, pageidavimai ir nurodymai" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + ''"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Nesutikimai gauti informaciją" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + '/certificates'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Pažymėjimai" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + ''"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Kiti požymiai" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + '/vaccines/calendar'"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Skiepų planavimas" | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [href]="(patient | patientLink) + ''"
                                                class="dropdown-item small px-3 px-lg-4 py-2"
                                            >
                                                {{ "Nestruktūrizuoti dokumentai" | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap gap-2 flex-column flex-xl-row"
                                 *ngIf="(encounterData$ | async)">
                                <div class="d-flex flex-wrap gap-2">
                                    <span
                                        [class]="
                                            'badge rounded-pill text-white align-self-center' +
                                            (isInsured() ? ' text-bg-success' : ' text-bg-danger')
                                        "
                                        translate>
                                        {{ isInsured() ? "Draustas" : "Nedraustas" }}
                                    </span>
                                    <span
                                        *ngIf="patientStatus?.indicators?.allergies"
                                        (click)="navigateToPage('/allergies2')"
                                        (keypress)="navigateToPage('/allergies2')"
                                        class="badge rounded-pill align-self-center text-bg-warning mouse-pointer"
                                        tabindex="0"
                                        translate>
                                        Alergijos
                                    </span>
                                    <span
                                        *ngIf="patientStatus?.indicators?.tumor"
                                        class="badge rounded-pill align-self-center text-bg-warning mouse-pointer"
                                        translate>
                                        Navikai
                                    </span>
                                    <span
                                        *ngIf="patientStatus?.indicators?.pregnancy && patientStatus?.indicators?.pregnancy?.active"
                                        (click)="navigateToPage('/pregnancy-dashboard-list')"
                                        (keypress)="navigateToPage('/pregnancy-dashboard-list')"
                                        class="badge rounded-pill align-self-center text-bg-warning mouse-pointer"
                                        tabindex="0"
                                        translate>
                                        Nėštumas
                                    </span>
                                    <span
                                        *ngIf="patientStatus?.indicators?.aspn"
                                        (click)="navigateToPage('/aspn/summary')"
                                        (keypress)="navigateToPage('/aspn/summary')"
                                        class="badge rounded-pill align-self-center text-bg-warning mouse-pointer"
                                        tabindex="0"
                                        translate>
                                        ASPN
                                    </span>
                                </div>
                                <div class="d-inline-block">
                                    <span class="text-nowrap" *ngIf="patient.personalCode || patient.esiNr">
                                        {{
                                            patient.personalCode
                                                ? "a.k. " + patient.personalCode
                                                : "esi nr.: " + patient.esiNr
                                        }}
                                    </span>
                                    <span class="vr" *ngIf="patientStatus?.phoneNo"></span>
                                    <span class="text-nowrap" *ngIf="patientStatus?.phoneNo">
                                        {{ patientStatus?.phoneNo }}
                                    </span>
                                    <span class="vr" *ngIf="patientStatus?.email"></span>
                                    <span class="text-nowrap" *ngIf="patientStatus?.email">
                                        {{ patientStatus?.email }}
                                    </span>
                                    <span class="vr" *ngIf="patientStatus?.indicators?.pregnancy.pregnancyNo"></span>
                                    <span class="text-nowrap" *ngIf="patientStatus?.indicators?.pregnancy.pregnancyNo">
                                        {{ "Nėštumas" | translate }}{{ ": "
                                        }}{{ patientStatus?.indicators?.pregnancy.pregnancyNo }}
                                    </span>
                                    <span class="vr" *ngIf="patientStatus?.indicators?.pregnancy.birthNo"></span>
                                    <span class="text-nowrap" *ngIf="patientStatus?.indicators?.pregnancy.birthNo">
                                        {{ "Gimdymas" | translate }}{{ ": "
                                        }}{{ patientStatus?.indicators?.pregnancy.birthNo }}
                                    </span>
                                    <span class="vr" *ngIf="patientStatus?.indicators?.pregnancy.pregnancyWeek || patientStatus?.indicators?.pregnancy.pregnancyDay"></span>
                                    <span class="text-nowrap"
                                          *ngIf="patientStatus?.indicators?.pregnancy.pregnancyWeek || patientStatus?.indicators?.pregnancy.pregnancyDay">
                                        {{ patientStatus?.indicators?.pregnancy.pregnancyWeek }}{{ " sav. "
                                        }}{{ patientStatus?.indicators?.pregnancy.pregnancyDay }}{{ " d." }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="align-self-lg-center d-flex gap-2 flex-column flex-lg-row">
                            {{ links && "Nuorodos" }}
                        </div>
                    </div>
                    <button
                        *ngIf="hasActiveEncounter"
                        class="btn btn-lg btn-outline-light text-nowrap align-self-lg-center align-self-stretch"
                        data-bs-toggle="modal"
                        data-bs-target="#documentType"
                        translate
                        (click)="onModalOpen()"
                    >
                        Naujas ESI įrašas
                    </button>
                    <button
                        *ngIf="hasActiveEncounter !== true"
                        class="btn btn-lg btn-outline-light text-nowrap align-self-lg-center align-self-stretch"
                        translate
                        (click)="openNewVisit()"
                    >
                        Naujas apsilankymas
                    </button>
                </div>
            </div>
        </div>
    </nav>
</div>
<nhr-msg-global></nhr-msg-global>
<document-type-select
    modalId="documentType"
    [loading]="modalIsLoading"
    [allowedTypes]="allowedTypes"
    [allowedCerts]="allowedCerts"
    [patient]="patient"
    [encounter]="encounter"
/>
