(function () {
    ng1App.factory('PatientInsuranceService', ['PatientsApiFactory', '$q', '$rootScope', PatientInsuranceService]);

    function PatientInsuranceService(PatientsApiFactory, $q, $rootScope) {
        var cacheTimeMillis = 60 * 1000;
        var cached = null;

        function addToCache(patientId, value) {
            cached = {id: patientId, value: value, date: new Date()};
        }

        function getCached(patientId) {
            if (cached && cached.id == patientId && new Date().getTime() - cacheTimeMillis < cached.date.getTime()) {
                return cached.value;
            }
            return null;
        }

        function getInsurance(patientId, forceLoad) {
            if (!forceLoad) {
                var res = getCached(patientId);
                if (res != null) {
                    console.debug('returning from cache with patientId=' + patientId);
                    return $q.when(res);
                }
            }
            return PatientsApiFactory.getInsurance({id: patientId}).$promise.then(function (insurance) {
                console.debug('querying for insurance with patientId=' + patientId);
                addToCache(patientId, insurance);
                $rootScope.$broadcast('PSD-INSURANCE-UPDATED', {patientId: patientId, insurance: insurance});
                return insurance;
            });
        }

        return {
            getInsurance: getInsurance
        };

    }
})();
