ng1App.factory('PractitionersApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/practitioners';
    return $resource('', {}, {
        get: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getFull: {
            method: 'GET',
            url: URL_BASE + '/:id/full'
        },
        getPhoto: {
            method: 'GET',
            url: URL_BASE + '/:code/photo'
        },
        post: {
            method: 'POST',
            url: URL_BASE
        },
        getByCode: {
            method: 'GET',
            url: URL_BASE + '?code=:code'
        },
        find: {
            method: 'GET',
            url: URL_BASE
        },
        getIncompleteDocs: {
            method: 'GET',
            url: URL_BASE + '/incompleteDocs'
        },
        searchGR: {
            method: 'GET',
            url: URL_BASE + '/searchGR'
        },
        getForm: {
            method: 'GET',
            url: URL_BASE + '/form'
        },
        update: {
            method: 'POST',
            url: URL_BASE + '/update'
        },
        countMyPatients: {
            method: 'GET',
            url: URL_BASE + '/count-my-patients'
        },
        getFromVapris: {
            method: 'GET',
            url: URL_BASE + '/search-vapris'
        },
        getFromLicreg: {
            method: 'GET',
            url: URL_BASE + '/search-licreg'
        },
        checkPersonalCode: {
            method: 'GET',
            url: URL_BASE + '/personal-code-check'
        },
        getSettings: {
            method: 'GET',
            url: URL_BASE + '/getSettings'
        },
        saveSettings: {
            method: 'POST',
            url: URL_BASE + '/saveSettings'
        },
        grouped: {
            method: 'GET',
            url: URL_BASE + '/grouped'
        },
        workplaces: {
            method: 'GET',
            url: URL_BASE + '/workplaces'
        },
        getStamplessRoles: {
            method: 'GET',
            url: URL_BASE + '/stampless-roles'
        },
        checkFillableWithSpecialtyForCompositionAuthor: {
            method: 'GET',
            url: URL_BASE + '/qualificationSettings/specialty'
        }
    });
}]);

ng1App.service('PractitionerUpdateService', 
    ['$upload', 'apiUrl',
     function ($upload, apiUrl) {
    this.update = function (practitioner, photo, successCb, errorCb) {
        $upload.upload({
            url: apiUrl + '/practitioners/update',
            data: {data: practitioner},
            file: photo
        }).progress(function (evt) {
        }).success(function (data, status, headers, config) {
            (successCb || angular.noop)(data);
        }).error(function (error) {
            (errorCb || angular.noop)(error);
        });
    };
}]);