<div *ngIf="formGroup">
    <h1>E097-TS Tėvų sutikimas dėl naujagimiui atliekamų procedūrų</h1>
    <back-link [path]="backPath" />
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <nhr-record-widget [closable]="false" [closed]="false"
        [title]="'Tėvų sutikimas dėl naujagimiui atliekamų procedūrų' | translate" id="checkupResults">
        <div class="row">
            <div class="col-md-3">
                <app-date-picker inputId="consentDate" [fControl]="formGroup?.get('consentDate')"
                    [labelText]="'Sutikimo data' | translate" [minDate]="today" labelClass="mt-0" />
            </div>
            <div class="col-md-3">
                <app-time-picker inputId="consentTime" [fControl]="formGroup?.get('consentTime')"
                    [labelText]="'Sutikimo laikas' | translate" labelClass="mt-md-0" />
            </div>
        </div>
        <div class="row">
            <div *ngFor="let r of getConsentsArray.controls; let j = index" class="col-md-6 col-lg-3">
                <fieldset>
                    <legend class="fw-bold form-label">{{ r.value.name | translate }}</legend>
                    <div *ngFor="let item of consent; let i = index">
                        <app-radio-button radioName="{{ r.value.shortName }}"
                            id="{{ r.value.shortName }}_{{ item.code }}" labelText="{{ item.name | translate }}"
                            radioValue="{{ item.code }}" [fControl]="r.controls.selection"></app-radio-button>
                    </div>
                </fieldset>
            </div>
            <fieldset class="col-md-6 col-lg-3">
                <legend class="fw-bold form-label">{{ 'Dėl atliekamų procedūrų sutikimą davė' | translate }}
                </legend>
                <div>
                    <app-radio-button *ngFor="let v of consentGivenBy; let i = index" radioName="consentGivenBy"
                        [labelText]="v.name" [radioTitle]="v.name" id="consentGivenBy_{{ v.value }}"
                        [radioValue]="v.value" [fControl]="formGroup.get('consentGivenBy')"></app-radio-button>
                </div>
                <div class="pt-2" *ngIf="formGroup.get('consentGivenBy').value == 'other'">
                    <app-input [fControl]="formGroup?.get('consentGivenByComment')" />
                </div>
            </fieldset>
        </div>
    </nhr-record-widget>

    <app-modification-actions (sign)="onSave()" (sealConfirm)="onSave()" (signingSubmit)="onSave()" (submit)="onSave()"
        (save)="onSave()" (clear)="clearForm()"></app-modification-actions>
</div>