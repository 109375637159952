<div>
    <app-title showFilterButton="true">
        Slaugos kalendorius
    </app-title>
    <div class="widget mb-4">
        <custom-search filter [name]="'quick-search'" [isAdvanced]="true" (filterService)="loadAspnVisitList($event)">
            <aspnVisitList-filter (onSearch)="loadAspnVisitList($event)"></aspnVisitList-filter>
        </custom-search>
    </div>
    <full-calendar *ngIf="calendarOptions" [options]="calendarOptions()" deepChangeDetection="true" #visitsCalendar
        style="width: 100%; height: 80%;">
        <ng-template #eventContent let-arg>
            <div class="fc-custom-event {{arg.event._def.extendedProps.status ?? ''}}">
                <!-- TODO: Fix tooltip, should work on long names and small event blocks -->
                <div class="event-content d-flex flex-column h-100">
                    <div class="d-flex">
                        <strong class="flex-grow-1">Pacientas</strong>
                        <div class="d-flex justify-content-between align-items-center">
                            <div (click)="openModal(arg.event._def.extendedProps.visitId)"
                                (keypress)="openModal(arg.event._def.extendedProps.visitId)" tabindex="0"
                                class="pb-1"
                                placement="bottom" tooltip="{{arg.event._def.extendedProps.patientFullName}}"
                                [adaptivePosition]="false"
                                container="body"
                            >
                                <span class="badge rounded-pill bg-dark small px-2">
                                    i
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">{{arg.event._def.extendedProps.patientFullName}}</div>
                </div>
                <div class="d-flex align-self-center w-100">
                    <strong class="flex-grow-1 status">
                        <span
                            class="badge rounded-pill {{badgeByStatus[arg.event._def.extendedProps.status.code]}} ">
                            {{labelByStatus[arg.event._def.extendedProps.status.code]}}
                        </span>
                    </strong>
                    <div class="d-flex align-self-center" *ngIf="arg.event._def.extendedProps.editableVisit">
                        <!-- <button type="button" class="btn btn-outline-primary btn-square me-1 btn-sm"
                            (click)="openVisitProceduresApproveModal(arg.event._def.extendedProps.visitId, arg.event._def.extendedProps.procedures)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-check2" viewBox="0 0 16 16">
                                <path
                                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </button> -->
                        <button type="button" class="btn p-0 px-1 text-primary mb-n2" aria-label="Edit"
                            (click)="openEditVisistDialog($event, arg.event._def.extendedProps.visitId, arg.event._def.extendedProps.periodVisitId)">
                            <i class="fa-solid fa-pencil"></i>
                        </button>
                        <button type="button"
                            class="btn p-0 px-1 text-danger mb-n2 me-n1" aria-label="Remove"
                            (click)="openCancelVisistDialog($event, arg.event._def.extendedProps.visitId, arg.event._def.extendedProps.periodVisitId)">
                            <i class="fa-regular fa-circle-xmark"></i>
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>

    </full-calendar>

    <ng-template #proceduresInfoModalTmp>
        <div class="modal-body">
            <div class="row"
                *ngIf="modalService.config.initialState.procedures && modalService.config.initialState.procedures.length > 0">
                <div class="col">
                    <ul>
                        <li *ngFor="let item of modalService.config.initialState.procedures">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </ng-template>

    <ng-template #editVisistDialogTmp>
        <div class="modal-body">
            <div class="row"
                *ngIf="modalService.config.initialState.periodVisitId && modalService.config.initialState.visitId">
                <div class="col"><strong>Ar norite koreguoti tik šį apsilankymą ar apsilankymų seriją?</strong></div>
            </div>
            <div class="row"
                *ngIf="!modalService.config.initialState.periodVisitId && modalService.config.initialState.visitId">
                <div class="col"><strong>Ar norite koreguoti apsilankymą?</strong></div>
            </div>
        </div>
        <div class="modal-footer">

            <button *ngIf="modalService.config.initialState.periodVisitId" type="button" class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="navigateToVisit(modalService.config.initialState.periodVisitId)">{{'Seriją'|translate}}</button>
            <button *ngIf="modalService.config.initialState.visitId" type="button" class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="navigateToVisit(modalService.config.initialState.visitId)">{{'Apsilankymą'|translate}}</button>
            <button type="button" class="btn btn-secondary"
                (click)="editVisistDialogRef.hide()">{{'cancel'|translate}}</button>
        </div>
    </ng-template>
    <ng-template #cancelVisistDialogTmp>
        <div class="modal-body">
            <div class="row"
                *ngIf="modalService.config.initialState.periodVisitId && modalService.config.initialState.visitId">
                <div class="col"><strong>Ar norite atšaukti tik šį apsilankymą ar apsilankymų seriją?</strong></div>
            </div>
            <div class="row"
                *ngIf="!modalService.config.initialState.periodVisitId && modalService.config.initialState.visitId">
                <div class="col"><strong>Ar norite atšaukti apsilankymą?</strong></div>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="modalService.config.initialState.periodVisitId" type="button" class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="openCancelVisitNoteModal(modalService.config.initialState.periodVisitId)">{{'Seriją'|translate}}</button>
            <button *ngIf="modalService.config.initialState.visitId" type="button" class="btn btn-primary"
                data-bs-dismiss="modal"
                (click)="openCancelVisitNoteModal(modalService.config.initialState.visitId)">{{'Apsilankymą'|translate}}</button>
            <button type="button" class="btn btn-secondary"
                (click)="cancelVisistDialogRef.hide()">{{'cancel'|translate}}</button>
        </div>
    </ng-template>
    <ng-template #cancelVisitNoteDialogTmp>
        <div class="modal-body">
            <div class="row req">
                <div class="col" [formGroup]="visitFormG">
                    <app-input id="cancelVisit" type="text" [fControl]="visitFormG.get('visitCancelNote')"
                        [showErrors]="true"
                        labelText="Ar patvirtinate pasirinkto apsilankymo / apsilankymų serijos atšaukimą?" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="modalService.config.initialState.visitId" type="button" class="btn btn-primary"
                (click)="cancelVisit(modalService.config.initialState.visitId);">{{'Taip'|translate}}</button>
            <button type="button" class="btn btn-secondary"
                (click)="cancelVisitNoteDialogRef.hide()">{{'Ne'|translate}}</button>
        </div>
    </ng-template>

    <ng-template #visitProceduresApproveModalTmp>
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{'Procedūros atlikimo statusas' | translate}}</h4>
        </div>
        <div class="modal-body" [formGroup]="visitProceduresFG">
            <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-main-grid"
                [columnDefs]="visitProceduresColDefs2" [defaultColDef]="defaultColDef"
                [rowData]="visitProceduresGridData" (gridReady)="onGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)" [context]="getContext()"
                (rowDataChanged)="refreshFormControls()" [domLayout]="'autoHeight'" />
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="submitVisitAsDone(modalService.config.initialState.visitId);">{{'Pateikti ir formuoti E025'|translate}}</button>
            <button type="button" class="btn btn-secondary"
                (click)="visitProceduresApproveModalRef.hide()">{{'cancel'|translate}}</button>
        </div>
    </ng-template>
</div>