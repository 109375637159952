angular.module('erx.utils').service('$erxModalReason', ["$modal", "$rootScope", "$q", "gettextCatalog", function ($modal, $rootScope, $q, gettextCatalog) {
  var scope = $rootScope.$new();
  var deferred;
  scope.submit = function (reason) {
    deferred.resolve(reason);
    confirm.hide();
  };

  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-reason/erxModalReason.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (opts) {
    if (scope.$$childHead) {
      delete scope.$$childHead.reason;
    } else {
      delete scope.reason;
    }
    scope.title = opts.title ? opts.title : gettextCatalog.getString('Atšaukimas');
    scope.reasonTitle = opts.reasonTitle ? opts.reasonTitle : gettextCatalog.getString('Atšaukimo priežastis');
    scope.confirm = opts.confirmTitle ? opts.confirmTitle : gettextCatalog.getString('Vykdyti atšaukimą');
    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };

  return confirm;
}]);