<div class="fw-bold mb-0">
    <ng-container *ngIf="inputType === 'checkbox'">
        <input class="me-1" type="checkbox" name="document_order" [id]="'r' + order.item.fullId" [(ngModel)]="data.value"/>
    </ng-container>
    <ng-container *ngIf="inputType === 'radio'">
        <input class="me-1" type="radio" name="document_order" [id]="'r' + order.item.fullId" [(ngModel)]="data.value" [value]="data.valueToSet"/>
    </ng-container>
    <label [for]="inputType ? 'r' + order.item.fullId : ''">
        <ng-container [ngSwitch]="order.item.type.code">
            <ng-container *ngSwitchCase="1">
                <span>{{ order.item.obsData.diagnosis.code }} {{ order.item.obsData.diagnosis.name }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                {{ order.item.labData.research.name }}<span class="vr"></span>{{ order.item.labData.specimen.name }}
            </ng-container>
            <ng-container *ngSwitchCase="3">
      <span *ngFor="let d of order.item.patData.diagnosis; let last = last;">
        <span>{{ d.code }} {{ d.name }}<span *ngIf="!last">,&nbsp;</span></span>
      </span>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                {{ order.item.vacData.recommendation.disease.name }}
            </ng-container>
            <ng-container *ngSwitchCase="10">E027-1-I Vaiko sveikatos pažymėjimas</ng-container>
            <ng-container *ngSwitchCase="11">E027-1-II Vaiko sveikatos pažymėjimas</ng-container>
            <ng-container *ngSwitchCase="16">Telemedicinos užduotis</ng-container>
        </ng-container>
    </label>
</div>
<div class="mb-0">
    <ng-container [ngSwitch]="order.item.type.code">
        <ng-container *ngSwitchCase="1">{{ 'consults' | translate }}
            : {{ order.item.targetQualification ? order.item.targetQualification.name : order.item.departmentSpecialization }}<span
                    class="vr"></span></ng-container>
        <ng-container *ngSwitchCase="2">{{ order.item.labData.specimenNotes }}<span class="vr" *ngIf="order.item.labData.specimenNotes"></span></ng-container>
        <ng-container *ngSwitchCase="3">{{ order.item.patData.sampleCollectionMethod.name }}<span class="vr"></span></ng-container>
        <ng-container *ngSwitchCase="4">{{ order.item.vacData.recommendation.recommendations[0].vaccineDoseNumber }} {{ 'dose' | translate }}<span
                class="vr"></span>{{ order.item.vacData.recommendation.recommendations[0].medicationName }}<span class="vr"></span></ng-container>
    </ng-container>
    <ng-container *ngIf="!hasOrderDoc()">
        <ng-container [ngSwitch]="order.item.type.code">
            <ng-container *ngSwitchCase="1">Konsultacijos, tyrimų, gydymo skyrimas</ng-container>
            <ng-container *ngSwitchCase="2">Skyrimas laboratoriniam tyrimui</ng-container>
            <ng-container *ngSwitchCase="3">Patologijos tyrimo skyrimas</ng-container>
            <ng-container *ngSwitchCase="4">Skiepų skyrimas</ng-container>
            <ng-container *ngSwitchCase="10">E027-1-I pažymos užduotis</ng-container>
            <ng-container *ngSwitchCase="11">E027-1-II pažymos užduotis</ng-container>
            <ng-container *ngSwitchCase="16">Telemedicinos užduotis</ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="hasOrderDoc()">
        <span class="">{{ order.documentDate | datetime }}</span>
        <app-doc-status-icon [status]="order.documentStatus || orderDoc.documentStatus"></app-doc-status-icon>
        <a [target]="newTab == true || newTab == 'true' ? '_blank' : '_self'" [href]="(order.compositionId || orderDoc.id) | documentLink:(order.patientId || orderDoc.patient.id)">
            {{ order.documentTitle || orderDoc.title }}
        </a>
    </ng-container>
    <br/>
    <span class="small">
    <ng-container *ngIf="doShowNotes() && order.item.notes">{{ order.item.notes }}<br/></ng-container>
         <span>{{ order.item.date | datetime }}</span>
    <ng-container *ngIf="order.item.practitioner">
      <span class="vr"></span>
         <app-practitioner-link [newTab]="newTab" [practitioner]="order.item.practitioner" [qualification]="order.item.sourceQualification.name"/>
    </ng-container>
    <ng-container *ngIf="order.item.organization"><span class="vr"></span>{{ order.item.organization.name }}</ng-container>
    <ng-container *ngIf="!order.item.organization && hasOrderDoc()"><span class="vr"></span>{{ order.organization.name || orderDoc.organization.name }}</ng-container>
  </span>
</div>
