ng1App.controller('document.E003EditCtrl',
    ['$scope', 'MessageService', 'EntitiesApiFactory', 'SveidraApiFactory', 'DocumentCtx', 'CertificateService', '$filter', '$modal', '$modalConfirm', 'DocumentModificationActions', 'SubFormValidator',
        function ($scope, MessageService, EntitiesApiFactory, SveidraApiFactory, DocumentCtx, CertificateService, $filter, $modal, $modalConfirm, DocumentModificationActions, SubFormValidator) {
            MessageService.clearAll();

            if($scope.form.partAllergies){
                $scope.currentAllergies = $scope.form.partAllergies.allergies;
            } else {
                $scope.currentAllergies = [];
            }
            // Side content list
            // jus fill array with something
            $scope._shared.contentList = _.range(18);
            $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id: 'doc_mai_inf'};
            $scope.currentDiagnoses = $scope.form.partDiagnosis;
            $scope.newRisks = $scope.form.partRiskFactors;


            $scope.types = {
                diagnosisTypes: EntitiesApiFactory.getList({classCode: 'condition-disease-type'}),
                sampleCollMethods: EntitiesApiFactory.getList({classCode: 'specimen-collection-method'}),
                prohibitAlertTypes: EntitiesApiFactory.getList({classCode: 'prohibit-alert-type'})
            };

            // $scope.validateAccomodationForm = function() {
            //     console.log("validateAccomodationForm");
            //     var accomodationForm = $scope.accomodationFormInCtrl;
            //     if (accomodationForm) {
            //         var isValid = DocumentModificationActions.validateFormSimple(accomodationForm, false);
            //         console.log('Accomodation Form Valid:', isValid);
            //     } else {
            //         console.log('Accomodation form not found');
            //     }
            // };

            $scope.handleValidationFailure = function (isValid) {
                $scope.diagnosisValid = isValid;
            };

            $scope.handleDiagnosesChange = function (event) {
                $scope.currentDiagnoses = event;
                $scope.form.partDiagnosis = event;
            };

            $scope.handleAllergiesChange = function(event) {
                $scope.currentAllergies = event;
                $scope.form.partAllergies.allergies = event;
            };

            $scope.handleRiskChange = function(event) {
                $scope.newRisks = event;
                $scope.form.partRiskFactors = event;
            };

            $scope._shared.customValidator = function (documentForm, skipRequired) {
//        if(skipRequired){
//            return true;
//        }
                var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
                MessageService.clear('diagnosis-region');
                MessageService.clear('inPatientSections-region');
                if (!$scope.form.partDiagnosis || $scope.form.partDiagnosis.length == 0) {
                    MessageService.add('error', 'Nenurodyta nė viena diagnozė.', 'diagnosis-region');
                    if (res) {
                        _.scrollTo('#diagnoses');
                    }
                }
                if ($scope.accomodationFormInCtrl &&  $scope.form.partInPatientAccomodation.length == 0) {
                    MessageService.add('error', 'Nenurodyti skyriaus duomenys.', 'inPatientSections-region');
                    var accomodationForm = $scope.accomodationFormInCtrl
                    DocumentModificationActions.validateFormSimple(accomodationForm, skipRequired);
                    return false
                }

                res = !res || validateDischargeForm(skipRequired);
                return res;

            };

            $scope._shared.onCreateSaveInterceptor = function (callback) {
                callback();
                /*
                      var modalStatistics = $modal({
                        scope: $scope,
                        title: 'F066/a-LK duomenys',
                        contentTemplate: '/templates/documents/e003/modal_statistics_e003.html'
                      });

                      $scope.modal = {
                        injuryAtWork: false,
                        departmentFilter: '',
                        department: null,
                        showDepReqError: false
                      };

                      $scope.proceed = function (sendStatistics) {
                        if (sendStatistics) {
                          // validation
                          if ($scope.modal.department == null) {
                            $scope.modal.showDepReqError = true;
                            return;
                          }
                          // data into main document object
                          DocumentCtx.document.injuryAtWork = $scope.modal.injuryAtWork;
                          DocumentCtx.document.forwardedToDepartment = $scope.modal.department.id;
                        }
                        // close modal
                        modalStatistics.hide();
                        // run further logic on submit
                        callback();
                      };

                        modalStatistics.$promise.then(function () {
                            $scope.departments = SveidraApiFactory.getDepartments();
                            modalStatistics.show();
                        });
                */
            };

            $scope.documentMode = DocumentCtx.getMode();
            $scope.documentStatus = DocumentCtx.getDocumentStatus();
            $scope.documentStatus2 = DocumentCtx.getDocumentStatus2();

            $scope.form.$promise.then(function () {
                CertificateService.prefillDocumentDiagnosis($scope.form, $scope);
            });

            var validateDischargeForm = function (skipRequired) {
                var res = true;
                MessageService.clear('partDischarge-region');
                $scope.partDischargeValidator = new SubFormValidator('partDischarge-region');
                if ($scope.form.partDischarge) {
                    // validate that all or none fields are filled
                    var dischargeForm = $scope.form.partDischarge;
                    var all = dischargeForm.dischargeDate && dischargeForm.type && dischargeForm.conditionDescription;
                    var any = dischargeForm.dischargeDate || dischargeForm.type || dischargeForm.conditionDescription;
                    var partialSubmit = skipRequired;
                    if ((partialSubmit && !all && any) || (!partialSubmit && !all)) {
                        // do report error
                        if (!dischargeForm.type) {
                            $scope.partDischargeValidator.add('type');
                        }
                        if (!dischargeForm.dischargeDate) {
                            $scope.partDischargeValidator.add('dischargeDate');
                        }
                        if (!dischargeForm.conditionDescription) {
                            $scope.partDischargeValidator.add('conditionDescription');
                        }
                        MessageService.add('error', 'Neužpildyti visi privalomi laukai.', 'partDischarge-region');
                        res = false;
                    }
                    if ($scope.form.partDischarge.type && ['1', '2', '3', '4', '5'].indexOf($scope.form.partDischarge.type.code) > -1) {
                        if (!$scope.form.partDischarge.destinationOrganizationName) {
                            $scope.partDischargeValidator.add('destinationOrganizationName');
                            MessageService.add('error', 'Neužpildyti visi privalomi laukai.', 'partDischarge-region');
                            res = false;
                        }
                    }
                    if (dischargeForm.dischargeDate && $scope.form.metadata.encounter.arrivalDate > dischargeForm.dischargeDate) {
                        MessageService.add('error', 'Išvykimo data negali būti vėlesnė nei atvykimo.', 'partDischarge-region');
                        $scope.partDischargeValidator.add('dischargeDate');
                        res = false;
                    }
                    if (!res) {
                        _.scrollTo('[msg-region="partDischarge-region"]');
                        return false;
                    }
                }
                return true;
            };

            $scope.confirmSkip = function (callback) {
                $modalConfirm.show({
                    title: $filter('translate')('F066.cancel.title'),
                    content: $filter('translate')('F066.cancel')
                }).then(callback);
            };

        }]);
