import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {FormCommunicationModel} from "../../components/modal/form-communication.model";

@Injectable({
    providedIn: 'root'
})
export class FormCommunicationService implements OnInit {

    private validationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private formData: BehaviorSubject<Map<string, Object>> = new BehaviorSubject<Map<string, Object>>(new Map<string, Object>());

    constructor() {
    }

    ngOnInit(): void {
    }

    emitValidationEvent(data?: any): void {
        this.validationSubject.next(data);
    }

    onValidationEvent(): Observable<any> {
        return this.validationSubject.asObservable();
    }

    emitValidationFormData(data: FormCommunicationModel): void {
        const emittedData: Map<string, Object> = this.formData.getValue();
        emittedData.set(data.formKey, data.formValues);
        this.formData.next(emittedData);
    }

    getFormData(): Map<string, Object> {
        console.log(this.formData.getValue())
        return this.formData.getValue();
    }
}