import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { toBoolean } from "underscore.string";
import { MessageService } from "../../services/utils/message.service";

@Component({
    selector: 'nhr-record-widget',
    templateUrl: 'nhr-record-widget.html'
})
export class NhrRecordWidgetComponent implements OnInit {
    @Input() closable: boolean = true;
    @Input() iconBgClass: string;
    @Input() iconClass: string;
    @Input() font: string;
    @Input() bodyClass: string;
    @Input() msgRegion: any;
    @Input() list: boolean;
    @Input() mixed: boolean;
    @Input() title: string;
    @Input() headerLinkText: string;
    @Input() headerLink: string;
    @Input() headerAdditionalButtonIcon: string;
    @Input() headerAdditionalButtonName: string;
    @Input() closed: boolean = false;
    @Input() showIcon: boolean;
    @Input() titleClass: string;
    @Input() titleTextClass: string;
    @Input() widgetClass: string;
    @Output() widgetToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() headerAdditionalButtonClick: EventEmitter<void> = new EventEmitter<void>();

    messages: any[];
    showTitle = false;

    constructor(private messageService: MessageService, private router: Router) {
    }

    ngOnInit() {
        //TODO: Angular accepts boolean as a string only when property is defined in brackets, e.g. [property]="false".
        // This is a workaround, which lets assign boolean property properly, in order to do not change large amount of html's.
        this.closable = typeof this.closable === 'string' ? toBoolean(this.closable) : this.closable;
        this.closed = typeof this.closed === 'string' ? toBoolean(this.closed) : this.closed;

        this.iconBgClass = this.iconBgClass ? this.iconBgClass : 'blue';
        this.bodyClass = this.bodyClass ? this.bodyClass : '';
        this.showTitle = !!(
            this.title 
            || this.iconClass
            || this.titleClass
            || this.titleTextClass
            || this.headerLinkText
            || this.headerAdditionalButtonIcon
            || this.headerAdditionalButtonName
        );
    }

    headerNavigate() {
        this.router.navigate([this.headerLink]);
    }

    toggle() {
        if (this.closable) {
            this.closed = !this.closed;
            this.widgetToggle.emit(this.closed);
        }
    }

    getItems(msgRegion: any) {
        return this.messageService.messages[msgRegion];
    }

    close(index: number) {
        this.messageService.remove(index, this.msgRegion);
    }
}
