import {Component, Input, OnInit} from "@angular/core";
import {OrganizationModel} from "../../../../shared/models/organization.model";

@Component({
    selector: 'organization-info',
    templateUrl: './organization-info.component.html'
})
export class OrganizationInfoComponent implements OnInit {

    @Input() organization: OrganizationModel;

    constructor() {

    }

    ngOnInit(): void {

    }
}