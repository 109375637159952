ng1App.factory("RolesPermissionGroupsApiFactory", 
    ['$resource', 'apiUrl',
     function ($resource, apiUrl) {

  return $resource('', {}, {
    create: {method: 'POST', url: apiUrl + '/groups'},
    getAll: {method: 'GET', url: apiUrl + '/groups'},
    getOne: {method: 'GET', url: apiUrl + '/groups/:groupId'},
    getGroupRoles: {method: 'GET', url: apiUrl + '/groups/roles/:groupId', isArray: false},
    update: {method: 'PUT', url: apiUrl + '/groups/:groupId'},
    remove: {method: 'DELETE', url: apiUrl + '/groups/:groupId'},
  });

}]);