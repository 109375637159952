angular.module('erx.api').factory('ErxForeignDispenseApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
    var URL_BASE = erxApiConfig.options.portalBackendUrl + '/ncp/documents';

    return $resource('', {id: '@id'}, {
        getForeignDocuments: {
            method: 'POST',
            url: URL_BASE + '/:id/dispenses/query',
            isArray: true
        },

        retrieveForeignDocument: {
            method: 'POST',
            url: URL_BASE + '/:id/dispenses/retrieval',
        },
    });
    
}]);