import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { DocumentCaptionModel } from "../models/shared.model";

@Component({
    selector: "app-document-caption",
    templateUrl: "./document-caption.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCaptionComponent {
    @Input() captionData: DocumentCaptionModel;
}
