ng1App.controller('doctor.patient.erx_pr.SignCtrl', 
    ['$scope', '$routeParams', 'ErxPaths', 'ErxIntegration', '$location',
     function ($scope, $routeParams, ErxPaths, ErxIntegration, $location) {

    $scope.prescriptionSignOptions = {
        // the id of the prescription being signed
        prescriptionId: $routeParams.prescriptionId,
        // the url that will be passed for callback to the gosign endpoint.
        // it should be a page with the erx-unisign-callback directive
        getUnisignCallbackUrl: function() {
            var loc = window.location.href.split( '/' );
            return loc[0] + '//' + loc[2] + ErxPaths.unisignCallback($routeParams.prescriptionId);
        },
        // view prescription url by id
        getReturnToViewPrsUrl: function() {
            return ErxPaths.viewDoc($routeParams.prescriptionId);
        },
        /* Redirect to the page with erx-prescription-list directive for the same composition */
        redirectToPrescriptionList: function () {
            return ErxPaths.list();
        },
        // docIds if available
        docIds: $routeParams.docIds
    };



}]);