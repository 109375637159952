<div class="row align-items-center mt-4 row-gap-2" [ariaLabel]="'Puslapiavimo navigacija' | translate" *ngIf="rowsCount > pageSize">
    <div class="order-1 col col-lg-auto small">{{ "Viso rezultatų: " | translate }}{{ rowsCount }}</div>
    <div class="order-3 order-lg-2 col-12 col-lg d-flex justify-content-center flex-wrap gap-1">
        <button
            class="btn btn-square-sm square-expand btn-primary"
            [ariaLabel]="'Atgal' | translate"
            [disabled]="currentPage < 1"
            (click)="onPrevSelect()">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
        </button>
        <button
            *ngIf="pagesCount > 0"
            class="btn btn-square-sm square-expand"
            [ngClass]="getButtonClass(0)"
            [ariaLabel]="('Į puslapį' | translate) + ' 1'"
            (click)="onFirstPageSelect()">
            {{ "1" }}
        </button>
        <button
            *ngIf="showPrevDots()"
            class="btn btn-square-sm square-expand"
            [ngClass]="getButtonClass(1)"
            [ariaLabel]="('Į puslapį' | translate) + ' ' + (showAfterFirst() ? 2 : calculateBackJump() + 1)"
            (click)="onDotsSelect(calculateBackJump())">
            {{ showAfterFirst() ? 2 : "..." }}
        </button>
        <button
            *ngFor="let page of pagesToIterate"
            class="btn btn-square-sm square-expand"
            [ariaLabel]="('Į puslapį' | translate) + ' ' + (page + 1)"
            [ngClass]="getButtonClass(page)" (click)="onPageSelect(page)">
            {{ page + 1 }}
        </button>
        <button
            *ngIf="showNextDots()"
            class="btn btn-square-sm square-expand"
            [ariaLabel]="('Į puslapį' | translate) + ' ' + (showAfterFirst() ? 2 : calculateForwardJump() + 1)"
            [ngClass]="getButtonClass(pagesCount - 1)"
            (click)="onDotsSelect(calculateForwardJump())"
        >
            {{ showBehindLast() ? pagesCount - 1 : "..." }}
        </button>
        <button
            *ngIf="pagesCount > 0"
            class="btn btn-square-sm square-expand"
            [ariaLabel]="('Į puslapį' | translate) + ' ' + (pagesCount - 1)"
            [ngClass]="getButtonClass(pagesCount - 1)"
            (click)="onLastPageSelect()">
            {{ pagesCount }}
        </button>
        <button
            class="btn btn-square-sm square-expand btn-primary"
            (click)="onNextSelect()"
            [ariaLabel]="'Pirmyn' | translate"
            [disabled]="pagesCount === realCurrentPage"
        >
            <i class="fa fa-angle-right" aria-hidden="true"></i>
        </button>
    </div>
    <div class="order-2 order-lg-3" style="width: 150px">
        <div class="input-group input-group-sm">
            <input
                type="number"
                [min]="1"
                [max]="pagesCount"
                class="form-control"
                placeholder="{{ 'Puslapis' | translate }}"
                aria-describedby="typePage"
                [(ngModel)]="typedPage"
                [ariaLabel]="'Įveskite puslapį' | translate"
            />
            <button
                id="typePage"
                class="btn btn-square-sm square-expand btn-primary input-group-text"
                [ariaLabel]="'Pirmyn' | translate"
                (click)="onPageEnter(typedPage)">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>
