<div>
    <h1>Ambulatorinės slaugos poreikių vertinimo klausimynas</h1>
    <back-link [path]="" />
    <div *ngIf="questionaireClassifiers">
        <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
            [prefillData]="prefillData"></app-common-patient-widget>
        <form [formGroup]="questionnaire" (submit)="onSave()">
            <!-- Widget for savirupa -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Savirūpa' | translate" id="selfCare">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <fieldset>
                            <legend>
                                {{ "Savirūpa" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-self-care-assessment']; let i=index">
                                <app-radio-button id='selfCare{{i}}' radioName="selfCare"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('selfCare')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <fieldset>
                            <legend>
                                {{ "Valgymas ir gėrimas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-eat-drink-self-care-assessment']; let i=index">
                                <app-radio-button id='eatingDrinking{{i}}' radioName="eatingDrinking"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('eatingDrinking')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>

            <!-- Widget for Asmens higiena ir rengimasis -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Asmens higiena ir rengimasis' | translate"
                id="hygiene">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <fieldset>
                            <legend>
                                {{ "Higiena rūpinasi" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-hygene-self-care-assessment']; let i=index">
                                <app-radio-button id='hygiene{{i}}' radioName="hygiene"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('hygiene')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <fieldset>
                            <legend>
                                {{ "Apsirengia ir apsiauna" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-dressing-self-care-assessment']; let i=index">
                                <app-radio-button id='dressingUp{{i}}' radioName="dressingUp"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('dressingUp')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>

            <!-- Widget for Regėjimas ir klausa -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Regėjimas ir klausa' | translate"
                id="visionHearing">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <fieldset>
                            <legend>
                                {{ "Regėjimas" | translate }}
                            </legend>
                            <div *ngFor="let item of questionaireClassifiers['nursing-vision-assessment']; let i=index">
                                <app-radio-button id='vision{{i}}' radioName="vision"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('vision')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <fieldset>
                            <legend>
                                {{ "Klausa" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-hearing-assessment']; let i=index">
                                <app-radio-button id='hearing{{i}}' radioName="hearing"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('hearing')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>


            <!-- Widget for Judėjimas ir transportavimas -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Judėjimas ir transportavimas' | translate"
                id="movementTransportation">
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Kūno padėtis" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-body-position-assessment']; let i=index">
                                <app-radio-button id='bodyPosition{{i}}' radioName="bodyPosition"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('bodyPosition')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Judėjimas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-body-mobility-assessment']; let i=index">
                                <app-radio-button id='movement{{i}}' radioName="movement"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('movement')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Griuvimo rizika" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-body-falling-risk-assessment']; let i=index">
                                <app-radio-button id='fallingRisk{{i}}' radioName="fallingRisk"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('fallingRisk')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Judesių koordinacija" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-body-movements-coordination-assessment']; let i=index">
                                <app-radio-button id='movementCoordination{{i}}' radioName="movementCoordination"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('movementCoordination')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Pusiausvyra" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-balance-assessment']; let i=index">
                                <app-radio-button id='balance{{i}}' radioName="balance"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('balance')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>


            <!-- Widget for Orentacija -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Orientacija' | translate" id="orentation">
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Orientacija laike" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-time-orientation-assessment']; let i=index">
                                <app-radio-button id='timeOrentation{{i}}' radioName="timeOrentation"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('timeOrentation')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Orientacija erdvėje" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-space-orientation-assessment']; let i=index">
                                <app-radio-button id='spaceOrentation{{i}}' radioName="spaceOrentation"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('spaceOrentation')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Savęs suvokimas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-self-perception-assessment']; let i=index">
                                <app-radio-button id='selfAwareness{{i}}' radioName="selfAwareness"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('selfAwareness')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>

            <!-- Widget for Bendravimas -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Bendravimas' | translate" id="comunication">
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Sąmonė" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-consciousness-assessment']; let i=index">
                                <app-radio-button id='consciousness{{i}}' radioName="consciousness"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('consciousness')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Kalba" | translate }}
                            </legend>
                            <div *ngFor="let item of questionaireClassifiers['nursing-speach-assessment']; let i=index">
                                <app-radio-button id='language{{i}}' radioName="language"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('language')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Minčių raiška" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-thoughts-expression-assessment']; let i=index">
                                <app-radio-button id='thoughtsExpression{{i}}' radioName="thoughtsExpression"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('thoughtsExpression')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Pateiktos informacijos suvokimas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-information-perception-assessment']; let i=index">
                                <app-radio-button id='infoPerception{{i}}' radioName="infoPerception"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('infoPerception')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Sprendimų priėmimas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-decision-making-assessment']; let i=index">
                                <app-radio-button id='decisionMaking{{i}}' radioName="decisionMaking"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('decisionMaking')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>

            <!-- Widget for Vaistai, Gyvenamoji vieta, Specializuota priežiūra -->
            <nhr-record-widget [closable]="true" [closed]="false"
                [title]="'Vaistai, Gyvenamoji vieta, Specializuota priežiūra' | translate" id="medicamentsOther">
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Vaistų vartojimas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-medication-use-assessment']; let i=index">
                                <app-radio-button id='medicationUse{{i}}' radioName="medicationUse"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('medicationUse')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Gyvenamosios vietos aplinkos pritaikymas" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-environment-adaptation-assessment']; let i=index">
                                <app-radio-button id='enviromentAdaptation{{i}}' radioName="enviromentAdaptation"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('enviromentAdaptation')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Specializuota priežiūra" | translate }}
                            </legend>
                            <div class="row">
                                <div class="form-check">
                                    <app-checkbox
                                        labelText='{{ "Asmeniui yra reikalinga dirbtinių angų, žaizdų arba pragulų priežiūra bei profilaktika arba nuolatinio šlapimo pūslės kateterio priežiūra" | translate }} - 4'
                                        [fControl]="questionnaire.get('specCare')"
                                        (checkboxChange)="countResult()"></app-checkbox>
                                    <!-- <input type="checkbox" class="form-check-input" name="specCare"
                                        formControlName="specCare" id="specCare1" value="true"
                                        (change)="countResult()" />
                                    <label class="form-check-label" for="specCare1">{{ "Asmeniui yra reikalinga dirbtinių angų, žaizdų arba pragulų priežiūra bei profilaktika arba nuolatinio šlapimo pūslės kateterio priežiūra" | translate }} - 4</label> -->
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>


            <!-- Widget for Specialistų pagalba -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Specialistų pagalba' | translate"
                id="specialistHelp">
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Slaugytojo paslaugos" | translate }}
                            </legend>
                            <div *ngFor="let item of questionaireClassifiers['nurse-service-assessment']; let i=index">
                                <app-radio-button id='nursingServices{{i}}' radioName="nursingServices"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('nursingServices')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Slaugytojo paslaugų reguliarumas" | translate }}
                            </legend>
                            <div class="row"
                                *ngFor="let item of questionaireClassifiers['nurse-service-regularity-assessment']; let i=index">
                                <div class="col-12">
                                    <app-radio-button id='nurseServicesRegularity{{i}}'
                                        radioName="nurseServicesRegularity"
                                        labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                        radioValue="{{item.code}}"
                                        [fControl]="questionnaire.get('nurseServicesRegularity')"
                                        (change)="countResult()" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Slaugytojo padėjėjo paslaugos" | translate }}
                            </legend>
                            <div class="row"
                                *ngFor="let item of questionaireClassifiers['nurse-assistant-service-assessment']; let i=index">
                                <div class="col-12">
                                    <app-radio-button id='nursingAssistantServices{{i}}'
                                        radioName="nursingAssistantServices"
                                        labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                        radioValue="{{item.code}}"
                                        [fControl]="questionnaire.get('nursingAssistantServices')"
                                        (change)="countResult()" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Slaugytojo padėjėjo paslaugų reguliarumas" | translate }}
                            </legend>
                            <div class="row"
                                *ngFor="let item of questionaireClassifiers['nurse-assistant-service-regularity-assessment']; let i=index">
                                <div class="col-12">
                                    <app-radio-button id='nurseAssistanrServicesRegularity{{i}}'
                                        radioName="nurseAssistanrServicesRegularity"
                                        labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                        radioValue="{{item.code}}"
                                        [fControl]="questionnaire.get('nurseAssistanrServicesRegularity')"
                                        (change)="countResult()" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Kineziterapeuto paslaugos" | translate }}
                            </legend>
                            <div class="row"
                                *ngFor="let item of questionaireClassifiers['nursing-physiotherapist-service-assessment']; let i=index">
                                <div class="col-12">
                                    <app-radio-button id='physiotherapistServices{{i}}'
                                        radioName="physiotherapistServices"
                                        labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                        radioValue="{{item.code}}"
                                        [fControl]="questionnaire.get('physiotherapistServices')"
                                        (change)="countResult()" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <fieldset>
                            <legend>
                                {{ "Socialinės paslaugos" | translate }}
                            </legend>
                            <div
                                *ngFor="let item of questionaireClassifiers['nursing-social-service-assessment']; let i=index">
                                <app-radio-button id='socialServices{{i}}' radioName="socialServices"
                                    labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                    radioValue="{{item.code}}" [fControl]="questionnaire.get('socialServices')"
                                    (change)="countResult()" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>

            <!-- Widget for Artimųjų dalyvavimas asmens priežiūroje -->
            <nhr-record-widget [closable]="true" [closed]="false"
                [title]="'Artimųjų dalyvavimas asmens priežiūroje' | translate" id="familyHelp">
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <fieldset>
                            <legend>
                                {{ "Slaugytojo padėjėjo paslaugų reguliarumas" | translate }}
                            </legend>
                            <div class="row"
                                *ngFor="let item of questionaireClassifiers['nursing-relative-care-assessment']; let i=index">
                                <div class="col-12">
                                    <app-radio-button id='relativesPersonalCare{{i}}' radioName="relativesPersonalCare"
                                        labelText='{{ (item.name + " - " +  item.value) | translate }}'
                                        radioValue="{{item.code}}"
                                        [fControl]="questionnaire.get('relativesPersonalCare')"
                                        (change)="countResult()" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </nhr-record-widget>
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Galutinis Vertinimas' | translate"
                id="finalResult">
                <div class="row" *ngIf="result > 45">
                    <div class="col">
                        <div class="alert alert-warning" role="alert">
                            Rekomenduojama paciento priežiūra slaugos namuose
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3 col-md-2 col-xl-1">
                        <label for="data">{{"Data" | translate}} </label>
                    </div>
                    <div class="col-sm-9 col-md-10 col-xl-11">
                        <app-date-picker inputId="data" [fControl]="questionnaire.get('date')"
                                         [minDate]="minDate" [showErrors]="true" />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 col-md-2 col-xl-1">
                        <label for="result">{{"Rezultatas" | translate}} </label>
                    </div>
                    <div class="col-sm-9 col-md-10 col-xl-11">
                        <app-input readonly="true" value="{{resultString}}" viewMode="true" inputClass="text-primary" />
                    </div>
                </div>
            </nhr-record-widget>



            <div class="block mt-3">
                <div class="buttons">
                    <!--            Will be changed with DocumentModificationActions component-->
                    <button class="btn btn-primary m-1" type="button">{{'doc.submit.sign'|translate}}</button>
                    <button class="btn btn-primary m-1" type="button">{{'doc.submit.batch.sign'|translate}}</button>
                    <button class="btn btn-secondary m-1" type="button">{{'doc.provide'|translate}}</button>
                    <button class="btn btn-secondary m-1" type="submit">
                        {{'doc.save'|translate}} </button>
                    <button class="btn btn-danger m-1" type="button">{{'doc.clear'|translate}}
                    </button>
                </div>
            </div>

        </form>


    </div>
</div>