<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngFor="let surgery of surgeries">
        <p class="mb-0">
            <small>
                {{ surgery.date }}
            </small>
        </p>
        <span *ngIf="surgery.type" class="fw-bold mb-0">
            {{ surgery.type.code }}
        </span>
        <span class="vr" *ngIf="surgery.type"></span>
        <span *ngIf="surgery.type" class="fw-bold mb-0">
            {{ surgery.type.display }}
        </span>
        <div class="mb-0">
            <div class="text-truncate">
                {{ surgery.notes }}
            </div>
        </div>
    </li>
</nhr-record-widget>