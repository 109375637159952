ng1App.factory('GpApiFactory', 
    ['$resource', '$routeParams', 'apiUrl',
     function ($resource, $routeParams, apiUrl) {

    var URL_BASE = apiUrl + '/certificate-gp';
    return $resource('', {}, {
        isGpCertificateExist: {
            method: 'GET',
            url: URL_BASE + '/:id/certificate/isExist'
        }
    });
}]);