<nhr-record-widget
    [closable]="true"
    [closed]="closed"
    [title]="'E113-AP | Nėščiosios ambulatorinis aprašymas' | translate"
    id="womanAmbulatoryDescription"
    class="h-auto"
>
    <div *ngFor="let description of outpatientVisitDescriptions | async">
        <app-outpatient-visit-description-template
            [description]="description"
        ></app-outpatient-visit-description-template>
    </div>
</nhr-record-widget>
<nhr-record-widget
    *ngIf="summaryView"
    [closable]="true"
    [closed]="closed"
    [title]="'Priežiūros planas' | translate"
    id="maintenancePlan"
    class="h-auto"
>
    <table class="table table-bordered table-hover w-100 mt-4">
        <thead>
            <tr>
                <th *ngFor="let headerName of maintenancePlanHeader | async" class="text-center">
                    {{ headerName | translate }}
                </th>
            </tr>
            <tr>
                <th *ngFor="let headerName of maintenancePlanDateHeader | async" class="text-center">
                    {{ checkIfDate(headerName) | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let val of maintenancePlan | async" class="text-center">
                <td *ngIf="val.name">
                    {{ val.name }}
                </td>
                <ng-container *ngFor="let key of maintenancePlanWeeksUpdated">
                    <td
                        *ngIf="!val[key].hideColumn"
                        [ngClass]="val[key].style"
                        [attr.colspan]="val[key].mergeLines || 1"
                    >
                        {{ val[key]?.note }}
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
    <span>{{
        "*KMI apskaičiuojamas pirmo apsilankymo motu; kiekvieno apsilankymo metu renkama, tikslinama anamnezė; matuojamas AKS; tiriamas šlapimas; gimdos dugno aukštis matuojamas ir VŠR vertinimas nuo 24 nėštumo sav."
            | translate
    }}</span
    ><br />
    <span>{{ "** kai moters Rh(-), o vyro Rh(+)" | translate }}</span>
</nhr-record-widget>
