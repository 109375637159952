<nhr-record-widget [closable]="true" [closed]="false" [mixed]="true" title="{{'doc.mentalHealthComponent.additionalForm.title'|translate}}">
    <div class="widget-body">
        <div *ngIf="documentData">
            <div class="row align-items-center">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label text-nowrap">{{'date' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    {{ documentData.date | date: 'YYYY-MM-dd HH:mm' }}
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label text-nowrap">{{'doc.dia.sts' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    {{ getDocStatus(documentData.status) | translate }}
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label text-nowrap">{{'doc.mentalHealthComponent.recomendationsForm.showPatient' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    {{ documentData?.showToPatient ? 'Taip' : 'Ne' }}
                </div>
            </div>
        </div>
        <div class="mt-2 d-flex justify-content-between" *ngIf="!documentData">
            <span>
                {{ 'doc.mentalHealthComponent.additionalForm.dataSupplement.none' | translate }}
            </span>
            <button class="btn btn-sm btn-secondary"
                    (click)="redirectToAttachmentPage()">
                {{ 'doc.mentalHealthComponent.additionalForm.dataSupplement.create' | translate }}
            </button>
        </div>
        <div class="mt-2 d-flex justify-content-end" *ngIf="documentData">
            <button class="btn btn-sm btn-secondary"
                    (click)="redirectToAttachmentPage(true)">
                {{ 'doc.mentalHealthComponent.additionalForm.dataSupplement.view' | translate }}
            </button>
        </div>
    </div>
</nhr-record-widget>
