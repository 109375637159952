ng1App.controller('doctor.patient.cert.e106.1.2.EditCtrl', 
    ['$scope', '$routeParams', '$filter', 'MessageService', 'CertificatePayment', 'EntitiesApiFactory', 'TypeaheadSearchHandlers', 'CertificateService', 'CommonFunctionService', 'CertificatesApiFactory', '$window',
     function ($scope, $routeParams, $filter,
                                                                     MessageService, CertificatePayment, EntitiesApiFactory, TypeaheadSearchHandlers,
                                                                     CertificateService, CommonFunctionService, CertificatesApiFactory, $window) {
    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.e106_2_1.mai.inf'),
                id: 'cer_e106_2_1_mai_inf'
            },
            {
                title: $filter('translate')('cer.e106_2_1.dec'),
                id: 'cer_e106_2_1_dec',
                show: function () {
                    if ($scope.additional.bornDead) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e106_2_1.und'),
                id: 'cer_e106_2_1_und'
            },
            {
                title: $filter('translate')('cer.e106_2_1.rep'),
                id: 'cer_e106_2_1_rep'
            },
            {
                title: $filter('translate')('cer.e106_2_1.dea'),
                id: 'cer_e106_2_1_dea'
            },
            {
                title: $filter('translate')('cer.e106_2_1.bor'),
                id: 'cer_e106_2_1_bor'
            },
            {
                title: $filter('translate')('cer.e106_2_1.inf'),
                id: 'cer_e106_2_1_inf'
            },
            {
                title: $filter('translate')('cer.e106_2_1.mot'),
                id: 'cer_e106_2_1_mot'
            },
            {
                title: $filter('translate')('cer.e106_2_1.new'),
                id: 'cer_e106_2_1_new'
            },
            {
                title: $filter('translate')('cer.e106_2_1.new.rea'),
                id: 'cer_e106_2_1_new_rea'
            },
            {
                title: $filter('translate')('cer.e106_2_1.mot.rea'),
                id: 'cer_e106_2_1_mot_rea'
            },
            {
                title: $filter('translate')('cer.e106_2_1.oth'),
                id: 'cer_e106_2_1_oth'
            },
            {
                title: $filter('translate')('cer.e106_2_1.wri'),
                id: 'cer_e106_2_1_wri'
            }
        ]
    };

    $scope.$emit('setHelpPage', 'kurti-pazyma-e106-2-1--medicininis-perinatalines-mirties-liudijimas');

    var certificateType = $scope.certificateType;
    CertificatePayment.showPaymentMessage(certificateType);

    if (!$scope.certificate.deathCauseFromMother) {
        $scope.certificate.deathCauseFromMother = {};
    }
    if (!$scope.certificate.deathCause) {
        $scope.certificate.deathCause = {};
    }

    $scope.additional = {
        byBodyFoundDate: false,
        replace: false,
        isReplacedCertificateDigital: false,
        deathCause: {},
        deathCauseFromMother: {}
    };
    $scope.codes = {
        birthPlaceHospital: '1',
        deathPlaceHospital: '1',
        deathTypeDiedFirstWeek: '2',
        deathTypeBornDead: '1',
        deathCertificateSpecialistOther: '7'
    };

    if($scope.certificate.birthHospitalName){
        $scope.certificate.birthHospitalNameDisplay = $scope.certificate.birthHospitalName;
    }

    if($scope.certificate.deathHospitalName){
        $scope.certificate.deathHospitalNameDisplay = $scope.certificate.deathHospitalName;
    }

    if ($scope.certificate.deathType) {
        $scope.additional.bornDead = $scope.certificate.deathType.code === $scope.codes.deathTypeBornDead;
    } else {
        $scope.additional.bornDead = $routeParams.bornDead;
    }
    
    if ( $scope.additional.bornDead) {
        MessageService.info('Pažyma saugoma motinos elektroninėje sveikatos istorijoje', 'patient_pract_data_region');
    } else {
        MessageService.info('Pažyma saugoma mirusiojo naujagimio elektroninėje sveikatos istorijoje', 'patient_pract_data_region');
    }

    if ($scope.certificate.patient.birthDate && !$scope.certificate.birthDateAndTime && !$scope.additional.bornDead) {
        $scope.certificate.birthDateAndTime = _.truncateToDays(new Date($scope.certificate.patient.birthDate).getTime());
    }

    if ($scope.certificate.deathCause && $scope.certificate.deathCause.mainDisease) {
        var mainDisease = $scope.certificate.deathCause.mainDisease;
        $scope.additional.deathCause.mainDisease = mainDisease;
        $scope.additional.deathCause.mainDisease.label = mainDisease.code + ' ' + mainDisease.name;
    }
    if ($scope.certificate.deathCauseFromMother && $scope.certificate.deathCauseFromMother.mainDisease) {
        var mainDisease = $scope.certificate.deathCauseFromMother.mainDisease;
        $scope.additional.deathCauseFromMother.mainDisease = mainDisease;
        $scope.additional.deathCauseFromMother.mainDisease.label = mainDisease.code + ' ' + mainDisease.name;
    }
//...reikia cia pataisyti...
    if ($scope.certificate.mother) {
        $scope.patient = $scope.certificate.patient;
        $scope.mother = $scope.certificate.mother;
    } else if ($scope.additional.bornDead) {
        //then document is filled from mother
        $scope.mother = $scope.certificate.patient;
    } else {
        $scope.patient = $scope.certificate.patient;
        if (!$scope.certificate.mother) {
            MessageService.error($filter('translate')('err.e106_2_1.motherRequired'));
        }
    }

    $scope.currentDate = new Date();
    $scope.maxCreationDate = new Date();

    $scope.saveAndSign = function (doSign) {
        $scope.clearDisabledFields();
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    $scope.clearDisabledFields = function () {
        if(!$scope.additional.babyUnidentified && !$scope.additional.bornDead){
            $scope.certificate.hypotheticalAge = null;
        }
        if($scope.certificate.birthPlace && $scope.certificate.birthPlace.code != $scope.codes.birthPlaceHospital){
            $scope.certificate.birthHospitalName = null;
            $scope.certificate.birthHospitalNameDisplay = null;
        }
        if($scope.certificate.deathPlace && $scope.certificate.deathPlace.code != $scope.codes.deathPlaceHospital){
            $scope.certificate.deathHospitalName = null;
            $scope.certificate.deathHospitalNameDisplay = null;
        }
        if(!$scope.additional.replace){
            $scope.certificate.replacedCertificate = null;
            $scope.certificate.replacedDigitalCertificate = null;
        }
        if($scope.additional.isReplacedCertificateDigital && $scope.additional.replace){
            $scope.certificate.replacedCertificate = null;
        }
        if(!$scope.additional.isReplacedCertificateDigital && $scope.additional.replace){
            $scope.certificate.replacedDigitalCertificate = null;
        }
        if($scope.certificate.deathCertificateSpecialistType && $scope.certificate.deathCertificateSpecialistType.code != $scope.codes.deathCertificateSpecialistOther){
            $scope.certificate.otherExplanations = null;
        }
    };

    // TODO implement
    $scope.saveDraft = function () {
        alert('Not implemented');
    };

    $scope.deathDateAndTimeRequired = function () {
        return (!$scope.certificate.hypotheticalAge && $scope.certificate.deathType
                && $scope.certificate.deathType.code === $scope.codes.deathTypeDiedFirstWeek
                )
                || $scope.certificate.hypotheticalAge;
    };
    $scope.deathPlaceRequired = function () {
        return (!$scope.certificate.hypotheticalAge && $scope.certificate.deathType
                && $scope.certificate.deathType.code === $scope.codes.deathTypeDiedFirstWeek
                )
                || $scope.certificate.hypotheticalAge;
    };


    $scope.additional.isReplacedCertificateDigital = $scope.certificate.replacedDigitalCertificate && $scope.certificate.replacedDigitalCertificate.fullId != null;
    $scope.additional.replace = $scope.certificate.replacedCertificate && ($scope.certificate.replacedCertificate.number != null || $scope.certificate.replacedCertificate.date != null) || $scope.additional.isReplacedCertificateDigital;

    $scope.replacedCertificateRegisteredChange = function(){
        if($scope.additional.replace && $scope.additional.isReplacedCertificateDigital){
            $scope.certLoading = true;
            CertificatesApiFactory.findDeathCertificateList({patientId: $scope.certificate.patient.id, type: 'e106_2_1'},function(result){
                if(result.values && result.values.length > 0){
                    $scope.certificate.replacedDigitalCertificate = result.values[0];
                    $scope.certificate.replacedDigitalCertificateList = result.values;
                } else {
                    $scope.certificate.replacedDigitalCertificate = null;
                    $scope.certificate.replacedDigitalCertificateList = null;
                    MessageService.error($filter('translate')('cer.e106_2_1.rep.nrDi.empty'));
                    $window.scrollTo(0, 10);
                }
                $scope.certLoading = false;
            },function(){
                $scope.certLoading = false;
            })
        }
    };

    $scope.$watch('additional.babyUnidentified', function (newValue) {
        $scope.certificate.hypotheticalAge = null;
        if (!newValue && $scope.certificate.deathType && $scope.certificate.deathType.code === $scope.codes.deathTypeBornDead) {
            $scope.certificate.deathDateAndTime = null;
            $scope.certificate.deathPlace = null;
            $scope.certificate.deathHospitalName = null;
        }
    });
    $scope.$watch('certificate.deathType', function (newValue) {
        if (!$scope.additional.babyUnidentified && newValue && newValue.code === $scope.codes.deathTypeBornDead) {
            $scope.certificate.deathDateAndTime = null;
            $scope.certificate.deathPlace = null;
            $scope.certificate.deathHospitalName = null;
        }
    });

    var getDeathInterpretation = function(){
        var res;
        _.each($scope.deathDateInterpretations, function (value) {
            if ("1" === value.code) {
                res = value;
            }
        });
        return res;
    }
    $scope.$watch('additional.byBodyFoundDate', function (newValue) {
        $scope.certificate.deathDateInterpretation = newValue ? getDeathInterpretation() : null;
    });

    $scope.genders = EntitiesApiFactory.getList({
        classCode: 'administrative-gender'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'administrativeGender', $scope.genders);

    $scope.certificateStatuses = EntitiesApiFactory.getList({
        classCode: 'death-certificate-status'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'certificateStatus', $scope.certificateStatuses);

    $scope.babyBirthPlaces = EntitiesApiFactory.getList({
        classCode: 'baby-birth-place'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'birthPlace', $scope.babyBirthPlaces);


    $scope.babyDeathPlaces = EntitiesApiFactory.getList({
        classCode: 'baby-death-place'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'deathPlace', $scope.babyDeathPlaces);


    $scope.babyDeadBornNumbers = EntitiesApiFactory.getList({
        classCode: 'baby-dead-born-number'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'babyDeadBornNumber', $scope.babyDeadBornNumbers);


    $scope.educationTypes = EntitiesApiFactory.getList({
        classCode: 'education-type'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate.motherData, 'education', $scope.educationTypes);


    $scope.pregnancyCompletions = EntitiesApiFactory.getList({
        classCode: 'pregnancy-completion'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'pregnancyCompletion', $scope.pregnancyCompletions);


    $scope.babyDeathTypes = EntitiesApiFactory.getList({
        classCode: 'baby-death-type'
    }, function (result) {
        $scope.babyDeathTypes = result;
        if ($scope.additional.bornDead) {
            for (var i = 0; i < result.length; i++) {
                if (result[i].code === $scope.codes.deathTypeBornDead) {
                    $scope.certificate.deathType = result[i];
                    break;
                }
            }
        }
    });

    $scope.perinatalDeathTypes = EntitiesApiFactory.getList({
        classCode: 'perinatal-death-type'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'perinatalDeathType', $scope.perinatalDeathTypes);

    $scope.deathCertificateSpecialistTypes = EntitiesApiFactory.getList({
        classCode: 'death-cert-specialist-type'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'deathCertificateSpecialistType',
            $scope.deathCertificateSpecialistTypes);

    $scope.deathDateInterpretations = EntitiesApiFactory.getList({
        classCode: 'death-date-interpretation'
    });
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'deathDateInterpretation', $scope.deathDateInterpretations);

    $scope.searchDeathCauses = TypeaheadSearchHandlers.ICD10;

    $scope.toggleDeathType = function (value) {
        if ($scope.additional.deathType) {
            $scope.certificate.deathType = value;
        } else {
            $scope.certificate.deathType = null;
        }
    };

    $scope.deathCauseMainDiseaseChange = function () {
        //workaround for typeahead bug in firefox
        if (typeof $scope.additional.deathCause.mainDisease != 'string') {
            $scope.certificate.deathCause.mainDisease = $scope.additional.deathCause.mainDisease;
        }
        if ($scope.certificate.deathCause.mainDisease && $scope.certificate.deathCause.mainDisease.name) {
            $scope.certificate.deathCause.description = $scope.certificate.deathCause.mainDisease.name;
            //XXX: parent elemement uses directive with 'scope: true' and on change does not work properly
            if (!$scope.$$phase) {
                $scope.$digest();
            }
        }
    };
    $scope.deathCauseFromMotherMainDiseaseChange = function () {
        //workaround for typeahead bug in firefox
        if (typeof $scope.additional.deathCauseFromMother.mainDisease != 'string') {
            $scope.certificate.deathCauseFromMother.mainDisease = $scope.additional.deathCauseFromMother.mainDisease;
        }
        if ($scope.certificate.deathCauseFromMother.mainDisease && $scope.certificate.deathCauseFromMother.mainDisease.name) {
            $scope.certificate.deathCauseFromMother.description = $scope.certificate.deathCauseFromMother.mainDisease.name;
            //XXX: parent elemement uses directive with 'scope: true' and on change does not work properly
            if (!$scope.$$phase) {
                $scope.$digest();
            }
        }
    };
    $scope.$watch('certificate.hypotheticalAge', function (value) {
        if (!value && !$scope.additional.bornDead) {
            EntitiesApiFactory.getList({
                searchCriteria: $scope.codes.deathTypeDiedFirstWeek,
                classCode: 'baby-death-type',
                byCode: true
            }, function (result) {
                $scope.certificate.deathType = result[0];
            })

        }
    });

    $scope.searchOrganizations = TypeaheadSearchHandlers.ORGANIZATIONS;
    $scope.searchBirthOrganizationsChange = function (org) {
        if (org && org.id) {
            $scope.certificate.birthHospitalName = org.name;
            $scope.certificate.birthHospitalRef = org.fullId;
        }
    };
    $scope.searchDeathOrganizationsChange = function (org) {
        if (org && org.id) {
            $scope.certificate.deathHospitalName = org.name;
            $scope.certificate.deathHospitalRef = org.fullId;
        }
    };

    $scope.certificate.$promise.then(function() {
        var cert = $scope.certificate;

        if (cert.deathCause && cert.deathCause.mainDisease && cert.deathCause.mainDisease.code) {
            $scope.additional.deathCause.mainDisease = TypeaheadSearchHandlers.entities_label(cert.deathCause.mainDisease);
        }
        if (cert.deathCauseFromMother && cert.deathCauseFromMother.mainDisease && cert.deathCauseFromMother.mainDisease.code) {
            $scope.additional.deathCauseFromMother.mainDisease = TypeaheadSearchHandlers.entities_label(cert.deathCauseFromMother.mainDisease);
        }
    });

}]);