import { HttpClient } from "@angular/common/http";

import {map, Observable, shareReplay} from "rxjs";
import { TranslateLoader } from "@ngx-translate/core";
import gettext from "gettext-parser";


export function createPoTranslateLoader(http: HttpClient) {
    return new TranslatePoHttpLoader(http, "assets/po", ".po");
}

export class TranslatePoHttpLoader implements TranslateLoader {
    public domain = "";
    cache$: Observable<Object> = null;
    cachedLang: string = null;

    constructor(
        protected _http: HttpClient,
        protected _prefix: string = "po",
        protected _suffix: string = ".po"
    ) {}

    // Gets the translations from file
    public getTranslation(lang: string): Observable<Object> {
        if (!this.cache$ || this.cachedLang !== lang) {
            console.debug("Loading lang: %s", lang)
            this.cache$ = this._http
                .get(`${this._prefix}/${lang}${this._suffix}`, { responseType: "text" })
                .pipe(map((contents: string) => this.parse(contents)))
                .pipe(shareReplay(1));
            this.cachedLang = lang;
        }
        return this.cache$;
        //
        // return this._http
        //     .get(`${this._prefix}/${lang}${this._suffix}`, { responseType: "text" })
        //     .pipe(map((contents: string) => this.parse(contents)))
        //     // .map((contents: string) => this.parse(contents));
    }

    // Parse po file
    public parse(contents: string): any {
        const translations: { [key: string]: string } = {};

        const po = gettext.po.parse(contents, "utf-8");
        if (!po.translations.hasOwnProperty(this.domain)) {
            return translations;
        }

        Object.keys(po.translations[this.domain]).forEach(key => {
            const translation: string = po.translations[this.domain][
                key
                ].msgstr.pop();
            if (key.length > 0 && translation.length > 0) {
                translations[key] = translation;
            }
        });

        return translations;
    }
}