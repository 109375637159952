angular.module('medvais.api').factory('MedvaisEncounterApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/encounter';
    
    return $resource('', {}, {
        
        createVirtual: {
            method: 'POST',
            url: URL_BASE + '/virtual'
        },
        
        getByPatient: {
            method: 'GET',
            url: URL_BASE + '/byPatient?patient=:patientId&organizationId=:organizationId',
            isArray: true
        }
        
    });
    
    
}]);

