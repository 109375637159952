ng1App.factory('AagaSgasApiFactory',
    ['$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/aagasgas';
    return $resource('', {}, {
        // -------- new ones ----------
        getAagaSgasForDocument: {
            method: 'GET',
            url: URL_BASE + '/:id'
        }
    });
}]);