<div class="modal-header">
    <h5 *ngIf="modalMode === 'complaint'" class="modal-title">{{'doc.med.dat.comp' | translate}}</h5>
    <h5 *ngIf="modalMode === 'anamnesis'" class="modal-title">{{'form.med.modal.anam' | translate}}</h5>
    <button type="button"  class="btn-close close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>

<div class="modal-body p-0">
    <form [formGroup]="form" class="row bg-light m-0 p-3">
        <div class="col-6 col-md">
            <strong>Nuo</strong>
            <div>
                <app-date-picker
                inputId="DateForm"
                [minDate]="twoYearsFromNow"
                [maxDate]="currentDate"
                [fControl]="form?.get('dateFrom')"
                suffixIcon="fa fa-calendar">
                </app-date-picker>
            </div>
        </div>
        <div class="col-6 col-md">
            <strong>Iki</strong>
            <div>
                <app-date-picker
                inputId="DateTo"
                [minDate]="twoYearsFromNow"
                [maxDate]="currentDate"
                [fControl]="form?.get('dateTo')"
                suffixIcon="fa fa-calendar">
                </app-date-picker>
            </div>
        </div>
        <div class="col-md-4">
            <strong>{{ 'cer.025.visits_dia'| translate }}</strong>
            <div>
                <input formControlName="diagnosis1"
                typeaheadOptionField="displayValue"
                [typeahead]="suggestions$"
                [typeaheadAsync]="true"
                [typeaheadItemTemplate]="customItemTemplate"
                (input)="onTypeaheadInput1($event)"
                (typeaheadOnSelect)="typeaheadOnSelect1($event)"
                (typeaheadNoResults)="typeaheadNoResults($event)"
                placeholder="{{'cer.025.final_dia_placeh' | translate}}"
                class="form-control">
                <ng-template #customItemTemplate let-model="item">
                    <span>{{model.code}} {{model.name}}</span>
                </ng-template>
            </div>
        </div>
        <div class="col-md-4">
            <strong>{{'doc.per.inf.doctor' | translate }}</strong>
            <div>
                <input formControlName="specialist1"
                typeaheadOptionField="givenNameAndFamilyName"
                [typeahead]="suggestionsPract$"
                [typeaheadAsync]="true"
                [typeaheadItemTemplate]="customItemTemplate2"
                (input)="onTypeaheadInput2($event)"
                (typeaheadOnSelect)="typeaheadOnSelect2($event)"
                (typeaheadNoResults)="typeaheadNoResults($event)"
                placeholder="Įveskite įstaigos Specialisto pavardę"
                class="form-control">
                <ng-template #customItemTemplate2 let-model="item">
                    <span><strong>{{model.givenName}}</strong> - {{model.familyName}}</span>
                </ng-template>
            </div>
        </div>
    </form>

        <ol *ngIf="practitionersData?.length" class="slats hover">
            <li *ngFor="let card of practitionersData; let i = index"
                class="pb-2 p-4 {{ card.conditions[0]?.diagnosisType === 'primary' ? ' bg-success-subtle' : '' }}">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="part-medical-practitioners-data-list-{{i}}" [(ngModel)]="card.selected" (change)="onCardSelectionChange()" />
                    <label for="part-medical-practitioners-data-list-{{i}}">{{ card.conditions[0]?.diagnosisCode}} {{ card.conditions[0]?.diagnosisText}}</label>
                </div>
                <div class="diagnosis-content">
                    <div class="diagnosis-details">
                        <span>{{'doctor' | translate}} {{ card.practitioner.givenName }} {{ card.practitioner.familyName }}</span>
                    </div>
                    <div class="diagnosis-notes">
                        <small *ngIf="modalMode === 'complaint'">{{ card.additionalData.recommendationForTreatmentNotes }}</small>
                        <small *ngIf="modalMode === 'anamnesis'">{{ card.additionalData.medicalDataAnamnesis }}</small>
                    </div>
                </div>
            </li>
        </ol>
        <div *ngIf="practitionersData?.length === 0 && dataReceived == false" class="text-center">
            <span><i class="fa fa-spinner fa-spin"></i>&nbsp;Kraunasi dokumentai</span><!-- Loading documents -->
        </div>
        <div *ngIf="practitionersData?.length === 0 && dataReceived == true" class="text-center">
            <span>&nbsp;{{ 'cer.025.enc.not.fou' | translate }}</span><!-- Documents loaded -->
        </div>
</div>

<div class="modal-footer d-flex justify-content-between">
    <div class="form-check ms-3">
        <input type="checkbox" id="part-medical-modal-select-all" class="form-check-input" [(ngModel)]="selectAll" (change)="toggleSelectAll()" />
        <label for="part-medical-modal-select-all" class="form-check-label">Pasirinkti Visus</label>
    </div>
    <div class="d-flex gap-3">
        <button type="button" class="btn btn-primary" (click)="includeSelectedComplaints()">+ Įterpti pasirinktus</button>
        <button type="button" class="btn btn-secondary" (click)="close()">x Atšaukti</button>
    </div>
</div>
