ng1App.factory("CertificatesApiFactory", [
    "$resource",
    "apiUrl",
    function ($resource, apiUrl) {
        var URL_BASE = apiUrl + "/certificates";
        return $resource(
            "",
            {},
            {
                getByComposition: {
                    method: "GET",
                    url: URL_BASE + "/:id",
                },
                getForHistoryPreview: {
                    method: "GET",
                    url: URL_BASE + "/:compositionId/history/:historyId",
                },
                getByCompositionForEdit: {
                    method: "GET",
                    url: URL_BASE + "/:id/edit",
                },
                getPrefilledForm: {
                    method: "GET",
                    url: URL_BASE + "/prefillForm",
                },
                saveFinal: {
                    method: "POST",
                    url: URL_BASE + "/saveFinal?type=:certificateType",
                },
                savePartial: {
                    method: "POST",
                    url: URL_BASE + "/savePartial?type=:certificateType",
                },
                saveFinalWithFile: {
                    method: "POST",
                    url: URL_BASE + "/saveFinalWithFile?type=:certificateType",
                },
                search: {
                    method: "GET",
                    url: URL_BASE + "/forList",
                    isArray: false,
                },
                count: {
                    method: "GET",
                    url: URL_BASE + "/count",
                },
                findDeathCertificate: {
                    method: "GET",
                    url: URL_BASE + "/find-death-certificate-to-replace",
                },
                findDeathCertificateList: {
                    method: "GET",
                    url: URL_BASE + "/findDeathCertificatesToReplace",
                },
                searchOrdered: {
                    method: "GET",
                    url: URL_BASE + "/forListOrdered",
                },
                getCountFromChildren: {
                    method: "GET",
                    url: URL_BASE + "/count?childrenDocs=true",
                },
                getByCompostionId: {
                    method: "GET",
                    url: URL_BASE + "/:id",
                },
                getFromRelatedTask: {
                    method: "GET",
                    url: URL_BASE + "/getFromRelatedTask/:taskId",
                },
                getByReferenceId: {
                    method: "GET",
                    url: URL_BASE + "/documentReference/:id/certificate",
                },
                makeFinal: {
                    method: "POST",
                    url: URL_BASE + "/makeFinal",
                },
                cancel: {
                    method: "POST",
                    url: URL_BASE + "/:id/cancelDocument",
                },
                getRelatedDocuments: {
                    method: "GET",
                    url: URL_BASE + "/:id/relatedDocuments",
                    isArray: true,
                },
                orderE027_1: {
                    method: "PUT",
                    url: URL_BASE + "/orderE027-1",
                },
                canE027_1BeOrdered: {
                    method: "GET",
                    url: URL_BASE + "/canE027-1BeOrdered",
                },
                createPublicViewCode: {
                    method: "POST",
                    url: URL_BASE + "/:certificateId/create-public-view-code",
                },
                getPublicViewCode: {
                    method: "GET",
                    url: URL_BASE + "/:certificateId/get-public-view-code",
                },
                initSigning: {
                    method: "GET",
                    url: URL_BASE + "/:id/initSigning?confirmUrl=:confirmUrl",
                },
                confirmSigning: {
                    method: "GET",
                    url: URL_BASE + "/:id/signResult",
                },
                canBeCanceled: {
                    method: "GET",
                    url: URL_BASE + "/:id/canBeCanceled",
                },
            }
        );
    },
]);
