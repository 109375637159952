ng1App.directive('loadCommentsOnToggle', function () {
    return {
        restrict: 'A',
        replace: false,
        link: function (scope: any, element, attrs, ctrl) {
            scope.$on('widget_toggle', function (event, data) {
                if (!data) {
                    //FIXME
                    scope.$$childHead.loadComments();
                }
            });
        }
    }
})
        .directive('addNewComment', 
    ['AlertsApiFactory',
     function (AlertsApiFactory) {
            return {
                templateUrl: '/templates/components/comments/edit_comment.html',
                replace: false,
                restrict: 'E',
                scope: {
                    practitioner: '=',
                    query: '=',
                    comments: '='

                },
                link: function (scope, element, attrs) {

                }
            }
        }])
        .directive('viewLoadedCommentsList', 
    ['AlertsApiFactory',
     function (AlertsApiFactory) {
            return {
                templateUrl: '/templates/components/comments/view_comments_list.html',
                replace: false,
                restrict: 'E',
                scope: {
                    author: '=',
                    comments: '=',
                    areMoreComments: '=',
                    loadMoreComments: '=',
                    newTab: '@'

                },
                link: function (scope: any, element, attrs) {
                    scope.commentsLoaded = true;
                    scope.search = attrs.search ? attrs.search : false;

                    scope.canCancel = function (index) {
                        if (!scope.author) {
                            return false;
                        }

                        var author = scope.author;
                        var com = scope.comments[index];
                        var comAuthor = com.authorPatient ? com.authorPatient : com.practitioner;

                        return author.id && comAuthor && comAuthor.id == author.id && com.active;
                    };


                    scope.cancelComment = function (index) {
                        var query = {
                            alertId: scope.comments[index].id
                        };
                        var callback = function () {
                            scope.comments[index].active = false;
                        };
                        AlertsApiFactory.cancelComment(query, {}, callback);
                    };
                    if (typeof scope.areMoreComments !== 'undefined') {
                        //TODO fix misspell
                        scope.isMoreComments = scope.areMoreComments;
                    } else {
                        scope.isMoreComments = function () {
                            return false;
                        };
                    }
                }
            }
        }])