ng1App.controller('doctor.user.UnfinishedRecordsCtrl', 
    ['$scope', '$location', '$routeParams', '$modalConfirm', 'SessionContext', 'MessageService',
     function ($scope, $location, $routeParams, $modalConfirm, SessionContext, MessageService) {
    $scope.$emit('setHelpPage', 'perzireti-specialisto-nebaigtu-dokumentu-sarasa');
    $scope.getOrganizationId = function () {
        return SessionContext.organization.id;
    };

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    var sgnst = $routeParams['sgnst'];
    if (sgnst) {
        if (sgnst == 'ok') {
            MessageService.add('success', 'Dokumentas pasirašytas sėkmingai');
        } else if (sgnst == 'canceled') {
            MessageService.add('warning', 'Dokumento pasirašymas atšauktas');
        } else if (sgnst == 'fail') {
            var errorid = $routeParams['msgid'];
            MessageService.add('error', 'Nepavyko pasirašyti dokumento. Klaidos ID: ' + errorid);
        }
    }

    $scope.onResult = function (data) {
        var status = ['CANCELED_UNSIGNED', 'FINAL_UNSIGNED'];
        angular.forEach(data, function (item) {
            if (item.type === 'order' || !item.resource.additionalData.canSign || !_.contains(status, item.resource.documentStatus)) {
                $scope.canSignAll = false;
            } else if (item.type !== 'order' && item.resource.additionalData.canSign && _.contains(status, item.resource.documentStatus)) {                
                $scope.documentIds.push(item.resource.id);
            }
        });
    };

    $scope.onSearch = function () {
        $scope.canSignAll = true;
        $scope.documentIds = [];
    };

    $scope.batchSign = function () {
        $modalConfirm.show({content: 'Ar tikrai norite pasirašyti ' + $scope.documentIds.length + ' dokumentus?'}).then(function () {
            $location.path('/dp/documents/sign').search({documentId: $scope.documentIds, multiSign: true});
        });
    };
}]);