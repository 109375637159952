import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ApiV2Templates} from "../../../../api/api-v2-templates";
import {TemplateModel} from "../../models/template.model";

@Component({
    selector: 'template-load',
    templateUrl: 'template-load.component.html'
})
export class TemplateLoadComponent implements OnInit {
    createDialogErr = {msg: null};
    @Input() templates: TemplateModel[];
    @Input() templatesCount: number;
    @Input() templatesTotal: number;
    @Output() selectedTemplate = new EventEmitter<TemplateModel>();
    quickSearchName: string = "templateLoadQuickSearch";
    data: { template: number, searchText: string } = {template: null, searchText: ""};
    form: FormGroup;
    from: number = 0;

    constructor(private formBuilder: FormBuilder, private router: Router, private apiV2Templates: ApiV2Templates) {
        this.form = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.form.addControl(this.quickSearchName, new FormControl(""));
    }

    filterTemplates(count?: number, from?: number) {
        const text: string = this.form.controls[this.quickSearchName].value ? this.form.controls[this.quickSearchName].value : "";
        const query: Object = {
            count: count ? count : this.templatesCount,
            from: from ? from : this.from,
            text: text
        };

        this.apiV2Templates.findForList(query)
            .subscribe(data => {
                this.templates = data.items;
                this.templatesTotal = data.total;
            });
    }

    onTemplateSelect(template: TemplateModel) {
        this.data.template = template.id;
        this.selectedTemplate.emit(template);
    }

    moreToLoad(): boolean {
        return this.templates.length < this.templatesTotal;
    };

    loadMore() {
        this.filterTemplates(this.templatesCount + 10);
    }

    notEmpty(): boolean {
        return this.templates?.length !== 0;
    }
}