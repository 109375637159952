<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    widgetClass="warning"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item mouse-pointer" *ngFor="let allergy of allergies"
        (click)="showDocument(allergy.referencedDocumentId)"
        (keypress)="showDocument(allergy.referencedDocumentId)"
        tabindex="0">
        <p class="mb-0">
            <small class="text-secondary">
                {{ moment(allergy.date).format("YYYY-MM-DD") }}
            </small>
        </p>
        <span *ngIf="allergy.allergen" class="fw-bold mb-0">
            {{ allergy.allergen?.display }}
        </span>
        <div class="mb-0">
            <div class="text-truncate">
                {{ allergy.notes }}
            </div>
        </div>
    </li>
    <li *ngIf="!loading" class="list-group-item p-0">
        <a [href]="getUrl()"
           class="d-block text-decoration-none hover text-center py-2 px-2_5">
            {{ 'show.more' | translate }}
        </a>
    </li>
</nhr-record-widget>