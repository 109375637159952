angular.module('medvais.components').service('$medvaisCancelDocMdl', ["$modal", "$rootScope", "$q", "$filter", function (
    $modal,
    $rootScope,
    $q,
    $filter) {

    var scope = $rootScope.$new();
    var deferred;

    scope.data = {};
    // scope.data.errorMsg = '';
    scope.data.reasonText = '';
    scope.data.cancelErr = 0;


    scope.close = function() {
        deferred.resolve();
        modal.hide();
    };

    scope.doCancel = function() {
        if (scope.data.reasonText != null && scope.data.reasonText != '') {
            deferred.resolve(scope.data.reasonText);
            modal.hide();
        } else {
            scope.data.cancelErr = 1;
        }
    };

    var modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-cancel-doc.html',
        title: $filter('translate')('medvais.cancel.doc.mdl.title'),
        scope: scope,
        show: false
    });
    var parentShow = modal.show;

    modal.show = function() {
        scope.data.reasonText = '';
        scope.data.cancelErr = 0;

        parentShow();
        deferred = $q.defer();
        return deferred.promise;
    };
    return modal;

}]);