import {Component, Input, OnInit} from '@angular/core';
import {ApiV2DocRefConsent} from "../../../../../api/api-v2-doc-ref-consent";
import {DocRefConsentModel} from "../../../models/doc-ref-consent.model";
import {SpanModel} from "../../../models/span.model";
import {OrganizationModel} from "../../../models/organization.model";
import {AgreementModel} from "../../../models/agreement.model";
import {DocRefConsentService} from "../../../services/doc-ref-consent.service";
import {PortalTypeEnum} from "../../../models/portalType.enum";
import {DocRefConsentDocStatusEnum} from "../../../models/doc-ref-consent-docStatus.enum";
import {RouteParams} from "../../../../ng1.routeParams";
import {Router} from "@angular/router";

@Component({
    selector: 'doc-ref-consent-view',
    templateUrl: './doc-ref-consent-view.component.html'
})
export class DocRefConsentViewComponent implements OnInit {

    backPath: string;
    @Input() docRefConsent: DocRefConsentModel;
    @Input() patientOrganizationSpan: SpanModel[];
    @Input() consentOrganizationSpan: SpanModel[];
    @Input() agreementSpan: SpanModel[];
    @Input() badgeColor: string;
    @Input() badgeTitle: string;
    @Input() dateBetween: string;
    @Input() agreementInfo: string;
    @Input() docStatusDescription: string;
    @Input() practitionerFullName: string;
    @Input() portalType: PortalTypeEnum;

    constructor(
        private apiV2DocRefConsent: ApiV2DocRefConsent,
        private docRefConsentService: DocRefConsentService,
        private router: Router,
        private params: RouteParams
    ) {
    }

    ngOnInit(): void {
        this.backPath = this.portalType + '/patients/' + this.params.patientId + '/doc-ref-consent/list';
        const id = this.params.id;
        this.loadDocRefConsent(id);
    }

    loadDocRefConsent(id: string) {
        return this.apiV2DocRefConsent.getDocRefConsentView(id).subscribe((data: DocRefConsentModel) => {
            this.docRefConsent = data;
            this.initializeData();
        });
    }

    initializeData() {
        this.badgeColor = this.docRefConsentService.getBadgeColor(this.docRefConsent.docStatus.code);
        this.badgeTitle = this.docRefConsentService.getDocStatus(this.docRefConsent.docStatus.code);
        this.dateBetween = this.docRefConsentService.getDateBetween(this.docRefConsent.created, this.docRefConsent.signed);
        this.agreementInfo = this.docRefConsentService.getAgreementInfo(this.docRefConsent.patient, this.docRefConsent.docStatus.code, this.docRefConsent.date);
        this.docStatusDescription = this.docRefConsentService.getDocStatusDescription(this.docRefConsent.docStatus.code);
        this.practitionerFullName = this.docRefConsentService.getPractitioner(this.docRefConsent.practitioner);

        let patientOrganization: OrganizationModel = this.docRefConsent.practitioner.practitionerOrganization;
        this.patientOrganizationSpan = [
            {label: "comp.docRefConsent.view.organization.title", text: patientOrganization.name},
            {label: "comp.docRefConsent.view.organization.code", text: patientOrganization.jarCode},
            {label: "comp.docRefConsent.view.organization.address", text: patientOrganization.activityAddress.text},
            {label: "comp.docRefConsent.view.organization.phone", text: patientOrganization.mobilePhone}
        ];

        let consentOrganization: OrganizationModel = this.docRefConsent.consentOrganization;
        this.consentOrganizationSpan = [
            {label: "comp.docRefConsent.view.organization.title", text: consentOrganization.name},
            {label: "comp.docRefConsent.view.organization.code", text: consentOrganization.jarCode},
            {label: "comp.docRefConsent.view.organization.address", text: consentOrganization.activityAddress.text},
            {label: "comp.docRefConsent.view.organization.phone", text: consentOrganization.mobilePhone}
        ];

        let agreement: AgreementModel = this.docRefConsent.agreement;
        this.agreementSpan = [
            {label: "comp.docRefConsent.view.agreement.init", text: this.getInitiator()},
            {label: "comp.docRefConsent.view.agreement.from", text: agreement.start},
            {label: "comp.docRefConsent.view.agreement.to", text: agreement.end},
            {label: "comp.docRefConsent.view.agreement.category", text: agreement.category}
        ];
    }

    getInitiator(): string {
        return this.practitionerFullName + ", " + this.docRefConsent.practitioner.speciality.name;
    }

    canSign(): boolean {
        const docStatus: DocRefConsentDocStatusEnum = DocRefConsentDocStatusEnum[this.docRefConsent.docStatus.code];
        return docStatus === DocRefConsentDocStatusEnum.UNSIGNED && this.portalType === PortalTypeEnum.PP;
    }

    canCancel(): boolean {
        const docStatus: DocRefConsentDocStatusEnum = DocRefConsentDocStatusEnum[this.docRefConsent.docStatus.code];
        return docStatus === DocRefConsentDocStatusEnum.UNSIGNED || (this.portalType === PortalTypeEnum.PP && docStatus !== DocRefConsentDocStatusEnum.CANCELED);
    }

    onCancel() {
        this.apiV2DocRefConsent.changeDocRefConsentStatus(this.docRefConsent.id, DocRefConsentDocStatusEnum.CANCELED)
            .subscribe(data => {
                this.docRefConsent = data;
                this.initializeData();
            });
    }

    onSign() {
        this.apiV2DocRefConsent.changeDocRefConsentStatus(this.docRefConsent.id, DocRefConsentDocStatusEnum.VALID)
            .subscribe(data => {
                this.docRefConsent = data;
                this.initializeData();
            });
    }

    goBack() {
        this.router.navigate([this.backPath]);
    }
}