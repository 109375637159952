import { CropBGSTest } from "../components/procedures-during-pregnancy/procedures-during-pregnancy.constants";

export const proceduresDuringPregnancyDataMock = {
    id: "12345679781",
    researches: [
        {
            name: "Kraujo tyrimai",
            date: "2023-11-08T15:21:04.000Z",
            pregnantBloodType: "A (II) -",
            fatherBloodGroup: "AB (IV) -",
        },
        {
            name: "Kraujo tyrimai",
            date: "2023-11-08T15:21:04.000Z",
            pregnantBloodType: "A (II) -",
            fatherBloodGroup: "AB (IV) -",
        },
        {
            name: "Kraujo tyrimai",
            date: "2023-11-08T15:21:04.000Z",
            pregnantBloodType: "A (II) -",
            fatherBloodGroup: "AB (IV) -",
        },
        {
            name: "Antikūnų tyrimai",
            date: "2023-11-08T15:21:04.000Z",
            antibodyTestResult: true,
            antibodyTiter: "4",
            antibodyTiter2: "6",
        },
        {
            name: "Kraujo tyrimas",
            date: "2023-11-08T15:21:04.000Z",
            bloodErythrocytes: "12",
            bloodHemoglobin: "23",
            bloodHematocrit: "43",
            bloodLeukocytes: "45",
            bloodPlatelets: "56",
        },
        {
            name: "Tyrimas dėl sifilio (RPR)",
            date: "2023-11-08T15:21:04.000Z",
            RPRTestResult: true,
            RPRStudyTiter: "34",
        },
        {
            name: "Tyrimas dėl ŽIV",
            date: "2023-11-08T15:21:04.000Z",
            HIVTestResult: "Taip",
        },
        {
            name: "Gliukozės kraujo plazmoje tyrimas",
            date: "2023-11-08T15:21:04.000Z",
            bloodPlasmaGlucoseTestResult: "56",
        },
        {
            name: "Tyrimas dėl hepatito B",
            date: "2023-11-08T15:21:04.000Z",
            hepatitisBResult: true,
        },
        {
            name: "Tyrimas dėl hepatito B",
            date: "2023-11-08T15:21:04.000Z",
            hepatitisBResult: true,
        },
        {
            name: "Šlapimo pasėlio tyrimas dėl besimptomės bakteriurijos",
            date: "2023-11-08T15:21:04.000Z",
            asymptomaticBacteriuriaResult: "Bakterijų ≥ 105 KVF/ml",
        },
        {
            name: "Gliukozės toleravimo mėginys (GTM)",
            date: "2023-11-08T15:21:04.000Z",
            GTMResult: true,
            GTMI: "2",
            GTMII: "34",
            GTMIII: "45",
        },
        {
            name: "Pasėlio tyrimas dėl naujagimių B grupės streptokoko (BGS) infekcijos rizikos",
            date: "2023-11-08T15:21:04.000Z",
            cropBGSTest: CropBGSTest.NotInvestigated,
            cropBGSTestResults: "Gimdymo metu yra rizika",
        },
        {
            name: "Šlapimo tyrimas",
            date: "2023-11-08T15:21:04.000Z",
            urineProtein: "2",
            urineLeukocytes: "3",
            urineErythrocytes: "4",
            urineGlucose: "5",
            urineKetones: "6",
            urinaryNitrites: "7",
        },
    ],
    procedures: [
        {
            name: "Anti-D imunoglobulinas",
            date: "2023-11-08T15:11:19.000Z",
            injectedAntiDImmunoglobulin: true,
        },
        {
            name: "Anti-D imunoglobulinas",
            date: "2023-11-08T15:11:19.000Z",
            injectedAntiDImmunoglobulin: true,
        },
        {
            name: "Prenatalinė chromosomų anomalijų patikra",
            date: "2023-11-08T15:11:19.000Z",
            prenatalScreeningResult: "Trigubas testas",
        },
        {
            name: "Prenatalinė diagnostika",
            date: "2023-11-08T15:11:19.000Z",
            amniocentesisDiagnosisDate: "2023-11-08T15:11:19.000Z",
            cordocentesisDiagnosisDate: "2023-11-08T15:11:19.000Z",
        },
        {
            name: "Vaisiaus chirurginės procedūros",
            standardFetalSurgicalProcedure: "Kraujo transfuzija",
            otherFetalSurgicalProcedure: "DSZADSsdf",
        },
        {
            name: "Vaisiaus plaučių brandinimas",
            fetalLungMaturation: true,
            pregnancyWeek: "33",
        },
        {
            name: "Tokolizė nėštumo metu",
            tocolysisDuringPregnancy: true,
        },
        {
            name: "Kita svarbi informacija",
            otherInfo: "sdfwerwer vsdfsdf",
        },
    ],
};
