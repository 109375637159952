import {Component, OnInit, Input} from '@angular/core';
import {ApiV2Links} from '../../../../api/api-v2-links';
import {ApiV2Auth} from "../../../../api/api-v2-auth";
import {EnvironmentSettings} from "../../../../environments/env";
import {DpSessionContext} from "../../services/dp-session-context.service";
import {QueryModel} from "../../../shared/models/query.model";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {ApiV2Alerts} from "../../../../api/api-v2-alerts";
import {MessagesTypesEnum} from "../../../shared/models/messages-types.enum";
import {ApiV2Tasks} from "../../../../api/api-v2-tasks";
import {DoctorUserModel} from "../../../shared/models/doctor-user.model";
import {PractitionerModel} from "../../../shared/models/practitioner.model";
import {ApiV2AccountSettings} from "../../../../api/api-v2-account-settings";
import {ApiV2Patients} from "../../../shared/services/api-v2-patients";
import {FilteredDataModel} from "../../../shared/models/filtered-data.model";
import {PatientModel} from "../../../shared/models/patient.model";
import {$locationShim} from "@angular/common/upgrade";

@Component({
    selector: 'dp-navigation',
    templateUrl: 'dp-navigation.component.html'
})
export class DpNavigationComponent implements OnInit {
    @Input() helpPage: string;
    @Input() iprUrl: string;
    @Input() ssoUrl: string;
    @Input() token: string;
    @Input() quickSearchName: string = 'quick-search-navbar';
    @Input() practitioner$!: Observable<PractitionerModel>;
    tasksCount: number;
    messagesCount: number;
    currentUser: DoctorUserModel;

    constructor(
        private router: Router,
        private apiV2Links: ApiV2Links,
        private apiV2Auth: ApiV2Auth,
        private apiV2Alerts: ApiV2Alerts,
        private apiV2Tasks: ApiV2Tasks,
        private apiPatients: ApiV2Patients,
        private apiV2AccountSettings: ApiV2AccountSettings,
        private location: $locationShim,
        public dpController: DpSessionContext
    ) {
        this.ssoUrl = EnvironmentSettings.getEnvironment().ssoUrl
    }

    ngOnInit() {
        this.dpController.loadSessionData();

        this.apiV2AccountSettings.get().subscribe(data => {
            this.dpController.setQualification(data.active_qualification);
        });

        this.apiV2Alerts.getUnreadCount().subscribe(data => {
            this.messagesCount = data[MessagesTypesEnum.UNREAD.toLowerCase()];
        });

        this.apiV2Links.getIPR().subscribe(result => {
            this.iprUrl = result.value;
        });

        this.dpController.currentUserData$.subscribe(data => {
            this.currentUser = data;
        });

        this.practitioner$ = this.dpController.practitionerData$;

        this.practitioner$.subscribe(practitioner => {
            if (practitioner) {
                const tasksQuery = {statuses: ['SUBMITTED', 'PENDING'], practitionerId: practitioner.id};
                this.apiV2Tasks.getActiveCount(tasksQuery).subscribe(count => {
                    this.tasksCount = count.value;
                });
            }
        });
    }

    filterPatient(query?: QueryModel, add?: boolean): void {
        this.apiPatients
            .search({
                personalCode: query[this.quickSearchName],
                checkActiveEncounter: true
                // esi: query[this.quickSearchName]
            })
            .subscribe((value: FilteredDataModel<PatientModel>) => {
                if (value.total === 1) {
                    var patient = value.items[0]
                    // this.router.navigate(['/dp/patients', patient.id, !patient.hasActiveEncounter ? "view" : ""],
                    //     {onSameUrlNavigation: "reload"});
                    // this.location.path('/dp/patients/' + patient.id + (!patient.hasActiveEncounter ? "/view" : ""))
                    this.location.path(`/dp/patients/${patient.id}${!patient.hasActiveEncounter ? "/view" : ""}`)
                } else {
                    this.apiPatients
                        .search({
                            esi: query[this.quickSearchName]
                        })
                        .subscribe((value: FilteredDataModel<PatientModel>) => {
                            if (value.total === 1) {
                                var patient = value.items[0]
                                this.location.path(`/dp/patients/${patient.id}${!patient.hasActiveEncounter ? "/view" : ""}`)
                                // this.router.navigate(['/dp/patients', patient.id, !patient.hasActiveEncounter ? "view" : ""], {
                                //     onSameUrlNavigation: "reload"
                                // });
                            } else {
                                this.redirectTo(query);
                            }
                        });
                }
            })
        // this.redirectTo(query);
    }

    onHomeClick() {
        this.router.navigate(['dp']);
    }

    onMessagesClick() {
        this.router.navigate(['dp/messages']);
    }

    onTasksClick() {
        this.router.navigate(['dp/tasks']);
    }

    onQualificationChange(event: any) {
        const qualification: string = event.target.value;
        this.dpController.setQualification(qualification);
        this.apiV2AccountSettings.save({active_qualification: qualification}).subscribe();
    }

    onChangeRoleClick() {
        window.location.replace("espbi-sso/#!/roles");
    }

    doLogout() {
        this.apiV2Auth.doLogout({}).subscribe((data: any) => {
            window.location.href = data.headers('Location');
        });
        return false;
    }

    toggleMainMenu(id: string) {
        document.getElementById(id).focus()
    }

    redirectTo(query: QueryModel) {
        // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate(
            ['dp/patients'], {
                queryParams: {
                    q: query[this.quickSearchName]
                },
                queryParamsHandling: "merge",
                onSameUrlNavigation: "reload"
            }).then(() => {
            window.location.reload();
        });
        // );
    }
}