import {Pipe, PipeTransform} from '@angular/core';
import {ErxPathsService} from "../services/erx-paths.service";
import {Environment} from "../../../environments/environment-properties";
import {EnvironmentSettings} from "../../../environments/env";
import PortalType = Environment.PortalType;

@Pipe({name: 'documentLink'})
export class DocumentLinkPipe implements PipeTransform {

    private readonly portalType: PortalType;

    constructor(private erxPathsService: ErxPathsService) {
        this.portalType = EnvironmentSettings.getPortalType();
    }

    transform(doc, patientId, isCert?): any {
        let part = 'documents/';
        let docId = doc;

        if (_.isObject(doc)) {
            if (doc.docType === 'erec01') {
                return this.erxPathsService.view(doc.additionalData.prescriptionId, doc.additionalData.parentId, patientId);
            }
            if (doc.docType === 'e027va') {
                return '/dp/imaging/study/e027va/' + doc.id;
            }
            docId = doc.id;
        }


        if (isCert || doc.certificate) {
            part = 'certificates/';
        }

        switch (this.portalType) {
            case PortalType.DOCTOR:
                return '/dp/patients/' + patientId + '/' + part + docId;
            case PortalType.PATIENT:
                return '/pp/' + part + docId;
            default:
                console.error('unknown portal type:', this.portalType);
                return 'unknown portal type';
        }
    }
}