import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {ConfigService} from "../app/config/config.service";
import {FilteredDataModel} from "../app/shared/models/filtered-data.model";
import { HttpParams } from '@angular/common/http';
import { PractitionerModel } from 'src/app/shared/models/practitioner.model';

interface PractitionerInterface {
    total: number;
    items: PractitionerModel[];
  }
@Injectable({
    providedIn: 'root'
})
export class ApiV2Practitioners extends ConfigService {
    path = this.apiUrl + '/practitioners';

    find(query?: any): Observable<FilteredDataModel> {
        const url = `${this.path}`;
        return this.http.get<FilteredDataModel>(url, {params: query});
    }

    getGroupedPractitioners(value: string, count: number): Observable<PractitionerModel[]> {
        let params = new HttpParams();

        params = params.append('q', String(value+'*'));
        params = params.append('count: ', count);

        const url = `${this.path}/grouped`;
        return this.http.get<PractitionerInterface>(url, {params}).pipe(map((data: PractitionerInterface) => data && data.items || []));
    }
}