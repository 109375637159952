angular.module('erx.api').factory('ErxDrugInteractionApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
    var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/MedicationPrescription';
    var lastDrugInteractionQueryId;
    return {
        getLastDrugInteractionQueryId: function () {
            return lastDrugInteractionQueryId;
        },
        setLastDrugInteractionQueryId: function (id) {
            lastDrugInteractionQueryId = id;
        },
        getServices: function () {
            return $resource('', {}, {
                getDrugInteractions: {
                  method: 'GET',
                  url: URL_BASE + '/drugInteractions'
                },
            });
        }  
    };
}]);