ng1App.factory('LinkApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/link';
    return $resource('', {}, {
        getIPR: {
            method: 'GET',
            url: URL_BASE + '/ipr',
            isArray: false
        },
        getMenu: {
            method: 'GET',
            url: URL_BASE + '/menu',
            isArray: true
        },
        getFullMenu: {
            method: 'GET',
            url: URL_BASE + '/full-menu',
            isArray: false
        }
    });
}]);