import { getTemplateComponent } from '../../../../../utils/hypridHelper';
import { E01011Component } from './e010-1-1/e010-1-1.component';
import { E01021Component } from './e010-2-1/e010-2-1.component';


ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding e010-1-1 routes");

        $routeSegmentProvider
            .when(`/dp/patients/:patientId/e010-1-1-view`, "DP.DP_PATIENT3L.NGN_E010_1_1_VIEW")
            .when(`/dp/patients/:patientId/e010-2-1-view`, "DP.DP_PATIENT3L.NGN_E010_2_1_VIEW");


        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT3L")
            .segment("NGN_E010_1_1_VIEW", { template: getTemplateComponent(E01011Component) })
            .segment("NGN_E010_2_1_VIEW", { template: getTemplateComponent(E01021Component) });
    },
]);
