angular.module('medvais.reports').directive('medvaisReports', function () {
    return {
        restrict: 'E',
        scope: {options: '='},
        templateUrl: 'modules/medvais/reports/reports.html',
        controller: 'medvaisReportsCtrl'
    };
});

angular.module('medvais.reports').controller('medvaisReportsCtrl', ["$scope", function (
        $scope
) {

    
    
}]);
