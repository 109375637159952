function parseDateString(dateString: string): Date {
    var dateOnlyParts = dateString.split('-');
    var year = dateOnlyParts[0];
    var month = Number(dateOnlyParts[1]) - 1;
    var day = dateOnlyParts[2];

    return new Date(Number(year), month, Number(day), 0, 0, 0);
}

function getCurrentDate() {
    return (new Date()).setHours(0, 0, 0, 0);
}

function getVaccinationStartDate() {
    return (new Date(2020,0,2,0,0,0));
}

ng1App.controller('doctor.patient.VaccineNewRecordsCtrl', 
    ['$scope', '$routeParams', '$window', 'MessageService', 'VaccinationRecordsApiFactory', 'SubFormValidator', 'VaccinationSaveService',
     function ($scope, $routeParams, $window, MessageService, VaccinationRecordsApiFactory, SubFormValidator, VaccinationSaveService) {
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope.patientId = $routeParams.patientId;

    $scope.diseases = {}
    $scope.countries = {}
    $scope.vaccines = {}
    $scope.recconfig = {}
    $scope.antigenList = {}

    $scope.fileDocument = null;
    $scope.maxFileSize = 0;

    $scope.form = {
        doctype: null,
        disease: null,
        recorddate: null,
        vaccine: null,
        serialnumber: null,
        issuingAuthority: null,
        country: null,
        documentdate: null,
        documentnumber: null,
        selectedVaccineDosenumber: null,
        patientid: $scope.patientId,
        antigen: null,
        recttype: null
    };

    $scope.loadMaxFileSize = function() {
        VaccinationRecordsApiFactory.getMaxFileSize({}, function(data) {
            $scope.maxFileSize = data['MaxFileSize']/ 1024 / 1024;
        })
    }
    $scope.loadMaxFileSize();

    $scope.loadDiseaseTypes = function() {
        VaccinationRecordsApiFactory.getDiseaseType({}, function(data) {
            $scope.diseases = data.o_ligos;
            $scope.form.disease = $scope.diseases[0];
        });
    };
    $scope.loadDiseaseTypes();

    $scope.loadAntigenCompList = function() {
        VaccinationRecordsApiFactory.getAntigenCompList({}, function(data) {
            $scope.antigenList = data.o_vakcinos;
        });
    };
    $scope.loadAntigenCompList();

    $scope.loadCountries = function() {
        VaccinationRecordsApiFactory.getCountries({}, function(data) {
            $scope.countries = data.o_salys;
        });
    };
    $scope.loadCountries();

    $scope.loadVaccines = function() {
        VaccinationRecordsApiFactory.getVaccines({}, function(data) {
            $scope.vaccines = data;
        });
    };
    $scope.loadVaccines();

    $scope.loadRecConfig = function() {
        VaccinationRecordsApiFactory.getRecConfig({}, function(data) {
            var recconfigArray = data.o_prk_id;
            var newRecconfigArray = [];
            for (var i = 0; i < recconfigArray.length; i++) {
                if ($scope.form.doctype == 'testfact' && recconfigArray[i].testTypeCategory == "PGR") {
                    newRecconfigArray.push(recconfigArray[i]);
                } else if ($scope.form.doctype == 'antigentest' && recconfigArray[i].testTypeCategory == "Antigeno") {
                    newRecconfigArray.push(recconfigArray[i]);
                }
            }
            $scope.recconfig = newRecconfigArray;
            $scope.form.recttype = $scope.recconfig[0];
        });
    };

    $scope.validator = new SubFormValidator('record-region');
    var validateRecordForm = function (data) {
        MessageService.clearAll();
        $scope.validator.clear();

        var Doctype = data.doctype;
        if (Doctype == null) {
            $scope.validator.add('doctype');
        }
        var Disease = data.disease;
        if (Disease == null) {
            $scope.validator.add('disease');
        }
        var Recorddate = data.recorddate;
        if (Recorddate == null) {
            $scope.validator.add('recorddate');
        }
        var RecorddateMoreThan = data.recorddate;
        if (RecorddateMoreThan != null && parseDateString(RecorddateMoreThan).getTime() > getCurrentDate()) {
            $scope.validator.add('recorddateMoreThan');
        }
        var RecorddateLessThanVaccinationStart = data.recorddate;
        if (RecorddateLessThanVaccinationStart != null && parseDateString(RecorddateLessThanVaccinationStart).getTime() < getVaccinationStartDate().getTime()) {
            $scope.validator.add('recorddateLessThanVaccinationStart');
        }
        var Vaccine = data.vaccine;
        if (Doctype == null && Vaccine == null) {
            $scope.validator.add('vaccine');
        }
        var Dosenumber = data.selectedVaccineDosenumber;
        if (Doctype == null && Dosenumber == null) {
            $scope.validator.add('dosenumber');
        }
        var Recttype = data.recttype;
        if (!Doctype && Recttype == null) {
            $scope.validator.add('recttype');
        }
        var Antigen = data.antigen;
        if (Doctype == 'antigentest' && Antigen == null) {
            $scope.validator.add('antigen');
        }
        var issuingauthority = data.issuingAuthority;
        if (issuingauthority == null || issuingauthority.length == 0) {
            $scope.validator.add('issuingAuthority');
        }
        var Country = data.country;
        if (Country == null) {
            $scope.validator.add('country');
        }
        var Documentdate = data.documentdate;
        if (Documentdate == null) {
            $scope.validator.add('documentdate');
        }
        var DocumentdateMoreThan = data.documentdate;
        if (DocumentdateMoreThan != null && parseDateString(DocumentdateMoreThan).getTime() > getCurrentDate()) {
            $scope.validator.add('documentdateMoreThan');
        }
        var DocumentdateLessThan = data.documentdate;
        if (DocumentdateLessThan != null && Recorddate != null && parseDateString(DocumentdateLessThan).getTime() < parseDateString(Recorddate).getTime()) {
            $scope.validator.add('documentdateLessThan');
        }
        var DocumentdateLessThanVaccinationStart = data.documentdate;
        if (DocumentdateLessThanVaccinationStart != null && parseDateString(DocumentdateLessThanVaccinationStart).getTime() < getVaccinationStartDate().getTime()) {
            $scope.validator.add('documentdateLessThanVaccinationStart');
        }
        var Docfile = $scope.fileDocument;
        if (Docfile == null) {
            $scope.validator.add('docfile');
        } else if (Docfile.size/ 1024 / 1024 > $scope.maxFileSize) {
            $scope.validator.add('docfileSize');
        } else if (Docfile.type != "image/gif" && Docfile.type != "image/png" && Docfile.type != "image/bmp" && Docfile.type != "image/jpeg" && Docfile.type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && Docfile.type != "application/msword" && Docfile.type != "application/pdf") {
            $scope.validator.add('docfileType');
        } else if (Docfile.size == 0 ) {
            $scope.validator.add('minDocfileSize');
        }
        return !$scope.validator.hasErrors();
    };

    $scope.saveNewRecord = function() {
        if (validateRecordForm($scope.form)) {
            MessageService.registerSuccessEvent();
            $scope.button={};
            $scope.button.disabled=true;
            VaccinationSaveService.saveRecord($scope.form, $scope.fileDocument, function (data) {
                $scope.goBack();
            },function (error) {
                angular.forEach(error.globalErrors, function (e) {
                    MessageService.error(e.msg);
                });
                $window.scrollTo(0, 10);
            });
        }
    };

    $scope.goBack = function () {
        window.history.back();
    };

    $scope.onFileSelect = function (file) {
        if (file) {
            $scope.fileDocument = file;
        }
    };

    $scope.onFileDelete = function () {
        $scope.fileDocument = null;
    };

    $scope.vaccineChanged = function (vaccine) {
        var vaccinesList = [];
        for(var i = 0; i < $scope.vaccines.o_vakcinos.length; i++) {
            if (vaccine.vaccineCode == $scope.vaccines.o_vakcinos[i].vaccineCode) {
                vaccinesList.push($scope.vaccines.o_vakcinos[i]);
            }
        }
        $scope.newVaccinesList = vaccinesList;
    };
}]);