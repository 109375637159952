<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Nt.formMaternity.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row max-auto req">
            <div class="form-group col-6 col-lg-3">
                    <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold"
                    >{{ 'doc.pregnancyDashboard.e096Nt.formMaternity.childbirth' | translate }}:
                    </span>
                <app-radio-button
                    *ngFor="let option of answersVariants?.['planningChildbirth']; let i = index"
                    radioName="planningChildbirth-{{i}}"
                    [fControl]="formGroup.get('childbirth')"
                    [radioValue]="option.name"
                    id="planningChildbirth-{{i}}"
                    class="col-auto my-2"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
                <app-form-control-validation [fControl]="formGroup.get('childbirth')" />
            </div>
            <div class="form-group col-6 col-lg-3">
                    <span
                        class="form-label flex-grow-1 mt-3 fw-bold col-form-label"
                        [class.norq]="!controlRequired('deliveryType')"
                    >
                        {{'doc.pregnancyDashboard.e096Nt.formMaternity.deliveryType' | translate}}:
                    </span>
                <app-radio-button
                    *ngFor="let option of answersVariants?.['modeOfDelivery']; let i = index"
                    radioName="deliveryType-{{i}}"
                    [fControl]="formGroup.get('deliveryType')"
                    [radioValue]="option.name"
                    id="deliveryType-{{i}}"
                    class="col-auto my-2"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
                <app-form-control-validation [fControl]="formGroup.get('deliveryType')" />
            </div>
            <div class="form-group col-auto col-lg-6 flex-shrink-1">
                <div>
                    <app-select
                        labelText="doc.pregnancyDashboard.e096Nt.formMaternity.cesareanSectionIndication"
                        [labelClass]="!controlRequired('cesareanSectionIndications') ? 'norq' : ''"
                        id="cesareanSectionIndications"
                        [fControl]="formGroup.get('cesareanSectionIndications')"
                        [values]="selectionValuesFromEntity(cesareanSectionIndicationsOptions)"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('cesareanSectionIndications')" />
                </div>
                <div>
                    <app-select
                        id="cesareanSectionIndications2"
                        class="mt-3 d-block"
                        [fControl]="formGroup.get('cesareanSectionIndicationsValue')"
                        [values]="cesareanSectionIndicationsValues"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('cesareanSectionIndicationsValue')" />
                </div>
                <div>
                    <app-input
                        type="textarea"
                        labelText="{{'doc.pregnancyDashboard.e096Nt.formMaternity.pregnancyDisease' | translate}}:"
                        [labelClass]="!controlRequired('pregnancyDisease') ? 'norq' : ''"
                        [fControl]="formGroup.get('pregnancyDisease')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('pregnancyDisease')" />
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>