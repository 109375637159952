import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {AbstractControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { EntityModel } from "../../../models/entity.model";

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
})
export class RadioButtonComponent implements OnInit, OnDestroy {
  @Output() radioChange = new EventEmitter();

  @Input({ required: true }) id: string;
    @Input() fControl: AbstractControl;
  @Input() radioValue: string | EntityModel;
  @Input() radioName: string;
  @Input() radioTitle: string= "";
  @Input() labelClass: string;
  @Input() radioChecked: boolean;
  @Input() radioDisabled: boolean;
  @Input() labelText: string;
  @Input() labelId: string;
  @Input() radioClass: string;
  @Input() formClass: string;
  @Input() inputClass: string;
  @Input() addUniqId: boolean;

  private readonly _subscription = new Subscription();
  private _uniqId = _.uniqueId();

  get uniqId(): string {
    return this.addUniqId ? `_${this._uniqId}` : '';
  }

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.fControl) {
      this._subscription.add(this.fControl.valueChanges.subscribe(() => this._cdr.markForCheck()));
    }
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
