angular.module('erx.prs')
        .directive('erxRelatedPrescriptions', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/related_docs/relatedPrescriptions.html',
            restrict: 'E',
            scope: {
              prsId: '=',
              redirectToViewPrsDoc: '='
            },
            controller: ["$scope", "ErxDispenseApiFactory", "gettextCatalog", function ($scope, ErxDispenseApiFactory, gettextCatalog) {
              $scope.getPrDocStatusValue = function (prs) {
                switch (prs.prescriptionData.status.code) {
                  case 'active':
                    return gettextCatalog.getString('GALIOJA');
                  case 'on hold':
                    if (prs.prescriptionData.status.reasonCode === 'for review')
                      return gettextCatalog.getString('SUSTABDYTAS');
                    else if (prs.prescriptionData.status.reasonCode === 'reserved')
                      return gettextCatalog.getString('REZERVUOTAS');
                    break;
                  case 'completed':
                    return gettextCatalog.getString('ĮSIGYTAS');
                  case 'entered in error':
                    return gettextCatalog.getString('ATŠAUKTAS');
                  case 'stopped':
                    return gettextCatalog.getString('NEGALIOJANTIS');
                  default:
                    return 'FIX ME';
                }
              }

              $scope.getStatusColorClass = function (status) {
                switch (status) {
                  case 'stopped':
                  case 'on hold':
                  case 'entered in error':
                    return 'text-danger';
                  case 'completed':
                  case 'active':
                    return 'text-success';
                  default:
                    return '';
                }
                return "text-danger"
              }


//              <b class="{{getStatusColorClass(d.dispenseData.status.code)}}">{{getDspStatusValue(d)}}</b>

              $scope.documents = ErxDispenseApiFactory.getRelated({id: $scope.prsId});
            }],
            replace: false
          };
        });

