ng1App.controller('EditAddressCtrl', 
    ['$scope', '$routeParams', '$window', 'MessageService', 'AddressesApiFactory',
     function ($scope, $routeParams, $window, MessageService, AddressesApiFactory) {
    var callbackUrl = $routeParams['callback-url'];
    var hastFirstPart = false;
    MessageService.clear('address-region');

    $scope.data = {};

    if ($routeParams.aobId) {
        $scope.data.address = {aobId: $routeParams.aobId};
    }

    if (!callbackUrl) {
        MessageService.error('Nenurodytas "callback-url" parametras.', 'address-region');
    }

    var toQueryString = function (address) {
        var queryParts = [];
        _.each(address, function (val, key) {
            if (val) {
                queryParts.push(key + '=' + encodeURIComponent(val));
            }
        });
        return queryParts.join('&');
    };

    var sendAddress = function (address) {
        $window.location.href = callbackUrl + '?' + toQueryString(address);
    };

    $scope.submitable = function () {
        return callbackUrl && hastFirstPart;
    };

    $scope.onChange = function () {
        hastFirstPart = $scope.data.address && $scope.data.address.settlementId;
    };

    $scope.submitAddress = function (address) {
        MessageService.clear('address-region');
        $scope.validateAddress(function () {
            if ($scope.addressForm.$valid && address && address.aobId) {
                var a = {
                    aobId: address.aobId,
                    municipalityId: address.municipalityId,
                    settlementId: address.settlementId,
                    streetId: address.streetId,
                    houseNo: address.houseNo,
                    hullNo: address.hullNo,
                    flatNo: address.flatNo,
                    text: address.text,
                    districtId: address.districtId
                };
                sendAddress(a);
            } else {
                MessageService.error('Klaidingas adresas.', 'address-region');
            }
        });
    };

    $scope.getValidate = function (func) {
        $scope.validateAddress = func;
    };

}]);