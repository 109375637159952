<!--Įrašai-->
<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true" [title]="titleName">
    <ol class="slats hover">
        <li *ngFor="let filter of filters"
            [ngClass]="{'last': $last, 'active': currentFilter.title == filter.title, 'disabled': (!filter.count || filter.count == 0) && currentFilter.title != filter.title}">
            <button *ngIf="currentFilter.title != filter.title" class="d-block text-decoration-none" (click)="!loading && changeFilter(filter)" role="link"
                [ngClass]="{'disabled-link': (!filter.count || filter.count == 0 || loading) && !filter.noCount}">
                {{filter.title | translate}} &nbsp;
                <span *ngIf="filter.count > 0" class="badge rounded-pill text-bg-light">{{filter.count}}</span>
                <span *ngIf="(!filter.count || filter.count == 0) && filter.noCount" class="small text-muted"></span>
                <!-- <span *ngIf="(!filter.count || filter.count == 0) && !filter.noCount" class="small text-muted">0</span> -->
            </button>
            <span class="slats-menu-item" *ngIf="currentFilter.title == filter.title">
                <span>{{filter.title | translate}}</span>&nbsp;
            	<span *ngIf="filter.count > 0" class="badge rounded-pill text-bg-success text-white">{{filter.count}}</span>
            	<span *ngIf="(!filter.count || filter.count == 0) && filter.noCount" class="small text-muted"></span>
                <!-- <span *ngIf="(!filter.count || filter.count == 0) && !filter.noCount" class="small text-muted">0</span> -->
            </span>
        </li>
    </ol>
</nhr-record-widget>