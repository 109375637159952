angular.module('erx.prs').directive('erxPrintMedicines', ["$window", function ($window) {
  var printPrescriptionsCtrl = function ($scope, ErxPrescriptionsApiFactory, ErxDispenseApiFactory, $erxHelpers) {
    $scope.h = $erxHelpers;
    // Skyrimų gavimas pagal kompozicijos ID
    $scope.getMedPrescriptions = function () {
      $scope.noRecPromise = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
              {
                namedQuery: 'mp-med-being-taken-no-doc',
                patientId: $scope.options.getPatientId()
              }, function (res) {
        $scope.noRec = res.result;
      });

      $scope.recNotOwnedPromise = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
              {
                namedQuery: 'mp-med-being-taken-doc',
                patientId: $scope.options.getPatientId()
              }, function (res) {
        $scope.recNotOwned = res.result;
      });

      $scope.recOwnedPromise = ErxDispenseApiFactory.getDocuments(
              {
                namedQuery: 'md-med-being-taken',
                patientId: $scope.options.getPatientId()
              }, function (res) {
        $scope.recOwned = res.result;
      });
    };



    $scope.print = function () {
      $window.print();
    }

    $scope.isLoading = function () {
      return !$scope.noRecPromise || !$scope.noRecPromise.$resolved
              || !$scope.recNotOwnedPromise || !$scope.recNotOwnedPromise.$resolved
              || !$scope.recOwnedPromise || !$scope.recOwnedPromise.$resolved;
    }

    $scope.calculateUseUntil = function (doc, type) {

      if (type === 'NO_REC') {
        var ret = new Date(doc.prescriptionData.dateWritten);
        ret.setDate(ret.getDate() + doc.prescriptionData.dispenseData.dispenseExpectedSupplyDuration);
        return ret;
      } else if (type === 'REC_NOT_OWNED') {
        var ret = new Date(doc.prescriptionData.dateWritten);
        ret.setDate(ret.getDate() + doc.prescriptionData.dispenseData.dispenseExpectedSupplyDuration);
        return ret;
      } else if (type === 'REC_OWNED') {
        return doc.dispenseData.dispense.dueDate;
      }
    }

    $scope.getMedPrescriptions();
  };
  printPrescriptionsCtrl.$inject = ["$scope", "ErxPrescriptionsApiFactory", "ErxDispenseApiFactory", "$erxHelpers"];
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/erx/patient-history/print-medicines/print_medicines.html',
    controller: printPrescriptionsCtrl
  }
}]);

