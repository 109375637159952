ng1App.controller('doctor.patient.CertificatesNewCtrl',
	['$scope', '$rootScope', '$routeParams', 'CertificatesApiFactory', 'CertificateService',
		'$window', '$location', 'DocumentSpinnerHelper', 'MessageService', '$filter', '$modal',
		'$log', 'CertificatesNewService', 'DraftsApiFactory', 'SessionContext',
		'PractitionerQualificationService',
	function ($scope, $rootScope, $routeParams, CertificatesApiFactory, CertificateService,
			  $window, $location, DocumentSpinnerHelper, MessageService, $filter, $modal,
			  $log, CertificatesNewService, DraftsApiFactory, SessionContext,
			  PractitionerQualificationService) {
	MessageService.clear();
	DocumentSpinnerHelper.reset();

    //2020-04-17 AS modified
    $scope._removed = {};
    $scope._modified = {};
	
    $scope.documentCancelReason = {};
    $scope.cancelErr = 0;
    
    var modalCancel = $modal({
        scope: $scope,
        title: $filter('translate')('mdl.add.rea.doc'),
        contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel.html'
    });

    SessionContext.practitioner.$promise.then(function (practitioner) {
			PractitionerQualificationService.checkValidOrEquivalentExists(practitioner);
    });
    
	if ($routeParams.draftId) {
	    $scope.certificate = DraftsApiFactory.getDraftForEdit({id: $routeParams.draftId}, function(data){
			$scope.certificateType = data.type;
			$scope.draftId = $routeParams.draftId;
			$scope.certificate.draftId = $routeParams.draftId;
			$scope.initialSave = true;
			CertificateService.populateCommonData($scope, data);
			CertificatesNewService.fillDataByType($scope, data.type, $routeParams.patientId, $routeParams.id);
	      },
	      function(err){
	          if (err.status == 500) {
	              MessageService.handleError(err.data, MessageService);
	          } else if (err.status == 400) {
	              _.each(err.data.globalErrors, function (i) {
	                  MessageService.error(i.msg == 'incorrect_document_status' ? 'Bloga dokumento būsena' : 'incorrect_document_status');
	              });
	          }
	      }
	    );
	} else if ($routeParams.id) {
		$scope.certificate = CertificatesApiFactory.getByCompositionForEdit({
	        id: $routeParams.id
	    }, function (data) {
			$scope.certificateType = data.type;
			CertificateService.populateCommonData($scope, data);
			CertificatesNewService.fillDataByType($scope, data.type, $routeParams.patientId, $routeParams.id);
	    });
	} else {
		// Get prefill data
	    var params = angular.copy($routeParams);
		$scope.certificate = CertificatesApiFactory.getPrefilledForm(params,
			function (data) {
				$scope.initialSave = true;
				$scope.certificateType =  $routeParams.certificateType;
				CertificateService.populateCommonData($scope, data);
				CertificatesNewService.fillDataByType($scope, $routeParams.certificateType, $routeParams.patientId, $routeParams.id);
			});
	}
	
    $scope.goBack = function () {
        if(!$rootScope.restrictedMode) {
//    	$window.history.back();
            // For now return to records
            $location.path('/dp/patients/' + $routeParams.patientId + '/records').search({});
        } else {
            $location.path('/dp/patients/' + $routeParams.patientId + '/certificates').search({});
        }
    };

}]);

ng1App.service('CertificatesNewService', 
    ['$log',
     function($log){
	return {
		fillDataByType: function (scope, type, patientId, id) {
			scope.templateUrl = '/templates/portal/doctor/patient/certificates/' + type + "/edit_" + type + '.html';
		}
	};
}]);