ng1App.controller('doctor.DocumentsSignCtrl', 
    ['$scope', '$window', '$routeParams', '$sce', 'DocumentsApiFactory', 'MessageService', 'IntegratedComponentContext',
     function ($scope, $window, $routeParams, $sce, DocumentsApiFactory, MessageService, IntegratedComponentContext) {
    $scope.$emit('setHelpPage', 'pasirasyti-elektronini-dokumenta');

    var queryParts = [];

    if (!angular.isArray($routeParams.documentId)) {
        queryParts.push('documentId=' + $routeParams.documentId);
    }

    if ($routeParams.patientId) {
        queryParts.push('patientId=' + $routeParams.patientId);
        $scope.patientId = $routeParams.patientId;
    }

    if ($routeParams.certificate) {
        queryParts.push('certificate=' + $routeParams.certificate);
    }

    if ($routeParams.multiSign) {
        queryParts.push('multiSign=' + $routeParams.multiSign);
        $scope.multiSign = $routeParams.multiSign;
    }

    if (IntegratedComponentContext.getEncounterId()) {
        queryParts.push('encounterId=' + IntegratedComponentContext.getEncounterId());
    }

    var loc = window.location.href.split('/');
    var confirmUrl = loc[0] + '//' + loc[2] + '/unisign_callback?' + queryParts.join('%26');

    console.log(confirmUrl);

    $scope.initSigning = DocumentsApiFactory.initSigning({
            compositionId: $routeParams.documentId,
            confirmUrl: confirmUrl
        }, function (i) {
            if (i.res == false) {
                $scope.initSignError = true;
                if(i.allreadySigned){
                    MessageService.add('warning', 'Dokumentas jau pasirašytas.');
                } else {
                    MessageService.add('error', 'nepavyko inicijuoti pasirašymo. Klaidos ID: ' + i.msgid);
                }
            } else {
                $window.sessionStorage.setItem('sign_transaction', i.transactionId);
                i.frameUrl = $sce.trustAsResourceUrl(i.signingUrl + '#' + encodeURIComponent(document.location.href));
            }
        },
        function (e) {
            console.log(e);
        });

}]);