ng1App.controller('doctor.patient.F066alkStageViewCtrl', 
    ['$scope', '$modalConfirm', '$filter', '$window', 'MessageService', '$routeParams', 'SveidraApiFactory', 'SveidraF066StageCommons', '$location', 'FormLoaderService',
     function ($scope, $modalConfirm, $filter, $window, MessageService, $routeParams, SveidraApiFactory,
                                                                   SveidraF066StageCommons, $location, FormLoaderService) {
    MessageService.clearAll();
    $scope.$emit('setHelpPage', 'medicinines-apskaitos-dokumento-perzira');

    $scope.goBack = function(){
        $window.history.back();
    };
    
    $scope.patientId = $routeParams.patientId;
    $scope.formId = $routeParams.formId;
    
    FormLoaderService.startFormLoading();
    $scope.stage = SveidraApiFactory.getStage({formId: $scope.formId, id: $routeParams.stageId});
    FormLoaderService.endFormLoading($scope.stage.$promise);
    
    $scope.getForcedTreatment = function () {
    	switch ($scope.stage.forcedTreatment) {
    	case 'YES':
    		return 'Yes';
    	case 'NO':
    		return 'No';
    	case 'UNKNOWN':
    		return 'Unknow';
    	}
    };
    
    $scope.getOrderForRehabilitation = function () {
    	if (!$scope.stage.rehabilitation) {
    		return 'cer.066.stage_order_not';
    	}
    	switch($scope.stage.rehabilitation) {
    	case 'INPATIENT':
    		return 'cer.066.stage_order_reab_stat';
    	case 'OUTPATIENT':
    		return 'cer.066.stage_order_reab_amb';
    	}
    };
    
    $scope.closeStage = function () {
    	SveidraF066StageCommons.closeStage($scope.formId, $scope.stage.id, function () {
    		$scope.stage.closed = true;
    	});
     };
	
    $scope.deleteStage = function () {
    	SveidraF066StageCommons.deleteStage($scope.formId, $scope.stage.id, function () {
    		$location.path('/dp/patients/' + $scope.patientId + '/aLK_forms').search();
    	});
    };
   
}]);