ng1App.controller('doctor.patient.DocumentsNewCtrl',
    ['$scope', '$routeParams', '$filter', 'DocumentCtx', 'DocumentsApiFactory', 'DocumentModificationActions', 'DocumentSpinnerHelper',
        '$modal', '$rootScope', 'FormLoaderService', 'TemplateSaver', 'CreatePaperEncounterOrderModal', 'SettingsApiFactory', 'TemplatesApiFactory', 'MessageService',
        function ($scope, $routeParams, $filter, DocumentCtx, DocumentsApiFactory, DocumentModificationActions, DocumentSpinnerHelper,
                  $modal, $rootScope, FormLoaderService, TemplateSaver, CreatePaperEncounterOrderModal, SettingsApiFactory, TemplatesApiFactory, MessageService) {
            $scope._shared = {
                customValidator: null,
                onCreateSaveInterceptor: null,
                // documents can override this to allow only save final (restrict sign)
                saveFinalOnly: function () {
                    return false;
                }
            };


            var params = angular.copy($routeParams);

            DocumentCtx.reset();
            DocumentCtx.setMode('new');
            DocumentCtx.setPatientId($routeParams.patientId);
            DocumentCtx.setDocumentType($routeParams.documentType);
            $scope.docCtx = DocumentCtx;

            $scope._removed = {};
            $scope._modified = {};

            // documents can override this to allow only save final (restrict sign)
            $scope.saveFinalOnly = function () {
                return false;
            };

            params.docType = DocumentCtx.getDocumentRealType();
            switch (params.docType) {
                case 'e025':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e025-ambulatorinio-apsilankymo-aprasymas');
                    break;
                case 'e003':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e003-stacionaro-epikrize');
                    break;
                case 'e027':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e027-medicinos-dokumentu-israsas--siuntimas');
                    break;
                case 'e027a':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e027-ats-atsakymas-i-siuntima');
                    break;
                case 'e200':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e200-laboratorijos-tyrimo-uzsakymas');
                    break;
                case 'e200a':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e200-ats-laboratorinio-tyrimo-rezultatu-duomenu-protokolas');
                    break;
                case 'e014':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e014-patologijos-tyrimo-uzsakymas');
                    break;
                case 'e014a':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e014-ats--patologijos-tyrimo-atsakymas');
                    break;
                case 'e063':
                    $scope.$emit('setHelpPage', 'kurti-dokumenta-e063-vakcinacijos-israsas');
                    break;
                default:
                    $scope.$emit('setHelpPage', 'kurti-elektronini-dokumenta');
            }

            $scope._populateForm = function (data) {
                $scope.form = data;
            };

            FormLoaderService.startFormLoading();
            $scope.form = DocumentsApiFactory.getPrefilledForm(params, function (doc) {
                DocumentCtx.document = doc;
                $scope.prefillData = doc;
                $scope.patientData = doc.metadata.patient;
                $scope.organization = doc.metadata.custodian;
                $scope.practitioner = doc.metadata.practitioner;
                $scope.encounterData = doc.metadata.encounter;
                DocumentModificationActions.checkValidQualification($scope.practitioner);
                // Load form configuration
                if (['e200', 'e200a'].includes(params.docType)) {
                    $scope.formConfiguration = SettingsApiFactory.getByName({name: 'form_' + params.docType + '_configuration'}, function (formConfigurationResponse) {
                        var parsedResponse = JSON.parse(formConfigurationResponse.value);
                        // Assign each object property separately to not override Resource $promise.
                        for (var p in parsedResponse) $scope.formConfiguration[p] = parsedResponse[p];
                    });
                }

                if (params.loadFromTemplate) {
                    $scope.form = TemplatesApiFactory.load({id: params.templateId, type: params.docType}, $scope.form, function (doc) {
                        DocumentCtx.document = doc;
                        MessageService.clearAll();
                        MessageService.add('success', 'Duomenys įkelti');
                    });
                    _.each($scope._formLoadCallbackRegistry, function (c) {
                        $scope.form.$promise.then(c[0], c[1], c[2]);
                    });
                }
            });
            FormLoaderService.endFormLoading($scope.form.$promise);

            // register all $scope.form.$promise.then callbacks for later use.
            $scope._formLoadCallbackRegistry = [];
            var _then = $scope.form.$promise.then;
            $scope.form.$promise.then = function (p1, p2, p3) {
                _then.call($scope.form.$promise, p1, p2, p3).then(function () {
                    $scope._formLoadCallbackRegistry.push([p1, p2, p3]);
                });
            };

            $scope.savePartial = function () {
                DocumentModificationActions.savePartial(null, $scope.mainDocumentForm, $scope._shared.customValidator, $scope._shared.onCreateSaveInterceptor);
            };
            $scope.saveFinal = function (doSign) {
                DocumentModificationActions.saveFinal(null, $scope.mainDocumentForm, $scope._shared.customValidator, $scope._shared.onCreateSaveInterceptor, null, null, doSign);
            };

            $scope.saveDraft = function () {
                DocumentModificationActions.saveDraft(null, $scope.mainDocumentForm, null);
            };

            window.__log = function () {
                DocumentModificationActions.__log($scope.mainDocumentForm);
            };

            $scope.docSpinnerHelper = DocumentSpinnerHelper;

            var updateEncounterData = function (enc) {
                $scope.encounterData = $scope.form.metadata.encounter = enc;
                $scope.form.encounterRef = enc.fullId;
            };

            $scope.changeEncounterOrder = function () {
                DocumentModificationActions.changeEncounterOrder($scope.form.patientId, $scope.encounterData, updateEncounterData);
            };

            $scope.removeEncounterOrder = function () {
                DocumentModificationActions.removeEncounterOrder($scope.encounterData, updateEncounterData);
            };

            $scope.hasEncounterOrder = function () {
                return $scope.encounterData && $scope.encounterData.orderRef;
            };

            //----only for test--
            $scope.addChangeE025 = function () {
                DocumentModificationActions.modalForContinuedTreatmentE025($routeParams.patientId, $scope, $rootScope);
            };

            $scope.loadTemplate = function () {
                DocumentModificationActions.loadTemplateModal($scope, $rootScope);
            };

            $scope.removeContinuedTreatmentE025 = function () {
                DocumentModificationActions.removeContinuedTreatmentE025($scope);
            };

            var modalCancel = $modal({
                scope: $scope,
                title: 'Atšaukimo priežastis',
                contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel.html'
            });
            $scope.addReason = function () {
                $scope.cancelErr = 0;
                modalCancel.$promise.then(function () {
                    modalCancel.show();
                });
            };
            $scope.createObs = function () {
                CreatePaperEncounterOrderModal.show($scope.encounterData.id, updateEncounterData);
            };
            $scope.sameOrg = function () {
                if (!$scope.encounterData) {
                    return false;
                }
                var orgId = $scope.encounterData.organizationRef.split('/')[1];
                var practOrgId = $rootScope.currentUser.organizationReference.split('/')[1];

                return orgId === practOrgId;
            };

            $scope.isEncounterActive = function () {
                if (!$scope.encounterData) {
                    return false;
                }

                return $scope.encounterData.status === 'in progress';
            };

            //----end test
            $scope.docMenu = {
                main: [
                    {
                        type: 'main',
                        title: 'doc.add.template',
                        action: $scope.loadTemplate,
                        isVisible: function () {
                            return $filter('acRead')('TemplateList');
                        }
                    }
                ],
                dropDown: [
                    {
                        type: 'ctx',
                        title: 'doc.add.template',
                        action: $scope.loadTemplate,
                        isVisible: function () {
                            return $filter('acRead')('TemplateList');
                        }
                    },
                    {
                        type: 'ctx',
                        title: 'doc.save.template',
                        isVisible: function () {
                            return $filter('acWrite')('TemplateList');
                        },
                        action: function () {
                            TemplateSaver.save($scope.form);
                        }
                    },
                    {type: 'separator'},
                    {
                        type: 'ctx',
                        title: 'doc.create.enc.ord',
                        isVisible: function () {
                            return $scope.sameOrg() && $scope.isEncounterActive() && $filter('acWrite')('Order', ['7']);
                        },
                        action: $scope.createObs
                    },
                    {
                        type: 'ctx',
                        title: 'doc.add.enc.ord',
                        isVisible: function () {
                            return $filter('acWrite')('Encounter') && $scope.sameOrg() && $scope.isEncounterActive() && !$scope.hasEncounterOrder();
                        },
                        action: $scope.changeEncounterOrder
                    },
                    // {
                    //     type: 'ctx',
                    //     title: 'doc.created.enc.ord.edit',
                    //     isVisible: function () {
                    //         return $scope.sameOrg() && $scope.isEncounterActive() && $filter('acWrite')('Order', ['7']);
                    //     },
                    //     action: $scope.createObs
                    // },
                    {
                        type: 'ctx',
                        title: 'doc.edit.enc.ord',
                        isVisible: function () {
                            return $filter('acWrite')('Encounter') && $scope.sameOrg() && $scope.isEncounterActive() && $scope.hasEncounterOrder();
                        },
                        action: $scope.changeEncounterOrder
                    },
                    {
                        type: 'ctx',
                        title: 'doc.remove.enc.ord',
                        isVisible: function () {
                            return $filter('acWrite')('Encounter') && $scope.sameOrg() && $scope.isEncounterActive() && $scope.hasEncounterOrder();
                        },
                        action: $scope.removeEncounterOrder
                    }
                ]
            };
        }]);

