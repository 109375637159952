ng1App.directive('certTypeSelect', 
    ['$modal', '$location', 'DpPatientContext', 'TypeaheadSearchHandlers', 'CertificateCreation', 'LoaderHelperService', 'EncountersApiFactory',
     function ($modal, $location, DpPatientContext, TypeaheadSearchHandlers, CertificateCreation, LoaderHelperService, EncountersApiFactory) {
    var modal: any = {};
    var createModal = function (scope) {
        return $modal({
            scope: scope,
            title: 'Medicininės pažymos',
            contentTemplate: '/templates/portal/doctor/patient/certificates/modals/certificate_type_select.html'
        });
    };
    var createSelectDentist = function (scope) {
        scope.searchPractitioners = TypeaheadSearchHandlers.PRACTITIONERS;
        scope.searchPractitionersChange = function (v) {
            if (v && v.id) {
                scope.selectedDentistId = v.id;
            }
        };
        return $modal({
            scope: scope,
            title: 'Odontologo pasirinkimas',
            contentTemplate: '/templates/portal/doctor/patient/records/modals/dentist_selection.html'
        });
    };

    return {
        scope: {
            encounter: '='
        },
        link: function (scope: any, element, attrs) {
            scope.createDocument = function () {
                var navigateToNewCer = function (documentType, additionalUrlParams?) {
                    var urlParams = {
                        encounterId: scope.encounter.id
                    };

                    if (urlParams) {
                        urlParams = angular.extend(urlParams, additionalUrlParams);
                    }

                    modal.hide();
                    $location.path('/dp/patients/' + DpPatientContext.id + '/certificates/' + documentType + '/new').search(urlParams);

                };
                var showSelectConclusionProvider = function (documentType) {
                    modal.hide();
                    modal = CertificateCreation.createWithConsultationSelect(documentType, {encounterId: scope.encounter.id});
                    scope.createDialogErr = {};
                    modal.$promise.then(function () {
                        modal.show();
                    });
                };
                var processE027_1 = function (doSelectOrder) {
                    console.log(doSelectOrder);
                    if (doSelectOrder == 'e027_1_I') {
                        modal.hide();
                        modal = createSelectDentist(scope);
                        scope.createDialogErr = {};
                        modal.$promise.then(function () {
                            modal.show();
                        });
                    } else if (doSelectOrder == 'e027_1_II') {
                        navigateToNewCer('e027_1_II');
                    } else {
                        scope.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas pažymėjimo tipas';
                    }
                };

                scope.createDialogErr = {};

                switch (scope.selected.documentType) {
                    case 'e103_1':
                    case 'e106':
                    case 'e106_2_1':
                        if (scope.selected.bornDead) {
                            navigateToNewCer(scope.selected.documentType, {
                                bornDead: true
                            });
                        } else {
                            navigateToNewCer(scope.selected.documentType);
                        }
                        break;
                    case 'e027_1':
                        processE027_1(scope.selected.orderType);
                        break;
                    case 'e047':
                    case 'e048':
                    case 'e049':
                    case 'e083_1':
                        showSelectConclusionProvider(scope.selected.documentType);
                        break;
                    default:
                        scope.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas dokumento tipas.';
                        break;
                }
            };

            scope.submitDentist = function () {
                var documentType = scope.selected.documentType;
                scope.createDialogErr = {};
                modal.hide();
                var searchParams: any = {
                    encounterId: scope.encounter.id
                };
                if (scope.selectedDentistId) {
                    searchParams.selectedDentistId = scope.selectedDentistId;
                }
                console.log(documentType);
                $location.path('/dp/patients/' + DpPatientContext.id + '/certificates/' + documentType + '_I/new').search(searchParams);
            };

            scope.onTypeChange = function () {
                switch (scope.selected.documentType) {
                    case 'e106_2_1':
                        scope.selected.bornDead = scope.modalInputs.documentE106_2_1.onlyBornDead;
                        break;
                    default:
                }
            };

            element.on('click', function () {
                scope.selected = {orders: []};
                scope.createDialogErr = {};
                scope.documentType = null;
                LoaderHelperService.preload(function (hideLoader) {
                    scope.modalInputs = EncountersApiFactory.getAllowedCertificatesTypes({encounterId: scope.encounter.id, patientId: scope.encounter.patientData.id}, function () {
                        modal = createModal(scope);
                        modal.encounterId = scope.encounter.id;
                        modal.$promise.then(function () {
                            hideLoader();
                            modal.show();
                        });
                    });
                });
            });
        }
    };
}]);