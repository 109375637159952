import {Component, OnInit} from "@angular/core";
import { RouteParams } from "src/app/ng1.routeParams";

@Component({
    selector: 'third-party-records-list',
    templateUrl: './third-party-records-list.component.html'
})
export class ThirdPartyRecordsListComponent implements OnInit {
    backPath: string
    constructor(private params: RouteParams) {}
    ngOnInit(): void {
        this.backPath = '/dp/patients/' + this.params.patientId
    }
}