ng1App.factory('SessionApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/session';
    return $resource('', {}, {
        getSysdate: {
            method: 'GET',
            url: URL_BASE + '/sysdate'
        },
        getOrganization: {
            method: 'GET',
            url: URL_BASE + '/organization'
        },
        getPractitioner: {
            method: 'GET',
            url: URL_BASE + '/practitioner'
        },
        getPatient: {
            method: 'GET',
            url: URL_BASE + '/patient'
        },
        getCurrent: {
            method: 'GET',
            url: URL_BASE + '/current'
        },
        getToken: {
            method: 'GET',
            url: URL_BASE + '/token'
        },
        getRepresentative: {
            method: 'GET',
            url: URL_BASE + '/representative'
        },
        putAagaSgas: {
            method: 'POST',
            url: URL_BASE + '/aagasgas/:aagaSgas',
            isArray: false
        },
        getAagaSgas: {
            method: 'GET',
            url: URL_BASE + '/aagasgas'
        }
    });
}]);