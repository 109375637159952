import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ApiV2DocRefConsent } from "../../../../../api/api-v2-doc-ref-consent";
import { RouteParams } from "../../../../ng1.routeParams";
import { DocRefConsentFilterEnum } from "../../../models/doc-ref-consent-filter.enum";
import { DocRefConsentModel } from "../../../models/doc-ref-consent.model";
import { FilterGroupModel } from "../../../models/filter-group.model";
import { FilterModel } from "../../../models/filter.model";
import { FilteredDataModel } from "../../../models/filtered-data.model";
import { PortalTypeEnum } from "../../../models/portalType.enum";
import { QueryModel } from "../../../models/query.model";
import { FilterService } from "../../../services/filter.service";
import { Pagination } from "../../list/pagination-bar/pagination-bar.constants";

@Component({
    selector: "doc-ref-consent-list",
    templateUrl: "./doc-ref-consent-list.component.html",
})
export class DocRefConsentListComponent implements OnInit {
    backPath: string;
    @Input() docRefConsents: DocRefConsentModel[];
    @Input() filters: FilterModel[];
    @Input() currentFilter: FilterModel;
    @Input() portalType: PortalTypeEnum;
    @Input() filtersGroup$: Observable<FilterGroupModel[]>;
    working: boolean = false;
    count: number;
    pagesCount: number;
    page: number = 1;
    loadMoreActive = false;
    patientId: string;

    constructor(public apiV2DocRefConsent: ApiV2DocRefConsent, private router: Router, private params: RouteParams) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.backPath = this.portalType;
        this.setFilters();
        this.setPredefinedFilters();
        this.loadDocRefConsentList(this.currentFilter.query);
    }

    loadDocRefConsentList(query?: QueryModel, add?: boolean): void {
        const filterQuery = {};
        this.apiV2DocRefConsent
            .getDocRefConsentList(query ? query : this.currentFilter.query)
            .subscribe((data: DocRefConsentModel[] | FilteredDataModel) => {
                const filteredData: FilteredDataModel = data as FilteredDataModel;
                FilterService.setFilterCount(this.filters, filteredData);
                filteredData.items.forEach((d) => (d.type = "DocRefConsentModel"));

                if (add) {
                    filteredData.items.forEach((item) => this.docRefConsents.push(item));
                } else {
                    this.docRefConsents = filteredData.items;
                }

                this.count = filteredData.total;
                this.loadMoreActive = this.count < filteredData.total;
                this.working = false;
                const pagesCount = filteredData.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
    }

    setFilters() {
        this.filtersGroup$ = this.apiV2DocRefConsent.getFilters();
    }

    setPredefinedFilters() {
        this.filters = FilterService.createFilters(DocRefConsentFilterEnum);
        if (this.filters) {
            this.currentFilter = this.filters.find((filter) => filter.title === DocRefConsentFilterEnum.TOTAL);
        }
    }

    canCreate(): boolean {
        return this.portalType === PortalTypeEnum.DP;
    }

    createDocRefConsent() {
        if (this.patientId) {
            this.router.navigate([this.portalType + "/patients/" + this.patientId + "/doc-ref-consent/create"]);
        } else {
            this.router.navigate([this.portalType + "/doc-ref-consent/create"]);
        }
    }

    onCardClick(id: string): void {
        this.router.navigate([this.portalType + "/patients/" + this.patientId + "/doc-ref-consent/view/" + id]);
    }

    loadMore() {
        this.working = true;
        this.page++;
        this.loadDocRefConsentList({ count: 5, page: this.page } as QueryModel, true);
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        this.loadDocRefConsentList({ count: pagination.count, page: pagination.page } as QueryModel);

        this.page = pagination.page;
    }
}
