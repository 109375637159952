ng1App.directive('asideButton', 
    ['$filter', 'CommonFunctionService',
     function ($filter, CommonFunctionService) {
    return {
        templateUrl: '/templates/components/content/aside-button.html',
        restrict: 'E',
        replace: false,
        scope: {
            contentList: "="
        },
        transclude: true,
        link: function (scope: any, element, attrs) {
            scope.goTo = CommonFunctionService.gotoAnchor;
        }
    };
}]);

ng1App.factory('AsideBlockModel', 
    ['$filter',
     function ($filter) {
    /**
     * Create aside block object, automatically translates block title and sets id to
     * same as translation key, except replaces dot (.) with underscore (_)
     * @param translationKey
     * @constructor
     */
    function AsideBlockModel(translationKey, showFunction) {
        this.title = $filter('translate')(translationKey);
        this.id = translationKey.replace(/\./g, '_');
        this.show = showFunction;
    }

    return (AsideBlockModel);
}]);