import { Component, Input } from '@angular/core';
import { E010_Row, E010_RowsGroup } from '../../../e010-forms/e010-forms.model';

@Component({
    selector: 'app-e010-2-1-form-obstetric-procedures',
    templateUrl: './e010-2-1-form-obstetric-procedures.component.html',
})
export class E01021FormObstetricProceduresComponent {
    @Input() set data(value : any) {
        if(!value) return;
        this.risksData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formObstetricProcedures.procedures',
                boldTitle: true,
                value: _.uniq(value.e113Tp.procedures.filter(item => item.date && this.mainProcedures.includes(item.name)).map((item) => item.name))
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formObstetricProcedures.immunoprophylaxisAntiD',
                boldTitle: true,
                value: value.e113Tp.procedures.filter(item => item.date && item.name === 'Anti-D imunoglobulinas')
                    .map((item) => this.formatDifference(item.date, value.e096N.fruits[0].birthTime))
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formObstetricProcedures.ultrasoundExamination',
                boldTitle: true,
                value: value.e113Ug.fetusUltrasoundExaminationList
                    .map((item) => this.formatUltrasoundExamination(item.ultrasoundStage, item.reExamination?.pregnancyWeek))
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formObstetricProcedures.labourInduction',
                boldTitle: true,
                value: value.e096Ge.laborProgress.laborInitMethod !== 'Sužadinta' ? []
                        : value.e096Ge.laborProgress.laborOnsetMethods
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formObstetricProcedures.childbirthInducing',
                boldTitle: true,
                value: [value.e096Part.entries.some(entry => entry.oxytocin && +entry.oxytocin > 0) ? 'Taip' : 'Ne']
            },
        ]

        this.rows = [{
            rows: this.risksData,
            style: 'list'
        }]
    };

    private risksData: E010_Row[];
    private mainProcedures = [
        "Prenatalinė chromosomų anomalijų patikra",
        "Prenatalinė diagnostika",
        "Vaisiaus chirurginės procedūros",
        "Vaisiaus plaučių brandinimas",
        "Tokolizė nėštumo metu"
    ];

    rows: E010_RowsGroup[] = [];

    constructor() {}

    private formatDifference(date1: string, date2: string): string {
        const dateDiff = moment(date1).diff(moment(date2), 'seconds');
        const diff = Math.abs(dateDiff);
        const result = diff >= 7 * 24 * 60 * 60 ? Math.floor(diff / (7 * 24 * 60 * 60)) + ' sav.' //weeks
                : diff >= 24 * 60 * 60 ? Math.floor(diff / (24 * 60 * 60)) + ' val.' //days
                : Math.floor(diff / 3600) + ' dien.' //hours
        return dateDiff < 0 ? `iki gimdymo ${result}` : `po gimdymo ${result}`
    }

    private formatUltrasoundExamination(value: string, pregnancyWeek?: number | string): string {
        switch (value) {
            case 'Vaisiaus ultragarsinis tyrimas 11+0–13+6 nėštumo savaitę':
                return '11-13 sav.';
            case 'Vaisiaus ultragarsinis tyrimas 18+0–20+0 nėštumo savaitę':
                return '18-20 sav.';
            case 'Vaisiaus ultragarsinis tyrimas II–III nėštumo trečdalį (esant indikacijų)':
                return `kitas ${pregnancyWeek} sav.`;
        }
    }
}
