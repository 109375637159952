<full-calendar *ngIf="calendarOptions" [options]="calendarOptions" style="width: 1512px; height: 80%;">
    <ng-template #eventContent let-arg>
        <div class="fc-custom-event {{getCardClass(arg.event._def.extendedProps.status)}}"
            [attr.data-tooltip]="arg.event._def.extendedProps.displayMode === 'short' ? (arg.event._def.title + ': ' + arg.event._def.extendedProps.tooltipContent) : arg.event._def.extendedProps.tooltipContent"
            placement="bottom"
            [tooltip]="arg.event._def.extendedProps.displayMode === 'short' ? (arg.event._def.title + ' ' + arg.event._def.extendedProps.tooltipContent) : arg.event._def.extendedProps.tooltipContent"
            [adaptivePosition]="false"
            container="body">
            <div class="event-content d-flex flex-column h-100">
                <div class="d-flex">
                    <strong *ngIf="arg.event._def.extendedProps.displayMode != 'short' || (arg.event._def.extendedProps.tooltipContent == 0 && arg.event._def.extendedProps.displayMode === 'short') " class="flex-grow-1">
                        {{arg.event._def.extendedProps.eventTypeName}}
                    </strong>
                    <div *ngIf="arg.event._def.extendedProps.displayMode === 'short' && arg.event._def.extendedProps.tooltipContent != 0" class="flex-grow-1 lh-1">
                        <span class="ms-2 rounded-circle rounded-empty" [ngClass]="getBadgeClass(arg.event._def.extendedProps.status)"></span>
                        {{arg.event._def.extendedProps.tooltipContent}}
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                            <div class="pb-1">
                                <ng-container [ngSwitch]="arg.event._def.extendedProps.eventTypeCode">
                                    <i *ngSwitchCase="'visit-doctor'" class="fa-solid fa-stethoscope"></i>
                                    <i *ngSwitchCase="'planned-immunization'" class="fa-solid fa-syringe"></i>
                                    <i *ngSwitchCase="'prescription-validity'" class="fa-solid fa-receipt"></i>
                                    <i *ngSwitchCase="'dispense-due-date'" class="fa-solid fa-capsules"></i>
                                    <!-- Default case if needed -->
                                    <i *ngSwitchDefault class="fa-solid fa-info-circle"></i>
                                </ng-container>
                            </div>
                    </div>
                </div>
                <div *ngIf="arg.event._def.extendedProps.displayMode != 'short'" class="flex-grow-1">
                    {{arg.event._def.extendedProps.tooltipContent}}
                </div>
            </div>
            <div *ngIf="arg.event._def.extendedProps?.status && arg.event._def.extendedProps.displayMode != 'short'" class="d-flex align-self-center w-100">
                <strong class="flex-grow-1 status">
                    <span
                        class="badge rounded-pill {{getBadgeClass(arg.event._def.extendedProps.status)}}">
                        {{arg.event._def.extendedProps.status || ''}}
                    </span>
                </strong>
            </div>
        </div>
    </ng-template>
</full-calendar>