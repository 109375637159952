import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    controlIsDisabled,
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-ge-labor-progress',
    templateUrl: './e096-ge-labor-progress.component.html',
})
export class E096GeLaborProgressComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    currentTime = new Date();
    formGroup: FormGroup;
    laborBaginningOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Savaiminė'
        },
        {
            id: '1',
            code: '1',
            name: 'Sužadinta'
        }
    ];
    antibioticsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Skirta'
        },
        {
            id: '1',
            code: '1',
            name: 'Neskirta'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    getCntrlValue = getControlValue.bind(this) as typeof getControlValue;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            laborInitMethod: this.fb.control(null, [Validators.required]),
            laborOnsetMethods: this.fb.control({value: [], disabled: true}),
            born: this.fb.control(null, [Validators.required]),
            bornAssistanceMethod: this.fb.control(null),
            robsonGroup: this.fb.control(null, [Validators.required]),
            placentaTimeDelivery: this.fb.control(null, [Validators.required]),
            medicinalPainRelief: this.fb.control([]),
            nonMedicinalPainRelief: this.fb.control(null),
            antibiotics: this.fb.control(null),
            antibioticsForProphylacticPurposes: this.fb.control(false),
            antibioticsPrescribedProphylactically: this.fb.control([]),
            otherAntibioticsPrescribedProphylactically: this.fb.control([]),
            antibioticsIntendedForTreatment: this.fb.control(false),
            purposesOfTreatment: this.fb.control([]),
            otherPurposesOfTreatment: this.fb.control([]),
        });

        this.changeStatusOfControl({
            controlNameForListen: 'born',
            controlNameForUpdate: 'bornAssistanceMethod',
            enableValue: 'Natūraliais takais, taikant pagalbines priemones',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'laborInitMethod',
            controlNameForUpdate: 'laborOnsetMethods',
            enableValue: 'Sužadinta'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'antibioticsForProphylacticPurposes',
            controlNameForUpdate: ['antibioticsPrescribedProphylactically', 'otherAntibioticsPrescribedProphylactically'],
            enableValue: true
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'antibioticsIntendedForTreatment',
            controlNameForUpdate: ['purposesOfTreatment', 'otherPurposesOfTreatment'],
            enableValue: true
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }
}
