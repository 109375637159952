ng1App.directive('nhrPartiallyPresentDocs', 
    ['$rootScope', 'UnfinishedRecordsApiFactory', '$routeParams',
     function ($rootScope, UnfinishedRecordsApiFactory, $routeParams) {
    return {
        templateUrl: '/templates/portal/doctor/patient/documents/layout/nhr-dp-partially-present-docs.html',
        restrict: 'E', 
        replace: false, 
        scope: {
        	encounterId: "=",
        	compositionId: "="},
        link: function (scope: any, element, attrs) {
            scope.closed = attrs.closed == 'true';
            scope.documents = [];
            scope.$on('widget_toggle', function (event, data) {
                if (!scope.dataLoaded) {
                    UnfinishedRecordsApiFactory.search({patient: $routeParams.patientId, encounter: scope.encounterId}, function (data) {
                        scope.dataLoaded = true;
                        scope.documents = data;
                        
                        if (scope.compositionId) {
                        	//removing current document from list
                        	scope.documents = _(scope.documents).filter(function(item) {
                        		return item.resource.id !== scope.compositionId;
                        	})
                        }
                    });
                }

            });
        }
    };
}]);


ng1App.directive('nhrDocumentHistory', 
    ['DocumentsApiFactory', 'EncountersApiFactory',
     function (DocumentsApiFactory, EncountersApiFactory) {
    return {
        templateUrl: '/templates/portal/doctor/patient/documents/layout/nhr-dp-doc-history.html',
        restrict: 'E',
        replace: false,
        scope: {
            compositionId: '=',
            encounterId: '=',
            patientId: '=',
            linkType: '='
        },
        link: function (scope: any, element, attrs) {
            scope.closed = attrs.closed == 'true';
            scope._ = _;
            scope.history = {};
            scope.dataLoaded = false;

            var loadHistory = function () {
                var stopSpinner = function () {
                    scope.dataLoaded = true;
                };
                scope.history = [];
                if (scope.encounterId) {
                    scope.history = EncountersApiFactory.encounterHistory({encounterId: scope.encounterId, page: 1, count: 7}, stopSpinner, stopSpinner);
                } else {
                   scope.history = DocumentsApiFactory.documentHistory({compositionId: scope.compositionId}, stopSpinner, stopSpinner);
                }
                

            };

            scope.$on('widget_toggle', function (event, data) {
                if (!scope.dataLoaded) {
                    loadHistory();
                }
            });

            scope.getLink = function (historyId) {
                switch (scope.linkType) {
                    case 'document':
                        return '/dp/patients/' + scope.patientId + '/documents/' + scope.compositionId + '/history/' + historyId;
                    case 'certificate':
                        return '/dp/patients/' + scope.patientId + '/certificates/' + scope.compositionId + '/history/' + historyId;
                    case 'summary':
                        return '/dp/patients/' + scope.patientId + '/summary/history/' + historyId;
                    case 'encounter':
                        return '/dp/patients/' + scope.patientId + '/encounters/' + scope.encounterId + '/history/' + historyId;
                    default :
                        console.log('unkown link type: ', scope.linkType);
                        return '#';
                }
            };
        }
    };
}]);

ng1App.directive('nhrRelatedDocuments', 
    ['$rootScope', 'DocumentsApiFactory', 'CertificatesApiFactory', '$routeParams',
     function ($rootScope, DocumentsApiFactory, CertificatesApiFactory, $routeParams) {
    return {
        templateUrl: '/templates/portal/doctor/patient/documents/layout/nhr-dp-related-docs.html',
        restrict: 'E', replace: false, scope: true, link: function (scope: any, element, attrs) {
            scope.closed = attrs.closed == 'true';

            scope.dataLoaded = false;
            scope.$on('widget_toggle', function (event, data) {
                if (!data && !scope.dataLoaded) {
                    if ($routeParams.documentId) {
                        scope.relatedDocuments = DocumentsApiFactory.getRelatedDocuments({id: $routeParams.documentId}, function (res) {
                            scope.dataLoaded = true;
                        });
                    } else {
                        scope.relatedDocuments = CertificatesApiFactory.getRelatedDocuments({id: $routeParams.id}, function (res) {
                            scope.dataLoaded = true;
                        });
                    }
                }
            });

        }
    };
}]);


 