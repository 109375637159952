import { Component } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-agGrid-checkbox-fg-cell",
    template: ` <app-checkbox [inputId]="key" labelText="" [fControl]="formControl"></app-checkbox> `,
})
export class AgGridCheckboxFgCell {
    formControl: FormControl;
    key;
    private value;
    private rowIndex: number;
    private formControlName: string;

    agInit(params: any) {
        this.key = params.column.colDef.headerName + "_" + params.rowIndex;
        this.value = params.value;
        this.rowIndex = params.rowIndex;
        this.formControlName = params.context.formControlName;
    }

    refresh(params: any): boolean {
        this.formControl = params.context.formGroupArray.at(this.rowIndex).get(this.formControlName);
        this.formControl.patchValue(this.value);
        return true;
    }
}
