<nhr-record-widget closable="true" closed="false" [title]="'Vaisto saugumo ir mokomoji medžiaga' | translate"
                   mixed="true">
    <ol class="slats hover">
        <li *ngIf="materialData === undefined">
            <i class="fa fa-spinner fa-spin"></i>
        </li>
        <li class="mouse-pointer" tabindex="0" (keyup)="onDocumentClick(data.documentUrl)" (click)="onDocumentClick(data.documentUrl)" *ngFor="let data of materialData">
            <div>
                <span>{{ data?.documentType?.displayValue }}</span>
            </div>
        </li>
        <li *ngIf="materialData !== undefined && materialData.length === 0" class="text-center">
            {{ 'Medžiagos nėra' | translate }}
        </li>
    </ol>
</nhr-record-widget>