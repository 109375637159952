ng1App.directive('encounterTypeIcon', function () {
    return {
        templateUrl: '/templates/components/encounter/encounter-type-icon.html',
        restrict: 'E',
        replace: false,
        scope: {
            encounter: '='
        },
        link: function (scope: any, element, attrs) {
            scope.getType = function(){
                var e = scope.encounter;
                if(!e){
                    return null;
                }
                if (e.encounterType === 'audit') {
                    return e.encounterType;
                }
                if(e.encounterType == 'pharmacy'){
                    return 'pharmacy';
                }
                if(e.event && e.event.code){
                    return e.event.code;
                }
                return 'unknown';
            }
        }
    };
});