<div>
    <h1>{{ "E113-AP Nėščiosios ambulatorinio apsilankymo aprašymas" | translate }}</h1>
    <app-document-caption [captionData]="documentCaptionData"></app-document-caption>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <app-ngn-caption [captionData]="ngnCaptionData"></app-ngn-caption>
    <app-encounter-block></app-encounter-block>
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Nėščiosios ambulatorinis aprašymas' | translate"
        id="womanAmbulatoryDescription">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <div class="row req">
                                    <div class="col-sm-12 col-md-6 d-flex flex-column">
                                        <app-date-picker inputId="assessmentDate" labelText="Vertinimo data"
                                            labelClass="fw-bold" [maxDate]="currentDate"
                                            [showErrors]="true"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.assessmentDate')"></app-date-picker>
                                    </div>
                                    <div class="col-sm-12 col-md-6 d-flex flex-column">
                                        <fieldset class="row">
                                            <legend class="fw-bold col-form-label">
                                                {{ "Nusiskundimai" | translate }}
                                            </legend>
                                            <div *ngFor="let item of complaints" class="col-md-6 col-sm-6">
                                                <app-radio-button radioName="isComplaints" [labelText]="item.name"
                                                    [radioTitle]="item.name" [id]="item.name"
                                                    [radioValue]="item.value" [fControl]="
                                                        formGroup.get('womanAmbulatoryDescription.isComplaints')
                                                    "></app-radio-button>
                                            </div>
                                            <app-form-control-validation [fControl]="formGroup.get('womanAmbulatoryDescription.isComplaints')" />
                                        </fieldset>
                                    </div>
                                </div>
                                <div *ngIf="formGroup.get('womanAmbulatoryDescription.isComplaints').value" class="row">
                                    <div class="col">
                                        <app-input labelText="Savijauta/nusiskundimai" labelClass="fw-bold"
                                            type="textarea"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.complaints')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <app-input id="weight" labelText="Svoris" labelClass="fw-bold" type="number"
                                            units="kg" min="20" numberStep="0.001" digitsInfo="1.3-3"
                                            placeholder="0.000"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.weight')" />
                                    </div>
                                    <div class="col-md-6">
                                        <app-input id="getGainWeightDuringPregnancy" labelText="Prieaugis"
                                            labelClass="fw-bold" viewMode="true" units="kg"
                                            [value]="getGainWeightDuringPregnancy" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <div class="fw-bold">{{ "Kraujospūdis (kairė)" | translate }}</div>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 pe-0">
                                                <app-input id="bloodPressureLeftUp" labelClass="fw-bold" type="number"
                                                    units="/" min="0" [fControl]="
                                                        formGroup?.get('womanAmbulatoryDescription.bloodPressureLeftUp')
                                                    " />
                                            </div>
                                            <div class="col-md-6 col-sm-6 ps-0">
                                                <app-input id="bloodPressureLeftDown" labelClass="fw-bold" type="number"
                                                    units="mmHg" min="0" [fControl]="
                                                        formGroup?.get(
                                                            'womanAmbulatoryDescription.bloodPressureLeftDown'
                                                        )
                                                    " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <div class="fw-bold">{{ "Kraujospūdis (dešinė)" | translate }}</div>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 pe-0">
                                                <app-input id="bloodPressureRightUp" labelClass="fw-bold" type="number"
                                                    units="/" min="0" [fControl]="
                                                        formGroup?.get(
                                                            'womanAmbulatoryDescription.bloodPressureRightUp'
                                                        )
                                                    " />
                                            </div>
                                            <div class="col-md-6 col-sm-6 ps-0">
                                                <app-input id="bloodPressureRightDown" labelClass="fw-bold"
                                                    type="number" units="mmHg" min="0" [fControl]="
                                                        formGroup?.get(
                                                            'womanAmbulatoryDescription.bloodPressureRightDown'
                                                        )
                                                    " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <app-input id="pulse" labelText="Pulsas:" labelClass="fw-bold" type="number"
                                            units="k/min." min="0"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.pulse')" />
                                    </div>
                                    <div class="col-md-6">
                                        <app-input id="temperature" labelText="Temperatūra:" labelClass="fw-bold"
                                            type="number" units="C°" numberStep="0.1" digitsInfo="1.1-1"
                                            placeholder="0.0"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.temperature')" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <app-input id="gda" labelText="GDA" labelClass="fw-bold" type="number"
                                            units="cm" min="0"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.gda')" />
                                    </div>
                                    <div class="col-md-6">
                                        <app-input id="childCount" labelText="Vaisių skaičius" labelClass="fw-bold"
                                            type="number" min="1" max="20"
                                            [fControl]="formGroup?.get('womanAmbulatoryDescription.childCount')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row req">
                    <div class="col-lg-6">
                        <app-input id="recommendations" labelText="Rekomendacijos" labelClass="fw-bold" type="textarea"
                            [showErrors]="true"
                            [fControl]="formGroup?.get('womanAmbulatoryDescription.recommendations')" />
                    </div>
                    <div class="col-lg-6">
                        <app-input id="appointmentsTreatment" labelText="Paskyrimai, gydymas" labelClass="fw-bold"
                            type="textarea" [showErrors]="true"
                            [fControl]="formGroup?.get('womanAmbulatoryDescription.appointmentsTreatment')" />
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Diagnozės' | translate" id="diagnosis">
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Nėščiosios vaisiaus(-ių) aprašymas' | translate"
        id="fetusDescription">
        <div *ngFor="let fGroup of getFetusDescriptionFormArray.controls; let i = index">
            <div class="card">
                <div class="card-body">
                    <div class="fw-bold pb-2">{{ "Vaisius" | translate }} {{ getChildCountArray?.[i]?.letter }}</div>
                    <div class="col">
                        <div class="row">
                            <app-input id="{{ i }}heartbeat" class="col-sm-12 col-md-6 col-xxl-3"
                                labelText="Širdies ritmas" type="number" min="0" [fControl]="fGroup.get('heartbeat')" />
                            <app-select id="{{ i }}movements" class="col-sm-12 col-md-6 col-xxl-3 req"
                                labelText="Judesiai" labelClass="fw-bold" [values]="fetusMovements"
                                [showErrors]="true"
                                [fControl]="fGroup.get('movements')" />
                            <app-select id="{{ i }}pathology" class="col-sm-12 col-md-6 col-xxl-3" labelText="Pirmeiga"
                                labelClass="fw-bold" [values]="fetusPathology" [fControl]="fGroup.get('pathology')" />
                            <app-select id="{{ i }}position" class="col-sm-12 col-md-6 col-xxl-3" labelText="Padėtis"
                                labelClass="fw-bold" [values]="fetusPosition" [fControl]="fGroup.get('position')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false"
        [title]="'Priežiūros planas, - Papildomi priežiūros plano įrašai' | translate" id="maintenancePlan">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <div class="row mb-2">
                                    <div class="col">
                                        <app-select id="additionalSpecialistConsultation"
                                            labelText="Papildoma specialisto konsultacija:" labelClass="fw-bold"
                                            placeholder="Papildoma specialisto konsultacija"
                                            [values]="additionalSpecialistConsultation"
                                            [fControl]="maintenancePlan?.get('additionalSpecialistConsultation')" />
                                    </div>
                                </div>
                                <div class="row mb-2 req">
                                    <div class="fw-bold col-form-label">{{ "Laikotarpis" | translate }}</div>
                                    <div class="col-md-6 col-sm-6">
                                        <app-select id="periodFrom" labelClass="fw-bold" placeholder="Savaitė nuo"
                                            [values]="maintenancePlanWeeks" [showErrors]="true"
                                            [fControl]="maintenancePlan?.get('periodFrom')" />
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <app-select id="periodTo" labelClass="fw-bold" placeholder="Savaitė iki"
                                            [values]="maintenancePlanWeeks" [showErrors]="true"
                                            [fControl]="maintenancePlan?.get('periodTo')" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <app-input id="note" labelText="Pastaba" labelClass="fw-bold" type="text"
                                            [fControl]="maintenancePlan?.get('note')" />
                                    </div>
                                    <div class="col-md-6 text-end">
                                        <button class="btn btn-secondary mt-4 me-2" type="submit"
                                            [disabled]="isDisableAddMaintenancePlan" (click)="addMaintenancePlan()">
                                            + {{ "Pridėti" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="getMaintenancePlanFormArray.length" class="col-lg-6 col-md-12 col-sm-12">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="fw-bold mb-3">
                                {{ "Šio apsilankymo metu pridėti papildomi įvykiai" | translate }}
                            </div>
                            <div *ngFor="let maintenancePlan of getMaintenancePlanFormArray.value; let i = index"
                                class="row mb-2 g-2 border-bottom">
                                <div class="col">
                                    <div class="row">
                                        <div class="fw-bold col-md-4 me-n4">{{ "Konsultacija" | translate }}:</div>
                                        <div class="col-md-8">
                                            {{ maintenancePlan.additionalSpecialistConsultation }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-4 me-n4">{{ "Laikotarpis" | translate }}:</div>
                                        <div class="col-md-8">
                                            {{ maintenancePlan.periodFrom }} {{ "iki" | translate }}
                                            {{ maintenancePlan.periodTo }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="fw-bold col-md-4 me-n4">{{ "Pastaba" | translate }}:</div>
                                        <div class="col-md-8">{{ maintenancePlan.note }}</div>
                                    </div>
                                    <div class="block my-2">
                                        <button class="btn btn-sm btn-hover-outline text-primary m-1" type="button"
                                            (click)="editMaintenancePlan(i)">
                                            <i class="fa fa-pencil"></i> {{ "Koreguoti" | translate }}
                                        </button>
                                        <button class="btn btn-sm btn-hover-outline text-primary m-1" type="submit"
                                            (click)="removeMaintenancePlan(i)">
                                            <i class="fa fa-trash"></i> {{ "Pašalinti" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
        (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
        [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
        [submitButtonDisabled]="true" [saveButtonDisabled]="!formGroup.valid"></app-modification-actions>
</div>