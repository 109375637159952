ng1App.directive('sveidraBloodComponents', 
    ['EntitiesApiFactory', 'SveidraListSubForm',
     function (EntitiesApiFactory, SveidraListSubForm) {
	// TODO implement hiding of this part
	
	var INTEGER_REGEXP = /^\d{1,5}$/;
	
	var resolveDataForEdit = function (form, data) {
		// Blood
		_.each(data.bloodComponents, function (blood) {
			if (form.blood.code == blood.code) {
				form.blood = blood;
			}
		});
	};
	
	var initForm = function (scope) {
		scope.formCtrl = new SveidraListSubForm(scope.stage.bloodComponents, 'blood-components');
		
		scope.formCtrl._data.bloodComponents = EntitiesApiFactory.getList({classCode: 'blood-nomenclature'}, function (result) {
			_.each(result, function (blood) {
				blood.label = _.getCodeName(blood);
			});
		});
		
		scope.formCtrl.after = function (action) {
			if (action == 'edit') {
        		resolveDataForEdit(this._form, this._data);
        	}
        };
		
        scope.formCtrl.validateValue = function (form, validator) {
			if (!form.blood) {
				validator.add('blood');
			}
			if (!form.count) {
				validator.add('countReq');
			} else if (!INTEGER_REGEXP.test(form.count)) {
				validator.add('count');
			}
    	};
		
		scope.formCtrl.init();
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/blood_components.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			scope.stage.$promise.then(function () {
				initForm(scope);
			});
		}
	};
}]);