angular.module('erx.utils').service('$erxModalDoseCalculator', ["$modal", "$rootScope", "$q", "gettextCatalog", function ($modal, $rootScope, $q, gettextCatalog) {
  var scope = $rootScope.$new();
  var deferred;
  scope.data = {};
  scope.confirmed = function () {
    deferred.resolve(scope.data.recommendedDose);
    confirm.hide();
  };
  scope.calculateDailyDose = function() {
    if (scope.data.weight
            && scope.data.amount
            && scope.data.weightForAmount) {
      scope.data.recommendedDose = Math.round(scope.data.weight * (scope.data.amount/scope.data.weightForAmount) * 10) / 10;
    } else {
      scope.data.recommendedDose = '';
    }
  }
  
  
  var confirm = $modal({template: 'modules/erx-utils/components/erx-dose-calculator/erxDoseCalculator.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (opts) {
    
    scope.title = opts.title ? opts.title : gettextCatalog.getString('Paros dozės skaičiuoklė');
    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };

  return confirm;
}]);