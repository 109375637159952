import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ConfigService } from "src/app/config/config.service";


@Injectable({
    providedIn: 'root',
})
export class ApiV2PatientCalendar extends ConfigService {

    path = this.apiUrl;

    getPatientCalendar(patientId: string, periodFrom?: Date, eventTypeCode?: string, periodTo?: Date){
        let params = new HttpParams();
        params = params.append('periodFrom', new Date().toISOString().slice(0, 10)); // For future check if periodFrom supposed ot be date now

        params = params.append('eventTypeCode', eventTypeCode || "");
        // if (periodFrom) {
        //     params = params.append('periodFrom', periodFrom.toISOString());
        // }
        if (periodTo) {
            params = params.append('periodTo', periodTo.toISOString());
        }

        const fullUrl = this.path + '/patients/' + patientId + "/calendar/events";

        return firstValueFrom(this.http.get<any[]>(fullUrl, { params }));

    }
}