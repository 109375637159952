import { FetusUltrasoundListModel } from "../models/fetusUltrasoundExamination.model";

export const fetusUltrasoundExaminationDataMock = {
    id: "12345679781",
    ultrasoundStage: "Vaisiaus ultragarsinis tyrimas 11+0–13+6 nėštumo savaitę",
    firstUltrasoundStage: {
        researchDate: "2023-11-09T18:11:30.000Z",
        researchConditions: "Geros",
        researchConditionsOther: null,
        childCount: 1,
        appearanceUterineAppendages: "Patalogija",
        cervicalLength: "16",
        rightPI: "8",
        rightRI: "7",
        rightProtodiastolicWave: "18",
        leftPI: "7",
        leftRI: "5",
        leftProtodiastolicWave: "1",
        fetusData: [
            {
                generalInfo: {
                    fetusLetter: "A",
                    chorionicity: false,
                    amnioticity: true,
                    headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                    neckFertility: { mm: "4" },
                    biparietalDimension: { mm: "1", week: "2", day: "3" },
                    headCircumference: { mm: "1", week: "2", day: "3" },
                    abdominalGirth: { mm: "1", week: "2", day: "3" },
                    femurLength: { mm: "1", week: "2", day: "3" },
                    humerusLength: { mm: "1", week: "2", day: "3" },
                    fetusWeight: { weight: "1", week: "2", day: "3" },
                    fetalPosition: null,
                    fetalForerunner: null,
                },
                head: {
                    form: "Patalogija",
                    skullOssification: "Norma",
                    sickleVault: "Netirta",
                    vascularPlexuses: "Patalogija",
                    transparentPartition: null,
                    middleLine: null,
                    brainNuclei: null,
                    cerebellum: { tests: null, mm: null, week: null },
                    greatCistern: { tests: null, mm: null },
                    rightAnteriorHorn: { tests: null, mm: null },
                    leftAnteriorHorn: { tests: null, mm: null },
                    rightPosteriorHorn: { tests: null, mm: null },
                    leftPosteriorHorn: { tests: null, mm: null },
                    greatConnection: null,
                    neckCrease: { tests: null, mm: null },
                },
                face: {
                    profile: "Patalogija",
                    eyeSockets: "Netirta",
                    nasalBone: { tests: "Norma", mm: null },
                    upperLip: null,
                    nostrils: null,
                    chin: null,
                    peritonealTissueThickness: { tests: null, mm: null },
                },
                neckAndSpine: { neck: "Norma", spine: "Netirta" },
                chest: { form: null, lungs: "Patalogija", diaphragm: "Netirta" },
                heart: {
                    activity: "Norma",
                    size: "Netirta",
                    axis: "Patalogija",
                    fourCameraView: "Netirta",
                    aorticOutflowTract: null,
                    pulmonaryTrunkExcretoryTract: null,
                },
                belly: {
                    stomach: "Norma",
                    intestine: "Netirta",
                    kidneys: "Netirta",
                    rightKidney: { tests: null, mm: null },
                    leftKidney: { tests: null, mm: null },
                    bladder: "Norma",
                    umbilicalCordAttaches: "Patalogija",
                },
                limbs: { rightHand: "Netirta", leftHand: "Norma", rightLegFoot: "Netirta", leftLegFoot: "Patalogija" },
                umbilicalCord: { threeBloodVessels: "Netirta" },
                sexOrgans: { mens: null, womens: null },
                bloodStream: {
                    throughThreeWayValve: "Norma",
                    throughDuctusVenosus: "Netirta",
                    heartAttackFrequency: "Patalogija",
                },
                placenta: {
                    localization: ["Priekinėje sienoje", "Žemai"],
                    placenta: null,
                    placentaAppearance: null,
                    pathologicalAttachmentPlacenta: null,
                    maturityDegree: null,
                },
                amnioticFluids: {
                    amnioticFluid: null,
                    VVI: null,
                    GVVK: null,
                    fetalBreathingMovements: null,
                    fetalMovements: null,
                    fetalTone: null,
                    amount: null,
                    KTG: null,
                    biophysicalProfile: null,
                },
                bloodFlowTest: {
                    umbilicalArtery: { PI: null, RI: null, SDRatio: null },
                    circulationClass: null,
                    middleCerebralArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                    venousDuct: { PI: null },
                    splenicArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                },
                conclusions: {
                    conclusion: "Norma, atliktas ne visas ištyrimas",
                    notes: "wdfwefw",
                    pregnancyDuration: { week: "1", day: "2" },
                },
            },
        ],
        detailedUltrasoundExamination: false,
        reExamination: { required: true, pregnancyWeek: "32" },
        sendConsultations: { required: true, specialist: "567y56u56" },
        otherImportantInfo: "rthrtrthrthrt",
        researchPurpose: null,
        researchPurposeOther: null,
    },
    secondUltrasoundStage: {
        researchDate: "2023-11-09T18:15:59.000Z",
        researchConditions: "Kita",
        researchConditionsOther: "esafwd",
        childCount: 1,
        appearanceUterineAppendages: "Netirta",
        cervicalLength: "1",
        rightPI: "1",
        rightRI: "2",
        rightProtodiastolicWave: "3",
        leftPI: "1",
        leftRI: "2",
        leftProtodiastolicWave: "3",
        fetusData: [
            {
                generalInfo: {
                    fetusLetter: "A",
                    chorionicity: true,
                    amnioticity: true,
                    headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                    neckFertility: { mm: null },
                    biparietalDimension: { mm: "1", week: "2", day: "3" },
                    headCircumference: { mm: "1", week: "2", day: "3" },
                    abdominalGirth: { mm: "1", week: "2", day: "3" },
                    femurLength: { mm: "1", week: "2", day: "3" },
                    humerusLength: { mm: "1", week: "2", day: "3" },
                    fetusWeight: { weight: "1", week: "2", day: "3" },
                    fetalPosition: null,
                    fetalForerunner: null,
                },
                head: {
                    form: "Norma",
                    skullOssification: null,
                    sickleVault: null,
                    vascularPlexuses: null,
                    transparentPartition: "Netirta",
                    middleLine: "Patalogija",
                    brainNuclei: "Netirta",
                    cerebellum: { tests: "Norma", mm: "1", week: "2" },
                    greatCistern: { tests: "Patalogija", mm: "3" },
                    rightAnteriorHorn: { tests: "Norma", mm: "2" },
                    leftAnteriorHorn: { tests: "Patalogija", mm: "2" },
                    rightPosteriorHorn: { tests: "Netirta", mm: "3" },
                    leftPosteriorHorn: { tests: "Patalogija", mm: "2" },
                    greatConnection: "Netirta",
                    neckCrease: { tests: "Norma", mm: "2" },
                },
                face: {
                    profile: "Patalogija",
                    eyeSockets: "Netirta",
                    nasalBone: { tests: "Norma", mm: "1" },
                    upperLip: "Patalogija",
                    nostrils: "Netirta",
                    chin: "Norma",
                    peritonealTissueThickness: { tests: "Patalogija", mm: "3" },
                },
                neckAndSpine: { neck: "Patalogija", spine: "Netirta" },
                chest: { form: "Norma", lungs: "Netirta", diaphragm: "Patalogija" },
                heart: {
                    activity: "Netirta",
                    size: "Norma",
                    axis: "Netirta",
                    fourCameraView: "Norma",
                    aorticOutflowTract: "Patalogija",
                    pulmonaryTrunkExcretoryTract: "Netirta",
                },
                belly: {
                    stomach: "Netirta",
                    intestine: "Patalogija",
                    kidneys: null,
                    rightKidney: { tests: "Netirta", mm: "3" },
                    leftKidney: { tests: "Patalogija", mm: "3" },
                    bladder: "Patalogija",
                    umbilicalCordAttaches: "Netirta",
                },
                limbs: { rightHand: "Norma", leftHand: "Netirta", rightLegFoot: "Patalogija", leftLegFoot: "Netirta" },
                umbilicalCord: { threeBloodVessels: "Norma" },
                sexOrgans: { mens: "Netirta", womens: "Patalogija" },
                bloodStream: { throughThreeWayValve: null, throughDuctusVenosus: null, heartAttackFrequency: null },
                placenta: {
                    localization: ["Priekinėje sienoje", "Žemai"],
                    placenta: "Nedengia vidinių gimdos kaklelio žiočių",
                    placentaAppearance: "Patalogija",
                    pathologicalAttachmentPlacenta: "įtariamas",
                    maturityDegree: null,
                },
                amnioticFluids: {
                    amnioticFluid: "Norma",
                    VVI: "12",
                    GVVK: "3",
                    fetalBreathingMovements: null,
                    fetalMovements: null,
                    fetalTone: null,
                    amount: null,
                    KTG: null,
                    biophysicalProfile: null,
                },
                bloodFlowTest: {
                    umbilicalArtery: { PI: null, RI: null, SDRatio: null },
                    circulationClass: null,
                    middleCerebralArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                    venousDuct: { PI: null },
                    splenicArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                },
                conclusions: {
                    conclusion: "Norma, atliktas ne visas ištyrimas",
                    notes: "qdaqwdqwdq",
                    pregnancyDuration: { week: "1", day: "2" },
                },
            },
        ],
        detailedUltrasoundExamination: true,
        reExamination: { required: false, pregnancyWeek: null },
        sendConsultations: { required: true, specialist: "dqwqwed" },
        otherImportantInfo: "wsdwqdew",
        researchPurpose: null,
        researchPurposeOther: null,
    },
    thirdUltrasoundStage: {
        researchDate: "2023-11-09T17:44:18.000Z",
        researchConditions: "Apsunkintos",
        researchConditionsOther: null,
        childCount: 1,
        appearanceUterineAppendages: "Patalogija",
        cervicalLength: "1",
        rightPI: "1",
        rightRI: "2",
        rightProtodiastolicWave: "3",
        leftPI: "5",
        leftRI: "6",
        leftProtodiastolicWave: "8",
        fetusData: [
            {
                generalInfo: {
                    fetusLetter: "A",
                    chorionicity: true,
                    amnioticity: false,
                    headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                    neckFertility: { mm: null },
                    biparietalDimension: { mm: "1", week: "2", day: "3" },
                    headCircumference: { mm: "1", week: "2", day: "3" },
                    abdominalGirth: { mm: "1", week: "2", day: "3" },
                    femurLength: { mm: "1", week: "2", day: "3" },
                    humerusLength: { mm: "1", week: "2", day: "3" },
                    fetusWeight: { weight: "1", week: "2", day: "3" },
                    fetalPosition: "skersinė",
                    fetalForerunner: "sėdmenų",
                },
                head: {
                    form: "Norma",
                    skullOssification: null,
                    sickleVault: null,
                    vascularPlexuses: null,
                    transparentPartition: "Patalogija",
                    middleLine: "Netirta",
                    brainNuclei: "Netirta",
                    cerebellum: { tests: "Patalogija", mm: "1", week: "2" },
                    greatCistern: { tests: "Norma", mm: "3" },
                    rightAnteriorHorn: { tests: "Patalogija", mm: "3" },
                    leftAnteriorHorn: { tests: "Netirta", mm: "4" },
                    rightPosteriorHorn: { tests: "Patalogija", mm: "2" },
                    leftPosteriorHorn: { tests: "Norma", mm: "4" },
                    greatConnection: "Netirta",
                    neckCrease: { tests: "Norma", mm: "5" },
                },
                face: {
                    profile: "Norma",
                    eyeSockets: "Netirta",
                    nasalBone: { tests: "Patalogija", mm: "2" },
                    upperLip: "Netirta",
                    nostrils: null,
                    chin: null,
                    peritonealTissueThickness: { tests: null, mm: "5" },
                },
                neckAndSpine: { neck: "Patalogija", spine: "Netirta" },
                chest: { form: "Netirta", lungs: "Patalogija", diaphragm: "Norma" },
                heart: {
                    activity: "Norma",
                    size: "Netirta",
                    axis: "Patalogija",
                    fourCameraView: "Netirta",
                    aorticOutflowTract: "Netirta",
                    pulmonaryTrunkExcretoryTract: "Norma",
                },
                belly: {
                    stomach: "Norma",
                    intestine: "Netirta",
                    kidneys: null,
                    rightKidney: { tests: "Patalogija", mm: "7" },
                    leftKidney: { tests: "Patalogija", mm: "8" },
                    bladder: "Netirta",
                    umbilicalCordAttaches: "Norma",
                },
                limbs: { rightHand: "Norma", leftHand: "Netirta", rightLegFoot: "Patalogija", leftLegFoot: "Netirta" },
                umbilicalCord: { threeBloodVessels: "Netirta" },
                sexOrgans: { mens: "Norma", womens: "Netirta" },
                bloodStream: { throughThreeWayValve: null, throughDuctusVenosus: null, heartAttackFrequency: null },
                placenta: {
                    localization: ["Priekinėje sienoje", "Žemai"],
                    placenta: "Nedengia vidinių gimdos kaklelio žiočių",
                    placentaAppearance: "Patalogija",
                    maturityDegree: 3,
                },
                amnioticFluids: {
                    amnioticFluid: "Norma",
                    VVI: "3",
                    GVVK: "4",
                    fetalBreathingMovements: 0,
                    fetalMovements: 2,
                    fetalTone: 0,
                    amount: 2,
                    KTG: 0,
                    biophysicalProfile: 2,
                },
                bloodFlowTest: {
                    umbilicalArtery: { PI: "3", RI: "4", SDRatio: "5" },
                    middleCerebralArtery: { PI: "3", RI: "3", SDRatio: "5", PSV: "5", cms: "6" },
                    venousDuct: { PI: "6" },
                    splenicArtery: { PI: "7", RI: "8", SDRatio: "9", PSV: "7", cms: "1" },
                },
                conclusions: {
                    conclusion: "Norma, atliktas ne visas ištyrimas",
                    notes: "asdas",
                    pregnancyDuration: { week: "1", day: "23" },
                },
            },
        ],
        detailedUltrasoundExamination: false,
        reExamination: { required: true, pregnancyWeek: "234" },
        sendConsultations: { required: true, specialist: "easfsd" },
        otherImportantInfo: "sdfsdfsdfsd",
        researchPurpose: "Vaisiaus būklės vertinimas",
        researchPurposeOther: null,
    },
};

export const fetusUltrasoundExaminationListDataMock: FetusUltrasoundListModel = {
    id: "12345679781",
    fetusUltrasoundExaminationList: [
        {
            ultrasoundStage: "Vaisiaus ultragarsinis tyrimas 11+0–13+6 nėštumo savaitę",
            researchDate: "2023-11-09T18:11:30.000Z",
            researchConditions: "Geros",
            researchConditionsOther: null,
            childCount: 1,
            appearanceUterineAppendages: "Patalogija",
            cervicalLength: "16",
            rightPI: "8",
            rightRI: "7",
            rightProtodiastolicWave: "18",
            leftPI: "7",
            leftRI: "5",
            leftProtodiastolicWave: "1",
            fetusData: [
                {
                    generalInfo: {
                        fetusLetter: "A",
                        chorionicity: false,
                        amnioticity: true,
                        headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                        neckFertility: { mm: "4" },
                        biparietalDimension: { mm: "1", week: "2", day: "3" },
                        headCircumference: { mm: "1", week: "2", day: "3" },
                        abdominalGirth: { mm: "1", week: "2", day: "3" },
                        femurLength: { mm: "1", week: "2", day: "3" },
                        humerusLength: { mm: "1", week: "2", day: "3" },
                        fetusWeight: { weight: "1", week: "2", day: "3" },
                        fetalPosition: null,
                        fetalForerunner: null,
                    },
                    head: {
                        form: "Patalogija",
                        skullOssification: "Norma",
                        sickleVault: "Netirta",
                        vascularPlexuses: "Patalogija",
                        transparentPartition: null,
                        middleLine: null,
                        brainNuclei: null,
                        cerebellum: { week: null },
                        greatCistern: { tests: null, mm: null },
                        rightAnteriorHorn: { tests: null, mm: null },
                        leftAnteriorHorn: { tests: null, mm: null },
                        rightPosteriorHorn: { tests: null, mm: null },
                        leftPosteriorHorn: { tests: null, mm: null },
                        greatConnection: null,
                        neckCrease: { tests: null, mm: null },
                    },
                    face: {
                        profile: "Patalogija",
                        eyeSockets: "Netirta",
                        nasalBone: { tests: "Norma", mm: null },
                        upperLip: null,
                        nostrils: null,
                        chin: null,
                        peritonealTissueThickness: { tests: null, mm: null },
                    },
                    neckAndSpine: { neck: "Norma", spine: "Netirta" },
                    chest: { form: null, lungs: "Patalogija", diaphragm: "Netirta" },
                    heart: {
                        activity: "Norma",
                        size: "Netirta",
                        axis: "Patalogija",
                        fourCameraView: "Netirta",
                        aorticOutflowTract: null,
                        pulmonaryTrunkExcretoryTract: null,
                    },
                    belly: {
                        stomach: "Norma",
                        intestine: "Netirta",
                        kidneys: "Netirta",
                        rightKidney: { tests: null, mm: null },
                        leftKidney: { tests: null, mm: null },
                        bladder: "Norma",
                        umbilicalCordAttaches: "Patalogija",
                    },
                    limbs: {
                        rightHand: "Netirta",
                        leftHand: "Norma",
                        rightLegFoot: "Netirta",
                        leftLegFoot: "Patalogija",
                    },
                    umbilicalCord: { threeBloodVessels: "Netirta" },
                    sexOrgans: { mens: null, womens: null },
                    bloodStream: {
                        throughThreeWayValve: "Norma",
                        throughDuctusVenosus: "Netirta",
                        heartAttackFrequency: "Patalogija",
                    },
                    placenta: {
                        localization: ["Priekinėje sienoje", "Žemai"],
                        placenta: null,
                        placentaAppearance: null,
                        pathologicalAttachmentPlacenta: null,
                        maturityDegree: null,
                    },
                    amnioticFluids: {
                        amnioticFluid: null,
                        VVI: null,
                        GVVK: null,
                        fetalBreathingMovements: null,
                        fetalMovements: null,
                        fetalTone: null,
                        amount: null,
                        KTG: null,
                        biophysicalProfile: null,
                    },
                    bloodFlowTest: {
                        umbilicalArtery: { PI: null, RI: null, SDRatio: null },
                        circulationClass: null,
                        middleCerebralArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                        venousDuct: { PI: null },
                        splenicArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                    },
                    conclusions: {
                        conclusion: "Norma, atliktas ne visas ištyrimas",
                        notes: "wdfwefw",
                        pregnancyDuration: { week: "1", day: "2" },
                    },
                },
            ],
            detailedUltrasoundExamination: false,
            reExamination: { required: true, pregnancyWeek: "32" },
            sendConsultations: { required: true, specialist: "567y56u56" },
            otherImportantInfo: "rthrtrthrthrt",
            researchPurpose: null,
            researchPurposeOther: null,
        },
        {
            ultrasoundStage: "Vaisiaus ultragarsinis tyrimas 18+0–20+0 nėštumo savaitę",
            researchDate: "2023-11-09T18:15:59.000Z",
            researchConditions: "Kita",
            researchConditionsOther: "esafwd",
            childCount: 1,
            appearanceUterineAppendages: "Netirta",
            cervicalLength: "1",
            rightPI: "1",
            rightRI: "2",
            rightProtodiastolicWave: "3",
            leftPI: "1",
            leftRI: "2",
            leftProtodiastolicWave: "3",
            fetusData: [
                {
                    generalInfo: {
                        fetusLetter: "A",
                        chorionicity: true,
                        amnioticity: true,
                        headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                        neckFertility: { mm: null },
                        biparietalDimension: { mm: "1", week: "2", day: "3" },
                        headCircumference: { mm: "1", week: "2", day: "3" },
                        abdominalGirth: { mm: "1", week: "2", day: "3" },
                        femurLength: { mm: "1", week: "2", day: "3" },
                        humerusLength: { mm: "1", week: "2", day: "3" },
                        fetusWeight: { weight: "1", week: "2", day: "3" },
                        fetalPosition: null,
                        fetalForerunner: null,
                    },
                    head: {
                        form: "Norma",
                        skullOssification: null,
                        sickleVault: null,
                        vascularPlexuses: null,
                        transparentPartition: "Netirta",
                        middleLine: "Patalogija",
                        brainNuclei: "Netirta",
                        cerebellum: { week: "2" },
                        greatCistern: { tests: "Patalogija", mm: "3" },
                        rightAnteriorHorn: { tests: "Norma", mm: "2" },
                        leftAnteriorHorn: { tests: "Patalogija", mm: "2" },
                        rightPosteriorHorn: { tests: "Netirta", mm: "3" },
                        leftPosteriorHorn: { tests: "Patalogija", mm: "2" },
                        greatConnection: "Netirta",
                        neckCrease: { tests: "Norma", mm: "2" },
                    },
                    face: {
                        profile: "Patalogija",
                        eyeSockets: "Netirta",
                        nasalBone: { tests: "Norma", mm: "1" },
                        upperLip: "Patalogija",
                        nostrils: "Netirta",
                        chin: "Norma",
                        peritonealTissueThickness: { tests: "Patalogija", mm: "3" },
                    },
                    neckAndSpine: { neck: "Patalogija", spine: "Netirta" },
                    chest: { form: "Norma", lungs: "Netirta", diaphragm: "Patalogija" },
                    heart: {
                        activity: "Netirta",
                        size: "Norma",
                        axis: "Netirta",
                        fourCameraView: "Norma",
                        aorticOutflowTract: "Patalogija",
                        pulmonaryTrunkExcretoryTract: "Netirta",
                    },
                    belly: {
                        stomach: "Netirta",
                        intestine: "Patalogija",
                        kidneys: null,
                        rightKidney: { tests: "Netirta", mm: "3" },
                        leftKidney: { tests: "Patalogija", mm: "3" },
                        bladder: "Patalogija",
                        umbilicalCordAttaches: "Netirta",
                    },
                    limbs: {
                        rightHand: "Norma",
                        leftHand: "Netirta",
                        rightLegFoot: "Patalogija",
                        leftLegFoot: "Netirta",
                    },
                    umbilicalCord: { threeBloodVessels: "Norma" },
                    sexOrgans: { mens: "Netirta", womens: "Patalogija" },
                    bloodStream: {
                        throughThreeWayValve: null,
                        throughDuctusVenosus: null,
                        heartAttackFrequency: null,
                    },
                    placenta: {
                        localization: ["Priekinėje sienoje", "Žemai"],
                        placenta: "Nedengia vidinių gimdos kaklelio žiočių",
                        placentaAppearance: "Patalogija",
                        pathologicalAttachmentPlacenta: "įtariamas",
                        maturityDegree: null,
                    },
                    amnioticFluids: {
                        amnioticFluid: "Norma",
                        VVI: "12",
                        GVVK: "3",
                        fetalBreathingMovements: null,
                        fetalMovements: null,
                        fetalTone: null,
                        amount: null,
                        KTG: null,
                        biophysicalProfile: null,
                    },
                    bloodFlowTest: {
                        umbilicalArtery: { PI: null, RI: null, SDRatio: null },
                        circulationClass: null,
                        middleCerebralArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                        venousDuct: { PI: null },
                        splenicArtery: { PI: null, RI: null, SDRatio: null, PSV: null, cms: null },
                    },
                    conclusions: {
                        conclusion: "Norma, atliktas ne visas ištyrimas",
                        notes: "qdaqwdqwdq",
                        pregnancyDuration: { week: "1", day: "2" },
                    },
                },
            ],
            detailedUltrasoundExamination: true,
            reExamination: { required: false, pregnancyWeek: null },
            sendConsultations: { required: true, specialist: "dqwqwed" },
            otherImportantInfo: "wsdwqdew",
            researchPurpose: null,
            researchPurposeOther: null,
        },
        {
            ultrasoundStage: "Vaisiaus ultragarsinis tyrimas II–III nėštumo trečdalį (esant indikacijų)",
            researchDate: "2023-11-09T17:44:18.000Z",
            researchConditions: "Apsunkintos",
            researchConditionsOther: null,
            childCount: 1,
            appearanceUterineAppendages: "Patalogija",
            cervicalLength: "1",
            rightPI: "1",
            rightRI: "2",
            rightProtodiastolicWave: "3",
            leftPI: "5",
            leftRI: "6",
            leftProtodiastolicWave: "8",
            fetusData: [
                {
                    generalInfo: {
                        fetusLetter: "A",
                        chorionicity: true,
                        amnioticity: false,
                        headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                        neckFertility: { mm: null },
                        biparietalDimension: { mm: "1", week: "2", day: "3" },
                        headCircumference: { mm: "1", week: "2", day: "3" },
                        abdominalGirth: { mm: "1", week: "2", day: "3" },
                        femurLength: { mm: "1", week: "2", day: "3" },
                        humerusLength: { mm: "1", week: "2", day: "3" },
                        fetusWeight: { weight: "1", week: "2", day: "3" },
                        fetalPosition: "skersinė",
                        fetalForerunner: "sėdmenų",
                    },
                    head: {
                        form: "Norma",
                        skullOssification: null,
                        sickleVault: null,
                        vascularPlexuses: null,
                        transparentPartition: "Patalogija",
                        middleLine: "Netirta",
                        brainNuclei: "Netirta",
                        cerebellum: { mm: "1", week: "2", day: "3" },
                        greatCistern: { tests: "Norma", mm: "3" },
                        rightAnteriorHorn: { tests: "Patalogija", mm: "3" },
                        leftAnteriorHorn: { tests: "Netirta", mm: "4" },
                        rightPosteriorHorn: { tests: "Patalogija", mm: "2" },
                        leftPosteriorHorn: { tests: "Norma", mm: "4" },
                        greatConnection: "Netirta",
                        neckCrease: { tests: "Norma", mm: "5" },
                    },
                    face: {
                        profile: "Norma",
                        eyeSockets: "Netirta",
                        nasalBone: { tests: "Patalogija", mm: "2" },
                        upperLip: "Netirta",
                        nostrils: null,
                        chin: null,
                        peritonealTissueThickness: { tests: null, mm: "5" },
                    },
                    neckAndSpine: { neck: "Patalogija", spine: "Netirta" },
                    chest: { form: "Netirta", lungs: "Patalogija", diaphragm: "Norma" },
                    heart: {
                        activity: "Norma",
                        size: "Netirta",
                        axis: "Patalogija",
                        fourCameraView: "Netirta",
                        aorticOutflowTract: "Netirta",
                        pulmonaryTrunkExcretoryTract: "Norma",
                    },
                    belly: {
                        stomach: "Norma",
                        intestine: "Netirta",
                        kidneys: null,
                        rightKidney: { tests: "Patalogija", mm: "7" },
                        leftKidney: { tests: "Patalogija", mm: "8" },
                        bladder: "Netirta",
                        umbilicalCordAttaches: "Norma",
                    },
                    limbs: {
                        rightHand: "Norma",
                        leftHand: "Netirta",
                        rightLegFoot: "Patalogija",
                        leftLegFoot: "Netirta",
                    },
                    umbilicalCord: { threeBloodVessels: "Netirta" },
                    sexOrgans: { mens: "Norma", womens: "Netirta" },
                    bloodStream: {
                        throughThreeWayValve: null,
                        throughDuctusVenosus: null,
                        heartAttackFrequency: null,
                    },
                    placenta: {
                        localization: ["Priekinėje sienoje", "Žemai"],
                        placenta: "Nedengia vidinių gimdos kaklelio žiočių",
                        placentaAppearance: "Patalogija",
                        maturityDegree: 3,
                    },
                    amnioticFluids: {
                        amnioticFluid: "Norma",
                        VVI: "3",
                        GVVK: "4",
                        fetalBreathingMovements: 0,
                        fetalMovements: 2,
                        fetalTone: 0,
                        amount: 2,
                        KTG: 0,
                        biophysicalProfile: 2,
                    },
                    bloodFlowTest: {
                        umbilicalArtery: { PI: "3", RI: "4", SDRatio: "5" },
                        circulationClass: "99",
                        middleCerebralArtery: { PI: "3", RI: "3", SDRatio: "5", PSV: "5", cms: "6" },
                        venousDuct: { PI: "6" },
                        splenicArtery: { PI: "7", RI: "8", SDRatio: "9", PSV: "7", cms: "1" },
                    },
                    conclusions: {
                        conclusion: "Norma, atliktas ne visas ištyrimas",
                        notes: "asdas",
                        pregnancyDuration: { week: "1", day: "23" },
                    },
                },
                {
                    generalInfo: {
                        fetusLetter: "B",
                        chorionicity: true,
                        amnioticity: false,
                        headButtocksMeasurement: { mm: "1", week: "2", day: "3" },
                        neckFertility: { mm: null },
                        biparietalDimension: { mm: "1", week: "2", day: "3" },
                        headCircumference: { mm: "1", week: "2", day: "3" },
                        abdominalGirth: { mm: "1", week: "2", day: "3" },
                        femurLength: { mm: "1", week: "2", day: "3" },
                        humerusLength: { mm: "1", week: "2", day: "3" },
                        fetusWeight: { weight: "1", week: "2", day: "3" },
                        fetalPosition: "skersinė",
                        fetalForerunner: "sėdmenų",
                    },
                    head: {
                        form: "Norma",
                        skullOssification: null,
                        sickleVault: null,
                        vascularPlexuses: null,
                        transparentPartition: "Patalogija",
                        middleLine: "Netirta",
                        brainNuclei: "Netirta",
                        cerebellum: { week: "2" },
                        greatCistern: { tests: "Norma", mm: "3" },
                        rightAnteriorHorn: { tests: "Patalogija", mm: "3" },
                        leftAnteriorHorn: { tests: "Netirta", mm: "4" },
                        rightPosteriorHorn: { tests: "Patalogija", mm: "2" },
                        leftPosteriorHorn: { tests: "Norma", mm: "4" },
                        greatConnection: "Netirta",
                        neckCrease: { tests: "Norma", mm: "5" },
                    },
                    face: {
                        profile: "Norma",
                        eyeSockets: "Netirta",
                        nasalBone: { tests: "Patalogija", mm: "2" },
                        upperLip: "Netirta",
                        nostrils: null,
                        chin: null,
                        peritonealTissueThickness: { tests: null, mm: "5" },
                    },
                    neckAndSpine: { neck: "Patalogija", spine: "Netirta" },
                    chest: { form: "Netirta", lungs: "Patalogija", diaphragm: "Norma" },
                    heart: {
                        activity: "Norma",
                        size: "Netirta",
                        axis: "Patalogija",
                        fourCameraView: "Netirta",
                        aorticOutflowTract: "Netirta",
                        pulmonaryTrunkExcretoryTract: "Norma",
                    },
                    belly: {
                        stomach: "Norma",
                        intestine: "Netirta",
                        kidneys: null,
                        rightKidney: { tests: "Patalogija", mm: "7" },
                        leftKidney: { tests: "Patalogija", mm: "8" },
                        bladder: "Netirta",
                        umbilicalCordAttaches: "Norma",
                    },
                    limbs: {
                        rightHand: "Norma",
                        leftHand: "Netirta",
                        rightLegFoot: "Patalogija",
                        leftLegFoot: "Netirta",
                    },
                    umbilicalCord: { threeBloodVessels: "Netirta" },
                    sexOrgans: { mens: "Norma", womens: "Netirta" },
                    bloodStream: {
                        throughThreeWayValve: null,
                        throughDuctusVenosus: null,
                        heartAttackFrequency: null,
                    },
                    placenta: {
                        localization: ["Priekinėje sienoje", "Žemai"],
                        placenta: "Nedengia vidinių gimdos kaklelio žiočių",
                        placentaAppearance: "Patalogija",
                        maturityDegree: 3,
                    },
                    amnioticFluids: {
                        amnioticFluid: "Norma",
                        VVI: "3",
                        GVVK: "4",
                        fetalBreathingMovements: 0,
                        fetalMovements: 2,
                        fetalTone: 0,
                        amount: 2,
                        KTG: 0,
                        biophysicalProfile: 2,
                    },
                    bloodFlowTest: {
                        umbilicalArtery: { PI: "3", RI: "4", SDRatio: "5" },
                        circulationClass: "99",
                        middleCerebralArtery: { PI: "3", RI: "3", SDRatio: "5", PSV: "5", cms: "6" },
                        venousDuct: { PI: "6" },
                        splenicArtery: { PI: "7", RI: "8", SDRatio: "9", PSV: "7", cms: "1" },
                    },
                    conclusions: {
                        conclusion: "Norma, atliktas ne visas ištyrimas",
                        notes: "asdas",
                        pregnancyDuration: { week: "1", day: "23" },
                    },
                },
            ],
            detailedUltrasoundExamination: false,
            reExamination: { required: true, pregnancyWeek: "234" },
            sendConsultations: { required: true, specialist: "easfsd" },
            otherImportantInfo: "sdfsdfsdfsd",
            researchPurpose: "Vaisiaus būklės vertinimas",
            researchPurposeOther: null,
        },
    ],
};
