import { Component, Input, OnChanges } from '@angular/core';
import moment from 'moment';
import { Entry, PartTableRowConfig } from '../../../../../models/e096.model';
import { LineConnection } from '../line-connector.directive';

@Component({
    selector: 'app-e096-part-table-active',
    templateUrl: './e096-part-table-active.component.html',
})
export class E096PartTableActiveComponent implements OnChanges {
    @Input() mainColumns: string[] = [];
    @Input() initialFormValue: Entry[];
    @Input() firstActiveColumn: number;
    @Input() lastLatentColumn: number;

    connections: LineConnection[] = [];
    rows: PartTableRowConfig[] = [
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.fetalHeartRate',
            property: 'fetalHeartRate',
            timeSchedule: '30m',
            records: true
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.cardiotocogramPerformanceMethod',
            property: 'cardiotocogramMethod',
            timeSchedule: '1h',
            records: true,
            formatValue: (value) => value === 'Tiesioginė' ? 'T' : value === 'Netiesioginė' ? 'N' : ''
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.amnioticFluidColor',
            property: 'colorAmnioticFluid',
            timeSchedule: '1h',
            records: true,
            formatValue: this.amnioticFluidColorFormat
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.cervixOpening',
            custom: true,
            counter: this.generateArray(0, 10),
            marginTop: true,
            timeSchedule: '15m',
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.time',
            timeSchedule: '1h',
            noBorders: true,
            formatValue: (_, i) => `${this.mainColumns[i] || '00'}`
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.fights',
            custom: true,
            counter: this.generateArray(0, 5),
            timeSchedule: '15m',
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.oxytocin',
            marginTop: true,
            property: 'oxytocin',
            timeSchedule: '15m',
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.pulse',
            marginTop: true,
            property: 'pulse',
            timeSchedule: '1h',
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.bloodPressure',
            timeSchedule: '1h',
            formatValue: (value) => value?.bloodPressureSystolic ? `${value?.bloodPressureSystolic}/${value?.bloodPressureDiastolic}` : ''
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.temperature',
            timeSchedule: '1h',
            property: 'temperature',
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.urinating',
            timeSchedule: '1h',
            property: 'urinating',
            formatValue: (value) => value === 'Pasišlapino' ? 'p' : value === 'Kateterizuota' ? 'k' : ''
        },
        {
            name: 'doc.pregnancyDashboard.e096Part.partogram.methodOfPainRelief',
            timeSchedule: '1h',
            property: 'methodOfPainRelief',
            formatValue: (value) => value?.map(word => word.slice(0, 2))?.join('')
        }
    ];

    private tablesConnected = false;

    constructor() {
    }

    ngOnChanges() {
        if (this.initialFormValue?.length && !this.tablesConnected && this.lastLatentColumn && this.firstActiveColumn) {
            const period = this.getPeriod(this.initialFormValue.at(-1).timeOfEntry);
            this.connections = [
                {
                    from: `activeCell-0-${ period }-4`,
                    to: `activeCell-6-${ period }-10`
                },
                {
                    from: `activeCell-4-${ period }-4`,
                    to: `activeCell-10-${ period }-10`
                }
            ];
            this.connections.push({
                from: `latentCell-${ this.lastLatentColumn }-${ period }-${ this.initialFormValue.at(-1).cervixOpening }`,
                to: `activeCell-${ this.firstActiveColumn }-${ period }-${ this.initialFormValue.at(-1).cervixOpening }`,
                dash: true
            });
            this.connections.push(
                ...this.initialFormValue.slice(0, -1)
                    .map((entry, i) => ({
                        from: `activeCell-${this.getHourColumn(entry.timeOfEntry)}-${ this.getPeriod(entry.timeOfEntry) }-${entry.cervixOpening}`,
                        to: `activeCell-${this.getHourColumn(this.initialFormValue[i + 1].timeOfEntry)}-${ this.getPeriod(this.initialFormValue[i + 1].timeOfEntry) }-${this.initialFormValue[i + 1].cervixOpening}`,
                        thin: true
                    }))
            );
            this.tablesConnected = true;
        }
    }

    getEntryDataByProp(key: string, data: Entry, records: boolean): any {
        if (!records) {
            return data?.[key] || data;
        }
        return data?.fetalRecords.find(value => !!value[key])[key];
    }

    getDataByHour(hour: string, period: number, row: PartTableRowConfig): any {
        const data = this.initialFormValue.filter(entry => moment(entry.timeOfEntry).hours() === +hour);
        if (!row.formatValue) {
            return this.getEntryDataByProp(row.property, this.getObjBySchedule(data, period, row.timeSchedule), row.records);
        } else {
            return row.formatValue(this.getEntryDataByProp(row.property, this.getObjBySchedule(data, period, row.timeSchedule), row.records), this.mainColumns.indexOf(hour))
        }
    }

    private getObjBySchedule(arr: Entry[], period: number, timeSchedule: '15m' | '30m' | '1h'): Entry {
        const sortArray = (arr: Entry[]): Entry[] => arr.sort((a, b) => new Date(b.timeOfEntry).getTime() - new Date(a.timeOfEntry).getTime());

        if (timeSchedule === '1h') {
            return sortArray(arr)[0];
        } else {
            const schedulePeriod = timeSchedule === '30m' ? 30 : 15;
            const startTime = (period - 1) * schedulePeriod;
            const endTime = period * schedulePeriod;

            const filteredArray = arr.filter(entry => {
                const minutes = new Date(entry.timeOfEntry).getMinutes();
                return minutes >= startTime && minutes < endTime;
            });

            return sortArray(filteredArray)[0];
        }
    }

    private amnioticFluidColorFormat(value: string): string {
        switch (value) {
            case 'Bespalviai':
                return 'B';
            case 'Mekonijus':
                return 'M';
            case 'Tirštas mekonijus':
                return 'tM';
            case 'Kruvini':
                return 'K';
            case 'Geltoni':
                return 'G';
            default:
                return '';
        }

    }

    private generateArray<T>(start, end, specificValue?: T): Array<string | T> {
        return Array.from({length: end - start + 1}, (_, index) => specificValue ?? String(start + index));
    }

    private getPeriod(time: string | Date): number {
        return Math.floor((moment(time).minutes() % 60) / 15) + 1;
    }

    private getHourColumn(date: Date | string): number {
        return this.mainColumns.indexOf(`${moment(date).hours()}`);
    }
}
