import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../config/config.service";
import {BrowserSessionStorageService} from "./browser-session-storage.service";
import {q} from "underscore";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Encounters extends ConfigService {
    sessionStorage: BrowserSessionStorageService;
    path = this.apiUrl + '/encounters';

    constructor(sessionStorage: BrowserSessionStorageService, http: HttpClient) {
        super(http)
        this.sessionStorage = sessionStorage;
    }

    hasActiveEncounter(query: any): Observable<boolean> {
        const url = `${this.path}/has-active-encounter`;
        return this.http.get<boolean>(url, {params: query});
    }

    getPatientEncounters(query: any): Observable<any> {
        const url = `${this.path}`;
        return this.http.get<any>(url, {params: query});
    }

    getAllowedDocumentTypes(encounterId: number, query?: any): Observable<any> {
        const url = `${this.path}/${encounterId}/allowed-document-types`;
        return this.http.get<any>(url, {params: query});
    }

    getAllowedCertificatesTypes(encounterId: number, query?: any): Observable<any> {
        const url = `${this.path}/${encounterId}/allowed-certificates-types`;
        return this.http.get<any>(url, {params: query});
    }

    post(body: any): Observable<any> {
        return this.http.post(this.path, body);
    }

    get(id: string | number): Observable<any> {
        return this.http.get(`${this.path}/${id}`);
    }

    getForEdit(id: string | number): Observable<any> {
        return this.http.get(`${this.path}/forEdit/${id}`);
    }

    getByDocType(query?: any): Observable<any> {
        return this.http.get(`${this.path}/byDocType`, {
            params: {
                ...query,
                bsid: this.sessionStorage.getBsid()
            }
        });
    }

    getByDocData(query?: any): Observable<any> {
        return this.http.get(`${this.path}/byDocData`,
            {
                params: {
                    ...query,
                    bsid: this.sessionStorage.getBsid()
                }
            })
    }

    getPatientEncountersByFTQuery(query?: any): Observable<any> {
        return this.http.get(`${this.path}/byDocFt`, {params: query});
    }

    closeEncounter(id: string | number, body: any): Observable<any> {
        return this.http.put(`${this.path}/${id}/close`, body);
    }

    countActiveAndCanceled(encounterId: string | number, docType: string): Observable<any> {
        return this.http.get(`${this.path}/${encounterId}/count-active-and-canceled/${docType}`);
    }

    cancelEncounter(id: string | number, body: any): Observable<any> {
        return this.http.put(`${this.path}/${id}/cancel`, body);
    }

    updateInsurance(id: string | number): Observable<any> {
        return this.http.get(`${this.path}/${id}/update-insurance`)
    }

    eventRestricted(id: string | number): Observable<any> {
        return this.http.get(`${this.path}/${id}/event-restricted`);
    }

    encounterHistory(encounterId: string | number): Observable<any> {
        return this.http.get(`${this.path}/${encounterId}/history`);
    }

    encounterHistoryItem(encounterId: string | number, historyId: string | number): Observable<any> {
        return this.http.get(`${this.path}/${encounterId}/history/${historyId}`)
        // TODO: isArray: false
    }

    getUnusedPatientOrders(query?: any): Observable<any> {
        return this.http.get(`${this.path}/orders-unused`, {params: query});
    }

    getOrderForEdit(id: string | number, query?: any): Observable<any> {
        return this.http.get(`${this.path}/order-for-edit/${id}`, {params: query});
    }

    changeOrder(body: any, query?: any): Observable<any> {
        return this.http.post(`${this.path}/change-order`, body, {params: query});
    }

    changeOrderToPaper(body: any): Observable<any> {
        return this.http.post(`${this.path}/change-order-to-paper`, body);
    }

    getEsiNr(query?: any): Observable<any> {
        return this.http.get(`${this.path}/get-esi-nr`, {params: query});
    }

    getByOrders(query?: any): Observable<any> {
        return this.http.get(`${this.path}/by-orders`, {params: query});
        // TODO: isArray: true
    }

    getRelatedDocs(encounterId: string | number, query?: any): Observable<any> {
        return this.http.get(`${this.path}/${encounterId}/related-documents`, {params: query});
        // TODO: isArray: true
    }

    isActive(query: any): Observable<any> {
        return this.http.get(`${this.path}/is-active`, {params: query});
    }

    getEncountersHistory(query?: any): Observable<any> {
        return this.http.get(`${this.path}/encounters-history`, {params: query});
        // TODO: isArray: true
    }

    registerAuditEncounter(query: any, body: any): Observable<any> {
        return this.http.post(`${this.path}/audit/register`, body, {params: query});
    }
}

