<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Nt.formAppointments.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row max-auto req">
            <div class="form-group col-6 col-lg-3">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold"
                        >{{ 'doc.pregnancyDashboard.e096Nt.formAppointments.assignToMode' | translate }}:
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of modOptions; let i = index"
                        radioName="assignmentToMode-{{i}}"
                        [fControl]="formGroup.get('assignmentToMode')"
                        [radioValue]="option.name"
                        id="assignmentToMode-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
            </div>
            <div class="form-group col-6 col-lg-9 flex-shrink-1">
                <app-select
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formAppointments.dieta' | translate}}:"
                    id="dieta"
                    [fControl]="formGroup.get('dieta')"
                    [values]="selectionValuesFromEntity(answersVariants?.['inpatientDiet'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('dieta')" />
            </div>
        </div>
        <app-input
            type="textarea"
            labelText="doc.pregnancyDashboard.e096Nt.formAppointments.appointmentForMedication"
            [fControl]="formGroup.get('appointmentForMedication')"
        ></app-input>
    </div>
</nhr-record-widget>