import { Component, Input, Optional, Attribute, Output, EventEmitter, OnInit, ElementRef } from "@angular/core";

@Component({
    selector: "[slat-item]",
    templateUrl: "./slat-item.component.html",
})
export class SlatItemComponent implements OnInit {
    @Input() edit: boolean;
    @Input() view: boolean;
    @Input() delete: boolean;
    @Input() custom: boolean;
    @Input() viewAnchorHref: string;
    @Input() editAnchorHref: string;
    @Input() customAnchorHref: string;
    @Input() editTitle: string;
    @Input() viewTitle: string;
    @Input() deleteTitle: string;
    @Input() customTitle: string;
    @Input() customIcon: string;

    @Output() slatItemClick = new EventEmitter<any>()
    @Output() editButtonClick = new EventEmitter<any>()
    @Output() viewButtonClick = new EventEmitter<any>()
    @Output() deleteButtonClick = new EventEmitter<any>()
    @Output() customButtonClick = new EventEmitter<any>()

    showEdit = false
    showView = false
    showDelete = false
    showCustom = false
    isSlatClickable = false

    constructor(
        private elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        const element = this.elementRef.nativeElement as HTMLElement;
        const attributes = element.attributes;

        this.showEdit = !!attributes.getNamedItem('edit')
        this.showView = !!attributes.getNamedItem('view')
        this.showDelete = !!attributes.getNamedItem('delete')
        this.showCustom = !!attributes.getNamedItem('custom')
        this.isSlatClickable = !!attributes.getNamedItem('clickable')

        this.customIcon = 'fa-chevron-right'
    }

    slatClick(event: MouseEvent) {
        event.stopPropagation()
        this.slatItemClick.emit()
    }

    editClick(event: MouseEvent) {
        event.stopPropagation()
        this.editButtonClick.emit()
    }

    viewClick(event: MouseEvent) {
        event.stopPropagation()
        this.viewButtonClick.emit()
    }

    deleteClick(event: MouseEvent) {
        event.stopPropagation()
        this.deleteButtonClick.emit()
    }

    customClick(event: MouseEvent) {
        event.stopPropagation()
        this.customButtonClick.emit()
    }

}
