<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Nt.formCondition.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row gap-2 flex-nowrap mx-auto">
            <div class="col col-lg-6 border-top border-bottom border-end border-start flex-shrink-1 pb-3">
                <div class="row align-items-center gap-2 flex-nowrap req">
                    <div class="form-group col-6 flex-shrink-1">
                        <app-date-picker
                            inputId="assessmentsDate"
                            suffixIcon="fa fa-calendar"
                            labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.dateAssessment' | translate}}"
                            labelClass="col-form-label"
                            [maxDate]="maxDate"
                            [minDate]="minDate"
                            [fControl]="formGroup.get('assessmentsDate')"
                        ></app-date-picker>
                        <app-form-control-validation [fControl]="formGroup.get('assessmentsDate')" />
                    </div>
                    <div class="form-group col-6 flex-shrink-1">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                            {{'doc.pregnancyDashboard.e096Nt.formCondition.complaints' | translate}}
                        </span>
                        <div class="input-group gap-3">
                            <app-radio-button
                                *ngFor="let option of complaintsOptions; let i = index"
                                radioName="cdh-complaints-{{i}}"
                                [fControl]="formGroup.get('complaints')"
                                [radioValue]="option.name"
                                id="cdh-complaints-{{i}}"
                                class="col-auto my-2"
                                formClass="mb-0"
                                [labelText]="option.name"
                            ></app-radio-button>
                            <app-form-control-validation [fControl]="formGroup.get('complaints')" />
                        </div>
                    </div>
                </div>
                <app-input
                    type="textarea"
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.arrivalReason' | translate}}:"
                    [textAreaRows]="3"
                    [fControl]="formGroup.get('arrivalComplaintsReason')"
                ></app-input>
                <app-input
                    type="textarea"
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.anamnesis' | translate}}:"
                    [textAreaRows]="3"
                    [fControl]="formGroup.get('anamnesis')"
                ></app-input>
            </div>
            <div class="col col-lg-6 border-top border-bottom border-end border-start flex-shrink-1 pb-3">
                <div class="row align-items-center gap-2 flex-nowrap">
                    <div class="form-group col-6 flex-shrink-1">
                        <app-input
                            labelText="{{'doc.aspn.healthAssessment.weight' | translate}}"
                            units="kg"
                            type="number"
                            [fControl]="formGroup.get('pregnantState.weight')"
                        ></app-input>
                        <app-form-control-validation [fControl]="formGroup.get('pregnantState.weight')" />
                    </div>
                    <app-input
                        labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.gainDuringPregnancy' | translate}}"
                        class="form-group col-6 flex-shrink-1"
                        units="kg"
                        type="number"
                        [fControl]="formGroup.get('pregnantState.gainDuringPregnancy')"
                    ></app-input>
                </div>
                <div class="row align-items-center gap-2 flex-nowrap req">
                    <div class="form-group col-6 flex-shrink-1">
                        <app-double-input
                            labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.bloodPressureLeft' | translate}}"
                            units="mmHg"
                            type="number"
                            labelClass="col-form-label"
                            [firstControl]="formGroup.get('pregnantState.bloodPressureLS')"
                            [secondControl]="formGroup.get('pregnantState.bloodPressureLD')"
                        ></app-double-input>
                        <app-form-control-validation
                            [fControl]="formGroup.get('pregnantState.bloodPressureLS')"
                            [showCondition]="
                            formGroup.get('pregnantState.bloodPressureLS').dirty &&
                            formGroup.get('pregnantState.bloodPressureLD').dirty &&
                            (formGroup.get('pregnantState.bloodPressureLS').invalid || formGroup.get('pregnantState.bloodPressureLD').invalid)"
                            customError="Second number must be same or greater to first"
                        />
                    </div>
                    <app-double-input
                        labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.bloodPressureRight' | translate}}"
                        class="form-group col-6 flex-shrink-1"
                        units="mmHg"
                        type="number"
                        labelClass="col-form-label"
                        [firstControl]="formGroup.get('pregnantState.bloodPressureRS')"
                        [secondControl]="formGroup.get('pregnantState.bloodPressureRD')"
                    ></app-double-input>
                </div>
                <div class="row align-items-center gap-2 flex-nowrap req">
                    <div class="form-group col-6 flex-shrink-1">
                        <app-input
                            labelText="{{'dpp.pat.sum.phy.pul' | translate}}:"
                            units="k/min."
                            type="number"
                            labelClass="col-form-label"
                            [fControl]="formGroup.get('pregnantState.pulse')"
                        ></app-input>
                        <app-form-control-validation [fControl]="formGroup.get('pregnantState.pulse')" />
                    </div>
                    <div class="form-group col-6 flex-shrink-1">
                        <app-input
                            labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.temperature' | translate}}:"
                            units="C°"
                            type="number"
                            labelClass="col-form-label"
                            [fControl]="formGroup.get('pregnantState.temperature')"
                        ></app-input>
                        <app-form-control-validation [fControl]="formGroup.get('pregnantState.temperature')" />
                    </div>
                </div>
                <div class="row align-items-center gap-2 flex-nowrap req">
                    <div class="form-group col-6 flex-shrink-1">
                        <app-input
                            labelText="GDA"
                            labelClass="col-form-label"
                            units="cm"
                            type="number"
                            [fControl]="formGroup.get('pregnantState.gda')"
                        ></app-input>
                        <app-form-control-validation [fControl]="formGroup.get('pregnantState.gda')" />
                    </div>
                    <div class="form-group col-6 flex-shrink-1">
                        <app-input
                            labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.fruitsNumber' | translate}}"
                            labelClass="col-form-label"
                            type="number"
                            [fControl]="formGroup.get('pregnantState.numberOfFruits')"
                        ></app-input>
                        <app-form-control-validation [fControl]="formGroup.get('pregnantState.numberOfFruits')" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mx-auto" [formGroup]="formGroup.get('pregnantState')">
            <div class="col border-top border-bottom border-end border-start pb-3">
                <div class="row align-items-center gap-2 flex-nowrap h-100">
                    <div class="form-group col-3 flex-shrink-1 h-100 d-flex flex-column req">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold col-form-label"
                        >{{ 'doc.pregnancyDashboard.e096Nt.formCondition.dischargeFromGenitals' | translate }}:
                        </span>
                        <div class="input-group h-100 align-items-center">
                            <div class="input-group gap-3">
                                <app-radio-button
                                    *ngFor="let option of complaintsOptions; let i = index"
                                    radioName="cdh-dischargeFromGenitals-{{i}}"
                                    [fControl]="formGroup.get('pregnantState.dischargeFromGenitals')"
                                    [radioValue]="option.name"
                                    id="cdh-dischargeFromGenitals-{{i}}"
                                    class="col-auto my-2"
                                    formClass="mb-0"
                                    [labelText]="option.name"
                                ></app-radio-button>
                                <app-form-control-validation [fControl]="formGroup.get('pregnantState.dischargeFromGenitals')" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-3 flex-shrink-1 h-100">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold"
                        >Išskyros:
                        </span>
                        <div class="input-group row" formArrayName="conditionalOfSecrets">
                            <app-checkbox
                                *ngFor="let checkbox of conditionalOfSecretsCheckboxes; let i = index"
                                inputId="conditionalOfSecrets{{i}}"
                                class="col-6 mt-1"
                                formClass="mb-0"
                                [labelText]="answers['genitalSecretionsStatus'][i].name"
                                [fControl]="checkbox"
                                (checkboxChange)="checkConditionalOfSecrets(checkbox, i)"
                            ></app-checkbox>
                        </div>
                    </div>
                    <div class="form-group col-3 flex-shrink-1 h-100 d-flex flex-column req">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold col-form-label"
                        >{{ 'doc.pregnancyDashboard.e096Nt.formCondition.regularContractions' |translate }}
                        </span>
                        <div class="input-group h-100 align-items-center gap-3">
                            <app-radio-button
                                *ngFor="let option of complaintsOptions; let i = index"
                                radioName="cdh-regularContractions-{{i}}"
                                [fControl]="formGroup.get('pregnantState.regularContractions')"
                                [radioValue]="option.name"
                                id="cdh-regularContractions-{{i}}"
                                class="col-auto my-2"
                                formClass="mb-0"
                                [labelText]="option.name"
                            ></app-radio-button>
                        </div>
                    </div>
                    <div class="req form-group col-3 flex-shrink-1 h-100 d-flex flex-column">
                        <app-date-picker
                            labelText="Reguliarių sąrėmių pradžia"
                            inputId="regularContractions"
                            suffixIcon="fa fa-calendar"
                            class="no-flex-child-important"
                            [labelClass]="!controlRequired('pregnantState.regularContractionsStart') ? 'norq flex-grow-0 mb-3' : 'flex-grow-0 mb-3'"
                            [withTimepicker]="true"
                            [fControl]="formGroup.get('pregnantState.regularContractionsStart')"
                        ></app-date-picker>
                        <app-form-control-validation [fControl]="formGroup.get('pregnantState.regularContractionsStart')" />
                    </div>
                </div>
            </div>
        </div>

        <app-e096-nt-assessment-of-fetal-condition
            *ngFor="let ind of fruitLength; let i = index"
            [fruitName]="generateFruitName(i)"
            [answersVariants]="answers"
            class="d-block mt-3"/>
    </div>
</nhr-record-widget>