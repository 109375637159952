ng1App.controller('doctor.patient.VaccinesAddNewManualCtrl', 
    ['$scope', '$modalConfirm', '$filter', '$routeParams', '$location', 'SubFormValidator', 'MessageService', 'VaccinationsApiFactory', 'EntitiesApiFactory', 'TypeaheadSearchHandlers',
     function ($scope, $modalConfirm, $filter, $routeParams, $location, SubFormValidator, MessageService, VaccinationsApiFactory, EntitiesApiFactory, TypeaheadSearchHandlers) {
    MessageService.clearAll();
    
    $scope.$emit('setHelpPage', 'ivesti-planuojamus-skiepus-rankiniu-bdu');

    var initForm = function(){
        $scope.form = {
            patientId: $routeParams.patientId,
            dateFrom: _.truncateToDays(new Date()),
            dateTo: null,
            disease: {},
            recommendation: {date: new Date().getTime(), plannedDate: _.truncateToDays(new Date())},
            type: 'manual',
            schemeList: [],
            isByScheme: false,
            vaccineTypeQuery: undefined,
            schemeSearch: undefined
        };
        angular.forEach($scope.schemeList, function (val) {
            val.selected = undefined;
        });
    };
    initForm();
    VaccinationsApiFactory.getSuggestedDate({type: 'manual'}, function(res){
        $scope.form.dateTo = res.value;
    });
    $scope.vaccineTypes = EntitiesApiFactory.getList({classCode: 'vaccine-type'});
    $scope.schemeList = [];

    $scope.generate = function(){
        if (validateForm($scope.form)) {
            $scope.form.schemeList = _.collectSelectedItems($scope.schemeList, function(item){return item.id;});
            var formToSubmit = angular.copy($scope.form);
            if(!formToSubmit.recommendation.vaccineDoseNumber || formToSubmit.recommendation.vaccineDoseNumber == ''){
                formToSubmit.recommendation.vaccineDoseNumber = 0;
            }

            VaccinationsApiFactory.generate(formToSubmit,function(res){
                if(res.value == 0){
                    MessageService.error('Pagal pateiktus parametrus sugeneruoti skiepų nepavyko');
                }else {
                    MessageService.registerSuccessEvent();
                    $scope.goBack();
                }
            },function(err){
                if (err.status == 500) {
                    MessageService.handleError(err.data, MessageService);
                } else if (err.status == 400) {
                    _.each(err.data.globalErrors, function (i) {
                        if (i.msg == 'birth_date') {
                            MessageService.error('Nenurodyta paciento gimimo data.');
                        } else {
                            MessageService.error(i.msg);
                        }
                    });
                }
            });
        } else {
            $scope.validator.showErrorsAndScroll();
        }
    };

    $scope.searchMedication = TypeaheadSearchHandlers.MEDICATION_NAMES;

    $scope.medicationNameChange = function (name) {
        if (name && name.label) {
            $scope.form.recommendation.medicationName = name.label;
            if($scope.form.isByScheme){
                $scope.schemeList = VaccinationsApiFactory.getSchemes({search: name.label});
            }
        } else if (!name) {
            $scope.form.recommendation.medicationName = undefined;
        }
    };

    $scope.goBack = function () {
        window.history.back();
    };
    $scope.clean = function(){
        $modalConfirm.show(
            { title : $filter('translate')('inf.clean.form.tit'), content : $filter('translate')('inf.clean.form') } ).then(function() {
                initForm();
        });
    };

    $scope.validator = new SubFormValidator('vaccine-region');
    var validateForm = function (data) {
        MessageService.clearAll();
        $scope.validator.clear();
        if(data.isByScheme){
            if (!data.dateFrom) {
                $scope.validator.add('dateFrom');
            }
            if (!data.dateTo) {
                $scope.validator.add('dateTo');
            }
        } else {
            if (!data.recommendation.plannedDate) {
                $scope.validator.add('plannedDate');
            }
            if (!data.disease.code) {
                $scope.validator.add('disease');
            }
            var doseNumber = data.recommendation.vaccineDoseNumber;
            if (!doseNumber || doseNumber % 1 !== 0 || doseNumber < 1) {
                $scope.validator.add('referralVaccineDose');
            }
        }
        if (!data.recommendation.medicationName) {
            $scope.validator.add('referralVaccineCode');
        }

        return !$scope.validator.hasErrors();
    };
}]);