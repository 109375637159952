import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ConfigService } from "../../config/config.service";
import { EntityModel } from "../models/entity.model";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Allergies extends ConfigService {
    path = this.apiUrl + '/allergies';
    
}
