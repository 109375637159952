/*
 * Portal type is defined in grunt build config file, for example: config_doctor.json
 * >>grunt build watch --cfgFile=config_doctor.json
 */
ng1App.filter('practitionerLink', 
    ['portalType',
     function (portalType) {
    return function (pract) {
        if (!pract) {
            return '';
        }
        switch (portalType) {
            case 'doctor':
                return '/dp/specialist/' + pract.id;
            case 'patient':
                return '/pp/doctor/' + pract.id;
            default :
                console.error('unknown portal type:', portalType);
                return 'unknown portal type';
        }
    };
}]);

ng1App.filter('vaccinationPlanLink', 
    ['portalType',
     function (portalType) {
    return function (vacc, patientId) {
        switch (portalType) {
            case 'doctor':
                if (vacc.immunizationRecommendation.isVaccinationFact) {
                    return '/dp/patients/' + patientId + '/documents/' + vacc.immunizationRecommendation.compositionId;
                } else {
                    return '/dp/patients/' + patientId + '/vaccines/view/' + vacc.immunizationRecommendation.id;
                }
            case 'patient':
                if (vacc.immunizationRecommendation.isVaccinationFact) {
                    return '/pp/documents/' + vacc.immunizationRecommendation.compositionId;
                } else {
                    return '/pp/vaccines/view/' + vacc.immunizationRecommendation.id;
                }
            default :
                console.error('unknown portal type:', portalType);
                return 'unknown portal type';
        }
    };
}]);

ng1App.filter('patientLink', 
    ['portalType',
     function (portalType) {
    return function (pat, useId) {
        switch (portalType) {
            case 'doctor':
                return '/dp/patients/' + pat.id;
            case 'patient':
                return '/pp/account' + (useId ? ('/' + pat.id) : '');
            default :
                console.error('unknown portal type:', portalType);
                return 'unknown portal type';
        }
    };
}]);

ng1App.filter('documentLink', 
    ['portalType', 'ErxPaths',
     function (portalType, ErxPaths) {
    return function (doc, patientId, isCert) {
        var part = 'documents/';

        var docId = doc;
        if (_.isObject(doc)) {
            if (doc.docType === 'erec01') {
                return ErxPaths.view(doc.additionalData.prescriptionId, doc.additionalData.parentId, patientId);
            }
            if (doc.docType === 'e027va') {
                return '/dp/imaging/study/e027va/' + doc.id;
            }
            docId = doc.id;
        }


        if (isCert || doc.certificate) {
            part = 'certificates/';
        }
        switch (portalType) {
            case 'doctor':
                return '/dp/patients/' + patientId + '/' + part + docId;
            case 'patient':
                return '/pp/' + part + docId;
            default :
                console.error('unknown portal type:', portalType);
                return 'unknown portal type';
        }
    };
}]);

ng1App.filter('helpLink', 
    ['portalType',
     function (portalType) {
    return function (page) {
        return '/help/index.html?page=' + page + '.html';
    };
}]);
