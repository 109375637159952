angular.module('erx').directive('erxViewPrescriptionRo', function () {
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/erx/patient-history/view-prescription/public/erxViewPrescription.html',
    controller: ["$scope", function($scope) {
      $scope.options.hideDocInfo = true;
      $scope.options.noActions = true;
    }]
  };
});