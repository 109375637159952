<app-title showFilterButton="true" backPath="/dp"  backTitle="{{'Mano darbalaukis' | translate}}">
    {{ "comp.docRefConsent.list.title" | translate }}
    <aside>
        <button class="btn btn-primary" type="button" *ngIf="canCreate()" (click)="createDocRefConsent()">
            {{ "comp.docRefConsent.list.new" | translate }}
        </button>
    </aside>
</app-title>
<div class="page-list list-group mb-2 overflow-hidden">
    <div class="custom-search">
        <div class="collapse multi-collapse" id="advancedFilter">
            <div class="custom-filter p-4">
                <doc-ref-consent-filter (onSearch)="loadDocRefConsentList($event)"></doc-ref-consent-filter>
            </div>
            <hr class="my-0 border-bottom-0 border-dark-subtle">
        </div>
    </div>
    <doc-ref-consent-card
        *ngFor="let docRefConsent of docRefConsents"
        [data]="docRefConsent"
        [onCardClick]="onCardClick"
        [portalType]="portalType"
        [ngClass]="'list-group-item'"
        role="button"
    />
</div>
<pagination-bar
    [rowsCount]="count"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>
