ng1App.controller('doctor.user.medvais.ImagingStudyUnisignCallbackCtrl', 
    ['$scope', 'SessionApiFactory', 'DpSessionContext', 'MedvaisPaths', '$routeParams', '$window',
     function ($scope, SessionApiFactory, DpSessionContext, MedvaisPaths, $routeParams, $window) {
    $scope.medvaisUnisignCallbackOptions = {

        getPractitioner: function(){
            return DpSessionContext.practitioner.$promise;
        },
        getOrganization: function(){
            return DpSessionContext.organization.$promise;
        },
        // the id of document we just tried to sign
        documentId: $routeParams.documentId,

        // this is where the gosign iframe should redirect to after finishing the document signing stuff
        // since it's called from within an iframe, this example changes $window.parent.location,
        redirectToImagingStudyList: function () {
            $window.parent.location = MedvaisPaths.list();
        	},

		// url to list of imaging studies
		getImagingStudyListUrl: function () {
			return MedvaisPaths.list();
        },
        // to get active qualification
        getAccountSettings: function () {
            return DpSessionContext.getSettings();
        }
    };
}]);


