<div>
    <div class="row req mb-3">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'date' | translate }}
            </strong>
        </div>
        <div class="col-md-3">
            {{ (formGroup.get('date')?.value | date: 'YYYY-MM-dd HH:mm') || '-' }}
        </div>
    </div>
    <div class="row req mb-3">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.generalDataForm.documentId' | translate }}
            </strong>
        </div>
        <div class="col-md-3">
            {{ formGroup.get('documentId')?.value || '-' }}
        </div>
    </div>
    <div class="row req mb-3">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.dia.sts' | translate }}
            </strong>
        </div>
        <div class="col-md-3">
            <app-select
                id="status"
                [fControl]="formGroup.get('status')"
                [values]="selectionValuesFromEntity(statuses)"
            ></app-select>
        </div>
    </div>
</div>