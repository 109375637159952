angular.module('erx.utils').service('$erxShortcutCustomization', ["$modal", "$rootScope", "$q", "gettextCatalog", function ($modal, $rootScope, $q, gettextCatalog) {
  var scope = $rootScope.$new();
  var deferred;
  scope.data = {};

  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-shortcut-customization/erxShortcutCustomization.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (opts, onSave, afterHide) {
    scope.title = opts.title ? opts.title : gettextCatalog.getString('Suflerio nustatymai');
    scope.shortcutData = opts.data;
    scope.accountSettings = opts.selectedData;
    scope.selectedDosageType = scope.accountSettings['shortcuts_dosage_type'];
    scope.selectedShortcuts = scope.selectedDosageType !== undefined ? scope.selectedDosageType : [];
    scope.selectedShortcuts.forEach(shortcut => scope.data[shortcut.id] = shortcut);
    scope.onSave = onSave;
    scope.afterHide = afterHide;

    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };

  scope.saveShortcuts = function() {
    let dataToSave = Object.assign(scope.accountSettings, {"shortcuts_dosage_type": Object.values(scope.data).map((d: any) => {
      d.title = d.field.displayValue;
      return d;
      })
    });
    scope.onSave(dataToSave).$promise.then(function (res) {
      scope.afterHide();
    });
  }

  return confirm;
}]);