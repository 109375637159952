import {Component, DestroyRef, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { ApiV2Documents } from "src/api/api-v2-documents";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, debounceTime, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { OrganizationModel } from "src/app/shared/models/organization.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import { ApiV2Organizations } from "src/api/api-v2-organizations";
import { EntityModel } from "src/app/shared/models/entity.model";
import { ApiV2Entities } from "src/app/shared/services/api-v2-entities";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { PatientModel } from "src/app/shared/models/patient.model";

@Component({
    selector: 'part-medical-modal',
    templateUrl: './partMedicalModal.component.html'
})
export class PartMedicalModalComponent implements OnInit {
    modalMode!: string;
    @Input() practitionersData: any[] = [];
    @Output() onClose = new EventEmitter<any[]>();
    selectAll = false;
    form: FormGroup;
    currentDate = new Date();
    twoYearsFromNow: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
    suggestionsOrg$?: any;
    suggestionsPract$: any;
    noResult = false;
    organizationArray: OrganizationModel[];
    practitionerArray: PractitionerModel[];
    suggestions$: Observable<EntityModel[]>;
    count = 10;
    fullDiagnosisNameLength = 0;
    fullSpecialistNameLength = 0;
    dataReceived = false;
    private lastRequestTime: number = 0;


    constructor(private formBuilder: FormBuilder, public apiV2Documents: ApiV2Documents, private modalRef: BsModalRef, private apiV2Practitioners: ApiV2Practitioners,
        private destroyRef: DestroyRef, private apiV2Entities: ApiV2Entities) {
        this.form = this.formBuilder.group({
            unsorted_document_type: [],
            diagnosis: [],
            diagnosis1: '',
            specialist: [],
            specialist1: '',
            dateFrom: [],
            dateTo: []
        });
   }

    ngOnInit(): void {
        this.fetchFilteredData();
        this.listenDiagnoseSearchEvent();
        this.getPractitionerList();
        this.setupFormValueChanges();
    }

    private setupFormValueChanges(): void {
        this.form.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(() => {
            this.fetchFilteredData();
        });
    }

   fetchFilteredData(): void {

        const currentTime = Date.now();
        this.lastRequestTime = currentTime;

        const dateFrom = this.form.value.dateFrom;
        const dateTo = this.form.value.dateTo;
        const diagnosis = this.form.value.diagnosis;
        const specialist = this.form.value.specialist;
        const docTypes = ['e025', 'e027', 'e027a', 'e003'];

        this.apiV2Documents.getForList(dateFrom, dateTo, diagnosis, specialist, docTypes).subscribe(data => {
            if (currentTime === this.lastRequestTime) {
                if(this.modalMode === 'complaint'){
                    this.practitionersData = data.filter(card => card.additionalData.recommendationForTreatmentNotes !== '–');
                    this.dataReceived = true;
                }
                if(this.modalMode === 'anamnesis'){
                        this.practitionersData = data.filter(card => card.additionalData.medicalDataAnamnesis !== '–');
                        this.dataReceived = true;
                    }
            }
        });
    }

    private listenDiagnoseSearchEvent(): void {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.form.get('diagnosis1').value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                const request = !query
                    ? this.apiV2Entities.getMostUsedDiagnoses({count: 10})
                    : this.apiV2Entities.getEntitiesList('tlk-10-am', {searchCriteria: query});

                return request.pipe(map(items => items.map(p => {
                    p['displayValue'] = `${p.code} ${p.name}`;
                    return p;
                })))
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    getPractitionerList(){
        this.suggestionsPract$ = new Observable((observer: Observer<string | undefined>) => {
          observer.next(this.form.get('specialist1').value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners.find({ q: query })
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(psm => ({
                            ...psm,
                            givenNameAndFamilyName: psm.givenName + " " + psm.familyName
                        })) || []));
                }
                return of([]);
            })
        );
    }

    includeSelectedComplaints(): void {
        if(this.modalMode == 'complaint'){
            const selectedComplaints = this.practitionersData.filter(card => card.selected);
            this.onClose.emit(selectedComplaints.map(c => c.additionalData.recommendationForTreatmentNotes));
            this.close();
        } else if(this.modalMode == 'anamnesis'){
            const selectedAnamnesis = this.practitionersData.filter(card => card.selected);
            this.onClose.emit(selectedAnamnesis.map(c => c.additionalData.medicalDataAnamnesis));
            this.close();
        }
    }

    toggleSelectAll(): void {
        this.practitionersData.forEach(card => {
            card.selected = this.selectAll;
        });
    }

    onCardSelectionChange(): void {
        this.selectAll = this.practitionersData.every(card => card.selected);
    }

    close(): void {
        this.modalRef.hide();
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    // retrieves selected organization object and get its id
    typeaheadOnSelect1(e: TypeaheadMatch): void {
      this.form.controls['diagnosis'].setValue(e.item.id);
      this.fullDiagnosisNameLength = e.item.name.length + e.item.code.length + 1; // need +1 because of space between name and code
    }

    onTypeaheadInput1(event: Event): void {
        const inputElement = event.target as HTMLInputElement;

        if (inputElement.value.length < this.fullDiagnosisNameLength) {
            this.form.controls['diagnosis1'].setValue('');
            this.form.controls['diagnosis'].setValue(null);
            this.fullDiagnosisNameLength = 0;
        } else {
            this.fullDiagnosisNameLength = inputElement.value.length;
        }
    }

    // retrieves selected practitioner object and get its id
    typeaheadOnSelect2(e: TypeaheadMatch): void {
      this.form.controls['specialist'].setValue(e.item.id);
      this.fullSpecialistNameLength = e.item.givenName.length + e.item.familyName.length + 1; // need +1 because of space between givenName and familyName
    }

    onTypeaheadInput2(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.value.length < this.fullSpecialistNameLength) {
            this.form.controls['specialist1'].setValue('');
            this.form.controls['specialist'].setValue(null);
            this.fullSpecialistNameLength = 0;
        } else {
            this.fullSpecialistNameLength = inputElement.value.length;
        }
    }
  }