import { Component, OnInit } from "@angular/core";
import { PortalTypeEnum } from "src/app/shared/models/portalType.enum";
import {RouteParams} from "../../../../ng1.routeParams";

@Component({
    selector: 'dp-doc-ref-attached-unsorted-list',
    templateUrl: './dp-doc-ref-attached-unsorted-list.component.html'
})
export class DpDocRefAttachedUnsortedListComponent implements OnInit {

    portalType: PortalTypeEnum = PortalTypeEnum.DP;
    patientId: string;

    constructor(
        // private route: ActivatedRoute
        private params: RouteParams
    ) {
   }

    ngOnInit(): void {
        this.getPatientId();
    }

    getPatientId(){
        // this.patientId = this.route.parent.snapshot.paramMap.get('id');
        this.patientId = this.params.id;
      }
}