ng1App.controller('doctor.patient.erx_pr.SignCallbackCtrl', 
    ['$scope', '$routeParams', 'ErxPaths', '$location', '$window',
     function ($scope, $routeParams, ErxPaths, $location, $window) {

    $scope.options = {
        // the prescription we just tried to sign id
        prescriptionId: $routeParams.prescriptionId,
        // this is where the gosign iframe should redirect to after finishing the document signing stuff
        // since it's called from within an iframe, this example changes $window.parent.location,
        // but it's up to you how you want to do it
        // page should have hideDocInfo set to false
        redirectToViewPrsFormWithStatus: function(status) {
            //if doctor-portal is opened inside integrated-component then at this point a message should be sent
            //to the integrated-component host with prescription id and sign status.
            //this should be done as: $window.parent.parentIFrame.sendMessage(...) because there are two iframes
            // 'parentIFrame' object is provided by iframe-resizer library used for integrated component
            // https://github.com/davidjbradshaw/iframe-resizer
            if('parentIFrame' in $window.parent) {
                $window.parent.parentIFrame.sendMessage({prescriptionId: $routeParams.prescriptionId, signStatus: status});
            }

            $window.parent.location = ErxPaths.viewDoc($routeParams.prescriptionId)+'&signStatus='+status;
        },
        redirectToPrsListWithStatus: function(status) {
            if('parentIFrame' in $window.parent) {
                $window.parent.parentIFrame.sendMessage({prescriptionId: $routeParams.prescriptionId, signStatus: status});
            }
            $window.parent.location = ErxPaths.list(status);
        }
    };


}]);