ng1App.controller('doctor.patient.SummaryHistoryCtrl',
        ['$scope', '$routeParams', '$filter', 'MessageService', 'PatientsApiFactory', 'DocumentsApiFactory', 'FormLoaderService', 'PatientSummaryApiFactory', 'PatientInsuranceService',
        function ($scope, $routeParams, $filter, MessageService, PatientsApiFactory, DocumentsApiFactory, FormLoaderService, PatientSummaryApiFactory, PatientInsuranceService) {
     
    // Side content list
     $scope._shared = {
        contentList: [
            {
                title: $filter('translate')('dpp.pat.sum.oth'), 
                id:'dpp_pat_sum_oth'
            },
            {
                title: $filter('translate')('dpp.pat.sum.pat'), 
                id:'dpp_pat_sum_pat'
            },
            {
                title: $filter('translate')('dpp.pat.sum.rep'), 
                id:'dpp_pat_sum_rep',
                show: function () {
                    if ($scope.summary.relatedPersons && $scope.summary.relatedPersons.length > 0){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.all'), 
                id:'dpp_pat_sum_all',
                show: function () {
                    if ($scope.summary.allergies){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.nreg.all'), 
                id:'dpp_pat_sum_nreg_all',
                show: function () {
                    if ($scope.summary.remarks && $scope.summary.remarks.allergiesRemarks){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.dia.new'), 
                id:'dpp_pat_sum_dia_new',
                show: function () {
                    if ($scope.summary.currentConditions){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.dia.new'), 
                id:'dpp_pat_sum_dia_new',
                show: function () {
                    if ($scope.summary.currentConditions){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.nreg.dia'), 
                id:'dpp_pat_sum_nreg_dia',
                show: function () {
                    if ( $scope.summary.remarks && $scope.summary.remarks.currentDiseasesRemarks){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.dia.old'), 
                id:'dpp_pat_sum_dia_old',
                show: function () {
                    if ($scope.summary.lastConditions){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
             {
                title: $filter('translate')('dpp.pat.sum.nreg.las.con'), 
                id:'dpp_pat_sum_nreg_las_con',
                show: function () {
                    if ($scope.summary.remarks && $scope.summary.remarks.pastDiseasesRemarks){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.sur.new'), 
                id:'dpp_pat_sum_sur_new',
                show: function () {
                    if ($scope.summary.proceduresIn6Months){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.sur.old'), 
                id:'dpp_pat_sum_sur_old',
                show: function () {
                    if ($scope.summary.proceduresBefore6Months){
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.phy'), 
                id:'dpp_pat_sum_phy',
                show: function () {
                    if ( $scope.summary.medicalData) {
                        return true;
                    } else {
                        return false;
                    }
                
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.rec'), 
                id:'dpp_pat_sum_rec',
                show: function () {
                    if ( $scope.summary.recomendation ) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.vac'), 
                id:'dpp_pat_sum_vac',
                show: function () {
                    if ( $scope.summary.vaccinations ) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.notr.vacc'), 
                id:'dpp_pat_notr_vacc',
                show: function () {
                    if ( $scope.summary.remarks && $scope.summary.remarks.vaccinationsRemarks ) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            {
                title: $filter('translate')('dpp.pat.sum.vac.med'), 
                id:'dpp_pat_sum_vac_med'
            }
        ]
     };
            MessageService.clear();
            MessageService.clear('bad-habits');
            MessageService.clear('implants');
            $scope.patientId = $routeParams.patientId;

            $scope.goBack = function () {
                window.history.back();
            };

    $scope.partialPath = function (file) {
        return '/templates/portal/common/patient_info/partials/summary/' + file;
    };

            $scope.checkInsurance = function () {
                $scope.insuranceLoading = true;
                PatientInsuranceService.getInsurance($scope.summary.patient.id, true)
                    .then(function (data) {
                        if (!$scope.summary.encounter) {
                            $scope.summary.encounter = {insuranceData: {}};
                        }
                        $scope.summary.encounter.insuranceData.insurancePSD = data;
                    })
                    .finally(function(){
                        $scope.insuranceLoading = false;
                    });
            };

            $scope.reload = function () {
                FormLoaderService.startFormLoading();
                $scope.summary = PatientSummaryApiFactory.getSummaryHistory({patientId: $scope.patientId, historyId: $routeParams.historyId}, function (data) {
                    $scope.checkInsurance();

                    DocumentsApiFactory.documentHistory({compositionId: data.id}, function (data) {
                        angular.forEach(data.items, function (i) {
                            if (i.historyId == $routeParams.historyId) {
                                $scope.itemType = i.type;
                            }
                        });
                    });
                });
                
                $scope.summary.$promise.finally(function (){
                   FormLoaderService.endFormLoading(); 
                });
            };

            $scope.reload();

            $scope.twoColumns = function () {
                var need2 = $filter('isNotEmpty')($scope.summary.implants)
                        || $filter('isNotEmpty')($scope.summary.badHabits)
                        || $filter('isNotEmpty')($scope.summary.planedBirthDate)
                        || $filter('isNotEmpty')($scope.summary.disabilityData)
                        || $filter('isNotEmpty')($scope.summary.bloodGroup);

                return need2 ? true : false;
            };
        }]);