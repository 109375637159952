angular.module('erx.utils').service('$erxModalAnalyticalInfoByOrg', ["$modal", "$rootScope", "ErxAnalyticsApiFactory", function ($modal, $rootScope, ErxAnalyticsApiFactory) {
  var scope = $rootScope.$new();
  scope.data = {};

  scope.organizations = [];

  scope.groupByCategories = [
    {code: 'DAY', displayValue: 'Dieną'},
    {code: 'MONTH', displayValue: 'Mėnesį'},
    {code: 'QUARTER', displayValue: 'Metų ketvirtį'},
    {code: 'HALF', displayValue: 'Pusmetį'},
    {code: 'YEAR', displayValue: 'Metus'},
  ];

  scope.getOrganizations = function (viewValue) {
    scope.orgsLoading = true;
    ErxAnalyticsApiFactory.getOrganizations({
      query: viewValue ? viewValue : '',
      count: 10
    }).$promise.then(function (res) {
      for (var i = 0; i < res.length; i++) {
        res[i]['label'] = res[i]['name'];
      }
      scope.organizations = res;
    }).finally(function () {
      scope.orgsLoading = false;
    });
  }

  scope.search = function () {
    scope.currentGroupBy = scope.data.groupBy;
    scope.entries = ErxAnalyticsApiFactory.getPrescriptionsByOrganization(
            {
              organizationId: scope.data.organization.id,
              groupBy: scope.data.groupBy,
              dateFrom: scope.data.dateFrom,
              dateTo: scope.data.dateTo
            });
  }


  var modal = $modal({template: 'modules/erx-utils/components/erx-modal-analytical-info-by-org/erxModalAnalyticalInfoByOrg.tpl.html', scope: scope, show: false});
  var parentShow = modal.show;

  modal.show = function (opts) {

    parentShow();
    return;
  };

  return modal;
}]);