ng1App.factory('AlertsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/alerts';
    return $resource('', {}, {
        getComments: {
            method: 'GET',
            url: URL_BASE + '/comments',
            isArray: false
        },
        getCommentsPermissions: {
            method: 'GET',
            url: URL_BASE + '/commentsPermissions',
            isArray: true
        },
        getCommentsVisibilities: {
            method: 'GET',
            url: URL_BASE + '/commentsVisibilities',
            isArray: true
        },
        addComment: {
            method: 'POST',
            url: URL_BASE + '/addComment'
        },
        cancelComment: {
            method: 'POST',
            url: URL_BASE + '/:alertId/cancel'
        },
        prefillMessage: {
            method: 'GET',
            url: URL_BASE + '/prefillMessage'
        },
        sendMessage: {
            method: 'POST',
            url: URL_BASE + '/sendMessage'
        },
        replyMessage: {
            method: 'POST',
            url: URL_BASE + '/replyMessage'
        },
        searchMessages: {
            method: 'GET',
            url: URL_BASE + '/searchMessages'
        },
        getThread: {
            method: 'GET',
            url: URL_BASE + '/getThread',
            isArray: true
        },
        deleteMessages: {
            method: 'POST',
            url: URL_BASE + '/deleteMessages'
        },
        getUnreadCount: {
            method: 'GET',
            url: URL_BASE + '/getUnreadCount',
            isArray: false
        }
    });
}]
);