angular.module('medvais.utils').directive('medvaisInputDate',function(){
    return {
        templateUrl: 'modules/medvais-utils/components/medvais-input-date.html',
        restrict: 'E', replace: true, scope: true, transclude: true,
        link: function (scope, element, attrs) {
        }
    }

});

