<app-title showFilterButton="true">
    {{'comp.docRefConsentTemplate.list.title'| translate}}
    <aside>
        <button class="btn btn-primary" type="button" *ngIf="canCreate()"
            (click)="createDocRefConsTemplate()">{{'comp.docRefConsentTemplate.list.new' | translate}}</button>
    </aside>
</app-title>
<div class="page-list list-group mb-2">
    <custom-search [name]="'quick-search'" [isAdvanced]="true" (filterService)="loadDocRefConsentTemplatesList($event)">
        <doc-ref-consent-template-filter (onSearch)="loadDocRefConsentTemplatesList($event)"></doc-ref-consent-template-filter>
    </custom-search>
    <doc-ref-consent-card
            *ngFor="let docRefConsentTemplate of docRefConsentTemplates"
            [data]="docRefConsentTemplate"
            [onCardClick]="onCardClick"
            [onEditClick]="onEditClick"
            [portalType]="portalType"
            [ngClass]="'list-group-item'"
            role="button"
    />
</div>
<div *ngIf="loadMoreActive" class="container d-flex justify-content-center align-items-center">
    <div class="row mb-20">
        <load-more-button (click)="loadMore()" [working]="working"/>
    </div>
</div>
