<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e010_1_1.formRiskFactors.title' | translate"
>
    <div class="card-body">
        <div class="row">
            <div class="col-12 mb-3" *ngFor="let item of risksData">
                <div class="col-auto fw-bold">{{item.name | translate}}</div>
                <ul>
                    <li *ngFor="let risk of item.value">{{risk}}</li>
                </ul>
            </div>
        </div>
    </div>
</nhr-record-widget>