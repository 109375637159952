import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-cardio-system-form',
    templateUrl: './sbvf-cardio-system-form.component.html',
})
export class SbvfCardioSystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        this._viewMode = value;
        if (value) {
            this.formGroup.disable();
        }
    };

    cardioSystemRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        chestPain: this.fb.control(null, [Validators.required]),
        chestPainScale: this.fb.control({value:null, disabled: true}),
        natureOfPain: this.fb.control(null, [Validators.required]),
        heartPalpitation: this.fb.control(null, [Validators.required]),
        timeOccurrenceHearthPalpitation: this.fb.control(null, [Validators.required]),
        headache: this.fb.control(null, [Validators.required]),
        headacheScale: this.fb.control({value:null, disabled: true}),
        painTime: this.fb.control(null, [Validators.required]),
        natureOfHeadache: this.fb.control(null, [Validators.required]),
        headDizziness: this.fb.control(null, [Validators.required]),
        dizzyTime: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });

    private _viewMode: boolean;
    constructor(
        private readonly fb: FormBuilder
    ) {
        this.formGroup.get('chestPain').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('chestPainScale').disable();
                    return;
                }
                if (value === '0') {
                    this.formGroup.get('chestPainScale').enable();
                } else {
                    this.formGroup.get('chestPainScale').reset();
                    this.formGroup.get('chestPainScale').disable();
                }
            });
        this.formGroup.get('headache').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('headacheScale').disable();
                    return;
                }
                if (value === '0') {
                    this.formGroup.get('headacheScale').enable();
                } else {
                    this.formGroup.get('headacheScale').reset();
                    this.formGroup.get('headacheScale').disable();
                }
            });
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.cardioSystemRows = [
            {
                title: 'Krūtinės ląstos skausmas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('chestPain')
            },
            {
                title: 'Skausmo skalė',
                type: 'choise',
                options: value['scale'],
                customClass: 'w-auto',
                formControl: this.formGroup.get('chestPainScale')
            },
            {
                title: 'Skausmo pobūdis',
                type: 'choise',
                options: value['natureOfPain'],
                formControl: this.formGroup.get('natureOfPain')
            },
            {
                title: 'Širdies plakimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('heartPalpitation')
            },
            {
                title: 'Širdies plakimo pasireiškimo laikas',
                type: 'choise',
                options: value['timeHeartPalpitations'],
                formControl: this.formGroup.get('timeOccurrenceHearthPalpitation')
            },
            {
                title: 'Galvos skausmai',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('headache')
            },
            {
                title: 'Skausmo skalė',
                type: 'choise',
                options: value['scale'],
                customClass: 'w-auto',
                formControl: this.formGroup.get('headacheScale')
            },
            {
                title: 'Skausmo laikas',
                type: 'choise',
                options: value['timeHeartPalpitations'],
                formControl: this.formGroup.get('painTime')
            },
            {
                title: 'Galvos skausmo pobūdis',
                type: 'choise',
                options: value['natureOfPain'],
                formControl: this.formGroup.get('natureOfHeadache')
            },
            {
                title: 'Galvos svaigimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('headDizziness')
            },
            {
                title: 'Svaigimo laikas',
                type: 'choise',
                options: value['dizzyTime'],
                formControl: this.formGroup.get('dizzyTime')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
