<nhr-record-widget
    [iconBgClass]="'dark'"
    [iconClass]="'fa-list'"
    [closable]="true"
    [closed]="true"
    [mixed]="true"
    [bodyClass]="'widget-body'"
    class="form-horizontal"
    [title]="'doc.encounterComponent.title' | translate"
>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label">{{ "doc.encounterComponent.title" | translate }}</strong>
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext">
                <span>{{ encounterData?.arrivalDate | date : "yyyy-MM-dd hh:mm" }}</span>
            </p>
        </div>
    </div>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label">{{ "doc.encounterComponent.typeOfSupport" | translate }}</strong>
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext">
                <span>{{ encounterData?.serviceType.name }}</span>
            </p>
        </div>
    </div>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label">{{ "doc.encounterComponent.medicalHistoryNumber" | translate }}</strong>
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext">
                <span>{{encounterData?.esiNumber | translate}}</span>
            </p>
        </div>
    </div>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label">{{ "doc.encounterComponent.consultationNature" | translate }}</strong>
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext">
                <span>{{ encounterData?.consultationNature?.name }}</span>
            </p>
        </div>
    </div>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label pt-0">
            <p class="form-control-plaintext pb-0">
                <span>{{ "doc.encounterComponent.prohibition" | translate }}</span>
            </p>
            <a href="/update">{{'doc.encounterComponent.update' | translate}}</a></strong
        >
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext pb-0">
                <span class="badge bg-danger rounded-5">{{ encounterData?.insuranceData.insurancePSD.assertStatus | titlecase }}</span>
            </p>
            <span>Tikrinta: {{ encounterData?.insuranceData.insurancePSD.assertionDate | date : "yyyy-MM-dd hh:mm" }}</span>
        </div>
    </div>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label">{{ "doc.encounterComponent.institutionReffering" | translate }}</strong>
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext">
                {{getEncounterData()}}
            </p>
        </div>
    </div>
    <div class="form-group mb-0">
        <strong class="col-md-4 col-lg-3 col-form-label">{{ "doc.encounterComponent.shippingDetails" | translate }}</strong>
        <div class="col-md-8 col-lg-9">
            <p class="form-control-plaintext">
                <span>{{ encounterData?.shippingDetails?.code }} {{ encounterData?.shippingDetails?.name }}</span>
            </p>
        </div>
    </div>
</nhr-record-widget>
