ng1App.controller('doctor.patient.CertificatesDraftViewCtrl', 
    ['$scope', '$routeParams', 'CertificatesApiFactory', 'CertificateService', '$window', '$location', 'DocumentSpinnerHelper', 'MessageService', '$filter', '$modal', '$log', 'CertificatesViewService', 'DraftsApiFactory', '$modalConfirm',
     function ($scope, $routeParams, CertificatesApiFactory, CertificateService,
                                                                        $window, $location, DocumentSpinnerHelper, MessageService, $filter, $modal, $log, CertificatesViewService, DraftsApiFactory, $modalConfirm) {
    
    $scope.$emit('setHelpPage', 'perzireti-elektronini-dokumenta');
    
	MessageService.clearAll();
        MessageService.showSuccessEventMessage();
        MessageService.unregisterSuccessEvent();
        
	DocumentSpinnerHelper.reset();

	$scope.certificate = DraftsApiFactory.getDraftForPreview({id: $routeParams.id}, function (data) {
    	CertificateService.populateCommonData($scope, data);
    	
    	// Additional data
		CertificatesViewService.fillDataByType($scope, data.type, $routeParams.patientId, $routeParams.id);
    });
    
    $scope.goBack = function () {
//    	$window.history.back();
    	// For now return to records
    	$location.path('/dp/patients/' + $routeParams.patientId + '/records').search({});
    };
    
    $scope.goToEdit = function () {
		$location.path('/dp/patients/' + $routeParams.patientId + '/certificates/drafts/'+ $routeParams.id+'/edit').search({});
    };

	$scope.cancel = function(){
		$modalConfirm.show({
			content: 'Ar tikrai norite pašalinti juodraštį?'
		}).then(function () {
			DraftsApiFactory.invalidateDraft({id: $routeParams.id});
			MessageService.registerSuccessEvent();
			$scope.goBack();
			MessageService.registerCancellationSuccessEvent();
		});
	}
    
}]);