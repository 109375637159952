import { Component, Input, OnChanges } from '@angular/core';
import moment from 'moment';
import { E010_RowsGroup } from '../../../e010-forms/e010-forms.model';

@Component({
    selector: 'app-e010-2-1-form-newborn',
    templateUrl: './e010-2-1-form-newborn.component.html',
})
export class E01021FormNewbornComponent implements OnChanges {
    @Input() fruitIndex: number;
    @Input() data: {[key: string]: any}

    fruitsRows: E010_RowsGroup[] = [];

    constructor() {}

    ngOnChanges() {
        if(!this.data || (!this.fruitIndex && this.fruitIndex !== 0)) return;
        this.generateData(this.data, this.fruitIndex);
    }

    private generateData(value: any, i: number) {
        const generalData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.fruitsNumber',
                boldTitle: true,
                value: value.e096N.generalData.numberOfFruits
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.fruitsRaised',
                boldTitle: true,
                value: value.e096N.fruits[i].fruit.charCodeAt(0) - 64
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.dateOfBirth',
                boldTitle: true,
                value: value.e096N.fruits[i].birthTime
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.gender',
                boldTitle: true,
                value: value.e096N.fruits[i].gender
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.weight',
                boldTitle: true,
                value: value.e096N.fruits[i].weight
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.height',
                boldTitle: true,
                value: value.e096N.fruits[i].height
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.headCircumference',
                boldTitle: true,
                value: value.e096N.fruits[i].headCircumference
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.gestationalAge',
                boldTitle: true,
                value: moment(value.e096N.fruits[i].birthTime).diff(moment(value.ngn.firstPregnancyDay), 'weeks')
            }
        ];

        this.fruitsRows = [{
            rows: generalData
        }]
    }
}
