import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";
import {MaterialDataModel} from "../app/shared/models/material-data.model";

@Injectable({
    providedIn: 'root'
})
export class ApiV2ErxMedications extends ConfigService {
    path = this.apiUrl + '/erx/doctor/Medication';

    getMedications(params: any): Observable<any[]> {
        const url = `${this.path}/search`;
        return this.http.get<any[]>(url, {params: params});
    }

    getAllMedications(params: any): Observable<any[]> {
        const url = `${this.path}/search/all`;
        return this.http.get<any[]>(url, {params: params});
    }

    getByRefFromCatalogue(ref: string): Observable<any> {
        const url = `${this.path}/catalogue`;
        return this.http.get<any>(url, {params: {ref: ref}});
    }

    getMedicationMaterials(query?: any): Observable<MaterialDataModel[]> {
        const url = `${this.path}/material-document`;
        return this.http.get<MaterialDataModel[]>(url, {params: query});
    }
}