<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formVacuumExtractor.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto req">
            <div class="form-group col-6 flex-shrink-1">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                            {{'doc.pregnancyDashboard.e096Ptkl.formVacuumExtractor.useVE' | translate}}:
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of useVacuumExtractionOptions; let i = index"
                        radioName="useVacuumExtraction-{{i}}"
                        [fControl]="useVacuumExtractionControl"
                        [radioValue]="option.name"
                        id="useVacuumExtraction-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="useVacuumExtractionControl" />
            </div>
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.startProcedure' | translate}}:"
                inputId="startOfProcedureVE"
                class="form-group col-4 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                labelClass="norq"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('startOfProcedure')"
            ></app-date-picker>
        </div>
        <div class="row mx-auto req">
            <div class="form-group col-12 col-lg-6">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ptkl.formVacuumExtractor.indicationForUseVE' | translate}}:
                </span>
                <div class="row col-auto">
                    <app-checkbox
                        *ngFor="let option of answersVariants?.['indicationForUseVacuumExtraction']; let i = index"
                        inputId="indicationForVacuumExtraction{{i}}"
                        class="mt-1"
                        formClass="mb-0"
                        [labelText]="option.name"
                        [checkboxValue]="option.name"
                        [checkboxDisabled]="!(editable && !controlDisabled('indicationForVacuumExtraction'))"
                        [checkboxChecked]="checkboxIsChecked(option.name, 'indicationForVacuumExtraction')"
                        (checkboxChange)="updateControl(option.name, 'indicationForVacuumExtraction')"
                    ></app-checkbox>
                </div>
            </div>
            <div class="form-group col-12 col-lg-6">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ptkl.formVacuumExtractor.conditionForUseVE' | translate}}:
                </span>
                <div class="row col-auto">
                    <app-checkbox
                        *ngFor="let option of answersVariants?.['conditionsForUseVacuumExtraction']; let i = index"
                        inputId="conditionsForVacuumExtraction{{i}}"
                        class="mt-1"
                        formClass="mb-0"
                        [labelText]="option.name"
                        [checkboxValue]="option.name"
                        [checkboxDisabled]="!(editable && !controlDisabled('conditionsForVacuumExtraction'))"
                        [checkboxChecked]="checkboxIsChecked(option.name, 'conditionsForVacuumExtraction')"
                        (checkboxChange)="updateControl(option.name, 'conditionsForVacuumExtraction')"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="row mx-auto req">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ptkl.formVacuumExtractor.result' | translate}}:
                </span>

                <div class="row align-items-baseline" *ngFor="let option of answersVariants?.['actionsPerformedWithVacuumExtraction']; let i = index">
                    <div class="col-12 ms-auto">
                        <app-checkbox
                            inputId="actionsTakenWithVacuumExtraction{{i}}"
                            class="mt-1"
                            formClass="mb-0 col-auto"
                            [labelText]="option.name"
                            [checkboxValue]="option.name"
                            [checkboxDisabled]="!(editable && !controlDisabled('actionsTakenWithVacuumExtraction'))"
                            [checkboxChecked]="checkboxIsChecked(option.name, 'actionsTakenWithVacuumExtraction')"
                            (checkboxChange)="updateParentControl(option, 'actionsTakenWithVacuumExtraction')"
                        ></app-checkbox>
                        <div
                            *ngIf="option.result?.length"
                            class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap"
                            [class.d-none]="!checkboxIsChecked(option.name, 'actionsTakenWithVacuumExtraction')"
                        >
                            <app-checkbox
                                *ngFor="let childValue of option.result; let j = index"
                                inputId="{{generateChildControlName(option.name)}}VE{{j}}"
                                class="mt-1 me-3"
                                formClass="mb-0"
                                [labelText]="childValue.name"
                                [checkboxValue]="childValue.name"
                                [checkboxDisabled]="!(editable && checkboxIsChecked(option.name, 'actionsTakenWithVacuumExtraction') && !controlDisabled('actionsTakenWithVacuumExtraction'))"
                                [checkboxChecked]="checkboxIsChecked(childValue.name, generateChildControlName(option.name))"
                                (checkboxChange)="updateControl(childValue.name, generateChildControlName(option.name))"
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-auto req">
            <app-date-picker
                labelText="doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.endProcedure"
                labelClass="norq"
                inputId="endOfProcedureVE"
                class="form-group col-4 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [minDate]="formGroup.get('startOfProcedure')?.value"
                [fControl]="formGroup.get('endOfProcedure')"
            ></app-date-picker>
            <app-input
                labelText="Notes"
                labelClass="norq"
                class="form-group col-8 flex-shrink-1"
                type="textarea"
                [fControl]="formGroup.get('notes')"
            ></app-input>
        </div>

    </div>
    <div class="card-footer">
        <span class="fw-bolder">
            {{'doc.pregnancyDashboard.e096Ptkl.performedProcedure' | translate}}
        </span>
        <div class="col-12 col-lg-6 mt-2">
            <app-find-specialists
                [editable]="editable"
                [customDisplaying]="false"
                [formArray]="formGroup?.get('performedProcedure')"
            ></app-find-specialists>
        </div>
    </div>
</nhr-record-widget>