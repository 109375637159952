import { Component } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-agGrid-select-fg-cell",
    template: `<select
        *ngIf="formControl"
        class="form-select"
        [id]="key"
        [formControl]="formControl"
        [ngClass]="{ 'is-invalid': formControl?.invalid }"
    >
        <option value="">--</option>
        <option value="T">Atlikta</option>
        <option value="N">Neatlikta</option>
    </select>`,
})
export class AgGridSelectFgCell {
    formControl: FormControl;
    key;
    private value;
    private rowIndex: number;
    private formControlName: string;

    agInit(params: any) {
        this.key = params.column.colDef.headerName + "_" + params.rowIndex;
        this.value = params.value;
        this.rowIndex = params.rowIndex;
        this.formControlName = params.context.formControlNameSelect;
    }

    refresh(params: any): boolean {
        this.formControl = params.context.formGroupArray.at(this.rowIndex).get(this.formControlName);
        this.formControl.patchValue(this.value);
        return true;
    }
}
