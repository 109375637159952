ng1App.factory('VlkFormCreationHelper', 
    ['$rootScope', '$modal', '$filter', 'PatientsApiFactory', 'SveidraApiFactory',
     function ($rootScope, $modal, $filter, PatientsApiFactory, SveidraApiFactory) {
	var PAGE_SIZE = 7;
	
	var modal = null;
	var scope = $rootScope.$new();
	
	// Form type selection
	scope.formType = null;
	scope.documentType = null;
	
	scope.selectType = function (formType, documentType) {
		scope.formType = formType;
		scope.documentType = documentType;
		formDocumentFilter();
		if (scope.formType) {
			modal.hide();
			initDocumentModal();
			modal.load();
		}
	};
	// --------------------------
	
	// Document selection
	scope.documentList = [];
	scope.hasMore = false;
	scope.selectedDocumentId = null;

	var documentFilter: any = {};
	
	var initDocumentModal = function () {
		modal = $modal({
			scope: scope,
			title: $filter('translate')('mod.dpp.sel.doc.typ'),
			contentTemplate: '/templates/portal/doctor/patient/aLK_forms/select_document.html',
			size: 'lg'
		});
		modal.load = function () {
			modal.$promise.then(function () {
				loadData(true);
				modal.show();
			});
		};
	};
	
	var formDocumentFilter = function () {
		if (scope.formType == '070alk') {
			documentFilter.formType = ['FORM_F025', 'FORM_F066'];
		} else {
			documentFilter.docType = scope.formType == 'f025alk' ? 'e025' : 'e003';
		}
		documentFilter.page = 1;
		documentFilter.count = PAGE_SIZE;
	};
	
	var loadData = function (reset) {
		if (reset) {
			scope.documentList = [];
		}
		if (scope.formType == '070alk') {
			SveidraApiFactory.getList(documentFilter, function (result) {
				scope.documentList = scope.documentList.concat(result.items);
				scope.hasMore = result.items.length == PAGE_SIZE;
			});
		} else {
			PatientsApiFactory.getDocumentsForSveidra(documentFilter, function (result) {
				scope.documentList = scope.documentList.concat(result);
				scope.hasMore = result.length == PAGE_SIZE;
			});
		}
	};
	
	scope.loadMore = function () {
		documentFilter.page = documentFilter.page + 1;
		loadData(false);
	};
	
	scope.selectDocument = function () {
		if (scope.selectedDocumentId) {
			modal.hide();
			scope.callback(scope.selectedDocumentId, scope.formType);
		}
	};
	
	scope.changeDocumentSelection = function (id) {
		scope.selectedDocumentId = id;
	};
	
	scope.textSearch = function (text) {
		documentFilter.ftq = text;
		documentFilter.page = 1;
		loadData(true);
	};
	// --------------------------
	
	return {
		getModal: function (patientId, callback) {
			documentFilter.patientId = patientId;
			
			scope.patientId = patientId;
			scope.callback = callback;
			scope.selectedDocumentId = null;
			
			modal = $modal({
                scope: scope,
                title: $filter('translate')('mod.dpp.sel.for.tit'),
                contentTemplate: '/templates/portal/doctor/patient/aLK_forms/select_form_type.html',
								size: 'lg'
            });
			return modal;
		},
		
		getDocumentSelectionModal: function (patientId, formType, callback, overlook) {
			documentFilter.patientId = patientId;
			documentFilter.overlook = overlook;
			
			scope.patientId = patientId;
			scope.callback = callback;
			scope.formType = formType;
			scope.selectedDocumentId = null;
			
			formDocumentFilter();
			initDocumentModal();
			
			return modal;
		}
	};
}]);