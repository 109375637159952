ng1App.controller('doctor.patient.DashboardCtrl',
    ['$scope', '$window', '$location', '$filter', '$modalConfirm', '$routeParams', '$modal', 'ApiV2PatientSummary', 'DpPatientContext', 'PatientsApiFactory', 'DgcApiFactory', 'MessageService', 'EncountersApiFactory', 'TasksApiFactory', '$route', '$routeSegment', 'DocumentsApiFactory', 'DraftsApiFactory', 'PersonRepresentationsApiFactory', 'UnfinishedRecordsApiFactory', 'SveidraApiFactory', 'PatientSummaryApiFactory', 'GpApiFactory', 'WarRefugeeApiFactory', 'IndirectHealthInfoApiFactory', 'EncounterService', 'SessionContext', 'apiUrl',
        function ($scope, $window, $location, $filter, $modalConfirm, $routeParams, $modal, ApiV2PatientSummary,
                                                            DpPatientContext, PatientsApiFactory, DgcApiFactory, MessageService, EncountersApiFactory, TasksApiFactory, $route, $routeSegment,
                                                            DocumentsApiFactory, DraftsApiFactory, PersonRepresentationsApiFactory, UnfinishedRecordsApiFactory, SveidraApiFactory,
                                                            PatientSummaryApiFactory, GpApiFactory, WarRefugeeApiFactory, IndirectHealthInfoApiFactory, EncounterService, SessionContext, apiUrl) {

    $scope.$emit('setHelpPage', 'paciento-kortele');

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();
    var maxDashboardCount = 3;
    $scope.patientEncounterData = [];
    $scope.documentType = null;
    $scope.extendedTreatment = null;
    $scope.documentName = null;
            $scope.createDialogErr = {};

    var loadLastEncounter = function () {
        EncountersApiFactory.getPatientEncounters(
            {patient: DpPatientContext.id, count: 1, searchDocs: 'false', status: ['planned', 'inProgress', 'onleave'],
                organizationId: [$scope.sessionCtx.organization.id] }, function (data) {
            $scope.patientEncounterData = data.items.shift();
        });
    }

            SessionContext.organization.$promise.then(function(org){
        loadLastEncounter();
    });

    $scope.$on('$locationChangeStart', function (next, current) {
        if ($route.current.regexp.test($location.path())) {
            _.each($routeSegment.chain, function (c) {
                c.reload();
            })
        }
    });

    $scope.patientId = $routeParams.patientId;

    EncountersApiFactory.hasActiveEncounter({patientId: $routeParams.patientId}, function (data) {
        $scope.hasActiveEncounter = DpPatientContext.hasActiveEncounter = data.value || (DpPatientContext.data && SessionContext.practitioner ? DpPatientContext.data.providerPractitioner == SessionContext.practitioner.id : false);

        let acRead = $filter('acRead');

        //Only load resources if doctor has any business seeing them
        if($scope.hasActiveEncounter){
            $scope.patientSummaryLoading = true;
            ApiV2PatientSummary.getPatientSummary($scope.patientId).subscribe(res => {
                $scope.patientSummary = res;
                $scope.chronicDiagnoses = res.conditions?.filter(c => c.clinicalStatus?.code === '90734009');
                $scope.otherDiagnoses = res.conditions?.filter(c => !c.clinicalStatus || c.clinicalStatus?.code === '55561003');
                $scope.patientSummaryLoading = false;
            });

            //defoult fail on get

            // FIXME this got replaced by unfinishedDocs bit?
            // if (acRead('UnfinishedDocumentList')) {
            //     $scope.incompleteDocsLoading = true;
            //     $scope.incompleteDocs = PatientsApiFactory.getIncompleteDocs({id: $routeParams.patientId, count: maxDashboardCount}, function(data){
            //         $scope.incompleteDocsLoading = false;
            //     }, function(error){
            //         $scope.incompleteDocsLoading = false;
            //     });
            // }

            // if (acRead('OrderList')) {
            //     $scope.activeOrdersLoading = true;
            //     // -------- START active orders ----------
            //     $scope.activeOrders = DocumentsApiFactory.orderDocuments({count: 3, patientId: $routeParams.patientId, status: 'active'}, function(data){
            //         $scope.activeOrdersLoading = false;
            //     }, function(error){
            //         $scope.activeOrdersLoading = false;
            //     });
            //     // -------- END active orders ----------
            // }
            //
            // if (acRead('AllergyIntoleranceList')) {
            //     $scope.activeAllergiesLoading = true;
            //     $scope.activeAllergies = PatientsApiFactory.getActiveAllergies({id: $routeParams.patientId, count: 100}, function(data){
            //         $scope.activeAllergiesLoading = false;
            //     }, function(error){
            //         $scope.activeAllergiesLoading = false;
            //     });
            // }
            //
            // if (acRead('ConditionList')) {
            //     $scope.diagnosisLoading = true;
            //     $scope.diagnosis = PatientsApiFactory.getLastDiagnosis({id: $routeParams.patientId}, function(data){
            //         $scope.diagnosisLoading = false;
            //     }, function(error){
            //         $scope.diagnosisLoading = false;
            //     });
            // }
            //
            // if (acRead('TestList')) {
            //     $scope.researchLoading = true;
            //     // it should be only e200a or e014a
            //     $scope.research = PatientsApiFactory.getDashboardResearchDocs({id: $routeParams.patientId, count: maxDashboardCount}, function(data){
            //         $scope.researchLoading = false;
            //     }, function(error){
            //         $scope.researchLoading = false;
            //     });
            // }
            //
            // if (acRead('TaskList')) {
            //     $scope.tasksLoading = true;
            //     $scope.tasks = TasksApiFactory.getList({patientId: $scope.patientId, count: 3, statuses: ['SUBMITTED', 'ABORTED']}, function(data){
            //         $scope.tasksLoading = false;
            //     }, function(error){
            //         $scope.tasksLoading = false;
            //     });
            // }
            //
            // if (acRead('Composition','60591-5')) {
            //     $scope.summaryHistory = PatientSummaryApiFactory.getSummaryHistoryLast({patientId: $scope.patientId});
            // }
            //
            // if (acRead('DraftList')) {
            //     $scope.draftsLoading = true;
            //     $scope.drafts = DraftsApiFactory.findDrafts({patient: $scope.patientId, count: 3}, function(data){
            //         $scope.draftsLoading = false;
            //     }, function(error){
            //         $scope.draftsLoading = false;
            //     });
            // }
            //
            // if (acRead('StatisticCard')) {
            //     $scope.sveidraFormsLoading = true;
            //     $scope.sveidraForms = SveidraApiFactory.getList({patientId: $routeParams.patientId, page: 1, count: 1}, function(data){
            //         $scope.sveidraFormsLoading = false;
            //     }, function(error){
            //         $scope.sveidraFormsLoading = false;
            //     });
            // }

            if (acRead('PatientRepresentation')) {
                $scope.representationListLoading = true;
                $scope.representationList = PersonRepresentationsApiFactory.getList({personId: $scope.patientId, type: 'PATIENT', valid: true, blocked: false, count: 3}, function(data){
                    $scope.representationListLoading = false;
                }, function(error){
                    $scope.representationListLoading = false;
                });
            }

            $scope.patientRepresentativeEditUrlStart = '/dp/patients/' + $scope.patientId + '/representatives/';
        }
    });

    $scope.getUpcomingVaccinations = function () {
        $scope.plannedVaccinations = PatientsApiFactory.getUpcomingVaccinations({id: $scope.patientId});
    };

    $scope.createPatientRecordE025 = function () {
        //$location.path('/dp/patients/' + DpPatientContext.id + '/documents/new/e025').search({encounterId: $scope.patientEncounterData.id});
    }

    var close = function (id) {
        EncounterService.closeEncounter(id, function(){
            MessageService.registerEncounterCloseSuccessEvent();
            $scope.$emit('encounterCloseEvent');
            $routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
    }

    $scope.closeEncounter = function (item) {
        var message = 'Ar tikrai norite užregistruoti paciento atvykimo pabaigos faktą.';
        if ('Organization/' + item.patientData.providerOrganization != item.practitionerData.organizationRef) {
            message = 'ESPBI IS užregistravus paciento atvykimo pabaigos faktą, ESPBI IS automatiškai panaikina prieigos prie paciento ESI teises.';
        }
        $modalConfirm.show({title: "Atvykimo uždarymas", content: message}).then(function () {
            close(item.id);
            // alert.hide();
        });
    };

    // For testing only
    $scope.createCertificate = function (cer) {
        if (!$scope.patientEncounterData) {
            alert('Reikalinga atvykimo informacija');
            return;
        }
        $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/' + cer + '/new').search({encounterId: $scope.patientEncounterData.id});
    };

    $scope.openRelatedDocument = function (documentId) {
        var url = '/dp/patients/' + $scope.patientId + '/documents/' + documentId;
        $window.location.href = url;
    }

    $scope.loadUnfinishedDocuments = function() {
		  if ($scope.unfinishedRecords == undefined) {
			  $scope.unfinishedRecords = [];
			  $scope.unfinishedRecordsLoading = true;
              $scope.unfinishedRecords = UnfinishedRecordsApiFactory.search({
                  patient: $scope.patientId,
                  count: 3
              }, function (data) {
                  $scope.unfinishedRecordsLoading = false;
              }, function(error){
                  $scope.unfinishedRecordsLoading = false;
              });
          }
    }

    $scope.openVaccinationView = function (item) {
        if (item.immunizationRecommendation.isVaccinationFact) {
            $location.path('/dp/patients/' + DpPatientContext.id + '/documents/' + item.immunizationRecommendation.compositionId);
        } else {
            $location.path('/dp/patients/' + DpPatientContext.id + '/vaccines/view/' + item.immunizationRecommendation.id)
        }
    };

    $scope.loadDoctorPatientCertificateTypes = function () {
        if ($filter('acRead')('Dcc')) {
            return DgcApiFactory.getDoctorPatientCertificateTypes({patientId: $routeParams.patientId});
        } else {
            return [];
        }
    };

    $scope.downloadDoctorPatientsCertificate = function(certificateType) {
        $window.open(apiUrl + '/certificates-dgc/patients/' + $routeParams.patientId + '/certificate/es-skaitmeninis-covid-pa%C5%BEym%C4%97jimas.pdf?type=' + certificateType);
    };

    $scope.downloadPatientGpCertificate = function () {
        $window.open(apiUrl + '/certificate-gp/patients/' + $scope.patientId + '/certificate/galimybi%C5%B3-pasas.pdf');
    };

    // $scope.loadGpSertificatePermission = function() {
    //     GpApiFactory.isGpCertificateExist({id: $routeParams.patientId}, function(data) {
    //         $scope.isGpDoctorPacientCertificateExist = data['IsExist'];
    //     })
    // }
    // $scope.loadGpSertificatePermission();

    $scope.getSysDate = function () {
        $scope.currentsysdate = new Date();
    };

    $scope.isWarRefugeeDPExist = -1;

    $scope.checkWarRefugee = function() {
        $scope.getSysDate();
        WarRefugeeApiFactory.isWarRefugeeExist({id: $routeParams.patientId}, function (data) {
            $scope.isWarRefugeeDPExist = data['exists'];
        });
    };

//------ Patient disagreement -------------------

    $scope.closed = true;
    $scope.checked = false;
    $scope.strFirstLimit = 0;
    $scope.strSecondLimit = 0;
    $scope.strThirdLimit = 0;

    $scope.textmessage = {
        firstDescription: "Asmens sveikatai svarbi informacija – informacija apie riziką susirgti liga ar atsirasti sveikatos sutrikimui, dėl kurių kiltų grėsmė asmens gyvybei ir (arba) kurie galėtų sukelti sunkų nuolatinį neįgalumą, kurią žinant pagal įprastą klinikinę praktiką, taikomą, kai ši informacija paaiškėja, asmeniui gali būti teikiamos sveikatos priežiūros paslaugos, siekiant išvengti tos ligos ar sveikatos sutrikimo ar juos palengvinti.",
        secondDescription: "Informuojame, kad jūs turite teisę bet kada šį nesutikimą atšaukti, bet iki atšaukimo gauta svarbi informacija jums nebus pateikta.",
        link: "Plačiau..."
    };

    $scope.showMore = function() {
        $scope.strFirstLimit = $scope.textmessage.firstDescription.length;
        $scope.strSecondLimit = $scope.textmessage.secondDescription.length;
        $scope.strThirdLimit = $scope.textmessage.link.length;
    };

    $scope.showLess = function() {
        $scope.strFirstLimit = 0;
        $scope.strSecondLimit = 0;
        $scope.strThirdLimit = 0;
    };

    $scope.checkPatientDisagrees = function() {
        if ($scope.closed) {
            IndirectHealthInfoApiFactory.isPatientDisagrees({id: $routeParams.patientId}, function (data) {
                $scope.checked = data['exists'];
            });
            $scope.closed = false;
        } else {
            $scope.closed = true;
        }
    };

    $scope.$on("ChangePatientDisagreement", function (evt, data) {
        $scope.checked = data;
    });

    var modal = $modal({
        scope: $scope,
        title: ' ',
        contentTemplate: '/templates/portal/doctor/patient/modal/dashboard_disagreement_modal.html'
    });

    $scope.open = function() {
        modal.$promise.then(function () {
            modal.show();
        });
    };

    //----- End Patient disagreement ----------------

    //--TEST DATA
    $scope.documents = [
        {
            'id': '14',
            'type': 'obs',
            'title': 'Nebaigtas siuntimas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Agneta',
            'patientLastname': 'Rimkevičienė',
            'patientBirth': '1988-11-11',
            'createDateTime': '2014-08-26 17:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'Ambirix* (EU)',
            'serviceData2': 'Skiepų skyrimas'
        },
        {
            'id': '1',
            'type': 'doc',
            'title': 'E025 Ambulatorinis apsilankymas',
            'status': 'preliminary',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Agneta',
            'patientLastname': 'Omenytė',
            'patientBirth': '1990-12-08',
            'createDateTime': '2014-08-26 14:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType: 'e025'
        }, //1.1 not finished
        {
            id: '11',
            type: 'doc',
            title: 'E014 Patologijos tyrimo užsakymas',
            docType: 'e014',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            createDateTime: '2014-08-29',
            serviceData1: 'C00.3 Viršutinės lūpos vidinis paviršius, C00.4 Apatinės lūpos vidinis paviršius',
            serviceData2: null,
            institution: 'VŠĮ Kauno Kalniečių poliklinika'
        },
        {
            'id': '2',
            'type': 'doc',
            'title': 'E003 Stacionaro epikrizė',
            'status': 'draft',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Agneta',
            'patientLastname': 'Omenytė',
            'patientBirth': '1990-12-08',
            'createDateTime': '2014-08-26 14:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType: 'e003'
        }, //1.2  draft
        {
            'id': '3',
            'type': 'doc',
            'title': 'E025 Ambulatorinis apsilankymas',
            'status': 'preliminary',
            'author': 'Antanas Romanius',
            'authorStamp': '485973',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'false',
            'patientName': 'Agneta',
            'patientLastname': 'Omenytė',
            'patientBirth': '1990-12-08',
            'createDateTime': '2014-08-26 14:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType: 'e025'
        }, //2.1 see not owner
        {
            'id': '4',
            'type': 'doc',
            'title': 'E025 Ambulatorinis apsilankymas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Agneta',
            'patientLastname': 'Omenytė',
            'patientBirth': '1990-12-08',
            'createDateTime': '2014-08-26 14:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType: 'e025'
        }, //3.1 submited to sing
        {
            'id': '5',
            'type': 'obs',
            'title': 'Nebaigtas siuntimas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Agneta',
            'patientLastname': '',
            'patientBirth': '',
            'createDateTime': '2014-08-26 14:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'A08.5 Kitos patikslintos žarnyno infekcijos',
            'consultationDocOrProf': 'Gyd. kardiologas',
            'serviceData2': 'Konsultacijos, tyrimų, gydymo skyrimas'
        },
        {
            'id': '6',
            'type': 'doc',
            'title': 'E027 Medicinos dokumentų išrašas / siuntimas',
            'status': 'preliminary',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Roberta',
            'patientLastname': 'Omenienė',
            'patientBirth': '1978-12-08',
            'createDateTime': '2014-08-26 16:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'A39.8 Kitos meningokokų sukeltos infekcijos',
            'consultationDocOrProf': 'Gyd. kardiologas',
            docType: 'e027'
        },
        {
            'id': '6',
            'type': 'doc',
            'title': 'E027 Medicinos dokumentų išrašas / siuntimas',
            'status': 'preliminary',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Ovidijus',
            'patientLastname': 'Zakas',
            'patientBirth': '1988-03-08',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'BCG vaccine SSI',
            docType: 'e027'
        },
        {
            'id': '7',
            'type': 'doc',
            'title': 'E027 Medicinos dokumentų išrašas / siuntimas',
            'status': 'preliminary',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Jonui',
            'patientLastname': 'Kokui',
            'patientBirth': '1989-04-18',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'Antikūnų prieš DNR nustatymas',
            docType: 'e027'
        },
        {
            'id': '8',
            'type': 'doc',
            'title': 'E027 Medicinos dokumentų išrašas / siuntimas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'Lietuvos kelių policijos tarnybai',
            docType: 'e027'
        }, {
            'id': '9',
            'type': 'doc',
            'title': 'E027-ats Atsakymas į konsultacijos, tyrimų, gydymo siuntimą',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'A39.8 Kitos meningokokų sukeltos infekcijos',
            docType: 'e027a'
        },
        {
            'id': '10',
            'type': 'doc',
            'title': 'E200 Laboratorinio tyrimo užsakymas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'Antikūnų prieš DNR nustatymas',
            docType: 'e200'
        },
        {
            'id': '9',
            'type': 'doc',
            'title': '	E200-ats Laboratorinio tyrimo rezultatų protokolas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'Antikūnų prieš DNR nustatymas',
            docType: 'e200a'
        },
        {
            'id': '11',
            'type': 'doc',
            'title': 'E014 Patologijos tyrimo užsakymas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'C00.3 Viršutinės lūpos vidinis paviršius, C00.4 Apatinės lūpos vidinis paviršius',
            'serviceData2': 'M8000/0 Navikas, gerybinis',
            docType: 'e014'
        },
        {
            'id': '12',
            'type': 'doc',
            'title': 'E014-ats Patologijos tyrimo atsakymas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'C00.3 Viršutinės lūpos vidinis paviršius, C00.4 Apatinės lūpos vidinis paviršius',
            'serviceData2': 'M8000/0 Navikas, gerybinis',
            docType: 'e014a'
        },
        {
            'id': '13',
            'type': 'doc',
            'title': 'E063 Vakcinacijos įrašas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Šeimos gydytojas',
            'isOwner': 'true',
            'patientName': 'Andriui',
            'patientLastname': 'Rinkevičiui',
            'patientBirth': '1965-08-04',
            'createDateTime': '2014-08-26 17:05',
            'ESInr': 'xxx-987',
            'serviceData1': 'Avaxim 160',
            docType: 'e063'
        },
        {
            'id': '15',
            'type': 'obs',
            'title': 'Nebaigtas siuntimas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Jolanta',
            'patientLastname': 'Augutienė',
            'patientBirth': '1975-12-01',
            'createDateTime': '2014-08-26 17:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'Antikūnų prieš skydliaukės peroksidazę nustatymas (anti-TPO)',
            'serviceData2': 'Skyrimas laboratoriniam tyrimui'
        },
        {
            'id': '16',
            'type': 'obs',
            'title': 'Nebaigtas siuntimas',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Jolanta',
            'patientLastname': 'Augutienė',
            'patientBirth': '1975-12-01',
            'createDateTime': '2014-08-26 17:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'C00.5 Lūpa, nepatikslinta, vidinis paviršius',
            'serviceData2': 'Skyrimas patologiniam tyrimui'
        },
        {
            'id': '17',
            'type': 'cer',
            'title': 'E047 Privalomo sveikatos patikrinimo medicininė pažyma',
            'status': 'final',
            'author': 'Jonas Petkevičius',
            'authorStamp': '485963',
            'authorProfesion': 'Gyd. kardiologas',
            'isOwner': 'true',
            'patientName': 'Jolanta',
            'patientLastname': 'Augutienė',
            'patientBirth': '1975-12-01',
            'createDateTime': '2014-08-26 17:50',
            'ESInr': 'xxx-987',
            'serviceData1': 'C00.5 Lūpa, nepatikslinta, vidinis paviršius',
            'serviceData2': 'Skyrimas patologiniam tyrimui',
            docType: 'e047'
        }]; //4.1 submited to sing
    $scope.cancel = function () {
        $modalConfirm.show(
                {title: $filter('translate')('inf.rep.cancel.tit'), content: $filter('translate')('inf.rep.cancel')}).then(function () {
        });
    };
    //4.1 submited to sing
    //service data https://confluence.nortal.com/pages/viewpage.action?pageId=91688196 "Paslaugos duomenys" depends on document type

    //--TEST DATA END
}]);
