angular.module('erx.utils').service('$erxDrugSupplyDisruptionsModal', ["$modal", "$rootScope", "$q", "gettextCatalog", function ($modal, $rootScope, $q, gettextCatalog) {
    var scope = $rootScope.$new();
    var deferred;
    scope.data = {};

    var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-drug-supply-disruptions/erxDrugSupplyDisruptionsModal.tpl.html', scope: scope, show: false});
    var parentShow = confirm.show;
    confirm.show = function (opts) {
        scope.title = opts.title ? opts.title : gettextCatalog.getString('Vaisto tiekimo sutrikimas');
        scope.disruption = opts.data;

        deferred = $q.defer();
        parentShow();
        return deferred.promise;
    };

    return confirm;
}]);