angular.module('medvais.utils').directive('medvaisMsgGlobal', ["MessageService", function (MessageService) {
    return {
        templateUrl: 'modules/medvais-utils/components/medvais-msg-global.html',
        restrict: 'E', replace: true, link: function (scope: any, element, attrs) {
            scope.getItems = function () {
                return MessageService.messages['global'];
            };
            scope.close = function (index) {
                MessageService.remove(index, 'global');
            };
        }
    }
}]);