<nhr-record-widget [closable]="true" [closed]="false" [mixed]="true" title="{{'doc.all'|translate}}">
    <div after-title *ngIf="newAllergies?.length && !viewOnly" class="widget-alert alert alert-dismissable alert-warning d-flex justify-content-between flex-wrap">
        <span>
            {{'dp.pat.das.all' | translate | uppercase}} <span class="vr"></span>
            {{'doc.all.form.allergiesWarning' | translate}}
        </span>
        <button class="btn btn-warning btn-sm" (click)="openCurrentsAllergiesModal()">
            {{'doc.all.form.registeredAllergies' | translate}}
        </button>
    </div>
    <div after-title *ngIf="newAllergies?.length === 0 && showInfoMessage && !viewOnly" class="widget-alert alert alert-warning alert-dismissible fade show">
        <span>
            {{'err.ale.14' | translate}}
        </span>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeInfoMessage()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="widget-body">
        <div *ngIf="currentAllergies?.length === 0" class="row d-flex align-items-center mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label py-0">{{'doc.all.add.nazv' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-checkbox inputId="submit-allergy-report" labelText="doc.all.add.nazv.chk" [(checkboxChecked)]="isSubmitAllergyReportChecked" (checkboxChange)="handleCheckboxChange($event)"></app-checkbox>
            </div>
        </div>
        <ol *ngIf="currentAllergies?.length" class="slats hover">
            <!-- TODO-design-slat-item: check if edit and delete works -->
            <li
                slat-item
                [edit]="!viewOnly" [delete]="!viewOnly"
                (editButtonClick)="openEditAllergyModal(i)"
                (deleteButtonClick)="delete(i)"
                *ngFor="let allergy of currentAllergies; let i = index"
                class="p-4 pb-2"
            >
                <div class="fw-bold mb-1">
                    <span *ngIf="allergy.code?.name">
                        {{allergy.code.name}}
                        <span class="vr"></span>
                    </span>
                    <span *ngIf="allergy.substance1?.name" class="fst-italic">
                        {{allergy.substance1.name}}
                        <span class="vr"></span>
                    </span>
                    {{allergy.manifestation.code}} {{allergy.manifestation.name}}
                </div>
                <div class="text-secondary small">
                    {{ allergy.onsetDate | date: 'YYYY-MM-dd'}} - {{ allergy.lastOccurrence | date: 'YYYY-MM-dd' }}
                </div>
                <div class="flex-grow-1 row small">
                    <div class="col-sm-6 col-md-4 col-xl">
                        <span class="text-secondary me-2">{{'doc.dia.dia.typ' | translate}}: </span>
                        <strong>{{ (allergy.type?.name ?? "-") | capitalize }}</strong>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl d-flex align-items-center gap-1">
                        <span class="text-secondary me-2">{{'doc.dia.sts' | translate}}: </span>
                            <!-- TODO-design: what colors are for this badge, otherwise delete -->
                            <ng-container *ngIf="allergy.clinicalStatus.name == 'Aktyvi'">
                            <div class="d-inline position-relative p-2">
                                <span class="position-absolute p-2 translate-middle rounded-circle bg-success">
                                    <span *ngIf="allergy.clinicalStatus.name" class="visually-hidden">{{ allergy.clinicalStatus.name | capitalize }}</span>
                                </span>
                            </div>
                            <!-- TODO-design: should we use an icon instead? If not delete this comment -->
                            <!-- <i class="fa-regular fa-circle-dot text-success fs-3 my-n1"></i> -->
                        </ng-container>
                        <strong>{{ (allergy.clinicalStatus?.name ?? "-") | capitalize }}</strong>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl">
                        <span class="text-secondary me-2">{{'doc.dia.con' | translate}}: </span>
                        <strong>{{ (allergy.verificationStatus?.name ?? "-") | capitalize }}</strong>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl">
                        <span class="text-secondary me-2">{{'doc.dia.sev' | translate}}: </span>
                        <strong>{{ (allergy.severity?.name ?? "-") | capitalize }}</strong>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl">
                        <span class="text-secondary me-2">{{'doc.all.crit' | translate}}: </span>
                        <strong>{{ (allergy.criticality?.name ?? "-") | capitalize }}</strong>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl">
                        <span class="text-secondary me-2">{{'doc.dia.cat' | translate}}: </span>
                        <strong>{{ (allergy.category?.name ?? "-") | capitalize }}</strong>
                    </div>
                </div>
                <div class="my-2 small">
                    {{ allergy.notes ?? '-' }}
                </div>
            </li>
        </ol>
        <div *ngIf="!isSubmitAllergyReportChecked && !viewOnly">
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label text-nowrap">{{'doc.all.react' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <input id="factor" [formControl]="allergy" type="text"
                    class="form-control" [typeahead]="allergiesFactorsSuggestions$" [typeaheadAsync]="true"
                    [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                    (typeaheadOnSelect)="allergySelected($event)"
                    [typeaheadOptionField]="'name'"
                    placeholder="{{'Pasirinkite' | translate}}">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label text-nowrap">{{'doc.all.allgn' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <input id="factor" [formControl]="allergen" type="text"
                           class="form-control" [typeahead]="allergensFactorsSuggestions$" [typeaheadAsync]="true"
                           [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                           (typeaheadOnSelect)="allergenSelected($event)"
                           [typeaheadOptionField]="'name'"
                           placeholder="{{'Pasirinkite' | translate}}">
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
