ng1App.factory('PatientsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/patients';
    return $resource('', {}, {
        saveDetails: {
            method: 'POST',
            url: URL_BASE
        },
        get: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getWithResources: {
            method: 'GET',
            url: URL_BASE + '/:id/withResources'
        },
        count: {
            method: 'GET',
            url: URL_BASE + '/:id/count'
        },
        search: {
            method: 'GET',
            url: URL_BASE,
            isArray: false
        },
        findPatient: {
            method: 'GET',
            url: URL_BASE + '/findPatient',
            isArray: false
        },
        fullSearch: {
            method: 'GET',
            url: URL_BASE + '/fullSearch',
            isArray: false
        },
        getWithRelatedPersons: {
            method: 'GET',
            url: URL_BASE + '/:id/withRelatedPersons'
        },
        // consider using search function with parameter 'q', which searches in name, surname, personalCode, esi
        getByPersonalOrEsiNr: {
            method: 'GET',
            url: URL_BASE + '/byPcEsi'
        },
        getByPersonal: {
            method: 'GET',
            url: URL_BASE + '/patient?personalCode=:personalCode'
        },
        getInsurance: {
            method: 'GET',
            url: URL_BASE + '/insurance?id=:id'
        },
        getHasDrivingLicense: {
            method: 'GET',
            url: URL_BASE + '/drivingLicense?personalCode=:personalCode'
        },
        getHasGunLicense: {
            method: 'GET',
            url: URL_BASE + '/gunLicense'
        },
        getPlannedVaccinations: {
            method: 'GET',
            url: URL_BASE + '/:id/planned-vaccinations',
            isArray: true
        },
        getUpcomingVaccinations: {
            method: 'GET',
            url: URL_BASE + '/:id/upcoming-vaccinations'
        },
        getVaccinations: {
            method: 'GET',
            url: URL_BASE + '/:id/vaccinations'
        },
        getVaccinationsCount: {
            method: 'GET',
            url: URL_BASE + '/:id/vaccinations/count'
        },
        getIncompleteDocs: {
            method: 'GET',
            url: URL_BASE + '/:id/incompleteDocs'
        },
        getActiveAllergies: {
            method: 'GET',
            url: URL_BASE + '/:id/activeAllergies',
            isArray: true
        },
        getActiveAllergiesCount: {
            method: 'GET',
            url: URL_BASE + '/:id/activeAllergiesCount'
        },
        getAllergiesForList: {
            method: 'GET',
            url: URL_BASE + '/:id/allergiesForList',
            isArray: true
        },
        getNzavIfExists: {
            method: 'GET',
            url: URL_BASE + '/:id/NZAV'
        },
        getAllergiesForListByEncounter: {
            method: 'GET',
            url: URL_BASE + '/:id/allergiesByEncounter?practitionerIdentifier=:practitionerId&encounterIdentifier=:encounterId',
            isArray: true
        },
        getLastDiagnosis: {
            method: 'GET',
            url: URL_BASE + '/:id/lastDiagnosis'
        },
        getDiagnosisForList: {
            method: 'GET',
            url: URL_BASE + '/:id/diagnosisForList',
            isArray: true
        },
        getDiagnosisForListByEncounter: {
            method: 'GET',
            url: URL_BASE + '/:id/diagnosisByEncounter?practitionerIdentifier=:practitionerId&encounterIdentifier=:encounterId',
            isArray: true
        },
        getDashboardResearchDocs: {
            method: 'GET',
            url: URL_BASE + '/:id/dashboardResearchResults'
        },
        getResearchDocsForList: {
            method: 'GET',
            url: URL_BASE + '/:id/researchResultsForList'
        },
        getResearchDataForAdvancedSearch: {
            method: 'GET',
            url: URL_BASE + '/:id/researchResultsDataForAndvancedSearch'
        },
        getResearchDocsByAdvancedSearch: {
            method: 'GET',
            url: URL_BASE + '/:id/researchResultsForListByAdvancedSearch'
        },
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        updateContacts: {
            method: 'POST',
            url: URL_BASE + '/update-contacts'
        },
        existInRegistry: {
            method: 'GET',
            url: URL_BASE + '/exist-in-registers'
        },
        updateFromRegistry: {
            method: 'POST',
            url: URL_BASE + '/update-from-registers'
        },
        update: {
            method: 'PUT',
            url: URL_BASE + '/:id'
        },
        merge: {
            method: 'PUT',
            url: URL_BASE + '/:patientId/merge/:targetId'
        },
        disjoin: {
            method: 'PUT',
            url: URL_BASE + '/:patientId/disjoin/:targetId'
        },
        getOtherDocuments: {
            method: 'GET',
            url: URL_BASE + '/:patientId/other-documents'
        },
        getDocumentsForSveidra: {
        	method: 'GET',
        	url: URL_BASE + '/:patientId/documentsForSveidra',
        	isArray: true
        },
        getAgreements: {
        	method: 'GET',
        	url: URL_BASE + '/:patientId/agreements'
        },
        
        getSettings: {
           method: 'GET',
           url: URL_BASE + '/getSettings'
        },
        saveSettings: {
           method: 'POST',
           url: URL_BASE + '/saveSettings'
        },
        getBMIEvaluation: {
           method: 'GET',
           url: URL_BASE + '/bmiEvaluation'
        }
    });
}]);

ng1App.service('PatientUpdateService', 
    ['$upload', 'apiUrl',
     function ($upload, apiUrl) {
    this.save = function (form, photo, otherFiles, successCb, errorCb) {
        var files = [];
        var fileKeys = [];
        if (photo) {
            files.push(photo);
            fileKeys.push('photo');
        }
        angular.forEach(otherFiles, function (val, key) {
            files.push(val);
            fileKeys.push('file_' + key);
        });

        $upload.upload({
            url: apiUrl + '/patients/save',
            data: {data: form},
            file: files,
            fileFormDataName: fileKeys
        }).progress(function (evt) {
        }).success(function (data, status, headers, config) {
            (successCb || angular.noop)(data);
        }).error(function (error) {
            (errorCb || angular.noop)(error);
        });
    };
}]);