import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UpgradeModule } from "@angular/upgrade/static";
import { TranslateModule } from "@ngx-translate/core";
import { AgGridModule } from "ag-grid-angular";
import { NgChartsModule } from "ng2-charts";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DowngradeAdapter } from "../../../utils/hypridHelper";
import { CapitalizePipe } from "../../shared/pipes/capitalize.pipe";
import { SharedLibrary } from "../../shared/shared.module";
import { E096Module } from "./components/e096/e096.module";
import { FetusUltrasoundExaminationComponent } from "./components/fetus-ultrasound-examination/fetus-ultrasound-examination.component";
import { UltrasoundTemplateComponent } from "./components/fetus-ultrasound-examination/ultrasound-template/ultrasound-template.component";
import { FetusUltrasoundExaminationViewComponent } from "./components/fetus-ultrasound-examination/view/fetus-ultrasound-examination-view.component";
import { UltrasoundExaminationTemplateComponent } from "./components/fetus-ultrasound-examination/view/ultrasound-examination-template/ultrasound-examination-template.component";
import { FirstNewbornCheckupComponent } from "./components/first-newborn-checkup/first-newborn-checkup.component";
import { GeneralPregnancyTerminationInfoComponent } from "./components/general-pregnancy-termination-info/general-pregnancy-termination-info.component";
import { GeneralPregnancyTerminationInfoViewComponent } from "./components/general-pregnancy-termination-info/view/general-pregnancy-termination-info-view.component";
import { OutpatientVisitDescriptionComponent } from "./components/outpatient-visit-description/outpatient-visit-description.component";
import { OutpatientVisitDescriptionTemplateComponent } from "./components/outpatient-visit-description/view/outpatient-visit-description-template/outpatient-visit-description-template.component";
import { OutpatientVisitDescriptionViewComponent } from "./components/outpatient-visit-description/view/outpatient-visit-description-view.component";
import { ParentalConsentComponent } from "./components/parental-consent/parental-consent.component";
import { PreganncyDashboardListComponent } from "./components/preganncy-dashboard-list/preganncy-dashboard-list.component";
import { PregnancyDashboardListFilterComponent } from "./components/preganncy-dashboard-list/pregnancy-dashboard-list-filter/pregnancy-dashboard-list-filter.component";
import { PregnancyDashboardComponent } from "./components/pregnancy-dashboard.component";
import { PregnancyTerminationComponent } from "./components/pregnancy-termination/pregnancy-termination.component";
import { ProceduresDuringPregnancyComponent } from "./components/procedures-during-pregnancy/procedures-during-pregnancy.component";
import { ProceduresDuringPregnancyViewComponent } from "./components/procedures-during-pregnancy/view/procedures-during-pregnancy-view.component";
import { RiskFactorsComponent } from "./components/risk-factors/risk-factors.component";
import { RiskFactorsViewComponent } from "./components/risk-factors/view/risk-factors-view.component";
import { DocumentCaptionComponent } from "./components/shared/document-caption/document-caption.component";
import { UterineGrowthCurveDiagramComponent } from "./components/uterine-growth-curve/uterine-growth-curve-diagram/uterine-growth-curve-diagram.component";
import { UterineGrowthCurveComponent } from "./components/uterine-growth-curve/uterine-growth-curve.component";
import { PregnancyDashboardViewComponent } from "./components/view/pregnancy-dashboard-view.component";
import "./pregnancy-dashboard.routes";
import { E097Module } from './components/e097/e097.module';
import { E010Module } from './components/e010/e010.module';
import { E030Module } from './components/e030/e030.module';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        PregnancyDashboardComponent,
        GeneralPregnancyTerminationInfoComponent,
        RiskFactorsComponent,
        PregnancyTerminationComponent,
        ProceduresDuringPregnancyComponent,
        OutpatientVisitDescriptionComponent,
        FetusUltrasoundExaminationComponent,
        UltrasoundTemplateComponent,
        FirstNewbornCheckupComponent,
        PregnancyDashboardViewComponent,
        GeneralPregnancyTerminationInfoViewComponent,
        RiskFactorsViewComponent,
        ProceduresDuringPregnancyViewComponent,
        OutpatientVisitDescriptionViewComponent,
        OutpatientVisitDescriptionTemplateComponent,
        UterineGrowthCurveComponent,
        UterineGrowthCurveDiagramComponent,
        FetusUltrasoundExaminationViewComponent,
        UltrasoundExaminationTemplateComponent,
        DocumentCaptionComponent,
        ParentalConsentComponent,
        PreganncyDashboardListComponent,
        PregnancyDashboardListFilterComponent,
    ],
    providers: [CapitalizePipe, DatePipe],
    imports: [
        CommonModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        TypeaheadModule.forRoot(),
        NgChartsModule,
        AgGridModule,
        BsDatepickerModule,
        E096Module,
        E097Module,
        E010Module,
        E030Module
    ],
})
export class PregnancyDashboardModule {}

new DowngradeAdapter(ng1App)
    .downgradeComponent(PregnancyDashboardComponent)
    .downgradeComponent(FirstNewbornCheckupComponent)
    .downgradeComponent(GeneralPregnancyTerminationInfoComponent)
    .downgradeComponent(RiskFactorsComponent)
    .downgradeComponent(PregnancyTerminationComponent)
    .downgradeComponent(ProceduresDuringPregnancyComponent)
    .downgradeComponent(OutpatientVisitDescriptionComponent)
    .downgradeComponent(FetusUltrasoundExaminationComponent)
    .downgradeComponent(UltrasoundTemplateComponent)
    .downgradeComponent(PregnancyDashboardViewComponent)
    .downgradeComponent(GeneralPregnancyTerminationInfoViewComponent)
    .downgradeComponent(RiskFactorsViewComponent)
    .downgradeComponent(ProceduresDuringPregnancyViewComponent)
    .downgradeComponent(OutpatientVisitDescriptionViewComponent)
    .downgradeComponent(FetusUltrasoundExaminationViewComponent)
    .downgradeComponent(UltrasoundExaminationTemplateComponent)
    .downgradeComponent(ParentalConsentComponent)
    .downgradeComponent(PreganncyDashboardListComponent)
    .downgradeComponent(PregnancyDashboardListFilterComponent);
