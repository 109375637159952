import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../config/config.service";
import { AllergyModel } from "../models/allergy.model";
import { CountModel } from "../models/count.model";
import { EncounterByDiagnosisModel } from "../models/encounter-by-diagnosis.model";
import { FilteredDataModel } from "../models/filtered-data.model";
import { InsuranceModel } from "../models/insurance.model";
import { PatientModel } from "../models/patient.model";

@Injectable({
    providedIn: "root",
})
export class ApiV2Patients extends ConfigService {
    path = this.apiUrl + "/patients";

    getPatient(patientId: string): Observable<PatientModel> {
        const url = `${this.path}/${patientId}`;
        return this.http.get<PatientModel>(url);
    }

    search(params: any): Observable<FilteredDataModel<PatientModel>> {
        const url = `${this.path}`;
        return this.http.get<FilteredDataModel<PatientModel>>(url, {params: params});
    }

    getInsurance(patientId: string): Observable<InsuranceModel> {
        const url = `${this.path}/insurance?id=${patientId}`;
        return this.http.get<InsuranceModel>(url);
    }

    getLastDiagnosis(patientId: string): Observable<any> {
        const url = `${this.path}/${patientId}/lastDiagnosis`;
        return this.http.get<any>(url);
    }

    getAllergiesForList(patientId: string): Observable<{ data: AllergyModel[] }> {
        const url = `${this.path}/${patientId}/allergiesForList`;
        return this.http.get<{ data: AllergyModel[] }>(url);
    }

    getFilteredAllergiesForList(
        patientId: string,
        filters: { [key: string]: any }
    ): Observable<{ items: AllergyModel[]; total: number }> {
        // const url = `${this.path}/${patientId}/allergiesForList`;
        // return this.http.get<AllergyModel[]>(url, { params: filters });

        const url = `${this.apiUrl}/allergies/list`;
        return this.http.get<{ items: AllergyModel[]; total: number }>(url, {
            params: { ...filters, patient: patientId },
        });
    }

    getActiveAllergies(patientId: string): Observable<AllergyModel[]> {
        const url = `${this.path}/${patientId}/activeAllergies`;
        return this.http.get<AllergyModel[]>(url);
    }

    getActiveAllergiesCount(patientId: string): Observable<CountModel> {
        const url = `${this.path}/${patientId}/activeAllergiesCount`;
        return this.http.get<CountModel>(url);
    }

    getDiagnosisByEncounter(
        patientId: string,
        practitionerId: any,
        encounterId: any
    ): Observable<EncounterByDiagnosisModel[]> {
        let params = new HttpParams();

        params = params.append("practitionerIdentifier", practitionerId ? practitionerId : "");
        params = params.append("encounterIdentifier", encounterId ? encounterId : "");

        const url = `${this.path}/${patientId}/diagnosisByEncounter`;
        return this.http.get<EncounterByDiagnosisModel[]>(url, { params });
    }

    public searchForList(params: any): Observable<FilteredDataModel> {
        const url = `${this.path}/forList`;
        return this.http.get<FilteredDataModel>(url, { params: params });
    }
}
