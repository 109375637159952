import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { QuillModule } from "ngx-quill";
import { routeParamsProvider } from "../ng1.routeParams";
import './ng1.app'

import './ng1App/po/en.po';
import './ng1App/po/lt.po';

export function getTextCatalogProvider($injector: any) {
    return $injector.get("gettextCatalog");
}

/**
 * Root module for erx patient components
 */
@NgModule({
    //Double rendering fix for angular components, which are downgraded and used in angularjs.
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        HttpClientModule,
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        QuillModule,
        TypeaheadModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        RouterModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgClickOutsideDirective,
        TooltipModule.forRoot(),
    ],
    declarations: [

    ],
    exports: [

    ],
    providers: [
        routeParamsProvider,
    ],
})
export class ErxDoctorLibrary {
    constructor() {}

    static forRoot(): ModuleWithProviders<ErxDoctorLibrary> {
        return {
            ngModule: ErxDoctorLibrary,
            providers: [
                routeParamsProvider,
            ],
        };
    }
}