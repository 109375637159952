import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-typeahead-component',
    templateUrl: './typeahead.component.html'
})
export class TypeaheadComponent {
    @Input() typeaheadId: string;
    @Input() typeaheadModel: any;
    @Input() typeaheadField: string;
    @Input() typeaheadValues: any[];
    @Output() onChangeModel = new EventEmitter<any>();

    modelChanged(event): void {
        this.onChangeModel.emit(event);
    }
}