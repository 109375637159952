ng1App.filter('translatePlural', ["gettextCatalog", function (gettextCatalog) {
    function filter(input, count) {
        if (count === 'k' || count % 1 !== 0) {
            return gettextCatalog.getStringForm(input, 3);
        }
        return gettextCatalog.getPlural(count, input, input);
    }

    filter.$stateful = true;
    return filter;
}]);