import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, merge } from 'rxjs';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    generateSelectionValuesFromEntity,
    listenAndChangeStatusOfControl
} from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-n-newborn',
    templateUrl: './e096-n-newborn.component.html',
})
export class E096NNewbornComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable = true;
    @Input() fruit: string;

    formGroup: FormGroup;
    private liveChildGroup: FormGroup;
    maxDate = new Date();
    occiputPositionOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Priekinė'
        },
        {
            id: '1',
            code: '1',
            name: 'Užpakalinė'
        }
    ];
    resuscitationOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];
    resuscitationMethodOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pirmieji gaivinimo žingsniai (PGŽ)'
        },
        {
            id: '1',
            code: '1',
            name: 'PGŽ + dirbtinė plaučių ventiliacija (DPV)'
        },
        {
            id: '2',
            code: '2',
            name: 'PGŽ + DPV + krūtinės ląstos paspaudimai (KLP)'
        },
        {
            id: '3',
            code: '3',
            name: 'PGŽ + DPV + KLP + medikamentai'
        }
    ];
    scaleOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '0'
        },
        {
            id: '1',
            code: '1',
            name: '1'
        },
        {
            id: '2',
            code: '2',
            name: '2'
        }
    ];
    newbornFirstFeedingOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Inicijuotas naujagimio po nepertraukiamo odos kontakto'
        },
        {
            id: '1',
            code: '1',
            name: 'Pridėtas prie krūties padedant personalui'
        },
        {
            id: '2',
            code: '2',
            name: 'Neinicijuotas'
        }
    ];
    newbornTrasferedWardOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Akušerijos'
        },
        {
            id: '1',
            code: '1',
            name: 'NITS'
        },
        {
            id: '2',
            code: '2',
            name: 'Intensyvaus stebėjimo palata'
        }
    ];
    newbornMovedOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Kartu su motina'
        },
        {
            id: '1',
            code: '1',
            name: 'Be motinos'
        }
    ];
    newbornTrasferedConditionOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Patenkinama'
        },
        {
            id: '1',
            code: '1',
            name: 'Nepatenkinama'
        },
        {
            id: '2',
            code: '2',
            name: 'Sunki'
        }
    ];

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    selectFromEntities = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.liveChildGroup = this.fb.group({
            after1min: this.fb.group({
                reflexes: this.fb.control(null, [Validators.required]),
                heartActivity: this.fb.control(null, [Validators.required]),
                breathing: this.fb.control(null, [Validators.required]),
                skinColor: this.fb.control(null, [Validators.required]),
                muscleTone: this.fb.control(null, [Validators.required]),
                apgarScore: this.fb.control({value: null, disabled: true})
            }),
            after5min: this.fb.group({
                reflexes: this.fb.control(null, [Validators.required]),
                heartActivity: this.fb.control(null, [Validators.required]),
                breathing: this.fb.control(null, [Validators.required]),
                skinColor: this.fb.control(null, [Validators.required]),
                muscleTone: this.fb.control(null, [Validators.required]),
                apgarScore: this.fb.control({value: null, disabled: true})
            }),
            after10min: this.fb.group({
                reflexes: this.fb.control(null, [Validators.required]),
                heartActivity: this.fb.control(null, [Validators.required]),
                breathing: this.fb.control(null, [Validators.required]),
                skinColor: this.fb.control(null, [Validators.required]),
                muscleTone: this.fb.control(null, [Validators.required]),
                apgarScore: this.fb.control({value: null, disabled: true})
            }),
            firstFeeding: this.fb.control(null, [Validators.required]),
            noFeedingReason: this.fb.control(null),
            transferOfNewborn: this.fb.group({
                transferTime: this.fb.control(null),
                trasferToWard: this.fb.control(null),
                newbornMoved: this.fb.control(null),
                conditionOfNewbornTransfered: this.fb.control(null),
                doctorReferredNewborn: this.fb.array([], [Validators.maxLength(1)]),
                doctorAdmittedNewborn: this.fb.array([], [Validators.maxLength(1)]),
            })
        })
        this.formGroup = this.fb.group({
            fruit: this.fb.control({value: this.fruit || null, disabled: true}, [Validators.required]),
            birthTime: this.fb.control(null, [Validators.required]),
            wasBorn: this.fb.control(null, [Validators.required]),
            undead: this.fb.control(null),
            firstName: this.fb.control(null),
            secondName: this.fb.control(null),
            primordial: this.fb.control(null, [Validators.required]),
            occiputPosition: this.fb.control(null),
            umbilicalCord: this.fb.control(null),
            umbilicalCordRotationApprox: this.fb.control(null),
            umbilicalCordsRevolutionGeneration: this.fb.control(null),
            umbilicalCordBlood1: this.fb.control(null, [Validators.required]),
            umbilicalCordBlood2: this.fb.control(null, [Validators.required]),
            lactates: this.fb.control(null, [Validators.required]),
            headCircumference: this.fb.control(null, [Validators.required, Validators.min(0)]),
            gender: this.fb.control(null, [Validators.required]),
            colorAmnioticFluid: this.fb.control(null, [Validators.required]),
            height: this.fb.control(null, [Validators.required, Validators.min(0)]),
            weight: this.fb.control(null, [Validators.required, Validators.min(0)]),
            resuscitation: this.fb.control(null),
            resuscitationDurationHours: this.fb.control(null, [Validators.min(0)]),
            resuscitationDurationMinutes: this.fb.control(null, [Validators.min(0), Validators.max(59)]),
            resuscitationMethod: this.fb.control(null),

        });

        this.updateApgareScore('after1min');
        this.updateApgareScore('after5min');
        this.updateApgareScore('after10min');

        this.changeStatusOfControl({
            controlNameForListen: 'wasBorn',
            controlNameForUpdate: 'undead',
            enableValue: 'Negyvagimis'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'wasBorn',
            controlNameForUpdate: ['firstName', 'secondName'],
            enableValue: 'Gyvas naujagimis'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => {
                if (res === 'Gyvas naujagimis') {
                    this.formGroup.addControl('childData', this.liveChildGroup, {emitEvent: false});
                } else {
                    this.formGroup.removeControl('childData', {emitEvent: false});
                }
            });
        this.changeStatusOfControl({
            controlNameForListen: 'primordial',
            controlNameForUpdate: 'occiputPosition',
            enableValue: 'Galva',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'resuscitation',
            controlNameForUpdate: ['resuscitationDurationHours', 'resuscitationDurationMinutes', 'resuscitationMethod'],
            enableValue: 'Taip',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'firstFeeding',
            controlNameForUpdate: 'noFeedingReason',
            enableValue: 'Neinicijuotas',
            addValidators: [Validators.required]
        }, this.liveChildGroup).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    getControlValue(control: string): any {
        return this.formGroup.get(control).value;
    }

    private calculateApgarScore(group: string) {
        this.liveChildGroup.get(group).get('apgarScore').patchValue(
            (+this.liveChildGroup.get(group).get('reflexes').value || 0) +
            (+this.liveChildGroup.get(group).get('heartActivity').value || 0) +
            (+this.liveChildGroup.get(group).get('breathing').value || 0) +
            (+this.liveChildGroup.get(group).get('skinColor').value || 0) +
            (+this.liveChildGroup.get(group).get('muscleTone').value || 0)
        );
    }

    private updateApgareScore(group: string): void {
        this.calculateApgarScore(group);
        merge(
            this.liveChildGroup.get(group).get('reflexes').valueChanges,
            this.liveChildGroup.get(group).get('heartActivity').valueChanges,
            this.liveChildGroup.get(group).get('breathing').valueChanges,
            this.liveChildGroup.get(group).get('skinColor').valueChanges,
            this.liveChildGroup.get(group).get('muscleTone').valueChanges,
        )
            .pipe(
                debounceTime(10),
                takeUntilDestroyed(this.destroyRef)
                )
            .subscribe(() => {
                this.calculateApgarScore(group)
            })
    }
}
