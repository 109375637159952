import { Component } from "@angular/core";
import { ApiV2Inr } from '../../../shared/services/api-v2-inr';
import { RouteParams } from '../../../ng1.routeParams';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InrModel } from '../../../shared/models/inr.model';
@Component({
    selector: "app-inr-page",
    templateUrl: "./inr-page.component.html",
})
export class InrPageComponent {
    patientId: string;
    inrId: string;
    editable: boolean;

    inrData: InrModel;
    constructor(
        private params: RouteParams,
        private apiV2Inr: ApiV2Inr
    ) {
        this.getPatientId();
        if (!this.editable) {
            this.apiV2Inr.getInrById(this.patientId, {inrId: this.inrId}).pipe(takeUntilDestroyed())
                .subscribe(res => {
                    this.inrData = res;
                })
        }
    }

    getPatientId() {
        this.patientId = this.params.patientId;
        this.inrId = this.params.viewId;
        this.editable = window.location.href.includes('/new');
    }
}
