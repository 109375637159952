<nhr-record-widget closable="true" closed="false"
                   title="{{'Išdavimo dokumentai pagal receptą' | translate}}" mixed="true">
    <ul class="list-group list-group-flush">
        <li *ngIf="loading" class="list-group-item">
            <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
        </li>
        <li class="list-group-item mouse-pointer" *ngFor="let rd of relatedDocuments"
            tabindex="0"
            (click)="onRelatedClick(rd?.metadata?.id)"
            (keypress)="onRelatedClick(rd?.metadata?.id)">
            <div class="row">
                <div class="col-12">
                    <strong>
                        {{ rd?.metadata?.docTitle }}
                    </strong>
                    <strong [ngClass]="getStatusColor(rd?.dispenseData?.status?.code)">
                        {{ ' (' }}{{ rd?.dispenseData?.status?.code | translate }}{{ ')' }}
                    </strong>
                </div>
                <div class="col-12">
                    <small>
                        <span class="mb-0">
                            {{ 'Išduota' | translate }}{{ ':' }}
                        </span>
                        <span class="mb-0">
                            {{ moment(rd?.dispenseData?.dispense?.whenHandedOver).format("YYYY-MM-DD") }}
                        </span>
                        <span class="vr"></span>
                        <span class="mb-0">
                            {{ 'Pakanka iki' | translate }}{{ ':' }}
                        </span>
                        <span class="mb-0">
                            {{ moment(rd?.dispenseData?.dispense?.dueDate).format("YYYY-MM-DD") }}
                        </span>
                        <span class="vr"></span>
                        <span class="mb-0">
                            {{ 'Išduotas kiekis' | translate }}{{ ':' }}
                        </span>
                        <span class="mb-0">
                            {{ rd?.dispenseData?.dispense?.quantity?.value }}{{ ' ' }}{{ rd?.dispenseData?.dispense?.quantity?.unit?.displayValue }}
                        </span>
                    </small>
                </div>
                <div class="col-12">
                    <small>
                        <span class="fw-bold text-secondary mb-0">
                            {{ 'Liko išduoti' | translate }}{{ ': ' }}
                        </span>
                        <span class="fw-bold mb-0">
                            {{ rd?.dispenseData?.dispense?.leftToDispense?.quantity?.value }}{{ ' ' }}{{ rd?.dispenseData?.dispense?.leftToDispense?.quantity?.unit?.displayValue }}
                        </span>
                    </small>
                </div>
            </div>
        </li>
        <li *ngIf="relatedDocuments?.length === 0" class="list-group-item">
            {{ 'Duomenų nėra' | translate }}
        </li>
    </ul>
</nhr-record-widget>