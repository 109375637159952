import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { generateSelectionValuesFromEntity } from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-part-record',
    templateUrl: './e096-part-record.component.html',
})
export class E096PartRecordComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    @Input() fruit: string;
    formGroup: FormGroup;
    currentTime = new Date();
    cardiotocogramMethodOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Tiesioginė'
        },
        {
            id: '1',
            code: '1',
            name: 'Netiesioginė'
        }
    ];
    levelOfThefirstPartOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '-3'
        },
        {
            id: '1',
            code: '1',
            name: '-2'
        },
        {
            id: '2',
            code: '2',
            name: '-1'
        },
        {
            id: '3',
            code: '3',
            name: '0'
        },
        {
            id: '4',
            code: '4',
            name: '1'
        },
        {
            id: '5',
            code: '5',
            name: '2'
        },
        {
            id: '6',
            code: '6',
            name: '3'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
                fetalHeartRate: this.fb.control(null),
                cardiotocogramMethod: this.fb.control(null),
                cardiotocogramEvaluation: this.fb.control(null),
                colorAmnioticFluid: this.fb.control(null),
                firstPart: this.fb.control(null),
                levelOfThefirstPart: this.fb.control(null),
            });
    }
}
