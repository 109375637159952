ng1App.controller('doctor.patient.erx.PrintPrescriptions', 
    ['$scope', '$routeParams',
     function ($scope, $routeParams) {

    $scope.options = {
        /* E003 or E025 composition id */
        getCompositionId: function () {
            return $routeParams.documentId;
        }
    };

}]);