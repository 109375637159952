import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";
import {SearchResultModel} from "../app/shared/models/searchResult.model";

@Injectable({
    providedIn: 'root'
})
export class ApiV2ErxMedicationPrescriptions extends ConfigService {
    path = this.apiUrl + `/erx/doctor/MedicationPrescription`;

    getDocuments(params: any): Observable<SearchResultModel> {
        const url = `${this.path}/documents`;
        return this.http.get<SearchResultModel>(url, {params: params});
    }

    getRelated(id: any, params: any): Observable<any[]> {
        const url = `${this.path}/${id}/related`;
        return this.http.get<any[]>(url, {params: params});
    }
}