angular.module('erx.utils').directive('erxAutoGrow', ["$timeout", function ($timeout) {
  return {
        link: function (scope, element, attrs) {
            var elem = element[0],
                fakeElemStyles = 'position: absolute; top: -999px; left: 0; overflow: hidden; -webkit-box-sizing: content-box; border: 0; height: auto; box-sizing: border-box; visibility: hidden;',
                fakeElem = angular.element('<div class="fake" style="' + fakeElemStyles + '">x</div>'),
                stylesToCopy = ['border', 'border-top-width', 'border-end-width', 'border-bottom-width', 'border-start-width', 'font-size', 'font-style', 'font-weight', 'line-height', 'font-family', 'width', 'padding-left', 'padding-right', 'padding-top', 'padding-bottom'];

            if (!fakeElem[0].parentElement) {                
                angular.element(document.body).append(fakeElem);
            }
            fakeElem.css('min-height', element.css('height'));
            stylesToCopy.forEach(style => fakeElem.css(style, element.css(style)));
            elem.style.overflow = 'hidden';
            elem.style.height = 'auto';     

            function onUpdate() {
                const lineHeight = window.getComputedStyle(elem).getPropertyValue('line-height');
                const height = lineHeight.endsWith('px') ?
                    fakeElem[0].scrollHeight + parseInt(lineHeight.replace('px', '')) + 'px' :
                    lineHeight;
                fakeElem.html(element.val().replace(/\n/g, "<br />"));
                elem.style.height = height;
            }

            function onResize() {
                stylesToCopy.forEach(style => fakeElem.css(style, element.css(style)));
                onUpdate();
            }

            $timeout(onUpdate, 0);
            element.on('blur keyup change', onUpdate);
            angular.element(window).on('resize', onResize);

            scope.$on('$destroy', function () {
                fakeElem.remove();
                angular.element(window).off('resize', onResize);
            });
            }
    };
}]);


