angular.module('erx.prs').directive('erxActivePrescriptions', ["ErxPrescriptionsApiFactory", "$erxHelpers", function (ErxPrescriptionsApiFactory, $erxHelpers) {
  return {
    restrict: 'E',
    scope: {options: '='},
    replace: false,
    templateUrl: 'modules/erx/prs/small_prescription_lists/active_prescriptions.html',
    link: function (scope: any, element, attrs) {
      scope.h = $erxHelpers;
      scope.prescriptions = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
              {
                status: null,
                count: 3,
                page: 1
              });
    }
  };
}]);