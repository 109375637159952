<app-title [backPath]="backPath">
    {{'Kitas dokumentas'| translate}}
</app-title>
<!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
<app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
    (valueChange)="onChanges($event)"></app-common-patient-widget>
<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true"
    [title]="'doc-ref-attached-unsorted.component.name'|translate">
    <div class="widget-body">
        <form [formGroup]="unsortedForm">
            <div class="form-group row">
                <div class="col-sm-3">
                    <label for="templateTitle" class="col-form-label">Dokumento įkėlimo data</label>
                </div>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="templateTitle" formControlName="date" />
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-sm-3 col-form-label text-end">Dokumento tipas </label>
                <div class="col-sm-8">
                    <select id="category" class="form-select" formControlName="unsorted_document_type"
                        [ngClass]="{ 'is-invalid': unsortedForm.get('unsorted_document_type')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let docType of documentTypeArray" [value]="docType.name">
                            {{docType.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-sm-3 col-form-label text-end">Dokumento aprašymas</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="templateTitle" formControlName="desc" />
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-sm-3 col-form-label text-end">Gavėjo įstaiga</label>
                <div class="col-sm-8 d-flex flex-row">
                    <input formControlName="state" [(ngModel)]="search" typeaheadOptionField="name"
                        [typeahead]="suggestions$" [typeaheadAsync]="true"
                        [typeaheadItemTemplate]="customItemTemplate2"
                        (typeaheadNoResults)="typeaheadNoResults($event)" placeholder="Įveskite įstaigos pavadinimą"
                        class="form-control">
                    <ng-template #customItemTemplate2 let-model="item">
                        <span><strong>{{model.name}}</strong> - {{model.jarCode}}</span>
                    </ng-template>
                    <button class='btn btn-secondary btn-sm ms-2'
                        (click)="addRecipient(search)">+&nbsp;Pridėti</button>
                </div>
            </div>
            <div class="form-group row" *ngFor="let p of getRecipientFormControls(); let i = index">
                <div class="offset-sm-3 col-sm-8">
                    <div class="d-flex justify-content-between">
                        <div>
                            {{i+1}}. {{p.value.name | json}}, JAR:{{p.value.jarCode | json}}
                        </div>
                        <button class='btn btn-secondary btn-sm' (click)="removeRecipient(i)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-sm-3 col-form-label text-end">Dokumento kodas</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="templateTitle" formControlName="masterIdentifier"
                        maxlength="255" />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-end" for="patientVisibleDoc"></label>
                <div class="col-sm-5 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="visibility" id="patientVisibleDoc"
                            formControlName="visibility" [value]="true" />
                        <label class="form-check-label" for="patientVisibleDoc">
                            Pacientui matomas dokumentas
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="visibility" id="patientUnvisibleDoc"
                            formControlName="visibility" [value]="false" />
                        <label class="form-check-label" for="patientUnvisibleDoc">
                            Pacientui nematomas dokumentas
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="visibility" value="invisible"
                            id="unremovableDoc" formControlName="deleteStatus" />
                        <label class="form-check-label" for="unremovableDoc">
                            Dokumentas netrinamas
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-end" for="fileInput"></label>
                <div class="col-sm-8">
                    <!-- How to get this block visible? -->
                    <button class='btn btn-secondary btn-sm' type="button" (click)="fileInput.click()"
                        *ngIf="!fileSelected">
                        <span translate>AddFile</span>
                        <input #fileInput type="file" class="me-2" id="fileInput" (change)="onFileSelected($event)"
                            style="display:none;" />
                    </button>
                    <div id="fileName" *ngIf="fileSelected"
                        class="d-flex justify-content-between align-items-center">
                        {{ fileName }}
                        <button class='btn btn-secondary btn-sm' (click)="onFileRemove()" *ngIf="fileSelected"
                            translate>delete</button>
                    </div>
                </div>
            </div>
        </form>
        <!-- <pre class="card card-block card-header">Model: {{unsortedForm.value.state | json}}</pre> -->
    </div>
</nhr-record-widget>
<app-modification-actions [submitButtonDisabled]="true" [saveButtonDisabled]='saveStatus' [signButton]="true"
    [sealConfirmButton]="true" [signingSubmitButton]="true" (submit)="submitForm()" (save)="saveForm()"
    (clear)="clearForm()">
</app-modification-actions>
