import { Component, Input } from '@angular/core';
import { E010_Row } from '../e010-forms.model';

@Component({
    selector: 'app-e010-form-risk-factors',
    templateUrl: './e010-form-risk-factors.component.html',
})
export class E010FormRiskFactorsComponent {
    @Input() set data(value: {[key: string]: any}) {
        if(!value) return;
        this.risksData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formRiskFactors.unfavorableObstetric',
                value: value.e113Rv.highRiskFactors.unfavorableObstetricHistory.filter(item => item.checked).map(item => item.name)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formRiskFactors.unfavorableCondition',
                value: value.e113Rv.highRiskFactors.unfavorablePregnantCondition.filter(item => item.checked).map(item => item.name)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formRiskFactors.fetalPatology',
                value: value.e113Rv.highRiskFactors.fetalPathology.filter(item => item.checked).map(item => item.name)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formRiskFactors.pregnancyPatology',
                value: value.e113Rv.highRiskFactors.pregnancyPathology.filter(item => item.checked).map(item => item.name)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formRiskFactors.pregnantDiseases',
                value: value.e113Rv.highRiskFactors.pregnantDiseases.filter(item => item.checked).map(item => item.name)
            }
        ]
    };

    risksData: E010_Row[];

    constructor() {}
}
