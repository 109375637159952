ng1App.factory('ServicesSubscriberApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/subscriber';
    return $resource('', {}, {
        searchCatalog: {
            method: 'GET',
            url: URL_BASE,
            isArray: false
        },
        getCatalog: {
            method: 'GET',
            url: URL_BASE + '/:id',
            isArray: false
        }
    });
}]);

ng1App.service('ServicesSubscriberUploader', 
    ['$upload', 'apiUrl',
     function ($upload, apiUrl) {
    this.upload = function (form, file, successCb, errorCb) {
        var url = apiUrl + '/subscriber';

        $upload.upload({
            url: url,
            data: {data: form},
            file: (!file || !file.type) ? undefined : file
        }).progress(function (evt) {
        }).success(function (data, status, headers, config) {
            (successCb || angular.noop)(data);
        }).error(function (error) {
            (errorCb || angular.noop)(error);
        });
    };
}]);