import { Component, Input } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { forkJoin, map } from "rxjs";
import { ApiV2Documents } from "../../../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../../../ng1.routeParams";
import { EntityModel } from "../../../../../../shared/models/entity.model";
import { ApiV2Entities } from "../../../../../../shared/services/api-v2-entities";
import { DocumentTagEnum } from '../../../../../../shared/models/document-tag.enum';
import { PatientActivityAndHomeEnvironmentalAssessment } from "../../../../services/dp-aspn-context.service";
import {
    CustomTableRowsData,
    SubFormLayout
} from "./assessment-sub-form/home-environment-assessment-sub-form.component";
@Component({
    selector: "aspn-activity-home-environment-assessment-form",
    templateUrl: "./home-environment-assessment-form.component.html",
})
export class HomeEnvironmentAssessmentFormComponent {
    @Input() editable: boolean = true;
    @Input() data: PatientActivityAndHomeEnvironmentalAssessment;

    prefillData: any;
    documentTag = DocumentTagEnum.vnapvf;
    tableFormGroups: FormGroup = this.fb.group({
        visitsFrequency: this.fb.control(null),
        visitsDuration: this.fb.control(null),
        visitsPeriodicality: this.fb.control(null),
        firstSecondTableNote: this.fb.control(null),
        thirdTableNote: this.fb.control(null),
        forthTableNote: this.fb.control(null),
        conclusion: this.fb.control(null, Validators.required),
        nextDate: this.fb.control(null),
    });
    tableLayouts: { [key: string]: SubFormLayout } = {};
    firstTableData: CustomTableRowsData = {
        row: [
            {
                label: 'Valgymas',
                key: 'eating'
            },
            {
                label: 'Asmens higiena',
                key: 'hygiene'
            },
            {
                label: 'Maudymasis',
                key: 'bathing'
            },
            {
                label: 'Rengimasis',
                key: 'preparing'
            },
            {
                label: 'Naudojimasis tualetu',
                key: 'toilet'
            },
        ],
        radioCellStyle: 'col',
        labelStyle: 'col-2'
    };
    secondTableData: CustomTableRowsData = {
        row: [
            {
                label: 'Pasivertimas lovoje',
                key: 'changingInBed'
            },
            {
                label: 'Atsisėdimas lovoje',
                key: 'sittingInBed'
            },
            {
                label: 'Atsistojimas iš sėdimos padėties',
                key: 'standsUp'
            },
            {
                label: 'Perikėlimas nuo lovos / kėdės',
                key: 'movingFromBed'
            },
            {
                label: 'Persikėlimas tualete',
                key: 'movingToToilet'
            },
            {
                label: 'Persikėlimas vonioje duše',
                key: 'shower'
            },
            {
                label: 'Ėjimas',
                key: 'walking'
            },
            {
                label: 'Važiavimas vežimėliu',
                key: 'wheelchair'
            },
            {
                label: 'Lipimas laiptais',
                key: 'climbing'
            },
        ],
        radioCellStyle: 'col',
        labelStyle: 'col-2'
    };
    thirdTableData: CustomTableRowsData = {
        row: [
            {
                label: 'Jutimai',
                key: 'senses'
            },
            {
                label: 'Raumenų jėga',
                key: 'muscle'
            },
            {
                label: 'Judesių amplitudė',
                key: 'motionRange'
            },
            {
                label: 'Pažinimo funkcijos',
                key: 'cognitive'
            },
            {
                label: 'Psichosocialinės funkcijos',
                key: 'psychosocial'
            },
            {
                label: 'Sensorinė integracija',
                key: 'sensory'
            },
            {
                label: 'Pusiausvyra',
                key: 'balance'
            },
        ],
        radioCellStyle: 'col',
        labelStyle: 'col-2'
    };
    forthTableData: CustomTableRowsData = {
        row: [
            {
                label: 'Namų durys',
                key: 'door'
            },
            {
                label: 'Slenksčiai',
                key: 'thresholds'
            },
            {
                label: 'Laiptai',
                key: 'stairs'
            },
            {
                label: 'Prieangis',
                key: 'porch'
            },
            {
                label: 'Gyvenamasis kambarys',
                key: 'livingRoom'
            },
            {
                label: 'Virtuvė / Valgomasis',
                key: 'kitchen'
            },
            {
                label: 'Tualeto kambarys',
                key: 'toilet'
            },
            {
                label: 'Vonios kambarys',
                key: 'bathroom'
            },
            {
                label: 'Miegamasis',
                key: 'bedroom'
            },
            {
                label: 'Apšvietimas',
                key: 'lighting'
            },
        ],
        radioCellStyle: 'col',
        labelStyle: 'col-2'
    };

    patientSupportLevelColumns: EntityModel[];
    patientDysfunctionLevelColumns: EntityModel[];
    patientEnvironmentComplianceLevelColumns: EntityModel[];
    repeatPeriodsClassifiers: EntityModel[];
    repeatPeriods: string[];

    constructor(
        private readonly apiV2Entities: ApiV2Entities,
        private readonly fb: FormBuilder,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents
    ) {

        forkJoin([
            this.apiV2Entities.getEntitiesList('patient-support-level'),
            this.apiV2Entities.getEntitiesList('patient-dysfunction-level'),
            this.apiV2Entities.getEntitiesList('patient-environment-compliance-level'),
            this.apiV2Entities.getEntitiesList('repeat-period')
        ]).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.patientSupportLevelColumns = res[0];
                this.generateTableData(res[0], this.firstTableData, 'first');
                this.generateTableData(res[0], this.secondTableData, 'second');
                this.patientDysfunctionLevelColumns = res[1];
                this.generateTableData(res[1], this.thirdTableData, 'third');
                this.patientEnvironmentComplianceLevelColumns = res[2];
                this.generateTableData(res[2], this.forthTableData, 'forth');
                this.repeatPeriods = res[3].map(item => item.name);
                this.repeatPeriodsClassifiers = res[3];

                this.updateForm();
            });
    }

    async ngOnInit(): Promise<void> {
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getFormControl(controlName: string, formGroup = this.tableFormGroups): FormControl {
        return formGroup.get(controlName) as FormControl
    }

    private createTableFormGroup(controlNames: string[]): FormGroup {
        const formGroupConfig = {};
        controlNames.forEach(name => {
            formGroupConfig[name] = this.fb.group({
                radio: this.fb.control(null, Validators.required),
                input: this.fb.control(null)
            });
        });

        return this.fb.group(formGroupConfig);
    }

    private generateTableData(res: EntityModel[], tableRows: CustomTableRowsData, tableKey: string) {
        const radioCell = {
            type: 'radioButton',
            cellClass: tableRows.radioCellStyle,
            formControlName: 'radio',
        };
        const sharedCells = [
            ...new Array(res.length).fill(radioCell),
            {
                type: 'inputText',
                cellClass: 'col-3 text-center d-flex align-items-center justify-content-center',
                formControlName: 'input',
            }
        ].map((cell, index) => ({
            ...cell,
            colName: res[index]?.name ?? ""
        }));
        this.tableFormGroups.addControl(tableKey, this.createTableFormGroup(tableRows.row.map(row => row.key)))

        const rows = tableRows.row.map(row => ({
            formGroup: this.tableFormGroups.get(tableKey).get(row.key) as FormGroup,
            label: row.label,
            labelCellClass: tableRows.labelStyle
        }))

        this.tableLayouts[tableKey] = {
            rows: rows,
            sharedCells
        };

    }

    chosePeriod() {
        if (!!this.tableFormGroups.get('visitsPeriodicality').value) {
            this.tableFormGroups.get('visitsFrequency').setValidators(Validators.required);
        } else {
            this.tableFormGroups.get('visitsFrequency').clearValidators();
        }
        this.tableFormGroups.get('visitsFrequency').updateValueAndValidity();
    }

    private updateForm() {
        if (!this.data) return;

        this.tableFormGroups.patchValue(this.data);
        this.tableFormGroups.get('visitsPeriodicality').patchValue(this.data.visitsPeriodicality?.name);
        this.updateSubForm(this.tableFormGroups.get('first') as FormGroup, this.data.supportLevel)
        this.updateSubForm(this.tableFormGroups.get('second') as FormGroup, this.data.supportLevel)
        this.updateSubForm(this.tableFormGroups.get('third') as FormGroup, this.data.dysfunctionLevel)
        this.updateSubForm(this.tableFormGroups.get('forth') as FormGroup, this.data.environmentComplianceLevel)
    }

    private updateSubForm(formGroup: FormGroup, data: { [key: string]: EntityModel | string }): void {
        for (const key in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(key)) {
                if (data[key]) {
                    formGroup.get(key + '.radio').setValue((data[key] as EntityModel).id);
                }
                const noteKey = key + 'Note';
                if (data[noteKey]) {
                    formGroup.get(key + '.input').setValue(data[noteKey]);
                }
            }
        }
    }

    private getSubFormValues(formGroup: FormGroup, valuesArray: EntityModel[]): { [key: string]: EntityModel | string } {
        let subFormValue = {};

        for (const key in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(key)) {
                const radioValue = valuesArray.find(item => formGroup.get(key + '.radio').value === item.id);
                const inputValue = formGroup.get(key + '.input').value;

                subFormValue = {
                    ...subFormValue,
                    [key]: radioValue,
                    [key + 'Note']: inputValue
                }
            }
        }

        return subFormValue
    }

    private getFormData(): PatientActivityAndHomeEnvironmentalAssessment {
        return {
            supportLevel: {
                ...this.getSubFormValues(this.tableFormGroups.get('first') as FormGroup, this.patientSupportLevelColumns),
                ...this.getSubFormValues(this.tableFormGroups.get('second') as FormGroup, this.patientSupportLevelColumns)
            },
            dysfunctionLevel: this.getSubFormValues(this.tableFormGroups.get('third') as FormGroup, this.patientDysfunctionLevelColumns),
            environmentComplianceLevel: this.getSubFormValues(this.tableFormGroups.get('forth') as FormGroup, this.patientEnvironmentComplianceLevelColumns),
            visitsFrequency: this.tableFormGroups.get('visitsFrequency').value,
            visitsDuration: this.tableFormGroups.get('visitsDuration').value,
            visitsPeriodicality: this.repeatPeriodsClassifiers.find(item => item.name === this.tableFormGroups.get('visitsPeriodicality').value),
            firstSecondTableNote: this.tableFormGroups.get('firstSecondTableNote').value,
            thirdTableNote: this.tableFormGroups.get('thirdTableNote').value,
            forthTableNote: this.tableFormGroups.get('forthTableNote').value,
            conclusion: this.tableFormGroups.get('conclusion').value,
            nextDate: this.tableFormGroups.get('nextDate').value,
        } as PatientActivityAndHomeEnvironmentalAssessment
    }

    save() {
        console.log(this.getFormData())
    }

    resetForm() {
        this.tableFormGroups.reset();
    }
}
