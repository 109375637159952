import { Component, DestroyRef, Input } from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-form-control-validation',
    templateUrl: './form-control-validation.component.html',
})
export class FormControlValidationComponent {
    @Input() set fControl(control: AbstractControl) {
        if (!control) {
            return;
        }
        control?.statusChanges?.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.updateErrorMessage(control);
            });
    };
    @Input() showCondition: boolean = true;
    @Input() customError: string;

    errorMessage: string;
    dirty: boolean;

    constructor(private destroyRef: DestroyRef) {}

    private updateErrorMessage(control: AbstractControl) {
        this.dirty = control.dirty;
        const errors = control.errors || this.customError;
        if (errors) {
            if (typeof errors === 'string') {
                this.errorMessage = errors;
            } else if (errors['required'] && (typeof control?.value !== 'object' || control?.value === null)) {
                this.errorMessage = 'This field is required.';
            } else if (errors['required'] && typeof control?.value === 'object') {
                this.errorMessage = 'At least 1 value is required.';
            } else if (errors['minlength']) {
                this.errorMessage = `Minimum length is ${errors['minlength'].requiredLength}.`;
            } else if (errors['maxlength']) {
                this.errorMessage = `Maximum length is ${errors['maxlength'].requiredLength}.`;
            } else if (errors['max']) {
                this.errorMessage = `Maximum value is ${errors['max'].max}.`;
            } else if (errors['min']) {
                this.errorMessage = `Minimal value is ${errors['min'].min}.`;
            } else if (errors['pattern']) {
                this.errorMessage = 'Invalid input format.'
            } else if (errors['email']) {
                this.errorMessage = 'Invalid email format.'
            } else {
                this.errorMessage = 'Invalid input.'
            }
        } else {
            this.errorMessage = null;
        }
    }
}
