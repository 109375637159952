<div id="{{data.id}}" (click)="onCardClick(data.id, data.documentType)" (keypress)="onCardClick(data.id, data.documentType)" tabindex="0">
    <div class="d-flex justify-content-between">
        <div>
            <div class="row">
                <div class=" text">
                    <span class="text-secondary">{{data.reportDate | date:'yyyy-MM-dd'}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col mb-1">
                    <span class="fw-bold">{{data.cardName}}</span>
                </div>
            </div>
            <div class="text">
                <span class="text">{{data.TLK_10_AM}} - </span>
                <span class="text">{{data.tumorDiagnosis}}</span>
                <span class="vr"></span>
                <span class="text">{{data.diagnosisDate | date:'yyyy-MM-dd'}}</span>
            </div>
            <div class="text-secondary">
                <span class="text">{{data.author.speciality.name}}</span>
                <span class="vr"></span>
                <span class="text text-info">{{practitionerFullName}}</span>
                <span class="vr"></span>
                <span class="text">{{data.author.practitionerOrganization.name}}</span>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end" >
            <!-- ??? off-topic in this component, but related with issue: -->
            <!-- ??? do we need a title? -->
            <!-- ??? Where are view and download buttons -->
            <!-- ??? button more / or pagination -->
            
            <!-- ??? what buttons we should choose here? -->
            <button class="btn btn-primary btn-square-sm" (click)="onEditClick(data.id, data.documentType)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>