import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import { Pagination } from "./pagination-bar.constants";

@Component({
    selector: "pagination-bar",
    templateUrl: "./pagination-bar.component.html",
})
export class PaginationBarComponent implements OnChanges {
    @Input() dotsJump: number = 9;
    @Input() pagesToShowArrows: number = 4;
    @Input() pagesTillCurrent: number = 4;
    @Input() pageSize: number = 10;
    @Input() rowsCount: number;
    @Input() pagesCount: number;
    @Input() currentPage: number = 0;
    @Input() noEventOnChange: boolean = false;

    @Output() paginating: EventEmitter<Pagination> = new EventEmitter<Pagination>();

    realCurrentPage: number;
    pagesToIterate: Array<number> = [];
    typedPage: number;

    ngOnChanges(changes: SimpleChanges): void {
        console.debug(`pagebar change detected: ${this.currentPage} / ${this.pageSize} / ${this.pagesCount}`);
        this.setPagination(this.noEventOnChange);
    }

    public setPagination(noEventOnChange: boolean = false) {
        this.realCurrentPage = this.currentPage + 1;

        const pages = Array.from(Array(this.pagesCount).keys());
        this.pagesToIterate = pages.filter(
            (p) =>
                p !== 0 &&
                p !== this.pagesCount - 1 &&
                p <= this.currentPage + this.pagesTillCurrent &&
                p >= this.currentPage - this.pagesTillCurrent
        );

        if (!noEventOnChange) {
        this.paginating.emit({
            count: this.pageSize,
            page: this.realCurrentPage,
        });
    }
    }

    public onFirstPageSelect() {
        this.currentPage = 0;
        this.setPagination();
    }

    public onLastPageSelect() {
        this.currentPage = this.pagesCount - 1;
        this.setPagination();
    }

    public onPageSelect(page: number) {
        this.currentPage = page;
        this.setPagination();
    }

    public onPageEnter(page: number) {
        if (page && page > 0 && page <= this.pagesCount) {
            this.typedPage = page;
            this.currentPage = page - 1;
            this.setPagination();
        }
    }

    public onDotsSelect(page: number) {
        this.currentPage = page;
        this.setPagination();
    }

    public onPrevSelect() {
        this.currentPage = this.currentPage - 1;
        this.setPagination();
    }

    public onNextSelect() {
        this.currentPage = this.currentPage + 1;
        this.setPagination();
    }

    public showPrev(orExact?: boolean) {
        return orExact ? this.realCurrentPage >= this.pagesToShowArrows : this.currentPage > this.pagesToShowArrows;
    }

    public showPrevDots() {
        return this.showPrev(true) && this.realCurrentPage - this.pagesToShowArrows > 0;
    }

    public showNext(orExact?: boolean) {
        return orExact
            ? this.pagesCount - this.realCurrentPage >= this.pagesToShowArrows
            : this.pagesCount - this.realCurrentPage > this.pagesToShowArrows;
    }

    public showNextDots() {
        return this.showNext(true) && this.realCurrentPage !== this.pagesCount - 1;
    }

    public showAfterFirst() {
        return this.pagesCount - this.realCurrentPage === 2;
    }

    public showBehindLast() {
        return this.pagesCount - this.realCurrentPage - this.pagesTillCurrent === 2;
    }

    public calculateBackJump() {
        const page: number = this.currentPage - this.dotsJump;
        return page >= 0 ? page : 1;
    }

    public calculateForwardJump() {
        const page: number = this.currentPage + this.dotsJump;
        return page <= this.pagesCount ? page : this.pagesCount - 2;
    }

    public getButtonClass(page: number): string {
        return page === this.currentPage ? "btn-primary" : "btn-outline-primary";
    }
}
