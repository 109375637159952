import {Component, OnInit} from '@angular/core';
import {PortalTypeEnum} from "../../../../shared/models/portalType.enum";

@Component({
    selector: 'dp-doc-ref-consent-list',
    templateUrl: './dp-doc-ref-consent-list.component.html'
})
export class DpDocRefConsentListComponent implements OnInit {

    portalType: PortalTypeEnum = PortalTypeEnum.DP;

    ngOnInit(): void {
    }
}