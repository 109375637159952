<h6>{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.subTitle' | translate}} {{fruit | uppercase}}</h6>
<div class="row req">
    <div class="form-group col-6 col-lg-3 flex-shrink-1">
        <app-date-picker
            labelText="{{'doc.pregnancyDashboard.e096Part.formNewborn.timeOfAmniocentesis' | translate}}:"
            labelClass="form-label flex-grow-1 col-form-label content-box h-2lh"
            inputId="timeOfAmniocentesis"
            suffixIcon="fa fa-calendar"
            dateInputFormat="YYYY-MM-DD HH:mm"
            [withTimepicker]="true"
            [maxDate]="currentTime"
            [fControl]="formGroup.get('timeOfAmniocentesis')"
        ></app-date-picker>
        <app-form-control-validation [fControl]="formGroup.get('timeOfAmniocentesis')" />
    </div>
    <div class="col-6 col-lg-3">
        <app-select
            labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.amnioticFluidColor' | translate}}:"
            labelClass="content-box h-2lh"
            [id]="'part-colorAmnioticFluid'"
            [fControl]="formGroup.get('colorAmnioticFluid')"
            [values]="selectionValuesFromEntity(answersVariants?.['fetalFluidColor'])"
        ></app-select>
        <app-form-control-validation [fControl]="formGroup.get('colorAmnioticFluid')" />
    </div>
    <div class="col-6 col-lg-3">
        <app-select
            labelText="{{'doc.pregnancyDashboard.e096Part.formNewborn.amnioticFluidAmount' | translate}}:"
            labelClass="content-box h-2lh"
            [id]="'part-amountOfAmnioticFluid'"
            [fControl]="formGroup.get('amountOfAmnioticFluid')"
            [values]="selectionValuesFromEntity(amountOfAmnioticFluidOptions)"
        ></app-select>
        <app-form-control-validation [fControl]="formGroup.get('amountOfAmnioticFluid')" />
    </div>
    <div class="col-6 col-lg-3">
        <app-select
            labelText="{{'doc.pregnancyDashboard.e096Part.formNewborn.firstPart' | translate}}:"
            labelClass="content-box h-2lh norq"
            [id]="'part-firstPart'"
            [fControl]="formGroup.get('firstPart')"
            [values]="selectionValuesFromEntity(answersVariants?.['fetalPrimordial'])"
        ></app-select>
        <app-form-control-validation [fControl]="formGroup.get('firstPart')" />
    </div>
</div>