// Modalinis langas, leidžiantis užpildyti skyrimą pagal ankstesnį
angular.module('erx.utils').service('$erxModalSearch', ["$modal", "$rootScope", "$timeout", "$q", "ErxPrescriptionsApiFactory", "gettextCatalog", function ($modal, $rootScope, $timeout, $q, ErxPrescriptionsApiFactory, gettextCatalog) {
  var scope = $rootScope.$new();
  var deferred;
  scope.prs = {};
  scope.confirmed = function () {
    deferred.resolve(scope.selected.metadata.id);
    confirm.hide();
  };

  scope.select = function (p) {
    scope.selected = p;
  }

  scope.isLoading = function () {
    return !scope.prs.$resolved;
  }

  scope.search = function (query) {
    scope.prs = ErxPrescriptionsApiFactory.getOlderMedPrescriptions
            ({patientId: scope.patientId, prescriptionType: scope.prescriptionType, query: query ? query : ''});
  }

  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-search/erxModalSearch.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;

  confirm.show = function (opts) {
    if (scope.$$childHead) {
      delete scope.$$childHead.query;
    } else {
      delete scope.query;
    }
    scope.patientId = opts.patientId;
    scope.prescriptionType = opts.prescriptionType;
    delete scope.selected;
    scope.prs = {};
    scope.prs = ErxPrescriptionsApiFactory.getOlderMedPrescriptions
            ({patientId: scope.patientId, prescriptionType: scope.prescriptionType, query: ''});
    scope.title = opts.title ? opts.title : gettextCatalog.getString('Skyrimo paieška');
    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };

  return confirm;
}]);