import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiV2Entities } from '../../services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../models/entity.model';
import { generateSelectionValuesFromEntity } from '../../../doctor/ngn/components/e096/e096-utils/utils';

@Component({
    selector: "app-antibiotics",
    templateUrl: "./antibiotics.component.html",
})
export class AntibioticsComponent implements OnChanges {
    @Input() formArray: FormArray;
    @Input() editable: boolean = true;

    substanceGroup: FormGroup = this.fb.group({
        activeSubstance: this.fb.control(null, [Validators.required]),
        dose: this.fb.control(null, [Validators.required]),
        numberOfDoses: this.fb.control(null, [Validators.required]),
    });
    editSubstanceId: number = null;
    substancesList: EntityModel[];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    get substances(): FormArray {
        return this.formArray
    }
    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
    ) {
        this.apiV2Entities.getEntitiesList('medicinal-preparations-and-reimbursable-MPPs')
            .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.substancesList = res;
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('editable')) {
            if (!this.editable) {
                this.substanceGroup.disable({emitEvent: false});
            }
        }
    }

    addSubstance() {
        if (this.editSubstanceId === null) {
            this.substances.push(this.fb.group({
                activeSubstance: this.substanceGroup.get('activeSubstance').value,
                dose: this.substanceGroup.get('dose').value,
                numberOfDoses: this.substanceGroup.get('numberOfDoses').value,
            }));
        } else {
            this.substances.at(this.editSubstanceId).setValue(this.substanceGroup.value);
            this.editSubstanceId = null;
        }
        this.substanceGroup.reset();
    }

    delete(i: number) {
        this.substances.removeAt(i);
        this.editSubstanceId = null;
        this.substanceGroup.reset();
    }

    editSubstance(i: number) {
        this.substanceGroup.patchValue(this.substances.at(i).value);
        this.editSubstanceId = i;
    }
}
