import {Component} from "@angular/core";
import {MessageService} from "../../services/utils/message.service";

@Component({
    selector: 'nhr-msg-global',
    templateUrl: 'nhr-msg-global.html'
})
export class NhrMsgGlobalComponent {

    constructor(private msgService: MessageService) {
    }

    getItems(): any[] {
        return this.msgService.messages['global']
    }

    close(index: number) {
        this.msgService.remove(index, 'global')
    }
}