angular.module('erx.utils').directive('erxDynamicName', ["$compile", "$parse", function($compile, $parse) {
  return {
    restrict: 'A',
    terminal: true,
    priority: 100000,
    link: function(scope, elem) {
      var name = $parse(elem.attr('erx-dynamic-name'))(scope);
      // $interpolate() will support things like 'skill'+skill.id where parse will not
      elem.removeAttr('erx-dynamic-name');
      elem.attr('name', name);
      $compile(elem)(scope);
    }
  };
}]);