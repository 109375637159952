export enum ErxFilterAdditionalStatusEnum {
    compensated = 'Kompensuojamas',
    not_compensated = 'Nekompensuojamas',
    isBrand = 'Vardinis',
    biological = 'Biologinis',
    narcotic = 'N',
    psychotropic = 'P',
    mti = 'MTI',
    inr = 'INR',
    disruption = 'disruption'
}