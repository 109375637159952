<div class="collapse multi-collapse" id="advancedFilter">
    <div class="custom-search border-0 border-dashed-bottom">
        <div class="custom-filter p-4">
            <div class="row">
                <div class="col-md-6 mb-3">
                    <strong>{{ 'Būsena' | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('status')"
                        class="mt-2 d-block"
                        [dropdownDataList]="statuses"
                        [singleSelection]="true"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                        [placeholder]="'Pasirinkti' | translate"
                    ></app-multiselect-dropdown>
                </div>
                <div class="col-md-6 col-xxl-3 mb-3">
                    <strong>{{ 'Forma' | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('form')"
                        class="mt-2 d-block"
                        [dropdownDataList]="forms"
                        [singleSelection]="true"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                        [placeholder]="'Pasirinkti' | translate"
                    ></app-multiselect-dropdown>
                </div>
                <div class="col-md-6 col-xxl-3 mb-3">
                    <strong>{{ 'Paskirta' | translate }}</strong>
                    <app-input
                        id="prescribed"
                        [fControl]="formGroup.get('prescribed')"
                        class="mt-2 d-block"
                    ></app-input>
                </div>
                <div class="col-md-6 col-xxl-6 mb-3">
                    <strong>{{ 'Išrašymo data' | translate }}</strong>
                    <div class="row">
                        <app-date-picker class="col-md mt-2" [fControl]="formGroup.get('fromDate')" inputId="fromDate"
                                         [placeholder]="'pp.dia.sea.dat.from' | translate"
                                         suffixIcon="fa fa-calendar"></app-date-picker>
                        <app-date-picker class="col-md mt-2" [fControl]="formGroup.get('toDate')" inputId="toDate"
                                         [placeholder]="'pp.dia.sea.dat.to' | translate"
                                         suffixIcon="fa fa-calendar"></app-date-picker>
                    </div>
                </div>
                <div class="col-md-6 col-xxl-3 mb-3">
                    <strong>{{ 'Numeris' | translate }}</strong>
                    <app-input
                        id="numberId"
                        type="number"
                        [min]="'0'"
                        [fControl]="formGroup.get('id')"
                        class="mt-2 d-block"
                    ></app-input>
                </div>
                <div class="col-md-6 col-xxl-3 mb-3">
                    <strong>{{ 'Tipas' | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('type')"
                        class="mt-2 d-block"
                        [dropdownDataList]="types"
                        [singleSelection]="true"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                        [placeholder]="'Pasirinkti' | translate"
                    ></app-multiselect-dropdown>
                </div>
                <div class="col-md-6 col-xxl-3 d-flex flex-column justify-content-end mb-3">
                    <app-checkbox inputId="myPrescriptions"
                                  [fControl]="formGroup.get('myPrescriptions')"
                                  labelText="Mano įrašai"
                                  class="mb-1">
                    </app-checkbox>
                </div>
                <div class="col-md-6 col-xxl-3 d-flex flex-column justify-content-end mb-3">
                    <app-checkbox inputId="isUsing"
                                  [fControl]="formGroup.get('isUsing')"
                                  labelText="Vartojami vaistai/MPP/MP"
                                  class="mb-1">
                    </app-checkbox>
                </div>
                <div class="col d-flex justify-content-end gap-3 mb-3">
                    <button class="btn btn-outline-primary"
                            (click)="clearFilters()">{{ 'pp.dia.sea.clearFilt' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="applyFilters()">{{ 'pp.dia.sea.srch' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>