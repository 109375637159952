import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2E110 extends ConfigService {

    path = this.apiUrl + '/e110';

    getE110FormView(id: string): Observable<any> {
        return this.http.get<any>(this.path + '/view/' + id);
    }

    submitE110Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/create/' + id, payload);
    }

    saveE110Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/save/' + id, payload);
    }

    signE110Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/sign/' + id, payload);
    }

    signingSubmitE110Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/signingSubmit/' + id, payload);
    }

    sealE110Form(payload: any, id: string): Observable<any> {
        return this.http.post<any>(this.path + '/seal/' + id, payload);
    }

    getGMPList(query?: any): Observable<any[] | FilteredDataModel> {
        let params = new HttpParams();

        // // params = params.append('ftq', query);
        // // params = params.append('page', String(page));
        //console.log("query",query);
        if(query.quick_search){
            //console.log("bbbbb");
            params = params.append('ftq', query.quick_search);
            params = params.append('page', String(1)); // 1 = page
            return this.http.get<FilteredDataModel>(this.path + '/list', {params});
        } else if (query.dateFrom || query.dateTo || query.recipient || query.specialist || query.unsorted_document_type){
            //console.log("ccccc");
            params = params.append('dateFrom', Date.parse(query.dateFrom) ||"");
            params = params.append('dateTo', Date.parse(query.dateTo) ||"");
            params = params.append('recipient', query.recipient ||"");
            params = params.append('specialist', query.specialist ||"");
            //params = params.append('unsorted_document_type', query.unsorted_document_type ||"");
            params = params.append('page', String(1)); // 1 = page
            return this.http.get<FilteredDataModel>(this.path + '/list', {params});
        }
        //console.log("ddddd");
        return this.http.get<any[]>(this.path + '/list');
    }
}