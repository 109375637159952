ng1App.factory('DgcApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/certificates-dgc';
    return $resource('', {}, {
        getPatientCertificateTypes: {
            method: 'GET',
            url: URL_BASE + '/current/certificateTypes',
            isArray: true
        },
        getDoctorPatientCertificateTypes: {
            method: 'GET',
            url: URL_BASE + '/patients/:patientId/certificate/certificateTypes',
            isArray: true
        },
        getPatientAllowMessages: {
            method: 'GET',
            url: URL_BASE + '/current/allowMessages'
        }
    });
}]);