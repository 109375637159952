import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";
import {FilteredDataModel} from "../app/shared/models/filtered-data.model";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Alerts extends ConfigService {
    path = this.apiUrl + '/alerts';

    getComments(): Observable<any> {
        const url = `${this.path}/comments`;
        return this.http.get<any>(url);
    }

    getCommentsPermissions(): Observable<any[]> {
        const url = `${this.path}/commentsPermissions`;
        return this.http.get<any[]>(url);
    }

    getCommentsVisibilities(): Observable<any[]> {
        const url = `${this.path}/commentsVisibilities`;
        return this.http.get<any[]>(url);
    }

    addComment(comment: any): Observable<any> {
        const url = `${this.path}/addComment`;
        return this.http.post<any>(url, comment);
    }

    cancelComment(comment: any): Observable<any> {
        const url = `${this.path}/:alertId/cancel`;
        return this.http.post<any>(url, comment);
    }

    prefillMessage(): Observable<any> {
        const url = `${this.path}/prefillMessage`;
        return this.http.get<any>(url);
    }

    sendMessage(message: any): Observable<any> {
        const url = `${this.path}/sendMessage`;
        return this.http.post<any>(url, message);
    }

    replyMessage(message: any): Observable<any> {
        const url = `${this.path}/replyMessage`;
        return this.http.post<any>(url, message);
    }

    searchMessages(params: any): Observable<FilteredDataModel> {
        const url = `${this.path}/searchMessages`;
        return this.http.get<FilteredDataModel>(url, {params: params});
    }

    getThread(): Observable<any[]> {
        const url = `${this.path}/getThread`;
        return this.http.get<any[]>(url);
    }

    deleteMessages(message: any): Observable<any> {
        const url = `${this.path}/deleteMessages`;
        return this.http.post<any>(url, message);
    }

    getUnreadCount(): Observable<any> {
        const url = `${this.path}/getUnreadCount`;
        return this.http.get<any>(url);
    }
}
