ng1App.service('PageClosingDialog', 
    ['$location', '$modalConfirm',
     function ($location, $modalConfirm) {
	
    var stopWatchingLocationChange = null;
    var dialogTitle = null;
    var dialogText = null;
	
    var handleLocationChange = function (event) {
    	// Prevent location change
    	event.preventDefault();
    	
    	// Save target location data
    	var path = $location.path();
    	var search = $location.search();
    	var hash = $location.hash();
    	
    	// Show confirmation dialog window to user
        $modalConfirm.show({title: dialogTitle, content: dialogText}).then(function () {
        	// Change location
        	$location.path(path).search(search).hash(hash);
        	// Stop intercepting location change
        	stopWatchingLocationChange();
        });
    };
	
	this.init = function (scope, title, text) {
		dialogTitle = title;
		if (!dialogTitle) {
			dialogTitle = 'Puslapio uždarymas';
		}
		dialogText = text;
		if (!dialogText) {
			dialogText = 'Ar tikrai norite išeiti iš šio puslapio?';
		}
		
		stopWatchingLocationChange = scope.$on('$locationChangeStart', handleLocationChange);
	};

	this.dismiss = function(){
		stopWatchingLocationChange();
	}
}]);