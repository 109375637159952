<!-- Toggle show/hide with classes .show and .hide -->
<div class="sveik-timepicker" [ngClass]="pickerOpened ? 'show' : 'hide'">
    <label
        [for]="inputId"
        *ngIf="labelText"
        [class]="
            labelClass?.includes('col-form-label')
                ? 'flex-grow-1 ' + (labelClass ?? '')
                : 'flex-grow-1 form-label ' + (labelClass ?? '')
        "
    >
        {{ labelText | translate }}
    </label>
    <!-- is-invalid goes into the form-control -->
    <div>
        <div class="sveik-timepicker-inputs" [ngClass]="{ 'is-invalid': !fControl?.valid }">
            <input
                type="number"
                class="form-control"
                autocomplete="off"
                min="0"
                max="23"
                maxlength="2"
                [id]="inputHoursId"
                [disabled]="fControl.disabled"
                [value]="fControl.value ? fControl.value.substring(0, 2) : ''"
                [formControl]="hourFormControl"
                (click)="openClose(true)"
                (clickOutside)="openClose(false)"
                (change)="setTime(true, null, null, null, $event)"
            />
            <span>:</span>
            <input
                type="number"
                class="form-control"
                autocomplete="off"
                min="0"
                max="59"
                maxlength="2"
                [id]="inputMinutesId"
                [disabled]="fControl.disabled"
                [value]="fControl.value ? fControl.value.substring(3, 5) : ''"
                [formControl]="minuteFormControl"
                (click)="openClose(true)"
                (clickOutside)="openClose(false)"
                (change)="setTime(false, null, null, null, $event)"
            />
        </div>
        <div class="sveik-timepicker-popover gap-2">
            <div class="sveik-timepicker-hours">
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="setTime(true, null, false, $event.currentTarget.nextSibling)"
                >
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                </button>
                <div class="sveik-timepicker-hours-container">
                    <!-- Loop through hours 00 - 23 -->
                    <button
                        type="button"
                        *ngFor="let hour of hoursArray"
                        [value]="hour"
                        [ngClass]="{ selected: isSelected(true, hour) }"
                        (click)="setTime(true, $event)"
                    >
                        {{ hour | number : "2.0" }}
                    </button>
                </div>
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="setTime(true, null, true, $event.currentTarget.previousSibling)"
                >
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </button>
            </div>
            <div class="sveik-timepicker-minutes">
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="setTime(false, null, false, $event.currentTarget.nextSibling)"
                >
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                </button>
                <div class="sveik-timepicker-minutes-container">
                    <!-- Loop through minutes 00 - 59 -->
                    <button
                        type="button"
                        *ngFor="let minute of minutesArray"
                        [value]="minute"
                        [ngClass]="{ selected: isSelected(false, minute) }"
                        (click)="setTime(false, $event)"
                    >
                        {{ minute | number : "2.0" }}
                    </button>
                </div>
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="setTime(false, null, true, $event.currentTarget.previousSibling)"
                >
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
