import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    controlIsDisabled,
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-ge-administration-antibiotics',
    templateUrl: './e096-ge-administration-antibiotics.component.html',
})
export class E096GeAdministrationAntibioticsComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    interstitialSpaceOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'I°'
        },
        {
            id: '1',
            code: '1',
            name: 'II°'
        },
        {
            id: '2',
            code: '2',
            name: 'III°'
        },
        {
            id: '1',
            code: '1',
            name: 'IV°'
        }
    ];
    carePlacentalPeriodOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Aktyvi (sol. Oxytocini 5VV į/v )'
        },
        {
            id: '1',
            code: '1',
            name: 'Aktyvi (sol. Oxytocini 10VV į/r)'
        },
        {
            id: '2',
            code: '2',
            name: 'Konservatyvi'
        }
    ];
    placentaSeparateOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pati'
        },
        {
            id: '1',
            code: '1',
            name: 'Atskirta ranka'
        }
    ];
    placentaOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Visa'
        },
        {
            id: '1',
            code: '1',
            name: 'Abejotina'
        }
    ];
    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];
    episiotomyOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Neatlikta'
        },
        {
            id: '1',
            code: '1',
            name: 'Atlikta'
        }
    ];
    birthCanalOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Neapžiūrėti'
        },
        {
            id: '1',
            code: '1',
            name: 'Apžiūrėti'
        }
    ];
    ruptureBirthCanalOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Rasta'
        },
        {
            id: '1',
            code: '1',
            name: 'Nerasta'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    getCntrlValue = getControlValue.bind(this) as typeof getControlValue;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            substances: this.fb.array([], [Validators.required]),
            carePlacentalPeriod: this.fb.control(null),
            placentaSeparate: this.fb.control(null),
            placenta: this.fb.control(null),
            examinationOfPlacentaIsOrdered: this.fb.control(null),
            lostBloodAmount: this.fb.control(null),
            lostBloodAmountDetail: this.fb.control(null),
            interventions: this.fb.control([]),
            episiotomy: this.fb.control(null, [Validators.required]),
            episiotomyType: this.fb.control({value: null, disabled: true}),
            birthCanal: this.fb.control(null),
            ruptureBirthCanal: this.fb.control(null),
            tears: this.fb.control([]),
            childbirthRuptureLevelCervix: this.fb.control(null),
            childbirthRuptureLevelVagina: this.fb.control(null),
            childbirthRuptureLevelLabia: this.fb.control(null),
            childbirthRuptureLevelSpace: this.fb.control(null),
            episiotomyArea: this.fb.control(false),
            doctorSuturedUterineRupture: this.fb.array([], [Validators.required, Validators.maxLength(1)])
        });

        this.changeStatusOfControl({
            controlNameForListen: 'lostBloodAmount',
            controlNameForUpdate: 'lostBloodAmountDetail',
            enableValue: 'daugiau kaip 1000 ml',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'episiotomy',
            controlNameForUpdate: 'episiotomyType',
            enableValue: 'Atlikta',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'ruptureBirthCanal',
            controlNameForUpdate: 'tears',
            enableValue: 'Rasta',
            resetValue: []
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'tears',
            controlNameForUpdate: 'childbirthRuptureLevelCervix',
            enableValue: 'Gimdos kaklelis',
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'tears',
            controlNameForUpdate: 'childbirthRuptureLevelVagina',
            enableValue: 'Makštis',
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'tears',
            controlNameForUpdate: 'childbirthRuptureLevelLabia',
            enableValue: 'Lytinės lūpos',
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'tears',
            controlNameForUpdate: ['childbirthRuptureLevelSpace', 'episiotomyArea'],
            enableValue: 'Tarpvietė',
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    ruptureLevelList(parent: string): string {
        return parent === 'Gimdos kaklelis' ? 'childbirthRuptureLevelCervix'
            : parent === 'Makštis' ? 'childbirthRuptureLevelVagina'
            : parent === 'Lytinės lūpos' ? 'childbirthRuptureLevelLabia' : null;
    }
}
