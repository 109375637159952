<div class="d-flex gap-1 justify-content-between align-items-center">
    <div class="d-flex flex-wrap">
        {{ title }}
        <span class="badge rounded-pill text-bg-primary text-white m-1" role="button" tabindex="0"
              *ngFor="let shortcut of shortcuts" ng-enter="selection()(shortcut.field)"
              (click)="shortcutClick(shortcut.field)"
              (keyup)="shortcutClick(shortcut.field)"
        >
            {{ shortcut.title }}
        </span>
    </div>
    <div *ngIf="editable">
        <button class="btn btn-sm btn-outline-primary" (click)="edit($event)"
                aria-label="{{'Suflerio nustatymai' | translate}}">
            <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>
    </div>
</div>
