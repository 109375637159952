import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { addDaysToDate, onCheckboxChange } from "../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { allergies, vaccinations } from "../../mocks/general-pregnancy-info.mock";
import { PregnancyTerminationInfoModel } from "../../models/pregnancyTerminationInfo.model";
import { DocumentCaptionModel, NGNCaptionModel } from "../shared/models/shared.model";
import {
    Addictions,
    AuxiliaryProcedures,
    ChildbirthModes,
    FamilyAnamnesis,
    MenstrualCycles,
    WomanAddictions,
    WomanDiseases,
    foilUsing,
    kmiIndicators,
} from "./general-pregnancy-termination-info.constants";

@Component({
    selector: "app-general-pregnancy-termination-info",
    templateUrl: "./general-pregnancy-termination-info.component.html",
})
export class GeneralPregnancyTerminationInfoComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;

    public foilUsing = foilUsing;

    //TODO: replace with data from caption
    public allergies = allergies;

    //TODO: replace with data from caption
    public vaccinations = vaccinations;

    public documentTag: DocumentTagEnum = DocumentTagEnum.e113_nd;

    prefillData: any;

    public get pregnancyInfo(): FormGroup {
        return this.formGroup?.get("pregnancyInfo") as FormGroup;
    }

    public get getFamilyAnamnesisFormArray(): FormArray {
        return this.formGroup?.get("anamnesis.familyAnamnesis") as FormArray;
    }

    public get getWomanAddictionsFormArray(): FormArray {
        return this.formGroup?.get("anamnesis.womanAddictions") as FormArray;
    }

    public currentDate = new Date();
    public onCheckboxChange = onCheckboxChange;
    public calcucatedBySystem = "Apskaičiuoja sistema";
    public calculatedKmi: string = this.calcucatedBySystem;
    public addDaysToDate = addDaysToDate;
    //TODO: replace with data from caption
    public pregnancyFirstDate = this.currentDate;
    public documentCaptionData: DocumentCaptionModel;
    public ngnCaptionData: NGNCaptionModel;

    public readonly menstrualCycle = Object.keys(MenstrualCycles).map((key) => ({ key, name: MenstrualCycles[key] }));
    public readonly womanDiseases = Object.values(WomanDiseases);
    public readonly familyAnamnesis = Object.values(FamilyAnamnesis);
    public readonly addictions = Object.values(Addictions);
    public readonly womanAddictions = Object.values(WomanAddictions);
    public readonly auxiliaryProcedures = Object.values(AuxiliaryProcedures);
    public readonly childbirthModesArray = Object.values(ChildbirthModes);
    public readonly childbirthModes = ChildbirthModes;

    private patientId: string;
    private isEdit: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();
        this.patchFamilyAnamnesis();
        this.patchWomanAddictions();
        this.subscription.add(this.subscribeFormChanges());
        this.subscription.add(this.subscribeDocumentCaptionData());
        this.subscription.add(this.subscribeNGNCaptionData());

        if (this.isEdit) {
            this.subscription.add(this.patchForm());
        }

        // this.formGroup.markAllAsTouched();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    public stringToBoolean(val: string): boolean {
        return JSON.parse(val);
    }

    public getWomanAddictionsControl(index: number, controlName: string): AbstractControl {
        return (this.formGroup?.get("anamnesis.womanAddictions") as FormArray).at(index).get(controlName);
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            pregnancyInfo: this._fb.group({
                firstVisitDate: new FormControl(null, Validators.required),
                pregnancy: new FormControl(null, Validators.required),
                birth: new FormControl(null, Validators.required),
                foilUsing: this._fb.group({
                    until: new FormControl(false),
                    onGoing: new FormControl(false),
                    over: new FormControl(false),
                }),
                menstrualCycle: new FormControl(null, Validators.required),
                lastMenstruationPeriod: new FormControl(null, Validators.required),
                menstruationDuration: new FormControl(null, Validators.required),
                cycleDuration: new FormControl(null, Validators.required),
                confirmedDeadline: new FormControl(null, Validators.required),
                ultrasoundDate: new FormControl(null, Validators.required),
            }),
            anamnesis: this._fb.group({
                familyAnamnesis: this._fb.array([]),
                womanDiseases: this._fb.array([]),
                womanAddictions: this._fb.array([]),
                biologicalFather: this._fb.group({
                    age: new FormControl(null, Validators.required),
                    addictions: this._fb.array([]),
                }),
                womanAllergies: new FormControl(null),
                womanKmi: this._fb.group({
                    weight: new FormControl(null, Validators.required),
                    height: new FormControl(null, Validators.required),
                }),
            }),
            previousPregnancies: this._fb.group({
                childbirthMode: new FormControl(null),
                auxiliaryProcedures: this._fb.array([]),
            }),
        });
    }

    public calculateKmi(weight: number, height: number): void {
        const kmi = Number((weight / ((height / 100) * (height / 100))).toFixed(1));

        const kmiName = kmiIndicators
            .map((indicator) => {
                if (
                    (indicator.numberFrom < kmi && indicator.numberTo > kmi) ||
                    indicator.numberFrom === kmi ||
                    indicator.numberTo === kmi
                ) {
                    return indicator.name;
                }
            })
            .filter(Boolean);

        this.calculatedKmi = kmiName.length ? `${kmiName} - ${kmi}` : this.calcucatedBySystem;
    }

    public async saveForm(): Promise<void> {
        const payload: PregnancyTerminationInfoModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.savePregnancyTerminationInfo(payload);

        this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${DocumentTagEnum.e113_nd}`]);
    }

    public async submitForm(): Promise<void> {
        const payload: PregnancyTerminationInfoModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.submitPregnancyTerminationInfo(payload);
    }

    public async signingSubmitForm(): Promise<void> {
        const payload: PregnancyTerminationInfoModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signingSubmitPregnancyTerminationInfo(payload);
    }

    public async sealConfirmForm(): Promise<void> {
        const payload: PregnancyTerminationInfoModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.sealConfirmPregnancyTerminationInfo(payload);
    }

    public async signForm(): Promise<void> {
        const payload: PregnancyTerminationInfoModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signPregnancyTerminationInfo(payload);
    }

    public clearForm(): void {
        this.formGroup.reset();
        (this.formGroup?.get("anamnesis.biologicalFather.addictions") as FormArray).clear();
        (this.formGroup?.get("anamnesis.womanDiseases") as FormArray).clear();
        this.formGroup.get("pregnancyInfo.foilUsing.until").patchValue(false);
        this.formGroup.get("pregnancyInfo.foilUsing.onGoing").patchValue(false);
        this.formGroup.get("pregnancyInfo.foilUsing.over").patchValue(false);
        this.patchFamilyAnamnesis();
        this.patchWomanAddictions();
    }

    public get calculateLastWomansPeriodDate(): Date {
        let date: Date;

        if (
            this.formGroup?.get("pregnancyInfo.cycleDuration").value &&
            this.formGroup?.get("pregnancyInfo.lastMenstruationPeriod").value
        ) {
            const cycleDeviation = 28 - Number(this.formGroup?.get("pregnancyInfo.cycleDuration").value);
            const daysToAdd = 280 - cycleDeviation;

            date = daysToAdd
                ? addDaysToDate(new Date(this.formGroup?.get("pregnancyInfo.lastMenstruationPeriod").value), daysToAdd)
                : null;
        }

        return date;
    }

    private subscribeFormChanges(): Subscription {
        return this.formGroup.valueChanges.subscribe((data) => {
            if (data?.anamnesis?.womanKmi?.weight && data?.anamnesis?.womanKmi?.height) {
                this.calculateKmi(Number(data?.anamnesis?.womanKmi?.weight), Number(data?.anamnesis?.womanKmi?.height));
            } else {
                this.calculatedKmi = this.calcucatedBySystem;
            }
        });
    }

    private patchFamilyAnamnesis(): void {
        const familyAnamnesis = this.formGroup?.get(`anamnesis.familyAnamnesis`) as FormArray;
        familyAnamnesis.clear();

        this.familyAnamnesis.forEach((name) =>
            familyAnamnesis.push(
                this._fb.group({
                    name: new FormControl(name),
                    checked: new FormControl(false),
                    value: new FormControl(null, []),
                })
            )
        );
    }

    private patchWomanAddictions(): void {
        const womanAddictions = this.formGroup?.get(`anamnesis.womanAddictions`) as FormArray;
        womanAddictions.clear();

        this.womanAddictions.forEach((name) =>
            womanAddictions.push(
                this._fb.group({
                    name: new FormControl(name),
                    beforePregnancy: new FormControl(false),
                    duringPregnancy: new FormControl(false),
                })
            )
        );
    }

    private patchForm(): Subscription {
        //TODO: Change 'id' with actual id from route params
        return this._apiV2PregnancyDashboard
            .getPregnancyTerminationInfo(this.patientId)
            .subscribe((data: PregnancyTerminationInfoModel) => {
                this.formGroup.patchValue(data);

                data.anamnesis.biologicalFather.addictions?.forEach((addiction) =>
                    (this.formGroup?.get("anamnesis.biologicalFather.addictions") as FormArray).push(
                        new FormControl(addiction)
                    )
                );

                data.anamnesis.womanDiseases?.forEach((disease) =>
                    (this.formGroup?.get("anamnesis.womanDiseases") as FormArray).push(new FormControl(disease))
                );
            });
    }

    private subscribeDocumentCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getDocumentCaptionData(this.patientId).subscribe((data) => {
            this.documentCaptionData = data;
        });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).subscribe((data) => {
            this.ngnCaptionData = data;
        });
    }
}
