ng1App.factory('ExternalSystemsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/external-systems';
    return $resource('', {}, {
    	create: {
    		method: 'POST',
    		url: URL_BASE
    	},
    	update: {
    		method: 'PUT',
    		url: URL_BASE + '/:id'
    	},
    	get: {
    		method: 'GET',
    		url: URL_BASE + '/:id'
    	},
    	delete: {
    		method: 'DELETE',
    		url: URL_BASE + '/:id'
    	},
    	getList: {
    		method: 'GET',
    		url: URL_BASE
    	}
    });
}]);