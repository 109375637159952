angular.module('erx.prs')
        .provider("erxPrescriptionsConfig", function () {
          var self = this;
          this.options = {
          };

          this.$get = ["ErxMedicationsApiFactory", "$rootScope", function (ErxMedicationsApiFactory, $rootScope) {
            var extend: any = {};
            extend.options = {
              getMedications: function (count, query, kind, type, compensated, checkCanPrescribe) {
                return ErxMedicationsApiFactory.getMedications({count: count, query: query, kind: kind, type: type, compensated: compensated ? true : undefined, checkCanPrescribe: checkCanPrescribe ? true : false}).$promise;
              },
              getMedicationById: function (id) {
                return ErxMedicationsApiFactory.getMedicationById({ref: id}).$promise;
              },
              displayGlobalMessage: function (type, message) {
                $rootScope.$emit('SHOW_MSG', {type: type, message: message});
              }
            };
            extend.options = angular.extend(extend.options, self.options);
            return extend;
          }];
          return this;
        });
