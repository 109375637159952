import angular from "angular";

angular.module('gettext').run(['gettextCatalog', function (gettextCatalog) {
    /* jshint -W100 */
    gettextCatalog.setStrings('en', {
        "medvais.doc.main.info": "Health care facility, physician and patient",
        "medvais.doc.main.info.birth": "Birthday",
        "medvais.doc.main.info.history": "Health history No.",
        "medvais.doc.main.info.institution": "Institution",
        "medvais.doc.main.info.patient": "Patient",
        "medvais.doc.main.info.specialist": "Specialist",
        "medvais.doc.main.info.stamp": "Stamp No.",
        "medvais.doc.patient.identification": "Patient identification",
        "medvais.email": "e-mail",
        "medvais.jarCode": "Legal Entity code",
        "medvais.personal.code": "Personal ID",
        "medvais.phone": "Tel.",
        "medvais.sveidraNr": "SVEIDRA No.",
        "mobile": "Mob.",
        "027va.unidentified.patient": "Unidentified patient",
        "027va.encounter.not.assigned": "Encounter not assigned",
        "027va.achi.code.required": "ACHI code required",
        "027va.achi.code.invalid": "Invalid ACHI code",
        "027va.img.study.description.required": "Medical image description required",
        "027va.conclusion.required": "Conclusion required",
        "027va.diagnoses.required": "Diagnosis required",
        "027va.diagnoses.description.required": "Diagnosis description required",
        "medvais.device.serial.no.label": "Device serial No.",
        "medvais.dose.label": "Dose",
        "medvais.dose.measure.unit.label": "Unit of measurement",
        "e027va.view.download": "Download",
        "medvais.img.study.list.reported": "Reported",
        "medvais.img.study.list.unreported": "Unreported",
        "medvais.img.study.list.telemedicine.dispatch": "Telemedicine dispatch",
        "medvais.img.study.list.telemedicine.order": "Telemedicine order",
        "medvais.027va.edit.imaging.study.updated.msg": "Related study data have been updated. Verify data correctness.",
        "medvais.img.study.list.new.doc.button.hint": "New document",
        "medvais.img.study.list.send.study.button.hint": "Send a study",
        "medvais.img.study.list.patient.identification.button.hint": "Patient identity confirmation",
        "medvais.img.study.list.study.preview.button.hint": "Study preview",
        "medvais.img.study.list.study.date.label": "Study date:",
        "medvais.027va.edit.img.study.description": "Medical image description",
        "medvais.027va.edit.conclusion": "Conclusion",
        "medvais.027va.view.img.study.description": "Medical image description",
        "medvais.027va.view.conclusion": "Conclusion",
        "patient.search.placeholder": "Enter the name, surname, personal ID or EHH No.",
        "medvais.achi.code.input.placeholder": "Enter ACHI code",
        "medvais.diagnosis.input.placeholder": "Enter diagnosis or its code",
        "medvais.027va.edit.doc.save.success.msg": "Document was successfully saved.",
        "medvais.doc.sign.success.msg": "Document was successfully signed.",
        "medvais.doc.sign.fail.msg": "Failed to sign the document.",
        "telemedicine.order.registration.success.msg": "Telemedicine order was successfully registered.",
        "medvais.doc.status.preliminary": "Partially approved",
        "medvais.doc.status.signed": "Signed",
        "medvais.doc.status.final": "Submited for signature",
        "medvais.doc.status.entered_in_error": "Canceled",
        "medvais.doc.status.entered_in_error.unsigned": "Submited for cancelation",
        "medvais.doc.status.amended": "Amended",
        "medvais.doc.status.amended.unsigned": "Submited for amend",
        "medvais.doc.status.new": "New document",
        "wait.loading": "Please wait, data dowload...",
        "loading.data": "Data dowload...",
        "File": "File",
        "Delete": "Delete",
        "027va.upload.file.size.10MB": "File size must not exceed 10 MB.",
        "027va.upload.file.type.pdf.images": "Possible format - PDF and Images.",
        "medvais.perf.physician.label": "Study performed by technologist:",
        "medvais.achi.code.label": "ACHI code:",
        "medvais.027va.add.related.study.title": "Attach related study",
        "medvais.ok": "Confirm",
        "medvais.cancel": "Cancel",
        "medvais.assign": "Assign",
        "medvais.close": "Close",
        "medvais.save": "Save",
        "medvais.sign": "Sign",
        "medvais.preview": "Preview",
        "medvais.delete": "Delete",
        "medvais.remove": "Remove",
        "medvais.add": "Add",
        "medvais.continue": "Continue",
        "medvais.return": "Return",
        "medvais.back": "Back",
        "medvais.order": "Order",
        "medvais.telemedicine": "Telemedicine",
        "medvais.information": "Information",
        "medvais.study.not.found": "Study not found.",
        "medvais.radiation.section.title": "Radiation data",
        "medvais.incorrect.dose.value.label": "Invalid value. Enter numeric value, e.g.: \"1\", \"1,89\", \"1.89\".",
        "medvais.search": "Search",
        "medvais.upload.study": "Upload a study",
        "medvais.msg.study.upload.success": "Study successfully uploaded.",
        "medvais.msg.err.study.upload.failed": "Study upload failed.",
        "medvais.telemedicine.orders.without.encounter": "Telemedicine orders without encounters",
        "medvais.telemedicine.order.date": "Order date",
        "medvais.records.not.found": "Record not found.",
        "medvais.dob": "DOB",
        "medvais.register.encounter": "Encounter registration",
        "medvais.failed.read.telemedicine.orders.without.encounter.msg": "Failed to read the order.",
        "medvais.failed.create.virtual.encounter.msg": "Encounter registration failed.",
        "practitioner.search.placeholder": "Enter name, surname",
        "organization.search.placeholder": "Enter health care facility name or code",
        "medvais.telemedicine.info.organization.label": "Institution",
        "medvais.telemedicine.info.practitioner.label": "Physician",
        "medvais.telemedicine.info.section.title": "Telemedicine customer",
        "medvais.e027va.cancel": "Cancel",
        "medvais.mdl.doc.cancel.reason": "Reason",
        "medvais.cancel.doc.mdl.title": "Document cancelation",
        "medvais.err.cancel.reason.mandatory": "Enter the reason",
        "medvais.doc.cancel.failure.msg": "Document cancelation failed",
        "medvais.e027va.edit": "Edit",
        "medvais.upload.study.btn.label": "Upload the sudy",
        "medvais.img.study.list.title": "Medical Images",
        "medvais.img.study.list.filter.params": "Search parameters",
        "medvais.img.study.list.filter.patient": "Patient",
        "medvais.img.study.list.filter.study.type": "Study type",
        "medvais.img.study.list.filter.study.date": "Study date",
        "medvais.img.study.list.filter.study.status": "Study status",
        "medvais.img.study.list.filter.telemedicine.status": "Telemedicine",
        "medvais.clear": "Clear",
        "medvais.img.study.list.filter.study.status.item.reported": "Reported",
        "medvais.img.study.list.filter.study.status.item.unreported": "Unreported",
        "medvais.img.study.list.filter.telemedicine.status.item.telemedicine.dispatches": "Telemedicine dispatches",
        "medvais.err.msg.incorrect.date": "Invalid date",
        "medvais.err.msg.date.from.bigger.than.date.to": "First date mustn't be bigger than second one",
        "medvais.1d": "1d",
        "medvais.1d.hint": "1 day",
        "medvais.3d": "3d",
        "medvais.3d.hint": "3 days",
        "medvais.7d": "7d",
        "medvais.7d.hint": "7 days",
        "medvais.30d": "30d",
        "medvais.30d.hint": "30 days",
        "medvais.doc.count": "Number of Documents:",
        "medvais.send.study.modal.title": "Study sending",
        "medvais.send.study.modal.device": "Device / PACS",
        "medvais.send.study.modal.btn.send": "Order study sending",
        "medvais.err.msg.study.send.registration.failed": "Study sending registration error.",
        "medvais.msg.study.send.task.processing": "Study sending takes place.",
        "medvais.identification.modal.specified.first.name": "Specified first name",
        "medvais.identification.modal.specified.last.name": "Specified last name",
        "medvais.identification.modal.specified.id": "Specified ID",
        "medvais.identification.modal.title": "The patient's identity confirmation",
        "medvais.err.msg.common.patient.identification": "Patient identification failed. Check the patient registration at Health care facility.",
        "medvais.order.telemedicine.modal.title": "Telemedicine service order",
        "medvais.order.telemedicine.modal.organization": "Health care facility",
        "medvais.order.telemedicine.modal.practitioner": "Physician",
        "medvais.err.msg.telemedicine.registration.failed": "Telemedicine registration error.",
        "medvais.all": "All",
        "medvais.doc.code": "E027-va",
        "medvais.doc.title": "Diagnostic study report",
        "medvais.doc.encounter.section": "Encounter",
        "medvais.doc.encounter.date": "Encounter date",
        "medvais.doc.order.section": "Referral information",
        "medvais.doc.order.date": "Referral date",
        "medvais.doc.order.specialist": "Specialist",
        "medvais.doc.study.info.section": "Study information",
        "medvais.doc.study.name": "Study name",
        "medvais.doc.study.achi.code": "Study ACHI code",
        "medvais.doc.study.date": "Study date",
        "medvais.doc.study.uid": "Study UID",
        "medvais.doc.study.attached.files": "Attached files",
        "medvais.doc.study.preview.btn.hint": "Study preview",
        "medvais.choose.file": "Choose a file",
        "medvais.choose.study.file": "Choose a study",
        "medvais.doc.related.studies.section": "Related studies",
        "medvais.doc.main.diagnosis.section": "Main diagnosis",
        "medvais.doc.diagnosis": "Diagnosis",
        "medvais.doc.diagnosis.description": "Description",
        "medvais.doc.related.diagnoses.section": "Related diagnoses",
        "medvais.msg.err.form.has.errors": "The form has errors.",
        "medvais.err.msg.doc.save.error": "Document save error.",
        "medvais.abbr.days": "d.",
        "medvais.abbr.months": "mo.",
        "medvais.abbr.years": "yr.",
        "medvais.err.msg.failed.add.related.study": "Failed to add related study.",
        "medvais.dashboard.all.images": "All images",
        "medvais.err.msg.form.has.errors": "Form contains errors",
        "medvais.err.msg.choose.patient.for.identification.from.list": "Assign patient from list",
        "medvais.submit.for.multi.signing": "Submit for multi-signing",
        "medvais.027va.edit.doc.submit.multisign.success.msg": "Document was successfully submitted for multi-signing.",
        "medvais.err.msg.identification.failure.has.active.document": "Patient identification failed. Study has active documents.",
        "medvais.err.msg.identification.failure.related.study": "Patient identification failed. This study is used as related study in a document.",
        "medvais.img.study.list.filter.study.uid": "Study UID",
        "medvais.main.list.type.organization.studies": "Organization studies",
        "medvais.main.list.type.telemedicine.orders": "Telemedicine orders",
        "medvais.list.filter.telemedicine.order.date": "Order date",
        "medvais.telemedicine.order.item.patient.label": "Patient:",
        "medvais.telemedicine.order.item.order.date.label": "Order date:",
        "medvais.telemedicine.order.item.requester.label": "Requester:",
        "medvais.show.other.page": "Show more",
        "medvais.of": "of",
        "medvais.telemedicine.orders.not.found": "Telemedicine orders not found.",
        "medvais.list.filter.telemedicine.order.status": "Order status",
        "medvais.telemedicine.order.item.doc.date.label": "Document date:",
        "medvais.027va.doc.misssing.title": "Missing diagnostic study report"
    });
    gettextCatalog.setStrings('lt', {
        "medvais.doc.main.info": "Asmens sveikatos priežiūros įstaiga, specialistas ir pacientas",
        "medvais.doc.main.info.birth": "Gimimo data",
        "medvais.doc.main.info.history": "Sveikatos istorijos Nr.",
        "medvais.doc.main.info.institution": "Organizacija",
        "medvais.doc.main.info.patient": "Pacientas",
        "medvais.doc.main.info.specialist": "Specialistas",
        "medvais.doc.main.info.stamp": "Spaudo nr.",
        "medvais.doc.patient.identification": "Paciento tapatybės patvirtinimas",
        "medvais.email": "El. p.",
        "medvais.jarCode": "JAR kodas",
        "medvais.personal.code": "Asm. k.",
        "medvais.phone": "Tel.",
        "medvais.sveidraNr": "SVEIDRA nr.",
        "mobile": "Mob.",
        "027va.unidentified.patient": "Nenustatyta paciento tapatybė",
        "027va.encounter.not.assigned": "Nepriskirtas atvykimas",
        "027va.achi.code.required": "ACHI kodas privalomas",
        "027va.achi.code.invalid": "Neteisingas ACHI kodas",
        "027va.img.study.description.required": "Medicininio vaizdo aprašymas privalomas",
        "027va.conclusion.required": "Išvada privaloma",
        "027va.diagnoses.required": "Diagnozė privaloma",
        "027va.diagnoses.description.required": "Diagnozės aprašymas privalomas",
        "medvais.device.serial.no.label": "Įrenginio serijinis nr.",
        "medvais.dose.label": "Dozė",
        "medvais.dose.measure.unit.label": "Matavimo vienetas",
        "e027va.view.download": "Atsisiųsti",
        "medvais.img.study.list.reported": "Aprašytas",
        "medvais.img.study.list.unreported": "Neaprašytas",
        "medvais.img.study.list.telemedicine.dispatch": "Telemedicinos siuntimas",
        "medvais.img.study.list.telemedicine.order": "Telemedicinos užsakymas",
        "medvais.027va.edit.imaging.study.updated.msg": "Medicininių vaizdų tyrimo duomenys buvo atnaujinti. Įsitikinkite duomenų teisingumu.",
        "medvais.027va.edit.related.imaging.study.updated.msg": "Susijusių medicininių vaizdų tyrimų duomenys buvo atnaujinti. Įsitikinkite duomenų teisingumu.",
        "medvais.img.study.list.new.doc.button.hint": "Naujas dokumentas",
        "medvais.img.study.list.send.study.button.hint": "Siųsti tyrimą",
        "medvais.img.study.list.patient.identification.button.hint": "Tapatybės patvirtinimas",
        "medvais.img.study.list.study.preview.button.hint": "Tyrimo peržiūra",
        "medvais.img.study.list.study.date.label": "Tyrimo atlikimo data:",
        "medvais.027va.edit.img.study.description": "Medicininio vaizdo aprašas",
        "medvais.027va.edit.conclusion": "Išvada",
        "medvais.027va.view.img.study.description": "Medicininio vaizdo aprašas",
        "medvais.027va.view.conclusion": "Išvada",
        "patient.search.placeholder": "Įveskite vardą, pavardę, asmens kodą ar ESI numerį",
        "medvais.achi.code.input.placeholder": "Įveskite ACHI kodą",
        "medvais.diagnosis.input.placeholder": "Įveskite diagnozę ar jos kodą",
        "medvais.027va.edit.doc.save.success.msg": "Dokumentas sėkmingai išsaugotas.",
        "medvais.doc.sign.success.msg": "Dokumentas sėkmingai pasirašytas.",
        "medvais.doc.sign.fail.msg": "Dokumento pasirašyti nepavyko.",
        "telemedicine.order.registration.success.msg": "Telemedicinos užduotis sėkmingai užregistruota.",
        "medvais.doc.status.preliminary": "Dalinai patvirtintas",
        "medvais.doc.status.signed": "Pasirašytas",
        "medvais.doc.status.final": "Pateiktas pasirašymui",
        "medvais.doc.status.entered_in_error": "Atšauktas",
        "medvais.doc.status.entered_in_error.unsigned": "Pateiktas atšaukimui",
        "medvais.doc.status.amended": "Pakeistas",
        "medvais.doc.status.amended.unsigned": "Pateiktas pakeitimui",
        "medvais.doc.status.new": "Naujas dokumentas",
        "wait.loading": "Palaukite, užkraunami duomenys...",
        "loading.data": "Kraunami duomenys...",
        "File": "Byla",
        "Delete": "Pašalinti",
        "027va.upload.file.size.10MB": "Bylos dydis turi neviršyti 10 MB.",
        "027va.upload.file.type.pdf.images": "Galimi formatai - PDF ir paveikslėliai.",
        "medvais.perf.physician.label": "Tyrimą atlikęs specialistas:",
        "medvais.achi.code.label": "ACHI kodas:",
        "medvais.027va.add.related.study.title": "Pridėti susijusį tyrimą",
        "medvais.ok": "Patvirtinti",
        "medvais.cancel": "Atšaukti",
        "medvais.assign": "Priskirti",
        "medvais.close": "Uždaryti",
        "medvais.save": "Saugoti",
        "medvais.sign": "Pasirašyti",
        "medvais.preview": "Peržiūrėti",
        "medvais.delete": "Ištrinti",
        "medvais.remove": "Pašalinti",
        "medvais.add": "Pridėti",
        "medvais.continue": "Tęsti",
        "medvais.return": "Grįžti",
        "medvais.back": "Atgal",
        "medvais.order": "Užsakyti",
        "medvais.telemedicine": "Telemedicina",
        "medvais.information": "Informacija",
        "medvais.study.not.found": "Tyrimų nerasta.",
        "medvais.radiation.section.title": "Jonizuojančios spinduliuotės duomenys",
        "medvais.incorrect.dose.value.label": "Neteisinga reikšmė. Įveskite skaitinę reikšmę, pvz.: \"1\", \"1,89\", \"1.89\".",
        "medvais.search": "Ieškoti",
        "medvais.upload.study": "Įkelti tyrimą",
        "medvais.msg.study.upload.success": "Tyrimas sėkmingai įkeltas.",
        "medvais.msg.err.study.upload.failed": "Tyrimo nepavyko įkelti.",
        "medvais.telemedicine.orders.without.encounter": "Telemedicinos užduotys be atvykimų",
        "medvais.telemedicine.order.date": "Užsakymo data",
        "medvais.records.not.found": "Įrašų nerasta.",
        "medvais.dob": "gim.",
        "medvais.register.encounter": "Registruoti atvykimą",
        "medvais.failed.read.telemedicine.orders.without.encounter.msg": "Nepavyko nuskaityti telemedicinos užduočių.",
        "medvais.failed.create.virtual.encounter.msg": "Atvykimo nepavyko užregistruoti.",
        "practitioner.search.placeholder": "Įveskite vardą, pavardę",
        "organization.search.placeholder": "Įveskite įstaigos pavadinimą ar kodą",
        "medvais.telemedicine.info.organization.label": "Organizacija",
        "medvais.telemedicine.info.practitioner.label": "Specialistas",
        "medvais.telemedicine.info.section.title": "Telemedicinos užsakovas",
        "medvais.e027va.cancel": "Atšaukti",
        "medvais.mdl.doc.cancel.reason": "Priežastis",
        "medvais.cancel.doc.mdl.title": "Dokumento atšaukimas",
        "medvais.err.cancel.reason.mandatory": "Nurodykite atšaukimo priežastį",
        "medvais.doc.cancel.failure.msg": "Nepavyko atšaukti dokumento",
        "medvais.e027va.edit": "Redaguoti",
        "medvais.upload.study.btn.label": "Įkelti tyrimą",
        "medvais.img.study.list.title": "Medicininiai vaizdai",
        "medvais.img.study.list.filter.params": "Paieškos parametrai",
        "medvais.img.study.list.filter.patient": "Pacientas",
        "medvais.img.study.list.filter.study.type": "Tyrimo tipas",
        "medvais.img.study.list.filter.study.date": "Tyrimo data",
        "medvais.img.study.list.filter.study.status": "Tyrimo būsena",
        "medvais.img.study.list.filter.telemedicine.status": "Telemedicina",
        "medvais.clear": "Išvalyti",
        "medvais.img.study.list.filter.study.status.item.reported": "Aprašyti",
        "medvais.img.study.list.filter.study.status.item.unreported": "Neaprašyti",
        "medvais.err.msg.incorrect.date": "Nekorektiškai nurodyta data",
        "medvais.err.msg.date.from.bigger.than.date.to": "Pirma data negali būti didesnė už antrą",
        "medvais.1d": "1d",
        "medvais.1d.hint": "1 diena",
        "medvais.3d": "3d",
        "medvais.3d.hint": "3 dienos",
        "medvais.7d": "7d",
        "medvais.7d.hint": "7 dienos",
        "medvais.30d": "30d",
        "medvais.30d.hint": "30 dienų",
        "medvais.doc.count": "Dokumentų sk.: ",
        "medvais.send.study.modal.title": "Tyrimo siuntimas",
        "medvais.send.study.modal.device": "Įrenginys / PACS",
        "medvais.send.study.modal.btn.send": "Užsakyti tyrimo siuntimą",
        "medvais.err.msg.study.send.registration.failed": "Tyrimo persiuntimo registravimo klaida.",
        "medvais.msg.study.send.task.processing": "Tyrimo persiuntimo užduotis vykdoma.",
        "medvais.identification.modal.specified.first.name": "Nurodytas vardas",
        "medvais.identification.modal.specified.last.name": "Nurodyta pavardė",
        "medvais.identification.modal.specified.id": "Nurodytas identifikatorius",
        "medvais.identification.modal.title": "Paciento tapatybės patvirtinimas",
        "medvais.err.msg.common.patient.identification": "Nepavyko priskirti paciento. Patikrinkite ar pasirinktas pacientas registruotas gydymo įstaigoje.",
        "medvais.order.telemedicine.modal.title": "Telemedicinos paslaugos užsakymas",
        "medvais.order.telemedicine.modal.organization": "SPĮ",
        "medvais.order.telemedicine.modal.practitioner": "Gydytojas",
        "medvais.err.msg.telemedicine.registration.failed": "Telemedicinos registravimo klaida.",
        "medvais.all": "Visi",
        "medvais.doc.code": "E027-va",
        "medvais.doc.title": "Diagnostinio tyrimo aprašymas",
        "medvais.doc.encounter.section": "Atvykimas",
        "medvais.doc.encounter.date": "Atvykimo data",
        "medvais.doc.order.section": "Informacija apie siuntimą",
        "medvais.doc.order.date": "Siuntimo data",
        "medvais.doc.order.specialist": "Specialistas",
        "medvais.doc.study.info.section": "Informacija apie tyrimą",
        "medvais.doc.study.name": "Tyrimo pavadinimas",
        "medvais.doc.study.achi.code": "Tyrimo ACHI kodas",
        "medvais.doc.study.date": "Tyrimo laikas",
        "medvais.doc.study.uid": "Tyrimo UID",
        "medvais.doc.study.attached.files": "Prisegtos bylos",
        "medvais.doc.study.preview.btn.hint": "Tyrimo peržiūra",
        "medvais.choose.file": "Pasirinkite bylą",
        "medvais.choose.study.file": "Pasirinkite tyrimą",
        "medvais.doc.related.studies.section": "Susiję tyrimai",
        "medvais.doc.main.diagnosis.section": "Pagrindinė diagnozė",
        "medvais.doc.diagnosis": "Diagnozė",
        "medvais.doc.diagnosis.description": "Aprašymas",
        "medvais.doc.related.diagnoses.section": "Susijusios diagnozės",
        "medvais.msg.err.form.has.errors": "Formoje yra klaidų.",
        "medvais.err.msg.doc.save.error": "Dokumento saugojimo klaida.",
        "medvais.abbr.days": "d.",
        "medvais.abbr.months": "mėn.",
        "medvais.abbr.years": "m.",
        "medvais.err.msg.failed.add.related.study": "Nepavyko pridėti susijusio tyrimo.",
        "medvais.dashboard.all.images": "Visi vaizdai",
        "medvais.err.msg.form.has.errors": "Formoje yra klaidų",
        "medvais.err.msg.choose.patient.for.identification.from.list": "Galima priskirti tik sąraše esantį pacientą",
        "medvais.submit.for.multi.signing": "Pateikti paketiniam pasirašymui",
        "medvais.027va.edit.doc.submit.multisign.success.msg": "Dokumentas sėkmingai pateiktas paketiniam pasirašymui.",
        "medvais.err.msg.identification.failure.has.active.document": "Nepavyko priskirti paciento. Tyrimas turi aktyvių dokumentų.",
        "medvais.err.msg.identification.failure.related.study": "Nepavyko priskirti paciento. Šis tyrimas - susijęs tyrimas dokumente.",
        "medvais.img.study.list.filter.study.uid": "Tyrimo UID",
        "medvais.main.list.type.organization.studies": "SPĮ tyrimai",
        "medvais.main.list.type.telemedicine.orders": "Telemedicinos užsakymai",
        "medvais.list.filter.telemedicine.order.date": "Užsakymo data",
        "medvais.telemedicine.order.item.patient.label": "Pacientas:",
        "medvais.telemedicine.order.item.order.date.label": "Užsakymo data:",
        "medvais.telemedicine.order.item.requester.label": "Užsakovas:",
        "medvais.show.other.page": "Rodyti daugiau",
        "medvais.of": "iš",
        "medvais.telemedicine.orders.not.found": "Telemedicinos užsakymų nerasta.",
        "medvais.list.filter.telemedicine.order.status": "Užsakymo būsena",
        "medvais.telemedicine.order.item.doc.date.label": "Dokumento data:",
        "medvais.027va.doc.misssing.title": "Nėra diagnostinio tyrimo aprašymo"
    });
    /* jshint +W100 */
}]);