import {Component, Input, OnInit} from "@angular/core";
import {PractitionerModel} from "../../../models/practitioner.model";
import {OrganizationModel} from "../../../models/organization.model";

@Component({
    selector: 'primary-health-care',
    templateUrl: './primary-health-care.component.html'
})
export class PrimaryHealthCareComponent implements OnInit {

    @Input() practitioner: PractitionerModel;
    @Input() organization: OrganizationModel;

    constructor() {

    }

    ngOnInit(): void {

    }

    onPractitionerClick() {
        window.location.href = `/dp/specialist/${this.practitioner.id}`;
    }
}