import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    multiCheckboxIsChecked,
    generateSelectionValuesFromEntity,
    updateCheckboxControl,
    listenAndChangeStatusOfControl,
    controlIsDisabled
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-ptkl-postpartum-hemorrhage-start',
    templateUrl: './e096-ptkl-postpartum-hemorrhage-start.component.html',
})
export class E096PtklPostpartumHemorrhageStartComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    helpRequestedOptions: EntityModel[] = [
        {
            "id": "0",
            "code": "0",
            "name": "Slaugytojo padėjėjas"
        },
        {
            "id": "1",
            "code": "1",
            "name": "Akušeris"
        },
        {
            "id": "2",
            "code": "2",
            "name": "Akušeris ginekologas"
        }
    ];

    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    generateSelectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            postpartumBleedingObserved: this.fb.control(null),
            helpRequested: this.fb.control([]),
            lostBloodAmount: this.fb.control(null),
            increasedBloodLoss: this.fb.control({value: null, disabled: true}),
        });

        this.changeStatusOfControl({
            controlNameForListen: 'lostBloodAmount',
            controlNameForUpdate: 'increasedBloodLoss',
            enableValue: 'daugiau kaip 1000 ml'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }
}
