import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-visual-system-form',
    templateUrl: './sbvf-visual-system-form.component.html',
})
export class SbvfVisualSystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    visualSystemRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        eyes: this.fb.control(null, [Validators.required]),
        sclera: this.fb.control(null, [Validators.required]),
        eyeDischarge: this.fb.control(null, [Validators.required]),
        dryEyes: this.fb.control(null, [Validators.required]),
        eyeDrops: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    })
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    private generateRows(value: {[key: string]: EntityModel[]}): void {
        this.visualSystemRows = [
            {
                title: 'Akys',
                type: 'choise',
                options: value['eyes'],
                formControl: this.formGroup.get('eyes')

            },
            {
                title: 'Skleros',
                type: 'choise',
                options: value['sclera'],
                formControl: this.formGroup.get('sclera')
            },
            {
                title: 'Akių išskyros',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('eyeDischarge')
            },
            {
                title: 'Akių sausumas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('dryEyes')
            },
            {
                title: 'Lašus akims',
                type: 'choise',
                options: value['eyeDrops'],
                formControl: this.formGroup.get('eyeDrops')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
