import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { VisitCancelDialog } from "./visitCancelDialog/visitCancelDialog.component";
import { VisitCancelNoteDialog } from "./visitCancelNoteDialog/visitCancelNoteDialog.component";
import { VisitEditDialog } from "./visitEditDialog/visitEditDialog.component";

@Component({
    selector: "visit-modal-actions",
    templateUrl: "./visit-modal-actions.component.html",
})
export class VisitModalActions implements OnInit {

    editVisitDialogRef?: BsModalRef;
    cancelVisitDialogRef?: BsModalRef;
    cancelVisitNoteDialogRef?: BsModalRef;

    public visitId: number;
    public periodVisitId: number;

    visitFormG: FormGroup;

    constructor(
        private router: Router,
        private modalService: BsModalService,
        private fb: FormBuilder) { }

    ngOnInit(): void { }

    openEditVisitDialog(visitId: number, periodVisitId: number) {
        this.editVisitDialogRef = this.modalService.show(VisitEditDialog, {
            initialState: { visitId: visitId, periodVisitId: periodVisitId },
        });
        this.editVisitDialogRef.content.navigateToVisit.subscribe((id: number) => {
            this.navigateToVisit(id);
        })
    }

    navigateToVisit(id: number) {
        console.log("Navigating to visit with id: ", id);
        this.editVisitDialogRef && this.editVisitDialogRef.hide();
        this.router.navigate([`/dp/aspn/spv/${id}`]);
    }

    openCancelVisistDialog(visitId: number, periodVisitId: number) {
        console.log("on open", this.visitFormG);

        this.cancelVisitDialogRef = this.modalService.show(VisitCancelDialog, {
            initialState: { visitId: visitId, periodVisitId: periodVisitId },
        });
        this.cancelVisitDialogRef.content.openCancelVisitNoteModal.subscribe((id: number) => {
            this.openCancelVisitNoteModal(id);
        })
    }

    openCancelVisitNoteModal(id: number) {
        this.cancelVisitDialogRef && this.cancelVisitDialogRef.hide();
        this.visitFormG = this.fb.group({
            visitId: [null, Validators.required],
            visitCancelNote: ["", [Validators.required]],
        });
        console.log("on cancel", this.visitFormG);
        this.visitFormG.get("visitCancelNote").setValue("");
        this.visitFormG.get("visitId").setValue(this.visitId);
        this.visitFormG.get("visitCancelNote").updateValueAndValidity();
        this.cancelVisitNoteDialogRef = this.modalService.show(VisitCancelNoteDialog, {
            initialState: { visitId: id, visitFormG: this.visitFormG },
        });
        this.cancelVisitNoteDialogRef.content.cancelVisit.subscribe((id: number) => {
            this.cancelVisit(id);
        })

    }

    cancelVisit(visitId: number) {
        console.log("Canceling visit or serial visits with id: ", visitId);
    }
}
