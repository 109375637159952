<form autocomplete="off">
    <quick-search [formV2]="form" [name]="quickSearchName" [isAdvanced]="false" (filterService)="filterTemplates()"/>
    <ol *ngIf="templates" class="slats hover">
        <li *ngFor="let t of templates" (click)="onTemplateSelect(t)"  (keypress)="onTemplateSelect(t)" tabindex="0">
            <div class="fw-bold mb-0">
                <label class="mb-0">
                    <input class="me-1" type="radio" [value]="t.id" name="templ" [(ngModel)]="data.template" title="{{t.name}}">{{t.name}} <!--template name-->
                </label>
            </div>
            <div class="mb-0 ">{{'used' | translate}}: {{t.lastTimeUsed | date: 'yyyy-MM-dd HH:mm'}}
                <span class="vr"></span> {{'changed' | translate}}: {{t.updated | date: 'yyyy-MM-dd HH:mm'}}
            </div>
        </li>
        <li class="last hover" *ngIf="moreToLoad()">
            <a tabindex="0" class="d-block text-decoration-none text-center" (click)="loadMore()" (keypress)="loadMore()">{{'mdl.add.tem.more'|translate}}<!--Daugiau ruošinių--></a>
        </li>
        <li class="last hover" *ngIf="!notEmpty()">
            <div class="text-center">
                <!--Ruošinių nėra-->{{'mdl.add.tem.no'|translate}}
            </div>
        </li>
    </ol>
</form>