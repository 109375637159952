ng1App.controller('doctor.patient.DocumentsDraftViewCtrl',
    ['$scope', '$routeParams', '$location', 'DocumentCtx', 'DraftsApiFactory', 'MessageService', 'DocumentSpinnerHelper', '$route', '$routeSegment', '$q', '$modalConfirm', '$window',
     function ($scope, $routeParams, $location, DocumentCtx, DraftsApiFactory, MessageService, DocumentSpinnerHelper, $route, $routeSegment, $q, $modalConfirm, $window) {
    $scope.$emit('setHelpPage', 'perzireti-elektronini-dokumenta');

    $scope._loadDocument = function(){
        DocumentCtx.reset();
        DocumentCtx.setPatientId($routeParams.patientId);
        DocumentSpinnerHelper.reset();

        MessageService.clearAll() ;
        MessageService.showSuccessEventMessage() ;
        MessageService.unregisterSuccessEvent();

        $scope.form = DraftsApiFactory.getDraftForPreview({id: $routeParams.draftId}, function (d) {
            console.log(d.metadata);
            DocumentCtx.setDocument(d);
            $scope.prefillData = d;
            $scope.patientData = d.metadata.patient;
            $scope.organization = d.metadata.custodian;
            $scope.practitioner = d.metadata.practitioner;
            $scope.encounterData = d.metadata.encounter;
        });
    };
    $scope._loadDocument();
    $scope.docCtx = DocumentCtx;

    $scope.$on('$locationChangeStart', function(next, current) {
        if($route.current.regexp.test($location.path())){
            _.each($routeSegment.chain, function(c){
                c.reload();
            })
        }
    });

    $scope.historyListUrl = '/dp/patients/' + $routeParams.patientId + '/records#search';

    $scope.goBack = function () {
        $window.history.back();
        // $location.path('/dp/patients/' + $routeParams.patientId + '/drafts').search({});
    };

    $scope.edit = function () {
        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/drafts/' + $routeParams.draftId + '/edit').search({});
    };

    $scope.cancel = function(){
        $modalConfirm.show({
            content: 'Ar tikrai norite pašalinti juodraštį?'
        }).then(function () {
            DraftsApiFactory.invalidateDraft({id: $routeParams.draftId});
            MessageService.registerSuccessEvent();
            $scope.goBack();
            MessageService.showSuccessEventMessage();
        });
    }

}]);
