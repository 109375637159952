import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs";
import { ApiV2F090 } from "src/api/api-v2-doc-ref-f090";
import { ApiV2Entities } from "src/api/api-v2-entities";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { EntityModel } from "src/app/shared/models/entity.model";
import { DirectiveReset } from "src/app/shared/services/directive-reset";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";

@Component({
    selector: 'dp-doc-ref-090-edit',
    templateUrl: './doc-ref-f090-form-edit.component.html'
})
export class DpDocRefF090EditComponent implements OnInit {

    backPath: string;
    patientId = null;
    form: FormGroup;
    currentDate = new Date();
    emitedValues = null;
    documentTag = DocumentTagEnum.e027a;
    diagnosisArray = null;
    isLoading = true;
    prefillData: any;

    problemTypeArray: string[];
    clinicalTArray: string[];
    clinicalNArray: string[];
    clinicalMArray: string[];
    tumorStagesArray: string[];
    cancerDiagnosisCircumstancesArray: string[];
    methodOfConfirmingDiagnosisArray: string[];
    documentType: EntityModel = {
        id: "2",
        code: "2",
        name: "f090"
    };

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private fb: FormBuilder,
        private ApiV2F090: ApiV2F090,
        private ApiV2Entities: ApiV2Entities,
        private directiveReset: DirectiveReset
    ) {
        this.form = this.fb.group({
            tumorDiagnosis: [],
            TLK_10_AM: [],
            diagnosisDate: [],
            tumorMorphology: [],
            tumorMorphologyCode: [],
            tumorDifferentialGrade: [],
            tumorDifferentialGradeCode: [],
            clinicalT: [],
            clinicalN: [],
            clinicalM: [],
            pathologicalT: [],
            pathologicalN: [],
            pathologicalM: [],
            tumorStage: [null, [Validators.required]],
            circumstancesForDiagnosis: [null, [Validators.required]],
            confirmedDiagnosis: [null, Validators.required],
            methodOfConfirmingDiagnosis: this.fb.array([]),
            reportDate: [null, [Validators.required]],
            cancerRegistryNotes: []
        });
    }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/f090/list`;
        this.getT();
        this.getN();
        this.getM();
        this.getTumorStages();
        this.getCancerDiagnosisCircumstances();
        this.getConfirmationMethod();
        const id = this.params.viewId;
        this.loadf090(id);
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    loadf090(id: string) {
        return this.ApiV2F090.getf090FormView(id).subscribe((data: any) => {
            if (data) {
                this.initializeData(data);
                this.diagnosisArray = data.diagnosis;
                this.isLoading = false;
            }
        });
    }

    initializeData(data) {
        this.form.controls['tumorDiagnosis'].setValue(data.tumorDiagnosis);
        this.form.controls['TLK_10_AM'].setValue(data.TLK_10_AM);
        this.form.controls['diagnosisDate'].setValue(data.diagnosisDate);
        this.form.controls['tumorMorphology'].setValue(data.tumorMorphology);
        this.form.controls['tumorMorphologyCode'].setValue(data.tumorMorphologyCode);
        this.form.controls['tumorDifferentialGrade'].setValue(data.tumorDifferentialGrade);
        this.form.controls['tumorDifferentialGradeCode'].setValue(data.tumorDifferentialGradeCode);
        this.form.controls['clinicalT'].setValue(data.clinicalT.code);
        this.form.controls['clinicalN'].setValue(data.clinicalN.code);
        this.form.controls['clinicalM'].setValue(data.clinicalM.code);
        this.form.controls['pathologicalT'].setValue(data.pathologicalT.code);
        this.form.controls['pathologicalN'].setValue(data.pathologicalN.code);
        this.form.controls['pathologicalM'].setValue(data.pathologicalM.code);
        this.form.controls['tumorStage'].setValue(data.tumorStage.code);
        this.form.controls['circumstancesForDiagnosis'].setValue(data.circumstancesForDiagnosis.code);
        this.getMethodOfConfirmingDiagnosis(data.methodOfConfirmingDiagnosis);
        //this.form.controls['methodOfConfirmingDiagnosis'].setValue(data.methodOfConfirmingDiagnosis);
        this.form.controls['reportDate'].setValue(new Date(data.reportDate));
        this.form.controls['cancerRegistryNotes'].setValue(data.cancerRegistryNotes);
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    getMethodOfConfirmingDiagnosis(methodOfConfirmingDiagnosis) {
        for (let i = 0; i < methodOfConfirmingDiagnosis.length; i++) {
            (this.form.get('methodOfConfirmingDiagnosis') as FormArray).push(
                this.fb.control(methodOfConfirmingDiagnosis[i])
            );
        }
    }

    addRecipient(inputValue) {
        if (inputValue == null || inputValue === "undefined") {
        } else {
            const methodOfConfirmingDiagnosis = this.form.get('methodOfConfirmingDiagnosis') as FormArray;
            const controls = methodOfConfirmingDiagnosis.controls;
            // Check for duplicate values before adding
            const isDuplicate = controls.some(control => control.value.code === inputValue);

            if (!isDuplicate) {
                methodOfConfirmingDiagnosis.push(this.fb.control(this.methodOfConfirmingDiagnosisArray[inputValue - 1]));
                this.form.controls['confirmedDiagnosis'].setValue(null);
            }
        }

        this.form.controls['confirmedDiagnosis'].updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    removeRecipient(index) {
        (this.form.get('methodOfConfirmingDiagnosis') as FormArray).removeAt(index);
        this.form.controls['confirmedDiagnosis'].updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    getRecipientFormControls(): AbstractControl[] {
        return (<FormArray>this.form.get('methodOfConfirmingDiagnosis')).controls
    }

    getT() {
        this.ApiV2Entities.get('clinicalT').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.clinicalTArray = response;
            }
        });
    }

    getN() {
        this.ApiV2Entities.get('clinicalN').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.clinicalNArray = response;
            }
        });
    }

    getM() {
        this.ApiV2Entities.get('clinicalM').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.clinicalMArray = response;
            }
        });
    }

    getTumorStages() {
        this.ApiV2Entities.get('tumor-stages').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.tumorStagesArray = response;
            }
        });
    }

    getCancerDiagnosisCircumstances() {
        this.ApiV2Entities.get('cancer-diagnosis-circumstances').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.cancerDiagnosisCircumstancesArray = response;
            }
        });
    }

    getConfirmationMethod() {
        this.ApiV2Entities.get('confirmation-method').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.methodOfConfirmingDiagnosisArray = response;
            }
        });
    }

    onChanges(event) {
        this.emitedValues = event;
    }

    onDiagnosisChanges(event) {
        this.diagnosisArray = event;
        if (event.length > 0) {
            this.form.controls['tumorDiagnosis'].setValue(event[0].name);
            this.form.controls['TLK_10_AM'].setValue(event[0].code);
            this.form.controls['diagnosisDate'].setValue(event[0].onsetDate);
        }
    }

    public saveForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const diagnosis = this.diagnosisArray;
        delete formValues.confirmedDiagnosis;
        const mergeJSON = { ...formValues, ...prefillJson, diagnosis };
        console.log("mergeJSON", mergeJSON);
        //this.ApiV2F090.savef090Form(mergeJSON, this.patientId).subscribe();
    }

    public submitForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        delete formValues.confirmedDiagnosis;
        this.ApiV2F090.submitf090Form(mergeJSON, this.patientId).subscribe();
    }

    public signingSubmitForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        delete formValues.confirmedDiagnosis;
        this.ApiV2F090.signingSubmitf090Form(mergeJSON, this.patientId).subscribe();
    }

    public sealConfirmForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        delete formValues.confirmedDiagnosis;
        this.ApiV2F090.sealf090Form(mergeJSON, this.patientId).subscribe();
    }

    public signForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        delete formValues.confirmedDiagnosis;
        this.ApiV2F090.signf090Form(mergeJSON, this.patientId).subscribe();
    }

    public clearForm(): void {
        this.form.get('tumorMorphology').patchValue("");
        this.form.get('tumorMorphologyCode').patchValue("");
        this.form.get('tumorDifferentialGrade').patchValue("");
        this.form.get('tumorDifferentialGradeCode').patchValue("");
        this.form.get('clinicalT').patchValue(null);
        this.form.get('clinicalN').patchValue(null);
        this.form.get('clinicalM').patchValue(null);
        this.form.get('pathologicalT').patchValue(null);
        this.form.get('pathologicalN').patchValue(null);
        this.form.get('pathologicalM').patchValue(null);
        this.form.get('tumorStage').patchValue(null);
        this.form.get('circumstancesForDiagnosis').patchValue(null);
        this.form.get('confirmedDiagnosis').patchValue(null);
        this.cleanMethodOfConfirmingDiagnosis();
        this.form.get('reportDate').patchValue("");
        this.form.get('cancerRegistryNotes').patchValue("");
        this.directiveReset.triggerReset();
        this.form.get('tumorDiagnosis').patchValue(null);
        this.form.get('TLK_10_AM').patchValue(null);
        this.form.get('diagnosisDate').patchValue(null);
    }

    cleanMethodOfConfirmingDiagnosis() {
        return (<FormArray>this.form.get('methodOfConfirmingDiagnosis')).controls = [];
    }

    gatherInfoFromEmittedData() {
        var encounterRef = this.emitedValues.encounter.fullId;
        var patientRef = this.emitedValues.encounter.patientRef;
        var patientData = this.emitedValues.encounter.patientData;
        var authorRef = this.emitedValues.item.practitionerRef;
        var authorData = this.emitedValues.practitioner;
        var organizationRef = this.emitedValues.item.organizationRef;
        var organizationData = this.emitedValues.item.organization;
        var cardName = "090/a - Pranešimas apie naujai diagnozuotą vėžinį susirgimą";
        const combinedJSON = {
            encounterRef: encounterRef,
            patientRef: patientRef,
            patient: patientData,
            authorRef: authorRef,
            author: authorData,
            custodianRef: organizationRef,
            custodian: organizationData,
            cardName: cardName,
            documentType: this.documentType
        }
        return combinedJSON;
    }
}