import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'load-more-button',
    templateUrl: './load-more-button.component.html'
})
export class LoadMoreButtonComponent implements OnInit {

    @Input() working: boolean;
    @Input() title: string;
    @Output() searchService = new EventEmitter<any>();

    ngOnInit(): void {

    }

    loadClick() {

    }
}
