import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";


@Component({
    selector: "shortcut-fill",
    templateUrl: "./shortcut-fill.component.html",
})
export class ShortcutFillComponent implements OnInit {

    @Input() shortcuts: any[];
    @Input() editable: boolean = false;
    @Input() title: string;
    @Output() onShortcutClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {

    }

    edit(event: any) {
        this.onEditClick.emit(event);
    }

    shortcutClick(event: any) {
        this.onShortcutClick.emit(event);
    }
}