import {Component, OnInit, ViewChild} from "@angular/core";
import {ColDef, GridOptions, IServerSideDatasource} from "ag-grid-enterprise";
import {AgGridCommonConfig} from "../../../configs/ag-grid-common.config";
import {AgGridAngular} from "ag-grid-angular";
import {GridReadyEvent} from "ag-grid-community";
import moment from "moment";
import {FullNamePipe} from "../../../pipes/full-name.pipe";
import {AgLinkCell} from "../../ag-grid/components/ag-link-cell/ag-link-cell.component";
import {RouteParams} from "../../../../ng1.routeParams";
import {AgTextCell, TextCellModel} from "../../ag-grid/components/ag-text-cell/ag-text-cell.component";
import {PractitionerModel} from "../../../models/practitioner.model";
import {OrganizationModel} from "../../../models/organization.model";
import {ApiV2ErxMedicationDispenses} from "../../../../../api/api-v2-erx-medication-dispenses";
import {OpticalDeviceEnum} from "../../../models/optical-device.enum";
import {TranslateService} from "@ngx-translate/core";
import {ApiV2MedicationDispense} from "../../../../pharmacist/services/api-v2-medication-dispense";
import {EnvironmentSettings} from "../../../../../environments/env";
import {Environment} from "../../../../../environments/environment-properties";
import PortalType = Environment.PortalType;
import {DispenseListFilterModel} from "../../../models/dispense-list-filter.model";
import {PaginationBarComponent} from "../../list/pagination-bar/pagination-bar.component";
import {Pagination} from "../../list/pagination-bar/pagination-bar.constants";

@Component({
    selector: 'dispense-list',
    templateUrl: './dispense-list.component.html'
})
export class DispenseListComponent implements OnInit {
    @ViewChild(AgGridAngular) agGrid: AgGridAngular;
    @ViewChild(PaginationBarComponent) paginationBar: PaginationBarComponent;

    patientId: string;
    dateFormat: string = 'YYYY-MM-DD';
    data: any[] = [];
    lastFilters: any;
    colDefs: ColDef[] = [];
    defaultColDef: ColDef = {
        sortable: false,
        floatingFilter: false,
        resizable: false,
        autoHeight: true,
        wrapText: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true
        },
        flex: 1,
    };
    paginationPageSize: number = 10;
    dataCount: number = 0;
    pagesCount: number = 0;
    datasource: IServerSideDatasource;
    gridOptions: GridOptions;
    isPharmacist: boolean = EnvironmentSettings.getPortalType() === PortalType.PHARMACIST;

    constructor(private apiV2ErxMedicationDispenses: ApiV2ErxMedicationDispenses, private apiV2MedicationDispense: ApiV2MedicationDispense,
                public fullNamePipe: FullNamePipe, private params: RouteParams, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.colDefs = this.getColumnDefs();
        this.updateResults();

        this.gridOptions = {
            ...AgGridCommonConfig,
            suppressServerSideInfiniteScroll: true,
            overlayNoRowsTemplate: "Duomenų nerasta",
            domLayout: 'autoHeight',
        }
    }

    assignFilter(field: any, value: any, filter: any) {
        if (value) {
            Object.assign(filter, {[field]: value});
        }
    }

    updateResults(advancedFilter?: DispenseListFilterModel) {
        this.datasource = {
            getRows: (params) => {
                this.agGrid.api.showLoadingOverlay();
                let filter = {
                    patientId: this.patientId,
                    order: 'DESC',
                    sort: 'NUMBER',
                    count: this.paginationPageSize
                };

                this.assignFilter('query', advancedFilter?.prescribed, filter);
                this.assignFilter('byPrc', advancedFilter?.myDispenses, filter);
                this.assignFilter('id', advancedFilter?.id, filter);
                this.assignFilter('type', advancedFilter?.type?.[0]?.id, filter);
                this.assignFilter('fromDate', new Date(advancedFilter?.fromDate).getTime(), filter);
                this.assignFilter('toDate', new Date(advancedFilter?.toDate).getTime(), filter);
                this.assignFilter('isUsing', advancedFilter?.isUsing, filter);
                this.assignFilter('abroad', advancedFilter?.abroad, filter);
                this.assignFilter('withoutRecipe', advancedFilter?.withoutRecipe, filter);
                this.assignFilter('page', advancedFilter?.page, filter);

                let apiService: ApiV2ErxMedicationDispenses | ApiV2MedicationDispense;
                if (this.isPharmacist) {
                    apiService = this.apiV2MedicationDispense;
                } else {
                    apiService = this.apiV2ErxMedicationDispenses;
                }

                apiService.getDocuments(filter).subscribe(res => {
                    this.data = res.result;
                    this.dataCount = res.totalResults;
                    const pagesCount = res.totalResults / 10;
                    this.pagesCount = Math.ceil(pagesCount);
                    this.lastFilters = filter;

                    if (this.dataCount === 0) {
                        this.agGrid.api.hideOverlay();
                        this.agGrid.api.showNoRowsOverlay();
                    } else {
                        this.agGrid.api.hideOverlay();
                    }

                    params.success({rowData: this.data, rowCount: this.dataCount});
                });
            }
        };
    }

    onGridReady(params: GridReadyEvent) {

    }

    getOnlyDate(value: any) {
        if (value) {
            return moment(value).format(this.dateFormat);
        }
        return "";
    }

    getColumnDefs(): ColDef[] {
        return [
            {
                colId: 'dispenseData.dispense.whenHandedOver',
                field: 'dispenseData.dispense.whenHandedOver',
                headerName: 'Data',
                headerTooltip: 'Data',
                valueFormatter: params => {
                    return this.getOnlyDate(params.value);
                },
                maxWidth: 110,
                cellClass: 'fs-5 text-secondary'
            },
            {
                colId: 'metadata.id',
                field: 'metadata.id',
                headerName: 'Nr',
                headerTooltip: 'Nr',
                cellRenderer: AgLinkCell,
                cellRendererParams: {
                    urlPath: this.isPharmacist ?
                        `/patients/${this.patientId}/disp/` :
                        `dp/patients/${this.patientId}/erx/dsp/`,
                    valueAsId: true,
                    pathAfterId: this.isPharmacist ? '/view' : ''
                },
                valueFormatter: params => {
                    return params.data.metadata.docId;
                },
                maxWidth: 125,
                cellClass: 'px-0'
            },
            {
                colId: 'dispenseData.dispense.medication.productName',
                field: 'dispenseData.dispense.medication.productName',
                headerName: 'Išduota',
                headerTooltip: 'Išduota',
                cellRenderer: AgTextCell,
                valueGetter: params => {
                    const dispense = params.data.dispenseData?.dispense;
                    const mainText: string[] = [
                        dispense?.medication?.productName
                    ];

                    const additionalText: string[] = [
                        dispense?.medication?.strength,
                        dispense?.medication?.pharmForm?.displayValue,
                        dispense?.medication?.activeIngredients,
                        dispense?.lenses?.opticalDevice ? this.translateService.instant(dispense?.lenses?.opticalDevice) : "",
                        dispense?.lenses?.opticalDevice === OpticalDeviceEnum.AL ? this.translateService.instant(dispense?.lenses?.lensesType) : "",
                    ];

                    const textCellModel: TextCellModel = {
                        mainText: mainText,
                        additionalText: additionalText
                    };

                    return textCellModel;
                },
            },
            {
                colId: 'dispenseData.dispense.quantity.value',
                field: 'dispenseData.dispense.quantity.value',
                headerName: 'Kiekis',
                headerTooltip: 'Kiekis',
                maxWidth: 60
            },
            {
                colId: 'dispenseData.dispense.dueDate',
                field: 'dispenseData.dispense.dueDate',
                headerName: 'Pakanka iki',
                headerTooltip: 'Pakanka iki',
                valueFormatter: params => {
                    return this.getOnlyDate(params.value);
                },
                maxWidth: 110
            },
            {
                colId: 'practitioner',
                field: 'practitioner',
                headerName: 'Išdavė',
                headerTooltip: 'Išdavė',
                cellRenderer: AgTextCell,
                valueGetter: params => {
                    const practitioner: PractitionerModel = params.data.practitioner;
                    const mainText: string[] = [
                        this.fullNamePipe.transform(practitioner),
                        practitioner.speciality.displayValue
                    ];

                    const organization: OrganizationModel = params.data.organization;
                    const additionalText: string[] = [
                        organization?.name
                    ];

                    const textCellModel: TextCellModel = {
                        mainText: mainText,
                        additionalText: additionalText
                    };

                    return textCellModel;
                },
            },
        ];
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        const filter = {...this.lastFilters, page: pagination.page};

        this.updateResults(filter);
    }
}