angular.module('medvais.doctor.user').directive('medvaisImgStudyList', function () {
    return {
        restrict: 'E',
        scope: {options: '='},
        templateUrl: 'modules/medvais/doctor/user/img-study-list.html',
        controller: 'medvaisImgStudyListCtrl'
    };
});

angular.module('medvais.doctor.user').controller('medvaisImgStudyListCtrl', ["$scope", "$rootScope", "$q", "$routeParams", "$filter", "$location", "$log", "$window", "MedvaisImagingStudyApiFactory", "MedvaisDocumentApiFactory", "MedvaisPatientApiFactory", "MedvaisClassifierApiFactory", "MedvaisMedvaisApiFactory", "$medvaisSendStudy", "$medvaisPatientIdentification", "MessageService", "MedvaisStudyUploader", "MedvaisImgStudyListFiltersService", "MedvaisPatientSearchService", "FormLoaderService", "$timeout", "$medvaisModalInfo", "$medvaisCreateTelemedicineEncounter", "MedvaisDocStateService", "MedvaisPageController", "MedvaisUtils", "MedvaisOrderApiFactory", function ($scope, $rootScope, $q,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   $routeParams,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   $filter,
        $location,
        $log,
        $window,
        MedvaisImagingStudyApiFactory,
        MedvaisDocumentApiFactory,
        MedvaisPatientApiFactory,
        MedvaisClassifierApiFactory,
        MedvaisMedvaisApiFactory,
        $medvaisSendStudy,
        $medvaisPatientIdentification,
        MessageService,
        MedvaisStudyUploader,
        MedvaisImgStudyListFiltersService,
        MedvaisPatientSearchService,
        FormLoaderService,
        $timeout,
        $medvaisModalInfo,
        $medvaisCreateTelemedicineEncounter,
        MedvaisDocStateService,
        MedvaisPageController, MedvaisUtils,
        MedvaisOrderApiFactory) {


    console.log('version: 1.1.4');

    var practitionerId;
    var practitionerRef;
    var organizationId;
    var organizationRef;
    var organizationJAR;
    var token;
    var clientIP;
    var modalitiesList = [];
    $scope.studyFile = {};
    $scope.meddreamUrl = '';
    var dateFormat = 'yyyy-MM-dd';
    $scope.filters = {selectedPerson: null, useDefaultFilter: true};

    $scope.loading = false;
    $scope.studyListLoading = false;
    $scope.telemedicineOrdersListLoading = false;
    $scope.timeout;

    $scope.pageCtrl = new MedvaisPageController();
    $scope.imagingStudyList = [];
    $scope.telemedicineOrdersList = [];


    FormLoaderService.startFormLoading();

    $scope.listTypes = [
        {id: 0, name: $filter('translate')('medvais.main.list.type.organization.studies')},
        {id: 1, name: $filter('translate')('medvais.main.list.type.telemedicine.orders')}
    ];

    $scope.studyStatesList = [
        {id: 0, name: $filter('translate')('medvais.img.study.list.filter.study.status.item.reported')},
        {id: 1, name: $filter('translate')('medvais.img.study.list.filter.study.status.item.unreported')}
    ];

    $q.all([
        $scope.options.getPractitioner(),
        $scope.options.getOrganization(),
            $scope.options.getUserInfo()
        ]).then(function(result) {


        var pract = result[0];
        var org = result[1];
        var user = result[2];

        practitionerId = pract.id;
        practitionerRef = pract.fullId;
        organizationJAR = org.jarCode;
        organizationRef = org.fullId;
        organizationId = org.id;
        token = user.token;
        clientIP = user.clientIP;

        console.log("practitionerId: " + practitionerId);
        console.log("practitioner ref: " + practitionerRef);
        console.log("organizationId: " + organizationId);
        console.log("organization ref: " + organizationRef);
        console.log("organizationJAR: " + organizationJAR);
        console.log("token: " + token);
        console.log("client IP: " + clientIP);

        checkStatus();
        initFilters();
    });

    var checkStatus = function() {
        if ($routeParams.sgnst) {
            $log.debug("Display signing status.", $routeParams.sgnst);
            if ($routeParams.sgnst === 'ok') {
                MessageService.success($filter('translate')('medvais.doc.sign.success.msg'));
            } else {
                MessageService.error($filter('translate')('medvais.doc.sign.fail.msg') + ($routeParams.msgid ? ' [' + $routeParams.msgid + ']' : ''));
            }
            $location.url($location.path()).replace();
        }
    };

    var initFilters = function() {
        MedvaisPatientSearchService.getPatientData($routeParams.patientFilter).then(
            function(result) {
                var patFilter = result;

                if (MedvaisImgStudyListFiltersService.getFilter() && !MedvaisImgStudyListFiltersService.getFilter().useDefaultFilter) {
                    $log.debug('Filters from memory.');

                    $scope.filters = MedvaisImgStudyListFiltersService.getFilter();
                    $scope.filters.selectedPerson = $scope.filters.patientData;
                    $scope.filters.useDefaultFilter = true;
                    MedvaisImgStudyListFiltersService.setFilter($scope.filters);
                } else {
                    $log.debug('Default filter.');

                    MedvaisImgStudyListFiltersService.setFilter(null);
                    $scope.filters.sdate = null;
                    $scope.filters.edate = null;
                    $scope.filters.patient = $routeParams.patientFilter;
                    $scope.filters.modality = $routeParams.modalityFilter;
                    $scope.set3DayInterval();

                    $location.url($location.path());
                    $location.replace();

                    if (patFilter) {
                        $scope.filters.patient = MedvaisPatientSearchService.getPatientId(patFilter);
                        $scope.filters.selectedPerson = MedvaisPatientSearchService.getPatientLabel(patFilter);
                        $scope.filters.patientData = $scope.filters.selectedPerson;
                        $scope.filters.reported = [true, true];
                    } else {
                        $scope.filters.reported = [false, true];
                        $scope.filters.patientData = null;
                    }
                    $scope.filters.uid = null;
                    $scope.filters.listType = 0;
                }

                if ($scope.filters.listType === 1) {
                    $scope.loadTelemedicineOrdersFirstPage();
                } else {
                    $scope.loadStudyFirstPage();
                }
            }
        );
    };

    MedvaisClassifierApiFactory.getDicomModalities().$promise.then(
        function (result) {
            $scope.modalitiesList = result.items;
            $scope.modalitiesList.unshift({id: "", code: "", name: $filter('translate')('medvais.all')});
        }
    );

    MedvaisMedvaisApiFactory.getMeddreamUrl().$promise.then(
        function (response) {
            $scope.meddreamUrl = response.url;
        }
    );

    $scope.loadImagingStudiesList = function (modality, sdate, edate) {
        FormLoaderService.startFormLoading();

        console.log("loadImagingStudiesList(). organizationId: " + organizationId);
        console.log("loadImagingStudiesList(). practitionerId: " + practitionerId);
        console.log("loadImagingStudiesList(). modality: " + modality);
        console.log("loadImagingStudiesList(). reported: " + $scope.filters.reported);
        console.log("loadImagingStudiesList(). patientId: " + $scope.filters.patient);
        console.log("loadImagingStudiesList(). uid: " + $scope.filters.uid);
        var modalityFilter = modality !== undefined && modality !== null && modality !== '' ? modality : null;
        var reportedFilter =
                $scope.filters.reported[0] === true && $scope.filters.reported[1] === true || $scope.filters.reported[0] === false && $scope.filters.reported[1] === false
                    ? null
                    : $scope.filters.reported[0] === true && $scope.filters.reported[1] === false
                        ? true : false;

        MedvaisImagingStudyApiFactory.getImagingStudies(
                {
                    uid: $scope.filters.uid,
                    modality: modalityFilter,
                    sdate: sdate,
                    edate: edate,
                    organizationId: organizationId,
                    practitionerId: practitionerId,
                    subjectId: $scope.filters.patient,
                    reported: reportedFilter,
                    count: $scope.pageCtrl.getCount(),
                    page: $scope.pageCtrl.getPage()
                }).$promise.then(
                    function (result) {
                        var imgStudies = result.items;
                        $log.debug('Study list loaded.');

                        if (!imgStudies || imgStudies.length > 0) {
                            $log.debug('List has items.', imgStudies);
                            for (var i = 0; i < imgStudies.length; i++) {
                                imgStudies[i].docs = [];

                                var docList = MedvaisDocumentApiFactory.get027vaDocListForImagingStudy({imagingStudyId: imgStudies[i].id, organizationId: organizationId, practitionerId: practitionerId});
                                imgStudies[i].docs = docList;
                                $scope.imagingStudyList.push(imgStudies[i]);
                            }
                        } else {
                            $log.debug('List empty.');
                        }
                        $scope.pageCtrl.afterLoad(result.total);
                    }
        ).finally(function () {
            $log.debug('Study list loading finally part.');
            $scope.studyListLoading = false;
            FormLoaderService.endFormLoading();
        });
    };

    $scope.loadStudyFirstPage = function () {
        if ($scope.studyListLoading === false) {
            $location.search('');
            $scope.studyListLoading = true;
            $scope.pageCtrl.reset();
            $scope.imagingStudyList = [];
            $scope.loadImagingStudiesList($scope.filters.modality, $scope.filters.sdate, $scope.filters.edate);
        }
    };

    $scope.loadStudyNextPage = function () {
        if ($scope.studyListLoading === false) {
            $location.search('');
            $scope.studyListLoading = true;
            $scope.pageCtrl.beforeLoad();
            $scope.loadImagingStudiesList($scope.filters.modality, $scope.filters.sdate, $scope.filters.edate);
        }
    };

    $scope.search = function () {
        $scope.loadStudyFirstPage();
    };

    $scope.loadMore = function () {
        $scope.loadStudyNextPage();
    };

    $scope.loadTelemedicineOrders = function() {
        FormLoaderService.startFormLoading();

        var modalityFilter = $scope.filters.modality !== undefined
            && $scope.filters.modality !== null
            && $scope.filters.modality !== '' ? $scope.filters.modality : null;

        var reportedFilter = $scope.filters.reported[0] === true && $scope.filters.reported[1] === true
            || $scope.filters.reported[0] === false && $scope.filters.reported[1] === false
                ? null : $scope.filters.reported[0] === true && $scope.filters.reported[1] === false ? true : false;

        MedvaisOrderApiFactory.getTelemedicineOrdersList(
            {
                uid: $scope.filters.uid,
                modality: modalityFilter,
                sdate: $scope.filters.sdate,
                edate: $scope.filters.edate,
                patientId: $scope.filters.patient,
                reported: reportedFilter,
                count: $scope.pageCtrl.getCount(),
                page: $scope.pageCtrl.getPage()
            }
        ).$promise.then(
            function (result) {
                $scope.telemedicineOrdersList = $scope.telemedicineOrdersList.concat(result.items);
                $scope.pageCtrl.afterLoad(result.total);
                $log.debug('Telemedicine orders list loaded.');
            }
        ).finally(function () {
            $log.debug('Telemedicine orders list loading finally part.');
            $scope.telemedicineOrdersListLoading = false;
            FormLoaderService.endFormLoading();
        });
    };

    $scope.loadTelemedicineOrdersFirstPage = function () {
        if ($scope.telemedicineOrdersListLoading === false) {
            $scope.telemedicineOrdersListLoading = true;
            $scope.pageCtrl.reset();
            $scope.telemedicineOrdersList = [];
            $scope.loadTelemedicineOrders();
        }
    };

    $scope.loadTelemedicineOrdersNextPage = function () {
        if ($scope.telemedicineOrdersListLoading === false) {
            $scope.telemedicineOrdersListLoading = true;
            $scope.pageCtrl.beforeLoad();
            $scope.loadTelemedicineOrders()
        }
    };


    $scope.clearFilters = function() {
        MedvaisImgStudyListFiltersService.setFilter(null);
        $scope.filters.sdate = null;
        $scope.filters.edate = null;
        $scope.filters.patient = null;
        $scope.filters.modality = null;
        $scope.filters.patientData = null;
        $scope.filters.selectedPerson = null;
        $scope.filters.useDefaultFilter = true;
        $scope.set3DayInterval();
        $scope.filters.reported = [false, true];

        $scope.loadStudyFirstPage();
    };

    $scope.setDayInterval = function () {
        var sDay = new Date();
        $scope.filters.sdate = $filter('date')(sDay, dateFormat);
        $scope.filters.edate = $filter('date')(sDay, dateFormat);
    };

    $scope.set3DayInterval = function () {
        var eDate = new Date();
        var sDate = new Date();
        sDate.setDate(sDate.getDate() - 2);
        $scope.filters.sdate = $filter('date')(sDate, dateFormat);
        $scope.filters.edate = $filter('date')(eDate, dateFormat);
    };

    $scope.set30DayInterval = function () {
        var eDate = new Date();
        var sDate = new Date();
        sDate.setDate(sDate.getDate() - 29);
        $scope.filters.sdate = $filter('date')(sDate, dateFormat);
        $scope.filters.edate = $filter('date')(eDate, dateFormat);
    };


    $scope.setWeekInterval = function () {
        var eDate = new Date();
        var sDate = new Date();
        sDate.setDate(sDate.getDate() - 6);
        $scope.filters.sdate = $filter('date')(sDate, dateFormat);
        $scope.filters.edate = $filter('date')(eDate, dateFormat);
    };


    $scope.identifyPatient = function (index) {
        var patientToIdentify: any = {};
        patientToIdentify.imagingStudyId = $scope.imagingStudyList[index].id;
        if ($scope.imagingStudyList[index].patient !== undefined ) {
            patientToIdentify.familyName = $scope.imagingStudyList[index].patient.familyName;
            patientToIdentify.givenName = $scope.imagingStudyList[index].patient.givenName;
            patientToIdentify.personalCode = $scope.imagingStudyList[index].patient.personalCode;
        }

        $medvaisPatientIdentification.show(patientToIdentify).then(
            function(res){
                $scope.loadStudyFirstPage();
            },
            function(err){
                MessageService.clearAll();
                MessageService.add(err.type, $filter('translate')(err.msg));
                $window.scrollTo(0, 10);
            }
        ).finally(function () {
            FormLoaderService.endFormLoading();
        });
    };


    $scope.openSendImagingStudyDialog = function (imagingStudy) {
        $medvaisSendStudy.show(
            {
                jarCode: organizationJAR,
                detailRef: imagingStudy.fullId,
                targetRef: organizationRef,
                subjectRef: imagingStudy.patient.fullId,
                sourceRef: practitionerRef
            }
        ).then(
            function(res){
                if (res === "true") {
                    MessageService.success($filter('translate')('medvais.msg.study.send.task.processing'));
                }
            }
        );
    };


    $scope.createDocument = function (index) {
        $scope.filters.useDefaultFilter = false;
        MedvaisImgStudyListFiltersService.setFilter($scope.filters);
        $location.path($scope.options.getNew027vaDocUrl()).search('imagingStudyId', $scope.imagingStudyList[index].id);
    };


    $scope.openDocument = function (documentId, status) {
        $scope.filters.useDefaultFilter = false;
        MedvaisImgStudyListFiltersService.setFilter($scope.filters);
        console.log('Document status: ' + status);
        if (status === 'preliminary') {
            $location.path($scope.options.getEdit027vaDocUrl(documentId));
        } else {
            $location.path($scope.options.getView027vaDocUrl(documentId));
        }

    };


    $scope.openImagingStudyView = function (imagingStudy) {
        window.open($scope.meddreamUrl + '?study=' + imagingStudy.iuid.replace('urn:oid:', '') + '&token=' + token);
    };


    $scope.addImageStudy = function(){
        $('#fileDialog').trigger('click');
    };


    var displayUploadSuccess = function () {
        MessageService.clearAll();
        MessageService.success($filter('translate')('medvais.msg.study.upload.success'));
    };

    var displayUploadError = function () {
        MessageService.clearAll();
        MessageService.error($filter('translate')('medvais.msg.err.study.upload.failed'));
    };


    $scope.onStudyFileSelect = function(file){
        MessageService.clearAll();
        if (file) {
            FormLoaderService.startFormLoading();
            MedvaisStudyUploader.upload(file, organizationJAR, displayUploadSuccess, displayUploadError);
        }
    };


    $scope.searchPatient = function (value) {
        if($scope.timeout) {
            $timeout.cancel($scope.timeout);
        }

        if (!value || value.length < 3 || value.fullId) {
            return [];
        }

        $scope.loading = true;
        return $scope.timeout = $timeout(function(){

            return MedvaisPatientSearchService.search(value).finally(function() {
                $scope.loading = false;
                $scope.timeout = null;
            });

        }, 2000);
    };


    $scope.searchPatientChange = function (patient) {
        if (patient && patient.id) {
            $scope.filters.patient = patient.id;
            $scope.filters.patientData = MedvaisPatientSearchService.getPatientLabel(patient);
        }
    };

    $scope.removePatientFilter = function () {
        $scope.filters.patient = null;
        $scope.filters.patientData = null;
        $scope.filters.selectedPerson = null;
    };


    $scope.getPatientLabel = function (patient) {
        return MedvaisPatientSearchService.getPatientLabel(patient);
    };

    $scope.openCreateTelemedicineEncounterModal = function () {
        $medvaisCreateTelemedicineEncounter.show(organizationId, organizationRef, practitionerId, practitionerRef).then(
            function(result){
                $scope.loadTelemedicineOrdersFirstPage();
            }
        );
    };

    $scope.getDocStateLabel = function(state, isSigned) {
        return MedvaisDocStateService.getStatusLabel(state, isSigned);
    };

    $scope.getFullName = function(person) {
        return MedvaisUtils.getFullName(person);
    };

    $scope.changeListType = function(type) {
        $log.debug('List type: ' + type);
        if (type === 1) {
            $scope.loadTelemedicineOrdersFirstPage();
        } else {
            $scope.loadStudyFirstPage();
        }
    };


}]);
