ng1App.controller('doctor.patient.erx_pr.EditCtrl',
    ['$scope', '$location', 'ErxHistoryPaths', '$routeParams', '$q', 'ErxPaths', 'ErxIntegration', 'AagaSgasApiFactory', 'SessionApiFactory',
     function ($scope, $location, ErxHistoryPaths, $routeParams, $q, ErxPaths, ErxIntegration, AagaSgasApiFactory, SessionApiFactory) {

    $scope.prescriptionFormOptions = {
        // route to return to from the prescription form. This should probably be the prescription list.
        getReturnUrl: function () {
            return ErxPaths.list();
        },
        // prescription type should only be filled for new prescription forms.
        prescriptionType: $routeParams.prescriptionType,
        // prescription id should only be filled for editing prescription forms
        prescriptionId: $routeParams.prescriptionId,
        // composition ID of E003 or E025 document
        getCompositionId: function(){
            return $routeParams.documentId;
        },
        // path to view prescription form by id (for the same encounter)
        getViewPrescriptionFormUrl: function (prescriptionId) {
            return ErxPaths.view(prescriptionId);
        },
        //returns integration object with getters for classifier values and displayGlobalMessage function
        getIntegrationData: ErxIntegration.getIntegrationDataObject,
        redirectToViewDsp: function (patientId, dispenseId) {
            $location.url(ErxHistoryPaths.viewPatDsp(patientId, dispenseId));
        },
        redirectToSignPrescription: function (id) {
            $location.path(ErxPaths.sign(id));
        },
        redirectToViewPrescriptionDoc: function(prescriptionId){
            $location.url(ErxPaths.viewDoc(prescriptionId));
        },
		getAagaSgas: function () {
            var deferred = $q.defer();
            AagaSgasApiFactory.getAagaSgasForDocument({id: $routeParams.documentId}, function (res) {
                if (res.aagaSgas) {
                    deferred.resolve(res);
                } else if ($routeParams.aagasgas) {
                    deferred.resolve({'aagaSgas': $routeParams.aagasgas});
                    // put into session
                    // needed to retrieve when aagaSgas parameter is passed during integrated component invocation
                    SessionApiFactory.putAagaSgas({aagaSgas:encodeURIComponent($routeParams.aagasgas)}, {});
                } else {
                    // get from session
                    // maybe it is integrated component? so aaga/sgas is stored into session
                    SessionApiFactory.getAagaSgas(function (aagaSgasResponse) {
                        var aagaSgas = aagaSgasResponse.aagasgas;
                        if (aagaSgas && aagaSgas!==undefined) {
                            deferred.resolve({'aagaSgas': decodeURIComponent(aagaSgas)});
                        } else {
                            deferred.resolve({});
                        }
                    });
                }
            });
            return deferred.promise;
        }
    };

}]);