// Validation
var hasErrors = function (field, errors) {
	if (field) {
		for (var i = 0; i < errors.length; i++) {
			if (field.$error[errors[i]]) {
				return true;
			}
		}
	}
	return false;
};

ng1App.directive('editBiosocialDisorder', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/070aLK/parts/edit_biosocial_disorder.html',
		restrict: 'E',
		require: '^form',
		scope: {
			certificate: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			
			// Generate values for Barthel and Karnofsky scales
			scope.scaleValues = [];
			for (var i = 0; i <= 20; i++) {
				scope.scaleValues.push(i * 5);
			}
			
			// Validation
			scope.hasErrors = hasErrors;
			
			scope.otherDataChanged = function () {
				if (scope.certificate.biosocialDisorder.otherData == '') {
					scope.certificate.biosocialDisorder.otherData = null;
				}
			};
		}
	};
});

ng1App.directive('editMedicalRehabilitation', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/070aLK/parts/edit_medical_rehabilitation.html',
		restrict: 'E',
		require: '^form',
		scope: {
			certificate: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			
			scope.additional = {
				serviceTypeSearch: null,
				profileSearch: null
			};
			
			scope.certificate.$promise.then(function () {
				scope.data = scope.certificate.medicalRehabilitation;
				
				scope.additional.serviceTypeSearch = _.getCodeName(scope.certificate.medicalRehabilitation.serviceType);
				scope.additional.profileSearch = _.getCodeName(scope.certificate.medicalRehabilitation.profile);
			});
			
			// Service type
			scope.searchForServiceType = TypeaheadSearchHandlers.MR_SERVICE_TYPE;
			scope.changeServiceType = function (type) {
				if (type) {
					if (type.code) {
						scope.certificate.medicalRehabilitation.serviceType = type;
					}
				} else {
					scope.certificate.medicalRehabilitation.serviceType = null;
				}
			};
			
			// Profile
			scope.searchForProfile = TypeaheadSearchHandlers.MR_PROFILE;
			scope.changeProfile = function (profile) {
				if (profile) {
					if (profile.code) {
						scope.certificate.medicalRehabilitation.profile = profile;
					}
				} else {
					scope.certificate.medicalRehabilitation.profile = null;
				}
			};
			
			// Validation
			scope.hasErrors = hasErrors;
			
			// Temporary solution for conditional requirement. ng-required for wrapped input doesn't work properly
			scope.parameterDateIsRequired = function (value, parameterValue) {
				return scope.form.submitted && parameterValue && !value;
			};
		}
	};
}]);

ng1App.directive('editMedicalRehabilitationPrescription', 
    ['OrganizationsApiFactory',
     function (OrganizationsApiFactory) {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/070aLK/parts/edit_medical_rehabilitation_prescription.html',
		restrict: 'E',
		require: '^form',
		scope: {
			certificate: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			
			scope.additional = {
				fillPrescription: false,
				
				jarSearch: null,
				searchedForJar: false
			};
			
			scope.certificate.$promise.then(function () {
				if (scope.certificate.medicalRehabilitationPrescription) {
					scope.additional.fillPrescription = true;
					scope.additional.searchedForJar = true;
				}
			});
			
			scope.prescriptionFillChanged = function () {
				if (scope.additional.fillPrescription) {
					if (!scope.certificate.medicalRehabilitationPrescription) {
						scope.certificate.medicalRehabilitationPrescription = {};
					}
				} else {
					scope.certificate.medicalRehabilitationPrescription = null;
					
					scope.additional.jarSearch = null;
					scope.additional.searchedForJar = false;
				}
			};
			
			// JAR
			scope.searchForJar = function () {
				if (scope.additional.jarSearch) {
					scope.additional.searchedForJar = false;
					scope.certificate.medicalRehabilitationPrescription.tpfJarCode = null;
					scope.certificate.medicalRehabilitationPrescription.tpfTitle = null;
					
					OrganizationsApiFactory.searchJAR({code: scope.additional.jarSearch}, function (result) {
						scope.additional.searchedForJar = true;
						if (result.jarCode) {
							scope.certificate.medicalRehabilitationPrescription.tpfJarCode = result.jarCode;
							scope.certificate.medicalRehabilitationPrescription.tpfTitle = result.name;
							scope.additional.jarSearch = null;
						}
					});
				}
			};
		}
	};
}]);