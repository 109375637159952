angular.module('erx.api').factory('ErxSubstancesApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Substance';
  return $resource('', {}, {
    // Veikliujų medžiagų paieška
    searchSubstances: {
      method: 'GET',
      url: URL_BASE + '/search',
      isArray: true
    }
  });
}]);


