ng1App.controller('doctor.patient.cert.I.e047ViewCtrl', 
    ['$scope', 'AsideBlockModel',
     function ($scope, AsideBlockModel) {
    $scope._shared = {
        contentList: [
            new AsideBlockModel('cer.e047.pat'),
            new AsideBlockModel('cer.e047.ini'),
            new AsideBlockModel('cer.e047.doc'),
            new AsideBlockModel('cer.e047.har', function () {
                var variable = $scope.certificate.harmfulFactors;
                return typeof variable !== 'undefined' && variable !== null;
            })
        ]
    };
}]);