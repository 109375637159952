<div class="modal fade" [id]="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" (keydown.enter)="$event.preventDefault()">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="modalSize">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title fs-5" id="modalTitle">{{title|translate}}</h4>
                <button [id]="modalCloseId" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="isLoaded">
                    <ng-content></ng-content>
                </ng-container>
                <spinner [working]="!isLoaded"></spinner>
            </div>
            <div class="modal-footer" *ngIf="hasFooterButtons">
                <button class="btn btn-primary" aria-hidden="true" (click)="confirmClick()">
                    {{'continue'| translate}}
                </button>
                <button class="btn btn-secondary" data-bs-dismiss="modal" aria-hidden="true" (click)="cancelClick()">
                    {{'cancel'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>