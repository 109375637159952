<div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Ar šios diagnozės dar aktualios pacientui?' | translate }}</h5>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
    <p>
        {{ 'Jeigu pacientui dėl šių diagozių dar reikės lankytis pas gydytojus, tai šių diagnozių uždaryti nereikia.' }}
    </p>
    <p>
        {{ 'Pažymėtos diagnozės bus uždarytos kaip nebeaktualios pacientui.' }}
    </p>
    <ol *ngIf="currentDiagnoses?.length"
        class="slats hover{{ currentDiagnoses?.length > 0 ? ' border-bottom' : ''}} border-dark-subtle">
        <li *ngFor="let diagnose of currentDiagnoses; let i = index"
            class="diagnosis-card pb-2 p-4{{ diagnose.type?.code === 'leading' ? ' bg-success-subtle' : '' }}">
            <div class="diagnosis-content">
                <div class="diagnosis-header">
                    <div class="form-check">
                        <input [id]="'diagnosis_' + i" type="checkbox" class="form-check-input"
                               [(ngModel)]="diagnose.selected"
                               (change)="onDiagnosisSelectionChange()"/>
                        <label class="form-check-label" [for]="'diagnosis_' + i">
                            <strong>{{ diagnose.code.code }} {{ diagnose.code.name }}</strong>
                        </label>
                    </div>
                </div>
                <div class="diagnosis-details">
                    <span *ngIf="diagnose.onsetPeriodStart">
                        {{ diagnose.onsetPeriodStart | date: 'YYYY-MM-dd' }} {{ ' - ...' }}
                    </span>
                    <span class="vr"></span>
                    <span *ngIf="diagnose.verificationStatus">
                        {{ diagnose.verificationStatus.name }}
                    </span>
                    <span class="vr"></span>
                    <span *ngIf="diagnose.severity">
                        {{ diagnose.severity.name }}
                    </span>
                </div>
            </div>
        </li>
    </ol>

    <div class="form-group p-4 req">
        <app-input
            id="circumstances"
            type="textarea"
            [labelText]="'doc.dia.lis.cir' | translate"
            [fControl]="formGroup?.get('circumstances')"
        />
    </div>

    <div class="p-4" *ngIf="currentDiagnoses?.length === 0">
        <p>{{ 'Diagnozių nėra.' }}</p>
    </div>

    <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="formGroup.invalid || !isAnySelected()" (click)="saveDiagnoses()">
            {{ 'Tęsti' }}
        </button>
        <button class="btn btn-secondary" (click)="close()">
            {{ 'Atšaukti' }}
        </button>
    </div>
</div>