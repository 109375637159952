import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ConfigService } from "../../config/config.service";
import { EntityModel } from '../models/entity.model';
import { HttpParams } from '@angular/common/http';
import { InrModel } from '../models/inr.model';

@Injectable({
    providedIn: 'root'
})
export class ApiV2Inr extends ConfigService {
    path = this.apiUrl + '/inr';

    getInrList(patientId: string, params?: {[key: string]: any}): Observable<{ items: InrModel[], total: number }> {
        const url = `${this.path}/list`;
        return this.http.get<{ items: InrModel[], total: number }>(url);
    }

    getInrById(patientId: string, params?: {[key: string]: string | number}): Observable<InrModel> {
        const url = `${this.path}/view/${patientId}`;
        return this.http.get<InrModel>(url, {params});
    }
    getMockDrugs(params?: any): Observable<any[]> {
        const url = `${this.path}/drugs`;
        return this.http.get<any[]>(url, {params});
    }
    getMockVaccines(params?: any): Observable<any[]> {
        const url = `${this.path}/vaccines`;
        return this.http.get<any[]>(url, {params});
    }
}
