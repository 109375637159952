<app-e096-nt-condition-during-hospitalization [answersVariants]="answersVariants" />
<app-e096-nt-examination-pregnant-woman [answersVariants]="answersVariants" [fruitLength]="fruitLength?.length" />
<app-e096-nt-research-planned [answersVariants]="answersVariants" [editable]="editable" />
<app-e096-nt-appointments [answersVariants]="answersVariants" />
<app-e096-nt-maternity-care-plan [answersVariants]="answersVariants" />

<div class="d-flex gap-3 mt-3" *ngIf="editable">
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger flex-grow-1" (click)="resetForm()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>