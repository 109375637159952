import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColDef, FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { Observable } from "rxjs";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { RouteParams } from "../../../../../../ng1.routeParams";
import { AgActionsCell } from "../../../../../../shared/components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { AgPaginationBarComponent } from "../../../../../../shared/components/ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";
import { AgGridCommonConfig } from "../../../../../../shared/configs/ag-grid-common.config";
import { FilteredDataModel } from "../../../../../../shared/models/filtered-data.model";
import { DocumentTagEnum } from "../../../../../../shared/models/document-tag.enum";
import { States } from "./nursing-and-care-plan-history.constants";

@Component({
    selector: "aspn-nursing-and-care-plan-history",
    templateUrl: "./nursing-and-care-plan-history.component.html",
})
export class NursingAndCarePlanHistoryComponent implements OnInit {
    @ViewChild(AgPaginationBarComponent) paginationBar: AgPaginationBarComponent;
    getOptions() {
        return { ...AgGridCommonConfig }
    }

    gridOptions = this.getOptions()

    constructor(private dpAspnContextService: DpAspnContextService, private params: RouteParams, private router: Router,) { }
    private patientId: string;
    backPath: string;

    async ngOnInit(): Promise<void> {
        this.patientId = this.params.patientId;
        this.backPath = '/dp/patients/' + this.patientId + '/aspn/summary'
    }

    public nursingAndCarePlanHistoryColDefs: ColDef[] = [
        { field: "createDate", headerName: "Sudarymo data" },
        {
            field: "planPractitioner", headerName: "Planą sudarė", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + " | "
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            field: "status",
            headerName: "Būsena",
            valueFormatter: (params) => {
                return States[params.value];
            },
        },
        {
            field: "actionButton",
            headerName: "",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: (params.data.status !== 'Cancelled' && params.data.status !== 'Signed') ? true : false,
                    viewable: false,
                    copyable: true,
                    urlPathEdit: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spp}/` + params.data.id,
                    urlPathCopy: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spp}/` + params.data.id
                }
            }
        },
    ];

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        autoHeight: true,
        wrapText: true,
    };

    getContext() {
        return {
            patientId: this.patientId,
        };
    }

    public nursingAndCarePlanHistoryData$!: Observable<any[] | FilteredDataModel>;
    //@ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    onGridReady(params: GridReadyEvent) {
        this.nursingAndCarePlanHistoryData$ = this.dpAspnContextService.getNursingAndCarePlanHistoryByPatientId(this.patientId);
        this.paginationBar.initForAgGrid(params);
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    loadNursingAndCarePlanHistoryList(query?: any) {
        this.nursingAndCarePlanHistoryData$ = this.dpAspnContextService.getNursingAndCarePlanHistoryByPatientId(this.patientId, query);
    }
}
