import angular from "angular";
import {Hooks} from "./hooks";
Hooks.getInstance().executePreScripts()

import './global'
import './app/polyfills'

import {setAngularJSGlobal} from '@angular/upgrade/static';
import {BootstrapOptions, CompilerOptions, NgModuleRef, Type} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

setAngularJSGlobal(angular);

export function bootstrap<M>(moduleType: Type<M>,
                             compilerOptions?: (CompilerOptions & BootstrapOptions) | Array<CompilerOptions & BootstrapOptions>,
                             postBootstrap?: Function) : Promise<void | NgModuleRef<M>>  {
    console.debug(`Bootstrapping ${moduleType.name}`)

    // Bootstrap using the UpgradeModule
    return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions)
        .then((ref: NgModuleRef<M>) => {
            console.debug(`${moduleType.name} bootstrapped`)
            if (postBootstrap !== undefined) {
                postBootstrap()
            }
            return ref
        })
        .then((ref: NgModuleRef<M>) => {
            // workaround to have the js executed after main one
            Hooks.getInstance().executePostScripts()
            return ref
        })
        .catch(reason => console.log(reason))
}