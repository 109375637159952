ng1App.controller('doctor.patient.cert.I.e027-1EditCtrl', 
    ['$scope', '$routeParams', '$filter', 'MessageService', 'PatientsApiFactory', 'CertificatePayment', 'EntitiesApiFactory', 'CertificateService', 'TasksApiFactory', '$q', 'CommonFunctionService',
     function ($scope, $routeParams, $filter, MessageService, PatientsApiFactory, CertificatePayment, EntitiesApiFactory, CertificateService, TasksApiFactory, $q, CommonFunctionService) {

    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.I0271.mai.inf'), 
                id:'cer_I0271_mai_inf'
            },
            {
                title: $filter('translate')('cer.I0271.doc'), 
                id:'cer_I0271_doc'
            },
            {
                title: $filter('translate')('cer.I0271.hea'), 
                id:'cer_I0271_hea'
            },
            {
                title: $filter('translate')('cer.I0271.vis'), 
                id:'cer_I0271_vis'
            },
            {
                title: $filter('translate')('cer.I0271.blo'), 
                id:'cer_I0271_blo'
            },
            {
                title: $filter('translate')('cer.I0271.bre'), 
                id:'cer_I0271_bre'
            },
            {
                title: $filter('translate')('cer.I0271.ner'), 
                id:'cer_I0271_ner'
            },
            {
                title: $filter('translate')('cer.I0271.dig'), 
                id:'cer_I0271_dig'
            },
            {
                title: $filter('translate')('cer.I0271.uro'), 
                id:'cer_I0271_uro'
            },
            {
                title: $filter('translate')('cer.I0271.end'), 
                id:'cer_I0271_end'
            },
            {
                title: $filter('translate')('cer.I0271.ske'), 
                id:'cer_I0271_ske'
            },
            {
                title: $filter('translate')('cer.I0271.lif'), 
                id:'cer_I0271_lif'
            },
            {
                title: $filter('translate')('cer.I0271.ski'), 
                id:'cer_I0271_ski'
            },
            {
                title: $filter('translate')('cer.I0271.con'), 
                id:'cer_I0271_con'
            }
        ]
    };
	
    $scope.$emit('setHelpPage', 'kurti-pazyma-e027-1-mokinio-sveikatos-pazymejimas-i-sveikatos-bkles-ivertinimas');
    
    var certificateType = $scope.certificateType;
    var certificate = $scope.certificate;
	//2020-04-14 AS modified
	//SP-05 KURTI PAŽYMĄ „E027-1 MOKINIO SVEIKATOS PAŽYMĖJIMAS: I. SVEIKATOS BŪKLĖS ĮVERTINIMAS“.
    /*var e027_1_ICount = TasksApiFactory.getActiveCount({
        patientId: $routeParams.patientId,
        orderType: '10'
    });
    var e027_1_IICount = TasksApiFactory.getActiveCount({
        patientId: $routeParams.patientId,
        orderType: '11'
    });

    $q.all([e027_1_ICount.$promise, e027_1_IICount.$promise]).then(function (data) {
        //if there is no active task for this part, but there is active task 
        //for second part of certificate
        if (data[0].value == 0 && data[1].value > 0) {
            MessageService.error($filter('translate')('err.e027_1_I.alreadyCompleted'));
        }
    });*/
    $scope.additional = {};
    	
    if (!certificate.partPhysicalCondition) {
    	certificate.partPhysicalCondition = {}
    }
	//2020-04-14 AS modified
    /*if (!certificate.hearingCondition) {
    	certificate.hearingCondition = {};
    }
    if (!certificate.circulationSystemStatus) {
    	certificate.circulationSystemStatus= {};
    }
    if (!certificate.respiratorySystemStatus) {
    	certificate.respiratorySystemStatus = {};
    }
    if (!certificate.nervousSystemStatus) {
    	certificate.nervousSystemStatus = {};
    }
    if (!certificate.gastrointestinalSystemStatus) {
    	certificate.gastrointestinalSystemStatus = {};
    }
    if (!certificate.genitourinarySystemStatus) {
    	certificate.genitourinarySystemStatus = {};
    }
    if (!certificate.genitourinarySystemStatus) {
    	certificate.genitourinarySystemStatus = {};
    }
    if (!certificate.endocrineSystemStatus) {
    	certificate.endocrineSystemStatus = {};
    }
    if (!certificate.musculoskeletalSystemStatus) {
    	certificate.musculoskeletalSystemStatus = {};
    }
    if (!certificate.bloodStatus) {
    	certificate.bloodStatus = {};
    }
    if (!certificate.skinStatus) {
    	certificate.skinStatus = {};
    }*/
    if (!certificate.physicalActivitiesParticipation) {
    	certificate.physicalActivitiesParticipation = {};
    }
    
    $scope.additional.dicharged = certificate.physicalActivitiesParticipation.dischargedFromPhysicalEducationTill != null;

	//2020-04-15 AS modified
    /*if ($routeParams.selectedDentistId) {
        $scope.certificate.selectedDentistId = $routeParams.selectedDentistId;
    }*/

    //var currentDate = new Date();
	//2020-04-15 AS modified Todo find out why selecting end date shows error
	$scope.minCreationDate = moment().subtract(3, 'months').toDate();
    $scope.maxCreationDate = moment().endOf('day').toDate();

    CertificatePayment.showPaymentMessage(certificateType);

	//2020-04-15 AS modified
    /*$scope.showBloodPressureErrors = function () {
        return ($scope.certificateForm.submitted || $scope.certificateForm.systolicBloodPressure.$dirty || $scope.certificateForm.diastolicBloodPressure.$dirty) &&
            ($scope.certificateForm.systolicBloodPressure.$invalid || $scope.certificateForm.diastolicBloodPressure.$invalid);

    };*/
	
	
	var initPartGeneralRecommendationsForm = function() {
		$scope.partGeneralRecommendationsForm = {partGeneralRecommendations:[], genOtherRecommendationText: ''};
	}

	
	$scope.validateCustom = function() {
		MessageService.clear('general-recommendations-region');
	    MessageService.clear('special-recommendations-region');
		$('[msg-region="general-recommendations-region"]').removeClass('ng-invalid');
		$('[msg-region="special-recommendations-region"]').removeClass('ng-invalid');
		var invalid = false;
		var physicalActivitiesPermittedStatus = $scope.certificate.physicalActivitiesParticipation.physicalActivitiesPermittedStatus;
        if (!physicalActivitiesPermittedStatus || physicalActivitiesPermittedStatus.code !=='2') {
			return;
		}
		if ($scope.certificate.partGeneralRecommendations.length == 0) {
			invalid = true;
			$('[msg-region="general-recommendations-region"]').addClass('ng-invalid');
			MessageService.add('error', 'Privaloma pasirinkti bent vieną bendrą rekomendaciją.', 'general-recommendations-region');
		}
		
		if ($scope.partGeneralRecommendationsForm.partGeneralRecommendations[4]
			&& $scope.certificate.partSpecialRecommendations.length == 0) {
			invalid = true;
			$('[msg-region="general-recommendations-region"]').addClass('ng-invalid');
			MessageService.add('error', 'Privaloma nurodyti bent vieną specialią rekomendaciją.', 'special-recommendations-region');
		}
		
		if (!$scope.certificateForm.$invalid && invalid) {
			$scope.certificateForm.$invalid = true;
		}
		
	}
	
    $scope.saveAndSign = function (doSign) {
		$scope.validateCustom();
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    $scope.clear = function () {
        $scope.certificate.date = (new Date()).getTime();
        $scope.certificate.birthDateAndTime = null;
        $scope.certificate.mass = null;
        $scope.certificate.babyBirthDeliveryPlace = $scope.babyBirthDeliveryPlaces[0];
        $scope.certificate.babyBirthDeliveryPlaceNotes = null;
    };

    // TODO implement
    $scope.saveDraft = function () {
        alert('Not implemented');
    };

    $scope.physicalActivitiesPermittedStatusChanged = function () {
		//2020-04-15 AS changed
		$scope.certificate.partSpecialRecommendations = [];
		$scope.certificate.partGeneralRecommendations = [];
		initPartGeneralRecommendationsForm();
    };
	
	//2020-04-15 AS changed
    /*$scope.emergencyHelpNeededChanged = function () {
        $scope.certificate.physicalActivitiesParticipation.emergencyHelpDescription = "";
    };*/

    $scope.bmiEvaluations = EntitiesApiFactory.getList({
        classCode: 'bmi-assessment'
    });
    $scope.participations = EntitiesApiFactory.getList({
        classCode: 'obs-physical-permitted-status'
    });
    $scope.physicalGroups = EntitiesApiFactory.getList({
        classCode: 'obs-physical-activities-group'
    });
    
    $scope.bmiEvaluations.$promise.then(function () {
    	CommonFunctionService.getTrueEntityValue(certificate.partPhysicalCondition, 'bmiAssessment', $scope.bmiEvaluations);
    });
    $scope.participations.$promise.then(function () {
    	CommonFunctionService.getTrueEntityValue(certificate.physicalActivitiesParticipation, 'physicalActivitiesPermittedStatus', $scope.participations);
    });
    $scope.physicalGroups.$promise.then(function () {
    	CommonFunctionService.getTrueEntityValue(certificate.physicalActivitiesParticipation, 'physicalActivitiesGroup', $scope.physicalGroups);
    });
    
    var updateBMIEvaluation = function() {
        var patientAgeInMonths = moment().diff(moment($scope.certificate.patient.birthDate), 'months');
        var bmiIndex = typeof $scope.additional.bmiIndex == 'number' ? $scope.additional.bmiIndex : 0;
        if (patientAgeInMonths < 24 || patientAgeInMonths > 300 || bmiIndex < 1 || bmiIndex > 150) {
            $scope.certificate.partPhysicalCondition.bmiAssessment = null;
            return;
        }
        PatientsApiFactory.getBMIEvaluation({gender: $scope.certificate.patient.sex.code,
                ageInMonths: patientAgeInMonths, bmi: bmiIndex
            },
            function (data) {
                $scope.certificate.partPhysicalCondition.bmiAssessment = _.find($scope.bmiEvaluations, function (item) {
                    return item.code === data.bmiEvalCode;
                });
            }
        );
    }
    
    $scope.refreshBMI = function (){
        var height = $scope.certificate.partPhysicalCondition.height;
        var weight = $scope.certificate.partPhysicalCondition.weight;
        if (weight && height && height !== 0) {
            var heighInMeters = height/100;
            $scope.additional.bmiIndex = weight / (heighInMeters * heighInMeters);
            //2020-04-15 AS modified
            //two decimal point rounding
            $scope.additional.bmiIndex = Math.round($scope.additional.bmiIndex * 100) / 100; 
       } else {
         $scope.additional.bmiIndex = null;
       }
       updateBMIEvaluation();
    };
	
    //2020-04-23 AS modified
    $scope.calcValidUntilDate = function (){	
        CertificateService.calcValidUntilDate($scope);
    };
    $scope.calcValidUntilDate();

    initPartGeneralRecommendationsForm();
	 
    //2020-04-15 AS modified
    /*$scope.showBloodPressureError = function(errorCode) {
    	var cf = $scope.certificateForm;
    	var dias  = cf.diastolicBloodPressure;
    	var sys = cf.systolicBloodPressure;

    	return dias.$error[errorCode] && (dias.$dirty || cf.submitted) ||  sys.$error[errorCode] && (sys.$dirty || cf.submitted);
    }*/
              

}]);