<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.title' | translate"
>
    <div class="card-body">
        <div class="row" *ngFor="let row of rowsData">
            <div class="py-4 bg-light fw-bold">{{row.name | translate}}</div>
            <div class="row mx-auto border-bottom border-light py-3" *ngFor="let item of row.rows">
                <div class="col-6 p-0">{{item.name | translate}}</div>
                <div class="col-6 p-0">{{item.value}}</div>
            </div>
        </div>
    </div>
</nhr-record-widget>