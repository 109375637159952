angular.module('erx.api').factory('ErxTemplatesApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Template';
  return $resource('', {}, {
    saveTemplate: {
      method: 'POST',
      url: URL_BASE
    },
    updateTemplate: {
      method: 'PUT',
      url: URL_BASE + '/:id'
    },
    search: {
      method: 'GET',
      url: URL_BASE + '/'
    },
    getById: {
      method: 'GET',
      url: URL_BASE + '/:id'
    }
    
  });
}]);


