<div class="p-2_5">
    <small class="text-secondary d-block">
        <i class="fa-regular fa-hospital"></i>
        {{ 'comp.docRefConsent.view.patientOrganization' | translate }}
    </small>
    <strong class="card-text mb-1">
        {{ organization.name }}
    </strong>
    <p class="mb-2 lh-sm">
        {{ 'jarCode' | translate }}{{ ': ' }}{{ organization.jarCode }}{{', '}}{{ 'sveidraNr' | translate }}{{ ': ' }}{{ organization.sveidraId }}
    </p>
    <p class="card-text" *ngIf="organization.phone">
        <a href="tel:{{ organization.phone }}" class="lh-1 d-flex gap-2 align-items-center d-inline-flex">
            <span class="btn btn-sm btn-square btn-square-sm btn-secondary">
                <i class="fa-regular fa-phone"></i>
            </span>
            <strong>
                {{ organization.phone }}
            </strong>
        </a>
    </p>
</div>
