import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { PregnancyTerminationModel } from "../../models/pregnancyTermination.model";
import { DocumentCaptionModel, NGNCaptionModel } from "../shared/models/shared.model";
import {
    AppliedTreatment,
    MedicalIndicationsPregnancyTermination,
    PregnancyTerminationReason,
    PsychologistConsultation,
} from "./pregnancy-termination.constants";

@Component({
    selector: "app-pregnancy-termination",
    templateUrl: "./pregnancy-termination.component.html",
})
export class PregnancyTerminationComponent {
    public formGroup: FormGroup;
    public readonly psychologistConsultationArray = Object.values(PsychologistConsultation);
    public readonly pregnancyTerminationReasonArray = Object.values(PregnancyTerminationReason);
    public readonly medicalIndicationsPregnancyTerminationArray = Object.values(MedicalIndicationsPregnancyTermination);
    public readonly appliedTreatmentArray = Object.values(AppliedTreatment);
    public readonly medicalIndicationsPregnancyTermination = MedicalIndicationsPregnancyTermination;
    public readonly pregnancyTerminationReason = PregnancyTerminationReason;
    public documentTag: DocumentTagEnum = DocumentTagEnum.e113_nna;
    prefillData: any;
    public documentCaptionData: DocumentCaptionModel;
    public ngnCaptionData: NGNCaptionModel;

    public get getPregnancyTerminationReason(): AbstractControl {
        return this.formGroup?.get("generalInfo.pregnancyTerminationReason");
    }

    public get getMedicalIndicationsPregnancyTermination(): AbstractControl {
        return this.formGroup?.get("generalInfo.medicalIndicationsPregnancyTermination");
    }

    public get getOthersPregnancyTerminationReason(): AbstractControl {
        return this.formGroup?.get("generalInfo.othersPregnancyTerminationReason");
    }

    private patientId: string;
    private isEdit: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();

        if (this.isEdit) {
            this.subscription.add(this.patchForm());
        }

        this.subscription.add(this.subscribeDocumentCaptionData());
        this.subscription.add(this.subscribeNGNCaptionData());
        this.formGroup.markAllAsTouched();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    public async saveForm(): Promise<void> {
        const payload: PregnancyTerminationModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.savePregnancyTermination(payload);

        this.router.navigate([`/dp/patients/${this.patientId}/pregnancy-dashboard-view`]);
    }

    public async submitForm(): Promise<void> {
        const payload: PregnancyTerminationModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.submitPregnancyTermination(payload);
    }

    public async signingSubmitForm(): Promise<void> {
        const payload: PregnancyTerminationModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signingSubmitPregnancyTermination(payload);
    }

    public async sealConfirmForm(): Promise<void> {
        const payload: PregnancyTerminationModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.sealConfirmPregnancyTermination(payload);
    }

    public async signForm(): Promise<void> {
        const payload: PregnancyTerminationModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signPregnancyTermination(payload);
    }

    public clearForm(): void {
        this.formGroup.reset();
    }

    public onPregnancyTerminationReasonChanges(): void {
        this.getMedicalIndicationsPregnancyTermination.reset();
        this.getOthersPregnancyTerminationReason.reset();
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            generalInfo: this._fb.group({
                psychologistConsultation: new FormControl(null, Validators.required),
                socialWorkerConsultation: new FormControl(null, Validators.required),
                pregnancyTerminationReason: new FormControl(null, Validators.required),
                medicalIndicationsPregnancyTermination: new FormControl(null, [
                    this.customMedicalIndicationsPregnancyTerminationValidator,
                ]),
                othersPregnancyTerminationReason: new FormControl(null, [
                    this.customOthersPregnancyTerminationReasonValidator,
                ]),
                appliedTreatment: new FormControl(null, Validators.required),
            }),
        });
    }

    private customMedicalIndicationsPregnancyTerminationValidator(control: AbstractControl) {
        const otherControl = control.parent?.get("pregnancyTerminationReason");

        if (
            otherControl?.value === PregnancyTerminationReason.PregnancyTerminationAccordingMedIndications &&
            !control.value
        ) {
            return { customValidation: true };
        }

        return null;
    }

    private customOthersPregnancyTerminationReasonValidator(control: AbstractControl) {
        const otherControl = control.parent?.get("medicalIndicationsPregnancyTermination");

        if (otherControl?.value === MedicalIndicationsPregnancyTermination.Other && !control.value) {
            return { customValidation: true };
        }

        return null;
    }

    private patchForm(): Subscription {
        //TODO: Change 'id' with actual id from route params
        return this._apiV2PregnancyDashboard
            .getPregnancyTermination(this.patientId)
            .subscribe((data: PregnancyTerminationModel) => {
                this.formGroup.patchValue(data);
            });
    }

    private subscribeDocumentCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getDocumentCaptionData(this.patientId).subscribe((data) => {
            this.documentCaptionData = data;
        });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).subscribe((data) => {
            this.ngnCaptionData = data;
        });
    }
}
