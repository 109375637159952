ng1App.controller('doctor.patient.F066alkViewCtrl', 
    ['$scope', '$routeParams', 'SveidraApiFactory', '$modalConfirm', '$filter', '$window', 'MessageService', 'SveidraCommons', 'SveidraF066StageCommons', 'DocumentsApiFactory', '$q', 'FormLoaderService',
     function ($scope, $routeParams, SveidraApiFactory, $modalConfirm, $filter, $window,
                                                              MessageService, SveidraCommons, SveidraF066StageCommons, DocumentsApiFactory,
                                                              $q, FormLoaderService) {
	
	FormLoaderService.startFormLoading();
	
    $scope.$emit('setHelpPage', 'medicinines-apskaitos-dokumento-perzira');
    
	$scope.goBack = function(){
        $window.history.back();
    }; 
    
    $scope.formId = $routeParams.formId;
    $scope.patientId = $routeParams.patientId;
    
	$scope.stages = SveidraApiFactory.getStageList({formId: $scope.formId});
    
    $scope.form = SveidraApiFactory.getForm({id: $scope.formId}, function (result) {
   	 	$scope.patientData = result.patient;
   	 	$scope.authorData = result.author;
   	 	$scope.organizationData = result.organization;
    });
    
    FormLoaderService.endFormLoading($q.all([$scope.form.$promise, $scope.stages.$promise]));
    
    $scope.closeCard = function () {
        $modalConfirm.show({title: $filter('translate')('inf.f066.clo.car.tit'), content: $filter('translate')('inf.f066.clo.car.tex')}).then(function () {
        	SveidraApiFactory.closeForm({id: $scope.formId}, {}, successHandler, errorHandler);
        });
    };
    
    var successHandler = function (result) {
	     // Reload form data
	     $scope.form = SveidraApiFactory.getForm({id: $scope.formId});
			
	     MessageService.showSuccessEventMessage();
	     MessageService.unregisterSuccessEvent();	
    };
    
    var errorHandler = function (result) {
		SveidraCommons.handleErrors(result, 'err.cer.066.sav');
    };
    
    $scope.closeStage = function (id, index) {
    	SveidraF066StageCommons.closeStage($scope.formId, id, function () {
    		$scope.stages[index].closed = true;
    	});
     };
	
    $scope.deleteStage = function (id, index) {
    	SveidraF066StageCommons.deleteStage($scope.formId, id, function () {
    		$scope.stages.splice(index, 1);
    	});
    };
    
    // Load related documents
    $scope.loadRelatedDocuments = function (callback) {
		 DocumentsApiFactory.searchForList({id: $scope.form.documentId}, function (result) {
			 callback(result);
		 });
    };
}]);