import {Injectable} from "@angular/core";
import {PatientModel} from "../../shared/models/patient.model";

@Injectable({
    providedIn: 'root'
})
export class DpPatientContextService {
    /** Patient id */
    public id: any = null

    public data: PatientModel = null
    public activeOrders: number = 0
    public activeConsultationPlanOrders: number = 0
    public hasActiveEncounter: boolean = false
}