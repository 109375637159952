import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { ApiV2F090 } from "src/api/api-v2-doc-ref-f090";
import { DocumentTagEnum } from "../../../models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";

@Component({
    selector: 'doc-ref-f090-view',
    templateUrl: './doc-ref-f090-form-view.component.html'
})
export class DocRefF090ViewComponent implements OnInit {

    backPath: string;
    patientId = null;
    form: FormGroup;
    emitedValues = null;
    viewData = null;
    //TODO change back to real tag
    documentTag = DocumentTagEnum.e027a;
    prefillData: any;
    isViewOnlyMode = true;
    isLoading = true;
    diagnosisArray = null;

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private fb: FormBuilder,
        private ApiV2F090: ApiV2F090,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/f090/list`;
        const id = this.params.viewId;
        this.loadf090(id);
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    loadf090(id: string) {
        return this.ApiV2F090.getf090FormView(id).subscribe((data: any) => {
            this.viewData = data;
            this.isViewOnlyMode = true;
            this.diagnosisArray = data.diagnosis;
            this.isLoading = false;
        });
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    onChanges(event) {
        this.emitedValues = event;
    }

    undoClick(): void {
        this.router.navigate([`dp/patients/${this.patientId}/f090/list`]);
    }
}