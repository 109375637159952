angular.module('erx.utils').service('$erxModalLoadDraft', ["$modal", "$rootScope", "$q", "$timeout", "ErxTemplatesApiFactory", function ($modal, $rootScope, $q, $timeout, ErxTemplatesApiFactory) {
  var scope = $rootScope.$new();
  var deferred;
  var count = 5;


  scope.submit = function () {
    deferred.resolve(scope.data.template);
    confirm.hide();
  };

  scope.isLoading = function () {
    return scope._loading;
  }

  scope.moreToLoad = function () {
    return scope.templates && (scope.templates.length < scope.templates.total);
  }

  scope.search = function () {
    scope.page = 0;
    scope.templates = [];
    scope.loadMore();
  }

  scope.loadMore = function () {
    ++scope.page;
    scope._loading = true;

    /*scope.templates = scope.templates.concat([
     {id: 1, name: 'ruos. 1', lastTimeUsed: new Date(), updated: new Date()},
     {id: 2, name: 'ruos. 2', lastTimeUsed: new Date(), updated: new Date()},
     {id: 3, name: 'ruos. 3', lastTimeUsed: new Date(), updated: new Date()}
     ]);*/
    //scope.templates.totalResults = 13;
//    $timeout(function () {
//      scope._loading = false;
//    }, 2500);

    ErxTemplatesApiFactory.search({type: scope.templateType,
      page: scope.page, count: count, sort: 'dateUpdated', query: scope.data.searchText}).$promise.then(function (res) {
      scope.templates = scope.templates.concat(res.result);
      scope.templates.total = res.total;
      scope._loading = false;
    });

  }

  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-load-draft/erxModalLoadDraft.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (opts) {
    scope.data = {};
    scope.templateType = opts.templateType;
    scope.templates = [];
    scope.page = 0;
    scope.loadMore();
    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };



  return confirm;
}]);