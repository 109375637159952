ng1App.controller('doctor.patient.VaccinesCalendarCtrl', 
    ['$scope', '$location', '$modal', '$filter', '$modalConfirm', '$routeParams', 'DpSessionContext', 'IntegratedComponentContext', 'DpPatientContext', 'PatientsApiFactory', 'OrderSelectModalHelper', 'MessageService', 'VaccinationsApiFactory', 'OrdersApiFactory', 'FormLoaderService', 'EncountersApiFactory',
     function ($scope, $location, $modal, $filter, $modalConfirm, $routeParams, DpSessionContext, IntegratedComponentContext,
                                                                   DpPatientContext, PatientsApiFactory, OrderSelectModalHelper, MessageService, VaccinationsApiFactory, OrdersApiFactory, FormLoaderService, EncountersApiFactory) {
    FormLoaderService.startFormLoading();
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope.patientId = $routeParams.patientId;
    $scope.$emit('setHelpPage', 'perzireti-skiepu-irasus-kalendoriaus-forma');

    $scope.canEdit = false;
    $scope.canAccessOrders = false;
    EncountersApiFactory.hasActiveEncounter({patientId: $scope.patientId}, function (data) {
        $scope.canEdit = data.value && $filter('acWrite')('ImmunizationSchedule');
        $scope.canAccessOrders = data.value && $filter('acWrite')('Composition', ['11369-6']);
    });

    $scope.months = [{number: 0, name: 'January'},
        {number: 1, name: 'February'},
        {number: 2, name: 'March'},
        {number: 3, name: 'April'},
        {number: 4, name: 'May'},
        {number: 5, name: 'June'},
        {number: 6, name: 'July'},
        {number: 7, name: 'August'},
        {number: 8, name: 'September'},
        {number: 9, name: 'October'},
        {number: 10, name: 'November'},
        {number: 11, name: 'December'}];
    var PAGE_SIZE = 10;
    var setupForm = function () {
        return {
            id: DpPatientContext.id,
            dateFrom: null,
            dateTo: null,
            page: 1,
            offset: 0,
            offsetField: "year",
            diseaseCodes: [],
            count: PAGE_SIZE,
            ascendingOrder: true,
            vaccineTypeQuery: undefined
        };
    };
    $scope.form = setupForm();
    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();
    $scope.plannedVaccinations = [];

    $scope.loadVaccinations = function () {
        $scope.loading = true;
        $scope.form.count = PAGE_SIZE;
        return PatientsApiFactory.getVaccinations($scope.form, function (res) {
            var vaccination = res["result"];
            for (var i = 0; i < vaccination.length; i++) {
                $scope.plannedVaccinations.push(vaccination[i]);
            }
            $scope.period = res["period"];
            $scope.year = moment($scope.period).year();
            $scope.form.page = res["currentPage"];
            $scope.loading = false;
        });
    };

    $scope.loadNext = function () {
        FormLoaderService.startFormLoading();
        $scope.plannedVaccinations = [];
        $scope.form.offset = $scope.form.offset + 1;
        var promise = $scope.loadVaccinations().$promise;
        FormLoaderService.endFormLoading(promise);
    };
    $scope.loadPrevious = function () {
        FormLoaderService.startFormLoading();
        $scope.plannedVaccinations = [];
        $scope.form.offset = $scope.form.offset - 1;
        var promise = $scope.loadVaccinations().$promise;
        FormLoaderService.endFormLoading(promise);
    };

    DpSessionContext.getSettings(function (data) {
        PAGE_SIZE = data.count_per_page || 10;
        FormLoaderService.endFormLoading($scope.loadVaccinations().$promise);
    });
    var createModal = function () {
        return $modal({
            scope: $scope,
            title: 'Planuojamo skiepo įvedimo tipas',
            contentTemplate: '/templates/portal/doctor/patient/vaccines/vaccine_type_select.html'
        });
    };

    var modal = createModal();

    $scope.selectDocumentType = function () {
        modal = createModal();
        modal.$promise.then(function () {
            modal.show();
        });
    };


    $scope.createVaccine = function (documentType) {
        if (documentType != null) {
            modal.hide();
            if (documentType == 'manual') {
                $location.path('/dp/patients/' + DpPatientContext.id + '/vaccines/new/');
            } else {
                $location.path('/dp/patients/' + DpPatientContext.id + '/vaccines/new/' + documentType);
            }
        }
    };

    $scope.mark = function (id, rec) {
        $modalConfirm.show(
                {title: $filter('translate')('inf.ale.vac.mark.tit'), content: $filter('translate')('inf.ale.vac.mark')}).then(function () {
            VaccinationsApiFactory.getMarkAsIgnored({"id": id}, null, function () {
                MessageService.success("Skiepas sėkmingai pažymėtas kaip neaktualus");
                rec.status.code = 'entered in error';
            });
        });
    };

    $scope.selectVaccineOrder = function () {
        var orders = OrdersApiFactory.getForE063({patientId: DpPatientContext.id});
        $scope.e063Modal = OrderSelectModalHelper.getModal('Skiepų skyrimai', 'Daugiau skyrimų', 'e063', 4, undefined, DpPatientContext.id, orders);

        $scope.e063Modal.$promise.then(function () {
            $scope.e063Modal.show();
        });
    };

    $scope.isIntegratedComponent = IntegratedComponentContext.isIntegratedComponent();

}]);