import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";

import { Router } from "@angular/router";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { ProceduresDuringPregnancyModel } from "../../models/proceduresDuringPregnancy.model";
import { YesNoOptions } from "../pregnancy-dashboard.constants";
import { DocumentCaptionModel, NGNCaptionModel } from "../shared/models/shared.model";
import {
    AsymptomaticBacteriuriaResults,
    BloodGroups,
    CropBGSTest,
    CropBGSTestResults,
    Fields,
    PrenatalScreeningResults,
    Procedures,
    ProceduresDateFieldTitles,
    ProceduresFieldsTitles,
    Researches,
    ResearchesDateFieldTitles,
    ResearchesFieldsTitles,
    StandardFetalSurgicalProcedures,
} from "./procedures-during-pregnancy.constants";

@Component({
    selector: "app-procedures-during-pregnancy",
    templateUrl: "./procedures-during-pregnancy.component.html",
})
export class ProceduresDuringPregnancyComponent {
    public formGroup: FormGroup;
    public researchFormGroup: FormGroup;
    public procedureFormGroup: FormGroup;
    public editableResearchIndex: number;
    public editableProcedureIndex: number;
    public currentDate = new Date();
    public YesNoOptions = YesNoOptions;
    public BloodGroups = BloodGroups.map(
        (bloodGroup) => `${bloodGroup.groupEu} (${bloodGroup.group}) ${bloodGroup.rhesus}`
    );

    public FetalLungMaturationOptions = [
        {
            name: "Atliktas",
            value: true,
        },
        {
            name: "Neatliktas",
            value: false,
        },
    ];

    public HepatitisBOptions = [
        {
            name: "Teigiamas",
            value: true,
        },
        {
            name: "Neigiamas",
            value: false,
        },
    ];

    public readonly researchesArray = Object.values(Researches);
    public readonly proceduresArray = Object.values(Procedures);
    public readonly prenatalScreeningResultsArray = Object.values(PrenatalScreeningResults);
    public readonly standardFetalSurgicalProceduresArray = Object.values(StandardFetalSurgicalProcedures);
    public readonly cropBGSTestArray = Object.values(CropBGSTest);
    public readonly asymptomaticBacteriuriaResultsArray = Object.values(AsymptomaticBacteriuriaResults);
    public readonly Researches = Researches;
    public readonly Procedures = Procedures;
    public readonly ResearchesFieldsTitles = ResearchesFieldsTitles;
    public readonly ProceduresFieldsTitles = ProceduresFieldsTitles;
    public readonly Fields = Fields;
    public readonly CropBGSTest = CropBGSTest;
    public documentTag: DocumentTagEnum = DocumentTagEnum.e113_tp;
    prefillData: any;
    public documentCaptionData: DocumentCaptionModel;
    public ngnCaptionData: NGNCaptionModel;
    public cropBGSTestInvestigatedArray = CropBGSTestResults[CropBGSTest.Investigated];
    public cropBGSTestNotInvestigatedResultArray = CropBGSTestResults[CropBGSTest.NotInvestigated];

    public get getResearchesFormArray(): FormArray {
        return this.formGroup?.get("researches") as FormArray;
    }

    public get getProceduresFormArray(): FormArray {
        return this.formGroup?.get("procedures") as FormArray;
    }

    private patientId: string;
    private isEdit: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();
        this.researchFormGroup = this._fb.group({
            name: new FormControl(null),
            date: new FormControl(null),
            pregnantBloodType: new FormControl(null),
            fatherBloodGroup: new FormControl(null),
            antibodyTestResult: new FormControl(null),
            antibodyTiter: new FormControl(null),
            antibodyTiter2: new FormControl(null),
            bloodErythrocytes: new FormControl(null),
            bloodHemoglobin: new FormControl(null),
            bloodHematocrit: new FormControl(null),
            bloodLeukocytes: new FormControl(null),
            bloodPlatelets: new FormControl(null),
            RPRTestResult: new FormControl(null),
            RPRStudyTiter: new FormControl(null),
            HIVTestResult: new FormControl(null),
            bloodPlasmaGlucoseTestResult: new FormControl(null),
            hepatitisBResult: new FormControl(null),
            asymptomaticBacteriuriaResult: new FormControl(null),
            GTMResult: new FormControl(null),
            GTMI: new FormControl(null),
            GTMII: new FormControl(null),
            GTMIII: new FormControl(null),
            cropBGSTest: new FormControl(null),
            cropBGSTestResults: new FormControl(null),
            urineProtein: new FormControl(null),
            urineLeukocytes: new FormControl(null),
            urineErythrocytes: new FormControl(null),
            urineGlucose: new FormControl(null),
            urineKetones: new FormControl(null),
            urinaryNitrites: new FormControl(null),
        });
        this.procedureFormGroup = this._fb.group({
            name: new FormControl(null),
            date: new FormControl(null),
            time: new FormControl(null),
            injectedAntiDImmunoglobulin: new FormControl(null),
            prenatalScreeningResult: new FormControl(null),
            amniocentesisDiagnosisDate: new FormControl(null),
            cordocentesisDiagnosisDate: new FormControl(null),
            standardFetalSurgicalProcedure: new FormControl(null),
            otherFetalSurgicalProcedure: new FormControl(null),
            fetalLungMaturation: new FormControl(null),
            pregnancyWeek: new FormControl(null),
            tocolysisDuringPregnancy: new FormControl(null),
            otherInfo: new FormControl(null),
        });

        if (this.isEdit) {
            this.subscription.add(this.patchForm());
        }

        this.subscription.add(this.subscribeResearchSelectChanges());
        this.subscription.add(this.subscribeProcedureSelectChanges());
        this.subscription.add(this.subscribeAntibodyTestResultChanges());
        this.subscription.add(this.subscribeResearchDateChanges());
        this.subscription.add(this.subscribeRPRTestResultChanges());
        this.subscription.add(this.subscribeGTMResultChanges());
        this.subscription.add(this.subscribeCropBGSTestChanges());
        this.subscription.add(this.subscribeFetalLungMaturationChanges());
        this.subscription.add(this.subscribeProcedureFormGroupChanges());
        this.subscription.add(this.subscribeDocumentCaptionData());
        this.subscription.add(this.subscribeNGNCaptionData());
        this.formGroup.markAllAsTouched();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    public async saveForm(): Promise<void> {
        const payload: ProceduresDuringPregnancyModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.saveResearchesAndProceduresDuringPregnancy(payload);

        this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${DocumentTagEnum.e113_tp}`]);
    }

    public async submitForm(): Promise<void> {
        const payload: ProceduresDuringPregnancyModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.submitResearchesAndProceduresDuringPregnancy(payload);
    }

    public async signingSubmitForm(): Promise<void> {
        const payload: ProceduresDuringPregnancyModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signingSubmitResearchesAndProceduresDuringPregnancy(payload);
    }

    public async sealConfirmForm(): Promise<void> {
        const payload: ProceduresDuringPregnancyModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.sealConfirmResearchesAndProceduresDuringPregnancy(payload);
    }

    public async signForm(): Promise<void> {
        const payload: ProceduresDuringPregnancyModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signResearchesAndProceduresDuringPregnancy(payload);
    }

    public clearForm(): void {
        this.getResearchesFormArray.clear();
        this.cancelAdding(true);
        this.getProceduresFormArray.clear();
        this.cancelAdding(false);
    }

    public addElementToArray(isResearches: boolean): void {
        if (isResearches) {
            const data = { ...this.researchFormGroup.value, name: this.formGroup.get("researchSelect").value };

            if (this.editableResearchIndex !== null && this.editableResearchIndex >= 0) {
                this.getResearchesFormArray.at(this.editableResearchIndex).patchValue(data);
                this.editableResearchIndex = null;
            } else {
                this.getResearchesFormArray.push(this._fb.group(data));
            }

            this.researchFormGroup.reset();
        } else {
            const data = { ...this.procedureFormGroup.value, name: this.formGroup.get("procedureSelect").value };

            if (this.editableProcedureIndex !== null && this.editableProcedureIndex >= 0) {
                this.getProceduresFormArray.at(this.editableProcedureIndex).patchValue(data);
                this.editableProcedureIndex = null;
            } else {
                this.getProceduresFormArray.push(this._fb.group(data));
            }

            this.procedureFormGroup.reset();
        }

        isResearches ? this.formGroup.get("researchSelect").reset() : this.formGroup.get("procedureSelect").reset();
    }

    public editElement(index: number, data: any, isResearches: boolean): void {
        if (isResearches) {
            this.formGroup.get("researchSelect").patchValue(data.name);
            this.researchFormGroup.patchValue(data);
            this.editableResearchIndex = index;
        } else {
            this.formGroup.get("procedureSelect").patchValue(data.name);
            this.procedureFormGroup.patchValue(data);
            this.editableProcedureIndex = index;
        }
    }

    public cancelAdding(isResearches: boolean): void {
        if (isResearches) {
            this.formGroup.get("researchSelect").reset();
            this.researchFormGroup.reset();
            this.editableResearchIndex = null;
        } else {
            this.formGroup.get("procedureSelect").reset();
            this.procedureFormGroup.reset();
            this.editableProcedureIndex = null;
        }
    }

    public disabledEditButton(index: number, isResearches: boolean): boolean {
        return isResearches ? this.editableResearchIndex === index : this.editableProcedureIndex === index;
    }

    public removeArrayElement(index: number, isResearches: boolean): void {
        if (this.editableResearchIndex === index || this.editableProcedureIndex === index) {
            this.cancelAdding(isResearches);
        }

        if (isResearches) {
            this.getResearchesFormArray.removeAt(index);

            if (this.editableResearchIndex !== index && this.editableResearchIndex > index) {
                this.editableResearchIndex = this.editableResearchIndex - 1;
            }
        } else {
            this.getProceduresFormArray.removeAt(index);

            if (this.editableProcedureIndex !== index && this.editableProcedureIndex > index) {
                this.editableProcedureIndex = this.editableProcedureIndex - 1;
            }
        }
    }

    public disableAddElement(isResearches: boolean): boolean {
        return !(isResearches ? this.researchFormGroup.valid : this.procedureFormGroup.valid);
    }

    public getFieldName(isResearches: boolean, field: Fields, fieldName: string): string {
        if (isResearches) {
            switch (field) {
                case Fields.Date:
                    return ResearchesDateFieldTitles[
                        fieldName ? fieldName : this.formGroup.get("researchSelect").value
                    ];
            }
        } else {
            switch (field) {
                case Fields.Date:
                    return ProceduresDateFieldTitles[
                        fieldName ? fieldName : this.formGroup.get("procedureSelect").value
                    ];
            }
        }
    }

    public showDateInput(isResearches: boolean): boolean {
        const proceduresWithoutDate = this.proceduresArray.filter(
            (proc) =>
                ![
                    Procedures.AntiDImmunoglobulin,
                    Procedures.PrenatalScreening,
                    Procedures.PrenatalDiagnostics,
                ].includes(proc)
        );

        const selectValue = isResearches
            ? this.formGroup.get("researchSelect").value
            : this.formGroup.get("procedureSelect").value;

        return !proceduresWithoutDate.includes(selectValue);
    }

    public getAddButtonName(isResearches: boolean): string {
        const isResearchEdit = this.editableResearchIndex !== null && this.editableResearchIndex >= 0;
        const isProcedureEdit = this.editableProcedureIndex !== null && this.editableProcedureIndex >= 0;
        const isEdit = isResearches ? isResearchEdit : isProcedureEdit;

        return isEdit ? "Koreguoti" : "+ Pridėti";
    }

    public isResearchVisibleBySelect(research: Researches, value?: string): boolean {
        return value ? value === research : this.formGroup.get("researchSelect").value === research;
    }

    public isProcedureVisibleBySelect(procedure: Procedures, value?: string): boolean {
        return value ? value === procedure : this.formGroup.get("procedureSelect").value === procedure;
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            researchSelect: new FormControl(null),
            researches: this._fb.array([]),
            procedureSelect: new FormControl(null),
            procedures: this._fb.array([]),
        });
    }

    private addRemoveValidations(isResearch: boolean, addValidation: boolean, fieldName: string): void {
        const formGroup = isResearch ? this.researchFormGroup : this.procedureFormGroup;

        if (addValidation) {
            formGroup.get(fieldName).setValidators([Validators.required]);
            formGroup.get(fieldName).updateValueAndValidity();
        } else {
            formGroup.get(fieldName).setValidators([]);
            formGroup.get(fieldName).updateValueAndValidity();
        }

        formGroup.get(fieldName).markAsTouched();
    }

    private subscribeResearchSelectChanges(): Subscription {
        return this.formGroup.get("researchSelect").valueChanges.subscribe((researchSelect) => {
            if (researchSelect) {
                const requiredDate = [
                    Researches.BloodTests,
                    Researches.Antibody,
                    Researches.Syphilis,
                    Researches.HIV,
                    Researches.Glucose,
                    Researches.HepatitisB,
                    Researches.UrineAsymptomaticBacteriuria,
                    Researches.GTM,
                ].includes(researchSelect);

                this.addRemoveValidations(true, requiredDate, "date");

                this.addRemoveValidations(true, Researches.BloodTests === researchSelect, "pregnantBloodType");
                this.addRemoveValidations(true, Researches.Antibody === researchSelect, "antibodyTestResult");
                this.addRemoveValidations(true, Researches.Syphilis === researchSelect, "RPRTestResult");
                this.addRemoveValidations(true, Researches.HIV === researchSelect, "HIVTestResult");
                this.addRemoveValidations(true, Researches.Glucose === researchSelect, "bloodPlasmaGlucoseTestResult");
                this.addRemoveValidations(true, Researches.HepatitisB === researchSelect, "hepatitisBResult");
                this.addRemoveValidations(
                    true,
                    Researches.UrineAsymptomaticBacteriuria === researchSelect,
                    "asymptomaticBacteriuriaResult"
                );
                this.addRemoveValidations(true, Researches.GBS === researchSelect, "cropBGSTest");
                this.addRemoveValidations(true, Researches.Urine === researchSelect, "urineProtein");
                this.addRemoveValidations(true, Researches.Urine === researchSelect, "urineLeukocytes");
                this.addRemoveValidations(true, Researches.Urine === researchSelect, "urineErythrocytes");
                this.addRemoveValidations(true, Researches.Urine === researchSelect, "urineGlucose");
                this.addRemoveValidations(true, Researches.Urine === researchSelect, "urineKetones");
                this.addRemoveValidations(true, Researches.Urine === researchSelect, "urinaryNitrites");
            }
        });
    }

    private subscribeProcedureSelectChanges(): Subscription {
        return this.formGroup.get("procedureSelect").valueChanges.subscribe((procedureSelect) => {
            if (procedureSelect) {
                this.addRemoveValidations(false, Procedures.AntiDImmunoglobulin === procedureSelect, "date");
                this.addRemoveValidations(false, Procedures.AntiDImmunoglobulin === procedureSelect, "time");
                this.addRemoveValidations(
                    false,
                    Procedures.AntiDImmunoglobulin === procedureSelect,
                    "injectedAntiDImmunoglobulin"
                );
            }
        });
    }

    private subscribeAntibodyTestResultChanges(): Subscription {
        return this.researchFormGroup.get("antibodyTestResult").valueChanges.subscribe((antibodyTestResult) => {
            this.addRemoveValidations(true, antibodyTestResult, "antibodyTiter");
            this.addRemoveValidations(true, antibodyTestResult, "antibodyTiter2");
        });
    }

    private subscribeResearchDateChanges(): Subscription {
        return this.researchFormGroup.get("date").valueChanges.subscribe((date) => {
            if (this.formGroup.get("researchSelect").value === Researches.BloodTest) {
                this.addRemoveValidations(true, date, "bloodErythrocytes");
                this.addRemoveValidations(true, date, "bloodHemoglobin");
                this.addRemoveValidations(true, date, "bloodHematocrit");
                this.addRemoveValidations(true, date, "bloodLeukocytes");
                this.addRemoveValidations(true, date, "bloodPlatelets");
            }
        });
    }

    private subscribeCropBGSTestChanges(): Subscription {
        return this.researchFormGroup.get("cropBGSTest").valueChanges.subscribe((cropBGSTest) => {
            this.researchFormGroup.get("cropBGSTestResults").reset();
            this.addRemoveValidations(true, cropBGSTest !== null, "cropBGSTestResults");
        });
    }

    private subscribeGTMResultChanges(): Subscription {
        return this.researchFormGroup.get("GTMResult").valueChanges.subscribe((GTMResult) => {
            this.addRemoveValidations(true, GTMResult, "GTMI");
            this.addRemoveValidations(true, GTMResult, "GTMII");
            this.addRemoveValidations(true, GTMResult, "GTMIII");
        });
    }

    private subscribeRPRTestResultChanges(): Subscription {
        return this.researchFormGroup.get("RPRTestResult").valueChanges.subscribe((RPRTestResult) => {
            this.addRemoveValidations(true, RPRTestResult, "RPRStudyTiter");
        });
    }

    private subscribeFetalLungMaturationChanges(): Subscription {
        return this.procedureFormGroup.get("fetalLungMaturation").valueChanges.subscribe((fetalLungMaturation) => {
            this.addRemoveValidations(false, fetalLungMaturation, "pregnancyWeek");
        });
    }

    private patchForm(): Subscription {
        //TODO: Change 'id' with actual id from route params
        return this._apiV2PregnancyDashboard
            .getResearchesAndProceduresDuringPregnancy(this.patientId)
            .subscribe((data: ProceduresDuringPregnancyModel) => {
                data.researches.forEach((research) => this.getResearchesFormArray.push(this._fb.group(research)));
                data.procedures.forEach((procedure) => this.getProceduresFormArray.push(this._fb.group(procedure)));
            });
    }

    private subscribeProcedureFormGroupChanges(): Subscription {
        return this.procedureFormGroup.get("time").valueChanges.subscribe((time: string) => {
            if (time) {
                const date = this.procedureFormGroup.get("date");
                const newDate = date.value ? (date.value as Date) : new Date();
                const hours = Number(time.substring(0, 2));
                const minutes = Number(time.substring(3));
                newDate.setHours(hours);
                newDate.setMinutes(minutes);

                date.patchValue(newDate);
            }
        });
    }

    private subscribeDocumentCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getDocumentCaptionData(this.patientId).subscribe((data) => {
            this.documentCaptionData = data;
        });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).subscribe((data) => {
            this.ngnCaptionData = data;
        });
    }
}
