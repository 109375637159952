angular.module('medvais.api').factory('MedvaisClassifierApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/classifiers';
    
    return $resource('', {}, {
        
        getDicomModalities: {
            method: 'GET',
            url: URL_BASE + '/dicom-modality'
        },
        
        getDoseUnits: {
            method: 'GET',
            url: URL_BASE + '/dose-unit'
        },
        
        getAchiCodes: {
            method: 'GET',
            url: URL_BASE + '/achi-code'
        },
        
        getTlk10Codes: {
            method: 'GET',
            url: URL_BASE + '/tlk-10-code'
        }
        
    });
    
}]);

