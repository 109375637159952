ng1App.controller('doctor.patient.erx.PatientHistoryViewPrsDocCtrl', 
    ['$scope', '$routeParams', 'ErxHistoryPaths', 'ErxIntegration', '$location',
     function ($scope, $routeParams, ErxHistoryPaths, ErxIntegration, $location) {

    $scope.erxPatientHistoryViewPrsDocOptions = {
        // Viewed prescription doc id
        prescriptionId: $routeParams.id,
        //returns integration object with getters for classifier values and displayGlobalMessage function
        getIntegrationData: ErxIntegration.getIntegrationDataObject,
        redirectToViewDsp: function (id, dispenseId) {
            $location.url(ErxHistoryPaths.viewDsp(dispenseId));
        }
    };

}]);