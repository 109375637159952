import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    controlIsDisabled, createDynamicChildControl,
    generateNameForChildControl,
    listenAndChangeStatusOfControl,
    multiCheckboxIsChecked,
    updateCheckboxControl,
    updateParentControlAndCreateChild
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-ptkl-actions-taken',
    templateUrl: './e096-ptkl-actions-taken.component.html',
})
export class E096PtklActionsTakenComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    maxDate = new Date();
    bloodForTestsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Bendram kraujo tyrimui (BKT)'
        },
        {
            id: '1',
            code: '1',
            name: 'Krešumui nustatyti'
        },
        {
            id: '2',
            code: '2',
            name: 'Kraujo grupei ir Rh faktoriui nustatyti'
        },
        {
            id: '3',
            code: '3',
            name: 'Sutapatinta ir rezervuota eritrocitų masė (2-4 vnt.)'
        }
    ];
    heavyBleedingRemainsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Atliktas pilvinės aortos dalies užspaudimas'
        },
        {
            id: '1',
            code: '1',
            name: 'Atliktas bimanualinis gimdos suspaudimas'
        }
    ];
    gradualDevascularizationOfUterusOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Perrištos a. uterina kylančiosios šakos (kairė ir (ar) dešinė)'
        },
        {
            id: '1',
            code: '1',
            name: 'Perrištos a. uterina kamienas (kairysis ir (ar) dešinysis)'
        },
        {
            id: '2',
            code: '2',
            name: 'Perrištos a. ovarica (kairė ir (ar) dešinė)'
        },
        {
            id: '3',
            code: '3',
            name: 'Perrištos abipus a. illiaca interna (atlieka angiochirurgas)'
        }
    ];
    amountOfMisoprostol = ['600 µg', '800 µg', '1000 µg'];

    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    generateChildControlName = generateNameForChildControl.bind(this) as typeof generateNameForChildControl;
    updateParentControl = updateParentControlAndCreateChild.bind(this) as typeof updateParentControlAndCreateChild;
    dynamicChildControl = createDynamicChildControl.bind(this) as typeof createDynamicChildControl;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            actionsTaken: this.fb.control([]),
            bloodForTests: this.fb.control({value: [], disabled: true}),
            anesthetistTeamArrived: this.fb.control(null),
            bleedingUterineAtony: this.fb.control([]),
            bleedingBirthCanal: this.fb.control([]),
            bleedingPlacentalRemnants: this.fb.control([]),
            bleedingCoagulationDisorder: this.fb.control([]),
            causeOfBloodClottingDisorder: this.fb.control(null),
            hemotransfusionOther: this.fb.control(null),
            heavyBleedingRemains: this.fb.control([]),
            transferredToOperatingRoom: this.fb.control(null),
            laparotomyStarted: this.fb.control(null),
            laparotomy: this.fb.control([]),
            gradualDevascularizationOfUterus: this.fb.control([]),
            amountOfMisoprostolSelect: this.fb.control(null)
        });

        this.changeStatusOfControl({
            controlNameForListen: 'actionsTaken',
            controlNameForUpdate: 'bloodForTests',
            enableValue: 'Paimta kraujo laboratoriniams tyrimams'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'laparotomy',
            controlNameForUpdate: 'gradualDevascularizationOfUterus',
            enableValue: 'Atlikta palaipsninė gimdos devaskuliarizacija'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }
}
