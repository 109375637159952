// Direktyva, skirta atvaizduoti bendrus paciento, daktaro bei organizacijos duomenis
angular.module('erx.prs').directive('erxGeneralInfo', function () {
  return {
    restrict: 'E',
    scope: {
      patient: '=patient',
      practitioner: '=practitioner',
      organization: '=organization',
      selectedQualification: '=selectedQualification'
    },
    replace: false,
    templateUrl: 'modules/erx/prs/general_info/general_info.html',
    controller: ["$scope", "$erxHelpers", function ($scope, $erxHelpers) {
      $scope.h = $erxHelpers;

      $scope.getCurrentDate = function () {
        return (new Date()).getTime();
      };

      $scope.loading = function () {
        return false;
      };
    }]
  };
});
