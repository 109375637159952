<div class="widget" [ngClass]="closed ? 'closed' : undefined" [class]="closable ? widgetClass + ' closable' : widgetClass">
    <ng-content select="[before-title]"></ng-content>
    <div class="widget-title" *ngIf="showTitle" [class]="titleClass" (click)="toggle()" (keypress)="toggle()" [tabindex]="closable ? 0 : -1">
        <ng-content select="[widget-title]"></ng-content>
        <div class="icon {{iconBgClass}} fs-2 lh-1" [style.display]="showIcon ? 'block' : 'none'">
            <i class="custom-icon {{iconClass}}"></i>
        </div>
        <div class="text" [class]="titleTextClass">
            <a class="float-end chevron" [ngClass]="closable ? '' : 'd-none'">
                <span class="text-body" [ngClass]="closable && closed ? 'up' : 'down'"></span>
                <span class="sr-only" translate>Collapse/Expand</span>
            </a>
            <a
                *ngIf="headerAdditionalButtonIcon || headerAdditionalButtonName"
                class="float-end text-body me-3"
                role="button"
                (click)="$event.stopPropagation(); headerAdditionalButtonClick.emit()"
                (keypress)="$event.stopPropagation(); headerAdditionalButtonClick.emit()"
                tabindex="0"
            >
                <i *ngIf="headerAdditionalButtonIcon" [class]="headerAdditionalButtonIcon"></i>
                <span [class.sr-only]="headerAdditionalButtonIcon" translate>{{headerAdditionalButtonName}}</span>
            </a>
            {{title}}
        </div>
        <div class="text" *ngIf="headerLinkText && headerLink">
            <div class="btn btn-link d-inline float-end py-0" tabindex="0" (click)="headerNavigate()"
                (keypress)="headerNavigate()">{{headerLinkText}}</div>
        </div>
    </div>
    <ng-content *ngIf="!closed" select="[after-title]"></ng-content>
    <!-- message -->
    <div *ngFor="let item of getItems(msgRegion)" class="widget-alert alert alert-dismissable"
        [ngClass]="{'alert-success': item.type === 'success', 'alert-info': item.type === 'info', 'alert-warning': item.type === 'warning', 'alert-danger': item.type === 'error', 'd-none': closed}">
        <button type="button" class="btn-close" (click)="close($event.button)" aria-label="Close"></button>
        <span [innerHTML]="item.getHtml()"></span>
    </div>

    <ng-template #widgetData>
        <ng-content />
    </ng-template>

    <ol *ngIf="list && !mixed" class="slats" [hidden]="closed">
        <ng-container *ngTemplateOutlet="widgetData" />
    </ol>

    <div class="widget-body {{bodyClass}}" *ngIf="!list && !mixed" [hidden]="closable && closed">
        <ng-container *ngTemplateOutlet="widgetData" />
    </div>

    <div class="{{bodyClass}}" *ngIf="mixed" [hidden]="closed">
        <ng-container *ngTemplateOutlet="widgetData" />
    </div>
</div>