import {ErxSupplyDisruptionTypeEnum} from "./erx-supply-disruption-type.enum";
import {PeriodModel} from "./period.model";

export interface ErxSupplyDisruptionModel {
    code: ErxSupplyDisruptionTypeEnum;
    period: PeriodModel
}

export function mapForCustomIconCell(key: string, data: ErxSupplyDisruptionModel): any {
    return {
        key: key,
        icon: getIcon(data[key].code),
        tooltip: data[key].period?.start !== undefined && data[key].period?.end !== undefined ?
            data[key].period.start + ' - ' + data[key].period.end :
            data[key].code,
    };
}

function getIcon(type: ErxSupplyDisruptionTypeEnum): string {
    const iconBase: string = "fa-solid fa-stopwatch ";
    switch (type) {
        case ErxSupplyDisruptionTypeEnum.SUPPLY_RENEWED:
            return iconBase + "text-success";
        case ErxSupplyDisruptionTypeEnum.NOT_STARTED_TO_SUPPLY:
            return iconBase + "text-secondary";
        case ErxSupplyDisruptionTypeEnum.SUPPLY_SUSPENDED:
            return iconBase +  "text-danger";
        case ErxSupplyDisruptionTypeEnum.TEMPORARY_SUPPLY_DISRUPTION:
            return iconBase + "text-warning";
        default:
            return undefined;
    }
}