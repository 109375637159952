import {DocumentTypeSelectService} from "../../../../../app/doctor/services/document-type-select.service";

ng1App.factory('DocumentTypeSelector',
    ['$modal', '$q', '$location', '$rootScope', '$filter', 'DpPatientContext', 'TypeaheadSearchHandlers', 'CertificateCreation', 'DocumentsApiFactory', 'OrderSelectModalHelper', 'LoaderHelperService', 'EncountersApiFactory',
     function ($modal, $q, $location, $rootScope, $filter, DpPatientContext, TypeaheadSearchHandlers, CertificateCreation, DocumentsApiFactory, OrderSelectModalHelper, LoaderHelperService, EncountersApiFactory) {
    var modal: any = {};
    var createModal = function (scope) {
        return $modal({
            scope: scope,
            title: 'Medicininės formos',
            contentTemplate: '/templates/portal/doctor/patient/records/modals/document_type_select.html'
        });
    };
    var modalForContinuedTreatmentE025 = function (scope) {
        return $modal({
            scope: scope,
            title: 'Ankstesnio gydymo forma',
            contentTemplate: '/templates/portal/doctor/patient/records/modals/document_select.html'
        });
    };
    var createSelectDentist = function (scope) {
        scope.searchPractitioners = TypeaheadSearchHandlers.PRACTITIONERS_ORG;
        scope.searchPractitionersChange = function (v) {
            if (v && v.id) {
                scope.selectedDentistId = v.id;
            }
        };
        return $modal({
            scope: scope,
            title: 'Odontologo pasirinkimas',
            contentTemplate: '/templates/portal/doctor/patient/records/modals/dentist_selection.html'
        });
    };

    var createSelectOrder = function (scope, documentType, options) {
        var loadE027Orders = function () {
            switch (options.orderType) {
                case '2':
                    return OrderSelectModalHelper.getModal('Laboratorinis skyrimas', 'Daugiau skyrimų', 'e027', 2, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e027Lab);
                case '4':
                    return OrderSelectModalHelper.getModal('Skiepų skyrimai', 'Daugiau skyrimų', 'e027', 4, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e027Vac);
                case '1':
                    return OrderSelectModalHelper.getModal('Konsultacijos, tyrimų, gydymo skyrimas', 'Daugiau skyrimų', 'e027', 1, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e027Obs);
            }
        };

        var selectedOrder = undefined;

        switch (documentType) {
            case 'e027':
                return loadE027Orders();
            case 'e200':
                var e200Orders = options.onlyE027 ? scope.modalInputs.data.e200From027 : scope.modalInputs.data.e200;
                return OrderSelectModalHelper.getModal('Laboratorinis skyrimas, siuntimas', 'Daugiau skyrimų, siuntimų', 'e200', 2, scope.encounter.id, DpPatientContext.id, e200Orders);
            case 'e200a':
                if (scope.modalInputs.data.e200a.length > 0 && scope.modalInputs.data.e200a[0].additional.selected) {
                    selectedOrder = scope.modalInputs.data.e200a[0];
                }
                return OrderSelectModalHelper.getModal('Laboratorinio tyrimo užsakymas', 'Daugiau skyrimų', 'e200a', 2, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e200a, selectedOrder);
            case 'e027a':
                if (scope.modalInputs.data.e027a.length > 0 && scope.modalInputs.data.e027a[0].additional.selected) {
                    selectedOrder = scope.modalInputs.data.e027a[0];
                }
                return OrderSelectModalHelper.getModal('Konsultacijos, tyrimų, gydymo siuntimas', 'Daugiau skyrimų, siuntimų', 'e027a', 1, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e027a, selectedOrder);
            case 'e014':
                return OrderSelectModalHelper.getModal('Patologijos tyrimo skyrimas', 'Daugiau skyrimų', 'e014', 3, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e014);
            case 'e014a':
                return OrderSelectModalHelper.getModal('Patologijos tyrimo užsakymas', 'Daugiau skyrimų', 'e014a', 34, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e014a);
            case 'e063':
                return OrderSelectModalHelper.getModal('Skiepų skyrimai', 'Daugiau skyrimų', 'e063', 4, scope.encounter.id, DpPatientContext.id, scope.modalInputs.data.e063);

        }
    };

    var scope = $rootScope.$new();

    scope.canCreateDoc = function () {
        return $filter('acWrite')('Composition', ['18842-5', '34108-1', '57133-1', '11488-4', 'X-LAB-ORDER', '11502-2', 'PATH-ORDER', '11526-1', '11369-6']);
    };

    scope.canCreateCer = function () {
        return $filter('acWrite')('Composition', ['444561001', '270101008', '307930005', '171387006', '274410002', '275674000', '171363009', '184822001', '270370004']);
    };

    scope.data = {};
    scope.createDocument = function () {
        var navigateToNew = function (documentType, additionalUrlParams?) {
            var urlParams = {
                encounterId: scope.encounter.id
            };

            if (urlParams) {
                urlParams = angular.extend(urlParams, additionalUrlParams);
            }

            //TODO: modal.hide(); block from opening medical document form
            modal.hide();
            $location.path('/dp/patients/' + DpPatientContext.id + '/documents/new/' + documentType).search(urlParams);
        };
        var navigateToNewCer = function (documentType, additionalUrlParams?) {
            var urlParams = {
                encounterId: scope.encounter.id
            };

            if (urlParams) {
                urlParams = angular.extend(urlParams, additionalUrlParams);
            }

            modal.hide();
            $location.path('/dp/patients/' + DpPatientContext.id + '/certificates/' + documentType + '/new').search(urlParams);

        };
        var showOrderSelect = function (documentType, options?) {
            modal.hide();
            modal = createSelectOrder(scope, documentType, options);
            scope.createDialogErr = {};
            modal.$promise.then(function () {
                modal.show();
            });
        };
        var showSelectConclusionProvider = function (documentType) {
            modal.hide();
            modal = CertificateCreation.createWithConsultationSelect(documentType, {encounterId: scope.encounter.id});
            scope.createDialogErr = {};
            modal.$promise.then(function () {
                modal.show();
            });
        };
        var processE027a = function (doSelectOrder, encounterType) {
            if (doSelectOrder || encounterType == 'ambulatory') {
                showOrderSelect('e027a');
            } else {
                navigateToNew('e027a');
            }
        };
        var processE027 = function (encounterType, orderType) {
            if ((encounterType == 'inpatient') && (orderType == '1' || orderType == '2')) {
                navigateToNew('e027', {
                    orderType: orderType
                });
            } else if (orderType) {
                showOrderSelect('e027', {
                    orderType: orderType
                });
            } else {
                scope.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas siuntimo tipas.';
            }
        };
        var processE025 = function (extendedTreatment, documentName) {
            if (extendedTreatment && !documentName) {
                var modal_scope = $rootScope.$new();
                modal.hide();
                modal = modalForContinuedTreatmentE025(modal_scope);
                modal.$promise.then(function () {
                    var page = 0;
                    modal_scope.selected = {};
                    modal_scope.patientId = DpPatientContext.id;
                    modal_scope.documents = {total: -1, items: []};
                    modal_scope.loadMore = function () {
                        DocumentsApiFactory.getE025FromFinishedEncounters({'patient': DpPatientContext.id, count: 3, page: ++page}, function (res) {
                            modal_scope.documents.total = res.total;
                            _.each(res.items, function (item) {
                                modal_scope.documents.items.push(item);
                            })
                        });
                    };
                    modal_scope.loadMore();
                    modal_scope.submit = function () {
                        navigateToNew('e025', {continueTreatment: modal_scope.selected.id});
                        modal.hide();
                    };
                    modal.show();
                });
            } else {
                navigateToNew('e025');
            }
        };
        var processE200 = function (doSelectOrder, encounterType) {
            if (doSelectOrder || encounterType == 'ambulatory') {
                showOrderSelect('e200', {
                    onlyE027: encounterType == 'inpatient'
                });
            } else {
                navigateToNew('e200');
            }
        };
        var processE200A = function (doSelectOrder, encounterType) {
            var order;

            if (encounterType == 'virtual') {
                order = scope.modalInputs.data.e200a[0];
                navigateToNew('e200a', {
                    orderId: order.item.id,
                    parentDocumentId: order.compositionId
                });
            } else if (doSelectOrder || encounterType == 'ambulatory') {
                showOrderSelect('e200a');
            } else if (encounterType == 'inpatient') {
                navigateToNew('e200a');
            }
        };
        var processE014A = function (encounterType) {
            var order;
            if (encounterType == 'ambulatory' || encounterType == 'inpatient') {
                showOrderSelect('e014a');
            } else if (encounterType == 'virtual') {
                order = scope.modalInputs.data.e014a[0];
                navigateToNew('e014a', {
                    orderId: order.item.id,
                    parentDocumentId: order.compositionId
                });
            }
        };
        var processE027_1 = function (doSelectOrder) {
            if (doSelectOrder == 'e027_1_I') {
                //Todo remove unused dentist selection modal code
                /* 2020-04-06 AS Changed
                 * 3.3	SP-04 KURTI PAŽYMĄ „E027-1 MOKINIO SVEIKATOS PAŽYMĖJIMAS“
                 * Creation of the certificate should not promt dentist selection
                 */
                navigateToNewCer('e027_1_I');
            } else if (doSelectOrder == 'e027_1_II') {
                navigateToNewCer('e027_1_II');
            } else {
                scope.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas pažymėjimo tipas';
            }
        };

        scope.createDialogErr = {};

        switch (scope.selected.documentType) {
            case 'e025':
                processE025(scope.selected.extendedTreatment, scope.selected.documentName);
                break;
            case 'e003':
                navigateToNew('e003');
                break;
            case 'e027':
                processE027(scope.encounter.event.code, scope.selected.orderType);
                break;
            case 'e027a':
                processE027a(scope.selected.e027aFromE003, scope.encounter.event.code);
                break;
            case 'e200':
                processE200(scope.selected.e200FromE003, scope.encounter.event.code);
                break;
            case 'e200a':
                processE200A(scope.selected.e200aFromE003, scope.encounter.event.code);
                break;
            case 'e014a':
                processE014A(scope.encounter.event.code);
                break;
            case 'e014':
            case 'e063':
                showOrderSelect(scope.selected.documentType, scope.selected.orderType);
                break;
            case 'e103_1':
            case 'e106':
            case 'e106_2_1':
                if (scope.selected.bornDead) {
                    navigateToNewCer(scope.selected.documentType, {
                        bornDead: true
                    });
                } else {
                    navigateToNewCer(scope.selected.documentType);
                }
                break;
            case 'e027_1':
                processE027_1(scope.selected.orderType);
                break;
            case 'e047':
            case 'e048':
            case 'e049':
            case 'e083_1':
                showSelectConclusionProvider(scope.selected.documentType);
                break;
            default:
                scope.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas dokumento tipas.';
                break;
        }
    };

    scope.submitDentist = function () {
        var documentType = scope.selected.documentType;
        scope.createDialogErr = {};
        modal.hide();
        var searchParams: any = {
            encounterId: scope.encounter.id
        };
        if (scope.selectedDentistId) {
            searchParams.selectedDentistId = scope.selectedDentistId;
        }
        $location.path('/dp/patients/' + DpPatientContext.id + '/certificates/' + documentType + '_I/new').search(searchParams);
    };

    var orderRequired = function (ctx, encounterType) {
        if (encounterType == 'inpatient') {
            return (ctx.reasons.noDocuments || ctx.reasons.allSigned) && !ctx.reasons.noOrders;
        }
        return true;
    };

    scope.onTypeChange = function () {
        switch (scope.selected.documentType) {
            case 'e027a':
                scope.selected.e027aFromE003 = orderRequired(scope.modalInputs.documentE027A, scope.encounter.event.code);
                break;
            case 'e200':
                scope.selected.e200FromE003 = orderRequired(scope.modalInputs.documentE200, scope.encounter.event.code);
                break;
            case 'e200a':
                scope.selected.e200aFromE003 = orderRequired(scope.modalInputs.documentE200a, scope.encounter.event.code);
                break;
            case 'e106_2_1':
                scope.selected.bornDead = scope.modalInputs.documentE106_2_1.onlyBornDead;
                break;
            default:
        }
    };

    scope.isDisabled = function () {
        switch (scope.selected.documentType) {
            case 'e027a':
                return orderRequired(scope.modalInputs.documentE027A, scope.encounter.event.code) || scope.modalInputs.documentE027A.reasons.noOrders;
            case 'e200':
                return orderRequired(scope.modalInputs.documentE200, scope.encounter.event.code) || scope.modalInputs.documentE200.reasons.noOrders;
            case 'e200a':
                return orderRequired(scope.modalInputs.documentE200a, scope.encounter.event.code) || scope.modalInputs.documentE200a.reasons.noOrders;
            default:
        }
        return false;
    };

    return {
        select: function (encounter) {
            scope.encounter = encounter;
            scope.selected = {orders: []};
            scope.createDialogErr = {};
            scope.documentType = null;
            scope.data.selectedType = scope.canCreateDoc() ? 'doc' : 'cer';
            LoaderHelperService.preload(function (hideLoader) {
                var certTypes = EncountersApiFactory.getAllowedCertificatesTypes({encounterId: scope.encounter.id, patientId: scope.encounter.patientData.id});
                var docTypes = EncountersApiFactory.getAllowedDocumentTypes({encounterId: scope.encounter.id, patientId: scope.encounter.patientData.id});

                $q.all([certTypes.$promise, docTypes.$promise]).then(function (data) {
                    scope.modalInputs = {};
                    scope.modalInputs = angular.extend(data[0], data[1]);
                    scope.modalInputs.data = angular.extend(data[0].data, data[1].data);

                    const modal: any = createModal(scope);
                    modal.encounterId = scope.encounter.id;
                    modal.$promise.then(function () {
                        modal.show();
                    }).finally(function () {
                        hideLoader();
                    });
                });
            });
        }
    };
}]);


ng1App.directive('selectDocumentType',
    ['DocumentTypeSelectService',
        function (DocumentTypeSelectService) {
    return {
        scope: {
            encounter: '='
        },
        link: function (scope: any, element, attrs) {
            element.on('click', function () {
                DocumentTypeSelectService.openDocumentTypeSelectModal(scope.encounter);

            });
        }
    };
}]);