ng1App.factory("ReportsApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/reports';

    return $resource('', {}, {
        getVaccines: {
            method: 'GET',
            url: URL_BASE + '/vaccines?patientId=:patientId'
        },
        getDoctorInfo: {
            method: 'GET',
            url: URL_BASE + '/doctor-info',
        },
        getPatientInfo: {
            method: 'GET',
            url: URL_BASE + '/covid-vaccinations-recoveries?personalCode=:personalCode',
            isArray: true
        },
        filterVaccinatedAndRecoveredPatients: {
            method: 'POST',
            url: URL_BASE + '/covid-vaccinations-recoveries',
            isArray: true
        },
        filterVaccinatedAndRecoveredPatientsInCsv: {
            method: 'POST',
            url: URL_BASE + '/covid-vaccinations-recoveries/csv'
        },
    });
}]);