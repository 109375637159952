angular.module('erx.prs')
        .directive('erxRelatedDispenses', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/related_docs/relatedDispenses.html',
            restrict: 'E',
            scope: {
              prsId: '=',
              redirectToViewDsp: '='
            },
            controller: ["$scope", "ErxPrescriptionsApiFactory", "gettextCatalog", function ($scope, ErxPrescriptionsApiFactory, gettextCatalog) {
              $scope.getDspStatusValue = function (prs) {
                if (!prs.dispenseData.status) {
                  return gettextCatalog.getString('JUODRAŠTIS');
                } else {
                  switch (prs.dispenseData.status.code) {
                    case 'in progress':
                      return gettextCatalog.getString('VYKDOMAS');
                    case 'entered in error':
                      return gettextCatalog.getString('ATŠAUKTAS');
                    case 'completed':
                      return gettextCatalog.getString('AKTUALUS');
                    default:
                      return 'FIX ME';
                  }
                }
              };

              $scope.getStatusColorClass = function (status) {
                switch (status) {
                  case 'stopped':
                  case 'on hold':
                  case 'entered in error':
                    return 'text-danger';
                  case 'completed':
                  case 'active':
                    return 'text-success';
                  default:
                    return '';
                }
                return "text-danger"
              }


//              <b class="{{getStatusColorClass(d.dispenseData.status.code)}}">{{getDspStatusValue(d)}}</b>

              $scope.documents = ErxPrescriptionsApiFactory.getRelated({id: $scope.prsId});
            }],
            replace: false
          };
        });