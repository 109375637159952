import moment from 'moment';

ng1App.directive('reloadForm',
    ['FormReloadService',
     function    (FormReloadService) {
    return {
        restrict: 'A',
        scope: {
            reloadForm: '='
        },
        link: function (scope, element, attr) {
            element.bind("click", function (event) {
                FormReloadService.reloadForm((scope as any).reloadForm)
            });
        }
    };
}]);

ng1App.directive('typeaheadFocus', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attr, ngModel) {
      ngModel.$setViewValue('');
      ngModel.$setPristine(); // Problem with validator "required"
    }
  };
});

ng1App.directive('nhrTypeahead', [
    '$window',
    '$parse',
    '$q',
    '$typeahead',
    '$parseOptions',
    function ($window, $parse, $q, $typeahead, $parseOptions) {
        return {
            restrict: 'EAC',
            require: 'ngModel',
            link: function postLink(scope, element, attr, controller) {
                // Directive options
                var options = jQuery.extend(true, {scope: scope}, $typeahead.defaults);
                angular.forEach([
                    'placement',
                    'container',
                    'delay',
                    'trigger',
                    'keyboard',
                    'html',
                    'animation',
                    'template',
                    'filter',
                    'limit',
                    'minLength',
                    'onSelect'
                ], function (key) {
                    if (angular.isDefined(attr[key]))
                        options[key] = attr[key];
                });
                // Build proper ngOptions
                var filter = options.filter;
                var limit = options.limit;
                var ngOptions = attr.ngOptions;
                if (filter)
                    ngOptions += ' | ' + filter + ':$viewValue';
                if (limit)
                    ngOptions += ' | limitTo:' + limit;
                var parsedOptions = $parseOptions(ngOptions);
                // Initialize typeahead
                var typeahead = $typeahead(element, controller, options);

                // Extending typeahead to allow selecting model and view values separately. ngModel is still bound to view
                var labelField = attr.labelField;
                var valueField = attr.valueField;
                var vModelSetter = $parse(attr.valueModel).assign;

                var parentScope = scope;
                typeahead.select = function (index) {
                    var value = typeahead.$scope.$matches[index].value[valueField];
                    var viewValue = typeahead.$scope.$matches[index].value[labelField];
                    if (controller) {
                        // set valueModel
                        vModelSetter(scope, value);
                        controller.$setViewValue(viewValue);
                        controller.$render();
                        if (parentScope)
                            parentScope.$digest();
                    }
                    if (options.trigger === 'focus')
                        element[0].blur();
                    else if (typeahead.$isShown)
                        typeahead.hide();
                    typeahead.$scope.$activeIndex = 0;
                    typeahead.$scope.$emit('$typeahead.select', viewValue, index);
                    if(angular.isDefined(options['onSelect'])){
                        typeahead.$scope.$emit('$typeahead.select' + options['onSelect'], viewValue, index);
                    }
                };

                // Watch model for changes
                scope.$watch(attr.ngModel, function (newValue, oldValue) {
                    // console.warn('$watch', element.attr('ng-model'), newValue);
                    (scope as any).$modelValue = newValue;
                    // clear valueModel if field is empty
                    if (newValue==""){
                        vModelSetter(scope,'');
                    }
                    // Publish modelValue on scope for custom templates
                    parsedOptions.valuesFn(scope, controller).then(function (values) {
                        if (values.length > limit)
                            values = values.slice(0, limit);
                        // Do not re-queue an update if a correct value has been selected
                        if (values.length === 1 && values[0].value === newValue)
                            return;
                        typeahead.update(values);
                        // Queue a new rendering that will leverage collection loading
                        controller.$render();
                    });
                });
                // Model rendering in view
                controller.$render = function () {
                    // console.warn('$render', element.attr('ng-model'), 'controller.$modelValue', typeof controller.$modelValue, controller.$modelValue, 'controller.$viewValue', typeof controller.$viewValue, controller.$viewValue);
                    if (controller.$isEmpty(controller.$viewValue))
                        return element.val('');
                    var index = typeahead.$getIndex(controller.$modelValue);
                    var selected = angular.isDefined(index) ? typeahead.$scope.$matches[index].label : controller.$viewValue;
                    selected = angular.isObject(selected) ? selected.label : selected;
                    element.val(selected.replace(/<(?:.|\n)*?>/gm, '').trim());
                };
                // Garbage collection
                scope.$on('$destroy', function () {
                    typeahead.destroy();
                    options = null;
                    typeahead = null;
                });
                // triggers function on-select
                if(angular.isDefined(options['onSelect'])){
                    scope.$on('$typeahead.select' + options['onSelect'], function(){
                        scope.$eval(options['onSelect']);
                    });
                }
            }
        };
    }
]);


ng1App.directive('fileButton', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            var acceptType = attributes.fileAccept || 'image/*';
            var accept = '';
            if ('all' != acceptType) {
                accept = 'accept="' + acceptType + '"';
            }
            (scope as any).addFile = true;
            element.bind("change", function (changeEvent) {
                scope.$apply(function () {
                    (scope as any).fileread = (changeEvent.target as any).files[0];
                    if (angular.isDefined(attributes['onSelect'])) {
                        scope.$eval(attributes['onSelect']);
                    }
                    (scope as any).addFile = false;
                });
            });

            var el = angular.element(element);
            var button = el.children()[0];
            var fileInput = angular.element('<input type="file" title=" " ng-file-select="" ' + accept + ' onclick="this.value=null;">');

            button.addEventListener('click', function() {
                fileInput[0].click();
            });

            fileInput.css({
                position: 'absolute',
                top: 0,
                left: 0,
                'z-index': '2',
                opacity: '0',
                cursor: 'pointer'
            });
            el.append(fileInput)
        }
    }
});

ng1App.directive('hasError', function () {
    return {
        restrict: 'A',
        scope: {
            hasError: '='
        },
        link: function (scope, element, attributes) {
            scope.$watch('hasError', function () {
                if ((scope as any).hasError) {
                    element.addClass('has-error');
                } else {
                    element.removeClass('has-error');
                }
            });
        }
    }
});

ng1App.directive('lastItem', function () {
    return {
        restrict: 'A',
        scope: {
            lastItem: '='
        },
        link: function (scope, element, attributes) {
            scope.$watch('lastItem', function () {
                if ((scope as any).lastItem) {
                    element.addClass('last');
                } else {
                    element.removeClass('last');
                }
            });
        }
    };
});
ng1App.directive('activeItem', function () {
    return {
        restrict: 'A',
        scope: {
            activeItem: '='
        },
        link: function (scope, element, attributes) {
            scope.$watch('activeItem', function () {
                if ((scope as any).activeItem) {
                    element.addClass('active');
                } else {
                    element.removeClass('active');
                }
            });
        }
    };
});

ng1App.directive("dynamicName",function($compile){
	return {
		restrict: "A",
		terminal: true,
		priority: 1000,
		link: function(scope, element, attrs) {
			var name = scope.$eval(attrs.dynamicName);
			if (!name) {
				name = '';
			}
			element.attr('name', name);
			element.removeAttr("dynamic-name");
			$compile(element)(scope);
		}
	};
});

ng1App.directive('ngEnter', function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                if(event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.ngEnter);
                    });
                    event.preventDefault();
                }
            });
        }
    }
});

//Ends preloader after finish rendering html
ng1App.directive('onFinishRender',
    ['$timeout', 'FormLoaderService',
     function ($timeout, FormLoaderService) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if ((scope as any).$last === true) {
                $timeout(function () {
					FormLoaderService.endFormLoading();
                });
            }
        }
    }
}]);

ng1App.directive('vaccinationCalendar',
    ['$location',
     function ($location) {
    return {
        templateUrl: '/templates/layout/nhr-calendar.html',
        restrict: 'E',
        replace: false,
        scope: {
            month: '=',
            year: '=',
            plannedVaccinations: '=',
            searchQuery: '=',
            patientId: '=',
            portalType : '='
        },
        transclude: true,
        link: function (scope: any, element, attrs) {
            var type = 'doctor';
            if(attrs.portalType){
                type = attrs.portalType;
            }

            scope.type = type;
            scope.currentTime = _.truncateToDays(new Date().getTime());
            scope.activateSubrow = function(row, day){
                if(scope.activeSubrow == row && scope.activeDay == day){
                    scope.activeSubrow = null;
                    scope.activeDay = null;
                }else {
                    scope.activeSubrow = row;
                    scope.activeDay = day;
                }
            };
            var momentDate = moment.utc([scope.year, scope.month]);
            var temp = momentDate.lang().week.dow;
            momentDate.lang().week.dow=1;
            var weekOffset = momentDate.week() - 1;

            var dayMap = {};
            _.each(scope.plannedVaccinations, function(item){
                var tempDate = moment(item.immunizationRecommendation.recommendations[0].plannedDate);
                if(momentDate.month() == tempDate.month()){
                    if(!dayMap[tempDate.date()]){
                        dayMap[tempDate.date()] = {list: []}
                    }
                    dayMap[tempDate.date()].list.push(item);
                }
            });
            scope.hasItems = function(date){
                if(date){
                    var tempDate = moment(date);
                    if(dayMap[tempDate.date()]){
                        return true;
                    }
                }
                return false;
            };
            scope.getItems = function(day){
                if(day){
                    if(dayMap[day]){
                        return dayMap[day].list;
                    }
                }
                return [];
            };
            var monthNotDone = true;
            scope.dateList = [];
            var lastWeek = 0;
            while (monthNotDone) {
                var weekNotDone = true;
                var currentWeek = momentDate.week() - weekOffset;
                var weekMap = {};
                var i;
                for(i=0;i<7;i++){
                    weekMap[i] = {}
                }
                while (weekNotDone) {
                    var time = moment.utc(momentDate);
                    var dayOfWeek = time.day();
                    if(dayOfWeek == 0){
                        dayOfWeek = 6;
                    } else {
                        dayOfWeek--;
                    }

                    weekMap[dayOfWeek]={day: time.date(), title: time.lang('lt').format('LL'), fullDate: time.toDate().getTime()};

                    momentDate.add(1, 'd');
                    var week = momentDate.week() - weekOffset;
                    if(scope.month != momentDate.month()){
                        if(currentWeek < 0){
                            currentWeek = lastWeek + 1;
                        }
                        monthNotDone = false;
                        weekNotDone = false;
                    } else

                    if(currentWeek != week){
                        if(currentWeek < 0){
                            currentWeek = lastWeek + 1;
                        }
                        weekNotDone = false;
                    }
                }
                scope.dateList.push({week: currentWeek, weekdays: weekMap});
                lastWeek = currentWeek;
            }
            momentDate.lang().week.dow=temp;

            scope.isToday = function(day){
               if(day != undefined){
                   if(this.currentTime == _.truncateToDays(new Date(this.year, this.month, day).getTime())){
                       return true;
                    }else{
                        return false;
                    }
               }else{
                   return false;
               }
            };
        }
    };
}]);

ng1App.directive("drawChart",
    ['$window',
     function ($window) {
        var __draw = function (scope, element, attrs) {

            var scaleMargin = function (item) {
                return item * 0.1;
                //return 1;
            };
            //init data
            var canvasElement = element.children()[0];
            canvasElement.height = 40;
            var padding = {
                left: 5,
                top: 10,
                right: 5,
                bottom: 0
            };
            var barSize = {
                width: 0,
                height: 15
            };
            var elementPadding = 0;

            var barGrayBackgroundColor = "#B0B0B0";
            var barGrayBackgroundAlpha = 0.3;

            var barColor = attrs.barColor == 'red' ? "#dd4e6e" : "#28b5ae";
            var barAlpha = 0.5;


            if(attrs.minValue === 'zero'){
                attrs.minValue = 0;
            }
            if(attrs.maxValue === 'zero'){
                attrs.maxValue = 0;
            }
            if(attrs.currentValue === 'zero'){
              attrs.currentValue = 0;
            }
            //--- available values since to
            var current = parseFloat(attrs.currentValue);

            var start = parseFloat(attrs.minValue);
            var end = parseFloat(attrs.maxValue);
            // X < Y
            if (!attrs.minValue) {
                if(current && end ){
                    if(current<end){
                        start = current - scaleMargin(current);
                    }else{
                        start = end - scaleMargin(current);
                    }
                } else if (current && !end){
                    start = current - scaleMargin(current);
                } else if (!current && end){
                    start = end - scaleMargin(current);
                } else {
                    start = 0;
                }
            }
            // normalize values
            if (current <= 0 || start <= 0) {
                if (current < start) {
                    start += current * (-1) + 1;
                    end += current * (-1) + 1;
                    current = 1;
                } else {
                    current += start * (-1) + 1;
                    end += start * (-1) + 1;
                    start = 1;
                }
            }
            // X > Y
            if (!attrs.maxValue) {
                end = start > current ? start + scaleMargin(start) : current;
            }

            // console.log('---Normalized---')
            // console.log('current ' + current)
            // console.log('start ' + start)
            // console.log('end ' + end)

            var since = start < current ? start - scaleMargin(start) : current - scaleMargin(current);
            var to = current > end ? scaleMargin(current) + current : scaleMargin(end) + end;

            // console.log('---RANGE---')
            // console.log('since ' + since)
            // console.log('to ' + to)
            var window = angular.element($window);
            //functions
            var changeWidth = function () {
                var newWidth = element[0].offsetWidth - elementPadding * 2;
                canvasElement.width = newWidth;
                barSize.width = newWidth - (padding.left + padding.right);
                redraw(barSize.width);
            };

            var redraw = function (size) {
                var ctx = canvasElement.getContext('2d');

                // set background draw style
                ctx.fillStyle = barGrayBackgroundColor;
                ctx.globalAlpha = barGrayBackgroundAlpha;

                // draw background
                ctx.fillRect(padding.left, padding.top, size, barSize.height);

                // set draw style
                ctx.fillStyle = barColor;
                ctx.globalAlpha = barAlpha;

                // draw bar
                //console.log('-------------------BAR VALUE-------------------')
                var startX = getX(size, start, to) + padding.left;
                var width = getWidth(size, start, end, to);

                if (!attrs.minValue) {
                    startX = padding.left;
                    width = getWidth(size, 0, end, to);
                }

                if (!attrs.maxValue) {
                    width = size - startX + padding.left;
                }

                // bar
                ctx.fillRect(startX, padding.top, width, barSize.height);

                ctx.strokeStyle = barColor;
                ctx.globalAlpha = 1;
                ctx.setLineDash([4, 2]);

                // left dotted line
                ctx.beginPath();
                ctx.moveTo(startX, padding.top / 2);
                ctx.lineTo(startX, barSize.height + padding.top * 1.5);
                ctx.stroke();
                // min value text
                ctx.font = "12px Arial";
                ctx.textAlign = 'center';
                ctx.fillStyle = '#B0B0B0';
                ctx.globalAlpha = 1;
                if (attrs.minValue)
                    ctx.fillText(attrs.minValue, startX, barSize.height + padding.top * 2.5);

                // right dotted line
                ctx.strokeStyle = barColor;
                ctx.beginPath();
                ctx.moveTo(startX + width, padding.top / 2);
                ctx.lineTo(startX + width, barSize.height + padding.top * 1.5);
                ctx.stroke();

                // max value text
                if (attrs.maxValue)
                    ctx.fillText(attrs.maxValue, startX + width, barSize.height + padding.top * 2.5);

                //draw current value
                //console.log('-------------------CURRENT VALUE-------------------')
                var currentX = getX(size, current, to) + padding.left;

                ctx.fillStyle = barColor;
                ctx.beginPath();
                ctx.moveTo(currentX, barSize.height + padding.top * 0.5);
                ctx.lineTo(currentX - padding.top * 0.5, barSize.height + padding.top * 1.5);
                ctx.lineTo(currentX + padding.top * 0.5, barSize.height + padding.top * 1.5);
                ctx.lineTo(currentX, barSize.height + padding.top * 0.5);
                ctx.fill();
            };

            var getX = function (recWidth, valStart, valMax) {
                // console.log('getX(' + recWidth + ', ' + valStart + ', ' + valMax + ')')
                // console.log('valStart: ' +valStart+  ' * recWidth: ' + recWidth)
                // console.log('-----------')
                // console.log('valMax: ' +valMax)
                // console.log('=')
                // console.log((valStart * recWidth) / valMax)
                // percent to px
                return (valStart * recWidth) / valMax;
            };

            var getWidth = function (recWidth, valStart, valEnd, valMax) {
                // console.log('getWidth(' + recWidth + ', ' + valStart + ', ' + valEnd +  ', ' + valMax + ')')
                // console.log('(valEnd: ' +valEnd+  ' - valStart: ' + valStart +') * recWidth: ' + recWidth )
                // console.log('-----------')
                // console.log('valMax ' +valMax)
                // console.log('=')
                // console.log(((valEnd - valStart) * recWidth) / valMax)
                // percent to px
                return ((valEnd - valStart) * recWidth) / valMax;
            };

            window.bind('resize', function () {
                changeWidth(); // when window size gets changed
            });

            changeWidth(); // when page loads

        };
    return {
        restrict: "A",
        scope: {
            maxValue: '@',
            minValue: '@',
            currentValue: '@',
            barColor: '@'
        },
        link: function(scope, element, attrs){
            var draw = function(){
                __draw(scope, element, attrs);
            };
            draw();
            scope.$watch('maxValue',draw);
            scope.$watch('minValue',draw);
            scope.$watch('currentValue',draw);
            scope.$watch('barColor',draw);
        }
    };
}]);

ng1App.directive('afterRender', ['$timeout', function ($timeout) {
    var def = {
        restrict: 'A',
        terminal: true,
        transclude: false,
        link: function (scope, element, attrs) {
            $timeout(scope.$eval(attrs.afterRender), 0);
        }
    };
    return def;
}]);

ng1App.directive('isolatedForm', function () {
    return {
        restrict: 'A',
        require: 'form',
        link: function (scope, element, attrs, ctrl) {
            if (ctrl && ctrl?.$$parentForm) {
                ctrl.$$parentForm.$removeControl(ctrl);
            }
        }
    };
});
