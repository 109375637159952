angular.module('medvais.utils').directive('medvaisDecimal', ["$timeout", function ($timeout) {

    var DECIMAL_REGEX = /^\-?\d+((\.|\,)\d+)?$/;

    return {
        restrict: "A",
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {

            ctrl.$parsers.unshift(function (viewValue) {

                if (DECIMAL_REGEX.test(viewValue)) {
                    ctrl.$setValidity('medvaisDecimal', true);
                    return viewValue.trim().replace(",", ".");
                } else if (ctrl.$isEmpty(viewValue)) {
                    ctrl.$setValidity('medvaisDecimal', true);
//                    return viewValue.trim().replace(",", ".");
                    return viewValue;
                } else {
                    ctrl.$setValidity('medvaisDecimal', false);
                    return undefined;
                }

            });

        }
    };

}]);

angular.module('medvais.utils').directive('medvaisValidDate', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (value) {
                scope.$on('errors-reset', function () {
                    ctrl.$setValidity("validDate", true);
                    return value;
                });
                if (value == undefined && elm.val() != '') {
                    ctrl.$setValidity('validDate', false);
                    return value;
                } else {
                    ctrl.$setValidity('validDate', true);
                    return value;
                }
            });
        }
    };
});

angular.module('medvais.utils').directive('medvaisValueLessOrMore', ["$filter", function ($filter) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
//            ctrl.$parsers.unshift(function (value) {
//                var type = attrs.valueLessOrMore;
//                var target = scope.$eval(attrs.targetValue);
//                if (type == 'lessThan') {
//                    if (value <= target || !target || !value) {
//                        ctrl.$setValidity('lessThan', true);
//                        return value;
//                    } else {
//                        ctrl.$setValidity('lessThan', false);
//                        return value;
//                    }
//                } else if (type == 'moreThan') {
//                    if (value >= target || !target || !value) {
//                        ctrl.$setValidity('moreThan', true);
//                        return value;
//                    } else {
//                        ctrl.$setValidity('moreThan', false);
//                        return value;
//                    }
//                }
//            });
//            ctrl.$formatters.unshift(function (value) {
//                var type = attrs.valueLessOrMore;
//                var target = scope.$eval(attrs.targetValue);
//                var dateVal = value ? Date.parse(value) : undefined;
//                var targetVal = target ? Date.parse(target) : undefined;
//                if (type == 'lessThan') {
//                    scope.$on('errors-reset', function () {
//                        ctrl.$setValidity("lessThan", true);
//                        return value;
//                    });
//                    ctrl.$setValidity("lessThan", targetVal ? dateVal <= targetVal : true);
//                    return value;
//                } else if (type == 'moreThan') {
//                    scope.$on('errors-reset', function () {
//                        ctrl.$setValidity("moreThan", true);
//                        return value;
//                    });
//                    ctrl.$setValidity("moreThan", targetVal ? dateVal >= targetVal : true);
//                    return value;
//                }
//            });
        }
    };
}]);
