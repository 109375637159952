ng1App.factory('CaptchaApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/captcha';
    return $resource('', {}, {
        getCaptcha: {
            method: 'GET',
            url: URL_BASE
        },
        isCaptchaPresent: {
            method: 'GET',
            url: URL_BASE + '/challenge'
        }
    });
}]);