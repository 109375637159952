import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ConfigService } from "../../config/config.service";
import { EntitiesQueryParamsModel } from "../models/entitiesQueryParams.model";
import { generateHttpParams } from "./utils/utils";
import { EntityModel } from "../models/entity.model";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Entities extends ConfigService {
    path = this.apiUrl + '/entities';

    getEntitiesList(typeCode: string, queryParams?: EntitiesQueryParamsModel): Observable<EntityModel[]> {
        const url = `${this.path}/${typeCode}/list`;
        let params = generateHttpParams(queryParams);
        return this.http.get<EntityModel[]>(url, {params});
    }

    getMostUsedDiagnoses(queryParams?: EntitiesQueryParamsModel): Observable<EntityModel[]> {
        const url = `${this.path}/most-used-diagnosis`;
        let params = generateHttpParams(queryParams);
        return this.http.get<EntityModel[]>(url, {params});
    }
}
