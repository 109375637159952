ng1App.service('SveidraF066StageCommons', 
    ['$modalConfirm', '$filter', 'SveidraApiFactory', 'SveidraCommons', 'MessageService',
     function ($modalConfirm, $filter, SveidraApiFactory, SveidraCommons, MessageService) {
	var success = function (callback) {
		MessageService.registerActionSuccessEvent();
		MessageService.showSuccessEventMessage();
		MessageService.unregisterSuccessEvent();
		callback();
	};
	
	var error = function (result) {
		SveidraCommons.handleErrors(result);
	};
	
	this.closeStage = function (formId, stageId, successCallback) {
    	$modalConfirm.show({title: $filter('translate')('inf.ale.f066.clo.sta.tit'), content: $filter('translate')('inf.ale.f066.clo.sta.tex')}).then(function () {
    		SveidraApiFactory.closeStage({formId: formId, id: stageId}, {}, function (result) {
    			success(successCallback);
    		}, error);
    	});
	};
	
	this.deleteStage = function (formId, stageId, successCallback) {
        $modalConfirm.show({title: $filter('translate')('inf.ale.f066.del.sta.tit'), content: $filter('translate')('inf.ale.f066.del.sta.tex')}).then(function () {
    		SveidraApiFactory.deleteStage({formId: formId, id: stageId}, function (result) {
    			success(successCallback);
    		}, error);
        });
	};
}]);