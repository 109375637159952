import {Injectable} from "@angular/core";
import {DocRefAbstractService} from "./doc-ref.abstract-service";
import moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class DocRefConsentTemplateService extends DocRefAbstractService {
    getDateByFormat(date: string, format: string): string {
        return moment(date).format(format);
    }
}