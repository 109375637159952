import {Pipe, PipeTransform} from '@angular/core';
import {EnvironmentSettings} from "../../../environments/env";
import {Environment} from "../../../environments/environment-properties";
import {PractitionerModel} from "../models/practitioner.model";
import PortalType = Environment.PortalType;

@Pipe({name: 'practitionerLink'})
export class PractitionerLinkPipe implements PipeTransform {

    private readonly portalType: PortalType;

    constructor() {
        this.portalType = EnvironmentSettings.getPortalType();
    }

    transform(practitioner: PractitionerModel): any {
        if (!practitioner) {
            return '';
        }
        switch (this.portalType) {
            case PortalType.DOCTOR:
                return '/dp/specialist/' + practitioner.id;
            case PortalType.PATIENT:
                return '/pp/doctor/' + practitioner.id;
            default:
                console.error('unknown portal type:', this.portalType);
                return 'unknown portal type';
        }
    }
}