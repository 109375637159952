ng1App.controller('document.E027aEditCtrl',
    ['$scope', 'MessageService', 'DocumentModificationActions', '$filter',
     function ($scope, MessageService, DocumentModificationActions, $filter) {
    MessageService.clearAll();
    $scope.data = {};

    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(12);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'};
    $scope.currentDiagnoses = $scope.form.partDiagnosis;

    $scope.handleValidationFailure = function(isValid) {
        $scope.diagnosisValid = isValid;
    };

    $scope.handleDiagnosesChange = function(event) {
        $scope.currentDiagnoses = event;
        $scope.form.partDiagnosis = event;
    };

    $scope._shared.customValidator = function (documentForm, skipRequired) {
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        MessageService.clear('diagnosis-region');
         if (!$scope.form.partDiagnosis || $scope.form.partDiagnosis.length == 0) {
            MessageService.add('error', 'Nenurodyta nė viena diagnozė.', 'diagnosis-region');
            if (res) {
                _.scrollTo('#diagnoses');
            }
            res = false;
        }
        return res;
    }
}]);