import _ from 'underscore';
import "underscore.string"
import * as us from "underscore.string"
import moment from 'moment'
import $ from 'jquery';

declare module 'underscore' {
    interface UnderscoreStatic {
        getFullName(p)

        getCodeName(e, separator?)
        markAllItems(state, list, visibleItemsOnly)
        dateRangeIsValid(from, to)
        hasSelectedItems(list)
        addDayStartTime(date)
        addDayEndTime(date)
        collectSelectedItems(list, modelFunction)
        concatListNames(list)

        age(from, to?): string

        agenumyears(from, to?)
        removeByIndex(list, index)
        countBedDays(from, to)
        scrollTo(jquerySelector)

        markAllAsTouched(form)
        markAllAsUntouched(form)
        scrollToFirstInvalid(jquerySelector?)
        truncateToMinutes(time)
        truncateToDays(time)
        getIdFromReference(reference)
        hasName(p)

        handleValidationErrors(data, MessageService, _resolveValidationError?)
        isJarCode(text: string): boolean
        stripTags(content: string): string
        join(str: any, ...strs: any[]): string
        _joinArray(array_arguments)

        exists(arg): boolean

        deepMerge(target, source);
    }
}
//
// _.prototype.getFullName = function (p) {
//     if (!p) {
//         return null;
//     }
//     return ((exists(p.namePrefix) && !_.isBlank(p.namePrefix)) ? p.namePrefix + ' ' : '') + (p.givenName ? p.givenName + ' ' : '') + (p.familyName ? p.familyName : '');
// }

_.mixin({
    getFullName: function (p) {
        if (!p) {
            return null;
        }
        return ((exists(p.namePrefix) && !_.isBlank(p.namePrefix)) ? p.namePrefix + ' ' : '') + (p.givenName ? p.givenName + ' ' : '') + (p.familyName ? p.familyName : '');
    },
    getCodeName: function (e, separator?) {
        if (!e) {
            return null;
        }
        return e.code + (separator ? separator : ' ') + e.name;
    },
    markAllItems: function (state, list, visibleItemsOnly) {
        _.each(list, function (item) {
            if (item.visible && visibleItemsOnly) {
                item.selected = state;
            } else if (!visibleItemsOnly) {
                item.selected = state;
            }
        });
    },
    dateRangeIsValid: function (from, to) {
        return from == undefined || to == undefined || from == '' || to == '' || from <= to;
    },
    hasSelectedItems: function (list) {
        var result = false;
        _.each(list, function (item) {
            if (item.selected) {
                result = true;
            }
        });
        return result;
    },
    addTime,
    addDayStartTime: function (date) {
        var TIME = 'T00:00:00';
        return  addTime(date, TIME);
    },
    addDayEndTime: function (date) {
        var TIME = 'T23:59:59';
        return addTime(date, TIME);
    },
    collectSelectedItems: function (list, modelFunction) {
        var selectedItems = [];
        _.each(list, function (item) {
            if (item.selected) {
                selectedItems.push(modelFunction(item));
            }
        });
        return selectedItems;
    },
    concatListNames: function (list) {
        var concatNames = null;
        _.each(list, function (item) {
            if (concatNames != null) {
                concatNames = concatNames.concat(', ');
            } else {
                concatNames = '';
            }
            concatNames = concatNames.concat(item.name ? item.name : item.display);
        });
        return concatNames;
    },
    age: function (from, to?): string {
        if (from) {
            to = to ? to : new Date().getTime();
            var fromM = Number(from) ? moment(Number(from)) : moment(from);
            var toM = Number(to) ? moment(Number(to)) : moment(to);
            var diffDays = toM.diff(fromM, 'days');
            if (diffDays <= 31) {
                return diffDays + ' d.'
            }
            var diffMonths = toM.diff(fromM, 'months');
            if (diffMonths <= 12) {
                return diffMonths + ' mėn.'
            }
            if (diffMonths <= 24) {
                return '1 m. ' + (diffMonths - 12) + ' mėn.'
            }
            return toM.diff(fromM, 'years') + ' m.';
        }
        return '';
    },
    agenumyears: function (from, to?) {
        if (from) {
            to = to ? to : new Date().getTime();
            var fromM = Number(from) ? moment(Number(from)) : moment(from);
            var toM = Number(to) ? moment(Number(to)) : moment(to);
            return toM.diff(fromM, 'years');
        }
        return '';
    },
    removeByIndex: function (list, index) {
        list.splice(index, 1);
    },
    countBedDays: function (from, to) {
        if ((from && to) && to >= from) {
            var res = moment(Number(to)).startOf('day').diff(moment(Number(from)).startOf('day'), 'days');
            return res == 0 ? 1 : res;
        }
        return null;
    },
    scrollTo: function (jquerySelector) {
        var elem = $(jquerySelector);
        if (elem && elem.offset()) {
            $("html, body").animate({
                scrollTop: elem.offset().top
            }, "fast");
        }
    },
    markAllAsTouched: function(form) {
        _.each(form, function (value, key) {
            if (value && value.$setTouched) {
                value.$setTouched();
            }
        });
    },
    markAllAsUntouched: function(form) {
        _.each(form, function (value, key) {
            if (value && value.$setUntouched && value.$setPristine) {
                value.$setPristine();
                value.$setUntouched();
            }
        });
    },
    scrollToFirstInvalid: function (jquerySelector?) {
        var offset = $('*:not(form).ng-invalid:first').first().offset();
        if (offset) {
            $("html, body").animate({
                scrollTop: offset.top - 60
            }, "fast");
        }
    },
    truncateToMinutes: function (time) {
        var d = new Date(time);
        d.setMilliseconds(0);
        d.setSeconds(0);
        return d.getTime();
    },
    truncateToDays: function (time) {
        var d = new Date(time);
        d.setMilliseconds(0);
        d.setSeconds(0);
        d.setMinutes(0);
        d.setHours(0);
        return d.getTime();
    },
    getIdFromReference: function (reference) {
        if (!reference) {
            return null;
        }
        if (reference.indexOf("/_history") > -1) {
            var str = reference.split("/_history")[0];
            return str.substring(str.lastIndexOf("/") + 1);
        }
        return reference.substring(reference.lastIndexOf("/") + 1);
    },
    hasName: function (p) {
        if (!p) {
            return false;
        }
        return !!(p.namePrefix || p.givenName || p.familyName);
    },
    handleValidationErrors: function (data, MessageService, _resolveValidationError?) {
        if (data.fieldErrors || data.globalErrors) {
            if (data.fieldErrors) {
                _.each(data.fieldErrors, function (i) {
                    console.debug(i.propertyPath + ": " + i.msg);
                    MessageService.error(i.msg);
                });
            }
            if (data.globalErrors) {
                _.each(data.globalErrors, function (i) {
                    var resolved = _resolveValidationError ? _resolveValidationError(i.msg) : i.msg;
                    MessageService.error((resolved != null ? resolved : i.msg) + (i.metadata.details? ". Techninis Aprašymas: " + i.metadata.details : '')) ;
                });
            }
        } else {
            MessageService.error("Klaida");
        }
        window.scrollTo(0, 10);
    },
    isJarCode: function (text: string): boolean {
        var txt = '' + text;
       return txt.search(/^\d{7,9}$/) === 0;
    },
    deepMerge
});

// moved from nortal-underscore
_.mixin({
    stripTags: function (content: string): string {
        return content.replace(/(<([^>]+)>)/ig, "");
    },
    exists
});

// _.mixin(_.str.exports()); // Underscore.string
_.mixin(us.exports()); // Underscore.string

_.mixin({
    empty,
    join: function (str: any, ...strs: any[]): string {
        return this._joinArray(Array.prototype.slice.call(str, strs));
    },
    _joinArray: function (array_arguments) {
        if (array_arguments.length > 3) {
            var element1 = this._join2(array_arguments[0], array_arguments[1], array_arguments[2]),
                    new_array = array_arguments.slice(3);
            new_array.unshift(element1);
            return this._joinArray(new_array);
        }
        return this._join2(array_arguments[0], array_arguments[1], array_arguments[2]);
    },
    _join2: function (value1, separator, value2) {
        var hasValue1 = !empty(value1);
        var hasValue2 = !empty(value2);

        if (hasValue1 && hasValue2) {
            return value1 + separator + value2;
        } else if (hasValue1) {
            return value1;
        }
        return value2;
    }

});


function exists(arg): boolean {
    return !_.isUndefined(arg) && !_.isNull(arg) && !_.isNaN(arg);
}

function empty(value) {
    return !exists(value) || _.trim(value) === '';
}

function addTime(date, add) {
    if (date) {
        return date.concat(add);
    }
}

function deepMerge(target, source) {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            const sourceValue = source[key];

            if (typeof sourceValue === 'object' && sourceValue !== null) {
                // Recursively merge nested objects
                if (!target[key]) {
                    // Create a new object in the target if it doesn't exist
                    target[key] = Array.isArray(sourceValue) ? [] : {};
                }
                deepMerge(target[key], sourceValue); // Recursively merge
            } else {
                // Copy the value from source to target if it's not an object
                target[key] = sourceValue;
            }
        }
    }
    return target;
}
