<div class="collapse multi-collapse" id="advancedFilter">
    <div class="custom-search">
        <div class="custom-filter p-4">
            <div class="form-horizontal">
                <ng-container *ngFor="let option of options">
                    <ng-container [ngSwitch]="option.type">
                        <div *ngSwitchCase="'date-range'" class="row mb-2">
                            <div class="col-md-4 col-xl-3">
                                <strong class="col-form-label">{{ option.title || "pp.dia.sea.dat" | translate }}</strong>
                            </div>
                            <div class="col-md-8 col-xl-9">
                                <div class="row">
                                    <app-date-picker
                                        [fControl]="getFormControl('fromDate')"
                                        inputId="fromDateId"
                                        [placeholder]="'pp.dia.sea.dat.from' | translate"
                                        suffixIcon="fa fa-calendar"
                                        class="col"
                                    ></app-date-picker>
                                    <app-date-picker
                                        [fControl]="getFormControl('toDate')"
                                        inputId="toDateId"
                                        [placeholder]="'pp.dia.sea.dat.to' | translate"
                                        suffixIcon="fa fa-calendar"
                                        class="col"
                                    ></app-date-picker>
                                </div>
                            </div>
                        </div>
    
                        <div *ngSwitchCase="'multi-select'" class="row mb-2">
                            <div class="col-md-4 col-xl-3">
                                <strong class="col-form-label">{{ option.title | translate }}</strong>
                            </div>
                            <div class="col-md-8 col-xl-9">
                                <div class="row">
                                    <!-- [itemsShowLimit]="option.multiSelectSettings?.itemsShowLimit" -->
                                    <app-multiselect-dropdown
                                        [fControl]="getFormControl(option.formControlName)"
                                        class="d-block"
                                        [placeholder]="option?.placeholder | translate"
                                        [dropdownDataList]="option.multiSelectOptions"
                                        [singleSelection]="!!option.multiSelectSettings?.singleSelection"
                                        [idField]="option.multiSelectSettings?.idField ?? 'id'"
                                        [textField]="option.multiSelectSettings?.textField ?? 'name'"
                                        [allowSearchFilter]="!!option.multiSelectSettings?.allowSearchFilter"
                                    ></app-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
    
                        <div *ngSwitchCase="'input'" class="row mb-2">
                            <div class="col-md-4 col-xl-3">
                                <strong class="col-form-label">{{ option.title | translate }}</strong>
                            </div>
                            <div class="col-md-8 col-xl-9">
                                <div class="row">
                                    <app-input
                                        [type]="option?.format ? option?.format : 'text'"
                                        class="d-block"
                                        [fControl]="getFormControl(option.formControlName)"
                                        [placeholder]="option?.placeholder | translate"
                                    ></app-input>
                                </div>
                            </div>
                        </div>
    
                        <div *ngSwitchCase="'date'" class="row mb-2">
                            <div class="col-md-4 col-xl-3">
                                <strong class="col-form-label">{{ option.title || "pp.dia.sea.dat" | translate }}</strong>
                            </div>
                            <div class="col-md-8 col-xl-9">
                                <div class="row">
                                    <app-date-picker
                                        [fControl]="getFormControl(option.formControlName)"
                                        inputId="{{ 'dateId-' + option.formControlName }}"
                                        [placeholder]="'data' | translate"
                                        suffixIcon="fa fa-calendar"
                                    ></app-date-picker>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'typeahead'" class="row mb-2">
                            <div class="col-md-4 col-xl-3">
                                <strong class="col-form-label">{{ option.title  || "" | translate }}</strong>
                            </div>
                            <div class="col-md-8 col-xl-9">
                                <div class="row">
                                    <div class="input-group">
                                        <input
                                            [id]="option.formControlName"
                                            [formControl]="option.typeaheadSettings.typeaheadformControl"
                                            [typeaheadOptionField]="option.typeaheadSettings.inputTextItem"
                                            [typeahead]="option.typeaheadSettings.suggestionsService"
                                            [typeaheadAsync]="true"
                                            (typeaheadOnSelect)="typeaheadOnSelect($event, option.formControlName)"
                                            (typeaheadNoResults)="typeaheadNoResults($event, option.formControlName)"
                                            (typeaheadOnBlur)="
                                                onBlurTypeahead(
                                                    $event,
                                                    option.formControlName,
                                                    option.typeaheadSettings.typeaheadformControl
                                                )
                                            "
                                            [placeholder]="option.placeholder | translate"
                                            class="form-control"
                                        />
                                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'checkbox'" class="row mb-2">
                            <div class="col-md-4 col-xl-3">
                                <strong class="col-form-label">{{ option.title  || "" | translate }}</strong>
                            </div>
                            <div class="col-md-8 col-xl-9">
                                <div class="row">
                                    <app-checkbox
                                        [inputId]="option.formControlName"
                                        [fControl]="getFormControl(option.formControlName)"
                                    ></app-checkbox>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="d-flex flex-row gap-3 justify-content-end pt-2">
                <button class="btn btn-outline-primary" (click)="clearFilters()">
                    {{ "pp.dia.sea.clearFilt" | translate }}
                </button>
                <button class="btn btn-primary" (click)="applyFilters()">{{ "pp.dia.sea.srch" | translate }}</button>
            </div>
        </div>
    </div>
    <hr class="my-0 border-bottom-0 border-dark-subtle" />
</div>
