<nhr-record-widget [title]="title | translate" class="mt-4 d-block" titleClass="pe-4" titleTextClass="fs-2 lh-lg ps-2" [closable]="true" bodyClass="p-0">
    <div *ngFor="let row of rows" class="row d-flex align-items-baseline py-2 border-bottom mx-0" [class.req]="isControlRequired(row)">
        <ng-container *ngIf="!isTemplateRef(row); else customRow">
            <div class="col-3 py-2">{{row.title}}</div>
            <div class="col-9 d-flex row align-items-start align-self-center">
                <ng-container *ngIf="row.type === 'choise'">
                    <app-radio-button
                            *ngFor="let option of row.options; let i = index"
                            [radioName]="generateRadioName(row, i)"
                            [fControl]="row?.formControl"
                            [radioValue]="option.id"
                            [id]="generateRadioName(row, i)"
                            class="col-3 my-2"
                            formClass="mb-0"
                            [labelText]="option.name"
                            [class]="row.customClass"
                            [radioDisabled]="row.formControl.disabled"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="row?.formControl" />
                    <ng-container *ngIf="row?.formControl?.value === row?.otherValueId">
                        <app-input
                                [fControl]="row?.otherFormControl"
                                [showErrors]="true"
                                class="align-self-center {{row.customClass}}"
                        ></app-input>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="row.type === 'text'">
                    <app-input
                            class="align-self-center {{row.customClass}}"
                            [fControl]="row.formControl"
                            [type]="row.textType ?? 'text'"
                            [showErrors]="true"
                            [class.w-auto]="row.textInputLabel"
                    ></app-input>
                    <span *ngIf="row.textInputLabel" class="w-auto align-self-center">{{row.textInputLabel}}</span>
                </ng-container>
                <ng-container *ngIf="row.type === 'text-select'">
                    <app-input
                            [fControl]="row.formControl"
                            [type]="row.textType ?? 'text'"
                            [showErrors]="true"
                            class="w-auto align-self-center {{row.customClass}}"
                    ></app-input>
                    <app-select
                            class="w-auto align-self-center"
                            [id]="row.title + 'SelectId'"
                            [fControl]="row.otherFormControl"
                            [showErrors]="true"
                            [values]="generateSelectionValuesFromEntity(row.options)"
                    ></app-select>
                </ng-container>
                <ng-container *ngIf="row.type === 'date'">
                    <app-date-picker
                            [fControl]="row.formControl"
                            [inputId]="row.title + 'Datepicker'"
                            [showErrors]="true"
                            suffixIcon="fa fa-calendar"
                            class="w-auto align-self-center {{row.customClass}}"
                    ></app-date-picker>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #customRow>
            <ng-container *ngTemplateOutlet="$any(row); context: { data: contextData }"></ng-container>
        </ng-template>
    </div>
</nhr-record-widget>
