import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'search-modal',
    templateUrl: './search-modal.component.html'
})
export class SearchModalComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();
    @Input() form: FormGroup;

    constructor() {
    }

    ngOnInit(): void {

    }

    searchAdvanced() {
        const query: Object = this.form.touched ? this.form.value : undefined;
        this.onSearch.emit(query);
    }

    clear() {
        this.form.reset();
    }
}