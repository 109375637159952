import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import { isAfter } from 'date-fns';
import { defineLocale, ltLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
defineLocale('lt', ltLocale);

@Component({
    selector: "app-date-picker",
    templateUrl: "./date-picker.component.html",
})
export class DatePickerComponent implements OnInit, OnChanges {
    @Input({required: true}) fControl: AbstractControl;
    @Input() placeholder: string = "";
    @Input() dateInputFormat?: string = "YYYY-MM-DD";
    @Input() required?: boolean;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() dateClass?: string;
    @Input() suffixClass?: string;
    @Input() inputGroupClass?: string;
    @Input() labelText: string;
    @Input() labelClass: string;
    @Input() errorClass: string;
    @Input({ required: true }) inputId: string;
    @Input() withTimepicker?: boolean;
    @Input() showErrors?: boolean;
    @Input() minMode?: 'day' | 'month' | 'year';
  @Input() set viewMode(state: boolean) {
    if (state) {
      this.fControl.disable();
    }
  };

    @Input() suffixIcon: string;
    @Output() datePickerChange = new EventEmitter();

    constructor(private localeService: BsLocaleService) {}

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    ngOnChanges() {}

    ngOnInit(): void {
        this.localeService.use("lt");
        this.dpConfig.withTimepicker = this.withTimepicker;
        this.dpConfig.dateInputFormat = this.dateInputFormat;
        this.dpConfig.showWeekNumbers = false;
        this.dpConfig.customTodayClass = "cur";
        this.minDate && (this.dpConfig.minDate = this.minDate);
        this.maxDate && (this.dpConfig.maxDate = this.maxDate);
        this.fControl.valueChanges.subscribe((x) => {
            if (x && Object.prototype.toString.call(x) === "[object Date]") {
                if (isNaN(x.getTime())) {
                    this.fControl.setValue(undefined);
                } else {
                    this.minDate &&
                        isAfter(this.minDate, x) &&
                        this.fControl.setValue(this.minDate, { onlySelf: true, emitEvent: false });
                    this.maxDate &&
                        isAfter(x, this.maxDate) &&
                        this.fControl.setValue(this.maxDate, { onlySelf: true, emitEvent: false });
                }
            } else if (x) {
                this.fControl.setValue(undefined);
            }
        });
    }

    onValueChange(e) {
        this.datePickerChange.emit(e);
    }
    onInputKeypress(e) {
        console.log('we are key pressing', e)
    }
}

