ng1App.factory("EptsCertificatesApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    
    var URL_BASE = apiUrl + '/certificates/epts';
    return $resource('', {}, {
        registerHcpConclusion: {
            method: 'POST',
            url: URL_BASE + '/hcpConclusion'
        },
        getNewCertificateNumber: {
            method: 'GET',
            url: URL_BASE + '/certificateNumber?organizationCode=:organizationCode&certificateType=:certificateType'
        },
        registerIncapacityCertificate: {
            method: 'POST',
            url: URL_BASE + '/incapacity'
        },
        getIncapacityCertificates: {
            method: 'GET',
			url: URL_BASE + '?type=NEDP',
            isArray: true
        },
        getIncapacityCertificateHistory: {
            method: 'GET',
			url: URL_BASE + '/incapacity/history?personalCode=:personalCode&birthDate=:birthDate&organizationCode=:organizationCode'
        },
        getInsuranceInformation: {
            method: 'GET',
            url: URL_BASE + '/insurance?personalCode=:personalCode&organizationCode=:organizationCode'
        },
        getPregnancyVacationCertificates: {
            method: 'GET',
			url: URL_BASE + '?type=NESP',
            isArray: true
        },
        registerPregnancyVacationCertificate: {
            method: 'POST',
            url: URL_BASE + '/pregnancyVacation'
        },
        getCertificates: {
            method: 'GET',
            url: URL_BASE + '?personalCode=:personalCode&birthDate=:birthDate&organizationCode=:organizationCode&number=:number',
            isArray: true
        }
    });
}]);