<ng-container *ngIf="!readOnly; else readOnlyBlock">
    <div class="form-check" [class]="formClass">
        <input
            *ngIf="fControl"
            type="checkbox"
            class="form-check-input"
            checked="{{ checkboxChecked ? 'checked' : null }}"
            [id]="inputId + uniqId"
            [formControl]="fControl"
            [class]="checkboxClass"
            [attr.name]="checkboxName"
            [attr.value]="checkboxValue"
            [attr.disabled]="fControl.disabled || checkboxDisabled || null"
            [title]="checkboxTitle"
            [attr.checked]="checkboxChecked || null"
            [ngClass]="{ 'is-invalid': (fControl?.invalid && fControl.touched) }"
            (change)="checkboxChange.emit($event)"
            (click)="checkboxClick.emit($event)"
        />
        <input
            *ngIf="!fControl"
            type="checkbox"
            size="5"
            class="form-check-input"
            checked="{{ checkboxChecked ? 'checked' : null }}"
            [id]="inputId + uniqId"
            [class]="checkboxClass"
            [title]="checkboxTitle"
            [attr.name]="checkboxName"
            [attr.value]="checkboxValue"
            [attr.data-value]="checkboxDataValue"
            [attr.disabled]="checkboxDisabled || null"
            [ngClass]="{ 'is-invalid': required }"
            (change)="checkboxChange.emit($event)"
            (click)="checkboxClick.emit($event)"
        />
        <label [for]="inputId + uniqId" [class]="labelClass" class="form-check-label">{{ labelText | translate }}</label>
    </div>
</ng-container>
<ng-template #readOnlyBlock>
    <div [ngClass]="labelClass">
        {{ labelText }}
    </div>
</ng-template>
