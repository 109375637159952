import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    E096NtConditionDuringHospitalizationComponent
} from './e096-nt-sub-forms/e096-nt-condition-during-hospitalization/e096-nt-condition-during-hospitalization.component';
import {
    E096NtExaminationPregnantWomanComponent
} from './e096-nt-sub-forms/e096-nt-examination-pregnant-woman/e096-nt-examination-pregnant-woman.component';
import {
    E096NtResearchPlannedComponent
} from './e096-nt-sub-forms/e096-nt-research-planned/e096-nt-research-planned.component';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, forkJoin, switchMap, take } from 'rxjs';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { E096NtAppointmentsComponent } from './e096-nt-sub-forms/e096-nt-appointments/e096-nt-appointments.component';
import {
    E096NtMaternityCarePlanComponent
} from './e096-nt-sub-forms/e096-nt-maternity-care-plan/e096-nt-maternity-care-plan.component';
import { E096MedicalRecord } from '../../../../models/e096-medicalRecord.model';
import { formatDateForDatePickers } from '../../e096-utils/utils';

@Component({
    selector: 'app-e096-nt-form',
    templateUrl: './e096-nt-form.component.html',
})
export class E096NtFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: E096MedicalRecord) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);

        this.patchFormWithInitialData(this._initialFormValue);
    }

    @ViewChild(E096NtConditionDuringHospitalizationComponent) conditionComponent: E096NtConditionDuringHospitalizationComponent;
    @ViewChild(E096NtExaminationPregnantWomanComponent) examinationComponent: E096NtExaminationPregnantWomanComponent;
    @ViewChild(E096NtResearchPlannedComponent) researchComponent: E096NtResearchPlannedComponent;
    @ViewChild(E096NtAppointmentsComponent) appointmentsComponent: E096NtAppointmentsComponent;
    @ViewChild(E096NtMaternityCarePlanComponent) maternityCareComponent: E096NtMaternityCarePlanComponent;

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: E096MedicalRecord;
    private dateFields: string[] = ['assessmentsDate', 'regularContractionsStart', 'investigationDate', 'amnioticFluidDate'];

    get fruitLength() {
        return this.conditionComponent?.fruitLength;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities
    ) {
        forkJoin({
            genitalSecretionsStatus: this.apiV2Entities.getEntitiesList('genital-secretions-status'),
            fetalMovements: this.apiV2Entities.getEntitiesList('fetal-movements'),
            fetalPrimordial: this.apiV2Entities.getEntitiesList('fetal-primordial'),
            fetalPosition: this.apiV2Entities.getEntitiesList('fetal-position'),
            fetalPositionSide: this.apiV2Entities.getEntitiesList('fetal-position-side'),
            fetalFluidStatus: this.apiV2Entities.getEntitiesList('fetal-fluid-status'),
            vaginalExaminationMethod: this.apiV2Entities.getEntitiesList('vaginal-examination-method'),
            locationOfTheCervix: this.apiV2Entities.getEntitiesList('location-of-the-cervix'),
            consistencyOfTheCervix: this.apiV2Entities.getEntitiesList('consistency-of-the-cervix'),
            cervixMaturity: this.apiV2Entities.getEntitiesList('cervix-maturity'),
            childbirthPeriod: this.apiV2Entities.getEntitiesList('childbirth-period'),
            fetalCoverings: this.apiV2Entities.getEntitiesList('fetal-coverings'),
            fetalFluidColor: this.apiV2Entities.getEntitiesList('fetal-fluid-color'),
            planningResearch: this.apiV2Entities.getEntitiesList('planning-research'),
            inpatientDiet: this.apiV2Entities.getEntitiesList('inpatient-diet'),
            planningChildbirth: this.apiV2Entities.getEntitiesList('planning-childbirth'),
            modeOfDelivery: this.apiV2Entities.getEntitiesList('mode-of-delivery'),
            repeatOperation: this.apiV2Entities.getEntitiesList('repeat-operation'),
            dystocia: this.apiV2Entities.getEntitiesList('dystocia'),
            fetusConditionIssues: this.apiV2Entities.getEntitiesList('fetus-condition-issues'),
            fetusBreechPresentation: this.apiV2Entities.getEntitiesList('fetus-breech-presentation'),
            otherIndications: this.apiV2Entities.getEntitiesList('other-indications')
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            conditionDuringHospitalization: this.conditionComponent.formGroup,
            examinationPregnantWomen: this.examinationComponent.formGroup,
            researchIsPlanned: this.researchComponent.formGroup,
            appointments: this.appointmentsComponent.formGroup,
            maternityCarePlan: this.maternityCareComponent.formGroup
        });
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }


    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.formGroup.patchValue(this._initialFormValue);
    }

    private patchFormWithInitialData(_initialFormValue: E096MedicalRecord) {
        this.formGroup.valueChanges.pipe(
            debounceTime(100),
            take(1),
            switchMap(() => {
                this.formGroup.patchValue(this._initialFormValue);
                return this.formGroup.valueChanges;
            }),
            debounceTime(100),
            take(1)
        ).subscribe(() => {
            this.formGroup.get('conditionDuringHospitalization.assessmentsOfFetalCondition').patchValue(
                this._initialFormValue.conditionDuringHospitalization.assessmentsOfFetalCondition);
            this.formGroup.get('examinationPregnantWomen').patchValue(
                this._initialFormValue.examinationPregnantWomen
            );
            if (!this.editable) {
                this.formGroup.disable();
            }
        });
    }
}
