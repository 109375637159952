import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {forkJoin} from "rxjs";
import {ApiV2Entities} from "../../../services/api-v2-entities";
import {EntityModel} from "../../../models/entity.model";
import {DispenseListFilterModel} from "../../../models/dispense-list-filter.model";

@Component({
    selector: 'dispense-list-filter',
    templateUrl: './dispense-list-filter.component.html'
})
export class DispenseListFilterComponent {
    @Output() filtering: EventEmitter<DispenseListFilterModel> = new EventEmitter<DispenseListFilterModel>();
    types: EntityModel[] = [];

    formGroup: FormGroup = this.fb.group({
        fromDate: this.fb.control(null),
        toDate: this.fb.control(null),
        isUsing: this.fb.control(null),
        myDispenses: this.fb.control(null),
        abroad: this.fb.control(null),
        withoutRecipe: this.fb.control(null),
        id: this.fb.control(null),
        type: this.fb.control(null),
        prescribed: this.fb.control(null),
    })

    constructor(
        private readonly fb: FormBuilder,
        private apiV2Entities: ApiV2Entities
    ) {
        forkJoin([
            this.apiV2Entities.getEntitiesList('medication-prescription-type'),
        ])
            .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.types = res[0]?.map(t => {
                    t.id = t.id ? t.id : t.code;
                    t.name = t.name ? t.name : t.displayValue;
                    return t;
                });
            });
    }

    clearFilters(): void {
        this.formGroup.reset();
        this.filtering.emit(this.formGroup.value);
    }

    applyFilters(): void {
        this.filtering.emit(this.formGroup.value);
    }
}
