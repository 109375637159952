import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { FilterModel } from "../../../shared/models/filter.model";
import { PregnancyFilterNames } from "./pregnancy-dashboard.constants";

@Component({
    selector: "app-doc-ref-consent",
    templateUrl: "./pregnancy-dashboard.component.html",
})
export class PregnancyDashboardComponent implements OnInit, OnDestroy {
    public pregnancyFilterNames = PregnancyFilterNames;
    public filters = [
        { title: this.pregnancyFilterNames.PregnancyData.toString(), query: {}, count: 1 },
        { title: this.pregnancyFilterNames.AmbulatoryDescription.toString(), query: {}, count: 1 },
        { title: this.pregnancyFilterNames.RiskFactors.toString(), query: {}, count: 1 },
        { title: this.pregnancyFilterNames.UltrasoundFetalExamination.toString(), query: {}, count: 1 },
        { title: this.pregnancyFilterNames.ExaminationsProceduresDuringPregnancy.toString(), query: {}, count: 1 },
        { title: this.pregnancyFilterNames.AbortationDescription.toString(), query: {}, count: 1 },
        { title: this.pregnancyFilterNames.FirstNewbornCheckup.toString(), query: {}, count: 1 },
    ];

    public currentForm = this.filters[0];
    private subscription: Subscription = new Subscription();

    constructor(private readonly _fb: FormBuilder) {}

    ngOnInit(): void {
        //will be used in future
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public changeForm(filter?: FilterModel) {
        this.currentForm = filter;
    }
}
