ng1App.factory('ConditionsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/conditions';
    return $resource('', {}, {
        getConditions: {
            method: 'GET',
            url: URL_BASE+'/fromDocs',
            isArray: true
        },
        find: {
            method: 'GET',
            url: URL_BASE,
            isArray: true
        },
        findAllByPatient: {
            method: 'GET',
            url: URL_BASE + '/allByPatient/:patientId',
            isArray: true
        }
    });

}]);