import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { ApiV2Patients } from "../../../shared/services/api-v2-patients";
import { ApiV2Templates } from "../../../../api/api-v2-templates";
import { DocumentTypeEnum } from "../../../shared/models/document-type.enum";
import { PatientModel } from "../../../shared/models/patient.model";
import { DocumentTagEnum } from "../../../shared/models/document-tag.enum";
import { TemplateModel } from "../../models/template.model";
import { CertificateModalComponent } from "../certificateModal/certificate-modal.component";
import { FinishedDocumentSelectComponent } from "../finishedDocumentSelect/finished-document-select.component";
import { OrderSelectComponent } from "../orderTypeSelect/order-select.component";

@Component({
    selector: "document-type-select",
    templateUrl: "document-type-select.component.html",
})
export class DocumentTypeSelectComponent implements OnInit {
    protected readonly Boolean = Boolean;
    protected readonly DocumentTagEnum = DocumentTagEnum;
    @Input() modalId: string;
    modalCloseId: string;
    @Input() allowedTypes: any;
    @Input() allowedCerts: any;
    @Input() patient: PatientModel;
    @Input() encounter: any;
    @Input() loading: boolean;
    createDialogErr: { msg: string } = { msg: null };
    activeTabId: string = "medDocsPanel";
    form: FormGroup;
    selectedDocName: string = "selectedDoc";
    selectedDocTypeName: string = "selectedDocType";
    selectedAdditionalDocName: string = "selectedAdditionalDocName";
    templates: TemplateModel[];
    templatesCount: number = 10;
    templatesTotal: number;
    selectedTemplate: TemplateModel;
    @ViewChild(OrderSelectComponent) orderSelectComponent: OrderSelectComponent;
    @ViewChild(CertificateModalComponent) certificateModalComponent: CertificateModalComponent;
    @ViewChild(FinishedDocumentSelectComponent) finishedDocumentSelectComponent: FinishedDocumentSelectComponent;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private apiV2Templates: ApiV2Templates,
        private apiV2Patients: ApiV2Patients
    ) {
        this.form = this.formBuilder.group({});
    }

    //45806050092

    //48308191363
    ngOnInit(): void {
        this.modalCloseId = this.modalId + "Close";
        this.form.addControl(this.selectedDocName, new FormControl(null));
        this.form.addControl(this.selectedDocTypeName, new FormControl(null));
        this.form.addControl(this.selectedAdditionalDocName, new FormControl(null));
    }

    onTabClick(activeTabId: string) {
        this.activeTabId = activeTabId;
        if (activeTabId === "templatesPanel") {
            this.apiV2Templates.findForList({ count: this.templatesCount }).subscribe((data) => {
                this.templates = data.items;
                this.templatesTotal = data.total;
            });
        }
    }

    /*TODO: after AccessControlHelper upgrade, these filters should be used.*/

    /*canCreateDoc() {
        return $filter('acWrite')('Composition', ['18842-5', '34108-1', '57133-1', '11488-4', 'X-LAB-ORDER', '11502-2', 'PATH-ORDER', '11526-1', '11369-6']);
    };

    canCreateCer() {
        return $filter('acWrite')('Composition', ['444561001', '270101008', '307930005', '171387006', '274410002', '275674000', '171363009', '184822001', '270370004']);
    };*/

    onSelectDoc() {
        this.form.controls[this.selectedDocTypeName].setValue(null);
        this.form.controls[this.selectedAdditionalDocName].setValue(null);
    }

    async newDocument(documentType: DocumentTypeEnum, additionalParams?: Object, newForm?: boolean) {
        const url: string = await this.buildUrlByDocumentType(documentType, additionalParams);
        const params: Object = { encounterId: this.encounter.id };
        const query: Object = additionalParams ? { ...params, ...additionalParams } : params;
        this.router.navigate([url], { queryParams: query });
        // if(!newForm){
        //     setTimeout(() => {
        //         window.location.reload();
        //     }, 500);
        // }
        this.closeModal();

    }

    async buildUrlByDocumentType(documentType: DocumentTypeEnum, params: Object) {
        const selectedDoc = this.form.controls[this.selectedDocName].value;
        const selectedDocType = this.form.controls[this.selectedDocTypeName].value;
        const urlDocument = selectedDocType && !params ? selectedDocType : selectedDoc;

        switch (documentType) {
            case DocumentTypeEnum.DOCUMENT:
                return `/dp/patients/${this.patient.id}/documents/new/${urlDocument}`;
            case DocumentTypeEnum.CERTIFICATE:
                return `/dp/patients/${this.patient.id}/certificates/${urlDocument}/new`;
            case DocumentTypeEnum.ASPN:
                return `/dp/patients/${this.patient.id}/aspn/${urlDocument}`;
            case DocumentTypeEnum.NGN:
                return `/dp/patients/${this.patient.id}/ngn/${urlDocument}`;
            case DocumentTypeEnum.RECIPES:
                return `/dp/patients/${this.patient.id}/erx/prescriptions/new`;
            case DocumentTypeEnum.TEMPLATE:
                return `/dp/patients/${this.patient.id}/documents/new/${this.selectedTemplate.type}`;
            case DocumentTypeEnum.UNSORTED:
                return `/dp/patients/${this.patient.id}/doc-unsorted/new`;
            case DocumentTypeEnum.FORM:
                return `/dp/patients/${this.patient.id}/${urlDocument}/new`;
            default:
                break;
        }
    }

    closeModal() {
        const parentClose = document.getElementById(this.modalCloseId);
        parentClose.click();
    }

    orderRequired(documentInput: any, encounterType: string): boolean {
        if (encounterType == "inpatient") {
            return (
                (documentInput.reasons.noDocuments || documentInput.reasons.allSigned) &&
                !documentInput.reasons.noOrders
            );
        }
        return true;
    }

    onTypeChange() {
        this.onSelectDoc();
        switch (this.form.controls[this.selectedDocName].value) {
            case DocumentTagEnum.e027a:
                if (this.orderRequired(this.allowedTypes.documentE027A, this.allowedTypes.encounterType)) {
                    this.form.controls[this.selectedAdditionalDocName].setValue(DocumentTagEnum.e027aFromE003);
                }
                break;
            case DocumentTagEnum.e200:
                if (this.orderRequired(this.allowedTypes.documentE200, this.allowedTypes.encounterType)) {
                    this.form.controls[this.selectedAdditionalDocName].setValue(DocumentTagEnum.e200FromE003);
                }
                break;
            case DocumentTagEnum.e200a:
                if (this.orderRequired(this.allowedTypes.documentE200a, this.allowedTypes.encounterType)) {
                    this.form.controls[this.selectedAdditionalDocName].setValue(DocumentTagEnum.e200aFromE003);
                }
                break;
            case DocumentTagEnum.e106_2_1:
                if (this.allowedCerts.documentE106_2_1.onlyBornDead) {
                    this.form.controls[this.selectedAdditionalDocName].setValue(DocumentTagEnum.bornDead);
                }
                break;
            default:
                break;
        }
    }

    isDisabled() {
        switch (this.form.controls[this.selectedDocName].value) {
            case DocumentTagEnum.e027a:
                return (
                    this.orderRequired(this.allowedTypes.documentE027A, this.allowedTypes.encounterType) ||
                    this.allowedTypes.documentE027A.reasons.noOrders
                );
            case DocumentTagEnum.e200:
                return (
                    this.orderRequired(this.allowedTypes.documentE200, this.allowedTypes.encounterType) ||
                    this.allowedTypes.documentE200.reasons.noOrders
                );
            case DocumentTagEnum.e200a:
                return (
                    this.orderRequired(this.allowedTypes.documentE200a, this.allowedTypes.encounterType) ||
                    this.allowedTypes.documentE200a.reasons.noOrders
                );
            default:
                break;
        }
        return false;
    }

    confirmSelection() {
        switch (this.form.controls[this.selectedDocName].value) {
            case DocumentTagEnum.e025:
                this.processE025();
                break;
            case DocumentTagEnum.e003:
                this.newDocument(DocumentTypeEnum.DOCUMENT);
                break;
            case DocumentTagEnum.e027:
                this.processE027();
                break;
            case DocumentTagEnum.e027a:
                this.processE027a();
                break;
            case DocumentTagEnum.e200:
                this.processE200();
                break;
            case DocumentTagEnum.e200a:
                this.processE200A();
                break;
            case DocumentTagEnum.e014a:
                this.processE014A();
                break;
            case DocumentTagEnum.e014:
            case DocumentTagEnum.e063:
                //this.createSelectOrder();
                this.newDocument(DocumentTypeEnum.DOCUMENT);
                break;
            case DocumentTagEnum.e103_1:
            case DocumentTagEnum.e106:
            case DocumentTagEnum.e106_2_1:
                this.form.controls[this.selectedDocTypeName].value
                    ? this.newDocument(DocumentTypeEnum.CERTIFICATE, { bornDead: true })
                    : this.newDocument(DocumentTypeEnum.CERTIFICATE);
                break;
            case DocumentTagEnum.e027_1:
                this.processE027_1();
                break;
            case DocumentTagEnum.e047:
            case DocumentTagEnum.e048:
            case DocumentTagEnum.e049:
            case DocumentTagEnum.e083_1:
                this.createCertificate({ encounterId: this.encounter.id });
                break;
            case DocumentTagEnum.e113_nd:
            case DocumentTagEnum.e113_ug:
            case DocumentTagEnum.e113_tp:
            case DocumentTagEnum.e113_rv:
            case DocumentTagEnum.e097_npa:
            case DocumentTagEnum.e097_ts:
            case DocumentTagEnum.e097_at:
            case DocumentTagEnum.e097_gd:
            case DocumentTagEnum.e096_nt:
            case DocumentTagEnum.e096_ptkl:
            case DocumentTagEnum.e096_n:
            case DocumentTagEnum.e096_ge:
            case DocumentTagEnum.e096_na:
            case DocumentTagEnum.e096_cpo:
            case DocumentTagEnum.e096_part:
            case DocumentTagEnum.e113_ap:
            case DocumentTagEnum.e113_nna:
                this.newDocument(DocumentTypeEnum.NGN);
                break;
            case DocumentTagEnum.template:
                this.newDocument(DocumentTypeEnum.TEMPLATE, {
                    loadFromTemplate: true,
                    templateId: this.selectedTemplate?.id,
                    docType: this.selectedTemplate?.type,
                });
                break;
            case DocumentTagEnum.spu:
            case DocumentTagEnum.spnk:
            case DocumentTagEnum.sbvf:
            case DocumentTagEnum.vnapvf:
            case DocumentTagEnum.spp:
                this.newDocument(DocumentTypeEnum.ASPN);
                break;
            case DocumentTagEnum.vps:
                this.newDocument(DocumentTypeEnum.RECIPES, { prescriptionType: "va" });
                break;
            case DocumentTagEnum.mpp:
                this.newDocument(DocumentTypeEnum.RECIPES, { prescriptionType: DocumentTagEnum.mpp });
                break;
            case DocumentTagEnum.mp:
                this.newDocument(DocumentTypeEnum.RECIPES, {prescriptionType: "mpa"});
                break;
            case DocumentTagEnum.evs:
                this.newDocument(DocumentTypeEnum.RECIPES, { prescriptionType: "ev" });
                break;
            case DocumentTagEnum.unsorted:
                this.newDocument(DocumentTypeEnum.UNSORTED);
                break;
            case DocumentTagEnum.e110:
            case DocumentTagEnum.f025:
            case DocumentTagEnum.f058:
            case DocumentTagEnum.f090:
                this.newDocument(DocumentTypeEnum.FORM,{}, true);
                break;
            default:
                //  TODO: error messages via component
                this.createDialogErr.msg = "Dokumento pildyti negalima, nes nepasirinktas dokumentas.";
                break;
        }
    }

    processE025() {
        if (this.form.controls[this.selectedDocTypeName].value) {
            this.finishedDocumentSelectComponent.openModal();
        } else {
            this.newDocument(DocumentTypeEnum.DOCUMENT);
        }
    }

    processE027() {
        if (this.allowedTypes.encounterType == "ambulatory") {
            this.newDocument(DocumentTypeEnum.DOCUMENT);
        }
    }

    processE027a() {
        this.newDocument(DocumentTypeEnum.DOCUMENT);
    }

    processE200() {
        const selectedAdditionalDoc: string = this.form.controls[this.selectedAdditionalDocName].value;
        if (selectedAdditionalDoc || this.allowedTypes.encounterType == "ambulatory") {
            this.createSelectOrder({ onlyE027: this.allowedTypes.encounterType == "inpatient" });
        } else {
            this.newDocument(DocumentTypeEnum.DOCUMENT);
        }
    }

    processE200A() {
        const selectedAdditionalDoc: string = this.form.controls[this.selectedAdditionalDocName].value;
        if (this.allowedTypes.encounterType == "virtual") {
            const order = this.allowedTypes.data.e200a?.[0];
            this.newDocument(DocumentTypeEnum.DOCUMENT, {
                orderId: order.item.id,
                parentDocumentId: order.compositionId,
            });
        } else if (selectedAdditionalDoc || this.allowedTypes.encounterType == "ambulatory") {
            this.createSelectOrder();
        } else if (this.allowedTypes.encounterType == "inpatient") {
            this.newDocument(DocumentTypeEnum.DOCUMENT);
        }
    }

    processE014A() {
        if (this.allowedTypes.encounterType == "ambulatory" || this.allowedTypes.encounterType == "inpatient") {
            this.createSelectOrder();
        } else if (this.allowedTypes.encounterType == "virtual") {
            const order = this.allowedTypes.data.e014a?.[0];
            this.newDocument(DocumentTypeEnum.DOCUMENT, {
                orderId: order.item.id,
                parentDocumentId: order.compositionId,
            });
        }
    }

    processE027_1() {
        const selectedDocType: string = this.form.controls[this.selectedDocTypeName].value;
        if (selectedDocType == DocumentTagEnum.e027_1_I) {
            //Todo remove unused dentist selection modal code
            /* 2020-04-06 AS Changed
             * 3.3	SP-04 KURTI PAŽYMĄ „E027-1 MOKINIO SVEIKATOS PAŽYMĖJIMAS“
             * Creation of the certificate should not promt dentist selection
             */
            this.newDocument(DocumentTypeEnum.CERTIFICATE);
        } else if (selectedDocType == DocumentTagEnum.e027_1_II) {
            this.newDocument(DocumentTypeEnum.CERTIFICATE);
        } else {
            //  TODO: error messages via component
            this.createDialogErr.msg = "Dokumento pildyti negalima, nes nepasirinktas pažymėjimo tipas";
        }
    }

    createSelectOrder(options?) {
        let selectedOrder = undefined;
        switch (this.form.controls[this.selectedDocName].value) {
            case DocumentTagEnum.e200:
                const e200Orders = options.onlyE027 ? this.allowedTypes.data.e200From027 : this.allowedTypes.data.e200;
                return this.orderSelectComponent.openModal(
                    "Laboratorinis skyrimas, siuntimas",
                    "Daugiau skyrimų, siuntimų",
                    DocumentTagEnum.e200,
                    2,
                    this.encounter.id,
                    this.patient.id,
                    e200Orders
                );
            case DocumentTagEnum.e200a:
                if (this.allowedTypes.data.e200a.length > 0 && this.allowedTypes.data.e200a[0].additional.selected) {
                    selectedOrder = this.allowedTypes.data.e200a[0];
                }
                return this.orderSelectComponent.openModal(
                    "Laboratorinio tyrimo užsakymas",
                    "Daugiau skyrimų",
                    DocumentTagEnum.e200a,
                    2,
                    this.encounter.id,
                    this.patient.id,
                    this.allowedTypes.data.e200a,
                    selectedOrder
                );
            case DocumentTagEnum.e027a:
                if (this.allowedTypes.data.e027a.length > 0 && this.allowedTypes.data.e027a[0].additional.selected) {
                    selectedOrder = this.allowedTypes.data.e027a[0];
                }
                return this.orderSelectComponent.openModal(
                    "Konsultacijos, tyrimų, gydymo siuntimas",
                    "Daugiau skyrimų, siuntimų",
                    DocumentTagEnum.e027a,
                    1,
                    this.encounter.id,
                    this.patient.id,
                    this.allowedTypes.data.e027a,
                    selectedOrder
                );
            case DocumentTagEnum.e014:
                return this.orderSelectComponent.openModal(
                    "Patologijos tyrimo skyrimas",
                    "Daugiau skyrimų",
                    DocumentTagEnum.e014,
                    3,
                    this.encounter.id,
                    this.patient.id,
                    this.allowedTypes.data.e014
                );
            case DocumentTagEnum.e014a:
                return this.orderSelectComponent.openModal(
                    "Patologijos tyrimo užsakymas",
                    "Daugiau skyrimų",
                    DocumentTagEnum.e014a,
                    34,
                    this.encounter.id,
                    this.patient.id,
                    this.allowedTypes.data.e014a
                );
            case DocumentTagEnum.e063:
                return this.orderSelectComponent.openModal(
                    "Skiepų skyrimai",
                    "Daugiau skyrimų",
                    DocumentTagEnum.e063,
                    4,
                    this.encounter.id,
                    this.patient.id,
                    this.allowedTypes.data.e063
                );
        }
    }

    createCertificate(additionalParams?: Object) {
        this.certificateModalComponent.openModal(this.form.controls[this.selectedDocName].value, additionalParams);
    }

    receiveSelectedTemplate(template: TemplateModel) {
        this.form.controls[this.selectedDocName].setValue(DocumentTagEnum.template);
        this.selectedTemplate = template;
    }
}
