ng1App.directive('erxStaticFormGroup', function () {
    return {
        templateUrl: '/templates/components/erx/static-form-group/erxStaticFormGroup.tpl.html',
        restrict: 'E',
        replace: true,
        terminal: true,
        priority: 100000,
        scope: {value: '=', label: '@', mandatory: '@'},
        transclude: true
    }
});