import {Component, Inject} from "@angular/core";
import {UpgradeModule} from "@angular/upgrade/static";

@Component({
    selector: 'patient-test-comp',
    template: `
    <button type="button" (click)="clicked()">Click me!</button>
    <span>{{message}}</span>`
})
export class DemoComponent {
    isOn = false;

    constructor(private upgrade: UpgradeModule) {
    }
    clicked() { this.isOn = !this.isOn; }
    get message() {
        // let router = this.upgrade.injector.get('$rootScope');
        return `The light is ${this.isOn ? 'On' : 'Off'}`;
    }
}