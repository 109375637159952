<div class="row d-flex req">
    <fieldset class="col-md-6 col-lg-4 mb-3" *ngFor="let question of questions">
        <legend class="float-none mb-2 pt-0" [class.col-form-label]="isRequiredControl(question.id)">{{question.question}}</legend>
        <ng-container *ngFor="let answer of question.answers; let i = index">
            <app-radio-button
                id="{{question.id}}-ans{{i}}"
                radioName="{{question.id}}-ans{{i}}"
                labelText='{{answer.answer}}'
                formClass="mb-1"
                [radioValue]="answer.answer"
                [fControl]="formGroup.get(question.id)"
            />
        </ng-container>
    </fieldset>
</div>