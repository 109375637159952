ng1App.factory('WarRefugeeApiFactory', 
    ['$resource', '$routeParams', 'apiUrl',
     function ($resource, $routeParams, apiUrl) {

    var URL_BASE = apiUrl + '/warRefugee';
    return $resource('', {}, {
        isWarRefugeeExist: {
            method: 'GET',
            url: URL_BASE + '/:id/isExist'
        }
    });
}]);