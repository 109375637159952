import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "visit-cancel-note-dialog",
    templateUrl: "./visitCancelNoteDialog.component.html",
})
export class VisitCancelNoteDialog implements OnInit {

    @Output() cancelVisit: EventEmitter<any> = new EventEmitter();

    visitId?: number;
    periodVisitId?: number;

    visitFormG?: FormGroup;

    constructor(
        public modalService: BsModalService,
    ) { }

    ngOnInit(): void { }

    action(id: number) {
        this.cancelVisit.emit(id);
    }
}
