import {
    AfterViewInit,
    Component,
    EventEmitter,
    ViewChild
} from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import * as moment from "moment/moment";
import { DiagnosisModel } from "../../../models/diagnosis.model";
import { ColDef } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { ApiV2Diagnosis } from "../../../../shared/services/api-v2-diagnosis";
import { IconComponent } from "../../../../shared/components/icon/icon.component";
import { TitleCasePipe } from "@angular/common";
import { ApiV2Entities } from "../../../../shared/services/api-v2-entities";
import {
    MultiselectDropdownComponent
} from '../../../../shared/components/multiselect-dropdown/multiselect-dropdown.component';
import {RouteParams} from "../../../../ng1.routeParams";

@Component({
  selector: 'app-diagnoses-list-modal',
  templateUrl: './diagnoses-list-modal.component.html',
  providers: [TitleCasePipe]
})
export class DiagnosesListModalComponent implements AfterViewInit {
    @ViewChild(AgGridAngular) agGrid: AgGridAngular;
    @ViewChild(MultiselectDropdownComponent) dropdownComponent: MultiselectDropdownComponent;

    onClose: EventEmitter<DiagnosisModel[]> = new EventEmitter<DiagnosisModel[]>();
    allDiagnoses: Array<DiagnosisModel> = [];
    filteredDiagnoses: Array<DiagnosisModel> = [];
    dropdownActiveFilter = ['Active', 'Inactive', 'Cancelled'];
    dropdownStatusFilter: string[] = [];

    colDefs: ColDef[] = [
        {
            field: "selected",
            headerName: '',
            headerClass: 'no-header-cell-comp-wrapper-important justify-content-center',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            showDisabledCheckboxes: true,
        },
        {
            field: "active",
            headerName: "",
            cellRenderer: IconComponent,
            cellRendererParams: {
                icon: 'fa fa-circle fs-4',
            },
            valueGetter: (params) => {
                return params.data.active === 'active' ? 'text-success'
                    : params.data.active === 'inactive' ? 'text-danger'
                        : '';
            },
            type: 'center',
        },
        {
            filter: 'agTextColumnFilter',
            headerName: 'Kodas',
            field: 'code',
            flex: 2
        },
        {
            filter: 'agTextColumnFilter',
            headerName: 'Pavadinimas',
            field: 'name',
            flex: 3
        },
        {
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (chosedDate, cellValue) => {
                    const cellDate = moment(cellValue, 'YYYY-MM-DD').utc();
                    const filterDate = moment(chosedDate).utc();
                    return cellDate.isSame(filterDate) ? 0 : -1;
                },
                defaultOption: 'equals'
            },
            headerName: 'Reg. data',
            valueGetter: (params) => (moment(params.data.onsetDate).format('YYYY-MM-DD')),
            flex: 3
        },
        {
            headerName: 'Kitos reg. datos',
            valueGetter: (params) => (params.data.dates?.map(date => moment(date).format('YYYY-MM-DD'))),
            flex: 3
        },
        {
            field: 'active',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: this.dropdownActiveFilter
            },
            headerClass: 'ag-grid-select-filter-white-important',
            flex: 2
        },
        {
            colId: 'status',
            headerName: 'Būsena',
            filter: 'agSetColumnFilter',
            valueGetter: (params) => (params.data.diagnosisStatus.name),
            flex: 3
        },
    ];
    defaultColDef: ColDef = {
        cellStyle: {
            textAlign: 'center'
        },
        autoHeight: true,
        suppressMenu: true,
        floatingFilter: true,
        flex: 1
    };

    constructor(
        readonly bsModalRef: BsModalRef,
        private readonly apiV2Diagnosis: ApiV2Diagnosis,
        private readonly apiV2Entities: ApiV2Entities,
        private params: RouteParams
    ) {
        this.apiV2Diagnosis.getDiagnosisList(this.params.patientId).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.allDiagnoses = res;
                this.filteredDiagnoses = [...this.allDiagnoses];
            });
        this.apiV2Entities.getEntitiesList('eHDSI_StatusCode').pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.dropdownStatusFilter = res.map(item => (_.capitalize(item.name)));
                const col = this.agGrid.columnApi.getColumn('status');
                col.setColDef( {
                        ...col.getColDef(),
                        filterParams: {
                            ...col.getColDef().filterParams,
                            values: this.dropdownStatusFilter
                        }
                    },
                    col.getUserProvidedColDef()
                );
                this.agGrid.api.refreshHeader();
            });
    }

    ngAfterViewInit() {
        this.agGrid?.api?.sizeColumnsToFit();
    }

    close(success?: boolean) {
        this.bsModalRef.hide();
        if (success) {
            this.onClose.emit(this.agGrid.api.getSelectedRows());
        }
    }
}
