ng1App.service('DpMessagesUtil', 
    ['$filter',
     function($filter){
	return {
		messageStatusDisplay: function(message){
			if(message.isSender){
				return message.status === 'READ' ? $filter('translate')('mess.sta.sen.vie') : $filter('translate')('mess.sta.sen');
			}else{
				return message.isResponded ? $filter('translate')('mess.sta.rec.ans') : $filter('translate')('mess.sta.rec');
			}
		},
		messageTitle: function(message){
			if(!message || !message.alert) return '';
			var title = '';
			if(message.isSender){
				if(message.alert.receiver){
					title = this.practitionerDisplay(message.alert.receiver);
				}else{
					title = this.patientDisplay(message.alert.subject);
				}
			}else{
				if(message.alert.author){
					title = this.authorDisplay(message.alert.author);
				}else{
					title =  $filter('translate')('mess.author.system')
				}
			}
			return title;
		},
        isPractitioner: function(person){
            if(person){
                return person.fullId.lastIndexOf('Practitioner', 0) === 0;
            }else{
                return false;
            }
        },
        isPatient: function(person){
            if(person){
                return person.fullId.lastIndexOf('Patient', 0) === 0;
            }else{
                return false;
            }
        },
        hasRelatedSubject: function(message){
            return message.alert && message.alert.receiver && this.isPractitioner(message.alert.author);
        },
        hasPatientSubject: function(message){
            return message.alert && message.alert.subject && this.isPatient(message.alert.subject);
        },
		authorDisplay: function(person){
			if(person){
				return this.isPractitioner(person) ? this.practitionerDisplay(person) : this.patientDisplay(person);
			}else{
				return $filter('translate')('mess.author.system');
			}
		},
		practitionerDisplay: function(practitioner){
			return (practitioner.speciality && practitioner.speciality.name ? practitioner.speciality.name + ' ':'') + $filter('capitalize')(_.getFullName(practitioner));
		},
		patientDisplay: function(patient){
			if(patient){
				return $filter('capitalize')(_.getFullName(patient))+ ', ESI Nr. ' + patient.esiNr + (patient.birthDate?', '+$filter('date')(patient.birthDate, 'yyyy-MM-dd'):'');
			}
		},
		receiverDisplay: function(receiver){
			return receiver.spe ? this.practitionerDisplay(receiver.spe) : this.patientDisplay(receiver.ptn);
		}
	};
}]);