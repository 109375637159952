/* global app */

/**
 * @deprecated since migration to angular.
 * Now using search-button-group.component.ts downgraded component.
 */
ng1App.directive('_searchButton', function () {
    return {
        templateUrl: '/templates/components/preloader/search-button.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '=',
            title: '@',
            workingTitle: '@',
            buttonType: '@'
        }
    };
});

ng1App.directive('spinnerButton', function () {
    return {
        templateUrl: '/templates/components/preloader/spinner-button.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '=',
            title: '@',
            workingTitle: '@',
            primary: '=',
            isDisabled: '=',
            buttonClass: '@',
            normalSize:'='
        }
    };
});

/**
 * @deprecated since migration to angular.
 * Now using load-more-button.component.ts downgraded component.
 */
ng1App.directive('_loadMoreButton', function () {
    return {
        templateUrl: '/templates/components/preloader/load-more-button.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '=',
            title: '@'
        }
    };
});

/**
 * @deprecated since migration to angular.
 * Now using spinner.component.ts downgraded component.
 */
ng1App.directive('_formPreloader', function () {
    return {
        templateUrl: '/templates/components/preloader/form-preloader.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '='
        }
    };
});

ng1App.directive('itemsPreloader', function () {
    return {
        templateUrl: '/templates/components/preloader/items-preloader.html',
        restrict: 'E',
        replace: true,
        scope: {
            title: '@',
            working: '='
        }
    };
});

ng1App.directive('listPreloader', function () {
    return {
        templateUrl: '/templates/components/preloader/list-preloader.html',
        restrict: 'E',
        replace: true,
        scope: {
            title: '@',
            working: '='
        }
    };
});

ng1App.directive('loadMoreModal', function () {
    return {
        templateUrl: '/templates/components/preloader/load-more-modal.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '=',
            hideLoadMore: '=',
            workingTitle: '@',
            loadMore: '&'
        }
    };
});
ng1App.directive('generatePreloader', function () {
    return {
        templateUrl: '/templates/components/preloader/generate-preloader.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '=',
            title: '@'
        }
    };
});