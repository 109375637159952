import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams, IRowNode} from "ag-grid-community";

export interface ICustomLinkCellParams {
    urlPath: string;
    valueAsId: boolean;
    pathAfterId: string;
}

@Component({
    selector: "ag-link-cell",
    templateUrl: "./ag-link-cell.component.html",
})
export class AgLinkCell implements ICellRendererAngularComp {

    params: ICellRendererParams & ICustomLinkCellParams;
    rowNode: IRowNode;
    public urlPath: string = '/';


    agInit(params: ICellRendererParams & ICustomLinkCellParams): void {
        this.params = params;
        this.rowNode = params.node;
        this.urlPath = this.getFullUrlPath();
    }

    refresh(params: ICellRendererParams & ICustomLinkCellParams): boolean {
        this.params = params;
        this.rowNode = params.node;
        return true;
    }

    onClick() {

    }

    getFullUrlPath(): string {
        let path: string = this.params.urlPath ? this.params.urlPath : this.urlPath;

        if (this.params.valueAsId) {
            path += this.params.value;
            if (this.params.pathAfterId) {
                path += this.params.pathAfterId;
            }
        }

        return path;
    }

}
