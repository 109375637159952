<form [formGroup]="form" novalidate autocomplete="off">
    <div class="row">
        <div *ngFor="let filter of filters" class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <label class="form-label fw-bold" for="{{filter.label}}">{{filter.label}}</label>
                <div class="w-100">
                    <select id="{{filter.label}}" [formControlName]="filter.label" class="form-select" *ngIf="filter.inputType === InputTypeEnum.SELECT">
                        <option *ngFor="let option of filter.options" value={{option.id}}>{{option.value}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</form>