<nhr-record-widget closable="true" closed="false" title="{{'Išdavimas' | translate}}" mixed="true">
    <div class="widget-body form-horizontal">
        <div class="row">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Recepto numeris' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ getPrescriptionId() }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Kompensuojamas' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ (dispenseData?.dispense?.compensationTag ? 'Taip' : 'Ne') | translate }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Išdavimo data' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ moment(dispenseData?.dispense?.whenHandedOver).format("YYYY-MM-DD") }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Įsigyta nuotoliniu būdu' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ (dispenseData?.dispense?.hasEOrder ? 'Taip' : 'Ne') | translate }}
                </p>
            </div>
        </div>
    </div>
</nhr-record-widget>
