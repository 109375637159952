ng1App.factory('SveidraLoginsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/sveidra-logins';
    return $resource('', {}, {
        find: {
            method: 'GET',
            url: URL_BASE
        },
        findById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        findOrg: {
            method: 'GET',
            url: URL_BASE + '/org'
        },
        save: {
            method: 'POST',
            url: URL_BASE
        }
    });
}]);