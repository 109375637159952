ng1App.controller('doctor.patient.TestRecordEditCtrl', 
    ['$scope', '$routeParams', '$window', 'VaccinationRecordsApiFactory', 'MessageService', 'VaccinationUpdateService', 'SubFormValidator',
     function ($scope, $routeParams, $window, VaccinationRecordsApiFactory, MessageService, VaccinationUpdateService, SubFormValidator) {
    $scope.testrecord = {};

    $scope.fileDocument = null;
    $scope.fileDocumentName = null;
    $scope.documentFileId = null;
    $scope.maxFileSize = 0;

    $scope.loadMaxFileSize = function() {
        VaccinationRecordsApiFactory.getMaxFileSize({}, function(data) {
            console.log("Data: " + Object.entries(data));
            console.log("Data next: " + data['MaxFileSize']);
            $scope.maxFileSize = data['MaxFileSize']/ 1024 / 1024;
            console.log("Max file size first: " + $scope.maxFileSize);
        })
    }
    $scope.loadMaxFileSize();

    $scope.loadTestRecords = function() {
        VaccinationRecordsApiFactory.getRecordById({isVaccinationDoc: false, recordId: $routeParams.recordId}, function(data) {
            $scope.testrecord = data;
            angular.forEach($scope.testrecord.o_tyrimai, function (child) {
                $scope.fileDocumentName = angular.element(child).attr('vaccineDocumentFileName');
                $scope.documentFileId = angular.element(child).attr('vaccineDocumentFileId');
            });
        });
    };
    $scope.loadTestRecords();

    $scope.cancelTestRecord = function(){
        VaccinationRecordsApiFactory.cancelRecord({recordType: false, recordId: $routeParams.recordId});
        $window.location.href = "/dp/patients/" +  $routeParams.patientId + "/pgr_vaccines_records/edit";
    };

    $scope.onFileSelect = function (file) {
        if (file) {
            $scope.fileDocument = file;
            $scope.fileDocumentName = file.name;
        }
    };

    $scope.onFileDelete = function (docfileid) {
        $scope.fileDocumentName = null;
        $scope.fileDocument = null;
        $scope.documentFileId = docfileid;
    };

    $scope.form = {
        doctype: false,
        recordId: $routeParams.recordId
    };

    $scope.validator = new SubFormValidator('record-region');
    var validateRecordForm = function () {
        MessageService.clearAll();
        $scope.validator.clear();

        var Docfile = $scope.fileDocument;
        if (Docfile == null && $scope.fileDocumentName == null) {
            $scope.validator.add('docfile');
        } else if (Docfile != null && Docfile.size/ 1024 / 1024 > $scope.maxFileSize) {
            $scope.validator.add('docfileSize');
        } else if (Docfile != null && Docfile.type != "image/gif" && Docfile.type != "image/png" && Docfile.type != "image/bmp" && Docfile.type != "image/jpeg" && Docfile.type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && Docfile.type != "application/msword" && Docfile.type != "application/pdf") {
            $scope.validator.add('docfileType');
        }
        return !$scope.validator.hasErrors();
    };

    $scope.updateRecord = function() {
        if (validateRecordForm()) {
            MessageService.registerSuccessEvent();
            $scope.button={};
            $scope.button.disabled=true;
            VaccinationUpdateService.updateRecord($scope.form, $scope.documentFileId, $scope.fileDocument, function (data) {
                $scope.goBack();
            },function (error) {
                angular.forEach(error.globalErrors, function (e) {
                    MessageService.error(e.msg);
                });
                $window.scrollTo(0, 10);
            });
        }
    };

    $scope.goBack = function () {
        window.history.back();
    };
}]);