import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    listenAndChangeStatusOfControl,
    multiCheckboxIsChecked,
    updateCheckboxControl
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e096-nt-research-planned',
    templateUrl: './e096-nt-research-planned.component.html',
})
export class E096NtResearchPlannedComponent implements OnInit {
    @Input() editable: boolean;
    @Input() answersVariants: {[key: string]: EntityModel[]};
    formGroup: FormGroup;

    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            researchIsPlanned: this.fb.control([], [Validators.required, Validators.minLength(1)]),
            otherStudiesIsPlanned: this.fb.control(null)
        });

        this.changeStatusOfControl({
            controlNameForListen: 'researchIsPlanned',
            controlNameForUpdate: 'otherStudiesIsPlanned',
            enableValue: 'Kiti tyrimai',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }
}
