ng1App.controller('doctor.patient.cert.II.e047ViewCtrl', 
    ['$scope', '$routeParams', 'CertificatesApiFactory', 'AsideBlockModel',
     function ($scope, $routeParams, CertificatesApiFactory, AsideBlockModel) {
	$scope.compositionId = $scope.certificate.compositionId;
	$scope.patientId = $routeParams.patientId;
	if (!$scope.certificate.employerCertificate) {
		 $scope.certificate.employerCertificate = CertificatesApiFactory.getByComposition({
	            id: $scope.certificate.employerCertificateCompositionId
	        });
	}

    $scope._shared = {
        contentList: [
            new AsideBlockModel('cer.e047.mai.inf'),
            new AsideBlockModel('cer.e047.ini.per'),
            new AsideBlockModel('cer.e047.cons', function () {
                return $scope.certificate.documentStatus !== 'FINAL_SIGNED'
                    && $scope.certificate.documentStatus !== 'CANCELED_SIGNED';
            }),
            new AsideBlockModel('cer.e047.doc.dat'),
            new AsideBlockModel('cer.e047.har', function () {
                var variable = $scope.certificate.employerCertificate.harmfulFactors;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e047.con', function () {

                return $scope.certificate.nextHealthCheckDate
                    || $scope.certificate.conclusionRemarks
                    || $scope.certificate.conclusion;
            })
        ]
    };
}]);