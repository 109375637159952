<nav id="mainnav" class="navbar navbar-expand-lg navbar-light bg-white px-3 px-lg-5 py-2_5" role="navigation" aria-label="'Main navigation' | translate">
    <div class="container-fluid ps-0">
        <a class="navbar-brand logo position-relative block" href="/dp" title="{{'returnHome' | translate}}">
            <img src="/assets/img/logo.svg" alt="{{'Health Portal logo'|translate}}">
            <strong class="align-middle fw-bold ms-3">{{'dp.nav.spe' | translate}}</strong><!--Specialistams-->
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbar-mobile" aria-controls="navbarSupportedContent" aria-expanded="false"
                [attr.aria-label]="'Show navigation' | translate" title="{{'Show navigation' | translate}}">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbar-mobile" class="collapse navbar-collapse">
            <quick-search class="me-auto" [name]="quickSearchName" [placeholder]="'comp.docRefConsent.new.search' | translate" (filterService)="filterPatient($event)"/>
            <ul class="d-flex align-items-start align-items-lg-center justify-content-md-end navbar-nav mb-2 mb-lg-0">
                <li class="nav-item text-nowrap">
                    <button class="btn btn-square bg-body-secondary mx-0 mx-lg-2_5 focus-ring focus-ring-primary" (click)="onHomeClick()" [ariaLabel]="'Home' | translate"><i class="fa fa-fw fa-home"></i></button>
                </li>
                <li class="nav-item text-nowrap">
                    <a class="nav-link" [href]="'/dp/patients'">{{'dp.nav.current.menu.patients' | translate}}</a>
                </li>
                <li class="nav-item text-nowrap">
                    <a class="nav-link" target="_blank" [href]="iprUrl + '/' + token">{{'dp.nav.ipr.por' | translate}}</a>
                </li>
                <li class="nav-item text-nowrap">
                    <a class="nav-link" [href]="'#'">{{'dp.nav.current.menu.reports' | translate}}</a>
                </li>
                <li class="nav-item text-nowrap">
                    <a class="nav-link" target="_blank" [href]="helpPage | helpLink">{{'Help' | translate}}</a>
                </li>
                <li class="nav-item text-nowrap pt-3 pt-lg-0">
                    <div class="fw-bold d-flex">
                        <button class="btn btn-square bg-body-secondary position-relative mx-0 mx-lg-2_5 focus-ring focus-ring-primary" (click)="onTasksClick()">
                            <i class="fa fa-fw fa-check-square-o" aria-hidden="true"></i>
                            <notification-count [count]="tasksCount" [visuallyHiddenTitle]="'active tasks'"></notification-count>
                        </button>

                        <button class="btn btn-square bg-body-secondary position-relative mx-2_5 focus-ring focus-ring-primary" (click)="onMessagesClick()">
                            <i class="fa fa-fw fa-envelope-o" aria-hidden="true"></i>
                            <notification-count [count]="messagesCount" [visuallyHiddenTitle]="'unread messages'"></notification-count>
                        </button>
                    </div>
                </li>
                <li *ngIf="practitioner$ | async as practitioner" class="nav-item w-100 pt-3 pt-lg-0">
                    <div class="dropdown">
                        <button class="nav-link dropdown-toggle text-start ms-0 ms-lg-2 focus-ring focus-ring-primary pe-2 me-n2 w-100" id="main-dropdown-closed" (click)="toggleMainMenu('main-dropdown-open')" type="button" aria-expanded="false" data-bs-toggle="dropdown">
                            <div class="d-flex flex-column">
                                <strong class="text-body">{{practitioner | fullName | capitalize}}</strong>
                                <small *ngIf="currentUser" class="text-tertiary">{{currentUser.roleName}}</small>
                            </div>
                        </button>
                        <ul id="main-dropdown" class="dropdown-menu dropdown-menu-end shadow pb-2">
                            <li class="position-relative">
                                <button class="nav-link dropdown-toggle d-flex flex-column text-start py-2_5 px-3 px-lg-4 focus-ring focus-ring-primary w-100" id="main-dropdown-open" (click)="toggleMainMenu('main-dropdown-closed')" type="button" aria-expanded="true" data-bs-toggle="dropdown">
                                    <div class="d-flex flex-column">
                                        <strong class="text-body">{{practitioner | fullName | capitalize}}</strong>
                                        <small *ngIf="currentUser" class="text-tertiary">{{currentUser.roleName}}</small>
                                    </div>
                                </button>
                            </li>
                            <li class="dropdown-divider py-0" tabindex="-1"></li>
                            <li *ngIf="currentUser" class="espbi-info bg-light">
                                <ul class="p-3 p-lg-4">
                                    <li class="mb-2_5">
                                        <small class="d-block lh-1 text-tertiary">
                                            {{currentUser.workplaceName}}
                                        </small>
                                    </li>
                                    <li class="mb-2_5">
                                        <small class="d-block lh-1 text-tertiary">
                                            {{'SVEIDRA - ' + currentUser.organizationSveidraId}}
                                        </small>
                                    </li>
                                    <li class="d-flex flex-column mb-2_5">
                                        <small>
                                            Darbo vieta:
                                        </small>
                                        <small class="d-block lh-1 text-tertiary">
                                            {{currentUser.workplaceAddress + ' (SVEIDRA:' + currentUser.organizationSveidraId + ')'}}
                                        </small>
                                    </li>
                                    <li>
                                        <small class="d-block">Licencija:</small>
                                        <select class="form-select form-select-sm text-tertiary" (change)="onQualificationChange($event)">
                                            <option *ngFor="let qualification of practitioner.qualificationList" [value]="qualification.licenseCode"
                                                    [defaultSelected]="dpController.getQualification()">
                                                {{qualification.licenseCode + ' - ' + qualification.name}}
                                            </option>
                                        </select>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-divider pt-0 pb-2"></li>
                            <li>
                                <small>
                                    <a href="/dp/account/view" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.nav.my' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="/dp/representatives" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.nav.my.rep' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="/dp/account/settings" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.pra.ntf.tit' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li class="dropdown-divider my-2"></li>
                            <li>
                                <small>
                                    <!--/dp/patients/{{patientId}}/drafts-->
                                    <a href="#" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.pat.das.dra' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="/dp/templates" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.use.das.tem' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="/dp/tasks" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.pat.das.tas' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="/dp/doc-ref-consent/list" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'comp.docRefConsent.list.title' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="/dp/imaging/study/list" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.use.das.med.vie' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li>
                                <small>
                                    <a href="#" class="dropdown-item px-3 px-lg-4 py-2">
                                        {{'dp.nav.current.menu.reports' | translate}}
                                    </a>
                                </small>
                            </li>
                            <li class="dropdown-divider my-2" tabindex="-1"></li>
                            <li class="d-grid py-2">
                            <button class="btn btn-sm btn-info mx-3 mx-lg-4" tabindex="0" (click)="onChangeRoleClick()">Keisti rolę</button>
                            </li><!-- Change role -->
                            <li class="dropdown-divider my-2"></li>
                            <li>
                                <button (click)="doLogout()" class="dropdown-item px-3 px-lg-4 py-2 fw-bold d-flex flex-row justify-content-between align-items-center">
                                    <span>
                                        {{'Log out'|translate}}
                                    </span>
                                    <i class="fa fa-sign-out"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>