angular.module('erx.utils').service('$erxModalDrugInteractions', ["$modal", "$rootScope", "gettextCatalog", function($modal, $rootScope, gettextCatalog) {
    var scope = $rootScope.$new();   
    var popup = $modal({template: 'modules/erx-utils/components/erx-modal-drug-interactions/erxModalDrugInteractions.tpl.html', scope: scope, show: false});
    var parentShow = popup.show;
    scope.setCurrInteraction = function(idx) {
        scope.currIndex = idx;
        scope.currInteraction = scope.drugInteractions[idx];
    }
    popup.show = function(drugInteractions, idx) { 
        scope.drugInteractions = drugInteractions;
        scope.setCurrInteraction(idx);
        parentShow();
    };
    
    scope.extractHostname = function(url) {
        var hostname;
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        return hostname;
    }
    
    return popup;
}]);