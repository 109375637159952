ng1App.directive('sveidraInterventionsF066', 
    ['SveidraListSubForm', 'DocumentsApiFactory', 'TypeaheadSearchHandlers',
     function (SveidraListSubForm, DocumentsApiFactory, TypeaheadSearchHandlers) {
	var FLOAT_REGEXP = /^\d{1,11}((\.|\,)\d{0,2})?$/;
	
	// Intervention
	var initIntervention = function (controller, documentId) {
		controller._data.interventionFilter = null;
		controller._data.interventions = DocumentsApiFactory.getProcedures({documentId: documentId});
		controller._data.selectedIntervention = null;
		
		controller.interventionChanged = function () {
			this._form.code = this._data.selectedIntervention.procedure;
			this._form.date = new Date(this._data.selectedIntervention.date);
		};
	};
	
	// Service
	var initService = function (controller) {
		controller._data.serviceSearhParam = null;
		
		controller.searchForService = TypeaheadSearchHandlers.HEALTH_SERVICE;
		
		controller.changeService = function (service) {
			if (service) {
				if (service.code) {
					this._form.service = service;
				}
			} else {
				this._form.service = null;
			}
		};
	};
	
	// Practitioner
	var initPractitioner = function (controller) {
		controller._data.practitionerSearchParam = null;
		controller.searchForPractitioner = TypeaheadSearchHandlers.PRACTITIONERS;
		
		controller.changePractitioner = function (practitioner) {
			if (practitioner) {
				if (practitioner.id) {
					this._form.practitionerId = practitioner.id;
					this._form.practitioner = practitioner;
				}
			} else {
				this._form.practitionerId = null;
				this._form.practitioner = null;
			}
		};
	};
	
	var clearAdditionalData = function (controller) {
		controller._data.interventionFilter = null;
		controller._data.selectedIntervention = null;
		controller._data.serviceSearhParam = null;
		controller._data.practitionerSearchParam = null;
	};
	
	var resolveDataForEdit = function (form, data) {
		// Intervention
		for (var i = 0; i < data.interventions.length; i++) {
			if (form.code.code == data.interventions[i].procedure.code) {
				data.selectedIntervention = data.interventions[i];
				break;
			}
		}
		
		// Service
		data.serviceSearhParam = _.getCodeName(form.service);
		
		// Practitioner
		data.practitionerSearchParam = _.getFullName(form.practitioner) + ' - ' + form.practitioner.stampCode;
	};
	
	
	var initForm = function (scope) {
		scope.formCtrl = new SveidraListSubForm(scope.stage.interventions, 'interventions');
		
		initIntervention(scope.formCtrl, scope.stage.documentId);
		initService(scope.formCtrl);
		initPractitioner(scope.formCtrl);
		
		scope.formCtrl.before = function (action) {
            if (action == 'cancel') {
                this.clear();
                clearAdditionalData(this);
            }
        };
        
        scope.formCtrl.after = function (action) {
        	if ((action == 'add' || action == 'save') && !this._validator.hasErrors()) {
        		clearAdditionalData(this);
        	} else if (action == 'edit') {
        		resolveDataForEdit(this._form, this._data);
        	}
        };
		
        scope.formCtrl.validateValue = function (form, validator) {
			if (!form.code) {
				validator.add('intervention');
			}
			if (!form.service) {
				validator.add('service');
			}
			if (!form.practitionerId){
				validator.add('practitioner');
			}
			if (form.cost && !FLOAT_REGEXP.test(form.cost)) {
				validator.add('cost');
			}
    	};
		
		scope.formCtrl.init();
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/interventions.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			scope.stage.$promise.then(function () {
				initForm(scope);
			});
			
		}
	};
}]);