ng1App.directive('sveidraCommonMedicationsView', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/common_medications_view.html',
		restrict: 'E',
		scope: {
			medications: '=',
			price: '=',
			priceRate: '=',
			title: '@',
			priceTitle: '@',
			priceRateTitle: '@',
            icon: '@',
            font: '@'
		},
		link: function (scope, element, attrs) {
		}
	};
});