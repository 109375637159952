ng1App.factory("RolesApiFactory", 
    ['$resource', 'apiUrl', 'RoleTypes',
     function ($resource, apiUrl, RoleTypes) {

  return _.reduce(RoleTypes.ALL_WITH_GROUP, function (api, type) {
    const typeApi = {};

    typeApi[type] = $resource('', {}, {
      update: {method: 'PUT', url: apiUrl + '/roles/:type/:roleId/', params: {type: type}},
      get: {method: 'GET', url: apiUrl + '/roles/:type/:roleId/', params: {type: type}},
      getAll: {method: 'GET', url: apiUrl + '/roles/:type/', params: {type: type}},
      create: {method: 'POST', url: apiUrl + '/roles/:type/', params: {type: type}},
    });

    return _.extend(_.extend(api, typeApi), {
      common: $resource('', {}, {
        getTypes: {method: 'GET', url: apiUrl + '/roles/all/'},
        isSpecialUserWithSingleOrganization: {method: 'GET', url: apiUrl + '/specialUsers/role/:roleCode/rule/organization'},
      })
    });
  }, {});

}]);