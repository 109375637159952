<!--doc.und.sur Atliktos chirurginės procedūros e025-->
<!--if exist show-->
<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true" [title]="'doc.und.sur'|translate">
    <div class="widget-body p-0">
        <form [formGroup]="form">
            <div *ngIf="!viewOnly" class="p-4">
                <div class="form-group row req">
                    <label for="templateTitle" class="col-md-3 col-form-label text-md-end">Procedūros atlikimo data</label>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 mb-2 mb-sm-0">
                                <app-date-picker
                                    inputId="Date"
                                    [maxDate]="currentDate"
                                    [fControl]="form?.get('date')">
                                </app-date-picker>
                            </div>
                            <div class="col-sm-6 col-md-2 mb-0">
                                <app-time-picker
                                    inputId="time"
                                    [fControl]="form.get('time')"
                                    [disabled]="true">
                                </app-time-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row req">
                    <label for="templateTitle" class="col-md-3 col-form-label text-md-end">Atlikta intervencinė procedūra</label>
                    <div class="col-md-8">
                        <input formControlName="procedure"
                            typeaheadOptionField="nameAndCode"
                            [typeahead]="suggestionsOrg$"
                            [typeaheadAsync]="true"
                            [typeaheadItemTemplate]="customItemTemplate"
                            (typeaheadOnSelect)="typeaheadOnSelect($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos pavadinimą"
                            class="form-control">
                        <ng-template #customItemTemplate let-model="item">
                            <span><strong>{{model.code}}</strong> {{model.name}}</span>
                        </ng-template>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="templateTitle" class="col-md-3 col-form-label text-md-end pt-md-0">Aprašymas</label>
                    <div class="col-md-8">
                        <textarea type="text" class="form-control" id="templateTitle" formControlName="description" maxlength = "4000" rows="4"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-md-3 col-md-8">
                        <button class='btn btn-secondary btn-sm' (click)="addOrEditProcedure()">
                            {{ editingIndex !== null ? 'Koreguoti' : '+ Pridėti' }}
                        </button>
                    </div>
                </div>
            </div>
            <ol class="slats hover">
                <li
                    *ngFor="let procedure of getProcedureFormControls(); let i = index" class="p-4{{ i == 0 && !viewOnly ? ' border-top border-dark-subtle' : '' }}{{viewOnly ? '' : ' pb-2'}}"
                    slat-item [delete]="!viewOnly" [edit]="!viewOnly"
                    (editButtonClick)="editProcedure(i)"
                    (deleteButtonClick)="removeProcedure(i)"
                >
                    <div class="row">
                    <div class="col-md-8">
                        <div class="fw-bold">{{ procedure.value.procedure }}</div>
                        <div class="col-md-4">
                        {{ procedure.value.date | date:'yyyy-MM-dd' }} {{ procedure.value.time }}
                        </div>
                    </div>
                    </div>
                </li>
            </ol>
        </form>
    </div>
</nhr-record-widget>
<!--end if-->