// Any changes that are made in app-title should be reflected in app-title-sc component too (app-title-sc.html and common-component.ts)

import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
})
export class TitleComponent {
  // To hide backPath pass null value
  @Input() backPath?: string | null;
  @Input() backTitle?: string;
  @Input() backInline?: boolean;
  @Input() backSubtitle?: string;
  @Input() badgeText?: string;
  @Input() badgeClass?: string;
  @Input() showFilterButton?: boolean;

  constructor() {}
}
