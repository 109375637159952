ng1App.controller('doctor.user.medvais.ImagingStudyListCtrl', 
    ['$scope', 'SessionApiFactory', 'DpSessionContext', 'MedvaisPaths',
     function ($scope, SessionApiFactory, DpSessionContext, MedvaisPaths) {

    $scope.medvaisImgStudyListOptions = {
        getPractitioner: function(){
            return DpSessionContext.practitioner.$promise;
        },
        getOrganization: function(){
            return DpSessionContext.organization.$promise;
        },
        getBackUrl: function () {
            return '/dp';
        },
        // route to document E027-va edit form. New document.
        getNew027vaDocUrl: MedvaisPaths.new,

        // route to document E027-va edit form. Edit existing document.
        getEdit027vaDocUrl: MedvaisPaths.edit,

        // route to document E027-va preview form.
        getView027vaDocUrl: MedvaisPaths.view,
        // user info
        getUserInfo: function(){
            return $scope.token;
        },
        // to get active qualification
        getAccountSettings: function () {
            return DpSessionContext.getSettings();
        }
    };
}]);


