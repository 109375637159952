<nhr-record-widget closable="true" closed="false" title="{{'Išdavimo dokumento duomenys' | translate}}" mixed="true">
    <div class="widget-body form-horizontal">
        <div class="row">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Pakanka iki' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ moment(dispenseData?.dispense?.dueDate).format("YYYY-MM-DD") }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="showLowIncome() || dispenseData?.dispense?.surchargeEligible">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Teisė į paciento priemokos padengimą' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p>
                    {{ 'Mažos pajamos' | translate }}{{ ': ' }}{{ (hasPatientLowIncome() ? 'Taip' : 'Ne') | translate }}
                </p>
                <p>
                    {{ 'Sukauptas priemokų krepšelis' | translate }}{{ ': ' }}{{ (dispenseData?.dispense?.surchargeEligible ? 'Taip' : 'Ne') | translate }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Kaina' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ dispenseData?.dispense?.price?.retail?.value }}{{ ' ' }}{{ dispenseData?.dispense?.price?.retail?.unit?.displayValue }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="dispenseData?.dispense?.compensationTag">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Paciento sumokėta priemoka' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ dispenseData?.dispense?.price?.paid?.value }}{{ ' ' }}{{ dispenseData?.dispense?.price?.paid?.unit?.displayValue }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="dispenseData?.dispense?.compensationTag">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Kompensuojamoji suma' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ dispenseData?.dispense?.price?.compensated?.value }}{{ ' ' }}{{ dispenseData?.dispense?.price?.compensated?.unit?.displayValue }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="hasPatientLowIncome() || dispenseData?.dispense?.surchargeEligible">
            <div class="col-sm-3">
                <strong class="col-form-label">
                    {{ 'Padengiama paciento priemoka' | translate }}
                </strong>
            </div>
            <div class="col-sm-9">
                <p class="form-control-plaintext">
                    {{ dispenseData?.dispense?.lowIncome?.surchargeCompensated?.value }}{{ ' ' }}{{ dispenseData?.dispense?.lowIncome?.surchargeCompensated?.unit?.displayValue }}
                </p>
            </div>
        </div>
    </div>
</nhr-record-widget>
