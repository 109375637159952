<form [formGroup]="form" novalidate autocomplete="off">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-xxl-6" *ngIf="categories">
            <div class="form-group">
                <label class="form-label fw-bold" for="category">Kategorija</label>
                <div class="w-100">
                    <select id="category" [formControlName]="'category'" class="form-select">
                        <option *ngFor="let option of categories" value={{option.id}}>{{option.title}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <label class="form-label fw-bold" [for]="procedureField">Procedūra</label>
                <div class="w-100">
                    <div class="input-group">
                        <input [id]="procedureField" [formControl]="procedureControl" type="text" class="form-control" [typeahead]="proceduresSuggestions$"
                               [typeaheadAsync]="true" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5" (typeaheadOnSelect)="changeProcedure($event)"
                               [typeaheadOptionField]="procedureField" placeholder="Įveskite procedūros pavadinimą">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <label class="form-label fw-bold" [for]="patientField">Pacientas</label>
                <div class="w-100">
                    <div class="input-group">
                        <input [id]="patientField" [formControl]="patientControl" type="text" class="form-control" [typeahead]="patientsSuggestions$"
                               [typeaheadAsync]="true" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5" (typeaheadOnSelect)="changePatient($event)"
                               [typeaheadOptionField]="patientField" placeholder="{{'comp.docRefConsent.new.search' | translate}}">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <label class="form-label fw-bold" [for]="initiatorField">Iniciatorius</label>
                <div class="w-100">
                    <div class="input-group">
                        <input [id]="initiatorField" [formControl]="initiatorControl" type="text" class="form-control" [typeahead]="initiatorsSuggestions$"
                               [typeaheadAsync]="true" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5" (typeaheadOnSelect)="changeInitiator($event)"
                               [typeaheadOptionField]="initiatorField"
                               placeholder="Ieškokite iniciatoriaus (Vardas, Pavardė, a.k., DIK)">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" (click)="onSearchClick($event)">{{"Ieškoti" | translate}}</button>
        </div>
    </div>
</form>
<!-- TODO-design: fix onSearchClick event trigger -->
<!-- <search-modal [form]="form" (onSearch)="onSearchClick($event)">
</search-modal> -->