<div [ngClass]="classes" *ngIf="working">
    <div class="spinner-container d-flex flex-column gap-3 align-items-center">
        <div>
            <span class="fa fa-spinner fa-spin" [ngClass]="{'fa-3x': !smallSize}"></span>
        </div>
        <p *ngIf="!noTitle" class="text-center mb-0">
            {{ title ? title : 'wait.loading' | translate }}
        </p>
    </div>
</div>
