import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErxTypeaheadUtilsService {

    constructor() {
    }

    public typeaheadComparator(): boolean {
        return true;
    }

    public drugSearchPhrase(drug, type): any {
        if (drug !== undefined && drug instanceof Object) {
            return type !== 'mpp' ? this.typeaheadDrugFormatter(drug) : this.typeaheadMppFormatter(drug);
        } else if (drug !== undefined) {
            return drug;
        }
        return "";
    }

    public typeaheadDrugFormatter(drug): string {
        if (drug !== undefined) {
            const pharmForm = drug.pharmForm !== undefined ? ' (' + drug.pharmForm.displayValue + ') ' : ' ';
            const atcCode = drug.atc !== undefined ? ' ' + drug.atc.code : '';
            const drugControlledType = drug.controlledType !== undefined ? ' ' + drug.controlledType.displayValue : '';
            return drug.activeIngredients + ' ' + drug.strength + pharmForm + drug.productName + atcCode + drugControlledType;
        }
        return "";
    }

    public typeaheadMppFormatter(drug): string {
        if (drug !== undefined) {
            return drug.productName + ' (' + drug.activeIngredients + ')';
        }
        return "";
    }

    public typeaheadSubstancesFormatter(drug): string {
        if (drug !== undefined) {
            return drug.name + ' ' + drug.code;
        }
        return "";
    }
}