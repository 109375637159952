import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams, IRowNode} from "ag-grid-community";

export interface ICustomPillCellParams {
}

export interface PillCellModel {
    status: string;
    pillColor?: string;
    validFrom?: string;
    validUntil?: string;
}

@Component({
    selector: "ag-pill-cell",
    templateUrl: "./ag-pill-cell.component.html",
})
export class AgPillCell implements ICellRendererAngularComp {

    params: ICellRendererParams & ICustomPillCellParams;
    rowNode: IRowNode;
    pillModel: PillCellModel;
    pillColor: string = "text-bg-primary";

    constructor() {
    }

    agInit(params: ICellRendererParams & ICustomPillCellParams): void {
        this.params = params;
        this.rowNode = params.node;
        this.pillModel = params.value;
        this.pillColor = this.pillModel.pillColor ? this.pillModel.pillColor : this.pillColor;
    }

    refresh(params: ICellRendererParams & ICustomPillCellParams): boolean {
        this.rowNode = params.node;
        this.pillModel = params.value;
        return true;
    }

}