import { HttpClient, HttpClientModule } from "@angular/common/http";
import { $locationShim, LocationUpgradeModule } from "@angular/common/upgrade";
import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UpgradeModule } from "@angular/upgrade/static";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DowngradeAdapter } from "../../utils/hypridHelper";
import { ErxDoctorLibrary } from "../erxDoctor/erxDoctor.module";
import { MedvaisLibrary } from "../medvais/medvais.module";
import { bootstrapUpgrade } from "../ng1.app";
import { routeParamsProvider } from "../ng1.routeParams";
import { RootModule } from "../root.module";
import { SharedLibrary } from "../shared/shared.module";
import { createPoTranslateLoader } from "../shared/translate/poHttpLoader";
import { AspnModule } from "./aspn/aspn.module";
import { DpDocRef110GMPComponent } from "./components/110-GMP/create/doc-ref-110-GMP-form.component";
import { DpDocRef110GMPEditComponent } from "./components/110-GMP/edit/doc-ref-110-GMP-form-edit.component";
import { DpDocRefE110ListComponent } from "./components/110-GMP/list/dp-doc-ref-110-GMP-form-list.component";
import { DpDocRef110GMPViewComponent } from "./components/110-GMP/view/dp-doc-ref-110-GMP-form-view.component";
import { AllRecordsListComponent } from "./components/allRecords/all-records-list.component";
import { AllergiesFormComponent } from "./components/allergies/allergies-form/allergies-form.component";
import { EditAllergyModalComponent } from "./components/allergies/allergies-form/edit-allergy-modal/edit-allergy-modal.component";
import { AllergiesPageComponent } from "./components/allergies/allergies-page/allergies-page.component";
import { AssessmentOfHealthFactorsFormComponent } from "./components/assessment-of-health-factors/assessment-of-health-factors-form/assessment-of-health-factors-form.component";
import { EditHealthFactorModalComponent } from "./components/assessment-of-health-factors/assessment-of-health-factors-form/edit-health-factor-modal/edit-health-factor-modal.component";
import { RiskTestComponent } from "./components/assessment-of-health-factors/assessment-of-health-factors-form/risk-test/risk-test.component";
import { AssessmentOfHealthFactorsPageComponent } from "./components/assessment-of-health-factors/assessment-of-health-factors-page/assessment-of-health-factors-page.component";
import { CertificateModalComponent } from "./components/certificateModal/certificate-modal.component";
import { DocRefAttachedUnsortedComponent } from "./components/doc-ref-attached-unsorted/create/doc-ref-attached-unsorted.component";
import { DocRefAttachedUnsortedEditComponent } from "./components/doc-ref-attached-unsorted/edit/doc-ref-attached-unsorted-edit.component";
import { DpDocRefAttachedUnsortedListComponent } from "./components/doc-ref-attached-unsorted/list/dp-doc-ref-attached-unsorted-list.component";
import { DpDocAttachedUnsortedViewComponent } from "./components/doc-ref-attached-unsorted/view/dp-doc-ref-attached-unsorted-view.component";
import { DocRefConsentCreateComponent } from "./components/docRefConsent/create/doc-ref-consent-create.component";
import { DpDocRefConsentListComponent } from "./components/docRefConsent/list/dp-doc-ref-consent-list.component";
import { DpDocRefConsentViewComponent } from "./components/docRefConsent/view/dp-doc-ref-consent-view.component";
import { DpDocRefConsentTemplateListComponent } from "./components/docRefConsentTemplate/list/dp-doc-ref-consent-template-list.component";
import { DpDocRefConsentTemplateViewComponent } from "./components/docRefConsentTemplate/view/dp-doc-ref-consent-template-view.component";
import { DocumentTypeSelectComponent } from "./components/documentTypeSelect/document-type-select.component";
import { DpDoctorContextMainComponent } from "./components/dp-doctor-context-main.component";
import { DpPatientContextMainComponent } from "./components/dp-patient-context-main.component";
import { DpDoctorContextComponent } from "./components/dpDoctorContext/doctor-context.component";
import { DpNavigationComponent } from "./components/dpNavigation/dp-navigation.component";
import { DpPatientContextComponent } from "./components/dpPatientContext/patient-context.component";
import { EncounterInfoComponent } from "./components/encounter/encounter-info.component";
import { OrganizationInfoComponent } from "./components/encounter/organizationInfo/organization-info.component";
import { RegistratorInfoComponent } from "./components/encounter/registratorInfo/registrator-info.component";
import { DpDocRefF0255Component } from "./components/f025/create/doc-ref-f025-5-form.component";
import { DpDocRefF0255EditComponent } from "./components/f025/edit/doc-ref-f025-5-form-edit.component";
import { DpDocRefF025ListComponent } from "./components/f025/list/dp-doc-ref-f025-list.component";
import { DpDocRefF025ViewComponent } from "./components/f025/view/dp-doc-ref-f025-form-view.component";
import { DpDocRef058Component } from "./components/f058/create/doc-ref-f058-form.component";
import { DpDocRef058EditComponent } from "./components/f058/edit/doc-ref-f058-form-edit.component";
import { DpDocRef058ViewComponent } from "./components/f058/view/dp-doc-ref-f058-form-view.component";
import { DpDocRefF090Component } from "./components/f090/create/doc-ref-f090-form.component";
import { DpDocRefF090EditComponent } from "./components/f090/edit/doc-ref-f090-form-edit.component";
import { DpDocRefF090ListComponent } from "./components/f090/list/dp-doc-ref-f090-form-list.component";
import { DpDocRef090ViewComponent } from "./components/f090/view/dp-doc-ref-f090-form-view.component";
import { FinishedDocumentSelectComponent } from "./components/finishedDocumentSelect/finished-document-select.component";
import { InrFormComponent } from "./components/inr-page/inr-form/inr-form.component";
import { InrPageComponent } from "./components/inr-page/inr-page.component";
import { OrderSelectComponent } from "./components/orderTypeSelect/order-select.component";
import { DpPatientCalendarComponent } from "./components/patient-calendar/dp-patient-calendar.component";
import { AssessmentOfHealthFactorsSummaryComponent } from "./components/summaries/assessment-of-health-factors-summary/assessment-of-health-factors-summary.component";
import { DiagnosisSummaryComponent } from "./components/summaries/diagnosis-summary/diagnosis-summary.component";
import { DisabilitiesSummaryComponent } from "./components/summaries/disabilities-summary/disabilities-summary.component";
import { MedicalDevicesSummaryComponent } from "./components/summaries/medical-devices-summary/medical-devices-summary.component";
import { MedicalRecommendationsSummaryComponent } from "./components/summaries/medical-recommendations-summary/medical-recommendations-summary.component";
import { SurgeriesSummaryComponent } from "./components/summaries/surgeries-summary/surgeries-summary.component";
import { VaccinesSummaryComponent } from "./components/summaries/vaccine-summary/vaccines-summary.component";
import { TemplateLoadComponent } from "./components/templateLoad/template-load.component";
import { ThirdPartyRecordsListComponent } from "./components/thirdPartyRecords/third-party-records-list.component";
import { DoctorRoutes } from "./doctor.routes";
import { PregnancyDashboardModule } from "./ngn/pregnancy-dashboard.module";
import { documentModificationActions } from "./services/document-modification-service";
import { DocumentTypeSelectService } from "./services/document-type-select.service";
import { documentContextService } from "./services/document_context";
import { DpPatientContextService } from "./services/dp-patient-context.service";
import { DpSessionContext } from "./services/dp-session-context.service";

@NgModule({
    //Double rendering fix for angular components, which are downgraded and used in angularjs.
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary.forRoot(),
        ErxDoctorLibrary.forRoot(),
        MedvaisLibrary.forRoot(),
        DoctorRoutes,
        FormsModule,
        ReactiveFormsModule,
        PregnancyDashboardModule,
        TranslateModule,
        TypeaheadModule.forRoot(),
        BrowserAnimationsModule,
        LocationUpgradeModule.config({
            useHash: false,
            hashPrefix: "!",
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createPoTranslateLoader,
                deps: [HttpClient],
            },

            // required for Pluralization support:
            // compiler: {
            //     provide: TranslateCompiler,
            //     useClass: TranslateMessageFormatCompiler
            // },
            // useDefaultLang: true,
            // defaultLanguage: "lt",
            isolate: false,
        }),
        TypeaheadModule,
        AspnModule,
    ],
    declarations: [
        DpDoctorContextMainComponent,
        DpPatientContextMainComponent,
        DpDoctorContextComponent,
        DpPatientContextComponent,
        DpDocRefConsentListComponent,
        DpDocRefConsentViewComponent,
        DpDocRefConsentTemplateListComponent,
        DpDocRefConsentTemplateViewComponent,
        DocRefConsentCreateComponent,
        DocRefAttachedUnsortedComponent,
        DpDocRefAttachedUnsortedListComponent,
        DpDocAttachedUnsortedViewComponent,
        DocRefAttachedUnsortedEditComponent,
        DocumentTypeSelectComponent,
        OrderSelectComponent,
        CertificateModalComponent,
        DpNavigationComponent,
        TemplateLoadComponent,
        FinishedDocumentSelectComponent,
        DpDocRef058Component,
        DpDocRef058EditComponent,
        DpDocRef058ViewComponent,
        DpDocRefF090Component,
        DpDocRefF090EditComponent,
        DpDocRef090ViewComponent,
        DpDocRefF090ListComponent,
        DpDocRefF0255Component,
        DpDocRefF025ListComponent,
        AllergiesFormComponent,
        EditAllergyModalComponent,
        AllergiesPageComponent,
        DpDocRefF0255EditComponent,
        DpDocRefF025ViewComponent,
        DpDocRef110GMPComponent,
        AssessmentOfHealthFactorsPageComponent,
        AssessmentOfHealthFactorsFormComponent,
        EditHealthFactorModalComponent,
        RiskTestComponent,
        DpDocRef110GMPEditComponent,
        DpDocRef110GMPViewComponent,
        DpDocRefE110ListComponent,
        InrPageComponent,
        InrFormComponent,
        AllRecordsListComponent,
        ThirdPartyRecordsListComponent,
        DiagnosisSummaryComponent,
        VaccinesSummaryComponent,
        AssessmentOfHealthFactorsSummaryComponent,
        MedicalRecommendationsSummaryComponent,
        DisabilitiesSummaryComponent,
        MedicalDevicesSummaryComponent,
        SurgeriesSummaryComponent,
        RegistratorInfoComponent,
        OrganizationInfoComponent,
        DpPatientCalendarComponent,
        EncounterInfoComponent,
    ],
    providers: [
        // Provider for ngRoute (ng1) route params
        routeParamsProvider,
        documentModificationActions,
        documentContextService,
        // CapitalizePipe,
        // FullNamePipe,
    ],
    exports: [],
})
export class DoctorModule extends RootModule implements DoBootstrap {
    constructor(private upgrade: UpgradeModule, translate: TranslateService) {
        super(translate);
    }

    ngDoBootstrap(appRef: ApplicationRef): void {
        console.debug("Ng1Module.ngDoBootstrap");
        bootstrapUpgrade(this.upgrade, document.body);
    }
}

new DowngradeAdapter(ng1App)
    .downgradeProvider($locationShim, "$location")
    .downgradeProvider(DpSessionContext, "DpSessionContext2")
    .downgradeProvider(DpPatientContextService, "DpPatientContext") // replaces ng1 DpPatientContext service
    .downgradeProvider(DocumentTypeSelectService, "DocumentTypeSelectService")
    .downgradeComponent(DpDoctorContextMainComponent)
    .downgradeComponent(DpPatientContextMainComponent)
    .downgradeComponent(DpDoctorContextComponent)
    .downgradeComponent(DpPatientContextComponent)
    .downgradeComponent(DpDocRefConsentListComponent)
    .downgradeComponent(DpDocRefConsentViewComponent)
    .downgradeComponent(DpDocRefConsentTemplateListComponent)
    .downgradeComponent(DpDocRefConsentTemplateViewComponent)
    .downgradeComponent(DocRefConsentCreateComponent)
    .downgradeComponent(DocRefAttachedUnsortedComponent)
    .downgradeComponent(DpDocRefAttachedUnsortedListComponent)
    .downgradeComponent(DpDocAttachedUnsortedViewComponent)
    .downgradeComponent(DocRefAttachedUnsortedEditComponent)
    .downgradeComponent(DocumentTypeSelectComponent)
    .downgradeComponent(OrderSelectComponent)
    .downgradeComponent(CertificateModalComponent)
    .downgradeComponent(DpNavigationComponent)
    .downgradeComponent(TemplateLoadComponent)
    .downgradeComponent(FinishedDocumentSelectComponent)
    .downgradeComponent(DpDocRef058Component)
    .downgradeComponent(DpDocRef058EditComponent)
    .downgradeComponent(DpDocRef058ViewComponent)
    .downgradeComponent(DpDocRefF090Component)
    .downgradeComponent(DpDocRefF090EditComponent)
    .downgradeComponent(DpDocRef090ViewComponent)
    .downgradeComponent(DpDocRefF090ListComponent)
    .downgradeComponent(DpDocRefF0255Component)
    .downgradeComponent(DpDocRefF025ListComponent)
    .downgradeComponent(AllergiesFormComponent, "appAllergiesForm")
    .downgradeComponent(AllergiesPageComponent, "appAllergiesPage")
    .downgradeComponent(DpDocRefF0255EditComponent)
    .downgradeComponent(DpDocRefF025ViewComponent)
    .downgradeComponent(DpDocRef110GMPComponent)
    .downgradeComponent(AssessmentOfHealthFactorsPageComponent)
    .downgradeComponent(AssessmentOfHealthFactorsFormComponent, "appAssessmentOfHealthFactorsForm")
    .downgradeComponent(DpDocRef110GMPEditComponent)
    .downgradeComponent(DpDocRef110GMPViewComponent)
    .downgradeComponent(DpDocRefE110ListComponent)
    .downgradeComponent(InrPageComponent)
    .downgradeComponent(AllRecordsListComponent)
    .downgradeComponent(ThirdPartyRecordsListComponent)
    .downgradeComponent(DiagnosisSummaryComponent)
    .downgradeComponent(VaccinesSummaryComponent)
    .downgradeComponent(AssessmentOfHealthFactorsSummaryComponent)
    .downgradeComponent(MedicalRecommendationsSummaryComponent)
    .downgradeComponent(DisabilitiesSummaryComponent)
    .downgradeComponent(MedicalDevicesSummaryComponent)
    .downgradeComponent(SurgeriesSummaryComponent)
    .downgradeComponent(RegistratorInfoComponent)
    .downgradeComponent(DpPatientCalendarComponent)
    .downgradeComponent(EncounterInfoComponent)
    .downgradeComponent(OrganizationInfoComponent);
