/* global angular, app */

ng1App.factory("SearchController", [
    "$rootScope",
    "$modal",
    "SearchFormSaver",
    "FormLoaderService",
    function ($rootScope, $modal, SearchFormSaver, FormLoaderService) {
        /**
         * arguments:
         *  [0] - viewId
         *  [1] - search dialog template path
         *  [2] - records per page
         *  [3] - scope initializing function
         *  [4] - form initializing function
         *  [5] - search function
         *  [6] - showPageCounter
         *
         *  OR
         *
         *  1 Config object
         *  {
         *      viewId: 'xxx', // string view id
         *      dialogTpl: 'xxx', // search dialog template path
         *      count: 7, // records per page
         *      initScope: function(scope){}, // scope initializing function
         *      initForm: function(){ return {};}, // form initializing function
         *      searchApi: function(queryObj, onSuccess, onFailure){ return $promise;}, // search function, should return promise
         *      showPageCounter: false,
         *      formToQuery: function(form){ return transform(form);} // form to query object transform function
         *  }
         */
        function SearchController() {
            var self = this;
            var page = 1;
            var autoload = true;
            var wasSearched = false;
            var createForm = function () {
                return {};
            };
            var formToQuery = function () {
                var query = {};
                return query;
            };
            var count, onlySimple, dialogTpl, initScope, searchFormSaver, scope, modal, initForm;
            if (arguments.length === 1) {
                var params = arguments[0];
                this.viewId = params.viewId;
                count = params.count || 7;
                dialogTpl = params.dialogTpl;
                initScope = params.initScope || angular.noop;
                searchFormSaver = new SearchFormSaver(this.viewId);
                this.initForm = params.initForm || createForm;
                this.searchApiFunc = params.searchApi || angular.noop;
                this.pageCounter = params.showPageCounter ? {} : undefined;
                this.formToQuery = params.formToQuery || formToQuery;
                autoload = params.autoLoad === undefined || params.autoLoad === null ? true : params.autoLoad;
                this._handleSearch = params._handleSearch;
                this._handleResult = params._handleResult;
                this.disableQuery = params.disableQuery || false;
            } else {
                this.viewId = arguments[0];
                count = arguments[2] || 7;
                dialogTpl = arguments[1];
                initScope = arguments[3] || angular.noop;
                searchFormSaver = new SearchFormSaver(this.viewId);
                this.initForm = arguments[4] || createForm;
                this.searchApiFunc = arguments[5] || angular.noop;
                this.pageCounter = arguments[6] ? {} : undefined;
                this.disableQuery = arguments[7] || false;
            }

            onlySimple = dialogTpl ? false : true;

            this._form = {};
            this._scope = {};
            this._list = [];
            this._total = 0;
            this._loading = false;

            this.isOnlySimple = function () {
                return onlySimple;
            };

            this.autoLoad = function () {
                return autoload;
            };

            this.wasSearched = function (param) {
                if (param !== undefined && param !== null) {
                    wasSearched = param;
                }
                return wasSearched;
            };

            this.getSearchFormSaver = function () {
                return searchFormSaver;
            };

            this.getPage = function () {
                return page;
            };

            this.setPage = function (currentPage) {
                page = currentPage;
            };

            this.nextPage = function () {
                page++;
            };

            this.resetPage = function () {
                page = 1;
            };

            this.setCount = function (countPerPage) {
                count = countPerPage;
            };

            this.getCount = function () {
                return count;
            };

            this.getModal = function () {
                return modal;
            };

            this.setForm = function (form) {
                this._form = form;
                if (!onlySimple) {
                    scope.form = form;
                }
            };

            if (!onlySimple) {
                scope = $rootScope.$new(true);
                scope.form = this._form;
                modal = $modal({
                    scope: scope,
                    title: "",
                    contentTemplate: dialogTpl,
                });
                scope.searchAdvanced = function () {
                    self.searchAdvanced();
                    modal.hide();
                };
                scope.clean = function () {
                    self.clear();
                };

                scope.clear = function () {
                    self.clear();
                };

                initScope(scope);
                this._scope = scope;
            }

            if (searchFormSaver.isActiveSearch()) {
                this.setForm(searchFormSaver.localLoad());
            } else {
                self.setForm(this.initForm(this._form));
            }
        }

        SearchController.prototype = {
            setPagingInfo: function (query) {
                query.page = this.getPage();
                query.count = this.getCount();
            },
            handleResult: function (result) {
                var self = this;

                if (!this._handleResult || this._handleResult(result)) {
                    this._list = [];
                    this._total = result.total;

                    angular.forEach(result.items, function (item) {
                        self._list.push(item);
                    });

                    if (self.pageCounter) {
                        self.pageCounter.count = self.getCount();
                        self.pageCounter.total = Math.ceil(result.total / self.getCount());
                        self.pageCounter.current = self.getPage();
                        self.pageCounter.size = self._total;
                    }
                }
            },
            handleError: function (error) {},
            handleFinally: function () {
                FormLoaderService.endFormLoading();
                this._loading = false;
                this.wasSearched(true);
            },
            handleSearch: function (append) {
                FormLoaderService.startFormLoading();
                this._loading = true;
                var query = {};

                if (!this.disableQuery) {
                    if (append) {
                        query = this._query;
                    } else {
                        query = this.formToQuery(this._form);
                        this._query = query;
                        // (query as any)._searchType = undefined;
                    }

                    this.setPagingInfo(query);
                }

                if (
                    !this._handleSearch ||
                    this._handleSearch(
                        query,
                        this.handleResult.bind(this),
                        this.handleError.bind(this),
                        this.handleFinally.bind(this)
                    )
                ) {
                    let res = this.getSearchFunc(this._form)(
                        query,
                        this.handleResult.bind(this),
                        this.handleError.bind(this)
                    );

                    res.$promise.finally(this.handleFinally.bind(this));
                }

                this.handleFinally.bind(this);
                if (this._form.filter) $rootScope.filterType = this._form.filter.title;
            },
            hasMore: function () {
                return this._total > this._list.length || !this.wasSearched();
            },
            loadMore: function () {
                this._loading = true;
                if (this.wasSearched()) {
                    this.nextPage();
                }
                this.handleSearch(true && this.wasSearched());
            },
            search: function () {
                FormLoaderService.startFormLoading();
                this._loading = true;
                if (this._form._searchType === "all") {
                    this.getSearchFormSaver().removeSaved();
                } else {
                    this.getSearchFormSaver().saveSearch(this._form);
                }
                this.resetPage();
                this._total = 0;
                this._list = [];
                this.handleSearch(false);
            },
            searchFilter: function () {
                this._form._searchType = "filter";
                this.search();
            },
            searchQuick: function () {
                this._form._searchType = "quick";
                this.search();
            },
            searchLastOrAll: function () {
                this._form._searchType = this._form._searchType || "all";
                if (this.wasSearched() || this._form._searchType !== "all" || this.autoLoad()) {
                    this.search();
                } else {
                    FormLoaderService.endFormLoading();
                }
            },
            searchAdvanced: function () {
                this._form._searchType = "advanced";
                this.search();
            },
            clear: function () {
                this.setForm(this.initForm());
            },
            clean: function () {
                this.clear();
            },
            clearAndSearch: function (params) {
                this.setForm(this.initForm());
                this.search(params);
            },
            showModal: function () {
                this.getModal().show();
            },
            getSearchFunc: function (form) {
                return this.searchApiFunc;
            },
            addQueryToForm: function (query) {
                this._form.q = query;
            },
        };
        return SearchController;
    },
]);
