ng1App.directive('nhrCertificateTasks', 
    ['TasksApiFactory',
     function (TasksApiFactory) {
	return {
		templateUrl: '/templates/portal/doctor/patient/certificates/layout/nhr-dp-cer-tasks.html',
		scope: {
			certificateId: '@'
		},
		restrict: 'E', 
		replace: false,
		link: function (scope: any, element, attrs) {
			scope.closed = attrs.closed == 'true';
      
			scope.tasks = {};
			scope.dataLoaded = false;
      
			scope.$on('widget_toggle', function (event, data) {
				if (!scope.dataLoaded) {
					// Load data
					scope.tasks = TasksApiFactory.getByCompositionId({compositionId: scope.certificateId}, afterLoadingEnded);
				}
			});
      
			var afterLoadingEnded = function () {
				scope.dataLoaded = true;
			};
		}
	};
}]);