import {ConfigService} from "../app/config/config.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DocRefConsentTemplateModel} from "../app/shared/models/doc-ref-consent-template.model";
import {FilteredDataModel} from "../app/shared/models/filtered-data.model";
import {QueryModel} from "../app/shared/models/query.model";
import {DocRefConsentCategoryModel} from "../app/shared/models/doc-ref-consent-category.model";
import {FilterGroupModel} from "../app/shared/models/filter-group.model";
import {DocRefConsentModel} from "../app/shared/models/doc-ref-consent.model";
import {DocRefConsentFilterModel} from "../app/shared/models/doc-ref-consent-filter.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2DocRefConsentTemplate extends ConfigService {

    path = this.apiUrl + '/doc-ref-consent-template';

    getDocRefConsentTemplatesList(query?: QueryModel): Observable<DocRefConsentTemplateModel[] | FilteredDataModel> {
        if (query) {
            return this.http.get<FilteredDataModel>(this.path + '/list', {params: this.getFilterParams(query)});
        }
        return this.http.get<DocRefConsentTemplateModel[]>(this.path + '/list');
    }

    getDocRefConsentTemplate(id: string): Observable<DocRefConsentTemplateModel> {
        return this.http.get<DocRefConsentTemplateModel>(this.path + '/' + id);
    }

    createDocRefConsentTemplate(docRefConsentTemplate: DocRefConsentTemplateModel): Observable<DocRefConsentTemplateModel> {
        return this.http.post<DocRefConsentTemplateModel>(this.path + '/create/', docRefConsentTemplate);
    }

    editDocRefConsentTemplate(docRefConsentTemplate: DocRefConsentTemplateModel): Observable<DocRefConsentTemplateModel> {
        return this.http.put<DocRefConsentTemplateModel>(this.path + '/edit/' + docRefConsentTemplate.id, docRefConsentTemplate);
    }

    getCategories(): Observable<DocRefConsentCategoryModel[]> {
        return this.http.get<DocRefConsentCategoryModel[]>(this.path + '/categories');
    }

    getFilters(): Observable<DocRefConsentFilterModel> {
        return this.http.get<DocRefConsentFilterModel>(this.path + '/filters');
    }
}