angular.module('medvais.api').factory('MedvaisOrderApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/order';
    
    return $resource('', {}, {
        
        orderSendStudy: {
            method: 'POST',
            url: URL_BASE + '/send/study'
        },
        
        orderTelemedicine: {
            method: 'POST',
            url: URL_BASE + '/telemedicine'
        },
        
        getForE027VA: {
            method: 'GET',
            url: URL_BASE + '/forE027VA?patientId=:patientId&organizationId=:organizationId',
            isArray: true
        },
        
        getTelemedicineOrdersWithoutEncounter: {
            method: 'GET',
            url: URL_BASE + '/telemedicine/orders/without-encounter',
            isArray: true
        },

        getTelemedicineOrdersList: {
            method: 'GET',
            url: URL_BASE + '/telemedicine/list'
        }
        
    });
    
    
}]);

