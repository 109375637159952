ng1App.controller('doctor.patient.AllergiesCtrl', 
    ['$scope', '$filter', '$routeParams', '$modal', 'PatientsApiFactory', 'EntitiesApiFactory', '$window', 'MessageService', 'FormLoaderService',
     function ($scope, $filter, $routeParams, $modal, PatientsApiFactory, EntitiesApiFactory, $window, MessageService, FormLoaderService) {
    FormLoaderService.startFormLoading();
    $scope.$emit('setHelpPage', 'perzireti-paciento-alergiju-sarasa');

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();
    
    $scope.form = {q:''};
    $scope.orderedAllergies = [];
    $scope.orderType = 'byCode';
    $scope.filterType = 'all';
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    //load allergies
    var promise = PatientsApiFactory.getAllergiesForList({id: $routeParams.patientId, count: 100}, function(data) {
        $scope.allergies = data;
        var preparedAllergies = getPreparedAllergiesForOrdering();
        $scope.hasAllergyItemsForView = preparedAllergies.length > 0;
        orderAllergies(preparedAllergies);
    }).$promise;
    
    FormLoaderService.endFormLoading(promise);

    //filters

    var filterAllergiesByAdvancedSearchAllergies = function(allergyItem) {
        return $filter('filter')($scope.selectedAllergies,
            {
                allergy : {
                    code : allergyItem.allergy ? allergyItem.allergy.code : '',
                    name : allergyItem.allergy ? allergyItem.allergy.name : ''
                }
            }, true).length > 0 ? true : false;
    };

    var filterAllergiesByAdvancedSearchPractitioners = function(allergyItem) {
        return $filter('filter')($scope.selectedPractitioners, {personalCode : allergyItem.relatedDoc && allergyItem.relatedDoc.practitioner ? allergyItem.relatedDoc.practitioner.personalCode : null}, true)[0] ? true : false;
    };

    var filterAllergiesByAdvancedSearchOrganizations = function(allergyItem) {
        return $filter('filter')($scope.selectedOrganizations, {jarCode : allergyItem.relatedDoc && allergyItem.relatedDoc.organization ? allergyItem.relatedDoc.organization.jarCode : null}, true)[0] ? true : false;
    };

    var filterAllergiesByAdvancedSearchDocs = function(allergyItem) {
        if($scope.advancedSearch.docE003.selected && !$scope.advancedSearch.docE025.selected) {
            return upperCase(allergyItem.relatedDoc.docType) == 'E003';
        }
        if(!$scope.advancedSearch.docE003.selected && $scope.advancedSearch.docE025.selected) {
            return upperCase(allergyItem.relatedDoc.docType) == 'E025';
        }
        return true;
    };

    var filterAllergiesByAdvancedSearchOccurDate = function(allergyItem) {
        if($scope.advancedSearch.sOccurDate.date && $scope.advancedSearch.eOccurDate.date) {
            return allergyItem.date >= $scope.advancedSearch.sOccurDate.milis && allergyItem.date <= $scope.advancedSearch.eOccurDate.milis;
        } else if($scope.advancedSearch.sOccurDate.date) {
            return allergyItem.date >= $scope.advancedSearch.sOccurDate.milis;
        } else {
            return allergyItem.date <= $scope.advancedSearch.eOccurDate.milis;
        }
    };

    var filterAllergiesByAdvancedSearchServiceDate = function(allergyItem) {
        if(allergyItem.relatedDoc){
            var date = Date.parse(allergyItem.relatedDoc.date);
            if($scope.advancedSearch.sServiceDate.date && $scope.advancedSearch.eServiceDate.date) {
                return date >= $scope.advancedSearch.sServiceDate.milis && date <= $scope.advancedSearch.eServiceDate.milis;
            } else if($scope.advancedSearch.sServiceDate.date) {
                return date >= $scope.advancedSearch.sServiceDate.milis;
            } else {
                return date <= $scope.advancedSearch.eServiceDate.milis;
            }
        }
    };

    //Watch functions

    var advancedSearchStatusWatchFunction = function() {
        if ($scope.advancedSearch && $scope.advancedSearch.status) {
            $scope.advancedSearch.allergies = orderList($scope.allergies, 'allergy.code');
            if($scope.advancedSearch.status.confirmed && !$scope.advancedSearch.status.refuted) {
                $scope.advancedSearch.allergies = $filter('filter')($scope.advancedSearch.allergies, {status: 'confirmed'});
            }
            else if(!$scope.advancedSearch.status.confirmed && $scope.advancedSearch.status.refuted){
                $scope.advancedSearch.allergies = $filter('filter')($scope.advancedSearch.allergies, {status: 'refuted'});
            }
            if($scope.advancedSearch.input && $scope.advancedSearch.input.allergies) {
                filterAdvancedSearchAllergiesByText($scope.advancedSearch.input.allergies);
            }
            $scope.makeAdvancedSearchAllergiesUnique();
        }
    };

    var advancedSearchDocumentWatchFunction = function(changed) {
        if(changed) {
            $scope.advancedSearch.docE003 = changed[0];
            $scope.advancedSearch.docE025 = changed[1];
            if ($scope.advancedSearch.docE003.selected && $scope.advancedSearch.docE025.selected || !$scope.advancedSearch.docE003.selected && !$scope.advancedSearch.docE025.selected) {
                $scope.advancedSearch.practitioners = orderList(getAllergiesPractitioners(), ['givenName', 'familyName']);
                $scope.advancedSearch.organizations = orderList(getAllergiesOrganizations(), 'name');
            }
            else if($scope.advancedSearch.docE003.selected) {
                    $scope.advancedSearch.practitioners = orderList(getAllergiesPractitioners($scope.advancedSearch.docE003), ['givenName', 'familyName']);
                    $scope.advancedSearch.organizations = orderList(getAllergiesOrganizations($scope.advancedSearch.docE003), 'name');
            }
            else {
                    $scope.advancedSearch.practitioners = orderList(getAllergiesPractitioners($scope.advancedSearch.docE025), ['givenName', 'familyName']);
                    $scope.advancedSearch.organizations = orderList(getAllergiesOrganizations($scope.advancedSearch.docE025), 'name');
            }
            if($scope.advancedSearch.input && $scope.advancedSearch.input.practitioners) {
                filterAdvancedSearchPractitionersByText($scope.advancedSearch.input.practitioners);
            }
            if($scope.advancedSearch.input && $scope.advancedSearch.input.organizations) {
                filterAdvancedSearchOrganizationsByText($scope.advancedSearch.input.organizations);
            }
            $scope.makeAdvancedSearchListsByDocUnique();
        }
    };

    //Watchers

    $scope.$watch('[orderType, filterType]', function(val) {
        if($scope.orderType && $scope.filterType && !$scope.filterTypeWatchDisabled && $scope.allergies) {
            $scope.form.q = '';
            $scope.orderedAllergies = [];
            var preparedAllergies = getPreparedAllergiesForOrdering();
            $scope.hasAllergyItemsForView = preparedAllergies.length > 0;
            orderAllergies(preparedAllergies);
        }
        if($scope.filterTypeWatchDisabled) {
            $scope.filterTypeWatchDisabled = false;
        }
    }, true);

    $scope.$watch('[advancedSearch.status.confirmed, advancedSearch.status.refuted]', advancedSearchStatusWatchFunction, true);

    $scope.$watch('advancedSearch.docs', advancedSearchDocumentWatchFunction, true);

    $scope.$watch('advancedSearch.input.allergies', function (newValue, oldValue) {
        if($scope.advancedSearch && $scope.advancedSearch.allergies) {
            if(oldValue && oldValue.length > newValue.length) {
                advancedSearchStatusWatchFunction();
            }
            else {
                filterAdvancedSearchAllergiesByText(newValue);
            }
        }
    }, true);

    $scope.$watch('advancedSearch.input.practitioners', function (newValue, oldValue) {
        if($scope.advancedSearch && $scope.advancedSearch.practitioners) {
            advancedSearchDocumentWatchFunction($scope.advancedSearch.docs);
        }
    }, true);

    $scope.$watch('advancedSearch.input.organizations', function (newValue, oldValue) {
        if($scope.advancedSearch && $scope.advancedSearch.organizations) {
            advancedSearchDocumentWatchFunction($scope.advancedSearch.docs);
        }
    }, true);

    // util functions

    function orderList(listToOrder, predicate) {
        return $filter('orderBy')(listToOrder, predicate);
    }

    function createAllergyItemForList(title, allergyItem) {
        return {
            title : title,
            allergies: [allergyItem]
        }
    }

    function addAllergyItemForList(title, allergy) {
        var existingAllergyItem = $filter('filter')($scope.orderedAllergies, {title: title})[0];
        if(existingAllergyItem) {
            existingAllergyItem.allergies.push(allergy);
        }
        else {
            var allergyItem = createAllergyItemForList(title, allergy);
            $scope.orderedAllergies.push(allergyItem);
        }
    }

    function getPreparedAllergiesForOrdering() {
        if($scope.filterType === 'all') {
            return $scope.allergies;
        }
        else {
            return $filter('filter')($scope.allergies, {status: $scope.filterType});
        }
    }

    function orderAllergies(preparedAllergies) {
        switch ($scope.orderType) {
            case 'byStatus':
                $scope.orderAllergiesByStatus(preparedAllergies);
                break;
            case 'byName':
                $scope.orderAllergiesByName(preparedAllergies);
                break;
            case 'byDate':
                $scope.orderAllergiesByDate(preparedAllergies);
                break;
            case 'byCode':
                $scope.orderAllergiesByCode(preparedAllergies);
                break;
        }
    }

    function upperCase(text) {
        return $filter('uppercase')(text);
    }

    function escapeSearchPhrase(text) {
        return text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }
    //gets all allergy practitioners or by doc provided
    function getAllergiesPractitioners(doc?) {
        var practitioners = [];
        var ids = {};
        angular.forEach($scope.allergies, function(allergy) {
            if(!allergy.relatedDoc || !allergy.relatedDoc.practitioner){
                return;
            }
            if(doc && doc.code == upperCase(allergy.relatedDoc.docType) && !(allergy.relatedDoc.practitioner.id in ids)) {
                practitioners.push(allergy.relatedDoc.practitioner);
                ids[allergy.relatedDoc.practitioner.id] = {};
            }
            else if(!doc && allergy.relatedDoc && !(allergy.relatedDoc.practitioner.id in ids)) {
                practitioners.push(allergy.relatedDoc.practitioner);
                ids[allergy.relatedDoc.practitioner.id] = {};
            }
        });

        return practitioners;
    }
    //gets all allergy organizations or by doc provided
    function getAllergiesOrganizations(doc?) {
        var organizations = [];
        var ids = {};
        angular.forEach($scope.allergies, function(allergy) {
            if(doc && allergy.relatedDoc && doc.code == upperCase(allergy.relatedDoc.docType) && !(allergy.relatedDoc.organization.id in ids)) {
                organizations.push(allergy.relatedDoc.organization);
                ids[allergy.relatedDoc.organization.id] = {};
            }
            else if(!doc && allergy.relatedDoc && !(allergy.relatedDoc.organization.id in ids)) {
                organizations.push(allergy.relatedDoc.organization);
                ids[allergy.relatedDoc.organization.id] = {};
            }
        });

        return organizations;
    }

    $scope.openRelatedDocument = function(documentId) {
        var url = '/dp/patients/' + $routeParams.patientId + '/documents/' + documentId;
        $window.location.href = url;
    };

    $scope.orderAllergiesByDate = function(preparedAllergies) {
        var orderedAllergies = orderList(preparedAllergies, ['-date', 'allergy.code']);
        angular.forEach(orderedAllergies, function(allergy) {
            addAllergyItemForList($filter('date')(allergy.date, 'yyyy-MM-dd'), allergy);
        })
    };

    $scope.orderAllergiesByCode = function(preparedAllergies) {
        var orderedAllergies = orderList(preparedAllergies, 'allergy.code');
        angular.forEach(orderedAllergies, function(allergy) {
            if(allergy.allergy){
                addAllergyItemForList(allergy.allergy.code[0], allergy);
            }
        })
    };

    $scope.orderAllergiesByName = function(preparedAllergies) {
        var orderedAllergies = orderList(preparedAllergies, 'allergy.name');
        angular.forEach(orderedAllergies, function(allergy) {
            addAllergyItemForList(allergy.allergy.name[0], allergy);
        })
    };

    $scope.orderAllergiesByStatus = function(preparedAllergies) {
        var orderedAllergies = orderList(preparedAllergies, ['status', 'allergy.code']);
        angular.forEach(orderedAllergies, function(allergy) {
            addAllergyItemForList(allergy.status, allergy);
        })
    };

    $scope.checkAll = function(listTocheck) {
        angular.forEach(listTocheck, function (item) {
            item.selected = true;
        });
    };

    $scope.uncheckAll = function(listToUncheck) {
        angular.forEach(listToUncheck, function (item) {
            item.selected = false;
        });
    };

    // search

    var AdvancedSearchAllergiesRegexTest = function(item, regex, filteredItems){
        if(regex.test(upperCase(item.allergy.code) + ' ' + upperCase(item.allergy.name))) {
            filteredItems.push(item);
        }
    };

    var AdvancedSearchPractitionersRegexTest = function(item, regex, filteredItems){
        if(regex.test(upperCase(item.givenName) + ' ' + upperCase(item.familyName))) {
            filteredItems.push(item);
        }
    };

    var AdvancedSearchOrganizationsRegexTest = function(item, regex, filteredItems){
        if(regex.test(upperCase(item.name))) {
            filteredItems.push(item);
        }
    };

    function filterAdvancedSearchAllergiesByText(searchText) {
        $scope.advancedSearch.allergies = filterListByRegExp($scope.advancedSearch.allergies, AdvancedSearchAllergiesRegexTest, searchText);
    }

    function filterAdvancedSearchPractitionersByText(searchText) {
        var filteredByStampCode = $filter('filter')($scope.advancedSearch.practitioners, {stampCode : searchText}, true);
        if(filteredByStampCode.length > 0) {
            $scope.advancedSearch.practitioners = filteredByStampCode;
        }
        else {
            $scope.advancedSearch.practitioners = filterListByRegExp($scope.advancedSearch.practitioners, AdvancedSearchPractitionersRegexTest, searchText);
        }
    }

    function filterAdvancedSearchOrganizationsByText(searchText) {
        var filteredByJarCode = $filter('filter')($scope.advancedSearch.organizations, {jarCode : searchText}, true);
        if(filteredByJarCode.length > 0) {
            $scope.advancedSearch.organizations = filteredByJarCode;
        }
        else {
            $scope.advancedSearch.organizations = filterListByRegExp($scope.advancedSearch.organizations, AdvancedSearchOrganizationsRegexTest, searchText);
        }
    }

    $scope.clearSearch = function() {
        $scope.form.q = '';
        $scope.advancedSearch = null;
        $scope.orderedAllergies = [];
        if($scope.filterType != 'all') {
            $scope.filterTypeWatchDisabled = true;
            $scope.filterType = 'all';
        }
        var preparedAllergies = getPreparedAllergiesForOrdering();
        $scope.hasAllergyItemsForView = preparedAllergies.length > 0;
        orderAllergies(preparedAllergies);
    };

    function advancedSearchFilterByAllergies(allergies) {
        if($scope.selectedAllergies.length > 0) {
            allergies = $filter('filter')(allergies, filterAllergiesByAdvancedSearchAllergies);
        }
        return allergies;
    }

    function advancedSearchFilterByDoc(allergies) {
        allergies = $filter('filter')(allergies, filterAllergiesByAdvancedSearchDocs);
        if($scope.selectedPractitioners.length > 0) {
            allergies = $filter('filter')(allergies, filterAllergiesByAdvancedSearchPractitioners);
        }
        if($scope.selectedOrganizations.length > 0) {
            allergies = $filter('filter')(allergies, filterAllergiesByAdvancedSearchOrganizations);
        }
        return allergies;
    }

    function advancedSearchFilterByDate(sDate, eDate, allergies, dateFilter) {
        if(sDate.date || eDate.date) {
            if(sDate.date) {
                sDate.milis = Date.parse(sDate.date);
            }
            if(eDate.date) {
                //include last interval day
                eDate.milis = Date.parse(eDate.date) + 86400000;
            }
            allergies = $filter('filter')(allergies, dateFilter);
        }
        return allergies;
    }

    function changeAllergySearchStatus() {
        var filterType = null;
        if($scope.advancedSearch.status.confirmed && $scope.advancedSearch.status.refuted || !$scope.advancedSearch.status.confirmed && !$scope.advancedSearch.status.refuted) {
            filterType = 'all';
        }
        else if($scope.advancedSearch.status.confirmed) {
            filterType = 'confirmed';
        }
        else if($scope.advancedSearch.status.refuted) {
            filterType = 'refuted';
        }
        if($scope.filterType != filterType) {
            $scope.filterTypeWatchDisabled = true;
            $scope.filterType = filterType;
        }
    }

    $scope.simpleSearch = function() {
        $scope.orderedAllergies = [];
        var filteredAllergies = filterListByRegExp(getPreparedAllergiesForOrdering(), simpleSearchRegexTest, $scope.form.q);
        $scope.hasAllergyItemsForView = filteredAllergies.length > 0;
        orderAllergies(filteredAllergies);
    };

    var simpleSearchRegexTest = function(item, regex, filteredItems){
        var date = $filter('date')(item.date, 'yyyy-MM-dd');
        if(regex.test(upperCase(item.allergy.code) + ' ' + upperCase(item.allergy.name)) || regex.test(date)) {
            filteredItems.push(item);
        }
    };

    function filterListByRegExp(listToFilter, regexTestFunction, text) {
        var filteredItems = [];
        var pattern = ".*" + escapeSearchPhrase(upperCase(text)) + ".*";
        var regExp = new RegExp(pattern);
        angular.forEach(listToFilter, function(item) {
            regexTestFunction(item, regExp, filteredItems);
        });
        return filteredItems;
    }

    var modal = $modal({
        scope: $scope,
        title: 'dpp.mod.all', //'Išplėstinė paieška',
        contentTemplate: '/templates/portal/doctor/patient/allergies/search_modal.html'
    });

    $scope.search = function () {
        $scope.cancelErr = 0;
        $scope.documentCancelReason = {};
        if(!$scope.advancedSearch) {
            $scope.initAdvancedSearch();
        }
        modal.$promise.then(function () {
            modal.show();
        });
    };

    function applyAdvancedSearch() {
        var allergies = getPreparedAllergiesForOrdering();
        $scope.selectedAllergies = $filter('filter')(getPreparedAllergiesForOrdering(), {selected : true});
        $scope.selectedPractitioners = $filter('filter')(getAllergiesPractitioners(), {selected : true});
        $scope.selectedOrganizations = $filter('filter')(getAllergiesOrganizations(), {selected : true});
        allergies = advancedSearchFilterByAllergies(allergies);
        allergies = advancedSearchFilterByDoc(allergies);
        allergies = advancedSearchFilterByDate($scope.advancedSearch.sOccurDate, $scope.advancedSearch.eOccurDate, allergies, filterAllergiesByAdvancedSearchOccurDate);
        allergies = advancedSearchFilterByDate($scope.advancedSearch.sServiceDate, $scope.advancedSearch.eServiceDate, allergies, filterAllergiesByAdvancedSearchServiceDate);

        return allergies;
    }

    $scope.performAdvancedSearch = function() {
        $scope.orderedAllergies = [];
        changeAllergySearchStatus();
        var filteredAllergies = applyAdvancedSearch();
        $scope.hasAllergyItemsForView = filteredAllergies.length > 0;
        orderAllergies(filteredAllergies);
        modal.$promise.then(function () {
            modal.hide();
        });
    };

    function uncheckAdvancedForm() {
        $scope.uncheckAll($scope.allergies);
        $scope.uncheckAll(getAllergiesPractitioners());
        $scope.uncheckAll(getAllergiesOrganizations());
        $scope.uncheckAll($scope.advancedSearch.docs);
    }

    $scope.cleanAdvancedForm = function() {
        cleanAdvancedSearchFormFields();
        $scope.advancedSearch.allergies = orderList($scope.allergies, 'allergy.code');
        $scope.advancedSearch.practitioners = orderList(getAllergiesPractitioners(), ['givenName', 'familyName']);
        $scope.advancedSearch.organizations = orderList(getAllergiesOrganizations(), 'name');
        $scope.makeAdvancedSearchAllergiesUnique();
        $scope.makeAdvancedSearchListsByDocUnique();
        uncheckAdvancedForm();
    };

    $scope.makeAdvancedSearchAllergiesUnique = function() {
        $scope.advancedSearch.allergies = $filter('unique')($scope.advancedSearch.allergies, 'allergy');
    };

    $scope.makeAdvancedSearchListsByDocUnique = function() {
        $scope.advancedSearch.practitioners = $filter('unique')($scope.advancedSearch.practitioners, 'stampCode');
        $scope.advancedSearch.organizations = $filter('unique')($scope.advancedSearch.organizations, 'jarCode');
    };

    function cleanAdvancedSearchFormFields() {
        $scope.advancedSearch.status = {
            confirmed : false,
            refuted : false
        };
        $scope.advancedSearch.sOccurDate = {};
        $scope.advancedSearch.eOccurDate = {};
        $scope.advancedSearch.sServiceDate = {};
        $scope.advancedSearch.eServiceDate = {};
        $scope.advancedSearch.docE003.selected = false;
        $scope.advancedSearch.docE025.selected = false;
        $scope.advancedSearch.input = {
            allergies : '',
            practitioners : '',
            organizations : ''
        }
    }

    function setAdvancedSearchFrom() {
        $scope.advancedSearch = {
            status : {
                confirmed : false,
                refuted : false
            },
            sOccurDate : {},
            eOccurDate : {},
            sServiceDate : {},
            eServiceDate : {},
            docE003 : {
                selected : false
            },
            docE025 : {
                selected : false
            }
        }
    }

    $scope.initAdvancedSearch = function() {
        var filterDocs = function(doc) {
            return doc.code == 'E025' || doc.code == 'E003';
        };
        setAdvancedSearchFrom();
        EntitiesApiFactory.getList({classCode: "data-set"}, function(data) {
            $scope.advancedSearch.docs = $filter('filter')(data, filterDocs);
        });
        $scope.advancedSearch.allergies = orderList($scope.allergies, 'allergy.code');
        $scope.advancedSearch.practitioners = orderList(getAllergiesPractitioners(), ['givenName', 'familyName']);
        $scope.advancedSearch.organizations = orderList(getAllergiesOrganizations(), 'name');
        uncheckAdvancedForm();
    };
    //-- for correct visual showing
    $scope.allergiesMatrix = [];
    $scope.listToMatrix = function (list, elementsPerSubArray) {
        var matrix = [], i, k;

        for (let i = 0, k = -1; i < list.length; i++) {
            if (i % elementsPerSubArray === 0) {
                k++;
                matrix[k] = [];
            }

            matrix[k].push(list[i]);
        }

        return matrix;
    };
    
    $scope.$watch('orderedAllergies', function() {
        $scope.allergiesMatrix  =  $scope.listToMatrix($scope.orderedAllergies, 3);
    }, true); 

}]);