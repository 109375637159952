/* global app */

ng1App.factory('PatientSummaryApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/patient-summary';
    return $resource('', {}, {
        getSummary: {
            method: 'GET',
            url: URL_BASE + '/:patientId/summary'
        },
        saveSummary: {
            method: 'POST',
            url: URL_BASE + '/:patientId/summary'
        },
        saveDraft: {
            method: 'POST',
            url: URL_BASE + '/:patientId/summary/draft'
        },
        updateDraft: {
            method: 'PUT',
            url: URL_BASE + '/:patientId/summary/draft/:draftId'
        },
        getDraft: {
            method: 'GET',
            url: URL_BASE + '/:patientId/summary/draft/:draftId'
        },
        getDraftForEdit: {
            method: 'GET',
            url: URL_BASE + '/:patientId/summary/draft/:draftId/edit'
        },
        getSummaryHistory: {
            method: 'GET',
            url: URL_BASE + '/:patientId/summary/history/:historyId'
        },
        getSummaryHistoryLast: {
            method: 'GET',
            url: URL_BASE + '/:patientId/summary/history/last'
        },
        hasSummary: {
            method: 'GET',
            url: URL_BASE + '/:patientId/has-summary'
        }
    });

}]);