import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { generateSelectionValuesFromEntity } from '../../../e096/e096-utils/utils';

export interface E030GeneralDocumentData {
    date: string,
    eventId: string,
    documentId: string,
    status: string,
    showToPatient?: boolean
}
@Component({
    selector: 'app-e030-general-data',
    templateUrl: './e030-general-data.component.html',
})
export class E030GeneralDataComponent implements OnInit {
    formGroup: FormGroup;
    statuses: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Preliminary'
        },
        {
            id: '1',
            code: '1',
            name: 'Final'
        },
        {
            id: '2',
            code: '2',
            name: 'Amended'
        },
        {
            id: '0',
            code: '0',
            name: 'Entered in error'
        }
    ];
    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            date: this.fb.control(null, [Validators.required]),
            eventId: this.fb.control(null, [Validators.required]),
            documentId: this.fb.control(null, [Validators.required]),
            status: this.fb.control(null, [Validators.required])
        });
    }
}
