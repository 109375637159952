<div class="d-block p-2_5 text-body mouse-pointer" tabindex="0" (click)="onPractitionerClick()" (keypress)="onPractitionerClick()">
    <small class="text-secondary d-block">
        <i class="fa-solid fa-stethoscope"></i>
        {{ 'dp.pat.enc.inf.reg' | translate }}
    </small>
    <strong class="card-text fs-3 mb-1">
        {{ _.getFullName(practitioner) | capitalize }}
    </strong>
    <p class="mb-2 lh-sm">
        {{ practitioner.speciality?.name }}{{ ' (' + ('stampNr' | translate) + ' ' }}{{ practitioner.stampCode }}{{ ')' }}
        <br />
        {{ organization.name }}
    </p>
    <p class="card-text" *ngIf="practitioner.phones?.length > 0">
        <a ng-repeat="phone in practitioner.phones" href="tel:{{ phone }}" class="lh-1 d-flex gap-2 align-items-center d-inline-flex" aria-label="{{ phone }}">
            <span class="btn btn-sm btn-square btn-square-sm btn-secondary">
                <i class="fa-regular fa-phone"></i>
            </span>
            <strong class="text-primary">
                {{ phone }}
            </strong>
        </a>
    </p>
    <p class="card-text">
        <a href="tel:123456789" class="lh-1 d-flex gap-2 align-items-center d-inline-flex" aria-label="123456789">
            <span class="btn btn-sm btn-square btn-square-sm btn-secondary">
                <i class="fa-regular fa-phone"></i>
            </span>
            <strong class="text-primary">
                123456789
            </strong>
        </a>
    </p>
</div>
