<app-title showFilterButton="true">
    {{ "Kiti dokumentai" | translate }}
</app-title>
<div class="row">
    <div class="col-sm-12">
        <div class="page-list list-group mb-2">
            <custom-search [name]="'quick_search'" [isAdvanced]="true" (filterService)="loadDocRefConsentList($event)">
                <attached-unsorted-search
                    (onSearch)="loadDocRefConsentList($event)"
                    [filtersGroup$]="filtersGroup$"
                />
            </custom-search>
            <doc-ref-attached-unsorted-card
                *ngFor="let docRefConsent of docRefConsents"
                [data]="docRefConsent"
                [onEditClick]="onEditClick"
                [onCardClick]="onCardClick"
                [portalType]="portalType"
                [patientId]="patientId"
                [ngClass]="'list-group-item'"
        />
    </div>
    <pagination-bar
        [rowsCount]="count"
        [pagesCount]="pagesCount"
        (paginating)="updatePagination($event)"
    ></pagination-bar>
</div>
