ng1App.directive('confirmOnExit', ['$filter', function ($filter) {
    return {
        restrict: 'A',
        require: 'form',
        link: function ($scope, elem, attrs, formController) {
            // condition when back page is pressed
            window.onbeforeunload = function () {
                if (formController && formController.$dirty) {
                    //onExit.confirm.messages
                    return $filter("translate")("onExit.confirm.message");
                }
            }
            // condition when user try to load other form (via icons )
            $scope.$on('$locationChangeStart', function (event, next, current) {
                if (formController && formController.$dirty) {
                    ////onExit.confirm.messages
                    if (!confirm(($filter("translate")("onExit.confirm.message")))) {
                        event.preventDefault();
                    }
                }
            });
        }
    };
}]);

ng1App.directive('nhrMsgLocal',
    ['MessageService',
     function (MessageService) {
    return {
        templateUrl: '/templates/layout/nhr-msg-local.html',
        restrict: 'E',
        replace: true,
        scope: {
            region: '=',
        },
        link: function (scope: any) {
            scope.getItems = function () {
                return scope.region ? MessageService.messages[scope.region] : [];
            };
            scope.close = function (index) {
                if (scope.region) {
                    MessageService.remove(index, scope.region);
                }
            };
        }
    };
}]);

ng1App.directive('nhrMsgIssueReport',
    ['MessageService', 'IssueReportsApiFactory', 'SessionApiFactory',
     function (MessageService, IssueReportsApiFactory, SessionApiFactory) {
    return {
        templateUrl: '/templates/layout/nhr-msg-issue-report.html',
        restrict: 'E', replace: true, link: function (scope: any, element, attrs) {
            scope.sending = false;
            scope.getReportableItems = function () {
                return MessageService.messages['global_error'];
            };
            scope.closeReportable = function (index) {
                MessageService.remove(index, 'global_error');
            };
            scope.send = function (index) {
                scope.sending = true;
                var msg = MessageService.messages['global_error'][index];
                IssueReportsApiFactory.sendIssueReport({
                    userFeedback: msg.userFeedback,
                    errorText: msg.options.errorResponse.error,
                    errorData: msg.options.errorResponse.errorData,
                    formData: JSON.stringify(msg.options.formData)
                }, function (data) {
                    MessageService.add('success', 'Klaida buvo sėkmingai užregistruota. Klaidos registracijos numeris: ' + data.issueId);
                    scope.closeReportable(index);
                    scope.sending = false;
                }, function (data) {
                    scope.sending = false;
                });
            };
        }
    };
}]);

ng1App.directive('nhrHpNavigation', function () {
    return {
        templateUrl: '/templates/layout/nhr-hp-navigation.html',
        restrict: 'E', replace: true, link: function (scope) {

        }
    };
});

ng1App.directive('nhrDevLayoutBadge', function () {
    return {
        templateUrl: '/templates/layout/nhr-dev-layout-badge.html',
        restrict: 'E', replace: true, link: function (scope) {

        }
    };
});

ng1App.directive('nhrHpSelectNavigation', function () {
    return {
        templateUrl: '/templates/layout/nhr-hp-select-navigation.html',
        restrict: 'E', replace: true, link: function (scope) {

        }
    };
});

ng1App.directive('nhrFooter',
    ['CommonFunctionService', 'gettextCatalog', '$routeSegment', '$cookies', 'footerLinks',
     function (CommonFunctionService, gettextCatalog, $routeSegment, $cookies, footerLinks) {
    return {
        templateUrl: '/templates/layout/nhr-footer.html',
        restrict: 'E', replace: true,
        link: function (scope: any, element, attrs) {
            scope.footerLinks = footerLinks;
            scope.goTo = CommonFunctionService.gotoAnchor;
            if (!$cookies.lang) {
                $cookies.lang = gettextCatalog.currentLanguage;
            }
            if (gettextCatalog.currentLanguage != $cookies.lang) {
                gettextCatalog.setCurrentLanguage($cookies.lang);
            }

            scope.currentLang = function () {
                return gettextCatalog.currentLanguage;
            };
            scope.switchLang = function (lang) {
                $cookies.lang = lang;
                gettextCatalog.setCurrentLanguage(lang);
                $routeSegment.chain[$routeSegment.chain.length - 1].reload();
            };
        }
    };
}]);

/**
 * @deprecated since migration to angular.
 * Now using nhr-widget.component.ts downgraded component.
 */
ng1App.directive('_nhrWidget', function () {
    return {
        templateUrl: '/templates/layout/nhr-widget.html',
        restrict: 'E', replace: true, scope: true, transclude: true,
        link: function (scope: any, element, attrs) {
            scope.color = attrs.color;
            scope.widgettitle = attrs.title;
            scope.closed = attrs.closed;
            scope.font = attrs.font;
            scope.iconClass = attrs.iconClass;
            scope.disabled = attrs.disabled;
            scope.amount = attrs.amount;
            scope.list = attrs.list;
            scope.mixed = attrs.mixed;
            scope.bodyClass = attrs.bodyClass;

            var observeAmount = function (value) {
                if (value > 0) {
                    scope.amount = value;
                    scope.badge = true;
                }
            };
            attrs.$observe('amount', observeAmount);

            scope.classDisabled = "";
            if (attrs.disabled == "true") {
                scope.classDisabled = "disabled";
            }
        }
    }
});

/**
 * @deprecated since migration to angular.
 * Now using nhr-record-widget.component.ts downgraded component.
 */
ng1App.directive('_nhrRecordWidget2',
    ['MessageService',
     function (MessageService) {
    return {
        templateUrl: '/templates/layout/nhr-record-widget.html',
        restrict: 'E', replace: true, scope: true, transclude: true,
        link: function (scope: any, element, attrs) {
            scope.closable = !attrs.closable || attrs.closable == 'true';
            scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'blue';
            scope.iconClass = attrs.iconClass;
            scope.font = attrs.font ? attrs.font : 'fa';
            scope.__title = attrs.title;
            scope.bodyClass = attrs.bodyClass ? attrs.bodyClass : 'form-horizontal';
            scope.msgRegion = attrs.msgRegion;
            scope.list = attrs.list;
            scope.mixed = attrs.mixed;

            scope._toggle = function () {
                if (scope.closable) {
                    scope.closed = !scope.closed;
                    scope.$emit('widget_toggle', scope.closed);
                    scope.$broadcast('widget_toggle', scope.closed);
                }
            };

            var observeClosed = function (newValue) {
                scope.closed = attrs.closed == 'true' || attrs.closed === true;
            };
            attrs.$observe('closed', observeClosed);

            var observeTitle = function (newValue) {
                scope.__title = newValue;
            };
            attrs.$observe('title', observeTitle);

            scope.getItems = function () {
                return MessageService.messages[scope.msgRegion];
            };
            scope.close = function (index) {
                MessageService.remove(index, scope.msgRegion);
            };
        }
    }
}]);
ng1App.directive('nhrInputDate', function () {
    return {
        templateUrl: '/templates/layout/nhr-input-date.html',
        restrict: 'E', replace: true, scope: true, transclude: true,
        link: function (scope, element, attrs) {
        }
    }

});
ng1App.directive('nhrInputTime', function () {
    return {
        templateUrl: '/templates/layout/nhr-input-time.html',
        restrict: 'E', replace: true, scope: true, transclude: true,
        link: function (scope, element, attrs) {
        }
    }

});
ng1App.directive('nhrAutoFocus',
    ['$timeout',
     function ($timeout) {
    return {
        restrict: 'AC',
        link: function (_scope, _element) {
            $timeout(function () {
                _element[0].focus();
            }, 0);
        }
    };
}]);
ng1App.directive('nhrAutoResize',
    ['$timeout',
     function ($timeout) {
    return {
        scope: {ngModel: '='},
        link: function (scope, element, attrs) {
            var elem = element[0],
                fakeElemStyles = 'position: absolute; top: -999px; left: 0; overflow: hidden; -webkit-box-sizing: content-box; border: 0; height: auto; box-sizing: border-box; visibility: hidden;',
                fakeElem = angular.element('<div class="fake" style="' + fakeElemStyles + '">x</div>'),
                stylesToCopy = ['border', 'border-top-width', 'border-right-width', 'border-bottom-width', 'border-left-width', 'font-size', 'font-style', 'font-weight', 'line-height', 'font-family', 'width', 'padding-left', 'padding-right', 'padding-top', 'padding-bottom'];
            if (!fakeElem[0].parentElement) {
                angular.element(document.body).append(fakeElem);
            }
            fakeElem.css('min-height', '34px');
            stylesToCopy.forEach(style => fakeElem.css(style, element.css(style)));
            elem.style.overflow = 'hidden';
            elem.style.height = 'auto';

            function onUpdate() {
                const lineHeight: string = window.getComputedStyle(elem).getPropertyValue('line-height');
                const height = lineHeight.endsWith('px') ?
                    fakeElem[0].scrollHeight + parseInt(lineHeight.replace('px', '')) + 'px' :
                    lineHeight;
                fakeElem.html(element.val().replace(/\n/g, "<br />"));
                elem.style.height = height
            }

            function onResize() {
                stylesToCopy.forEach(style => fakeElem.css(style, element.css(style)));
                onUpdate();
            }

            $timeout(onUpdate, 0);
            scope.$watch('ngModel', onUpdate);
            //element.on('blur keyup change', onUpdate);
            angular.element(window).on('resize', onResize);

            scope.$on('$destroy', function () {
                fakeElem.remove();
                angular.element(window).off('resize', onResize);
            });
        }
    };
}]);

ng1App.directive('nhrPractitionerInfo', function () {
    return {
        templateUrl: '/templates/layout/nhr-practitioner-info.html',
        restrict: 'E', replace: true, scope: {
            'practitioner': '=practitioner'
        }, transclude: true,
        link: function (scope: any, element, attrs) {
            scope._ = _;
        }
    }
});

ng1App.service('$modalConfirm',
    ['$modal', '$rootScope', '$q',
     function ($modal, $rootScope, $q) {
    var scope = $rootScope.$new();
    var deferred;
    scope.title = 'Dėmesio';
    scope.content = '';
    scope.confirmed = function (res) {
        deferred.resolve(res);
        confirm.hide();
    };

    var confirm = $modal({templateUrl: '/templates/layout/angular-strap/confirm.tpl.html', scope: scope, show: false});
    var parentShow = confirm.show;
    confirm.show = function (opts) {
        scope.title = opts.title ? opts.title : 'Dėmesio';
        scope.content = opts.content ? opts.content : '';
        deferred = $q.defer();
        parentShow();
        return deferred.promise;
    };

    return confirm;
}]);

ng1App.directive('nhrImageSize', function () {
    return {
        restrict: 'A',
        replace: false,
        link: function (scope, element, attrs) {
            var maxHeight = attrs.nhrImageSize;
            var minHeight = (element[0] as any).width;
            if (maxHeight) {
                element.css('maxHeight', maxHeight + 'px');
                if (minHeight > maxHeight) {
                    element.css('minHeight', maxHeight);
                } else {
                    element.css('minHeight', minHeight + 'px');
                }
            } else {
                element.css('maxHeight', '100px');
                if (minHeight < 100) {
                    element.css('minHeight', (element[0] as any).width);
                }
            }
        }
    };
});

ng1App.directive('nhrToWindowSize',
    ['$window', '$timeout',
     function ($window, $timeout) {
    return {
        restrict: 'A',
        replace: false,
        link: function (scope, element, attrs) {
            var window = angular.element($window);
            var changeHeight = function () {
                if (element[0].scrollHeight < window.height()) {
                    element.css('height', window.height() + 'px');
                }
            };

            window.bind('resize', function () {
                changeHeight();   // when window size gets changed
            });

            $timeout(changeHeight, 0);// when page loads
        }
    };
}]);

ng1App.directive('isToday',
    ['$window',
     function ($window) {
    return {
        restrict: 'A',
        replace: false,
        link: function (scope, element, attrs) {
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            if (today.toISOString() == attrs.isToday.replace(/"/g, '')) {
                scope.$watch(function () {
                    return element.hasClass('btn-primary');
                }, function (val) {
                    if (element.hasClass('btn-primary')) {
                        element.removeClass('cur');
                    } else {
                        element.addClass('cur');
                    }
                });

                if (element.hasClass('btn-primary') == false) {
                    element.addClass('cur');
                }

            }
        }
    };
}]);

ng1App.directive('currentTime',
    ['$window',
     function ($window) {
    return {
        restrict: 'A',
        replace: false,
        scope: {
            isHour: '='
        },
        link: function (scope: any, element, attrs) {
            var today = new Date();

            if ((attrs.currentTime.replace(/"/g, '') == today.getHours() && scope.isHour) || (attrs.currentTime.replace(/"/g, '') == today.getMinutes() && !scope.isHour)) {

                scope.$watch(function () {
                    return element.hasClass('btn-primary');
                }, function (val) {
                    if (element.hasClass('btn-primary')) {
                        element.removeClass('cur');
                    } else {
                        element.addClass('cur');
                    }
                });

                if (element.hasClass('btn-primary') == false) {
                    element.addClass('cur');
                }
            }
        }
    };
}]);
