angular.module('medvais.components').service('$medvaisAddRelatedStudy', ["$modal", "$rootScope", "$q", "$filter", "MedvaisImagingStudyApiFactory", "MedvaisClassifierApiFactory", "FormLoaderService", "MedvaisACHIService", function ($modal, $rootScope, $q, $filter, 
    MedvaisImagingStudyApiFactory, MedvaisClassifierApiFactory, FormLoaderService, MedvaisACHIService) {
        
    var scope = $rootScope.$new();
    var deferred;
    
    scope.params = {};
    scope.params.relatedImagingStudies = [];
    scope.params.selectedItem = {};
    scope.params.activeItemId = -1;
    scope.achiParams = {selectedACHI: {}};
    
    scope.onItemSelected = function(index) {
        scope.params.activeItemId = scope.params.relatedImagingStudies[index].id;
        scope.params.selectedItem = scope.params.relatedImagingStudies[index];
        scope.params.selectedItem.index = index;
        scope.achiParams.selectedACHI = MedvaisACHIService.getACHILabel(scope.params.selectedItem.achiCode);
    };
    
    scope.addRelatedStudy = function() {
        if (scope.params.selectedItem.id) {
            var updateData = {achiCode: scope.params.selectedItem.achiCode, perfPhysician: scope.params.selectedItem.perfPhysician};
            FormLoaderService.startFormLoading();
            MedvaisImagingStudyApiFactory.updateAchiAndPhysician({id: scope.params.selectedItem.id}, updateData).$promise.then(
                function(res){
                    deferred.resolve({study: res, index: scope.params.selectedItem.index});
                },
                function(err){
                    deferred.reject($filter('translate')('medvais.err.msg.failed.add.related.study')); 
                    
                }
            ).finally(
                function(){
                    modal.hide();
                }
            );
        }
    };
    
    scope.searchAchiCode = function (value) {
        if (!value || value.length < 3){
            return [];
        }
        
        return MedvaisClassifierApiFactory.getAchiCodes({text: value}).$promise.then(
            function (response) {
                return _.map(response.items, function (v) {
                    v.label = v.code + ' ' + v.name;
                    return v;
                });
                
            }
        );
    };
    
    scope.searchAchiCodeChange = function(value) {
        if (value && value.code) {
            console.log(value);
            scope.params.selectedItem.achiCode = value.code;
        } else {
        }
    };
    
    var modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-add-related-study.html',
        title: $filter('translate')('medvais.027va.add.related.study.title'),
        scope: scope,
        show: false
    });
    var parentShow = modal.show;
    
    modal.show = function(relatedStudyList) {
        scope.params.relatedImagingStudies = relatedStudyList;
        scope.params.selectedItem = {};
        scope.params.activeItemId = -1;
        
        parentShow();
        deferred = $q.defer();
        return deferred.promise;
    };
    return modal;
        
}]);