import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'patient-context',
    template: `
        <dp-navigation ng-hide="restrictedMode"></dp-navigation>
        <dp-doctor-context ng-hide="restrictedMode"></dp-doctor-context>
        <!--<dp-encounter-context ng-hide="restrictedMode"></dp-encounter-context>-->
        <nhr-msg-global></nhr-msg-global>
        <div id="wrap">
            <div id="content" role="main" class="container">
                <router-outlet></router-outlet>
                <spinner [working]="formLoading"></spinner>
            </div>
        </div>
    `,
})
export class DpPatientContextMainComponent implements OnInit {

    @Input() formLoading: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }
}