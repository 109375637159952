import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, map, of, switchMap } from "rxjs";
import { ApiV2Entities } from "src/api/api-v2-entities";
import { ApiV2Organizations } from "src/api/api-v2-organizations";
import { ApiV2Patients } from "src/app/shared/services/api-v2-patients";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import { EntityModel } from "src/app/shared/models/entity.model";
import { FilterGroupModel } from "src/app/shared/models/filter-group.model";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { OrganizationModel } from "src/app/shared/models/organization.model";
import { PatientSearchModel } from "src/app/shared/models/patient-search.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";

@Component({
    selector: "aspnVisitList-filter",
    templateUrl: "./aspnVisitList-filter.component.html",
})
export class AspnVisitListFilterComponent implements OnInit {
    @Input() selected: string;
    @Input() filtersGroup$: Observable<FilterGroupModel[]>;
    @Output() onSearch = new EventEmitter<any>();
    filters: FilterGroupModel[];
    noResult = false;

    patientSuggestions$?: Observable<PatientSearchModel[]>;
    practitionerSuggestions$: Observable<any[]>;
    organizationSuggestions$: Observable<any[]>;

    count = 10;
    form: FormGroup;
    procedureOpts: EntityModel[] = [
        {
            code: "1",
            id: "1",
            name: "procedura1",
        },
    ];

    constructor(
        private formBuilder: FormBuilder,
        private ApiV2Entities: ApiV2Entities,
        private apiV2Patients: ApiV2Patients,
        private apiV2Organizations: ApiV2Organizations,
        private apiV2Practitioners: ApiV2Practitioners
    ) {
        this.form = this.formBuilder.group({
            dateFrom: "",
            dateTo: "",
            patient: "",
            visitState: "",
            practitioner: "",
            urgent: "",
            procedures: null,
        });
    }

    ngOnInit(): void {
        this.initializeOrganizationSuggestions();
        this.initializePatientSuggestions();
        this.initializePractitionerSuggestions();
    }

    initializePatientSuggestions() {
        this.patientSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get("patient").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Patients
                        .search({ q: query })
                        .pipe(
                            map(
                                (data: FilteredDataModel) =>
                                    data && data.items.map((pat) => this.getPersonCredentials(pat) || [])
                            )
                        );
                }
                return of([]);
            })
        );
    }

    initializePractitionerSuggestions() {
        this.practitionerSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get("practitioner").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners
                        .find({ q: query })
                        .pipe(
                            map(
                                (data: FilteredDataModel) =>
                                    (data && data.items.map((psm) => this.getPersonCredentials(psm))) || []
                            )
                        );
                }
                return of([]);
            })
        );
    }

    initializeOrganizationSuggestions() {
        this.organizationSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get("organization").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Organizations
                        .getOrganizations(query, this.count, "ROOT_ONLY")
                        .pipe(
                            map(
                                (data: OrganizationModel[]) =>
                                    data && data.map((psm) => psm.name + " " + psm.jarCode || [])
                            )
                        );
                }
                return of([]);
            })
        );
    }

    getPersonCredentials(person: PatientModel | PractitionerModel) {
        return (
            (person.namePrefix ? person.namePrefix + " " : "") +
            person.givenName +
            " " +
            person.familyName +
            " " +
            person.personalCode
        );
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    // retrieves selected practitioner object and get its id
    typeaheadOnSelectPractitioner(e: TypeaheadMatch): void {
        //this.form.controls['practitioner'].setValue(e.item.id);
    }

    // retrieves selected patient object and get its id
    typeaheadOnSelectPatient(e: TypeaheadMatch): void {
        //this.form.controls['patient'].setValue(e.item.id);
    }

    searchAdvanced() {
        const query: Object = this.form.touched ? this.form.value : undefined;
        this.onSearch.emit(query);
    }

    clear() {
        this.form.reset();
    }
}
