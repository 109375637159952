<nhr-record-widget [title]="'doc.aspn.healthAssessment.msrmt' | translate" titleClass="pe-4" titleTextClass="fs-2 lh-lg ps-2" [closable]="true" bodyClass="pt-4 px-5 pb-5">
    <div class="d-flex justify-content-between gap-4">
        <app-input
                class="col-2 flex-grow-1"
                labelText="doc.aspn.healthAssessment.bpSys"
                type="number"
                [fControl]="formGroup.get('bloodPressureSystolic')"
        ></app-input>
        <app-input
                class="col-2 flex-grow-1"
                labelText="doc.aspn.healthAssessment.bpDia"
                type="number"
                [fControl]="formGroup.get('bloodPressureDiastolic')"
        ></app-input>
        <app-input
                class="col-2 flex-grow-1"
                labelText="doc.aspn.healthAssessment.bOSat"
                type="number"
                [fControl]="formGroup.get('bloodOxygenSaturation')"
        ></app-input>
        <app-input
                class="col-2 flex-grow-1"
                labelText="doc.aspn.healthAssessment.gluc"
                type="number"
                [fControl]="formGroup.get('glucose')"
        ></app-input>
        <div class="col-2 flex-grow-1"></div>
    </div>
    <div class="d-flex justify-content-between gap-4 mt-4">
        <app-input
                labelText="doc.aspn.healthAssessment.choles"
                class="col-2 flex-grow-1"
                type="number"
                [fControl]="formGroup.get('cholesterol')"
        ></app-input>
        <app-input
                labelText="doc.aspn.healthAssessment.coag"
                class="col-2 flex-grow-1"
                type="number"
                [fControl]="formGroup.get('coagulation')"
        ></app-input>
        <app-input
                labelText="doc.aspn.healthAssessment.hRate"
                class="col-2 flex-grow-1"
                type="number"
                [fControl]="formGroup.get('heartRate')"
        ></app-input>
        <app-input
                labelText="doc.aspn.healthAssessment.temp"
                class="col-2 flex-grow-1"
                type="number"
                [fControl]="formGroup.get('temperature')"
        ></app-input>
        <app-input
                labelText="doc.aspn.healthAssessment.weight"
                class="col-2 flex-grow-1"
                type="number"
                [fControl]="formGroup.get('weight')"
        ></app-input>
    </div>
</nhr-record-widget>
