ng1App.directive('sveidraStageMedications', 
    ['TypeaheadSearchHandlers', 'SveidraListSubForm', 'EntitiesApiFactory',
     function (TypeaheadSearchHandlers, SveidraListSubForm, EntitiesApiFactory) {
	
	var FLOAT_REGEXP = /^\d{1,5}((\.|\,)\d{0,5})?$/;
	
	// Medication
	var initMedication = function (controller) {
		controller._data.medicationSearchParam = null;
		controller._data.budgetArticles = [];
		controller.searchForMedication = TypeaheadSearchHandlers.CP_MEDICATION;
		
		controller.changeMedication = function (medication) {
			if (medication) {
				if (medication.medicationId) {
					this._form.medicationId = medication.medicationId;
					this._form.medicationTitle = medication.genericTitle;
					this._form.measure = medication.amountMeasure;
					
					this._data.budgetArticles = medication.budgetArticles;
				}
			} else {
				this._form.medicationId = null;
				this._form.medicationTitle = null;
				this._form.measure = null;
				this._form.centralizedProcurementArticle = null;
				
				this._data.budgetArticles = [];
			}
		};
	};
	
	// Practitioner
	var initPractitioner = function (controller) {
		controller._data.practitionerSearchParam = null;
		controller.searchForPractitioner = TypeaheadSearchHandlers.PRACTITIONERS;
		
		controller.changePractitioner = function (practitioner) {
			if (practitioner) {
				if (practitioner.id) {
					this._form.practitionerId = practitioner.id;
					this._form.practitioner = practitioner;
				}
			} else {
				this._form.practitionerId = null;
				this._form.practitioner = null;
			}
		};
	};
	
	var clearAdditionalData = function (controller) {
		controller._data.medicationSearchParam = null;
		controller._data.budgetArticles = [];
		controller._data.practitionerSearchParam = null;
	};
	
	var resolveDataForEdit = function (form, data) {
		// Medication
		data.medicationSearchParam = form.medicationId + ' ' + form.medicationTitle;
		EntitiesApiFactory.getList({classCode: 'cp-medication', medicationId: form.medicationId}, function (result) {
			data.budgetArticles = result[0].budgetArticles;
		});
		
		// Practitioner
		data.practitionerSearchParam = _.getFullName(form.practitioner) + ' - ' + form.practitioner.stampCode;
	};
	
	
	var initForm = function (scope) {
		scope.formCtrl = new SveidraListSubForm(scope.stage.cpMedications, 'cp-medications');
		
		initMedication(scope.formCtrl);
		initPractitioner(scope.formCtrl);
		
		scope.formCtrl.before = function (action) {
            if (action == 'cancel') {
                this.clear();
                clearAdditionalData(this);
            }
        };
        
        scope.formCtrl.after = function (action) {
        	if ((action == 'add' || action == 'save') && !this._validator.hasErrors()) {
        		clearAdditionalData(this);
        	} else if (action == 'edit') {
        		resolveDataForEdit(this._form, this._data);
        	}
        };
		
        scope.formCtrl.validateValue = function (form, validator) {
        	if (!form.medicationId) {
        		validator.add('medication');
        	} else {
        		if (!form.centralizedProcurementArticle) {
        			validator.add('budgetArticle');
        		}
        		if (!form.amount) {
        			validator.add('amountReq');
        		} else if (!FLOAT_REGEXP.test(form.amount)) {
        			validator.add('amount');
        		}
        	}
        	
			if (!form.date) {
				validator.add('date');
			} else if (!dateInDepartmentInterval(form.date, scope.stage.departments)) {
				validator.add('dateInterval');
			}
			
        	if (!form.practitionerId) {
        		validator.add('practitioner');
        	}
    	};
		
		scope.formCtrl.init();
	};
	
	// Validate against department intervals
	var dateInDepartmentInterval = function (date, departments) {
		if (departments) {
			var dateObject = new Date(date);
			var isInInterval = false;
			_.each(departments, function (department) {
				isInInterval ||= dateObject >= new Date(department.from) && (!department.to || dateObject <= new Date(department.to));
			});
			return isInInterval;
		}
		return false;
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/cp_medications.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			scope.stage.$promise.then(function () {
				initForm(scope);
			});
		}
	};
}]);