import {Component, OnInit} from '@angular/core';
import {PortalTypeEnum} from "../../../../shared/models/portalType.enum";

@Component({
    selector: 'dp-doc-ref-consent-template-view',
    templateUrl: './dp-doc-ref-consent-template-view.component.html'
})
export class DpDocRefConsentTemplateViewComponent implements OnInit {

    portalType: PortalTypeEnum = PortalTypeEnum.DP;

    ngOnInit(): void {
    }

}
