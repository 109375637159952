ng1App.factory("EncounterActions", [
    "MessageService",
    function (MessageService) {
        return {
            validateForm: function (htmlForm, data, _mode, agreementIsMandatory, currTime, callbackIfValid) {
                MessageService.clearAll();
                _.markAllAsTouched(htmlForm)

                var hasOrderErrors = false;
                if (data != null) {
                    if (data.isByOrder && !data.paperOrder && data.orderRef == null || data.orderRef == "") {
                        MessageService.add(
                            "error",
                            "Būtina pasirinkti bent vieną siuntimą.",
                            "order-region"
                        );
                        MessageService.add(
                            "error",
                            'Skiltyje "Siuntimo duomenys" yra klaidų.',
                            "arrival-region"
                        );
                        hasOrderErrors = true;
                    }

                    if (agreementIsMandatory && data.agreementList.length == 0) {
                        MessageService.add("error", "Būtinas paciento sutikimas.", "agreement-region");
                        hasOrderErrors = true;
                    }
                }
                if (htmlForm.$valid && !hasOrderErrors && callbackIfValid) {
                    callbackIfValid();
                } else {
                    _.scrollToFirstInvalid();
                    MessageService.add("error", "Formoje yra klaidų");
                }
            },
        };
    },
]);

ng1App.controller("doctor.patient.EncountersNewCtrl", [
    "$window",
    "$filter",
    "$scope",
    "$routeParams",
    "$location",
    "$modal",
    "$upload",
    "MessageService",
    "PatientsApiFactory",
    "SessionApiFactory",
    "EntitiesApiFactory",
    "TypeaheadSearchHandlers",
    "apiUrl",
    "EncountersApiFactory",
    "ClassifiersApiFactory",
    "SessionContext",
    "FormLoaderHelper",
    "EncounterActions",
    "PatientInsuranceService",
    "DpSessionContext2",
    function (
        $window,
        $filter,
        $scope,
        $routeParams,
        $location,
        $modal,
        $upload,
        MessageService,
        PatientsApiFactory,
        SessionApiFactory,
        EntitiesApiFactory,
        TypeaheadSearchHandlers,
        apiUrl,
        EncountersApiFactory,
        ClassifiersApiFactory,
        SessionContext,
        FormLoaderHelper,
        EncounterActions,
        PatientInsuranceService,
        DpSessionContext2
    ) {
        var formLoaderHelper = new FormLoaderHelper();
        $scope.$emit("setHelpPage", "apsilankymo-registravimas");

        var currTime = null;

        MessageService.clearAll();
        $scope.patientEncounters = [];
        $scope.patientOrders = [];
        $scope.noMoreResults = false;
        $scope.pageNumber = 1;
        $scope.organization = formLoaderHelper.addPromise(SessionApiFactory.getOrganization());
        $scope.practitioner = formLoaderHelper.addPromise(SessionApiFactory.getPractitioner());
        $scope.patient = {};
        $scope.patientInfo = {};
        $scope.relatedPersons = [];
        $scope.showPatientUpdateLink = true;
        $scope.form = {};

        // TODO: copied from src/portal/doctor/patient/encounters/edit.ts
        // TODO: yet, nothing sets those variables like the other do.
        var linkedOrder;
        var orderEventType;

        var getPatient = function () {
            return PatientsApiFactory.getWithRelatedPersons({id: $routeParams.patientId}, function (data) {
                $scope.relatedPersons = [];
                _.each(data.relatedPersons, function (value) {
                    if (!value.deathDate) {
                        $scope.relatedPersons.push(value);
                    }
                });
                $scope.patient = data.patient;
                $scope.patientInfo = angular.copy(data.patient);
                $scope.updateInsuranceInfo();

                EncountersApiFactory.getEsiNr({patientId: $routeParams.patientId}, function (data) {
                    $scope.form.esiNumber = data.esiNr;
                });
            });
        };

        formLoaderHelper.addPromise(getPatient());

        $scope.searchOrganizations = TypeaheadSearchHandlers.ORGANIZATIONS;
        $scope.searchOrganizationsChange = function (v) {
            if (v && v.id) {
                $scope.form.emsOrganizationRef = v.fullId;
            }
        };
        $scope.searchPaperOrderOrganizationsChange = function (v) {
            if (v && v.id) {
                $scope.form.paperOrderOrganizationRef = v.fullId;
            }
        };

        $scope.searchPractitioners = function (v) {
            return TypeaheadSearchHandlers.PRACTITIONERS(v, SessionContext.organization.id, null, null, null, "active");
        };
        $scope.searchPractitionersChange = function (v) {
            if (v && v.id) {
                $scope.form.specialistRef = v.fullId;
            }
        };

        $scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;
        $scope.searchEmsDiagnosisChange = function (v) {
            if (v && v.code) {
                $scope.form.emsCondition = v;
            }
        };
        $scope.searchPaperOrderDiagnosisChange = function (v) {
            if (v && v.code) {
                $scope.form.paperOrderDiagnosis = v;
            }
        };

        $scope.searchCountries = TypeaheadSearchHandlers.COUNTRIES;
        $scope.searchCountriesChange = function (v) {
            if (v && v.code) {
                $scope.form.insuranceData.country = v;
            }
        };
        $scope.insuranceDocTypes = formLoaderHelper.addPromise(
            EntitiesApiFactory.getList({classCode: "insurance-doc-type"})
        );

        //$scope.events = formLoaderHelper.addPromise(EntitiesApiFactory.getList({ classCode: "visit-events" }));
        $scope.events = [
            {code: "ambulatory", name: "Atvykimas į SPĮ gauti ambulatorinių paslaugų"},
            {code: "inpatient", name: "Atvykimas į SPĮ gauti stacionarinių paslaugų"},
            {code: "virtual", name: "Paslaugų teikimas pacientui neatvykus į SPĮ"},
            {code: "home", name: "Paslaugos paciento namuose"},
        ]

        $scope.serviceTypes = formLoaderHelper.addPromise(
            EntitiesApiFactory.getList({classCode: "encounter-service-type"})
        );
        $scope.insuranceTypes = formLoaderHelper.addPromise(
            EntitiesApiFactory.getList({classCode: "insurance-other-country"})
        );
        $scope.encounterReasons = formLoaderHelper.addPromise(EntitiesApiFactory.getList({classCode: 'encounter-reason'}));

        $scope.updateInsuranceInfo = function () {
            $scope.checkingInsurance = true;
            $scope._save_loading = true;
            PatientInsuranceService.getInsurance($scope.patient.id, true)
                .then(function (insurancePSD) {
                    $scope.form.insuranceData.insurancePSD = insurancePSD;
                })
                .finally(function () {
                    $scope.checkingInsurance = false;
                    $scope._save_loading = false;
                });
        };

        $scope.loadEncounters = function () {
            $scope._encounters_loading = true;
            EncountersApiFactory.getEncountersHistory(
                {patient: $routeParams.patientId},
                function (encounters) {
                    $scope._encounters_loading = false;
                    $scope.patientEncounters = encounters;
                },
                function () {
                    $scope._encounters_loading = false;
                }
            );
        };

        $scope.loadEncounters();

        $scope.loadOrders = function (loadLinked) {
            $scope._orders_loading = true;
            var isVirtual = $scope.form.event.code == "virtual";
            EncountersApiFactory.getUnusedPatientOrders(
                {patient: $routeParams.patientId, page: $scope.pageNumber++, isVirtual: isVirtual},
                function (orders) {
                    $scope._orders_loading = false;
                    for (var i = 0; i < orders.items.length; i++) {
                        $scope.patientOrders.push(orders.items[i]);
                    }
                    if (orders.total <= $scope.patientOrders.length) {
                        $scope.noMoreResults = true;
                    }
                    if (loadLinked) {
                        var linkedOrderMissing = true;
                        for (var i = 0; i < $scope.patientOrders.length; i++) {
                            if ($scope.form.order && $scope.patientOrders[i].item.id == $scope.form.order.id) {
                                linkedOrderMissing = false;
                            }
                        }

                        // TODO: copy/paste of src/portal/doctor/patient/encounters/edit.ts:116 ?
                        if (linkedOrderMissing && linkedOrder && orderEventType == $scope.form.event.code) {
                            EncountersApiFactory.getOrderForEdit(
                                {id: linkedOrder, isVirtual: isVirtual},
                                function (data) {
                                    $scope.patientOrders.unshift(data);
                                }
                            );
                        }
                    }
                },
                function () {
                    $scope._orders_loading = false;
                }
            );
        };

        $scope.loadMoreOrders = function () {
            $scope.loadOrders();
        };

        $scope.reloadOrders = function () {
            if ($scope.form.isByOrder) {
                $scope.form.paperOrder = false;
                $scope.form.orderRef = null;
                $scope.form.order = null;
                $scope.pageNumber = 1;
                $scope.patientOrders = [];
                $scope.noMoreResults = false;
                $scope.loadOrders(false);
            }
            if ($scope.form.reason) {
                $scope.form.reason.code = undefined;
            }
        };

        $scope.changeByOrderFlag = function () {
            if ($scope.form.isByOrder) {
                $scope.form.isByOrder = false;
            } else {
                $scope.form.isByOrder = true;
                $scope.reloadOrders();
            }
        };

        $scope.selectOrder = function (id) {
            $scope.form.orderRef = id;
            console.debug(id);
        };

        $scope.selectServiceType = function (item) {
            $scope.form.serviceType = item;
        };

        $scope.initForm = function () {
            _.markAllItems(false, $scope.reasons, false);
            $scope.form = {
                event: {
                    code: "ambulatory",
                },
                esiNumber: $scope.patient.esiNumber,
                orderSelection: {},
                agreementList: [],
                arrivalDate: currTime,
                insuranceData: {
                    validUntil: null,
                    isInsured: $scope.isInsured,
                    insuranceOtherCountry: $scope.insuranceTypes[0],
                    otherInsurance: false,
                },
                emsOrganizationName: null,
                emsOrganizationRef: null,
                emsCondition: null,
                paperOrder: false,
            };
            $scope.agreementFiles = {};
        };
        formLoaderHelper.addPromise(
            SessionApiFactory.getSysdate({}, function (result) {
                currTime = result.value;
                $scope.initForm();
            })
        );

        $scope.save = function () {
            console.debug("form:", $scope.form);
            EncounterActions.validateForm(
                $scope.filterForm,
                $scope.form,
                "new",
                $scope.agreementIsMandatory,
                currTime,
                function () {
                    $scope.formValidated = true;
                    $scope._save_loading = true;
                    $scope.form.patientData = $scope.patient;
                    $scope.form.patientRef = $scope.patient.fullId;
                    $scope.form.practitionerData = $scope.practitioner;
                    $scope.form.organizationData = $scope.organization;
                    var files = [];
                    var fileKeys = [];
                    angular.forEach($scope.agreementFiles, function (val, key) {
                        if (val) {
                            files.push(val);
                            fileKeys.push("file_" + key);
                        }
                    });

                    $scope.upload = $upload
                        .upload({
                            url: apiUrl + "/encounters",
                            method: "POST",
                            data: {form: $scope.form},
                            file: files,
                            fileFormDataName: fileKeys,
                        })
                        .progress(function (evt) {
                            ///console.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total));
                        })
                        .success(function (resp) {
                            $scope._save_loading = false;
                            if (resp.agreementsFailed) {
                                MessageService.registerEncounterPartialSaved(
                                    "Nepavyko išsaugoti sutikimų. Klaidos ID: " + resp.errorId
                                );
                            } else {
                                MessageService.registerSuccessEvent();
                            }

                            DpSessionContext2.getLatestEncounter();
                            $location.path("dp/patients/" + $routeParams.patientId);
                        })
                        .error(function (resp) {
                            console.debug("percent: " + resp);
                            $scope._save_loading = false;
                            angular.forEach(resp.fieldErrors, function (item) {
                                if ("arrivalDate" == item.propertyPath) {
                                    MessageService.add("error", $filter("translate")(item.msg), "arrival-region");
                                } else if ("esiNumber" == item.propertyPath) {
                                    MessageService.add("error", item.msg, "arrival-region");
                                } else {
                                    MessageService.add("error", item.msg, "info-region");
                                }
                            });
                            angular.forEach(resp.globalErrors, function (item) {
                                MessageService.error(item.msg);
                            });
                            _.scrollTo("#skip-link");
                        });
                }
            );
        };

        // modals
        var modal = $modal({
            scope: $scope,
            contentTemplate: "/templates/portal/doctor/patient/encounters/patient_info_modal.html",
        });
        $scope.enterPatientInfo = function () {
            $scope.cancelErr = 0;
            modal.$promise.then(function () {
                $scope.patientInfo = angular.copy($scope.patient);
                modal.show();
            });
        };

        $scope.searchCountry = TypeaheadSearchHandlers.COUNTRIES;
        $scope.searchResidenceCountryChange = function (c) {
            if (c && c.code) {
                $scope.patientInfo.foreignAddress.country = c;
            } else if (!c) {
                $scope.patientInfo.foreignAddress.country = undefined;
            }
        };
        $scope.updatePatientInfo = function () {
            PatientsApiFactory.updateContacts(
                $scope.patientInfo,
                function () {
                    MessageService.success("Paciento duomenys sėkmingai atnaujinti");
                    getPatient();
                    modal.hide();
                },
                function (error) {
                    angular.forEach(error.data.fieldErrors, function (e) {
                        MessageService.error(e.propertyPath + " " + e.msg);
                    });
                }
            );
        };

        var EMImodal = $modal({
            scope: $scope,
            contentTemplate: "/templates/portal/doctor/patient/encounters/EMI_change_modal.html",
        });
        $scope.changeEMI = function () {
            $scope.cancelErr = 0;
            EMImodal.$promise.then(function () {
                $scope.EMImodal = {};
                $scope.EMImodal.esiNumber = $scope.form.esiNumber;
                EMImodal.show();
            });
            $scope.EMImodalSave = function () {
                $scope.form.esiNumber = $scope.EMImodal.esiNumber;
                EMImodal.hide();
            };
        };

        $scope.getPatientAge = function () {
            if ($scope.patient) {
                return _.age($scope.patient.birthDate, $scope.patient.deathDate);
            }
            return "";
        };

        ClassifiersApiFactory.getAgreementsTypesForEncounter({}, function (data) {
            var hideAgreements = false;
            var agreementIsMandatory = false;
            angular.forEach(data.items, function (value) {
                hideAgreements = _.find(value.relations, function (item) {
                    return (item.docId = 21 && item.relation == 0);
                });
                agreementIsMandatory = _.find(value.relations, function (item) {
                    return (item.docId = 21 && item.relation == 2);
                });
            });
            $scope.hideAgreements = hideAgreements;
            $scope.agreementIsMandatory = agreementIsMandatory;
        });

        formLoaderHelper.finally();

        $scope.goBack = function () {
            $window.history.back();
        };
    },
]);
