ng1App.controller('doctor.user.UnfinishedRecordsSignCtrl', 
    ['$scope', '$filter', '$routeParams', '$modal',
     function ($scope, $filter, $routeParams, $modal) {
    $scope.documents = [{
            id: '1',
            type: 'doc',
            title: 'E025 Ambulatorinis apsilankymas',
            status: 'preliminary',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Agneta',
            patientLastname: 'Omenytė',
            patientBirth: '1990-12-08',
            createDateTime: '2014-08-26 14:50',
            ESInr: 'xxx-987',
            serviceData1: 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType:'e025'
        }, //1.1 not finished
        {
            id: '2',
            type: 'doc',
            title: 'E003 Stacionaro epikrizė',
            status: 'draft',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Agneta',
            patientLastname: 'Omenytė',
            patientBirth: '1990-12-08',
            createDateTime: '2014-08-26 14:50',
            ESInr: 'xxx-987',
            serviceData1: 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
             docType:'e003'
        }, //1.2  draft
        {
            id: '3',
            type: 'doc',
            title: 'E025 Ambulatorinis apsilankymas',
            status: 'preliminary',
            author: 'Antanas Romanius',
            authorStamp: '485973',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'false',
            patientName: 'Agneta',
            patientLastname: 'Omenytė',
            patientBirth: '1990-12-08',
            createDateTime: '2014-08-26 14:50',
            ESInr: 'xxx-987',
            serviceData1: 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType:'e025'
        }, //2.1 see not owner
        {
            id: '4',
            type: 'doc',
            title: 'E025 Ambulatorinis apsilankymas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Agneta',
            patientLastname: 'Omenytė',
            patientBirth: '1990-12-08',
            createDateTime: '2014-08-26 14:50',
            ESInr: 'xxx-987',
            serviceData1: 'A08.5 Kitos patikslintos žarnyno infekcijos, A08.2 Adenovirusų sukeltas enteritas',
            docType:'e025'
        }, //3.1 submited to sing
        {
            id: '5',
            type: 'obs',
            title: 'Nebaigtas siuntimas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Agneta',
            patientLastname: '',
            patientBirth: '',
            createDateTime: '2014-08-26 14:50',
            ESInr: 'xxx-987',
            serviceData1: 'A08.5 Kitos patikslintos žarnyno infekcijos',
            consultationDocOrProf: 'Gyd. kardiologas',
            serviceData2: 'Konsultacijos, tyrimų, gydymo skyrimas'
        },
        {
            id: '6',
            type: 'doc',
            title: 'E027 Konsultacijos, tyrimų, gydymo siuntimas',
            status: 'preliminary',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Roberta',
            patientLastname: 'Omenienė',
            patientBirth: '1978-12-08',
            createDateTime: '2014-08-26 16:50',
            ESInr: 'xxx-987',
            serviceData1: 'A39.8 Kitos meningokokų sukeltos infekcijos',
            consultationDocOrProf: 'Gyd. kardiologas',
            docType:'e027'
        },
        {
            id: '6',
            type: 'doc',
            title: 'E027 Siuntimas skiepytis',
            status: 'preliminary',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Ovidijus',
            patientLastname: 'Zakas',
            patientBirth: '1988-03-08',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'BCG vaccine SSI',
            docType:'e027'
        },
        {
            id: '7',
            type: 'doc',
            title: 'E027 Siuntimui laboratoriniam tyrimui',
            status: 'preliminary',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Jonui',
            patientLastname: 'Kokui',
            patientBirth: '1989-04-18',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'Antikūnų prieš DNR nustatymas',
            docType:'e027'
        },
        {
            id: '8',
            type: 'doc',
            title: 'E027 Medicinos dokumentų išrašas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'Lietuvos kelių policijos tarnybai',
            docType:'e027'
        }, {
            id: '9',
            type: 'doc',
            title: 'E027-ats Atsakymas į konsultacijos, tyrimų, gydymo siuntimą',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'A39.8 Kitos meningokokų sukeltos infekcijos',
            docType:'e027'
        },
        {
            id: '10',
            type: 'doc',
            title: 'E200 Laboratorinio tyrimo užsakymas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'Antikūnų prieš DNR nustatymas',
            docType:'e200'
        },
        {
            id: '9',
            type: 'doc',
            title: 'E200-ats Laboratorinio tyrimo rezultatų protokolas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'Antikūnų prieš DNR nustatymas',
            docType:'e200a'
        },
        {
            id: '11',
            type: 'doc',
            title: 'E014 Patologijos tyrimo užsakymas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'C00.3 Viršutinės lūpos vidinis paviršius, C00.4 Apatinės lūpos vidinis paviršius',
            serviceData2: 'M8000/0 Navikas, gerybinis',
            docType:'e014'
        },
        {
            id: '12',
            type: 'doc',
            title: 'E014-ats Patologijos tyrimo atsakymas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'C00.3 Viršutinės lūpos vidinis paviršius, C00.4 Apatinės lūpos vidinis paviršius',
            serviceData2: 'M8000/0 Navikas, gerybinis',
            docType:'e014a'
        },
        {
            id: '13',
            type: 'doc',
            title: 'E063 Vakcinacijos įrašas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Šeimos gydytojas',
            isOwner: 'true',
            patientName: 'Andriui',
            patientLastname: 'Rinkevičiui',
            patientBirth: '1965-08-04',
            createDateTime: '2014-08-26 17:05',
            ESInr: 'xxx-987',
            serviceData1: 'Avaxim 160',
            docType:'e063'
        },
        {
            id: '14',
            type: 'obs',
            title: 'Nebaigtas siuntimas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Agneta',
            patientLastname: 'Rimkevičienė',
            patientBirth: '1988-11-11',
            createDateTime: '2014-08-26 17:50',
            ESInr: 'xxx-987',
            serviceData1: 'Ambirix* (EU)',
            serviceData2: 'Skiepų skyrimas'
        },
        {
            id: '15',
            type: 'obs',
            title: 'Nebaigtas siuntimas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Jolanta',
            patientLastname: 'Augutienė',
            patientBirth: '1975-12-01',
            createDateTime: '2014-08-26 17:50',
            ESInr: 'xxx-987',
            serviceData1: 'Antikūnų prieš skydliaukės peroksidazę nustatymas (anti-TPO)',
            serviceData2: 'Skyrimas laboratoriniam tyrimui'
        },
        {
            id: '16',
            type: 'obs',
            title: 'Nebaigtas siuntimas',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Jolanta',
            patientLastname: 'Augutienė',
            patientBirth: '1975-12-01',
            createDateTime: '2014-08-26 17:50',
            ESInr: 'xxx-987',
            serviceData1: 'C00.5 Lūpa, nepatikslinta, vidinis paviršius',
            serviceData2: 'Skyrimas patologiniam tyrimui'
        },
        {
            id: '17',
            type: 'cer',
            title: 'E047 Privalomo sveikatos patikrinimo medicininė pažyma',
            status: 'final',
            author: 'Jonas Petkevičius',
            authorStamp: '485963',
            authorProfesion: 'Gyd. kardiologas',
            isOwner: 'true',
            patientName: 'Jolanta',
            patientLastname: 'Augutienė',
            patientBirth: '1975-12-01',
            createDateTime: '2014-08-26 17:50',
            ESInr: 'xxx-987',
            serviceData1: 'C00.5 Lūpa, nepatikslinta, vidinis paviršius',
            serviceData2: 'Skyrimas patologiniam tyrimui'
        }]; //4.1 submited to sing
    //service data https://confluence.nortal.com/pages/viewpage.action?pageId=91688196 "Paslaugos duomenys" depends on document type

    $scope.getTitle = function (state: string): string {
        if (state == 'entered in error' || state == 'enteredInError') {
            return 'Atšauktas';
        } else if (state == 'amended') {
            return 'Pakeistas';
        } else if (state == 'final') {
            return 'Pateiktas pasirašymui';
        } else if (state == 'preliminary') {
            return 'Dalinai patvirtintas';
        } else if (state == 'draft') {
            return 'Juodraštis';
        } else {
            return 'Pasirašytas';
        }
    };
    $scope.getActions = function (state) {
        let actionList = {
            edit: {
                disabled: true,
                iconName: 'fa-edit',
                title: 'Redaguoti'
            },
            sign: {
                disabled: true,
                iconName: 'fa-edit',
                title: 'Pasirašyti'
            },
            create: {
                disabled: true,
                iconName: 'fa-plus',
                title: 'Sukūrti'
            },
            creDisability: {
                disabled: true,
                iconName: 'fa-plus',
                title: 'Sukūrti nedarbingumo pažymėjimą'
            },
            subDisability: {
                disabled: true,
                iconName: 'fa-plus',
                title: 'Pateikti nedarbingumo pažymėjimą SODRA IS'
            },
            crePregnancy: {
                disabled: true,
                iconName: 'fa-plus',
                title: 'Sukūrti nėštumo ir gimdymo atostogų pažymėjimą'
            },
            subPregnancy: {
                disabled: true,
                iconName: 'fa-plus',
                title: 'Pateikti nėštumo ir gimdymo atostogų pažymėjimą SODRA IS'
            },
            subRecipes: {
                disabled: true,
                iconName: 'fa-plus',
                title: $filter('translate')('dpp.unf.rec.lis.drugs')
            }/*,
            subRecipes: {
                disabled: true,
                iconName: 'fa-plus',
                title: 'Mirties liudijimo išrašymas'
            },*/
        };
        if (state == 'draft' || state == 'preliminary') {
            // TODO: What it suppose to do?????????
            // list.a
        }
    };
    var modal = $modal({
        scope: $scope,
        // TODO: No such template with path. Maybe: /templates/portal/doctor/user/records/search.html ?
        contentTemplate: '/templates/portal/doctor/user/records/search.html'
    });
    $scope.search = function () {
        $scope.cancelErr = 0;
        $scope.documentCancelReason = {};
        modal.$promise.then(function () {
            modal.show();
        });
    };
}]);