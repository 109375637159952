import './api-auth';
import './api-links';
import './api-roles';
import './api-tasks';
import './api-users';
import './api-alerts';
import './api-drafts';
import './api-orders';
import './api-captcha';
import './api-devices';
import './api-persons';
import './api-reports';
import './api-session';
import './api-sveidra';
import './api-aagasgas';
import './api-entities';
import './api-patients';
import './api-settings';
import './api-addresses';
import './api-documents';
import './api-erx-rules';
import './api-importers';
import './api-templates';
import './api-automation';
import './api-conditions';
import './api-encounters';
import './api-accessrules';
import './api-admin-tools';
import './api-attachments';
import './api-medications';
import './api-war-refugee';
import './api-certificates';
import './api-erx-settings';
import './api-sodra-logins';
import './api-specialusers';
import './api-vaccinations';
import './api-issue-reports';
import './api-organizations';
import './api-practitioners';
import './api-gp-certificate';
import './api-sveidra-logins';
import './api-archive-history';
import './api-bus-medications';
import './api-patient-summary';
import './api-remote-pharmacy';
import './api-representations';
import './api-account-settings';
import './api-dgc-certificates';
import './api-document-history';
import './api-external-systems';
import './api-role-permissions';
import './api-certificates-epts';
import './api-unfinishedrecords';
import './api-medications-catalog';
import './api-services-subscriber';
import './api-vaccination-records';
import './api-indirect-health-info';
import './api-number-generation-rules';
import './api-roles-permission-groups';
import './api-aspn-context';
import './api-ncp';