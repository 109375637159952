ng1App.controller('document.E003ChangeCtrl',
    ['$scope', 'MessageService', 'EntitiesApiFactory', '$filter', 'DocumentModificationActions',
        function ($scope, MessageService, EntitiesApiFactory, $filter, DocumentModificationActions) {
            MessageService.clearAll();

            // Side content list
            // jus fill array with something
            $scope._shared.contentList = _.range(20);
            $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id: 'doc_mai_inf'};
            $scope.currentDiagnoses = $scope.form.partDiagnosis;
            $scope.newRisks = $scope.form.partRiskFactors;
            if($scope.form.partAllergies){
                $scope.currentAllergies = $scope.form.partAllergies.allergies;
            } else {
                $scope.currentAllergies = [];
            }

            $scope.types = {
                diagnosisTypes: EntitiesApiFactory.getList({classCode: 'condition-disease-type'}),
                sampleCollMethods: EntitiesApiFactory.getList({classCode: 'specimen-collection-method'})
            };

            $scope.handleValidationFailure = function (isValid) {
                $scope.diagnosisValid = isValid;
            };

            $scope.handleAllergiesChange = function(event) {
                $scope.currentAllergies = event;
                $scope.form.partAllergies.allergies = event;
            };

            $scope.handleDiagnosesChange = function (event) {
                $scope.currentDiagnoses = event;
                $scope.form.partDiagnosis = event;
            };

            $scope._shared.customValidator = function (documentForm, skipRequired) {
                var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
                MessageService.clear('diagnosis-region');
                if (!$scope.form.partDiagnosis || $scope.form.partDiagnosis.length == 0) {
                    MessageService.add('error', 'Nenurodyta nė viena diagnozė.', 'diagnosis-region');
                    if (res) {
                        _.scrollTo('[msg-region="diagnosis-region"]');
                    }
                    res = false;
                }
                return res;
            }
        }]);
