import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {ApiV2DocRefConsentTemplate} from "../../../../../api/api-v2-doc-ref-consent-template";
import {DocRefConsentTemplateModel} from "../../../models/doc-ref-consent-template.model";
import {FilterService} from "../../../services/filter.service";
import {DocRefConsentTemplateFilterEnum} from "../../../models/doc-ref-consent-template-filter.enum";
import {FilterModel} from "../../../models/filter.model";
import {FilteredDataModel} from "../../../models/filtered-data.model";
import {PortalTypeEnum} from "../../../models/portalType.enum";
import {Observable} from "rxjs";
import {QueryModel} from "../../../models/query.model";
import {DocRefConsentFilterModel} from "../../../models/doc-ref-consent-filter.model";
import {RouteParams} from "../../../../ng1.routeParams";

@Component({
    selector: 'doc-ref-consent-template-list',
    templateUrl: './doc-ref-consent-template-list.component.html'
})
export class DocRefConsentTemplateListComponent {
    backPath: string;
    @Input() docRefConsentTemplates: DocRefConsentTemplateModel[];
    @Input() predefinedFilters: FilterModel[];
    @Input() currentFilter: FilterModel;
    @Input() portalType: PortalTypeEnum;
    @Input() filtersGroup$: Observable<DocRefConsentFilterModel>;
    working: boolean = false;
    count: number;
    page: number = 1;
    loadMoreActive = false;

    constructor(public apiV2DocRefConsentTemplate: ApiV2DocRefConsentTemplate, private router: Router, private params: RouteParams) {
    }

    ngOnInit(): void {
        this.backPath = this.portalType;
        this.setFilters();
        this.setPredefinedFilters();
        this.loadDocRefConsentTemplatesList(this.currentFilter.query);
    }

    loadDocRefConsentTemplatesList(query?: QueryModel, add?: boolean): void {
        this.apiV2DocRefConsentTemplate.getDocRefConsentTemplatesList(query ? query : this.currentFilter.query).subscribe((data: DocRefConsentTemplateModel[] | FilteredDataModel) => {
            const filteredData: FilteredDataModel = data as FilteredDataModel;
            FilterService.setFilterCount(this.predefinedFilters, filteredData);
            filteredData.items.forEach(d => d.type = 'DocRefConsentTemplateModel');

            if (add) {
                filteredData.items.forEach(item => this.docRefConsentTemplates.push(item));
            } else {
                this.docRefConsentTemplates = filteredData.items;
            }

            this.count = this.docRefConsentTemplates.length;
            this.loadMoreActive = this.count < filteredData.total;
            this.working = false;
        });
    }

    setFilters() {
        this.filtersGroup$ = this.apiV2DocRefConsentTemplate.getFilters();
    }

    setPredefinedFilters() {
        this.predefinedFilters = FilterService.createFilters(DocRefConsentTemplateFilterEnum);
        if (this.predefinedFilters) {
            this.currentFilter = this.predefinedFilters.find(filter => filter.title === DocRefConsentTemplateFilterEnum.TOTAL);
        }
    }

    canCreate(): boolean {
        return this.portalType === PortalTypeEnum.AP;
    }

    createDocRefConsTemplate() {
        this.router.navigate([this.portalType + '/patients/' + this.params?.patientId + '/doc-ref-consent-template/' + 'create']);
    }

    onCardClick(id: string): void {
        this.router.navigate([this.portalType + '/patients/' + this.params?.patientId + '/doc-ref-consent-template/' + 'view/' + id]);
    }

    onEditClick(id: string): void {
        this.router.navigate([this.portalType + '/patients/' + this.params?.patientId + '/doc-ref-consent-template/' + 'edit/' + id]);
    }

    loadMore() {
        this.working = true;
        this.page++;
        this.loadDocRefConsentTemplatesList({count: 5, page: this.page} as QueryModel, true);
    }
}
