<header class="app-title mb-4">
  <div class="row">
    <div class="col">
      <h1 class="mb-0 flex-grow-1">
        <ng-content></ng-content>
      </h1>
      <div class="d-flex flex{{ backInline ? '-row align-items-center' : '-column' }}">
        <back-link *ngIf="backPath !== null" [path]="backPath" [title]="backTitle" [subTitle]="backSubtitle" [badgeText]="badgeText" [badgeClass]="badgeClass" />
        <ng-content select="content"></ng-content>
      </div>
    </div>
    <div class="app-advanced-filters col-12 col-sm-auto d-flex gap-1 align-items-start mt-3 mt-sm-0">
      <ng-content select="aside"></ng-content>
      <toggle-filters *ngIf="showFilterButton"></toggle-filters>
    </div>
  </div>
</header>
