/* global app, _ */
ng1App.filter('age', function () {
    return function (person) {
        if (person && person.birthDate) {
            return _.age(person.birthDate, person.deathDate);
        }
    };
});

ng1App.filter('ageYears', function () {
    return function (person) {
        if (person && person.birthDate) {
            return _.agenumyears(person.birthDate, person.deathDate);
        }
    };
});

ng1App.filter('onlyName', function () {
    return function (input, searchField) {
        if (input != undefined) {
            var reg = new RegExp(searchField, "gi");
            return input.filter(function (t) {
                var matches = t.name && t.name.match(reg) != null;
                if (matches) {
                    t.visible = true;
                } else {
                    t.visible = false;
                }
                return matches;
            });
        }
    }
});

ng1App.filter('nameOrSveidraId', function () {
    return function (input, searchField) {
        if (input != undefined) {
            var reg = new RegExp(searchField, "gi");
            return input.filter(function (t) {
                var matches = (t.name && t.name.match(reg) != null) || (t.sveidraId && t.sveidraId.match(reg) != null);
                if (matches) {
                    t.visible = true;
                } else {
                    t.visible = false;
                }
                return matches;
            });
        }
    }
});

ng1App.filter('fLicense', function () {
    return function (input, searchField) {
        if (input != undefined) {
            var reg = new RegExp(searchField, "gi");
            return input.filter(function (t) {
                var matches = (t.profession && t.profession.name && t.profession.name.match(reg) != null) || (t.licenseCode	 && t.licenseCode.match(reg) != null);
                if (matches) {
                    t.visible = true;
                } else {
                    t.visible = false;
                }
                return matches;
            });
        }
    }
});

ng1App.filter('references', function () {
    return function (input, identifierField) {
        if (input != undefined) {
            return input.filter(function (t) {
                return t.orderId == identifierField;
            });
        }
    }
});

ng1App.filter('isEmpty', function () {
    return function (val) {
        return !val || angular.equals(val, {}) || angular.equals(val, []);
    };
});

ng1App.filter('isNotEmpty', function () {
    return function (val) {
        return !(!val || angular.equals(val, {}) || angular.equals(val, []));
    };
});

ng1App.filter('photoUrl',
    ['apiUrl',
     function (apiUrl) {
    return function (val) {
        return val && val.photoId ? (apiUrl + '/attachments/media/' + val.photoId) : '/assets/img/profile.png';
    };
}]);
/* Document reference file url */
ng1App.filter('drFileUrl',
    ['apiUrl',
     function (apiUrl) {
    return function (val) {
        return val && val.id ? (apiUrl + '/attachments/file/' + val.id) : '/assets/img/profile.png';
    };
}]);

ng1App.filter('console', function () {
    return function (val) {
        console.log(val);
        return val;
    };
});

ng1App.filter('foreignAddress', function () {
    return function (addr) {
        var parts = [];
        if (addr.text) {
            parts.push(addr.text);
        }
        if (addr.city) {
            parts.push(addr.city);
        }
        if (addr.country) {
            parts.push(addr.country.name);
        }
        return parts.join(', ');
    };
});

ng1App.filter('fullName',
    ['$filter',
     function ($filter) {
    return function (person) {
        return $filter('capitalize')(_.getFullName(person));
    };
}]);

ng1App.filter('fullNameOrEsi',
    ['$filter',
     function ($filter) {
    return function (person) {
        return (person.givenName && person.familyName) ? $filter('capitalize')(_.getFullName(person)) : ('ESI Nr. ' + person.esiNr);
    };
}]);

ng1App.filter('codeName', function () {
    return function (entity, separator) {
        return _.getCodeName(entity, separator);
    };
});

ng1App.filter('listFilter',
    ['$filter',
     function ($filter) {

    var match = function (item, query, path, type) {
        var parts = path.split('.');
        var value = item;
        for (var i = 0; i < parts.length; i++) {
            var pos1 = parts[i].search("\\[");
            var pos2 = parts[i].search("\\]");
            var index = -1;
            if (pos1 != -1 && pos2 != -1) {
                index = parts[i].slice(pos1 + 1, pos2);
                parts[i] = parts[i].slice(0, pos1);
            }
            if (index != -1 && !isNaN(index)) {
                value = value[parts[i]][index];
            } else {
                value = value[parts[i]];
            }
            if (value === undefined || value === null) {
                return false;
            }
        }
        switch (type) {
            case 'date':
                return $filter('date')(value, 'yyyy-MM-dd').indexOf(query) > -1;
            case 'datetime':
                return $filter('date')(value, 'yyyy-MM-dd HH:mm').indexOf(query) > -1;
            case 'number':
                return value == query;
            default :
                return String(value).toUpperCase().indexOf(query.toUpperCase()) > -1;
        }
    };


    return function (list, query, paths, data) {
        //console.log(list)
        var ctrl = data || {};
        var result = [];
        if (query === undefined || query === null || query === '') {
            result = list;
        } else if (!paths || paths.length === 0) {
            result = $filter('filter')(list, query);
        } else {
            result = _.filter(list,
     function (item) {
                for (var i = 0; i < paths.length; i++) {
                    var parts = paths[i].split('|');
                    if (match(item, query, parts[0], parts[1])) {
                        return true;
                    }
                }
                return false;
            });
        }
        ctrl.total = list ? list.length : 0;
        ctrl.filtered = result ? result.length : 0;
        return result;
    };
}]);

ng1App.filter('vaccinationStatusPresent', function () {
    return function (list, query) {
        var result = false;
        if (query === undefined || query === null || query === '' || !list) {
            return result;
        } else {
            _.each(list, function (item) {
                if (!result) {
                    switch (query) {
                        case 'overdue':
                            result = _.truncateToDays(new Date().getTime()) > _.truncateToDays(item.immunizationRecommendation.recommendations[0].plannedDate) &&
                                    item.immunizationRecommendation.compositionId == undefined &&
                                    !item.immunizationRecommendation.isVaccinationFact && item.immunizationRecommendation.recommendations[0].status.code != 'entered in error';
                            break;
                        case 'planned':
                            result = _.truncateToDays(new Date().getTime()) <= _.truncateToDays(item.immunizationRecommendation.recommendations[0].plannedDate) &&
                                    item.immunizationRecommendation.compositionId == undefined &&
                                    !item.immunizationRecommendation.isVaccinationFact && item.immunizationRecommendation.recommendations[0].status.code != 'entered in error';
                            break;
                        case 'done':
                            result = item.immunizationRecommendation.isVaccinationFact;
                            break;
                        case 'underway':
                            result = item.immunizationRecommendation.compositionId != undefined && !item.immunizationRecommendation.isVaccinationFact;
                            break;
                        case 'ignored':
                            result = item.immunizationRecommendation.recommendations[0].status.code == 'entered in error';
                            break;
                        default :
                            result = false;
                    }
                }
            });
        }
        return result;
    };
});

ng1App.filter('vaccinationStatus', function () {
    return function (item, query) {
        if (query === undefined || query === null || query === '' || !item) {
            return false;
        } else {
            switch (query) {
                case 'overdue':
                    return _.truncateToDays(new Date().getTime()) > _.truncateToDays(item.recommendations[0].plannedDate) &&
                            item.compositionId == undefined &&
                            !item.isVaccinationFact && item.recommendations[0].status.code != 'entered in error';
                case 'planned':
                    return _.truncateToDays(new Date().getTime()) <= _.truncateToDays(item.recommendations[0].plannedDate) &&
                            item.compositionId == undefined &&
                            !item.isVaccinationFact && item.recommendations[0].status.code != 'entered in error';
                case 'done':
                    return item.isVaccinationFact;
                case 'underway':
                    return item.compositionId != undefined && !item.isVaccinationFact;
                case 'ignored':
                    return item.recommendations[0].status.code == 'entered in error';
                default :
                    return false;
            }
        }
    };
});

ng1App.filter('orderByVaccinationStatus', function () {
    return function (list) {
        if (!angular.isObject(list))
            return list;

        var array = [];
        for (var objectKey in list) {
            array.push(list[objectKey]);
        }

        var itemStatusCode = function (item) {
            var res = 0;
            if (_.truncateToDays(new Date().getTime()) > _.truncateToDays(item.immunizationRecommendation.recommendations[0].plannedDate) &&
                    item.immunizationRecommendation.compositionId == undefined &&
                    !item.immunizationRecommendation.isVaccinationFact && item.immunizationRecommendation.recommendations[0].status.code != 'entered in error') {
                res = 4;
            }
            if (_.truncateToDays(new Date().getTime()) <= _.truncateToDays(item.immunizationRecommendation.recommendations[0].plannedDate) &&
                    item.immunizationRecommendation.compositionId == undefined &&
                    !item.immunizationRecommendation.isVaccinationFact && item.immunizationRecommendation.recommendations[0].status.code != 'entered in error') {
                res = 3;
            }
            if (item.immunizationRecommendation.isVaccinationFact) {
                res = 1;
            }
            if (item.immunizationRecommendation.compositionId != undefined && !item.immunizationRecommendation.isVaccinationFact) {
                res = 2;
            }
            if (item.immunizationRecommendation.recommendations[0].status.code == 'entered in error') {
                res = 0;
            }
            return res;
        };

        array.sort(function (a, b) {
            var prio_a = itemStatusCode(a);
            var prio_b = itemStatusCode(b);
            return prio_b - prio_a;
        });

        return array;
    };
});

ng1App.filter('join', function () {
    return function (list, sepatator, transform) {
        var sep = sepatator || ', ';
        if (transform) {
            return list ? _.map(list, transform).join(sep) : undefined;
        } else {
            return list ? list.join(sep) : undefined;
        }
    };
});

/*ng1App.filter('_date',
    ['$filter',
     function ($filter) {
    return function (value, defaultValue) {
        return value ? $filter('date')(value, 'yyyy-MM-dd') : $filter('translate')(defaultValue);
    };
}]);*/

ng1App.filter('time',
    ['$filter',
     function ($filter) {
    return function (value, defaultValue) {
        return value ? $filter('date')(value, 'HH:mm') : $filter('translate')(defaultValue);
    };
}]);

ng1App.filter('datetime',
    ['$filter',
     function ($filter) {
    return function (value, defaultValue) {
        return value ? $filter('date')(value, 'yyyy-MM-dd HH:mm') : $filter('translate')(defaultValue);
    };
}]);

ng1App.filter('timeUnit', function () {
    return function (value) {
        if (value) {
            var tempVal = value.displayCode ? value.displayCode : value;
            switch (tempVal) {
                case 's':
                    return 'sec.';
                case 'min':
                    return 'min.';
                case 'h':
                    return 'val.';
                case 'd':
                    return 'd.';
                case 'wk':
                    return 'sav.';
                case 'mo':
                    return 'mėn.';
                case 'a':
                    return 'm.';
            }
        } else {
            return '';
        }
    };
});

ng1App.filter('capitalize', function () {
    return function (string) {
        return string ? string.replace(/([^\s-]*) */g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) : string;
    };
});

ng1App.filter('encounterTitle', function () {
    return function (encounter) {
        if (!encounter) {
            return '';
        }
        if (encounter.encounterType == 'pharmacy') {
            return 'Atvykimas į vaistinę';
        }
        if (encounter.event) {
            if (encounter.event.code === 'inpatient') {
                return 'Atvykimas į stacionarą';
            } else if (encounter.event.code === 'ambulatory') {
                return 'Atvykimas į SPĮ gauti abulatorinių paslaugų';
            } else if (encounter.event.code === 'virtual') {
                return 'Paslaugų suteikimas pacientui neatvykus į SPĮ';
            }
        }
        return 'Kita';
    };
});

ng1App.filter('editable', function () {
    return function (importedData, property, options) {
        return _.find(importedData, function (item) {
            var result = item.propertyName === property;
            if (result && options && _.has(options, 'rid')) {
                result = result && item.resourceId === options.rid;
            }
            if (result && options && _.has(options, 'pid')) {
                result = result && item.propertyIdentifier === options.pid;
            }

            return result;
        }) ? false : true;
    };
});

ng1App.filter('imported', function () {
    return function (importedData, property, options) {
        return _.find(importedData, function (item) {
            var result = item.propertyName === property;
            if (result && options && _.has(options, 'rid')) {
                result = result && item.resourceId === options.rid;
            }
            if (result && options && _.has(options, 'pid')) {
                result = result && item.propertyIdentifier === options.pid;
            }
            return result;
        }) ? true : false;
    };
});

ng1App.filter('matchPortal',
    ['portalType',
     function (portalType) {
    return function (portal) {
        return portalType === portal;
    }
}]);

ng1App.filter('strip', function () {
    return function (text, length) {
        var len = length || 90;
        if (angular.isString(text)) {
            return text.length > len ? (text.substring(0, len) + '...') : text;
        } else {
            return text;
        }
    }
});


ng1App.filter('workplaceHasUniqueDept', function () {
    return function (wp) {
        if(!wp || !wp.departments){
            return false;
        }
        var orgId = wp.organization.id;
        angular.forEach(wp.departments, function (dept) {
            if(dept.organization.id != orgId){
                return true;
            }
        });
        return false;
    };
});


ng1App.filter('trueFalse', function () {
    return function (value) {
        if(value && value == true){
            return 'Taip';
        }
        return 'Ne';
    };
});

ng1App.filter('yesNo', function () {
    return function (value) {
        return value ? 'yes' : 'no';
    };
});