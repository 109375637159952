<div class="collapse multi-collapse" id="advancedFilter">
    <div class="custom-search border-0 border-dashed-bottom">
        <div class="custom-filter p-4">
            <div class="row">
                <div class="col-md-6 col-xxl-5 d-flex flex-column mb-3">
                    <strong>{{ "pp.dia.sea.dat" | translate }}</strong>
                    <div class="row">
                        <app-date-picker
                            class="col-md mt-2"
                            [fControl]="formGroup.get('fromDate')"
                            inputId="fromDate"
                            [placeholder]="'pp.dia.sea.dat.from' | translate"
                            suffixIcon="fa fa-calendar"
                        ></app-date-picker>
                        <app-date-picker
                            class="col-md mt-2"
                            [fControl]="formGroup.get('toDate')"
                            inputId="toDate"
                            [placeholder]="'pp.dia.sea.dat.to' | translate"
                            suffixIcon="fa fa-calendar"
                        ></app-date-picker>
                    </div>
                </div>
                <div class="col-md-6 col-xxl-3 mb-3">
                    <strong>{{ "doc.dia.sts" | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('diagnosisStatus')"
                        class="mt-2 d-block"
                        [dropdownDataList]="statuses"
                        [singleSelection]="false"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                    ></app-multiselect-dropdown>
                </div>
                <div class="col-md-6 col-xxl-4 mb-3">
                    <strong>{{ "doc.dia.con" | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('diagnosisConfidence')"
                        class="mt-2 d-block"
                        [dropdownDataList]="confidences"
                        [singleSelection]="false"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                    ></app-multiselect-dropdown>
                </div>
                <div class="col-md-6 col-xxl-4 mb-3">
                    <strong>{{ "doc.dia.sev" | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('diagnosisSeverity')"
                        class="mt-2 d-block"
                        [dropdownDataList]="severities"
                        [singleSelection]="false"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                    ></app-multiselect-dropdown>
                </div>
                <div class="col-md-6 col-xxl-4 mb-3">
                    <strong>{{ "doc.dia.cat" | translate }}</strong>
                    <app-multiselect-dropdown
                        [fControl]="formGroup.get('diagnosisCategory')"
                        class="mt-2 d-block"
                        [dropdownDataList]="categories"
                        [singleSelection]="false"
                        [idField]="'id'"
                        [textField]="'name'"
                        [allowSearchFilter]="false"
                    ></app-multiselect-dropdown>
                </div>
                <!-- <div class="col-md-6 col-xxl-4 mb-3">
                    <strong>{{'doc.dia.dia.typ' | translate}}</strong>
                    <app-multiselect-dropdown
                            [fControl]="formGroup.get('diagnosisType')"
                            class="mt-2 d-block"
                            [dropdownDataList]="types"
                            [singleSelection]="false"
                            [idField]="'id'"
                            [textField]="'name'"
                            [allowSearchFilter]="false"
                    ></app-multiselect-dropdown>
                </div> -->
            </div>
            <div class="d-flex justify-content-end gap-3">
                <button class="btn btn-outline-primary" (click)="clearFilters()">
                    {{ "pp.dia.sea.clearFilt" | translate }}
                </button>
                <button class="btn btn-primary" (click)="applyFilters()">{{ "pp.dia.sea.srch" | translate }}</button>
            </div>
        </div>
    </div>
</div>
