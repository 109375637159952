import {Component, OnDestroy} from '@angular/core';
import {IStatusPanelParams} from 'ag-grid-community';

@Component({
    selector: 'ag-pagination-bar',
    templateUrl: './ag-pagination-bar.component.html'
})
export class AgPaginationBarComponent implements OnDestroy{
    params: IStatusPanelParams;
    dotsJump: number = 9;
    pagesToShowArrows: number = 6;
    pagesTillCurrent: number = 4;
    pageSize: number = 10;
    rowsCount: number;
    pagesCount: number;
    currentPage: number;
    realCurrentPage: number;
    pagesToIterate: Array<number> = [];
    typedPage: number;

    initForAgGrid(params: any): void {
        this.params = params;
        this.params.api.addEventListener('modelUpdated', () => {
            this.setPagination();
        });

        this.params.api.addEventListener('paginationChanged', () => {
            this.setPagination();
        });
    }

    setPagination() {
        this.pageSize = this.params.api.paginationGetPageSize();
        this.rowsCount = this.params.api.paginationGetRowCount();
        this.pagesCount = this.params.api.paginationGetTotalPages();
        this.currentPage = this.params.api.paginationGetCurrentPage();
        this.realCurrentPage = this.currentPage + 1;

        const pages = Array.from(Array(this.pagesCount).keys());
        this.pagesToIterate = pages.filter(p => p !== 0 &&
            p !== this.pagesCount - 1 &&
            p <= this.currentPage + this.pagesTillCurrent &&
            p >= this.currentPage - this.pagesTillCurrent
        );
    }

    onFirstPageSelect() {
        event.preventDefault();
        this.params.api.paginationGoToFirstPage();
    }

    onLastPageSelect() {
        event.preventDefault();
        this.params.api.paginationGoToLastPage();
    }

    onPageSelect(page: number) {
        event.preventDefault();
        this.params.api.paginationGoToPage(page);
    }

    onPageEnter(page: any){
        event.preventDefault();
        const calculatedPage: number = parseInt(page);
        this.params.api.paginationGoToPage(!isNaN(calculatedPage) ? calculatedPage - 1 : 0);
    }

    onDotsSelect(page: number) {
        event.preventDefault();
        this.params.api.paginationGoToPage(page);
    }

    onPrevSelect() {
        event.preventDefault();
        this.params.api.paginationGoToPreviousPage();
    }

    onNextSelect() {
        event.preventDefault();
        this.params.api.paginationGoToNextPage();
    }

    showPrev(orExact?: boolean) {
        return orExact ?
            this.realCurrentPage >= this.pagesToShowArrows :
            this.currentPage > this.pagesToShowArrows;
    }

    showPrevDots() {
        return this.showPrev(true) && this.realCurrentPage - this.pagesToShowArrows > 0;
    }

    showNext(orExact?: boolean) {
        return orExact ?
            this.pagesCount - this.realCurrentPage >= this.pagesToShowArrows :
            this.pagesCount - this.realCurrentPage > this.pagesToShowArrows;
    }

    showNextDots() {
        return this.showNext(true) && this.realCurrentPage !== this.pagesCount - 1;
    }

    showAfterFirst() {
        return this.realCurrentPage - this.pagesTillCurrent === 3;
    }

    showBehindLast() {
        return this.pagesCount - this.realCurrentPage - this.pagesTillCurrent === 2;
    }

    getButtonClass(page: number): string {
        return page === this.currentPage ? 'btn-primary' : 'btn-outline-primary';
    }

    calculateBackJump() {
        const page: number = this.currentPage - this.dotsJump;
        return page >= 0 ? page : 1;
    }

    calculateForwardJump() {
        const page: number = this.currentPage + this.dotsJump;
        return page <= this.pagesCount ? page : this.pagesCount - 2;
    }

    ngOnDestroy(): void {
        this.params.api.removeEventListener('modelUpdated', null);
        this.params.api.addEventListener('paginationChanged', null);
    }
}