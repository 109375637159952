ng1App.controller('document.E014aEditCtrl', 
    ['$scope', 'MessageService', 'DocumentCtx', 'DocumentModificationActions', '$filter',
     function ($scope, MessageService, DocumentCtx, DocumentModificationActions, $filter) {
    MessageService.clearAll();
    
    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(5);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'}; 

    $scope.data = {};
    $scope.patientId = DocumentCtx.patientId;

    $scope._shared.customValidator = function (documentForm, skipRequired) {
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        MessageService.clear('pat-results');
        if (!$scope.form.patalogyTestResults.dateOfReceipt || (_.truncateToDays($scope.form.orderData.item.patData.dateTaken) > _.truncateToDays($scope.form.patalogyTestResults.dateOfReceipt))) {
            MessageService.add('error', 'Medžiagos gavimo data turi būti vėlesnė nei paėmimo data ir laikas.', 'pat-results');
            if (res) {
                _.scrollTo('[msg-region="pat-results"]');
            }
            res = false;
        }
        if ($scope.form.patalogyTestResults.dateOfReceipt && (!$scope.form.patalogyTestResults.dateOfResults || (_.truncateToDays($scope.form.patalogyTestResults.dateOfResults) < _.truncateToDays($scope.form.patalogyTestResults.dateOfReceipt)))) {
            MessageService.add('error', 'Atsakymo data turi būti vėlesnė nei medžiagos gavimo data.', 'pat-results');
            if (res) {
                _.scrollTo('[msg-region="pat-results"]');
            }
            res = false;
        }
        return res;
    }

}]);