ng1App.directive('f025alkEncounterMedications', 
    ['SubFormListController', 'TypeaheadSearchHandlers', 'CommonFunctionService', '$modalConfirm', 'EntitiesApiFactory',
     function (SubFormListController, TypeaheadSearchHandlers, CommonFunctionService, $modalConfirm,
                                                          EntitiesApiFactory) {
	var FLOAT_REGEXP = /^\-?\d{1,8}((\.|\,)\d{0,2})?$/;
	
	var initForm = function (scope) {
		scope.formCtrl = new SubFormListController(scope.encounter.medications, 'medications');
		scope.formCtrl._appendTop = true;
		scope.formCtrl._data.medicationSearch = null;
		scope.formCtrl._data.currentArticles = [];
		scope.formCtrl.searchForMedication = TypeaheadSearchHandlers.CP_MEDICATION;
		scope.formCtrl.validateForm = function (form) {
			this.clear();
			
			console.log(form);
			
			if (!form.medicationId) {
				this._validator.add('medication');
			}
			if (!form.centralizedProcurementArticle) {
				this._validator.add('article');
			}
			if (form.cost && !FLOAT_REGEXP.test(form.amount)) {
				this._validator.add('amount');
			}
			
            return !this._validator.hasErrors();
		};
		scope.formCtrl.before = function (action) {
            if (action == 'cancel') {
                this.clear();
        		this._data.medicationSearch = null;
            }
        };
        scope.formCtrl.after = function (action) {
        	if (action == 'add' || action == 'save') {
        		this._data.medicationSearch = null;
        		this._data.currentArticles = [];
        	} else if (action == 'edit') {
        		EntitiesApiFactory.getList({classCode: 'cp-medication', medicationId: this._form.medicationId}, function (result) {
        			scope.formCtrl.resolveMedication(result[0]);
        			scope.formCtrl._data.medicationSearch = result[0];
        			result[0].label = result[0].medicationId + ' ' + result[0].genericTitle;
        		});
        	}
        };
        scope.formCtrl.editValue = function (index) {
        	this.edit(index);
        	CommonFunctionService.gotoAnchor('edit');
        };
        scope.formCtrl.deleteValue = function (index) {
            this.before('delete');
            var _this = this;
            $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
            	if (_this._list[index].id) {
            		_this._list[index].removed = true;
            	} else {
            		var removed = _this._list.splice(index, 1);
            		_this.onDelete(removed[0]);
            		_this.after('delete');
            	}
            });
        };
        scope.formCtrl.changeMedication = function (medication) {
        	if (medication != null) {
        		if (medication.medicationId) {
        			this.resolveMedication(medication);
        		}
        	} else {
        		this._form = this.initForm();
        		this._data.currentArticles = [];
        	}
        };
        scope.formCtrl.resolveMedication = function (medication) {
			this._form.medicationId = medication.medicationId;
			this._form.medicationTitle = medication.genericTitle;
			this._form.measure = medication.measure;
			
			this._data.currentArticles = [];
			for (var i = 0; i < medication.budgetArticles.length; i++) {
				this._data.currentArticles.push(medication.budgetArticles[i].code);
			}
			if (this._form.centralizedProcurementArticle == null) {
				this._form.centralizedProcurementArticle = this._data.currentArticles[0];
        	}
        };
        scope.formCtrl.init();
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f025alk/parts/medications.html',
		restrict: 'E',
		scope: {
			encounter: '='
		},
		link: function (scope: any, element, attrs) {
			scope.encounter.$promise.then(function () {
				initForm(scope);
			});
		}
	};
}]);