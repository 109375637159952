import { Component, DestroyRef, EventEmitter, Input, Output } from "@angular/core";
import { RouteParams } from "../../../../ng1.routeParams";
import { BsModalService } from "ngx-bootstrap/modal";
import { FormControl } from "@angular/forms";
import { ApiV2Patients } from "../../../../shared/services/api-v2-patients";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EditAllergyModalComponent } from "./edit-allergy-modal/edit-allergy-modal.component";
import { ApiV2Allergies } from "../../../../shared/services/api-v2-allergies";
import { SelectOption } from "../../../../shared/components/select/select.component";
import { Observable, Observer, combineLatest, map, of, switchMap, take } from "rxjs";
import { EntityModel } from "../../../../shared/models/entity.model";
import { AllergyModel } from "../../../../shared/models/allergy.model";
import { ApiV2Entities } from "../../../../shared/services/api-v2-entities";
import { CurrentAllergiesListModalComponent } from "../../../../shared/components/current-allergies-list-modal/current-allergies-list-modal";

@Component({
    selector: "app-allergies-form",
    templateUrl: "./allergies-form.component.html",
})
export class AllergiesFormComponent {
    patientId: string;
    @Input() currentAllergies: AllergyModel[] = [];
    newAllergies: AllergyModel[] = [];
    @Output() currentAllergiesChange = new EventEmitter<AllergyModel[]>();
    allergy: FormControl = new FormControl();
    allergen: FormControl = new FormControl();
    reactionsList: EntityModel[] = [];
    reactions: SelectOption[] = [];
    allergensList: EntityModel[] = [];
    allergens: SelectOption[] = [];
    isSubmitAllergyReportChecked: boolean = false;
    showInfoMessage: boolean = true;
    allergensFactorsSuggestions$: Observable<AllergyModel[]>;
    allergiesFactorsSuggestions$: Observable<SelectOption[]>;
    validAllergySelected = false;
    validAllergenSelected = false;
    allergySelectedValue: EntityModel;
    allergenSelectedValue: EntityModel;
    @Input() viewOnly: boolean = false;

    constructor(
        private modalService: BsModalService,
        private apiV2Patients: ApiV2Patients,
        private params: RouteParams,
        private destroyRef: DestroyRef,
        private ApiV2Entities: ApiV2Entities
    ) {
        this.patientId = this.params.patientId;
        this.apiV2Patients
            .getActiveAllergies(this.patientId)
            .pipe(takeUntilDestroyed())
            .subscribe((res) => {
                this.newAllergies = res;
            });
        this.initializeAllergiesSuggestions();
        this.initializeAllergenSuggestions();

        // this.ApiV2Entities.get('get-allergens').pipe(takeUntilDestroyed())
        //     .subscribe(res => {
        //         this.allergensList = res;
        //         this.allergens = res.map(allergen => ({text: allergen.name, value: allergen.id}));
        //     });
        // this.ApiV2Entities.get('get-reactions').pipe(takeUntilDestroyed())
        //     .subscribe(res => {
        //         this.reactionsList = res;
        //         this.reactions = res.map(reaction => ({text: reaction.name, value: reaction.id}));
        //     });
    }

    handleCheckboxChange(event: Event) {
        this.isSubmitAllergyReportChecked = (event.target as HTMLInputElement).checked;
    }

    initializeAllergenSuggestions() {
        this.allergensFactorsSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.allergen.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.ApiV2Entities.getEntitiesList("allergen", { searchCriteria: query }).pipe(
                        map((data: any[]) => data && data.map((entities) => this.getEntityInfo(entities) || []))
                    );
                }
                return of([]);
            })
        );
    }

    initializeAllergiesSuggestions() {
        this.allergiesFactorsSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.allergy.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.ApiV2Entities.getEntitiesList("allergy-reaction", { searchCriteria: query }).pipe(
                        map((data: any[]) => data && data.map((entities) => this.getEntityInfo(entities) || []))
                    );
                }
                return of([]);
            })
        );
    }

    allergySelected(event: any): void {
        this.allergySelectedValue = event.item;
        this.validAllergySelected = true;
        this.checkAndOpenModal();
    }

    allergenSelected(event: any): void {
        this.allergenSelectedValue = event.item;
        this.validAllergenSelected = true;
        this.checkAndOpenModal();
    }

    checkAndOpenModal(): void {
        if (this.validAllergySelected && this.validAllergenSelected) {
            this.openAddAllergyModal();
            this.validAllergySelected = false;
            this.validAllergenSelected = false;
        }
    }

    openEditAllergyModal(i: number) {
        const initialState = {
            allergy: this.currentAllergies[i],
            isEditInitiated: true,
        };
        const ref = this.modalService.show(EditAllergyModalComponent, {
            initialState,
            animated: true,
            class: "modal-xxl",
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: AllergyModel) => {
            this.currentAllergies[i] = { ...result };
            this.emitCurrentAllergies();
        });
    }

    openAddAllergyModal() {
        const initialState = {
            allergy: {
                code: this.allergySelectedValue,
                substance1: this.allergenSelectedValue,
            } as AllergyModel,
            isEditInitiated: false,
        };
        const ref = this.modalService.show(EditAllergyModalComponent, {
            initialState,
            animated: true,
            class: "modal-xxl",
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: AllergyModel) => {
            if (!this.currentAllergies) {
                this.currentAllergies = []; // Initialize it if it's undefined
              }
            this.currentAllergies.push(result);
            this.emitCurrentAllergies();
            this.allergy.reset();
            this.allergen.reset();
        });
    }

    delete(i: number) {
        this.currentAllergies.splice(i, 1);
        this.emitCurrentAllergies();
    }

    openCurrentsAllergiesModal(): void {
        const initialState = {
            allergies: this.newAllergies,
        };
        const ref = this.modalService.show(CurrentAllergiesListModalComponent, {
            initialState,
            animated: true,
            class: "modal-xxl",
        });
    }

    closeInfoMessage() {
        this.showInfoMessage = false;
    }

    getEntityInfo(entities: EntityModel) {
        return {
            id: entities.id,
            code: entities.code,
            name: entities.name,
        };
    }

    private emitCurrentAllergies(): void {
        this.currentAllergiesChange.emit(this.currentAllergies);
    }
}
