<nhr-record-widget
    [closable]="true"
    [closed]="false"
    [title]="title | translate"
>
    <div class="card-body">
        <div class="row" *ngFor="let row of rows">
            <div class="py-4 bg-light fw-bold mb-3" *ngIf="row.name">{{row.name | translate}}</div>
            <div
                class="col-6 col-lg-4"
                [class.col-12]="row.style === 'list'"
                [class.col-lg-12]="row.style === 'list'"
                *ngFor="let item of row.rows">
                <div *ngIf="item?.subGroup" class="fw-bold mb-3 mt-2">{{item?.subGroup | translate}}</div>
                <div *ngIf="item?.name"
                     [class.fw-bold]="item?.boldTitle"
                     [class.fst-italic]="item?.italicStyle"
                     class="mb-1" >{{item?.name | translate}}</div>
                <ul [class.ps-0]="row.style !== 'list'">
                    <ng-container *ngIf="item.value && valueIsArray(item.value)">
                        <ng-container *ngFor="let value of item.value">
                            <li *ngIf="!!value && row.style === 'list'">{{value}}</li>
                            <div *ngIf="!!value && row.style !== 'list'">{{value}}</div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="item.value && !valueIsArray(item.value)">
                        <li *ngIf="row.style === 'list'">{{item.value}}</li>
                        <div *ngIf="row.style !== 'list'">{{item.value}}</div>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</nhr-record-widget>