import './e048_practitioner/view_e048_practitioner';
import './e047_practitioner/view_e047_practitioner';
import './e048_employer/view_e048_employer';
import './e047_employer/view_e047_employer';
import './components/certificate_service';
import './components/certificate_payment';
import './components/components';
import './e027_1_II/view_e027_1_II';
import './e106_2_1/view_e106_2_1';
import './e027_1_I/view_e027_1_I';
import './e103_1/view_e103_1';
import './e083_1/view_e083_1';
import './e106/view_e106';
import './e049/view_e049';