<div class="modal-body">
    <div class="row req">
        <div class="col" [formGroup]="visitFormG">
            <app-input id="cancelVisit" type="text" [fControl]="visitFormG.get('visitCancelNote')"
                labelText="Ar patvirtinate pasirinkto apsilankymo / apsilankymų serijos atšaukimą?" />
        </div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="modalService.config.initialState.visitId" type="button" class="btn btn-primary"
        data-bs-dismiss="modal" (click)="action(modalService.config.initialState.visitId)">{{'Taip'|translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="modalService.hide()">{{'Ne'|translate}}</button>
</div>