angular.module('erx.api').factory('ErxParametersApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
    var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Parameter';
    return $resource('', {}, {
        // Paciento gavimas pagal ID
        getMethodologicalHelpUrl: {
            method: 'GET',
            url: URL_BASE + '/methodological-help-url',
            isArray: false
        }
    });
}]);