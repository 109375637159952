import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    E096PtklPostpartumHemorrhageStartComponent
} from '../e096-ptkl-postpartum-hemorrhage-start/e096-ptkl-postpartum-hemorrhage-start.component';
import {
    E096PtklAssessmentWomanConditionComponent
} from '../e096-ptkl-assessment-woman-condition/e096-ptkl-assessment-woman-condition.component';
import { E096PtklActionsTakenComponent } from '../e096-ptkl-actions-taken/e096-ptkl-actions-taken.component';
import { E096PtklEndAssistanceComponent } from '../e096-ptkl-end-assistance/e096-ptkl-end-assistance.component';

@Component({
    selector: 'app-e096-ptkl-assisting-with-postpartum-hemorrhage',
    templateUrl: './e096-ptkl-assisting-with-postpartum-hemorrhage.component.html',
})
export class E096PtklAssistingWithPostpartumHemorrhageComponent implements AfterViewInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;

    @ViewChild(E096PtklPostpartumHemorrhageStartComponent) startComponent: E096PtklPostpartumHemorrhageStartComponent;
    @ViewChild(E096PtklAssessmentWomanConditionComponent) womanConditionComponent: E096PtklAssessmentWomanConditionComponent;
    @ViewChild(E096PtklActionsTakenComponent) actionsTakenComponent: E096PtklActionsTakenComponent;
    @ViewChild(E096PtklEndAssistanceComponent) endAssistanceComponent: E096PtklEndAssistanceComponent;

    formGroup: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngAfterViewInit(): void {
        this.formGroup = this.fb.group({
            start: this.startComponent.formGroup,
            assessmentWomanCondition: this.womanConditionComponent.formGroup,
            actionsTaken: this.actionsTakenComponent.formGroup,
            endAssistance: this.endAssistanceComponent.formGroup
        });
    }
}
