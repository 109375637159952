<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'doc.healthAssessment.form.factor' | translate }}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="formGroup">
    <div class="row mb-3">
        <div class="col-md-6 col-lg-auto">
            <div class="d-flex gap-2">
                <span class="d-block">{{ 'doc.healthAssessment.form.factor' | translate }}:</span>
                <span class="fw-bold">{{ risk.riskFactor?.name }}</span>
            </div>
        </div>
        <div class="col-lg">
            <div class="row">
                <div class="col d-flex gap-2 me-5 justify-content-end">
                    <span class="d-block fw-bold">{{ 'doc.dia.mod.reg' | translate }}
                        : </span> {{ (risk?.date | date: 'YYYY-MM-dd') || '-' }}
                </div>
            </div>
        </div>
    </div>

    <div class="row req">
        <app-input
            id="riskFactorMethodologyName"
            type="textarea"
            class="d-block mb-3"
            [labelText]="'doc.healthAssessment.form.assessmentMet' | translate"
            labelClass="col-form-label"
            [fControl]="formGroup?.get('riskFactorMethodologyName')"
            [ngClass]="{ 'is-invalid': formGroup?.get('riskFactorMethodologyName')?.invalid }"
        />
    </div>
    <ng-container *ngIf="(isAuditTestRequired || isFagerstormTestRequired) && !isEditMode">
        <app-risk-test *ngIf="isAuditTestRequired" test="AUDIT-C" (onTestComplete)="getScore($event)"></app-risk-test>
        <app-risk-test *ngIf="isFagerstormTestRequired" test="Fagerstorm"
                       (onTestComplete)="getScore($event)"></app-risk-test>
    </ng-container>
    <app-input
        id="assessmentScore"
        type="number"
        class="d-block mb-3"
        [labelText]="'doc.healthAssessment.form.assessment' | translate"
        [fControl]="formGroup?.get('assessmentScore')"
    />
    <app-input
        id="comments"
        type="textarea"
        [labelText]="'doc.healthAssessment.form.assessmentDesc' | translate"
        [fControl]="formGroup?.get('comments')"
    />
    <app-checkbox
        inputId="patientConsent"
        [fControl]="formGroup?.get('patientConsent')"
        labelClass="fw-bold"
        checkboxChecked="true"
        checkboxValue="true"
        labelText="{{'riskFactor.patientConsent' | translate}}">
    </app-checkbox>

</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="formGroup?.invalid" (click)="close(true)"><span
        class="fa fa-plus"></span> {{ 'doc.dia.btn.con' | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="close()"><span
        class="fa fa-times"></span> {{ 'doc.dia.btn.can' | translate }}
    </button>
</div>
