import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../../config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2PatientSummary extends ConfigService {
    path = this.apiUrl + '/patient-summary';

    getSummary(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary`;
        return this.http.get<any>(url, params);
    }

    saveSummary(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary`;
        return this.http.post<any>(url, params);
    }

    saveDraft(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/draft`;
        return this.http.post<any>(url, params);
    }

    updateDraft(patientId: string, draftId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/draft/${draftId}`;
        return this.http.post<any>(url, params);
    }

    getDraft(patientId: string, draftId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/draft/${draftId}`;
        return this.http.get<any>(url, params);
    }

    getDraftForEdit(patientId: string, draftId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/draft/${draftId}/edit`;
        return this.http.get<any>(url, params);
    }

    getSummaryHistory(patientId: string, historyId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/history/${historyId}/edit`;
        return this.http.get<any>(url, params);
    }

    getSummaryHistoryLast(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/history/last`;
        return this.http.get<any>(url, params);
    }

    hasSummary(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/has-summary`;
        return this.http.get<any>(url, params);
    }

    getDocumentListStatus(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/documentStatus`;
        return this.http.get<any>(url, params);
    }

    getPatientSummary(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/dashboard`;
        return this.http.get<any>(url, params);
    }

    getPatientStatus(patientId: string, params?: any): Observable<any> {
        const url = `${this.path}/${patientId}/summary/flags`;
        return this.http.get<any>(url, params);
    }
}