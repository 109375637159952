ng1App.controller('doctor.patient.F025alkEncounterEditCtrl', 
    ['$scope', '$window', 'MessageService', '$routeParams', 'SveidraApiFactory', '$routeSegment', '$filter', '$modalConfirm', 'TypeaheadSearchHandlers', 'EntitiesApiFactory', 'SveidraCommons', 'DocumentsApiFactory', '$location', 'CommonFunctionService', 'DocumentSpinnerHelper', 'FormLoaderService', '$q',
     function ($scope, $window, MessageService, $routeParams, SveidraApiFactory, $routeSegment,
                                                                       $filter, $modalConfirm, TypeaheadSearchHandlers, EntitiesApiFactory, SveidraCommons,
                                                                       DocumentsApiFactory, $location, CommonFunctionService, DocumentSpinnerHelper,
                                                                       FormLoaderService, $q) {
	FormLoaderService.startFormLoading();
	
	$scope.$emit('setHelpPage', 'ivesti-koreguoti-apsilankymo-duomenis');

	$scope.goBack = function(){
        $window.history.back();
    };
	
	MessageService.clearAll();
	
	$scope.mode = $routeParams.encounterId ? 'edit' : 'new';
	$scope.encounterId = $routeParams.encounterId;
	
	$scope.loadingSpinnerHelper = DocumentSpinnerHelper;
	
    $scope.serviceTypes = EntitiesApiFactory.getList({classCode: 'sveidra-service-type'});
    $scope.encounterResults = EntitiesApiFactory.getList({classCode: 'sveidra-encounter-result'});
	
	if ($scope.mode == 'edit') {
		$scope.encounter = SveidraApiFactory.getEncounter({formId: $routeParams.formId, id: $scope.encounterId});
	} else {
		$scope.encounter = SveidraApiFactory.getPrefilledEncounter({formId: $routeParams.formId, documentId: $routeParams.documentId});
	}
	
	FormLoaderService.endFormLoading($q.all([$scope.encounter.$promise, $scope.serviceTypes.$promise, $scope.encounterResults.$promise]));
    
	// Miscellaneous stuff
	$scope.additional = {
		healthServiceSearch: null,
		revisedHealthServiceSearch: null,
		
		diagnosisFilter: null,
		selectDiagnosis: false
	};
	
	// Search for health service
	$scope.searchForHealthService = TypeaheadSearchHandlers.HEALTH_SERVICE;
	
	$scope.changeHealthService = function (service) {
		if (service) {
			if (service.code) {
				$scope.encounter.service.code = service;
			}
		} else {
			$scope.encounter.service.code = null;
		}
	};
	
	$scope.changeRevisedHealthService = function (service) {
		if (service) {
			if (service.code) {
				$scope.encounter.service.revised = service;
			}
		} else {
			$scope.encounter.service.revised = null;
		}
	};
	
	// Actions	
	$scope.save = function (submitToSveidra) {
		$scope.encounterForm.submitted = true;
		
		if (!$scope.encounter.service.code) {
			return;
		}
		
		if ($scope.encounterForm.$invalid) {
			return;
		}
		
		$scope.loadingSpinnerHelper.startLoading();
		if ($scope.mode == 'edit') {
			SveidraApiFactory.updateEncounter({formId: $routeParams.formId, id: $scope.encounterId, submitToSveidra: submitToSveidra}, $scope.encounter, saveSuccessHandler, saveErrorHandler);
		} else {
			SveidraApiFactory.createEncounter({formId: $routeParams.formId, submitToSveidra: submitToSveidra}, $scope.encounter, saveSuccessHandler, saveErrorHandler);
		}
	};
	
	var saveSuccessHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleSaveWithWarnings(result);
		if (result.withWarnings) {
			$scope.mode = 'edit';
			$scope.encounterId = result.id;
			$scope.encounter = SveidraApiFactory.getEncounter({formId: $routeParams.formId, id: $scope.encounterId}, function () {
				fillData(false);
			});
		} else {
			$location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms/f025alk/' + $routeParams.formId).search({}); 
		}
	};
	
	var saveErrorHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleErrors(result, 'err.cer.025.enc.sav');
	};
	
	$scope.clean = function () {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')}).then(function () {
        	$routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
    };
    
    // Validation
    $scope.serviceMissing = function () {
    	if ($scope.encounterForm.serviceCode && $scope.encounter.service) {
    		return ($scope.encounterForm.serviceCode.$dirty || $scope.encounterForm.submitted) 
    			&& ($scope.encounterForm.serviceCode.$invalid || !$scope.encounter.service.code);
    	}
    	return false;
    };
    
    $scope.diagnosisMissing = function () {
    	return $scope.encounterForm.submitted && !$scope.encounter.diagnosis;
    };
    
    $scope.encounter.$promise.then(function () {
		fillData(true);
    });
    
    var fillData = function (reset) {
    	$scope.encounterResults.$promise.then(function () {
			// Resolve result
			if ($scope.encounter.result) {
    			for (var i = 0; i < $scope.encounterResults.length; i++) {
    				if ($scope.encounter.result.code == $scope.encounterResults[i].code) {
    					$scope.encounter.result = $scope.encounterResults[i];
    				}
    			}
    		} else {
    			$scope.encounter.result = $scope.encounterResults[0];
    		}
		});
    	
    	// Resolve service data
    	if ($scope.encounter.service.code) {
    		$scope.additional.healthServiceSearch = _.getCodeName($scope.encounter.service.code);
    	}
		if ($scope.encounter.service.name) {
			$scope.additional.revisedHealthServiceSearch = _.getCodeName($scope.encounter.service.revised);
		}
		$scope.serviceTypes.$promise.then(function () {
			if ($scope.encounter.service.type) {
    			for (var i = 0; i < $scope.serviceTypes.length; i++) {
    				if ($scope.encounter.service.type.code == $scope.serviceTypes[i].code) {
    					$scope.encounter.service.type = $scope.serviceTypes[i];
    				}
    			}
			}
		});
		
    	// Resolving diagnosis
		if (reset) {
			$scope.diagnoses = DocumentsApiFactory.getDiagnoses({documentId: $scope.encounter.documentId});
		}
    	
    	$scope.diagnoses.$promise.then(function () {
    		$scope.additional.selectDiagnosis = $scope.diagnoses.length != 1;
			if (!$scope.additional.selectDiagnosis) {
				if (!$scope.encounter.diagnosis) {
					$scope.encounter.diagnosis = $scope.diagnoses[0].diagnosis;
				}
			} else if ($scope.encounter.diagnosis) {
    			for (var i = 0; i < $scope.diagnoses.length; i++) {
    				if ($scope.encounter.diagnosis.code == $scope.diagnoses[i].diagnosis.code) {
    					$scope.encounter.diagnosis = $scope.diagnoses[i].diagnosis;
    				}
    			}
    		}
    	});
    };
}]);