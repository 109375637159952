ng1App.factory('SearchFormSaver',
    ['$window', '$location',
     function ($window, $location) {

    function SearchFormSaver(viewId) {
        this._formId = viewId;
    }

    SearchFormSaver.prototype = {
        localSave: function (form) {
            $window.sessionStorage.setItem(this._formId, angular.toJson(form));
        },
        localLoad: function () {
            var json = $window.sessionStorage.getItem(this._formId);
            if (json) {
                return angular.fromJson(json);
            }
            return null;
        },
        isActiveSearch: function () {
            return $location.hash() === 'search' && $window.sessionStorage.getItem(this._formId);
        },
        setSearchFlag: function () {
            $location.hash('search');
            $location.replace();
        },
        saveSearch: function (form) {
            this.localSave(form);
            this.setSearchFlag();
        },
        loadSaved: function (defaultForm) {
            if (this.isActiveSearch()) {
                return this.localLoad();
            }
            return defaultForm;
        },
        removeSaved: function () {
            $window.sessionStorage.removeItem(this._formId);
            $location.hash('');
            $location.replace();
        }
    };

    return (SearchFormSaver);

}]);

ng1App.factory('FormLoaderHelper',
    ['$q', 'FormLoaderService',
     function ($q, FormLoaderService) {
    function FormLoaderHelper() {
        FormLoaderService.startFormLoading();
        this.promises = [];
    }
    FormLoaderHelper.prototype = {
        addPromise: function (obj) {
            if (obj.$promise) {
                this.promises.push(obj.$promise);
            } else {
                this.promises.push(obj);
            }

            return obj;
        },
        finally: function (callback) {
            var promise = $q.all(this.promises);
            promise.finally(function () {
                (callback || angular.noop)();
                FormLoaderService.endFormLoading();
            });
            return promise;
        }
    };

    return (FormLoaderHelper);
}]);

ng1App.service('FormLoaderService',
    ['$rootScope', '$q',
     function ($rootScope, $q) {
    this.startFormLoading = function () {
        $rootScope.formLoading = true;
    };

    var endFormLoading = function () {
        $rootScope.formLoading = false;
    };

    this.endFormLoading = function () {
        if (arguments.length > 0) {
            $q.all(arguments).finally(endFormLoading);
        } else {
            endFormLoading();
        }
    };

}]);

ng1App.service('FormReloadService',
    ['$modalConfirm', '$routeSegment',
     function ($modalConfirm, $routeSegment) {
    var reload = function () {
        $routeSegment.chain[$routeSegment.chain.length - 1].reload();
    };
    this.reloadForm = function (requireConfirm) {
        if (arguments.length > 0 && requireConfirm) {
            $modalConfirm.show({
                content: 'Formos pakeitimai bus išvalyti.'
            }).then(function () {
                reload();
            });
        } else {
            reload();
        }
    };

}]);

ng1App.service('DocumentSpinnerHelper', function () {
    this.loading = false;
    this.reset = function () {
        this.loading = false;
    };
    this.startLoading = function () {
        this.loading = true;
    };
    this.endLoading = function () {
        this.loading = false;
    };
});

ng1App.factory('LoaderHelperService',
    ['FormLoaderService',
     function (FormLoaderService) {
    return {
        preload: function (onLoad) {
            FormLoaderService.startFormLoading();
            (onLoad || angular.noop)(FormLoaderService.endFormLoading.bind(FormLoaderService));
        }
    };
}]);

/** @deprecated migrated to dp-patient-context.service.ts */
ng1App.factory('_DpPatientContext',
    ['EncountersApiFactory',
     function (EncountersApiFactory) {
    return {
        id: null,
        data: null,
        activeOrders: 0,
        activeConsultationPlanOrders: 0
    };
}]);

/** @deprecated by browser-storage.service.ts */
ng1App.factory('_BrowserSessionStorage',
    ['$window',
     function ($window) {
    var key = 'portal_bsid';
    return {
        getBsid: function () {
            return $window.sessionStorage.getItem(key);
        },
        generateBsid: function () {
            var bsid = _.random(123456789, 912345678) + '' + new Date().getTime();
            $window.sessionStorage.setItem(key, bsid);
        },
        ensureBsidExist: function () {
            if (!this.getBsid()) {
                this.generateBsid();
            }
        }
    }
}]);

ng1App.service('PractitionerQualificationService',
    ['$filter', 'PractitionersApiFactory', 'MessageService',
     function (
  $filter, PractitionersApiFactory, MessageService
) {

  this.checkValidOrEquivalentExists = function (practitioner, messageResolver) {
      messageResolver = messageResolver || $filter('translate');
      PractitionersApiFactory.checkFillableWithSpecialtyForCompositionAuthor({}, function (response) {
      if ((response || {}).result === false && _.isEmpty((practitioner || {}).validQualificationList)) {
        MessageService.error(messageResolver('no_valid_licence_or_position'));
      }
    });
  }

}]);