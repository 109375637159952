ng1App.directive('editRepresentativePractitioner', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        templateUrl: '/templates/portal/common/representatives/practitioner_edit.html',
        replace: false,
        restrict: 'E',
        require: '^form',
        scope: {
            representationData: '=',
            configuration: '=',
            organization: '=',
            mode: '@',
            showPractitionerLink: '@',
            role: '='
        },
        link: function (scope: any, element, attrs, form) {
            scope.form = form;
            scope.additional = {
                ipAddress: null,
                practitioner: null
            };

            scope.roleCode = function () {
                return (scope.representationData && scope.representationData.representativeType && scope.representationData.representativeType.code === '2') ? scope.role : undefined;
            };

            scope.showTypeList = function () {
                if (!scope.configuration || !scope.configuration.permittedRepresentativeTypes) {
                    return false;
                }
                return scope.mode == 'new' && scope.configuration.permittedRepresentativeTypes.length > 1;
            };

            scope.addIp = function () {
                if (scope.additional.ipAddress) {
                    if (!scope.representationData.ips) {
                        scope.representationData.ips = [];
                    }
                    if (scope.representationData.ips.indexOf(scope.additional.ipAddress) < 0) {
                        scope.representationData.ips.push(scope.additional.ipAddress);
                    }
                }
                scope.additional.ipAddress = null;
            };

            scope.deleteIp = function (index) {
                scope.representationData.ips.splice(index, 1);
            };

            scope.searchPractitioner = TypeaheadSearchHandlers.PRACTITIONERS;

            scope.changePractitioner = function (value) {
                if (value && value.id) {
                    scope.representationData.representative = {
                        fhirId: value.id
                    };
                }
            };

            scope.hasErrors = function (input) {
                if (scope.form[input]) {
                    return scope.form[input].$invalid && (scope.form[input].$dirty || scope.form.submitted);
                }
                return false;
            };

            scope.$watch('representationData.representative.fhirId', function (newValue) {
                if (!newValue) {
                    scope.additional.practitioner = null;
                }
            });
        }
    };
}]);

ng1App.directive('editRepresentativePatientCommon', 
    ['PatientsApiFactory',
     function (PatientsApiFactory) {
    return {
        templateUrl: '/templates/portal/common/representatives/patient_edit.html',
        replace: false,
        restrict: 'E',
        require: '^form',
        scope: {
            representationData: '=',
            configuration: '=',
            mode: '@'
        },
        link: function (scope: any, element, attrs, form) {
            scope._ = _;
            scope.form = form;
            scope.additional = {
                patientSearchParam: null,
                searchedForPatient: false,
                patient: null,
                needsAuthorization: false,
                canEnterValidityEnd: true,
                selectedAuthorizationTypes: {}
            };

            scope.$watch('representationData.representativeType', function (newValue) {
                if (newValue && scope.representationData.type == 'PATIENT') {
                    scope.additional.needsAuthorization = scope.configuration.authorizationNeeded.indexOf(newValue.code) >= 0;
                    scope.additional.canEnterValidityEnd = scope.configuration.isAdult || scope.representationData.representativeType.code != '3';
                    if (!scope.additional.canEnterValidityEnd) {
                        scope.representationData.validTo = scope.configuration.adulthoodDate;
                    }
                    if (!scope.additional.needsAuthorization) {
                        scope.clearAuthorizationTypesSelections();
                    }
                }
            });

            scope.$watch('representationData.authorizationTypes', function (newValue) {
                if (newValue) {
                    scope.additional.selectedAuthorizationTypes = {};
                    _.each(scope.representationData.authorizationTypes, function (type) {
                        scope.additional.selectedAuthorizationTypes[type.code] = true;
                    });
                }
            });

            scope.showTypeList = function () {
                if (!scope.configuration || !scope.configuration.representativeTypes) {
                    return false;
                }
                return scope.mode == 'new' && scope.configuration.representativeTypes.length > 1;
            };

            scope.searchForPatient = function () {
                if (scope.additional.patientSearchParam) {
                    scope.searchingPatient = true;
                    PatientsApiFactory.search({personalCode: scope.additional.patientSearchParam, alive: true}, function (result) {
                        if (result.total > 0) {
                            scope.searchingPatient = false;
                            patientSearchResult(result.items[0]);
                        } else {
                            PatientsApiFactory.search({esi: scope.additional.patientSearchParam, alive: true}, function (result) {
                                patientSearchResult(result.items[0]);
                            }).$promise.finally(function () {
                                scope.searchingPatient = false;
                            });
                        }
                    }, function () {
                        scope.searchingPatient = false;
                    });
                }
            };

            var patientSearchResult = function (patient) {
                scope.additional.patientSearchParam = null;
                scope.additional.searchedForPatient = true;
                scope.representationData.representative = null;
                scope.additional.patient = null;
                if (patient.id) {
                    scope.additional.patient = patient;
                    scope.representationData.representative = {
                        fhirId: patient.id
                    };
                }
            };

            scope.changeAuthorizationTypeSelection = function (type) {
                var index = scope.representationData.authorizationTypes.indexOf(type);
                if (index >= 0) {
                    // Remove
                    scope.representationData.authorizationTypes.splice(index, 1);
                } else {
                    // Add
                    scope.representationData.authorizationTypes.push(type);
                }
            };

            scope.selectAllAuthorizationTypes = function () {
                _.each(scope.configuration.authorizationTypes, function (type) {
                    scope.additional.selectedAuthorizationTypes[type.code] = true;
                    scope.representationData.authorizationTypes.push(type);
                });
            };

            scope.clearAuthorizationTypesSelections = function () {
                scope.additional.selectedAuthorizationTypes = {};
                scope.representationData.authorizationTypes = [];
            };

            scope.hasErrors = function (input) {
                if (scope.form[input]) {
                    return scope.form[input].$invalid && (scope.form[input].$dirty || scope.form.submitted);
                }
                return false;
            };

            scope.hasAuthorizationError = function () {
                if (scope.representationData) {
                    return scope.form.submitted && scope.additional.needsAuthorization && scope.representationData.authorizationTypes.length == 0;
                }
                return false;
            };

            scope.hasRepresentativeError = function () {
                if (scope.representationData) {
                    return scope.form.submitted && (!scope.representationData.representative || !scope.representationData.representative.fhirId);
                }
                return false;
            };
        }
    };
}]);

ng1App.directive('patientRepresentativeItem', 
    ['$filter', 'RepresentationCommons', 'portalType', 'apiUrl',
     function ($filter, RepresentationCommons, portalType, apiUrl) {
    return {
        templateUrl: '/templates/portal/common/representatives/patient_item.html',
        restrict: 'E',
        replace: true,
        scope: {
            data: '=',
            startEditUrl: '@',
            simplified: '@'
        },
        link: function (scope: any, element, attrs) {
            var observeData = function () {
                scope.data.representative.photoId = _.getIdFromReference(scope.data.representative.photoReference);
            };
            attrs.$observe("data", observeData);

            var acWrite = $filter('acWrite');
            scope.canWrite = function () {
                return scope.data.isValid && acWrite('PatientRepresentation', [scope.data.representativeType.code]);
            };
            scope.showDropDown = function () {
                return scope.canWrite() || scope.data.updateDocumentId || scope.data.approvalDocumentId || scope.data.cancellationDocumentId;
            };

            scope.getStatusKey = RepresentationCommons.getStatusTitleKey;
            scope.getStatusColor = RepresentationCommons.getStatusColor;
            scope.cancel = RepresentationCommons.cancel;
            scope.getRepresentationStatusKey = RepresentationCommons.getRepresentationStatusKey;

            scope.patientInformation = function () {
                var information = null;
                if (scope.data.representative.givenName || scope.data.representative.familyName) {
                    information = $filter('capitalize')(scope.data.representative.givenName) + ' '
                        + $filter('capitalize')(scope.data.representative.familyName);
                } else {
                    information = scope.data.representative.number;
                }
                if (scope.data.representative.birthDate) {
                    information += ', ';
                    information += $filter('date')(scope.data.representative.birthDate, 'yyyy-MM-dd');
                }
                return information;
            };

            scope.hasDocuments = function () {
                // For now show document download only in patient portal
                return (scope.data.updateDocumentId
                        || scope.data.approvalDocumentId
                        || scope.data.cancellationDocumentId)
                    //						&& portalType == 'patient';
                    && true;
            };

            scope.pdfDownloadUrl = function (id) {
                var resolvedId = null;
                if (id) {
                    resolvedId = id;
                } else {
                    resolvedId = scope.data.updateDocumentId
                        || scope.data.approvalDocumentId
                        || scope.data.cancellationDocumentId;
                }
                return apiUrl + '/representations/signedPdfs/' + resolvedId;
            };

            scope.getPdfTitleKey = function () {
                if (scope.data.updateDocumentId) {
                    return 'per.rep.upd.pdf.tit';
                }
                if (scope.data.approvalDocumentId) {
                    return 'per.rep.app.pdf.tit';
                }
                if (scope.data.cancellationDocumentId) {
                    return 'per.rep.can.pdf.tit';
                }
                return '';
            };
        }
    };
}]);

ng1App.service('RepresentationCommons', 
    ['PersonRepresentationsApiFactory', '$location', 'MessageService', '$filter', '$window', '$modalConfirm', '$routeSegment',
     function (PersonRepresentationsApiFactory, $location, MessageService, $filter, $window, $modalConfirm, $routeSegment) {
    var ERROR_KEY_MAP = {
        'representation_exists': 'err.per.rep.exi',
        'not_adult_self_representing_person': 'err.per.rep.not.adul.sel',
        'no_authorization_types': 'err.per.rep.no.auth.typ',
        'not_same_person': 'err.per.rep.not.sam.per',
        'same_patient': 'err.per.rep.sam.pat',
        'same_practitioner': 'err.per.rep.sam.pra',
        'role_missing': 'err.per.rep.rol.mis',
        'role_does_not_match': 'err.per.rep.rol.not.mat'
    };

    this.getRepresentationStatusKey = function (representation) {
        if (representation.type == 'PATIENT' && (representation.representativeType.code == '4' || representation.representativeType.code == '5')) {
            switch (representation.status) {
                case 'CREATED':
                    return 'mess.sub.sta.cre';
                case 'APPROVED':
                    return 'mess.sub.sta.apr';
                case 'REFUSED':
                    return 'mess.sub.sta.can';
                default:
                    return '';
            }
        }
        return '';
    };

    this.getStatusTitleKey = function (representation) {
        if (!representation) {
            return '';
        }
        if (representation.isBlocked) {
            return 'rep.blocked';
        }
        if (!representation.isValid) {
            return 'rep.invalid';
        }
        return 'rep.valid';
    };

    this.getStatusColor = function (representation) {
        if (!representation) {
            return '';
        }
        if (representation.isBlocked) {
            return 'text-danger';
        }
        if (!representation.isValid) {
            return 'text-info-emphasis';
        }
        return 'text-success';
    };

    this.cancel = function (representation) {
        $modalConfirm.show({title: $filter('translate')('inf.rep.cancel.tit'), content: $filter('translate')('inf.rep.cancel')}).then(function () {
            MessageService.clearAll();
            PersonRepresentationsApiFactory.cancel({id: representation.id}, {}, function (result) {
                if (result.changeId) {
                    // If cancellation must be confirmed by signature, redirect to signing page
                    // For now only patients has to sign representations
                    $location.path('/pp/representatives/sign').search({changeId: result.changeId});
                } else {
                    MessageService.registerCancellationSuccessEvent();
                    $routeSegment.chain[$routeSegment.chain.length - 1].reload();
                }
            }, function () {
                MessageService.error($filter('translate')('err.per.rep.can'));
            });
        });
    };

    this.save = function (representation, mode, redirectUrl, form, customValidation) {
        form.submitted = true;

        // Set day end time
        if (representation.validTo) {
            representation.validTo = _.addDayEndTime(representation.validTo);
        }

        if (form.$invalid) {
            _.scrollToFirstInvalid();
            return;
        }

        if (customValidation && !customValidation()) {
            $window.scrollTo(0, 10);
            return;
        }

        if (mode == 'new') {
            PersonRepresentationsApiFactory.create(representation, function (result) {
                handleSaveSuccess(result, redirectUrl);
            }, function (result) {
                handleSaveErrors(result, 'err.per.rep.cre');
            });
        } else {
            PersonRepresentationsApiFactory.update({id: representation.id}, representation, function (result) {
                handleSaveSuccess(result, redirectUrl);
            }, function (result) {
                handleSaveErrors(result, 'err.per.rep.upd');
            });
        }
    };

    var handleSaveSuccess = function (result, redirectUrl) {
        if (result.changeId) {
            // If save must be confirmed by signature, redirect to signing page
            // For now only patients has to sign representations
            $location.path('/pp/representatives/sign').search({changeId: result.changeId});
        } else {
            MessageService.clearAll();
            MessageService.registerSuccessEvent();
            $location.path(redirectUrl).search();
        }
    };

    var handleSaveErrors = function (result, defaultMessage) {
        if (result.data && result.data.globalErrors) {
            _.each(result.data.globalErrors, function (error) {
                var errorKey = ERROR_KEY_MAP[error.msg];
                MessageService.error(errorKey ? $filter('translate')(errorKey) : error.msg);
            });
        } else {
            MessageService.error($filter('translate')(defaultMessage));
        }
        $window.scrollTo(0, 10);
    };
}]);