<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formControl.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto req">
            <div class="form-group col-auto">
                <app-radio-button
                    *ngFor="let option of modOptions; let i = index"
                    radioName="assignmentToMode-{{i}}"
                    [fControl]="formGroup"
                    [radioValue]="option.name"
                    id="assignmentToMode-{{i}}"
                    [labelText]="option.name"
                ></app-radio-button>
                <app-form-control-validation [formControl]="formGroup" />
            </div>
        </div>
    </div>
</nhr-record-widget>