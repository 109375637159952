import {Component, Input, OnInit} from "@angular/core";
import {ErxPrescriptionTypeEnum} from "../../../../models/erx-prescription-type.enum";
import {Router} from "@angular/router";
import {RouteParams} from "../../../../../ng1.routeParams";
import {ApiV2MedicationPrescription} from "../../../../../pharmacist/services/api-v2-medication-prescription";
import {MedicationPrescriptionStatusEnum} from "../../../../models/medicationPrescriptionStatus.enum";
import moment from "moment";
import {ApiV2ErxMedicationPrescriptions} from "../../../../../../api/api-v2-erx-medication-prescriptions";
import {EnvironmentSettings} from "../../../../../../environments/env";
import {Environment} from "../../../../../../environments/environment-properties";
import PortalType = Environment.PortalType;

@Component({
    selector: "dispense-documents",
    templateUrl: "./dispense-documents.component.html",
})
export class DispenseDocumentsComponent implements OnInit {
    protected readonly moment = moment;
    protected readonly ErxPrescriptionTypeEnum = ErxPrescriptionTypeEnum;
    @Input() prescriptionType: ErxPrescriptionTypeEnum;
    @Input() prescription: any;
    patientId: string;
    prescriptionId: string;
    docId: string;
    relatedDocuments: any[] = [];
    loading: boolean = true;

    constructor(private router: Router, private routeParams: RouteParams,
                private apiV2MedicationPrescription: ApiV2MedicationPrescription, private apiV2ErxMedicationPrescriptions: ApiV2ErxMedicationPrescriptions) {
    }

    ngOnInit(): void {
        this.patientId = this.routeParams.patientId;
        this.prescriptionId = this.routeParams.prescriptionId ? this.routeParams.prescriptionId : this.routeParams.id;
        this.docId = this.prescription?.metadata?.docId;

        let apiService: ApiV2ErxMedicationPrescriptions | ApiV2MedicationPrescription;
        if (EnvironmentSettings.getPortalType() === PortalType.PHARMACIST) {
            apiService = this.apiV2MedicationPrescription;
        } else {
            apiService = this.apiV2ErxMedicationPrescriptions;
        }

        apiService.getRelated(this.prescriptionId, {includeRelated: true}).subscribe(d => {
            this.relatedDocuments = d;
            this.loading = false;
        });
    }


    onRelatedClick(documentId: any): void {
        if (EnvironmentSettings.getPortalType() === PortalType.PHARMACIST) {
            this.router.navigate([`patients/${this.patientId}/disp/${documentId}/view`]);
        } else {
            this.router.navigate([`patients/${this.patientId}/erx/dsp/${documentId}`]);
        }
    }

    getStatusColor(status: any): string {
        switch (status) {
            case MedicationPrescriptionStatusEnum.ACTIVE:
                return 'text-success';
            case MedicationPrescriptionStatusEnum.STOPPED:
                return 'text-warning';
            case MedicationPrescriptionStatusEnum.COMPLETED:
                return 'text-primary';
            case MedicationPrescriptionStatusEnum.ON_HOLD:
            case MedicationPrescriptionStatusEnum.ON_HOLD_FOR_REVIEW:
            case MedicationPrescriptionStatusEnum.ON_HOLD_RESERVED:
            case MedicationPrescriptionStatusEnum.ENTERED_IN_ERROR:
                return 'text-danger';
            default:
                return undefined;
        }
    }
}