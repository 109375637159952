ng1App.factory('SveidraListSubForm', 
    ['SubFormListController', 'CommonFunctionService', '$modalConfirm',
     function (SubFormListController, CommonFunctionService, $modalConfirm) {
	
	function SveidraListSubForm (list, region) {
		SubFormListController.call(this, list, region);
		
		this._appendTop = true;
	}
	
	SveidraListSubForm.prototype = new SubFormListController();
	SveidraListSubForm.prototype.constructor = SveidraListSubForm;
	
	SveidraListSubForm.prototype.validateValue = function (form, validator) {
	};
	
	SveidraListSubForm.prototype.validateForm = function (form) {
		this.clear();
		this.validateValue(form, this._validator);
		return !this._validator.hasErrors();
	};
	
	SveidraListSubForm.prototype.editValue = function (index) {
    	this.edit(index);
    	CommonFunctionService.gotoAnchor('edit');
    };
    
    SveidraListSubForm.prototype.deleteValue = function (index) {
        this.before('delete');
        var _this = this;
        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
        	if (_this._list[index].id) {
        		_this._list[index].removed = true;
        	} else {
        		var removed = _this._list.splice(index, 1);
        		_this.onDelete(removed[0]);
        		_this.after('delete');
        	}
        });
    };
	
	return (SveidraListSubForm);
}]);