import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { FilteredDataModel } from "../app/shared/models/filtered-data.model";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class ApiV2Documents extends ConfigService {
    path = this.apiUrl + "/documents2";

    searchForList(params: any): Observable<any[]> {
        const url = `${this.path}/forList`;
        return this.http.get<any[]>(url, {params: params});
    }

    getPrefilledForm(docType?: any, documentType?: any, encounterId?: any, patientId?: any): Observable<any[]> {
        let params = new HttpParams();

        docType && (params = params.append("docType", docType));
        documentType && (params = params.append("documentType", documentType));
        encounterId && (params = params.append("encounterId", encounterId));
        patientId && (params = params.append("patientId", patientId));

        const url = `${this.path}/prefillForm`;
        return this.http.get<any[]>(url, { params });
    }

    getOrderDocumentsByOrder(params: any): Observable<any[]> {
        const url = `${this.path}/orderDocumentsByOrder`;
        return this.http.get<any[]>(url, { params: params });
    }

    getE025FromFinishedEncounters(params: any): Observable<FilteredDataModel> {
        const url = `${this.path}/e025FromFinishedEncounters`;
        return this.http.get<FilteredDataModel>(url, { params: params });
    }

    changeE025ContinuedTreatmentParentDoc(params: any[]): Observable<any> {
        const url = `${this.path}/e025ChangeContinuedTreatmentParentDoc`;
        return this.http.post<any[]>(url, { params: params });
    }

    searchForListWithTotals(params: any): Observable<FilteredDataModel> {
        const url = `${this.path}/forList?appendTotals=true`;
        return this.http.get<FilteredDataModel>(url, { params: params });
    }

    getE063Data(): Observable<any> {
        const url = `${this.path}/500000`;
        return this.http.get<any>(url);
    }

    getE003Data(): Observable<any> {
        const url = `${this.path}/400000`;
        return this.http.get<any>(url);
    }

    getE106_2_1Data(): Observable<any> {
        const url = `${this.apiUrl}/certificates/documentReference/1000040448/certificate`;
        return this.http.get<any>(url);
    }

    getForList(dateFrom?: any, dateTo?: any, diagnosis?: any, practitioner?: any, docTypes?: string[]): Observable<any> {
        let params = new HttpParams();
        params = params.append('count', 5);

        if (dateFrom) {
            const parsedDateFrom = Date.parse(dateFrom);
            if (!isNaN(parsedDateFrom)) { // Check if the parsed date is a valid number
                params = params.append('dateFrom', parsedDateFrom.toString());
            }
        }
        if (dateTo) {
            const parsedDateTo = Date.parse(dateTo);
            if (!isNaN(parsedDateTo)) { // Check if the parsed date is a valid number
                params = params.append('dateTo', parsedDateTo.toString());
            }
        }

        if (diagnosis) {
            params = params.append('diagnosisCodes', diagnosis);
        }
        if (practitioner) {
            params = params.append('practitioner', practitioner);
        }

        if (docTypes && docTypes.length) {
            // Assuming API can handle multiple docType
            docTypes.forEach((docType) => {
                params = params.append('docType', docType);
            });
        }

        const url = `${this.path}/forList`;
        return this.http.get<any>(url, {params});
    }

    getByComposition(id: string): Observable<FilteredDataModel> {
        const url = `${this.path}/${id}`;
        return this.http.get<FilteredDataModel>(url, {});
    }

    createPartial(body: any, formType: string) {
        const url = `${this.path}/savePartial`;
        return this.http.post<any>(url, body, { params: { type: formType } });
    }
    updatePartial(body: any, formType: string) {
        const url = `${this.path}/savePartial`;
        return this.http.put<any>(url, body, { params: { type: formType } });
    }

    cancelDocument(documentId: string, cancelReason: string): Observable<any> {
        const url = `${this.path}/${documentId}/cancelDocument`;
        return this.http.post<any>(url, { reason: cancelReason });
    }
}