<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto req">
            <div class="form-group col-6 flex-shrink-1">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                            {{'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.useObstetricForceps' | translate}}:
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of useObstetricForcepsOptions; let i = index"
                        radioName="useObstetricForceps-{{i}}"
                        [fControl]="useObstetricForcepsControl"
                        [radioValue]="option.name"
                        id="useObstetricForceps-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="useObstetricForcepsControl" />
            </div>
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.startProcedure' | translate}}:"
                inputId="startOfProcedure"
                class="form-group col-4 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                labelClass="norq"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('startOfProcedure')"
            ></app-date-picker>
        </div>
        <div class="row mx-auto req">
            <div class="form-group col-12 col-lg-6">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.indicationForUseOF' | translate}}:
                </span>
                <div class="row col-auto">
                    <app-checkbox
                        *ngFor="let option of answersVariants?.['indicationForUseObstetricForceps']; let i = index"
                        inputId="indicationForObstetricForceps{{i}}"
                        class="mt-1"
                        formClass="mb-0"
                        [labelText]="option.name"
                        [checkboxValue]="option.name"
                        [checkboxDisabled]="!(editable && !controlDisabled('indicationForObstetricForceps'))"
                        [checkboxChecked]="checkboxIsChecked(option.name, 'indicationForObstetricForceps')"
                        (checkboxChange)="updateControl(option.name, 'indicationForObstetricForceps')"
                    ></app-checkbox>
                </div>
            </div>
            <div class="form-group col-12 col-lg-6">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.result' | translate}}:
                </span>
                <div class="row col-auto">
                    <app-checkbox
                        *ngFor="let option of answersVariants?.['conditionsForUseObstetricForceps']; let i = index"
                        inputId="conditionsForObstetricForceps{{i}}"
                        class="mt-1"
                        formClass="mb-0"
                        [labelText]="option.name"
                        [checkboxValue]="option.name"
                        [checkboxDisabled]="!(editable && !controlDisabled('conditionsForObstetricForceps'))"
                        [checkboxChecked]="checkboxIsChecked(option.name, 'conditionsForObstetricForceps')"
                        (checkboxChange)="updateControl(option.name, 'conditionsForObstetricForceps')"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="row mx-auto req">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.conditionForUseOF' | translate}}:
                </span>

                <div class="row align-items-baseline" *ngFor="let option of answersVariants?.['actionsPerformedWithObstetricForceps']; let i = index">
                    <div class="col-12 ms-auto">
                        <app-checkbox
                            inputId="actionsPerformedWithObstetricForceps{{i}}"
                            class="mt-1"
                            formClass="mb-0 col-auto"
                            [labelText]="option.name"
                            [checkboxValue]="option.name"
                            [checkboxDisabled]="!(editable && !controlDisabled('actionsPerformedWithObstetricForceps'))"
                            [checkboxChecked]="checkboxIsChecked(option.name, 'actionsPerformedWithObstetricForceps')"
                            (checkboxChange)="updateParentControl(option, 'actionsPerformedWithObstetricForceps')"
                        ></app-checkbox>
                        <div
                            *ngIf="option.result?.length"
                            class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap"
                            [class.d-none]="!checkboxIsChecked(option.name, 'actionsPerformedWithObstetricForceps')"
                        >
                            <app-checkbox
                                *ngFor="let childValue of option.result; let j = index"
                                inputId="{{generateChildControlName(option.name)}}{{j}}"
                                class="mt-1 me-3"
                                formClass="mb-0"
                                [labelText]="childValue.name"
                                [checkboxValue]="childValue.name"
                                [checkboxDisabled]="!(editable && checkboxIsChecked(option.name, 'actionsPerformedWithObstetricForceps') && !controlDisabled('actionsPerformedWithObstetricForceps'))"
                                [checkboxChecked]="checkboxIsChecked(childValue.name, generateChildControlName(option.name))"
                                (checkboxChange)="updateControl(childValue.name, generateChildControlName(option.name))"
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-auto req">
            <app-date-picker
                labelText="doc.pregnancyDashboard.e096Ptkl.formObstetricForcepsUse.endProcedure"
                inputId="endOfProcedure"
                class="form-group col-4 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                labelClass="norq"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [minDate]="formGroup.get('startOfProcedure')?.value"
                [fControl]="formGroup.get('endOfProcedure')"
            ></app-date-picker>
            <app-input
                labelText="Notes"
                labelClass="norq"
                class="form-group col-8 flex-shrink-1"
                type="textarea"
                [fControl]="formGroup.get('notes')"
            ></app-input>
        </div>

    </div>
    <div class="card-footer">
        <span class="fw-bolder">
            {{'doc.pregnancyDashboard.e096Ptkl.performedProcedure' | translate}}
        </span>
        <div class="col-12 col-lg-6 mt-2">
            <app-find-specialists
                [editable]="editable"
                [customDisplaying]="false"
                [formArray]="formGroup?.get('performedProcedure')"
            ></app-find-specialists>
        </div>
    </div>
</nhr-record-widget>