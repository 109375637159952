import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2AccessRules extends ConfigService {
    path = this.apiUrl + '/accessRules';

    get(): Observable<any> {
        const url = `${this.path}/permisions`;
        return this.http.get<any>(url);
    }

    checkPermision(): Observable<any> {
        const url = `${this.path}/check-permision`;
        return this.http.get<any>(url);
    }
}
