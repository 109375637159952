import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UpgradeModule } from "@angular/upgrade/static";
import { TranslateModule } from "@ngx-translate/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CapitalizePipe } from "src/app/shared/pipes/capitalize.pipe";
import { SharedLibrary } from "src/app/shared/shared.module";
import { NursingNeedsAssessmentQuestionnaireComponent } from "./components/patient/nursingNeedsAssessmentQuestionnaire/nursing-needs-assessment-questionnaire.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FullCalendarModule } from "@fullcalendar/angular";
import { AgGridModule } from "ag-grid-angular";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BsModalService } from "ngx-bootstrap/modal";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DowngradeAdapter } from "../../../utils/hypridHelper";
import "./aspnPatient.routes";
import { AspnVisitEditComponent } from "./components/doctor/nursingServiceVisit/nursing-service-visit-edit.component";
import { AspnVisitModalComponent } from "./components/doctor/nursingServiceVisit/nursing-service-visit-modal.component";
import { NursingAndCarePlan } from "./components/patient/nursingAndCarePlan/nursing-and-care-plan.component";
import { NursingAndCarePlanView } from "./components/patient/nursingAndCarePlan/view/nursing-and-care-plan-view.component";
import { NursingServiceOrderCreateComponent } from "./components/patient/nursingServiceOrder/nursing-service-order-create.component";
import { NursingNeedsHistoryComponent } from "./components/patient/summary/nursingNeedsHistory/nursing-needs-history.component";
import { NursingSummaryComponent } from "./components/patient/summary/nursingSummary.component";

import { AspnF027ListComponent } from "./components/doctor/aspnF027list/aspnF027list.component";
import { AspnVisitListFilterComponent } from "./components/doctor/aspnVisitsCalendar/aspnVisitList-filter.component";
import { AspnVisitsCalendarComponent } from "./components/doctor/aspnVisitsCalendar/aspnVisitsCalendar.component";
import { NursingServiceOrderListComponent } from "./components/doctor/nursingServiceOrderList/nursingServiceOrderList.component";
import {
    HomeEnvironmentAssessmentFormComponent
} from "./components/patient/homeEnvironmentAssessment/assessment-form/home-environment-assessment-form.component";
import {
    HomeEnvironmentAssessmentSubFormComponent
} from "./components/patient/homeEnvironmentAssessment/assessment-form/assessment-sub-form/home-environment-assessment-sub-form.component";
import {
    HomeEnvironmentAssessmentComponent
} from "./components/patient/homeEnvironmentAssessment/home-environment-assessment.component";
import { PatientHealthAssessmentsFormsComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/patient-health-assessments-forms.component";
import { SbvfBodyTempFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-body-temp-form/sbvf-body-temp-form.component";
import { SbvfBreathSystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-breath-system-form/sbvf-breath-system-form.component";
import { SbvfCardioSystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-cardio-system-form/sbvf-cardio-system-form.component";
import { SbvfDigestiveSystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-digestive-system-form/sbvf-digestive-system-form.component";
import { SbvfEntSystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-ent-system-form/sbvf-ent-system-form.component";
import { SbvfGeneralDataFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-general-data-form/sbvf-general-data-form.component";
import { SbvfJointsFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-joints-form/sbvf-joints-form.component";
import { SbvfLeatherFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-leather-form/sbvf-leather-form.component";
import { SbvfMeasurementsFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-measurements-form/sbvf-measurements-form.component";
import { SbvfRenalUrinarySystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-renal-urinary-system-form/sbvf-renal-urinary-system-form.component";
import { SbvfSkeletalMuscularSystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-skeletal-muscular-system-form/sbvf-skeletal-muscular-system-form.component";
import { SbvfVisualSystemFormComponent } from "./components/patient/patient-health-assessments/patient-health-assessments-forms/sub-forms/sbvf-visual-system-form/sbvf-visual-system-form.component";
import { PatientHealthAssessmentsComponent } from "./components/patient/patient-health-assessments/patient-health-assessments.component";
import { ActivityAndHomeEnvironmentHistoryComponent } from "./components/patient/summary/activityAndHomeEnvironmentHistory/activity-and-home-environment-history.component";
import { AllVisitsComponent } from "./components/patient/summary/allVisits/all-visits.component";
import { HealthAssesmentHistoryComponent } from "./components/patient/summary/healthAssesmentHistory/health-assesment-history.component";
import { NursingAndCarePlanHistoryComponent } from "./components/patient/summary/nursingAndCarePlanHistory/nursing-and-care-plan-history.component";
import { PatientAppointmentsComponent } from "./components/patient/summary/patientAppointments/patient-appointments.component";
import { VisitAgActionsCell } from "./components/shared/visit-ag-actions-cell/visit-ag-actions-cell.component";
import { VisitModalActions } from "./components/shared/visit-modal-actions/visit-modal-actions.component";
import { VisitCancelDialog } from "./components/shared/visit-modal-actions/visitCancelDialog/visitCancelDialog.component";
import { VisitCancelNoteDialog } from "./components/shared/visit-modal-actions/visitCancelNoteDialog/visitCancelNoteDialog.component";
import { VisitEditDialog } from "./components/shared/visit-modal-actions/visitEditDialog/visitEditDialog.component";

@NgModule({
    schemas: [],
    declarations: [
        NursingNeedsAssessmentQuestionnaireComponent,
        NursingNeedsHistoryComponent,
        ActivityAndHomeEnvironmentHistoryComponent,
        NursingAndCarePlan,
        NursingAndCarePlanView,
        NursingAndCarePlanHistoryComponent,
        HealthAssesmentHistoryComponent,
        NursingSummaryComponent,
        NursingServiceOrderCreateComponent,
        AspnVisitEditComponent,
        AspnVisitModalComponent,
        HomeEnvironmentAssessmentComponent,
        HomeEnvironmentAssessmentFormComponent,
        HomeEnvironmentAssessmentSubFormComponent,
        AspnF027ListComponent,
        NursingServiceOrderListComponent,
        PatientHealthAssessmentsComponent,
        PatientHealthAssessmentsFormsComponent,
        SbvfMeasurementsFormComponent,
        SbvfGeneralDataFormComponent,
        SbvfVisualSystemFormComponent,
        SbvfEntSystemFormComponent,
        SbvfBreathSystemFormComponent,
        SbvfCardioSystemFormComponent,
        SbvfDigestiveSystemFormComponent,
        SbvfRenalUrinarySystemFormComponent,
        SbvfSkeletalMuscularSystemFormComponent,
        SbvfJointsFormComponent,
        SbvfBodyTempFormComponent,
        SbvfLeatherFormComponent,
        AspnVisitsCalendarComponent,
        VisitAgActionsCell,
        AspnVisitListFilterComponent,
        AllVisitsComponent,
        VisitModalActions,
        VisitEditDialog,
        VisitCancelDialog,
        VisitCancelNoteDialog,
        PatientAppointmentsComponent
    ],
    providers: [CapitalizePipe, BsModalService],
    imports: [
        CommonModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary.forRoot(),
        TranslateModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        AgGridModule,
        BrowserAnimationsModule,
        RouterModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        FullCalendarModule,
        TypeaheadModule.forRoot(),
    ],
})
export class AspnModule { }

// Downgrade all components
new DowngradeAdapter(ng1App)
    .downgradeComponent(NursingNeedsAssessmentQuestionnaireComponent)
    .downgradeComponent(NursingAndCarePlan)
    .downgradeComponent(NursingAndCarePlanView)
    .downgradeComponent(NursingNeedsHistoryComponent)
    .downgradeComponent(NursingAndCarePlanHistoryComponent)
    .downgradeComponent(HealthAssesmentHistoryComponent)
    .downgradeComponent(ActivityAndHomeEnvironmentHistoryComponent)
    .downgradeComponent(NursingSummaryComponent)
    .downgradeComponent(NursingServiceOrderCreateComponent)
    .downgradeComponent(AspnVisitEditComponent)
    .downgradeComponent(HomeEnvironmentAssessmentComponent)
    .downgradeComponent(AspnF027ListComponent)
    .downgradeComponent(NursingServiceOrderListComponent)
    .downgradeComponent(PatientHealthAssessmentsComponent)
    .downgradeComponent(VisitModalActions)
    .downgradeComponent(VisitAgActionsCell)
    .downgradeComponent(AspnVisitsCalendarComponent)
    .downgradeComponent(AllVisitsComponent)
    .downgradeComponent(VisitEditDialog)
    .downgradeComponent(VisitCancelDialog)
    .downgradeComponent(VisitCancelNoteDialog)
    .downgradeComponent(PatientAppointmentsComponent)
    .downgradeComponent(AspnVisitListFilterComponent);
