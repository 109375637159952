angular.module('erx.utils').service('$erxModalAnalyticalInfoByAgeGroup', ["$modal", "$rootScope", "ErxAnalyticsApiFactory", function ($modal, $rootScope, ErxAnalyticsApiFactory) {
  var scope = $rootScope.$new();
  scope.data = {};

  scope.ageGroups = [
    {code: '', displayValue: ''},
    {code: 'AGE_0', displayValue: 'Iki vienerių metų'},
    {code: 'AGE_BETWEEN_1_TO_4', displayValue: '1-4 metų'},
    {code: 'AGE_BETWEEN_5_TO_6', displayValue: '5-6 metų '},
    {code: 'AGE_BETWEEN_7_TO_17', displayValue: '7-17 metų'},
    {code: 'AGE_BETWEEN_18_TO_49', displayValue: '18-49 metų'},
    {code: 'AGE_BETWEEN_50_TO_65', displayValue: '50-65 metų'},
    {code: 'AGE_BETWEEN_66_AND_UP', displayValue: 'Virš 65 metų'}
  ];
  
  scope.groupByCategories = [
    {code: 'DAY', displayValue: 'Dieną'},
    {code: 'MONTH', displayValue: 'Mėnesį'},
    {code: 'QUARTER', displayValue: 'Metų ketvirtį'},
    {code: 'HALF', displayValue: 'Pusmetį'},
    {code: 'YEAR', displayValue: 'Metus'},
  ];

  scope.search = function () {
    scope.currentGroupBy = scope.data.groupBy;
    scope.entries = ErxAnalyticsApiFactory.getPrescriptionsByAgeGroup(
            {
              ageGroup: scope.data.ageGroup ? scope.data.ageGroup : null,
              groupBy: scope.data.groupBy,
              dateFrom: scope.data.dateFrom,
              dateTo: scope.data.dateTo
            });
  }


  var modal = $modal({template: 'modules/erx-utils/components/erx-modal-analytical-info-by-age-group/erxModalAnalyticalInfoByAgeGroup.tpl.html', scope: scope, show: false});
  var parentShow = modal.show;

  modal.show = function (opts) {

    parentShow();
    return;
  };

  return modal;
}]);