import { Component, OnInit } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { IHeaderParams } from "ag-grid-community";

export interface ICustomHeaderParams {
  linkString: string;
  routerLinkTo: string;
}

@Component({
  selector: "ag-col-header-link",
  templateUrl: "./ag-col-header-link.component.html",
})
export class AgColHeaderLink implements OnInit, IHeaderAngularComp {
    public params!: IHeaderParams & ICustomHeaderParams;
  agInit(params: IHeaderParams<any, any> & ICustomHeaderParams): void {
      this.params = params;
  }
  refresh(params: IHeaderParams<any, any>): boolean {
      return false;
  } 
  ngOnInit(): void {
    
  }
}
