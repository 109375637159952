ng1App.directive('patientWidget', function () {
    return {
        templateUrl: '/templates/portal/common/patient_info/patient_widget.html',
        replace: false,
        restrict: 'E',
        scope: {
            patient: '=',
            careProvider: '=',
            careProvidingOrganization: '=',
            fullInfoLink: '@'
        },
        link: function (scope: any, element, attr) {
            scope._ = _;
        }
    };
});

ng1App.directive('patientInfoZone', function () {
    return {
        templateUrl: '/templates/portal/common/patient_info/zone/zone.html',
        replace: true,
        restrict: 'E',
        scope: {
            patient: '=',
            patientDashboardUrl: '@',
            representative: '='
        },
        link: function (scope: any, element, attr) {
            scope._ = _;
        }
    };
});

ng1App.directive('patientInfoZoneMobile', function () {
    return {
        templateUrl: '/templates/portal/common/patient_info/zone/zone_mobile.html',
        replace: true,
        restrict: 'E',
        scope: {
            patient: '=',
            patientDashboardUrl: '@',
            representative: '='
        },
        link: function (scope: any, element, attr) {
            scope._ = _;
        }
    };
});

ng1App.directive('patientForeignAddressEdit', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        templateUrl: '/templates/portal/common/patient_info/partials/foreign_address_edit.html',
        replace: false,
        restrict: 'E',
        scope: {
            patient: '='
        },
        link: function (scope: any, element, attrs) {
            if (scope.patient.foreignAddress) {
                scope.patient.tmpCountry = scope.patient.foreignAddress.country;
            }

            scope.searchCountry = TypeaheadSearchHandlers.COUNTRIES;
            scope.searchResidenceCountryChange = function (c) {
                if (c && c.code) {
                    scope.patient.foreignAddress.country = c;
                } else if (!c) {
                    scope.patient.foreignAddress.country = undefined;
                }
            };
        }
    };
}]);

ng1App.directive('patientRelatedPersonsEdit', 
    ['$filter', 'SubFormValidator', '$modalConfirm', 'PatientsApiFactory', 'EntitiesApiFactory', 'CaptchaApiFactory', 'apiUrl', 'portalType', 'MessageService',
     function ($filter, SubFormValidator, $modalConfirm, PatientsApiFactory, EntitiesApiFactory, CaptchaApiFactory, apiUrl, portalType, MessageService) {
    return {
        templateUrl: '/templates/portal/common/patient_info/partials/related_persons_edit.html',
        replace: false,
        restrict: 'E',
        scope: {
            form: '=',
            data: '='
        },
        link: function (scope: any, element, attrs) {
            scope._ = _;
            scope.patientPortal = portalType === 'patient';
            scope.relationshipTypesRaw = EntitiesApiFactory.getList({classCode: 'related-person-type', count: -1});


            scope.is18 = function (patient) {
                return patient.birthDate ? _.agenumyears(patient.birthDate) >= 18 : true;
            };

            scope.getHasMother = function (person) {
                var res = false;
                angular.forEach(person.relatedPersons, function (val) {
                    if (val.relation.code == '1') {
                        res = true;
                    }
                })
                return res;
            };

            scope.showRelation = function(val){
                return scope.is18(scope.form.patient) || (val.code != '2' && (val.code != '1' || !scope.getHasMother(scope.form.patient)))
            }


            scope.relationshipTypes = function(){
                var res = [];
                if(scope.form.patient){
                    angular.forEach(scope.relationshipTypesRaw, function (val) {
                        if (scope.showRelation(val)) {
                            res.push(val);
                        }
                    });
                }
                return res;
            }

            scope.isEditable = function (rp) {
                if (!rp.relation) {
                    return true;
                }
                return $filter('editable')(scope.form.importedData, 'relatedPerson', {pid: rp.patientRef})
            };

            scope.person = function (fullRef) {
                var p = scope.form.relatedPersons[fullRef];
                return p ? p : scope.data.patients[fullRef];
            };

            var editRelatedPersonIndex = 0;
            var newRelatedPerson = function () {
                scope.data.rpSearchQuery = undefined;
                return {
                    captcha: null,
                    fromRegistry: true,
                    emails: [],
                    phones: []
                };
            };

            scope.inRelatedPersonEdit = false;
            scope.rpValidator = new SubFormValidator('related-persons');
            scope.relatedPerson = newRelatedPerson();

            scope.updateCaptchaChallengeStatus = function () {
                CaptchaApiFactory.isCaptchaPresent(function (res) {
                    scope.captchaChallengePresent = res.captchaChallengePresent;
                    if (scope.captchaChallengePresent) {
                        scope.captchaUrl = apiUrl + '/captcha?t=' + new Date().getTime();
                    }
                });
            };

            if (portalType === 'patient') {
                scope.updateCaptchaChallengeStatus();
            }

            var validateRelatedPerson = function (rp) {
                scope.rpValidator.clear();

                if (scope.relatedPerson.fromRegistry) {
                    if (scope.patientPortal && !scope.relatedPerson.givenName) {
                        scope.rpValidator.add('givenName');
                    }
                    if (scope.patientPortal && !scope.relatedPerson.familyName) {
                        scope.rpValidator.add('familyName');
                    }
                    if (scope.patientPortal && !scope.relatedPerson.personalCode) {
                        scope.rpValidator.add('personalCode');
                    }
                    if (scope.patientPortal && scope.captchaChallengePresent && !scope.relatedPerson.captcha) {
                        scope.rpValidator.add('captcha');
                    }

                    if (!scope.patientPortal && !scope.relatedPerson.patientRef) {
                        scope.rpValidator.add('patient');
                    }
                } else {
                    if (!scope.relatedPerson.givenName) {
                        scope.rpValidator.add('givenName');
                    }
                    if (!scope.relatedPerson.familyName) {
                        scope.rpValidator.add('familyName');
                    }
                }
                if (!scope.relatedPerson.relation || !scope.relatedPerson.relation.code) {
                    scope.rpValidator.add('relation');
                }
                
                return !scope.rpValidator.hasErrors();
            };

            scope.addRelatedPerson = function () {
                if (validateRelatedPerson(scope.relatedPerson)) {
                    if (scope.patientPortal && scope.relatedPerson.fromRegistry) {
                        searchPatientByFullNameAndPersonalCode(scope.relatedPerson.captcha, scope.relatedPerson.givenName, scope.relatedPerson.familyName, scope.relatedPerson.personalCode, function(){
                            scope.form.patient.relatedPersons.push(scope.relatedPerson);
                            scope.data.patients[scope.relatedPerson.patientRef] = scope.relatedPerson.patient;
                            scope.inRelatedPersonEdit = false;
                            scope.relatedPerson = newRelatedPerson();
                        });
                    } else {
                        scope.form.patient.relatedPersons.push(scope.relatedPerson);
                        scope.data.patients[scope.relatedPerson.patientRef] = scope.relatedPerson.patient;
                        scope.inRelatedPersonEdit = false;
                        scope.relatedPerson = newRelatedPerson();
                    }

                } else {
                    scope.rpValidator.showErrorsAndScroll();
                }
            };
            scope.saveRelatedPerson = function () {
                if (validateRelatedPerson(scope.relatedPerson)) {
                    scope.form.patient.relatedPersons[editRelatedPersonIndex] = scope.relatedPerson;
                    scope.data.patients[scope.relatedPerson.patientRef] = scope.relatedPerson.patient;
                    scope.inRelatedPersonEdit = false;
                    scope.relatedPerson = newRelatedPerson();
                } else {
                    scope.rpValidator.showErrorsAndScroll();
                }
            };
            scope.editRelatedPerson = function (index) {
                scope.rpValidator.clear();
                editRelatedPersonIndex = index;
                scope.inRelatedPersonEdit = true;
                scope.relatedPerson = angular.copy(scope.form.patient.relatedPersons[editRelatedPersonIndex]);
                scope.relatedPerson.fromRegistry = scope.relatedPerson.patientRef ? true : false;
                scope.relatedPerson.relation = _.find(scope.relationshipTypes, function (t) {
                    return t.code === scope.relatedPerson.relation.code;
                });
                if (scope.relatedPerson.fromRegistry) {
                    scope.relatedPerson.patient = scope.person(scope.relatedPerson.patientRef);
                    scope.relatedPerson.familyName = scope.relatedPerson.patient.familyName;
                    scope.relatedPerson.givenName = scope.relatedPerson.patient.givenName;
                    scope.relatedPerson.personalCode = scope.relatedPerson.patient.personalCode;
                }
            };

            scope.deleteRelatedPerson = function (index) {
                $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                    scope.form.patient.relatedPersons.splice(index, 1);
                });
            };
            scope.cancelRelatedPerson = function () {
                scope.rpValidator.clear();
                scope.inRelatedPersonEdit = false;
                scope.relatedPerson = newRelatedPerson();
            };

            var searchPatientByIdentifier = function (arg) {
                if (arg.params.length === 0) {
                    scope.relatedPerson.patientNotFound = true;
                    scope.relatedPerson.patient = undefined;
                    scope.data.searchingRP = false;
                    return;
                }

                scope.relatedPerson.patientNotFound = false;
                scope.data.searchingRP = true;
                var query = {};
                query[arg.params.shift()] = arg.value;
                PatientsApiFactory.search(query, function (data) {
                    if (data.total === 1) {
                        scope.relatedPerson.patient = data.items[0];
                        scope.relatedPerson.patientRef = data.items[0].fullId;
                        scope.data.searchingRP = false;
                    } else {
                        searchPatientByIdentifier(arg);
                    }
                }, function () {
                    scope.data.searchingRP = false;
                });
            };

            var searchPatientByFullNameAndPersonalCode = function (captcha, given?, family?, personalCode?, callback?) {
                scope.relatedPerson.patientNotFound = false;
                scope.data.searchingRP = true;
                var query = {
                    captcha: captcha,
                    personalCode: personalCode,
                    givenName: given,
                    familyName: family
                };

                PatientsApiFactory.search(query, function (data) {
                    if (data.total === 1) {
                        scope.relatedPerson.patient = data.items[0];
                        scope.relatedPerson.patientRef = data.items[0].fullId;
                        (callback || angular.noop())();
                    }else {
                        MessageService.error('Asmuo nerastas pagal pateiktus duomenis.','related-persons');
                    }
                    scope.data.searchingRP = false;
                    scope.updateCaptchaChallengeStatus();
                    scope.relatedPerson.captcha = null;
                }, function (data) {
                    if (data.status == 429) {
                        MessageService.error($filter('translate')('err.captcha.invalid'), 'related-persons');
                        scope.updateCaptchaChallengeStatus();
                        scope.relatedPerson.captcha = null;
                    }
                    scope.data.searchingRP = false;
                });
            };

            scope.findPatient = function () {
                if (scope.patientPortal) {
                    searchPatientByFullNameAndPersonalCode({given: scope.data.rpSearchGiven, family: scope.data.rpSearchGiven, personalCode: scope.data.rpSearchGiven});
                } else {
                    searchPatientByIdentifier({params: ['personalCode', 'esi'], value: scope.data.rpSearchQuery});
                }
            };
        }
    };
}]);