import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: '[search-button], search-button',
    templateUrl: './search-button.component.html'
})
export class SearchButtonComponent implements OnInit {

    @Input() working: boolean;
    @Input() buttonType: string;
    @Input() workingTitle: string;
    @Input() loadingTitle: string;
    @Input() title: string;
    @Input() isAdvanced: boolean;
    @Output() quickClick = new EventEmitter<any>();
    @Output() advancedClick = new EventEmitter<any>();
    @Output() clearClick = new EventEmitter<any>();

    ngOnInit(): void {

    }

    onQuick(){
        this.quickClick.emit();
    }

    onAdvanced() {
        this.advancedClick.emit();
    }

    onClear() {
        this.clearClick.emit();
    }

}
