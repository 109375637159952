import { AfterViewInit, Component, DestroyRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, forkJoin } from 'rxjs';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import {
    E096PtklControlSheetComponent
} from './e096-ptkl-subforms/e096-ptkl-control-sheet/e096-ptkl-control-sheet.component';
import {
    E096PtklObstetricForcepsUseComponent
} from './e096-ptkl-subforms/e096-ptkl-obstetric-forceps-use/e096-ptkl-obstetric-forceps-use.component';
import {
    E096PtklFetalShoulderImpingementComponent
} from './e096-ptkl-subforms/e096-ptkl-fetal-shoulder-impingement/e096-ptkl-fetal-shoulder-impingement.component';
import {
    E096PtklAssistingWithPostpartumHemorrhageComponent
} from './e096-ptkl-subforms/e096-ptkl-assisting-with-postpartum-hemorrhage/e096-ptkl-assisting-with-postpartum-hemorrhage.component';
import {
    E096PtklVacuumExtractionComponent
} from './e096-ptkl-subforms/e096-ptkl-vacuum-extraction/e096-ptkl-vacuum-extraction.component';
import {
    clearFormArray,
    formatDateForDatePickers,
    updateFormArray
} from '../../e096-utils/utils';

@Component({
    selector: 'app-e096-ptkl-form',
    templateUrl: './e096-ptkl-form.component.html',
})
export class E096PtklFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: {[key: string]: any}) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);
        this.initPatchChildForms = true;
        this.patchFormWithInitialData();
    }

    @ViewChild(E096PtklControlSheetComponent) controlSheetComponent: E096PtklControlSheetComponent;
    @ViewChild(E096PtklFetalShoulderImpingementComponent) fetalComponent: E096PtklFetalShoulderImpingementComponent;
    @ViewChild(E096PtklObstetricForcepsUseComponent) obstetricComponent: E096PtklObstetricForcepsUseComponent;
    @ViewChild(E096PtklVacuumExtractionComponent) vacuumExtractorComponent: E096PtklVacuumExtractionComponent;
    @ViewChild(E096PtklAssistingWithPostpartumHemorrhageComponent) assistingWithPostpartumHemorrhageComponent: E096PtklAssistingWithPostpartumHemorrhageComponent;

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: {[key: string]: any};

    private initPatchChildForms: boolean = false;
    private dateFields: string[] = [
        'postpartumBleedingObserved',
        'assessmentDone',
        'anesthetistTeamArrived',
        'transferredToOperatingRoom',
        'laparotomyStarted',
        'bleedingStopped',
        'takenToIntensiveCareUnit',
        'endActiveSupport',
        'startOfProcedure',
        'endOfProcedure',
        'diagnosesDate',
        'fixedTimeOfBirth'
    ];

    private updateFormArray = updateFormArray.bind(this) as typeof updateFormArray;
    private clearfArray = clearFormArray.bind(this) as typeof clearFormArray;

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly destroyRef: DestroyRef
    ) {
        forkJoin({
            indicationForUseObstetricForceps: this.apiV2Entities.getEntitiesList('obstetric-forceps-use-indications'),
            conditionsForUseObstetricForceps: this.apiV2Entities.getEntitiesList('obstetric-forceps-use-conditions'),
            actionsPerformedWithObstetricForceps: this.apiV2Entities.getEntitiesList('obstetric-forceps-actions'),
            indicationForUseVacuumExtraction: this.apiV2Entities.getEntitiesList('ve-use-indication'),
            conditionsForUseVacuumExtraction: this.apiV2Entities.getEntitiesList('ve-use-conditions'),
            actionsPerformedWithVacuumExtraction: this.apiV2Entities.getEntitiesList('ve-actions-taken'),
            lostBloodAmount: this.apiV2Entities.getEntitiesList('lost-blood-amount'),
            e096ptActions: this.apiV2Entities.getEntitiesList('e096pt-actions'),
            uterineAtonyBleeding: this.apiV2Entities.getEntitiesList('uterine-atony-bleeding'),
            birthCanalTraumaBleeding: this.apiV2Entities.getEntitiesList('birth-canal-trauma-bleeding'),
            pacentaRemnantsBleeding: this.apiV2Entities.getEntitiesList('pacenta-remnants-bleeding'),
            clottingDisorderBleeding: this.apiV2Entities.getEntitiesList('clotting-disorder-bleeding'),
            laparathomy: this.apiV2Entities.getEntitiesList('laparathomy'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            controlSheet: this.controlSheetComponent?.formGroup,
        });
        this.formGroup.get('controlSheet').valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(0))
            .subscribe(() => {
                this.updateFormGroup();
        })
        if (!this.editable) {
            this.formGroup?.disable({emitEvent: false});
        }
        this._initialFormValue = this.formGroup?.value;
    }


    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.formGroup.patchValue(this._initialFormValue, {emitEvent: false});
        setTimeout(() => this.updateParticipants(this._initialFormValue, this.formGroup.get('controlSheet').value));
    }

    private patchFormWithInitialData() {
        this.formGroup.patchValue(this._initialFormValue, {emitEvent: false});
        if (!this.editable) {
            this.formGroup.disable({emitEvent: true});
        } else {
            this.formGroup.enable({emitEvent: true});
        }
    }

    private updateFormGroup() {
        const controlSheetValue = this.formGroup.get('controlSheet').value;
        this.keepOnlyControl('controlSheet');
        switch (controlSheetValue) {
            case 'Replių ir vakuuminio ekstraktoriaus naudojimas':
                this.formGroup.addControl('obstetricForcepsUse', this.obstetricComponent?.formGroup, {emitEvent: false});
                this.formGroup.addControl('vacuumExtractor', this.vacuumExtractorComponent?.formGroup, {emitEvent: false});
                break;
            case 'Pagalbos teikimas pogimdyminio kraujavimo atveju':
                this.formGroup.addControl('assistingWithPostpartumHemorrhage', this.assistingWithPostpartumHemorrhageComponent?.formGroup, {emitEvent: false});
                break;
            case 'Vaisiaus pečių užstrigimas':
                this.formGroup.addControl('fetalShoulderImpingement', this.fetalComponent?.formGroup, {emitEvent: false});
                break;
        }
        this.formGroup.updateValueAndValidity({emitEvent: false, onlySelf: false});
        if (this.initPatchChildForms) {
            this.initPatchChildForms = false;
            setTimeout(() => this.patchFormWithInitialData());
            setTimeout(() => this.updateParticipants(this._initialFormValue, this.formGroup.get('controlSheet').value));
        }
    }

    private updateParticipants(data: {[key: string]: any}, controlSheetValue: string): void {
        switch (controlSheetValue) {
            case 'Replių ir vakuuminio ekstraktoriaus naudojimas':
                this.clearfArray('obstetricForcepsUse.performedProcedure');
                this.clearfArray('vacuumExtractor.performedProcedure');
                this.updateFormArray(data, 'obstetricForcepsUse.performedProcedure');
                this.updateFormArray(data, 'vacuumExtractor.performedProcedure');
                break;
            case 'Pagalbos teikimas pogimdyminio kraujavimo atveju':
                this.clearfArray('assistingWithPostpartumHemorrhage.endAssistance.ledTheTeam');
                this.clearfArray('assistingWithPostpartumHemorrhage.endAssistance.teamMembers');
                this.clearfArray('assistingWithPostpartumHemorrhage.endAssistance.controlSheetWasFilledOut');
                this.updateFormArray(data, 'assistingWithPostpartumHemorrhage.endAssistance.ledTheTeam');
                this.updateFormArray(data, 'assistingWithPostpartumHemorrhage.endAssistance.teamMembers');
                this.updateFormArray(data, 'assistingWithPostpartumHemorrhage.endAssistance.controlSheetWasFilledOut');
                break;
            case 'Vaisiaus pečių užstrigimas':
                this.clearfArray('fetalShoulderImpingement.performedProcedure');
                this.updateFormArray(data, 'fetalShoulderImpingement.performedProcedure');
                break;
        }
    }

    private keepOnlyControl(controlToKeep: string): void {
        const controlNames = Object.keys(this.formGroup.controls);

        controlNames.forEach(controlName => {
            if (controlName !== controlToKeep) {
                this.formGroup.removeControl(controlName);
            }
        })
    }
}
