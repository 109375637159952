angular.module('erx.prs')
        .directive('erxPrescriptionGuide', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/guide/prescription_guide.html',
            restrict: 'E',
            scope: {
              prDoc: '=',
              step: '='
            },
            link: function (scope, element, attrs) {
            },
            replace: false
          };
        })