ng1App.controller('document.E063ViewCtrl', 
    ['$scope', '$resource', '$routeParams', '$http', '$location', '$filter',
     function ($scope, $resource, $routeParams, $http, $location, $filter) {
	$scope.data = {};
	$scope.result = {};
    
    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: _.range(6)
    }
    
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'}; 

	$scope.observations =[ 
	{ 'id':1, 'date':'2014-12-12', 'diagnosis':'Erkinis encefalitas', 'preparationTitle':'TicoVac', 'dose':'1',
	'description':'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt ante id pellentesque ornare. Integer non magna id justo egestas eleifend in et quam. Aliquam erat volutpat.',
	'genericTitle':'genericTitle1', 'famaticForm':'famaticForm1', 'drugStrength':'drugStrength1'}
	];
	$scope.observationsCount = $scope.observations.lenght;
	$scope.scionRadios = 1;


	$scope.npakids =[ 
	{ 'title':"pirmas", 'count':'4' },{ 'title':"antras", 'count':2},{ 'title':"trecias", 'count':6},
	];
	$scope.npakidsCount =  $scope.npakids.lenght;
}]);