<div class="mb-4 d-flex flex-column-reverse d-sm-block">
    <div class="float-sm-end">
        <app-select
            [fControl]="fruitControl"
            id="fruitId"
            labelText="Vaisius"
            class="d-flex gap-2"
            [placeholder]="null"
            [values]="fruitValues"
            (onChange)="setFruitIndex()"
        ></app-select>
    </div>
    <div>
        <h1 class="mb-1">{{'doc.pregnancyDashboard.e010_2_1.title' | translate}}</h1>
    </div>
</div>
<app-e010-form-mother [data]="formsData" />
<app-e010-form-prev-pregnancies [data]="formsData" />
<app-e010-form-risk-factors [data]="formsData" />
<app-e010-2-1-form-obstetric-procedures [data]="formsData" />
<app-e010-2-1-form-childbirth [data]="formsData" [fruitIndex]="fruitIndex" />
<app-e010-2-1-form-newborn *ngIf="showNewbornSection" [data]="formsData" [fruitIndex]="fruitIndex" />
<app-e010-form-death [data]="formsData" />