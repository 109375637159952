import { EntityModel } from "src/app/shared/models/entity.model";
import { MedicationModel } from "src/app/shared/models/medication.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import {
    fromLocalDateTimeDto,
    toLocalDateTimeDto,
} from "src/app/shared/services/utils/utils";

export interface NursingServiceOrderProcedureValue {
    id: string,
    procedureGroup: string;
    procedures: EntityModel[];
    placeInBodyProcedure: EntityModel[];
    medicines: MedicationModel[];
    procedureFromDate: Date;
    procedureToDate: Date;
    periodicity: string;
    frequency: number;
    recommendations: string;
    urgent: boolean;
}

export interface NursingServiceOrderValue {
    patient: PatientModel;
    appointmentDate: string;
    appointmentPractitioner: Partial<PractitionerModel>;
    procedures: NursingServiceOrderProcedureValue[];
}

export interface NursingServiceOrderProcedureDto {
    id: string,
    procedureGroup: Partial<EntityModel>;
    procedures: EntityModel[];
    placeInBodyProcedure: EntityModel[];
    medicines: MedicationModel[];
    procedureFromDate: string;
    procedureToDate: string;
    periodicity: Partial<EntityModel>;
    frequency: number;
    recommendations: string;
    urgent: boolean;
}

export interface NursingServiceOrderDto {
    patient: PatientModel;
    appointmentDate: string;
    appointmentPractitioner: Partial<PractitionerModel>;
    procedures: NursingServiceOrderProcedureDto[];
}

export function toNursingServiceOrderDto(
    val: NursingServiceOrderValue
): NursingServiceOrderDto {
    return {
        patient: val.patient,
        appointmentDate: val.appointmentDate,
        appointmentPractitioner: val.appointmentPractitioner,
        procedures: val.procedures.map((p) => toNursingServiceOrderProcedureDto(p)),
    };
}
export function toNursingServiceOrderProcedureDto(
    val: NursingServiceOrderProcedureValue
): NursingServiceOrderProcedureDto {
    return {
        id: val.id,
        procedureGroup: toEntityModel(val.procedureGroup),
        procedures: val.procedures,
        medicines: val.medicines,
        procedureFromDate: toLocalDateTimeDto(val.procedureFromDate),
        procedureToDate: toLocalDateTimeDto(val.procedureToDate),
        recommendations: val.recommendations,
        urgent: val.urgent,
        frequency: val.frequency,
        periodicity: toEntityModel(val.periodicity),
        placeInBodyProcedure: val.placeInBodyProcedure,
    };
}

export function fromNursingServiceOrderDto(
    val: NursingServiceOrderDto
): NursingServiceOrderValue {
    return {
        patient: val.patient,
        appointmentDate: val.appointmentDate,
        appointmentPractitioner: val.appointmentPractitioner,
        procedures: val.procedures.map((p) =>
            fromNursingServiceOrderProcedureDto(p)
        ),
    };
}
export function fromNursingServiceOrderProcedureDto(
    val: NursingServiceOrderProcedureDto
): NursingServiceOrderProcedureValue {
    return {
        id: val.id,
        procedureGroup: fromEntityModel(val.procedureGroup),
        procedures: val.procedures,
        medicines: val.medicines,
        procedureFromDate: fromLocalDateTimeDto(val.procedureFromDate),
        procedureToDate: fromLocalDateTimeDto(val.procedureToDate),
        recommendations: val.recommendations,
        urgent: val.urgent,
        frequency: val.frequency,
        periodicity: fromEntityModel(val.periodicity),
        placeInBodyProcedure: val.placeInBodyProcedure,
    };
}

export function toEntityModel(code: string): Partial<EntityModel> {
    if (code) {
        return { code: code };
    }
    return undefined;
}

export function fromEntityModel(entMod: Partial<EntityModel>): string {
    return entMod.code;
}
