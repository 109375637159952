ng1App.factory('DraftsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/drafts';
    return $resource('', {}, {
        findDrafts: {
            method: 'GET',
            url: URL_BASE + '?patient=:patient'
        },
        getDraftForPreview: {
            method: 'GET',
            url: URL_BASE + '/:id',
            transformResponse: function (data, headersGetter) {
                // a hack!!
                var orig = angular.fromJson(data);
                var form = orig.data;
                // documentForm
                if (form.metadata) {
                    form.metadata.documentStatus = 'DRAFT';
                    form.metadata.docType = orig.docType;
                } else {
                    form.documentStatus = 'DRAFT';
                    form.type = orig.docType;
                }

                var createDate = orig.updated ? new Date(orig.updated).getTime() : new Date().getTime();
                if (form.metadata) {
                    form.metadata.createDate = createDate;
                }
                return form;
            }
        },
        getDraftForEdit: {
            method: 'GET',
            url: URL_BASE + '/:id/edit',
            transformResponse: function (data, headersGetter) {
                // a hack!!
                var orig = angular.fromJson(data);
                var form = orig.data;
                if (form.metadata) {
                    form.metadata.documentStatus = 'DRAFT';
                    form.metadata.docType = orig.docType;
                } else {
                    form.documentStatus = 'DRAFT';
                    form.type = orig.docType;
                }
                var createDate = orig.updated ? new Date(orig.updated).getTime() : new Date().getTime();
                if (form.metadata) {
                    form.metadata.createDate = createDate;
                }
                return form;
            }
        },
        saveDraft: {
            method: 'POST',
            url: URL_BASE + '?type=:docType'
        },
        countByDocType: {
            method: 'GET',
            url: URL_BASE + '/countByDocType'
        },
        invalidateDraft: {
            method: 'DELETE',
            url: URL_BASE + '/:id'
        }
    });
}]);