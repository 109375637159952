ng1App
    .directive("certificatesList", [
        "$routeParams",
        "$rootScope",
        "$modal",
        "CertificatesApiFactory",
        "AccountSettingsApiFactory",
        "TypeaheadSearchHandlers",
        "$q",
        "EncountersApiFactory",
        "IntegratedComponentContext",
        "$filter",
        "SearchController",
        function (
            $routeParams,
            $rootScope,
            $modal,
            CertificatesApiFactory,
            AccountSettingsApiFactory,
            TypeaheadSearchHandlers,
            $q,
            EncountersApiFactory,
            IntegratedComponentContext,
            $filter,
            SearchController
        ) {
            return {
                templateUrl: "/templates/certificates/components/view/certificates.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    var isPatientPortal = attrs.patientPortal;

                    var initAll = function () {
                        var isEmployeeRepr = isPatientPortal && scope.representative.type.code == 5;

                        scope.isEmployeeRepr = isEmployeeRepr;
                        scope.isPatientPortal = isPatientPortal;

                        scope.handlers = {
                            isPatientPortal: isPatientPortal,
                        };

                        // -- define filters --
                        if (isEmployeeRepr) {
                            scope.filters = [
                                { title: "dpp.use.mess.rec.all", query: {} }, // all
                                {
                                    title: "dpp.use.mess.rec.unsigned",
                                    query: { docStatus: ["FINAL_UNSIGNED", "ENTERED_IN_ERROR"] },
                                }, // nepasirasytos
                                { title: "dpp.use.mess.rec.active", query: { docStatus: ["FINAL_SIGNED"] } }, // aktyvios
                                { title: "dpp.use.mess.rec.exec", query: { docStatus: ["IN_PROGRESS"] } }, // vykdomos
                            ];
                        } else if (isPatientPortal) {
                            scope.filters = [
                                { title: "dpp.use.mess.rec.complete", query: {} }, // complete
                                { title: "dpp.use.mess.rec.val", query: { docStatus: ["FINAL_SIGNED"] } }, // active
                                { title: "dpp.use.mess.rec.inv", query: { docStatus: ["INACTIVE", "EXECUTED"] } }, // inactive
                                { title: "dpp.use.mess.rec.chi", query: { childrenDocs: true } }, // fromChildren
                            ];
                        } else {
                            scope.filters = [
                                { title: "dpp.use.mess.rec.all", query: {} }, // all
                                {
                                    title: "dpp.use.mess.rec.unf",
                                    query: { docStatus: ["IN_PROGRESS", "FINAL_UNSIGNED", "PRELIMINARY"] },
                                }, // preliminary
                                { title: "dpp.use.mess.rec.val", query: { docStatus: ["FINAL_SIGNED"] } }, // active
                                { title: "dpp.use.mess.rec.inv", query: { docStatus: ["INACTIVE", "EXECUTED"] } }, // inactive
                                { title: "dpp.use.mess.rec.chi", query: { childrenDocs: true } }, // fromChildren
                            ];
                        }

                        var resolveDocStatus = function (statuses) {
                            var res = [];
                            if (statuses) {
                                for (var s in statuses) {
                                    switch (s) {
                                        case "unfinished":
                                            res.push("IN_PROGRESS", "FINAL_UNSIGNED", "PRELIMINARY");
                                            break;
                                        case "cancelled":
                                            res.push("CANCELED_SIGNED");
                                            break;
                                        case "active":
                                            res.push("FINAL_SIGNED");
                                            break;
                                        case "inactive":
                                            res.push("INACTIVE", "EXECUTED");
                                            break;
                                    }
                                }
                            }
                            return res;
                        };

                        var prepareQuery = function (query) {
                            if (!isPatientPortal) {
                                query.patientId = $routeParams.patientId;
                            }
                            return query;
                        };

                        // -- get counts for filters filters --
                        angular.forEach(scope.filters, function (filter) {
                            var q = prepareQuery(angular.copy(filter.query));
                            CertificatesApiFactory.count(q, function (data) {
                                filter.count = data.value;
                            });
                        });

                        // -- define search controller --
                        scope.searchCtrl = new SearchController({
                            viewId: "DPP_CERTIFICATES",
                            dialogTpl: "/templates/certificates/components/view/certificates_search.html",
                            count: 10,
                            initScope: function (scope) {
                                scope.searchByStateEnabled = !isPatientPortal;
                                scope.searchByOrgEnabled = !isEmployeeRepr;
                                scope.searchByPractEnabled = !isEmployeeRepr;
                                scope.states1 = [
                                    { code: "active", name: "Galiojanti" },
                                    { code: "unfinished", name: "Nebaigta" },
                                ];

                                scope.states2 = [
                                    { code: "inactive", name: "Negaliojanti" },
                                    { code: "canceled", name: "Atšaukta" },
                                ];
                                if (isEmployeeRepr) {
                                    scope.docTypes = [
                                        {
                                            code: "e047_employer",
                                            name: "E047 Privalomojo sveikatos patikrinimo medicininė pažyma",
                                        },
                                        {
                                            code: "e048_employer",
                                            name: "E048 Asmens medicininė knygelė (sveikatos pasas)",
                                        },
                                    ];
                                } else {
                                    scope.docTypes = [
                                        { code: "e103_1", name: "E103-1 Vaiko gimimo pažymėjimas" },
                                        {
                                            code: "e047_practitioner",
                                            name: "E047 Privalomojo sveikatos patikrinimo medicininė pažyma",
                                        },
                                        {
                                            code: "e048_practitioner",
                                            name: "E048 Asmens medicininė knygelė (sveikatos pasas)",
                                        },
                                        { code: "e049", name: "E049 Asmens privalomojo sveikatos tikrinimo kortelė" },
                                        {
                                            code: "e083_1",
                                            name: "E083-1 Vairuotojo sveikatos patikrinimo medicininė pažyma",
                                        },
                                        { code: "e106", name: "E106 Medicininis mirties liudijimas" },
                                        {
                                            code: "e106_2_1",
                                            name: "E106-2-1 Medicininis perinatalinės mirties liudijimas",
                                        },
                                        //2020-04-26 AS modified
                                        {
                                            code: "e027_1_I",
                                            name: "E027-1 Mokinio sveikatos pažymėjimas: I. Sveikatos būklės įvertinimas",
                                        },
                                        {
                                            code: "e027_1_II",
                                            name: "E027-1 Mokinio sveikatos pažymėjimas: II. Dantų ir žandikaulių būklės įvertinimas",
                                        },
                                    ];
                                }
                                scope.checkAll = function (source, target) {
                                    angular.forEach(source, function (val) {
                                        target[val.code] = true;
                                    });
                                };
                                scope.uncheckAll = function (target) {
                                    angular.forEach(target, function (val, key) {
                                        target[key] = false;
                                    });
                                };
                            },
                            initForm: function () {
                                return {
                                    filter: scope.filters[0],
                                    status: {},
                                    organization: [],
                                    practitioner: [],
                                    docTypes: {},
                                    authorQualification: [],
                                };
                            },
                            searchApi: CertificatesApiFactory.search,
                            showPageCounter: true,
                            formToQuery: function (form) {
                                var getCodes = function (target) {
                                    var res = [];
                                    angular.forEach(target, function (val, key) {
                                        if (val === true) {
                                            res.push(key);
                                        }
                                    });
                                    return res;
                                };
                                var query = prepareQuery({});
                                switch (form._searchType) {
                                    case "quick":
                                        form.filter = undefined;
                                        query.ftq = form.ftq;
                                        break;
                                    case "filter":
                                        angular.extend(query, form.filter.query);
                                        break;
                                    case "advanced":
                                        query.dateFrom = form.dateFrom;
                                        query.dateTo = form.dateTo;
                                        query.docStatus = resolveDocStatus(form.status);
                                        query.docType = getCodes(form.docTypes);
                                        query.authorQualificationId = _.map(form.authorQualification, function (o) {
                                            return o.id;
                                        });
                                        query.practitionerId = _.map(form.practitioner, function (o) {
                                            return o.id;
                                        });
                                        query.organizationId = _.map(form.organization, function (o) {
                                            return o.id;
                                        });
                                        break;
                                }
                                return query;
                            },
                        });

                        AccountSettingsApiFactory.get(function (data) {
                            var pageSize = 10;
                            scope.searchCtrl.setCount(pageSize);
                            scope.searchCtrl.searchLastOrAll();
                        });

                        scope.changeFilter = function (filter) {
                            scope.searchCtrl.clear();
                            scope.searchCtrl._form.filter = filter;
                            scope.searchCtrl.searchFilter();
                        };

                        if ($rootScope.restrictedMode) {
                            var encounterId = $routeParams.encounterId;
                            if (!IntegratedComponentContext.isInitialized()) {
                                IntegratedComponentContext.setData(encounterId);
                            } else {
                                encounterId = IntegratedComponentContext.getEncounterId();
                            }
                            EncountersApiFactory.get({ id: encounterId }, function (result) {
                                scope.encounter = result;
                                scope.isEncounterReady = true;
                            });
                        }

                        scope.updatePagination = function (pagination) {
                            if (pagination?.page) {
                                scope.searchCtrl.setPage(pagination.page);
                                scope.searchCtrl.handleSearch(false);
                            }
                        };
                    };

                    scope.representative.$promise.then(initAll);
                },
            };
        },
    ])

    .directive("orderedCertificatesListPp", [
        "$routeParams",
        "$rootScope",
        "CertificatesApiFactory",
        "FormLoaderService",
        function ($routeParams, $rootScope, CertificatesApiFactory, FormLoaderService) {
            return {
                templateUrl: "/templates/certificates/components/view/certificatesOrderedPP.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    FormLoaderService.startFormLoading();
                    scope.data = CertificatesApiFactory.searchOrdered();
                    scope.data.$promise.finally(function () {
                        FormLoaderService.endFormLoading();
                    });
                },
            };
        },
    ])

    .directive("certificateListItem", [
        "$routeParams",
        function ($routeParams) {
            return {
                templateUrl: "/templates/certificates/components/view/certificate_list_item.html",
                restrict: "E",
                replace: false,
                scope: { item: "=certificate", patientPortal: "=" },
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.getViewUrl = function (certificateId) {
                        if (scope.patientPortal) {
                            return "/pp/certificates/" + certificateId;
                        } else {
                            return "/dp/patients/" + $routeParams.patientId + "/certificates/" + certificateId;
                        }
                    };
                },
            };
        },
    ])
    .directive("orderedCertificateListItemPp", [
        "$location",
        "$filter",
        "TasksApiFactory",
        "$routeSegment",
        "$modalConfirm",
        "CertificatesViewService",
        "MessageService",
        function (
            $location,
            $filter,
            TasksApiFactory,
            $routeSegment,
            $modalConfirm,
            CertificatesViewService,
            MessageService
        ) {
            return {
                templateUrl: "/templates/certificates/components/view/certificate_ordered_list_item_pp.html",
                restrict: "E",
                replace: false,
                scope: {
                    c: "=certificate",
                },
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.$parent.user.$promise.then(function (data) {
                        scope.patientId = data.id;
                    });

                    //scope.allowSign = function (item) {
                    //    var result = item.documentStatus === 'CANCELED_UNSIGNED' || item.documentStatus === 'FINAL_UNSIGNED';
                    //    result = result && scope.patientId === item.author.id;
                    //
                    //    switch (item.docType) {
                    //        case 'e047_employer':
                    //            result = result && $filter('acWrite')('Composition', ['160922003'], null, 'SIGN');
                    //            break;
                    //        case 'e048_employer':
                    //            result = result && $filter('acWrite')('Composition', ['224406003'], null, 'SIGN');
                    //            break;
                    //        default :
                    //            break;
                    //    }
                    //
                    //    return  result;
                    //};
                    //scope.allowCancel = function (item) {
                    //    var result = item.documentStatus === 'FINAL_SIGNED' && scope.patientId === item.author.id && item.additionalData.canBeCanceled;
                    //
                    //    switch (item.docType) {
                    //        case 'e047_employer':
                    //            result = result && $filter('acWrite')('Composition', ['160922003']);
                    //            break;
                    //        case 'e048_employer':
                    //            result = result && $filter('acWrite')('Composition', ['224406003']);
                    //            break;
                    //        default :
                    //            break;
                    //    }
                    //
                    //    return  result;
                    //};
                    scope.cancelTask = function (taskId, orderType) {
                        $modalConfirm
                            .show({
                                content: "Ar tikrai atšaukti pažymos užsakymą ?",
                            })
                            .then(function () {
                                TasksApiFactory.cancel(
                                    { taskId: taskId, orderType: orderType },
                                    function () {
                                        $location.path("/pp/certificates").search({ ordered: true });
                                        $routeSegment.chain[$routeSegment.chain.length - 1].reload(); // For now (there should be better solution)
                                    },
                                    function (e) {
                                        angular.forEach(e.data.globalErrors, function (e) {
                                            MessageService.error(e.msg);
                                        });
                                    }
                                );
                            });
                    };
                },
            };
        },
    ]);

ng1App
    .directive("editCondition", [
        "EntitiesApiFactory",
        "CommonFunctionService",
        function (EntitiesApiFactory, CommonFunctionService) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_condition.html",
                restrict: "E",
                replace: false,
                require: "^form",
                scope: {
                    conditionModel: "=",
                    title: "@",
                    badConditionCode: "@",
                    inputName: "@",
                    iconClass: "=",
                    font: "=",
                },
                transclude: true,
                link: function (scope: any, element, attrs, formController) {
                    scope.options = EntitiesApiFactory.getList(
                        {
                            classCode: attrs.options,
                        },
                        function (options) {
                            CommonFunctionService.getTrueEntityValue(scope.conditionModel, "condition", options);
                        }
                    );
                    scope.inputName = attrs.inputName;
                    scope.font = attrs.font ? attrs.font : "fa";
                    scope.iconClass = attrs.iconClass ? attrs.iconClass : "fa-file";
                    scope.formController = formController;
                    scope.clearProblemsDescription = function (v) {
                        if (scope.conditionModel) {
                            if (scope.conditionModel.condition.code != scope.badConditionCode) {
                                scope.conditionModel.problemsDescription = "";
                            } else {
                                scope.conditionModel.problemsDescription = null;
                            }
                        }
                    };
                    scope.hasConditionErrors = function (v) {
                        if (scope.conditionModel == null) {
                            return true;
                        }
                        return !scope.conditionModel.condition || !scope.conditionModel.condition.code;
                    };
                    scope.hasProblemsErrors = function (v) {
                        if (scope.conditionModel == null) {
                            return false;
                        }
                        var isValid =
                            scope.conditionModel.condition != null &&
                            scope.conditionModel.condition.code == scope.badConditionCode &&
                            scope.conditionModel.problemsDescription == null;

                        return isValid;
                    };
                    scope.conditionProblemsRequired = function () {
                        if (scope.conditionModel == null) {
                            return false;
                        }
                        return (
                            scope.conditionModel.condition &&
                            scope.conditionModel.condition.code === scope.badConditionCode
                        );
                    };
                },
            };
        },
    ])
    .directive("viewDrivingCategoryGroup", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_driving_category_group.html",
            restrict: "E",
            scope: {
                title: "@",
                categoriesTitle: "@",
                validityTitle: "@",
                blockId: "@",
                group: "=",
            },
        };
    })
    .directive("editDrivingCategoryGroup", [
        "EntitiesApiFactory",
        function (EntitiesApiFactory) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_driving_category_group.html",
                restrict: "E",
                scope: {
                    // Text keys
                    titleKey: "@",
                    dateTitleKey: "@",
                    // Model
                    categoryGroup: "=",
                    // Other parameters
                    groupName: "@",
                    inputName: "@",
                    dateValidation: "&",
                    dateError: "&",
                },
                link: function (scope: any, element, attrs) {
                    scope.categories = EntitiesApiFactory.getList(
                        { classCode: "vehicle-category", searchCriteria: scope.groupName },
                        function () {
                            _.each(scope.categoryGroup.categories, function (category) {
                                for (var i = 0; i < scope.categories.length; i++) {
                                    if (category.code === scope.categories[i].code) {
                                        scope.categories[i].selected = true;
                                        scope.categoryGroup.categorySelected = true;
                                        break;
                                    }
                                }
                            });
                        }
                    );

                    // Use this so we don't have to iterate over all categories again and again
                    scope.categorySelectionChange = function (category) {
                        if (category.selected) {
                            scope.categoryGroup.categorySelected = true;

                            // Add category to group
                            scope.categoryGroup.categories.push(category);
                        } else if (scope.categoryGroup.categorySelected) {
                            scope.categoryGroup.categorySelected = false;

                            _.each(scope.categories, function (category) {
                                scope.categoryGroup.categorySelected |= category.selected;
                            });

                            // Remove category from group
                            for (var i = 0; i < scope.categoryGroup.categories.length; i++) {
                                if (scope.categoryGroup.categories[i].code == category.code) {
                                    scope.categoryGroup.categories.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    };

                    // Error handling
                    var errors = {
                        dateRequired: false,
                    };

                    scope.hasDateErrors = function () {
                        if (scope.categoryGroup) {
                            errors.dateRequired =
                                scope.categoryGroup.categorySelected && !scope.categoryGroup.validTill;

                            var invalid = false;

                            if (scope.dateValidation) {
                                invalid = !scope.dateValidation();
                            } else {
                                invalid = false;
                            }

                            return errors.dateRequired || invalid;
                        }
                    };

                    scope.getDateError = function () {
                        if (errors.dateRequired) {
                            return "err.required";
                        }

                        if (scope.dateError) {
                            return scope.dateError();
                        }
                    };

                    scope.hasCategoryErrors = function () {
                        if (scope.categoryGroup) {
                            return !scope.categoryGroup.categorySelected && scope.categoryGroup.validTill;
                        }
                    };

                    scope.getCategoryError = function () {
                        return "err.required";
                    };
                },
            };
        },
    ])
    .directive("editHarmfulFactors", [
        "TypeaheadSearchHandlers",
        function (TypeaheadSearchHandlers) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_harmful_factors.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.searchHarmfulFactors = TypeaheadSearchHandlers.HARMFUL_FACTOR;
                    scope.searchHarmfulFactorsChange = function (val) {
                        if (val && val.id) {
                            if (!scope.certificate.harmfulFactors) {
                                scope.certificate.harmfulFactors = {
                                    items: [],
                                };
                            }
                            scope.certificate.harmfulFactors.items.unshift(val);
                            scope.certificate.harmfulFactor = undefined;
                        }
                    };
                    scope.removeSelectedHarmufulFactor = function (index) {
                        if (!scope.certificate.harmfulFactors) {
                            scope.certificate.harmfulFactors = {
                                items: [],
                            };
                        }
                        scope.certificate.harmfulFactors.items.splice(index, 1);
                    };
                },
            };
        },
    ])
    .directive("editEmployer", [
        "OrganizationsApiFactory",
        "MessageService",
        "$filter",
        function (OrganizationsApiFactory, MessageService, $filter) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_employer.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.editEmployer = {};
                    scope.loadJarCode = function (jar, onBlur) {
                        //if jar code is already found, no need to search it again on blur
                        if (onBlur && scope.fromIntegration && _.startsWith(scope.fromIntegration.jarCode, jar)) {
                            return;
                        }
                        if (jar && ((jar.length === 7 && onBlur) || jar.length === 9)) {
                            if (new RegExp("^[0-9]+$").test(jar)) {
                                scope.fromIntegration = OrganizationsApiFactory.searchJAR(
                                    {
                                        code: jar,
                                    },
                                    function (data) {
                                        if (!data.jarCode) {
                                            MessageService.error(
                                                $filter("translate")("err.cer.noJar"),
                                                "edit-employer"
                                            );
                                            scope.certificate.authorOrganization = null;
                                            scope.certificate.occupation.organization = null;
                                        } else {
                                            scope.certificate.authorOrganization = data.jarCode + " " + data.name;
                                            scope.certificate.occupation.organization =
                                                scope.certificate.authorOrganization;
                                        }
                                    }
                                );
                            } else {
                                MessageService.error($filter("translate")("err.cer.noJar"), "edit-employer");
                            }
                        } else {
                            scope.fromIntegration = null;
                            scope.certificate.authorOrganization = null;
                            scope.certificate.occupation.organization = null;
                        }
                    };
                },
            };
        },
    ])
    .directive("editEmployee", [
        "TypeaheadSearchHandlers",
        function (TypeaheadSearchHandlers) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_employee.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.editEmployee = {};
                    //handlers
                    scope.searchOccupationCodes = TypeaheadSearchHandlers.QUALIFICATION_CODE;

                    scope.searchOccupationCodesChange = function (val) {
                        if (!val) {
                            scope.certificate.occupation.code = null;
                        } else if (typeof val != "string") {
                            scope.certificate.occupation.code = val;
                            scope.certificate.occupation.jobPosition = val.name;
                        }

                        if (val && val.name) {
                            scope.certificate.occupation.jobPosition = val.name;
                        }
                    };
                },
            };
        },
    ])
    .directive("editWorkConditionConclusion", [
        "EntitiesApiFactory",
        "$q",
        function (EntitiesApiFactory, $q) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_work_condition_conclusion.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.conclusions = EntitiesApiFactory.getList({
                        classCode: "work-ability-conclusion",
                    });

                    if (scope.certificate.$promise) {
                        scope.certificate.$promise.then(function () {
                            $q.all([scope.conclusions.$promise, scope.certificate.$promise]).then(function () {
                                if (scope.certificate.conclusion) {
                                    for (var i = 0; i < scope.conclusions.length; i++) {
                                        if (scope.conclusions[i].code === scope.certificate.conclusion.code)
                                            scope.certificate.conclusion = scope.conclusions[i];
                                    }
                                }
                            });
                        });
                    }
                },
            };
        },
    ])

    .directive("viewHarmfulFactors", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_harmful_factors.html",
            restrict: "E",
            replace: false,
            scope: {
                harmfulFactors: "=",
            },
            transclude: true,
        };
    })
    .directive("viewPatientOccupation", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_patient_occupation.html",
            restrict: "E",
            replace: false,
            scope: {
                occupation: "=",
            },
            transclude: true,
        };
    })
    .directive("viewEmployer", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_employer.html",
            restrict: "E",
            replace: false,
            scope: {
                certificate: "=",
            },
            transclude: true,
        };
    })
    .directive("viewCertificateOrganization", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_certificate_organization.html",
            restrict: "E",
            replace: false,
            scope: true,
            transclude: true,
        };
    })
    .directive("viewQualificationOrPosition", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_qualification_or_position.html",
            restrict: "E",
            replace: false,
            scope: true,
            transclude: true,
        };
    })
    .directive("viewCertificatePatient", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_cert_patient.html",
            restrict: "E",
            replace: false,
            scope: true,
            transclude: true,
        };
    })
    .directive("viewCertificatePractitioner", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_practitioner.html",
            restrict: "E",
            replace: false,
            scope: true,
            transclude: true,
            link: function (scope: any, element, attrs) {
                scope.isEdit = attrs.isEdit;
                scope.label = attrs.label ? attrs.label : "";
                scope.showEditPositionAndQualification = function () {
                    return (
                        scope.isEdit &&
                        scope.certificate.practitioner &&
                        ((scope.certificate.practitioner.positions &&
                            scope.certificate.practitioner.positions.length > 1) ||
                            (scope.certificate.practitioner.validQualificationList &&
                                scope.certificate.practitioner.validQualificationList.length > 1))
                    );
                };
                scope.isFinalAuthor = function () {
                    return (
                        !scope.certificate.finalCertificateAuthor ||
                        scope.certificate.finalCertificateAuthor.id === scope.certificate.practitioner.id
                    );
                };
            },
        };
    })
    .directive("viewFinalCertificateAuthor", [
        "$filter",
        function ($filter) {
            return {
                templateUrl: "/templates/certificates/components/view/view_final_certificate_author.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any) {
                    scope.showFinalCertificateAuthor = function () {
                        return (
                            scope.certificate.finalCertificateAuthor &&
                            scope.certificate.finalCertificateAuthor.id != scope.certificate.practitioner.id
                        );
                    };
                    scope.hasQualification = function () {
                        return (
                            scope.certificate.finalCertificateAuthor &&
                            scope.certificate.finalCertificateAuthor.validQualificationList &&
                            scope.certificate.finalCertificateAuthor.validQualificationList.length > 0
                        );
                    };
                    scope.hasPosition = function () {
                        return (
                            scope.certificate.finalCertificateAuthor &&
                            scope.certificate.finalCertificateAuthor.positions &&
                            scope.certificate.finalCertificateAuthor.positions.length > 0
                        );
                    };
                    scope.findSelectedQualifcation = function () {
                        if (scope.certificate.finalCertificateAuthorQualification) {
                            var result = $filter("filter")(
                                scope.certificate.finalCertificateAuthor.validQualificationList,
                                { code: scope.certificate.finalCertificateAuthorQualification },
                                true
                            );
                            return result[0].name;
                        } else {
                            return scope.certificate.finalCertificateAuthor.validQualificationList[0].name;
                        }
                    };
                },
            };
        },
    ])
    .directive("viewPhysicalCondition", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_physical_condition.html",
            restrict: "E",
            replace: false,
            scope: {
                condition: "=",
                title: "@",
                iconClass: "@",
                font: "@",
                blockId: "@",
            },
            transclude: true,
        };
    })
    .directive("viewConclusion", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_conclusion.html",
            restrict: "E",
            replace: false,
            scope: {
                certificate: "=",
            },
            transclude: true,
        };
    })
    .directive("viewCerAuthor", function () {
        return {
            templateUrl: "/templates/certificates/components/view/view_cer_author.html",
            restrict: "E",
            replace: false,
            scope: {
                certificate: "=",
            },
            transclude: true,
            link: function (scope: any) {
                scope._ = _;
            },
        };
    })
    .directive("certificateHeader", [
        "$routeParams",
        "$location",
        function ($routeParams, $location) {
            return {
                templateUrl: "/templates/certificates/components/view/certificate_header.html",
                restrict: "E",
                replace: false,
                scope: {
                    title: "@",
                    certificate: "=",
                    patientPortal: "=",
                },
                transclude: true,
                link: function (scope: any) {
                    scope.goBack = function () {
                        // For now return to records
                        if (scope.patientPortal) {
                            $location.path("/pp/certificates/").search({});
                        } else {
                            $location.path("/dp/patients/" + $routeParams.patientId + "/records").search({});
                        }
                    };
                },
            };
        },
    ])
    .directive("certificateActionButtons", [
        "CertificateService",
        "portalType",
        function (CertificateService, portalType) {
            return {
                templateUrl: "/templates/certificates/components/edit/certificate_action_buttons.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any) {
                    scope.saveDraft = function () {
                        CertificateService.saveDraft(
                            scope.draftId,
                            scope.certificateType,
                            scope.certificate,
                            scope.certificateForm
                        );
                    };
                    scope.portalType = portalType;
                },
            };
        },
    ])
    .directive("modifiableCertificateActionButtons", [
        "CertificateService",
        function (CertificateService) {
            return {
                templateUrl: "/templates/certificates/components/edit/modifiable_certificate_action_buttons.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any) {
                    scope.saveDraft = function () {
                        CertificateService.saveDraft(
                            scope.draftId,
                            scope.certificateType,
                            scope.certificate,
                            scope.certificateForm
                        );
                    };
                },
            };
        },
    ])
    .directive("editCerConsultations", [
        "TypeaheadSearchHandlers",
        "MessageService",
        "TasksApiFactory",
        "$filter",
        function (TypeaheadSearchHandlers, MessageService, TasksApiFactory, $filter) {
            return {
                templateUrl: "/templates/certificates/components/edit/edit_cer_consultations.html",
                restrict: "E",
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.certificateCode = attrs.certificateCode;
                    scope.title = attrs.title;
                    scope.closed = attrs.closed ? attrs.closed : "false";
                    scope.formType = attrs.formType;
                    scope.searchQualifications = TypeaheadSearchHandlers.PROFESSIONS_MEDICAL;
                    scope.additional = {};
                    scope.searchQualificationsChange = function (val) {
                        if (val && val.id) {
                            if (!scope.certificate.targetQualificationList) {
                                scope.certificate.targetQualificationList = [];
                            }
                            scope.certificate.targetQualificationList.unshift(val);
                            scope.additional.targetQualification = undefined;
                        }
                    };
                    scope.removeQualification = function (index) {
                        scope.certificate.targetQualificationList.splice(index, 1);
                    };

                    scope.generateQualifications = function () {
                        var messageRegion = "cer-consultations-region";
                        MessageService.clear(messageRegion);

                        var query = {
                            code: scope.certificateCode,
                            patientBirthDate: scope.patient.birthDate,
                            qualificationCodes: getQualificationCodes(),
                        };

                        TasksApiFactory.getConsultationQualifications(
                            query,
                            function (data) {
                                if (data.length > 0) {
                                    if (!scope.certificate.targetQualificationList) {
                                        scope.certificate.targetQualificationList = [];
                                    }
                                    _.each(data, function (qualification) {
                                        if (
                                            !_.find(scope.certificate.targetQualificationList, function (value) {
                                                return value.code == qualification.code;
                                            })
                                        ) {
                                            scope.certificate.targetQualificationList.unshift(qualification);
                                        }
                                    });
                                } else {
                                    MessageService.info($filter("translate")("cer.con.lis.no.dat"), messageRegion);
                                }
                            },
                            function () {
                                MessageService.error($filter("translate")("err.cer.con.list"), messageRegion);
                            }
                        );
                    };

                    var getQualificationCodes = function () {
                        var result = [];
                        if (scope.certificate.practitionerQualification) {
                            result.push(scope.certificate.practitionerQualification.code);
                        }
                        return result;
                    };
                },
            };
        },
    ])
    .directive("viewCerConsultations", [
        "TasksApiFactory",
        "DocumentsApiFactory",
        "MessageService",
        "$filter",
        "$routeParams",
        "$log",
        "portalType",
        "$location",
        function (
            TasksApiFactory,
            DocumentsApiFactory,
            MessageService,
            $filter,
            $routeParams,
            $log,
            portalType,
            $location
        ) {
            var consultationTask = function (task) {
                return (
                    task.taskType.code == "E047-perform-consultation" ||
                    task.taskType.code == "E048-perform-consultation" ||
                    task.taskType.code == "E049-perform-consultation" ||
                    task.taskType.code == "E083-1-perform-consultation"
                );
            };

            return {
                templateUrl: "/templates/certificates/components/view/view_cer_consultations.html",
                restrict: "E",
                scope: {
                    editMode: "=",
                    title: "@",
                    closed: "@",
                    certificateId: "@",
                    patientId: "@",
                    needRecommendations: "@",
                    needAtLeastOneConsultation: "@",
                    certificate: "=",
                },
                link: function (scope: any, element, attrs) {
                    if (portalType === "patient") {
                        return;
                    }
                    scope._ = _;

                    scope.navigateDoc = function (id) {
                        $location.path("/dp/patients/" + scope.patientId + "/documents/" + id).search();
                    };

                    var messageRegion = "consultation-message-region";
                    MessageService.clear(messageRegion);
                    if (!scope.certificateId) {
                        return;
                    }
                    scope.certificate.$promise.then(function () {
                        TasksApiFactory.getByCompositionId(
                            {
                                compositionId: scope.certificateId,
                            },
                            function (resp) {
                                var tasks = resp.items;
                                scope.completedCount = 0;
                                if (tasks.length > 0) {
                                    var pendingConsultations = null;
                                    var documentQuery = {
                                        docReferences: [],
                                        docType: ["e025"],
                                        count: -1,
                                        status: "FINAL_SIGNED",
                                    };
                                    var hasActiveConsultations = false;
                                    var hasAnyConsultations = false;
                                    _.each(tasks, function (task) {
                                        if (consultationTask(task)) {
                                            if (task.status == "COMPLETE") {
                                                hasActiveConsultations = true;
                                                hasAnyConsultations = true;
                                                scope.completedCount++;
                                                _.each(task.allFulfillmentIds, function (f) {
                                                    documentQuery.docReferences.push(f);
                                                });
                                            } else {
                                                hasAnyConsultations = true;
                                                if (pendingConsultations) {
                                                    pendingConsultations += ", " + task.targetQualification.name;
                                                } else {
                                                    pendingConsultations = task.targetQualification.name;
                                                }
                                            }
                                        }
                                    });

                                    scope.showBlock = scope.certificate.signed
                                        ? hasActiveConsultations
                                        : hasAnyConsultations;
                                    if (documentQuery.docReferences.length > 0) {
                                        // Get fulfilment documents
                                        scope.documents = DocumentsApiFactory.searchForList(documentQuery, {});
                                    }
                                    if (scope.needAtLeastOneConsultation && scope.completedCount === 0) {
                                        MessageService.error(
                                            $filter("translate")("err.cer.task.atLeastOne"),
                                            messageRegion
                                        );
                                    }
                                    if (scope.showBlock && pendingConsultations && !scope.certificate.signed) {
                                        // Show message about pending consultations
                                        var warning =
                                            $filter("translate")("err.cer.task.unf1") +
                                            " " +
                                            pendingConsultations +
                                            ". " +
                                            $filter("translate")("err.cer.task.unf2");
                                        MessageService.warning(warning, messageRegion);
                                    }
                                }
                            }
                        );
                    });
                },
            };
        },
    ]);
ng1App.directive("certificatesListWidgetDp", [
    "CertificatesApiFactory",
    "$routeParams",
    function (CertificatesApiFactory, $routeParams) {
        return {
            templateUrl: "/templates/certificates/components/view/certificates_widget.html",
            restrict: "E",
            replace: false,
            scope: true,
            transclude: true,
            link: function (scope: any, element, attrs) {
                var params = {
                    page: 1,
                    count: 3,
                    patientId: scope.patientId,
                    docStatus: ["FINAL_SIGNED"],
                };

                scope.certificates = CertificatesApiFactory.search(params);

                scope.getViewUrl = function (certificateId) {
                    return "/dp/patients/" + scope.patientId + "/certificates/" + certificateId;
                };
                scope.getCertificatesListUrl = function () {
                    return "/dp/patients/" + scope.patientId + "/certificates/";
                };
            },
        };
    },
]);
//-------------- EPTS --------------
ng1App.directive("eptsCertificateHeader", [
    "$window",
    "EptsCertificateUtils",
    "$location",
    "$routeParams",
    function ($window, EptsCertificateUtils, $location, $routeParams) {
        return {
            templateUrl: "/templates/certificates/components/view/epts_certificate_header.html",
            restrict: "E",
            replace: false,
            scope: {
                title: "@",
                showActions: "@",
                certificate: "=",
            },
            transclude: true,
            link: function (scope: any) {
                scope.getColor = EptsCertificateUtils.getStatusColor;
                scope.getStatusName = EptsCertificateUtils.getCertificateStatus;

                scope.goBack = function () {
                    $window.history.back();
                };
                scope.sign = function () {
                    // Open certificate signing window
                    $window.open(scope.certificate.signingUrl);
                    // Go to document view
                    $location
                        .path("/dp/patients/" + $routeParams.patientId + "/documents/" + $routeParams.documentId)
                        .search({});
                };
                scope.download = function () {
                    $window.open(scope.certificate.downloadUrl);
                };
            },
        };
    },
]);
ng1App.service("EptsCertificateUtils", [
    "$routeParams",
    function ($routeParams) {
        this.sortCertificates = function (certificates) {
            certificates.sort(function (first, second) {
                if (!first.certificateNo) {
                    return -1;
                }

                if (!second.certificateNo) {
                    return 1;
                }

                return second.startDate - first.startDate;
            });
        };

        this.getCertificateStatus = function (certificateStatus) {
            switch (certificateStatus) {
                case "REGISTERED":
                    return "epts.registered";
                case "SIGNED":
                    return "epts.signed";
                case "SAVED_IN_DVS":
                    return "epts.saved";
                case "BAD_SIGNATURE":
                    return "epts.wrongSignature";
                case "CANCELED":
                    return "epts.canceled";
                default:
                    return "";
            }
        };

        this.getCertificateUrl = function (certificate) {
            var certificatePart = "";

            if (certificate.type == "incapacity") {
                certificatePart = "incapacity";
            } else {
                certificatePart = "nesp";
            }

            return (
                "/dp/patients/" +
                $routeParams.patientId +
                "/certificates/" +
                certificatePart +
                "/" +
                certificate.certificateNo +
                "/view?documentId=" +
                $routeParams.documentId +
                "&submissionDate=" +
                certificate.submissionDate
            );
        };

        this.getCertificateTitle = function (certificateType) {
            switch (certificateType) {
                case "incapacity":
                    return "doc.cer.inc.cer.inc";
                case "pregnancy":
                    return "doc.cer.inc.cer.pre";
                case "medical":
                    return "doc.cer.inc.cer.med";
                default:
                    return "";
            }
        };

        this.getStatusColor = function (certificateStatus) {
            switch (certificateStatus) {
                case "REGISTERED":
                    return "text-info-emphasis";
                case "SIGNED":
                    return "text-success";
                case "SAVED_IN_DVS":
                    return "text-info-emphasis";
                case "BAD_SIGNATURE":
                    return "text-warning";
                case "CANCELED":
                    return "text-danger";
                default:
                    return "";
            }
        };
    },
]);
