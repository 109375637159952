ng1App.controller('doctor.patient.MainCtrl',
    ['$scope', '$routeParams', 'ApiV2PatientSummary', 'DpPatientContext', 'PatientsApiFactory', 'PractitionersApiFactory', 'OrganizationsApiFactory', '$location', '$route', '$routeSegment', 'PatientInsuranceService',
        function ($scope, $routeParams, ApiV2PatientSummary, DpPatientContext, PatientsApiFactory, PractitionersApiFactory,
                                                       OrganizationsApiFactory, $location, $route, $routeSegment, PatientInsuranceService) {

    $scope.$on('PSD-INSURANCE-UPDATED', function (event, payload) {
        if (DpPatientContext.data && DpPatientContext.data.id == payload.patientId) {
            DpPatientContext.data.insurance = payload.insurance;
        }
    });
    $scope.patientId = $routeParams.patientId;

            ApiV2PatientSummary.getDocumentListStatus($scope.patientId).subscribe(res => {
                $scope.documentListStatus = res;
            });

    var reload = function (patientId) {
        DpPatientContext.id = patientId;
        PatientsApiFactory.get({id: patientId}, function (d) {
            d.fullName = _.getFullName(d);
            if (d.birthDate) {
                d.age = _.age(d.birthDate, d.deathDate);
            }
            PatientInsuranceService.getInsurance(patientId).then(function (insurance) {
                d.insurance = insurance;
            });

            $scope.patientCtx = DpPatientContext.data = d;
            if (d.providerPractitioner) {
                PractitionersApiFactory.get({id: d.providerPractitioner}, function (data) {
                    $scope.patientCtx.pPractitioner = data;
                });
            }
            if (d.providerOrganization) {
                OrganizationsApiFactory.getById({organizationId: d.providerOrganization}, function (data) {
                    $scope.patientCtx.pOrganization = data;
                });
            }

        });
    };

    reload($routeParams.patientId);

    $scope.$on('patientWasEdited', function (evt, patientId) {
        reload(patientId);
    });

    $scope.$on('$locationChangeStart', function (next, current) {
        var urlRegex = /\/dp\/patients\/(\d+).+/;
        var matched = urlRegex.exec(current);
        if(matched && matched[1] != $scope.patientCtx.id){
            var doReload = false;
            angular.forEach($routeSegment.chain, function (item){
                doReload = doReload || item.name === 'DP_PATIENT';
                if(doReload){
                    item.reload();
                }
            });
        }
    });

}]);
