// Naujo skyrimo bei skyrimo redagavimo formos direktyva
angular.module('erx.prs').directive('erxPrescriptionForm', function () {
    var prescriptionFormCtrl = function ($scope, $filter, $timeout, $location, $erxModalAnalyticalInfoByOrg, $erxModalAnalyticalInfoByAgeGroup,
    $erxModalConfirm, $erxModalSearch, $erxModalAnalyticalInfo, $erxHelpers, $erxDrugSupplyDisruptionsModal, $erxModalMedinfo, $erxModalLoadDraft, $erxModalSaveDraft, $erxModalDoseCalculator, $erxShortcutCustomization, $erxShortcutTabsDosageM, $erxConditionsModal,
    ErxParametersApiFactory, ErxPrescriptionsApiFactory, ErxDrugInteractionApiFactory, ErxClassifiersApiFactory, erxPrescriptionsConfig, ErxSettingsApiFactory,
                                         ErxMedicationsApiFactory, ErxSubstancesApiFactory, ErxTemplatesApiFactory, gettextCatalog, MessageService, DosageSummaryService, ErxTypeaheadUtilsService, $routeParams, DpSessionContext2) {

        MessageService.clear('drug-region');
        MessageService.clear('named-drug-region');
        MessageService.clear('mpp-region');
        MessageService.clear('em-drug-region');
        MessageService.clear('prescription-region');
    $scope.shortcutsDataLoading = true;
    $scope.typeaheadInfoVaSelectTemplate = 'modules/erx-utils/components/erx-typeahead-templates/erx-info-va-select-typeahead.tpl.html';
    $scope.typeaheadVaSelectTemplate = 'modules/erx-utils/components/erx-typeahead-templates/erx-va-select-typeahead.tpl.html';
    $scope.typeaheadDefaultSelectTemplate = 'modules/erx-utils/components/erx-typeahead-templates/erx-default-select-typeahead.tpl.html';
    $scope.medicationProduct = {};
    $scope.disruptions = [];
        $scope.safetyEducationParams;
    $scope.detailedInfo = false;
    $scope.dosageSummary = "";
    $scope.shortcutDosageInstructionKey = 'shortcuts_dosage_instruction';
    $scope.shortcutDosageTypeKey = 'shortcuts_dosage_type';
    $scope.isInsulin = false;
    $scope.isLoading = false;
        $scope.patientId = $routeParams.patientId;
        $scope.encounterId = $routeParams.encounterId;

        if (angular.isDefined($scope.options)) {
      angular.extend($scope.options, erxPrescriptionsConfig.options);
    } else {
      $scope.options = erxPrescriptionsConfig.options;
    }

        $scope.onDiagnosisChanges = function (event) {
            $scope.conditions = event;
        }

        DpSessionContext2.encounterData$.subscribe(enc => {
            $scope.patientRef = enc.patientRef;
            $scope.encounterRef = enc.fullId;
        });

        DpSessionContext2.accountSettingsData$.subscribe(res => {
            $scope.activeQualification = res.last_qualification;
        });

        DpSessionContext2.practitionerData$.subscribe(res => {
            $scope.practitionerData = res;
        });

        $scope.options.getIntegrationData().isPatientInsured().$promise.then(function (r) {
            $scope.isPatientInsured = r.assertStatus !== 'notInsured';
            $scope.setCompensationOfInsurance();
        });

    $scope.setCompensationOfInsurance = function (){
        $scope.medicationPrescription.dispenseData.compensationTag = $scope.isPatientInsured;
    }

    $scope.options.getIntegrationData().getShortcuts().$promise.then(function (res) {
      $scope.shortcutsDataLoading = false;
      $scope.accountSettings = res;
      $scope.dosageInstructionShortcutsList = $scope.assignShortcutsData(res, $scope.shortcutDosageInstructionKey);
      $scope.dosageTypeShortcutsList = $scope.assignShortcutsData(res, $scope.shortcutDosageTypeKey);
    }).catch(function (err) {
      $scope.shortcutsDataLoading = false;
      $scope.accountSettings = [];
      $scope.dosageInstructionShortcutsList = [];
      $scope.dosageTypeShortcutsList = [];
    });

    $scope.assignShortcutsData = function (res, key) {
      let target;
      if(res[key] !== undefined){
        target = JSON.parse(res[key]);
      } else {
        target = $scope.options.getIntegrationData().getDefaultShortcuts()[key];
      }
      Object.assign($scope.accountSettings, {[key]: target});
      return target;
    }

    $scope.diagnosesHandler = function (value) {
      return $scope.options.getIntegrationData().getDiagnosesHandler(value);
    }

    $scope.checkForPrescriptionValidity = function () {
      if ($scope.medicationPrescription.dispenseData.prescriptionTag == 'ier' && typeof $scope.medicationPrescription.drug == 'object') {
        ErxPrescriptionsApiFactory.getDrugInUseUntil({ activeSubstances: $scope.medicationPrescription.drug.activeIngredients, patientId: $scope.patientId })
          .$promise.then(function (res) {
            $scope.currentValidUntil = res.date;
            if (res.date) {
                MessageService.add('warning', gettextCatalog.getString('Šis vaistas jau buvo skirtas ir gydymo kursas dar nepasibaigęs. Vartojamo vaisto pakanka iki ') + $filter('date')($scope.currentValidUntil, 'yyyy-MM-dd'), 'drug-region');
                MessageService.add('warning', gettextCatalog.getString('Šis vaistas jau buvo skirtas ir gydymo kursas dar nepasibaigęs. Vartojamo vaisto pakanka iki ') + $filter('date')($scope.currentValidUntil, 'yyyy-MM-dd'), 'em-drug-region');
              $erxModalConfirm.show({ title: 'Patvirtinimas', content: gettextCatalog.getString('Perskaičiuota galiojimo pradžios data pasikeitė. Ar atnaujinti ją formoje?') }).then(function () {


                var date = new Date($scope.currentValidUntil);
                var newDate = new Date(date);
                newDate.setDate(date.getDate() + 1);
                $scope.medicationPrescription.dispenseData.validFrom = newDate.getTime();
              });

            }
          });
      }
    }

    $scope.checkFirstPrescribing = function() {
       if ($scope.medicationPrescription.drug && $scope.medicationPrescription.drug.compensated
            && $scope.medicationPrescription.dispenseData.compensationTag === true) {
         ErxPrescriptionsApiFactory.isFirstPrescribing({ prescriptionId: $scope.options.prescriptionId, patientId: $scope.patientId, activeSubstances: $scope.medicationPrescription.drug.activeIngredients, strength: $scope.medicationPrescription.drug.strength, pharmFormCode: $scope.medicationPrescription.drug.pharmForm.code })
           .$promise.then(function (res) {
               $scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag = res.firstPrescribing;
           });
       } else {
           delete $scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag;
       }
    }

    $scope.typeaheadLoading = false;
    loadValues();
    loadSysParams();
    if ($scope.options.prescriptionType) {
      $scope.prescriptionFound = true; //Fixme
      initForm($scope.options.prescriptionType);
    } else if (($scope.options.prescriptionId)) {
      loadForm($scope.options.prescriptionId);
    }



    // formos inicializavimas kuriant naują skyrimą
    function initForm(prescriptionType) {
      $scope.medicationPrescription = {};
      $scope.medicationPrescription.dosageInstructionData = {};
      $scope.medicationPrescription.dispenseData = { prescriptionTag: 'ier' };
      $scope.medicationPrescription.additional = {};
      $scope.medicationPrescription.metadata = {};
      var reason;
      if (prescriptionType !== 'kp' && angular.isDefined($scope.form)) {
        reason = $scope.form.reason;
      }
      $scope.form = { "reason": reason }; //Remembering current diagnosis

      $scope.medicationPrescription.prescriptionType = prescriptionType;
      if (prescriptionType === 'va') {
        $scope.form.title = gettextCatalog.getString("Vaisto skyrimas");
        $scope.checkForPrescriptionValidity();
        $scope.medicationPrescription.isBrand = false;
        $scope.medicationPrescription.dosageInstructionData = {
          schedule: {
            units: 'd'
          },
          morning: false,
          noon: false,
          evening: false,
          asNeeded: false
        }
        angular.forEach($scope.intakeTimes, function (value, key) {
          $scope.medicationPrescription.dosageInstructionData[value['code']] = false;
        });
        $scope.medicationPrescription.dispenseData.prescriptionTags = {
          gkkTag: false,
          longTag: false,
          specialTag: false,
          nominalTag: false,
            specialistDecision: false,
            labelingExemptionTag: false,
            byDemandTag: false
        };
        $timeout(function () {
          $scope.prescriptionTagChanged();
        });
          /*  if ($scope.options.getAagaSgas) {
              $scope.options.getAagaSgas().then(function (res) {
                if (res.aagaSgas) {
                  $scope.medicationPrescription.additional.aagaSgasNumber = res.aagaSgas;
                }
              });
            }*/

      } else if (prescriptionType === 'mpa') {
        $scope.form.title = gettextCatalog.getString("Medicininės priemonės skyrimas");
        $scope.medicationPrescription.dispenseData.lensFlag = true;
        $scope.medicationPrescription.dispenseData.prescriptionTags = {
          longTag: false
        };
        $scope.medicationPrescription.isBrand = false;
        $scope.medicationPrescription.dispenseData.medicationType = 'optic';
          $scope.medicationPrescription.dispenseData.lenses = {};
          $scope.medicationPrescription.dispenseData.lenses.opticalDevice = 'glens';
          $scope.medicationPrescription.dispenseData.lenses.lensesType = 'difficult';
        $scope.medicationPrescription.drug = {};
          /*if ($scope.options.getAagaSgas) {
            $scope.options.getAagaSgas().then(function (res) {
              if (res.aagaSgas) {
                $scope.medicationPrescription.additional.aagaSgasNumber = res.aagaSgas;
              }
            });
          }*/
        $timeout(function () {
          $scope.prescriptionTagChanged();
        });
      } else if (prescriptionType === 'mpp') {
        $scope.form.title = gettextCatalog.getString("Medicinos pagalbos priemonės skyrimas");
        $scope.medicationPrescription.isBrand = false;
        $scope.medicationPrescription.dispenseData.prescriptionTags = {
          specialistDecision: false
        };
          /*  if ($scope.options.getAagaSgas) {
              $scope.options.getAagaSgas().then(function (res) {
                if (res.aagaSgas) {
                  $scope.medicationPrescription.additional.aagaSgasNumber = res.aagaSgas;
                }
              });
            }*/
        $timeout(function () {
          $scope.prescriptionTagChanged();
        });
      } else if (prescriptionType === 'ev') {
        $scope.form.title = gettextCatalog.getString("Ekstemporalaus vaisto skyrimas");
        $scope.medicationPrescription.isBrand = false;
        $scope.medicationPrescription.medicationData = {
          extemporaneous: {
            description: undefined
          }
        }
        $scope.medicationPrescription.dosageInstructionData = {
          schedule: {
            units: 'd'
          },
          morning: false,
          noon: false,
          evening: false,
          asNeeded: false
        }
          $scope.extempAmount = null;
          /*  if ($scope.options.getAagaSgas) {
              $scope.options.getAagaSgas().then(function (res) {
                if (res.aagaSgas) {
                  $scope.medicationPrescription.additional.aagaSgasNumber = res.aagaSgas;
                }
              });
            }*/

        angular.forEach($scope.intakeTimes, function (value, key) {
          $scope.medicationPrescription.dosageInstructionData[value['code']] = false;
        });
        $scope.checkForPrescriptionValidity();
        $scope.medicationPrescription.dispenseData.prescriptionTags = {
          gkkTag: false,
          longTag: false,
          specialTag: false,
          specialistDecision: false
        };
        $timeout(function () {
          $scope.prescriptionTagChanged();
        });
        $scope.medicationPrescription.mainIngredient = 'va';
      } else if (prescriptionType === 'kp') {
        $scope.form.title = gettextCatalog.getString("Naujas kito produkto skyrimas");
      }
    }

    // formos inicializavimas koreguojant esantį skyrimą
    function loadForm(prescriptionId) {
      $scope.form = {};
      $scope.medicationPrescription = {};
      $scope.medicationPrescription.additional = {};
      ErxPrescriptionsApiFactory.getDocument({ id: prescriptionId },
        function (res) {

            var prescriptionType = res.prescriptionData.prescriptionType.code === 'vv' ? 'va' : res.prescriptionData.prescriptionType.code;
          $scope.medicationPrescription.prescriptionType = prescriptionType;
          $scope.medicationPrescription.prescriptionStatus = res.prescriptionData.prescriptionStatus;
          $scope.medicationPrescription.dispenseData = res.prescriptionData.dispenseData;
          $scope.medicationPrescription.dosageInstructionData = res.prescriptionData.dosageInstructionData;
          $scope.medicationPrescription.metadata = res.metadata;
          $scope.medicationPrescription.dateWritten = res.prescriptionData.dateWritten;
          $scope.medicationPrescription.issues = res.prescriptionData.issues;
          if (res.prescriptionData.dispenseData.dispensePackageSize
            && res.prescriptionData.dispenseData.dispensePackageSize
            && res.prescriptionData.dispenseData.dispensePackageSize.code) {
            $scope.medicationPrescription.dispenseData.dispensePackageSize = getMassVolumeUnitByCode(res.prescriptionData.dispenseData.dispensePackageSize.code);
          }

          if ($scope.medicationPrescription.dispenseData &&
            $scope.medicationPrescription.dispenseData.compensation &&
            $scope.medicationPrescription.dispenseData.compensation.code) {
            try {
              $scope.medicationPrescription.dispenseData.compensation.code =
                parseInt($scope.medicationPrescription.dispenseData.compensation.code);
            } catch (e) {

            }
          }

            $scope.medicationPrescription.dispenseData.aagaSgasNumber = $scope.encounterId;

          if ($scope.isMPSForm()) {
            if (res.prescriptionData.dispenseData.lenses) {
              $scope.medicationPrescription.lensFlag = true;
              $scope.medicationPrescription.dispenseData.lenses = res.prescriptionData.dispenseData.lenses;
            }
          }

          if ($scope.isMedicineForm() || $scope.isMPPForm() || $scope.isNamedMedicineForm()) {

            if (angular.isDefined(res.prescriptionData.medicationRef)) {
              $scope.medicationPrescription.medicationRef = res.prescriptionData.medicationRef;
              $scope.medicationPrescription.isBrand = true;
            }
            $scope.medicationPrescription.drug = res.prescriptionData.medicationData;
            $scope.medicationPrescription.drug.label = " ";
            $scope.medicationPrescription.drug.ref = res.prescriptionData.medicationRef;
            $scope.medicationSelected = true;
            $scope.medicationPrescription.drug.atc = res.prescriptionData.dispenseData.atc;

              $scope.medicationPrescription.reason = [res.prescriptionData.prescriptionDiagnosis];

            $scope.detailedInfo = true;
          }

          if ($scope.isMedicineForm()) {
            if ($scope.medicationPrescription.dispenseData.dispensePackageName) {
              ErxMedicationsApiFactory.getPackages({ activeSubstances: $scope.medicationPrescription.drug.activeIngredients, compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined }).$promise.then(function (res) {
                $scope.packages = res.packages;
              });
            }
          }

          if ($scope.isNamedMedicineForm()) {
            $scope.medicationPrescription.drug = res.prescriptionData.medicationData;
            $scope.medicationSelected = true;
            if (angular.isObject(res.prescriptionData.medicationData.pharmForm)) {
              $scope.medicationPrescription.drug.pharmFormText = res.prescriptionData.medicationData.pharmForm.displayValue;
            }
            if (angular.isObject($scope.medicationPrescription.drug.controlledType)) {
              $scope.medicationPrescription.drug.controlledType = $scope.medicationPrescription.drug.controlledType.code;
            }
            delete $scope.medicationPrescription.drug.ingredients;
          }

          if ($scope.isExtemporalForm()) {
            if (res.prescriptionData.medicationData.ingredients
              && res.prescriptionData.medicationData.ingredients.length > 0) {
              if (res.prescriptionData.medicationData.ingredients[0].medication) {
                $scope.medicationPrescription.mainIngredient = 'va';
                $scope.medicationPrescription.drug = res.prescriptionData.medicationData.ingredients[0].medication;
                $scope.medicationPrescription.drug.ref = res.prescriptionData.medicationData.ingredients[0].itemRef;
                $scope.medicationSelected = true;
                $scope.extempAmount = res.prescriptionData.medicationData.ingredients[0].amount;
                $scope.backupMedication = res.prescriptionData.medicationData.ingredients[0].medication;
                  if ($scope.medicationPrescription.dispenseData.dispensePackageName) {
                      ErxMedicationsApiFactory.getPackages({
                          activeSubstances: $scope.medicationPrescription.drug.activeIngredients,
                          compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined
                      }).$promise.then(function (res) {
                          $scope.packages = res.packages;
                      });
                  }
              }
              else if (res.prescriptionData.medicationData.ingredients[0].substance) {
                $scope.medicationPrescription.mainIngredient = 'vm';
                $scope.extempAmount = res.prescriptionData.medicationData.ingredients[0].amount;
                $scope.medicationPrescription.extempPharmForm = res.prescriptionData.medicationData.ingredients[0].pharmForm;
                $scope.medicationPrescription.strength = res.prescriptionData.medicationData.ingredients[0].strength;
                $scope.medicationPrescription.drug = res.prescriptionData.medicationData.ingredients[0].substance.name;
                $scope.medicationSelected = true;
                $scope.backupSubstance = res.prescriptionData.medicationData.ingredients[0].substance;
              }
            }
            else {
              $scope.medicationPrescription.mainIngredient = 'ne';
            }

            $scope.medicationPrescription.medicationData = {
              extemporaneous: res.prescriptionData.medicationData.extemporaneous,
              pharmForm: res.prescriptionData.medicationData.pharmForm,
              isBrand: $scope.medicationPrescription.drug && angular.isDefined($scope.medicationPrescription.drug.ref)
            };

              $scope.medicationPrescription.reason = [res.prescriptionData.prescriptionDiagnosis];

            $scope.detailedInfo = true;

            if (res.prescriptionData.medicationData.controlledType) {
              $scope.medicationPrescription.medicationData.controlledType = res.prescriptionData.medicationData.controlledType.code;
            }

            if (res.prescriptionData.medicationData.pharmFormText) {
              $scope.medicationPrescription.medicationData.pharmForm = res.prescriptionData.medicationData.pharmFormText;
            }
          }

          if (prescriptionType !== 'kp' && !$scope.isExtemporalForm() && !$scope.isNamedMedicineForm()) {
            if (angular.isDefined(res.prescriptionData.medicationData)) {
              $scope.medicationPrescription.medicationData = res.prescriptionData.medicationData;
            }
          }

          $timeout(function () {
            $scope.validToChanged();
          });
          $scope.prescriptionFound = true;
        },
        function (error) {
          $scope.prescriptionFound = false;
        }
      );
    }


    function getMassVolumeUnitByCode(code) {
      return $filter('filter')($scope.massAndVolumeUnits, { code: code })[0];
    }

    var validateWidgets = function () {
      var ret = [];
        MessageService.clear('drug-region');
        MessageService.clear('named-drug-region');
        MessageService.clear('mpp-region');
        MessageService.clear('em-drug-region');
        MessageService.clear('prescription-region');

      if ($scope.isMedicineForm()) {
        if (!$scope.medicationPrescription.drug ||
          !$scope.medicationPrescription.drug.activeIngredients) {
            MessageService.add('error', gettextCatalog.getString('Privaloma pasirinkti skiriamą vaistą'), 'drug-region');
          ret.push('[msg-region="drug-region"]');
        }
      } else if ($scope.isNamedMedicineForm()) {
        if (!angular.isObject($scope.medicationPrescription.drug) ||
          !$scope.medicationPrescription.drug.activeIngredients) {
            MessageService.add('error', gettextCatalog.getString('Privaloma pasirinkti arba įvesti skiriamą vaistą'), 'named-drug-region');
          ret.push('[msg-region="named-drug-region"]');
        }
      } else if ($scope.isMPPForm() && $scope.medicationPrescription.dispenseData.compensationTag) {
        if (!$scope.medicationPrescription.drug ||
          !$scope.medicationPrescription.drug.activeIngredients) {
            MessageService.add('error', gettextCatalog.getString('Privaloma pasirinkti skiriamą MPP'), 'mpp-region');
          ret.push('[msg-region="em-drug-region"]');
        }
      } else if ($scope.isExtemporalForm()) {
        if ($scope.medicationPrescription.mainIngredient === 'va') {
          if (!$scope.medicationPrescription.drug ||
            !$scope.medicationPrescription.drug.activeIngredients) {
              MessageService.add('error', gettextCatalog.getString('Privaloma pasirinkti vaistą arba veikliają medžiagą'), 'em-drug-region');
            ret.push('[msg-region="em-drug-region"]');
          }
        }
      }
      return ret;
    }

    // formos validumo nustatymas
    var validateForm = function (form) {
      var invalidWidgets = validateWidgets();
      if (form.$valid && invalidWidgets.length === 0) {
        return true;
      }
      $erxHelpers.setAllInputsDirty(form);
      $erxHelpers.scrollToFirstInvalidOrCustom(invalidWidgets);
      return false;
    };
    // formos duomenų vertimas į formą, tinkamą siųsti į aplikacijos REST API
    var getDocForm = function () {
      var prs: any = {};
      if ($scope.isNamedMedicineForm()) {
        prs.prescriptionType = 'vv';
      } else {
        prs.prescriptionType = angular.copy($scope.medicationPrescription.prescriptionType.toLowerCase());
      }

      var drugInteractionQueryId = ErxDrugInteractionApiFactory.getLastDrugInteractionQueryId();
      if (drugInteractionQueryId) {
          $scope.medicationPrescription.dispenseData.drugInteractionQueryId = drugInteractionQueryId;
          ErxDrugInteractionApiFactory.setLastDrugInteractionQueryId(undefined);
      }

      prs.dosageInstructionData = angular.copy($scope.medicationPrescription.dosageInstructionData);
      if ((!$scope.isMedicineForm() || $scope.medicationPrescription.isBrand === true)
          && angular.isDefined($scope.medicationPrescription.dispenseData.prescriptionTags) && $scope.medicationPrescription.dispenseData.compensationTag === true) {
        delete $scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag;
      }

      prs.dispenseData = angular.copy($scope.medicationPrescription.dispenseData);
      if (prs.dispenseData.prescriptionTag)
        prs.dispenseData.prescriptionTag = prs.dispenseData.prescriptionTag.toLowerCase();
      if (angular.isDefined($scope.medicationPrescription.medicationData)) {
        prs.medicationData = $scope.medicationPrescription.medicationData;
      } else if (!$scope.isOtherForm()) {
        prs.medicationData = {};
      }

      prs.issues = $scope.medicationPrescription.issues;

      if (angular.isDefined($scope.medicationPrescription.drug)) {
        prs.dispenseData.atc = $scope.medicationPrescription.drug.atc;
        if ($scope.medicationPrescription.isBrand === true && !$scope.isNamedMedicineForm()) {
          prs.medicationRef = $scope.medicationPrescription.drug.ref;

          delete prs.medicationData;
        } else {
          if ($scope.isNamedMedicineForm()) {
            if ($scope.medicationPrescription.drug.ref) {
              prs.medicationRef = $scope.medicationPrescription.drug.ref;
              delete prs.medicationData;
            } else {
              prs.medicationData.productName = $scope.medicationPrescription.drug.productName;
              if (angular.isObject($scope.medicationPrescription.drug.activeIngredients)) {
                prs.medicationData.ref = $scope.medicationPrescription.drug.activeIngredients.ref;
                prs.medicationData.activeIngredients = $scope.medicationPrescription.drug.activeIngredients.activeIngredients;
              } else {
                prs.medicationData.activeIngredients = $scope.medicationPrescription.drug.activeIngredients;
              }
              prs.medicationData.strength = $scope.medicationPrescription.drug.strength;
              if ($scope.medicationPrescription.drug.controlledType
                && angular.isString($scope.medicationPrescription.drug.controlledType.code)) {
                prs.medicationData.controlledType = $scope.controlledTypes[$scope.medicationPrescription.drug.controlledType.code];
              }
              prs.medicationData.pharmFormText = $scope.medicationPrescription.drug.pharmFormText;
              delete prs.medicationData.pharmForm;
            }
          } else if ($scope.isExtemporalForm()) {
            if (angular.isObject($scope.medicationPrescription.medicationData.pharmForm)) {
              prs.medicationData.pharmForm = angular.copy($scope.medicationPrescription.medicationData.pharmForm);
              delete prs.medicationData.pharmFormText;
            }
            else if (angular.isString($scope.medicationPrescription.medicationData.pharmForm)) {
              prs.medicationData.pharmFormText = $scope.medicationPrescription.medicationData.pharmForm;
              delete prs.medicationData.pharmForm;
            }
            if (prs.medicationData.extemporaneous.volumeWeight
              && (!prs.medicationData.extemporaneous.volumeWeight.value
                || !prs.medicationData.extemporaneous.volumeWeight.unit)) {
              delete prs.medicationData.extemporaneous.volumeWeight;
            }
            if ($scope.medicationPrescription.medicationData.controlledType
              && angular.isString($scope.medicationPrescription.medicationData.controlledType.code)) {
              prs.medicationData.controlledType = $scope.controlledTypes[$scope.medicationPrescription.medicationData.controlledType.code];
            }

          } else if ($scope.isMPPForm()) {
            if ($scope.medicationPrescription.isBrand) {
              prs.medicationData.ref = $scope.medicationPrescription.drug.ref;
            } else {
              prs.medicationData.activeIngredients = $scope.medicationPrescription.drug.activeIngredients;
              if (!$scope.medicationPrescription.dispenseData.compensationTag && !$scope.medicationPrescription.drug.activeIngredients)
                prs.medicationData.productName = $scope.medicationPrescription.drug.productName;
            }
          } else if ($scope.isMPSForm()) {

          } else {
            prs.medicationData.ref = $scope.medicationPrescription.drug.ref;
            prs.medicationData.activeIngredients = $scope.medicationPrescription.drug.activeIngredients;
            prs.medicationData.strength = $scope.medicationPrescription.drug.strength;
            if ($scope.medicationPrescription.drug.pharmForm)
              prs.medicationData.pharmForm = $scope.medicationPrescription.drug.pharmForm;
            if ($scope.medicationPrescription.drug.controlledType)
              prs.medicationData.controlledType = $scope.medicationPrescription.drug.controlledType;
          }
        }
      }

      if (angular.isDefined($scope.medicationPrescription.reason)) {
        prs.reason = $scope.medicationPrescription.reason;
      }

      if (angular.isDefined(prs.dosageInstructionData.schedule)) {
        if (!prs.dosageInstructionData.schedule.units ||
          !prs.dosageInstructionData.schedule.frequency) {
          delete prs.dosageInstructionData.schedule;
        }
      }

      if (angular.isDefined(prs.dosageInstructionData.doseQuantity)) {
        if (!prs.dosageInstructionData.doseQuantity.unit ||
          !prs.dosageInstructionData.doseQuantity.value) {
          delete prs.dosageInstructionData.doseQuantity;
        }
      }

      if (angular.isDefined(prs.dosageInstructionData.dosePerDay)) {
        if (!prs.dosageInstructionData.dosePerDay.unit ||
          !prs.dosageInstructionData.dosePerDay.value) {
          delete prs.dosageInstructionData.dosePerDay;
        }
      }

      if (angular.isDefined(prs.medicationData) && angular.isDefined(prs.medicationData.extemporaneous)) {
        if (angular.isDefined(prs.medicationData.extemporaneous.volumeWeight)) {
          if (!prs.medicationData.extemporaneous.volumeWeight.value || !prs.medicationData.extemporaneous.volumeWeight.unit) {
            delete prs.medicationData.extemporaneous.volumeWeight;
          }
        }
      }

      if (angular.isDefined($scope.medicationPrescription.dispenseData.lensFlag)) {
        prs.dispenseData.lenses = $scope.medicationPrescription.dispenseData.lenses;
      }

        prs.dispenseData.aagaSgasNumber = $scope.encounterId;

      if ($scope.isMPPForm()) {
        prs.dispenseData.dispenseQuantity.unit = {
          code: 'vnt.',
          displayValue: 'Vienetas'
        }
      }
        prs.medicationData !== undefined ?
            prs.medicationData.isBrand = $scope.medicationPrescription.isBrand :
            prs.medicationData = {isBrand: $scope.medicationPrescription.isBrand};

      var docForm: any = { "prescriptionData": prs };
      if (angular.isDefined($scope.medicationPrescription.metadata)) {
        docForm.metadata = $scope.medicationPrescription.metadata;
      } else {
        docForm.metadata = {};
      }

      return docForm;
    }

    // Vaistų paieška pagal teksto fragmentą
    $scope.getMedications = function (viewValue) {
      if (viewValue != null && viewValue !== '' && typeof viewValue !== 'object' && viewValue.length >= 3) {
        $scope.typeaheadLoading = true;
        return $scope.options.getMedications(10, viewValue, 'PRODUCT', 'VA', $scope.medicationPrescription.dispenseData.compensationTag, true)
            .then(function (response) {
                  $scope.typeaheadLoading = false;
                  return response.map(r => Object.assign(r, {label: $scope.typeaheadDrugFormatter(r)}));
                },
                function (error) {
                  $scope.typeaheadLoading = false;
                  return []
                });
      }
      return [];
    }

    $scope.typeaheadComparator = function (){
        return ErxTypeaheadUtilsService.typeaheadComparator();
    }

    $scope.drugSearchPhrase = function (drug, type) {
        return ErxTypeaheadUtilsService.drugSearchPhrase(drug, type);
    }

    $scope.typeaheadDrugFormatter = function (drug) {
        return ErxTypeaheadUtilsService.typeaheadDrugFormatter(drug);
    }

    $scope.typeaheadMppFormatter = function (drug) {
        return ErxTypeaheadUtilsService.typeaheadMppFormatter(drug);
    }

    $scope.typeaheadSubstancesFormatter = function (drug) {
        return ErxTypeaheadUtilsService.typeaheadSubstancesFormatter(drug);
    }

    $scope.activeIngredients = [];
    // Ingredientų paieška pagal teksto fragmentą
    $scope.getActiveSubstances = function (viewValue) {
      if ($scope.namedInputsDisabled()) {
        return [];
      }
      if (viewValue != null && viewValue != '' && typeof viewValue !== 'object' && viewValue.length >= 3) {
        $scope.activeIngredientsLoading = true;
        return $scope.options.getMedications(10, viewValue, 'PRODUCT', 'VA', $scope.medicationPrescription.dispenseData.compensationTag, true)
            .then(function (response) {
              for (var i = 0; i < response.length; i++) {
                  response[i]['label'] = response[i]['activeIngredients'];
              }

              if ($scope.medicationPrescription.drug && !angular.isObject($scope.medicationPrescription.drug.activeIngredients)) {
                var matches = $filter('filter')(response, { activeIngredients: $scope.medicationPrescription.drug.activeIngredients }, true);
                if (matches.length >= 1) {
                  $scope.medicationPrescription.drug.activeIngredients = matches[0];
                }
              }

              $scope.activeIngredientsLoading = false;
              return response.map(r => Object.assign(r, {label: $scope.typeaheadDrugFormatter(r)}));
            },
            function (error) {
              $scope.activeIngredientsLoading = false;
              return [];
            });
      }
      return [];
    };
    $scope.mpps = [];
    // MPP paieška pagal teksto fragmentą
    $scope.getMpps = function (viewValue) {
      if (viewValue != null && viewValue != '' && typeof viewValue !== 'object' && viewValue.length >= 3) {
        $scope.mppsLoading = true;
        return $scope.options.getMedications(10, viewValue, 'PRODUCT', 'MPP', $scope.medicationPrescription.dispenseData.compensationTag, false)
            .then(function (response) {
              for (var i = 0; i < response.length; i++) {
                response[i]['label'] = response[i]['productName'] + ' (' + response[i]['activeIngredients'] + ')';
              }
              $scope.mppsLoading = false;
              return response.map(r => Object.assign(r, {label: $scope.typeaheadMppFormatter(r)}));
            },
            function (error) {
              $scope.mppsLoading = false;
              return [];
            })
      }
      return [];
    }

    $scope.substances = [];
    // Veikliųjų medžiagų paieška pagal teksto fragmentą
    $scope.getSubstances = function (viewValue) {
      if (viewValue != null && viewValue != '' && typeof viewValue !== 'object' && viewValue.length >= 3) {
        $scope.substancesLoading = true;
        return ErxSubstancesApiFactory.searchSubstances({ count: 10, query: viewValue }).$promise
            .then(function (response) {
                for (var i = 0; i < response.length; i++) {
                  response[i]['label'] = response[i]['name'];
                }
                if ($scope.medicationPrescription.drug && !angular.isObject($scope.medicationPrescription.drug.activeIngredients)) {
                  var matches = $filter('filter')(response, { name: $scope.medicationPrescription.drug }, true);
                  if (matches.length >= 1) {
                    $scope.medicationPrescription.drug = matches[0];
                  }
                }
                $scope.substancesLoading = false;
                return response.map(r => Object.assign(r, {label: $scope.typeaheadSubstancesFormatter(r)}));
              },
              function (error) {
                $scope.substancesLoading = false;
                return [];
              });
      }
      return [];
    }

    // Paros dozės paskaičiavimas pagal susijusius laukus
    $scope.calculateDosePerDay = function () {
      if ($scope.medicationPrescription.dosageInstructionData.schedule
        && $scope.medicationPrescription.dosageInstructionData.schedule.frequency
        && $scope.medicationPrescription.dosageInstructionData.doseQuantity
        && $scope.medicationPrescription.dosageInstructionData.doseQuantity.value
        && $scope.medicationPrescription.dosageInstructionData.schedule.units
        && $scope.medicationPrescription.dosageInstructionData.schedule.units === 'd') {
        $scope.medicationPrescription.dosageInstructionData.dosePerDay.value =
          Number(($scope.medicationPrescription.dosageInstructionData.schedule.frequency
            * $scope.medicationPrescription.dosageInstructionData.doseQuantity.value).toFixed(5));
      } else {
        if (!$scope.medicationPrescription.dosageInstructionData.dosePerDay)
          $scope.medicationPrescription.dosageInstructionData.dosePerDay = {};
        $scope.medicationPrescription.dosageInstructionData.dosePerDay.value = null;
      }
      $scope.changeDosageSummaryData();
    }

    $scope.calculateUsageFields = function () {
      //changedField dq - dosequantity, sc - schedule, dd - duration, dpq - dispensequantity
      try {
        if ($scope.medicationPrescription.dosageInstructionData.doseQuantity.value &&
          $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit &&
          $scope.medicationPrescription.dosageInstructionData.schedule.frequency &&
          $scope.medicationPrescription.dosageInstructionData.schedule.units === 'd' &&
          $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration) {
          if (!$scope.isFormPackage()) {
            $scope.medicationPrescription.dispenseData.dispenseQuantity.value = Number((
              $scope.medicationPrescription.dosageInstructionData.doseQuantity.value *
              $scope.medicationPrescription.dosageInstructionData.schedule.frequency *
              $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration).toFixed(5));
            $scope.medicationPrescription.dispenseData.dispenseQuantity.unit =
              $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit;
          }
        }
        $scope.changeDosageSummaryData();
      } catch (e) {

      }
    }

    // Ar pasirinkta dozuotės forma yra pakuotė
    $scope.isFormPackage = function () {
      try {
        return $scope.medicationPrescription.dispenseData.dispenseQuantity.unit.code === 'pak.';
      } catch (err) {
        return false;
      }
    }

    // Ar yra skyrimo koregavimas
    $scope.isEditForm = function () {
      return angular.isDefined($scope.medicationPrescription.metadata) &&
        angular.isDefined($scope.medicationPrescription.metadata.id);
    };
    // Ar skiriamas vaistas
    $scope.isMedicineForm = function () {
      return ($scope.medicationPrescription.prescriptionType === 'va')
        && !$scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    };
    // Ar skiriamas vardinis vaistas
    $scope.isNamedMedicineForm = function () {
      return ($scope.medicationPrescription.prescriptionType === 'va')
        && $scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    };
    // Ar skiriama MPS
    $scope.isMPSForm = function () {
        return $scope.medicationPrescription.prescriptionType === 'mpa';
    }
    // Ar skiriama MPP
    $scope.isMPPForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'mpp';
    };
    // Ar skiriamas ekstemporalus vaistas
    $scope.isExtemporalForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'ev';
    };
    // Ar skiriamas kitas produktas
    $scope.isOtherForm = function () {
      return $scope.medicationPrescription.prescriptionType === 'kp';
    };

    // Ar rodyti vaistų sąveikas
    $scope.showDrugInteraction = function() {
        return $scope.patientId && ($scope.isMedicineForm() || $scope.isNamedMedicineForm() || $scope.isExtemporalForm());
    };

    $scope.isFirstPrescribe = function() {
      return (($scope.medicationPrescription.drug && $scope.medicationPrescription.drug.compensated && $scope.medicationPrescription.dispenseData.compensationTag)
              || angular.isDefined($scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag));
    };

    $scope.showFirstPrescribing = function() {
      return $scope.isFirstPrescribe() && !$scope.medicationPrescription.isBrand;
    };

    function getRouteByCode(routeCode) {
      return $filter('filter')($scope.dosageInstructions, { code: routeCode }, true)[0];
    }

    // Kompensavimo pasikeitimo logika
    $scope.compensatedChanged = function () {
      $timeout(function () {
        $scope.medications = [];
        delete $scope.packages;
        if ($scope.medicationPrescription) {
          delete $scope.medicationPrescription.drug;
        }

        if ($scope.medicationPrescription.dispenseData.compensationTag === true) {
          $scope.checkForPrescriptionValidity();
          if (!angular.isDefined($scope.medicationPrescription.additional)) {
            $scope.medicationPrescription.additional = {};
          }
          $scope.setDefaultCompensationTypeCode();
        } else {
          delete $scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag;
          delete $scope.medicationPrescription.dispenseData.compensation;
        }
      }, 0);
    };
    // Recepto žymos pasikeitimo logika
    $scope.prescriptionTagChanged = function () {
        MessageService.clear('drug-region');
        MessageService.clear('em-drug-region');
      delete $scope.medicationPrescription.dispenseData.validFrom;
      delete $scope.medicationPrescription.dispenseData.validUntil;
      delete $scope.medicationPrescription.dispenseData.paperPresCompLotNumber;
      delete $scope.medicationPrescription.dispenseData.paperPresCompNumber;
      delete $scope.medicationPrescription.dispenseData.paperPresNPNumber;
      delete $scope.medicationPrescription.dispenseData.paperPresNPLotNumber;
      delete $scope.medicationPrescription.dispenseData.dispenserNote;
      delete $scope.medicationPrescription.dispenseData.compensation;
      if (angular.isDefined($scope.medicationPrescription.dispenseData.prescriptionTags)) {
        delete $scope.medicationPrescription.dispenseData.prescriptionTags.numberOfRepeatsAllowed;
      }

      if (angular.isDefined($scope.medicationPrescription.additional)) {
        delete $scope.medicationPrescription.additional.validFor;
      }
      if ($scope.medicationPrescription.dispenseData.prescriptionTag === 'ier' && !$scope.medicationPrescription.dispenseData.validFrom) {
        var currDate = new Date();
        currDate.setHours(0, 0, 0, 0);
        $scope.medicationPrescription.dispenseData.validFrom = currDate.getTime();

        if (!angular.isDefined($scope.medicationPrescription.additional)) {
          $scope.medicationPrescription.additional = {};
        }
      }

      $scope.checkForPrescriptionValidity();
    }

    // Vardinio vaisto paieškos pasikeitimo logika
    $scope.namedTypeaheadChanged = function () {
      if (angular.isObject($scope.medicationPrescription.drug)) {
        $scope.medicationPrescription.drug.pharmFormText = $scope.medicationPrescription.drug.pharmForm.displayValue;
        delete $scope.medicationPrescription.drug.pharmForm;
      }
    }


    $scope.checkMedicineDrugInteraction = function() {
        var drug = $scope.medicationPrescription.drug;
        var activeIngredients = drug.activeIngredients;
        var pharmFormText = drug.pharmForm ? drug.pharmForm.displayValue : drug.pharmFormText;
        $scope.$broadcast('drg:selected', activeIngredients, pharmFormText);
    }

    $scope.checkNominalDrugInteraction = function() {
        //Vaistų sąveikos direktyvai
        var activeIngredients = $scope.medicationPrescription.drug.activeIngredients;
        if (angular.isObject($scope.medicationPrescription.drug.activeIngredients)) {
            activeIngredients = activeIngredients.activeIngredients;
        }
        var pharmFormText = $scope.medicationPrescription.drug.pharmFormText;
        $scope.$broadcast('drg:cleared');
        if (activeIngredients && pharmFormText) {
            $scope.$broadcast('drg:selected', activeIngredients, pharmFormText);
        }
    }

    $scope.checkExtempDrugInteraction = function(valid) {
        //Vaistų sąveikos direktyvai
        $scope.$broadcast('drg:cleared');
        if (valid) {
            var activeIngredients = $scope.medicationPrescription.drug;
            if (angular.isObject(activeIngredients)) {
                if (angular.isDefined(activeIngredients.label)) {
                    activeIngredients = activeIngredients.label;
                } else {
                    activeIngredients = activeIngredients.activeIngredients;
                }
            }
            if (angular.isDefined($scope.medicationPrescription.medicationData)) {
                var extempPharmForm = $scope.medicationPrescription.medicationData.pharmForm;
                if (angular.isObject(extempPharmForm)) {
                    extempPharmForm = extempPharmForm.displayValue;
                }
            }
            if (activeIngredients && extempPharmForm) {
                $scope.$broadcast('drg:selected', activeIngredients, extempPharmForm);
            }

        }
    }


    // Recepto galiojimo periodo pasikeitimo logika
    $scope.validForChanged = function () {
      if ($scope.medicationPrescription.dispenseData.validFrom)
        if (!$scope.medicationPrescription.additional.validFor)
          $scope.medicationPrescription.dispenseData.validUntil = null;
        else {
          $scope.medicationPrescription.dispenseData.validUntil =
            moment($scope.medicationPrescription.dispenseData.validFrom).add('days', $scope.medicationPrescription.additional.validFor - 1).valueOf();
        }
    };
    // Recepto galiojimo periodo pasikeitimo logika
    $scope.validToChanged = function () {
      if ($scope.medicationPrescription.dispenseData.validUntil) {
        try {
          $scope.medicationPrescription.additional.validFor = ""; //Fix
          $scope.medicationPrescription.additional.validFor = 1 +
            Math.floor((new Date($scope.medicationPrescription.dispenseData.validUntil).getTime() - new Date($scope.medicationPrescription.dispenseData.validFrom).getTime()) / 86400000);
        } catch (err) {
        }
      } else if (angular.isDefined($scope.medicationPrescription.additional)) {
        $scope.medicationPrescription.additional.validFor = null;
      }
    };
    $scope.canEditWarnings = function () {
      return $scope.medicationPrescription.issues
        && $scope.medicationPrescription.issues.length > 0;
    }

    var saveMpr = function (docForm) {
      return ErxPrescriptionsApiFactory.submitMedPrescription(docForm,
        function (res) {

        },
        function (err) {
          if (err.data && err.data.messages) {
            var warnings = [];
            angular.forEach(err.data.messages, function (value, key) {
              if (value.type === 'warning') {
                warnings.push({
                  issue:
                  {
                    code: value.code,
                    displayValue: value.content
                  }
                });
              }
            });
            if (warnings.length > 0) {
              $scope.medicationPrescription.issues = warnings;
            }
          }
        });
    }

        $scope.getActiveQualification = function () {
            if ($scope.activeQualification) {
                const qf = $scope.practitionerData?.qualificationList
                    ?.filter(q => q.licenseCode === $scope.activeQualification)
                    ?.map(q => {
                        q.licenseExpired = new Date(q.licenseExpired).getTime();
                        q.licenseIssued = new Date(q.licenseIssued).getTime();
                        return q;
                    });
                if (qf?.length > 0) {
                    return qf[0];
                }
            }
            return undefined;
        }

    $scope.save = function (form, signTag) {
      if (!validateForm(form)) {
        return;
      }

        var docForm = getDocForm();
        docForm.prescriptionData.prescriptionDiagnosis = $scope.conditions[0];
        docForm.prescriptionData.reason = null;
        docForm.prescriptionData.prescriptionType = {code: $scope.medicationPrescription.prescriptionType};
        docForm.prescriptionData.patientRef = $scope.patientRef;
        docForm.prescriptionData.encounterRef = $scope.encounterRef;
        //docForm.metadata.relCompId = $scope.options.getCompositionId();
        //docForm.practitionerQualification = $scope.compositionData.activeQualification;
        docForm.practitionerQualification = $scope.getActiveQualification();

        $scope.actionsDisabled = true;
        ErxPrescriptionsApiFactory.saveMedPrescription(docForm,
        function (res) {
          docForm.metadata.id = res.id;
          saveMpr(docForm).$promise.then(function (res) {
            if (!signTag) {
              $location.url($scope.options.getReturnUrl());
            } else if (signTag) {
              $scope.options.redirectToViewPrescriptionDoc(res.id);
            }
          }).finally(function () {
            $scope.actionsDisabled = false;
          });
        },
        function (err) {
          $scope.actionsDisabled = false;
        });
    }
    // Juodraščio saugojimas
    $scope.saveDraftDoc = function (form) {
      if (!validateForm(form)) {
        return;
      }
      var docForm = getDocForm();
        docForm.prescriptionData.patientRef = $scope.patientRef;
        docForm.prescriptionData.encounterRef = $scope.encounterRef;
        //docForm.metadata.relCompId = $scope.options.getCompositionId();
        //docForm.practitionerQualification = $scope.compositionData.activeQualification;
        docForm.practitionerQualification = $scope.getActiveQualification();

        $scope.actionsDisabled = true;
      ErxPrescriptionsApiFactory.saveMedPrescription(docForm,
        function (res) {
          $location.url($scope.options.getReturnUrl());
          $scope.options.getIntegrationData().displayGlobalMessage('success', gettextCatalog.getString('Skyrimo juodraštis išsaugotas'));
        })
        .$promise.finally(function () {
          $scope.actionsDisabled = false;
        });
    };
    // Ar leidžiama išsaugoti juodraštį
    $scope.canSaveDraft = function () {
      return !$scope.medicationPrescription.prescriptionStatus;
    };
    // Ar leidžiama pašalinti skyrimą
    $scope.canDelete = function () {
      return angular.isDefined($scope.medicationPrescription.metadata.id
        && (!$scope.medicationPrescription.prescriptionStatus
          || $scope.medicationPrescription.prescriptionStatus === 'valid'));
    };
    // Skyrimo šalinimas
    $scope.deletePrescription = function () {
      if ($scope.medicationPrescription.metadata.id) {
        $erxModalConfirm.show({ content: gettextCatalog.getString('Ar tikrai norite pašalinti?') }).then(function () {
          $scope.actionsDisabled = true;
          ErxPrescriptionsApiFactory.deleteMedPrescription({ id: $scope.medicationPrescription.metadata.id }).$promise.then(
            function (res) {
              $location.url($scope.options.getReturnUrl());
              $scope.options.getIntegrationData().displayGlobalMessage('success', gettextCatalog.getString('Skyrimas pašalintas'));
            }
          ).finally(function () {
            $scope.actionsDisabled = false;
          });
        });
      }
    };
    // Vardinio vaisto žymos pakeitimo logika
    $scope.nominalTagChanged = function () {
      delete $scope.medicationPrescription.dispenseData.prescriptionTags.nominalConfirmTag;
      delete $scope.medicationPrescription.dispenseData.prescriptionTags.nominalDeclarationValid;
      $scope.medicationPrescription.drug = {};
      $scope.medicationSelected = false;
      $scope.detailedInfo = false;
      if ($scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag) {
        $scope.medicationPrescription.dispenseData.compensationTag = false;
        var currDate: any = new Date();
        $scope.medicationPrescription.dispenseData.prescriptionTags.nominalDeclarationValid = currDate.setYear(currDate.getFullYear() + 1);
      } else {
        $scope.medicationPrescription.dispenseData.compensationTag = false;
      }

      delete $scope.medicationPrescription.dispenseData.prescriptionTags.gkkTag;
      $scope.gkkTagChanged();
        $scope.medicationPrescription.dispenseData.prescriptionTags.nominalConfirmTag = false;
    };
    // Ar recepto galiojimo periodas validus
    $scope.dateValid = function () {
      var from = $scope.medicationPrescription.dispenseData.validFrom;
      var to = $scope.medicationPrescription.dispenseData.validUntil;
      return from === undefined || to === undefined || from === '' || to === '' || from <= to;
    }

    // Paros dozės formos pasikeitimo logika
    $scope.dosePerDayUnitCodeChanged = function () {
      if ($scope.medicationPrescription.dosageInstructionData.dosePerDay.unit) {
        if (!$scope.medicationPrescription.dosageInstructionData.doseQuantity) {
          $scope.medicationPrescription.dosageInstructionData.doseQuantity = {};
        }
        $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit = $scope.medicationPrescription.dosageInstructionData.dosePerDay.unit;

        if (!$scope.isFormPackage()) {
          if (!$scope.medicationPrescription.dispenseData.dispenseQuantity) {
            $scope.medicationPrescription.dispenseData.dispenseQuantity = {};
          }
          $scope.medicationPrescription.dispenseData.dispenseQuantity.unit =
            $scope.medicationPrescription.dosageInstructionData.dosePerDay.unit;
        }
      }
    };
    // Vienkartinės dozės formos pasikeitimo logika
    $scope.doseQuantityUnitCodeChanged = function () {
      if ($scope.medicationPrescription.dosageInstructionData.doseQuantity.unit) {
        if (!$scope.medicationPrescription.dosageInstructionData.dosePerDay) {
          $scope.medicationPrescription.dosageInstructionData.dosePerDay = {};
        }
        $scope.medicationPrescription.dosageInstructionData.dosePerDay.unit = $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit;

        if (!$scope.isFormPackage()) {
          if (!$scope.medicationPrescription.dispenseData.dispenseQuantity) {
            $scope.medicationPrescription.dispenseData.dispenseQuantity = {};
          }
          $scope.medicationPrescription.dispenseData.dispenseQuantity.unit =
            $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit;
        }
      }
      $scope.calculateUsageFields();
    };
    // Pasikeitus ypatingo skyrimo žymai išvalomas jo pagrindimas
    $scope.specialTagChanged = function () {
      delete $scope.medicationPrescription.dispenseData.prescriptionTags.specialTagExp;
    };
    // Pasikeitus ilgalaikio vartojimo žymai išvalomi detalesni duomenys
    $scope.continuousTreatmentTagChanged = function () {
      delete $scope.medicationPrescription.dispenseData.prescriptionTags.numberOfRepeatsAllowed;
    };
    // Pasikeitus gkk sprendimo žymai išvalomas pagrindimas dėl gkk
    $scope.gkkTagChanged = function () {
      if($scope.isExtemporalForm()){
        $scope.medicationPrescription.isBrand = $scope.medicationPrescription.dispenseData.prescriptionTags.gkkTag;
      }
      delete $scope.medicationPrescription.dispenseData.prescriptionTags.gkkTagExp;
    };
    // Parinkus vaistą iš klasifikatoriaus neleidžiama koreguoti detalių vaisto laukų
    $scope.namedInputsDisabled = function () {
      return $scope.medicationPrescription.drug.length > 0 ||
        $scope.medicationPrescription.drug.ref;
    };
    $scope.showMedInfo = function (ref) {
      $erxModalMedinfo.show({ ref: ref ? ref : $scope.medicationPrescription.drug.ref });
    };
    $scope.canCreateUsingOlder = function () {
      return angular.isDefined($scope.options.prescriptionType);
    }


    // Skyrimo pagal ankstesnį modalinio lango kvietimas bei apdorojimas
    $scope.showOlderPrescriptionModal = function () {
        $erxModalSearch.show({
            prescriptionType: $scope.options.prescriptionType.toUpperCase(),
            patientId: $scope.patientId
        }).then(function (prescriptionId) {
        ErxPrescriptionsApiFactory.getDocument({ id: prescriptionId }).$promise.then(function (res) {

            var prescriptionType = res.prescriptionData.prescriptionType.code === 'vv' ? 'va' : res.prescriptionData.prescriptionType.code;
          $scope.medicationPrescription.dispenseData = res.prescriptionData.dispenseData;
          $scope.medicationPrescription.dosageInstructionData = res.prescriptionData.dosageInstructionData;

          if (res.prescriptionData.dispenseData) {
              delete $scope.medicationPrescription.dispenseData.aagaSgasNumber;

              if (res.prescriptionData.dispenseData.validFrom) {
                  $scope.validToChanged();
                  var currDate = new Date();
                  currDate.setHours(0, 0, 0, 0);
                  $scope.medicationPrescription.dispenseData.validFrom = currDate.getTime();
                  $scope.validForChanged();
              }

              if (res.prescriptionData.dispenseData.dispensePackageSize
                && res.prescriptionData.dispenseData.dispensePackageSize.code) {
                  $scope.medicationPrescription.dispenseData.dispensePackageSize = getMassVolumeUnitByCode(res.prescriptionData.dispenseData.dispensePackageSize.code);
              }
          }

          if ($scope.isMedicineForm() && res.prescriptionData.dispenseData
            && res.prescriptionData.dispenseData.prescriptionTags
            && angular.isDefined(res.prescriptionData.dispenseData.prescriptionTags.firstPrescribingTag)) {
            ErxPrescriptionsApiFactory.isFirstPrescribing({ patientId: $scope.patientId,
                activeSubstances: res.prescriptionData.medicationData.activeIngredients, strength: res.prescriptionData.medicationData.strength,
                pharmFormCode: res.prescriptionData.medicationData.pharmForm.code })
            .$promise.then(function (res) {
               $scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag = res.firstPrescribing;
            });
          }

          if ($scope.isMPSForm()) {
            if (res.prescriptionData.dispenseData.lenses) {
              $scope.medicationPrescription.dispenseData.lensFlag = true;
              $scope.medicationPrescription.dispenseData.lenses = res.prescriptionData.dispenseData.lenses;
            }
          }

          if ($scope.isMedicineForm() || $scope.isMPPForm() || $scope.isNamedMedicineForm()) {

            if (angular.isDefined(res.prescriptionData.medicationRef)) {
              $scope.medicationPrescription.medicationRef = res.prescriptionData.medicationRef;
              $scope.medicationPrescription.isBrand = true;
            }
            $scope.medicationPrescription.drug = res.prescriptionData.medicationData;
            $scope.medicationPrescription.drug.label = " ";
            $scope.medicationPrescription.drug.ref = res.prescriptionData.medicationRef;
            $scope.medicationSelected = true;
            $scope.medicationPrescription.drug.atc = res.prescriptionData.dispenseData.atc;
              $scope.medicationPrescription.reason = [res.prescriptionData.prescriptionDiagnosis];
            $scope.detailedInfo = true;
          }

          if ($scope.isMedicineForm()) {
            if ($scope.medicationPrescription.dispenseData.dispensePackageName) {
              ErxMedicationsApiFactory.getPackages({ activeSubstances: $scope.medicationPrescription.drug.activeIngredients, compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined }).$promise.then(function (res) {
                $scope.packages = res.packages;
              });
            }
          }

          if ($scope.isNamedMedicineForm()) {
            $scope.medicationPrescription.drug = res.prescriptionData.medicationData;
            $scope.medicationSelected = true;
            if (angular.isObject(res.prescriptionData.medicationData.pharmForm)) {
              $scope.medicationPrescription.drug.pharmFormText = res.prescriptionData.medicationData.pharmForm.displayValue;
            }
            if (angular.isObject($scope.medicationPrescription.drug.controlledType)) {
              $scope.medicationPrescription.drug.controlledType = $scope.medicationPrescription.drug.controlledType.code;
            }
            delete $scope.medicationPrescription.drug.ingredients;
          }

          if ($scope.isExtemporalForm()) {
            if (res.prescriptionData.medicationData.ingredients
              && res.prescriptionData.medicationData.ingredients.length > 0) {
              if (res.prescriptionData.medicationData.ingredients[0].medication) {
                $scope.medicationPrescription.mainIngredient = 'va';
                $scope.medicationPrescription.drug = res.prescriptionData.medicationData.ingredients[0].medication;
                $scope.medicationPrescription.drug.ref = res.prescriptionData.medicationData.ingredients[0].itemRef;
                $scope.medicationSelected = true;
                $scope.extempAmount = res.prescriptionData.medicationData.ingredients[0].amount;
                $scope.backupMedication = res.prescriptionData.medicationData.ingredients[0].medication;
                  if ($scope.medicationPrescription.dispenseData.dispensePackageName) {
                      ErxMedicationsApiFactory.getPackages({
                          activeSubstances: $scope.medicationPrescription.drug.activeIngredients,
                          compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined
                      }).$promise.then(function (res) {
                          $scope.packages = res.packages;
                      });
                  }
              }
              else if (res.prescriptionData.medicationData.ingredients[0].substance) {
                $scope.medicationPrescription.mainIngredient = 'vm';
                $scope.extempAmount = res.prescriptionData.medicationData.ingredients[0].amount;
                $scope.medicationPrescription.extempPharmForm = res.prescriptionData.medicationData.ingredients[0].pharmForm;
                $scope.medicationPrescription.strength = res.prescriptionData.medicationData.ingredients[0].strength;
                $scope.medicationPrescription.drug = res.prescriptionData.medicationData.ingredients[0].substance.name;
                $scope.medicationSelected = true;
                $scope.backupSubstance = res.prescriptionData.medicationData.ingredients[0].substance;
              }
            }
            else {
              $scope.medicationPrescription.mainIngredient = 'ne';
            }

            $scope.medicationPrescription.medicationData = {
              extemporaneous: res.prescriptionData.medicationData.extemporaneous,
              pharmForm: res.prescriptionData.medicationData.pharmForm,
              isBrand: $scope.medicationPrescription.drug && angular.isDefined($scope.medicationPrescription.drug.ref)
            };

              $scope.medicationPrescription.reason = [res.prescriptionData.prescriptionDiagnosis];

            $scope.detailedInfo = true;

            if (res.prescriptionData.medicationData.controlledType) {
              $scope.medicationPrescription.medicationData.controlledType = res.prescriptionData.medicationData.controlledType.code;
            }
          }

          if (prescriptionType !== 'kp' && !$scope.isExtemporalForm() && !$scope.isNamedMedicineForm()) {
            if (angular.isDefined(res.prescriptionData.medicationData)) {
              $scope.medicationPrescription.medicationData = res.prescriptionData.medicationData;
            }
          }

          $timeout(function () {
            $scope.validToChanged();
            //Vaistų sąveika
            if ($scope.isMedicineForm()) {
                $scope.checkMedicineDrugInteraction();
            } else if ($scope.isExtemporalForm()) {
                $scope.checkExtempDrugInteraction(true);
            } else if ($scope.isNamedMedicineForm()) {
                $scope.checkNominalDrugInteraction();
            }
          });
          $scope.prescriptionFound = true;

        });
      });
    };
    $scope.clearNominalDrug = function () {
      $scope.medicationPrescription.drug = "";
      $scope.medicationPrescription.isBrand = false;
      $scope.medicationSelected = false;
      //Vaistų sąveikos direktyvai
      $scope.$broadcast('drg:cleared');
    }

    $scope.clearDrug = function () {
      $scope.medicationPrescription.drug = "";
      $scope.medicationPrescription.isBrand = false;
      $scope.medicationSelected = false;
      //Vaistų sąveikos direktyvai
      $scope.$broadcast('drg:cleared');
    }

    $scope.setIsBrandCheckbox = function (){
      $scope.medicationPrescription.isBrand = !$scope.isFirstPrescribe() &&
          (($scope.medicationProduct.subgroup !== undefined &&
              $scope.medicationProduct.subgroup.displayValue === 'Biologinis') ||
              $scope.hasMtiPositive($scope.medicationProduct.ingredients));
    }

    $scope.hasMtiPositive = function (ingredients){
      return ingredients.filter(ingredient => {
        if(ingredient.substance !== undefined){
          return ingredient.substance.mti;
        }
      }).length !== 0;
    }

    // Paprasto vaisto paieškos pasirinkimo papildoma logika
    // Gaunamos galimos pakuotės
    $scope.$on('$typeahead.select', function (evt, value) {
      if(!JSON.parse((event.target as any).getAttribute('defaultOnSelect'))) {
        if ($scope.isMedicineForm()) {
          ErxMedicationsApiFactory.getMedicationById({ref: $scope.medicationPrescription.drug.ref}).$promise.then(function (res) {
            $scope.medicationProduct = res;
            $scope.setIsBrandCheckbox();
          });

          ErxMedicationsApiFactory.getPackages({
            activeSubstances: $scope.medicationPrescription.drug.activeIngredients,
            compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined
          }).$promise.then(function (res) {
            $scope.packages = res.packages;
          });

          if ($scope.medicationPrescription.drug.atc !== undefined) {
            $scope.isInsulin = $scope.medicationPrescription.drug.atc.displayValue !== undefined &&
                $scope.medicationPrescription.drug.atc.displayValue.toLowerCase().includes('insulin');
          }
        } else if ($scope.isMPPForm() && $scope.medicationPrescription.dispenseData.compensationTag) {
          ErxMedicationsApiFactory.getPackages({
            activeSubstances: $scope.medicationPrescription.drug.activeIngredients,
            compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined
          }).$promise.then(function (res) {
            $scope.packages = res.packages;
          });
        } else if ($scope.isExtemporalForm()) {
            if ($scope.medicationPrescription.mainIngredient === 'va') {
                ErxMedicationsApiFactory.getPackages({
                    activeSubstances: $scope.medicationPrescription.drug.activeIngredients,
                    compensated: $scope.medicationPrescription.dispenseData.compensationTag ? true : undefined
                }).$promise.then(function (res) {
                    $scope.packages = res.packages;
                });
            }
            if ($scope.medicationPrescription.medicationData.extemporaneous.description !== undefined) {
                $scope.medicationPrescription.medicationData.extemporaneous.description += "; " + value.label;
            } else {
                $scope.medicationPrescription.medicationData.extemporaneous.description = value.label;
            }
        }
        $scope.getSupplyDisruptions();
        $scope.getSecurityAndEducationalData();
        $scope.medicationSelected = true;
      }
    });

    $scope.getSupplyDisruptions = function() {
      if(($scope.isExtemporalForm() || $scope.isNamedMedicineForm() || $scope.isMedicineForm()) && $scope.medicationPrescription.drug !== undefined){
          const drug = $scope.medicationPrescription.drug;
          $scope.disruptions = [];
          if (drug?.supplyDisruptionType?.code) {
              $scope.disruptions?.push({
                  id: drug?.id,
                  activeIngredients: drug?.activeIngredients,
                  strength: drug?.strength,
                  pharmForm: drug?.pharmForm?.displayValue,
                  npakid7: drug?.npakid7,
                  atcCode: drug?.atc?.code,
                  productName: drug?.productName,
                  supplyDisruptionType: drug?.supplyDisruptionType?.code,
                  supplyDisruptionTypeText: drug?.supplyDisruptionType?.displayValue,
                  dateFrom: drug?.supplyDisruptionFrom,
                  dateTo: drug?.supplyDisruptionTo,
              });
          }
      }
    }

    $scope.getSecurityAndEducationalData = function() {
        if (($scope.isExtemporalForm() || $scope.isNamedMedicineForm() || $scope.isMedicineForm()) && $scope.medicationPrescription.drug !== undefined) {
            $scope.safetyEducationParams = {
                atc: $scope.medicationPrescription.drug?.atc?.code
            };
      }
    }

    $scope.showDisruptionsModal = (index) => {
      $erxDrugSupplyDisruptionsModal.show({ data: $scope.disruptions[index] });
    }

    // Ekstemporalaus vaisto pagrindinės sudėtinės dalies pasikeitimo logika
    $scope.mainIngredientChanged = function () {
        MessageService.clear('em-drug-region');
      $scope.medicationPrescription.isBrand = false;
      delete $scope.medicationPrescription.drug;
      $scope.medicationSelected = false;
        $scope.extempAmount = null;
      if ($scope.medicationPrescription.mainIngredient === 'vm') {
        $scope.medicationPrescription.extempPharmForm = "";
      }
    }

    $scope.controlledTypes = {
      "P": { code: 'P', displayValue: gettextCatalog.getString('Psichotropinė') },
      "N": { code: 'N', displayValue: gettextCatalog.getString('Narkotinė') }
    }
    $scope.yesNoRequired = [
      { value: true, displayValue: gettextCatalog.getString('Taip') },
      { value: false, displayValue: gettextCatalog.getString('Ne') }
    ];
    $scope.showAnalyticalInfo = function () {
      $erxModalAnalyticalInfo.show({ reason: $scope.medicationPrescription.reason });
    };
    $scope.canShowAnalyticalInfo = function () {
      return !!$scope.medicationPrescription.reason;
    }

    $scope.showAnalyticalInfoByOrg = function () {
      $erxModalAnalyticalInfoByOrg.show({});
    };
    $scope.showAnalyticalInfoByAgeGroup = function () {
      $erxModalAnalyticalInfoByAgeGroup.show({});
    };
    $scope.saveDraft = function () {
      var templateType;
      if ($scope.isMedicineForm() || $scope.isNamedMedicineForm()) {
        templateType = 'sk_va';
      } else if ($scope.isMPSForm()) {
        templateType = 'sk_mps';
      } else if ($scope.isMPPForm()) {
        templateType = 'sk_mpp';
      } else if ($scope.isExtemporalForm()) {
        templateType = 'sk_ev';
      } else if ($scope.isOtherForm()) {
        templateType = 'sk_kp';
      }

      if ($scope.isExtemporalForm()) {
        var data: any = {
          medicationPrescription: $scope.medicationPrescription,
          extempAmount: $scope.extempAmount
        };
      } else {
        var data: any = {
          medicationPrescription: $scope.medicationPrescription
        };
      }
      if (data && data.medicationPrescription) {
        if (data.medicationPrescription.issues)
          delete data.medicationPrescription.issues;
        if (data.medicationPrescription.metadata)
          delete data.medicationPrescription.metadata;
      }
      $erxModalSaveDraft.show({ templateType: templateType, data: data });
    }

    $scope.loadDraft = function () {
      var templateType;
      if ($scope.isMedicineForm() || $scope.isNamedMedicineForm()) {
        templateType = 'sk_va';
      } else if ($scope.isMPSForm()) {
        templateType = 'sk_mps';
      } else if ($scope.isMPPForm()) {
        templateType = 'sk_mpp';
      } else if ($scope.isExtemporalForm()) {
        templateType = 'sk_ev';
      } else if ($scope.isOtherForm()) {
        templateType = 'sk_kp';
      }
      $erxModalLoadDraft.show({ templateType: templateType }).then(function (templateId) {
        delete $scope.prescriptionFound;
        ErxTemplatesApiFactory.getById({ id: templateId }, {}).$promise.then(function (res) {
          var data = JSON.parse(res.data);
          var rsn = $scope.medicationPrescription.reason;
          $scope.medicationPrescription = data.medicationPrescription;
          $scope.medicationPrescription.reason = rsn;
          $scope.extempAmount = data.extempAmount;
          $scope.medicationPrescription.metadata = {};

          if ($scope.medicationPrescription.dispenseData) {
            delete $scope.medicationPrescription.dispenseData.aagaSgasNumber;

            if ($scope.medicationPrescription.dispenseData.validFrom) {
              $scope.validToChanged();
              var currDate = new Date();
              currDate.setHours(0, 0, 0, 0);
              $scope.medicationPrescription.dispenseData.validFrom = currDate.getTime();
              $scope.validForChanged();
            }

            if ($scope.isMedicineForm() && $scope.medicationPrescription.dispenseData.prescriptionTags
                && angular.isDefined($scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag)) {
              ErxPrescriptionsApiFactory.isFirstPrescribing({ patientId: $scope.patientId,
                  activeSubstances: $scope.medicationPrescription.drug.activeIngredients, strength: $scope.medicationPrescription.drug.strength,
                  pharmFormCode: $scope.medicationPrescription.drug.pharmForm.code })
              .$promise.then(function (res) {
                 $scope.medicationPrescription.dispenseData.prescriptionTags.firstPrescribingTag = res.firstPrescribing;
              });
            }
          }

          $timeout(function () {
            //Vaistų sąveika
            if ($scope.isMedicineForm()) {
                $scope.checkMedicineDrugInteraction();
            } else if ($scope.isExtemporalForm()) {
                $scope.checkExtempDrugInteraction(true);
            } else if ($scope.isNamedMedicineForm()) {
                $scope.checkNominalDrugInteraction();
            }
          });

          $scope.prescriptionFound = true;

        });
      });
    }

    $scope.orderByFunction = function (comp) {
      return 1 //parseInt(comp.code);
    };

    $scope.getStep = function () {
      if ((!$scope.medicationPrescription.prescriptionStatus ||
        !$scope.medicationPrescription.prescriptionStatus.code)
        && !$scope.options.prescriptionId) {
        return 0;
      } else if ((!$scope.medicationPrescription.prescriptionStatus ||
        !$scope.medicationPrescription.prescriptionStatus.code)
        && $scope.options.prescriptionId) {
        return 1;
      } else if ($scope.medicationPrescription.prescriptionStatus &&
        $scope.medicationPrescription.prescriptionStatus.code === 'active'
        && $scope.medicationPrescription.metadata
        && $scope.medicationPrescription.metadata.docStatus !== 'Signed'
        && $scope.medicationPrescription.metadata.docStatus !== 'entered in error'
        && $scope.medicationPrescription.prescriptionStatus
        && $scope.medicationPrescription.prescriptionStatus.code === 'active') {
        return 2;
      } else {
        return -1;
      }
    }

    $scope.openDoseCalculationModal = function () {
      $erxModalDoseCalculator.show({}).then(function (res) {
        if (!$scope.medicationPrescription.dosageInstructionData.dosePerDay) {
          $scope.medicationPrescription.dosageInstructionData.dosePerDay = {};
        }
        $scope.medicationPrescription.dosageInstructionData.dosePerDay.value = res;
        $scope.dosePerDayUnitCodeChanged();
      })
    }

    $scope.brandNameClicked = function () {
      if (!$scope.medicationPrescription.drug.ref) {
        var region = "";
        if ($scope.isMedicineForm())
          region = 'drug-region';
        else if ($scope.isNamedMedicineForm())
          region = 'named-drug-region';
        else if ($scope.isExtemporalForm())
          region = 'em-drug-region';
        else if ($scope.isMPPForm())
          region = 'mpp-region';

          MessageService.clear(region);
          MessageService.add('error', gettextCatalog.getString('Privaloma pasirinkti skiriamą vaistą'), region);
      }
    }

    $scope.dosageTimeChanged = function () {
      if ((!$scope.medicationPrescription.dosageInstructionData.schedule.frequency
        || [0, 1, 2, 3].indexOf($scope.medicationPrescription.dosageInstructionData.schedule.frequency) > -1)
        && $scope.medicationPrescription.dosageInstructionData.schedule.units === 'd') {
        $scope.medicationPrescription.dosageInstructionData.schedule.frequency =
          ($scope.medicationPrescription.dosageInstructionData.morning ? 1 : 0) +
          ($scope.medicationPrescription.dosageInstructionData.noon ? 1 : 0) +
          ($scope.medicationPrescription.dosageInstructionData.evening ? 1 : 0)
        $scope.calculateUsageFields();
        $scope.calculateDosePerDay();
      } else {
        $scope.changeDosageSummaryData();
      }
    }

    $scope.getDosageInstructions = function (showAll) {
      if (showAll || $scope.dosageInstructionsFiltered.length == 0) {
        return $scope.dosageInstructions;
      } else {
        return $scope.dosageInstructionsFiltered;
      }
    }

    $scope.getDosageForm = function (showAll) {
      if (typeof $scope.medicationPrescription.drug == 'object' && $scope.dosageFormForPharmForm
        && $scope.dosageFormForPharmForm.$resolved && $scope.dosageFormForPharmForm.length > 0 && !showAll) {
        return $scope.dosageFormForPharmForm;
      }
      if (angular.isDefined($scope.dosageFormFiltered)) {
        if (showAll || $scope.dosageFormFiltered.length == 0) {
          return $scope.dosageForm;
        } else {
          return $scope.dosageFormFiltered;
        }
      }
    }


    /* 2019-02-26 Todo check if it works as expected. The bug should be fixed.
      $scope.$watch('medicationPrescription.medicationData.pharmForm', function (newValue, oldValue) {
      Workaround for firefox bug 652
      https://github.com/mgcrea/angular-strap/issues/652
      if (oldValue && oldValue.displayValue && typeof newValue == 'string'
        && oldValue.displayValue == newValue) {
        $scope.medicationPrescription.medicationData.pharmForm = oldValue;
      }
    });/*

    /* 2019-02-26 Todo check if it works as expected. The bug should be fixed.
      $scope.$watch('medicationPrescription.extempPharmForm', function (newValue, oldValue) {
      Workaround for firefox bug 652
      https://github.com/mgcrea/angular-strap/issues/652
      if (oldValue && oldValue.displayValue && typeof newValue == 'string'
        && oldValue.displayValue == newValue) {
        $scope.medicationPrescription.extempPharmForm = oldValue;
      }
    })*/;

    $scope.$watch('medicationPrescription.drug', function (newValue, oldValue) {
        MessageService.clear('drug-region');
        MessageService.clear('em-drug-region');

      //2019-02-21 Causes double calls, commented, fix in erx-typeahead.js
      //Workaround for firefox bug 652
      //https://github.com/mgcrea/angular-strap/issues/652
      /*if (oldValue && oldValue.label && typeof newValue == 'string'
        && oldValue.label == newValue) {
        $scope.medicationPrescription.drug = oldValue;
      }*/

      var checkDrugInteraction = !$scope.isExtemporalForm() || $scope.medicationPrescription.mainIngredient === 'va';

      if ($scope.isMedicineForm() || $scope.isExtemporalForm() || $scope.isNamedMedicineForm()) {


        //Vaistų sąveikos direktyvai
        if (checkDrugInteraction) {
            $scope.$broadcast('drg:cleared');
        }

        if (typeof newValue == 'object' && newValue.ref) {

          //Vaistų sąveikos direktyvai
          if (checkDrugInteraction) {
            if ($scope.isMedicineForm()) {
                $scope.checkMedicineDrugInteraction();
            } else if ($scope.isNamedMedicineForm()) {
                $scope.checkNominalDrugInteraction();
            } else if ($scope.isExtemporalForm()) {
                $scope.checkExtempDrugInteraction(true);
            }
          }

          $scope.dosageInstructionsFiltered = ErxMedicationsApiFactory.getRoutes({ id: newValue.ref.split('/')[1] });
          $scope.dosageInstructionsFiltered.$promise.then(function (res) {
            if (res.length === 1) {
              $scope.medicationPrescription.dosageInstructionData.route = res[0];
            }
          });
          if (newValue.pharmForm) {
            $scope.dosageFormForPharmForm = ErxClassifiersApiFactory.getPharmFormMeasureUnitsByPFCode({ code: newValue.pharmForm.code });
            $scope.dosageFormForPharmForm.$promise.then(function (res) {
              if (res.length === 1) {
                if (!$scope.medicationPrescription.dispenseData.dispenseQuantity) {
                  $scope.medicationPrescription.dispenseData.dispenseQuantity = {};
                };
                if (!$scope.medicationPrescription.dosageInstructionData.dosePerDay) {
                  $scope.medicationPrescription.dosageInstructionData.dosePerDay = {};
                };
                if (!$scope.medicationPrescription.dosageInstructionData.doseQuantity) {
                  $scope.medicationPrescription.dosageInstructionData.doseQuantity = {};
                };
                $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit = res[0];
                $scope.medicationPrescription.dispenseData.dispenseQuantity.unit = res[0];
                $scope.medicationPrescription.dosageInstructionData.dosePerDay.unit = res[0];
              }
            });
          }

          $scope.checkForPrescriptionValidity();
        }
        if (typeof newValue == 'string') {
          $scope.dosageInstructionsFiltered = [];
        }
      }
      if (($scope.isMedicineForm() || $scope.isNamedMedicineForm) && typeof newValue == 'object') {
        if ($scope.medicationPrescription.drug.pharmForm && $filter('filter')($scope.divisiblePharmForms, $scope.medicationPrescription.drug.pharmForm.code).length == 0) {
          if (!$scope.medicationPrescription.dispenseData.dispenseQuantity) {
            $scope.medicationPrescription.dispenseData.dispenseQuantity = {};
          }
          $scope.medicationPrescription.dispenseData.dispenseQuantity.unit = $filter('filter')($scope.dosageForm, { code: 'pak.' })[0];
        }

      }
      if ($scope.isMedicineForm() && typeof newValue == 'object' && newValue.ref) {
        $scope.checkFirstPrescribing();
      }

        $scope.disruptions = [];
        $scope.safetyEducationParams = undefined;
    });

    $scope.shortcutDosageTypeInstructionSelect = function (value) {
      event.preventDefault();
      if (!$scope.medicationPrescription.dosageInstructionData) {
        $scope.medicationPrescription.dosageInstructionData = {};
      }

      $scope.medicationPrescription.dosageInstructionData.route = value;
    };

    $scope.afterHideModalClick = function () {
      $scope.shortcutsDataLoading = true;
      $scope.options.getIntegrationData().getShortcuts().$promise.then(function (res) {
        $scope.shortcutsDataLoading = false;
        $scope.accountSettings = res;
        $scope.dosageTypeShortcutsList = $scope.assignShortcutsData(res, $scope.shortcutDosageTypeKey);
        $erxShortcutCustomization.hide();
      });
    }

    $scope.openShortcutCustomizationModal = function (data) {
      $erxShortcutCustomization.show(
          {data: data, selectedData: $scope.accountSettings},
          $scope.options.getIntegrationData().saveShortcuts,
          $scope.afterHideModalClick
      );
    }

    $scope.shortcutDosageInstructionStaticData = [
      {
        id: 1,
        title: "Ryte",
        group: "frequencyCheckbox",
        field: "morning"
      },
      {
        id: 2,
        title: "Per pietus",
        group: "frequencyCheckbox",
        field: "noon"
      },
      {
        id: 3,
        title: "Vakare",
        group: "frequencyCheckbox",
        field: "evening"
      },
      {
        id: 4,
        title: "Pagal poreikį",
        group: "frequencyCheckbox",
        field: "asNeeded"
      },
      {
        id: 5,
        title: "Prieš valgį",
        group: "timeCheckbox",
        field: "beforeMeal"
      },
      {
        id: 6,
        title: "Valgio metu",
        group: "timeCheckbox",
        field: "duringMeal"
      },
      {
        id: 7,
        title: "Po valgio",
        group: "timeCheckbox",
        field: "afterMeal"
      },
      {
        id: 8,
        title: "Prieš miegą",
        group: "timeCheckbox",
        field: "beforeSleep"
      },
      {
        id: 9,
        title: "Nepriklausomai nuo valgymo",
        group: "timeCheckbox",
        field: "independMeal"
      }
    ];

    $scope.shortcutDosageInstructionSelect = function (value, quantity, group) {
      event.preventDefault();
      switch (group){
        case 'disposableDosage':
          if (!$scope.medicationPrescription.dosageInstructionData.doseQuantity) {
            $scope.medicationPrescription.dosageInstructionData.doseQuantity = {};
          }

          $scope.medicationPrescription.dosageInstructionData.doseQuantity.value = quantity;
          $scope.medicationPrescription.dosageInstructionData.doseQuantity.unit = value;
          break;
        case 'frequencyDosage':
          $scope.medicationPrescription.dosageInstructionData.schedule.frequency = quantity;
          $scope.medicationPrescription.dosageInstructionData.schedule.units = value.code;
          break;
        case 'dayDosage':
          if (!$scope.medicationPrescription.dosageInstructionData.dosePerDay) {
            $scope.medicationPrescription.dosageInstructionData.dosePerDay = {};
          }

          $scope.medicationPrescription.dosageInstructionData.dosePerDay.value = quantity;
          $scope.medicationPrescription.dosageInstructionData.dosePerDay.unit = value;
          break;
        case 'frequencyCheckbox':
        case 'timeCheckbox':
          $scope.medicationPrescription.dosageInstructionData[value] = true;
          $scope.dosageTimeChanged();
          break;
        default:
          break;
      }
      $scope.changeDosageSummaryData();
    };

    $scope.dosageDataForShortcutModal = function (){
      return {
        disposableDosage: $scope.getDosageForm(true),
        frequencyDosage: $scope.frequencyMeasurements,
        dayDosage: $scope.getDosageForm(true)
      }
    };

    $scope.afterHideModalMClick = function () {
      $scope.shortcutsDataLoading = true;
      $scope.options.getIntegrationData().getShortcuts().$promise.then(function (res) {
        $scope.shortcutsDataLoading = false;
        $scope.accountSettings = res;
        $scope.dosageInstructionShortcutsList = $scope.assignShortcutsData(res, $scope.shortcutDosageInstructionKey);
        $erxShortcutTabsDosageM.hide();
      });
    }

    $scope.openDosageShortcutCustomizationModal = function () {
      $erxShortcutTabsDosageM.show(
          {data: $scope.dosageDataForShortcutModal(), selectedData: $scope.accountSettings},
          $scope.options.getIntegrationData().saveShortcuts,
          $scope.afterHideModalMClick
      );
    }

    $scope.shortcutDispenseData = [
      {
        id: 1,
        title: "Tabl.",
        group: "dispense",
        field: {
          code: "tab.",
          displayValue: "Tabletė",
          $$hashKey: "object:735"
        }
      },
      {
        id: 2,
        title: "Pak.",
        group: "dispense",
        field: {
          code: "pak.",
          displayValue: "Pakuotė",
          $$hashKey: "object:739"
        }
      },
      {
        id: 3,
        title: "Švirk.",
        group: "dispense",
        field: {
          code: "švirkš.",
          displayValue: "Švirkštas",
          $$hashKey: "object:733"
        }
      }
    ];

    $scope.shortcutDispenseSelect = function (value) {
      event.preventDefault();
      if (!$scope.medicationPrescription.dispenseData.dispenseQuantity) {
        $scope.medicationPrescription.dispenseData.dispenseQuantity = {};
      }

      $scope.medicationPrescription.dispenseData.dispenseQuantity.unit = value;
    };

    $scope.shortcutPurposeData = [
      {
        id: 1,
        title: "Tolumui",
        field: "Tolumui"
      },
      {
        id: 2,
        title: "Artumui",
        field: "Artumui"
      },
      {
        id: 3,
        title: "Nuolatiniam devėjimui",
        field: "Nuolatiniam devėjimui"
      }
    ];

    $scope.shortcutPurposeSelect = function (value) {
      event.preventDefault();
        $scope.medicationPrescription.dispenseData.lenses.purposeDescription = value;
    };

    $scope.shortcutTreatmentData = [
      {
        id: 1,
        title: "7d.",
        field: 7
      },
      {
        id: 2,
        title: "30d.",
        field: 30
      },
      {
        id: 3,
        title: "60d.",
        field: 60
      },
      {
        id: 4,
        title: "90d.",
        field: 90
      },
      {
        id: 5,
        title: "180d.",
        field: 180
      }
    ];

    $scope.shortcutTreatmentSelect = function (value) {
      event.preventDefault();
      $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration = value;
    };

    $scope.shortcutRecipeData = [
      {
        id: 1,
        title: "10 dienų",
        field: 10
      },
      {
        id: 2,
        title: "30 dienų",
        field: 30
      },
      {
        id: 3,
        title: "60 dienų",
        field: 60
      },
      {
        id: 4,
        title: "90 dienų",
        field: 90
      },
      {
        id: 5,
        title: "180 dienų",
        field: 180
      }
    ];

    $scope.shortcutRecipeSelect = function (value) {
      event.preventDefault();
      $scope.medicationPrescription.additional.validFor = value;
      $scope.medicationPrescription.dispenseData.validUntil = new Date().setDate(new Date($scope.medicationPrescription.dispenseData.validFrom).getDate() + value);
    };

    $scope.showConditionsButton = function () {
      return $scope.medicationSelected && !$scope.medicationPrescription.dispenseData.prescriptionTags.nominalTag;
    }

    $scope.showConditionsModal = function () {
      event.preventDefault();
      $scope.isLoading = true;
        let params: any = {};
        if ($scope.medicationProduct?.atc?.code) {
            Object.assign(params, {atc: $scope.medicationProduct?.atc?.code});
        }
        if ($scope.conditions?.length > 0) {
            Object.assign(params, {tlkCode: $scope.conditions?.[0]?.code?.code});
        }
        if ($scope.medicationPrescription.dispenseData.compensationTag && $scope.medicationPrescription?.dispenseData?.compensation) {
            Object.assign(params, {compensationCode: String($scope.medicationPrescription.dispenseData.compensation?.code)});
        }

        const practitionerQualification = $scope.getActiveQualification();
        if (practitionerQualification) {
            Object.assign(params, {qualificationCode: practitionerQualification.code});
        }

        $scope.options.getIntegrationData().getPrescriptionConditions(params).$promise
          .then(res => {
            $scope.isLoading = false;
            $erxConditionsModal.show({data: res});
          });
    };

    $scope.isVmSelected = function (){
      return $scope.medicationPrescription.mainIngredient === 'vm';
    }

    $scope.isGlensSelected = function () {
        return $scope.medicationPrescription.dispenseData.lenses.opticalDevice === 'glens';
    }

    $scope.lensPrismBaseGroup = [
      {
          id: 1,
          code: "BU"
      },
      {
          id: 2,
          code: "BD"
      },
      {
          id: 3,
          code: "BI"
      },
      {
          id: 4,
          code: "BO"
      },
      {
          id: 5,
          code: "Meridianas"
      }
    ];

    $scope.opticDeviceChanged = function () {
      if($scope.isMPSForm() && $scope.medicationPrescription.dispenseData.compensationTag && $scope.isGlensSelected()){
        $scope.medicationPrescription.dispenseData.prescriptionTag = 'ier';
        $scope.setDefaultDate();
      } else {
        $scope.medicationPrescription.dispenseData.prescriptionTag = 'rni';
        $scope.medicationPrescription.dispenseData.compensationTag = false;
      }
    }

    $scope.setDefaultDate = function () {
      if(!$scope.medicationPrescription.dispenseData.validFrom){
        $scope.medicationPrescription.dispenseData.validFrom = new Date();
      }
    }

    $scope.changeDetailedInfoState = function (){
      event.preventDefault();
      $scope.detailedInfo = !$scope.detailedInfo;
    }

    $scope.clearDosageSummaryData = function () {
      event.stopPropagation();
      event.preventDefault();
      $scope.dosageSummary = "";
      $scope.medicationPrescription.dosageInstructionData = {
        dosePerDay: {},
        schedule: {
          units: 'd'
        },
        morning: false,
        noon: false,
        evening: false,
        asNeeded: false
      };
    }

    $scope.changeDosageSummaryData = function () {
        $scope.dosageSummary = DosageSummaryService.buildDosageSummary(
            $scope.dosageSummary,
            $scope.medicationPrescription.dosageInstructionData,
            $scope.frequencyMeasurements,
            $scope.intakeTimes
        );
    }

    $scope.minMaxValidation = function enforceMinMax(event, field) {
      const el = event.target;
      if (el.value !== "") {
        if (parseFloat(el.value) < parseFloat(el.min)) {
          el.value = el.min;
        }
        if (parseFloat(el.value) > parseFloat(el.max)) {
          el.value = el.max;
        }
        $scope.medicationPrescription.dispenseData.lenses[el.name] = parseFloat(el.value);
      }
    }

        $scope.onMedicationDosageValuesChange = function (formValues) {
            $scope.$apply(function () {
                $scope.medicationPrescription.dosageInstructionData = formValues;
                $scope.changeDosageSummaryData();
            });
        };

    $scope.preventDefault = function () {
      event.preventDefault();
    }

    // Integracinių duomenų užkrovimas
    function loadValues() {
      ErxClassifiersApiFactory.getDivisiblePharmForms().$promise.then(function (res) {
        $scope.divisiblePharmForms = res.list;
      });


      var integrationObject = $scope.options.getIntegrationData();
      $scope.dosageInstructions = integrationObject.getDosageInstructions();
      $scope.dosageInstructionsFiltered = [];


      integrationObject.getCompensationTypeCodes().$promise.then(function (res) {
        angular.forEach(res, function (value) {
          try {
            value.code = parseInt(value.code);
          } catch (e) {

          }
        })
        $scope.compensationTypeCodes = res;
        $scope.setDefaultCompensationTypeCode();
      });

      $scope.setDefaultCompensationTypeCode = function (){
        if($scope.medicationPrescription.dispenseData.compensationTag) {
          $scope.medicationPrescription.dispenseData.compensation = $scope.compensationTypeCodes.filter(type => type.code === 3)[0];
        }
      }

      $scope.medicationDoubleClick = function (drug) {
        $scope.medicationPrescription.drug = drug;

        if($scope.isExtemporalForm()) {
          if ($scope.medicationPrescription.medicationData.extemporaneous.description !== undefined) {
            $scope.medicationPrescription.medicationData.extemporaneous.description += "; " + $scope.typeaheadDrugFormatter(drug);
          } else {
            $scope.medicationPrescription.medicationData.extemporaneous.description = $scope.typeaheadDrugFormatter(drug);
          }
        }
          $scope.getSupplyDisruptions();
          $scope.getSecurityAndEducationalData();

        $scope.medicationSelected = true;
        $scope.detailedInfo = true;
      }

      $scope.intakeTimes = [
        { code: 'beforeMeal', displayValue: gettextCatalog.getString('Prieš valgį')},
        { code: 'duringMeal', displayValue: gettextCatalog.getString('Valgio metu')},
        { code: 'afterMeal', displayValue: gettextCatalog.getString('Po valgio')},
        { code: 'independMeal', displayValue: gettextCatalog.getString('Nepriklausomai nuo valgymo')},
        { code: 'beforeSleep', displayValue: gettextCatalog.getString('Prieš miegą')}
      ];

      //Dozuočių skaičius = ( [Paros dozė] x [Gydymo kurso trukmė] ) : ( [Stiprumas] x [Pakuotės dydis] )
      $scope.calculateInsulinDosageCount = function (calculateTreatment) {
        if($scope.isInsulin && $scope.medicationPrescription.dosageInstructionData !== undefined &&
            $scope.medicationPrescription.dosageInstructionData.dosePerDay !== undefined &&
            $scope.medicationPrescription.dispenseData !== undefined &&
            $scope.medicationPrescription.drug !== undefined &&
            $scope.medicationPrescription.drug.strength !== undefined &&
            $scope.medicationPrescription.dispenseData.dispensePackageName !== undefined
        ) {
          const dosagePerDay = $scope.medicationPrescription.dosageInstructionData.dosePerDay.value;
          const strength = $scope.medicationPrescription.drug.strength;
          const packageName = $scope.medicationPrescription.dispenseData.dispensePackageName;

          const mlMatch = packageName.match(/\b(\d+)\s*ml\b/);
          const nMatch = packageName.match(/N(\d+)/);

          if (mlMatch !== undefined && nMatch !== undefined && dosagePerDay !== undefined && strength !== undefined) {
            const ml = parseInt(mlMatch[1], 10);
            const n = parseInt(nMatch[1], 10);
            const packageAmount = ml * n;

            const treatmentLength = $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration;
            const dosageCount = $scope.medicationPrescription.dispenseData.dispenseQuantity !== undefined ? $scope.medicationPrescription.dispenseData.dispenseQuantity.value : undefined;
            if(treatmentLength !== undefined && dosageCount !== undefined && calculateTreatment !== undefined && calculateTreatment){
              $scope.calculateTreatment(dosagePerDay, treatmentLength, strength, packageAmount);
            } else if(treatmentLength !== undefined && dosageCount !== undefined && calculateTreatment !== undefined && !calculateTreatment) {
              $scope.calculateDosage(dosagePerDay, dosageCount, strength, packageAmount);
            } else if(dosageCount !== undefined){
              $scope.calculateDosage(dosagePerDay, dosageCount, strength, packageAmount);
           } else if(treatmentLength !== undefined){
              $scope.calculateTreatment(dosagePerDay, treatmentLength, strength, packageAmount);
           }
          }
        }
      }

      $scope.calculateTreatment = function (dosagePerDay, treatmentLength, strength, packageAmount){
        const calculatedDosage = (parseInt(dosagePerDay) * parseInt(treatmentLength)) / (parseInt(strength) * packageAmount);
        $scope.medicationPrescription.dispenseData.dispenseQuantity.value = Math.ceil(calculatedDosage);
      }

      $scope.calculateDosage = function (dosagePerDay, dosageCount, strength, packageAmount){
        const calculatedTreatment = parseInt(dosageCount) * parseInt(strength) * packageAmount / parseInt(dosagePerDay);
        $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration = Math.round(calculatedTreatment);
      }

      $scope.massAndVolumeUnits = integrationObject.getMassAndVolumeUnits();

      ErxClassifiersApiFactory.getPrcPharmFormMeasureUnits().$promise.then(function (res) {
        $scope.dosageForm = res;

        ErxSettingsApiFactory.getAll().$promise.then(function (res2) {
          $scope.settings = res2;
          $scope.dosageFormFiltered = $scope.dosageForm.slice(0, parseInt(res2["ERX_DOZ_RODYTI_IRASU"]));
        });
      });

      angular.forEach($scope.dosageForm, function (value, key) {
        delete value.id;
        delete value.name;
      });
      $scope.urgencyTags = integrationObject.getUrgencyTags();
      $scope.prescriptionTags = integrationObject.getPrescriptionTags();
      $scope.frequencyMeasurements = integrationObject.getUnitsOfTime();
      $scope.getPharmaceuticalForms = function (viewValue) {
        if (viewValue && viewValue.length > 0) {
          $scope.pharmLoading = true;
          return integrationObject.getPharmaceuticalForms(viewValue, 10).$promise
              .then(function (res) {
                for (var i = 0; i < res.length; i++) {
                  res[i].displayValue = res[i].name;
                  delete res[i].id;
                }
                $scope.pharmLoading = false;
                return res;
              })
        }
        return [];
      }
    }

    function loadSysParams() {
      ErxParametersApiFactory.getMethodologicalHelpUrl().$promise.then(function (res) {
        $scope.methHelpUrl = res.value;
      });

    }

  };
    prescriptionFormCtrl.$inject = ["$scope", "$filter", "$timeout", "$location", "$erxModalAnalyticalInfoByOrg", "$erxModalAnalyticalInfoByAgeGroup", "$erxModalConfirm", "$erxModalSearch", "$erxModalAnalyticalInfo", "$erxHelpers", "$erxDrugSupplyDisruptionsModal", "$erxModalMedinfo", "$erxModalLoadDraft", "$erxModalSaveDraft", "$erxModalDoseCalculator", "$erxShortcutCustomization", "$erxShortcutTabsDosageM", "$erxConditionsModal", "ErxParametersApiFactory", "ErxPrescriptionsApiFactory", "ErxDrugInteractionApiFactory", "ErxClassifiersApiFactory", "erxPrescriptionsConfig", "ErxSettingsApiFactory", "ErxMedicationsApiFactory", "ErxSubstancesApiFactory", "ErxTemplatesApiFactory", "gettextCatalog", "MessageService", "DosageSummaryService", "ErxTypeaheadUtilsService", "$routeParams", "DpSessionContext2"];
  return {
    restrict: 'E',
    scope: { options: '=' },
    templateUrl: 'modules/erx/prs/prescription_form/prescription_form.html',
    controller: prescriptionFormCtrl
  }
});