<div class="p-2_5 px-4 d-flex gap-3 align-items-center" [tabindex]="isSlatClickable ? 0 : -1" (click)="slatClick($event)" (keypress)="slatClick($event)">
    <div class="flex-grow-1">
        <ng-content></ng-content>
    </div>
    <div class="btn-group">
        <button *ngIf="(showEdit || edit) && !editAnchorHref" title="{{editTitle ?? ('edit' | translate)}}" type="button" class="btn btn-secondary btn-sm" (click)="editClick($event)">
            <i class="fa-solid fa-pencil"></i>
            <span class="visually-hidden">{{editTitle}}</span>
        </button>
        <a *ngIf="(showEdit || edit) && !!editAnchorHref" title="{{editTitle}}" href="{{editAnchorHref}}" class="btn btn-secondary btn-sm">
            <i class="fa-solid fa-pencil"></i>
            <span class="visually-hidden">{{editTitle}}</span>
        </a>

        <button *ngIf="(showView || view) && !viewAnchorHref" title="{{viewTitle}}" type="button" class="btn btn-secondary btn-sm" (click)="viewClick($event)">
            <i class="fa-solid fa-eye"></i>
            <span class="visually-hidden">{{viewTitle}}</span>
        </button>
        <a *ngIf="(showView || view) && !!viewAnchorHref" title="{{viewTitle}}" href="{{viewAnchorHref}}" class="btn btn-secondary btn-sm">
            <i class="fa-solid fa-eye"></i>
            <span class="visually-hidden">{{viewTitle}}</span>
        </a>

        <button *ngIf="showDelete || delete" title="{{deleteTitle}}" type="button" class="btn btn-secondary btn-sm" (click)="deleteClick($event)">
            <i class="fa-solid fa-trash"></i>
            <span class="visually-hidden">{{deleteTitle}}</span>
        </button>

        <button *ngIf="(showCustom || custom) && !customAnchorHref" title="{{customTitle}}" type="button" class="btn btn-secondary btn-sm" (click)="customClick($event)">
            <i class="fa-solid fa-eye"></i>
            <span class="visually-hidden">{{customTitle}}</span>
        </button>
        <a *ngIf="(showCustom || custom) && !!customAnchorHref" title="{{customTitle}}" href="{{customAnchorHref}}" class="btn btn-secondary btn-sm">
            <i class="fa-solid" [ngClass]="customIcon"></i>
            <span class="visually-hidden">{{customTitle}}</span>
        </a>
        <ng-content select="[buttons-group]"></ng-content>
    </div>
</div>
