<nhr-record-widget [closable]="false" [closed]="false" [title]="title | translate" id="ultrasountTemplate">
    <div class="row mb-2">
        <div class="col-sm-12 col-md-6">
            <div class="row mb-2 req">
                <app-date-picker
                    class="col"
                    inputId="researchDate"
                    labelText="Tyrimo data"
                    labelClass="fw-bold"
                    [maxDate]="currentDate"
                    [showErrors]="true"
                    [fControl]="formGroup?.get('researchDate')"
                ></app-date-picker>
            </div>
            <div *ngIf="title === UltrasoundStages.Third" class="row">
                <fieldset class="col">
                    <legend class="fw-bold mb-1">{{ "Tyrimo tikslas" | translate }}</legend>
                    <app-checkbox
                        *ngFor="let purpose of researchPurposeArray"
                        class="row ms-0"
                        [checkboxValue]="purpose"
                        [labelText]="purpose"
                        [required]="!formGroup.get('researchPurpose')?.valid"
                        [checkboxTitle]="purpose"
                        [checkboxChecked]="formGroup.get('researchPurpose').value === purpose"
                        [addUniqId]="true"
                        (change)="setCheckbox(formGroup.get('researchPurpose'), purpose)"
                    ></app-checkbox>
                    <div class="row">
                        <app-input
                            *ngIf="formGroup.get('researchPurpose').value === ResearchPurpose.Other"
                            id="researchPurposeOther"
                            type="text"
                            [fControl]="formGroup?.get('researchPurposeOther')"
                        />
                    </div>
                </fieldset>
            </div>
            <div class="row req">
                <fieldset class="col">
                    <legend class="fw-bold mb-1 col-form-label">{{ "Tyrimo sąlygos" | translate }}</legend>
                    <app-checkbox
                        *ngFor="let condition of researchConditionsArray"
                        class="row ms-0"
                        [checkboxValue]="condition"
                        [labelText]="condition"
                        [required]="!formGroup.get('researchConditions')?.valid"
                        [checkboxTitle]="condition"
                        [checkboxChecked]="formGroup.get('researchConditions').value === condition"
                        [addUniqId]="true"
                        (change)="setCheckbox(formGroup.get('researchConditions'), condition)"
                    ></app-checkbox>
                    <app-form-control-validation [fControl]="formGroup.get('researchConditions')" />
                    <div class="row">
                        <app-input
                            *ngIf="formGroup.get('researchConditions').value === ResearchConditions.Other"
                            id="researchConditionsOther"
                            type="text"
                            [fControl]="formGroup?.get('researchConditionsOther')"
                        />
                    </div>
                </fieldset>
            </div>
        </div>

        <div class="col-sm-12 col-md-6">
            <div class="row mb-2">
                <div class="col-sm-12 col-md-8 fw-bold">
                    {{ "Paskutinių normalių mėnesinių data" | translate }}
                </div>
                <div class="col-sm-12 col-md-4">
                    {{ pregnancyInfo.lastMenstruationPeriod | date : "yyyy-MM-dd" }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12 col-md-8 fw-bold">
                    {{ "Nėštumo savaitė pagal mėnesines" | translate }}
                </div>
                <div class="col-sm-12 col-md-4">{{ getPregnancyWeekByWomanPeriod }} {{ "sav." | translate }}</div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12 col-md-8 fw-bold">
                    {{ "Nėštumo diena pagal mėnesines" | translate }}
                </div>
                <div class="col-sm-12 col-md-4">{{ getPregnancyDayByWomanPeriod }} {{ "d." | translate }}</div>
            </div>
            <div *ngIf="title !== UltrasoundStages.First">
                <div class="row mb-2">
                    <div class="col-sm-12 col-md-8 fw-bold">
                        {{ "Nėštumo savaitė pagal ankstyvą ultragarsinį tyrimą" | translate }}
                    </div>
                    <div class="col-sm-12 col-md-4">{{ ngnCaptionData.pregnancyWeek }} {{ "sav." | translate }}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12 col-md-8 fw-bold">
                        {{ "Nėštumo diena pagal ankstyvą ultragarsinį tyrimą" | translate }}
                    </div>
                    <div class="col-sm-12 col-md-4">{{ ngnCaptionData.pregnancyDay }} {{ "d." | translate }}</div>
                </div>
            </div>
            <div class="row mb-2 req">
                <fieldset class="col">
                    <legend class="fw-bold mb-1 col-form-label">{{ "Vaisių skaičius" | translate }}</legend>
                    <app-input
                        id="childCount"
                        class="col-sm-12 col-md-6 d-inline-flex"
                        type="number"
                        min="1"
                        max="20"
                        [showErrors]="true"
                        [fControl]="formGroup.get('childCount')"
                    />
                </fieldset>
            </div>
        </div>
    </div>

    <div class="card w-100 mb-2">
        <div class="card-header">{{ "Gimda" | translate }}</div>
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-sm-12 col-md-6">
                    {{ "Gimdos kaklelio ilgis, mm" | translate }}
                </div>
                <app-input
                    id="cervicalLength"
                    class="col-sm-12 col-md-6 d-inline-flex"
                    type="number"
                    min="1"
                    max="20"
                    [fControl]="formGroup?.get('cervicalLength')"
                />
                <div class="fs-6 fst-italic">
                    {{ "(matuojamas tik esant persileidimo ar priešlaikinio gimdymo rizikai)" | translate }}
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-6 col-md-3 col-lg-3">
                    {{ "Gimdos priedų išvaizda" | translate }}
                </div>
                <app-checkbox
                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                    class="col-sm-3 col-md-3 col-lg-3"
                    [checkboxValue]="appendage"
                    [labelText]="appendage"
                    [required]="!formGroup.get('appearanceUterineAppendages')?.valid"
                    [checkboxTitle]="appendage"
                    [addUniqId]="true"
                    [checkboxChecked]="formGroup.get('appearanceUterineAppendages')?.value === appendage"
                    (change)="setCheckbox(formGroup.get('appearanceUterineAppendages'), appendage)"
                ></app-checkbox>
            </div>
        </div>
    </div>

    <div class="card w-100">
        <div class="card-header">{{ "Kraujotakos tyrimas" | translate }}</div>
        <div class="card-body">
            <div class="row">
                <fieldset class="col">
                    <legend class="fw-bold mb-1">{{ "Gimdos arterijos" | translate }}</legend>
                    <div class="row p-3">
                        <div class="col">
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-12 mb-2">
                                    {{ "Dešinioji" | translate }}
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="row mb-2">
                                        <div class="col-sm-2 col-md-2 col-lg-2 flex-md-row">
                                            {{ "PI" | translate }}
                                        </div>
                                        <app-input
                                            class="col-sm-10 col-md-10"
                                            id="rightPI"
                                            type="number"
                                            min="1"
                                            max="20"
                                            [fControl]="formGroup?.get('rightPI')"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="row mb-2">
                                        <div class="col-sm-2 col-md-2 col-lg-2 flex-md-row">
                                            {{ "RI" | translate }}
                                        </div>
                                        <app-input
                                            class="col-sm-10 col-md-10"
                                            id="rightRI"
                                            type="number"
                                            min="1"
                                            max="20"
                                            [fControl]="formGroup?.get('rightRI')"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="row mb-2">
                                        <div class="col-sm-4 col-md-6">
                                            {{ "Protodiastolinė banga" | translate }}
                                        </div>
                                        <app-select
                                            id="rightProtodiastolicWave"
                                            class="col-sm-8 col-md-6"
                                            [values]="['+', '-']"
                                            [fControl]="formGroup?.get('rightProtodiastolicWave')"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 mb-2">
                                    {{ "Kairioji" | translate }}
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="row mb-2">
                                        <div class="col-sm-2 col-md-2 col-lg-2 flex-md-row">
                                            {{ "PI" | translate }}
                                        </div>
                                        <app-input
                                            class="col-sm-10 col-md-10"
                                            id="leftPI"
                                            type="number"
                                            min="1"
                                            max="20"
                                            [fControl]="formGroup?.get('leftPI')"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="row mb-2">
                                        <div class="col-sm-2 col-md-2 col-lg-2 flex-md-row">
                                            {{ "RI" | translate }}
                                        </div>
                                        <app-input
                                            class="col-sm-10 col-md-10"
                                            id="leftRI"
                                            type="number"
                                            min="1"
                                            max="20"
                                            [fControl]="formGroup?.get('leftRI')"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="row mb-2">
                                        <div class="col-sm-4 col-md-6">
                                            {{ "Protodiastolinė banga" | translate }}
                                        </div>
                                        <app-select
                                            id="leftProtodiastolicWave"
                                            class="col-sm-8 col-md-6"
                                            [values]="['+', '-']"
                                            [fControl]="formGroup?.get('leftProtodiastolicWave')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</nhr-record-widget>

<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'Vaisiaus duomenys' | translate"
    id="firstStageFetusInfo"
>
    <div *ngIf="getChildsFormArray.length" class="mb-2" id="nav-tab">
        <div class="row">
            <div *ngFor="let child of getChildsFormArray.value; let i = index" class="col-sm-1 col-md-1 col-lg-1">
                <button
                    class="nav-link"
                    id="{{ 'nav-home-tab'[i] }}"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    [ngClass]="{ 'text-primary border-bottom border-primary border-2': selectedTabIndex === i }"
                    (click)="setTab(i)"
                >
                    {{ child.letter }} {{ "vaisius" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="getFetusDataFormArray.length" class="tab-content mb-2" id="nav-tabContent">
        <div class="card w-100">
            <div class="card-header">{{ "Ultragarsinis vaisiaus anatomijos tyrimas" | translate }}</div>
            <div class="card-body">
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Bendrieji duomenys apie vaisių" | translate }}</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                {{ "Chorioniškumas" | translate }}
                            </div>
                            <div *ngFor="let item of chorionicity" class="col-sm-6 col-md-4">
                                <app-radio-button
                                    radioName="chorionicity"
                                    [labelText]="item.name"
                                    [radioTitle]="item.name"
                                    [radioId]="item.name"
                                    [radioValue]="item.value"
                                    [fControl]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.chorionicity')
                                    "
                                ></app-radio-button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                {{ "Amniotiškumas" | translate }}
                            </div>
                            <div *ngFor="let item of chorionicity" class="col-sm-6 col-md-4">
                                <app-radio-button
                                    radioName="amnioticity"
                                    [labelText]="item.name"
                                    [radioTitle]="item.name"
                                    [radioId]="item.name"
                                    [radioValue]="item.value"
                                    [fControl]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.amnioticity')
                                    "
                                ></app-radio-button>
                            </div>
                        </div>
                        <div *ngIf="title === UltrasoundStages.First" class="row mb-2 req">
                            <div class="col-sm-12 col-md-3 col-form-label">
                                {{ "Viršugalvio sėdmenų matmuo" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="headButtocksMeasurement.mm"
                                type="number"
                                units="mm, atitinka"
                                [showErrors]="true"
                                [fControl]="
                                    getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('generalInfo.headButtocksMeasurement.mm')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="headButtocksMeasurement.week"
                                type="number"
                                units="sav"
                                [showErrors]="true"
                                [fControl]="
                                    getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('generalInfo.headButtocksMeasurement.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="headButtocksMeasurement.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [showErrors]="true"
                                [fControl]="
                                    getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('generalInfo.headButtocksMeasurement.day')
                                "
                            />
                        </div>
                        <div *ngIf="title === UltrasoundStages.First" class="row mb-2 req">
                            <div class="col-sm-12 col-md-3 col-form-label">
                                {{ "Sprando vaiskuma" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="neckFertility.mm"
                                type="number"
                                units="mm"
                                [showErrors]="true"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.neckFertility.mm')
                                "
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.First, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.First, false)"
                            >
                                {{ "Biparietalinis matmuo" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="biparietalDimension.mm"
                                type="number"
                                units="mm, atitinka"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.biparietalDimension.mm')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="biparietalDimension.week"
                                type="number"
                                units="sav"
                                [fControl]="
                                    getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('generalInfo.biparietalDimension.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="biparietalDimension.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [fControl]="
                                    getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('generalInfo.biparietalDimension.day')
                                "
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.First, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.First, false)"
                            >
                                {{ "Galvos apimtis" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="headCircumference.mm"
                                type="number"
                                units="mm, atitinka"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.headCircumference.mm')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="headCircumference.week"
                                type="number"
                                units="sav"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.headCircumference.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="headCircumference.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.headCircumference.day')
                                "
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.First, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.First, false)"
                            >
                                {{ "Pilvo apimtis" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="abdominalGirth.mm"
                                type="number"
                                units="mm, atitinka"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.abdominalGirth.mm')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="abdominalGirth.week"
                                type="number"
                                units="sav"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.abdominalGirth.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="abdominalGirth.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.abdominalGirth.day')
                                "
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.First, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.First, false)"
                            >
                                {{ "Šlaunikaulio ilgis" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="femurLength.mm"
                                type="number"
                                units="mm, atitinka"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.femurLength.mm')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="femurLength.week"
                                type="number"
                                units="sav"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.femurLength.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="femurLength.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.femurLength.day')
                                "
                            />
                        </div>
                        <div *ngIf="title !== UltrasoundStages.First" class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Žastikaulio ilgis" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="humerusLength.mm"
                                type="number"
                                units="mm, atitinka"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.humerusLength.mm')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="humerusLength.week"
                                type="number"
                                units="sav"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.humerusLength.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="humerusLength.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.humerusLength.day')
                                "
                            />
                        </div>
                        <div
                            *ngIf="title !== UltrasoundStages.First"
                            class="row mb-2"
                            [ngClass]="setStartStyle(true, true)"
                        >
                            <div class="col-sm-12 col-md-3" [ngClass]="setStartStyle(true, false)">
                                {{ "Vaisiaus svoris" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="fetusWeight.weight"
                                type="number"
                                units="g, atitinka"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetusWeight.weight')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="fetusWeight.week"
                                type="number"
                                units="sav"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetusWeight.week')
                                "
                            />
                            <app-input
                                class="col-sm-4 col-md-3"
                                id="fetusWeight.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetusWeight.day')
                                "
                            />
                        </div>
                        123123123
                        <div *ngIf="title === UltrasoundStages.Third">
                            <fieldset class="row mb-2 req">
                                <legend class="col-sm-3 col-md-3 fw-light col-form-label">
                                    {{ "Vaisiaus padėtis" | translate }}
                                </legend>
                                <div class="col-auto flex-grow">
                                    <app-radio-button
                                        *ngFor="let position of fetalPositionArray"
                                        class="col-sm-2 col-md-2 flex-grow"
                                        radioName="fetalPosition"
                                        [labelText]="position"
                                        [radioTitle]="position"
                                        [radioId]="position"
                                        [radioValue]="position"
                                        [fControl]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetalPosition')
                                    "
                                        [radioChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetalPosition')
                                            ?.value === position
                                    "
                                    />
                                    <app-form-control-validation class="d-block w-full"
                                        [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetalPosition')" />
                                </div>
                            </fieldset>
                            <fieldset class="row mb-2 req">
                                <legend class="col-sm-3 col-md-3 fw-light col-form-label">
                                    {{ "Vaisiaus pirmeiga" | translate }}
                                </legend>
                                <div class="col-auto flex-grow">
                                    <app-radio-button
                                        *ngFor="let position of fetalForerunnerArray"
                                        class="col-sm-2 col-md-2 flex-grow"
                                        radioName="fetalForerunner"
                                        [labelText]="position"
                                        [radioTitle]="position"
                                        [radioId]="position"
                                        [radioValue]="position"
                                        [fControl]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetalForerunner')
                                    "
                                        [radioChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetalForerunner')
                                            ?.value === position
                                    "
                                    />
                                    <app-form-control-validation class="d-block w-full"
                                                                 [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('generalInfo.fetalForerunner')" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Galva" | translate }}</div>
                    <div class="card-body">
                        <div *ngIf="title === UltrasoundStages.First">
                            <div class="row mb-2 req">
                                <div class="col-sm-12 col-md-3 col-form-label">
                                    {{ "Forma" | translate }}
                                </div>
                                <div class="col-auto flex-grow">
                                    <app-checkbox
                                        *ngFor="let appendage of appearanceUterineAppendagesArray"
                                        class="col-sm-4 col-md-3"
                                        [checkboxValue]="appendage"
                                        [labelText]="appendage"
                                        [required]="!getFetusDataFormArray.at(selectedTabIndex).get('head.form')?.valid"
                                        [checkboxTitle]="appendage"
                                        [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.form')?.value === appendage
                                    "
                                        (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.form'),
                                            appendage
                                        )
                                    "
                                    ></app-checkbox>
                                    <app-form-control-validation class="d-block w-full"
                                                                 [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.form')" />
                                </div>
                            </div>
                            <div class="row mb-2 req">
                                <div class="col-sm-12 col-md-3 col-form-label">
                                    {{ "Kaukolės kaulėjimas" | translate }}
                                </div>
                                <div class="col-auto flex-grow">
                                    <app-checkbox
                                        *ngFor="let appendage of appearanceUterineAppendagesArray"
                                        class="col-sm-4 col-md-3"
                                        [checkboxValue]="appendage"
                                        [labelText]="appendage"
                                        [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.skullOssification')?.valid
                                    "
                                        [checkboxTitle]="appendage"
                                        [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.skullOssification')
                                            ?.value === appendage
                                    "
                                        (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.skullOssification'),
                                            appendage
                                        )
                                    "
                                    ></app-checkbox>
                                    <app-form-control-validation class="d-block w-full"
                                                                 [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.skullOssification')" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-3">
                                    {{ "Skliauto pjautuvas" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.sickleVault')?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.sickleVault')?.value ===
                                        appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.sickleVault'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                        </div>

                        <div class="row" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Kraujagysliniai rezginiai" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.vascularPlexuses')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.vascularPlexuses')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.vascularPlexuses'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>

                        <div *ngIf="title !== UltrasoundStages.First">
                            <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                <div
                                    class="col-sm-12 col-md-3"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                >
                                    {{ "Kaukolės forma, kontūrai" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="!getFetusDataFormArray.at(selectedTabIndex).get('head.form')?.valid"
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.form')?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.form'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                            <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                <div
                                    class="col-sm-12 col-md-3"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                >
                                    {{ "Skaidrioji pertvara" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.transparentPartition')
                                            ?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.transparentPartition')
                                            ?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.transparentPartition'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                            <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                <div
                                    class="col-sm-12 col-md-3"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                >
                                    {{ "Vidurinė linija" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.middleLine')?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.middleLine')?.value ===
                                        appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.middleLine'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                            <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                <div
                                    class="col-sm-12 col-md-3"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                >
                                    {{ "Smegenų branduoliai" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.brainNuclei')?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.brainNuclei')?.value ===
                                        appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.brainNuclei'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                            <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                <div
                                    class="col-sm-12 col-md-3"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                >
                                    {{ "Smegenėlės" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-3 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.cerebellum.tests')?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.cerebellum.tests')
                                            ?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.cerebellum.tests'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                                <app-input
                                    class="col-sm-4 col-md-4"
                                    type="number"
                                    units="mm, atitinka"
                                    [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.cerebellum.mm')"
                                />
                                <app-input
                                    class="col-sm-4 col-md-4"
                                    type="number"
                                    units="sav"
                                    [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.cerebellum.week')"
                                />
                                <app-input
                                    class="col-sm-4 col-md-4"
                                    type="number"
                                    units="d"
                                    [max]="6"
                                    [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.cerebellum.day')"
                                />
                            </div>
                            <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                <div
                                    class="col-sm-12 col-md-3"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)"
                                >
                                    {{ "Didžioji cisterna" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-3 col-md-2"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('head.greatCistern.tests')
                                            ?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.greatCistern.tests')
                                            ?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('head.greatCistern.tests'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                                <app-input
                                    class="col-sm-3 col-md-3"
                                    type="number"
                                    units="mm"
                                    [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.greatCistern.mm')"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="title !== UltrasoundStages.First" class="card w-100 mb-2">
                    <div class="card-header">{{ "Šoniniai skilveliai" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Dešinysis priekinis ragas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-3 col-md-2"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.rightAnteriorHorn.tests')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.rightAnteriorHorn.tests')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.rightAnteriorHorn.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.rightAnteriorHorn.mm')"
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Kairysis priekinis ragas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-3 col-md-2"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.leftAnteriorHorn.tests')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.leftAnteriorHorn.tests')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.leftAnteriorHorn.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.leftAnteriorHorn.mm')"
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Dešinysis užpakalinis ragas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-3 col-md-2"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.rightPosteriorHorn.tests')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.rightPosteriorHorn.tests')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.rightPosteriorHorn.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.rightPosteriorHorn.mm')
                                "
                            />
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Kairysis užpakalinis ragas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-3 col-md-2"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.leftPosteriorHorn.tests')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.leftPosteriorHorn.tests')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.leftPosteriorHorn.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.leftPosteriorHorn.mm')"
                            />
                        </div>
                    </div>
                </div>

                <div *ngIf="title !== UltrasoundStages.First" class="card w-100 mb-2">
                    <div class="card-header">{{ "Kraujagysliniai rezginiai" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Didžioji jungtis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.greatConnection')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.greatConnection')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.greatConnection'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Sprando raukšlė" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-3 col-md-2"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('head.neckCrease.tests')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('head.neckCrease.tests')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('head.neckCrease.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('head.neckCrease.mm')"
                            />
                        </div>
                    </div>
                </div>

                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Veidas" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Profilis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('face.profile')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('face.profile')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('face.profile'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div *ngIf="title !== UltrasoundStages.First" class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Viršutinė lūpa" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('face.upperLip')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('face.upperLip')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('face.upperLip'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Akiduobės" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('face.eyeSockets')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('face.eyeSockets')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('face.eyeSockets'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div *ngIf="title !== UltrasoundStages.First">
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-3">
                                    {{ "Nosis, šnervės" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="!getFetusDataFormArray.at(selectedTabIndex).get('face.nostrils')?.valid"
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('face.nostrils')?.value ===
                                        appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('face.nostrils'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-3">
                                    {{ "Smakras" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="!getFetusDataFormArray.at(selectedTabIndex).get('face.chin')?.valid"
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('face.chin')?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('face.chin'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Nosies kaulas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                [class]="title !== UltrasoundStages.First ? 'col-sm-3 col-md-2' : 'col-sm-4 col-md-3'"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('face.nasalBone.tests')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('face.nasalBone.tests')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('face.nasalBone.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                *ngIf="title !== UltrasoundStages.First"
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('face.nasalBone.mm')"
                            />
                        </div>
                        <div *ngIf="title !== UltrasoundStages.First" class="row">
                            <div class="col-sm-12 col-md-3">
                                {{ "Prienosinių audinių storis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-3 col-md-2"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('face.peritonealTissueThickness.tests')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray
                                        .at(selectedTabIndex)
                                        .get('face.peritonealTissueThickness.tests')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('face.peritonealTissueThickness.tests'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                            <app-input
                                class="col-sm-3 col-md-3"
                                type="number"
                                units="mm"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('face.peritonealTissueThickness.mm')
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Kaklas, stuburas" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Kaklas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('neckAndSpine.neck')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('neckAndSpine.neck')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('neckAndSpine.neck'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Stuburas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('neckAndSpine.spine')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('neckAndSpine.spine')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('neckAndSpine.spine'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Krūtinės ląsta" | translate }}</div>
                    <div class="card-body">
                        <div *ngIf="title !== UltrasoundStages.First" class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Krūtinės ląstos forma" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('chest.form')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('chest.form')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(getFetusDataFormArray.at(selectedTabIndex).get('chest.form'), appendage)
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Plaučiai" }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('chest.lungs')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('chest.lungs')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('chest.lungs'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Diafragma" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('chest.diaphragm')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('chest.diaphragm')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('chest.diaphragm'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Širdis" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Širdies veikla" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('heart.activity')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('heart.activity')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('heart.activity'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Dydis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('heart.size')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('heart.size')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(getFetusDataFormArray.at(selectedTabIndex).get('heart.size'), appendage)
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Širdies ašis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('heart.axis')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('heart.axis')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(getFetusDataFormArray.at(selectedTabIndex).get('heart.axis'), appendage)
                                "
                            ></app-checkbox>
                        </div>
                        <div *ngIf="title !== UltrasoundStages.First">
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-3">
                                    {{ "Aortos išvarymo traktas" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray.at(selectedTabIndex).get('heart.aorticOutflowTract')
                                            ?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray.at(selectedTabIndex).get('heart.aorticOutflowTract')
                                            ?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray.at(selectedTabIndex).get('heart.aorticOutflowTract'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-3">
                                    {{ "Plautinio kamieno išvarymo traktas" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let appendage of appearanceUterineAppendagesArray"
                                    class="col-sm-4 col-md-3"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                        !getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('heart.pulmonaryTrunkExcretoryTract')?.valid
                                    "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('heart.pulmonaryTrunkExcretoryTract')?.value === appendage
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('heart.pulmonaryTrunkExcretoryTract'),
                                            appendage
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                        </div>
                        <div class="row" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Keturių kamerų vaizdas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('heart.fourCameraView')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('heart.fourCameraView')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('heart.fourCameraView'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Pilvas" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Skrandis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('belly.stomach')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('belly.stomach')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('belly.stomach'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{ "Žarnynas" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('belly.intestine')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('belly.intestine')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('belly.intestine'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Šlapimo pūslė" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('belly.bladder')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('belly.bladder')?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('belly.bladder'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Pilvo siena / virkštelės tvirtinimosi vieta" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [checkboxTitle]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('belly.umbilicalCordAttaches')
                                        ?.valid
                                "
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('belly.umbilicalCordAttaches')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('belly.umbilicalCordAttaches'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Inkstai" | translate }}</div>
                    <div class="card-body">
                        <div class="row">
                            <div *ngIf="title === UltrasoundStages.First" class="col">
                                <div class="row">
                                    <app-checkbox
                                        *ngFor="let appendage of appearanceUterineAppendagesArray"
                                        class="col-sm-4 col-md-4"
                                        [checkboxValue]="appendage"
                                        [labelText]="appendage"
                                        [required]="
                                            !getFetusDataFormArray.at(selectedTabIndex).get('belly.kidneys')?.valid
                                        "
                                        [checkboxTitle]="appendage"
                                        [checkboxChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('belly.kidneys')?.value ===
                                            appendage
                                        "
                                        (change)="
                                            setCheckbox(
                                                getFetusDataFormArray.at(selectedTabIndex).get('belly.kidneys'),
                                                appendage
                                            )
                                        "
                                    ></app-checkbox>
                                </div>
                            </div>

                            <div *ngIf="title !== UltrasoundStages.First">
                                <div
                                    class="row mb-2"
                                    [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)"
                                >
                                    <div
                                        class="col-sm-12 col-md-3"
                                        [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                    >
                                        {{ "Dešinysis inkstas (geldelė)" | translate }}
                                    </div>
                                    <app-checkbox
                                        *ngFor="let appendage of appearanceUterineAppendagesArray"
                                        class="col-sm-3 col-md-2"
                                        [checkboxValue]="appendage"
                                        [labelText]="appendage"
                                        [required]="
                                            !getFetusDataFormArray.at(selectedTabIndex).get('belly.rightKidney.tests')
                                                ?.valid
                                        "
                                        [checkboxTitle]="appendage"
                                        [checkboxChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('belly.rightKidney.tests')
                                                ?.value === appendage
                                        "
                                        (change)="
                                            setCheckbox(
                                                getFetusDataFormArray
                                                    .at(selectedTabIndex)
                                                    .get('belly.rightKidney.tests'),
                                                appendage
                                            )
                                        "
                                    ></app-checkbox>
                                    <app-input
                                        class="col-sm-3 col-md-3"
                                        type="number"
                                        units="mm"
                                        [fControl]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('belly.rightKidney.mm')
                                        "
                                    />
                                </div>
                                <div class="row" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                                    <div
                                        class="col-sm-12 col-md-3"
                                        [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                                    >
                                        {{ "Kairysis inkstas (geldelė)" | translate }}
                                    </div>
                                    <app-checkbox
                                        *ngFor="let appendage of appearanceUterineAppendagesArray"
                                        class="col-sm-3 col-md-2"
                                        [checkboxValue]="appendage"
                                        [labelText]="appendage"
                                        [required]="
                                            !getFetusDataFormArray.at(selectedTabIndex).get('belly.leftKidney.tests')
                                                ?.valid
                                        "
                                        [checkboxTitle]="appendage"
                                        [checkboxChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('belly.leftKidney.tests')
                                                ?.value === appendage
                                        "
                                        (change)="
                                            setCheckbox(
                                                getFetusDataFormArray
                                                    .at(selectedTabIndex)
                                                    .get('belly.leftKidney.tests'),
                                                appendage
                                            )
                                        "
                                    ></app-checkbox>
                                    <app-input
                                        class="col-sm-3 col-md-3"
                                        type="number"
                                        units="mm"
                                        [fControl]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('belly.leftKidney.mm')
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Galūnės" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Dešinioji ranka su plaštaka" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('limbs.rightHand')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('limbs.rightHand')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('limbs.rightHand'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Kairioji ranka su plaštaka" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('limbs.leftHand')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('limbs.leftHand')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('limbs.leftHand'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Dešinioji koja su pėda" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('limbs.rightLegFoot')?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('limbs.rightLegFoot')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('limbs.rightLegFoot'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row" [ngClass]="setStartStyle(title !== UltrasoundStages.Third, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title !== UltrasoundStages.Third, false)"
                            >
                                {{ "Kairioji koja su pėda" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('limbs.leftLegFoot')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('limbs.leftLegFoot')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('limbs.leftLegFoot'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Virkštelė" | translate }}</div>
                    <div class="card-body">
                        <div class="row" [ngClass]="setStartStyle(title === UltrasoundStages.Second, true)">
                            <div
                                class="col-sm-12 col-md-3"
                                [ngClass]="setStartStyle(title === UltrasoundStages.Second, false)"
                            >
                                {{
                                    title === UltrasoundStages.First
                                        ? ("Virkštelės kraujagyslės" | translate)
                                        : ("Trys kraujagyslės" | translate)
                                }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('umbilicalCord.threeBloodVessels')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('umbilicalCord.threeBloodVessels')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('umbilicalCord.threeBloodVessels'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div *ngIf="title !== UltrasoundStages.First" class="card w-100 mb-2">
                    <div class="card-header">{{ "Lyties organai" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Vyriškieji" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('sexOrgans.mens')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('sexOrgans.mens')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('sexOrgans.mens'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-3">
                                {{ "Moteriškieji" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="!getFetusDataFormArray.at(selectedTabIndex).get('sexOrgans.womens')?.valid"
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('sexOrgans.womens')?.value ===
                                    appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('sexOrgans.womens'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div *ngIf="title === UltrasoundStages.First" class="card w-100 mb-2">
                    <div class="card-header">{{ "Kraujotaka" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Pro triburį vožtuvą" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('bloodStream.throughThreeWayValve')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('bloodStream.throughThreeWayValve')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('bloodStream.throughThreeWayValve'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-3">
                                {{ "Pro veninį lataką" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('bloodStream.throughDuctusVenosus')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('bloodStream.throughDuctusVenosus')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('bloodStream.throughDuctusVenosus'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-3">
                                {{ "Širdies susitr. dažnis" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let appendage of appearanceUterineAppendagesArray"
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="appendage"
                                [labelText]="appendage"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('bloodStream.heartAttackFrequency')
                                        ?.valid
                                "
                                [checkboxTitle]="appendage"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('bloodStream.heartAttackFrequency')
                                        ?.value === appendage
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('bloodStream.heartAttackFrequency'),
                                        appendage
                                    )
                                "
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Placenta" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2" [ngClass]="setStartStyle(true, true)">
                            <div class="col-sm-6 col-md-3" [ngClass]="setStartStyle(true, false)">
                                {{ "Placentos lokalizacija gimdoje" | translate }}
                            </div>
                            <app-multiselect-dropdown
                                placeholder="--"
                                [dropdownDataList]="placentaLocalizationArray"
                                idField="id"
                                textField="name"
                                elementId="placenta.localization"
                                [itemsShowLimit]="99"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('placenta.localization')"
                            />
                        </div>
                        <div *ngIf="title !== UltrasoundStages.First">
                            <div class="row mb-2" [ngClass]="setStartStyle(true, true)">
                                <div class="col-sm-6 col-md-3" [ngClass]="setStartStyle(true, false)">
                                    {{ "Placenta" | translate }}
                                </div>
                                <app-select
                                    id="placenta.placenta"
                                    class="col-sm-6 col-md-9"
                                    [placeholder]="null"
                                    [values]="placentaArray"
                                    [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('placenta.placenta')"
                                />
                            </div>
                            <div
                                *ngIf="
                                    getFetusDataFormArray.at(selectedTabIndex).get('placenta.placenta').value ===
                                    Placenta.Other
                                "
                                class="row mb-2"
                            >
                                <div class="col-sm-6 col-md-3">
                                    {{ "Placenta nuo vidinių žiočių" | translate }}
                                </div>
                                <app-input
                                    class="col-sm-6 col-md-9"
                                    id="placentaFromInternalMouth"
                                    type="number"
                                    units="mm"
                                    [fControl]="
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('placenta.placentaFromInternalMouth')
                                    "
                                />
                            </div>
                            <div class="row mb-2">
                                <fieldset class="row" [ngClass]="setStartStyle(true, true)">
                                    <legend class="col-sm-3 col-md-3 fw-light" [ngClass]="setStartStyle(true, false)">
                                        {{ "Placentos išvaizda" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="
                                            let appearance of [
                                                AppearanceUterineAppendages.Norm,
                                                AppearanceUterineAppendages.Pathology
                                            ]
                                        "
                                        class="col-sm-3 col-md-3 m-3 flex-grow"
                                        radioName="placentaAppearance"
                                        [labelText]="appearance"
                                        [radioTitle]="appearance"
                                        [radioId]="appearance"
                                        [radioValue]="appearance"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('placenta.placentaAppearance')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('placenta.placentaAppearance')?.value === appearance
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div *ngIf="title === UltrasoundStages.Third" class="row mb-2">
                                <fieldset class="row" [ngClass]="setStartStyle(true, true)">
                                    <legend class="col-sm-3 col-md-3 fw-light" [ngClass]="setStartStyle(true, false)">
                                        {{ "Placentos subrendimo laipsnis" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let degree of [0, 1, 2, 3]"
                                        class="col-sm-1 col-md-1 m-3 flex-grow"
                                        radioName="maturityDegree"
                                        [labelText]="degree"
                                        [radioTitle]="degree"
                                        [radioId]="degree"
                                        [radioValue]="degree"
                                        [fControl]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('placenta.maturityDegree')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('placenta.maturityDegree')
                                                ?.value === degree
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-md-3">
                                    {{ "Patologinis placentos prisitvirtinimas" | translate }}
                                </div>
                                <app-checkbox
                                    *ngFor="let placentaPathologicalAttachment of placentaPathologicalAttachmentArray"
                                    class="col-sm-3 col-md-3"
                                    [checkboxValue]="placentaPathologicalAttachment"
                                    [labelText]="placentaPathologicalAttachment"
                                    [required]="
                                        !getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('placenta.pathologicalAttachmentPlacenta')?.valid
                                    "
                                    [checkboxTitle]="placentaPathologicalAttachment"
                                    [checkboxChecked]="
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('placenta.pathologicalAttachmentPlacenta')?.value ===
                                        placentaPathologicalAttachment
                                    "
                                    (change)="
                                        setCheckbox(
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('placenta.pathologicalAttachmentPlacenta'),
                                            placentaPathologicalAttachment
                                        )
                                    "
                                ></app-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="title !== UltrasoundStages.First" class="card w-100 mb-2">
                    <div class="card-header">{{ "Vaisiaus vandenys" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2 req">
                            <div class="col-sm-4 col-md-4 col-form-label">
                                {{ "Vaisiaus vandenys" | translate }}
                            </div>
                            <div class="col-auto flex-grow">
                                <app-checkbox
                                    *ngFor="
                                    let appendage of [
                                        AppearanceUterineAppendages.Norm,
                                        AppearanceUterineAppendages.Pathology
                                    ]
                                "
                                    class="col-sm-4 col-md-4"
                                    [checkboxValue]="appendage"
                                    [labelText]="appendage"
                                    [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amnioticFluid')
                                        ?.valid
                                "
                                    [checkboxTitle]="appendage"
                                    [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amnioticFluid')
                                        ?.value === appendage
                                "
                                    (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amnioticFluid'),
                                        appendage
                                    )
                                "
                                ></app-checkbox>
                                <app-form-control-validation class="d-block w-full"
                                                             [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amnioticFluid')" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-md-4">
                                {{ "Vaisiaus vandenys VVI/GVVK, mm" | translate }}
                            </div>
                            <app-input
                                class="col-sm-4 col-md-4 pe-0"
                                type="number"
                                units="/"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.VVI')"
                            />
                            <app-input
                                class="col-sm-4 col-md-4 ps-0"
                                type="number"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.GVVK')"
                            />
                        </div>

                        <div *ngIf="title === UltrasoundStages.Third">
                            <div class="row">
                                <fieldset class="row">
                                    <legend class="col-sm-4 col-md-4 fw-light">
                                        {{ "Vaisiaus kvėpavimo judesiai" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let count of [0, 2]"
                                        class="col-sm-3 col-md-3 m-3 flex-grow"
                                        radioName="fetalBreathingMovements"
                                        [labelText]="count"
                                        [radioTitle]="count"
                                        [radioId]="count"
                                        [radioValue]="count"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('amnioticFluids.fetalBreathingMovements')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('amnioticFluids.fetalBreathingMovements')?.value === count
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div class="row">
                                <fieldset class="row">
                                    <legend class="col-sm-4 col-md-4 fw-light">
                                        {{ "Vaisiaus judesiai" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let count of [0, 2]"
                                        class="col-sm-3 col-md-3 m-3 flex-grow"
                                        radioName="fetalMovements"
                                        [labelText]="count"
                                        [radioTitle]="count"
                                        [radioId]="count"
                                        [radioValue]="count"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('amnioticFluids.fetalMovements')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('amnioticFluids.fetalMovements')?.value === count
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div class="row">
                                <fieldset class="row">
                                    <legend class="col-sm-4 col-md-4 fw-light">
                                        {{ "Vaisiaus tonusas" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let count of [0, 2]"
                                        class="col-sm-3 col-md-3 m-3 flex-grow"
                                        radioName="fetalTone"
                                        [labelText]="count"
                                        [radioTitle]="count"
                                        [radioId]="count"
                                        [radioValue]="count"
                                        [fControl]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.fetalTone')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.fetalTone')
                                                ?.value === count
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div class="row">
                                <fieldset class="row">
                                    <legend class="col-sm-4 col-md-4 fw-light">
                                        {{ "Vaisiaus vandenų kiekis" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let count of [0, 2]"
                                        class="col-sm-3 col-md-3 m-3 flex-grow"
                                        radioName="amount"
                                        [labelText]="count"
                                        [radioTitle]="count"
                                        [radioId]="count"
                                        [radioValue]="count"
                                        [fControl]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amount')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amount')
                                                ?.value === count
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div class="row">
                                <fieldset class="row">
                                    <legend class="col-sm-4 col-md-4 fw-light">
                                        {{ "KTG" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let count of [0, 2]"
                                        class="col-sm-3 col-md-3 m-3 flex-grow"
                                        radioName="KTG"
                                        [labelText]="count"
                                        [radioTitle]="count"
                                        [radioId]="count"
                                        [radioValue]="count"
                                        [fControl]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.KTG')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.KTG')
                                                ?.value === count
                                        "
                                    />
                                </fieldset>
                            </div>
                            <div class="row">
                                <fieldset class="row">
                                    <legend class="col-sm-4 col-md-4 fw-light">
                                        {{ "Biofizinis profilis" | translate }}
                                    </legend>
                                    <app-radio-button
                                        *ngFor="let count of [0, 2, 4, 6, 8, 10]"
                                        class="col-sm-1 col-md-1 m-3 flex-grow"
                                        radioName="biophysicalProfile"
                                        [labelText]="count"
                                        [radioTitle]="count"
                                        [radioId]="count"
                                        [radioValue]="count"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('amnioticFluids.biophysicalProfile')
                                        "
                                        [radioChecked]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('amnioticFluids.biophysicalProfile')?.value === count
                                        "
                                    />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="title === UltrasoundStages.Third" class="card w-100 mb-2">
                    <div class="card-header">{{ "Kraujotakos tyrimas" | translate }}</div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-12">
                                {{ "Virkštelės arterija" | translate }}
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "PI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="umbilicalArtery.PI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.umbilicalArtery.PI')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "RI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="umbilicalArtery.RI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.umbilicalArtery.RI')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="row">
                                    <div class="col-sm-4 col-md-4">
                                        {{ "S/D santykis" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="umbilicalArtery.SDRatio"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.umbilicalArtery.SDRatio')
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                {{ "Kraujotakos klasė" | translate }}
                            </div>
                            <app-checkbox
                                *ngFor="let bloodFlow of bloodFlowTestArray"
                                class="col-sm-3 col-md-3"
                                [checkboxValue]="bloodFlow"
                                [labelText]="bloodFlow"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('bloodFlowTest.circulationClass')
                                        ?.valid
                                "
                                [checkboxTitle]="bloodFlow"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('bloodFlowTest.circulationClass')
                                        ?.value === bloodFlow
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray
                                            .at(selectedTabIndex)
                                            .get('bloodFlowTest.circulationClass'),
                                        bloodFlow
                                    )
                                "
                            ></app-checkbox>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-12">
                                {{ "Vidurinė smegenų arterija" | translate }}
                            </div>
                            <div class="col-sm-12 col-md-2">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "PI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="middleCerebralArtery.PI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.middleCerebralArtery.PI')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-2">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "RI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="middleCerebralArtery.RI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.middleCerebralArtery.RI')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4">
                                        {{ "S/D santykis" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="middleCerebralArtery.SDRatio"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.middleCerebralArtery.SDRatio')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-5">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4">
                                        {{ "PSV (Vmax)" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-4 col-md-4"
                                        id="middleCerebralArtery.PSV"
                                        type="number"
                                        min="1"
                                        max="20"
                                        units="cm/s"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.middleCerebralArtery.PSV')
                                        "
                                    />
                                    <app-input
                                        class="col-sm-4 col-md-4"
                                        id="middleCerebralArtery.cms"
                                        type="number"
                                        min="1"
                                        max="20"
                                        units="MoM"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.middleCerebralArtery.cms')
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-12">
                                {{ "Veninis latakas" | translate }}
                            </div>
                            <div class="col-sm-12 col-md-2">
                                <div class="row">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "PI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="venousDuct.PI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.venousDuct.PI')
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 col-md-12">
                                {{ "Blužnies arterija" | translate }}
                            </div>
                            <div class="col-sm-12 col-md-2">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "PI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="splenicArtery.PI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.splenicArtery.PI')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-2">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4 flex-md-row">
                                        {{ "RI" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="splenicArtery.RI"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.splenicArtery.RI')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-md-4">
                                        {{ "S/D santykis" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-8 col-md-8"
                                        id="splenicArtery.SDRatio"
                                        type="number"
                                        min="1"
                                        max="20"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.splenicArtery.SDRatio')
                                        "
                                    />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-5">
                                <div class="row">
                                    <div class="col-sm-4 col-md-4">
                                        {{ "PSV (Vmax)" | translate }}
                                    </div>
                                    <app-input
                                        class="col-sm-4 col-md-4"
                                        id="splenicArtery.PSV"
                                        type="number"
                                        min="1"
                                        max="20"
                                        units="cm/s"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.splenicArtery.PSV')
                                        "
                                    />
                                    <app-input
                                        class="col-sm-4 col-md-4"
                                        id="splenicArtery.cms"
                                        type="number"
                                        min="1"
                                        max="20"
                                        units="MoM"
                                        [fControl]="
                                            getFetusDataFormArray
                                                .at(selectedTabIndex)
                                                .get('bloodFlowTest.splenicArtery.cms')
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card w-100 mb-2">
                    <div class="card-header">{{ "Pastabos, išvados" | translate }}</div>
                    <div class="card-body">
                        <div class="row req">
                            <div class="col fw-bold col-form-label">
                                {{ "Išvada" | translate }}
                            </div>
                            <app-checkbox
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="Conclusions.Norm"
                                [labelText]="Conclusions.Norm"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')?.valid
                                "
                                [checkboxTitle]="Conclusions.Norm"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')?.value ===
                                    Conclusions.Norm
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion'),
                                        Conclusions.Norm
                                    )
                                "
                            ></app-checkbox>
                            <app-form-control-validation class="d-block w-full"
                                                         [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('amnioticFluids.amnioticFluid')" />
                            <app-checkbox
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="Conclusions.NotFinished"
                                [labelText]="Conclusions.NotFinished"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')?.valid
                                "
                                [checkboxTitle]="Conclusions.NotFinished"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')?.value ===
                                    Conclusions.NotFinished
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion'),
                                        Conclusions.NotFinished
                                    )
                                "
                            ></app-checkbox>
                            <app-checkbox
                                class="col-sm-4 col-md-3"
                                [checkboxValue]="Conclusions.Pathology"
                                [labelText]="Conclusions.Pathology"
                                [required]="
                                    !getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')?.valid
                                "
                                [checkboxTitle]="Conclusions.Pathology"
                                [checkboxChecked]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')?.value ===
                                    Conclusions.Pathology
                                "
                                (change)="
                                    setCheckbox(
                                        getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion'),
                                        Conclusions.Pathology
                                    )
                                "
                            ></app-checkbox>
                            <app-form-control-validation
                            [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('conclusions.conclusion')" />
                        </div>
                        <div class="row mb-2">
                            <app-input
                                id="otherImportantInfo"
                                type="textarea"
                                labelClass="fw-light"
                                labelText="Pastabos, detali informacija apie patologinius radinius"
                                [fControl]="getFetusDataFormArray.at(selectedTabIndex).get('conclusions.notes')"
                            />
                        </div>
                        <div class="row req">
                            <div class="col-sm-12 col-md-4 col-form-label">
                                {{ "Ultragarsu nustatyta nėštumo trukmė" | translate }}
                            </div>
                            <app-input
                                class="col-sm-6 col-md-4"
                                id="pregnancyDuration.week"
                                type="number"
                                units="sav"
                                [showErrors]="true"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('conclusions.pregnancyDuration.week')
                                "
                            />
                            <app-input
                                class="col-sm-6 col-md-4"
                                id="pregnancyDuration.day"
                                type="number"
                                units="d"
                                [max]="6"
                                [showErrors]="true"
                                [fControl]="
                                    getFetusDataFormArray.at(selectedTabIndex).get('conclusions.pregnancyDuration.day')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card w-100">
        <div class="card-header">{{ "Rekomendacijos" | translate }}</div>
        <div class="card-body">
            <div class="row mb-2">
                <app-checkbox
                    [inputId]="'detailedUltrasoundExamination'"
                    class="col"
                    labelText="Detalesnis ultragarsinis tyrimas nereikalingas"
                    checkboxTitle="Detalesnis ultragarsinis tyrimas nereikalingas"
                    [required]="!formGroup?.get('detailedUltrasoundExamination')?.valid"
                    [checkboxValue]="formGroup?.get('detailedUltrasoundExamination').value"
                    [checkboxChecked]="formGroup?.get('detailedUltrasoundExamination').value"
                    (change)="
                        formGroup
                            ?.get('detailedUltrasoundExamination')
                            .patchValue(!formGroup?.get('detailedUltrasoundExamination').value)
                    "
                ></app-checkbox>
            </div>
            <div *ngIf="!formGroup?.get('detailedUltrasoundExamination').value" class="row mb-2">
                <app-checkbox
                    [inputId]="'reExamination_required'"
                    class="col-sm-12 col-md-3"
                    labelText="Atlikti pakartotinį tyrimą, esant"
                    checkboxTitle="Atlikti pakartotinį tyrimą, esant"
                    [required]="!formGroup?.get('reExamination.required')?.valid"
                    [checkboxValue]="formGroup?.get('reExamination.required').value"
                    [checkboxChecked]="formGroup?.get('reExamination.required').value"
                    (change)="
                        formGroup
                            ?.get('reExamination.required')
                            .patchValue(!formGroup?.get('reExamination.required').value)
                    "
                ></app-checkbox>
                <app-input
                    class="col-sm-12 col-md-9"
                    id="reExamination.pregnancyWeek"
                    type="number"
                    units="nėštumo sav."
                    [fControl]="formGroup?.get('reExamination.pregnancyWeek')"
                />
            </div>
            <div class="row mb-2">
                <app-checkbox
                    [inputId]="'sendConsultations_required'"
                    class="col-sm-12 col-md-3"
                    labelText="Skirta konsultacija pas"
                    checkboxTitle="Skirta konsultacija pas"
                    [required]="!formGroup?.get('sendConsultations.required')?.valid"
                    [checkboxValue]="formGroup?.get('sendConsultations.required').value"
                    [checkboxChecked]="formGroup?.get('sendConsultations.required').value"
                    (change)="
                        formGroup
                            ?.get('sendConsultations.required')
                            .patchValue(!formGroup?.get('sendConsultations.required').value)
                    "
                ></app-checkbox>
                <app-input
                    class="col-sm-10 col-md-9"
                    id="sendConsultations.specialist"
                    type="text"
                    [fControl]="formGroup?.get('sendConsultations.specialist')"
                />
            </div>
            <div class="row">
                <app-input
                    id="otherImportantInfo"
                    type="textarea"
                    labelText="Kita svarbi informacija"
                    labelClass="fw-bold"
                    [fControl]="formGroup?.get('otherImportantInfo')"
                />
            </div>
        </div>
    </div>
</nhr-record-widget>
