ng1App.controller('doctor.patient.erx_pr.ListCtrl', 
    ['$scope', '$rootScope', '$routeParams', 'ErxPaths', '$window', 'ErxIntegration', 'DocumentsApiFactory', '$location', 'MessageService',
     function ($scope, $rootScope, $routeParams, ErxPaths, $window, ErxIntegration, DocumentsApiFactory, $location, MessageService) {

    $scope.prescriptionListOptions = {
        /* Route to a new prescription form, prescription type is passed here and the same type should be passed to the prescription form*/
        getNewPrescriptionUrl: function (prescriptionType) {
            return ErxPaths.new(prescriptionType/*, $routeParams.aagasgas*/);
        },
        /* Route to edit a prescription form  */
        getEditPrescriptionUrl: function (prescriptionId) {
            return ErxPaths.edit(prescriptionId);
        },
        getEditPrescriptionDocUrl: function (prescriptionId) {
            return ErxPaths.editDoc(prescriptionId);
        },
        /* Route to view a prescription form */
        getViewPrescriptionUrl: function (prescriptionId) {
            return ErxPaths.view(prescriptionId);
        },
        /* Path to view prescription form with hideDocInfo = false */
        getViewPrescriptionDocUrl: function (prescriptionId) {
            return ErxPaths.viewDoc(prescriptionId);
        },
        /* Route to return to from the prescription list */
        getReturnFromPrescriptionListUrl: function() {
            if($rootScope.restrictedMode) {
               return  ErxPaths.list();
            }
            return ErxPaths.backToDoc();
        } ,
        /* E003 or E025 composition id */
        getCompositionId: function() {
            return $routeParams.documentId;
        },
        /* Path to prescription sign page with docIds parameter*/
        getSignPrescriptionUrl: function (prescriptionId, docIds) {
            return ErxPaths.sign(prescriptionId, docIds);
        },
        submitToCard: function(param){
            $rootScope.$emit('ERX_SUBMIT_PENDING');
            DocumentsApiFactory.submitPrescriptions({id: $routeParams.documentId},{}, function(res){
                MessageService.registerSuccessEvent();
                MessageService.showSuccessEventMessage();
                $location.path($scope.prescriptionListOptions.getReturnFromPrescriptionListUrl()).search({});
            }).$promise.finally(function () {$rootScope.$emit('ERX_SUBMIT_RESOLVED'); });

        },
        openPrintPrescriptions: function () {
            $window.open(ErxPaths.printPrescriptions());
        }
    };



}]);