import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs";
import { ApiV2f025 } from "src/api/api-v2-doc-ref-f025";
import { ApiV2Entities } from "src/api/api-v2-entities";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { EntityModel } from "src/app/shared/models/entity.model";
import { DirectiveReset } from "src/app/shared/services/directive-reset";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";

@Component({
    selector: 'dp-doc-ref-025',
    templateUrl: './doc-ref-f025-5-form.component.html'
})
export class DpDocRefF0255Component implements OnInit {

    backPath: string;
    patientId = null;
    form1: FormGroup;
    form2: FormGroup;
    form3: FormGroup;
    form4: FormGroup;
    form5: FormGroup;
    form6: FormGroup;
    currentDate = new Date();
    documentTag = DocumentTagEnum.e027a;
    diagnosisArray = null;
    prefillData: any;

    classifierArray = [
        'referred-treatment-classifier',
        'treated-before-classifier',
        'alternative-treatment-classifier',
        'place-of-residence-classifier',
        'yes-or-no',
        'lives-with-classifier',
        'employment-classifier',
        'education-classifier',
        'psychoactive-substance-classifier',
        'method-of-substance-abuse-classifier',
        'frequency-of-use-classifier',
        'injection-frequency-classifier',
        'last-use-classifier',
        'borrowed-needles-classifier',
        'checked-out-for-classifier',
        'sent-for-checkup-classifier',
        'vaccinated-from-Hepatitis-B-classifier',
        'medicine-classifier',
        'reason-for-leaving-institution-classifier',
    ];

    myClassifierArrayOfClassifiers: any[] = [];

    emitedValues = null;

    documentType: EntityModel = {
        id: "3",
        code: "3",
        name: "f025"
    };

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private fb: FormBuilder,
        private ApiV2F025: ApiV2f025,
        private ApiV2Entities: ApiV2Entities,
        private directiveReset: DirectiveReset
    ) {
        this.form1 = this.fb.group({
            gender: [],
            age: [],
            visitDate: [null, [Validators.required]],
            diseaseFormType: [true],
            whoDirectedForTreatment: [null, [Validators.required]],
            otherOptionsWhoDirectedForTreatment: [],
            possibleRehabForSubstanceAbuse: [null, [Validators.required]],
            BeginningOfFirstTreatmentDate: [],
            opioidSubstitutionTherapy: [null, [Validators.required]],
            firstTreatmentDate: []
        });
        this.form2 = this.fb.group({
            municipality: [],
            //placeOfResidence: [],
            dataAboutPlaceOfResidence: [null, [Validators.required]],
            livesWithMinors: [null, [Validators.required]],
            livesWithWho: [null, [Validators.required]],
            currentEmployment: [null, [Validators.required]],
            receivedEducation: [null, [Validators.required]],
        });
        this.form3 = this.fb.group({
            substanceForSeekingHelp: [null, [Validators.required]],
            mainWayOfUsingSubstance: [null, [Validators.required]],
            firstTimeUseAge: [null, [Validators.required]],
            firstTimeUseAgeForMainSubstance: [null, [Validators.required]],
            frequencyOfSubstanceUse: [null, [Validators.required]],
            useOfDifferentSubstances: [null, [Validators.required]],
            useOfOtherSubstancesFirst: [],
            useOfOtherSubstancesSecond: [],
            useOfInjectableSubstances: [],
            timeSpanFromLastInjection: []
        });
        this.form4 = this.fb.group({
            useOfBorrowedEquipment: [null, [Validators.required]],
            HIVpositive: [null, [Validators.required]],
            checkedOutForHIV: [],
            sentToCheckForHIV: [],
            otherOptionsForHIV: [],
            hepatitisB: [null, [Validators.required]],
            checkedOutForHepatitisB: [],
            sentToCheckForHepatitisB: [],
            otherOptionsForHepatitisB: [],
            vaccinatedForHepatitisB: [],
            hepatitisC: [null, [Validators.required]],
            checkedOutForHepatitisC: [],
            sentToCheckForHepatitisC: [],
            otherOptionsForHepatitisC: [],
            tuberculosis: [null, [Validators.required]],
            checkedOutForTuberculosis: [],
            sentToCheckForTuberculosis: [],
            otherOptionsForTuberculosis: []
        });
        this.form5 = this.fb.group({
            tumorDiagnosis: [],
            TLK_10_AM: [],
            diagnosisDate: [],
            DiagnosisF11_F19: [],
            diagnosisDateF11_F19: [],
            patientCareServiceType: [true],
            providedServices: [null, [Validators.required]],
            treatmentUsedAtThisStage: [],
            otherTreatmentUsedAtThisStage: []
        });
        this.form6 = this.fb.group({
            dischargedFromHealthInstitution: [],
            otherInformation: [],
            formFillDate: [null, [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/f090/list`;
        this.getClassifiers();
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getClassifiers() {
        for (let i = 0; i < this.classifierArray.length; i++) {
            var classifierName = this.classifierArray[i];
            this.ApiV2Entities.get(classifierName).pipe(map((response: any) => response)).subscribe({
                next: (response) => {
                    this.myClassifierArrayOfClassifiers[i] = response;
                }
            });
        }
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    getMunicipality(event) {
        this.form2.get('municipality').patchValue(event.patient.declaredAddress.municipalityId);
    }

    getPatientAgeAndGender(event) {
        this.form1.get('gender').patchValue(event.patient.sex.name);
        var patientAge = moment().diff(event.patient.birthDate, 'years');
        this.form1.get('age').patchValue(patientAge);

    }

    onChanges(event) {
        this.emitedValues = event;
        this.getMunicipality(event);
        this.getPatientAgeAndGender(event);
    }

    getAllFormsInOne() {
        const formValues = {
            ...this.form1.value, ...this.form2.value, ...this.form3.value,
            ...this.form4.value, ...this.form5.value, ...this.form6.value
        };
        return formValues;
    }

    onDiagnosisChanges(event) {
        this.diagnosisArray = event;
        if (event.length > 0) {
            this.form5.controls['tumorDiagnosis'].setValue(event[0].name);
            this.form5.controls['TLK_10_AM'].setValue(event[0].code);
            this.form5.controls['diagnosisDate'].setValue(event[0].onsetDate);
            this.form5.controls['DiagnosisF11_F19'].setValue("DiagnosisF11_F19");
            this.form5.controls['diagnosisDateF11_F19'].setValue(new Date());
        }
    }

    public saveForm(): void {
        const formValues = this.getAllFormsInOne();
        var prefillJson = this.gatherInfoFromEmittedData();
        const diagnosis = this.diagnosisArray;
        const mergeJSON = { ...formValues, ...prefillJson, diagnosis };
        console.log("mergeJSON", mergeJSON);
        //this.ApiV2F025.savef025Form(mergeJSON, this.patientId).subscribe();
    }

    public submitForm(): void {
        const formValues = this.getAllFormsInOne();
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2F025.submitf025Form(mergeJSON, this.patientId).subscribe();
    }

    public signingSubmitForm(): void {
        const formValues = this.getAllFormsInOne();
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2F025.signingSubmitf025Form(mergeJSON, this.patientId).subscribe();
    }

    public sealConfirmForm(): void {
        const formValues = this.getAllFormsInOne();
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2F025.sealf025Form(mergeJSON, this.patientId).subscribe();
    }

    public signForm(): void {
        const formValues = this.getAllFormsInOne();
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2F025.signf025Form(mergeJSON, this.patientId).subscribe();
    }

    public clearForm(): void {
        this.form1.get('visitDate').patchValue("");
        this.form1.get('diseaseFormType').patchValue(true);
        this.form1.get('whoDirectedForTreatment').patchValue(null);
        this.form1.get('otherOptionsWhoDirectedForTreatment').patchValue("");
        this.form1.get('possibleRehabForSubstanceAbuse').patchValue(null);
        this.form1.get('BeginningOfFirstTreatmentDate').patchValue("");
        this.form1.get('opioidSubstitutionTherapy').patchValue(null);
        this.form1.get('firstTreatmentDate').patchValue("");

        // Patch values for form2
        this.form2.get('dataAboutPlaceOfResidence').patchValue(null);
        this.form2.get('livesWithMinors').patchValue(null);
        this.form2.get('livesWithWho').patchValue(null);
        this.form2.get('currentEmployment').patchValue(null);
        this.form2.get('receivedEducation').patchValue(null);

        // Patch values for form3
        this.form3.get('substanceForSeekingHelp').patchValue(null);
        this.form3.get('mainWayOfUsingSubstance').patchValue(null);
        this.form3.get('firstTimeUseAge').patchValue("");
        this.form3.get('firstTimeUseAgeForMainSubstance').patchValue("");
        this.form3.get('frequencyOfSubstanceUse').patchValue(null);
        this.form3.get('useOfDifferentSubstances').patchValue(null);
        this.form3.get('useOfOtherSubstancesFirst').patchValue(null);
        this.form3.get('useOfOtherSubstancesSecond').patchValue(null);
        this.form3.get('useOfInjectableSubstances').patchValue(null);
        this.form3.get('timeSpanFromLastInjection').patchValue(null);

        // Patch values for form4
        this.form4.get('useOfBorrowedEquipment').patchValue(null);
        this.form4.get('HIVpositive').patchValue(null);
        this.form4.get('checkedOutForHIV').patchValue(null);
        this.form4.get('sentToCheckForHIV').patchValue(null);
        this.form4.get('otherOptionsForHIV').patchValue("");
        this.form4.get('hepatitisB').patchValue(null);
        this.form4.get('checkedOutForHepatitisB').patchValue(null);
        this.form4.get('sentToCheckForHepatitisB').patchValue(null);
        this.form4.get('vaccinatedForHepatitisB').patchValue(null);
        this.form4.get('hepatitisC').patchValue(null);
        this.form4.get('checkedOutForHepatitisC').patchValue(null);
        this.form4.get('sentToCheckForHepatitisC').patchValue(null);
        this.form4.get('otherOptionsForHepatitisC').patchValue("");
        this.form4.get('tuberculosis').patchValue(null);
        this.form4.get('checkedOutForTuberculosis').patchValue(null);
        this.form4.get('sentToCheckForTuberculosis').patchValue(null);
        this.form4.get('otherOptionsForTuberculosis').patchValue("");

        // Patch values for form5
        this.directiveReset.triggerReset();
        this.form5.get('tumorDiagnosis').patchValue(null);
        this.form5.get('TLK_10_AM').patchValue(null);
        this.form5.get('diagnosisDate').patchValue(null);
        this.form5.get('DiagnosisF11_F19').patchValue(null);
        this.form5.get('diagnosisDateF11_F19').patchValue(null);
        this.form5.get('patientCareServiceType').patchValue(true);
        this.form5.get('providedServices').patchValue("");
        this.form5.get('treatmentUsedAtThisStage').patchValue(null);
        this.form5.get('otherTreatmentUsedAtThisStage').patchValue("");

        // Patch values for form6
        this.form6.get('dischargedFromHealthInstitution').patchValue(null);
        this.form6.get('otherInformation').patchValue("");
        this.form6.get('formFillDate').patchValue("");
    }

    gatherInfoFromEmittedData() {
        var encounterRef = this.emitedValues.encounter.fullId;
        var patientRef = this.emitedValues.encounter.patientRef;
        var patientData = this.emitedValues.encounter.patientData;
        var authorRef = this.emitedValues.item.practitionerRef;
        var authorData = this.emitedValues.practitioner;
        var organizationRef = this.emitedValues.item.organizationRef;
        var organizationData = this.emitedValues.item.organization;
        var cardName = "025-5/a - Statistinė apskaitos forma";
        const combinedJSON = {
            encounterRef: encounterRef,
            patientRef: patientRef,
            patient: patientData,
            authorRef: authorRef,
            author: authorData,
            custodianRef: organizationRef,
            custodian: organizationData,
            cardName: cardName,
            documentType: this.documentType
        }
        return combinedJSON;
    }
}