import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-skeletal-muscular-system-form',
    templateUrl: './sbvf-skeletal-muscular-system-form.component.html',
})
export class SbvfSkeletalMuscularSystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    skeletalMuscularSystemRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        absenceOfBodyPart: this.fb.control(null, [Validators.required]),
        localization: this.fb.control(null),
        prostheses: this.fb.control(null, [Validators.required]),
        prosthesis: this.fb.control(null, [Validators.required]),
        paralysis: this.fb.control(null, [Validators.required]),
        placeOfParalysis: this.fb.control(null, [Validators.required]),
        activity: this.fb.control(null, [Validators.required]),
        agility: this.fb.control(null, [Validators.required]),
        contractures: this.fb.control(null, [Validators.required]),
        spsText: this.fb.control(null),
        muscleBonePain: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.skeletalMuscularSystemRows = [
            {
                title: 'Kūno dalių nebuvimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('absenceOfBodyPart')
            },
            {
                title: 'Lokalizacija',
                type: 'text',
                customClass: 'w-auto',
                formControl: this.formGroup.get('localization')
            },
            {
                title: 'Protezai',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('prostheses')
            },
            {
                title: 'Protezus',
                type: 'choise',
                options: value['diapers'],
                formControl: this.formGroup.get('prosthesis')
            },
            {
                title: 'Paralyžius',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('paralysis')
            },
            {
                title: 'Paralyžiaus vietos',
                type: 'choise',
                options: value['placeParalysis'],
                formControl: this.formGroup.get('placeOfParalysis')
            },
            {
                title: 'Aktyvumas',
                type: 'choise',
                options: value['muscularActivity'],
                formControl: this.formGroup.get('activity')
            },
            {
                title: 'Judrumas',
                type: 'choise',
                options: value['agility'],
                formControl: this.formGroup.get('agility')
            },
            {
                title: 'Kontraktūros',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('contractures')
            },
            {
                title: 'Jei yra, lokalizacija (įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('spsText')
            },
            {
                title: 'Raumenų / kaulų skausmas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('muscleBonePain')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
