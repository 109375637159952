import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Reports extends ConfigService {
    path = this.apiUrl + '/reports';

    getVaccines(): Observable<any> {
        const url = `${this.path}/vaccines?patientId=:patientId`;
        return this.http.get<any>(url);
    }

    getDoctorInfo(): Observable<any> {
        const url = `${this.path}/doctor-info`;
        return this.http.get<any>(url);
    }

    getPatientInfo(): Observable<any[]> {
        const url = `${this.path}/covid-vaccinations-recoveries?personalCode=:personalCode`;
        return this.http.get<any[]>(url);
    }

    filterVaccinatedAndRecoveredPatients(): Observable<any[]> {
        const url = `${this.path}/covid-vaccinations-recoveries`;
        return this.http.get<any[]>(url);
    }

    filterVaccinatedAndRecoveredPatientsInCsv(): Observable<any> {
        const url = `${this.path}/covid-vaccinations-recoveries/csv`;
        return this.http.get<any>(url);
    }
}
