import { AfterViewInit, Component, DestroyRef, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../../models/entity.model';
import {
    getControlValue,
    listenAndChangeStatusOfControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
import { ApiV2Inr } from '../../../services/api-v2-inr';
import moment from 'moment';
import { PractitionerModel } from '../../../models/practitioner.model';
import { ApiV2Session } from '../../../../../api/api-v2-session';
import { map } from 'rxjs';
@Component({
    selector: "app-inr-whom-person-info-subform",
    templateUrl: "./inr-whom-person-info-subform.component.html",
})
export class InrWhomPersonInfoSubformComponent implements AfterViewInit {
    @Input() editable: boolean = true;
    @Input() set patient(value: boolean) {
        this.isPatient = value;
        if (!value) {
            return;
        }

        if (this.editable) {
            this.changeStatusOfControl({
                    controlNameForListen: 'suspectedAdverseReaction',
                    controlNameForUpdate: 'personYouRepresent',
                    enableValue: 'Mano atstovaujamam asmeniui',
                    addValidators: [Validators.required]
                },
                this.patientFormGroup
            ).pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(res => {
                    if (res === 'Jums pačiam') {
                        this.formGroup.patchValue({
                            fullName: `${this.currentPatient?.givenName} ${this.currentPatient?.familyName}`,
                            age: moment().diff(moment(this.currentPatient?.birthDate), 'years'),
                            gender: this.currentPatient.sex?.name
                        });
                    }
                });
        }
    }

    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];
    suspectedAdverseReactionOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Jums pačiam'
        },
        {
            id: '1',
            code: '1',
            name: 'Mano atstovaujamam asmeniui'
        }
    ];
    personYouRepresentList: any[] = [];
    isPatient: boolean;

    formGroup: FormGroup;
    patientFormGroup: FormGroup = this.fb.group({
        suspectedAdverseReaction: this.fb.control(null, [Validators.required]),
        personYouRepresent: this.fb.control(null)
    });

    currentPatient: PractitionerModel;

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    constructor(
        private fb: FormBuilder,
        private destroyRef: DestroyRef,
        private apiV2Inr: ApiV2Inr,

        private apiV2Session: ApiV2Session,
    ) {
        this.formGroup = this.fb.group({
            fullName: this.fb.control(null),
            age: this.fb.control(null),
            gender: this.fb.control(null),
            weight: this.fb.control(null),
            height: this.fb.control(null),
            agreeSharePersonalData: this.fb.control(null)
        });
    }

    ngAfterViewInit() {
        if (this.editable) {
            const obs = this.isPatient
                ? this.apiV2Session.getRepresentative().pipe(map(data => data.patient))
                : this.apiV2Session.getPatient();

            obs.pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(data => {
                    if (!data) {
                        return;
                    }
                    this.currentPatient = data;
                    this.personYouRepresentList = data.relatedPersons.map(person => `${data?.givenName} ${data?.familyName}, ${person.relation.name}`)
                    this.formGroup.patchValue({
                        fullName: `${data?.givenName} ${data?.familyName}`,
                        age: moment().diff(moment(data?.birthDate), 'years'),
                        gender: data.sex?.name
                    })
                });
        }
    }

    selectPatient() {
        this.formGroup.patchValue({
            fullName: this.patientFormGroup.get('personYouRepresent').getRawValue()
        });
    }
}
