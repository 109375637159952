// Direktyvos, aprašančios skyrimo bei receptų koregavimo ir peržiūros formų smulkesnius komponentus
angular.module('erx.prs')
        // Vaisto papildomų pasirinkimų komponentas
        .directive('erxEditPrescriptionMedicationOptions', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_prescription_medication_options.html',
            restrict: 'E',
            scope: true,
            link: function (scope, element, attrs) {
            },
            replace: false
          };
        })
        // Recepto duomenų koregavimo komponentas
        .directive('erxEditPrescriptionInfo', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_prescription_info.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        // Vaisto ir vardinio vaisto duomenų koregavimo komponentas
        .directive('erxEditMPrescription', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_m_prescription.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        // MPS duomenų koregavimo komponentas
        .directive('erxEditMpsPrescription', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_mps_prescription.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        // MPP duomenų koregavimo komponentas
        .directive('erxEditMppPrescription', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_mpp_prescription.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        // Kito produkto duomenų koregavimo komponentas
        .directive('erxEditMaPrescription', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_ma_prescription.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        // Ekstemporalaus vaisto duomenų koregavimo komponentas
        .directive('erxEditEmPrescription', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_em_prescription.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        // Bendras veiksmų su skyrimu/receptu komponentas naudojamas koregavimo formose
        .directive('erxEditPrescriptionConfirmation', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_prescription_confirmation.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope, element, attrs) {
            }
          };
        })
        .directive('erxEditWarnings', function () {
          return {
            templateUrl: 'modules/erx/prs/prescription_form/directives/edit_warnings.html',
            restrict: 'E',
            replace: true,
            scope: true,
            transclude: true,
            link: function (scope: any, element, attrs) {
              scope.deleteWarning = function(index) {
                scope.medicationPrescription.issues.splice(index, 1);
              }
            }
          };
        })
      .directive('shortcutPrescriptionGroup', function () {
        return {
          templateUrl: 'modules/erx/prs/prescription_form/directives/shortcut_prescription_group.html',
          restrict: 'E',
          scope: {
            title: '=',
            data: '=',
            staticData: '=',
            selection: '&',
            edit: '&',
          },
          controller: ["$scope", function ($scope) {
            if($scope.data !== undefined){
              $scope.frequencyDosageData = $scope.data.filter(item => item.group === 'frequencyDosage');
              $scope.dayDosageData = $scope.data.filter(item => item.group === 'dayDosage');
              $scope.disposableDosageData = $scope.data.filter(item => item.group === 'disposableDosage');
            } else {
              $scope.frequencyDosageData = [];
              $scope.dayDosageData = [];
              $scope.disposableDosageData = [];
            }

            $scope.staticFrequencyCheckbox = $scope.staticData.filter(item => item.group === 'frequencyCheckbox');
            $scope.staticTimeCheckbox = $scope.staticData.filter(item => item.group === 'timeCheckbox');
          }]
        }
      })
    .directive('compensationTypeCodeSelect', function () {
      return {
        templateUrl: 'modules/erx/prs/prescription_form/directives/compensation_type_code_select.html',
        restrict: 'E',
        scope: {
          id: '=',
          isRequired: '=',
          name: '=',
          title: '=',
          ngModel: '=',
          data: '=',
        },
      }
    })
    .directive('editPrescriptionDosage', function () {
      return {
        templateUrl: 'modules/erx/prs/prescription_form/directives/edit_prescription_dosage.html',
        restrict: 'E',
        scope: true,
        link: function (scope, element, attrs) {
        }
      }
    })
    .directive('editPrescriptionMedicineUsage', function () {
      return {
        templateUrl: 'modules/erx/prs/prescription_form/directives/edit_prescription_medicine_usage.html',
        restrict: 'E',
        scope: true,
        link: function (scope, element, attrs) {
        }
      }
    });