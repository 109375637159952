import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { onCheckboxChange } from "../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { HighRiskFactors, RiskFactorsModel, RiskLevels } from "../../models/riskFactors.model";
import { DocumentCaptionModel, NGNCaptionModel } from "../shared/models/shared.model";
import {
    BirthRiskHighRisks,
    BirthRiskLowRisks,
    BirthRiskMediumRisks,
    CaesareanOperationHighRisks,
    FetalPathology,
    NaturalWayAndCaesareanOperationLowRisks,
    NaturalWayAndCaesareanOperationMediumRisks,
    NaturalWayHighRisks,
    PostpartumHemorrhageHighRisks,
    PostpartumHemorrhageLowRisks,
    PostpartumHemorrhageMediumRisks,
    PregnancyPathology,
    PregnantDiseases,
    PrematureBirthRiskHighRisks,
    PrematureBirthRiskLowRisks,
    ThromboembolismPreventionHighRisks,
    ThromboembolismPreventionLowRisks,
    ThromboembolismPreventionMediumRisks,
    UnfavorableObstetricHistory,
    UnfavorablePregnantCondition,
    getRiskLevel,
} from "./risk-factors.constants";

@Component({
    selector: "app-risk-factors",
    templateUrl: "./risk-factors.component.html",
})
export class RiskFactorsComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;
    public readonly unfavorableObstetricHistory = Object.values(UnfavorableObstetricHistory);
    public readonly pregnancyPathology = Object.values(PregnancyPathology);
    public readonly unfavorablePregnantCondition = Object.values(UnfavorablePregnantCondition);
    public readonly fetalPathology = Object.values(FetalPathology);
    public readonly pregnantDiseases = Object.values(PregnantDiseases);
    public readonly thromboembolismPreventionLowRisks = Object.values(ThromboembolismPreventionLowRisks);
    public readonly thromboembolismPreventionMediumRisks = Object.values(ThromboembolismPreventionMediumRisks);
    public readonly thromboembolismPreventionHighRisks = Object.values(ThromboembolismPreventionHighRisks);
    public readonly prematureBirthRiskLowRisks = Object.values(PrematureBirthRiskLowRisks);
    public readonly prematureBirthRiskHighRisks = Object.values(PrematureBirthRiskHighRisks);
    public readonly birthRiskLowRisks = Object.values(BirthRiskLowRisks);
    public readonly birthRiskMediumRisks = Object.values(BirthRiskMediumRisks);
    public readonly birthRiskHighRisks = Object.values(BirthRiskHighRisks);
    public readonly postpartumHemorrhageLowRisks = Object.values(PostpartumHemorrhageLowRisks);
    public readonly postpartumHemorrhageMediumRisks = Object.values(PostpartumHemorrhageMediumRisks);
    public readonly postpartumHemorrhageHighRisks = Object.values(PostpartumHemorrhageHighRisks);
    public readonly naturalWayAndCaesareanOperationLowRisks = Object.values(NaturalWayAndCaesareanOperationLowRisks);
    public readonly naturalWayAndCaesareanOperationMediumRisks = Object.values(
        NaturalWayAndCaesareanOperationMediumRisks
    );
    public readonly naturalWayHighRisks = Object.values(NaturalWayHighRisks);
    public readonly caesareanOperationHighRisks = Object.values(CaesareanOperationHighRisks);

    public thromboembolismPrevention = [
        {
            label: "Maža rizika, - kai nustatyti mažiau nei 3 rizikos veiksniai iš žemiau pateikto sąrašo:",
            controlName: "low",
            checkBoxNames: this.thromboembolismPreventionLowRisks,
        },
        {
            label: "Vidutinė rizika, - kai nustatyti 3 ir daugiau mažos rizikos veiksniai arba bent vienas iš žemiau:",
            controlName: "medium",
            checkBoxNames: this.thromboembolismPreventionMediumRisks,
        },
        {
            label: "Didelė rizika, - anksčiau buvęs vienas GVT epizodas, kai yra:",
            controlName: "high",
            checkBoxNames: this.thromboembolismPreventionHighRisks,
        },
    ];

    public prematureBirthRisk = [
        {
            label: "Maža rizika, - kai pažymėti visi veiksniai iš žemiau pateikto sąrašo:",
            controlName: "low",
            checkBoxNames: this.prematureBirthRiskLowRisks,
        },
        {
            label: "Didelė rizika, - kai pažymėtas bent vienas veiksnys iš žemiau pateikto sąrašo:",
            controlName: "high",
            checkBoxNames: this.prematureBirthRiskHighRisks,
        },
    ];

    public birthRisk = [
        {
            label: "Maža rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
            controlName: "low",
            checkBoxNames: this.birthRiskLowRisks,
        },
        {
            label: "Vidutinė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
            controlName: "medium",
            checkBoxNames: this.birthRiskMediumRisks,
        },
        {
            label: "Didelė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
            controlName: "high",
            checkBoxNames: this.birthRiskHighRisks,
        },
    ];

    public postpartumHemorrhageRisks = [
        {
            label: "Maža rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
            controlName: "low",
            checkBoxNames: this.postpartumHemorrhageLowRisks,
        },
        {
            label: "Vidutinė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
            controlName: "medium",
            checkBoxNames: this.postpartumHemorrhageMediumRisks,
        },
        {
            label: "Didelė rizika, - kai nustatyti 1 ir daugiau rizikos veiksnių iš žemiau pateikto sąrašo:",
            controlName: "high",
            checkBoxNames: this.postpartumHemorrhageHighRisks,
        },
    ];

    public thromboembolismPreventionAfterChildbirthRisksNaturalWay = [
        {
            label: "Maža rizika",
            controlName: "low",
            checkBoxNames: this.naturalWayAndCaesareanOperationLowRisks,
        },
        {
            label: "Vidutinė rizika, - kai nustatyti 3 ir daugiau mažos rizikos veiksniai arba bent vienas iš žemiau:",
            controlName: "medium",
            checkBoxNames: this.naturalWayAndCaesareanOperationMediumRisks.filter(
                (risk) => risk !== NaturalWayAndCaesareanOperationMediumRisks.AfterCaesareanOperation
            ),
        },
        {
            label: "Didelė rizika, - kai pažymėti 4 ir daugiau Vidutinės rizikos veiksniai arba vienas ar daugiau Didelės rizikos veiksniai",
            controlName: "high",
            checkBoxNames: this.naturalWayHighRisks,
        },
    ];

    public thromboembolismPreventionAfterChildbirthRisksCaesareanOperation = [
        {
            label: "Maža rizika",
            controlName: "low",
            checkBoxNames: this.naturalWayAndCaesareanOperationLowRisks,
        },
        {
            label: "Vidutinė rizika, - kai nustatyti 3 ir daugiau mažos rizikos veiksniai arba bent vienas iš žemiau:",
            controlName: "medium",
            checkBoxNames: this.naturalWayAndCaesareanOperationMediumRisks,
        },
        {
            label: "Didelė rizika, - kai pažymėti 4 ir daugiau Vidutinės rizikos veiksniai arba vienas ar daugiau Didelės rizikos veiksniai",
            controlName: "high",
            checkBoxNames: this.caesareanOperationHighRisks,
        },
    ];

    public thromboembolismPreventionAfterChildbirthOptions = [
        {
            name: "Natūraliais takais",
            value: true,
        },
        {
            name: "Cezario pjūvio operacija",
            value: false,
        },
    ];

    public getCheckboxFormArrayValue(fGroup: string, controlName: string): [] {
        return this.formGroup?.get(`${fGroup}.${controlName}`)?.value || [];
    }

    public get getUnfavorableObstetricHistoryFormArray(): FormArray {
        return this.formGroup?.get("highRiskFactors.unfavorableObstetricHistory") as FormArray;
    }

    public get getPregnancyPathologyFormArray(): FormArray {
        return this.formGroup?.get("highRiskFactors.pregnancyPathology") as FormArray;
    }

    public get getUnfavorablePregnantConditionFormArray(): FormArray {
        return this.formGroup?.get("highRiskFactors.unfavorablePregnantCondition") as FormArray;
    }

    public get getFetalPathologyFormArray(): FormArray {
        return this.formGroup?.get("highRiskFactors.fetalPathology") as FormArray;
    }

    public get getPregnantDiseasesFormArray(): FormArray {
        return this.formGroup?.get("highRiskFactors.pregnantDiseases") as FormArray;
    }

    public currentDate = new Date();
    public documentTag: DocumentTagEnum = DocumentTagEnum.e113_rv;
    prefillData: any;
    public documentCaptionData: DocumentCaptionModel;
    public ngnCaptionData: NGNCaptionModel;

    public onCheckboxChange = onCheckboxChange;
    public getRiskLevel = getRiskLevel;

    private patientId: string;
    private isEdit: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();
        this.patchHighRiskFactors();
        this.subscription.add(this.subscribeDocumentCaptionData());
        this.subscription.add(this.subscribeNGNCaptionData());
        this.subscription.add(this.subscribeChildbirthTypeChanges());

        if (this.isEdit) {
            this.subscription.add(this.patchForm());
        }

        this.formGroup.markAllAsTouched();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    public isRiskLevelSelected(fGroup: string): boolean {
        const value = this.formGroup?.get(`${fGroup}`)?.value;
        return !!value?.high?.length || !!value?.medium?.length || !!value?.low?.length;
    }

    public getRiskLevelStyle(fGroup: string): string {
        switch (fGroup) {
            case "prematureBirthRisk":
                if (this.formGroup?.get(`${fGroup}.high`)?.value?.length > 0) {
                    return "bg-danger";
                } else if (
                    this.formGroup?.get(`${fGroup}.low`)?.value?.length === this.prematureBirthRiskLowRisks.length
                ) {
                    return "bg-success";
                } else {
                    return "";
                }

            case "birthRisk":
                if (
                    this.formGroup?.get(`${fGroup}.high`)?.value?.length > 0 ||
                    this.formGroup?.get(`${fGroup}.medium`)?.value?.length > 2
                ) {
                    return "bg-danger";
                } else if (
                    this.formGroup?.get(`${fGroup}.medium`)?.value?.length === 1 ||
                    this.formGroup?.get(`${fGroup}.medium`)?.value?.length === 2
                ) {
                    return "bg-warning";
                } else if (this.formGroup?.get(`${fGroup}.low`)?.value?.length === 4) {
                    return "bg-success";
                } else {
                    return;
                }

            case "postpartumHemorrhageRisk":
                if (
                    this.formGroup?.get(`${fGroup}.high`)?.value?.length > 0 ||
                    this.formGroup?.get(`${fGroup}.medium`)?.value?.length > 1
                ) {
                    return "bg-danger";
                } else if (this.formGroup?.get(`${fGroup}.medium`)?.value?.length > 0) {
                    return "bg-warning";
                }

            case "thromboembolismPreventionAfterChildbirth":
                if (
                    this.formGroup?.get(`${fGroup}.high`)?.value?.length > 0 ||
                    this.formGroup?.get(`${fGroup}.medium`)?.value?.length >= 4 ||
                    (this.formGroup?.get(`${fGroup}.medium`)?.value?.length >= 3 &&
                        this.formGroup
                            ?.get(`${fGroup}.medium`)
                            ?.value?.includes(NaturalWayAndCaesareanOperationMediumRisks.AfterCaesareanOperation))
                ) {
                    return "bg-danger";
                } else if (
                    (this.formGroup?.get(`${fGroup}.medium`)?.value?.length >= 2 &&
                        this.formGroup
                            ?.get(`${fGroup}.medium`)
                            ?.value?.includes(NaturalWayAndCaesareanOperationMediumRisks.AfterCaesareanOperation)) ||
                    this.formGroup?.get(`${fGroup}.medium`)?.value?.length >= 3
                ) {
                    return "bg-warning";
                } else {
                    return "bg-success";
                }

            default:
                return "bg-success";
        }
    }

    public clearForm(): void {
        this.clearHighRiskFactors();
        this.clearRisk("prematureBirthRisk");
        this.clearRisk("birthRisk");
        this.clearRisk("postpartumHemorrhageRisk");
        this.clearRisk("thromboembolismPrevention");
        this.clearRisk("thromboembolismPreventionAfterChildbirth");

        this.patchHighRiskFactors();
    }

    public clearHighRiskFactors(): void {
        (this.formGroup?.get(`highRiskFactors.unfavorableObstetricHistory`) as FormArray).clear();
        (this.formGroup?.get(`highRiskFactors.pregnancyPathology`) as FormArray).clear();
        (this.formGroup?.get(`highRiskFactors.unfavorablePregnantCondition`) as FormArray).clear();
        (this.formGroup?.get(`highRiskFactors.fetalPathology`) as FormArray).clear();
        (this.formGroup?.get(`highRiskFactors.pregnantDiseases`) as FormArray).clear();
    }

    public clearRisk(control: string): void {
        (this.formGroup?.get(`${control}.low`) as FormArray).clear();
        (this.formGroup?.get(`${control}.high`) as FormArray).clear();
        if (control !== "prematureBirthRisk") {
            (this.formGroup?.get(`${control}.medium`) as FormArray).clear();
        }
    }

    public async saveForm(): Promise<void> {
        const payload: RiskFactorsModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.saveRiskFactors(payload);

        this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${DocumentTagEnum.e113_rv}`]);
    }

    public async submitForm(): Promise<void> {
        const payload: RiskFactorsModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.submitRiskFactors(payload);
    }

    public async signingSubmitForm(): Promise<void> {
        const payload: RiskFactorsModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signingSubmitRiskFactors(payload);
    }

    public async sealConfirmForm(): Promise<void> {
        const payload: RiskFactorsModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.sealConfirmRiskFactors(payload);
    }

    public async signForm(): Promise<void> {
        const payload: RiskFactorsModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signRiskFactors(payload);
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            highRiskFactors: this._fb.group({
                unfavorableObstetricHistory: this._fb.array([]),
                pregnancyPathology: this._fb.array([]),
                unfavorablePregnantCondition: this._fb.array([]),
                fetalPathology: this._fb.array([]),
                pregnantDiseases: this._fb.array([]),
            }),
            prematureBirthRisk: this._fb.group({
                low: this._fb.array([]),
                high: this._fb.array([]),
            }),
            birthRisk: this._fb.group({
                low: this._fb.array([]),
                medium: this._fb.array([]),
                high: this._fb.array([]),
            }),
            postpartumHemorrhageRisk: this._fb.group({
                low: this._fb.array([]),
                medium: this._fb.array([]),
                high: this._fb.array([]),
            }),
            thromboembolismPrevention: this._fb.group({
                low: this._fb.array([]),
                medium: this._fb.array([]),
                high: this._fb.array([]),
            }),
            thromboembolismPreventionAfterChildbirth: this._fb.group({
                low: this._fb.array([]),
                medium: this._fb.array([]),
                high: this._fb.array([]),
            }),
            childbirthType: this._fb.control(null),
        });
    }

    private patchHighRiskFactors(): void {
        this.unfavorableObstetricHistory.forEach((x) => this.pushHighRiskFactors("unfavorableObstetricHistory", x));
        this.pregnancyPathology.forEach((x) => this.pushHighRiskFactors("pregnancyPathology", x));
        this.unfavorablePregnantCondition.forEach((x) => this.pushHighRiskFactors("unfavorablePregnantCondition", x));
        this.fetalPathology.forEach((x) => this.pushHighRiskFactors("fetalPathology", x));
        this.pregnantDiseases.forEach((x) => this.pushHighRiskFactors("pregnantDiseases", x));
    }

    private pushHighRiskFactors(control: string, name: string): void {
        (this.formGroup?.get(`highRiskFactors.${control}`) as FormArray).push(
            this._fb.group({
                name: new FormControl(name),
                checked: new FormControl(false),
                value: new FormControl(null, [this.customDateValidator]),
            })
        );
    }

    private customDateValidator(control: AbstractControl) {
        const otherControl = control.parent?.get("checked");

        if (otherControl && !control.value) {
            return { customValidation: true };
        }

        return null;
    }

    private patchForm(): Subscription {
        return this._apiV2PregnancyDashboard.getRiskFactors(this.patientId).subscribe((data) => {
            this.patchRiskFactors("highRiskFactors", data.highRiskFactors);
            this.patchRiskLevels("prematureBirthRisk", data.prematureBirthRisk);
            this.patchRiskLevels("birthRisk", data.birthRisk);
            this.patchRiskLevels("postpartumHemorrhageRisk", data.postpartumHemorrhageRisk);
            this.patchRiskLevels("thromboembolismPrevention", data.thromboembolismPrevention);
            this.patchRiskLevels(
                "thromboembolismPreventionAfterChildbirth",
                data.thromboembolismPreventionAfterChildbirth
            );
        });
    }

    private patchRiskFactors(fGroup: string, data: HighRiskFactors): void {
        const unfavorableObstetricHistory: FormArray = this.formGroup.get(
            `${fGroup}.unfavorableObstetricHistory`
        ) as FormArray;
        const pregnancyPathology: FormArray = this.formGroup.get(`${fGroup}.pregnancyPathology`) as FormArray;
        const unfavorablePregnantCondition: FormArray = this.formGroup.get(
            `${fGroup}.unfavorablePregnantCondition`
        ) as FormArray;
        const fetalPathology: FormArray = this.formGroup.get(`${fGroup}.fetalPathology`) as FormArray;
        const pregnantDiseases: FormArray = this.formGroup.get(`${fGroup}.pregnantDiseases`) as FormArray;

        data?.unfavorableObstetricHistory?.forEach((x) =>
            unfavorableObstetricHistory.controls
                .find((c) => c.value.name === x.name)
                .patchValue({
                    checked: x.checked,
                    value: x.value,
                })
        );
        data?.pregnancyPathology?.forEach((x) =>
            pregnancyPathology.controls
                .find((c) => c.value.name === x.name)
                .patchValue({
                    checked: x.checked,
                    value: x.value,
                })
        );
        data?.unfavorablePregnantCondition?.forEach((x) =>
            unfavorablePregnantCondition.controls
                .find((c) => c.value.name === x.name)
                .patchValue({
                    checked: x.checked,
                    value: x.value,
                })
        );
        data?.fetalPathology?.forEach((x) =>
            fetalPathology.controls
                .find((c) => c.value.name === x.name)
                .patchValue({
                    checked: x.checked,
                    value: x.value,
                })
        );
        data?.pregnantDiseases?.forEach((x) =>
            pregnantDiseases.controls
                .find((c) => c.value.name === x.name)
                .patchValue({
                    checked: x.checked,
                    value: x.value,
                })
        );
    }

    private patchRiskLevels(fGroup: string, data: RiskLevels): void {
        const low: FormArray = this.formGroup.get(`${fGroup}.low`) as FormArray;
        const medium: FormArray = this.formGroup.get(`${fGroup}.medium`) as FormArray;
        const high: FormArray = this.formGroup.get(`${fGroup}.high`) as FormArray;

        data?.low?.forEach((x: string) => low.push(new FormControl(x)));
        data?.medium?.forEach((x: string) => medium.push(new FormControl(x)));
        data?.high?.forEach((x: string) => high.push(new FormControl(x)));
    }

    private subscribeDocumentCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getDocumentCaptionData(this.patientId).subscribe((data) => {
            this.documentCaptionData = data;
        });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).subscribe((data) => {
            this.ngnCaptionData = data;
        });
    }

    private subscribeChildbirthTypeChanges(): Subscription {
        return this.formGroup.get("childbirthType").valueChanges.subscribe(() => {
            this.clearRisk("thromboembolismPreventionAfterChildbirth");
        });
    }
}
