<div>
    <h1>Naujas slaugos ir priežiūros planas</h1>
    <back-link [path]="" />

    <div>
        <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
            [prefillData]="prefillData"></app-common-patient-widget>
        <form (submit)="onSave()">
            <div *ngFor="let nursingSolution of nursingSolutionControls; let i = index; let last = last">
                <nhr-record-widget [closable]="true" [closed]="false"
                    [title]="'Slaugos problema ir sprendimo metodai' | translate" id="nursingSolution">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'problem_' + i}}"
                                labelText="Būklės vertinime identifikuota slaugos problema" labelClass="fw-bold"
                                type="textarea" [fControl]="nursingSolution.get('problem')" />
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'justification_' + i}}" labelText="Pagrindimas" labelClass="fw-bold"
                                type="textarea" [fControl]="nursingSolution.get('justification')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'diagnosis_' + i}}" labelText="Slaugos diagnozė" labelClass="fw-bold"
                                type="textarea" [fControl]="nursingSolution.get('diagnosis')" />
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'assessment_' + i}}" labelText="Vertinimas" labelClass="fw-bold"
                                type="textarea" [fControl]="nursingSolution.get('assessment')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'purpose_' + i}}" labelText="Slaugos tikslas" labelClass="fw-bold"
                                type="textarea" [fControl]="nursingSolution.get('purpose')" />
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'procedures_' + i}}" labelText="Procedūros" labelClass="fw-bold"
                                type="textarea" [fControl]="nursingSolution.get('procedures')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <app-input id="{{'visits_' + i}}" labelText="Vizitai ir vizito trukmė (7 d.d.)"
                                labelClass="fw-bold" type="textarea" [fControl]="nursingSolution.get('visits')" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col d-flex justify-content-end">
                            <button *ngIf="last" class="btn btn-primary m-1" type="button"
                                (click)="addNursingSolution()">Prideti</button>
                            <button *ngIf="i != 0 || !last" class="btn btn-primary m-1" type="button"
                                (click)="removeNursingSolution(i)">Atimti</button>
                        </div>
                    </div>
                </nhr-record-widget>
            </div>

            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Supažindinimas' | translate"
                id="introduction">
                <div class="row">
                    <div class="col-md-4 fw-bold">{{'Pacientas supažindintas su planu' | translate}}</div>
                    <div class="col-md-1">
                        <app-checkbox inputId="patientIntroduction" [fControl]="formGroup.get('planIntroduction.patient')">
                        </app-checkbox>
                    </div>
                    <div class="col">
                        <app-input id="patientFalseReason" [showErrors]="true"
                            [fControl]="formGroup.get('planIntroduction.patientFalseReason')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 fw-bold">{{'Artimieji supažindinti su planu' | translate}}</div>
                    <div class="col-md-1">
                        <app-checkbox inputId="relativesIntroduction"
                            [fControl]="formGroup.get('planIntroduction.relatives')">
                        </app-checkbox>
                    </div>
                    <div class="col">
                        <app-input id="relativesFalseReason" [showErrors]="true"
                            [fControl]="formGroup.get('planIntroduction.relativesFalseReason')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 fw-bold">{{'PASTABA' | translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        {{'Į paciento slaugos ir priežiūros planą įtraukiamos tik nuolatinio pobūdžio ASPN. Į planą
                        neįtraukiamos diagnostinės procedūros ir vienkartiniai ar nustatytu laiku atliekami gydymo ar
                        slaugos veiksmai (pvz., paskirtos injekcijos N10)' | translate}}
                    </div>
                </div>
            </nhr-record-widget>

            <div class="block mt-3">
                <div class="buttons">
                    <!--            Will be changed with DocumentModificationActions component-->
                    <button class="btn btn-primary m-1" type="button"
                        (click)="onSave()">{{'doc.submit.sign'|translate}}</button>
                    <button class="btn btn-primary m-1" type="button"
                        (click)="onSave()">{{'doc.submit.batch.sign'|translate}}</button>
                    <button class="btn btn-secondary m-1" type="button" (click)="onSave()">{{'doc.provide'|translate}}
                    </button>
                    <button class="btn btn-secondary m-1" type="button"
                        (click)="onSave()">{{'doc.provide'|translate}}</button>
                    <button class="btn btn-secondary m-1" type="submit">
                        {{'doc.save'|translate}} </button>
                    <button class="btn btn-danger m-1" type="button" (click)="clearForm()">{{'doc.clear'|translate}}
                    </button>
                </div>
            </div>

        </form>


    </div>
</div>