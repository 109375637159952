<app-title [backPath]="backUrl" [backTitle]="'dpp.desktop'| translate" [showFilterButton]="true">
    {{ "Visi įrašai" | translate }}
</app-title>
<div class="widget">
    <app-page-filters [options]="filterOptions" (filtering)="updateFilters($event)"></app-page-filters>
    <div class="widget-body" [ngClass]="(records$ | async)?.length ? 'p-0' : ''">
        <ol *ngIf="(records$ | async)?.length" class="slats hover">
            <li *ngFor="let record of records$ | async; let i = index" tabindex="0" (click)="viewDocument(record.id)" (keyup)="viewDocument(record.id)">
                <div class="d-flex align-content-center column-gap-3 flex-column flex-md-row">
                    <div class="text-nowrap text-secondary">
                        {{ record.date | date : "yyyy-MM-dd" }}
                    </div>
                    <div class="flex-grow-1">
                        <div class="justify-content-between">
                            <app-doc-status-icon
                                class="d-inline-flex me-1"
                                [status]="record?.documentStatus"
                            ></app-doc-status-icon>
                            <strong>{{ record?.title }}</strong>
                        </div>
                        <div class="small text-secondary">
                            <div *ngIf="record?.conditions?.length" class="description">
                                {{ record.conditions }}
                            </div>
                            <div>
                                <a href="/dp/specialist/{{ record?.specialist?.id }}"
                                    >{{ record?.specialist?.givenName }} {{ record?.specialist?.familyName }}</a
                                >
                                <span class="vr"></span>
                                <span>{{ record?.specialist?.speciality?.name }}</span>
                                <span class="vr"></span>
                                <span>{{ record?.specialist?.practitionerOrganization?.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ol>
        <ng-container *ngIf="!(records$ | async)?.length">
            <span>{{ "Nėra duomenų" | translate }}</span>
        </ng-container>
        <spinner [working]="!isLoaded"></spinner>
    </div>
</div>

<pagination-bar
    #paginationBar
    [rowsCount]="count"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>
