import { TestQuestions } from '../../../../models/risk-factor.model';

export const FagerstormQuestions: TestQuestions[] = [
    {
        question: '1. Kada surūkote savo pirmą cigaretę nubudęs ryte?',
        answers: [
            { answer: 'Per 5 min.', score: 3 },
            { answer: 'Po 6¬–30 min.', score: 2 },
            { answer: 'Po 31¬–60 min.', score: 1 },
            { answer: 'Po 60 min.', score: 0 }
        ]
    },
    {
        question: '2. Ar Jums sunku susilaikyti nerūkius vietose, kur rūkyti draudžiama?',
        answers: [
            { answer: 'Taip', score: 1 },
            { answer: 'Ne', score: 0 },
        ]
    },
    {
        question: '3. Kurios cigaretės Jūs labiausiai nenorėtumėte atsisakyti?',
        answers: [
            { answer: 'Pirmosios rytinės', score: 1 },
            { answer: 'Bet kurios kitos', score: 0 },
        ]
    },
    {
        question: '4. Kiek cigarečių surūkote per dieną?',
        answers: [
            { answer: '31 ir daugiau', score: 3 },
            { answer: '21–30', score: 2 },
            { answer: '11–20', score: 1 },
            { answer: '10 ar mažiau', score: 0 },
        ]
    },
    {
        question: '5. Ar pirmosiomis valandomis po prabudimo Jūs rūkote daugiau negu kitu dienos metu?',
        answers: [
            { answer: 'Taip', score: 1 },
            { answer: 'Ne', score: 0 },
        ]
    },
    {
        question: '6. Ar jūs rūkote, kai sergate ir didesnę dalį dienos praleidžiate lovoje?',
        answers: [
            { answer: 'Taip', score: 1 },
            { answer: 'Ne', score: 0 },
        ]
    }
];