import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-renal-urinary-system-form',
    templateUrl: './sbvf-renal-urinary-system-form.component.html',
})
export class SbvfRenalUrinarySystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        this._viewMode = value;
        if (value) {
            this.formGroup.disable();
        }
    };

    renalUrinarySystemRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        stoma: this.fb.control(null, [Validators.required]),
        stomaType: this.fb.control({value: null, disabled: true}),
        urinating: this.fb.control(null, [Validators.required]),
        urinaryDisorder: this.fb.control(null, [Validators.required]),
        urinaryFrequency: this.fb.control(null, [Validators.required]),
        colorOfUrine: this.fb.control(null),
        catheterization: this.fb.control(null, [Validators.required]),
        bloodInUrine: this.fb.control(null, [Validators.required]),
        diapers: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });

    private _viewMode: boolean;
    constructor(
        private readonly fb: FormBuilder
    ) {
        this.formGroup.get('stoma').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('stomaType').disable();
                    return;
                }
                if (value === '0') {
                    this.formGroup.get('stomaType').enable();
                } else {
                    this.formGroup.get('stomaType').reset();
                    this.formGroup.get('stomaType').disable();
                }
            });
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.renalUrinarySystemRows = [
            {
                title: 'Stoma',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('stoma')
            },
            {
                title: 'Stomo tipas',
                type: 'choise',
                options: value['urinaryStomaType'],
                formControl: this.formGroup.get('stomaType')
            },
            {
                title: 'Šlapinimasis',
                type: 'choise',
                options: value['swallowing'],
                formControl: this.formGroup.get('urinating')
            },
            {
                title: 'Šlapinimosi sutrikimas',
                type: 'choise',
                options: value['urinaryDisorder'],
                formControl: this.formGroup.get('urinaryDisorder')
            },
            {
                title: 'Šlapinimosi dažnis',
                type: 'text',
                textInputLabel: 'k. per parą',
                formControl: this.formGroup.get('urinaryFrequency')
            },
            {
                title: 'Šlapimo spalva',
                type: 'text',
                customClass: 'w-auto',
                formControl: this.formGroup.get('colorOfUrine')
            },
            {
                title: 'Kateterizacija',
                type: 'choise',
                options: value['catheterization'],
                formControl: this.formGroup.get('catheterization')
            },
            {
                title: 'Kraujas šlapime',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('bloodInUrine')
            },
            {
                title: 'Sauskelnės ir pan.',
                type: 'choise',
                options: value['diapers'],
                formControl: this.formGroup.get('diapers')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('stoma')
            }
        ];
    }
}
