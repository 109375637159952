ng1App.factory("MedicationsCatalogApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/medications-catalog';

    return $resource('', {}, {
        getList: {
            method: 'GET',
            url: URL_BASE
        },
        getMedication: {
            method: 'GET',
            url: URL_BASE + '/:id'
        }
    });
}]);