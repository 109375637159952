ng1App.controller('document.E200EditCtrl', 
    ['$scope', '$routeParams', 'MessageService', 'DocumentModificationActions', '$filter',
     function ($scope, $routeParams, MessageService, DocumentModificationActions, $filter) {
    MessageService.clear();
    
    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(5);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'}; 
    
    $scope.data = {};

    $scope.doCreateOrder = false;

    $scope.form.$promise.then(function(doc){
        $scope.doCreateOrder = !(doc.orderData && doc.orderData.item && doc.orderData.item.id);
    });

    $scope._shared.customValidator = function (documentForm, skipRequired) {
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        MessageService.clear('material-research');
        if (!$scope.form.storageInformation.takeDatetime || (_.truncateToMinutes($scope.form.orderData.item.date) > _.truncateToMinutes($scope.form.storageInformation.takeDatetime))) {
            MessageService.add('error', 'Paėmimo data ir laikas turi būti vėlesnis nei skyrimo.', 'material-research');
            if (res) {
                _.scrollTo('[msg-region="material-research"]');
            }
            res = false;
        }
        return res;
    };
}]);