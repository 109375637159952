ng1App.controller('doctor.patient.F066alkEditCtrl', 
    ['$scope', '$window', '$routeParams', 'MessageService', 'SveidraApiFactory', '$routeSegment', '$modalConfirm', '$filter', '$location', 'DocumentSpinnerHelper', 'SveidraCommons', 'FormLoaderService', '$q',
     function ($scope, $window, $routeParams, MessageService, SveidraApiFactory, $routeSegment,
                                                              $modalConfirm, $filter, $location, DocumentSpinnerHelper, SveidraCommons,
                                                              FormLoaderService, $q) {
    
	FormLoaderService.startFormLoading();
	
	$scope.numberFormat = /^\-?\d{1,11}((\.|\,)\d{0,2})?$/;
    
    $scope.$emit('setHelpPage', 'tvarkyti-korteles-duomenis1');
	
	$scope.goBack = function(){
        $window.history.back();
    }; 
	
	MessageService.clearAll();
	
	$scope.mode = $routeParams.formId ? 'edit' : 'new';
	$scope.formId = $routeParams.formId;
	
	$scope.loadingSpinnerHelper = DocumentSpinnerHelper;
	
	if ($scope.mode == 'edit') {
		$scope.form = SveidraApiFactory.getFormForEdit({id: $scope.formId});
	} else {
		$scope.form = SveidraApiFactory.getPrefilledForm({patientId: $routeParams.patientId, type: 'FORM_F066', documentId: $routeParams.documentId});
	}
	
	// Load departments
	$scope.departments = SveidraApiFactory.getDepartments();
	
	$scope.data = {
		enterPatientSurcharges: false,
		enterPaidServices: false
	};
	
	// Perform actions after form is loaded
	$scope.form.$promise.then(function () {
		$scope.patientData = $scope.form.patient;
		$scope.authorData = $scope.form.author;
		$scope.organizationData = $scope.form.organization;
		
		// Resolve status of surcharges
		$scope.data.enterPatientSurcharges = $scope.form.surcharge.materialsAndMedicine 
											|| $scope.form.surcharge.researchAndProcedures
											|| $scope.form.surcharge.otherPatientCharges
											|| $scope.form.surcharge.stayInHcf;
		
		$scope.data.enterPaidServices = $scope.form.surcharge.paidServices
										|| $scope.form.surcharge.plannedOrUrgentServices
										|| $scope.form.surcharge.otherServices;
		
		$scope.departments.$promise.then(function () {
			// Resolve department
			_.each($scope.departments.sveidraOrganizationDepartments, function (department) {
				if ($scope.form.department && $scope.form.department.id === department.id) {
					$scope.form.department = department;
				}
			});
		});
	});
	
    FormLoaderService.endFormLoading($q.all([$scope.form.$promise, $scope.departments.$promise]));
	
	$scope.enterPatientSurchargesChanged = function () {
		if (!$scope.data.enterPatientSurcharges) {
			$scope.form.surcharge.materialsAndMedicine = null;
			$scope.form.surcharge.researchAndProcedures = null;
			$scope.form.surcharge.otherPatientCharges = null;
			$scope.form.surcharge.stayInHcf = null;
		}
	};
	
	$scope.enterPaidServicesChanged = function () {
		if (!$scope.data.enterPaidServices) {
			$scope.form.surcharge.paidServices = null;
			$scope.form.surcharge.plannedOrUrgentServices = null;
			$scope.form.surcharge.otherServices = null;
		}
	};
	
	$scope.save = function () {
		$scope.cardForm.submitted = true;
		if ($scope.cardForm.$invalid) {
			return;
		}
		
		$scope.loadingSpinnerHelper.startLoading();
		if ($scope.mode == 'edit') {
			SveidraApiFactory.updateForm({id: $scope.formId}, $scope.form, saveSuccessHandler, saveErrorHandler);
		} else {
			SveidraApiFactory.createForm({type: 'FORM_F066'}, $scope.form, saveSuccessHandler, saveErrorHandler);
		}
	};
	
	var saveSuccessHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleSaveWithWarnings(result);
		if (result.withWarnings) {
			$scope.mode = 'edit';
			$scope.formId = result.id;
			$scope.form = SveidraApiFactory.getFormForEdit();
		} else {
			MessageService.registerSuccessEvent();
			$location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms/').search({});
		}
	};
	
	var saveErrorHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleErrors(result, 'err.cer.066.sav');
	};
	
	$scope.clean = function () {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')}).then(function () {
        	$routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
	};
}]);