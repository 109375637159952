<ng-content></ng-content>
<button [title]="(working && workingTitle) ? loadingTitle : title" [type]="buttonType ? buttonType : 'button'" class="btn btn-primary" [disabled]="working" (click)="onQuick()">
    <span *ngIf="working" class="fa fa-spinner fa-spin"></span>
    <span *ngIf="working" class="sr-only ng-binding">loading...</span>

    <span *ngIf="!working" class="fa fa-search"></span>
    <span *ngIf="!working" class="sr-only ng-binding">search</span>
</button>

<ng-container *ngIf="isAdvanced">
    <button *ngIf="isAdvanced" [disabled]="working" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
            title="{{ 'ap.med.dev.lis.sea.moreAct' | translate }}">
        <span class="visually-hidden">{{ 'ap.med.dev.lis.sea.moreAct' | translate }}</span>
    </button>

    <div class="dropdown" *ngIf="isAdvanced">
        <ul class="dropdown-menu dropdown-menu-end">
            <li>
                <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#search"
                        (click)="onAdvanced()">{{ 'ap.med.dev.lis.sea.extSearch' | translate }}</button>
            </li>
            <li role="presentation" class="dropdown-divider"></li>
            <li>
                <button class="dropdown-item" (click)="onClear()">{{ 'ap.med.dev.lis.sea.clearSearch' | translate }}</button>
            </li>
        </ul>
    </div>
</ng-container>