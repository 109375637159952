import { Component } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BsModalService } from "ngx-bootstrap/modal";
import { forkJoin, take } from "rxjs";
import { ApiV2Patients } from "../../../../shared/services/api-v2-patients";
import { RouteParams } from "../../../../ng1.routeParams";
import { Pagination } from "../../../../shared/components/list/pagination-bar/pagination-bar.constants";
import { FilterOptionsInterface } from "../../../../shared/components/page-filters/page-filters.component";
import { AllergyModel } from "../../../../shared/models/allergy.model";
import { ApiV2Allergies } from "../../../../shared/services/api-v2-allergies";
import { ApiV2Entities } from "../../../../shared/services/api-v2-entities";
import { EditAllergyModalComponent } from "../allergies-form/edit-allergy-modal/edit-allergy-modal.component";
@Component({
    selector: "app-allergies-page",
    templateUrl: "./allergies-page.component.html",
})
export class AllergiesPageComponent {
    allergyHistory: AllergyModel[] = [];
    allergyFullHistory: AllergyModel[] = [];
    backUrl: string;
    btnPressed: boolean;
    filterOptions: FilterOptionsInterface[] = [];

    public total: number;
    public pagesCount: number;

    private lastFilters: { [key: string]: any } = {};
    private patientId: string;

    constructor(
        private modalService: BsModalService,
        private apiV2Patients: ApiV2Patients,
        private apiV2Entities: ApiV2Entities,
        private apiV2Allergies: ApiV2Allergies,
        private params: RouteParams
    ) {
        this.apiV2Patients
            .getFilteredAllergiesForList(this.params.patientId, this.lastFilters)
            .pipe(take(1))
            .subscribe((res) => {
                console.log("res", res);
                this.allergyHistory = res.items;

                this.total = res.total;

                const pagesCount = res.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
        forkJoin([
            this.apiV2Entities.getEntitiesList("allergy-intolerance-clinical-status"),
            this.apiV2Entities.getEntitiesList("condition-verification-status"),
            this.apiV2Entities.getEntitiesList("condition-severity"),
            this.apiV2Entities.getEntitiesList("allergy-intolerance-criticality"),
            this.apiV2Entities.getEntitiesList("allergy-crivitality"),
            this.apiV2Entities.getEntitiesList("allergy-category"),
            this.apiV2Entities.getEntitiesList("allergy-reaction"),
        ])
            .pipe(takeUntilDestroyed())
            .subscribe((res) => {
                this.filterOptions = [
                    {
                        title: "pp.dia.sea.dat",
                        type: "date-range",
                    },
                    {
                        title: "Code",
                        formControlName: "reaction",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[6],
                    },
                    {
                        title: "doc.dia.dia.typ",
                        formControlName: "crivitality",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[4],
                    },
                    {
                        title: "doc.dia.cat",
                        formControlName: "category",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[5],
                    },
                    {
                        title: "doc.dia.sts",
                        formControlName: "status",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[0],
                    },
                    {
                        title: "doc.dia.con",
                        formControlName: "verificationStatus",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[1],
                    },
                    {
                        title: "doc.dia.sev",
                        formControlName: "severity",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res[2],
                    },
                ];
            });
    }

    async ngOnInit(): Promise<void> {
        this.patientId = this.params.patientId;
        this.backUrl = "/dp/patients/" + this.patientId;
    }

    updateFilters(filters: { [key: string]: any }) {
        if (!filters) {
            return;
        }
        this.filterList(filters);
    }

    openEditAllergyModal(i: number) {
        const initialState = {
            allergy: this.allergyHistory[i],
        };
        const ref = this.modalService.show(EditAllergyModalComponent, {
            initialState,
            animated: true,
            class: "modal-xl",
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: AllergyModel) => {
            this.allergyHistory[i] = { ...result };
        });
    }

    delete(i: number) {
        this.allergyHistory.splice(i, 1);
    }

    filterList(filters: { [key: string]: any }): void {
        const equal = _.isEqual(filters, this.lastFilters);
        if (equal) {
            return;
        }
        if (_.isEmpty(filters)) {
            this.allergyHistory = [...this.allergyFullHistory];
        }

        this.apiV2Patients
            .getFilteredAllergiesForList(this.patientId, filters)
            .pipe(take(1))
            .subscribe((res) => {
                this.allergyHistory = res.items;
                this.lastFilters = filters;

                this.total = res.total;

                const pagesCount = res.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        const filter = { ...this.lastFilters, page: pagination.page };

        this.filterList(filter);
    }
}
