angular.module('erx.api').factory('ErxMedicationsApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Medication';
  return $resource('', {}, {
    // Vaistų paieška
    getMedications: {
      method: 'GET',
      url: URL_BASE + '/search',
      isArray: true
    },
    // Vaisto gavimas pagal ID
    getMedicationById: {
      method: 'GET',
      url: URL_BASE
    },
    // Pakuočių gavimas pagal bendrinį vaisto pavadinimą
    getPackages: {
      method: 'GET',
      url: URL_BASE + '/packages'
    },
    getCatalogue: {
      method: 'GET',
      url: URL_BASE + '/catalogue'
    },
    getRoutes: {
      method: 'GET',
      url: URL_BASE + '/:id/routes',
      isArray: true
    },
      getMaterialDocuments: {
          method: 'GET',
          url: URL_BASE + '/material-document',
          isArray: true
      }
  });
}]);


