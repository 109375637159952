import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'empty-component',
    template: `
    `,
})
export class EmptyComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
    }
}