<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Part.formEntries.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row" *ngIf="editable">
            <button class="btn btn-primary w-auto mx-3" [disabled]="partogramIsInvalid" (click)="addNewEntry()" *ngIf="!newEntry">{{'doc.pregnancyDashboard.e096Part.formEntries.new' | translate}}</button>
            <app-e096-part-entry-form
                *ngIf="newEntry"
                [answersVariants]="answersVariants"
                [fruitLength]="fruitLength"
                [editMode]="!!!editEntryId"
                (addNewEntry)="addEntry($event)"
                (edit)="newEntry = $event"
            />
        </div>
        <div class="row mt-3">
            <div *ngFor="let control of formGroup.controls; let i = index" class="py-3 border-bottom border-top" [class.bg-success-subtle]="i%2 && editEntryId !== i">
                <app-e096-part-entry-form
                    [answersVariants]="answersVariants"
                    [fruitLength]="fruitLength"
                    [entryData]="control.getRawValue()"
                    [editMode]="editEntryId === i"
                    [editable]="editable"
                    [disableBtns]="partogramIsInvalid"
                    (addNewEntry)="addEntry($event)"
                    (edit)="editEntryId = ($event ? i : null)"
                    (removeEntry)="removeEntry(i)"
                />
            </div>
        </div>
    </div>
</nhr-record-widget>