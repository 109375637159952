ng1App.service('SveidraCommons', 
    ['MessageService', '$filter', '$window',
     function (MessageService, $filter, $window) {
	this.handleErrors = function (result, defaultMessage) {
		var ERROR_KEY_MAP = {
				// Add error mapping here
		};
		
		MessageService.clearAll();
		if (result.data && result.data.globalErrors) {
			_.each(result.data.globalErrors, function (error) {
				var errorKey = ERROR_KEY_MAP[error.msg];
				MessageService.error(errorKey ? $filter('translate')(errorKey) : error.msg);
			});
		} else {
			MessageService.error($filter('translate')(defaultMessage));
		}
	    $window.scrollTo(0, 10);
	};
	
	this.handleSaveWithWarnings = function (result) {
		MessageService.clearAll();
		MessageService.registerSuccessEvent();
		if (result.withWarnings) {
		     MessageService.clearAll();
		     MessageService.showSuccessEventMessage();
		     MessageService.unregisterSuccessEvent();
		     _.each(result.warnings, function (warning) {
		    	MessageService.warning(warning); 
		     });
		     $window.scrollTo(0, 10);
		}
	};
}]);