<nhr-record-widget [bodyClass]="'p-0'" [closable]="false" [titleTextClass]="'d-none'" [titleClass]="(showFilters ? '' : 'd-none ') + 'p-2_5'">
    <div *ngIf="showFilters" widget-title class="w-100">
        <ng-content></ng-content>
    </div>
    <ag-grid-angular
        style="width: 100%"
        class="ag-theme-main-grid sveik-grid"
        [columnDefs]="colDefs"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [rowSelection]="'single'"
        [rowModelType]="'serverSide'"
        [serverSideDatasource]="datasource"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel]="true"
        (gridReady)="onGridReady($event)"
    />
</nhr-record-widget>
<pagination-bar
    [rowsCount]="dataCount"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>