<!-- TODO-design: Refactor escape(labelText)! -->
<!-- TODO-design: flex-grow-1 should be as a prop of the component via labelClass -->
<label *ngIf="labelText" class="form-label flex-grow-1 col-form-label" [class]="labelClass" [for]="id"
    >{{ labelText | translate }}
</label>
<div>
    <div class="input-group" [class]="inputGroupClass">
        <input
            *ngIf="!viewMode && type !== 'textarea'"
            [id]="id"
            class="form-control"
            [type]="type"
            [class]="inputClass"
            [formControl]="fControl"
            [readonly]="readonly"
            [ngClass]="{ 'is-invalid': (fControl?.invalid && fControl.touched) || error }"
            [pattern]="pattern"
            [step]="numberStep"
            [placeholder]="placeholder"
            [min]="min"
            [max]="max"
            [accept]="accept"
            [multiple]="multiple"
            (blur)="formatValue()"
            (keydown.enter)="listenEnterPress && onEnterKey($event)"
        />

        <textarea
            *ngIf="!viewMode && type === 'textarea'"
            class="form-control form-outline w-100"
            aria-label="With textarea"
            [id]="id"
            [rows]="textAreaRows"
            [formControl]="fControl"
            [class]="inputClass"
            [ngClass]="{ 'is-invalid': fControl?.invalid && fControl.touched }"
            (keydown.enter)="listenEnterPress && onEnterKey($event)"
        ></textarea>

        <input
            *ngIf="viewMode && type !== 'textarea'"
            class="form-control"
            [id]="id"
            [class]="inputClass"
            [readonly]="viewMode"
            [value]="value || fControl?.value"
        />
        <textarea
            *ngIf="viewMode && type === 'textarea'"
            class="form-control"
            [rows]="textAreaRows"
            [id]="id"
            [class]="inputClass"
            [readonly]="viewMode"
            [value]="value || fControl?.value"
        >
    </textarea>

        <span *ngIf="!!units" class="input-group-text" [id]="id">{{ units | translate }}</span>
        <span *ngIf="!units && suffixIcon" class="input-group-text"><i [class]="suffixIcon"></i></span>
    </div>
    <app-form-control-validation *ngIf="showErrors" [fControl]="fControl" />
</div>
