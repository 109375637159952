angular.module('erx.utils').service('$erxModalMedinfo', ["$modal", "$rootScope", "$q", "ErxMedicationsApiFactory", "erxApiConfig", function ($modal, $rootScope, $q, ErxMedicationsApiFactory, erxApiConfig) {
  var scope = $rootScope.$new();
  scope.data = {};

  var getMedicationInfo = function (ref) {
    scope.data.med = ErxMedicationsApiFactory.getCatalogue({ref: ref});
  }

  var attachmentUrl = '';
  scope.getAttachmentUrl = function () {
//    if (!attachmentUrl && scope.data.ref) {
//      try {
//        if (typeof scope.data.ref == 'string') {
//          scope.data.ref = scope.data.ref.split('/')[1]
//        }
//      } catch (e) {
//      }
//      attachmentUrl = erxApiConfig.options.portalBackendUrl + '/erx' + '/Medication/' + scope.data.ref + '/attachment';
//    }
    if (typeof scope.data.ref == 'string') {
      scope.data.ref = scope.data.ref.split('/')[1]
      scope.data.ref = parseInt(scope.data.ref);
    }
    switch (scope.data.ref) {
      case 1000753806: // Losartan Actavis 12,5 mg
      case 1000095645:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=4769&tid=spc'
      case 1000753821:  //Meropenem Anfarm 1000 mg
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=3419&tid=il' 
      case 1000753826: //Fraxiparine 2850 anti-Xa TV/0,3 ml
      case 1000110415:
      case 1000114433:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=3373&tid=il'
      case 1000753831: //Fragmin 12500 anti-Xa TV/0,5 ml
      case 1000099502:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=3373&tid=il'
      case 1000753836: // Vitaminas A+E Omega Pharmaitaminas A+E GSK] 2500 TV
      case 1000096597:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=1690&tid=spc';
      case 1000753841: // Cortineff 100g
      case 1000126649:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=8622&tid=spc';
      case 1000753846: // Aurocard 0,4 g/g
      case 1000106923:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=5039&tid=spc'
      case 1000753851: //Engerix-B 10  g/0,5 ml
      case 1000101550:
      case 1000127156:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=158&tid=spc'
      case 1000753856: //MEDOPEXOL 0,7 mg
      case 1000107351:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=7381&tid=spc'
      case 1000753866: //Baclosal 25 mg
      case 1000104148:
        return 'http://extranet.vvkt.lt/paieska/dl.php?fid=3765&tid=spc'
      default:
        return '';
    }
    return attachmentUrl;
  };

  var modal = $modal({template: 'modules/erx-utils/components/erx-modal-medinfo/erxModalMedinfo.tpl.html', scope: scope, show: false});
  var parentShow = modal.show;

  modal.show = function (opts) {
    if (!opts.ref) {
      return;
    }
    scope.data.ref = opts.ref;
    getMedicationInfo(scope.data.ref);
    parentShow();
    return;
  };

  return modal;
}]);