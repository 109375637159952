ng1App.controller('doctor.patient.cert.e083-1ViewCtrl', 
    ['$scope', '$routeParams', '$filter', 'AsideBlockModel',
     function ($scope, $routeParams, $filter, AsideBlockModel) {
	$scope.compositionId = $scope.certificate.compositionId;
	$scope.patientId = $routeParams.patientId;
    
	$scope.getLimitationRepresentation = function (limitation) {
		var result = limitation.limitation.code + ' ';
		result += limitation.limitation.name;
        if (limitation.additionalInfo) {
            result += ' (' + limitation.additionalInfo + ')';
        }
		return result;
	};
	
	$scope.getLimitationScope = function (limitation) {
		if (limitation.category) {
			return limitation.category.name;
		}
		if (limitation.groups) {
			if (limitation.groups.length == 1) {
				return limitation.groups[0].name;
			}
		}
		return $filter('translate')('cer.e083.res.for.group.all');
	};

	$scope._shared = {
		contentList: [
			new AsideBlockModel('cer.e083.mai.inf'),
			new AsideBlockModel('cer.e083.con'),
            new AsideBlockModel('cer.e083.doc'),
            new AsideBlockModel('cer.e083.fir', function () {
                var variable = $scope.certificate.group1;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e083.sec',function () {
                var variable = $scope.certificate.group2;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e083.oth',function () {
                var variable = $scope.certificate.other;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e083.res',function () {
                var variable = $scope.certificate.limitations;
                return typeof variable !== 'undefined' && variable !== null && variable.length > 0;
            }),
            new AsideBlockModel('cer.e083.ext',function () {
                return $scope.certificate.bloodGroup || $scope.certificate.remarks
            }),

		]
	};
}]);