import {Component, Input, OnInit} from "@angular/core";
import {forEach} from "angular";
import {Router} from "@angular/router";
import {DocumentTagEnum} from "../../../shared/models/document-tag.enum";

@Component({
    selector: 'order-select',
    templateUrl: 'order-select.component.html'
})
export class OrderSelectComponent implements OnInit {
    protected readonly DocumentTagEnum = DocumentTagEnum;
    @Input() parentModalId: string;
    @Input() parentModalCloseId: string;
    orderSelectModalId: string = "orderSelect";
    orderSelectModalCloseId: string = "orderSelectClose";
    createDialogErr = {msg: null};
    documentType;
    orderList = [];
    orderType;
    encounterId;
    patientId;
    title;
    orderLoadMoreTitle;
    selected = {orders: [], order: null};
    orderListOps = {filtered: null};
    filterPaths: string[] = [
        'documentTitle',
        'item.patData.sampleCollectionMethod.name',
        'item.date|datetime',
        'item.date|datetime',
        'documentDate|datetime',
        'item.practitioner.givenName',
        'item.practitioner.familyName',
        'organization.name'
    ];
    ordersLimit: number = 7;
    searchQuery: any;
    parentClose: HTMLElement;
    childClose: HTMLElement;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    openModal(title: string, orderLoadMoreTitle: string, documentType: string, orderType: number, encounterId: string, patientId: string, orders: [], selectedOrder?: string) {
        const childModalElement = document.getElementById(this.orderSelectModalId);
        const childModal = new bootstrap.Modal(childModalElement);
        this.parentClose = document.getElementById(this.parentModalCloseId);
        this.childClose = document.getElementById(this.orderSelectModalCloseId);

        if (childModalElement) {
            this.title = title;
            this.documentType = documentType;
            this.orderList = orders;
            this.orderType = orderType;
            this.encounterId = encounterId;
            this.patientId = patientId;
            this.orderLoadMoreTitle = orderLoadMoreTitle;
            this.selected.order = selectedOrder

            this.parentClose.click()
            childModal.show();
        }
    }

    reduce(memo, val) {
        return memo || val;
    }

    isValidSelection(orderType) {
        return (orderType === '4' && this.selected.orders && _.reduce(_.values(this.selected.orders), this.reduce, false))
            || (orderType !== '4' && this.selected.order && this.selected.order.item);
    };

    createOrder() {
        const documentType = this.documentType;
        const orderType = this.orderType;

        this.createDialogErr = {msg: null};

        if (this.isValidSelection(orderType)) {
            const queryParams: any = {
                encounterId: this.encounterId ? this.encounterId : this.selected.order.encounterId,
                orderId: this.selected.order.item.id,
                parentDocumentId: this.selected.order.compositionId
            };

            if (documentType === DocumentTagEnum.e027 && orderType == '4') {
                const orders = [];
                forEach(_.keys(this.selected.orders), function (val) {
                    if (this.selected.orders[val]) {
                        const start = val.indexOf('/') + 1;
                        const end = val.indexOf('/', start);
                        orders.push(val.substring(start, end));
                    }
                });
                queryParams.orderId = orders;
                queryParams.orderType = 4;
            }

            const url: string = `/dp/patients/${this.patientId}/documents/new/${documentType}`
            this.router.navigate([url], {queryParams: queryParams});

            this.parentClose.click();
            this.childClose.click();
        } else {
            this.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas siuntimas.';
        }
    }

    isMoreOrders(): boolean {
        return this.ordersLimit < this.orderListOps.filtered;
    }

    loadMoreOrders() {
        this.ordersLimit += 7;
    }
}