/*Deprecated service, now using downgraded version of MessageService*/
ng1App.service('DeprecatedMessageService',
    ['$rootScope', '$filter',
     function ($rootScope, $filter) {
  this.messages = {'global': [], 'global_error': []};

  function surviveMessages(current){
      var survived = [];
      _.each(current, function(m){
          if (m && m.options && m.options.surviveOnce) {
              m.options.surviveOnce = false;
              survived.push(m);
          }
      });
      if (survived.length > 0) {
          console.debug('survived:', survived);
      }
      return survived;
  }

  this.clearAll = function () {
    console.debug("Clear all");
    this.messages = {'global': surviveMessages(this.messages.global), 'global_error': surviveMessages(this.messages.global_error)};
  };

  $rootScope.successEvent = 0 ;

  this.clear = function (region) {
      console.debug("Clear");
    if (!region) {
      region = 'global';
    }
    this.messages[region] = surviveMessages(this.messages[region]);
  };
  this.remove = function (arg, region) {
      console.debug("Remove");
    if (!region) {
      region = 'global';
    }
    if (_.isNumber(arg)) {
      this.messages[region].splice(arg, 1);
      return;
    }
    this.messages[region] = _.reject(this.messages[region], function (message) {
      return message === arg;
    });
  };
  this.handleError = function (messages,service){
        if (messages){
            var err = JSON.parse(messages.split("error:")[1]);
            angular.forEach(err, function(item){
                //FIXME fix this place because in angular foreach somehow scope is different and it can't see this.err('msg')
                service.error(item.msg);
            });
        }
        else {
            this.error('Klaida išsaugant dokumentą.');
        }
  };
  this.registerSuccessEvent = function (){
      $rootScope.successEvent = 1 ;
  };
  this.registerCancellationSuccessEvent = function (){
      $rootScope.successEvent = 2 ;
  };
  this.registerBlockingEvent = function () {
	  $rootScope.successEvent = 3;
  };
  this.registerUnblockingEvent = function () {
	  $rootScope.successEvent = 4;
  };
  this.registerActionSuccessEvent = function () {
	  $rootScope.successEvent = 5;
  };
  this.registerEncounterCloseSuccessEvent = function () {
      $rootScope.successEvent = 6;
  };
  this.registerEncounterPartialSaved = function (msg){
      $rootScope.successEvent = 7 ;
      $rootScope.errorMessage = msg ;
  };
    this.registerSuccessEventWithWarning = function (msg){
        $rootScope.successEvent = 8 ;
        $rootScope.warningMessage = msg ;
    };
  this.unregisterSuccessEvent = function(){
      $rootScope.successEvent = 0 ;
  };
  this.showSuccessEventMessage = function (){
      console.debug("showSuccessEventMessage");
      switch ($rootScope.successEvent) {
	case 1:
		this.success('Įrašas sėkmingai išsaugotas ESPBI IS.');
		break;
	case 2:
		this.success('Įrašas atšauktas sėkmingai.');
		break;
	case 3:
		this.success('Įrašas sėkmingai užblokuotas');
		break;
	case 4:
		this.success('Įrašo blokavimas sėkmingai pašalintas');
		break;
	case 5:
		this.success('Veiksmas sėkmingai atliktas');
		break;
    case 6:
        this.success('Apsilankymas sėkmingai uždarytas');
        break;
    case 7:
        this.success('Apsilankymas sėkmingai užregistruotas');
        if($rootScope.errorMessage){
            this.error($rootScope.errorMessage);
            $rootScope.errorMessage = undefined;
        }
        break;
    case 8:
        this.success('Įrašas sėkmingai išsaugotas ESPBI IS.');
        if($rootScope.warningMessage){
            this.warning($rootScope.warningMessage);
            $rootScope.warningMessage = undefined;
        }
        break;
	}
  };
  this.error = function (text, arg1, arg2) {
    this.add("error", text, arg1, arg2);
  };
  this.warning = function (text, arg1, arg2) {
    this.add("warning", text, arg1, arg2);
  };
  this.success = function (text, arg1, arg2) {
    this.add("success", text, arg1, arg2);
  };
  this.info = function (text, arg1, arg2) {
    this.add("info", text, arg1, arg2);
  };
  this.add = function (type, text, arg1, arg2) {
    var options, region = 'global';
    if (arg2) {
      region = arg2;
    }
    if (_.isObject(arg1)) {
      options = arg1;
    } else if (_.isString(arg1)) {
      region = arg1;
    }

    var errorTime = '';
    if(type === "error"){
      errorTime = '[' + $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss') + '] ';
    }

    var newItem = {
      type: type,
      errorTime: errorTime,
      text: errorTime + text,
      getHtml: function () {
        return errorTime + text;
      },
      options: options
    };

    if (!this.messages[region]) {
      this.messages[region] = [];
    }
    this.messages[region].push(newItem);
    console.debug("Žinutė:" + newItem.text);
  };


}]);