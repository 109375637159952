import { Component, ViewChild } from "@angular/core";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouteParams } from "../../../../ng1.routeParams";
import { UterineGrowthCurve } from "../../models/uterineGrowthCurve";
import { ColDef } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { debounceTime, fromEvent } from "rxjs";
import { TranslatePipe } from "@ngx-translate/core";
import moment from "moment";

@Component({
    selector: "app-uterine-growth-curve",
    templateUrl: "./uterine-growth-curve.component.html",
    providers: [TranslatePipe]
})
export class UterineGrowthCurveComponent {
    @ViewChild(AgGridAngular) agGrid: AgGridAngular;

    data: UterineGrowthCurve[];

    colDefs: ColDef[] = [
        {
            field: "date",
            headerName: this.translate.transform('pp.dia.sea.dat'),
            headerClass: 'border-end border-bottom text-wrap text-center',
            cellClass: 'text-wrap lh-base d-flex align-items-center justify-content-center py-2 border-bottom border-end',
            flex: 1,
            valueGetter: (params) => moment(params.data.date).format('YYYY-MM-DD')
        },
        {
            field: "distance",
            headerClass: 'border-bottom text-wrap text-center',
            cellClass: 'text-wrap lh-base d-flex align-items-center justify-content-center py-2 border-bottom',
            flex: 1,
            headerName: this.translate.transform('doc.pregnancyDashboard.uterineDiagram.yAxis'),
        },
        {
            field: "week",
            headerClass: 'border-end border-bottom text-wrap text-center',
            cellClass: 'text-wrap lh-base d-flex align-items-center justify-content-center py-2 border-bottom border-end',
            flex: 1,
            headerName: this.translate.transform('doc.pregnancyDashboard.uterineDiagram.xAxis'),
        },
        {
            field: "doc",
            headerClass: 'border-bottom text-wrap text-center',
            cellClass: 'text-wrap lh-base d-flex align-items-center justify-content-center py-2 border-bottom',
            headerName: this.translate.transform('doc.pregnancyDashboard.uterineDiagram.fillColumn'),
            flex: 2,
            valueGetter: (params) => {
                return `${params.data.clinic} | ${params.data.post} | ${params.data.doctor} | ${moment(params.data.fillDate).format('YYYY-MM-DD HH:mm:ss')}`
            },
            autoHeight: true
        },
    ];
    defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        cellStyle: {
            textAlign: 'center',
        },
    };

    private patientId: string;

    constructor(
        private apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private translate: TranslatePipe
    ) {
        this.getPatientId();
        this.apiV2PregnancyDashboard.getUterineGrowthCurveData(this.patientId).pipe(takeUntilDestroyed())
            .subscribe(res => this.loadData(res));
        fromEvent(window, 'resize')
            .pipe(debounceTime(200), takeUntilDestroyed())
            .subscribe(() => this.fitTable());
    }

    getPatientId(){
        this.patientId = this.params.patientId;
    }

    private loadData(data: UterineGrowthCurve[]) {
        this.data = data;
        this.agGrid?.api.setRowData(data);
        this.agGrid?.api.sizeColumnsToFit();
    }

    private fitTable() {
        this.agGrid?.api.sizeColumnsToFit();
    }
}
