import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-practitioner-link',
    templateUrl: './practitioner-link.component.html',
})
export class PractitionerLinkComponent implements OnInit {
    @Input() practitioner: any;
    @Input() qualification: any;
    @Input() newTab: string | boolean;

    constructor() {
    }

    ngOnInit(): void {
    }
}