import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin, map, Observable, Observer, of, switchMap} from 'rxjs';
import {EntityModel} from '../../../../../../shared/models/entity.model';
import {generateSelectionValuesFromEntity} from '../../../e096/e096-utils/utils';
import {ApiV2Entities} from '../../../../../../shared/services/api-v2-entities';
import {RiskFactorModel} from "../../../../../models/risk-factor.model";
import moment from "moment";

@Component({
    selector: 'app-e030-psychiatric-risk-factors',
    templateUrl: './e030-psychiatric-risk-factors.component.html',
})
export class E030PsychiatricRiskFactorsComponent implements OnInit {
    @Input() editable: boolean = true;
    answersVariants: { [key: string]: EntityModel[] };
    currentTime = new Date();
    formGroup: FormGroup;
    healthRisksSuggestions$: Observable<RiskFactorModel[]>;
    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities
    ) {
        forkJoin({
            fetalPrimordial: this.apiV2Entities.getEntitiesList('fetal-primordial'),
            childbirthMedicalPainManagement: this.apiV2Entities.getEntitiesList('childbirth-medical-pain-management'),
            cardiotocogramResults: this.apiV2Entities.getEntitiesList('cardiotocogram-results'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            psychiatricRiskFactor: this.fb.control(null, [Validators.required]),
            determinationDate: this.fb.control(null, [Validators.required]),
            patientConsent: this.fb.control(null, [Validators.required]),
            nameOfMethodology: this.fb.control(null),
            assessmentOfRiskFactors: this.fb.control(null),
            assessmentDescription: this.fb.control(null)
        });
        this.initializeHealthRisksSuggestions();
    }

    initializeHealthRisksSuggestions() {
        this.healthRisksSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("psychiatricRiskFactor").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Entities.getEntitiesList('health-risk-factor', {searchCriteria: query})
                        .pipe(map((data) => data || []));
                }
                return of([]);
            })
        );
    }

    protected readonly moment = moment;
}
