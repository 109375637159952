<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Nt.formExamination.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto req">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold col-form-label">
                            {{ 'doc.pregnancyDashboard.e096Nt.formExamination.vaginalExamination' | translate }}
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of examinationOptions; let i = index"
                        radioName="vaginalExamination-{{i}}"
                        [fControl]="researchFormControl"
                        [radioValue]="option.name"
                        id="vaginalExamination-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="researchFormControl" />
            </div>
        </div>

        <div class="row mx-auto req">
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-date-picker
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.investigationDate' | translate}}:"
                    [labelClass]="!controlRequired('investigationDate') ? 'norq' : ''"
                    inputId="regularContractions"
                    suffixIcon="fa fa-calendar"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [withTimepicker]="true"
                    [fControl]="formGroup1.get('investigationDate')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup1.get('investigationDate')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1 h-100 d-flex flex-column">
                        <span
                            class="form-label flex-grow-1 col-form-label mt-3 fw-bold"
                            [class.norq]="!controlRequired('investigationMethod')"
                        >
                            {{ 'doc.pregnancyDashboard.e096Nt.formExamination.investigationDone' | translate }}:
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of answersVariants?.['vaginalExaminationMethod']; let i = index"
                        radioName="investigationMethod-{{i}}"
                        [fControl]="formGroup1.get('investigationMethod')"
                        [radioValue]="option.name"
                        id="investigationMethod-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="formGroup1.get('investigationMethod')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1 h-100 d-flex flex-column">
                        <span
                            class="form-label flex-grow-1 col-form-label mt-3 fw-bold"
                            [class.norq]="!controlRequired('cervix')"
                        >
                            {{ 'doc.pregnancyDashboard.e096Nt.formExamination.cervix' | translate }}:
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of answersVariants?.['locationOfTheCervix']; let i = index"
                        radioName="cervix-{{i}}"
                        [fControl]="formGroup1.get('cervix')"
                        [radioValue]="option.name"
                        id="cervix-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="formGroup1.get('cervix')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-select
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.consistencyOfCervix' | translate}}:"
                    id="cervixConsistency"
                    [fControl]="formGroup1.get('cervixConsistency')"
                    [values]="selectionValuesFromEntity(answersVariants?.['consistencyOfTheCervix'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup1.get('cervixConsistency')" />
            </div>
        </div>

        <div class="row mx-auto req">
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-input
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.cervicalLength' | translate}}:"
                    [labelClass]="!controlRequired('cervicalLength') ? 'norq' : ''"
                    units="cm"
                    type="number"
                    [fControl]="formGroup1.get('cervicalLength')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup1.get('cervicalLength')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-input
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.openingOfTheCervix' | translate}}:"
                    [labelClass]="!controlRequired('openingOfTheCervix') ? 'norq' : ''"
                    units="cm"
                    type="number"
                    [fControl]="formGroup1.get('openingOfTheCervix')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup1.get('openingOfTheCervix')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-select
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.maturityOfTheCervix' | translate}}:"
                    [labelClass]="!controlRequired('maturityOfTheCervix') ? 'norq' : ''"
                    id="maturityOfTheCervix"
                    [fControl]="formGroup1.get('maturityOfTheCervix')"
                    [values]="selectionValuesFromEntity(answersVariants?.['cervixMaturity'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup1.get('maturityOfTheCervix')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-select
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.childbirthPeriod' | translate}}:"
                    [labelClass]="!controlRequired('childBirthPeriod') ? 'norq' : ''"
                    id="childBirthPeriod"
                    [fControl]="formGroup1.get('childBirthPeriod')"
                    [values]="selectionValuesFromEntity(answersVariants?.['childbirthPeriod'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup1.get('childBirthPeriod')" />
            </div>
        </div>

        <div class="row mx-auto border-top border-bottom border-end border-start py-3 mt-3">
            <h6>{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.subTitle' | translate}}</h6>

            <div class="row align-items-center gap-2 flex-nowrap h-100 req mx-0 px-0">
                <div class="form-group col-6 col-lg-3 flex-shrink-1">
                    <app-select
                        labelText="doc.pregnancyDashboard.e096Nt.formExamination.fruitName"
                        [labelClass]="!controlRequired('fruitName') ? 'norq' : ''"
                        id="fruitName"
                        [fControl]="formGroup.get('fruitName')"
                        [values]="fruitsList"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('fruitName')" />
                </div>
                <div class="form-group col-6 col-lg-3 flex-shrink-1">
                    <app-select
                        labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.fruitForerunner' | translate}}:"
                        [labelClass]="!controlRequired('forerunner') ? 'norq' : ''"
                        id="forerunner"
                        [fControl]="formGroup.get('forerunner')"
                        [values]="selectionValuesFromEntity(answersVariants?.['fetalPrimordial'])"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('forerunner')" />
                </div>
                <div class="form-group col-6 col-lg-3 flex-shrink-1">
                    <app-select
                        labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.fetalCoverings' | translate}}:"
                        [labelClass]="!controlRequired('fetalCoverings') ? 'norq' : ''"
                        id="fetalCoverings"
                        [fControl]="formGroup.get('fetalCoverings')"
                        [values]="selectionValuesFromEntity(answersVariants?.['fetalCoverings'])"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('fetalCoverings')" />
                </div>
                <div class="form-group col-6 col-lg-3 flex-shrink-1">
                    <app-select
                        labelText="{{'doc.pregnancyDashboard.e096Nt.formExamination.waters' | translate}}:"
                        [labelClass]="!controlRequired('waters') ? 'norq' : ''"
                        id="waters"
                        [fControl]="formGroup.get('waters')"
                        [values]="selectionValuesFromEntity(answersVariants?.['fetalFluidColor'])"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('waters')" />
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>