import {Environment} from "./environment-properties";

/**
 * The common environment properties for all portal types.
 */
export const mainEnvironment: Environment.Properties = {
    production: false,

    defaultLanguage: "lt",

    apiUrl: "/api",

    ipasasUrl: null,
    iprUrl: "https://ipr-mokymai.esveikata.lt/authentications/sso",
    ssoUrl: "http://localhost:4200/espbi-sso",
    footerLinks: {
        siteTree: "http://www.esveikata.lt/svetaines-medis",
        privacyPolicy: "http://www.esveikata.lt/privatumo-politika",
        help: "http://www.esveikata.lt/pagalba",
        contacts: "http://www.esveikata.lt/kontaktai"
    },
    sveidraLinks: {
        apapUrl: "http://sveidra-test.vlk.lt/forms/frmservlet?config=apap_test",
        spapUrl: "http://sveidra-test.vlk.lt/forms/frmservlet?config=spap_test"
    },
    medvaisReportsUrl: null,
    allowPatientCreateRepresentations: true,
    allowPatientOrderCertificates: true,
};

/**
 * The environment properties overridden for portal type
 */
export const environments = new Map<Environment.PortalType, Environment.Properties>([
    [Environment.PortalType.ADMIN, {
        ...mainEnvironment,
        ...{
            apiUrl: "/api/admin",
            medvaisReportsUrl: "http://mmedvais-report1.esveikata.lan:8080/WebViewerExample/"
        }
    }],
    [Environment.PortalType.DOCTOR, {
        ...mainEnvironment,
        ...{
            apiUrl: "/api/doctor"
        }
    }],
    [Environment.PortalType.PATIENT, {
        ...mainEnvironment,
        ...{
            apiUrl: "/api/patient"
        }
    }],
    [Environment.PortalType.PHARMACIST, {
        ...mainEnvironment,
        ...{
            apiUrl: "/api/pharmacist"
        }
    }]
])