/**
 * Created by tomasz on 2014-12-05.
 */
var BASEDIR = '/templates/components/input';
var registerTypeaheadFns = function (scope, attrs, handler) {
    scope._multi = attrs.multi == true || attrs.multi == 'true';
    scope._placeholder = (attrs.placeholder && attrs.placeholder != '') ? attrs.placeholder : 'Įveskite paieškos užklausą';

    scope.searchTypeahed = handler;
    scope._displayValue = undefined;
    if (scope._multi) {
        if (!scope._targetModel || !scope._targetModel.unshift) {
            alert('model must be typeof Array');
            throw 'model must be typeof Array, actual: ' + scope._targetModel;
        }
        scope.searchTypeahedChange = function (val) {
            if (val && val.id) {
                scope._targetModel.unshift(val);
                scope._displayValue = undefined;
            }
        };
        scope.removeTypeaheadItem = function (index) {
            scope._targetModel.splice(index, 1);
        };
    } else {
        scope.searchTypeahedChange = function (val) {
            if (val == '') {
                scope._targetModel = null;
            } else if (val && (val.id || val.code)) {
                scope._targetModel = val;
                setTimeout(function () {
                    scope.$apply()
                }); // hack for buggy angular-strap typeahead
            }
        };
        scope.$watch(function (scope) {
            return scope._targetModel
        }, function () {
            if (scope._targetModel == null) {
                scope._displayValue = undefined;
            }
        });
    }
};

ng1App.directive('typeaheadPractitioners', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        templateUrl: BASEDIR + '/typeahead-practitioners.html',
        restrict: 'E',
        replace: false,
        scope: {
            _targetModel: '=model',
            withOrg: '@'
        },
        link: function (scope: any, element, attrs) {
            if (scope.withOrg == true || scope.withOrg == 'true') {
                registerTypeaheadFns(scope, attrs, TypeaheadSearchHandlers.PRACTITIONERS_ORG);
            } else {
                registerTypeaheadFns(scope, attrs, TypeaheadSearchHandlers.PRACTITIONERS);
            }
        }
    }
}]);
ng1App.directive('typeaheadPractitionerQualifications', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        templateUrl: BASEDIR + '/typeahead-practitioner-qualifications.html',
        restrict: 'E',
        replace: false,
        scope: {
            _targetModel: '=model'
        },
        link: function (scope, element, attrs) {
            registerTypeaheadFns(scope, attrs, TypeaheadSearchHandlers.PROFESSIONS_MEDICAL);
        }
    }
}]);
ng1App.directive('typeaheadOrganizations', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        templateUrl: BASEDIR + '/typeahead-organizations.html',
        restrict: 'E',
        replace: false,
        scope: {
            _targetModel: '=model'
        },
        link: function (scope, element, attrs) {
            registerTypeaheadFns(scope, attrs, TypeaheadSearchHandlers.ORGANIZATIONS);
        }
    }
}]);
ng1App.directive('typeaheadCls', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        templateUrl: BASEDIR + '/typeahead-cls.html',
        restrict: 'E',
        replace: false,
        scope: {
            _targetModel: '=model',
            clsClass: '@'
        },
        link: function (scope: any, element, attrs) {
            if (!TypeaheadSearchHandlers[scope.clsClass]) {
                alert('unsupported clsClass: ' + scope.clsClass);
                throw 'unsupported clsClass: ' + scope.clsClass;
            }
            registerTypeaheadFns(scope, attrs, TypeaheadSearchHandlers[scope.clsClass]);
        }
    }
}]);
ng1App.directive('editSimpleList', function () {
    return {
        require: 'ngModel',
        templateUrl: BASEDIR + '/edit_simple_list.html',
        restrict: 'E',
        replace: false,
        scope: {},
        link: function (scope: any, element, attrs, ctrl) {
            var items = [];

            ctrl.inputCtrl = scope.childCtrl;

            scope.items = function () {
                return items;
            };

            ctrl.$isEmpty = function (val) {
                return _.isEmpty(val);
            };

            ctrl.$formatters.push(function (val) {
                items = [];
                if (val) {
                    _.each(val, function (item) {
                        items.push(item);
                    });
                }
                return val;
            });

            scope.addItem = function () {
                if (scope.itemValue && !_.contains(items, scope.itemValue)) {
                    items.unshift(scope.itemValue);
                }
                scope.itemValue = undefined;

                ctrl.$setViewValue(items);
            };

            scope.removeItem = function (index) {
                items.splice(index, 1);
                ctrl.$setViewValue(items);
            };
        }
    }
});
ng1App.directive('ctrlToParent', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        replace: false,
        scope: {},
        link: function (scope: any, element, attrs, ctrl) {
            scope.$parent.childCtrl = ctrl;
        }
    }
});
ng1App.directive('errorStyle', 
    ['$log',
     function ($log) {
    return {
        restrict: 'A',
        require: '^form',
        scope: true,
        link: function (scope: any, element, attrs, formController) {
            scope.form = formController;

            var inputElement = element.find('input');

            if (inputElement.length == 0) {
                inputElement = element.find('textarea');
            }
            if (inputElement.length == 0) {
                inputElement = element.find('select');
            }
            var fieldName = 'form.' + (attrs.inputName || inputElement.attr('name'));

            if (!formController.$name) {
                $log.error('Cannot use error messages component, form does not have name');
            }
            scope.hasError = false;
            scope.isDirty = false;
            scope.isSubmitted = false;

            scope.$watch(fieldName + '.$invalid', function (hasError) {
                scope.hasError = hasError;
                scope.updateElementStyle();
            });

            scope.$watch(fieldName + '.$dirty', function (isDirty) {
                scope.isDirty = isDirty;
                scope.updateElementStyle();
            });

            scope.$watch('form.submitted', function (isSubmitted) {
                scope.isSubmitted = isSubmitted;
                scope.updateElementStyle();
            });

            scope.updateElementStyle = function () {
                if (scope.hasError && (scope.isDirty || scope.isSubmitted)) {
                    element.addClass('has-error');
                } else {
                    element.removeClass('has-error');
                }
            };

        }
    };
}]);
ng1App.directive('errorMessages', 
    ['$log',
     function ($log) {
    return {
        restrict: 'E',
        require: '^form',
        templateUrl: BASEDIR + '/error_messages.html',
        replace: false,
        scope: true,
        transclude: true,
        link: function (scope: any, element, attrs, formController) {
            scope.form = formController;
            scope.commonErrors = {};
            scope.commonErrors['required'] = 'err.required';
            scope.commonErrors['pattern'] = 'err.pattern';
            scope.commonErrors['minlength'] = 'err.minLength';
            scope.commonErrors['maxlength'] = 'err.maxLength';
            scope.commonErrors['float'] = 'err.float';


            scope.customErrors = {};
            scope.customErrors['integer'] = 'err.int.incorrect';
            scope.customErrors['validDate'] = 'err.date.incorrect';
            scope.customErrors['notFutureDate'] = 'err.date.future';
            scope.customErrors['notPastDate'] = 'err.date.past';
            scope.customErrors['valueIsEntity'] = 'err.valueIsEntity';
            scope.customErrors['positiveValue'] = 'err.positive';

            scope.userErrors = [];
            _.each(scope.$eval(attrs.errors), function (error) {
                if (error.error in scope.commonErrors) {
                    scope.commonErrors[error.error] = error.message;
                } else if (error.error in scope.customErrors) {
                    scope.customErrors[error.error] = error.message;
                } else {
                    scope.userErrors.push(error);
                }
            });

            var fieldName = 'form.' + attrs.inputName;

            scope.hasError = false;
            scope.isDirty = false;
            scope.isSubmitted = false;
            scope.inputError = null;

            if (!formController.$name) {
                $log.error('Cannot use error messages component, form does not have name');
            }
            scope.$watch(fieldName + '.$invalid', function (hasError) {
                scope.hasError = hasError;
            });

            scope.$watch(fieldName + '.$dirty', function (isDirty) {
                scope.isDirty = isDirty;
            });

            scope.$watch('form.submitted', function (isSubmitted) {
                scope.isSubmitted = isSubmitted;
            });

            scope.$watch(fieldName + '.$error', function (inputError) {
                scope.inputError = inputError;
            });

            scope.errorMessageKey = function () {
                if (!scope.inputError) {
                    return '';
                }

                // Check in custom errors
                for (let error in scope.customErrors) {
                    if (scope.inputError[error]) {
                        return scope.customErrors[error];
                    }
                }

                // Check in user errors
                if (scope.userErrors) {
                    for (var i = 0; i < scope.userErrors.length; i++) {
                        let error = scope.userErrors[i];
                        if (scope.inputError[scope.userErrors[i].error]) {
                            return scope.userErrors[i].message;
                        }
                    }
                }

                // Check in common errors
                for (let error in scope.commonErrors) {
                    if (scope.inputError[error]) {
                        return scope.commonErrors[error];
                    }
                }


                return '';
            };
        }
    };
}]);
ng1App.directive('entityList', 
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
    return {
        require: 'ngModel',
        templateUrl: BASEDIR + '/entity-list.html',
        restrict: 'E',
        replace: false,
        scope: {label: '=', placeholder: '='},
        link: function (scope: any, element, attrs, ctrl) {
            var items = [];
            var identifier = attrs.identifier ? attrs.identifier : 'code';

            scope.handler = TypeaheadSearchHandlers[attrs.handlerName];
            scope.name = attrs.name;

            scope.items = function () {
                return items;
            };

            ctrl.$isEmpty = function (val) {
                return _.isEmpty(val);
            };

            ctrl.$formatters.push(function (val) {
                items = [];
                if (val) {
                    _.each(val, function (item) {
                        items.push(item);
                    });
                }
                return val;
            });

            scope.addItem = function (val) {
                if (val && val[identifier]) {
                    var item = _.find(items, function (i) {
                        return val[identifier] == i[identifier];
                    });
                    if (!item) {
                        items.unshift(val);
                        scope.entity = undefined;
                        ctrl.$setViewValue(items);
                    }
                }
            };

            scope.removeItem = function (index) {
                items.splice(index, 1);
                ctrl.$setViewValue(items);
            };
        }
    }
}]);
ng1App.directive('fileInput', function () {
    return {
        require: 'ngModel',
        templateUrl: BASEDIR + '/file-input.html',
        restrict: 'E',
        replace: false,
        scope: {fileType: '=', onRemove: '&', onSelect: '&'},
        link: function (scope: any, element, attrs, ctrl) {

            scope.removeFile = function () {
                scope.fileSelected = false;
                scope.file = undefined;
                scope.fileName = undefined;
                ctrl.$setViewValue(scope.file);
                (scope.onRemove || angular.noop)();
            };

            scope.onFileSelect = function (file) {
                if (file) {
                    scope.file = file;
                    scope.fileName = undefined;
                    scope.fileSelected = true;
                    (scope.onSelect || angular.noop)();
                } else {
                    scope.fileSelected = true;
                }
                ctrl.$setViewValue(scope.file);
            };

            ctrl.$isEmpty = function (val) {
                return !scope.file;
            };
            ctrl.$formatters.push(function (val) {
                scope.file = val;
                if (val && val.name) {
                    scope.fileSelected = true;
                }
                return val;
            });
        }
    };
});

ng1App.directive('dateInput', 
    ['$compile',
     function ($compile) {
    return {
        templateUrl: BASEDIR + '/date-input.html',
        restrict: 'E',
        replace: false,
        scope: {ngModel: '=', ngRequired: '=', id: '@', inputClass: '@'},
        link: function (scope: any, element, attrs) {
//            scope.id = attrs.id ? attrs.id : '';
            scope.placeholder = attrs.placeholder;
            scope.title = attrs.title;
            scope.minDate = attrs.minDate;
            scope.maxDate = attrs.maxDate;
            if (angular.isUndefined(scope.inputClass)) {
                scope.componentClasses = ['me-2'];
            } else {
                scope.componentClasses = scope.inputClass.split(' ');
            }
            if (attrs.notPast == 'true') {
                var child = element.children().attr('not-past-date', '')
                $compile(child)(scope);
            }

            var observeMinDate = function (newValue) {
                scope.minDate = newValue;
            };
            attrs.$observe('minDate', observeMinDate);

            var observeMaxDate = function (newValue) {
                scope.maxDate = newValue;
            };
            attrs.$observe('maxDate', observeMaxDate);

            scope.$watch('ngModel', function (v) {
                if (scope.ngModel) {
                    var date = /^\d{4}-\d{2}-\d{2}/.exec(scope.ngModel);
                    if (date && date.length == 1) {
                        scope.ngModel = date[0];
                    }
                }
            });
        }
    };
}]);

ng1App.directive('dateTimeInput', function () {
    return {
        templateUrl: BASEDIR + '/date-time-input.html',
        restrict: 'E',
        replace: false,
        scope: {ngModel: '=', ngRequired: '=', id: '@'},
        link: function (scope: any, element, attrs) {
//            scope.id = attrs.id ? attrs.id : '';

            scope.$watch('ngModel', function (v) {
                if (scope.ngModel) {
                    if (/^\d{4}-\d{2}-\d{2}$/.exec(scope.ngModel)) {
                        scope.ngModel = scope.ngModel + 'T00:00:00';
                    }
                }
            });
        }
    };
});

ng1App.directive('timeInput', function () {
    return {
        templateUrl: BASEDIR + '/time-input.html',
        restrict: 'E',
        replace: false,
        scope: {ngModel: '=', ngRequired: '='},
        link: function (scope: any, element, attrs) {
            scope.id = attrs.id ? attrs.id : '';
        }
    };
});
//2020-04 AS add
ng1App.directive('dashToMinusOne', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elt, attrs, modelCtrl) {
            // conversion "view -> model"
            modelCtrl.$parsers.unshift(function (value) {
                if (value == '-') value = '-1';
                return value;
            })
            // conversion "model -> view
            modelCtrl.$formatters.unshift(function formatter(modelValue) {
                if (modelValue == '-1') modelValue = '-';
                return modelValue;
            })
        }
    }
});