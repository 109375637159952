<div>
    <h1>Paciento Veiklos ir namų aplinkos vertinimo istorija</h1>
    <back-link [path]="backPath" />
    <nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
        [title]="'Paciento Veiklos ir namų aplinkos vertinimo istorija' | translate"
        id="activityAndHomeEnvironmentHistory">
        <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid"
            [columnDefs]="activityAndHomeEnvironmentHistoryColDefs" [defaultColDef]="defaultColDef"
            [rowData]="activityAndHomeEnvironmentHistoryData$ | async" [rowSelection]="'multiple'"
            (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
            [domLayout]="'autoHeight'" [context]="getContext()" [gridOptions]="gridOptions" [pagination]="true"
            [paginationPageSize]="10" [suppressPaginationPanel]="true" />
    </nhr-record-widget>
    <ag-pagination-bar></ag-pagination-bar>
</div>