import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { NGNCaptionModel } from "../models/shared.model";

@Component({
    selector: "app-ngn-caption",
    templateUrl: "./ngn-caption.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgnCaptionComponent {
    @Input() captionData: NGNCaptionModel;
}
