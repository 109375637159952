<div class="mb-4 d-flex flex-column-reverse d-sm-block">
    <div class="float-sm-end">
        <!--            right-side header actions-->
    </div>
    <div>
        <h1 class="mb-1">{{'doc.aspn.healthAssessment.title' | translate}}</h1>
        <back-link [path]="backUrl" [subTitle]="subTitle" linkClass="text-decoration-none"/>
    </div>
</div>
<aspn-patient-health-assessments-forms />
