ng1App.controller('doctor.patient.cert.I.e048ViewCtrl', 
    ['$scope', 'AsideBlockModel',
     function ($scope, AsideBlockModel) {
    $scope._shared = {
        contentList: [
            new AsideBlockModel('cer.e048.pat'),
            new AsideBlockModel('cer.e048.ini'),
            new AsideBlockModel('cer.e048.doc'),
            new AsideBlockModel('cer.e048.har', function () {
                var variable = $scope.certificate.harmfulFactors;
                return typeof variable !== 'undefined' && variable !== null;
            })
        ]
    };
}]);