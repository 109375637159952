import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

// import {Modal} from "bootstrap";

@Component({
    selector: 'modal',
    templateUrl: 'modal.component.html'
})
export class ModalComponent implements OnInit {
    @Input() modalId: string;
    @Input() modalCloseId: string;
    @Input() modalSize: string = "modal-xl";
    @Input() parentModalId: string;
    @Input() title: string;
    @Input() isLoaded: boolean = true;
    @Input() hasFooterButtons: boolean = true;
    @Output() confirm = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {

    }

    confirmClick() {
        this.confirm.emit();
    }

    cancelClick() {
        const closeButton = document.getElementById(this.modalCloseId);
        if (closeButton) {
            closeButton.click();
            if (this.parentModalId) {
                const modalElement = document.getElementById(this.parentModalId);
                if (modalElement) {
                    const modal = new bootstrap.Modal(modalElement);
                    modal.show();
                }
            }
        }
    }
}