// Direktyva skirta atvaizduoti E003 arba E025 formos skyrimus
angular.module('erx.prs').directive('erxPrescriptionList', function () {
  return {
    restrict: 'E',
    scope: {options: '='},
    controller: ["$scope", "$rootScope", "$erxModalConfirm", "$location", "ErxPrescriptionsApiFactory", "ErxDocumentsApiFactory", "erxPrescriptionListConfig", function ($scope, $rootScope, $erxModalConfirm, $location, ErxPrescriptionsApiFactory, ErxDocumentsApiFactory, erxPrescriptionListConfig) {

      var submitToCardLoading = false;

      $rootScope.$on('ERX_SUBMIT_PENDING', function () {
        submitToCardLoading = true;
      });

      $rootScope.$on('ERX_SUBMIT_RESOLVED', function () {
        submitToCardLoading = false;
        $scope.getMedPrescriptions();
      });


      // Skyrimo šalinimas
      $scope.deleteMedPrescription = function (id) {
        $erxModalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {

          ErxPrescriptionsApiFactory.deleteMedPrescription({id: id}).$promise.then(function (res) {
            $scope.getMedPrescriptions();
          });
        });
      };

      // Skyrimų gavimas pagal kompozicijos ID
      $scope.getMedPrescriptions = function () {
        $scope.promise = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount({compositionId: $scope.options.getCompositionId()}, function (res) {
          $scope.prescriptions = res.result;
        });
      };

      $scope.submitToCard = function () {
        $scope.options.submitToCard();
      }

      // Ar skyrimų sąrašas yra kraunamas
      $scope.isLoading = function () {
        return !$scope.promise || !$scope.promise.$resolved;
      }

      $scope.canEditPrescription = function (status) {
        return angular.isDefined(status) && status === 'valid';
      };

      $scope.canDeletePrescription = function (status) {
        return !status || status === 'valid';
      }

      $scope.canViewPrescription = function (status) {
        return angular.isDefined(status) && status !== null;
      }

      $scope.canFillPrescription = function (status) {
        return !angular.isDefined(status) || status === null;
      }

      $scope.canViewPrescriptionDoc = function (tag) {
        return tag && (tag !== 'rni');
      }

      $scope.canSignPrescriptionDoc = function (docStatus, prescriptionStatus, tag, status) {
        return  (!docStatus ||
                (
                        docStatus !== 'Signed'
                        && docStatus !== 'entered in error'
                        && docStatus !== 'completed'
                        && docStatus !== 'stopped'
                        && docStatus !== 'on hold')
                )
                &&
                (!status ||
                        (
                                status !== 'entered in error'
                                && status !== 'completed'
                                && status !== 'on hold'
                                && status !== 'stopped'
                                )
                        )
                && prescriptionStatus === 'active'
                && tag === 'ier'
      }

      $scope.canEditPrescriptionDoc = function (status, prescriptionStatus) {
        return (!status || status === 'valid') && (prescriptionStatus != 'entered in error');
      }

      $scope.isSubmitToCardLoading = function () {
        return submitToCardLoading;
      }

      $scope.print = function () {
        $scope.options.openPrintPrescriptions();
      }

      $scope.selectAllForSign = function() {
        angular.forEach($scope.prescriptions, function (prs) {  
          if ($scope.canSignPrescriptionDoc(prs.metadata.docStatus, prs.prescriptionData.prescriptionStatus ? prs.prescriptionData.prescriptionStatus.code : null, 
          prs.prescriptionData.dispenseData.prescriptionTag, prs.prescriptionData.status ? prs.prescriptionData.status.code : null)) {
            prs.checkedForSign = true;
          }
        });
      }

      $scope.signButtonDisabled = function() {
        var ret = true;
        angular.forEach($scope.prescriptions, function (prs) {
          if (prs.checkedForSign) {
            ret = false;
          }
        });
        return ret;
      }

      $scope.signSelected = function() {
        var selectedIds = [];
        angular.forEach($scope.prescriptions, function(prs) {
          if (prs.checkedForSign) {
            selectedIds.push(prs.metadata.docId);
          }
        });
        //$location.path($scope.options.getSignPrescriptionUrl(0, selectedIds.join("$")));
        $location.path($scope.options.getSignPrescriptionUrl(0, '')).search('docIds', selectedIds.join("$"));
        //ErxDocumentsApiFactory.multisign({compositionIds: selectedIds, confirmUrl: $location.url()});
      }

      if (angular.isDefined($scope.options)) {
        angular.extend($scope.options, erxPrescriptionListConfig.options);
      } else {
        $scope.options = erxPrescriptionListConfig.options;
      }
      $scope.getMedPrescriptions();
    }],
    link: function ($scope, $element, $attrs) {
    },
    templateUrl:
            'modules/erx/prs/prescription_list/erxPrescriptionList.html'
  };
});
