<div>
    <h1>{{ "doc.mentalHealthComponent.title" | translate }}</h1>
    <!--<app-document-caption [captionData]="documentCaptionData"></app-document-caption>-->
    <app-common-patient-widget *ngIf="prefillData && editable" [modeModification]="true"
                               (valueChange)="onPatientWidgetChanges($event)"
                               [prefillData]="prefillData"></app-common-patient-widget>
    <app-encounter-block></app-encounter-block>
    <app-e030-psychiatric-risk-factors [editable]="editable"/>
    <app-e030-medical-data [editable]="editable"/>
    <app-e030-consultation-plan [editable]="editable"/>
    <app-e030-diagnoses-description [editable]="editable"/>
    <app-e030-treatment-applied [editable]="editable"/>
    <app-e030-recomendations [editable]="editable" [fromDoc]="fromDoc"/>
    <app-modification-actions *ngIf="editable" (sign)="signForm()"
                              (sealConfirm)="sealConfirmForm()"
                              (signingSubmit)="signingSubmitForm()"
                              (submit)="submitForm()"
                              (save)="saveForm()"
                              (clear)="clearForm()"
                              [signButtonDisabled]="true"
                              [sealConfirmButtonDisabled]="true"
                              [signingSubmitButtonDisabled]="true"
                              [submitButtonDisabled]="true">
    </app-modification-actions>
</div>