import { addDaysToDate } from "../../../../shared/services/utils/utils";

export enum FoilUsingNames {
    Until = "Iki pastojimo",
    OnGoing = "Pastojus",
    Over = "Didesnis kiekis",
}

export enum MenstrualCycles {
    Regular = "Reguliarus",
    Irregular = "Nereguliarus",
}

export enum FamilyAnamnesis {
    Diabetes = "Cukrinis diabetas",
    broadcastingDisadvantages = "Sklaidos trūkumai",
    Z63 = "Z63.0",
    Other = "Kita",
}

export enum WomanDiseases {
    CardiovascularPathology = "Širdies ir kraujagyslių patologija",
    Diabetes = "Cukrinis diabetas",
    UrologicalPathology = "Urologinė patologija",
    HypertensiveDisease = "Hipertoninė liga",
    Tuberculosis = "Tuberkuliozė",
    Others = "Kitos",
}

export enum Addictions {
    Smoking = "Rūko",
    AlcoholAbuses = "Piknaudžiauja alkoholiu",
    UsesDrugs = "Vartoja narkotikus",
}

export enum WomanAddictions {
    Smoking = "Rūkymas",
    PassiveSmoking = "Pasyvus rūkymas",
    AlcoholAbuses = "Alkoholio vartojimas",
    UsesDrugs = "Narkotikų vartojimas",
}

export const kmiIndicators = [
    {
        name: "Per mažas",
        numberFrom: 0,
        numberTo: 18.5,
    },
    {
        name: "Normalus",
        numberFrom: 18.6,
        numberTo: 24.9,
    },
    {
        name: "Antsvoris",
        numberFrom: 25,
        numberTo: 29.9,
    },
    {
        name: "Nutukimas",
        numberFrom: 30,
        numberTo: 99,
    },
];

export enum AuxiliaryProcedures {
    VacuumExtraction = "Vakuumekstrakcija",
    ForcepsExtraction = "Ekstrakcija replėmis",
    TurningFetusExtraction = "Vaisiaus apgręžimas ir ekstrakcija",
    AssistedDeliveryBreech = "Teikta pagalba, gimdant sėdmenų pirmeiga",
}

export enum ChildbirthModes {
    OnNaturalPaths = "Natūraliais takais",
    OnNaturalPathsUseAids = "Natūraliais takais, taikant pagalbines priemones",
    CesareanOperation = "Cezario pjūvio operacija",
}

export const foilUsing = [
    {
        label: FoilUsingNames.Until,
        controlName: "until",
    },
    {
        label: FoilUsingNames.OnGoing,
        controlName: "onGoing",
    },
    {
        label: FoilUsingNames.Over,
        controlName: "over",
    },
];

export const calculateLastWomansPeriodDate = (cycleDuration: number, lastMenstruationPeriod: string): Date => {
    let date: Date;

    if (cycleDuration && lastMenstruationPeriod) {
        const cycleDeviation = 28 - Number(cycleDuration);
        const daysToAdd = 280 - cycleDeviation;

        date = daysToAdd ? addDaysToDate(new Date(lastMenstruationPeriod), daysToAdd) : null;
    }

    return date;
};
