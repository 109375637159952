/**
 * @deprecated since migration to angular.
 * Now using dp-navigation.component.ts downgraded component.
 */
ng1App.directive('_dpNavigation', 
    ['LinkApiFactory', 'ssoUrl',
     function (LinkApiFactory, ssoUrl) {
    return {
        templateUrl: '/templates/portal/doctor/layout/dp-navigation.component.html',
        restrict: 'E',
        replace: true,
        link: function (scope: any) {
            scope.$on('setHelpPage', function (event, page) {
                scope.helpPage = page;
            });

            LinkApiFactory.getIPR(function (result) {
                scope.iprUrl = result.value;
            });

            scope.ssoUrl = ssoUrl;
        }
    };
}]);