/* global angular, app */

ng1App.controller('doctor.patient.alkFormsCtrl', 
    ['$scope', '$routeParams', '$location', 'MessageService', 'VlkFormCreationHelper', 'SearchController', 'SveidraApiFactory', 'DpSessionContext',
     function ($scope, $routeParams, $location, MessageService, VlkFormCreationHelper, SearchController,
                                                           SveidraApiFactory, DpSessionContext) {
    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope.filters = [
        // All
        {title: 'dpp.alk.for.rec.all', query: {}, count: null},
        // In progress
        {title: 'dpp.alk.for.rec.inp', query: {inProgress: true}, count: null},
        // Finished
        {title: 'dpp.alk.for.rec.fin', query: {finished: true}, count: null}
    ];

    $scope.searchCtrl = new SearchController({
        viewId: 'DPP_ALK_FORMS',
        count: 10,
        initForm: function () {
            return {
                filter: $scope.filters[0]
            };
        },
        searchApi: SveidraApiFactory.getList,
        showPageCounter: true});

    $scope.searchCtrl.formToQuery = function (form) {
        var query: any = {};
        query.patientId = $routeParams.patientId;
        switch (form._searchType) {
            case 'quick':
                break;
            case 'filter':
                angular.extend(query, form.filter.query);
                break;
        }
        return query;
    };

    $scope.filterChanged = function (newFilter) {
        $scope.searchCtrl.clear();
        $scope.searchCtrl._form.filter = newFilter;
        $scope.searchCtrl.searchFilter();
    };

    _.each($scope.filters, function (filter) {
        filter.query.patientId = $routeParams.patientId;
        SveidraApiFactory.getCount(filter.query, function (result) {
            filter.count = result.value;
        });
    });

    DpSessionContext.getSettings(function (data) {
        $scope.searchCtrl.setCount(data.count_per_page || 10);
        $scope.searchCtrl.searchLastOrAll();
    });

    var patientId = $routeParams.patientId;
    $scope.newFormModal = null;

    var documentSelected = function (documentId, formType) {
        $location.path('/dp/patients/' + patientId + '/aLK_forms/' + formType + '/new')
                .search({documentId: documentId});
    };

    // New form creation
    $scope.createNewForm = function () {
    	var newFormModal = VlkFormCreationHelper.getModal(patientId, documentSelected);
    	newFormModal.$promise.then(function () {
    		newFormModal.show();
    	});
    };

}]);