/* global app, _ */
ng1App.factory('AccessControllHelper', 
    ['$q', '$rootScope', 'practitionerTypesMarker', 'specialUserTypesMarker',
     function ($q, $rootScope, practitionerTypesMarker, specialUserTypesMarker) {

    var docTypeMap = {
        //docs
        e025: {code: '34108-1'},
        e027: {code: '57133-1'},
        e027a: {code: '11488-4'},
        e003: {code: '18842-5'},
        e200: {code: 'X-LAB-ORDER'},
        e200a: {code: '11502-2'},
        e063: {code: '11369-6'},
        e014: {code: 'X-PATH-ORDER'},
        e014a: {code: '11526-1'},
        //certs
        e027_1_I: {code: '171387006'},
        e027_1_II: {code: '274410002'},
        e103_1: {code: '444561001'},
        e083_1: {code: '270370004'},
        e049: {code: '184822001'},
        e106: {code: '307930005'},
        e048_employer: {code: '224406003'},
        e048_practitioner: {code: '171363009'},
        e047_employer: {code: '160922003'},
        e047_practitioner: {code: '275674000'},
        e106_2_1: {code: '270101008'},
        //other
        e027va: {code: '18748-4'},
        erec01: {code: '57833-6'},
        evai01: {code: '60593-1'},
        patient_summary: {code: '60591-5'}
    };

    var containsAll = function (list, values) {
        var result = true;
        angular.forEach(values, function (value) {
            result = result && _.contains(list, value);
        });
        return result;
    };

    var containsSome = function (list, values) {
        var result = false;
        angular.forEach(values, function (value) {
            if (_.contains(list, value)) {
                result = true;
            }
        });
        return result;
    };

    var containsNone = function (list, values) {
        var result = true;
        angular.forEach(values, function (value) {
            if (_.contains(list, value)) {
                result = false;
            }
        });
        return result;
    };

    var canRead = function (rule, params, partly) {
        params = params || [];

        if (params.length === 0) {
            if (rule.denyAll) {
                return partly ? (rule.read.length + rule.write.length > 0) : false;
            } else if (rule.readAll) {
                return partly ? true : rule.deny.length === 0;
            } else if (rule.writeAll) {
                return partly ? true : rule.deny.length === 0;
            } else {
                return partly ? (rule.read.length + rule.write.length > 0) : rule.deny.length === 0;
            }
        } else {
            var union = _.union(rule.read, rule.write);
            if (rule.denyAll) {
                return partly ? containsSome(union, params) : containsAll(union, params);
            } else if (rule.readAll) {
                return partly ? !containsAll(rule.deny, params) : containsNone(rule.deny, params);
            } else if (rule.writeAll) {
                return partly ? !containsAll(rule.deny, params) : containsNone(rule.deny, params);
            } else {
                return partly ? containsSome(union, params) : containsAll(union, params);
            }
        }
    };
    var canWrite = function (rule, params, partly) {
        params = params || [];

        if (params.length === 0) {
            if (rule.denyAll) {
                return partly ? (rule.write.length > 0) : false;
            } else if (rule.readAll) {
                return partly ? (rule.write.length > 0) : false;
            } else if (rule.writeAll) {
                return partly ? true : (rule.deny.length + rule.read.length === 0);
            } else {
                return partly ? (rule.write.length > 0) : (rule.deny.length + rule.read.length === 0);
            }
        } else {
            var union = _.union(rule.read, rule.deny);
            if (rule.denyAll) {
                return partly ? containsSome(rule.write, params) : containsAll(rule.write, params);
            } else if (rule.readAll) {
                return partly ? containsSome(rule.write, params) : containsAll(rule.write, params);
            } else if (rule.writeAll) {
                return partly ? !containsAll(union, params) : containsNone(union, params);
            } else {
                return partly ? containsSome(rule.write, params) : containsAll(rule.write, params);
            }
        }
    };

    var checkPolicy = function (component, params, policy, partly, action, all) {
        if (!$rootScope._accessRules.$resolved) {
            return false;
        }

        if (action && !$rootScope._accessRules.actions[action]) {
            return false;
        }

        if (params === practitionerTypesMarker && $rootScope.practitionerTypes) {
            if (!$rootScope.practitionerTypes.$resolved) {
                return false;
            }
            params = $rootScope.practitionerTypeCodes;
        } else if (params === specialUserTypesMarker && $rootScope.specialUserTypes) {
            if (!$rootScope.specialUserTypes.$resolved) {
                return false;
            }
            params = $rootScope.specialUserTypeCodes;
        }

        partly = (partly === undefined || partly === null) ? true : partly;
        var rule = action ? $rootScope._accessRules.actions[action][component] : $rootScope._accessRules.rules[component];
        if (!rule && $rootScope._accessRules && $rootScope._accessRules.$resolved) {
            console.error('Unknown component: ' + component);
            return false;
        } else if ($rootScope._accessRules && !$rootScope._accessRules.$resolved) {
            return false;
        }

        switch (policy.toLowerCase()) {
            case 'read':
                if (all && !rule.readAll) {
                    return false;
                }
                return canRead(rule, params, partly);
            case 'write':
                if (all && !rule.writeAll) {
                    return false;
                }
                return canWrite(rule, params, partly);
            default :
                console.error('Unknown policy: ' + policy);
                return false;
        }
    };

    var check = function (arg, policy, action, all?) {

        if (angular.isArray(arg)) {
            for (var i = 0; i < arg.length; i++) {
                arg[i].policy = arg[i].policy || policy;
                if (arg[i].component && arg[i].policy) {
                    if (checkPolicy(arg[i].component, arg[i].params || [], arg[i].policy, arg[i].partly, action, all)) {
                        return true;
                    }
                } else {
                    console.error('bad AC check argument', arg[i]);
                    return false;
                }
            }
        } else {
            arg.policy = arg.policy || policy;
            if (arg.component && arg.policy) {
                return checkPolicy(arg.component, arg.params || [], arg.policy, arg.partly, action, all);
            } else {
                console.error('bad AC check argument', arg);
                return false;
            }
        }
        return false;
    };

    var saveCheck = function (arg, policy, action) {

        var defer = $q.defer();
        var promise = defer.promise;

        var rulesWaiter;
        if ($rootScope.practitionerTypes && $rootScope.specialUserTypes) {
            rulesWaiter = $q.all([
                $rootScope._accessRules.$promise,
                $rootScope.practitionerTypes.$promise,
                $rootScope.specialUserTypes.$promise
            ]);
        } else {
            rulesWaiter = $rootScope._accessRules.$promise;
        }

        rulesWaiter.then(function () {
            if (check(arg, policy, action)) {
                defer.resolve();
            } else {
                defer.reject('FORBIDEN');
            }
        }, function () {
            defer.reject('FORBIDEN');
        });

        return promise;
    };

    return {
        canRead: function (arg, action) {
            return saveCheck(arg, 'read', action);
        },
        canWrite: function (arg, action) {
            return saveCheck(arg, 'write', action);
        },
        check: check,
        getDocTypeCode: function (docType) {
            var code = docTypeMap[docType];
            return code ? code.code : undefined;
        }
    };
}]);

ng1App.filter('acRead', 
    ['AccessControllHelper',
     function (AccessControllHelper) {
    return function (arg, params, partly, action) {
        if (angular.isObject(arg)) {
            return AccessControllHelper.check(arg, 'read', action);
        } else {
            return AccessControllHelper.check({component: arg, params: params, partly: partly}, 'read', action);
        }
    };
}]);

ng1App.filter('acWrite', 
    ['AccessControllHelper',
     function (AccessControllHelper) {
    return function (arg, params, partly, action) {
        if (angular.isObject(arg)) {
            return AccessControllHelper.check(arg, 'write', action);
        } else {
            return AccessControllHelper.check({component: arg, params: params, partly: partly}, 'write', action);
        }
    };
}]);

ng1App.filter('acWriteAll', 
    ['AccessControllHelper',
     function (AccessControllHelper) {
    return function (arg, params, partly, action) {
        if (angular.isObject(arg)) {
            return AccessControllHelper.check(arg, 'write', action, true);
        } else {
            return AccessControllHelper.check({component: arg, params: params, partly: partly}, 'write', action, true);
        }
    };
}]);

ng1App.filter('clsAcWrite', 
    ['AccessControllHelper',
     function (AccessControllHelper) {
    return function (clsList, component) {
        var list = [];
        angular.forEach(clsList, function (entity) {
            if (AccessControllHelper.check({component: component, params: [entity.code]}, 'write')) {
                list.push(entity);
            }
        });
        return list;
    };
}]);