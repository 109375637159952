import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: 'app-cancel-reason-modal',
    templateUrl: './cancel-reason-modal.component.html'
  })
  export class CancelReasonModalComponent {
    @Output() cancelReasonSubmitted = new EventEmitter<string>();
    cancelReasonForm: FormGroup;

    constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private modalRef: BsModalRef) {
        this.cancelReasonForm = this.formBuilder.group({
        reason: ['', [Validators.required]]
        });
  }

    submitReason() {
        if (this.cancelReasonForm.valid) {
          this.cancelReasonSubmitted.emit(this.cancelReasonForm.value.reason);
          this.bsModalRef.hide();
        }
      }

    closeModal() {
        this.modalRef.hide();
    }
  }