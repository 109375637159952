import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toggle-filters',
    templateUrl: './toggle-filters.component.html'
})
export class ToggleFiltersComponent implements OnInit {
    ngOnInit(): void {}
    constructor(private translate: TranslateService) {}
    
    filtersVisible = false;
    buttonText = 'Filtrai';
    toggle(): void {
        const hide = 'Suskleisti filtrus'
        const show = 'Filtrai'
        this.filtersVisible = !this.filtersVisible;
        this.buttonText = this.filtersVisible ? hide : show;
    }
}
