import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, tap } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { OrganizationModel } from "src/app/shared/models/organization.model";

interface OrganizationInterface {
    total: number;
    items: OrganizationModel[];
  }

@Injectable({
    providedIn: 'root',
})
export class ApiV2Organizations extends ConfigService {

    path = this.apiUrl + '/organizations';

    getOrganizations(value: string, count: number, hierarchyType: string ): Observable<OrganizationModel[]> {
        let params = new HttpParams();

        params = params.append('q', String(value+'*'));
        params = params.append('count: ', count);
        params = params.append('hierarchyType', hierarchyType);

        const url = `${this.path}/`;
        return this.http.get<OrganizationInterface>(url, {params}).pipe(map((data: OrganizationInterface) => data && data.items || []));
    }
}