import { AfterViewInit, Component, DestroyRef, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from "@angular/forms";
import { forkJoin, map } from 'rxjs';
import { ApiV2Documents } from "../../../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../../../ng1.routeParams";
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { DocumentTagEnum } from '../../../../../../shared/models/document-tag.enum';
import { HealthAssessmentsRecord } from '../../../../model/health-assessments';
import { DpAspnContextService } from "../../../../services/dp-aspn-context.service";
import { SbvfBodyTempFormComponent } from './sub-forms/sbvf-body-temp-form/sbvf-body-temp-form.component';
import { SbvfBreathSystemFormComponent } from './sub-forms/sbvf-breath-system-form/sbvf-breath-system-form.component';
import { SbvfCardioSystemFormComponent } from './sub-forms/sbvf-cardio-system-form/sbvf-cardio-system-form.component';
import {
    SbvfDigestiveSystemFormComponent
} from './sub-forms/sbvf-digestive-system-form/sbvf-digestive-system-form.component';
import { SbvfEntSystemFormComponent } from './sub-forms/sbvf-ent-system-form/sbvf-ent-system-form.component';
import { SbvfGeneralDataFormComponent } from './sub-forms/sbvf-general-data-form/sbvf-general-data-form.component';
import { SbvfJointsFormComponent } from './sub-forms/sbvf-joints-form/sbvf-joints-form.component';
import { SbvfLeatherFormComponent } from './sub-forms/sbvf-leather-form/sbvf-leather-form.component';
import { SbvfMeasurementsFormComponent } from './sub-forms/sbvf-measurements-form/sbvf-measurements-form.component';
import {
    SbvfRenalUrinarySystemFormComponent
} from './sub-forms/sbvf-renal-urinary-system-form/sbvf-renal-urinary-system-form.component';
import {
    SbvfSkeletalMuscularSystemFormComponent
} from './sub-forms/sbvf-skeletal-muscular-system-form/sbvf-skeletal-muscular-system-form.component';
import { SbvfVisualSystemFormComponent } from './sub-forms/sbvf-visual-system-form/sbvf-visual-system-form.component';
import { markControlsAsDirty } from '../../../../../../shared/services/utils/utils';


@Component({
    selector: "aspn-patient-health-assessments-forms",
    templateUrl: "./patient-health-assessments-forms.component.html",
})
export class PatientHealthAssessmentsFormsComponent implements AfterViewInit {
    @ViewChild(SbvfMeasurementsFormComponent) measurements: SbvfMeasurementsFormComponent;
    @ViewChild(SbvfGeneralDataFormComponent) generalData: SbvfGeneralDataFormComponent;
    @ViewChild(SbvfVisualSystemFormComponent) visualSystem: SbvfVisualSystemFormComponent;
    @ViewChild(SbvfEntSystemFormComponent) entSystem: SbvfEntSystemFormComponent;
    @ViewChild(SbvfBreathSystemFormComponent) breathSystem: SbvfBreathSystemFormComponent;
    @ViewChild(SbvfCardioSystemFormComponent) cardioSystem: SbvfCardioSystemFormComponent;
    @ViewChild(SbvfDigestiveSystemFormComponent) digestiveSystem: SbvfDigestiveSystemFormComponent;
    @ViewChild(SbvfRenalUrinarySystemFormComponent) renalUrinarySystem: SbvfRenalUrinarySystemFormComponent;
    @ViewChild(SbvfSkeletalMuscularSystemFormComponent) skeletalMuscularSystem: SbvfSkeletalMuscularSystemFormComponent;
    @ViewChild(SbvfJointsFormComponent) jointsSystem: SbvfJointsFormComponent;
    @ViewChild(SbvfBodyTempFormComponent) bodyTempSystem: SbvfBodyTempFormComponent;
    @ViewChild(SbvfLeatherFormComponent) leatherSystem: SbvfLeatherFormComponent;

    patientId: string;
    formId: string;
    viewMode: boolean;
    formGroup: FormGroup;
    loadedData: HealthAssessmentsRecord;
    answersVariants: { [key: string]: EntityModel[] };
    documentTag = DocumentTagEnum.sbvf;
    prefillData: any;

    constructor(
        private readonly params: RouteParams,
        private readonly dpAspnContextService: DpAspnContextService,
        private readonly destroyRef: DestroyRef,
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private apiV2Documents: ApiV2Documents
    ) {
        this.getEntities();
        this.getPatientId();
        this.loadDataForViewEdit();
        this.viewMode = window.location.href.includes('/view');
    }

    async ngOnInit(): Promise<void> {
        this.getPrefillData();
    }

    ngAfterViewInit(): void {
        this.formGroup = this.fb.group({
            measurements: this.measurements.formGroup,
            generalData: this.generalData.formGroup,
            visualSystem: this.visualSystem.formGroup,
            entSystem: this.entSystem.formGroup,
            breathSystem: this.breathSystem.formGroup,
            cardioSystem: this.cardioSystem.formGroup,
            digestiveSystem: this.digestiveSystem.formGroup,
            renalUrinarySystem: this.renalUrinarySystem.formGroup,
            skeletalMuscularSystem: this.skeletalMuscularSystem.formGroup,
            jointsSystem: this.jointsSystem.formGroup,
            bodyTempSystem: this.bodyTempSystem.formGroup,
            leatherSystem: this.fb.group({
                ...this.leatherSystem.formGroup.controls,
                risk: this.fb.group({ ...this.leatherSystem.subGroup.controls })
            }),
        });
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    save(): void {
        markControlsAsDirty(this.formGroup);
        console.log(this.formGroup)
        console.log(this.formGroup.value);
    }

    clear(): void {
        this.formGroup.reset(this.loadedData);
        this.formGroup.get('breathSystem.onsetOfCough').setValue(new Date(this.loadedData.breathSystem.onsetOfCough));
        this.formGroup.get('breathSystem.onsetShortnessBreath').setValue(new Date(this.loadedData.breathSystem.onsetShortnessBreath));
    }

    private getPatientId() {
        this.patientId = this.params.patientId;
        this.formId = this.params.id;
    }

    private loadDataForViewEdit(): void {
        if (!this.patientId || !this.formId) {
            return;
        }
        this.dpAspnContextService.getPatientHealthAssessment(this.patientId, this.formId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => {
                this.formGroup.patchValue(res);
                this.formGroup.get('breathSystem.onsetOfCough').setValue(new Date(res.breathSystem.onsetOfCough));
                this.formGroup.get('breathSystem.onsetShortnessBreath').setValue(new Date(res.breathSystem.onsetShortnessBreath));
                this.loadedData = { ...res };
            });
    }

    private getEntities(): void {
        forkJoin({
            somaticState: this.apiV2Entities.getEntitiesList('somatic-state-sbvf'),
            consciousness: this.apiV2Entities.getEntitiesList('consciousness-sbvf'),
            communication: this.apiV2Entities.getEntitiesList('communication-sbvf'),
            concomitantDiseases: this.apiV2Entities.getEntitiesList('concomitant-diseases-sbvf'),
            eyes: this.apiV2Entities.getEntitiesList('eyes-sbvf'),
            sclera: this.apiV2Entities.getEntitiesList('sclera-sbvf'),
            thereNo: this.apiV2Entities.getEntitiesList('there-no-sbvf'),
            eyeDrops: this.apiV2Entities.getEntitiesList('eye-drops-sbvf'),
            earLobe: this.apiV2Entities.getEntitiesList('ear-lobe-sbvf'),
            nasalDischarge: this.apiV2Entities.getEntitiesList('nasal-discharge-sbvf'),
            nasalMucosa: this.apiV2Entities.getEntitiesList('nasal-mucosa-sbvf'),
            oralMucosa: this.apiV2Entities.getEntitiesList('oral-mucosa-sbvf'),
            oralPlaque: this.apiV2Entities.getEntitiesList('oral-plaque-sbvf'),
            tongue: this.apiV2Entities.getEntitiesList('tongue-sbvf'),
            breathing: this.apiV2Entities.getEntitiesList('breathing-sbvf'),
            cough: this.apiV2Entities.getEntitiesList('cough-sbvf'),
            coughType: this.apiV2Entities.getEntitiesList('cough-type-sbvf'),
            coughTime: this.apiV2Entities.getEntitiesList('cough-time-sbvf'),
            yesNo: this.apiV2Entities.getEntitiesList('yes-no-sbvf'),
            scale: this.apiV2Entities.getEntitiesList('one-ten-sbvf'),
            natureOfPain: this.apiV2Entities.getEntitiesList('nature-of-pain-sbvf'),
            timeHeartPalpitations: this.apiV2Entities.getEntitiesList('time-heart-palpitations-sbvf'),
            dizzyTime: this.apiV2Entities.getEntitiesList('dizzy-time-sbvf'),
            appetite: this.apiV2Entities.getEntitiesList('appetite-sbvf'),
            swallowing: this.apiV2Entities.getEntitiesList('swallowing-sbvf'),
            feed: this.apiV2Entities.getEntitiesList('feed-sbvf'),
            belly: this.apiV2Entities.getEntitiesList('belly-sbvf'),
            nauseaTime: this.apiV2Entities.getEntitiesList('nausea-time-sbvf'),
            amountCalories: this.apiV2Entities.getEntitiesList('amount-calories-sbvf'),
            vomiting: this.apiV2Entities.getEntitiesList('vomiting-sbvf'),
            stomaType: this.apiV2Entities.getEntitiesList('stoma-type-sbvf'),
            defecation: this.apiV2Entities.getEntitiesList('defecation-sbvf'),
            releaseDrugs: this.apiV2Entities.getEntitiesList('release-drugs-sbvf'),
            painDefecation: this.apiV2Entities.getEntitiesList('pain-defecation-sbvf'),
            fecalIncontinence: this.apiV2Entities.getEntitiesList('fecal-incontinence-sbvf'),
            stoolConsistency: this.apiV2Entities.getEntitiesList('stool-consistency-sbvf'),
            urinaryStomaType: this.apiV2Entities.getEntitiesList('urinary-stoma-type-sbvf'),
            urinaryDisorder: this.apiV2Entities.getEntitiesList('urinary-disorder-sbvf'),
            catheterization: this.apiV2Entities.getEntitiesList('catheterization-sbvf'),
            diapers: this.apiV2Entities.getEntitiesList('diapers-sbvf'),
            placeParalysis: this.apiV2Entities.getEntitiesList('place-paralysis-sbvf'),
            muscularActivity: this.apiV2Entities.getEntitiesList('muscular-activity-sbvf'),
            agility: this.apiV2Entities.getEntitiesList('agility-sbvf'),
            jointMobility: this.apiV2Entities.getEntitiesList('joint-mobility-sbvf'),
            signsInflammation: this.apiV2Entities.getEntitiesList('signs-inflammation-sbvf'),
            roomTemp: this.apiV2Entities.getEntitiesList('room-temp-sbvf'),
            clothing: this.apiV2Entities.getEntitiesList('clothing-sbvf'),
            skinCondition: this.apiV2Entities.getEntitiesList('skin-condition-sbvf'),
            skinColor: this.apiV2Entities.getEntitiesList('skin-color-sbvf'),
            nails: this.apiV2Entities.getEntitiesList('nails-sbvf'),
            defectTypes: this.apiV2Entities.getEntitiesList('defect-types-sbvf'),
            wounds: this.apiV2Entities.getEntitiesList('wounds-sbvf'),
            skinAroundWounds: this.apiV2Entities.getEntitiesList('skin-around-wounds-sbvf'),
            signsWoundsInfection: this.apiV2Entities.getEntitiesList('signs-wounds-infection-sbvf'),
            exudation: this.apiV2Entities.getEntitiesList('exudation-sbvf'),
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => this.answersVariants = res);
    }
}
