import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "cancel-dialog",
    templateUrl: "./visitCancelDialog.component.html",
})
export class VisitCancelDialog implements OnInit {

    @Output() openCancelVisitNoteModal: EventEmitter<any> = new EventEmitter();

    visitId?: number;
    periodVisitId?: number;

    constructor(
        public modalService: BsModalService,
    ) { }

    ngOnInit(): void {
    }

    action(id: number) {
        this.openCancelVisitNoteModal.emit(id);
    }

}
