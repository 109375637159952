import { Component } from "@angular/core";
import { E096FormsNames } from './e096.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouteParams } from '../../../../ng1.routeParams';
import { ApiV2E096 } from '../../../../../api/api-v2-doc-ref-e096';
import { E096MedicalRecord } from '../../models/e096-medicalRecord.model';
import { forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
    E096PartTableModalComponent
} from './e096-part/e096-part-table/e096-part-table-modal/e096-part-table-modal.component';

@Component({
    selector: "app-e096",
    templateUrl: "./e096.component.html",
})
export class E096Component {
    public e096FormsNames = E096FormsNames;
    editable: boolean;
    private patientId: string;
    initialNTFormValue: E096MedicalRecord;
    initialPTKLFormValue: { [key: string]: any };
    initialNFormValue: { [key: string]: any };
    initialGEFormValue: { [key: string]: any };
    initialNAFormValue: { [key: string]: any };
    initialCPOFormValue: { [key: string]: any };
    initialPARTFormValue: { [key: string]: any };

    constructor(
        private params: RouteParams,
        private apiV2E096: ApiV2E096,
        private modalService: BsModalService

    ) {
        this.getPatientId();
        forkJoin({
            nt: this.apiV2E096.getE096FormView('nt', this.patientId),
            ptkl: this.apiV2E096.getE096FormView('ptkl', this.patientId),
            n: this.apiV2E096.getE096FormView('n', this.patientId),
            ge: this.apiV2E096.getE096FormView('ge', this.patientId),
            na: this.apiV2E096.getE096FormView('na', this.patientId),
            cpo: this.apiV2E096.getE096FormView('cpo', this.patientId),
            part: this.apiV2E096.getE096FormView('part', this.patientId),
        })
        .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.initialNTFormValue = res.nt;
                this.initialPTKLFormValue = res.ptkl;
                this.initialNFormValue = res.n;
                this.initialGEFormValue = res.ge;
                this.initialNAFormValue = res.na;
                this.initialCPOFormValue = res.cpo;
                this.initialPARTFormValue = res.part;
            });
    }


    getPatientId(){
        this.patientId = this.params.patientId;
        this.editable = !window.location.href.includes('/view');
    }

    openPartogram() {
        this.modalService.show(E096PartTableModalComponent, {
            animated: true,
            class: 'max-w-90 partogram-modal'
        });
    }
}
