import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {MedicalRecommendationsSummaryModel} from "../../../models/medical-recommendations-summary.model";

@Component({
    selector: 'medical-recommendations-summary',
    templateUrl: './medical-recommendations-summary.component.html'
})
export class MedicalRecommendationsSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() medicalRecommendations: MedicalRecommendationsSummaryModel;
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]);
    }
}