/* global app, angular, _ */

ng1App.controller('doctor.patient.PatientEditCtrl', 
    ['$scope', '$location', '$routeParams', '$q', '$window', 'SessionApiFactory', 'PatientsApiFactory', 'PatientUpdateService', 'EntitiesApiFactory', 'ClassifiersApiFactory', 'TypeaheadSearchHandlers', 'SubFormListController', 'MessageService', 'FormLoaderService', 'AccessRulesApiFactory',
     function ($scope, $location, $routeParams, $q, $window,
                                                              SessionApiFactory, PatientsApiFactory, PatientUpdateService, EntitiesApiFactory, ClassifiersApiFactory,
                                                              TypeaheadSearchHandlers, SubFormListController, MessageService, FormLoaderService, AccessRulesApiFactory) {

    MessageService.clearAll();
    FormLoaderService.startFormLoading();

    $scope.data = {patients: {}, addFile: true, lithuanianAddress: true};
    $scope.fromRegistry = false;
    $scope.patientId = $routeParams.patientId;

    $scope.patientTypes = [
        {code: 'FOREIGN', name: 'dpp.pat.mai.typ.for'},
        {code: 'NEWBORN', name: 'dpp.pat.mai.typ.new'},
        {code: 'UNKNOWN', name: 'dpp.pat.mai.typ.unk'}
    ];

    $scope.docTypes = EntitiesApiFactory.getList({classCode: 'identity-document-type', count: -1});
    $scope.sexTypes = EntitiesApiFactory.getList({classCode: 'administrative-gender'});
    $scope.maritalStatusTypes = EntitiesApiFactory.getList({classCode: 'marital-status'});

    $scope.agreementTypes = ClassifiersApiFactory.getAgreementsTypesForPatient({}, function (data) {
        if (data.total === 1) {
            $scope.data.agreementType = data.items[0];
        }
    });

    $scope.isEditable = function (key) {
        return !_.find($scope.data.importedData, function (val) {
            return val.propertyName === key;
        });
    };

    var fixSelectedEntities = function () {
        if ($scope.form.patient.sex) {
            $scope.form.patient.sex = _.find($scope.sexTypes, function (item) {
                return item.code === $scope.form.patient.sex.code;
            });
        }

        if ($scope.form.patient.maritalStatus) {
            $scope.form.patient.maritalStatus = _.find($scope.maritalStatusTypes, function (item) {
                return item.code === $scope.form.patient.maritalStatus.code;
            });
        }
    };

    // identity documents start
    var initIdentityDocsCtrl = function (docs) {
        $scope.idDocsCtrl = new SubFormListController(docs, 'identity-documents');
        $scope.idDocsCtrl.initForm = function () {
            this._data.tmpCountry = undefined;
            return {changed: true, deleted: false};
        };
        $scope.idDocsCtrl.validateForm = function (form) {
            if (!form.documentType) {
                this._validator.add('type');
            }
            if (!form.serieNumber) {
                this._validator.add('number');
            }

            return !this._validator.hasErrors();
        };

        $scope.idDocsCtrl.searchCountryChange = function (c) {
            if (c && c.code) {
                this._form.documentCountry = c;
            }
        };

        $scope.idDocsCtrl.after = function (action) {
            if (action === 'edit') {
                this._data.tmpCountry = _.getCodeName(this._form.documentCountry);
                var typeCOde = this._form.documentType.code;
                this._form.documentType = _.find($scope.docTypes, function (t) {
                    return t.code === typeCOde;
                });
            }
        };

        $scope.idDocsCtrl.handleDelete = function (index) {
            this._list[index].deleted = true;
        };

        $scope.idDocsCtrl.init();
    };
    // identity documents end

    /* agreements start */
    var valideAgreement = function (form) {
        this.clear();
        if (!form.documentType) {
            this._validator.add('type');
        }

        if (!form.description) {
            this._validator.add('description');
        }
        if ($scope.data.agreementType && $scope.data.agreementType.validity == 1 && (!form.validFrom || (form.validTo && form.validTo < form.validFrom))) {
            this._validator.add('period');
        }

        if ($scope.data.agreementType && $scope.data.agreementType.validity == 1 && !form.indefinite && !form.validTo) {
            this._validator.add('period');
        }

        if ($scope.data.agreementType && $scope.data.agreementType.additionalDoc == 1 && !this._data.files[form.fileKey]) {
            this._validator.add('file');
        }

        return !this._validator.hasErrors();
    };

    var onIndefiniteChange = function () {
        if (this._form.indefinite) {
            this._form.validTo = undefined;
        }
    };
    var onFileSelect = function (file) {
        if (file) {
            this._data.files[this._form.fileKey] = file;
        }
    };

    var removeAttachment = function () {
        this._data.files[this._form.fileKey] = undefined;
    };

    var newAgreement = function () {
        if (!this._data.fileKey) {
            this._data.fileKey = 1;
        }

        var form: any = {fileKey: this._data.fileKey++};
        SessionApiFactory.getSysdate({}, function (data) {
            form.validFrom = data.value;
        });
        if ($scope.data.agreementType) {
            form.documentType = {name: $scope.data.agreementType.title, code: $scope.data.agreementType.id};
        }
        SessionApiFactory.getSysdate({}, function (data) {
            form.dateCreated = data.value;
        });

        return form;
    };

    var cancelable = function (ag) {
        return !ag.canceled && ag.cancelable && ag.fullId;
    };

    var initAgreementsCtrl = function (agreementsList) {
        $scope.agreementsCtrl = new SubFormListController(agreementsList, 'agreement-region');
        $scope.agreementsCtrl._data.files = {};
        $scope.agreementsCtrl.initForm = newAgreement;
        $scope.agreementsCtrl.validateForm = valideAgreement;
        $scope.agreementsCtrl.onIndefiniteChange = onIndefiniteChange;
        $scope.agreementsCtrl.onFileSelect = onFileSelect;
        $scope.agreementsCtrl.removeAttachment = removeAttachment;
        $scope.agreementsCtrl.cancelable = cancelable;
        $scope.agreementsCtrl.before = function (action) {
            if (action === 'cancel' || action === 'edit') {
                this.clear();
            }
        };
        $scope.agreementsCtrl.after = function (action) {
            if (action === 'edit') {
                var form = this._form;
                form.indefinite = !form.validTo;
                $scope.data.agreementType = _.find($scope.agreementTypes.items, function (i) {
                    return i.id == form.documentType.code;
                });
            }
        };
        $scope.agreementsCtrl.onTypeChange = function () {
            $scope.agreementsCtrl._form.documentType = {name: $scope.data.agreementType.title, code: $scope.data.agreementType.id};
        };

        $scope.agreementsCtrl.filterRelatedPersons = function (persons) {
            return _.filter(persons, 
     function (p) {
                var person = $scope.person(p.patientRef);
                if (person && !person.deathDate) {
                    return p.patientRef;
                }
            });
        };
        $scope.agreementsCtrl.init();
    };
    /* agreements end */

    var reloadPatientForm = function (patientId) {
        FormLoaderService.startFormLoading();
        $scope.otherDocuments = PatientsApiFactory.getOtherDocuments({patientId: patientId, type: 'other'});
        $scope.form = PatientsApiFactory.getWithResources({id: patientId, forEdit: true});
        $scope.patientWithResources = $scope.form;

        $q.all([$scope.form.$promise, $scope.sexTypes.$promise, $scope.maritalStatusTypes.$promise, $scope.agreementTypes.$promise]).then(function () {
            if ($scope.form.insuranceDocs[0] && $scope.form.insuranceDocs[0].esInstitution) {
                $scope.data.esAutority = $scope.form.insuranceDocs[0].esInstitution;
                $scope.data.esAutority.label = $scope.data.esAutority.name;
            }

            if ($scope.form.patient.foreignAddress && $scope.form.patient.foreignAddress.country) {
                $scope.form.patient.foreignAddress.tmpCountry = $scope.form.patient.foreignAddress.country;
                $scope.form.patient.foreignAddress.tmpCountry.label = $scope.form.patient.foreignAddress.country.name;
            }

            initAgreementsCtrl($scope.form.agreements);
            initIdentityDocsCtrl($scope.form.identityDocs);
            fixSelectedEntities();

            if (!angular.equals($scope.form.patient.foreignAddress, {})) {
                $scope.data.lithuanianAddress = false;
            }
        }).finally(function () {
            FormLoaderService.endFormLoading();
        });
    };

    if ($scope.patientId) {
        $scope.$emit('setHelpPage', 'redaguoti-paciento-duomenis');
        reloadPatientForm($scope.patientId);
    } else {
        $scope.$emit('setHelpPage', 'pildyti-naujo-paciento-duomenis');
        $q.all([$scope.sexTypes.$promise, $scope.maritalStatusTypes.$promise, $scope.agreementTypes.$promise]).finally(function () {
            FormLoaderService.endFormLoading();
        });

        $scope.form = {
            type: $scope.patientTypes[0],
            patient: {
                agreements: [],
                relatedPersons: [],
                emails: [],
                phones: [],
                foreignAddress: {}
            },
            relatedPersons: [],
            insuranceDocs: [{}],
            agreements: [],
            identityDocs: []
        };
        initIdentityDocsCtrl($scope.form.identityDocs);
        $scope.agreementTypes.$promise.then(function () {
            initAgreementsCtrl($scope.form.agreements);
        });
    }

    $scope.partialPath = function (file) {
        return '/templates/portal/doctor/patient/patient/partials/' + file;
    };

    $scope.partialPathCommon = function (file) {
        return '/templates/portal/common/patient_info/partials/' + file;
    };

    $scope.isType = function (type) {
        return $scope.form.type && $scope.form.type.code === type;
    };

    $scope.person = function (fullRef) {
        var p = $scope.form.relatedPersons[fullRef];
        return p ? p : $scope.data.patients[fullRef];
    };

    $scope.searchCountry = TypeaheadSearchHandlers.COUNTRIES;

    $scope.searchEsAutority = TypeaheadSearchHandlers.ES_AUTORITY;
    $scope.searchEsAutorityChange = function (c) {
        if (c && c.code) {
            $scope.form.insuranceDocs[0].esInstitution = c;
        } else if (!c) {
            $scope.form.insuranceDocs[0].esInstitution = undefined;
        }
    };

    $scope.onFileSelect = function (file) {
        if (file) {
            $scope.data.file = file;
            $scope.form.patient.photoName = file.name;
        }
    };
    $scope.removePhoto = function () {
        $scope.form.patient.photoName = undefined;
        $scope.data.file = undefined;
    };

    //--> Save patient
    $scope.savePatient = function () {
        MessageService.clearAll();
        $scope.saving = true;
        PatientUpdateService.save($scope.form, $scope.data.file, $scope.agreementsCtrl._data.files, function (data) {
            $scope.$emit('patientWasEdited', data.patientId);
            $location.path('/dp/patients/' + data.patientId + '/view');
            $scope.saving = false;
            MessageService.registerSuccessEvent();
        }, function (error) {
            $scope.saving = false;
            if (error) {
                angular.forEach(error.fieldErrors, function (e) {
                    MessageService.error(e.propertyPath + ' ' + e.msg);
                });
                angular.forEach(error.globalErrors, function (e) {
                    MessageService.error( e.msg);
                });
                _.scrollToFirstInvalid();
                if(error.globalErrors){
       		     	$window.scrollTo(0, 10);
                }
                if (!error.fieldErrors && !error.globalErrors) {
                    _.scrollTo('#skip-link');
                }
            }
        });
    };

    $scope.loadingPatientData = false;
    $scope.checkPatient = function (personalCode, foreignPersonalCode) {
        MessageService.clear();

        if (!personalCode && !foreignPersonalCode) {
            MessageService.warning('Nenurodytas paciento asmens kodas');
            return;
        }

        FormLoaderService.startFormLoading();

        var updatePatientFromRegister = function (data) {
            if (data.patientId) {
                if ($scope.form.patient.id && $scope.form.patient.id === data.patientId) {
                    reloadPatientForm($scope.form.patient.id);
                } else if ($scope.form.patient.id && $scope.form.patient.id !== data.patientId) {
                    MessageService.error('Pacientas su nurodytu asmens kodu jau užregistruotas.');
                } else {
                    AccessRulesApiFactory.checkPermision({type: 'Patient', id: data.patientId}, function (data1) {
                        if (data1.value === 'WRITE' || data1.value === 'write') {
                            $location.path('/dp/patients/' + data.patientId + '/edit');
                        } else {
                            $location.path('/dp/patients/' + data.patientId + '/view');
                        }
                    }, function () {
                        $location.path('/dp/patients/' + data.patientId + '/view');
                    });
                }
            } else {
                MessageService.error('Asmuo nerastas Užsieniečių registre.');
            }
        };

        var onError = function (error) {
            angular.forEach(error.data.globalErrors, function (e) {
                MessageService.error(e.msg);
            });
        };

        var promise = PatientsApiFactory.updateFromRegistry({personalCode: personalCode, foreignPersonalCode: foreignPersonalCode, patientId: $scope.form.patient.id}, updatePatientFromRegister, onError).$promise;

        FormLoaderService.endFormLoading(promise);

    };

    $scope.lithuanianAddressChange = function () {
        if ($scope.data.lithuanianAddress) {
            $scope.form.patient.residenceAddress = {};
            $scope.form.patient.foreignAddress = null;
        } else {
            $scope.form.patient.residenceAddress = null;
            $scope.form.patient.foreignAddress = {};
        }
    };
}]);