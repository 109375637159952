angular.module('medvais.api').factory('MedvaisMedvaisApiFactory', ["$resource", function ($resource) {

    var URL_BASE = '/medvais-api/medvais';

    return $resource('', {}, {
        getMeddreamUrl: {
            method: 'GET',
            url: URL_BASE + '/meddream/url'
        },
        getOrganizationDevices: {
            method: 'GET',
            url: URL_BASE + '/devices?jarCode=:jarCode',
            isArray: true
        },
        getStudyInfo: {
            method: 'GET',
            url: URL_BASE + '/study?uid=:uid'
        }

    });


}]);


angular.module('medvais.api').service('MedvaisStudyUploader', ["$upload", "FormLoaderService", function ($upload, FormLoaderService) {
    var URL_BASE = '/medvais-api/medvais';

    this.upload = function (form, jar, displaySuccess, displayError) {
        $upload.upload({
            url: URL_BASE + '/upload/study',
            data: {dicom: form, jar: jar}
        }).progress(function (evt) {
            
        }).success(function (data, status, headers, config) {
            FormLoaderService.endFormLoading();
            (displaySuccess || angular.noop)();
        }).error(function () {
            FormLoaderService.endFormLoading();
            (displayError || angular.noop)();
        });
    };
    
    
}]);


angular.module('medvais.api').service('MedvaisSyncStudyUploader', ["$q", "$upload", "FormLoaderService", function ($q, $upload, FormLoaderService) {
    var URL_BASE = '/medvais-api/medvais';
    
    var promise = function( callback ) {
        var deferred = $q.defer();
        callback( deferred );
        return deferred.promise;
    };

    this.upload = function (form, jar, displaySuccess, displayError) {
        return promise( function( deferred ) {
            $upload.upload({
                url: URL_BASE + '/upload/study',
                data: {dicom: form, jar: jar}
            }).progress(function (evt) {
            }).success(function (data, status, headers, config) {
                FormLoaderService.endFormLoading();
                (displaySuccess || angular.noop)();
                deferred.resolve(data);
            }).error(function () {
                FormLoaderService.endFormLoading();
                (displayError || angular.noop)();
                deferred.reject();
            });
        });
    };

}]);

