angular.module('erx').directive('erxCategoryItem', function () {
  return {
    templateUrl: 'modules/erx/patient-history/categoryItem.html',
    replace: true, 
    restrict: 'E',
    scope: {
      label: '@',
      cat: '@',
      catClass: '@',
      count: '=',
      hideCondition: '=',
      performAction: '=',
    }
  };
});

