<div *ngIf="title?.length" class="custom-table-sub-header p-3 border-top fw-bolder fs-5">{{title}}</div>
<div class="row m-0 fs-5 border-bottom" *ngFor="let row of layout?.rows; let rowI = index"
     [class.border-top]="rowI === 0"
     [class.border-bottom-0]="rowI + 1 === layout.rows.length && noLastRowBorder"
     [formGroup]="row.formGroup"
>
    <div [class]="row.labelCellClass" class="d-flex align-items-center">{{row.label}}</div>
    <div *ngFor="let col of layout.sharedCells; let i = index" [class]="col.cellClass" class="text-center d-flex align-items-center justify-content-center position-relative py-2">
        <ng-container *ngIf="col.type === 'radioButton'">
            <!-- TODO: ADD PROPER ID!!! -->
            <app-radio-button
                    [id]="row.label + col.formControlName + i"
                    radioName="{{ row.label + col.formControlName }}"
                    formClass="mb-0"
                    [radioValue]="i.toString()"
                    labelText="{{row.label + ': ' + col.colName}}"
                    labelClass="position-absolute top-0 start-0 w-100 h-100 fs-0"
                    [radioDisabled]="!editable"
                    [fControl]="row.formGroup.get(col.formControlName)"
            />
        </ng-container>
        <ng-container *ngIf="col.type === 'inputText'">
            <app-input
                    [type]="'text'"
                    [fControl]="row.formGroup.get(col.formControlName)"
                    [value]="row.formGroup.get(col.formControlName).value"
                    [viewMode]="!editable"
                    [placeholder]="'doc.aspn.assessment.placeholder' | translate"
                    class="w-100"
                    inputClass="fs-5"
            ></app-input>
        </ng-container>
    </div>
</div>
