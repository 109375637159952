import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiV2Documents } from "../../../../api/api-v2-documents";
import { PatientModel } from "../../../shared/models/patient.model";
import { DocumentTagEnum } from "../../../shared/models/document-tag.enum";
import {DpSessionContext} from "../../services/dp-session-context.service";

@Component({
    selector: 'finished-document-select',
    templateUrl: 'finished-document-select.component.html'
})
export class FinishedDocumentSelectComponent implements OnInit {
    @Input() parentModalId: string;
    @Input() parentModalCloseId: string;
    @Input() patient: PatientModel;
    finishedDocumentSelectModalId: string = "finishedDocumentSelect";
    finishedDocumentSelectModalCloseId: string = "finishedDocumentSelectClose";
    quickSearchName: string = "finishedDocumentQuickSearch";
    createDialogErr = { msg: null };
    encounterId;
    title;
    selected = { id: null };
    from: number = 1;
    documentsLimit: number = 10;
    total: number;
    searchQuery: any;
    parentClose: HTMLElement;
    childClose: HTMLElement;
    documents: any[];
    form: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private apiV2Documents: ApiV2Documents,
        private dpMainController: DpSessionContext
    ) {
        this.form = this.formBuilder.group({});
    }

    ngOnInit(): void {
    }

    openModal() {
        const childModalElement = document.getElementById(this.finishedDocumentSelectModalId);
        const childModal = new bootstrap.Modal(childModalElement);
        this.parentClose = document.getElementById(this.parentModalCloseId);
        this.childClose = document.getElementById(this.finishedDocumentSelectModalCloseId);

        if (childModalElement) {
            this.encounterId = this.dpMainController.encounterDataSubject.getValue().id;
            this.apiV2Documents.getE025FromFinishedEncounters({ patient: this.patient.id, count: this.documentsLimit, page: this.from })
                .subscribe(data => {
                    this.documents = data.items;
                    this.total = data.total;
                });
            this.parentClose.click()
            childModal.show();
        }
    }

    continueDocument() {
        this.createDialogErr = { msg: null };
        if (this.selected?.id) {
            const queryParams: any = {
                encounterId: this.encounterId,
                continueTreatment: this.selected.id
            };

            const url: string = `/dp/patients/${this.patient.id}/documents/new/${DocumentTagEnum.e025}`
            this.router.navigate([url], { queryParams: queryParams });

            this.parentClose.click();
            this.childClose.click();
        } else {
            this.createDialogErr.msg = 'Dokumento pildyti negalima, nes nepasirinktas siuntimas.';
        }
    }

    loadMore() {
        this.documentsLimit += 10;
    }

    filterFinishedDocuments(count?: number, from?: number) {
        // TODO: there is no specific endpoint to search by entered text in getE025FromFinishedEncounters() endpoint. More research needed.
        const text: string = this.form.controls[this.quickSearchName].value ? this.form.controls[this.quickSearchName].value : "";
        const query: Object = {
            count: count ? count : this.documentsLimit,
            page: from ? from : this.from,
            ftq: text,
            patientId: this.patient.id
        };

        this.apiV2Documents.searchForListWithTotals(query)
            .subscribe(data => {
                this.documents = data.items;
                this.total = data.total;
            });
    }
}