ng1App.factory("RolePermissionsApiFactory", 
    ['$resource', 'apiUrl', 'RoleTypes',
     function ($resource, apiUrl, RoleTypes) {

  return _.reduce(RoleTypes.ALL_WITH_GROUP, function (api, type) {
    const typeApi = {};

    typeApi[type] = $resource('', {}, {
      get: {method: 'GET', url: apiUrl + '/roles/:type/:roleId/permissions/', params: {type: type}},
      assign: {method: 'POST', url: apiUrl + '/roles/:type/:roleId/permissions/list', params: {type: type}},
      create: {method: 'POST', url: apiUrl + '/roles/:type/:roleId/permissions/', params: {type: type}},
      update: {method: 'PUT', url: apiUrl + '/roles/:type/:roleId/permissions/:permissionId', params: {type: type}},
      remove: {method: 'DELETE', url: apiUrl + '/roles/:type/:roleId/permissions/:permissionId', params: {type: type}},

      getGroups: {method: 'GET', url: apiUrl + '/roles/:type/:roleId/groups/', params: {type: type}},
      getGroupsToAssign: {method: 'GET', url: apiUrl + '/roles/:type/:roleId/groups/list', params: {type: type}},
      assignGroups: {method: 'POST', url: apiUrl + '/roles/:type/:roleId/groups/', params: {type: type}},
      removeGroup: {method: 'DELETE', url: apiUrl + '/roles/:type/:roleId/groups/:groupId/', params: {type: type}},
    });

    return _.extend(_.extend(api, typeApi), {
      common: $resource('', {}, {
        getPermissionsToAssign: {method: 'GET', url: apiUrl + '/permissions/'},
        getRolesWhereAssigned: {method: 'GET', url: apiUrl + '/permissions/:permissionId/owners/'}
      })
    });
  }, {});

}]);