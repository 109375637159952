ng1App.factory('AspnContextFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/aspn';
    return $resource('', {}, {
        getLastQuestionaireByPatientId: {
            method: 'GET',
            url: URL_BASE + '/questionaire/patient/:patientId',
            isArray: false
        },
    });
}]);