import {Injectable} from "@angular/core";
import {ApiV2Encounters} from "./api-v2-encounters";
import {PromiseDecorator, toPromise} from "./utils/promise_utils";

@Injectable({
    providedIn: 'root'
})
export class ApiV2EncountersPromiseDecorator {

    constructor(private encountersApi: ApiV2Encounters) {
    }

    hasActiveEncounter(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<boolean> {
        return toPromise(this.encountersApi.hasActiveEncounter(query), onSuccess, onError, onFinally);
    }

    getPatientEncounters(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getPatientEncounters(query), onSuccess, onError, onFinally);
    }

    getAllowedDocumentTypes(encounterId: any, query?: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (encounterId instanceof Object) {
            encounterId = (encounterId as any).encounterId;
        }

        return toPromise(this.encountersApi.getAllowedDocumentTypes(encounterId, query), onSuccess, onError, onFinally);
    }

    getAllowedCertificatesTypes(encounterId: any, query?: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (encounterId instanceof Object) {
            encounterId = (encounterId as any).encounterId;
        }

        return toPromise(this.encountersApi.getAllowedCertificatesTypes(encounterId, query), onSuccess, onError, onFinally);
    }

    post(body: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.post(body), onSuccess, onError, onFinally);
    }

    get(id: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (id instanceof Object) {
            id = (id as any).id;
        }
        return toPromise(this.encountersApi.get(id), onSuccess, onError, onFinally);
    }

    getForEdit(id: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (id instanceof Object) {
            id = (id as any).id;
        }
        return toPromise(this.encountersApi.getForEdit(id), onSuccess, onError, onFinally);
    }

    getByDocType(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getByDocType(query), onSuccess, onError, onFinally);
    }

    getByDocData(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getByDocData(query), onSuccess, onError, onFinally);
    }

    getPatientEncountersByFTQuery(query?: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getPatientEncountersByFTQuery(query), onSuccess, onError, onFinally);
    }

    closeEncounter(id: any, body: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (id instanceof Object) {
            id = (id as any).id;
        }

        return toPromise(this.encountersApi.closeEncounter(id, body), onSuccess, onError, onFinally);
    }

    countActiveAndCanceled(params: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        let encounterId
        let docType

        if (params instanceof Object) {
            encounterId = (params as any).encounterId;
            docType = (params as any).docType;
        }

        return toPromise(this.encountersApi.countActiveAndCanceled(encounterId, docType), onSuccess, onError, onFinally);
    }

    cancelEncounter(id: any, body: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (id instanceof Object) {
            id = (id as any).id;
        }

        return toPromise(this.encountersApi.cancelEncounter(id, body), onSuccess, onError, onFinally);
    }

    updateInsurance(id: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (id instanceof Object) {
            id = (id as any).id;
        }

        return toPromise(this.encountersApi.updateInsurance(id), onSuccess, onError, onFinally);
    }

    eventRestricted(id: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (id instanceof Object) {
            id = (id as any).id;
        }

        return toPromise(this.encountersApi.eventRestricted(id), onSuccess, onError, onFinally);
    }

    encounterHistory(encounterId: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (encounterId instanceof Object) {
            encounterId = (encounterId as any).encounterId;
        }

        return toPromise(this.encountersApi.encounterHistory(encounterId), onSuccess, onError, onFinally);
    }

    encounterHistoryItem(params: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        let encounterId
        let historyId

        if (params instanceof Object) {
            encounterId = (params as any).encounterId;
            historyId = (params as any).historyId;
        }

        return toPromise(this.encountersApi.encounterHistoryItem(encounterId, historyId), onSuccess, onError, onFinally);
    }

    getUnusedPatientOrders(query?: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getUnusedPatientOrders(query), onSuccess, onError, onFinally);
    }

    getOrderForEdit(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        let id = (query as any).id;
        return toPromise(this.encountersApi.getOrderForEdit(id, query), onSuccess, onError, onFinally);
    }

    changeOrder(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.changeOrder(null, query), onSuccess, onError, onFinally);
    }

    changeOrderToPaper(body: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.changeOrderToPaper(body), onSuccess, onError, onFinally);
    }

    getEsiNr(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getEsiNr(query), onSuccess, onError, onFinally);
    }

    getByOrders(query?: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getByOrders(query), onSuccess, onError, onFinally);
    }

    getRelatedDocs(encounterId: any, query?: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        if (encounterId instanceof Object) {
            encounterId = (encounterId as any).encounterId;
        }

        return toPromise(this.encountersApi.getRelatedDocs(encounterId, query), onSuccess, onError, onFinally);
    }

    isActive(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.isActive(query), onSuccess, onError, onFinally);
    }

    getEncountersHistory(query: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.getEncountersHistory(query), onSuccess, onError, onFinally);
    }

    registerAuditEncounter(query: any, body: any, onSuccess?: any, onError?: any, onFinally?: any): PromiseDecorator<any> {
        return toPromise(this.encountersApi.registerAuditEncounter(query, body), onSuccess, onError, onFinally);
    }
}