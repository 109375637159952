import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { onCheckboxChange } from "../../../../../shared/services/utils/utils";
import { PregnancyInfo } from "../../../models/pregnancyTerminationInfo.model";
import { NGNCaptionModel } from "../../shared/models/shared.model";
import {
    AppearanceUterineAppendages,
    BloodFlowTest,
    Conclusions,
    FetalForerunner,
    FetalPosition,
    Placenta,
    PlacentaLocalization,
    PlacentaPathologicalAttachment,
    ResearchConditions,
    ResearchPurpose,
    UltrasoundStages,
} from "../fetus-ultrasound-examination.constants";

@Component({
    selector: "app-ultrasound-template",
    templateUrl: "./ultrasound-template.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltrasoundTemplateComponent {
    @Input() public title: UltrasoundStages;
    @Input() public formGroup: FormGroup;
    @Input() public pregnancyInfo: PregnancyInfo;
    @Input() public ngnCaptionData: NGNCaptionModel;

    public readonly researchConditionsArray = Object.values(ResearchConditions);
    public readonly appearanceUterineAppendagesArray = Object.values(AppearanceUterineAppendages);
    public readonly placentaLocalizationArray = Object.values(PlacentaLocalization);
    public readonly placentaPathologicalAttachmentArray = Object.values(PlacentaPathologicalAttachment);
    public readonly placentaArray = Object.values(Placenta);
    public readonly researchPurposeArray = Object.values(ResearchPurpose);
    public readonly fetalPositionArray = Object.values(FetalPosition);
    public readonly fetalForerunnerArray = Object.values(FetalForerunner);
    public readonly bloodFlowTestArray = Object.values(BloodFlowTest);

    public readonly UltrasoundStages = UltrasoundStages;
    public readonly ResearchConditions = ResearchConditions;
    public readonly Conclusions = Conclusions;
    public readonly AppearanceUterineAppendages = AppearanceUterineAppendages;
    public readonly ResearchPurpose = ResearchPurpose;
    public readonly Placenta = Placenta;

    public onCheckboxChange = onCheckboxChange;
    public tabIndex: number = 0;
    public currentDate = new Date();

    public chorionicity = [
        {
            name: "DC",
            value: true,
        },
        {
            name: "MC",
            value: false,
        },
    ];

    public get getChildCount(): FormControl {
        return this.formGroup?.get("childCount") as FormControl;
    }

    public get getChildsFormArray(): FormArray {
        return this.formGroup?.get("childsNavButtons") as FormArray;
    }

    public get getFetusDataFormArray(): FormArray {
        return this.formGroup?.get("fetusData") as FormArray;
    }

    public get selectedTabIndex(): number {
        return this.tabIndex;
    }

    public get getPregnancyWeekByWomanPeriod(): string {
        const researchDate = this.formGroup?.get("researchDate").value;
        let calculatedWeek = 0;

        if (researchDate && this.pregnancyInfo.lastMenstruationPeriod) {
            const start = this.pregnancyInfo.lastMenstruationPeriod;
            const end = new Date(researchDate);

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = end.getTime() - start.getTime();

            // Convert milliseconds to days
            calculatedWeek = Math.floor(Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) / 7);
        }

        return this.pregnancyInfo.lastMenstruationPeriod ? String(calculatedWeek) : "-";
    }

    public get getPregnancyDayByWomanPeriod(): string {
        const researchDate = this.formGroup?.get("researchDate").value;
        let calculatedDay = 0;

        if (researchDate && this.pregnancyInfo.lastMenstruationPeriod) {
            const start = this.pregnancyInfo.lastMenstruationPeriod;
            const end = new Date(researchDate);

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = end.getTime() - start.getTime();

            // Convert milliseconds to days
            calculatedDay = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        }
        return this.pregnancyInfo.lastMenstruationPeriod ? calculatedDay.toFixed(0) : "-";
    }

    constructor(private readonly _fb: FormBuilder) {}

    public setTab(index: number) {
        this.tabIndex = index;
    }

    public setCheckbox(formGroup: FormGroup, value): void {
        if (formGroup.value === value) {
            formGroup.patchValue(null);
        } else {
            formGroup.patchValue(value);
        }
    }

    public setChildFormArray(childCount: number): void {
        const count = Math.min(Math.max(childCount || 0, 0), 20);
        const childArray =
            count > 0
                ? Array.from({ length: count }, (_, index) => ({
                      letter: String.fromCharCode(65 + index),
                  }))
                : [];

        if (this.getChildsFormArray.length < childCount) {
            childArray.forEach((child, index) => {
                if (
                    this.getChildsFormArray.length === 0 ||
                    this.getChildsFormArray.value[index]?.letter !== child.letter
                ) {
                    this.getChildsFormArray.push(this._fb.group(child));

                    this.patchFetusData();
                }
            });
        } else {
            this.indexesToRemove(Number(childCount), this.getChildsFormArray.length).forEach((index) => {
                this.getChildsFormArray.removeAt(index);
                this.getFetusDataFormArray.removeAt(index);
            });

            if (this.selectedTabIndex >= childCount) {
                this.setTab(0);
            }
        }

        this.getFetusDataFormArray.markAllAsTouched();
    }

    public setStartStyle(isRequared: boolean, reqOrColFormLabel: boolean): string {
        let style = "";

        if (isRequared) {
            if (reqOrColFormLabel) {
                style = "req";
            } else {
                style = "col-form-label";
            }
        }

        return style;
    }

    private measurementControls(requiredForStages: UltrasoundStages[]) {
        return {
            mm: new FormControl(null, ...this.requiredValidator(requiredForStages)),
            week: new FormControl(null, ...this.requiredValidator(requiredForStages)),
            day: new FormControl(null, ...this.requiredValidator(requiredForStages)),
        };
    }

    private testsMMControls(requiredForStages: UltrasoundStages[]) {
        return {
            tests: new FormControl(null, ...this.requiredValidator(requiredForStages)),
            mm: new FormControl(null, ...this.requiredValidator(requiredForStages)),
        };
    }

    private requiredValidator(requiredForStages: UltrasoundStages[]): Validators[] {
        return requiredForStages.includes(this.title) ? [Validators.required] : [];
    }

    private validateFormArray(control: FormArray): ValidationErrors | null {
        return control?.value?.length ? null : { required: true };
    }

    private patchFetusData(): void {
        this.getFetusDataFormArray.push(
            this._fb.group({
                generalInfo: this._fb.group({
                    fetusLetter: new FormControl(null),
                    chorionicity: new FormControl(null),
                    amnioticity: new FormControl(null),
                    headButtocksMeasurement: this._fb.group({
                        ...this.measurementControls([UltrasoundStages.First]),
                    }),
                    neckFertility: this._fb.group({
                        mm: new FormControl(null, ...this.requiredValidator([UltrasoundStages.First])),
                    }),
                    biparietalDimension: this._fb.group({
                        ...this.measurementControls([UltrasoundStages.Second, UltrasoundStages.Third]),
                    }),
                    headCircumference: this._fb.group({
                        ...this.measurementControls([UltrasoundStages.Second, UltrasoundStages.Third]),
                    }),
                    abdominalGirth: this._fb.group({
                        ...this.measurementControls([UltrasoundStages.Second, UltrasoundStages.Third]),
                    }),
                    femurLength: this._fb.group({
                        ...this.measurementControls([UltrasoundStages.Second, UltrasoundStages.Third]),
                    }),
                    humerusLength: this._fb.group({
                        ...this.measurementControls([]),
                    }),
                    fetusWeight: this._fb.group({
                        weight: new FormControl(
                            null,
                            ...this.requiredValidator([UltrasoundStages.Second, UltrasoundStages.Third])
                        ),
                        week: new FormControl(
                            null,
                            ...this.requiredValidator([UltrasoundStages.Second, UltrasoundStages.Third])
                        ),
                        day: new FormControl(
                            null,
                            ...this.requiredValidator([UltrasoundStages.Second, UltrasoundStages.Third])
                        ),
                    }),
                    fetalPosition: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Third])),
                    fetalForerunner: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Third])),
                }),
                head: this._fb.group({
                    form: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    skullOssification: new FormControl(null, ...this.requiredValidator([UltrasoundStages.First])),
                    sickleVault: new FormControl(null),
                    vascularPlexuses: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    //Second Stage
                    transparentPartition: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                    middleLine: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                    brainNuclei: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                    cerebellum: this._fb.group({
                        ...this.testsMMControls([UltrasoundStages.Second]),
                        week: new FormControl(null),
                        day: new FormControl(null),
                    }),
                    greatCistern: this._fb.group({
                        ...this.testsMMControls([UltrasoundStages.Second]),
                    }),
                    rightAnteriorHorn: this._fb.group({
                        tests: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                        mm: new FormControl(null),
                    }),
                    leftAnteriorHorn: this._fb.group({
                        tests: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                        mm: new FormControl(null),
                    }),
                    rightPosteriorHorn: this._fb.group({
                        tests: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                        mm: new FormControl(null),
                    }),
                    leftPosteriorHorn: this._fb.group({
                        tests: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                        mm: new FormControl(null),
                    }),
                    greatConnection: new FormControl(null),
                    neckCrease: this._fb.group({
                        ...this.testsMMControls([UltrasoundStages.Second]),
                    }),
                }),
                face: this._fb.group({
                    profile: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    eyeSockets: new FormControl(null),
                    nasalBone: this._fb.group({
                        tests: new FormControl(null),
                        mm: new FormControl(null),
                    }),
                    //Second Stage
                    upperLip: new FormControl(null),
                    nostrils: new FormControl(null),
                    chin: new FormControl(null),
                    peritonealTissueThickness: this._fb.group({
                        tests: new FormControl(null),
                        mm: new FormControl(null),
                    }),
                }),
                neckAndSpine: this._fb.group({
                    neck: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    spine: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                }),
                chest: this._fb.group({
                    form: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                    lungs: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    diaphragm: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                }),
                heart: this._fb.group({
                    activity: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    size: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    axis: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    fourCameraView: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                    //Second Stage
                    aorticOutflowTract: new FormControl(null),
                    pulmonaryTrunkExcretoryTract: new FormControl(null),
                }),
                belly: this._fb.group({
                    stomach: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    intestine: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                    kidneys: new FormControl(null),
                    rightKidney: this._fb.group({
                        tests: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                        mm: new FormControl(null),
                    }),
                    leftKidney: this._fb.group({
                        tests: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                        mm: new FormControl(null),
                    }),
                    bladder: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    umbilicalCordAttaches: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                }),
                limbs: this._fb.group({
                    rightHand: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    leftHand: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    rightLegFoot: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                    leftLegFoot: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.First, UltrasoundStages.Second])
                    ),
                }),
                umbilicalCord: this._fb.group({
                    threeBloodVessels: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Second])),
                }),
                sexOrgans: this._fb.group({
                    mens: new FormControl(null),
                    womens: new FormControl(null),
                }),
                bloodStream: this._fb.group({
                    throughThreeWayValve: new FormControl(null),
                    throughDuctusVenosus: new FormControl(null),
                    heartAttackFrequency: new FormControl(null),
                }),
                placenta: this._fb.group({
                    localization: new FormControl([], this.validateFormArray),
                    placenta: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.Second, UltrasoundStages.Third])
                    ),
                    placentaFromInternalMouth: new FormControl(null),
                    placentaAppearance: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.Second, UltrasoundStages.Third])
                    ),
                    pathologicalAttachmentPlacenta: new FormControl(null),
                    //Third Stage
                    maturityDegree: new FormControl(null, ...this.requiredValidator([UltrasoundStages.Third])),
                }),
                amnioticFluids: this._fb.group({
                    amnioticFluid: new FormControl(
                        null,
                        ...this.requiredValidator([UltrasoundStages.Second, UltrasoundStages.Third])
                    ),
                    VVI: new FormControl(null),
                    GVVK: new FormControl(null),
                    fetalBreathingMovements: new FormControl(null),
                    fetalMovements: new FormControl(null),
                    fetalTone: new FormControl(null),
                    amount: new FormControl(null),
                    KTG: new FormControl(null),
                    biophysicalProfile: new FormControl(null),
                }),
                bloodFlowTest: this._fb.group({
                    umbilicalArtery: this._fb.group({
                        PI: new FormControl(null),
                        RI: new FormControl(null),
                        SDRatio: new FormControl(null),
                    }),
                    circulationClass: new FormControl(null),
                    middleCerebralArtery: this._fb.group({
                        PI: new FormControl(null),
                        RI: new FormControl(null),
                        SDRatio: new FormControl(null),
                        PSV: new FormControl(null),
                        cms: new FormControl(null),
                    }),
                    venousDuct: this._fb.group({
                        PI: new FormControl(null),
                    }),
                    splenicArtery: this._fb.group({
                        PI: new FormControl(null),
                        RI: new FormControl(null),
                        SDRatio: new FormControl(null),
                        PSV: new FormControl(null),
                        cms: new FormControl(null),
                    }),
                }),
                conclusions: this._fb.group({
                    conclusion: new FormControl(null, Validators.required),
                    notes: new FormControl(null),
                    pregnancyDuration: this._fb.group({
                        week: new FormControl(null, Validators.required),
                        day: new FormControl(null, Validators.required),
                    }),
                }),
            })
        );
    }

    private indexesToRemove(start: number, end: number): number[] {
        const result: number[] = [];

        for (let i = start; i < end; i++) {
            result.push(i);
        }

        return result.sort((a, b) => b - a);
    }
}
