import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    addObjToFormArray,
    multiCheckboxIsChecked,
    updateCheckboxControl,
} from '../../../../../e096/e096-utils/utils';
import { debounceTime, distinctUntilChanged, map, Observable, Observer, switchMap } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiV2Entities } from '../../../../../../../../shared/services/api-v2-entities';

@Component({
    selector: 'app-e097-gd-diagnosis',
    templateUrl: './e097-gd-diagnosis.component.html',
})
export class E097GdDiagnosisComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup = this.fb.group({
        diagnosis: this.fb.array([]),
        decisions: this.fb.control(null),
        mealsOnDeparture: this.fb.control([]),
    });
    currentTime = new Date();
    searchDiagnose: FormControl = this.fb.control(null);
    selectedDiagnose: EntityModel;
    suggestions$: Observable<EntityModel[]>;

    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;

    mealsOnDepartureOptions: EntityModel[] = [
        {
            name: 'Žindomas',
            code: '0',
            id: '0'
        },
        {
            name: 'Nutrauktas motinos pienas',
            code: '1',
            id: '1'
        },
        {
            name: 'Mišinys',
            code: '2',
            id: '2'
        },
        {
            name: 'Donorinis motinos pienas',
            code: '3',
            id: '3'
        }
    ]

    get diagnosisControl() {
        return (this.formGroup.get('diagnosis') as FormArray).controls;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.getDiagnoses();
    }

    selectDiagnose(e: TypeaheadMatch) {
        this.selectedDiagnose = e.item;
    }

    addDiagnoseToList(): void {
        delete this.selectedDiagnose['fullDisplay'];
        const formGroup = addObjToFormArray(this.selectedDiagnose);
        (this.formGroup.get('diagnosis') as FormArray).push(formGroup, {emitEvent: false});
        this.searchDiagnose.reset();
        this.selectedDiagnose = null;
    }

    deleteDiagnose(i: number) {
        (this.formGroup.get('diagnosis') as FormArray).removeAt(i, {emitEvent: false})
    }

    private getDiagnoses() {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.searchDiagnose.value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                const request = !query?.length
                    ? this.apiV2Entities.getMostUsedDiagnoses({count: 10})
                    : this.apiV2Entities.getEntitiesList('tlk-10-am', {searchCriteria: query});

                return request.pipe(map(items => items
                    .filter(p => !(this.formGroup.get('diagnosis') as FormArray).getRawValue()?.some(s => s.id === p.id))
                    .map(p => {
                        p['fullDisplay'] = `${p.code} ${p.name}`;
                        return p;
                    })
                ))
            }),
            takeUntilDestroyed(this.destroyRef)
        );

        this.searchDiagnose.valueChanges.pipe(
            debounceTime(50),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(r => {
            if (!this.selectedDiagnose || r === this.selectedDiagnose?.['fullDisplay']) return;
            this.selectedDiagnose = null;
        })
    }
}
