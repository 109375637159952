/* global angular */

ng1App.controller('doctor.patient.DraftsCtrl', 
    ['$scope', '$routeParams', 'SearchController', 'MessageService', 'DraftsApiFactory', 'DpPatientContext', 'DpSessionContext',
     function ($scope, $routeParams, SearchController, MessageService, DraftsApiFactory, DpPatientContext, DpSessionContext) {
    MessageService.clearAll();
    $scope.$emit('setHelpPage', 'perzireti-paciento-juodrascius');

    $scope.filters = [
        {title: 'dpp.pat.dra.rec.all', query: {}},
        {title: 'dpp.pat.dra.rec.amb', query: {type: 'e025'}},
        {title: 'dpp.pat.dra.rec.inp', query: {type: 'e003'}},
        {title: 'dpp.pat.dra.rec.sen', query: {type: 'e027'}},
        {title: 'dpp.pat.dra.rec.sena', query: {type: 'e027a'}},
        {title: 'dpp.pat.dra.rec.lab', query: {type: 'e200'}},
        {title: 'dpp.pat.dra.rec.labr', query: {type: 'e200a'}},
        {title: 'dpp.pat.dra.rec.pat', query: {type: 'e014'}},
        {title: 'dpp.pat.dra.rec.pata', query: {type: 'e014a'}},
        {title: 'dpp.pat.dra.rec.vac', query: {type: 'e063'}},
        {title: 'dpp.pat.dra.rec.cer', query: {type: 'cert'}},
        {title: 'dpp.pat.sum', query: {type: 'patient_summary'}}
    ];

    $scope.searchCtrl = new SearchController({
        viewId: 'DPP_DRAFTS',
        count: 10,
        initForm: function () {
            return {
                filter: $scope.filters[0]
            };
        },
        searchApi: DraftsApiFactory.findDrafts,
        showPageCounter: true});

    $scope.searchCtrl.setPagingInfo = function (query) {
        query.count = this.getCount();
        query.first = this._list.length;
    };

    $scope.searchCtrl.formToQuery = function (form) {
        var query: any = {};
        query.patient = DpPatientContext.id;

        switch (form._searchType) {
            case 'quick':
                form.filter = undefined;
                query.text = form.q;
                break;
            case 'filter':
                form.q = undefined;
                query.type = form.filter ? form.filter.query.type : undefined;
                break;
        }

        return query;
    };

    $scope.handlers = {patientCtx: DpPatientContext};

    $scope.changeFilter = function (f) {
        $scope.searchCtrl.clear();
        $scope.searchCtrl._form.filter = f;
        $scope.searchCtrl.searchFilter();
    };

    DpSessionContext.getSettings(function (data) {
        $scope.searchCtrl.setCount(data.count_per_page || 10);
        $scope.searchCtrl.searchLastOrAll();
    });

    DraftsApiFactory.countByDocType({patient: DpPatientContext.id}, function (data) {
        $scope.filters[0].count = data.allCount;
        $scope.filters[10].count = data.certificateCount;
        angular.forEach(data.countsByDocType, function (val, key) {
            angular.forEach($scope.filters, function (f) {
                if (f.query.type === key) {
                    f.count = val;
                }
            });
        });
    });

    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;

}]);