<app-title backPath="/dp/doc-ref-consent/list" backTitle="Sutikimai">
    {{'comp.docRefConsent.new.title'| translate}}
</app-title>
<!-- <app-common-patient-widget *ngIf="patientId" [_modeModification]="false"></app-common-patient-widget> -->
<app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
    [prefillData]="prefillData"></app-common-patient-widget>
<nhr-record-widget [closable]="false" [closed]="false"
    [bodyClass]="'form-horizontal'"
    [title]="'comp.docRefConsent.new.subtitle' | translate" id="newConsent">
    <form [formGroup]="form" autocomplete="off">
        <div *ngIf="!patientId" class="form-group row align-items-center req">
            <label [for]="patientField"
                class="col-md-4 col-form-label">{{'comp.docRefConsent.new.patient' |
                translate}}</label>
            <div class="col-md-8">
                <div class="input-group">
                    <input [id]="patientField" [formControlName]="patientField" type="text"
                        class="form-control" [typeahead]="suggestions$" [typeaheadAsync]="true"
                        [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                        (typeaheadOnSelect)="changePatient($event)"
                        [typeaheadOptionField]="patientField"
                        [ngClass]="{ 'is-invalid': form.get(patientField)?.invalid }"
                        placeholder="{{'comp.docRefConsent.new.search' | translate}}" [required]="true">
                    <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="form-group row req">
            <label [for]="categoryField"
                class="col-md-4 col-form-label">{{'comp.docRefConsent.new.category' |
                translate}}</label>
            <div class="col-md-8">
                <select [id]="categoryField" [formControlName]="categoryField" class="form-select"
                    (change)="changeCategory($event.target.value)"
                    [ngClass]="{ 'is-invalid': form.get(categoryField)?.invalid }" [required]="true">
                    <option value="" disabled selected hidden>Pasirinkti...</option>
                    <option *ngFor="let category of categories$ | async" value={{category.id}}>
                        {{category.title}}</option>
                </select>

            </div>
        </div>
        <div class="form-group row req">
            <label [for]="templateTitleField"
                class="col-md-4 col-form-label">{{'comp.docRefConsent.new.template' |
                translate}}</label>
            <div class="col-md-8">
                <div class="d-flex gap-3">
                    <select [id]="templateTitleField" [formControlName]="templateTitleField"
                        class="form-select" (change)="changeTemplate($event.target.value)"
                        [ngClass]="{ 'is-invalid': form.get(templateTitleField)?.invalid }"
                        [required]="true">
                        <option *ngFor=" let template of filteredTemplates" value={{template.id}}>
                            {{template.title}}</option>
                    </select>
                    <button *ngIf="selectedTemplateId" class="btn btn-square btn-secondary" (click)="onViewClick()" placement="right"
                        [tooltip]="'doc-ref-consent-create.component.View'|translate">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group row req">
            <label [for]="expiryDateField"
                class="col-md-4 col-form-label">{{'comp.docRefConsent.new.expiry' |
                translate}}</label>
            <div class="col-md-8">
                <app-date-picker [inputId]="expiryDateField" [maxDate]="currentDate"
                    [fControl]="expiryDateControl" [required]="true" />
            </div>
        </div>
    </form>
</nhr-record-widget>
<form-buttons-layout>
    <!-- <button class="btn btn-outline-secondary" type="button"
        (click)="goBack()">{{'comp.docRefConsent.new.back' | translate}}</button> -->
    <button class="btn btn-primary" type="button" (click)="onSave()"
        [disabled]="!form.valid">{{'comp.docRefConsent.new.send' | translate}}</button>
</form-buttons-layout>
