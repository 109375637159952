<div>
    <app-title backPath="/dp" showFilterButton="true">
        Slaugos paslaugų užsakymai (Paskyrimai)
    </app-title>
    <nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
        [title]="'Slaugos paslaugų užsakymai (Paskyrimai)' | translate" id="nursingServiceOrderListComponent">
        <custom-search before-title [name]="'quick-search'" [isAdvanced]="true" (filterService)="loadFilteredList($event)">
            <nursingServiceOrderList-filter (onSearch)="loadFilteredList($event)"></nursingServiceOrderList-filter>
        </custom-search>
        <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid"
            [columnDefs]="nursingServiceOrderListColDefs" [defaultColDef]="defaultColDef"
            [rowData]="nursingServiceOrderListData$ | async" [rowSelection]="'multiple'"
            (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
            [domLayout]="'autoHeight'" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
            [suppressPaginationPanel]="true" />
    </nhr-record-widget>
    <ag-pagination-bar></ag-pagination-bar>
</div>
