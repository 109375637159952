<app-e096-ptkl-control-sheet />

<ng-container *ngIf="formGroup?.get('controlSheet').value === 'Replių ir vakuuminio ekstraktoriaus naudojimas'">
    <app-e096-ptkl-obstetric-forceps-use [answersVariants]="answersVariants" [editable]="editable" />
    <app-e096-ptkl-vacuum-extraction [answersVariants]="answersVariants" [editable]="editable" />
</ng-container>

<ng-container *ngIf="formGroup?.get('controlSheet').value === 'Pagalbos teikimas pogimdyminio kraujavimo atveju'">
    <app-e096-ptkl-assisting-with-postpartum-hemorrhage [answersVariants]="answersVariants" [editable]="editable" />
</ng-container>

<ng-container *ngIf="formGroup?.get('controlSheet').value === 'Vaisiaus pečių užstrigimas'">
    <app-e096-ptkl-fetal-shoulder-impingement [editable]="editable" />
</ng-container>

<div class="d-flex gap-3 mt-3" *ngIf="editable">
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger flex-grow-1" (click)="resetForm()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>