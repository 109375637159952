<div class="d-flex justify-content-center">
    <span class="badge rounded-pill text-white" [ngClass]="pillColor">{{ pillModel.status }}</span>
</div>
<div class="d-flex text-secondary" *ngIf="pillModel.validFrom && pillModel.validUntil">
    <div class="me-auto">{{ 'nuo:' }}</div>
    <div class="ms-2">{{ pillModel.validFrom }}</div>
</div>
<div class="d-flex text-secondary" *ngIf="pillModel.validFrom && pillModel.validUntil">
    <div class="me-auto">{{ 'iki:' }}</div>
    <div class="ms-2">{{ pillModel.validUntil }}</div>
</div>