<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngFor="let aohf of assessmentOfHealthFactors">
        <p class="mb-0">
            <small>
                {{ aohf.date }}
            </small>
        </p>
        <span *ngIf="aohf.healthRiskFactor" class="fw-bold mb-0">
            {{ aohf.healthRiskFactor?.display }}
        </span>
        <span *ngIf="aohf.assessmentMethodology" class="vr"></span>
        <span *ngIf="aohf.assessmentMethodology" class="fw-bold mb-0">
            {{ aohf.assessmentMethodology }}
        </span>
        <span *ngIf="aohf.assessmentEvaluation" class="vr"></span>
        <span *ngIf="aohf.assessmentEvaluation" class="fw-bold mb-0">
            {{ aohf.assessmentEvaluation }}
        </span>
        <div class="mb-0">
            <div class="text-truncate">
                {{ aohf.notes }}
            </div>
        </div>
    </li>
    <li *ngIf="!loading" class="list-group-item p-0">
        <a href="/dp/patients/{{patientId}}/risks" class="d-block text-decoration-none hover text-center px-2_5 py-2">
            {{ 'show.more' | translate }}
        </a>
    </li>
</nhr-record-widget>