import { Component } from "@angular/core";
import { FormArray } from "@angular/forms";

@Component({
    selector: "app-agGrid-modal-link-cell",
    template: ` <button type="button" class="btn btn-link" (click)="openModal()">{{ linkTitle | translate }}</button> `,
})
export class AgGridModalOpenLinkCell {
    agContext: any;
    cellParams: any;
    linkTitle: string;

    agInit(params: any) {
        if (params.linkTitle) {
            this.linkTitle = params.linkTitle;
        }
        if (params.context.linkTitle) {
            this.linkTitle = params.context.linkTitle;
        }
        this.cellParams = params;
        this.agContext = params.context;
    }

    refresh(params: any): boolean {
        return true;
    }

    openModal() {
        if (this.agContext) {
            this.agContext.parentComp.openModal(this.cellParams.data);
        }
    }
}
