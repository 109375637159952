ng1App.controller('document.E200aEditCtrl', 
    ['$scope', '$routeParams', 'MessageService', 'DocumentModificationActions', '$filter',
     function ($scope, $routeParams, MessageService, DocumentModificationActions, $filter) {
    MessageService.clear();
    
    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(8);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'};
    $scope._shared.saveFinalOnly = function(){
        var ri = $scope.form ? $scope.form.researchInformation : null;
        return ri && ri.attester;
    }

    $scope.data = {};

    $scope.doCreateOrder = false;

    $scope.form.$promise.then(function(doc){
        $scope.doCreateOrder = !(doc.orderData && doc.orderData.item && doc.orderData.item.id);
    });

    $scope._shared.customValidator = function (documentForm, skipRequired) {
        var res = DocumentModificationActions.validateFormSimple(documentForm, skipRequired);
        var takeDatetime = $routeParams.orderId ? $scope.form.orderData.item.labData.storageInformation.takeDatetime : $scope.form.storageInformation.takeDatetime;
        MessageService.clear('material-research');
        MessageService.clear('lab-test');
        MessageService.clear('material-receiving');

        if ($scope.form.materiallReceivedAt && (!takeDatetime || _.truncateToMinutes($scope.form.materiallReceivedAt) < _.truncateToMinutes(takeDatetime))) {
            MessageService.add('error', 'Gavimo data ir laikas turi būti vėlesni nei paėmimo data ir laikas.', $scope.doCreateOrder ? 'material-research' : 'material-receiving');
            if (res) {
                _.scrollTo($scope.doCreateOrder ? '[msg-region="material-research"]' : '[msg-region="material-receiving"]');
            }
            res = false;
        }
        if ($scope.form.materiallReceivedAt && (!$scope.form.researchInformation.testPerformedAt || _.truncateToMinutes($scope.form.researchInformation.testPerformedAt) < _.truncateToMinutes($scope.form.materiallReceivedAt))) {
            MessageService.add('error', 'Tyrimo atlikimo data ir laikas turi būti vėlesnis nei gavimo data ir laikas.', 'lab-test');
            if (res) {
                _.scrollTo('[msg-region="lab-test"]');
            }
            res = false;
        }
        return res;
    };
}]);