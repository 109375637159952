<modal [modalId]="certificateModalId" [modalCloseId]="certificateModalCloseId" [parentModalId]="parentModalId" title="mdl.spe.sel" (confirm)="createCertificate()">
    <ng-container *ngIf="patient">
        <!--patient-->
        <div class="form-group">
            <strong class="col-md-4 col-lg-3 col-form-label"><!--Pacientas-->{{'cer.e083.mai.inf.pat'|translate}}</strong>
            <div class="col-md-8 col-lg-9">
                <div class="form-control-plaintext">
                    <strong> {{ patient?.givenName }} {{ patient?.familyName }}</strong>, {{ patient?.birthDate | date: 'yyyy-MM-dd' }}
                    <div *ngIf="patient?.personalCode">{{ 'personCode'|translate }}: {{ patient?.personalCode }}</div>
                    <div *ngIf="patient?.declaredAddress && patient?.declaredAddress?.text">
                        {{ patient?.declaredAddress?.text }}
                    </div>
                    <span *ngIf="!patient?.declaredAddress && !patient?.declaredAddress?.text">
                                {{'err.dec_adr_notFound' | translate}}
                            </span>
                </div>
            </div>
        </div>

        <!--show if user have right create conclusions-->
        <div class="form-group">
            <div class="col-md-8 col-lg-9 offset-0 offset-md-4 offset-lg-3">
                <div class="form-group form-check">
                    <label title="{{'cer.e083.exa.spe'|translate}}">
                        <input name="cer.e083.exa.spe" [(ngModel)]="finalAuthorSameAsCurrent" id="cer.e083.exa.spe" type="checkbox"
                               (ngModelChange)="finalAuthorSameAsCurrentChange(finalAuthorSameAsCurrent)">
                        <!--Ištyrimą atliks pažymą inicijuojantis specialistas-->{{'mdl.spe.sel.curr' | translate}}
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group req" *ngIf="!finalAuthorSameAsCurrent">
            <strong class="col-md-4 col-lg-3 col-form-label">
                {{ 'mdl.spe.sel.res' | translate }}
            </strong>
            <div class="col-md-8 col-lg-9">
                <div class="mc">
                    <div class="mc-input">
                        <input
                                type="text"
                                class="form-control"
                                id="dp.pat.enc.vis.spec"
                                name="dp.pat.enc.vis.spec"
                                placeholder="{{ 'dp.pat.enc.vis.specFill' | translate }}"
                                (change)="searchPractitionersChange($event)"
                        />
                    </div>
                    <div class="multiselect" *ngIf="practitioners">
                                <span *ngFor="let p of practitioners">
                                    <label *ngFor="let q of p.validQualificationList" for="finalCertificateAuthor">
                                        <input
                                                id="finalCertificateAuthor"
                                                type="radio"
                                                [(ngModel)]="finalCertificateAuthor"
                                                name="finalCertificateAuthor"
                                                [value]="p"
                                                [title]="p.givenName + ' ' + p.familyName + ', ' + q.name + ' ' + q.licenseCode"
                                                (click)="finalCertificateAuthorChange(p, q)"
                                        />
                                        {{ p.givenName }} {{ p.familyName }}, {{ q.name }} {{ q.licenseCode }}
                                    </label>
                                    <br/>
                                </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</modal>