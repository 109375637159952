ng1App.controller('doctor.patient.erx_pr.EditDocCtrl', 
    ['$scope', '$routeParams', 'ErxPaths', 'ErxIntegration', '$location', 'ErxHistoryPaths',
     function ($scope, $routeParams, ErxPaths, ErxIntegration, $location, ErxHistoryPaths) {

    $scope.options = {
        // path to return to from the view prescription form (probably prescription list)
        getReturnUrl: function() {
            return ErxPaths.list();
        },
        // the prescription id
        prescriptionId: $routeParams.prescriptionId,
        // implement this function to redirect to the page with the sign-prescription directive (for the same prescription)
        redirectToSignPage: function() {
            $location.path(ErxPaths.sign($routeParams.prescriptionId));
        },
        // implement this function to redirect to the page with the sign-prescription directive (for the same prescription) and pass docIds
        redirectToSignPageWithDocIds: function(docIds) {
            $location.path(ErxPaths.sign($routeParams.prescriptionId)).search('docIds', docIds);
        },
        // redirect to another view prescription form for the same encounter with hideDocInfo = false
        redirectToViewPrescriptionDoc: function(prescriptionId){
            $location.url(ErxPaths.viewDoc($routeParams.prescriptionId));
        },
        // redirect to a view prescription form for the same encounter with hideDocInfo = true
        redirectToViewPrescriptionForm: function(prescriptionId){
            $location.url(ErxPaths.view($routeParams.prescriptionId));
        },
        //returns integration object with getters for classifier values and displayGlobalMessage function
        getIntegrationData: ErxIntegration.getIntegrationDataObject,
        getCompositionId: function(){
            return $routeParams.documentId;
        },
        redirectToViewDsp: function (patientId, dispenseId) {
            $location.url(ErxHistoryPaths.viewPatDsp(patientId, dispenseId));
        }
    };



}]);