<app-title [backPath]="backPath">
    {{'058-089-115/a - Pranešimai apie nustatytą infekcinį susirgimą'| translate}}
</app-title>
<div *ngIf="viewData" class="row" style="margin-top: 25px;">
    <!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="false" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
        (valueChange)="onChanges($event)"></app-common-patient-widget>
    <app-diagnoses-form *ngIf="!isLoading" (currentDiagnosesChange)="onDiagnosisChanges($event)"
        [viewOnly]="isViewOnlyMode" [currentDiagnoses]="diagnosisArray"
        [isRequiredFieldInForm]=" true"></app-diagnoses-form>
    <nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false"
        [mixed]="true" [title]="'doc-ref-058-089-151a-form.component'|translate">
        <div class="widget-body">
            <form [formGroup]="form">
                <div class="row mb-2">
                    <div class="col-md-6 fw-bold text-xs-start text-md-end">Paskutinį kartą buvimo darbe ar vaikų
                        ugdymo įstaigoje <span class="text-danger">*</span></div>
                    <div class="col-md-6">
                        {{viewData.attendanceDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6 fw-bold text-xs-start text-md-end">Susirgimo (ligos simptomų pradžia) <span
                            class="text-danger">*</span></div>
                    <div class="col-md-6">
                        {{viewData.gettingSickDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6 fw-bold text-xs-start text-md-end">Kreipimosi (atvykimo į SPĮ) data <span
                            class="text-danger">*</span></div>
                    <div class="col-md-6">
                        {{viewData.checkInDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label for="templateTitle" class="col-md-6 col-form-label text-xs-start text-md-end">Diagnozės nustatymo data *</label>
                    <div class="col-sm-8">
                        <app-date-picker
                        inputId="diagnosisDate"
                        [maxDate]="currentDate"
                        [fControl]="form.get('diagnosisDate')">
                        </app-date-picker>
                    </div>
                </div> -->
                <div class="row mb-2">
                    <div class="col-md-6 fw-bold text-xs-start text-md-end">Pranešimo užpildymo data <span
                            class="text-danger">*</span></div>
                    <div class="col-md-6">
                        {{viewData.reportDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label class="col-md-6 col-form-label text-xs-start text-md-end" for="patientVisibleDoc"></label>
                    <div class="col-md-6 mt-2">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="visibility" value="invisible" id="hospitalizedStatus" formControlName="hospitalized"/>
                            <label class="form-check-label" for="hospitalizedStatus">
                                Asmuo hospitalizuotas
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="row mb-2">
                    <div class="col-md-6 text-xs-start fw-bold text-md-end">Asmuo hospitalizuotas</div>
                    <div class="col-md-6">
                        {{viewData.hospitalized.name}}
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label for="category" class="col-md-6 col-form-label text-xs-start text-md-end">Dokumento tipas </label>
                    <div class="col-sm-8">
                        <select id="category" class="form-select" formControlName="diagnosisStatus" [(ngModel)]="statusValue" required>
                            <option [value]="undefined">--Pasirinkti--</option>
                            <option *ngFor="let docType of problemTypeArray" [value]="docType.name">
                                {{docType.name}}
                            </option>
                        </select>
                    </div>
                </div> -->
                <!-- <div class="form-group row">
                    <label class="col-md-6 col-form-label text-xs-start text-md-end" for="patientVisibleDoc"></label>
                    <div class="col-md-6 mt-2">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="visibility" value="invisible" id="reasonForDeathStatus" formControlName="reasonForDeath"/>
                            <label class="form-check-label" for="reasonForDeathStatus">
                                Pagrindinė mirties priežastis
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="row mb-2">
                    <div class="col-md-6 text-xs-start fw-bold text-md-end">Pagrindinė mirties priežastis</div>
                    <div class="col-md-6">
                        {{viewData.reasonForDeath.name}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6 text-xs-start fw-bold text-md-end">Kita epidemiologiškai svarbi informacija
                    </div>
                    <div class="col-md-6">
                        {{viewData.relevantInformation}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6 text-xs-start fw-bold text-md-end">Duomenys apie gyvūną, kuris sužalojo
                        žmogų</div>
                    <div class="col-md-6">
                        {{viewData.animalKnown.name}}
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label class="col-md-6 col-form-label text-xs-start text-md-end" for="patientVisibleDoc">Duomenys apie gyvūną, kuris sužalojo žmogų</label>
                    <div class="col-md-6 mt-2">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="visibility" value="invisible" id="animalKnownStatus" formControlName="animalKnown"/>
                            <label class="form-check-label" for="animalKnownStatus">
                                Gyvūnas žinomas
                            </label>
                        </div>
                    </div>
                </div> -->
                <!-- <fieldset class="form-group row">
                    <label class="col-md-6 col-form-label text-xs-start text-md-end" for="patientDiseaseForm">Ligos forma</label>
                    <div class="col-md-6 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseForm" id="newVariant" formControlName="diseaseForm" [value]="'new'" [(ngModel)]="selectedDiseaseForm"/>
                            <label class="form-check-label" for="newVariant">
                                Naujas variantas
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm1" formControlName="diseaseForm"  [value]="'sporaginous'" [(ngModel)]="selectedDiseaseForm"/>
                            <label class="form-check-label" for="conditionForm1">
                                Sporaginė forma
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm2" formControlName="diseaseForm" [value]="'iatrogenic'" [(ngModel)]="selectedDiseaseForm"/>
                            <label class="form-check-label" for="conditionForm2">
                                Jatrogeninė forma
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm3" formControlName="diseaseForm" [value]="'family'" [(ngModel)]="selectedDiseaseForm"/>
                            <label class="form-check-label" for="conditionForm3">
                                Šeiminė (genetinė) forma
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm4" formControlName="diseaseForm" [value]="'unknown'" [(ngModel)]="selectedDiseaseForm"/>
                            <label class="form-check-label" for="conditionForm4">
                                Nežinoma
                            </label>
                        </div>
                    </div>
                </fieldset> -->
                <div class="row mb-2">
                    <div class="col-md-6 text-xs-start fw-bold text-md-end">Ligos forma</div>
                    <div class="col-md-6">
                        {{viewData.diseaseForm.name}}
                    </div>
                </div>
                <div *ngIf="form.get('diseaseForm').value === '5'" class="row mb-2">
                    <label for="templateTitle" class="col-md-6 d-flex text-xs-start text-md-end">Jei "Nežinoma"
                        įrašyti</label>
                    <div class="col-md-6">
                        {{viewData.writeIfUnknown}}
                    </div>
                </div>
                <div *ngIf="form.get('diseaseForm').value === '1'" class="row mb-2">
                    <label for="templateTitle" class="col-md-6 d-flex text-xs-start text-md-end">Informacija apie
                        atliktus diagnostinius
                        tyrimus ligai nustatyti ir jų rezultatus</label>
                    <div class="col-md-6">
                        {{viewData.newInfo}}
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
</div>
<undo-actions (undo)="undoClick()"></undo-actions>
