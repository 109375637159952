ng1App.factory('ErxPaths',
    ['$routeParams',
     function ($routeParams) {
    var docUrl = function (documentId?, patientId?) {
        return '/dp/patients/' + (patientId || $routeParams.patientId);
    };
    var baseUrl = function (documentId?, patientId?) {
        return docUrl(documentId, patientId) + '/erx/prescriptions';
    };
    return {
        backToDoc: function () {
            return docUrl();
        },
        view: function (prescriptionId, documentId, patientId) {
            return baseUrl(documentId, patientId) + '/' + prescriptionId + '?hideDoc=1';
        },
        viewDoc: function (prescriptionId) {
            return baseUrl() + '/' + prescriptionId + '?hideDoc=0';
        },
        new : function (prescriptionType/*, aagaSgas*/) {
            var url = baseUrl() + '/new?prescriptionType=' + prescriptionType;
/*
            if (aagaSgas) {
                url += '&aagasgas=' + aagaSgas;
            }
*/
            return url;
        },
        edit: function (prescriptionId) {
            return baseUrl() + '/' + prescriptionId + '/edit';
        },
        editDoc: function (prescriptionId) {
            return baseUrl() + '/' + prescriptionId + '/editDoc';
        },
        sign: function (prescriptionId, docIds) {
            var url = baseUrl() + '/' + prescriptionId + '/sign';
            if (docIds) {
                url = url + '?docIds=' + docIds;
            }
            return url;
        },
        list: function (signStatus) {
            var url = baseUrl();
            if (signStatus) {
                return url = url + '?signStatus=' + signStatus;
            }
            return url;
        },
        unisignCallback: function (prescriptionId) {
            return baseUrl() + '/' + prescriptionId + '/signcallback';
        },
        printPrescriptions: function (id) {
            return baseUrl() + '/print';
        }
    };
}]);

ng1App.factory('ErxHistoryPaths',
    ['$routeParams',
     function ($routeParams) {
    var baseUrl = function () {
        return '/dp/patients/' + $routeParams.patientId + '/erx';
    };
    return {
        viewDsp: function (id) {
            return baseUrl() + '/dsp/' + id;
        },
        viewPatDsp: function (patientId, dispenseId) {
            return '/dp/patients/' + patientId + '/erx/dsp/' + dispenseId;
        },
        viewPrs: function (id) {
            return baseUrl() + '/prs/' + id;
        },
        viewPrsDoc: function (id) {
            return baseUrl() + '/prs/' + id + '/doc';
        },
        viewPrsDoc2: function (patientId, prescriptionId) {
            return '/dp/patients/' + patientId + '/erx/prs/' + prescriptionId + '/doc';
        },
        printMedicines: function () {
            return baseUrl() + '/print-medicines';
        }
    }

}]);

ng1App.factory('ErxIntegration',
    ['$rootScope', '$routeParams', 'AccountSettingsApiFactory', 'PatientsApiFactory', 'EntitiesApiFactory', 'MessageService', 'DocumentsApiFactory', 'TypeaheadSearchHandlers', '$q',
     function ($rootScope, $routeParams, AccountSettingsApiFactory, PatientsApiFactory, EntitiesApiFactory, MessageService, DocumentsApiFactory, TypeaheadSearchHandlers, $q) {

    //EntitiesApiFactory.getList({classCode: 'route'});

    var transform = function (res) {
        _.each(res, function (e) {
            e.displayValue = e.name;
        });
    };

    let createPromise = function(json) {
        return new Promise((resolve, reject) => {
            // Simulate an asynchronous operation, like fetching data from an API
            setTimeout(() => {
                // Resolve the Promise with the mocked JSON data
                resolve(json);
            }, 1000); // Simulating a delay of 1 second
        });
    }


    return {
        getIntegrationDataObject: function () {
            return {
                // we will call this function when we want to display a global message
                // possible types = ['error', 'warning', 'info', 'success']
                displayGlobalMessage: function (type, message) {
                    MessageService.add(type, message);
                    //$rootScope.$emit('SHOW_MSG', {type: type, code: '', content: message});
                },
                // 'route'
                getDosageInstructions: function () {
                    //return [
                    //    {code: '1', displayValue: 'vartoti ant odos'},
                    //    {code: '2', displayValue: 'vartoti per burną'},
                    //    {code: '3', displayValue: 'vartoti ant odos, įkvėpti'},
                    //    {code: '4', displayValue: 'vartoti į burną ir ryklę'},
                    //    {code: '5', displayValue: 'vartoti į nosį'},
                    //    {code: '6', displayValue: 'vartoti ant akių'}
                    //];
                    return EntitiesApiFactory.getList({classCode: 'route'}, transform);
                },
                getDiagnosesHandler: function (value) {
                    return TypeaheadSearchHandlers.ICD10(value);
                },
                getSecurityAndEducationalInfo: function () {
                    const json = [
                        {
                            documentLink: "http://localhost:4200/dp",
                            label: "Mokomoji medžiaga pacientui"
                        },
                        {
                            documentLink: "http://localhost:4200/dp",
                            label: "Mokomoji medžiaga farmacijos specialistui"
                        },
                        {
                            documentLink: "http://localhost:4200/dp",
                            label: "Laiškas sveikatos priežiūros specialistui"
                        },
                        {
                            documentLink: "http://localhost:4200/dp",
                            label: " Registracijos pažymėjimo priedai"
                        },
                    ];

                    return createPromise(json);
                },
                getSupplyDisruptions: function () {
                    const json = [
                        {
                            id: 11100,
                            date: "2023-12-01",
                            activeIngredients: "Bendrinis pavadinimas 1",
                            strength: "50mg",
                            pharmForm: "tabletės",
                            npakid7: "11111",
                            atcCode: "ATC001",
                            productName: "Prekinis pav. 1",
                            supplyDisruptionType: null,
                            supplyDisruptionTypeText: "Tiekiama",
                            dateFrom: "2023-12-01",
                            dateTo: "2024-03-01"
                        },
                        {
                            id: 11101,
                            date: "2023-12-01",
                            activeIngredients: "Bendrinis pavadinimas 2",
                            strength: "50mg",
                            pharmForm: "tabletės",
                            npakid7: "11111",
                            atcCode: "ATC001",
                            productName: "Prekinis pav. 2",
                            supplyDisruptionType: "NT",
                            supplyDisruptionTypeText: "Nepradėtas tiekti",
                        },
                        {
                            id: 11102,
                            date: "2023-12-01",
                            activeIngredients: "Bendrinis pavadinimas 3",
                            strength: "50mg",
                            pharmForm: "tabletės",
                            npakid7: "11111",
                            atcCode: "ATC001",
                            productName: "Prekinis pav. 3",
                            supplyDisruptionType: "TN",
                            supplyDisruptionTypeText: "Tiekimo nutraukimas",
                            dateFrom: "2023-12-01",
                            dateTo: "2024-12-01"
                        },
                        {
                            id: 11103,
                            date: "2023-12-01",
                            activeIngredients: "Bendrinis pavadinimas 3",
                            strength: "50mg",
                            pharmForm: "tabletės",
                            npakid7: "11111",
                            atcCode: "ATC001",
                            productName: "Prekinis pav. 4",
                            supplyDisruptionType: "TS",
                            supplyDisruptionTypeText: "Laikinas tiekimo sutrikimas"
                        },
                        {
                            id: 11104,
                            date: "2023-12-01",
                            activeIngredients: "Bendrinis pavadinimas 4",
                            strength: "50mg",
                            pharmForm: "tabletės",
                            npakid7: "11111",
                            atcCode: "ATC001",
                            productName: "Prekinis pav. 5",
                            supplyDisruptionType: "TA",
                            supplyDisruptionTypeText: "Tiekimo atnaujinimas"
                        },
                ];

                    return createPromise(json);
                },
                getPrescriptionConditions: function (params: any) {
                    return EntitiesApiFactory.medPrescriptionConditions(params);
                    //it depends on diagnosis code and drug code?
                    /*const json = [
                        {
                            code: "diagnosis code",
                            condition: "Skiriamas Ph (bcr-abl) teigiamai lėtinei mieloleukemijai gydyti pacientams, kuriems tirozino kinazės inhibitoriai netaikyti, kai gydymas\
                              Imatinibum yra neoptimalus ar kontraindikuotinas. Gydymas Imatinibum yra neoptimalus,\
                              kai: prieš paskiriant vaistinį preparatą nustatyta lėtinės mieloleukemijos\
                              didelės EUTOS rizikos lėtinė fazė ar akceleracijos fazė ar ekstramedulinė\
                              liga; prieš paskiriant vaistinį preparatą nustatyta atsparumą šiam vaistiniam\
                              preparatui rodanti bcr-abl geno mutacija. Prieš skiriant vaistinį preparatą Nilotinibum, nustatomos bcr-abl geno\
                              mutacijos. Gydant vaistiniu preparatu Nilotinibum,\
                              bcr-abl geno molekulinis tyrimas atliekamas ne rečiau kaip kas 3 mėnesius,\
                              kol bcr-abl tampa <0,1 %, ne rečiau kaip kas 6 mėnesius, kai bcr-abl <\
                              0,1 %.;",
                            legalBaseName: "Teisės akto dokumentas",
                            legalBaseUrl: "http://localhost:4200/dp"
                        },
                        {
                            code: "diagnosis code",
                            condition: "Skiriamas Ph (bcr-abl) teigiamai lėtinei mieloleukemijai gydyti pacientams, kuriems tirozino kinazės inhibitoriai netaikyti, kai gydymas\
                              Imatinibum yra neoptimalus ar kontraindikuotinas. Gydymas Imatinibum yra neoptimalus,\
                              kai: prieš paskiriant vaistinį preparatą nustatyta lėtinės mieloleukemijos\
                              didelės EUTOS rizikos lėtinė fazė ar akceleracijos fazė ar ekstramedulinė\
                              liga; prieš paskiriant vaistinį preparatą nustatyta atsparumą šiam vaistiniam\
                              preparatui rodanti bcr-abl geno mutacija. Prieš skiriant vaistinį preparatą Nilotinibum, nustatomos bcr-abl geno\
                              mutacijos. Gydant vaistiniu preparatu Nilotinibum,\
                              bcr-abl geno molekulinis tyrimas atliekamas ne rečiau kaip kas 3 mėnesius,\
                              kol bcr-abl tampa <0,1 %, ne rečiau kaip kas 6 mėnesius, kai bcr-abl <\
                              0,1 %.;",
                            legalBaseName: "Teisės akto dokumentas",
                            legalBaseUrl: "http://localhost:4200/dp"
                        },
                    ];

                    return createPromise(json);*/
                },
                getDefaultShortcuts: function () {
                    return {
                        "shortcuts_dosage_instruction": [
                            {
                                "id": 1,
                                "title": "1 tabletė",
                                "group": "disposableDosage",
                                "quantity": 1,
                                "field": {
                                    "code": "tab.",
                                    "displayValue": "Tabletė"
                                }
                            },
                            {
                                "id": 2,
                                "title": "2 tabletė",
                                "group": "disposableDosage",
                                "quantity": 2,
                                "field": {
                                    "code": "tab.",
                                    "displayValue": "Tabletė"
                                }
                            },
                            {
                                "id": 3,
                                "title": "0.5 tabletė",
                                "group": "disposableDosage",
                                "quantity": 0.5,
                                "field": {
                                    "code": "tab.",
                                    "displayValue": "Tabletė"
                                }
                            },
                            {
                                "id": 4,
                                "title": "1 ampulė",
                                "group": "disposableDosage",
                                "quantity": 1,
                                "field": {
                                    "code": "amp.",
                                    "displayValue": "Ampulė"
                                }
                            },
                            {
                                "id": 5,
                                "title": "1 lašas",
                                "group": "disposableDosage",
                                "quantity": 1,
                                "field": {
                                    "code": "laš.",
                                    "displayValue": "Lašas"
                                }
                            },
                            {
                                "id": 6,
                                "title": "1k. per dieną",
                                "group": "frequencyDosage",
                                "quantity": 1,
                                "field": {
                                    "id": "d",
                                    "code": "d",
                                    "name": "Diena",
                                    "displayValue": "Diena"
                                }
                            },
                            {
                                "id": 7,
                                "title": "2k. per dieną",
                                "group": "frequencyDosage",
                                "quantity": 2,
                                "field": {
                                    "id": "d",
                                    "code": "d",
                                    "name": "Diena",
                                    "displayValue": "Diena"
                                }
                            },
                            {
                                "id": 8,
                                "title": "1k. per savaitę",
                                "group": "frequencyDosage",
                                "quantity": 1,
                                "field": {
                                    "id": "wk",
                                    "code": "wk",
                                    "name": "Savaitė",
                                    "displayValue": "Savaitė"
                                }
                            },
                            {
                                "id": 9,
                                "title": "2k. per savaitę",
                                "group": "frequencyDosage",
                                "quantity": 2,
                                "field": {
                                    "id": "wk",
                                    "code": "wk",
                                    "name": "Savaitė",
                                    "displayValue": "Savaitė"
                                }
                            },
                            {
                                "id": 10,
                                "title": "1k. per mėnesį",
                                "group": "frequencyDosage",
                                "quantity": 1,
                                "field": {
                                    "id": "mo",
                                    "code": "mo",
                                    "name": "Mėnuo",
                                    "displayValue": "Mėnuo"
                                }
                            },
                            {
                                "id": 11,
                                "title": "1 tabletė",
                                "group": "dayDosage",
                                "quantity": 1,
                                "field": {
                                    "code": "tab.",
                                    "displayValue": "Tabletė"
                                }
                            },
                            {
                                "id": 12,
                                "title": "2 tabletė",
                                "group": "dayDosage",
                                "quantity": 2,
                                "field": {
                                    "code": "tab.",
                                    "displayValue": "Tabletė"
                                }
                            },
                            {
                                "id": 13,
                                "title": "0.5 tabletė",
                                "group": "dayDosage",
                                "quantity": 0.5,
                                "field": {
                                    "code": "tab.",
                                    "displayValue": "Tabletė"
                                }
                            },
                            {
                                "id": 14,
                                "title": "1 ampulė",
                                "group": "dayDosage",
                                "quantity": 1,
                                "field": {
                                    "code": "amp.",
                                    "displayValue": "Ampulė"
                                }
                            },
                            {
                                "id": 15,
                                "title": "1 lašas",
                                "group": "dayDosage",
                                "quantity": 1,
                                "field": {
                                    "code": "laš.",
                                    "displayValue": "Lašas"
                                }
                            }
                        ],
                        "shortcuts_dosage_type": [
                            {
                                "id": 16,
                                "title": "per burną",
                                "group": "dosageType",
                                "field": {
                                    "id": "2",
                                    "code": "2",
                                    "name": "vartoti per burną",
                                    "displayValue": "vartoti per burną"
                                }
                            },
                            {
                                "id": 17,
                                "title": "įkvėpti",
                                "group": "dosageType",
                                "field": {
                                    "id": "12",
                                    "code": "12",
                                    "name": "įkvėpti",
                                    "displayValue": "įkvėpti"
                                }
                            },
                            {
                                "id": 18,
                                "title": "leisti po oda",
                                "group": "dosageType",
                                "field": {
                                    "id": "34",
                                    "code": "34",
                                    "name": "leisti po oda",
                                    "displayValue": "leisti po oda"
                                }
                            },
                            {
                                "id": 19,
                                "title": "leisti į raumenis",
                                "group": "dosageType",
                                "field": {
                                    "id": "43",
                                    "code": "43",
                                    "name": "leisti į raumenis",
                                    "displayValue": "leisti į raumenis"
                                }
                            },
                            {
                                "id": 20,
                                "title": "vartoti ant akių",
                                "group": "dosageType",
                                "field": {
                                    "id": "18",
                                    "code": "18",
                                    "name": "vartoti ant akių",
                                    "displayValue": "vartoti ant akių"
                                }
                            }
                        ]
                    };
                },
                getShortcuts: function () {
                    return AccountSettingsApiFactory.get();
                },
                saveShortcuts: function (data) {
                    return AccountSettingsApiFactory.save(data);
                },
                // 'compensation-type'
                getCompensationTypeCodes: function () {
                    return EntitiesApiFactory.getList({classCode: 'compensation-type'}, transform);
                    //return [
                    //    {code: '1', displayValue: 'Vaikui (100%)'},
                    //    {code: '2', displayValue: 'Invalidui (100%)'},
                    //    {code: '6', displayValue: 'Invalidui (80%)'},
                    //    {code: '3', displayValue: 'Liga pagal sąrašą (100%)'},
                    //    {code: '4', displayValue: 'Kitos'},
                    //    {code: '5', displayValue: 'Vaikui (80%)'},
                    //    {code: '6', displayValue: 'Invalidui (80%)'},
                    //    {code: '6', displayValue: 'Invalidui (50%)'},
                    //    {code: '7', displayValue: 'Pensininkui (80%)'},
                    //    {code: '7', displayValue: 'Pensininkui (50%)'},
                    //    {code: '8', displayValue: 'Liga pagal sąrašą (80%)'},
                    //    {code: '9', displayValue: 'Liga pagal sąrašą (50%)'},
                    //    {code: '10', displayValue: 'Centralizuotas pirkimas (100%)'},
                    //    {code: '11', displayValue: 'Centralizuotas pirkimas (80%)'},
                    //    {code: '12', displayValue: 'Liga pagal sąrašą (90%)'}
                    //];
                },
                getUnitsOfTime: function () {
                    return EntitiesApiFactory.getList({classCode: 'units-of-time', count: -1}, transform);
                },
                // 'pharmaceutical-form-measure-unit'
                getDosageForms: function () {
                    return EntitiesApiFactory.getList({classCode: 'pharmaceutical-form-measure-unit', count: -1}, transform);
                    //return [
                    //    {code: 'g', displayValue: 'g'},
                    //    {code: 'mg', displayValue: 'mg'},
                    //    {code: 'mm', displayValue: 'mm'},
                    //    {code: 'tabletes', displayValue: 'tabletės'},
                    //    {code: 'lasai', displayValue: 'lašai'},
                    //    {code: 'pack', displayValue: 'pakuotės'}
                    //];
                },
                // 'strength-unit'
                getMassAndVolumeUnits: function () {
                    return EntitiesApiFactory.getList({classCode: 'strength-unit'}, transform);
                    //return [
                    //    {code: '1', displayValue: 'mg'},
                    //    {code: '2', displayValue: 'g'},
                    //    {code: '3', displayValue: 'l'},
                    //    {code: '4', displayValue: 'ml'}
                    //];
                },
                // 'medication-prescription-urgency-tag'
                getUrgencyTags: function () {
                    return EntitiesApiFactory.getList({classCode: 'medication-prescription-urgency-tag', count: -1}, transform);
                    //return [
                    //    {code: 'CITO', displayValue: 'Skubiai'},
                    //    {code: 'STATIM', displayValue: 'Nedelsiant'}
                    //];
                },
                isPatientInsured: function () {
                    return PatientsApiFactory.getInsurance({id: $routeParams.patientId});
                },
                // 'medication-prescription-tag'
                getPrescriptionTags: function () {
                    return EntitiesApiFactory.getList({classCode: 'medication-prescription-tag', count: -1}, transform);
                    //return [
                    //    {code: "ier", displayValue: "Išrašomas elektroninis receptas"},
                    //    {code: "iprs", displayValue: "Išrašomas popierinis receptas dėl ESPBI IS sutrikimų"},
                    //    {code: "ipru", "displayValue": "Išrašomas popierinis receptas, nes asmuo įsigis vaistus užsienyje"},
                    //    {code: "rni", "displayValue": "Receptas nebus išrašomas"}
                    //];
                },
                // 'controlled-type'
                getControlledTypes: function () {
                    return EntitiesApiFactory.getList({classCode: 'controlled-type'}, transform);
                    //return [
                    //    {code: "P", displayValue: "Psichotropinė"},
                    //    {code: "N", displayValue: "Narkotinė"}
                    //];
                },
                getPharmaceuticalForms: function (text, count) {
                    return EntitiesApiFactory.getList({classCode: 'pharmaceutical-form', searchCriteria: text, count: count}, transform);
                },
                getCompositionData: function (compId) {
                    var deferred = $q.defer();
                    DocumentsApiFactory.searchForList({id: compId}, function (res) {
                        var data: any = {};
                        if (res.length > 0) {
                            var doc = res[0];
                            data.patientRef = doc.patient.fullId;
                            data.encounterRef = doc.encounterRef;
                            data.reasons = [];
                            _.each(doc.conditions, function (c) {
                                data.reasons.push({
                                    code: c.diagnosisCode,
                                    displayValue: c.diagnosisText,
                                    name: c.diagnosisText,
                                    fullDisplay: c.diagnosisCode + " " + c.diagnosisText
                                });
                            });
                            data.aagaSgas = doc.aagaSgas;
                            data.activeQualification = doc.practitionerQualification;
                        }
                        deferred.resolve(data);
                    });
                    return deferred.promise;
                }
            }
        }
    };
}]);


