<div class="card mb-2_5">
  <div class="card-body p-0">
      <ol class="slats hover">
          <li>
              <registrator-info [practitioner]="practitioner" [organization]="organization"></registrator-info>
          </li>
          <li>
              <organization-info [organization]="organization"></organization-info>
          </li>
      </ol>
  </div>
</div>
