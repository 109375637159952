import {
    AfterViewInit,
    Component, DestroyRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    multiCheckboxIsChecked,
    updateCheckboxControl,
} from '../../../../e096-utils/utils';
import { E096PartRecordComponent } from '../e096-part-record/e096-part-record.component';
import { take, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { validateBloodPressure } from '../../../../e096-utils/custom-validation';
import { ApiV2Practitioners } from '../../../../../../../../../api/api-v2-practitioners';
import { PractitionerModel } from '../../../../../../../../shared/models/practitioner.model';
import moment from 'moment';

@Component({
    selector: 'app-e096-part-entry-form',
    templateUrl: './e096-part-entry-form.component.html',
})
export class E096PartEntryFormComponent implements OnInit, AfterViewInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    @Input() editMode: boolean = false;
    @Input() fruitLength: string[];
    @Input() entryData: any;
    @Input() disableBtns: boolean = false;

    @Output() addNewEntry: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() removeEntry: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren(E096PartRecordComponent) recordComponents: E096PartRecordComponent[];

    formGroup: FormGroup;
    currentTime = new Date();
    currentDoctor: PractitionerModel;

    durationOfTheStrikeOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '< 20s'
        },
        {
            id: '1',
            code: '1',
            name: '20-40s'
        },
        {
            id: '2',
            code: '2',
            name: '> 40s'
        }
    ];

    fightsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '1'
        },
        {
            id: '1',
            code: '1',
            name: '2'
        },
        {
            id: '2',
            code: '2',
            name: '3'
        },
        {
            id: '3',
            code: '3',
            name: '4'
        },
        {
            id: '4',
            code: '4',
            name: '5'
        }
    ];

    urinatingOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pasišlapino'
        },
        {
            id: '1',
            code: '1',
            name: 'Kateterizuota'
        }
    ];

    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    bpValidator = validateBloodPressure.bind(this) as typeof validateBloodPressure;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
        private readonly apiV2Practitioners: ApiV2Practitioners
    ) {
        this.getCurrentDoctor();
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            timeOfEntry: this.fb.control(new Date(), [Validators.required]),
            durationOfTheStrike: this.fb.control(null),
            fights: this.fb.control(null),
            cervixOpening: this.fb.control(null, [Validators.required]),
            oxytocin: this.fb.control(null),
            pulse: this.fb.control(null, [Validators.min(1)]),
            bloodPressureSystolic: this.fb.control(null),
            bloodPressureDiastolic: this.fb.control(null),
            temperature: this.fb.control(null),
            methodOfPainRelief: this.fb.control([]),
            urinating: this.fb.control(null, [Validators.required]),
            decisionAndAppointments: this.fb.control(null),
            fetalRecords: this.fb.array([], [Validators.required, Validators.minLength(1)])
        }, {validators: [this.customValidateFruits.bind(this)]});

        this.bpValidator('bloodPressureSystolic', 'bloodPressureDiastolic')
            .pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }

    ngAfterViewInit() {
        this.updateFormData();
    }

    addEntry() {
        this.addNewEntry.emit({
            ...this.formGroup.getRawValue(),
            doctor: this.entryData?.doctor || this.currentDoctor,
            updatedTime: moment().toISOString()
        });
        this.formGroup.reset();
        this.edit.emit(false);
    }

    editEntry() {
        this.edit.emit(true);
        this.editMode = true;
        this.updateFormData();
    }

    cancel() {
        this.formGroup.reset();
        this.edit.emit(false);
    }

    remove() {
        this.removeEntry.emit();
        this.formGroup.reset();
        this.editMode = false;
    }

    doctorQualification(doctor: PractitionerModel) {
        return doctor?.qualificationList?.map(item => item.name)?.join(', ');
    }

    private customValidateFruits(): ValidationErrors | null {
        if (!this.fruitLength?.length) {
            return { 'atLeastOneFruitIsRequired': true };
        } else {
            return null;
        }
    }

    private updateFormData(): void {
        if (!this.editMode) return;
        this.formGroup.removeControl('fetalRecords')

        timer(0).pipe(take(1))
            .subscribe(() => {
                this.formGroup.addControl('fetalRecords',
                    this.fb.array(this.recordComponents.map(component => component.formGroup)));
                this.formGroup.get('fetalRecords').addValidators([Validators.required, Validators.minLength(1)]);

                if (this.entryData) {
                    this.formGroup.patchValue(this.entryData);
                }
            })
    }

    // TODO: implements instead get real doctor fn
    private getCurrentDoctor(): void {
        this.apiV2Practitioners.find().pipe(take(1))
            .subscribe(res => {
                this.currentDoctor = res.items[0];
            })
    }
}
