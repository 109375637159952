import {erxApiModule} from "./api";

import './api-erx-substances';
import './api-erx-templates';
import './api-analytics';
import './api-med-prescriptions';
import './api-erx-patients';
import './api-med-foreign-dispense';
import './api-erx-parameters';
import './api-documents';
import './api-drug-interactions';
import './api-classifiers';
import './erxApiConfigProvider';
import './api-med-dispense';
import './api-medications';
import './api-settings';

export default erxApiModule;