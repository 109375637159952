import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-e030-medical-data',
    templateUrl: './e030-medical-data.component.html',
})
export class E030MedicalDataComponent implements OnInit {
    @Input() editable: boolean = true;
    formGroup: FormGroup;

    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            complaints: this.fb.control(null, [Validators.required]),
            illnessAnamneeze: this.fb.control(null, [Validators.required]),
            psychiatricHistory: this.fb.control(null),
        });
    }
}
