angular.module('medvais.components').service('$medvaisOrderTelemedicine', ["$modal", "$rootScope", "$q", "$filter", "$window", "MedvaisOrganizationApiFactory", "MedvaisPractitionerApiFactory", "MedvaisOrderApiFactory", "MessageService", "$timeout", function (
        $modal, $rootScope, $q,
        $filter,
        $window,
        MedvaisOrganizationApiFactory,
        MedvaisPractitionerApiFactory,
        MedvaisOrderApiFactory,
        MessageService,
        $timeout) {
    
    var scope = $rootScope.$new();
    var deferred;
    
    scope.params = {};
    
    scope.params.selectedOrganization = null;
    scope.params.selectedOrganizationFullId = null;
    scope.params.selectedOrganizationId = null;

    scope.params.selectedPractitioner = null;
    scope.params.selectedPractitionerFullId = null;
    
    scope.params.telemedicineInfo = {};

    scope.organizationSearchTimeout;
    scope.practitionerSearchTimeout;

    scope.loadingOrganizations = false;
    scope.loadingPractitioners = false;


    
    scope.searchOrganization = function (value) {
        if(scope.organizationSearchTimeout) {
            $timeout.cancel(scope.organizationSearchTimeout);
        }
        
        if (!value || value.length < 3 || value.fullId) {
            scope.loadingOrganizations = false;
            return [];
        }

        scope.loadingOrganizations = true;
        return scope.organizationSearchTimeout = $timeout(function () {
            return MedvaisOrganizationApiFactory.search({
                searchValue: value,
                excludeJarCode: scope.params.telemedicineInfo.order.jarCode
            }).$promise.then(
                function (response) {
                    return _.map(response, function (v) {
                        v.label = v.name + '( ' + (v.jarCode ? v.jarCode : '-') + ' )';
                        return v;
                    });

                }
            ).finally(
                function () {
                    scope.loadingOrganizations = false;
                    scope.organizationSearchTimeout = null;
                }
            );
        }, 2000);
    };
    
    
    scope.searchOrganizationChange = function (org) {
        if (org && org.id) {
            scope.params.selectedOrganizationFullId = org.fullId;
            scope.params.selectedOrganizationId  = org.id;
        }
    };

    scope.changeSelectedOrganization = function () {
        scope.params.selectedOrganizationFullId = null;
        scope.params.selectedOrganizationId  = null;
        scope.params.selectedOrganization = null;
        scope.params.selectedPractitioner = null;
        scope.params.selectedPractitionerFullId = null;
    };
    
    
    scope.searchPractitioner = function (value) {
        if(scope.practitionerSearchTimeout) {
            $timeout.cancel(scope.practitionerSearchTimeout);
        }

        if (!value || value.length < 3 || value.fullId) {
            scope.loadingPractitioners = false;
            return [];
        }

        console.log(value);

        scope.loadingPractitioners = true;
        return scope.practitionerSearchTimeout = $timeout(function () {

            return MedvaisPractitionerApiFactory.search({
                organizationId: scope.params.selectedOrganizationId,
                query: value
            }).$promise.then(
                function (response) {
                    return _.map(response, function (v) {
                        v.label = v.givenName + ' ' + v.familyName;
                        return v;
                    });

                }
            ).finally(
                function () {
                    scope.loadingPractitioners = false;
                    scope.practitionerSearchTimeout = null;
                }
            );
        }, 2000);
    };


    scope.searchPractitionerChange = function (practitioner) {
        if (practitioner && practitioner.id) {
            scope.params.selectedPractitionerFullId = practitioner.fullId;
        } 
    };

    scope.changeSelectedPractitioner = function () {
        scope.params.selectedPractitioner = null;
        scope.params.selectedPractitionerFullId = null;
    };
    

    scope.orderTelemedicine = function () {
        scope.params.telemedicineInfo.order.targetRef = 
            scope.params.selectedPractitionerFullId
                ? scope.params.selectedPractitionerFullId
                : scope.params.selectedOrganizationFullId;
                
        console.log("Selected telemedicine target ref: " + scope.params.telemedicineInfo.order.targetRef);
        MedvaisOrderApiFactory.orderTelemedicine(scope.params.telemedicineInfo).$promise.then(
            function () {
                deferred.resolve("true");
                modal.hide();
            }, 
            function(err) {
                MessageService.clearAll();
                MessageService.add('error', $filter('translate')('medvais.err.msg.telemedicine.registration.failed'));
                $window.scrollTo(0, 10);
                modal.hide();
            }
        );
    };

    var modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-order-telemedicine.html',
        title: $filter('translate')('medvais.order.telemedicine.modal.title'),
        scope: scope,
        show: false});

    var parentShow = modal.show;

    modal.show = function (orderInfo, diagnosticReportRef) {
        console.log("Diagnostic report ref: " + diagnosticReportRef);
        scope.params.telemedicineInfo.order = orderInfo;
        scope.params.telemedicineInfo.fulfillmentRef = diagnosticReportRef;
        scope.params.selectedOrganization = null;
        scope.params.selectedOrganizationFullId = null;
        scope.params.selectedOrganizationId = null;
        scope.params.selectedPractitioner = null;
        scope.params.selectedPractitionerFullId = null;
        parentShow();
        deferred = $q.defer();
        return deferred.promise;
    };

    return modal;
    
    
}]);