angular.module('erx.prs')
        .provider("erxPrescriptionListConfig", function () {
          var self = this;
          this.options = {};
          this.$get = function () {
            var extend: any = {};
            extend.options = self.options;
            return extend;
          };
          return this;
        });
