import {Component, Input, OnInit,} from "@angular/core";

@Component({
    selector: 'link-status-badge',
    templateUrl: 'link-status-badge.component.html',
})
export class LinkStatusBadgeComponent implements OnInit {
    @Input() status: boolean = false;
    @Input() message: string;

    constructor() {

    }

    ngOnInit(): void {
        if (!this.message) {
            this.message = "sideMenu.link.message";
        }
    }
}