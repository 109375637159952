angular.module('erx.api').factory('ErxDocumentsApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Document';
  return $resource('', {}, {
    
    sign: {
      method: 'GET',
      url: URL_BASE + '/:id/sign'
    },

    multisign: {
      method: 'GET',
      url: URL_BASE + '/sign'
    },

    confirmSigning: {
      method: 'GET',
      url: URL_BASE + '/sign/:transactionId/confirm'
    }

    
  });
}]);