import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { defineLocale, ltLocale } from "ngx-bootstrap/chronos";
defineLocale("lt", ltLocale);

@Component({
    selector: "app-time-picker",
    templateUrl: "./time-picker.component.html",
})
export class TimePickerComponent implements OnInit {
    @Input({ required: true }) fControl: FormControl;
    @Input() hoursPlaceholder: string = "00";
    @Input() minutesPlaceholder: string = "00";
    @Input() required?: boolean;
    @Input({ required: true }) inputHoursId: string;
    @Input({ required: true }) inputMinutesId: string;
    @Input() labelText: string;
    @Input() labelClass: string;
    @Input() errorClass: string;
    @Input() disabled?: boolean;
    @Output() timePickerChange = new EventEmitter();

    public pickerOpened: boolean;
    public hoursArray: number[] = Array.from({ length: 24 }, (_, index) => index);
    public minutesArray: number[] = Array.from({ length: 60 }, (_, index) => index);
    public timeFormControl: FormControl = new FormControl(null);
    public hourFormControl: FormControl = new FormControl(null);
    public minuteFormControl: FormControl = new FormControl(null);

    constructor(private el: ElementRef) {}

    @HostListener("document:click", ["$event.target"])
    onClick(target: any) {
        const clickedInside = this.el.nativeElement.contains(target);
        if (!clickedInside) {
            this.openClose(false);
        }
    }

    ngOnInit(): void {
        this.hourFormControl.patchValue(this.fControl.value.substring(0, 2));
        this.minuteFormControl.patchValue(this.fControl.value.substring(3, 5));
    }

    public openClose(onClick: boolean): void {
        if (onClick && !this.pickerOpened) {
            this.pickerOpened = !this.pickerOpened;
        }

        if (!onClick && this.pickerOpened) {
            this.pickerOpened = !this.pickerOpened;
        }
    }

    private scrollTo(scrollElement: HTMLElement, hours: boolean, value) {
        if (scrollElement) {
            const total = hours ? 24 : 60;
            const elShown = total / Math.ceil(scrollElement.scrollHeight / scrollElement.offsetHeight);
            const modHeight = Math.floor(parseInt(value) / elShown);
            scrollElement.scrollTop = scrollElement.offsetHeight * modHeight;
        }
    }

    public setTime(
        hours: boolean,
        event?: any,
        increase?: boolean,
        scrollElement?: HTMLElement,
        hourMinutEvent?: any
    ): void {
        const timeFormControl = this.fControl;
        const timeValue = timeFormControl.value ? timeFormControl.value : "0";

        let formatedEventValue = hourMinutEvent?.target?.value || event?.target?.value;

        const eventValue = formatedEventValue || timeValue;
        const value = eventValue < 10 ? "0" + eventValue : eventValue;

        const pattern = /^\d{2}:\d{2}$/;

        if (hours) {
            if (formatedEventValue) {
                const time = pattern.test(timeValue) ? `${value}:${String(timeValue).substring(3)}` : `${value}:00`;
                timeFormControl.patchValue(time);
            } else {
                let incrementedNumber = String(increase ? parseInt(value, 10) + 1 : parseInt(value, 10) - 1);

                if (incrementedNumber === "-1") {
                    incrementedNumber = "23";
                }

                if (incrementedNumber === "24") {
                    incrementedNumber = "00";
                }

                const editedValue = incrementedNumber.toString().padStart(2, "0");
                const time = pattern.test(timeValue)
                    ? `${editedValue}:${String(timeValue).substring(3)}`
                    : `${editedValue}:00`;
                timeFormControl.patchValue(time);
                if (scrollElement) {
                    this.scrollTo(scrollElement, hours, editedValue);
                }
            }
        } else {
            if (formatedEventValue) {
                const time = pattern.test(timeValue) ? `${String(timeValue).substring(0, 2)}:${value}` : `00:${value}`;
                timeFormControl.patchValue(time);
            } else {
                const valForIncrease = value.substring(3);
                let incrementedNumber = String(
                    increase ? parseInt(valForIncrease, 10) + 1 : parseInt(valForIncrease, 10) - 1
                );

                if (incrementedNumber === "-1") {
                    incrementedNumber = "59";
                }

                if (incrementedNumber === "60") {
                    incrementedNumber = "00";
                }

                const editedValue = incrementedNumber.toString().padStart(2, "0");

                const time = pattern.test(timeValue)
                    ? `${String(timeValue).substring(0, 2)}:${editedValue}`
                    : `00:${editedValue}`;
                timeFormControl.patchValue(time);
                if (scrollElement) {
                    this.scrollTo(scrollElement, hours, editedValue);
                }
            }
        }
    }

    public isSelected(hour: boolean, value: string): boolean {
        const valueWithZero = Number(value) < 10 ? "0" + value : String(value);
        return hour
            ? this.fControl?.value?.substring(0, 2) === valueWithZero
            : this.fControl?.value?.substring(3) === valueWithZero;
    }
}
