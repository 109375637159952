ng1App.controller('CommonPatientRepresentativesListCtrl', 
    ['$scope', 'PersonRepresentationsApiFactory', 'MessageService', 'SearchController', 'AccountSettingsApiFactory', 'allowPatientCreateRepresentations', 'portalType',
     function ($scope, PersonRepresentationsApiFactory, MessageService, SearchController, AccountSettingsApiFactory,
                                                                    allowPatientCreateRepresentations, portalType) {
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    $scope.canCreate = portalType == 'patient' ? allowPatientCreateRepresentations : true;
    $scope.portalType = portalType;

    if (portalType === 'patient') {
        $scope.employerRepresentativeTypeMessage = loadEmployerRepresentativeTypeMessage();
    }

    var addCommonParams = function (query) {
        query.type = 'PATIENT';
        query.personId = $scope.patientId;
        if(portalType != 'admin'){
            query.valid = true;
            query.blocked = false;
        }
        return query;
    };

    var allFilters = [
        // All
        {
            title: 'dpp.pat.rep.rec.all',
            count: 0,
            query: {}
        },
        // Valid
        {
            title: 'dpp.pat.rep.rec.val',
            count: 0,
            query: {valid: true}
        },
        // Invalid
        {
            title: 'dpp.pat.rep.rec.inv',
            count: 0,
            query: {valid: false}
        },
        // Blocked
        {
            title: 'dpp.pat.rep.rec.blo',
            count: 0,
            query: {blocked: true}
        },
        // Not blocked
        {
            title: 'dpp.pat.rep.rec.unb',
            count: 0,
            query: {blocked: false},
        },
        // Representing by law
        {
            title: 'dpp.pat.rep.rec.law',
            count: 0,
            query: {representativeType: ['3']}
        },
        // Authorised representatives
        {
            title: 'dpp.pat.rep.rec.aut',
            count: 0,
            query: {representativeType: ['4']}
        },
        // Employer representatives
        {
            title: 'dpp.pat.rep.rec.emp',
            count: 0,
            query: {representativeType: ['5']}
        }
    ];

    var patientFilters = [
        // All
        {
            title: 'dpp.pat.rep.rec.all',
            count: 0,
            query: {}
        },
        // Representing by law
        {
            title: 'dpp.pat.rep.rec.law',
            count: 0,
            query: {representativeType: ['3']}
        },
        // Authorised representatives
        {
            title: 'dpp.pat.rep.rec.aut',
            count: 0,
            query: {representativeType: ['4']}
        },
        // Employer representatives
        {
            title: 'dpp.pat.rep.rec.emp',
            count: 0,
            query: {representativeType: ['5']}
        }
    ];

    $scope.filters = portalType == 'admin' ? allFilters : patientFilters;

    $scope.searchCtrl = new SearchController({
        viewId: $scope.viewId,
        count: 10,
        initForm: function ( ) {
            return {filter: $scope.filters[0]};
        },
        searchApi: PersonRepresentationsApiFactory.getList,
        _handleResult: function (data) {
            if (data && portalType === 'patient') {
                setMessageForEmployerRepresentations(data.items);
            }
            return true;
        },
        showPageCounter: true
    });

    $scope.searchCtrl.formToQuery = function (form) {
        var query: any = {};
        addCommonParams(query);
        switch (form._searchType) {
            case 'advanced':
                break;
            case 'filter':
                angular.extend(query, form.filter.query);
                break;
            case 'quick':
                form.filter = undefined;
                query.text = form.text;
                break;
        }

        return query;
    };

    AccountSettingsApiFactory.get(function (data) {
        $scope.searchCtrl.setCount(data.count_per_page || 10);
        $scope.searchCtrl.searchLastOrAll();
    });

    // Count records
    _.each($scope.filters, function (filter) {
    	filter.query.count = 0;
        PersonRepresentationsApiFactory.getList(addCommonParams(filter.query), function (data) {
            filter.count = data.total;
        });
    });

    $scope.changeFilter = function (filter) {
        $scope.searchCtrl.clear();
        $scope.searchCtrl._form.filter = filter;
        $scope.searchCtrl.searchFilter();
    };

    function loadEmployerRepresentativeTypeMessage() {
        return PersonRepresentationsApiFactory.getRepresentativeTypeMessage({representativeTypeId: '5'});
    }

    function setMessageForEmployerRepresentations(representations) {
        $scope.employerRepresentativeTypeMessage.$promise.then(function (result) {
            const message = (result || {}).message;
            if (message) {
                _.each(representations, function (representation) {
                    const ofEmployerRepresentativeType = representation.representativeType.code === '5';
                    const representationValid = representation.isValid && !representation.isBlocked;
                    if (ofEmployerRepresentativeType && representationValid) {
                        representation.representativeTypeMessage = result.message;
                    }
                })
            }
        });
    }

}]);