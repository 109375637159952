import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {DiagnosisModel, ExtendedDiagnosisModel} from "../../../models/diagnosis.model";
import {RouteParams} from "../../../../ng1.routeParams";
import {ApiV2Patients} from "../../../services/api-v2-patients";
import {ApiV2Session} from "../../../../../api/api-v2-session";

@Component({
    selector: 'diagnosis-by-encounter',
    templateUrl: './diagnoses-by-encounter.html'
})
export class DiagnosisByEncounterComponent implements OnInit {
    @Input() patientId: string;
    @Input() practitionerId: number;
    @Input() encounterId: string;
    @Input() currentDiagnoses: ExtendedDiagnosisModel[];
    @Output() onClose = new EventEmitter<DiagnosisModel[]>();
    selectAll = false;
    @Output() showModal = new EventEmitter<boolean>();

    constructor(private params: RouteParams, private apiV2Patients: ApiV2Patients,
                private modalRef: BsModalRef, private apiV2Session: ApiV2Session) {
    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.encounterId = this.params.encounterId;
    }

    includeSelectedDiagnoses(): void {
        const selectedDiagnoses = this.currentDiagnoses
            .filter(diagnose => diagnose.selected)
            .map(({selected, ...diagnose}) => diagnose); // Destructuring to exclude the 'selected' property
        this.onClose.emit(selectedDiagnoses); // Emitting without the 'selected' property
        this.close();
    }

    toggleSelectAll(): void {
        this.currentDiagnoses.forEach(diagnose => {
            diagnose.selected = this.selectAll;
        });
    }

    onDiagnosisSelectionChange(): void {
        this.updateSelectAllState();
    }

    updateSelectAllState(): void {
        this.selectAll = this.currentDiagnoses.every(diagnose => diagnose.selected);
    }

    loadDiagnoses(patientId: string, practitionerId: number, encounterId: string): void {
        this.apiV2Patients.getDiagnosisByEncounter(patientId, practitionerId, encounterId)
        .subscribe(response => {
            this.currentDiagnoses = response.map(item => ({
                ...item.diagnose,
                selected: true
            }));
            this.selectAll = true;
        });
    }

    close(): void {
        this.modalRef.hide();
    }
}