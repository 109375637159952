ng1App.factory('MedicationsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/medications';
    return $resource('', {}, {
        search: {
            method: 'GET',
            url: URL_BASE,
            isArray: true
        },
        searchCovidVaccines: {
            method: 'GET',
            url: URL_BASE + '/covid-vaccines',
            isArray: true
        },
        searchProductNames: {
            method: 'GET',
            url: URL_BASE + '/product-names',
            isArray: true
        },
    });
}]
);