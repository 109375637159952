angular.module('erx.prs').directive('erxPrintPrescriptions', ["$window", function ($window) {
  var printPrescriptionsCtrl = function ($scope, ErxPrescriptionsApiFactory) {
    // Skyrimų gavimas pagal kompozicijos ID
    $scope.getMedPrescriptions = function () {
      $scope.promise = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount({compositionId: $scope.options.getCompositionId()}, function (res) {
        $scope.prescriptions = res.result;
      });
    };

    $scope.print = function () {
      $window.print();
    }

    $scope.isLoading = function () {
      return !$scope.promise || !$scope.promise.$resolved;
    }

    $scope.getMedPrescriptions();
  };
  printPrescriptionsCtrl.$inject = ["$scope", "ErxPrescriptionsApiFactory"];
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/erx/prs/prescription_form/print_prescriptions/print_prescriptions.html',
    controller: printPrescriptionsCtrl
  }
}]);

