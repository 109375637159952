import {Component, Input, OnInit} from "@angular/core";
import {ErxPrescriptionTypeEnum} from "src/app/shared/models/erx-prescription-type.enum";
import {PractitionerModel} from "../../../models/practitioner.model";
import {OrganizationModel} from "../../../models/organization.model";
import {Router} from "@angular/router";
import {RouteParams} from "../../../../ng1.routeParams";
import {TranslateService} from "@ngx-translate/core";
import {MedicationPrescriptionStatusEnum} from "../../../models/medicationPrescriptionStatus.enum";
import {EnvironmentSettings} from "../../../../../environments/env";
import {Environment} from "../../../../../environments/environment-properties";
import PortalType = Environment.PortalType;
import moment from "moment";

@Component({
    selector: "recipe-data",
    templateUrl: "./recipe-data.component.html",
})
export class RecipeDataComponent implements OnInit {
    protected readonly ErxPrescriptionTypeEnum = ErxPrescriptionTypeEnum;
    @Input() prescriptionType: ErxPrescriptionTypeEnum;
    @Input() prescription: any;
    @Input() dosageSummary: string = "";
    @Input() practitioner: PractitionerModel;
    @Input() organization: OrganizationModel;
    medicationData: any;
    extempDrugName: any;
    extempDrugDescription: any;
    isActiveSubstanceType: boolean = false;
    patientId: string;
    prescriptionId: string;
    docId: string;

    constructor(private router: Router, private routeParams: RouteParams, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.patientId = this.routeParams.patientId;
        this.prescriptionId = this.routeParams.prescriptionId ? this.routeParams.prescriptionId : this.prescription?.docNumber;
        this.medicationData = this.prescription.prescriptionData?.medicationData ? this.prescription.prescriptionData?.medicationData : this.prescription?.medicationData;
        this.docId = this.prescription?.metadata?.docId;

        if (this.prescriptionType === ErxPrescriptionTypeEnum.EV) {
            if (this.medicationData.ingredients?.length > 0) {

                this.isActiveSubstanceType = !this.medicationData.ingredients[0]?.medication;
                if (!this.isActiveSubstanceType) {
                    this.medicationData = this.medicationData.ingredients[0]?.medication;
                }
                if (this.isActiveSubstanceType) {
                    this.extempDrugName = this.medicationData.ingredients[0]?.substance.name;
                    this.extempDrugDescription = this.medicationData.extemporaneous.description;
                }
            }
        }
    }

    getDaysDiff(): number {
        return moment(this.prescription.prescriptionData?.dispenseData?.validUntil).diff(this.prescription.prescriptionData?.dispenseData?.validFrom, 'days');
    }

    getDosageString(): string {
        return this.dosageSummary;
    }

    onRecipeNoClick(): void {
        if (EnvironmentSettings.getPortalType() === PortalType.PHARMACIST) {
            this.router.navigate([`patients/${this.patientId}/presc/${this.prescriptionId}`]);
        } else {
            this.router.navigate([`dp/patients/${this.patientId}/erx/prs/${this.prescriptionId}/doc`]);
        }
    }

    onDownloadClick(): void {
        if (EnvironmentSettings.getPortalType() === PortalType.PHARMACIST) {
            window.open(`/api/Document/${this.prescriptionId}/signedPdf`);
        } else {
            window.open(`api/doctor/erx/doctor/Document/${this.prescriptionId}/signedPdf`);
        }
    }

    getStatusPillColor(): string {
        const status = this.prescription?.prescriptionData?.status?.code;
        switch (status) {
            case MedicationPrescriptionStatusEnum.ACTIVE:
                return 'text-bg-success';
            case MedicationPrescriptionStatusEnum.STOPPED:
                return 'text-bg-warning';
            case MedicationPrescriptionStatusEnum.COMPLETED:
                return 'text-bg-primary';
            case MedicationPrescriptionStatusEnum.ON_HOLD:
            case MedicationPrescriptionStatusEnum.ON_HOLD_FOR_REVIEW:
            case MedicationPrescriptionStatusEnum.ON_HOLD_RESERVED:
            case MedicationPrescriptionStatusEnum.ENTERED_IN_ERROR:
                return 'text-bg-danger';
            default:
                return undefined;
        }
    }

    getStatusTranslation(status): string {
        return status ? this.translateService.instant(status) : undefined;
    }
}