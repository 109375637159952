import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RouteParams} from '../../../../ng1.routeParams';
import {FormControl} from '@angular/forms';
import {map, Observable, Observer, of, switchMap, take} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EditHealthFactorModalComponent} from './edit-health-factor-modal/edit-health-factor-modal.component';
import {ApiV2Risks} from '../../../../shared/services/api-v2-risks';
import {RiskFactorModel} from '../../../models/risk-factor.model';
import moment from 'moment';
import {ApiV2Entities} from '../../../../shared/services/api-v2-entities';
import {
    CurrentRisksListModalComponent
} from "../../../../shared/components/current-risks-list-modal/current-risks-list-modal.component";
import {EntityModel} from "../../../../shared/models/entity.model";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";

@Component({
    selector: 'app-assessment-of-health-factors-form',
    templateUrl: './assessment-of-health-factors-form.component.html'
})
export class AssessmentOfHealthFactorsFormComponent implements OnInit {
    backUrl: string;
    riskFactor = new FormControl();
    currentRisks: RiskFactorModel[];
    @Input() newRisks: RiskFactorModel[] = [];
    @Output() newRisksChange = new EventEmitter<RiskFactorModel[]>();
    private patientId: string;
    riskFactorsSuggestions$?: Observable<RiskFactorModel[]>;
    @Input() viewOnly: boolean = false;
    @Input() isEditMode: boolean = false;

    constructor(
        private params: RouteParams,
        private modalService: BsModalService,
        private apiV2Risks: ApiV2Risks,
        private apiV2Entities: ApiV2Entities,
    ) {
    }


    async ngOnInit(): Promise<void> {
        this.patientId = this.params.patientId;
        this.backUrl = "/dp/patients/" + this.patientId;
        this.newRisks = this.newRisks ? this.newRisks : [];

        this.initializeRiskSuggestions();
        this.apiV2Risks.getRisksList({patient: this.patientId}).subscribe(res => {
            this.currentRisks = res?.items;
        });
    }

    openAddRiskFactorModal(selectedRiskF: TypeaheadMatch) {
        if (!this.riskFactor.value) {
            return;
        }
        const initialState = {
            risk: {
                name: selectedRiskF?.item as EntityModel,
                riskFactor: selectedRiskF?.item as EntityModel,
                date: moment().toISOString(),
                riskFactorMethodologyName: this.riskFactor.value === 'Alkoholio vartojimas'
                    ? 'AUDIT ir AUDIT-C'
                    : this.riskFactor.value === 'Rūkymas'
                        ? 'Fagerstromo testas'
                        : null
            } as RiskFactorModel,
            isEditMode: this.isEditMode
        };
        const ref = this.modalService.show(EditHealthFactorModalComponent, {
            initialState,
            animated: true,
            class: 'modal-xxl'
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: RiskFactorModel | void) => {
            this.riskFactor.reset();
            if (!result) {
                return;
            }
            this.newRisks.push(result);
            this.emitCurrentRisks();
        });
    }

    openEditRiskModal(i: number) {
        const initialState = {
            risk: this.newRisks[i],
            isEditMode: this.isEditMode
        };
        const ref = this.modalService.show(EditHealthFactorModalComponent, {
            initialState,
            animated: true,
            class: 'modal-xxl'
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: RiskFactorModel | void) => {
            this.riskFactor.reset();
            if (!result) {
                return;
            }
            this.newRisks[i] = {...result};
            this.emitCurrentRisks();
        });
    }

    delete(i: number) {
        this.newRisks.splice(i, 1);
        this.emitCurrentRisks();
    }

    openCurrentsRiskFactorsModal(): void {
        const initialState = {
            risks: this.currentRisks
        }
        const ref = this.modalService.show(CurrentRisksListModalComponent, {
            initialState,
            animated: true,
            class: 'modal-xxl'
        });
    }

    initializeRiskSuggestions() {
        this.riskFactorsSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.riskFactor.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Entities
                        .getEntitiesList('health-risk-factor', {searchCriteria: query})
                        .pipe(map((data: any[]) =>
                            data && data.map((riskF) => riskF || [])));
                }
                return of([]);
            })
        );
    }

    private emitCurrentRisks(): void {
        this.newRisksChange.emit(this.newRisks);
    }
}
