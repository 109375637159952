import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { DocRefAttachedUnsortedModel } from "src/app/shared/models/doc-ref-attached-unsorted.model";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { QueryModel } from "src/app/shared/models/query.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2AttachedUnsorted extends ConfigService {

    path = this.apiUrl + '/doc-ref-attached-unsorted';

    getAttachedUnsortedList(query?: any): Observable<DocRefAttachedUnsortedModel[] | FilteredDataModel> {
        let params = new HttpParams();

        // params = params.append('ftq', query);
        // params = params.append('page', String(page));
        console.log("query",query);
        if(query.quick_search){
            //console.log("bbbbb");
            params = params.append('ftq', query.quick_search);
            params = params.append('page', String(1)); // 1 = page
            return this.http.get<FilteredDataModel>(this.path + '/list', {params});
        } else if (query.dateFrom || query.dateTo || query.recipient || query.specialist || query.unsorted_document_type){
            //console.log("ccccc");
            params = params.append('dateFrom', Date.parse(query.dateFrom) ||"");
            params = params.append('dateTo', Date.parse(query.dateTo) ||"");
            params = params.append('recipient', query.recipient ||"");
            params = params.append('specialist', query.specialist ||"");
            params = params.append('unsorted_document_type', query.unsorted_document_type ||"");
            params = params.append('page', String(1)); // 1 = page
            return this.http.get<FilteredDataModel>(this.path + '/list', {params});
        }
        //console.log("ddddd");
        return this.http.get<DocRefAttachedUnsortedModel[]>(this.path + '/list');
    }

    getAttachedUnsortedView(id: string): Observable<DocRefAttachedUnsortedModel> {
        return this.http.get<DocRefAttachedUnsortedModel>(this.path + '/view/' + id);
    }

    submitAttachedUnsortedView(payload: DocRefAttachedUnsortedModel, id: string): Observable<DocRefAttachedUnsortedModel> {
        return this.http.post<DocRefAttachedUnsortedModel>(this.path + '/create/' + id, payload);
    }

    saveAttachedUnsortedView(payload: DocRefAttachedUnsortedModel, id: string): Observable<DocRefAttachedUnsortedModel> {
        return this.http.post<DocRefAttachedUnsortedModel>(this.path + '/save/' + id, payload);
    }
}