ng1App.factory('SveidraApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/sveidraForms';
    return $resource('', {}, {
        getPrefilledForm: {
            method: 'GET',
            url: URL_BASE + '/prefilledForm'
        },
        createForm: {
        	method: 'POST',
        	url: URL_BASE
        },
        updateForm: {
        	method: 'PUT',
        	url: URL_BASE + '/:id'
        },
        closeForm: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/close'
        },
        getForm: {
        	method: 'GET',
        	url: URL_BASE + '/:id'
        },
        getFormForEdit: {
        	method: 'GET',
        	url: URL_BASE + '/:id/forEdit'
        },
        deleteForm: {
        	method: 'DELETE',
        	url: URL_BASE + '/:formId'
        },
        getList: {
        	method: 'GET',
        	url: URL_BASE
        },
        getCount: {
        	method: 'GET',
        	url: URL_BASE + '/count'
        },
        getPrefilledEncounter: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/encounters/prefilled'
        },
        createEncounter: {
        	method: 'POST',
        	url: URL_BASE + '/:formId/encounters'
        },
        updateEncounter: {
        	method: 'PUT',
        	url: URL_BASE + '/:formId/encounters/:id'
        },
        getEncounter: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/encounters/:id'
        },
        getEncounters: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/encounters',
        	isArray: true
        },
        deleteEncounter: {
        	method: 'DELETE',
        	url: URL_BASE + '/:formId/encounters/:id'
        },
        getPrefilledStage: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/stages/prefilled'
        },
        createStage: {
        	method: 'POST',
        	url: URL_BASE + '/:formId/stages'
        },
        updateStage: {
        	method: 'PUT',
        	url: URL_BASE + '/:formId/stages/:id'
        },
        getStageList: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/stages',
        	isArray: true
        },
        getStage: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/stages/:id'
        },
        getStageForEdit: {
        	method: 'GET',
        	url: URL_BASE + '/:formId/stages/:id/forEdit'
        },
        closeStage: {
        	method: 'PUT',
        	url: URL_BASE + '/:formId/stages/:id/close'
        },
        deleteStage: {
        	method: 'DELETE',
        	url: URL_BASE + '/:formId/stages/:id'
        },
        getDepartments: {
        	method: 'GET',
        	url: URL_BASE + '/departments'
        }
    });
}]);