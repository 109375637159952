import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../../models/entity.model';
import {
    controlIsDisabled,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
import { map, Observable } from 'rxjs';
import { ApiV2Inr } from '../../../services/api-v2-inr';
@Component({
    selector: "app-inr-other-used-medical-products-subform",
    templateUrl: "./inr-other-used-medical-products-subform.component.html",
})
export class InrOtherUsedMedicalProductsSubformComponent {
    @Input() editable: boolean = true;

    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];
    productSelectionVariants: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pasirinkti vaistinį preparatą iš pardavimo dokumento'
        },
        {
            id: '1',
            code: '1',
            name: 'Pasirinkti vaistinį preparatą iš katalogo'
        },
        {
            id: '2',
            code: '2',
            name: 'Įvesti pačiam vaistinio preparato duomenis'
        }
    ];

    formGroup: FormGroup;
    productSelectionControl: FormControl = this.fb.control(null);
    searchProduct: FormControl = this.fb.control(null);
    maxDate = new Date();

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    getDrugsObs = this.getDrugs.bind(this);
    constructor(
        private fb: FormBuilder,
        private apiV2Inr: ApiV2Inr
    ) {
        this.formGroup = this.fb.group({
            useOtherMedicineProduct: this.fb.control(null),
            medicinalProductName: this.fb.control(null),
            pharmaceuticalForm: this.fb.control(null),
            strength: this.fb.control(null),
            administrationMethod: this.fb.control(null),
            dailyDose: this.fb.control(null),
            startedUsingDate: this.fb.control(null),
            endUsingDate: this.fb.control(null),
            prescribedMedicinalProductIndications: this.fb.control(null),
            usedWithOtherMedicinalProducts: this.fb.control(null),
        });

        this.changeStatusOfControl({
            controlNameForListen: 'useOtherMedicineProduct',
            controlNameForUpdate: ['pharmaceuticalForm', 'strength', 'administrationMethod', 'dailyDose', 'startedUsingDate', 'endUsingDate', 'prescribedMedicinalProductIndications', 'usedWithOtherMedicinalProducts'],
            enableValue: 'Taip',
        }).pipe(takeUntilDestroyed())
            .subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'useOtherMedicineProduct',
            controlNameForUpdate: 'medicinalProductName',
            enableValue: 'Taip',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed())
            .subscribe();
    }

    getDrugs(query: string): Observable<any> {
        return this.apiV2Inr.getMockDrugs({q: query}).pipe(
            map(item => item
                .map(p => {
                    p['fullDisplay'] =`${ p.medicinalProductName } ${ p.dailyDose }mg/ml (${ p.administrationMethod }) ${ p.activeSubstanceName } ${ p.medicinalProductSerialNumber }`;
                    return p;
                })
            ))
    }

    selectDrug(drug: any) {
        this.formGroup.patchValue(drug);
    }
}
