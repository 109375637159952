angular.module('erx.utils').service('$erxModalWarningExpl', ["$modal", "$rootScope", "$q", "gettextCatalog", function ($modal, $rootScope, $q, gettextCatalog) {
  var scope = $rootScope.$new();
  scope.data = {};
  var deferred;
  scope.submit = function () {
    deferred.resolve(scope.data.warnings);
    confirm.hide();
  };

  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-warning-expl/erxModalWarningExpl.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (warnings, opts) {
    scope.data = {};
    scope.data.warnings = warnings;
    scope.data.title = opts.title ? opts.title : gettextCatalog.getString('Įspėjimai');
    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };

  return confirm;
}]);