ng1App.controller("doctor.patient.cert.NespEditCtrl", [
    "$scope",
    "$routeParams",
    "$filter",
    "$window",
    "$location",
    "DocumentsApiFactory",
    "EptsCertificatesApiFactory",
    "MessageService",
    "TypeaheadSearchHandlers",
    "DocumentSpinnerHelper",
    "EntitiesApiFactory",
    "$modalConfirm",
    "$routeSegment",
    "$q",
    "FormLoaderService",
    function (
        $scope,
        $routeParams,
        $filter,
        $window,
        $location,
        DocumentsApiFactory,
        EptsCertificatesApiFactory,
        MessageService,
        TypeaheadSearchHandlers,
        DocumentSpinnerHelper,
        EntitiesApiFactory,
        $modalConfirm,
        $routeSegment,
        $q,
        FormLoaderService
    ) {
        MessageService.clear();

        $scope.$emit("setHelpPage", "pildyti-nestumo-ir-gimdymo-atostogu-pazymejima");

        FormLoaderService.startFormLoading();

        $scope.patientId = $routeParams.patientId;

        // Data initialization
        $scope.pregnancyDaysValues = [
            { specific: true, value: 126 },
            { specific: true, value: 14 },
            { specific: true, value: 56 },
            { specific: true, value: 70 },
            { specific: true, value: 28 },
            { specific: true, value: 42 },
            { specific: true, value: 98 },
            { specific: false, value: $filter("translate")("cer.nesp.cer.days.to70") },
        ];

        $scope.pregnancyDaysExtansionValues = [14, 98];

        // Additional parameters
        $scope.additionalParameters = {
            complicatedChildbirth: false,
            complicatedChildbirthFlagNotEditable: false,
            pregnancyDays: $scope.pregnancyDaysValues[0],
            pregnancyDaysExtention: $scope.pregnancyDaysExtansionValues,

            spinner: DocumentSpinnerHelper,
        };

        $scope.pregnancyVacationCertificate = {
            submissionDate: $routeParams.submissionDate,
            documentId: $routeParams.documentId,

            number: null,
            series: null,
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: null,
            childBirthDate: null,
            childBirthComplication: null,
        };

        $scope.document = DocumentsApiFactory.getByComposition({ id: $routeParams.documentId }, function (document) {
            $scope.patientData = document.metadata.patient;
            $scope.practitioner = document.metadata.practitioner;
            $scope.organization = document.metadata.custodian;

            $scope.pregnancyVacationCertificate.organization = document.metadata.custodian;
            $scope.pregnancyVacationCertificate.practitioner = document.metadata.practitioner;
            $scope.pregnancyVacationCertificate.person = document.metadata.patient;

            // Fix for person address
            if ($scope.pregnancyVacationCertificate.person.residenceAddress) {
                $scope.pregnancyVacationCertificate.person.address =
                    $scope.pregnancyVacationCertificate.person.residenceAddress.text;
            }
        });

        $scope.document.$promise
            .then(function () {
                EptsCertificatesApiFactory.getPregnancyVacationCertificates(
                    {
                        personalCode: $scope.patientData.personalCode,
                        birthDate: $scope.patientData.birthDate,
                        organizationCode: $scope.organization.jarCode,
                        statuses: ["SIGNED"],
                        date: new Date().getTime(),
                    },
                    function (certificates) {
                        $scope.existingCertificates = certificates;

                        var validCertificate = getValidCertificate(certificates);
                        $scope.additionalParameters.additional = validCertificate != null;

                        var number = EptsCertificatesApiFactory.getNewCertificateNumber(
                            { organizationCode: $scope.organization.jarCode, certificateType: "NESP" },
                            function (number) {
                                $scope.pregnancyVacationCertificate.number = number.number;
                                $scope.pregnancyVacationCertificate.series = number.series;
                            }
                        );

                        if ($scope.additionalParameters.additional) {
                            $scope.pregnancyVacationCertificate.startDate = validCertificate.endDate + 86400000;
                            $scope.pregnancyVacationCertificate.insurance = validCertificate.insurance;

                            $scope.$watch("additionalParameters.pregnancyDaysExtention", function (newValue) {
                                $scope.pregnancyVacationCertificate.endDate =
                                    $scope.pregnancyVacationCertificate.startDate + (newValue - 1) * 86400000;
                            });

                            $scope.additionalParameters.pregnancyDaysExtention = $scope.pregnancyDaysExtansionValues[0];
                            FormLoaderService.endFormLoading(number.$promise);
                        } else {
                            var insurance = EptsCertificatesApiFactory.getInsuranceInformation(
                                {
                                    personalCode: $scope.patientData.personalCode,
                                    organizationCode: $scope.organization.jarCode,
                                },
                                function (insurance) {
                                    $scope.pregnancyVacationCertificate.insurance = insurance;
                                }
                            );
                            FormLoaderService.endFormLoading($q.all([number.$promise, insurance.$promise]));
                        }
                    },
                    function () {
                        FormLoaderService.endFormLoading();
                    }
                );
            })
            .catch(function (error) {
                // Error callback
                console.error("Request failed:", error);
                FormLoaderService.endFormLoading();
            });

        // Existing certificates
        $scope.existingCertificates = [];

        $scope.errors = {
            startDateRequired: false,
            endDateRequired: false,
            endDateToSmall: false,
            endDateToBig: false,
            childBirthDateRequired: false,
            childBirthComplicationRequired: false,
        };

        $scope.$watch("additionalParameters.pregnancyDays", function (newValue) {
            // Calculate pregnancy end date
            if (newValue.specific) {
                if ($scope.pregnancyVacationCertificate.startDate != null) {
                    $scope.pregnancyVacationCertificate.endDate = calculateEndDate(
                        $scope.pregnancyVacationCertificate.startDate,
                        newValue.value
                    );
                } else {
                    $scope.pregnancyVacationCertificate.endDate = null;
                }
            }

            // Update complicated child birth display
            $scope.additionalParameters.complicatedChildbirthFlagNotEditable = $scope.needBirthDate();
            if ($scope.additionalParameters.complicatedChildbirthFlagNotEditable) {
                $scope.additionalParameters.complicatedChildbirth = true;
            }
        });

        $scope.$watch("pregnancyVacationCertificate.startDate", function (newValue) {
            if ($scope.additionalParameters.pregnancyDays.specific) {
                if (newValue != null) {
                    $scope.pregnancyVacationCertificate.endDate = calculateEndDate(
                        newValue,
                        $scope.additionalParameters.pregnancyDays.value
                    );
                } else {
                    $scope.pregnancyVacationCertificate.endDate = null;
                }
            }
        });

        var calculateEndDate = function (startDate, duration) {
            return moment(startDate)
                .add(duration - 1, "days")
                .valueOf();
        };

        function getCertificateByNumber(certificates, number) {
            var result = null;

            _.each(certificates, function (certificate) {
                if (certificate.number == number) {
                    result = certificate;
                }
            });

            return result;
        }

        function getValidCertificate(certificates) {
            var validCertificate = null;
            var now = new Date().getTime();

            _.each(certificates, function (certificate) {
                if (certificate.startDate <= now && certificate.endDate >= now) {
                    validCertificate = certificate;
                }
            });

            return validCertificate;
        }

        $scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;

        $scope.updateDiagnosis = function (diagnosis) {
            if (diagnosis && diagnosis.code) {
                $scope.pregnancyVacationCertificate.childBirthComplication = diagnosis;
            }
        };

        $scope.check = function () {
            $scope.pregnancyVacationForm.submitted = true;

            // TODO refactor this and get rid of manual validation
            // Clear all errors
            MessageService.clear();

            $scope.errors.startDateRequired = false;
            $scope.errors.endDateRequired = false;
            $scope.errors.endDateToSmall = false;
            $scope.errors.endDateToBig = false;
            $scope.errors.childBirthDateRequired = false;
            $scope.errors.childBirthComplicationRequired = false;

            var hasErrors = false;

            // Check insurance
            if (!$scope.pregnancyVacationCertificate.insurance) {
                MessageService.error($filter("translate")("err.epts.ins.req"));
                hasErrors = true;
            }

            // Start date
            if (!$scope.pregnancyVacationCertificate.startDate) {
                $scope.errors.startDateRequired = true;
                hasErrors = true;
            }

            // End date
            if ($scope.additionalParameters.pregnancyDays.specific == false) {
                if (!$scope.pregnancyVacationCertificate.endDate) {
                    $scope.errors.endDateRequired = true;
                    hasErrors = true;
                } else if ($scope.pregnancyVacationCertificate.startDate) {
                    var days =
                        moment(new Date($scope.pregnancyVacationCertificate.endDate)).diff(
                            moment(new Date($scope.pregnancyVacationCertificate.startDate)),
                            "days"
                        ) + 1;

                    if (days < 0) {
                        $scope.errors.endDateToSmall = true;
                        hasErrors = true;
                    } else if (days > 70) {
                        $scope.errors.endDateToBig = true;
                        hasErrors = true;
                    }
                }
            }

            // Child birth date
            if (
                ($scope.needBirthDate() || $scope.additionalParameters.additional) &&
                !$scope.pregnancyVacationCertificate.childBirthDate
            ) {
                $scope.errors.childBirthDateRequired = true;
                hasErrors = true;
            }

            // Child birth complication
            if (
                ($scope.needComplication() || $scope.additionalParameters.additional) &&
                !$scope.pregnancyVacationCertificate.childBirthComplication
            ) {
                $scope.errors.childBirthComplicationRequired = true;
                hasErrors = true;
            }

            if ($scope.pregnancyVacationForm.$invalid || hasErrors) {
                return;
            }

            // Register certificate
            DocumentSpinnerHelper.startLoading();
            MessageService.clearAll();
            EptsCertificatesApiFactory.registerPregnancyVacationCertificate(
                $scope.pregnancyVacationCertificate
            ).$promise.then(
                function (registrationResult) {
                    DocumentSpinnerHelper.endLoading();
                    // Open view page
                    $location.path(
                        "/dp/patients/" +
                            $routeParams.patientId +
                            "/certificates/nesp/" +
                            $scope.pregnancyVacationCertificate.number +
                            "/view"
                    );
                },
                function (result) {
                    DocumentSpinnerHelper.endLoading();
                    _.each(result.data.errors, function (i) {
                        MessageService.error(i);
                    });
                    _.each(result.data.fieldErrors, function (i) {
                        var errorMessage = i.propertyPath + ": " + i.msg;
                        MessageService.error(errorMessage);
                    });
                    _.each(result.data.globalErrors, function (i) {
                        MessageService.error(i.msg);
                    });
                    $window.scrollTo(0, 10);
                }
            );
        };

        $scope.clear = function () {
            $modalConfirm
                .show({
                    title: $filter("translate")("inf.clean.form.tit"),
                    content: $filter("translate")("inf.clean.form"),
                })
                .then(function () {
                    $routeSegment.chain[$routeSegment.chain.length - 1].reload();
                });
        };

        $scope.needBirthDate = function () {
            return (
                $scope.additionalParameters.pregnancyDays.value == 56 ||
                $scope.additionalParameters.pregnancyDays.value == 28 ||
                $scope.additionalParameters.pregnancyDays.value == 70 ||
                $scope.additionalParameters.pregnancyDays.value == 42
            );
        };

        $scope.needComplication = function () {
            return (
                $scope.additionalParameters.pregnancyDays.value == 70 ||
                $scope.additionalParameters.pregnancyDays.value == 42
            );
        };

        // Get complication
        $scope.getComplication = function (certificate) {
            if (!certificate.childBirthComplication) {
                return "";
            }

            return certificate.childBirthComplication.code + " " + certificate.childBirthComplication.name;
        };
    },
]);
