<app-e096-part-partogram [answersVariants]="answersVariants" [editable]="editable" />
<app-e096-part-entries [answersVariants]="answersVariants" [editable]="editable" [fruitLength]="fruitLength" />

<div class="d-flex gap-3 mt-3" *ngIf="editable">
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger flex-grow-1" (click)="resetForm()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>