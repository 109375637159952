ng1App.factory('_EncountersApiFactory',
    ['$resource', 'apiUrl', 'BrowserSessionStorage',
     function ($resource, apiUrl, BrowserSessionStorage) {

    var URL_BASE = apiUrl + '/encounters';
    return $resource('', {}, {
        post: {
            method: 'POST',
            url: URL_BASE
        },
        get: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getForEdit: {
            method: 'GET',
            url: URL_BASE + '/forEdit/:id'
        },
        getByDocType: {
            method: 'GET',
            url: URL_BASE + '/byDocType?bsid=' + BrowserSessionStorage.getBsid()
        },
        getByDocData: {
            method: 'GET',
            url: URL_BASE + '/byDocData?bsid=' + BrowserSessionStorage.getBsid()
        },
        getPatientEncountersByFTQuery: {
            method: 'GET',
            url: URL_BASE + '/byDocFt'
        },
        closeEncounter: {
            method: 'PUT',
            url: URL_BASE + '/:id/close'
        },
        countActiveAndCanceled: {
            method: 'GET',
            url: URL_BASE + '/:encounterId/count-active-and-canceled/:docType'
        },
        cancelEncounter: {
            method: 'PUT',
            url: URL_BASE + '/:id/cancel'
        },
        updateInsurance: {
            method: 'GET',
            url: URL_BASE + '/:id/update-insurance'
        },
        eventRestricted: {
            method: 'GET',
            url: URL_BASE + '/:id/event-restricted'
        },
        encounterHistory: {
            method: 'GET',
            url: URL_BASE + '/:encounterId/history'
        },
        encounterHistoryItem: {
            method: 'GET',
            url: URL_BASE + '/:encounterId/history/:historyId',
            isArray: false
        },
        getUnusedPatientOrders: {
            method: 'GET',
            url: URL_BASE + '/orders-unused'
        },
        getOrderForEdit: {
            method: 'GET',
            url: URL_BASE + '/order-for-edit/:id'
        },
        changeOrder: {
            method: 'POST',
            url: URL_BASE + '/change-order'
        },
        changeOrderToPaper: {
            method: 'POST',
            url: URL_BASE + '/change-order-to-paper'
        },
        getEsiNr: {
            method: 'GET',
            url: URL_BASE + '/get-esi-nr'
        },
        getByOrders: {
            method: 'GET',
            url: URL_BASE + '/by-orders',
            isArray: true
        },
        getAllowedDocumentTypes: {
            method: 'GET',
            url: URL_BASE + '/:encounterId/allowed-document-types'
        },
        getAllowedCertificatesTypes: {
            method: 'GET',
            url: URL_BASE + '/:encounterId/allowed-certificates-types'
        },
        getPatientEncounters: {
            method: 'GET',
            url: URL_BASE + '/'
        },
        getRelatedDocs: {
            method: 'GET',
            url: URL_BASE + '/:encounterId/related-documents',
            isArray: true
        },
        hasActiveEncounter: {
            method: 'GET',
            url: URL_BASE + '/has-active-encounter'
        },
        isActive: {
            method: 'GET',
            url: URL_BASE + '/is-active'
        },
        getEncountersHistory: {
            method: 'GET',
            url: URL_BASE + '/encounters-history',
            isArray: true
        },
        registerAuditEncounter: {
          method: 'POST',
          url: URL_BASE + '/audit/register'
        }
    });
}]);
