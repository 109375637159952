ng1App.controller('doctor.patient.F025alkViewCtrl', 
    ['$scope', '$routeParams', '$window', '$location', '$modalConfirm', 'MessageService', 'SveidraApiFactory', 'VlkFormCreationHelper', 'SveidraCommons', '$filter', 'DocumentsApiFactory', 'FormLoaderService', '$q',
     function ($scope, $routeParams, $window, $location, $modalConfirm, MessageService,
                                                              SveidraApiFactory, VlkFormCreationHelper, SveidraCommons, $filter,
                                                              DocumentsApiFactory, FormLoaderService, $q) {
     MessageService.clearAll();
     MessageService.showSuccessEventMessage();
     MessageService.unregisterSuccessEvent();
    
     $scope.$emit('setHelpPage', 'medicinines-apskaitos-dokumento-perzira');
    
     $scope.goBack = function(){
        $window.history.back();
     };
    
     $scope.formId = $routeParams.formId;
     $scope.patientId = $routeParams.patientId;
    
     var documentSelected = function (documentId, formType) {
    	 $location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms/f025alk/' + $scope.formId + '/encounter/new')
    	 			.search({documentId: documentId});
     };

     FormLoaderService.startFormLoading();
     $scope.form = SveidraApiFactory.getForm({id: $scope.formId}, function (result) {
    	 $scope.patientData = result.patient;
    	 $scope.authorData = result.author;
    	 $scope.organizationData = result.organization;
     });
	 $scope.encounters = SveidraApiFactory.getEncounters({formId: $scope.formId});
     FormLoaderService.endFormLoading($q.all([$scope.form.$promise, $scope.encounters.$promise]));
     
     // Load related documents
     $scope.loadRelatedDocuments = function (callback) {
    	 return $scope.encounters.$promise.then(function () {
    		 var ids = [];
    		 ids.push($scope.form.documentId);
    		 _.each($scope.encounters, function (encounter) {
    			ids.push(encounter.documentId); 
    		 });
    		 DocumentsApiFactory.searchForList({id: ids}, function (result) {
    			 callback(result);
    		 });
    	 });
     };
    
     // New encounter creation
     $scope.createNewEncounter = function () {
    	 var documentSelectModal = VlkFormCreationHelper.getDocumentSelectionModal($routeParams.patientId,
    				 																'f025alk', documentSelected, 
    				 																$scope.form.documentId);
    	 documentSelectModal.load();
     };
     
     // Delete encounter
     $scope.deleteEncounter = function (id, index) {
         $modalConfirm.show({title: $filter('translate')('err.ale.tit.f025.del'), content: $filter('translate')('err.ale.f025.del')}).then(function() {
        	 SveidraApiFactory.deleteEncounter({formId: $scope.formId, id: id}, function () {
        		 $scope.encounters.splice(index, 1);
        	 });
         });
     };
    
     // Close form
     $scope.close = function () {
    	 // TODO display error if closing date doesn't exist
         $modalConfirm.show({title: $filter('translate')('inf.f025.clo.car.tit'), content: $filter('translate')('inf.f025.clo.car.tex')}).then(function () {
        	 SveidraApiFactory.closeForm({id: $scope.formId}, {}, successHandler, errorHandler);
         });
     };
     
     var successHandler = function (result) {
	     // Reload form data
	     $scope.form = SveidraApiFactory.getForm({id: $scope.formId});
			
	     MessageService.showSuccessEventMessage();
	     MessageService.unregisterSuccessEvent();	
     };
     
     var errorHandler = function (result) {
		SveidraCommons.handleErrors(result, 'err.cer.025.sav');
     };

}]);