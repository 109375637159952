import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'listFilter'})
export class ListFilterPipe implements PipeTransform {

    transform(list: any[], query: string, paths: string[], data?: any): any[] {
        const match = (item: any, query: string, path: string, type: string): boolean => {
            const parts = path.split('.');
            let value: any = item;

            for (let i = 0; i < parts.length; i++) {
                const pos1 = parts[i].search("\\[");
                const pos2 = parts[i].search("\\]");
                let index = -1;

                if (pos1 != -1 && pos2 != -1) {
                    index = +parts[i].slice(pos1 + 1, pos2);
                    parts[i] = parts[i].slice(0, pos1);
                }

                if (index != -1 && !isNaN(index)) {
                    value = value[parts[i]][index];
                } else {
                    value = value[parts[i]];
                }

                if (value === undefined || value === null) {
                    return false;
                }
            }

            switch (type) {
                case 'date':
                    return value.toString().includes(query);
                case 'datetime':
                    return value.toString().includes(query);
                case 'number':
                    return value == query;
                default:
                    return value.toString().toUpperCase().includes(query.toUpperCase());
            }
        };

        const ctrl = data || {};
        let result: any[] = [];

        if (query === undefined || query === null || query === '') {
            result = list;
        } else if (!paths || paths.length === 0) {
            result = list.filter(item => item.toString().includes(query));
        } else {
            result = list.filter(item => {
                for (let i = 0; i < paths.length; i++) {
                    const parts = paths[i].split('|');
                    if (match(item, query, parts[0], parts[1])) {
                        return true;
                    }
                }
                return false;
            });
        }

        ctrl.total = list ? list.length : 0;
        ctrl.filtered = result ? result.length : 0;
        return result;
    }
}