ng1App.directive('patientInsuranceIcon', 
    ['$filter',
     function ($filter) {
    return {
        templateUrl: '/templates/components/patient/patient-insurance-icon.html',
        restrict: 'E',
        replace: false,
        scope: {
            insurance: '=',
            showTitle: '@'
        },
        link: function (scope: any, element, attrs) {
            scope.getTooltip = function () {
                var insurance = scope.insurance;
                var tooltip = $filter('translate')(insurance.assertStatus);
                if (insurance.assertStatus != 'notAssertable') {
                    tooltip += ', tikrinta ' + $filter('date')(insurance.assertionDate, 'yyyy-MM-dd HH:mm');
                }
                return tooltip;
            }
        }
    };
}]);