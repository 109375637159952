import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-e030-consultation-plan',
    templateUrl: './e030-consultation-plan.component.html',
})
export class E030ConsultationPlanComponent implements OnInit {
    @Input() editable: boolean = true;
    formGroup: FormControl;
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.control(null, [Validators.required]);
    }
}
