import { Component, Input } from '@angular/core';
import moment from 'moment';
import { E010_Row, E010_RowsGroup } from '../e010-forms.model';

@Component({
    selector: 'app-e010-form-death',
    templateUrl: './e010-form-death.component.html',
})
export class E010FormDeathComponent {
    @Input() set data(value: {[key: string]: any}) {
        if(!value) return;
        this.causeOfDeathRows = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.underlyingDisease',
                value: value.e106_2_1.deathCause?.mainDisease?.code || value.e10Mpn.deathCause?.mainDisease?.code || '-'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.otherDisease',
                value: value.e106_2_1.deathCause?.otherDisease?.code || value.e10Mpn.deathCause?.otherDisease?.code || '-'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.majorMotherDisease',
                value: value.e106_2_1.deathCauseFromMother?.mainDisease?.code || value.e10Mpn.deathCauseFromMother?.mainDisease?.code || '-'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.otherMotherDisease',
                value: value.e106_2_1.deathCauseFromMother?.otherDisease?.code || value.e10Mpn.deathCauseFromMother?.otherDisease?.code || '-'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.otherConditions',
                value: value.e106_2_1.otherConditions || value.e10Mpn.otherConditions || '-'
            }
        ];

        this.rowsData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.deathTime',
                rows: [{
                    value: moment(value.e106_2_1?.deathDateAndTime || value.e10Mpn?.dateOfDeath)
                        .format('YYYY-MM-DD HH:mm') || '-'
                }]
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.causesOfDeath',
                style: 'list',
                rows: this.causeOfDeathRows
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.newborndeathCircumstances',
                style: 'list',
                rows: [{value: value.e10Mpn.newbornDeathCircumstances || '-'}]
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.finalConclusion',
                style: 'list',
                rows: [{value: value.e10Mpn.finalConclusion || '-'}]
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formDeath.pathologicalExamination',
                style: 'list',
                rows: [{value: value.e10Mpn.newbornDeadPathologicalExamination || '-'}]
            },
        ]
    };

    private causeOfDeathRows: E010_Row[] = [];

    rowsData: E010_RowsGroup[];
    constructor() {}

    valueIsArray(value: string | string[]): value is Array<string> {
        return Array.isArray(value);
    }
}
