import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AgGridFilterValidationService {

    public validateFilter(isValid: boolean, filtersErrors: any[], colId: string) {
        isValid ? this.removeError(filtersErrors, colId) : this.addError(filtersErrors, colId);
        return isValid;
    }

    private addError(filtersErrors: any[], colId: string) {
        if (this.findIndex(filtersErrors, colId) === -1) {
            filtersErrors.push({colId: colId, valid: false});
        }
    }

    private removeError(filtersErrors: any[], colId: string) {
        const indexToRemove = this.findIndex(filtersErrors, colId);

        if (indexToRemove !== -1) {
            filtersErrors.splice(indexToRemove, 1);
        }
    }

    private findIndex(filtersErrors: any[], colId: string) {
        return filtersErrors.findIndex(item => item.colId === colId);
    }
}