import { Component, DestroyRef, Input, ViewChildren } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    E096NtAssessmentOfFetalConditionComponent
} from '../e096-nt-assessment-of-fetal-condition/e096-nt-assessment-of-fetal-condition.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay } from 'rxjs';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { controlIsRequired, listenAndChangeStatusOfControl } from '../../../../e096-utils/utils';
import { validateBloodPressure } from '../../../../e096-utils/custom-validation';

@Component({
    selector: 'app-e096-nt-condition-during-hospitalization',
    templateUrl: './e096-nt-condition-during-hospitalization.component.html',
})
export class E096NtConditionDuringHospitalizationComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.answers = value;
        this.generateDynamicControls(value);
    }

    @ViewChildren(E096NtAssessmentOfFetalConditionComponent) assessmentsOfFetalCondition: E096NtAssessmentOfFetalConditionComponent[];

    formGroup: FormGroup = this.fb.group({
        assessmentsDate: this.fb.control(null, [Validators.required]),
        complaints: this.fb.control(null, [Validators.required]),
        arrivalComplaintsReason: this.fb.control(null),
        anamnesis: this.fb.control(null),
        pregnantState: this.fb.group({
            bloodPressureRS: this.fb.control(null, [Validators.required, Validators.min(0)]),
            bloodPressureRD: this.fb.control(null, [Validators.required, Validators.min(0)]),
            bloodPressureLS: this.fb.control(null, [Validators.required, Validators.min(0)]),
            bloodPressureLD: this.fb.control(null, [Validators.required, Validators.min(0)]),
            pulse: this.fb.control(null, [Validators.required, Validators.min(0)]),
            temperature: this.fb.control(null, [Validators.required]),
            weight: this.fb.control(null, [Validators.min(0)]),
            gainDuringPregnancy: this.fb.control({value: null, disabled: true}),
            gda: this.fb.control(null, [Validators.required, Validators.min(0)]),
            numberOfFruits: this.fb.control(null, [Validators.required, Validators.min(0)]),
            dischargeFromGenitals: this.fb.control(null, [Validators.required]),
            conditionalOfSecrets: this.fb.array([]),
            regularContractions: this.fb.control(null),
            regularContractionsStart: this.fb.control({value: null, disabled: true}),
        }),
        assessmentsOfFetalCondition: this.fb.array([])
    });
    maxDate = new Date();
    minDate = new Date('2022-01-01');
    complaintsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Yra'
        },
        {
            id: '1',
            code: '1',
            name: 'Nėra'
        }
    ];
    answers: {[key: string]: EntityModel[]};

    controlRequired = controlIsRequired.bind(this) as typeof controlIsRequired;
    bpValidator = validateBloodPressure.bind(this) as typeof validateBloodPressure;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;

    get conditionalOfSecretsCheckboxes() {
        return (this.formGroup.get('pregnantState.conditionalOfSecrets') as FormArray).controls;
    }

    get fruitLength() {
        return _.times(this.formGroup.get('pregnantState.numberOfFruits')?.value, () => null);
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
    ) {
    }

    checkConditionalOfSecrets(control: FormControl | AbstractControl, i: number): void {
        control.setValue(control.getRawValue() ? this.answers['genitalSecretionsStatus'][i].name : false);
    }

    generateFruitName(i: number): string {
        return String.fromCharCode(65 + i);
    }

    private generateDynamicControls(value: {[key: string]: EntityModel[]}): void {
        this.answers['genitalSecretionsStatus'].forEach(option => {
            (this.formGroup.get('pregnantState.conditionalOfSecrets') as FormArray).push(this.fb.control({value: false, disabled: true}));
        })

        this.formGroup.get('pregnantState.numberOfFruits').valueChanges.pipe(takeUntilDestroyed(this.destroyRef), delay(10))
            .subscribe(() => {
                this.formGroup.removeControl('assessmentsOfFetalCondition')
                this.formGroup.addControl('assessmentsOfFetalCondition',
                    this.fb.array(this.assessmentsOfFetalCondition.map(component => component.formGroup)));
            })

        this.bpValidator('pregnantState.bloodPressureRS', 'pregnantState.bloodPressureRD')
            .pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
        this.bpValidator('pregnantState.bloodPressureLS', 'pregnantState.bloodPressureLD')
            .pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
        this.changeStatusOfControl({
            controlNameForListen: 'pregnantState.dischargeFromGenitals',
            controlNameForUpdate: 'pregnantState.conditionalOfSecrets',
            enableValue: 'Yra'
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'pregnantState.regularContractions',
            controlNameForUpdate: 'pregnantState.regularContractionsStart',
            enableValue: 'Yra',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

}
