<nhr-record-widget
    [closed]="false"
    [closable]="true"
    [title]="'doc.mentalHealthComponent.recomendationsForm.title' | translate"
    class="h-auto"
>
    <div class="row mb-3" *ngIf="editable || formGroup.get('treatmentApplied')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.recomendationsForm.recomendations' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-input
                *ngIf="editable"
                [fControl]="formGroup.get('treatmentApplied')"
            ></app-input>
            <p *ngIf="!editable && formGroup.get('treatmentApplied')?.value"
               class="form-control-plaintext">
                {{ formGroup.get('treatmentApplied')?.value }}
            </p>
        </div>
    </div>
    <div class="row mb-3 req" *ngIf="editable || formGroup.get('showThePatient')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.recomendationsForm.showPatient' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-select
                *ngIf="editable"
                id="showThePatient"
                [fControl]="formGroup.get('showThePatient')"
                [values]="selectionValuesFromEntity(yesNoOptions)"
            ></app-select>
            <p *ngIf="!editable && formGroup.get('showThePatient')?.value"
               class="form-control-plaintext">
                {{ formGroup.get('showThePatient')?.value }}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-9 ms-auto ps-3">
            <app-checkbox
                *ngIf="fromDoc === DocumentTagEnum.e025"
                class="mt-1"
                formClass="mb-0"
                [inputId]="'ambulatoryMonitoring'"
                [labelText]="'doc.mentalHealthComponent.recomendationsForm.ambulatoryMonitoring'"
                [fControl]="formGroup.get('ambulatoryMonitoring')"
            ></app-checkbox>
            <app-checkbox
                *ngIf="fromDoc === DocumentTagEnum.e003"
                class="mt-2"
                formClass="mb-0"
                [inputId]="'stationaryObservation'"
                [labelText]="'doc.mentalHealthComponent.recomendationsForm.stationaryObservation'"
                [fControl]="formGroup.get('stationaryObservation')"
            ></app-checkbox>
            <app-checkbox
                *ngIf="fromDoc === DocumentTagEnum.e003"
                class="mt-2"
                formClass="mb-0"
                [inputId]="'forcedHospitalization'"
                [labelText]="'doc.mentalHealthComponent.recomendationsForm.forcedHospitalization'"
                [fControl]="formGroup.get('forcedHospitalization')"
            ></app-checkbox>
        </div>
    </div>
</nhr-record-widget>
