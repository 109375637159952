export enum ThromboembolismPreventionLowRisks {
    Age = "Amžius – daugiau nei 35 metai",
    Obesity = "Nutukimas (KMI > 30 kg/m2)",
    Smoking = "Rūkymas",
    VaricoseLegVeins = "Kojų venų varikoziniai mazgai",
    SystemicInfection = "Esama sisteminė infekcija",
    Immobility = "Nejudrumas > 5 parų (pvz., paraplegija, simfizio disfunkcija, ilgai trunkančios kelionės).",
    Preeclampsia = "Preeklampsija",
    Dehydration = "Dehidracija/gausus nėščiųjų vėmimas/ kiaušidžių perstimuliavimo sindromas",
    MultiplePregnancy = "Daugiavaisis nėštumas",
    ArtificialInseminationPregnancy = "Nėštumas po taikytų pagalbinio apvaisinimo būdų",
    PlacentalAbruption = "Anamnezėje kliniškai reikšminga placentos atšoka",
    Births = "3 ir daugiau gimdymų",
}

export enum ThromboembolismPreventionMediumRisks {
    PreviousEpisodeGVT = "Anksčiau buvęs vienas GVT epizodas, nesant šeiminės GVT anamnezės ar trombofilijos (AFS, įgimtos trombofilijos) dėl aiškios priežasties (estrogenų vartojimo, ilgųjų kaulų lūžio)",
    Thrombophilia = "Trombofilija be GVT epizodo",
    OneOfListedDiseases = "Viena iš išvardytų ligų (sisteminė raudonoji vilkligė, onkologinė liga, bakterinė infekcinė liga, nefrozinis sindromas, pjautuvinė anemija, įgimtos širdies ydos arba protezuotas širdies vožtuvas, protezuotas klubo sanarys)",
    IntravenousDrugsUse = "Intraveninių narkotikų vartojimas",
    SurgicalProcedures = "Chirurginės procedūros (pvz., apendektomija)",
}

export enum ThromboembolismPreventionHighRisks {
    GVTorThrombophilia = "Šeiminė GVT ar trombofilija (AFS arba įgimta trombofilja)",
    GVTandPE = "GVT ir (arba) PE be žinomos priežasties",
    GVTEpisode = "Kai yra buvęs daugiau nei vienas GVT epizodas",
}

export enum PrematureBirthRiskLowRisks {
    RuptureFetalMembranes = "Nėra priešlaikinio neišnešioto vaisiaus dangalų plyšimo",
    GKLength = "Nėra reguliarios gimdymo veiklos ir gimdos kaklelio (GK) ilgis (išmatuotas transvaginaliniu davikliu) iki 27+6 nėštumo savaitės ≥ 25 mm, po 28+0 nėštumo savaitės ≥ 20 mm",
    CervicalChanges = "Nėra progresuojančių gimdos kaklelio pokyčių (trumpėjimas ir atsivėrimas)",
    FibronectinTestNegative = "IGFBP-1 testas ar fibronektino tyrimas neigiamas (atliekamas esant galimybei)",
}

export enum PrematureBirthRiskHighRisks {
    RuptureFetalMembranes = "Priešlaikinis neišnešioto vaisiaus dangalų plyšimas",
    GKLength = "Yra priešlaikinio gimdymo simptomų ir GK ilgis (išmatuotas transvaginaliniu davikliu) iki 27+6 nėštumo savaitės <25 mm, po 28+0 nėštumo savaitės <20 mm",
    GKChanges = "Yra reguliari gimdymo veikla ir progresuojantys GK pokyčiai (sutrumpėjimas daugiau kaip 80 proc. viso GK ilgio ir (arba) atsivėrimas >3 cm)",
    PGSymptoms = "Yra PG simptomų ir buvusi rizikinga būklė (buvęs PG iki 34+0 nėštumo savaitės)",
    FibronectinTestPositive = "IGFBP-1 testas ar fibronektino tyrimas teigiamas (atliekamas esant galimybei)",
}

export enum BirthRiskLowRisks {
    LowRiskPregnancy = "Nėštumo metu buvo maža rizika",
    LaborSmoothly = "Sklandžiai prasidėjo ir progresuoja gimdymas",
    PostpartumCourse = "Prognozuojama normali pogimdyminė eiga",
    ObstetricCare = "Reikalinga ir (ar) planuojama akušerinė pagalba neviršija akušerio kompetencijos",
}

export enum BirthRiskMediumRisks {
    DoubtfulKTG = "Abejotina KTG",
    PrematureLaborBegun = "Prasidėjęs priešlaikinis gimdymas po 34+0 nėštumo savaičių",
    LaborInduced = "Sužadintas gimdymas",
    UncomplicatedPatientDiseases = "Nekomplikuotos pacientės ligos (pvz., nėštumo laikotarpiu nustatytas ar nekomplikuotas prieš nėštumą diagnozuotas cukrinis diabetas, epilepsija ir kitos)",
    AmnioticFluidContaminatedMeconium = "Mekonijumi suteršti vaisiaus vandenys",
    SlowedFetalGrowth = "Įtariama, kad vaisiaus augimas sulėtėjęs (mažiau kaip 10 procentilių)",
    MildPreeclampsia = "Lengva preeklampsija",
    Oligohydramnios = "Oligohidramnionas",
    BirthAfter = "Gimdymas po 42+0 savaitės",
    LightBleedingDuringChildbirth = "Negausus kraujavimas gimdymo metu",
    NonProgressiveLabour = "Neprogresuojantis gimdymas",
    PredictedFetalShoulderDystocia = "Prognozuojama vaisiaus pečių distocija",
    EstimatedFetalWeight = "Numatomas vaisiaus svoris >4500 g",
    PreviousPostpartumHemorrhage = "Buvęs pogimdyminis kraujavimas",
    Polyhydramnios = "Polihidramnionas",
    PreviousCesareanOperation = "Buvusi 1 cezario pjūvio operacija",
    PreviousPerinatalDeath = "Buvusi perinatalinė mirtis",
    Obesity = "Nutukimas (KMI daugiau kaip 30 kg/m² kūno ploto)",
}

export enum BirthRiskHighRisks {
    PathologicalKTG = "Patologinė KTG",
    SeriouslyIllMother = "Sunkiai serganti gimdyvė (pvz., komplikuotas cukrinis diabetas, širdies nepakankamumas, sunki preeklampsija ir panašiai)",
    FetalButtockProtuberance = "Vaisiaus sėdmenų pirmeiga",
    FetalPosition = "Skersinė ar įstrižinė vaisiaus padėtis",
    MultiplePregnancy = "Daugiavaisis nėštumas",
    PrematureBirth = "Priešlaikinis gimdymas iki 34+0 nėštumo savaitės",
    HeavyBleeding = "Gausus kraujavimas gimdymo metu ar po jo",
    HighTemperature = "Temperatūra didesnė kaip 38 ºC",
    MediumRiskFactors = "3 ir daugiau vidutinės rizikos grupės veiksnių.",
}

export enum PostpartumHemorrhageLowRisks {
    None = "Nėra nei vieno PK rizikos veiksnio",
}

export enum PostpartumHemorrhageMediumRisks {
    PreviouBirths = "PK ankstesnių gimdymų metu",
    PreviousCaesarean = "Buvusi cezario pjūvio operacija ar kitokia gimdos operacija",
    MultiplePregnancy = "Daugiavaisis nėštumas",
    Polyhydramnios = "Polihidramnionas",
    EstimatedFetalWeight = "Numatomas vaisiaus svoris ≥ 4kg",
    MultipleBirths = "Buvę daug (≥5) gimdymų",
    Obesity = "Nutukimas (KMI≥35 kg/m2)",
    Chorioamnionitas = "Chorioamnionitas",
    UterineFibroids = "Gimdos miomos",
    SevereAndModerateAnemia = "Sunki ir vidutinė anemija",
}

export enum PostpartumHemorrhageHighRisks {
    PlacentalAccretion = "Galimas placentos įaugimas, priaugimas ar peraugimas",
    ProfuseBleedingOnHospitalization = "Placentos pirmeiga ar žemas placentos prisitvirtinimas",
    PreventativeAndRoutineCare = "Gausus kraujavimas stacionarizuojant",
    BloodClottingDisorders = "Kraujo krešumo sutrikimai",
    HematocritAndOther = "Hematokrito rodmenys <30 % ir kiti rizikos veiksniai",
    Platelets = "Trombocitų <100x109/l",
    SeverePreeclampsia = "Sunki preeklampsija",
    ModerateRiskFactors = "≥2 vidutinės rizikos veiksniai",
}

export enum UnfavorableObstetricHistory {
    Infertility = "Nevaisingumas",
    PrematurePregnancies = "Vienas ir daugiau iš eilės neišnešioti nėštumai (vėlyvi (≥14+0 nėštumo savaitės) persileidimai ir (ar) priešlaikiniai gimdymai)",
    CaesarianOperation = "Cezario pjūvio operacija",
    PlacentalAccretion = "Gimdos operacija",
    UterineSurgery = "Eklampsija",
    SeverePreeclampsia = "Sunki preeklampsija",
    ThromboembolicComplications = "Tromboembolinės komplikacijos",
    PerinatalDeath = "Perinatalinė mirtis",
    NewbornCentralNervousSystemDamage = "Naujagimio centrinės nervų sistemos pažeidimas",
    NewbornDiffusionDisadvantages = "Naujagimio sklaidos trūkumai",
    NewbornHemolytiDdisease = "Naujagimio hemolizinė liga",
}

export enum PregnancyPathology {
    IrregularFetusPosition = "Netaisyklinga vaisiaus padėtis nuo 36 nėštumo savaitės",
    MultiplePregnancy = "Daugiavaisis nėštumas",
    Oligohydramnios = "Oligohidramnionas",
    Polyhydramnios = "Polihidramnionas",
    HypertensiveConditions = "Hipertenzinės būklės",
    Bleeding = "Kraujavimas",
    PregnancyContinuesBeyondDueDate = "Nėštumas tęsiasi po nustatyto gimdymo termino (> 41+0 nėštumo savaitės)",
    PathologicalAttachment = "Placentos pirmeiga ar kitas patologinis prisitvirtinimas",
}

export enum UnfavorablePregnantCondition {
    OlderThan40Years = "Pirmą kartą gimdysianti nėščioji yra vyresnė nei 40 m.",
    YoungerThan18Years = "Pirmą kartą gimdysianti nėščioji yra jaunesnė nei 18 m.",
    GivingBirth5TimesOrMore = "Nėščioji, gimdysianti 5 kartą ar daugiau",
    IsoimmuneSensitization = "Rh ir kita izoimuninė sensibilizacija",
    KMI30OrMore = "Iki 12 nėštumo savaitės nustatytas KMI yra 30 ar didesnis",
    AssistedFertilization = "Pagalbinis apvaisinimas",
}

export enum FetalPathology {
    LargeFetal = "Stambus vaisius (svoris didesnis nei 90 procentilių)",
    InsufficientFetalGrowth = "Nepakankamas vaisiaus augimas (svoris mažesnis nei 10 procentilių)",
    FetalDispersalDisadvantages = "Vaisiaus sklaidos trūkumai",
    FetalHydropsy = "Vaisiaus vandenė",
    FetalCardiacArrhythmia = "Vaisiaus širdies aritmija",
}

export enum PregnantDiseases {
    PregnancyDisease = "Nėščiosios liga, komplikuojanti nėštumo eigą",
    DiabetesTypeI = "Cukrinis diabetas (I tipo)",
    DiabetesTypeII = "Cukrinis diabetas (II tipo)",
    GestationalDiabetes = "Gestacinis diabetas",
    Pyelonephritis = "Pielonefritas",
    OncologicalDiseases = "Onkologinės ligos",
    GenitalDispersalDisadvantages = "Lyties organų sklaidos trūkumai",
    UterineFibroids = "Gimdos miomos, komplikuojančios nėštumo eigą",
    AlcoholismAndDrugAddiction = "Alkoholizmas, narkomanija",
    HIVandAIDSOthers = "ŽIV, AIDS, hepatitas B ir (ar) C",
}

export const getRiskLevel = (style: string): string => {
    switch (style) {
        case "bg-danger":
            return "Didelė";

        case "bg-warning":
            return "Vidutinė";

        default:
            return "Maža";
    }
};

export enum NaturalWayAndCaesareanOperationLowRisks {
    Uncomplicated = "Nekomplikuotas nėštumas",
}

export enum NaturalWayAndCaesareanOperationMediumRisks {
    Age = "Amžius – daugiau nei 35 metai",
    Obesity = "2 ir 3 laipsnio nutukimas",
    MultipleBirths = "Ketvirtas ir daugiau gimdymas",
    LaborWaterlessPeriod = "Gimdymo ar bevandenio laikotarpio trukmė 24val. ir daugiau",
    LegsVaricoseVeins = "Kojų venų varikoziniai mazgai",
    Chorioamnionitis = "Chorionamnionitas",
    Preeclampsia = "Preeklampsija",
    ForcedBedRest = "Priverstinis gulėjimas 4 ir daugiau dienų iki gimdymo",
    DeliveryUsingForcepsOrVacuumExtractor = "Gimdymas naudojant reples ar vakuuminį ekstraktorių",
    HeavyBleeding = "Gausus kraujavimas (šoko indeksas >0,8)",
    Dehydration = "Dehidracija (Ht > 0,38)",
    NephroticSyndrome = "Nefrozinis sindromas",
    SickleCellAnemia = "Pjautuvinė anemija",
    PlacentaRebound = "Placentos atšoka",
    Bleeding = "Nukraujavimas > 1000 ml.",
    AfterCaesareanOperation = "Po planinės cezario pjūvio operacijos (III ir IV skubumo grupės) - du ir daugiau iš aukščiau išvardintų veiksnių",
}

export enum NaturalWayHighRisks {
    Thrombosis = "Buvusi moters arba šeiminė giliųjų venų trombozė (GVT) su plaučių embolija (PE) arba be jos",
    AcquiredThrombophilia = "Laboratoriniais tyrimais patvirtinta įgimta arba įgyta trombofilija.",
    LowerLimbsParalysis = "Apatinių galūnių paralyžius",
    PostpartumHysterectomy = "Histerektomija po gimdymo",
    MechanicalHeartValves = "Mechaniniai širdies vožtuvai",
    JointProsthesis = "Sąnario protezas",
    ChronicAtrialFibrillation = "Lėtinis prieširdžių virpėjimas",
    ClinicallyManifestedPlacentalAbruption = "Kliniškai pasireiškusi placentos atšoka",
    GVT = "GVT (su PE ar be jos) nėštumo metu",
}

export enum CaesareanOperationHighRisks {
    ThreeOrMoreMediumRisks = "Nustatyti trys ir daugiau vidutinės rizikos diagnostiniai kriterijai",
    Hysterectomy = "Histerektomija ar kita pilvo ertmės operacija atliekant planinį cezario pjūvį",
    GVT = "Buvusi moters arba šeiminė GVT (su PE arba be jos)",
    Paraplegia = "Paraplegija",
    UrgentCaesareanOperation = "	Skubi cezario pjūvio operacija (I-a ir II-a skubumo grupės)",
    LaboratoryTestsThrombophilia = "Laboratoriniais tyrimais patvirtinta įgimta ar įgyta trombofilija",
}
