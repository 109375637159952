angular.module('medvais.doctor.user').directive('medvaisDashboardImgStudyContent', function () {
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/medvais/doctor/user/dashboard-img-study-content.html',
    controller: 'medvaisDashboardImgStudyContentCtrl'
  };
});

angular.module('medvais.doctor.user').controller('medvaisDashboardImgStudyContentCtrl', ["$scope", function($scope) {  
  
    $scope.getImgStudyListUrlWithFilter = function(modalityFilter) {
        return $scope.options.getImgStudyListUrlWithFilter(modalityFilter);
    };
    
    $scope.getImgStudyListUrl = function() {
        return $scope.options.getImgStudyListUrl();
    };

}]);