import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Observer, map, of, switchMap } from 'rxjs';
import { ApiV2AttachedUnsorted } from 'src/api/api-v2-doc-ref-attached-unsorted';
import { ApiV2DocumentClassifier } from 'src/api/api-v2-document-classifier';
import { ApiV2Organizations } from 'src/api/api-v2-organizations';
import { DocumentTagEnum } from '../../../../shared/models/document-tag.enum';
import { OrganizationModel } from 'src/app/shared/models/organization.model';
import { ApiV2Documents } from '../../../../../api/api-v2-documents';
import { RouteParams } from "../../../../ng1.routeParams";

@Component({
    selector: 'app-doc-ref-attached-unsorted',
    templateUrl: './doc-ref-attached-unsorted.component.html',
    providers: [DatePipe]
})
export class DocRefAttachedUnsortedComponent implements OnInit {

    backPath: string;
    fileSelected: boolean = false;
    fileName: string = '';
    unsortedForm: FormGroup;
    date = new Date();
    documentTypeArray: string[];
    docTypeValue: string;
    organizationArray: OrganizationModel[];
    search?: string;
    suggestions$?: any;
    errorMessage?: string;
    noResult = false;
    formData = new FormData;
    patientId = null;
    emitedValues = null;
    saveStatus = true;
    documentTag = DocumentTagEnum.e027a
    prefillData: any;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private apiV2DocumentClassifier: ApiV2DocumentClassifier,
        private http: HttpClient,
        private apiV2Organizations: ApiV2Organizations,
        private params: RouteParams,
        private apiV2DocRefAttachedUnsorted: ApiV2AttachedUnsorted,
        private apiV2Documents: ApiV2Documents,
    ) {
        this.unsortedForm = this.fb.group({
            date: [this.datePipe.transform(this.date, "yyyy-MM-dd")],
            unsorted_document_type: [null, Validators.required],
            desc: [],
            recipient: this.fb.array([]),
            state: [],
            visibility: [true, Validators.required],
            masterIdentifier: [],
            deleteStatus: [false],
        });
        this.unsortedForm.get('date').disable();
    }

    ngOnInit(): void {
        this.getPatientId();
        this.backPath = `dp/patients/${this.patientId}/doc-unsorted/list`;
        this.getDocumentClassifierList();
        // TODO: replace this with a normal API call to get OrganizationList
        this.getOrganizationsList();
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getDocumentClassifierList() {
        this.apiV2DocumentClassifier.getClassifierList().pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.documentTypeArray = response;
            }
        });
    }

    getOrganizationsList() {
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.search);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Organizations.getOrganizations(query, 10, 'ROOT_ONLY').pipe(map((organization: OrganizationModel[]) => this.organizationArray = organization));
                }
                return of([]);
            })
        );
    }

    getPatientId() {
        // this.patientId = this.route.parent.snapshot.paramMap.get('id');
        this.patientId = this.params.id;
    }

    onFileSelected(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.fileName = file.name;
            this.formData.append('file', file);
            this.fileSelected = true;
            this.saveStatus = false;
        }
    }

    onFileRemove() {
        this.fileSelected = false;
        this.saveStatus = true;
        this.fileName = '';
    }

    goBack() {
        this.router.navigate([this.backPath]);
    }

    addRecipient(inputValue) {
        if (inputValue) {
            const recipientFormArray = this.unsortedForm.get('recipient') as FormArray;

            // Check for duplicates before adding
            const isDuplicate = recipientFormArray.controls.some(control => {
                return control.value.name === inputValue;
            });

            if (!isDuplicate) {
                for (let i = 0; i < this.organizationArray.length; i++) {
                    if (inputValue === this.organizationArray[i].name) {
                        recipientFormArray.push(this.fb.control(this.organizationArray[i]));
                        this.search = '';
                    }
                }
            }
        }
    }

    removeRecipient(index) {
        (this.unsortedForm.get('recipient') as FormArray).removeAt(index);
    }

    getRecipientFormControls(): AbstractControl[] {
        return (<FormArray>this.unsortedForm.get('recipient')).controls
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    //TODO fix submit button to combine all information from forms and file
    // send(values) {
    //   console.log(this.unsortedForm.getRawValue());
    //   this.http.post('http://localhost:3001/api/v1/documents2/1/add-agreement', this.formData).subscribe((res: any) => {
    //       console.log("res",res);
    //   });
    // }

    // receives data from common-patient-widget
    onChanges(event) {
        this.emitedValues = event;
    }

    public submitForm(): void {
        var formValues = this.unsortedForm.getRawValue();
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.apiV2DocRefAttachedUnsorted.submitAttachedUnsortedView(mergeJSON, this.patientId).subscribe();
        // this.http.post('http://localhost:3001/api/v1/documents2/1/add-agreement', this.formData).subscribe((res: any) => {
        //     console.log("res",res);
        // });
    }

    gatherInfoFromEmittedData() {
        var encounterRef = this.emitedValues.encounter.fullId;
        var patientRef = this.emitedValues.encounter.patientRef;
        var patientData = this.emitedValues.encounter.patientData;
        var authorRef = this.emitedValues.item.practitionerRef;
        var authorData = this.emitedValues.practitioner;
        var organizationRef = this.emitedValues.item.organizationRef;
        var organizationData = this.emitedValues.item.organization;
        const combinedJSON = {
            encounterRef: encounterRef,
            patientRef: patientRef,
            patient: patientData,
            authorRef: authorRef,
            author: authorData,
            custodianRef: organizationRef,
            custodian: organizationData,
        }
        return combinedJSON;
    }

    public saveForm(): void {
        const formValues = { ...this.unsortedForm.value };
        delete formValues.state;
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        console.log("mergeJSON", mergeJSON);
        //this.apiV2DocRefAttachedUnsorted.saveAttachedUnsortedView(mergeJSON, this.patientId).subscribe();
        // this.http.post('http://localhost:3001/api/v1/documents2/1/add-agreement', this.formData).subscribe((res: any) => {
        //     console.log("res",res);
        // });
    }

    public clearForm(): void {
        this.unsortedForm.get('unsorted_document_type').patchValue(null);
        this.unsortedForm.get('desc').patchValue("");
        this.unsortedForm.get('state').patchValue("");
        this.unsortedForm.get('masterIdentifier').patchValue("");
        this.unsortedForm.get('visibility').patchValue(true);
        this.unsortedForm.get('deleteStatus').patchValue(false);
        this.fileSelected = false;
        this.saveStatus = true;
        this.cleanRecipient();
    }

    cleanRecipient() {
        const recipientFormArray = this.unsortedForm.get('recipient') as FormArray;
        while (recipientFormArray.length !== 0) {
            recipientFormArray.removeAt(0);
        }
    }

}
