import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, map, of, switchMap } from "rxjs";
import { ApiV2DocumentClassifier } from "src/api/api-v2-document-classifier";
import { ApiV2Organizations } from "src/api/api-v2-organizations";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import { FilterGroupModel } from "src/app/shared/models/filter-group.model";
import { OrganizationModel } from "src/app/shared/models/organization.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";

@Component({
    selector: 'f090-search',
    templateUrl: './doc-ref-090_search.component.html'
})
export class F090SearchComponent implements OnInit {

    @Input() selected: string;
    @Input() filtersGroup$: Observable<FilterGroupModel[]>;
    @Output() onSearch = new EventEmitter<any>();
    //docTypeValue: string;
    //documentTypeArray: string[];
    filters: FilterGroupModel[];
    noResult = false;
    organizationArray: OrganizationModel[];
    practitionerArray: PractitionerModel[];
    searchOrganization?: string;
    searchPractitioner?: string;
    suggestionsOrg$?: any;
    suggestionsPract$: any;
    count = 10;
    form: FormGroup;

    constructor(private formBuilder: FormBuilder,private apiV2DocumentClassifier: ApiV2DocumentClassifier,
        private apiV2Organizations: ApiV2Organizations, private apiV2Practitioners: ApiV2Practitioners) {
        this.form = this.formBuilder.group({
            unsorted_document_type: [null, Validators.required],
            recipient: [],
            recipient1: '',
            specialist: [],
            specialist1: '',
            dateFrom: [],
            dateTo: []
        });
    }


    ngOnInit(): void {
        //this.getDocumentClassifierList();
        this.getOrganizationsList();
        this.getPractitionerList();
    }

    // getDocumentClassifierList(){
    //     this.apiV2DocumentClassifier.getClassifierList().pipe(map((response: any) => response)).subscribe({
    //       next: (response) => {
    //         this.documentTypeArray = response;
    //       }
    //     });
    // }

    getOrganizationsList(){
        this.suggestionsOrg$ = new Observable((observer: Observer<string | undefined>) => {
          observer.next(this.form.get('recipient1').value);
        }).pipe(
          switchMap((query: string) => {
            if (query) {
              return this.getOrganizations(query).pipe(
                map((newDisplay: OrganizationModel[]) => {
                  return newDisplay.map(org => ({
                    ...org,
                    nameAndJarCode: org.name + ' ' + org.jarCode
                  }));
                })
              );
            }
            return of([]);
          })
        );
    }

    getOrganizations(query): Observable<OrganizationModel[]>{
      return this.apiV2Organizations.getOrganizations(query, this.count, 'ROOT_ONLY').pipe(map((organization: OrganizationModel[]) => this.organizationArray = organization));
    }

    getPractitionerList(){
        this.suggestionsPract$ = new Observable((observer: Observer<string | undefined>) => {
          observer.next(this.form.get('specialist1').value);
        }).pipe(
          switchMap((query: string) => {
            if (query) {
              return this.getPractitioners(query).pipe(
                map((newDisplay: PractitionerModel[]) => {
                  return newDisplay.map(pra => ({
                    ...pra,
                    givenNameAndFamilyName: pra.givenName + ' ' + pra.familyName
                  }));
                })
              );
            }
            return of([]);
          })
        );
    }

    getPractitioners(query): Observable<PractitionerModel[]>{
      return this.apiV2Practitioners.getGroupedPractitioners(query, this.count).pipe(map((practitioner: PractitionerModel[]) => this.practitionerArray = practitioner));
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    // retrieves selected organization object and get its id
    typeaheadOnSelect1(e: TypeaheadMatch): void {
      this.form.controls['recipient'].setValue(e.item.id);
    }

    // retrieves selected practitioner object and get its id
    typeaheadOnSelect2(e: TypeaheadMatch): void {
      this.form.controls['specialist'].setValue(e.item.id);
    }

    searchAdvanced() {
        const query: Object = this.form.touched ? this.form.value : undefined;
        this.onSearch.emit(query);
    }

    clear() {
        this.form.reset();
    }
}