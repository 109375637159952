angular.module('erx.api')
        .provider("erxApiConfig", function () {
          var self = this;
          this.options = { };

          this.$get = function () {
            var extend: any = {};
            extend.options = self.options;
            return extend;
          };
          return this;
        });
