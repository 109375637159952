import { Component, Input } from '@angular/core';
import { Entry, PartTableRowConfig } from '../../../../../models/e096.model';
import moment from 'moment';

@Component({
    selector: 'app-e096-part-table-fights',
    templateUrl: './e096-part-table-fights.component.html',
})
export class E096PartTableFightsComponent {
    @Input() set initialFormValue(value: Entry[]) {
        value.forEach(data => this.generateFightsTableData(data));
    };
    @Input() mainColumns: string[] = [];
    @Input() row: PartTableRowConfig;
    @Input() phase: 'latent' | 'active' = null;

    rowsCounter = this.generateArray(1, 5);
    fightsData: {
        [key: string]: Entry
    } = {};

    triangleSymbol = '&#9650;';
    emptyTriangleSymbol = '&#9651;';
    squareSymbol = '&#9726;';

    constructor() {
    }

    getDataByHour(hour: string, period: number, value: number): any {
        return +this.fightsData[`fights-${hour}-${period}`]?.fights === +value
            ? this.symbolFormat(this.fightsData[`fights-${hour}-${period}`]?.durationOfTheStrike) : '';
    }

    private symbolFormat(value: string): string {
        switch (value) {
            case '< 20s':
                return this.emptyTriangleSymbol;
            case '20-40s':
                return this.triangleSymbol;
            case '> 40s':
                return this.squareSymbol;
            default:
                return '';
        }

    }

    private generateArray<T>(start, end, specificValue?: T): Array<string | T> {
        return Array.from({length: end - start + 1}, (_, index) => specificValue ?? String(start + index));
    }

    private generateFightsTableData(data: Entry): void {
        const hour = moment(data.timeOfEntry).hours();
        const period = Math.floor((moment(data.timeOfEntry).minutes() % 60) / 15) + 1;
        if (!this.fightsData?.[`fights-${hour}-${period}`]) {
            this.fightsData[`fights-${hour}-${period}`] = data;
        } else {
            this.fightsData[`fights-${hour}-${period}`] = moment(data.timeOfEntry) > moment(this.fightsData[`fights-${ hour }-${ period }`].timeOfEntry)
                ? data : this.fightsData[`fights-${ hour }-${ period }`];
        }
    }
}
