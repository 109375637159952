<div class="modal fade" id="search" tabindex="-1" aria-labelledby="searchLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title fs-5" id="dpp.rea.res.mod.fil">{{'dp.rec.mod.fil' | translate}}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ng-content></ng-content>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="searchAdvanced()">{{'search' | translate}}</button>
                    <button class="btn btn-secondary" (click)="clear()">{{ 'clean' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>