import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root',
  })
  export class DirectiveReset {
    // Create a Subject to emit events that allows values to be multicasted to many Observers
    private resetSubject = new Subject<void>();

    // Expose an observable to components
    reset$ = this.resetSubject.asObservable();

    // Method to trigger a reset
    triggerReset(): void {
      this.resetSubject.next();
    }
  }