import {Component, Input, OnInit} from "@angular/core";
import moment from "moment";

@Component({
    selector: "dispense-info-view",
    templateUrl: "./dispense-info-view.component.html",
})
export class DispenseInfoViewComponent implements OnInit {
    protected readonly moment = moment;
    @Input() dispenseData: any;

    constructor() {
    }

    ngOnInit(): void {

    }

    getPrescriptionId(): string {
        return this.dispenseData?.prescription?.docNumber ?
            this.dispenseData?.prescription?.docNumber :
            this.dispenseData?.prescription?.ref?.split('/')?.[1];
    }
}