import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import moment from "moment";
import { Observable, Observer, map, of, switchMap } from "rxjs";
import { RouteParams } from 'src/app/ng1.routeParams';
import { ApiV2DocRefConsent } from "../../../../../api/api-v2-doc-ref-consent";
import { ApiV2DocRefConsentTemplate } from "../../../../../api/api-v2-doc-ref-consent-template";
import { ApiV2Documents } from '../../../../../api/api-v2-documents';
import { ApiV2Patients } from "../../../../shared/services/api-v2-patients";
import { DocRefConsentCategoryModel } from "../../../../shared/models/doc-ref-consent-category.model";
import { DocRefConsentDocStatusEnum } from "../../../../shared/models/doc-ref-consent-docStatus.enum";
import { DocRefConsentTemplateModel } from "../../../../shared/models/doc-ref-consent-template.model";
import { DocRefConsentModel } from "../../../../shared/models/doc-ref-consent.model";
import { FilteredDataModel } from "../../../../shared/models/filtered-data.model";
import { PatientSearchModel } from "../../../../shared/models/patient-search.model";
import { PatientModel } from "../../../../shared/models/patient.model";
import { PractitionerModel } from "../../../../shared/models/practitioner.model";
import {DpSessionContext} from "../../../services/dp-session-context.service";

@Component({
    selector: 'doc-ref-consent-create',
    templateUrl: './doc-ref-consent-create.component.html'
})
export class DocRefConsentCreateComponent implements OnInit {

    backPath: string;
    @Input() categories$: Observable<DocRefConsentCategoryModel[]>;
    @Input() filteredTemplates: DocRefConsentTemplateModel[];
    templates$: Observable<DocRefConsentTemplateModel[] | FilteredDataModel>;
    selectedTemplateId: any;
    selectedPatientId: any;
    form: FormGroup;
    patientField: string = "patient";
    categoryField: string = "category";
    templateTitleField: string = "templateTitle";
    expiryDateField: string = "expiryDate";
    patientControl: FormControl;
    categoryControl: FormControl;
    templateTitleControl: FormControl;
    expiryDateControl: FormControl;
    suggestions$?: Observable<PatientSearchModel[]>;
    practitionerData: PractitionerModel;
    currentDate = new Date();
    patientId = null;
    prefillData: any;
    documentTag = "";

    constructor(
        private router: Router,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private apiV2DocRefConsent: ApiV2DocRefConsent,
        private apiV2DocRefConsentTemplate: ApiV2DocRefConsentTemplate,
        private apiV2Patients: ApiV2Patients,
        private formBuilder: FormBuilder,
        private dpMainController: DpSessionContext
    ) {
        this.form = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.getPatientId();
        this.backPath = `dp/patients/${this.patientId}/doc-ref-consent/list`;
        this.categories$ = this.apiV2DocRefConsentTemplate.getCategories();
        this.templates$ = this.apiV2DocRefConsentTemplate.getDocRefConsentTemplatesList();
        this.initializeForm();
        this.initializeSuggestions();

        this.dpMainController.practitionerData$.subscribe(data => {
            this.practitionerData = data;
        });
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    initializeForm() {
        this.patientControl = new FormControl(null, [Validators.required]);
        this.categoryControl = new FormControl("", [Validators.required]);
        this.templateTitleControl = new FormControl(null, [Validators.required]);
        this.expiryDateControl = new FormControl(null, [Validators.required]);

        this.form.addControl(this.patientField, this.patientControl);
        this.form.addControl(this.categoryField, this.categoryControl);
        this.form.addControl(this.templateTitleField, this.templateTitleControl);
        this.form.addControl(this.expiryDateField, this.expiryDateControl);
    }

    initializeSuggestions() {
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.patientControl.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Patients.search({ q: query })
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(psm => this.getPatientTypeahead(psm)) || []));
                }
                return of([]);
            })
        );
    }

    changeCategory(id: any) {
        this.templates$ = this.apiV2DocRefConsentTemplate.getDocRefConsentTemplatesList();
        this.templates$.subscribe((data) => {
            this.filteredTemplates = data.filter(d => d.category.code === id && d.docStatus.code === DocRefConsentDocStatusEnum.VALID);
            this.selectedTemplateId = this.filteredTemplates.length > 0 ? this.filteredTemplates.at(0).id : null;
            this.form.controls[this.templateTitleField].setValue(this.selectedTemplateId);
        });
    }

    changeTemplate(id: any) {
        this.selectedTemplateId = id;
    }

    changePatient(event: any) {
        this.selectedPatientId = event?.item.id;
    }

    onViewClick() {
        if (this.selectedTemplateId) {
            window.open(`/dp/patients/${this.selectedPatientId}/doc-ref-consent-template/view/${this.selectedTemplateId}`, '_blank')
            // this.router.navigate(['dp', 'patients', this.selectedPatientId, 'doc-ref-consent-template', 'view', this.selectedTemplateId]);
        }
    }

    goBack() {
        if (this.patientId) {
            this.router.navigate([this.backPath]);
        } else {
            this.router.navigate(['/dp/doc-ref-consent/list']);
        }
    }

    getPatientTypeahead(patient: PatientModel) {
        return {
            id: patient.id,
            patient: (patient.namePrefix ? patient.namePrefix + " " : "") +
                patient.givenName + " " +
                patient.familyName + " " +
                patient.personalCode
        }
    }

    onSave() {
        if (this.form.valid) {
            this.apiV2DocRefConsent.createDocRefConsent(this.prepareData()).subscribe();
        } else {
            //error msg
        }
    }

    prepareData(): DocRefConsentModel {
        const timeStamp: string = moment().format();
        return {
            patient: { id: this.selectedPatientId },
            consentOrganization: this.practitionerData.practitionerOrganization,
            practitioner: this.practitionerData,
            created: timeStamp,
            period: {
                start: timeStamp,
                end: this.expiryDateControl.value,
            },
            templateId: this.selectedTemplateId,
        } as DocRefConsentModel;
    }
}