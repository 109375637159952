<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e097Gd.researchForm.title' | translate"
>
    <div class="card-body">
        <div class="row" *ngFor="let research of researches">
            <fieldset class="col-6 col-lg-3 d-flex flex-column">
                <app-checkbox
                    class="mt-3 col-form-label"
                    formClass="mb-0"
                    [inputId]="'gd-' + research.formGroup"
                    [labelText]="research.name | translate"
                    [fControl]="formGroup.get(research.formGroup + '.result')"
                ></app-checkbox>
                <app-input
                    *ngIf="controlValue(research.formGroup + '.result') && research.additionalTextarea"
                    type="textarea"
                    textAreaRows="2"
                    [fControl]="formGroup.get(research.formGroup + '.' + research.additionalTextarea)"
                ></app-input>
            </fieldset>
            <app-input
                *ngIf="controlValue(research.formGroup + '.result') && !research.selectContol"
                labelText="{{'doc.pregnancyDashboard.e097Gd.InspectionsForm.comment' | translate}}:"
                class="form-group col-6 col-lg-9"
                labelClass="col-form-label"
                type="textarea"
                textAreaRows="3"
                [fControl]="formGroup.get(research.formGroup + '.comment')"
            ></app-input>
            <app-select
                *ngIf="controlValue(research.formGroup + '.result') && research.selectContol"
                class="form-group col-6 col-lg-3 mb-0 d-flex align-items-end"
                id="ge-{{research.selectContol}}"
                [fControl]="formGroup.get(research.formGroup + '.' + research.selectContol)"
                [values]="selectionValuesFromEntity(answersVariants?.['bloodGroup'])"
            ></app-select>
        </div>
    </div>
</nhr-record-widget>