ng1App.controller('doctor.user.RepresentativesCtrl', 
    ['$scope', 'SearchController', 'PersonRepresentationsApiFactory', 'MessageService', 'DpSessionContext',
     function ($scope, SearchController, PersonRepresentationsApiFactory, MessageService, DpSessionContext) {

    $scope.$emit('setHelpPage', 'perzireti-atstovavimu-sarasa');

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    $scope.filters = [
        // All
        {title: 'dp.use.rep.rec.all', count: 0, query: {}},
        // Valid
        {title: 'dp.use.rep.rec.val', count: 0, query: {valid: true}},
        // Invalid
        {title: 'dp.use.rep.rec.inv', count: 0, query: {valid: false}},
        // Blocked
        {title: 'dp.use.rep.rec.blo', count: 0, query: {blocked: true}},
        // Not blocked
        {title: 'dp.use.rep.rec.unb', count: 0, query: {blocked: false}, },
        // Delegated specialists
        {title: 'dp.use.rep.rec.sub', count: 0, query: {representativeType: ['2']}},
        // Authorized specialists
        {title: 'dp.use.rep.rec.aut', count: 0, query: {representativeType: ['3']}}
    ];

    $scope.searchCtrl = new SearchController({
        viewId: 'dp_doctor_representitives_list',
        count: 10,
        initForm: function () {
            return {filter: $scope.filters[0]};
        },
        searchApi: PersonRepresentationsApiFactory.getList,
        showPageCounter: true});

    $scope.searchCtrl.formToQuery = function (form) {
        var query: any = {};
        query.type = 'PRACTITIONER';
        
        switch (form._searchType) {
            case 'advanced':
                break;
            case 'filter':
                angular.extend(query, form.filter.query);
                break;
            case 'quick':
                form.filter = undefined;
                query.text = form.text;
                break;
        }

        return query;
    };
    DpSessionContext.getSettings(function (data) {
        $scope.searchCtrl.setCount(data.count_per_page || 10);
        $scope.searchCtrl.searchLastOrAll();
    });


    // Count records
    _.each($scope.filters, function (filter) {
        PersonRepresentationsApiFactory.getList(angular.extend(filter.query, {type: 'PRACTITIONER', count: 0}), function (data) {
            filter.count = data.total;
        });
    });

    $scope.changeFilter = function (filter) {
        if (filter.count > 0) {
            $scope.searchCtrl.clear();
            $scope.searchCtrl._form.filter = filter;
            $scope.searchCtrl.searchFilter();
        }
    };
}]);