<button *ngIf="createable" type="button" (click)="buttonClickedNavigate(urlPathCreate)"
    class="btn btn-square-sm btn-outline-primary me-2" [title]="createButtonTitle">
    <i class="fa-fw fa-regular fa-file-circle-plus"></i>
</button>
<button *ngIf="copyable" type="button" (click)="buttonClickedNavigate(urlPathCopy)"
    class="btn btn-square-sm btn-outline-primary me-2" [title]="copyButtonTitle">
    <i class="fa-fw fa-regular fa-copy"></i>
</button>

<button *ngIf="deleteable" type="button" (click)="removeRow()" class="btn btn-square-sm btn-outline-primary me-2"
    [title]="deleteButtonTitle">
    <i class="fa-fw fa-regular fa-trash-can"></i>
</button>

<button *ngIf="editable" type="button" (click)="buttonClickedNavigate(urlPathEdit)"
    class="btn btn-square-sm btn-outline-primary me-2" [title]="editButtonTitle">
    <i class="fa-fw fa-solid fa-pencil"></i>
</button>

<button *ngIf="viewable" type="button" (click)="buttonClickedNavigate(urlPathView)"
    class="btn btn-square-sm btn-outline-primary me-2" [title]="viewButtonTitle">
    <i class="fa-fw fa-regular fa-eye"></i>
</button>