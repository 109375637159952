import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";
import {FilteredDataModel} from "../app/shared/models/filtered-data.model";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Templates extends ConfigService {
    path = this.apiUrl + '/templates';

    findForList(query?: any): Observable<FilteredDataModel> {
        const url = `${this.path}/forList`;
        return this.http.get<FilteredDataModel>(url, {params: query});
    }

    load(templateId: string, query?: any): Observable<any> {
        const url = `${this.path}/${templateId}/load`;
        return this.http.post<any>(url, {params: query});
    }
}