angular.module('medvais.components').service('$medvaisPatientIdentification', ["$modal", "$rootScope", "$q", "$filter", "MedvaisPatientApiFactory", "MedvaisImagingStudyApiFactory", "MedvaisPatientSearchService", "$timeout", "FormLoaderService", "MessageService", "MedvaisErrorHandler", function (
    $modal, $rootScope, $q, $filter,
    MedvaisPatientApiFactory,
        MedvaisImagingStudyApiFactory,
        MedvaisPatientSearchService, $timeout, FormLoaderService, MessageService, MedvaisErrorHandler) {

    var scope = $rootScope.$new();
    var deferred;

    scope.data = {};
    scope.data.errorMsg = '';

    scope.params = {};
    scope.params.patientToIdentify = {};
    scope.params.identifiedPerson = null;
    scope.params.identifiedPersonFullId = null;

    scope.loading = false;
    scope.timeout;

    scope.searchPatient = function (value) {
        if(scope.timeout) {
            $timeout.cancel(scope.timeout);
        }

        scope.data.errorMsg = '';

        if (!value || value.length < 3 || scope.params.identifiedPersonFullId) {
            return [];
        }

        scope.loading = true;
        return scope.timeout = $timeout(function(){

            return MedvaisPatientSearchService.search(value).finally(function() {
                scope.loading = false;
                scope.timeout = null;
            });

        }, 2000);
    };

    scope.searchPatientChange = function (patient) {
        if (patient && patient.id) {
            scope.params.identifiedPersonFullId = patient.fullId;
        }
    };

    scope.changeIdentifiedPerson = function () {
        scope.params.identifiedPerson = null;
        scope.params.identifiedPersonFullId = null;
    };

    scope.confirmPatientIdentification = function () {
        console.log("imaging study: " + scope.params.patientToIdentify.imagingStudyId);
        console.log("patient ref: " + scope.params.identifiedPersonFullId);

        if (scope.params.identifiedPersonFullId) {
            FormLoaderService.startFormLoading();
            MedvaisImagingStudyApiFactory.identifyPatient({id: scope.params.patientToIdentify.imagingStudyId, patientRef: scope.params.identifiedPersonFullId}).$promise.then(
                function () {
                    var doc = {imagingStudy: {id: scope.params.patientToIdentify.imagingStudyId}, relatedImagingStudies: []};
                    deferred.resolve(doc);
                },
                function(err){
//                    deferred.reject($filter('translate')('medvais.err.msg.common.patient.identification'));
                    deferred.reject(MedvaisErrorHandler.handlePatientIdentification(err));
                }
            ).finally(
                function(){
                    modal.hide();
                }
            );
        } else {
            MessageService.clearAll();
            scope.data.errorMsg = $filter('translate')('medvais.err.msg.choose.patient.for.identification.from.list');
        }
    };


    var modal: any = {};
    modal = $modal({
        template: 'modules/medvais/components/modal/medvais-modal-patient-identification.html',
        title: $filter('translate')('medvais.identification.modal.title'),
        scope: scope,
        show: false});

    var parentShow = modal.show;

    modal.show = function (patientToIdentify) {
        scope.data.errorMsg = '';
        scope.params.patientToIdentify = patientToIdentify;
        scope.params.identifiedPerson = null;
        scope.params.identifiedPersonFullId = null;
        parentShow();
        deferred = $q.defer();
        return deferred.promise;
    };

    return modal;

}]);