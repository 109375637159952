import { Component } from "@angular/core";
import { RouteParams } from '../../../../../ng1.routeParams';
import { ApiV2E096 } from '../../../../../../api/api-v2-doc-ref-e096';
import { ApiV2E097 } from '../../../../../../api/api-v2-doc-ref-e097';
import { ApiV2E010 } from '../../../../../../api/api-v2-doc-ref-e010';
import { ApiV2PregnancyDashboard } from '../../../../../../api/api-v2-doc-pregnancy-dashboard';
import { ApiV2Documents } from '../../../../../../api/api-v2-documents';
import { forkJoin } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: "app-e010-1-1",
    templateUrl: "./e010-1-1.component.html",
})
export class E01011Component {
    formsData: { [key: string]: any };

    private patientId: string;

    constructor(
        private params: RouteParams,
        private apiV2E096: ApiV2E096,
        private apiV2E097: ApiV2E097,
        private apiV2E010: ApiV2E010,
        private apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private apiV2Documents: ApiV2Documents
    ) {
        this.getPatientId();
        forkJoin({
            e113Nd: this.apiV2PregnancyDashboard.getPregnancyTerminationInfo(this.patientId),
            e113Tp: this.apiV2PregnancyDashboard.getResearchesAndProceduresDuringPregnancy(this.patientId),
            e113Rv: this.apiV2PregnancyDashboard.getRiskFactors(this.patientId),
            e113Nna: this.apiV2PregnancyDashboard.getPregnancyTerminationInfo(this.patientId),
            e113Ug: this.apiV2PregnancyDashboard.getFetusUltrasoundExaminationList(this.patientId),
            e096N: this.apiV2E096.getE096FormView('n', this.patientId),
            e096Ge: this.apiV2E096.getE096FormView('ge', this.patientId),
            e096Part: this.apiV2E096.getE096FormView('part', this.patientId),
            e096Cpo: this.apiV2E096.getE096FormView('cpo', this.patientId),
            e096An: this.apiV2E096.getE096FormView('na', this.patientId),
            e097At: this.apiV2E097.getE097FormView('at', this.patientId),
            e097Gd: this.apiV2E097.getE097FormView('gd', this.patientId),
            e097Npa: this.apiV2E097.getE097FormView('npa', this.patientId),
            e063: this.apiV2Documents.getE063Data(),
            e003: this.apiV2Documents.getE003Data(),
            e106_2_1: this.apiV2Documents.getE106_2_1Data(),
            e10Mpn: this.apiV2E010.getE010FormView('mpn'),
            ngn: this.apiV2PregnancyDashboard.getNGNCaption(this.patientId),
        })
        .pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.formsData = res;
            });
    }


    getPatientId(){
        this.patientId = this.params.patientId;
    }
}
