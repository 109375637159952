ng1App.factory("DocumentsApiFactory", [
    "$resource",
    "apiUrl",
    function ($resource, apiUrl) {
        var URL_BASE = apiUrl + "/documents2";
        return $resource(
            "",
            {},
            {
                // -------- new ones ----------
                getByComposition: {
                    method: "GET",
                    url: URL_BASE + "/:id",
                },
                getForHistoryPreview: {
                    method: "GET",
                    url: URL_BASE + "/:compositionId/history/:historyId",
                },
                getByCompositionForEdit: {
                    method: "GET",
                    url: URL_BASE + "/:id/edit",
                },
                getByCompositionForAmend: {
                    method: "GET",
                    url: URL_BASE + "/:id/amend",
                },
                getPrefilledForm: {
                    method: "GET",
                    url: URL_BASE + "/prefillForm",
                },
                getEncounter: {
                    method: "GET",
                    url: URL_BASE + "/:compositionId/encounter",
                },
                searchForList: {
                    method: "GET",
                    url: URL_BASE + "/forList",
                    isArray: true,
                },
                searchForListWithTotals: {
                    method: "GET",
                    url: URL_BASE + "/forList?appendTotals=true",
                    isArray: false,
                },
                getDocumentCount: {
                    method: "GET",
                    url: URL_BASE + "/count",
                },
                hasActiveE025Related: {
                    method: "GET",
                    url: URL_BASE + "/:id/hasActiveE025Related",
                },
                getDocumentCountAll: {
                    method: "GET",
                    url: URL_BASE + "/countAll",
                },
                getPatientDocumentCount: {
                    method: "GET",
                    url: URL_BASE + "/count?patient=:patientIdentifier",
                },
                initSigning: {
                    method: "GET",
                    url: URL_BASE + "/initSigning",
                },
                confirmSigning: {
                    method: "GET",
                    url: URL_BASE + "/signResult",
                },
                getRelatedDocuments: {
                    method: "GET",
                    url: URL_BASE + "/:id/relatedDocuments",
                    isArray: true,
                },
                updatePartial: {
                    method: "PUT",
                    url: URL_BASE + "/:id/savePartial?type=:docType",
                },
                updateFinal: {
                    method: "PUT",
                    url: URL_BASE + "/:id/saveFinal?type=:docType",
                },
                updateAmended: {
                    method: "PUT",
                    url: URL_BASE + "/:id/amend",
                },
                createPartial: {
                    method: "POST",
                    url: URL_BASE + "/savePartial?type=:docType",
                },
                createFinal: {
                    method: "POST",
                    url: URL_BASE + "/saveFinal?type=:docType",
                },
                cancel: {
                    method: "POST",
                    url: URL_BASE + "/:documentId/cancelDocument",
                },
                makeFinal: {
                    method: "POST",
                    url: URL_BASE + "/makeFinal",
                },
                getOrderResponseDocumentsForE025: {
                    method: "GET",
                    url: URL_BASE + "/orderResponseDocumentsForE025",
                },
                getOrderResponseDocumentsForE003: {
                    method: "GET",
                    url: URL_BASE + "/orderResponseDocumentsForE003",
                },
                getOrderResponseDocumentsForE014: {
                    method: "GET",
                    url: URL_BASE + "/orderResponseDocumentsForE014",
                },
                getOrderDocumentsByOrder: {
                    method: "GET",
                    url: URL_BASE + "/orderDocumentsByOrder",
                    isArray: true,
                },
                getOrderResponseDocumentsByOrder: {
                    method: "GET",
                    url: URL_BASE + "/orderResponseDocumentsByOrder",
                    isArray: true,
                },
                getEncounterDocsCounts: {
                    method: "GET",
                    url: URL_BASE + "/docCountInEncounter",
                    isArray: false,
                },
                search: {
                    method: "GET",
                    url: URL_BASE,
                    isArray: true,
                },
                orderDocuments: {
                    method: "GET",
                    url: URL_BASE + "/orderDocuments",
                },
                orderDocumentCountsByStatus: {
                    method: "GET",
                    url: URL_BASE + "/orderDocumentCountsByStatus",
                },
                orderDocumentCount: {
                    method: "GET",
                    url: URL_BASE + "/orderDocumentCount",
                },
                documentHistory: {
                    method: "GET",
                    url: URL_BASE + "/:compositionId/history",
                },
                getE025FromFinishedEncounters: {
                    method: "GET",
                    url: URL_BASE + "/e025FromFinishedEncounters",
                },
                changeE025ContinuedTreatmentParentDoc: {
                    method: "POST",
                    url: URL_BASE + "/e025ChangeContinuedTreatmentParentDoc",
                },
                submitPrescriptions: {
                    method: "POST",
                    url: URL_BASE + "/submitPrescriptions?compositionId=:id",
                },
                getAgreements: {
                    method: "GET",
                    url: URL_BASE + "/agreements",
                },
                cancelAgreement: {
                    method: "POST",
                    url: URL_BASE + "/cancel-agreement/:agreementId",
                },
                toggleConfidentiality: {
                    method: "PUT",
                    url: URL_BASE + "/:id/toggleConfidentiality",
                },
                clearConfidentiality: {
                    method: "PUT",
                    url: URL_BASE + "/clearConfidentiality",
                },
                getDiagnoses: {
                    method: "GET",
                    url: URL_BASE + "/:documentId/diagnoses",
                    isArray: true,
                },
                getConfidentialityHistory: {
                    method: "GET",
                    url: URL_BASE + "/:documentId/confidentialityHistory",
                },
                getProcedures: {
                    method: "GET",
                    url: URL_BASE + "/:documentId/procedures",
                    isArray: true,
                },
                getHasPdf: {
                    method: "GET",
                    url: URL_BASE + "/:compositionId/hasPdf",
                },
            }
        );
    },
]);

ng1App.service("AgreementUploader", [
    "$upload",
    "apiUrl",
    function ($upload, apiUrl) {
        this.upload = function (compositionId, form, file, successCb, errorCb) {
            $upload
                .upload({
                    url: apiUrl + "/documents2/" + compositionId + "/add-agreement",
                    data: { data: form },
                    file: file,
                })
                .progress(function (evt) {})
                .success(function (data, status, headers, config) {
                    (successCb || angular.noop)();
                })
                .error(function (error) {
                    (errorCb || angular.noop)(error);
                });
        };
    },
]);
