ng1App.controller('Mock',
    ['$scope',
        function ($scope) {
            $scope.runFormsExample = function () {
                // Form validation example
                this.forms = document.querySelectorAll('.needs-validation')
                Array.from(this.forms as NodeListOf<HTMLFormElement>).forEach(form => {
                    form.addEventListener('submit', event => {
                        if (!form.checkValidity()) {
                            event.preventDefault()
                            event.stopPropagation()
                        }
                        form.classList.add('was-validated')
                    }, false)
                })

                // TODO-design: use tooltips with ngx-bootstrap, remove tooltip helper code after
                const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
                this.tooltipList = tooltipTriggerList.map(tooltipTriggerEl => {
                    return new bootstrap.Tooltip(tooltipTriggerEl);
                });
            };
            $scope.runFormsExample();
        }
    ]
);