<table class="table table-bordered table-hover w-100 mt-4 shadow-none" *ngIf="initialFormValue?.length">
    <tbody>
    <ng-container *ngFor="let row of rows; let i = index">
        <tr *ngIf="!row?.custom" class="text-center d-flex part-table-row w-100 border-0" [class.mt-3]="row.marginTop" [class.border-0]="row.noBorders">
            <div *ngIf="showDescription" class="p-0 part-table-cell text-nowrap pe-2 d-flex align-items-center" [style.flex]="3">{{row.name | translate}}</div>
            <td *ngFor="let column of mainColumns; let j = index" class="p-0 flex-1 part-table-cell border-top border-bottom" [class.border-0]="row.noBorders">
                <ng-container *ngTemplateOutlet="tdCells; context: {row, column, id: row.property + '-' + i + '-' + j}"></ng-container>
            </td>
        </tr>
        <div *ngIf="row?.custom && row?.name === 'doc.pregnancyDashboard.e096Part.partogram.cervixOpening'" class="text-center d-flex part-table-row w-100 border-0">
            <div *ngIf="showDescription" class="p-0 part-table-cell text-nowrap pe-2 d-flex justify-content-center text-vertical" [style.flex]="3">{{row.name | translate}}</div>
            <app-e096-part-table-cervix
                [initialFormValue]="initialFormValue"
                [mainColumns]="mainColumns"
                [row]="row"
                [connections]="connections"
                [style.flex]="mainColumns.length"
                class="p-0"
                phase="latent" />
        </div>
        <div *ngIf="row?.custom && row?.name === 'doc.pregnancyDashboard.e096Part.partogram.fights'" class="text-center d-flex part-table-row w-100 border-0">
            <div *ngIf="showDescription" class="p-0 part-table-cell text-nowrap pe-2 d-flex justify-content-center text-vertical" [style.flex]="3">{{row.name | translate}}</div>
            <app-e096-part-table-fights
                [initialFormValue]="initialFormValue"
                [mainColumns]="mainColumns"
                [row]="row"
                [style.flex]="mainColumns.length"
                class="p-0"
                phase="latent" />
        </div>
    </ng-container>
    </tbody>
</table>

<ng-template #tdCells let-row="row" let-column="column" let-id="id">
    <ng-container [ngSwitch]="row.timeSchedule">
        <ng-container *ngSwitchCase="'15m'">
            <div class="d-flex border-light h-100">
                <div class="p-2 px-1 flex-1" [id]="id + '-' + 1">
                    {{getDataByHour(column, 1, row)}}
                </div>
                <div class="p-2 px-1 flex-1" [id]="id + '-' + 2" [class.border-start]="!row.noBorders">
                    {{getDataByHour(column, 2, row)}}
                </div>
                <div class="p-2 px-1 flex-1" [id]="id + '-' + 3" [class.border-start]="!row.noBorders">
                    {{getDataByHour(column, 3, row)}}
                </div>
                <div class="p-2 px-1 flex-1" [id]="id + '-' + 4" [class.border-start]="!row.noBorders">
                    {{getDataByHour(column, 4, row)}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'30m'">
            <div class="d-flex border-light h-100">
                <div class="py-2 px-1 flex-1" [id]="id + '-' + 1">
                    {{getDataByHour(column, 1, row)}}
                </div>
                <div class="py-2 px-1 flex-1" [id]="id + '-' + 2" [class.border-start]="!row.noBorders">
                    {{getDataByHour(column, 2, row)}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'1h'">
            <div class="d-flex border-light h-100">
                <div class="py-2 px-1 flex-1" [id]="id">
                    {{getDataByHour(column, 1, row)}}
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
