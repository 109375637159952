angular.module('erx.prs').directive('erxDispensedPrescriptions', ["$erxHelpers", "ErxPrescriptionsApiFactory", function ($erxHelpers, ErxPrescriptionsApiFactory) {
  return {
    restrict: 'E',
    scope: {options: '='},
    replace: false,
    templateUrl: 'modules/erx/prs/small_prescription_lists/dispensed_prescriptions.html',
    link: function (scope: any, element, attrs) {
      scope.h = $erxHelpers;
      scope.dspFilter = attrs.dspFilter;
      scope.title = attrs.title;
      scope.prescriptions = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
              {
                status: null,
                count: 3,
                page: 1
              });
    }
  };
}]);