<div *ngIf="viewData">
    <app-title [backPath]="backPath">
        {{'090/a - Pranešimas apie diagnozuotą onkologinį susirgimą'| translate}}
    </app-title>
    <!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="false" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
        (valueChange)="onChanges($event)"></app-common-patient-widget>
    <app-diagnoses-form *ngIf="!isLoading" (currentDiagnosesChange)="onDiagnosisChanges($event)"
        [viewOnly]="isViewOnlyMode" [currentDiagnoses]="diagnosisArray"
        [isRequiredFieldInForm]=" true"></app-diagnoses-form>
    <nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true"
        [title]="'doc-ref-058-089-151a-form.component'|translate">
        <div class="widget-body">
            <div class="row mb-2">
                <label for="templateTitle"
                    class="col-md-5 d-flex fw-bold justify-content-sm-start justify-content-md-end">Naviko
                    morfologija</label>
                <div class="col-md-6">
                    {{viewData.tumorMorphology}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="templateTitle"
                    class="col-md-5 d-flex fw-bold justify-content-sm-start justify-content-md-end">Kodas</label>
                <div class="col-md-6">
                    {{viewData.tumorMorphologyCode}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="templateTitle"
                    class="col-md-5 d-flex fw-bold justify-content-sm-start justify-content-md-end">Naviko
                    diferencialinis laipsnis</label>
                <div class="col-md-6">
                    {{viewData.tumorDifferentialGrade}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="templateTitle"
                    class="col-md-5 d-flex fw-bold justify-content-sm-start justify-content-md-end">Kodas</label>
                <div class="col-md-6">
                    {{viewData.tumorDifferentialGradeCode}}
                </div>
            </div>
            <label for="templateTitle" class="col-md-5 fw-bold text-sm-start text-md-end">Klinikinis:</label>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">T </label>
                <div class="col-sm-6">
                    {{viewData.clinicalT.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">N </label>
                <div class="col-sm-6">
                    {{viewData.clinicalN.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">M </label>
                <div class="col-sm-6">
                    {{viewData.clinicalM.name}}
                </div>
            </div>
            <label for="templateTitle" class="col-md-5 fw-bold text-sm-start text-md-end">Patologinis:</label>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">T </label>
                <div class="col-sm-6">
                    {{viewData.pathologicalT.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">N </label>
                <div class="col-sm-6">
                    {{viewData.pathologicalN.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">M </label>
                <div class="col-sm-6">
                    {{viewData.pathologicalM.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">Naviko stadija <span
                        class="text-danger">*</span></label>
                <div class="col-sm-6">
                    {{viewData.tumorStage.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="category" class="col-md-5 fw-bold text-sm-start text-md-end">Onkologinės ligos diagnozės
                    nustatymo aplinkybės <span class="text-danger">*</span></label>
                <div class="col-sm-6">
                    {{viewData.circumstancesForDiagnosis.name}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="templateTitle" class="col-md-5 col-form-label text-end align-self-start">Onkologinės ligos
                    diagnozės
                    patvirtinimo būdas (nurodyti vieną ar daugiau) <span class="text-danger">*</span></label>
                <div class="col-sm-6">
                    <div>
                        <div class="col-sm-9 mb-3">
                            <div *ngFor="let item of viewData.methodOfConfirmingDiagnosis; let i=index">{{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div for="templateTitle" class="col-md-5 fw-bold text-sm-start text-md-end">Pranešimo užpildymo data
                </div>
                <div class="col-md-6">
                    {{viewData.reportDate | date:'yyyy-MM-dd'}}
                </div>
            </div>
            <div class="row mb-2">
                <label for="templateTitle" class="col-md-5 fw-bold text-sm-start text-md-end">Pastabos vėžio
                    registrui</label>
                <div class="col-md-6">
                    {{viewData.cancerRegistryNotes}}
                </div>
            </div>
        </div>
    </nhr-record-widget>
    <undo-actions (undo)="undoClick()"></undo-actions>
</div>