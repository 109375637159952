<form [formGroup]="form" novalidate autocomplete="off">
    <div class="row" *ngIf="filters">
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <label class="form-label fw-bold mt-0" for="category">Kategorija</label>
                <div class="w-100">
                    <select id="category" [formControlName]="'category'" class="form-select">
                        <option *ngFor="let option of filters.categories" value={{option.id}}>{{option.title}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <label class="form-label fw-bold mt-3 mt-sm-0" for="category">Būsena</label>
                <div class="w-100">
                    <select id="status" [formControlName]="'status'" class="form-select">
                        <option *ngFor="let option of filters.consentStatuses"
                                value={{option.id}}>{{DocRefConsentDocStatusTranslationsEnum[option.status] | translate}}</option>
                    </select>
                </div>
            </div>
            <div class="text-end">
                <button class="btn btn-primary" (click)="onSearchClick($event)">
                    {{"Ieškoti" | translate}}
                </button>
            </div>
        </div>
    </div>
</form>
<!-- TODO-design: fix onSearchClick event trigger -->
<!-- <search-modal [form]="form" (onSearch)="onSearchClick($event)">
</search-modal> -->