import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Entities extends ConfigService {
    path = this.apiUrl + '/entities';

    get(classCode: string, searchCriteria?: any, typeCode?: any, byCode?: any, valid?: any): Observable<any> {
        let params = new HttpParams();

        params = params.append('searchCriteria', searchCriteria ? searchCriteria : '');
        params = params.append('typeCode', typeCode ? typeCode : '');
        params = params.append('byCode', byCode ? byCode : '');
        params = params.append('valid', valid ? valid : '');
        const url = `${this.path}/${classCode}/list`;
        return this.http.get<any>(url, {params});
    }
}