angular.module('medvais.documents').directive('medvaisUnisignCallback', function () {

    return {
        restrict: 'E',
        scope: {options: '='},
        templateUrl: 'modules/medvais/documents/signing/medvais-unisign-callback.html',
        controller: 'medvaisUnisignCallbackCtrl'
    };

});

angular.module('medvais.documents').controller('medvaisUnisignCallbackCtrl', ["$scope", "MedvaisDocumentApiFactory", "MessageService", "$log", "$filter", "$location", "$window", function ($scope, 
        MedvaisDocumentApiFactory, 
        MessageService,
        $log,
        $filter,
        $location,
        $window) {
    
    MedvaisDocumentApiFactory.confirmSigning({id: $scope.options.documentId}).$promise.then (
        function (resp) {
            $log.debug('Signing status', resp);
            var url = $scope.options.getImagingStudyListUrl();
            
//            if (resp.res === true) {
//                MessageService.success($filter('translate')('medvais.doc.sign.success.msg'));
//            } else {
//                MessageService.error($filter('translate')('medvais.doc.sign.fail.msg') + (resp.msgid ? ' [' + resp.msgid + ']' : ''));
//            }
            
            url += '?sgnst=' + (resp.status === 'ok' ? 'ok' : 'fail');
            if (resp.msgid) {
                url += "&msgid=" + resp.msgid;
            }
            $window.parent.location = url;
        }
    );
    
}]);