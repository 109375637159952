<h6>{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.subTitle' | translate}} {{fruit | uppercase}}</h6>
<div class="row">
    <app-input
        labelText="doc.pregnancyDashboard.e096Part.formRecord.heartRate"
        type="number"
        class="col-6 col-lg-3"
        labelClass="col-form-label"
        units="k/min"
        [fControl]="formGroup.get('fetalHeartRate')"
    ></app-input>
    <app-select
        class="col-6 col-lg-3"
        labelText="doc.pregnancyDashboard.e096Part.formRecord.cardiotocogramMethod"
        [id]="'part-record-cardiotocogramMethod'"
        [fControl]="formGroup.get('cardiotocogramMethod')"
        [values]="selectionValuesFromEntity(cardiotocogramMethodOptions)"
    ></app-select>
    <app-select
        class="col-6 col-lg-3"
        labelText="doc.pregnancyDashboard.e096Part.formRecord.cardiotocogramEvaluation"
        [id]="'part-record-cardiotocogramEvaluation'"
        [fControl]="formGroup.get('cardiotocogramEvaluation')"
        [values]="selectionValuesFromEntity(answersVariants?.['cardiotocogramResults'])"
    ></app-select>
    <app-select
        class="col-6 col-lg-3"
        labelText="doc.pregnancyDashboard.e096N.formNewborn.amnioticFluidColor"
        [id]="'part-record-colorAmnioticFluid'"
        [fControl]="formGroup.get('colorAmnioticFluid')"
        [values]="selectionValuesFromEntity(answersVariants?.['fetalFluidColor'])"
    ></app-select>
    <app-select
        class="col-6 col-lg-3"
        labelText="doc.pregnancyDashboard.e096Part.formNewborn.firstPart"
        [id]="'part-record-firstPart'"
        [fControl]="formGroup.get('firstPart')"
        [values]="selectionValuesFromEntity(answersVariants?.['fetalPrimordial'])"
    ></app-select>
    <app-select
        class="col-6 col-lg-3"
        labelText="doc.pregnancyDashboard.e096Part.formRecord.firstPartLevel"
        [id]="'part-record-levelOfThefirstPart'"
        [fControl]="formGroup.get('levelOfThefirstPart')"
        [values]="selectionValuesFromEntity(levelOfThefirstPartOptions)"
    ></app-select>
</div>