import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {QueryModel} from "../shared/models/query.model";
import {EnvironmentSettings} from "../../environments/env";

@Injectable()
export class ConfigService {

    apiUrl: string;
    portal: string;

    constructor(protected http: HttpClient) {
        this.apiUrl = EnvironmentSettings.getEnvironment().apiUrl;
        this.portal = EnvironmentSettings.getPortalType().toString();
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    protected getFilterParams(query: QueryModel): HttpParams {
        let httpParams = new HttpParams();

        if (query) {
            for (const key in query) {
                if (query.hasOwnProperty(key) && query[key] !== null) {
                    httpParams = httpParams.set(key, query[key]);
                }
            }
        }

        return httpParams;
    }
}