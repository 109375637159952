import {Component, Inject} from "@angular/core";
import {UpgradeModule} from "@angular/upgrade/static";
@Component({
    selector: 'page-not-found',
    templateUrl: './404.html'
})
export class PageNotFoundComponent {

    constructor() {
    }
}