<modal
        [modalId]="modalId"
        [modalCloseId]="modalCloseId"
        [modalSize]="modalSize"
        [title]="title"
        [hasFooterButtons]="hasFooterButtons"
>
    <ng-container>
        <div class="d-flex justify-content-between align-items-center p-2">
            <div class="form-group d-inline-flex flex-fill m-2">
                <label for="phraseSearchInput">{{'Bendra paieška' | translate}}</label>
                <input
                    id="phraseSearchInput"
                    type="text"
                    class="form-control"
                    [ngClass]="searchPhraseClasses"
                    placeholder="Įveskite frazę"
                    [(ngModel)]="searchPhrase"
                    (keyup.enter)="searchByString($event)"
                    (ngModelChange)="validateSearhPhraseInput()"
                    [tooltip]="searchPhraseTooltip | translate"
                    placement="bottom"
                    container="body"
                >
            </div>
            <div class="form-check flex-fill m-2">
                <input id="limitCheckbox" class="form-check-input" type="checkbox" value="">
                <label for="limitCheckbox"
                       class="form-check-label">{{restrictCheckboxTitle | translate}}</label>
            </div>
            <div class="me-auto">
                <button class="btn btn-outline-danger" (click)="clearAllFilters($event)">Valyti filtrus</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ag-grid-angular
                        style="width: 100%; height: 500px"
                        class="ag-theme-main-grid"
                        [columnDefs]="colDefs"
                        [defaultColDef]="defaultColDef"
                        [gridOptions]="gridOptions"
                        [rowSelection]="'single'"
                        [rowModelType]="'serverSide'"
                        [serverSideDatasource]="datasource"
                        [pagination]="true"
                        [paginationPageSize]="paginationPageSize"
                        [suppressPaginationPanel]="true"
                        (gridReady)="onGridReady($event)"
                        [context]="context"
                />
            </div>
            <div class="col-12">
                <ag-pagination-bar></ag-pagination-bar>
            </div>
        </div>
    </ng-container>
</modal>