import { CommonModule, DatePipe } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SharedLibrary } from '../../../../shared/shared.module';
import { CapitalizePipe } from '../../../../shared/pipes/capitalize.pipe';
import { DowngradeAdapter } from '../../../../../utils/hypridHelper';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { E097Component } from './e097.component';
import './e097.routes';
import { E097AtPageComponent } from './e097-at/e097-at-page/e097-at-page.component';
import { E097AtFormComponent } from './e097-at/e097-at-form/e097-at-form.component';
import {
    E097AtVerificationComponent
} from './e097-at/e097-at-form/e097-at-sub-forms/e097-at-verification/e097-at-verification.component';
import { E097GdPageComponent } from './e097-gd/e097-gd-page/e097-gd-page.component';
import { E097GdFormComponent } from './e097-gd/e097-gd-form/e097-gd-form.component';
import {
    E097GdGeneralInspectionComponent
} from './e097-gd/e097-gd-form/e097-gd-sub-forms/e097-gd-general-inspection/e097-gd-general-inspection.component';
import {
    E097GdInspectionsComponent
} from './e097-gd/e097-gd-form/e097-gd-sub-forms/e097-gd-inspections/e097-gd-inspections.component';
import {
    E097GdResearchComponent
} from './e097-gd/e097-gd-form/e097-gd-sub-forms/e097-gd-research/e097-gd-research.component';
import {
    E097GdAppointmentsComponent
} from './e097-gd/e097-gd-form/e097-gd-sub-forms/e097-gd-appointments/e097-gd-appointments.component';
import {
    E097GdDiagnosisComponent
} from './e097-gd/e097-gd-form/e097-gd-sub-forms/e097-gd-diagnosis/e097-gd-diagnosis.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        E097Component,
        E097AtPageComponent,
        E097AtFormComponent,
        E097AtVerificationComponent,
        E097GdPageComponent,
        E097GdFormComponent,
        E097GdGeneralInspectionComponent,
        E097GdInspectionsComponent,
        E097GdResearchComponent,
        E097GdAppointmentsComponent,
        E097GdDiagnosisComponent
    ],
    providers: [CapitalizePipe, DatePipe],
    imports: [
        CommonModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        TimepickerModule,
        TypeaheadModule,
    ],
})
export class E097Module {}

new DowngradeAdapter(ng1App)
    .downgradeComponent(E097Component)
    .downgradeComponent(E097AtPageComponent)
    .downgradeComponent(E097GdPageComponent);
