import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { EntityModel } from "../../models/entity.model";

// formControlName non required for date-range
export interface FilterOptionsInterface {
    title: string;
    formControlName?: string;
    typeaheadformControl?: FormControl;
    type: "date-range" | "multi-select" | "input" | "date" | "typeahead";
    format?: "text" | "number";
    placeholder?: string;
    multiSelectOptions?: EntityModel[];
    multiSelectSettings?: IDropdownSettings;
    typeaheadSettings?: any;
}
@Component({
    selector: "app-page-filters",
    templateUrl: "./page-filters.component.html",
})
export class PageFiltersComponent {
    @Input() set options(value: FilterOptionsInterface[]) {
        this._options = value;
        this.createForm();
    }
    @Output() filtering: EventEmitter<{ [key: string]: any }> = new EventEmitter<{ [key: string]: any }>();

    values: { [key: string]: EntityModel[] };
    get options(): FilterOptionsInterface[] {
        return this._options;
    }

    formGroup: FormGroup = this.fb.group({});
    private _options: FilterOptionsInterface[];

    constructor(private readonly fb: FormBuilder) {}

    clearFilters(): void {
        this.formGroup.reset();
        this.options.forEach((option) => {
            if (option.type === "typeahead") {
                option.typeaheadformControl?.reset();
            }
        });
        this.filtering.emit(this.formGroup.value);
    }

    applyFilters(): void {
        this.filtering.emit(this.formGroup.value);
    }

    private createForm() {
        this.options.forEach((option) => {
            if (option.type === "date-range") {
                this.formGroup.addControl("fromDate", this.fb.control(null));
                this.formGroup.addControl("toDate", this.fb.control(null));
            } else {
                this.formGroup.addControl(option.formControlName, this.fb.control(null));
            }
        });
    }

    getFormControl(formControlName: string): FormControl {
        return this.formGroup.get(formControlName) as FormControl;
    }

    typeaheadOnSelect(e: TypeaheadMatch, controlName: string) {
        if (e) {
            this.formGroup.controls[controlName].setValue(e.item);
        } else {
            this.formGroup.controls[controlName].setValue(null);
        }
    }
    onBlurTypeahead(event: TypeaheadMatch, controlName: string, typeaheadformControl: FormControl) {
        if (!event || event.value !== typeaheadformControl.value) {
            this.formGroup.controls[controlName].setValue(null);
            typeaheadformControl.setValue(null);
        }
    }

    typeaheadNoResults(event: TypeaheadMatch, controlName: string, typeaheadformControl?: FormControl) {}
}
