<div class="modal-header">
    <h4 class="modal-title pull-left">{{'doc.dia.mod.upl' | translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ag-grid-angular
        style="width: 100%; height: 500px"
        class="ag-theme-main-grid ag-grid-select-filter-white-important"
        [columnDefs]="colDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="filteredDiagnoses"
        [rowSelection]="'multiple'"
        [animateRows]="false"
        [suppressRowClickSelection]="true"
    />
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="close(true)"><span class="fa fa-plus"></span> {{'doc.dia.btn.add' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="close()"><span class="fa fa-times"></span> {{'doc.dia.btn.can' | translate}}</button>
</div>
