import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, map, of, switchMap } from "rxjs";
import { ApiV2AccountSettings } from "src/api/api-v2-account-settings";
import { ApiV2Documents } from "src/api/api-v2-documents";
import { ApiV2Entities } from "src/api/api-v2-entities";
import {DpSessionService} from "../../../../doctor/services/dp-session.service";
import { RouteParams } from "src/app/ng1.routeParams";
import { EntityModel } from "src/app/shared/models/entity.model";
import { DirectiveReset } from "src/app/shared/services/directive-reset";

@Component({
    selector: 'app-undergone-surgical-procedures',
    templateUrl: './undergone_surgical_procedures.component.html'
})
export class UndergoneSurgicalProceduresCreateComponent implements OnInit {

    suggestionsOrg$?: any;
    noResult = false;
    form: FormGroup;
    currentDate = new Date();
    entitiesArray: EntityModel[];
    typeCode = null;
    byCode = null;
    valid = null;
    @Output() valueChange = new EventEmitter();
    @Input() viewOnly: boolean = false;
    editingIndex: number = null; // Variable to track the index being edited

    @Input() set preloadedProcedures(value: any[]) {
        if (value && value.length > 0) {
            const proceduresFormArray = this.form.get('procedures') as FormArray;
            proceduresFormArray.clear();
            value.forEach(procedure => {
                const procedureFormGroup = this.formBuilder.group({
                    date: [procedure.date],
                    time: [procedure.time],
                    procedure: [procedure.procedure],
                    description: [procedure.description]
                });
                proceduresFormArray.push(procedureFormGroup);
            });
        }
    }

    constructor(
        private params: RouteParams,
        private formBuilder: FormBuilder,
        private apiV2Documents: ApiV2Documents,
        private apiV2AccountSettings: ApiV2AccountSettings,
        private ApiV2Entities: ApiV2Entities,
        private dpSessionContextService: DpSessionService,
        private directiveReset: DirectiveReset
    ) {
        this.form = this.formBuilder.group({
            date: [],
            time: [],
            procedure: [],
            description: [],
            procedures: this.formBuilder.array([])
        });
    }

    ngOnInit(): void {
        //   this.getPrefillData();
        //   this.hideMsgAboutQualification = true;
        //   this.getActiveQualification();
        this.getAchiList();
        this.directiveReset.reset$.subscribe(() => {
            this.resetForm();
        });
    }

    getAchiList() {
        this.suggestionsOrg$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get('procedure').value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.getAchi(query).pipe(
                        map((newDisplay: EntityModel[]) => {
                            return newDisplay.map(org => ({
                                ...org,
                                nameAndCode: org.code + ' ' + org.name
                            }));
                        })
                    );
                }
                return of([]);
            })
        );
    }

    getAchi(query): Observable<EntityModel[]> {
        return this.ApiV2Entities.get('achi', query, this.typeCode, this.byCode, this.valid).pipe(map((achi: EntityModel[]) => this.entitiesArray = achi));
    }

    // get proceduresArray(): FormArray {
    //     return this.form.get('procedures') as FormArray;
    //   }

    //   addProcedure() {
    //     const newProcedureForm = this.formBuilder.group({
    //         date: [''],
    //         time: [''],
    //         procedure: [''],
    //         description: ['']
    //     });

    //     this.proceduresArray.push(newProcedureForm);
    // }

    // removeProcedure(index: number) {
    //     this.proceduresArray.removeAt(index);
    // }

    addOrEditProcedure() {
        const date = this.form.get('date').value;
        const time = this.form.get('time').value;
        const procedure = this.form.get('procedure').value;
        const description = this.form.get('description').value;

        if (!date || !time || !procedure) {
            return;
        }

        if (this.editingIndex !== null) {
            // Editing an existing procedure
            const procedureForm = (this.form.get('procedures') as FormArray).at(this.editingIndex);
            procedureForm.patchValue({
                date,
                time,
                procedure,
                description,
            });
            this.editingIndex = null; // Reset editing state
        } else {
            // Adding a new procedure
            const newProcedureForm = this.formBuilder.group({
                date,
                time,
                procedure,
                description,
            });
            (this.form.get('procedures') as FormArray).push(newProcedureForm);
        }

        this.form.patchValue({
            date: null,
            time: null,
            procedure: null,
            description: null,
        });

        this.emitProcedureValues();
    }

    editProcedure(index: number) {
        const procedureForm = (this.form.get('procedures') as FormArray).at(index);
        this.form.patchValue({
            date: procedureForm.get('date').value,
            time: procedureForm.get('time').value,
            procedure: procedureForm.get('procedure').value,
            description: procedureForm.get('description').value,
        });
        this.editingIndex = index; // Set editing state

        this.emitProcedureValues();
    }

    removeProcedure(index: number) {
        (this.form.get('procedures') as FormArray).removeAt(index);
        this.emitProcedureValues();
    }

    getProcedureFormControls(): AbstractControl[] {
        const controls = (<FormArray>this.form.get('procedures')).controls;

        // Extract the values from controls and pass them to dataCollected
        const procedureValues = controls.map(control => control.value);
        this.dataCollected(procedureValues);

        return controls;
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    // retrieves selected organization object and get its id
    typeaheadOnSelect(e: TypeaheadMatch): void {
        //this.form.controls['recipient'].setValue(e.item.id);
    }

    private emitProcedureValues(): void {
        const procedureValues = (this.form.get('procedures') as FormArray).value;
        this.dataCollected(procedureValues);
    }

    dataCollected(response) {
        this.valueChange.emit(response);
    }

    resetForm(): void {
        const procedureValues = (<FormArray>this.form.get('procedures')).controls = [];
        this.dataCollected(procedureValues);
    }

}