import { CommonModule, DatePipe } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SharedLibrary } from '../../../../shared/shared.module';
import { CapitalizePipe } from '../../../../shared/pipes/capitalize.pipe';
import { DowngradeAdapter } from '../../../../../utils/hypridHelper';
import {
    E096PtklAssessmentWomanConditionComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-assessment-woman-condition/e096-ptkl-assessment-woman-condition.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { E096NtPageComponent } from './e096-nt/e096-nt-page/e096-nt-page.component';
import { E096NtFormComponent } from './e096-nt/e096-nt-form/e096-nt-form.component';
import {
    E096NtConditionDuringHospitalizationComponent
} from './e096-nt/e096-nt-form/e096-nt-sub-forms/e096-nt-condition-during-hospitalization/e096-nt-condition-during-hospitalization.component';
import {
    E096NtAssessmentOfFetalConditionComponent
} from './e096-nt/e096-nt-form/e096-nt-sub-forms/e096-nt-assessment-of-fetal-condition/e096-nt-assessment-of-fetal-condition.component';
import {
    E096NtExaminationPregnantWomanComponent
} from './e096-nt/e096-nt-form/e096-nt-sub-forms/e096-nt-examination-pregnant-woman/e096-nt-examination-pregnant-woman.component';
import {
    E096NtResearchPlannedComponent
} from './e096-nt/e096-nt-form/e096-nt-sub-forms/e096-nt-research-planned/e096-nt-research-planned.component';
import {
    E096NtAppointmentsComponent
} from './e096-nt/e096-nt-form/e096-nt-sub-forms/e096-nt-appointments/e096-nt-appointments.component';
import {
    E096NtMaternityCarePlanComponent
} from './e096-nt/e096-nt-form/e096-nt-sub-forms/e096-nt-maternity-care-plan/e096-nt-maternity-care-plan.component';
import { E096Component } from './e096.component';
import { E096PtklPageComponent } from './e096-ptkl/e096-ptkl-page/e096-ptkl-page.component';
import { E096PtklFormComponent } from './e096-ptkl/e096-ptkl-form/e096-ptkl-form.component';
import {
    E096PtklControlSheetComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-control-sheet/e096-ptkl-control-sheet.component';
import {
    E096PtklFetalShoulderImpingementComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-fetal-shoulder-impingement/e096-ptkl-fetal-shoulder-impingement.component';
import {
    E096PtklObstetricForcepsUseComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-obstetric-forceps-use/e096-ptkl-obstetric-forceps-use.component';
import {
    E096PtklAssistingWithPostpartumHemorrhageComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-assisting-with-postpartum-hemorrhage/e096-ptkl-assisting-with-postpartum-hemorrhage.component';
import {
    E096PtklPostpartumHemorrhageStartComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-postpartum-hemorrhage-start/e096-ptkl-postpartum-hemorrhage-start.component';
import {
    E096PtklActionsTakenComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-actions-taken/e096-ptkl-actions-taken.component';
import {
    E096PtklEndAssistanceComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-end-assistance/e096-ptkl-end-assistance.component';
import {
    E096PtklVacuumExtractionComponent
} from './e096-ptkl/e096-ptkl-form/e096-ptkl-subforms/e096-ptkl-vacuum-extraction/e096-ptkl-vacuum-extraction.component';
import "./e096.routes";
import { E096NPageComponent } from './e096-n/e096-n-page/e096-n-page.component';
import { E096NFormComponent } from './e096-n/e096-n-form/e096-n-form.component';
import {
    E096NGeneralDataComponent
} from './e096-n/e096-n-form/e096-n-sub-forms/e096-n-general-data/e096-n-general-data.component';
import { E096NNewbornComponent } from './e096-n/e096-n-form/e096-n-sub-forms/e096-n-newborn/e096-n-newborn.component';
import { E096GePageComponent } from './e096-ge/e096-ge-page/e096-ge-page.component';
import { E096GeFormComponent } from './e096-ge/e096-ge-form/e096-ge-form.component';
import {
    E096GeLaborProgressComponent
} from './e096-ge/e096-ge-form/e096-ge-sub-forms/e096-ge-labor-progress/e096-ge-labor-progress.component';
import {
    E096GeAdministrationAntibioticsComponent
} from './e096-ge/e096-ge-form/e096-ge-sub-forms/e096-ge-administration-antibiotics/e096-ge-administration-antibiotics.component';
import { E096NaPageComponent } from './e096-na/e096-na-page/e096-na-page.component';
import { E096NaFormComponent } from './e096-na/e096-na-form/e096-na-form.component';
import { E096CpoPageComponent } from './e096-cpo/e096-cpo-page/e096-cpo-page.component';
import { E096CpoFormComponent } from './e096-cpo/e096-cpo-form/e096-cpo-form.component';
import {
    E096CpoGeneralInformationComponent
} from './e096-cpo/e096-cpo-form/e096-cpo-sub-forms/e096-ge-general-information/e096-cpo-general-information.component';
import {
    E096CpoCaesareanProcedureComponent
} from './e096-cpo/e096-cpo-form/e096-cpo-sub-forms/e096-cpo-caesarean-procedure/e096-cpo-caesarean-procedure.component';
import {
    E096CpoCaesareanParticipantsComponent
} from './e096-cpo/e096-cpo-form/e096-cpo-sub-forms/e096-cpo-caesarean-participants/e096-cpo-caesarean-participants.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { E096PartPageComponent } from './e096-part/e096-part-page/e096-part-page.component';
import { E096PartFormComponent } from './e096-part/e096-part-form/e096-part-form.component';
import {
    E096PartPartogramComponent
} from './e096-part/e096-part-form/e096-part-sub-forms/e096-part-partogram/e096-part-partogram.component';
import {
    E096PartNewbornComponent
} from './e096-part/e096-part-form/e096-part-sub-forms/e096-part-newborn/e096-part-newborn.component';
import {
    E096PartEntriesComponent
} from './e096-part/e096-part-form/e096-part-sub-forms/e096-part-entries/e096-part-entries.component';
import {
    E096PartEntryFormComponent
} from './e096-part/e096-part-form/e096-part-sub-forms/e096-part-entry-form/e096-part-entry-form.component';
import {
    E096PartRecordComponent
} from './e096-part/e096-part-form/e096-part-sub-forms/e096-part-record/e096-part-record.component';
import { E096PartTableComponent } from './e096-part/e096-part-table/e096-part-table.component';
import {
    E096PartTableCervixComponent
} from './e096-part/e096-part-table/e096-part-table-cervix/e096-part-table-cervix.component';
import {
    E096PartTableFightsComponent
} from './e096-part/e096-part-table/e096-part-table-fights/e096-part-table-fights.component';
import {
    E096PartTableActiveComponent
} from './e096-part/e096-part-table/e096-part-table-active/e096-part-table-active.component';
import {
    E096PartTableLatentComponent
} from './e096-part/e096-part-table/e096-part-table-latent/e096-part-table-latent.component';
import { LineConnectorDirective } from './e096-part/e096-part-table/line-connector.directive';
import {
    E096PartTableModalComponent
} from './e096-part/e096-part-table/e096-part-table-modal/e096-part-table-modal.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        E096NtPageComponent,
        E096NtFormComponent,
        E096NtConditionDuringHospitalizationComponent,
        E096NtAssessmentOfFetalConditionComponent,
        E096NtExaminationPregnantWomanComponent,
        E096NtResearchPlannedComponent,
        E096NtAppointmentsComponent,
        E096NtMaternityCarePlanComponent,
        E096Component,
        E096PtklPageComponent,
        E096PtklFormComponent,
        E096PtklControlSheetComponent,
        E096PtklFetalShoulderImpingementComponent,
        E096PtklObstetricForcepsUseComponent,
        E096PtklAssistingWithPostpartumHemorrhageComponent,
        E096PtklPostpartumHemorrhageStartComponent,
        E096PtklAssessmentWomanConditionComponent,
        E096PtklActionsTakenComponent,
        E096PtklEndAssistanceComponent,
        E096PtklVacuumExtractionComponent,
        E096NPageComponent,
        E096NFormComponent,
        E096NGeneralDataComponent,
        E096NNewbornComponent,
        E096GePageComponent,
        E096GeFormComponent,
        E096GeLaborProgressComponent,
        E096GeAdministrationAntibioticsComponent,
        E096NaPageComponent,
        E096NaFormComponent,
        E096CpoPageComponent,
        E096CpoFormComponent,
        E096CpoGeneralInformationComponent,
        E096CpoCaesareanProcedureComponent,
        E096CpoCaesareanParticipantsComponent,
        E096PartPageComponent,
        E096PartFormComponent,
        E096PartPartogramComponent,
        E096PartNewbornComponent,
        E096PartEntriesComponent,
        E096PartEntryFormComponent,
        E096PartRecordComponent,
        E096PartTableComponent,
        E096PartTableCervixComponent,
        E096PartTableFightsComponent,
        E096PartTableActiveComponent,
        E096PartTableLatentComponent,
        LineConnectorDirective,
        E096PartTableModalComponent
    ],
    providers: [CapitalizePipe, DatePipe],
    imports: [
        CommonModule,
        UpgradeModule,
        HttpClientModule,
        SharedLibrary,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        TimepickerModule,
        TypeaheadModule,
    ],
})
export class E096Module {}

new DowngradeAdapter(ng1App)
    .downgradeComponent(E096Component)
    .downgradeComponent(E096NtPageComponent)
    .downgradeComponent(E096PtklPageComponent)
    .downgradeComponent(E096NPageComponent)
    .downgradeComponent(E096GePageComponent)
    .downgradeComponent(E096NaPageComponent)
    .downgradeComponent(E096CpoPageComponent)
    .downgradeComponent(E096PartPageComponent)

    .downgradeComponent(E096PartTableComponent);
