import { AfterViewInit, Component, DestroyRef, Input, ViewChild } from "@angular/core";
import { formatDateForDatePickers, getControlValue } from '../../../ngn/components/e096/e096-utils/utils';
import { delay, take } from 'rxjs';
import {
    InrDescriptionVaccineSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-description-vaccine-subform/inr-description-vaccine-subform.component';
import {
    InrNotificationTypeSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-notification-type-subform/inr-notification-type-subform.component';
import {
    InrNotificationFormSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-notification-form-subform/inr-notification-form-subform.component';
import {
    InrReportingPersonInfoSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-reporting-person-info-subform/inr-reporting-person-info-subform.component';
import {
    InrWhomPersonInfoSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-whom-person-info-subform/inr-whom-person-info-subform.component';
import {
    InrDrugsCausedInrSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-drugs-caused-inr-subform/inr-drugs-caused-inr-subform.component';
import {
    InrVaccineCausedInrSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-vaccine-caused-inr-subform/inr-vaccine-caused-inr-subform.component';
import {
    InrDescriptionDrugsSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-description-drugs-subform/inr-description-drugs-subform.component';
import {
    InrOtherUsedMedicalProductsSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-other-used-medical-products-subform/inr-other-used-medical-products-subform.component';
import {
    InrAdditionalInfoSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-additional-info-subform/inr-additional-info-subform.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    InrDoctorInformingSubformComponent
} from '../../../../shared/components/inr-sub-forms/inr-doctor-informing-subform/inr-doctor-informing-subform.component';
import { InrModel } from '../../../../shared/models/inr.model';

@Component({
    selector: "app-inr-form",
    templateUrl: "./inr-form.component.html",
})
export class InrFormComponent implements AfterViewInit {
    @Input() editable: boolean = false;
    @Input() set initialData(value: InrModel) {
        if (!value) {
            return;
        }
        this.patient = !!value.doctorWasInformed;
        this.updateInitialData(value);
    };

    @ViewChild(InrNotificationTypeSubformComponent) notificationType: InrNotificationTypeSubformComponent;
    @ViewChild(InrNotificationFormSubformComponent) notificationForm: InrNotificationFormSubformComponent;
    @ViewChild(InrReportingPersonInfoSubformComponent) reportingPerson: InrReportingPersonInfoSubformComponent;
    @ViewChild(InrWhomPersonInfoSubformComponent) patientPerson: InrWhomPersonInfoSubformComponent;
    @ViewChild(InrDrugsCausedInrSubformComponent) drugs: InrDrugsCausedInrSubformComponent;
    @ViewChild(InrVaccineCausedInrSubformComponent) vaccine: InrVaccineCausedInrSubformComponent;
    @ViewChild(InrDescriptionDrugsSubformComponent) descriptionDrugs: InrDescriptionDrugsSubformComponent;
    @ViewChild(InrDescriptionVaccineSubformComponent) descriptionVaccine: InrDescriptionVaccineSubformComponent;
    @ViewChild(InrOtherUsedMedicalProductsSubformComponent) otherMedicalProductsUsed: InrOtherUsedMedicalProductsSubformComponent;
    @ViewChild(InrAdditionalInfoSubformComponent) additionalInfo: InrAdditionalInfoSubformComponent;
    @ViewChild(InrDoctorInformingSubformComponent) doctorWasInformed: InrDoctorInformingSubformComponent;

    formGroup: FormGroup;
    dateFields: string[] = ['startedUsingDate', 'endUsingDate', 'vaccinationDate', 'startYear', 'endYear'];
    patient: boolean;
    controlValue = getControlValue.bind(this) as typeof getControlValue;

    constructor(private fb: FormBuilder, private destroyRef: DestroyRef) {
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            notificationType: this.notificationType.formGroup,
            notificationForm: this.notificationForm.formGroup,
            reportingPerson: this.reportingPerson.formGroup,
            patientPerson: this.patientPerson.formGroup,
            otherMedicalProductsUsed: this.otherMedicalProductsUsed.formGroup,
            additionalInfo: this.additionalInfo.formGroup,
        });

        this.formGroup.get('notificationForm').valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            delay(50)
        )
            .subscribe(res => {
                if (res === 'Pranešimas dėl vaistinio preparato') {
                    this.formGroup.removeControl('vaccine');
                    this.formGroup.removeControl('descriptionVaccine');
                    this.formGroup.addControl('drugs', this.drugs?.formGroup);
                    this.formGroup.addControl('descriptionDrugs', this.descriptionDrugs?.formGroup);
                    this.formGroup.updateValueAndValidity();
                } else if (res === 'Pranešimas dėl vakcinos') {
                    this.formGroup.removeControl('drugs');
                    this.formGroup.removeControl('descriptionDrugs');
                    this.formGroup.addControl('vaccine', this.vaccine?.formGroup);
                    this.formGroup.addControl('descriptionVaccine', this.descriptionVaccine?.formGroup);
                    this.formGroup.updateValueAndValidity();
                }
            });
    }

    log() {
        console.log(this.formGroup);
    }

    private updateInitialData(value: any): void {
        if (!value || !this.formGroup) {
            return;
        }

        const initialData = formatDateForDatePickers(value, this.dateFields);
        this.formGroup.valueChanges.pipe(
            take(1),
            delay(100)
        )
            .subscribe(() => {
                if (this.patient) {
                    this.formGroup.removeControl('notificationType');
                    this.formGroup.addControl('doctorWasInformed', this.doctorWasInformed?.formGroup);
                }
                this.formGroup.patchValue(initialData);
                this.formGroup.disable();
            })
        this.formGroup.patchValue(initialData);
    }
}
