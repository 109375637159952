angular.module('medvais.documents').directive('medvaisEditDoc027va', function () {

    return {
        restrict: 'E',
        scope: {options: '='},
        templateUrl: 'modules/medvais/documents/edit-027va.html',
        controller: 'medvaisEditDoc027vaCtrl'
    };

});


angular.module('medvais.documents').controller('medvaisEditDoc027vaCtrl', ["$scope", "$q", "$routeParams", "$modal", "$location", "$window", "$filter", "$timeout", "$log", "$anchorScroll", "MedvaisImagingStudyApiFactory", "MedvaisPractitionerApiFactory", "MedvaisOrganizationApiFactory", "MedvaisClassifierApiFactory", "MedvaisPatientApiFactory", "MedvaisDocumentApiFactory", "MedvaisEncounterApiFactory", "MedvaisMedvaisApiFactory", "$medvaisOrderTelemedicine", "$medvaisPatientIdentification", "MessageService", "MedvaisOrderApiFactory", "MedvaisDocStateService", "FormLoaderService", "MedvaisMediaApiFactory", "MedvaisMediaFileUploader", "MedvaisSyncMediaFileUploader", "MedvaisValidationService", "$medvaisAddRelatedStudy", "MedvaisDiagnosesService", "MedvaisACHIService", "$medvaisCancelDocMdl", "Medvais027vaFormService", function (
        $scope, $q,
        $routeParams,
        $modal,
        $location,
        $window,
        $filter,
        $timeout,
        $log,
        $anchorScroll,
        MedvaisImagingStudyApiFactory,
        MedvaisPractitionerApiFactory,
        MedvaisOrganizationApiFactory,
        MedvaisClassifierApiFactory,
        MedvaisPatientApiFactory,
        MedvaisDocumentApiFactory,
        MedvaisEncounterApiFactory,
        MedvaisMedvaisApiFactory,
        $medvaisOrderTelemedicine,
        $medvaisPatientIdentification,
        MessageService,
        MedvaisOrderApiFactory,
        MedvaisDocStateService,
        FormLoaderService,
        MedvaisMediaApiFactory,
        MedvaisMediaFileUploader,
        MedvaisSyncMediaFileUploader,
        MedvaisValidationService,
        $medvaisAddRelatedStudy,
        MedvaisDiagnosesService,
        MedvaisACHIService,
        $medvaisCancelDocMdl,
        Medvais027vaFormService) {


    MessageService.clearAll();
    $anchorScroll();

    var practitionerId;
    var practitionerRef;
    var organizationId;
    var organizationRef;
    var organizationJAR;
    var token;
    var clientIP;
    $scope.documentId = $routeParams.documentId;
    $scope.imagingStudyId = $routeParams.imagingStudyId;
    $scope.relatedImagingStudies = [];
    $scope.documentData = {};
    $scope.doseUnitsList = [];
    $scope.encountersList = [];
    $scope.ordersList = [];
    $scope.meddreamUrl = '';
    $scope.diagMainParams = {selectedDiagnosis: {}};
    $scope.achiParams = {selectedACHI: {}};
    $scope.docMainInfo = {patient: null, practitioner: null, organization: null, practitionerQualification: null};
    $scope.telemedicineInfo = {};
    $scope.docMenu = {};

    var refs = new Map();


    $q.all([$scope.options.getPractitioner(), $scope.options.getOrganization(), $scope.options.getUserInfo()]).then(function (result) {
        var pract = result[0];
        var org = result[1];
        var user = result[2];

        practitionerId = pract.id;
        practitionerRef = pract.fullId;
        organizationJAR = org.jarCode;
        organizationRef = org.fullId;
        organizationId = org.id;
        token = user.token;
        clientIP = user.clientIP;

        console.log("Edit form. practitionerId: " + practitionerId);
        console.log("Edit form. practitioner ref: " + practitionerRef);
        console.log("Edit form. organizationId: " + organizationId);
        console.log("Edit form. organization ref: " + organizationRef);
        console.log("Edit form. organizationJAR: " + organizationJAR);
        console.log("Edit form. token: " + token);
        console.log("Edit form. Client IP: " + clientIP);

        if ($scope.documentId) {
            $scope.flagNew = false;
            initEditDocData();
        } else if ($scope.imagingStudyId) {
            $scope.flagNew = true;
            initNewDocData();
        }

    });


    MedvaisMedvaisApiFactory.getMeddreamUrl().$promise.then(
            function (response) {
                $scope.meddreamUrl = response.url;
            }
    );
    MedvaisClassifierApiFactory.getDoseUnits().$promise.then(
            function (result) {
                $scope.doseUnitsList = result.items;
            }
    );


    var initNewDocData = function () {
        FormLoaderService.startFormLoading();
        var doc = {imagingStudy: {id: $scope.imagingStudyId}, relatedImagingStudies: []};

        initDocumentData();

        loadImagingStudyData(doc)
            .then(loadRelatedImagingStudiesList)
            .then(loadPractitionerData)
            .then(loadOrganizationData)
            .then(loadEncounterList)
            .then(loadOrderList)
            .then(
                function() {
                    $scope.docMainInfo.practitioner = $scope.documentData.practitioner;
                    $scope.docMainInfo.patient = $scope.documentData.imagingStudy.patient;
                    $scope.docMainInfo.organization = $scope.documentData.organization;
                    $scope.docMainInfo.practitionerQualification = $scope.documentData.practitionerQualification;

                    initMenu();
                    $log.debug('027va new form initialization completed.');
                }
            )
            .finally(function () {
                console.log('Finally part of new 027-va initialization executed.');
                FormLoaderService.endFormLoading();
            });
    };


    var initEditDocData = function () {
        console.log("Document id: " + $scope.documentId);
        FormLoaderService.startFormLoading();

        loadPractitionerData()
            .then(loadDocumentData)
            .then(loadImagingStudyData)
            .then(loadAssignedRelatedImagingStudies)
            .then(loadRelatedImagingStudiesList)
            .then(loadEncounterList)
            .then(loadOrderList)
            .then(
                function() {
                    $scope.diagMainParams.selectedDiagnosis = MedvaisDiagnosesService.getDiagnosisLabel($scope.documentData.mainDiagnosis.diagnosis);
                    $scope.achiParams.selectedACHI = MedvaisACHIService.getACHILabel($scope.documentData.achiCode);

                    $scope.docMainInfo.practitioner = $scope.documentData.metadata.practitioner;
                    $scope.docMainInfo.patient = $scope.documentData.metadata.patient;
                    $scope.docMainInfo.organization = $scope.documentData.metadata.custodian;
                    $scope.docMainInfo.practitionerQualification = $scope.documentData.practitionerQualification;

                    initMenu();
                    $log.debug('027va edit form initialization completed.');
                }
            )
            .finally(
                function () {
                    console.log('Finally part of existing 027-va initialization executed.');
                    FormLoaderService.endFormLoading();
                }
            );
    };


    var loadPractitionerData = function() {
        return MedvaisPractitionerApiFactory.getById({id: practitionerId}).$promise.then(
            function (practitioner) {
                $scope.documentData.practitioner = practitioner;
                $scope.documentData.practitionerQualification = getActiveQualification(practitioner.validQualificationList);
                $log.debug('Practitioner data loaded.', practitionerId);
            }
        );
    };

    var getActiveQualification = function(validQualificationList) {
        $log.debug('Qualification in settings: ', $scope.options.getAccountSettings().active_qualification);
        var qualification = null
        angular.forEach(validQualificationList, function (item) {
            if (item.licenseCode === $scope.options.getAccountSettings().active_qualification) {
                $log.debug('Active qualification: ', item.licenseCode, item.code, item.name);
                qualification = item;
            }
        });
        return qualification;
    };


    var loadDocumentData = function () {
        return MedvaisDocumentApiFactory.get027vaDocEditData({id: $scope.documentId}).$promise.then(
            function (doc) {
                $scope.documentData.conclusion = doc.conclusion;
                $scope.documentData.imagingStudyDescription = doc.imagingStudyDescription;
                $scope.documentData.radiation = doc.radiation;
                $scope.documentData.practitioner = doc.metadata.practitioner;
                $scope.documentData.mainDiagnosis = doc.mainDiagnosis;
                $scope.documentData.relatedDiagnoses = doc.relatedDiagnoses;
                $scope.documentData.achiCode = doc.achiCode;
                $scope.documentData.encounterRef = doc.encounterRef;
                $scope.documentData.diagnosticReportRef = doc.diagnosticReportRef;
                $scope.documentData.orderRef = doc.orderRef;
                $scope.documentData.relatedImagingStudies = [];
                $scope.documentData.mediaList = doc.mediaList;
                $scope.documentData.metadata = doc.metadata;
                $scope.documentData.telemedicineInfo = doc.telemedicineInfo;

                for (var i = 0; i < doc.relatedImagingStudies.length; i++) {
                    refs.set(doc.relatedImagingStudies[i].id, doc.relatedImagingStudies[i].fullId);
                }

                $log.debug('Document data loaded.');
                return(doc);
            },
            function (error) {
            }
        );

    };


    var initDocumentData = function() {
        $scope.documentData.radiation = {};
        $scope.documentData.relatedImagingStudies = [];
        $scope.documentData.imagingStudyDescription = '';
        $scope.documentData.conclusion = '';
        $scope.documentData.achiCode = '';
        $scope.documentData.achiDescription = '';
        $scope.documentData.mainDiagnosis = {};
        $scope.documentData.relatedDiagnoses = [];
        $scope.documentData.encounterId = 0;
        $scope.documentData.encounterRef = '';
        $scope.documentData.diagnosticReportRef = '';
        $scope.documentData.orderRef = '';
        $scope.documentData.mediaList = [];
        $scope.documentData.metadata = {status: 'new', signed: false, docTypeDataSetCode: '', docTypeTitle: ''};
        $scope.documentData.telemedicineInfo = {type: 'none', orderData: {}};

        $log.debug('Document data initialized.');
    };


    var loadImagingStudyData = function(doc) {
        return MedvaisImagingStudyApiFactory.getImagingStudyWithTelemedicineStatusById({id: doc.imagingStudy.id, organizationId: organizationId}).$promise.then(
            function (imagingStudy) {
                if (checkIfImagingStudyUpdated(imagingStudy.fullId, doc.imagingStudy.fullId) === true) {
                    MessageService.add('warning', $filter('translate')('medvais.027va.edit.imaging.study.updated.msg'));
                }

                console.log('Telemedicine type of imaging study: ' + imagingStudy.telemedicineStatus);
                console.log('Telemedicine type of document: ' + imagingStudy.telemedicineStatus);

                $scope.documentData.imagingStudy = imagingStudy;
                $scope.documentData.patientId = $scope.documentData.imagingStudy.patient.id;

                console.log('Study data loaded.');
                return(doc);
            }
        );
    };


    var loadAssignedRelatedImagingStudies = function(doc) {
        var ids = [];
        var updated = false;

        for (var i = 0; i < doc.relatedImagingStudies.length; i++) {
            ids.push(doc.relatedImagingStudies[i].id);
        }

        return MedvaisImagingStudyApiFactory.getImagingStudyByIds({ids: ids}).$promise.then(
            function (res) {
                for (var i = 0; i < res.length; i++) {
                    $log.debug('Checking study versions: ' + res[i].fullId + " " + refs.get(res[i].id));
                    updated = checkIfImagingStudyUpdated(res[i].fullId, refs.get(res[i].id));
                    if (updated === true) {
                        break;
                    }
                }

                if (updated === true) {
                    MessageService.add('warning', $filter('translate')('medvais.027va.edit.related.imaging.study.updated.msg'));
                }

                $scope.documentData.relatedImagingStudies = res;
                $log.debug('Assigned related studies data loaded.');
                return(doc);
            }
        );
    };


    var loadRelatedImagingStudiesList = function(doc) {
        if ($scope.documentData.imagingStudy.patient.id === undefined || $scope.documentData.imagingStudy.patient.id === 'patient1') {
            return;
        }

        var excludeIds = [];
        for (var i = 0; i < doc.relatedImagingStudies.length; i++) {
            excludeIds.push(doc.relatedImagingStudies[i].id);
        }

        return MedvaisImagingStudyApiFactory.getImagingStudiesOfPatient({patientId: $scope.documentData.imagingStudy.patient.id, excludeIds: excludeIds}).$promise.then(
            function (imgStudies) {
                $scope.relatedImagingStudies = imgStudies;
                $log.debug('Related study list data loaded.');
            },
            function (error) {
            }
        );

    };


    var initMenu = function () {
        $scope.docMenu = {
            main: [
                {
                    type: 'main',
                    title: 'medvais.e027va.cancel',
                    isVisible: function () {
                        return MedvaisDocStateService.canCancel($scope.documentData.metadata.status) && $scope.documentData.metadata.practitioner.id === practitionerId;
                    },
                    action: $scope.cancel
                }
            ],
            dropDown: [
                {
                    type: 'ctx',
                    title: 'medvais.e027va.cancel',
                    isVisible: function () {
                        return MedvaisDocStateService.canCancel($scope.documentData.metadata.status) && $scope.documentData.metadata.practitioner.id === practitionerId;
                    },
                    action: $scope.cancel
                }
            ]
        };
    };


    var loadEncounterList = function () {
        return MedvaisEncounterApiFactory.getByPatient({patientId: $scope.documentData.patientId, organizationId: organizationId}).$promise.then(
            function (encounters) {
                $scope.encountersList = encounters;
                $log.debug('Encounter list loaded.');
            }
        );
    };


    var loadOrderList = function () {
        return MedvaisOrderApiFactory.getForE027VA({patientId: $scope.documentData.patientId, organizationId: organizationId}).$promise.then(
            function (orders) {
                angular.forEach(orders, function (item) {
                    item.checked = ($scope.documentData.orderRef !== null && $scope.documentData.orderRef !== '' && $scope.documentData.orderRef === item.fullId) ? true : false;
                });

                $scope.ordersList = orders;
                $log.debug('Order list loaded.');
            }
        );
    };


    var loadOrganizationData = function() {
        return MedvaisOrganizationApiFactory.getById({id: organizationId}).$promise.then(
            function (organization) {
                $scope.documentData.organization = organization;
                $log.debug('Organization data loaded.');
            }
        );
    };


    $scope.getEncounterList = function (patientId, organizationId) {
        MedvaisEncounterApiFactory.getByPatient({patientId: patientId, organizationId: organizationId}).$promise.then(
                function (encounters) {
                    $scope.encountersList = encounters;
                }
        );
    };


    $scope.getOrderList = function (patientId, organizationId, selected) {
        MedvaisOrderApiFactory.getForE027VA({patientId: patientId, organizationId: organizationId}).$promise.then(
                function (orders) {
                    angular.forEach(orders, function (item) {
                        item.checked = (selected !== null && selected !== '' && selected === item.fullId) ? true : false;
                    });

                    $scope.ordersList = orders;
                }
        );
    };


    var checkIfImagingStudyUpdated = function (latestImgStudyFullId, docImgStudyFullId) {
        return $scope.flagNew === false && docImgStudyFullId !== latestImgStudyFullId;
    };


    $scope.addRelatedImagingStudy = function (index) {
        $scope.documentData.relatedImagingStudies.push($scope.relatedImagingStudies[index]);
        $scope.relatedImagingStudies.splice(index, 1);

    };

    $scope.removeRelatedImagingStudy = function (index) {
        $scope.relatedImagingStudies.push($scope.documentData.relatedImagingStudies[index]);
        $scope.documentData.relatedImagingStudies.splice(index, 1);
    };


    $scope.searchDiagnosis = function (value) {
        if (!value || value.length < 3) {
            return [];
        }
        return MedvaisClassifierApiFactory.getTlk10Codes({text: value}).$promise.then(
                function (response) {
                    return _.map(response.items, function (v) {
                        v.label = v.code + ' ' + v.name;
                        return v;
                    });

                }
        );
    };

    $scope.diagRelatedParams = {selectedDiagnosis: {}, selectedDiagnosisDescription: '', tempDiagnosis: {}};
    $scope.searchReleatedDiagnosisChange = function (value) {
        if (value && value.code && value.name) {
            $scope.diagRelatedParams.tempDiagnosis = value;
        } else {
        }
    };


    $scope.addRelatedDiagnosis = function (diagnosis, description) {
        diagnosis = $scope.diagRelatedParams.tempDiagnosis;

        if (diagnosis && diagnosis.code && diagnosis.name) {
            var item: any = {diagnosis: {}};
            item.diagnosis.code = diagnosis.code;
            item.diagnosis.name = diagnosis.name;
            item.description = description;

            $scope.documentData.relatedDiagnoses.push(item);
            $scope.diagRelatedParams.selectedDiagnosis = null;
            $scope.diagRelatedParams.tempDiagnosis = null;
            $scope.diagRelatedParams.selectedDiagnosisDescription = '';
        }
    };


    $scope.removeRelatedDiagnosis = function (index) {
        $scope.documentData.relatedDiagnoses.splice(index, 1);
    };


    $scope.searchMainDiagnosisChange = function (value) {
        if (value && value.code && value.name) {
            console.log("Selected diagnosis: " + value.code + ' ' + value.name);
            var item: any = {diagnosis: {}};
            item.diagnosis.code = value.code;
            item.diagnosis.name = value.name;
            $scope.documentData.mainDiagnosis.fullId = "";
            $scope.documentData.mainDiagnosis.diagnosis = item.diagnosis;
        }
    };

    $scope.searchAchiCode = function (value) {
        if (!value || value.length < 3) {
            return [];
        }

        return MedvaisClassifierApiFactory.getAchiCodes({text: value}).$promise.then(
                function (response) {
                    return _.map(response.items, function (v) {
                        v.label = v.code + ' ' + v.name;
                        return v;
                    });

                }
        );
    };

    $scope.searchAchiCodeChange = function (value) {
        if (value && value.code) {
            console.log(value);
            $scope.documentData.achiCode = value.code;
        } else {
        }
    };


    $scope.identifyPatient = function () {
        var patientToIdentify: any = {};
        patientToIdentify.imagingStudyId = $scope.documentData.imagingStudy.id;
        patientToIdentify.familyName = $scope.documentData.imagingStudy.patient.familyName;
        patientToIdentify.givenName = $scope.documentData.imagingStudy.patient.givenName;
        patientToIdentify.personalCode = $scope.documentData.imagingStudy.patient.personalCode;

        $medvaisPatientIdentification.show(patientToIdentify)
            .then(loadImagingStudyData)
            .then(loadAssignedRelatedImagingStudies)
            .then(loadRelatedImagingStudiesList)
            .then(loadOrganizationData)
            .then(loadEncounterList)
            .then(loadOrderList)
            .then(
                function() {
                    $scope.docMainInfo.patient = $scope.documentData.imagingStudy.patient;
                }
            )
            .catch(
                function(err) {
                    MessageService.clearAll();
                    MessageService.add(err.type, $filter('translate')(err.msg));
                    $window.scrollTo(0, 10);
                }
            )
            .finally(function () {
                $log.debug('Finally part of patient identification.');
                FormLoaderService.endFormLoading();
            });
    };


    $scope.getFullName = function (p) {

        if (!p) {
            return null;
        }

        return ((_.exists(p.namePrefix) && !_.isBlank(p.namePrefix)) ? p.namePrefix + ' ' : '') +
                (p.givenName ? p.givenName + ' ' : '') +
                (p.familyName ? p.familyName : '');
    };

    $scope.getAge = function (p) {

        if (!p) {
            return null;
        }

        return $scope.calcAge(p.birthDate, new Date().getTime());

    };

    $scope.calcAge = function (from, to) {
        if (from && to) {
            var fromM = moment(Number(from));
            var toM = moment(Number(to));
            var diffDays = toM.diff(fromM, 'days');
            if (diffDays <= 31) {
                return diffDays + ' ' + $filter('translate')('medvais.abbr.days');
            }
            var diffMonths = toM.diff(fromM, 'months');
            if (diffMonths <= 12) {
                return diffMonths + ' ' + $filter('translate')('medvais.abbr.months');
            }
            if (diffMonths <= 24) {
                return '1 ' + $filter('translate')('medvais.abbr.years') + ' ' + (diffMonths - 12) + ' ' + $filter('translate')('medvais.abbr.months');
            }
            return toM.diff(fromM, 'years') + ' ' + $filter('translate')('medvais.abbr.years');
        }
        return '';
    };


    $scope.return = function () {
        MessageService.clearAll();
//        $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
        $window.history.back();
    };


    $scope.saveDocument = function () {
        MessageService.clearAll();
        console.log($scope.documentData);

        Medvais027vaFormService.validate($scope.form027va, $scope.documentData, false,
            function() {
                $log.info('027va validation successful.');

                if ($scope.flagNew) {
                    console.log("Saving new document...");
                    MedvaisDocumentApiFactory.createPartial($scope.documentData).$promise.then(
                            function () {
                                MessageService.success($filter('translate')('medvais.027va.edit.doc.save.success.msg'));
                                $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
                            },
                            function (err) {
                                handleSaveError(err);
                            }

                    );
                } else {
                    console.log("Updating document: " + $scope.documentId);
                    MedvaisDocumentApiFactory.updatePartial({id: $scope.documentId}, $scope.documentData).$promise.then(
                            function () {
                                MessageService.success($filter('translate')('medvais.027va.edit.doc.save.success.msg'));
                                $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
                            },
                            function (err) {
                                handleSaveError(err);
                            }
                    );
                }
            }
        );
    };


    $scope.saveFinalDocument = function () {
        MessageService.clearAll();
        $log.debug($scope.documentData);

        Medvais027vaFormService.validate($scope.form027va, $scope.documentData, true,
            function() {
                if ($scope.flagNew) {
                    $log.info("Creating new final document...");
                    MedvaisDocumentApiFactory.createFinal($scope.documentData).$promise.then(
                        function (res) {
                            MessageService.success($filter('translate')('medvais.027va.edit.doc.submit.multisign.success.msg'));
                            $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
                        },
                        function (err) {
                            handleSaveError(err);
                        }
                    );
                } else {
                    $log.info("Updating document to final: ", $scope.documentId);
                    MedvaisDocumentApiFactory.updateFinal({id: $scope.documentId}, $scope.documentData).$promise.then(
                        function (res) {
                            MessageService.success($filter('translate')('medvais.027va.edit.doc.submit.multisign.success.msg'));
                            $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
                        },
                        function (err) {
                            handleSaveError(err);
                        }
                    );
                }
            }
        );
    };


    $scope.signDocument = function () {
        MessageService.clearAll();
        console.log($scope.documentData);

        Medvais027vaFormService.validate($scope.form027va, $scope.documentData, true,
            function() {
                $log.info('027va validation successful.');
                console.log("Before signing document... ");
                if ($scope.flagNew) {
                    console.log("Creating new final document...");
                    MedvaisDocumentApiFactory.createFinal($scope.documentData).$promise.then(
                            function (res) {
                                console.log("Signing document..." + res["compositionId"]);
                                $scope.options.redirectToSignPage(res["compositionId"]);
                            },
                            function (err) {
                                handleSaveError(err);
                            }
                    );
                } else {
                    console.log("Updating document final: " + $scope.documentId);
                    MedvaisDocumentApiFactory.updateFinal({id: $scope.documentId}, $scope.documentData).$promise.then(
                            function (res) {
                                console.log("Signing document..." + $scope.documentId);
                                $scope.options.redirectToSignPage($scope.documentId);
                            },
                            function (err) {
                                handleSaveError(err);
                            }
                    );
                }
            }
        );
    };


    $scope.openTelemedicineRegistration = function () {
        MessageService.clearAll();
        $log.debug("openTelemedicineRegistration(). jarCode: " + organizationJAR + " practitionerRef: " + practitionerRef);
        $log.debug($scope.documentData);

        Medvais027vaFormService.validate($scope.form027va, $scope.documentData, false,
            function() {
                $log.info('027va validation successful.');
                if ($scope.flagNew) {
                    console.log("Saving new document...");
                    MedvaisDocumentApiFactory.createPartial($scope.documentData).$promise.then(
                            function (res) {
                                console.log("Composition id of new doc: " + res.compositionId);
                                $location.path($scope.options.getEdit027vaDocUrl(res.compositionId)).search('');

                                MedvaisDocumentApiFactory.get027vaDocEditData({id: res.compositionId}).$promise.then(
                                        function (doc) {
                                            $medvaisOrderTelemedicine.show(
                                                    {
                                                        jarCode: organizationJAR,
                                                        detailRef: doc.imagingStudy.fullId,
                                                        subjectRef: doc.metadata.patient.fullId,
                                                        sourceRef: practitionerRef
                                                    }, doc.diagnosticReportRef
                                                    ).then(
                                                    function (res) {
                                                        if (res === "true") {
                                                            MessageService.success($filter('translate')('telemedicine.order.registration.success.msg'));
                                                            $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
                                                        }
                                                    }
                                            );
                                        }
                                );

                            },
                            function (err) {
                                handleSaveError(err);
                            }
                    );
                } else {
                    console.log("Updating document: " + $scope.documentId);
                    MedvaisDocumentApiFactory.updatePartial({id: $scope.documentId}, $scope.documentData).$promise.then(
                            function (res) {
                                $medvaisOrderTelemedicine.show(
                                        {
                                            jarCode: organizationJAR,
                                            detailRef: $scope.documentData.imagingStudy.fullId,
                                            subjectRef: $scope.documentData.imagingStudy.patient.fullId,
                                            sourceRef: practitionerRef
                                        }, $scope.documentData.diagnosticReportRef
                                        ).then(
                                        function (res) {
                                            if (res === "true") {
                                                MessageService.success($filter('translate')('telemedicine.order.registration.success.msg'));
                                                $location.path($scope.options.getReturnImagingStudyListUrl()).search('');
                                            }
                                        }
                                );
                            },
                            function (err) {
                                handleSaveError(err);
                            }
                    );
                }
            }
        );
    };


    var handleSaveError = function (err) {
        if (err.status === 500) {
            if (err.data.fieldErrors && err.data.globalErrors) {
                _.each(err.data.fieldErrors, function (i) {
                    var errorMessage = i.propertyPath;
                    errorMessage += ": " + i.msg;
                    MessageService.error(errorMessage);
                });
                _.each(err.data.globalErrors, function (i) {
                    var resolved = resolveValidationError(i.msg);
                    var msg = resolved.msg !== null ? resolved.msg : i.msg;
                    MessageService.add('error', msg, resolved.region);
                });
            } else if (err.data.code && err.data.type && err.data.code === 'medvais.portal.study.updated') {
                let doc = {imagingStudy: {id: $scope.documentData.imagingStudy.id}};
                loadImagingStudyData(doc);
            } else if (err.data.code && err.data.type && err.data.code === 'medvais.portal.related.study.updated') {
                let doc = {relatedImagingStudies: $scope.documentData.relatedImagingStudies};
                loadAssignedRelatedImagingStudies(doc);
            } else {
                MessageService.error($filter('translate')('medvais.err.msg.doc.save.error'));
            }

        } else if (err.status === 400) {
            _.each(err.data.globalErrors, function (i) {
                var resolved = resolveValidationError(i.msg);
                var msg = resolved.msg !== null ? resolved.msg : i.msg;
                MessageService.error(msg);
            });

        }
        $window.scrollTo(0, 10);
    };



    var resolveValidationError = function (msg) {
        switch (msg) {
            case '027va.unidentified.patient':
            {
                return {msg: $filter('translate')('027va.unidentified.patient')};
            }
            case '027va.encounter.not.assigned':
            {
                return {msg: $filter('translate')('027va.encounter.not.assigned'), region: 'encounter-region'};
            }
            case '027va.achi.code.required':
            {
                return {msg: $filter('translate')('027va.achi.code.required'), region: 'study-info-region'};
            }
            case '027va.achi.code.invalid':
            {
                return {msg: $filter('translate')('027va.achi.code.invalid')};
            }
            case '027va.img.study.description.required':
            {
                return {msg: $filter('translate')('027va.img.study.description.required'), region: 'description-region'};
            }
            case '027va.conclusion.required':
            {
                return {msg: $filter('translate')('027va.conclusion.required'), region: 'conclusion-region'};
            }
            case '027va.diagnoses.required':
            {
                return {msg: $filter('translate')('027va.diagnoses.required'), region: 'main-diagnosis-region'};
            }
            default :
                return {msg: null};
        }
        ;
        return null;
    };


    $scope.openImagingStudyView = function (uid) {
        window.open($scope.meddreamUrl + '?study=' + uid.replace('urn:oid:', '') + '&token=' + token);
    };


    $scope.updateOrderSelection = function (pos) {
        angular.forEach($scope.ordersList, function (order, index) {
            if (pos !== index) {
                order.checked = false;
            }
        });
    };


    $scope.changeOrder = function (order) {
        $scope.documentData.orderRef = order.checked ? order.fullId : '';
    };


    $scope.getDocStateLabel = function (state, isSigned) {
        return MedvaisDocStateService.getStatusLabel(state, isSigned);
    };

    $scope.onFileSelect = function (file) {
        MessageService.clearAll();

        console.log("File selected: " + file.name);
        console.log("File type: " + file.type);
        if (!MedvaisValidationService.validateFileSize(file.size)) {
            MessageService.add('error', $filter('translate')('027va.upload.file.size.10MB'), 'study-info-region');

            return;
        }
        if (!MedvaisValidationService.validateFileType(file.type)) {
            MessageService.add('error', $filter('translate')('027va.upload.file.type.pdf.images'), 'study-info-region');
            return;
        }

        if (file) {
            var item: any = {};
            item.fullId = '';
            item.fileName = file.name;
            item.fileType = file.type;
            FormLoaderService.startFormLoading();

            MedvaisSyncMediaFileUploader.upload(file).then(
                    function (res) {
                        item.fullId = res[0].ref;
                        $scope.documentData.mediaList.push(item);
                    }
            );

        }
    };

    $scope.removeAttachment = function (index) {
        $scope.documentData.mediaList.splice(index, 1);
    };

    $scope.getAttachmentUrl = function (ref) {
        return '/medvais-api/media/file?mediaRef=' + ref;
    };

    $scope.openRelatedStudyModal = function () {
        $medvaisAddRelatedStudy.show($scope.relatedImagingStudies).then(
//            updatePartial,
                function (res, index) {
                    $scope.documentData.relatedImagingStudies.push(res.study);
                    $scope.relatedImagingStudies.splice(res.index, 1);
                },
                function (err) {
                    MessageService.clearAll();
                    MessageService.add('error', err, 'studies-region');
                }
        ).finally(function () {
            FormLoaderService.endFormLoading();
        });
    };

    $scope.cancel = function () {
        $medvaisCancelDocMdl.show().then(
                function (reasonText) {
                    if (reasonText) {
                        MedvaisDocumentApiFactory.cancel({id: $scope.documentId}, {reason: reasonText}).$promise.then(
                                function (res) {
                                    $scope.options.redirectToSignPage(res.compositionId);
                                },
                                function (err) {
                                    MessageService.clearAll();
                                    MessageService.error($filter('translate')('medvais.doc.cancel.failure.msg'));
                                }
                        );
                    }
                }
        );
    };


}]);

