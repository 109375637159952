import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subscription, map } from 'rxjs';
import { ApiV2PregnancyDashboard } from '../../../../../../../api/api-v2-doc-pregnancy-dashboard';
import { ApiV2E096 } from '../../../../../../../api/api-v2-doc-ref-e096';
import { ApiV2Documents } from '../../../../../../../api/api-v2-documents';
import { RouteParams } from '../../../../../../ng1.routeParams';
import { DocumentTagEnum } from '../../../../../../shared/models/document-tag.enum';
import { E096MedicalRecord } from '../../../../models/e096-medicalRecord.model';
import { NGNCaptionModel } from '../../../shared/models/shared.model';

@Component({
    selector: 'app-e096-nt-page',
    templateUrl: './e096-nt-page.component.html',
})
export class E096NtPageComponent {
    backUrl: string;
    editable: boolean;
    initialFormValue: E096MedicalRecord;
    ngnCaptionData: NGNCaptionModel;
    documentTag = DocumentTagEnum.e096_nt;
    private patientId: string;
    private formId: string;
    prefillData: any;

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private apiV2E096: ApiV2E096,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private destroyRef: DestroyRef
    ) {
        this.getPatientId();
        if (this.formId) {
            this.apiV2E096.getE096FormView('nt', this.patientId).pipe(takeUntilDestroyed())
                .subscribe(res => this.initialFormValue = res);
        }
        this.subscribeNGNCaptionData();
    }

    async ngOnInit(): Promise<void> {
        this.getPrefillData();
    }


    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getPatientId() {
        this.patientId = this.params.patientId;
        this.formId = this.params?.id;
        this.backUrl = '/dp/patients/' + this.patientId;
        this.editable = !window.location.href.includes('/view');
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
                this.ngnCaptionData = data;
            });
    }
}
