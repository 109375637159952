<div *ngIf="editMode" class="pb-3">
    <div class="row req">
        <div class="form-group col-6 col-lg-3 flex-shrink-1">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Part.formEntry.entryTime' | translate}}:"
                labelClass="form-label flex-grow-1 col-form-label"
                inputId="timeOfEntry"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="currentTime"
                [fControl]="formGroup.get('timeOfEntry')"
            ></app-date-picker>
            <app-form-control-validation [fControl]="formGroup.get('timeOfEntry')" />
        </div>
        <fieldset class="col-6 col-lg-3">
            <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold norq">
                        {{'doc.pregnancyDashboard.e096Part.formEntry.strikeDuration' | translate}}:
                    </span>
            <div class="input-group gap-3">
                <app-radio-button
                    *ngFor="let option of durationOfTheStrikeOptions; let i = index"
                    radioName="durationOfTheStrike-{{i}}"
                    [fControl]="formGroup.get('durationOfTheStrike')"
                    [radioValue]="option.name"
                    id="durationOfTheStrike-{{i}}"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
        </fieldset>
        <fieldset class="col-6 col-lg-3">
            <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold norq">
                        {{'doc.pregnancyDashboard.e096Part.formEntry.fightsCount' | translate}}:
                    </span>
            <div class="input-group gap-3">
                <app-radio-button
                    *ngFor="let option of fightsOptions; let i = index"
                    radioName="fights-{{i}}"
                    [fControl]="formGroup.get('fights')"
                    [radioValue]="option.name"
                    id="fights-{{i}}"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
        </fieldset>
        <app-input
            labelText="{{'doc.pregnancyDashboard.e096Part.formEntry.cervixOpening' | translate}}:"
            type="number"
            class="col-6 col-lg-3"
            labelClass="col-form-label"
            units="cm"
            [showErrors]="true"
            [fControl]="formGroup.get('cervixOpening')"
        ></app-input>
        <app-input
            labelText="{{'doc.pregnancyDashboard.e096Part.formEntry.oxytocin' | translate}}:"
            type="number"
            class="col-6 col-lg-3"
            labelClass="col-form-label norq"
            units="mV/min"
            [fControl]="formGroup.get('oxytocin')"
        ></app-input>
        <app-input
            labelText="{{'doc.med.dat.pul' | translate}}:"
            type="number"
            class="col-6 col-lg-3"
            labelClass="col-form-label norq"
            units="k/min"
            [showErrors]="true"
            [fControl]="formGroup.get('pulse')"
        ></app-input>
        <app-double-input
            labelText="{{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.bloodPressure' | translate}}:"
            class="col-6 col-lg-3"
            units="mmHg"
            type="number"
            labelClass="col-form-label mt-3 norq"
            [firstControl]="formGroup.get('bloodPressureSystolic')"
            [secondControl]="formGroup.get('bloodPressureDiastolic')"
        ></app-double-input>
        <app-input
            labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.temperature' | translate}}:"
            type="number"
            class="col-6 col-lg-3"
            labelClass="col-form-label norq"
            units="C°"
            [fControl]="formGroup.get('temperature')"
        ></app-input>
    </div>
    <div class="row mt-3 req">
        <fieldset class="col-6 col-lg-3">
            <legend class="float-none form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold norq">
                {{'doc.pregnancyDashboard.e096Part.formEntry.methodOfPainRelief' | translate}}:
            </legend>
            <app-checkbox
                *ngFor="let option of answersVariants?.['childbirthMedicalPainManagement']; let i = index"
                inputId="part-methodOfPainRelief-{{i}}"
                class="mt-1"
                formClass="mb-0"
                [labelText]="option.name"
                [checkboxValue]="option.name"
                [checkboxDisabled]="!(editable)"
                [checkboxChecked]="checkboxIsChecked(option.name, 'methodOfPainRelief')"
                (checkboxChange)="updateControl(option.name, 'methodOfPainRelief')"
            ></app-checkbox>
        </fieldset>
        <fieldset class="col-6 col-lg-3">
            <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold">
                        {{'doc.pregnancyDashboard.e096Part.formEntry.urinating' | translate}}:
                    </span>
            <div class="input-group">
                <app-radio-button
                    *ngFor="let option of urinatingOptions; let i = index"
                    radioName="part-urinating-{{i}}"
                    [fControl]="formGroup.get('urinating')"
                    [radioValue]="option.name"
                    id="part-urinating-{{i}}"
                    class="w-100"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
            <app-form-control-validation [fControl]="formGroup.get('urinating')" />
        </fieldset>
        <app-input
            labelText="{{'doc.pregnancyDashboard.e096Part.formEntry.decisionAndAppointments' | translate}}:"
            type="textarea"
            class="col-12 col-lg-6"
            labelClass="col-form-label norq"
            textAreaRows="5"
            [fControl]="formGroup.get('decisionAndAppointments')"
        ></app-input>
    </div>
    <nhr-record-widget
        [closed]="false"
        [title]="'doc.pregnancyDashboard.e096Part.formEntry.fetalRecord' | translate"
        class="h-auto d-block mt-3"
        bodyClass="bg-light p-2"
        *ngIf="fruitLength?.length"
    >
        <div class="card-body">
            <ng-container *ngFor="let fruit of fruitLength; let i = index">
                <app-e096-part-record
                    [answersVariants]="answersVariants"
                    [editable]="editable"
                    [fruit]="fruit"
                    class="d-block p-3 bg-white"
                    [class.mt-2]="i !== 0"
                />
            </ng-container>
        </div>
    </nhr-record-widget>

    <div class="row d-flex justify-content-end gap-3 mt-4">
        <button class="btn btn-primary w-auto"
                [disabled]="formGroup?.invalid"
                (click)="addEntry()"
        >{{(!entryData ? 'add' : 'doc.dia.edt') | translate}}</button>
        <button *ngIf="entryData" class="btn btn-danger w-auto" (click)="remove()">{{'remove' | translate}}</button>
        <button class="btn btn-outline-danger w-auto" (click)="cancel()">{{'return' | translate}}</button>
    </div>
</div>

<ng-container *ngIf="!editMode">
    <div class="row">
        <div class="col-2 fw-bold d-flex align-items-center">
            {{entryData['timeOfEntry'] | datetime}}
        </div>
        <div class="col-7">
            <div class="fw-bold">{{'doc.pregnancyDashboard.e096Part.formEntry.submitData' | translate}}:</div>
            <div>{{(entryData['updatedTime'] ?? entryData['timeOfEntry']) | datetime}}</div>
            <div>
                <span>{{doctorQualification(entryData.doctor)}}</span>,
                <span>{{entryData.doctor?.['givenName'] | titlecase}} {{entryData.doctor?.['familyName'] | titlecase}}</span></div>
            </div>
        <div *ngIf="editable" class="col-3 d-flex gap-3 align-items-center">
            <button class="btn btn-primary flex-grow-1 h-75" [disabled]="disableBtns" (click)="editEntry()">{{'doc.dia.edt' | translate}}</button>
            <button class="btn btn-danger flex-grow-1 h-75" [disabled]="disableBtns" (click)="remove()">{{'remove' | translate}}</button>
        </div>
    </div>
</ng-container>