import {Component, Input, OnInit} from "@angular/core";
import {MedicalDevicesSummaryModel} from "../../../models/medical-devices-summary.model";
import {MppSummaryModel} from "../../../models/mpp-summary.model";

@Component({
    selector: 'medical-devices-summary',
    templateUrl: './medical-devices-summary.component.html'
})
export class MedicalDevicesSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() implants: MedicalDevicesSummaryModel[] = [];
    @Input() mpps: MppSummaryModel[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }
}