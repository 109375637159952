ng1App.directive('patientInsuranceBlock', ['PatientInsuranceService', function (PatientInsuranceService) {
    return {
        templateUrl: '/templates/components/patient/patient-insurance-block.html',
        restrict: 'E',
        replace: false,
        scope: {
            insurance: '=',
            loading: '='
        },
        link: function (scope, element, attrs) {
        }
    };
}]);