<app-title [backPath]="backPath">
    {{'GMP kortelė'| translate}}
</app-title>
<!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
<app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
    (valueChange)="onChanges($event)"></app-common-patient-widget>
<app-encounter-block></app-encounter-block>
<patient-transported-institution (valueChange)="onInstitutionChanges($event)"></patient-transported-institution>
<app-diagnoses-form (currentDiagnosesChange)="onDiagnosisChanges($event)"
    [isRequiredFieldInForm]=" true"></app-diagnoses-form>
<app-undergone-surgical-procedures (valueChange)="onSurgicalChanges($event)"></app-undergone-surgical-procedures>
<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true"
    [title]="'doc-ref-058-089-151a-form.component'|translate">
    <div class="widget-body">
        <div class="form-group row">
            <label class="col-md-3 col-form-label text-md-end" for="XMLfileInput">Pridėti XML tipo failą</label>
            <div class="col-md-8">
                <button *ngIf="!XMLfileSelected" class='btn btn-secondary btn-sm' type="button"
                    (click)="onXMLFileButtonClick()">
                    <span translate>AddFile</span>
                </button>
                <input #XMLfileInput type="file" class="me-2" id="XMLfileInput" (change)="onXMLFileSelected($event)"
                    style="display:none;" accept=".xml" />
                <div id="XMLfileName" *ngIf="XMLfileSelected"
                    class="d-flex justify-content-between align-items-center">
                    {{ XMLfileName }}
                    <button *ngIf="XMLfileSelected" class='btn btn-secondary btn-sm' type="button"
                        (click)="onXMLFileButtonClick()">
                        <span>Keisti failą</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label text-md-end" for="PDFfileInput">Pridėti PDF tipo failą</label>
            <div class="col-md-8">
                <button *ngIf="!PDFfileSelected" class='btn btn-secondary btn-sm' type="button"
                    (click)="onPDFFileButtonClick()">
                    <span translate>AddFile</span>
                </button>
                <input #PDFfileInput type="file" class="me-2" id="PDFfileInput" (change)="onPDFFileSelected($event)"
                    style="display:none;" accept=".pdf" />
                <div id="PDFfileName" *ngIf="PDFfileSelected"
                    class="d-flex justify-content-between align-items-center">
                    {{ PDFfileName }}
                    <button *ngIf="PDFfileSelected" class='btn btn-secondary btn-sm' type="button"
                        (click)="onPDFFileButtonClick()"><span>Keisti failą</span></button>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
<app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
    (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
    [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
    [submitButtonDisabled]="true"></app-modification-actions>

<!-- <div class="container">
    <back-link [path]="backPath"/>
    <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget>
    <app-encounter-block></app-encounter-block>
    <patient-transported-institution (valueChange)="onInstitutionChanges($event)"></patient-transported-institution>
    <app-diagnoses-form></app-diagnoses-form>
    <app-undergone-surgical-procedures (valueChange)="onSurgicalChanges($event)"></app-undergone-surgical-procedures>
    <nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true" [title]="'doc-ref-058-089-151a-form.component'|translate">
        <div class="widget-body">
            <form [formGroup]="form">
                <div class="form-group row">
                    <label class="col-md-3 col-form-label text-md-end" for="XMLfileInput">Pridėti XML tipo failą</label>
                    <div class="col-md-8">
                        <button class='btn btn-secondary btn-sm' type="button" (click)="XMLfileInput.click()" *ngIf="!XMLfileSelected">
                            <span translate>AddFile</span>
                            <input #XMLfileInput type="file" class="me-2" id="XMLfileInput" (change)="onXMLFileSelected($event)" style="display:none;" />
                        </button>
                        <div id="XMLfileName" *ngIf="XMLfileSelected" class="d-flex justify-content-between align-items-center">
                            {{ XMLfileName }}
                            <button class='btn btn-secondary btn-sm' (click)="onXMLFileRemove()" *ngIf="XMLfileSelected">Remove</button>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label text-md-end" for="PDFfileInput">Pridėti PDF tipo failą</label>
                    <div class="col-md-8">
                        <button class='btn btn-secondary btn-sm' type="button" (click)="PDFfileInput.click()" *ngIf="!PDFfileSelected">
                            <span translate>AddFile</span>
                            <input #PDFfileInput type="file" class="me-2" id="PDFfileInput" (change)="onPDFFileSelected($event)" style="display:none;" />
                        </button>
                        <div id="PDFfileName" *ngIf="PDFfileSelected" class="d-flex justify-content-between align-items-center">
                            {{ PDFfileName }}
                            <button class='btn btn-secondary btn-sm' (click)="onPDFFileRemove()" *ngIf="PDFfileSelected">Remove</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </nhr-record-widget>
    <app-modification-actions
    (sign)="signForm()"
    (sealConfirm)="sealConfirmForm()"
    (signingSubmit)="signingSubmitForm()"
    (submit)="submitForm()"
    (save)="saveForm()"
    (clear)="clearForm()"
  ></app-modification-actions>
</div> -->