ng1App.controller('doctor.user.DashboardCtrl',
    ['$scope', '$location', '$modalConfirm', '$filter', '$routeParams', '$window', 'PatientsApiFactory', 'MessageService', 'DpSessionContext', 'TasksApiFactory', 'AlertsApiFactory', 'DpMessagesUtil', '$q', 'UnfinishedRecordsApiFactory', 'TemplatesApiFactory', 'EncountersApiFactory', 'PatientUpdateStatusHandler', 'DocumentsApiFactory', 'SessionApiFactory',
     function ($scope, $location, $modalConfirm, $filter, $routeParams, $window, PatientsApiFactory, MessageService,
                                                         DpSessionContext, TasksApiFactory, AlertsApiFactory, DpMessagesUtil, $q, UnfinishedRecordsApiFactory, TemplatesApiFactory, EncountersApiFactory,
                                                         PatientUpdateStatusHandler, DocumentsApiFactory, SessionApiFactory) {
    $scope.$emit('setHelpPage', 'pradinis-espbi-is-puslapis-prisijungusiems-specialistams');
    MessageService.clear();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    var maxDashboardCount = 3;

    DpSessionContext.practitioner.$promise.then(function (a) {
        $scope.practitionerId = DpSessionContext.practitioner.id;
        $scope.todayPatientsLoading = true;
        PatientsApiFactory.search({filter_type: 'mytoday', count: 3}, function (res) {
            $scope.todayPatients = res.items;
            $scope.todayPatientsCount = res.total;
        }).$promise.finally(function () {
            $scope.todayPatientsLoading = false;
        });
    });

    $scope.loadUnfinishedDocuments = function () {
        if ($scope.unfinishedRecords == undefined) {
            $scope.unfinishedRecords = [];
            $q.all([DpSessionContext.organization.$promise, DpSessionContext.practitioner.$promise]).then(function () {
                $scope.unfinishedRecords = UnfinishedRecordsApiFactory.search(
                        {organization: DpSessionContext.organization.id,
                            practitioner: DpSessionContext.practitioner.id, count: 3});
            })
        }
    };

    $scope.data = {
        personalCode: '',
        personalCodeEnc: ''
    };

    //---------DEMO DATA----------

    $scope.certificates = [
        {
            'patientName': 'Roberta',
            'patientLastname': 'Rasomavitienė',
            'birthday': '1966-11-07',
            'number': '14-000001',
            'title': 'E049/a Asmens privalomojo sveikatos tikrinimo kortelė',
            'status': 'Ruošiamas',
            'documentStartDateTime': '2014-08-05 10:42'
        },
        {
            'patientName': 'Emilija',
            'patientLastname': 'Oniškytė',
            'birthday': '2014-08-01',
            'number': '14-001',
            'title': '106-2-1/a Medicininis perinatalinės mirties liudijimas',
            'status': 'Ruošiamas',
            'documentStartDateTime': '2014-08-05 11:35'
        },
        {
            'patientName': 'Julijus Augustinas',
            'patientLastname': 'Firnavitėnas',
            'birthday': '2000-08-11',
            'number': '14-00001',
            'title': '106/a Medicininis mirties liudijimas',
            'status': 'Ruošiamas',
            'documentStartDateTime': '2014-08-05 11:45'
        }
    ];

    DpSessionContext.practitioner.$promise.then(function () {
        $scope.tasks = TasksApiFactory.getList({targetPractitionerId: DpSessionContext.practitioner.id, statuses: ['SUBMITTED', 'ABORTED'], count: 3});
    });

    //---------DEMO DATA END----------

    $scope.viewLoading = false;
    $scope.view = function () {
        $scope.viewLoading = true;
        $scope.errors = [];

        if(!$scope.data.personalCode){
            $scope.errors.push({
                message: 'Nenurodytas asmens kodas.',
                type: 'error'
            });
            $scope.viewLoading = false;
            return;
        }

            PatientsApiFactory.search(
                {
                    personalCode: $scope.data.personalCode,
                },
                function (data) {
                    if (data.total > 0) {
                        $scope.viewLoading = false;
                        $location.path("/dp/patients/" + data.items[0].id); // + data.id
                    } else {
                        PatientsApiFactory.search(
                            {
                                esi: $scope.data.personalCode,
                            },
                            function (data) {
                                $scope.viewLoading = false;
                                if (data.total > 0) {
                                    $scope.viewLoading = false;
                                    $location.path("/dp/patients/" + data.items[0].id); // + data.id
                                } else {
                                    PatientsApiFactory.search(
                                        {
                                            dik: $scope.data.personalCode,
                                        },
                                        function (data) {
                                            $scope.viewLoading = false;
                                            if (data.total > 0) {
                                                $scope.viewLoading = false;
                                                $location.path("/dp/patients/" + data.items[0].id); // + data.id
                                            } else {
                                                $scope.errors.push({
                                                    message: "Nėra registre.",
                                                    type: "error",
                                                });
                                            }
                                        },
                                        function () {
                                            $scope.viewLoading = false;
                                        }
                                    );
                                }
                            },
                            function () {
                                $scope.viewLoading = false;
                            }
                        );
                    }
                },
                function () {
                    $scope.viewLoading = false;
                }
            );
        };

    $scope.canRegisterStandardEncounter = function () {
        return $filter('acWrite')('DocumentList');
    }

    $scope.canRegisterAuditEncounter = function () {
        return $filter('acWrite')('PatientAudit');
    }

    $scope.isEncounterRegistrationVisible = function () {
        return $scope.canRegisterStandardEncounter() || $scope.canRegisterAuditEncounter();
    }

    $scope.getEncounterRegistrationWidgetColor = function () {
        return $scope.canRegisterAuditEncounter() ? 'dark' : 'blue';
    }

    $scope.getEncounterRegistrar = function () {
        if ($scope.canRegisterStandardEncounter()) {
            if ($scope.canRegisterAuditEncounter()) {
                return $scope.registerAuditEncounter;
            } else {
                return $scope.registerEncounter;
            }
        }
    }

    $scope.registerAuditEncounter = function () {
        $scope.registerEncounterLoading = true;
        $scope.errors = [];

        validatePersonalCode($scope.data.personalCodeEnc);
        if (!_.isEmpty($scope.errors)) {
            $scope.registerEncounterLoading = false;
            return;
        }

            PatientsApiFactory.search(
                {
                    personalCode: $scope.data.personalCodeEnc,
                    update: false,
                },
                function (result) {
                    if (result.total > 0) {
                        const patients = (result || {}).items || [];
                        const patientReference = (_.first(patients) || {}).fullId;
                        if (patientReference) {
                            registerEncounter(patientReference);
                        } else {
                            PatientsApiFactory.search(
                                {
                                    esi: $scope.data.personalCodeEnc,
                                    update: false,
                                },
                                function (result) {
                                    if (result.total > 0) {
                                        const patients = (result || {}).items || [];
                                        const patientReference = (_.first(patients) || {}).fullId;
                                        if (patientReference) {
                                            registerEncounter(patientReference);
                                        } else {
                                            PatientsApiFactory.search(
                                                {
                                                    dik: $scope.data.personalCodeEnc,
                                                    update: false,
                                                },
                                                function (result) {
                                                    if (result.total > 0) {
                                                        const patients = (result || {}).items || [];
                                                        const patientReference = (_.first(patients) || {}).fullId;
                                                        if (patientReference) {
                                                            registerEncounter(patientReference);
                                                        } else {
                                                            $scope.errors.push({
                                                                message: "Nėra registre.",
                                                                type: "error",
                                                            });
                                                            $scope.registerEncounterLoading = false;
                                                        }
                                                        PatientUpdateStatusHandler.handleResponse(result, true);
                                                    } else {
                                                        $scope.errors.push({
                                                            message: "Nėra registre.",
                                                            type: "error",
                                                        });
                                                    }
                                                },
                                                function () {
                                                    $scope.registerEncounterLoading = false;
                                                }
                                            );
                                        }
                                        PatientUpdateStatusHandler.handleResponse(result, true);
                                    } else {
                                        PatientsApiFactory.search(
                                            {
                                                dik: $scope.data.personalCodeEnc,
                                                update: false,
                                            },
                                            function (result) {
                                                if (result.total > 0) {
                                                    const patients = (result || {}).items || [];
                                                    const patientReference = (_.first(patients) || {}).fullId;
                                                    if (patientReference) {
                                                        registerEncounter(patientReference);
                                                    } else {
                                                        $scope.errors.push({
                                                            message: "Nėra registre.",
                                                            type: "error",
                                                        });
                                                        $scope.registerEncounterLoading = false;
                                                    }
                                                    PatientUpdateStatusHandler.handleResponse(result, true);
                                                } else {
                                                    $scope.errors.push({
                                                        message: "Nėra registre.",
                                                        type: "error",
                                                    });
                                                }
                                            },
                                            function () {
                                                $scope.registerEncounterLoading = false;
                                            }
                                        );
                                    }
                                },
                                function () {
                                    $scope.registerEncounterLoading = false;
                                }
                            );
                            $scope.errors.push({
                                message: "Nėra registre.",
                                type: "error",
                            });
                            $scope.registerEncounterLoading = false;
                        }
                        PatientUpdateStatusHandler.handleResponse(result, true);
                    } else {
                        PatientsApiFactory.search(
                            {
                                esi: $scope.data.personalCodeEnc,
                                update: false,
                            },
                            function (result) {
                                if (result.total > 0) {
                                    const patients = (result || {}).items || [];
                                    const patientReference = (_.first(patients) || {}).fullId;
                                    if (patientReference) {
                                        registerEncounter(patientReference);
                                    } else {
                                        PatientsApiFactory.search(
                                            {
                                                dik: $scope.data.personalCodeEnc,
                                                update: false,
                                            },
                                            function (result) {
                                                if (result.total > 0) {
                                                    const patients = (result || {}).items || [];
                                                    const patientReference = (_.first(patients) || {}).fullId;
                                                    if (patientReference) {
                                                        registerEncounter(patientReference);
                                                    } else {
                                                        $scope.errors.push({
                                                            message: "Nėra registre.",
                                                            type: "error",
                                                        });
                                                        $scope.registerEncounterLoading = false;
                                                    }
                                                    PatientUpdateStatusHandler.handleResponse(result, true);
                                                } else {
                                                    $scope.errors.push({
                                                        message: "Nėra registre.",
                                                        type: "error",
                                                    });
                                                }
                                            },
                                            function () {
                                                $scope.registerEncounterLoading = false;
                                            }
                                        );
                                    }
                                    PatientUpdateStatusHandler.handleResponse(result, true);
                                } else {
                                    PatientsApiFactory.search(
                                        {
                                            dik: $scope.data.personalCodeEnc,
                                            update: false,
                                        },
                                        function (result) {
                                            if (result.total > 0) {
                                                const patients = (result || {}).items || [];
                                                const patientReference = (_.first(patients) || {}).fullId;
                                                if (patientReference) {
                                                    registerEncounter(patientReference);
                                                } else {
                                                    $scope.errors.push({
                                                        message: "Nėra registre.",
                                                        type: "error",
                                                    });
                                                    $scope.registerEncounterLoading = false;
                                                }
                                                PatientUpdateStatusHandler.handleResponse(result, true);
                                            } else {
                                                $scope.errors.push({
                                                    message: "Nėra registre.",
                                                    type: "error",
                                                });
                                            }
                                        },
                                        function () {
                                            $scope.registerEncounterLoading = false;
                                        }
                                    );
                                }
                            },
                            function () {
                                $scope.registerEncounterLoading = false;
                            }
                        );
                    }
                },
                function () {
                    $scope.registerEncounterLoading = false;
                }
            );

        function registerEncounter(patientReference) {
            EncountersApiFactory.registerAuditEncounter(
              {patientRef: patientReference},
              {},
              function (result) {
                  const patientId = (result || {}).patientId;
                  if (patientId) {
                      MessageService.registerSuccessEvent();
                      $location.path('dp/patients/' + patientId);
                  }
              },
              function (error) {
                  angular.forEach(error.data.fieldErrors, function (e) {
                      MessageService.error(e.msg);
                  });
                  angular.forEach(error.data.globalErrors, function (e) {
                      MessageService.error(e.msg);
                  });
              }
              ).$promise.finally(function () {
                $scope.registerEncounterLoading = false;
            });
        }

        function validatePersonalCode(value) {
            if (!value) {
                $scope.errors.push({
                    message: 'Nenurodytas asmens kodas.',
                    type: 'error'
                });
            }
        }

    };


    $scope.registerEncounterLoading = false;
    $scope.registerEncounter = function () {
        $scope.registerEncounterLoading = true;
        $scope.errors = [];

        if(!$scope.data.personalCodeEnc){
            $scope.errors.push({
                message: 'Nenurodytas asmens kodas.',
                type: 'error'
            });
            $scope.registerEncounterLoading = false;
            return;
        }

        PatientsApiFactory.search({
            personalCode: $scope.data.personalCodeEnc,
            update: true
        }, function (result) {
            $scope.registerEncounterLoading = false;
            if (result.total > 0) {
                $location.path('/dp/patients/' + result.items[0].id + '/encounters/new'); // + data.id
            } else {
                $scope.errors.push({
                    message: 'Nėra registre.',
                    type: 'error'
                });
            }
            PatientUpdateStatusHandler.handleResponse(result, true);
        }, function () {
            $scope.registerEncounterLoading = false;
        });

    };

    // + Message part
    $scope.messages = [];
    $scope.msgUtil = DpMessagesUtil;

    $scope.searchMessages = function () {
        $scope.messages = [];
        $scope.messagesLoading = true;
        AlertsApiFactory.searchMessages({page: 1, count: 7, folder: 'UNREAD'}, function (data) {
            $scope.unreadMsgCount = data.total;
            $scope.$emit('updateUnreadMsgCount', data.total);
            _.each(data.items, function (p) {
                $scope.messages.push(p);
            });
        }).$promise.finally(function () {
            $scope.messagesLoading = false;
        });
    };
    $scope.searchMessages();

    $scope.deleteMessage = function (messageId) {
        MessageService.clearAll();
        $modalConfirm.show(
                {title: $filter('translate')('err.ale.tit.mess.del'), content: $filter('translate')('err.ale.mess.del')}).then(function () {
            AlertsApiFactory.deleteMessages([messageId], function (data) {
                $scope.searchMessages();
                MessageService.success($filter('translate')('mess.one.del.success'));
            });
        });
    };

    // - Message part

    $scope.medvaisDashboardImgStudyContentOptions = {
        // route to imaging study list
        getImgStudyListUrl: function () {
            return '/dp/imaging/study/list';
        },
        // route to imaging study list with modality filter
        getImgStudyListUrlWithFilter: function (modality) {
            return '/dp/imaging/study/list?modalityFilter=' + modality;
        }
    };

    $scope.loadTemplateCounts = function () {
        if (!$scope.templateCounts) {
            $scope.templateCounts = [];
            $scope.templateCountsLoading = true;
            TemplatesApiFactory.countsByType({}, function (res) {
                _.each(res, function (t) {
                    if (t.type && t.type != 'all') {
                        $scope.templateCounts.push(t);
                    }
                });
            }).$promise.finally(function () {
                $scope.templateCountsLoading = false;
            });
        }
    };

    $scope.linkLastEncounter = function (id) {
        EncountersApiFactory.getPatientEncounters({patient: id, count: 1, searchDocs: 'false', status: ['planned', 'inProgress', 'onleave']}, function (data) {
            var enc = data.items.shift();
            if(enc.id){
                $location.path('/dp/patients/' + id + '/encounters/' + enc.id + '/edit');
            }
        });
    };

    $scope.loadTemplateCounts();


}]);