ng1App.directive('docStatusIcon', function () {
    return {
        templateUrl: '/templates/components/doc/doc-status-icon.html',
        restrict: 'E',
        replace: false,
        scope: {_status: '=status', _signed: '=signed'},
        link: function (scope: any, element, attrs) {
            scope.showTitle = attrs.showTitle == true || attrs.showTitle == 'true';
            console.error('use <doc-status-icon2> instead!');
            scope.status = 'use_other_directive!';
        }
    };
})
        .directive('docStatusIcon2', 
    ['portalType',
     function (portalType) {
            return {
                templateUrl: '/templates/components/doc/doc-status-icon.html',
                restrict: 'E',
                replace: false,
                scope: {status: '='},
                link: function (scope: any, element, attrs) {
                    scope.showTitle = attrs.showTitle == true || attrs.showTitle == 'true';
                    scope.portalType = portalType;
                }
            }
        }])
        .directive('docVisibilityIcon', function () {
            return {
                templateUrl: '/templates/components/doc/doc-visibility-icon.html',
                restrict: 'E',
                replace: false,
                scope: {visible: '='},
                link: function (scope, element, attrs) {

                }
            };
        })
        .directive('listOrder', 
    ['portalType',
     function (portalType) {
            return {
                templateUrl: '/templates/components/doc/list-order.html',
                restrict: 'E',
                replace: false,
                scope: {i: '=item', document: '=document', newTab: '@'},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.portalType = portalType;
                }
            }
        }])
        .directive('listOrderSmall', function () {
            return {
                templateUrl: '/templates/components/doc/list-order-small.html',
                restrict: 'E',
                replace: false,
                scope: {i: '=item', document: '=document'},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                }
            }
        })
        .directive('orderWithMetadata', 
    ['DocumentsApiFactory',
     function (DocumentsApiFactory) {
            return {
                templateUrl: '/templates/components/doc/order-with-metadata.html',
                require: 'ngModel',
                restrict: 'E',
                replace: false,
                scope: {o: '=orderItem', showNotes: '=', radioValue: '=', innitValue: '=', findOrderDocument: '=', newTab: '@', excludeDocument: '='},
                link: function (scope: any, element, attrs, ctrl) {
                    scope._ = _;
                    scope.inputType = attrs.inputType;
                    scope.data = {valueToSet: scope.radioValue ? scope.radioValue : scope.o};
                    if (scope.innitValue) {
                        scope.data.value = scope.innitValue;
                    }

                    var orderDocLoaded = false;
                    scope.$watch('o.item.id', function (val, old) {
                        if (!orderDocLoaded && val) {
                            if (scope.findOrderDocument) {
                                DocumentsApiFactory.getOrderDocumentsByOrder({orderId: val, alsoE025E003: true}, function (data) {
                                    orderDocLoaded = true;
                                    scope.orderDoc = data.length > 0 ? data[0] : undefined;
                                });
                            }
                        }
                    });

                    scope.hasOrderDoc = function () {
                        return (scope.o && scope.o.compositionId) || scope.orderDoc;
                    };

                    scope.doShowNotes = function () {
                        return scope.showNotes === undefined ? true : scope.showNotes;
                    };

                    var setValue = function (newVal) {
                        ctrl.$setViewValue(newVal);
                    };

                    scope.$watch('data.value', setValue);
                }
            };
        }])
        .directive('historyDocumentItem', 
    ['MedvaisPaths', 'ErxPaths',
     function (MedvaisPaths, ErxPaths) {
            return {
                templateUrl: '/templates/components/doc/history-document-item.html',
                restrict: 'E',
                replace: false,
                scope: {item: '=', patientId: "=", showModifyButton: "="},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.getUrl = function () {
                        if (scope.item.docType == 'e027va') {
                            return MedvaisPaths.view(scope.item.id);
                        }
                        if (scope.item.docType == 'erec01') {
                            return ErxPaths.view(scope.item.additionalData.prescriptionId, scope.item.additionalData.parentId);
                        }

                        if (scope.item.certificate) {
                            if (scope.item.documentStatus == 'DRAFT') {
                                return '/dp/patients/' + scope.patientId + '/certificates/drafts/' + scope.item.id;
                            } else {
                                return '/dp/patients/' + scope.patientId + '/certificates/' + scope.item.id;
                            }
                        } else {
                            if (scope.item.documentStatus == 'DRAFT') {
                                if (scope.item.docType == 'patient_summary') {
                                    return '/dp/patients/' + scope.patientId + '/summary/draft/' + scope.item.id;
                                } else {
                                    return '/dp/patients/' + scope.patientId + '/documents/drafts/' + scope.item.id;
                                }

                            } else {
                                return '/dp/patients/' + scope.patientId + '/documents/' + scope.item.id;
                            }
                        }
                    };
                    scope.getUrlEdit = function () {
                        if (scope.item.certificate) {
                            return '/dp/patients/' + scope.patientId + '/certificates/drafts/' + scope.item.id + '/edit';
                        }
                        if (scope.item.documentStatus == 'DRAFT') {
                            if (scope.item.docType == 'patient_summary') {
                                return '/dp/patients/' + scope.patientId + '/summary/draft/' + scope.item.id + '/edit';
                            } else {
                                return '/dp/patients/' + scope.patientId + '/documents/drafts/' + scope.item.id + '/edit';
                            }
                        }
                        return '/dp/patients/' + scope.patientId + '/documents/' + scope.item.id + '/edit';
                    };
                }
            };
        }])
        .directive('patHistoryDocumentItem', function () {
            return {
                templateUrl: '/templates/components/doc/pat-history-doc-item.html',
                restrict: 'E',
                replace: false,
                scope: {item: '=', patientId: "=", showModifyButton: "="},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.toName = function (entity) {
                        return entity.name;
                    };

                }
            };
        })
        .directive('listDocument', 
    ['portalType',
     function (portalType) {
            return {
                templateUrl: '/templates/components/doc/list-document.html',
                restrict: 'E',
                replace: false,
                scope: {item: '=', patientId: "=", showOrganization: '=', responseDocuments: '=', showPatient: "=", encounter: '=', showVisible: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.portalType = portalType;
                    scope.dateFormat = attrs.dateFormat ? attrs.dateFormat : 'yyyy-MM-dd HH:mm';

                    scope.toName = function (entity) {
                        return entity.name;
                    };
                }
            };
        }])
        .directive('diagnosisItem', function () {
            return {
                templateUrl: '/templates/components/doc/diagnosis-item.html',
                restrict: 'E',
                replace: false,
                scope: {item: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                }
            };
        })
        .directive('procedureItem', function () {
            return {
                templateUrl: '/templates/components/doc/procedure-item.html',
                restrict: 'E',
                replace: false,
                scope: {item: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                }
            };
        })
        .directive('prescriptionItem', function () {
            return {
                templateUrl: '/templates/components/doc/prescription-item.html',
                restrict: 'E',
                replace: false,
                scope: {item: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                }
            };
        })
        .directive('vaccinationItem', function () {
            return {
                templateUrl: '/templates/components/doc/vaccination-item.html',
                restrict: 'E',
                replace: false,
                scope: {item: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                }
            };
        })
        .directive('listVaccination', function () {
            return {
                templateUrl: '/templates/components/doc/list-vaccination.html',
                restrict: 'E',
                replace: false,
                scope: {recommendationItem: '=', item: '=', showDate: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                }
            };
        })
        .directive('historyItemType', function () {
            return {
                templateUrl: '/templates/components/doc/history-item-type.html',
                restrict: 'E',
                replace: false,
                scope: {type: '='},
                link: function (scope, element, attrs) {
                }
            };
        })
        .directive('docServiceData', function () {
            return {
                templateUrl: '/templates/components/doc/document-service-data.html',
                restrict: 'E',
                replace: false,
                scope: {doc: '='},
                link: function (scope: any, element, attrs) {
                    scope.toName = function (entity) {
                        return entity.name;
                    };
                }
            };
        })
        .directive('listUnfinishedRecord', 
    ['$location', '$routeParams', 'DocumentsApiFactory', 'CertificatesApiFactory', '$modal', '$q', 'CertificateService', 'MessageService', '$filter',
     function ($location, $routeParams, DocumentsApiFactory, CertificatesApiFactory, $modal, $q, CertificateService, MessageService, $filter) {
            return {
                templateUrl: '/templates/components/doc/list-unfinished-record.html',
                restrict: 'E',
                replace: false,
                scope: {item: '=', encounterId: "=", showPatient: "="},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.patientId = $routeParams.patientId;
                    scope.getEditUrl = function (item) {
                        if (scope.item.resource.certificate) {
                            return '/dp/patients/' + scope.item.resource.patientId + '/certificates/' + item.id + '/edit';
                        } else {
                            return '/dp/patients/' + scope.item.resource.patientId + '/documents/' + item.id + '/edit';
                        }
                    };
                    scope.createVisit = function (item) {
                        console.log(item)
                        var encounter = null;
                        if (!scope.encounterId) {
                            encounter = DocumentsApiFactory.getEncounter({compositionId: item.compositionId});
                        } else {
                            encounter = $q.defer()
                            encounter.resolve({id: scope.encounterId});
                        }

                        var docType = null;
                        switch (item.item.type.code) {
                            case '1':
                                docType = 'e027';
                                break;
                            case '2':
                                docType = ['e027', 'e200'];
                                break;
                            case '3':
                                docType = 'e014';
                                break;
                            case '4':
                                docType = ['e027', 'e063'];
                                break;
                        }
                        var promise = encounter.$promise || encounter.promise;
                        promise.then(function (resolvedEncounter) {
                            var orderId = item.item.id;
                            scope.navigateToVisit(item.patientId, docType, resolvedEncounter.id, orderId, item.compositionId, item.item.type.code);
                        });
                    };

                    scope.navigateToVisit = function (patientId, docType, encounterId, orderId, parentDocumentId, orderType) {
                        // if there is more than one doc type, user must choose
                        if (typeof docType !== 'string') {
                            scope.docType = docType;
                            var modal = $modal({
                                scope: scope,
                                title: 'testas',
                                contentTemplate: '/templates/portal/doctor/patient/records/modals/order_type_select.html'

                            });
                            scope.data = {};
                            modal.$promise.then(function () {
                                modal.show();
                            });
                            scope.confirm = function (selectedDocType) {
                                if (!selectedDocType) {
                                    return;
                                }
                                $location.path('/dp/patients/' + patientId + '/documents/new/' + selectedDocType).search(
                                        {encounterId: encounterId, orderId: orderId, parentDocumentId: parentDocumentId, orderType: orderType == '4' ? '4' : null});
                                modal.hide();
                            }

                        } else {
                            $location.path('/dp/patients/' + patientId + '/documents/new/' + docType).search(
                                    {encounterId: encounterId, orderId: orderId, parentDocumentId: parentDocumentId});
                        }
                    }
                    scope.canCertificateBeCreated = function (type, e025Doc) {
                        var hasDummyCertificate = false;
                        if (e025Doc.additionalData && e025Doc.additionalData.partCertificates) {
                            _.each(e025Doc.additionalData.partCertificates.certificates, function (certificate) {
                                if (!certificate.certificateNo && (certificate.type == type)) {
                                    hasDummyCertificate = true;
                                }
                            });
                        }

                        return hasDummyCertificate;
                    }

                    scope.showE003E025 = function (d) {
                        return d.type != 'cer' && (d.resource.docType === 'e025' || d.resource.docType === 'e003')
                    }

                    scope.showPreliminary = function (d) {
                        return d.type !== 'order' && d.resource.certificate && d.resource.documentStatus === 'PRELIMINARY'
                    }

                    scope.navigateToSign = function (doc) {
                        var params: any = {};
                        if (doc.certificate) {
                            params.certificate = doc.docType;
                        }

                        var onMakeFinalError = function (res) {
                            MessageService.add('error', $filter('translate')('err.cer.initSign'));
                            _.handleValidationErrors(res.data, MessageService);
                        };

                        var patientId = $routeParams.patientId;
                        if (!patientId) {
                            patientId = doc.patient.id;
                        }

                        var doSign = function (doc) {
                            var docId = doc.id;
                            if (!doc.id) {
                                docId = doc.compositionId;
                            }
                            if (doc.certificate) {
                                CertificateService.toSignPage(patientId, docId, doc.docType);
                            } else {
                                $location.path('/dp/patients/' + patientId + '/documents/' + docId + '/sign').search({});
                            }
                        }
                        //$location.path('/dp/patients/' + doc.patient.id + '/documents/' + doc.id + '/sign').search(params);
                        if (doc.certificate) {
                            if (doc.documentStatus == 'PRELIMINARY') {
                                CertificatesApiFactory.makeFinal({compositionId: doc.id}, {}, doSign, onMakeFinalError);
                            } else {
                                doSign(doc);
                            }
                        } else {
                            if (doc.documentStatus == 'PRELIMINARY') {
                                DocumentsApiFactory.makeFinal({compositionId: doc.id}, {}, doSign, onMakeFinalError);
                            } else {
                                doSign(doc);
                            }
                        }
                    };
                }
            };
        }]);