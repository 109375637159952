import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription, take } from "rxjs";
import { ApiV2Session } from "../../../../../api/api-v2-session";
import { EnvironmentSettings } from "../../../../../environments/env";
import { Environment } from "../../../../../environments/environment-properties";
import { RouteParams } from "../../../../ng1.routeParams";
import { EditDiagnoseModalComponent } from "../../../../shared/components/diagnosis/edit-diagnose-modal/edit-diagnose-modal.component";
import { Pagination } from "../../../../shared/components/list/pagination-bar/pagination-bar.constants";
import { DiagnosisFilters, DiagnosisModel } from "../../../../shared/models/diagnosis.model";
import { ApiV2Diagnosis } from "../../../../shared/services/api-v2-diagnosis";
import { PaginationBarComponent } from "../../list/pagination-bar/pagination-bar.component";
import PortalType = Environment.PortalType;

@Component({
    selector: "app-diagnosis-page",
    templateUrl: "./diagnosis-page.component.html",
})
export class DiagnosisPageComponent implements OnInit, OnDestroy {
    @ViewChild("paginationBar") paginationBar: PaginationBarComponent;

    diagnosesHistory: DiagnosisModel[] = [];
    diagnosesFullHistory: DiagnosisModel[] = [];
    backUrl: string;
    btnPressed: boolean;
    public total: number;
    public pagesCount: number;
    public isLoaded: boolean = true;
    public page: number = 1;
    public isEditable: boolean;

    private lastFilters: DiagnosisFilters = { count: 10 };
    private patientId: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private modalService: BsModalService,
        private apiV2Diagnosis: ApiV2Diagnosis,
        private params: RouteParams,
        private apiV2Session: ApiV2Session,
        private datePipe: DatePipe
    ) {}

    public ngOnInit(): void {
        this.isEditable = EnvironmentSettings.getPortalType() === PortalType.DOCTOR;

        if (this.params.patientId) {
            this.patientId = this.params.patientId;
        } else {
            this.subscription.add(this.subscribePatient());
        }

        this.backUrl = "/dp/patients/" + this.patientId;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public updateFilters(filters: DiagnosisFilters) {
        if (!filters) {
            return;
        }

        const updatedfilters = { ...filters, page: 1 };

        this.filterList(updatedfilters);

        this.paginationBar.currentPage = 0;
        this.paginationBar.realCurrentPage = 1;
    }

    public openEditDiagnoseModal(i: number) {
        const initialState = {
            diagnose: this.diagnosesHistory[i],
            enableTypesEdit: false,
        };
        const ref = this.modalService.show(EditDiagnoseModalComponent, {
            initialState,
            animated: true,
            class: "modal-xl",
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: DiagnosisModel) => {
            // TODO: Needs error handling
            this.apiV2Diagnosis.saveConditions(this.patientId, [result]).subscribe(() => {
                this.diagnosesHistory[i] = { ...result };
            });
        });
    }

    public delete(i: number) {
        this.diagnosesHistory.splice(i, 1);
    }

    public filterList(filters: DiagnosisFilters): void {
        const equal = _.isEqual(filters, this.lastFilters);

        if (equal) {
            return;
        }

        if (filters && Object.values(filters).some((value) => value !== null && value !== undefined && value !== "")) {
            this.lastFilters = { ...filters, count: 10 };
        }
        this.isLoaded = false;

        if (this.patientId) {
            this.loadList(this.patientId, filters);
        }
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        const filter = { ...this.lastFilters, page: pagination.page };

        this.filterList(filter);
    }

    private loadList(patientId: string, filters: DiagnosisFilters): void {
        const params = {
            onsetPeriodStart: filters?.fromDate
                ? this.datePipe.transform(new Date(filters?.fromDate), "yyyy-MM-ddTHH:mm:ss")
                : "",
            onsetPeriodEnd: filters?.toDate
                ? this.datePipe.transform(new Date(filters?.toDate), "yyyy-MM-ddTHH:mm:ss")
                : "",
            type: (filters?.diagnosisType || []).map((x) => x.id),
            clinicalStatus: (filters?.diagnosisStatus || []).map((x) => x.id),
            verificationStatus: (filters?.diagnosisConfidence || []).map((x) => x.id),
            severity: (filters?.diagnosisSeverity || []).map((x) => x.id),
            category: (filters?.diagnosisCategory || []).map((x) => x.id),
            count: filters.count,
            page: filters.page,
        };

        for (const key in params) {
            if (
                params.hasOwnProperty(key) &&
                (params[key] === null || params[key] === undefined || params[key] === "" || params[key]?.length === 0)
            ) {
                delete params[key];
            }
        }

        this.apiV2Diagnosis
            .getFilteredDiagnosisHistory(patientId, params)
            .pipe(take(1))
            .subscribe({
                next: (res) => {
                    this.diagnosesHistory = res.items;
                    this.lastFilters = filters;

                    this.total = res.total;

                    const pagesCount = res.total / 10;
                    this.pagesCount = Math.ceil(pagesCount);

                    this.isLoaded = true;
                },
                error: () => {
                    this.diagnosesHistory = [];
                    this.isLoaded = true;
                },
            });
    }

    private subscribePatient(): Subscription {
        return this.apiV2Session.getPatient().subscribe((data) => {
            this.patientId = data.id;

            this.loadList(this.patientId, this.lastFilters);
        });
    }
}
