import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, Subscription, map, of, switchMap } from "rxjs";
import { ApiV2Organizations } from "../../../../../../api/api-v2-organizations";
import { SelectOption } from "../../../../../shared/components/select/select.component";
import { EntityModel } from "../../../../../shared/models/entity.model";
import { OrganizationModel } from "../../../../../shared/models/organization.model";
import { ApiV2Entities } from "../../../../../shared/services/api-v2-entities";
import { DocStatuses, EncounterStatuses } from "../../../../../shared/services/utils/utils";
import { NgnDocumentFilters } from "../../../models/pregnancyDashboard.model";
import { NGNDocumentsTypes } from "../../pregnancy-dashboard.constants";

@Component({
    selector: "app-pregnancy-dashboard-list-filter",
    templateUrl: "./pregnancy-dashboard-list-filter.component.html",
    providers: [TranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PregnancyDashboardListFilterComponent implements OnInit, OnDestroy {
    @Input() showFilters: boolean;
    @Output() filtering: EventEmitter<NgnDocumentFilters> = new EventEmitter<NgnDocumentFilters>();

    public filterOption = [
        {
            name: "Pagal apsilankymo duomenis",
            value: true,
        },
        {
            name: "Pagal dokumento duomenis",
            value: false,
        },
    ];

    public documentStatuses = DocStatuses.map((status) => ({
        ...status,
        name: this.translatePipe.transform(status.name),
    }));

    public encounterStatuses = EncounterStatuses.map((status) => ({
        value: status.code,
        text: status.name,
    }));

    public ngnDocumentsTypes = NGNDocumentsTypes;

    public targetQualificationCodes: EntityModel[] = [];
    public visitEvents: SelectOption[] = [
        { value: "isEms", text: "Atvežė GMP brigada" },
        { value: "isByOrder", text: "Siuntimas" },
    ];
    public namesSPI: EntityModel[] = [];

    public formGroup: FormGroup = this.fb.group({
        filterByVisitData: this.fb.control(true),

        pregnancy: this.fb.control(null),
        status: this.fb.control(null),
        docType: this.fb.control(null),
        targetQualificationCodes: this.fb.control(null),
        targetQualificationCodes1: this.fb.control(null),

        dateFrom: this.fb.control(null),
        dateTo: this.fb.control(null),
        nameSPI: this.fb.control(null),
        nameSPI1: this.fb.control(null),
        visitStatus: this.fb.control(null),
        visitReason: this.fb.control(null),
    });

    public suggestionsOrg$?: any;
    public suggestionsQualification$?: any;
    public organizationArray: OrganizationModel[];
    public noOrganisationResult = false;
    public noQualificationResult = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly fb: FormBuilder,
        private apiV2Entities: ApiV2Entities,
        private apiV2Organizations: ApiV2Organizations,
        private translatePipe: TranslatePipe
    ) {}

    ngOnInit(): void {
        this.getOrganizationsList();
        this.getQualificationCodesList();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public clearFilters(): void {
        this.formGroup.reset();
        this.formGroup.get("filterByVisitData").patchValue(true);
        this.filtering.emit(this.formGroup.value);
    }

    public applyFilters(): void {
        this.filtering.emit(this.formGroup.value);
    }

    public getOrganizationsList() {
        this.suggestionsOrg$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("nameSPI1").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.getOrganizations(query).pipe(
                        map((newDisplay: OrganizationModel[]) => {
                            return newDisplay.map((org) => ({
                                ...org,
                                nameAndJarCode:
                                    org.name +
                                    ", JAR kodas " +
                                    org.jarCode +
                                    ", SVEIDRA Nr. " +
                                    org.sveidraId +
                                    " " +
                                    org.registrationAddress.text,
                            }));
                        })
                    );
                }
                return of([]);
            })
        );
    }

    public getQualificationCodesList() {
        this.suggestionsQualification$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("targetQualificationCodes1").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.getQualificationCodes(query).pipe(
                        map((newDisplay: EntityModel[]) => {
                            return newDisplay.map((org) => ({
                                ...org,
                            }));
                        })
                    );
                }
                return of([]);
            })
        );
    }

    public getOrganizations(query): Observable<OrganizationModel[]> {
        return this.apiV2Organizations
            .getOrganizations(query, 10, "ROOT_ONLY")
            .pipe(map((organization: OrganizationModel[]) => (this.organizationArray = organization)));
    }

    public getQualificationCodes(name): Observable<EntityModel[]> {
        const queryParams = { searchCriteria: name };

        return this.apiV2Entities
            .getEntitiesList("qualification-code", queryParams)
            .pipe(map((entity: EntityModel[]) => (this.targetQualificationCodes = entity)));
    }

    public typeaheadNoResults(event: boolean, org: boolean): void {
        if (org) {
            this.noOrganisationResult = event;
        } else {
            this.noQualificationResult = event;
        }
    }

    public typeaheadOnSelect(e: TypeaheadMatch, org: boolean): void {
        if (org) {
            this.formGroup.controls["namesSPI"].setValue(e.item.id);
        } else {
            this.formGroup.controls["targetQualificationCodes"].setValue(e.item.id);
        }
    }
}
