<div class="row mx-auto" *ngIf="!customDisplaying && specialistsControl?.length">
    <div class="row align-items-center mb-1 px-0" *ngFor="let control of specialistsControl; let i = index">
        <span class="w-auto">{{control.value.givenName}} {{control.value.familyName}}, {{getSpecialistQualifications(control.value)}}, {{control.value.personalCode}}</span>
        <div class="mt-2 flex-shrink-1 w-auto" *ngIf="editable">
            <button class="btn btn-block text-primary btn-sm fs-4" (click)="deleteSpecialist(i)"><i class="fa fa-trash"></i>&nbsp;{{'doc.dia.dlt' | translate}}</button>
        </div>
    </div>
</div>
<app-error-message *ngIf="formArray.errors && !disable && editable && controlWasTouched" [errorMessage]="getErrorMessage()"/>

<div class="input-group" *ngIf="editable">
    <input
        [formControl]="searchValue"
        [typeahead]="suggestions$"
        [typeaheadAsync]="true"
        [typeaheadItemTemplate]="customItemTemplate"
        [typeaheadMinLength]="0"
        [typeaheadOptionsLimit]="10"
        [typeaheadWaitMs]="200"
        typeaheadOptionField="fullDisplay"
        (typeaheadOnSelect)="selectSpecialist($event)"
        class="form-control h-[38px]"
        (typeaheadOnBlur)="controlWasTouched = true"
    />
    <ng-template #customItemTemplate let-model="item">
        <span>{{model.givenName}} {{model.familyName}}, {{getSpecialistQualifications(model)}}, {{model.personalCode}}</span>
    </ng-template>
    <button class='btn btn-outline-primary btn-sm ms-2' [disabled]="!selectedSpecialist" (click)="addSpecialistToList()">+&nbsp;Pridėti</button>
</div>