angular.module('medvais.api').factory('MedvaisPatientApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/patient';
    
    return $resource('', {}, {
        
        query: {
            method: 'GET',
            url: URL_BASE + '/query',
            isArray: true
        },
        
        getById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        }
        
    });
    
    
}]);

