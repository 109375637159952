import { Component, DestroyRef } from "@angular/core";
import { RouteParams } from "../../../../../ng1.routeParams";
import {
    DpAspnContextService,
    PatientActivityAndHomeEnvironmentalAssessment
} from "../../../services/dp-aspn-context.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import moment from "moment";

@Component({
    selector: "aspn-activity-home-environment-assessment",
    templateUrl: "./home-environment-assessment.component.html",
})
export class HomeEnvironmentAssessmentComponent {
    patientId: string;
    formId: string;
    backUrl: string
    viewMode: boolean;
    subTitle: string | string[];
    loadedData: PatientActivityAndHomeEnvironmentalAssessment;

    constructor(
        private readonly params: RouteParams,
        private readonly dpAspnContextService: DpAspnContextService,
        private readonly destroyRef: DestroyRef,
    ) {
        this.getPatientId();
        this.loadDataForViewEdit();
        this.viewMode = window.location.href.includes('/view');
    }

    getPatientId(){
        this.patientId = this.params.patientId;
        this.formId = this.params.id;
        this.backUrl = '/dp/patients/' + this.patientId;
    }

    private loadDataForViewEdit():  void {
        if (!this.patientId || !this.formId) {
            this.subTitle = ['doc.new'];
            return;
        }
        this.dpAspnContextService.getPatientActivityAndHomeEnvironmentalAssessment(this.patientId, this.formId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => {
                this.loadedData = res.data;
                this.subTitle = [moment(res.date).format('YYYY-MM-DD HH:mm'), 'doc.aspn.assessment.viewStatus', res.doctor];
            });
    }
}
