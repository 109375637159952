import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { ConfigService } from "../app/config/config.service";
import { DocumentCaptionModel, NGNCaptionModel } from "../app/doctor/ngn/components/shared/models/shared.model";
import { DocumentCaptionMock } from "../app/doctor/ngn/mocks/document-caption.mock";
import {
    fetusUltrasoundExaminationDataMock,
    fetusUltrasoundExaminationListDataMock,
} from "../app/doctor/ngn/mocks/fetus-ultrasound-examination.mock";
import { generalPregnancyInfoMock } from "../app/doctor/ngn/mocks/general-pregnancy-info.mock";
import { NGNCaptionMock } from "../app/doctor/ngn/mocks/ngn-caption.mock";
// import { NGNDocumentsMock } from "../app/doctor/ngn/mocks/ngn-documents.mock";
import { outpatientVisitDescriptionDataMock } from "../app/doctor/ngn/mocks/outpatient-visit-description.mock";
import { proceduresDuringPregnancyDataMock } from "../app/doctor/ngn/mocks/procedures-during-pregnancy.mock";
import {
    FetusUltrasoundListModel,
    FetusUltrasoundModel,
} from "../app/doctor/ngn/models/fetusUltrasoundExamination.model";
import { FirstNewbornCheckupModel } from "../app/doctor/ngn/models/firstNewbornCheckup.model";
import { OutpatientVisitDescriptionModel } from "../app/doctor/ngn/models/outpatientVisitDescription.model";
import {
    FirstNewbornCheckupClassifiers,
    ParentalConsentClassifiers,
} from "../app/doctor/ngn/models/pregnancyDashboard.model";
import { PregnancyTerminationModel } from "../app/doctor/ngn/models/pregnancyTermination.model";
import { PregnancyTerminationInfoModel } from "../app/doctor/ngn/models/pregnancyTerminationInfo.model";
import { ProceduresDuringPregnancyModel } from "../app/doctor/ngn/models/proceduresDuringPregnancy.model";
import { RiskFactorsModel } from "../app/doctor/ngn/models/riskFactors.model";
import { UterineGrowthCurve } from "../app/doctor/ngn/models/uterineGrowthCurve";
@Injectable({
    providedIn: "root",
})
export class ApiV2PregnancyDashboard extends ConfigService {
    path = this.apiUrl + "/doc-pregnancy-dashboard";

    getFirstNewbornCheckupClassifiers() {
        return firstValueFrom(this.http.get<FirstNewbornCheckupClassifiers>(this.apiUrl + "/ngn/npa/classifiers"));
    }

    getParentalConsentClassifiers() {
        return firstValueFrom(this.http.get<ParentalConsentClassifiers>(this.apiUrl + "/ngn/ts/classifiers"));
    }

    getFirstNewbornCheckup(id: string): Observable<FirstNewbornCheckupModel> {
        const url = `${this.path}/first-newborn-checkup/view/${id}`;
        return this.http.get<FirstNewbornCheckupModel>(url);
    }

    saveFirstNewbornCheckup(payload: FirstNewbornCheckupModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/first-newborn-checkup/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitFirstNewbornCheckup(payload: FirstNewbornCheckupModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/first-newborn-checkup/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitFirstNewbornCheckup(payload: FirstNewbornCheckupModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/first-newborn-checkup/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmFirstNewbornCheckup(payload: FirstNewbornCheckupModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/first-newborn-checkup/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signFirstNewbornCheckup(payload: FirstNewbornCheckupModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/first-newborn-checkup/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getRiskFactors(id: string): Observable<RiskFactorsModel> {
        const url = `${this.path}/risk-factors/view/${id}`;
        return this.http.get<RiskFactorsModel>(url);
    }

    saveRiskFactors(payload: RiskFactorsModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));

        localStorage.setItem("riskFactors", JSON.stringify(payload));
        const url = `${this.path}/risk-factors/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitRiskFactors(payload: RiskFactorsModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/risk-factors/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitRiskFactors(payload: RiskFactorsModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/risk-factors/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmRiskFactors(payload: RiskFactorsModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/risk-factors/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signRiskFactors(payload: RiskFactorsModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/risk-factors/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getPregnancyTerminationInfo(id: string): Observable<PregnancyTerminationInfoModel> {
        // const url = `${this.path}/pregnancy-termination-info/view/${id}`;
        // return this.http.get<PregnancyTerminationInfoModel>(url);

        return of(generalPregnancyInfoMock);
    }

    savePregnancyTerminationInfo(payload: PregnancyTerminationInfoModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));

        localStorage.setItem("pregnancyTerminationInfo", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination-info/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitPregnancyTerminationInfo(payload: PregnancyTerminationInfoModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination-info/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitPregnancyTerminationInfo(payload: PregnancyTerminationInfoModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination-info/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmPregnancyTerminationInfo(payload: PregnancyTerminationInfoModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination-info/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signPregnancyTerminationInfo(payload: PregnancyTerminationInfoModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination-info/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getPregnancyTermination(id: string): Observable<PregnancyTerminationModel> {
        const url = `${this.path}/pregnancy-termination/view/${id}`;
        return this.http.get<PregnancyTerminationModel>(url);
    }

    savePregnancyTermination(payload: PregnancyTerminationModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));

        localStorage.setItem("pregnancyTermination", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitPregnancyTermination(payload: PregnancyTerminationModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitPregnancyTermination(payload: PregnancyTerminationModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmPregnancyTermination(payload: PregnancyTerminationModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signPregnancyTermination(payload: PregnancyTerminationModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/pregnancy-termination/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getOutpatientVisitDescription(id: string): Observable<OutpatientVisitDescriptionModel> {
        const url = `${this.path}/outpatient-visit-description/view/${id}`;
        return this.http.get<OutpatientVisitDescriptionModel>(url);
    }

    getOutpatientVisitDescriptions(id: string): Observable<OutpatientVisitDescriptionModel[]> {
        // const url = `${this.path}/outpatient-visit-description/view/${id}/list`;
        // return this.http.get<OutpatientVisitDescriptionModel[]>(url);
        return new Observable((subscriber) => subscriber.next(outpatientVisitDescriptionDataMock));
    }

    saveOutpatientVisitDescription(payload: OutpatientVisitDescriptionModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));

        localStorage.setItem("outpatientVisitDescription", JSON.stringify([payload]));
        const url = `${this.path}/outpatient-visit-description/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitOutpatientVisitDescription(payload: OutpatientVisitDescriptionModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/outpatient-visit-description/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitOutpatientVisitDescription(payload: OutpatientVisitDescriptionModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/outpatient-visit-description/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmOutpatientVisitDescription(payload: OutpatientVisitDescriptionModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/outpatient-visit-description/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signOutpatientVisitDescription(payload: OutpatientVisitDescriptionModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/outpatient-visit-description/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getFetusUltrasoundExamination(id: string): Observable<FetusUltrasoundModel> {
        // const url = `${this.path}/fetus-ultrasound-examination/view/${id}`;
        // return this.http.get<OutpatientVisitDescriptionModel>(url);
        return new Observable((subscriber) => subscriber.next(fetusUltrasoundExaminationDataMock));
    }

    getFetusUltrasoundExaminationList(id: string): Observable<FetusUltrasoundListModel> {
        // const url = `${this.path}/fetus-ultrasound-examination/view/${id}`;
        // return this.http.get<OutpatientVisitDescriptionModel>(url);
        return of(fetusUltrasoundExaminationListDataMock);
    }

    saveFetusUltrasoundExamination(payload: FetusUltrasoundModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));

        const mapedPayload: FetusUltrasoundListModel = {
            fetusUltrasoundExaminationList: [
                ...(payload.firstUltrasoundStage
                    ? [
                          {
                              ...payload.firstUltrasoundStage,
                              ultrasoundStage: payload.ultrasoundStage,
                          },
                      ]
                    : []),
                ...(payload.secondUltrasoundStage
                    ? [
                          {
                              ...payload.secondUltrasoundStage,
                              ultrasoundStage: payload.ultrasoundStage,
                          },
                      ]
                    : []),
                ...(payload.thirdUltrasoundStage
                    ? [
                          {
                              ...payload.thirdUltrasoundStage,
                              ultrasoundStage: payload.ultrasoundStage,
                          },
                      ]
                    : []),
            ],
        };
        localStorage.setItem("fetusUltrasoundExamination", JSON.stringify(mapedPayload));
        const url = `${this.path}/fetus-ultrasound-examination/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitFetusUltrasoundExamination(payload: FetusUltrasoundModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/fetus-ultrasound-examination/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitFetusUltrasoundExamination(payload: FetusUltrasoundModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/fetus-ultrasound-examination/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmFetusUltrasoundExamination(payload: FetusUltrasoundModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/fetus-ultrasound-examination/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signFetusUltrasoundExamination(payload: FetusUltrasoundModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/fetus-ultrasound-examination/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getResearchesAndProceduresDuringPregnancy(id: string): Observable<ProceduresDuringPregnancyModel> {
        // const url = `${this.path}/researches-procedures-during-pregnancy/view/${id}`;
        // return this.http.get<ProceduresDuringPregnancyModel>(url);
        return of(proceduresDuringPregnancyDataMock);
    }

    saveResearchesAndProceduresDuringPregnancy(payload: ProceduresDuringPregnancyModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));

        localStorage.setItem("researchesAndProceduresDuringPregnancy", JSON.stringify(payload));
        const url = `${this.path}/researches-procedures-during-pregnancy/domain/save/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    submitResearchesAndProceduresDuringPregnancy(payload: ProceduresDuringPregnancyModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/researches-procedures-during-pregnancy/domain/submit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signingSubmitResearchesAndProceduresDuringPregnancy(payload: ProceduresDuringPregnancyModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/researches-procedures-during-pregnancy/domain/signingSubmit/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    sealConfirmResearchesAndProceduresDuringPregnancy(payload: ProceduresDuringPregnancyModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/researches-procedures-during-pregnancy/domain/sealConfirm/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    signResearchesAndProceduresDuringPregnancy(payload: ProceduresDuringPregnancyModel): Observable<any> {
        console.log("duomenysJSON: ", JSON.stringify(payload));
        const url = `${this.path}/researches-procedures-during-pregnancy/domain/sign/${payload.id}`;
        return this.http.post<any>(url, payload);
    }

    getUterineGrowthCurveData(patientId: string): Observable<UterineGrowthCurve[]> {
        const url = `${this.path}/uterine-growth-curve/${patientId}`;
        return this.http.get<UterineGrowthCurve[]>(url);
    }

    getDocumentCaptionData(id: string): Observable<DocumentCaptionModel> {
        // const url = `${this.path}/document-caption/view/${id}`;
        // return this.http.get<DocumentCaptionModel>(url);

        return new Observable((subscriber) => subscriber.next(DocumentCaptionMock));
    }

    getNGNCaption(id: string): Observable<NGNCaptionModel> {
        // const url = `${this.path}/ngn-caption/view/${id}`;
        // return this.http.get<NGNCaptionModel>(url);

        return of(NGNCaptionMock);
    }

    // getNGNDocuments(patientId: string, query?: NgnDocumentFilters): Observable<NgnDocument[]> {
    //     console.log("getNGNDocuments query", query);

    //     // const url = `${this.path}/ngn-documents/list`;
    //     // return this.http.post<NgnDocument[]>(url, {filters, patientId});

    //     return new Observable((subscriber) => subscriber.next(NGNDocumentsMock));
    // }
}
