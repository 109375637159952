/* global angular, app, _ */
ng1App.controller('doctor.patient.ObservationsCtrl', 
    ['$scope', '$routeParams', 'DocumentsApiFactory', 'EncountersApiFactory', 'SearchController', 'MessageService', 'DpSessionContext',
     function ($scope, $routeParams, DocumentsApiFactory, EncountersApiFactory, SearchController, MessageService, DpSessionContext) {

    $scope.$emit('setHelpPage', 'perzireti-paciento-siuntimu-sarasa');
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    $scope.encounters = {};
    $scope.orderRespDocuments = {};
    $scope.filters = [
        {title: 'dpp.obs.rec.all', query: {}},
        {title: 'dpp.obs.rec.act', query: {status: 'active'}},
        {title: 'dpp.obs.rec.porc', query: {status: 'inProgress'}},
        {title: 'dpp.obs.rec.fin', query: {status: 'completed'}},
        {title: 'dpp.obs.rec.nota', query: {status: 'inactive'}},
        {title: 'dpp.obs.rec.cons', query: {docType: 'e027', orderType: '1'}},
        {title: 'dpp.obs.rec.vac', query: {docType: 'e027', orderType: '4'}},
        {title: 'dpp.obs.rec.lab', query: {docType: 'e027', orderType: '2'}},
        {title: 'dpp.obs.rec.labOrder', query: {docType: 'e200'}},
        {title: 'dpp.obs.rec.pat', query: {docType: 'e014'}}
    ];
    $scope.searchCtrl = new SearchController({
        viewId: 'DPP_OBSERVATIONS',
        dialogTpl: '/templates/portal/doctor/patient/observations/search.html',
        count: 10,
        initScope: function (scope) {
            scope.docTypes = [
                {code: 'e027', name: 'E027 Medicinos dokumentų išrašas / siuntimas'},
                {code: 'e200', name: 'E200 Laboratorinio tyrimo užsakymas'},
                {code: 'e014', name: 'E014 Patologijos tyrimo užsakymas'}
            ];
            scope.docStates = [
                {code: 'active', name: 'dpp.obs.rec.act'},
                {code: 'inProgress', name: 'dpp.obs.rec.porc'},
                {code: 'completed', name: 'dpp.obs.rec.fin'},
                {code: 'inactive', name: 'dpp.obs.rec.nota'}
            ];
            scope.checkAll = function (source, target) {
                angular.forEach(source, function (val) {
                    target[val.code] = true;
                });
            };
            scope.uncheckAll = function (target) {
                angular.forEach(target, function (val, key) {
                    target[key] = false;
                });
            };
        },
        initForm: function () {
            $scope.encounters = {};
            return {
                filter: $scope.filters[0],
                docTypes: {},
                docStates: {},
                specialist: [],
                organization: [],
                diagnosis: [],
                medication: [],
                professionQualification: [],
                orderBy:'date'
            };
        },
        searchApi: DocumentsApiFactory.orderDocuments,
        showPageCounter: true,
        formToQuery: function (form) {
            var getCodes = function (target) {
                var res = [];
                angular.forEach(target, function (val, key) {
                    if (val === true) {
                        res.push(key);
                    }
                });
                return res;
            };

            var query: any = {
                patientId: $routeParams.patientId,
                orderBy: form.orderBy
            };

            switch (form._searchType) {
                case 'quick':
                    form.filter = undefined;
                    query.ftq = form.ftq;
                    break;
                case 'filter':
                    angular.extend(query, form.filter.query);
                    break;
                case 'advanced':
                    form.ftq = undefined;
                    form.filter = undefined;
                    query.docType = getCodes(form.docTypes);
                query.status = getCodes(form.docStates);
                query.dateFrom = form.dateFrom;
                query.dateTo = form.dateTo;
                    query.practitionerId = _.map(form.specialist, function (o) {
                        return o.id;
                    });
                    query.organizationId = _.map(form.organization, function (o) {
                        return o.id;
                    });
                    break;
            }

            return query;
        }
    });

    $scope.searchCtrl.handleResult = function (result) {
        getRespDocuments(result.items);
        SearchController.prototype.handleResult.call(this, result);
    };

    $scope.searchCtrl.handleSearch = function (append) {
        if(!append){
            $scope.orderRespDocuments = {};
        }
        SearchController.prototype.handleSearch.call(this, append);
    };


    $scope.changeFilter = function (filter) {
        $scope.searchCtrl.clear();
        $scope.searchCtrl._form.filter = filter;
        $scope.searchCtrl.searchFilter();
    };

    $scope.handlers = {
        getEncounter: function (ref) {
            if (ref) {
                return $scope.encounters[ref.split('/')[1]];
            }
            return null;
        },
        getOrderRespDocuments: function (id) {
            return $scope.orderRespDocuments[id];
        }
    };

    angular.forEach($scope.filters, function (filter) {
        filter.query.patientId = $routeParams.patientId;

        if (filter.query.docType) {
            DocumentsApiFactory.orderDocumentCount(filter.query, function (data) {
                filter.count = data.val;
            });
        }
    });

    DocumentsApiFactory.orderDocumentCountsByStatus({patientId: $routeParams.patientId}, function (data) {
        $scope.filters[0].count = data.all;
        angular.forEach($scope.filters, function (filter) {
            if (filter.query.status) {
                filter.count = data[filter.query.status];
            }
        });
    });

    DpSessionContext.getSettings(function (data) {
        $scope.searchCtrl.setCount(data.count_per_page);
        $scope.searchCtrl.searchLastOrAll();
    });

    var getRespDocuments = function (items) {
        var orderIds = [];
        var temp = {};
        _.each(items, function (item) {
            _.each(item.orderRefs, function (ref) {
                orderIds.push(ref.split('/')[1]);
            });
        });
        if (orderIds.length > 0) {
            DocumentsApiFactory.getOrderResponseDocumentsByOrder({orderId: orderIds}, function (res) {
                var orderIdsFiltered = orderIds;
                _.each(res, function (item) {
                    var id = item.orderRefs[0].split('/')[1];
                    temp[id] = item;
                    orderIdsFiltered = _.without(orderIdsFiltered, id);
                });
                _.each(items, function (item) {
                    _.each(item.orderRefs, function (ref) {
                        var respDoc = temp[ref.split('/')[1]];
                        if (respDoc) {
                            if (!$scope.orderRespDocuments[item.id]) {
                                $scope.orderRespDocuments[item.id] = [];
                            }
                            $scope.orderRespDocuments[item.id].push(respDoc);
                        }
                    });
                });
                if (orderIdsFiltered.length > 0) {
                    EncountersApiFactory.getByOrders({orderId: orderIdsFiltered}, function (encs) {
                        _.each(encs, function (e) {
                            $scope.encounters[e.orderRef.split('/')[1]] = e;
                        });
                    });
                }
            });
        }
    };
}]);