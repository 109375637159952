/* global angular, app */
ng1App.controller("doctor.user.RecordsCtrl", [
    "$scope",
    "$routeParams",
    "ErxPaths",
    "DocumentsApiFactory",
    "MessageService",
    "FormLoaderService",
    "SearchController",
    function ($scope, $routeParams, ErxPaths, DocumentsApiFactory, MessageService, FormLoaderService, SearchController) {
        MessageService.clearAll();
        FormLoaderService.startFormLoading();

        $scope.$emit("setHelpPage", "perzireti-specialisto-sukurtu-irasu-sarasa");

        $scope.searchCtrl = new SearchController({
            viewId: "dp-user-records",
            dialogTpl: "/templates/portal/doctor/user/records/search.html",
            initScope: function (scope) {
                scope.docStatuses = [
                    { code: "IN_PROGRESS", name: "doc.in_progress" },
                    { code: "COMPLETED", name: "doc.completed" },
                    { code: "CANCELED_SIGNED", name: "doc.entered_in_error" },
                    { code: "CANCELED_UNSIGNED", name: "doc.entered_in_error_unsigned" },
                    { code: "INACTIVE", name: "doc.inactive" },
                    { code: "AMENDED_SIGNED", name: "doc.amended" },
                    { code: "AMENDED_UNSIGNED", name: "doc.amended_unsigned" },
                    { code: "PRELIMINARY", name: "doc.preliminary" },
                    { code: "FINAL_UNSIGNED", name: "doc.final" },
                    { code: "FINAL_SIGNED", name: "doc.signed" },
                ];
                scope.docStatusesCheckAll = function (fromList, toList, property) {
                    angular.forEach(fromList, function (item) {
                        toList[item[property]] = true;
                    });
                };
                scope.docStatusesUncheckAll = function (list) {
                    angular.forEach(list, function (val, key) {
                        list[key] = false;
                    });
                };
            },
            initForm: function () {
                return {
                    docStatuses: {},
                };
            },
            searchApi: function (queryObj, onSuccess, onFailure) {
                return DocumentsApiFactory.searchForListWithTotals(queryObj, onSuccess, onFailure);
            },
            showPageCounter: true,
            formToQuery: function (form) {
                var toCode = function (entity) {
                    return entity.code;
                };
                var toLabel = function (entity) {
                    return entity.label;
                };
                var query: any = {
                    forCurrentUser: true,
                };
                if (form.signed != "") {
                    query.signed = form.signed;
                }
                switch (form._searchType) {
                    case "quick":
                        query.ftq = form.ftq;
                        break;
                    case "filter":
                        angular.extend(query, form.filter.query);
                        break;
                    case "advanced":
                    default:
                        query.dateFrom = form.dateFrom;
                        query.dateTo = form.dateTo;
                        query.morphologicalCodes = _.map(form.morphological, toCode);
                        query.researchCodes = _.map(form.research, toCode);
                        query.diagnosisCodes = _.map(form.diagnosis, toCode);
                        query.targetQualificationCodes = _.map(form.qualification, toCode);
                        query.medications = _.map(form.medication, toLabel);
                        query.patientQuery = form.patientQuery;
                        var status = [];
                        _.forEach(form.docStatuses, function (value, key) {
                            if (value) {
                                status.push(key);
                            }
                        });
                        query.status = status.length > 0 ? status : undefined;
                        break;
                }

                return query;
            },
        });

        $scope.filters = [
            { title: "dp.my.rec.all", query: { docType: null, forCurrentUser: true } },
            { title: "dp.my.rec.amb", query: { docType: "e025", forCurrentUser: true } },
            { title: "dp.my.rec.patIn", query: { docType: "e003", forCurrentUser: true } },
            { title: "dp.my.rec.ref", query: { docType: "e027", messages: false, forCurrentUser: true } },
            { title: "dp.my.rec.ansToRef", query: { docType: "e027a", forCurrentUser: true } },
            { title: "dp.my.rec.ord", query: { docType: ["e200", "e014"], forCurrentUser: true } },
            { title: "dp.my.rec.res", query: { docType: ["e200a", "e014a"], forCurrentUser: true } },
            { title: "dp.my.rec.vac", query: { docType: "e063", forCurrentUser: true } },
            { title: "dp.my.rec.ext", query: { docType: "e027", messages: true, forCurrentUser: true } },
            {
                title: "dp.my.rec.cert",
                query: {
                    docType: [
                        "e027_1_I",
                        "e027_1_II",
                        "e103_1",
                        "e083_1",
                        "e049",
                        "e106",
                        "e048_employer",
                        "e048_practitioner",
                        "e047_employer",
                        "e047_practitioner",
                        "e106_2_1",
                    ],
                    forCurrentUser: true,
                },
            },
            { title: "dp.my.rec.erec", query: { docType: ["erec01"], forCurrentUser: true } },
            { title: "dp.my.rec.evai", query: { docType: ["evai01"], forCurrentUser: true } },
            { title: "dp.my.rec.e027va", query: { docType: ["e027va"], forCurrentUser: true } },
        ];

        angular.forEach($scope.filters, function (f) {
            DocumentsApiFactory.getDocumentCount(f.query, function (data) {
                f.count = data.value;
            });
        });

        if ($routeParams.filterType && $scope.filters.find(obj => { return obj.title === $routeParams.filterType })) {
            $scope.searchCtrl._form._searchType = 'filter';
            $scope.searchCtrl._form.filter = $scope.filters.find(obj => { return obj.title === $routeParams.filterType });
        } else {
            $scope.searchCtrl._form._searchType = 'all';
        }

        $scope.searchCtrl.searchLastOrAll();

        $scope.changeFilter = function (filter) {
            var signed = $scope.searchCtrl._form.signed;
            $scope.searchCtrl.clear();
            $scope.searchCtrl._form.filter = filter;
            if (signed != undefined) {
                $scope.searchCtrl._form.signed = signed;
            }
            $scope.searchCtrl.searchFilter();
        };

        $scope.$on('changeFilter', function (event, filterType) {
            $scope.changeFilter($scope.filters.find(obj => { return obj.title === filterType }));
        });

        $scope.onSignedChange = function (newVal, oldVal) {
            if (oldVal == newVal) {
                return;
            }
            $scope.searchCtrl.searchLastOrAll();
        };

        $scope.updatePagination = function (pagination) {
            if (pagination?.page) {
                $scope.searchCtrl.setPage(pagination.page);
                $scope.searchCtrl.handleSearch(false);
            }
        };
    },
]);
