<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Cpo.procedureForm.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row req">
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Cpo.procedureForm.abdominalWallIncised' | translate}}</legend>
                <ng-container *ngFor="let item of abdominalWallIncisedOptions; let i = index">
                    <app-radio-button
                        id="abdominalWallIncised{{i}}"
                        radioName="abdominalWallIncised{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('abdominalWallIncised')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('abdominalWallIncised')" />
            </fieldset>
            <div class="col-6 col-lg-3">
                <span class="col-form-label mt-3 fst-italic norq">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.hemostasisPerformed' | translate}}
                </span>
            </div>
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Cpo.procedureForm.bladder' | translate}}</legend>
                <ng-container *ngFor="let item of bladderOptions; let i = index">
                    <app-radio-button
                        id="bladder{{i}}"
                        radioName="bladder{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('bladder')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('bladder')" />
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Cpo.procedureForm.uterusIsIncised' | translate}}</legend>
                <ng-container *ngFor="let item of uterusIsIncisedOptions; let i = index">
                    <app-radio-button
                        id="uterusIsIncised{{i}}"
                        radioName="uterusIsIncised{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('uterusIsIncised')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('uterusIsIncised')" />
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Cpo.procedureForm.placeOfUterineIncision' | translate}}</legend>
                <ng-container *ngFor="let item of placeOfUterineIncisionOptions; let i = index">
                    <app-radio-button
                        id="placeOfUterineIncision{{i}}"
                        radioName="placeOfUterineIncision{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('placeOfUterineIncision')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('placeOfUterineIncision')" />
            </fieldset>
            <div class="col-6 col-lg-3">
                <span class="col-form-label mt-3 fst-italic norq">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.incisionIsWidened' | translate}}
                </span>
            </div>
            <app-input
                type="textarea"
                class="form-group col-6 col-lg-3"
                labelText="doc.pregnancyDashboard.e096Cpo.procedureForm.otherFeatures"
                labelClass="norq"
                [fControl]="formGroup.get('otherFeatures')"
            ></app-input>
            <div class="col-6 col-lg-3">
                <div class="col-form-label mt-3 fst-italic norq pre-wrap">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.placentalPeriodActiveText' | translate}}
                </div>
            </div>
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Cpo.procedureForm.placentaStatus' | translate}}:</legend>
                <ng-container *ngFor="let item of placentaStatusOptions; let i = index">
                    <app-radio-button
                        id="placentaStatus{{i}}"
                        radioName="placentaStatus{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('placentaStatus')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('placentaStatus')" />
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3 norq">{{'doc.pregnancyDashboard.e096Cpo.procedureForm.attachment' | translate}}</legend>
                <ng-container *ngFor="let item of attachmentOptions; let i = index">
                    <app-checkbox
                        class="mt-1"
                        formClass="mb-0"
                        [inputId]="'attachment-' + i"
                        [labelText]="item.name"
                        [checkboxDisabled]="!(editable)"
                        [checkboxChecked]="checkboxIsChecked(item.name, 'attachments')"
                        (checkboxChange)="updateControl(item.name, 'attachments')"
                    ></app-checkbox>
                </ng-container>
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.examinationOfPlacentaIsOrdered' | translate}}:</legend>
                <ng-container *ngFor="let item of yesNoOptions; let i = index">
                    <app-radio-button
                        id="examinationIsOrdered{{i}}"
                        radioName="examinationIsOrdered{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('examinationOfPlacentaIsOrdered')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('examinationOfPlacentaIsOrdered')" />
            </fieldset>

            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.uterineMuscleSutured' | translate}}
                    <span class="fw-light fst-italic">
                        {{'doc.pregnancyDashboard.e096Cpo.procedureForm.uterineMuscleSuturedDesc' | translate}}
                    </span>
                </legend>
                <ng-container *ngFor="let item of uterineMuscleSuturedOptions; let i = index">
                    <app-radio-button
                        id="uterineMuscleSutured{{i}}"
                        radioName="uterineMuscleSutured{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('uterineMuscleSutured')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('uterineMuscleSutured')" />
            </fieldset>

            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.abdominalOrgans' | translate}}:
                </legend>
                <ng-container *ngFor="let item of abdominalOrgansOptions; let i = index">
                    <app-radio-button
                        id="abdominalOrgans{{i}}"
                        radioName="abdominalOrgans{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('abdominalOrgans')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('abdominalOrgans')" />
            </fieldset>
            <div class="form-group col-6 col-lg-9">
                <ng-container *ngIf="getCntrlValue('abdominalOrgans') === 'Pakitę'">
                    <app-input
                        type="textarea"
                        labelText="{{'doc.pregnancyDashboard.e096Cpo.procedureForm.alteredOrgansDescriptions' | translate}}:"
                        [fControl]="formGroup.get('alteredOrgansDescriptions')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('alteredOrgansDescriptions')" />
                </ng-container>
            </div>
            <div class="col-6 col-lg-3">
                <div class="col-form-label mt-3 fst-italic norq">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.abdominalWallIsSutured' | translate}}
                </div>
            </div>
            <div class="col-6 col-lg-3">
                <fieldset>
                    <legend class="float-none col-form-label mt-3">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.lostBloodAmount' | translate}}:</legend>
                    <ng-container *ngFor="let item of answersVariants?.['lostBloodAmount']; let i = index">
                        <app-radio-button
                            id="lostBloodAmount{{i}}"
                            radioName="lostBloodAmount{{ i }}"
                            [labelText]="item.name"
                            [radioValue]="item.name"
                            [fControl]="formGroup.get('lostBloodAmount')"
                        />
                    </ng-container>
                    <app-form-control-validation [fControl]="formGroup.get('lostBloodAmount')" />
                </fieldset>
                <ng-container *ngIf="getCntrlValue('lostBloodAmount') === 'daugiau kaip 1000 ml'">
                    <app-input
                        class="mt-1"
                        type="text"
                        units="ml"
                        [fControl]="formGroup.get('lostBloodAmountDetail')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('lostBloodAmountDetail')" />
                </ng-container>
            </div>

            <fieldset class="col-6 col-lg-3">
                <legend class="float-none col-form-label mt-3">
                    {{'doc.pregnancyDashboard.e096Cpo.procedureForm.urineThroughCatheter' | translate}}
                </legend>
                <ng-container *ngFor="let item of urineThroughCatheterOptions; let i = index">
                    <app-radio-button
                        id="urineThroughCatheter{{i}}"
                        radioName="urineThroughCatheter{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('urineThroughCatheter')"
                    />
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('urineThroughCatheter')" />
            </fieldset>
            <fieldset class="col-6 col-lg-3" *ngIf="getCntrlValue('urineThroughCatheter') === 'Teka'">
                <legend class="float-none col-form-label mt-3">Pro Foley kateterį šlapimas:</legend>
                <ng-container *ngFor="let item of answersVariants?.['urinaryState']; let i = index">
                    <app-checkbox
                        class="mt-1"
                        formClass="mb-0"
                        [id]="'urinaryState-' + i"
                        [labelText]="item.name"
                        [checkboxDisabled]="!(editable)"
                        [checkboxChecked]="checkboxIsChecked(item.name, 'urinaryState')"
                        (checkboxChange)="updateControl(item.name, 'urinaryState')"
                    ></app-checkbox>
                </ng-container>
                <app-form-control-validation [fControl]="formGroup.get('urinaryState')" />
            </fieldset>
            <app-input
                type="textarea"
                class="form-group col-12"
                labelText="doc.pregnancyDashboard.e096Cpo.procedureForm.atypicalMoments"
                labelClass="norq"
                [fControl]="formGroup.get('atypicalMoments')"
            ></app-input>
        </div>
    </div>
</nhr-record-widget>