ng1App.controller('doctor.patient.DocumentsHistoryCtrl',
    ['$scope', '$routeParams', 'DocumentCtx', 'MessageService', 'DocumentsApiFactory',
     function ($scope, $routeParams, DocumentCtx, MessageService, DocumentsApiFactory) {
    $scope.$emit('setHelpPage', 'perzireti-elektronini-dokumenta');

    $scope.goBack = function () {
        window.history.back();
    };

    $scope._loadDocument = function () {
        DocumentCtx.reset();
        DocumentCtx.setPatientId($routeParams.patientId);
        DocumentCtx.setDocumentId($routeParams.documentId);

        $scope.documentCancelReason = {};
        $scope.cancelErr = 0;

        MessageService.clearAll();

        $scope.form = DocumentsApiFactory.getForHistoryPreview({compositionId: $routeParams.documentId, historyId: $routeParams.historyId}, function (d) {
            DocumentCtx.setDocument(d);
            $scope.prefillData = d;
            $scope.patientData = d.metadata.patient;
            $scope.organization = d.metadata.custodian;
            $scope.practitioner = d.metadata.practitioner;
            $scope.encounterData = d.metadata.encounter;
            DocumentsApiFactory.documentHistory({compositionId: $routeParams.documentId}, function (data) {
                angular.forEach(data.items, function (i) {
                    if(i.historyId == $routeParams.historyId){
                        $scope.itemType = i.type;
                    }
                });
            });
        });
    };

    $scope._loadDocument();
    $scope.docCtx = DocumentCtx;

}]);
