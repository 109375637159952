import medvaisComponentsModule from "./medvais-components";
import './modal/medvais-modal-cancel-doc';
import './modal/medvais-modal-order-telemedicine';
import './modal/medvais-modal-info';
import './modal/medvais-modal-add-related-study';
import './list/medvais-btn-load-more';
import './modal/medvais-modal-create-telemedicine-encounter';
import './modal/medvais-modal-patient-identification';
import './modal/medvais-modal-send-study';
import './common/medvais-drop-down';
import './common/medvais-doc-status';
import './preloader/medvais-preloaders';

export default medvaisComponentsModule;