import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "visit-edit-dialog",
    templateUrl: "./visitEditDialog.component.html",
})
export class VisitEditDialog implements OnInit {

    @Output() navigateToVisit: EventEmitter<any> = new EventEmitter();

    visitId?: number;
    periodVisitId?: number;

    constructor(
        public modalService: BsModalService,
    ) { }

    ngOnInit(): void {
    }

    action(id: number) {
        //this.modalService.hide();
        this.navigateToVisit.emit(id);
    }

}
