angular.module('erx.api').factory('ErxDispenseApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/MedicationDispense';
  return $resource('', {id: '@id'}, {
    // Išdavimo gavimas pagal Id
    getById: {
      method: 'GET',
      url: URL_BASE + '/:id',
    },
    get: {
      method: 'GET',
      url: URL_BASE
    },
    getDocuments: {
      method: 'GET',
      url: URL_BASE + '/documents'
    },
    // Susiję dokumentai
    getRelated: {
      method: 'GET',
      url: URL_BASE + '/:id/related',
      isArray: true
    }
  });
}]);
