/* global angular, app */

ng1App.controller('doctor.patient.EditSummaryCtrl',
        ['$scope', '$q', '$routeParams', 'PatientsApiFactory', 'EntitiesApiFactory', 'SubFormValidator', '$modalConfirm', '$location', 'MessageService', '$filter',
            'FormLoaderService', 'PatientSummaryApiFactory', 'DraftsApiFactory', 'PatientInsuranceService',
        function ($scope, $q, $routeParams, PatientsApiFactory, EntitiesApiFactory, SubFormValidator, $modalConfirm, $location, MessageService, $filter,
                FormLoaderService, PatientSummaryApiFactory, DraftsApiFactory, PatientInsuranceService) {
            $scope.$emit('setHelpPage', 'atnaujinti-suvestine');
            $scope.isDraft = $routeParams.draftId ? true : false;
            // Side content list
            $scope._shared = {
                // jus fill array with something
                contentList: [
                    {title: $filter('translate')('dpp.pat.sum.oth'), id: 'd pp_pat_sum_oth'},
                    {title: $filter('translate')('dpp.pat.sum.pat'), id: 'dpp_ pat_sum_pat'},
                    {title: $filter('translate')('dpp.pat.sum.rep'), id: 'dpp_pat _sum_rep',
                        show: function () {
                            if ($scope.summary.relatedPersons) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.dis'), id: 'dpp_pat_s um_dis',
                        show: function () {
                            if ($scope.summary.disabilityData) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.pre'), id: 'dpp_pat_sum _pre'},
                    {title: $filter('translate')('dpp.pat.sum.soc'), id: 'dpp_pat_sum_so c'},
                    {title: $filter('translate')('cer.e083.ext'), id: 'cer_e083_ext'},
                    {title: $filter('translate')('dpp.pat.sum.all'), id: 'dpp_pat_sum_all',
                        show: function () {
                            if ($scope.summary.allergies) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.nreg.all'), id: 'dpp_pat_sum_nreg_all'},
                    {title: $filter('translate')('dpp.pat.sum.dia.new'), id: 'dpp_pat_sum_dia_new',
                        show: function () {
                            if ($scope.summary.currentConditions) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.nreg.dia'), id: 'dpp_pat_sum_nreg_dia'},
                    {title: $filter('translate')('dpp.pat.sum.dia.old'), id: 'dpp_pat_sum_dia_old',
                        show: function () {
                            if ($scope.summary.lastConditions) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.nreg.las.con'), id: 'dpp_pat_sum_nreg_las_con'},
                    {title: $filter('translate')('dpp.pat.sum.sur.new'), id: 'dpp_pat_sum_sur_new',
                        show: function () {
                            if ($scope.summary.proceduresIn6Months) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.sur.old'), id: 'dpp_pat_sum_sur_old',
                        show: function () {
                            if ($scope.summary.proceduresBefore6Months) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.equ'), id: 'dpp_pat_sum_equ'},
                    {title: $filter('translate')('dpp.pat.sum.phy'), id: 'dpp_pat_sum_phy'},
                    {title: $filter('translate')('dpp.pat.sum.rec'), id: 'dpp_pat_sum_rec',
                        show: function () {
                            if ($scope.summary.recomendation) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.sum.vac'), id: 'dpp_pat_sum_vac',
                        show: function () {
                            if ($scope.summary.vaccinations) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    },
                    {title: $filter('translate')('dpp.pat.notr.vacc'), id: 'dpp_pat_notr_vacc'},
                    {title: $filter('translate')('dpp.pat.sum.vac.med'), id: 'dpp_pat_sum_vac_med'}
                ]
            };
            MessageService.clear();
            MessageService.clear('bad-habits');
            MessageService.clear('implants');
            $scope.patientId = $routeParams.patientId;
            $scope._spinner_loading = false;
            FormLoaderService.startFormLoading();

            if ($scope.isDraft) {
                $scope.summary = PatientSummaryApiFactory.getDraftForEdit({patientId: $routeParams.patientId, draftId: $routeParams.draftId});
            } else {
                $scope.summary = PatientSummaryApiFactory.getSummary({patientId: $scope.patientId, generateNew: true}, function () {
                    $scope.checkInsurance();
                });
            }

            $scope.summary.$promise.finally(function () {
                FormLoaderService.endFormLoading();
            });
            $scope.checkInsurance = function () {
                $scope.insuranceLoading = true;
                PatientInsuranceService.getInsurance($scope.summary.patient.id, true)
                    .then(function (data) {
                        if (!$scope.summary.encounter) {
                            $scope.summary.encounter = {insuranceData: {}};
                        }
                        $scope.summary.encounter.insuranceData.insurancePSD = data;
                    })
                    .finally(function(){
                        $scope.insuranceLoading = false;
                    });
            };
// bad habits code start
            var isHabitEditMode = false,
                    habitIndex = -1;
            var newHabit = function () {
                return {};
            };
            var validateBadHabit = function (bh) {
                $scope.bhValidator.clear();
                if (!bh.type) {
                    $scope.bhValidator.add('type');
                }
                if (!bh.validFrom) {
                    $scope.bhValidator.add('period');
                }
                if (!bh.validFrom && !bh.actualNow) {
                    $scope.bhValidator.add('period');
                }

                if (bh.validFrom && bh.validTo && _.truncateToDays(bh.validFrom) > _.truncateToDays(bh.validTo)) {
                    $scope.bhValidator.add('period');
                }

                return !$scope.bhValidator.hasErrors();
            };
            $scope.bhValidator = new SubFormValidator('bad-habits');
            $scope.addictions = EntitiesApiFactory.getList({classCode: 'addiction'});
            $scope.bloodGroups = EntitiesApiFactory.getList({classCode: 'blood-group'});
            $q.all([$scope.summary.$promise, $scope.bloodGroups.$promise]).then(function () {
                if ($scope.summary.bloodGroup) {
                    _.each($scope.bloodGroups, function (i) {
                        if (i.code === $scope.summary.bloodGroup.code) {
                            $scope.summary.bloodGroup = i;
                        }
                    });
                }
            });
            $scope.badHabit = newHabit();
            $scope.isHabitEditMode = function () {
                return isHabitEditMode;
            };
            $scope.actualNowChange = function () {
                if ($scope.badHabit.actualNow) {
                    $scope.badHabit.validTo = undefined;
                }
            };
            $scope.addHabit = function () {
                if (validateBadHabit($scope.badHabit)) {
                    $scope.summary.badHabits.push($scope.badHabit);
                    $scope.badHabit = newHabit();
                } else {
                    $scope.bhValidator.showErrorsAndScroll();
                }
            };
            $scope.saveHabit = function () {
                if (validateBadHabit($scope.badHabit)) {
                    $scope.summary.badHabits[habitIndex] = $scope.badHabit;
                    $scope.badHabit = newHabit();
                    isHabitEditMode = false;
                } else {
                    $scope.bhValidator.showErrorsAndScroll();
                }
            };
            $scope.cancelEditHabit = function () {
                isHabitEditMode = false;
                $scope.badHabit = newHabit();
            };
            $scope.editHabit = function (index) {
                habitIndex = index;
                isHabitEditMode = true;
                $scope.badHabit = angular.copy($scope.summary.badHabits[index]);
                _.each($scope.addictions, function (a) {
                    if (a.code == $scope.badHabit.type.code) {
                        $scope.badHabit.type = a;
                    }
                });
                $scope.badHabit.actualNow = !$scope.badHabit.validTo;
            };
            $scope.deleteHabit = function (index) {
                $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                    $scope.summary.badHabits.splice(index, 1);
                });
            };
// bad habits code end

            // implants code start
            var isImplantEditMode = false,
                    implantIndex = -1;
            const newImplant = function () {
                return {};
            };
            const validateImplant = function (impl) {
                $scope.implValidator.clear();
                if (!impl.validFrom) {
                    $scope.implValidator.add('validForm');
                }
                if (!impl.name && !impl.serial) {
                    $scope.implValidator.add('name');
                    $scope.implValidator.add('serial');
                }

                return !$scope.implValidator.hasErrors();
            };
            $scope.implValidator = new SubFormValidator('implants');
            $scope.implant = newImplant();
            $scope.isImplantEditMode = function () {
                return isImplantEditMode;
            };
            $scope.addImplant = function () {
                if (validateImplant($scope.implant)) {
                    $scope.summary.implants.push(angular.copy($scope.implant));
                    delete $scope.implant;
                    $scope.implant = newImplant();
                } else {
                    $scope.implValidator.showErrorsAndScroll();
                }
            };
            $scope.saveImplant = function () {
                if (validateImplant($scope.implant)) {
                    $scope.summary.implants[implantIndex] = $scope.implant;
                    $scope.implant = newImplant();
                    isImplantEditMode = false;
                } else {
                    $scope.implValidator.showErrorsAndScroll();
                }
            };
            $scope.cancelEditImplant = function () {
                isImplantEditMode = false;
                $scope.implant = newImplant();
            };
            $scope.editImplant = function (index) {
                implantIndex = index;
                isImplantEditMode = true;
                $scope.implant = angular.copy($scope.summary.implants[index]);
            };
            $scope.deleteImplant = function (index) {
                $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                    $scope.summary.implants.splice(index, 1);
                });
            };
// implants code code end

            $scope.relatedPerson = function (ref) {
                return $scope.summary.relatedPersons[ref];
            };
            $scope.partialPath = function (file) {
                return '/templates/portal/doctor/patient/patient_summary/partials/' + file;
            };
            $scope.partialPathCommon = function (file) {
                return '/templates/portal/common/patient_info/partials/summary/' + file;
            };
            $scope.cancel = function () {
                $modalConfirm.show({content: 'Ar tikrai norite atšaukti paciento suvestinės atnaujinimą?'}).then(function () {
                    $location.path('/dp/patients/' + $scope.patientId + '/summary');
                });
            };
            var validateForm = function (mainForm, callbackIfValid) {
                MessageService.clearAll();
                if (mainForm.$valid && callbackIfValid) {
                    callbackIfValid();
                } else {
                    $('.ng-invalid').addClass('border border-danger');
                    MessageService.add('error', 'Formoje yra klaidų');
                    _.scrollToFirstInvalid();
                }
            };
            $scope.save = function () {
                validateForm($scope.summaryForm, function () {
                    $scope.saving = true;
                    PatientSummaryApiFactory.saveSummary({patientId: $routeParams.patientId, draftId: $routeParams.draftId}, $scope.summary, function (data) {
                        if (data.value === 'success') {
                            $location.path('/dp/patients/' + $scope.patientId + '/summary');
                        } else if (data.value === 'newerVersionFound') {
                            if ($scope.isDraft) {
                                $modalConfirm.show({
                                    content: 'Suvestinė atnaujino kitas specialistas. Juodraštis bus pašalintas.'
                                }).then(function () {
                                    DraftsApiFactory.invalidateDraft({id: $routeParams.draftId});
                                    $location.path('/dp/patients/' + $scope.patientId + '/summary');
                                });
                            } else {
                                MessageService.error('Buvo pateikta nauja paciento suvestinė');
                            }
                        }
                    }, function (resp) {
                        angular.forEach(resp.data.globalErrors, function (item) {
                            MessageService.add('error', item.msg);
                        });
                    }).$promise.finally(function () {
                        $scope.saving = false;
                    });
                });
            };
            $scope.saveAsDraft = function () {
                var query = {patientId: $routeParams.patientId, draftId: $routeParams.draftId};
                var onsuccess = function (data) {
                    $location.path('/dp/patients/' + $scope.patientId + '/summary/draft/' + data.value);
                };
                var onfail = function (resp) {
                    angular.forEach(resp.data.globalErrors, function (item) {
                        MessageService.add('error', item.msg);
                    });
                };
                $scope.savingDraft = true;

                var promise;

                if ($scope.isDraft) {
                    promise = PatientSummaryApiFactory.updateDraft(query, $scope.summary, onsuccess, onfail).$promise;
                } else {
                    promise = PatientSummaryApiFactory.saveDraft(query, $scope.summary, onsuccess, onfail).$promise;
                }

                promise.finally(function () {
                    $scope.savingDraft = false;
                });
            };
        }]);