import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";
import {PractitionerModel} from "../app/shared/models/practitioner.model";
import {RepresentativeModel} from "../app/shared/models/representative.model";
import {DoctorUserModel} from "../app/shared/models/doctor-user.model";
import {SessionData} from "../app/doctor/models/session-data";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Session extends ConfigService {
    path = this.apiUrl + '/session';

    getSysdate(): Observable<any> {
        const url = `${this.path}/sysdate`;
        return this.http.get<any>(url);
    }

    getOrganization(): Observable<any> {
        const url = `${this.path}/organization`;
        return this.http.get<any>(url);
    }

    getPractitioner(): Observable<PractitionerModel> {
        const url = `${this.path}/practitioner`;
        return this.http.get<PractitionerModel>(url);
    }

    getPatient(): Observable<any> {
        const url = `${this.path}/patient`;
        return this.http.get<any>(url);
    }

    getCurrent(): Observable<DoctorUserModel | any> {
        const url = `${this.path}/current`;
        return this.http.get<DoctorUserModel | any>(url);
    }

    getSession(): Observable<SessionData> {
        const url = `${this.path}/data`;
        return this.http.get<SessionData>(url);
    }

    getToken(): Observable<any> {
        const url = `${this.path}/token`;
        return this.http.get<any>(url);
    }

    getRepresentative(): Observable<RepresentativeModel> {
        const url = `${this.path}/representative`;
        return this.http.get<any>(url);
    }

    putAagaSgas(aagaSgas: any): Observable<any> {
        const url = `${this.path}/aagasgas/:aagaSgas`;
        return this.http.put<any>(url, aagaSgas);
    }

    getAagaSgas(): Observable<any> {
        const url = `${this.path}/aagasgas`;
        return this.http.get<any>(url);
    }
}
