import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AssessmentOfHealthFactorsSummaryModel} from "../../../models/assessment-of-health-factors-summary.model";

@Component({
    selector: 'assessment-of-health-factors-summary',
    templateUrl: './assessment-of-health-factors-summary.component.html'
})
export class AssessmentOfHealthFactorsSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() assessmentOfHealthFactors: AssessmentOfHealthFactorsSummaryModel[] = [];
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]);
    }
}