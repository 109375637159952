ng1App.factory('TypeaheadSearchHandlers',
    ['$filter', 'EntitiesApiFactory', 'OrganizationsApiFactory', 'PractitionersApiFactory', 'AddressesApiFactory', 'MedicationsApiFactory', 'PatientsApiFactory',
    function ($filter, EntitiesApiFactory, OrganizationsApiFactory, PractitionersApiFactory, AddressesApiFactory, MedicationsApiFactory, PatientsApiFactory) {
    var checkInput = function(value, minLength?){
       return  value && !angular.isObject(value) && value.length >= (minLength ? minLength : 2);
    };
    var orderItems = function(items, labelHandler, hideCode?, maxLength?){
        var max = -1;
        _.each(items, function(item){
            var len = labelHandler(item, hideCode, maxLength) ? labelHandler(item, hideCode, maxLength).length : undefined;
            if(len && len > max){
                max = len;
            }
        });
        var pad = "";
        var i = 0;
        for (; i<max; i++){
            pad += " ";
        }
        var results = $filter('orderBy')(items, function(item) {
            return labelHandler(item, hideCode, maxLength);
            //return (pad + labelHandler(item, hideCode, maxLength)).slice(-pad.length);
        });
        return results;
    };
    var handlers = {
        entities_label: function(v, hideCode?, maxLength?){
            var label = v ? (hideCode ? v.name : (v.code + ' ' + v.name)) : null;
            return label && maxLength? (label.substring(0, 90)+'...'): label;
        },
        entities: function(classCode, value, hideCode?, maxLength?){
            if(!checkInput(value)){
                return [];
            }
            return EntitiesApiFactory.getList({classCode: classCode, searchCriteria:value}).$promise.then(
                function(response){
                    var results = orderItems(response, handlers.entities_label, hideCode, maxLength);
                    return _.map(results, function (v) {
                        v.label =  handlers.entities_label(v, hideCode, maxLength);
                        return v;
                    });
                });
        },
        TOP_ICD10: function () {
            return EntitiesApiFactory.topDiagnosis({ count: 10}).$promise.then(
                function(response){
                    var results = orderItems(response, handlers.entities_label);
                    return _.map(results, function (v) {
                            v.label = handlers.entities_label(v);
                            return v;
                        });
                });
        },
        ICD10: function (value) {
            if (value && value.length > 1) {
                return handlers.entities('tlk-10-am', value);
            } else if (!value || value.length === 0) {
                return handlers.TOP_ICD10();
            }
        },        
        ICD0: function(value){
            return handlers.entities('icd-o-3',value);
        },
        ACHI_PROCEDURAL: function(value){
            return handlers.entities('achi',value);
        },
        MATERIALL_TYPE: function(value){
            return handlers.entities('specimen-type', value, true);
        },
        RESEARCH_TYPE: function(value){
            return handlers.entities('diagnostic-type', value, true);
        },
        ES_AUTORITY: function(value){
            return handlers.entities('es-authority', value);
        },
        PAT_PROCEDURES: function (value) {
            return handlers.entities('procedure-code', value, false, 90);
        },
        COUNTRIES: function (value) {
            return handlers.entities('country', value, true);
        },
        VACCINATION_REACTIONS: function (value) {
            return handlers.entities('response-to-vaccination', value);
        },
        INSURANCE_DOCUMENT_TYPES: function (value) {
            return handlers.entities('insurance-doc-type', value, true);
        },
        ORG_DEPARTMENTS_label: function(v){
            return (v.name + (v.sveidraId ? ', SVEIDRA nr. ' + v.sveidraId : ''));
        },
        PROFESSIONS: function(value){
            return handlers.entities('qualification-code', value, true);
        },
        PROFESSIONS_MEDICAL: function(value){
            return handlers.entities('qualification-code-medical', value, true);
        },
        ORGANIZATIONS_label: function (v) {
            v.label = (v.name + (v.jarCode ? ', JAR: ' + v.jarCode : '') + (v.sveidraId ? ', SVEIDRA nr. ' + v.sveidraId : ''));
            return v;
        },
        PRACTITIONERS_label: function (v) {
            v.label = v.givenName + ' ' + v.familyName + (v.stampCode ? ' - ' + v.stampCode : '');
            return v;
        },
        PRACTITIONERS_QLF_label: function (v) {
            v.label = v.givenName + ' ' + v.familyName + (v.speciality && v.speciality.name ? ', ' + v.speciality.name : '');
            return v;
        },
        PRACTITIONERS_ORG_label: function (v) {
            v.label = v.givenName + ' ' + v.familyName + (v.stampCode ? ' - ' + v.stampCode : '') + ", " + v.practitionerOrganization.name;
            return v;
        },
        PATIENTS_label: function (v) {
        	v.label = v.givenName + ' ' + v.familyName + (v.personalCode ? ', ' + v.personalCode : '');
        	return v;
        },
        PATIENTS_BD_label: function (v) {
        	v.label = v.givenName + ' ' + v.familyName + (v.birthDate ? ', ' + $filter('date')(v.birthDate, 'yyyy-MM-dd') : '');
        	return v;
        },
        ORG_DEPARTMENTS: function(value, jarCode){
            if (!checkInput(value)){
                return [];
            }
            return OrganizationsApiFactory.get({name: value, jarCode: jarCode, count: 10, hierarchyType: 'DEPARTMENTS_ONLY'}).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORG_DEPARTMENTS_label);
                    return _.map(results, function (v) {
                        v.label = handlers.ORG_DEPARTMENTS_label(v);
                        return v;
                    });
                });
        },
        /*
            only root organizations in JAR code scope and search params
        */
        ORGANIZATIONS_SEARCH: function(value, searchParams){
            if (!checkInput(value)){
                return [];
            }
            var params = {q: value+'*',  count: 10, hierarchyType:'ROOT_ONLY'};
            params = Object.assign(params, searchParams);
            return OrganizationsApiFactory.get(params).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORGANIZATIONS_label);
                    return _.map(results, function (v) {
                        return handlers.ORGANIZATIONS_label(v);
                    });
                });
        },
        ORGANIZATIONS_ALL_ACTIVE: function(value){
            if (!checkInput(value)){
                return [];
            }
            return OrganizationsApiFactory.get({q: value+'*',  count: 10, hierarchyType:'ALL', activeOnly: true}).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORGANIZATIONS_label);
                    return _.map(results, function (v) {
                        return handlers.ORGANIZATIONS_label(v);
                    });
                });
        },
        /*
	        only root organizations in JAR code scope
        */
        ORGANIZATIONS: function(value){
            return handlers.ORGANIZATIONS_SEARCH(value, {});
        },
        /*
            only root organizations in JAR code scope by jarCode
        */
        ORGANIZATIONS_BY_JAR: function(value){
            if (!checkInput(value)){
                return [];
            }
            return OrganizationsApiFactory.get({jarCode: value,  count: 10, hierarchyType:'ROOT_ONLY'}).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORGANIZATIONS_label);
                    return _.map(results, function (v) {
                        return handlers.ORGANIZATIONS_label(v);
                    });
                });
        },
        /*
            only organizations suitable as workplace
        */
        WORKPLACES: function(value){
            if (!checkInput(value)){
                return [];
            }
            return OrganizationsApiFactory.getForWorkplace({q: value+'*'}).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORGANIZATIONS_label);
                    return _.map(results, function (v) {
                        return handlers.ORGANIZATIONS_label(v);
                    });
                });
        },
        /*
            only organizations suitable as workplace by jarCode
        */
        WORKPLACES_BY_JAR: function(value){
            if (!checkInput(value)){
                return [];
            }
            return OrganizationsApiFactory.getForWorkplaceByJar({jarCode: value}).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORGANIZATIONS_label);
                    return _.map(results, function (v) {
                        return handlers.ORGANIZATIONS_label(v);
                    });
                });
        },
        /*
            all organizations
        */
        ALL_ORGANIZATIONS: function(value){
            if (!checkInput(value)){
                return [];
            }
            return OrganizationsApiFactory.get({q: value,  count: 10}).$promise.then(
                function(response){
                    var results = orderItems(response.items, handlers.ORGANIZATIONS_label);
                    return _.map(results, function (v) {
                         v.label = handlers.ORG_DEPARTMENTS_label(v);
                        return v;
                    });
                });
        },
        PRACTITIONERS: function (value, organizationId, jarCode, labelHandler, roleCode?, state?) {
            if (!checkInput(value)){
                return [];
            }
            return PractitionersApiFactory.find({
                q: value,
                organization: organizationId,
                jarCode: jarCode,
                count: 10,
                role: roleCode,
                state: state
            }).$promise.then(
                function (response) {
                	if (!labelHandler) {
                		labelHandler = handlers.PRACTITIONERS_label;
                	}
                    var results = orderItems(response.items, labelHandler);
                    return _.map(results, function (v) {
                        return labelHandler(v);
                    });
                });
        },
        PRACTITIONERS_QLF: function (value, organizationId, jarCode) {
            if (!checkInput(value)){
                return [];
            }
            return PractitionersApiFactory.find({
                q: value,
                organization: organizationId,
                jarCode: jarCode,
                count: 10
            }).$promise.then(
                function (response) {
                    var results = orderItems(response.items, handlers.PRACTITIONERS_QLF_label);
                    return _.map(results, function (v) {
                        return handlers.PRACTITIONERS_QLF_label(v);
                    });
                });
        },
        PRACTITIONERS_ORG: function (value, organizationId, jarCode) {
            return handlers.PRACTITIONERS(value, organizationId, jarCode, handlers.PRACTITIONERS_ORG_label);
        },
        DRIVING_LIMITATIONS: function (value) {
        	return handlers.entities('driving-limitation', value);
        },
        HARMFUL_FACTOR: function (value) {
        	return handlers.entities('harmful-factor', value);
        },	
        QUALIFICATION_CODE: function (value) {
        	return handlers.entities('qualification-code', value);
        },
        ADDRESSES_label: function (v) {
            v.label = v.text;
            return v;
        },
        ADDRESSES: function (value) {
        	if (!checkInput(value, 3)) {
        		return [];
        	}
        	return AddressesApiFactory.getLocations({searchQuery: value, count: 10}).$promise.then(function (result) {
                return _.map(result, function (location) {
        			return handlers.ADDRESSES_label(location);
        		});
        	});
        },
        MEDICATION_NAMES: function (name, diseaseId) {
            if (!checkInput(name, 3)) {
                return [];
            }
            var query = {
                name: name,
                disease: diseaseId
            };
            return MedicationsApiFactory.searchProductNames(query).$promise.then(function (data) {
                return data;
            });
        },
        PATIENTS: function (value) {
            if (!checkInput(value)){
                return [];
            }
            return PatientsApiFactory.search({
            	q: value
            }).$promise.then(
                function (response) {
                    var results = orderItems(response.items, handlers.PATIENTS_label);
                    return _.map(results, function (v) {
                        return handlers.PATIENTS_label(v);
                    });
                });
        },
        PATIENTS_BD: function (value) {
            if (!checkInput(value)){
                return [];
            }
            return PatientsApiFactory.search({
            	q: value
            }).$promise.then(
                function (response) {
                    var results = orderItems(response.items, handlers.PATIENTS_BD_label);
                    return _.map(results, function (v) {
                        return handlers.PATIENTS_BD_label(v);
                    });
                });
        },
        HEALTH_SERVICE: function (value) {
        	return handlers.entities('health-service', value);
        },
        CP_MEDICATION: function (value) {
        	if (!checkInput(value)) {
        		return [];
        	}
        	return EntitiesApiFactory.getList({classCode: 'cp-medication', searchCriteria: value}).$promise.then(function (response) {
                var labelHandler = function(item){
                    return item.medicationId + ' ' + item.genericTitle;
                };
                var results = orderItems(response, labelHandler);
                return _.map(results, function (v) {
                    v.label = labelHandler(v);
                    return v;
                });
        	});
        },
        MR_SERVICE_TYPE: function (value) {
        	return handlers.entities('medical-rehabilitation-services-type', value);
        },
        MR_PROFILE: function (value) {
        	return handlers.entities('medical-rehabilitation-profile', value);
        }
        };
    return handlers;
}]);