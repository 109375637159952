<nhr-record-widget
    [closed]="false"
    [title]="'inr.whomPersonForm.title' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <div *ngIf="isPatient && editable" class="mb-4">
            <div class="row mb-3 req">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.whomPersonForm.suspectedAdverseReaction' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-radio-button
                            *ngFor="let option of suspectedAdverseReactionOptions; let i = index"
                            radioName="suspectedAdverseReaction-{{i}}"
                            [fControl]="patientFormGroup.get('suspectedAdverseReaction')"
                            [radioValue]="option.name"
                            id="suspectedAdverseReaction-{{i}}"
                            class="w-100"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="patientFormGroup.get('suspectedAdverseReaction')" />
                    </div>
                </div>
            </div>
            <div class="row mb-3 req" *ngIf="patientFormGroup.get('suspectedAdverseReaction')?.value === 'Mano atstovaujamam asmeniui'">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'Pasirinkite jūsų atstovaujamą asmenį' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div>
                        <app-select
                            [id]="'personYouRepresent'"
                            [fControl]="patientFormGroup.get('personYouRepresent')"
                            [values]="personYouRepresentList"
                            (onChange)="selectPatient()"
                        ></app-select>
                        <app-form-control-validation [fControl]="formGroup.get('personYouRepresent')" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.doctorInformingForm.name' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                {{controlValue('fullName') ?? '-'}}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'dp.usr.rep.pat.age' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                {{controlValue('age') ?? '-'}}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'ap.cla.new.sex' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                {{controlValue('gender') ?? '-'}}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'dpp.pat.sum.phy.wei' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    type="number"
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('weight')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('weight')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'dpp.pat.sum.phy.hei' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    type="number"
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('height')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('height')" />
            </div>
        </div>
        <div class="row mb-3" *ngIf="editable">
            <strong>
                Pastaba. Pateikti asmens duomenys yra konfidencialūs ir bus naudojami tik su farmakologiniu budrumu susijusiems uždaviniams spręsti ir pareigoms vykdyti, vaistinių preparatų saugumui stebėti bei jų naudos ir rizikos santykio pokyčiams nustatyti.
            </strong>
        </div>
        <div *ngIf="isPatient && editable" class="row mb-3">
            <div>
                <strong>
                    Informuojame, kad Jūsų nurodytų asmens duomenų, įskaitant sveikatos duomenis, valdytoja – Valstybinė vaistų
                    kontrolės tarnyba prie Lietuvos Respublikos sveikatos apsaugos ministerijos (juridinio asmens kodas
                    191351864, adresas: Studentų g. 45A, LT-08107,Vilnius, tel. 85 263 92 64, el. p. vvkt@vvkt.lt):
                </strong>
                <ul class="fw-bold">
                    <li>
                        asmens duomenų, įskaitant sveikatos duomenis, tvarkymo tikslas: spręsti uždavinius ir vykdyti
                        pareigas susijusias su farmakologiniu budrumu, stebėti vaistinių preparatų saugumą bei jų naudos ir
                        rizikos santykio pokyčiams nustatyti;
                    </li>
                    <li>
                        asmens duomenys, įskaitant sveikatos duomenis, tvarkomi vadovaujantis: Bendrojo duomenų apsaugos
                        reglamento (toliau – BDAR) 6 straipsnio 1 dalies 1 dalies c punktu, t. y. siekiant įvykdyti teisinę
                        prievolę;
                    </li>
                    <li>
                        Tarnyba asmens duomenų, įskaitant sveikatos duomenis, saugojimo terminą nustato vadovaudamasi
                        Lietuvos Respublikos dokumentų ir archyvų įstatymo nustatyta tvarka. Asmens duomenų saugojimo terminas
                        nurodytas Tarnybos patvirtintame dokumentacijos plane ir paskelbtas čia
                        https://www.vvkt.lt/index.php?4004286486. Pasibaigus nustatytam saugojimo terminui, duomenys sunaikinami
                        Lietuvos Respublikos dokumentų ir archyvų įstatymo nustatyta tvarka;
                    </li>
                    <li>
                        asmens duomenys – paciento vardas, pavardė ir/ar asmens kodas gali būti pateikti asmens sveikatos
                        priežiūros įstaigoms ir Higienos institutui, siekiant gauti pranešimus apie įtariamas nepageidaujamas
                        reakcijas, priskirtas prie sunkius ir (ar) netikėtus padarinius sukėlusių nepageidaujamų reakcijų,
                        papildančią informaciją ir asmens duomenis, įskaitant sveikatos duomenis.
                    </li>
                </ul>
                <strong>Kreipdamiesi raštu Jūs turite teisę prašyti:</strong>
                <ul class="fw-bold">
                    <li>leisti susipažinti su savo asmens duomenimis, įskaitant sveikatos duomenis;</li>
                    <li>juos ištaisyti arba ištrinti, arba apriboti jų tvarkymą;</li>
                    <li>pateikti skundą Valstybinei duomenų apsaugos inspekcijai (L. Sapiegos g. 17, 10312 Vilnius,
                        ada@ada.lt).</li>
                </ul>
                <strong>
                    Valstybinės vaistų kontrolės tarnybos prie Lietuvos Respublikos sveikatos apsaugos ministerijos asmens
                    duomenų apsaugos pareigūnas, tel. +370 690 58561, el. p. dap@vvkt.lt.
                </strong>
            </div>
        </div>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>