ng1App.factory('DocumentModificationActions',
    ['MessageService', '$location', 'DocumentsApiFactory', 'DocumentCtx', '$routeParams', 'DocumentSpinnerHelper', '$timeout', '$modal', 'DraftsApiFactory', '$modalConfirm', '$filter', 'EncountersApiFactory', 'TemplatesApiFactory', '$rootScope', 'PractitionerQualificationService',
     function (MessageService, $location, DocumentsApiFactory, DocumentCtx, $routeParams, DocumentSpinnerHelper, $timeout, $modal, DraftsApiFactory, $modalConfirm, $filter, EncountersApiFactory, TemplatesApiFactory, $rootScope,
                                                        PractitionerQualificationService) {
    var onSaveSuccess = function (data) {
        DocumentSpinnerHelper.endLoading();
        if (data.compositionId != null) {
/*
            if (data.warnings != null) {
                var documentType = DocumentCtx.getDocumentType();
                var msg;
                if (documentType === 'e025') {
                    msg = 'Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F025/a-LK kortelės. AAGA numeris nebuvo priskirtas šiam dokumentui.';
                } else {
                    msg = 'Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F066/a-LK kortelės. SGAS numeris nebuvo priskirtas šiam dokumentui.';
                }
				console.log('Warning: ' + msg + data.warnings[0]);
                MessageService.registerSuccessEventWithWarning(msg);
            } else {
*/
                MessageService.registerSuccessEvent();
//            }
            $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + data.compositionId).search({});
        }
    };

    var onSignSuccess = function (data, hideSuccess?) {
        DocumentSpinnerHelper.endLoading();
        if (data.compositionId != null) {
            if (!hideSuccess) {
/*
                if (data.warnings != null) {
                    var documentType = DocumentCtx.getDocumentType();
                    var msg;
                    if (documentType === 'e025') {
                        msg = 'Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F025/a-LK kortelės. AAGA numeris nebuvo priskirtas šiam dokumentui.';
                    } else {
                        msg = 'Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F066/a-LK kortelės. SGAS numeris nebuvo priskirtas šiam dokumentui.';
                    }
					console.log('Warning: ' + msg + data.warnings[0]);
                    MessageService.registerSuccessEventWithWarning(msg);
                } else {
*/
                    MessageService.registerSuccessEvent();
                // }
            } else {
/*
                if (data.warnings != null) {
                    if (data.warnings[0] == 'F025') {
                        MessageService.registerSuccessEventWithWarning('Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F025/a-LK kortelės. AAGA numeris nebuvo priskirtas šiam dokumentui.');
                    } else {
                        MessageService.registerSuccessEventWithWarning('Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F066/a-LK kortelės. SGAS numeris nebuvo priskirtas šiam dokumentui.');
                    }
                }
*/
            }
            $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + data.compositionId + '/sign').search({});
        }
    };

    var handleErrors = function (data) {
        DocumentSpinnerHelper.endLoading();
        _.handleValidationErrors(data, MessageService, _resolveValidationError);
    };

    var onSaveError = function (res) {
        handleErrors(res.data);
    };


    var _resolveValidationError = function (msg) {
        switch (msg) {
            case 'no_valid_licence_or_position':
            {
                return $filter('translate')('no_valid_licence_or_position');
            }
        }
        return null;
    };

    var hasErrorsSkipRequired = function (htmlDocForm) {
        for (var i in htmlDocForm.$error) {
            if (i != 'required' && htmlDocForm.$error[i] != false) {
                return true;
            }
        }
        return false;
    };

    var modalChangeEncounterOrder = null;
    var modalAddChangeE025 = null;
    var loadTemplateModal = null;
    var confirmSignModal = null;

    var showConfirmSignModal = function (data, callback) {
        var items = _.map(data, function (i) {
            switch (i) {
                case 'certificate#incapacity':
                    return 'iškviesti nedarbingumo pažymėjimo išrašymo funkcionalumo;';
                case 'certificate#pregnancy':
                    return 'iškviesti nėštumo ir gimdymo atostogų pažymėjimo išrašymo funkcionalumo;';
                case 'patOrder':
                    return 'išrašyti E014 medicininį dokumentą pagal patologijos tyrimo skyrimą;';
                case 'labOrder':
                    return 'išrašyti E200 medicininį dokumentą pagal laboratorinio tyrimo skyrimą;';
                case 'obsOrder':
                    return 'išrašyti E027 medicininį dokumentą pagal siuntimo skyrimą;';
                default:
                    return i;
            }
        });
        if (!confirmSignModal) {
            confirmSignModal = $modal({
                scope: $rootScope.$new(),
                title: 'Dėmesio',
                contentTemplate: '/templates/portal/doctor/patient/documents/modal/document_confirm_onFinal.html'
            });
        }
        confirmSignModal.$promise.then(function () {
            var scope = confirmSignModal.$scope;
            scope.items = items;
            scope.confirmed = function () {
                callback();
                scope.$hide();
            };
            confirmSignModal.show();
        });

    };

    return {
        _validateAndSubmit: function (formValidator, htmlDocForm, skipRequired, onSaveInterceptor, submitAction) {
            var submit = function () {
                DocumentSpinnerHelper.startLoading();
                submitAction();
            };
            if (!formValidator) {
                formValidator = this.validateFormSimple;
            }
            if (formValidator(htmlDocForm, skipRequired)) {
                if (onSaveInterceptor) {
                    onSaveInterceptor(submit);
                } else {
                    submit();
                }
            }
        },
        validateFormSimple: function (htmlDocForm, skipRequired) {
            MessageService.clear();
            // function replacer(key, value) {
            //     if (key === '$parent' || key === '$root') { // common properties that can cause circular references in AngularJS
            //         return undefined;
            //     }
            //     if (typeof value === 'object' && value !== null) {
            //         if (seen.has(value)) {
            //             return; // If the value has been seen before, skip it
            //         }
            //         seen.add(value);
            //     }
            //     return value;
            // }

            // const seen = new WeakSet(); // To keep track of seen objects
            // var formStateString = JSON.stringify(htmlDocForm, replacer, 2);
            // console.log("Form Validation State: ", formStateString); DELETE AFTER
            if (htmlDocForm.$valid) {
                return true;
            } else {
                if (skipRequired && !hasErrorsSkipRequired(htmlDocForm)) {
                    return true;
                } else {
                    _.markAllAsTouched(htmlDocForm);
                    MessageService.add('error', 'Formoje yra klaidų');
                    _.scrollToFirstInvalid();
                    return false;
                }
            }
        },
        /**
         * @param htmlDocForm - html form
         * @param formValidator - optional, custom validation function, return true if valid.
         * If customValidator undefined - validateFormSimple is called.
         */
        savePartial: function (id, htmlDocForm, formValidator, onSaveInterceptor, draftId) {
            this._validateAndSubmit(formValidator, htmlDocForm, true, onSaveInterceptor, function () {
                if (id) {
                    DocumentsApiFactory.updatePartial({id: id, docType: DocumentCtx.getDocumentRealType()}, DocumentCtx.document, onSaveSuccess, onSaveError);
                } else {
                    DocumentsApiFactory.createPartial({docType: DocumentCtx.getDocumentRealType(), draftId: draftId}, DocumentCtx.document, onSaveSuccess, onSaveError);
                }
            });
        },
        /**
         * @param htmlDocForm - html form
         * @param formValidator - optional, custom validation function, return true if valid.
         * If customValidator undefined - validateFormSimple is checked.
         */
        saveFinal: function (id, htmlDocForm, formValidator, onSaveInterceptor, draftId, confirmed, doSign) {
            doSign = (doSign === undefined || doSign === null) ? true : doSign;
            console.log(arguments, doSign);
            var self = this;
            var successHandler = function (data) {
                if (doSign) {
                    onSignSuccess(data);
                } else {
                    DocumentSpinnerHelper.endLoading();
                    if (data.compositionId) {
/*
                        if (data.warnings != null) {
                            var msg = 'Šio dokumento pagrindu nepavyko automatiškai sugeneruoti F025/a-LK kortelės. AAGA numeris nebuvo priskirtas šiam dokumentui.';
							console.log('Warning: ' + msg + data.warnings[0]);
                            MessageService.registerSuccessEventWithWarning(msg);
                        } else {
*/
                            MessageService.registerSuccessEvent();
                        // }
                        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + data.compositionId).search({});
                    }
                }
            };
            var errorHandler = function (res) {
                if (res.status == 409 && res.data.error == 'mustConfirm') {
                    DocumentSpinnerHelper.endLoading();
                    showConfirmSignModal(res.data.data, function () {
                        self.saveFinal(id, htmlDocForm, formValidator, onSaveInterceptor, draftId, true);
                    });
                } else {
                    onSaveError(res);
                }
            };
            this._validateAndSubmit(formValidator, htmlDocForm, false, onSaveInterceptor, function () {
                if (id) {
                    DocumentsApiFactory.updateFinal({id: id, docType: DocumentCtx.getDocumentRealType(), confirmed: confirmed}, DocumentCtx.document, successHandler, errorHandler);
                } else {
                    DocumentsApiFactory.createFinal({docType: DocumentCtx.getDocumentRealType(), draftId: draftId, confirmed: confirmed}, DocumentCtx.document, successHandler, errorHandler);
                }
            });

        },
        saveDraft: function (id, htmlDocForm, formValidator, onSaveInterceptor) {
            //console.log("saveDraft 1111111111111111111 function called with id:", id, "and form:", htmlDocForm); //Delete After
            var onDraftPostSuccess = function (data) {
                DocumentSpinnerHelper.endLoading();
                //console.log("Draft saved successfully 222222222222:", data); //Delete After
                console.log(data);
                if (data.draftId != null) {
                    //console.log("Draft = null 44444444444:", data); //Delete After
                    MessageService.registerSuccessEvent();
                    $location.path('/dp/patients/' + $routeParams.patientId + '/documents/drafts/' + data.draftId).search({});
                }
            };
            this._validateAndSubmit(formValidator, htmlDocForm, true, onSaveInterceptor, function () {
                //console.log("Form validation passed, submitting draft...33333333333333333"); //Delete After
                //console.log("DocumentCtx.document:", DocumentCtx.document); //Delete After
                DraftsApiFactory.saveDraft({id: id, docType: DocumentCtx.getDocumentRealType(), patientId: $routeParams.patientId}, DocumentCtx.document, onDraftPostSuccess, onSaveError);
            });
        },
        /**
         * @param htmlDocForm - html form
         * @param formValidator - optional, custom validation function, return true if valid.
         * If customValidator undefined - validateFormSimple is checked.
         */
        amendAndSign: function (id, htmlDocForm, formValidator, onSaveInterceptor) {
            this._validateAndSubmit(formValidator, htmlDocForm, false, onSaveInterceptor, function () {
                DocumentsApiFactory.updateAmended({id: id}, DocumentCtx.document, onSignSuccess, onSaveError);
            });
        },
        makeFinalAndSign: function (document, callback, confirmed, doSign) {
            doSign = (doSign === undefined || doSign === null) ? true : doSign;
            var onSucess = function (data) {
                if (doSign) {
                    onSignSuccess(data, true);
                } else {
                    DocumentSpinnerHelper.endLoading();
                    if (data.compositionId) {
                        MessageService.registerSuccessEvent();
                        $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + data.compositionId).search({});
                    }
                }
                (callback||angular.noop)();
            };
            var self = this;
            var errorHandler = function (res) {
                if (res.status == 400) {
                    DocumentSpinnerHelper.endLoading();
                    MessageService.error("Dokumente yra klaidų");
                } else if (res.status == 409 && res.data.error == 'mustConfirm') {
                    DocumentSpinnerHelper.endLoading();
                    showConfirmSignModal(res.data.data, function () {
                        self.makeFinalAndSign(document, callback, true, doSign);
                    });
                } else {
                    onSaveError(res);
                }
            };
            var metadata = document.metadata;
            if (metadata.status == 'preliminary') {
                DocumentSpinnerHelper.startLoading();
                DocumentsApiFactory.makeFinal({compositionRef: metadata.compositionRef, confirmed: confirmed}, {}, onSucess, errorHandler);
            } else {
                onSucess({compositionId: metadata.compositionId});
            }
        },
        /**
         * @param htmlDocForm - html form
         */
        __log: function (htmlDocForm) {
            DocumentSpinnerHelper.startLoading();
            $timeout(function () {
                DocumentSpinnerHelper.endLoading()
            }, 250);
            console.log("mainDocumentForm: ");
            console.log(htmlDocForm);
            console.log("$valid: " + htmlDocForm.$valid);
            console.log("FORM: ");
            console.log(JSON.stringify(DocumentCtx.document, null, '\t'));
            console.log(DocumentCtx.document);
        },
        checkValidQualification: function (practitioner) {
            PractitionerQualificationService.checkValidOrEquivalentExists(practitioner, _resolveValidationError);
        },
        removeEncounterOrder: function (encounterData, callBack) {
            $modalConfirm.show({title: $filter('translate')('Dėmesio'), content: $filter('translate')('Ar tikrai norite pašalinti siuntimą?')})
                    .then(function () {
                        MessageService.clearAll();
                        EncountersApiFactory.changeOrder({encounterId: encounterData.id}, function (data) {
                            (callBack || angular.noop)(data);
                        });
                    });
        },
        changeEncounterOrder: function (patientId, encounterData, callBack) {
            if (!modalChangeEncounterOrder) {
                modalChangeEncounterOrder = $modal({
                    title: 'Siuntimo pasirinkimas',
                    contentTemplate: '/templates/portal/doctor/patient/documents/modal/change_encounter_order_modal.html'
                });
            }

            modalChangeEncounterOrder.$promise.then(function () {
                var scope = modalChangeEncounterOrder.$scope;
                var page = 1;
                scope.encOrders = [];
                scope.allLoaded = false;
                scope.noOrders = false;
                scope.spinner = DocumentSpinnerHelper;

                scope.order = {};

                scope.loadOrders = function () {
                    var isVirtual = encounterData.event && encounterData.event.code == 'virtual';
                    DocumentSpinnerHelper.startLoading();
                    EncountersApiFactory.getUnusedPatientOrders({patient: patientId, page: page++, isVirtual: isVirtual, excludeOrderTypes: ['message']}, function (orders) {
                        DocumentSpinnerHelper.endLoading();
                        scope.allLoaded = false;
                        scope.noOrders = scope.encOrders.length == 0 && orders.total == 0;
                        angular.forEach(orders.items, function (item) {
                            scope.encOrders.push(item);
                        });
                        if (scope.encOrders.length >= orders.total) {
                            scope.allLoaded = true;
                        }
                    });
                };

                scope.loadMoreOrders = function () {
                    scope.loadOrders();
                };

                scope._changeOrder = function () {
                    EncountersApiFactory.changeOrder({orderRef: scope.order.ref, encounterId: encounterData.id}, function (data) {
                        (callBack || angular.noop)(data);
                        scope.$hide();
                    });
                };

                scope.loadOrders();
                modalChangeEncounterOrder.show();
            });
        },
        removeContinuedTreatmentE025: function ($document_scope) {
            $modalConfirm.show({title: $filter('translate')('Dėmesio'), content: $filter('translate')('Ar tikrai norite pašalinti susijusį E025?')})
                    .then(function () {
                        MessageService.clearAll();
                        MessageService.add('success', 'Duomenys pašalinti');
                        $document_scope.form.continuedTreatmentParentDocId = null;
                        $document_scope.form.continuedTreatmentParentDoc = null;
                    });
        },
        loadTemplateModal: function ($document_scope, $rootScope) {
            if (!loadTemplateModal) {
                loadTemplateModal = $modal({
                    scope: $rootScope.$new(),
                    title: 'Įkelti ruošinio duomenis',
                    contentTemplate: '/templates/portal/doctor/patient/documents/modal/load_template.html'
                });
            }
            loadTemplateModal.$promise.then(function () {
                var _docType = DocumentCtx.getDocumentType();
                if (!_docType) {
                    loadTemplateModal = null;
                    return;
                }
                var modal_scope = loadTemplateModal.$scope;
                var from = 0;
                modal_scope.total = -1;
                modal_scope.data = {};
                modal_scope.spinner = {};
                modal_scope.templates = [];

                var endLoading = function () {
                    modal_scope.spinner.loading = false;
                };

                modal_scope.loadMore = function () {
                    modal_scope.spinner.loading = true;
                    TemplatesApiFactory.findForList({type: _docType, text: modal_scope.data.searchText, from: 5 * from++, count: 5}, function (res) {
                        endLoading();
                        _.each(res.items, function (i) {
                            modal_scope.templates.push(i);
                        });
                        modal_scope.total = res.total;
                    }, endLoading());
                };
                modal_scope.loadMore();

                modal_scope.search = function () {
                    from = 0;
                    modal_scope.total = -1;
                    modal_scope.templates = [];
                    modal_scope.loadMore();
                };

                modal_scope.moreToLoad = function () {
                    return modal_scope.templates.length < modal_scope.total;
                };

                modal_scope.mergeTemplate = function () {
                    modal_scope.spinner.loading = true;
                    $document_scope.form = TemplatesApiFactory.load({id: modal_scope.data.template, type: _docType}, $document_scope.form, function (res) {
                        DocumentCtx.document = res;
                        MessageService.clearAll();
                        MessageService.add('success', 'Duomenys įkelti');
                        endLoading();
                        loadTemplateModal.hide();
                    }, function (res) {
                        endLoading();
                        console.log(res);
                    });
                    _.each($document_scope._formLoadCallbackRegistry, function (c) {
                        $document_scope.form.$promise.then(c[0], c[1], c[2]);
                    });
                };

                loadTemplateModal.show();
            });
        },
        modalForContinuedTreatmentE025: function (patientId, $document_scope, $rootScope) {
            if (!modalAddChangeE025) {
                modalAddChangeE025 = $modal({
                    scope: $rootScope.$new(),
                    title: 'Ankstesnio gydymo forma',
                    contentTemplate: '/templates/portal/doctor/patient/records/modals/document_select.html'
                });
            }
            modalAddChangeE025.$promise.then(function () {
                var page = 0;
                var modal_scope = modalAddChangeE025.$scope;
                modal_scope.docSpinnerHelper = $document_scope.docSpinnerHelper;
                modal_scope.selected = {};
                modal_scope.patientId = patientId;
                modal_scope.documents = {total: -1, items: []};
                modal_scope.loadMore = function () {
                    DocumentsApiFactory.getE025FromFinishedEncounters({'patient': patientId, count: 3, page: ++page, exclude: $document_scope.form.continuedTreatmentParentDocId}, function (res) {
                        modal_scope.documents.total = res.total;
                        _.each(res.items, function (item) {
                            modal_scope.documents.items.push(item);
                        })
                    });
                };
                modal_scope.loadMore();

                modal_scope.submit = function () {
                    $modalConfirm.show({title: $filter('translate')('Dėmesio'), content: $filter('translate')('Bus ištrinti visuose laukuose suvesti duomenys.')})
                            .then(function () {
                                modal_scope.docSpinnerHelper.startLoading();
                                $document_scope.form = DocumentsApiFactory.changeE025ContinuedTreatmentParentDoc({parentDocId: modal_scope.selected.id}, $document_scope.form, function (doc) {
                                    DocumentCtx.document = doc;
                                    MessageService.clearAll();
                                    MessageService.add('success', 'Duomenys įkelti');
                                    modal_scope.docSpinnerHelper.endLoading();
                                    modalAddChangeE025.hide();
                                }, modal_scope.docSpinnerHelper.endLoading);
                                _.each($document_scope._formLoadCallbackRegistry, function (c) {
                                    $document_scope.form.$promise.then(c[0], c[1], c[2]);
                                });
                            });
                };
                modalAddChangeE025.show();
            });
        }
    };

}]);