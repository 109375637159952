import { Component, Input } from '@angular/core';
import moment from 'moment';
import { E010_Row, E010_RowsGroup } from '../../../e010-forms/e010-forms.model';

@Component({
    selector: 'app-e010-1-1-form-newborn',
    templateUrl: './e010-1-1-form-newborn.component.html',
})
export class E01011FormNewbornComponent {
    @Input() set data(value: {[key: string]: any}) {
        if(!value) return;
        value.e096N.fruits.forEach((_, i) => this.generateData(value, i))
    };

    fruitsRows: {
        name?: string;
        style?: 'list' | 'column';
        rows: E010_Row[];
    }[][] = [];

    private diagnoseCache: string[] = [];

    constructor() {}

    private generateData(value: any, i: number) {
        const generalData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.fruitsNumber',
                boldTitle: true,
                value: value.e096N.generalData.numberOfFruits
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.fruitsRaised',
                boldTitle: true,
                value: value.e096N.fruits[i].fruit.charCodeAt(0) - 64
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.dateOfBirth',
                boldTitle: true,
                value: value.e096N.fruits[i].birthTime
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.gender',
                boldTitle: true,
                value: value.e096N.fruits[i].gender
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.weight',
                boldTitle: true,
                value: value.e096N.fruits[i].weight
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.height',
                boldTitle: true,
                value: value.e096N.fruits[i].height
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.headCircumference',
                boldTitle: true,
                value: value.e096N.fruits[i].headCircumference
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.gestationalAge',
                boldTitle: true,
                value: moment(value.e096N.fruits[i].birthTime).diff(moment(value.ngn.firstPregnancyDay), 'weeks')
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.umbilicalCordBlood',
                boldTitle: true,
                value: value.e096N.fruits[i].umbilicalCordBlood1 + '.' + value.e096N.fruits[i].umbilicalCordBlood2
            }
        ];

        if (value.e096N.fruits[i].childData) {
            const childData = [
                {
                    name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.apgar1',
                    boldTitle: true,
                    value: value.e096N.fruits[i].childData.after1min.apgarScore
                },
                {
                    name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.apgar5',
                    boldTitle: true,
                    value: value.e096N.fruits[i].childData.after5min.apgarScore
                },
                {
                    name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.apgar10',
                    boldTitle: true,
                    value: value.e096N.fruits[i].childData.after10min.apgarScore
                },
            ];
            generalData.splice(generalData.length - 1, 0, ...childData);
        }

        const screeningData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.hearingTest',
                boldTitle: true,
                value: value.e097At.find(ver => ver.verificationType === 'Klausos tikrinimas OAE (+/–)')?.verificationStatus === 'Atliktas' ? 'Atlikta' : 'Neatlikta'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.eyeRed',
                boldTitle: true,
                value: value.e097At.find(ver => ver.verificationType === 'Akių dugno raudono reflekso tyrimas (+/–)')?.verificationStatus === 'Atliktas' ? 'Atlikta' : 'Neatlikta'
            },
            {
                // TODO: 5.7.2.3 "Širdis (ribos, ūžesiai, ritmo pobūdis) komentaras"
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.criticalHearthDefects',
                boldTitle: true,
                value: value.e097At.find(ver => ver.verificationType === 'Dėl ĮKŠY SpO2(%)')?.verificationStatus === 'Atliktas' ? 'Atlikta' : 'Neatlikta'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pmal',
                boldTitle: true,
                value: value.e097At.find(ver => ver.verificationType === 'Dėl paveldimų medžiagų apykaitos ligų (PMAL)')?.verificationStatus === 'Atliktas' ? 'Atlikta' : 'Neatlikta'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.bcg',
                boldTitle: true,
                value: value.e063?.order?.item?.vacData?.recommendation?.disease?.name === 'Tuberkuliozė' ? 'Atlikta' : 'Neatlikta'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.hb',
                boldTitle: true,
                value: value.e063?.order?.item?.vacData?.recommendation?.disease?.name === 'Hepatitas B' ? 'Atlikta' : 'Neatlikta'
            },
        ];
        const cprData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.deliveryRoomCPR',
                boldTitle: true,
                value: value.e096N.fruits[i].resuscitation
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.resuscitationDuration',
                boldTitle: true,
                value: value.e096N.fruits[i].resuscitationDurationHours + ':' + value.e096N.fruits[i].resuscitationDurationMinutes
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.CPRperformed',
                boldTitle: true,
                value: value.e096N.fruits[i].resuscitationMethod
            },
        ];
        const mealsOnDepartureData = [
            {
                value: value.e097Gd.diagnosis.mealsOnDeparture
            }
        ];

        const patologyData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology1',
                boldTitle: true,
                value: this.getDiagnosesInRange('P05', 'P08', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology2',
                boldTitle: true,
                value: this.getDiagnosesInRange('P10', 'P15', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology3',
                boldTitle: true,
                value: this.getDiagnosesInRange('P20', 'P21', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology4',
                boldTitle: true,
                value: this.getDiagnosesInRange('P91.6', 'P91.6', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology5',
                boldTitle: true,
                value: this.getDiagnosesInRange('P22', 'P28', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology6',
                boldTitle: true,
                value: this.getDiagnosesInRange('P29', 'P29', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology7',
                boldTitle: true,
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology7a',
                boldTitle: true,
                italicStyle: true,
                value: this.getEarlyNeonatalInfectionDiagnosed(value, i)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology7b',
                boldTitle: true,
                italicStyle: true,
                value: this.getLateNeonatalInfectionDiagnosed(value, i, 1)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology7c',
                boldTitle: true,
                italicStyle: true,
                value: this.getLateNeonatalInfectionDiagnosed(value, i, 2)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology8',
                boldTitle: true,
                value: this.getDiagnosesInRange('P50', 'P61', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology9',
                boldTitle: true,
                value: this.getDiagnosesInRange('P70', 'P74', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology10',
                boldTitle: true,
                value: this.getDiagnosesInRange('P75', 'P78', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology11',
                boldTitle: true,
                value: this.getDiagnosesInRange('P80', 'P80', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology12',
                boldTitle: true,
                value: this.getDiagnosesInRange('P90', 'P91', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology13',
                boldTitle: true,
                value: this.getDiagnosesInRange('P92', 'P96', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology14',
                boldTitle: true,
                value: this.getDiagnosesInRange('Q', 'Q', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhology15',
                boldTitle: true,
                value: this.getDiagnosesInRange('H35.1', 'H35.1', value)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.pathhologyOther',
                boldTitle: true,
                value: this.getOtherDiagnoses(value)
            },
        ];


        const rowsData: E010_RowsGroup[] = [
            {
                rows: generalData
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.newbornScreening',
                rows: screeningData
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.deliveryRoomCPR',
                rows: cprData
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.newbornPathology',
                style: 'list',
                rows: patologyData
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.toFinish',
                style: 'list',
                rows: [{value: this.generateTreatmentOutcome(value.e003.partMedicalData.treatmentOutcome)}]
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formNewborn.departureMeals',
                style: 'list',
                rows: mealsOnDepartureData
            },
        ];
        this.fruitsRows.push(rowsData)
    }

    private generateTreatmentOutcome(value: string): string {
        switch (value) {
            case '4':
                return 'Mirė';
            case '1':
            case '5':
            case '6':
                return 'Perkeltas';
            case '3':
                return 'Išrašytas namo';

        }
    }

    private getDiagnosesInRange(start: string, end: string, data: any): string[] {
        const regexp = /(^[a-zA-Z]+)(\d+(\.\d)?)?/i;
        const startNumber = parseFloat(start.match(regexp)?.[2]);
        const endNumber = parseFloat(end.match(regexp)?.[2]);
        const symbol = start.match(regexp)?.[1].toLowerCase();
        const filterDiagnoses = (arr) => arr.filter((diagnose) => {
            const match = diagnose.code.match(regexp);
            if (!match || match[1].toLowerCase() !== symbol) {
                return false;
            }
            if (symbol && !startNumber) {
                return symbol === match[1].toLowerCase()
            }

            const foundedNumber = parseFloat(match[2]);

            return (startNumber <= foundedNumber && foundedNumber <= endNumber)
        }).map(diagnose => diagnose.code);

        const filteredNpa = filterDiagnoses(data.e097Npa.diagnosis);
        const filteredGd = filterDiagnoses(data.e097Gd.diagnosis.diagnosis);
        const result = [...filteredNpa, ...filteredGd];
        this.diagnoseCache.push(...result);
        if (!result.length) {
            result.push('-');
        }
        return result;
    }

    private getOtherDiagnoses(value: {[key: string]: any}): string[] | string {
        const otherDiagnoses = [...value.e097Npa.diagnosis, ...value.e097Gd.diagnosis.diagnosis]
            .filter(diagnose => !this.diagnoseCache.includes(diagnose.code)).map(diagnose => diagnose.code)

        return otherDiagnoses?.length ? otherDiagnoses : '-';
    }

    private getEarlyNeonatalInfectionDiagnosed(value: {[key: string]: any}, i: number): string[] | string {
        const diffTime = moment(value.e097Gd.generalInspection.performanceDate).diff(moment(value.e096N.fruits[i].birthTime), 'hours');
        if (diffTime <= 72) {
            return this.getDiagnosesInRange('P35', 'P39', value)
        } else {
            return '-';
        }
    }

    private getLateNeonatalInfectionDiagnosed(value: {[key: string]: any}, i: number, period: number): string[] | string {
        const diffTime = moment(value.e097Gd.generalInspection.performanceDate).diff(moment(value.e096N.fruits[i].birthTime), 'hours');
        if (diffTime > 72) {
            const diagnoses = this.getDiagnosesInRange('P35', 'P39', value);
            const uniqArray = _.uniq(diagnoses);
            const duplicatesArray = diagnoses.filter((item, index, array) => array.indexOf(item) !== index);
            const result = period === 1 ? uniqArray : duplicatesArray;
            return result?.length ? result : '-';
        } else {
            return '-';
        }
    }
}
