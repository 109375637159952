<h1 class="mb-4">{{'Slaugos paciento suvestinė'| translate}}</h1>
<back-link [path]="" />
<nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
    [headerLinkText]="'Slaugos poreikių vertinimo istorija' | translate" [headerLink]="nursingNeedsHistoryUrl"
    [title]="'Slaugos poreikiai' | translate" id="nursingNeeds">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid" [columnDefs]="nursingNeedsColDefs"
        [defaultColDef]="defaultColDef" [rowData]="nursingNeedsData$ | async" [rowSelection]="'multiple'"
        (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
        [domLayout]="'autoHeight'" [context]="getContext()" [gridOptions]="nursingNeedsGridOptions" />
</nhr-record-widget>
<nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
    [title]="'Sveikatos būklės dinamika' | translate"
    [headerLinkText]="'Sveikatos būklės vertinimo istorija' | translate" [headerLink]="healthAssesmentHistoryUrl"
    id="healthStatusDinamics">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid" [columnDefs]="healthStatusColDefs"
        [defaultColDef]="defaultColDef" [rowData]="healthStatusData$ | async" [rowSelection]="'multiple'"
        (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
        [domLayout]="'autoHeight'" [context]="getContext()" [gridOptions]="healthStatusGridOptions" />
</nhr-record-widget>
<nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
    [title]="'Slaugos paslaugų užsakymai (Paskyrimai)' | translate"
    [headerLinkText]="'Visi paciento paskyrimai' | translate" [headerLink]="patientAppointmentsUrl"
    id="nursingServiceOrders">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid"
        [columnDefs]="nursingServiceOrdersColDefs" [defaultColDef]="defaultColDef"
        [rowData]="nursingServiceOrdersData$ | async" [rowSelection]="'multiple'" (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)" [domLayout]="'autoHeight'" [context]="getContext()"
        [gridOptions]="nursingServiceOrdersGridOptions" />
</nhr-record-widget>
<nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
    [title]="'Suplanuoti apsilankymai' | translate" [headerLinkText]="'Visi apsilankymai' | translate"
    [headerLink]="allVisitsUrl" id="scheduledVisits">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid"
        [columnDefs]="scheduledVisitsColDefs" [defaultColDef]="defaultColDef"
        [rowData]="scheduledVisitsData$ | async" [rowSelection]="'multiple'" (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)" [domLayout]="'autoHeight'" [context]="getContext()"
        [gridOptions]="scheduledVisitsGridOptions" />
</nhr-record-widget>
<nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
    [title]="'Slaugos ir priežiūros planas' | translate"
    [headerLinkText]="'Slaugos ir priežiūros plano istorija' | translate"
    [headerLink]="nursingAndCarePlanHistoryUrl" id="patientNursingAndCarePlan">
    <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-main-grid"
        [columnDefs]="nursingAndCarePlanColDefs" [defaultColDef]="defaultColDef"
        [rowData]="nursingAndCarePlanData$ | async" [rowSelection]="'multiple'" (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)" [gridOptions]="nursingAndCarePlanGridOptions"
        [domLayout]="'autoHeight'" [context]="getContext()" />
</nhr-record-widget>
<nhr-record-widget [closable]="false" [closed]="false" class="widget-grid"
    [title]="'Veiklos ir namų aplinkos vertinimo istorija' | translate"
    [headerLinkText]="'Veiklos ir namų aplinkos vertinimo istorija' | translate"
    [headerLink]="activityAndHomeEnvironmentHistoryUrl" id="patientActivityAndHomeEnvironmentHistory">
    <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid"
        [columnDefs]="environmentalAssessmentColDefs" [defaultColDef]="defaultColDef"
        [rowData]="environmentalAssessmentData$ | async" [rowSelection]="'multiple'"
        (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
        [domLayout]="'autoHeight'" [context]="getContext()" [gridOptions]="environmentalAssessmentGridOptions" />
</nhr-record-widget>

<ng-template #visitProceduresApproveModalTmp>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'Procedūros atlikimo statusas' | translate}}</h4>
    </div>
    <div class="modal-body" [formGroup]="visitProceduresFG">
        <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-main-grid"
            [columnDefs]="visitProceduresColDefs2" [defaultColDef]="defaultColDef"
            [rowData]="visitProceduresGridData" (gridReady)="visitProceduresOnGridReady($event)"
            (firstDataRendered)="onFirstDataRendered($event)" [context]="visitProceduresGetContext()"
            (rowDataChanged)="refreshFormControls()" [domLayout]="'autoHeight'" />
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
            (click)="visitProceduresSubmit(modalService.config.initialState.visitId)">{{'Pateikti ir formuoti
            E025'|translate}}</button>
        <button type="button" class="btn btn-secondary"
            (click)="visitProceduresApproveModalRef.hide()">{{'cancel'|translate}}</button>
    </div>
</ng-template>
