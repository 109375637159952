<nhr-record-widget [closable]="false" [closed]="false" [title]="'NGN antraštė' | translate" id="ngnCaption">
    <div class="card mb-3">
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo kortelės unikalus ID" | translate }}</div>
                    <div class="row">{{ captionData?.cardId }}</div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo kortelės būsena" | translate }}</div>
                    <div class="row">
                        {{ captionData?.cardStatus ? ("Aktyvi" | translate) : ("Uždaryta" | translate) }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo pirmoji diena" | translate }}</div>
                    <div class="row">{{ captionData?.firstPregnancyDay | date : "YYYY-MM-dd" }}</div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo savaitė" | translate }}</div>
                    <div class="row">{{ captionData?.pregnancyWeek }}</div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo diena" | translate }}</div>
                    <div class="row">{{ captionData?.pregnancyDay }}</div>
                </div>
            </div>
        </div>
    </div></nhr-record-widget
>
