angular.module('erx.utils').directive('erxDrugSecurityAndEducation', function () {
    return {
        templateUrl: 'modules/erx-utils/components/erx-drug-security-and-education/erxDrugSecurityAndEducation.tpl.html',
        restrict: 'E',
        scope: {
            patientId: '=',
            links: '=',
        },
        controller: ["$scope", function ($scope) {
            $scope.onClick = function (link){
                window.open(link, '_blank');
            }
        }]
    };
});