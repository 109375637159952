import moment from "moment";
import {DocRefConsentDocStatusEnum} from "../models/doc-ref-consent-docStatus.enum";
import {DocRefConsentDocStatusTranslationsEnum} from "../models/doc-ref-consent-docStatus-translations.enum";

export abstract class DocRefAbstractService {
    getBadgeColor(docStatus: string): string {
        switch (docStatus) {
            case DocRefConsentDocStatusEnum.VALID:
                return 'bg-success';
            case DocRefConsentDocStatusEnum.CANCELED:
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    }

    getDateBetween(createdOn: string, signedOn: string): string {
        const format: string = "YYYY-MM-DD";
        const created: string = moment(createdOn).format(format);
        const signed: string = signedOn ? moment(createdOn).format(format) : "dabar";
        return String(created + " - " + signed);
    }

    getDocStatus(docStatus: string) {
        return DocRefConsentDocStatusTranslationsEnum[docStatus];
    }
}