import { Component, EventEmitter, Input, Output } from "@angular/core";
import {AbstractControl} from "@angular/forms";

@Component({
    selector: "app-select",
    templateUrl: "./select.component.html",
})
export class SelectComponent {
    @Input({ required: true }) id: string;
    @Input({required: true}) fControl: AbstractControl;
    @Input() labelText: string;
    @Input() labelClass: string;
    @Input() inputClass: string;
    @Input() errorClass: string;
    @Input() values: string[] | SelectOption[];
    @Input() placeholder: string = "Pasirinkite";
    @Input() showErrors: boolean;
    @Input() set viewMode(state: boolean) {
        if (state) {
            this.fControl.disable();
        }
    }

    @Input() disabled: boolean;

    @Output() onChange = new EventEmitter();
}

export interface SelectOption {
    value: any;
    text: string;
}
