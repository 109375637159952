import '../../main';
import './ng1.app'
import '../shared/ng1.app'
import '../../portal/doctor';

// Translations. These are converted by webpack loader to ng1 "gettext" module.
// TODO: NG2+ uses @ngx-translation. That needs to be migrated to use single framework and minimize bundle size
import '../../po/en.po'
import '../../po/lt.po'

import {mainEnvironment} from "../../environments/environment";
import {bootstrap} from "../../main";
import {DoctorModule} from "./doctor.module";

const lang = process.env['ESPBI_defaultLang']
document.documentElement.lang = lang || mainEnvironment.defaultLanguage

bootstrap(DoctorModule)
