ng1App.factory('EncounterViewDataHandler', 
    ['OrganizationsApiFactory', 'EncountersApiFactory',
     function(OrganizationsApiFactory, EncountersApiFactory){
    return function($scope, data){
        $scope.organization = data.organizationData;
        $scope.practitioner = data.practitionerData;
        $scope.patient = data.patientData;
        const orderTypeCode = data.order.type ? data.order.type.code : data.order.type;
        data.paperOrder = (data.order && orderTypeCode == 7);

        if(data.isByOrder && !data.paperOrder){
            var linkedOrderId = data.order.id;
            var isVirtual = data.event.code == 'virtual';
            EncountersApiFactory.getOrderForEdit({"id": linkedOrderId, isVirtual: isVirtual}, function (res) {
                $scope.o = res;
                console.log('o', res);
            });
        }
        if (data.paperOrderOrganizationRef) {
            OrganizationsApiFactory.getById({organizationId: _.getIdFromReference(data.paperOrderOrganizationRef)}, function(org){
                data.paperOrderOrganizationDisplay = org ? org.name : '';
            });
        }
    };
}]);

ng1App.controller('doctor.patient.EncountersViewCtrl', 
    ['$scope', '$routeParams', '$location', '$modalConfirm', 'MessageService', 'EncountersApiFactory', 'PatientsApiFactory', 'SessionApiFactory', '$window', 'OrganizationsApiFactory', 'EncounterViewDataHandler', 'EncounterService',
     function ($scope, $routeParams, $location, $modalConfirm, MessageService,
                                                                 EncountersApiFactory, PatientsApiFactory, SessionApiFactory , $window,
                                                                 OrganizationsApiFactory, EncounterViewDataHandler, EncounterService) {

    $scope.$emit('setHelpPage', 'apsilankymo-perzira');
    
    MessageService.clearAll();
    $scope.refresh = function () {
        MessageService.clearAll() ;
        EncountersApiFactory.get({"id": $routeParams.documentId}, function(data){
            $scope.form = data;
            EncounterViewDataHandler($scope, data);
        });
    };
    
    SessionApiFactory.getPractitioner({}, function (practitioner) {
        $scope.loadAllergies(practitioner);
        $scope.loadDiagnosis(practitioner);
    });
    
    $scope.loadAllergies = function(practitioner) {
        PatientsApiFactory.getAllergiesForListByEncounter({id: $routeParams.patientId, practitionerId: practitioner.id, encounterId :                                                                                       $routeParams.documentId, count : 100}, function(allergies) {
            $scope.encounterAllergies = allergies;
        });
    };
    
    $scope.loadDiagnosis = function(practitioner) {
        PatientsApiFactory.getDiagnosisForListByEncounter({id: $routeParams.patientId, practitionerId: practitioner.id, encounterId :                                                                         $routeParams.documentId}, function(diagnosis) {
            $scope.encounterDiagnosis = diagnosis;
        });
    };
    
    $scope.refresh();

    $scope.documentList = EncountersApiFactory.getRelatedDocs({
        encounterId: $routeParams.documentId,
        count: -1
    });

    $scope.getPatientAge = function(){
        if($scope.patient){
            return _.age($scope.patient.birthDate, new Date().getTime());
        }
        return '';
    };

    var cancel = function(id){
        EncountersApiFactory.cancelEncounter({"id": id}, null,
            function(){
                MessageService.registerSuccessEvent();
                $location.path('dp/patients/' + $routeParams.patientId);
            },
            function(err){
                if (err.status == 500) {
                    MessageService.handleError(err.data, MessageService);
                } else if (err.status == 400) {
                    _.each(err.data.globalErrors, function (i) {
                        if(i.msg == 'invalid_content'){
                            MessageService.error('Atvykimo atšaukimas neįvykdytas. Akvykimas turi aktyvių dokumentų.');
                        } else if (i.msg == 'invalid_state'){
                            MessageService.error('Atvykimo atšaukimas neįvykdytas. Atšaukti galima tik aktyvius atvykimus.');
                        } else{
                            MessageService.error(i.msg);
                        }
                    });
                }
            });
    };

    $scope.confirmCancel = function(id){
        $modalConfirm.show({title: "Atvykimo atšaukimas", content: 'Per klaidą suvestas apsilankymo įrašas bus atšauktas.'})
            .then(function () {
            cancel(id);
                // TODO: Not sure what it is...
                // alert.hide();
        });
    };
    
    $scope.openRelatedDocument = function(documentId) {
        var url = '/dp/patients/' + $routeParams.patientId + '/documents/' + documentId;
        $window.location.href = url;
    };
    
    var close = function(id){
        EncounterService.closeEncounter(id, function(){
            MessageService.registerEncounterCloseSuccessEvent();
            $location.path('dp/patients/' + $routeParams.patientId);
        });
    };

    $scope.closeEncounter = function(item){
        var message = 'Ar tikrai norite užregistruoti paciento atvykimo pabaigos faktą.';
        if('Organization/' + item.patientData.providerOrganization != item.practitionerData.organizationRef){
            message = 'ESPBI IS užregistravus paciento atvykimo pabaigos faktą, ESPBI IS automatiškai panaikina prieigos prie paciento ESI teises.';
        }
        $modalConfirm.show({title:"Atvykimo uždarymas", content: message}).then(function() {
            close(item.id);
            // TODO: Not sure what it is...
            // alert.hide();
        });
    };

    $scope.goBack = function() {
        $window.history.back();
    };
}]);