ng1App.factory('DocumentCtx', function () {
    var configuration = {
        e003: {
            type: 'e003',
            title: 'E003 Stacionaro epikrizė',
            viewTemplateUrl: '/templates/documents/e003/view_e003.html',
            editTemplateUrl: '/templates/documents/e003/edit_e003.html',
            amendTemplateUrl: '/templates/documents/e003/change_e003.html',
            operationsSupported: {savePartial: true, sign: true, modify: true, amend: true}
        },
        e025: {
            type: 'e025',
            title: 'E025 Ambulatorinio apsilankymo aprašymas',
            viewTemplateUrl: '/templates/documents/e025/view_e025.html',
            editTemplateUrl: '/templates/documents/e025/edit_e025.html',
            amendTemplateUrl: '/templates/documents/e025/change_e025.html',
            operationsSupported: {savePartial: true, sign: true, modify: true, amend: true}
        },
        e027: {
            type: 'e027',
            title: 'E027 Medicinos dokumentų išrašas / siuntimas',
            viewTemplateUrl: '/templates/documents/e027/view_e027.html',
            editTemplateUrl: '/templates/documents/e027/edit_e027.html',
            operationsSupported: {savePartial: false, sign: true}
        },
        e027a: {
            type: 'e027a',
            title: 'E027-ats. Atsakymas į siuntimą',
            viewTemplateUrl: '/templates/documents/e027a/view_e027a.html',
            editTemplateUrl: '/templates/documents/e027a/edit_e027a.html',
            operationsSupported: {savePartial: false, sign: true}
        },
        e200: {
            type: 'e200',
            title: 'E200 Laboratorinio tyrimo užsakymas',
            viewTemplateUrl: '/templates/documents/e200/view_e200.html',
            editTemplateUrl: '/templates/documents/e200/edit_e200.html',
            operationsSupported: {savePartial: false, sign: true}
        },
        e200a: {
            type: 'e200a',
            title: 'E200-ats Laboratorinio tyrimo rezultatų (duomenų) protokolas',
            viewTemplateUrl: '/templates/documents/e200a/view_e200a.html',
            editTemplateUrl: '/templates/documents/e200a/edit_e200a.html',
            operationsSupported: {savePartial: false, sign: true}
        },
        e014: {
            type: 'e014',
            title: 'E014 Patologijos tyrimo užsakymas',
            viewTemplateUrl: '/templates/documents/e014/view_e014.html',
            editTemplateUrl: '/templates/documents/e014/edit_e014.html',
            operationsSupported: {savePartial: false, sign: true}
        },
        e014a: {
            type: 'e014a',
            title: 'E014-ats Patologijos tyrimo atsakymas',
            viewTemplateUrl: '/templates/documents/e014a/view_e014a.html',
            editTemplateUrl: '/templates/documents/e014a/edit_e014a.html',
            operationsSupported: {savePartial: false, sign: true}
        },
        e063: {
            type: 'e063',
            title: 'E063 Vakcinacijos įrašas',
            viewTemplateUrl: '/templates/documents/e063/view_e063.html',
            editTemplateUrl: '/templates/documents/e063/edit_e063.html',
            operationsSupported: {savePartial: false, sign: true}
        }
    };

    return {
        reset: function () {
            this.patientId = null;
            this.documentType = null;
            this.documentId = null;
            this.document = null;
            this.templateUrl = null;
            this.mode = 'view';
        },
        setMode: function (mode) {
            this.mode = mode;
        },
        setPatientId: function (patientId) {
            this.patientId = patientId;
        },
        setDocumentType: function (documentType) {
            this.documentType = documentType;
            this.defineDocumentTypeSpecific(this.documentType);
        },
        defineDocumentTypeSpecific: function (documentType) {
            var dcfg = configuration[documentType];
            this.documentType = documentType;
            if (!dcfg) {
                return;
            }
            this.documentTitle = dcfg.title;
            switch (this.mode) {
                case 'new':
                case 'edit':
                    this.templateUrl = dcfg.editTemplateUrl;
                    break;
                case 'amend':
                    this.templateUrl = dcfg.amendTemplateUrl;
                    break;
                case 'view':
                default:
                    this.templateUrl = dcfg.viewTemplateUrl;
                    break;
            }
        },
        setDocument: function (document) {
            this.document = document;
            this.defineDocumentTypeSpecific(this.document.metadata.docType);
        },
        setDocumentId: function (documentId) {
            this.documentId = documentId;
        },
        getMode: function () {
            return this.mode;
        },
        getDocument: function () {
            return this.document;
        },
        getDocumentType: function () {
            return this.documentType;
        },
        getDocumentRealType: function () {
            return configuration[this.documentType].type;
        },
        getDocumentStatus: function () {
            return this.document ? this.document.metadata.status : null;
        },
        getDocumentStatus2: function () {
            return this.document ? this.document.metadata.documentStatus : null;
        },
        getOperationsSupported: function () {
            return configuration[this.documentType].operationsSupported;
        },
        canSaveDraft: function () {
            return this.mode == 'new' || this.getDocumentStatus2() == 'DRAFT';
        },
        isEditable: function () {
            var st = this.getDocumentStatus();
            return (this.documentType == 'e025' || this.documentType == 'e003') && !this.isSigned() && st != 'enteredInError' && st != 'final' && st != 'final_' && st != 'amended';
            //return (this.documentType == 'e025' || this.documentType == 'e003') && !this.isSigned() && this.document.metadata.status != 'enteredInError';
        },
        isAmendable: function () {
            var st = this.getDocumentStatus();
            return !this.isEditable() && (this.documentType == 'e025' || this.documentType == 'e003') && st != 'enteredInError';
        },
        isSigned: function () {
            if (!this.document || !this.document.metadata) {
                return false;
            }
            return this.document.metadata.signed;
        },
        isSubmitedForSign: function () {
            if (!this.document || !this.document.metadata) {
                return false;
            }
            return this.document.metadata.documentStatus === 'FINAL_UNSIGNED';
        }
    }
});