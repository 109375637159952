<nhr-record-widget
    [closed]="false"
    [title]="'inr.notifTypeForm.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row req">
            <div class="offset-md-4 offset-lg-3 col-md-8 col-lg-9">
                <div class="input-group">
                    <app-radio-button
                        *ngFor="let option of typesOfNotification; let i = index"
                        radioName="typeOfNotification-{{i}}"
                        [fControl]="formGroup.get('typeOfNotification')"
                        [radioValue]="option.name"
                        id="typeOfNotification-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('typeOfNotification')" />
                </div>
            </div>
        </div>
        <div class="row" *ngIf="controlValue('typeOfNotification') === 'Papildomas'">
            <app-select
                labelText="inr.notifTypeForm.report"
                [id]="'report'"
                [fControl]="formGroup.get('report')"
                [values]="selectFromEntities(selectOptions)"
            ></app-select>
        </div>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>