<form [formGroup]="form">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Nuo</strong>
                <div class="w-100">
                    <app-date-picker 
                    inputId="DateForm"
                    [fControl]="form?.get('dateFrom')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Iki</strong>
                <div class="w-100">
                    <app-date-picker 
                    inputId="DateTo"
                    [fControl]="form?.get('dateTo')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Dokumento tipas</strong>
                <div class="w-100">
                    <select id="category" class="form-select" formControlName="unsorted_document_type" [(ngModel)]="docTypeValue" required>
                        <option *ngFor="let docType of documentTypeArray" [value]="docType.id">
                            {{docType.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Gavėjo pavadinimas</strong>
                <div class="w-100">
                    <input formControlName="recipient1"
                    typeaheadOptionField="nameAndJarCode"
                    [typeahead]="suggestionsOrg$"
                    [typeaheadAsync]="true"
                    [typeaheadItemTemplate]="customItemTemplate"
                    (typeaheadOnSelect)="typeaheadOnSelect1($event)"
                    (typeaheadNoResults)="typeaheadNoResults($event)"
                    placeholder="Įveskite įstaigos pavadinimą"
                    class="form-control">
                <ng-template #customItemTemplate let-model="item">
                    <span><strong>{{model.name}}</strong> - {{model.jarCode}}</span>
                </ng-template>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Specialisto vardas, pavardė</strong>
                <div class="w-100">
                    <input formControlName="specialist1"
                    typeaheadOptionField="givenNameAndFamilyName"
                    [typeahead]="suggestionsPract$"
                    [typeaheadAsync]="true"
                    [typeaheadItemTemplate]="customItemTemplate2"
                    (typeaheadOnSelect)="typeaheadOnSelect2($event)"
                    (typeaheadNoResults)="typeaheadNoResults($event)"
                    placeholder="Įveskite įstaigos Specialisto pavardę"
                    class="form-control">
                <ng-template #customItemTemplate2 let-model="item">
                    <span><strong>{{model.givenName}}</strong> - {{model.familyName}}</span>
                </ng-template>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="d-flex justify-content-end gap-2">
    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="searchAdvanced()">{{'search' | translate}}</button>
    <button class="btn btn-secondary" (click)="clear()">{{ 'clean' | translate }}</button>
</div>
