<div>
    <h1>Visi apsilankymai</h1>
    <back-link [path]="backPath" />
    <nhr-record-widget [closable]="false" [closed]="false" class="widget-grid" [title]="'Visi apsilankymai' | translate"
        id="allVisits">
        <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-main-grid" [columnDefs]="allVisitsColDefs"
            [defaultColDef]="defaultColDef" [rowData]="allVisitsData$ | async" [rowSelection]="'multiple'"
            (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered($event)"
            [domLayout]="'autoHeight'" [context]="getContext()" [pagination]="true" [paginationPageSize]="10"
            [suppressPaginationPanel]="true" [gridOptions]="gridOptions" />

    </nhr-record-widget>
    <ag-pagination-bar></ag-pagination-bar>
</div>