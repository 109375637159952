import angular from "angular";

import $ from 'jquery'

import _ from 'underscore'
import 'underscore.string'
import "./utils/underscore_extensions"
import moment from "moment"
import {mainEnvironment} from "./environments/environment"
import {enableProdMode} from '@angular/core'

if (mainEnvironment.production) {
    console.log("Running EPSBI production")
    enableProdMode()

    // of the logs in production
    // TODO: check if there is any webpack plugin to remove it from code
    console.debug = () => function (){}
    console.trace = () => function (){}
}

// workaround to define the global variables for *.js
window['$'] = $.noConflict()
window['jQuery'] = $.noConflict()
window['_'] = _
window['angular'] = angular
window['moment'] = moment
