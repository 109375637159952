import { AfterViewInit, Component, Input } from '@angular/core';
import { multiCheckboxIsChecked, updateCheckboxControl } from '../../e096-utils/utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
    selector: 'app-e096-na-form',
    templateUrl: './e096-na-form.component.html',
})
export class E096NaFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: { [key: string]: any }) {
        if (!value) return;
        this._initialFormValue = value;
        this.formGroup.patchValue(this._initialFormValue);
    }

    // TODO: in future add reaction for ( 3.1.4.7. E106 Medical death certificate )
    formGroup: FormGroup = this.fb.group({
        cases: this.fb.control([]),
        womenDeath: this.fb.control({value: null, disabled: false}),
        causeOfDeath: this.fb.control({value: null, disabled: false})
    });

    birthLossCases: EntityModel[];
    _initialFormValue: { [key: string]: any };
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities
    ) {
        this.apiV2Entities.getEntitiesList('birth-loss-cases').pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.birthLossCases = res;
            });
    }

    ngAfterViewInit() {
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }


    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.formGroup.patchValue(this._initialFormValue);
    }
}
