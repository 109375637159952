var strings = new Object();
strings["Contents"] = "Turinys";
strings["Index"] = "Rodyklė";
strings["Search"] = "Paieška";
strings["Bookmark"] = "Žymė";
strings["Loading the data for search..."] = "Užkraunami duomenys paieškai...";
strings["Type in the word(s) to search for:"] = "Įveskite ieškomą žodį:";
strings["Search title only"] = "Ieškoti pagal pavadinimą";
strings["Search previous results"] = "Ieškoti rezultatuose";
strings["Display"] = "Rodyti";
strings["No topics found!"] = "Nieko nerasta!";
strings["Type in the keyword to find:"] = "Įveskite raktinį žodį:";
strings["Show all"] = "Išskleisti";
strings["Hide all"] = "Suskleisti";
strings["Previous"] = "Atgal";
strings["Next"] = "Pirmyn";
strings["Loading table of contents..."] = "Užkraunamas turinys...";
strings["Topics:"] = "Pavadinimai:";
strings["Current topic:"] = "Dabartinė temą:";
strings["Remove"] = "Pašalinti";
strings["Add"] = "Pridėti";
