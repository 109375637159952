<form-buttons-layout>
    <button
        form-btn-left
        *ngIf="!signButton"
        class="btn btn-primary"
        type="button"
        (click)="sign.emit($event)"
        [disabled]="signButtonDisabled"
    >
        {{ "Pateikti pasirašymui" | translate }}
    </button>
    <!--    <button-->
    <!--        form-btn-left -->
    <!--        *ngIf="!sealConfirmButton"-->
    <!--        class="btn btn-primary"-->
    <!--        type="button"-->
    <!--        (click)="sealConfirm.emit($event)"-->
    <!--        [disabled]="sealConfirmButtonDisabled"-->
    <!--    >-->
    <!--        {{ "Patvirtinti spaudu" | translate }}-->
    <!--    </button>-->
    <button
        form-btn-left
        *ngIf="!signingSubmitButton"
        class="btn {{ signingSubmitButtonDisabled ? 'btn-primary' : 'btn-secondary' }}"
        type="button"
        (click)="signingSubmit.emit($event)"
        [disabled]="signingSubmitButtonDisabled"
    >
        {{ "Pateikti paketiniam pasirašymui" | translate }}
    </button>
    <button
        form-btn-left
        *ngIf="!submitButton"
        class="btn {{ submitButtonDisabled ? 'btn-primary' : 'btn-secondary' }}"
        type="button"
        (click)="submit.emit($event)"
        [disabled]="submitButtonDisabled"
    >
        {{ "Pateikti duomenis" | translate }}
    </button>
    <button
        *ngIf="!saveButton"
        class="btn {{ saveButtonDisabled ? 'btn-primary' : 'btn-secondary' }}"
        type="submit"
        (click)="save.emit($event)"
        [disabled]="saveButtonDisabled"
    >
        {{ "Išsaugoti" | translate }}
    </button>
    <button
        *ngIf="!clearButton"
        class="btn btn-danger"
        type="button"
        (click)="clear.emit($event)"
        [disabled]="clearButtonDisabled"
    >
        {{ "Išvalyti" | translate }}
    </button>
</form-buttons-layout>
