<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto">
            <app-date-picker
                labelText="doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.isDiagnosed"
                inputId="regularContractions"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('diagnosesDate')"
            ></app-date-picker>
        </div>
        <div class="row mx-auto">
            <div class="form-group col-12">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.title' | translate}}:
                </span>
                <div class="row align-items-baseline" *ngFor="let option of options; let i = index">
                    <app-input
                        *ngIf="option.input"
                        class="form-group col-2"
                        type="number"
                        [class.d-none]="!getActionsTaken(option.formControlName).value"
                        [units]="option.input.unit"
                        [fControl]="getActionsTaken(option.input.formControlName)"
                    ></app-input>
                    <div class="col-10 ms-auto">
                        <app-checkbox
                            inputId="{{option.formControlName}}"
                            class="mt-1"
                            formClass="mb-0"
                            [labelText]="option.label"
                            [fControl]="getActionsTaken(option.formControlName)"
                            (checkboxChange)="changeStatusOfChild(getActionsTaken(option.formControlName).value,  option)"
                        ></app-checkbox>
                        <div
                            *ngIf="option.child"
                            class="col-11 ms-auto mb-2 mt-1 d-flex flex-wrap"
                            [class.d-none]="!getActionsTaken(option.formControlName).value"
                        >
                            <app-checkbox
                                *ngFor="let childValue of option.child.values; let j = index"
                                inputId="{{option.formControlName}}{{j}}"
                                class="mt-1 me-3"
                                formClass="mb-0"
                                [labelText]="childValue"
                                [checkboxValue]="childValue"
                                [checkboxDisabled]="!(editable && getActionsTaken(option.formControlName).value && !controlDisabled(option.formControlName))"
                                [checkboxChecked]="option.child.formControlName && checkboxIsChecked(childValue, 'actionsTaken.' + option.child.formControlName)"
                                (checkboxChange)="option.child.formControlName && updateControl(childValue, 'actionsTaken.' + option.child.formControlName)"
                            ></app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-auto">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formFetalShoulder.timeOfBirth' | translate}}:"
                labelClass="text-nowrap"
                inputId="regularContractions"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('fixedTimeOfBirth')"
            ></app-date-picker>
        </div>
    </div>
    <div class="card-footer mt-4">
        <div class="row mx-auto">
            <span class="fw-bolder">
            {{'doc.pregnancyDashboard.e096Ptkl.performedProcedure' | translate}}:
        </span>
            <div class="col-12 col-lg-6 mt-2">
                <app-find-specialists
                    [editable]="editable"
                    [customDisplaying]="false"
                    [formArray]="formGroup?.get('performedProcedure')"
                ></app-find-specialists>
            </div>
        </div>
    </div>
</nhr-record-widget>