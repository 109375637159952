angular.module('erx').directive('erxViewForeignDispenseRo', function () {
    var viewForeignDispenseCtrl = function ($scope, $routeParams, ErxForeignDispenseApiFactory, erxApiConfig) {
      var signedPdfUrl = '';

      $scope.documentId = $routeParams.id;
      $scope.patientId = $routeParams.patientId;
      $scope.loading = true;
      $scope.failedToLoad = false;

      ErxForeignDispenseApiFactory.retrieveForeignDocument({id: $scope.patientId}, {documentId: $scope.documentId}, function (res) {
        $scope.dispense = res;
      }).$promise.catch(function() {
        $scope.failedToLoad = true;
      }).finally(function() {
        $scope.loading = false;
      });

      $scope.getSignedPdf = function () {
        if (!signedPdfUrl) {
          signedPdfUrl = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Document/' + $scope.dispense.uuid + '/signedPdf';
        }
        return signedPdfUrl;
      };

      $scope.ingredientNamePresent = function() {
        if ($scope.dispense.medication.ingredients.find(function (e) {
          return e.hasOwnProperty('ingredientName');
        })) {
          return true;
        } else {
          return false;
        }
      };

      $scope.getStatusColorClass = function (status) {
        if (status === 'P') {
          return 'text-success';
        } else {
          return 'text-danger';
        }
      };

      $scope.getFdspStatusValue = function (prs) {
        if (!prs) {
          return 'Nenurodyta';
        } else {
          switch (prs) {
            case 'P':
              return 'Patvirtintas';
            case 'N':
              return 'Nepatvirtintas';
            case 'A':
              return 'Atšauktas';
            default:
              return 'FIX ME';
          }
        }
      };

    };
    viewForeignDispenseCtrl.$inject = ["$scope", "$routeParams", "ErxForeignDispenseApiFactory", "erxApiConfig"];
    return {
      restrict: 'E',
      scope: {options: '='},
      templateUrl: 'modules/erx/patient-history/view-foreign-dispense/viewForeignDispense.html',
      controller: viewForeignDispenseCtrl
    };
  });
