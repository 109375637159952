import { DecimalPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
    selector: "app-multiselect-dropdown",
    templateUrl: "./multiselect-dropdown.component.html",
})
export class MultiselectDropdownComponent implements OnInit {
    @Input() placeholder: string = "";

    @Input() dropdownDataList: any[];
    @Input() idField: string;
    @Input() textField: string;
    @Input() singleSelection: boolean = false;
    @Input() itemsShowLimit: number = 10;
    @Input() allowSearchFilter: boolean = true;
    @Input() enableCheckAll: boolean = false;
    @Input() elementId: string;
    @Input() labelText: string;
    @Input() labelClass: string;

    @Input() readonly: boolean;
    @Input() viewMode: boolean;
    @Input() errorClass: string;
    @Input() fControl: FormControl;

    @Output() onSelect = new EventEmitter();
    @Output() onSelectAll = new EventEmitter();
    @Output() onDeSelect = new EventEmitter();
    @Output() onFilterChange = new EventEmitter();
    @Output() onDropDownClose = new EventEmitter();

    dropdownSettings: IDropdownSettings;

    selectedItems: any[] = [];

    constructor() {}
    ngOnInit(): void {
        this.dropdownSettings = {
            singleSelection: this.singleSelection,
            idField: this.idField,
            textField: this.textField,
            itemsShowLimit: this.itemsShowLimit,
            allowSearchFilter: this.allowSearchFilter,
            enableCheckAll: this.enableCheckAll,
        };
    }

    onItemSelect(event: any) {
        this.onSelect.emit(event);
    }
    onItemSelectAll(items: any) {
        this.onSelectAll.emit(items);
    }
    onItemDeSelect(event: any) {
        this.onDeSelect.emit(event);
    }
    onCompFilterChange(event: any) {
        this.onFilterChange.emit(event);
    }
    onCompDropDownClose(event: any) {
        this.onDropDownClose.emit(event);
    }
}
