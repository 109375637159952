ng1App.directive('addressInput', 
    ['AddressesApiFactory', 'TypeaheadSearchHandlers', '$q',
     function (AddressesApiFactory, TypeaheadSearchHandlers, $q) {
    return {
        templateUrl: '/templates/components/address/address-input.html',
        restrict: 'E',
        replace: false,
        require: 'ngModel',
        scope: {
            name: '@',
            titleKey: '@',
            onChange: '&',
            getValidate: '&',
            required: '@',
            disabled:'='
        },
        link: function (scope: any, element, attrs, mdlCtrl) {
            scope.address = undefined;
            scope.cityAndStreet = undefined;
            scope.houseAndFlat = undefined;

            scope.addressSearch = TypeaheadSearchHandlers.ADDRESSES;

            var addressText = undefined;
            var validationChain = undefined;
            var getValidateExist = attrs.getValidate;

            var onChange = function () {
                if (scope.onChange) {
                    scope.onChange();
                }
            };

            const testAddress = function () {
                var defer = $q.defer();

                if (addressText && (!scope.address || scope.address.text !== addressText)) {
                    defer.resolve(false);
                } else if (!scope.address) {
                    defer.resolve(true);
                } else if (scope.address) {
                    if (scope.address.houseNo) {
                        scope.address.count = 2;
                        AddressesApiFactory.getAddresses(scope.address, function (results) {
                            if (results.length === 1) {
                                scope.address.aobId = results[0].aobId;
                                scope.address.text = results[0].text;
                                scope.address.zipCode = results[0].zipCode;
                                addressText = results[0].text;
                                defer.resolve(true);
                            } else if (results.length > 1) {
                                scope.address.aobId = undefined;
                                defer.resolve(false);
                            } else {
                                scope.address.aobId = undefined;
                                defer.resolve(false);
                            }
                        });
                    } else {
                        defer.resolve(false);
                    }
                }

                return defer.promise;
            };

            const validate = function (callBack?) {
                var onEnd = function (valid) {
                    mdlCtrl.$setValidity('incomplete', valid);
                    if (callBack) {
                        callBack();
                    }
                };

                if (scope.onValidate) {
                    scope.onValidate();
                }

                if (validationChain) {
                    validationChain.then(testAddress).then(onEnd);
                } else {
                    validationChain = testAddress().then(onEnd);
                }
            };

            const formatAddress = function (val) {
                if (val) {
                    scope.address = val;
                } else {
                    scope.address = {};
                }
                return val;
            };

            const parseAddress = function (val) {
                if (!val) {
                    mdlCtrl.$setValidity('incomplete', true);
                }
                return val;
            };

            if (scope.getValidate) {
                scope.getValidate({func: validate});
            }

            mdlCtrl.$parsers.push(parseAddress);

            mdlCtrl.$formatters.push(formatAddress);

            mdlCtrl.$viewChangeListeners.push(onChange);

            mdlCtrl.$isEmpty = function () {
                return false;
            };

            mdlCtrl.$render = function () {
                if (scope.address && !scope.address.aobId) {
                    scope.cityAndStreet = scope.address;
                    scope.cityAndStreet.label = scope.address.text;

                    if (scope.address.houseNo) {
                        scope.houseAndFlat = scope.address.houseNo;
                        scope.houseAndFlat += scope.address.hullNo ? (' ' + scope.address.hullNo) : '';
                        scope.houseAndFlat += scope.address.flatNo ? ('-' + scope.address.flatNo) : '';
                    }
                    return;
                }
                
                AddressesApiFactory.getAddressByAobId({aobId: scope.address.aobId},
                function (data) {
                    var addr = data[0];
                    if (_.keys(scope.address).length === 1) {
                        scope.address = addr;
                        mdlCtrl.$setViewValue(scope.address);
                    }

                    scope.cityAndStreet = scope.address;
                    scope.cityAndStreet.label = addr.text;

                    if (scope.address.houseNo) {
                        scope.houseAndFlat = scope.address.houseNo;
                        scope.houseAndFlat += scope.address.hullNo ? (' ' + scope.address.hullNo) : '';
                        scope.houseAndFlat += scope.address.flatNo ? ('-' + scope.address.flatNo) : '';
                    }

                });
            };

            scope.cityAndStreetChange = function (value) {
                if (value && value.municipalityId) {
                    scope.address = value;
                    addressText = value.text;
                    scope.houseAndFlat = undefined;
                } else if (value) {
                    addressText = value;
                } else {
                    scope.cityAndStreet = undefined;
                    scope.houseAndFlat = undefined;
                    scope.address = undefined;
                    addressText = undefined;
                }
                mdlCtrl.$setViewValue(scope.address);
            };

            scope.houseAndFlatChange = function (text) {
                if (text) {
                    var parts = text.split('-');
                    var houseIdentityParts = parts[0].replace(/\s+/g, ' ').split(' ');

                    scope.address.houseNo = houseIdentityParts[0];
                    if (houseIdentityParts.length > 1) {
                        scope.address.hullNo = houseIdentityParts[1];
                        // Ignore all other parts
                    }
                    if (parts.length > 1) {
                        scope.address.flatNo = parts[1];
                        // Ignore all other parts
                    }
                }
                scope.address.aobId = undefined;
                mdlCtrl.$setViewValue(scope.address);
            };

            scope.houseAndFlatBlur = function () {
                if (!getValidateExist) {
                    validate();
                }
            };

            scope.isDisabled = function () {
                return !scope.address || !scope.address.municipalityId;
            };
        }
    };
}]);