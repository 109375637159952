import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { EntityModel } from "src/app/shared/models/entity.model";
import { MedicationModel } from "src/app/shared/models/medication.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { fromDateDto, fromLocalDateTimeDto, toDateDto, toLocalDateTimeDto } from "src/app/shared/services/utils/utils";
import { AspnOrderDto } from "../model/aspnOrder.model";
import { AspnVisitClassifiers, AspnVisitDto, AspnVisitsCalendarEvent } from "../model/aspnVisit.model";
import { options } from "@fullcalendar/core/preact";
import { HttpParams } from "@angular/common/http";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";

@Injectable({
    providedIn: "root",
})
export class DpAspnVisitService extends ConfigService {
    path = this.apiUrl + "/aspn";
    aspnOrder = this.path + "/aspnOrder";
    aspnVisit = this.path + "/aspnVisit";

    aspnVisitsCalendar = this.path + "/aspnVisitCalendar";

    getAspnVisitsCalendarEvents() {
        return firstValueFrom(this.http.get<AspnVisitsCalendarEvent[]>(this.aspnVisitsCalendar + "/events"));
    }

    getAspnVisitsCalendarEventsFilter(query) {
        let params = new HttpParams();
        if (query) {
            if (query.quick_search) {
                params = params.append("ftq", query.quick_search);
                params = params.append("page", String(1)); // 1 = page
                return firstValueFrom(
                    this.http.get<FilteredDataModel>(this.aspnVisitsCalendar + "/events", { params })
                );
            } else if (
                query.dateFrom ||
                query.dateTo ||
                query.specialist ||
                query.patient ||
                query.urgent ||
                query.procedures
            ) {
                params = params.append("dateFrom", Date.parse(query.dateFrom) || "");
                params = params.append("dateTo", Date.parse(query.dateTo) || "");
                params = params.append("specialist", query.specialist || "");
                params = params.append("patient", query.patient || "");
                params = params.append("urgent", query.urgent || "");
                params = params.append("procedures", query.procedures || "");
                params = params.append("page", String(1)); // 1 = page
                return firstValueFrom(
                    this.http.get<FilteredDataModel>(this.aspnVisitsCalendar + "/events", { params })
                );
            }
        }
        return firstValueFrom(this.http.get<AspnVisitsCalendarEvent[]>(this.aspnVisitsCalendar + "/events"));
    }

    getAspnVisitClassifiers() {
        return firstValueFrom(this.http.get<AspnVisitClassifiers>(this.aspnVisit + "/classifiers"));
    }

    getAspnVisitById(visitId: number) {
        return firstValueFrom(this.http.get<AspnVisitDto>(this.aspnVisit + `/${visitId}`));
    }

    getAspnVisitPatientOrders(patientId: string) {
        return firstValueFrom(this.http.get<AspnOrderDto[]>(this.aspnVisit + `/orders/patient/${patientId}`));
    }

    getAspnVisitPatientOrder(patientId: string, orderId: number) {
        return firstValueFrom(this.http.get<AspnOrderDto>(this.aspnVisit + `/order/${orderId}/patient/${patientId}`));
    }
    getAspnVisitPatientOrderByOrderId(orderId: number) {
        return firstValueFrom(this.http.get<AspnOrderDto>(this.aspnVisit + `/order/${orderId}`));
    }

    cancelAspnVisit(visitId: number, cancelNote: string) {
        return firstValueFrom(
            this.http.delete<any>(this.aspnVisit + "/" + visitId, {
                body: `{"note": "${cancelNote}"}`,
            })
        );
    }

    submitAspnVisit(visitId: number, procsUpd: any) {
        return firstValueFrom(this.http.post<any>(this.aspnVisit + "/" + visitId, JSON.stringify(procsUpd)));
    }
}

// export interface AspnVisitProcedureValue {
//     procedureGroup: string;
//     urgent: boolean;
//     repeatPeriod: Partial<EntityModel>;
//     frequency: number;
//     relatedMedication: Partial<MedicationModel>[];
//     procedures: Partial<EntityModel>[];
//     bodySite: Partial<EntityModel>[];
//     procedureFromDate: Date;
//     procedureToDate: Date;
//     recommendations: string;
//     id: string;
// }

// export interface AspnVisitValue {
//     visitOrderId: number;
//     status:  Partial<EntityModel>;
//     patient: Partial<PatientModel>;
//     visitingPractitioner: Partial<PractitionerModel>;
//     comment:string;
//     repeatPeriod: Partial<EntityModel>;
//     weekDays: Partial<EntityModel>[];
//     everyMonthDay: number;
//     everyMonthWeekDaySequence: Partial<EntityModel>;
//     everyMonthWeekDayName: Partial<EntityModel>;
//     repeatUntilDate: Date;
//     repeatUntilCount: number;
//     visitStartDate: Date;
//     visitTimeFrom: string;
//     visitTimeTo: string;
//     periodVisits: Partial<AspnVisitValue>[];
//     orderProcedures: AspnVisitProcedureValue[];
//     visitProcedures: AspnVisitProcedureValue[];
// }

// export interface AspnVisitProcedureDto {
//     //   procedureStatus: Partial<EntityModel>;
//     procedureGroup: Partial<EntityModel>;
//     urgent: boolean;
//     repeatPeriod: Partial<EntityModel>;
//     frequency: number;
//     relatedMedication: Partial<MedicationModel>[];
//     procedures: Partial<EntityModel>[];
//     bodySite: Partial<EntityModel>[];
//     procedureFromDate: string;
//     procedureToDate: string;
//     recommendations: string;
//     id: string;
// }

// export interface AspnVisitDto {
//     visitOrderId: number;
//     status: Partial<EntityModel>;
//     patient: Partial<PatientModel>;
//     visitingPractitioner: Partial<PractitionerModel>;
//     comment: string;
//     repeatPeriod: Partial<EntityModel>;
//     weekDays: Partial<EntityModel>[];
//     everyMonthDay: number;
//     everyMonthWeekDaySequence: Partial<EntityModel>;
//     everyMonthWeekDayName: Partial<EntityModel>;
//     repeatUntilDate: string;
//     repeatUntilCount: number;
//     visitStartDate: string;
//     visitTimeFrom: string;
//     visitTimeTo: string;
//     orderProcedures: AspnVisitProcedureDto[];
//     visitProcedures: AspnVisitProcedureDto[];
//     periodVisits: Partial<AspnVisitDto>[];
// }

// export function toAspnVisitDto(
//   val: Partial<AspnVisitValue>
// ): Partial<AspnVisitDto> {
//   return {
//     visitOrderId: val.visitOrderId,
//     status: val.status,
//     patient: val.patient,
//     visitingPractitioner: val.visitingPractitioner,
//     comment: val.comment,
//     repeatPeriod: val.repeatPeriod,
//     weekDays: val.weekDays,
//     everyMonthDay: val.everyMonthDay,
//     everyMonthWeekDaySequence: val.everyMonthWeekDaySequence,
//     everyMonthWeekDayName: val.everyMonthWeekDayName,
//     repeatUntilDate: toDateDto(val.repeatUntilDate),
//     repeatUntilCount: val.repeatUntilCount,
//     visitStartDate: toDateDto(val.visitStartDate),
//     visitTimeFrom: val.visitTimeFrom,
//     visitTimeTo: val.visitTimeTo,
//     orderProcedures: val.orderProcedures.map((p) => toAspnVisitProcedureDto(p)),
//     visitProcedures: val.visitProcedures.map((p) => toAspnVisitProcedureDto(p)),
//     periodVisits: val.periodVisits.map((vp) => {
//         vp.periodVisits = [];
//         return toAspnVisitDto(vp);

//     })
//   };
// }

// export function toAspnVisitProcedureDto(val: AspnVisitProcedureValue): AspnVisitProcedureDto {
//     return {
//         procedureGroup: toEntityModel(val.procedureGroup),
//         urgent: val.urgent,
//         repeatPeriod: val.repeatPeriod,
//         frequency: val.frequency,
//         relatedMedication: val.relatedMedication,
//         procedures: val.procedures,
//         bodySite: val.bodySite,
//         procedureFromDate: toLocalDateTimeDto(val.procedureFromDate),
//         procedureToDate: toLocalDateTimeDto(val.procedureFromDate),
//         recommendations: val.recommendations,
//         id: val.id,
//     };
// }

// export function fromAspnOrderDto(
//   val: Partial<AspnVisitDto>
// ):  Partial<AspnVisitValue> {
//   return {
//     visitOrderId: val.visitOrderId,
//     status: val.status,
//     patient: val.patient,
//     visitingPractitioner: val.visitingPractitioner,
//     comment: val.comment,
//     repeatPeriod: val.repeatPeriod,
//     weekDays: val.weekDays,
//     everyMonthDay: val.everyMonthDay,
//     everyMonthWeekDaySequence: val.everyMonthWeekDaySequence,
//     everyMonthWeekDayName: val.everyMonthWeekDayName,
//     repeatUntilDate: fromDateDto(val.repeatUntilDate),
//     repeatUntilCount: val.repeatUntilCount,
//     visitStartDate: fromDateDto(val.visitStartDate),
//     visitTimeFrom: val.visitTimeFrom,
//     visitTimeTo: val.visitTimeTo,
//     orderProcedures: val.orderProcedures.map((p) => fromAspnVisitProcedureDto(p)),
//     visitProcedures: val.visitProcedures.map((p) => fromAspnVisitProcedureDto(p)),
//     periodVisits: val.periodVisits.map((vp) => {
//         vp.periodVisits = [];
//         return fromAspnOrderDto(vp);

//     })
//   };
// }
// export function fromAspnVisitProcedureDto(val: AspnVisitProcedureDto): AspnVisitProcedureValue {
//     return {
//         procedureGroup: fromEntityModel(val.procedureGroup),
//         urgent: val.urgent,
//         repeatPeriod: val.repeatPeriod,
//         frequency: val.frequency,
//         relatedMedication: val.relatedMedication,
//         procedures: val.procedures,
//         bodySite: val.bodySite,
//         procedureFromDate: fromLocalDateTimeDto(val.procedureFromDate),
//         procedureToDate: fromLocalDateTimeDto(val.procedureFromDate),
//         recommendations: val.recommendations,
//         id: val.id,
//     };
// }
