<div class="pid">ID <strong>MAIN_404</strong></div>
<div id="wrap" ng-controller="Page404Ctrl" nhr-to-window-size> 
  <div class="container text-center h-100"> 
      <div class="row justify-content-center h-100">
        <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-lg-4 col-lg-offset-4 mt-4 mb-4 text-center align-self-center">
          <div class="widget">
            <div class="widget-body"> <img src="/assets/img/logo.svg" alt="{{'Health Portal logo'|translate}}">
              <h2 translate>404.pageNotFound</h2>
              <p><strong translate>404.sorryPageNotFound</strong></p>
              <p>{{'404.return'|translate}} <a ng-click="goBack()" translate>404.back</a> {{'404.goTo'|translate}} <a href="/" translate>404.mainPage</a>.</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
