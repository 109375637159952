ng1App.service('CertificateService', 
    ['$filter', 'MessageService', 'CertificatesApiFactory', '$location', '$routeParams', '$window', 'DocumentSpinnerHelper', 'DraftsApiFactory',
     function ($filter, MessageService, CertificatesApiFactory, $location, $routeParams, $window, DocumentSpinnerHelper, DraftsApiFactory) {
    this.onSaveError = function (res) {
        DocumentSpinnerHelper.endLoading();
        if (res.data.fieldErrors && res.data.globalErrors) {
            _.each(res.data.fieldErrors, function (i) {
                var errorMessage = $filter('translate')('cer.field.' + i.propertyPath);
                errorMessage += ": " + $filter('translate')('err.cer.' + i.msg);
                MessageService.error(errorMessage);
            });
            _.each(res.data.globalErrors, function (i) {
                var resolved = _resolveValidationError(i.msg);
                MessageService.error(resolved != null ? resolved : i.msg);
            });
        } else {
            MessageService.error($filter('translate')('err.cer.save'));
        }
        $window.scrollTo(0, 10);
    };

    this.savePartial = function (certificateType, certificate, certificateForm, entitiesCheckRequest) {
        certificateForm.submitted = true;
        certificateForm.spinner = DocumentSpinnerHelper;
        DocumentSpinnerHelper.startLoading();
        var allEntitiesValid = true;

        if (entitiesCheckRequest) {
            allEntitiesValid = this.checkEntities(entitiesCheckRequest);
        }
        if (certificateForm.$invalid || !allEntitiesValid) {
            DocumentSpinnerHelper.endLoading();
            if (certificateForm.$invalid) {
                _.scrollToFirstInvalid();
            }
            return;
        }

        CertificatesApiFactory.savePartial({
            certificateType: certificateType, draftId: certificate.draftId
        }, certificate,
                function (result) {
                    MessageService.clear();
                    MessageService.registerSuccessEvent();
                    DocumentSpinnerHelper.endLoading();
                    $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/' + result.compositionId).search({});
                }, this.onSaveError);
    };

    this.saveAndSign = function (certificateType, certificate, certificateForm, entitiesCheckRequest, isPatientPortal, doSign) {
        doSign = (doSign === undefined || doSign === null) ? true : doSign;
        certificateForm.submitted = true;
        certificateForm.spinner = DocumentSpinnerHelper;
        DocumentSpinnerHelper.startLoading();
        var allEntitiesValid = true;
        if (entitiesCheckRequest) {
            allEntitiesValid = this.checkEntities(entitiesCheckRequest);
        }
        if (certificateForm.$invalid || !allEntitiesValid) {
            if (certificateForm.$invalid) {
                _.scrollToFirstInvalid();
            }
            DocumentSpinnerHelper.endLoading();
            return;
        }

        CertificatesApiFactory.saveFinal({
            certificateType: certificateType, draftId: certificate.draftId
        }, certificate,
                function (result) {
                    DocumentSpinnerHelper.endLoading();
                    if (result.compositionId != null) {
                        MessageService.clear();
                        MessageService.registerSuccessEvent();
                        if (doSign) {
                            if (!isPatientPortal) {
                                $location.path('/dp/patients/' + $routeParams.patientId + '/documents/' + result.compositionId + '/sign').search({certificate: certificateType});
                            } else {
                                $location.path('/pp/certificates/' + result.compositionId + "/sign").search({});
                            }
                        } else {
                            if (!isPatientPortal) {
                                $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/' + result.compositionId).search({});
                            } else {
                                $location.path('/pp/certificates/' + result.compositionId).search({});
                            }
                        }
                    }
                }, this.onSaveError);
    };

    this.saveDraft = function (id, certificateType, certificateForm) {
        DraftsApiFactory.saveDraft({id: id, docType: certificateType, patientId: $routeParams.patientId}, certificateForm, function (resp) {
            MessageService.registerSuccessEvent();
            MessageService.registerSuccessEvent();
            DocumentSpinnerHelper.endLoading();
            $location.path('/dp/patients/' + $routeParams.patientId + '/certificates/drafts/' + resp.draftId).search({});
        }, this.onSaveError);
    };

    this.checkEntities = function (entitiesCheckRequest) {
        var allEntitiesValid = true;
        for (var i = 0; i < entitiesCheckRequest.length; i++) {
            var checkValue = entitiesCheckRequest[i];
            //if model value is null, but input for model is not empty that means that entered text is not from classifiers
            var isValueResolved = checkValue.value && checkValue.value.code;
            var isValueEntered = checkValue.inputValue && typeof checkValue.inputValue === 'string' && checkValue.inputValue !== '';
            if (isValueEntered && !isValueResolved) {
                MessageService.add('error', "'" + checkValue.inputName + "' " + $filter('translate')('err.mustBeEntity'), checkValue.messageRegion);
                _.scrollTo(checkValue.messageRegion);
                allEntitiesValid = false;
            }
        }
        return allEntitiesValid;
    };

    this.populateCommonData = function (scope, data) {
        scope.patient = data.patient;
        scope.practitioner = data.practitioner;
        scope.custodian = data.custodian;
        scope.acMeta = data.acMeta;
        //2020-04-26 AS modified
        if (data.compositionMeta && data.compositionMeta.version) {
            scope.compositionMetaProfileVersion = data.compositionMeta.version;
        } else {
            scope.compositionMetaProfileVersion = 'v1';
        }
        scope.isCompositionProfileVersion = function(version) {
            return scope.compositionMetaProfileVersion == version;
        };
    };

    this.toSignPage = function (patientId, compositionId, certificateType) {
        $location.path('/dp/patients/' + patientId + '/documents/' + compositionId + '/sign').search({certificate: certificateType});
    };
	
    //2020-04-23 AS modified
    this.calcValidUntilDate = function (scope){	
		scope.validUntilDate = moment(scope.certificate.creationTime).add(12, 'months').toDate();//Todo get months from param
    };

    this.prefillDocumentDiagnosis = function (document, scope) {
        if (document.partMedicalCertificate && document.partMedicalCertificate.certificate) {
            var certificate = document.partMedicalCertificate.certificate;
            if (certificate.type === 'e106') {
                var description = '';
                if (certificate.deathCauseDescription) {
                    description += $filter('translate')('cer.e106.mai.dia.name');
                    description += ": " + certificate.deathCauseDescription + "\n";
                }
                if (certificate.otherConditions) {
                    description += $filter('translate')('cer.e106.oth');
                    description += ": " + certificate.otherConditions;
                }
                scope.initDiagnosis = {
                    diagnosis: certificate.deathCause,
                    description: description
                }
            } else if (certificate.type === 'e106_2_1') {
                var description = '';
                if (certificate.deathCause && certificate.deathCause.description) {
                    description += $filter('translate')('cer.e106_2_1.new.rea.mDiaNam');
                    description += ": " + certificate.deathCause.description + "\n";
                }
                if (certificate.deathCause && certificate.deathCause.otherDiseaseDescription) {
                    description += $filter('translate')('cer.e106_2_1.new.rea.oth');
                    description += ": " + certificate.deathCause.otherDiseaseDescription;
                }
                var diagnosis = null;
                if (certificate.deathCause) {
                    diagnosis = certificate.deathCause.mainDisease;
                }
                scope.initDiagnosis = {
                    diagnosis: diagnosis,
                    description: description
                }
            } else if (certificate.type === 'e103_1') {
                var description = '';
                if (certificate.patient && certificate.patient.sex) {
                    description += $filter('translate')('cer.1031.mai.inf.sex');
                    description += ": " + certificate.patient.sex.name;
                }

                scope.initDiagnosis = {
                    description: description,
                    date: certificate.birthDateAndTime
                }
            }
        }
    };

    var _resolveValidationError = function (msg) {
        switch (msg) {
            case 'no_valid_licence_or_position':
            {
                return $filter('translate')('no_valid_licence_or_position');
            }
        }
        return null;
    };
}]);

ng1App.service('ConsultationCertificateService', function () {
    this.showNextHealthCheckDateWarning = function (creationTime, nextHealthCheckDate) {
        if (!creationTime || !nextHealthCheckDate) {
            return false;
        }
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        var diffDays = Math.round(Math.abs((creationTime - nextHealthCheckDate) / (oneDay)));

        return diffDays < 365;
    };

});