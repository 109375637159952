import { RouteParams } from "../../../../../ng1.routeParams";
import { Component } from "@angular/core";


@Component({
    selector: "aspn-patient-health-assessments",
    templateUrl: "./patient-health-assessments.component.html",
})
export class PatientHealthAssessmentsComponent {
    patientId: string;
    formId: string;
    backUrl: string
    subTitle: string | string[];

    constructor(
        private readonly params: RouteParams,
    ) {
        this.getPatientId();
    }

    getPatientId(){
        this.patientId = this.params.patientId;
        this.backUrl = '/dp/patients/' + this.patientId;
        this.formId = this.params.id;
        if (!this.patientId || !this.formId) {
            this.subTitle = ['doc.new'];
            return;
        }
    }
}
