// Direktyvos, aprašančios skyrimo peržiūros formų smulkesnius komponentus
// Bendras skyrimų peržiūros viršutinės dalies komponentas
ng1App.directive('erxViewPrescriptionHeader', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_prescription_header.html',
        restrict: 'E',
        scope: true,
        link: function (scope, element, attrs) {
        },
        replace: false
    };
});
// Recepto duomenų peržiūros komponentas
ng1App.directive('erxViewPrescriptionInfo', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_prescription_info.html',
        restrict: 'E',
        replace: true,
        scope: true,
        transclude: true,
        link: function (scope, element, attrs) {
        }
    };
});
// Išdavimo informacijos peržiūros komponentas
ng1App.directive('erxViewDispenseInfo', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_dispense_info.html',
        restrict: 'E',
        scope: true,
        link: function (scope, element, attrs) {
        },
        replace: false
    };
});
// Vaisto ir vardinio vaisto duomenų peržiūros komponentas
ng1App.directive('erxViewMPrescription', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_m_prescription.html',
        restrict: 'E',
        scope: true,
        link: function (scope, element, attrs) {
        },
        replace: false
    };
});
// Ekstemporalaus vaisto duomenų koregavimo komponentas
ng1App.directive('erxViewEmPrescription', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_em_prescription.html',
        restrict: 'E',
        scope: true,
        link: function (scope, element, attrs) {
        },
        replace: false
    };
});
// MP duomenų peržiūros komponentas
ng1App.directive('erxViewMpsPrescription', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_mps_prescription.html',
        restrict: 'E',
        replace: true,
        scope: true,
        transclude: true,
        link: function (scope, element, attrs) {
        }
    };
});
// MPP duomenų peržiūros komponentas
ng1App.directive('erxViewMppPrescription', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_mpp_prescription.html',
        restrict: 'E',
        replace: true,
        scope: true,
        transclude: true,
        link: function (scope, element, attrs) {
        }
    };
});
// Kito produkto duomenų peržiūros komponentas
ng1App.directive('erxViewMaPrescription', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_ma_prescription.html',
        restrict: 'E',
        replace: true,
        scope: true,
        transclude: true,
        link: function (scope, element, attrs) {
        }
    };
});
// Užrakinto recepto komponentas
ng1App.directive('erxViewLockedPrescription', function () {
    return {
        templateUrl: '/templates/components/erx/prescription_view_components/view_locked_prescription.html',
        restrict: 'E',
        replace: true,
        scope: true,
        transclude: true,
        link: function (scope, element, attrs) {
        }
    };
});