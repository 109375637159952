/* global angular, app */
ng1App.controller('doctor.user.ReportsCtrl', 
    ['$scope', 'ReportsApiFactory', 'PatientsApiFactory',
     function ($scope, ReportsApiFactory, PatientsApiFactory) {

    $scope.form = {};
    $scope.filterResults = [];
    $scope.hasNoResults = false;
    $scope.pageItems = [];
    $scope.itemsPerPage = 50;
    $scope.pager = {};

    $scope.searchAdvanced = function (scope) {
        ReportsApiFactory.filterVaccinatedAndRecoveredPatients(scope.form).$promise.then(function(res) {
            $scope.filterResults = angular.fromJson(res);
            if($scope.filterResults.length < 1) {
                $scope.hasNoResults = true;
            } else {
                $scope.hasNoResults = false;
                $scope.setPage(1);
            }
        });
	};

    $scope.clean = function () {
        $scope.hasNoResults = false;
        $scope.form = {};
        $scope.filterResults = [];
    };

    $scope.getPersonDetails = function (patientInfo) {
        if(patientInfo.details && patientInfo.details.length > 0) {
            patientInfo.details = [];
        } else {
            ReportsApiFactory.getPatientInfo({personalCode: patientInfo.personalCode}).$promise.then(function(res) {
                patientInfo.details = angular.fromJson(res);
            });
        }
    };

    $scope.downloadCsv = function() {
        ReportsApiFactory.filterVaccinatedAndRecoveredPatientsInCsv($scope.form).$promise.then(function(res) {
            var downloadLink = document.createElement("a");
            downloadLink.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(res.fileContent);
            downloadLink.download = "Pacientai.csv";

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });
    };

    $scope.getPatientId = function(event, item) {
        PatientsApiFactory.getByPersonal({personalCode: item.personalCode}, function (res) {
            var linkElement = event.target;
            linkElement.href = "/dp/patients/" + res.id;
            linkElement.click();
        });
    };

    $scope.setItemsPerPage = function(num) {
        $scope.itemsPerPage = num;
        $scope.setPage(1);
      }

    $scope.setPage = function(page) {
		if (page < 1 || page > $scope.pager.totalPages) {
			return;
		}

		// get pager object from service
		$scope.pager = $scope.getPager($scope.filterResults.length, page, $scope.itemsPerPage);

		// get current page of items
		$scope.pageItems = $scope.filterResults.slice($scope.pager.startIndex, $scope.pager.endIndex);
	}

    $scope.getPager = function(totalItems, currentPage, pageSize) {
		// default to first page
		currentPage = currentPage || 1;

		// default page size is 10
		pageSize = pageSize || 10;

		// calculate total pages
		var totalPages = Math.ceil(totalItems / pageSize);

		var startPage, endPage;
		if (totalPages <= 10) {
			// less than 10 total pages so show all
			startPage = 1;
			endPage = totalPages;
		} else {
			// more than 10 total pages so calculate start and end pages
			if (currentPage <= 6) {
				startPage = 1;
				endPage = 10;
			} else if (currentPage + 4 >= totalPages) {
				startPage = totalPages - 9;
				endPage = totalPages;
			} else {
				startPage = currentPage - 5;
				endPage = currentPage + 4;
			}
		}

		// calculate start and end item indexes
		var startIndex = (currentPage - 1) * pageSize;
		var endIndex = Math.min(currentPage * pageSize, totalItems);

		// create an array of pages to ng-repeat in the pager control
		var pages = _.range(startPage, endPage + 1);

		// return object with all pager properties required by the view
		return {
			totalItems: totalItems,
			currentPage: currentPage,
			pageSize: pageSize,
			totalPages: totalPages,
			startPage: startPage,
			endPage: endPage,
			startIndex: startIndex,
			endIndex: endIndex,
			pages: pages
		};
    }

}]);