<app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
    [prefillData]="prefillData"></app-common-patient-widget>

<nhr-record-widget bodyClass="p-0" widgetClass="widget-min-800">
    <div>
        <div class="border-top">
            <div class="row fs-5 fw-bolder m-0">
                <div class="col-2 py-3 border-end text-center d-flex align-items-center justify-content-center">
                    {{'doc.aspn.assessment.supLevels' | translate}}</div>
                <div *ngFor="let col of patientSupportLevelColumns"
                    class="col py-3 border-end text-center d-flex align-items-center justify-content-center text-break">
                    {{col.name}}</div>
                <div class="col-3 py-3 text-center d-flex align-items-center justify-content-center">
                    {{'doc.aspn.assessment.toolsUsed' | translate}}</div>
            </div>
            <aspn-activity-home-environment-assessment-sub-form title="1 {{'doc.aspn.assessment.activity' | translate}}"
                [layout]="tableLayouts['first']" [noLastRowBorder]="true" [editable]="editable" />
            <aspn-activity-home-environment-assessment-sub-form title="2 {{'doc.aspn.assessment.moving' | translate}}"
                [layout]="tableLayouts['second']" [editable]="editable" />
        </div>

        <div class="bg-light">
            <app-input class="d-flex align-items-center text-center py-3 px-3" id="descriptionTextarea" type="textarea"
                textAreaRows="3" labelClass="col-2 mb-0 fw-bolder pt-1 ps-3 align-self-baseline text-start fs-5"
                inputClass="fs-5" [labelText]="'Notes' | translate" [fControl]="getFormControl('firstSecondTableNote')"
                [viewMode]="!editable" />
        </div>
    </div>
</nhr-record-widget>

<nhr-record-widget title="3 {{'doc.aspn.assessment.assessmentOfFunctions' | translate}}" titleTextClass="fs-5 py-2"
    bodyClass="p-0" class="mt-3 d-block" widgetClass="widget-min-800">
    <div class="border-top">
        <div class="row fs-5 fw-bolder m-0">
            <div class="col-2 py-3 border-end text-center d-flex align-items-center justify-content-center">
                {{'doc.aspn.assessment.dysFunctionColumn' | translate}}</div>
            <div *ngFor="let col of patientDysfunctionLevelColumns"
                class="col py-3 border-end text-center d-flex align-items-center justify-content-center text-break">
                {{col.name}}</div>
            <div class="col-3 py-3 text-center d-flex align-items-center justify-content-center">
                {{'doc.aspn.assessment.toolsUsed' | translate}}</div>
        </div>
        <aspn-activity-home-environment-assessment-sub-form [layout]="tableLayouts['third']" [editable]="editable" />
    </div>

    <div class="bg-light">
        <app-input class="d-flex align-items-center text-center py-3 px-3" id="descriptionTextarea2" type="textarea"
            textAreaRows="3" labelClass="col-2 mb-0 fw-bolder pt-1 ps-3 align-self-baseline text-start fs-5"
            inputClass="fs-5" [labelText]="'Notes' | translate" [fControl]="getFormControl('thirdTableNote')"
            [viewMode]="!editable" />
    </div>
</nhr-record-widget>

<nhr-record-widget title="4 {{'doc.aspn.assessment.assessmentOfHome' | translate}}" titleTextClass="fs-5 py-2"
    bodyClass="p-0" class="mt-3 d-block" widgetClass="widget-min-800">
    <div class="border-top">
        <div class="row fs-5 fw-bolder m-0">
            <div class="col-2 py-3 border-end text-center d-flex align-items-center justify-content-center">
                {{'doc.aspn.assessment.homePart' | translate}}</div>
            <div *ngFor="let col of patientEnvironmentComplianceLevelColumns"
                class="col py-3 border-end text-center d-flex align-items-center justify-content-center">{{col.name}}
            </div>
            <div class="col-3 py-3 text-center d-flex align-items-center justify-content-center">
                {{'doc.aspn.assessment.toolsUsed' | translate}}</div>
        </div>
        <aspn-activity-home-environment-assessment-sub-form [layout]="tableLayouts['forth']" [editable]="editable" />
    </div>

    <div class="bg-light">
        <app-input class="d-flex align-items-center text-center py-3 px-3" id="descriptionTextarea3" type="textarea"
            textAreaRows="3" labelClass="col-2 mb-0 fw-bolder pt-1 ps-3 align-self-baseline text-start fs-5"
            inputClass="fs-5" [labelText]="'Notes' | translate" [fControl]="getFormControl('forthTableNote')"
            [viewMode]="!editable" />
    </div>
</nhr-record-widget>

<div class="row mt-3">
    <nhr-record-widget [title]="'doc.aspn.assessment.visitsFreq' | translate"
        class="col-md-4 align-self-stretch d-block mb-3 mb-md-0" widgetClass="h-100">
        <div class="row d-flex flex-xl-nowrap req">
            <div class="col-md-6 col-xl-3">
                <app-input type="number" [placeholder]="'doc.aspn.assessment.frequencyPlaceholder' | translate"
                    [fControl]="getFormControl('visitsFrequency')" [labelText]="'doc.aspn.assessment.freq' | translate"
                    [showErrors]="true"
                    [viewMode]="!editable"
                    labelClass="fs-5 {{!!this.tableFormGroups.get('visitsPeriodicality').value ? '' : 'norq'}}"
                    inputClass="fs-5"></app-input>
            </div>
            <div class="col-md-6 col-xl">
                <app-select id="periodicality" [fControl]="getFormControl('visitsPeriodicality')"
                    [labelText]="'doc.aspn.assessment.period' | translate" labelClass="fs-5 col-form-label norq"
                    inputClass="fs-5" [values]="repeatPeriods" [viewMode]="!editable"
                    (onChange)="chosePeriod()"></app-select>
            </div>
            <div class="col-xl-4">
                <app-input type="number" [fControl]="getFormControl('visitsDuration')"
                    [labelText]="'doc.aspn.assessment.duration' | translate" [viewMode]="!editable"
                    labelClass="fs-5 norq"
                    inputClass="fs-5"></app-input>
            </div>
        </div>
    </nhr-record-widget>
    <nhr-record-widget [title]="'doc.aspn.assessment.actionPlan' | translate" titleTextClass="fs-5"
        class="col-md-8 align-self-stretch d-block" widgetClass="h-100">
        <div class="row req">
            <div class="col-sm-9">
                <app-input id="conclusion" type="textarea" [labelText]="'doc.aspn.assessment.conclusion' | translate"
                    [fControl]="getFormControl('conclusion')" [showErrors]="true" [viewMode]="!editable" labelClass="my-0" />
            </div>
            <div class="col-sm-3">
                <app-date-picker [labelText]="'doc.aspn.assessment.nextDate' | translate"
                    labelClass="my-0 col-form-label norq" suffixIcon="fa fa-calendar"
                    [placeholder]="'doc.aspn.assessment.datePlaceholder' | translate"
                    [fControl]="getFormControl('nextDate')" [inputId]="'nextDate'"
                    [viewMode]="!editable"></app-date-picker>
            </div>
        </div>
    </nhr-record-widget>

</div>

<!-- TODO: the external buttons component should be used -->
<div class="d-flex gap-3 mt-3 flex-wrap" *ngIf="editable">
    <button class="btn btn-primary" [disabled]="tableFormGroups.invalid"
        (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary" [disabled]="tableFormGroups.invalid"
        (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary" [disabled]="tableFormGroups.invalid"
        (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary" [disabled]="tableFormGroups.invalid"
        (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary" [disabled]="tableFormGroups.invalid"
        (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger" (click)="resetForm()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>