<modal
    [modalId]="modalId"
    [modalCloseId]="modalCloseId"
    title="Medicininės formos"
    [isLoaded]="!loading && allowedTypes && allowedCerts"
    (confirm)="confirmSelection()"
>
    <ng-container *ngIf="!loading && allowedTypes && allowedCerts">
        <!--TODO: global message component should be reused?-->
        <div class="alert alert-danger alert-dismissable mb-0" *ngIf="createDialogErr?.msg">
            <button
                aria-hidden="true"
                data-bs-dismiss="alert"
                class="btn-close"
                type="button"
                (click)="createDialogErr.msg = null"
            >
                ×
            </button>
            {{ createDialogErr.msg }}
        </div>
        <ul class="nav nav-tabs" id="medicalModalTabs" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link active"
                    id="medDocs"
                    data-bs-toggle="tab"
                    data-bs-target="#medDocsPanel"
                    type="button"
                    role="tab"
                    aria-controls="medDocsPanel"
                    aria-selected="true"
                    (click)="onTabClick('medDocsPanel')"
                >
                    Medicininiai dokumentai
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="medCerts"
                    data-bs-toggle="tab"
                    data-bs-target="#medCertsPanel"
                    type="button"
                    role="tab"
                    aria-controls="medCertsPanel"
                    aria-selected="false"
                    (click)="onTabClick('medCertsPanel')"
                >
                    Medicininės pažymos
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="templates"
                    data-bs-toggle="tab"
                    data-bs-target="#templatesPanel"
                    type="button"
                    role="tab"
                    aria-controls="templatesPanel"
                    aria-selected="false"
                    (click)="onTabClick('templatesPanel')"
                >
                    Šablonai
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="ngn"
                    data-bs-toggle="tab"
                    data-bs-target="#ngnPanel"
                    type="button"
                    role="tab"
                    aria-controls="ngnPanel"
                    aria-selected="false"
                    (click)="onTabClick('ngnPanel')"
                >
                    NGN
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="aspn"
                    data-bs-toggle="tab"
                    data-bs-target="#aspnPanel"
                    type="button"
                    role="tab"
                    aria-controls="aspnPanel"
                    aria-selected="false"
                    (click)="onTabClick('aspnPanel')"
                >
                    ASPN
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="recipes"
                    data-bs-toggle="tab"
                    data-bs-target="#recipesPanel"
                    type="button"
                    role="tab"
                    aria-controls="recipesPanel"
                    aria-selected="false"
                    (click)="onTabClick('recipesPanel')"
                >
                    Receptai
                </button>
            </li>
        </ul>

        <form [formGroup]="form" novalidate autocomplete="off">
            <div class="tab-content" id="medicalModalTabsContent">
                <div
                    class="tab-pane fade show active"
                    id="medDocsPanel"
                    role="tabpanel"
                    aria-labelledby="medDocs"
                    tabindex="0"
                >
                    <ol class="slats">
                        <li *ngIf="!allowedTypes.documentE003.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e003"
                                    [radioName]="selectedDocName"
                                    labelText="E003 Stacionaro epikrizė"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE003 || allowedTypes.documentE003.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e003"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE003 || allowedTypes.documentE003.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!-- if e027 order type1 is disabled show one of reasons -->
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE003.disabled &&
                                        allowedTypes.documentE003.reasons.hasActive
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes prie atvykimo yra aktyvi stacionaro epikrizė.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE003.disabled &&
                                        allowedTypes.documentE003.reasons.ambulatory
                                    "
                                >
                                    Šio tipo dokumento negalite pildyti, nes tai apsilankymas į SPĮ gauti ambulatorinių
                                    paslaugų.
                                </span>
                                <!-- end if -->
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE025.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e025"
                                    [radioName]="selectedDocName"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE025 || allowedTypes.documentE025.disabled
                                    }"
                                    labelText="E025 Ambulatorinio apsilankymo aprašymas"
                                    [radioValue]="DocumentTagEnum.e025"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE025 || allowedTypes.documentE025.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <div
                                    class="form-group form-check ms-4"
                                    *ngIf="form.controls[selectedDocName].value == DocumentTagEnum.e025"
                                >
                                    <app-checkbox
                                        [inputId]="'extendedTreatment'"
                                        [checkboxName]="'extendedTreatment'"
                                        [labelText]="'Pratęsti ankstesnį gydymą'"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                    />
                                </div>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE025.disabled && !allowedTypes.documentE025.reasons.virtual
                                    "
                                >
                                    Šio tipo dokumento negalite pildyti, nes tai atvykimas į stacionarą.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE025.disabled && allowedTypes.documentE025.reasons.virtual
                                    "
                                >
                                    Šio tipo dokumento negalite pildyti, nes tai paslaugų suteikimas pacientui neatvykus
                                    į SPĮ
                                </span>
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE027.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e027"
                                    [radioName]="selectedDocName"
                                    labelText="E027 Medicinos dokumentų išrašas / siuntimas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE027 || allowedTypes.documentE027.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e027"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE027 || allowedTypes.documentE027.disabled)
                                    "
                                />
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE027A.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e027a"
                                    [radioName]="selectedDocName"
                                    labelText="E027-ats Atsakymas į siuntimą"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE027A || allowedTypes.documentE027A.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e027a"
                                    [fControl]="form.controls[selectedDocName]"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE027A || allowedTypes.documentE027A.disabled)
                                    "
                                    (radioChange)="onTypeChange()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--if e003-->
                                <div
                                    class="form-group form-check ms-4"
                                    *ngIf="
                                        allowedTypes.encounterType === 'inpatient' &&
                                        form.controls[selectedDocName].value === DocumentTagEnum.e027a
                                    "
                                >
                                    <app-checkbox
                                        [inputId]="DocumentTagEnum.e027aFromE003"
                                        [checkboxName]="DocumentTagEnum.e027aFromE003"
                                        [labelText]="'Pridėti siuntimą'"
                                        [ngClass]="{ 'text-secondary': allowedTypes.documentE027A.reasons.noOrders }"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                        [checkboxDisabled]="isDisabled()"
                                    />
                                    <span class="small" *ngIf="allowedTypes.e027aFromE003 && isDisabled()">
                                        Atvykime nėra dalinai patvirtintos stacionaro epikrizės, todėl dokumentą galite
                                        pildyti tik pagal siuntimą.
                                    </span>
                                </div>
                                <!--enf if-->
                                <!--if e027a is disabled show one of reasons -->
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE027A.disabled &&
                                        allowedTypes.documentE027A.reasons.noOrders
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes pacientas neturi aktyvių arba vykdomų E027
                                    siuntimų.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE200.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e200"
                                    [radioName]="selectedDocName"
                                    labelText="E200 Laboratorinio tyrimo užsakymas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE200 || allowedTypes.documentE200.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e200"
                                    [fControl]="form.controls[selectedDocName]"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE200 || allowedTypes.documentE200.disabled)
                                    "
                                    (radioChange)="onTypeChange()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--if e003-->
                                <div
                                    class="form-group form-check ms-4"
                                    *ngIf="
                                        allowedTypes.encounterType === 'inpatient' &&
                                        form.controls[selectedDocName].value === DocumentTagEnum.e200
                                    "
                                >
                                    <app-checkbox
                                        [inputId]="DocumentTagEnum.e200FromE003"
                                        [checkboxName]="DocumentTagEnum.e200FromE003"
                                        [labelText]="'Pridėti siuntimą'"
                                        [ngClass]="{ 'text-secondary': allowedTypes.documentE200.reasons.noOrders }"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                        [checkboxDisabled]="isDisabled()"
                                    />
                                    <span
                                        class="small"
                                        *ngIf="isDisabled() && !allowedTypes.documentE200.reasons.noOrders"
                                    >
                                        Atvykime nėra dalinai patvirtintos stacionaro epikrizės, todėl dokumentą galite
                                        pildyti tik pagal siuntimą.
                                    </span>
                                    <span
                                        class="small"
                                        *ngIf="isDisabled() && allowedTypes.documentE200.reasons.noOrders"
                                    >
                                        Šio dokumento negalite pildyti, nes nėra aktyvaus laboratorinio tyrimo siuntimo.
                                    </span>
                                </div>
                                <!--enf if-->
                                <!--if e200 order is disabled show one of reazons -->
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.encounterType !== 'inpatient' &&
                                        allowedTypes.documentE200.disabled &&
                                        allowedTypes.documentE200.reasons.noOrders
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes nėra aktyvaus laboratorinio tyrimo skyrimo.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE200.disabled && allowedTypes.documentE200.reasons.noOrders
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes nėra aktyvaus laboratorinio tyrimo siuntimo.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE200.disabled &&
                                        allowedTypes.documentE200.reasons.allSigned
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes pateikta stacionaro epikrizė jau pasirašyta.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE200.disabled &&
                                        allowedTypes.documentE200.reasons.noDocuments
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes stacionaro epikrizė dar nepateikta.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE200a.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e200a"
                                    [radioName]="selectedDocName"
                                    labelText="E200-ats Laboratorinio tyrimo rezultatų (duomenų) protokolas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE200a || allowedTypes.documentE200a.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e200a"
                                    [fControl]="form.controls[selectedDocName]"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE200a || allowedTypes.documentE200a.disabled)
                                    "
                                    (radioChange)="onTypeChange()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--if e003-->
                                <div
                                    class="form-group form-check ms-4"
                                    *ngIf="
                                        allowedTypes.encounterType == 'inpatient' &&
                                        form.controls[selectedDocName].value == DocumentTagEnum.e200a
                                    "
                                >
                                    <app-checkbox
                                        [inputId]="DocumentTagEnum.e200aFromE003"
                                        [checkboxName]="DocumentTagEnum.e200aFromE003"
                                        [labelText]="'Pridėti užsakymą'"
                                        [ngClass]="{ 'text-secondary': allowedTypes.documentE0200a.reasons.noOrders }"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                        [checkboxDisabled]="isDisabled()"
                                    />
                                    <span
                                        class="small"
                                        *ngIf="isDisabled() && allowedTypes.documentE200a.reasons.noOrders"
                                    >
                                        Šio dokumento negalite pildyti, nes nėra aktyvaus laboratorinio tyrimo užsakymo.
                                    </span>
                                    <span
                                        class="small"
                                        *ngIf="isDisabled() && !allowedTypes.documentE200a.reasons.noOrders"
                                    >
                                        Atvykime nėra dalinai patvirtintos stacionaro epikrizės, todėl dokumentą galite
                                        pildyti tik pagal siuntimą.
                                    </span>
                                </div>
                                <!--enf if-->
                                <!--if e200a order is disabled show one of reazons -->
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE200a.disabled &&
                                        allowedTypes.documentE200a.reasons.noOrders
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes nėra aktyvaus laboratorinio tyrimo užsakymo.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE200a.disabled &&
                                        allowedTypes.documentE200a.reasons.allSigned
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes pateikta stacionaro epikrizė jau pasirašyta.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE200a.disabled &&
                                        allowedTypes.documentE200a.reasons.noDocuments
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes stacionaro epikrizė dar nepateikta.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE014.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e014"
                                    [radioName]="selectedDocName"
                                    labelText="E014 Patologijos tyrimo užsakymas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE014 || allowedTypes.documentE014.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e014"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE014 || allowedTypes.documentE014.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if e014 order is disabled show one of reazons -->
                                <span
                                    class="small"
                                    *ngIf="allowedTypes.documentE014.disabled && allowedTypes.documentE014.disabled"
                                >
                                    Šio dokumento negalite pildyti, nes nėra aktyvaus patologijos skyrimo.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE014a.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e014a"
                                    [radioName]="selectedDocName"
                                    labelText="E014-ats Patologijos tyrimo atsakymas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE014a || allowedTypes.documentE014a.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e014a"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE014a || allowedTypes.documentE014a.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if e014a order is disabled show one of reazons -->
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE014a.disabled &&
                                        allowedTypes.documentE014a.disabled &&
                                        allowedTypes.documentE014a.reasons.noOrders
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes nėra aktyvaus patologijos tyrimo užsakymo.
                                </span>
                                <span
                                    class="small"
                                    *ngIf="
                                        allowedTypes.documentE014a.disabled &&
                                        allowedTypes.documentE014a.disabled &&
                                        allowedTypes.documentE014a.reasons.notVirtual
                                    "
                                >
                                    Šio dokumento negalite pildyti, nes aspilankymo tipas nėra Virtualus.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentE063?.reasons?.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e063"
                                    [radioName]="selectedDocName"
                                    labelText="E063 Vakcinacijos įrašas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE063 || allowedTypes.documentE063.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e063"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE063 || allowedTypes.documentE063.disabled)
                                    "
                                />
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.document025_5a?.reasons?.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.f025"
                                    [radioName]="selectedDocName"
                                    labelText="025-5/a Klausimynas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.document025_5a || allowedTypes.document025_5a.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.f025"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.document025_5a || allowedTypes.document025_5a.disabled)
                                    "
                                />
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.document058a?.reasons?.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.f058"
                                    [radioName]="selectedDocName"
                                    labelText="058/a Pranešimas apie nustatytą infekcinį susirgimą"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.document058a || allowedTypes.document058a.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.f058"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.document058a || allowedTypes.document058a.disabled)
                                    "
                                />
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.document090a?.reasons?.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.f090"
                                    [radioName]="selectedDocName"
                                    labelText="090/a Pranešimas apie nustatytą onkologinį surigimą"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.document090a || allowedTypes.document090a.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.f090"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.document090a || allowedTypes.document090a.disabled)
                                    "
                                />
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.documentUnsorted?.reasons?.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.unsorted"
                                    [radioName]="selectedDocName"
                                    labelText="Įkelti papildomus dokumentus"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentUnsorted || allowedTypes.documentUnsorted.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.unsorted"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(
                                            !allowedTypes.documentUnsorted || allowedTypes.documentUnsorted.disabled
                                        )
                                    "
                                />
                            </div>
                        </li>
                        <li *ngIf="!allowedTypes.document110a?.reasons?.ac" class="last">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e110"
                                    [radioName]="selectedDocName"
                                    labelText="GMP kortelė"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedTypes.documentE110 || allowedTypes.documentE110.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e110"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedTypes.documentE110 || allowedTypes.documentE110.disabled)
                                    "
                                />
                            </div>
                        </li>
                    </ol>
                </div>

                <div class="tab-pane fade" id="medCertsPanel" role="tabpanel" aria-labelledby="medCerts" tabindex="0">
                    <ol class="slats">
                        <li *ngIf="!allowedCerts.documentE103_1.reasons.ac">
                            <!--after submit open E103-1 new-->
                            <!--radio disabled if user alredy have E103-1-->
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e103_1"
                                    [radioName]="selectedDocName"
                                    labelText="E103-1 Vaiko gimimo pažymėjimas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE103_1 || allowedCerts.documentE103_1.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e103_1"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE103_1 || allowedCerts.documentE103_1.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--E103 disabled show one of reasons-->
                                <span class="small" *ngIf="allowedCerts.documentE103_1.reasons.virtual">
                                    Šio tipo dokumento negalite pildyti, nes tai virtualus apsilankymas.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE103_1.reasons.exists">
                                    Šio tipo dokumento negalite pildyti, nes šis dokumentas jau egzistuoja.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE103_1.reasons.noMother">
                                    Šio dokumento negalite pildyti, nes pacientas neturi susijusio asmens "mama".
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE103_1.reasons.noBirthDate">
                                    Šio tipo dokumento negalite pildyti, nes pacientui nenurodyta gimimo data
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedCerts.documentE106_2_1.reasons.ac">
                            <!--after submit open E102-2-1 new-->
                            <div class="fw-bold mb-0">
                                <!--radio disabled if ( encounter is not patient in ) && (patient age > 6 days && patient gender men)-->
                                <app-radio-button
                                    [id]="DocumentTagEnum.e106_2_1"
                                    [radioName]="selectedDocName"
                                    labelText="E106-2-1 Medicininis perinatalinės mirties liudijimas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE106_2_1 || allowedCerts.documentE106_2_1.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e106_2_1"
                                    [fControl]="form.controls[selectedDocName]"
                                    [radioDisabled]="
                                        Boolean(
                                            !allowedCerts.documentE106_2_1 || allowedCerts.documentE106_2_1.disabled
                                        )
                                    "
                                    (radioChange)="onTypeChange()"
                                />
                            </div>

                            <div class="mb-0">
                                <!--mother esi-->
                                <!--show if exist more signed E106-2-1 in mother esi-->
                                <div
                                    class="form-group form-check ms-4"
                                    *ngIf="form.controls[selectedDocName].value == DocumentTagEnum.e106_2_1"
                                >
                                    <app-checkbox
                                        [inputId]="DocumentTagEnum.bornDead"
                                        [checkboxName]="DocumentTagEnum.bornDead"
                                        [labelText]="'Mirusysis neregistruotas pacientų kataloge'"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                        [checkboxDisabled]="allowedCerts.documentE106_2_1.onlyBornDead"
                                    />
                                    <span class="small" *ngIf="allowedCerts.documentE106_2_1.onlyBornDead">
                                        Jei pacientas - miręs naujagimis, jam būtinas susijęs asmuo "mama".
                                    </span>
                                </div>
                                <span class="small" *ngIf="allowedCerts.documentE106_2_1.disabled">
                                    Šio tipo dokumento negalite pildyti, nes tai apsilankymas į SPĮ gauti ambulatorinių
                                    paslaugų.
                                </span>
                            </div>
                        </li>
                        <!--after submit open E106 new-->
                        <li *ngIf="!allowedCerts.documentE106.reasons.ac">
                            <div class="fw-bold mb-0">
                                <!--radio disabled if ( encounter is not patient in ) && (patient age > 6 days && patient gender men)-->
                                <app-radio-button
                                    [id]="DocumentTagEnum.e106"
                                    [radioName]="selectedDocName"
                                    labelText="E106 Medicininis mirties liudijimas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE106 || allowedCerts.documentE106.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e106"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE106 || allowedCerts.documentE106.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if E106 disabled show one of reasons -->
                                <span class="small" *ngIf="allowedCerts.documentE106.reasons.virtual">
                                    Šio tipo dokumento negalite pildyti, nes tai virtualus apsilankymas.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE106.reasons.exists">
                                    Šio tipo dokumento negalite pildyti, nes tokia pažyma pacientui jau išrašyta.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedCerts.documentE027_1.reasons.ac">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e027_1"
                                    [radioName]="selectedDocName"
                                    labelText="E027-1 Mokinio sveikatos pažymėjimas"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE027_1 || allowedCerts.documentE027_1.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e027_1"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE027_1 || allowedCerts.documentE027_1.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--after submit open E027-1 I new-->
                                <div
                                    class="radio ms-4"
                                    *ngIf="
                                        !allowedCerts.documentE027_1.reasons.ac_I &&
                                        form.controls[selectedDocName].value == DocumentTagEnum.e027_1
                                    "
                                >
                                    <app-radio-button
                                        [id]="DocumentTagEnum.e027_1_I"
                                        [radioName]="selectedDocTypeName"
                                        labelText="I. Sveikatos būklės įvertinimas"
                                        [radioValue]="DocumentTagEnum.e027_1_I"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                    />
                                </div>
                                <!--after submit open E027-1 II new-->
                                <div
                                    class="radio ms-4"
                                    *ngIf="
                                        !allowedCerts.documentE027_1.reasons.ac_II &&
                                        form.controls[selectedDocName].value == DocumentTagEnum.e027_1
                                    "
                                >
                                    <app-radio-button
                                        [id]="DocumentTagEnum.e027_1_II"
                                        [radioName]="selectedDocTypeName"
                                        labelText="II. Dantų ir žandikaulių būklės įvertinimas"
                                        [radioValue]="DocumentTagEnum.e027_1_II"
                                        [fControl]="form.controls[selectedDocTypeName]"
                                    />
                                </div>
                                <span class="small" *ngIf="allowedCerts.documentE027_1.reasons.virtual">
                                    Šio tipo dokumentas prie virtualus apsilankymo pildomas iš užduoties.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE027_1.reasons.activeTasks">
                                    Šio tipo dokumento negalite pildyti, nes sistemoje yra aktyvių šios pažymos kūrimo
                                    užduočių.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE027_1.reasons.wrongEncounterType">
                                    Šio tipo dokumento negalite pildyti, nes tai apsilankymas stacionare.
                                </span>
                            </div>
                        </li>
                        <!--after submit open E106 new-->
                        <li *ngIf="!allowedCerts.documentE047.reasons.ac">
                            <div class="fw-bold mb-0">
                                <!--radio disabled if ( encounter is not patient in ) && (patient age > 6 days && patient gender men)-->
                                <app-radio-button
                                    [id]="DocumentTagEnum.e047"
                                    [radioName]="selectedDocName"
                                    labelText="E047 Privalomojo sveikatos patikrinimo medicininė pažyma"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE047 || allowedCerts.documentE047.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e047"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE047 || allowedCerts.documentE047.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if E106 disabled show one of reasons -->
                                <span class="small" *ngIf="allowedCerts.documentE047.disabled">
                                    Šio tipo dokumentas pildomas iš užduoties.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <!--after submit open E106 new-->
                        <li *ngIf="!allowedCerts.documentE048.reasons.ac">
                            <div class="fw-bold mb-0">
                                <!--radio disabled if ( encounter is not patient in ) && (patient age > 6 days && patient gender men)-->
                                <app-radio-button
                                    [id]="DocumentTagEnum.e048"
                                    [radioName]="selectedDocName"
                                    labelText="E048 Asmens medicininė knygelė (sveikatos pasas)"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE048 || allowedCerts.documentE048.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e048"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE048 || allowedCerts.documentE048.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if E106 disabled show one of reasons -->
                                <span class="small" *ngIf="allowedCerts.documentE048.disabled">
                                    Šio tipo dokumentas pildomas iš užduoties.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedCerts.documentE049.reasons.ac">
                            <div class="fw-bold mb-0">
                                <!--radio disabled if ( encounter is not patient in ) && (patient age > 6 days && patient gender men)-->
                                <app-radio-button
                                    [id]="DocumentTagEnum.e049"
                                    [radioName]="selectedDocName"
                                    labelText="E049 Asmens privalomojo sveikatos tikrinimo kortelė"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE049 || allowedCerts.documentE049.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e049"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE049 || allowedCerts.documentE049.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if E106 disabled show one of reasons -->
                                <span class="small" *ngIf="allowedCerts.documentE049.reasons.virtual">
                                    Šio tipo dokumento negalite pildyti, nes tai virtualus apsilankymas.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE049.reasons.wrongEncountType">
                                    Šio tipo dokumento negalite pildyti, nes tai apsilankymas stacionare.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE049.reasons.activeTasks">
                                    Šio tipo dokumento negalite pildyti, nes yra aktyvių užduočių susijusių su
                                    dokumentu.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                        <li *ngIf="!allowedCerts.documentE083_1.reasons.ac" class="last">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e083_1"
                                    [radioName]="selectedDocName"
                                    labelText="E083-1 Vairuotojo sveikatos patikrinimo medicininė pažyma"
                                    [labelClass]="'fw-normal'"
                                    [ngClass]="{
                                        'text-secondary':
                                            !allowedCerts.documentE083_1 || allowedCerts.documentE083_1.disabled
                                    }"
                                    [radioValue]="DocumentTagEnum.e083_1"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                    [radioDisabled]="
                                        Boolean(!allowedCerts.documentE083_1 || allowedCerts.documentE083_1.disabled)
                                    "
                                />
                            </div>
                            <div class="mb-0">
                                <!--if E083-1 disabled show one of reasons -->
                                <span class="small" *ngIf="allowedCerts.documentE083_1.reasons.virtual">
                                    Šio tipo dokumento negalite pildyti, nes tai virtualus apsilankymas.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE083_1.reasons.wrongEncounterType">
                                    Šio tipo dokumento negalite pildyti, nes tai apsilankymas stacionare.
                                </span>
                                <span class="small" *ngIf="allowedCerts.documentE083_1.reasons.exists">
                                    Šio tipo dokumento negalite pildyti, nes tokia pažyma pacientui pradėta kurti bet
                                    nebaigta.
                                </span>
                                <!--end if-->
                            </div>
                        </li>
                    </ol>
                </div>
                <div class="tab-pane fade" id="templatesPanel" role="tabpanel" aria-labelledby="templates" tabindex="0">
                    <template-load
                        [templates]="templates"
                        [templatesCount]="templatesCount"
                        [templatesTotal]="templatesTotal"
                        (selectedTemplate)="receiveSelectedTemplate($event)"
                    />
                </div>
                <div class="tab-pane fade" id="ngnPanel" role="tabpanel" aria-labelledby="ngn" tabindex="0">
                    <ol class="slats">
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e113_nd"
                                    [radioName]="selectedDocName"
                                    labelText="E113-ND Nėštumo duomenys, anamnezė ir priežiūros planas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e113_nd"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--      <span class="small" *ngIf="!allowedCerts.documentE113_ND || allowedCerts.documentE113_ND.disabled">
                                          Šio tipo dokumento negalite pildyti.
                                      </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e113_ap"
                                    [radioName]="selectedDocName"
                                    labelText="E113-AP Nėščiosios ambulatorinio apsilankymo aprašymas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e113_ap"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_AP || allowedCerts.documentE113_AP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e113_rv"
                                    [radioName]="selectedDocName"
                                    labelText="E113-RV Rizikos veiksniai"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e113_rv"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--<span class="small" *ngIf="!allowedCerts.documentE113_RV || allowedCerts.documentE113_RV.disabled">
                                    Šio tipo dokumento negalite pildyti.
                                </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e113_ug"
                                    [radioName]="selectedDocName"
                                    labelText="E113-UG Vaisiaus ultragarsinis tyrimas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e113_ug"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_UG || allowedCerts.documentE113_UG.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e113_tp"
                                    [radioName]="selectedDocName"
                                    labelText="E113-TP Tyrimai ir procedūros nėštumo metu"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e113_tp"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e113_nna"
                                    [radioName]="selectedDocName"
                                    labelText="E113-NNA Nėštumo nutraukimo aprašymas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e113_nna"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e097_npa"
                                    [radioName]="selectedDocName"
                                    labelText="E097-NPA Naujagimio pirma apžiūra"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e097_npa"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e097_ts"
                                    [radioName]="selectedDocName"
                                    labelText="E097-TS Tėvų sutikimas dėl naujagimiui atliekamų procedūrų"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e097_ts"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e097_at"
                                    [radioName]="selectedDocName"
                                    labelText="E097-AT Atlikti tikrinimai"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e097_at"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e097_gd"
                                    [radioName]="selectedDocName"
                                    labelText="E097-GD Gydytojo dienynas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e097_gd"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_nt"
                                    [radioName]="selectedDocName"
                                    labelText="E096-NT Nėščiosios tyrimas, gimdymo priežiūros planas, paskyrimai"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_nt"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_ptkl"
                                    [radioName]="selectedDocName"
                                    labelText="E096-PTKL Pagalbos teikimo kontrolinis lapas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_ptkl"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_n"
                                    [radioName]="selectedDocName"
                                    labelText="E096-N Naujagimis (-iai)"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_n"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_ge"
                                    [radioName]="selectedDocName"
                                    labelText="E096-GE Gimdymo eiga"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_ge"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_na"
                                    [radioName]="selectedDocName"
                                    labelText="E096-NA Nėštumo, gimdymo ar pogimdyminiu laikotarpio arti netekties ir netekties atvejai"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_na"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_cpo"
                                    [radioName]="selectedDocName"
                                    labelText="E096-CPO Cezario pjūvio operacijos protokolas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_cpo"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.e096_part"
                                    [radioName]="selectedDocName"
                                    labelText="E096-PA Partograma"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.e096_part"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_TP || allowedCerts.documentE113_TP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                    </ol>
                </div>
                <div class="tab-pane fade" id="aspnPanel" role="tabpanel" aria-labelledby="aspn" tabindex="0">
                    <ol class="slats">
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.spu"
                                    [radioName]="selectedDocName"
                                    labelText="Slaugos procedūrų užsakymas (Paskyrimas)"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.spu"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--      <span class="small" *ngIf="!allowedCerts.documentE113_ND || allowedCerts.documentE113_ND.disabled">
                                          Šio tipo dokumento negalite pildyti.
                                      </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.spnk"
                                    [radioName]="selectedDocName"
                                    labelText="Slaugos poreikių nustatymo klausimynas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.spnk"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_AP || allowedCerts.documentE113_AP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.sbvf"
                                    [radioName]="selectedDocName"
                                    labelText="Sveikatos būklės vertinimo forma"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.sbvf"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--<span class="small" *ngIf="!allowedCerts.documentE113_RV || allowedCerts.documentE113_RV.disabled">
                                    Šio tipo dokumento negalite pildyti.
                                </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.vnapvf"
                                    [radioName]="selectedDocName"
                                    labelText="Veiklos ir namų aplinkos vertinimo forma"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.vnapvf"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_UG || allowedCerts.documentE113_UG.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.spp"
                                    [radioName]="selectedDocName"
                                    labelText="Slaugos ir priežiūros planas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.spp"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_UG || allowedCerts.documentE113_UG.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                    </ol>
                </div>
                <div class="tab-pane fade" id="recipesPanel" role="tabpanel" aria-labelledby="recipes" tabindex="0">
                    <ol class="slats">
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.vps"
                                    [radioName]="selectedDocName"
                                    labelText="Vaistinio preparato skyrimas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.vps"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--      <span class="small" *ngIf="!allowedCerts.documentE113_ND || allowedCerts.documentE113_ND.disabled">
                                          Šio tipo dokumento negalite pildyti.
                                      </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.mpp"
                                    [radioName]="selectedDocName"
                                    labelText="MPP skyrimas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.mpp"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_AP || allowedCerts.documentE113_AP.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                        <li>
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.mp"
                                    [radioName]="selectedDocName"
                                    labelText="MP akinių/lęšių skyrimas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.mp"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!--<span class="small" *ngIf="!allowedCerts.documentE113_RV || allowedCerts.documentE113_RV.disabled">
                                    Šio tipo dokumento negalite pildyti.
                                </span>-->
                            </div>
                        </li>
                        <li class="last">
                            <div class="fw-bold mb-0">
                                <app-radio-button
                                    [id]="DocumentTagEnum.evs"
                                    [radioName]="selectedDocName"
                                    labelText="Ekstemporalaus vaisto skyrimas"
                                    [labelClass]="'fw-normal'"
                                    [radioValue]="DocumentTagEnum.evs"
                                    [fControl]="form.controls[selectedDocName]"
                                    (radioChange)="onSelectDoc()"
                                />
                            </div>
                            <div class="mb-0">
                                <!-- <span class="small" *ngIf="!allowedCerts.documentE113_UG || allowedCerts.documentE113_UG.disabled">
                                     Šio tipo dokumento negalite pildyti.
                                 </span>-->
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </form>
    </ng-container>
</modal>
<order-select [parentModalId]="modalId" [parentModalCloseId]="modalCloseId" />
<certificate-modal [parentModalId]="modalId" [parentModalCloseId]="modalCloseId" [patient]="patient" />
<finished-document-select [parentModalId]="modalId" [parentModalCloseId]="modalCloseId" [patient]="patient" />
