angular.module('medvais.api').factory('MedvaisOrganizationApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/organization';
    
    return $resource('', {}, {
        
        search: {
            method: 'GET',
            url: URL_BASE + '/search',
            isArray: true
        },

        getById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        }
        
    });
    
    
}]);

