import { getTemplateComponent } from '../../../../../utils/hypridHelper';
import { E030Component } from './e030.component';
import {E030ViewComponent} from "./e030-view.component";


ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding e030 routes");

        $routeSegmentProvider
            .when(`/dp/patients/:patientId/e030`, "DP.DP_PATIENT2L.NGN_E030_EDIT")
            .when(`/dp/patients/:patientId/e030/view`, "DP.DP_PATIENT2L.NGN_E030_VIEW");


        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2L")
            .segment("NGN_E030_EDIT", {template: getTemplateComponent(E030Component)})
            .segment("NGN_E030_VIEW", {template: getTemplateComponent(E030ViewComponent)});
    },
]);
