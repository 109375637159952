ng1App.directive('cancelAgreement', 
    ['$modal', 'DocumentsApiFactory', 'MessageService',
     function ($modal, DocumentsApiFactory, MessageService) {

    var createModal = function (scope) {
        return $modal({
            scope: scope,
            title: 'Sutikimo atšaukimas',
            contentTemplate: '/templates/components/agreements/cancel_agreement.html'
        });
    };

    return {
        restrict: 'A',
        scope: {
            agreement: '=cancelAgreement'
        },
        link: function (scope: any, element, attrs) {
            var modal;
            scope.form = {};

            scope.dlgBtnYesClick = function () {
                if (scope.form.cancelReason) {
                    scope.invalid = false;
                    DocumentsApiFactory.cancelAgreement({agreementId: scope.agreement.id}, scope.form, function () {
                        scope.agreement.canceled = true;
                        modal.hide();
                    }, function (error) {
                        _.handleValidationErrors(error.data, MessageService);
                        modal.hide();
                    });
                } else {
                    scope.invalid = true;
                }
            };
            scope.dlgBtnNoClick = function () {
                modal.hide();
            };
            element.on('click', function () {
                modal = createModal(scope);
                modal.$promise.then(function () {
                    modal.show();
                });
            });
        }
    };
}]);

