import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Form090Model } from "src/app/shared/models/090.model";
import { EntityModel } from "src/app/shared/models/entity.model";
import { PortalTypeEnum } from "src/app/shared/models/portalType.enum";
import { DocRefConsentTemplateService } from "src/app/shared/services/doc-ref-consent-template.service";
import { DocRefConsentService } from "src/app/shared/services/doc-ref-consent.service";

@Component({
    selector: 'doc-ref-f090-card',
    templateUrl: './doc-ref-f090-form-card.component.html'
})
export class DocRefF090CardComponent implements OnInit {

    @Input() data: Form090Model;
    @Input() badgeColor: string;
    @Input() badgeTitle: string;
    @Input() dateBetween: string;
    @Input() practitionerFullName: string;
    @Input() patientFullName: string;
    @Input() portalType: PortalTypeEnum;
    @Input() patientId: string;
    @Input() onCardClick: (id: string, documentType: EntityModel) => void;
    @Input() onEditClick: (id: string, documentType: EntityModel) => void;

    constructor(private router: Router, private docRefConsentService: DocRefConsentService, private docRefConsentTemplateService: DocRefConsentTemplateService) {
    }

    ngOnInit(): void {
        this.practitionerFullName = this.formatFullName(this.data.author);
    }

    formatFullName(response){
        var formatName = _.getFullName(response);
        return formatName;
    }

    canEdit(): boolean {
        return this.portalType === PortalTypeEnum.AP;
    }
}
