import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {MessageService} from "./utils/message.service";
import {messages} from "nx/src/utils/ab-testing";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private messages: MessageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    // Handle the error
                    // this.messages.handleError(error?.error)
                    return throwError(() => error);
                })
            );
    }
}