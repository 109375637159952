/* global app */

// Any changes that are made in app-title-sc should be reflected in app-title component too (title.component.ts|html)
ng1App.directive('appTitleSc', [function () {
    return {
        templateUrl: '/templates/components/common/app-title-sc.html',
        restrict: 'EA',
        replace: false,
        transclude: {
            'aside': '?aside',
            'content': '?content',
        },
        scope: {
            backPath: '@',
            backTitle: '@',
            backSubtitle: '@',
            backInline: '@',
            badgeText: '@',
            badgeClass: '@',
            showFilterButton: '@',
        },
    };
}]);

ng1App.directive('saveCleanForm',
    ['FormReloadService',
     function (FormReloadService) {
    return {
        templateUrl: '/templates/components/common/save-clean-form.html',
        restrict: 'E',
        replace: false,
        scope: {
            save: '&',
            clean: '&',
            saving: '=isSaving',
            cleanType: '@'
        },
        link: function (scope: any, element, attrs) {
            scope.cleanForm = function () {
                switch (scope.cleanType) {
                    case 'reload':
                        FormReloadService.reloadForm(true);
                        break;
                    case 'custom':
                        scope.clean();
                        break;
                    default :
                        break;
                }
            };
        }
    };
}]);


ng1App.directive('listBlock', function () {
    return {
        templateUrl: '/templates/components/common/list-block.html',
        restrict: 'E',
        replace: false,
        transclude: true,
        scope: {
            list: '=',
            showLoadMore: '=',
            loadMore: '&',
            templateUrl: '=',
            loading: '=',
            handlers: '=',
            hover: '=',
            pageCounter: '=',
            loadMoreTitle: '@',
            emptyListTitle: '@'
        }
    };
});

ng1App.directive('skypeLink', function () {
    return {
        templateUrl: '/templates/components/common/skype-link.html',
        restrict: 'E',
        replace: false,
        scope: {
            skypeName: '@',
            linkText: '@'
        }
    };
});

/**
 * @deprecated since migration to angular.
 * Now using quick-search.component.ts downgraded component.
 */
ng1App.directive('_quickSearch', function () {
    return {
        templateUrl: '/templates/components/common/quick-search.html',
        restrict: 'E',
        replace: false,
        scope: {
            name: '@',
            form: '=',
            propertyName: '@',
            loading: '=',
            onQuick: '&',
            onAdvanced: '&',
            onClear: '&',
            isAdvanced: '='
        }
    };
});

ng1App.directive('quickSearchSc', function () {
    return {
        templateUrl: '/templates/components/common/quick-search-sc.html',
        restrict: 'E',
        replace: false,
        scope: {
            name: '@',
            searchCtrl: '=',
            propertyName: '@',
            isAdvanced: '='
        }
    };
});

ng1App.directive('customSearchSc', function () {
    return {
        templateUrl: '/templates/components/common/custom-search-sc.html',
        restrict: 'E',
        replace: false,
        transclude: true,
        scope: {
            name: '@',
            searchCtrl: '=',
            templateUrl: '=',
            propertyName: '@',
            isAdvanced: '=',
            form: '='
        }
    };
});

ng1App.directive('practitionerLink', function () {
    return {
        templateUrl: '/templates/components/common/practitioner-link.html',
        restrict: 'E',
        replace: false,
        scope: {
            practitioner: '=',
            qualification: '=',
            newTab: '@'
        }
    };
});

ng1App.directive('mainMenu',
    ['$location',
     function ($location) {
    return {
        templateUrl: '/templates/components/common/main-menu.html',
        restrict: 'E',
        replace: true,
        scope: {
            menu: '='
        },
        link: function (scope: any, element, attrs) {
            scope.getClass = function (item) {
                var result = '';

                function checkActive(item) {
                    if (item.url.length > 0 && $location.path().substr(0, item.url.length) === item.url) {
                        result = 'active';
                    }
                }

                if (item.children) {
                    _.forEach(item.children, checkActive)
                } else {
                    checkActive(item);
                }

                if (item.disabled) {
                    result += ' disabled';
                }
                return result;
            };
        }
    };
}]);

ng1App.directive('dropDown', function () {
    return {
        templateUrl: '/templates/components/common/drop-down.html',
        restrict: 'E',
        replace: true,
        scope: {
            menu: '='
        },
        link: function (scope: any, element, attrs) {
            scope.hasMain = function () {
                var has = false;
                angular.forEach(scope.menu.main, function (item) {
                    if (scope.isVisible(item)) {
                        has = true;
                    }
                });
                return has;
            };

            scope.firstVisibleFromCtx = function () {
                var found;
                angular.forEach(scope.menu.dropDown, function (item) {
                    if (item.type !== 'separator' && !found && scope.isVisible(item)) {
                        found = item;
                    }
                });
                return found;
            };

            scope.isVisible = function (item, index: number, last) {
                if (item.type === 'separator') {
                    if (index === 0 || last) {
                        return false;
                    }

                    var before = false;
                    var after = false;

                    for (var i = index - 1; i >= 0; i--) {
                        var tmp = scope.menu.dropDown[i];
                        if (tmp.type === 'separator') {
                            before = false;
                            break;
                        } else {
                            if (scope.isVisible(tmp)) {
                                before = true;
                                break;
                            }
                        }
                    }

                    for (var i = index + 1; i < scope.menu.dropDown.length; i++) {
                        var tmp = scope.menu.dropDown[i];
                        if (tmp.type === 'separator') {
                            after = true;
                            break;
                        } else {
                            if (scope.isVisible(tmp)) {
                                after = true;
                                break;
                            }
                        }
                    }

                    return after && before;
                }
                return item.isVisible ? item.isVisible() : true;
            };

            scope.hasDropDown = function () {
                var has = false;
                angular.forEach(scope.menu.dropDown, function (item) {
                    if (item.type !== 'separator' && scope.isVisible(item)) {
                        has = true;
                    }
                });
                return has;
            };

            scope.isDisabled = function (item) {
                return item.isDisabled ? item.isDisabled() : false;
            };
        }
    };
});
