export enum PregnancyFilterNames {
    PregnancyData = "E113-ND Nėštumo duomenys, anamnezė ir priežiūros planas",
    AmbulatoryDescription = "E113-AP Nėščiosios ambulatorinis aprašymas",
    RiskFactors = "E113-RV Rizikos veiksniai",
    UltrasoundFetalExamination = "E113-UG Vaisiaus ultragarsinis tyrimas",
    ExaminationsProceduresDuringPregnancy = "E113-TP Tyrimai ir procedūros nėštumo metu",
    AbortationDescription = "E113-NNA Nėštumo nutraukimo aprašymas",
    FirstNewbornCheckup = "E097-NPA Naujagimio pirma apžiūra",
    ParentalConsent = "E097-TS Tėvų sutikimas dėl naujagimiui atliekamų procedūrų",
}

export const YesNoOptions = [
    {
        name: "Taip",
        value: true,
    },
    {
        name: "Ne",
        value: false,
    },
];

export const NGNDocumentsTypes = [
    { value: "e113nd", name: PregnancyFilterNames.PregnancyData },
    { value: "e113ap", name: PregnancyFilterNames.AmbulatoryDescription },
    { value: "e113nna", name: PregnancyFilterNames.AbortationDescription },
    { value: "e113rv", name: PregnancyFilterNames.RiskFactors },
    { value: "e113tp", name: PregnancyFilterNames.ExaminationsProceduresDuringPregnancy },
    { value: "e113ug", name: PregnancyFilterNames.UltrasoundFetalExamination },
];
