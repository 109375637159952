ng1App.directive('integer', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var INTEGER_REGEXP = /^\-?\d+$/;
            ctrl.$parsers.unshift(function (viewValue) {
                if (INTEGER_REGEXP.test(viewValue) || _.isEmpty(viewValue)) {
                    // it is valid
                    ctrl.$setValidity('integer', true);
                    return _.isEmpty(viewValue) ? null : viewValue;
                } else {
                    // it is invalid, return undefined (no model update)
                    ctrl.$setValidity('integer', false);
                    return undefined;
                }
            });
        }
    };
});

ng1App.directive('smartFloat', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var FLOAT_REGEXP = /^\-?\d+((\.|\,)\d+)?$/;
            ctrl.$parsers.unshift(function (viewValue) {
                if (FLOAT_REGEXP.test(viewValue) || _.isEmpty(viewValue)) {
                    ctrl.$setValidity('float', true);
                    return _.isEmpty(viewValue) ? null : parseFloat(viewValue.replace(',', '.'));
                } else {
                    ctrl.$setValidity('float', false);
                    return undefined;
                }
            });

            if (attrs.smartFloat && parseInt(attrs.smartFloat) > 0) {
                ctrl.$formatters.unshift(function (val) {
                    if (!isNaN(val)) {
                        var res = Number(val).toFixed(parseInt(attrs.smartFloat)).toString();
                        return res.replace('.', ',');
                    } else {
                        return val;
                    }
                });
                elm.bind('blur', function () {
                    ctrl.$render()
                });
            }
        }
    };
});

ng1App.directive('moreThan', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var check = function (value, moreThan) {
                return value > moreThan || value == null || value == undefined || isNaN(value);
            };
            ctrl.$parsers.push(function (value) {
                if (check(value, scope.$eval(attrs.moreThan))) {
                    ctrl.$setValidity('moreThan', true);
                    return value;
                } else {
                    ctrl.$setValidity('moreThan', false);
                    return value;
                }
            });
            ctrl.$formatters.push(function (value) {
                ctrl.$setValidity("moreThan", check(value, scope.$eval(attrs.moreThan)));
                return value;
            });
        }
    };
});

ng1App.directive('lessThan', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var check = function (value, lessThan) {
                return value < lessThan || value == null || value == undefined || isNaN(value);
            };
            ctrl.$parsers.push(function (value) {
                if (check(value, scope.$eval(attrs.lessThan))) {
                    ctrl.$setValidity('lessThan', true);
                    return value;
                } else {
                    ctrl.$setValidity('lessThan', false);
                    return value;
                }
            });
            ctrl.$formatters.push(function (value) {
                ctrl.$setValidity("lessThan", check(value, scope.$eval(attrs.lessThan)));
                return value;
            });
        }
    };
});

ng1App.directive('notLessThan', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var check = function (value, moreThan) {
                return value >= moreThan || value == null || value == undefined;
            };
            ctrl.$parsers.push(function (value) {
                if (check(value, scope.$eval(attrs.notLessThan))) {
                    ctrl.$setValidity('notLessThan', true);
                    return value;
                } else {
                    ctrl.$setValidity('notLessThan', false);
                    return value;
                }
            });
            ctrl.$formatters.push(function (value) {
                ctrl.$setValidity("notLessThan", check(value, scope.$eval(attrs.notLessThan)));
                return value;
            });
        }
    };
});

ng1App.directive('notMoreThan', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var check = function (value, moreThan) {
                return value <= moreThan || value == null || value == undefined;
            };
            ctrl.$parsers.push(function (value) {
                if (check(value, scope.$eval(attrs.notMoreThan))) {
                    ctrl.$setValidity('notMoreThan', true);
                    return value;
                } else {
                    ctrl.$setValidity('notMoreThan', false);
                    return value;
                }
            });
            ctrl.$formatters.push(function (value) {
                ctrl.$setValidity("notMoreThan", check(value, scope.$eval(attrs.notMoreThan)));
                return value;
            });
        }
    };
});

ng1App.directive('valueLessOrMore', 
    ['$filter', '$parse',
     function ($filter, $parse) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (value) {
                var type = attrs.valueLessOrMore;
                var targetValidationForm = attrs.targetValidationForm;
                var target = scope.$eval(attrs.targetValue);
                var dateVal = value ? $filter('date')(value, "yyyy-MM-dd") : undefined;
                var targetVal = target ? $filter('date')(target, "yyyy-MM-dd") : undefined;

                var errorState = null;
                var targetValidity = null;
                if (targetValidationForm) {
                    errorState = $parse(targetValidationForm + ".$error." + type);
                    targetValidity = scope.$eval(targetValidationForm);
                }
                if (type == 'lessThan') {
                    if (dateVal <= targetVal || !targetVal || !dateVal) {
                        ctrl.$setValidity('lessThan', true);
                        if (targetValidationForm) {
                            targetValidity.$setValidity('moreThan', true);
                            errorState.assign(scope, false);
                        }
                        return value;
                    } else {
                        ctrl.$setValidity('lessThan', false);
                        return value;
                    }
                } else if (type == 'moreThan') {
                    if (dateVal >= targetVal || !targetVal || !dateVal) {
                        ctrl.$setValidity('moreThan', true);
                        if (targetValidationForm) {
                            targetValidity.$setValidity('lessThan', true);
                            errorState.assign(scope, false);
                        }
                        return value;
                    } else {
                        ctrl.$setValidity('moreThan', false);
                        return value;
                    }
                }
            });
            ctrl.$formatters.unshift(function (value) {
                var type = attrs.valueLessOrMore;
                var target = scope.$eval(attrs.targetValue);
                //var dateVal = value ? Date.parse(value) : undefined;
                //var targetVal = target ? Date.parse(target) : undefined;
                var dateVal = value ? $filter('date')(value, "yyyy-MM-dd") : undefined;
                var targetVal = target ? $filter('date')(target, "yyyy-MM-dd") : undefined;
                if (type == 'lessThan') {
                    scope.$on('errors-reset', function () {
                        ctrl.$setValidity("lessThan", true);
                        return value;
                    });
                    ctrl.$setValidity("lessThan", targetVal ? dateVal <= targetVal : true);
                    return value;
                } else if (type == 'moreThan') {
                    scope.$on('errors-reset', function () {
                        ctrl.$setValidity("moreThan", true);
                        return value;
                    });
                    ctrl.$setValidity("moreThan", targetVal ? dateVal >= targetVal : true);
                    return value;
                }
            });
        }
    };
}]);

ng1App.directive('validDate', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (value) {
                scope.$on('errors-reset', function () {
                    ctrl.$setValidity("validDate", true);
                    return value;
                });
                if (value == undefined && elm.val() != '') {
                    ctrl.$setValidity('validDate', false);
                    return value;
                } else {
                    ctrl.$setValidity('validDate', true);
                    return value;
                }
            });
        }
    };
});

ng1App.directive('bothEntered', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validate = function (value) {
                var other = scope.$eval(attrs.bothEntered);
                if ((value || value == 0) && (other || other == 0) || _.isEmpty(value) && _.isEmpty(other)) {
                    ctrl.$setValidity('bothEntered', true);
                    return value;
                } else {
                    ctrl.$setValidity('bothEntered', false);
                    return value;
                }
            };

            ctrl.$parsers.push(validate);
            ctrl.$formatters.push(validate);

            var observeBothEntered = function (comparisonModel) {
                // Whenever the comparison model changes we'll re-validate
                return validate(ctrl.$viewValue);
            };
            attrs.$observe('bothEntered', observeBothEntered);
        }
    };
});

ng1App.directive('notFutureDate', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (value) {
                scope.$on('errors-reset', function () {
                    ctrl.$setValidity("notFutureDate", true);
                    return value;
                });

                if (value == undefined && elm.val() != '') {
                    ctrl.$setValidity('notFutureDate', true);
                } else {
                    var date = new Date(value);
                    ctrl.$setValidity('notFutureDate', date.getTime() <= (new Date()).getTime());
                }

                return value;
            });
        }
    };
});
ng1App.directive('notPastDate', 
    ['$log',
     function ($log) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (value) {
                scope.$on('errors-reset', function () {
                    ctrl.$setValidity("notPastDate", true);
                    return value;
                });

                if (value == undefined && elm.val() != '') {
                    ctrl.$setValidity('notPastDate', true);
                } else {
                    var date = new Date(value);
                    ctrl.$setValidity('notPastDate', date.getTime() >= new Date().setHours(0, 0, 0, 0));
                }

                return value;
            });
        }
    };
}]);
ng1App.directive('valueIsEntity', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.push(function (value) {
                ctrl.$setValidity('valueIsEntity', !value || value.code);
                return value;
            });
            ctrl.$formatters.push(function (value) {
                ctrl.$setValidity("valueIsEntity", !value || value.code);
                return value;
            });
        }
    };
});
ng1App.directive('positiveValue', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var check = function (value) {
                return value >= 0 || value == null || value == undefined || isNaN(value);
            };
            ctrl.$parsers.push(function (value) {
                if (check(value)) {
                    ctrl.$setValidity('positiveValue', true);
                    return value;
                } else {
                    ctrl.$setValidity('positiveValue', false);
                    return value;
                }
            });
            ctrl.$formatters.push(function (value) {
                ctrl.$setValidity("positiveValue", check(value));
                return value;
            });
        }
    };
});
/**
 * Returns SubFormValidator constructor.
 */
ng1App.factory("SubFormValidator", 
    ['MessageService',
     function (MessageService) {
    function SubFormValidator(regionName) {
        this.regionName = regionName;
        this.invalidFields = {};
    }

    SubFormValidator.prototype = {
        hasErrors: function () {
            for (var f in this.invalidFields) {
                if (this.invalidFields[f] === true) {
                    return true;
                }
            }
            return false;
        },
        add: function (field) {
            this.invalidFields[field] = true;
        },
        clear: function () {
            var _this = this;
            _.each(_this.invalidFields, function (value, prop) {
                delete _this.invalidFields[prop];
            });
            if (MessageService) {
                MessageService.clear(this.regionName);
            }
        },
        showErrors: function () {
            MessageService.add('error', 'Formoje yra klaidų', this.regionName);
        },
        scroll: function () {
            _.scrollTo('[msg-region="' + this.regionName + '"]');
        },
        showErrorsAndScroll: function () {
            this.showErrors();
            this.scroll();
        }
    };
    return (SubFormValidator);
}]);