ng1App.controller('doctor.patient.cert.NespViewCtrl', 
    ['$scope', '$routeParams', '$filter', 'EptsCertificatesApiFactory', 'MessageService', 'PatientsApiFactory', 'SessionApiFactory', '$q',
     function ($scope, $routeParams, $filter, EptsCertificatesApiFactory, MessageService, PatientsApiFactory, SessionApiFactory, $q) {
	MessageService.clear();
    $scope.$emit('setHelpPage', 'perzireti-nestumo-ir-gimdymo-atostogu-pazymejima');
	
	// Make sure all data is loaded
	$scope.practitioner = SessionApiFactory.getPractitioner({}, function (practitioner) {
		$scope.practitioner.fullName = _.getFullName(practitioner);
    });
	$scope.organization = SessionApiFactory.getOrganization({});
	$scope.patientData = PatientsApiFactory.get({id: $routeParams.patientId});
	
	$q.all([$scope.practitioner.$promise, $scope.organization.$promise, $scope.patientData.$promise]).then(function () {
		EptsCertificatesApiFactory.getPregnancyVacationCertificates({personalCode: $scope.patientData.personalCode,
																	birthDate: $scope.patientData.birthDate,
																	organizationCode: $scope.organization.jarCode,
																	number: $routeParams.id},
			function (certificates) {													
				$scope.pregnancyVacationCertificate = certificates[0];

				if ($scope.pregnancyVacationCertificate.status != 'SIGNED') {
					MessageService.add('warning', $filter('translate')('warn.cer.sign'));
				}
				
				$scope.pregnancyVacationCertificate.submissionDate = $routeParams.submissionDate;
			});
	});
    
    // Get pregnancy period
	$scope.getPeriod = function () {
		if (!$scope.pregnancyVacationCertificate) {
			return '';
		}
		
		return moment($scope.pregnancyVacationCertificate.endDate).diff(moment($scope.pregnancyVacationCertificate.startDate), 'days') + 1;
	};
	
	// Get complication
	$scope.getComplication = function () {
		if (!$scope.pregnancyVacationCertificate || !$scope.pregnancyVacationCertificate.childBirthComplication) {
			return '';
		}
		
		return $scope.pregnancyVacationCertificate.childBirthComplication.code + ' ' + $scope.pregnancyVacationCertificate.childBirthComplication.name;
	};
}]);
