import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiV2AccessRules } from "../../../api/api-v2-access-rules";
import { ApiV2Session } from "../../../api/api-v2-session";
import { PractitionerModel } from "../../shared/models/practitioner.model";
import { RepresentativeModel } from "../../shared/models/representative.model";
import { OrganizationModel } from "../../shared/models/organization.model";
import { PatientModel } from "../../shared/models/patient.model";
import { ApiV2AccountSettings } from "../../../api/api-v2-account-settings";
import {ApiV2Encounters} from "../../shared/services/api-v2-encounters";

@Injectable({
    providedIn: "root",
})
export class DpSessionContext implements OnInit {
    public sessionCtx: any;
    public representative: Observable<any>;
    public currentUserDataSubject = new BehaviorSubject<any>(null);
    public currentUserData$: Observable<any> = this.currentUserDataSubject.asObservable();
    public accessRulesDataSubject = new BehaviorSubject<any>(null);
    public accessRulesData$: Observable<any> = this.accessRulesDataSubject.asObservable();
    public accountSettingsDataSubject = new BehaviorSubject<any>(null);
    public accountSettingsData$: Observable<any> = this.accountSettingsDataSubject.asObservable();
    public organizationDataSubject: BehaviorSubject<OrganizationModel> = new BehaviorSubject<OrganizationModel>(null);
    public organizationData$: Observable<OrganizationModel> = this.organizationDataSubject.asObservable();
    public representativeDataSubject = new BehaviorSubject<any>(null);
    public representativeData$: Observable<RepresentativeModel> = this.representativeDataSubject.asObservable();
    public practitionerDataSubject: BehaviorSubject<PractitionerModel> = new BehaviorSubject<PractitionerModel>(null);
    public practitionerData$: Observable<PractitionerModel> = this.practitionerDataSubject.asObservable();
    public patientDataSubject = new BehaviorSubject<any>(null);
    public patientData$: Observable<PractitionerModel> = this.patientDataSubject.asObservable();
    public encounterDataSubject = new BehaviorSubject<any>(null);
    public encounterData$: Observable<any> = this.encounterDataSubject.asObservable();
    private patient: PatientModel;
    private activeQualification: string;

    constructor(
        private apiV2AccessRules: ApiV2AccessRules,
        private apiV2Session: ApiV2Session,
        private apiV2Encounters: ApiV2Encounters,
        private apiV2AccountSettings: ApiV2AccountSettings
    ) {}

    ngOnInit(): void {}

    loadSessionData() {
        this.getCurrentUser();
        this.getOrganization();
        this.getRepresentative();
        this.getPractitioner();
        this.getPatient();
        this.getAccountSettings();
    }

    public getCurrentUser() {
        this.apiV2Session.getCurrent().subscribe((data: any) => {
            this.currentUserDataSubject.next(data);
        });
    }

    public getAccountSettings() {
        this.apiV2AccountSettings.get().subscribe((data: any) => {
            this.accountSettingsDataSubject.next(data);
        });
    }

    public getAccessRules() {
        this.apiV2AccessRules.get().subscribe((data) => {
            this.accessRulesDataSubject.next(data);
        });
    }

    public getOrganization() {
        this.apiV2Session.getOrganization().subscribe((data) => {
            this.organizationDataSubject.next(data);
        });
    }

    public getRepresentative() {
        this.apiV2Session.getRepresentative().subscribe((data) => {
            this.representativeDataSubject.next(data);
        });
    }

    public getPractitioner() {
        this.apiV2Session.getPractitioner().subscribe((data) => {
            this.practitionerDataSubject.next(data);
        });
    }

    public getPatient() {
        this.apiV2Session.getPatient().subscribe((data) => {
            this.patientDataSubject.next(data);
        });
    }

    public getLatestEncounter() {
        const encounterQuery: Object = {
            count: 1,
            patient: this.patient.id,
            organizationId: this.organizationDataSubject.getValue().id,
            searchDocs: false,
            status: ["planned", "inProgress", "onleave"],
        };

        this.apiV2Encounters.getPatientEncounters(encounterQuery).subscribe((data) => {
            this.encounterDataSubject.next(data?.items?.shift());
        });
    }

    public setPatient(patient: PatientModel) {
        this.patient = patient;
    }

    public setQualification(qualification: string) {
        this.activeQualification = qualification;
    }

    public getQualification(): string {
        return this.activeQualification;
    }
}
