import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApiV2Documents} from "../../../../../api/api-v2-documents";

@Component({
    selector: 'app-order-with-metadata',
    templateUrl: './order-with-metadata.component.html',
})
export class OrderWithMetadataComponent implements OnInit, OnChanges {
    @Input() order: any;
    @Input() showNotes: boolean | undefined;
    @Input() radioValue: any;
    @Input() innitValue: any;
    @Input() findOrderDocument: boolean | undefined;
    @Input() newTab: boolean | string;
    @Input() excludeDocument: any;
    @Input() inputType: string;
    data: { valueToSet: any; value?: any };
    orderDocLoaded: boolean = false;
    orderDoc: any;

    constructor(private apiV2Documents: ApiV2Documents) {
    }

    ngOnInit(): void {
        this.data = {valueToSet: this.radioValue ? this.radioValue : this.order};
        if (this.innitValue) {
            this.data.value = this.innitValue;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.orderDocLoaded && changes.val && this.findOrderDocument) {
            this.apiV2Documents.getOrderDocumentsByOrder({orderId: changes.val, alsoE025E003: true}).subscribe(data => {
                this.orderDocLoaded = true;
                this.orderDoc = data.length > 0 ? data[0] : undefined;
            })
        }
    }

    hasOrderDoc(): boolean {
        return (this.order && this.order.compositionId) || this.orderDoc;
    }

    doShowNotes(): boolean {
        return this.showNotes === undefined ? true : this.showNotes;
    }
}