<div>
    <h1>{{ "E113-ND Nėštumo duomenys, anamnezė" | translate }}</h1>
    <app-document-caption [captionData]="documentCaptionData"></app-document-caption>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <app-ngn-caption [captionData]="ngnCaptionData"></app-ngn-caption>
    <app-encounter-block></app-encounter-block>
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Nėštumo duomenys' | translate" id="pregnancyData">
        <div class="card mb-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-8 col-lg-6">
                        <div class="row req">
                            <app-date-picker inputId="primaryVisitDate"
                                class="col-sm-12 col-md-12 col-lg-6 d-flex flex-column"
                                labelText="Pirmo apsilankymo data"
                                [showErrors]="true"
                                [fControl]="formGroup?.get('pregnancyInfo.firstVisitDate')"></app-date-picker>
                            <app-input id="pregnancy" class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column"
                                labelText="Nėštumas" type="number" [min]="1"
                                [showErrors]="true"
                                [fControl]="formGroup?.get('pregnancyInfo.pregnancy')" />
                            <app-input id="birth" class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column"
                                labelText="Gimdymas" type="number" [min]="1"
                                [showErrors]="true"
                                [fControl]="formGroup?.get('pregnancyInfo.birth')" />
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-6 d-flex flex-column">
                        <fieldset>
                            <legend class="flex-grow-1">{{ "Folinės r. vartojimas" | translate }}</legend>
                            <div class="row">
                                <app-checkbox *ngFor="let item of foilUsing" class="col-md-12 col-lg-4" (change)="
                                        formGroup
                                            .get('pregnancyInfo.foilUsing')
                                            .get(item.controlName)
                                            .patchValue(!stringToBoolean($event.target.value))
                                    " [checkboxValue]="
                                        formGroup.get('pregnancyInfo.foilUsing').get(item.controlName).value
                                    " [labelText]="item.label" [checkboxTitle]="item.label" [checkboxChecked]="
                                        formGroup.get('pregnancyInfo.foilUsing').get(item.controlName).value
                                    "
                                    [addUniqId]="true"
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-n3">
            <div class="col-lg-12 col-xl-6">
                <div class="card mb-3">
                    <div class="card-header">{{ "Mėnesinės" | translate }}</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 d-flex flex-column">
                                <fieldset class="row req">
                                    <legend class="col-12 col-form-label">{{ "Mėnesinių ciklas" | translate }}</legend>
                                    <app-radio-button *ngFor="let item of menstrualCycle" radioName="menstrualCycle"
                                        [labelText]="item.name" [radioTitle]="item.name" [id]="item.key"
                                        [radioValue]="item.key" [fControl]="pregnancyInfo.get('menstrualCycle')"
                                        [radioChecked]="pregnancyInfo.get('menstrualCycle').value === item.key" />
                                    <app-form-control-validation [fControl]="pregnancyInfo.get('menstrualCycle')" />
                                </fieldset>
                            </div>
                            <div class="col-sm-12 col-md-6 d-flex flex-column">
                                <div class="row req">
                                    <div class="col-12">
                                        <app-input id="menstruationDuration" labelText="Mėnesinių trukmė" type="number"
                                            min="0" max="20"
                                            [showErrors]="true"
                                            [fControl]="formGroup?.get('pregnancyInfo.menstruationDuration')" />
                                    </div>
                                    <div class="col-12">
                                        <app-input id="cycleDuration" labelText="Ciklo trukmė"
                                            class="d-flex flex-column" type="number" max="99"
                                            [showErrors]="true"
                                            [min]="formGroup?.get('pregnancyInfo.menstruationDuration').value || 0"
                                            [fControl]="formGroup?.get('pregnancyInfo.cycleDuration')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row req">
                            <div class="col-sm-12 d-flex flex-column">
                                <app-date-picker inputId="lastMenstruationPeriodDate"
                                    labelText="Paskutinių normalių mėnesinių data" [maxDate]="currentDate"
                                    [showErrors]="true"
                                    [fControl]="formGroup?.get('pregnancyInfo.lastMenstruationPeriod')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-6">
                <div class="card mb-3">
                    <div class="card-header">
                        {{ "Gimdymo terminas" | translate }}
                    </div>
                    <div class="card-body">
                        <div class="row req">
                            <div class="col-sm-12 col-md-6">
                                <app-date-picker inputId="confirmedDeadlineDate" labelText="Patvirtintas terminas"
                                    [minDate]="currentDate"
                                    [showErrors]="true"
                                    [fControl]="formGroup?.get('pregnancyInfo.confirmedDeadline')"></app-date-picker>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <app-input id="calculateLastWomansPeriodDate" labelText="Pagal paskutines mėnesinės"
                                           [showErrors]="true"
                                    viewMode="true" [value]="calculateLastWomansPeriodDate | date : 'yyyy-MM-dd'" />
                            </div>
                        </div>
                        <div class="row req">
                            <div class="col-sm-12">
                                <app-date-picker inputId="ultrasoundDate"
                                    labelText="Gimdymo terminas pagal ultragarso tyrimą"
                                    [maxDate]="addDaysToDate(currentDate, 280)"
                                    [showErrors]="true"
                                    [fControl]="formGroup?.get('pregnancyInfo.ultrasoundDate')"></app-date-picker>
                            </div>
                            <div class="col-sm-12">
                                <app-input id="calculateLastWomansVacationDay"
                                    labelText="Preliminari nėštumo ir gimdymo atostogų pradžios data" viewMode="true"
                                           [showErrors]="true"
                                    [value]="addDaysToDate(pregnancyFirstDate, 210) | date : 'yyyy-MM-dd'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Anamnezė' | translate" id="anamnesis">
        <div class="row mb-n3">
            <div class="col-lg-12 col-xl-6 d-flex flex-column">
                <div class="card mb-3">
                    <div class="card-header">{{ "Šeimos anamnezė" | translate }}</div>
                    <div class="card-body pt-4">
                        <div class="row" *ngFor="let item of getFamilyAnamnesisFormArray.controls">
                            <div class="col-sm-6">
                                <app-checkbox (change)="
                                        onCheckboxChange(
                                            formGroup,
                                            $event,
                                            'anamnesis',
                                            'familyAnamnesis',
                                            item.value.name
                                        )
                                    " [labelText]="item.value.name" [checkboxTitle]="item.value.name"
                                    [checkboxChecked]="item.value.checked"
                                    [addUniqId]="true"
                                ></app-checkbox>
                            </div>
                            <div class="col-sm-6">
                                <app-input *ngIf="!!item.value.checked" type="text" [fControl]="item.controls.value" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-header">{{ "Moters žalingi įpročiai" | translate }}</div>
                    <div class="card-body pt-4">
                        <fieldset class="row" *ngFor="let item of getWomanAddictionsFormArray.controls; let i = index">
                            <legend class="col-sm-12 col-lg-5 mt-0">
                                {{ item.value.name | translate }}
                            </legend>
                            <div class="col-sm-12 col-lg-7">
                                <div class="row">
                                    <div class="col">
                                        <app-checkbox labelText="Iki nėštumo" checkboxTitle="Iki nėštumo" (change)="
                                                getWomanAddictionsControl(i, 'beforePregnancy').patchValue(
                                                    !stringToBoolean(item.value.beforePregnancy)
                                                )
                                            " [checkboxChecked]="item.value.beforePregnancy" [addUniqId]="true" />
                                    </div>
                                    <div class="col">
                                        <app-checkbox labelText="Nėštumo metu" checkboxTitle="Nėštumo metu" (change)="
                                                getWomanAddictionsControl(i, 'duringPregnancy').patchValue(
                                                    !stringToBoolean(item.value.duringPregnancy)
                                                )
                                            " [checkboxChecked]="item.value.duringPregnancy" [addUniqId]="true"></app-checkbox>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="card mb-3 flex-grow-1">
                    <div class="card-header">{{ "Moters persirgtos ligos" | translate }}</div>
                    <div class="card-body pt-4">
                        <div *ngFor="let disease of womanDiseases">
                            <app-checkbox (change)="onCheckboxChange(formGroup, $event, 'anamnesis', 'womanDiseases')"
                                [checkboxValue]="disease" [labelText]="disease" [checkboxTitle]="disease"
                                [checkboxChecked]="formGroup.get('anamnesis.womanDiseases').value.includes(disease)" [addUniqId]="true"></app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-6 d-flex flex-column">
                <div class="card mb-3">
                    <div class="card-header">{{ "Vaiko biologinis tėvas" | translate }}</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 req">
                                <app-input id="biologicalFather.age" labelText="Amžius, m." type="number" max="99"
                                           [showErrors]="true"
                                    [fControl]="formGroup?.get('anamnesis.biologicalFather.age')" />
                            </div>
                            <div class="col-md-6">
                                <fieldset>
                                    <legend>
                                        {{ "Žalingi įpročiai" | translate }}
                                    </legend>
                                    <div *ngFor="let addicition of addictions">
                                        <app-checkbox (change)="
                                                onCheckboxChange(
                                                    formGroup,
                                                    $event,
                                                    'anamnesis.biologicalFather',
                                                    'addictions'
                                                )
                                            " [checkboxValue]="addicition" [labelText]="addicition"
                                            [checkboxTitle]="addicition" [checkboxChecked]="
                                                formGroup
                                                    .get('anamnesis.biologicalFather.addictions')
                                                    .value.includes(addicition)
                                            "
                                            [addUniqId]="true"
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-header">{{ "Moters skiepai, alergijos" | translate }}</div>
                    <div class="card-body pt-4">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="fw-bold">{{ "Alergijos:" | translate }}</div>
                                    <div class="col">
                                        <div class="row pt-2" *ngFor="let allergy of allergies">
                                            <small>{{ allergy.date | date : "yyyy-MM-dd h:mm" }}</small>
                                            <span>{{ allergy.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="fw-bold">{{ "Skiepai:" | translate }}</div>
                                    <div class="col">
                                        <div class="row pt-2" *ngFor="let vaccination of vaccinations">
                                            <small>{{ vaccination.date | date : "yyyy-MM-dd h:mm" }}</small>
                                            <span>{{ vaccination.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-2">
                            <app-input id="womanAllergies" labelText="Alergijos, neregistruotos pacientės ESI"
                                type="text" [fControl]="formGroup?.get('anamnesis.womanAllergies')" />
                        </div>
                    </div>
                </div>
                <div class="card mb-3 flex-grow-1">
                    <div class="card-header">{{ "Moters KMI" | translate }}</div>
                    <div class="card-body">
                        <div class="row req">
                            <div class="col-sm-12 col-md-6">
                                <app-input id="weight" labelText="Svoris" type="number" units="kg" min="20"
                                    numberStep="0.001" digitsInfo="1.3-3" placeholder="0.000"
                                    [showErrors]="true"
                                    [fControl]="formGroup?.get('anamnesis.womanKmi.weight')" />
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <app-input id="height" labelText="Ūgis" type="number" units="cm" min="50"
                                    numberStep="0.1" digitsInfo="1.1-1" placeholder="0.0"
                                    [showErrors]="true"
                                    [fControl]="formGroup?.get('anamnesis.womanKmi.height')" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-input id="kmi" labelText="KMI" labelClass="fw-bold" viewMode="true"
                                    [value]="calculatedKmi" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <!-- TODO: Waiting for Iteration2 -->
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Ankstesni nėštumai ir jų baigtys' | translate"
        id="previousPregnanciesOutcomes">
        <div class="card mb-3">
            <div class="card-header">{{ "Nėščiosios informacija iš Pacientės ESI" | translate }}</div>
            <div class="card-body pt-4">
                <div>Ruošiama</div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header">{{ "Nėščiosios pateikta informacija" | translate }}</div>
            <div class="card-body pt-4">
                <div>Ruošiama</div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                {{ "Papildomai nėščiosios pateikiama informacija apie kitus ankstesnius nėštumus" | translate }}
            </div>
            <div class="card-body pt-4 mb-n3">
                Ruošiama
                <!-- <div class="row">
                    <div class="col-lg-12 col-xl-6 d-flex">
                        <div class="card mb-3 flex-grow-1">
                            <div class="card-header">{{ "Nėštumas" | translate }}</div>
                            <div class="card-body pt-4">
                                <div class="col">
                                    <div class="row"></div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6">
                        <div class="card mb-3">
                            <div class="card-header">{{ "Gimdymas" | translate }}</div>
                            <div class="card-body pt-4">
                                <div class="row">
                                    <div class="col-md-6">
                                        <app-select
                                            id="childbirthMode"
                                            labelText="Gimdymo būdas"
                                            [values]="childbirthModesArray"
                                            [fControl]="formGroup?.get('previousPregnancies.childbirthMode')"
                                        />
                                    </div>
                                    <div
                                        *ngIf="
                                            formGroup?.get('previousPregnancies.childbirthMode').value ===
                                            childbirthModes.OnNaturalPathsUseAids
                                        "
                                        class="col-md-6"
                                    >
                                        <div class="fw-bold pb-2">{{ "Pagalbinės procedūros" | translate }}</div>
                                        <div class="row" *ngFor="let procedure of auxiliaryProcedures">
                                            <app-checkbox
                                                (change)="
                                                    onCheckboxChange(
                                                        formGroup,
                                                        $event,
                                                        'previousPregnancies',
                                                        'auxiliaryProcedures'
                                                    )
                                                "
                                                [checkboxValue]="procedure"
                                                [labelText]="procedure"
                                                [checkboxTitle]="procedure"
                                                [checkboxChecked]="
                                                    formGroup
                                                        .get('previousPregnancies.auxiliaryProcedures')
                                                        .value.includes(procedure)
                                                "
                                            ></app-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </nhr-record-widget>

    <app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
        (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
        [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
        [submitButtonDisabled]="true" [saveButtonDisabled]="!formGroup.valid"></app-modification-actions>
</div>