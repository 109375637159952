angular.module('medvais.utils').directive('medvaisRecordWidget', ["$medvaisWidgetService", "MessageService", function ($medvaisWidgetService, MessageService) {
    return {
        templateUrl: 'modules/medvais-utils/components/medvais-record-widget.html',
        restrict: 'E', replace: true, scope: true, transclude: true,
        link: function (scope: any, element, attrs) {
            scope.closable = !attrs.closable || attrs.closable == 'true';
            scope.closed = attrs.closed == 'true';
            scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'blue';
            scope.iconClass = attrs.iconClass;
            scope.title = attrs.title;
            scope.bodyClass = attrs.bodyClass ? attrs.bodyClass : 'form-horizontal';
            scope.msgRegion = attrs.msgRegion;
            scope.list = attrs.list;
            scope.mixed = attrs.mixed;

            scope.getItems = function () {
                //return $medvaisWidgetService.messages[scope.msgRegion];
                return MessageService.messages[scope.msgRegion];
            };
            scope.close = function (index) {
                //$medvaisWidgetService.remove(index, scope.msgRegion);
                MessageService.remove(index, scope.msgRegion);
            };
        }
    }
}]);