ng1App.controller('doctor.patient.RepresentativesCtrl', 
    ['$scope', '$routeParams', '$filter',
     function ($scope, $routeParams, $filter) {
    $scope.$emit('setHelpPage', 'perzireti-atstovavimu-sarasa1');

    $scope.viewId = 'dp_patient_representatives_list';
    $scope.patientId = $routeParams.patientId;
    $scope.handlers = {
        editUrlStart: '/dp/patients/' + $scope.patientId + '/representatives/'
    };
    $scope.newRepresentativeUrl = '/dp/patients/' + $scope.patientId + '/representatives/new';
    $scope.dashboard = { 
        url: '/dp/patients/' + $routeParams.patientId,  
        title: $filter('translate')('dpp.desktop')
    };
}]);