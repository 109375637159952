angular.module('erx.api').factory('ErxPrescriptionsApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/MedicationPrescription';
  return $resource('', {count: '@count', id: '@id', confirmUrl: '@confirmUrl'}, {
    // Skyrimo gavimas pagal ID
    getDocument: {
      method: 'GET',
      url: URL_BASE + '/:id'
    },
    // Skyrimų sąrašo gavimas
    getMedPrescriptions: {
      method: 'GET',
      url: URL_BASE + '/',
      isArray: true
    },
    // Ankstesnių skyrimų sąrašo gavimas
    getOlderMedPrescriptions: {
      method: 'GET',
      url: URL_BASE + '/filteredList'
    },
    // Skyrimo išsaugojimas
    createMedPrescription: {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      url: URL_BASE + '/'
    },
    saveMedPrescription: {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      url: URL_BASE + '/'
    },
    submitMedPrescription: {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      url: URL_BASE + '/submit'
    },
    // Skyrimų sąrašo gavimas su vieno puslapio įrašų skaičiumi
    getMedPrescriptionsWithCount: {
      method: 'GET',
      url: URL_BASE
    },
    // Receptų sąrašo gavimas su vieno puslapio įrašų skaičiumi
    getMedPrescriptionDocsWithCount: {
      method: 'GET',
      url: URL_BASE + '/documents'
    },
    // Skyrimo trynimas
    deleteMedPrescription: {
      method: 'DELETE',
      url: URL_BASE + '/:id'
    },
    // Skyrimo pasirašymo nuorodos gavimas
    getSignUrl: {
      method: 'POST',
      url: URL_BASE + '/:id/sign?confirmUrl=:confirmUrl'
    },
    // Skyrimo patvirtinimas
    confirmSigning: {
      method: 'GET',
      url: URL_BASE + '/:id/sign/confirm'
    },
    // Skyrimo atšaukimas
    cancel: {
      method: 'POST',
      url: URL_BASE + '/:id/cancel'
    },
    // Recepto sustabdymas
    stop: {
      method: 'POST',
      url: URL_BASE + '/:id/stop'
    },
    // Recepto atnaujinimas
    renew: {
      method: 'POST',
      url: URL_BASE + '/:id/suspend'
    },
    // Pateikimas į kortelę
    submit: {
      method: 'GET',
      url: URL_BASE + '/testSubmit'
    },
    // Tikslinimas
    correct: {
      method: 'POST',
      url: URL_BASE + '/:id/correct'
    },
    // Susiję dokumentai
    getRelated: {
      method: 'GET',
      url: URL_BASE + '/:id/related',
      isArray: true
    },
    getDrugInUseUntil: {
      method: 'GET',
      url: URL_BASE + '/medInUseUntilDate'
    },
    isFirstPrescribing: {
      method: 'GET',
      url: URL_BASE + '/firstPrescribing'
    }
  });
}]);