// Išplėstinės receptų paieškos modulis
angular.module('erx.prs').directive('erxAdvPrsList', function () {
  var advPrsListCtrl = function ($scope) {
    //$scope.options.type
    // 'D', 'P', 'H'
    //$scope.options.doctorId;
    //$scope.options.patientId;
  };
  advPrsListCtrl.$inject = ["$scope"];
  return {
    restrict: 'E',
    scope: {options: '='},
    templateUrl: 'modules/erx/prs/advanced_prescription_list/advanced_prescription_list.html',
    controller: advPrsListCtrl

  };
});

