ng1App.factory('MedvaisPaths', 
    ['$location',
     function ($location) {
    return {

        list: function () {
            return '/dp/imaging/study/list';
        },

        // route to document E027-va edit form. New document.
        new: function () {
            return '/dp/imaging/study/e027va/new';
        },

        // route to document E027-va edit form. Edit existing document.
        edit: function (documentId) {
            return '/dp/imaging/study/e027va/' + documentId+'/edit';
        },

        // route to document E027-va preview form.
        view: function (documentId) {
            return '/dp/imaging/study/e027va/' + documentId;
        },

        sign: function (documentId) {
            $location.path('/dp/imaging/study/e027va/' + documentId+'/sign');
        },

        unisignCallback: function (documentId) {
            var loc = window.location.href.split( '/' );
            var url = loc[0] + '//' + loc[2]+'/dp/imaging/study/e027va/' + documentId + '/unisignCallback';
            return url;
            //return $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/dp/imaging/study/' + documentId + '/unisign_callback';
        }
    }
}]);


