import $ from 'jquery'

export class Hooks {
    private static instance: Hooks;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() { }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): Hooks {
        if (!Hooks.instance) {
            Hooks.instance = new Hooks();
        }

        return Hooks.instance;
    }

    /**
     * This hook is executed before any other load of the angular or main scripts, but after vendor (node module dependencies).
     */
    executePreScripts(): void {
        console.debug("Executing pre scripts")
    }

    /**
     * This hook is executed after other scripts at the end of the main load.
     */
    executePostScripts(): void {
        console.debug("Executing post scripts")

        $(document).on("keydown", function (e) {
            if (e.which === 8
                && (!$(e.target).is("input, textarea") ||
                    ($(e.target).is("input") &&
                        (e.target.getAttribute("type") === "radio" || e.target.getAttribute("type") === "checkbox")))) {
                e.preventDefault();
            }
        });
    }
}
