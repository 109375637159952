import { AfterViewInit, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
    addObjToFormArray,
    generateSelectionValuesFromEntity,
    multiCheckboxIsChecked,
    updateCheckboxControl,
    updateFormArray
} from '../../../e096/e096-utils/utils';


@Component({
    selector: 'app-e097-at-form',
    templateUrl: './e097-at-form.component.html',
})
export class E097AtFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: { [key: string]: any }[]) {
        if (!value) return;
        this._initialFormValue = value;
        this._initialFormValue.forEach((item, i) => {
            this.verifications.push(addObjToFormArray(item));
            this.updatePerformedSpecialist((this.verifications.at(i) as FormGroup), item);
        })
    }

    verifications: FormArray = this.fb.array([]);
    editVerificationId: number;
    editVerificationValue: any;
    _initialFormValue: { [key: string]: any }[];

    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;


    get controlsArray() {
        return this.verifications.controls.map(control => control.value);
    }
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    ngAfterViewInit() {
        this._initialFormValue = this.verifications.value;
    }

    editVerification(id: number) {
        this.editVerificationId = id;
        this.editVerificationValue = this.verifications.at(this.editVerificationId).getRawValue();
    }

    deleteVerification(id: number) {
        this.editVerificationId = null;
        this.editVerificationValue = null;
        this.verifications.removeAt(id);
    }

    save() {
        console.log(this.verifications.getRawValue());
    }

    resetForm() {
        this.editVerificationId = null;
        this.editVerificationValue = new Date;
        this.verifications.clear({emitEvent: false});
        this._initialFormValue.forEach((item, i) => {
            this.verifications.push(addObjToFormArray(item));
            this.updatePerformedSpecialist((this.verifications.at(i) as FormGroup), item);
        });
    }

    addVerification(verification: FormGroup) {
        if (this.editVerificationId === null || this.editVerificationId === undefined) {
            this.verifications.push(verification);
        } else {
            this.verifications.setControl(this.editVerificationId, verification);
            this.editVerificationId = null;
        }
    }

    cancelVerification() {
        this.editVerificationId = null;
    }

    private updatePerformedSpecialist(group: FormGroup, data: any): void {
        group.removeControl('performed', {emitEvent: false});
        updateFormArray(data, 'performed', group);
    }
}
