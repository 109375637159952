import {erxPrsModule} from "./prs";

import './general_info/general_info';
import './prescription_form/guide/prescription_guide';
import './prescription_form/signing/sign_prescription';
import './prescription_form/signing/erx_unisign_callback';
import './prescription_form/directives/erx-components';
import './prescription_form/related_docs/relatedDispenses';
import './prescription_form/related_docs/relatedPrescriptions';
import './prescription_form/print_prescriptions/print_prescriptions';
import './prescription_form/edit_prescription_doc/edit_prescription_doc';
import './prescription_form/prescription_form';
import './prescription_form/view_prescription_form';
import './prescription_form/prescriptionsConfigProvider';
import './prescription_list/erxPrescriptionList';
import './prescription_list/erxPrescriptionListConfigProvider';
import './small_prescription_lists/active_prescriptions';
import './small_prescription_lists/document_prescriptions';
import './small_prescription_lists/dispensed_prescriptions';
import './advanced_prescription_list/advanced_prescription_list';

export default erxPrsModule;