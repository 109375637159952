<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formPostpartumHemorrhageStart.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formPostpartumHemorrhageStart.bleedingWasNoted' | translate}}:"
                inputId="postpartumBleedingObserved"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                suffixIcon="fa fa-calendar"
                inputGroupClass="mb-4"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [fControl]="formGroup.get('postpartumBleedingObserved')"
            ></app-date-picker>
            <div class="form-group col-6 col-lg-3">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e096Ptkl.formPostpartumHemorrhageStart.helpCalled' | translate}}:
                </span>
                <app-checkbox
                    *ngFor="let option of helpRequestedOptions; let i = index"
                    inputId="helpRequested{{i}}"
                    class="mt-1 d-flex flex-column"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('helpRequested'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'helpRequested')"
                    (checkboxChange)="updateControl(option.name, 'helpRequested')"
                ></app-checkbox>
            </div>
            <app-select
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formPostpartumHemorrhageStart.amountBloodLoss' | translate}}:"
                inputClass="mb-4"
                [id]="'lostBloodAmount'"
                [fControl]="formGroup.get('lostBloodAmount')"
                [values]="generateSelectionValuesFromEntity(answersVariants?.['lostBloodAmount'])"
            ></app-select>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formPostpartumHemorrhageStart.increasedBloodLoss' | translate}}"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                inputGroupClass="mb-4"
                units="ml"
                type="number"
                [class.d-none]="formGroup.get('lostBloodAmount')?.value !== 'daugiau kaip 1000 ml'"
                [fControl]="formGroup.get('increasedBloodLoss')"
            ></app-input>
        </div>
    </div>
</nhr-record-widget>