ng1App.directive('sveidraDiagnoses', function () {
	
	var updateDiagnoses = function (stage, diagnoses) {
		var mainDiagnosis = _.find(diagnoses, function (diagnosis) {
			return diagnosis.diagnosisType == 'main';
		});
		stage.mainDiagnosis = mainDiagnosis.diagnosis;
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/diagnoses.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '=',
			diagnoses: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			scope.stage.$promise.then(function () {
				scope.$watch('diagnoses', function (newValue: any) {
					if (newValue.$promise) {
						newValue.$promise.then(function () {
							updateDiagnoses(scope.stage, scope.diagnoses);
						});
					}
				});
			});
			
			scope.diagnosisFilter = null;
			
			scope.toggleSelection = function (diagnosis) {
				var index = -1;
				_.each(scope.stage.additionalDiagnoses, function (d, i) {
					if (d.code == diagnosis.diagnosis.code) {
						index = i;
					}
				});
				
				if (index >= 0) {
					// Remove
					scope.stage.additionalDiagnoses.splice(index, 1);
				} else {
					// Add
					scope.stage.additionalDiagnoses.push(diagnosis.diagnosis);
				}
			};
			
			scope.isSelected = function (diagnosis) {
				var existing = _.find(scope.stage.additionalDiagnoses, function (d) {
					return d.code == diagnosis.diagnosis.code;
				}); 
				return !!existing;
			};
		}
	};
});