<div
    id="{{data.id}}"
    slat-item clickable edit
    (slatItemClick)="onCardClick(data.id)"
    (editButtonClick)="onEditClick(data.id)"
>
    <div class="text">
        <span class="text-secondary">{{data.date | date:'yyyy-MM-dd'}}</span>
    </div>
    <div class="mb-1">
        <span class="fw-bold">{{data.unsorted_document_type}} -</span>
        <span class="text" >{{data.desc}}</span>
    </div>
    <div *ngIf="data.recipient.length == 1" class="text-secondary">
        <span class="text">{{data.masterIdentifier}} | </span>
        <span class="text">{{data.recipient[0].name}}</span>
    </div>
    <div *ngIf="data.recipient.length > 1" class="text-secondary">
        <span class="text">{{data.masterIdentifier}}</span>
        <span class="text" *ngFor="let recipients of data.recipient">
            | {{recipients.name}}
        </span>
    </div>
    <div class="text-secondary">
        <span class="text">{{data.author.speciality.name}} | </span>
        <!-- TODO: needs a link here -->
        <span class="text text-info">{{practitionerFullName}} | </span>
        <span class="text">{{data.author.practitionerOrganization.name}}</span>
    </div>
</div>