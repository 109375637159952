ng1App.controller('doctor.patient.F066alkStageEditCtrl', 
    ['$scope', '$modalConfirm', '$filter', 'MessageService', '$window', '$routeSegment', '$routeParams', 'SveidraApiFactory', 'DocumentsApiFactory', 'DocumentSpinnerHelper', 'SveidraCommons', '$location', 'FormLoaderService',
     function ($scope, $modalConfirm, $filter, MessageService, $window, $routeSegment, $routeParams,
                                                                   SveidraApiFactory, DocumentsApiFactory, DocumentSpinnerHelper, SveidraCommons,
                                                                   $location, FormLoaderService) {
	FormLoaderService.startFormLoading();
	
	MessageService.clearAll();
    
    $scope.$emit('setHelpPage', 'ivesti-gydymo-etapo-duomenis');
    
    $scope.goBack = function () {
        $window.history.back();
    };
    
    
    $scope.patientId = $routeParams.patientId;
    $scope.mode = $routeParams.stageId ? 'edit' : 'new';
    $scope.stageId = $routeParams.stageId;
    
    $scope.loadingSpinnerHelper = DocumentSpinnerHelper;
    
    if ($scope.mode == 'new') {
        $scope.stage = SveidraApiFactory.getPrefilledStage({formId: $routeParams.formId});
    } else {
    	$scope.stage = SveidraApiFactory.getStageForEdit({formId: $routeParams.formId, id: $scope.stageId});
    }
    
    $scope.stage.$promise.then(function () {
    	// Loading required additional data
    	$scope.diagnoses = DocumentsApiFactory.getDiagnoses({documentId: $scope.stage.documentId});
    	FormLoaderService.endFormLoading($scope.diagnoses.$promise);
    });
    
    
    $scope.clean = function () {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')}).then(function () {
        	$routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
    };
    
    $scope.save = function (transfer) {
    	$scope.stageForm.submitted = true;
    	
    	console.log($scope.stageForm);
    	console.log($scope.stage);
    	
    	if ($scope.stageForm.$invalid && $scope.stageForm.$error.required) {
    		return;
    	}
   
    	$scope.loadingSpinnerHelper.startLoading();
		if ($scope.mode == 'edit') {
			SveidraApiFactory.updateStage({formId: $routeParams.formId, id: $scope.stageId, submitToSveidra: transfer}, $scope.stage, saveSuccessHandler, saveErrorHandler);
		} else {
			SveidraApiFactory.createStage({formId: $routeParams.formId, submitToSveidra: transfer}, $scope.stage, saveSuccessHandler, saveErrorHandler);
		}
    };
    
    var saveSuccessHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleSaveWithWarnings(result);
		if (result.withWarnings) {
			$scope.mode = 'edit';
			$scope.stageId = result.id;
			$scope.stage = SveidraApiFactory.getStage({formId: $routeParams.formId, id: $scope.stageId});
		} else {
			$location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms/f066alk/' + $routeParams.formId).search({}); 
		}
	};
	
	var saveErrorHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleErrors(result, 'err.cer.066.sta.sav');
	};

}]);