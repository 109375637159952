import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';

@Component({
    selector: 'app-e096-ptkl-control-sheet',
    templateUrl: './e096-ptkl-control-sheet.component.html',
})
export class E096PtklControlSheetComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    formGroup: FormControl;
    modOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Replių ir vakuuminio ekstraktoriaus naudojimas'
        },
        {
            id: '1',
            code: '1',
            name: 'Pagalbos teikimas pogimdyminio kraujavimo atveju'
        },
        {
            id: '2',
            code: '2',
            name: 'Vaisiaus pečių užstrigimas'
        }
    ];

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.control(null, [Validators.required]);
    }
}
