<div class="input-group">
    <input
        #input
        [formControl]="searchValue"
        [typeahead]="suggestions$"
        [typeaheadAsync]="true"
        [typeaheadItemTemplate]="customItemTemplate"
        [typeaheadMinLength]="0"
        [typeaheadOptionsLimit]="10"
        [typeaheadWaitMs]="200"
        typeaheadOptionField="fullDisplay"
        (typeaheadOnSelect)="choseData($event)"
        class="form-control h-[38px]"
    />
    <ng-template #customItemTemplate let-model="item">
        <span>{{model?.fullDisplay || model?.fullName || model?.name}}</span>
    </ng-template>
    <button class='btn btn-primary btn-sm' [disabled]="!input.value" (click)="input.value = ''">{{'clean' | translate}}</button>
</div>