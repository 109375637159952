import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, signal } from "@angular/core";
import { CalendarOptions, EventApi } from "@fullcalendar/core";
import { ColDef, FirstDataRenderedEvent, GridApi, GridReadyEvent, RowNode } from "ag-grid-community";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { RouteParams } from "src/app/ng1.routeParams";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ltLocale from "@fullcalendar/core/locales/lt";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DpAspnVisitService } from "../../../services/dpAspnVisit.service";
import { AspnVisitsCalendarEvent } from "../../../model/aspnVisit.model";
import { AgGridModalOpenLinkCell } from "src/app/shared/components/ag-grid/ag-modal-open-link-cell/ag-modal-open-link.component";
import { AgGridSelectFgCell } from "src/app/shared/components/ag-grid/components/ag-select-cell/agGrid-select-fg-cell";
import { AgGridInputFgCell } from "src/app/shared/components/ag-grid/components/ag-input-cell/agGrid-input-fg-cell";
import { AspnVisitModalComponent } from "../nursingServiceVisit/nursing-service-visit-modal.component";
import { Observable, Observer, firstValueFrom, map, of, switchMap } from "rxjs";
import { PatientSearchModel } from "src/app/shared/models/patient-search.model";
import { ApiV2Patients } from "src/app/shared/services/api-v2-patients";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import { ApiV2Entities } from "src/app/shared/services/api-v2-entities";
import { EntityModel } from "src/app/shared/models/entity.model";

@Component({
    selector: "aspn-visits-calendar",
    templateUrl: "./aspnVisitsCalendar.component.html",
})
export class AspnVisitsCalendarComponent implements OnInit {
    @ViewChild("visitsCalendar") calendarComponent: FullCalendarComponent;
    @ViewChild("proceduresInfoModalTmp", { read: TemplateRef }) proceduresInfoModalTmp: TemplateRef<any>;
    @ViewChild("editVisistDialogTmp", { read: TemplateRef }) editVisistDialogTmp: TemplateRef<any>;
    @ViewChild("cancelVisistDialogTmp", { read: TemplateRef }) cancelVisistDialogTmp: TemplateRef<any>;
    @ViewChild("cancelVisitNoteDialogTmp", { read: TemplateRef }) cancelVisitNoteDialogTmp: TemplateRef<any>;
    @ViewChild("visitProceduresApproveModalTmp", { read: TemplateRef })
    visitProceduresApproveModalTmp: TemplateRef<any>;
    @ViewChild("moreInfoModalTmp", { read: TemplateRef }) moreInfoModalTmp: TemplateRef<any>;

    proceduresInfoModalRef?: BsModalRef;
    editVisistDialogRef?: BsModalRef;
    cancelVisistDialogRef?: BsModalRef;
    cancelVisitNoteDialogRef?: BsModalRef;
    visitProceduresApproveModalRef?: BsModalRef;
    modalRef2?: BsModalRef;
    private agGridApi: GridApi;

    currentEvents = signal<EventApi[]>([]);

    events: AspnVisitsCalendarEvent[] = [];

    labelByStatus = {
        1: "Suplanuotas",
        2: "Įvykęs",
        3: "Atšauktas",
    };

    badgeByStatus = {
        2: "bg-dark-subtle text-dark",
        1: "text-bg-success text-white",
        3: "text-bg-danger text-white",
    };

    // public calendarOptions;

    visitFormG: FormGroup;
    filterForm: FormGroup = this.fb.group({
        patientQuery: [""],
        practitionierQuery: [""]
    });

    testFC = new FormControl("labas");

    btnPressed: boolean;
    patientSuggestions$?: Observable<PatientSearchModel[]>;
    practitionerSuggestions$: Observable<any[]>;
    filterOptions: any[];
    aspnProcedureList:EntityModel[];



    constructor(
        private dpAspnContextService: DpAspnContextService,
        private dbAspnVisitService: DpAspnVisitService,
        private apiV2Patients: ApiV2Patients,
        private apiV2Practitioners: ApiV2Practitioners,
        private params: RouteParams,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private modalService: BsModalService,
        private fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
    ) { }

    calendarOptions = signal<CalendarOptions>({
        initialView: "timeGridWeek",
        plugins: [dayGridPlugin, timeGridPlugin],
        eventSources: [
            {
                url: this.dbAspnVisitService.aspnVisitsCalendar + "/events",
                method: "GET",
                extraParams: {
                    ftq: "",
                    page: "1",
                    dateFrom: "",
                    dateTo: "",
                    specialist: "",
                    patient: "",
                    urgent: false,
                    procedures: [],
                },
            },
        ],
        headerToolbar: {
            left: "prev,next",
            center: "title",
            right: "timeGridDay,timeGridWeek,dayGridMonth",
        },
        eventMinHeight: 80,
        weekends: true,
        allDaySlot: false,
        locale: ltLocale,
        height: 800,
        views: {
            timeGridDay: { buttonText: "Diena" },
            timeGridWeek: { buttonText: "Savaitė" },
            timeGridMonth: { buttonText: "Mėnesis" },
        },
        // eventsSet: this.handleEvents.bind(this),
        eventClick: (eventInfo) => {
            !eventInfo.jsEvent.defaultPrevented &&
                this.openVisitProceduresApproveModal(
                    eventInfo.event._def.extendedProps.visitId,
                    eventInfo.event._def.extendedProps.procedures
                );
            eventInfo.el.blur();
        },
    });

    async ngOnInit(): Promise<void> {

        this.dbAspnVisitService.getAspnVisitsCalendarEvents().then((val) => {
            this.events = val;
        });


        await firstValueFrom(this.apiV2Entities.getEntitiesList("aspn-procedure-list")).then((val) => {
            this.aspnProcedureList = val;
        });

        // to resize the calendar events correctly
        setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
        }, 1);

        this.visitFormG = this.fb.group({
            visitId: [null, Validators.required],
            visitCancelNote: ["", [Validators.required]],
        });

        this.visitProceduresFG.valueChanges.subscribe((val) => {
            (<FormGroup[]>(<FormArray>this.visitProceduresFG.get("procedures")).controls).forEach((fg) => {
                fg.get("procedureState").updateValueAndValidity({ onlySelf: false, emitEvent: false });
                fg.get("cancelNote").updateValueAndValidity({ onlySelf: false, emitEvent: false });
            });
        });
        this.initializePatientSuggestions();
        this.initializePractitionerSuggestions();
        this.initializePatientSuggestions();
        this.filterOptions = [
            {
                title: 'Pacientas',
                formControlName: 'patient',
                type: 'typeahead',
                placeholder: 'Pasirinkite',
                typeaheadSettings: {
                    suggestionsService:  this.patientSuggestions$,
                    typeaheadformControl:this.filterForm.get("patientQuery"),
                    inputTextItem: "inputText"
                }
            },
            {
                title: 'Data nuo',
                type: 'date',
                formControlName: 'fromDate',
            },
            {
                title: 'Data iki',
                type: 'date',
                formControlName: 'toDate',
            },
            {
                title: 'Būsena',
                formControlName: 'visitState',
                type: 'multi-select',
                placeholder: 'Pasirinkite',
                multiSelectOptions: [
                    {
                        code: "1",
                        name: "Suplanuotas"
                    },
                    {
                        code: "2",
                        name: "Įvykęs"
                    },
                    {
                        code: "3",
                        name: "Atšauktas"
                    },
                ],
                multiSelectSettings:{
                    singleSelection: true,
                    idField: "code",
                    textField: "name"
                }
            },
            {
                title: 'Specialistas',
                formControlName: 'practitionier',
                type: 'typeahead',
                placeholder: 'Pasirinkite',
                typeaheadSettings: {
                    suggestionsService: this.practitionerSuggestions$,
                    typeaheadformControl:this.filterForm.get("practitionierQuery"),
                    inputTextItem: "inputText"
                }
            },
            {
                title: 'Procedūros',
                formControlName: 'procedures',
                type: 'multi-select',
                placeholder: 'Pasirinkite',
                multiSelectOptions: this.aspnProcedureList,
                multiSelectSettings:{
                    singleSelection: false,
                    itemsShowLimit: 50,
                    idField: "code",
                    textField: "name"
                }
            },
            {
                title: 'Skubios',
                formControlName: 'urgent',
                type: 'checkbox',
            },
        ]

    }

    initializePatientSuggestions() {
        this.patientSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.filterForm.get("patientQuery").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Patients
                        .search({ q: query })
                        .pipe(
                            map(
                                (data: FilteredDataModel) =>
                                    data && data.items.map((pat) => {
                                        pat.inputText = (pat.namePrefix ? pat.namePrefix + " " : "") +
                                        pat.givenName +
                                        " " +
                                        pat.familyName +
                                        " " +
                                        pat.personalCode
                                        return pat;
                                    }) || []
                            )
                        );
                }
                return of([]);
            })
        );
    }

    initializePractitionerSuggestions() {
        this.practitionerSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.filterForm.get("practitionierQuery").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners
                        .find({ q: query })
                        .pipe(
                            map(
                                (data: FilteredDataModel) =>
                                    (data && data.items.map((psm) => {
                                        psm.inputText = (psm.namePrefix ? psm.namePrefix + " " : "") +
                                        psm.givenName +
                                        " " +
                                        psm.familyName +
                                        " " +
                                        psm.personalCode;
                                        return psm;
                                    })) || []
                            )
                        );
                }
                return of([]);
            })
        );
    }

    getPersonCredentials(person: PatientModel | PractitionerModel) {
        return (
            (person.namePrefix ? person.namePrefix + " " : "") +
            person.givenName +
            " " +
            person.familyName +
            " " +
            person.personalCode
        );
    }

    // agGrid --------
    public visitProceduresGridData: any = [];

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        wrapText: true,
        autoHeight: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
    };

    public visitProceduresFG = new FormGroup({
        procedures: new FormArray([]),
    });

    public visitProceduresColDefs2: ColDef[] = [
        {
            field: "procedures",
            headerName: "Procedūros",
        },
        { field: "urgent", headerName: "Skuba", width: 60 },
        { field: "relatedMedication", headerName: "Priemonės ir medikamentai" },
        {
            field: "moreInfo",
            headerName: "",
            cellRenderer: AgGridModalOpenLinkCell,
            cellRendererParams: {
                linkTitle: "Daugiau",
            },
            width: 90,
        },
        {
            field: "procedureState",
            headerName: "Procedūros atlikimas",
            cellRenderer: AgGridSelectFgCell,
            width: 110,
        },
        {
            field: "cancelNote",
            headerName: "Neatlikimo priežastis",
            cellRenderer: AgGridInputFgCell,
            width: 110,
        },
    ];

    public visitProceduresColDefs: ColDef[] = [
        {
            field: "procedures",
            headerName: "Procedūros",
        },
        {
            field: "linkToOrder",
            headerName: "Paskyrimas",
            cellRenderer: (param) => {
                if (param.value) {
                    return `<a class="btn btn-link px-0" href="${param.value}" target="_blank">${param.linkString}</a>`;
                } else {
                    return ``;
                }
            },
            cellRendererParams: {
                linkString: "Atidaryti",
            },
        },
        { field: "urgent", headerName: "Skuba" },
        { field: "medication", headerName: "Priemonės ir medikamentai" },
        { field: "procedureState", headerName: "Proceduros atlikimas" },
        { field: "cancelNote", headerName: "Neatlikimo priežastis" },
    ];

    onGridReady(params: GridReadyEvent) {
        this.agGridApi = params.api;
        // this.columnAgGridApi = params.columnApi;
        this.refreshFormControls();
        this.agGridApi.sizeColumnsToFit();
    }

    refreshFormControls() {
        if (this.agGridApi) {
            this.createFormControls();
            this.agGridApi.refreshCells({ force: true });
        }
    }

    private createFormControls() {
        const visitProcedures = <FormArray>this.visitProceduresFG.controls["procedures"];
        visitProcedures.clear();
        this.agGridApi.forEachNode((rowNode: RowNode, i) => {
            visitProcedures.push(
                new FormGroup({
                    procedureId: new FormControl(rowNode.data.id),
                    procedureState: new FormControl(null, [this.visitProcedureApprovValidator]),
                    cancelNote: new FormControl("", [this.cancelNoteValidator]),
                })
            );
        });
    }

    private createKey(rowNodeData: any, columnName: string): any {
        return rowNodeData[columnName];
    }

    getContext() {
        return {
            createKey: this.createKey,
            formGroupArray: this.visitProceduresFG.controls["procedures"],
            parentComp: this,
            formControlNameSelect: "procedureState",
            formControlNameInput: "cancelNote",
        };
    }

    getRowNodeId(data: any) {
        return data.orderProcedureId;
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents.set(events);
        this.changeDetector.detectChanges();
    }

    openModal(rowData: any) {
        let bodySites = [];
        if (rowData.bodySite && rowData.bodySite.length > 0 && rowData.bodySite[0].id) {
            bodySites = rowData.bodySite.map((s) => s.name);
        } else {
            bodySites = rowData.bodySite;
        }
        const initialState: ModalOptions = {
            initialState: {
                procedureLocations: bodySites,
                recommendations: rowData.recommendations,
                title: "Papildoma procedūrų informacija",
            },
            ignoreBackdropClick: false,
        };

        this.modalRef2 = this.modalService.show(AspnVisitModalComponent, {
            ...initialState,
            ...Object.assign({}, { class: "second modal-lg" }),
            id: 2,
        });
        this.modalRef2.content.closeBtnName = "Uždaryti";
    }

    openEditVisistDialog(event: PointerEvent, visitId: number, periodVisitId: number) {
        event.preventDefault();
        this.editVisistDialogRef = this.modalService.show(this.editVisistDialogTmp, {
            initialState: { visitId: visitId, periodVisitId: periodVisitId },
        });
    }

    openCancelVisistDialog(event: any, visitId: number, periodVisitId: number) {
        event.preventDefault();
        this.cancelVisistDialogRef = this.modalService.show(this.cancelVisistDialogTmp, {
            initialState: { visitId: visitId, periodVisitId: periodVisitId },
        });
    }

    navigateToVisit(visitId: number) {
        this.editVisistDialogRef && this.editVisistDialogRef.hide();
        this.router.navigate([`/dp/aspn/spv/${visitId}`]);
    }

    openCancelVisitNoteModal(visitId: number) {
        this.visitFormG.get("visitCancelNote").setValue("");
        this.visitFormG.get("visitId").setValue(visitId);
        this.visitFormG.get("visitCancelNote").updateValueAndValidity();
        this.visitFormG.get("visitCancelNote").markAsUntouched();
        this.cancelVisitNoteDialogRef = this.modalService.show(this.cancelVisitNoteDialogTmp, {
            initialState: { visitId: visitId },
        });
        this.cancelVisistDialogRef.hide();
    }

    cancelVisit(visitId: number) {
        if (this.visitFormG.valid) {
            const cancelOk = this.dbAspnVisitService.cancelAspnVisit(
                visitId,
                this.visitFormG.get("visitCancelNote").value
            );
            this.cancelVisitNoteDialogRef.hide();
            this.calendarComponent.getApi().refetchEvents();
        }
    }

    openVisitProceduresApproveModal(visitId: number, procedures: any[]) {
        this.visitProceduresGridData = [];

        this.dbAspnVisitService.getAspnVisitById(visitId).then((val) => {
            val.orderProcedures.forEach((op) => {
                this.visitProceduresGridData.push({
                    id: op.id,
                    procedures: op.procedures.map((v) => v.name).join(", "),
                    bodySite: op.bodySite.map((pro) => pro.name),
                    relatedMedication: op.relatedMedication.map((m) => m.name).join(", "),
                    recommendations: op.recommendations,
                    procedureState: "",
                    cancelNote: "",
                    urgent: op.urgent,
                });
            });
            val.visitProcedures.forEach((op) => {
                this.visitProceduresGridData.push({
                    id: op.id,
                    procedures: op.procedures.map((v) => v.name).join(", "),
                    bodySite: op.bodySite.map((pro) => pro.name),
                    relatedMedication: op.relatedMedication.map((m) => m.name).join(", "),
                    recommendations: op.recommendations,
                    procedureState: "",
                    cancelNote: "",
                    urgent: op.urgent,
                });
            });
            this.visitProceduresApproveModalRef = this.modalService.show(this.visitProceduresApproveModalTmp, {
                initialState: { visitId: visitId, procedures: procedures },
                class: "modal-xl",
            });
        });
    }

    submitVisitAsDone(visitId) {
        if (this.visitProceduresFG.valid) {
            const procsVal = [];
            this.agGridApi.forEachNode((node) => {
                procsVal.push({
                    procedureId: node.data.id,
                    procedureState: this.visitProceduresFG.controls["procedures"].controls.find(
                        (p) => p.value.procedureId === node.data.id
                    )?.value.procedureState,
                    cancelNote: this.visitProceduresFG.controls["procedures"].controls.find(
                        (p) => p.value.procedureId === node.data.id
                    )?.value.cancelNote,
                });
            });

            this.dbAspnVisitService.submitAspnVisit(visitId, procsVal).then((val) => {
                this.router.navigate(["/dp/patients/1000070414/documents/new/e025"]);
            });

            this.visitProceduresApproveModalRef && this.visitProceduresApproveModalRef.hide();
        }
    }

    visitProcedureApprovValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (!control.value) {
                return { error: "Butinas" };
            }
        }
        return null;
    };

    cancelNoteValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (visitProcedureFg.get("procedureState").value === "N" && !control.value) {
                return { error: "Butinas" };
            }
        }
        return null;
    };

    loadAspnVisitList(query) {
        this.calendarComponent.getApi().removeAllEventSources();
        this.calendarComponent.getApi().addEventSource({
            url: this.dbAspnVisitService.aspnVisitsCalendar + "/events",
            method: "GET",
            extraParams: {
                ftq: query["quick-search"] ? query["quick-search"] : "",
                page: "1",
                dateFrom: query.dateFrom ? query.dateFrom : "",
                dateTo: query.dateTo ? query.dateTo : "",
                specialist: query.specialist ? query.specialist : "",
                patient: query.patient ? query.patient : "",
                urgent: query.urgent ? query.urgent : "",
                procedures: query.procedures ? query.procedures : "",
            },
        });
    }

    updateFilters(filters: {[key: string]: any}) {
        console.log("filters ---> ", filters);

        if (!filters) {
            return;
        }
        // this.filterList(filters);
    }
}
