export abstract class DocumentModificationActions {
    // abstract onSaveSuccess(data) : void;
    // abstract onSignSuccess(data, hideSuccess?) : void;
    // abstract handleErrors(data) : void;
    // abstract onSaveError(res) : void;
    // abstract _resolveValidationError(msg) : any;
    // abstract hasErrorsSkipRequired(htmlDocForm) : boolean;
    // abstract showConfirmSignModal(data, callback) : void;

    abstract _validateAndSubmit(formValidator, htmlDocForm, skipRequired, onSaveInterceptor, submitAction): void;
    abstract validateFormSimple(htmlDocForm, skipRequired) : boolean;
    abstract savePartial(id, htmlDocForm, formValidator, onSaveInterceptor, draftId): void;
    abstract saveFinal(id, htmlDocForm, formValidator, onSaveInterceptor, draftId, confirmed, doSign) : void;
    abstract saveDraft(id, htmlDocForm, formValidator, onSaveInterceptor) : void;
    abstract amendAndSign(id, htmlDocForm, formValidator, onSaveInterceptor): void;
    abstract makeFinalAndSign(document, callback, confirmed, doSign) : void;
    abstract __log(htmlDocForm) : void;
    abstract checkValidQualification(practitioner): void;
    abstract removeEncounterOrder(encounterData, callBack) : void;
    abstract changeEncounterOrder(patientId, encounterData, callBack): void;
    abstract removeContinuedTreatmentE025($document_scope):void;
    abstract loadTemplateModal($document_scope, $rootScope): void;
    abstract  modalForContinuedTreatmentE025(patientId, $document_scope, $rootScope): void;
}

export function documentModificationActionsFactory(i: any) {
    return i.get('DocumentModificationActions');
}

export const documentModificationActions = {
    provide: DocumentModificationActions,
    useFactory: documentModificationActionsFactory,
    deps: ['$injector']
};

