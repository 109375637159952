import angular from "angular";

angular.module('medvais.components').directive('medvaisBtnLoadMore', function () {
    return {
        templateUrl: 'modules/medvais/components/list/medvais-btn-load-more.html',
        restrict: 'E',
        replace: false,
        scope: {
            loadMore: '&',
            loading: '=',
            title:'@'
        }
    };
});