import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable, Observer, map, of, switchMap } from 'rxjs';
import { ApiV2AttachedUnsorted } from 'src/api/api-v2-doc-ref-attached-unsorted';
import { ApiV2DocumentClassifier } from 'src/api/api-v2-document-classifier';
import { ApiV2Organizations } from 'src/api/api-v2-organizations';
import { DocumentTagEnum } from '../../../models/document-tag.enum';
import { DocRefAttachedUnsortedModel } from 'src/app/shared/models/doc-ref-attached-unsorted.model';
import { ApiV2DocRefConsent } from "../../../../../api/api-v2-doc-ref-consent";
import { ApiV2Documents } from '../../../../../api/api-v2-documents';
import { RouteParams } from "../../../../ng1.routeParams";
import { OrganizationModel } from "../../../models/organization.model";
import { PortalTypeEnum } from "../../../models/portalType.enum";
import { DocRefConsentService } from "../../../services/doc-ref-consent.service";

@Component({
    selector: 'doc-ref-attached-unsorted-view',
    templateUrl: './doc-ref-attached-unsorted-view.component.html',
    providers: [DatePipe]
})
export class DocRefAttachedUnsortedViewComponent implements OnInit {

    backPath: string;
    @Input() docAttachedUnsorted: DocRefAttachedUnsortedModel;
    // @Input() docRefConsent: DocRefConsentModel;
    // @Input() patientOrganizationSpan: SpanModel[];
    // @Input() consentOrganizationSpan: SpanModel[];
    // @Input() agreementSpan: SpanModel[];
    // @Input() badgeColor: string;
    // @Input() badgeTitle: string;
    // @Input() dateBetween: string;
    // @Input() agreementInfo: string;
    // @Input() docStatusDescription: string;
    // @Input() practitionerFullName: string;
    @Input() portalType: PortalTypeEnum;

    fileSelected: boolean = false;
    fileName: string = '';
    unsortedForm: FormGroup;
    date = new Date();
    documentTypeArray: string[];
    docTypeValue: string;
    organizationArray: OrganizationModel[];
    search?: string;
    suggestions$?: any;
    errorMessage?: string;
    noResult = false;
    formData = new FormData;
    patientId = null;
    canEdit = false;
    documentTag = DocumentTagEnum.e027a;
    prefillData: any;

    constructor(
        public apiV2DocRefAttachedUnsorted: ApiV2AttachedUnsorted,
        private fb: FormBuilder,
        private apiV2DocRefConsent: ApiV2DocRefConsent,
        private docRefConsentService: DocRefConsentService,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router,
        private apiV2DocumentClassifier: ApiV2DocumentClassifier,
        private apiV2Organizations: ApiV2Organizations,
        private http: HttpClient, private datePipe: DatePipe
    ) {
        this.unsortedForm = this.fb.group({
            date: [],
            unsorted_document_type: [],
            desc: [],
            recipient: this.fb.array([]),
            state: [],
            visibility: [],
            masterIdentifier: [],
            deleteStatus: [],
        });
        this.unsortedForm.get('date').disable();
        if (!this.canEdit) {
            this.unsortedForm.get('unsorted_document_type').disable();
            this.unsortedForm.get('unsorted_document_type').disable();
            this.unsortedForm.get('desc').disable();
            this.unsortedForm.get('recipient').disable();
            this.unsortedForm.get('state').disable();
            this.unsortedForm.get('visibility').disable();
            this.unsortedForm.get('masterIdentifier').disable();
            this.unsortedForm.get('deleteStatus').disable();
        }
    }

    ngOnInit(): void {
        var patientId = this.params.id;
        this.backPath = this.portalType + `/patients/${patientId}/doc-unsorted/list`;
        this.getDocumentClassifierList();
        this.getOrganizationsList();
        // this.route.params.subscribe(params => {
        //     const id = params['viewId'];
        const id = this.params.viewId;
        //this.loadDocRefConsent(id);
        this.loadAttachedUnsorted(id);
        // });
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    // loadDocRefConsent(id: string) {
    //     return this.apiV2DocRefConsent.getDocRefConsentView(id).subscribe((data: DocRefConsentModel) => {
    //         this.docRefConsent = data;
    //         this.initializeData();
    //     });
    // }

    loadAttachedUnsorted(id: string) {
        return this.apiV2DocRefAttachedUnsorted.getAttachedUnsortedView(id).subscribe((data: DocRefAttachedUnsortedModel) => {
            this.docAttachedUnsorted = data;
            if (this.docAttachedUnsorted) {
                this.initializeData(this.docAttachedUnsorted);
            }
        });
    }

    getCurrentRecipients(recipients) {
        for (let i = 0; i < recipients.length; i++) {
            (this.unsortedForm.get('recipient') as FormArray).push(
                this.fb.control(recipients[i])
            );
        }
    }

    getDocumentClassifierList() {
        this.apiV2DocumentClassifier.getClassifierList().pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.documentTypeArray = response;
            }
        });
    }

    getOrganizationsList() {
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.search);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Organizations.getOrganizations(query, 10, 'ROOT_ONLY').pipe(map((organization: OrganizationModel[]) => this.organizationArray = organization));
                }
                return of([]);
            })
        );
    }

    onFileSelected(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.fileName = file.name;
            this.formData.append('file', file);
            this.fileSelected = true;
        }
    }

    onFileRemove() {
        this.fileSelected = false;
        this.fileName = '';
    }

    addRecipient(inputValue) {
        for (let i = 0; i < this.organizationArray.length; i++) {
            if (inputValue == this.organizationArray[i].name) {
                (this.unsortedForm.get('recipient') as FormArray).push(
                    this.fb.control(this.organizationArray[i])
                );
                this.search = '';
            }
        }
    }

    removeRecipient(index) {
        (this.unsortedForm.get('recipient') as FormArray).removeAt(index);
    }

    getRecipientFormControls(): AbstractControl[] {
        return (<FormArray>this.unsortedForm.get('recipient')).controls
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    //TODO fix submit button to combine all information from forms and file
    send(values) {
        // console.log(this.unsortedForm.getRawValue());
        // this.http.post('http://localhost:3001/api/v1/documents2/1/add-agreement', this.formData).subscribe((res: any) => {
        //     console.log("res",res);
        // });
    }


    initializeData(data) {
        this.unsortedForm.controls['date'].setValue(this.datePipe.transform(this.docAttachedUnsorted.date, "yyyy-MM-dd"));
        this.unsortedForm.controls['unsorted_document_type'].setValue(this.docAttachedUnsorted.unsorted_document_type);
        this.unsortedForm.controls['desc'].setValue(this.docAttachedUnsorted.desc);
        //console.log("data.recipient",this.docAttachedUnsorted.recipient);
        this.getCurrentRecipients(data.recipient);
        this.unsortedForm.controls['state'].setValue("");
        this.unsortedForm.controls['visibility'].setValue(this.docAttachedUnsorted.visibility);
        this.unsortedForm.controls['masterIdentifier'].setValue(this.docAttachedUnsorted.masterIdentifier);
        this.unsortedForm.controls['deleteStatus'].setValue(this.docAttachedUnsorted.deleteStatus);
    }

    // getInitiator(): string {
    //     return this.practitionerFullName + ", " + this.docRefConsent.practitioner.speciality.name;
    // }

    // canSign(): boolean {
    //     const docStatus: DocRefConsentDocStatusEnum = DocRefConsentDocStatusEnum[this.docRefConsent.docStatus.code];
    //     return docStatus === DocRefConsentDocStatusEnum.UNSIGNED && this.portalType === PortalTypeEnum.PP;
    // }

    // canCancel(): boolean {
    //     const docStatus: DocRefConsentDocStatusEnum = DocRefConsentDocStatusEnum[this.docRefConsent.docStatus.code];
    //     return docStatus === DocRefConsentDocStatusEnum.UNSIGNED || (this.portalType === PortalTypeEnum.PP && docStatus !== DocRefConsentDocStatusEnum.CANCELED);
    // }

    goBack() {
        this.router.navigate([this.backPath]);
    }

    // onCancel() {
    //     this.apiV2DocRefConsent.changeDocRefConsentStatus(this.docRefConsent.id, DocRefConsentDocStatusEnum.CANCELED)
    //         .subscribe(data => {
    //             this.docRefConsent = data;
    //             this.initializeData();
    //         });
    // }

    // onSign() {
    //     this.apiV2DocRefConsent.changeDocRefConsentStatus(this.docRefConsent.id, DocRefConsentDocStatusEnum.VALID)
    //         .subscribe(data => {
    //             this.docRefConsent = data;
    //             this.initializeData();
    //         });
    // }

    // receives data from common-patient-widget
    openAttachedDocument() {
        window.open(`/api/doctor/attachments/file/${this.docAttachedUnsorted.id}`);
    };

    onChanges(event) {
        //console.log("emited",event);
    }

    undoClick(): void {
        this.router.navigate([this.portalType + `/patients/${this.patientId}/doc-unsorted/list`]);
    }
}