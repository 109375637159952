import {Injectable} from '@angular/core';
import {ApiV2Session} from '../../../api/api-v2-session';
import {ApiV2AccountSettings} from '../../../api/api-v2-account-settings';
import {PractitionerModel} from "../../shared/models/practitioner.model";
import {OrganizationModel} from "../../shared/models/organization.model";

@Injectable({
    providedIn: 'root'
})
export class DpSessionService {
    private settings: any;
    private practitioner: PractitionerModel;
    private organization: OrganizationModel;

    constructor(
        private apiV2Session: ApiV2Session,
        private apiV2AccountSettings: ApiV2AccountSettings
    ) {
        this.settings = null;
        this.practitioner = null;
        this.organization = null;
        this.reloadSettings();
    }

    reloadSettings(): void {
        this.apiV2AccountSettings.get().subscribe((data: any) => {
            this.settings = data;
        });
    }

    getPractitioner(): PractitionerModel {
        this.apiV2Session.getPractitioner().subscribe((data: PractitionerModel) => {
            this.practitioner = data;
        });

        this.practitioner.fullName = this.getFullName(this.practitioner);
        return this.practitioner;
    }

    getOrganization(): any {
        this.apiV2Session.getOrganization().subscribe((data: OrganizationModel) => {
            this.organization = data;
        });
        return this.organization;
    }

    getSettings(): any {
        if (!this.settings) {
            this.reloadSettings();
        }
        return this.settings;
    }

    private getFullName(practitioner: any): string {
        // Implement getFullName logic here
        return ''; // Placeholder return value
    }
}
