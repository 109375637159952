<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Cpo.participantsForm.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto mb-3 req">
            <span class="fw-bolder col-form-label">
                {{'doc.pregnancyDashboard.e096Cpo.participantsForm.operated' | translate}}:
            </span>
            <div class="col-12 col-lg-6 mt-2">
                <app-find-specialists
                    [editable]="editable"
                    [customDisplaying]="false"
                    [formArray]="formGroup?.get('operated')"
                ></app-find-specialists>
            </div>
        </div>
        <div class="row mx-auto mb-3">
            <span class="fw-bolder col-form-label">
                {{'doc.pregnancyDashboard.e096Cpo.participantsForm.assisted' | translate}}:
            </span>
            <div class="col-12 col-lg-6 mt-2">
                <app-find-specialists
                    [editable]="editable"
                    [customDisplaying]="false"
                    [formArray]="formGroup?.get('assisted')"
                ></app-find-specialists>
            </div>
        </div>
        <div class="row mx-auto mb-3 req">
            <span class="fw-bolder col-form-label">
                {{'doc.pregnancyDashboard.e096Cpo.participantsForm.doctors' | translate}}:
            </span>
            <div class="col-12 col-lg-6 mt-2">
                <app-find-specialists
                    [editable]="editable"
                    [customDisplaying]="false"
                    [formArray]="formGroup?.get('doctors')"
                ></app-find-specialists>
            </div>
        </div>
        <div class="row mx-auto mb-3">
            <span class="fw-bolder col-form-label">
                {{'doc.pregnancyDashboard.e096Cpo.participantsForm.nurse' | translate}}:
            </span>
            <div class="col-12 col-lg-6 mt-2">
                <app-find-specialists
                    [editable]="editable"
                    [customDisplaying]="false"
                    [formArray]="formGroup?.get('nurse')"
                ></app-find-specialists>
            </div>
        </div>
    </div>
</nhr-record-widget>