import {isDevMode} from '@angular/core'

ng1App.controller('RootCtrl',
    ['$scope', '$location', '$cookies', '$rootScope', '$window', 'AuthApiFactory', 'MessageService', 'SessionApiFactory', 'portalType', 'footerLinks', 'sveidraLinks', 'BrowserSessionStorage', '$routeSegment',
        function ($scope, $location, $cookies, $rootScope, $window, AuthApiFactory, MessageService, SessionApiFactory, portalType, footerLinks, sveidraLinks, BrowserSessionStorage, $routeSegment) {

            console.debug("Loading RootJS controller")
            var redirected = false;
            $rootScope._ = _;
            $rootScope.$on('DO_LOGIN', function (event, p) {
                if (p && (p.indexOf('http://') == 0 || p.indexOf('https://') == 0)) {
                    if (!redirected) {
                        window.location.href = p;
                        redirected = true;
                    }
                } else {
                    $location.url('/');
                }
            });

            $rootScope.$on("SHOW_MSG", function (event, message) {
                console.log(message);
                var text = message.onlyContent ? message.content : (_.join(message.code + " ", ": ", message.title) + message.content);
                MessageService.add(message.type, text, message.region);
            });

            $scope.doLogout = function () {
                AuthApiFactory.doLogout({}, function (res, headers) {
                    window.location.href = headers('Location');
                });
                return false;
            };

            $scope.getCurrentUrl = function () {
                return $location.absUrl();
            };

            $scope.footerLinks = footerLinks;
            $scope.sveidraLinks = sveidraLinks;

            var sessionId = $location.search().sessionId;
            if (sessionId) {
                $cookies['sessionId'] = sessionId;
                $location.url($location.path());
            }

            $scope.goBack = function () {
                $window.history.back();
            };

            $scope.$route = $location.path() + ($location.hash() ? '#' + $location.hash() : '')
            $scope.$watch(function () {
                return $location.path() + ($location.hash() ? '#' + $location.hash() : '');
            }, function (newRoute) {
                $scope.$route = newRoute;
            });

            $scope.isDevMode = function (): boolean {
                return isDevMode();
            }

            //
            $scope.$on('$routeChangeSuccess', function (next, current) {
                if (next != current) {
                    let lastSegment = $routeSegment.chain[$routeSegment.chain.length - 1];
                    if (lastSegment) {
                        console.debug("Reloading segment: " + lastSegment);
                        lastSegment.reload();
                    }
                }
            });

            BrowserSessionStorage.ensureBsidExist();
        }]);