<div class="mb-4 d-flex flex-column-reverse d-sm-block">
    <div class="float-sm-end">
    </div>
    <div>
        <h1 class="mb-1">{{'doc.pregnancyDashboard.e096Ptkl.title' | translate}}</h1>
        <back-link [path]="backUrl" subTitle="doc.new" linkClass="text-decoration-none" />
    </div>
</div>
<app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
    [prefillData]="prefillData"></app-common-patient-widget>
<app-ngn-caption [captionData]="ngnCaptionData" />
<app-e096-ptkl-form [editable]="editable" [initialFormValue]="initialFormValue"></app-e096-ptkl-form>
