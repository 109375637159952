import './doc/components_doc';
import './input/components_input';
import './lists/components_lists';
import './common/common-components';
import './address/component_address';
import './content/asside-button';
import './patient/patient-components';
import './patient/patient-insurance-icon';
import './patient/patient-insurance-block';
import './signing/signing';
import './comments/comments';
import './comments/view_edit_comments';
import './encounter/components-encounter';
import './preloader/preloaders';
import './agreements/agreements';
import './erx/index';