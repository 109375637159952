import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AllergySummaryModel} from "../../../models/allergy-summary.model";
import {EnvironmentSettings} from "../../../../../environments/env";
import moment from "moment";

@Component({
    selector: 'allergies-summary',
    templateUrl: './allergies-summary.component.html'
})
export class AllergiesSummaryComponent implements OnInit {
    protected readonly moment = moment;
    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() allergies: AllergySummaryModel[] = [];
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        if (docId) {
            EnvironmentSettings.isSpecialist() ?
                this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]) :
                this.router.navigate([`patients/${this.patientId}/documents/${docId}`]);
        }
    }

    getUrl() {
        return EnvironmentSettings.isSpecialist() ?
            '/dp/patients/{{patientId}}/allergies2' :
            '/patients/{{patientId}}/allergies2';

    }
}
