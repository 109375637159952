angular.module('medvais.components').directive('medvaisDropDown', function () {
    return {
        templateUrl: 'modules/medvais/components/common/medvais-drop-down.html',
        restrict: 'E',
        replace: true,
        scope: {
            menu: '='
        },
        link: function (scope: any, element, attrs) {
            scope.hasMain = function () {
                var has = false;
                angular.forEach(scope.menu.main, function (item) {
                    if (scope.isVisible(item)) {
                        has = true;
                    }
                });
                return has;
            };

            scope.firstVisibleFromCtx = function () {
                var found;
                angular.forEach(scope.menu.dropDown, function (item) {
                    if (item.type !== 'separator' && !found && scope.isVisible(item)) {
                        found = item;
                    }
                });
                return found;
            };

            scope.isVisible = function (item, index, last) {
                if (item.type === 'separator') {
                    if (index === 0 || last) {
                        return false;
                    }

                    var before = false;
                    var after = false;

                    for (var i = index - 1; i >= 0; i--) {
                        var tmp = scope.menu.dropDown[i];
                        if (tmp.type === 'separator') {
                            before = false;
                            break;
                        } else {
                            if (scope.isVisible(tmp)) {
                                before = true;
                                break;
                            }
                        }
                    }

                    for (let i = index + 1; i < scope.menu.dropDown.length; i++) {
                        let tmp = scope.menu.dropDown[i];
                        if (tmp.type === 'separator') {
                            after = true;
                            break;
                        } else {
                            if (scope.isVisible(tmp)) {
                                after = true;
                                break;
                            }
                        }
                    }

                    return after && before;
                }
                return item.isVisible ? item.isVisible() : true;
            };

            scope.hasDropDown = function () {
                var has = false;
                angular.forEach(scope.menu.dropDown, function (item) {
                    if (item.type !== 'separator' && scope.isVisible(item)) {
                        has = true;
                    }
                });
                return has;
            };

            scope.isDisabled = function (item) {
                return item.isDisabled ? item.isDisabled() : false;
            };
        }
    };
});
