import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, merge, Observable, tap } from 'rxjs';


export function validateBloodPressure(controlNameSystolic: string, controlNameDiastolic: string, formGroup: FormGroup = this.formGroup): Observable<void> {
    return merge(
        formGroup.get(controlNameSystolic).valueChanges,
        formGroup.get(controlNameDiastolic).valueChanges
    )
        .pipe(
            debounceTime(200),
            tap(() => {
                bloodPressureValidator(controlNameSystolic, controlNameDiastolic, formGroup);
            })
        );
}
export function bloodPressureValidator(controlNameSystolic: string, controlNameDiastolic: string, formGroup: FormGroup = this.formGroup): void {
    const firstValue = formGroup.get(controlNameSystolic).value;
    const secondValue = formGroup.get(controlNameDiastolic).value;
    let bloodPressureSystolicValidators = getSystolicValidators(firstValue, secondValue);
    let bloodPressureDiastolicValidators = getDiastolicValidators(firstValue, secondValue);

    formGroup.get(controlNameSystolic).clearValidators();
    formGroup.get(controlNameDiastolic).clearValidators();
    formGroup.get(controlNameSystolic).setValidators(bloodPressureSystolicValidators);
    formGroup.get(controlNameDiastolic).setValidators(bloodPressureDiastolicValidators);

    formGroup.get(controlNameSystolic).updateValueAndValidity({emitEvent: false});
    formGroup.get(controlNameDiastolic).updateValueAndValidity({emitEvent: false});
}

function getSystolicValidators(firstValue: number | null, secondValue: number | null): ValidatorFn[] {
    const validators = [];

    if (firstValue || secondValue) {
        validators.push(Validators.required);
        if (secondValue) {
            validators.push(Validators.min(secondValue));
        } else {
            validators.push(Validators.min(0));
        }
    } else {
        validators.push(Validators.min(0));
    }

    return validators;
}

function getDiastolicValidators(firstValue: number | null, secondValue: number | null): ValidatorFn[] {
    const validators = [Validators.min(0)];

    if (firstValue || secondValue) {
        validators.push(Validators.required);
        if (firstValue) {
            validators.push(Validators.max(firstValue));
        }
    }

    return validators;
}