<app-title [backPath]="backPath">
    {{'058-089-115/a - Pranešimas apie nustatytą infekcinį susirgimą'| translate}}
</app-title>
<!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
<app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
    (valueChange)="onChanges($event)"></app-common-patient-widget>
<app-diagnoses-form (currentDiagnosesChange)="onDiagnosisChanges($event)"
    [isRequiredFieldInForm]=" true"></app-diagnoses-form>
<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true"
    [title]="'doc-ref-058-089-151a-form.component'|translate">
    <div class="widget-body">
        <form [formGroup]="form">
            <!-- <div class="mb-2 row">
                <label for="category" class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Diagnoze </label>
                <div class="col-md-4 col-lg-5">
                    <select id="category" class="form-select" formControlName="diagnosisStatus" [(ngModel)]="statusValue" required>
                        <option [value]="undefined">--Pasirinkti--</option>
                        <option *ngFor="let docType of problemTypeArray" [value]="docType.name">
                            {{docType.name}}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="mb-2 row">
                <label for="templateTitle"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Paskutinį
                    kartą buvimo darbe ar vaikų ugdymo įstaigoje</label>
                <div class="col-md-4 col-lg-5">
                    <app-date-picker inputId="attendanceDate" [maxDate]="currentDate"
                        [fControl]="form?.get('attendanceDate')">
                    </app-date-picker>
                </div>
            </div>
            <div class="mb-2 row">
                <label for="templateTitle"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Susirgimo
                    (ligos simptomų pradžia) <span class="text-danger">*</span></label>
                <div class="col-md-4 col-lg-5">
                    <app-date-picker inputId="gettingSickDate" [maxDate]="currentDate"
                        [fControl]="form?.get('gettingSickDate')">
                    </app-date-picker>
                </div>
            </div>
            <div class="mb-2 row">
                <label for="templateTitle"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Kreipimosi
                    (atvykimo į SPĮ) data <span class="text-danger">*</span></label>
                <div class="col-md-4 col-lg-5">
                    <app-date-picker inputId="checkInDate" [maxDate]="currentDate"
                        [fControl]="form?.get('checkInDate')">
                    </app-date-picker>
                </div>
            </div>
            <!-- <div class="mb-2 row">
                <label for="templateTitle" class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Diagnozės nustatymo data <span class="text-danger">*</span></label>
                <div class="col-md-4 col-lg-5">
                    <app-date-picker
                    inputId="diagnosisDate"
                    [maxDate]="currentDate"
                    [fControl]="form.get('diagnosisDate')">
                    </app-date-picker>
                </div>
            </div> -->
            <div class="mb-2 row">
                <label for="templateTitle"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Pranešimo
                    užpildymo data <span class="text-danger">*</span></label>
                <div class="col-md-4 col-lg-5">
                    <app-date-picker inputId="reportDate" [maxDate]="currentDate"
                        [fControl]="form?.get('reportDate')">
                    </app-date-picker>
                </div>
            </div>
            <!-- <div class="mb-2 row">
                <div class="offset-md-7 offset-lg-6 col-md-4 col-lg-5 mt-2">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="visibility" value="invisible" id="hospitalizedStatus" formControlName="hospitalized"/>
                        <label class="form-check-label" for="hospitalizedStatus">
                            Asmuo hospitalizuotas
                        </label>
                    </div>
                </div>
            </div> -->
            <div class="form-group row">
                <label for="category"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Asmuo
                    hospitalizuotas </label>
                <div class="col-md-4 col-lg-5">
                    <select id="category" class="form-select" formControlName="hospitalized" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let hospitalized of InfectionsPersonHospitalizationArray"
                            [value]="hospitalized.code">
                            {{hospitalized.name}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="mb-2 row">
                <label for="category" class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Dokumento tipas </label>
                <div class="col-sm-8">
                    <select id="category" class="form-select" formControlName="diagnosisStatus" [(ngModel)]="statusValue" required>
                        <option [value]="undefined">--Pasirinkti--</option>
                        <option *ngFor="let docType of problemTypeArray" [value]="docType.name">
                            {{docType.name}}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="form-group row">
                <label for="category"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Pagrindinė
                    mirties priežastis</label>
                <div class="col-md-4 col-lg-5">
                    <select id="category" class="form-select" formControlName="reasonForDeath" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let reasonForDeath of ConditionSeverityArray" [value]="reasonForDeath.code">
                            {{reasonForDeath.nameLT}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="mb-2 row">
                <div class="offset-md-7 offset-lg-6 col-md-4 col-lg-5">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="visibility" value="invisible" id="reasonForDeathStatus" formControlName="reasonForDeath"/>
                        <label class="form-check-label" for="reasonForDeathStatus">
                            Pagrindinė mirties priežastis
                        </label>
                    </div>
                </div>
            </div> -->
            <div class="mb-2 row">
                <label for="templateTitle" class="col-md-7 col-lg-6 col-form-label text-xs-start text-md-end">Kita
                    epidemiologiškai svarbi informacija</label>
                <div class="col-md-4 col-lg-5">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="relevantInformation" maxlength="4000" rows="4"></textarea>
                </div>
            </div>
            <!-- <div class="mb-2 mt-4 row">
                <label class="col-md-7 col-lg-6 col-form-label text-xs-start text-md-end" for="patientVisibleDoc">Duomenys apie gyvūną, kuris sužalojo žmogų</label>
                <div class="col-md-4 col-lg-5 mt-2">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="visibility" value="invisible" id="animalKnownStatus" formControlName="animalKnown"/>
                        <label class="form-check-label" for="animalKnownStatus">
                            Gyvūnas žinomas
                        </label>
                    </div>
                </div>
            </div> -->
            <div class="form-group row">
                <label for="category"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Duomenys
                    apie gyvūną, kuris sužalojo žmogų</label>
                <div class="col-md-4 col-lg-5">
                    <select id="category" class="form-select" formControlName="animalKnown" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let animalKnown of InfectionsFamiliarityAnimalArray"
                            [value]="animalKnown.code">
                            {{animalKnown.name}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <fieldset class="mb-2 row">
                <label class="col-md-7 col-lg-6 col-form-label text-xs-start text-md-end" for="patientDiseaseForm">Ligos forma</label>
                <div class="col-md-4 col-lg-5 mt-2">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm1" formControlName="diseaseForm" [value]="'new'" [(ngModel)]="selectedDiseaseForm"/>
                        <label class="form-check-label" for="conditionForm1">
                            Naujas variantas
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm2" formControlName="diseaseForm"  [value]="'sporaginous'" [(ngModel)]="selectedDiseaseForm"/>
                        <label class="form-check-label" for="conditionForm2">
                            Sporaginė forma
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm3" formControlName="diseaseForm" [value]="'iatrogenic'" [(ngModel)]="selectedDiseaseForm"/>
                        <label class="form-check-label" for="conditionForm3">
                            Jatrogeninė forma
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm4" formControlName="diseaseForm" [value]="'family'" [(ngModel)]="selectedDiseaseForm"/>
                        <label class="form-check-label" for="conditionForm4">
                            Šeiminė (genetinė) forma
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseForm" id="conditionForm5" formControlName="diseaseForm" [value]="'unknown'" [(ngModel)]="selectedDiseaseForm"/>
                        <label class="form-check-label" for="conditionForm5">
                            Nežinoma
                        </label>
                    </div>
                </div>
            </fieldset> -->
            <div class="form-group row">
                <label for="category"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Ligos
                    forma</label>
                <div class="col-md-4 col-lg-5">
                    <select id="category" class="form-select" formControlName="diseaseForm" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let diseaseForm of InfectionsFormDiseaseArray" [value]="diseaseForm.code">
                            {{diseaseForm.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form.get('diseaseForm').value === '5'" class="mb-2 row">
                <label for="templateTitle"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Jei
                    "Nežinoma" įrašyti</label>
                <div class="col-md-4 col-lg-5">
                    <textarea type="text" class="form-control" id="templateTitle" formControlName="writeIfUnknown"
                        maxlength="4000" rows="4"></textarea>
                </div>
            </div>
            <div *ngIf="form.get('diseaseForm').value === '1'" class="mb-2 row">
                <label for="templateTitle"
                    class="col-md-7 col-lg-6 col-form-label align-self-center text-xs-start text-md-end">Informacija
                    apie atliktus diagnostinius
                    tyrimus ligai nustatyti ir jų rezultatus</label>
                <div class="col-md-4 col-lg-5">
                    <textarea type="text" class="form-control" id="templateTitle" formControlName="newInfo"
                        maxlength="4000" rows="4"></textarea>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
    (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
    [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
    [submitButtonDisabled]="true"></app-modification-actions>
