export enum FetusMovements {
    Sufficient = "Pakankami",
    NotEnough = "Nepakankami (≤10/60 min.)",
    DoesntFeel = "Nejaučia",
}

export enum FetusPathology {
    Head = "Galva",
    Buttocks = "Sėdmenys",
    Legs = "Kojos",
    Others = "Kita",
}

export enum FetusPosition {
    Longitudinal = "Išilginė",
    Diagonal = "Įstrižinė",
    Transverse = "Skersinė",
    Volatile = "Nepastovi",
}

export enum AdditionalSpecialistConsultation {
    Code2012 = "Kodas LPK  2012",
    Code2010 = "Kodas LPK 2009-2010",
    Name2012 = "Pavadinimas LPK 2012",
    Name2010 = "Pavadinimas LPK 2009-2010",
    Level = "Lygmuo",
}

export const MaintenancePlanWeeks = ["<11", "12", "14", "18", "20", "24", "27", "28", "29", "35", "37", "40", ">41"];

export const MaintenancePlanTableDataNames = [
    //...TODO: check if all names is correct
    AdditionalSpecialistConsultation.Code2010,
    AdditionalSpecialistConsultation.Code2012,
    AdditionalSpecialistConsultation.Level,
    AdditionalSpecialistConsultation.Name2010,
    AdditionalSpecialistConsultation.Name2012,
];

export const MaintenancePlanTableDataStaticValues = [
    {
        name: "Apsilankymai (bendrosios praktikos gydytojas)*",
        "11": { periodFrom: true, periodTo: true, note: "1" },
        "12": { periodFrom: true, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: true, periodTo: false, note: "2", mergeLines: 5 },
        "20": { periodFrom: false, periodTo: false, hideColumn: true },
        "24": { periodFrom: false, periodTo: false, hideColumn: true },
        "27": { periodFrom: false, periodTo: false, hideColumn: true },
        "28": { periodFrom: false, periodTo: true, hideColumn: true },
        "29": { periodFrom: true, periodTo: false, note: "2-3", mergeLines: 4 },
        "35": { periodFrom: false, periodTo: false, hideColumn: true },
        "37": { periodFrom: false, periodTo: false, hideColumn: true },
        "40": { periodFrom: false, periodTo: true, hideColumn: true },
        "41": { periodFrom: true, periodTo: true, note: "1" },
    },
    {
        name: "Bendras kraujo tyrimas, RPR, ŽIV",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: true, periodTo: true, note: "32 sav." },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Gliukozės kraujo plazmoje nustatymas",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: true, periodTo: true },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Kraujo tyrimas dėl hepatito B (HBsAg)",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: true, periodTo: true },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Kraujo grupė ir Rh(D) faktorius",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Rh(D) antikūnių nustatymas**",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Šlapimo pasėlis besimptomei bakteriurijai nustatyti",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: false },
        "14": { periodFrom: false, periodTo: true },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Vaisiaus ultragarsinis tyrimas",
        "11": { periodFrom: false, periodTo: false },
        "12": { periodFrom: true, periodTo: false },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: true },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: true, periodTo: false },
    },
    {
        name: "Kardiatograma",
        "11": { periodFrom: false, periodTo: false },
        "12": { periodFrom: false, periodTo: false },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: true, periodTo: false },
    },
    {
        name: "Gliukozės toleravimo meginys",
        "11": { periodFrom: false, periodTo: false },
        "12": { periodFrom: false, periodTo: false },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: true, periodTo: false },
        "28": { periodFrom: false, periodTo: true },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Pasėlis dėl BGS infekcijos rizikos",
        "11": { periodFrom: false, periodTo: false },
        "12": { periodFrom: false, periodTo: false },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Odontologo konsultacija",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Vidaus ligų/šeimos gydytojo konsultacija",
        "11": { periodFrom: true, periodTo: false },
        "12": { periodFrom: false, periodTo: true },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: false },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: false, periodTo: false },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: false, periodTo: false },
    },
    {
        name: "Gydytojo akušerio ginekologo konsultacija",
        "11": { periodFrom: false, periodTo: false },
        "12": { periodFrom: true, periodTo: false },
        "14": { periodFrom: false, periodTo: false },
        "18": { periodFrom: false, periodTo: false },
        "20": { periodFrom: false, periodTo: true },
        "24": { periodFrom: false, periodTo: false },
        "27": { periodFrom: false, periodTo: false },
        "28": { periodFrom: false, periodTo: false },
        "29": { periodFrom: false, periodTo: false },
        "35": { periodFrom: false, periodTo: false },
        "37": { periodFrom: true, periodTo: true },
        "40": { periodFrom: false, periodTo: false },
        "41": { periodFrom: true, periodTo: true },
    },
];
