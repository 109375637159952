<nhr-record-widget
    [closed]="false"
    [title]="'inr.notifForm.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row req">
            <div class="offset-md-4 offset-lg-3 col-md-8 col-lg-9">
                <div class="input-group">
                    <app-radio-button
                        *ngFor="let option of notificationAbout; let i = index"
                        radioName="notificationAbout-{{i}}"
                        [fControl]="formGroup"
                        [radioValue]="option.name"
                        id="notificationAbout-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('notificationAbout')" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>