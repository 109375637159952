<nhr-record-widget
    [closed]="false"
    [title]="'inr.doctorInformingForm.title' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <div class="row mb-3 req">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.doctorInformingForm.informedDoctor' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <div>
                    <app-radio-button
                        *ngFor="let option of yesNoOptions; let i = index"
                        radioName="informedDoctor-{{i}}"
                        [fControl]="formGroup.get('informedDoctor')"
                        [radioValue]="option.name"
                        id="informedDoctor-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('informedDoctor')" />
                </div>
            </div>
        </div>
        <div class="row mb-3" *ngIf="editable">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label ">{{'inr.doctorInformingForm.searchSpecialist' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-find-and-prefill-info
                    [obs]="getSpecialistsObs"
                    [disable]="controlValue('informedDoctor') !== 'Taip'"
                    (selectData)="selectDoctor($event)"
                />
            </div>
        </div>
        <ng-container *ngIf="controlValue('informedDoctor') === 'Taip'">
            <div class="row mb-3 req">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label ">{{'inr.doctorInformingForm.name' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="formGroup.get('fullName')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('fullName')" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label ">{{'inr.doctorInformingForm.phone' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="formGroup.get('phone')"
                    ></app-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label ">{{'inr.doctorInformingForm.email' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="formGroup.get('email')"
                    ></app-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label ">{{'inr.doctorInformingForm.doctorInstitution' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="formGroup.get('doctorInstitution')"
                    ></app-input>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>