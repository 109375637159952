<nhr-record-widget
    [closable]="true"
    [closed]="closed"
    [title]="'E113-UG | Vaisiaus ultragarsinis tyrimas' | translate"
    id="ultrasound"
    class="h-auto"
>
    <app-select
        id="researchDate"
        labelText="Tyrimo data"
        labelClass="fs-6 fw-bold"
        [placeholder]="null"
        [values]="researchDates | async"
        [fControl]="researchDateFormControl"
    />
    <app-ultrasound-examination-template
        [ultrasoundData]="(ultrasoundData | async)[researchDateFormControlValue]"
        [selectedButton]="selectedFetus"
    ></app-ultrasound-examination-template>
</nhr-record-widget>
