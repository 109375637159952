<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa fa-stethoscope'" [showIcon]="false" [closable]="true" [closed]="false" [mixed]="true" title="{{'doc.healthAssessment.form.title'|translate}}">
    <div after-title *ngIf="currentRisks?.length && !viewOnly" class="widget-alert alert alert-dismissable alert-warning d-flex justify-content-between flex-wrap">
        <span>
            {{'doc.healthAssessment.form.riskFactors' | translate | uppercase}} <span class="vr"></span>
            {{'doc.healthAssessment.form.riskWarning' | translate}}
        </span>
        <button class="btn btn-warning btn-sm" (click)="openCurrentsRiskFactorsModal()">
            {{'doc.healthAssessment.form.riskFactors' | translate}}
        </button>
    </div>
    <div class="widget-body p-0">
        <ol *ngIf="newRisks?.length" class="slats hover border-bottom border-dark-subtle">
            <!-- TODO-design-slat-item: check if edit and delete works -->
            <li
                slat-item
                [edit]="!viewOnly"
                [delete]="!viewOnly"
                (editButtonClick)="openEditRiskModal(i)"
                (deleteButtonClick)="delete(i)"
                *ngFor="let risk of newRisks; let i = index">
                <strong class="name">{{ risk.riskFactor?.name }}</strong>
                <div class="details small">
                    <span class="me-1">
                        <span class="text-secondary">{{ risk.date | date: 'YYYY-MM-dd' }}</span>
                    </span> <span class="vr"></span>
                    <span class="mx-1 fw-bolder">
                        <ng-container *ngIf="risk.assessmentScore">
                            <strong>{{ risk.assessmentScore }}</strong>
                        </ng-container>
                        <ng-container *ngIf="!risk.assessmentScore">
                            <strong>NA</strong>
                        </ng-container>
                    </span> <span class="vr"></span>
                    <span class="mx-1">
                        <ng-container *ngIf="risk.riskFactorMethodologyName">
                            <span>{{ risk.riskFactorMethodologyName }}</span>
                        </ng-container>
                        <ng-container *ngIf="!risk.riskFactorMethodologyName">
                            <span>-</span>
                        </ng-container>
                    </span>
                </div>
                <div class="description fs-5 fw-light">{{ risk.comments ?? '-' }}</div>
            </li>
        </ol>
        <div class="p-4" *ngIf="!viewOnly">
            <div class="row mb-3 mt-3 req">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label text-nowrap">
                        {{ 'doc.healthAssessment.form.factor' | translate }}
                    </strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <input id="factor" [formControl]="riskFactor" type="text"
                        class="form-control" [typeahead]="riskFactorsSuggestions$" [typeaheadAsync]="true"
                        [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                        (typeaheadOnSelect)="openAddRiskFactorModal($event)"
                        [typeaheadOptionField]="'name'"
                        placeholder="{{'Pasirinkite' | translate}}">
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
