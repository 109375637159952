ng1App.factory('IntegratedComponentContext', 
    ['$rootScope',
     function ($rootScope) {
    var initialized = false;
    var data = {
        encounterId: null
    };
    var hideDocCancelAction = false;
    var integratedComponentContext = {
        setData: function (encounterId) {
            data.encounterId = encounterId;
            initialized = true;
        },
        isInitialized: function() {
          return initialized;
        },
        getEncounterId: function () {
            return data.encounterId;
        },
        isIntegratedComponent: function() {
            return !!$rootScope.restrictedMode;
        },
        setHideDocCancelAction: function() {
            hideDocCancelAction = true;
        },
        isHideDocCancelAction: function() {
            return hideDocCancelAction;
        }
    };
    return integratedComponentContext;
}]);