ng1App.controller('doctor.patient.cert.e049ViewCtrl', 
    ['$scope', '$routeParams', 'AsideBlockModel',
     function ($scope, $routeParams, AsideBlockModel) {
    $scope.patientId = $routeParams.patientId;
    $scope.compositionId = $scope.certificate.compositionId;
    $scope._shared = {
        contentList: [
            new AsideBlockModel('cer.e049.mai.inf'),
            new AsideBlockModel('cer.e049.doc'),
            new AsideBlockModel('cer.e049.har', function () {
                var variable = $scope.certificate.harmfulFactors;
                return typeof variable !== 'undefined' && variable !== null
                    && $scope.certificate.harmfulFactors.items.length > 0;
            }),
            new AsideBlockModel('cer.e049.pri.pro', function () {
                var variable = $scope.certificate.previousJobsDescription;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e049.mai.kno', function () {
                var variable = $scope.certificate.healthHistory;
                return typeof variable !== 'undefined' && variable !== null;
            }),
            new AsideBlockModel('cer.e049.cons', function () {
                return $scope.certificate.documentStatus !== 'FINAL_SIGNED'
                    && $scope.certificate.documentStatus !== 'CANCELED_SIGNED';
            }),
            new AsideBlockModel('cer.e049.fin.cons', function () {
                return $scope.certificate.conclusionRemarks
                        || $scope.certificate.expireDate
            })
        ]
    };
}]);