ng1App.controller('doctor.patient.cert.e106EditCtrl', 
    ['$scope', '$filter', 'EntitiesApiFactory', 'MessageService', 'CertificatePayment', 'TypeaheadSearchHandlers', 'CertificateService', 'CertificatesApiFactory', '$window',
     function ($scope, $filter, EntitiesApiFactory, MessageService, CertificatePayment, TypeaheadSearchHandlers, CertificateService, CertificatesApiFactory, $window) {
    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.e106.mai.inf'),
                id: 'cer_e106_mai_inf'
            },
            {
                title: $filter('translate')('cer.e106.und'),
                id: 'cer_e106_und'
            },
            {
                title: $filter('translate')('cer.e106.rep'),
                id: 'cer_e106_rep'
            },
            {
                title: $filter('translate')('cer.e106.dea'),
                id: 'cer_e106_dea'
            },
            {
                title: $filter('translate')('cer.e106.inf'),
                id: 'cer_e106_inf'
            },
            {
                title: $filter('translate')('cer.e106.new'),
                id: 'cer_e106_new'
            },
            {
                title: $filter('translate')('cer.e106.pla'),
                id: 'cer_e106_pla'
            },
            {
                title: $filter('translate')('cer.e106.acc'),
                id: 'cer_e106_acc',
                show: function () {
                    if ($scope.additional.incidentData) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e106.rea'),
                id: 'cer_e106_rea'
            },
            {
                title: $filter('translate')('cer.e106.mai'),
                id: 'cer_e106_mai'
            },
            {
                title: $filter('translate')('cer.e106.oth'),
                id: 'cer_e106_oth'
            },
            {
                title: $filter('translate')('cer.e106.ope'),
                id: 'cer_e106_ope'
            },
            {
                title: $filter('translate')('cer.e106.wri'),
                id: 'cer_e106_wri'
            }
        ]
    };

    $scope.$emit('setHelpPage', 'kurti-pazyma-e106-medicininis-mirties-liudijimas');

    var certificateType = 'e106';

    MessageService.clearAll();

    CertificatePayment.showPaymentMessage(certificateType);

    $scope.additional = {
        unidentified: false,
        replace: false,
        isReplacedCertificateDigital: false,
        byBodyFoundDate: false,
        newbornDeath: false,
        incidentData: false,
        incidentDateUnknown: false,
        tempDeathCause: ''
    };

    $scope.currentDate = new Date();

    // Get values	
    $scope.searchDeathCauses = TypeaheadSearchHandlers.ICD10;

    $scope.deathDateInterpretations = EntitiesApiFactory.getList({classCode: 'death-date-interpretation'});

    $scope.accidentSites = EntitiesApiFactory.getList({classCode: 'accident-site'}, function (result) {
        if ($scope.additional.incidentData) {
            //$scope.certificate.incidentPlace = result[0];
        }
    });

    $scope.deathCertificateSpecialistTypes = EntitiesApiFactory.getList({classCode: 'death-cert-specialist-type'});

    $scope.operationBeforeDeathPerformedStatuses = EntitiesApiFactory.getList({classCode: 'operation-before-death-performed'});

    $scope.deathPlaces = EntitiesApiFactory.getList({classCode: 'death-place'});

    $scope.pregnancyCompletions = EntitiesApiFactory.getList({classCode: 'pregnancy-completion'});

    $scope.maternalDeathTypes = EntitiesApiFactory.getList({classCode: 'maternal-death-type'});

    $scope.deathTypes = EntitiesApiFactory.getList({classCode: 'death-type'});

    $scope.deathCertificateStatuses = EntitiesApiFactory.getList({classCode: 'death-certificate-status'});

    $scope.isWoman = function () {
        return $scope.patientCtx.sex && (($scope.patientCtx.sex.code == '2') || ($scope.patientCtx.sex.code == 'F'));
    };

    $scope.$watch('certificate.deathType', function (newValue) {
        if (newValue) {
            $scope.additional.incidentData = (newValue.code == '3') || (newValue.code == '4') || (newValue.code == '5') || (newValue.code == '6');

            if (!$scope.additional.incidentData) {
                $scope.additional.incidentDateUnknown = false;

                $scope.certificate.incidentCircumstances = null;
                $scope.certificate.incidentPlace = null;
                $scope.certificate.incidentPlaceDescription = null;
            } else {
                // $scope.certificate.incidentPlace = $scope.accidentSites[0];
            }
        }
    });

    $scope.onChangeIncidentDateUnknown = function () {
        if(!$scope.additional.incidentDateUnknown){
            $scope.certificate.incidentDate = null;
            //NOTE list index here is swaped with line below since values were swapped, if you're here because of validation issue on this field theres probably inconsistency with list ordering somwhere
            $scope.certificate.incidentDateInterpretation = $scope.additional.incidentDateUnknown ? $scope.deathDateInterpretations[0] : null;
        }
    };

    $scope.$watch('additional.newbornDeath', function (newValue) {
        if (!newValue) {
            $scope.certificate.pregnancyCompletion = null;
            $scope.certificate.newbornMass = null;
        }
    });

    $scope.$watch('additional.byBodyFoundDate', function (newValue) {
        //NOTE list index here is swaped with line above since values were swapped, if you're here because of validation issue on this field theres probably inconsistency with list ordering somwhere
        $scope.certificate.deathDateInterpretation = newValue ? $scope.deathDateInterpretations[1] : null;
    });

    $scope.$watch('additional.unidentified', function (newValue) {
        if (!newValue) {
            $scope.certificate.hypotheticalAge = null;
        }
    });

    $scope.additional.isReplacedCertificateDigital = $scope.certificate.replacedDigitalCertificate && $scope.certificate.replacedDigitalCertificate.fullId != null;
    $scope.additional.replace = $scope.certificate.replacedCertificate && ($scope.certificate.replacedCertificate.number != null || $scope.certificate.replacedCertificate.date != null) || $scope.additional.isReplacedCertificateDigital;
    $scope.replacedCertificateChange = function () {
        if ($scope.additional.replace && !$scope.additional.isReplacedCertificateDigital) {
            $scope.certificate.replacedCertificate = {
                number: null,
                date: null
            };
        } else if(!$scope.additional.replace && $scope.additional.isReplacedCertificateDigital){
            $scope.certificate.replacedCertificate = null;
            $scope.certificate.replacedDigitalCertificate = null;
            $scope.additional.isReplacedCertificateDigital = false
        } else {
            $scope.certificate.replacedCertificate = null;
        }
    };
    $scope.replacedCertificateRegisteredChange = function(){
        if($scope.additional.replace && $scope.additional.isReplacedCertificateDigital){
            $scope.certLoading = true;
            CertificatesApiFactory.findDeathCertificate({patientId: $scope.certificate.patient.id, type: 'e106'},function(result){
                if(!result.value){
                    MessageService.error($filter('translate')('cer.e106_2_1.rep.nrDi.empty'));
                    $window.scrollTo(0, 10);
                }
                $scope.certificate.replacedDigitalCertificate = result.value;
                $scope.replacedCertificateChange();
                $scope.certLoading = false;
            },function(){
                $scope.certLoading = false;
            })
        } else {
            $scope.replacedCertificateChange();
        }
    };

    $scope.$watch('certificate.operationBeforeDeathPerformed', function (newValue) {
        if (newValue && newValue.code != '1') {
            $scope.certificate.operationDate = null;
            $scope.certificate.operationCause = null;
        }
    });

    $scope.saveAndSign = function (doSign) {
        var mustBeEntities = [
            {
                value: $scope.certificate.deathCause,
                inputName: $filter('translate')('cer.e106.mai.dia'),
                messageRegion: 'death-cause-region',
                inputValue: $scope.additional.tempDeathCause
            }
        ];
        if($scope.additional.incidentDateUnknown){
            $scope.certificate.incidentDateUnknown = $scope.additional.incidentDateUnknown;
        } else {
            $scope.certificate.incidentDateUnknown = false;
        }
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, mustBeEntities, null, doSign);
    };

    $scope.changeDeathCause = function (deathCause) {
        if (deathCause && deathCause.code) {
            if (!$scope.certificate.deathCause || $scope.certificate.deathCause.code != deathCause.code) {
                $scope.certificate.deathCauseDescription = deathCause.name;
            }
            $scope.certificate.deathCause = deathCause;

            // Synchronise model with view
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.getMinPerformOperationDate = function () {
        if (!$scope.certificate.deathDate) {
            return null;
        }
        var deathDate = new Date($scope.certificate.deathDate);
        deathDate.setDate(deathDate.getDate() - 28);
        return deathDate;
    };

    var findEntityByCode = function(all, entity){
        return _.find(all, function (item) {
            return item.code === entity.code;
        });
    };

    $scope.certificate.$promise.then(function() {
        var cert = $scope.certificate;
        if (cert.deathCause && cert.deathCause.code) {
            $scope.additional.tempDeathCause = TypeaheadSearchHandlers.entities_label(cert.deathCause);
        }
        if(cert.hypotheticalAge){
            $scope.additional.unidentified = true;
        }
        if (cert.certificateStatus) {
            $scope.deathCertificateStatuses.$promise.then(function () {
                cert.certificateStatus = findEntityByCode($scope.deathCertificateStatuses, cert.certificateStatus);
            });
        }
        if(cert.deathDateInterpretation){
            $scope.deathDateInterpretations.$promise.then(function(){
                $scope.additional.byBodyFoundDate = true;
                cert.deathDateInterpretation = $scope.deathDateInterpretations[0];
            })
        }
        if (cert.deathType){
            $scope.deathTypes.$promise.then(function () {
                cert.deathType = findEntityByCode($scope.deathTypes, cert.deathType);
            });
        }
        if(cert.pregnancyCompletion || cert.newbornMass){
            $scope.additional.newbornDeath = true;
        }
        if (cert.pregnancyCompletion) {
            $scope.pregnancyCompletions.$promise.then(function () {
                cert.pregnancyCompletion = findEntityByCode($scope.pregnancyCompletions, cert.pregnancyCompletion);
            });
        }
        if (cert.deathPlace){
            $scope.deathPlaces.$promise.then(function () {
                cert.deathPlace = findEntityByCode($scope.deathPlaces, cert.deathPlace);
            });
        }
        if (cert.operationBeforeDeathPerformed){
            $scope.operationBeforeDeathPerformedStatuses.$promise.then(function () {
                cert.operationBeforeDeathPerformed = findEntityByCode($scope.operationBeforeDeathPerformedStatuses, cert.operationBeforeDeathPerformed);
            });
        }
        if (cert.deathCertificateSpecialistType) {
            $scope.deathCertificateSpecialistTypes.$promise.then(function (items) {
                cert.deathCertificateSpecialistType = findEntityByCode(items, cert.deathCertificateSpecialistType);
            });
        }
        if (cert.maternalDeathType) {
            $scope.maternalDeathTypes.$promise.then(function (items) {
                cert.maternalDeathType = findEntityByCode(items, cert.maternalDeathType);
            });
        }
        if (cert.incidentPlace) {
            $scope.accidentSites.$promise.then(function (items) {
                cert.incidentPlace = findEntityByCode(items, cert.incidentPlace);
            });
        }

    });

}]);