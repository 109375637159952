import {ErxPrescriptionTypeEnum} from "../../../../../../../shared/models/erx-prescription-type.enum";

angular.module('erx').directive('erxViewDispenseWidget', function () {
  return {
    restrict: 'E',
    /*scope: '=',*/
    replace: false,
    templateUrl: 'modules/erx/patient-history/view-dispense/viewDispenseWidget/viewDispenseWidget.html',
    controller: ["$scope", "$erxModalMedinfo", "gettextCatalog", function ($scope, $erxModalMedinfo, gettextCatalog) {

      var _isLoading = false;

      var initForm = function () {
        $scope.static = {};
        $scope.static.drug = $scope.dispense.dispenseData.dispense.medication;
        $scope.prescription = {
            prescriptionData: $scope.dispense.prescription,
            metadata: $scope.dispense.metadata
        };

        if ($scope.isExtemporalForm()) {
          if ($scope.prescription.prescriptionData.medicationData.ingredients) {
            $scope.mainIngredient = $scope.data.dispense.medication.ingredients[0].medication ? 'va' : 'vm';
            if ($scope.data.dispense.medication.ingredients[0].medication) {
              $scope.drug = $scope.data.dispense.medication.ingredients[0].medication;
              if ($scope.data.dispense.medication.ingredients[0].amount) {
                $scope.extempNumerator = $scope.data.dispense.medication.ingredients[0].amount.numerator;
                $scope.extempDenumerator = $scope.data.dispense.medication.ingredients[0].amount.denominator;
              }
            }
            if ($scope.data.dispense.medication.ingredients[0].substance) {
              $scope.extempAmount = $scope.data.dispense.medication.ingredients[0].amount;
              $scope.extempPharmForm = $scope.data.dispense.medication.ingredients[0].pharmForm;
              $scope.extempStrength = $scope.data.dispense.medication.ingredients[0].strength;
              $scope.extempDrugName = $scope.data.dispense.medication.ingredients[0].substance.name;
              if ($scope.data.dispense.medication.ingredients[0].amount) {
                $scope.extempNumerator = $scope.data.dispense.medication.ingredients[0].amount.numerator;
                $scope.extempDenumerator = $scope.data.dispense.medication.ingredients[0].amount.denominator;
              }
            }
          }
        }

        if ($scope.isMPPForm()) {
          if ($scope.prescription.prescriptionData.dispenseData.lenses) {
            $scope.lensFlag = true;
            $scope.lensData = $scope.prescription.prescriptionData.dispenseData.lenses;
            $scope.data.dispense.lenses = $scope.prescription.prescriptionData.dispenseData.lenses;
          }
        }
      };


      $scope.getWhenHandedOverLabel = function () {
        return $scope.hasEOrder() ? 'Išdavimo data / Nuotolinė sutartis pasirašyta' : 'Išdavimo data';
      }

      $scope.isCompensatedDrug = function () {
        return $scope.data.dispense.compensationTag;
      }

      $scope.hasEOrder = function () {
        return $scope.data.dispense.hasEOrder;
      }

      $scope.isMedicineForm = function () {
          return ($scope.prescriptionType === ErxPrescriptionTypeEnum.VA);
      };

      $scope.isNamedMedicineForm = function () {
          return ($scope.prescriptionType === ErxPrescriptionTypeEnum.VV);
      };

      $scope.isMPPForm = function () {
          return $scope.prescriptionType === ErxPrescriptionTypeEnum.MPP;
      }

        $scope.isMPAForm = function () {
            return $scope.prescriptionType === ErxPrescriptionTypeEnum.MPA;
        }

      $scope.isExtemporalForm = function () {
          return $scope.prescriptionType === ErxPrescriptionTypeEnum.EV;
      };

      $scope.isOtherForm = function () {
          return $scope.prescriptionType === ErxPrescriptionTypeEnum.KP;
      };

      $scope.getSpecificTemplateUrl = function () {
        if ($scope.isMedicineForm() || $scope.isNamedMedicineForm() && $scope.prescription.prescriptionData.medicationRef)
          return 'modules/erx/patient-history/view-dispense/viewDispenseWidget/specific_dsp/view_m_dispense.html'
        else if ($scope.isNamedMedicineForm())
          return 'modules/erx/patient-history/view-dispense/viewDispenseWidget/specific_dsp/view_vv_dispense.html'
        else if ($scope.isMPPForm())
          return 'modules/erx/patient-history/view-dispense/viewDispenseWidget/specific_dsp/view_mpp_dispense.html'
        else if ($scope.isMPAForm())
            return 'modules/erx/patient-history/view-dispense/viewDispenseWidget/specific_dsp/view_mpa_dispense.html'
        else if ($scope.isExtemporalForm())
          return 'modules/erx/patient-history/view-dispense/viewDispenseWidget/specific_dsp/view_em_dispense.html'
      };

      $scope.getDosageFrequency = function () {
        if (angular.isDefined($scope.data.dispense.dosage)) {
          var ret = '';
          for (var i = 0; i < $scope.timesOfDay.length; i++) {
            if ($scope.data.dispense.dosage[$scope.timesOfDay[i].code]) {
              if (ret !== '')
                ret += ', ';
              ret += gettextCatalog.getString($scope.timesOfDay[i].displayValue);
            }
            if (i === $scope.timesOfDay.length - 1 && ret !== '')
              ret += '.';
          }
          return ret;
        }
      };

      $scope.getDosageFrequencyPerDay = function () {
        var ret = "";
        if (angular.isDefined($scope.data.dispense.dosage.schedule.frequency)
          && angular.isDefined($scope.data.dispense.dosage.schedule.units)) {
          ret += $scope.data.dispense.dosage.schedule.frequency;
          ret += ' kartai per ';
          ret += $scope.data.dispense.dosage.schedule.units;

        }
        return ret;
      }

      $scope.getDosageFrequencyEveryXHours = function () {
        var ret = "";
        if (angular.isDefined($scope.data.dispense.dosage.timeQuantity)) {
          ret += 'kas ';
          ret += $scope.data.dispense.dosage.timeQuantity;
          ret += ' valandas';
        }
        return ret;
      };

      $scope.getTreatmentDuration = function () {
        var ret = "";
        if (angular.isDefined($scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration)) {
          ret += $scope.medicationPrescription.dispenseData.dispenseExpectedSupplyDuration + ' d.';
        }
        return ret;
      };

      $scope.getIntakeTimes = function () {
        var ret = '';
        for (var i = 0; i < $scope.intakeTimes.length; i++) {
          if ($scope.data.dispense.dosage[$scope.intakeTimes[i].code]) {
            if (ret !== '')
              ret += ', ';
            ret += gettextCatalog.getString($scope.intakeTimes[i].displayValue);
          }
          if (i === $scope.intakeTimes.length - 1 && ret !== '')
            ret += '.';
        }
        return ret;
      };

      $scope.isCompensatedDrug = function () {
        return $scope.data.dispense.compensationTag;
      }

      $scope.getPrescriptionId = function () {
          return $scope.data.prescription.ref?.split('/')[1];
      }

      $scope.showMedInfo = function () {
        $erxModalMedinfo.show({ ref: $scope.data.dispense.medicationRef });
      }

      $scope.showLowIncome = function() {
        return $scope.data.dispense.lowIncome;
      }

      $scope.hasPatientLowIncome = function () {
        return $scope.data.dispense.lowIncome
            && $scope.data.dispense.lowIncome.lowIncomeTag == true;
      }

      $scope.isLoading = function () {
        return _isLoading;
      }

      var loadClassifiers = function () {
        $scope.timesOfDay = [
          { code: 'morning', displayValue: 'Ryte' },
          { code: 'noon', displayValue: 'Per pietus' },
          { code: 'evening', displayValue: 'Vakare' },
          { code: 'asNeeded', displayValue: 'Pagal poreikį' }];
      };

      loadClassifiers();
      initForm();
    }]
  };
});