ng1App.controller('doctor.user.SpecialistCtrl', 
    ['$scope', '$routeParams', '$location', '$window', 'PractitionersApiFactory',
     function ($scope, $routeParams, $location, $window, PractitionersApiFactory)  {
	$scope.$emit('setHelpPage', 'perzireti-kito-specialisto-duomenis');
    
    $scope.goBack = function () {
    	$window.history.back();
    };
    
	$scope.practitionerId = $routeParams.id;
	
	$scope.load = function() {
		PractitionersApiFactory.getFull({id: $scope.practitionerId}, function (data) {
			$scope.practitioner = data;
			$scope.practitioner.fullName = _.getFullName(data);
        });
	};
	$scope.load();
	
	$scope.sendMessage = function(){
		$location.path('/dp/messages/new').search({practitioner: $scope.practitionerId});
	};
	
}]);