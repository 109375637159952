import { PartMedicalModalComponent } from "src/app/shared/components/medical-data-modal-windows/partMedicalData-modals/partMedicalModal.component";
import {
    CurrentRisksListModalComponent
} from "../../app/shared/components/current-risks-list-modal/current-risks-list-modal.component";
import {
    CurrentAllergiesListModalComponent
} from "../../app/shared/components/current-allergies-list-modal/current-allergies-list-modal";

export var withSysDate = function (SessionApiFactory, data, callback) {
    SessionApiFactory.getSysdate({}, function (res) {
        data.date = res.value;
        callback();
    });
};

var setupIsolatedScope = function(scope, attrs){
    scope.closed = attrs.closed ? attrs.closed : 'false';
    scope.formType = attrs.formType;
    scope._ = _;
    var __findHtmlForm = function(scope){
        if(scope.mainDocumentForm){
            return scope.mainDocumentForm;
        }
        if(scope.$parent){
            return __findHtmlForm(scope.$parent);
        }
        return null;
    }
    scope.mainDocumentForm = __findHtmlForm(scope);
};

var reg_number :RegExp = /([+-]?\d+([\.,]\d+)?)/;
var reg_compare :RegExp = new RegExp('([<>]=?)(\\s+)?('+reg_number.source+')');
var reg_interval :RegExp = new RegExp(reg_number.source+'(\\s+)?-(\\s+)?'+reg_number.source);

var checkLabResItem = function(item){
    if(!item || !item.normalReferenceRange || ! item.value){
        return null;
    }
    var m;
    const value = reg_number.exec(item.value);
    if(!value){
        return null;
    }
    let parsedValue = parseFloat(value[1].replace(',','.'));
    item._value = parsedValue;
    if(item._value === 0){
      item._value ='zero'
    }
    if((m = reg_compare.exec(item.normalReferenceRange)) != null){
        if(m[1] == '<'){
          item._minValue =  undefined;
          item._maxValue = parseFloat(m[3]);
        }else if(m[1] == '<='){
          item._minValue =  undefined;
          item._maxValue = parseFloat(m[3]);
        }else if(m[1] == '>'){
          item._minValue = parseFloat(m[3]);
          item._maxValue = undefined;
        }else if(m[1] == '>='){
          item._minValue = parseFloat(m[3]);
          item._maxValue = undefined;
        }
        if(item._minValue === 0){
          item._minValue ='zero'
        }
        if(item._maxValue === 0){
          item._maxValue ='zero'
        }
        return item.warn = !eval(parsedValue + m[1]+m[3]);
    } else if((m = reg_interval.exec(item.normalReferenceRange)) != null) {
        item._minValue = parseFloat(m[1]);
        item._maxValue = parseFloat(m[5]);
        if(item._minValue === 0){
          item._minValue ='zero'
        }
        if(item._maxValue === 0){
          item._maxValue ='zero'
        }

        var res = item._minValue <= parsedValue && item._maxValue >= parsedValue;
        return item.warn = !res;
    } else {
        return null;
    }
};


ng1App.directive('encounterBlock',
    ['EncountersApiFactory', '$modal', '$filter', 'PatientsApiFactory', 'OrganizationsApiFactory',
     function (EncountersApiFactory, $modal, $filter, PatientsApiFactory, OrganizationsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/view_edit/encounter-block.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {title: $filter('translate')('doc.vis.lar'), id:'doc_vis_lar'};
                    }


                    scope.formType = attrs.formType;
                    scope.editMode = attrs.editMode == true || attrs.editMode == 'true';
                    scope.smallMode = attrs.smallMode == true || attrs.smallMode == 'true';
                    scope.insuranceLoading = false;

                    scope.updateInsurance = function () {
                        scope.insuranceLoading = true;
                        EncountersApiFactory.updateInsurance({id: scope.encounterData.id}, function (resp) {
                            scope.encounterData = resp;
                            scope.insuranceLoading = false;
                        }, function(){
                            scope.insuranceLoading = false;
                        });
                    }
                    scope.form.$promise.then(function(form){
                        var enc = form.metadata.encounter;
                        if(enc.paperOrder){
                            OrganizationsApiFactory.getById({organizationId: _.getIdFromReference(enc.paperOrderOrganizationRef)}, function(org){
                                enc.paperOrderOrganization = org;
                            });
                        }
                    });



                }
            };
        }])
        .directive('editMedicalData', ['BsModalService', 'ApiV2Risks', 'ApiV2Patients',
         function (BsModalService, ApiV2Risks, ApiV2Patients) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_medical_data.html',
                restrict: 'E',
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {title: attrs.title, id: 'doc_med_dat'};
                    }

                    if (!scope.form.partMedicalData) {
                        scope.form.partMedicalData = {};
                    }

                    if (!scope.form.newAllergies){
                        scope.form.newAllergies = {};
                        ApiV2Patients.getActiveAllergies(scope.patientId)
                            .subscribe(res => {
                                scope.form.newAllergies = res;
                            });
                    }

                    if (!scope.form.currentRisks){
                        scope.form.currentRisks = {};
                        ApiV2Risks.getRisksList({patient: scope.patientId})
                            .subscribe(res => {
                                scope.form.currentRisks = res;
                            });
                    }

                    scope.openModal = function(mode) {
                        if(mode === 'complaint'){
                            const initialState = {
                                modalMode: mode
                            }
                            const modal = BsModalService.show(PartMedicalModalComponent, {
                                initialState,
                                animated: true,
                                class: 'modal-xxl'
                            });

                            modal.content.onClose.subscribe((selectedComplaints) => {
                                scope.form.partMedicalData.complaint = selectedComplaints.join('\n');
                                scope.$apply();
                            });
                        } else if(mode === 'anamnesis'){
                            const initialState = {
                                modalMode: mode
                            }
                            const modal = BsModalService.show(PartMedicalModalComponent, {
                                initialState,
                                animated: true,
                                class: 'modal-xxl'
                            });

                            modal.content.onClose.subscribe((selectedComplaints) => {
                                scope.form.partMedicalData.anamnesis = selectedComplaints.join('\n');
                                scope.$apply();
                            });
                        }
                    };

                    scope.openCurrentsRiskFactorsModal = function() {
                        const initialState = {
                            risks: scope.form.currentRisks
                        }
                        const ref = BsModalService.show(CurrentRisksListModalComponent, {
                            initialState,
                            animated: true,
                            class: 'modal-xxl'
                        });
                    }

                    scope.openCurrentsAllergiesModal = function() {
                        const initialState = {
                            allergies: scope.form.newAllergies
                        }
                        const ref = BsModalService.show(CurrentAllergiesListModalComponent, {
                            initialState,
                            animated: true,
                            class: 'modal-xxl'
                        });
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.title = attrs.title;

                    scope.calculateBMI = function () {
                        if (scope.form.partMedicalData.height && scope.form.partMedicalData.weight) {
                            var heightInMeters = scope.form.partMedicalData.height / 100; // convert cm to meters
                            var bmi = scope.form.partMedicalData.weight / (heightInMeters * heightInMeters);
                            scope.form.partMedicalData.bmi = bmi.toFixed(2);
                        }
                    };

                    scope.$watch('form.partMedicalData.height', function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            scope.calculateBMI();
                        }
                    });

                    scope.$watch('form.partMedicalData.weight', function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            scope.calculateBMI();
                        }
                    });
                }
            };
        }])
        .directive('viewProviderData',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_provider_data.html',
                restrict: 'E',
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.pro.dat'),
                            id: 'doc_pro_dat'
                        };
                    }

                    scope.formType = attrs.formType;
                    scope.title = attrs.title;

                    scope.form.$promise.then(function () {
                        if (scope.form.provider.qualification) {
                            scope.form.provider.qualification = _.find(scope.form.provider.orderer.qualificationList, function (q) {
                                return q.code == scope.form.provider.qualification.code;
                            });
                        }
                    });
                }
            };
        }])
        .directive('viewMedicalData', function () {
            return {
                templateUrl: '/templates/documents/components/view/view_medical_data.html',
                restrict: 'E',
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.isMedicalDataEmpty = function () {
                        var part = scope.form.partMedicalData;
                        return part == null || (part.anamnesis == null && part.otherData == null);
                    }

                    scope.calculateBMI = function () {
                        if (scope.form.partMedicalData.height && scope.form.partMedicalData.weight) {
                            var heightInMeters = scope.form.partMedicalData.height / 100; // convert cm to meters
                            var bmi = scope.form.partMedicalData.weight / (heightInMeters * heightInMeters);
                            scope.form.partMedicalData.bmi = bmi.toFixed(2);
                        }
                    };

                    scope.$watch('form.partMedicalData.height', function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            scope.calculateBMI();
                        }
                    });

                    scope.$watch('form.partMedicalData.weight', function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            scope.calculateBMI();
                        }
                    });

                    if(scope.form.partMedicalData){
                        scope.calculateBMI();
                    }

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: attrs.title,
                            id: 'doc_med_dat',
                            show: function() {
                                return ! scope.isMedicalDataEmpty();
                            }
                        };
                    }

                    scope.formType = attrs.formType;
                    scope.title = attrs.title;

                }
            };
        })
        .directive('viewDiagnosis',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_diagnosis.html',
                restrict: 'E',
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: attrs.title ? attrs.title : $filter('translate')('doc.dia'),
                            id: 'doc_dia',
                            show: function () {
                                return scope.form.partDiagnosis && true;
                            }
                        };
                    }

                    scope.formType = attrs.formType;
                    scope.title = attrs.title;
                    scope.smallMode = attrs.smallMode === 'true' || attrs.smallMode === true;
                }
            };
        }])
        .directive('editDiagnosis',
    ['TypeaheadSearchHandlers', 'MessageService', 'SessionApiFactory', '$modalConfirm', 'SubFormValidator', '$filter', 'ConditionsApiFactory',
     function (TypeaheadSearchHandlers, MessageService, SessionApiFactory, $modalConfirm, SubFormValidator, $filter, ConditionsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_diagnosis.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.dia'),
                            id: 'doc_dia'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.showDiagnosisType = attrs.showDiagnosisType == 'false' ? false : true;
                    scope.skipRemoveChecks = attrs.skipRemoveChecks == 'true' ? true : false;

                    if (!scope._removed.diagnosis) {
                        scope._removed.diagnosis = [];
                    }
                    if (!scope._modified.diagnosis) {
                        scope._modified.diagnosis = [];
                    }


                    var initDiagnosisForm = function () {
                        scope.diagnosisForm = {tempdiagnosis:''};
                        scope.diagnosisForm.diagnosisType = 'main'
                    }

                    scope.$watch('initDiagnosis', function (initDiagnosis) {
                        if (initDiagnosis) {
                            if (!scope.diagnosisForm.description) {
                                scope.diagnosisForm.description = initDiagnosis.description;
                            }
                            if (!scope.diagnosisForm.date) {
                                scope.diagnosisForm.date = initDiagnosis.date;
                            }
                            if (!scope.diagnosisForm.diagnosis && initDiagnosis.diagnosis) {
                                var d = initDiagnosis.diagnosis;
                                scope.diagnosisForm.diagnosis = d;
                                scope.diagnosisForm.tempdiagnosis = d.code + ' ' + d.name;

                            }
                        }
                    })

                    initDiagnosisForm();

                    scope.validator = new SubFormValidator('diagnosis-region');

                    /**
                     * on change handler needed for angular-strap typeahead bug on FF.
                     *  https://github.com/mgcrea/angular-strap/issues/652
                     * @param v
                     */
                    scope.searchDiagnosisChange = function (v) {
                        if (v && v.code) {
                            scope.diagnosisForm.diagnosis = v;
                        }
                    }

                    /**
                     * Function for diagnosis typeahead combo.
                     */
                    scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;

                    scope.__modifyDiagnosis = function (d) {
                        scope.validator.clear();
                        scope.diagnosisForm = _.clone(d);
                        scope.diagnosisForm.orig = d;
                        scope.diagnosisForm._modeModification = true;
                        scope.diagnosisForm.tempdiagnosis = d.diagnosis.code + ' ' + d.diagnosis.name;
                        if (scope.showDiagnosisType) {
                            // scope.diagnosisForm.diagnosisType = 'main';
                            scope.diagnosisForm.type = _.find(scope.types.diagnosisTypes, function (i) {
                                return d.type.code === i.code;
                            });
                        }
                    }

                    scope.__cancelDiagnosisModification = function () {
                        scope.validator.clear();
                        initDiagnosisForm();
                    }

                    scope.__saveDiagnosis = function () {
                        doSave(function () {
                            var data = _.clone(scope.diagnosisForm);
                            withSysDate(SessionApiFactory, data, function () {
                                if(data.fullId){
                                    var found = _.find(scope._modified.diagnosis, function(d){
                                        return d.fullId == data.fullId;
                                    });
                                    if(!found){
                                        scope._modified.diagnosis.push(data);
                                    }
                                }
                                var orig = scope.diagnosisForm.orig;
                                scope.diagnosisForm.orig = null;
                                angular.copy(scope.diagnosisForm, orig);
                                initDiagnosisForm();
                            });
                        });
                    }

                    var doSave = function (callback) {
                        scope.validator.clear();
                        var showComonError = true;
                        if (!scope.diagnosisForm.diagnosis || !scope.diagnosisForm.diagnosis.code) {
                            console.debug(scope.diagnosisForm);
                            scope.validator.add('diagnosis');
                        } else if (!scope.diagnosisForm._modeModification && _.find(scope.form.partDiagnosis, function (d) {
                            return d.diagnosis.code == scope.diagnosisForm.diagnosis.code;
                        }) != null) {
                            scope.validator.add('diagnosis');
                            showComonError = false;
                            MessageService.add('error', 'Diagnozė jau pridėta.', 'diagnosis-region');
                        }
                        if (scope.showDiagnosisType) {
                            if ((!scope.diagnosisForm.type || !scope.diagnosisForm.type.code)) {
                                scope.validator.add('type');
                            }
                        }

                        if (scope.formType == 'e003') {
                            if (!scope.validator.hasErrors() && scope.diagnosisForm.diagnosisType == 'main') {
                                var mainExists = null != _.find(scope.form.partDiagnosis, function (d) {
                                    return d.diagnosisType == 'main' && d.diagnosis.code != scope.diagnosisForm.diagnosis.code;
                                });
                                if (mainExists) {
                                    scope.validator.add('diagnosisType');
                                    showComonError = false;
                                    MessageService.add('error', 'Pagrindinė diagnozė jau pridėta.', 'diagnosis-region');
                                }
                            }
                        }


                        if (!scope.validator.hasErrors()) {
                            if (scope.formType == 'e003' || scope.formType == 'e025') {
                                checkDiagnosisWarnings()
                            }
                            callback();
                        } else if (showComonError) {
                            MessageService.add('error', 'Formoje yra klaidų', 'diagnosis-region');
                        }
                    }
                    var checkDiagnosisWarnings = function(){
                        var p = scope.form.patientId;
                        var d = scope.diagnosisForm.diagnosis;
                        var c = scope.diagnosisForm.type.code;
                        var diagDisplay = d.code + ' ' + d.name;
                        if(c == '0'){
                            ConditionsApiFactory.find({patient: p, thisYear: true, code: d.code, exclude: d.fullId}).$promise.then(function(res){
                                if(res.length == 0){
                                    //ĮP.10.
                                    MessageService.add('warning', 'Diagnozė '+diagDisplay+' nėra registruota paciento ESI šiais metais.', 'diagnosis-region');
                                }
                            });
                        } else if(c == '+'){
                            ConditionsApiFactory.find({patient: p, code: d.code, exclude: d.fullId}).$promise.then(function(res){
                                if(res.length > 0){
                                    //ĮP.12.
                                    MessageService.add('warning', 'Diagnozė '+diagDisplay+' yra registruota paciento ESI.', 'diagnosis-region');
                                }
                            });
                        } else {
                            ConditionsApiFactory.find({patient: p, code: d.code, exclude: d.fullId}).$promise.then(function(res) {
                                if(res.length == 0){
                                    //ĮP.11.
                                    MessageService.add('warning', 'Diagnozė '+diagDisplay+' nėra registruota šio paciento ESI.', 'diagnosis-region');
                                }
//                                var found = false;
//                                _.each(res, function (r) {
//                                    if (moment(r.date).year() == moment(new Date()).year()) {
//                                        found = true;
//                                    }
//                                });
//                                if (found) {
//                                    //ĮP.11.
//                                    MessageService.add('warning', 'Diagnozė ' + diagDisplay + ' nėra registruota šio paciento ESI.', 'diagnosis-region');
//                                } else {
//                                    //ĮP.12.
//                                    MessageService.add('warning', 'Diagnozė ' + diagDisplay + ' yra registruota paciento ESI.', 'diagnosis-region');
//                                }
                            });
                        }
                    }

                    scope.__addDiagnosis = function () {
                        doSave(function () {
                            var data = _.clone(scope.diagnosisForm);
                            if (!data.date) {
                                withSysDate(SessionApiFactory, data, function () {
                                    scope.form.partDiagnosis.push(data);
                                    initDiagnosisForm();
                                });
                            } else {
                                scope.form.partDiagnosis.push(data);
                                initDiagnosisForm();
                            }
                        });
                    };

                    var checkIfDiagnosisIsNotUsedInConsultationPlan = function (d) {
                        if (!scope.form.partConsultationPlan) {
                            return true;
                        }
                        return null == _.find(scope.form.partConsultationPlan.items, function (i) {
                            return (i.obsData && i.obsData.diagnosis && i.obsData.diagnosis.code == d.code) || (i.patData && _.find(i.patData.diagnosis, function (item) {
                                return item.code == d.code;
                            }));
                        });
                    }

                    var checkIfDiagnosisIsNotUsedInSpecialistConsultations = function (d) {
                        if (!scope.form.partConsultations) {
                            return true;
                        }
                        return null == _.find(scope.form.partConsultations, function (i) {
                            return _.find(i.diagnosis, function (item) {
                                return item.code == d.code;
                            });
                        });
                    }


                    scope.___removeDiagnosis = function (index) {
                        if(!scope.skipRemoveChecks){
                            if ('e027a' != scope.formType && !checkIfDiagnosisIsNotUsedInConsultationPlan(scope.form.partDiagnosis[index].diagnosis)) {
                                if ('e003' == scope.formType) {
                                    $modalConfirm.show({content: 'Diagnozė panaudota patologinio tyrimo skyrime. Pirma pašalinkite tyrimo įrašą.'});
                                } else {
                                    $modalConfirm.show({content: 'Diagnozė panaudota konsultacijų plane. Pirma pašalinkite konsultacijų įrašą.'});
                                }
                                return;
                            }
                            if ('e027a' != scope.formType && !checkIfDiagnosisIsNotUsedInSpecialistConsultations(scope.form.partDiagnosis[index].diagnosis)) {
                                $modalConfirm.show({content: 'Diagnozė panaudota Specialistų konsultacijų bloke. Pirma pašalinkite konsultacijų įrašą.'});
                                return;
                            }
                        }
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            MessageService.clear('diagnosis-region');
                            var removed = scope.form.partDiagnosis.splice(index, 1);
                            if (removed[0].fullId) {
                                scope._removed.diagnosis.push(removed[0]);
                            }
                        });
                    };
                }
            };
        }])
        .directive('viewAllergies',
    ['PatientsApiFactory', '$window', '$filter',
     function (PatientsApiFactory, $window, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_allergies.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.all'),
                            id: 'doc_all',
                            show: function () {
                                    return (scope.form.partAllergies && scope.form.partAllergies.allergies.length > 0) || (scope.nzav.submitted && !scope.hasActiveAllergies);
                            }
                        };
                    }

                    scope.formType = attrs.formType;
                    scope.form.$promise.then(function (data) {
                        checkPatientAllergies(data.patientId);
                    });


                    function checkPatientAllergies(patientId) {
                        checkActiveAllergies(patientId);
                        checkNzav(patientId);
                    }

                    function checkActiveAllergies(patientId) {
                        PatientsApiFactory.getActiveAllergiesCount({id: patientId}, function (result) {
                            scope.hasActiveAllergies = result.count != 0;
                        });
                    }

                    function checkNzav(patientId) {
                        PatientsApiFactory.getNzavIfExists({id: patientId}, function (nzav) {
                            scope.nzav = {
                                submitted: nzav.practitioner && nzav.date,
                                practitioner: nzav.practitioner,
                                date: nzav.date
                            };
                        });
                    }
                }
            };
        }])
        .directive('viewOrdersPat',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_orders_pat.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.patolog.obs'),
                            id: 'doc_patolog_obs',
                            show: function(){
                                return scope.form.partConsultationPlan.items && scope.form.partConsultationPlan.items.length > 0 ;
                            }
                        };
                    }
                }
            };
        }])
        .directive('viewConsultations',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_consultations.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.spec.cons'),
                            id: 'doc_spec_cons',
                            show: function () {
                                if (scope.form.partConsultations) {
                                    return scope.form.partConsultations;
                                } else {
                                    return false;
                                }
                            }
                        };
                    }
                }
            };
        }])
        .directive('viewInPatientAccomodationData',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_in_patient_accomodation_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.departm.info'),
                            id: 'doc_departm_info',
                            show: function () {
                                return scope.form.partInPatientAccomodation;
                            }
                        };
                    }
                }
            };
        }])
        .directive('viewInPatientDischargeData',
    ['OrganizationsApiFactory', 'TypeaheadSearchHandlers', '$filter',
     function (OrganizationsApiFactory, TypeaheadSearchHandlers, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_in_patient_discharge_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.leave.info'),
                            id: 'doc_leave_info',
                            show: function () {
                                return scope.form.partDischarge && scope.form.partDischarge.type;
                            }
                        };
                    }
                }
            };
        }])
        .directive('viewImmMed',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_immune_medication_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.imm.med'),
                            id: 'doc_imm_med'
                        };
                    }
                }
            };
        }])
        .directive('viewVacInf',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_vaccines_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.vac.inf'),
                            id: 'doc_vac_inf'
                        };
                    }
                }
            };
        }])
        .directive('viewReaTo',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_reaction_to_vaccine.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.rea.tov'),
                            id: 'doc_rea_tov'
                        };
                    }
                }
            };
        }])
        .directive('viewVacRes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_vaccines_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.vis.res'),
                            id: 'doc_vis_res'
                        };
                    }
                }
            };
        }])
        .directive('viewPlaVac',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_planned_vaccines.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pla.vac'),
                            id: 'doc_pla_vac',
                            show: function () {
                                if (scope.form.plannedVaccinations)
                                {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        };
                    }
                }
            };
        }])
        .directive('viewLabRes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_laboratory_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.newTab = attrs.newTab;
                    scope.findOrderDocument = attrs.findOrderDocument;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.lab.res'),
                            id: 'doc_lab_res'
                        };
                    }
                }
            };
        }])
        .directive('viewMatRes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_materiall_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.mat.res'),
                            id: 'doc_mat_res'
                        };
                    }
                }
            };
        }])
        .directive('viewLabTest',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_laboratory_test.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.lab.tes'),
                            id: 'doc_lab_tes'
                        };
                    }
                }
            };
        }])
        .directive('viewLabResults',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_laboratory_results.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.lab.resu'),
                            id: 'doc_lab_resu'
                        };
                    }

                    scope.form.$promise.then(function(){
                        _.each(scope.form.analyteItems, function(i){
                            checkLabResItem(i);
                        });
                    });
                }
            };
        }])
        .directive('viewExtLabDat',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_extra_laboratory_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.ext.lab'),
                            id: 'doc_ext_lab'
                        };
                    }
                }
            };
        }])
        .directive('viewMateriallReceiving',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_materiall_receiving.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.mat.rec'),
                            id: 'doc_mat_rec'
                        };
                    }
                }
            };
        }])
        .directive('editAllergies',
    ['MessageService', 'TypeaheadSearchHandlers', 'SessionApiFactory', 'SubFormValidator', '$filter', '$modalConfirm', 'PatientsApiFactory', 'PractitionersApiFactory', 'SessionContext', '$routeParams', '$window',
     function (MessageService, TypeaheadSearchHandlers, SessionApiFactory, SubFormValidator, $filter, $modalConfirm, PatientsApiFactory, PractitionersApiFactory, SessionContext, $routeParams, $window) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_allergies.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.all'),
                            id: 'doc_all'
                        };
                    }

                    scope.formType = attrs.formType;
                    checkPatientAllergies();

                    function checkPatientAllergies() {
                        checkActiveAllergies();
                        checkNzav();
                    }

                    function checkActiveAllergies(callback?) {
                        PatientsApiFactory.getActiveAllergiesCount({id: $routeParams.patientId}, function (result) {
                            scope.hasActiveAllergies = result.count != 0;
                        });
                    }

                    function checkNzav() {
                        PatientsApiFactory.getNzavIfExists({id: $routeParams.patientId}, function (nzav) {
                            scope.nzav = {
                                submitted: nzav.practitioner && nzav.date ? true : false,
                                practitioner: nzav.practitioner,
                                date: nzav.date
                            };
                        });
                    }
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            var f = scope.form.partAllergies;
                            scope.closed = !(f.allergies.length > 0 || f.noAllergies);
                        });
                    }

                    if (!scope._removed.allergies) {
                        scope._removed.allergies = [];
                    }
                    if (!scope._modified.allergies) {
                        scope._modified.allergies = [];
                    }

                    var initAllergiesForm = function () {
                        scope.allergiesForm = {
                            _invalidFields: {}
                        };
                        withSysDate(SessionApiFactory, scope.allergiesForm, function () {
                        });
                    }
                    initAllergiesForm();

                    scope.validator = new SubFormValidator('allergies-region');

                    /**
                     * on change handler needed for angular-strap typeahead bug on FF.
                     * @param v
                     */
                    scope.searchAllergiesChange = function (v) {
                        if (v && v.id) {
                            scope.allergiesForm.allergy = v;
                        }
                    }

                    scope.searchAllergies = TypeaheadSearchHandlers.ICD10;

                    scope.__modifyAllergy = function (index) {
                        scope.validator.clear();
                        scope.allergiesForm = _.clone(scope.form.partAllergies.allergies[index]);
                        scope.allergiesForm.index = index;
                        scope.allergiesForm._modeModification = true;
                        scope.allergiesForm.tempallergy = scope.allergiesForm.allergy.code + ' ' + scope.allergiesForm.allergy.name;
                    }

                    scope.__cancelAllergyModification = function () {
                        scope.validator.clear();
                        initAllergiesForm();
                    }


                    var __validate = function (callback) {
                        var showCommonError = true;
                        scope.validator.clear();
                        if (!scope.allergiesForm.allergy) {
                            scope.validator.add('allergy');
                        }
                        // check if allergy by code isnt added yet
                        if (scope.form.partAllergies) {
                            _.each(scope.form.partAllergies.allergies, function (a, index) {
                                if (scope.allergiesForm.allergy && a.allergy.code == scope.allergiesForm.allergy.code && (!scope.allergiesForm._modeModification || scope.allergiesForm.index != index)) {
                                    scope.validator.add('allergy');
                                    showCommonError = false;
                                    MessageService.add('error', 'Alergija jau pridėta.', 'allergies-region');
                                }
                            });
                        }
                        if (!scope.allergiesForm.referralMaterialCode) {
                            scope.validator.add('referralMaterialCode');
                        }
                        if (!scope.allergiesForm.description) {
                            scope.validator.add('description');
                        }
                        if (!scope.validator.hasErrors()) {
                            callback();
                        } else if (showCommonError) {
                            MessageService.add('error', 'Formoje yra klaidų', 'allergies-region');
                        }
                    }

                    scope.__saveAllergy = function () {
                        __validate(function () {
                            var data = _.clone(scope.allergiesForm);
                            if(data.fullId){
                                var found = _.find(scope._modified.allergies, function(a){
                                    return a.fullId == data.fullId;
                                });
                                if(!found){
                                    scope._modified.allergies.push(data);
                                }
                            }
                            angular.copy(scope.allergiesForm, scope.form.partAllergies.allergies[data.index]);
                            initAllergiesForm();
                        });
                    }

                    scope.__addAllergy = function () {
                        __validate(function () {
                            if (scope.nzav.submitted) {
                                scope.form.partAllergies.noAllergies = false;
                            }
                            var data = _.clone(scope.allergiesForm);
                            scope.form.partAllergies.allergies.push(data);
                            initAllergiesForm();
                        });
                    }

                    scope.___removeAllergy = function (index) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            var removed = scope.form.partAllergies.allergies.splice(index, 1);
                            if (removed[0].fullId) {
                                scope._removed.allergies.push(removed[0]);
                            }
                        });
                    }

                    scope.clearInfoMessage = function () {
                        MessageService.clear('allergies-region');
                    }

                    scope.setNzavData = function () {
                        scope.form.partAllergies.practitionerId = SessionContext.practitioner.id;
                        SessionApiFactory.getSysdate({}, function (result) {
                            scope.form.partAllergies.date = new Date(result.value);
                        });
                        scope.clearInfoMessage();
                    }

                    scope.$watch('[form.partAllergies.allergies, form.partAllergies.noAllergies, nzav,  hasActiveAllergies]', function () {
                        if (scope.form.partAllergies && scope.form.partAllergies.allergies.length == 0 && scope.form.partAllergies.noAllergies == false && scope.nzav && scope.nzav.submitted == false && scope.hasActiveAllergies == false) {
                            MessageService.add('info', $filter('translate')('err.ale.14'), 'allergies-region');
                        }
                    }, true);
                }
            };
        }]);

var _research_applyOrderDocuments = function (scope, DocumentsApiFactory) {
    scope.orderDocuments = {};
    // load consultation plan related documents
    scope.form.$promise.then(function () {
        var orderIds = [];
        _.each(scope.form.partConsultationPlan.items, function (item) {
            orderIds.push(item.id);
        });
        if (orderIds.length > 0) {
            DocumentsApiFactory.getOrderDocumentsByOrder({orderId: orderIds}, function (res) {
                _.each(res, function (item) {
                    scope.orderDocuments[item.orderRefs[0].split('/')[1]] = item;
                });
            });
        }
    });
};

var _vaccination_applyOrderDocuments = function (scope, DocumentsApiFactory) {
    scope.vaccinationsDocuments = {};
    // load consultation plan related documents
    scope.form.$promise.then(function () {
        var orderIds = [];
        _.each(scope.form.immunizationOrders, function (item) {
            orderIds.push(item.id);
        });
        if (orderIds.length > 0) {
            DocumentsApiFactory.getOrderDocumentsByOrder({orderId: orderIds}, function (res) {
                _.each(res, function (item) {
                    _.each(item.orderRefs, function (ref) {
                        scope.vaccinationsDocuments[ref.split('/')[1]] = item;
                    });
                });
            });
        }
    });
};

ng1App.directive('viewResearch',
    ['DocumentsApiFactory', '$filter',
     function (DocumentsApiFactory, $filter) {
    return {
        templateUrl: '/templates/documents/components/view/view_research.html',
        restrict: 'E',
        replace: false,
        scope: true,
        transclude: true,
        link: function (scope: any, element, attrs) {
            //add block to document content
            if (scope._shared.contentList) {
                scope._shared.contentList[attrs.contentIndex] = {
                    title: $filter('translate')('doc.res'),
                    id: 'doc_res',
                    show: function () {
                        return scope.form.partConsultationPlan.text || scope.form.partConsultationPlan.items;
                    }
                };
            }

            scope.formType = attrs.formType;
            _research_applyOrderDocuments(scope, DocumentsApiFactory);
            scope.form.$promise.then(function (f) {
                f.partConsultationPlan.items.sort(function (a, b) {
                    return b.date - a.date
                });
            });
        }
    };
}])
    //     .directive('editResearch',
    // ['TypeaheadSearchHandlers', 'MessageService', 'SessionApiFactory', '$filter', 'DocumentsApiFactory', '$modalConfirm', 'SubFormValidator',
    //  function (TypeaheadSearchHandlers, MessageService, SessionApiFactory, $filter, DocumentsApiFactory, $modalConfirm, SubFormValidator) {
    //         return {
    //             templateUrl: '/templates/documents/components/edit/edit_research.html',
    //             restrict: 'E',
    //             replace: false,
    //             scope: true,
    //             transclude: true,
    //             link: function (scope: any, element, attrs) {

    //                 //add block to document content
    //                 if (scope._shared.contentList) {
    //                     scope._shared.contentList[attrs.contentIndex] = {
    //                         title: $filter('translate')('doc.res'),
    //                         id:'doc_res'
    //                     };
    //                 }

    //                 scope.closed = attrs.closed ? attrs.closed : 'false';
    //                 scope.formType = attrs.formType;

    //                 if (!scope._removed.orders) {
    //                     scope._removed.orders = [];
    //                 }

    //                 scope.form.$promise.then(function (f) {
    //                     f.partConsultationPlan.items.sort(function (a, b) {
    //                         return b.date - a.date
    //                     });
    //                 });

    //                 _research_applyOrderDocuments(scope, DocumentsApiFactory);
    //                 scope.consultationPlanForm = {};
    //                 var intiConsultationPlanForm = function (type) {
    //                         scope.consultationPlanForm.type= type;
    //                         scope.consultationPlanForm.__selectedDiagnosis= {};
    //                         scope.consultationPlanForm.patData= {diagnosis: []};
    //                         scope.consultationPlanForm.obsData= {};
    //                         scope.consultationPlanForm.labData= {};
    //                 };

    //                 scope.types.orderTypes.$promise.then(function(data){
    //                     intiConsultationPlanForm(data[0]);
    //                 });

    //                 scope.initDiagnosis = function () {
    //                     scope.consultationPlanForm.__selectedDiagnosis = {};
    //                 }


    //                 scope.validator = new SubFormValidator('cp-diagnosis-region');

    //                 // --- Filter diagnosis for selection BEGIN ---
    //                 var __options = [];
    //                 var __watchFn = function () {
    //                     if (!scope.form.partDiagnosis) {
    //                         __options.splice(0, __options.length);
    //                     } else {
    //                         __options = _.map(scope.form.partDiagnosis, function (d) {
    //                             return d.diagnosis;
    //                         });
    //                     }
    //                 }
    //                 scope.$watch("form.partDiagnosis", __watchFn, true);
    //                 scope.$watch("form.partConsultationPlan.items", __watchFn, true);
    //                 scope.$watch("__modeModification", __watchFn, true);
    //                 // --- Filter diagnosis for selection END ---

    //                 scope.___diagnosisListFiltered = function () {
    //                     return __options;
    //                 }

    //                 scope.___diagnosisSelected = function () {
    //                     if (scope.consultationPlanForm.type.code == '1') {
    //                         scope.consultationPlanForm.obsData.diagnosis = __options.filter(function (d) {
    //                             return scope.consultationPlanForm.__selectedDiagnosis[d.code];
    //                         })[0];
    //                     } else if (scope.consultationPlanForm.type.code == '3') {
    //                         scope.consultationPlanForm.patData.diagnosis = __options.filter(function (d) {
    //                             return scope.consultationPlanForm.__selectedDiagnosis[d.code];
    //                         });
    //                     }
    //                 }

    //                 scope.searchProfessionTexts = TypeaheadSearchHandlers.PROFESSIONS_MEDICAL;
    //                 scope.searchProfessionTextsChange = function (val) {
    //                     if (val) {
    //                         if (val.code) {
    //                             scope.consultationPlanForm.departmentSpecialization = undefined;
    //                             scope.consultationPlanForm.targetQualification = val;
    //                         } else if (_.isString(val)) {
    //                             scope.consultationPlanForm.targetQualification = undefined;
    //                             scope.consultationPlanForm.departmentSpecialization = val;
    //                         }
    //                     }
    //                 };

    //                 // --- Filter diagnosis for selection END ---

    //                 // lab START
    //                 scope.searchMaterialTypesChange = function (v) {
    //                     if (v && v.id) {
    //                         scope.consultationPlanForm.labData.specimen = v;
    //                     }
    //                 }
    //                 scope.searchResearchTypesChange = function (v) {
    //                     if (v && v.id) {
    //                         scope.consultationPlanForm.labData.research = v;
    //                     }
    //                 }

    //                 scope.searchMaterialTypes = TypeaheadSearchHandlers.MATERIALL_TYPE;
    //                 scope.searchResearchTypes = TypeaheadSearchHandlers.RESEARCH_TYPE;
    //                 // lab END

    //                 var __validate = function (callback) {
    //                     scope.validator.clear();
    //                     var form = scope.consultationPlanForm;
    //                     switch (form.type.code) {
    //                         case '1':
    //                             console.debug(form)
    //                             if (!form.obsData.diagnosis || !form.obsData.diagnosis.code) {
    //                                 scope.validator.add('diagnosis');
    //                             }
    //                             if (!form.departmentSpecialization && !form.targetQualification) {
    //                                 scope.validator.add('referralSpec');
    //                             }
    //                             break;
    //                         case '2':
    //                             if (!form.labData.specimen || !form.labData.specimen.code) {
    //                                 scope.validator.add('labSpecimen');
    //                             } else if (form.labData.specimen.code === '27' && !form.labData.specimenNotes) {
    //                                 scope.validator.add('labSpecimenNote');
    //                             }
    //                             if (!form.labData.research || !form.labData.research.code) {
    //                                 scope.validator.add('labResearch');
    //                             }
    //                             break;
    //                         case '3':
    //                             if (!form.patData.diagnosis || form.patData.diagnosis.length == 0) {
    //                                 scope.validator.add('diagnosis');
    //                             }
    //                             if (!form.patData.sampleCollectionMethod || !form.patData.sampleCollectionMethod.code) {
    //                                 scope.validator.add('sampleCollectionMethod');
    //                             }
    //                             break;
    //                     }
    //                     if (!scope.validator.hasErrors()) {
    //                         callback();
    //                     } else {
    //                         MessageService.add('error', 'Formoje yra klaidų', 'cp-diagnosis-region');
    //                     }
    //                 }

    //                 scope.___addConsultationPlan = function () {
    //                     __validate(function () {
    //                         var cpf = scope.consultationPlanForm;
    //                         var m = _.clone(cpf);
    //                         withSysDate(SessionApiFactory, m, function () {
    //                             scope.form.partConsultationPlan.items.unshift(m);
    //                             intiConsultationPlanForm(cpf.type);
    //                         });
    //                     });
    //                 }

    //                 // ---- modification removed by RCESPBI-1591 ----
    //                 //scope.___saveConsultationPlan = function(){
    //                 //    __validate(function(){
    //                 //        var orig = scope.form.partConsultationPlan.items[scope.consultationPlanForm.__index];
    //                 //        angular.copy(scope.consultationPlanForm, orig);
    //                 //        intiConsultationPlanForm(scope.consultationPlanForm.type);
    //                 //        scope.__modeModification = false;
    //                 //    });
    //                 //}

    //                 // ---- modification removed by RCESPBI-1591 ----
    //                 //scope.___modifyConsultationPlan = function(index){
    //                 //    scope.validator.clear();
    //                 //    scope.__modeModification = true;
    //                 //    scope.consultationPlanForm = _.clone(scope.form.partConsultationPlan.items[index]);
    //                 //    var cpForm = scope.consultationPlanForm;
    //                 //    cpForm.tmpTargetSpecialization = cpForm.targetQualification ? cpForm.targetQualification : cpForm.departmentSpecialization;
    //                 //    cpForm.__index = index;
    //                 //    // fill type
    //                 //    cpForm.type = _.find(scope.types.orderTypes, function(t){return t.code == cpForm.type.code});
    //                 //    // fill __selectedDiagnosis
    //                 //    cpForm.__selectedDiagnosis = {};
    //                 //    if(cpForm.type.code == '1'){
    //                 //        cpForm.__selectedDiagnosis[cpForm.obsData.diagnosis.code] = true;
    //                 //    } else if(cpForm.type.code == '3'){
    //                 //        _.each(cpForm.patData.diagnosis, function(d){cpForm.__selectedDiagnosis[d.code] = true;});
    //                 //    }
    //                 //    // fill lab typehaead values
    //                 //    if(cpForm.labData.research){
    //                 //        cpForm.labResearchDisplay = cpForm.labData.research.name;
    //                 //    }
    //                 //    if(cpForm.labData.specimen) {
    //                 //        cpForm.specimenDisplay = cpForm.labData.specimen.name;
    //                 //    }
    //                 //    // fill sampleCollectionMethod
    //                 //    if(cpForm.type.code == '3' && cpForm.patData.sampleCollectionMethod){
    //                 //        cpForm.patData.sampleCollectionMethod = _.find(scope.types.sampleCollMethods, function(t){return t.code == cpForm.sampleCollectionMethod.code});
    //                 //    }
    //                 //}

    //                 scope.___cancelModConsultationPlan = function () {
    //                     console.debug(scope.consultationPlanForm);
    //                     scope.validator.clear();
    //                     scope.__modeModification = false;
    //                     intiConsultationPlanForm(scope.consultationPlanForm.type);
    //                 }


    //                 scope.___removeConsultationPlan = function (index) {
    //                     $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
    //                         MessageService.clear('cp-diagnosis-region');

    //                         var removed = scope.form.partConsultationPlan.items.splice(index, 1);
    //                         if (removed[0].fullId) {
    //                             scope._removed.orders.push(removed[0]);
    //                         }
    //                     });
    //                 };
    //             }
    //         };
    //     }])
        .directive('editResearch',
    ['MessageService', 'SubFormValidator', '$filter',
     function (MessageService, SubFormValidator, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {

                     // Initialize title and set default values
                if (scope._shared && scope._shared.contentList) {
                    scope._shared.contentList[attrs.contentIndex] = {
                        title: $filter('translate')('doc.res'),
                        id: 'doc_res'
                    };
                }
                scope.closed = attrs.closed || 'false';

                // Initialize validator for conDescription
                scope.validator = new SubFormValidator('cp-diagnosis-region');

                // Watch changes on conDescription field
                scope.$watch('form.partConsultationPlan.text', function (newValue) {
                    if (!newValue || newValue.trim() === '') {
                        scope.validator.add('conDescription');
                    } else {
                        scope.validator.clear();
                    }
                });

                // Function to handle changes or submissions for conDescription
                scope.handleConDescriptionChange = function () {
                    if (scope.validator.hasErrors()) {
                        // Show error messages or handle errors
                        MessageService.add('error', 'Error in conDescription', 'cp-diagnosis-region');
                    } else {
                        // Handle the valid conDescription data
                        // Implement saving or further processing as needed
                    }
                };
            }
        };
    }])
        .directive('editResearchData',
    ['DocumentsApiFactory', 'DocumentCtx', '$filter',
     function (DocumentsApiFactory, DocumentCtx, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_research_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {

                    scope.filterPaths = [
                        'title', 'date|date',
                        'practitioner.givenName', 'practitioner.familyName',
                        'practitioner.stampCode', 'practitionerQualification.name',
                        'practitioner.speciality.name', 'organization.name'
                    ];

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.lab.ins'),
                            id: 'doc_lab_ins'};
                    }

                    scope.formType = attrs.formType;
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = !scope.form.partPerformedDiagnosticLabTests.description && _.keys(scope.form.partPerformedDiagnosticLabTests.documentsSelected).length == 0;
                        });
                    }

                    scope.loadedItems = [];
                    // TODO: excludeIds should be filled with form data....
                    var excludeIds = [];
                    var currentPage = 1;
                    var itemsPerPage = 3;
                    scope.totalItems = -1;
                    scope.loadMore = function () {
                        if (scope.formType == 'e003') {
                            var encounterId = scope.form.encounterId;
                            DocumentsApiFactory.getOrderResponseDocumentsForE003({compositionId: DocumentCtx.documentId, patient: DocumentCtx.patientId, encounterId: encounterId, page: currentPage++, count: itemsPerPage, excludeId: excludeIds}, function (res) {
                                scope.totalItems = res.total;
                                scope.loadedItems = scope.loadedItems.concat(res.items);
                            });
                        } else {
                            DocumentsApiFactory.getOrderResponseDocumentsForE025({patient: DocumentCtx.patientId, page: currentPage++, count: itemsPerPage, excludeId: excludeIds}, function (res) {
                                scope.totalItems = res.total;
                                scope.loadedItems = scope.loadedItems.concat(res.items);
                            });
                        }
                    }
                    scope.isNotLoadedItemsLeft = function () {
                        return scope.totalItems == -1 || scope.totalItems > scope.loadedItems.length;
                    }
                    if (excludeIds.length == 0) {
                        scope.form.$promise.then(function () {
                            scope.loadMore();
                        })
                    }
                }
            };
        }])
        .directive('viewResearchData',
    ['DocumentsApiFactory', '$filter',
     function (DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_research_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.isResearchDataEmpty = function () {
                        if(scope.form.partPerformedDiagnosticLabTests){
                            return _.keys(scope.form.partPerformedDiagnosticLabTests.documentsSelected).length == 0 && !scope.form.partPerformedDiagnosticLabTests.description;
                        }
                        return true;
                    }

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.lab.ins'),
                            id: 'doc_lab_ins',
                            show: function () {
                                return !scope.isResearchDataEmpty();
                            }
                        };
                    }

                    scope.$watch('form.partPerformedDiagnosticLabTests', function () {
                        if (scope.form.partPerformedDiagnosticLabTests.documentsSelected && _.keys(scope.form.partPerformedDiagnosticLabTests.documentsSelected).length > 0) {
                            var query: any = {
                                docReferences: []
                            };
                            _.each(scope.form.partPerformedDiagnosticLabTests.documentsSelected, function (val, key) {
                                if (val) {
                                    var start = key.indexOf('/'),
                                            end = key.indexOf('/', start + 1);
                                    query.docReferences.push(key.substring(start + 1, end));
                                }
                            });
                            query.count = query.docReferences.length;
                            scope.loadedItems = DocumentsApiFactory.searchForList(query);
                        }
                    });

                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editTreatmentDrugs',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_treatment_drugs.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                             title: $filter('translate')('doc.app.dru'),
                             id: 'doc_app_dru'
                         };
                    }
                    scope.formType = attrs.formType;

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = !scope.form.partTreatment.drugTreatment;
                        });
                    }
                }
            };
        }])
        .directive('viewTreatmentDrugs',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_treatment_drugs.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                             title: $filter('translate')('doc.app.dru'),
                             id: 'doc_app_dru',
                             show: function () {
                                 if(scope.form.partTreatment){
                                    return scope.form.partTreatment.drugTreatment;
                                 }else{
                                     return false;
                                 }
                             }
                         };
                    }
                }
            };
        }])
        .directive('editUndergoneSurgicalProcedures',
    ['MessageService', 'TypeaheadSearchHandlers', 'SessionApiFactory', 'SubFormValidator', '$modalConfirm', '$filter',
     function (MessageService, TypeaheadSearchHandlers, SessionApiFactory, SubFormValidator, $modalConfirm, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_undergone_surgical_procedures.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    if(scope.form.partTreatment === undefined){
                        scope.form.partTreatment = {surgeryProcedures: []};
                    }
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.und.sur'),
                            id: 'doc_und_sur'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    if (scope.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = scope.form.partTreatment.surgeryProcedures.length == 0;
                        });
                    }

                    var initForm = function () {
                        scope.surgeryProcedureForm = {};
                        withSysDate(SessionApiFactory, scope.surgeryProcedureForm, function () {
                        });
                    };
                    initForm();

                    scope.validator = new SubFormValidator('surgery-procedures');

                    /**
                     * on change handler needed for angular-strap typeahead bug on FF.
                     * @param v
                     */
                    scope.searchSurgeryProcedureChange = function (v) {
                        if (v && v.id) {
                            scope.surgeryProcedureForm.procedure = v;
                        }
                    }

                    scope.searchSurgeryProcedure = TypeaheadSearchHandlers.ACHI_PROCEDURAL;

                    var validate = function (form, callback) {
                        _.markAllAsTouched(form);
                        scope.validator.clear();
                        if (!scope.surgeryProcedureForm.procedure || !scope.surgeryProcedureForm.procedure.code) {
                            scope.validator.add('procedure');
                        }
                        var now = new Date().getTime();
                        if (!scope.surgeryProcedureForm.date || scope.surgeryProcedureForm.date > now) {
                            scope.validator.add('date');
                        }
                        if (!scope.validator.hasErrors()) {
                            callback();
                            _.markAllAsUntouched(form);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    }


                    scope.__addSurgeryProcedure = function (form) {
                        validate(form, function () {
                            var data = _.clone(scope.surgeryProcedureForm);
                            scope.form.partTreatment.surgeryProcedures.push(data);
                            initForm();
                        });
                    }

                    scope.___removeSurgeryProcedure = function (index) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            MessageService.clear('surgery-procedures');
                            scope.form.partTreatment.surgeryProcedures.splice(index, 1);
                        });
                    }

                    scope.__modifySurgeryProcedure = function (a) {
                        scope.surgeryProcedureForm = _.clone(a);
                        scope.surgeryProcedureForm.orig = a;
                        scope.surgeryProcedureForm._modeModification = true;
                        scope.surgeryProcedureForm.tempProcedure = a.procedure.code + ' ' + a.procedure.name;
                    }

                    scope.__saveSurgeryProcedure = function (form) {
                        validate(form, function () {
                            var orig = scope.surgeryProcedureForm.orig;
                            scope.surgeryProcedureForm.orig = null;
                            angular.copy(scope.surgeryProcedureForm, orig);
                            initForm();
                        });
                    }

                    scope.__cancelSurgeryProcedureModification = function () {
                        initForm();
                    }
                }
            };
        }])
        .directive('viewUndergoneSurgicalProcedures',
    ['MessageService', 'TypeaheadSearchHandlers', '$filter',
     function (MessageService, TypeaheadSearchHandlers, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_undergone_surgical_procedures.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.und.sur'),
                            id: 'doc_und_sur',
                            show: function () {
                                if(scope.form.partTreatment){
                                    return scope.form.partTreatment.surgeryProcedures;
                                }else{
                                    return false;
                                }
                            }
                        };
                    }
                }
            };
        }])
        .directive('editTreatmentDescription',
    ['$filter', 'DocumentsApiFactory',
     function ($filter, DocumentsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_treatment_description.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.adj.tre'),
                            id: 'doc_adj_tre'
                        };
                    }

                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = !scope.form.partTreatment.drugTreatment &&
                                           !scope.form.continuedTreatmentParentDocId &&
                                           !scope.form.recommendationsForTreatmentNotes;
                        });
                    }

                    scope.form.$promise.then(function (f) {
                        var id = f.continuedTreatmentParentDocId;
                        if (id) {
                            DocumentsApiFactory.searchForList({id: id}, function (res) {
                                scope.continuedTreatmentParentDoc = res.length > 0 ? res[0] : null;
                            });
                        }
                    });
                }
            };
        }])
        .directive('viewTreatmentDescription',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_treatment_description.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.adj.tre'),
                            id: 'doc_adj_tre',
                            show: function () {
                                if (scope.form.partTreatment){
                                    return scope.form.partTreatment.description;
                                }else{
                                    return false;
                                }
                            }
                        };
                    }
                }
            };
        }])
        .directive('editRecommendations',
    ['DocumentsApiFactory', '$filter',
     function (DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_recommendations.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.rec'),
                            id: 'doc_rec'
                        };
                    }
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = !scope.form.continuedTreatmentParentDocId && !scope.form.recommendationsForTreatmentNotes;
                        })
                    }

                    scope.form.$promise.then(function (f) {
                        var id = f.continuedTreatmentParentDocId;
                        if (id) {
                            DocumentsApiFactory.searchForList({id: id}, function (res) {
                                scope.continuedTreatmentParentDoc = res.length > 0 ? res[0] : null;
                            });
                        }
                    });
                }
            };
        }])
        .directive('viewRecommendations',
    ['DocumentsApiFactory', '$filter',
     function (DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_recommendations.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.rec'),
                            id: 'doc_rec',
                            show: function () {
                                return scope.form.recommendationsForTreatmentNotes || (scope.f && scope.f.continuedTreatmentParentDocId);
                            }
                        };
                    }
                    scope.formType = attrs.formType;
                    scope.form.$promise.then(function (f) {
                        var id = f.continuedTreatmentParentDocId;
                        if (id) {
                            DocumentsApiFactory.searchForList({id: id}, function (res) {
                                scope.continuedTreatmentParentDoc = res.length > 0 ? res[0] : null;
                            });
                        }
                    });
                }
            };
        }])
        .directive('editVaccines',
    ['SessionApiFactory', 'SubFormValidator', 'EntitiesApiFactory', 'TypeaheadSearchHandlers', 'DocumentsApiFactory', 'PatientsApiFactory', '$modalConfirm', '$routeParams', '$filter',
     function (SessionApiFactory, SubFormValidator, EntitiesApiFactory, TypeaheadSearchHandlers, DocumentsApiFactory, PatientsApiFactory, $modalConfirm, $routeParams, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_vaccines.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.ord.vac'),
                            id: 'doc_ord_vac'
                        };
                    }

                    scope.preFillFields = function (rec, item) {
                        scope.order._medicationName = rec.medicationName;
                        scope.order.vacData.recommendation.disease = scope.vaccineTypes.find(vt => vt.id === item.disease.id);
                        scope.vaccineTypeQuery = item.disease.name;
                    };

                    scope.formType = attrs.formType;

                    _vaccination_applyOrderDocuments(scope, DocumentsApiFactory);

                    if (!scope._removed.orders) {
                        scope._removed.orders = [];
                    }

                    scope.enterManualy = false;
                    scope.plannedVaccinations = PatientsApiFactory.getPlannedVaccinations({id: $routeParams.patientId});
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = scope.form.immunizationOrders == null || scope.form.immunizationOrders.length === 0;
                        });
                    }

                    var editMode = false,
                            editIndex = -1;

                    var newVaccine = function () {
                        scope.vaccineTypeQuery = '';
                        var vac: any = {
                            type: {code: 4},
                            vacData: {
                                recommendation: {
                                    recommendations: [{}]
                                }
                            }
                        };

                        SessionApiFactory.getSysdate({}, function (data) {
                            vac.date = data.value;
                            vac.vacData.recommendation.recommendations[0].date = data.value;
                            vac.vacData.recommendation.recommendations[0].plannedDate = _.truncateToDays(data.value);
                        });
                        scope.planSearchInput = undefined;
                        return vac;
                    };

                    var validate = function (order) {
                        scope.validator.clear();
                        if (!order.vacData.recommendation.disease || !order.vacData.recommendation.disease.code) {
                            scope.validator.add('diseace');
                        }
                        if (!order.vacData.recommendation.recommendations[0].medicationName) {
                            scope.validator.add('vaccine');
                        }
                        var doseNumber = order.vacData.recommendation.recommendations[0].vaccineDoseNumber;
                        if (!doseNumber || doseNumber % 1 !== 0 || doseNumber < 1 || doseNumber > 12) {
                            scope.validator.add('doseNumber');
                        }
                        return !scope.validator.hasErrors();
                    };

                    scope.validator = new SubFormValidator('edit-vaccines-region');
                    scope.order = newVaccine();

                    scope.vaccineTypes = EntitiesApiFactory.getList({classCode: 'vaccine-type'});

                    scope.searchMedication = TypeaheadSearchHandlers.MEDICATION_NAMES;

                    scope.medicationNameChange = function (name) {
                        if (!scope.order.vacData) {
                            scope.order.vacData = {};
                        }

                        if (!scope.order.vacData.recommendation) {
                            scope.order.vacData.recommendation = {
                                recommendations: [{}]
                            };
                        }

                        const recommendations = scope.order.vacData.recommendation.recommendations;
                        if (!recommendations || recommendations.length === 0) {
                            scope.order.vacData.recommendation.recommendations = [{}];
                        }

                        scope.order.vacData.recommendation.recommendations[0].medicationName = name ? name.label : undefined;
                    };

                    scope.clearMedicationName = function () {

                        if (!scope.order.vacData) {
                            scope.order.vacData = {};
                        }

                        if (!scope.order.vacData.recommendation) {
                            scope.order.vacData.recommendation = {
                                recommendations: [{}]
                            };
                        } else if (!scope.order.vacData.recommendation.recommendations) {
                            scope.order.vacData.recommendation.recommendations = [{}];
                        } else if (scope.order.vacData.recommendation.recommendations.length === 0) {
                            scope.order.vacData.recommendation.recommendations.push({});
                        }

                        // Clear medication name
                        scope.order.vacData.recommendation.recommendations[0].medicationName = undefined;
                    };

                    scope.$watch('vaccineTypeQuery', function (newValue, oldValue) {
                        if (newValue !== oldValue && (!newValue || newValue.length < oldValue.length)) {
                            scope.order.vacData.recommendation = {};
                            scope.order._medicationName  = null;
                            scope.order.vacData.recommendation.disease = null;
                            scope.vaccineTypeQuery = "";
                        }
                    });

                    scope.isEditMode = function () {
                        return editMode;
                    };

                    // scope.editVaccine = function (index) {
                    //     scope.order = angular.copy(scope.form.immunizationOrders[index]);
                    //     editIndex = index;
                    //     editMode = true;
                    // };

                    scope.addVaccine = function () {
                        if (validate(scope.order)) {
                            if(!scope.form.immunizationOrders){
                                scope.form.immunizationOrders = scope.form.immunizationOrders || [];
                            }
                            scope.form.immunizationOrders.unshift(scope.order);
                            scope.order = newVaccine();
                            console.log("scope.order",scope.order);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    };
                    scope.isAlreadyAdded = function(item){
                        var id = item.id;
                        var res = false;
                        if(scope.form.immunizationOrders){
                            angular.forEach(scope.form.immunizationOrders, function (value) {
                                if(id == value.vacData.recommendation.id){
                                    res =  true;
                                }
                            });
                        }
                        return res;
                    }
                    // ---- modification removed by RCESPBI-1591 ----
                    //scope.saveVaccine = function () {
                    //    if (validate(scope.order)) {
                    //        scope.form.immunizationOrders[editIndex] = scope.order;
                    //        scope.order = newVaccine();
                    //        editMode = false;
                    //    } else {
                    //        scope.validator.showErrorsAndScroll();
                    //    }
                    //};

                    // scope.saveVaccine = function () {
                    //     if (validate(scope.order)) {
                    //         scope.form.immunizationOrders[editIndex] = angular.copy(scope.order);
                    //         scope.order = newVaccine();
                    //         editMode = false;
                    //     } else {
                    //         scope.validator.showErrorsAndScroll();
                    //     }
                    // };

                    scope.deleteVaccine = function (index) {
                        $modalConfirm.show({
                            content: 'Ar tikrai norite pašalinti?'
                        }).then(function () {
                            var removed = scope.form.immunizationOrders.splice(index, 1);
                            if (removed[0].fullId) {
                                scope._removed.orders.push(removed[0]);
                            }
                        });
                    };
                    // ---- modification removed by RCESPBI-1591 ----
                    //scope.editVaccine = function (index) {
                    //    scope.validator.clear();
                    //    scope.order = angular.copy(scope.form.immunizationOrders[index]);
                    //    scope.order._medicationName = {label : scope.order.vacData.recommendationList[0].medicationName};
                    //    var actualType = _.find(scope.vaccineTypes, function (t) {
                    //            return t.code === scope.order.vacData.disease.code;
                    //        });
                    //    if (actualType) {
                    //        scope.order.vacData.disease = actualType;
                    //    }
                    //    editIndex = index;
                    //    editMode = true;
                    //};
                    scope.cancelEdit = function () {
                        scope.order = newVaccine();
                        editMode = false;
                    };
                }
            };
        }])
        .directive('viewVaccines',
    ['DocumentsApiFactory', '$filter',
     function (DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_vaccines.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.newTab = attrs.newTab;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.ord.vac'),
                            id: 'doc_ord_vac',
                            show: function () {
                                return scope.form.immunizationOrders && scope.form.immunizationOrders.length > 0 ;
                            }
                        };
                    }
                    _vaccination_applyOrderDocuments(scope, DocumentsApiFactory);
                }
            };
        }])
        .directive('viewStatements',
    ['$routeParams', '$filter', 'portalType',
     function ($routeParams, $filter, portalType) {
            return {
                templateUrl: '/templates/documents/components/view/view_statements.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.editMode = attrs.editMode == 'true';
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.rel.cer'),
                            id: 'doc_rel_cer',
                            show: function(){
                                return scope.form.partMedicalCertificate
                            }
                        };
                    }

                    scope.getPractitionerQualification = function (partMedicalCertificate) {
                        var certificate = partMedicalCertificate.certificate;
                        if (partMedicalCertificate.certificate.practitionerQualification) {
                            return certificate.practitionerQualification.name;
                        }
                        if (partMedicalCertificate.certificate.practitionerPosition) {
                            return certificate.practitionerPosition.name;
                        }
                    };

                    scope.certificateUrl = function () {
                        var url = '/';
                        switch (portalType) {
                            case 'doctor':
                                url = '/dp/patients/' + $routeParams.patientId + '/certificates/' + scope.form.partMedicalCertificate.certificate.compositionId;
                                break;

                            case 'patient':
                                url = '/pp/certificates/' + scope.form.partMedicalCertificate.certificate.compositionId;
                                break;
                        }
                        return url;
                    };

                }
            };
        }])
        .directive('editPoliceMessages',
    ['$routeParams', '$window', 'PatientsApiFactory', 'EntitiesApiFactory', 'DpPatientContext', '$filter', 'MessageService',
     function ($routeParams, $window, PatientsApiFactory, EntitiesApiFactory, DpPatientContext, $filter, MessageService) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_police_messages.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.pol.mes'),
                            id: 'doc_pol_mes',
                            show: function(){
                                return scope.hasAnyLicense();
                            }
                        };
                    }

                    scope.form.$promise.then(function(f){
                        var pc = f.metadata.patient.personalCode;
                        var id = f.metadata.patient.id;
                        PatientsApiFactory.getHasDrivingLicense({personalCode: pc}, function (data) {
                            if (data.connectionError) {
                                // quick hack
                                scope.hasValidDrivingLicense = true;
                                scope.regitraConnectionError = true;
                            } else {
                                scope.hasValidDrivingLicense = data.hasValidLicense;
                            }
                        }, function(resp){
                            console.debug(resp);
                        });


                        PatientsApiFactory.getHasGunLicense({patientId: id}, function (data) {
                            scope.hasGunPermission = data.hasValidLicense;
                        });
                    });



                    scope.currentAlertMessage = {}
                    if (scope.form.partAlertMessages === undefined) {
                        scope.form.partAlertMessages = {messages: []}
                    }
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.isFormEditMode = false;
                    scope.drivingLicenseType = 1;
                    scope.gunType = 2;

                    scope.isOneChoiceAvailable = function () {
                        return scope.hasOnlyChoiceBasedOnLicense() || scope.hasOnlyChoiceBasedOnList();
                    }

                    scope.isNoChoiceAvailableBasedOnList = function () {
                        return scope.hasBothLicenses() && (scope.types.prohibitAlertTypes.length - scope.form.partAlertMessages?.messages.length == 0) ||
                            scope.hasAnyLicense() && (scope.types.prohibitAlertTypes.length - scope.form.partAlertMessages?.messages.length == 1);
                    }

                    scope.hasOnlyChoiceBasedOnLicense = function () {
                        return scope.hasOnlyLicense() && !scope.isAlreadyAdded(getOnlyLicenseType());
                    }

                    scope.hasOnlyChoiceBasedOnList = function () {
                        if (scope.types.prohibitAlertTypes.length - scope.form.partAlertMessages?.messages.length == 1) {
                            return scope.hasValidDrivingLicense && !scope.isAlreadyAdded(scope.drivingLicenseType) ||
                                    scope.hasGunPermission && !scope.isAlreadyAdded(scope.gunType);
                        }
                        ;
                        return false;
                    }

                    scope.getLastChoiceTypeBasedOnList = function () {
                        if (!scope.isAlreadyAdded(scope.drivingLicenseType)) {
                            return scope.drivingLicenseType;
                        }
                        if (!scope.isAlreadyAdded(scope.gunType)) {
                            return scope.gunType;
                        }
                    }

                    scope.hasAnyLicense = function () {
                        return scope.hasValidDrivingLicense || scope.hasGunPermission;
                    }

                    scope.hasOnlyLicense = function () {
                        return scope.hasOnlyDrivingLicense() || scope.hasOnlyGunLicense();
                    }

                    scope.hasOnlyDrivingLicense = function () {
                        return scope.hasValidDrivingLicense && !scope.hasGunPermission;
                    }

                    scope.hasOnlyGunLicense = function () {
                        return !scope.hasValidDrivingLicense && scope.hasGunPermission;
                    }

                    scope.hasBothLicenses = function () {
                        return scope.hasValidDrivingLicense && scope.hasGunPermission;
                    }

                    scope.isAddButtonEnabled = function () {
                        return !scope.currentAlertMessage.type || !scope.isAlreadyAdded(scope.currentAlertMessage.type) || scope.isOneChoiceAvailable();
                    }

                    scope.isDrivingLicenseOnlyChoice = function () {
                        return scope.hasOnlyDrivingLicense() || scope.hasValidDrivingLicense && !scope.isAlreadyAdded(scope.drivingLicenseType);
                    }

                    scope.isGunLicenseOnlyChoice = function () {
                        return scope.hasOnlyGunLicense() || scope.hasGunPermission && !scope.isAlreadyAdded(scope.gunType);
                    }

                    function getOnlyLicenseType() {
                        if (scope.hasOnlyDrivingLicense()) {
                            return scope.drivingLicenseType;
                        }
                        else if (scope.hasOnlyGunLicense()) {
                            return scope.gunType;
                        }
                        return null;
                    }

                    scope.redirectRelatedDoc = function (compId) {
                        var url = '/dp/patients/' + $routeParams.patientId + '/documents/' + compId;
                        $window.open(url);
                    }

                    function clearForm() {
                        scope.currentAlertMessage.type = null;
                        scope.currentAlertMessage.additionalInfo = null;
                        scope.currentAlertMessage.editIndex = null;
                    }

                    function getMsgThemeByType(type) {
                        var theme;
                        angular.forEach(scope.types.prohibitAlertTypes, function (msg) {
                            if (type == msg.code) {
                                theme = msg.name;
                                return;
                            }
                        })
                        return theme;
                    }

                    scope.isAlreadyAdded = function (type) {
                        var isAdded = false;
                        angular.forEach(scope.form.partAlertMessages?.messages, function (msg) {
                            if (type == msg.selectedType) {
                                isAdded = true;
                                return;
                            }
                        })
                        return isAdded;
                    };

                    function updateMessage(message, isEdited) {
                        if (scope.hasOnlyChoiceBasedOnLicense() && !scope.isFormEditMode) {
                            var onlyChoiceType = getOnlyLicenseType();
                            message.selectedType = onlyChoiceType;
                            message.theme = getMsgThemeByType(onlyChoiceType);
                        }
                        else if (scope.hasOnlyChoiceBasedOnList() && !scope.isFormEditMode) {
                            var onlyChoiceType = scope.getLastChoiceTypeBasedOnList();
                            message.selectedType = onlyChoiceType;
                            message.theme = getMsgThemeByType(onlyChoiceType);
                        }
                        else {
                            message.selectedType = scope.currentAlertMessage.type;
                            message.theme = getMsgThemeByType(scope.currentAlertMessage.type);
                        }
                        message.additionalInfo = scope.currentAlertMessage.additionalInfo;
                        message.createDate = new Date();
                        message.isEdited = isEdited;
                    }

                    scope.addAlertMessage = function () {
                        var message: any = {
                            isNew: true
                        }
                        updateMessage(message, false);
                        if(message.selectedType == scope.drivingLicenseType && scope.regitraConnectionError){
                            MessageService.clear('licences-msg-region');
                            MessageService.add('error', $filter('translate')('regitra.connectionError'), 'licences-msg-region');
                            return;
                        }
                        scope.form.partAlertMessages?.messages.unshift(message);
                        clearForm();
                    };

                    scope.editAlertMessage = function (message, editIndex) {
                        scope.isFormEditMode = true;
                        scope.currentAlertMessage.type = message.selectedType;
                        scope.currentAlertMessage.additionalInfo = message.additionalInfo;
                        scope.currentAlertMessage.editIndex = editIndex;
                    };

                    scope.removeAlertMessage = function (index) {
                        scope.isFormEditMode = false;
                        var messageToRemove = scope.form.partAlertMessages?.messages[index];
                        if (messageToRemove && messageToRemove.isNew) {
                            scope.form.partAlertMessages?.messages.splice(index, 1);
                        }
                        else {
                            messageToRemove.isDeleted = true;
                            messageToRemove.isEdited = false;
                        }
                        scope.currentAlertMessage.editIndex = null;
                    };

                    scope.cancelAlertMessageEdit = function () {
                        scope.isFormEditMode = false;
                        clearForm();
                    };

                    scope.saveAlertMessageEdit = function () {
                        var editMessage = scope.form.partAlertMessages?.messages[scope.currentAlertMessage.editIndex];
                        updateMessage(editMessage, !editMessage.isNew);
                        scope.isFormEditMode = false;
                        clearForm();
                    };
                }
            };
        }])
        .directive('viewPoliceMessages',
    ['$routeParams', '$window', 'portalType', '$filter',
     function ($routeParams, $window, portalType, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_police_messages.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.pol.mes'),
                            id: 'doc_pol_mes',
                            show: function() {
                                if (scope.form.partAlertMessages) {
                                    return scope.form.partAlertMessages?.messages.length > 0;
                                } else {
                                   return false;
                               }
                            }
                        };
                    }

                    scope.formType = attrs.formType;

                    scope.redirectRelatedDoc = function (compId) {
                        var url = '/';
                        switch (portalType) {
                            case 'doctor':
                                url = '/dp/patients/' + $routeParams.patientId + '/documents/' + compId;
                                break;

                            case 'patient':
                                url = '/pp/documents/' + compId;
                                break;
                        }

                        $window.open(url);
                    };

                    scope.isForView = function (alertMessage, currentDocRefsInDocList) {
                        var isForView = alertMessage.relatedDocument != null && conatainsDocRef(alertMessage.relatedDocument, currentDocRefsInDocList)
                                || alertMessage.relatedDocument == null && alertMessage.isLastRelatedDocInDocList;

                        return isForView;
                    };

                    function conatainsDocRef(relatedDocument, currentDocRefsInDocList) {
                        var contains = false;
                        angular.forEach(currentDocRefsInDocList, function (currentRef) {
                            if (currentRef === relatedDocument.documentReferenceRef) {
                                contains = true;
                                return;
                            }
                        })
                        return contains;
                    }

                }
            };
        }])
        .directive('editNewborn',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_newborn.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.new.bor'),
                            id: 'doc_new_bor'
                        };
                    }
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = !scope.form.newbornExaminationNotes;
                        });
                    }
                }
            };
        }])
        .directive('viewNewborn',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_newborn.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.new.bor'),
                            id: 'doc_new_bor',
                            show: function () {
                              return scope.form.newbornExaminationNotes;
                            }
                        };
                    }
                }
            };
        }])
        .directive('editCertificates',
            ['$filter', '$routeParams', 'EptsCertificatesApiFactory', 'MessageService', 'EptsCertificateUtils',
                function ($filter, $routeParams, EptsCertificatesApiFactory, MessageService, EptsCertificateUtils) {
                    return {
                        templateUrl: '/templates/documents/components/edit/edit_certificates.html',
                        restrict: 'E',
                        replace: false,
                        scope: true,
                        transclude: true,
                        link: function (scope: any, element, attrs) {
                            //add block to document content
                            if (scope._shared.contentList) {
                                scope._shared.contentList[attrs.contentIndex] ={
                                    title: $filter('translate')('doc.cer.inc'),
                                    id: 'doc_cer_inc'
                                };
                            }
                            scope.formType = attrs.formType;
                            scope.closed = attrs.closed ? attrs.closed : 'false';
                            if (attrs.closed) {
                                scope.form.$promise.then(function () {
                                    scope.closed = !scope.form.partCertificates || scope.form.partCertificates.certificates.length == 0;
                                });
                            }

                            if (!scope.form.partCertificates) {
                                scope.form.partCertificates = {certificates: []};
                            }

                            // Mark required field error
                            scope.certificateNoRequired = false;

                            scope.editMode = false;
                            var editedCertificate = null;

                            var certificatesMessageRegion = 'certificates-msg-region';
                            MessageService.clear(certificatesMessageRegion);

                            EptsCertificateUtils.sortCertificates(scope.form.partCertificates.certificates);

                            scope.currentCertificate = {
                                startDate: null,
                                endDate: null,
                                certificateNo: null,
                                type: 'incapacity'
                            };

                            scope.form.$promise.then(function () {
                                // Update certificate statuses (remove obsolete certificates)
                                if (scope.form.partCertificates.certificates.length > 0) {
                                    EptsCertificatesApiFactory.getCertificates({personalCode: scope.patientData.personalCode,
                                        birthDate: scope.patientData.birthDate,
                                        organizationCode: scope.organization.jarCode},
                                    function (certificatesInEpts) {
                                        var updated = false;

                                        _.each(scope.form.partCertificates.certificates, function (certificate) {
                                            var certificateInEpts = findCertificateByNumber(certificatesInEpts, certificate.certificateNo);

                                            if (certificateInEpts != null) {
                                                // Possibly needs to be updated
                                                if (certificate.status != certificateInEpts.status) {
                                                    certificate.status = certificateInEpts.status;
                                                    updated = true;
                                                }
                                            } else {
                                                // Remove obsolete certificate
                                                scope.removeCertificate(certificate);
                                                updated = true;
                                            }
                                        });

                                        if (updated) {
                                            MessageService.add('info', $filter('translate')('cer.status.updated'), certificatesMessageRegion);
                                        }
                                    });
                                }
                            });

                            function findCertificateByNumber(certificates, number) {
                                for (var i = 0; i < certificates.length; i++) {
                                    if (certificates[i].number == number) {
                                        return certificates[i];
                                    }
                                }
                                return null;
                            }

                            // Check if there is dummy certificate
                            scope.hasDummyCertificate = false;
                            _.each(scope.form.partCertificates.certificates, function (certificate) {
                                if (!certificate.certificateNo) {
                                    scope.hasDummyCertificate = true;
                                }
                            });

                            scope.$watch('currentCertificate.type', function () {
                                if (!scope.editMode) {
                                    scope.currentCertificate.endDate = null;
                                    scope.currentCertificate.certificateNo = null;
                                    scope.currentCertificate.description = null;

                                    if (scope.currentCertificate.type == 'medical') {
                                        scope.currentCertificate.startDate = (new Date()).getTime();
                                    } else {
                                        scope.currentCertificate.startDate = null;
                                    }
                                }
                            });

                            scope.saveCertificate = function (form) {
                                MessageService.clear('certificates-msg-region');

                                if (form.$invalid) {
                                    MessageService.add('error', 'Privaloma užpildyti privalomus laukus', 'certificates-msg-region');
                                    return;
                                }

                                editedCertificate.startDate = scope.currentCertificate.startDate;
                                editedCertificate.endDate = scope.currentCertificate.endDate;
                                editedCertificate.certificateNo = scope.currentCertificate.certificateNo;
                                editedCertificate.description = scope.currentCertificate.description;
                                editedCertificate = null;	// Detach from main object

                                scope.currentCertificate.startDate = (new Date()).getTime();
                                scope.currentCertificate.endDate = null;
                                scope.currentCertificate.certificateNo = null;
                                scope.currentCertificate.description = null;
                                _.markAllAsUntouched(form);

                                scope.editMode = false;
                            };

                            scope.cancelCertificateEdit = function () {
                                scope.currentCertificate.startDate = (new Date()).getTime();
                                scope.currentCertificate.endDate = null;
                                scope.currentCertificate.certificateNo = null;
                                scope.currentCertificate.description = null;

                                scope.editMode = false;
                            };

                            scope.editCertificate = function (certificate) {
                                scope.editMode = true;
                                scope.currentCertificate = {
                                    certificateNo: certificate.certificateNo,
                                    startDate: certificate.startDate,
                                    endDate: certificate.endDate,
                                    description: certificate.description,
                                    type: 'medical'
                                };

                                editedCertificate = certificate;
                            };

                            scope.removeCertificate = function (certificate) {
                                var index = scope.form.partCertificates.certificates
                                        .indexOf(certificate);
                                if (index >= 0) {
                                    scope.form.partCertificates.certificates
                                            .splice(index, 1);
                                }

                                if (scope.hasDummyCertificate) {
                                    scope.hasDummyCertificate = index > 0;
                                }
                            };

                            scope.addCertificate = function (form: any) {
                                _.markAllAsTouched(form);
                                MessageService.clear('certificates-msg-region');
                                if ((scope.currentCertificate.type != 'medical')
                                        && (scope.form.metadata.patient.personalCode == scope.form.metadata.practitioner.personalCode)) {
                                    MessageService.error($filter('translate')('err.sod.cer.sam.per'), certificatesMessageRegion);
                                    return;
                                }

                                if (form.$invalid && scope.currentCertificate.type == 'medical') {
                                    MessageService.add('error', 'Privaloma užpildyti privalomus laukus', 'certificates-msg-region');
                                    return;
                                }

                                var certificate = {
                                    startDate: scope.currentCertificate.startDate,
                                    endDate: scope.currentCertificate.endDate,
                                    certificateNo: scope.currentCertificate.certificateNo,
                                    type: scope.currentCertificate.type,
                                    description: scope.currentCertificate.description,
                                    status: 'REGISTERED',
                                    submissionDate: ((scope.currentCertificate.type == 'medical') ? (new Date()).getTime() : null)
                                };

                                scope.form.partCertificates.certificates.unshift(certificate);

                                // Clear previous data
                                scope.currentCertificate.certificateNo = null;
                                scope.currentCertificate.endDate = null;
                                scope.currentCertificate.description = null;
                                if (scope.currentCertificate.type == 'medical') {
                                    scope.currentCertificate.startDate = (new Date()).getTime();
                                }
                                _.markAllAsUntouched(form);

                                scope.hasDummyCertificate = certificate.type != 'medical';
                                EptsCertificateUtils.sortCertificates(scope.form.partCertificates.certificates);
                            };

                            scope.getTitle = EptsCertificateUtils.getCertificateTitle;
                            scope.getCertificateUrl = EptsCertificateUtils.getCertificateUrl;
                            scope.getStatus = EptsCertificateUtils.getCertificateStatus;
                        }
                    };
                }])
        .directive(
                'viewCertificates',
                ['$filter', '$routeParams', 'EptsCertificateUtils',
                function ($filter, $routeParams, EptsCertificateUtils) {
                    return {
                        templateUrl: '/templates/documents/components/view/view_certificates.html',
                        restrict: 'E',
                        replace: false,
                        scope: true,
                        transclude: true,
                        link: function (scope: any, element, attrs) {
                             //add block to document content
                            if (scope._shared.contentList) {
                                scope._shared.contentList[attrs.contentIndex] ={
                                    title: $filter('translate')('doc.cer.inc'),
                                    id: 'doc_cer_inc',
                                    show: function () {
                                      return scope.form.partCertificates.certificates && scope.form.partCertificates.certificates.length > 0;
                                    }
                                };
                            }

                            scope.formType = attrs.formType;

                            if (!scope.form.partCertificates) {
                                scope.form.partCertificates = {certificates: []};
                            }

                            EptsCertificateUtils.sortCertificates(scope.form.partCertificates.certificates);

                            scope.getTitle = EptsCertificateUtils.getCertificateTitle;
                            scope.getCertificateUrl = EptsCertificateUtils.getCertificateUrl;
                            scope.getStatus = EptsCertificateUtils.getCertificateStatus;
                        }
                    };
                }])
        .directive('editNotes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_notes.html',
                restrict: 'E',
                replace: false,
                scope: {form: '=', shared: '='},
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope.shared.contentList) {
                        scope.shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.not'),
                            id: 'doc_not'
                        };
                    }
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = !scope.form.notes;
                        });
                    }

                }
            };
        }])
        .directive('viewNotes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_notes.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.not'),
                            id: 'doc_not',
                            show: function () {
                                return scope.form.notes;
                            }
                        };
                    }
                }
            };
        }])
        .directive('editObsResearch',
    ['ConditionsApiFactory', 'TypeaheadSearchHandlers', '$q', '$filter',
     function (ConditionsApiFactory, TypeaheadSearchHandlers, $q, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_observation_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.obs.res'),
                            id: 'doc_obs_res'
                        };
                    }
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    var encounterId = scope.form.encounterId;
                    scope.formType = attrs.formType;
                    scope.temp = {};
                    scope.filter = {
                        diagnosisSearch: ''
                    };

                    scope.diagnosisFilterPaths = [];

                    scope.getQualifications = TypeaheadSearchHandlers.PROFESSIONS_MEDICAL;

                    scope.getQualificationsChange = function (val) {
                        if (val) {
                            if (val.code) {
                                scope.form.orderData.item.targetQualification = val;
                            } else if (_.isString(val)) {
                                scope.form.orderData.item.targetQualification = undefined;
                                scope.form.orderData.item.departmentSpecialization = val;
                            }
                        }
                    };
                    scope.diagnosisList = ConditionsApiFactory.getConditions({
                        docType: 'e003',
                        encounterId: encounterId
                    });

                    scope.isDiagnosisAvailable = function (diag) {
                        var available = true;
                        _.each(scope.form.partDiagnosis, function (item) {
                            if (item.diagnosis.code == diag.code) {
                                available = false;
                            }
                        });
                        return available;
                    }

                    scope.form.$promise.then(function (f) {
                        scope.diagnosisList.$promise.then(function (dList) {
                            if (f.orderData && f.orderData.item) {
                                var item = f.orderData.item;
                                scope.temp.targetQualification = item.targetQualification && item.targetQualification.name ? item.targetQualification.name : item.departmentSpecialization;
                            }
                            if (f.existingDiagnosis) {
                                _.each(dList, function (d) {
                                    if (d.diagnosis.code == f.existingDiagnosis.diagnosis.code) {
                                        f.existingDiagnosis = d;
                                    }
                                });
                            }
                        });
                    });
                }
            };
        }])
        .directive('viewObsExtractPolice',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_observation_extract_police.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    scope.isAlertMessageEmpty = function () {
                        return !scope.form.alertMessageItem;
                    }

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.obs.ext.pol'),
                            id: 'doc_obs_ext_pol',
                            show: function () {
                                return !scope.isAlertMessageEmpty();
                            }
                        };
                    }
                }
            };
        }])
        .directive('editRelatedDiagnosis',
    ['SessionApiFactory', 'ConditionsApiFactory', 'MessageService', '$modalConfirm', 'SubFormValidator', '$filter',
     function (SessionApiFactory, ConditionsApiFactory, MessageService, $modalConfirm, SubFormValidator, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_related_diagnosis.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.dia.rel'),
                            id: 'doc_dia_rel'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    var editMode = false;
                    var editIndex = -1;
                    var validate = function (diagnosisForm) {
                        scope.validator.clear();
                        if (!diagnosisForm.diagnosis || !diagnosisForm.diagnosis.code) {
                            MessageService.add('error', 'Parinkta diagnozė, kurios nėra klasifikatoriuje.', 'related-diagnosis-region');
                            scope.validator.add('diagnosis');
                        }
                        return !scope.validator.hasErrors();
                    };

                    scope.formType = attrs.formType;
                    scope.diagnosisFilterPaths = [];
                    scope.validator = new SubFormValidator('related-diagnosis-region');

                    scope.filter = {
                        diagnosisSearch: ''
                    };

                    scope.data.diagnosisForm = {};

                    scope.form.$promise.then(function (form) {
                        if (form.encounterId) {
                            scope.relatedDiagnosisList = ConditionsApiFactory.getConditions({
                                docType: ['e003', 'e025'],
                                encounterId: form.encounterId
                            });
                        }
                    });

                    scope.addDiagnosis = function () {
                        if (validate(scope.data.diagnosisForm)) {
                            var data = _.clone(scope.data.diagnosisForm);
                            withSysDate(SessionApiFactory, data, function () {
                                scope.form.partDiagnosis.push(data);
                                scope.data.diagnosisForm = {};
                            });
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    };

                    scope.removeDiagnosis = function (index) {
                        $modalConfirm.show({
                            content: 'Ar tikrai norite pašalinti?'
                        }).then(function () {
                            scope.form.partDiagnosis.splice(index, 1);
                        });
                    };

                    scope.editDiagnosis = function (index) {
                        var diagForm = _.clone(scope.form.partDiagnosis[index]);
                        editIndex = index;
                        editMode = true;

                        _.each(scope.relatedDiagnosisList, function (d) {
                            if (d.diagnosis.code == diagForm.diagnosis.code) {
                                scope.data.diagnosisForm = d;
                                scope.data.diagnosisForm.description = diagForm.description;
                            }
                        });
                    };

                    scope.saveDiagnosis = function () {
                        if (validate(scope.data.diagnosisForm)) {
                            var data = _.clone(scope.data.diagnosisForm);
                            withSysDate(SessionApiFactory, data, function () {
                                scope.form.partDiagnosis[editIndex] = data;
                                editMode = false;
                                scope.data.diagnosisForm = {};
                            });
                        }
                    };
                    scope.cancelEditDiagnosis = function () {
                        MessageService.clear('related-diagnosis-region');
                        scope.data.diagnosisForm = {};
                        editMode = false;
                    };

                    scope.isEditMode = function () {
                        return editMode;
                    };

                    scope.isDiagnosisAvailable = function (diag) {
                        var available = true;
                        if (!editMode) {
                            _.each(scope.form.partDiagnosis, function (item) {
                                if (item.fullId === diag.fullId) {
                                    available = false;
                                }
                            });
                        }
                        return available;
                    };
                }
            };
        }])
        .directive('editObservation',
            ['ConditionsApiFactory', 'EntitiesApiFactory', 'AspnContextFactory', 'TypeaheadSearchHandlers', '$q', '$filter', '$routeParams',
                function (ConditionsApiFactory, EntitiesApiFactory, AspnContextFactory, TypeaheadSearchHandlers, $q, $filter, $routeParams) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_observation.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    var encounterId = scope.form.encounterId;
                    scope.patientId = $routeParams.patientId
                    scope.temp = {};
                    scope.filter = {
                        diagnosisSearch: ''
                    };
                    scope.currentDate = new Date().getTime();
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.obs'),
                            id: 'doc_obs'
                        };
                    }

                    scope.states = [];
                    scope.onChangeService = function (searchInput) {
                        if (searchInput !== undefined && searchInput.trim().length !== 0) {
                            EntitiesApiFactory.getList({
                                classCode: 'service-type-list',
                                searchCriteria: searchInput
                            }).$promise.then(types => {
                                scope.states = types;
                            });
                        } else {
                            scope.states = [];
                        }
                    }

                    scope.getLastQuestionaire = function () {
                        AspnContextFactory.getLastQuestionaireByPatientId({patientId: scope.patientId}).$promise.then(qstn => {
                            scope.form.orderData.item.aspnNeeds = qstn.finalScore !== undefined ? qstn.finalScore : "";
                        });
                    }

                    scope.obsTypes = [];
                    EntitiesApiFactory.getList({classCode: 'order-type', count: -1}).$promise.then(types => {
                        scope.obsTypes = types.filter(t => t.code == 1 || t.code == 21);
                    });

                    scope.onChangeObsType = function(type){
                        if (scope.form.orderData.item === undefined) {
                            scope.form.orderData.item = {};
                        }
                        scope.form.orderData.item.type = type;
                    }

                    scope.liftTypes = [];
                    EntitiesApiFactory.getList({classCode: 'lift-type', count: -1}).$promise.then(types => {
                        scope.liftTypes = types;
                    });

                    scope.onChangeLiftType = function(type){
                        scope.form.orderData.item.liftType = type;
                    }

                    scope.nursingTypes = [];
                    EntitiesApiFactory.getList({classCode: 'nursing-service-type', count: -1}).$promise.then(types => {
                        scope.nursingTypes = types;
                    });

                    scope.onChangeNursingType = function(type){
                        scope.form.orderData.item.nursingType = type;
                        if (type.code === 'ASPN') {
                            scope.getLastQuestionaire();
                        } else {
                            scope.form.orderData.item.aspnNeeds = undefined;
                        }
                    }

                    scope.diagnosisFilterPaths = [];
                    scope.getQualifications = TypeaheadSearchHandlers.PROFESSIONS_MEDICAL;
                    scope.getQualificationsChange = function (val) {
                        if (val) {
                            if (val.code) {
                                scope.form.orderData.item.targetQualification = val;
                            } else if (_.isString(val)) {
                                scope.form.orderData.item.targetQualification = undefined;
                                scope.form.orderData.item.departmentSpecialization = val;
                            }
                        }
                    };
                    scope.diagnosisList = ConditionsApiFactory.getConditions({
                        docType: 'e003',
                        encounterId: encounterId
                    });

                    scope.searchDepartments = function (v) {
                        if (scope.form.orderData.item.organization) {
                            return TypeaheadSearchHandlers.ALL_ORGANIZATIONS(v);
                        } else {
                            return [];
                        }
                    };

                    scope.searchDepartmentsChange = function (v) {
                        if (v && v.id) {
                            scope.form.orderData.item.organization = v;
                        }
                    };
                }
            };
        }])
        .directive('editLaboratoryResearch',
    ['TypeaheadSearchHandlers', '$filter',
     function (TypeaheadSearchHandlers, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_laboratory_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.title =attrs.title;

                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.lab.res'),
                            id: 'doc_lab_res'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.labResearch = {};

                    scope.form.$promise.then(function () {
                        if (scope.form.orderData.item.labData.specimen) {
                            scope.labResearch.specimenDisplay = scope.form.orderData.item.labData.specimen.name;
                        }
                        if (scope.form.orderData.item.labData.research) {
                            scope.labResearch.researchDisplay = scope.form.orderData.item.labData.research.name;
                        }
                    });

                    var init = function () {
                        scope.isSpecimentNoteRequired = function () {
                            return scope.form.orderData.item.labData.specimen && '27' === scope.form.orderData.item.labData.specimen.code;
                        };
                    };

                    scope.form.$promise.then(init);
                    scope.searchMaterialTypes = TypeaheadSearchHandlers.MATERIALL_TYPE;
                    scope.searchResearchTypes = TypeaheadSearchHandlers.RESEARCH_TYPE;

                    scope.searchMaterialTypesChange = function (v) {
                        if (v && v.id) {
                            scope.form.orderData.item.labData.specimen = v;
                        }
                    };
                    scope.searchResearchTypesChange = function (v) {
                        if (v && v.id) {
                            scope.form.orderData.item.labData.research = v;
                        }
                    };
                }
            };
        }])
        .directive('editResearchDataDescription',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_research_data_description.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.res.dat.des'),
                            id: 'doc_res_dat_des'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editDiagnosisSm',
    ['TypeaheadSearchHandlers',
     function (TypeaheadSearchHandlers) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_diagnosis_sm.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('commonPatientWidget', function () {
            return {
                templateUrl: '/templates/documents/components/common-patient-widget.html',
                restrict: 'E',
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope._modeModification = attrs.modeModification;
                    scope.getPatientAge = function () {
                        if (scope.patientData) {
                            return _.age(scope.patientData.birthDate);
                        }
                        return '';
                    }
                }
            };
        })
        .directive('editInPatientDischargeData',
    ['TypeaheadSearchHandlers', 'EntitiesApiFactory', '$filter',
     function (TypeaheadSearchHandlers, EntitiesApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_in_patient_discharge_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.leave.info'),
                            id: 'doc_leave_info'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.form.$promise.then(function () {
                        scope.$watch('form.partDischarge.dischargeDate', function (val, b) {
                            scope.form.partDischarge.bedDays = _.countBedDays(scope.encounterData.arrivalDate, val);
                        });

                        scope.dischargeTypes = EntitiesApiFactory.getList({classCode: 'f025alk-discharge-type'}, function (list) {
                            angular.forEach(list, function (val) {
                                if (scope.form.partDischarge.type && val.code === scope.form.partDischarge.type.code) {
                                    scope.form.partDischarge.type = val;
                                }
                            });
                        });
                        scope.searchOrganizations = TypeaheadSearchHandlers.ORGANIZATIONS;
                        scope.searchOrganizationsChange = function (v) {
                            if ((v && _.isString(v)) || !v) {
                                scope.form.partDischarge.destinationOrganizationName = v;
                            }
                        };

                        scope.form.partDischarge.destinationOrganizationDisplay = scope.form.partDischarge.destinationOrganizationName;
                    });
                }
            };
        }])
        .directive('editInPatientAccomodationData',
    ['TypeaheadSearchHandlers', 'DocumentCtx', 'MessageService', '$modalConfirm', '$filter',
     function (TypeaheadSearchHandlers, DocumentCtx, MessageService, $modalConfirm, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_in_patient_accomodation_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    if(scope.form.partInPatientAccomodation === undefined){
                        scope.form.partInPatientAccomodation = [];
                    }

                    setupIsolatedScope(scope, attrs);

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.departm.info'),
                            id: 'doc_departm_info'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.inPatientAccomodationForm = {};


                    var encounterDate = null;
                    var initForm = function () {
                        scope.inPatientAccomodationForm = {};
                        if (!scope.form.partInPatientAccomodation || scope.form.partInPatientAccomodation.length == 0) {
                            scope.inPatientAccomodationForm.from = encounterDate;
                        }
                        scope.departmentSearchHandler();
                    };
                    scope.form.$promise.then(function () {
                        encounterDate = scope.form.metadata.encounter.arrivalDate;
                        initForm();
                    });

                    scope.departmentSearchHandler = function (value) {
                        if (!scope.form.metadata) {
                            return [];
                        }
                        var jarCode = scope.form.metadata.custodian.jarCode;
                        return TypeaheadSearchHandlers.ORG_DEPARTMENTS(value, jarCode);
                    }

                    if (attrs.exposeForm) {
                        scope.$parent[attrs.exposeForm] = scope.accomodationForm;
                    }

                    scope.searchDepartmentChange = function (v) {
                        if (v && v.id) {
                            scope.inPatientAccomodationForm.department = v;
                            scope.inPatientAccomodationForm.departmentRef = v.fullId;
                        }
                    }

                    var validate = function (form, handler) {
                        _.markAllAsTouched(form);
                        MessageService.clear('inPatientSections-region');
                        var hasErrors = false;

                        if (!scope.inPatientAccomodationForm.department || !scope.inPatientAccomodationForm.department.name) {
                            MessageService.add('error', 'Formoje yra klaidų', 'inPatientSections-region');
                            MessageService.add('error', 'Skyrius yra privalomas', 'inPatientSections-region ');
                            hasErrors = true;
                            scope.departmentRequired = true;
                        } else {
                            scope.departmentRequired = false;
                        }

                        if (!scope.inPatientAccomodationForm.from || !scope.inPatientAccomodationForm.to) {
                            if(!hasErrors){
                                MessageService.add('error', 'Formoje yra klaidų', 'inPatientSections-region');
                            }
                            MessageService.add('error', 'Gydymo datos yra privalomos', 'inPatientSections-region');
                            hasErrors = true;
                        } else if (scope.inPatientAccomodationForm.from > scope.inPatientAccomodationForm.to) {
                            if(!hasErrors){
                                MessageService.add('error', 'Formoje yra klaidų', 'inPatientSections-region');
                            }
                            MessageService.add('error', 'Gydymo pabaiga turi būti vėliau nei pradžia', 'inPatientSections-region');
                            hasErrors = true;
                        }
                        if(!hasErrors){
                            handler();
                            _.markAllAsUntouched(form);
                        }
                    }

                    scope.__addInPatientSection = function (form) {
                        validate(form,function () {
                            var data = _.clone(scope.inPatientAccomodationForm);
                            scope.form.partInPatientAccomodation.push(data);
                            initForm();
                        });
                    }
                    scope.__removeInPatientSection = function (index) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            scope.form.partInPatientAccomodation.splice(index, 1);
                            initForm();
                        });
                    }
                    scope.countBedDays = function (form) {
                        return form ? _.countBedDays(form.from, form.to) : null;
                    }

                    scope.__modifyInPatientSection = function (s) {
                        scope.inPatientAccomodationForm = _.clone(s);
                        scope.inPatientAccomodationForm.orig = s;
                        scope.inPatientAccomodationForm._modeModification = true;
                        scope.inPatientAccomodationForm.departmentDisplay = TypeaheadSearchHandlers.ORG_DEPARTMENTS_label(scope.inPatientAccomodationForm.department);
                    }

                    scope.__saveInPatientSection = function (form) {
                        validate(form, function () {
                            var orig = scope.inPatientAccomodationForm.orig;
                            scope.inPatientAccomodationForm.orig = null;
                            angular.copy(scope.inPatientAccomodationForm, orig);
                            initForm();
                        });
                    }

                    scope.__cancelInPatientSectionModification = function () {
                        MessageService.clear('inPatientSections-region');
                        initForm();
                    }

                }
            };
        }])
        .directive('editOrdersPat',
    ['MessageService', 'SubFormValidator', '$modalConfirm', 'DocumentsApiFactory', '$filter',
     function (MessageService, SubFormValidator, $modalConfirm, DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_orders_pat.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.patolog.obs'),
                            id: 'doc_patolog_obs'
                        };
                    }

                    scope.formType = attrs.formType;
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = scope.form.partConsultationPlan.items.length == 0;
                        });
                    }

                    if (!scope._removed.orders) {
                        scope._removed.orders = [];
                    }

                    var initForm = function () {
                        scope.appointementsForm = {
                            selectedDiagnosis: {},
                            patData: {}
                        }
                    }

                    initForm();

                    _research_applyOrderDocuments(scope, DocumentsApiFactory);

                    var __options = [];

                    var __watchFn = function () {
                        if (!scope.form || !scope.form.partConsultationPlan) {
                            return;
                        }
                        __options = _.map(scope.form.partDiagnosis, function (d) {
                            return d.diagnosis;
                        });
                    }

                    scope.$watch("form.partDiagnosis", __watchFn, true);
                    scope.$watch("form.partConsultationPlan.items", __watchFn, true);
                    scope.$watch("appointementsForm._modeModification", __watchFn, true);

                    scope.___diagnosisListFiltered = function () {
                        return __options;
                    }

                    scope.validator = new SubFormValidator('appointments-region');

                    var validate = function (handler) {
                        scope.validator.clear();
                        var selectedDiagnosis = [];
                        _.each(scope.form.partDiagnosis, function (d) {
                            if (scope.appointementsForm.selectedDiagnosis[d.diagnosis.code]) {
                                selectedDiagnosis.push(d.diagnosis);
                            }
                        });
                        if (selectedDiagnosis.length == 0) {
                            scope.validator.add('diagnosis');
                        }
                        if (!scope.appointementsForm.patData.sampleCollectionMethod || !scope.appointementsForm.patData.sampleCollectionMethod.id) {
                            scope.validator.add('sampleCollectionMethod');
                        }
                        if (!scope.appointementsForm.date) {
                            scope.validator.add('date');
                        }
                        if (!scope.validator.hasErrors()) {
                            handler(selectedDiagnosis);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    }

                    scope.__addAppointment = function () {
                        validate(function (selectedDiagnosis) {
                            var data = _.clone(scope.appointementsForm);
                            data.type = {code: 3};
                            data.selectedDiagnosis = null;
                            data.patData.diagnosis = selectedDiagnosis;
                            scope.form.partConsultationPlan.items.push(data);
                            initForm();
                        });
                    }

                    scope.__removeItem = function (index) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            var removed = scope.form.partConsultationPlan.items.splice(index, 1);
                            if (removed[0].fullId) {
                                scope._removed.orders.push(removed[0]);
                            }
                        });
                    };

                    // ---- modification removed by RCESPBI-1591 ----
                    //scope.__modifyAppointment = function (index) {
                    //    scope.appointementsForm = _.clone(scope.form.partConsultationPlan.items[index]);
                    //    scope.appointementsForm.index = index;
                    //    scope.appointementsForm.selectedDiagnosis = {};
                    //    scope.appointementsForm._modeModification = true;
                    //    _.each(scope.appointementsForm.patData.diagnosis, function(d){
                    //        scope.appointementsForm.selectedDiagnosis[d.code] = true;
                    //    });
                    //    scope.appointementsForm.patData.sampleCollectionMethod = _.find(scope.types.sampleCollMethods, function(i){
                    //        return scope.appointementsForm.patData.sampleCollectionMethod.code === i.code;
                    //    });
                    //}

                    // ---- modification removed by RCESPBI-1591 ----
                    //scope.__saveAppointment = function () {
                    //    validate(function(selectedDiagnosis){
                    //        var data = _.clone(scope.appointementsForm);
                    //        scope.appointementsForm.patData.diagnosis = selectedDiagnosis;
                    //        angular.copy(scope.appointementsForm, scope.form.partConsultationPlan.items[data.index]);
                    //        initForm();
                    //    });
                    //}

                    scope.__cancelAppointmentModification = function () {
                        initForm();
                    }

                }
            };
        }])
        .directive('editConsultations',
    ['MessageService', 'SessionApiFactory', 'SessionContext', '$modalConfirm', '$filter',
     function (MessageService, SessionApiFactory, SessionContext, $modalConfirm, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_consultations.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.spec.cons'),
                            id: 'doc_spec_cons',
                            show: function () {
                                // TODO: Check if works after change : form. is the same as scope.form?
                               return scope.form.metadata.compositionId && scope.form.metadata.status=='preliminary';
                            }
                        };
                    }

                    scope.formType = attrs.formType;
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    if (attrs.closed) {
                        scope.form.$promise.then(function () {
                            scope.closed = scope.form.partConsultations.length == 0;
                        });
                    }

                    var initForm = function () {
                        scope.consultationsForm = {
                            _selectedDiagnosis: {}
                        };
                    };
                    initForm();

                    var validate = function (allDiagnosis, handler) {
                        MessageService.clear('consultations-region');
                        var selectedDiagnosis = [];
                        _.each(allDiagnosis, function (i) {
                            if (scope.consultationsForm._selectedDiagnosis[i.diagnosis.code]) {
                                selectedDiagnosis.push(i.diagnosis);
                            }
                        });
                        if (selectedDiagnosis.length == 0) {
                            MessageService.add('error', 'Formoje yra klaidų', 'consultations-region');
                        } else if (!scope.consultationsForm.description || scope.consultationsForm.description.trim() == '') {
                            MessageService.add('error', 'Formoje yra klaidų', 'consultations-region');
                        } else {
                            handler(selectedDiagnosis);
                        }
                    }

                    scope.addConsultations = function (allDiagnosis) {
                        validate(allDiagnosis, function (selectedDiagnosis) {
                            var data = {
                                diagnosis: selectedDiagnosis,
                                description: scope.consultationsForm.description,
                                practitioner: SessionContext.practitioner
                            };
                            withSysDate(SessionApiFactory, data, function () {
                                scope.form.partConsultations.push(data);
                                initForm();
                            });
                        });
                    };

                    scope.removeSelectedConsultation = function (index) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            scope.form.partConsultations.splice(index, 1);
                        });
                    };

                    scope.__modifyConsultation = function (d) {
                        MessageService.clear('consultations-region');
                        scope.consultationsForm = _.clone(d);
                        scope.consultationsForm.orig = d;
                        scope.consultationsForm._modeModification = true;
                        scope.consultationsForm._selectedDiagnosis = {};
                        _.each(d.diagnosis, function (d) {
                            scope.consultationsForm._selectedDiagnosis[d.code] = true;
                        });
                    }

                    scope.__saveConsultation = function (allDiagnosis) {
                        validate(allDiagnosis, function (selectedDiagnosis) {
                            var orig = scope.consultationsForm.orig;
                            scope.consultationsForm.orig = null;
                            angular.copy(scope.consultationsForm, orig);
                            orig.diagnosis = selectedDiagnosis;
                            initForm();
                        });
                    }

                    scope.__cancelConsultationModification = function () {
                        MessageService.clear('consultations-region');
                        initForm();
                    }

                }
            };
        }])
        .directive('editMateriallResearch',
    ['EntitiesApiFactory', '$filter',
     function (EntitiesApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_materiall_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.mat.res'),
                            id: 'doc_mat_res'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.data.types = {
                        storageTypes: EntitiesApiFactory.getList({
                            classCode: 'specimen-storage-trans-detail'
                        }, function (list) {
                            var type = (scope.form.storageInformation && scope.form.storageInformation.storageType) ? scope.form.storageInformation.storageType.code : null;
                            if (type) {
                                angular.forEach(list, function (item) {
                                    if (item.code === type) {
                                        scope.form.storageInformation.storageType = item;
                                    }
                                });
                            }
                        })
                    };

                    scope.isStorageNoteRequired = function () {
                        if (scope.form.storageInformation && scope.form.storageInformation.storageType) {
                            return scope.form.storageInformation.storageType.name.toLowerCase() === 'kita';
                        } else {
                            return false;
                        }
                    }
                }
            };
        }])
        .directive('editExtraLaboratoryData',
    ['$filter', 'SettingsApiFactory',
     function ($filter, SettingsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_extra_laboratory_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.ext.lab'),
                            id: 'doc_ext_lab'
                        };
                    }

                    scope.formConfiguration = SettingsApiFactory.getByName({name : 'form_e200_configuration'});
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.showTiriamiImunoglobulinai = function () {
                        if(scope.formConfiguration && scope.form.orderData &&
                            scope.form.orderData.item && scope.form.orderData.item.labData &&
                            scope.form.orderData.item.labData.researchDiagnosticTypeCode) {
                            var tiriamiImunoglobulinai = JSON.parse(scope.formConfiguration.value).tiriamiImunoglobulinai;
                            return tiriamiImunoglobulinai.includes(scope.form.orderData.item.labData.researchDiagnosticTypeCode);
                        }
                        return false;
                    };

                    scope.addTestingImmunoToDescription = function () {
                        if(scope.form.genAntibodies) {
                            scope.form.description = $filter('translate')('doc.ext.lab.gen.antibod');
                            return;
                        }

                        var igG = $filter('translate')('doc.ext.lab.ig.g');
                        var igGResult = '';
                        if(scope.form.igGRbd) {
                            igG = igG + ' (' + $filter('translate')('doc.ext.lab.anti.s.s1.rbd') + ')';
                            igGResult = igG + '\n';
                        }
                        if(scope.form.igGAntiN) {
                            igG = igG + ' (' + $filter('translate')('doc.ext.lab.anti.n') + ') ';
                            igGResult = igG + '\n';
                        }

                        var igM = $filter('translate')('doc.ext.lab.ig.m');
                        var igMResult = '';
                        if(scope.form.igMRbd) {
                            igM = igM + ' (' + $filter('translate')('doc.ext.lab.anti.s.s1.rbd') + ')';
                            igMResult = igM + '\n';
                        }
                        if(scope.form.igMAntiN) {
                            igM = igM + ' (' + $filter('translate')('doc.ext.lab.anti.n') + ')';
                            igMResult = igM + '\n';
                        }

                        var igAResult = '';
                        if(scope.form.igA) {
                            igAResult = $filter('translate')('doc.ext.lab.ig.a');
                        }

                        scope.form.description = igGResult + igMResult + igAResult;
                        console.debug(scope.form.igGRbd);
                    };
                }
            };
        }])
        .directive('editMateriallReceiving',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_materiall_receiving.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.mat.rec'),
                            id: 'doc_mat_rec'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editProviderData',
    ['TypeaheadSearchHandlers', 'ConditionsApiFactory', '$filter',
     function (TypeaheadSearchHandlers, ConditionsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_provider_data.html',
                restrict: 'E',
                replace: false,
                scope: {form: '=', shared: '='},
                transclude: true,
                link: function (scope: any, element, attrs) {
                     //add block to document content
                    if (scope.shared.contentList) {
                        scope.shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.pro.dat'),
                            id: 'doc_pro_dat'
                        };
                    }

                    setupIsolatedScope(scope, attrs);
                    scope.tmp = {};

                    var init = function () {
                        var f = scope.form;
                        scope.tmp.department = f.provider.department;
                        scope.tmp.department.label = TypeaheadSearchHandlers.ORG_DEPARTMENTS_label(f.provider.department);
                        scope.tmp.practitioner = TypeaheadSearchHandlers.PRACTITIONERS_label(f.provider.orderer);

                        scope.searchPractitioners = function (v) {
                            return TypeaheadSearchHandlers.PRACTITIONERS(v, null, f.provider.department.jarCode);
                        };

                        scope.tmp.targetQualification = f.provider.targetQualification && f.provider.targetQualification.name ? f.provider.targetQualification.name : f.provider.departmentSpecialization;

                        scope.diagnosisList = ConditionsApiFactory.getConditions({
                            docType: 'e003',
                            encounterId: f.encounterId
                        });
                    };
                    scope.form.$promise.then(init);

                    scope.searchDepartments = function (v) {
                        if (scope.form.provider) {
                            return TypeaheadSearchHandlers.ALL_ORGANIZATIONS(v);
                        } else {
                            return [];
                        }
                    };

                    scope.searchDepartmentsChange = function (v) {
                        if (v && v.id) {
                            scope.form.provider.department = v;
                            scope.form.provider.orderer = undefined;
                            scope.tmp.practitioner = undefined;
                            scope.form.provider.qualification = undefined;
                        }
                    };

                    scope.searchPractitionersChange = function (v) {
                        if (v && v.id) {
                            scope.form.provider.orderer = v;
                            if (v.qualificationList.length === 1) {
                                scope.form.provider.qualification = v.qualificationList[0];
                            }
                        } else if (!v) {
                            scope.form.provider.orderer = undefined;
                        }
                    };

                    scope.searchPractitionersBlur = function () {
                        if (!scope.form.provider.orderer || !_.isObject(scope.form.provider.orderer)) {
                            scope.form.provider.orderer = undefined;
                            scope.tmp.practitioner = undefined;
                        }
                    };

                    scope.getQualifications = TypeaheadSearchHandlers.PROFESSIONS_MEDICAL;

                    scope.getQualificationsChange = function (val) {
                        if (val) {
                            if (val.code) {
                                scope.form.provider.targetQualification = val;
                            } else if (_.isString(val)) {
                                scope.form.provider.targetQualification = undefined;
                                scope.form.provider.departmentSpecialization = val;
                            }
                        }
                    };
                }
            };
        }])
        .directive('editLabTest',
    ['TypeaheadSearchHandlers', '$filter',
     function (TypeaheadSearchHandlers, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_laboratory_test.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.lab.tes'),
                            id: 'doc_lab_tes'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.tmp = {};

                    scope.showReagentTestProductOrManufacturerName = function () {
                        return scope.form.researchInformation &&
                        scope.form.researchInformation.selectedDiagnosticTestCode == 'T0';
                    };

                    scope.form.$promise.then(function () {
                        if (scope.form.researchInformation.attester) {
                            scope.tmp.practitioner = TypeaheadSearchHandlers.PRACTITIONERS_label(scope.form.researchInformation.attester);
                        }

                        if (scope.form.metadata) {
                            scope.searchPractitioners = function (value) {
                                return TypeaheadSearchHandlers.PRACTITIONERS(value, scope.form.metadata.custodian.id);
                            };

                            scope.searchPractitionersChange = function (v) {
                                if (v && v.id) {
                                    scope.form.researchInformation.attester = v;
                                    // a hack for $scope._shared.saveFinalOnly
                                    window.setTimeout(function(){
                                        scope.$apply()
                                    });

                                } else if (!v) {
                                    scope.form.researchInformation.attester = undefined;
                                    // a hack for $scope._shared.saveFinalOnly
                                    window.setTimeout(function(){
                                        scope.$apply()
                                    });
                                }
                            };
                        }

                        if (attrs.formType == 'e200a') {
                            scope.formConfiguration.$promise.then(function() {
                                _.each(scope.formConfiguration.prefill.labTestMethod, function(item) {
                                    if (item.codes.includes(scope.form.orderData.item.labData.researchDiagnosticTypeCode)) {
                                        scope.form.researchInformation.usedAnalyzeMethod = item.values[scope.currentLang()];
                                    }
                                })
                            });
                        }
                    });
                }
            };
        }])
        .directive('editLabRes',
    ['SubFormValidator', 'MessageService', '$modalConfirm', '$filter',
     function (SubFormValidator, MessageService, $modalConfirm, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_laboratory_results.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.lab.resu'),
                            id: 'doc_lab_resu'
                        };
                    }

                    if (attrs.formType == 'e200a') {
                        scope.form.$promise.then(function () {
                            scope.formConfiguration.$promise.then(function() {
                                scope.showElement = function(code) {
                                    if(scope.formConfiguration && scope.formConfiguration.visibility[code]) {
                                        return scope.formConfiguration.visibility[code].includes(scope.form.orderData.item.labData.researchDiagnosticTypeCode);
                                    }
                                    return false;
                                };
                            });
                        });
                    } else {
                        scope.showElement = function(code) { return false };
                    }

                    scope.getEmptyResultItem = function() {
                        return {
                            ig: {
                                g: {},
                                m: {},
                                a: {},
                                all: {}
                            }
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    var editMode = false,
                            editIndex = -1;
                    var validate = function (resultItem) {
                        scope.validator.clear();
                        if (!resultItem.analyteName) {
                            scope.validator.add('analyteName');
                        }
                        if (!resultItem.value) {
                            scope.validator.add('value');
                        }
                        if (!resultItem.normalReferenceRange) {
                            scope.validator.add('normalReferenceRange');
                        }

                        // Serologinių tyrimų (IgG, IgM, IgA) validacijos
                        if (resultItem.igCode) {
                            var ig = resultItem.ig[resultItem.igCode];

                            if (scope.showIgTypes(resultItem.igCode) && !ig.type) {
                                scope.validator.add('ig' + resultItem.igCode +'Type');
                            }
                            if (!ig.result) {
                                scope.validator.add('ig' + resultItem.igCode +'Result');
                            }

                            for (var i = 0; i < scope.form.analyteItems.length; i++) {
                                var currentIgCode = resultItem.igCode;
                                var alreadyAddedIgCode = scope.form.analyteItems[i].igCode;

                                if (['g', 'm', 'a'].includes(currentIgCode) && alreadyAddedIgCode == 'all') {
                                    MessageService.error( $filter('translate')('doc.lab.resu.ig.err.combo.exists'), 'lab-result');
                                    scope.validator.add('ig-' + currentIgCode);
                                    break;
                                } else if (currentIgCode == 'all' && ['g', 'm', 'a'].includes(alreadyAddedIgCode)) {
                                    MessageService.error( $filter('translate')('doc.lab.resu.ig.err.single.exists'), 'lab-result');
                                    scope.validator.add('ig-' + currentIgCode);
                                    break;
                                }
                            }
                        }

                        return !scope.validator.hasErrors();
                    };
                    scope.formType = attrs.formType;
                    scope.validator = new SubFormValidator('lab-result');
                    scope.resultItem = scope.getEmptyResultItem();

                    scope.form.$promise.then(function(){
                        _.each(scope.form.analyteItems, function(i){
                            checkLabResItem(i);
                        });
                    });

                    scope.virusTests = [{title: "COVID-19 tyrimo (PGR) rezultatų ruošiniai",
                        buttons: [
                            {label: "SARS-CoV-2 RNR nerasta",
                                style: {'border-color': '#229955', 'background-color': '#229955', 'color': 'black', 'font-weight': 'bold'},
                                analyteName: 'SARS-CoV-2 RNR (SARS-CoV-2 RNA)',
                                normalReferenceRange: '-',
                                value: 'Nerasta (Not detected)',
                                usedAnalyzeMethod: 'Tikralaikė PGR (Real-time PCR)'
                            },
                            {label: "SARS-CoV-2 RNR rasta",
                                style: {'border-color': 'red', 'background-color': 'red', 'color': 'black', 'font-weight': 'bold'},
                                analyteName: 'SARS-CoV-2 RNR (SARS-CoV-2 RNA)',
                                normalReferenceRange: '-',
                                value: 'Rasta (Detected)',
                                usedAnalyzeMethod: 'Tikralaikė PGR (Real-time PCR)'
                            },
                            {label: "Įtariamas COVID-19 ligos atvejis",
                                style: {'border-color': '#ED7014', 'background-color': '#ED7014', 'color': 'black', 'font-weight': 'bold'},
                                analyteName: 'SARS-CoV-2 RNR (SARS-CoV-2 RNA)',
                                normalReferenceRange: '-',
                                value: 'Įtariamas COVID-19 ligos atvejis (Suspected case of SARS-CoV-2 infection)',
                                usedAnalyzeMethod: 'Tikralaikė PGR (Real-time PCR)'
                            },
                            {label: "Nevertintinas",
                                style: {'border-color': '#CBCBCB', 'background-color': '#CBCBCB', 'color': 'black', 'font-weight': 'bold'},
                                analyteName: 'SARS-CoV-2 RNR (SARS-CoV-2 RNA)',
                                normalReferenceRange: '-',
                                value: 'Nevertintinas (Inconclusive)',
                                usedAnalyzeMethod: 'Tikralaikė PGR (Real-time PCR)'
                            }
                        ],
                        code: 'buttonsPgr'
                    },{title: "Greitųjų SARS-CoV-2 antigeno testų rezultatų ruošiniai",
                            buttons: [
                                {label: "SARS-CoV-2 viruso antigeno nerasta",
                                    style: {'border-color': '#00A1FE', 'background-color': 'white', 'color': 'black', 'font-weight': 'bold'},
                                    analyteName: 'SARS-CoV-2 viruso antigenas (virus antigen)',
                                    normalReferenceRange: '-',
                                    value: 'Nerasta (Not detected)',
                                    usedAnalyzeMethod: 'Imunochromatografija (Immunochromatography)'
                                },
                                {label: "SARS-CoV-2 viruso antigenas rastas",
                                    style: {'border-color': '#00A1FE', 'background-color': '#00A1FE', 'color': 'black', 'font-weight': 'bold'},
                                    analyteName: 'SARS-CoV-2 viruso antigenas (virus antigen)',
                                    normalReferenceRange: '-',
                                    value: 'Rasta (Detected)',
                                    usedAnalyzeMethod: 'Imunochromatografija (Immunochromatography)',
                                    notes: 'Informuojame, kad Jums atlikto greitojo antigeno testo rezultatas yra teigiamas. Jums reikia izoliuotis ir atlikti pakartotinį PGR tyrimą. Ėminys pakartotiniam PGR tyrimui turi būti paimamas ne vėliau kaip per 48 val. nuo antigeno testo rezultato gavimo. Registruokitės www.1808.lt elektroniniu būdu arba telefonu 1808.'
                                }
                            ],
                            code: 'buttonsGreitiejiAntigenai'
                        }
                    ];

                    scope.igAnalytes = {
                        labels: {
                            igTypeSingle: $filter('translate')('doc.ext.lab.anti.n'),
                            igTypeCombo: $filter('translate')('doc.ext.lab.anti.s.s1.rbd'),
                            igResultPos: $filter('translate')('doc.lab.resu.ig.pos.label'),
                            igResultNeg: $filter('translate')('doc.lab.resu.ig.neg.label'),
                            igResultBor: $filter('translate')('doc.lab.resu.ig.bor.label'),
                            concUnit: $filter('translate')('doc.lab.resu.ig.conc.unit')
                        },
                        values: {
                            igResultPos: $filter('translate')('doc.lab.resu.ig.pos.value'),
                            igResultNeg: $filter('translate')('doc.lab.resu.ig.neg.value'),
                            igResultBor: $filter('translate')('doc.lab.resu.ig.bor.value')
                        },
                        items: [
                            {
                                code: 'g',
                                name: $filter('translate')('doc.ext.lab.ig.g')
                            },
                            {
                                code: 'm',
                                name: $filter('translate')('doc.ext.lab.ig.m')
                            },
                            {
                                code: 'a',
                                name: $filter('translate')('doc.ext.lab.ig.a')
                            },
                            {
                                code: 'all',
                                name: $filter('translate')('doc.ext.lab.gen.antibod')
                            }
                        ],
                        showIgTypes: ['g', 'm']
                    }

                    scope.addResultItem = function () {
                        if (validate(scope.resultItem)) {
                            scope.form.analyteItems.push(scope.resultItem);
                            scope.resultItem = scope.getEmptyResultItem();
                            checkLabResItem(scope.form.analyteItems[scope.form.analyteItems.length-1]);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    };

                    scope.fillVirusTestItem = function (item) {
                        scope.resultItem.analyteName = item.analyteName;
                        scope.resultItem.normalReferenceRange = item.normalReferenceRange;
                        scope.resultItem.value = item.value;
                        scope.form.researchInformation.usedAnalyzeMethod = item.usedAnalyzeMethod;
                        if(item.notes){
                            scope.form.notes = item.notes;
                        }
                        scope.addResultItem();
                    };

                    scope.saveIgAnalyteItem = function (item, addNew) {
                        var ig = scope.resultItem.ig[item.code];

                        scope.resultItem.igCode = item.code;
                        scope.resultItem.analyteName = item.name + (ig.type ? ' ' + ig.type : '');
                        scope.resultItem.normalReferenceRange = ig.normalReferenceRange ? ig.normalReferenceRange : '-';
                        scope.resultItem.value = (ig.value ? ig.value : '-') + ' ' + scope.igAnalytes.labels.concUnit + ' ' + ig.result;

                        if (addNew) {
                            scope.addResultItem();
                        } else {
                            scope.saveResultItem();
                        }
                    };

                    scope.removeResultItem = function (index) {
                        $modalConfirm.show({
                            content: 'Ar tikrai norite pašalinti?'
                        }).then(function () {
                            scope.form.analyteItems.splice(index, 1);
                        });
                    };

                    scope.editResultItem = function (index) {
                        editIndex = index;
                        editMode = true;
                        scope.resultItem = _.clone(scope.form.analyteItems[index]);
                    };

                    scope.saveResultItem = function () {
                        if (validate(scope.resultItem)) {
                            scope.form.analyteItems[editIndex] = scope.resultItem;
                            editMode = false;
                            scope.resultItem = scope.getEmptyResultItem();
                            checkLabResItem(scope.form.analyteItems[editIndex]);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    };

                    scope.cancelResultItem = function () {
                        scope.validator.clear();
                        editMode = false;
                        scope.resultItem = {};
                    };

                    scope.isEditMode = function () {
                        return editMode;
                    };

                    scope.isIgAnalyteEditMode = function (item) {
                        return editMode && (item.code == scope.resultItem.igCode);
                    };

                    scope.showIgTypes = function (code) {
                        return scope.igAnalytes.showIgTypes.includes(code);
                    };
                }
            };
        }])
        .directive('editImpMed',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_important_medical_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.imp.med'),
                            id: 'doc_imp_med'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editMorRes',
    ['DocumentCtx', 'DocumentsApiFactory', '$filter',
     function (DocumentCtx, DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_morphological_researches.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.mor.res'),
                            id: 'doc_mor_res'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.data.count = 7;
                    scope.data.page = 1;
                    scope.data.docs = [];

                    var loadDocs = function () {
                        DocumentsApiFactory.getOrderResponseDocumentsForE014({
                            patientId: DocumentCtx.patientId,
                            count: scope.data.count,
                            page: scope.data.page
                        }, function (list) {
                            scope.data.docs = scope.data.docs.concat(list.items);
                            scope.data.total = list.total;
                            console.debug(scope.data.docs);
                        });
                    };

                    scope.loadMoreDocs = function () {
                        scope.data.page++;
                        loadDocs();
                    };


                    loadDocs();
                }
            };
        }])
        .directive('editMatInf',
    ['EntitiesApiFactory', '$filter',
     function (EntitiesApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_material_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.mat.inf'),
                            id: 'doc_mat_inf'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.types = {
                        specimenSubstances: EntitiesApiFactory.getList({classCode: 'specimen-fixating-substance'})
                    };
                    scope.form.$promise.then(function(form){
                        scope.types.specimenSubstances.$promise.then(function(types){
                            _.each(types, function(t){
                                if(form.specimenMaterial && form.specimenMaterial.code == t.code){
                                    form.specimenMaterial = t;
                                }
                            });
                        });
                    });
                }
            };
        }])
        .directive('editExtPat',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_extra_pathology_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.ext.pat'),
                            id: 'doc_ext_pat'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editPatRes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_patalogy_results.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pat.res'),
                            id: 'doc_pat_res'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editProInf',
    ['TypeaheadSearchHandlers', 'MessageService', '$modalConfirm', '$filter',
     function (TypeaheadSearchHandlers, MessageService, $modalConfirm, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_procedures_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pro.inf'),
                            id: 'doc_pro_inf'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.temp = {};

                    scope.searchProcedures = TypeaheadSearchHandlers.PAT_PROCEDURES;
                    scope.searchProceduresChange = function (val) {
                        if (val && val.id) {
                            scope.procedure = val;
                        }
                    };

                    scope.addProcedure = function () {
                        var found = false;
                        MessageService.clear('procedures-info-region');

                        if (!scope.procedure) {
                            MessageService.error('Nepasirinkta jokia procedūra.', 'procedures-info-region');
                            return;
                        }

                        _.each(scope.form.patalogyTestResults.proceduresCodes, function (item) {
                            if (item.code == scope.procedure.code) {
                                found = true;
                                MessageService.error('Procedūra jau pridėta.', 'procedures-info-region');
                            }
                        });

                        if (!found) {
                            console.debug(scope.procedure);
                            scope.form.patalogyTestResults.proceduresCodes.push(scope.procedure);
                            scope.procedure = null;
                            scope.temp.procedure = null;
                        }
                    };
                    scope.removeProcedure = function (index, proc) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti procedūrą (' + proc.code + ')?'}).then(function () {
                            scope.form.patalogyTestResults.proceduresCodes.splice(index, 1);
                        });
                    };

                    scope.notesRequired = function () {
                        return scope.form.patalogyTestResults && scope.form.patalogyTestResults.proceduresCodes && scope.form.patalogyTestResults.proceduresCodes.length == 0;
                    };
                }
            };
        }])
        .directive('editMainDia',
    ['TypeaheadSearchHandlers', '$filter',
     function (TypeaheadSearchHandlers, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_main_diagnosis.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.mai.dia'),
                            id: 'doc_mai_dia'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.temp = {};

                    scope.form.$promise.then(function (f) {
                        var pd = f.primaryDiagnosis;
                        if (pd && pd.diagnosisCode) {
                            scope.temp.diagnosisCode = pd.diagnosisCode.code + ' ' + pd.diagnosisCode.name;
                        }
                        if (pd && pd.morphologicalCode) {
                            scope.temp.morphologicalCode = pd.morphologicalCode.code + ' ' + pd.morphologicalCode.name;
                        }
                    });

                    scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;
                    scope.searchMorphDiagnosis = TypeaheadSearchHandlers.ICD0;

                    scope.searchDiagnosisChange = function (val) {
                        if (val && val.id) {
                            scope.form.primaryDiagnosis.diagnosisCode = val;
                        }
                    };
                    scope.searchMorphDiagnosisChange = function (val) {
                        if (val && val.id) {
                            scope.form.primaryDiagnosis.morphologicalCode = val;
                        }
                    };
                }
            };
        }])
        .directive('editVacRes', function () {
            return {
                templateUrl: '/templates/documents/components/edit/edit_vaccines_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        })
        .directive('editImmMed',
    ['MedicationsApiFactory', '$filter',
     function (MedicationsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_immune_medication_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.imm.med'),
                            id: 'doc_imm_med'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.covidSelected = [];
                    scope.form.$promise.then(function (f) {
                        if (!f.immunization) {
                            f.immunization = {};
                        }
                        f.immunization.vaccinationDate = new Date().getTime();
                        f.immunization.isActual = true;
                        if(scope.automated){
                            scope.npakids = MedicationsApiFactory.searchCovidVaccines({name: f.order.item.vacData.recommendation.recommendations[0].medicationName}, function (meds) {
                                if (f.immunization.medicationData && f.immunization.medicationData.id) {
                                    _.each(meds, function (m) {
                                        if (m.id == f.immunization.medicationData.id) {
                                            scope.covidSelected = m;
                                            scope.covidNpakidChanged(m);
                                        }
                                    });
                                } else {
                                    if (meds && meds.length != 0) {
                                        scope.covidSelected = meds[0];
                                        scope.covidNpakidChanged(meds[0]);
                                    }
                                }
                                return meds;
                            });
                        } else {
                            scope.npakids = MedicationsApiFactory.search({exactName: f.order.item.vacData.recommendation.recommendations[0].medicationName, kind: 'package_', count: 100}, function (meds) {
                                console.debug("data", f.immunization.medicationData);
                                if (f.immunization.medicationData && f.immunization.medicationData.id) {
                                    _.each(meds, function (m) {
                                        if (m.id == f.immunization.medicationData.id) {
                                            f.immunization.medicationData = m;
                                        }
                                    });
                                } else {
                                    if (meds && meds.length != 0) {
                                        f.immunization.medicationData = meds[0];
                                    }
                                }
                                return meds;
                            });
                        }
                    });
                }
            };
        }])
        .directive('editVacInf',
    ['$q', '$filter',
     function ($q, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_vaccines_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.vac.inf'),
                            id: 'doc_vac_inf'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editReaTo',
    ['TypeaheadSearchHandlers', '$filter',
     function (TypeaheadSearchHandlers, $filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_reaction_to_vaccine.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.rea.tov'),
                            id: 'doc_rea_tov'
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.searchReactions = TypeaheadSearchHandlers.VACCINATION_REACTIONS;
                    scope.searchReactionsChange = function (v) {
                        if (v && v.id) {
                            scope.form.vaccinationReaction = v;
                        }
                    }
                    scope.form.$promise.then(function (f) {
                        console.debug('loaded', f.vaccinationReaction);
                        if (f.vaccinationReaction && f.vaccinationReaction.code) {
                            f.vaccinationReactionDisplay = f.vaccinationReaction.code + ' ' + f.vaccinationReaction.name;
                        }
                    });
                }
            };
        }])
        .directive('editPlaVac',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_planned_vaccines.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pla.vac'),
                            id: 'doc_pla_vac',
                            show: function () {
                                if (scope.form.plannedVaccinations)
                                {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        };
                    }

                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('editAgreements',
    ['ClassifiersApiFactory', 'SessionApiFactory', 'PatientsApiFactory', 'SubFormValidator', 'MessageService', '$routeParams', '$modal', '$modalConfirm',
     function (ClassifiersApiFactory, SessionApiFactory, PatientsApiFactory, SubFormValidator, MessageService, $routeParams, $modal, $modalConfirm) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_agreements.html',
                restrict: 'E',
                replace: false,
                scope: {agreementList: '=', agreementFiles: '=', iconBgClass: '=iconBgClass', relatedPersons: '='},
                link: function (scope: any, element, attrs) {
                    scope.editMode = false;
                    scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'blue';
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    var fileKey = 1;

                    var resetAgreementForm = function () {
                        scope.editMode = false;
                        scope.agreementForm = {};
                        scope.agreementForm.index = -1;
                        scope.agreementForm.agreementType = {};
                        scope.agreementFile = undefined;
                        scope.agreementForm.fileKey = fileKey++;
                        scope.status = 'final';
                        scope.reference = null;

                        SessionApiFactory.getSysdate({},function(data){
                             scope.agreementForm.validFrom = data.value;
                        });

                        if (!scope.patientAgreemnetTypes) {
                            ClassifiersApiFactory.getAgreementsTypesForEncounter({}, function (data) {
                                var res = [];
                                angular.forEach(data.items, function (value) {
                                    var permitedForEncounter = _.find(value.relations, function (item) {
                                        return item.docId = 21 && item.relation != 0;
                                    });
                                    if (permitedForEncounter) {
                                        res.push(value);
                                    }
                                });
                                if (res && res.length == 1) {
                                    scope.agreementForm.agreementType = res[0];
                                }
                                scope.patientAgreemnetTypes = res;
                            });
                        } else {
                            if (scope.patientAgreemnetTypes.length == 1) {
                                scope.agreementForm.agreementType = scope.patientAgreemnetTypes[0];
                            }
                        }
                    };
                    resetAgreementForm();

                    scope.validator = new SubFormValidator('agreement-region');
                    scope.getCommissioner = function (key) {
                        return scope.relatedPersons[key];
                    };
                    //agreement logic
                    scope.indefiniteFlagChange = function(){
                        if(scope.agreementForm.indefinite){
                            scope.agreementForm.validTo = undefined;
                        }
                    }

                    scope.addAgreement = function (form) {
                        _.markAllAsTouched(form);
                        console.debug(scope.agreementFiles, scope.agreementForm.fileKey);
                        scope.validator.clear();
                        if (!scope.agreementForm.agreementType.id) {
                            scope.validator.add('agreementType');
                        }
                        if (!scope.agreementForm.validFrom || (!scope.agreementForm.indefinite && !scope.agreementForm.validTo && scope.agreementForm.agreementType && scope.agreementForm.agreementType.validity==1)) {
                            scope.validator.add('date');
                        }
                        if (!scope.agreementForm.description) {
                            scope.validator.add('description');
                        }
                        if(scope.agreementForm.agreementType && scope.agreementForm.agreementType.additionalDoc==1 && !scope.agreementFiles[scope.agreementForm.fileKey]){
                            scope.validator.add('file');
                        }
                        if (!scope.validator.hasErrors()) {
                            var item = {
                                dateCreated: null,
                                commisioner: scope.agreementForm.commisioner,
                                documentType: {
                                    name: scope.agreementForm.agreementType.title,
                                    code: scope.agreementForm.agreementType.id
                                },
                                validFrom: scope.agreementForm.validFrom,
                                validTo: scope.agreementForm.validTo,
                                description: scope.agreementForm.description,
                                indefinite: scope.agreementForm.indefinite,
                                reference: scope.agreementForm.reference,
                                status: scope.agreementForm.status,
                                fileKey: scope.agreementForm.fileKey
                            };
                            if(scope.agreementFile){
                                scope.agreementFiles[scope.agreementForm.fileKey] = scope.agreementFile;
                            }
                            SessionApiFactory.getSysdate({}, function (data) {
                                item.dateCreated = data.value;
                            });

                            if (scope.agreementForm.index == -1) {
                                scope.agreementList.push(item);
                            } else {
                                scope.agreementList.splice(scope.agreementForm.index, 1, item);
                            }
                            resetAgreementForm();
                            _.markAllAsUntouched(form);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    }

                    scope.cancelAgreementEdit = function () {
                        MessageService.clear('agreement-region');
                        resetAgreementForm();
                    }

                    var getAgreementType = function(id){
                        var agreement = {};
                        angular.forEach(scope.patientAgreemnetTypes, function (value) {
                            if(value.id == id){
                                agreement = value;
                            }
                        });
                        return agreement;
                    };
                    scope.isCancellable = function(a){
                        if(!a.fullId || a.cancelled){
                            return false;
                        }else {
                            var time = new Date().getTime();
                            var agType: any = getAgreementType(a.documentType.code);
                            var isNotForbidden = agType.cancellation != 3;
                            var isNotExpired = agType.cancellation == 2 && (!a.validTo || a.validTo >= time);
                            return a.fullId && !a.cancelled && isNotForbidden && isNotExpired;
                        }
                    }
                    scope.editAgreement = function (index, item) {
                        scope.editMode = true;
                        scope.agreementForm = {
                            index: index,
                            commisioner: item.commisioner,
                            agreementType: getAgreementType(item.documentType.code),
                            validFrom: item.validFrom,
                            validTo: item.validTo,
                            description: item.description,
                            indefinite: item.indefinite,
                            reference: item.reference,
                            status: item.status,
                            fileKey: item.fileKey
                        };
                        scope.agreementFile = scope.agreementFiles[scope.agreementForm.fileKey];
                    }

                    scope.onFileSelect = function (file) {
                        if (file) {
                            scope.agreementFiles[scope.agreementForm.fileKey] = file;
                        }
                    };

                    scope.removeAgreement = function (index) {
                        $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
                            scope.agreementList.splice(index, 1);
                        });
                    };

                    scope.removeAttachment = function () {
                        scope.agreementFiles[scope.agreementForm.fileKey] = undefined;
                    };
                    scope._ = _;
                }
            }
        }])
        .directive('viewAgreements',
    ['ClassifiersApiFactory',
     function (ClassifiersApiFactory) {
            return {
                templateUrl: '/templates/documents/components/view/view_agreements.html',
                restrict: 'E',
                replace: false,
                transclude: true,
                scope: {agreementList: '='},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'blue';
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                }
            };
        }])
        .directive('viewAgreementsSm',
    ['DocumentsApiFactory',
     function (DocumentsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/view/view_agreements_small.html',
                restrict: 'E',
                replace: false,
                transclude: true,
                scope: {compositionId: '=', iconBgClass:'@'},
                link: function (scope: any, element, attrs) {
                    scope._ = _;
                    scope.closed = attrs.closed ? attrs.closed : 'false';

                    var load = function () {
                        scope.dataLoaded = false;
                        scope.agreements = DocumentsApiFactory.getAgreements({compositionId: scope.compositionId}, function (data) {
                            scope.dataLoaded = true;
                        }, function () {
                            scope.dataLoaded = true;
                        });
                    };

                    scope.$on('widget_toggle', function (event, data) {
                        if (!scope.dataLoaded) {
                            load();
                        }
                    });
                }
            };
        }])
        .directive('editComments',
    ['EntitiesApiFactory', 'SessionApiFactory', 'SubFormValidator',
     function (EntitiesApiFactory, SessionApiFactory, SubFormValidator) {
            return {
                templateUrl: '/templates/documents/components/edit/edit_comments.html',
                restrict: 'E',
                replace: false,
                scope: {
                    comments: "=comments"
                },
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;
                    scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'green';

                    var newComment = function () {
                        return {
                            active: true,
                            dateCreated: new Date().getTime(),
                            visibilities: [],
                            visibleFor: {}
                        };
                    };

                    var findVisibilityType = function (code) {
                        return _.find(scope.commentsVisibilities, function (val) {
                            return code == val.code
                        });
                    };

                    var findCommentType = function (code) {
                        return _.find(scope.commentTypes, function (val) {
                            return code == val.code
                        });
                    };

                    scope.validator = new SubFormValidator('comment-region');

                    scope.data = {};
                    scope.comment = newComment();
                    scope.practitioner = SessionApiFactory.getPractitioner();
                    scope.commentTypes = EntitiesApiFactory.getList({classCode: 'comment-alert-type'});
                    scope.commentsVisibilities = [
                        {
                            code: '0',
                            name: 'Asmeninis'
                        },
                        {
                            code: '1',
                            name: 'Pacientams'
                        },
                        {
                            code: '2',
                            name: 'Specialistams'
                        }
                    ];

                    scope.onVisibilityChange = function () {
                        scope.comment.visibilities = [];
                        _.each(scope.comment.visibleFor, function (val, key) {
                            var type = findVisibilityType(key);
                            if (type) {
                                scope.comment.visibilities.push(type);
                            }
                        });
                    };

                    scope.add = function () {
                        scope.validator.clear()

                        if (!scope.comment.validFrom) {
                            scope.validator.add('valid');
                        }

                        if (!scope.comment.termless && !scope.comment.validTo) {
                            scope.validator.add('valid');
                        }

                        if (!scope.comment.note) {
                            scope.validator.add('note');
                        }

                        if (!scope.validator.hasErrors()) {
                            scope.comment.commentType = findCommentType('3');
                            if (scope.comment.visibilities.length === 0) {
                                scope.comment.visibilities.push(findVisibilityType('0'));
                            }
                            scope.comments.push(scope.comment);
                            console.debug(scope.comment);
                            scope.comment = newComment();
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    };

                    scope.termlessChange = function () {
                        if (scope.comment.termless) {
                            scope.comment.validTo = undefined;
                        }
                    };

                    scope.canCancel = function (index) {
                        var pract = scope.practitioner,
                                com = scope.comments[index];
                        return pract && com.practitioner && com.practitioner.id == pract.id && com.active;
                    };

                    scope.cancel = function (index) {
                        scope.comments[index].active = false;
                    };
                }
            };
        }])
        .directive('viewComments', function () {
            return {
                templateUrl: '/templates/documents/components/view/view_comments.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'green';
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                }
            };
        })
        .directive('viewMorRes',
    ['DocumentsApiFactory', '$filter',
     function (DocumentsApiFactory, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_morphological_researches.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.mor.res'),
                            id: 'doc_mor_res'
                        };
                    }

                    scope.formType = attrs.formType;

                    scope.count = 7;
                    scope.loadedItems = [];

                    scope.$watch('form.performedTests', function () {
                        if (scope.form.performedTests && _.keys(scope.form.performedTests).length > 0) {
                            var query: any = {
                                docReferences: []
                            };
                            _.each(scope.form.performedTests, function (val, key) {
                                if (val) {
                                    var start = key.indexOf('/'),
                                            end = key.indexOf('/', start + 1);
                                    query.docReferences.push(key.substring(start + 1, end));
                                }
                            });
                            query.count = query.docReferences.length;
                            scope.loadedItems = DocumentsApiFactory.searchForList(query);
                        }
                    });

                    scope.showLoadMore = function () {
                        return scope.loadedItems.length > 0 && scope.loadedItems.length > scope.count;
                    };

                    scope.loadMoreDocs = function () {
                        scope.count += 7;
                    };
                }
            };
        }])
        .directive('viewMatInf',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_material_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.mat.inf'),
                            id: 'doc_mat_inf'
                        };
                    }
                }
            };
        }])
        .directive('viewExtPat',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_extra_pathology_data.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.ext.pat'),
                            id: 'doc_ext_pat'
                        };
                    }
                }
            };
        }])
        .directive('viewPatTest',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_patalogy_test_choose.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pat.tes'),
                            id: 'doc_pat_tes'
                        };
                    }
                }
            };
        }])
        .directive('viewPatRes',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_patalogy_results.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pat.res'),
                            id: 'doc_pat_res'
                        };
                    }
                }
            };
        }])
        .directive('viewMainDia',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_main_diagnosis.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.mai.dia'),
                            id: 'doc_mai_dia'
                        };
                    }
                }
            };
        }])
        .directive('viewProInf',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_procedures_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] = {
                            title: $filter('translate')('doc.pro.inf'),
                            id: 'doc_pro_inf'
                        };
                    }
                }
            };
        }])
        .directive('viewLaboratoryResearch', function () {
            return {
                templateUrl: '/templates/documents/components/view/view_laboratory_research.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                }
            };
        })
        .directive('viewObservation',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_observation.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.obs'),
                            id: 'doc_obs',
                            show: function () {
                               return !scope.form.alertMessageItem;
                            }
                        };
                    }
                }
            };
        }])
        .directive('viewObservationChooce',
    ['$routeParams', '$filter',
     function ($routeParams, $filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_observation_chooce.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.obs.cho'),
                            id: 'doc_obs_cho'
                        };
                    }

                    scope.formType = attrs.formType;

                    scope.patientId = $routeParams.patientId;
                }
            };
        }])
        .directive('viewPatObs',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_pathology_observation.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {

                    //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.pat.obs'),
                            id: 'doc_pat_obs'
                        };
                    }
                    scope.newTab = attrs.newTab;
                    scope.formType = attrs.formType;
                }
            };
        }])
        .directive('viewImpMed',
    ['$filter',
     function ($filter) {
            return {
                templateUrl: '/templates/documents/components/view/view_important_medical_information.html',
                restrict: 'E',
                replace: false,
                scope: true,
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;

                     //add block to document content
                    if (scope._shared.contentList) {
                        scope._shared.contentList[attrs.contentIndex] ={
                            title: $filter('translate')('doc.imp.med'),
                            id: 'doc_imp_med',
                            show: function () {
                                if (scope.form.importantMedicalData) {
                                    return true ;
                                } else {
                                    return false ;
                                }
                            }
                        };
                    }
                }
            };
        }])
        .directive('editBinaryFiles', function () {
            return {
                templateUrl: '/templates/documents/components/edit/edit_binary_files.html',
                restrict: 'E',
                replace: false,
                scope: {},
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.formType = attrs.formType;

                    scope.binaryFileTypes = [{
                            id: 1,
                            code: 1,
                            title: "Ankstesni nusiskundimai",
                            description: "Paciento pateikta informacija apie ankstesnius, ESPBI IS nefiksuotus sveikatos įvykius"
                        }, {
                            id: 2,
                            code: 2,
                            title: "Operacijos protokolas",
                            description: "Operacijos protokolas"
                        }, {
                            id: 3,
                            code: 3,
                            title: "Kita",
                            description: "Kitas priedas"
                        }];
                }
            };
        })
        .directive('viewBinaryFiles',
    ['AttachmentsApiFactory',
     function (AttachmentsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/view/view_binary_files.html',
                restrict: 'E',
                replace: false,
                scope: {
                    compositionId: '='
                },
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.closed = attrs.closed ? attrs.closed : 'false';

                    var load = function () {
                        scope.dataLoaded = false;
                        AttachmentsApiFactory.getAttachments({compositionId: scope.compositionId, count: -1}, function (data) {
                            scope.attachments = data.items;
                            scope.totalCount = data.total;
                            scope.dataLoaded = true;
                        }, function () {
                            scope.dataLoaded = true;
                        });
                    };
                    scope.$on('widget_toggle', function (event, data) {
                        if (!scope.dataLoaded) {
                            load();
                        }
                    });
                }
            };
        }])
        .directive('viewEditBinaryFiles',
    ['$filter', 'ClassifiersApiFactory', 'AttachmentsApiFactory', 'AttachmentsUploader', 'SubFormValidator', 'MessageService', 'DocumentSpinnerHelper', '$modal',
     function ($filter, ClassifiersApiFactory, AttachmentsApiFactory, AttachmentsUploader, SubFormValidator, MessageService, DocumentSpinnerHelper, $modal) {
            return {
                templateUrl: '/templates/documents/components/view_edit/binary_files.html',
                restrict: 'E',
                replace: false,
                scope: {
                    compositionId: '=',
                    docType: '='
                },
                transclude: true,
                link: function (scope: any, element, attrs) {


                    scope.formType = attrs.formType;
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'green';

                    var newFile = function () {
                        scope.file = undefined;
                        scope.data.addFile = true;
                        scope.form = {compositionId: scope.compositionId};
                        if (scope.binaryFileTypes.length == 1) {
                            scope.form.type = {code: scope.binaryFileTypes[0].id, name: scope.binaryFileTypes[0].title};
                            scope.form.typeNote = scope.binaryFileTypes[0].note;
                        }
                    };

                    var validate = function () {
                        if (!scope.form.type) {
                            scope.validator.add('type');
                        }
                        if (!scope.file) {
                            scope.validator.add('file');
                        }
                        return !scope.validator.hasErrors();
                    };

                    var reload = function () {
                        MessageService.clear("doc-attachment");
                        scope.dataLoaded = false;
                        AttachmentsApiFactory.getAttachments({compositionId: scope.compositionId, count: -1}, function (data) {
                            scope.attachments = data.items;
                            scope.totalCount = data.total;
                            scope.dataLoaded = true;
                            scope.data.selected = data.total === 0 ? ($filter('acWrite')('AttachmentList') ? 2 : 1) : 1;
                        });
                    };

                    var modalCancel = $modal({
                        scope: scope,
                        title: $filter('translate')('mdl.add.rea.doc'),
                        contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel.html'
                    });

                    scope._ = _;
                    scope.data = {addFile: true, selected: 1};
                    scope.documentCancelReason = {};
                    scope.attachments = [];
                    scope.totalCount = 0;
                    scope.dataLoaded = false;
                    scope.uploading = false;
                    scope.validator = new SubFormValidator("doc-attachment");
                    scope.docSpinnerHelper = DocumentSpinnerHelper;

                    scope.onTypeChange = function () {
                        scope.form.type = {code: scope.form.tmpType.id, name: scope.form.tmpType.title};
                        scope.form.typeNote = scope.form.tmpType.note;
                    };

                    scope.$watch('compositionId', function () {
                        if (scope.compositionId) {
                            ClassifiersApiFactory.getAttachmentTypesByDocType({docType: scope.docType}, function (data) {
                                scope.binaryFileTypes = data.items;
                                newFile();
                            });
                        }
                    });

                    scope.$on('widget_toggle', function (event, data) {
                        if (scope.attachments && !scope.dataLoaded) {
                            reload();
                        }
                    });

                    scope.onFileSelect = function (file) {
                        if (file) {
                            scope.file = file;
                            scope.data.addFile = false;
                        }
                    };

                    scope.removeFile = function () {
                        scope.file = undefined;
                        scope.data.addFile = true;
                    };

                    scope.showRemoveDialog = function (index) {
                        scope.indecForRemove = index;
                        modalCancel.$promise.then(function () {
                            modalCancel.show();
                        });
                    };

                    scope.doCancel = function () {
                        scope.docSpinnerHelper.startLoading();
                        var cancelReasonText = scope.documentCancelReason.text;
                        AttachmentsApiFactory.removeAttachment({attachmentId: scope.attachments[scope.indecForRemove].id}, {reason: cancelReasonText}, function () {
                            scope.docSpinnerHelper.endLoading();
                            scope.attachments.splice(scope.indecForRemove, 1);
                            scope.totalCount--;
                            modalCancel.hide();
                        }, function () {
                            scope.docSpinnerHelper.endLoading();
                            modalCancel.hide();
                        });
                    };

                    scope.upload = function () {
                        scope.validator.clear();

                        var success = function () {
                            scope.data.selected = 1;
                            scope.uploading = false;
                            newFile();
                            reload();
                        };

                        var error = function () {
                            scope.uploading = false;
                        };

                        if (validate()) {
                            scope.uploading = true;
                            AttachmentsUploader.upload(scope.compositionId, scope.form, scope.file, success, error);
                        } else {
                            scope.validator.showErrorsAndScroll();
                        }
                    };

                }
            };
        }])
        .directive('viewEditAgreements',
    ['SessionApiFactory', 'ClassifiersApiFactory', 'DocumentsApiFactory', 'AgreementUploader', 'MessageService', 'SubFormValidator', 'PatientsApiFactory',
     function (SessionApiFactory, ClassifiersApiFactory, DocumentsApiFactory, AgreementUploader, MessageService, SubFormValidator, PatientsApiFactory) {
            return {
                templateUrl: '/templates/documents/components/view_edit/agreements.html',
                restrict: 'E',
                replace: false,
                scope: {compositionId: '=', docType: '=', patientId: '='},
                transclude: true,
                link: function (scope: any, element, attrs) {
                    scope.formType = attrs.formType;
                    scope.closed = attrs.closed ? attrs.closed : 'false';
                    scope.iconBgClass = attrs.iconBgClass ? attrs.iconBgClass : 'green';
                    scope.data = {};
                    scope.data.loaded = false;
                    scope.validator = new SubFormValidator('document-agreements');
                    var reloadAgreements = function () {
                        scope.data.tab = 'loading';
                        scope.agreements = DocumentsApiFactory.getAgreements({compositionId: scope.compositionId}, function (data) {

                            scope.data.tab = data.total > 0 ? 'list' : 'new';
                            scope.data.loaded = true;
                        }, function () {
                            scope.data.tab = 'list';
                        });
                    };

                    var newAgreement = function () {
                        scope.validator.clear();
                        MessageService.clear('document-agreements');
                        scope.form = {};
                        scope.file = undefined;
                        scope.fileSelected = false;
                        if (scope.agreementsTypes.total === 1) {
                            scope.form.type = scope.agreementsTypes.items[0];
                            scope.form.documentType = {name: scope.form.type.title, code: scope.form.type.id};
                            scope.onTypeChange(scope.form.type);
                        }
                    };

                    var validate = function (form) {
                        scope.validator.clear();
                        if (!form.description) {
                            scope.validator.add('description');
                        }
                        if (!form.documentType) {
                            scope.validator.add('documentType');
                        } else {
                            if (form.type.validity == 1 && !form.indefinite && !form.validFrom) {
                                scope.validator.add('validPeriod');
                            }

                            if (form.type.additionalDoc == 1 && !scope.file) {
                                scope.validator.add('file');
                            }
                        }

                        return !scope.validator.hasErrors();
                    };

                    scope.$watch('docType', function () {
                        if (scope.docType) {
                            scope.agreementsTypes = ClassifiersApiFactory.getAgreementsTypesByDocType({docType: scope.docType}, newAgreement);
                        }
                    });

                    scope.$on('widget_toggle', function (event, data) {
                        if (!scope.data.loaded) {
                            scope.patient = PatientsApiFactory.getWithRelatedPersons({id: scope.patientId});
                            scope.alive = function () {
                                return _.filter(_.values(scope.patient.relatedPersons), function (value) {
                                    return !value.deathDate;
                                });
                            };
                            reloadAgreements();
                        }
                    });

                    scope.showPeriodBlock = function () {
                        return !scope.form || !scope.form.type || scope.form.type.validity == 1;
                    };

                    scope.onIndefiniteChange = function () {
                        if (scope.form.indefinite) {
                            scope.form.validTo = undefined;
                        }
                    };

                    scope.onTypeChange = function (type) {
                        scope.form.documentType = {code: type.id, name: type.title};
                        if (type.validity == 2) {
                            SessionApiFactory.getSysdate({}, function(data){
                                scope.form.validFrom = data.value;
                            });
                            scope.form.validTo = undefined;
                        }
                    };



                    scope.onFileSelect = function (file) {
                        if (file) {
                            scope.fileSelected = true;
                            scope.file = file;
                        }
                    };

                    scope.removeFile = function (file) {
                        scope.file = undefined;
                        scope.fileSelected = false;
                    };

                    scope.saveAgreement = function () {
                        if (validate(scope.form)) {
                            AgreementUploader.upload(scope.compositionId, scope.form, scope.file, function () {
                                newAgreement();
                                reloadAgreements();
                                MessageService.clear('document-agreements');
                            }, function (error) {
                                angular.forEach(error.fieldErrors, function (e) {
                                    MessageService.error(e.propertyPath + ' ' + e.msg, 'document-agreements');
                                });
                            });
                        } else {
                            MessageService.error('Forma turi klaidų.', 'document-agreements');
                        }
                    };
                }
            };
        }]);
