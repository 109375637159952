export enum Researches {
    BloodTests = "Kraujo tyrimai",
    Antibody = "Antikūnų tyrimai",
    BloodTest = "Kraujo tyrimas",
    Syphilis = "Tyrimas dėl sifilio (RPR)",
    HIV = "Tyrimas dėl ŽIV",
    Glucose = "Gliukozės kraujo plazmoje tyrimas",
    HepatitisB = "Tyrimas dėl hepatito B",
    UrineAsymptomaticBacteriuria = "Šlapimo pasėlio tyrimas dėl besimptomės bakteriurijos",
    GTM = "Gliukozės toleravimo mėginys (GTM)",
    GBS = "Pasėlio tyrimas dėl naujagimių B grupės streptokoko (BGS) infekcijos rizikos",
    Urine = "Šlapimo tyrimas",
}

export enum Procedures {
    AntiDImmunoglobulin = "Anti-D imunoglobulinas",
    PrenatalScreening = "Prenatalinė chromosomų anomalijų patikra",
    PrenatalDiagnostics = "Prenatalinė diagnostika",
    FetalSurgicalProcedures = "Vaisiaus chirurginės procedūros",
    FetalLungMaturation = "Vaisiaus plaučių brandinimas",
    TocolysisDuringPregnancy = "Tokolizė nėštumo metu",
    Other = "Kita svarbi informacija",
}

export const ResearchesDateFieldTitles: { [key in Researches]: string } = {
    [Researches.BloodTests]: "Kraujo tyrimo data",
    [Researches.Antibody]: "Antikūnų tyrimo data",
    [Researches.BloodTest]: "Kraujo tyrimo data",
    [Researches.Syphilis]: "RPR tyrimo data",
    [Researches.HIV]: "ŽIV tyrimo data",
    [Researches.Glucose]: "Gliukozės kraujo plazmoje tyrimo data",
    [Researches.HepatitisB]: "Hepatito B tyrimo data",
    [Researches.UrineAsymptomaticBacteriuria]: "Šlapimo pasėlio tyrimo dėl besimptomės bakteriurijos data",
    [Researches.GTM]: "GTM data",
    [Researches.GBS]: "Pasėlio tyrimas dėl BGS data",
    [Researches.Urine]: "Šlapimo tyrimo data",
};

export const ProceduresDateFieldTitles = {
    [Procedures.AntiDImmunoglobulin]: "Anti-D imunoglobulino procedūros data ir laikas",
    [Procedures.PrenatalScreening]: "Prenatalinė chromosomų anomalijų patikros rezultato data",
    [Procedures.PrenatalDiagnostics]: "Choriono gaurelių biopsijos diagnozės data",
};

export enum ResearchesFieldsTitles {
    PregnantBloodType = "Kraujo grupė (Nėščioji)",
    FatherBloodGroup = "Kraujo grupė (Vaiko biologinio tėvo)",
    AntibodyTestResult = "Antikūnų tyrimo rezultatas",
    AntibodyTiter = "Antikūnų titras",
    BloodErythrocytes = "Kraujo eritrocitai",
    BloodHemoglobin = "Kraujo hemoglobinas",
    BloodHematocrit = "Kraujo hematokritas",
    BloodLeukocytes = "Kraujo leukocitai",
    BloodPlatelets = "Kraujo trombocitai",
    RPRTestResult = "RPR tyrimo rezultatas",
    RPRStudyTiter = "RPR tyrimo titras",
    HIVTestResult = "ŽIV tyrimo rezultatas",
    BloodPlasmaGlucoseTestResult = "Gliukozės kraujo plazmoje tyrimo rezultatas",
    HepatitisBResult = "Hepatito B tyrimo rezultatas",
    AsymptomaticBacteriuriaResult = "Šlapimo pasėlio tyrimo dėl besimptomės bakteriurijos rezultatas",
    GTMResult = "GTM rezultatas",
    GTMI = "GTM I",
    GTMII = "GTM II",
    GTMIII = "GTM III",
    CropBGSTestResults = "Pasėlio tyrimas dėl BGS rezultatai",
    UrineProtein = "Šlapimo baltymas",
    UrineLeukocytes = "Šlapimo leukocitai",
    UrineErythrocytes = "Šlapimo eritrocitai",
    UrineGlucose = "Šlapimo gliukozė",
    UrineKetones = "Šlapimo ketonai",
    UrinaryNitrites = "Šlapimo nitritai",
}
export enum ProceduresFieldsTitles {
    InjectedAntiDImmunoglobulin = "Sušvirkštas Anti-D imunoglobulinas",
    PrenatalScreeningResult = "Prenatalinė chromosomų anomalijų patikros rezultatas",
    AmniocentesisDiagnosisDate = "Amniocentezė diagnozės data",
    CordocentesisDiagnosisDate = "Kordocentezė diagnozės data",
    StandardFetalSurgicalProcedure = "Standartinės vaisiaus chirurginės procedūros",
    OtherFetalSurgicalProcedure = "Kitos vaisiaus chirurginės procedūros",
    FetalLungMaturation = "Vaisiaus plaučių brandinimas",
    PregnancyWeek = "Nėštumo savaitė",
}

export enum Fields {
    Date = "date",
}

export enum PrenatalScreeningResults {
    CombinedTest = "Kombinuotasis testas",
    TripleTest = "Trigubas testas",
    FetalDNATesting = "Laisvos vaisiaus DNR tyrimas",
}

export enum StandardFetalSurgicalProcedures {
    BloodTransfusion = "Kraujo transfuzija",
    LaserProcedureForDTS = "Lazerio procedūra esant DTS",
    StentPlacement = "Stento įdėjimas",
}

export enum TocolysisDuringPregnancy {
    BloodTransfusion = "Kraujo transfuzija",
    LaserProcedureForDTS = "Lazerio procedūra esant DTS",
    StentPlacement = "Stento įdėjimas",
}

export enum CropBGSTest {
    Investigated = "Tirta",
    NotInvestigated = "Netirta",
}

export const CropBGSTestResults: { [key in CropBGSTest]: string[] } = {
    [CropBGSTest.Investigated]: ["Nerasta", "Rasta"],
    [CropBGSTest.NotInvestigated]: ["Gimdymo metu yra rizika", "Nėra rizikos"],
};

export enum AsymptomaticBacteriuriaResults {
    Norm = "Norma",
    More = "Bakterijų ≥ 10^5 KVF/ml",
}

export const BloodGroups = [
    {
        id: 1,
        groupEu: "O",
        group: "I",
        rhesus: "-",
    },
    {
        id: 2,
        groupEu: "O",
        group: "I",
        rhesus: "+",
    },
    {
        id: 3,
        groupEu: "A",
        group: "II",
        rhesus: "-",
    },
    {
        id: 4,
        groupEu: "A",
        group: "II",
        rhesus: "+",
    },
    {
        id: 5,
        groupEu: "B",
        group: "III",
        rhesus: "-",
    },
    {
        id: 6,
        groupEu: "B",
        group: "III",
        rhesus: "+",
    },
    {
        id: 7,
        groupEu: "AB",
        group: "IV",
        rhesus: "-",
    },
    {
        id: 8,
        groupEu: "AB",
        group: "IV",
        rhesus: "+",
    },
];

export const ResearchesViewHeaderTitles: { [key in Researches]: string[] } = {
    [Researches.BloodTests]: [
        "Kraujo tyrimo data",
        "Kraujo grupė (Nėščioji)",
        "Kraujo grupė (Vaiko biologinio tėvo)",
        "Įvedė",
    ],
    [Researches.Antibody]: ["Antikūnų tyrimo data", "Antikūnų tyrimo rezultatas", "Antikūnų titras", "Įvedė"],
    [Researches.BloodTest]: [
        "Kraujo tyrimo data",
        "Kraujo eritrocitai *10^12/l",
        "Kraujo hemoglobinas g/l",
        "Kraujo hematokritas",
        "Kraujo leukocitai *10^9/l",
        "Kraujo trombocitai *10^9/l",
        "Įvedė",
    ],
    [Researches.Syphilis]: ["RPR tyrimo data", "RPR tyrimo rezultatas", "RPR tyrimo titras", "Įvedė"],
    [Researches.HIV]: ["ŽIV tyrimo data", "ŽIV tyrimo rezultatas", "Įvedė"],
    [Researches.Glucose]: [
        "Gliukozės kraujo plazmoje tyrimo data",
        "Gliukozės kraujo plazmoje tyrimo rezultatas",
        "Įvedė",
    ],
    [Researches.HepatitisB]: ["Hepatito B tyrimo data", "Hepatito B tyrimo rezultatas", "Įvedė"],
    [Researches.UrineAsymptomaticBacteriuria]: [
        "Šlapimo pasėlio tyrimo dėl besimptomės bakteriurijos data",
        "Šlapimo pasėlio tyrimo dėl besimptomės bakteriurijos rezultatas",
        "Įvedė",
    ],
    [Researches.GTM]: ["GTM data", "GTM rezultatas", "GTM I (mmol/l)", "GTM II (mmol/l)", "GTM III (mmol/l)", "Įvedė"],
    [Researches.GBS]: ["Pasėlio tyrimas dėl BGS data", "Pasėlio tyrimas dėl BGS rezultatai", "Įvedė"],
    [Researches.Urine]: [
        "Šlapimo tyrimo data",
        "Šlapimo baltymas",
        "Šlapimo leukocitai",
        "Šlapimo eritrocitai",
        "Šlapimo gliukozė",
        "Šlapimo ketonai",
        "Šlapimo nitritai",
        "Įvedė",
    ],
};

export const ProceduresViewHeaderTitles: { [key in Procedures]: string[] } = {
    [Procedures.AntiDImmunoglobulin]: [
        "Anti-D imunoglobulino procedūros data ir laikas",
        "Sušvirkštas Anti-D imunoglobulinas",
        "Įvedė",
    ],
    [Procedures.PrenatalScreening]: [
        "Prenatalinė chromosomų anomalijų patikros rezultato  data",
        "Prenatalinė chromosomų anomalijų patikros rezultatas",
        "Įvedė",
    ],
    [Procedures.PrenatalDiagnostics]: [
        "Choriono gaurelių biopsijos diagnozės data",
        "Amniocentezė diagnozės data",
        "Kordocentezė diagnozės data",
        "Įvedė",
    ],
    [Procedures.FetalSurgicalProcedures]: [
        "Standartinės vaisiaus chirurginės procedūros",
        "Kitos vaisiaus chirurginės procedūros",
        "Įvedė",
    ],
    [Procedures.FetalLungMaturation]: ["Vaisiaus plaučių brandinimas", "Nėštumo savaitė", "Įvedė"],
    [Procedures.TocolysisDuringPregnancy]: ["Tokolizė nėštumo metu", "Įvedė"],
    [Procedures.Other]: ["Kita svarbi informacija", "Įvedė"],
};
