<app-title [backPath]="backUrl" backSubtitle="pp.dia.sea.title" showFilterButton="true">
    {{ "doc.dia" | translate }}
</app-title>
<div class="widget">
    <app-diagnosis-page-filters (filtering)="updateFilters($event)"></app-diagnosis-page-filters>
    <div class="widget-body" [class]="diagnosesHistory?.length ? ' p-0' : ''">
        <ol *ngIf="diagnosesHistory?.length" class="slats hover">
            <li
                *ngFor="let diagnose of diagnosesHistory; let i = index"
                [class.bg-success-subtle]="diagnose.type?.id === 'primary'"
                slat-item
                [edit]="isEditable"
                (editButtonClick)="openEditDiagnoseModal(index)"
                [editTitle]="'doc.dia.edt' | translate"
            >
                <div class="row flex-wrap-reverse flex-md-wrap">
                    <div
                        *ngIf="!!diagnose.onsetPeriodStart || !!diagnose.onsetPeriodEnd"
                        class="col-md-auto text-md-center text-secondary text-nowrap mb-1 mb-md-0"
                    >
                        <span *ngIf="!!diagnose.onsetPeriodStart">{{
                            diagnose.onsetPeriodStart | date : "YYYY-MM-dd"
                        }}</span>
                        <span *ngIf="!!diagnose.onsetPeriodEnd">
                            - {{ diagnose.onsetPeriodEnd | date : "YYYY-MM-dd" }}</span
                        >
                    </div>
                    <div class="col ps-md-0 mb-md-1">
                        <strong>{{ diagnose.code.id + " " + diagnose.code.name }}</strong>
                    </div>
                </div>
                <div class="row small">
                    <div class="col-sm-6 col-md-4 col-xl text-nowrap d-flex align-items-center">
                        <span class="text-secondary me-2">{{ "doc.dia.sts" | translate }}:</span>
                        <ng-container *ngIf="diagnose.clinicalStatus">
                            <div class="d-inline position-relative p-2">
                                <span class="position-absolute p-2 translate-middle rounded-circle bg-success">
                                    <span class="visually-hidden">{{
                                        diagnose.clinicalStatus.name ?? "-" | capitalize
                                    }}</span>
                                </span>
                            </div>
                            <!-- TODO-design: should we use an icon instead? If not delete this comment -->
                            <!-- <i class="fa-regular fa-circle-dot text-success fs-3 my-n1"></i> -->
                            <strong class="ps-1">{{ diagnose.clinicalStatus.name ?? "-" | capitalize }}</strong>
                        </ng-container>
                        <ng-container *ngIf="!diagnose.clinicalStatus">
                            <strong>-</strong>
                        </ng-container>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                        <span class="text-secondary">{{ "doc.dia.con" | translate }}: </span>
                        <ng-container *ngIf="diagnose.verificationStatus">
                            <strong>{{ diagnose.verificationStatus.name | capitalize }}</strong>
                        </ng-container>
                        <ng-container *ngIf="!diagnose.verificationStatus">
                            <strong>-</strong>
                        </ng-container>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                        <span class="text-secondary">{{ "doc.dia.sev" | translate }}: </span>
                        <ng-container *ngIf="diagnose.severity">
                            <strong>{{ diagnose.severity.name | capitalize }}</strong>
                        </ng-container>
                        <ng-container *ngIf="!diagnose.severity">
                            <strong>-</strong>
                        </ng-container>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                        <span class="text-secondary">{{ "doc.dia.cat" | translate }}: </span>
                        <ng-container *ngIf="diagnose.category">
                            <strong>{{ diagnose.category.name | capitalize }}</strong>
                        </ng-container>
                        <ng-container *ngIf="!diagnose.category">
                            <strong>-</strong>
                        </ng-container>
                    </div>
                </div>
                <div class="description small mt-2">
                    <strong> {{ "doc.dia.desc" | translate }}: </strong>
                    <span class="text-secondary">
                        {{ diagnose?.notes ?? "-" }}
                    </span>
                </div>
                <div class="circumstances small mb-2">
                    <strong> {{ "doc.dia.cir" | translate }}: </strong>
                    <span class="text-secondary">
                        {{ diagnose?.decisionCircumstances ?? "-" }}
                    </span>
                </div>
            </li>
        </ol>
        <ng-container *ngIf="!diagnosesHistory?.length">
            <span>{{
                (diagnosesFullHistory?.length ? "pp.dia.sea.noDiagnosisForFilter" : "pp.dia.sea.noDiagnosis")
                    | translate
            }}</span>
        </ng-container>

        <spinner [working]="!isLoaded"></spinner>
    </div>
</div>

<pagination-bar
    #paginationBar
    [rowsCount]="total"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>
