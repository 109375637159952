<div *ngIf="this.textCellModel.simpleText" [tooltip]="this.textCellModel.simpleText.toLowerCase() | translate"
     placement="bottom" container="body">
    {{ this.textCellModel.simpleText }}
</div>
<div class="d-flex" *ngFor="let mainText of this.textCellModel.mainText">
    <div class="me-auto" *ngIf="mainText?.key">{{ mainText?.key }}</div>
    <div class="ms-2" *ngIf="mainText?.value">{{ mainText?.value }}</div>
    <div class="justify-content-start" *ngIf="!mainText?.key">{{ mainText }}</div>
</div>
<div class="d-flex text-secondary" *ngFor="let additionalText of this.textCellModel.additionalText">
    <div class="me-auto" *ngIf="additionalText?.key">{{ additionalText?.key }}</div>
    <div class="ms-2" *ngIf="additionalText?.value">{{ additionalText?.value }}</div>
    <div class="justify-content-start" *ngIf="!additionalText?.key">{{ additionalText }}</div>
</div>
<div class="d-flex" *ngFor="let linkText of this.textCellModel.linkText">
    <button class="btn btn-link" role="button" (click)="onLinkClick(linkText.onClickExpand)">
        <div class="d-flex justify-content-between">
            <div *ngIf="linkText?.formattedText?.key">{{ linkText?.formattedText.key }}</div>
            <div class="ms-2" *ngIf="linkText?.formattedText?.value">{{ linkText?.formattedText.value }}</div>
            <div class="justify-content-center"
                 *ngIf="!linkText?.formattedText?.key && !linkText?.formattedText?.value">
                {{ linkText?.formattedText }}
            </div>
        </div>
    </button>
</div>