ng1App.controller('doctor.patient.cert.e103-1ViewCtrl', 
    ['$scope', 'AsideBlockModel',
     function ($scope, AsideBlockModel) {
	$scope.certificate.$promise.then(function () {
		$scope.mother = $scope.certificate.mother;
	});
	$scope._shared = {
		contentList: [
			new AsideBlockModel('cer.1031.mai.inf'),
			new AsideBlockModel('cer.1031.doc')
		]
	};
}]);