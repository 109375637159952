import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeSelectService {
    private openModalSource = new Subject<void>();
    openModal$ = this.openModalSource.asObservable();

    constructor() {
    }

    openDocumentTypeSelectModal(encounter?: any) {
        this.openModalSource.next(encounter);
    }
}