angular.module('erx.utils').directive('erxDrugInteractions', ["$rootScope", "$erxModalDrugInteractions", "ErxPrescriptionsApiFactory", "ErxDrugInteractionApiFactory", function ($rootScope, $erxModalDrugInteractions, ErxPrescriptionsApiFactory, ErxDrugInteractionApiFactory) {
  return {
    templateUrl: 'modules/erx-utils/components/erx-drug-interactions/erxDrugInteractions.tpl.html',
    restrict: 'E',
    replace: false,
    scope: {patientId: '='},
    link: function (scope: any, element, attrs) {
        function initDrugInteraction() {
            delete scope.activeSubstances;
            delete scope.pharmForm;
            scope.drugInteractions = {};
        }

        scope.checkDrugInteraction = function() {
            ErxDrugInteractionApiFactory.setLastDrugInteractionQueryId('#');
            scope.drugInteractions = ErxDrugInteractionApiFactory.getServices().getDrugInteractions({patientId: scope.patientId, activeSubstances: scope.activeSubstances, pharmForm: scope.pharmForm});
            scope.drugInteractions.$promise.then(
                function (res) {
                    ErxDrugInteractionApiFactory.setLastDrugInteractionQueryId(res.drugInteractionQueryId);
                }
            );
        }
        scope.showDrugInteraction = function(idx) {
            $erxModalDrugInteractions.show(scope.drugInteractions.result, idx);
        };

        scope.$on("drg:selected", function (e, activeSubstances, pharmForm) {
            scope.activeSubstances = activeSubstances;
            scope.pharmForm = pharmForm;
            scope.checkDrugInteraction();
        });
        scope.$on("drg:cleared", function (e) {
            initDrugInteraction();
        });
        initDrugInteraction();
    }
  };
}]);