ng1App.controller('document.E027aViewCtrl',
    ['$scope', '$routeParams', '$filter',
        function ($scope, $routeParams, $filter) {
            $scope.documentId = $routeParams.documentId;
            $scope.patientId = $routeParams.patientId;
            $scope.currentDiagnoses = $scope.form.partDiagnosis;

            // Side content list
            $scope._shared = {
                // jus fill array with something
                contentList: _.range(12)
            }

            $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id: 'doc_mai_inf'};

            $scope.byOrder = true;
            $scope.form.$promise.then(function (form) {
                $scope.byOrder = form.orderData.item.targetQualification || form.orderData.item.departmentSpecialization;
            });
        }]);