import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../../api/api-v2-doc-pregnancy-dashboard";
import { RouteParams } from "../../../../../ng1.routeParams";
import { addDaysToDate } from "../../../../../shared/services/utils/utils";
import { allergies, vaccinations } from "../../../mocks/general-pregnancy-info.mock";
import { Anamnesis, FamilyAnamnesis, PregnancyInfo } from "../../../models/pregnancyTerminationInfo.model";
import {
    FoilUsingNames,
    MenstrualCycles,
    calculateLastWomansPeriodDate,
    kmiIndicators,
} from "../general-pregnancy-termination-info.constants";

@Component({
    selector: "app-general-pregnancy-termination-info-view",
    templateUrl: "./general-pregnancy-termination-info-view.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralPregnancyTerminationInfoViewComponent implements OnInit, OnDestroy {
    @Input() closed?: boolean;

    public pregnancyInfo = new BehaviorSubject<PregnancyInfo>(null);
    public anamnesis = new BehaviorSubject<Anamnesis>(null);
    public biologicalFatherAddictions: string;

    public womanDiseases: string;
    public familyAnamnesis: FamilyAnamnesis[];
    public womanAddictions: { name: string; value: string }[];
    public calculatedKmi: string;

    public foilUsing = new BehaviorSubject<string>("");
    public MenstrualCycles = MenstrualCycles;
    public calculatedLastWomansPeriodDate: string;
    public pregnancyFirstDate = new Date();
    public addDaysToDate = addDaysToDate;

    //TODO: replace with data from caption
    public readonly allergies = allergies;

    //TODO: replace with data from caption
    public readonly vaccinations = vaccinations;

    public readonly foilUsingNamesArray = Object.values(FoilUsingNames);

    private patientId: string;
    private subscription: Subscription = new Subscription();

    constructor(private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard, private params: RouteParams) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.subscription.add(this.getPregnancyTerminationInfo());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        localStorage.clear();
    }

    private getPregnancyTerminationInfo(): Subscription {
        return this._apiV2PregnancyDashboard.getPregnancyTerminationInfo(this.patientId).subscribe((x) => {
            const savedData = localStorage.getItem("pregnancyTerminationInfo");
            let data = null;
            if (savedData) {
                data = JSON.parse(savedData);
            } else {
                data = x;
            }

            this.pregnancyInfo.next(data.pregnancyInfo);
            this.anamnesis.next(data.anamnesis);
            let foilUsingNamesArray: FoilUsingNames[] = [];

            if (data.pregnancyInfo.foilUsing.until) {
                foilUsingNamesArray.push(FoilUsingNames.Until);
            }
            if (data.pregnancyInfo.foilUsing.onGoing) {
                foilUsingNamesArray.push(FoilUsingNames.OnGoing);
            }
            if (data.pregnancyInfo.foilUsing.over) {
                foilUsingNamesArray.push(FoilUsingNames.Over);
            }

            this.foilUsing.next(foilUsingNamesArray.join(", "));
            this.biologicalFatherAddictions = data.anamnesis.biologicalFather.addictions.join(", ");
            this.womanDiseases = data.anamnesis.womanDiseases.join(", ");
            this.familyAnamnesis = data.anamnesis.familyAnamnesis.filter((anamnesis) => anamnesis.checked);
            this.womanAddictions = data.anamnesis.womanAddictions.map((addiction) => {
                let value = "";

                if (addiction.beforePregnancy && addiction.duringPregnancy) {
                    value = "Iki nėštumo, Nėštumo metu";
                } else if (addiction.beforePregnancy) {
                    value = "Iki nėštumo";
                } else if (addiction.duringPregnancy) {
                    value = "Nėštumo metu";
                }

                return {
                    name: addiction.name,
                    value,
                };
            });

            this.calculatedLastWomansPeriodDate = calculateLastWomansPeriodDate(
                data.pregnancyInfo.cycleDuration,
                String(data.pregnancyInfo.lastMenstruationPeriod)
            ).toISOString();

            const kmi =
                data.anamnesis.womanKmi.weight /
                ((data.anamnesis.womanKmi.height / 100) * (data.anamnesis.womanKmi.height / 100));

            const kmiName = kmiIndicators
                .map((indicator) => {
                    if (indicator.numberFrom < kmi && indicator.numberTo > kmi) {
                        return indicator.name;
                    }
                })
                .filter(Boolean);

            this.calculatedKmi = kmiName.length ? `${kmiName} - ${kmi.toFixed(1)}` : "";
        });
    }
}
