import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { FilteredDataModel } from "../app/shared/models/filtered-data.model";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class ApiV2Drafts extends ConfigService {
    path = this.apiUrl + "/drafts";
    findDrafts(patientId: string) {
        let params = new HttpParams();
        patientId && (params = params.append("patient", patientId));
        const url = `${this.path}`;
        return this.http.get<any[]>(url, { params });
    }

    getDraftForPreview(draftId: string) {
        const url = `${this.path}/${draftId}`;
        return this.http.get<any>(url, {}).pipe(
            map((val) => {
                let form = val.data;
                // documentForm
                if (form.metadata) {
                    form.metadata.documentStatus = "DRAFT";
                    form.metadata.docType = val.docType;
                } else {
                    form.documentStatus = "DRAFT";
                    form.type = val.docType;
                }

                var createDate = val.updated ? new Date(val.updated).getTime() : new Date().getTime();
                if (form.metadata) {
                    form.metadata.createDate = createDate;
                }
                return form;
            })
        );
    }

    getDraftForEdit(draftId: string) {
        const url = `${this.path}/${draftId}/edit`;
        return this.http.get<any>(url, {}).pipe(
            map((val) => {
                let form = val.data;
                // documentForm
                if (form.metadata) {
                    form.metadata.documentStatus = "DRAFT";
                    form.metadata.docType = val.docType;
                } else {
                    form.documentStatus = "DRAFT";
                    form.type = val.docType;
                }

                var createDate = val.updated ? new Date(val.updated).getTime() : new Date().getTime();
                if (form.metadata) {
                    form.metadata.createDate = createDate;
                }
                return form;
            })
        );
    }

    saveDraft(body: any, formType: string, patientId: string, draftId?: string) {
        const url = `${this.path}`;
        const params: any = { type: formType, patientId: patientId };
        if (draftId) {
            params.id = draftId;
        }
        return this.http.post<any>(url, body, { params: params });
    }

    countByDocType(patientId: string, encounterId: string) {
        let params = new HttpParams();
        patientId && (params = params.append("patientId", patientId));
        encounterId && (params = params.append("encounterId", encounterId));
        const url = `${this.path}/countByDocType`;
        return this.http.get<any[]>(url, { params });
    }
}
