import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { toBoolean } from "underscore.string";
import { MessageService } from "../../services/utils/message.service";

@Component({
    selector: 'nhr-widget',
    templateUrl: 'nhr-widget.html'
})
export class NhrWidgetComponent implements OnInit {
    @Input() color: string;
    @Input() title: string;
    @Input() closed: boolean;
    @Input() closeable: boolean = true;
    @Input() font: string;
    @Input() icon: string;
    @Input() disabled: boolean;
    @Input() amount: string;
    @Input() list: boolean;
    @Input() mixed: boolean;
    @Input() bodyClass: string;
    @Input() classDisabled: string;
    @Input() class: string;
    @Input() badge: string;

    @Output() widgetToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    messages: any[];

    constructor(private router: Router) {
    }

    private toBool(v: string | boolean): boolean {
        return typeof v === 'string' ? toBoolean(v) : v;
    }

    ngOnInit() {
        //TODO: Angular accepts boolean as a string only when property is defined in brackets, e.g. [property]="false".
        // This is a workaround, which lets assign boolean property properly, in order to do not change large amount of html's.
        this.closeable = this.toBool(this.closeable)
        this.closed = this.toBool(this.closed);
        this.list = this.toBool(this.list);
        this.mixed = this.toBool(this.mixed);

        this.font = this.font ? this.font : 'fa';
        this.bodyClass = this.bodyClass ? this.bodyClass : '';
    }

    // headerNavigate() {
    //     this.router.navigate([this.headerLink]);
    // }

    toggle() {
        if (this.closeable) {
            this.closed = !this.closed;
            this.widgetToggle.emit(this.closed);
        }
    }
}
