<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Nt.formFetalCondition.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <h6>{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.subTitle' | translate}} {{fruitName | uppercase}}</h6>
        <div class="row align-items-center mx-auto mt-3 req">
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-input
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.hRate' | translate}}"
                    units="k/min."
                    type="number"
                    [fControl]="formGroup.get('hearthRate')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('hearthRate')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-select
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.fetalMovements' | translate}}"
                    [id]="'fetalMovements-' + fruitName"
                    [fControl]="formGroup.get('fetalMovements')"
                    [values]="selectionValuesFromEntity(answersVariants?.['fetalMovements'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('fetalMovements')" />
            </div>
            <app-select
                class="form-group col-6 col-lg-3 flex-shrink-1"
                labelText="{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.forerunner' | translate}}"
                labelClass="norq"
                [id]="'forerunnerFruit-' + fruitName"
                [fControl]="formGroup.get('forerunner')"
                [values]="selectionValuesFromEntity(answersVariants?.['fetalPrimordial'])"
            ></app-select>
            <app-select
                class="form-group col-6 col-lg-3 flex-shrink-1"
                labelText="{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.position' | translate}}"
                labelClass="norq"
                [id]="'fetalPosition-' + fruitName"
                [fControl]="formGroup.get('position')"
                [values]="selectionValuesFromEntity(answersVariants?.['fetalPosition'])"
            ></app-select>
            <app-select
                class="form-group col-6 col-lg-3 flex-shrink-1"
                labelText="{{'doc.pregnancyDashboard.e096Nt.formFetalCondition.positionSide' | translate}}:"
                labelClass="norq"
                [id]="'fetalPositionSide-' + fruitName"
                [fControl]="formGroup.get('positionSide')"
                [values]="selectionValuesFromEntity(answersVariants?.['fetalPositionSide'])"
            ></app-select>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold"
                        >{{ 'doc.pregnancyDashboard.e096Nt.formFetalCondition.amnioticFluid' | translate }}:
                        </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of answersVariants?.['fetalFluidStatus']; let i = index"
                        radioName="fetalFluidStatus-{{fruitName}}-{{i}}"
                        [fControl]="formGroup.get('amnioticFluid')"
                        [radioValue]="option.name"
                        id="fetalFluidStatus-{{fruitName}}-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="formGroup.get('amnioticFluid')" />
            </div>
            <app-date-picker
                labelText="doc.pregnancyDashboard.e096Nt.formFetalCondition.amnioticFluidDate"
                inputId="regularContractions"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                [labelClass]="!controlRequired('amnioticFluidDate') ? 'norq' : ''"
                [maxDate]="maxDate"
                [withTimepicker]="true"
                [fControl]="formGroup.get('amnioticFluidDate')"
            ></app-date-picker>
        </div>
    </div>
</nhr-record-widget>