import moment from "moment";
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'datetime'})
export class DatetimePipe implements PipeTransform {

    constructor() {
    }

    transform(value: string): any {
        return moment(value).format('YYYY-MM-DD HH:mm');
    }
}