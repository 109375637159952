import {Environment} from "./environment-properties";
import {environments} from './environment'
import {IModule} from "angular";
import PortalType = Environment.PortalType;


export function environment(portalType: Environment.PortalType): Environment.Properties {
    return environments.get(portalType)
}

export class EnvironmentSettings {
    private static portalType: Environment.PortalType
    private static currentEnv: Environment.Properties

    static setEnvironment(portalType: Environment.PortalType) {
        this.portalType = portalType
        this.currentEnv = environment(portalType)
    }

    static initNg1Environment(appRef: IModule) {
        appRef.constant('portalType', this.portalType);
        appRef.constant('apiUrl', this.currentEnv.apiUrl)
        appRef.constant('ipasasUrl', this.currentEnv.ipasasUrl);
        appRef.constant('ssoUrl', this.currentEnv.ssoUrl);
        appRef.constant('iprUrl', this.currentEnv.ssoUrl);
        appRef.constant('footerLinks', this.currentEnv.footerLinks);
        appRef.constant('sveidraLinks', this.currentEnv.sveidraLinks);
        appRef.constant('medvaisReportsUrl', this.currentEnv.medvaisReportsUrl);
        appRef.constant('allowPatientOrderCertificates', this.currentEnv.allowPatientOrderCertificates);
        appRef.constant('allowPatientCreateRepresentations', this.currentEnv.allowPatientCreateRepresentations);
    }

    static getEnvironment(): Environment.Properties {
        return this.currentEnv
    }

    static getPortalType(): Environment.PortalType {
        return this.portalType;
    }

    static isSpecialist(): boolean {
        return this.getPortalType() === PortalType.DOCTOR;
    }

    static isPharmacist(): boolean {
        return this.getPortalType() === PortalType.PHARMACIST;
    }

    static isPatient(): boolean {
        return this.getPortalType() === PortalType.PATIENT;
    }

    static isAdmin(): boolean {
        return this.getPortalType() === PortalType.ADMIN;
    }
}