import {erxUtilsModule} from './erx-utils';

import './services/erx-helpers';
import './components/erx-foreign/erx-units/erxUnits';
import './components/erx-foreign/erx-product-package/erx-product-package';
import './components/erx-on-enter/erxOnEnter';
import './components/erx-allergies/erxAllergies';
import './components/erx-diagnoses/erxDiagnoses';
import './components/erx-modal-reason/erx-modal-reason';
import './components/erx-modal-search/erx-modal-search';
import './components/erx-modal-confirm/erx-modal-confirm';
import './components/erx-modal-medinfo/erx-modal-medinfo';
import './components/erx-dose-calculator/erxDoseCalculator';
import './components/erx-modal-conditions/erxConditionsModal';
import './components/erx-modal-load-draft/erx-modal-load-draft';
import './components/erx-modal-save-draft/erx-modal-save-draft';
import './components/erx-drug-interactions/erxDrugInteractions';
import './components/erx-modal-warning-expl/erx-modal-warning-expl';
import './components/erx-modal-analytical-info/erx-modal-analytical-info';
import './components/erx-drug-supply-disruptions/erxDrugSupplyDisruptions';
import './components/erx-modal-drug-interactions/erx-modal-drug-interactions';
import './components/erx-drug-security-and-education/erxDrugSecurityAndEducation';
import './components/erx-modal-analytical-info-by-org/erx-modal-analytical-info-by-org';
import './components/erx-modal-shortcut-customization/erxShortcutTabsDosageM';
import './components/erx-modal-shortcut-customization/erxShortcutCustomization';
import './components/erx-modal-drug-supply-disruptions/erxDrugSupplyDisruptionsModal';
import './components/erx-modal-analytical-info-by-age-group/erx-modal-analytical-info-by-age-group';
import './components/ui-select';
import './components/erx-tooltip';
import './components/erx-auto-grow';
import './components/erx-typeahead';
import './components/erx-datepicker';
import './components/erx-dynamic-name';
import './validation/validation';

export default erxUtilsModule;