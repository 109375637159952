import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RiskFactorModel } from '../../../../models/risk-factor.model';
import {ApiV2Risks} from "../../../../../shared/services/api-v2-risks";

@Component({
  selector: 'app-edit-health-factor-modal',
  templateUrl: './edit-health-factor-modal.component.html',
})
export class EditHealthFactorModalComponent implements OnInit {
    risk: Partial<RiskFactorModel>;
    formGroup!: FormGroup;
    onClose: EventEmitter<RiskFactorModel | void> = new EventEmitter<RiskFactorModel | void>();
    isAuditTestRequired: boolean;
    isFagerstormTestRequired: boolean;
    isEditMode = false;

    constructor(
        readonly bsModalRef: BsModalRef,
        private readonly fb: FormBuilder,
        private apiV2Risks: ApiV2Risks,
    ) {
    }

    ngOnInit() {
        this.isAuditTestRequired = this.risk?.name?.name === 'Alkoholio vartojimas';
        this.isFagerstormTestRequired = this.risk?.name?.name === 'Rūkymas';
        this.formGroup = this.fb.group({
            riskFactorMethodologyName: this.fb.control(this.risk?.riskFactorMethodologyName ?? null, Validators.required),
            assessmentScore: this.fb.control(this.risk?.assessmentScore ?? null),
            comments: this.fb.control(this.risk?.comments ?? null),
            patientConsent: this.fb.control(this.risk.patientConsent ?? true),
        })
    }

    getScore(score: number): void {
        this.formGroup.get('assessmentScore').patchValue(`${score}`);
        // this.formGroup.get('assessment').disable({emitEvent: false});
    }

    close(success?: boolean) {
        if (success) {
            if (this.formGroup.invalid) return;
            const newData = {
                ...this.risk,
                ...this.formGroup.getRawValue()
            }

            if (this.isEditMode) {
                this.apiV2Risks.updateRiskFactor(newData).subscribe(res => {
                    this.onClose.emit(res);
                });
            } else {
                this.onClose.emit(newData);
            }
        } else {
            this.onClose.emit();
        }
        this.bsModalRef.hide();
    }
}
