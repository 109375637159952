import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';

@Component({
    selector: 'app-e096-cpo-caesarean-participants',
    templateUrl: './e096-cpo-caesarean-participants.component.html',
})
export class E096CpoCaesareanParticipantsComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            operated: this.fb.array([], [Validators.required, Validators.maxLength(1)]),
            assisted: this.fb.array([]),
            doctors: this.fb.array([], [Validators.required, Validators.minLength(1)]),
            nurse: this.fb.array([])
        });
    }
}
