<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096N.formNewborn.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row req">
            <div class="col-6 col-lg-3">
                <app-input
                    labelText="doc.pregnancyDashboard.e096Nt.formFetalCondition.subTitle"
                    class="form-group"
                    labelClass="col-form-label"
                    [fControl]="formGroup.get('fruit')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('fruit')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-date-picker
                    labelText="cer.1031.doc.bor"
                    labelClass="form-label flex-grow-1 col-form-label"
                    inputId="regularContractions"
                    suffixIcon="fa fa-calendar"
                    [maxDate]="maxDate"
                    [withTimepicker]="true"
                    [fControl]="formGroup.get('birthTime')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('birthTime')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-select
                    labelText="doc.pregnancyDashboard.e096N.formNewborn.wasBorn"
                    [id]="'wasBorn'"
                    [fControl]="formGroup.get('wasBorn')"
                    [values]="selectFromEntities(answersVariants?.['pregnancyOutcome'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('wasBorn')" />
            </div>
            <div class="col-6 col-lg-3" *ngIf="getControlValue('wasBorn') === 'Negyvagimis'">
                <app-select
                    labelText="doc.pregnancyDashboard.e096N.formNewborn.undead"
                    labelClass="norq"
                    [id]="'undead'"
                    [fControl]="formGroup.get('undead')"
                    [values]="selectFromEntities(answersVariants?.['stillbirth'])"
                ></app-select>
            </div>
            <ng-container *ngIf="getControlValue('wasBorn') === 'Gyvas naujagimis'">
                <div class="col-6 col-lg-3">
                    <app-input
                        labelText="doc.pregnancyDashboard.e096N.formNewborn.name"
                        class="form-group flex-shrink-1"
                        labelClass="col-form-label norq"
                        [fControl]="formGroup.get('firstName')"
                    ></app-input>
                </div>
                <div class="col-6 col-lg-3">
                    <app-input
                        labelText="doc.pregnancyDashboard.e096N.formNewborn.secondName"
                        class="form-group flex-shrink-1"
                        labelClass="col-form-label norq"
                        [fControl]="formGroup.get('secondName')"
                    ></app-input>
                </div>
            </ng-container>
        </div>

        <div class="row req">
            <div class="col-6 col-lg-3">
                <app-select
                    labelText="doc.pregnancyDashboard.e096Nt.formFetalCondition.forerunner"
                    [id]="'primordial'"
                    [fControl]="formGroup.get('primordial')"
                    [values]="selectFromEntities(answersVariants?.['fetalPrimordial'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('primordial')" />
            </div>

            <div class="col-6 col-lg-3">
                <ng-container *ngIf="getControlValue('primordial') === 'Galva'">
                    <app-select
                        labelText="doc.pregnancyDashboard.e096N.formNewborn.positionOfOcciput"
                        [id]="'occiputPosition'"
                        [fControl]="formGroup.get('occiputPosition')"
                        [values]="selectFromEntities(occiputPositionOptions)"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('occiputPosition')" />
                </ng-container>
            </div>
            <app-input
                labelText="doc.pregnancyDashboard.e096N.formNewborn.umbilicalCordFeature"
                class="col-6 col-lg-3"
                labelClass="col-form-label norq"
                [fControl]="formGroup.get('umbilicalCord')"
            ></app-input>
            <app-input
                labelText="doc.pregnancyDashboard.e096N.formNewborn.umbilicalCordRotationApprox"
                class="col-6 col-lg-3"
                labelClass="col-form-label norq"
                [fControl]="formGroup.get('umbilicalCordRotationApprox')"
            ></app-input>
            <app-input
                labelText="doc.pregnancyDashboard.e096N.formNewborn.umbilicalCordRevolutionGeneration"
                class="col-6 col-lg-3"
                labelClass="col-form-label norq"
                [fControl]="formGroup.get('umbilicalCordsRevolutionGeneration')"
            ></app-input>
            <app-double-input
                labelText="doc.pregnancyDashboard.e096N.formNewborn.umbilicalCordBlood"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                units="pH/Lac"
                type="number"
                labelClass="col-form-label mt-3"
                [firstControl]="formGroup.get('umbilicalCordBlood1')"
                [secondControl]="formGroup.get('umbilicalCordBlood2')"
            ></app-double-input>
            <div class="col-6 col-lg-3">
                <app-input
                    labelText="doc.pregnancyDashboard.e096N.formNewborn.lactates"
                    labelClass="col-form-label"
                    [fControl]="formGroup.get('lactates')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('lactates')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-input
                    labelText="doc.pregnancyDashboard.e096N.formNewborn.headCircumference"
                    labelClass="col-form-label"
                    units="cm"
                    [fControl]="formGroup.get('headCircumference')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('headCircumference')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-select
                    labelText="dpp.pat.mai.sex"
                    [id]="'gender'"
                    [fControl]="formGroup.get('gender')"
                    [values]="selectFromEntities(answersVariants?.['administrativeGender'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('administrativeGender')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-select
                    labelText="doc.pregnancyDashboard.e096N.formNewborn.amnioticFluidColor"
                    [id]="'colorAmnioticFluid'"
                    [fControl]="formGroup.get('colorAmnioticFluid')"
                    [values]="selectFromEntities(answersVariants?.['fetalFluidColor'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('colorAmnioticFluid')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-input
                    labelText="doc.med.dat.hei"
                    labelClass="col-form-label"
                    units="cm"
                    [fControl]="formGroup.get('height')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('height')" />
            </div>
            <div class="col-6 col-lg-3">
                <app-input
                    labelText="dpp.pat.sum.phy.wei"
                    labelClass="col-form-label"
                    units="g"
                    [fControl]="formGroup.get('weight')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('weight')" />
            </div>
        </div>
        <div class="row req" [class.mb-5]="getControlValue('wasBorn') === 'Gyvas naujagimis'">
            <app-select
                class="col-6 col-lg-3"
                labelText="doc.pregnancyDashboard.e096N.formNewborn.resuscitation"
                labelClass="norq"
                [id]="'resuscitation'"
                [fControl]="formGroup.get('resuscitation')"
                [values]="selectFromEntities(resuscitationOptions)"
            ></app-select>
            <ng-container *ngIf="getControlValue('resuscitation') === 'Taip'">
                <app-double-input
                    labelText="doc.pregnancyDashboard.e096N.formNewborn.resuscitationDuration"
                    class="form-group col-6 col-lg-3 flex-shrink-1"
                    textSeparator="h"
                    units="min"
                    type="number"
                    labelClass="col-form-label mt-3"
                    [firstControl]="formGroup.get('resuscitationDurationHours')"
                    [secondControl]="formGroup.get('resuscitationDurationMinutes')"
                ></app-double-input>
                <div class="col-6 col-lg-3">
                    <app-select
                        labelText="doc.pregnancyDashboard.e096N.formNewborn.resuscitationMethod"
                        [id]="'resuscitationMethod'"
                        [fControl]="formGroup.get('resuscitationMethod')"
                        [values]="selectFromEntities(resuscitationMethodOptions)"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('resuscitationMethod')" />
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="getControlValue('wasBorn') === 'Gyvas naujagimis'">
            <ng-container *ngTemplateOutlet="timeGroup; context: {formGroup: this.formGroup.get('childData.after1min'), minutes: 1}"></ng-container>
            <ng-container *ngTemplateOutlet="timeGroup; context: {formGroup: this.formGroup.get('childData.after5min'), minutes: 5}"></ng-container>
            <ng-container *ngTemplateOutlet="timeGroup; context: {formGroup: this.formGroup.get('childData.after10min'), minutes: 10}"></ng-container>

            <div class="row mt-4 req">
                <div class="col-6 col-lg-3">
                    <app-select
                        labelText="doc.pregnancyDashboard.e096N.formNewborn.firstFeeding"
                        [id]="'firstFeeding'"
                        [fControl]="formGroup.get('childData.firstFeeding')"
                        [values]="selectFromEntities(newbornFirstFeedingOptions)"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('childData.firstFeeding')" />
                </div>
                <div
                    *ngIf="getControlValue('childData.firstFeeding') === 'Neinicijuotas'"
                    class="col-6 col-lg-3"
                >
                    <app-input
                        labelText="doc.pregnancyDashboard.e096N.formNewborn.reason"
                        labelClass="col-form-label"
                        [fControl]="formGroup.get('childData.noFeedingReason')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('childData.firstFeeding')" />
                </div>
            </div>
            <h4 class="mt-3">{{ 'doc.pregnancyDashboard.e096N.formNewborn.newbornTransfer' | translate }}</h4>
            <div class="row">
                <app-date-picker
                    class="form-group col-6 col-lg-3"
                    labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.transferTime' | translate}}:"
                    inputId="regularContractions"
                    suffixIcon="fa fa-calendar"
                    [withTimepicker]="true"
                    [maxDate]="maxDate"
                    [fControl]="formGroup.get('childData.transferOfNewborn.transferTime')"
                ></app-date-picker>
                <div class="col-6 col-lg-3">
                <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold">
                            {{'doc.pregnancyDashboard.e096N.formNewborn.trasferToWard' | translate}}:
                        </span>
                    <div class="input-group">
                        <app-radio-button
                            *ngFor="let option of newbornTrasferedWardOptions; let i = index"
                            radioName="trasferToWard-{{i}}"
                            [fControl]="formGroup.get('childData.transferOfNewborn.trasferToWard')"
                            [radioValue]="option.name"
                            id="trasferToWard-{{i}}"
                            class="w-100"
                            formClass="mb-0"
                            [labelText]="option.name"
                        ></app-radio-button>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold">
                            {{'doc.pregnancyDashboard.e096N.formNewborn.trasferred' | translate}}:
                        </span>
                    <div class="input-group">
                        <app-radio-button
                            *ngFor="let option of newbornMovedOptions; let i = index"
                            radioName="newbornMoved-{{i}}"
                            [fControl]="formGroup.get('childData.transferOfNewborn.newbornMoved')"
                            [radioValue]="option.name"
                            id="newbornMoved-{{i}}"
                            class="w-100"
                            formClass="mb-0"
                            [labelText]="option.name"
                        ></app-radio-button>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold">
                            {{'doc.pregnancyDashboard.e096N.formNewborn.conditionOfNewbornTransfered' | translate}}:
                        </span>
                    <div class="input-group">
                        <app-radio-button
                            *ngFor="let option of newbornTrasferedConditionOptions; let i = index"
                            radioName="conditionOfNewbornTransfered-{{i}}"
                            [fControl]="formGroup.get('childData.transferOfNewborn.conditionOfNewbornTransfered')"
                            [radioValue]="option.name"
                            id="conditionOfNewbornTransfered-{{i}}"
                            class="w-100"
                            formClass="mb-0"
                            [labelText]="option.name"
                        ></app-radio-button>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-6 mb-3">
                        <span class="fw-bolder">
                            {{'doc.pregnancyDashboard.e096N.formNewborn.wasTransferredToWard' | translate}}:
                        </span>
                        <div class="mt-2">
                            <app-find-specialists
                                [editable]="editable"
                                [customDisplaying]="false"
                                [formArray]="formGroup?.get('childData.transferOfNewborn.doctorReferredNewborn')"
                            ></app-find-specialists>
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <span class="fw-bolder">
                            {{'doc.pregnancyDashboard.e096N.formNewborn.admittedToDepartment' | translate}}:
                        </span>
                        <div class="mt-2">
                            <app-find-specialists
                                [editable]="editable"
                                [customDisplaying]="false"
                                [formArray]="formGroup?.get('childData.transferOfNewborn.doctorAdmittedNewborn')"
                            ></app-find-specialists>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</nhr-record-widget>

<ng-template #timeGroup let-group="formGroup" let-minutes="minutes">
    <div class="mt-3">
        <span>
            Po {{ minutes }} min.
        </span>
        <div class="row flex-nowrap req">
            <div class="col-3 flex-shrink-1">
                <app-select
                    labelClass="mt-0 pt-0"
                    labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.reflexes' | translate}}:"
                    [id]="'reflexes{{ minutes }}min'"
                    [fControl]="group.get('reflexes')"
                    [values]="selectFromEntities(scaleOptions)"
                ></app-select>
                <app-form-control-validation [fControl]="group.get('reflexes')" />
            </div>
            <div class="col-3 flex-shrink-1">
                <app-select
                    labelClass="mt-0 pt-0"
                    labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.heartActivity' | translate}}:"
                    [id]="'heartActivity{{ minutes }}min'"
                    [fControl]="group.get('heartActivity')"
                    [values]="selectFromEntities(scaleOptions)"
                ></app-select>
                <app-form-control-validation [fControl]="group.get('heartActivity')" />
            </div>
            <div class="col-3 flex-shrink-1">
                <app-select
                    labelClass="mt-0 pt-0"
                    labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.breathing' | translate}}:"
                    [id]="'breathing{{ minutes }}min'"
                    [fControl]="group.get('breathing')"
                    [values]="selectFromEntities(scaleOptions)"
                ></app-select>
                <app-form-control-validation [fControl]="group.get('breathing')" />
            </div>
            <div class="col-3 flex-shrink-1">
                <app-select
                    labelClass="mt-0 pt-0"
                    labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.skinColor' | translate}}:"
                    [id]="'skinColor{{ minutes }}min'"
                    [fControl]="group.get('skinColor')"
                    [values]="selectFromEntities(scaleOptions)"
                ></app-select>
                <app-form-control-validation [fControl]="group.get('skinColor')" />
            </div>
            <div class="col-3 flex-shrink-1">
                <app-select
                    labelClass="mt-0 pt-0"
                    labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.muscleTone' | translate}}:"
                    [id]="'muscleTone{{ minutes }}min'"
                    [fControl]="group.get('muscleTone')"
                    [values]="selectFromEntities(scaleOptions)"
                ></app-select>
                <app-form-control-validation [fControl]="group.get('muscleTone')" />
            </div>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096N.formNewborn.apgarScore' | translate}}:"
                labelClass="mt-0 pt-0"
                class="col-3 flex-shrink-1"
                [fControl]="group.get('apgarScore')"
            ></app-input>
        </div>
    </div>
</ng-template>