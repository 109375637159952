import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../../api/api-v2-doc-pregnancy-dashboard";
import { RouteParams } from "../../../../../ng1.routeParams";
import { addWeeksToDate } from "../../../../../shared/services/utils/utils";
import { OutpatientVisitDescriptionModel } from "../../../models/outpatientVisitDescription.model";
import {
    MaintenancePlanTableDataNames,
    MaintenancePlanTableDataStaticValues,
    MaintenancePlanWeeks,
} from "../outpatient-visit-description.constants";

@Component({
    selector: "app-outpatient-visit-description-view",
    templateUrl: "./outpatient-visit-description-view.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutpatientVisitDescriptionViewComponent {
    @Input() closed?: boolean;
    @Input() summaryView?: boolean = true;

    public readonly maintenancePlanWeeks = Object.values(MaintenancePlanWeeks);
    public readonly maintenancePlanTableDataNames = Object.values(MaintenancePlanTableDataNames);
    public readonly maintenancePlanWeeksUpdated = MaintenancePlanWeeks.map((week) => week.replace(/[^0-9]/g, ""));

    public outpatientVisitDescriptions = new BehaviorSubject<
        {
            headerData: { name: string; date: string; bloodPressure: string; pulse: string };
            footerLabel: string;
            data: OutpatientVisitDescriptionModel;
        }[]
    >([]);

    public researchDates = new BehaviorSubject<string[]>([]);
    public maintenancePlanHeader = new BehaviorSubject<string[]>([
        "Nėštumo savaitė, iki (imtinai)",
        ...this.maintenancePlanWeeks,
    ]);
    public maintenancePlanDateHeader = new BehaviorSubject<string[]>([]);
    public maintenancePlan = new BehaviorSubject<any[]>([]);

    private patientId: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private datePipe: DatePipe,
        private params: RouteParams
    ) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.subscription.add(this.getOutpatientVisitDescription());
    }

    ngOnDestroy(): void {
        this.maintenancePlanHeader.next(this.maintenancePlanWeeks);
        this.subscription.unsubscribe();
        localStorage.clear();
    }

    public checkIfDate(data: string): string {
        return !isNaN(Date.parse(data)) ? this.datePipe.transform(new Date(data), "yyyy-MM-dd") : data;
    }

    private extractNumbersBetweenKeys(obj: { periodFromKey?: string; periodToKey?: string }): string[] | null {
        if (!obj?.periodFromKey && !obj?.periodToKey) {
            return null;
        }

        const fromKey = obj.periodFromKey ? parseInt(obj.periodFromKey, 10) : undefined;
        const toKey = obj.periodToKey ? parseInt(obj.periodToKey, 10) : undefined;

        if (!fromKey && !toKey) {
            return null;
        }

        const result: string[] = [];

        if (fromKey !== undefined && toKey !== undefined) {
            for (let i = fromKey; i <= toKey; i++) {
                result.push(i.toString());
            }
        } else {
            result.push(fromKey ? fromKey.toString() : toKey.toString());
        }

        return result.filter((key) => this.maintenancePlanWeeksUpdated.includes(key));
    }

    private getKeysToAddStiles(inputArray: { periodFromKey?: string; periodToKey?: string }[]): string[][] {
        const resultArray: string[][] = [];
        let currentObject: { periodFromKey?: string; periodToKey?: string } | null = null;

        for (const item of inputArray) {
            if (item.periodFromKey && item.periodToKey) {
                // If both keys are present, process the current object and reset
                if (currentObject?.periodFromKey) {
                    resultArray.push(this.extractNumbersBetweenKeys(currentObject) || []);
                }
                currentObject = item;
            } else if (item.periodFromKey) {
                // If only periodFromKey is present, store it for the next iteration
                currentObject = item;
            } else if (item.periodToKey && currentObject?.periodFromKey) {
                // If only periodToKey is present and there's a stored periodFromKey,
                // combine it with the stored periodFromKey
                currentObject.periodToKey = item.periodToKey;
                resultArray.push(this.extractNumbersBetweenKeys(currentObject) || []);
                currentObject = null;
            }
        }

        // Process the last object if it's not processed yet
        if (currentObject?.periodFromKey) {
            resultArray.push(this.extractNumbersBetweenKeys(currentObject) || []);
        }

        return resultArray;
    }

    private getOutpatientVisitDescription(): Subscription {
        return this._apiV2PregnancyDashboard.getOutpatientVisitDescriptions(this.patientId).subscribe((x) => {
            const savedData = localStorage.getItem("outpatientVisitDescription");
            let data = null;
            if (savedData) {
                data = JSON.parse(savedData);
            } else {
                data = x;
            }

            const outpatientVisitDescriptions = data.map((descrition) => ({
                headerData: {
                    name: "Arterinis kraujo spaudimas",
                    date: new Date(descrition?.womanAmbulatoryDescription?.assessmentDate).toDateString(),
                    bloodPressure: `K. ${descrition?.womanAmbulatoryDescription?.bloodPressureLeftUp}/${descrition?.womanAmbulatoryDescription?.bloodPressureLeftDown} | D. ${descrition?.womanAmbulatoryDescription?.bloodPressureRightUp}/${descrition?.womanAmbulatoryDescription?.bloodPressureRightDown}`,
                    pulse: `VŠR(A): ${descrition?.womanAmbulatoryDescription?.pulse}`,
                },
                footerLabel: `VŠĮ Antakalnio poliklinika | Slaugos ir akušerijos specialistas | Jonas Jonaitis | ${new Date(
                    descrition?.womanAmbulatoryDescription?.assessmentDate
                ).toDateString()}`,
                data: descrition,
            }));

            this.outpatientVisitDescriptions.next(outpatientVisitDescriptions);

            this.maintenancePlanDateHeader.next([
                "Data, iki (imtinai)",
                ...[
                    new Date().toDateString(),
                    addWeeksToDate(new Date(), 1).toDateString(),
                    addWeeksToDate(new Date(), 3).toDateString(),
                    addWeeksToDate(new Date(), 7).toDateString(),
                    addWeeksToDate(new Date(), 9).toDateString(),
                    addWeeksToDate(new Date(), 13).toDateString(),
                    addWeeksToDate(new Date(), 16).toDateString(),
                    addWeeksToDate(new Date(), 17).toDateString(),
                    addWeeksToDate(new Date(), 18).toDateString(),
                    addWeeksToDate(new Date(), 24).toDateString(),
                    addWeeksToDate(new Date(), 26).toDateString(),
                    addWeeksToDate(new Date(), 29).toDateString(),
                    addWeeksToDate(new Date(), 30).toDateString(),
                ],
            ]);

            const maintenancePlan = [...MaintenancePlanTableDataStaticValues];

            data.forEach((x) => {
                x.maintenancePlan.forEach((plan) => {
                    const styles = this.maintenancePlanWeeksUpdated.map((w) => ({
                        [w]: {
                            periodFrom: w === plan.periodFrom.replace(/[^0-9]/g, ""),
                            periodTo: w === plan.periodTo.replace(/[^0-9]/g, ""),
                            note: plan.note,
                            mergeLines: plan.mergeLines,
                            hideColumn: plan.hideColumn,
                        },
                    }));

                    const transformedStyles = {
                        ...Object.assign({}, ...Object.values(styles)),
                    };
                    maintenancePlan.push({
                        name: plan.additionalSpecialistConsultation,
                        ...transformedStyles,
                    });
                });
            });

            const maintenancePlanToView = maintenancePlan.map((plan, i, array) => {
                const periodValues2 = [];

                this.maintenancePlanWeeksUpdated.forEach((key) => {
                    let periodFromKey: string;
                    let periodToKey: string;

                    if (array[i][key].periodFrom) {
                        periodFromKey = key;
                    }

                    if (array[i][key].periodTo) {
                        periodToKey = key;
                    }

                    if (periodFromKey || periodToKey) {
                        periodValues2.push({
                            periodFromKey,
                            periodToKey,
                        });
                    }
                });

                const resultArray = this.getKeysToAddStiles(periodValues2);

                const staticValues = MaintenancePlanTableDataStaticValues.some((val) => val.name === plan.name);

                resultArray.forEach((array) => {
                    array.forEach((valToUpdate, i, a) => {
                        const firstElement = String(a[0]);
                        const key = String(valToUpdate);
                        const value = plan[key];

                        if (firstElement && !staticValues) {
                            const firstElementValue = plan[firstElement];
                            if (firstElementValue) {
                                firstElementValue["mergeLines"] = a.length;
                            }
                        }

                        if (value) {
                            value["style"] = `bg-dark-subtle border-top border-bottom`;

                            if (firstElement !== key && !staticValues) {
                                value["hideColumn"] = true;
                            }
                        }
                    });
                });

                this.maintenancePlanWeeksUpdated.forEach((key) => {
                    if (!plan[key]["mergeLines"] && !staticValues) {
                        plan[key]["note"] = null;
                    }
                });

                return plan;
            });

            this.maintenancePlan.next(maintenancePlanToView);
        });
    }
}
