import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    generateSelectionValuesFromEntity, getControlValue,
    listenAndChangeStatusOfControl,
} from '../../../../../e096/e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e097-gd-research',
    templateUrl: './e097-gd-research.component.html',
})
export class E097GdResearchComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup = this.fb.group({});
    currentTime = new Date();

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;

    researches = [
        {
            name: 'doc.pregnancyDashboard.e097Gd.researchForm.bilirubin',
            formGroup: 'bilirubin'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.researchForm.glycemia',
            formGroup: 'glycemia'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.researchForm.generalBloodTest',
            formGroup: 'generalBloodTest'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.researchForm.bloodGroup',
            formGroup: 'bloodGroup',
            selectContol: 'bloodTypeIsDetermined'
        },
        {
            name: 'CRB',
            formGroup: 'crb'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.researchForm.directCoombsReaction',
            formGroup: 'directCoombsReaction'
        },
        {
            name: 'doc.pregnancyDashboard.e097Gd.researchForm.otherStudies',
            formGroup: 'otherStudies',
            additionalTextarea: 'nameOfTheNextStudy'
        }
    ];

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
    ) {
    }

    ngOnInit(): void {
        this.researches.forEach(inspection => {
            this.formGroup.addControl(inspection.formGroup, this.fb.group({
                result: this.fb.control(false),
            }));
            (this.formGroup.get(inspection.formGroup) as FormGroup)
                .addControl(inspection.selectContol ?? 'comment', this.fb.control(null));
            if (inspection.additionalTextarea) {
                (this.formGroup.get(inspection.formGroup) as FormGroup)
                    .addControl(inspection.additionalTextarea, this.fb.control(null));
            }
            const controlsForUpdate = inspection.additionalTextarea
                ? [`${inspection.formGroup}.${inspection.selectContol ?? 'comment'}`, `${inspection.formGroup}.${inspection.additionalTextarea}`]
                : `${inspection.formGroup}.${inspection.selectContol ?? 'comment'}`
            this.changeStatusOfControl({
                controlNameForListen: `${inspection.formGroup}.result`,
                controlNameForUpdate: controlsForUpdate,
                enableValue: true,
            }).pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe();
        })
    }
}
