import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, distinctUntilChanged, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { FetusUltrasoundData, FetusUltrasoundModel } from "../../models/fetusUltrasoundExamination.model";
import { PregnancyInfo } from "../../models/pregnancyTerminationInfo.model";
import { DocumentCaptionModel, NGNCaptionModel } from "../shared/models/shared.model";
import { UltrasoundStages } from "./fetus-ultrasound-examination.constants";
import { UltrasoundTemplateComponent } from "./ultrasound-template/ultrasound-template.component";

@Component({
    selector: "app-fetus-ultrasound-examination",
    templateUrl: "./fetus-ultrasound-examination.component.html",
})
export class FetusUltrasoundExaminationComponent implements OnInit, OnDestroy {
    @ViewChild("ultrasoundTemplateComponent") public ultrasoundTemplateComponent!: UltrasoundTemplateComponent;

    public formGroup: FormGroup;

    public readonly ultrasoundStagesArray = Object.values(UltrasoundStages);
    public pregnancyInfo: PregnancyInfo;

    public documentTag: DocumentTagEnum = DocumentTagEnum.e113_ug;
    public documentCaptionData: DocumentCaptionModel;
    public ngnCaptionData: NGNCaptionModel;
    prefillData: any;

    public get getUltrasoundStageValue(): UltrasoundStages {
        return this.formGroup?.get("ultrasoundStage").value;
    }

    public get getUltrasoundFirstStage(): FormGroup {
        return this.formGroup?.get("firstUltrasoundStage") as FormGroup;
    }

    public get getUltrasoundSecondStage(): FormGroup {
        return this.formGroup?.get("secondUltrasoundStage") as FormGroup;
    }

    public get getUltrasoundThirdStage(): FormGroup {
        return this.formGroup?.get("thirdUltrasoundStage") as FormGroup;
    }

    public get getUltrasoundStageFormGroup(): FormGroup {
        let stageName = "";
        const value: UltrasoundStages = this.getUltrasoundStageValue;

        switch (value) {
            case UltrasoundStages.First:
                stageName = "firstUltrasoundStage";
                break;
            case UltrasoundStages.Second:
                stageName = "secondUltrasoundStage";
                break;
            case UltrasoundStages.Third:
                stageName = "thirdUltrasoundStage";
                break;

            default:
                stageName = "firstUltrasoundStage";
        }

        const controlNameByStage = `${stageName}`;

        return this.formGroup?.get(controlNameByStage) as FormGroup;
    }

    private patientId: string;
    private isEdit: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();

        if (this.isEdit) {
            this.subscription.add(this.patchForm());
        }

        this.subscription.add(this.subscribeDocumentCaptionData());
        this.subscription.add(this.subscribeNGNCaptionData());
        this.subscription.add(this.getPregnancyTerminationInfo());
        this.subscription.add(this.subscribeStageChanges());
        this.subscription.add(this.subscribeFormChanges());
        this.formGroup.markAllAsTouched();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    public async saveForm(): Promise<void> {
        const payload: FetusUltrasoundModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.saveFetusUltrasoundExamination(payload);

        this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${DocumentTagEnum.e113_ug}`]);
    }

    public async submitForm(): Promise<void> {
        const payload: FetusUltrasoundModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.submitFetusUltrasoundExamination(payload);
    }

    public async signingSubmitForm(): Promise<void> {
        const payload: FetusUltrasoundModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signingSubmitFetusUltrasoundExamination(payload);
    }

    public async sealConfirmForm(): Promise<void> {
        const payload: FetusUltrasoundModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.sealConfirmFetusUltrasoundExamination(payload);
    }

    public async signForm(): Promise<void> {
        const payload: FetusUltrasoundModel = { ...this.formGroup.value, id: this.patientId };
        await this._apiV2PregnancyDashboard.signFetusUltrasoundExamination(payload);
    }

    public clearForm(): void {
        this.formGroup.reset();
    }

    public changeStage(event: any): void {
        this.resetFormAfterStageChanges(event.target.value);
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            ultrasoundStage: new FormControl(null),
            firstUltrasoundStage: this._fb.group({
                ...this.ultrasoundStageGeneralFields(),
            }),
            secondUltrasoundStage: this._fb.group({
                ...this.ultrasoundStageGeneralFields(),
            }),
            thirdUltrasoundStage: this._fb.group({
                ...this.ultrasoundStageGeneralFields(),
            }),
        });
    }

    private ultrasoundStageGeneralFields() {
        return {
            researchDate: new FormControl(null, Validators.required),
            researchConditions: new FormControl(null, Validators.required),
            researchConditionsOther: new FormControl(null),
            childCount: new FormControl(null, Validators.required),
            appearanceUterineAppendages: new FormControl(null),
            cervicalLength: new FormControl(null),
            rightPI: new FormControl(null),
            rightRI: new FormControl(null),
            rightProtodiastolicWave: new FormControl(null),
            leftPI: new FormControl(null),
            leftRI: new FormControl(null),
            leftProtodiastolicWave: new FormControl(null),
            childsNavButtons: this._fb.array([]),
            fetusData: this._fb.array([]),
            detailedUltrasoundExamination: new FormControl(false),
            reExamination: this._fb.group({
                required: new FormControl(false),
                pregnancyWeek: new FormControl(null),
            }),
            sendConsultations: this._fb.group({
                required: new FormControl(false),
                specialist: new FormControl(null),
            }),
            otherImportantInfo: new FormControl(null),
            //Third Stage
            researchPurpose: new FormControl(null),
            researchPurposeOther: new FormControl(null),
        };
    }

    private resetFormAfterStageChanges(stage: UltrasoundStages): void {
        switch (stage) {
            case UltrasoundStages.First:
                if (this.formGroup.get("firstUltrasoundStage") === null) {
                    this.formGroup.setControl(
                        "firstUltrasoundStage",
                        this._fb.group({
                            ...this.ultrasoundStageGeneralFields(),
                        })
                    );
                }

                this.formGroup.removeControl("secondUltrasoundStage");
                this.formGroup.removeControl("thirdUltrasoundStage");
                break;
            case UltrasoundStages.Second:
                if (this.formGroup.get("secondUltrasoundStage") === null) {
                    this.formGroup.setControl(
                        "secondUltrasoundStage",
                        this._fb.group({
                            ...this.ultrasoundStageGeneralFields(),
                        })
                    );
                }

                this.formGroup.removeControl("firstUltrasoundStage");
                this.formGroup.removeControl("thirdUltrasoundStage");
                break;
            case UltrasoundStages.Third:
                if (this.formGroup.get("thirdUltrasoundStage") === null) {
                    this.formGroup.addControl(
                        "thirdUltrasoundStage",
                        this._fb.group({
                            ...this.ultrasoundStageGeneralFields(),
                        })
                    );
                }

                this.formGroup.removeControl("firstUltrasoundStage");
                this.formGroup.removeControl("secondUltrasoundStage");
                break;
        }
    }

    private subscribeStageChanges(): Subscription {
        return this.formGroup
            ?.get("ultrasoundStage")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe((ultrasoundStage) => {
                this.resetFormAfterStageChanges(ultrasoundStage);
                this.formGroup.markAllAsTouched();
            });
    }

    private subscribeFormChanges(): Subscription {
        return this.formGroup.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
            if (this.formGroup.get("firstUltrasoundStage.childCount")?.value) {
                this.ultrasoundTemplateComponent?.setChildFormArray(
                    this.formGroup.get("firstUltrasoundStage.childCount").value
                );
            }

            if (this.formGroup.get("secondUltrasoundStage.childCount")?.value) {
                this.ultrasoundTemplateComponent?.setChildFormArray(
                    this.formGroup.get("secondUltrasoundStage.childCount").value
                );
            }

            if (this.formGroup.get("thirdUltrasoundStage.childCount")?.value) {
                this.ultrasoundTemplateComponent?.setChildFormArray(
                    this.formGroup.get("thirdUltrasoundStage.childCount").value
                );
            }
        });
    }

    private patchForm(): Subscription {
        return this._apiV2PregnancyDashboard
            .getFetusUltrasoundExamination(this.patientId)
            .subscribe((data: FetusUltrasoundModel) => {
                this.formGroup.patchValue(data);

                const firstStageFetus = this.getUltrasoundFirstStage.get("fetusData") as FormArray;
                const secondStageFetus = this.getUltrasoundSecondStage.get("fetusData") as FormArray;
                const thirdStageFetus = this.getUltrasoundThirdStage.get("fetusData") as FormArray;

                const firstStageChildsNavButtons = this.getUltrasoundFirstStage.get("childsNavButtons") as FormArray;
                const secondStageChildsNavButtons = this.getUltrasoundSecondStage.get("childsNavButtons") as FormArray;
                const thirdStageChildsNavButtons = this.getUltrasoundThirdStage.get("childsNavButtons") as FormArray;

                data.firstUltrasoundStage.fetusData.forEach((fetus) => {
                    firstStageChildsNavButtons.push(this._fb.group({ letter: fetus.generalInfo.fetusLetter }));
                    firstStageFetus.push(this.patchFetusData(fetus));
                });

                data.secondUltrasoundStage.fetusData.forEach((fetus) => {
                    secondStageChildsNavButtons.push(this._fb.group({ letter: fetus.generalInfo.fetusLetter }));
                    secondStageFetus.push(this.patchFetusData(fetus));
                });

                data.thirdUltrasoundStage.fetusData.forEach((fetus) => {
                    thirdStageChildsNavButtons.push(this._fb.group({ letter: fetus.generalInfo.fetusLetter }));
                    thirdStageFetus.push(this.patchFetusData(fetus));
                });
            });
    }

    private patchFetusData(fetus: FetusUltrasoundData): FormGroup {
        const fetusData: FormGroup = this._fb.group({
            generalInfo: this._fb.group({
                ...fetus.generalInfo,
                headButtocksMeasurement: this._fb.group(fetus.generalInfo.headButtocksMeasurement),
                neckFertility: this._fb.group(fetus.generalInfo.neckFertility),
                biparietalDimension: this._fb.group(fetus.generalInfo.biparietalDimension),
                headCircumference: this._fb.group(fetus.generalInfo.headCircumference),
                abdominalGirth: this._fb.group(fetus.generalInfo.abdominalGirth),
                femurLength: this._fb.group(fetus.generalInfo.femurLength),
                humerusLength: this._fb.group(fetus.generalInfo.humerusLength),
                fetusWeight: this._fb.group(fetus.generalInfo.fetusWeight),
            }),
            head: this._fb.group({
                ...fetus.head,
                cerebellum: this._fb.group(fetus.head.cerebellum),
                greatCistern: this._fb.group(fetus.head.greatCistern),
                rightAnteriorHorn: this._fb.group(fetus.head.rightAnteriorHorn),
                leftAnteriorHorn: this._fb.group(fetus.head.leftAnteriorHorn),
                rightPosteriorHorn: this._fb.group(fetus.head.rightPosteriorHorn),
                leftPosteriorHorn: this._fb.group(fetus.head.leftPosteriorHorn),
                neckCrease: this._fb.group(fetus.head.neckCrease),
            }),
            face: this._fb.group({
                ...fetus.face,
                nasalBone: this._fb.group(fetus.face.nasalBone),
                peritonealTissueThickness: this._fb.group(fetus.face.peritonealTissueThickness),
            }),
            neckAndSpine: this._fb.group(fetus.neckAndSpine),
            chest: this._fb.group(fetus.chest),
            heart: this._fb.group(fetus.heart),
            belly: this._fb.group({
                ...fetus.belly,
                rightKidney: this._fb.group(fetus.belly.rightKidney),
                leftKidney: this._fb.group(fetus.belly.leftKidney),
            }),
            limbs: this._fb.group(fetus.limbs),
            umbilicalCord: this._fb.group(fetus.umbilicalCord),
            sexOrgans: this._fb.group(fetus.sexOrgans),
            bloodStream: this._fb.group(fetus.bloodStream),
            placenta: this._fb.group({
                ...fetus.placenta,
                localization: new FormControl(fetus.placenta.localization),
            }),
            amnioticFluids: this._fb.group(fetus.amnioticFluids),
            bloodFlowTest: this._fb.group({
                ...fetus.bloodFlowTest,
                umbilicalArtery: this._fb.group(fetus.bloodFlowTest.umbilicalArtery),
                middleCerebralArtery: this._fb.group(fetus.bloodFlowTest.middleCerebralArtery),
                venousDuct: this._fb.group(fetus.bloodFlowTest.venousDuct),
                splenicArtery: this._fb.group(fetus.bloodFlowTest.splenicArtery),
            }),
            conclusions: this._fb.group({
                ...fetus.conclusions,
                pregnancyDuration: this._fb.group(fetus.conclusions.pregnancyDuration),
            }),
        });

        return fetusData;
    }

    private subscribeDocumentCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getDocumentCaptionData(this.patientId).subscribe((data) => {
            this.documentCaptionData = data;
        });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).subscribe((data) => {
            this.ngnCaptionData = data;
        });
    }

    private getPregnancyTerminationInfo(): Subscription {
        return this._apiV2PregnancyDashboard
            .getPregnancyTerminationInfo(this.patientId)
            .subscribe((pregnancyTermination) => {
                console.log("pregnancyTermination", pregnancyTermination);
                this.pregnancyInfo = pregnancyTermination.pregnancyInfo;
            });
    }
}
