ng1App.controller('doctor.user.AccountEditCtrl', 
    ['$scope', '$routeParams', '$modal', '$location', '$filter', '$window', 'DpSessionContext', 'MessageService', 'PractitionersApiFactory', 'PractitionerUpdateService',
     function ($scope, $routeParams, $modal, $location, $filter, $window, DpSessionContext, MessageService, PractitionersApiFactory, PractitionerUpdateService) {
	$scope.$emit('setHelpPage', 'redaguoti-specialisto-duomenis');
    
	$scope.practitioner = {};
	$scope.data = {addFile: true};
	
	DpSessionContext.practitioner.$promise.then(function(a){
		$scope.practitionerId = DpSessionContext.practitioner.id;
		$scope.load();
	});
	
	$scope.load = function() {
		MessageService.clearAll();
		PractitionersApiFactory.getFull({id: $scope.practitionerId}, function (data) {
			$scope.practitioner = data;
			$scope.practitioner.fullName = _.getFullName(data);
        });
	};

    $scope.onFileSelect = function (file) {
        if (file) {
            $scope.data.file = file;
            $scope.practitioner.photoName = file.name;
        }
    };
    $scope.removePhoto = function(){
        $scope.practitioner.photoName = undefined;
        $scope.data.file = undefined;
    };
    
    $scope.$watch('practitioner.phones[0]', function (phone) {
    	if(phone){
    		var ctrl = $scope.practitionerForm.practitionerPhones.inputCtrl;
    		ctrl.$setValidity('required',true);
    		MessageService.clearAll();
    	}
    });
	
    $scope.save = function () {
    	MessageService.clearAll();
    	$scope.practitionerForm.submitted = true;
    	if(!$scope.practitioner.phones || $scope.practitioner.phones.length==0){
    		var ctrl = $scope.practitionerForm.practitionerPhones.inputCtrl;
    		ctrl.$setViewValue(ctrl.$viewValue);
    		ctrl.$setValidity('required',false);
    		MessageService.add('error', $filter('translate')('err.contacts.noPhone'), 'contacts-region');
    	}else{
                MessageService.registerSuccessEvent();
    		PractitionerUpdateService.update($scope.practitioner, $scope.data.file, function (data) {
    			$location.path('/dp/account/view');
    		},function (error) {
                angular.forEach(error.globalErrors, function (e) {
                    MessageService.error(e.msg);
                });
   		     	$window.scrollTo(0, 10);
            });
    	}
    	
    };

}]);