import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { BsModalService } from "ngx-bootstrap/modal";
import { VisitModalActions } from "../visit-modal-actions/visit-modal-actions.component";

export interface ICustomHeaderParams {
    linkString: string;
    routerLinkTo: string;
}

@Component({
    providers: [VisitModalActions],
    selector: "visit-ag-actions-cell",
    templateUrl: "./visit-ag-actions-cell.component.html",
})
export class VisitAgActionsCell implements OnInit, ICellRendererAngularComp {

    public rowNode: IRowNode<any>;

    public visitId: number;
    public periodVisitId: number;

    constructor(
        public _child: VisitModalActions,
        private router: Router, private modalService: BsModalService, private fb: FormBuilder) { }

    ngOnInit(): void { }

    agInit(params: CustomCellRendererParams): void {
        this.visitId = params.visitId;
        this.periodVisitId = params.periodVisitId;
    }

    refresh(params: ICellRendererParams<any, any, any>): boolean {
        this.rowNode = params.node
        return true;
    }

    openEditVisistDialog() {
        this._child.openEditVisitDialog(this.visitId, this.periodVisitId);
    }

    openCancelVisistDialog() {
        this._child.openCancelVisistDialog(this.visitId, this.periodVisitId);
    }
}

export interface CustomCellRendererParams extends ICellRendererParams<any, any, any> {
    periodVisitId: number;
    visitId: number;
}
