import { DestroyRef, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { debounceTime, fromEvent, of, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface LineConnection {
    from: string;
    to: string;
    dash?: boolean;
    thin?: boolean;
}
@Directive({
    selector: '[appLineConnector]'
})
export class LineConnectorDirective implements OnInit {
    @Input() connections: LineConnection[] = [];

    private lines: HTMLElement[] = [];

    constructor(private el: ElementRef, private renderer: Renderer2, private destroyRef: DestroyRef) {}

    ngOnInit(): void {
        setTimeout(() => this.connectCells());
        this.setupResizeListener();
    }

    private setupResizeListener(): void {
        fromEvent(window, 'resize')
            .pipe(
                debounceTime(5),
                switchMap(() => {
                    this.removeOldLines();
                    return of(null)
                }),
                debounceTime(100),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => this.connectCells());
    }

    private connectCells(): void {
        const doc = document.querySelector('.partogram-modal') ?? document;
        this.connections.forEach(connection => {
            const fromCell = doc.querySelector(`#${connection.from}`) as HTMLElement;
            const toCell = doc.querySelector(`#${this.modifyToCellId(connection.to)}`) as HTMLElement;

            if (fromCell && toCell) {
                this.lines.push(this.createLine(fromCell, toCell, connection));
                this.lines.forEach(line => {
                    this.renderer.appendChild(this.el.nativeElement, line);
                });
            }
        })


    }

    private createLine(cell1: HTMLElement, cell2: HTMLElement, config?: LineConnection): HTMLElement {
        const line = this.renderer.createElement('div');
        this.renderer.addClass(line, 'part-table-line');

        const fromRect = cell1.getBoundingClientRect();
        const fromRectParent = cell1.offsetParent.getBoundingClientRect();
        const toRect = cell2.getBoundingClientRect();
        const toRectParent = cell1.offsetParent.getBoundingClientRect();

        const fromX = fromRect.left - fromRectParent.left;
        const fromY = fromRect.top - fromRectParent.top + fromRect.height;

        const toX = toRect.left - toRectParent.left + toRect.width;
        const toY = toRect.top - toRectParent.top;

        const deltaX = toX - fromX;
        const deltaY = toY - fromY;

        const angleRad = Math.atan2(deltaY, deltaX);
        const angleDeg = angleRad * (180 / Math.PI);

        const lineWidth = Math.sqrt(deltaX ** 2 + deltaY ** 2);

        this.renderer.setStyle(line, 'top', `${fromY - 0.5}px`);
        this.renderer.setStyle(line, 'width', `${lineWidth}px`);
        if (!config.dash) {
            this.renderer.setStyle(line, 'left', `${fromX}px`);
            this.renderer.setStyle(line, 'transform-origin', `0 50%`);
            this.renderer.setStyle(line, 'transform', `rotate(${angleDeg}deg)`);
        } else {
            this.renderer.setStyle(line, 'left', `${fromRect.right - fromRectParent.right - fromRect.width}px`);
            this.renderer.setStyle(line, 'border-style', 'dashed');
        }
        if (config.thin) {
            this.renderer.setStyle(line, 'border-width', `0.5px`);
        }

        return line;
    }

    private modifyToCellId(cellId: string): string {
        const regex = /\b(\w+)-(\d+)-(\d+)-(\d+)/;
        const match = cellId.match(regex);

        if (!match) return cellId;

        return cellId.replace(regex, (_, word, first, second, third) => {
            const period = this.addPeriod(+second, 3);
            return `${word}-${+first - 1 + period.moveHour}-${period.period}-${+third - 1}`;
        })
    }

    private addPeriod(currentPeriod: number, add: number): {period: number, moveHour: number} {
        const totalPeriod = 4;
        return  {
            period: (currentPeriod + add - 1) % totalPeriod + 1,
            moveHour: currentPeriod + add > 4 ? 1 : 0
        }
    }

    private removeOldLines(): void {
        this.lines.forEach(line => this.renderer.removeChild(this.el.nativeElement, line));
        this.lines = [];
    }
}