ng1App.directive('sveidraStageConclusion', 
    ['EntitiesApiFactory', 'TypeaheadSearchHandlers',
     function (EntitiesApiFactory, TypeaheadSearchHandlers) {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f066alk/parts/stage_conclusion.html',
		restrict: 'E',
		require: '^form',
		scope: {
			stage: '='
		},
		link: function (scope: any, element, attrs, formController) {
			scope.form = formController;
			
			// Discharge types
			scope.dischargeTypes = EntitiesApiFactory.getList({classCode: 'f025alk-discharge-type'});
			
			scope.stage.$promise.then(function () {
				if (!scope.stage.endDate) {
					scope.stage.endDate = new Date();
				}
				if (!scope.stage.rehabilitation) {
					scope.stage.rehabilitation = '';
				}
				if (scope.stage.dischargeType) {
					// Resolve discharge type
					scope.dischargeTypes.$promise.then(function () {
						_.each(scope.dischargeTypes, function (type) {
							if (type.code === scope.stage.dischargeType.code) {
								scope.stage.dischargeType = type;
							}
						});
					});
				}
			});
			
			scope.dischargeTypeChanged = function () {
				var dischargeType = this.stage.dischargeType; 
				if (dischargeType) {
					if (dischargeType.code > 5) {
						this.stage.nextOrganizationId = null;
						this.stage.nextOrganizationSveidraId = null;
						this.stage.nextOrganizationName = null;
						
						this.organizationSearchParam = null;
					}
					if (dischargeType.code == 8) {
						this.stage.stageEndDate = new Date();
					} else {
						this.stage.stageEndDate = null;
					}
					if (dischargeType != 9) {
						this.stage.additionalDeathDiagnoses = [];
					}
				}
				
				this.mainDeathDiagnosisSearchParam = null;
				this.additionalDeathDiagnosisSearchParam = null;
				this.searchAdditionalInTlk10am = true;
			};
			
			// Organization
			scope.organizationSearchParam = null;
			scope.searchForOrganization = TypeaheadSearchHandlers.ORGANIZATIONS;
			
			scope.changeOrganization = function (organization) {
				if (organization) {
					if (organization.id) {
						this.stage.nextOrganizationId = organization.id;
						this.stage.nextOrganizationName = organization.name;
						this.stage.nextOrganizationSveidraId = organization.sveidraId;
					}
				} else {
					this.stage.nextOrganizationId = null;
					this.stage.nextOrganizationName = null;
					this.stage.nextOrganizationSveidraId = null;
				}
			};
			
			// Death diagnoses
			scope.mainDeathDiagnosisSearchParam = null;
			scope.additionalDeathDiagnosisSearchParam = null;
			scope.searchAdditionalInTlk10am = true;
			
			scope.searchForDiagnosis = function (value, in10am) {
				if (in10am) {
					return TypeaheadSearchHandlers.ICD10(value);
				}
				return TypeaheadSearchHandlers.ICD0(value);
			};
			
			scope.changeMainDeathDiagnosis = function (diagnosis) {
				console.log(scope.mainDeathDiagnosisSearchParam);
				if (diagnosis) {
					if (diagnosis.code) {
						scope.stage.mainDeathDiagnosis = diagnosis;
					}
				} else {
					scope.stage.mainDeathDiagnosis = null;
				}
			};
			
			scope.changeAdditionalDeathDiagnosis = function (diagnosis) {
				if (diagnosis && diagnosis.code) {
					if (!this.stage.additionalDeathDiagnoses) {
						this.stage.additionalDeathDiagnoses = [];
					}
					this.stage.additionalDeathDiagnoses.unshift(diagnosis);
					this.additionalDeathDiagnosisSearchParam = null;
				}
			};
			
			scope.deleteAdditionalDeathDiagnosis = function (index) {
				this.stage.additionalDeathDiagnoses.splice(index, 1);
			};
		}
	};
}]);