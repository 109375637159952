angular.module('erx.prs').directive('erxDocumentPrescriptions', function () {
  return {
    restrict: 'E',
    scope: {options: '='},
    replace: false,
    templateUrl: 'modules/erx/prs/small_prescription_lists/document_prescriptions.html',
    controller: ["$scope", "ErxPrescriptionsApiFactory", function ($scope, ErxPrescriptionsApiFactory) {
      
      // Skyrimų gavimas pagal kompozicijos ID
      $scope.getMedPrescriptions = function () {
        $scope.promise = ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount({compositionId: $scope.options.getCompositionId()}, function (res) {
          $scope.prescriptions = res.result;
        });
      };
      
      // Ar skyrimų sąrašas yra kraunamas
      $scope.isLoading = function () {
        return !$scope.promise || !$scope.promise.$resolved;
      }
      $scope.getMedPrescriptions();
    }]
  };
});