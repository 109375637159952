angular.module('erx.prs').directive('erxUnisignCallback', function () {
  var erxUnisignCallbackCtrl = function ($scope, $window, ErxPrescriptionsApiFactory, ErxDocumentsApiFactory) {
    /*ErxPrescriptionsApiFactory.confirmSigning({ id: $scope.options.prescriptionId }).$promise.then
      (
      function (res) {
        console.log(res);
        $scope.options.redirectToViewPrsFormWithStatus(res.status);
      }
      );*/
      var transaction = $window.sessionStorage.getItem('erx_sign_transaction');
      $window.sessionStorage.removeItem('erx_sign_transaction');
      ErxDocumentsApiFactory.confirmSigning({ transactionId: transaction }).$promise.then
      (
      function (res) {
        $window.parent.location = '/'
        $scope.options.redirectToPrsListWithStatus(res.status);
      }
      );
  };
  erxUnisignCallbackCtrl.$inject = ["$scope", "$window", "ErxPrescriptionsApiFactory", "ErxDocumentsApiFactory"];
  return {
    restrict: 'E',
    scope: { options: '=' },
    templateUrl: 'modules/erx/prs/prescription_form/signing/erx_unisign_callback.html',
    controller: erxUnisignCallbackCtrl
  }
});

