import angular from "angular";

export const medvaisServicesModule: angular.IModule = angular.module('medvais.services', []);
export default medvaisServicesModule;

angular.module('medvais.services').service('MedvaisPatientSearchService', ["MedvaisPatientApiFactory", "$filter", "$q", "$timeout", function (MedvaisPatientApiFactory, $filter, $q, $timeout) {
    var dateFormat = 'yyyy-MM-dd';
    this.search = function (value) {
        return MedvaisPatientApiFactory.query({query: value}).$promise.then(
                function (response) {
                    return _.map(response, function (v) {
                        v.label = v.givenName + ' ' +
                                v.familyName +
                                ($filter('date')(v.birthDate, dateFormat) ? ' (gim. ' + $filter('date')(v.birthDate, dateFormat) + ')' : '');
                        return v;
                    });

                }
        ).finally(function () {
        });
    };

    this.getPatientLabel = function (patient) {
        return patient.givenName + ' ' +
                patient.familyName +
                ($filter('date')(patient.birthDate, dateFormat) ? ' (gim. ' + $filter('date')(patient.birthDate, dateFormat) + ')' : '');
    };

    this.getPatientId = function (patient) {
        return patient.id;
    };


    this.getPatientData = function (id) {
        var deferred = $q.defer();

        if (id) {
            MedvaisPatientApiFactory.getById({id: id}).$promise.then(
                    function (response) {
                        return deferred.resolve(response);
                    }
            );
        } else {
            deferred.resolve(null);
        }
        return deferred.promise;

    };

}]);


angular.module('medvais.services').service('MedvaisImgStudyListFiltersService', ["$rootScope", function ($rootScope) {

    this.setFilter = function (value) {
        sessionStorage.imgStudyListFilter = JSON.stringify(value);
    };

    this.getFilter = function () {
        if (sessionStorage.imgStudyListFilter) {
            return JSON.parse(sessionStorage.imgStudyListFilter);
        } else {
            return null;
        }
    };

}]);


angular.module('medvais.services').service('MedvaisDocStateService', ["$filter", function ($filter) {

    this.getStatusLabel = function (status, isSigned) {
        var result;

        switch (status) {
            case 'new':
            case '':
                result = 'NEW';
                break;
            case 'preliminary':
                result = 'PRELIMINARY';
                break;
            case 'final':
            case 'final_':
                if (isSigned === true) {
                    result = 'FINAL_SIGNED';
                } else {
                    result = 'FINAL_UNSIGNED';
                }
                break;
            case 'enteredInError':
            case 'entered in error':
                if (isSigned === true) {
                    result = 'CANCELED_SIGNED';
                } else {
                    result = 'CANCELED_UNSIGNED';
                }
                break;
            case 'amended':
                if (isSigned === true) {
                    result = 'AMENDED_SIGNED';
                } else {
                    result = 'AMENDED_UNSIGNED';
                }
                break;
            default:
                result = null;
        }

        return result;
    };

    this.canCancel = function(status) {
        return _.contains(['final_', 'final', 'preliminary', 'amended'], status);
    };

    this.canEdit = function(status) {
        return _.contains(['preliminary'], status);
    };

}]);

angular.module('medvais.services').service('MedvaisFormLoaderService', ["$rootScope", "$q", function ($rootScope, $q) {
    this.startFormLoading = function () {
        $rootScope.medvaisFormLoading = true;
    };

    var endFormLoading = function () {
        $rootScope.medvaisFormLoading = false;
    };

    this.endFormLoading = function () {
        if (arguments.length > 0) {
            $q.all(arguments).finally(endFormLoading);
        } else {
            endFormLoading();
        }
    };

}]);


angular.module('medvais.services').service('MedvaisValidationService', function () {

    this.validateFileSize = function (fileSize) {
        if (fileSize < 10485760) {
            return true;
        } else {
            return false;
        }

    };

    this.validateFileType = function (fileType) {
        if (fileType.toLowerCase().indexOf('image/') >= 0 || fileType.toLowerCase().indexOf('/pdf') >= 0) {
            return true;
        } else {
            return false;
        }

    };

});

angular.module('medvais.services').service('MedvaisDiagnosesService', function () {

    this.getDiagnosisLabel = function (diagnosis) {
        if (diagnosis) {
            return diagnosis.code + ' ' + diagnosis.name;
        }
    };

});

angular.module('medvais.services').service('MedvaisACHIService', function () {

    this.getACHILabel = function (achi) {
        if (achi) {
            return achi;
        }
    };

});

angular.module('medvais.services').service('MedvaisDateUtilsService', ["$filter", function ($filter) {

    this.getShortFormat = function () {
        return 'yyyy-MM-dd';
    };

    this.getToday = function () {
        return new Date();
    };

    this.getTodayStr = function () {
        return $filter('date')(this.getToday(), this.getShortFormat());
    };

    this.get2DaysFromToday = function () {
        var date = new Date();
        date.setDate(date.getDate() - 2);
        return date;
    };

    this.get2DaysFromTodayStr = function () {
        return $filter('date')(this.get2DaysFromToday(), this.getShortFormat());
    };

}]);


angular.module('medvais.services').service('Medvais027vaFormService', ["$log", "$filter", "$window", "MessageService", function ($log, $filter, $window, MessageService) {

    this.validate = function(htmlForm, data, finalFlag, successCallback) {
        MessageService.clearAll();

        if (data.imagingStudy.patientNeedIdentification === true) {
            MessageService.error($filter('translate')('027va.unidentified.patient'));
            $window.scrollTo(0, 10);
            return;
        }

        if (htmlForm.encounterList) {
            htmlForm.encounterList.$setValidity("required", true);
        }
        if (htmlForm.achiCode) {
            htmlForm.achiCode.$setValidity("required", true);
        }
        if (htmlForm.imagingStudyDescription) {
            htmlForm.imagingStudyDescription.$setValidity("required", true);
        }
        if (htmlForm.conclusion) {
            htmlForm.conclusion.$setValidity("required", true);
        }
        if (htmlForm.mainDiagnosis) {
            htmlForm.mainDiagnosis.$setValidity("required", true);
        }
        htmlForm.$setPristine();

        if (data) {
            if (htmlForm.encounterList && !data.encounterRef) {
                htmlForm.encounterList.$setValidity("required", false);
            }
            if (!data.achiCode) {
                htmlForm.achiCode.$setValidity("required", false);
            }
            if (!data.imagingStudyDescription && finalFlag === true) {
                htmlForm.imagingStudyDescription.$setValidity("required", false);
            }
            if (!data.conclusion && finalFlag === true) {
                htmlForm.conclusion.$setValidity("required", false);
            }
            if (!data.mainDiagnosis.diagnosis && finalFlag === true) {
                htmlForm.mainDiagnosis.$setValidity("required", false);
            }
        }

        if (htmlForm.$valid && successCallback) {
            successCallback();
        } else {
            _.each(htmlForm, function (value, key) {
                if (value.$setViewValue) {
                    return value.$setViewValue(value.$viewValue);
                }
            });

            MessageService.error($filter('translate')('medvais.err.msg.form.has.errors'));
            _.scrollToFirstInvalid();
        }
    };

}]);

angular.module('medvais.services').service('MedvaisErrorHandler', function () {

    this.handlePatientIdentification = function(error) {
        var result: any = {}
        result.type = 'error';
        result.msg = 'medvais.err.msg.common.patient.identification';

        if (error.status === 500) {
            if (error.data.code && error.data.type && error.data.code === 'identification.failure.has.active.document') {
                result.type = 'error';
                result.msg = 'medvais.err.msg.identification.failure.has.active.document';
            } else if (error.data.code && error.data.type && error.data.code === 'identification.failure.related.study') {
                result.type = 'error';
                result.msg = 'medvais.err.msg.identification.failure.related.study';
            }
        }

        return result;
    }

});

angular.module('medvais.services').factory('MedvaisPageController', function () {

    function MedvaisPageController() {

        var _page = 1;
        var _loadedCount = 0;
        var _count = 8;
        var _total = 0;
        var _wasSearched = false;

        this.getPage = function () {
            return _page;
        };

        this.getPageTotal = function () {
            // console.log('Total: ', _total);
            // console.log('Count: ', _count);
            // console.log('Page total: ', Math.ceil(_total / _count));
            return Math.ceil(_total / _count);
        };

        this.getCount = function () {
            return _count;
        };

        this.getTotal = function () {
            return _total;
        };

        this.setTotal = function (total) {
            _total = total;
        };

        this.wasSearched = function (param) {
            if (param !== undefined && param !== null) {
                _wasSearched = param;
            }
            return _wasSearched;
        };

        this.hasMore = function () {
            return (_total > _loadedCount) && _loadedCount > 0;
        };

        this.afterLoad = function (total) {
            _loadedCount = _count * _page;
            _total = total;
        };

        this.beforeLoad = function () {
            _page++;
        };

        this.reset = function () {
            _page = 1;
            _loadedCount = 0;
        };

    }

    return (MedvaisPageController);

});

angular.module('medvais.services').service('MedvaisUtils', function () {

    this.getFullName = function (p) {

        if (!p) {
            return null;
        }

        return ((_.exists(p.namePrefix) && !_.isBlank(p.namePrefix)) ? p.namePrefix + ' ' : '') +
            (p.givenName ? p.givenName + ' ' : '') +
            (p.familyName ? p.familyName : '');
    };
});

