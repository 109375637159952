angular.module('medvais.api').factory('MedvaisMediaApiFactory', ["$resource", function ($resource) {

    var URL_BASE = '/medvais-api/media';

    return $resource('', {}, {
        getFile: {
            method: 'GET',
            url: URL_BASE + '/file?mediaRef=:mediaRef'
        }
    });


}]);


angular.module('medvais.api').service('MedvaisMediaFileUploader', ["$upload", "FormLoaderService", function ($upload, FormLoaderService) {
    var URL_BASE = '/medvais-api/media';

    this.upload = function (file, displaySuccess, displayError) {
        $upload.upload({
            url: URL_BASE + '/',
            data: file
        }).progress(function (evt) {
            
        }).success(function (data, status, headers, config) {
            FormLoaderService.endFormLoading();
            (displaySuccess || angular.noop)();
        }).error(function () {
            FormLoaderService.endFormLoading();
            (displayError || angular.noop)();
        });
    };

}]);


angular.module('medvais.api').service('MedvaisSyncMediaFileUploader', ["$q", "$upload", "FormLoaderService", function ($q, $upload, FormLoaderService) {
    var URL_BASE = '/medvais-api/media';
    
    var promise = function( callback ) {
        var deferred = $q.defer();
        callback( deferred );
        return deferred.promise;
    };
    
    this.upload = function (file) {
        return promise( function( deferred ) {
            $upload.upload({
                url: URL_BASE + '/',
                data: {file: file}
            }).progress(function (evt) {
            }).success(function (data, status, headers, config) {
                FormLoaderService.endFormLoading();
                deferred.resolve(data);
            }).error(function () {
                FormLoaderService.endFormLoading();
                deferred.reject();
            });
        });
    };
    
    
}]);

