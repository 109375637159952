<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Nt.formResearch.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto req">
            <div class="col-6 col-lg-3 mt-1">
                <app-checkbox
                    *ngFor="let option of answersVariants?.['planningResearch']; let i = index"
                    inputId="researchPlanned{{i}}"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!editable"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'researchIsPlanned')"
                    (checkboxChange)="updateControl(option.name, 'researchIsPlanned')"
                ></app-checkbox>
                <app-form-control-validation [fControl]="formGroup.get('researchIsPlanned')" />
            </div>
        </div>
        <app-input
            class="mt-3 d-block"
            [class.d-none]="!checkboxIsChecked('Kiti tyrimai', 'researchIsPlanned')"
            [fControl]="formGroup.get('otherStudiesIsPlanned')"
        ></app-input>
        <app-form-control-validation [fControl]="formGroup.get('otherStudiesIsPlanned')" />
    </div>
</nhr-record-widget>