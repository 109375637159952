ng1App.factory('AuthApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/pub/users';
    return $resource('', {}, {
        // TODO: remove this
        doIpasasAuth: {
            method: 'POST',
            url: URL_BASE + '/auth/ipasas'
        },
        doLogout: {
            method: 'POST',
            url: apiUrl + '/session/logout'
        }
    });
}]);