/* global app */

ng1App.factory("AccountSettingsApiFactory",
    ['$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/account-settings';

    return $resource('', {}, {
        get: {
            method: 'GET',
            url: URL_BASE
        },
        save: {
            method: 'POST',
            url: URL_BASE
        }
    });
}]);