<div>
    <h1>{{ "E113-NNA Nėštumo nutrūkimo / nutraukimo patvirtinimo aktas" | translate }}</h1>
    <app-document-caption [captionData]="documentCaptionData"></app-document-caption>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <app-ngn-caption [captionData]="ngnCaptionData"></app-ngn-caption>
    <app-encounter-block></app-encounter-block>
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Nėštumo duomenys' | translate" id="pregnancyData">
        <div class="row req">
            <app-select id="psychologistConsultation" labelText="Psichologo konsultacija" labelClass="fs-6 fw-bold"
                class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column" [values]="psychologistConsultationArray"
                [showErrors]="true"
                [fControl]="formGroup?.get('generalInfo.psychologistConsultation')" />
            <app-select id="socialWorkerConsultation" labelText="Socialinio darbuotojo konsultacija"
                labelClass="fs-6 fw-bold" class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                [values]="psychologistConsultationArray"
                [showErrors]="true"
                [fControl]="formGroup?.get('generalInfo.socialWorkerConsultation')" />
        </div>
        <div class="row req">
            <app-select id="pregnancyTerminationReason" labelText="Nėštumo nutraukimo priežastis:"
                labelClass="fs-6 fw-bold" class="col-sm-4 col-md-4 col-lg-4 d-flex flex-column"
                [values]="pregnancyTerminationReasonArray"
                [showErrors]="true"
                [fControl]="formGroup?.get('generalInfo.pregnancyTerminationReason')"
                (onChange)="onPregnancyTerminationReasonChanges()" />
            <app-select *ngIf="
                    formGroup?.get('generalInfo.pregnancyTerminationReason').value ===
                    pregnancyTerminationReason.PregnancyTerminationAccordingMedIndications
                " class="col-sm-4 col-md-4 col-lg-4 d-flex flex-column" _id="medicalIndicationsPregnancyTermination"
                labelText="Nėštumo nutraukimas dėl medicininių indikacijų teisės aktų nustatyta tvarka:"
                labelClass="fs-6 fw-bold" [values]="medicalIndicationsPregnancyTerminationArray"
                [showErrors]="true"
                [fControl]="formGroup?.get('generalInfo.medicalIndicationsPregnancyTermination')"
                (onChange)="getOthersPregnancyTerminationReason.reset()" />
            <app-input *ngIf="
                    !!formGroup?.get('generalInfo.pregnancyTerminationReason').value &&
                    formGroup?.get('generalInfo.pregnancyTerminationReason').value ===
                        pregnancyTerminationReason.PregnancyTerminationAccordingMedIndications &&
                    formGroup?.get('generalInfo.medicalIndicationsPregnancyTermination').value ===
                        medicalIndicationsPregnancyTermination.Other
                " class="col-sm-4 col-md-4 col-lg-4 d-flex flex-column" labelText="Kita nėštumo nutraukimo priežastis:"
                labelClass="fs-6 fw-bold" type="textarea"
                [showErrors]="true"
                [fControl]="formGroup?.get('generalInfo.othersPregnancyTerminationReason')" />
        </div>
        <div class="row req">
            <app-select id="appliedTreatment" labelText="Taikytas gydymas" labelClass="fs-6 fw-bold"
                class="col-sm-12 col-md-12 col-lg-12" [values]="appliedTreatmentArray"
                [fControl]="formGroup?.get('generalInfo.appliedTreatment')" />
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Operacijos ir kitos intervencijos:' | translate"
        id="operations">
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Diagnozės' | translate" id="diagnosis">
    </nhr-record-widget>

    <app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
        (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
        [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
        [submitButtonDisabled]="true" [saveButtonDisabled]="!formGroup.valid"></app-modification-actions>
</div>