import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {PrescriptionsSummaryModel} from "../../../models/prescriptions-summary.model";
import {EnvironmentSettings} from "../../../../../environments/env";
import moment from "moment";

@Component({
    selector: 'prescriptions-summary',
    templateUrl: './prescriptions-summary.component.html'
})
export class PrescriptionsSummaryComponent implements OnInit {
    protected readonly moment = moment;
    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() prescriptions: PrescriptionsSummaryModel[] = [];
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        EnvironmentSettings.isSpecialist() ?
            this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]) :
            this.router.navigate([`patients/${this.patientId}/documents/${docId}`]);

    }

    showMedicationName(prs: PrescriptionsSummaryModel): boolean {
        return (prs.prescriptionTagsNominalTag || prs.status === 'completed') && prs.medication?.name !== undefined;
    }

    getRecipesUrl() {
        return EnvironmentSettings.isSpecialist() ?
            `/dp/patients/${this.patientId}/erx` :
            `/patients/${this.patientId}/recipes-history`;

    }

    getDispensesUrl() {
        return EnvironmentSettings.isSpecialist() ?
            `/dp/patients/${this.patientId}/erx/dispenses/list` :
            `/patients/${this.patientId}/dispenses-history`;

    }
}