import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { EntityModel } from '../../../models/entity.model';
@Component({
    selector: "app-inr-notification-form-subform",
    templateUrl: "./inr-notification-form-subform.component.html",
})
export class InrNotificationFormSubformComponent {
    @Input() editable: boolean = true;

    notificationAbout: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pranešimas dėl vaistinio preparato'
        },
        {
            id: '1',
            code: '1',
            name: 'Pranešimas dėl vakcinos'
        }
    ];

    formGroup: FormControl = this.fb.control(null, [Validators.required]);
    constructor(
        private fb: FormBuilder
    ) {
    }
}
