import angular, {IModule} from "angular";
import "jquery"

import ngResource from 'angular-resource';
import ngAnimate from 'angular-animate';
import 'angular-strap';
import ngCookies from 'angular-cookies'
import ngSanitize from 'angular-sanitize'
import ngRoute from 'angular-route'
import 'angular-route-segment'
import 'angular-dragdrop'
import 'angular-gettext'
import 'ng-file-upload/dist/angular-file-upload-all.min'

import 'angular-ui-utils/modules/alias/alias'
import 'angular-ui-utils/modules/event/event'
import 'angular-ui-utils/modules/format/format'
import 'angular-ui-utils/modules/highlight/highlight'
import 'angular-ui-utils/modules/ie-shiv/ie-shiv'
import 'angular-ui-utils/modules/include/include'
import 'angular-ui-utils/modules/indeterminate/indeterminate'
import 'angular-ui-utils/modules/inflector/inflector'
import 'angular-ui-utils/modules/jq/jq'
import 'angular-ui-utils/modules/keypress/keypress'
import 'angular-ui-utils/modules/mask/mask'
import 'angular-ui-utils/modules/reset/reset'
import 'angular-ui-utils/modules/route/route'
import 'angular-ui-utils/modules/scroll/scroll'
import 'angular-ui-utils/modules/scroll/scroll-jqlite'
import 'angular-ui-utils/modules/scrollfix/scrollfix'
import 'angular-ui-utils/modules/showhide/showhide'
import 'angular-ui-utils/modules/unique/unique'
import 'angular-ui-utils/modules/validate/validate'
import 'angular-ui-utils/modules/utils'

import * as jq from "jquery";

// overrides global $ once again, because of angular-strap lib override causing undefined
$ = jq.noConflict();

export function initNg1Module(deps?: any[]) {
    console.debug("Registering NG1-ESPBI module")
    return angular.module("espbi", [
        ngCookies,
        ngAnimate,
        ngSanitize,
        ngResource,
        ngRoute,
        'route-segment',
        'view-segment',
        'ui.utils',
        'mgcrea.ngStrap',
        'ngDragDrop',
        'gettext',
        'angularFileUpload',
        ...deps
    ]);
}

export function configNg1Module(ng1App: IModule) {

    // dummy controller for mockups
    ng1App.controller('MockupCtrl',
    ['$scope',
     function ($scope) {
    }]);

    ng1App.config([
        '$typeaheadProvider', '$routeSegmentProvider', '$routeProvider', '$locationProvider', '$httpProvider', '$modalProvider', '$datepickerProvider', '$timepickerProvider', '$compileProvider',
        function ($typeaheadProvider, $routeSegmentProvider, $routeProvider, $locationProvider, $httpProvider, $modalProvider, $datepickerProvider, $timepickerProvider, $compileProvider) {

            // NB! ngmin don't understand this injection. Let it be specified before ngmin!
            $httpProvider.interceptors.push(['$q', '$rootScope', function ($q, $rootScope) {
                return {
                    'responseError': function (rejection) {
                        if (rejection.status == 401) {
                            $rootScope.$emit('DO_LOGIN');
                        } else if (299 < rejection.status && rejection.status < 600) {
                            if (rejection.data.messages) {
                                _.each(rejection.data.messages, function (message) {
                                    $rootScope.$emit('SHOW_MSG', message);
                                });
                            } else if (rejection.data.region) {
                                $rootScope.$emit('SHOW_MSG', {
                                    type: 'error',
                                    code: rejection.status,
                                    content: rejection.data.error,
                                    onlyContent: true,
                                    region: rejection.data.region
                                });
                            } else if (typeof rejection.data == 'string' || rejection.data instanceof String) {
                                var ooError = undefined;
                                try {
                                    ooError = $(rejection.data).find("issue").find("details").attr("value");
                                } catch (err) {
                                    // console.log(err)
                                }
                                $rootScope.$emit('SHOW_MSG', {
                                    type: 'error',
                                    code: rejection.status,
                                    content: (ooError ? 'OperationOutcome: ' + ooError : _.stripTags(rejection.data)),
                                    onlyContent: true
                                });
                            } else {
                                console.log('failure ', rejection.data);
                            }
                        }
                        return $q.reject(rejection);
                    }
                };
            }]);

            //initialize get if not there
            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = {};
            }
            //disable IE ajax request caching
            //$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
            $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
            $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';


            angular.extend($typeaheadProvider.defaults, {
                animation: 'animation-flipX',
                minLength: 1,
                limit: 10
            });

            angular.extend($modalProvider.defaults, {
                templateUrl: '/templates/layout/angular-strap/modal.tpl.html',
                html: true,
                show: false
            });

            angular.extend($datepickerProvider.defaults, {
                templateUrl: '/templates/layout/angular-strap/datepicker.tpl.html',
                startWeek: 1
            });

            angular.extend($timepickerProvider.defaults, {
                templateUrl: '/templates/layout/angular-strap/timepicker.tpl.html'
            });
            // Add Skype protocol to angular whitelist
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|skype):/);

        }]);


    ng1App.run([
        '$timeout', '$log', '$rootScope', 'SessionApiFactory', 'gettextCatalog', 'IntegratedComponentService',
        function ($timeout, $log, $rootScope, SessionApiFactory, gettextCatalog, IntegratedComponentService) {
            function isNotPublic(urlPath) {
                if (urlPath.$$route) {
                    return urlPath.$$route.originalPath != "/integrated/address";
                }
                return true;
            }

            $rootScope.$on('$routeChangeStart', function (event, next, current) {
                if (isNotPublic(next)) {
                    if (!$rootScope.currentUser) {
                        $rootScope.currentUser = SessionApiFactory.getCurrent();
                        $rootScope.token = SessionApiFactory.getToken();
                    }

                    $rootScope.currentUser.$promise.then(function (result) {
                        $rootScope.restrictedMode = result.authType == "CERTIFICATE";
                        if ($rootScope.restrictedMode) {
                            IntegratedComponentService.configure();
                        }
                        IntegratedComponentService.checkExpiredSession($rootScope.restrictedMode);
                    }, function (error) {
                        IntegratedComponentService.checkExpiredSession(false);
                    });
                }
            });

            $rootScope.$on('$routeChangeError', function () {
                console.log('ERROR');
            });

            // TODO: why this function in $timeout???? It should be sufficient to call it once on service creation
            // this is potential race condition to leave some text untranslated
            $timeout(function () {
                const lang = $('html').attr('lang')
                gettextCatalog.setCurrentLanguage(lang);
            });
        }]);
}
