import { Component, OnInit } from "@angular/core";
import { RouteParams } from "src/app/ng1.routeParams";

@Component({
    selector: 'dp-patient-calendar',
    templateUrl: './dp-patient-calendar.component.html'
})
export class DpPatientCalendarComponent implements OnInit {
    patientId: string;
    constructor(private readonly params: RouteParams) { }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
    }
}