<div *ngIf="ultrasoundData" class="mt-3">
    <div class="card mb-3">
        <div class="card-header">{{ ultrasoundData.ultrasoundStage | translate }}</div>
        <div class="card-body">
            <div class="row ms-0 mb-3">
                <div *ngIf="ultrasoundData.researchDate" class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Tyrimo data" | translate }}</div>
                    <div class="row">
                        {{ ultrasoundData.researchDate }}
                    </div>
                </div>
                <div *ngIf="ultrasoundData.researchPurpose" class="col-sm-3 col-md-3 col-lg-3 p-0 d-flex flex-column">
                    <div class="row fw-bold">{{ "Tyrimo tikslas" | translate }}</div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                            {{ ultrasoundData.researchPurpose }}
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                            {{ ultrasoundData.researchPurposeOther }}
                        </div>
                    </div>
                </div>
                <div *ngIf="ultrasoundData.researchConditions" class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Tyrimo sąlygos" | translate }}</div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                            {{ ultrasoundData.researchConditions }}
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                            {{ ultrasoundData.researchConditionsOther }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Paskutinių normalių mėnesinių data" | translate }}</div>
                    <div class="row">2022-12-05</div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo trukmė pagal mėnesines" | translate }}</div>
                    <div class="row">20 sav. + 5d</div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo trukmė pagal ankstyvą ultragarsinį tyrimą" | translate }}</div>
                    <div class="row">20 sav. + 5d</div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Nėštumo diena pagal ankstyvą ultragarsinį tyrimą" | translate }}</div>
                    <div class="row">5d</div>
                </div>
                <div *ngIf="ultrasoundData.childCount" class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                    <div class="row fw-bold">{{ "Vaisių skaičius" | translate }}</div>
                    <div class="row">
                        {{ ultrasoundData.childCount }}
                    </div>
                </div>
            </div>

            <div class="card w-100 mb-2">
                <div class="card-header">{{ "Gimda" | translate }}</div>
                <div class="card-body">
                    <div class="row ms-0">
                        <div *ngIf="ultrasoundData.childCount" class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                            <div class="row fw-bold">{{ "Gimdos kaklelio ilgis, mm" | translate }}</div>

                            <div class="fs-6 fst-italic">
                                {{ "(matuojamas tik esant persileidimo ar priešlaikinio gimdymo rizikai)" | translate }}
                            </div>
                            <div class="row">
                                {{ ultrasoundData.cervicalLength }}
                            </div>
                        </div>
                        <div *ngIf="ultrasoundData.childCount" class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                            <div class="row fw-bold">{{ "Gimdos priedų išvaizda" | translate }}</div>
                            <div class="row">
                                {{ ultrasoundData.appearanceUterineAppendages }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card w-100 mb-2">
                <div class="card-header">{{ "Kraujotakos tyrimas" | translate }}</div>
                <div class="card-body">
                    <div class="row">
                        <fieldset class="col d-flex flex-column">
                            <legend class="fw-bold mb-1">{{ "Gimdos arterijos" | translate }}</legend>
                            <div class="row p-3">
                                <div class="col">
                                    <div class="row mb-2">
                                        <div class="col-sm-12 col-md-12 mb-2 d-flex flex-column">
                                            {{ "Dešinioji" | translate }}
                                        </div>
                                        <div
                                            *ngIf="ultrasoundData.rightPI"
                                            class="col-sm-12 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            <div class="row mb-2">
                                                <div class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column flex-md-row">
                                                    {{ "PI" | translate }}
                                                </div>
                                                <div class="col-sm-10 col-md-10 d-flex flex-column">
                                                    {{ ultrasoundData.rightPI }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="ultrasoundData.rightRI"
                                            class="col-sm-12 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            <div class="row mb-2">
                                                <div class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column flex-md-row">
                                                    {{ "RI" | translate }}
                                                </div>
                                                <div class="col-sm-10 col-md-10 d-flex flex-column">
                                                    {{ ultrasoundData.rightRI }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="ultrasoundData.rightProtodiastolicWave"
                                            class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            <div class="row mb-2">
                                                <div class="col-sm-6 col-md-6 d-flex flex-column">
                                                    {{ "Protodiastolinė banga" | translate }}
                                                </div>
                                                <div class="col-sm-6 col-md-6 d-flex flex-column">
                                                    {{ ultrasoundData.rightProtodiastolicWave }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 mb-2 d-flex flex-column">
                                            {{ "Kairioji" | translate }}
                                        </div>
                                        <div
                                            *ngIf="ultrasoundData.leftPI"
                                            class="col-sm-12 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            <div class="row mb-2">
                                                <div class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column flex-md-row">
                                                    {{ "PI" | translate }}
                                                </div>
                                                <div class="col-sm-10 col-md-10 d-flex flex-column">
                                                    {{ ultrasoundData.leftPI }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="ultrasoundData.leftRI"
                                            class="col-sm-12 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            <div class="row mb-2">
                                                <div class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column flex-md-row">
                                                    {{ "RI" | translate }}
                                                </div>
                                                <div class="col-sm-10 col-md-10 d-flex flex-column">
                                                    {{ ultrasoundData.leftRI }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="ultrasoundData.leftProtodiastolicWave"
                                            class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            <div class="row mb-2">
                                                <div class="col-sm-6 col-md-6 d-flex flex-column">
                                                    {{ "Protodiastolinė banga" | translate }}
                                                </div>
                                                <div class="col-sm-6 col-md-6 d-flex flex-column">
                                                    {{ ultrasoundData.leftProtodiastolicWave }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">{{ "Vaisiaus duomenys " | translate }}</div>
        <div class="card-body">
            <div *ngIf="ultrasoundData.fetusData.length" class="mb-2" id="nav-tab">
                <div class="row">
                    <div
                        *ngFor="let fetusData of ultrasoundData.fetusData; let i = index"
                        class="col-sm-1 col-md-1 col-lg-1 d-flex flex-column"
                    >
                        <button
                            class="nav-link"
                            id="{{ 'nav-home-tab'[i] }}"
                            data-bs-toggle="tab"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            [ngClass]="{
                                'text-primary border-bottom border-primary border-2': selectedTabIndex === i
                            }"
                            (click)="setTab(i, fetusData.generalInfo.fetusLetter)"
                        >
                            {{ fetusData.generalInfo.fetusLetter }} {{ "vaisius" | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="getFetusData" class="card w-100 mb-3">
                <div class="card-header">{{ "Ultragarsinis vaisiaus anatomijos tyrimas" | translate }}</div>
                <div class="card-body">
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Bendrieji duomenys apie vaisių" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "Chorioniškumas" | translate }}
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{
                                                getFetusData.generalInfo.chorionicity
                                                    ? ("Taip" | translate)
                                                    : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "Amniotiškumas" | translate }}
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{
                                                getFetusData.generalInfo.amnioticity
                                                    ? ("Taip" | translate)
                                                    : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Viršugalvio sėdmenų matmuo" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.headButtocksMeasurement.mm"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.headButtocksMeasurement.mm }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.headButtocksMeasurement.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.headButtocksMeasurement.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.headButtocksMeasurement.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.headButtocksMeasurement.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.First" class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Sprando vaiskuma" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.neckFertility.mm"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.neckFertility.mm }}
                                    {{ "mm" | translate }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Biparietalinis matmuo" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.biparietalDimension.mm"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.biparietalDimension.mm }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.biparietalDimension.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.biparietalDimension.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.biparietalDimension.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.biparietalDimension.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Galvos apimtis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.headCircumference.mm"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.headCircumference.mm }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.headCircumference.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.headCircumference.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.headCircumference.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.headCircumference.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Pilvo apimtis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.abdominalGirth.mm"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.abdominalGirth.mm }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.abdominalGirth.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.abdominalGirth.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.abdominalGirth.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.abdominalGirth.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Šlaunikaulio ilgis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.femurLength.mm"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.femurLength.mm }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.femurLength.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.femurLength.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.femurLength.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.femurLength.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Žastikaulio ilgis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.humerusLength.mm"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.humerusLength.mm }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.humerusLength.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.humerusLength.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.humerusLength.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.humerusLength.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Vaisiaus svoris" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.fetusWeight.weight"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.fetusWeight.weight }}
                                    {{ "mm, atitinka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.fetusWeight.week"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.fetusWeight.week }}
                                    {{ "sav" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.generalInfo.fetusWeight.day"
                                    class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                >
                                    {{ getFetusData.generalInfo.fetusWeight.day }}
                                    {{ "d" | translate }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.Third" class="row mb-2">
                                <div class="col d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "Vaisiaus padėtis" | translate }}
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ getFetusData.generalInfo.fetalPosition }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.Third" class="row mb-2">
                                <div class="col d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "Vaisiaus pirmeiga" | translate }}
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ getFetusData.generalInfo.fetalForerunner }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Galva" | translate }}</div>
                        <div class="card-body">
                            <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.First">
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Forma" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.form"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.form }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Kaukolės kaulėjimas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.skullOssification"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.skullOssification }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Skliauto pjautuvas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.sickleVault"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.sickleVault }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Kraujagysliniai rezginiai" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.vascularPlexuses"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.vascularPlexuses }}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First">
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Kaukolės forma, kontūrai" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.form"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.form }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Skaidrioji pertvara" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.transparentPartition"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.transparentPartition }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Vidurinė linija" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.middleLine"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.middleLine }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Smegenų branduoliai" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.brainNuclei"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.brainNuclei }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Smegenėlės" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.tests"
                                        class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.cerebellum.mm"
                                        class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.cerebellum.mm }}
                                        {{ "mm, atitinka" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.cerebellum.week"
                                        class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.cerebellum.week }}
                                        {{ "sav." | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.cerebellum.day"
                                        class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.cerebellum.day }}
                                        {{ "d" | translate }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Didžioji cisterna" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.tests"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.greatCistern.mm"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.greatCistern.mm }}
                                        {{ "mm" | translate }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-12 col-md-3 d-flex flex-column">
                                        {{ "Šoniniai skilveliai" | translate }}
                                    </div>
                                </div>
                                <div class="row ms-2 mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Dešinysis priekinis ragas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.rightAnteriorHorn.tests"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.rightAnteriorHorn.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.rightAnteriorHorn.mm"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.rightAnteriorHorn.mm }}
                                        {{ "mm" | translate }}
                                    </div>
                                </div>
                                <div class="row ms-2 mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Kairysis priekinis ragas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.leftAnteriorHorn.tests"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.leftAnteriorHorn.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.leftAnteriorHorn.mm"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.leftAnteriorHorn.mm }}
                                        {{ "mm" | translate }}
                                    </div>
                                </div>
                                <div class="row ms-2 mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Dešinysis užpakalinis ragas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.rightPosteriorHorn.tests"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.rightPosteriorHorn.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.rightPosteriorHorn.mm"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.rightPosteriorHorn.mm }}
                                        {{ "mm" | translate }}
                                    </div>
                                </div>
                                <div class="row ms-2 mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Kairysis užpakalinis ragas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.leftPosteriorHorn.tests"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.leftPosteriorHorn.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.leftPosteriorHorn.mm"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.leftPosteriorHorn.mm }}
                                        {{ "mm" | translate }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Didžioji jungtis" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.greatConnection"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.greatConnection }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Sprando raukšlė" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.neckCrease.tests"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.neckCrease.tests }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.head.neckCrease.mm"
                                        class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                    >
                                        {{ getFetusData.head.neckCrease.mm }}
                                        {{ "mm" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Veidas" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Profilis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.profile"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.face.profile }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First" class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Viršutinė lūpa" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.upperLip"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.face.upperLip }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Akiduobės" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.eyeSockets"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.face.eyeSockets }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First">
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Nosis, šnervės" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.face.nostrils"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.face.nostrils }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Smakras" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.face.chin"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.face.chin }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Nosies kaulas" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.nasalBone.tests"
                                    class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                >
                                    {{ getFetusData.face.nasalBone.tests }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.nasalBone.mm"
                                    class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                >
                                    {{ getFetusData.face.nasalBone.mm }}
                                    {{ "mm" | translate }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First" class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Prienosinių audinių storis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.peritonealTissueThickness.tests"
                                    class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                >
                                    {{ getFetusData.face.peritonealTissueThickness.tests }}
                                </div>
                                <div
                                    *ngIf="getFetusData.face.peritonealTissueThickness.mm"
                                    class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                >
                                    {{ getFetusData.face.peritonealTissueThickness.mm }}
                                    {{ "mm" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Kaklas, stuburas" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Kaklas" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.neckAndSpine.neck"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.neckAndSpine.neck }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Stuburas" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.neckAndSpine.spine"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.neckAndSpine.spine }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Krūtinės ląsta" | translate }}</div>
                        <div class="card-body">
                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First" class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Krūtinės ląstos forma" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.chest.form"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.chest.form }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{
                                        ultrasoundData.ultrasoundStage !== UltrasoundStages.First
                                            ? "Plaučiai"
                                            : ("Plaučių sritis" | translate)
                                    }}
                                </div>
                                <div
                                    *ngIf="getFetusData.chest.lungs"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.chest.lungs }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Diafragma" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.chest.diaphragm"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.chest.diaphragm }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Širdis" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Širdies veikla" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.heart.activity"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.heart.activity }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Dydis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.heart.size"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.heart.size }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Širdies ašis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.heart.axis"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.heart.axis }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First">
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Aortos išvarymo traktas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.heart.aorticOutflowTract"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.heart.aorticOutflowTract }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Plautinio kamieno išvarymo traktas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.heart.pulmonaryTrunkExcretoryTract"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.heart.pulmonaryTrunkExcretoryTract }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Keturių kamerų vaizdas" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.heart.fourCameraView"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.heart.fourCameraView }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Pilvas" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Skrandis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.belly.stomach"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.belly.stomach }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Žarnynas" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.belly.intestine"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.belly.intestine }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Inkstai" | translate }}
                                </div>
                                <div
                                    *ngIf="
                                        ultrasoundData.ultrasoundStage === UltrasoundStages.First &&
                                        getFetusData.belly.kidneys
                                    "
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.belly.kidneys }}
                                </div>

                                <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First">
                                    <div class="row ms-2 mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ "Dešinysis inkstas (geldelė)" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.belly.rightKidney.tests"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.belly.rightKidney.tests }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.belly.rightKidney.mm"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.belly.rightKidney.mm }}
                                        </div>
                                    </div>

                                    <div class="row ms-2 mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ "Kairysis inkstas (geldelė)" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.belly.leftKidney.tests"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.belly.leftKidney.tests }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.belly.leftKidney.mm"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.belly.leftKidney.mm }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Šlapimo pūslė" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.belly.bladder"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.belly.bladder }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Pilvo siena, virkštelės tvirtinimosi vieta" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.belly.umbilicalCordAttaches"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.belly.umbilicalCordAttaches }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Galūnės" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Dešinioji ranka su plaštaka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.limbs.rightHand"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.limbs.rightHand }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Kairioji ranka su plaštaka" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.limbs.leftHand"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.limbs.leftHand }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Dešinioji koja su pėda" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.limbs.rightLegFoot"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.limbs.rightLegFoot }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Kairioji koja su pėda" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.limbs.leftLegFoot"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.limbs.leftLegFoot }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Virkštelė" | translate }}</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Trys kraujagyslės" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.umbilicalCord.threeBloodVessels"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.umbilicalCord.threeBloodVessels }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First" class="card w-100 mb-2">
                        <div class="card-header">{{ "Lyties organai" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Vyriškieji" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.sexOrgans.mens"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.sexOrgans.mens }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Moteriškieji" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.sexOrgans.womens"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.sexOrgans.womens }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.First" class="card w-100 mb-2">
                        <div class="card-header">{{ "Kraujotaka" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Pro triburį vožtuvą" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.bloodStream.throughThreeWayValve"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.bloodStream.throughThreeWayValve }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Pro veninį lataką" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.bloodStream.throughDuctusVenosus"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.bloodStream.throughDuctusVenosus }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Širdies susitr. dažnis" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.bloodStream.heartAttackFrequency"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.bloodStream.heartAttackFrequency }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Placenta" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Placentos lokalizacija gimdoje*" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.placenta.localization"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.placenta.localization }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First">
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Placenta" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.placenta.placenta"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.placenta.placenta }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Placenta nuo vidinių žiočių" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.placenta.placentaFromInternalMouth"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.placenta.placentaFromInternalMouth }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Placentos išvaizda" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.placenta.placentaAppearance"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.placenta.placentaAppearance }}
                                    </div>
                                </div>
                                <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.Third" class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Placentos subrendimo laipsnis" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.placenta.maturityDegree"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.placenta.maturityDegree }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Patologinis placentos prisitvirtinimas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.placenta.pathologicalAttachmentPlacenta"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.placenta.pathologicalAttachmentPlacenta }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ultrasoundData.ultrasoundStage !== UltrasoundStages.First" class="card w-100 mb-2">
                        <div class="card-header">{{ "Vaisiaus vandenys" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Vaisiaus vandenys" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.amnioticFluids.amnioticFluid"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.amnioticFluids.amnioticFluid }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Vaisiaus vandenys VVI/GVVK, mm" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.amnioticFluids.VVI"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.amnioticFluids.VVI }}/{{ getFetusData.amnioticFluids.GVVK }}
                                    {{ "mm" | translate }}
                                </div>
                            </div>
                            <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.Third">
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Vaisiaus kvėpavimo judesiai" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.amnioticFluids.fetalBreathingMovements"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.amnioticFluids.fetalBreathingMovements }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Vaisiaus judesiai" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.amnioticFluids.fetalMovements"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.amnioticFluids.fetalMovements }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Vaisiaus tonusas" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.amnioticFluids.fetalTone"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.amnioticFluids.fetalTone }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Vaisiaus vandenų kiekis" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.amnioticFluids.amount"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.amnioticFluids.amount }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "KTG" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.amnioticFluids.KTG"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.amnioticFluids.KTG }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                        {{ "Biofizinis profilis" | translate }}
                                    </div>
                                    <div
                                        *ngIf="getFetusData.amnioticFluids.biophysicalProfile"
                                        class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                    >
                                        {{ getFetusData.amnioticFluids.biophysicalProfile }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ultrasoundData.ultrasoundStage === UltrasoundStages.Third" class="card w-100 mb-2">
                        <div class="card-header">{{ "Kraujotakos tyrimas" | translate }}</div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column">
                                    {{ "Virkštelės arterija" | translate }}
                                </div>
                                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "PI" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.umbilicalArtery.PI"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.umbilicalArtery.PI }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "RI" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.umbilicalArtery.RI"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.umbilicalArtery.RI }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ "S/D santykis" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.umbilicalArtery.SDRatio"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.umbilicalArtery.SDRatio }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Kraujotakos klasė" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.bloodFlowTest.circulationClass"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.bloodFlowTest.circulationClass }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-12 d-flex flex-column">
                                    {{ "Vidurinė smegenų arterija" | translate }}
                                </div>
                                <div class="col-sm-2 col-md-2 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "PI" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.middleCerebralArtery.PI"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.middleCerebralArtery.PI }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "RI" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.middleCerebralArtery.RI"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.middleCerebralArtery.RI }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 d-flex flex-column">
                                    <div class="row">
                                        <div class="col-sm-9 col-md-9 col-lg-9 d-flex flex-column">
                                            {{ "S/D santykis" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.middleCerebralArtery.SDRatio"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.middleCerebralArtery.SDRatio }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-9 col-md-9 col-lg-9 d-flex flex-column">
                                            {{ "PSV (Vmax)" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.middleCerebralArtery.PSV"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.middleCerebralArtery.PSV }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 d-flex flex-column">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ "cm/s" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.middleCerebralArtery.cms"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.middleCerebralArtery.cms }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Veninis latakas" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.bloodFlowTest.venousDuct.PI"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.bloodFlowTest.venousDuct.PI }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 d-flex flex-column">
                                    {{ "Blužnies arterija" | translate }}
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "PI" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.splenicArtery.PI"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.splenicArtery.PI }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column flex-md-row">
                                            {{ "RI" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.splenicArtery.RI"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.splenicArtery.RI }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 d-flex flex-column">
                                    <div class="row">
                                        <div class="col-sm-9 col-md-9 col-lg-9 d-flex flex-column">
                                            {{ "S/D santykis" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.splenicArtery.SDRatio"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.splenicArtery.SDRatio }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 d-flex flex-column">
                                    <div class="row mb-2">
                                        <div class="col-sm-9 col-md-9 col-lg-9 d-flex flex-column">
                                            {{ "PSV (Vmax)" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.splenicArtery.PSV"
                                            class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.splenicArtery.PSV }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 d-flex flex-column">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                            {{ "cm/s" | translate }}
                                        </div>
                                        <div
                                            *ngIf="getFetusData.bloodFlowTest.splenicArtery.cms"
                                            class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                        >
                                            {{ getFetusData.bloodFlowTest.splenicArtery.cms }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mb-2">
                        <div class="card-header">{{ "Pastabos, išvados" | translate }}</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column fw-bold">
                                    {{ "Išvada" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.conclusions.conclusion"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.conclusions.conclusion }}
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Pastabos, detali informacija apie patologinius radinius" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.conclusions.notes"
                                    class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column"
                                >
                                    {{ getFetusData.conclusions.notes }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                                    {{ "Ultragarsu nustatyta nėštumo trukmė" | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.conclusions.pregnancyDuration.week"
                                    class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                >
                                    {{ getFetusData.conclusions.pregnancyDuration.week }}
                                    {{ "sav." | translate }}
                                </div>
                                <div
                                    *ngIf="getFetusData.conclusions.pregnancyDuration.day"
                                    class="col-sm-3 col-md-3 col-lg-3 d-flex flex-column"
                                >
                                    {{ getFetusData.conclusions.pregnancyDuration.day }}
                                    {{ "d." | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card w-100">
                <div class="card-header">{{ "Rekomendacijos" | translate }}</div>
                <div class="card-body">
                    <div class="row ms-0 mb-2">
                        <div class="d-flex flex-column">
                            <div class="row fw-bold">
                                {{ "detalesnis ultragarsinis tyrimas nereikalingas" | translate }}
                            </div>
                            <div class="row">
                                {{
                                    ultrasoundData.detailedUltrasoundExamination
                                        ? ("Taip" | translate)
                                        : ("Ne" | translate)
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row ms-0 mb-2">
                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                            <div class="row fw-bold">
                                {{ "atlikti pakartotinį tyrimą, esant" | translate }}
                            </div>
                            <div class="row">
                                {{ ultrasoundData.reExamination.required ? ("Taip" | translate) : ("Ne" | translate) }}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                            <div class="row fw-bold">
                                {{ "nėštumo sav." | translate }}
                            </div>
                            <div class="row">
                                {{ ultrasoundData.reExamination.pregnancyWeek }}
                            </div>
                        </div>
                    </div>
                    <div class="row ms-0 mb-2">
                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                            <div class="row fw-bold">
                                {{ "siųsti konsultacijos į" | translate }}
                            </div>
                            <div class="row">
                                {{
                                    ultrasoundData.sendConsultations.required
                                        ? ("Taip" | translate)
                                        : ("Ne" | translate)
                                }}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 d-flex flex-column">
                            <div class="row fw-bold">
                                {{ "nėštumo sav." | translate }}
                            </div>
                            <div class="row">
                                {{ ultrasoundData.sendConsultations.specialist }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ultrasoundData.otherImportantInfo" class="row ms-0">
                        <div>
                            <div class="row fw-bold">{{ "Kita svarbi informacija" | translate }}</div>
                            <div class="row">
                                {{ ultrasoundData.otherImportantInfo }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
