import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {DisabilitiesSummaryModel} from "../../../models/disabilities-summary.model";
import {SpecialNeedsSummaryModel} from "../../../models/special-needs-summary.model";
import {WorkabilitySummaryModel} from "../../../models/workability-summary.model";

@Component({
    selector: 'disabilities-summary',
    templateUrl: './disabilities-summary.component.html'
})
export class DisabilitiesSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() disability: DisabilitiesSummaryModel;
    @Input() specialNeeds: SpecialNeedsSummaryModel;
    @Input() workability: WorkabilitySummaryModel;
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]);
    }
}