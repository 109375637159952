angular.module('medvais.documents').directive('medvaisTelemedicineInfo', function () {

    return {
        restrict: 'E',
        scope: {
            practitioner: '='
        },
        transclude: true,
        templateUrl: 'modules/medvais/documents/components/telemedicine-info.html',
        link: function (scope: any, element, attrs) {

            scope.getFullName = function (p) {
                if (!p) {
                    return null;
                }
                return ((_.exists(p.namePrefix) && !_.isBlank(p.namePrefix)) ? p.namePrefix + ' ' : '') +
                    (p.givenName ? p.givenName + ' ' : '') +
                    (p.familyName ? p.familyName : '');
            };

        }
    };

});
