<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngFor="let mpp of mpps">
        <p class="mb-0">
            <small>
                {{ mpp.date }}
            </small>
        </p>
        <span *ngIf="mpp.atcCode" class="fw-bold mb-0">
            {{ mpp.atcCode }}
        </span>
        <span class="vr" *ngIf="mpp.brandName"></span>
        <span *ngIf="mpp.brandName" class="fw-bold mb-0">
            {{ mpp.brandName }}
        </span>
        <div class="mb-0">
            {{ mpp.notes }}
        </div>
    </li>
    <li class="list-group-item" *ngFor="let implant of implants">
        <p class="mb-0">
            <small>
                {{ implant.date }}
            </small>
        </p>
        <span *ngIf="implant.identifier" class="fw-bold mb-0">
            {{ implant.identifier }}
        </span>
        <span class="vr" *ngIf="implant.name"></span>
        <span *ngIf="implant.name" class="fw-bold mb-0">
            {{ implant.name }}
        </span>
        <div class="mb-0">
            {{ implant.notes }}
        </div>
    </li>
</nhr-record-widget>