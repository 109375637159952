export enum DocumentTagEnum {
    e025 = "e025",
    e003 = "e003",
    e027 = "e027",
    e027a = "e027a",
    e200 = "e200",
    e200a = "e200a",
    e014 = "e014",
    e014a = "e014a",
    e063 = "e063",
    e103_1 = "e103_1",
    e106 = "e106",
    e106_2_1 = "e106_2_1",
    e027_1 = "e027_1",
    e027_1_I = "e027_1_I",
    e027_1_II = "e027_1_II",
    e047 = "e047",
    e048 = "e048",
    e049 = "e049",
    e083_1 = "e083_1",
    e027aFromE003 = "e027aFromE003",
    e200FromE003 = "e200FromE003",
    e200aFromE003 = "e200aFromE003",
    bornDead = "bornDead",
    e113_nd = "e113_nd",
    e113_ap = "e113_ap",
    e113_rv = "e113_rv",
    e113_ug = "e113_ug",
    e113_tp = "e113_tp",
    e113_nna = "e113_nna",
    e097_npa = "e097_npa",
    e097_ts = "e097_ts",
    e097_at = "e097_at",
    e097_gd = "e097_gd",
    spu = "spu",
    spnk = "spnk",
    spv = "spv",
    sbvf = "sbvf",
    vnapvf = "vnapvf",
    spp = "spp",
    spol = "spol",
    spups = "spups",
    spak = "spak",
    vps = "vps",
    mpp = "mpp",
    mp = "mp",
    evs = "evs",
    kps = "kps",
    template = "template",
    unsorted = "unsorted",
    f025 = "f025",
    f090 = "f090",
    f058 = "f058",
    e110 = "e110",
    e096_nt = "e096_nt",
    e096_ptkl = "e096_ptkl",
    e096_n = "e096_n",
    e096_ge = "e096_ge",
    e096_na = "e096_na",
    e096_cpo = "e096_cpo",
    e096_part = "e096_part",
    e030 = "e030",
}
