import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColDef, FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { Observable } from "rxjs";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { RouteParams } from "../../../../../../ng1.routeParams";
import { AgActionsCell } from "../../../../../../shared/components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { AgPaginationBarComponent } from "../../../../../../shared/components/ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";
import { AgGridCommonConfig } from "../../../../../../shared/configs/ag-grid-common.config";
import { DocumentTagEnum } from "../../../../../../shared/models/document-tag.enum";
import { States } from "./activity-and-home-environment-history.constants";

@Component({
    selector: "aspn-patient-activity-and-home-environment-history",
    templateUrl: "./activity-and-home-environment-history.component.html",
})
export class ActivityAndHomeEnvironmentHistoryComponent implements OnInit {
    @ViewChild(AgPaginationBarComponent) paginationBar: AgPaginationBarComponent;
    backPath: string;
    getOptions() {
        return { ...AgGridCommonConfig }
    }

    gridOptions = this.getOptions()

    constructor(private dpAspnContextService: DpAspnContextService, private params: RouteParams, private router: Router,) { }
    private patientId: string;

    async ngOnInit(): Promise<void> {
        this.patientId = this.params.patientId;
        this.backPath = '/dp/patients/' + this.patientId + '/aspn/summary'
    }

    public activityAndHomeEnvironmentHistoryColDefs: ColDef[] = [
        { field: "assessmentDate", headerName: "Vertinimo data" },
        { field: "assessingPractitioner", headerName: "Vertinantis specialistas" },
        { field: "otherAssesmentDate", headerName: "Kitas vertinimas" },
        {
            field: "status",
            headerName: "Būsena",
            valueFormatter: (params) => {
                return States[params.value];
            },
        },
        {
            field: "actionButton",
            headerName: "",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                console.log("thk", params.context.patientId);

                return {
                    editable: (params.data.status !== 'Cancelled' && params.data.status !== 'Signed') ? true : false,
                    viewable: false,
                    copyable: true,
                    urlPathEdit: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.vnapvf}/` + params.data.id,
                    urlPathCopy: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.vnapvf}/` + params.data.id
                }
            }
        },
    ];

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        autoHeight: true,
        wrapText: true,
    };

    getContext() {
        return {
            patientId: this.patientId,
        };
    }

    public activityAndHomeEnvironmentHistoryData$!: Observable<any[]>;
    //@ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    onGridReady(params: GridReadyEvent) {
        this.activityAndHomeEnvironmentHistoryData$ = this.dpAspnContextService.getActivityAndHomeEnvironmentHistoryByPatientId(this.patientId);
        this.paginationBar.initForAgGrid(params);
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }
}
