import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';

@Component({
    selector: 'app-e096-ptkl-end-assistance',
    templateUrl: './e096-ptkl-end-assistance.component.html',
})
export class E096PtklEndAssistanceComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    maxDate = new Date();

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            bleedingStopped: this.fb.control(null),
            takenToIntensiveCareUnit: this.fb.control(null),
            totalBloodLoss: this.fb.control(null),
            amountCrystalloidSolutionsDispensed: this.fb.control(null),
            addedMassErythrocytes: this.fb.control(null),
            amountFreshFrozenPlasma: this.fb.control(null),
            dosesCryoprecipitatePrescribed: this.fb.control(null),
            amountColloidalSolutionsDropped: this.fb.control(null),
            administeredAmountOxytocin: this.fb.control(null),
            prescribedAmountMisoprospol: this.fb.control(null),
            amountMethylergometrinePrescribed: this.fb.control(null),
            amountCarbetocinPrescribed: this.fb.control(null),
            otherPrescribedMedications: this.fb.control(null),
            endActiveSupport: this.fb.control(null),
            durationActiveSupport: this.fb.control({value: null, disabled: true}),
            ledTheTeam: this.fb.array([], [Validators.required, Validators.maxLength(1)]),
            teamMembers: this.fb.array([], [Validators.required]),
            controlSheetWasFilledOut: this.fb.array([], [Validators.required, Validators.maxLength(1)]),
        });
    }
}
