angular.module('erx.utils').factory('$erxHelpers', ["$window", function ($window) {
  return {
    getFullName: function (p) {
      if (!p) {
        return null;
      }
      return ((angular.isDefined(p.namePrefix) && !(p.namePrefix === '')) ? p.namePrefix + ' ' : '')
              + (p.givenName ? p.givenName + ' ' : '') + (p.familyName ? p.familyName : '');
    },
    getAge: function (from, to) {
      if (from && to) {
        var fromM = moment(Number(from));
        var toM = moment(Number(to));
        var diffDays = toM.diff(fromM, 'days');
        if (diffDays <= 31) {
          return diffDays + ' d.'
        }
        var diffMonths = toM.diff(fromM, 'months');
        if (diffMonths <= 12) {
          return diffMonths + ' mėn.'
        }
        if (diffMonths <= 24) {
          return '1 m. ' + (diffMonths - 12) + ' mėn.'
        }
        return toM.diff(fromM, 'years') + ' m.';
      }
      return '';
    },
    setAllInputsDirty: function (scope) {
      angular.forEach(scope, function (value, key) {
        // We skip non-form and non-inputs
        if (!value || value.$dirty === undefined) {
          return;
        }
        // Recursively applying same method on all forms included in the form
        if (value.$addControl) {
          return this.setAllInputsDirty(value);
        }
        // Setting inputs to $dirty by re-applying its content in itself
        value.$setDirty();
      });
    },
    scrollToFirstInvalid: function (jquerySelector) {
      try {
        $("html, body").animate({
          scrollTop: $('*:not(form).ng-invalid:first').first().offset().top - 60
        }, "fast");
      } catch (err) {

      }
    },
    scrollToFirstInvalidOrCustom: function (selectors) {
      var customSelectors = '';
      if (selectors.length > 0) {
        angular.forEach(selectors, function(value, key) {
          customSelectors += ',' + value;
        });
      }
      try {
        var elem = $('*:not(form).ng-invalid' + customSelectors).first();
        var position = elem.offset().top;
        if (elem.hasClass('ng-invalid')) {
          position -= 60;
        }
        $("html, body").animate({
          scrollTop: position
        }, "fast");
      } catch (err) {

      }
    },
    scrollTo: function(jquerySelector){
        var elem = $(jquerySelector);
        if(elem && elem.offset()){
            $("html, body").animate({
                scrollTop: elem.offset().top
            }, "fast");
        }
    },
    scrollToTop: function (jquerySelector) {
      $("html, body").animate({scrollTop: 0}, "fast");
    },
    getBrowser: function () {

      var userAgent = $window.navigator.userAgent;

      var browsers = {chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i};

      for (var key in browsers) {
        if (browsers[key].test(userAgent)) {
          return key;
        }
      }

      return 'unknown';
    }

  }
}]);
