import { Component, Input } from '@angular/core';
import { PreviousPregnancies } from '../../../../models/pregnancyTerminationInfo.model';
import { E010_Row, E010_RowsGroup } from '../e010-forms.model';

@Component({
    selector: 'app-e010-form-prev-pregnancies',
    templateUrl: './e010-form-prev-pregnancies.component.html',
})
export class E010FormPrevPregnanciesComponent {
    @Input() set data(value: {[key: string]: any}) {
        if(!value) return;
        this.pregnancyData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.prevPregnancies',
                value: value.e113Nd.pregnancyInfo.pregnancy ?? 'N.A.'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.spontaneousMiscarriage',
                value: this.countObjects(this.spontaneousMiscarriageReasons, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.ectopicPregnancy',
                value: this.countObjects(this.ectopicReasons, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.voluntaryTermination',
                value: this.countObjects(this.voluntaryTerminationReasons, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.terminationMedicalIndications',
                value: this.countObjects(this.medicalIndicationsReasons, value.e113Nd.previousPregnancies)
            }
        ];
        this.birthData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.prevBirths',
                value: value.e113Nd.pregnancyInfo.birth
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.stillBirth',
                value: this.countObjects(this.stillBirth, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.prematureStillbirth',
                value: this.countObjects(this.stillBirth, value.e113Nd.previousPregnancies, {upToWeek36: true})
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.bornPremature',
                value: this.countObjects(this.bornPremature, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.diedOnDay06',
                value: this.countObjects(this.diedOnDay06, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.diedOnDay06Premature',
                value: this.countObjects([...this.bornPremature, ...this.diedOnDay06], value.e113Nd.previousPregnancies, {includeAll: true})
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.diedOnDay727',
                value: this.countObjects(this.diedOnDay727, value.e113Nd.previousPregnancies)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.diedOnDay727Premature',
                value: this.countObjects([...this.bornPremature, ...this.diedOnDay727], value.e113Nd.previousPregnancies, {includeAll: true})
            },
        ];
        this.rowsData = [
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.prevPregnanciesTitle',
                rows: this.pregnancyData
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formPrevPregnancies.prevBirths',
                rows: this.birthData
            }
        ]
    };

    rowsData: E010_RowsGroup[];

    private pregnancyData: E010_Row[];
    private birthData: E010_Row[];
    private spontaneousMiscarriageReasons = ['Savaiminis persileidimas', 'Nesivystantis nėštumas / vaisiaus žūtis', 'Gerybinė pūslinė išvisa'];
    private ectopicReasons = ['Ektopinis (negimdinis) nėštumas'];
    private voluntaryTerminationReasons = ['Nėštumo nutraukimas moters pageidavimu'];
    private medicalIndicationsReasons = ['Nėštumo nutraukimas pagal med. indikacijas'];
    private stillBirth = ['Negyvagimis'];
    private bornPremature = ['Gimė neišnešiotas'];
    private diedOnDay06 = ['Mirė 0-6 parų'];
    private diedOnDay727 = ['Mirė 7-27 parų'];

    constructor() {}

    private countObjects(arrayWithResults: string[], data: PreviousPregnancies[], config?: {upToWeek36?: boolean, includeAll?: boolean}): number {
        return data.reduce((acc, obj) => {
            const checkInclude = (source: { code: string; name: string; }[], includeAll: boolean) => {
                return includeAll
                        ? arrayWithResults.every(res => source?.some(item => item.name === res))
                        : arrayWithResults.some(res => source?.some(item => item.name === res))
            }

            const patientInclude = checkInclude(obj.informationProvidedByPatient?.pregnancyOutcome, config?.includeAll);
            const ehrInclude = checkInclude(obj.informationFromPatientEHR?.pregnancyOutcome, config?.includeAll);
            const patientResult = (config?.upToWeek36 ? patientInclude && obj.informationProvidedByPatient.pregnancyWeek <= 36 : patientInclude) ? 1 : 0;
            const ehrResult = (config?.upToWeek36 ? ehrInclude && obj.informationFromPatientEHR.pregnancyWeek <= 36 : ehrInclude) ? 1 : 0;
            return acc + patientResult + ehrResult
        }, 0);
    }
}
