import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { OrganizationModel } from "../../models/organization.model";
import { Observable, Observer, map, of, switchMap } from "rxjs";
import { ApiV2Organizations } from "src/api/api-v2-organizations";
import { DirectiveReset } from "../../services/directive-reset";


@Component({
    selector: 'patient-transported-institution',
    templateUrl: './patient-transported-institution.component.html'
  })
  export class PatientTransportedInstitutionComponent implements OnInit {

    @Input() set institutionValue(value: OrganizationModel) {
        if (value) {
            this.form.controls['institution'].setValue(value);
        }
    }

    form: FormGroup;
    suggestionsOrg$?: any;
    noResult = false;
    count = 10;
    @Output() valueChange = new EventEmitter();
    organizationArray: OrganizationModel[];
    @Input() viewOnly: boolean = false;

    constructor(private formBuilder: FormBuilder, private apiV2Organizations: ApiV2Organizations,
        private directiveReset: DirectiveReset) {
        this.form = this.formBuilder.group({
            institution: [],
            organization: [null, Validators.required],
        });
    }

    ngOnInit(): void {
        this.getOrganizationsList();
        this.directiveReset.reset$.subscribe(() => {
            this.resetForm();
          });
    }

    resetForm(): void {
        this.form.get('institution').patchValue("");
        this.form.get('organization').patchValue("");
        const response = null;
        this.dataCollected(response);
    }

    getOrganizationsList(){
        this.suggestionsOrg$ = new Observable((observer: Observer<string | undefined>) => {
          observer.next(this.form.get('organization').value);
        }).pipe(
          switchMap((query: string) => {
            if (query) {
              return this.getOrganizations(query).pipe(
                map((newDisplay: OrganizationModel[]) => {
                  return newDisplay.map(org => ({
                    ...org,
                    nameAndJarCode: org.name + ', JAR kodas ' + org.jarCode + ', SVEIDRA Nr. ' + org.sveidraId + ' ' + org.registrationAddress.text
                  }));
                })
              );
            }
            return of([]);
          })
        );
    }

    getOrganizations(query): Observable<OrganizationModel[]>{
      return this.apiV2Organizations.getOrganizations(query, this.count, 'ROOT_ONLY').pipe(map((organization: OrganizationModel[]) => this.organizationArray = organization));
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
      this.form.controls['institution'].setValue(e.item);
      this.dataCollected(e.item);
    }

    dataCollected(response) {
        this.valueChange.emit(response);
      }
  }