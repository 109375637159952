ng1App.controller('doctor.patient.VaccineEditCtrl', 
    ['$scope', '$modalConfirm', '$routeParams', '$filter', '$location', 'MessageService', 'VaccinationsApiFactory', 'SubFormValidator', 'PractitionersApiFactory', 'SessionContext',
     function ($scope, $modalConfirm, $routeParams, $filter, $location, MessageService, VaccinationsApiFactory, SubFormValidator, PractitionersApiFactory, SessionContext) {
    MessageService.clear();
    
    $scope.$emit('setHelpPage', 'pakoreguoti-planuojama-skiepa');

    var setVaccinationForEdit = function(){
        VaccinationsApiFactory.getVaccination({id: $routeParams.id}, function (res) {
            $scope.form = res;
            $scope.form.immunizationRecommendation.practitioner = PractitionersApiFactory.getFull({id: SessionContext.practitioner.id});
            $scope.form.immunizationRecommendation.practitionerRef = SessionContext.practitioner.fullId;
        });
    };
    setVaccinationForEdit();
    $scope.goBack = function () {
        window.history.back();
    };
    $scope.clean = function(){
        $modalConfirm.show(
            { title : $filter('translate')('inf.clean.form.tit'), content : $filter('translate')('inf.clean.form') } ).then(function() {
                setVaccinationForEdit();
        });
    };

    $scope.validator = new SubFormValidator('edit-region');
    var validateForm = function (data) {
        MessageService.clearAll();
        $scope.validator.clear();
        if (!data.immunizationRecommendation.recommendations[0].plannedDate) {
            $scope.validator.add('plannedDate');
        }
        var doseNumber = data.immunizationRecommendation.recommendations[0].vaccineDoseNumber;
        if (!doseNumber || doseNumber % 1 !== 0 || doseNumber < 1) {
            $scope.validator.add('referralVaccineDose');
        }
        return !$scope.validator.hasErrors();
    };
    $scope.save = function(){
        if (validateForm($scope.form)) {
            VaccinationsApiFactory.save($scope.form,function(){
                MessageService.registerSuccessEvent();
                $scope.goBack();
            });
        } else {
            $scope.validator.showErrorsAndScroll();
        }
    };
}]);