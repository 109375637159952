import { AfterViewInit, Component, DestroyRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, forkJoin, take } from 'rxjs';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { clearFormArray, formatDateForDatePickers, updateFormArray } from '../../e096-utils/utils';
import {
    E096GeLaborProgressComponent
} from './e096-ge-sub-forms/e096-ge-labor-progress/e096-ge-labor-progress.component';
import {
    E096GeAdministrationAntibioticsComponent
} from './e096-ge-sub-forms/e096-ge-administration-antibiotics/e096-ge-administration-antibiotics.component';

@Component({
    selector: 'app-e096-ge-form',
    templateUrl: './e096-ge-form.component.html',
})
export class E096GeFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: {[key: string]: any}) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);

        this.patchFormWithInitialData(this._initialFormValue);
    }

    @ViewChild(E096GeLaborProgressComponent) laborProgressComponent: E096GeLaborProgressComponent;
    @ViewChild(E096GeAdministrationAntibioticsComponent) antibioticsComponent: E096GeAdministrationAntibioticsComponent;

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: {[key: string]: any};
    private dateFields: string[] = ['placentaTimeDelivery'];

    private updateFormArray = updateFormArray.bind(this) as typeof updateFormArray;
    private clearfArray = clearFormArray.bind(this) as typeof clearFormArray;

    get antibioticsUsed(): boolean {
        return this.formGroup?.get('laborProgress')?.get('antibiotics')?.value === 'Skirta';
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly destroyRef: DestroyRef
    ) {
        forkJoin({
            modeOfDelivery: this.apiV2Entities.getEntitiesList('mode-of-delivery'),
            naturalChildbirthHelp: this.apiV2Entities.getEntitiesList('natural-childbirth-help'),
            laborOnsetMethod: this.apiV2Entities.getEntitiesList('labor-onset-method'),
            robsonGroups: this.apiV2Entities.getEntitiesList('robson-groups'),
            childbirthMedicalPainManagement: this.apiV2Entities.getEntitiesList('childbirth-medical-pain-management'),
            childbirthAntibioticsProphylacticalPrescriptionReason: this.apiV2Entities.getEntitiesList('childbirth-antibiotics-prophylactical-prescription-reason'),
            childbirthAntibioticsTreatmetPrescriotionReason: this.apiV2Entities.getEntitiesList('childbirth-antibiotics-treatmet-prescriotion-reason'),
            medicinalPreparationsAndReimbursableMPPs: this.apiV2Entities.getEntitiesList('medicinal-preparations-and-reimbursable-MPPs'),
            lostBloodAmount: this.apiV2Entities.getEntitiesList('lost-blood-amount'),
            childbirthInterventions: this.apiV2Entities.getEntitiesList('childbirth-interventions'),
            performedEpisiotomy: this.apiV2Entities.getEntitiesList('performed-episiotomy'),
            childbirthRuptureArea: this.apiV2Entities.getEntitiesList('childbirth-rupture-area'),
            childbirthRuptureLevelCervix: this.apiV2Entities.getEntitiesList('childbirth-rupture-level-cervix'),
            childbirthRuptureLevelVagina: this.apiV2Entities.getEntitiesList('childbirth-rupture-level-vagina'),
            childbirthRuptureLevelLabia: this.apiV2Entities.getEntitiesList('childbirth-rupture-level-labia'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            laborProgress: this.laborProgressComponent.formGroup,
        });
        this.formGroup.get('laborProgress')?.get('antibiotics')?.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged(),
            debounceTime(10)
        )
            .subscribe(res => {
                if (res === 'Skirta') {
                    this.formGroup.addControl('administrationOfAntibiotics', this.antibioticsComponent.formGroup, {emitEvent: false});
                } else {
                    this.formGroup.removeControl('administrationOfAntibiotics', {emitEvent: false});
                }
            });
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }


    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.patchFormWithInitialData(this._initialFormValue);
    }

    private patchFormWithInitialData(_initialFormValue: {[key: string]: any}) {
        this.formGroup.valueChanges.pipe(
            debounceTime(100),
            take(1)
        ).subscribe(() => {
            this.formGroup.patchValue(this._initialFormValue);
            this.clearfArray(`administrationOfAntibiotics.substances`);
            this.clearfArray(`administrationOfAntibiotics.doctorSuturedUterineRupture`);
            this.updateFormArray(this._initialFormValue, `administrationOfAntibiotics.substances`);
            this.updateFormArray(this._initialFormValue, 'administrationOfAntibiotics.doctorSuturedUterineRupture');
            if (!this.editable) {
                this.formGroup.disable();
            }
        });
        this.formGroup.patchValue(this._initialFormValue);
    }
}
