ng1App.directive('taskItem', 
    ['$filter', '$location', 'SessionContext', 'MessageService', '$modal', 'PatientsApiFactory', 'TasksApiFactory', '$rootScope', 'CertificateCreation', '$upload', '$log', '$routeSegment', 'apiUrl', 'IntegratedComponentContext', 'EncountersApiFactory', 'PatientInsuranceService',
     function($filter, $location, SessionContext, MessageService, $modal, PatientsApiFactory,
                                      TasksApiFactory, $rootScope, CertificateCreation, $upload, $log, $routeSegment, apiUrl,
                                      IntegratedComponentContext, EncountersApiFactory, PatientInsuranceService) {
	var TEMPLATE_DIR = '/templates/portal/doctor/tasks/components';
	var MODAL_DIR = '/templates/portal/doctor/tasks/modals';

	var STATUS_DATA = {};
	STATUS_DATA['SUBMITTED'] = {text: 'tas.sta.act', color: 'text-danger'};
	STATUS_DATA['PENDING'] = {text: 'tas.sta.sto', color: 'text-warning'};
	STATUS_DATA['CANCELLED'] = {text: 'tas.sta.can', color: 'text-info-emphasis'};
	STATUS_DATA['COMPLETE'] = {text: 'tas.sta.fin', color: 'text-success'};
	STATUS_DATA['ABORTED'] = {text: 'tas.sta.act', color: 'text-danger'};

	var addTaskAction = function (scope, action) {
		if (scope.firstAction) {
			scope.otherActions.push(action);
		} else {
			scope.firstAction = action;
		}
	};

	var checkCreateAction = function (scope, action?) {
		var task = scope.task;
		if (task.meta && task.meta.createCon) {
			addTaskAction(scope, {title: 'dp.use.tas.cre.con', icon: 'fa-plus', code: 'createCon'});
		}
		if (task.meta && task.meta.createCer) {
			addTaskAction(scope, {title: 'dp.use.tas.cre.cer', icon: 'fa-plus', code: 'createCer'});
		}
	};

	var checkStopAction = function (scope, action?) {
            var task = scope.task;
		if (task.meta && task.meta.stop) {
                addTaskAction(scope, {title: 'dp.use.tas.stop', icon: 'fa-share-square-o', code: 'stop'});
            }
        };

	var checkCancelAction = function (scope, action?) {
            var task = scope.task;
		if (task.meta && task.meta.cancel) {
                addTaskAction(scope, {title: 'cancel', icon: 'fa-share-square-o', code: 'cancel'});
            }
        };

	var createNewEncounter = function (task, modal, callback) {
		var encounter = {
			arrivalDate: (new Date()).getTime(),
			event: {
				code: 'virtual'
	        },
	        // TODO get this from classifier (encounter-service-type)
	        serviceType: {
	        	id: 'other',
	        	code: 'other',
	        	name: 'Kita'
	        },
	        practitionerData: SessionContext.practitioner,
	        patientData: task.subject,
	        organizationData: SessionContext.organization,
	        isByOrder: true,
	        orderRef: task.orderFullId,
	        insuranceData: {
	        	isInsured: false,
				assertionDate: (new Date()).getTime(),
				insurancePSD: null,
	        }
		};
        PatientInsuranceService.getInsurance(task.subject.id, true).then(function (data) {
            encounter.insuranceData.insurancePSD = data;
			$upload.upload({url: apiUrl + '/encounters', method: 'POST', data: {form: encounter, defaultEsi: true}})
				.success( function (data) {
					modal.hide();
					callback(data.value);
				})
				.error(function () {
            		modal.hide();
            		MessageService.error($filter('translate')('err.tas.cre.enc'));
            	});
        }).catch(function () {
            modal.hide();
        });
	};
	
	var creationDialog = function (task, callback) {
		var modal = $modal({
				contentTemplate: MODAL_DIR + '/creation-modal.html',
				backdrop: 'static',
				keyboard: false});
		
		modal.$promise.then(function () {
			modal.show();
            var encounterTypes = ['ambulatory'];
            EncountersApiFactory.getPatientEncounters({patient: task.subject.id,
													status: ['inProgress'],
													eventCode: encounterTypes,
													organizationId: SessionContext.organization.id, 
													searchDocs: 'false'}, function (res) {
				var encounter = null;
                var data = res.items;
				if (data.length > 0) {
                    // Find latest
                    data = _.sortBy(data, function(e){return -1 * e.arrivalDate;});
					encounter = data[0];
				}
				if (encounter) {
					modal.hide();
					callback(encounter.id);
				} else if (task.orderType.code == '10' || task.orderType.code == '11') {
					createNewEncounter(task, modal, callback);
				} else {
					modal.hide();
					MessageService.error($filter('translate')('tas.no.val.enc'));
				}
			}, function () {
				modal.hide();
			});
		});
	};
	
	var createCertificate = function (task) {
        var callback = function (encounterId) {
            var certificateType = null;
            var needsConsultation = false;
            if (task.taskType && task.taskType.code) {
                switch (task.taskType.code) {
                    case 'E047-create-initial-certificate':
                        certificateType = 'e047';
                        needsConsultation = true;
                        break;
                    case 'E048-create-initial-certificate':
                        certificateType = 'e048';
                        needsConsultation = true;
                        break;
                    case 'E027-create-certificate':
                        if (task.orderType && task.orderType.code === '10') {
                            certificateType = 'e027_1_I';
                        } else {
                            certificateType = 'e027_1_II';
                        }
                        break;
                    default:
                        $log.error('Trying to create unknown certificate: ',task.taskType.code);
                };
            }

            if (certificateType) {
                if (needsConsultation) {
                    var modal = CertificateCreation.createWithConsultationSelect(certificateType, {encounterId: encounterId, taskId: task.taskId});
                    modal.$promise.then(function () {
                        modal.show();
                    });
                } else {
                    $location.path('/dp/patients/' + task.subject.id + '/certificates/' + certificateType + '/new')
                        .search({encounterId: encounterId, taskId: task.taskId});
                }
            }
        };
        if(!$rootScope.restrictedMode) {
            creationDialog(task, callback);
        } else {
            callback(IntegratedComponentContext.getEncounterId());
        }
	};
	
	var createConsultation = function (task) {
		creationDialog(task, function (encounterId) {
			$location.path('/dp/patients/' + task.subject.id + '/documents/new/e025')
				.search({encounterId: encounterId, taskId: task.taskId});
		});
	};
	
	var stopTask = function (task) {
		var stopModal = $modal({
			scope: $rootScope.$new(),
			contentTemplate: MODAL_DIR + '/task-status-change-modal.html',
			backdrop: 'static'
		});
		stopModal.$scope.title = $filter('translate')('dp.use.tas.stop');
		stopModal.$scope.showComment = true;
		stopModal.$scope.comment = "";
		stopModal.$scope.submit = function () {
			TasksApiFactory.stop({taskId: task.taskId, comment: stopModal.$scope.comment, orderType: task.orderType.code}, function () {
                                MessageService.registerActionSuccessEvent();
				stopModal.hide();
				$routeSegment.chain[$routeSegment.chain.length - 1].reload();	// For now (there should be better solution)
			}, function (error) {
                _.handleValidationErrors(error.data, MessageService);
				stopModal.hide();
			});
		};
		stopModal.$promise.then(function () {
			stopModal.show();
		});
	};
	
	var cancelTask = function (task) {
		var cancelModal = $modal({
			scope: $rootScope.$new(),
			contentTemplate: MODAL_DIR + '/task-status-change-modal.html',
			backdrop: 'static'
		});
		cancelModal.$scope.title = $filter('translate')('cancel');
		// TODO implement related document search
		cancelModal.$scope.submit = function () {
			TasksApiFactory.cancel({taskId: task.taskId, orderType : task.orderType.code}, function () {
                            MessageService.registerActionSuccessEvent();
				cancelModal.hide();
				$routeSegment.chain[$routeSegment.chain.length - 1].reload();	// For now (there should be better solution)
			}, function (error) {
                _.handleValidationErrors(error.data, MessageService);
				cancelModal.hide();
			});
		};
        cancelModal.$promise.then(function () {
            TasksApiFactory.hasRelatedDocuments({taskId: task.taskId}, function (data) {
                cancelModal.$scope.showRelated = data.hasRelatedDocuments;

                cancelModal.show();
            });

        });
	};
	
	return {
		templateUrl: TEMPLATE_DIR + '/task-item.html',
		restrict: 'E',
		replace: true,
		scope: {
			task: '=',
			extended: '@',
			patient: '@'
		},
		link: function (scope: any, element, attrs) {
			scope._ = _;
			scope.showComments = false;
			
			var status = STATUS_DATA[scope.task.status];
			if (status) {
				scope.taskStatusText = status.text;
				scope.taskStatusColor = status.color;
			}

            if(scope.task.orderType.code == '10'){
                scope.task.taskName = 'Parengti pažymos E027-1 „Vaiko sveikatos pažymėjimas I. Fizinės būklės įvertinimas“ dalį';
            } else if(scope.task.orderType.code == '11'){
                scope.task.taskName = 'Parengti pažymos E027-1 „Vaiko sveikatos pažymėjimas II. Dantų ir žandikaulių būklės įvertinimas“ dalį';
            }

			if (scope.task.authorPractitioner) {
				scope.authorName = _.getFullName(scope.task.authorPractitioner);
				scope.authorStamp = scope.task.authorPractitioner.stampCode;
			} else if (scope.task.authorPatient) {
				scope.authorName = _.getFullName(scope.task.authorPatient);
				scope.authorPath = '/dp/patients/' + scope.task.authorPatient.id;
			}
			
			if (scope.task.reason) {
				switch (scope.task.reason.documentType) {
				case 'e025':
				case 'e027':
				case 'e027a':
				case 'e003':
				case 'e200':
				case 'e200a':
				case 'e063':
				case 'e014':
				case 'e014a':
					scope.documentPath = '/dp/patients/' + scope.task.subject.id + '/documents/' + scope.task.reason.documentId;
					break;
				default:
					scope.documentPath = '/dp/patients/' + scope.task.subject.id + '/certificates/' + scope.task.reason.documentId;
					break;
				}
			}
			
			var subject = scope.task.subject;
			if (subject.familyName) {
				var data = _.getFullName(subject);
				if (subject.birthDate) {
					data += ', ' + $filter('date')(subject.birthDate, 'yyyy-MM-dd');
				}
				scope.subjectData = data;
			} else {
				scope.subjectData = $filter('translate')('esiNr') + ' ' + subject.esiNr;
			}
			
			scope.otherActions = [];
			checkCreateAction(scope, SessionContext.practitioner);
			checkStopAction(scope, SessionContext.practitioner);
			checkCancelAction(scope, SessionContext.practitioner);
			
			scope.performAction2 = function (code) {
				switch (code) {
				case 'createCer':
					createCertificate(scope.task);
					break;
				case 'createCon':
					createConsultation(scope.task);
					break;
				case 'stop':
					stopTask(scope.task);
					break;
				case 'cancel':
					cancelTask(scope.task);
					break;
				}
			};

            scope.performAction = function (code) {
                if(scope.task.taskType && scope.task.taskType.code === 'E027-create-certificate'){
                    var encounterTypes = ['ambulatory'];
                    EncountersApiFactory.getPatientEncounters({patient: scope.task.subject.id,
                        status: ['inProgress'],
                        eventCode: encounterTypes,
                        organizationId: SessionContext.organization.id,
                        searchDocs: 'false'}, function (res) {
                        var encounter = null;
                        var data = res.items;
                        if (data.length > 0) {
                            // Find latest
                            data = _.sortBy(data, function(e){return -1 * e.arrivalDate;});
                            encounter = data[0];
                        }
                        if (encounter) {
                            scope.performAction2(code);
                        } else {
                            createEncounterConfirm(code)
                        }
                    });
                } else {
                    scope.performAction2(code);
                }
            };

            var createEncounterConfirm = function (code) {
                var createEncounterModal = $modal({
                    scope: $rootScope.$new(),
                    contentTemplate: MODAL_DIR + '/create-encounter-modal.html',
                    backdrop: 'static'
                });
                createEncounterModal.$scope.title = $filter('translate')('create-encounter-for-task');
                createEncounterModal.$scope.confirmed = function () {
                    createEncounterModal.hide();
                    scope.performAction2(code);
                };
                createEncounterModal.$promise.then(function () {
                    createEncounterModal.show();
                });
            };


			// Comments
			scope.commentCount = scope.task.comments ? scope.task.comments.length : 0;
			scope.comments = scope.task.comments;
			scope.toogleCommentShow = function () {
				scope.showComments = !scope.showComments;
			};
		}
	};
}]);