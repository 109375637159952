ng1App.controller('document.E014EditCtrl', 
    ['$scope', '$filter',
     function ($scope, $filter) {
    $scope.data = {};
    $scope.result = {};

    // Side content list
    // jus fill array with something
    $scope._shared.contentList = _.range(7);
    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id:'doc_mai_inf'}; 
}]);