<nhr-record-widget
    [closable]="true"
    [closed]="closed"
    [title]="'E113-TP | Tyrimai ir procedūros nėštumo metu' | translate"
    id="researches"
    class="h-auto"
>
    <div class="card mb-3">
        <div class="card-header">{{ "Tyrimai" | translate }}</div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let research of researches | async" class="ms-2 mt-2">
                        <div class="card mb-3">
                            <div class="card-header">{{ research.name | translate }}</div>
                            <div class="card-body overflow-auto">
                                <table class="table table-hover w-100 mt-4">
                                    <thead>
                                        <tr>
                                            <th
                                                *ngFor="let headerName of research.headerData"
                                                [class]="headerStyle(research.headerData?.length)"
                                            >
                                                {{ headerName | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let val of research.data">
                                            <td>
                                                {{ val.date | date : "yyyy-MM-dd" }}
                                            </td>
                                            <!-- BloodTests -->
                                            <td *ngIf="val.pregnantBloodType">
                                                {{ val.pregnantBloodType }}
                                            </td>
                                            <td *ngIf="val.fatherBloodGroup">
                                                {{ val.fatherBloodGroup }}
                                            </td>
                                            <!-- Antibody -->
                                            <td
                                                *ngIf="
                                                    val.antibodyTestResult !== null &&
                                                    Researches.Antibody === research.name
                                                "
                                            >
                                                {{ val.antibodyTestResult ? ("Taip" | translate) : ("Ne" | translate) }}
                                            </td>
                                            <td *ngIf="val.antibodyTiter && val.antibodyTiter2">
                                                {{ val.antibodyTiter }} :
                                                {{ val.antibodyTiter2 }}
                                            </td>
                                            <!-- BloodTest -->
                                            <td *ngIf="val.bloodErythrocytes">
                                                {{ val.bloodErythrocytes }}
                                            </td>
                                            <td *ngIf="val.bloodHemoglobin">
                                                {{ val.bloodHemoglobin }}
                                            </td>
                                            <td *ngIf="val.bloodHematocrit">
                                                {{ val.bloodHematocrit }}
                                            </td>
                                            <td *ngIf="val.bloodLeukocytes">
                                                {{ val.bloodLeukocytes }}
                                            </td>
                                            <td *ngIf="val.bloodPlatelets">
                                                {{ val.bloodPlatelets }}
                                            </td>
                                            <!-- Syphilis -->
                                            <td
                                                *ngIf="
                                                    val.RPRTestResult !== null && Researches.Syphilis === research.name
                                                "
                                            >
                                                {{ val.RPRTestResult ? ("Taip" | translate) : ("Ne" | translate) }}
                                            </td>
                                            <td *ngIf="val.RPRStudyTiter">
                                                {{ val.RPRStudyTiter }}
                                            </td>
                                            <!-- HIV -->
                                            <td *ngIf="val.HIVTestResult">
                                                {{ val.HIVTestResult }}
                                            </td>
                                            <!-- Glucose -->
                                            <td *ngIf="val.bloodPlasmaGlucoseTestResult">
                                                {{ val.bloodPlasmaGlucoseTestResult }}
                                            </td>
                                            <!-- HepatitisB -->
                                            <td
                                                *ngIf="
                                                    val.hepatitisBResult !== null &&
                                                    Researches.HepatitisB === research.name
                                                "
                                            >
                                                {{
                                                    val.hepatitisBResult
                                                        ? ("Teigiamas" | translate)
                                                        : ("Neigiamas" | translate)
                                                }}
                                            </td>
                                            <!-- UrineAsymptomaticBacteriuria -->
                                            <td *ngIf="val.asymptomaticBacteriuriaResult">
                                                {{ val.asymptomaticBacteriuriaResult }}
                                            </td>
                                            <!-- GTM -->
                                            <td *ngIf="val.GTMResult !== null && Researches.GTM === research.name">
                                                {{ val.GTMResult ? ("Taip" | translate) : ("Ne" | translate) }}
                                            </td>
                                            <td *ngIf="val.GTMI">
                                                {{ val.GTMI }}
                                            </td>
                                            <td *ngIf="val.GTMII">
                                                {{ val.GTMII }}
                                            </td>
                                            <td *ngIf="val.GTMIII">
                                                {{ val.GTMIII }}
                                            </td>
                                            <!-- GBS -->
                                            <td *ngIf="val.cropBGSTest && val.cropBGSTestResults">
                                                {{ val.cropBGSTest }} - {{ val.cropBGSTestResults }}
                                            </td>
                                            <!-- Urine -->
                                            <td *ngIf="val.urineProtein">
                                                {{ val.urineProtein }}
                                            </td>
                                            <td *ngIf="val.urineLeukocytes">
                                                {{ val.urineLeukocytes }}
                                            </td>
                                            <td *ngIf="val.urineErythrocytes">
                                                {{ val.urineErythrocytes }}
                                            </td>
                                            <td *ngIf="val.urineGlucose">
                                                {{ val.urineGlucose }}
                                            </td>
                                            <td *ngIf="val.urineKetones">
                                                {{ val.urineKetones }}
                                            </td>
                                            <td *ngIf="val.urinaryNitrites">
                                                {{ val.urinaryNitrites }}
                                            </td>
                                            <!-- EnteredBySpecialist -->
                                            <td *ngIf="val.enteredBySpecialist">
                                                {{ val.enteredBySpecialist }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Procedūros" | translate }}</div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let procedure of procedures | async" class="ms-2 mt-2">
                        <div class="card mb-3">
                            <div class="card-header">{{ procedure.name | translate }}</div>
                            <div class="card-body overflow-auto">
                                <table class="table table-hover w-100 mt-4">
                                    <thead>
                                        <tr>
                                            <th
                                                *ngFor="let headerName of procedure.headerData"
                                                [class]="headerStyle(procedure.headerData?.length)"
                                            >
                                                {{ headerName | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let val of procedure.data">
                                            <td *ngIf="val.date">
                                                {{
                                                    val.date
                                                        | date
                                                            : (Procedures.AntiDImmunoglobulin === procedure.name
                                                                  ? "yyyy-MM-dd hh:mm"
                                                                  : "yyyy-MM-dd")
                                                }}
                                            </td>
                                            <!-- AntiDImmunoglobulin -->
                                            <td
                                                *ngIf="
                                                    val.injectedAntiDImmunoglobulin !== null &&
                                                    Procedures.AntiDImmunoglobulin === procedure.name
                                                "
                                            >
                                                {{
                                                    val.injectedAntiDImmunoglobulin
                                                        ? ("Taip" | translate)
                                                        : ("Ne" | translate)
                                                }}
                                            </td>
                                            <!-- PrenatalScreening -->
                                            <td *ngIf="val.prenatalScreeningResult">
                                                {{ val.prenatalScreeningResult }}
                                            </td>
                                            <!-- PrenatalDiagnostics -->
                                            <td *ngIf="val.amniocentesisDiagnosisDate">
                                                {{ val.amniocentesisDiagnosisDate | date : "yyyy-MM-dd" }}
                                            </td>
                                            <td *ngIf="val.cordocentesisDiagnosisDate">
                                                {{ val.cordocentesisDiagnosisDate | date : "yyyy-MM-dd" }}
                                            </td>
                                            <!-- FetalSurgicalProcedures -->
                                            <td *ngIf="val.standardFetalSurgicalProcedure">
                                                {{ val.standardFetalSurgicalProcedure }}
                                            </td>
                                            <td *ngIf="val.otherFetalSurgicalProcedure">
                                                {{ val.otherFetalSurgicalProcedure }}
                                            </td>
                                            <!-- FetalLungMaturation -->
                                            <td
                                                *ngIf="
                                                    val.fetalLungMaturation !== null &&
                                                    Procedures.FetalLungMaturation === procedure.name
                                                "
                                            >
                                                {{
                                                    val.fetalLungMaturation
                                                        ? ("Atliktas" | translate)
                                                        : ("Neatliktas" | translate)
                                                }}
                                            </td>
                                            <td *ngIf="val.pregnancyWeek">
                                                {{ val.pregnancyWeek }}
                                            </td>
                                            <!-- TocolysisDuringPregnancy -->
                                            <td
                                                *ngIf="
                                                    val.tocolysisDuringPregnancy !== null &&
                                                    Procedures.TocolysisDuringPregnancy === procedure.name
                                                "
                                            >
                                                {{
                                                    val.tocolysisDuringPregnancy
                                                        ? ("Taip" | translate)
                                                        : ("Ne" | translate)
                                                }}
                                            </td>
                                            <!-- Other -->
                                            <td *ngIf="val.otherInfo">
                                                {{ val.otherInfo }}
                                            </td>
                                            <!-- EnteredBySpecialist -->
                                            <td *ngIf="val.enteredBySpecialist">
                                                {{ val.enteredBySpecialist }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
