import { getTemplateComponent } from "../../../utils/hypridHelper";
import { DocumentTagEnum } from "../../shared/models/document-tag.enum";
import { FetusUltrasoundExaminationComponent } from "./components/fetus-ultrasound-examination/fetus-ultrasound-examination.component";
import { FetusUltrasoundExaminationViewComponent } from "./components/fetus-ultrasound-examination/view/fetus-ultrasound-examination-view.component";
import { FirstNewbornCheckupComponent } from "./components/first-newborn-checkup/first-newborn-checkup.component";
import { GeneralPregnancyTerminationInfoComponent } from "./components/general-pregnancy-termination-info/general-pregnancy-termination-info.component";
import { GeneralPregnancyTerminationInfoViewComponent } from "./components/general-pregnancy-termination-info/view/general-pregnancy-termination-info-view.component";
import { OutpatientVisitDescriptionComponent } from "./components/outpatient-visit-description/outpatient-visit-description.component";
import { OutpatientVisitDescriptionViewComponent } from "./components/outpatient-visit-description/view/outpatient-visit-description-view.component";
import { ParentalConsentComponent } from "./components/parental-consent/parental-consent.component";
import { PreganncyDashboardListComponent } from "./components/preganncy-dashboard-list/preganncy-dashboard-list.component";
import { PregnancyDashboardComponent } from "./components/pregnancy-dashboard.component";
import { PregnancyTerminationComponent } from "./components/pregnancy-termination/pregnancy-termination.component";
import { ProceduresDuringPregnancyComponent } from "./components/procedures-during-pregnancy/procedures-during-pregnancy.component";
import { ProceduresDuringPregnancyViewComponent } from "./components/procedures-during-pregnancy/view/procedures-during-pregnancy-view.component";
import { RiskFactorsComponent } from "./components/risk-factors/risk-factors.component";
import { RiskFactorsViewComponent } from "./components/risk-factors/view/risk-factors-view.component";
import { PregnancyDashboardViewComponent } from "./components/view/pregnancy-dashboard-view.component";

ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding NGN routes");
        // DP.DP_PATIENT2L -> Patient + 2 column layout
        // DP.DP_PATIENT3L -> Patient + 3 column layout

        // Patient routes
        $routeSegmentProvider
            .when(`/dp/patients/:patientId/pregnancy-dashboard`, "DP.DP_PATIENT2L.NGN_DASH")
            .when(`/dp/patients/:patientId/pregnancy-dashboard-view`, "DP.DP_PATIENT3L.NGN_DASH_VIEW")
            .when(`/dp/patients/:patientId/pregnancy-dashboard-list`, "DP.DP_PATIENT3L.NGN_DASH_LIST")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e113_nd}`, "DP.DP_PATIENT2LREVNEW.NGN_ND_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e113_ap}`, "DP.DP_PATIENT2LREVNEW.NGN_AP_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e113_rv}`, "DP.DP_PATIENT2LREVNEW.NGN_RV_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e113_ug}`, "DP.DP_PATIENT2LREVNEW.NGN_UG_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e113_tp}`, "DP.DP_PATIENT2LREVNEW.NGN_TP_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e113_nna}`, "DP.DP_PATIENT2LREVNEW.NGN_NNA_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_npa}`, "DP.DP_PATIENT2LREVNEW.NGN_NPA_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_ts}`, "DP.DP_PATIENT2LREVNEW.NGN_TS_EDIT")
            .when(`/dp/patients/:patientId/ngn/view/${DocumentTagEnum.e113_nd}`, "DP.DP_PATIENT3L.NGN_ND_VIEW")
            .when(`/dp/patients/:patientId/ngn/view/${DocumentTagEnum.e113_rv}`, "DP.DP_PATIENT3L.NGN_RV_VIEW")
            .when(`/dp/patients/:patientId/ngn/view/${DocumentTagEnum.e113_tp}`, "DP.DP_PATIENT3L.NGN_TP_VIEW")
            .when(`/dp/patients/:patientId/ngn/view/${DocumentTagEnum.e113_ap}`, "DP.DP_PATIENT3L.NGN_AP_VIEW")
            .when(`/dp/patients/:patientId/ngn/view/${DocumentTagEnum.e113_ug}`, "DP.DP_PATIENT3L.NGN_UG_VIEW");

        // Doctor routes
        // DP.DP_USER2L -> Doctor + 2 column layout
        // DP.DP_USER3L -> Doctor + 3 column layout
        /*
            $routeSegmentProvider
                .when('/dp/user/:patientId/doc-unsorted', 'DP.DP_PATIENT3L.DOC_UNSORTED')
        */

        // VIEW or 3col layout
        $routeSegmentProvider
            .within('DP')
            .within('DP_PATIENT3L')
            .segment("NGN_RV_VIEW", { template: getTemplateComponent(RiskFactorsViewComponent) })
            .segment("NGN_ND_VIEW", { template: getTemplateComponent(GeneralPregnancyTerminationInfoViewComponent) })
            .segment("NGN_TP_VIEW", { template: getTemplateComponent(ProceduresDuringPregnancyViewComponent) })
            .segment("NGN_AP_VIEW", { template: getTemplateComponent(OutpatientVisitDescriptionViewComponent) })
            .segment("NGN_UG_VIEW", { template: getTemplateComponent(FetusUltrasoundExaminationViewComponent) })
            .segment("NGN_DASH_LIST", { template: getTemplateComponent(PreganncyDashboardListComponent) })
            .segment("NGN_DASH_VIEW", { template: getTemplateComponent(PregnancyDashboardViewComponent) });

        // EDIT or 2col layout
        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2L")
            .segment("NGN_DASH", { template: getTemplateComponent(PregnancyDashboardComponent) });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2LREVNEW")
            .segment("NGN_ND_EDIT", { template: getTemplateComponent(GeneralPregnancyTerminationInfoComponent) })
            .segment("NGN_AP_EDIT", { template: getTemplateComponent(OutpatientVisitDescriptionComponent) })
            .segment("NGN_RV_EDIT", { template: getTemplateComponent(RiskFactorsComponent) })
            .segment("NGN_UG_EDIT", { template: getTemplateComponent(FetusUltrasoundExaminationComponent) })
            .segment("NGN_TP_EDIT", { template: getTemplateComponent(ProceduresDuringPregnancyComponent) })
            .segment("NGN_NNA_EDIT", { template: getTemplateComponent(PregnancyTerminationComponent) })
            .segment("NGN_NPA_EDIT", { template: getTemplateComponent(FirstNewbornCheckupComponent) })
            .segment("NGN_TS_EDIT", { template: getTemplateComponent(ParentalConsentComponent) });
    },
]);
