ng1App.service('DosageSummaryService',
    ['$filter',
        function ($filter) {

            this.buildDosageSummary = function (dosageSummary, dosageInstructionData, frequencyMeasurements, intakeTimes) {
                let dosageSummaryParts = [];

                // Dosage Info
                if (dosageInstructionData.doseQuantity && dosageInstructionData.doseQuantity.value) {
                    let dosageInfo = dosageInstructionData.doseQuantity.value;
                    if (dosageInstructionData.doseQuantity.unit && dosageInstructionData.doseQuantity.unit.displayValue) {
                        dosageInfo += " " + $filter('translatePlural')(dosageInstructionData.doseQuantity.unit.displayValue.toLowerCase(),
                            dosageInstructionData.doseQuantity.value);
                    } else {
                        dosageInfo += " (dozės vienetai)"
                    }
                    dosageSummaryParts.push(dosageInfo);
                }

                // Dosage frequency
                if (dosageInstructionData.schedule?.frequency) {
                    let dosagePer = dosageInstructionData.schedule.frequency + "k.";
                    if (dosageInstructionData.schedule.units) {
                        const unit = frequencyMeasurements.find(unit => unit.code === dosageInstructionData.schedule.units);
                        if (unit && unit.name || unit && unit.displayValue) {
                            const unitName: string = unit.name ? unit.name : unit.displayValue;
                            dosagePer += " per " + $filter('translatePlural')(unitName.toLowerCase(), 'k');
                        }
                    }
                    dosageSummaryParts.push(dosagePer);
                }

                // Dosage Hourly
                if (dosageInstructionData.timeQuantity) {
                    dosageSummaryParts.push("kas " + dosageInstructionData.timeQuantity + " val.");
                }

                // Dosage Frequency
                let dosageFrequency = [];
                if (dosageInstructionData.morning) {
                    dosageFrequency.push("ryte");
                }
                if (dosageInstructionData.noon) {
                    dosageFrequency.push("per pietus");
                }
                if (dosageInstructionData.evening) {
                    dosageFrequency.push("vakare");
                }
                if (dosageInstructionData.asNeeded) {
                    dosageFrequency.push("pagal poreikį");
                }
                if (dosageFrequency.length > 0) {
                    dosageSummaryParts.push("(" + dosageFrequency.join(', ') + ")");
                }

                // Dosage Timing
                let dosageTiming = intakeTimes.filter(intake => dosageInstructionData[intake.code])
                    .map(intake => intake.displayValue.toLowerCase());
                if (dosageTiming.length > 0) {
                    dosageSummaryParts.push("(" + dosageTiming.join(', ') + ")");
                }

                //24-7 dosing
                if (dosageInstructionData.dosePerDay && dosageInstructionData.dosePerDay.value) {
                    let dosePerDay = "paros dozė: " + dosageInstructionData.dosePerDay.value;
                    if (dosageInstructionData.dosePerDay.unit && dosageInstructionData.dosePerDay.unit.displayValue) {
                        dosePerDay += " " + $filter('translatePlural')(dosageInstructionData.dosePerDay.unit.displayValue.toLowerCase(),
                            dosageInstructionData.dosePerDay.value);
                    } else {
                        dosePerDay += " (dozės vienetai)"
                    }
                    dosageSummaryParts.push(dosePerDay);
                }

                //route
                if (dosageInstructionData.route && dosageInstructionData.route.code) {
                    const route = dosageInstructionData.route.name ? dosageInstructionData.route.name : dosageInstructionData.route.displayValue;
                    dosageSummaryParts.push(route);
                }

                // Summary
                if (dosageSummaryParts.length > 0) {
                    dosageSummary = dosageSummaryParts.join(', ') + ".";
                } else {
                    dosageSummary = "";
                }

                return dosageSummary;
            }
        }
    ]
);