ng1App.factory('VaccinationRecordsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/vaccination/records';
    return $resource('', {}, {
        getDiseaseType: {
            method: 'GET',
            url: URL_BASE + '/disease/list'
        },
        getCountries: {
            method: 'GET',
            url: URL_BASE + '/country/list'
        },
        getVaccines: {
            method: 'GET',
            url: URL_BASE + '/vaccine/list'
        },
        getRecConfig: {
            method: 'GET',
            url: URL_BASE + '/recconfig/list'
        },
        getRecords: {
            method: 'GET',
            url: URL_BASE + '/records/list/:id'
        },
        getRecordById: {
            method: 'GET',
            url: URL_BASE + '/record/?isVaccinationDoc=:isVaccinationDoc&recordId=:recordId'
        },
        getMaxFileSize: {
            method: 'GET',
            url: URL_BASE + '/maxfilesize'
        },
        cancelRecord: {
            method: 'GET',
            url: URL_BASE + '/cancel/record/?recordType=:recordType&recordId=:recordId'
        },
        getAntigenCompList: {
            method: 'GET',
            url: URL_BASE + '/atntigencompanies/list'
        }
    });
}]);

ng1App.service('VaccinationSaveService', 
    ['$upload', 'apiUrl',
     function ($upload, apiUrl) {
    this.saveRecord = function (vaccinerecord, docimage, successCb, errorCb) {
        $upload.upload({
            url: apiUrl + '/vaccination/records/save',
            data: {data: vaccinerecord},
            file: docimage
        }).progress(function (evt) {
        }).success(function (data, status, headers, config) {
            (successCb || angular.noop)(data);
        }).error(function (error) {
            (errorCb || angular.noop)(error);
        });
    };
}]);

ng1App.service('VaccinationUpdateService', 
    ['$upload', 'apiUrl',
     function ($upload, apiUrl) {
    this.updateRecord = function (vaccinerecord, docimageid, docimage, successCb, errorCb) {
        $upload.upload({
            url: apiUrl + '/vaccination/records/update',
            data: {data: vaccinerecord, imgid: docimageid},
            file: docimage
        }).progress(function (evt) {
        }).success(function (data, status, headers, config) {
            (successCb || angular.noop)(data);
        }).error(function (error) {
            (errorCb || angular.noop)(error);
        });
    };
}]);