import { CommonModule, DecimalPipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { FullCalendarModule } from "@fullcalendar/angular";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { AgGridModule } from "ag-grid-angular";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsModalService } from "ngx-bootstrap/modal";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { QuillModule } from "ngx-quill";
import { DowngradeAdapter } from "../../utils/hypridHelper";
import { NgnCaptionComponent } from "../doctor/ngn/components/shared/ngn-caption/ngn-caption.component";
import { routeParamsProvider } from "../ng1.routeParams";
import { DocRefF025ViewComponent } from "./components/025-5/view/doc-ref-f025-form-view.component";
import { DocRef058ViewComponent } from "./components/058-089-151a-form/view/doc-ref-f058-form-view.component";
import { DocRefF090CardComponent } from "./components/090/card/doc-ref-f090-form-card.component";
import { DocRefF090ListComponent } from "./components/090/list/doc-ref-f090-form-list.component";
import { DocRefF090ViewComponent } from "./components/090/view/doc-ref-f090-form-view.component";
import { DocRefE110CardComponent } from "./components/110-GMP/card/doc-ref-110-GMP-form-card.component";
import { DocRefE110ListComponent } from "./components/110-GMP/list/doc-ref-110-GMP-form-list.component";
import { DocRef110GMPViewComponent } from "./components/110-GMP/view/doc-ref-110-GMP-form-view.component";
import { EmptyComponent } from "./components/EmptyComponent";
import { AgGridModalOpenLinkCell } from "./components/ag-grid/ag-modal-open-link-cell/ag-modal-open-link.component";
import { AgActionsCell } from "./components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { AgGridCheckboxFgCell } from "./components/ag-grid/components/ag-checkbox-cell/agGrid-checkbox-fg-cell";
import { AgColFilterButton } from "./components/ag-grid/components/ag-col-filter-button/ag-col-filter-button.component";
import { AgColFilterText } from "./components/ag-grid/components/ag-col-filter-text/ag-col-filter-text.component";
import { AgColHeaderLink } from "./components/ag-grid/components/ag-col-header-link/ag-col-header-link.component";
import { AgGridInputFgCell } from "./components/ag-grid/components/ag-input-cell/agGrid-input-fg-cell";
import { AgLinkCell } from "./components/ag-grid/components/ag-link-cell/ag-link-cell.component";
import { AgOverlayLoadingComponent } from "./components/ag-grid/components/ag-overlay-loading/ag-overlay-loading.component";
import { AgPaginationBarComponent } from "./components/ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";
import { AgPillCell } from "./components/ag-grid/components/ag-pill-cell/ag-pill-cell.component";
import { AgGridSelectFgCell } from "./components/ag-grid/components/ag-select-cell/agGrid-select-fg-cell";
import { AgTextCell } from "./components/ag-grid/components/ag-text-cell/ag-text-cell.component";
import { AntibioticsComponent } from "./components/antibiotics/antibiotics.component";
import { ButtonComponent } from "./components/buttons/app-button/button.component";
import { RadioButtonComponent } from "./components/buttons/radio-button/radio-button.component";
import { SidebarButtonComponent } from "./components/buttons/sidebar-button/button.component";
import { CellIconsComponent } from "./components/cellIcons/cell-icons.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { CommonPatientWidgetComponent } from "./components/common-patient-widget/common-patient-widget.component";
import { CurrentAllergiesListModalComponent } from "./components/current-allergies-list-modal/current-allergies-list-modal";
import { CurrentRisksListModalComponent } from "./components/current-risks-list-modal/current-risks-list-modal.component";
import { PrimaryHealthCareComponent } from "./components/dashboard/primaryHealthCare/primary-health-care.component";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { DemoComponent } from "./components/demo/demo.component";
import { DiagnosisByEncounterComponent } from "./components/diagnosis/diagnoses-by-encounter/diagnoses-by-encounter";
import { DiagnosesCloseModalComponent } from "./components/diagnosis/diagnoses-close-modal/diagnoses-close-modal.component";
import { DiagnosesFormComponent } from "./components/diagnosis/diagnoses-form.component";
import { DiagnosesListModalComponent } from "./components/diagnosis/diagnoses-list-modal/diagnoses-list-modal.component";
import { EditDiagnoseModalComponent } from "./components/diagnosis/edit-diagnose-modal/edit-diagnose-modal.component";
import { DocRefAttachedUnsortedCardComponent } from "./components/doc-ref-attached-unsorted/card/doc-ref-attached-unsorted-card.component";
import { DocRefAttachedUnsortedListComponent } from "./components/doc-ref-attached-unsorted/list/doc-ref-attached-unsorted-list.component";
import { DocRefAttachedUnsortedViewComponent } from "./components/doc-ref-attached-unsorted/view/doc-ref-attached-unsorted-view.component";
import { DocStatusIconComponent } from "./components/docComponents/docStatusIcon/doc-status-icon.component";
import { OrderWithMetadataComponent } from "./components/docComponents/orderWithMetadata/order-with-metadata.component";
import { DocRefConsentCardComponent } from "./components/docRefConsent/card/doc-ref-consent-card.component";
import { DocRefConsentFilterComponent } from "./components/docRefConsent/filter/doc-ref-consent-filter.component";
import { DocRefConsentListComponent } from "./components/docRefConsent/list/doc-ref-consent-list.component";
import { DocRefConsentSpanComponent } from "./components/docRefConsent/span/doc-ref-consent-span.component";
import { DocRefConsentViewComponent } from "./components/docRefConsent/view/doc-ref-consent-view.component";
import { DocRefConsentTemplateFilterComponent } from "./components/docRefConsentTemplate/filter/doc-ref-consent-template-filter.component";
import { DocRefConsentTemplateListComponent } from "./components/docRefConsentTemplate/list/doc-ref-consent-template-list.component";
import { DocRefConsentTemplateViewComponent } from "./components/docRefConsentTemplate/view/doc-ref-consent-template-view.component";
import { DoubleInputComponent } from "./components/double-input/double-input.component";
import { EncounterBlockComponent } from "./components/encounter-block/encounter-block.component";
import { ErrorMessageComponent } from "./components/erorr-message/error-message.component";
import { DispenseHistoryPageComponent } from "./components/erx/dispenseHistoryPage/dispense-history-page.component";
import { DispenseListComponent } from "./components/erx/dispenseList/dispense-list.component";
import { ErecHistoryPageComponent } from "./components/erx/erecHistoryPage/erec-history-page.component";
import { ErecListComponent } from "./components/erx/erecList/erec-list.component";
import { ErxFilterComponent } from "./components/erx/erxFilter/erx-filter.component";
import { MedicationDosageComponent } from "./components/erx/medication-dosage/medication-dosage.component";
import { F090SearchComponent } from "./components/filters/090/doc-ref-090_search.component";
import { AspnF027listFilterComponent } from "./components/filters/aspnF027list/aspnF027list-filter.component";
import { CustomSearchComponent } from "./components/filters/customSearch/custom-search.component";
import { AttachedUnsortedSearchComponent } from "./components/filters/doc-ref-attached-unsorted_search/doc-ref-attached-unsorted_search.component";
import { FiltersGroupComponent } from "./components/filters/filtersGroup/filters-group.component";
import { NursingAndCarePlanHistoryFilterComponent } from "./components/filters/nursingAndCarePlanHistory/nursingAndCarePlanHistory-filter.component";
import { NursingServiceOrderListFilterComponent } from "./components/filters/nursingServiceOrderList/nursingServiceOrderList-filter.component";
import { QuickSearchComponent } from "./components/filters/quickSearch/quick-search.component";
import { SearchModalComponent } from "./components/filters/searchModal/search-modal.component";
import { FindSpecialistsComponent } from "./components/find-specialists/find-specialists.component";
import { FormButtonsLayoutComponent } from "./components/form-buttons-layout/form-buttons-layout.component";
import { FormControlValidationComponent } from "./components/form-control-validation/form-control-validation.component";
import { IconComponent } from "./components/icon/icon.component";
import { InputComponent } from "./components/input/input.component";
import { FindAndPrefillInfoComponent } from "./components/inr-sub-forms/find-and-prefill-info/find-and-prefill-info.component";
import { InrAdditionalInfoSubformComponent } from "./components/inr-sub-forms/inr-additional-info-subform/inr-additional-info-subform.component";
import { InrDescriptionDrugsSubformComponent } from "./components/inr-sub-forms/inr-description-drugs-subform/inr-description-drugs-subform.component";
import { InrDescriptionVaccineSubformComponent } from "./components/inr-sub-forms/inr-description-vaccine-subform/inr-description-vaccine-subform.component";
import { InrDoctorInformingSubformComponent } from "./components/inr-sub-forms/inr-doctor-informing-subform/inr-doctor-informing-subform.component";
import { InrDrugsCausedInrSubformComponent } from "./components/inr-sub-forms/inr-drugs-caused-inr-subform/inr-drugs-caused-inr-subform.component";
import { InrNotificationFormSubformComponent } from "./components/inr-sub-forms/inr-notification-form-subform/inr-notification-form-subform.component";
import { InrNotificationTypeSubformComponent } from "./components/inr-sub-forms/inr-notification-type-subform/inr-notification-type-subform.component";
import { InrOtherUsedMedicalProductsSubformComponent } from "./components/inr-sub-forms/inr-other-used-medical-products-subform/inr-other-used-medical-products-subform.component";
import { InrReportingPersonInfoSubformComponent } from "./components/inr-sub-forms/inr-reporting-person-info-subform/inr-reporting-person-info-subform.component";
import { InrVaccineCausedInrSubformComponent } from "./components/inr-sub-forms/inr-vaccine-caused-inr-subform/inr-vaccine-caused-inr-subform.component";
import { InrWhomPersonInfoSubformComponent } from "./components/inr-sub-forms/inr-whom-person-info-subform/inr-whom-person-info-subform.component";
import { LinkStatusBadgeComponent } from "./components/linkStatusBadge/link-status-badge.component";
import { PaginationBarComponent } from "./components/list/pagination-bar/pagination-bar.component";
import { PartMedicalModalComponent } from "./components/medical-data-modal-windows/partMedicalData-modals/partMedicalModal.component";
import { ModalComponent } from "./components/modal/modal.component";
import { ModificationActionsComponent } from "./components/modification-actions/modification-actions.component";
import { MultiselectDropdownComponent } from "./components/multiselect-dropdown/multiselect-dropdown.component";
import { NhrMsgGlobalComponent } from "./components/nhrMsgGlobal/nhr-msg-global.component";
import { NhrRecordWidgetComponent } from "./components/nhrRecordWidget/nhr-record-widget.component";
import { NhrWidgetComponent } from "./components/nhrWidget/nhr-widget.component";
import { PageFiltersComponent } from "./components/page-filters/page-filters.component";
import { PageNotFoundComponent } from "./components/pageNotFound/pageNotFound.component";
import { PatientCalendarComponent } from "./components/patient-calendar/patient-calendar.component";
import { PatientHealthAssessmentsReusableFormComponent } from "./components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component";
import { PatientTransportedInstitutionComponent } from "./components/patient-transported-institution/patient-transported-institution.component";
import { PractitionerLinkComponent } from "./components/practitionerLink/practitioner-link.component";
import { PredefinedFiltersComponent } from "./components/predefinedFilters/predefined-filters.component";
import { BackLinkComponent } from "./components/preloaders/backLink/back-link.component";
import { LoadMoreButtonComponent } from "./components/preloaders/loadMoreButton/load-more-button.component";
import { NotificationCountComponent } from "./components/preloaders/notificationCount/notification-count.component";
import { SearchButtonComponent } from "./components/preloaders/searchButton/search-button.component";
import { SearchButtonGroupComponent } from "./components/preloaders/searchButtonGroup/search-button-group.component";
import { SpinnerComponent } from "./components/preloaders/spinner/spinner.component";
import { ToggleFiltersComponent } from "./components/preloaders/toggleFilters/toggle-filters.component";
import { RichTextEditorComponent } from "./components/richTextEditor/rich-text-editor.component";
import { ScrollToFirstInvalidDirective } from "./components/scroll-to-first-invalid/scroll-to-first-invalid.directive";
import { SelectComponent } from "./components/select/select.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { TitleComponent } from "./components/title/title.component";
import { SlatItemComponent } from "./components/list/slat-item/slat-item.component";
import { TypeaheadComponent } from "./components/typeahead/typeahead.component";
import { UndergoneSurgicalProceduresCreateComponent } from "./components/undergone_surgical_procedures/create/undergone_surgical_procedures.component";
import { UndoActionButtonComponent } from "./components/undo/undo-action.component";
import { VisitDocumentsComponent } from "./components/visit-documents/visit-documents.component";
import { AgePipe } from "./pipes/age.pipe";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { DatetimePipe } from "./pipes/datetime.pipe";
import { DocumentLinkPipe } from "./pipes/document-link.pipe";
import { FullNamePipe } from "./pipes/full-name.pipe";
import { HelpLinkPipe } from "./pipes/help-link.pipe";
import { ListFilterPipe } from "./pipes/list-filter.pipe";
import { PatientLinkPipe } from "./pipes/patient-link.pipe";
import { PractitionerLinkPipe } from "./pipes/practitioner-link.pipe";
import { PredicatePipe } from "./pipes/predicate.pipe";
import { ApiV2EncountersPromiseDecorator } from "./services/api-v2-encounters-promise-decorator.service";
import { ApiV2PatientSummary } from "./services/api-v2-patient-summary";
import { ApiV2Patients } from "./services/api-v2-patients";
import { BrowserSessionStorageService } from "./services/browser-session-storage.service";
import { ErxTypeaheadUtilsService } from "./services/erx-typeahead-utils.service";
import { GlobalErrorHandler } from "./services/global-error-handler";
import { HttpErrorInterceptor } from "./services/http-error-interceptor";

import { DashboardCardComponent } from "./components/dashboard-card/dashboard-card.component";
import { DiagnosisPageFiltersComponent } from "./components/diagnoses-form/diagnosis-page/diagnosis-page-filters/diagnosis-page-filters.component";
import { DiagnosisPageComponent } from "./components/diagnoses-form/diagnosis-page/diagnosis-page.component";
import { DispenseInfoViewComponent } from "./components/erx/dispense-view-components/dispense-info/dispense-info-view.component";
import { DispenseMpaInfoComponent } from "./components/erx/dispense-view-components/dispense-mpa-info/dispense-mpa-info.component";
import { DispenseDocumentDataComponent } from "./components/erx/dispense-view-components/dispese-document-data/dispense-document-data.component";
import { RecipeDataComponent } from "./components/erx/recipe-data/recipe-data.component";
import { SafetyAndEducationDataComponent } from "./components/erx/safety-and-education-data/safety-and-education-data.component";
import { ShortcutFillComponent } from "./components/erx/shortcut-fill/shortcut-fill.component";
import { InrListPageComponent } from "./components/inr-list-page/inr-list-page.component";
import { CancelReasonModalComponent } from "./components/visit-documents/cancel-reason-modal/cancel-reason-modal.component";
import { ApiV2Risks } from "./services/api-v2-risks";
import { FormCommunicationService } from "./services/utils/form-communication.service";
import { MessageService } from "./services/utils/message.service";
import {ErecListFilterComponent} from "./components/erx/erecListFilter/erec-list-filter.component";
import {DispenseListFilterComponent} from "./components/erx/dispenseListFilter/dispense-list-filter.component";
import {
    DispenseDocumentsComponent
} from "./components/erx/dispense-view-components/dispense-documents/dispense-documents.component";
import {AllergiesSummaryComponent} from "./components/summaries/allergies-summary/allergies-summary.component";
import {
    PrescriptionsSummaryComponent
} from "./components/summaries/prescriptions-summary/prescriptions-summary.component";
import {InrSummaryComponent} from "./components/summaries/inr-summary/inr-summary.component";
import {ApiV2Inr} from "./services/api-v2-inr";

export function getTextCatalogProvider($injector: any) {
    return $injector.get("gettextCatalog");
}

function allowAttribute(componentFactory) {
    const wrapper = function($compile, $injector, $parse) {
        const component = componentFactory($compile, $injector, $parse);
        component.restrict = "AE";
        return component;
    };
    wrapper.$inject = ["$compile", "$injector", "$parse"];
    return wrapper;
}

/**
 * Root module for shared components
 */
@NgModule({
    //Double rendering fix for angular components, which are downgraded and used in angularjs.
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        HttpClientModule,
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        QuillModule,
        TypeaheadModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        RouterModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgClickOutsideDirective,
        TooltipModule.forRoot(),
        AgGridModule,
        FullCalendarModule,
    ],
    declarations: [
        DemoComponent,
        PageNotFoundComponent,
        // TranslatePipe,
        HelpLinkPipe,
        PredicatePipe,
        CapitalizePipe,
        FullNamePipe,
        AgePipe,
        ListFilterPipe,
        DatetimePipe,
        DocumentLinkPipe,
        PractitionerLinkPipe,
        PatientLinkPipe,
        EmptyComponent,
        PredefinedFiltersComponent,
        NhrRecordWidgetComponent,
        NhrWidgetComponent,
        LinkStatusBadgeComponent,
        DocRefConsentListComponent,
        DocRefConsentCardComponent,
        DocRefConsentSpanComponent,
        DocRefConsentViewComponent,
        DocRefConsentTemplateListComponent,
        DocRefConsentTemplateViewComponent,
        DiagnosesListModalComponent,
        EditDiagnoseModalComponent,
        RichTextEditorComponent,
        LoadMoreButtonComponent,
        SearchModalComponent,
        QuickSearchComponent,
        CustomSearchComponent,
        SearchButtonGroupComponent,
        SearchButtonComponent,
        FiltersGroupComponent,
        CheckboxComponent,
        DatePickerComponent,
        TimePickerComponent,
        ErrorMessageComponent,
        ModificationActionsComponent,
        CommonPatientWidgetComponent,
        RadioButtonComponent,
        InputComponent,
        SelectComponent,
        TitleComponent,
        SlatItemComponent,
        ToggleFiltersComponent,
        AllergiesSummaryComponent,
        PrescriptionsSummaryComponent,
        InrSummaryComponent,
        AgColFilterButton,
        AgColFilterText,
        AgPaginationBarComponent,
        AgOverlayLoadingComponent,
        PaginationBarComponent,
        ErecListComponent,
        DispenseListComponent,
        AgPillCell,
        AgTextCell,
        AgLinkCell,
        AgColHeaderLink,
        AgActionsCell,
        AgGridCheckboxFgCell,
        AgGridModalOpenLinkCell,
        OrderWithMetadataComponent,
        DocStatusIconComponent,
        PractitionerLinkComponent,
        DocRefAttachedUnsortedCardComponent,
        DocRefAttachedUnsortedListComponent,
        DocRefAttachedUnsortedViewComponent,
        AttachedUnsortedSearchComponent,
        BackLinkComponent,
        ModalComponent,
        DocRefConsentTemplateFilterComponent,
        DocRefConsentFilterComponent,
        SpinnerComponent,
        NotificationCountComponent,
        DocRef058ViewComponent,
        MultiselectDropdownComponent,
        DocRefF090ViewComponent,
        DocRefF090ListComponent,
        DocRefF090CardComponent,
        IconComponent,
        CellIconsComponent,
        ButtonComponent,
        SidebarButtonComponent,
        FormButtonsLayoutComponent,
        PageFiltersComponent,
        EncounterBlockComponent,
        DocRefF025ViewComponent,
        F090SearchComponent,
        AspnF027listFilterComponent,
        NursingAndCarePlanHistoryFilterComponent,
        PatientHealthAssessmentsReusableFormComponent,
        UndergoneSurgicalProceduresCreateComponent,
        PatientTransportedInstitutionComponent,
        DocRef110GMPViewComponent,
        DocRefE110ListComponent,
        DocRefE110CardComponent,
        DiagnosesFormComponent,
        DiagnosesCloseModalComponent,
        AgGridSelectFgCell,
        AgGridInputFgCell,
        NursingServiceOrderListFilterComponent,
        DoubleInputComponent,
        UndoActionButtonComponent,
        NgnCaptionComponent,
        FindSpecialistsComponent,
        AntibioticsComponent,
        DiagnosisByEncounterComponent,
        PartMedicalModalComponent,
        ErecHistoryPageComponent,
        DispenseHistoryPageComponent,
        VisitDocumentsComponent,
        FormControlValidationComponent,
        TypeaheadComponent,
        CurrentRisksListModalComponent,
        CurrentAllergiesListModalComponent,
        ScrollToFirstInvalidDirective,
        InrNotificationTypeSubformComponent,
        InrNotificationFormSubformComponent,
        InrReportingPersonInfoSubformComponent,
        InrDrugsCausedInrSubformComponent,
        InrWhomPersonInfoSubformComponent,
        InrVaccineCausedInrSubformComponent,
        InrDescriptionDrugsSubformComponent,
        InrDescriptionVaccineSubformComponent,
        InrOtherUsedMedicalProductsSubformComponent,
        InrAdditionalInfoSubformComponent,
        InrDoctorInformingSubformComponent,
        FindAndPrefillInfoComponent,
        NhrMsgGlobalComponent,
        PrimaryHealthCareComponent,
        PatientCalendarComponent,
        MedicationDosageComponent,
        DispenseInfoViewComponent,
        DispenseDocumentDataComponent,
        DispenseMpaInfoComponent,
        DispenseDocumentsComponent,
        ErxFilterComponent,
        DispenseListFilterComponent,
        ErecListFilterComponent,
        RecipeDataComponent,
        SafetyAndEducationDataComponent,
        DiagnosisPageComponent,
        DiagnosisPageFiltersComponent,
        ShortcutFillComponent,
        DashboardCardComponent,
        CancelReasonModalComponent,
        InrListPageComponent,
    ],
    exports: [
        CommonModule,
        HelpLinkPipe,
        PredicatePipe,
        CapitalizePipe,
        FullNamePipe,
        AgePipe,
        ListFilterPipe,
        DatetimePipe,
        DocumentLinkPipe,
        PractitionerLinkPipe,
        PatientLinkPipe,
        PredefinedFiltersComponent,
        NhrRecordWidgetComponent,
        NhrWidgetComponent,
        LinkStatusBadgeComponent,
        DocRefConsentListComponent,
        DocRefConsentCardComponent,
        DocRefConsentSpanComponent,
        DocRefConsentViewComponent,
        DocRefConsentTemplateListComponent,
        DocRefConsentTemplateViewComponent,
        DiagnosesListModalComponent,
        EditDiagnoseModalComponent,
        CheckboxComponent,
        DatePickerComponent,
        TimePickerComponent,
        ErrorMessageComponent,
        RichTextEditorComponent,
        ModificationActionsComponent,
        CommonPatientWidgetComponent,
        RadioButtonComponent,
        InputComponent,
        SelectComponent,
        TitleComponent,
        SlatItemComponent,
        ToggleFiltersComponent,
        AllergiesSummaryComponent,
        PrescriptionsSummaryComponent,
        InrSummaryComponent,
        OrderWithMetadataComponent,
        DocStatusIconComponent,
        PractitionerLinkComponent,
        DocRefAttachedUnsortedCardComponent,
        DocRefAttachedUnsortedListComponent,
        DocRefAttachedUnsortedViewComponent,
        AttachedUnsortedSearchComponent,
        QuickSearchComponent,
        CustomSearchComponent,
        BackLinkComponent,
        ModalComponent,
        DocRefConsentTemplateFilterComponent,
        DocRefConsentFilterComponent,
        SpinnerComponent,
        NotificationCountComponent,
        DocRef058ViewComponent,
        MultiselectDropdownComponent,
        DocRefF090ViewComponent,
        DocRefF090ListComponent,
        DocRefF090CardComponent,
        AgColFilterButton,
        AgColFilterText,
        AgPaginationBarComponent,
        AgOverlayLoadingComponent,
        PaginationBarComponent,
        IconComponent,
        CellIconsComponent,
        EmptyComponent,
        ButtonComponent,
        SidebarButtonComponent,
        FormButtonsLayoutComponent,
        PageFiltersComponent,
        EncounterBlockComponent,
        DocRefF025ViewComponent,
        F090SearchComponent,
        AspnF027listFilterComponent,
        NursingAndCarePlanHistoryFilterComponent,
        PatientHealthAssessmentsReusableFormComponent,
        UndergoneSurgicalProceduresCreateComponent,
        PatientTransportedInstitutionComponent,
        DocRef110GMPViewComponent,
        DocRefE110ListComponent,
        DocRefE110CardComponent,
        DiagnosesFormComponent,
        DiagnosesCloseModalComponent,
        NursingServiceOrderListFilterComponent,
        DoubleInputComponent,
        UndoActionButtonComponent,
        NgnCaptionComponent,
        TooltipModule,
        FindSpecialistsComponent,
        AntibioticsComponent,
        AgGridModule,
        DiagnosisByEncounterComponent,
        PartMedicalModalComponent,
        ErecHistoryPageComponent,
        DispenseHistoryPageComponent,
        VisitDocumentsComponent,
        FormControlValidationComponent,
        TypeaheadComponent,
        CurrentRisksListModalComponent,
        CurrentAllergiesListModalComponent,
        ScrollToFirstInvalidDirective,
        InrNotificationTypeSubformComponent,
        InrNotificationFormSubformComponent,
        InrReportingPersonInfoSubformComponent,
        InrDrugsCausedInrSubformComponent,
        InrWhomPersonInfoSubformComponent,
        InrVaccineCausedInrSubformComponent,
        InrDescriptionDrugsSubformComponent,
        InrDescriptionVaccineSubformComponent,
        InrOtherUsedMedicalProductsSubformComponent,
        InrAdditionalInfoSubformComponent,
        InrDoctorInformingSubformComponent,
        FindAndPrefillInfoComponent,
        NhrMsgGlobalComponent,
        PrimaryHealthCareComponent,
        PatientCalendarComponent,
        FullCalendarModule,
        MedicationDosageComponent,
        DispenseInfoViewComponent,
        DispenseDocumentDataComponent,
        DispenseMpaInfoComponent,
        DispenseDocumentsComponent,
        ErxFilterComponent,
        ErecListFilterComponent,
        DispenseListFilterComponent,
        RecipeDataComponent,
        SafetyAndEducationDataComponent,
        DiagnosisPageComponent,
        DiagnosisPageFiltersComponent,
        ShortcutFillComponent,
        DashboardCardComponent,
        CancelReasonModalComponent,
        InrListPageComponent,
    ],
    providers: [
        HelpLinkPipe,
        PredicatePipe,
        CapitalizePipe,
        FullNamePipe,
        DecimalPipe,
        AgePipe,
        DatetimePipe,
        DocumentLinkPipe,
        PractitionerLinkPipe,
        PatientLinkPipe,
        ListFilterPipe,
        routeParamsProvider,
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
    ],
})
export class SharedLibrary {
    constructor() {}

    static forRoot(): ModuleWithProviders<SharedLibrary> {
        return {
            ngModule: SharedLibrary,
            providers: [
                HelpLinkPipe,
                PredicatePipe,
                CapitalizePipe,
                FullNamePipe,
                AgePipe,
                DatetimePipe,
                DocumentLinkPipe,
                PractitionerLinkPipe,
                PatientLinkPipe,
                ListFilterPipe,
                TranslatePipe,
                BsModalService,
                routeParamsProvider,
                {
                    // processes all errors
                    provide: ErrorHandler,
                    useClass: GlobalErrorHandler,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpErrorInterceptor,
                    multi: true,
                },
            ],
        };
    }
}

//     .downgradeProvider(HelpLinkPipe)
//     .downgradeComponent(PredefinedFiltersComponent)
new DowngradeAdapter(ng1App)
    .downgradeComponent(ScrollToFirstInvalidDirective)
    .downgradeComponent(NhrMsgGlobalComponent)
    .downgradeProvider(BrowserSessionStorageService, "BrowserSessionStorage")

    // Not yet ready: Has problems with $promise resolution which is used in code
    .downgradeProvider(ApiV2EncountersPromiseDecorator, "EncountersApiFactory");

ng1App
    // .factory('$location', downgradeInjectable($locationShim))
    // .factory('translatePipe', downgradeInjectable(TranslatePipe))
    .factory("capitalizePipe", downgradeInjectable(CapitalizePipe))
    .directive("predefinedFilters", downgradeComponent({ component: PredefinedFiltersComponent }))
    .directive("nhrRecordWidget", downgradeComponent({ component: NhrRecordWidgetComponent }))
    .directive("nhrWidget", downgradeComponent({ component: NhrWidgetComponent }))
    .directive("loadMoreButton", downgradeComponent({ component: LoadMoreButtonComponent }))
    .directive("searchButtonGroup", downgradeComponent({ component: SearchButtonGroupComponent }))
    .directive("searchButton", downgradeComponent({ component: SearchButtonComponent }))
    .directive("slatItem", allowAttribute(downgradeComponent({ component: SlatItemComponent })))
    .directive("quickSearch", downgradeComponent({ component: QuickSearchComponent }))
    .directive("customSearch", downgradeComponent({ component: CustomSearchComponent }))
    .directive("backLink", downgradeComponent({ component: BackLinkComponent }))
    .directive("toggleFilters", downgradeComponent({ component: ToggleFiltersComponent }))
    .directive("notificationCount", downgradeComponent({ component: NotificationCountComponent }))
    .directive("spinner", downgradeComponent({ component: SpinnerComponent }))
    .directive("appButton", downgradeComponent({ component: ButtonComponent }))
    .directive("sidebarButton", downgradeComponent({ component: SidebarButtonComponent }))
    .directive("formButtonsLayout", downgradeComponent({ component: FormButtonsLayoutComponent }))
    .directive("appDiagnosesForm", downgradeComponent({ component: DiagnosesFormComponent }))
    .directive("paginationBar", downgradeComponent({ component: PaginationBarComponent }))
    .directive("erecList", downgradeComponent({ component: ErecListComponent }))
    .directive("erecHistoryPage", downgradeComponent({ component: ErecHistoryPageComponent }))
    .directive("dispenseHistoryPage", downgradeComponent({ component: DispenseHistoryPageComponent }))
    .directive("dispenseList", downgradeComponent({ component: DispenseListComponent }))
    .directive("appVisitDocuments", downgradeComponent({ component: VisitDocumentsComponent }))
    .directive("appEncounterBlock", downgradeComponent({ component: EncounterBlockComponent }))
    .directive("appTypeaheadComponent", downgradeComponent({ component: TypeaheadComponent }))
    .directive("appCommonPatientWidget", downgradeComponent({ component: CommonPatientWidgetComponent }))
    .directive("diagnosesCloseModal", downgradeComponent({ component: DiagnosesCloseModalComponent }))
    .directive("linkStatusBadge", downgradeComponent({ component: LinkStatusBadgeComponent }))
    .directive("primaryHealthCare", downgradeComponent({ component: PrimaryHealthCareComponent }))
    .directive("medicationDosage", downgradeComponent({ component: MedicationDosageComponent }))
    .directive("erxFilterComponent", downgradeComponent({ component: ErxFilterComponent }))
    .directive("appDiagnosisPage", downgradeComponent({ component: DiagnosisPageComponent }))
    .directive("appDiagnosisPageFilters", downgradeComponent({ component: DiagnosisPageFiltersComponent }))
    .directive("shortcutFill", downgradeComponent({ component: ShortcutFillComponent }))
    .directive("dashboardCard", downgradeComponent({ component: DashboardCardComponent }))
    .directive("safetyAndEducationData", downgradeComponent({ component: SafetyAndEducationDataComponent }))
    .directive("inrListPage", downgradeComponent({ component: InrListPageComponent }))
    .directive("recipeData", downgradeComponent({ component: RecipeDataComponent }))
    .directive("dispenseInfoView", downgradeComponent({ component: DispenseInfoViewComponent }))
    .directive("dispenseDocumentData", downgradeComponent({ component: DispenseDocumentDataComponent }))
    .directive("dispenseMpaInfo", downgradeComponent({ component: DispenseMpaInfoComponent }))
    .directive("dispenseDocuments", downgradeComponent({component: DispenseDocumentsComponent}))
    .directive("allergiesSummary", downgradeComponent({component: AllergiesSummaryComponent}))
    .directive("prescriptionsSummary", downgradeComponent({component: PrescriptionsSummaryComponent}))
    .directive("inrSummary", downgradeComponent({component: InrSummaryComponent}))
    .service("MessageService", downgradeInjectable(MessageService))
    .service("BsModalService", downgradeInjectable(BsModalService))
    .service("ApiV2Risks", downgradeInjectable(ApiV2Risks))
    .service("ApiV2PatientSummary", downgradeInjectable(ApiV2PatientSummary))
    .service("ApiV2Patients", downgradeInjectable(ApiV2Patients))
    .service("ApiV2Inr", downgradeInjectable(ApiV2Inr))
    .service("ErxTypeaheadUtilsService", downgradeInjectable(ErxTypeaheadUtilsService))
    .service("FormCommunicationService", downgradeInjectable(FormCommunicationService));
