ng1App.controller('document.E027ViewCtrl',
    ['$scope', '$resource', '$routeParams', 'DocumentCtx', 'MessageService', '$filter',
        function ($scope, $resource, $routeParams, DocumentCtx, MessageService, $filter) {
            $scope.documentId = $routeParams.documentId;
            $scope.patientId = $routeParams.patientId;
            $scope.currentDiagnoses = $scope.form.partDiagnosis;
            $scope.receiver = getReceiver();

            // Side content list
            $scope._shared = {
                // jus fill array with something
                contentList: _.range(9)
            }

            $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id: 'doc_mai_inf'};

            function getReceiver() {
                if ($scope.form.alertMessageItem) {
                    var receiverFilter = function (receiver) {
                        return receiver && (receiver.code === '2' || receiver.code === '3');
                    }
                    var filteredReceiver = $filter('filter')($scope.form.alertMessageItem.receivers, receiverFilter);
                    return filteredReceiver.length == 1 ? filteredReceiver[0] : null;
                }
            }
        }]);