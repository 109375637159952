import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-ent-system-form',
    templateUrl: './sbvf-ent-system-form.component.html',
})
export class SbvfEntSystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    entSystemRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        earLobeD: this.fb.control(null, [Validators.required]),
        earLobeK: this.fb.control(null, [Validators.required]),
        nasalDischarge: this.fb.control(null, [Validators.required]),
        nasalMucosa: this.fb.control(null, [Validators.required]),
        oralMucosa: this.fb.control(null, [Validators.required]),
        oralPlaque: this.fb.control(null, [Validators.required]),
        oralInfection: this.fb.control(null, [Validators.required]),
        dryMouth: this.fb.control(null, [Validators.required]),
        badBreath: this.fb.control(null, [Validators.required]),
        tongue: this.fb.control(null, [Validators.required]),
        tonguePlague: this.fb.control(null, [Validators.required]),
        dentalProstheses: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    })

    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.entSystemRows = [
            {
                title: 'Ausies landa (dešinė)',
                type: 'choise',
                options: value['earLobe'],
                formControl: this.formGroup.get('earLobeD')
            },
            {
                title: 'Ausies landa (kairė)',
                type: 'choise',
                options: value['earLobe'],
                formControl: this.formGroup.get('earLobeK')
            },
            {
                title: 'Nosies išskyros',
                type: 'choise',
                options: value['nasalDischarge'],
                formControl: this.formGroup.get('nasalDischarge')
            },
            {
                title: 'Nosies gleivinė',
                type: 'choise',
                options: value['nasalMucosa'],
                formControl: this.formGroup.get('nasalMucosa')
            },
            {
                title: 'Burnos gleivinė',
                type: 'choise',
                options: value['oralMucosa'],
                formControl: this.formGroup.get('oralMucosa')
            },
            {
                title: 'Burnos apnašas',
                type: 'choise',
                options: value['oralPlaque'],
                formControl: this.formGroup.get('oralPlaque')
            },
            {
                title: 'Burnos infekcija',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('oralInfection')
            },
            {
                title: 'Burnos džiūvimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('dryMouth')
            },
            {
                title: 'Kvapas iš burnos',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('badBreath')
            },
            {
                title: 'Liežuvis',
                type: 'choise',
                options: value['tongue'],
                formControl: this.formGroup.get('tongue')
            },
            {
                title: 'Liežuvio apnašas',
                type: 'choise',
                options: value['oralPlaque'],
                formControl: this.formGroup.get('tonguePlague')
            },
            {
                title: 'Dantų protezai',
                type: 'choise',
                options: value['eyeDrops'],
                formControl: this.formGroup.get('dentalProstheses')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
