ng1App.factory("ERXSettingsApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/erxSettings';

    return $resource('', {}, {
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        search: {
            method: 'GET',
            url: URL_BASE + '/forList',
            isArray: true
        },
        getById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        }
    });
}]);