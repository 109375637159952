import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { ApiV2f025 } from "src/api/api-v2-doc-ref-f025";
import { DocumentTagEnum } from "../../../models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";

@Component({
    selector: 'doc-ref-f025-view',
    templateUrl: './doc-ref-f025-form-view.component.html'
})
export class DocRefF025ViewComponent implements OnInit {

    backPath: string;
    patientId = null;
    form1: FormGroup;
    form2: FormGroup;
    form3: FormGroup;
    form4: FormGroup;
    form5: FormGroup;
    form6: FormGroup;
    emitedValues = null;
    viewData = null;
    documentTag = DocumentTagEnum.e027a;
    prefillData: any;
    isViewOnlyMode = true;
    isLoading = true;
    diagnosisArray = null;

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private fb: FormBuilder,
        private ApiV2F025: ApiV2f025,
        private router: Router
    ) {
        this.form1 = this.fb.group({
            gender: [],
            age: [],
            visitDate: [],
            diseaseFormType: [true],
            whoDirectedForTreatment: [],
            otherOptionsWhoDirectedForTreatment: [],
            possibleRehabForSubstanceAbuse: [],
            BeginningOfFirstTreatmentDate: [],
            opioidSubstitutionTherapy: [],
            firstTreatmentDate: []
        });
        this.form2 = this.fb.group({
            municipality: [],
            //placeOfResidence: [],
            dataAboutPlaceOfResidence: [],
            livesWithMinors: [],
            livesWithWho: [],
            currentEmployment: [],
            receivedEducation: [],
        });
        this.form3 = this.fb.group({
            substanceForSeekingHelp: [],
            mainWayOfUsingSubstance: [],
            firstTimeUseAge: [],
            firstTimeUseAgeForMainSubstance: [],
            frequencyOfSubstanceUse: [],
            useOfDifferentSubstances: [],
            useOfOtherSubstancesFirst: [],
            useOfOtherSubstancesSecond: [],
            useOfInjectableSubstances: [],
            timeSpanFromLastInjection: []
        });
        this.form4 = this.fb.group({
            useOfBorrowedEquipment: [],
            HIVpositive: [],
            checkedOutForHIV: [],
            sentToCheckForHIV: [],
            otherOptionsForHIV: [],
            hepatitisB: [],
            checkedOutForHepatitisB: [],
            sentToCheckForHepatitisB: [],
            otherOptionsForHepatitisB: [],
            vaccinatedForHepatitisB: [],
            hepatitisC: [],
            checkedOutForHepatitisC: [],
            sentToCheckForHepatitisC: [],
            otherOptionsForHepatitisC: [],
            tuberculosis: [],
            checkedOutForTuberculosis: [],
            sentToCheckForTuberculosis: [],
            otherOptionsForTuberculosis: []
        });
        this.form5 = this.fb.group({
            tumorDiagnosis: [],
            TLK_10_AM: [],
            diagnosisDate: [],
            DiagnosisF11_F19: [],
            diagnosisDateF11_F19: [],
            patientCareServiceType: [true],
            providedServices: [],
            treatmentUsedAtThisStage: [],
            otherTreatmentUsedAtThisStage: []
        });
        this.form6 = this.fb.group({
            dischargedFromHealthInstitution: [],
            otherInformation: [],
            formFillDate: []
        });
    }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/f090/list`;
        const id = this.params.viewId;
        this.loadF025(id);
        this.disableForms();
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    disableForms() {
        this.form1.disable();
        this.form2.disable();
        this.form3.disable();
        this.form4.disable();
        this.form5.disable();
        this.form6.disable();
    }

    loadF025(id: string) {
        return this.ApiV2F025.getF025FormView(id).subscribe((data: any) => {
            this.viewData = data;
            this.isViewOnlyMode = true;
            this.initializeData(data);
            this.diagnosisArray = data.diagnosis;
            this.isLoading = false;
        });
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    onChanges(event) {
        this.emitedValues = event;
    }

    initializeData(data) {
        // Form 1
        this.form1.controls['gender'].setValue(data.gender || '');
        this.form1.controls['age'].setValue(data.age || '');
        this.form1.controls['visitDate'].setValue(data.visitDate || '');
        this.form1.controls['diseaseFormType'].setValue(data.diseaseFormType !== undefined ? data.diseaseFormType : true);
        this.form1.controls['whoDirectedForTreatment'].setValue(data.whoDirectedForTreatment.code || '');
        this.form1.controls['otherOptionsWhoDirectedForTreatment'].setValue(data.otherOptionsWhoDirectedForTreatment || '');
        this.form1.controls['possibleRehabForSubstanceAbuse'].setValue(data.possibleRehabForSubstanceAbuse.code || '');
        this.form1.controls['BeginningOfFirstTreatmentDate'].setValue(data.BeginningOfFirstTreatmentDate || '');
        this.form1.controls['opioidSubstitutionTherapy'].setValue(data.opioidSubstitutionTherapy.code || '');
        this.form1.controls['firstTreatmentDate'].setValue(data.firstTreatmentDate || '');

        // Form 2
        this.form2.controls['municipality'].setValue(data.municipality || '');
        this.form2.controls['dataAboutPlaceOfResidence'].setValue(data.dataAboutPlaceOfResidence.code || '');
        this.form2.controls['livesWithMinors'].setValue(data.livesWithMinors.code || '');
        this.form2.controls['livesWithWho'].setValue(data.livesWithWho.code || '');
        this.form2.controls['currentEmployment'].setValue(data.currentEmployment.code || '');
        this.form2.controls['receivedEducation'].setValue(data.receivedEducation.code || '');

        // Form 3
        this.form3.controls['substanceForSeekingHelp'].setValue(data.substanceForSeekingHelp.code || '');
        this.form3.controls['mainWayOfUsingSubstance'].setValue(data.mainWayOfUsingSubstance.code || '');
        this.form3.controls['firstTimeUseAge'].setValue(data.firstTimeUseAge || '');
        this.form3.controls['firstTimeUseAgeForMainSubstance'].setValue(data.firstTimeUseAgeForMainSubstance || '');
        this.form3.controls['frequencyOfSubstanceUse'].setValue(data.frequencyOfSubstanceUse.code || '');
        this.form3.controls['useOfDifferentSubstances'].setValue(data.useOfDifferentSubstances.code || '');
        this.form3.controls['useOfOtherSubstancesFirst'].setValue(data.useOfOtherSubstancesFirst.code || '');
        this.form3.controls['useOfOtherSubstancesSecond'].setValue(data.useOfOtherSubstancesSecond.code || '');
        this.form3.controls['useOfInjectableSubstances'].setValue(data.useOfInjectableSubstances.code || '');
        this.form3.controls['timeSpanFromLastInjection'].setValue(data.timeSpanFromLastInjection.code || '');

        // Form 4
        this.form4.controls['useOfBorrowedEquipment'].setValue(data.useOfBorrowedEquipment.code || '');
        this.form4.controls['HIVpositive'].setValue(data.HIVpositive.code || '');
        this.form4.controls['checkedOutForHIV'].setValue(data.checkedOutForHIV.code || '');
        this.form4.controls['sentToCheckForHIV'].setValue(data.sentToCheckForHIV.code || '');
        this.form4.controls['otherOptionsForHIV'].setValue(data.otherOptionsForHIV || '');
        this.form4.controls['hepatitisB'].setValue(data.hepatitisB.code || '');
        this.form4.controls['checkedOutForHepatitisB'].setValue(data.checkedOutForHepatitisB.code || '');
        this.form4.controls['sentToCheckForHepatitisB'].setValue(data.sentToCheckForHepatitisB.code || '');
        this.form4.controls['otherOptionsForHepatitisB'].setValue(data.otherOptionsForHepatitisB || '');
        this.form4.controls['vaccinatedForHepatitisB'].setValue(data.vaccinatedForHepatitisB.code || '');
        this.form4.controls['hepatitisC'].setValue(data.hepatitisC.code || '');
        this.form4.controls['checkedOutForHepatitisC'].setValue(data.checkedOutForHepatitisC.code || '');
        this.form4.controls['sentToCheckForHepatitisC'].setValue(data.sentToCheckForHepatitisC.code || '');
        this.form4.controls['otherOptionsForHepatitisC'].setValue(data.otherOptionsForHepatitisC || '');
        this.form4.controls['tuberculosis'].setValue(data.tuberculosis.code || '');
        this.form4.controls['checkedOutForTuberculosis'].setValue(data.checkedOutForTuberculosis.code || '');
        this.form4.controls['sentToCheckForTuberculosis'].setValue(data.sentToCheckForTuberculosis.code || '');
        this.form4.controls['otherOptionsForTuberculosis'].setValue(data.otherOptionsForTuberculosis || '');

        // Form 5
        this.form5.controls['tumorDiagnosis'].setValue(data.tumorDiagnosis || '');
        this.form5.controls['TLK_10_AM'].setValue(data.TLK_10_AM || '');
        this.form5.controls['diagnosisDate'].setValue(data.diagnosisDate || '');
        this.form5.controls['DiagnosisF11_F19'].setValue(data.DiagnosisF11_F19 || '');
        this.form5.controls['diagnosisDateF11_F19'].setValue(data.diagnosisDateF11_F19 || '');
        this.form5.controls['patientCareServiceType'].setValue(data.patientCareServiceType !== undefined ? data.patientCareServiceType : true);
        this.form5.controls['providedServices'].setValue(data.providedServices || '');
        this.form5.controls['treatmentUsedAtThisStage'].setValue(data.treatmentUsedAtThisStage.code || '');
        this.form5.controls['otherTreatmentUsedAtThisStage'].setValue(data.otherTreatmentUsedAtThisStage || '');

        // Form 6
        this.form6.controls['dischargedFromHealthInstitution'].setValue(data.dischargedFromHealthInstitution.code || '');
        this.form6.controls['otherInformation'].setValue(data.otherInformation || '');
        this.form6.controls['formFillDate'].setValue(data.formFillDate || '');
    }

    undoClick(): void {
        this.router.navigate([`dp/patients/${this.patientId}/f090/list`]);
    }
}