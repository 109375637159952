import {AfterViewInit, Component, Input} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {mapForCustomIconCell} from "../../models/erx-supply-disruption.model";

interface ICustomCellIconsParams {
    icons: CellIcon[];
    additionalClass: string;
    dynamicIconKeys?: string[];
}

interface CellIcon {
    key: string;
    icon: string;
    tooltip: string;
}

@Component({
    selector: 'app-cell-icons',
    templateUrl: './cell-icons.component.html',
})
export class CellIconsComponent implements ICellRendererAngularComp, AfterViewInit {
    @Input() icons: CellIcon[];
    @Input() additionalClass: string;
    private value: any;
    private params: ICellRendererParams & ICustomCellIconsParams;
    private dynamicIconKeys: string[] = [];

    agInit(params: ICellRendererParams & ICustomCellIconsParams): void {
        this.params = params;
        this.icons = params.icons;
        this.additionalClass = params.additionalClass;
        this.dynamicIconKeys = params.dynamicIconKeys ? params.dynamicIconKeys : this.dynamicIconKeys;
        this.value = params.value;
    }

    ngAfterViewInit(): void {
        this.initDynamicIcons();
    }

    refresh(params: ICellRendererParams<any>): boolean {
        this.value = params.value;
        return false;
    }

    getIconClass(index: number): string {
        return `${this.icons[index].icon} ${this.additionalClass}`;
    }

    showIcon(index: number): boolean {
        const cellIcon: CellIcon = this.icons[index];
        if(this.dynamicIconKeys.includes(cellIcon.key)){
            return cellIcon.icon !== undefined;
        }

        const cellObject = this.getCellValue();
        return cellObject[cellIcon.key];
    }

    getCellValue(): any {
        return this.value;
    }

    initDynamicIcons() {
        this.dynamicIconKeys.forEach(key => {
            this.icons = this.icons.map(icon => {
                if (icon.key === key) {
                    return mapForCustomIconCell(icon.key, this.getCellValue()) as CellIcon;
                }
                return icon;
            });
        });
    }
}
