import { ChangeDetectionStrategy, Component, ViewChild, type OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, take } from "rxjs";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { PaginationBarComponent } from "../../../../shared/components/list/pagination-bar/pagination-bar.component";
import { Pagination } from "../../../../shared/components/list/pagination-bar/pagination-bar.constants";
import { PaginationStaticData } from "../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { NgnDocument, NgnDocumentFilters } from "../../models/pregnancyDashboard.model";
import {PractitionerModel} from "../../../../shared/models/practitioner.model";

@Component({
    selector: "app-preganncy-dashboard-list",
    templateUrl: "./preganncy-dashboard-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreganncyDashboardListComponent implements OnInit {
    @ViewChild("paginationBar") paginationBarComponent: PaginationBarComponent;
    public ngnDocuments$ = new BehaviorSubject<NgnDocument[]>([]);

    public btnPressed: boolean;
    public total: number;
    public pagesCount: number;
    public isLoaded: boolean = true;

    private lastFilters: NgnDocumentFilters = PaginationStaticData;

    private patientId: string;

    constructor(private params: RouteParams, private router: Router, private _apiV2Documents: ApiV2Documents) {}

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.filterList();
    }

    public updateFilters(filters: NgnDocumentFilters) {
        if (!filters) {
            return;
        }
        this.filterList(filters);

        this.paginationBarComponent.currentPage = 0;
        this.paginationBarComponent.realCurrentPage = 1;
    }

    public filterList(filters?: NgnDocumentFilters): void {
        const equal = _.isEqual(filters, this.lastFilters);

        if (equal) {
            return;
        }

        if (filters && Object.values(filters).some((value) => value !== null && value !== undefined && value !== "")) {
            this.lastFilters = { ...filters, count: 10 };
        }

        const params = {
            ...filters,
            dateFrom: filters?.dateFrom ? new Date(filters?.dateFrom).getTime() : "",
            dateTo: filters?.dateTo ? new Date(filters?.dateTo).getTime() : "",
            patient: this.patientId,
            docType: (filters?.docType || []).map((type) => type.value),
            status: (filters?.status || []).map((type) => type.code),
        };

        delete params["filterByVisitData"];
        delete params["targetQualificationCodes1"];
        delete params["nameSPI1"];

        for (const key in params) {
            if (
                params.hasOwnProperty(key) &&
                (params[key] === null || params[key] === undefined || params[key] === "" || params[key]?.length === 0)
            ) {
                delete params[key];
            }
        }

        this.isLoaded = false;

        this._apiV2Documents
            .searchForListWithTotals(params)
            .pipe(take(1))
            .subscribe((res) => {
                const ngnDocuments = res.items.map((doc) => {
                    const conditions = doc.conditions.map((condition) => condition.diagnosisText).join("; ");

                    const specialist: PractitionerModel = {
                        ...doc.practitioner,
                        practitionerOrganization: doc.organization,
                    };

                    return { ...doc, conditions, specialist };
                });

                this.ngnDocuments$.next(ngnDocuments);
                this.total = res.total;

                const pagesCount = res.total / this.lastFilters.count;
                this.pagesCount = Math.ceil(pagesCount);

                this.isLoaded = true;
            });
    }

    public viewDocument(documentId: string, documentTag: DocumentTagEnum): void {
        // this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${documentTag}/${documentId}`]);
        this.router.navigate([`/dp/patients/${this.patientId}/ngn/view/${documentTag}`]);
    }

    public editDocument(documentId: string, documentTag: DocumentTagEnum): void {
        // this.router.navigate([`/dp/patients/${this.patientId}/ngn/${documentTag}/${documentId}`]);
        this.router.navigate([`/dp/patients/${this.patientId}/ngn/${documentTag}`]);
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        const filter = { ...this.lastFilters, page: pagination.page };

        this.filterList(filter);
    }
}
