import { AfterViewInit, Component, DestroyRef, Input, ViewChild, ViewChildren } from '@angular/core';
import { clearFormArray, formatDateForDatePickers, updateFormArray } from '../../e096-utils/utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { debounceTime, delay, forkJoin, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { E096NGeneralDataComponent } from './e096-n-sub-forms/e096-n-general-data/e096-n-general-data.component';
import { E096NNewbornComponent } from './e096-n-sub-forms/e096-n-newborn/e096-n-newborn.component';


@Component({
    selector: 'app-e096-n-form',
    templateUrl: './e096-n-form.component.html',
})
export class E096NFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: {[key: string]: any}) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);

        this.patchFormWithInitialData(this._initialFormValue);
    }

    @ViewChild(E096NGeneralDataComponent) generalDataComponent: E096NGeneralDataComponent;
    @ViewChildren(E096NNewbornComponent) newbornComponents: E096NNewbornComponent[];

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: {[key: string]: any};
    private dateFields: string[] = ['birthTime', 'transferTime'];

    private clearfArray = clearFormArray.bind(this) as typeof clearFormArray;
    private updateFormArray = updateFormArray.bind(this) as typeof updateFormArray;

    get fruitLength() {
        return _.times(this.generalDataComponent?.fruitLength || 0, (n) => this.generateFruitName(n));
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
        private readonly destroyRef: DestroyRef
    ) {
        forkJoin({
            pregnancyOutcome: this.apiV2Entities.getEntitiesList('pregnancy-outcome'),
            stillbirth: this.apiV2Entities.getEntitiesList('stillbirth'),
            fetalPrimordial: this.apiV2Entities.getEntitiesList('fetal-primordial'),
            fetalFluidColor: this.apiV2Entities.getEntitiesList('fetal-fluid-color'),
            administrativeGender: this.apiV2Entities.getEntitiesList('administrative-gender'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            generalData: this.generalDataComponent?.formGroup,
            fruits: this.fb.array([])
        });
        this.formGroup.get('generalData.numberOfFruits').valueChanges.pipe(takeUntilDestroyed(this.destroyRef), delay(10))
            .subscribe(() => {
                this.formGroup.removeControl('fruits')
                this.formGroup.addControl('fruits',
                    this.fb.array(this.newbornComponents.map(component => component.formGroup)));
            });
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }

    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.patchFormWithInitialData(this._initialFormValue);
    }

    private patchFormWithInitialData(_initialFormValue: {[key: string]: any}) {
        this.formGroup.valueChanges.pipe(
            debounceTime(100),
            take(1)
        ).subscribe(() => {
            this.formGroup.patchValue(this._initialFormValue);
            this._initialFormValue?.['fruits'].forEach((_, i) => {
                this.clearfArray(`fruits.${i}.childData.transferOfNewborn.doctorReferredNewborn`);
                this.clearfArray(`fruits.${i}.childData.transferOfNewborn.doctorAdmittedNewborn`);
                this.updateFormArray(this._initialFormValue, `fruits.${i}.childData.transferOfNewborn.doctorReferredNewborn`);
                this.updateFormArray(this._initialFormValue, `fruits.${i}.childData.transferOfNewborn.doctorAdmittedNewborn`);
            });
            if (!this.editable) {
                this.formGroup.disable();
            }
        });

        this.formGroup.patchValue(this._initialFormValue);
    }

    private generateFruitName(i: number): string {
        return String.fromCharCode(65 + i);
    }
}
