/* global angular */

ng1App.controller('doctor.user.MessagesCtrl', 
    ['$scope', '$filter', '$modalConfirm', '$location', 'AlertsApiFactory', 'MessageService', 'DpMessagesUtil', 'FormLoaderService', 'SearchController', 'DpSessionContext',
     function ($scope, $filter, $modalConfirm,
                                                        $location, AlertsApiFactory, MessageService, DpMessagesUtil, FormLoaderService, SearchController, DpSessionContext) {
    // Init
    $scope.$emit('setHelpPage', 'perzireti-pranesimu-sarasa');
    $scope.folders = [
        {title: 'dp.use.mess.rec.all.rea', query: {folder: 'INBOX'}}, // Visi gauti
        {title: 'dp.use.mess.rec.unr', query: {folder: 'UNREAD'}}, // Neperskaityti
        {title: 'dp.use.mess.rec.rea', query: {folder: 'READ'}}, // Perskaityti
        {title: 'dp.use.mess.rec.sen', query: {folder: 'SENT'}} // Išsiųstieji
    ];

    $scope.searchCtrl = new SearchController({
        viewId: 'DP_MESSAGES',
        count: 10,
        dialogTpl: '/templates/portal/doctor/user/messages/search.html',
        initForm: function () {
            return {
                filter: $scope.folders[0],
                mess: 'RECEIVED',
                shi: 'oth'
            };
        },
        searchApi: AlertsApiFactory.searchMessages,
        showPageCounter: true
    });

    $scope.searchCtrl.formToQuery = function (form) {
        var query: any = {};
        switch (form._searchType) {
            case 'quick':
                form.filter = undefined;
                query.text = form.q;
                break;
            case 'all':
            case 'filter':
                form.q = undefined;
                query.folder = form.filter.query.folder;
                break;
            case 'advanced':
                form.filter = undefined;
                form.q = undefined;
                query.dateFrom = form.dateFrom;
                query.dateTo = form.dateTo;
                query.messageType = form.mess;
                query.systemSender = form.shi=='sys'?true:false;
                query.sender = form.sender;
                query.receiver = form.receiver;
                query.text = form.text;
                break;
            default:
                form.q = undefined;
                query.folder = form.filter.query.folder;
                break;
        }

        return query;
    };

    $scope.searchCtrl.handleResult = function (result) {
        SearchController.prototype.handleResult.call(this, result);

        $scope.$emit('updateUnreadMsgCount', result.unread);

        $scope.folders[2].count = result.read;
        $scope.folders[1].count = result.unread;
        $scope.folders[3].count = result.sent;
        $scope.folders[0].count = result.inbox;

        $scope.deselectAllMessages();
    };

    $scope.handlers = {
        selectedMessages: {},
        util: DpMessagesUtil,
        goToMessage: function (messageId) {
            MessageService.clearAll();
            $location.path('/dp/messages/' + messageId);// .search(searchParams);
        },
        deleteOne: function (messageId) {
            MessageService.clearAll();
            $modalConfirm.show(
                    {title: $filter('translate')('err.ale.tit.mess.del'), content: $filter('translate')('err.ale.mess.del')}).then(function () {
                AlertsApiFactory.deleteMessages([messageId], function (data) {
                    FormLoaderService.startFormLoading();
                    $scope.searchCtrl.searchLastOrAll();
                    MessageService.success($filter('translate')('mess.one.del.success'));
                });
            });
        }
    };

    $scope.selectFolder = function (folder) {
        $scope.searchCtrl.clear();
        $scope.searchCtrl._form.filter = folder;
        $scope.searchCtrl.searchFilter();
    };

    $scope.selectAllMessages = function () {
        angular.forEach($scope.searchCtrl._list, function (item) {
            $scope.handlers.selectedMessages[item.id] = $filter('acWrite')('MessageList') && $filter('acWrite')('Alert', [item.alert.category.code]);
        });
    };

    $scope.deselectAllMessages = function () {
        $scope.handlers.selectedMessages = {};
    };

    $scope.delete = function () {
        MessageService.clearAll();
        var ids = [];
        angular.forEach($scope.handlers.selectedMessages, function (val, key) {
            if (val) {
                ids.push(key);
            }
        });
        if (ids.length > 0) {
            $modalConfirm.show(
                    {title: $filter('translate')('err.ale.tit.mess.del.mark'), content: $filter('translate')('err.ale.mess.del.mark')}).then(function () {


                AlertsApiFactory.deleteMessages(ids, function (data) {
                    FormLoaderService.startFormLoading();
                    $scope.searchCtrl.searchLastOrAll();
                    MessageService.success($filter('translate')('mess.del.success'));
                });
            });
        }
    };

    DpSessionContext.getSettings(function (data) {
        MessageService.clearAll();
        $scope.searchCtrl.setCount(data.count_per_page || 10);
        $scope.searchCtrl.searchLastOrAll();
    });

}]);