import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-general-data-form',
    templateUrl: './sbvf-general-data-form.component.html',
})
export class SbvfGeneralDataFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    generalDataRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        somaticState: this.fb.control(null, [Validators.required]),
        consciousness: this.fb.control(null, [Validators.required]),
        communication: this.fb.control(null, [Validators.required]),
        concomitantDiseases: this.fb.control(null, [Validators.required]),
        concomitantDiseasesOther: this.fb.control(null),
    })
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.generalDataRows = [
            {
                title: 'Somatinė būsena',
                type: 'choise',
                options: value['somaticState'],
                formControl: this.formGroup.get('somaticState')
            },
            {
                title: 'Sąmonė',
                type: 'choise',
                options: value['consciousness'],
                formControl: this.formGroup.get('consciousness')
            },
            {
                title: 'Bendravimas',
                type: 'choise',
                options: value['communication'],
                formControl: this.formGroup.get('communication')
            },
            {
                title: 'Gretutinės ligos',
                type: 'choise',
                options: value['concomitant-diseases'],
                otherValueId: '7',
                formControl: this.formGroup.get('concomitantDiseases'),
                otherFormControl: this.formGroup.get('concomitantDiseasesOther')
            }
        ];
    }
}
