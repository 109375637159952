import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {InrSummaryModel} from "../../../models/inr-summary.model";
import {EnvironmentSettings} from "../../../../../environments/env";

@Component({
    selector: 'inr-summary',
    templateUrl: './inr-summary.component.html'
})
export class InrSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() inr: InrSummaryModel[] = [];
    @Input() patientId: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    showDocument(docId: string) {
        EnvironmentSettings.isSpecialist() ?
            this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]) :
            this.router.navigate([`patients/${this.patientId}/documents/${docId}`]);
    }

    getAllUrl() {
        return EnvironmentSettings.isSpecialist() ?
            `/dp/patients/${this.patientId}/inr` :
            `/patients/${this.patientId}/inr`;

    }

    getCreateUrl() {
        return EnvironmentSettings.isSpecialist() ?
            `/dp/patients/${this.patientId}/inr/new` :
            `/patients/${this.patientId}/inr/new`;

    }
}