import {Component, OnInit} from "@angular/core";
import { RouteParams } from "src/app/ng1.routeParams";

@Component({
    selector: 'dp-doc-ref-f025-list',
    templateUrl: './dp-doc-ref-f025-list.component.html'
})
export class DpDocRefF025ListComponent implements OnInit {
    backPath = '';
    constructor(private params: RouteParams) {}
    ngOnInit(): void {
        this.backPath = `dp/patients/${this.params.patientId}/f090/list`;
    }
}