ng1App.controller('doctor.patient.cert.e083-1EditCtrl', 
    ['$scope', '$routeParams', '$filter', 'EntitiesApiFactory', 'CertificatePayment', 'TypeaheadSearchHandlers', 'CertificateService', 'CommonFunctionService', 'MessageService', '$window',
     function ($scope, $routeParams, $filter, EntitiesApiFactory, CertificatePayment, TypeaheadSearchHandlers, CertificateService, CommonFunctionService, MessageService, $window) {
    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.e083.mai.inf'),
                id: 'cer_e083_mai_inf'
            },
            {
                title: $filter('translate')('cer.e083.mai.con'),
                id: 'cer_e083_mai_con',
                show: function () {
                    if ($scope.certificate.initialSave) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e083.con'),
                id: 'cer_e083_con',
                show: function () {
                    if (!$scope.certificate.initialSave) {
                        return true;
                    }
                    return false;
                }
            },
            {
                title: $filter('translate')('cer.e083.doc'),
                id: 'cer_e083_doc'
            },
            {
                title: $filter('translate')('cer.e083.fir'),
                id: 'cer_e083_fir'
            },
            {
                title: $filter('translate')('cer.e083.sec'),
                id: 'cer_e083_sec'
            },
            {
                title: $filter('translate')('cer.e083.oth'),
                id: 'cer_e083_oth'
            },
            {
                title: $filter('translate')('cer.e083.res'),
                id: 'cer_e083_res'
            },
            {
                title: $filter('translate')('cer.e083.ext'),
                id: 'cer_e083_ext'
            }
        ]
    };

    $scope.$emit('setHelpPage', 'kurti-pazyma-e083-1-vairuotojo-sveikatos-patikrinimo-medicinine-pazyma');

    CertificatePayment.showPaymentMessage('e083-1');
    $scope.compositionId = $routeParams.id;
    $scope.patientId = $routeParams.patientId;
    $scope.currentDate = new Date();

    // Get values 
    $scope.drivingAbilityConslution = EntitiesApiFactory.getList({classCode: 'driving-ability-conclusion'});
    $scope.bloodGroups = EntitiesApiFactory.getList({classCode: 'blood-group'});
    $scope.vehicleCategories = EntitiesApiFactory.getList({classCode: 'vehicle-category'});
    $scope.vehicleCategoryGroups = EntitiesApiFactory.getList({classCode: 'vehicle-category-group'});

    if (!$scope.certificate.group1) {
        $scope.certificate.group1 = {
            categories: [],
            validTill: null
        };
    }
    if (!$scope.certificate.group2) {
        $scope.certificate.group2 = {
            categories: [],
            validTill: null
        };
    }
    if (!$scope.certificate.other) {
        $scope.certificate.other = {
            categories: [],
            validTill: null
        };
    }

    // Group validity dates
    var dateErrors = {
        group1DateEarly: false,
        group1DateLate: false,
        group2DateEarly: false,
        group2DateLate: false
    };

    CommonFunctionService.getTrueEntityValue($scope.certificate, 'conclusion', $scope.drivingAbilityConslution);
    CommonFunctionService.getTrueEntityValue($scope.certificate, 'bloodGroup', $scope.bloodGroups);

    $scope.certificate.initialSave = $scope.initialSave;

    $scope.savePartial = function () {
        if (allGroupsEmpty()) {
            MessageService.error($filter('translate')('err.cer.e083_1.no.gr'));
            window.scrollTo(0, 10);
            return;
        }
        if ($scope.certificate.conclusion.code === '2' && ($scope.certificate.limitations === undefined || $scope.certificate.limitations.length === 0)) {
            MessageService.error($filter('translate')('err.cer.e083_1.no.lim'));
            window.scrollTo(0, 10);
            return;
        }
        CertificateService.savePartial('e083_1', $scope.certificate, $scope.certificateForm);
    };

    $scope.saveAndSign = function (doSign) {
        if (allGroupsEmpty()) {
            MessageService.error($filter('translate')('err.cer.e083_1.no.gr'));
            $window.scrollTo(0, 10);
            return;
        }
        if ($scope.certificate.conclusion.code === '2' && ($scope.certificate.limitations === undefined || $scope.certificate.limitations.length === 0)) {
            MessageService.error($filter('translate')('err.cer.e083_1.no.lim'));
            window.scrollTo(0, 10);
            return;
        }
        if(dateErrors.group1DateEarly || dateErrors.group1DateLate){
            _.scrollTo('[id="cer_e083_fir"]');
            return;
        }
        if(dateErrors.group2DateEarly || dateErrors.group2DateLate){
            _.scrollTo('[id="cer_e083_sec"]');
            return;
        }
        CertificateService.saveAndSign('e083_1', $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    var allGroupsEmpty = function () {
        return $scope.certificate.group1.categories.length == 0 && !$scope.certificate.group1.validTill
                && $scope.certificate.group2.categories.length == 0 && !$scope.certificate.group2.validTill
                && $scope.certificate.other.categories.length == 0 && !$scope.certificate.other.validTill;
    };

    $scope.group1DateValidation = function () {
        if ($scope.certificate.creationTime && $scope.certificate.group1.validTill) {
            var creation = moment($scope.certificate.creationTime);
            var validTill = moment($scope.certificate.group1.validTill);
            var diff = Math.abs(creation.diff(validTill, 'year', true));

            dateErrors.group1DateEarly = $scope.certificate.creationTime > $scope.certificate.group1.validTill;
            dateErrors.group1DateLate = diff > 10;

            return !(dateErrors.group1DateEarly || dateErrors.group1DateLate);
        }
        return true;
    };

    $scope.group2DateValidation = function () {
        if ($scope.certificate.creationTime && $scope.certificate.group2.validTill) {
            var creation = moment($scope.certificate.creationTime);
            var validTill = moment($scope.certificate.group2.validTill);
            var diff = Math.abs(creation.diff(validTill, 'year', true));

            dateErrors.group2DateEarly = $scope.certificate.creationTime > $scope.certificate.group2.validTill;
            dateErrors.group2DateLate = diff > 5;

            return !(dateErrors.group2DateEarly || dateErrors.group2DateLate);
        }
        return true;
    };

    $scope.otherGroupsDateValidation = function () {
        if ($scope.certificate.creationTime && $scope.certificate.other.validTill) {
            return $scope.certificate.creationTime <= $scope.certificate.other.validTill;
        }

        return true;
    };

    $scope.group1DateError = function () {
        if (dateErrors.group1DateEarly) {
            return 'err.cer.e083.val.dat';
        }
        if (dateErrors.group1DateLate) {
            return 'err.cer.e083.dat.gr1';
        }
    };

    $scope.group2DateError = function () {
        if (dateErrors.group2DateEarly) {
            return 'err.cer.e083.val.dat';
        }
        if (dateErrors.group2DateLate) {
            return 'err.cer.e083.dat.gr2';
        }
    };

    $scope.otherGroupsDateError = function () {
        return 'err.cer.e083.val.dat';
    };

    // Limitations    
    $scope.limitationData = {};
    $scope.searchLimitations = TypeaheadSearchHandlers.DRIVING_LIMITATIONS;

    $scope.limitationChanged = function (limitation) {
        if (limitation && limitation.code) {
            $scope.limitationData.limitation = limitation;
            $scope.limitationData.additionalInfo = null;

            // Synchronise model with view
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.$watch('limitationData.appliedTo', function (newValue) {
        if (newValue == 'group') {
            $scope.limitationData.category = null;
            $scope.limitationData.group = $scope.vehicleCategoryGroups[0];
        } else if (newValue == 'category') {
            $scope.limitationData.group = null;
        }
    });

    $scope.addLimitation = function () {
        // Validate
        $scope.limitationData.errors = {};
        var hasErrors = false;

        if ($scope.limitationData.limitation && $scope.limitationData.limitation.code && ($scope.limitationData.limitation.code == "50" || $scope.limitationData.limitation.code == "62")) {
            //alphanumeric
            var ALPHANUMERIC_REGEXP = /^[a-z0-9]+$/i;
            if ($scope.limitationData.additionalInfo && $scope.limitationData.additionalInfo != "" && !ALPHANUMERIC_REGEXP.test($scope.limitationData.additionalInfo)){
                hasErrors = true;
                $scope.limitationData.errors['alphanumericRequired'] = true;
            }
        } else if ($scope.limitationData.limitation && $scope.limitationData.limitation.code && !($scope.limitationData.limitation.code == "50" || $scope.limitationData.limitation.code == "62")){
            //numeric
            var NUMERIC_REGEXP = /\D/;
            if ($scope.limitationData.additionalInfo && $scope.limitationData.additionalInfo != "" && NUMERIC_REGEXP.test($scope.limitationData.additionalInfo)){
                hasErrors = true;
                $scope.limitationData.errors['numericRequired'] = true;
            }
        }

        if (!$scope.limitationData.limitation || !$scope.limitationData.limitation.code) {
            hasErrors = true;
            $scope.limitationData.errors['limitationRequired'] = true;
        }
        if (!$scope.limitationData.appliedTo) {
            hasErrors = true;
            $scope.limitationData.errors['appliedToRequired'] = true;
        } else {
            if (($scope.limitationData.appliedTo == 'category') && !$scope.limitationData.category) {
                hasErrors = true;
                $scope.limitationData.errors['categoryRequired'] = true;
            }
        }

        if (hasErrors) {
            return;
        }

        // Add new limitation
        if (!$scope.certificate.limitations) {
            $scope.certificate.limitations = [];
        }

        var limitation: any = {
            category: $scope.limitationData.category,
            limitation: $scope.limitationData.limitation,
            additionalInfo: $scope.limitationData.additionalInfo
        };

        if (!limitation.category) {
            if ($scope.limitationData.group) {
                limitation.groups = [];
                limitation.groups.push($scope.limitationData.group);
            } else {
                limitation.groups = $scope.vehicleCategoryGroups;
            }
        }

        $scope.certificate.limitations.unshift(limitation);

        $scope.limitationData.limitation = null;
        $scope.limitationData.tempLimitation = null;
        $scope.limitationData.group = null;
        $scope.limitationData.category = null;
        $scope.limitationData.additionalInfo = null;
        $scope.limitationData.appliedTo = null;
    };

    $scope.getLimitationRepresentation = function (limitation) {
        var result = limitation.limitation.code + ' ';
        result += limitation.limitation.name;
        if (limitation.additionalInfo) {
            result += ' (' + limitation.additionalInfo + ')';
        }
        return result;
    };

    $scope.getLimitationScope = function (limitation) {
        if (limitation.category) {
            return limitation.category.name;
        }
        if (limitation.groups) {
            if (limitation.groups.length == 1) {
                return limitation.groups[0].name;
            }
        }
        return $filter('translate')('cer.e083.res.for.group.all');
    };

    $scope.removeLimitation = function (index) {
        $scope.certificate.limitations.splice(index, 1);
    };
}]);