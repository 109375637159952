import { HttpParams } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, Subscription, map, switchMap } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { ApiV2Practitioners } from "../../../../../api/api-v2-practitioners";
import { RouteParams } from "../../../../ng1.routeParams";
import { FilteredDataModel } from "../../../../shared/models/filtered-data.model";
import { onCheckboxChange, toDateDto } from "../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { FirstNewbornCheckupModel } from "../../models/firstNewbornCheckup.model";
import { YesNoOptions } from "../pregnancy-dashboard.constants";
import { CheckupPlaces, Checkups } from "./first-newborn-checkup.constants";

@Component({
    selector: "app-first-newborn-checkup",
    templateUrl: "./first-newborn-checkup.component.html",
})
export class FirstNewbornCheckupComponent implements OnInit, OnDestroy {
    documentTag: DocumentTagEnum = DocumentTagEnum.e097_npa;
    public formGroup: FormGroup;
    prefillData: any;

    public readonly checkupPlaces = Object.values(CheckupPlaces);
    public readonly results = Object.values(Checkups);

    public newbornMonitoredFor;
    public pathology;
    public newbornExaminationPlan;
    public ultrasoundExaminations;
    public newbornAppointedRegime;
    public newbornAppointedDiet;
    public newbornAppointedTreatments;
    today = new Date();

    developmentDefectsOptions = YesNoOptions;

    suggestions$?: any;
    noResult = false;

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
        //console.log(Object.getOwnPropertyNames(Checkups));
    }

    getSpecialists() {
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.formGroup.get("specialistName").value);
        }).pipe(
            switchMap((query: string) => {
                let params = new HttpParams();
                params = params.append("q", String((query ? query : "") + "*"));
                ["LIC_specialistas"].map((r) => (params = params.append("role", r)));
                return this.apiV2Practitioners.find(params).pipe(
                    map((practitioniers: FilteredDataModel) => {
                        return practitioniers.items.map((p) => {
                            p.fullDisplay = p.givenName + " " + p.familyName;
                            return p;
                        });
                    })
                );
            })
        );
    }

    getSpecialistFormControls(): AbstractControl[] {
        return (<FormArray>this.formGroup.get("checkupInfo.specialist")).value;
    }

    async typeaheadOnSelectP(e: TypeaheadMatch): Promise<void> {
        if (e) {
            this.formGroup.controls["specialistValue"].setValue(e.item);
        } else {
            this.formGroup.controls["specialistValue"].setValue(null);
        }
    }

    onClickAddSpecialist() {
        const specArray = this.formGroup.get("checkupInfo.specialist") as FormArray;
        const isDuplicate = specArray.controls.some((control) => {
            return (
                this.formGroup.controls["specialistValue"].value &&
                control.value.fullDisplay === this.formGroup.controls["specialistValue"].value.fullDisplay
            );
        });
        if (!isDuplicate && this.formGroup.controls["specialistValue"].value) {
            specArray.push(new FormControl(this.formGroup.controls["specialistValue"].value));
            this.formGroup.controls["specialistValue"].setValue(null);
            this.formGroup.controls["specialistName"].setValue("");
        }
    }

    onBlurtypeahead(event) {
        if (!event || event.item.fullDisplay !== this.formGroup.controls["specialistName"].value) {
            this.formGroup.controls["specialistValue"].setValue(null);
            this.formGroup.controls["specialistName"].setValue("");
        }
    }

    onClickRemoveSpecialist(index) {
        (this.formGroup.get("checkupInfo.specialist") as FormArray).removeAt(index);
    }

    public vitaminK = [
        {
            name: "1 mg į/r",
            value: "1",
        },
        {
            name: "0,5 mg į/r",
            value: "0.5",
        },
        {
            name: "0,3 mg į/r",
            value: "0.3",
        },
    ];

    public currentDate = new Date();

    public onCheckboxChange = onCheckboxChange;

    public onCheckboxChangeVitaminK = (formGroup, e, fGroup, controlName, checkboxName) => {
        onCheckboxChange(formGroup, e, fGroup, controlName, checkboxName);
        //this.formGroup.get('appointments.newbornAppointedTreatments').value.includes('1') ?
        if (e.target.checked) {
            this.formGroup.get('appointments.vitaminK').setValidators(Validators.required)
        } else {
            this.formGroup.get('appointments.vitaminK').clearValidators()
            this.formGroup.get('appointments.vitaminK').reset()
        }
        this.formGroup.get('appointments.vitaminK').updateValueAndValidity({ onlySelf: true, emitEvent: false })
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private apiV2Practitioners: ApiV2Practitioners
    ) { }

    public get getResultsArray(): FormArray {
        return this.formGroup?.get("checkupResults.checkups") as FormArray;
    }

    ngOnInit(): void {
        this.formGroup = this.makeForm();

        this._apiV2PregnancyDashboard.getFirstNewbornCheckupClassifiers().then((val) => {
            this.newbornMonitoredFor = val.newbornMonitoredFor;
            this.pathology = val.pathology;
            this.newbornExaminationPlan = val.newbornExaminationPlan;
            this.ultrasoundExaminations = val.ultrasoundExaminations;
            this.newbornAppointedRegime = val.newbornAppointedRegime;
            this.newbornAppointedDiet = val.newbornAppointedDiet;
            this.newbornAppointedTreatments = val.newbornAppointedTreatments;
        });
        this.getSpecialists();
        this.patchCheckups();
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    private patchCheckups(): void {
        this.results.forEach((name) => {
            (this.formGroup?.get("checkupResults.checkups") as FormArray).push(
                this._fb.group({
                    name: new FormControl(name),
                    shortName: new FormControl(
                        Object.keys(Checkups)[Object.values(Checkups).indexOf(name as unknown as Checkups)]
                    ),
                    selection: new FormControl("1"),
                    comment: new FormControl(""),
                })
            );
        });
    }

    public clearForm(): void {
        this.ngOnInit();
    }

    public saveForm(): void {
        //Change 'id' with actual id from route params and add additional route navigating if necessary
        const payload: FirstNewbornCheckupModel = { ...this.formGroup.value, id: "12345679781" };
        this._apiV2PregnancyDashboard.saveFirstNewbornCheckup(payload);
    }

    public submitForm(): void {
        //Change 'id' with actual id from route params and add additional route navigating if necessary
        const payload: FirstNewbornCheckupModel = { ...this.formGroup.value, id: "12345679781" };
        this._apiV2PregnancyDashboard.submitFirstNewbornCheckup(payload);
    }

    public signingSubmitForm(): void {
        //Change 'id' with actual id from route params and add additional route navigating if necessary
        const payload: FirstNewbornCheckupModel = { ...this.formGroup.value, id: "12345679781" };
        this._apiV2PregnancyDashboard.signingSubmitFirstNewbornCheckup(payload);
    }

    public sealConfirmForm(): void {
        //Change 'id' with actual id from route params and add additional route navigating if necessary
        const payload: FirstNewbornCheckupModel = { ...this.formGroup.value, id: "12345679781" };
        this._apiV2PregnancyDashboard.sealConfirmFirstNewbornCheckup(payload);
    }

    public signForm(): void {
        //Change 'id' with actual id from route params and add additional route navigating if necessary
        const payload: FirstNewbornCheckupModel = { ...this.formGroup.value, id: "12345679781" };
        this._apiV2PregnancyDashboard.signFirstNewbornCheckup(payload);
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            specialistName: new FormControl(""),
            specialistValue: new FormControl(""),
            checkupInfo: this._fb.group({
                checkupDate: new FormControl(""),
                checkupTime: new FormControl(null),
                checkupPlace: new FormControl(""),
                specialist: this._fb.array([]),
            }),

            checkupResults: this._fb.group({
                checkups: this._fb.array([]),
                developmentDefects: new FormControl(false),
                developmentDefectsInfo: new FormControl(null),
                newbornMonitoredFor: this._fb.array([]),
                newbornExaminationPlan: this._fb.array([]),
                ultrasoundExaminations: this._fb.array([]),
                otherExaminationsInfo: new FormControl(null),
            }),

            appointments: this._fb.group({
                newbornAppointedRegime: new FormControl(null),
                newbornAppointedDiet: this._fb.array([]),
                newbornAppointedTreatments: this._fb.array([]),
                vitaminK: new FormControl(null),
                newbornAppointedTreatmentsOtherInfo: new FormControl(null),
            }),
        });
    }

    onSave() {
        console.log("Resultatai (formGroup): ", this.formGroup.value);
        console.log("DTO ---->", this.formToDto());
    }

    formToDto(): FirstNewbornCheckupModel {
        return {
            id: "",
            checkupInfo: {
                checkupDate: toDateDto(this.formGroup.get('checkupInfo.checkupDate').value),
                checkupTime: this.formGroup.get('checkupInfo.checkupTime').value,
                checkupPlace: this.formGroup.get('checkupInfo.checkupPlace').value,
                specialist: this.formGroup.get('checkupInfo.specialist').value,
            },
            checkupResults: {
                checkups: this.formGroup.get('checkupResults.checkups').value,
                developmentDefects: this.formGroup.get('checkupResults.developmentDefects').value,
                developmentDefectsInfo: this.formGroup.get('checkupResults.developmentDefectsInfo').value,
                newbornMonitoredFor: this.formGroup.get('checkupResults.newbornMonitoredFor').value,
                newbornExaminationPlan: this.formGroup.get('checkupResults.newbornExaminationPlan').value,
                ultrasoundExaminations: this.formGroup.get('checkupResults.ultrasoundExaminations').value,
                otherExaminationsInfo: this.formGroup.get('checkupResults.otherExaminationsInfo').value
            },
            appointments: {
                newbornAppointedRegime: this.formGroup.get('appointments.newbornAppointedRegime').value,
                newbornAppointedDiet: this.formGroup.get('appointments.newbornAppointedDiet').value,
                newbornAppointedTreatments: this.formGroup.get('appointments.newbornAppointedTreatments').value,
                vitaminK: this.formGroup.get('appointments.vitaminK').value,
                newbornAppointedTreatmentsOtherInfo: this.formGroup.get('appointments.newbornAppointedTreatmentsOtherInfo').value
            }
        };
    }
}
