<nhr-record-widget
    [closed]="false"
    [closable]="true"
    [title]="'doc.mentalHealthComponent.psychiatricRiskForm.title' | translate"
    class="h-auto"
>
    <div class="row mb-3 req" *ngIf="editable || formGroup.get('psychiatricRiskFactor')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.psychiatricRiskForm.risks' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <div class="input-group">
                <input
                    *ngIf="editable"
                    required
                    id="psychiatricRiskFactor"
                    [ngClass]="{'is-invalid': formGroup.get('psychiatricRiskFactor').touched && formGroup.get('psychiatricRiskFactor').invalid}"
                    [formControl]="formGroup.get('psychiatricRiskFactor')"
                    typeaheadOptionField="name"
                    [typeahead]="healthRisksSuggestions$"
                    [typeaheadAsync]="true"
                    placeholder="Įveskite"
                    class="form-control">
                <p *ngIf="!editable && formGroup.get('psychiatricRiskFactor')?.value"
                   class="form-control-plaintext">
                    {{ formGroup.get('psychiatricRiskFactor')?.value }}
                </p>
            </div>
        </div>
    </div>
    <div class="row mb-3 req" *ngIf="editable || formGroup.get('determinationDate')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.psychiatricRiskForm.determinationDate' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-date-picker
                *ngIf="editable"
                inputId="timeOfDischarge"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="currentTime"
                [fControl]="formGroup.get('determinationDate')"
            ></app-date-picker>
            <p *ngIf="!editable && formGroup.get('determinationDate')?.value"
               class="form-control-plaintext">
                {{ moment(formGroup.get('determinationDate')?.value).format('YYYY-MM-DD HH:mm') }}
            </p>
        </div>
    </div>
    <div class="row mb-3 req" *ngIf="editable || formGroup.get('patientConsent')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.psychiatricRiskForm.patientConsent' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-select
                *ngIf="editable"
                id="patientConsent"
                [fControl]="formGroup.get('patientConsent')"
                [values]="selectionValuesFromEntity(this.yesNoOptions)"
            ></app-select>
            <p *ngIf="!editable && formGroup.get('patientConsent')?.value"
               class="form-control-plaintext">
                {{ formGroup.get('patientConsent')?.value }}
            </p>
        </div>
    </div>
    <div class="row mb-3" *ngIf="editable || formGroup.get('nameOfMethodology')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.psychiatricRiskForm.nameOfMetodology' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-input
                *ngIf="editable"
                [fControl]="formGroup.get('nameOfMethodology')"
            ></app-input>
            <p *ngIf="!editable && formGroup.get('nameOfMethodology')?.value"
               class="form-control-plaintext">
                {{ formGroup.get('nameOfMethodology')?.value }}
            </p>
        </div>
    </div>
    <div class="row mb-3" *ngIf="editable || formGroup.get('assessmentOfRiskFactors')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.psychiatricRiskForm.riskFactorAssessment' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-input
                *ngIf="editable"
                [fControl]="formGroup.get('assessmentOfRiskFactors')"
            ></app-input>
            <p *ngIf="!editable && formGroup.get('assessmentOfRiskFactors')?.value"
               class="form-control-plaintext">
                {{ formGroup.get('assessmentOfRiskFactors')?.value }}
            </p>
        </div>
    </div>
    <div class="row mb-3" *ngIf="editable || formGroup.get('assessmentDescription')?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.psychiatricRiskForm.assessmentDescription' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-input
                *ngIf="editable"
                [fControl]="formGroup.get('assessmentDescription')"
            ></app-input>
            <p *ngIf="!editable && formGroup.get('assessmentDescription')?.value"
               class="form-control-plaintext">
                {{ formGroup.get('assessmentDescription')?.value }}
            </p>
        </div>
    </div>
</nhr-record-widget>