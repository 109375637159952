<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false" [mixed]="true" [title]="'Medicinos įstaiga į kurią vežamas pacientas'">
    <div class="widget-body p-0">
        <form [formGroup]="form">
                    <div *ngIf="!viewOnly" class="form-group p-2_5 row req">
                        <label for="templateTitle" class="col-md-3 col-form-label text-md-end">Medicinos įstaiga į kurią vežamas pacientas</label>
                        <div class="col-md-8 d-flex flex-row align-items-center">
                            <input formControlName="organization"
                            typeaheadOptionField="nameAndJarCode"
                            [typeahead]="suggestionsOrg$"
                            [typeaheadAsync]="true"
                            [typeaheadItemTemplate]="customItemTemplate"
                            (typeaheadOnSelect)="typeaheadOnSelect($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos pavadinimą"
                            [ngClass]="{ 'is-invalid': form.get('organization')?.invalid && !form.get('institution')?.value }"
                            class="form-control">
                        <ng-template #customItemTemplate let-model="item">
                            <span><strong>{{model.name}}</strong> - {{model.jarCode}}</span>
                        </ng-template>
                        </div>
                    </div>
                    <ol class="slats border-top border-secondary-subtle" *ngIf="form.controls['institution'].value">
                        <li>
                            <span class="d-flex offset-0 offset-lg-3 px-2_5">
                                <strong>{{ form.controls['institution'].value.name }}</strong>, JAR kodas {{form.controls['institution'].value.jarCode}},
                                SVEIDRA Nr. {{form.controls['institution'].value.sveidraId}} {{form.controls['institution'].value.registrationAddress.text}}
                            </span>
                        </li>
                    </ol>
        </form>
    </div>
</nhr-record-widget>

