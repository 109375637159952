/* global angular, app, _ */

ng1App.controller("doctor.user.PatientsViewCtrl", [
    "$scope",
    "$filter",
    "$routeParams",
    "PatientsApiFactory",
    "SearchController",
    "DpSessionContext",
    "MessageService",
    "PatientUpdateStatusHandler",
    function (
        $scope,
        $filter,
        $routeParams,
        PatientsApiFactory,
        SearchController,
        DpSessionContext,
        MessageService,
        PatientUpdateStatusHandler
    ) {
        $scope.$emit("setHelpPage", "perzireti-pacientu-sarasa");

        var _mapModelsToIds = function (params, name) {
            if (params[name]) {
                var ids = [];
                _.each(params[name], function (p) {
                    ids.push(p.id);
                });
                params[name] = ids;
            }
        };
        var loadCount = 0;

        $scope._filterDefs = [];

        var mytoday;
        var acRead = $filter("acRead");

        var searchFilter = { title: "search.results", query: {}, skipCount: true, count: 0 };
        if (acRead("AllPatientList")) {
            $scope._filterDefs.push(searchFilter); // Visi
        }
        if (acRead("OrganizationPatientList")) {
            $scope._filterDefs.push({ title: "dp.use.rec.instAll", query: { filter_type: "org" } }); // Įstaigos pacientai
        }
        if (acRead("OrganizationTodayPatientList")) {
            $scope._filterDefs.push({ title: "dp.use.rec.instTodayPatients", query: { filter_type: "orgtoday" } }); // Įstaigos šios dienos pacientai
        }
        if (acRead("MyTodayPatientList")) {
            mytoday = { title: "dp.use.rec.myTodayPatients", query: { filter_type: "mytoday" } };
            $scope._filterDefs.push(mytoday); // Mano šios dienos pacientai
        }
        if (acRead("MyPatientList")) {
            $scope._filterDefs.push({ title: "dp.use.rec.myPatients", query: { filter_type: "my" } }); // Mano pacientai
        }
        var keysToIgnore = ["count", "page", "_searchType", "checkActiveEncounter", "tryRegisterIfNotFound"];
        var onlyPageAndCount = function (query) {
            var result = true;
            angular.forEach(query, function (val, key) {
                if (
                    !_.contains(keysToIgnore, key) &&
                    (angular.isArray(val) || angular.isObject(val)
                        ? !_.isEmpty(val)
                        : val !== null && val !== undefined)
                ) {
                    result = false;
                }
            });
            return result;
        };

        $scope.searchCtrl = new SearchController({
            viewId: "dp_patient_list",
            dialogTpl: "/templates/portal/doctor/user/patients/search.html",
            count: 10,
            initForm: function () {
                return {
                    filter: $routeParams.f === "mytoday" && mytoday ? mytoday : $scope._filterDefs[0],
                    encounterTargetPractitioner: [],
                    encounterRegistrant: [],
                    carePractitioner: [],
                    careOrganization: [],
                };
            },
            searchApi: PatientsApiFactory.search,
            _handleSearch: function (query, onResult, onError, onFinaly) {
                if (onlyPageAndCount(query)) {
                    onResult({ items: [], total: 0 });
                    onFinaly();
                    return false;
                }
                return true;
            },
            _handleResult: function (data) {
                searchFilter.count = data.total;
                PatientUpdateStatusHandler.handleResponse(data);
                return true;
            },
            showPageCounter: true,
        });

        $scope.searchCtrl.handleResult = function (result) {
            loadCount++;
            if (loadCount === 1 && $routeParams.f === "mytoday" && result.total === 0) {
                $scope.searchCtrl._form.filter = $scope._filterDefs[0];
                $scope.searchCtrl.searchFilter();
                return;
            }
            SearchController.prototype.handleResult.call(this, result);
        };

        $scope.searchCtrl.formToQuery = function (form) {
            var query: any = { checkActiveEncounter: true };

            if (form._searchType === "advanced") {
                form.filter = searchFilter;
                angular.extend(query, form);
                query.filter = undefined;
                _mapModelsToIds(query, "carePractitioner");
                _mapModelsToIds(query, "careOrganization");
                _mapModelsToIds(query, "encounterTargetPractitioner");
                _mapModelsToIds(query, "encounterRegistrant");
                query.filter_type = undefined;
            } else if (form.q) {
                query.q = form.q;
                form.filter = searchFilter;
            } else if (form.filter) {
                angular.extend(query, form.filter.query);
            }
            query.tryRegisterIfNotFound = query.q ? true : false;
            return query;
        };

        $scope._changeFilter = function (f) {
            $scope.searchCtrl.clear();
            $scope.searchCtrl._form.filter = f;
            $scope.searchCtrl.searchFilter();
        };

        _.each($scope._filterDefs, function (filter) {
            if (!filter.skipCount) {
                PatientsApiFactory.count(filter.query, function (res) {
                    filter.count = res.count;
                });
            }
        });

        if ($routeParams.q) {
            $scope.searchCtrl.addQueryToForm($routeParams.q);
        }

        DpSessionContext.getSettings(function (data) {
            $scope.searchCtrl.setCount(data.count_per_page || 10);
            $scope.searchCtrl.searchLastOrAll();
        });

        $scope.updatePagination = function (pagination) {
            if (pagination?.page) {
                $scope.searchCtrl.setPage(pagination.page);
                $scope.searchCtrl.handleSearch(false);
            }
        };
    },
]);
