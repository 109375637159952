import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { map } from 'rxjs';
import { ApiV2Patients } from "src/app/shared/services/api-v2-patients";
import {
    DpAspnContextService
} from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { PatientModel } from "src/app/shared/models/patient.model";
import { ApiV2Documents } from "../../../../../../api/api-v2-documents";
import { ApiV2Practitioners } from "../../../../../../api/api-v2-practitioners";
import { ApiV2Session } from "../../../../../../api/api-v2-session";
import { RouteParams } from "../../../../../ng1.routeParams";
import { OrganizationModel } from "../../../../../shared/models/organization.model";
import { PractitionerModel } from "../../../../../shared/models/practitioner.model";
import { toDateDto } from "../../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../../shared/models/document-tag.enum";
import { NursingAndCarePlanModel } from "../../../model/nursingAndCarePlan";

@Component({
    selector: "nursing-and-care-plan",
    templateUrl: "./nursing-and-care-plan.component.html",
})
export class NursingAndCarePlan implements OnInit {
    documentTag: DocumentTagEnum = DocumentTagEnum.spp;
    public formGroup: FormGroup;
    patient: PatientModel;
    organization: OrganizationModel;
    practitioner: PractitionerModel;
    prefillData: any;

    private atLeastOneIntroduced: ValidatorFn = (control: AbstractControl) => {
        control.valueChanges.subscribe(data => {
            if (data.patient || data.relatives) {
                control.get('patient')?.clearValidators()
                control.get('relatives')?.clearValidators()
            } else {
                control.get('patient')?.setValidators(Validators.requiredTrue)
                control.get('relatives')?.setValidators(Validators.requiredTrue)
            }
            control.get('patient')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            control.get('relatives')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        })
        return null;
    }

    private checkboxTextFields: ValidatorFn = (control: AbstractControl) => {
        control.get('patient')?.valueChanges.subscribe(data => {
            data ? control.get('patientFalseReason')?.clearValidators() : control.get('patientFalseReason')?.setValidators(Validators.required)
            control.get('patientFalseReason')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        })
        control.get('relatives')?.valueChanges.subscribe(data => {
            data ? control.get('relativesFalseReason')?.clearValidators() : control.get('relativesFalseReason')?.setValidators(Validators.required)
            control.get('relativesFalseReason')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        })
        return null;
    }

    constructor(
        private readonly _fb: FormBuilder,
        private dpAspnContextService: DpAspnContextService,
        private params: RouteParams,
        private apiV2Patients: ApiV2Patients,
        private apiV2Practitioners: ApiV2Practitioners,
        private apiV2Documents: ApiV2Documents,
        private apiV2Session: ApiV2Session
    ) { }

    async ngOnInit(): Promise<void> {

        this.getPrefillData();

        this.formGroup = this.makeForm();

        // await firstValueFrom(this.apiV2Patients.getPatient(this.params.patientId)).then(val => {
        //     this.patient = val;
        //     this.formGroup.get('header.patient').setValue(val);
        // });

        // await firstValueFrom(this.apiV2Session.getPatient()).then(val => {
        //     this.patient = val;
        //     this.formGroup.get('header.patient').setValue(val);
        // });

        // await firstValueFrom(this.apiV2Session.getPractitioner()).then(val => {
        //     this.practitioner = val;
        //     this.formGroup.get('header.practitioner').setValue(val);
        // });

        // this.apiV2Documents.getPrefilledForm(this.documentType, this.documentType, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
        //     next: (response) => {
        //         this.formGroup.get('header.practitioner').setValue(response.metadata.practitioner);
        //     }
        // });



        this.addNursingSolution();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            header: new FormGroup({
                patient: new FormControl(""),
                createDate: new FormControl(""),
                practitioner: new FormControl({}),
            }),
            nursingSolutions: new FormArray([]),
            planIntroduction: new FormGroup({
                patient: new FormControl(false, Validators.requiredTrue),
                relatives: new FormControl(false, Validators.requiredTrue),
                patientFalseReason: new FormControl("", Validators.required),
                relativesFalseReason: new FormControl("", Validators.required),
            },
                { validators: [this.checkboxTextFields, this.atLeastOneIntroduced] }
            )
        });
    }

    get nursingSolutionControls() {
        return (<FormGroup>this.formGroup.get("nursingSolutions")).controls;
    }

    addNursingSolution() {
        const fg = new FormGroup({
            problem: new FormControl(""),
            justification: new FormControl(""),
            diagnosis: new FormControl(""),
            assessment: new FormControl(""),
            purpose: new FormControl(""),
            procedures: new FormControl(""),
            visits: new FormControl(""),
        });
        (<FormArray>this.formGroup.get("nursingSolutions")).push(fg);
    }

    removeNursingSolution(index: number) {
        (<FormArray>this.formGroup.get("nursingSolutions")).removeAt(index);
    }

    onSave() {
        console.log("Resultatai (formGroup): ", this.formGroup.value);
        console.log("DTO ---->", this.formToDto());
    }

    public clearForm(): void {
        this.ngOnInit();
    }

    formToDto(): NursingAndCarePlanModel {
        return {
            header: {
                patient: this.formGroup.get('header.patient').value,
                createDate: toDateDto(new Date()),
                practitioner: this.formGroup.get('header.practitioner').value
            },
            nursingSolutions: this.formGroup.get('nursingSolutions').value,
            planIntroduction: this.formGroup.get('planIntroduction').value,
        };
    }
}