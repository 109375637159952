ng1App.controller('doctor.patient.automation.QuickVaccinationCtrl', 
    ['$scope', '$location', '$routeParams', 'AutomationApiFactory', 'MessageService',
     function ($scope, $location, $routeParams, AutomationApiFactory, MessageService) {
    $scope.loading = true;
    
    AutomationApiFactory.autoSubmiE025ForCovid19({patientId: $routeParams.patientId, vaccine:$routeParams.vaccine, doseNo:$routeParams.doseNo}, 
        function(res) {
            $scope.loading = false;
            $scope.$apply();

            $location.path('/dp/patients/' + $routeParams.patientId + '/documents/new/e063').search({
                encounterId: res.encounterId, orderId: res.orderId, parentDocumentId: res.compositionId, automated: 'true'
            });
        },
        function (err) {
            $scope.loading = false;
            $scope.$apply();
            _.each(err.data.globalErrors, function (i) {
                MessageService.error(i.msg);
            });
    });
}]);