<div class="cancel-reason-modal">
    <h3>{{ 'mdl.add.rea.doc' | translate }}</h3>
    <form [formGroup]="cancelReasonForm" (ngSubmit)="submitReason()">
      <div class="form-group">
        <label for="reason">{{ 'dp.pat.enc.can.reason' | translate }} <span class="text-danger">*</span></label>
        <textarea
          formControlName="reason"
          id="reason"
          rows="3"
          class="form-control"
          placeholder="Įveskite atšaukimo priežastį čia..."
          required
        ></textarea>
      </div>
      <div class="modal-actions">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Grįžti</button>
        <button type="submit" class="btn btn-primary">Testi</button>
      </div>
    </form>
  </div>