import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { generateSelectionValuesFromEntity } from '../../../e096/e096-utils/utils';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import {DocumentTagEnum} from "../../../../../../shared/models/document-tag.enum";

@Component({
    selector: 'app-e030-recomendations',
    templateUrl: './e030-recomendations.component.html',
})
export class E030RecomendationsComponent implements OnInit {
    protected readonly DocumentTagEnum = DocumentTagEnum;
    @Input() editable: boolean = true;
    @Input() fromDoc: DocumentTagEnum | undefined;
    formGroup: FormGroup;
    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            treatmentApplied: this.fb.control(null, [Validators.required]),
            showThePatient: this.fb.control(null, [Validators.required]),
            ambulatoryMonitoring: this.fb.control(false),
            stationaryObservation: this.fb.control(false),
            forcedHospitalization: this.fb.control(false),
        });
    }
}
