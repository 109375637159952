<div class="d-grid grid-uterine-chart" [style.height.px]="700">
    <div class="fs-3 fw-bolder text-center h-100 ps-3 text-vertical" style="width: min-content; max-width: 50px">
        {{'doc.pregnancyDashboard.uterineDiagram.yAxis' | translate}}
    </div>
    <div>
        <canvas
                baseChart
                [type]="chartType"
                [data]="chartData"
                [options]="chartOptions"
                class="custom-chart-border"
        ></canvas>
    </div>
</div>
<div class="fs-3 fw-bolder ms-5 ps-3">{{'doc.pregnancyDashboard.uterineDiagram.xAxis' | translate}}</div>
