<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e097Gd.generalInspectionForm.title' | translate"
>
    <div class="card-body">
        <div class="row req">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e097Gd.generalInspectionForm.performanceDate' | translate}}:"
                labelClass="form-label flex-grow-1 col-form-label mt-0"
                inputId="performanceDate"
                class="form-group col-12 col-lg-3"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD"
                [maxDate]="currentTime"
                [showErrors]="true"
                [fControl]="formGroup.get('performanceDate')"
            ></app-date-picker>
            <fieldset class="col-12 col-lg-6">
                <legend class="float-none form-label fw-bolder col-form-label norq mt-lg-0">
                    {{'doc.pregnancyDashboard.e097Gd.generalInspectionForm.inspectionWasCarriedOut' | translate}}:
                </legend>
                <div class="col-12">
                    <app-find-specialists
                        [editable]="editable"
                        [disable]="!controlValue('inspectionWasCarriedOut')"
                        [formArray]="formGroup?.get('inspectionWasCarriedOut')"
                    ></app-find-specialists>
                </div>
            </fieldset>
        </div>
    </div>
</nhr-record-widget>