import {Component, Input, OnInit} from "@angular/core";
import {ApiV2Documents} from '../../../../api/api-v2-documents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {RouteParams} from '../../../ng1.routeParams';
import {multiCheckboxIsChecked, updateCheckboxControl} from '../../../doctor/ngn/components/e096/e096-utils/utils';
import {PortalTypeEnum} from "../../models/portalType.enum";
import {DpSessionContext} from "../../../doctor/services/dp-session-context.service";
import { CancelReasonModalComponent } from "./cancel-reason-modal/cancel-reason-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { take } from "rxjs";
import { MessageService } from "../../services/utils/message.service";

@Component({
    selector: 'app-visit-documents',
    templateUrl: './visit-documents.component.html',
})
export class VisitDocumentsComponent implements OnInit {

    showWidget: boolean = false;
    documentsList: any[];
    actionInProgress = 'actionInProgress';
    documentsChecked = 'documentsChecked';
    formGroup: FormGroup = this.fb.group({
        [this.actionInProgress]: this.fb.control(null),
        [this.documentsChecked]: this.fb.control([])
    })
    actions = [
        {
            name: 'Pateikti paketiniam pasirašymui',
            method: () => this.batchSignDocuments(),
        },
        {
            name: 'Atšaukti',
            method: () => this.cancelDocuments(),
        },
        {
            name: 'Pateikti paketiniam atšaukimui',
            method: () => this.batchCancelDocuments(),
        }
    ];
    viewMode = true;
    encounterData: any;
    patientId: string;
    documentId: string;
    @Input() encounterId: string;
    @Input() portalType: PortalTypeEnum = PortalTypeEnum.DP;

    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;

    constructor(
        private apiV2Documents: ApiV2Documents,
        private dpController: DpSessionContext,
        private router: Router,
        private params: RouteParams,
        private fb: FormBuilder,
        private modalService: BsModalService,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
        if (!this.encounterId) {
            this.dpController.encounterData$.subscribe(res => {
                if (res) {
                    this.encounterData = res;
                    this.encounterId = res.id;
                }
                this.generateDocumentsList();
            })
        } else {
            this.generateDocumentsList();
        }
        this.patientId = this.params.patientId;
        this.documentId = this.params.documentId;
    }

    private generateDocumentsList(): void {
        this.showWidget = false;
        this.encounterId = this.params.encounterId ? this.params.encounterId : this.encounterId;
        if (!this.encounterId) {
            return;
        }

        this.apiV2Documents.searchForList({
            withDrafts: true,
            encounterId: this.encounterId
        }).subscribe(res => {
            this.documentsList = res;
            this.showWidget = true;
        })
    }

    navigateToDoc(id: string | undefined): void {
        if (!this.viewMode) return;
        const url = `${this.portalType}/patients/${this.patientId}/documents/${id}`;
        if (!url) return;
        this.router.navigate([url]);
    }

    markAll(): void {
        this.getUnchecked().forEach(item => {
            this.updateControl(item.id, this.documentsChecked);
        });
    }

    getUnchecked(): any[] {
        return this.documentsList.filter(d => {
            return !this.formGroup.get(this.documentsChecked).value?.includes(d.id);
        });
    }

    confirmAction(action: () => void) {
        console.log("Action triggered");
        action();
    }

    batchSignDocuments(): void {
        const url = `${this.portalType}/patients/${this.patientId}`;
        const documentIds = this.formGroup.get(this.documentsChecked).value;
        this.router.navigate([[url] + '/documents/sign'], { queryParams: { documentId: documentIds, multiSign: true } });
    }

    cancelDocuments(): void {
        const ref = this.modalService.show(CancelReasonModalComponent, {
          animated: true,
          class: 'modal-xl'
        });
        ref.content!.cancelReasonSubmitted.pipe(take(1)).subscribe((reason: string) => {
          this.performCancellation(reason);
        });
      }

      private performCancellation(cancelReason: string) {
        if (cancelReason) {
          const documentIds = this.formGroup.get(this.documentsChecked).value;
          this.apiV2Documents.cancelDocument(documentIds[0], cancelReason).subscribe({
            next: (response) => {
              this.redirectToSignPage(documentIds[0]);
              console.log('Document cancelled successfully', response);
            },
            error: (error) => {
              console.error('Failed to cancel document', error);
            }
          });
        } else {
          console.error('Cancellation reason is required');
        }
      }

    redirectToSignPage = function (compositionId) {
        const url = `${this.portalType}/patients/${this.patientId}`;
        this.router.navigate([[url] + '/documents/' + compositionId + '/sign']);
    };

    batchCancelDocuments(): void {
        // Example of batch cancelling documents
        console.log('Batch cancelling documents');
        // Implement your batch cancelling logic here
    }

    // TODO: log method for result of selection
    // log(): void {
    //     console.log(this.formGroup.value);
    // }

    isItemMarkable(item: any): boolean {
        return item.status !== 'Patvirtintas' && item.status !== 'Ruošiamas';
    }
}
