ng1App.factory('OrdersApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    
    var URL_BASE = apiUrl + '/orders';
    return $resource('', {}, {
        getUsedInCompositionsCount: {
            method: 'GET',
            url: URL_BASE + '/:orderId/active?docType=:docType'
        },
        getForE014: {
            method: 'GET',
            url: URL_BASE + '/forE014',
            isArray: true
        },
        getForE027A: {
            method: 'GET',
            url: URL_BASE + '/forE027A',
            isArray: true
        },
        getForE200A: {
            method: 'GET',
            url: URL_BASE + '/forE200A',
            isArray: true
        },
        getForE200: {
            method: 'GET',
            url: URL_BASE + '/forE200',
            isArray: true
        },
        getForE200FromE027: {
            method: 'GET',
            url: URL_BASE + '/forE200FromE027',
            isArray: true
        },
        getForE027: {
            method: 'GET',
            url: URL_BASE + '/forE027',
            isArray: true
        },
        getForE014A: {
            method: 'GET',
            url: URL_BASE + '/forE014A',
            isArray: true
        },
        getForE063: {
            method: 'GET',
            url: URL_BASE + '/forE063',
            isArray: true
        }

    });
}]);