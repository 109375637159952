import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { EntityModel } from "src/app/shared/models/entity.model";
import { AspnOrderDto } from "../model/aspnOrder.model";

@Injectable({
    providedIn: "root",
})
export class DpAspnOrderService extends ConfigService {
    path = this.apiUrl + "/aspn";
    aspnOrder = this.path + "/aspnOrder";
    aspnVisit = this.path + "/aspnVisit";

    createOrder(order: AspnOrderDto) {
        return firstValueFrom(this.http.post<any>(this.aspnOrder + "/", order));
    }

    updateOrder(id: string, order: AspnOrderDto) {
        return firstValueFrom(this.http.put<any>(this.aspnOrder + "/" + id, order));
    }

    getAspnOrderClassifiers() {
        return firstValueFrom(this.http.get<AspnOrderClassifiers>(this.aspnOrder + "/classifiers"));
    }

    getAspnOrderById(id: string) {
        return firstValueFrom(this.http.get<AspnOrderDto>(this.aspnOrder + "/" + id));
    }

    getAspnOrderF027Order(f027OrderId: string) {
        return firstValueFrom(this.http.get<AspnF027Order>(this.aspnOrder + "/orderF027/" + f027OrderId));
    }

    getAspnPractitioniersByOrganizationId(organizationId: string) {
        return firstValueFrom(this.http.get<any>(this.aspnOrder + `/practitioniers/${organizationId}`));
    }
}

export interface AspnOrderClassifier extends EntityModel {
    children?: AspnOrderClassifier[];
}

export interface AspnOrderClassifiers {
    procedureState?: AspnOrderClassifier[];
    appointmentState?: AspnOrderClassifier[];
    proceduresGroup?: AspnOrderClassifier[];
    placeInBodyProcedure?: AspnOrderClassifier[];
    periodicity?: AspnOrderClassifier[];
}
export interface AspnVisitClassifiers {
    periodicity?: AspnOrderClassifier[];
    proceduresGroup?: AspnOrderClassifier[];
    placeInBodyProcedure?: AspnOrderClassifier[];
    procedureState?: AspnOrderClassifier[];
}

export interface AspnF027Order {
    orderPractitionierFullName: string;
    orderOrganizationID: string;
    orderOrganizationName: string;
    aspnNeedValue: string;
    nursingServiceType: string;
    otherInfo: string;
}
