angular.module('medvais.documents').directive('medvaisDocMainInfo', function () {

    return {
        restrict: 'E',
        scope: true,
        transclude: true,
        replace: false,
        templateUrl: 'modules/medvais/documents/components/doc-main-info.html',
        link: function (scope, element, attrs) {
            
        }
    };

});