ng1App.controller('UnisignCallbackCtrl', 
    ['$scope', '$window', '$routeParams', 'DocumentsApiFactory', 'PersonRepresentationsApiFactory', 'CertificatesApiFactory',
     function ($scope, $window, $routeParams, DocumentsApiFactory, PersonRepresentationsApiFactory, CertificatesApiFactory) {
    var transaction = $window.sessionStorage.getItem('sign_transaction');
    $window.sessionStorage.removeItem('sign_transaction');

    if ($routeParams.changeId) {
        PersonRepresentationsApiFactory.signResult({changeId: $routeParams.changeId}, function (result) {
            // For now only patient portal has representation signing
            if ($routeParams.auth) {
                $window.parent.location = '/pp/authorizations?signStatus=' + result.status;
            } else {
                $window.parent.location = '/pp/representatives?signStatus=' + result.status;
            }
        });
    } else if ($routeParams.patientId || $routeParams.multiSign) {
        DocumentsApiFactory.confirmSigning({transaction: transaction}, {}, function (resp) {
            if ($routeParams.multiSign) {
                if ($routeParams.patientId) {
                    $window.parent.location = '/dp/patients/' + $routeParams.patientId + '/unfinished/records?sgnst=' + (resp.res === false ? 'fail' : resp.status);
                } else {
                    $window.parent.location = '/dp/unfinished/records?sgnst=' + (resp.res === false ? 'fail' : resp.status);
                }
            } else {
                if ($routeParams.certificate) {
                    $window.parent.location = '/dp/patients/' + $routeParams.patientId + '/certificates/' + $routeParams.documentId + '?sgnst=' + (resp.res === false ? 'fail' : resp.status);
                } else {
                    $window.parent.location = '/dp/patients/' + $routeParams.patientId + '/documents/' + $routeParams.documentId + '?sgnst=' + (resp.res === false ? 'fail' : resp.status);
                }
            }
        });
    } else {
        CertificatesApiFactory.confirmSigning({id: transaction}, function (resp) {
            var url = '/pp/certificates/' + $routeParams.documentId + "?sgnst=" + (resp.res === false ? 'fail' : resp.status);
            if (resp.msgid) {
                url += "&msgid=" + resp.msgid;
            }
            $window.parent.location = url;
        });


    }
}]);
