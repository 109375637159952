import {TranslateService} from "@ngx-translate/core";
import {mainEnvironment} from "../environments/environment";

export abstract class RootModule {
    constructor(private translate: TranslateService) {
        translate.setDefaultLang(document.documentElement.lang)
    }

    changeLanguage(lang: string) {
        document.documentElement.lang = lang || mainEnvironment.defaultLanguage
        this.translate.use(lang); // Change the active language
    }

}