import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { getTemplateComponent } from "../../utils/hypridHelper";
import { ErxDoctorLibrary } from "../erxDoctor/erxDoctor.module";
import { EmptyComponent } from "../shared/components/EmptyComponent";
import { DispenseHistoryPageComponent } from "../shared/components/erx/dispenseHistoryPage/dispense-history-page.component";
import { InrListPageComponent } from "../shared/components/inr-list-page/inr-list-page.component";
import { SharedLibrary } from "../shared/shared.module";
import { DpDocRef110GMPComponent } from "./components/110-GMP/create/doc-ref-110-GMP-form.component";
import { DpDocRef110GMPEditComponent } from "./components/110-GMP/edit/doc-ref-110-GMP-form-edit.component";
import { DpDocRefE110ListComponent } from "./components/110-GMP/list/dp-doc-ref-110-GMP-form-list.component";
import { DpDocRef110GMPViewComponent } from "./components/110-GMP/view/dp-doc-ref-110-GMP-form-view.component";
import { AllRecordsListComponent } from "./components/allRecords/all-records-list.component";
import { AssessmentOfHealthFactorsPageComponent } from "./components/assessment-of-health-factors/assessment-of-health-factors-page/assessment-of-health-factors-page.component";
import { DocRefAttachedUnsortedComponent } from "./components/doc-ref-attached-unsorted/create/doc-ref-attached-unsorted.component";
import { DocRefAttachedUnsortedEditComponent } from "./components/doc-ref-attached-unsorted/edit/doc-ref-attached-unsorted-edit.component";
import { DpDocRefAttachedUnsortedListComponent } from "./components/doc-ref-attached-unsorted/list/dp-doc-ref-attached-unsorted-list.component";
import { DpDocAttachedUnsortedViewComponent } from "./components/doc-ref-attached-unsorted/view/dp-doc-ref-attached-unsorted-view.component";
import { DocRefConsentCreateComponent } from "./components/docRefConsent/create/doc-ref-consent-create.component";
import { DpDocRefConsentListComponent } from "./components/docRefConsent/list/dp-doc-ref-consent-list.component";
import { DpDocRefConsentViewComponent } from "./components/docRefConsent/view/dp-doc-ref-consent-view.component";
import { DpDocRefConsentTemplateListComponent } from "./components/docRefConsentTemplate/list/dp-doc-ref-consent-template-list.component";
import { DpDocRefConsentTemplateViewComponent } from "./components/docRefConsentTemplate/view/dp-doc-ref-consent-template-view.component";
import { DpDocRefF0255Component } from "./components/f025/create/doc-ref-f025-5-form.component";
import { DpDocRefF0255EditComponent } from "./components/f025/edit/doc-ref-f025-5-form-edit.component";
import { DpDocRefF025ListComponent } from "./components/f025/list/dp-doc-ref-f025-list.component";
import { DpDocRefF025ViewComponent } from "./components/f025/view/dp-doc-ref-f025-form-view.component";
import { DpDocRef058Component } from "./components/f058/create/doc-ref-f058-form.component";
import { DpDocRef058EditComponent } from "./components/f058/edit/doc-ref-f058-form-edit.component";
import { DpDocRef058ViewComponent } from "./components/f058/view/dp-doc-ref-f058-form-view.component";
import { DpDocRefF090Component } from "./components/f090/create/doc-ref-f090-form.component";
import { DpDocRefF090EditComponent } from "./components/f090/edit/doc-ref-f090-form-edit.component";
import { DpDocRefF090ListComponent } from "./components/f090/list/dp-doc-ref-f090-form-list.component";
import { DpDocRef090ViewComponent } from "./components/f090/view/dp-doc-ref-f090-form-view.component";
import { InrPageComponent } from "./components/inr-page/inr-page.component";
import { DpPatientCalendarComponent } from "./components/patient-calendar/dp-patient-calendar.component";
import { ThirdPartyRecordsListComponent } from "./components/thirdPartyRecords/third-party-records-list.component";
import {ErecHistoryPageComponent} from "../shared/components/erx/erecHistoryPage/erec-history-page.component";

// export const doctorRoutes: Routes = [
//   // this should run with doctor content
//   {
//     path: 'dp/patients',
//     pathMatch: 'full',
//     component: DpDoctorContextMainComponent,
//     children: [
//       // make sure all other goes to Ng1 implementation in the end
//       { path: '**', component: DpNg1MainComponent },
//     ],
//   },
//   {
//     // routes/views within patient context
//     path: 'dp/patients/:id',
//     component: DpPatientContextMainComponent,
//     children: [
//       ...NGNRoutes,
//       ...AspnPatientRoutes,
//       { path: 'doc-unsorted', component: DocRefAttachedUnsortedComponent, pathMatch: 'full' },
//       { path: 'doc-unsorted/list', component: DpDocRefAttachedUnsortedListComponent, pathMatch: 'full' },
//       { path: 'doc-unsorted/view/:viewId', component: DpDocAttachedUnsortedViewComponent, pathMatch: 'full' },
//       { path: 'doc-unsorted/edit/:viewId', component: DocRefAttachedUnsortedEditComponent, pathMatch: 'full' },
//       // make sure all other goes to Ng1 implementation in the end
//       { path: '**', component: DpNg1MainComponent },
//     ],
//   },
//   {
//     // routes/views within doctor context (when encounter for patient is not yet opened)
//     path: 'dp',
//     component: DpDoctorContextMainComponent,
//     children: [
//       { path: 'doc-ref-consent/list', component: DpDocRefConsentListComponent, pathMatch: 'full' },
//       // TODO-design: for testing only, replace routes with renewed component with menu
//       { path: 'doc-ref-consent/two-cols', component: DpLayoutTwoColsComponent, pathMatch: 'full' },
//       // TODO-design: for testing only, replace routes with renewed component with menu
//       { path: 'doc-ref-consent/three-cols', component: DpLayoutThreeColsComponent, pathMatch: 'full' },
//       { path: 'doc-ref-consent/create', component: DocRefConsentCreateComponent, pathMatch: 'full' },
//       { path: 'doc-ref-consent/view/:id', component: DpDocRefConsentViewComponent, pathMatch: 'full' },
//       { path: 'doc-ref-consent-template/list', component: DpDocRefConsentTemplateListComponent, pathMatch: 'full' },
//       {
//         path: 'doc-ref-consent-template/view/:id',
//         component: DpDocRefConsentTemplateViewComponent,
//         pathMatch: 'full',
//       },
//       // make sure all other goes to Ng1 implementation in the end
//       { path: '**', component: DpNg1MainComponent },
//     ],
//   },
//
//   { path: '404', component: PageNotFoundComponent },
//   { path: '**', component: DpNg1MainComponent },
// ];

const dummyRoutes: Routes = [{ path: "**", component: EmptyComponent }];

@NgModule({
    imports: [
        SharedLibrary,
        ErxDoctorLibrary,
        RouterModule.forRoot(dummyRoutes, {
            // enableTracing: isDevMode() && process.env['ESPBI_debugRoutes'] === 'true',
            useHash: true,
            initialNavigation: "enabledNonBlocking",
            onSameUrlNavigation: "reload",
            //canceledNavigationResolution: "computed"
            // preloadingStrategy: PreloadAllModules
        }),
        UpgradeModule,
    ],
    exports: [RouterModule],
    providers: [],
})
export class DoctorRoutes {
    constructor() {}
}

ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding DoctorModule routes");

        $routeSegmentProvider
            .when("/dp/patients/:patientId/doc-unsorted/new", "DP.DP_PATIENT2LREVNEW.DOC_UNSORTED")
            .when("/dp/patients/:patientId/doc-unsorted/list", "DP.DP_PATIENT3L.DOC_UNSORTED_LIST")
            .when("/dp/patients/:patientId/doc-unsorted/view/:viewId", "DP.DP_PATIENT3L.DOC_UNSORTED_VIEW")
            .when("/dp/patients/:patientId/doc-unsorted/view/:viewId/:document", "DP.DP.DP_PATIENT3L.DOC_UNSORTED_VIEW")
            .when("/dp/patients/:patientId/doc-unsorted/edit/:viewId", "DP.DP_PATIENT2LREVNEW.DOC_UNSORTED_EDIT")

            .when("/dp/patients/:patientId/doc-ref-consent/list", "DP.DP_PATIENT3L.CONSENT_LIST")
            .when("/dp/patients/:patientId/doc-ref-consent/create", "DP.DP_PATIENT2LREVNEW.CONSENT_NEW")
            .when("/dp/patients/:patientId/doc-ref-consent/view/:id", "DP.DP_PATIENT3L.CONSENT_VIEW")
            .when("/dp/patients/:patientId/doc-ref-consent-template/list", "DP.DP_PATIENT3L.CONSENT_TEMPLATE_LIST")
            .when("/dp/patients/:patientId/doc-ref-consent-template/view/:id", "DP.DP_PATIENT3L.CONSENT_TEMPLATE_VIEW")

            .when("/dp/doc-ref-consent/list", "DP.DP_USER2L.CONSENT_LIST")
            .when("/dp/doc-ref-consent/create", "DP.DP_USER2L.CONSENT_NEW")

            .when("/dp/patients/:patientId/f058/new", "DP.DP_PATIENT2LREVNEW.F058")
            .when("/dp/patients/:patientId/f058/edit/:viewId", "DP.DP_PATIENT2LREVNEW.F058_EDIT")
            .when("/dp/patients/:patientId/f058/view/:viewId", "DP.DP_PATIENT3L.F058_VIEW")

            .when("/dp/patients/:patientId/f090/new", "DP.DP_PATIENT2LREVNEW.090")

            .when("/dp/patients/:patientId/f090/list", "DP.DP_PATIENT3L.090_LIST")
            .when("/dp/patients/:patientId/f090/edit/:viewId", "DP.DP_PATIENT2LREVNEW.090_EDIT")
            .when("/dp/patients/:patientId/f090/view/:viewId", "DP.DP_PATIENT3L.090_VIEW")
            .when("/dp/patients/:patientId/f025/new", "DP.DP_PATIENT2LREVNEW.F025")
            .when("/dp/patients/:patientId/f025/list", "DP.DP_PATIENT3L.F025_LIST")
            .when("/dp/patients/:patientId/f025/edit/:viewI", "DP.DP_PATIENT2LREVNEW.F025_EDIT")
            .when("/dp/patients/:patientId/f025/view/:viewI", "DP.DP_PATIENT3L.F025_VIEW")

            .when("/dp/patients/:patientId/e110/new", "DP.DP_PATIENT2LREVNEW.110")
            .when("/dp/patients/:patientId/e110/edit/:viewI", "DP.DP_PATIENT2LREVNEW.110_EDIT")
            .when("/dp/patients/:patientId/e110/view/:viewId", "DP.DP_PATIENT3L.110_VIEW")
            .when("/dp/patients/:patientId/e110/list", "DP.DP_PATIENT3L.110_LIST")
            .when("/dp/patients/:patientId/risks", "DP.DP_PATIENT3L.HEALTH_FACTORS")

            .when("/dp/patients/:patientId/inr", "DP.DP_PATIENT3L.INR_LIST")
            .when("/dp/patients/:patientId/inr/new", "DP.DP_PATIENT2LREVNEW.INR_NEW")
            .when("/dp/patients/:patientId/inr/view/:viewId", "DP.DP_PATIENT3L.INR_VIEW")

            .when("/dp/patients/:patientId/erx/dispenses/list", "DP.DP_PATIENT2L.ERX_DISPENSES_LIST")
            .when("/dp/patients/:patientId/erx", "DP.DP_PATIENT2L.ERX_PRESCRIPTIONS_LIST")
            .when("/dp/patients/:patientId/all-records", "DP.DP_PATIENT3L.ALL_RECORDS_LIST")
            .when("/dp/patients/:patientId/third-party-records", "DP.DP_PATIENT3L.THIRD_PARTY_RECORDS_LIST")

            .when("/dp/patients/:patientId/events", "DP.DP_PATIENT2L.PATIENT_CALENDAR");

        // VIEW layout
        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2L")
            .segment("ERX_DISPENSES_LIST", { template: getTemplateComponent(DispenseHistoryPageComponent) })
            .segment("ERX_PRESCRIPTIONS_LIST", {template: getTemplateComponent(ErecHistoryPageComponent)})
            .segment("PATIENT_CALENDAR", { template: getTemplateComponent(DpPatientCalendarComponent) });
        // .within("DP_PATIENT3L")
        // .segment("DOC_UNSORTED_LIST", {template: getTemplateComponent(DpDocRefAttachedUnsortedListComponent)})
        // //.segment("DOC_UNSORTED_VIEW", {template: getTemplateComponent(DpDocAttachedUnsortedViewComponent)})
        // .segment("CONSENT_LIST", {template: getTemplateComponent(DpDocRefConsentListComponent)})
        // //.segment("CONSENT_VIEW", {template: getTemplateComponent(DpDocRefConsentViewComponent)})
        // .segment("CONSENT_TEMPLATE_LIST", {template: getTemplateComponent(DpDocRefConsentTemplateListComponent)})
        // //.segment("CONSENT_TEMPLATE_VIEW", {template: getTemplateComponent(DpDocRefConsentTemplateViewComponent)})
        // //.segment("F058_VIEW", {template: getTemplateComponent(DpDocRef058ViewComponent)})
        // // .segment("090_LIST", {template: getTemplateComponent(DpDocRefF090ListComponent)})
        // // .segment("090_VIEW", {template: getTemplateComponent(DpDocRef090ViewComponent)})
        // //.segment("F025_VIEW", {template: getTemplateComponent(DpDocRefF025ViewComponent)})
        // //.segment("110_VIEW", {template: getTemplateComponent(DpDocRef110GMPViewComponent)})
        // .segment("110_LIST", {template: getTemplateComponent(DpDocRefE110ListComponent)})
        // //.segment("INR_VIEW", {template: getTemplateComponent(InrPageComponent)})
        // .segment("INR_LIST", {template: getTemplateComponent(InrListPageComponent)});

        $routeSegmentProvider
            .within("DP")
            .within("DP_USER2L")
            .segment("CONSENT_LIST", { template: getTemplateComponent(DpDocRefConsentListComponent) })
            .segment("CONSENT_NEW", { template: getTemplateComponent(DocRefConsentCreateComponent) });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2LREVNEW")
            .segment("090", { template: getTemplateComponent(DpDocRefF090Component) })
            .segment("090_EDIT", { template: getTemplateComponent(DpDocRefF090EditComponent) })
            .segment("DOC_UNSORTED", { template: getTemplateComponent(DocRefAttachedUnsortedComponent) })
            .segment("DOC_UNSORTED_EDIT", { template: getTemplateComponent(DocRefAttachedUnsortedEditComponent) })
            .segment("CONSENT_NEW", { template: getTemplateComponent(DocRefConsentCreateComponent) })
            .segment("F058", { template: getTemplateComponent(DpDocRef058Component) })
            .segment("F058_EDIT", { template: getTemplateComponent(DpDocRef058EditComponent) })
            .segment("F025", { template: getTemplateComponent(DpDocRefF0255Component) })
            .segment("F025_EDIT", { template: getTemplateComponent(DpDocRefF0255EditComponent) })
            .segment("110", { template: getTemplateComponent(DpDocRef110GMPComponent) })
            .segment("110_EDIT", { template: getTemplateComponent(DpDocRef110GMPEditComponent) })
            .segment("INR_NEW", { template: getTemplateComponent(InrPageComponent) });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT3L")
            .segment("090_VIEW", { template: getTemplateComponent(DpDocRef090ViewComponent) })
            .segment("DOC_UNSORTED_VIEW", { template: getTemplateComponent(DpDocAttachedUnsortedViewComponent) })
            .segment("CONSENT_VIEW", { template: getTemplateComponent(DpDocRefConsentViewComponent) })
            .segment("CONSENT_TEMPLATE_VIEW", { template: getTemplateComponent(DpDocRefConsentTemplateViewComponent) })
            .segment("F058_VIEW", { template: getTemplateComponent(DpDocRef058ViewComponent) })
            .segment("F025_VIEW", { template: getTemplateComponent(DpDocRefF025ViewComponent) })
            .segment("110_VIEW", { template: getTemplateComponent(DpDocRef110GMPViewComponent) })
            .segment("INR_VIEW", { template: getTemplateComponent(InrPageComponent) })
            .segment("F025_LIST", { template: getTemplateComponent(DpDocRefF025ListComponent) })
            .segment("090_LIST", { template: getTemplateComponent(DpDocRefF090ListComponent) })
            .segment("DOC_UNSORTED_LIST", { template: getTemplateComponent(DpDocRefAttachedUnsortedListComponent) })
            .segment("CONSENT_LIST", { template: getTemplateComponent(DpDocRefConsentListComponent) })
            .segment("CONSENT_TEMPLATE_LIST", { template: getTemplateComponent(DpDocRefConsentTemplateListComponent) })
            .segment("110_LIST", { template: getTemplateComponent(DpDocRefE110ListComponent) })
            .segment("INR_LIST", { template: getTemplateComponent(InrListPageComponent) })
            .segment("ALL_RECORDS_LIST", { template: getTemplateComponent(AllRecordsListComponent) })
            .segment("HEALTH_FACTORS", { template: getTemplateComponent(AssessmentOfHealthFactorsPageComponent) })
            .segment("THIRD_PARTY_RECORDS_LIST", { template: getTemplateComponent(ThirdPartyRecordsListComponent) });
    },
]);
