import { Component, EventEmitter, Input, Output } from "@angular/core";
import {Event} from "@angular/router";

@Component({
    selector: "app-modification-actions",
    templateUrl: "./modification-actions.component.html",
})
export class ModificationActionsComponent {
    @Input() signButton: boolean;
    @Input() sealConfirmButton: boolean;
    @Input() signingSubmitButton: boolean;
    @Input() submitButton: boolean;
    @Input() saveButton: boolean;
    @Input() clearButton: boolean;

    @Input() signButtonDisabled: boolean;
    @Input() sealConfirmButtonDisabled: boolean;
    @Input() signingSubmitButtonDisabled: boolean;
    @Input() submitButtonDisabled: boolean;
    @Input() saveButtonDisabled: boolean;
    @Input() clearButtonDisabled: boolean;

    @Output() public sign: EventEmitter<Event> = new EventEmitter();
    @Output() public sealConfirm: EventEmitter<Event> = new EventEmitter();
    @Output() public signingSubmit: EventEmitter<Event> = new EventEmitter();
    @Output() public submit: EventEmitter<Event> = new EventEmitter();
    @Output() public save: EventEmitter<Event> = new EventEmitter();
    @Output() public clear: EventEmitter<Event> = new EventEmitter();
}
