import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: 'app-doc-status-icon',
    templateUrl: './doc-status-icon.component.html',
})
export class DocStatusIconComponent implements OnInit {
    @Input() status: string;
    @Input() showTitle: boolean;
    @Input() portalType: string;

    ngOnInit(): void {
    }
}