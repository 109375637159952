ng1App.factory('UsersApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/pub';
    return $resource('', {}, {
        getCurrent: {
            method: 'GET',
            url: URL_BASE + '/users/current'
        },
        getAccounts: {
            method: 'GET',
            url: URL_BASE + '/accounts',
            isArray: true
        },
        selectRole: {
            method: 'POST',
            url: URL_BASE + '/accounts'
        },
        loggedIn: {
            method: 'GET',
            url: apiUrl  + '/users/loggedIn',
            isArray: true
        },
        loggedInCount: {
            method: 'GET',
            url: apiUrl  + '/users/loggedInCount',
            isArray: false
        }
    });

}]
);