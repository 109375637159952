import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { controlIsRequired, generateSelectionValuesFromEntity } from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-nt-examination-pregnant-woman',
    templateUrl: './e096-nt-examination-pregnant-woman.component.html',
})
export class E096NtExaminationPregnantWomanComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() set fruitLength(value: number) {
        if (!value) return;
        this.fruitsList = _.times(value, () => null).map((_, i) => this.generateFruitName(i));
        this.formGroup2.reset();
        this.formGroup2.disable({emitEvent: false});
        this.updGroup2Status(this.researchFormControl?.value, 'Atliktas');
    };

    formGroup: FormGroup;
    researchFormControl: FormControl;
    formGroup1: FormGroup;
    formGroup2: FormGroup;
    examinationOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Atliktas'
        },
        {
            id: '1',
            code: '1',
            name: 'Neatliktas'
        }
    ];
    fruitsList: string[] = [];
    maxDate = new Date();
    minDate = new Date('2022-01-01');

    controlRequired = controlIsRequired.bind(this) as typeof controlIsRequired;
    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.researchFormControl = this.fb.control(null, [Validators.required]);
        this.formGroup1 = this.fb.group({
            investigationDate: this.fb.control({value: null, disabled: true}),
            investigationMethod: this.fb.control({value: null, disabled: true}),
            cervix: this.fb.control({value: null, disabled: true}),
            cervixConsistency: this.fb.control({value: null, disabled: true}),
            cervicalLength: this.fb.control({value: null, disabled: true}),
            openingOfTheCervix: this.fb.control({value: null, disabled: true}),
            maturityOfTheCervix: this.fb.control({value: null, disabled: true}),
            childBirthPeriod: this.fb.control({value: null, disabled: true})
        });
        this.formGroup2 = this.fb.group({
            fruitName: this.fb.control({value: null, disabled: true}),
            forerunner: this.fb.control({value: null, disabled: true}),
            fetalCoverings: this.fb.control({value: null, disabled: true}),
            waters: this.fb.control({value: null, disabled: true})
        });
        this.formGroup = this.fb.group({
            research: this.researchFormControl,
            ...this.formGroup1.controls,
            ...this.formGroup2.controls
        });

        this.researchFormControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(value => {
                this.updGroup1Status(value, 'Atliktas');
                this.updGroup2Status(value, 'Atliktas');
            });
    }

    private generateFruitName(i: number): string {
        return String.fromCharCode(65 + i);
    }

    private updChildControlValidators(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.get(key);
            if (formGroup.enabled) {
                control.setValidators([Validators.required]);
            } else {
                control.clearValidators();
            }
            control.updateValueAndValidity({onlySelf: true, emitEvent: false});
        })
    }

    private updGroup1Status(curValue: string, enableValue: string): void {
        if (enableValue === curValue) {
            this.formGroup1.enable({emitEvent: false});
            this.updChildControlValidators(this.formGroup1);
        } else if (this.formGroup1.enabled) {
            this.formGroup1.reset();
            this.formGroup1.disable({emitEvent: false});
            this.updChildControlValidators(this.formGroup1);
        }
    }

    private updGroup2Status(curValue: string, enableValue: string): void {
        if ((enableValue === curValue) && this.fruitsList?.length) {
            this.formGroup2.enable({emitEvent: false})
            this.updChildControlValidators(this.formGroup2);
        } else if (this.formGroup2.enabled) {
            this.formGroup2.reset();
            this.formGroup2.disable({emitEvent: false});
            this.updChildControlValidators(this.formGroup2);
        }
    }
}
