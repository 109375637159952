import {Component, Input, OnInit} from "@angular/core";
import moment from "moment";

@Component({
    selector: "dispense-document-data",
    templateUrl: "./dispense-document-data.component.html",
})
export class DispenseDocumentDataComponent implements OnInit {
    protected readonly moment = moment;
    @Input() dispenseData: any;

    constructor() {
    }

    ngOnInit(): void {

    }

    showLowIncome(): boolean {
        return this.dispenseData?.dispense?.lowIncome;
    }

    hasPatientLowIncome(): boolean {
        return this.dispenseData?.dispense?.lowIncome && this.dispenseData?.dispense?.lowIncome?.lowIncomeTag;
    }
}