angular.module('erx').directive('erxPatientHistory', function () {
    var ctrl = function ($scope, ErxPrescriptionsApiFactory, ErxDispenseApiFactory, ErxForeignDispenseApiFactory, $erxHelpers, $cookies, $filter, NcpApiFactory) {
    $scope.h = $erxHelpers;
    $scope.specialistFilter = 'all';
    $scope.orderBy = 'NUMBER';
    $scope.orderType = 'DESC';
    var settingsCookieName = 'erxs_phst' + $scope.options.getPatientId();

    $scope.orderValues = [
      {filter: 'NUMBER', 'displayValue': 'Pagal numerį'},
      {filter: 'DATE', 'displayValue': 'Pagal datą'}
    ];

    $scope.searchCountries = [];
        NcpApiFactory.getCountries({}, function (res) {
          for(var i = 0; i < res.countries.country.length; i++){
            $scope.searchCountries.push({
              name: $filter('translate')(res.countries.country[i].code),
              code: res.countries.country[i].code
            });
          };
        });

    var patientId = $scope.options.getPatientId();
    $scope.patientId = patientId;
    var count = 5;
    var page = 1;
    var searchQuery = '';
    var getCount = function (catClass, category) {
      if (catClass === 'pr') {
        return ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
                {
                  status: category === 'ALL' ? null : category,
                  count: 0,
                  page: 0,
                  patientId: patientId,
                  byPrc: $scope.specialistFilter == 'mine' ? true : null
                });
      } else if (catClass === 'prDoc') {
        return ErxPrescriptionsApiFactory.getMedPrescriptionDocsWithCount(
                {
                  status: category === 'ALL' ? null : category,
                  count: 0,
                  page: 0,
                  patientId: patientId,
                  byPrc: $scope.specialistFilter == 'mine' ? true : null
                });
      } else if (catClass === 'dsp') {
        return ErxDispenseApiFactory.getDocuments(
          {
            status: category === 'ALL' ? null : category,
            count: 0,
            page: 0,
            patientId: patientId,
          });
    }  else if (catClass === 'fdsp') {
      return ErxForeignDispenseApiFactory.getForeignDocuments({id: patientId},{});
    }else if (catClass === 'med' || catClass === 'finMed') {
        var namedQuery;
        if (category === 'NO_REC' || category === 'REC_NOT_OWNED') {
          if (catClass === 'med') {
            namedQuery = category === 'NO_REC' ? 'mp-med-being-taken-no-doc' : category === 'REC_NOT_OWNED' ? 'mp-med-being-taken-doc' : null;
          } else if (catClass === 'finMed') {
            namedQuery = category === 'NO_REC' ? 'mp-med-taken-no-doc' : category === 'REC_NOT_OWNED' ? 'mp-med-taken-doc' : null;
          }
          return  ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
                  {
                    namedQuery: namedQuery,
                    count: 0,
                    page: 0,
                    patientId: patientId,
                    byPrc: $scope.specialistFilter == 'mine' ? true : null
                  });
        } else if (category === 'REC_OWNED') {
          if (catClass === 'med') {
            namedQuery = 'md-med-being-taken';
          } else if (catClass === 'finMed') {
            namedQuery = 'md-med-taken';
          }
          return ErxDispenseApiFactory.getDocuments(
                  {
                    namedQuery: namedQuery,
                    count: 0,
                    page: 0,
                    patientId: patientId
                  });
        }
      }
    };

    $scope.allLoaded = false;
    $scope.isLoading = false;
    $scope.setCategory = function (catClass, category) {
      if ($scope.isLoading) {
        return;
      }
      $scope.catClass = catClass;
      $scope.category = category;
      searchQuery = '';
      $scope.query = '';
      page = 1;
      $scope.allLoaded = false;
      $scope.documents = [];
      $scope.loadMoreRecords();
    }

    $scope.prDocCounts = {
      ALL: getCount('prDoc', 'ALL'),
      ACTIVE: getCount('prDoc', 'ACTIVE'),
      ON_HOLD_FOR_REVIEW: getCount('prDoc', 'ON_HOLD_FOR_REVIEW'),
      ON_HOLD_RESERVED: getCount('prDoc', 'ON_HOLD_RESERVED'),
      COMPLETED: getCount('prDoc', 'COMPLETED'),
      STOPPED: getCount('prDoc', 'STOPPED'),
      ENTERED_IN_ERROR: getCount('prDoc', 'ENTERED_IN_ERROR')
    };

    $scope.prCounts = {
      ALL: getCount('pr', 'ALL'),
      ACTIVE: getCount('pr', 'ACTIVE'),
      ENTERED_IN_ERROR: getCount('pr', 'ENTERED_IN_ERROR')
    };

    $scope.dspCounts = {
      ALL: getCount('dsp', 'ALL'),
      COMPLETED: getCount('dsp', 'COMPLETED'),
      ENTERED_IN_ERROR: getCount('dsp', 'ENTERED_IN_ERROR'),
    };

    $scope.fdspCounts = {
      ALL: getCount('fdsp', 'ALL'),
    };

    $scope.medCounts = {
      NO_REC: getCount('med', 'NO_REC'),
      REC_NOT_OWNED: getCount('med', 'REC_NOT_OWNED'),
      REC_OWNED: getCount('med', 'REC_OWNED')
    };

    $scope.finMedCounts = {
      NO_REC: getCount('finMed', 'NO_REC'),
      REC_NOT_OWNED: getCount('finMed', 'REC_NOT_OWNED'),
      REC_OWNED: getCount('finMed', 'REC_OWNED')
    };


    $scope.loadMoreRecords = function () {
      if ($scope.catClass === 'pr') {
        $scope.isLoading = true;
        ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
                {
                  status: $scope.category === 'ALL' ? null : $scope.category,
                  count: count,
                  page: page++,
                  patientId: patientId,
                  query: searchQuery,
                  byPrc: $scope.specialistFilter == 'mine' ? true : null,
                  sort: $scope.orderBy,
                  order: $scope.orderType
                }, function (data) {
          angular.forEach(data.result, function (item) {
            $scope.documents.push(item);
          });
          $scope.allLoaded = $scope.documents.length === data.totalResults;
        }).$promise.finally(function () {
          $scope.isLoading = false;
        });
      } else if ($scope.catClass === 'prDoc') {
        $scope.isLoading = true;
        ErxPrescriptionsApiFactory.getMedPrescriptionDocsWithCount(
                {
                  status: $scope.category === 'ALL' ? null : $scope.category,
                  count: count,
                  page: page++,
                  patientId: patientId,
                  query: searchQuery,
                  byPrc: $scope.specialistFilter == 'mine' ? true : null,
                  sort: $scope.orderBy,
                  order: $scope.orderType
                }, function (data) {
          angular.forEach(data.result, function (item) {
            $scope.documents.push(item);
          });
          $scope.allLoaded = $scope.documents.length === data.totalResults;
        }).$promise.finally(function () {
          $scope.isLoading = false;
        });
      } else if ($scope.catClass === 'dsp') {
          ErxDispenseApiFactory.getDocuments(
          {
            status: $scope.category === 'ALL' ? null : $scope.category,
            count: count,
            page: page++,
            patientId: patientId,
            query: searchQuery,
            sort: $scope.orderBy,
            order: $scope.orderType
          }, function (data) {
            angular.forEach(data.result, function (item) {
              $scope.documents.push(item);
            });
            $scope.allLoaded = $scope.documents.length === data.totalResults;
          }).$promise.finally(function () {
            $scope.isLoading = false;
          });
      } else if ($scope.catClass === 'fdsp') {
        $scope.isLoading = true;
        $scope.documents = [];
        ErxForeignDispenseApiFactory.getForeignDocuments( {id: patientId},{}, function (data) {
            angular.forEach(data, function (item) {
              item.country = $filter('translate')(item.countryCode);
              $scope.documents.push(item);
            });
          }).$promise.finally(function () {
            $scope.isLoading = false;
          });
      }
      else if ($scope.catClass === 'med' || $scope.catClass === 'finMed') {
        var namedQuery;
        $scope.isLoading = true;
        if ($scope.category === 'NO_REC' || $scope.category === 'REC_NOT_OWNED') {
          if ($scope.catClass === 'med') {
            namedQuery = $scope.category === 'NO_REC' ? 'mp-med-being-taken-no-doc' : $scope.category === 'REC_NOT_OWNED' ? 'mp-med-being-taken-doc' : null;
          } else if ($scope.catClass === 'finMed') {
            namedQuery = $scope.category === 'NO_REC' ? 'mp-med-taken-no-doc' : $scope.category === 'REC_NOT_OWNED' ? 'mp-med-taken-doc' : null;
          }
          ErxPrescriptionsApiFactory.getMedPrescriptionsWithCount(
                  {
                    namedQuery: namedQuery,
                    count: count,
                    page: page++,
                    patientId: patientId,
                    byPrc: $scope.specialistFilter == 'mine' ? true : null,
                    query: searchQuery,
                    sort: $scope.orderBy,
                    order: $scope.orderType
                  }, function (data) {
            angular.forEach(data.result, function (item) {
              $scope.documents.push(item);
            });
            $scope.allLoaded = $scope.documents.length === data.totalResults;
          }).$promise.finally(function () {
            $scope.isLoading = false;
          });
        } else if ($scope.category === 'REC_OWNED') {
          if ($scope.catClass === 'med') {
            namedQuery = 'md-med-being-taken';
          } else if ($scope.catClass === 'finMed') {
            namedQuery = 'md-med-taken';
          }
          ErxDispenseApiFactory.getDocuments(
                  {
                    namedQuery: namedQuery,
                    count: count,
                    page: page++,
                    patientId: patientId,
                    query: searchQuery,
                    sort: $scope.orderBy,
                    order: $scope.orderType
                  }, function (data) {
            angular.forEach(data.result, function (item) {
              $scope.documents.push(item);
            });
            $scope.allLoaded = $scope.documents.length === data.totalResults;
          }).$promise.finally(function () {
            $scope.isLoading = false;
          }
          );
        }
      }

    }

    $scope.clearSearch = function () {
      $scope.documents = [];
      delete $scope.catClass;
      delete $scope.category;
      $scope.allLoaded = true;
    }

    $scope.getPrDocStatusValue = function (prs) {
      switch (prs.prescriptionData.status.code) {
        case 'active':
          return 'GALIOJA';
        case 'on hold':
          if (prs.prescriptionData.status.reasonCode === 'for review')
            return 'SUSTABDYTAS';
          else if (prs.prescriptionData.status.reasonCode === 'reserved')
            return 'REZERVUOTAS';
          break;
        case 'completed':
          return 'ĮSIGYTAS';
        case 'entered in error':
          return 'ATŠAUKTAS';
        case 'stopped':
          return 'NEGALIOJANTIS';
        default:
          return 'FIX ME';
      }
    }

    $scope.getPrStatusValue = function (prs) {
      switch (prs.prescriptionData.prescriptionStatus.code) {
        case 'active':
          return 'GALIOJA';
        case 'entered in error':
          return 'ATŠAUKTAS'
        case 'entered in error':
          return 'NEGALIOJANTIS'
        default:
          return 'FIX ME';
      }
    }

    $scope.getPrsTypeValue = function (prs) {
      switch (prs.prescriptionData.prescriptionType) {
        case 'va' :
          return 'Vaistas';
        case 'vv' :
          return 'Vardinis vaistas';
        case 'mpp' :
          return 'MPP';
        case 'ev' :
          return 'Ekstemporalus vaistas';
        case 'kp' :
          return 'Kitas produktas';
        default:
          return 'FIX ME';
      }
    }

    $scope.getDspStatusValue = function (prs) {
      if (!prs.dispenseData.status) {
        return 'JUODRAŠTIS';
      } else {
        switch (prs.dispenseData.status.code) {
          case 'in progress':
            return 'VYKDOMAS';
          case 'entered in error':
            return 'ATŠAUKTAS';
          case 'completed':
            return 'AKTUALUS';
          default:
            return 'FIX ME';
        }
      }
    };

    $scope.getFdspStatusValue = function (prs) {
      if (!prs.status) {
        return 'JUODRAŠTIS';
      } else {
        switch (prs.status) {
          case 'P':
            return 'Įsigytas';
          case 'N':
            return 'Neįsigytas';
          case 'A':
            return 'Atšauktas';
          default:
            return 'FIX ME';
        }
      }
    };

    $scope.getStatusColorClass = function (status) {
      switch (status) {
        case 'stopped':
        case 'on hold':
        case 'entered in error':
          return 'text-danger';
        case 'completed':
        case 'active':
          return 'text-success';
        default:
          return '';
      }
      return "text-danger"
    }

    $scope.getForeignStatusColorClass = function (status) {
      if (status === 'P') {
        return 'text-success';
      } else {
        return 'text-danger';
      }
    }

    $scope.canShowValidUntil = function (status) {
      switch (status) {
        case 'completed':
        case 'stopped':
        case 'entered in error':
          return false;
      }
      return true;
    }

    $scope.quickSearch = function () {
      page = 1;
      searchQuery = $scope.query;
      $scope.allLoaded = false;
      $scope.documents = [];
      $scope.loadMoreRecords();
    }

    $scope.specialistFilterChanged = function () {
      $scope.setCategory($scope.catClass, $scope.category);

      $scope.prDocCounts = {
        ALL: getCount('prDoc', 'ALL'),
        ACTIVE: getCount('prDoc', 'ACTIVE'),
        ON_HOLD_FOR_REVIEW: getCount('prDoc', 'ON_HOLD_FOR_REVIEW'),
        ON_HOLD_RESERVED: getCount('prDoc', 'ON_HOLD_RESERVED'),
        COMPLETED: getCount('prDoc', 'COMPLETED'),
        STOPPED: getCount('prDoc', 'STOPPED'),
        ENTERED_IN_ERROR: getCount('prDoc', 'ENTERED_IN_ERROR')
      };

      $scope.prCounts = {
        ALL: getCount('pr', 'ALL'),
        ACTIVE: getCount('pr', 'ACTIVE'),
        ENTERED_IN_ERROR: getCount('pr', 'ENTERED_IN_ERROR')
      };

      $scope.dspCounts = {
        ALL: getCount('dsp', 'ALL'),
        COMPLETED: getCount('dsp', 'COMPLETED'),
        ENTERED_IN_ERROR: getCount('dsp', 'ENTERED_IN_ERROR')
      };

      $scope.fdspCounts = {
        ALL: getCount('fdsp', 'ALL'),
      };

      $scope.medCounts = {
        NO_REC: getCount('med', 'NO_REC'),
        REC_NOT_OWNED: getCount('med', 'REC_NOT_OWNED'),
        REC_OWNED: getCount('med', 'REC_OWNED')
      };

      $scope.finMedCounts = {
        NO_REC: getCount('finMed', 'NO_REC'),
        REC_NOT_OWNED: getCount('finMed', 'REC_NOT_OWNED'),
        REC_OWNED: getCount('finMed', 'REC_OWNED')
      };
    }

    $scope.orderByChanged = function () {
      saveSettings();
      page = 1;
      $scope.documents = [];
      $scope.allLoaded = false;
      $scope.loadMoreRecords();
    }

    $scope.switchOrderType = function () {
      if ($scope.orderType === 'ASC')
        $scope.orderType = 'DESC'
      else if ($scope.orderType === 'DESC')
        $scope.orderType = 'ASC'
      saveSettings();
      page = 1;
      $scope.documents = [];
      $scope.allLoaded = false;
      $scope.loadMoreRecords();
    }

    $scope.calculateUseUntil = function (doc) {

      if ($scope.category === 'NO_REC') {
        var ret = new Date(doc.prescriptionData.dateWritten);
        ret.setDate(ret.getDate() + doc.prescriptionData.dispenseData.dispenseExpectedSupplyDuration);
        return ret;
      } else if ($scope.category === 'REC_NOT_OWNED') {
        var ret = new Date(doc.prescriptionData.dateWritten);
        ret.setDate(ret.getDate() + doc.prescriptionData.dispenseData.dispenseExpectedSupplyDuration);
        return ret;
      } else if ($scope.category === 'REC_OWNED') {
        return doc.dispenseData.dispense.dueDate;
      }
    }

    var saveSettings = function () {
      $cookies[settingsCookieName] = $scope.orderType + ',' + $scope.orderBy;
    }

    var loadSettings = function () {
      if ($cookies[settingsCookieName]) {
        var tokens = $cookies[settingsCookieName].split(',');
        if (tokens.length == 2) {
          $scope.orderType = tokens[0];
          $scope.orderBy = tokens[1];
        }
      }
    }

    $scope.resetSettings = function () {
      delete $cookies[settingsCookieName];
      $scope.orderBy = 'NUMBER';
      $scope.orderType = 'DESC';
      $scope.switchOrderType();
    }

    $scope.foreignFilter = {};

    $scope.filterForeignDocuments = function () {
      $scope.isLoading = true;
      if (Object.keys($scope.foreignFilter).includes('country') || Object.keys($scope.foreignFilter).includes('brandName') ||
      Object.keys($scope.foreignFilter).includes('to') || Object.keys($scope.foreignFilter).includes('from')) {

        $scope.documents = [];
        var countryCode;
        if($scope.foreignFilter.country) {
          countryCode = $scope.searchCountries.find(function (e) {
            return e.name === $scope.foreignFilter.country;
          }).code;
        }

        ErxForeignDispenseApiFactory.getForeignDocuments({id: patientId},
        {
          countryCode: countryCode,
          brandName: $scope.foreignFilter.brandName,
          dateFrom: $scope.foreignFilter.from,
          dateTo: $scope.foreignFilter.to,
        }, function (data) {
          angular.forEach(data, function (item) {
            item.country = $filter('translate')(item.countryCode);
            $scope.documents.push(item);
          });
        }).$promise.finally(function () {
          $scope.isLoading = false;
        });
      }
    };

    $scope.clearForeignDocumentsFilter = function () {
      $scope.isLoading = true;
      if (Object.keys($scope.foreignFilter).includes('country') || Object.keys($scope.foreignFilter).includes('brandName') ||
      Object.keys($scope.foreignFilter).includes('to') || Object.keys($scope.foreignFilter).includes('from')) {

        $scope.foreignFilter = {};

        $scope.documents = [];
        ErxForeignDispenseApiFactory.getForeignDocuments( {id: patientId}, {}, function (data) {
          angular.forEach(data, function (item) {
            item.country = $filter('translate')(item.countryCode);
            $scope.documents.push(item);
          });
        }).$promise.finally(function () {
          $scope.isLoading = false;
        });
      }
    };


    $scope.setFilter = function(field) {
      if(field === "to" ) {
        $scope.dateToSelected = true;
      }else $scope.dateToSelected = false;

      if(field === "from") {
        $scope.dateFromSelected = true;
      }else $scope.dateFromSelected = false;

      if(field === "brandName") {
        $scope.brandNameSelected = true;
      }else $scope.brandNameSelected = false;

      if(field === "country") {
        $scope.countrySelected = true;
      }else $scope.countrySelected = false;
    };

    $scope.getStatusColorClass = function (status) {
      if (status) {
        return 'text-danger'
      } else return 'text-success'
    }


    $scope.setCategory('prDoc', 'ALL');
    loadSettings();
  }
    ctrl.$inject = ["$scope", "ErxPrescriptionsApiFactory", "ErxDispenseApiFactory", "ErxForeignDispenseApiFactory", "$erxHelpers", "$cookies", "$filter", "NcpApiFactory"];

  return {
    restrict: 'E',
    replace: true,
    scope: {options: '='},
    templateUrl: 'modules/erx/patient-history/erxPatientHistory.html',
    controller: ctrl
  };
});