ng1App.factory('SpecialUsersApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/specialUsers';
    return $resource('', {}, {
        get: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        create: {
            method: 'POST',
            url: URL_BASE
        },
        update: {
            method: 'PUT',
            url: URL_BASE + '/:id'
        },
        block: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/block'
        },
        unblock: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/unblock'
        },
        cancel: {
        	method: 'PUT',
        	url: URL_BASE + '/:id/cancel'
        },
        getList: {
        	method: 'GET',
        	url: URL_BASE
        },
        getCount: {
        	method: 'GET',
        	url: URL_BASE + '/count'
        },
        createAccessRules: {
        	method: 'POST',
        	url: URL_BASE + '/:userId/accessRules'
        },
        updateAccessRules: {
        	method: 'PUT',
        	url: URL_BASE + '/:userId/accessRules'
        },
        getAccessRules: {
        	method: 'GET',
        	url: URL_BASE + '/:userId/accessRules'
        }
    });
}]);