<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row mx-auto">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.assessmentDate' | translate}}:"
                inputId="assessmentDone"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('assessmentDone')"
            ></app-date-picker>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
            <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.consciousness' | translate}}:
            </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of consciousnessOptions; let i = index"
                        radioName="consciousness-{{i}}"
                        [fControl]="formGroup.get('consciousness')"
                        [radioValue]="option.name"
                        id="consciousness-{{i}}"
                        class="my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
            </div>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.breathRate' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                units="k/min."
                type="number"
                [fControl]="formGroup.get('breathingRate')"
            ></app-input>
            <app-input
                labelText="Sp02:"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                units="%"
                type="number"
                [fControl]="formGroup.get('spO2')"
            ></app-input>
            <app-input
                labelText="{{'doc.med.dat.pul' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                units="k/min."
                type="number"
                [showErrors]="true"
                [fControl]="formGroup.get('pulse')"
            ></app-input>
            <app-double-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.bloodPressure' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                units="mmHg"
                type="number"
                labelClass="col-form-label mt-3"
                [firstControl]="formGroup.get('bloodPressureSystolic')"
                [secondControl]="formGroup.get('bloodPressureDiastolic')"
            ></app-double-input>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
            <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.skin' | translate}}:
            </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of skinOptions; let i = index"
                        radioName="skin-{{i}}"
                        [fControl]="formGroup.get('skin')"
                        [radioValue]="option.name"
                        id="skin-{{i}}"
                        class="my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
            <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.compounds' | translate}}:
            </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of compoundsOptions; let i = index"
                        radioName="compounds-{{i}}"
                        [fControl]="formGroup.get('compounds')"
                        [radioValue]="option.name"
                        id="compounds-{{i}}"
                        class="my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
            </div>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formWomenCondition.diuresis' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                units="ml/val"
                type="number"
                [fControl]="formGroup.get('diuresis')"
            ></app-input>
        </div>
    </div>
</nhr-record-widget>