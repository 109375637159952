ng1App.controller('doctor.patient.ResultsCtrl', 
    ['$scope', '$filter', '$window', '$routeParams', '$modal', 'PatientsApiFactory', 'EntitiesApiFactory', '$modalConfirm', 'MessageService', 'FormLoaderService', 'DpSessionContext',
     function ($scope, $filter, $window, $routeParams, $modal, PatientsApiFactory, EntitiesApiFactory, $modalConfirm,
                                                          MessageService, FormLoaderService, DpSessionContext) {
    'use strict';

    FormLoaderService.startFormLoading();
    $scope.$emit('setHelpPage', 'perzireti-paciento-tyrimu-rezultatu-sarasa');
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;

    $scope.data = {};
    $scope.page = 1;
    $scope.pageCount = 10;
    $scope.form = {};
    $scope.advancedSearch = {};
    $scope.listState = {
        all: 1,
        lab: 2,
        pat: 3,
        act: 4,
        can: 5,
        search: 6
    };

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();

    var docListLoadingCallback = function (research) {
        if (!$scope.research) {
            $scope.research = research;

            switch ($scope.currentState) {
                case $scope.listState.all :
                    $scope.totalCount = research.totalCount;
                    $scope.patCount = research.patCount;
                    $scope.labCount = research.labCount;
                    $scope.activeCount = research.activeCount;
                    $scope.canceledCount = research.canceledCount;
                    $scope.currentCount = $scope.totalCount;
                    break;
                case $scope.listState.lab :
                    $scope.currentCount = $scope.labCount;
                    break;
                case $scope.listState.pat :
                    $scope.currentCount = $scope.patCount;
                    break;
                case $scope.listState.act :
                    $scope.currentCount = $scope.activeCount;
                    break;
                case $scope.listState.can :
                    $scope.currentCount = $scope.canceledCount;
                    break;
            }
        }
        else {
            appendList(research.documents);
        }
        $scope.hideMore = false;
    };

    $scope.researchDocsForListRequest = function (callback, getCounts, orderType, isActive) {
        var promise = PatientsApiFactory.getResearchDocsForList({id: $routeParams.patientId,
            page: $scope.page,
            count: $scope.pageCount,
            getCounts: getCounts,
            orderType: orderType,
            isActive: isActive}, callback).$promise;

        FormLoaderService.endFormLoading(promise);
    };

    $scope.setInitProperties = function (state) {
        $scope.hideMore = true;
        $scope.research = null;
        $scope.page = 1;
        $scope.currentState = state;
    };

    $scope.initAllResults = function () {
        if ($scope.currentState != $scope.listState.all) {
            $scope.setInitProperties($scope.listState.all);
            loadDocuments();
        }
    };

    $scope.initLabResults = function () {
        if ($scope.currentState != $scope.listState.lab) {
            $scope.setInitProperties($scope.listState.lab);
            loadDocuments();
        }
    };

    $scope.initPatResults = function () {
        if ($scope.currentState != $scope.listState.pat) {
            $scope.setInitProperties($scope.listState.pat);
            loadDocuments();
        }
    };

    $scope.initActiveResults = function () {
        if ($scope.currentState != $scope.listState.act) {
            $scope.setInitProperties($scope.listState.act);
            loadDocuments();
        }
    };

    $scope.initCanceledResults = function () {
        if ($scope.currentState != $scope.listState.can) {
            $scope.setInitProperties($scope.listState.can);
            loadDocuments();
        }
    };

    $scope.showPageSeparator = function (index){
        return (index + 1) % $scope.pageCount === 0;
    };

    DpSessionContext.getSettings(function (data) {
        $scope.pageCount = data.count_per_page;
        $scope.initAllResults();
    });

    function loadAllResults(callback, getCounts) {
        $scope.researchDocsForListRequest(callback, getCounts, null, null);
    }

    function loadLabResults(callback) {
        $scope.researchDocsForListRequest(callback, false, $scope.listState.lab, null);
    }

    function loadPatResults(callback) {
        $scope.researchDocsForListRequest(callback, false, $scope.listState.pat, null);
    }

    function loadActiveResults(callback) {
        $scope.researchDocsForListRequest(callback, false, null, true);
    }

    function loadCanceledResults(callback) {
        $scope.researchDocsForListRequest(callback, false, null, false);
    }

    function loadSearchResults(callback) {
        PatientsApiFactory.getResearchDocsByAdvancedSearch(collectSearchCriteria(), callback);
    }

    function loadDocuments() {
        switch ($scope.currentState) {
            case $scope.listState.all :
                loadAllResults(docListLoadingCallback, !$scope.research);
                break;
            case $scope.listState.lab :
                loadLabResults(docListLoadingCallback);
                break;
            case $scope.listState.pat :
                loadPatResults(docListLoadingCallback);
                break;
            case $scope.listState.act :
                loadActiveResults(docListLoadingCallback);
                break;
            case $scope.listState.can :
                loadCanceledResults(docListLoadingCallback);
                break;
            case $scope.listState.search :
                loadSearchResults(docListLoadingCallback);
                break;
        }
    }

    var modal = $modal({
        scope: $scope,
        contentTemplate: '/templates/portal/doctor/patient/results/search_modal.html'
    });

    function appendList(documents) {
        if (documents) {
            for (var i = 0; i < documents.length; i++) {
                $scope.research.documents.push(documents[i]);
            }
        }
    }

    $scope.loadMoreRecords = function () {
        $scope.page++;
        loadDocuments();
    };

    $scope.openRelatedDocument = function (documentId) {
        var url = '/dp/patients/' + $routeParams.patientId + '/documents/' + documentId;
        $window.location.href = url;
    };

    $scope.submitAdvancedSearch = function () {
        if (!$scope.form.filterForm.$invalid) {
            $scope.page = 1;
            $scope.currentState = $scope.listState.search;
            $scope.hideMore = true;
            PatientsApiFactory.getResearchDocsByAdvancedSearch(collectSearchCriteria(), function (research) {
                $scope.research = research;
                $scope.currentCount = research.totalCount;
                $scope.hideMore = false;
            });
            modal.$promise.then(function () {
                modal.hide();
            });
        }
    };

    $scope.clearQuickSearch = function () {

    };

    $scope.resetSearch = function () {
        $scope.clearQuickSearch();
        $scope.cleanAdvancedSearch(false);
        if ($scope.currentState == $scope.listState.search) {
            $scope.initAllResults();
        }
    };

    $scope.cleanAdvancedSearch = function (isConfirmRequired) {
        var formCleanFunction = function () {
            $scope.advancedSearch.validFrom = null;
            $scope.advancedSearch.validTo = null;
            $scope.advancedSearch.signed = false;
            $scope.advancedSearch.canceled = false;
            $scope.uncheckAll($scope.advancedSearch.docs);
            $scope.uncheckAll($scope.advancedSearch.practitioners);
            $scope.uncheckAll($scope.advancedSearch.organizations);
            $scope.uncheckAll($scope.advancedSearch.laboratoryResearch);
            $scope.uncheckAll($scope.advancedSearch.primaryDiagnosis);
            $scope.uncheckAll($scope.advancedSearch.morphologicalCode);
            $scope.advancedSearch.text = {};
            if ($scope.form.filterForm.$invalid) {
                $scope.form.filterForm.$setPristine();
                $scope.form.filterForm.validFrom.$setValidity('validDate', true);
                $scope.form.filterForm.validTo.$setValidity('validDate', true);
                $scope.form.filterForm.validFrom.$setValidity('lessThan', true);
                $scope.form.filterForm.validTo.$setValidity('moreThan', true);
                $scope.form.filterForm.$invalid = false;
            }
        };
        if (isConfirmRequired) {
            $scope.confirmClean(formCleanFunction);
        }
        else {
            formCleanFunction();
        }
    };

    $scope.checkAll = function (listTocheck) {
        angular.forEach(listTocheck, function (item) {
            item.selected = true;
        });
    };

    $scope.uncheckAll = function (listToUncheck) {
        if (listToUncheck) {
            angular.forEach(listToUncheck, function (item) {
                if (item) {
                    item.selected = false;
                }
            });
        }
    };

    function collectPractitioners() {
        var practitionerIds = [];
        angular.forEach($filter('filter')($scope.advancedSearch.practitioners, {selected: true}), function (item) {
            practitionerIds.push(item.id);
        });
        return practitionerIds;
    }

    function collectOrganizations() {
        var organizationIds = [];
        angular.forEach($filter('filter')($scope.advancedSearch.organizations, {selected: true}), function (item) {
            organizationIds.push(item.id);
        });
        return organizationIds;
    }

    function collectEntities(listToCollectFrom) {
        var entityIds = [];
        angular.forEach(listToCollectFrom, function (item) {
            entityIds.push(item.code);
        });
        return entityIds;
    }

    function collectSearchCriteria() {
        function getDocCodes(docs) {
            var docCodes = [];
            angular.forEach(docs, function (doc) {
                docCodes.push(doc.code);
            });
            return docCodes;
        }
        return {
            id: $routeParams.patientId,
            page: $scope.page,
            count: $scope.pageCount,
            validFrom: $scope.advancedSearch.validFrom,
            validTo: $scope.advancedSearch.validTo,
            signed: $scope.advancedSearch.signed,
            canceled: $scope.advancedSearch.canceled,
            docsTypes: getDocCodes($filter('filter')($scope.advancedSearch.docs, {selected: true})),
            practitioners: collectPractitioners(),
            organizations: collectOrganizations(),
            laboratoryResearch: collectEntities($filter('filter')($scope.advancedSearch.laboratoryResearch, {selected: true})),
            primaryDiagnosis: collectEntities($filter('filter')($scope.advancedSearch.primaryDiagnosis, {selected: true})),
            morphologicalCodes: collectEntities($filter('filter')($scope.advancedSearch.morphologicalCodes, {selected: true}))
        }
    }

    var AdvancedSearchPractitionersRegexFunction = function (item, regex, filteredItems) {
        if (regex.test(item.givenName + ' ' + item.familyName)) {
            filteredItems.push(item);
        }
    };

    var AdvancedSearchOrganizationsRegexFunction = function (item, regex, filteredItems) {
        if (regex.test(item.name)) {
            filteredItems.push(item);
        }
    };

    var entityRegexFunction = function (item, regex, filteredItems) {
        if (regex.test(item.code + ' ' + item.name)) {
            filteredItems.push(item);
        }
    };

    function escapeSearchPhrase(text) {
        return text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    function filterListByRegExp(listToFilter, regexFunction, text) {
        var filteredItems = [];
        var pattern = ".*" + escapeSearchPhrase(text) + ".*";
        var regExp = new RegExp(pattern, 'i');
        angular.forEach(listToFilter, function (item) {
            regexFunction(item, regExp, filteredItems);
        });
        return filteredItems;
    }

    $scope.search = function () {
        $scope.cancelErr = 0;
        $scope.documentCancelReason = {};
        if (angular.equals({}, $scope.advancedSearch)) {
            PatientsApiFactory.getResearchDataForAdvancedSearch({id: $routeParams.patientId}, function (data) {
                $scope.advancedSearch = data;
                EntitiesApiFactory.getList({classCode: "data-set"}, function (data) {
                    var documentFilterFunction = function (doc) {
                        return doc.code == 'E014-ats' || doc.code == 'E200-ats';
                    };
                    $scope.advancedSearch.docs = $filter('filter')(data, documentFilterFunction);
                });
            });
        }
        modal.$promise.then(function () {
            modal.show();
        });
    };

    $scope.confirmClean = function (callback) {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')})
                .then(callback);
    }
}]);