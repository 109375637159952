<nhr-record-widget
    [mixed]="true"
    [closable]="false"
    [closed]="false"
    [title]="title | translate"
    class="h-auto"
    [bodyClass]="'page-list list-group'"
>
    <li *ngIf="loading" class="list-group-item">
        <spinner [working]="true" [inside]="true" [smallSize]="true"></spinner>
    </li>
    <li class="list-group-item" *ngFor="let item of inr">
        <p class="mb-0">
            <small>
                {{ item.date }}
            </small>
        </p>
        <div class="fw-bold mb-0" *ngIf="item.medication">
            <span *ngIf="item.medication?.name" class="fw-bold mb-0">
                {{ item.medication.name }}
            </span>
            <span *ngIf="item.medication?.activeSubstances" class="vr"></span>
            <span *ngIf="item.medication?.activeSubstances" class="fw-bold mb-0">
                {{ item.medication?.activeSubstances }}
            </span>
            <span *ngIf="item.medication?.strength" class="vr"></span>
            <span *ngIf="item.medication?.strength" class="fw-bold mb-0">
                {{ item.medication?.strength }}
            </span>
            <span *ngIf="item.medication?.form" class="vr"></span>
            <span *ngIf="item.medication?.form" class="fw-bold mb-0">
                {{ item.medication?.form?.display }}
            </span>
        </div>
        <span class="mb-0" *ngIf="item.registeringPractitioner">
            {{ 'inr.listPage.registeredBy' | translate }}{{ ': ' }}{{ item.registeringPractitioner?.speciality }}
        </span>
        <span class="vr"></span>
        <span class="mb-0" *ngIf="item.registeringPractitioner">
            {{ item.registeringPractitioner?.["name.given"] }} {{ item.registeringPractitioner?.["name.family"] }}
        </span>
    </li>
    <li *ngIf="!loading" class="list-group-item p-0">
        <div class="row p-0">
            <div class="col align-content-center">
                <a [href]="getAllUrl()" class="d-block text-decoration-none hover text-center px-2_5 py-2">
                    {{ 'show.more' | translate }}
                </a>
            </div>
            <div class="col align-content-center">
                <a [href]="getCreateUrl()" class="d-block btn btn btn-secondary fw-normal m-2">
                    {{ 'inr.listPage.newInr' | translate }}
                </a>
            </div>
        </div>
    </li>
</nhr-record-widget>