ng1App.factory('BusinessMedicationsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/bus-medications';
    return $resource('', {}, {
        search: {
            method: 'GET',
            url: URL_BASE,
        },
    });
}]
);