ng1App.factory("RemotePharmacyApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/remotePharmacies';
    
    return $resource('', {}, {  
        search: {       
            method: 'GET',
            url: URL_BASE + '/',
            isArray: false
        },
        searchById:{
            method: 'GET',
            url: URL_BASE + '/:id/',
            isArray: false
        },
        save: {
            method: 'POST',
            url: URL_BASE + "/save"
        },
        update: {
            method: 'PUT',
            url: URL_BASE + "/update"
        },
        exists:{
            method: 'GET',
            url: URL_BASE + "/exists/:id/"
        }
    });
}]);