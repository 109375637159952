<nhr-record-widget
    [closed]="false"
    [title]="'inr.vaccineForm.title' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <ng-container *ngIf="editable">
            <div class="row mb-3">
                <div class="input-group">
                    <app-radio-button
                        *ngFor="let option of vaccineSelectionVariants; let i = index"
                        radioName="vaccineSelectionVariant-{{i}}"
                        [fControl]="vaccineSelectionVariant"
                        [radioValue]="option.name"
                        id="vaccineSelectionVariant-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="vaccineSelectionVariant" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.vaccineForm.serchVaccine' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-find-and-prefill-info
                        [obs]="getVaccinesObs"
                        [disable]="vaccineSelectionVariant.value !== 'Pasirinkti vakciną iš skiepijamo fakto (E063)'"
                        (selectData)="selectVaccine($event)"
                    />
                </div>
            </div>
            <hr>
        </ng-container>
        <div class="row mb-3 req">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.vaccineForm.vaccineName' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('vaccineName')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('vaccineName')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.vaccineForm.vaccineSerialNumber' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('vaccineSerialNumber')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'dp.use.vacc.dat.dat' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-date-picker
                    inputId="vaccinationDate"
                    suffixIcon="fa fa-calendar"
                    [maxDate]="maxDate"
                    [withTimepicker]="true"
                    [fControl]="formGroup.get('vaccinationDate')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('vaccinationDate')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.vaccineForm.dosesWasVaccinated' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-select
                    [id]="'dosesWasVaccinated'"
                    [fControl]="formGroup.get('dosesWasVaccinated')"
                    [values]="selectFromEntities(selectOptions)"
                ></app-select>
            </div>
        </div>
        <div class="row mb-3" *ngIf="controlValue('dosesWasVaccinated')">
            <div class="col-md-4 col-lg-3">
                <strong class="text-end">
                    {{'inr.vaccineForm.vaccinesGotBefore' | translate}}
                </strong>
            </div>
        </div>
        <ng-container *ngFor="let control of getDosesFormArrayControls; let i = index">
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong>
                       DOZE {{i + 1}}
                    </strong>
                </div>
            </div>
            <div class="row mb-3 req">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.vaccineForm.vaccineName' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-input
                        inputGroupClass="mt-auto"
                        [fControl]="control"
                    ></app-input>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary" (click)="log()">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>
