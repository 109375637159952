ng1App.controller("doctor.patient.cert.IncapacityEditCtrl", [
    "$scope",
    "$routeParams",
    "$modal",
    "$filter",
    "$window",
    "$location",
    "DocumentsApiFactory",
    "PatientsApiFactory",
    "EptsCertificatesApiFactory",
    "EntitiesApiFactory",
    "MessageService",
    "DocumentSpinnerHelper",
    "$modalConfirm",
    "$routeSegment",
    "$q",
    "FormLoaderService",
    function (
        $scope,
        $routeParams,
        $modal,
        $filter,
        $window,
        $location,
        DocumentsApiFactory,
        PatientsApiFactory,
        EptsCertificatesApiFactory,
        EntitiesApiFactory,
        MessageService,
        DocumentSpinnerHelper,
        $modalConfirm,
        $routeSegment,
        $q,
        FormLoaderService
    ) {
        MessageService.clearAll();

        $scope.$emit("setHelpPage", "pildyti-nedarbingumo-pazymejima");

        FormLoaderService.startFormLoading();

        // Get additional info
        // <=============================>
        $scope.incapacityReasons = EntitiesApiFactory.getList({ classCode: "disability-cause" }, function (list) {
            $scope.incapacityCertificate.reason = list[0];
        });

        $scope.behaviourRulesViolations = EntitiesApiFactory.getList({ classCode: "conduct-type" }, function (list) {
            $scope.incapacityCertificate.behaviourRulesViolationInformation.type = list[0];
        });
        // <=============================>

        // Data initialization
        $scope.hcpConclusionTypes = [
            { code: "1", name: $filter("translate")("cerr.inc.hcp_conclusion_type.able-bodied") },
            { code: "2", name: $filter("translate")("cerr.inc.hcp_conclusion_type.temporary_disabled") },
            { code: "3", name: $filter("translate")("cerr.inc.hcp_conclusion_type.directed_to_dcao") },
        ];

        $scope.diagnoses = [];

        // Additional form parameters
        $scope.additionalParameters = {
            searchForCaringPerson: "spc",
            caringPersonPersonalCode: null,
            caringPersonNhsCode: null,

            enterRehabInformation: false,
            enterAccidentInformation: false,
            enterOccupationalDiseaseInformation: false,
            enterBehaviourRulesViolationInformation: false,

            spinner: DocumentSpinnerHelper,
        };

        $scope.incapacityCertificate = {
            submissionDate: $routeParams.submissionDate,
            documentId: $routeParams.documentId,

            number: null,
            series: null,
            inpatient: false,
            reason: null,
            diagnosis: null,
            totalIncapacityTime: 0,
            caringPerson: null,

            rehabInformation: {
                startDate: null,
                endDate: null,
            },

            accidentInformation: {
                date: null,
            },

            occupationalDiseaseInformation: {
                date: null,
                actNumber: null,
            },

            behaviourRulesViolationInformation: {
                date: null,
                type: null,
                description: null,
                periodStart: null,
                periodEnd: null,
            },

            hcpConclusion: {
                date: null,
                number: null,
                type: $scope.hcpConclusionTypes[0],
            },

            inpatientPeriodStartDate: null,
            inpatientPeriodEndDate: null,

            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: null,
        };

        $scope.document = DocumentsApiFactory.getByComposition({ id: $routeParams.documentId }, function (document) {
            $scope.patientData = document.metadata.patient;
            $scope.practitioner = document.metadata.practitioner;
            $scope.organization = document.metadata.custodian;

            $scope.incapacityCertificate.inpatient = document.metadata.docType == "e003";

            // Calculate inpatient days
            if ($scope.incapacityCertificate.inpatient) {
                if (document.partInPatientAccomodation) {
                    _.each(document.partInPatientAccomodation, function (accomodation) {
                        if (
                            $scope.incapacityCertificate.inpatientPeriodStartDate == null ||
                            accomodation.from < $scope.incapacityCertificate.inpatientPeriodStartDate
                        ) {
                            $scope.incapacityCertificate.inpatientPeriodStartDate = accomodation.from;
                        }
                    });
                    if (!$scope.incapacityCertificate.inpatientPeriodStartDate) {
                        $scope.incapacityCertificate.inpatientPeriodStartDate = document.metadata.encounter.arrivalDate;
                    }
                }

                if (document.partDischarge) {
                    $scope.incapacityCertificate.inpatientPeriodEndDate = document.partDischarge.dischargeDate;
                }
            }

            // Get diagnoses
            if (document.partDiagnosis) {
                _.each(document.partDiagnosis, function (diagnosis) {
                    $scope.diagnoses.push(diagnosis.diagnosis);
                });
            }
        });

        $scope.document.$promise
            .then(function () {
                var number = EptsCertificatesApiFactory.getNewCertificateNumber(
                    { organizationCode: $scope.organization.jarCode, certificateType: "NEDP" },
                    function (number) {
                        $scope.incapacityCertificate.number = number.number;
                        $scope.incapacityCertificate.series = number.series;
                    }
                );

                var insurance = EptsCertificatesApiFactory.getInsuranceInformation(
                    { personalCode: $scope.patientData.personalCode, organizationCode: $scope.organization.jarCode },
                    function (insurance) {
                        $scope.incapacityCertificate.insurance = insurance;
                    }
                );

                var history = EptsCertificatesApiFactory.getIncapacityCertificateHistory(
                    {
                        personalCode: $scope.patientData.personalCode,
                        birthDate: $scope.patientData.birthDate,
                        organizationCode: $scope.organization.jarCode,
                    },
                    function (data) {
                        $scope.certificateHistoryData = data;

                        if (data.latestIncapacityStartDate) {
                            $scope.incapacityCertificate.totalIncapacityTime = $scope.getDayCount(
                                data.latestIncapacityStartDate,
                                data.latestIncapacityEtartDate,
                                false
                            );
                        }

                        if (data.existingIncapacityCertificateEndDate) {
                            $scope.incapacityCertificate.startDate = data.existingIncapacityCertificateEndDate + 1;
                        }
                    }
                );

                FormLoaderService.endFormLoading($q.all([number.$promise, insurance.$promise, history.$promise]));
            })
            .catch(function (error) {
                // Error callback
                console.error("Request failed:", error);
                FormLoaderService.endFormLoading();
            });

        $scope.errors = {
            diagnosis: false,
            caringPersonFamilyName: false,
            caringPersonBirthDate: false,
            inpatientPeriodStartDateRequired: false,
            inpatientPeriodEndDateRequired: false,
        };

        $scope.$watch("incapacityCertificate.reason", function (newValue, oldValue) {
            if (newValue && oldValue && newValue.code != oldValue.code) {
                if (newValue.code != "6") {
                    // Accident
                    $scope.incapacityCertificate.accidentInformation = {
                        date: null,
                    };
                    $scope.additionalParameters.enterAccidentInformation = false;
                }
                if (newValue.code != "5") {
                    // Occupational disease
                    $scope.incapacityCertificate.occupationalDiseaseInformation = {
                        date: null,
                        actNumber: null,
                    };
                    $scope.additionalParameters.enterOccupationalDiseaseInformation = false;
                }
            }
        });

        // Modal window for HCP conclusion info
        // <=============================>
        var hcpConclusionInfoModal = $modal({
            scope: $scope,
            contentTemplate: "/templates/portal/doctor/patient/certificates/incapacity/hcpconclusion_modal.html",
            backdrop: "static",
            keyboard: false,
        });

        $scope.hcpConclusionModalWindowCancelClick = function () {
            hcpConclusionInfoModal.hide();
        };

        $scope.hcpConclusionModalWindowSubmitClick = function () {
            $scope.hcpConclusionInfoModalData.badNumber = !$scope.hcpConclusionCopy.number;
            $scope.hcpConclusionInfoModalData.badDate = !$scope.hcpConclusionCopy.date;

            if (!$scope.hcpConclusionInfoModalData.badDate && !$scope.hcpConclusionInfoModalData.badNumber) {
                var messageRegion = "certificate-data-region";
                MessageService.clearAll();

                // Form full HCP conclusion
                var hcpConclusion = {
                    date: $scope.hcpConclusionCopy.date,
                    number: $scope.hcpConclusionCopy.number,
                    type: $scope.hcpConclusionCopy.type,
                    chairman: $scope.practitioner,
                    organization: $scope.organization,
                    person: $scope.patientData,
                    certificateSeries: $scope.incapacityCertificate.series,
                    certificateNumber: $scope.incapacityCertificate.number,
                };

                // Register conclusion
                EptsCertificatesApiFactory.registerHcpConclusion(hcpConclusion).$promise.then(
                    function (conclusion) {
                        // Assign needed information
                        $scope.incapacityCertificate.hcpConclusion = conclusion;

                        hcpConclusionInfoModal.hide();

                        // Open HCP conclusion signing window
                        $window.open(conclusion.signingUrl);

                        // Update history data
                        EptsCertificatesApiFactory.getIncapacityCertificateHistory(
                            {
                                personalCode: $scope.patientData.personalCode,
                                birthDate: $scope.patientData.birthDate,
                                organizationCode: $scope.organization.jarCode,
                            },
                            function (data) {
                                $scope.certificateHistoryData = data;
                            }
                        );
                    },

                    function () {
                        MessageService.add("error", $filter("translate")("err.incapcity.register_hcp"), messageRegion);
                    }
                );

                hcpConclusionInfoModal.hide();
            }
        };

        $scope.addHcpConclusion = function () {
            $scope.hcpConclusionCopy.date = $scope.incapacityCertificate.hcpConclusion.date;
            $scope.hcpConclusionCopy.number = $scope.incapacityCertificate.hcpConclusion.number;
            $scope.hcpConclusionCopy.type = $scope.incapacityCertificate.hcpConclusion.type;

            hcpConclusionInfoModal.show();
        };

        $scope.removeHcpConclusion = function () {
            $scope.incapacityCertificate.hcpConclusion.date = null;
            $scope.incapacityCertificate.hcpConclusion.number = null;
            $scope.incapacityCertificate.hcpConclusion.type = $scope.hcpConclusionTypes[0];
        };

        $scope.hclConclusionFormHasRequiredFieldError = function (field) {
            return (
                hcpConclusionInfoModal.$scope.hcpConclusionForm[field].$dirty &&
                hcpConclusionInfoModal.$scope.hcpConclusionForm[field].$error["required"]
            );
        };

        $scope.hcpConclusionInfoModalData = {
            badDate: false,
            badNumber: false,
        };

        $scope.hcpConclusionCopy = {
            date: null,
            number: null,
            type: null,
        };
        // <=============================>

        // Caring person search
        $scope.findCaringPerson = function () {
            var region = "caring-person-data-region";

            MessageService.clear(region);

            if ("spc" == $scope.additionalParameters.searchForCaringPerson) {
                // Search by personal code

                if (!$scope.additionalParameters.caringPersonPersonalCode) {
                    MessageService.add("error", $filter("translate")("err.no_person_code"), region);
                    return;
                }

                PatientsApiFactory.getByPersonalOrEsiNr({
                    personalCode: $scope.additionalParameters.caringPersonPersonalCode,
                }).$promise.then(
                    function (patient) {
                        $scope.incapacityCertificate.caringPerson = patient;
                    },

                    function () {
                        $scope.incapacityCertificate.caringPerson = null;
                        MessageService.add("error", $filter("translate")("err.person_data_not_found"), region);
                    }
                );
            } else {
                //	Search by NHS code

                if (!$scope.additionalParameters.caringPersonNhsCode) {
                    MessageService.add("error", $filter("translate")("err.no_nhs_code"), region);
                    return;
                }

                PatientsApiFactory.getByPersonalOrEsiNr({
                    esi: $scope.additionalParameters.caringPersonNhsCode,
                }).$promise.then(
                    function (patient) {
                        $scope.incapacityCertificate.caringPerson = patient;
                    },

                    function () {
                        $scope.incapacityCertificate.caringPerson = null;
                        MessageService.add("error", $filter("translate")("err.person_data_not_found"), region);
                    }
                );
            }
        };

        // Data validation and certificate registration
        $scope.check = function () {
            // TODO refactor this and get rid of manual validation

            $scope.incapacityForm.submitted = true;

            // Clear all errors
            var caringPersonRegion = "caring-person-data-region";
            MessageService.clear();

            $scope.errors.diagnosis = false;
            $scope.errors.caringPersonFamilyName = false;
            $scope.errors.caringPersonBirthDate = false;
            $scope.errors.inpatientPeriodStartDateRequired = false;
            $scope.errors.inpatientPeriodEndDateRequired = false;

            var hasErrors = false;

            // Check insurance
            if (!$scope.incapacityCertificate.insurance) {
                MessageService.error($filter("translate")("err.epts.ins.req"));
                hasErrors = true;
            }

            // Check inpatient period
            if ($scope.incapacityCertificate.inpatient) {
                if ($scope.incapacityCertificate.inpatientPeriodEndDate == null) {
                    hasErrors = true;
                    $scope.errors.inpatientPeriodEndDateRequired = true;
                }

                if ($scope.incapacityCertificate.inpatientPeriodStartDate == null) {
                    hasErrors = true;
                    $scope.errors.inpatientPeriodStartDateRequired = true;
                }
            }

            // Check if incapacity period is correct (one check wasn't made before)
            if (
                $scope.incapacityCertificate.endDate &&
                $scope.incapacityCertificate.endDate < $scope.incapacityCertificate.startDate
            ) {
                hasErrors = true;
            }

            // Check diagnosis
            if (!$scope.incapacityCertificate.diagnosis) {
                $scope.errors.diagnosis = true;
                hasErrors = true;
            }

            // Check caring person
            if ($scope.hasCaringPerson()) {
                if ($scope.incapacityCertificate.caringPerson == null) {
                    MessageService.add("error", $filter("translate")("err.required"), caringPersonRegion);
                    hasErrors = true;
                }

                if ($scope.additionalParameters.searchForCaringPerson == "enter") {
                    if ($scope.incapacityCertificate.caringPerson.familyName == null) {
                        $scope.errors.caringPersonFamilyName = true;
                        hasErrors = true;
                    }

                    if ($scope.incapacityCertificate.caringPerson.birthDate == null) {
                        $scope.errors.caringPersonBirthDate = true;
                        hasErrors = true;
                    }
                }
            }

            if ($scope.incapacityForm.$invalid || hasErrors) {
                console.log(hasErrors);
                console.log($scope.incapacityForm);
                console.log($scope.errors);
                MessageService.add("error", "Formoje yra klaidų");
                return;
            }

            // Build actual certificate object
            var certificate: any = {
                documentId: $scope.incapacityCertificate.documentId,
                number: $scope.incapacityCertificate.number,
                series: $scope.incapacityCertificate.series,
                inpatient: $scope.incapacityCertificate.inpatient,
                insurance: $scope.incapacityCertificate.insurance,
                reason: $scope.incapacityCertificate.reason,
                diagnosis: $scope.incapacityCertificate.diagnosis,
                caringPerson: $scope.incapacityCertificate.caringPerson,

                inpatientPeriodStartDate: $scope.incapacityCertificate.inpatientPeriodStartDate,
                inpatientPeriodEndDate: $scope.incapacityCertificate.inpatientPeriodEndDate,

                startDate: $scope.incapacityCertificate.startDate,
                endDate: $scope.incapacityCertificate.endDate,

                organization: $scope.organization,
                practitioner: $scope.practitioner,
                person: $scope.patientData,
            };

            // Fix for person address
            if (certificate.person.residenceAddress) {
                certificate.person.address = certificate.person.residenceAddress.text;
            }

            // Fix for person phone
            if (certificate.person.phones && certificate.person.phones.length > 0) {
                certificate.person.phone = certificate.person.phones[0];
            }

            if ($scope.incapacityCertificate.hcpConclusion.date && !$scope.hasCaringPerson()) {
                certificate.hcpConclusion = $scope.incapacityCertificate.hcpConclusion;
            } else {
                certificate.hcpConclusion = null;
            }

            if ($scope.additionalParameters.enterAccidentInformation) {
                certificate.accidentInformation = $scope.incapacityCertificate.accidentInformation;
            }

            if ($scope.additionalParameters.enterBehaviourRulesViolationInformation) {
                certificate.behaviourRulesViolationInformation =
                    $scope.incapacityCertificate.behaviourRulesViolationInformation;
            }

            if ($scope.additionalParameters.enterOccupationalDiseaseInformation) {
                certificate.occupationalDiseaseInformation =
                    $scope.incapacityCertificate.occupationalDiseaseInformation;
            }

            if ($scope.additionalParameters.enterRehabInformation) {
                certificate.rehabInformation = $scope.incapacityCertificate.rehabInformation;
            }

            // Register certificate
            MessageService.clearAll();
            DocumentSpinnerHelper.startLoading();
            EptsCertificatesApiFactory.registerIncapacityCertificate(certificate).$promise.then(
                function (registrationResult) {
                    DocumentSpinnerHelper.endLoading();
                    // Open view page
                    $location.path(
                        "/dp/patients/" +
                            $routeParams.patientId +
                            "/certificates/incapacity/" +
                            $scope.incapacityCertificate.number +
                            "/view"
                    );
                },
                function (result) {
                    DocumentSpinnerHelper.endLoading();
                    _.each(result.data.errors, function (i) {
                        MessageService.error(i);
                    });
                    _.each(result.data.fieldErrors, function (i) {
                        var errorMessage = i.propertyPath + ": " + i.msg;
                        MessageService.error(errorMessage);
                    });
                    _.each(result.data.globalErrors, function (i) {
                        MessageService.error(i.msg);
                    });
                    $window.scrollTo(0, 10);
                }
            );
        };

        // Clear form
        $scope.clear = function () {
            $modalConfirm
                .show({
                    title: $filter("translate")("inf.clean.form.tit"),
                    content: $filter("translate")("inf.clean.form"),
                })
                .then(function () {
                    $routeSegment.chain[$routeSegment.chain.length - 1].reload();
                });
        };

        // Get caring person main information
        $scope.getCaringPersonMainInfo = function () {
            var info = "";
            var caringPerson = $scope.incapacityCertificate.caringPerson;

            if (!caringPerson) {
                return info;
            }

            if (caringPerson.familyName) {
                info = _.getFullName(caringPerson);
            } else {
                info = $scope.incapacityCertificate.caringPerson.esiNr;
            }

            if (caringPerson.birthDate) {
                info += ", " + $filter("date")(caringPerson.birthDate, "yyyy-MM-dd");
            }

            return info;
        };

        // Get caring person additional info
        $scope.getCaringPersonAdditionalInfo = function () {
            var info = "";
            var caringPerson = $scope.incapacityCertificate.caringPerson;

            if (!caringPerson) {
                return info;
            }

            if (caringPerson.personalCode) {
                info += $filter("translate")("perCode") + " " + caringPerson.personalCode;
                if (caringPerson.familyName) {
                    info += ", " + $filter("translate")("esiNr") + " " + caringPerson.esiNr;
                }
            }

            return info;
        };

        // Returns "true" if required field is empty
        $scope.hasRequiredFieldError = function (field) {
            return $scope.incapacityForm.submitted && $scope.incapacityForm[field].$error["required"];
        };

        // Clear caring person data
        $scope.clearCaringPerson = function () {
            MessageService.clear("caring-person-data-region");
            if ($scope.additionalParameters.searchForCaringPerson == "enter") {
                $scope.incapacityCertificate.caringPerson = {
                    givenName: null,
                    familyName: null,
                    birthDate: null,
                };
            } else {
                $scope.incapacityCertificate.caringPerson = null;
            }
        };

        // Get day count between two dates
        $scope.getDayCount = function (start, end) {
            if (!_.exists(start) || _.isBlank(start) || !_.exists(end) || _.isBlank(end) || end < start) {
                return "0";
            }
            return Math.floor((end - start) / 86400000) + 1;
        };

        // Returns "true" if caring person info needs to be displayed
        $scope.hasCaringPerson = function () {
            if ($scope.incapacityCertificate.reason) {
                return $scope.incapacityCertificate.reason.code == 4 || $scope.incapacityCertificate.reason.code == 8;
            }

            return false;
        };

        $scope.getHcpConclusionDescription = function (code) {
            var description = "";

            _.each($scope.hcpConclusionTypes, function (conclusion) {
                if (conclusion.code == code) {
                    description = conclusion.name;
                }
            });

            return description;
        };
    },
]);
