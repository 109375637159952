<app-title [backSubtitle]="'pp.dia.sea.title' | translate">
    {{"NGN dokumentai" | translate}}
    <aside>
        <app-button
            btnClass="btn btn-secondary"
            icon="fa fa-filter"
            toggleIcon="fa fa-times-circle"
            [toggle]="true"
            [toggleText]="'pp.dia.sea.filtCol' | translate"
            (btnClick)="btnPressed = $event"
            >{{ "pp.dia.sea.filt" | translate }}</app-button
        >
    </aside>
</app-title>

<app-pregnancy-dashboard-list-filter
    [showFilters]="btnPressed"
    (filtering)="updateFilters($event)"
></app-pregnancy-dashboard-list-filter>

<div class="widget p-0">
    <div class="widget-body" [ngClass]="(ngnDocuments$ | async)?.length ? 'p-0' : ''">
        <ol *ngIf="(ngnDocuments$ | async)?.length" class="slats hover">
            <li
                slat-item custom edit
                [customIcon]="'fa-file'"
                (customButtonClick)="viewDocument(document.id, document.docType)"
                (editButtonClick)="editDocument(document.id, document.docType)"
                *ngFor="let document of ngnDocuments$ | async; let i = index">
                <div class="d-flex align-content-center column-gap-3 flex-column flex-md-row">
                    <div class="text-nowrap text-secondary">
                        {{ document.date | date : "yyyy-MM-dd" }}
                    </div>
                    <div class="flex-grow-1">
                        <div class="justify-content-between">
                            <app-doc-status-icon
                                class="d-inline-flex me-1"
                                [status]="record?.documentStatus"
                            ></app-doc-status-icon>
                            <strong>{{ record?.title }}</strong>
                        </div>
                        <div class="small text-secondary">
                            <div *ngIf="document?.conditions?.length" class="description">
                                {{ document.conditions }}
                            </div>
                            <div>
                                <a href="/dp/specialist/{{ record?.specialist?.id }}"
                                    >{{ document?.specialist?.givenName }} {{ document?.specialist?.familyName }}</a
                                >
                                <span class="vr"></span>
                                <span>{{ document?.specialist?.speciality?.name }}</span>
                                <span class="vr"></span>
                                <span>{{ document?.specialist?.practitionerOrganization?.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ol>
        <ng-container *ngIf="!(ngnDocuments$ | async)?.length">
            <span>{{ "Nėra duomenų" | translate }}</span>
        </ng-container>
        <spinner [working]="!isLoaded"></spinner>
    </div>
</div>

<pagination-bar
    #paginationBar
    [rowsCount]="total"
    [pagesCount]="pagesCount"
    (paginating)="updatePagination($event)"
></pagination-bar>
