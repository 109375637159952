<label [for]="inputId" *ngIf="labelText" [class]="labelClass?.includes('col-form-label') ? 'flex-grow-1 ' + (labelClass ?? '') : 'flex-grow-1 form-label ' + (labelClass ?? '')">
    {{ labelText | translate }}
</label>
<div>
    <div class="input-group {{inputGroupClass}}">
        <input
            [id]="inputId"
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            [class]="dateClass"
            bsDatepicker
            [minMode]="minMode"
            [bsConfig]="dpConfig"
            [formControl]="fControl"
            [ngClass]="{ 'is-invalid': fControl?.invalid && fControl.touched }"
            (bsValueChange)="onValueChange($event)"
            [minDate]="minDate"
            [maxDate]="maxDate"
            (ngKeydown)="onInputKeypress($event)"
        />
        <span *ngIf="suffixIcon" class="input-group-text{{suffixClass ? ' ' + suffixClass : ''}}"><i [class]="suffixIcon"></i></span>
        <span *ngIf="suffixIcon === undefined" class="input-group-text"><i class="fa far fa-calendar"></i></span>
    </div>
    <app-form-control-validation *ngIf="showErrors" [fControl]="fControl" />
</div>
