(function () {
    ng1App.factory('EncounterService', ['EncountersApiFactory', 'MessageService', EncounterService]);

    function EncounterService(EncountersApiFactory, MessageService) {

        function closeEncounter(id, onSuccess, onError){
            EncountersApiFactory.closeEncounter({"id": id}, null,
                onSuccess,
                function(err){
                    if (err.status == 500) {
                        MessageService.handleError(err.data, MessageService);
                    } else if (err.status == 400) {
                        _.each(err.data.globalErrors, function (i) {
                            if (i.metadata && i.metadata.detailedCode == '127.NO_ACTIVE_DOCUMENTS') {
                                MessageService.error('Apsilankymo uždaryti nepavyko, nes apsilankymas neturi aktyvių dokumentų.');
                            } else if (i.metadata && i.metadata.detailedCode == '127.HAS_ACTIVE_DOCUMENTS') {
                                MessageService.error('Apsilankymo atšaukti nepavyko, nes apsilankymas turi aktyvių dokumentų.');
                            } else {
                                MessageService.error(i.msg);
                            }
                        });
                    }
                    onError(err);
                });
        }

        return {
            closeEncounter: closeEncounter
        };

    }
})();
