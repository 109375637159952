import {Component, Input, OnInit} from "@angular/core";
import {PractitionerModel} from "../../../shared/models/practitioner.model";
import {OrganizationModel} from "../../../shared/models/organization.model";
import _ from "underscore";

@Component({
    selector: 'encounter-info',
    templateUrl: './encounter-info.component.html'
})
export class EncounterInfoComponent implements OnInit {

    protected readonly _ = _;
    @Input() practitioner: PractitionerModel;
    @Input() organization: OrganizationModel;

    constructor() {

    }

    ngOnInit(): void {

    }
}