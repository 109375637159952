import { Component, Input } from '@angular/core';
import { E010_Row } from '../e010-forms.model';

@Component({
    selector: 'app-e010-form-mother',
    templateUrl: './e010-form-mother.component.html',
})
export class E010FormMotherComponent {
    @Input() set data(value: {[key: string]: any}) {
        if(!value) return;
        this.motherData = [
            {
                name: 'Z63.0',
                value: this.getZ63_0(value.e113Nd.anamnesis.familyAnamnesis)
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formMother.bloodGroup', //Rh (D) factor,
                value: /[+-]/.exec(value.e113Tp.researches.filter(obj => obj.hasOwnProperty('pregnantBloodType'))[0]?.pregnantBloodType)[0] || 'N.A.'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formMother.fluVaccines', // Vaccines from the flu
                value: this.fluDiseaseCodes.includes(value.e063?.order?.item?.vacData?.recommendation?.disease?.code) ? 'Skiepyta' : 'Neskiepyta'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formMother.kokliusVaccines', // Pertussis vaccines
                value: value.e063?.order?.item?.vacData?.recommendation?.disease?.code === '78208' ? 'Skiepyta' : 'Neskiepyta'
            },
            {
                name: 'doc.pregnancyDashboard.e010_1_1.formMother.lookedAfterPregantWoman', // He looked after the pregnant woman
                value: value.e113Nd.pregnancyInfo?.carryBy || '-'
            }
        ]
    };

    private fluDiseaseCodes = ['85805', '85810', '85768', '85805', '85773', '85772', '85809', '85779', '85790', '85779', '85793']

    motherData: E010_Row[];

    constructor() {}

    private getZ63_0(data: {name: string, checked: boolean}[]): string {
        const z63Data = data.find(item => item.name === 'Z63.0');
        switch (z63Data?.checked) {
            case true:
                return 'Taip';
            case false:
                return 'Ne';
            default:
                return 'N.A.'

        }
    }
}
