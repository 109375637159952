import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-breath-system-form',
    templateUrl: './sbvf-breath-system-form.component.html',
})
export class SbvfBreathSystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        if (value) {
            this.formGroup.disable();
        }
    };

    breathSystemRows: PatientHealthAssessmentsFormRow[] = [];
    formGroup = this.fb.group({
        breathing: this.fb.control(null, [Validators.required]),
        wheezing: this.fb.control(null, [Validators.required]),
        facialCyanosis: this.fb.control(null, [Validators.required]),
        cough: this.fb.control(null, [Validators.required]),
        onsetOfCough: this.fb.control(null, [Validators.required]),
        thereIsCough: this.fb.control(null, [Validators.required]),
        painWhenCough: this.fb.control(null, [Validators.required]),
        typeOfCough: this.fb.control(null, [Validators.required]),
        coughTime: this.fb.control(null, [Validators.required]),
        shortnessBreath: this.fb.control(null, [Validators.required]),
        onsetShortnessBreath: this.fb.control(null, [Validators.required]),
        shortnessBreathBothersome: this.fb.control(null, [Validators.required]),
        chestPain: this.fb.control(null, [Validators.required]),
        breathingRate: this.fb.control(null, [Validators.required]),
        phlegm: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });
    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.breathSystemRows = [
            {
                title: 'Kvėpuoja',
                type: 'choise',
                options: value['breathing'],
                formControl: this.formGroup.get('breathing')
            },
            {
                title: 'Švokštimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('wheezing')
            },
            {
                title: 'Veido cianozė',
                type: 'choise',
                options: value['nasalDischarge'],
                formControl: this.formGroup.get('facialCyanosis')
            },
            {
                title: 'Kosulys',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('cough')
            },
            {
                title: 'Kosulio pradžia',
                type: 'date',
                formControl: this.formGroup.get('onsetOfCough')
            },
            {
                title: 'Kosulys būna',
                type: 'choise',
                options: value['cough'],
                formControl: this.formGroup.get('thereIsCough')
            },
            {
                title: 'Skausmas kosint',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('painWhenCough')
            },
            {
                title: 'Kosulio tipas',
                type: 'choise',
                options: value['coughType'],
                formControl: this.formGroup.get('typeOfCough')
            },
            {
                title: 'Kosulio laikas',
                type: 'choise',
                options: value['coughTime'],
                formControl: this.formGroup.get('coughTime')
            },
            {
                title: 'Dusulys',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('shortnessBreath')
            },
            {
                title: 'Dusulio pradžia',
                type: 'date',
                formControl: this.formGroup.get('onsetShortnessBreath')
            },
            {
                title: 'Dusulys varginantis',
                type: 'choise',
                options: value['yesNo'],
                formControl: this.formGroup.get('shortnessBreathBothersome')
            },
            {
                title: 'Krūtinė skausmas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('chestPain')
            },
            {
                title: 'Kvėpavimo dažnis',
                type: 'text',
                formControl: this.formGroup.get('breathingRate'),
                textInputLabel: 'k./min.'
            },
            {
                title: 'Skrepliai',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('phlegm')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
