angular.module('erx').directive('erxGeneralDspInfo', function () {
  return {
    restrict: 'E',
    scope: {
      patient: '=',
      practitioner: '=',
      organization: '=',
      pickedUpBy: '=',
      selectedQualification: '='
    },
    replace: false,
    templateUrl: 'modules/erx/prs/general_info/general_dsp_info.html',
    controller: ["$scope", "$erxHelpers", function ($scope, $erxHelpers) {
      $scope.h = $erxHelpers;

      $scope.getCurrentDate = function () {
        return (new Date()).getTime();
      };

      $scope.loading = function () {
        return false;
      };
    }]
  };
});
