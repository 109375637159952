import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../models/entity.model';
import {
    getControlValue,
    listenAndChangeStatusOfControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiV2Practitioners } from '../../../../../api/api-v2-practitioners';
import { map, Observable } from 'rxjs';
import { PractitionerModel } from '../../../models/practitioner.model';
@Component({
    selector: "app-inr-doctor-informing-subform",
    templateUrl: "./inr-doctor-informing-subform.component.html",
})
export class InrDoctorInformingSubformComponent {
    @Input() editable: boolean = true;

    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];

    formGroup: FormGroup;

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    getSpecialistsObs = this.getSpecialists.bind(this);
    constructor(
        private fb: FormBuilder,
        private apiV2Practitioners: ApiV2Practitioners
    ) {
        this.formGroup = this.fb.group({
            informedDoctor: this.fb.control(null),
            fullName: this.fb.control(null, [Validators.required]),
            phone: this.fb.control(null),
            email: this.fb.control(null),
            doctorInstitution: this.fb.control(null),
        });

        this.changeStatusOfControl({
            controlNameForListen: 'informedDoctor',
            controlNameForUpdate: ['fullName', 'phone', 'email', 'doctorInstitution'],
            enableValue: 'Taip',
        }).pipe(takeUntilDestroyed())
            .subscribe();
    }

    getSpecialists(query: string): Observable<any> {
        return this.apiV2Practitioners.find({q: query}).pipe(
            map(item => item.items
                .map(p => {
                    const qualification = this.getSpecialistQualifications(p);
                    p['fullDisplay'] = `${p.givenName} ${p.familyName}, ${qualification}, ${p.personalCode}`;
                    return p;
                })
            ))
    }

    getSpecialistQualifications(specialist: PractitionerModel): string {
        return Object.values(specialist?.qualificationList)?.map(q => q?.name)?.join(',');
    }

    selectDoctor(doctor: any) {
        this.formGroup.patchValue({
            fullName: `${doctor.givenName} ${doctor.familyName}`,
            doctorInstitution: doctor.practitionerOrganization?.name
        });
    }
}
