import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { isArray } from "angular";
import { CardTitles } from "./dashboard-card.constants";
import { DashboardCardActions } from "./dashboard-card.model";

@Component({
    selector: "dashboard-card",
    templateUrl: "./dashboard-card.component.html",
})
export class DashboardCardComponent {
    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() data: any;
    @Input() patientId: string;
    @Input() closable: boolean = true;
    @Input() closed: boolean = false;
    @Input() showIcon: boolean = false;
    @Input() iconClass: string;
    @Input() iconBgClass: string;
    @Input() buttons: DashboardCardActions[];

    public isArray = isArray;
    public CardTitles = CardTitles;

    constructor(private router: Router) {}

    public showDocument(docId: string) {
        this.router.navigate([`dp/patients/${this.patientId}/documents/${docId}`]);
    }

    public showMedicationName(prs: any): boolean {
        return (prs.prescriptionTagsNominalTag || prs.status === "completed") && prs.medication?.name !== undefined;
    }

    public navigate(path: string) {
        this.router.navigate([path]);
    }

    public accept() {
        console.log("accept");
    }

    public decline() {
        console.log("decline");
    }
}
