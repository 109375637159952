import angular from "angular";
import 'angular-gettext';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';

import 'angular-ui-utils/modules/alias/alias';
import 'angular-ui-utils/modules/event/event';
import 'angular-ui-utils/modules/format/format';
import 'angular-ui-utils/modules/highlight/highlight';
import 'angular-ui-utils/modules/ie-shiv/ie-shiv';
import 'angular-ui-utils/modules/include/include';
import 'angular-ui-utils/modules/indeterminate/indeterminate';
import 'angular-ui-utils/modules/inflector/inflector';
import 'angular-ui-utils/modules/jq/jq';
import 'angular-ui-utils/modules/keypress/keypress';
import 'angular-ui-utils/modules/mask/mask';
import 'angular-ui-utils/modules/reset/reset';
import 'angular-ui-utils/modules/route/route';
import 'angular-ui-utils/modules/scroll/scroll';
import 'angular-ui-utils/modules/scroll/scroll-jqlite';
import 'angular-ui-utils/modules/scrollfix/scrollfix';
import 'angular-ui-utils/modules/showhide/showhide';
import 'angular-ui-utils/modules/unique/unique';
import 'angular-ui-utils/modules/validate/validate';
import 'angular-ui-utils/modules/utils';

import 'angular-strap';

// make sure restore jQuery after strap import
import * as jq from "jquery";
// overrides global $ once again, because of angular-strap lib override causing undefined
$ = jq.noConflict();

import erxPrsModule from "./prs";
import erxApiModule from "../api";
import erxUtilsModule from "../erx-utils";

// Šakninis eRecepto modulis
export const erxModule: angular.IModule = angular.module('erx', [
    erxPrsModule.name,
    erxApiModule.name,
    erxUtilsModule.name,
    ngAnimate,
    'mgcrea.ngStrap',
    'ui.utils',
    ngCookies,
    'gettext'
]);

export default erxModule;