<app-title [backPath]="backPath">
    {{'Kitas dokumentas'| translate}}
</app-title>
<div *ngIf="docAttachedUnsorted" class="row" style="margin-top: 25px;">
    <!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="false" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
        (valueChange)="onChanges($event)"></app-common-patient-widget>
    <nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="false"
        [mixed]="true" [title]="'doc-ref-attached-unsorted.component.view'|translate">
        <div class="widget-body">
            <form [formGroup]="unsortedForm" (ngSubmit)="send(unsortedForm.value)">
                <div class="row mb-2">
                    <div for="templateTitle" class="col-sm-3 fw-bold text-sm-start text-md-end">
                        Dokumento įkėlimo data
                    </div>
                    <div class="col-sm-9">
                        {{docAttachedUnsorted.date | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="category" class="col-sm-3 fw-bold text-sm-start text-md-end">Dokumento tipas
                    </label>
                    <div class="col-sm-9">
                        {{docAttachedUnsorted.unsorted_document_type}}
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="templateTitle" class="col-sm-3 fw-bold text-sm-start text-md-end">Dokumento
                        aprašymas</label>
                    <div class="col-sm-9">
                        {{docAttachedUnsorted.desc}}
                    </div>
                </div>
                <div class="row mb-2" *ngFor="let item of docAttachedUnsorted.recipient; let i=index">
                    <label *ngIf="i == 0" for="templateTitle"
                        class="col-sm-3 fw-bold text-sm-start text-md-end">Gavėjo įstaiga</label>
                    <label *ngIf="i > 0" for="templateTitle"
                        class="col-sm-3 fw-bold text-sm-start text-md-end"></label>
                    <div class="col-sm-9">
                        {{i+1}}. {{item.name | json}}, JAR:{{item.jarCode | json}}
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="templateTitle" class="col-sm-3 fw-bold text-sm-start text-md-end">Dokumento
                        kodas</label>
                    <div class="col-sm-9">
                        {{docAttachedUnsorted.masterIdentifier}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="offset-sm-3 col-sm-9">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="visibility" value="invisible"
                                id="unremovableDoc" formControlName="deleteStatus" />
                            <label class="form-check-label" for="unremovableDoc">
                                Dokumentas netrinamas
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <!--                        <label class="col-sm-3 fw-bold text-sm-start text-md-end" for="fileInput"></label>-->
                    <div class="col-sm-3"></div>
                    <div class="col-sm-9">
                        <!-- <button class='btn btn-secondary btn-sm' type="button" (click)="fileInput.click()" *ngIf="!fileSelected">
                            <span translate>AddFile</span>
                            <input #fileInput type="file" class="me-2" id="fileInput" (change)="onFileSelected($event)" style="display:none;" />
                        </button>
                        <div id="fileName" *ngIf="fileSelected">
                            {{ fileName }}
                            <button class='btn btn-secondary btn-sm' (click)="onFileRemove()" *ngIf="fileSelected">Remove</button>
                        </div> -->
                        <a href="#" (click)="openAttachedDocument()"
                            download="doc_{{docAttachedUnsorted.id}}">{{"PDF Dokumentas" | translate}}</a>
                    </div>
                </div>
                <input *ngIf="canEdit" class='btn btn-secondary btn-sm' type="submit">
            </form>
            <!-- <pre class="card card-block card-header">Model: {{unsortedForm.value.state | json}}</pre> -->
        </div>
    </nhr-record-widget>
    <!-- <app-modification-actions></app-modification-actions> -->
</div>
<undo-actions (undo)="undoClick()"></undo-actions>
