ng1App.controller('doctor.patient.070alkEditCtrl', 
    ['$scope', '$window', '$routeParams', 'MessageService', 'DocumentSpinnerHelper', 'SveidraApiFactory', 'EntitiesApiFactory', '$routeSegment', '$modalConfirm', '$filter', 'TypeaheadSearchHandlers', 'SveidraCommons', '$location', 'FormLoaderService', '$q',
     function ($scope, $window, $routeParams, MessageService, DocumentSpinnerHelper,
                                                             SveidraApiFactory, EntitiesApiFactory, $routeSegment, $modalConfirm,
                                                             $filter, TypeaheadSearchHandlers, SveidraCommons, $location,
                                                             FormLoaderService, $q) {
    $scope.goBack = function(){
        $window.history.back();
    };
    
    FormLoaderService.startFormLoading();
    
    $scope.$emit('setHelpPage', 'tvarkyti-pazymos-duomenis');
    
    MessageService.clearAll();
	
	$scope.mode = $routeParams.formId ? 'edit' : 'new';
	$scope.formId = $routeParams.formId;
	
	$scope.loadingSpinnerHelper = DocumentSpinnerHelper;
	
	if ($scope.mode == 'edit') {
		$scope.form = SveidraApiFactory.getFormForEdit({id: $scope.formId});
	} else {
		$scope.form = SveidraApiFactory.getPrefilledForm({patientId: $routeParams.patientId, type: 'FORM_070', documentId: $routeParams.documentId});
	}
	
	$scope.additional = {
		diagnosisSearch: null,
		
		fillAccompanyingPerson: false
	};
	
	// Load classifiers
	$scope.certificateTypes = EntitiesApiFactory.getList({classCode: 'f070alk-certificate-type'});
	$scope.accompanyingPersonReasons = EntitiesApiFactory.getList({classCode: 'f070alk-accomp-person-reason'});
	
	FormLoaderService.endFormLoading($q.all([$scope.form.$promise, $scope.certificateTypes.$promise, $scope.accompanyingPersonReasons.$promise]));
	
	// Perform actions after form is loaded
	$scope.form.$promise.then(function () {
		$scope.patientData = $scope.form.patient;
		$scope.authorData = $scope.form.cardRegistrator;
		$scope.organizationData = $scope.form.organization;
		$scope.responsiblePersonData = $scope.form.author;
		
		// Resolve certificate type
		if ($scope.form.certificateType) {
			$scope.certificateTypes.$promise.then(function () {
				for (var i = 0; i < $scope.certificateTypes.length; i++) {
					if ($scope.form.certificateType.code == $scope.certificateTypes[i].code) {
						$scope.form.certificateType = $scope.certificateTypes[i];
					}
				}
			});
		}
		
		// Fill diagnosis search input
		$scope.additional.diagnosisSearch = _.getCodeName($scope.form.diagnosis);
		
		// Resolve accompanying person
		if ($scope.form.accompanyingPersonReason) {
			$scope.accompanyingPersonReasons.$promise.then(function () {
				for (var i = 0; i < $scope.accompanyingPersonReasons.length; i++) {
					if ($scope.form.accompanyingPersonReason.code == $scope.accompanyingPersonReasons[i].code) {
						$scope.form.accompanyingPersonReason = $scope.accompanyingPersonReasons[i];
					}
				}
			});
			$scope.additional.fillAccompanyingPerson = true;
		}
	});
	
	// Type ahead
	$scope.searchForDiagnosis = TypeaheadSearchHandlers.ICD10;
	
	$scope.changeDiagnosis = function (diagnosis) {
		if (diagnosis) {
			if (diagnosis.code) {
				$scope.form.diagnosis = diagnosis;
			}
		} else {
			$scope.form.diagnosis = null;
		}
	};
	
	$scope.fillAccompanyingPersonChange = function () {
		if (!$scope.additional.fillAccompanyingPerson) {
			$scope.form.accompanyingPersonReason = null;
		}
	};
	
	
	$scope.save = function (transfer) {
		$scope.certificateForm.submitted = true;
		if (!validate()) {
			_.scrollToFirstInvalid();
			return;
		}
		
		$scope.form.transferred = transfer;
		
		$scope.loadingSpinnerHelper.startLoading();
		if ($scope.mode == 'edit') {
			SveidraApiFactory.updateForm({id: $scope.formId}, $scope.form, saveSuccessHandler, saveErrorHandler);
		} else {
			SveidraApiFactory.createForm({type: 'FORM_070'}, $scope.form, saveSuccessHandler, saveErrorHandler);
		}
	};
	
	var validate = function () {
		if ($scope.certificateForm.$invalid) {
			return false;
		}
		if (!$scope.form.diagnosis) {
			return false;
		}
		if (!$scope.form.medicalRehabilitation.serviceType) {
			return false;
		}
		if (!$scope.form.medicalRehabilitation.profile) {
			return false;
		}
		if ($scope.form.medicalRehabilitation.workingCapacity && !$scope.form.medicalRehabilitation.workingCapacityDate) {
			return false;
		}
		if ($scope.form.medicalRehabilitation.disability && !$scope.form.medicalRehabilitation.disabilityDate) {
			return false;
		}
		if ($scope.form.medicalRehabilitation.specialNeeds && !$scope.form.medicalRehabilitation.specialNeedsDate) {
			return false;
		}
		if ($scope.form.medicalRehabilitationPrescription 
				&& (!$scope.form.medicalRehabilitationPrescription.tpfJarCode || !$scope.form.medicalRehabilitationPrescription.date)) {
			return false;
		}
		if ($scope.form.biosocialDisorder.otherData && $scope.form.biosocialDisorder.otherData.length < 10) {
			return false;
		}
		
		return true;
	};
	
	
	var saveSuccessHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		MessageService.registerSuccessEvent();
		$location.path('/dp/patients/' + $routeParams.patientId + '/aLK_forms').search({});
	};
	
	var saveErrorHandler = function (result) {
		$scope.loadingSpinnerHelper.endLoading();
		SveidraCommons.handleErrors(result, 'err.cer.070.sav');
	};
	
	
	$scope.clean = function () {
        $modalConfirm.show({title: $filter('translate')('inf.clean.form.tit'), content: $filter('translate')('inf.clean.form')}).then(function() {
        	$routeSegment.chain[$routeSegment.chain.length - 1].reload();
        });
	};
}]);

