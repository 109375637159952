import { isDevMode } from "@angular/core";

ng1App.config([
    "$routeSegmentProvider",
    "$locationProvider",
    function ($routeSegmentProvider, $locationProvider) {
        $routeSegmentProvider.options.autoLoadTemplates = true;
        // $locationProvider.html5Mode(true).hashPrefix('!');

        var acRead = function (arg) {
            return {
                canRead: [
                    "AccessControllHelper",
                    function (AccessControllHelper) {
                        return AccessControllHelper.canRead(arg);
                    },
                ],
            };
        };

        var acWrite = function (arg) {
            return {
                canRead: [
                    "AccessControllHelper",
                    function (AccessControllHelper) {
                        return AccessControllHelper.canWrite(arg);
                    },
                ],
            };
        };

        var patientEdit = function () {
            return {
                canWrite: [
                    "AccessControllHelper",
                    function (AccessControllHelper) {
                        return AccessControllHelper.canWrite({ component: "PatientData" });
                    },
                ],
                hasActiveEncounter: [
                    "$q",
                    "$routeParams",
                    "EncountersApiFactory",
                    function ($q, $routeParams, EncountersApiFactory) {
                        var d = $q.defer();
                        EncountersApiFactory.hasActiveEncounter({ patientId: $routeParams.patientId }, function (data) {
                            if (data.value) {
                                d.resolve();
                            } else {
                                d.reject("FORBIDEN");
                            }
                        });

                        return d.promise;
                    },
                ],
            };
        };

        var acReadPatients = function () {
            var components = [
                "AllPatientList",
                "OrganizationPatientList",
                "OrganizationTodayPatientList",
                "MyTodayPatientList",
                "MyPatientList",
            ];

            return {
                patients: [
                    "AccessControllHelper",
                    "$q",
                    function (AccessControllHelper, $q) {
                        var d = $q.defer();
                        var promises = [];
                        var succeed = [];
                        angular.forEach(components, function (c) {
                            var tmp = AccessControllHelper.canRead({ component: c });
                            tmp.then(function () {
                                succeed.push(true);
                            });
                            promises.push(tmp);
                        });
                        $q.all(promises)
                            .then(function () {
                                d.resolve();
                            })
                            .catch(function () {
                                if (succeed.length > 0) {
                                    d.resolve();
                                } else {
                                    d.reject("FORBIDEN");
                                }
                            });

                        return d.promise;
                    },
                ],
            };
        };

        var canCreateDoc = function (typeParam, idParam?) {
            return {
                check: [
                    "AccessControllHelper",
                    "AccessRulesApiFactory",
                    "$routeParams",
                    "$q",
                    function (AccessControllHelper, AccessRulesApiFactory, $routeParams, $q) {
                        if ($routeParams[typeParam]) {
                            return AccessControllHelper.canWrite({
                                component: "Composition",
                                params: [AccessControllHelper.getDocTypeCode($routeParams[typeParam])],
                            });
                        } else if (idParam && $routeParams[idParam]) {
                            var d = $q.defer();
                            AccessRulesApiFactory.checkPermision(
                                { type: "Composition", id: $routeParams[idParam] },
                                function (data) {
                                    if (data.value === "WRITE" || data.value === "write") {
                                        d.resolve();
                                    } else {
                                        d.reject("FORBIDEN");
                                    }
                                },
                                function () {
                                    d.reject("FORBIDEN");
                                }
                            );
                            return d.promise;
                        } else {
                            var d = $q.defer();
                            d.resolve();
                            return d.promise;
                        }
                    },
                ],
            };
        };

        var resolveFailed = {
            templateUrl: "/templates/portal/common/errors/unavailable_data.html",
            controller: "unavailableData",
        };

        // BASE
        var tb_root = "/templates/portal/";

        // DOCTOR PORTAL
        var tb_dp = tb_root + "doctor/";
        var tb_dp_patient = tb_dp + "patient/",
            url_dp_patient = "/dp/patients/:patientId";
        $routeSegmentProvider
            .segment("ERX_PRINT_MEDICINES", {
                templateUrl: tb_dp_patient + "/erx/erx_print_medicines.html",
                controller: "doctor.patient.erx.PrintMedicines",
            })
            .segment("ERX_PRINT_PRESCRIPTIONS", {
                templateUrl: tb_dp_patient + "/erx/erx_print_prescriptions.html",
                controller: "doctor.patient.erx.PrintPrescriptions",
            })
            // .segment('DP', {templateUrl: tb_dp + 'main.html', controller: 'doctor.MainCtrl'});
            .segment("DP", { templateUrl: tb_dp + "main.html", controller: "doctor.MainCtrl" });

        // DOCTOR PORTAL (USER)
        var tb_dp_user = tb_dp + "user/";
        $routeSegmentProvider
            .when(url_dp_patient + "/erx/print-medicines", "ERX_PRINT_MEDICINES")
            .when(url_dp_patient + "/erx/prescriptions/print", "ERX_PRINT_PRESCRIPTIONS")
            .when("/dp", "DP.DP_USER2L.DP_DASHBOARD")
            .when("/dp/records", "DP.DP_USER2L.DP_RECORDS")
            .when("/dp/documents/sign", "DP.DP_USER2L.DP_DOCUMENTS_SIGN")
            //.when('/dp/records/filter', 'DP.DP_USER.DP_RECORDS_FILTER')
            .when("/dp/patients", "DP.DP_USER2L.DP_PATIENTS")
            .when("/dp/patients/new", "DP.DP_USER2L.DP_PATIENT_NEW")
            .when("/dp/reports/covid19-vaccines-recoveries", "DP.DP_USER2L.DP_REPORTS_COVID19_VAC_RECOV")
            .when("/dp/unfinished/records", "DP.DP_USER2L.DP_UNFINISHED_RECORDS")
            .when("/dp/unfinished/records/sign", "DP.DP_USER2L.DP_UNFINISHED_RECORDS_SIGN")
            .when("/dp/account/view", "DP.DP_USER2L.DP_ACCOUNT_VIEW")
            .when("/dp/account/edit", "DP.DP_USER2L.DP_ACCOUNT_EDIT")
            .when("/dp/account/settings", "DP.DP_USER2L.DP_ACCOUNT_SETTINGS_EDIT")
            .when("/dp/tasks", "DP.DP_USER2L.DP_TASKS")
            .when("/dp/templates", "DP.DP_USER2L.DP_TEMPLATES")
            .when("/dp/templates/:type", "DP.DP_USER2L.DP_TEMPLATES")
            .when("/dp/messages", "DP.DP_USER2L.DP_MESSAGES")
            .when("/dp/messages/new", "DP.DP_USER2L.DP_NEW_MESSAGE")
            .when("/dp/messages/:id", "DP.DP_USER2L.DP_VIEW_MESSAGE")
            .when("/dp/specialist/:id", "DP.DP_USER2L.DP_SPECIALIST_VIEW")
            .when("/dp/representatives", "DP.DP_USER2L.DP_REPRESENTATIVES")
            .when("/dp/representatives/new", "DP.DP_USER2L.DP_REPRESENTATIVE_EDIT")
            .when("/dp/representatives/:id/edit", "DP.DP_USER2L.DP_REPRESENTATIVE_EDIT")
            //  ------ MEDVAIS ------
            .when("/dp/imaging/study/list", "DP.DP_USER2L.MEDVAIS_IMG_STUDY_LIST")
            .when("/dp/imaging/study/e027va/new", "DP.DP_USER2L.MEDVAIS_IMG_STUDY_E027VA_NEW")
            .when("/dp/imaging/study/e027va/:documentId", "DP.DP_USER2L.MEDVAIS_IMG_STUDY_E027VA_VIEW")
            .when("/dp/imaging/study/e027va/:documentId/edit", "DP.DP_USER2L.MEDVAIS_IMG_STUDY_E027VA_EDIT")
            .when("/dp/imaging/study/e027va/:documentId/sign", "DP.DP_USER2L.MEDVAIS_IMG_STUDY_E027VA_SIGN")
            .when("/dp/imaging/study/e027va/:documentId/unisignCallback", "MEDVAIS_IMG_STUDY_E027VA_UNISIGN_CALLBACK")
            //  ------ MEDVAIS  end ------
            .segment("MEDVAIS_IMG_STUDY_E027VA_UNISIGN_CALLBACK", {
                templateUrl: tb_dp_user + "medvais/e027va_unisignCallback.html",
                controller: "doctor.user.medvais.ImagingStudyUnisignCallbackCtrl",
            });

        $routeSegmentProvider.within("DP").segment("DP_USER3L", {
            templateUrl: tb_dp_user + "main_3columns.html",
            controller: "doctor.user.MainCtrl",
        });

        $routeSegmentProvider.within("DP").segment("DP_USER2L", {
            templateUrl: tb_dp_user + "main_2columns.html",
            controller: "doctor.user.MainCtrl",
        });

        $routeSegmentProvider
            .within("DP")
            .within("DP_USER2L")
            .segment("DP_TASKS", {
                templateUrl: tb_dp + "tasks/tasks.html",
                controller: "doctor.TasksCtrl",
                resolve: acRead({ component: "TaskList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_RECORDS", {
                templateUrl: tb_dp_user + "records/records.html",
                controller: "doctor.user.RecordsCtrl",
                resolve: acRead({ component: "MyDocumentList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_PATIENTS", {
                templateUrl: tb_dp_user + "patients/patients.html",
                controller: "doctor.user.PatientsViewCtrl",
                resolve: acReadPatients(),
                resolveFailed: resolveFailed,
            })
            .segment("DP_REPORTS_COVID19_VAC_RECOV", {
                templateUrl: tb_dp_user + "reports/covid19_vaccines_recoveries.html",
                controller: "doctor.user.ReportsCtrl",
                resolve: acReadPatients(),
                resolveFailed: resolveFailed,
            })
            .segment("DP_UNFINISHED_RECORDS", {
                templateUrl: tb_dp_user + "records/unfinished_records.html",
                controller: "doctor.user.UnfinishedRecordsCtrl",
                resolve: acRead({ component: "UnfinishedDocumentList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_ACCOUNT_VIEW", {
                templateUrl: tb_dp_user + "account/view.html",
                controller: "doctor.user.AccountViewCtrl",
                resolve: acRead({ component: "PractitionerData" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_TEMPLATES", {
                templateUrl: tb_dp_user + "templates/templates.html",
                controller: "doctor.user.TemplatesCtrl",
                resolve: acRead({ component: "TemplateList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_MESSAGES", {
                templateUrl: tb_dp_user + "messages/messages.html",
                controller: "doctor.user.MessagesCtrl",
                resolve: acRead({ component: "MessageList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_VIEW_MESSAGE", {
                templateUrl: tb_dp_user + "messages/view.html",
                controller: "doctor.user.ViewMessageCtrl",
                resolve: acRead({ component: "MessageList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_SPECIALIST_VIEW", {
                templateUrl: tb_dp_user + "specialist/view.html",
                controller: "doctor.user.SpecialistCtrl",
            })
            .segment("DP_REPRESENTATIVES", {
                templateUrl: tb_dp_user + "representatives/representatives.html",
                controller: "doctor.user.RepresentativesCtrl",
                resolve: acRead({ component: "PractitionerRepresentation" }),
                resolveFailed: resolveFailed,
            })
            //  ------ MEDVAIS ------
            .segment("MEDVAIS_IMG_STUDY_LIST", {
                templateUrl: tb_dp_user + "medvais/imaging_study_list.html",
                controller: "doctor.user.medvais.ImagingStudyListCtrl",
                resolve: acRead({ component: "ImagingStudyList" }),
                resolveFailed: resolveFailed,
            })
            .segment("MEDVAIS_IMG_STUDY_E027VA_VIEW", {
                templateUrl: tb_dp_user + "medvais/e027va_view.html",
                controller: "doctor.user.medvais.ImagingStudyViewCtrl",
            })

            // EDITING 2 columns layout
            // .up()
            // .segment('DP_USER2L', {templateUrl: tb_dp_user + 'main_2columns.html', controller: 'doctor.user.MainCtrl'})
            // .within()
            .segment("DP_DASHBOARD", {
                templateUrl: tb_dp_user + "dashboard.html",
                controller: "doctor.user.DashboardCtrl",
            })
            .segment("DP_PATIENT_NEW", {
                templateUrl: tb_dp + "patient/patient/patient_new.html",
                controller: "doctor.patient.PatientEditCtrl",
                resolve: acWrite({ component: "PatientData" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_UNFINISHED_RECORDS_SIGN", {
                templateUrl: tb_dp_user + "records/unfinished_records_sign.html",
                controller: "doctor.user.UnfinishedRecordsSignCtrl",
                resolve: acRead({ component: "UnfinishedDocumentList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_ACCOUNT_EDIT", {
                templateUrl: tb_dp_user + "account/edit.html",
                controller: "doctor.user.AccountEditCtrl",
                resolve: acWrite({ component: "PractitionerData" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_ACCOUNT_SETTINGS_EDIT", {
                templateUrl: tb_dp_user + "account/settings_edit.html",
                controller: "doctor.user.AccountSettingsEditCtrl",
            })
            .segment("DP_NEW_MESSAGE", {
                templateUrl: tb_dp_user + "messages/new.html",
                controller: "doctor.user.NewMessageCtrl",
                resolve: acRead({ component: "MessageList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_REPRESENTATIVE_EDIT", {
                templateUrl: tb_dp_user + "representatives/edit.html",
                controller: "doctor.user.RepresentativeEditCtrl",
                resolve: acWrite({ component: "PractitionerRepresentation", params: ["2", "3"] }),
                resolveFailed: resolveFailed,
            })
            .segment("DP_DOCUMENTS_SIGN", {
                templateUrl: tb_dp_user + "documents/documents_sign.html",
                controller: "doctor.DocumentsSignCtrl",
            })
            .segment("MEDVAIS_IMG_STUDY_E027VA_NEW", {
                templateUrl: tb_dp_user + "medvais/e027va_new.html",
                controller: "doctor.user.medvais.ImagingStudyNewCtrl",
            })
            .segment("MEDVAIS_IMG_STUDY_E027VA_EDIT", {
                templateUrl: tb_dp_user + "medvais/e027va_edit.html",
                controller: "doctor.user.medvais.ImagingStudyEditCtrl",
            })
            .segment("MEDVAIS_IMG_STUDY_E027VA_SIGN", {
                templateUrl: tb_dp_user + "medvais/e027va_sign.html",
                controller: "doctor.user.medvais.ImagingStudySignCtrl",
            });

        //  ------ MEDVAIS  end ------

        // DOCTOR PORTAL (PATIENT)
        $routeSegmentProvider
            .when(url_dp_patient, "DP.DP_PATIENT2L.DPP_DASHBOARD")
            .when(url_dp_patient + "/encounters/new", "DP.DP_PATIENT1L.DPP_ENCOUNTER_NEW")
            .when(url_dp_patient + "/encounters/:documentId/edit", "DP.DP_PATIENT1L.DPP_ENCOUNTER_EDIT")
            .when(url_dp_patient + "/encounters/:documentId/view", "DP.DP_PATIENT2L.DPP_ENCOUNTER_VIEW")
            .when(
                url_dp_patient + "/encounters/:documentId/history/:historyId",
                "DP.DP_PATIENT2L.DPP_ENCOUNTER_HISTORY_VIEW"
            )
            .when(url_dp_patient + "/records", "DP.DP_PATIENT2L.DPP_RECORDS")
            .when(url_dp_patient + "/drafts", "DP.DP_PATIENT2L.DPP_DRAFTS")
            .when(url_dp_patient + "/records/:docType", "DP.DP_PATIENT2L.DPP_RECORDS")
            .when(url_dp_patient + "/vaccines", "DP.DP_PATIENT2L.DPP_VACCINES")
            .when(url_dp_patient + "/vaccines/calendar", "DP.DP_PATIENT2L.DPP_VACCINES_CALENDAR")
            .when(url_dp_patient + "/vaccines/view/:id", "DP.DP_PATIENT2L.DPP_VACCINE_VIEW")
            .when(url_dp_patient + "/vaccines/edit/:id", "DP.DP_PATIENT2L.DPP_VACCINE_EDIT")
            .when(url_dp_patient + "/vaccines/new", "DP.DP_PATIENT2L.DPP_VACCINES_NEW_MANUAL")
            .when(url_dp_patient + "/vaccines/new/baltipa", "DP.DP_PATIENT2L.DPP_VACCINES_NEW_BALTIPA")
            .when(url_dp_patient + "/vaccines/new/national", "DP.DP_PATIENT2L.DPP_VACCINES_NEW_NATIONAL")
            .when(url_dp_patient + "/documents/sign", "DP.DP_PATIENT2L.DPP_DOCUMENTS_SIGN")
            .when(url_dp_patient + "/documents/new/:documentType", "DP.DP_PATIENT2LREVNEW.DPP_DOCUMENTS_NEW")
            .when(url_dp_patient + "/documents/:documentId", "DP.DP_PATIENT2L.DPP_DOCUMENTS_VIEW")
            .when(url_dp_patient + "/documents/:documentId/edit", "DP.DP_PATIENT2LREVNEW.DPP_DOCUMENTS_EDIT")
            .when(url_dp_patient + "/documents/:documentId/amend", "DP.DP_PATIENT2LREVNEW.DPP_DOCUMENTS_AMEND")
            .when(url_dp_patient + "/documents/:documentId/sign", "DP.DP_PATIENT2L.DPP_DOCUMENTS_SIGN")
            //.when(url_dp_patient + '/documents/:documentId/sign_result', 'DP.DP_PATIENT2L.DPP_DOCUMENTS_SIGN_RESULT')
            .when(url_dp_patient + "/documents/:documentId/history/:historyId", "DP.DP_PATIENT2L.DPP_DOCUMENTS_HISTORY")
            .when(url_dp_patient + "/documents/drafts/:draftId", "DP.DP_PATIENT2L.DPP_DOCUMENT_DRAFTS_VIEW")
            .when(url_dp_patient + "/documents/drafts/:draftId/edit", "DP.DP_PATIENT2LREVNEW.DPP_DOCUMENT_DRAFTS_EDIT")

            // --------- ERX ----------
            //.when(url_dp_patient + '/erx', 'DP.DP_PATIENT2L.ERX_PATIENT_HISTORY')
            .when(url_dp_patient + "/erx/dsp/:id", "DP.DP_PATIENT2L.ERX_PATIENT_HISTORY_DSP")
            .when(url_dp_patient + "/erx/prs/:id", "DP.DP_PATIENT2L.ERX_PATIENT_HISTORY_PRS")
            .when(url_dp_patient + "/erx/prs/:id/doc", "DP.DP_PATIENT2L.ERX_PATIENT_HISTORY_PRS_DOC")

            //.when(url_dp_patient + '/erx/prescriptions', 'DP.DP_PATIENT2L.DPP_PR_LIST')
            .when(url_dp_patient + "/erx/prescriptions/new", "DP.DP_PATIENT1L.DPP_PR_NEW")
            .when(url_dp_patient + "/erx/prescriptions/:prescriptionId", "DP.DP_PATIENT2L.DPP_PR_VIEW")
            .when(url_dp_patient + "/erx/prescriptions/:prescriptionId/sign", "DP.DP_PATIENT2L.DPP_PR_SIGN")
            .when(
                url_dp_patient + "/erx/prescriptions/:prescriptionId/signcallback",
                "DP.DP_PATIENT2L.DPP_PR_SIGN_CALLBACK"
            )
            .when(url_dp_patient + "/erx/prescriptions/:prescriptionId/edit", "DP.DP_PATIENT1L.DPP_PR_EDIT")
            .when(url_dp_patient + "/erx/prescriptions/:prescriptionId/editDoc", "DP.DP_PATIENT1L.DPP_PR_EDIT_DOC")
            // --------- end ERX ----------

            .when(url_dp_patient + "/view", "DP.DP_PATIENT2L.DPP_PATIENT_VIEW")
            .when(url_dp_patient + "/edit", "DP.DP_PATIENT2L.DPP_PATIENT_EDIT")
            .when(url_dp_patient + "/unfinished/records", "DP.DP_PATIENT2L.DPP_UNFINISHED_RECORDS")
            .when(url_dp_patient + "/results", "DP.DP_PATIENT2L.DPP_RESULTS")
            .when(url_dp_patient + "/pgr_vaccines_records", "DP.DP_PATIENT2L.DPP_VACCINES_RECORDS")
            .when(url_dp_patient + "/vaccines_records/new", "DP.DP_PATIENT2L.DPP_VACCINES_RECORDS_NEW")
            .when(url_dp_patient + "/pgr_vaccines_records/edit", "DP.DP_PATIENT2L.DPP_VACCINES_RECORDS_EDIT")
            .when(url_dp_patient + "/vaccine_record/edit/:recordId", "DP.DP_PATIENT2L.DPP_VACCINE_RECORD_EDIT")
            .when(url_dp_patient + "/test_record/edit/:recordId", "DP.DP_PATIENT2L.DPP_TEST_RECORD_EDIT")
            .when(url_dp_patient + "/allergies2", "DP.DP_PATIENT3L.DPP_ALLERGIES2")
            .when(url_dp_patient + "/dgcCertificates", "DP.DP_PATIENT2L.DPP_DGC_CERTIFICATES")
            .when(url_dp_patient + "/diagnosis2", "DP.DP_PATIENT3L.DPP_DIAGNOSIS2")
            .when(url_dp_patient + "/observations", "DP.DP_PATIENT2L.DPP_OBSERVATIONS")
            .when(url_dp_patient + "/tasks", "DP.DP_PATIENT2L.DP_TASKS")
            .when(url_dp_patient + "/summary", "DP.DP_PATIENT2L.DPP_VIEW_SUMMARY")
            .when(url_dp_patient + "/summary/edit", "DP.DP_PATIENT2L.DPP_EDIT_SUMMARY")
            .when(url_dp_patient + "/summary/draft/:draftId", "DP.DP_PATIENT2L.DPP_VIEW_SUMMARY_DRAFT")
            .when(url_dp_patient + "/summary/draft/:draftId/edit", "DP.DP_PATIENT2L.DPP_EDIT_SUMMARY_DRAFT")
            .when(url_dp_patient + "/summary/history/:historyId", "DP.DP_PATIENT2L.DPP_SUMMARY_HISTORY")
            .when(url_dp_patient + "/certificates", "DP.DP_PATIENT2L.DPP_CERTIFICATES")
            //.when(url_dp_patient + '/certificates/nesp', 'DP.DP_PATIENT2L.DPP_CERT_NESP_NEW')
            .when(url_dp_patient + "/certificates/nesp/:id/view", "DP.DP_PATIENT2L.DPP_CERT_NESP_VIEW")
            .when(url_dp_patient + "/certificates/nesp/new", "DP.DP_PATIENT2L.DPP_CERT_NESP_EDIT")
            .when(url_dp_patient + "/certificates/incapacity/new", "DP.DP_PATIENT2L.DPP_CERT_INCAPACITY_EDIT")
            .when(url_dp_patient + "/certificates/incapacity/:id/view", "DP.DP_PATIENT2L.DPP_CERT_INCAPACITY_VIEW")
            .when(url_dp_patient + "/certificates/:id", "DP.DP_PATIENT2L.DPP_CERT_VIEW")
            .when(url_dp_patient + "/certificates/:id/history/:historyId", "DP.DP_PATIENT2L.DPP_CERT_HISTORY")
            .when(url_dp_patient + "/certificates/:id/edit", "DP.DP_PATIENT2LREVNEW.DPP_CERT_NEW")
            .when(url_dp_patient + "/certificates/:certificateType/new", "DP.DP_PATIENT2LREVNEW.DPP_CERT_NEW")
            .when(url_dp_patient + "/certificates/drafts/:id", "DP.DP_PATIENT2L.DPP_CERT_DRAFT_VIEW")
            .when(url_dp_patient + "/certificates/drafts/:draftId/edit", "DP.DP_PATIENT2LREVNEW.DPP_CERT_NEW")
            .when(url_dp_patient + "/representatives", "DP.DP_PATIENT2L.DPP_REPRESENTATIVES")
            .when(url_dp_patient + "/representatives/new", "DP.DP_PATIENT2L.DPP_REPRESENTATIVE_EDIT")
            .when(url_dp_patient + "/representatives/:id/edit", "DP.DP_PATIENT2L.DPP_REPRESENTATIVE_EDIT")

            .when(
                url_dp_patient + "/automation/vaccination/:vaccine/dose/:doseNo",
                "DP.DP_PATIENT2L.DPP_AUTOMATION_VACCINATION"
            );
        // url and mapping disabled ESPBIRC-4796
        //.when(url_dp_patient + '/reports', 'DP.DP_PATIENT.DPP_REPORT_VIEW')

        $routeSegmentProvider.within("DP").segment("DP_PATIENT3L", {
            templateUrl: tb_dp_patient + "main_3columns.html",
            controller: "doctor.patient.MainCtrl",
        });

        $routeSegmentProvider.within("DP").segment("DP_PATIENT2LREV", {
            templateUrl: tb_dp_patient + "main_2columns_reverse.html",
            controller: "doctor.patient.MainCtrl",
        });

        $routeSegmentProvider.within("DP").segment("DP_PATIENT2LREVNEW", {
            templateUrl: tb_dp_patient + "main_2columns_reverse_remastered.html",
            controller: "doctor.patient.MainCtrl",
        });

        $routeSegmentProvider.within("DP").segment("DP_PATIENT1L", {
            templateUrl: tb_dp_patient + "main_1column.html",
            controller: "doctor.patient.MainCtrl",
        });

        $routeSegmentProvider.within("DP").segment("DP_PATIENT2L", {
            templateUrl: tb_dp_patient + "main_2columns.html",
            controller: "doctor.patient.MainCtrl",
        });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT1L")
            .segment("DPP_PR_NEW", {
                templateUrl: tb_dp_patient + "/erx/erx_presc_edit.html",
                controller: "doctor.patient.erx_pr.EditCtrl",
            })
            .segment("DPP_PR_EDIT", {
                templateUrl: tb_dp_patient + "/erx/erx_presc_edit.html",
                controller: "doctor.patient.erx_pr.EditCtrl",
            })
            .segment("DPP_PR_EDIT_DOC", {
                templateUrl: tb_dp_patient + "/erx/erx_presc_edit_doc.html",
                controller: "doctor.patient.erx_pr.EditDocCtrl",
            })
            .segment("DPP_ENCOUNTER_NEW", {
                templateUrl: tb_dp_patient + "encounters/new.html",
                controller: "doctor.patient.EncountersNewCtrl",
            })
            .segment("DPP_ENCOUNTER_EDIT", {
                templateUrl: tb_dp_patient + "encounters/edit.html",
                controller: "doctor.patient.EncountersEditCtrl",
            });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2L")

            .segment("DPP_ENCOUNTER_VIEW", {
                templateUrl: tb_dp_patient + "encounters/view.html",
                controller: "doctor.patient.EncountersViewCtrl",
            })
            .segment("DP_TASKS", {
                templateUrl: tb_dp + "tasks/tasks.html",
                controller: "doctor.TasksCtrl",
                resolve: acRead({ component: "TaskList" }),
                resolveFailed: resolveFailed,
            })
            //.segment('DPP_ENCOUNTER_VIEW', {templateUrl: tb_dp_patient + 'encounters/view.html', controller: 'doctor.patient.EncountersViewCtrl'})
            .segment("DPP_ENCOUNTER_HISTORY_VIEW", {
                templateUrl: tb_dp_patient + "encounters/history.html",
                controller: "doctor.patient.EncountersHistoryCtrl",
            })
            .segment("DPP_RECORDS", {
                templateUrl: tb_dp_patient + "records/list.html",
                controller: "doctor.patient.RecordsCtrl",
                resolve: acRead({ component: "DocumentList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_DRAFTS", {
                templateUrl: tb_dp_patient + "drafts/drafts.html",
                controller: "doctor.patient.DraftsCtrl",
                resolve: acRead({ component: "DraftList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VACCINES", {
                templateUrl: tb_dp_patient + "vaccines/list.html",
                controller: "doctor.patient.VaccinesCtrl",
                resolve: acRead({ component: "ImmunizationSchedule" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VACCINES_CALENDAR", {
                templateUrl: tb_dp_patient + "vaccines/calendar.html",
                controller: "doctor.patient.VaccinesCalendarCtrl",
                resolve: acRead({ component: "ImmunizationSchedule" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VACCINE_VIEW", {
                templateUrl: tb_dp_patient + "vaccines/view.html",
                controller: "doctor.patient.VaccineViewCtrl",
            })
            .segment("DPP_DOCUMENTS_VIEW", {
                templateUrl: tb_dp_patient + "documents/documents_view.html",
                controller: "doctor.patient.DocumentsViewCtrl",
                reloadOnSearch: true,
            })
            .segment("DPP_DOCUMENTS_HISTORY", {
                templateUrl: tb_dp_patient + "documents/documents_history.html",
                controller: "doctor.patient.DocumentsHistoryCtrl",
            })
            // --------- ERX ----------

            // .segment('ERX_PATIENT_HISTORY', {
            //     templateUrl: tb_dp_patient + '/erx/erx_patient_history.html',
            //     controller: 'doctor.patient.erx.PatientHistoryCtrl',
            //     resolve: acRead({component: 'PrescriptionList'}),
            //     resolveFailed: resolveFailed
            // })
            .segment("ERX_PATIENT_HISTORY_DSP", {
                templateUrl: tb_dp_patient + "/erx/erx_patient_history_view_dsp.html",
                controller: "doctor.patient.erx.PatientHistoryViewDspCtrl",
            })
            .segment("ERX_PATIENT_HISTORY_PRS", {
                templateUrl: tb_dp_patient + "/erx/erx_patient_history_view_prs.html",
                controller: "doctor.patient.erx.PatientHistoryViewPrsCtrl",
            })
            .segment("ERX_PATIENT_HISTORY_PRS_DOC", {
                templateUrl: tb_dp_patient + "/erx/erx_patient_history_view_prs_doc.html",
                controller: "doctor.patient.erx.PatientHistoryViewPrsDocCtrl",
            })

            //.segment('DPP_PR_LIST', {templateUrl: tb_dp_patient + '/erx/erx_presc_list.html', controller: 'doctor.patient.erx_pr.ListCtrl'})
            .segment("DPP_PR_VIEW", {
                templateUrl: tb_dp_patient + "/erx/erx_presc_view.html",
                controller: "doctor.patient.erx_pr.ViewCtrl",
                dependencies: ["hideDoc"],
            })
            // --------- end ERX ----------

            .segment("DPP_DOCUMENT_DRAFTS_VIEW", {
                templateUrl: tb_dp_patient + "documents/documents_view_draft.html",
                controller: "doctor.patient.DocumentsDraftViewCtrl",
            })
            .segment("DPP_UNFINISHED_RECORDS", {
                templateUrl: tb_dp_patient + "records/unfinished_records.html",
                controller: "doctor.patient.UnfinishedRecordsCtrl",
                resolve: acRead({ component: "UnfinishedDocumentList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_RESULTS", {
                templateUrl: tb_dp_patient + "results/results.html",
                controller: "doctor.patient.ResultsCtrl",
            })
            .segment("DPP_VACCINES_RECORDS", {
                templateUrl: tb_dp_patient + "vaccines_records/records.html",
                controller: "doctor.patient.VaccineRecordsCtrl",
            })
            .segment("DPP_DGC_CERTIFICATES", {
                templateUrl: tb_dp_patient + "dgcCertificates/dgc_certificates.html",
                controller: "doctor.patient.DgcCtrl",
                resolve: acRead({ component: "Dcc" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_OBSERVATIONS", {
                templateUrl: tb_dp_patient + "observations/observations.html",
                controller: "doctor.patient.ObservationsCtrl",
                resolve: acRead({ component: "OrderList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VIEW_SUMMARY", {
                templateUrl: tb_dp_patient + "patient_summary/view.html",
                controller: "doctor.patient.ViewSummaryCtrl",
                resolve: acRead({ component: "Composition", params: ["60591-5"] }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VIEW_SUMMARY_DRAFT", {
                templateUrl: tb_dp_patient + "patient_summary/view.html",
                controller: "doctor.patient.ViewSummaryCtrl",
            })
            .segment("DPP_SUMMARY_HISTORY", {
                templateUrl: tb_dp_patient + "patient_summary/history.html",
                controller: "doctor.patient.SummaryHistoryCtrl",
            })
            .segment("DPP_CERTIFICATES", {
                templateUrl: tb_dp_patient + "certificates/certificates.html",
                controller: "doctor.patient.CertificatesCtrl",
                resolve: acRead({ component: "CertificateList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_CERT_NESP_VIEW", {
                templateUrl: tb_dp_patient + "certificates/nesp/view_nesp.html",
                controller: "doctor.patient.cert.NespViewCtrl",
            })
            .segment("DPP_CERT_INCAPACITY_VIEW", {
                templateUrl: tb_dp_patient + "certificates/incapacity/view_incapacity.html",
                controller: "doctor.patient.cert.IncapacityViewCtrl",
            })
            .segment("DPP_CERT_VIEW", {
                templateUrl: tb_dp_patient + "certificates/view/view_certificate.html",
                controller: "doctor.patient.CertificatesViewCtrl",
            })
            .segment("DPP_CERT_HISTORY", {
                templateUrl: tb_dp_patient + "certificates/history/certificate_history.html",
                controller: "doctor.patient.CertificatesHistoryCtrl",
            })
            .segment("DPP_CERT_DRAFT_VIEW", {
                templateUrl: tb_dp_patient + "certificates/drafts/view_certificate_draft.html",
                controller: "doctor.patient.CertificatesDraftViewCtrl",
            })
            .segment("DPP_REPRESENTATIVES", {
                templateUrl: tb_dp_patient + "representatives/representatives.html",
                controller: "doctor.patient.RepresentativesCtrl",
                resolve: acRead({ component: "RepresentetivesList" }),
                resolveFailed: resolveFailed,
            })

            .segment("DPP_AUTOMATION_VACCINATION", {
                templateUrl: tb_dp_patient + "automation/quick_vaccination.html",
                controller: "doctor.patient.automation.QuickVaccinationCtrl",
            })

            // EDITING 2 columns layout
            // .up()
            // .segment('DP_PATIENT2L', {templateUrl: tb_dp_patient + 'main_2columns.html', controller: 'doctor.patient.MainCtrl'})
            // .within()
            .segment("DPP_DASHBOARD", {
                templateUrl: tb_dp_patient + "dashboard.html",
                controller: "doctor.patient.DashboardCtrl",
            })
            //.segment('DPP_ENCOUNTER_NEW', {templateUrl: tb_dp_patient + 'encounters/new.html', controller: 'doctor.patient.EncountersNewCtrl'})
            //.segment('DPP_ENCOUNTER_EDIT', {templateUrl: tb_dp_patient + 'encounters/edit.html', controller: 'doctor.patient.EncountersEditCtrl'})
            .segment("DPP_PATIENT_VIEW", {
                templateUrl: "/templates/portal/common/patient_info/patient_view.html",
                controller: "doctor.patient.PatientViewCtrl",
                resolve: acRead({ component: "PatientData" }),
                resolveFailed: resolveFailed,
            })

            .segment("DPP_VACCINE_EDIT", {
                templateUrl: tb_dp_patient + "vaccines/edit.html",
                controller: "doctor.patient.VaccineEditCtrl",
            })
            .segment("DPP_VACCINES_NEW_MANUAL", {
                templateUrl: tb_dp_patient + "vaccines/new-manual.html",
                controller: "doctor.patient.VaccinesAddNewManualCtrl",
                resolve: acWrite({ component: "ImmunizationSchedule" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VACCINES_NEW_BALTIPA", {
                templateUrl: tb_dp_patient + "vaccines/new-baltipa.html",
                controller: "doctor.patient.VaccinesAddNewBaltipaCtrl",
                resolve: acWrite({ component: "ImmunizationSchedule" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VACCINES_NEW_NATIONAL", {
                templateUrl: tb_dp_patient + "vaccines/new-national.html",
                controller: "doctor.patient.VaccinesAddNewNationalCtrl",
                resolve: acWrite({ component: "ImmunizationSchedule" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_DOCUMENTS_SIGN", {
                templateUrl: tb_dp_user + "documents/documents_sign.html",
                controller: "doctor.DocumentsSignCtrl",
            })

            // --------- ERX ----------
            .segment("DPP_PR_SIGN", {
                templateUrl: tb_dp_patient + "/erx/erx_presc_sign.html",
                controller: "doctor.patient.erx_pr.SignCtrl",
            })
            .segment("DPP_PR_SIGN_CALLBACK", {
                templateUrl: tb_dp_patient + "/erx/erx_presc_sign_callback.html",
                controller: "doctor.patient.erx_pr.SignCallbackCtrl",
            })
            // --------- end ERX ----------

            .segment("DPP_PATIENT_EDIT", {
                templateUrl: tb_dp_patient + "patient/patient_edit.html",
                controller: "doctor.patient.PatientEditCtrl",
                resolve: patientEdit(),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_VACCINES_RECORDS_NEW", {
                templateUrl: tb_dp_patient + "vaccines_records/new-record.html",
                controller: "doctor.patient.VaccineNewRecordsCtrl",
            })
            .segment("DPP_VACCINES_RECORDS_EDIT", {
                templateUrl: tb_dp_patient + "vaccines_records/edit.html",
                controller: "doctor.patient.VaccineRecordsEditCtrl",
            })
            .segment("DPP_VACCINE_RECORD_EDIT", {
                templateUrl: tb_dp_patient + "vaccines_records/vaccine-edit.html",
                controller: "doctor.patient.VaccineRecordEditCtrl",
            })
            .segment("DPP_TEST_RECORD_EDIT", {
                templateUrl: tb_dp_patient + "vaccines_records/test-edit.html",
                controller: "doctor.patient.TestRecordEditCtrl",
            })
            .segment("DPP_EDIT_SUMMARY", {
                templateUrl: tb_dp_patient + "patient_summary/edit.html",
                controller: "doctor.patient.EditSummaryCtrl",
                resolve: acWrite({ component: "Composition", params: ["60591-5"] }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_EDIT_SUMMARY_DRAFT", {
                templateUrl: tb_dp_patient + "patient_summary/edit.html",
                controller: "doctor.patient.EditSummaryCtrl",
            })
            .segment("DPP_CERT_NESP_EDIT", {
                templateUrl: tb_dp_patient + "certificates/nesp/edit_nesp.html",
                controller: "doctor.patient.cert.NespEditCtrl",
            })
            .segment("DPP_CERT_INCAPACITY_EDIT", {
                templateUrl: tb_dp_patient + "certificates/incapacity/edit_incapacity.html",
                controller: "doctor.patient.cert.IncapacityEditCtrl",
            })
            .segment("DPP_CER_GKK_EDIT", {
                templateUrl: tb_dp_patient + "certificates/gkk/edit_gkk.html",
                controller: "doctor.patient.cert.GKKEditCtrl",
            })
            .segment("DPP_CERT_E103-1_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e103-1/edit_e103-1.html",
                controller: "doctor.patient.cert.e103-1EditCtrl",
            })
            .segment("DPP_CERT_I_E027-1_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e027-1/edit_I_e027-1.html",
                controller: "doctor.patient.cert.I.e027-1EditCtrl",
            })
            .segment("DPP_CERT_II_E027-1_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e027-1/edit_II_e027-1.html",
                controller: "doctor.patient.cert.II.e027-1EditCtrl",
            })
            .segment("DPP_CERT_E083-1_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e083-1/edit_e083-1.html",
                controller: "doctor.patient.cert.e083-1EditCtrl",
            })
            .segment("DPP_CERT_I_E047_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e047/edit_I_e047.html",
                controller: "doctor.patient.cert.I.e047EditCtrl",
            })
            .segment("DPP_CERT_II_E047_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e047/edit_II_e047.html",
                controller: "doctor.patient.cert.II.e047EditCtrl",
            })
            .segment("DPP_CERT_II_E047_NEW", {
                templateUrl: tb_dp_patient + "certificates/e047/edit_II_e047.html",
                controller: "doctor.patient.cert.II.e047NewCtrl",
            })
            .segment("DPP_CERT_II_E048_NEW", {
                templateUrl: tb_dp_patient + "certificates/e048/edit_II_e048.html",
                controller: "doctor.patient.cert.II.e048NewCtrl",
            })
            .segment("DPP_CERT_I_E048_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e048/edit_I_e048.html",
                controller: "doctor.patient.cert.I.e048EditCtrl",
            })
            .segment("DPP_CERT_II_E048_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e048/edit_II_e048.html",
                controller: "doctor.patient.cert.II.e048EditCtrl",
            })
            .segment("DPP_CERT_E049_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e049/edit_e049.html",
                controller: "doctor.patient.cert.e049EditCtrl",
            })
            .segment("DPP_CERT_E049_NEW", {
                templateUrl: tb_dp_patient + "certificates/e049/edit_e049.html",
                controller: "doctor.patient.cert.e049NewCtrl",
            })
            .segment("DPP_CERT_E106_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e106/edit_e106.html",
                controller: "doctor.patient.cert.e106EditCtrl",
            })
            .segment("DPP_CERT_E106_2_1_EDIT", {
                templateUrl: tb_dp_patient + "certificates/e106-2-1/edit_e106_2_1.html",
                controller: "doctor.patient.cert.e106.1.2.EditCtrl",
            })
            .segment("DPP_CERT_DRAFT_VIEW", {
                templateUrl: tb_dp_patient + "certificates/drafts/view_certificate_draft.html",
                controller: "doctor.patient.CertificatesDraftViewCtrl",
            })
            .segment("DPP_CERT_DRAFT_EDIT", {
                templateUrl: tb_dp_patient + "certificates/drafts/edit_certificate_draft.html",
                controller: "doctor.patient.CertificatesDraftEditCtrl",
            })
            .segment("DPP_REPRESENTATIVES", {
                templateUrl: tb_dp_patient + "representatives/representatives.html",
                controller: "doctor.patient.RepresentativesCtrl",
                resolve: acRead({ component: "RepresentetivesList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_REPRESENTATIVE_EDIT", {
                templateUrl: tb_dp_patient + "representatives/edit.html",
                controller: "doctor.patient.RepresentativeEditCtrl",
                resolve: acWrite({ component: "RepresentetivesList" }),
                resolveFailed: resolveFailed,
            });
        /*.segment('ERX_PATIENT_HISTORY', {
            templateUrl: tb_dp_patient + '/erx/erx_patient_history.html',
            controller: 'doctor.patient.erx.PatientHistoryCtrl',
            resolve: acRead({component: 'PrescriptionList'}),
            resolveFailed: resolveFailed
        });*/

        // url and mapping disabled ESPBIRC-4796
        //.segment('DPP_REPORT_VIEW', {templateUrl: tb_dp_patient + 'reports/view_report.html', controller: 'doctor.patient.ReportsViewCtrl'});
        //

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2LREVNEW")
            .segment("DPP_DOCUMENTS_NEW", {
                templateUrl: tb_dp_patient + "documents/documents_new.html",
                controller: "doctor.patient.DocumentsNewCtrl",
                resolve: canCreateDoc("documentType"),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_DOCUMENTS_EDIT", {
                templateUrl: tb_dp_patient + "documents/documents_edit.html",
                controller: "doctor.patient.DocumentsEditCtrl",
                resolve: canCreateDoc("documentType", "documentId"),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_DOCUMENTS_AMEND", {
                templateUrl: tb_dp_patient + "documents/documents_amend.html",
                controller: "doctor.patient.DocumentsAmendCtrl",
                resolve: canCreateDoc("documentType", "documentId"),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_DOCUMENT_DRAFTS_EDIT", {
                templateUrl: tb_dp_patient + "documents/documents_edit_draft.html",
                controller: "doctor.patient.DocumentsDraftEditCtrl",
            })
            .segment("DPP_CERT_NEW", {
                templateUrl: tb_dp_patient + "certificates/new/new_certificate.html",
                controller: "doctor.patient.CertificatesNewCtrl",
                resolve: canCreateDoc("certificateType", "id"),
                resolveFailed: resolveFailed,
            });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT3L")
            .segment("DPP_ALLERGIES2", {
                template: "<app-allergies-page></app-allergies-page>",
                resolve: acRead({ component: "AllergyIntoleranceList" }),
                resolveFailed: resolveFailed,
            })
            .segment("DPP_DIAGNOSIS2", {
                template: "<app-diagnosis-page></app-diagnosis-page>",
                resolve: acRead({ component: "ConditionList" }),
                resolveFailed: resolveFailed,
            });
        // .segment('DPP_RESULTS', {templateUrl: tb_dp_patient + 'results/results.html', controller: 'doctor.patient.ResultsCtrl'});

        if (isDevMode()) {
            // PROTO
            $routeSegmentProvider
                .when("/proto", "DP.DP_USER.PROTO_MAIN")
                .when("/proto/paging", "DP.DP_USER.PAGING")
                .when("/proto/error", "DP.DP_USER.PROTO_ERROR")
                .when("/proto/validation", "DP.DP_USER.PROTO_VALIDATION")
                .when("/proto/historyitem", "DP.DP_USER.PROTO_HISTORY_ITEM")
                .when("/proto/components", "DP.DP_USER.PROTO_COMPONENTS")
                .when("/proto/filters", "DP.DP_USER.PROTO_FILTERS")
                .when("/proto/list/items", "DP.DP_USER.PROTO_LIST_ITEMS")
                .when("/proto/aside", "DP.DP_USER.ASIDE")
                .when("/proto/statuses", "DP.DP_USER.PROTO_STATUSES")
                .when("/proto/roles/roles_list", "DP.DP_USER.ROLES_LIST")
                .when("/proto/roles/roles_blocks", "DP.DP_USER.ROLES_BLOCKS")
                .when("/proto/roles/roles_employer", "DP.DP_USER.ROLES_EMPLOYER")
                .when("/proto/classifiers/list", "DP.DP_USER.PROTO_CLASSIFIERS_LIST")
                .when("/proto/preloaders/1", "DP.DP_USER.PROTO_PRELOADER1")
                .when("/proto/preloaders/2", "DP.DP_USER.PROTO_PRELOADER2")
                .when("/proto/preloaders/3", "DP.DP_USER.PROTO_PRELOADER3")
                .when("/proto/preloaders/4", "DP.DP_USER.PROTO_PRELOADER4")
                .when("/proto/preloaders/5", "DP.DP_USER.PROTO_PRELOADER5")
                .when("/proto/preloaders/6", "DP.DP_USER.PROTO_PRELOADER6")
                .when("/proto/preloaders/7", "DP.DP_USER.PROTO_PRELOADER7")
                .when("/proto/preloaders/9", "DP.DP_USER.PROTO_PRELOADER9")
                .when("/proto/preloaders/10", "DP.DP_USER.PROTO_PRELOADER10")
                .when("/proto/icons", "DP.DP_USER.PROTO_ICONS")
                .when("/proto/pageclosingdialog", "DP.DP_USER.PROTO_PAGE_CLOSING_DIALOG")
                .within("DP")
                .within("DP_USER")
                .segment("PROTO_ERROR", { templateUrl: "/templates/proto/error.html", controller: "proto.ErrorCtrl" })
                .segment("PROTO_VALIDATION", {
                    templateUrl: "/templates/proto/validation.html",
                    controller: "proto.ValidationCtrl",
                })
                .segment("PROTO_HISTORY_ITEM", {
                    templateUrl: "/templates/proto/history_item.html",
                    controller: "proto.HistoryItemCtrl",
                })
                .segment("PROTO_COMPONENTS", {
                    templateUrl: "/templates/proto/components.html",
                    controller: "proto.ComponentsCtrl",
                })
                .segment("PROTO_FILTERS", {
                    templateUrl: "/templates/proto/filters.html",
                    controller: "proto.FiltersCtrl",
                })
                .segment("ASIDE", { templateUrl: "/templates/proto/aside.html", controller: "proto.AsideCtrl" })
                .segment("PROTO_LIST_ITEMS", {
                    templateUrl: "/templates/proto/list_elements.html",
                    controller: "proto.ListItemsCtrl",
                })
                .segment("PROTO_MAIN", { templateUrl: "/templates/proto/proto.html" })
                .segment("PAGING", { templateUrl: "/templates/proto/paging.html", controller: "proto.PagingCtrl" })
                .segment("PROTO_STATUSES", { templateUrl: "/templates/proto/statuses.html" })
                .segment("PROTO_CLASSIFIERS_LIST", { templateUrl: "/templates/proto/classifiers_list_items.html" })
                .segment("ROLES_LIST", {
                    templateUrl: "/templates/proto/roles/roles_list.html",
                    controller: "proto.RolesCtrl",
                })
                .segment("ROLES_BLOCKS", {
                    templateUrl: "/templates/proto/roles/roles_blocks.html",
                    controller: "proto.RolesCtrl",
                })
                .segment("ROLES_EMPLOYER", {
                    templateUrl: "/templates/proto/roles/roles_employer.html",
                    controller: "proto.RolesCtrl",
                })
                .segment("PROTO_PRELOADER1", {
                    templateUrl: "/templates/proto/preloaders/preloader1.html",
                    controller: "proto.Preloader1",
                })
                .segment("PROTO_PRELOADER2", {
                    templateUrl: "/templates/proto/preloaders/preloader2.html",
                    controller: "proto.Preloader2",
                })
                .segment("PROTO_PRELOADER3", {
                    templateUrl: "/templates/proto/preloaders/preloader3.html",
                    controller: "proto.Preloader3",
                })
                .segment("PROTO_PRELOADER4", {
                    templateUrl: "/templates/proto/preloaders/preloader4.html",
                    controller: "proto.Preloader4",
                })
                .segment("PROTO_PRELOADER5", {
                    templateUrl: "/templates/proto/preloaders/preloader5.html",
                    controller: "proto.Preloader5",
                })
                .segment("PROTO_PRELOADER6", {
                    templateUrl: "/templates/proto/preloaders/preloader6.html",
                    controller: "proto.Preloader6",
                })
                .segment("PROTO_PRELOADER7", {
                    templateUrl: "/templates/proto/preloaders/preloader7.html",
                    controller: "proto.Preloader7",
                })
                .segment("PROTO_PRELOADER9", {
                    templateUrl: "/templates/proto/preloaders/preloader9.html",
                    controller: "proto.Preloader9",
                })
                .segment("PROTO_PRELOADER10", {
                    templateUrl: "/templates/proto/preloaders/preloader10.html",
                    controller: "proto.Preloader10",
                })
                .segment("PROTO_ICONS", { templateUrl: "/templates/proto/icons.html", controller: "proto.Icons" })
                .segment("PROTO_PAGE_CLOSING_DIALOG", {
                    templateUrl: "/templates/proto/page_close_dialog.html",
                    controller: "proto.PageCloseDialog",
                });
        }

        // INTEGRATED
        $routeSegmentProvider
            .when("/int" + url_dp_patient + "/documents/new/:documentType", "DPP_DOCUMENTS_NEW")
            .when("/dp/integrated/address", "ADDRESS")
            .segment("DPP_DOCUMENTS_NEW", {
                templateUrl: tb_dp_patient + "documents/documents_new.html",
                controller: "doctor.patient.DocumentsNewCtrl",
            })
            .segment("ADDRESS", {
                templateUrl: tb_root + "common/integrated_components/edit_address.html",
                controller: "EditAddressCtrl",
            });
    },
]);

// -------- ERX -----------
ng1App.config([
    "erxApiConfigProvider",
    "apiUrl",
    function (erxApiConfigProvider, apiUrl) {
        angular.extend(erxApiConfigProvider.options, {
            // url to the portal backend to use when calling our web services
            // naturally, if the portal backend is on the same machine as the web server
            // or if you have some proxy configurations on your web server,
            // this doesn't have to be a full url
            portalBackendUrl: apiUrl,
        });
    },
]);
