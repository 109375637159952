<div id="{{data.id}}" (click)="onCardClick(data.id)" (keypress)="onCardClick(data.id)" tabindex="0">
    <div class="row p-0">
        <div class="col-8">
            <div class="row">
                <div class=" text">
                    <span class="badge rounded-pill me-2 {{badgeColor}}">{{badgeTitle | translate}}</span>
                    <span class="text-secondary">{{dateBetween}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col mb-1">
                    <span class="fw-bold">{{data.title}}</span>
                </div>
            </div>
            <div class="text-secondary">
                <span class="text" *ngIf="data.type === 'DocRefConsentModel'">{{data.description}}</span>
                <span class="text" *ngIf="data.type === 'DocRefConsentTemplateModel'">{{'comp.docRefConsentTemplate.list.category' | translate}}{{' ' + data.categoryTitle}}</span>
            </div>
            <div class="text-secondary" *ngIf="data.type === 'DocRefConsentModel'">
                <span class="text">{{'comp.docRefConsent.new.patient' | translate}}{{' ' + patientFullName}}</span>
            </div>
            <div class="text-secondary" *ngIf="data.type === 'DocRefConsentModel'">
                <span class="text">{{data.practitioner.speciality.name}}</span>
                <span class="vr"></span>
                <a class="text" [href]="data.id ? '/' + portalType + '/specialist/' + data.id : undefined">{{practitionerFullName}}</a>
                <span class="vr"></span>
                <span class="text">{{data.practitioner.practitionerOrganization.name}}</span>
            </div>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end" *ngIf="canEdit()">
            <button class="btn btn-icon" (click)="onEditClick(data.id)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>