<div class="card mb-3">
    <div class="card-header" (click)="toggle()" (keypress)="toggle()" tabindex="0">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 align-middle">
                {{ description.headerData.date | date : "YYYY-MM-dd" }}
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row text-center">
                    {{ description.headerData.name | translate }}
                </div>
                <div class="row text-center">{{ description.headerData.bloodPressure }}</div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row">
                    <span class="text-center">{{ description.headerData.pulse }}</span>
                    <div class="icon fs-2 lh-1" style.display="block">
                        <i class="custom-icon"></i>
                    </div>
                    <div class="text">
                        <a class="float-end chevron">
                            <span [ngClass]="closed ? 'up' : 'down'"></span>
                            <span class="sr-only" translate>Collapse/Expand</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!closed" class="card-body">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col d-flex flex-column">
                                <div class="row flex-grow-1">
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="row fw-bold">
                                            {{ "Apsilankyta" | translate }}
                                        </div>
                                        <div class="row">
                                            {{
                                                description.data.womanAmbulatoryDescription.assessmentDate
                                                    | date : "yyyy-MM-dd"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="row fw-bold">
                                            {{ "Nusiskundimai" | translate }}
                                        </div>
                                        <div class="row">
                                            {{
                                                description.data.womanAmbulatoryDescription.isComplaints
                                                    ? ("Taip" | translate)
                                                    : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="description.data.womanAmbulatoryDescription.isComplaints"
                                        class="col-sm-4 col-md-4 col-lg-4"
                                    >
                                        <div class="row fw-bold">
                                            {{ "Savijauta/nusiskundimai" | translate }}
                                        </div>
                                        <div class="row">
                                            {{
                                                description.data.womanAmbulatoryDescription.complaints
                                                    ? ("Taip" | translate)
                                                    : ("Ne" | translate)
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <div class="row mb-2">
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">
                                            {{ "Svoris" | translate }}
                                        </div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.weight }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">
                                            {{ "Prieaugis" | translate }}
                                        </div>
                                        <div class="row">
                                            {{ getGainWeightDuringPregnancy }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">{{ "Kraujospūdis (kairė)" | translate }}</div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.bloodPressureLeftUp }}/{{
                                                description.data.womanAmbulatoryDescription.bloodPressureLeftDown
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">{{ "Kraujospūdis (dešinė)" | translate }}</div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.bloodPressureRightUp }}/{{
                                                description.data.womanAmbulatoryDescription.bloodPressureRightDown
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">
                                            {{ "Pulsas" | translate }}
                                        </div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.pulse }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">
                                            {{ "Temperatūra" | translate }}
                                        </div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.temperature }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">
                                            {{ "GDA" | translate }}
                                        </div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.gda }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3">
                                        <div class="row fw-bold">
                                            {{ "Vaisių skaičius" | translate }}
                                        </div>
                                        <div class="row">
                                            {{ description.data.womanAmbulatoryDescription.childCount }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="row fw-bold">
                            {{ "Rekomendacijos" | translate }}
                        </div>
                        <div class="row">
                            {{ description.data.womanAmbulatoryDescription.recommendations }}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="row fw-bold">
                            {{ "Paskyrimai, gydymas" | translate }}
                        </div>
                        <div class="row">
                            {{ description.data.womanAmbulatoryDescription.appointmentsTreatment }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let fetusDescription of description.data.fetusDescription" class="card mb-2">
            <div class="card-body">
                <div class="fw-bold pb-2">{{ "Vaisius" | translate }} {{ fetusDescription.letter }}</div>
                <div class="col">
                    <div class="row">
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="row fw-bold">
                                {{ "Širdies ritmas" | translate }}
                            </div>
                            <div class="row">
                                {{ fetusDescription.heartbeat }}
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="row fw-bold">
                                {{ "Judesiai" | translate }}
                            </div>
                            <div class="row">
                                {{ fetusDescription.movements }}
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="row fw-bold">
                                {{ "Pirmeiga" | translate }}
                            </div>
                            <div class="row">
                                {{ fetusDescription.pathology }}
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="row fw-bold">
                                {{ "Padėtis" | translate }}
                            </div>
                            <div class="row">
                                {{ fetusDescription.position }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">{{ description.footerLabel | translate }}</div>
</div>
