import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-leather-form',
    templateUrl: './sbvf-leather-form.component.html',
})
export class SbvfLeatherFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        this._viewMode = value;
        if (value) {
            this.formGroup.disable();
            this.subGroup.disable();
        }
    };

    @ViewChild('subTable') table: TemplateRef<any>;
    @ViewChild('woundSizeRow') woundSizeRow: TemplateRef<any>;
    leatherRows: Array<PatientHealthAssessmentsFormRow | TemplateRef<any>> = [];

    woundGroup = this.fb.group({
        length: this.fb.control(null, [Validators.required]),
        depth: this.fb.control(null, [Validators.required]),
        width: this.fb.control(null, [Validators.required])
    })

    formGroup = this.fb.group({
        skinCondition: this.fb.control(null, [Validators.required]),
        skinColor: this.fb.control(null, [Validators.required]),
        skinParasites: this.fb.control(null, [Validators.required]),
        nails: this.fb.control(null, [Validators.required]),
        skinDefects: this.fb.control(null, [Validators.required]),
        skinDefectsDetails: this.fb.control({value: null, disabled: true}),
        defectTypes: this.fb.control(null, [Validators.required]),
        localizationOfSkinDefects: this.fb.control(null),
        localizationOfOnsetOfLesions: this.fb.control(null),
        localizationOfWoundTermination: this.fb.control(null),
        wounds: this.fb.control(null, [Validators.required]),
        conditionOfSkinAroundWound: this.fb.control(null, [Validators.required]),
        scent: this.fb.control(null, [Validators.required]),
        signsOfWoundsInfection: this.fb.control(null, [Validators.required]),
        woundPainScale: this.fb.control({value: null, disabled: true}),
        woundSize: this.woundGroup,
        dryNecrosis: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        wetNecrosis: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        granulations: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        epiteliacios: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        exudation: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });

    subGroup = this.fb.group({
        riskOfLeaks: this.fb.control(null, [Validators.required]),
        stateOfHealth: this.fb.control(null, [Validators.required]),
        mentalState: this.fb.control(null, [Validators.required]),
        generalActivity: this.fb.control(null, [Validators.required]),
        agility: this.fb.control(null, [Validators.required]),
        urinaryFaecalIncontinence: this.fb.control(null, [Validators.required]),
        images: this.fb.control<FileList>(null),
    })

    private _viewMode: boolean;
    constructor(
        private readonly fb: FormBuilder
    ) {
        this.formGroup.get('skinDefects').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('skinDefectsDetails').disable();
                    return;
                }
                if (value === '0') {
                    this.formGroup.get('skinDefectsDetails').enable();
                } else {
                    this.formGroup.get('skinDefectsDetails').reset();
                    this.formGroup.get('skinDefectsDetails').disable();
                }
            });
        this.formGroup.get('signsOfWoundsInfection').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('woundPainScale').disable();
                    return;
                }
                if (value === '4') {
                    this.formGroup.get('woundPainScale').enable();
                } else {
                    this.formGroup.get('woundPainScale').reset();
                    this.formGroup.get('woundPainScale').disable();
                }
            });
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.leatherRows = [
            {
                title: 'Odos būklė',
                type: 'choise',
                options: value['skinCondition'],
                formControl: this.formGroup.get('skinCondition')
            },
            {
                title: 'Odos spalva',
                type: 'choise',
                options: value['skinColor'],
                formControl: this.formGroup.get('skinColor')
            },
            {
                title: 'Odos parazitai',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('skinParasites')
            },
            {
                title: 'Nagai',
                type: 'choise',
                options: value['nails'],
                formControl: this.formGroup.get('nails')
            },
            {
                title: 'Odos defektai',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('skinDefects')
            },
            {
                title: 'Odos defektų detalės',
                type: 'text',
                formControl: this.formGroup.get('skinDefectsDetails')
            },
            {
                title: 'Defekto tipai',
                type: 'choise',
                options: value['defectTypes'],
                formControl: this.formGroup.get('defectTypes')
            },
            {
                title: 'Esant odos defektams, jų lokalizacija',
                type: 'text',
                formControl: this.formGroup.get('localizationOfSkinDefects')
            },
            {
                title: 'Žaizdų pradžios vieta',
                type: 'text',
                formControl: this.formGroup.get('localizationOfOnsetOfLesions')
            },
            {
                title: 'Žaizdų pabaigos vieta',
                type: 'text',
                formControl: this.formGroup.get('localizationOfWoundTermination')
            },
            {
                title: 'Žaizdos',
                type: 'choise',
                options: value['wounds'],
                formControl: this.formGroup.get('wounds')
            },
            {
                title: 'Odos aplink žaizdą būklė',
                type: 'choise',
                options: value['skinAroundWounds'],
                formControl: this.formGroup.get('conditionOfSkinAroundWound')
            },
            {
                title: 'Kvapas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('scent')
            },
            {
                title: 'Žaizdos infekcijos požymiai',
                type: 'choise',
                options: value['signsWoundsInfection'],
                formControl: this.formGroup.get('signsOfWoundsInfection')
            },
            {
                title: 'Žaizdos skausmas',
                type: 'choise',
                options: value['scale'],
                customClass: 'w-auto',
                formControl: this.formGroup.get('woundPainScale')
            },
            this.woundSizeRow,
            {
                title: 'Sausos nekrozės (juoda spalva), %',
                type: 'text',
                customClass: 'w-auto',
                textType: 'number',
                formControl: this.formGroup.get('dryNecrosis')
            },
            {
                title: 'Šlapios nekrozės (geltona spalva), %',
                type: 'text',
                customClass: 'w-auto',
                textType: 'number',
                formControl: this.formGroup.get('wetNecrosis')
            },
            {
                title: 'Granuliacijos (raudona spalva), %',
                type: 'text',
                customClass: 'w-auto',
                textType: 'number',
                formControl: this.formGroup.get('granulations')
            },
            {
                title: 'Epiteliacijos (rožinė spalva), %',
                type: 'text',
                formControl: this.formGroup.get('epiteliacios')
            },
            {
                title: 'Eksudacija',
                type: 'choise',
                options: value['exudation'],
                formControl: this.formGroup.get('exudation')
            },
            this.table,
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
