<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Na.form.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-12 col-lg-8">
                <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                   {{'doc.pregnancyDashboard.e096Na.form.cases' | translate}}:
                </span>
                <app-checkbox
                    *ngFor="let option of birthLossCases; let i = index"
                    inputId="actionsTaken{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!editable"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'cases')"
                    (checkboxChange)="updateControl(option.name, 'cases')"
                ></app-checkbox>
            </div>
            <div class="col-12 col-lg-4">
                <app-input
                    type="text"
                    labelText="doc.pregnancyDashboard.e096Na.form.womenDied"
                    class="d-block col-10"
                    [fControl]="formGroup?.get('womenDeath')"
                ></app-input>
                <app-input
                    type="text"
                    labelText="doc.pregnancyDashboard.e096Na.form.causeOfDeath"
                    class="d-block col-10"
                    [fControl]="formGroup?.get('causeOfDeath')"
                ></app-input>
            </div>
        </div>
    </div>
</nhr-record-widget>

<div class="d-flex gap-3 mt-3" *ngIf="editable">
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid" (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger flex-grow-1" (click)="resetForm()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>