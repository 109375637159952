ng1App.controller('doctor.patient.DiagnosisCtrl', 
    ['$scope', '$filter', '$routeParams', '$location', '$modal', 'PatientsApiFactory', 'EntitiesApiFactory', 'MessageService', 'FormLoaderService', 'ConditionsApiFactory', 'DocumentsApiFactory', 'SearchFormSaver',
     function ($scope, $filter, $routeParams, $location, $modal, PatientsApiFactory, EntitiesApiFactory, MessageService, FormLoaderService, ConditionsApiFactory, DocumentsApiFactory, SearchFormSaver) {

    $scope.$emit('setHelpPage', 'perzireti-paciento-diagnoziu-sarasa');

    MessageService.clearAll();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();
    var searchFormSaver = new SearchFormSaver('dp_patient_diagnosis');


    $scope.form = {q: ''};
    $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
    $scope.orderedDiagnosis = [];
    $scope.orderType = 'byCode';
    $scope.filterType = 'all';

    var searchFn = function(params){
        FormLoaderService.startFormLoading();
        return ConditionsApiFactory.findAllByPatient(params, function (data) {
            $scope.diagnosis = data;
            orderDiagnosis($scope.diagnosis);
            FormLoaderService.endFormLoading();
        }, function(res){
            FormLoaderService.endFormLoading();
        });
    };

    var modal = $modal({
        scope: $scope,
        title: 'dpp.mod.all', //'Išplėstinė paieška',
        contentTemplate: '/templates/portal/doctor/patient/diagnosis/search_modal.html'

    });
    $scope.search = function () {
        $scope.cancelErr = 0;
        $scope.documentCancelReason = {};
        if (!$scope.advancedSearch) {
            $scope.initAdvancedSearch();
        }
        modal.$promise.then(function () {
            modal.show();
        });
    };

    $scope.$watch('[orderType, filterType]', function () {
        if ($scope.orderType && $scope.filterType && !$scope.filterTypeWatchDisabled && $scope.diagnosis) {
            $scope.form.q = '';
            $scope.orderedDiagnosis = [];
            orderDiagnosis(getPreparedDiagnosisForOrdering());
        }
        if ($scope.filterTypeWatchDisabled) {
            $scope.filterTypeWatchDisabled = false;
        }
    }, true);

    $scope.openRelatedDocument = function (id) {
        FormLoaderService.startFormLoading();
        DocumentsApiFactory.searchForList({patient: $routeParams.patientId, diagnosis: id, docType: ['e003','e025']}, function(data){
            if(data.length > 0){
                var url = '/dp/patients/' + $routeParams.patientId + '/documents/' + data[0].id;
                $location.path(url).search({});
            } else {
                MessageService.add('error', 'Dokumentas nerastas');
                window.scrollTo(0, 10);
                FormLoaderService.endFormLoading();
            }
        }, function(res){
            FormLoaderService.endFormLoading();
        });
    };

    function upperCase(text) {
        return $filter('uppercase')(text);
    }

    function escapeSearchPhrase(text) {
        return text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    function orderDiagnosis(preparedDiagnosis) {
        switch ($scope.orderType) {
            case 'byStatus':
                $scope.orderDiagnosisByStatus(preparedDiagnosis);
                break;
            case 'byName':
                $scope.orderDiagnosisByName(preparedDiagnosis);
                break;
            case 'byDate':
                $scope.orderDiagnosisByDate(preparedDiagnosis);
                break;
            case 'byCode':
                $scope.orderDiagnosisByCode(preparedDiagnosis);
                break;
        }
    }

    function orderList(listToOrder, predicate) {
        return $filter('orderBy')(listToOrder, predicate);
    }

    function addDiagnoseItemForList(title, diagnose) {
        var existingDiagnoseItem = $filter('filter')($scope.orderedDiagnosis, {title: title})[0];
        if (existingDiagnoseItem) {
            existingDiagnoseItem.diagnosis.push(diagnose);
        }
        else {
            $scope.orderedDiagnosis.push({title: title, diagnosis: [diagnose]});
        }
    }

    function getPreparedDiagnosisForOrdering() {
        if ($scope.filterType === 'all') {
            return $scope.diagnosis;
        } else {
            return $filter('filter')($scope.diagnosis, {status: $scope.filterType });
        }
    }

    $scope.orderDiagnosisByDate = function (preparedDiagnosis) {
        var orderedDiagnosis = orderList(preparedDiagnosis, ['-onsetDate', 'diagnosisCode']);
        angular.forEach(orderedDiagnosis, function (item) {
            addDiagnoseItemForList($filter('date')(item.onsetDate, 'yyyy-MM-dd'), item);
        })
    };

    $scope.orderDiagnosisByCode = function (preparedDiagnosis) {
        var orderedDiagnosis = orderList(preparedDiagnosis, 'diagnosisCode');
        angular.forEach(orderedDiagnosis, function (item) {
            addDiagnoseItemForList(item.diagnosisCode, item);
        })
    };

    $scope.orderDiagnosisByName = function (preparedDiagnosis) {
        var orderedDiagnosis = orderList(preparedDiagnosis, 'diagnosisText');
        angular.forEach(orderedDiagnosis, function (item) {
            addDiagnoseItemForList(item.diagnosisText, item);
        })
    };

    $scope.orderDiagnosisByStatus = function (preparedDiagnosis) {
        var orderedDiagnosis = orderList(preparedDiagnosis, ['status', 'diagnosisCode']);
        angular.forEach(orderedDiagnosis, function (item) {
            addDiagnoseItemForList(item.status, item);
        })
    };

    $scope.simpleSearch = function () {
        $scope.orderedDiagnosis = [];
        var filteredDiagnosis = filterListByRegExp(getPreparedDiagnosisForOrdering(), simpleSearchRegexTest, $scope.form.q);
        orderDiagnosis(filteredDiagnosis);
    };

    var simpleSearchRegexTest = function (item, regex, filteredItems) {
        var date = $filter('date')(item.date, 'yyyy-MM-dd');
        if (regex.test(upperCase(item.diagnosisCode) + ' ' + upperCase(item.diagnosisText)) || regex.test(date)) {
            filteredItems.push(item);
        }
    };

    function filterListByRegExp(listToFilter, regexTestFunction, text) {
        var filteredItems = [];
        var pattern = ".*" + escapeSearchPhrase(upperCase(text)) + ".*";
        var regExp = new RegExp(pattern);
        angular.forEach(listToFilter, function (item) {
            regexTestFunction(item, regExp, filteredItems);
        });
        return filteredItems;
    }

    $scope.clearSearch = function () {
        $scope.form.q = '';
        $scope.advancedSearch = null;
        $scope.orderedDiagnosis = [];
        if ($scope.filterType != 'all') {
            $scope.filterTypeWatchDisabled = true;
            $scope.filterType = 'all';
        }
        doSimpleSearch();
    };

    var makeSearchParams = function(){
        console.log($scope.advancedSearch);
        var form: any = {patientId: $routeParams.patientId, compositionType: [], organization: [], practitioner: [], status: []};
        if($scope.advancedSearch.docs){
            _.each($scope.advancedSearch.docs, function(d){
                if(d.selected) form.compositionType.push(d.code.toLowerCase());
            });
        }
        if($scope.advancedSearch.eOccurDate){
            form.occurDateTo = $scope.advancedSearch.eOccurDate.date;
        }
        if($scope.advancedSearch.sOccurDate){
            form.occurDateFrom = $scope.advancedSearch.sOccurDate.date;
        }
        if($scope.advancedSearch.eServiceDate){
            form.serviceDateTo = $scope.advancedSearch.eServiceDate.date;
        }
        if($scope.advancedSearch.sServiceDate){
            form.serviceDateFrom = $scope.advancedSearch.sServiceDate.date;
        }
        _.each($scope.advancedSearch.organizations, function(o){
            form.organization.push(o.id);
        });
        _.each($scope.advancedSearch.practitioners, function(p){
            form.practitioner.push(p.id);
        });
        if($scope.advancedSearch.status){
            if($scope.advancedSearch.status.confirmed){
                form.status.push('confirmed');
            }
            if($scope.advancedSearch.status.refuted){
                form.status.push('refuted');
            }
        }
        return form;
    };


    $scope.performAdvancedSearch = function () {
        searchFormSaver.saveSearch($scope.advancedSearch);
        doAdvancedSearch();
        modal.$promise.then(function () {
            modal.hide();
        });
    };

    var doAdvancedSearch = function(){
        $scope.orderedDiagnosis = [];
        searchFn(makeSearchParams());
    };

    var doSimpleSearch = function(){
        searchFn({patientId: $routeParams.patientId});
    };


    $scope.cleanAdvancedForm = function () {
        searchFormSaver.removeSaved();
        $scope.initAdvancedSearch();
    };

    $scope.initAdvancedSearch = function (){
        $scope.advancedSearch = {
            status: {},
            practitioners: [],
            organizations: []
        };
        EntitiesApiFactory.getList({classCode: "data-set"}, function (data) {
            $scope.advancedSearch.docs = $filter('filter')(data, function (doc) {
               return doc.code == 'E025' || doc.code == 'E003';
            });
        });
    };


    //-- for correct visual showing
    $scope.diagnosisMatrix = [];
    $scope.listToMatrix = function (list, elementsPerSubArray) {
        var matrix = [], i, k;

        for (let i = 0, k = -1; i < list.length; i++) {
            if (i % elementsPerSubArray === 0) {
                k++;
                matrix[k] = [];
            }

            matrix[k].push(list[i]);
        }

        return matrix;
    };

    $scope.$watch('orderedDiagnosis', function () {
        $scope.diagnosisMatrix = $scope.listToMatrix($scope.orderedDiagnosis, 3);
    }, true);


    if (searchFormSaver.isActiveSearch()) {
        $scope.advancedSearch = searchFormSaver.localLoad();
        doAdvancedSearch();
    } else {
        doSimpleSearch();
    }
}]);