import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColDef, FirstDataRenderedEvent, GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { Observable } from "rxjs";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { RouteParams } from "../../../../../../ng1.routeParams";
import { AgColHeaderLink } from "../../../../../../shared/components/ag-grid/components/ag-col-header-link/ag-col-header-link.component";
import { AgPaginationBarComponent } from "../../../../../../shared/components/ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";

import { AgGridCommonConfig } from "../../../../../../shared/configs/ag-grid-common.config";
import { VisitAgActionsCell } from "../../../shared/visit-ag-actions-cell/visit-ag-actions-cell.component";
import { States } from "./all-visits.constants";

@Component({
    selector: "aspn-patient-all-visits",
    templateUrl: "./all-visits.component.html",
})
export class AllVisitsComponent implements OnInit {
    @ViewChild(AgPaginationBarComponent) paginationBar: AgPaginationBarComponent;
    backPath: string;

    constructor(private dpAspnContextService: DpAspnContextService, private params: RouteParams, private router: Router,) { }
    private patientId: string;

    async ngOnInit(): Promise<void> {
        this.patientId = this.params.patientId;
        this.backPath = '/dp/patients/' + this.patientId + '/aspn/summary'
    }

    getOptions() {
        return { ...AgGridCommonConfig }
    }

    gridOptions = this.getOptions()

    public allVisitsColDefs: ColDef[] = [
        { field: "visitDate", headerName: "Apsilankymo data", minWidth: 130 },
        { field: "status", headerName: "Būsena", cellRenderer: statusCellRender },
        {
            field: "urgent", width: 100, headerName: "Skuba",
            cellRenderer: (params) => urgentCellRender(params, "visitProcedures"),
            cellStyle: { justifyContent: 'center' }
        },
        {
            field: "visitPractitioner", headerName: "Specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            field: "visitProcedures",
            width: 130,
            headerName: "Procedūros",
            cellRenderer: proceduresCellRender,
            cellStyle: { justifyContent: 'center' }
        },
        {
            minWidth: 130,
            field: "actionButton",
            headerComponent: AgColHeaderLink,
            headerComponentParams(params) {
                return {
                    linkString: "+ Kurti naują apsilankymą",
                    routerLinkTo: "/dp/aspn/spv/1",
                }
            },
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: VisitAgActionsCell,
            cellRendererParams(params) {
                return {
                    visitId: params.data.visitId,
                    periodVisitId: params.data.periodVisitId
                }
            }
        }
    ];

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        autoHeight: true,
        wrapText: true,
    };

    getContext() {
        return {
            patientId: this.patientId,
        };
    }

    public allVisitsData$!: Observable<any[]>;

    onGridReady(params: GridReadyEvent) {
        this.allVisitsData$ = this.dpAspnContextService.getAllVisitsByPatientId(this.patientId);
        this.paginationBar.initForAgGrid(params);
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }
}

function statusCellRender(params: ValueFormatterParams) {
    if (params.value === "Planned")
        return `<span class="badge rounded-pill text-bg-primary text-white" style="width: 120px;">${States[params.value]}</span>`;
    if (params.value === "Cancelled")
        return `<span class="badge rounded-pill text-bg-secondary text-white" style="width: 120px;">${States[params.value]}</span>`;
    if (params.value === "Visited")
        return `<span class="badge rounded-pill text-bg-success text-white" style="width: 120px;">${States[params.value]}</span>`;
}

function urgentCellRender(params: ValueFormatterParams, proceduresField: string) {

    for (let i of params.data[proceduresField]) {
        if (i.urgent) {
            return `<span class="position-absolute translate-middle p-2 bg-danger rounded-circle">
                        <span class="visually-hidden">${i.urgent}</span>
                    </span>`;
        }
    }
}

function proceduresCellRender(params: ValueFormatterParams) {
    var txt = "";
    for (var p of params.value) {
        txt = p.procedures.map((m) => m.name).join(", ");
    }
    return `<span class="badge rounded-pill text-bg-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="${txt}">?</span>`;
}