ng1App.controller('doctor.user.AccountViewCtrl', 
    ['$scope', 'DpSessionContext', 'MessageService', 'PractitionersApiFactory',
     function ($scope, DpSessionContext, MessageService, PractitionersApiFactory) {
    $scope.$emit('setHelpPage', 'perzireti-specialisto-duomenis');
    MessageService.clear();
    MessageService.showSuccessEventMessage();
    MessageService.unregisterSuccessEvent();
	$scope.practitioner = {};
	
	DpSessionContext.practitioner.$promise.then(function(a){
		$scope.practitionerId = DpSessionContext.practitioner.id;
		$scope.load();
	});
	
	$scope.load = function() {
		PractitionersApiFactory.getFull({id: $scope.practitionerId}, function (data) {
			$scope.practitioner = data;
			$scope.practitioner.fullName = _.getFullName(data);
        });
	};

}]);