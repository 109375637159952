<app-title showFilterButton="true">
    {{'090/a - Pranešimai apie diagnozuotą onkologinį susirgimą'| translate}}
</app-title>
<div class="row">
    <div class="col-sm-12">
        <div class="page-list list-group mb-2">
            <custom-search [name]="'quick_search'" [isAdvanced]="true" (filterService)="loadDocRefConsentList($event)">
                <f090-search (onSearch)="loadDocRefConsentList($event)" [filtersGroup$]="filtersGroup$"/>
            </custom-search>
            <doc-ref-f090-card
                *ngFor="let docRefConsent of docRefConsents"
                [data]="docRefConsent"
                [onEditClick]="onEditClick"
                [onCardClick]="onCardClick"
                [portalType]="portalType"
                [patientId]="patientId"
                [ngClass]="'list-group-item'"
                role="button"
            />
        </div>
        <div *ngIf="loadMoreActive" class="container d-flex justify-content-center align-items-center">
            <div class="row mb-20">
                <load-more-button (click)="loadMore()" [working]="working"/>
            </div>
        </div>
    </div>
</div>
