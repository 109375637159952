<!-- TODO-design: Refactor escape(labelText)! -->
<!-- TODO-design: flex-grow-1 should be as a prop of the component via labelClass -->
<span *ngIf="labelText" class="form-label flex-grow-1 col-form-label fw-bold" [class]="labelClass">{{ labelText |
    translate }}
</span>
<div class="input-group" [class]="inputGroupClass">
    <input *ngIf="!viewMode && type !== 'textarea'" class="form-control" [type]="type" [class]="inputClass"
        [formControl]="firstControl" [readonly]="readonly"
        [ngClass]="{ 'is-invalid': (firstControl?.invalid && firstControl.touched) || error }" [pattern]="pattern"
        [step]="numberStep" [placeholder]="placeholder" [min]="min" [max]="max"
        (blur)="formatValue(firstControl)"
        (keydown.enter)="listenEnterPress && onEnterKey($event)" />
    <span class="input-group-text">{{ textSeparator | translate }}</span>
    <input *ngIf="!viewMode && type !== 'textarea'" class="form-control" [type]="type" [class]="inputClass"
           [formControl]="secondControl" [readonly]="readonly"
           [ngClass]="{ 'is-invalid': (secondControl?.invalid && secondControl.touched) || error }" [pattern]="pattern"
           [step]="numberStep" [placeholder]="placeholder" [min]="min" [max]="max"
           (blur)="formatValue(secondControl)"
           (keydown.enter)="listenEnterPress && onEnterKey($event)" />


    <input *ngIf="viewMode && type !== 'textarea'" class="form-control" [class]="inputClass"
        [readonly]="viewMode" [value]="value || firstControl?.value" />
    <span *ngIf="viewMode && type !== 'textarea'" class="input-group-text">{{ textSeparator | translate }}</span>
    <input *ngIf="viewMode && type !== 'textarea'" class="form-control" [class]="inputClass"
        [readonly]="viewMode" [value]="value || secondControl?.value" />

    <span *ngIf="!!units" class="input-group-text">{{ units | translate }}</span>
    <span *ngIf="!units && suffixIcon" class="input-group-text"><i [class]="suffixIcon"></i></span>
</div>
<app-error-message [errorMessage]="error" />
