import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    controlIsDisabled,
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay } from 'rxjs';
import { validateBloodPressure } from '../../../../e096-utils/custom-validation';

@Component({
    selector: 'app-e096-part-newborn',
    templateUrl: './e096-part-newborn.component.html',
})
export class E096PartNewbornComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    @Input() fruit: string;
    currentTime = new Date();
    formGroup: FormGroup;
    amountOfAmnioticFluidOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Mažai'
        },
        {
            id: '1',
            code: '1',
            name: 'Vidutiniškai'
        },
        {
            id: '2',
            code: '2',
            name: 'Gausiai'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            timeOfAmniocentesis: this.fb.control(new Date(), [Validators.required]),
            colorAmnioticFluid: this.fb.control(null, [Validators.required]),
            amountOfAmnioticFluid: this.fb.control(null, [Validators.required]),
            firstPart: this.fb.control(null),
        });
    }
}
