import {ErxSupplyDisruptionTypeEnum} from "../../../../../../shared/models/erx-supply-disruption-type.enum";

angular.module('erx.utils').directive('erxDrugSupplyDisruptions', function () {
    return {
        templateUrl: 'modules/erx-utils/components/erx-drug-supply-disruptions/erxDrugSupplyDisruptions.tpl.html',
        restrict: 'E',
        scope: {
            patientId: '=',
            disruptions: '=',
            onClick: '&'
        },
        controller: ["$scope", function ($scope) {
            $scope.getNgClass = function (type) {
                switch (type){
                    case ErxSupplyDisruptionTypeEnum.SUPPLIED:
                    case ErxSupplyDisruptionTypeEnum.SUPPLY_RENEWED:
                    case null:
                        return "text-success";
                    case ErxSupplyDisruptionTypeEnum.TEMPORARY_SUPPLY_DISRUPTION:
                    case ErxSupplyDisruptionTypeEnum.NOT_STARTED_TO_SUPPLY:
                        return "text-warning";
                    case ErxSupplyDisruptionTypeEnum.SUPPLY_SUSPENDED:
                        return "text-danger";
                }
            }
        }]
    };
});