/**
 * Created by sergei on 19.08.15.
 */
import {IFramePage} from "iframe-resizer";

ng1App.service('IntegratedComponentService', function () {

  var isConfigured = false;

  this.configure = function () {
    if (isConfigured) return;
    //mutation observer will find all created modals and append 'data-iframe-height' attribute
    //which will be used by iframe-resizer
    observeAndModifyModals();
    //modal's overflow property has to be 'hidden' to hide scrollbars that would otherwise be shown
    changeCss('.modal', 'overflow', 'hidden');
    changeCss('.modal', 'overflow-y', 'hidden');
    changeCss('#wrap', 'background-image', 'none');
    changeCss('#wrap', 'background-color', '#ffffff');
    isConfigured = true;
  };

  this.checkExpiredSession = function (restrictedMode) {
    if (!restrictedMode && isConfigured && ('parentIFrame' in window)) {
      const parentIFrame = window["parentIFrame"] as IFramePage
      parentIFrame.sendMessage({reloadContent: true});
    }
  };


  var observeAndModifyModals = function () {
    // well.. WebKitMutationObserver is not the standard therefore type assertion is required
    MutationObserver = window.MutationObserver || (window as any).WebKitMutationObserver;
    var observer = new MutationObserver(function (mutations, observer) {
      for (var i = 0; i < mutations.length; i++) {
        for (var j = 0; j < mutations[i].addedNodes.length; j++) {
          var addedNode = mutations[i].addedNodes[j] as Element;
          if (addedNode.classList && addedNode.classList.contains('modal') && addedNode.childNodes) {
            for (var k = 0; k < addedNode.childNodes.length; k++) {
              var node = addedNode.childNodes[k] as Element;
              if (node.classList && node.classList.contains('modal-dialog')) {
                node.setAttribute('data-iframe-height', '');
                if ('parentIFrame' in window) {
                  const parentIFrame = window["parentIFrame"] as IFramePage
                  parentIFrame.size();
                }
              }
            }
          }
        }
      }
    });
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  };

  var changeCss = function (theClass, element, value) {
    //http://www.shawnolson.net/a/503/altering-css-class-attributes-with-javascript.html
    var cssRules;
    for (var S = 0; S < document.styleSheets.length; S++) {
      try {
        document.styleSheets[S].insertRule(theClass + ' { ' + element + ': ' + value + '; }', document.styleSheets[S][cssRules].length);
      } catch (err) {
        try {
          document.styleSheets[S].addRule(theClass, element + ': ' + value + ';');
        } catch (err) {
          try {
            if (document.styleSheets[S]['rules']) {
              cssRules = 'rules';
            } else if (document.styleSheets[S]['cssRules']) {
              cssRules = 'cssRules';
            } else {
              //no rules found... browser unknown
            }
            for (var R = 0; R < document.styleSheets[S][cssRules].length; R++) {
              if (document.styleSheets[S][cssRules][R].selectorText == theClass) {
                if (document.styleSheets[S][cssRules][R].style[element]) {
                  document.styleSheets[S][cssRules][R].style[element] = value;
                  break;
                }
              }
            }
          } catch (err) {
          }
        }
      }
    }
  }
});