<nhr-record-widget
    [closable]="true"
    [closed]="closed"
    [title]="'E113-ND | Nėštumo duomenys, anamnezė' | translate"
    id="pregnancyData"
    class="h-auto"
>
    <div class="card mb-3">
        <div class="card-header">{{ "Nėštumo duomenys" | translate }}</div>
        <div class="card-body">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row ms-0">
                        <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                            <div class="row fw-bold">{{ "Pirmo apsilankymo data" | translate }}</div>
                            <div class="row">
                                {{ (pregnancyInfo | async)?.firstVisitDate }}
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                            <div class="row fw-bold">{{ "Nėštumas" | translate }}</div>
                            <div class="row">
                                {{ (pregnancyInfo | async)?.pregnancy }}
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                            <div class="row fw-bold">{{ "Gimdymas" | translate }}</div>
                            <div class="row">
                                {{ (pregnancyInfo | async)?.birth }}
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                            <div class="row fw-bold">{{ "Folinės r. vartojimas" | translate }}</div>
                            <div class="row">
                                {{ foilUsing | async }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-n3">
                <div class="col-lg-12 col-xl-6">
                    <div class="card mb-3">
                        <div class="card-header">{{ "Mėnesinės" | translate }}</div>
                        <div class="card-body">
                            <div class="row ms-0">
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">{{ "Mėnesinių ciklas" | translate }}</div>
                                    <div class="row">
                                        {{ MenstrualCycles[(pregnancyInfo | async)?.menstrualCycle] }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">{{ "Mėnesinių trukmė" | translate }}</div>
                                    <div class="row">
                                        {{ (pregnancyInfo | async)?.menstruationDuration }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">{{ "Ciklo trukmė" | translate }}</div>
                                    <div class="row">
                                        {{ (pregnancyInfo | async)?.cycleDuration }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">
                                        {{ "Paskutinių normalių mėnesinių data" | translate }}:
                                    </div>
                                    <div class="row">
                                        {{ (pregnancyInfo | async)?.lastMenstruationPeriod }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <div class="card mb-3">
                        <div class="card-header">
                            {{ "Gimdymo terminas" | translate }}
                        </div>
                        <div class="card-body">
                            <div class="row ms-0">
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">{{ "Patvirtintas terminas" | translate }}</div>
                                    <div class="row">
                                        {{ (pregnancyInfo | async)?.confirmedDeadline }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">{{ "Pagal paskutines mėnesinės" | translate }}</div>
                                    <div class="row">
                                        {{ calculatedLastWomansPeriodDate | date : "yyyy-MM-dd" }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">
                                        {{ "Gimdymo terminas pagal ultragarso tyrimą" | translate }}
                                    </div>
                                    <div class="row">
                                        {{ (pregnancyInfo | async)?.ultrasoundDate }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
                                    <div class="row fw-bold">
                                        {{ "Preliminari nėštumo ir gimdymo atostogų pradžios data" | translate }}
                                    </div>
                                    <div class="row">
                                        {{ addDaysToDate(pregnancyFirstDate, 210) | date : "yyyy-MM-dd" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-3">
        <div class="card-header">{{ "Anamnezė" | translate }}</div>
        <div class="card-body">
            <div class="row mb-n3">
                <div class="col-lg-12 col-xl-6 d-flex flex-column">
                    <div class="card mb-3">
                        <div class="card-header">{{ "Šeimos anamnezė" | translate }}</div>
                        <div class="card-body">
                            <div class="row ms-0" *ngFor="let item of familyAnamnesis">
                                <div class="col-sm-6">
                                    <div class="row">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                        {{ item.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header">{{ "Moters žalingi įpročiai" | translate }}</div>
                        <div class="card-body">
                            <div class="row ms-0" *ngFor="let item of womanAddictions">
                                <div class="col-sm-6">
                                    <div class="row">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                        {{ item.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 flex-grow-1">
                        <div class="card-header">{{ "Moters persirgtos ligos" | translate }}</div>
                        <div class="card-body">
                            <div class="row ms-0">
                                {{ womanDiseases }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6 d-flex flex-column">
                    <div class="card mb-3">
                        <div class="card-header">{{ "Vaiko biologinis tėvas" | translate }}</div>
                        <div class="card-body">
                            <div class="row ms-0">
                                <div class="col-md-6">
                                    <div class="row fw-bold">{{ "Amžius, m." | translate }}</div>
                                    <div class="row">
                                        {{ (anamnesis | async)?.biologicalFather.age }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row fw-bold">{{ "Žalingi įpročiai" | translate }}</div>
                                    <div class="row">
                                        {{ biologicalFatherAddictions }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header">{{ "Moters skiepai, alergijos" | translate }}</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="fw-bold">{{ "Alergijos:" | translate }}</div>
                                        <div class="col">
                                            <div class="row pt-2" *ngFor="let allergy of allergies">
                                                <small>{{ allergy.date | date : "yyyy-MM-dd h:mm" }}</small>
                                                <span>{{ allergy.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="fw-bold">{{ "Skiepai:" | translate }}</div>
                                        <div class="col">
                                            <div class="row pt-2" *ngFor="let vaccination of vaccinations">
                                                <small>{{ vaccination.date | date : "yyyy-MM-dd h:mm" }}</small>
                                                <span>{{ vaccination.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-2 ms-0">
                                <div class="col">
                                    <div class="row fw-bold">
                                        {{ "Alergijos, neregistruotos pacientės ESI" | translate }}
                                    </div>
                                    <div class="row">
                                        {{ (anamnesis | async)?.womanAllergies }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 flex-grow-1">
                        <div class="card-header">{{ "Moters KMI" | translate }}</div>
                        <div class="card-body">
                            <div class="row ms-0">
                                <div class="col-sm-12 col-md-4">
                                    <div class="row fw-bold">{{ "Svoris" | translate }}</div>
                                    <div class="row">
                                        {{ (anamnesis | async)?.womanKmi.weight }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <div class="row fw-bold">{{ "Ūgis" | translate }}</div>
                                    <div class="row">
                                        {{ (anamnesis | async)?.womanKmi.height }}
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <div class="row fw-bold">{{ "KMI" | translate }}</div>
                                    <div class="row">
                                        {{ calculatedKmi }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TODO: Waiting for Iteration2 -->
    <div class="card mb-3">
        <div class="card-header">{{ "Ankstesni nėštumai ir jų baigtys" | translate }}</div>
        <div class="card-body">
            <div class="card mb-3">
                <div class="card-header">{{ "Nėščiosios informacija iš Pacientės ESI" | translate }}</div>
                <div class="card-body">
                    <div>Ruošiama</div>
                </div>
            </div>
            <div class="card mb-3">
                <div class="card-header">{{ "Nėščiosios pateikta informacija" | translate }}</div>
                <div class="card-body">
                    <div>Ruošiama</div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    {{ "Papildomai nėščiosios pateikiama informacija apie kitus ankstesnius nėštumus" | translate }}
                </div>
                <div class="card-body mb-n3">
                    Ruošiama
                    <!-- <div class="row">
                        <div class="col-lg-12 col-xl-6 d-flex">
                            <div class="card mb-3 flex-grow-1">
                                <div class="card-header">{{ "Nėštumas" | translate }}</div>
                                <div class="card-body">
                                    <div class="col">
                                        <div class="row"></div>
                                        <div class="row">
                                            <div class="col-md-6"></div>
                                            <div class="col-md-6"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xl-6">
                            <div class="card mb-3">
                                <div class="card-header">{{ "Gimdymas" | translate }}</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <app-select
                                                id="childbirthMode"
                                                labelText="Gimdymo būdas"
                                                [values]="childbirthModesArray"
                                                [fControl]="formGroup?.get('previousPregnancies.childbirthMode')"
                                            />
                                        </div>
                                        <div
                                            *ngIf="
                                                formGroup?.get('previousPregnancies.childbirthMode').value ===
                                                childbirthModes.OnNaturalPathsUseAids
                                            "
                                            class="col-md-6"
                                        >
                                            <div class="fw-bold pb-2">{{ "Pagalbinės procedūros" | translate }}</div>
                                            <div class="row" *ngFor="let procedure of auxiliaryProcedures">
                                                <app-checkbox
                                                    (change)="
                                                        onCheckboxChange(
                                                            formGroup,
                                                            $event,
                                                            'previousPregnancies',
                                                            'auxiliaryProcedures'
                                                        )
                                                    "
                                                    [checkboxValue]="procedure"
                                                    [labelText]="procedure"
                                                    [checkboxTitle]="procedure"
                                                    [checkboxChecked]="
                                                        formGroup
                                                            .get('previousPregnancies.auxiliaryProcedures')
                                                            .value.includes(procedure)
                                                    "
                                                ></app-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
