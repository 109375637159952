<div class="modal-header">
    <h5 class="modal-title">{{'doc.dia.not.included' | translate}}</h5>
    <button type="button"  class="btn-close close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

<div class="modal-body p-0 diagnosis-by-encounter">
    <ol *ngIf="currentDiagnoses?.length" class="slats hover{{ (currentDiagnoses?.length ?? 0) > 0 ? ' border-bottom' : ''}} border-dark-subtle">
        <li *ngFor="let diagnose of currentDiagnoses; let i = index"
            class="diagnosis-card d-flex gap-3{{ diagnose.type?.code === 'leading' ? ' bg-success-subtle' : '' }}">
            <div>
                <input type="checkbox" class="form-check-input" [(ngModel)]="diagnose.selected" (change)="onDiagnosisSelectionChange()" />
            </div>
            <div class="diagnosis-content">
                <div class="diagnosis-header">
                    <strong>{{ diagnose.code.code }} {{ diagnose.code.name }}</strong>
                </div>

                <div class="diagnosis-details small text-secondary">
                    <span *ngIf="diagnose.onsetDate">{{ diagnose.onsetDate | date: 'YYYY-MM-dd' }}</span>
                    <span *ngIf="diagnose.onsetDate" class="vr"></span>
                    <span *ngIf="diagnose.endDate"> - {{ diagnose.endDate | date: 'YYYY-MM-dd' }}</span>
                    <span *ngIf="diagnose.endDate" class="vr"></span>
                    <span *ngIf="diagnose.clinicalStatus">Būsena: <strong class="text-body">{{ diagnose.clinicalStatus.name }}</strong></span>
                    <span *ngIf="diagnose.clinicalStatus" class="vr"></span>
                    <span *ngIf="diagnose.verificationStatus">Tikrumas: <strong class="text-body">{{ diagnose.verificationStatus.name }}</strong></span>
                    <span *ngIf="diagnose.verificationStatus" class="vr"></span>
                    <span *ngIf="diagnose.severity">Sunkumas: <strong class="text-body">{{ diagnose.severity.name }}</strong></span>
                    <span *ngIf="diagnose.severity" class="vr"></span>
                    <span *ngIf="diagnose.category">Kategorija: <strong class="text-body">{{ diagnose.category.name }}</strong></span>
                </div>

                <div *ngIf="diagnose.notes" class="diagnosis-notes small text-secondary mt-2">
                    Aprašymas: {{ diagnose.notes }}
                </div>
            </div>
        </li>
    </ol>

    <div class="p-4" *ngIf="currentDiagnoses?.length === 0">
        <p>No diagnoses available.</p>
    </div>
</div>
<div class="modal-footer row">
    <div class="col">
        <input type="checkbox" class="form-check-input" [(ngModel)]="selectAll" (change)="toggleSelectAll()" /> Pasirinkti Visus
    </div>
    <div class="col-auto d-flex gap-3">
        <button class="btn btn-primary" (click)="includeSelectedDiagnoses()">+ Įtraukti</button>
        <button class="btn btn-secondary" (click)="close()">x Atšaukti</button>
    </div>
</div>