ng1App.controller('doctor.patient.VaccinesAddNewNationalCtrl', 
    ['$scope', '$location', '$modalConfirm', '$filter', '$routeParams', 'SubFormValidator', 'VaccinationsApiFactory', 'MessageService', 'ClassifiersApiFactory', 'EntitiesApiFactory',
     function ($scope, $location, $modalConfirm, $filter, $routeParams, SubFormValidator, VaccinationsApiFactory, MessageService, ClassifiersApiFactory, EntitiesApiFactory) {
    MessageService.clearAll();
    
    $scope.$emit('setHelpPage', 'ivesti-planuojamus-skiepus-pagal-nacionalini-arba-baltipa-skiepu-kalendoriu');
    
    $scope.form = {
        patientId: $routeParams.patientId,
        dateFrom: _.truncateToDays(new Date()),
        type: 'national'
    };
    VaccinationsApiFactory.getSuggestedDate({type: 'national', patientId: $routeParams.patientId}, function(res){
        $scope.form.dateTo = res.value;
    },function(err){
        if (err.status == 500) {
            MessageService.handleError(err.data, MessageService);
        } else if (err.status == 400) {
            _.each(err.data.globalErrors, function (i) {
                if (i.msg == 'birth_date') {
                    MessageService.error('Nenurodyta paciento gimimo data.');
                } else {
                    MessageService.error(i.msg);
                }
            });
        }
    });

    $scope.generate = function(){
        if (validateForm($scope.form)) {
            $scope.loading = true;
            VaccinationsApiFactory.generate($scope.form,function(res){
                if(res.value == 0){
                    MessageService.add('error', 'Pagal pateiktus parametrus sugeneruoti skiepų nepavyko');
                }else {
                    MessageService.registerSuccessEvent();
                    $scope.goBack();
                }
                $scope.loading = false;
            },function(err){
                if (err.status == 500) {
                    MessageService.handleError(err.data, MessageService);
                } else if (err.status == 400) {
                    _.each(err.data.globalErrors, function (i) {
                        if (i.msg == 'birth_date') {
                            MessageService.error('Nenurodyta paciento gimimo data.');
                        } else {
                            MessageService.error(i.msg);
                        }
                    });
                }
                $scope.loading = false;
            });
        } else {
            $scope.validator.showErrorsAndScroll();
            $scope.loading = false;
        }
    };


    $scope.generated = false;
    $scope.preGenerate = function(){
        if (validateForm($scope.form)) {
            $scope.loading = true;
            VaccinationsApiFactory.preGenerate($scope.form,function(res){
                var result = res['value'];
                if(result.value == 0){
                    MessageService.add('error', 'Pagal pateiktus parametrus sugeneruoti skiepų nepavyko');
                }else {
                    $scope.plannedVaccinations = result;
                    $scope.generated = true;
                }
                $scope.loading = false;
            },function(err){
                if (err.status == 500) {
                    MessageService.handleError(err.data, MessageService);
                } else if (err.status == 400) {
                    _.each(err.data.globalErrors, function (i) {
                        if (i.msg == 'birth_date') {
                            MessageService.error('Nenurodyta paciento gimimo data.');
                        } else {
                            MessageService.error(i.msg);
                        }
                    });
                }
                $scope.loading = false;
            });
        } else {
            $scope.validator.showErrorsAndScroll();
            $scope.loading = false;
        }
    };
    
    $scope.goBack = function () {
        window.history.back();
    };
    $scope.currentTime= _.truncateToDays(new Date().getTime());
    $scope.clean = function(){
        $modalConfirm.show(
            { title : $filter('translate')('inf.clean.form.tit'), content : $filter('translate')('inf.clean.form') } ).then(function() {
                $scope.plannedVaccinations = [];
                $scope.generated = false;
        });
    };

    $scope.validator = new SubFormValidator('vaccine-region');
    var validateForm = function (data) {
        MessageService.clearAll();
        $scope.validator.clear();
        if (!data.dateTo) {
            $scope.validator.add('dateTo');
        }
        return !$scope.validator.hasErrors();
    };
    
}]);