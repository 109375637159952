import {Component} from "@angular/core";

@Component({
    selector: "app-e030-view",
    templateUrl: "./e030-view.component.html",
})
export class E030ViewComponent {
    editable: boolean = false;

    constructor() {

    }
}
