import {
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {distinctUntilChanged, map, Observable, Observer, of, Subscription, switchMap} from "rxjs";
import {EntityModel} from "../../../models/entity.model";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ApiV2Entities} from "../../../services/api-v2-entities";
import {FormCommunicationService} from "../../../services/utils/form-communication.service";
import {FormCommunicationModel} from "../../modal/form-communication.model";

@Component({
    selector: "medication-dosage",
    templateUrl: "./medication-dosage.component.html",
})
export class MedicationDosageComponent implements OnInit, OnChanges, OnDestroy {

    @Input() dosageSummary: string = "";
    @Input() dosageInstructionData: any;
    @Input() frequencyMeasurements: string[] = [];
    @Input() intakeTimes: string[] = [];
    @Input() isDispense: boolean = false;
    @Input() isPaperDispense: boolean = false;
    @Input() collapsed: boolean = true;
    @Output() formValuesChange = new EventEmitter<any>();
    @Output() clearDosageSummary = new EventEmitter<any>();
    currentDate = new Date();
    form: FormGroup;
    consumptionSuggestions$: Observable<EntityModel[]>;
    pharmFormSuggestions$: Observable<EntityModel[]>;
    pharmFormSuggestionsDaily$: Observable<EntityModel[]>;
    private validationSubscription: Subscription;

    constructor(private fb: FormBuilder, private destroyRef: DestroyRef, private apiV2Entities: ApiV2Entities,
                private formCommunicationService: FormCommunicationService) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        /*  if ('dosageSummary' in changes) {
              this.refreshForm();
          }*/
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            dosageInstructionData: new FormGroup({
                consumptionType: new FormGroup({
                    code: new FormControl(this.dosageInstructionData?.route?.code),
                    displayValue: new FormControl(this.dosageInstructionData?.route?.displayValue),
                }),
                dosePerDay: new FormGroup({
                    value: new FormControl(this.dosageInstructionData?.dosePerDay?.value),
                    unit: new FormGroup({
                        code: new FormControl(this.dosageInstructionData?.dosePerDay?.unit?.code),
                        displayValue: new FormControl(this.dosageInstructionData?.dosePerDay?.unit?.displayValue),
                    })
                }),
                doseQuantity: new FormGroup({
                    value: new FormControl(this.dosageInstructionData?.doseQuantity?.value),
                    unit: new FormGroup({
                        code: new FormControl(this.dosageInstructionData?.doseQuantity?.unit?.code),
                        displayValue: new FormControl(this.dosageInstructionData?.doseQuantity?.unit?.displayValue),
                    })
                }),
                schedule: new FormGroup({
                    frequency: new FormControl(this.dosageInstructionData?.schedule?.frequency),
                    units: new FormControl(this.dosageInstructionData?.schedule?.units),
                }),
                timeQuantity: new FormControl(this.dosageInstructionData?.timeQuantity),
                morning: new FormControl(this.dosageInstructionData?.morning),
                noon: new FormControl(this.dosageInstructionData?.noon),
                evening: new FormControl(this.dosageInstructionData?.evening),
                beforeMeal: new FormControl(this.dosageInstructionData?.beforeMeal),
                duringMeal: new FormControl(this.dosageInstructionData?.duringMeal),
                afterMeal: new FormControl(this.dosageInstructionData?.afterMeal),
                independMeal: new FormControl(this.dosageInstructionData?.independMeal),
                asNeeded: new FormControl(this.dosageInstructionData?.asNeeded),
                beforeSleep: new FormControl(this.dosageInstructionData?.beforeSleep),
                route: new FormGroup({
                    code: new FormControl(this.dosageInstructionData?.route?.code),
                    displayValue: new FormControl(this.dosageInstructionData?.route?.displayValue),
                })
            }),
        });

        this.intakeTimes.forEach((i: any) => {
            (this.form.get('dosageInstructionData') as FormGroup).addControl(i.code, new FormControl(this.dosageInstructionData?.[i.code]))
        });

        this.listenPharmFormMeasureUnitEvent();
        this.listenDailyPharmFormMeasureUnitEvent();

        if (this.isDispense) {
            this.listenConsumptionTypeEvent();
        }

        this.form.get('dosageInstructionData').valueChanges.subscribe(value => {
            this.formValuesChange.emit(value);
        });

        this.validationSubscription = this.formCommunicationService.onValidationEvent()
            .subscribe((data: any) => {
                if (data) {
                    this.validateForm();
                    this.formCommunicationService.emitValidationFormData({
                        formKey: 'medicationDosage',
                        formValues: this.formValues()
                    } as FormCommunicationModel);
                }
            });
    }

    ngOnDestroy(): void {
        if (this.validationSubscription) {
            this.validationSubscription.unsubscribe();
        }
    }

    formValues() {
        const control = this.form.controls.dosageInstructionData;
        return {
            dosageInstructionData: {
                consumptionType: control.get('consumptionType.code').value && control.get('consumptionType.displayValue').value ? {
                    code: control.get('consumptionType.code').value,
                    displayValue: control.get('consumptionType.displayValue').value
                } : null,
                dosePerDay: control.get('dosePerDay.value').value && control.get('dosePerDay.unit.code').value ? {
                    value: control.get('dosePerDay.value').value,
                    unit: control.get('dosePerDay.unit').value
                } : null,
                doseQuantity: control.get('doseQuantity.value').value && control.get('doseQuantity.unit.code').value ? {
                    value: control.get('doseQuantity.value').value,
                    unit: control.get('doseQuantity.unit').value
                } : null,
                schedule: control.get('schedule.frequency').value && control.get('schedule.units').value ? {
                    frequency: control.get('schedule.frequency').value,
                    units: control.get('schedule.units').value
                } : null,
                timeQuantity: control.get('timeQuantity').value,
                morning: control.get('morning').value,
                noon: control.get('noon').value,
                evening: control.get('evening').value,
                beforeMeal: control.get('beforeMeal').value,
                duringMeal: control.get('duringMeal').value,
                afterMeal: control.get('afterMeal').value,
                independMeal: control.get('independMeal').value,
                asNeeded: control.get('asNeeded').value,
                beforeSleep: control.get('beforeSleep').value,
                route: control.get('route.code').value && control.get('route.displayValue').value ? {
                    code: control.get('route.code').value,
                    displayValue: control.get('route.displayValue').value
                } : null,
            }
        }
    }

    validateForm() {
        this.form.markAllAsTouched();
    }

    private refreshForm() {
        this.form.get('dosageInstructionData').patchValue(this.dosageInstructionData);
    }

    private listenPharmFormMeasureUnitEvent(): void {
        this.pharmFormSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get('dosageInstructionData.doseQuantity.unit.displayValue').value);
        }).pipe(switchMap((query: string | undefined) => {
            if (query) {
                return this.getPharmFormUnits(query)
            }
            return of([]);
        }));
    }

    private listenDailyPharmFormMeasureUnitEvent(): void {
        this.pharmFormSuggestionsDaily$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.form.get('dosageInstructionData.dosePerDay.unit.displayValue').value);
        }).pipe(switchMap((query: string | undefined) => {
            if (query) {
                return this.getPharmFormUnits(query)
            }
            return of([]);
        }));
    }

    private getPharmFormUnits(query: string | undefined) {
        return this.apiV2Entities.getEntitiesList('pharmaceutical-form-measure-unit', {searchCriteria: query})
            .pipe(map(items => items.map(p => {
                if (!p.displayValue) {
                    p['displayValue'] = p.name;
                }
                return p;
            })))
    }

    private listenConsumptionTypeEvent(): void {
        this.consumptionSuggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.form.get('dosageInstructionData.consumptionType.displayValue').value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                if (query) {
                    const request = this.apiV2Entities.getEntitiesList('route', {searchCriteria: query});
                    return request.pipe(map(items => items.map(p => {
                        if (!p.displayValue) {
                            p['displayValue'] = p.name;
                        }
                        return p;
                    })))
                }
                return of([]);
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    clearDosageSummaryData() {
        event.preventDefault();
        if (this.clearDosageSummary.observed) {
            this.clearDosageSummary.emit();
        } else {
            this.form.reset();
            this.dosageSummary = "";
        }
    }

    selectDoseQuantity(entity: any, fieldName: string) {
        this.form.get(`dosageInstructionData.${fieldName}.unit.code`).setValue(entity?.item?.code);
    }

    selectConsumptionType(entity: any) {
        this.form.get('dosageInstructionData.consumptionType.code').setValue(entity?.item?.code);
    }
}