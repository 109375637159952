ng1App.controller('doctor.patient.cert.II.e027-1ViewCtrl', 
    ['$scope', 'CertificateService', '$resource', 'AsideBlockModel',
     function ($scope, CertificateService, $resource, AsideBlockModel) {
    //2020-04-23 AS modified	
	$scope.certificate.$promise.then(function () {
		CertificateService.calcValidUntilDate($scope);
	});

	$scope._shared = {
        contentList: [
            new AsideBlockModel('cer.I0271.mai.inf'),
            new AsideBlockModel('cer.I0271.doc')
        ]
    };
	
}]);

ng1App.filter('replaceMinusOne', function () {
    return function (input) {                
        if (typeof input === "undefined") return input;
        if (input == '-1') return '';
        return input;
    };
  });
