import {Pipe, PipeTransform} from '@angular/core';
import {EnvironmentSettings} from "../../../environments/env";
import {Environment} from "../../../environments/environment-properties";
import {PatientModel} from "../models/patient.model";
import PortalType = Environment.PortalType;

@Pipe({name: 'patientLink'})
export class PatientLinkPipe implements PipeTransform {

    private readonly portalType: PortalType;

    constructor() {
        this.portalType = EnvironmentSettings.getPortalType();
    }

    transform(patient: PatientModel, useId?: boolean): any {
        switch (this.portalType) {
            case 'doctor':
                return '/dp/patients/' + patient.id;
            case 'patient':
                return '/pp/account' + (useId ? ('/' + patient.id) : '');
            default:
                console.error('unknown portal type:', this.portalType);
                return 'unknown portal type';
        }
    }
}