angular.module('medvais.components').directive('medvaisListPreloader', function () {
    return {
        templateUrl: 'modules/medvais/components/preloader/medvais-list-preloader.html',
        restrict: 'E',
        replace: true,
        scope: {
            title: '@',
            working: '='
        }
    };
});

angular.module('medvais.components').directive('medvaisFormPreloader', function () {
    return {
        templateUrl: 'modules/medvais/components/preloader/medvais-form-preloader.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '='
        }
    };
});

angular.module('medvais.components').directive('medvaisLoadMoreButton', function () {
    return {
        templateUrl: 'modules/medvais/components/preloader/medvais-load-more-button.html',
        restrict: 'E',
        replace: true,
        scope: {
            working: '=',
            title:'@'
        }
    };
});

