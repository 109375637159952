import {Component, Input, OnInit} from '@angular/core';
import {ApiV2Links} from '../../../../api/api-v2-links';
import {ApiV2Reports} from '../../../../api/api-v2-reports';
import {ApiV2Alerts} from "../../../../api/api-v2-alerts";
import {ApiV2AccountSettings} from "../../../../api/api-v2-account-settings";
import {DpSessionContext} from "../../services/dp-session-context.service";
import {PatientModel} from "../../../shared/models/patient.model";
import {ApiV2Patients} from "../../../shared/services/api-v2-patients";
import {forkJoin} from "rxjs";
import {InsuranceModel} from "../../../shared/models/insurance.model";
import {InsuranceStatusEnum} from "../../../shared/models/insurance-status.enum";
import {RouteParams} from "../../../ng1.routeParams";
import {ApiV2Encounters} from "../../../shared/services/api-v2-encounters";

@Component({
    selector: 'dp-doctor-context',
    templateUrl: 'doctor-context.component.html'
})
export class DpDoctorContextComponent implements OnInit {
    menu: any[];
    showReport: boolean;
    unreadCount: number;
    isQualificationActive: (q: any) => boolean;
    updateActiveQualification: (q: any) => void;
    reportClass: string;
    @Input() iprUrl: string;
    @Input() token: any;
    @Input() patient: PatientModel;
    patientId: string;
    settings: any;
    allowedTypes: any;
    allowedCerts: any;
    hasActiveEncounter: boolean;
    encounter: any;
    modalIsLoading: boolean;

    insurance: InsuranceModel;
    hasAllergies: boolean;
    //TODO: tumors, pregnancy and aspn properties should get state from endpoints (which are now not available).
    tumors: boolean;
    pregnancy: boolean;
    aspn: boolean;

    patientPhone: string;
    patientEmail: string;
    additionalData: string[];
    links: string[];

    constructor(
        private apiV2Alerts: ApiV2Alerts,
        private apiV2AccountSettings: ApiV2AccountSettings,
        private apiV2Links: ApiV2Links,
        private apiV2Reports: ApiV2Reports,
        private dpController: DpSessionContext,
        private apiV2Encounters: ApiV2Encounters,
        private apiV2Patients: ApiV2Patients,
        // private route: ActivatedRoute,
        private params: RouteParams
    ) {
    }

    ngOnInit(): void {
        // this.route.params.subscribe(params => {
        //     this.patientId = params['id'];
            // TODO: doesn't this apply to patient context?
        // Moved to dp-patient-context component
            // this.apiV2Patients.getPatient(this.patientId).subscribe(data => {
            //     this.dpController.setPatient(data);
            //     this.dpController.getLatestEncounter();
            //     this.patient = data;
            //     this.patientPhone = data.phones?.[0];
            //     this.patientEmail = data.emails?.[0];
            //     this.dpController.encounterData$.subscribe(data => this.encounter = data);
            // });
            // this.apiV2Patients.getInsurance(this.patientId).subscribe(data => {
            //     this.insurance = data;
            // });
            // this.apiV2Patients.getActiveAllergiesCount(this.patientId).subscribe(data => {
            //     this.hasAllergies = data.count > 0;
            // });
        // });
        //
        // this.menu = [
        //     {
        //         text: 'dp.nav.current.menu.records', url: '/dp/records', show: () => {
        //             return this.acRead('MyDocumentList');
        //         }
        //     },
        //     {
        //         text: 'dp.nav.current.menu.patients', url: '/dp/patients', show: () => {
        //             return (
        //                 this.acRead('AllPatientList') ||
        //                 this.acRead('OrganizationPatientList') ||
        //                 this.acRead('OrganizationTodayPatientList') ||
        //                 this.acRead('MyTodayPatientList') ||
        //                 this.acRead('MyPatientList')
        //             );
        //         }
        //     }
        // ];

        this.reloadUnreadCount();
        this.getIPR();
        this.checkQualification();
        this.getDoctorInfo();
        this.token = {token: ""}
    }

    acRead(permission: string): boolean {
        // Implement acRead logic here
        return true; // Placeholder return value
    }

    reloadUnreadCount(): void {
        this.apiV2Alerts.getUnreadCount().subscribe(data => {
            this.unreadCount = data.unread;
        });
    }

    onReportsClick(event: Event): void {
        const leftOffset = (event.target as HTMLElement).getBoundingClientRect().left;
        this.reportClass = window.innerWidth - leftOffset > window.innerWidth / 2
            ? ''
            : 'dropdown-to-left';
    }

    updateUnreadMsgCount(data: any): void {
        if (data) {
            this.unreadCount = data;
        } else {
            this.reloadUnreadCount();
        }
    }

    getIPR(): void {
        this.apiV2Links.getIPR().subscribe(result => {
            this.iprUrl = result.value;
        });
    }

    checkQualification(): void {
        this.apiV2AccountSettings.get().subscribe((settings: any) => {
            const activeQualification = settings.active_qualification;
            this.isQualificationActive = (q: any) => activeQualification === q.licenseCode;
            this.updateActiveQualification = (q: any) => {
                const setting = {active_qualification: q.licenseCode};
                this.apiV2AccountSettings.save(setting).subscribe(() => {
                    settings.active_qualification = q.licenseCode;
                });
            };
        });
    }

    getDoctorInfo(): void {
        if (!this.showReport) {
            this.apiV2Reports.getDoctorInfo().subscribe(res => {
                if (res && res.isFamilyDoctor) {
                    this.showReport = res.isFamilyDoctor;
                }
            });
        }
    }

    onModalOpen() {
        this.modalIsLoading = true;
        const patientIdQuery = {patientId: this.patient.id};
        this.dpController.encounterData$.subscribe(data => {
            this.encounter = data;
            if (this.encounter) {
                forkJoin([
                    this.apiV2Encounters.getAllowedDocumentTypes(this.encounter?.id, patientIdQuery),
                    this.apiV2Encounters.getAllowedCertificatesTypes(this.encounter?.id, patientIdQuery)]
                ).subscribe(([allowedTypesData, allowedCertsData]) => {
                    this.allowedTypes = allowedTypesData;
                    this.allowedCerts = allowedCertsData;
                    this.modalIsLoading = false;
                });
            }
        });
    }

    isInsured(): boolean {
        return InsuranceStatusEnum[this.insurance?.assertStatus] === InsuranceStatusEnum.INSURED;
    }
}