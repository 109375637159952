ng1App.factory('PatientViewCtrlPreparator',
    ['$filter', 'PatientsApiFactory', 'ClassifiersApiFactory', 'portalType', 'FormLoaderService',
     function ($filter, PatientsApiFactory, ClassifiersApiFactory, portalType, FormLoaderService) {
    return {
        prepare: function (scope) {
            FormLoaderService.startFormLoading();
            scope.portalType = portalType;

            scope.reload = function (id) {

                scope.patientId = id;
                scope.commentsQuery = {
                    patientId: id
                };
                scope.patient = {};
                scope.comments = [];
                scope.commentsLoaded = false;
                scope.commentsClosed = true;

                FormLoaderService.endFormLoading(PatientsApiFactory.getWithRelatedPersons({
                    id: scope.patientId
                }, function (data) {
                    scope.patientWithResources = data;
                    scope.patient = data.patient;
                    scope.showMergeOption = portalType === 'doctor' && !(data.patient.personalCode || data.patient.foreignPersonalCode) && !data.patient.replaced;
                    scope.showDisjoinOption = portalType === 'doctor' && (data.patient.personalCode || data.patient.foreignPersonalCode) && data.linkedPatients && data.linkedPatients.length > 0;
                    scope.showMergeMenu = scope.showMergeOption && scope.hideActions;
                    scope.showUpdateOption = (data.patient.personalCode || data.patient.foreignPersonalCode);
                }).$promise);

                ClassifiersApiFactory.search({classCode: 'patient-agreement-type'}, function (types) {
                    scope.agreementType = _.find(types.items, function (t) {
                        return t.id == 1;
                    });
                });

                scope.aggPage = 1;
                scope.aggCount = 5;
                scope.agreements = [];
                scope.aggTotal = 0;

                scope.otherDocuments = PatientsApiFactory.getOtherDocuments({patientId: scope.patientId, type: 'other'});
                scope.identityDocs = PatientsApiFactory.getOtherDocuments({patientId: scope.patientId, type: 'identity'});
                scope.insuranceDocs = PatientsApiFactory.getOtherDocuments({patientId: scope.patientId, type: 'healthInsurance'});
                scope.loadMoreAgreements();
            };

            scope.person = function (fullRef) {
                return scope.patientWithResources.relatedPersons[fullRef];
            };

            scope.getAge = function (from, to) {
                return _.age(from, to);
            };

            scope.is18 = function (patient) {
                return patient.birthDate ? _.agenumyears(patient.birthDate) >= 18 : true;
            };

            scope.isNewborn = function (patient) {
                var temp = patient.personalCode;
                return !scope.is18(patient) && !temp;
            };

            scope.showRelatedPersonUrl = function () {
                return portalType === 'doctor';
            };

            scope.partialCommonPath = function (file) {
                return '/templates/portal/common/patient_info/partials/' + file;
            };

            scope.agreementCancelable = function (ag) {
                return !ag.canceled && ag.cancelable && ag.fullId;
            };

            scope.loadMoreAgreements = function () {
                PatientsApiFactory.getAgreements({patientId: scope.patientId, page: scope.aggPage++, count: scope.aggCount}, function (data) {
                    _.each(data.items, function (item) {
                        scope.agreements.push(item);
                    });
                    scope.aggTotal = data.total;
                });
            };

            scope.updateFromRegisters = function () {
                var query: any = {update: true};

                if (portalType === 'doctor') {
                    if (scope.patient.personalCode) {
                        query.personalCode = scope.patient.personalCode;
                    } else {
                        query.personalCode = scope.patient.foreignPersonalCode;
                    }
                }
                FormLoaderService.startFormLoading();
                FormLoaderService.endFormLoading(PatientsApiFactory.search(query, function () {
                    scope.reload(scope.patientId);
                }).$promise);
            };

            scope.canViewRelatedPersons = function () {
                var result = scope.patient && scope.patient.relatedPersons && scope.patient.relatedPersons.length > 0;
                result = result && $filter('acRead')('RelatedPersonList');
                return result;
            };

            scope.showLowIncome = portalType === 'patient';

        }
    };
}]);
