ng1App.controller('doctor.patient.erx.PrintMedicines', 
    ['$scope', '$routeParams',
     function ($scope, $routeParams) {

    $scope.options = {
        getPatientId: function () {
            return $routeParams.patientId;
        }
    };

}]);