import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { controlIsRequired, generateSelectionValuesFromEntity } from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-nt-assessment-of-fetal-condition',
    templateUrl: './e096-nt-assessment-of-fetal-condition.component.html',
})
export class E096NtAssessmentOfFetalConditionComponent implements OnInit {
    @Input({required: true}) fruitName: string;
    @Input() answersVariants: {[key: string]: EntityModel[]};
    formGroup: FormGroup;
    maxDate = new Date();

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    controlRequired = controlIsRequired.bind(this) as typeof controlIsRequired;

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: this.fb.control(this.fruitName),
            hearthRate: this.fb.control(null, [Validators.required, Validators.min(0)]),
            fetalMovements: this.fb.control(null, [Validators.required]),
            forerunner: this.fb.control(null),
            position: this.fb.control(null),
            positionSide: this.fb.control(null),
            amnioticFluid: this.fb.control(null, [Validators.required]),
            amnioticFluidDate: this.fb.control({value: null, disabled: true})
        });
        this.formGroup.get('amnioticFluid').valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(value => {
                if (value === 'Nutekėję') {
                    this.formGroup.get('amnioticFluidDate').enable({emitEvent: false});
                    this.formGroup.get('amnioticFluidDate').addValidators([Validators.required]);
                    this.formGroup.get('amnioticFluidDate').updateValueAndValidity({emitEvent: false});
                } else {
                    this.formGroup.get('amnioticFluidDate').reset();
                    this.formGroup.get('amnioticFluidDate').disable({emitEvent: false});
                    this.formGroup.get('amnioticFluidDate').clearValidators();
                    this.formGroup.get('amnioticFluidDate').updateValueAndValidity({emitEvent: false});
                }
            });
    }
}
