angular.module('medvais.documents').directive('medvaisSignDocument', function () {

    return {
        restrict: 'E',
        scope: {options: '='},
        templateUrl: 'modules/medvais/documents/signing/medvais-sign-doc.html',
        controller: 'medvaisSignDocumentCtrl'
    };

});

angular.module('medvais.documents').controller('medvaisSignDocumentCtrl', ["$scope", "$rootScope", "$sce", "MedvaisDocumentApiFactory", "$log", "$location", "$route", function ($scope, $rootScope, $sce, MedvaisDocumentApiFactory, $log, $location, $route) {
    
    var url = "";
//    $location.path('/doc/' + $scope.options.documentId + '/unisign_callback');
    
    MedvaisDocumentApiFactory.initSigning({id: $scope.options.documentId, confirmUrl: $scope.options.getUnisignCallbackUrl()}).$promise.then(
        function (res) {
          $log.debug(res);
          url = res.signingUrl;
        },
        function (err) {
          $log.error(err);
        }
    );
    
    $scope.getUrl = function () {
        if (url.length > 0)
            return $sce.trustAsResourceUrl(url);

    };
    
    $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl, newState, oldState) {
        $log.debug(newUrl);
        $log.debug(oldUrl);
        $rootScope.medvaisOldUrl = oldUrl;
    });
    
}]);
