angular.module('erx.utils').service('$erxModalSaveDraft', ["$modal", "$rootScope", "$q", "ErxTemplatesApiFactory", function ($modal, $rootScope, $q, ErxTemplatesApiFactory) {
  var scope = $rootScope.$new();
  var count = 5;

  scope.showNewTab = function () {
    delete scope.templates;
    scope.data = {};
    scope.selected = 1;
  }

  scope.showEditTab = function () {
    scope.data = {};
    scope.search();
    scope.selected = 2;
  }

  scope.isLoading = function () {
    return scope._loading;
  }

  scope.submit = function () {
    if (scope.selected === 1) {
      deferred.resolve({opType: 1, name: scope.data.draftName});
      ErxTemplatesApiFactory.saveTemplate({title: scope.data.draftName, searchIndex: scope.data.draftName, type: scope.templateType}, scope.templateData).$promise.then(
              function (res) {
              },
              function (err) {
              });
    } else if (scope.selected === 2) {
      ErxTemplatesApiFactory.updateTemplate({id: scope.data.template.id,
        title: scope.data.draftName,
        searchIndex: scope.data.draftName,
        type: scope.templateType}, scope.templateData).$promise.then(
              function (res) {
                confirm.hide();
              });
    }

    confirm.hide();

  };

  scope.search = function () {
    scope.page = 0;
    scope.templates = [];
    scope.loadMore();
  }

  scope.moreToLoad = function () {
    return scope.templates && (scope.templates.length < scope.templates.total);
  }

  scope.loadMore = function () {
    ++scope.page;
    scope._loading = true;

    ErxTemplatesApiFactory.search({type: scope.templateType,
      page: scope.page, count: count, sort: 'dateUpdated', query: scope.data.searchText}).$promise.then(function (res) {
      scope.templates = scope.templates.concat(res.result);
      scope.templates.total = res.total;
      scope._loading = false;
    });
  }


  var deferred;
  var confirm = $modal({template: 'modules/erx-utils/components/erx-modal-save-draft/erxModalSaveDraft.tpl.html', scope: scope, show: false});
  var parentShow = confirm.show;
  confirm.show = function (opts) {
    scope.selected = 1;
    scope.page = 0;
    scope.templateType = opts.templateType;
    scope.templateData = opts.data;
    scope.data = {};
    deferred = $q.defer();
    parentShow();
    return deferred.promise;
  };



  return confirm;
}]);