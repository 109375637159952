<div class="container-xxl sveik-layout two-cols">
    <aside class="sveik-sidebar-min">
        <nav class="navbar text-center">
            <button
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#sidebar-menu"
                title="{{ 'Show navigation' | translate }}"
            >
                <span class="sr-only">{{ "Show navigation" | translate }}</span>
                <span class="navbar-toggler-icon"></span>
            </button>
        </nav>
    </aside>
    <predefined-filters
        id="sidebar-menu"
        class="sveik-sidebar"
        ng-non-bindable
        (filterService)="changeForm($event)"
        [filters]="filters"
        [currentFilter]="filters[0]"
        [title]="'comp.docRefConsent.list.title' | translate"
    />
    <main id="main" class="sveik-main">
        <app-general-pregnancy-termination-info *ngIf="currentForm.title === pregnancyFilterNames.PregnancyData" />
        <app-outpatient-visit-description *ngIf="currentForm.title === pregnancyFilterNames.AmbulatoryDescription" />
        <app-risk-factors *ngIf="currentForm.title === pregnancyFilterNames.RiskFactors" />
        <app-fetus-ultrasound-examination
            *ngIf="currentForm.title === pregnancyFilterNames.UltrasoundFetalExamination"
        />
        <app-procedures-during-pregnancy
            *ngIf="currentForm.title === pregnancyFilterNames.ExaminationsProceduresDuringPregnancy"
        />
        <app-pregnancy-termination *ngIf="currentForm.title === pregnancyFilterNames.AbortationDescription" />
    </main>
</div>
