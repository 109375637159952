<!-- TODO-design-custom-search: get rid of isAdvanced, showAdvanced and onAdvanced, perhaps onQuick too -->

<div class="collapse multi-collapse" id="advancedFilter">
    <div class="custom-filter p-4">
        <ng-content *ngTemplateOutlet="advancedFilter"></ng-content>
    </div>
    <hr class="my-0 border-bottom-0 border-dark-subtle">
</div>

<ng-template #advancedFilter>
    <ng-content></ng-content>
</ng-template>
