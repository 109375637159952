angular.module('erx.utils').service('$erxModalAnalyticalInfo', ["$modal", "$rootScope", "ErxAnalyticsApiFactory", "gettextCatalog", function ($modal, $rootScope, ErxAnalyticsApiFactory, gettextCatalog) {
  var scope = $rootScope.$new();
  scope.data = {};

  scope.intakeTimes = [
    { code: 'beforeMeal', displayValue: 'Prieš valgį' },
    { code: 'duringMeal', displayValue: 'Valgio metu' },
    { code: 'afterMeal', displayValue: 'Po valgio' },
    { code: 'independMeal', displayValue: 'Nepriklausomai nuo valgymo' },
    { code: 'beforeSleep', displayValue: 'Prieš miegą' }
  ];

  scope.timesOfDay = [
    { code: 'morning', displayValue: 'Ryte' },
    { code: 'noon', displayValue: 'Per pietus' },
    { code: 'evening', displayValue: 'Vakare' },
    { code: 'asNeeded', displayValue: 'Pagal poreikį' }];

  scope.getInfo = function () {
    return ErxAnalyticsApiFactory.getByDiagnosis({ reasonCode: scope.data.reason.code });
  }

  scope.getIntakeTimes = function (entry) {
    var ret = '';
    for (var i = 0; i < scope.intakeTimes.length; i++) {
      if (entry[scope.intakeTimes[i].code]) {
        if (ret !== '')
          ret += ', ';
        ret += gettextCatalog.getString(scope.intakeTimes[i].displayValue);
      }
      if (i === scope.intakeTimes.length - 1 && ret !== '')
        ret += '.';
    }
    return ret;
  };

  scope.getDosageFrequency = function (entry) {
    var ret = '';
    for (var i = 0; i < scope.timesOfDay.length; i++) {
      if (entry[scope.timesOfDay[i].code]) {
        if (ret !== '')
          ret += ', ';
        ret += gettextCatalog.getString(scope.timesOfDay[i].displayValue);
      }
      if (i === scope.timesOfDay.length - 1 && ret !== '')
        ret += '.';
    }
    return ret;
  };

  var modal = $modal({ template: 'modules/erx-utils/components/erx-modal-analytical-info/erxModalAnalyticalInfo.tpl.html', scope: scope, show: false });
  var parentShow = modal.show;

  modal.show = function (opts) {
    if (!opts || !opts.reason) {
      console.warn("Tried to view analytical info without diagnosis!");
      return;
    } else {
      scope.data.reason = opts.reason;
      scope.data.entries = scope.getInfo();
    }
    parentShow();
    return;
  };

  return modal;
}]);