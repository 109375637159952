import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { generateSelectionValuesFromEntity } from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-nt-appointments',
    templateUrl: './e096-nt-appointments.component.html',
})
export class E096NtAppointmentsComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    formGroup: FormGroup;
    modOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'I'
        },
        {
            id: '1',
            code: '1',
            name: 'II'
        },
        {
            id: '2',
            code: '2',
            name: 'III'
        },
        {
            id: '3',
            code: '3',
            name: 'IV'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            assignmentToMode: this.fb.control(null, [Validators.required]),
            dieta: this.fb.control(null, [Validators.required]),
            appointmentForMedication: this.fb.control(null)
        });
    }
}
