<div class="container" *ngIf="docRefConsent">
    <app-title
        [badgeText]="badgeTitle | translate"
        [badgeClass]="badgeColor"
        [backSubtitle]="dateBetween"
    >
        {{docRefConsent.title}}
        <content>
            <div class="row">
                <div class="col">
                    <span class="small">{{agreementInfo}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span class="small">{{docStatusDescription | translate}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span class="small">{{docRefConsent.practitioner.speciality.name}} | </span>
                    <a [href]="docRefConsent.practitioner.id ? '/dp/specialist/' + docRefConsent.practitioner.id : undefined" class="small text-info">{{practitionerFullName}}</a>
                    <span class="small"> | {{docRefConsent.practitioner.practitionerOrganization.name}}</span>
                </div>
            </div>
        </content>
    </app-title>
    <div class="row mt-4 widget-auto-height">
        <div class="col-lg-6">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsent.view.patientOrganization' | translate" id="patientOrganization">
                <doc-ref-consent-span *ngFor="let span of patientOrganizationSpan" [span]="span"/>
            </nhr-record-widget>
        </div>
        <div class="col-lg-6">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsent.view.consentOrganization' | translate" id="consentOrganization">
                <doc-ref-consent-span *ngFor="let span of consentOrganizationSpan" [span]="span"/>
            </nhr-record-widget>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsent.view.agreement.data' | translate" id="agreementData">
                <doc-ref-consent-span *ngFor="let span of agreementSpan" [span]="span"/>
            </nhr-record-widget>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsent.view.agreement.text' | translate" id="agreementText">
                {{docRefConsent.description}}
            </nhr-record-widget>
        </div>
    </div>
    <div class="row">
        <div class="mt-2 d-flex flex-row-reverse">
            <div class="me-1">
                <button class="btn btn-outline-secondary" type="button" (click)="goBack()">{{'comp.docRefConsent.new.back' | translate}}</button>
            </div>
            <div class="me-1" *ngIf="canCancel()">
                <button class="btn btn-danger" type="button" (click)="onCancel()">{{'comp.docRefConsent.view.agreement.cancel' | translate}}</button>
            </div>
            <div class="me-1" *ngIf="canSign()">
                <button class="btn btn-primary" type="button" (click)="onSign()">{{'comp.docRefConsent.view.agreement.confirm' | translate}}</button>
            </div>
        </div>
    </div>
</div>