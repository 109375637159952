export enum PsychologistConsultation {
  Was = 'Buvo',
  Wasnt = 'Nebuvo',
  Refused = 'Atsisakė',
}

export enum PregnancyTerminationReason {
  SpontaneousMiscarriage = 'Savaiminis persileidimas',
  FetalDeath = 'Nesivystantis nėštumas / vaisiaus žūtis',
  BenignCystAltogether = 'Gerybinė pūslinė išvisa',
  EctopicPregnancy = 'Ektopinis (negimdinis) nėštumas',
  WomanRequestPregnancyTermination = 'Nėštumo nutraukimas moters pageidavimu',
  PregnancyTerminationAccordingMedIndications = 'Nėštumo nutraukimas pagal med. indikacijas',
  LiveNewborn = 'Gyvas naujagimis',
  Undead = 'Negyvagimis',
}

export enum MedicalIndicationsPregnancyTermination {
  MalformationAetectedAntenatalPeriod = 'Nustatytas apsigimimas antenataliniu periodu',
  Other = 'Kita',
}

export enum AppliedTreatment {
  Surgical = 'Chirurginis',
  Medicinal = 'Medikamentinis',
  NoTreatmentApplied = 'Gydymas netaikytas',
}
