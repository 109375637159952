import { getTemplateComponent } from '../../../../../utils/hypridHelper';
import { E097Component } from './e097.component';
import { DocumentTagEnum } from '../../../../shared/models/document-tag.enum';
import { E097AtPageComponent } from './e097-at/e097-at-page/e097-at-page.component';
import { E097GdPageComponent } from './e097-gd/e097-gd-page/e097-gd-page.component';


ng1App.config([
    "$routeSegmentProvider",
    function ($routeSegmentProvider) {
        console.debug("Adding e097 routes");

        $routeSegmentProvider
            .when(`/dp/patients/:patientId/e097-view`, "DP.DP_PATIENT3L.NGN_E097_VIEW")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_at}`, "DP.DP_PATIENT2LREVNEW.NGN_AT_NEW")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_at}/view/:id`, "DP.DP_PATIENT3L.NGN_AT_VIEW")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_at}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_AT_EDIT")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_gd}`, "DP.DP_PATIENT2LREVNEW.NGN_GD_NEW")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_gd}/view/:id`, "DP.DP_PATIENT3L.NGN_GD_VIEW")
            .when(`/dp/patients/:patientId/ngn/${DocumentTagEnum.e097_gd}/edit/:id`, "DP.DP_PATIENT2LREVNEW.NGN_GD_EDIT");


        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT3L")
            .segment("NGN_E097_VIEW", { template: getTemplateComponent(E097Component) })
            .segment("NGN_AT_VIEW", { template: getTemplateComponent(E097AtPageComponent) })
            .segment("NGN_GD_VIEW", { template: getTemplateComponent(E097GdPageComponent) });

        $routeSegmentProvider
            .within("DP")
            .within("DP_PATIENT2LREVNEW")
            .segment("NGN_AT_NEW", { template: getTemplateComponent(E097AtPageComponent) })
            .segment("NGN_AT_EDIT", { template: getTemplateComponent(E097AtPageComponent) })
            .segment("NGN_GD_NEW", { template: getTemplateComponent(E097GdPageComponent) })
            .segment("NGN_GD_EDIT", { template: getTemplateComponent(E097GdPageComponent) });
    },
]);
