<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
                <strong class="form-label">Data nuo</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateForm" [fControl]="form?.get('dateFrom')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
                <strong class="form-label">Data iki</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateTo" [fControl]="form?.get('dateTo')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Paskyrė</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="orderPractitioner" formControlName="orderPractitioner"
                            typeaheadOptionField="orderPractitioner"
                            [typeahead]="orderPractitionerSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectOrderPractitioner($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite specialisto pavardę" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Specialistas</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="appointmentPractitioner"
                            formControlName="appointmentPractitioner"
                            typeaheadOptionField="appointmentPractitioner"
                            [typeahead]="appointmentPractitionerSuggestions$"
                            [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectAppointmentPractitioner($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite specialisto pavardę" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Pacientas</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="patient" formControlName="patient" typeaheadOptionField="patient"
                            [typeahead]="patientSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectPatient($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įvesti paciento vardą, pavardę" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Būsena</strong>
                <div class="w-100">
                    <select id="status" class="form-select" formControlName="status">
                        <option [ngValue]="null" selected>--Pasirinkti būseną--</option>

                        <option *ngFor="let state of states | keyvalue" [value]="state.key">
                            {{state.value}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-xxl-6">
            <div class="row mt-md-3 d-flex flex-column">
                <app-checkbox inputId="myAppointments" [fControl]="form.get('myAppointments')" labelClass="fw-bold" labelText="{{'Mano paskyrimai' | translate}}">
                </app-checkbox>
                <app-checkbox inputId="urgent" [fControl]="form.get('urgent')" labelClass="fw-bold" labelText="{{'Skubu' | translate}}">
                </app-checkbox>
            </div>
        </div>


    </div>
</form>
<div class="d-flex justify-content-end gap-2">
    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="searchAdvanced()">{{'search' |
        translate}}</button>
    <button class="btn btn-secondary" (click)="clear()">{{ 'clean' | translate }}</button>
</div>
