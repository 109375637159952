import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { validateBloodPressure } from '../../../../e096-utils/custom-validation';

@Component({
    selector: 'app-e096-ptkl-assessment-woman-condition',
    templateUrl: './e096-ptkl-assessment-woman-condition.component.html',
})
export class E096PtklAssessmentWomanConditionComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    maxDate = new Date();
    skinOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Sausa'
        },
        {
            id: '1',
            code: '1',
            name: 'Drėgna'
        }
    ];
    compoundsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Normalios spalvos'
        },
        {
            id: '1',
            code: '1',
            name: 'Blyškios'
        }
    ];
    consciousnessOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Norma'
        },
        {
            id: '1',
            code: '1',
            name: 'Pritemusi'
        },
        {
            id: '2',
            code: '2',
            name: 'Nėra'
        }
    ];

    bpValidator = validateBloodPressure.bind(this) as typeof validateBloodPressure;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            assessmentDone: this.fb.control(null),
            consciousness: this.fb.control(null),
            breathingRate: this.fb.control(null),
            spO2: this.fb.control(null),
            pulse: this.fb.control(null, [Validators.min(0)]),
            bloodPressureSystolic: this.fb.control(null, [Validators.min(0)]),
            bloodPressureDiastolic: this.fb.control(null, [Validators.min(0)]),
            skin: this.fb.control(null),
            compounds: this.fb.control(null),
            diuresis: this.fb.control(null),
        });

        this.bpValidator('bloodPressureSystolic', 'bloodPressureDiastolic')
            .pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
}
