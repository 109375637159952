import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FetusUltrasoundData, FetusUltrasoundList } from "../../../../models/fetusUltrasoundExamination.model";
import { UltrasoundStages } from "../../fetus-ultrasound-examination.constants";

@Component({
    selector: "app-ultrasound-examination-template",
    templateUrl: "./ultrasound-examination-template.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UltrasoundExaminationTemplateComponent {
    @Input() ultrasoundData: FetusUltrasoundList;
    @Input() selectedButton: FormControl = new FormControl(null);

    public UltrasoundStages = UltrasoundStages;
    public tabIndex: number = 0;

    public get selectedTabIndex(): number {
        return this.tabIndex;
    }

    public get selectedButtonFormControlValue(): string {
        return this.selectedButton.value;
    }

    public get getFetusData(): FetusUltrasoundData {
        return this.ultrasoundData.fetusData.find(
            (x) => x.generalInfo.fetusLetter === this.selectedButtonFormControlValue
        );
    }

    public setTab(index: number, fetusLetter: string) {
        this.selectedButton.patchValue(fetusLetter);
        this.tabIndex = index;
    }
}
