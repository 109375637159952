angular.module('erx.utils').directive('erxDiagnoses', ["ErxPatientsApiFactory", function (ErxPatientsApiFactory) {
  return {
    templateUrl: 'modules/erx-utils/components/erx-diagnoses/erxDiagnoses.tpl.html',
    restrict: 'E',
    replace: false,
    scope: {patientId: '='},
    link: function (scope: any, element, attrs) {
     scope.diagnoses = ErxPatientsApiFactory.getConditions({id: scope.patientId, count: 5, page: 1});
    }
  };
}]);