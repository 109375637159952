import { Component, OnInit } from "@angular/core";
import { FormArray } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { string } from "underscore";

@Component({
    selector: "app-modal",
    template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="procedureLocations && procedureLocations.length > 0">
            <div class="col">
                <span class="fw-bold">{{'Procedūros atlikimo vieta' | translate}}</span>
            </div>
            <div class="col">
                <ul >
                    <li *ngFor="let item of procedureLocations">
                        {{item}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="recommendations" >

            <div class="col">
                <span class="fw-bold">{{'Rekomendacijos ir pastabos' | translate}}</span>
            </div>
            <div class="col">
                {{recommendations}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()" >{{closeBtnName}}</button>
    </div>
  `,
})
export class AspnVisitModalComponent implements OnInit {
    procedureLocations?: string[];
    recommendations?: string;
    title?: string;
    closeBtnName?: string;

    constructor(public bsModalRef: BsModalRef) {}

    ngOnInit() {

    }
    onHidden(){

    }
  }
