<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.dia.dia' | translate"
>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-12">
                    <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                        {{'pp.mod.dia.dia' | translate}}
                    </span>
                <ng-container [ngTemplateOutlet]="diagnoseSearch"></ng-container>
            </div>
        </div>
        <div class="row">
            <app-input
                labelText="doc.pregnancyDashboard.e097Gd.appointmentsForm.decisions"
                class="form-group col-12"
                labelClass="col-form-label"
                type="textarea"
                textAreaRows="3"
                [fControl]="formGroup.get('decisions')"
            ></app-input>
        </div>
        <div class="row">
            <fieldset class="col-12 d-flex flex-column">
                <legend class="float-none col-form-label mt-3 norq">
                    {{'doc.pregnancyDashboard.e097Gd.appointmentsForm.mealsOnDeparture' | translate}}
                </legend>
                <div class="d-flex gap-4">
                    <app-checkbox
                        *ngFor="let item of mealsOnDepartureOptions; let i = index"
                        class="mt-3 col-form-label"
                        formClass="mb-0"
                        [inputId]="'gd-mealsOnDeparture' + i"
                        [labelText]="item.name"
                        [checkboxDisabled]="!(editable)"
                        [checkboxChecked]="checkboxIsChecked(item.name, 'mealsOnDeparture')"
                        (checkboxChange)="updateControl(item.name, 'mealsOnDeparture')"
                    ></app-checkbox>
                </div>
            </fieldset>
        </div>
    </div>
</nhr-record-widget>

<ng-template #diagnoseSearch>
    <div class="row col-12 col-lg-8">
        <div class="input-group" *ngIf="editable">
            <input
                [formControl]="searchDiagnose"
                [typeahead]="suggestions$"
                [typeaheadAsync]="true"
                [typeaheadItemTemplate]="customItemTemplate"
                [typeaheadMinLength]="0"
                [typeaheadOptionsLimit]="10"
                [typeaheadWaitMs]="200"
                typeaheadOptionField="fullDisplay"
                (typeaheadOnSelect)="selectDiagnose($event)"
                class="form-control h-[38px]"
            />
            <ng-template #customItemTemplate let-model="item">
                <span>{{model.code}} {{model.name}}</span>
            </ng-template>
            <button class='btn btn-outline-primary btn-sm ms-2' [disabled]="!selectedDiagnose" (click)="addDiagnoseToList()">+&nbsp;{{'add' | translate}}</button>
        </div>

        <div class="row mx-auto" *ngIf="diagnosisControl?.length">
            <div class="row align-items-center mt-1 px-0" *ngFor="let control of diagnosisControl; let i = index">
                <span class="w-auto">{{control.value.code}} {{control.value.name}}</span>
                <div class="mt-2 flex-shrink-1 w-auto" *ngIf="editable">
                    <button class="btn btn-block text-primary btn-sm fs-4" (click)="deleteDiagnose(i)"><i class="fa fa-trash"></i>&nbsp;{{'doc.dia.dlt' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
