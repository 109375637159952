<nav class="navbar sveik-sidebar-min w-100">
    <div class="d-flex justify-content-between align-items-center">
        <strong class="sidebar-button-label d-md-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-main-menu">Meniu:</strong>
        <button class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-main-menu"
                title="{{'Show navigation' | translate}}">
            <span class="sr-only">{{ 'Show navigation'| translate }}</span>
            <span class="navbar-toggler-icon" [ngClass]="{'hide-icon':iconClass}">
                <i *ngIf="iconClass" [ngClass]="iconClass"></i>
            </span>
        </button>
    </div>
</nav>