import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../../models/entity.model';
import {
    controlIsDisabled,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
@Component({
    selector: "app-inr-description-drugs-subform",
    templateUrl: "./inr-description-drugs-subform.component.html",
})
export class InrDescriptionDrugsSubformComponent {
    @Input() editable = true;
    @Input() set patient(value: boolean) {
        this.isPatient = value;
        if (value && this.formGroup.get('causedByMedicationError').hasValidator(Validators.required)) {
            this.formGroup.get('causedByMedicationError').removeValidators(Validators.required);
        } else if (!value && !this.formGroup.get('causedByMedicationError').hasValidator(Validators.required)) {
            this.formGroup.get('causedByMedicationError').addValidators(Validators.required);
        }
    };

    yesNoOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Taip'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne'
        }
    ];
    severeConsequencesEffects: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Mirtis'
        },
        {
            id: '1',
            code: '1',
            name: 'Pavojus gyvybei'
        },
        {
            id: '2',
            code: '2',
            name: 'Hospitalizavimas'
        },
        {
            id: '3',
            code: '3',
            name: 'Stacionarinio gydymo trukmės prailginimas'
        },
        {
            id: '4',
            code: '4',
            name: 'Ilgalaikis ar reikšmingas neįgalumas'
        },
        {
            id: '5',
            code: '5',
            name: 'Nedarbingumas'
        },
        {
            id: '6',
            code: '6',
            name: 'Apsigimimas'
        },
        {
            id: '7',
            code: '7',
            name: 'Kita'
        }
    ];
    outcomeOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'ĮNR pasibaigė, nebėra jokių simptomų'
        },
        {
            id: '1',
            code: '1',
            name: 'ĮNR tebesitęsia (asmens sveikatos būklė nepakitusi)'
        },
        {
            id: '2',
            code: '2',
            name: 'ĮNR tebesitęsia (asmens sveikatos būklė gerėja)'
        },
        {
            id: '3',
            code: '3',
            name: 'ĮNR tebesitęsia (asmens sveikatos būklė blogėja)'
        },
        {
            id: '4',
            code: '4',
            name: 'Asmuo pasveiko, bet liko liekamieji reiškiniai'
        },
        {
            id: '5',
            code: '5',
            name: 'Dėl ĮNR asmuo mirė'
        },
        {
            id: '6',
            code: '6',
            name: 'Nežinoma'
        }
    ];
    medicalErrors: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Vaistinio preparato išrašymo arba paskyrimo klaida (paskirtas ne tas vaistinis preparatas)'
        },
        {
            id: '1',
            code: '1',
            name: 'Vaistinio preparato išdavimo klaida'
        },
        {
            id: '2',
            code: '2',
            name: 'Dozavimo klaida'
        },
        {
            id: '3',
            code: '3',
            name: 'Vartojimo būdo klaida'
        },
        {
            id: '4',
            code: '4',
            name: 'Netinkamas vaistinio preparato laikymas'
        }
    ];
    occuredList: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Vaistinio preparato vartojimo nesilaikant registracijos sąlygų (angl. Off-label use)'
        },
        {
            id: '1',
            code: '1',
            name: 'Perdozavimo'
        },
        {
            id: '2',
            code: '2',
            name: 'Piktnaudžiavimo vaistiniu preparatu'
        },
        {
            id: '3',
            code: '3',
            name: 'Netinkamo vaistinio preparato vartojimo'
        },
        {
            id: '4',
            code: '4',
            name: 'Ekspozicijos vaistiniu preparatu darbo aplinkoje'
        },
        {
            id: '5',
            code: '5',
            name: 'Kita'
        }
    ];
    offLabelConditions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Ne pagal patvirtintas terapinės indikacijas'
        },
        {
            id: '1',
            code: '1',
            name: 'Ne tos amžiaus grupės pacientui'
        },
        {
            id: '2',
            code: '2',
            name: 'Kitokia dozė'
        },
        {
            id: '3',
            code: '3',
            name: 'Kitokiu vartojimo būdų'
        },
        {
            id: '4',
            code: '4',
            name: 'Kita'
        }
    ];
    ineffectiveList: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Vaistinis preparatas vartojamas gyvybei pavojingai ligai gydyti'
        },
        {
            id: '1',
            code: '1',
            name: 'Kontraceptinis vaistinis preparatas'
        },
        {
            id: '2',
            code: '2',
            name: 'Kitoks vaistinis preparatas'
        }
    ];

    formGroup: FormGroup;
    isPatient: boolean;
    maxDate = new Date();

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    constructor(
        private fb: FormBuilder
    ) {
        this.formGroup = this.fb.group({
            description: this.fb.control(null, [Validators.required]),
            startYear: this.fb.control(null),
            endYear: this.fb.control(null),
            severeConsequences: this.fb.control(null),
            severeConsequencesEffect: this.fb.control(null),
            treatmentShouldBeApplied: this.fb.control(null),
            shortSevereConsequencesDescription: this.fb.control(null),
            outcome: this.fb.control(null, [Validators.required]),
            causedByMedicationError: this.fb.control(null, [Validators.required]),
            medicationError: this.fb.control(null),
            shortMedicationErrorDescription: this.fb.control(null),
            occuredDue: this.fb.control(null),
            offLabelConditions: this.fb.control({value: [], disabled: true}),
            causalRelationshipINRMedicinalProduct: this.fb.control([]),
            itWasIneffective: this.fb.control(null),
            shortEffectiveDescription: this.fb.control(null),
            moreInr: this.fb.control(null),
        });

        this.changeStatusOfControl({
            controlNameForListen: 'occuredDue',
            controlNameForUpdate: 'offLabelConditions',
            enableValue: 'Vaistinio preparato vartojimo nesilaikant registracijos sąlygų (angl. Off-label use)',
        }).pipe(takeUntilDestroyed())
            .subscribe();
    }
}
