ng1App.controller('doctor.user.ViewMessageCtrl', 
    ['$scope', '$routeParams', '$filter', '$modalConfirm', '$location', 'AlertsApiFactory', 'MessageService', 'DpSessionContext', 'DpMessagesUtil', 'SessionContext',
     function ($scope, $routeParams, $filter, $modalConfirm, $location, AlertsApiFactory, MessageService,
                                                           DpSessionContext, DpMessagesUtil, SessionContext) {
    //Init
    $scope.$emit('setHelpPage', 'perzireti-pranesima');
    $scope.messageId = $routeParams.id;
    $scope.sessionCtx = DpSessionContext;
    $scope.messages = {};
    $scope.lastMessage = {};
    $scope.util = DpMessagesUtil;

    // Handlers
    $scope.reload = function () {
        MessageService.clearAll();
        if ($location.hash() == "success") {
            MessageService.success($filter('translate')('mess.sent.success'));
            $location.hash("");
        }
        AlertsApiFactory.getThread({messageId: $scope.messageId}, function (data) {
            $scope.showReply = false;
            $scope.messages = data;
            $scope.lastMessage = $scope.messages[0];
            $scope.$emit('updateUnreadMsgCount');
        });
    };
    $scope.reload();

    $scope.canDelete = function () {
        return ($scope.lastMessage.isSender || ($scope.lastMessage.alert && !$scope.lastMessage.alert.author)) && $filter('acWrite')('MessageList') && $filter('acWrite')('Alert', [$scope.lastMessage.alert.category.code]);
    };

    $scope.canReply = function () {
        return !$scope.lastMessage.isSender && $scope.lastMessage.alert && $scope.lastMessage.alert.author && ($filter('acWrite')('MessageList') && $filter('acWrite')('Alert', [$scope.lastMessage.alert.authorRef.indexOf('Patient/') === 0 ? '5' : '3']));
    };

    $scope.practitionerQualification = function (practitioner) {
        if (practitioner.qualificationList
                && practitioner.qualificationList.length > 0) {
            return practitioner.qualificationList[0].name
        }
        return null;
    };

    $scope.deleteOne = function () {
        MessageService.clearAll();
        $modalConfirm.show(
                {title: $filter('translate')('err.ale.tit.mess.del'), content: $filter('translate')('err.ale.mess.del')}).then(function () {
            AlertsApiFactory.deleteMessages([$scope.lastMessage.id], function (data) {
                $location.path('/dp/messages');
                MessageService.success($filter('translate')('mess.one.del.success'));
            });
        });
    };

    $scope.message = {
        subjectRef: [],
        receiverRef: []
    };

    $scope.doShowReply = function () {
        $scope.$emit('setHelpPage', 'kurti-atsakyma-i-pranesima');

        $scope.showReply = true;
        $scope.message.title = 'Ats:' + $scope.lastMessage.alert.title;
    };

    $scope.clean = function () {
        $scope.message = {
            subjectRef: [],
            receiverRef: []
        };
        $scope.messageForm.submitted = false;
        $scope.messageForm.$setPristine();
    };

    $scope.replyMessage = function () {

        MessageService.clearAll();
        $scope.messageForm.submitted = true;

        if ($scope.messageForm.$valid) {

            $scope.message.parentMessageId = $scope.lastMessage.id;
            if ($scope.lastMessage.alert.authorRef.lastIndexOf('Practitioner', 0) === 0) {
                $scope.message.receiverRef.push({
                    subjectRef: $scope.lastMessage.alert.subject.fullId,
                    receiverRef: $scope.lastMessage.alert.author.fullId
                });
            } else {
                $scope.message.subjectRef.push($scope.lastMessage.alert.subject.fullId);
            }

            AlertsApiFactory.replyMessage($scope.message, function (data) {
                $location.path('/dp/messages/' + data.messageId);
                $scope.messageId = data.messageId;
                $scope.reload();
                MessageService.success($filter('translate')('mess.sent.one.success'));
            });
        }
    };

    $scope.showSendCopyToEDelivery = function () {
        return SessionContext.practitioner.eDeliveryAddress && $scope.lastMessage.alert.author.eDeliveryAddress;
    }

}]);