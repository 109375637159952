import './patient/aLK_forms/f066alk/parts/diagnoses';
import './patient/patient/patient_edit';
import './patient/erx/erx_print_medicines';
import './user/templates/templates';
import './user/medvais/e027va_edit';
import './patient/erx/erx_print_prescriptions';
import './patient/aLK_forms/form_creation';
import './patient/aLK_forms/parts/components';
import './patient/aLK_forms/f066alk/parts/main_stage_data';
import './user/messages/messages';
import './user/representatives/representatives';
import './patient/certificates/e047_practitioner/edit_e047_practitioner';
import './patient/records/modals/document_type_select';
import './user/medvais/e027va_new';
import './common/unfinished_records_list';
import './user/patients/patients';
import './patient/certificates/layout/base';
import './patient/patient/patient_view';
import './user/records/unfinished_records';
import './patient/documents/documents_edit';
import './patient/aLK_forms/f025alk/edit_encounter';
import './patient/allergies/allergies';
import './patient/vaccines/new-national';
import './patient/certificates/e106/edit_e106';
import './patient/certificates/drafts/view_certificate_draft';
import './patient/encounters/history';
import './user/medvais/e027va_view';
import './user/medvais/e027va_sign';
import './patient/erx/erx_presc_edit_doc';
import './patient/results/results';
import './user/medvais/imaging_study_list';
import './patient/erx/erx_presc_list';
import './patient/aLK_forms/f066alk/parts/blood_components';
import './patient/encounters/edit';
import './patient/documents/documents_view_draft';
import './patient/aLK_forms/f025alk/view_f025a_lk';
import './user/dashboard';
import './patient/certificates/new/new_certificate';
import './patient/certificates/e106_2_1/edit_e106_2_1';
import './patient/documents/modal/edit_profession';
import './patient/documents/modal/save_template';
import './user/specialist/view';
import './patient/modal/dashboard_disagreement_modal';
import './patient/certificates/incapacity/view_incapacity';
import './patient/certificates/incapacity/edit_incapacity';
import './patient/aLK_forms/f066alk/view_f066alk';
import './patient/certificates/e083_1/edit_e083_1';
import './user/account/edit';
import './patient/documents/modal/create_paper_encounter_order';
import './patient/aLK_forms/f066alk/stage_commons';
import './patient/certificates/view/view_certificate';
import './patient/certificates/drafts/edit_certificate_draft';
import './patient/aLK_forms/f025alk/parts/medications';
import './WebHelp/languages';
import './patient/aLK_forms/f066alk/parts/stage_conclusion';
import './patient/aLK_forms/070aLK/edit_070a_lk';
import './patient/certificates/certificates';
import './patient/certificates/e049/edit_e049';
import './user/layout/base';
import './patient/vaccines/list';
import './user/representatives/practitioner_item';
import './user/messages/new';
import './patient/aLK_forms/f025alk/edit_f025a_lk';
import './patient/layout/base';
import './patient/aLK_forms/f025alk/parts/surcharges';
import './patient/documents/modal/add_binary_file';
import './patient/aLK_forms/070aLK/parts/components';
import './patient/certificates/history/certificate_history';
import './user/main';
import './patient/encounters/view';
import './patient/certificates/e027_1_I/edit_e027_1_I';
import './user/account/settings_edit';
import './patient/aLK_forms/f066alk/parts/common_medications_view';
import './patient/erx/erx_presc_sign_callback';
import './user/messages/messages_util';
import './layout/base';
import './patient/documents/documents_amend';
import './patient/erx/erx_presc_sign';
import './user/account/view';
import './patient/aLK_forms/f066alk/edit_f066alk';
import './tasks/components/components';
import './patient/documents/layout/base';
import './patient/records/unfinished_records';
import './patient/certificates/e048_practitioner/edit_e048_practitioner';
import './patient/erx/erx_presc_view';
import './user/medvais/medvais';
import './patient/erx/erx_patient_history';
import './patient/documents/documents_new';
import './patient/documents/modal/add_comment';
import './patient/certificates/nesp/view_nesp';
import './patient/vaccines/new-manual';
import './patient/drafts/drafts';
import './patient/documents/modal/add_agreement';
import './patient/aLK_forms/070aLK/view_070a_lk';
import './patient/vaccines/new-baltipa';
import './patient/aLK_forms/f025alk/parts/interventions';
import './patient/certificates/modals/edit_position_and_qualification';
import './patient/certificates/modals/certificate_creation';
import './patient/aLK_forms/alk_forms';
import './patient/erx/erx_patient_history_view_prs';
import './user/representatives/edit';
import './patient/aLK_forms/f066alk/parts/common_medications';
import './patient/erx/erx_patient_history_view_prs_doc';
import './patient/aLK_forms/f066alk/parts/departments';
import './patient/certificates/e027_1_II/edit_e027_1_II';
import './user/documents/documents_sign';
import './patient/documents/documents_view';
import './patient/diagnosis/diagnosis';
import './patient/aLK_forms/f066alk/parts/cp_medications';
import './patient/reports/view_report';
import './patient/aLK_forms/f066alk/view_stage';
import './patient/erx/erx_patient_history_view_dsp';
import './user/medvais/e027va_unisignCallback';
import './patient/aLK_forms/sveidra_list_sub_form';
import './patient/vaccines/edit';
import './patient/documents/documents_edit_draft';
import './patient/records/modals/OrderSelectModalHelper';
import './patient/records/list';
import './patient/certificates/e103_1/edit_e103_1';
import './patient/aLK_forms/f066alk/parts/interventions';
import './user/messages/view';
import './user/records/records';
import './patient/erx/erx_presc_edit';
import './tasks/tasks';
import './patient/aLK_forms/sveidra_related_documents';
import './patient/aLK_forms/f066alk/edit_stage';
import './patient/documents/modification_actions';
import './patient/certificates/components/components';
import './WebHelp/data';
import './patient/aLK_forms/commons';
import './user/records/unfinished_records_sign';
import './patient/vaccines/calendar';
import './patient/certificates/modals/certificate_type_select';
import './patient/certificates/nesp/edit_nesp';
import './patient/automation/quick_vaccination';
import './patient/documents/documents_history';
import './user/reports/reports';
import './patient/encounters/new';
import './patient/vaccines/view';
import './patient/observations/observations';
import './patient/dgcCertificates/dgc_certificates';
import './patient/patient_summary/edit';
import './patient/patient_summary/view';
import './patient/patient_summary/history';
import './patient/representatives/representatives';
import './patient/representatives/edit';
import './patient/vaccines_records/edit';
import './patient/vaccines_records/records';
import './patient/vaccines_records/test-edit';
import './patient/vaccines_records/new-record';
import './patient/vaccines_records/vaccine-edit';
import './patient/main';
import './patient/dashboard';
import './main';
import './doctor_app';