ng1App.factory('UnfinishedRecordsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/unfinished-records';
    return $resource('', {}, {
        search: {
            method: 'GET',
            url: URL_BASE,
            isArray: true
        },
        count: {
            method: 'GET',
            url: URL_BASE + '/count'
        }
    });
}]);
