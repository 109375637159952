angular.module('erx.utils').directive('erxAllergies', ["ErxPatientsApiFactory", function (ErxPatientsApiFactory) {
  return {
    templateUrl: 'modules/erx-utils/components/erx-allergies/erxAllergies.tpl.html',
    restrict: 'E',
    replace: false,
    scope: {patientId: '='},
    link: function (scope: any, element, attrs) {
      scope.allergies = ErxPatientsApiFactory.getAllergies({id: scope.patientId});
    }
  };
}]);