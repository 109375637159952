ng1App.controller('doctor.user.AccountSettingsEditCtrl', 
    ['$scope', '$location', '$filter', 'DpSessionContext', 'MessageService', 'PractitionersApiFactory',
     function ($scope, $location, $filter, DpSessionContext, MessageService, PractitionersApiFactory) {
    $scope.$emit('setHelpPage', 'tvarkyti-specialisto-informavimo-nustatymus');

    $scope.countPerPageValues = [
        '5', '10', '15'
    ];

    $scope.form = {notification: {}, countPerPage: 10};

    $scope.mainPrefs = {};
    $scope.systemPrefs = {};

    $scope.practitioner = {};

    $scope.canEditNotifications = function () {
        return $filter('acWrite')('MessageList');
    };

    DpSessionContext.practitioner.$promise.then(function (a) {
        $scope.practitionerId = DpSessionContext.practitioner.id;
        $scope.load();
    });

    $scope.load = function () {
        MessageService.clearAll();
        PractitionersApiFactory.getSettings({}, function (data) {
            $scope.form = data;
        });
        PractitionersApiFactory.getFull({id: $scope.practitionerId}, function (data) {
            $scope.practitioner = data;
            $scope.practitioner.fullName = _.getFullName(data);
        });
    };

    $scope.save = function () {
        MessageService.clearAll();
        $scope.settingsForm.submitted = true;

        var hasCheckedNotification = false;
        if ($scope.canEditNotifications()) {
            for (var i = 0; i < $scope.form.notification.notificationMsgPreferences.length; i++) {
                if ($scope.form.notification.notificationMsgPreferences[i].isEnabled) {
                    hasCheckedNotification = true;
                    break;
                }
            }


            if (hasCheckedNotification && !$scope.form.notification.notificationEmail) {
                MessageService.add('error', $filter('translate')('err.notifcation.noEmail'), 'notification-region');
                $scope.settingsForm.notificationEmail.$setValidity("required", false);
            } else {
                $scope.settingsForm.notificationEmail.$setValidity("required", true);
            }
        }
        if ($scope.settingsForm.$valid) {
            $scope.saving = true;
            PractitionersApiFactory.saveSettings($scope.form, function (data) {
                $location.path('/dp');
                DpSessionContext.reloadSettings();
                MessageService.registerSuccessEvent();
            }).$promise.finally(function () {
                $scope.saving = false;
            });
        }
    };

    $scope.allSystemPrefsSelected = true;

    $scope.toggleSystemPrefs = function () {

    }

}]);