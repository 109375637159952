<nhr-record-widget
    [closed]="false"
    [closable]="true"
    [title]="'doc.mentalHealthComponent.diagnosesDescriptionForm.title' | translate"
    class="h-auto"
    *ngIf="editable || formGroup?.value"
>
    <div class="row mb-3" *ngIf="editable || formGroup?.value">
        <div class="col-md-3">
            <strong class="col-form-label text-md-end">
                {{ 'doc.mentalHealthComponent.diagnosesDescriptionForm.title' | translate }}
            </strong>
        </div>
        <div class="col-md-9">
            <app-input
                *ngIf="editable"
                [fControl]="formGroup"
            ></app-input>
            <p *ngIf="!editable && formGroup?.value"
               class="form-control-plaintext">
                {{ formGroup?.value }}
            </p>
        </div>
    </div>
</nhr-record-widget>
