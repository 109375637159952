<div *ngIf="docRefConsentTemplate">
    <app-title
        [backPath]="backPath"
        [badgeText]="badgeTitle | translate"
        [badgeClass]="badgeColor"
        [backSubtitle]="dateBetween"
    >
        {{docRefConsentTemplate.title}}
    </app-title>
    <div class="row mt-4">
        <div class="col-lg-6">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsentTemplate.view.template.organization' | translate" id="templateOrganization">
                <doc-ref-consent-span *ngFor="let span of consentOrganizationSpan" [span]="span"/>
            </nhr-record-widget>
        </div>
        <div class="col-lg-6">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsentTemplate.view.template.data' | translate" id="templateData">
                <doc-ref-consent-span *ngFor="let span of consentTemplateSpan" [span]="span"/>
            </nhr-record-widget>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'comp.docRefConsent.view.agreement.text' | translate" id="agreementText">
                {{docRefConsentTemplate.description}}
            </nhr-record-widget>
        </div>
    </div>
    <div class="row">
        <div class="mt-2 d-flex flex-row-reverse">
            <div class="me-1">
                <button class="btn btn-outline-secondary" type="button" (click)="goBack()">{{'comp.docRefConsent.new.back' | translate}}</button>
            </div>
        </div>
    </div>
</div>