import {Component, Input} from '@angular/core';
import {SpanModel} from "../../../models/span.model";
import {OrganizationModel} from "../../../models/organization.model";
import {ApiV2DocRefConsentTemplate} from "../../../../../api/api-v2-doc-ref-consent-template";
import {DocRefConsentTemplateService} from "../../../services/doc-ref-consent-template.service";
import {DocRefConsentTemplateModel} from "../../../models/doc-ref-consent-template.model";
import {PeriodModel} from "../../../models/period.model";
import {PortalTypeEnum} from "../../../models/portalType.enum";
import {RouteParams} from "../../../../ng1.routeParams";
import {Router} from "@angular/router";

@Component({
    selector: 'doc-ref-consent-template-view',
    templateUrl: './doc-ref-consent-template-view.component.html'
})
export class DocRefConsentTemplateViewComponent {

    backPath: string;
    @Input() docRefConsentTemplate: DocRefConsentTemplateModel;
    @Input() consentTemplateSpan: SpanModel[];
    @Input() consentOrganizationSpan: SpanModel[];
    @Input() badgeColor: string;
    @Input() badgeTitle: string;
    @Input() dateBetween: string;
    @Input() portalType: PortalTypeEnum;

    constructor(
        private apiV2DocRefConsentTemplate: ApiV2DocRefConsentTemplate,
        private docRefConsentTemplateService: DocRefConsentTemplateService,
        private router: Router,
        private params: RouteParams
    ) {
    }

    ngOnInit(): void {
        this.backPath = this.portalType + '/patients/' + this.params.patientId + '/doc-ref-consent-template/list';
        const id = this.params.id;
        this.loadDocRefConsentTemplate(id);
    }

    loadDocRefConsentTemplate(id: string) {
        return this.apiV2DocRefConsentTemplate.getDocRefConsentTemplate(id).subscribe((data: DocRefConsentTemplateModel) => {
            this.docRefConsentTemplate = data;
            let consentOrganization: OrganizationModel = this.docRefConsentTemplate.consentOrganization;
            this.consentOrganizationSpan = [
                {label: "comp.docRefConsent.view.organization.title", text: consentOrganization.name},
                {label: "comp.docRefConsent.view.organization.code", text: consentOrganization.jarCode},
                {label: "comp.docRefConsent.view.organization.address", text: consentOrganization.activityAddress.text},
                {label: "comp.docRefConsent.view.organization.phone", text: consentOrganization.mobilePhone}
            ];
            const period: PeriodModel = this.docRefConsentTemplate.period;
            const periodFormat: string = "YYYY-MM-DD";
            this.consentTemplateSpan = [
                {label: "comp.docRefConsentTemplate.view.template.from", text: this.docRefConsentTemplateService.getDateByFormat(period.start, periodFormat)},
                {label: "comp.docRefConsentTemplate.view.template.to", text: this.docRefConsentTemplateService.getDateByFormat(period.end, periodFormat)},
                {label: "comp.docRefConsentTemplate.view.template.category", text: this.docRefConsentTemplate.categoryTitle}
            ];

            this.badgeColor = this.docRefConsentTemplateService.getBadgeColor(this.docRefConsentTemplate.docStatus.code);
            this.badgeTitle = this.docRefConsentTemplateService.getDocStatus(this.docRefConsentTemplate.docStatus.code);
            this.dateBetween = this.docRefConsentTemplateService.getDateBetween(period.start, period.end);
        });
    }

    goBack(){
        this.router.navigate([this.backPath]);
    }
}