<div>
    <h1>{{ "E113-UG Vaisiaus utragarsinis tyrimas" | translate }}</h1>
    <app-document-caption [captionData]="documentCaptionData"></app-document-caption>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <app-ngn-caption [captionData]="ngnCaptionData"></app-ngn-caption>
    <app-encounter-block></app-encounter-block>
    <div class="row mb-2 req">
        <app-select id="ultrasoundStage" class="col d-flex flex-column" [placeholder]="null"
            [values]="ultrasoundStagesArray" [fControl]="formGroup?.get('ultrasoundStage')" />
    </div>

    <ng-container *ngIf="!!formGroup?.get('ultrasoundStage').value"
        [ngTemplateOutlet]="ultrasountTemplate"></ng-container>

    <app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
        (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
        [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
        [submitButtonDisabled]="true" [saveButtonDisabled]="!formGroup.valid"></app-modification-actions>
</div>

<ng-template #ultrasountTemplate>
    <app-ultrasound-template #ultrasoundTemplateComponent [title]="getUltrasoundStageValue"
        [formGroup]="getUltrasoundStageFormGroup" [pregnancyInfo]="pregnancyInfo"
        [ngnCaptionData]="ngnCaptionData"></app-ultrasound-template>
</ng-template>