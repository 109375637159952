<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formPostpartumHemorrhage.title' | translate"
    class="h-auto"
    bodyClass="p-0"
>
    <div class="card-body">
        <app-e096-ptkl-postpartum-hemorrhage-start [answersVariants]="answersVariants" />
        <app-e096-ptkl-assessment-woman-condition />
        <app-e096-ptkl-actions-taken [answersVariants]="answersVariants" />
        <app-e096-ptkl-end-assistance [editable]="editable"/>
    </div>
</nhr-record-widget>