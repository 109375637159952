import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";

@Injectable({
    providedIn: 'root',
})
export class ApiV2E096 extends ConfigService {

    path = this.apiUrl + '/e096';

    getE096FormView(form: string, id: string): Observable<any> {
        return this.http.get<any>(`${this.path}/${form}/view/${id}`);
    }
}