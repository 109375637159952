import { Component, DestroyRef, Input, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay, map, take } from 'rxjs';
import { E096PartNewbornComponent } from '../e096-part-newborn/e096-part-newborn.component';
import { formatDateForDatePickers } from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-part-partogram',
    templateUrl: './e096-part-partogram.component.html',
})
export class E096PartPartogramComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;

    @ViewChildren(E096PartNewbornComponent) newbornComponents: E096PartNewbornComponent[];

    currentTime = new Date();
    formGroup: FormGroup;
    isCorrectinMode: boolean = true;
    initialData: any;
    private dateFields: string[] = ['regularOnsetOfContractions', 'timeOfAmniocentesis'];

    get fruitLength() {
        return _.times(this.formGroup.get('numberOfFruits')?.value || 0, (n) => this.generateFruitName(n));
    }

    get checkValidityForCorrectionMode(): boolean {
        return Object.values(this.formGroup.controls).some(control => control.invalid);
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            regularOnsetOfContractions: this.fb.control(new Date()),
            numberOfFruits: this.fb.control(null, [Validators.required, Validators.min(1), Validators.max(3)])
        },
            {validators: [this.customValidateNonCorrectionMode.bind(this)]});

        this.formGroup.valueChanges.pipe(
            take(1),
            map(() => !this.checkValidityForCorrectionMode && this.formGroup.pristine)
        ).subscribe(res => {
            if (res) {
                this.changeCorrectionMode(false);
            }
        });

        this.formGroup.get('numberOfFruits').valueChanges.pipe(takeUntilDestroyed(this.destroyRef), delay(10))
            .subscribe(() => {
                this.formGroup.removeControl('fruits')
                this.formGroup.addControl('fruits',
                    this.fb.array(this.newbornComponents.map(component => component.formGroup)));
                if (!this.isCorrectinMode) {
                    this.formGroup.get('fruits').disable({emitEvent: false});
                }
            });
    }

    generateFruitName(i: number): string {
        return String.fromCharCode(65 + i);
    }

    changeCorrectionMode(force?: boolean): void {
        this.isCorrectinMode = force ?? !this.isCorrectinMode;
        if (this.isCorrectinMode) {
            this.initialData = formatDateForDatePickers(JSON.parse(JSON.stringify(this.formGroup.getRawValue())), this.dateFields);
            this.formGroup.enable({emitEvent: false});
        } else {
            this.formGroup.disable({emitEvent: false});
        }
    }

    cancel() {
        this.formGroup.reset(this.initialData, {emitEvent: true});
    }

    private customValidateNonCorrectionMode(): ValidationErrors | null {
        if (this.isCorrectinMode) {
            return { 'correctionMode': true };
        } else {
            return null;
        }
    }
}
