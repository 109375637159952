<nhr-record-widget
    [closable]="false"
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e097At.checkDoneForm.subForm' | translate"
>
    <div class="card-body">
        <div class="row">
            <app-select
                class="form-group flex-shrink-1"
                id="verificationType"
                placeholder="{{'doc.pregnancyDashboard.e097At.checkDoneForm.selectPlaceholder' | translate}}"
                [fControl]="formGroup.get('verificationType')"
                [showErrors]="true"
                [values]="selectionValuesFromEntity(verificationTypeOptions)"
            ></app-select>
        </div>
        <div class="row" [class.req]="controlValue('verificationType')">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e097At.checkDoneForm.examinationDate' | translate}}:"
                labelClass="form-label flex-grow-1 col-form-label"
                inputId="verificationDate"
                class="form-group col-12 col-lg-6"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD"
                [maxDate]="currentTime"
                [showErrors]="true"
                [fControl]="formGroup.get('verificationDate')"
            ></app-date-picker>
            <fieldset class="form-group col-12 col-lg-6">
                <legend class="float-none form-label flex-grow-1 col-form-label mt-3 fw-bold">
                    {{'doc.pregnancyDashboard.e097At.checkDoneForm.examination' | translate}}:
                </legend>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of verificationStatusOptions; let i = index"
                        radioName="verificationStatus-{{i}}"
                        [fControl]="formGroup.get('verificationStatus')"
                        [radioValue]="option.name"
                        id="verificationStatus-{{i}}"
                        class="col-auto my-2"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
                <app-form-control-validation [fControl]="formGroup.get('verificationStatus')" />
            </fieldset>
            <ng-container *ngIf="controlValue('verificationType') as type">
                <ng-container
                    [ngTemplateOutlet]="type ===  'Dėl ĮKŠY SpO2(%)' ? hip
                        : type ===  'Dėl paveldimų medžiagų apykaitos ligų (PMAL)' ? hereditaryMetabolic
                            : plusMinus"
                ></ng-container>
            </ng-container>
            <div class="row">
                <app-input
                    type="textarea"
                    class="form-group col-12"
                    labelText="{{'doc.pregnancyDashboard.e097At.checkDoneForm.nonPerformanceReason' | translate}}:"
                    labelClass="norq"
                    [fControl]="formGroup.get('nonPerformanceReason')"
                ></app-input>
            </div>
            <div class="row">
                <app-date-picker
                    labelText="{{'doc.pregnancyDashboard.e097At.checkDoneForm.needRepeat' | translate}}:"
                    labelClass="form-label flex-grow-1 col-form-label"
                    inputId="verificationDate"
                    class="form-group col-12 col-lg-6"
                    suffixIcon="fa fa-calendar"
                    dateInputFormat="YYYY-MM-DD"
                    [minDate]="currentTime"
                    [showErrors]="true"
                    [fControl]="formGroup.get('repeatDate')"
                ></app-date-picker>
            </div>
            <div class="row">
                <span class="form-label fw-bolder col-form-label">
                    {{'doc.pregnancyDashboard.e097At.checkDoneForm.performed' | translate}}:
                </span>
                <div class="col-12 mt-2">
                    <app-find-specialists
                        [editable]="editable"
                        [disable]="!controlValue('verificationType')"
                        [customDisplaying]="false"
                        [formArray]="formGroup?.get('performed')"
                    ></app-find-specialists>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer mt-4" *ngIf="editable">
        <div class="ms-auto d-flex gap-3 justify-content-end">
            <button class="btn btn-outline-primary" [disabled]="formGroup?.invalid" (click)="submit()">{{'submit' | translate}}</button>
            <button class="btn btn-outline-danger" (click)="cancel()">{{'cancel' | translate}}</button>
        </div>
    </div>
</nhr-record-widget>

<ng-template #plusMinus>
    <div class="row">
        <fieldset class="form-group col-12">
            <legend class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e097At.checkDoneForm.resultLeft' | translate}}:
            </legend>
            <div class="input-group gap-3">
                <app-radio-button
                    *ngFor="let option of plusMinusOptions; let i = index"
                    radioName="resultLeft-{{i}}"
                    [fControl]="formGroup.get('resultLeft')"
                    [radioValue]="option.name"
                    id="resultLeft-{{i}}"
                    class="col-auto"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
            <app-form-control-validation [fControl]="formGroup.get('resultLeft')" />
        </fieldset>
    </div>
    <div class="row">
        <fieldset class="form-group col-12">
            <legend class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e097At.checkDoneForm.resultRight' | translate}}:
            </legend>
            <div class="input-group gap-3">
                <app-radio-button
                    *ngFor="let option of plusMinusOptions; let i = index"
                    radioName="resultRight-{{i}}"
                    [fControl]="formGroup.get('resultRight')"
                    [radioValue]="option.name"
                    id="resultRight-{{i}}"
                    class="col-auto"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
            <app-form-control-validation [fControl]="formGroup.get('resultRight')" />
        </fieldset>
    </div>
</ng-template>

<ng-template #hip>
    <div class="row">
        <fieldset class="form-group col-12">
            <legend class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e097At.checkDoneForm.resultRightHand' | translate}}:
            </legend>
            <div class="input-group gap-2">
                <app-radio-button
                    *ngFor="let option of percentOptions; let i = index"
                    radioName="resultRightHand-{{i}}"
                    [fControl]="formGroup.get('resultRightHand')"
                    [radioValue]="option.name"
                    id="resultRightHand-{{i}}"
                    class="col-12"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
            <app-form-control-validation [fControl]="formGroup.get('resultRightHand')" />
        </fieldset>
    </div>
    <div class="row">
        <fieldset class="form-group col-12">
            <legend class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e097At.checkDoneForm.resultRightLeg' | translate}}:
            </legend>
            <div class="input-group gap-2">
                <app-radio-button
                    *ngFor="let option of percentOptions; let i = index"
                    radioName="resultRightLeg-{{i}}"
                    [fControl]="formGroup.get('resultRightLeg')"
                    [radioValue]="option.name"
                    id="resultRightLeg-{{i}}"
                    class="col-12"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
            <app-form-control-validation [fControl]="formGroup.get('resultRightLeg')" />
        </fieldset>
    </div>
</ng-template>

<ng-template #hereditaryMetabolic>
    <div class="row">
        <fieldset class="form-group col-12">
            <legend class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                {{'doc.pregnancyDashboard.e097At.checkDoneForm.result' | translate}}:
            </legend>
            <div class="input-group gap-3">
                <app-radio-button
                    *ngFor="let option of plusMinusOptions; let i = index"
                    radioName="result-{{i}}"
                    [fControl]="formGroup.get('result')"
                    [radioValue]="option.name"
                    id="result-{{i}}"
                    class="col-auto"
                    formClass="mb-0"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
            <app-form-control-validation [fControl]="formGroup.get('result')" />
        </fieldset>
    </div>
</ng-template>