import { withSysDate } from "../../../../../documents/components/components";

ng1App.directive('eptsInsuranceEdit', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/certificates/components/edit/epts_insurance_edit.html',
		restrict: 'E',
		require: '^form',
		scope: {
			insurance: '='
		},
		link: function (scope: any, element, attrs, form) {
			scope.form = form;

			scope.$watch('insurance.type', function () {
				if (scope.insurance && scope.insurance.type == 'UNINSURED') {
					scope.insurance.series = null;
				}
			});
		}
	};
});

ng1App.directive('eptsInsuranceView', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/certificates/components/view/epts_insurance_view.html',
		restrict: 'E',
		scope: {
			insurance: '='
		},
		link: function (scope: any, element, attrs) {
			scope.getInsuranceTypeTitleKey = function () {
				if (!scope.insurance) {
					return '';
				}
				switch (scope.insurance.type) {
				case 'UNEMPLOYMENT':
					return 'cer.data.ins.doc.tip.unemployment';
				case 'MHA_EMPLOYEE':
					return 'cer.data.ins.doc.tip.mha_employee';
				case 'UNINSURED':
					return 'cer.data.ins.doc.tip.uninsured';
				default:
					return '';
                }
            };
		}
	};
});

//2020-04-06 AS modified
ng1App.directive('editSpecialRecommendations',
    ['TypeaheadSearchHandlers', 'MessageService', 'SessionApiFactory', '$modalConfirm', 'SubFormValidator', '$filter', 'ConditionsApiFactory',
     function (TypeaheadSearchHandlers, MessageService, SessionApiFactory, $modalConfirm, SubFormValidator, $filter, ConditionsApiFactory) {
	return {
		templateUrl: '/templates/portal/doctor/patient/certificates/components/edit/edit_special_recommendations.html',
		restrict: 'E',
		replace: false,
		scope: true,
		transclude: true,
		link: function (scope: any, element, attrs) {
			//add block to document content
			if (scope._shared.contentList) {
				scope._shared.contentList[attrs.contentIndex] = {
					title: $filter('translate')('doc.dia'),
					id: 'doc_dia'
				};
			}

			if (!scope._removed.diagnosis) {
				scope._removed.diagnosis = [];
			}
			if (!scope._modified.diagnosis) {
				scope._modified.diagnosis = [];
			}

			var initDiagnosisForm = function () {
				scope.diagnosisForm = {tempdiagnosis:''};
			}

			initDiagnosisForm();

			scope.validator = new SubFormValidator('special-recommendations-region');

			/**
			 * on change handler needed for angular-strap typeahead bug on FF.
			 *  https://github.com/mgcrea/angular-strap/issues/652
			 * @param v
			 */
			scope.searchDiagnosisChange = function (v) {
				if (v && v.code) {
					scope.diagnosisForm.diagnosis = v;
				}
			}

			/**
			 * Function for diagnosis typeahead combo.
			 */
			scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;

			scope.__modifyDiagnosis = function (d) {
				scope.validator.clear();
				scope.diagnosisForm = _.clone(d);
				scope.diagnosisForm.orig = d;
				scope.diagnosisForm._modeModification = true;
				scope.diagnosisForm.tempdiagnosis = d.diagnosis.code + ' ' + d.diagnosis.name;
				if (scope.showDiagnosisType) {
					scope.diagnosisForm.type = _.find(scope.types.diagnosisTypes, function (i) {
						return d.type.code === i.code;
					});
				}
			}

			scope.__cancelDiagnosisModification = function () {
				scope.validator.clear();
				initDiagnosisForm();
			}

			scope.__saveDiagnosis = function () {
				doSave(function () {
					var data = _.clone(scope.diagnosisForm);
					// withSysDate(SessionApiFactory, data, function () {
					// 	if(data.fullId){
					// 		var found = _.find(scope._modified.diagnosis, function(d){
					// 			return d.fullId == data.fullId;
					// 		});
					// 		if(!found){
					// 			scope._modified.diagnosis.push(data);
					// 		}
					// 	}
					// 	var orig = scope.diagnosisForm.orig;
					// 	scope.diagnosisForm.orig = null;
					// 	angular.copy(scope.diagnosisForm, orig);
					// 	initDiagnosisForm();
					// });
				});
			}

			var doSave = function (callback) {
				scope.validator.clear();
				var showComonError = true;
				if (!scope.diagnosisForm.recommendation) {
					scope.validator.add('recommendation');
				}

				if (!scope.diagnosisForm.diagnosis || !scope.diagnosisForm.diagnosis.code) {
					scope.validator.add('diagnosis');
				} else if (!scope.diagnosisForm._modeModification && _.find(scope.certificate.partSpecialRecommendations, function (d) {
					return d.diagnosis.code == scope.diagnosisForm.diagnosis.code;
				}) != null) {
					scope.validator.add('diagnosis');
					showComonError = false;
					MessageService.add('error', 'Diagnozė jau pridėta.', 'special-recommendations-region');
				}

				if (!scope.validator.hasErrors()) {
					callback();
				} else if (showComonError) {
					MessageService.add('error', 'Formoje yra klaidų', 'special-recommendations-region');
				}
			}


			scope.__addDiagnosis = function () {
				doSave(function () {
					var data = _.clone(scope.diagnosisForm);
					scope.certificate.partSpecialRecommendations.push(data);
					initDiagnosisForm();
				});
			};

			scope.___removeDiagnosis = function (index) {
				$modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
					MessageService.clear('special-recommendations-region');
					var removed = scope.certificate.partSpecialRecommendations.splice(index, 1);
					if (removed[0].fullId) {
						scope._removed.diagnosis.push(removed[0]);
					}
				});
			};
		}
	};
}])


//2020-04-19 AS modified
ng1App.directive('editGeneralRecommendations',
    ['EntitiesApiFactory',
     function (EntitiesApiFactory) {
	return {
		templateUrl: '/templates/portal/doctor/patient/certificates/components/edit/edit_general_recommendations.html',
		restrict: 'E',
		replace: false,
		require: '^form',
		scope: true,
		transclude: true,
		link: function (scope: any, element, attrs, form) {
			scope.form = form;
			scope.generalRecommendations = EntitiesApiFactory.getList({
				classCode: 'obs-general-recommendations'
			});

			var initPartGeneralRecommendationsForm = function () {
				var index = findGeneralRecommendationIndex('5');
				var grForm = scope.partGeneralRecommendationsForm;
				if (typeof index !== 'undefined') {
					grForm.genOtherRecommendationText = scope.certificate.partGeneralRecommendations[index].recommendationText;
				}
				scope.certificate.partGeneralRecommendations.forEach(function (rec) {
					grForm.partGeneralRecommendations[rec.recommendation.code] = true;
				});
			}

			var removeGeneralRecommendation = function(item) {
				var index = findGeneralRecommendationIndex(item.code);
				scope.certificate.partGeneralRecommendations.splice(index,1);
			}

			var findGeneralRecommendationIndex = function(code) {
				for (var i = scope.certificate.partGeneralRecommendations.length - 1; i >= 0; --i) {
					if (scope.certificate.partGeneralRecommendations[i].recommendation.code === code) {
						return i;
					}
				}
			}

			scope.__generalRecommendationSelected = function (item) {
                var grForm = scope.partGeneralRecommendationsForm;
                var certGenRec = scope.certificate.partGeneralRecommendations;
				if (grForm.partGeneralRecommendations[item.code]) {
					certGenRec.push({recommendation: item});
                    certGenRec.sort(function(a, b){
                        var codeA=a.recommendation.code, codeB=b.recommendation.code;
                        if (codeA < codeB) return -1;
                        if (codeA > codeB) return 1;
                        return 0;
                    });
				} else {
					removeGeneralRecommendation(item);
				}
				if (item.code === '5') {
					grForm.genOtherRecommendationText = null;
				}
			};

			scope.__updateRecomendationText = function() {
				var index = findGeneralRecommendationIndex('5');
				scope.certificate.partGeneralRecommendations[index].recommendationText = scope.partGeneralRecommendationsForm.genOtherRecommendationText;
			}

			initPartGeneralRecommendationsForm();

		}
	};
}])
