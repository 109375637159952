import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams, IRowNode} from "ag-grid-community";

export interface ICustomTextCellParams {
}

export interface TextCellLinkModel {
    onClickExpand: boolean;
    formattedText?: string | KeyValueTextModel;
}

export interface KeyValueTextModel{
    key: string;
    value: string | number | undefined;
}

export interface TextCellModel {
    mainText: string[] | KeyValueTextModel[];
    additionalText?: string[] | KeyValueTextModel[];
    linkText?: TextCellLinkModel[];
    simpleText?: string;
    hasTooltip?: boolean;
}

@Component({
    selector: "ag-text-cell",
    templateUrl: "./ag-text-cell.component.html",
})
export class AgTextCell implements ICellRendererAngularComp {

    params: ICellRendererParams & ICustomTextCellParams;
    rowNode: IRowNode;
    textCellModel: TextCellModel;

    constructor() {
    }

    agInit(params: ICellRendererParams & ICustomTextCellParams): void {
        this.params = params;
        this.rowNode = params.node;
        this.textCellModel = params.value;
    }

    refresh(params: ICellRendererParams & ICustomTextCellParams): boolean {
        this.rowNode = params.node;
        this.textCellModel = params.value;
        return true;
    }

    onLinkClick(onClickExpand: boolean) {
        if (onClickExpand) {
            this.params.api.setRowNodeExpanded(this.rowNode, !this.rowNode.expanded);
        }
    }

}