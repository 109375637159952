import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    getControlValue,
    listenAndChangeStatusOfControl,
} from '../../../../../e096/e096-utils/utils';

@Component({
    selector: 'app-e097-gd-general-inspection',
    templateUrl: './e097-gd-general-inspection.component.html',
})
export class E097GdGeneralInspectionComponent implements OnInit {
    @Input() editable: boolean = true;
    formGroup: FormGroup;
    currentTime = new Date();
    dateFields: string[] = ['verificationDate', 'repeatDate'];

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;

    constructor(
        private readonly fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            performanceDate: this.fb.control(null, [Validators.required]),
            inspectionWasCarriedOut: this.fb.array([], [Validators.maxLength(1)]),
        });
    }
}
