import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../../models/entity.model';
import {
    generateSelectionValuesFromEntity, getControlValue,
    listenAndChangeStatusOfControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
@Component({
    selector: "app-inr-notification-type-subform",
    templateUrl: "./inr-notification-type-subform.component.html",
})
export class InrNotificationTypeSubformComponent {
    @Input() editable: boolean = true;

    typesOfNotification: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pirminis'
        },
        {
            id: '1',
            code: '1',
            name: 'Papildomas'
        }
    ];
    selectOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Item 1'
        },
        {
            id: '1',
            code: '1',
            name: 'Item 2'
        },
        {
            id: '2',
            code: '2',
            name: 'Item 3'
        },
        {
            id: '3',
            code: '3',
            name: 'Item 4'
        }
    ];

    formGroup: FormGroup;

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    selectFromEntities = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    constructor(
        private fb: FormBuilder
    ) {
        this.formGroup = this.fb.group({
            typeOfNotification: this.fb.control(null, [Validators.required]),
            report: this.fb.control(null)
        });

        this.changeStatusOfControl({
            controlNameForListen: 'typeOfNotification',
            controlNameForUpdate: 'report',
            enableValue: 'Papildomas',
        }).pipe(takeUntilDestroyed())
            .subscribe();
    }
}
