import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Observable } from 'rxjs';
import { EntityModel } from '../../../models/entity.model';
import {
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl
} from '../../../../doctor/ngn/components/e096/e096-utils/utils';
import { ApiV2Inr } from '../../../services/api-v2-inr';
@Component({
    selector: "app-inr-vaccine-caused-inr-subform",
    templateUrl: "./inr-vaccine-caused-inr-subform.component.html",
})
export class InrVaccineCausedInrSubformComponent {
    @Input() editable: boolean = true;

    vaccineSelectionVariants: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pasirinkti vakciną iš skiepijamo fakto (E063)'
        },
        {
            id: '1',
            code: '1',
            name: 'Įvesti pačiam vakcinos duomenis'
        }
    ];
    selectOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: '1 dozė'
        },
        {
            id: '1',
            code: '1',
            name: '2 dozė'
        },
        {
            id: '2',
            code: '2',
            name: '3 dozė'
        },
        {
            id: '3',
            code: '3',
            name: '4 dozė'
        },
        {
            id: '4',
            code: '4',
            name: '5 dozė'
        },
        {
            id: '5',
            code: '5',
            name: '6 dozė'
        }
    ];

    formGroup: FormGroup;
    vaccineSelectionVariant: FormControl = this.fb.control(null);
    searchVaccine: FormControl = this.fb.control(null);
    maxDate = new Date();

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;
    selectFromEntities = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    getVaccinesObs = this.getVaccines.bind(this);

    get dosesCount() {
        const doses = this.selectOptions.find((item => item.name === this.formGroup.get('dosesWasVaccinated').value));
        return this.selectOptions.indexOf(doses) + 1;
    }

    get getDosesFormArrayControls() {
        return (this.formGroup.get('doses') as FormArray).controls as FormControl[];
    }
    constructor(
        private fb: FormBuilder,
        private apiV2Inr: ApiV2Inr
    ) {
        this.formGroup = this.fb.group({
            vaccineName: this.fb.control(null, [Validators.required]),
            vaccineSerialNumber: this.fb.control(null),
            vaccinationDate: this.fb.control(null),
            dosesWasVaccinated: this.fb.control(null),
            doses: this.fb.array([])
        });

        this.formGroup.get('dosesWasVaccinated').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.updateFormArrayControlsControls();
            });
    }

    log() {
        console.log(this.formGroup);
    }

    getVaccines(query: string): Observable<any> {
        return this.apiV2Inr.getMockVaccines({q: query}).pipe(
            map(item => item
                .map(p => {
                    p['fullDisplay'] =`${ p.vaccineName } ${ p.vaccineSerialNumber }`;
                    return p;
                })
            ))
    }

    selectVaccine(vaccine: any) {
        this.formGroup.patchValue(vaccine);
    }

    private updateFormArrayControlsControls() {
        const formArray = this.formGroup.get('doses') as FormArray;

        formArray.clear();

        for (let i = 0; i < this.dosesCount; i++) {
            formArray.push(this.fb.control(null));
        }
    }
}
