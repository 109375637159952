import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription, map } from "rxjs";
import { ApiV2PregnancyDashboard } from "../../../../../api/api-v2-doc-pregnancy-dashboard";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";
import { RouteParams } from "../../../../ng1.routeParams";
import { toDateDto } from "../../../../shared/services/utils/utils";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { ParentalConsentModel } from "../../models/parentalConsent.model";
import { Consents } from "./parental-consent.constants";

@Component({
    selector: "app-parental-consent",
    templateUrl: "./parental-consent.component.html",
})
export class ParentalConsentComponent implements OnInit, OnDestroy {
    documentTag: DocumentTagEnum = DocumentTagEnum.e097_ts;
    prefillData: any;
    public formGroup: FormGroup;
    public readonly consentValues = Object.values(Consents);
    public consent;
    private patientId: string;
    backPath: string;
    today = new Date();

    public consentGivenBy = [
        {
            name: "Motina",
            value: "mother",
        },
        {
            name: "Tėvas",
            value: "father",
        },
        {
            name: "Kitas",
            value: "other",
        },
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly _fb: FormBuilder,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
    ) { }

    public get getConsentsArray(): FormArray {
        return this.formGroup?.get("consents") as FormArray;
    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.formGroup = this.makeForm();

        this._apiV2PregnancyDashboard.getParentalConsentClassifiers().then((val) => {
            this.consent = val.consent;
        });
        this.patchConsents();
        this.backPath = '/dp/patients/' + this.patientId
        this.getPrefillData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    private patchConsents(): void {
        this.consentValues.forEach((name) => {
            (this.formGroup?.get("consents") as FormArray).push(
                this._fb.group({
                    name: new FormControl(name),
                    shortName: new FormControl(
                        Object.keys(Consents)[Object.values(Consents).indexOf(name as unknown as Consents)]
                    ),
                    selection: new FormControl(null),
                })
            );
        });
    }

    public clearForm(): void {
        this.ngOnInit();
    }

    private makeForm(): FormGroup {
        return this._fb.group({
            consentDate: new FormControl(""),
            consentTime: new FormControl(null),
            consents: this._fb.array([]),
            consentGivenBy: new FormControl(""),
            consentGivenByComment: new FormControl(""),
        });
    }

    onSave() {
        console.log("Resultatai (formGroup): ", this.formGroup.value);
        console.log("DTO ---->", this.formToDto());
    }

    formToDto(): ParentalConsentModel {
        return {
            id: "",
            consentDate: toDateDto(this.formGroup.get('consentDate').value),
            consentTime: this.formGroup.get('consentTime').value,
            consents: this.formGroup.get('consents').value,
            consentGivenBy: this.formGroup.get('consentGivenBy').value,
            consentGivenByComment: this.formGroup.get('consentGivenByComment').value
        };
    }
}
