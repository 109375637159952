ng1App.controller('doctor.patient.erx.PatientHistoryCtrl', 
    ['$scope', '$routeParams', '$window', 'ErxHistoryPaths', '$location',
     function ($scope, $routeParams, $window, ErxHistoryPaths, $location) {

    $scope.erxPatientHistoryOptions = {
        // redirect to page with erx-view-dispense
        redirectToViewDsp: function (id) {
            $location.url(ErxHistoryPaths.viewDsp(id));
        },
        // redirect to page with erx-view-prescription-ro
        redirectToViewPrs: function (id) {
            $location.url(ErxHistoryPaths.viewPrs(id));
        },
        // redirect to page with erx-view-prescription-doc-ro
        redirectToViewPrsDoc: function (id) {
            $location.url(ErxHistoryPaths.viewPrsDoc(id));
        },
        // current patient id
        getPatientId: function () {
            return $routeParams.patientId;
        },
        openPrintMedicines: function () {
            $window.open(ErxHistoryPaths.printMedicines());
        }
    };

}]);