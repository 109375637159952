ng1App.factory('EntitiesApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/entities';
    return $resource('', {}, {
        getList: {
            method: 'GET',
            url: URL_BASE + '/:classCode/list?searchCriteria=:searchCriteria&typeCode=:typeCode&byCode=:byCode&valid=:valid',
            isArray: true
        },
        getListWithCount: {
            method: 'GET',
            url: URL_BASE + '/:classCode/list?searchCriteria=:searchCriteria&typeCode=:typeCode&byCode=:byCode&valid=:valid&count=:count',
            isArray: true
        },
        getClassifierList: {
            method: 'GET',
            url: URL_BASE + ''
        },
        getClassifierCount: {
            method: 'GET',
            url: URL_BASE + '/count'
        },
        get: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        save: {
            method: 'POST',
            url: URL_BASE
        },
        update: {
            method: 'PUT',
            url: URL_BASE + '/:id'
        },
        topDiagnosis: {
            method: 'GET',
            url: URL_BASE + '/most-used-diagnosis',
            isArray: true
        },
		infectiousDiseases: {
			method: 'GET',
			url: URL_BASE + '/tlk-10-am/infectiousDiseases'
			//'http://localhost:8181/cxf/Classifiers/tlk-10-am/infectiousDiseases'
        },
        medPrescriptionConditions: {
            method: 'GET',
            url: URL_BASE + '/med-pres-conditions/list',
            isArray: true
        },
    });

}]);

ng1App.factory('ClassifiersApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

    var URL_BASE = apiUrl + '/classifiers';
    return $resource('', {}, {
        search: {
            method: 'GET',
            url: URL_BASE + '/:classCode/?count=10',
            isArray: false
        },
        getClassifiersList: {
            method: 'GET',
            url: URL_BASE + '/classifiersList',
            isArray: false
        },
        getAttachmentTypesByDocType: {
            method: 'GET',
            url: URL_BASE + '/attached-file-description/by-doc-type',
            isArray: false
        },
        getAgreementsTypesByDocType: {
            method: 'GET',
            url: URL_BASE + '/patient-agreement-type/by-doc-type',
            isArray: false
        },
        getAgreementsTypesForPatient: {
            method: 'GET',
            url: URL_BASE + '/patient-agreement-type/for-patient',
            isArray: false
        },
        getAgreementsTypesForEncounter: {
            method: 'GET',
            url: URL_BASE + '/patient-agreement-type/for-encounter',
            isArray: false
        },
        getEOrderStatuses: {
            method: 'GET',
            url: URL_BASE + '/e-order-status',
            isArray: true
        }
    });

}]);
