import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, take } from "rxjs";
import { ApiV2Session } from "../../../../api/api-v2-session";
import { EnvironmentSettings } from "../../../../environments/env";
import { Environment } from "../../../../environments/environment-properties";
import { RouteParams } from "../../../ng1.routeParams";
import { InrModel } from "../../models/inr.model";
import { ApiV2Inr } from "../../services/api-v2-inr";
import { Pagination } from "../list/pagination-bar/pagination-bar.constants";
import PortalType = Environment.PortalType;

@Component({
    selector: "app-inr-list-page",
    templateUrl: "./inr-list-page.component.html",
})
export class InrListPageComponent implements OnDestroy {
    inrHistory: InrModel[] = [];
    backUrl: string;

    public total: number;
    public pagesCount: number;
    public isEditable: boolean;

    private patientId: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private apiV2Inr: ApiV2Inr,
        private params: RouteParams,
        private router: Router,
        private apiV2Session: ApiV2Session
    ) {
        this.isEditable = EnvironmentSettings.getPortalType() === PortalType.DOCTOR;

        this.getPatientId();

        this.apiV2Inr
            .getInrList(this.patientId)
            .pipe(take(1))
            .subscribe((res) => {
                this.inrHistory = res.items;

                this.total = res.total;

                const pagesCount = res.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPatientId() {
        if (this.params.patientId) {
            this.patientId = this.params.patientId;
        } else {
            this.subscription.add(this.subscribePatient());
        }

        this.backUrl = "/dp/patients/" + this.patientId;
    }

    openInr(i: number) {
        this.router.navigate(
            this.isEditable ? [`/dp/patients/${this.patientId}/inr/view/${i}`] : [`/pp/inr/view/${i}`]
        );
    }

    updatePagination(pagination: Pagination) {
        this.apiV2Inr
            .getInrList(this.patientId, { page: pagination.page })
            .pipe(take(1))
            .subscribe((res) => {
                this.inrHistory = res.items;

                this.total = res.total;

                const pagesCount = res.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
    }

    createNewInr() {
        this.router.navigate(this.isEditable ? [`/dp/patients/${this.patientId}/inr/new`] : [`/pp/inr/new`]);
    }

    inrName(inr: InrModel) {
        if (inr.notificationForm === "Pranešimas dėl vaistinio preparato") {
            const name = [];
            name.push(
                inr.drugs.medicinalProductName,
                inr.drugs.activeSubstanceName,
                inr.drugs.strength,
                inr.drugs.pharmaceuticalForm
            );
            return name.filter((str) => !!str).join(", ");
        } else if (inr.notificationForm === "Pranešimas dėl vakcinos") {
            return inr.vaccine.vaccineName;
        }
        return "";
    }

    private subscribePatient(): Subscription {
        return this.apiV2Session.getPatient().subscribe((data) => {
            this.patientId = data.id;
        });
    }
}
