<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Part.formPatrogram.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row req">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Part.formPatrogram.contractionsOnset' | translate}}:"
                labelClass="form-label flex-grow-1 col-form-label norq"
                inputId="regularOnsetOfContractions"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="currentTime"
                [fControl]="formGroup.get('regularOnsetOfContractions')"
            ></app-date-picker>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-input
                    type="number"
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.fruitsNumber' | translate}}:"
                    labelClass="form-label flex-grow-1 col-form-label"
                    [fControl]="formGroup.get('numberOfFruits')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('numberOfFruits')" />
            </div>
        </div>
        <nhr-record-widget
            [closed]="false"
            [title]="'doc.pregnancyDashboard.e096Part.formPatrogram.fetusData' | translate"
            class="h-auto d-block mt-3"
            bodyClass="bg-light p-2"
            *ngIf="fruitLength?.length"
        >
            <div class="card-body">
                <ng-container *ngFor="let fruit of fruitLength; let i = index">
                    <app-e096-part-newborn
                        [answersVariants]="answersVariants"
                        [editable]="editable"
                        [fruit]="fruit"
                        class="d-block p-3 bg-white"
                        [class.mt-2]="i !== 0"
                    />
                </ng-container>
            </div>
        </nhr-record-widget>
    </div>
    <div class="card-footer d-flex gap-3 justify-content-end mt-3" *ngIf="editable">
        <ng-container *ngIf="isCorrectinMode">
            <button class="btn btn-outline-primary flex-grow-0" [disabled]="checkValidityForCorrectionMode" (click)="changeCorrectionMode()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
            <button class="btn btn-danger flex-grow-0" (click)="cancel()">{{'cancel' | translate}}</button>
        </ng-container>
        <ng-container *ngIf="!isCorrectinMode">
            <button class="btn btn-primary flex-grow-0" (click)="changeCorrectionMode()">{{'doc.dia.edt' | translate}}</button>
        </ng-container>
    </div>
</nhr-record-widget>