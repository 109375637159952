<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true" [closed]="closed" [mixed]="true"
                   [bodyClass]="'widget-body'" class="form-horizontal" [title]="'cer.025.info'|translate">
<form *ngIf="patientData">
    <!--<strong class="col-md-4 col-lg-3 col-form-label">{{'doc.mai.inf.pat'}}</strong>
    <div class="form-group row" *ngIf="patientData">
        <label for="templateTitle" class="col-sm-3 col-form-label d-flex flex-row-reverse">Pacientas</label>
        <div class="col-sm-5">
            <input type="text" class="form-control" id="templateTitle" formControlName="fullName"/>
        </div>
        <span *ngIf="patientData.birthDate">{{patientData.birthDate }}</span><span *ngIf="patientData.sex">, {{patientData.sex.name}}</span><br *ngIf="patientData.birthDate || patientData.sex">
    </div> -->
    <strong class="col-md-4 col-lg-3 col-form-label">{{'doc.mai.inf.pat'|translate}}</strong>
    <div class="col-md-8 col-lg-9">
        <p class="form-control-plaintext" *ngIf="patientData">
            <strong>{{patientDataFullName | capitalize}}</strong><span *ngIf="patientDataFullName">,</span> {{'doc.mai.inf.his'|translate}}<!--sveikatos istorijos Nr. --> {{patientData.esiNr}}<br>
            <span *ngIf="patientData.personalCode || patientData.foreignPersonalCode">{{'perCode'|translate}}: {{patientData.personalCode || patientData.foreignPersonalCode}}<br><!-- Asm. k.--></span>
            <span *ngIf="patientData.birthDate">{{'doc.mai.inf.birth'|translate}}: {{patientData.birthDate | date:'yyyy-MM-dd'}}, {{patientAge}} m.</span><span *ngIf="patientData.sex">, {{patientData.sex.name}}<!-- Gimimo data--></span><br *ngIf="patientData.birthDate || patientData.sex">
            <span *ngIf="(patientData.declaredAddress && patientData.declaredAddress.text)">
                {{ 'dpp.pat.mai.dec.adr'|translate }}
                {{patientData.declaredAddress.text}}
            </span>
            <span *ngIf="(patientData.residenceAddress && patientData.residenceAddress.text) && !(patientData.declaredAddress && patientData.declaredAddress.text)">
                {{patientData.residenceAddress.text}}
            </span>
            <span *ngIf="(patientData.foreignAddress && (patientData.foreignAddress.text || patientData.foreignAddress.city || patientData.foreignAddress.country)) && !(patientData.residenceAddress && patientData.residenceAddress.text) && !(patientData.declaredAddress && patientData.declaredAddress.text)">
                {{ 'dpp.pat.con.add'|translate }}
                {{
                    (patientData.foreignAddress.text ? patientData.foreignAddress.text : '')+(patientData.foreignAddress.city ? ', ' : '')+
                    (patientData.foreignAddress.city ? patientData.foreignAddress.city : '')+(patientData.foreignAddress.country ? ', ' : '')+
                    (patientData.foreignAddress.country ? patientData.foreignAddress.country.name : '')
                }}
            </span>
            <span *ngIf="!(patientData.foreignAddress && (patientData.foreignAddress.text || patientData.foreignAddress.city || patientData.foreignAddress.country)) && !(patientData.residenceAddress && patientData.residenceAddress.text) && !(patientData.declaredAddress && patientData.declaredAddress.text)">
                {{('err.dec_adr_notFound' | translate)}}
            </span>
            <span *ngIf="patientData.phones.length >= 1">
                <br>
                {{ 'Phone'|translate }}:
                <span *ngFor="let p of patientData.phones; let last = last; ">
                    {{ p }}
                    <span *ngIf="!last">, </span>
                </span>
            </span>
            <span *ngIf="patientData.emails.length >= 1">
                <br>
                {{ 'Email'|translate }}:
                <span *ngFor="let e of patientData.emails; let last = last; ">
                    {{ e }}
                    <span *ngIf="!last">, </span>
                </span>
            </span>
        </p>
    </div>
</form>
<div class="form-group" *ngIf="practitioner">
    <strong class="col-md-4 col-lg-3 col-form-label">{{'doc.mai.inf.spe'|translate}}<!--Specialistas--></strong>
    <div class="col-md-8 col-lg-9">
        <!--practitioner:{{practitioner.validQualificationList}}-->
        <p class="form-control-plaintext" >
            <strong>{{practitionerFullName | capitalize}},</strong> <span *ngIf="practitioner.stampCode"> {{'doc.mai.inf.stamp'|translate}} {{practitioner.stampCode}}</span><br>
            <!--spaudo Nr.-->
            <span *ngFor="let o of practitioner.departmentOrgList">{{o.name}},&nbsp;</span>{{qualificationName}}
            <br *ngIf="practitioner.phones || practitioner.emails">
            <span *ngIf="practitioner.phones.length >= 1">
                {{'Phone'|translate}}:
                <span *ngFor="let p of practitioner.phones; let last = last;">
                    {{p}}<span *ngIf="!last || practitioner.phones">, </span>
                    <!--all phones-->
                </span>
            </span>
            <span *ngIf="practitioner.emails.length >= 1">
                {{'email'|translate}}:
                <span *ngFor="let e of practitioner.emails; let last = last;">
                    <a href="mailto:{{e}}">{{e}}</a><span *ngIf="!last">, </span>
                    <!--all mails-->
                </span>
            </span>
            <!--if user have two or more valid professions and can choose valid professions show button else hide-->
            <br class="modeModification && practitioner.validQualificationList.length>1">
            <a data-bs-toggle="modal" data-bs-target="#qualificationModal" class="nb mouse-pointer"
               *ngIf="modeModification && practitioner.validQualificationList.length > 1"
               edit-profession="practitioner.validQualificationList"
               edit-prof-selected="practitioner.practitionerQualification"
               tabindex="0"
            ><!--Keisti profesinę kvalifikaciją-->{{'ChangeProfession' | translate}}</a> <!--open modal-->
        </p>
    </div>
</div>
<div class="form-group" *ngIf="organization">
    <strong class="col-md-4 col-lg-3 col-form-label">{{'doc.mai.inf.inst'|translate}}</strong>
    <!--Įstaiga-->
    <div class="col-md-8 col-lg-9">
        <p class="form-control-plaintext">
            <strong>{{organization.name}}</strong> <br>{{'jarCode'|translate}}
            <!--JAR kodas-->{{organization.jarCode}}<span *ngIf="organization.sveidraId">, {{'sveidraNr'|translate}}<!--SVEIDRA Nr.--> {{organization.sveidraId}}</span><br>
            <span *ngIf="organization.activityAddress && organization.activityAddress.text">{{organization.activityAddress.text}}<br></span>
            <span *ngIf="organization.phone || organization.mobilePhone"><span *ngIf="organization.phone">{{'Phone'|translate}}: {{organization.phone}}</span><span *ngIf="organization.phone && organization.mobilePhone">,</span>
                <span *ngIf="organization.mobilePhone">{{'mobile'|translate}}: {{organization.mobilePhone}}</span>
                <br>
            </span>
            <span *ngIf="organization.email">{{'Email'|translate}}: <a href="{{organization.email}}">{{organization.email}}</a></span>
        </p>
    </div>
</div>

<div class="form-group" *ngIf="practitioner && organization && practitioner.authorDepartment">
    <strong class="col-md-4 col-lg-3 col-form-label">{{'doc.mai.inf.depa'|translate}}</strong>

    <div class="col-md-8 col-lg-9">
      <p class="form-control-plaintext">
        <strong>{{practitioner.authorDepartment.name}}</strong> <br>
      <span *ngIf="practitioner.authorDepartment.jarCode!==organization.jarCode">{{'jarCode'|translate}} {{practitioner.authorDepartment.jarCode}}<span *ngIf="practitioner.authorDepartment.sveidraId">, </span></span><span *ngIf="practitioner.authorDepartment.sveidraId"> {{'sveidraNr'|translate}} {{practitioner.authorDepartment.sveidraId}}</span><br>
        <span *ngIf="practitioner.authorDepartment.activityAddress && practitioner.authorDepartment.activityAddress.text">{{practitioner.authorDepartment.activityAddress.text}}<br></span>
        <span *ngIf="practitioner.authorDepartment.phone || practitioner.authorDepartment.mobilePhone"><span *ngIf="practitioner.authorDepartment.phone">{{'Phone'|translate}}: {{practitioner.authorDepartment.phone}}</span><span
            *ngIf="practitioner.authorDepartment.phone && practitioner.authorDepartment.mobilePhone">,</span><span
            *ngIf="practitioner.authorDepartment.mobilePhone">{{'mobile'|translate}}: {{practitioner.authorDepartment.mobilePhone}}</span><br></span>
        <span *ngIf="practitioner.authorDepartment.email">{{'Email'|translate}}: <a href="{{practitioner.authorDepartment.email}}">{{practitioner.authorDepartment.email}}</a></span>
      </p>
      <p class="form-control-plaintext" *ngIf="!practitioner.authorDepartment">
        {{'doc.mai.inf.depa.unk'|translate}}
      </p>
    </div>
  </div>

  <div class="form-group" *ngIf="aagaSgas">
    <strong class="col-md-4 col-lg-3 col-form-label" ng-show="getDocumentType()=='e025'">{{'doc.mai.inf.aaga'|translate}}</strong>
    <strong class="col-md-4 col-lg-3 col-form-label" ng-show="getDocumentType()=='e003'">{{'doc.mai.inf.sgas'|translate}}</strong>
    <!--AAGA/SGAS-->
    <div class="col-md-8 col-lg-9">
      <p class="form-control-plaintext" *ngIf="aagaSgas">
          <strong>{{aagaSgas}}</strong>
      </p>
    </div>
  </div>
</nhr-record-widget>

<!-- Modal -->
<div class="modal fade" id="qualificationModal" tabindex="-1" aria-labelledby="qualificationModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="qualificationModalLabel" translate>ChangeProfession</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="pid" xmlns="http://www.w3.org/1999/html">ID <strong>MDL.EDIT_PROFESSION</strong></div>
            <div class="modal-body">
                <div class="alert alert-info" *ngIf="hideMsgAboutQualification" translate>info.default.qualification</div>
                <form  autocomplete="off">
                <!--show all available professions-->
                <ol class="slats hover">
                    <li *ngFor="let q of qualificationList" class="p-0">
                        <div class="form-check mb-0">
                            <label class="form-check-label w-100 py-2 px-3 d-flex align-items-center" for="id{{q.licenseCode}}">
                                <input class="form-check-input" type="radio" id="id{{q.licenseCode}}" [(ngModel)]="chosenProfession" value="{{q.licenseCode}}" title="{{q.name}}" name="qualification">
                                <div class="ms-3">
                                    <span *ngIf="q.name">{{q.name}},</span>
                                    <span class="nb">{{'licenseNr'|translate}} {{q.licenseCode}}</span>
                                    <div class="mb-0" *ngIf="q.licenseIssued ">
                                        {{q.licenseIssued | date:'yyyy-MM-dd'}} &mdash; <span *ngIf="q.licenseExpired">{{q.licenseExpired | date:'yyyy-MM-dd'}}</span><span *ngIf="!q.licenseExpired">{{'now'|translate}}</span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </li>
                    </ol>
                </form>
            </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'cancel'|translate}}</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="submitProfession()">{{'update'|translate}}</button>
        </div>
      </div>
    </div>
  </div>