<div>
    <h1>Naujas apsilankymas</h1>
    <div *ngIf="aspnVisitF">
        <form [formGroup]="aspnVisitF" (submit)="openConfirmModal()">
            <!-- <app-common-patient-widget [_modeModification]="false"></app-common-patient-widget> -->
            <nhr-record-widget [closable]="true" [closed]="false" [title]="'Bendroji informacija' | translate"
                id="commonInfo">
                <div class="row req">
                    <div class="col-sm-12 col-md-2">
                        <app-date-picker inputId="{{'visitDate'}}" [fControl]="aspnVisitF.get('visitDate')"
                            class="d-flex flex-column h-100" [minDate]="visitDateFromMinDate"
                            (datePickerChange)="onVisitDateChange($event)"
                            [showErrors]="true"
                            labelText="{{'Apsilankymo data' | translate}}" />
                    </div>
                    <div class="col-sm-12 col-md-2">
                        <app-time-picker id="timeFrom" [fControl]="aspnVisitF.get('visitTimeFrom')"
                            labelText="Laikas nuo" class="d-flex flex-column h-100"></app-time-picker>
                    </div>
                    <div class="col-sm-12 col-md-2">
                        <app-time-picker class="d-flex flex-column h-100" id="timeTo"
                            [fControl]="aspnVisitF.get('visitTimeTo')" labelText="Laikas nuo"></app-time-picker>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="d-flex flex-column h-100">
                            <label class="form-label flex-grow-1" for="visitingSpecialist">{{
                                'Lankantis specialistas' | translate }}
                            </label>

                            <input id="visitingSpecialist" formControlName="visitingSpecialistDisplay"
                                typeaheadOptionField="fullDisplay" [typeahead]="suggestions$" [typeaheadAsync]="true"
                                [typeaheadMinLength]="0" [typeaheadItemTemplate]="customItemTemplate2"
                                (typeaheadNoResults)="typeaheadNoResults($event)"
                                (typeaheadOnSelect)="typeaheadOnSelectP($event)"
                                (typeaheadOnBlur)="onBlurtypeahead($event)" placeholder="Įveskite specialistą"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': !aspnVisitF?.get('visitingSpecialist')?.valid }" />
                            <ng-template #customItemTemplate2 let-model="item">
                                <span>{{model.familyName}} {{model.givenName}} ({{model.qualificationList &&
                                    model.qualificationList[0].name }}) </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 align-self-end">
                        <button type="button" class="btn btn-info w-100 text-nowrap mt-3 mt-lg-0"
                            (click)="openVizitsCalendarModal(modalTemplate)"
                            [disabled]="!visitingPractitionierSelected  ">{{'Specialisto užimtumas' | translate}}
                        </button>
                        <ng-template #modalTemplate>
                            <div class="modal-header">
                                <div>
                                    <h1 class="modal-title fs-5" id="specTimetableModalLabel">{{'Specialisto
                                        kalendorius' | translate}}</h1>
                                    <h1>{{aspnVisitF.get('visitingSpecialistDisplay').value}} </h1>

                                </div>

                                <button type="button" class="close btn-close pull-right" aria-label="Close"
                                    (click)="calendarModalRef?.hide()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="modal-body">
                                    <full-calendar *ngIf="calendarOptions" [options]="calendarOptions">
                                        <ng-template #eventContent let-arg>
                                            <div class="fc-custom-event {{arg.event._def.extendedProps.status ?? ''}}">
                                                <!-- TODO: Fix tooltip, should work on long names and small event blocks -->
                                                <div class="event-content"
                                                    tooltip="{{arg.event._def.extendedProps.patientFullName}}">
                                                    <strong>Pacientas</strong>
                                                    <div>{{arg.event._def.extendedProps.patientFullName}}</div>
                                                </div>
                                                <strong class="status">
                                                    <span
                                                        class="badge rounded-pill {{badgeByStatus[arg.event._def.extendedProps.status]}}">
                                                        {{labelByStatus[arg.event._def.extendedProps.status]}}
                                                    </span>
                                                </strong>
                                            </div>
                                        </ng-template>

                                    </full-calendar>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-input id="specialistComment" type="text" [fControl]="aspnVisitF.get('comment')"
                        labelText="Specialisto komentaras"/>
                    </div>
                </div>
            </nhr-record-widget>
            <nhr-record-widget *ngIf="isVisistWithPeriod" [closable]="true" [closed]="false"
                [title]="'Apsilankymo periodiškumas' | translate" id="visitFrequency">
                <div class="row">
                    <fieldset class="col-sm-12 col-md-2 col-xxl-2 d-flex flex-column">
                        <legend>Periodiškumas</legend>
                        <div>
                            <app-select id="periodicity" [fControl]="aspnVisitF.get('periodicVisit.periodicity')"
                                [values]="[{value:'1', text:'Kasdien'},{value:'2', text:'Kas antra dieną'},{value:'3', text:'Per savaitę'},{value:'4', text:'Per mėnesį'}]"
                                (onChange)="onPeriodicityChange($event)" />
                        </div>
                    </fieldset>

                    <fieldset *ngIf="aspnVisitF.get('periodicVisit.periodicity').value === '3'"
                        class="col-sm-12 col-md-5 col-xxl-5 d-flex flex-column week-day-selector">
                        <legend>Savaitės dienos</legend>
                        <div>
                            <input type="checkbox" class="btn-check" id="pr" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.mon')" />
                            <label class="btn btn-outline-primary me-1" for="pr">Pr</label>
                            <input type="checkbox" class="btn-check" id="an" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.tue')" />
                            <label class="btn btn-outline-primary me-1" for="an">An</label>
                            <input type="checkbox" class="btn-check" id="tr" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.wed')" />
                            <label class="btn btn-outline-primary me-1" for="tr">Tr</label>
                            <input type="checkbox" class="btn-check" id="kt" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.thu')" />
                            <label class="btn btn-outline-primary me-1" for="kt">Kt</label>
                            <input type="checkbox" class="btn-check" id="pn" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.fri')" />
                            <label class="btn btn-outline-primary me-1" for="pn">Pn</label>
                            <input type="checkbox" class="btn-check" id="š" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.sat')" />
                            <label class="btn btn-outline-primary me-1" for="š">Š</label>
                            <input type="checkbox" class="btn-check" id="s" autocomplete="off"
                                [formControl]="aspnVisitF.get('periodicVisit.weekDays.sun')" />
                            <label class="btn btn-outline-primary" for="s">S</label>
                        </div>

                    </fieldset>

                    <fieldset *ngIf="aspnVisitF.get('periodicVisit.periodicity').value === '4'"
                        class="col-sm-12 col-md-5 col-xxl-5 d-flex flex-column">
                        <legend>Dienos</legend>
                        <div>
                            <div class="row">
                                <div class="col mb-3">
                                    <strong>Apsilankymai bus suplanuoti pasirinktomis dienomis</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-check">
                                        <input type="radio" id="dienaRadio" class="form-check-input pe-none"
                                            value="diena" name="perMonth"
                                            [checked]="aspnVisitF.get('periodicVisit.monthDay').value"
                                            (datePickerChange)="onMonthDayChange($event)" aria-disabled="true"
                                            tabindex="-1" />
                                        <label for="dienaRadio" class="form-check-label">{{ "Diena" | translate
                                            }}</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <app-select id="{{'periodicVisit.monthDay'}}"
                                        [fControl]="aspnVisitF.get('periodicVisit.monthDay')" [values]="[
                                            {value:1, text:'1'},{value:2, text:'2'},{value:3, text:'3'},
                                            {value:4, text:'4'},{value:5, text:'5'},{value:6, text:'6'},
                                            {value:7, text:'7'},{value:8, text:'8'},{value:9, text:'9'},
                                            {value:10, text:'10'},{value:11, text:'11'},{value:12, text:'12'},
                                            {value:13, text:'13'},{value:14, text:'14'},{value:15, text:'15'},
                                            {value:16, text:'16'},{value:17, text:'17'},{value:18, text:'18'},
                                            {value:19, text:'19'},{value:20, text:'20'},{value:21, text:'21'},
                                            {value:22, text:'22'},{value:23, text:'23'},{value:24, text:'24'},
                                            {value:25, text:'25'},{value:26, text:'26'},{value:27, text:'27'},
                                            {value:28, text:'28'},{value:29, text:'29'},{value:30, text:'30'},
                                            {value:31, text:'31'}
                                        ]" (onChange)="onMonthDayChange($event)" />

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-check">
                                        <input type="radio" id="kiekvienaRadio" class="form-check-input pe-none"
                                            value="diena" name="perMonth"
                                            [checked]="aspnVisitF.get('periodicVisit.every').value || aspnVisitF.get('periodicVisit.everyWeekDay').value"
                                            aria-disabled="true" tabindex="-1" />
                                        <label for="kiekvienaRadio" class="form-check-label">{{ "Kiekvienas" |
                                            translate }}</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <app-select id="{{'periodicVisit.every'}}"
                                        [fControl]="aspnVisitF.get('periodicVisit.every')" [values]="[
                                            {value:1, text:'Pirmas'},
                                            {value:2, text:'Antras'},
                                            {value:3, text:'Trečias'},
                                            {value:4, text:'Ketvirtas'},
                                            {value:5, text:'Paskutinis'}
                                        ]" (onChange)="onEveryChange($event)" />
                                </div>
                                <div class="col-2">
                                    <span> menesio </span>
                                </div>
                                <div class="col">
                                    <app-select id="{{'periodicVisit.everyWeekDay'}}"
                                        [fControl]="aspnVisitF.get('periodicVisit.everyWeekDay')" [values]=""
                                        (onChange)="onEveryChange($event)"
                                        [values]="[{value:'mon', text:'Pirmadienis'}, {value:'tue', text:'Antradienis'},{value:'wed', text:'Trečiadienis'}, {value:'thu', text:'Ketvirtadienis'},{value:'fri', text:'Penktadienis'}, {value:'sat', text:'Šeštadienis'},{value:'sun', text:'Sekmadienis'} ]" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset *ngIf="aspnVisitF.get('periodicVisit.periodicity').value"
                        class="col-sm-12 col-md-5 col-xxl-5 d-flex flex-column">
                        <legend>Terminas</legend>
                        <div>
                            <div class="row">
                                <div class="col mb-3">
                                    <strong>Apsilankymai baigiasi</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-check">
                                        <input type="radio" id="end" class="form-check-input pe-none" value="diena"
                                            name="end" [checked]="aspnVisitF.get('periodicVisit.deadlineDate').value"
                                            aria-disabled="true" tabindex="-1" />
                                        <label for="endDate" class="form-check-label">{{ "Baigiasi po datos" | translate
                                            }}</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <app-date-picker inputId="endDate"
                                        [fControl]="aspnVisitF.get('periodicVisit.deadlineDate')"
                                        [minDate]="visitDateFromMinDateP" [maxDate]="visitDateToMaxDateP" labelText=""
                                        (datePickerChange)="onDeadlineDateChange($event)" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-check">
                                        <input type="radio" id="endVisitCount" class="form-check-input pe-none"
                                            value="diena" name="end"
                                            [checked]="aspnVisitF.get('periodicVisit.deadlineVisitCount').value"
                                            aria-disabled="true" tabindex="-1" />
                                        <label for="endVisitCount" class="form-check-label">{{ "Baigiasi po apsilankymų"
                                            | translate }}</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <app-input id="visitTimeFrom" type="number"
                                        [fControl]="aspnVisitF.get('periodicVisit.deadlineVisitCount')" labelText=""
                                        (change)="onDeadlineVisitCountChange($event)" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </nhr-record-widget>




            <div class="widget-grid">
                <nhr-record-widget [closable]="true" [closed]="false" [title]="'Procedūros pagal Paskyrimą' | translate"
                    id="procedureByOrder">
                    <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-main-grid"
                        [columnDefs]="procedureByOrderColDefs" [defaultColDef]="defaultColDef"
                        [rowData]="visitOrderProceduresGridData" (gridReady)="onGridReady($event)"
                        (firstDataRendered)="onFirstDataRendered($event)" [context]="getContext()"
                        [getRowNodeId]="getRowNodeId" (rowDataChanged)="refreshFormControls()"
                        [domLayout]="'autoHeight'" />
                </nhr-record-widget>
            </div>

            <div class="widget-grid">
                <nhr-record-widget [closable]="true" [closed]="false" [title]="'Papildomos procedūros' | translate"
                    id="visitProcedure">
                    <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-main-grid" #visitProcedureGrid
                        [columnDefs]="visitProcedureColDefs" [defaultColDef]="defaultColDef"
                        [rowData]="visitProceduresGridData" (gridReady)="onVisitProcedureGridReady($event)"
                        (firstDataRendered)="onFirstDataRendered($event)" [context]="getVisitProcedureContext()"
                         (rowDataChanged)="refreshVisitProceduresFormControls()" (modelUpdated)="refreshVisitProceduresFormControls()"
                        [domLayout]="'autoHeight'" overlayNoRowsTemplate="{{'<span></span>'}}"/>
                        <button (click)="openVisitProcedureeditor(visitProcedureEditorModal)" class="btn btn-link" type="button"> + Pridėti</button>

                </nhr-record-widget>
            </div>




            <div class="block mt-3">
                <div class="buttons">
                    <!--            Will be changed with DocumentModificationActions component-->
                    <button *ngIf="canSubmitE025()" class="btn btn-secondary m-1" type="button"
                        (click)="openConfirmModal()">{{'Pateikti ir
                        formuoti E025'|translate}}</button>

                    <button *ngIf="!canSubmitE025()" class="btn btn-secondary m-1" type="button"
                        (click)="openConfirmModal()">{{'doc.provide'|translate}}</button>
                    <button class="btn btn-secondary m-1" type="submit">
                        {{'doc.save'|translate}} </button>
                    <button class="btn btn-danger" type="button" (click)="logMe()">{{'doc.clear'|translate}}
                    </button>
                    {{visitCount}}
                </div>
            </div>
        </form>
    </div>
    <ng-template #confirmationModal>
        <div class="modal-body">
            <div class="row">
                <div class="col">
                    <p *ngIf="isVisistWithPeriod" class="fw-bold">Planuojamas apsilankymų skaičius:
                        {{this.visitsDates.length}}</p>
                    <p *ngIf="isVisistWithPeriod" class="fw-bold">Periodas: {{this.visitsDates[0] &&
                        this.visitsDates[0].toISOString().substring(0,10)}} - {{this.visitsDates[0] &&
                        this.visitsDates[this.visitsDates.length -1].toISOString().substring(0,10)}}</p>
                    <p> </p>
                    <p class="fw-bold">Patvirtinus, duomenys bus pateikti ASPN ir IPR posistemėms</p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{'cancel'|translate}}</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="onSave()">{{'save'|translate}}</button>
        </div>
    </ng-template>

    <ng-template #visitProcedureEditorModal>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <app-select id="visitProcedureGroup" labelText="{{'Procedūrų grupė' | translate}}"
                        [fControl]="visitProcedureFG.get('procedureGroup')" [values]="procedureGoupOpts"
                        (onChange)="onVisitProcedureGroupChange($event)" />

                </div>
                <div class="col-sm-12 col-md-6">
                    <app-multiselect-dropdown placeholder="Pasirinkite" [dropdownDataList]="placeInBodyProcedureOpts" idField="id"
                        textField="name" elementId="placeInBodyProcedure" itemsShowLimit="{{100}}"
                        [fControl]="visitProcedureFG.get('bodySite')" labelText="{{'Procedūros vieta' | translate}}" />

                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <app-multiselect-dropdown placeholder="Pasirinkite" [dropdownDataList]="procedureOpts" idField="code"
                        textField="name" elementId="procedures" itemsShowLimit="{{100}}"
                        [fControl]="visitProcedureFG.get('procedures')" labelText="{{'Procedūros' | translate}}" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <app-multiselect-dropdown placeholder="Pasirinkite" [dropdownDataList]="medicinesOpts" idField="id"
                        textField="name" elementId="medicines" itemsShowLimit="{{100}}"
                        [fControl]="visitProcedureFG.get('relatedMedication')" labelText="{{'Priemonės/Medikamentai' | translate}}"
                        (onFilterChange)="onFilterChange($event)" />

                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <app-input id="recommendations" type="text" [fControl]="visitProcedureFG.get('recommendations')"
                        labelText="Rekomendacijos ir pastabos" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{'cancel'|translate}}</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="addVisitProcedureRow()">{{'save'|translate}}</button>
        </div>
    </ng-template>
</div>