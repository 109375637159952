ng1App.controller('document.E025ViewCtrl',
    ['$scope', '$rootScope', '$routeParams', '$filter', '$location', 'ErxPaths', 'portalType',
     function ($scope, $rootScope, $routeParams, $filter, $location, ErxPaths, portalType) {
    $scope.documentId = $routeParams.documentId;
    $scope.patientId = $routeParams.patientId;
    $scope.portalType = portalType;

    $scope.currentDiagnoses = $scope.form.partDiagnosis;
    if($scope.form.partAllergies){
        $scope.currentAllergies = $scope.form.partAllergies.allergies;
    }
    //$scope.currentAllergies = $scope.form.partAllergies.allergies;
         $scope.newRisks = $scope.form.partRiskFactors;

    $scope.$watch('form.partDiagnosis', function(newValue, oldValue) {
       if (newValue !== oldValue) {
           $scope.currentDiagnoses = newValue;
       }
    }, true);

   $scope.$watch('form.partAllergies.allergies', function(newValue, oldValue) {
       if (newValue !== oldValue) {
           $scope.currentAllergies = newValue;
       }
    }, true);

   $scope.$watch('form.partRiskFactors', function(newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.newRisks = newValue;
        }
    }, true);

    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: _.range(18)
    }

    $scope._shared.contentList[0] = {title: $filter('translate')('doc.mai.inf'), id: 'doc_mai_inf'};
    $scope.erxDocPrescOptions = {
        /* E003 or E025 composition id */
        getCompositionId: function () {
            return $routeParams.documentId;
        },
        /* Redirect to the page with erx-prescription-list directive for the same composition */
        redirectToPrescriptionList: function () {
            $location.url(ErxPaths.list());
        }
    };

    $scope.isAdvancedPharmacist = $rootScope.currentUser.roleCode == 'advanced_pharmacist';
}]);
