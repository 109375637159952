ng1App.directive('patientViewBlock', function () {
    return {
        templateUrl: '/templates/components/patient/patient-block.html',
        restrict: 'E',
        replace: false,
        scope: {
            patient:'=',
            hidePersonalCode: '=',
            hideAddress: '=',
            hideBirthDate: '=',
            hideSex: '='
        },
        link: function (scope: any, element, attrs) {
            scope.showDeclared = false;
            scope.showResidential = false;
            scope.showForeign = false;
            scope.addressNotProvided = false;
            if (scope.patient.declaredAddress && scope.patient.declaredAddress.text) {
                scope.showDeclared = true;
            } else if (scope.patient.residenceAddress && scope.patient.residenceAddress.text) {
                scope.showResidential = true;
            } else if (scope.patient.foreignAddress && (scope.patient.foreignAddress.text || scope.patient.foreignAddress.city || scope.patient.foreignAddress.country)) {
                scope.showForeign = true;
                scope.foreignAddrText = [
                    (scope.patient.foreignAddress.text ? scope.patient.foreignAddress.text : null),
                    (scope.patient.foreignAddress.city ? scope.patient.foreignAddress.city : null),
                    (scope.patient.foreignAddress.country ? scope.patient.foreignAddress.country.name : null)
                ].filter(function(e){return e}).join(', ');
            } else {
                scope.addressNotProvided = true;
            }
        }
    };
});