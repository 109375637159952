angular.module('erx.prs').directive('erxSignPrescription', function () {
  var signPrescriptionCtrl = function ($scope, $sce, $location, $window, ErxPrescriptionsApiFactory, ErxDocumentsApiFactory) {
    var url = "";
    $scope.loadingUrl = true;

    /*ErxPrescriptionsApiFactory.getSignUrl(
            {id: $scope.options.prescriptionId, confirmUrl: $scope.options.getUnisignCallbackUrl()}).$promise.then(
            function (res) {
              url = res.url;
              $scope.loadingUrl = false;
            },
            function (err) {
              url = "";
              $scope.loadingUrl = false;
            }
    );*/
    var prescriptions = [];
      prescriptions = $scope.options.docIds?.toString().split("$");

    ErxDocumentsApiFactory.multisign({ compositionIds: prescriptions, confirmUrl: $scope.options.getUnisignCallbackUrl() }).$promise.then(
      function (res) {
        $window.sessionStorage.setItem('erx_sign_transaction', res.transactionId);
        url = res.signingUrl;
        $scope.loadingUrl = false;
      },
      function (err) {
        url = "";
        $scope.loadingUrl = false;
      }
    );

    $scope.getUrl = function () {
      if (url.length > 0)
        return $sce.trustAsResourceUrl(url);
    };

    $scope.isFromList = function () {
      return $scope.options.prescriptionId == 0;
    }

    $scope.redirectToPrescriptionList = function () {
      $location.url($scope.options.redirectToPrescriptionList());
    }
  };
  signPrescriptionCtrl.$inject = ["$scope", "$sce", "$location", "$window", "ErxPrescriptionsApiFactory", "ErxDocumentsApiFactory"];
  return {
    restrict: 'E',
    scope: { options: '=' },
    templateUrl: 'modules/erx/prs/prescription_form/signing/sign_prescription.html',
    controller: signPrescriptionCtrl
  }
});

