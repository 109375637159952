import {Injectable} from "@angular/core";
import {FilteredDataModel} from "../models/filtered-data.model";
import {FilterModel} from "../models/filter.model";

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    public static setFilterCount(filters: any, filteredData: FilteredDataModel) {
        filters.forEach(filter => {
            if (filteredData[filter.query.filter]) {
                filter.count = filteredData[filter.query.filter];
            }
        });
    }

    public static createFilters(filterEnum: any) {
        let filters: FilterModel[] = [];

        Object.entries(filterEnum).map(([key, value]) => {
            filters.push({title: value, query: {filter: key.toLowerCase()}, count: undefined} as FilterModel);
        });

        return filters;
    }
}