import { Component, DestroyRef } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import { listenAndChangeStatusOfControl } from '../../../../e096-utils/utils';

@Component({
    selector: 'app-e096-n-general-data',
    templateUrl: './e096-n-general-data.component.html',
})
export class E096NGeneralDataComponent {
    formGroup: FormGroup = this.fb.group({
        numberOfFruits: this.fb.control(null, [Validators.required, Validators.min(0)]),
        placeOfBirth: this.fb.control(null, [Validators.required]),
        elsewhere: this.fb.control({value: null, disabled: true})
    });
    complaintsOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Stacionare'
        },
        {
            id: '1',
            code: '1',
            name: 'GMP'
        },
        {
            id: '2',
            code: '2',
            name: 'Namuose'
        },
        {
            id: '3',
            code: '3',
            name: 'Kitur'
        }
    ];

    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;

    get fruitLength() {
        return this.formGroup.get('numberOfFruits')?.value;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
    ) {
        this.changeStatusOfControl({
            controlNameForListen: 'placeOfBirth',
            controlNameForUpdate: 'elsewhere',
            enableValue: 'Kitur',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    getControlValue(control: string): any {
        return this.formGroup.get(control).value;
    }
}
