import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { EntityModel } from "src/app/shared/models/entity.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { FilteredDataModel } from "../../../shared/models/filtered-data.model";
import { HealthAssessmentsRecord } from "../model/health-assessments";
import { NursingServiceOrderDto } from "../model/nursingServiceOrder";

@Injectable({
    providedIn: "root",
})
export class DpAspnContextService extends ConfigService {
    path = this.apiUrl + "/aspn";
    questionairePath = this.path + "/questionaire";
    summaryPath = this.path + "/summary";
    nursingServiceOrder = this.path + "/nursingServiceOrder";
    nursingServiceVisit = this.path + "/nursingServiceVisit";
    nursingAndCarePlan = this.path + "/nursingAndCarePlan";

    getQuestionaireClassifiers() {
        return firstValueFrom(this.http.get<QuestionaireClassifiers>(this.questionairePath + "/classifiers"));
    }

    getQuestionaireById(id: string) {
        return firstValueFrom(this.http.get<QuestionaireDto>(this.questionairePath + "/questtionaire/" + id));
    }

    createQuestionaire(questionaire: any) {
        return firstValueFrom(this.http.post<any>(this.questionairePath + "/questtionaire", questionaire));
    }

    updateQuestionaire(id: string, questionaire: QuestionaireDto) {
        return firstValueFrom(this.http.put<any>(this.questionairePath + "/questtionaire/" + id, questionaire));
    }

    createOrder(order: NursingServiceOrderDto) {
        return firstValueFrom(this.http.post<any>(this.nursingServiceOrder + "/", order));
    }

    updateOrder(id: string, order: QuestionaireDto) {
        return firstValueFrom(this.http.put<any>(this.nursingServiceOrder + "/" + id, order));
    }

    getNursingServiceOrderClassifiers() {
        return firstValueFrom(this.http.get<NursingServiceOrderClassifiers>(this.nursingServiceOrder + "/classifiers"));
    }

    getNursingServiceVisitClassifiers() {
        return firstValueFrom(this.http.get<NursingServiceVisitClassifiers>(this.nursingServiceVisit + "/classifiers"));
    }

    getNursingServiceOrderPractTimetable(practitionierId: string) {
        return firstValueFrom(
            this.http.get<AspnPractitionierTimetableEvent[]>(
                this.nursingServiceOrder + `/practitionierTimetable/${practitionierId}`
            )
        );
    }

    getNursingServicePractitioniersByOrganizationId(organizationId: string) {
        return firstValueFrom(this.http.get<any>(this.nursingServiceOrder + `/practitioniers/${organizationId}`));
    }

    getNursingServiceOrderById(id: string) {
        return firstValueFrom(this.http.get<NursingServiceOrderDto>(this.nursingServiceOrder + "/" + id));
    }

    getNursingServiceOrderF027Order(f027OrderId: string) {
        return firstValueFrom(
            this.http.get<NursingServiceF027Order>(this.nursingServiceOrder + "/orderF027/" + f027OrderId)
        );
    }

    // getAspnF027ListByOrganizationId(id: string) {
    //     return this.http.get<any>(this.path + "/aspn-f027-list/" + id);
    // }

    getAspnF027ListByOrganizationId(id: string, query?: any): Observable<any[] | FilteredDataModel> {
        let params = new HttpParams();
        if (query) {
            if (query.quick_search) {
                params = params.append("ftq", query.quick_search);
                params = params.append("page", String(1)); // 1 = page
                return this.http.get<FilteredDataModel>(this.path + "/aspn-f027-list/" + id, { params });
            } else if (
                query.dateFrom ||
                query.dateTo ||
                query.organization ||
                query.specialist ||
                query.patient ||
                query.aspnType
            ) {
                params = params.append("dateFrom", Date.parse(query.dateFrom) || "");
                params = params.append("dateTo", Date.parse(query.dateTo) || "");
                params = params.append("organization", query.organization || "");
                params = params.append("specialist", query.specialist || "");
                params = params.append("patient", query.patient || "");
                params = params.append("aspnType", query.aspnType || "");
                params = params.append("page", String(1)); // 1 = page
                return this.http.get<FilteredDataModel>(this.path + "/aspn-f027-list/" + id, { params });
            }
        }
        return this.http.get<any>(this.path + "/aspn-f027-list/" + id);
    }

    // getNursingServiceOrderListByOrganizationId(id: string) {
    //     return this.http.get<any>(this.path + "/nursing-service-order-list/" + id);
    // }

    getNursingServiceOrderListByOrganizationId(id: string, query?: any): Observable<any[] | FilteredDataModel> {
        let params = new HttpParams();
        if (query) {
            if (query.quick_search) {
                params = params.append("ftq", query.quick_search);
                params = params.append("page", String(1)); // 1 = page
                return this.http.get<FilteredDataModel>(this.path + "/nursing-service-order-list/" + id, { params });
            } else if (
                query.dateFrom ||
                query.dateTo ||
                query.patient ||
                query.status ||
                query.orderPractitioner ||
                query.appointmentPractitioner ||
                query.myAppointments ||
                query.urgent
            ) {
                params = params.append("dateFrom", Date.parse(query.dateFrom) || "");
                params = params.append("dateTo", Date.parse(query.dateTo) || "");
                params = params.append("patient", query.patient || "");
                params = params.append("status", query.status || null);
                params = params.append("orderPractitioner", query.orderPractitioner || "");
                params = params.append("appointmentPractitioner", query.appointmentPractitioner || "");
                params = params.append("myAppointments", query.myAppointments || false);
                params = params.append("urgent", query.urgent || false);
                params = params.append("page", String(1)); // 1 = page
                return this.http.get<FilteredDataModel>(this.path + "/nursing-service-order-list/" + id, { params });
            }
        }
        return this.http.get<any>(this.path + "/nursing-service-order-list/" + id);
    }

    getNursingNeedsHistoryByPatientId(id: string) {
        return this.http.get<any>(this.path + "/nursing-needs-history/" + id);
    }

    getHealthAssesmentHistoryByPatientId(id: string) {
        return this.http.get<any>(this.path + "/health-assesment-history/" + id);
    }

    // getNursingAndCarePlanHistoryByPatientId(id: string) {
    //     return this.http.get<any>(this.path + "/nursing-and-care-plan-history/" + id);
    // }

    getNursingAndCarePlanHistoryByPatientId(id: string, query?: any): Observable<any[] | FilteredDataModel> {
        let params = new HttpParams();
        if (query) {
            if (query.quick_search) {
                params = params.append("ftq", query.quick_search);
                params = params.append("page", String(1)); // 1 = page
                return this.http.get<FilteredDataModel>(this.path + "/nursing-and-care-plan-history/" + id, { params });
            } else if (query.dateFrom || query.dateTo || query.status || query.practitioner || query.qualification) {
                params = params.append("dateFrom", Date.parse(query.dateFrom) || "");
                params = params.append("dateTo", Date.parse(query.dateTo) || "");
                params = params.append("status", query.status || "");
                params = params.append("practitioner", query.practitioner || "");
                params = params.append("qualification", query.qualification || "");
                params = params.append("page", String(1)); // 1 = page
                return this.http.get<FilteredDataModel>(this.path + "/nursing-and-care-plan-history/" + id, { params });
            }
        }
        return this.http.get<any>(this.path + "/nursing-and-care-plan-history/" + id);
    }

    getActivityAndHomeEnvironmentHistoryByPatientId(id: string) {
        return this.http.get<any>(this.path + "/activity-and-home-environment-history/" + id);
    }

    getAllVisitsByPatientId(id: string) {
        return this.http.get<any>(this.path + "/all-visits/" + id);
    }

    getPatientAppointmentsByPatientId(id: string) {
        return this.http.get<any>(this.path + "/patient-appointments/" + id);
    }

    getQuestionairesByPatientId(id: string) {
        return this.http.get<any>(this.summaryPath + "/questtionaires/" + id);
    }

    getLastQuestionaireByPatientId(id: string) {
        return this.http.get<any>(this.questionairePath + "/patient/" + id);
    }

    getHealthStatusByPatientId(id: string) {
        return this.http.get<any>(this.summaryPath + "/health_status/" + id);
    }
    getScheduledVisitsByPatientId(id: string) {
        return this.http.get<any>(this.summaryPath + "/scheduled-visits/" + id);
    }
    getNursingServiceOrdersByPatientId(id: string) {
        return this.http.get<any>(this.summaryPath + "/nursing-service-orders/" + id);
    }
    getEnvironmentalAssessmentByPatientId(id: string) {
        return this.http.get<any>(this.summaryPath + "/environmental-assessment/" + id);
    }

    getPatientActivityAndHomeEnvironmentalAssessment(
        patientId: string,
        id: string
    ): Observable<FilledPatientActivityAndHomeEnvironmentalAssessment> {
        return this.http.get<FilledPatientActivityAndHomeEnvironmentalAssessment>(
            `${this.path}/assessment/${patientId}/${id}`
        );
    }

    getSummaryNursingAndCarePlanByPatientId(id: string) {
        return this.http.get<any>(this.summaryPath + "/nursing-and-care-plan/" + id);
    }

    getNursingAndCarePlanByPatientId(id: string) {
        return this.http.get<any>(this.nursingAndCarePlan + "/" + id);
    }

    async getResults() {
        return this.http.get<QuestionaireClassifiers>(this.questionairePath + "/classifiers").subscribe((val) => {
            return val;
        });
    }

    getPatientHealthAssessment(patientId: string, id: string): Observable<HealthAssessmentsRecord> {
        return this.http.get<HealthAssessmentsRecord>(`${this.path}/health-assessment/${patientId}/${id}`);
    }
}

export interface QuestionaireClassifiers {
    selfCare?: QuestionaireClassifier[];
    eatingDrinking?: QuestionaireClassifier[];
    hygiene?: QuestionaireClassifier[];
    dressingUp?: QuestionaireClassifier[];
    vision?: QuestionaireClassifier[];
    hearing?: QuestionaireClassifier[];
    bodyPosition?: QuestionaireClassifier[];
    movement?: QuestionaireClassifier[];
    fallingRisk?: QuestionaireClassifier[];
    movementCoordination?: QuestionaireClassifier[];
    balance?: QuestionaireClassifier[];
    timeOrentation?: QuestionaireClassifier[];
    spaceOrentation?: QuestionaireClassifier[];
    selfAwareness?: QuestionaireClassifier[];
    consciousness?: QuestionaireClassifier[];
    language?: QuestionaireClassifier[];
    thoughtsExpression?: QuestionaireClassifier[];
    infoPerception?: QuestionaireClassifier[];
    decisionMaking?: QuestionaireClassifier[];
    medicationUse?: QuestionaireClassifier[];
    enviromentAdaptation?: QuestionaireClassifier[];
    specCare?: QuestionaireClassifier[];
    nursingServices?: QuestionaireClassifier[];
    nurseServicesRegularity?: QuestionaireClassifier[];
    nursingAssistantServices?: QuestionaireClassifier[];
    nurseAssistanrServicesRegularity?: QuestionaireClassifier[];
    physiotherapistServices?: QuestionaireClassifier[];
    socialServices?: QuestionaireClassifier[];
    relativesPersonalCare?: QuestionaireClassifier[];
    aspnRequirement?: QuestionaireClassifier[];
}

export interface QuestionaireClassifier extends EntityModel {
    value?: string;
}

export interface QuestionaireDto {
    patient: PatientModel;
    selfCare?: QuestionaireClassifier;
    eatingDrinking?: QuestionaireClassifier;
    hygiene?: QuestionaireClassifier;
    dressingUp?: QuestionaireClassifier;
    vision?: QuestionaireClassifier;
    hearing?: QuestionaireClassifier;
    bodyPosition?: QuestionaireClassifier;
    movement?: QuestionaireClassifier;
    fallingRisk?: QuestionaireClassifier;
    movementCoordination?: QuestionaireClassifier;
    balance?: QuestionaireClassifier;
    timeOrentation?: QuestionaireClassifier;
    spaceOrentation?: QuestionaireClassifier;
    selfAwareness?: QuestionaireClassifier;
    consciousness?: QuestionaireClassifier;
    language?: QuestionaireClassifier;
    thoughtsExpression?: QuestionaireClassifier;
    infoPerception?: QuestionaireClassifier;
    decisionMaking?: QuestionaireClassifier;
    medicationUse?: QuestionaireClassifier;
    enviromentAdaptation?: QuestionaireClassifier;
    specCare?: boolean;
    nursingServices?: QuestionaireClassifier;
    nurseServicesRegularity?: QuestionaireClassifier;
    nursingAssistantServices?: QuestionaireClassifier;
    nurseAssistanrServicesRegularity?: QuestionaireClassifier;
    physiotherapistServices?: QuestionaireClassifier;
    socialServices?: QuestionaireClassifier;
    relativesPersonalCare?: QuestionaireClassifier;
    aspnRequirement?: QuestionaireClassifier;
    totalScore?: number;
    date?: string;
}

export interface NursingServiceOrderClassifier extends EntityModel {
    children?: NursingServiceOrderClassifier[];
}

export interface NursingServiceOrderClassifiers {
    procedureState?: NursingServiceOrderClassifier[];
    appointmentState?: NursingServiceOrderClassifier[];
    proceduresGroup?: NursingServiceOrderClassifier[];
    placeInBodyProcedure?: NursingServiceOrderClassifier[];
    periodicity?: NursingServiceOrderClassifier[];
}
export interface NursingServiceVisitClassifiers {
    periodicity?: NursingServiceOrderClassifier[];
    proceduresGroup?: NursingServiceOrderClassifier[];
    placeInBodyProcedure?: NursingServiceOrderClassifier[];
    procedureState?: NursingServiceOrderClassifier[];
}

export interface AspnPractitionierTimetableEvent {
    title: string;
    start: string;
    end: string;
    status: string;
    patientFullName: string;
}

export interface AspnPractitionierVisistTimetableEvent extends AspnPractitionierTimetableEvent {}

export interface NursingServicePractitionier {
    practitionierId: string;
    practitionierFullName: string;
    practitionierRole: string;
}

export interface NursingServiceF027Order {
    orderPractitionierFullName: string;
    orderOrganizationID: string;
    orderOrganizationName: string;
    aspnNeedValue: string;
    nursingServiceType: string;
    otherInfo: string;
}

export interface PatientActivityAndHomeEnvironmentalAssessment {
    visitsFrequency?: number;
    visitsDuration?: number;
    visitsPeriodicality?: EntityModel;
    firstSecondTableNote?: string;
    thirdTableNote?: string;
    forthTableNote?: string;
    conclusion: string;
    nextDate?: string;
    supportLevel: {
        eating: EntityModel;
        eatingNote?: string;
        hygiene: EntityModel;
        hygieneNote?: string;
        bathing: EntityModel;
        bathingNote?: string;
        preparing: EntityModel;
        preparingNote?: string;
        toilet: EntityModel;
        toiletNote?: string;
        changingInBed: EntityModel;
        changingInBedNote?: string;
        sittingInBed: EntityModel;
        sittingInBedNote?: string;
        standsUp: EntityModel;
        standsUpNote?: string;
        movingFromBed: EntityModel;
        movingFromBedNote?: string;
        movingToToilet: EntityModel;
        movingToToiletNote?: string;
        shower: EntityModel;
        showerNote?: string;
        walking: EntityModel;
        walkingNote?: string;
        wheelchair: EntityModel;
        wheelchairNote?: string;
        climbing: EntityModel;
        climbingNote?: string;
    };
    dysfunctionLevel: {
        senses: EntityModel;
        sensesNote?: string;
        muscle: EntityModel;
        muscleNote?: string;
        motionRange: EntityModel;
        motionRangeNote?: string;
        cognitive: EntityModel;
        cognitiveNote?: string;
        psychosocial: EntityModel;
        psychosocialNote?: string;
        sensory: EntityModel;
        sensoryNote?: string;
        balance: EntityModel;
        balanceNote?: string;
    };
    environmentComplianceLevel: {
        door: EntityModel;
        doorNote?: string;
        thresholds: EntityModel;
        thresholdsNote?: string;
        stairs: EntityModel;
        stairsNote?: string;
        porch: EntityModel;
        porchNote?: string;
        livingRoom: EntityModel;
        livingRoomNote?: string;
        kitchen: EntityModel;
        kitchenNote?: string;
        toilet: EntityModel;
        toiletNote?: string;
        bathroom: EntityModel;
        bathroomNote?: string;
        bedroom: EntityModel;
        bedroomNote?: string;
        lighting: EntityModel;
        lightingNote?: string;
    };
}

export interface FilledPatientActivityAndHomeEnvironmentalAssessment {
    data: PatientActivityAndHomeEnvironmentalAssessment;
    date: string;
    doctor: string;
}
