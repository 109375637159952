import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    addObjToFormArray,
    controlIsDisabled,
    generateSelectionValuesFromEntity,
    getControlValue,
    listenAndChangeStatusOfControl, multiCheckboxIsChecked, updateCheckboxControl
} from '../../../../e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, map, Observable, Observer, switchMap } from 'rxjs';
import { ApiV2Entities } from '../../../../../../../../shared/services/api-v2-entities';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'app-e096-cpo-general-information',
    templateUrl: './e096-cpo-general-information.component.html',
})
export class E096CpoGeneralInformationComponent implements OnInit {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    currentTime = new Date();
    formGroup: FormGroup;
    searchDiagnose: FormControl = this.fb.control(null);
    selectedDiagnose: EntityModel;
    suggestions$: Observable<EntityModel[]>;
    urgencyOfOperationOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'I neatidėliotina'
        },
        {
            id: '1',
            code: '1',
            name: 'II skubi'
        },
        {
            id: '2',
            code: '2',
            name: 'III atliktina'
        },
        {
            id: '3',
            code: '3',
            name: 'IV planinė'
        }
    ];
    caesareanSectionIndicationOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Pakartotinė operacija'
        },
        {
            id: '1',
            code: '1',
            name: 'Distocija'
        },
        {
            id: '2',
            code: '2',
            name: 'Vaisiaus būklės ypatumai'
        },
        {
            id: '3',
            code: '3',
            name: 'Vaisiaus sėdmeninė pirmeiga'
        },
        {
            id: '4',
            code: '4',
            name: 'Kitos indikacijos'
        },
        {
            id: '5',
            code: '5',
            name: 'Nėščiosios liga'
        }
    ];

    selectionValuesFromEntity = generateSelectionValuesFromEntity.bind(this) as typeof generateSelectionValuesFromEntity;
    getCntrlValue = getControlValue.bind(this) as typeof getControlValue;
    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlDisabled = controlIsDisabled.bind(this) as typeof controlIsDisabled;
    checkboxIsChecked = multiCheckboxIsChecked.bind(this) as typeof multiCheckboxIsChecked;
    updateControl = updateCheckboxControl.bind(this) as typeof updateCheckboxControl;
    private addObjectToFormArray = addObjToFormArray;

    get diagnosisControl() {
        return (this.formGroup.get('diagnoses') as FormArray).controls;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
        private readonly apiV2Entities: ApiV2Entities
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            urgencyOfOperation: this.fb.control(null),
            robsonGroup: this.fb.control(null),
            timeOfDischarge: this.fb.control(null),
            diagnoses: this.fb.array([]),
            caesareanSectionIndicationGroup: this.fb.control(null, [Validators.required]),
            caesareanSectionIndicationValue: this.fb.control(null),
            pregnancyDisease: this.fb.control(null),
            anasthesia: this.fb.control([], [Validators.required]),
            initiationOfAnesthesia: this.fb.control(null, [Validators.required]),
            startOfOperation: this.fb.control(null, [Validators.required]),
            endOfOperation: this.fb.control(null, [Validators.required]),
        });

        this.changeStatusOfControl({
            controlNameForListen: 'caesareanSectionIndicationGroup',
            controlNameForUpdate: 'pregnancyDisease',
            enableValue: 'Nėščiosios liga',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.changeStatusOfControl({
            controlNameForListen: 'caesareanSectionIndicationGroup',
            controlNameForUpdate: 'caesareanSectionIndicationValue',
            disableValue: 'Nėščiosios liga',
            addValidators: [Validators.required]
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();

        this.getDiagnoses();
    }

    getIndicationDetail(): string {
        switch (this.formGroup.get('caesareanSectionIndicationGroup').value) {
            case 'Pakartotinė operacija':
                return 'repeatOperation';
            case 'Distocija':
                return 'dystocia';
            case 'Vaisiaus būklės ypatumai':
                return 'fetusCondition';
            case 'Vaisiaus sėdmeninė pirmeiga':
                return 'fetalBreechPresentation';
            case 'Kitos indikacijos':
                return 'otherIndications';
        }
    }

    selectDiagnose(e: TypeaheadMatch) {
        this.selectedDiagnose = e.item;
    }

    addDiagnoseToList(): void {
        delete this.selectedDiagnose['fullDisplay'];
        const formGroup = this.addObjectToFormArray(this.selectedDiagnose);
        (this.formGroup.get('diagnoses') as FormArray).push(formGroup, {emitEvent: false});
        this.searchDiagnose.reset();
        this.selectedDiagnose = null;
    }

    deleteDiagnose(i: number) {
        (this.formGroup.get('diagnoses') as FormArray).removeAt(i, {emitEvent: false})
    }

    private getDiagnoses() {
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(
                    this.searchDiagnose.value
                );
            }
        ).pipe(
            distinctUntilChanged(),
            switchMap((query: string | undefined) => {
                const request = !query?.length
                    ? this.apiV2Entities.getMostUsedDiagnoses({count: 10})
                    : this.apiV2Entities.getEntitiesList('tlk-10-am', {searchCriteria: query});

                return request.pipe(map(items => items
                    .filter(p => !(this.formGroup.get('diagnoses') as FormArray).getRawValue()?.some(s => s.id === p.id))
                    .map(p => {
                        p['fullDisplay'] = `${p.code} ${p.name}`;
                        return p;
                     })
                ))
            }),
            takeUntilDestroyed(this.destroyRef)
        );

        this.searchDiagnose.valueChanges.pipe(
            debounceTime(50),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(r => {
            if (!this.selectedDiagnose || r === this.selectedDiagnose?.['fullDisplay']) return;
            this.selectedDiagnose = null;
        })
    }
}
