<div class="accordion" id="dosageSummary">
    <div class="accordion-item">
        <div class="accordion-header">
            <div class="accordion-button py-2 px-2_5" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseDosage" aria-expanded="true"
                 aria-controls="collapseDosage" [ngClass]="{'collapsed': collapsed}">
                <div class="d-flex flex-column flex-sm-row">
                    <span class="d-inline-block me-3">
                        {{ 'Dozavimas' | translate }}
                    </span>
                    <small class="d-flex align-items-center gap-3"
                           *ngIf="dosageSummary !== undefined && dosageSummary.length !== 0">
                        {{ dosageSummary }}
                    </small>
                </div>
                <button class="btn btn-link btn-sm p-0" (click)="clearDosageSummaryData()" data-bs-toggle="collapse"
                        data-bs-target>
                    {{ 'Išvalyti' | translate }}
                </button>
            </div>
        </div>
        <div id="collapseDosage" class="accordion-collapse collapse"
             data-bs-parent="#dosageSummary" [ngClass]="{'show': !collapsed}">
            <div class="accordion-body">
                <div class="row row-gap-2">
                    <div class="col-12" *ngIf="isDispense && !isPaperDispense">
                        <label class="col-form-label" for="consumptionType">
                            {{ 'Vartojimo būdas' | translate }}
                        </label>
                        <div class="form-control-plaintext">
                            {{ dosageInstructionData?.route?.displayValue }}
                        </div>
                    </div>
                    <div class="col-12 req" *ngIf="isDispense && isPaperDispense">
                        <label class="col-form-label" for="consumptionType">
                            {{ 'Vartojimo būdas' | translate }}
                        </label>
                        <input
                            id="consumptionType"
                            [formControl]="form.get('dosageInstructionData.consumptionType.displayValue')"
                            [typeahead]="consumptionSuggestions$"
                            [typeaheadAsync]="true"
                            [typeaheadMinLength]="0"
                            [typeaheadOptionsLimit]="10"
                            [typeaheadWaitMs]="200"
                            typeaheadOptionField="displayValue"
                            (typeaheadOnSelect)="selectConsumptionType($event)"
                            (change)="selectConsumptionType($event)"
                            [placeholder]="'Ieškoti' | translate"
                            class="form-control"
                        />
                    </div>
                    <div class="col-xxl-6">
                        <div class="row row-gap-2">
                            <div class="col-sm-12 col-md-6">
                                <label class="col-form-label" for="singleIntakeDose">
                                    {{ 'Vienkartinė dozė' | translate }}
                                </label>
                                <app-input id="singleIntakeDose"
                                           type="number"
                                           [min]="'0'"
                                           [numberStep]="'1'"
                                           [fControl]="form.get('dosageInstructionData.doseQuantity.value')"
                                />
                                <!--<input id="singleIntakeDose" data-min-length="0" erx-two-decimal-points step="1" min="0"
                                       class="form-control"
                                       ng-model="medicationPrescription.dosageInstructionData.doseQuantity.value"
                                       ng-change="calculateUsageFields() || calculateDosePerDay()" type="number"
                                       name="singleIntakeDose">-->
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label class="col-form-label" for="doseQuantitySelect">
                                    {{ 'Dozės vienetai' | translate }}
                                </label>
                                <input
                                    id="doseQuantitySelect"
                                    [formControl]="form.get('dosageInstructionData.doseQuantity.unit.displayValue')"
                                    [typeahead]="pharmFormSuggestions$"
                                    [typeaheadAsync]="true"
                                    [typeaheadMinLength]="0"
                                    [typeaheadOptionsLimit]="10"
                                    [typeaheadWaitMs]="200"
                                    typeaheadOptionField="displayValue"
                                    (typeaheadOnSelect)="selectDoseQuantity($event, 'doseQuantity')"
                                    (change)="selectDoseQuantity($event, 'doseQuantity')"
                                    [placeholder]="'Ieškoti' | translate"
                                    class="form-control"
                                />
                                <!--  <ng-template #customItemTemplate2 let-model="item">
                                      <span>{{model.name}}</span>
                                  </ng-template>-->
                                <!--  <ui-select id="doseQuantitySelect" name="doseQuantity" can-select-all="true"
                                             on-select="doseQuantityUnitCodeChanged()"
                                             ng-model="medicationPrescription.dosageInstructionData.doseQuantity.unit"
                                             theme="bootstrap">
                                      <ui-select-match class="ui-select-match" allow-clear="true"
                                                       ng-attr-title="{{$select.selected.displayValue}}"
                                                       placeholder="{{'Ieškoti' | translate}}">{{ $select.selected.displayValue }}
                                      </ui-select-match>
                                      <ui-select-choices class="ui-select-choices"
                                                         repeat="doseQuantity in (getDosageForm($select.showAll)) | filter: { code: '!pak.', displayValue: $select.search }">
                                                                              <span
                                                                                  ng-attr-title="{{doseQuantity.displayValue}}"
                                                                                  ng-bind-html="doseQuantity.displayValue | highlight: $select.search"></span>
                                      </ui-select-choices>
                                  </ui-select>-->
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="input-group">
                                    <input id="scheduleFrequency" type="number" class="form-control" min="1" step="1"
                                           [formControl]="form?.get('dosageInstructionData.schedule.frequency')">
                                    <!--<input type="number" class="form-control" min="1"
                                           ng-model="medicationPrescription.dosageInstructionData.schedule.frequency"
                                           ng-change="calculateUsageFields() || calculateDosePerDay()"
                                           title="{{'Dienų skaičius apskaičiuojamos automatiškai kai vykdomas žymių pasirinkimas: „Ryte“, „Per pietus“, „Vakare“. Jei rodomų dienų skaičius pataisomas rankiniu būdu, automatinis skaičiavimas pagal žymes sustabdomas' | translate}}"
                                           erx-integer>-->
                                    <span class="input-group-text fs-5"
                                          title="Dienų skaičius apskaičiuojamos automatiškai kai vykdomas žymių pasirinkimas: „Ryte“, „Per pietus“, „Vakare“. Jei rodomų dienų skaičius pataisomas rankiniu būdu, automatinis skaičiavimas pagal žymes sustabdomas">&nbsp;{{ 'kartai per' | translate }}&nbsp;</span>
                                    <!--  <app-select id="dosePerDayUnit"
                                                  [values]="frequencyMeasurements"
                                                  [showErrors]="true"
                                                  [fControl]="form?.get('dosageInstructionData.dosePerDay.unit')"/>-->

                                    <!--        ng-model="medicationPrescription.dosageInstructionData.schedule.units"
                                            ng-options="measurement.code as (measurement.displayValue) for measurement in frequencyMeasurements"
                                            ng-change="calculateUsageFields() || calculateDosePerDay()"-->
                                    <select id="scheduleUnits" class="form-control"
                                            [formControl]="form?.get('dosageInstructionData.schedule.units')"
                                            title="{{'Dienų skaičius apskaičiuojamos automatiškai kai vykdomas žymių pasirinkimas: „Ryte“, „Per pietus“, „Vakare“. Jei rodomų dienų skaičius pataisomas rankiniu būdu, automatinis skaičiavimas pagal žymes sustabdomas' | translate}}">
                                        <option value="" class="text-placeholder">{{ '' }}</option>
                                        <option *ngFor="let measurement of frequencyMeasurements"
                                                [value]="measurement.code ? measurement.code : measurement">
                                            {{ measurement.displayValue }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="input-group">
                                    <span class="input-group-text fs-5">{{ 'kas' | translate }}</span>
                                    <input type="number" class="form-control" min="0"
                                           [formControl]="form?.get('dosageInstructionData.timeQuantity')"
                                           title="{{'Valandos' | translate}}" fractionSize="0">
                                    <!-- <input type="number" class="form-control" min="0"
                                            ng-model="medicationPrescription.dosageInstructionData.timeQuantity"
                                            ng-change="changeDosageSummaryData()"
                                            title="{{'Valandos' | translate}}" fractionSize="0">-->
                                    <span class="input-group-text fs-5">{{ 'val.' | translate }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label class="col-form-label"
                                       for="treatmentDuration2">{{ 'Paros dozė' | translate }}</label>
                                <div class="pe-1">
                                    <app-input id="treatmentDuration2"
                                               type="number"
                                               class=""
                                               [min]="'1'"
                                               [numberStep]="'1'"
                                               [fControl]="form?.get('dosageInstructionData.dosePerDay.value')"
                                    />
                                    <!-- <input id="treatmentDuration2" class="form-control"
                                            ng-model="medicationPrescription.dosageInstructionData.dosePerDay.value"
                                            ng-change="changeDosageSummaryData()"
                                            ng-blur="calculateInsulinDosageCount()"
                                            erx-two-decimal-points
                                            step="1" type="number" name="treatmentDuration2">-->
                                    <div *ngIf="!isDispense">
                                        <button class="btn btn-sm btn-link p-0"
                                                ng-click="openDoseCalculationModal()">{{ 'Dozuotės skaičiuoklė' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <label class="col-form-label"
                                       for="dosePerDay">{{ 'Dozės vienetai' | translate }}</label>
                                <div class="ps-0">
                                    <input
                                        id="dosePerDay"
                                        [formControl]="form.get('dosageInstructionData.dosePerDay.unit.displayValue')"
                                        [typeahead]="pharmFormSuggestionsDaily$"
                                        [typeaheadAsync]="true"
                                        [typeaheadMinLength]="0"
                                        [typeaheadOptionsLimit]="10"
                                        [typeaheadWaitMs]="200"
                                        typeaheadOptionField="displayValue"
                                        (typeaheadOnSelect)="selectDoseQuantity($event, 'dosePerDay')"
                                        (change)="selectDoseQuantity($event, 'dosePerDay')"
                                        [placeholder]="'Ieškoti' | translate"
                                        class="form-control"
                                    />
                                    <!-- <ui-select id="dosePerDay" name="dosePerDay" can-select-all="true"
                                                on-select="dosePerDayUnitCodeChanged()"
                                                ng-model="medicationPrescription.dosageInstructionData.dosePerDay.unit"
                                                theme="bootstrap">
                                         <ui-select-match class="ui-select-match" allow-clear="true"
                                                          ng-attr-title="{{$select.selected.displayValue}}"
                                                          placeholder="Ieškoti">{{ $select.selected.displayValue }}
                                         </ui-select-match>
                                         <ui-select-choices class="ui-select-choices"
                                                            repeat="dosePerDay in (getDosageForm($select.showAll)) | filter: { code: '!pak.', displayValue: $select.search }">
                                                                     <span ng-attr-title="{{dosePerDay.displayValue}}"
                                                                           ng-bind-html="dosePerDay.displayValue | highlight: $select.search"></span>
                                         </ui-select-choices>
                                     </ui-select>-->

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6">
                        <div class="row">
                            <div class="col-auto">
                                <strong class="col-form-label">{{ 'Vartojimo dažnumas' | translate }}</strong>
                                <div class="form-check">
                                    <input id="morningTag"
                                           ng-change="dosageTimeChanged()"
                                           type="checkbox"
                                           [formControl]="form?.get('dosageInstructionData.morning')"
                                           title="{{'Ryte' | translate}}" name="morningTag" class="form-check-input"/>
                                    <label class="form-check-label" for="morningTag">
                                        {{ 'Ryte' | translate }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input id="middayTag" type="checkbox"
                                           ng-change="dosageTimeChanged()"
                                           [formControl]="form?.get('dosageInstructionData.noon')"
                                           title="{{'Per pietus' | translate}}" name="middayTag"
                                           class="form-check-input"/>
                                    <label class="form-check-label" for="middayTag">
                                        {{ 'Per pietus' | translate }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input id="eveningTag" type="checkbox"
                                           ng-change="dosageTimeChanged()"
                                           [formControl]="form?.get('dosageInstructionData.evening')"
                                           title="{{'Vakare' | translate}}" name="eveningTag" class="form-check-input"/>
                                    <label class="form-check-label" for="eveningTag">
                                        {{ 'Vakare' | translate }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input id="asNeededTag" value="continuousTreatmentTag" type="checkbox"
                                           ng-change="dosageTimeChanged()"
                                           [formControl]="form?.get('dosageInstructionData.asNeeded')"
                                           title="{{'Pagal poreikį' | translate}}" name="asNeededTag"
                                           class="form-check-input"/>
                                    <label class="form-check-label" for="asNeededTag">
                                        {{ 'Pagal poreikį' | translate }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-auto">
                                <strong class="col-form-label">{{ 'Vartojimo laikas' | translate }}</strong>
                                <div class="form-check" *ngFor="let c of intakeTimes">
                                    <input [id]="c.code" type="checkbox" [value]="c.code"
                                           [formControl]="form?.get('dosageInstructionData.' + c.code)"
                                           ng-change="changeDosageSummaryData()"
                                           class="form-check-input"/>
                                    <label class="form-check-label text-nowrap" [for]="c.code">
                                        {{ c.displayValue | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
