ng1App.controller('doctor.patient.erx.PatientHistoryViewPrsCtrl', 
    ['$scope', '$routeParams', 'ErxHistoryPaths', 'ErxIntegration', '$location',
     function ($scope, $routeParams, ErxHistoryPaths, ErxIntegration, $location) {

    $scope.erxPatientHistoryViewPrsOptions = {
        // redirect to page with erx-view-prescription-doc-ro
        redirectToViewPrsDoc: function (id) {
            $location.url(ErxHistoryPaths.viewPrsDoc(id));
        },
        // Viewed prescription id
        prescriptionId: $routeParams.id,
        //returns integration object with getters for classifier values and displayGlobalMessage function
        getIntegrationData: ErxIntegration.getIntegrationDataObject,
        redirectToViewDsp: function (id) {
            $location.url(ErxHistoryPaths.viewDsp(id));
        }
    };

}]);