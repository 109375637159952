<div class="modal-header">
    <h4 class="modal-title pull-left">{{'doc.all.title' | translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="formGroup">
    <div class="row">
        <div class="col-md-6 col-lg-auto">
            <div class="d-flex flex-column">
                <strong class="d-block">{{'doc.all.mod.rea' | translate}}:</strong>
                <span>{{ allergy.code?.name }}</span>
            </div>
        </div>
        <div class="col-md-6 col-lg">
            <div class="d-flex flex-column">
                <strong class="d-block">{{'doc.all.allgn' | translate}}: </strong>
                <span>{{ allergy.substance1?.name }}</span>
            </div>
        </div>
        <div class="col-lg">
            <div class="row">
                <div class="col">
                    <strong class="d-block">{{'doc.dia.mod.reg' | translate}}: </strong>
                    <ng-container *ngIf="allergy?.recordedDate; else noRecordedDate">
                        {{ allergy.recordedDate | date:'yyyy-MM-dd' }}
                    </ng-container>
                    <ng-template #noRecordedDate>-</ng-template>
                </div>
                <div class="col">
                    <strong class="d-block">{{'doc.dia.mod.ren' | translate}}: </strong>
                    <ng-container *ngIf="allergy?.renewalDate; else noRenewalDateDate">
                        {{ allergy.renewalDate | date:'yyyy-MM-dd' }}
                    </ng-container>
                    <ng-template #noRenewalDateDate>-</ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="row req">
        <div class="col">
            <strong class="col-form-label">
                {{'doc.dia.dia' | translate}}
            </strong>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-8 d-flex mb-3">
                <div class="input-group">
                    <input
                        [formControl]="formGroup?.get('manifestation')"
                        [typeahead]="suggestions$"
                        [typeaheadAsync]="true"
                        [typeaheadItemTemplate]="customItemTemplate2"
                        [typeaheadMinLength]="0"
                        [typeaheadOptionsLimit]="10"
                        [typeaheadWaitMs]="200"
                        typeaheadOptionField="fullDisplay"
                        (typeaheadOnSelect)="selectDiagnose($event)"

                        [placeholder]="'doc.all.fill.title' | translate"
                        class="form-control h-[38px]"
                    />
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                    <ng-template #customItemTemplate2 let-model="item">
                        <span>{{model.code}} {{model.name}}</span>
                    </ng-template>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="row mb-3">
                    <div class="col req d-flex align-items-center">
                        <app-date-picker [fControl]="formGroup.get('onsetDate')" class="d-flex" inputId="onsetDate" [placeholder]="'doc.dia.mod.dat' | translate" suffixIcon="fa fa-calendar"></app-date-picker>
                        <div class="mx-3">-</div>
                        <app-date-picker [fControl]="formGroup.get('lastOccurrence')" class="d-flex" inputId="lastOccurrence" [placeholder]="'doc.dia.mod.dat' | translate" suffixIcon="fa fa-calendar"></app-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gap-3">
            <fieldset class="col-auto req">
                <legend class="float-none field-required col-form-label pt-0">{{'doc.dia.sts' | translate}}</legend>
                <ng-container *ngFor="let clinicalStatus of statuses; let i = index">
                    <app-radio-button
                        id="clinicalStatus{{clinicalStatus.id}}"
                        radioName="clinicalStatus{{ i }}"
                        labelText='{{ clinicalStatus.name | capitalize }}'
                        [radioValue]="clinicalStatus.id"
                        [fControl]="formGroup.get('clinicalStatus')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-auto req">
                <legend class="float-none field-required col-form-label pt-0">{{'doc.dia.con' | translate}}</legend>
                <ng-container *ngFor="let verificationStatus of verificationStatuses; let i = index">
                    <app-radio-button
                        id="verificationStatus{{verificationStatus.id}}"
                        radioName="verificationStatus{{ i }}"
                        labelText='{{ verificationStatus.name | capitalize }}'
                        [radioValue]="verificationStatus.id"
                        [fControl]="formGroup.get('verificationStatus')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-auto">
                <legend class="float-none col-form-label pt-0">{{'doc.dia.sev' | translate}}</legend>
                <ng-container *ngFor="let severity of severities; let i = index">
                    <app-radio-button
                        id="severity{{severity.id}}"
                        radioName="severity{{ i }}"
                        labelText='{{ severity.name | capitalize }}'
                        [radioValue]="severity.id"
                        [fControl]="formGroup.get('severity')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-auto">
                <legend class="float-none col-form-label pt-0">{{'doc.all.crit' | translate}}</legend>
                <ng-container *ngFor="let criticality of intoleranceCriticalities; let i = index">
                    <app-radio-button
                        id="criticality{{criticality.id}}"
                        radioName="criticality{{ i }}"
                        labelText='{{ criticality.name | capitalize }}'
                        [radioValue]="criticality.id"
                        [fControl]="formGroup.get('criticality')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-auto req">
                <legend class="float-none field-required col-form-label pt-0">{{'doc.dia.dia.typ' | translate}}</legend>
                <ng-container *ngFor="let type of types; let i = index">
                    <app-radio-button
                        id="type{{type.id}}"
                        radioName="type{{ i }}"
                        labelText='{{ type.name | capitalize }}'
                        [radioValue]="type.id"
                        [fControl]="formGroup.get('type')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-auto">
                <legend class="float-none col-form-label pt-0">{{'doc.dia.cat' | translate}}</legend>
                <ng-container *ngFor="let category of categories; let i = index">
                    <app-radio-button
                        id="category{{category.id}}"
                        radioName="category{{ i }}"
                        labelText='{{ category.name | capitalize }}'
                        formClass="req"
                        [radioValue]="category.id"
                        [fControl]="formGroup.get('category')"
                    />
                </ng-container>
            </fieldset>
        </div>
        <!-- <div class="row mt-2">
            <app-input
                    class="d-flex align-items-center text-center mb-3"
                    id="descriptionTextarea"
                    type="textarea"
                    [labelText]="'doc.dia.lis.des' | translate"
                    labelClass="col-1_5 mb-0 fw-bolder me-3 mt-0"
                    [fControl]="formGroup?.get('notes')"
            />
            <app-input
                    class="d-flex align-items-center text-center mb-3"
                    id="circumstancesTextarea"
                    type="textarea"
                    [labelText]="'doc.dia.lis.cir' | translate"
                    labelClass="col-1_5 mb-0 fw-bolder me-3 mt-0 text-center"
                    [fControl]="formGroup?.get('circumstances')"
            />
        </div> -->
    </div>
    <div class="row req">
        <div class="col-12">
            <app-input
                class="d-block"
                id="descriptionTextarea"
                type="textarea"
                [labelText]="'doc.dia.lis.des' | translate"
                [fControl]="formGroup?.get('notes')"
            />
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid" (click)="close(true)"><span class="fa fa-plus"></span> {{'doc.dia.btn.con' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="close()"><span class="fa fa-times"></span> {{'doc.dia.btn.can' | translate}}</button>
</div>
