ng1App.factory("AccessRulesApiFactory",
    ['$resource', 'apiUrl', function ($resource, apiUrl) {
        var URL_BASE = apiUrl + '/accessRules';

        return $resource('', {}, {
            get: {
                method: 'GET',
                url: URL_BASE + '/permisions'
            },
            checkPermision: {
                method: 'GET',
                url: URL_BASE + '/check-permision'
            }
        });
    }]);
ng1App.factory("PatientAccessRulesApiFactory", ['$resource', 'apiUrl', ($resource, apiUrl) => {
    var URL_BASE = apiUrl + '/accessRules/patient';

    return $resource('', {}, {
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        search: {
            method: 'GET',
            url: URL_BASE + '/forList'
            },
        getById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getCount: {
            method: 'GET',
            url: URL_BASE + '/count'
        }
    });
}]);

ng1App.factory("PractitionerAccessRulesApiFactory",
    ['$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/accessRules/practitioner';

    return $resource('', {}, {
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        search: {
            method: 'GET',
            url: URL_BASE + '/forList'
            },
        getById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getCount: {
            method: 'GET',
            url: URL_BASE + '/count'
        }
    });
}]);