<modal [modalId]="finishedDocumentSelectModalId" [modalCloseId]="finishedDocumentSelectModalCloseId" [parentModalId]="parentModalId" [title]="title"
       (confirm)="continueDocument()">
    <ng-container>
        <div class="alert alert-danger alert-dismissable mb-0" *ngIf="createDialogErr?.msg">
            <button aria-hidden="true" data-bs-dismiss="alert" class="btn-close" type="button" (click)="createDialogErr.msg = null">×</button>
            {{createDialogErr.msg}}
        </div>
        <form autocomplete="off">
            <quick-search [formV2]="form" [name]="quickSearchName" [isAdvanced]="false" (filterService)="filterFinishedDocuments()"/>
        </form>
        <ol class="slats hover" *ngIf="documents">
            <!--last-item="$last && (total != 0 && total > documents.length) == false"-->
            <li *ngFor="let d of documents">
                <div class="fw-bold mb-0">
                    <label>
                        <input class="me-1" type="radio" name="documentId" [(ngModel)]="selected.id" [value]="d.id"/>
                        <span *ngFor="let c of d.conditions">
                                    {{c.diagnosisCode}} {{c.diagnosisText}}
                            <!--  <span *ngIf="!$last">,&nbsp;</span>-->
                                </span>
                    </label>
                </div>
                <div class="mb-0">
                    <app-doc-status-icon [status]="d.documentStatus"></app-doc-status-icon>
                    <a [href]="'/dp/patients/' + patient.id + '/documents/' + d.id" target="_blank">{{d.title}}</a>
                    <br/>
                    <small>
                        {{d.date | date: 'yyyy-MM-dd'}}
                        <span *ngIf="d.practitioner">
                                    <span class="vr"></span>
                                    <app-practitioner-link [practitioner]="d.practitioner" [qualification]="d.practitioner.speciality.name" [newTab]="true"></app-practitioner-link>
                                </span>
                        <span class="vr"></span> {{d.organization.name}}
                    </small>
                </div>
            </li>
            <li class="last" (click)="loadMore()" (keypress)="loadMore()" *ngIf="total != 0 && total > documents.length" tabindex="0">
                <a class="d-block text-decoration-none text-center"><!--Daugiau ambulatorinių apsilankymų-->{{'mdl.edi.e025.more'|translate}}</a>
            </li>
            <li class="last" *ngIf="total == 0">
                <!--Įrašų nerasta-->
                <div class="mb-0 text-center">{{'mdl.edi.e025.not.foun'|translate}}</div>
            </li>
        </ol>
    </ng-container>
</modal>