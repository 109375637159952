<label *ngIf="labelText" [for]="id" [class]="labelClass" class="form-label flex-grow-1 col-form-label"
    >{{ labelText | translate }}
</label>

<div>
    <select
        [id]="id"
        [class]="inputClass + (fControl.value ? ' ' : ' text-placeholder ') + 'form-select'"
        [formControl]="fControl"
        [ngClass]="{ 'is-invalid': (fControl?.invalid && fControl.touched) }"
        [disabled]="disabled"
        (change)="onChange.emit($event)"
    >
        <option *ngIf="!!placeholder" value="" class="text-placeholder">{{ placeholder }}</option>
        <option *ngFor="let val of values" [value]="val.value ? val.value : val">
            {{ val.value ? (val.text ? val.text : val.value) : val }}
        </option>
    </select>
    <app-form-control-validation *ngIf="showErrors" [fControl]="fControl" />
</div>
