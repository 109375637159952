import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "dispense-mpa-info",
    templateUrl: "./dispense-mpa-info.component.html",
})
export class DispenseMpaInfoComponent implements OnInit {
    @Input() lenses: any;

    constructor() {
    }

    ngOnInit(): void {

    }

    isGlens(): boolean {
        return this.lenses?.opticalDevice === 'glens';
    }
}