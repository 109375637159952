import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { GridApi, ICellRendererParams, IRowNode } from "ag-grid-community";

export interface ICustomHeaderParams {
    linkString: string;
    routerLinkTo: string;
}

@Component({
    selector: "ag-actions-cell",
    templateUrl: "./ag-actions-cell.component.html",
})
export class AgActionsCell implements OnInit, ICellRendererAngularComp {
    public cellValue!: string;

    public editable: boolean = false;
    public viewable: boolean = false;
    public copyable: boolean = false;
    public createable: boolean = false;
    public deleteable: boolean = false;
    public urlPathCopy: string = "";
    public urlPathView: string = "";
    public urlPathEdit: string = "";
    public urlPathCreate: string = "";
    public rowNode:IRowNode<any>;
    public deleteRow;
    public api: GridApi<any>;
    public editButtonTitle:string = "";
    public viewButtonTitle:string = "";
    public copyButtonTitle:string = "";
    public createButtonTitle:string = "";
    public deleteButtonTitle:string = "";

    constructor(private router: Router) {}

    ngOnInit(): void {}

    agInit(params: CustomCellRendererParams): void {
        this.editable = params.editable;
        this.viewable = params.viewable;
        this.copyable = params.copyable;
        this.createable = params.createable;
        this.deleteable = params.deleteable;
        this.urlPathCopy = params.urlPathCopy;
        this.urlPathView = params.urlPathView;
        this.urlPathEdit = params.urlPathEdit;
        this.urlPathCreate = params.urlPathCreate;
        this.editButtonTitle = params.editButtonTitle;
        this.viewButtonTitle = params.viewButtonTitle;
        this.copyButtonTitle = params.copyButtonTitle;
        this.createButtonTitle = params.createButtonTitle;
        this.deleteButtonTitle = params.deleteButtonTitle;
        this.viewable = params.viewable;
        this.copyable = params.copyable;
        this.createable = params.createable;
        this.deleteable = params.deleteable;
        this.rowNode = params.node;
        this.api = params.api;
    }

    refresh(params: ICellRendererParams<any, any, any>): boolean {
        this.rowNode = params.node
        return true;
    }

    buttonClickedNavigate(path: string) {
        this.router.navigate([path]);
    }

    removeRow(){
        this.api?.applyTransaction({ remove: [this.rowNode.data] });
    }

    getValueToDisplay(params: ICellRendererParams) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }
}

export interface CustomCellRendererParams extends ICellRendererParams<any, any, any> {
    editable: boolean;
    viewable: boolean;
    copyable: boolean;
    createable: boolean;
    deleteable: boolean;
    urlPathCopy: string;
    urlPathView: string;
    urlPathEdit: string;
    urlPathCreate: string;
    rowId:number
    editButtonTitle:string;
    viewButtonTitle: string;
    copyButtonTitle: string;
    createButtonTitle: string;
    deleteButtonTitle: string;
}
