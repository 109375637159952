import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "undo-actions",
    templateUrl: "./undo-action.component.html",
})
export class UndoActionButtonComponent {

    @Input() undoButton: boolean;

    @Input() undoButtonDisabled: boolean;

    @Output() public undo: EventEmitter<Event> = new EventEmitter();
}
