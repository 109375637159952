ng1App.controller('doctor.patient.DocumentsEditCtrl',
    ['$scope', '$rootScope', '$routeParams', '$modal', '$filter', 'DocumentCtx', 'DocumentsApiFactory', 'DocumentModificationActions', 'FormLoaderService', 'DocumentSpinnerHelper', 'MessageService', 'CreatePaperEncounterOrderModal', 'TemplateSaver',
     function ($scope, $rootScope, $routeParams, $modal, $filter, DocumentCtx, DocumentsApiFactory,
                                                                DocumentModificationActions, FormLoaderService, DocumentSpinnerHelper, MessageService,
                                                                CreatePaperEncounterOrderModal, TemplateSaver) {
    $scope.$emit('setHelpPage', 'redaguoti-elektronini-dokumenta');

    $scope._shared = {
        customValidator: null,
        // documents can override this to allow only save final (restrict sign)
        saveFinalOnly: function(){
            return false;
        }
    };

    DocumentCtx.reset();
    DocumentCtx.setMode('edit');
    DocumentCtx.setPatientId($routeParams.patientId);
    DocumentCtx.setDocumentId($routeParams.documentId);

    $scope._removed = {};
    $scope._modified = {};
    // documents can override this to allow only save final (restrict sign)
    $scope.saveFinalOnly = function(){
        return false;
    };

    FormLoaderService.startFormLoading();
    $scope.form = DocumentsApiFactory.getByCompositionForEdit({id: $routeParams.documentId, certificateId: $routeParams.certificateId}, function (d) {
        DocumentCtx.setDocument(d);
        $scope.form = d;
            $scope.prefillData = d;
        $scope.patientData = d.metadata.patient;
        $scope.organization = d.metadata.custodian;
        $scope.practitioner = d.metadata.practitioner;
        $scope.encounterData = d.metadata.encounter;
        DocumentModificationActions.checkValidQualification($scope.practitioner);
    },
            function (err) {
                if (err.status == 500) {
                    MessageService.handleError(err.data, MessageService);
                } else if (err.status == 400) {
                    _.each(err.data.globalErrors, function (i) {
                        MessageService.error(i.msg == 'incorrect_document_status' ? 'Bloga dokumento būsena' : i.msg);
                    });
                }
            }
    );
    FormLoaderService.endFormLoading($scope.form.$promise);

    // register all $scope.form.$promise.then callbacks for later use.
    $scope._formLoadCallbackRegistry = [];
    var _then = $scope.form.$promise.then;
    $scope.form.$promise.then = function (p1, p2, p3) {
        _then.call($scope.form.$promise, p1, p2, p3).then(function () {
            $scope._formLoadCallbackRegistry.push([p1, p2, p3]);
        });
    };

    $scope.docCtx = DocumentCtx;

    var checkRemovedOrModifiedItems = function (callback) {
        console.log('removed:', $scope._removed, 'modified:', $scope._modified);
        if (normalizeModifiedItems('allergies') || normalizeModifiedItems('diagnosis') || normalizeModifiedItems('orders')) {
            $scope.addReason(callback);
        } else {
            callback();
        }
    };

    var normalizeModifiedItems = function (type) {
        if ($scope._modified[type]) {
            _.each($scope._removed[type], function (r) {
                var index = -1;
                for (var i = 0; i < $scope._modified[type].length; i++) {
                    var m = $scope._modified[type][i];
                    if (m.fullId == r.fullId) {
                        index = i;
                        break;
                    }
                }
                console.log(index);
                if (index > -1) {
                    $scope._modified[type].splice(index, 1);
                }
            });
        }
        //$scope._modified[type] = _.without($scope._modified[type], $scope._removed[type]);
        return ($scope._modified[type] && $scope._modified[type].length > 0) || ($scope._removed[type] && $scope._removed[type].length > 0);
    };



    $scope.savePartial = function () {
        DocumentModificationActions.savePartial($routeParams.documentId, $scope.mainDocumentForm, $scope._shared.customValidator, checkRemovedOrModifiedItems);
    };
    $scope.saveFinal = function (doSign) {
        DocumentModificationActions.saveFinal($routeParams.documentId, $scope.mainDocumentForm, $scope._shared.customValidator, checkRemovedOrModifiedItems, null, null, doSign);
    };

    $scope._spinner_loading = false;

    window.__log = function () {
        DocumentModificationActions.__log($scope.mainDocumentForm);
    };

    $scope.docSpinnerHelper = DocumentSpinnerHelper;

    var updateEncounterData = function (enc) {
        $scope.encounterData = $scope.form.metadata.encounter = enc;
        $scope.form.encounterRef = enc.fullId;
    };

    $scope.changeEncounterOrder = function () {
        DocumentModificationActions.changeEncounterOrder($scope.form.patientId, $scope.encounterData, updateEncounterData);
    };

    $scope.removeEncounterOrder = function () {
        DocumentModificationActions.removeEncounterOrder($scope.encounterData, updateEncounterData);
    };

    $scope.hasEncounterOrder = function () {
        return $scope.encounterData && $scope.encounterData.orderRef;
    };

    $scope.addChangeE025 = function () {
        DocumentModificationActions.modalForContinuedTreatmentE025($routeParams.patientId, $scope, $rootScope);
    };

    $scope.loadTemplate = function () {
        DocumentModificationActions.loadTemplateModal($scope, $rootScope);
    };

    $scope.removeContinuedTreatmentE025 = function () {
        DocumentModificationActions.removeContinuedTreatmentE025($scope);
    };

    var modalCancelResources = $modal({
        scope: $scope,
        title: $filter('translate')('mdl.add.rea.title'),
        contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel_resources.html'
    });
    $scope.addReason = function (callback) {
        $scope._modalCancelCallback = function () {
            modalCancelResources.hide();
            callback();
        };
        modalCancelResources.$promise.then(function () {
            modalCancelResources.show();
        });
    };

    $scope.createObs = function () {
        CreatePaperEncounterOrderModal.show($scope.encounterData.id, updateEncounterData);
    };
    $scope.sameOrg = function () {
        if (!$scope.encounterData) {
            return false;
        }
        var orgId = $scope.encounterData.organizationRef.split('/')[1];
        var practOrgId = $rootScope.currentUser.organizationReference.split('/')[1];

        return orgId === practOrgId;
    };

    $scope.isEncounterActive = function () {
        if (!$scope.encounterData) {
            return false;
        }

        return $scope.encounterData.status === 'in progress';
    };

    //----end test

    $scope.docMenu = {
        main: [
            {
                type: 'main',
                title: 'doc.add.template',
                action: $scope.loadTemplate,
                isVisible: function () {
                    return $filter('acRead')('TemplateList');
                }
            }
        ],
        dropDown: [
            {
                type: 'ctx',
                title: 'doc.add.template',
                action: $scope.loadTemplate,
                isVisible: function () {
                    return $filter('acRead')('TemplateList');
                }
            },
            {
                type: 'ctx',
                title: 'doc.save.template',
                isVisible: function () {
                    return $filter('acWrite')('TemplateList');
                },
                action: function () {
                    TemplateSaver.save($scope.form);
                }
            },
            {type: 'separator'},
            {
                type: 'ctx',
                title: 'doc.create.enc.ord',
                isVisible: function () {
                    return $scope.sameOrg() && $scope.isEncounterActive() && $filter('acWrite')('Order', ['7']);
                },
                action: $scope.createObs
            },
            {
                type: 'ctx',
                title: 'doc.add.enc.ord',
                isVisible: function () {
                    return $filter('acWrite')('Encounter') && $scope.sameOrg() && $scope.isEncounterActive() && !$scope.hasEncounterOrder();
                },
                action: $scope.changeEncounterOrder
            },
            // {
            //     type: 'ctx',
            //     title: 'doc.created.enc.ord.edit',
            //     isVisible: function () {
            //         return $scope.sameOrg() && $scope.isEncounterActive() && $filter('acWrite')('Order', ['7']);
            //     },
            //     action: $scope.createObs
            // },
            {
                type: 'ctx',
                title: 'doc.edit.enc.ord',
                isVisible: function () {
                    return $filter('acWrite')('Encounter') && $scope.sameOrg() && $scope.isEncounterActive() && $scope.hasEncounterOrder();
                },
                action: $scope.changeEncounterOrder
            },
            {
                type: 'ctx',
                title: 'doc.remove.enc.ord',
                isVisible: function () {
                    return $filter('acWrite')('Encounter') && $scope.sameOrg() && $scope.isEncounterActive() && $scope.hasEncounterOrder();
                },
                action: $scope.removeEncounterOrder
            }
        ]
    };
}]);
