import {Component, OnInit} from '@angular/core';
import {PortalTypeEnum} from "../../../../shared/models/portalType.enum";

@Component({
    selector: 'dp-doc-ref-consent-template-list',
    templateUrl: './dp-doc-ref-consent-template-list.component.html'
})
export class DpDocRefConsentTemplateListComponent implements OnInit {

    portalType: PortalTypeEnum = PortalTypeEnum.DP;

    ngOnInit(): void {
    }

}
