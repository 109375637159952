import { EntityModel } from '../../../../../shared/models/entity.model';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { distinctUntilChanged, EMPTY, Observable, tap } from 'rxjs';

export interface IUpdateControlStatus {
    controlNameForListen: string;
    controlNameForUpdate: string | string[];
    enableValue?: any;
    disableValue?: any;
    addValidators?: ValidatorFn[];
    resetValue?: any;
    booleanCompare?: boolean;
}

export function generateSelectionValuesFromEntity(values: EntityModel[]): string[] {
    if (!values?.length) {
        return [];
    }
    return values.map(item => item.name);
}

export function updateCheckboxControl(value: string, controlName: string, formGroup = this.formGroup) {
    if (!formGroup) {
        return;
    }
    if (!formGroup.get(controlName)) {
        createDynamicChildControl(controlName, [], formGroup);
    }
    const currentValue = [...formGroup.get(controlName).getRawValue()];
    const index = currentValue.indexOf(value);
    if (index !== -1) {
        currentValue.splice(index, 1);
    } else {
        currentValue.push(value);
    }
    formGroup.get(controlName).setValue(currentValue);
    formGroup.get(controlName).markAsDirty();
    formGroup.get(controlName).markAsTouched();
}

export function multiCheckboxIsChecked(checkboxValue: string, controlName: string, formGroup = this.formGroup): boolean {
    if (!formGroup) {
        return;
    }
    if (!formGroup.get(controlName)) {
        createDynamicChildControl(controlName, [], formGroup);
    }
    return formGroup.get(controlName)?.value?.includes(checkboxValue);
}

export function controlIsDisabled(controlName: string, formGroup = this.formGroup): boolean {
    if (!formGroup) {
        return;
    }
    return formGroup.get(controlName)?.disabled;
}

export function listenAndChangeStatusOfControl(config: IUpdateControlStatus, formGroup = this.formGroup): Observable<any> {
    if (!formGroup) {
        return EMPTY;
    }
    function enableControl(control: string) {
        config?.disableValue && formGroup.get(control)?.reset(config?.resetValue || null);
        formGroup.get(control)?.enable({emitEvent: false});
        if (config.addValidators) {
            formGroup.get(control)?.addValidators(config.addValidators);
        }
        formGroup.get(control)?.updateValueAndValidity({onlySelf: true, emitEvent: false});
    }
    const disableControl = (control: string) => {
        formGroup.get(control)?.reset(config?.resetValue || null);
        formGroup.get(control)?.disable({emitEvent: false});
        if (config.addValidators) {
            formGroup.get(control)?.clearValidators();
        }
        formGroup.get(control)?.updateValueAndValidity({onlySelf: true, emitEvent: false});
    }
    return formGroup.get(config.controlNameForListen).valueChanges.pipe(
            distinctUntilChanged(),
            tap((value: any) => {
                const multiChild = Array.isArray(config.controlNameForUpdate);
                const listenForDisabledValue = value?.length && !config.enableValue;
                let trueCondition: boolean;
                if (config.booleanCompare) {
                    trueCondition =
                        (!Array.isArray(value) && (!!value == !!config?.enableValue || (listenForDisabledValue && !!value != !!config?.disableValue)))
                        || (Array.isArray(value) && (value?.includes(config?.enableValue) || (listenForDisabledValue && !value?.includes(config?.disableValue))));
                } else {
                    trueCondition =
                        (!Array.isArray(value) && (value === config?.enableValue || (listenForDisabledValue && value !== config?.disableValue)))
                        || (Array.isArray(value) && (value?.includes(config?.enableValue) || (listenForDisabledValue && !value?.includes(config?.disableValue))));
                }

                if (trueCondition) {
                    if (!multiChild) {
                        enableControl(<string>config.controlNameForUpdate);
                    } else {
                        (<string[]>config.controlNameForUpdate).forEach(control => {
                            enableControl(control)
                        });
                    }
                } else {
                    if (!multiChild) {
                        (formGroup.get(config.controlNameForUpdate).enabled) && disableControl((<string>config.controlNameForUpdate));
                    } else {
                        (<string[]>config.controlNameForUpdate).forEach(control => {
                            (formGroup.get(control).enabled) && disableControl(control)
                        });
                    }
                }
            })
        );
}

export function generateNameForChildControl(parent: string, uniqSuffix: string = ''): string {
    return _.camelize(
        parent
            .replace(/\([^)]*\)/g, '')
            .split('.').pop().replace(/[^a-zA-Z\s]/g, '').toLowerCase()) + `${uniqSuffix}`;
}

export function updateParentControlAndCreateChild(option: EntityModel, controlName: string, uniqSuffix: string = '', formGroup = this.formGroup) {
    updateCheckboxControl(option.name, controlName, formGroup);

    if (!option.result?.length) {
        return;
    }
    const isTrue = multiCheckboxIsChecked(option.name, controlName, formGroup);
    const childFormControlName = generateNameForChildControl(option.name, uniqSuffix);
    createDynamicChildControl(childFormControlName, [], formGroup);
    changeStatusOfChild(isTrue, childFormControlName, formGroup)
}

export function createDynamicChildControl(controlName: string, initValue: any = null, formGroup = this.formGroup): FormControl {
    if (!formGroup.contains(controlName)) {
        formGroup.addControl(controlName, new FormControl({value: initValue, disabled: true}), {emitEvent: false});
    }
    return formGroup.get(controlName);
}

export function changeStatusOfChild(value: boolean, formControlName: string, formGroup = this.formGroup): void {
    if (value) {
        formGroup.get(formControlName)?.enable({emitEvent: false});
    } else {
        formGroup.get(formControlName)?.reset();
        formGroup.get(formControlName)?.disable({emitEvent: false});
    }
}

export function controlIsRequired(controlName: string, formGroup = this.formGroup): boolean {
    if (!formGroup) {
        return;
    }
    return formGroup.get(controlName).hasValidator(Validators.required);
}

export function formatDateForDatePickers<T extends object>(obj: T, fieldsForRefactoring: string[]): T {
    function recursiveRefactorDates(input: any) {
        if (_.isObject(input)) {
            _.each(input, (value, key) => {
                if (_.contains(fieldsForRefactoring, key) && _.isString(value)) {
                    const dateValue = new Date(value);

                    if (!isNaN(dateValue.getTime())) {
                        input[key] = dateValue;
                    }
                }

                recursiveRefactorDates(value);
            })
        }
    }

    const result = JSON.parse(JSON.stringify(obj));
    recursiveRefactorDates(result);

    return result;
}

export function getControlValue(control: string, formGroup = this.formGroup): any {
    return formGroup?.get(control)?.value;
}

export function addMultipleFormGroupsToFormArray(formGroups: FormGroup[], formArrayName: string, formGroup = this.formGroup): void {
    formGroups?.forEach(group => {
        if (!formGroup.get(formArrayName)) {
            formGroup.addControl(formArrayName, new FormArray([]));
        }
        (formGroup.get(formArrayName) as FormArray).push(group, {emitEvent: false});
    });
}

export function clearFormArray(formArrayName: string, formGroup = this.formGroup): void {
    const control = (formGroup?.get(formArrayName) as FormArray);
    control?.clear({emitEvent: false});
}

export function addObjToFormArray(obj: any): FormGroup {
    const formGroup = new FormGroup({});

    Object.keys(obj)?.forEach(key => {
        if (obj[key] !== null && typeof obj[key] === 'object' && !(obj[key] instanceof Date) && !Array.isArray(obj[key])) {
            formGroup.addControl(key, addObjToFormArray(obj[key]));
        } else {
            formGroup.addControl(key, new FormControl(obj[key]));
        }
    });

    return formGroup
}

export function updateFormArray(data: {[key: string]: any}, keyChain: string, formGroup = this.formGroup): void {
    const formControl = keyChain.split('.').reduce((acc, curr) => acc && acc[curr], data)?.map(item => addObjToFormArray(item));
    addMultipleFormGroupsToFormArray(formControl, keyChain, formGroup);
}