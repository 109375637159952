ng1App.factory('OrganizationsApiFactory', 
    ['$log', '$resource', 'apiUrl', '$http',
     function ($log, $resource, apiUrl, $http) {

    var URL_BASE = apiUrl + '/organizations';
    return $resource('', {}, {
        get: {
            method: 'GET',
            url: URL_BASE
        },
        getForWorkplace: {
            method: 'GET',
            url: URL_BASE + '/forWorkplace'
        },
        getForWorkplaceByJar: {
            method: 'GET',
            url: URL_BASE + '/forWorkplaceByJar'
        },
        getSuggestions: {
            method: 'GET',
            url: URL_BASE + '/suggest?search=:search',
            isArray: true
        },
        getById: {
            method: 'GET',
            url: URL_BASE + "/:organizationId"
        },
        getCount: {
            method: 'GET',
            url: URL_BASE + "/count"
        },
        saveOrUpdate: {
            method: 'POST',
            url: URL_BASE + "/saveOrUpdate"
        },
        getHierarchy: {
            method: 'GET',
            url: URL_BASE + "/:organizationId/hierarchy"
        },
        getDepartments: {
            method: 'GET',
            url: URL_BASE + "/:organizationId/departments",
            isArray: true
        },
        searchJAR: {
            method: 'GET',
            url: URL_BASE + "/searchJAR",
            isArray: false
        },
        delete: {
            method: 'DELETE',
            url: URL_BASE + "/:organizationId",
            isArray: false
        },
        checkOrganizationCertificate : {
            method: 'GET',
            url: URL_BASE + "/checkOrganizationCertificate?jarCode=:jarCode"
        },
        saveOrganizationCertificate : {
            method: 'POST',
            url: URL_BASE + "/saveOrganizationCertificate"
        },
        updateOrganizationCertificate : {
            method: 'POST',
            url: URL_BASE + "/updateOrganizationCertificate"
        },
        organizationCertificatesForList : {
            method: 'GET',
            url: URL_BASE + "/organizationCertificatesForList"
        },
        organizationCertificateByJarCode : {
            method: 'GET',
            url: URL_BASE + "/organizationCertificateByJarCode"
        },
        organizationCertificateById : {
            method: 'GET',
            url: URL_BASE + "/organizationCertificateById"
        },
        getOrganizationSystemUser : {
            method: 'GET',
            url: URL_BASE + "/:jarCode/organization-system-user"
        },
        getRootOrganizationOf : {
            method: 'GET',
            url: URL_BASE + "/root-of/:organizationId"
        },
        moveOrganization: {
            method: 'POST',
            url: URL_BASE + "/move"
        }
    });
}]);