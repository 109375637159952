<label *ngIf="labelText" [class]="labelClass" class="form-label flex-grow-1 col-form-label" for="{{ elementId }}">{{
    labelText |
    translate }}
</label>
<ng-multiselect-dropdown [id]="elementId" [placeholder]="placeholder" [settings]="dropdownSettings"
    [data]="dropdownDataList" [formControl]="fControl" [disabled]="readonly || viewMode"
    [ngClass]="{ 'is-invalid': fControl?.invalid && fControl.touched }" (onSelect)="onItemSelect($event)"
    (onDeSelect)="onItemDeSelect($event)" (onFilterChange)="onCompFilterChange($event)"
    (onSelectAll)="onItemSelectAll($event)" (onDropDownClose)="onCompDropDownClose($event)"
    class="w-100 ag-header-multiselect">
</ng-multiselect-dropdown>