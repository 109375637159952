import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { Formf058Model } from "src/app/shared/models/f058.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2f058 extends ConfigService {

    path = this.apiUrl + '/f058';

    getf058FormView(id: string): Observable<Formf058Model> {
        return this.http.get<Formf058Model>(this.path + '/view/' + id);
    }

    getConditionSeverity(): Observable<any[]> {
        const url = `${this.path}/condition-severity`;
        return this.http.get<any[]>(url);
    }

    getInfectionsFamiliarityAnimal(): Observable<any[]> {
        const url = `${this.path}/infections-familiarity-animal`;
        return this.http.get<any[]>(url);
    }

    getInfectionsFormDisease(): Observable<any[]> {
        const url = `${this.path}/infections-form-disease`;
        return this.http.get<any[]>(url);
    }

    getInfectionsPersonHospitalization(): Observable<any[]> {
        const url = `${this.path}/infections-person-hospitalization`;
        return this.http.get<any[]>(url);
    }

    submitf058Form(payload: Formf058Model, id: string): Observable<Formf058Model> {
        return this.http.post<Formf058Model>(this.path + '/create/' + id, payload);
    }

    savef058Form(payload: Formf058Model, id: string): Observable<Formf058Model> {
        return this.http.post<Formf058Model>(this.path + '/save/' + id, payload);
    }

    signf058Form(payload: Formf058Model, id: string): Observable<Formf058Model> {
        return this.http.post<Formf058Model>(this.path + '/sign/' + id, payload);
    }

    signingSubmitf058Form(payload: Formf058Model, id: string): Observable<Formf058Model> {
        return this.http.post<Formf058Model>(this.path + '/signingSubmit/' + id, payload);
    }

    sealf058Form(payload: Formf058Model, id: string): Observable<Formf058Model> {
        return this.http.post<Formf058Model>(this.path + '/seal/' + id, payload);
    }
}