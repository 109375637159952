import {AfterViewInit, Component, DestroyRef, Input, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from '@angular/forms';
import {
    E030PsychiatricRiskFactorsComponent
} from './e030-forms/e030-psychiatric-risk-factors/e030-psychiatric-risk-factors.component';
import {E030MedicalDataComponent} from './e030-forms/e030-medical-data/e030-medical-data.component';
import {E030ConsultationPlanComponent} from './e030-forms/e030-consultation-plan/e030-consultation-plan.component';
import {
    E030DiagnosesDescriptionComponent
} from './e030-forms/e030-diagnoses-description/e030-diagnoses-description.component';
import {E030TreatmentAppliedComponent} from './e030-forms/e030-treatment-applied/e030-treatment-applied.component';
import {E030RecomendationsComponent} from './e030-forms/e030-recomendations/e030-recomendations.component';
import {ApiV2E030} from '../../../../../api/api-v2-doc-ref-e030';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {formatDateForDatePickers} from '../e096/e096-utils/utils';
import {NGNCaptionModel} from '../shared/models/shared.model';
import {DocumentTagEnum} from '../../../../shared/models/document-tag.enum';
import {map, Subscription} from 'rxjs';
import {ApiV2Documents} from '../../../../../api/api-v2-documents';
import {ApiV2PregnancyDashboard} from '../../../../../api/api-v2-doc-pregnancy-dashboard';
import {RouteParams} from '../../../../ng1.routeParams';
import {E030GeneralDocumentData} from './e030-forms/e030-general-data/e030-general-data.component';

@Component({
    selector: "app-e030",
    templateUrl: "./e030.component.html",
})
export class E030Component implements OnInit, AfterViewInit {
    @Input() editable: boolean = true;
    @Input() documentData: E030GeneralDocumentData;

    @ViewChild(E030PsychiatricRiskFactorsComponent) psychiatricRiskFactorsComponent: E030PsychiatricRiskFactorsComponent
    @ViewChild(E030MedicalDataComponent) medicalDataComponent: E030MedicalDataComponent
    @ViewChild(E030ConsultationPlanComponent) consultationPlanComponent: E030ConsultationPlanComponent
    @ViewChild(E030DiagnosesDescriptionComponent) diagnosesDescriptionComponent: E030DiagnosesDescriptionComponent
    @ViewChild(E030TreatmentAppliedComponent) treatmentAppliedComponent: E030TreatmentAppliedComponent
    @ViewChild(E030RecomendationsComponent) recomendationsComponent: E030RecomendationsComponent

    initialFormValue: { [key: string]: any };
    formGroup: FormGroup;
    ngnCaptionData: NGNCaptionModel;
    documentTag = DocumentTagEnum.e030;
    prefillData: any;
    private patientId: string;
    private encounterId: string;
    fromDoc: DocumentTagEnum | undefined;

    constructor(
        private fb: FormBuilder,
        private apiV2E030: ApiV2E030,
        private destroyRef: DestroyRef,
        private apiV2Documents: ApiV2Documents,
        private _apiV2PregnancyDashboard: ApiV2PregnancyDashboard,
        private params: RouteParams,
    ) {

    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.encounterId = this.params.encounterId;
        this.fromDoc = this.params.fromDoc ? DocumentTagEnum[this.params.fromDoc] : undefined;
        this.subscribeNGNCaptionData();
        this.getPrefillData()
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            psychiatricRisk: this.psychiatricRiskFactorsComponent?.formGroup,
            medicalData: this.medicalDataComponent?.formGroup,
            consultationPlan: this.consultationPlanComponent?.formGroup,
            diagnosesDescription: this.diagnosesDescriptionComponent?.formGroup,
            treatmentApplied: this.treatmentAppliedComponent?.formGroup,
            recomendations: this.recomendationsComponent?.formGroup,
        });
        if (!this.editable) {
            this.loadData();
            this.formGroup.disable();
        }
        if (this.documentData) {
            this.formGroup.patchValue({generalData: this.documentData});
        }
    }

    private loadData(): void {
        this.apiV2E030.getE030FormView().pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => {
                this.initialFormValue = res;
                const initData = formatDateForDatePickers(res, ['determinationDate']);
                this.formGroup.patchValue(initData);
            });
    }

    private getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.encounterId, this.patientId)
            .pipe(map((response: any) => response))
            .subscribe({
                next: (response) => {
                    this.prefillData = response;
                }
            });
    }

    private subscribeNGNCaptionData(): Subscription {
        return this._apiV2PregnancyDashboard.getNGNCaption(this.patientId).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
                this.ngnCaptionData = data;
            });
    }

    public saveForm(): void {
        const formValues = {...this.prefillData, ...this.formGroup.value};
        console.log(formValues);
        this.apiV2E030.saveE030Form(formValues, this.patientId).subscribe();
    }

    public submitForm(): void {
        const formValues = {...this.prefillData, ...this.formGroup.value};
        this.apiV2E030.submitE030Form(formValues, this.patientId).subscribe();
    }

    public signingSubmitForm(): void {
        const formValues = {...this.prefillData, ...this.formGroup.value};
        this.apiV2E030.signingSubmitE030Form(formValues, this.patientId).subscribe();
    }

    public sealConfirmForm(): void {
        const formValues = {...this.prefillData, ...this.formGroup.value};
        this.apiV2E030.sealConfirmE030Form(formValues, this.patientId).subscribe();
    }

    public signForm(): void {
        const formValues = {...this.prefillData, ...this.formGroup.value};
        this.apiV2E030.signE030Form(formValues, this.patientId).subscribe();
    }

    public clearForm(): void {
        this.formGroup.reset();
    }

    onPatientWidgetChanges(event) {
        this.prefillData = {...event};
    }
}
