<div>
    <h1>{{ "E113-RV Rizikos veiksniai" | translate }}</h1>
    <app-document-caption [captionData]="documentCaptionData"></app-document-caption>
    <app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
        [prefillData]="prefillData"></app-common-patient-widget>
    <app-ngn-caption [captionData]="ngnCaptionData"></app-ngn-caption>
    <app-encounter-block></app-encounter-block>
    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Didelės rizikos nėštumo veiksniai' | translate"
        id="highRiskPregnancyFactors">
        <div class="row">
            <div class="col-lg-12 col-xl-6">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">
                                    {{ "Nepalanki akušerinė anamnezė" | translate }}
                                </header>
                                <div class="row" *ngFor="let item of getUnfavorableObstetricHistoryFormArray.controls">
                                    <div class="col-7">
                                        <app-checkbox [addUniqId]="true" [inputId]="item.value.name" [labelText]="item.value.name"
                                            [checkboxChecked]="item.value.checked" (change)="
                                                onCheckboxChange(
                                                    formGroup,
                                                    $event,
                                                    'highRiskFactors',
                                                    'unfavorableObstetricHistory',
                                                    item.value.name
                                                )
                                            "></app-checkbox>
                                    </div>
                                    <div class="col-5 date">
                                        <app-date-picker *ngIf="!!item.value.checked" [inputId]="item.value.name + 'input'"
                                            [fControl]="item.controls.value" [maxDate]="currentDate"></app-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">{{ "Nėštumo patologija" | translate }}</header>
                                <div class="row" *ngFor="let item of getPregnancyPathologyFormArray.controls">
                                    <div class="col-7">
                                        <app-checkbox [addUniqId]="true" [inputId]="item.value.name" [labelText]="item.value.name"
                                            [checkboxChecked]="item.value.checked" (change)="
                                                onCheckboxChange(
                                                    formGroup,
                                                    $event,
                                                    'highRiskFactors',
                                                    'pregnancyPathology',
                                                    item.value.name
                                                )
                                            "></app-checkbox>
                                    </div>
                                    <div class="col-5 date">
                                        <app-date-picker *ngIf="!!item.value.checked" [inputId]="item.value.name + 'input'"
                                            [fControl]="item.controls.value" [maxDate]="currentDate"></app-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-6">
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">
                                    {{ "Nepalanki nėščiosios būklė" | translate }}
                                </header>
                                <div class="row" *ngFor="let item of getUnfavorablePregnantConditionFormArray.controls">
                                    <div class="col-7">
                                        <app-checkbox [addUniqId]="true" [inputId]="item.value.name" [labelText]="item.value.name"
                                            [checkboxChecked]="item.value.checked" (change)="
                                                onCheckboxChange(
                                                    formGroup,
                                                    $event,
                                                    'highRiskFactors',
                                                    'unfavorablePregnantCondition',
                                                    item.value.name
                                                )
                                            "></app-checkbox>
                                    </div>
                                    <div class="col-5 date">
                                        <app-date-picker *ngIf="!!item.value.checked" [inputId]="item.value.name + 'input'"
                                            [fControl]="item.controls.value" [maxDate]="currentDate"></app-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">{{ "Vaisiaus patologija" | translate }}</header>
                                <div class="row" *ngFor="let item of getFetalPathologyFormArray.controls">
                                    <div class="col-7">
                                        <app-checkbox [addUniqId]="true" [inputId]="item.value.name" [labelText]="item.value.name"
                                            [checkboxChecked]="item.value.checked" (change)="
                                                onCheckboxChange(
                                                    formGroup,
                                                    $event,
                                                    'highRiskFactors',
                                                    'fetalPathology',
                                                    item.value.name
                                                )
                                            "></app-checkbox>
                                    </div>
                                    <div class="col-5 date">
                                        <app-date-picker *ngIf="!!item.value.checked" [inputId]="item.value.name + 'input'"
                                            [fControl]="item.controls.value" [maxDate]="currentDate"></app-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 g-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">{{ "Nėščiosios ligos" | translate }}</header>
                                <div class="row"
                                    *ngFor="let item of getPregnantDiseasesFormArray.controls; let i = index">
                                    <div class="col-7">
                                        <app-checkbox [addUniqId]="true" [inputId]="item.value.name" [labelText]="item.value.name"
                                            [checkboxChecked]="item.value.checked" (change)="
                                                onCheckboxChange(
                                                    formGroup,
                                                    $event,
                                                    'highRiskFactors',
                                                    'pregnantDiseases',
                                                    item.value.name
                                                )
                                            "></app-checkbox>
                                    </div>
                                    <div class="col-5 date">
                                        <app-date-picker *ngIf="!!item.value.checked" [inputId]="item.value.name + 'input'"
                                            [fControl]="item.controls.value" [maxDate]="currentDate"></app-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Priešlaikinio gimdymo rizika' | translate"
        id="prematureBirthRisk">
        <div class="row">
            <div class="col">
                <div *ngIf="isRiskLevelSelected('prematureBirthRisk') && !!getRiskLevelStyle('prematureBirthRisk')"
                    [class]="getRiskLevelStyle('prematureBirthRisk')">
                    <header>
                        <span class="fw-bold ms-5"> {{ "Rizikos lygmuo" | translate }}</span> -
                        {{ getRiskLevel(getRiskLevelStyle("prematureBirthRisk")) | lowercase }}
                    </header>
                </div>
                <div *ngFor="let risk of prematureBirthRisk" class="row ms-3 mt-2">
                    <span class="mb-2"><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                        {{ risk.label.split(",") | slice : 1 }}</span>
                    <div *ngFor="let item of risk.checkBoxNames" class="ms-5">
                        <app-checkbox [addUniqId]="true" [inputId]="item" [checkboxValue]="item" [labelText]="item" [checkboxChecked]="
                                getCheckboxFormArrayValue('prematureBirthRisk', risk.controlName).includes(item)
                            "
                            (change)="onCheckboxChange(formGroup, $event, 'prematureBirthRisk', risk.controlName)"></app-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Gimdymo rizika' | translate" id="birthRisk">
        <div class="row">
            <div class="col">
                <div *ngIf="isRiskLevelSelected('birthRisk') && getRiskLevelStyle('birthRisk')"
                    [class]="getRiskLevelStyle('birthRisk')">
                    <header>
                        <span class="fw-bold ms-5"> {{ "Rizikos lygmuo" | translate }}</span> -
                        {{ getRiskLevel(getRiskLevelStyle("birthRisk")) | lowercase }}
                    </header>
                </div>
                <div *ngFor="let risk of birthRisk" class="row ms-3 mt-2">
                    <span class="mb-2"><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                        {{ risk.label.split(",") | slice : 1 }}</span>
                    <div *ngFor="let item of risk.checkBoxNames" class="ms-5">
                        <app-checkbox [addUniqId]="true" [inputId]="item" [checkboxValue]="item" [labelText]="item"
                            [checkboxChecked]="getCheckboxFormArrayValue('birthRisk', risk.controlName).includes(item)"
                            (change)="onCheckboxChange(formGroup, $event, 'birthRisk', risk.controlName)"></app-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget id="postpartumHemorrhageRisk" [closable]="false" [closed]="false"
        [title]="'Pogimdyminio kraujavimo rizika' | translate">
        <div class="row">
            <div class="col">
                <div *ngIf="isRiskLevelSelected('postpartumHemorrhageRisk')"
                    [class]="getRiskLevelStyle('postpartumHemorrhageRisk')">
                    <header>
                        <span class="fw-bold ms-5"> {{ "Rizikos lygmuo" | translate }}</span> -
                        {{ getRiskLevel(getRiskLevelStyle("postpartumHemorrhageRisk")) | lowercase }}
                    </header>
                </div>
                <div *ngFor="let risk of postpartumHemorrhageRisks" class="row ms-3 mt-2">
                    <span class="mb-2"><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                        {{ risk.label.split(",") | slice : 1 }}</span>
                    <div *ngFor="let item of risk.checkBoxNames" class="ms-5">
                        <app-checkbox [addUniqId]="true" [inputId]="item" [checkboxValue]="item" [labelText]="item" [checkboxChecked]="
                                getCheckboxFormArrayValue('postpartumHemorrhageRisk', risk.controlName).includes(item)
                            "
                            (change)="onCheckboxChange(formGroup, $event, 'postpartumHemorrhageRisk', risk.controlName)"></app-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false" [title]="'Tromboembolijų profilaktika' | translate"
        id="thromboembolismPrevention">
        <div class="row">
            <div class="col">
                <div *ngIf="isRiskLevelSelected('thromboembolismPrevention')"
                    [class]="getRiskLevelStyle('thromboembolismPrevention')">
                    <header>
                        <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                        {{ getRiskLevel(getRiskLevelStyle("thromboembolismPrevention")) | lowercase }}
                    </header>
                </div>
                <div *ngFor="let risk of thromboembolismPrevention" class="row ms-3 mt-2">
                    <span class="mb-2"><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                        {{ risk.label.split(",") | slice : 1 }}</span>
                    <div *ngFor="let item of risk.checkBoxNames" class="ms-5">
                        <app-checkbox [addUniqId]="true" [inputId]="item" [checkboxValue]="item" [labelText]="item" [checkboxChecked]="
                                getCheckboxFormArrayValue('thromboembolismPrevention', risk.controlName).includes(item)
                            " (change)="
                                onCheckboxChange(formGroup, $event, 'thromboembolismPrevention', risk.controlName)
                            "></app-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <nhr-record-widget [closable]="false" [closed]="false"
        [title]="'Tromboembolijų profilaktika po gimdymo' | translate" id="thromboembolismPreventionAfterChildbirth">
        <div class="row">
            <div class="col">
                <div *ngIf="isRiskLevelSelected('thromboembolismPreventionAfterChildbirth')"
                    [class]="getRiskLevelStyle('thromboembolismPreventionAfterChildbirth')">
                    <header>
                        <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                        {{ getRiskLevel(getRiskLevelStyle("thromboembolismPreventionAfterChildbirth")) | lowercase }}
                    </header>
                </div>

                <fieldset class="row ms-3 mt-2">
                    <legend class="fw-bold">
                        {{ "Gimdymo būdas" | translate }}
                    </legend>
                    <div *ngFor="let item of thromboembolismPreventionAfterChildbirthOptions" class="col-md-6 col-sm-6">
                        <app-radio-button radioName="childbirthType" [inputId]="item.name" [labelText]="item.name"
                            [radioTitle]="item.name" [radioId]="item.name" [radioValue]="item.value"
                            [fControl]="formGroup.get('childbirthType')"></app-radio-button>
                    </div>
                </fieldset>

                <div *ngIf="formGroup.get('childbirthType').value">
                    <div *ngFor="let risk of thromboembolismPreventionAfterChildbirthRisksNaturalWay"
                        class="row ms-3 mt-2">
                        <span class="mb-2"><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span>
                        <div *ngFor="let item of risk.checkBoxNames" class="ms-5">
                            <app-checkbox [addUniqId]="true" [inputId]="item" [checkboxValue]="item" [labelText]="item" [checkboxChecked]="
                                    getCheckboxFormArrayValue(
                                        'thromboembolismPreventionAfterChildbirth',
                                        risk.controlName
                                    ).includes(item)
                                " (change)="
                                    onCheckboxChange(
                                        formGroup,
                                        $event,
                                        'thromboembolismPreventionAfterChildbirth',
                                        risk.controlName
                                    )
                                "></app-checkbox>
                        </div>
                    </div>
                </div>

                <div *ngIf="formGroup.get('childbirthType').value === false">
                    <div *ngFor="let risk of thromboembolismPreventionAfterChildbirthRisksCaesareanOperation"
                        class="row ms-3 mt-2">
                        <span class="mb-2"><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span>
                        <div *ngFor="let item of risk.checkBoxNames" class="ms-5">
                            <app-checkbox [addUniqId]="true" [inputId]="item" [checkboxValue]="item" [labelText]="item" [checkboxChecked]="
                                    getCheckboxFormArrayValue(
                                        'thromboembolismPreventionAfterChildbirth',
                                        risk.controlName
                                    ).includes(item)
                                " (change)="
                                    onCheckboxChange(
                                        formGroup,
                                        $event,
                                        'thromboembolismPreventionAfterChildbirth',
                                        risk.controlName
                                    )
                                "></app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nhr-record-widget>

    <app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
        (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
        [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
        [submitButtonDisabled]="true" [saveButtonDisabled]="!formGroup.valid"></app-modification-actions>
</div>