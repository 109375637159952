export enum CheckupPlaces {
    MaternityWard = "Gimdymo palatoje",
    OperatingRoom = "Operacinėje",
    Ward = "Palatoje",
}

export enum Checkups {
    generalCondition = "Bendra būklė",
    skin = "Oda",
    visibleMucosa = "Matomos gleivinės",
    umbilicalCordandBloodVesselCount = "Virkštelės bigė, kraujagyslių skaičius",
    head = "Galva",
    palateWholeness = "Gomurio vientisumas",
    chestShape = "Krūtinės ląstos forma",
    lungCondition = "Plaučių būklė",
    breathingRate = "Kvėpavimo dažnis",
    heart = "Širdis (ribos, ūžesiai, ritmo pobūdis)",
    pulseRate = "Pulso dažnis",
    nerveSystem = "Nervų sistema",
    abdominalOrgans = "Pilvo ertmės organai",
    meconium = "Mekonijus",
    urinating = "Šlapinimasis",
    externalGenitalia = "Išoriniai lyties organai",
    anus = "Analinė anga",
    hipJointCondition = "Klubų sąnarių būklė",
}
