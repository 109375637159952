import {Pipe, PipeTransform} from '@angular/core';
import {PersonModel} from "../models/person.model";

@Pipe({name: 'age'})
export class AgePipe implements PipeTransform {
    constructor() {
    }

    transform(person: PersonModel): any {
        if (person && person.birthDate) {
            return _.age(person.birthDate, person.deathDate);
        }
    }
}