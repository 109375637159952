ng1App.service('CertificatePayment', 
    ['MessageService', 'EntitiesApiFactory', '$log',
     function (MessageService, EntitiesApiFactory, $log) {
	this.showPaymentMessage = function (certificateType) {
//		EntitiesApiFactory.getList({classCode: 'certificate-payment-message'}, function (list) {
//			_.each(list, function (message) {
//				if (certificateType == message.code) {
//					MessageService.add('warning', message.name);
//				}
//			});
//		});
		$log.info('Certificate payment disabled until payment message retrieval will be fixed');
	};
}]);