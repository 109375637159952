<nhr-record-widget
    [closable]="true"
    [closed]="closed"
    [title]="'E113-RV | Rizikos veiksniai' | translate"
    id="riskFactors"
    class="h-auto"
>
    <div class="card mb-3">
        <div class="card-header">{{ "Didelės Rizikos Nėštumo Veiksniai" | translate }}</div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12 col-xl-6 d-flex flex-column">
                    <div class="card mb-2 g-2">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">
                                    {{ "Nepalanki akušerinė anamnezė" | translate }}
                                </header>
                                <div class="row ms-5" *ngFor="let item of unfavorableObstetricHistory | async">
                                    <div class="col-sm-9">
                                        <div class="row">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="row">
                                            {{ item.value | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card flex-grow-1 mb-2 g-2">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">{{ "Nėštumo patologija" | translate }}</header>
                                <div class="row ms-5" *ngFor="let item of pregnancyPathology | async">
                                    <div class="col-sm-9">
                                        <div class="row">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="row">
                                            {{ item.value | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <div class="card mb-2 g-2">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">
                                    {{ "Nepalanki nėščiosios būklė" | translate }}
                                </header>
                                <div class="row ms-5" *ngFor="let item of unfavorablePregnantCondition | async">
                                    <div class="col-sm-9">
                                        <div class="row">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="row">
                                            {{ item.value | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 g-2">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">{{ "Vaisiaus patologija" | translate }}</header>
                                <div class="row ms-5" *ngFor="let item of fetalPathology | async">
                                    <div class="col-sm-9">
                                        <div class="row">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="row">
                                            {{ item.value | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 g-2">
                        <div class="card-body">
                            <div class="col">
                                <header class="fw-bold card-title">{{ "Nėščiosios ligos" | translate }}</header>
                                <div class="row ms-5" *ngFor="let item of pregnantDiseases | async">
                                    <div class="col-sm-9">
                                        <div class="row">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="row">
                                            {{ item.value | date : "yyyy-MM-dd" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Tromboembolijų profilaktika" | translate }}</div>
        <div class="card-subtitle">
            <div [class]="thromboembolismPrevention.riskLevelStyle">
                <header>
                    <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                    {{ thromboembolismPrevention.riskLevel | lowercase }}
                </header>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let risk of thromboembolismPrevention.risks" class="row ms-2 mt-2">
                        <span class="mb-2"
                            ><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span
                        >
                        <div class="row ms-5" *ngFor="let item of risk.value">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Priešlaikinio gimdymo rizika" | translate }}</div>
        <div class="card-subtitle">
            <div [class]="prematureBirthRisk.riskLevelStyle">
                <header>
                    <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                    {{ prematureBirthRisk.riskLevel | lowercase }}
                </header>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let risk of prematureBirthRisk.risks" class="row ms-2 mt-2">
                        <span class="mb-2"
                            ><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span
                        >
                        <div class="row ms-5" *ngFor="let item of risk.value">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Gimdymo rizika" | translate }}</div>
        <div class="card-subtitle">
            <div [class]="birthRisk.riskLevelStyle">
                <header>
                    <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                    {{ birthRisk.riskLevel | lowercase }}
                </header>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let risk of birthRisk.risks" class="row ms-2 mt-2">
                        <span class="mb-2"
                            ><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span
                        >
                        <div class="row ms-5" *ngFor="let item of risk.value">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Pogimdyminio kraujavimo rizika" | translate }}</div>
        <div class="card-subtitle">
            <div [class]="postpartumHemorrhageRisk.riskLevelStyle">
                <header>
                    <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                    {{ postpartumHemorrhageRisk.riskLevel | lowercase }}
                </header>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let risk of postpartumHemorrhageRisk.risks" class="row ms-2 mt-2">
                        <span class="mb-2"
                            ><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span
                        >
                        <div class="row ms-5" *ngFor="let item of risk.value">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Tromboembolijų profilaktika" | translate }}</div>
        <div class="card-subtitle">
            <div [class]="thromboembolismPrevention.riskLevelStyle">
                <header>
                    <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                    {{ thromboembolismPrevention.riskLevel | lowercase }}
                </header>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div *ngFor="let risk of thromboembolismPrevention.risks" class="row ms-2 mt-2">
                        <span class="mb-2"
                            ><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span
                        >
                        <div class="row ms-5" *ngFor="let item of risk.value">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">{{ "Tromboembolijų profilaktika po gimdymo" | translate }}</div>
        <div class="card-subtitle">
            <div [class]="thromboembolismPreventionAfterChildbirth.riskLevelStyle">
                <header>
                    <span class="fw-bold ms-5">{{ "Rizikos lygmuo" | translate }}</span> -
                    {{ thromboembolismPreventionAfterChildbirth.riskLevel | lowercase }}
                </header>
            </div>
        </div>
        <div class="card-body">
            <header class="fw-bold card-title">
                {{ "Gimdymo būdas:" | translate }}
                {{
                    this.childbirthType ? ("Natūraliais takais" | translate) : ("Cezario pjūvio operacija" | translate)
                }}
            </header>

            <div class="row">
                <div class="col">
                    <div *ngFor="let risk of thromboembolismPreventionAfterChildbirth.risks" class="row ms-2 mt-2">
                        <span class="mb-2"
                            ><span class="fw-bold"> {{ risk.label.split("-")[0] }}</span>
                            {{ risk.label.split(",") | slice : 1 }}</span
                        >
                        <div class="row ms-5" *ngFor="let item of risk.value">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
