<div class="modal-header">
    <h4 class="modal-title pull-left">{{'doc.healthAssessment.form.registeredFactors' | translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body p-0">
    <ol
        *ngFor="let risk of risks; let i = index"
        [class.mt-3]="i !== 0"
        class="slats hover"
    >
        <li>
            <div class="name mt-2 fw-bolder fw-bold">{{ risk.riskFactor?.name }}</div>
            <div class="details my-1">
                <small class="text-secondary">{{ risk.date | date: 'YYYY-MM-dd' }}</small>
                <span class="vr"></span>
                <ng-container *ngIf="risk.assessmentScore">
                    <strong>{{ risk.assessmentScore }}</strong>
                </ng-container>
                <ng-container *ngIf="!risk.assessmentScore">
                    <strong>NA</strong>
                </ng-container>
                <span class="vr"></span>
                <ng-container *ngIf="risk.riskFactorMethodologyName">
                    <strong>{{ risk.riskFactorMethodologyName }}</strong>
                </ng-container>
                <ng-container *ngIf="!risk.riskFactorMethodologyName">
                    <span>-</span>
                </ng-container>
            </div>
            <small>{{ risk.comments ?? '-' }}</small>
        </li>
    </ol>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="close()">{{'exit' | translate}}</button>
</div>