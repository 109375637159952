import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { ColDef, FirstDataRenderedEvent, GridApi, GridReadyEvent, RowDoubleClickedEvent, RowNode, ValueFormatterParams } from "ag-grid-community";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { DpAspnContextService } from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { AgActionsCell } from "src/app/shared/components/ag-grid/components/ag-actions-cell/ag-actions-cell.component";
import { AgColHeaderLink } from "src/app/shared/components/ag-grid/components/ag-col-header-link/ag-col-header-link.component";
import { AgGridInputFgCell } from "src/app/shared/components/ag-grid/components/ag-input-cell/agGrid-input-fg-cell";
import { AgGridSelectFgCell } from "src/app/shared/components/ag-grid/components/ag-select-cell/agGrid-select-fg-cell";
import { RouteParams } from "../../../../../ng1.routeParams";
import { AgGridModalOpenLinkCell } from "../../../../../shared/components/ag-grid/ag-modal-open-link-cell/ag-modal-open-link.component";
import { AgGridCommonConfig } from "../../../../../shared/configs/ag-grid-common.config";
import { DocumentTagEnum } from "../../../../../shared/models/document-tag.enum";
import { VisitAgActionsCell } from "../../shared/visit-ag-actions-cell/visit-ag-actions-cell.component";
import { States } from "./nursingSummary.constants";

@Component({
    selector: "aspn-patient-nursing-summary",
    templateUrl: "./nursingSummary.component.html",
})
export class NursingSummaryComponent implements OnInit {
    @ViewChild("visitProceduresApproveModalTmp", { read: TemplateRef }) visitProceduresApproveModalTmp: TemplateRef<any>;
    @ViewChild("editVisistDialogTmp", { read: TemplateRef }) editVisistDialogTmp: TemplateRef<any>;
    @ViewChild("cancelVisistDialogTmp", { read: TemplateRef }) cancelVisistDialogTmp: TemplateRef<any>;
    @ViewChild("scheduledVisitsActionButtonsTmp", { read: TemplateRef }) scheduledVisitsActionButtonsTmp: TemplateRef<any>;

    constructor(private dpAspnContextService: DpAspnContextService,
        private modalService: BsModalService,
        private params: RouteParams,
        private router: Router,) { }


    private patienId: string;
    nursingNeedsHistoryUrl: string;
    activityAndHomeEnvironmentHistoryUrl: string;
    nursingAndCarePlanHistoryUrl: string;
    healthAssesmentHistoryUrl: string;
    allVisitsUrl: string;
    patientAppointmentsUrl: string;
    visitProceduresApproveModalRef?: BsModalRef;
    editVisistDialogRef?: BsModalRef;
    cancelVisistDialogRef?: BsModalRef;

    public visitProceduresFG = new FormGroup({
        procedures: new FormArray([]),
    });

    private agGridApi: GridApi;

    async ngOnInit(): Promise<void> {
        this.patienId = this.params.patientId;
        this.nursingNeedsHistoryUrl = "/dp/patients/" + this.patienId + "/aspn/nusingNeedsHistory";
        this.activityAndHomeEnvironmentHistoryUrl = "/dp/patients/" + this.patienId + "/aspn/activityAndHomeEnvironmentHistory";
        this.nursingAndCarePlanHistoryUrl = "/dp/patients/" + this.patienId + "/aspn/nursingAndCarePlanHistory";
        this.healthAssesmentHistoryUrl = "/dp/patients/" + this.patienId + "/aspn/healthAssesmentHistory";
        this.allVisitsUrl = "/dp/patients/" + this.patienId + "/aspn/allVisits";
        this.patientAppointmentsUrl = "/dp/patients/" + this.patienId + "/aspn/patientAppointments";

        this.visitProceduresFG.valueChanges.subscribe((val) => {
            (<FormGroup[]>(<FormArray>this.visitProceduresFG.get("procedures")).controls).forEach((fg) => {
                fg.get("procedureState").updateValueAndValidity({ onlySelf: false, emitEvent: false });
                fg.get("cancelNote").updateValueAndValidity({ onlySelf: false, emitEvent: false });
            });
        });

    }

    getOptions(tag: DocumentTagEnum | string, id: string) {
        return {
            ...AgGridCommonConfig,
            onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
                this.router.navigate(["/dp/patients/" + this.patienId + "/aspn/" + tag + "/" + event.data[id] + "/view"])
            },
        }
    }

    nursingNeedsGridOptions = this.getOptions(DocumentTagEnum.spnk, "id")
    healthStatusGridOptions = this.getOptions(DocumentTagEnum.sbvf, "id")
    nursingServiceOrdersGridOptions = this.getOptions(DocumentTagEnum.spu, "orderId")
    nursingAndCarePlanGridOptions = this.getOptions(DocumentTagEnum.spp, "id")
    environmentalAssessmentGridOptions = this.getOptions(DocumentTagEnum.vnapvf, "id")
    scheduledVisitsGridOptions = {
        ...AgGridCommonConfig,
        onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
            this.visitProceduresOpenApproveModal(
                event.data.visitId, event.data.orderProcedures, event.data.visitProcedures
            );
        },
    }

    public nursingNeedsColDefs: ColDef[] = [
        { field: "assessmentDate", headerName: "Vertinimo data", minWidth: 130 },
        {
            field: "assessingPractitioner", headerName: "Vertinantis gydytojas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        { field: "aspnDate", headerName: "ASPN paskirta nuo" },
        { field: "finalScore", headerName: "Galutinis vertinimas" },
        {
            minWidth: 130,
            field: "actionButton",
            headerName: "Kopijuoti duomenų rinkinį",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: (params.data.status !== 'Cancelled' && params.data.status !== 'Signed') ? true : false,
                    viewable: false,
                    copyable: true,
                    urlPathEdit: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spnk}/` + params.data.id,
                    urlPathCopy: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spnk}/` + params.data.id
                }
            }
        }
    ];

    public healthStatusColDefs: ColDef[] = [
        { field: "assessmentDate", headerName: "Data", minWidth: 130 },
        { field: "measurements.temperature", headerName: "Temp." },
        { field: "measurements.heartRate", headerName: "Pulsas" },
        {
            field: "bloodPressure", headerName: "AKS", valueFormatter: (params) => {
                return params.data.measurements.bloodPressureDiastolic + " / " + params.data.measurements.bloodPressureSystolic;
            },
        },
        { field: "breathSystem.breathingRate", headerName: "Kvėpavimas" },
        { field: "measurements.bloodOxygenSaturation", headerName: "SpO2" },
        { field: "measurements.glucose", headerName: "Gliukozė" },
        {
            field: "pain", headerName: "Skausmas", valueFormatter: (params) => {
                const painAssesments = {
                    "Krūtinės ląstos skausmas": params.data.cardioSystem.chestPainScale,
                    "Galvos skausmas": params.data.cardioSystem.headache,
                    "Pilvo skausmas": params.data.digestiveSystem.abdominalPainScale,
                    "Sąnarių skausmas": params.data.jointsSystem.jointPainScale,
                    "Žaizdų skausmas": params.data.leatherSystem.woundPainScale
                };
                const maxPain = Math.max(...Object.values(painAssesments));
                let txt: string = '';
                for (var i in painAssesments) {
                    if (painAssesments[i] == maxPain) {
                        if (txt != '') { txt += ', ' }
                        txt += i + ' ' + painAssesments[i]
                    }
                }

                return txt;
            },
        },
        { field: "leatherSystem.risk.riskOfLeaks", headerName: "Pragulos" },
        {
            minWidth: 130,
            field: "actionButton",
            headerName: "Kopijuoti duomenų rinkinį",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: (params.data.status !== 'Cancelled' && params.data.status !== 'Signed') ? true : false,
                    viewable: false,
                    copyable: true,
                    urlPathEdit: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.sbvf}/` + params.data.id,
                    urlPathCopy: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.sbvf}/` + params.data.id
                }
            }
        }
    ];

    public nursingServiceOrdersColDefs: ColDef[] = [
        { field: "appointmentDate", headerName: "Paskyrimo data", minWidth: 130 },
        {
            field: "status",
            headerName: "Būsena",
            valueFormatter: (params) => {
                return States[params.value];
            },
            cellRenderer: statusCellRender,
            //cellStyle: { justifyContent: 'center' }
        },
        {
            field: "urgent", width: 100, headerName: "Skuba", cellRenderer: (params) => urgentCellRender(params, "procedures"), cellStyle: { justifyContent: 'center' }
        },
        {
            field: "appointmentPractitioner", headerName: "Specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            field: "procedures",
            width: 130,
            headerName: "Procedūros",
            cellRenderer: proceduresCellRender,
            cellStyle: { justifyContent: 'center' }
        },
        {
            field: "orderPractitioner", headerName: "Paskyręs specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            minWidth: 130,
            field: "actionButton",
            headerName: "Planuoti apsilankymą pagal",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    createable: (params.data.status !== 'Fulfilled' && params.data.status !== 'Cancelled') ? true : false,
                    urlPathCreate: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spu}/` + params.data.orderId
                }
            }
        },
    ];

    public scheduledVisitsColDefs: ColDef[] = [
        { field: "visitDate", headerName: "Apsilankymo data", minWidth: 130 },
        { field: "status", headerName: "Būsena", cellRenderer: statusCellRender },
        {
            field: "urgent", width: 100, headerName: "Skuba",
            cellRenderer: (params) => urgentCellRender(params, "visitProcedures"),
            cellStyle: { justifyContent: 'center' }
        },
        {
            field: "visitProcedures",
            width: 130,
            headerName: "Procedūros",
            cellRenderer: proceduresCellRender,
            cellStyle: { justifyContent: 'center' }
        },
        {
            field: "visitPractitioner", headerName: "Specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            minWidth: 130,
            field: "actionButton",
            headerComponent: AgColHeaderLink,
            headerComponentParams(params) {
                return {
                    linkString: "+ Kurti naują apsilankymą",
                    routerLinkTo: "/dp/aspn/spv/1",
                }
            },
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: VisitAgActionsCell,
            cellRendererParams(params) {
                return {
                    visitId: params.data.visitId,
                    periodVisitId: params.data.periodVisitId
                }
            }
        }
    ];

    public nursingAndCarePlanColDefs: ColDef[] = [
        { field: "createDate", headerName: "Sudarymo data" },
        {
            field: "practitioner", headerName: "Specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        {
            headerName: "Nustatyta slaugos problemų",
            field: "problemCount",
            valueFormatter: (params) => {
                return params.data.nursingSolutions.length
            },
        },
        {
            minWidth: 130,
            field: "actionButton",
            headerName: "Kopijuoti duomenų rinkinį",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: (params.data.status !== 'Cancelled' && params.data.status !== 'Signed') ? true : false,
                    viewable: false,
                    copyable: true,
                    urlPathEdit: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spp}/` + params.data.id,
                    urlPathCopy: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.spp}/` + params.data.id
                }
            }
        }
    ];

    public environmentalAssessmentColDefs: ColDef[] = [
        { field: "assessmentDate", headerName: "Vertinimo data", minWidth: 130 },
        {
            field: "assessingPractitioner", headerName: "Vertinantis specialistas", valueFormatter: (params) => {
                var txt: string = "";
                params.value.qualificationList.forEach(function (item) {
                    if (item.typeCode === "valid") {
                        txt += item.name + "\n"
                    }
                })

                txt += params.value.givenName + " " + params.value.familyName;

                return txt
            },
            cellStyle: { whiteSpace: 'normal' }
        },
        { field: "nextAssessmentDate", headerName: "Kitas vertinimas" },
        {
            minWidth: 130,
            field: "actionButton",
            headerName: "Kopijuoti duomenų rinkinį",
            cellClass: 'ag-right-aligned-cell',
            type: 'rightAligned',
            cellStyle: { "direction": "rtl" },
            cellRenderer: AgActionsCell,
            cellRendererParams(params) {
                return {
                    editable: (params.data.status !== 'Cancelled' && params.data.status !== 'Signed') ? true : false,
                    viewable: false,
                    copyable: true,
                    urlPathEdit: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.vnapvf}/` + params.data.id,
                    urlPathCopy: `/dp/patients/${params.context.patientId}/aspn/${DocumentTagEnum.vnapvf}/` + params.data.id
                }
            }
        }
    ];

    public visitProceduresColDefs2: ColDef[] = [
        {
            field: "procedures",
            headerName: "Procedūros",
        },
        // {
        //     field: "order",
        //     headerName: "Paskyrimas",
        //     cellRenderer: (param) => {
        //         if (param.value) {
        //             return `<a class="btn btn-link px-0" href="${param.value}" role="button"  target="_blank" >${param.linkString}</a>`;
        //         } else {
        //             return ``;
        //         }
        //     },
        //     cellRendererParams: {
        //         linkString: "Atidaryti",
        //     },
        // },
        {
            field: "urgent",
            headerName: "Skuba",
            width: 60,
            cellRenderer: (params) => {
                if (params.value) {
                    return `<span class="position-absolute translate-middle p-2 bg-danger rounded-circle">
                            <span class="visually-hidden">${params.value}</span>
                        </span>`;
                }
            },
            cellStyle: { justifyContent: 'center' }
        },
        { field: "medicines", headerName: "Priemonės ir medikamentai" },
        {
            field: "moreInfo",
            headerName: "",
            cellRenderer: AgGridModalOpenLinkCell,
            cellRendererParams: {
                linkTitle: "Daugiau",
            },
            width: 90,
        },
        {
            field: "procedureState",
            headerName: "Procedūros atlikimas",
            cellRenderer: AgGridSelectFgCell,
            width: 110,
        },
        {
            field: "cancelNote",
            headerName: "Neatlikimo priežastis",
            cellRenderer: AgGridInputFgCell,
            width: 110,
        },
    ];

    public defaultColDef: ColDef = {
        sortable: false,
        filter: false,
        autoHeight: true,
        wrapText: true,
        suppressMenu: true,
    };

    public nursingNeedsData$!: Observable<any[]>;
    public healthStatusData$!: Observable<any[]>;
    public scheduledVisitsData$!: Observable<any[]>;
    public nursingServiceOrdersData$!: Observable<any[]>;
    public nursingAndCarePlanData$!: Observable<any[]>;
    public environmentalAssessmentData$!: Observable<any[]>;

    public visitProceduresGridData: any = [];

    onGridReady(params: GridReadyEvent) {
        this.nursingNeedsData$ = this.dpAspnContextService.getQuestionairesByPatientId(this.patienId);
        this.healthStatusData$ = this.dpAspnContextService.getHealthStatusByPatientId(this.patienId);
        this.scheduledVisitsData$ = this.dpAspnContextService.getScheduledVisitsByPatientId(this.patienId);
        this.nursingServiceOrdersData$ = this.dpAspnContextService.getNursingServiceOrdersByPatientId(this.patienId);
        this.environmentalAssessmentData$ = this.dpAspnContextService.getEnvironmentalAssessmentByPatientId(
            this.patienId
        );
        this.nursingAndCarePlanData$ = this.dpAspnContextService.getSummaryNursingAndCarePlanByPatientId(this.patienId);
    }


    refreshFormControls() {
        if (this.agGridApi) {
            this.createFormControls();
            this.agGridApi.refreshCells({ force: true });
        }
    }

    private createFormControls() {
        const visitProcedures = <FormArray>this.visitProceduresFG.controls["procedures"];
        visitProcedures.clear();
        this.agGridApi.forEachNode((rowNode: RowNode, i) => {
            visitProcedures.push(
                new FormGroup({
                    procedureId: new FormControl(rowNode.data.orderProcedureId),
                    planedVisit: new FormControl(rowNode.data.planedVisit),
                    procedureState: new FormControl(null, [this.visitProceduresApproveValidator]),
                    cancelNote: new FormControl("", [this.visitProceduresCancelNoteValidator]),
                })
            );
        });
    }

    private createKey(rowNodeData: any, columnName: string): any {
        return rowNodeData[columnName];
    }

    visitProceduresGetContext() {
        return {
            createKey: this.createKey,
            formGroupArray: this.visitProceduresFG.controls["procedures"],
            parentComp: this,
            formControlNameSelect: "procedureState",
            formControlNameInput: "cancelNote",
        };
    }

    visitProceduresOnGridReady(params: GridReadyEvent) {
        this.agGridApi = params.api;
        this.refreshFormControls();
        this.agGridApi.sizeColumnsToFit();
    }

    visitProceduresApproveValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (!control.value) {
                return { error: "Butinas" };
            }
        }
        return null;
    };

    visitProceduresCancelNoteValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        console.log("cancelNoteValidator --->", control);
        const visitProcedureFg = control.parent as FormGroup;
        if (visitProcedureFg) {
            if (visitProcedureFg.get("procedureState").value === "N" && !control.value) {
                return { error: "Butinas" };
            }
        }
        return null;
    };

    visitProceduresOpenApproveModal(visitId: number, orderProcedures: any[], visitProcedures: any[]) {
        this.visitProceduresGridData = [];

        orderProcedures.forEach((op) => {
            this.visitProceduresGridData.push({
                procedures: op.procedures.map((v) => v.name).join(", "),
                medicines: op.medicines.map((m) => m.name).join(", "),
                procedureState: "",
                cancelNote: "",
                urgent: op.urgent,
            });
        });
        visitProcedures.forEach((vp) => {
            this.visitProceduresGridData.push({
                procedures: vp.procedures.map((v) => v.name).join(", "),
                medicines: vp.medicines.map((m) => m.name).join(", "),
                procedureState: "",
                cancelNote: "",
                urgent: vp.urgent,
            });
        });

        this.visitProceduresApproveModalRef = this.modalService.show(this.visitProceduresApproveModalTmp, {
            initialState: { visitId: visitId },
            class: "modal-xl",
        });
    }

    visitProceduresSubmit(visitId) {
        console.log("submitVisitAsDone", visitId, this.visitProceduresFG.valid);
        this.agGridApi.forEachNode((node) => {
            console.log("node -->", node.data);
        });
        if (this.visitProceduresFG.valid) {
            this.visitProceduresApproveModalRef && this.visitProceduresApproveModalRef.hide();
        }
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    getContext() {
        return {
            patientId: this.patienId,
        };
    }
}

function statusCellRender(params: ValueFormatterParams) {
    if (params.value === "Active" || params.value === "Planned")
        return `<span class="badge rounded-pill text-bg-primary text-white" style="width: 120px;">${States[params.value]}</span>`;
    if (params.value === "Cancelled")
        return `<span class="badge rounded-pill text-bg-secondary text-white" style="width: 120px;">${States[params.value]}</span>`;
    if (params.value === "Fulfilled" || params.value === "Visited")
        return `<span class="badge rounded-pill text-bg-success text-white" style="width: 120px;">${States[params.value]}</span>`;
}

function urgentCellRender(params: ValueFormatterParams, proceduresField: string) {

    for (let i of params.data[proceduresField]) {
        if (i.urgent) {
            return `<span class="position-absolute translate-middle p-2 bg-danger rounded-circle">
                        <span class="visually-hidden">${i.urgent}</span>
                    </span>`;
        }
    }
}

function proceduresCellRender(params: ValueFormatterParams) {
    var txt = "";
    for (var p of params.value) {
        txt = p.procedures.map((m) => m.name).join(", ");
        // for (var n of p.procedures) {
        //     if (txt != "") { txt += ", " }
        //     txt += n.name
        // }
    }
    return `<span class="badge rounded-pill text-bg-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="${txt}">?</span>`;
}