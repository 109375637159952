import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../config/config.service";
import { RiskFactorModel } from "../../doctor/models/risk-factor.model";
import { FilteredDataModel } from "../models/filtered-data.model";

@Injectable({
    providedIn: "root",
})
export class ApiV2Risks extends ConfigService {
    path = this.apiUrl + "/observations/risk-factors";

    getRisksList(data?: any): Observable<FilteredDataModel> {
        const params = {
            page: data?.page ? data.page : 1,
            patient: data.patient,
            issuedFrom: data.fromDate,
            issuedTo: data.toDate,
            assessmentScore: data.assessmentScore ? String(data.assessmentScore) : null,
            practitioner: data.practitioner,
            riskFactor: (data.riskFactors || []).map((f) => f.name),
        };

        for (const key in params) {
            if (
                params.hasOwnProperty(key) &&
                (params[key] === null || params[key] === undefined || params[key] === "" || params[key]?.length === 0)
            ) {
                delete params[key];
            }
        }

        const url = `${this.path}/list`;
        return this.http.get<FilteredDataModel>(url, { params });
    }

    updateRiskFactor(riskFactor: Partial<RiskFactorModel>): Observable<RiskFactorModel> {
        const url = `${this.path}/${riskFactor?.id}`;
        return this.http.put<RiskFactorModel>(url, riskFactor);
    }
}
