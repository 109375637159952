import {Component, OnInit} from '@angular/core';
import {PortalTypeEnum} from "../../../../shared/models/portalType.enum";

@Component({
    selector: 'dp-doc-ref-consent-view',
    templateUrl: './dp-doc-ref-consent-view.component.html'
})
export class DpDocRefConsentViewComponent implements OnInit{

    portalType: PortalTypeEnum = PortalTypeEnum.DP;

    ngOnInit(): void {
    }
}
