import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiV2DocRefConsentTemplate} from "../../../../../api/api-v2-doc-ref-consent-template";
import {DocRefConsentDocStatusTranslationsEnum} from "../../../models/doc-ref-consent-docStatus-translations.enum";
import {DocRefConsentCategoryModel} from "../../../models/doc-ref-consent-category.model";
import {PatientModel} from "../../../models/patient.model";
import {PractitionerModel} from "../../../models/practitioner.model";
import {map, Observable, Observer, of, switchMap} from "rxjs";
import {PatientSearchModel} from "../../../models/patient-search.model";
import {ApiV2Patients} from "../../../services/api-v2-patients";
import {ApiV2DocRefConsent} from "../../../../../api/api-v2-doc-ref-consent";
import {FilteredDataModel} from "../../../models/filtered-data.model";
import {ApiV2Practitioners} from "../../../../../api/api-v2-practitioners";

@Component({
    selector: 'doc-ref-consent-filter',
    templateUrl: './doc-ref-consent-filter.component.html'
})
export class DocRefConsentFilterComponent implements OnInit {
    protected readonly DocRefConsentDocStatusTranslationsEnum = DocRefConsentDocStatusTranslationsEnum;
    @Input() form: FormGroup;
    @Output() onSearch = new EventEmitter<any>();
    categories: DocRefConsentCategoryModel[];
    procedures: any[];
    initiator: PractitionerModel[];
    patients: PatientModel[];
    patientsSuggestions$?: Observable<PatientSearchModel[]>;
    initiatorsSuggestions$?: Observable<any[]>;
    proceduresSuggestions$?: Observable<any[]>;

    patientField: string = "patient";
    patientControl: FormControl;
    selectedPatientId: any;

    procedureField: string = "procedure";
    procedureControl: FormControl;
    selectedProcedureId: any;

    initiatorField: string = "initiator";
    initiatorControl: FormControl;
    selectedInitiatorId: any;

    constructor(
        private formBuilder: FormBuilder,
        private apiV2DocRefConsentTemplate: ApiV2DocRefConsentTemplate,
        private apiV2DocRefConsent: ApiV2DocRefConsent,
        private apiV2Patients: ApiV2Patients,
        private apiV2Practitioners: ApiV2Practitioners
    ) {
        this.form = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.initializeForm();
        this.initializePatientsSuggestions();
        this.initializeInitiatorsSuggestions();
        this.initializeProceduresSuggestions();

        this.apiV2DocRefConsentTemplate.getCategories().subscribe(data => {
            this.categories = data;
        });
    }

    initializeForm() {
        this.patientControl = new FormControl(null, [Validators.required]);
        this.procedureControl = new FormControl(null, [Validators.required]);
        this.initiatorControl = new FormControl(null, [Validators.required]);

        this.form.addControl("category", new FormControl(null));
        this.form.addControl(this.patientField, this.patientControl);
        this.form.addControl(this.procedureField, this.procedureControl);
        this.form.addControl(this.initiatorField, this.initiatorControl);
    }

    initializePatientsSuggestions() {
        this.patientsSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.patientControl.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Patients.search({q: query})
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(psm => this.getPersonCredentials(psm)) || []));
                }
                return of([]);
            })
        );
    }

    initializeProceduresSuggestions() {
        this.proceduresSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.procedureControl.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2DocRefConsent.getProcedures({q: query})
                        .pipe(map((data: any[]) => data && data.map(psm => psm.value) || []));
                }
                return of([]);
            })
        );
    }

    initializeInitiatorsSuggestions() {
        this.initiatorsSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.initiatorControl.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners.find({q: query})
                        .pipe(map((data: FilteredDataModel) => data && data.items.map(psm => this.getPersonCredentials(psm)) || []));
                }
                return of([]);
            })
        );
    }

    changePatient(event: any) {
        this.selectedPatientId = event?.item.id;
    }

    changeProcedure(event: any) {
        this.selectedProcedureId = event?.item.id;
    }

    changeInitiator(event: any) {
        this.selectedInitiatorId = event?.item.id;
    }

    onSearchClick(event) {
        console.log(event)
        this.onSearch.emit(event);
    }

    getPersonCredentials(person: PatientModel | PractitionerModel) {
        return (person.namePrefix ? person.namePrefix + " " : "") + person.givenName + " " + person.familyName + " " + person.personalCode;
    }
}