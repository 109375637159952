<form class="my-2" [formGroup]="formV2" [name]="formName" autocomplete="on">
    <div class="sveik-main-search input-group" search-button-group [buttonType]="'submit'" [working]="loading" [isAdvanced]="isAdvanced" (quickClick)="onQuick()" (advancedClick)="onAdvanced()"
         (clearClick)="onClear()">
        <input autocomplete="on"
               [id]="name"
               [name]="name"
               [formControlName]="name"
               [disabled]="loading"
               [(ngModel)]="form[propertyName ? propertyName : 'q']"
               class="form-control"
               type="text"
               placeholder="{{placeholder ? placeholder : 'ap.med.dev.lis.fill' | translate }}"
               title="{{ 'quicksearch' | translate }}"
               [ariaLabel]="'quicksearch' | translate"
        />
    </div>
</form>