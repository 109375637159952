ng1App.directive('sveidraFormStatus', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/parts/form_status.html',
		restrict: 'E',
		scope: {
			form: '=',
			showSeparator: '@'
		},
		link: function (scope, element, attrs) {
		}
	};
});

ng1App.directive('sveidraCardTitle', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/parts/card_title.html',
		restrict: 'E',
		scope: {
			card: '='
		},
		link: function (scope, element, attrs) {
		}
	};
});

ng1App.directive('sveidraCardInfo', function () {
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/parts/card_info.html',
		restrict: 'E',
		scope: {
			card: '='
		},
		link: function (scope, element, attrs) {
		}
	};
});