<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ge.formLabor.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row">
            <div class="row mx-0 px-0 col-12 col-lg-3 req">
                <div class="form-group col-6 col-lg-12">
                        <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold">
                            {{'doc.pregnancyDashboard.e096Ge.formLabor.childBirthStart' | translate}}:
                        </span>
                    <div class="input-group gap-3">
                        <app-radio-button
                            *ngFor="let option of laborBaginningOptions; let i = index"
                            radioName="laborInitMethod-{{i}}"
                            [fControl]="formGroup.get('laborInitMethod')"
                            [radioValue]="option.name"
                            id="laborInitMethod-{{i}}"
                            class="col-auto my-2"
                            formClass="mb-0"
                            [labelText]="option.name"
                        ></app-radio-button>
                        <app-form-control-validation [fControl]="formGroup.get('laborInitMethod')" />
                    </div>
                </div>
                <div class="form-group col-6 col-lg-12" *ngIf="getCntrlValue('laborInitMethod') === 'Sužadinta'">
                    <span class="form-label flex-grow-1 col-form-label mt-3 mt-lg-0 fw-bold norq">
                        {{'doc.pregnancyDashboard.e096Ge.formLabor.aroused' | translate}}:
                    </span>
                    <app-checkbox
                        *ngFor="let option of answersVariants?.['laborOnsetMethod']; let i = index"
                        inputId="laborOnsetMethods{{i}}"
                        class="mt-1"
                        formClass="mb-0"
                        [labelText]="option.name"
                        [checkboxValue]="option.name"
                        [checkboxDisabled]="!(editable && !controlDisabled('laborOnsetMethods'))"
                        [checkboxChecked]="checkboxIsChecked(option.name, 'laborOnsetMethods')"
                        (checkboxChange)="updateControl(option.name, 'laborOnsetMethods')"
                    ></app-checkbox>
                </div>
            </div>
            <div class="row mx-0 px-0 col-12 col-lg-9 req">
                <div class="form-group col-6 col-lg-4 flex-shrink-1">
                    <app-select
                        labelText="{{'doc.pregnancyDashboard.e096Ge.formLabor.gaveBirth' | translate}}:"
                        id="ge-born"
                        [fControl]="formGroup.get('born')"
                        [values]="selectionValuesFromEntity(answersVariants?.['modeOfDelivery'])"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('born')" />
                </div>
                <div
                    *ngIf="getCntrlValue('born') === 'Natūraliais takais, taikant pagalbines priemones'"
                    class="form-group col-6 col-lg-8 flex-shrink-1"
                >
                    <app-select
                        labelText="{{'doc.pregnancyDashboard.e096Ge.formLabor.assistanceMethod' | translate}}:"
                        id="ge-bornAssistanceMethod"
                        [fControl]="formGroup.get('bornAssistanceMethod')"
                        [values]="selectionValuesFromEntity(answersVariants?.['naturalChildbirthHelp'])"
                    ></app-select>
                    <app-form-control-validation [fControl]="formGroup.get('bornAssistanceMethod')" />
                </div>
            </div>
        </div>
        <div class="row req">
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-select
                    labelText="{{'doc.pregnancyDashboard.e096Ge.formLabor.robsonGroup' | translate}}:"
                    labelClass="form-label flex-grow-1 col-form-label content-box h-2lh"
                    id="robsonGroup"
                    [fControl]="formGroup.get('robsonGroup')"
                    [values]="selectionValuesFromEntity(answersVariants?.['robsonGroups'])"
                ></app-select>
                <app-form-control-validation [fControl]="formGroup.get('robsonGroup')" />
            </div>
            <div class="form-group col-6 col-lg-3 flex-shrink-1">
                <app-date-picker
                    labelText="{{'doc.pregnancyDashboard.e096Ge.formLabor.placentaTimeBirth' | translate}}:"
                    labelClass="form-label flex-grow-1 col-form-label content-box h-2lh"
                    inputId="placentaTimeDelivery"
                    suffixIcon="fa fa-calendar"
                    dateInputFormat="YYYY-MM-DD HH:mm"
                    [withTimepicker]="true"
                    [maxDate]="currentTime"
                    [fControl]="formGroup.get('placentaTimeDelivery')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('placentaTimeDelivery')" />
            </div>
            <div class="form-group col-6 col-lg-3">
                    <span class="form-label flex-grow-1 col-form-label mt-3 fw-bold norq">
                        {{'doc.pregnancyDashboard.e096Ge.formLabor.medicinalPainRelief' | translate}}:
                    </span>
                <app-checkbox
                    *ngFor="let option of answersVariants?.['childbirthMedicalPainManagement']; let i = index"
                    inputId="medicinalPainRelief{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable && !controlDisabled('medicinalPainRelief'))"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'medicinalPainRelief')"
                    (checkboxChange)="updateControl(option.name, 'medicinalPainRelief')"
                ></app-checkbox>
            </div>
            <app-input
                type="textarea"
                class="form-group col-6 col-lg-3"
                labelText="{{'doc.pregnancyDashboard.e096Ge.formLabor.nonMedicinalPainRelief' | translate}}:"
                labelClass="norq"
                [fControl]="formGroup.get('nonMedicinalPainRelief')"
            ></app-input>
        </div>
        <div class="row mt-3 mt-lg-0 req">
            <div class="form-group col-6 col-lg-3">
                <span class="form-label flex-grow-1 col-form-label fw-bold">
                    {{'doc.pregnancyDashboard.e096Ge.formLabor.antibiotics' | translate}}:
                </span>
                <div class="input-group gap-3">
                    <app-radio-button
                        *ngFor="let option of antibioticsOptions; let i = index"
                        radioName="ge-antibiotics-{{i}}"
                        [fControl]="formGroup.get('antibiotics')"
                        [radioValue]="option.name"
                        id="ge-antibiotics-{{i}}"
                        class="col-auto"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                </div>
            </div>
            <div class="form-group col-6" *ngIf="getCntrlValue('antibiotics') === 'Skirta'">
                <span class="form-label flex-grow-1 col-form-label fw-bold norq">
                    {{'doc.pregnancyDashboard.e096Ge.formLabor.antibioticsPrescribed' | translate}}:
                </span>
                <div class="row px-0 mx-auto">
                    <div class="col-6">
                        <app-checkbox
                            class="mt-1"
                            formClass="mb-0"
                            [inputId]="'antibiotics-for-profilactic'"
                            [labelText]="'doc.pregnancyDashboard.e096Ge.formLabor.prophylactically'"
                            [fControl]="formGroup.get('antibioticsForProphylacticPurposes')"
                        ></app-checkbox>
                        <ng-container *ngIf="formGroup.get('antibioticsForProphylacticPurposes').value">
                            <app-checkbox
                                *ngFor="let option of answersVariants?.['childbirthAntibioticsProphylacticalPrescriptionReason']; let i = index"
                                id="ge-antibioticsPrescribedProphylactically{{i}}"
                                class="mt-1"
                                formClass="mb-0"
                                [labelText]="option.name"
                                [checkboxValue]="option.name"
                                [checkboxDisabled]="!(editable && !controlDisabled('antibioticsPrescribedProphylactically'))"
                                [checkboxChecked]="checkboxIsChecked(option.name, 'antibioticsPrescribedProphylactically')"
                                (checkboxChange)="updateControl(option.name, 'antibioticsPrescribedProphylactically')"
                            ></app-checkbox>
                            <app-input
                                type="textarea"
                                labelText="doc.pregnancyDashboard.e096Ge.formLabor.prophylacticallyOther"
                                labelClass="fw-light mt-0 norq"
                                [textAreaRows]="2"
                                [fControl]="formGroup.get('otherAntibioticsPrescribedProphylactically')"
                            ></app-input>
                        </ng-container>
                    </div>
                    <div class="col-6">
                        <app-checkbox
                            class="mt-1"
                            formClass="mb-0"
                            [inputId]="'antibiotics-for-purpose-of-treatment'"
                            [labelText]="'doc.pregnancyDashboard.e096Ge.formLabor.purposeOfTreatment'"
                            [fControl]="formGroup.get('antibioticsIntendedForTreatment')"
                        ></app-checkbox>
                        <ng-container *ngIf="formGroup.get('antibioticsIntendedForTreatment').value">
                            <app-checkbox
                                *ngFor="let option of answersVariants?.['childbirthAntibioticsTreatmetPrescriotionReason']; let i = index"
                                inputId="ge-purposesOfTreatment{{i}}"
                                class="mt-1"
                                formClass="mb-0"
                                [labelText]="option.name"
                                [checkboxValue]="option.name"
                                [checkboxDisabled]="!(editable && !controlDisabled('purposesOfTreatment'))"
                                [checkboxChecked]="checkboxIsChecked(option.name, 'purposesOfTreatment')"
                                (checkboxChange)="updateControl(option.name, 'purposesOfTreatment')"
                            ></app-checkbox>
                            <app-input
                                type="textarea"
                                labelText="doc.pregnancyDashboard.e096Ge.formLabor.otherPurposeOfTreatment"
                                labelClass="fw-light mt-0 norq"
                                [textAreaRows]="2"
                                [fControl]="formGroup.get('otherPurposesOfTreatment')"
                            ></app-input>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>