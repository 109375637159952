<app-title [backPath]="backUrl" [backTitle]="'dpp.desktop'| translate" backSubtitle="pp.dia.sea.title" showFilterButton="true">
    {{ "doc.healthAssessment.subTitle" | translate }}
</app-title>
<div class="widget">
    <app-page-filters [options]="filterOptions" (filtering)="updateFilters($event)"></app-page-filters>
    <div class="widget-body" [class]="risksHistory?.length ? ' p-0' : ''">
        <ol *ngIf="risksHistory?.length !== 0" class="slats">
            <li
                *ngFor="let risk of risksHistory; let i = index"
                slat-item edit
                (editButtonClick)="openEditHealthFactorModal(i)"
            >
                <div class="row flex-wrap-reverse flex-sm-wrap">
                    <div class="col-sm-auto text-sm-center text-secondary text-nowrap mb-1 mb-sm-0">
                        {{ risk.date | date : "YYYY-MM-dd" }}
                    </div>
                    <div class="col ps-sm-0 mb-sm-1">
                        <strong>
                            {{ risk.riskFactor?.name }}
                        </strong>
                    </div>
                </div>
                <div class="small text-secondary">
                    <div class="details my-1">
                        <span class="me-1">
                            <span>{{ "doc.healthAssessment.score" | translate }}: </span>
                            <ng-container *ngIf="risk.assessmentScore">
                                <strong class="text-body">{{ risk.assessmentScore }}</strong>
                            </ng-container>
                            <ng-container *ngIf="!risk.assessmentScore">
                                <strong class="text-body">-</strong>
                            </ng-container>
                        </span>
                        <span class="vr"></span>
                        <span class="mx-1">
                            <ng-container *ngIf="risk.riskFactorMethodologyName">
                                <strong class="text-body">{{ risk.riskFactorMethodologyName }}</strong>
                            </ng-container>
                            <ng-container *ngIf="!risk.riskFactorMethodologyName">
                                <strong class="text-body">-</strong>
                            </ng-container>
                        </span>
                    </div>
                    <div class="description">
                        {{ "doc.healthAssessment.form.assessmentDesc" | translate }}:
                        <span>{{ risk?.comments ?? "-" }}</span>
                    </div>
                </div>
            </li>
        </ol>
        <ng-container *ngIf="!risksHistory?.length">
            <span>
                {{ (risksFullHistory?.length ? "doc.all.noRiskFactorsForFilter" : "doc.healthAssessment.page.noRiskFactors") | translate }}</span>
        </ng-container>
    </div>
</div>

<pagination-bar *ngIf="risksHistory?.length !== 0" [rowsCount]="count" [pagesCount]="pagesCount"
                (paginating)="updatePagination($event)"></pagination-bar>
