import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
    selector: 'app-e030-treatment-applied',
    templateUrl: './e030-treatment-applied.component.html',
})
export class E030TreatmentAppliedComponent implements OnInit {
    @Input() editable: boolean = true;
    formGroup: FormControl;

    constructor(
        private readonly fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.fb.control(null);
    }
}
