ng1App.factory('AdminToolsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {

        var URL_BASE = apiUrl + '/tools';
        return $resource('', {}, {
            updatePatientData: {
                method: 'POST',
                url: URL_BASE + '/patientUpdaterTool/update?personalCode=:personalCode',
                isArray: false
            },
            reindexImportedData: {
                method: 'POST',
                url: URL_BASE + '/patientUpdaterTool/reindexImportedData?personalCode=:personalCode',
                isArray: false
            }
        });
    }]
);