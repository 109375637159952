<nhr-record-widget
    [closed]="false"
    [title]="'inr.drugForm.doctorInstitution' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <ng-container *ngIf="editable">
            <div class="row mb-3">
                <div class="input-group">
                    <app-radio-button
                        *ngFor="let option of drugsSelectionVariants; let i = index"
                        radioName="drugsSelectionVariant-{{i}}"
                        [fControl]="drugsSelectionVariant"
                        [radioValue]="option.name"
                        id="drugsSelectionVariant-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="drugsSelectionVariant" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.drugForm.drugSearch' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    <app-find-and-prefill-info
                        [obs]="getDrugsObs"
                        [disable]="!drugsSelectionVariant.value || drugsSelectionVariant.value === 'Įvesti pačiam vaistinio preparato duomenis'"
                        (selectData)="selectDrug($event)"
                    />
                </div>
            </div>
            <hr>
        </ng-container>
        <div class="row mb-3 req">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.medicinalProductName' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('medicinalProductName')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('medicinalProductName')" />
            </div>
        </div>
        <div class="row mb-3 req">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.activeSubstanceName' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('activeSubstanceName')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('activeSubstanceName')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.pharmaceuticalForm' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('pharmaceuticalForm')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.strength' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('strength')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.administrationMethod' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('administrationMethod')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.dailyDose' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('dailyDose')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.medicinalProductSerialNumber' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('medicinalProductSerialNumber')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.startedUsingDate' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-date-picker
                    inputId="startedUsingDate"
                    suffixIcon="fa fa-calendar"
                    [maxDate]="maxDate"
                    [withTimepicker]="true"
                    [fControl]="formGroup.get('startedUsingDate')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('startedUsingDate')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.endUsingDate' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-date-picker
                    inputId="endUsingDate"
                    suffixIcon="fa fa-calendar"
                    [maxDate]="maxDate"
                    [withTimepicker]="true"
                    [fControl]="formGroup.get('endUsingDate')"
                ></app-date-picker>
                <app-form-control-validation [fControl]="formGroup.get('endUsingDate')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.drugForm.therapeuticIndications' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('therapeuticIndications')"
                ></app-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">
                    {{'inr.drugForm.reactionCausedByOtherDrugs' | translate}}
                </strong>
            </div>
            <div class="col-md-8 col-lg-9 d-flex justify-content-center flex-column">
                <app-radio-button
                    *ngFor="let option of yesNoOptions; let i = index"
                    radioName="reactionCausedByOtherDrugs-{{i}}"
                    [fControl]="formGroup.get('reactionCausedByOtherDrugs')"
                    [radioValue]="option.name"
                    id="reactionCausedByOtherDrugs-{{i}}"
                    class="w-100"
                    [labelText]="option.name"
                ></app-radio-button>
            </div>
        </div>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>