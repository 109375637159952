ng1App.controller('doctor.patient.PatientViewCtrl',
        ['$scope', '$routeParams', '$location', '$modal', 'PatientsApiFactory', 'MessageService', 'PatientViewCtrlPreparator', 'EncountersApiFactory',
        function ($scope, $routeParams, $location, $modal, PatientsApiFactory, MessageService, PatientViewCtrlPreparator, EncountersApiFactory) {

            $scope.$emit('setHelpPage', 'paciento-informacijos-perziros-puslapis');
            MessageService.clear();
            MessageService.showSuccessEventMessage();
            MessageService.unregisterSuccessEvent();

            PatientViewCtrlPreparator.prepare($scope);
            $scope.editUrl = '/dp/patients/' + $routeParams.patientId + '/edit';
            $scope.dashboardUrl = '/dp/patients/' + $routeParams.patientId;
            $scope.reload($routeParams.patientId);

            var modalMerge;
            var modalDisjoin;
            $scope.hideActions = true;
            $scope.showMergeMenu = false;
            EncountersApiFactory.hasActiveEncounter({patientId:$routeParams.patientId}, function (data){
                $scope.hideActions = !data.value;
            });

            $scope.merge = function () {
                $scope.mergeForm = {loaded: false};
                if (!modalMerge) {
                    $scope.findLithuanianPatient = function (personalCode) {
                        var stop = function () {
                            $scope.mergeForm.searching = false;
                        };
                        $scope.mergeForm.searching = true;
                        $scope.mergeForm.foundPatient = PatientsApiFactory.getByPersonalOrEsiNr({personalCode: personalCode, onlyLithuanianPC: true}, stop, stop);
                    };

                    $scope.mergePatients = function () {
                        var success = function () {
                            modalMerge.hide();
                            $scope.mergeForm.merging = false;
                            $location.path('dp/patients/' + $scope.mergeForm.foundPatient.id + '/view');
                            $scope.reload($scope.mergeForm.foundPatient.id);
                            MessageService.success('Pacientai sėkmingai sulieti.');
                        };
                        var fail = function (error) {
                            modalMerge.hide();
                            $scope.mergeForm.merging = false;
                            angular.forEach(error.data.globalErrors, function (err) {
                                MessageService.error(err.msg);
                            });
                        };
                        $scope.mergeForm.merging = true;
                        PatientsApiFactory.merge({patientId: $scope.patient.id, targetId: $scope.mergeForm.foundPatient.id}, {}, success, fail);
                    };

                    $scope.obPKChange = function () {
                        $scope.mergeForm.foundPatient = undefined;
                    };

                    modalMerge = $modal({
                        scope: $scope,
                        title: 'Sulieti paskyras',
                        contentTemplate: '/templates/portal/common/patient_info/merge_data.html'
                    });
                }

                $scope.cancelErr = 0;
                modalMerge.$promise.then(function () {
                    modalMerge.show();
                });
            };


            $scope.disjoin = function () {
                $scope.disjoinForm = {};
                if (!modalDisjoin) {
                    $scope.disjoinPatients = function (targetId) {
                        var success = function () {
                            modalDisjoin.hide();
                            $scope.disjoinForm.disjoining = false;

                            var index = -1;
                            for (var i = 0; i < $scope.patientWithResources.linkedPatients.length; i++) {
                                var lp = $scope.patientWithResources.linkedPatients[i];
                                if (targetId === lp.id) {
                                    index = i;
                                    break;
                                }
                            }
                            $scope.patientWithResources.linkedPatients.splice(index, 1);

                            MessageService.success('Suliejimas sėkmingai atšauktas.');
                        };
                        var fail = function () {
                            $scope.disjoinForm.disjoining = false;
                            modalDisjoin.hide();
                        };
                        $scope.disjoinForm.disjoining = true;
                        PatientsApiFactory.disjoin({patientId: $scope.patientId, targetId: targetId}, {}, success, fail);
                    };

                    modalDisjoin = $modal({
                        scope: $scope,
                        title: 'Pašalinti sulietą paskyrą',
                        contentTemplate: '/templates/portal/common/patient_info/disjoin_data.html'
                    });
                }

                $scope.cancelErr = 0;
                modalDisjoin.$promise.then(function () {
                    modalDisjoin.show();
                });
            };

        }]);