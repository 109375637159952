ng1App.factory('VaccinationsApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/vaccinations';
    return $resource('', {}, {
        generate: {
            method: 'POST',
            url: URL_BASE + '/generate'
        },
        preGenerate: {
            method: 'POST',
            url: URL_BASE + '/pre-generate'
        },
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        getSchemes: {
            method: 'GET',
            url: URL_BASE + '/schemes',
            isArray: true
        },
        getVaccination: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getMarkAsIgnored: {
            method: 'PUT',
            url: URL_BASE + '/:id/mark-ignored'
        },
        getSuggestedDate: {
            method: 'GET',
            url: URL_BASE + '/suggest-date'
        }
    });
}]);
