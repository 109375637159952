<nhr-record-widget
    [closed]="false"
    [title]="'inr.reportingPersonForm.title' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.doctorInformingForm.name' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                {{controlValue('fullName') ?? '-'}}
            </div>
        </div>
        <ng-container *ngIf="!patient">
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.reportingPersonForm.qualification' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    {{controlValue('qualification') ?? '-'}}
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3">
                    <strong class="col-form-label">{{'inr.reportingPersonForm.organization' | translate}}</strong>
                </div>
                <div class="col-md-8 col-lg-9">
                    {{controlValue('organization') ?? '-'}}
                </div>
            </div>
        </ng-container>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.reportingPersonForm.phone' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    type="tel"
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('phone')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('phone')" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label">{{'inr.doctorInformingForm.email' | translate}}</strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    type="email"
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('email')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('email')" />
            </div>
        </div>
        <ng-container *ngIf="!patient">
            <div class="row mb-3" *ngIf="editable">
                <strong>
                    {{'inr.reportingPersonForm.agreePersonalInfo' | translate}}:
                </strong>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-lg-3" *ngIf="!editable">
                    <strong class="col-form-label">{{'inr.reportingPersonForm.agreePersonalInfoConsent' | translate}}</strong>
                </div>
                <div [class.input-group]="editable"
                    class="col-md-8 col-lg-9"
                    [ngClass]="{
                        'offset-md-4 offset-lg-3': editable,
                    }">
                    <app-radio-button
                        *ngFor="let option of yesNoOptions; let i = index"
                        radioName="agreeSharePersonalData-{{i}}"
                        [fControl]="formGroup.get('agreeSharePersonalData')"
                        [radioValue]="option.name"
                        id="agreeSharePersonalData-{{i}}"
                        class="w-100"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('agreeSharePersonalData')" />
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="patient && editable">
            <div class="row mb-3">
                <strong>
                    Pastaba. Prašome nurodyti savo kontaktinius duomenis, kad Valstybinė vaistų kontrolės tarnyba prie Lietuvos
                    Respublikos sveikatos apsaugos ministerijos galėtų su Jumis susisiekti
                </strong>
            </div>
            <div class="row mb-3">
                <strong>
                    Pastaba. Pateikti asmens duomenys yra konfidencialūs ir bus naudojami tik su farmakologiniu budrumu
                    susijusiems uždaviniams spręsti ir
                    pareigoms vykdyti, vaistinių preparatų saugumui stebėti bei jų naudos ir rizikos santykio pokyčiams
                    nustatyti.
                </strong>
            </div>
        </ng-container>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>