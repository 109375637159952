import { Component, Input, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    addObjToFormArray,
    updateFormArray
} from '../../../../e096-utils/utils';
import { E096PartNewbornComponent } from '../e096-part-newborn/e096-part-newborn.component';

@Component({
    selector: 'app-e096-part-entries',
    templateUrl: './e096-part-entries.component.html',
})
export class E096PartEntriesComponent {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    @Input() fruitLength: string[];

    @ViewChildren(E096PartNewbornComponent) newbornComponents: E096PartNewbornComponent[];

    formGroup: FormArray = this.fb.array([]);
    editEntryId: number | null = null;
    newEntry: boolean = false;

    updFormArray = updateFormArray.bind(this) as typeof updateFormArray;

    get partogramIsInvalid() {
        return this.formGroup.parent?.get('partogram').invalid;
    }

    constructor(
        private readonly fb: FormBuilder,
    ) {
    }

    addNewEntry() {
        this.editEntryId = null;
        this.newEntry = true;
        this.formGroup.parent.get('partogram').get('numberOfFruits').markAsTouched();
    }

    addEntry(item: any) {
        if (this.editEntryId === null) {
            this.formGroup.insert(0, addObjToFormArray(item), {emitEvent: false});
            (this.formGroup.at(0) as FormGroup).removeControl('fetalRecords', {emitEvent: false});
            this.updFormArray(item, 'fetalRecords', (this.formGroup.at(0) as FormGroup));
        } else {
            (this.formGroup.at(this.editEntryId) as FormGroup).patchValue(item, {emitEvent: false});
        }

        this.editEntryId = null;
        this.newEntry = false;
    }

    removeEntry(i: number) {
        this.formGroup.removeAt(i, {emitEvent: false});
        this.editEntryId = null;
    }
}
