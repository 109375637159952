import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs";
import { ApiV2f058 } from "src/api/api-v2-doc-ref-f058";
import { ApiV2ProblemStatusClassifier } from "src/api/api-v2-doc-ref-problem-status-classifier";
import { ApiV2Entities } from "src/api/api-v2-entities";
import { DocumentTagEnum } from "../../../../shared/models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { EntityModel } from "src/app/shared/models/entity.model";
import { DirectiveReset } from "src/app/shared/services/directive-reset";
import { ApiV2Documents } from "../../../../../api/api-v2-documents";

@Component({
    selector: 'dp-doc-ref-f058',
    templateUrl: './doc-ref-f058-form.component.html'
})
export class DpDocRef058Component implements OnInit {

    backPath: string;
    patientId = null;
    form: FormGroup;
    currentDate = new Date();
    statusValue: string;
    problemTypeArray: string[];
    emitedValues = null;
    ConditionSeverityValue: string;
    InfectionsFamiliarityAnimalValue: string;
    InfectionsFormDiseaseValue: string;
    InfectionsPersonHospitalizationValue: string;
    documentTag = DocumentTagEnum.e027a;
    diagnosisArray = null;
    prefillData: any;

    ConditionSeverityArray: string[];
    InfectionsFamiliarityAnimalArray: string[];
    InfectionsFormDiseaseArray: string[];
    InfectionsPersonHospitalizationArray: string[];
    documentType: EntityModel = {
        id: "1",
        code: "1",
        name: "f058"
    };

    constructor(
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
        private fb: FormBuilder,
        private apiV2ProblemStatusClassifier: ApiV2ProblemStatusClassifier,
        private ApiV2ApiV2f058: ApiV2f058,
        private ApiV2Entities: ApiV2Entities,
        private directiveReset: DirectiveReset
    ) {
        this.form = this.fb.group({
            attendanceDate: [],
            gettingSickDate: [null, [Validators.required]],
            checkInDate: [null, [Validators.required]],
            //TODO this date will be taken from diagnosis
            diagnosisDate: [],
            reportDate: [null, [Validators.required]],
            hospitalized: [],
            //diagnosisStatus: [],
            reasonForDeath: [],
            TLK_10_AM: [],
            tumorDiagnosis: [],
            additionalDiagnosisCode: [],
            additionalDiagnosisName: [],
            relevantInformation: [],
            animalKnown: [],
            diseaseForm: [],
            writeIfUnknown: [],
            newInfo: [],
        });
    }

    ngOnInit(): void {
        this.getPatientId();
        //TODO fix paths accordingly
        this.backPath = `dp/patients/${this.patientId}/f090/list`;
        this.getDiagnosticStatusClassifierList();
        this.getConditionSeverity();
        this.getInfectionsFamiliarityAnimal();
        this.getInfectionsFormDisease();
        this.getInfectionsPersonHospitalization();
        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getPatientId() {
        this.patientId = this.params.patientId;
    }

    getDiagnosticStatusClassifierList() {
        this.ApiV2Entities.get('problem-status-classifier').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.problemTypeArray = response;
            }
        });
    }

    onChanges(event) {
        this.emitedValues = event;
    }

    onDiagnosisChanges(event) {
        this.diagnosisArray = event;
        if (event.length > 0) {
            this.form.controls['tumorDiagnosis'].setValue(event[0].name);
            this.form.controls['TLK_10_AM'].setValue(event[0].code);
            this.form.controls['diagnosisDate'].setValue(event[0].onsetDate);
            this.form.get('additionalDiagnosisCode').patchValue(null);
            this.form.get('additionalDiagnosisName').patchValue(null);
            if (event.length > 1) {
                this.form.controls['additionalDiagnosisCode'].setValue(event[1].name);
                this.form.controls['additionalDiagnosisName'].setValue(event[1].code);
            }
        }
    }

    getConditionSeverity() {
        this.ApiV2Entities.get('condition-severity-058').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.ConditionSeverityArray = response;
            }
        });
    }

    getInfectionsFamiliarityAnimal() {
        this.ApiV2Entities.get('infections-familiarity-animal').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.InfectionsFamiliarityAnimalArray = response;
            }
        });
    }

    getInfectionsFormDisease() {
        this.ApiV2Entities.get('infections-form-disease').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.InfectionsFormDiseaseArray = response;
            }
        });
    }

    getInfectionsPersonHospitalization() {
        this.ApiV2Entities.get('infections-person-hospitalization').pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.InfectionsPersonHospitalizationArray = response;
            }
        });
    }

    public saveForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const diagnosis = this.diagnosisArray;
        const mergeJSON = { ...formValues, ...prefillJson, diagnosis };
        console.log("mergeJSON", mergeJSON);
        //this.ApiV2ApiV2f058.savef058Form(mergeJSON, this.patientId).subscribe();
    }

    public submitForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2ApiV2f058.submitf058Form(mergeJSON, this.patientId).subscribe();
    }

    public signingSubmitForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2ApiV2f058.signingSubmitf058Form(mergeJSON, this.patientId).subscribe();
    }

    public sealConfirmForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2ApiV2f058.sealf058Form(mergeJSON, this.patientId).subscribe();
    }

    public signForm(): void {
        const formValues = { ...this.form.value };
        var prefillJson = this.gatherInfoFromEmittedData();
        const mergeJSON = { ...formValues, ...prefillJson };
        this.ApiV2ApiV2f058.signf058Form(mergeJSON, this.patientId).subscribe();
    }

    public clearForm(): void {
        this.form.get('attendanceDate').patchValue("");
        this.form.get('gettingSickDate').patchValue("");
        this.form.get('checkInDate').patchValue("");
        this.form.get('reportDate').patchValue("");
        this.form.get('hospitalized').patchValue(null);
        //this.form.get('diagnosisStatus').patchValue("");
        this.form.get('reasonForDeath').patchValue(null);
        // this.form.get('TLK_10_AM').patchValue("");
        // this.form.get('tumorDiagnosis').patchValue("");
        // this.form.get('additionalDiagnosisCode').patchValue("");
        // this.form.get('additionalDiagnosisName').patchValue("");
        this.form.get('relevantInformation').patchValue("");
        this.form.get('animalKnown').patchValue(null);
        this.form.get('diseaseForm').patchValue(null);
        this.form.get('writeIfUnknown').patchValue("");
        this.form.get('newInfo').patchValue("");
        this.directiveReset.triggerReset();
        this.form.get('tumorDiagnosis').patchValue(null);
        this.form.get('TLK_10_AM').patchValue(null);
        this.form.get('diagnosisDate').patchValue(null);
        this.form.get('additionalDiagnosisCode').patchValue(null);
        this.form.get('additionalDiagnosisName').patchValue(null);
    }

    gatherInfoFromEmittedData() {
        var encounterRef = this.emitedValues.encounter.fullId;
        var patientRef = this.emitedValues.encounter.patientRef;
        var patientData = this.emitedValues.encounter.patientData;
        var authorRef = this.emitedValues.item.practitionerRef;
        var authorData = this.emitedValues.practitioner;
        var organizationRef = this.emitedValues.item.organizationRef;
        var organizationData = this.emitedValues.item.organization;
        var cardName = "058-089-151/a - Pranešimas apie nustatytą (įtariamą) infekcinį susirgimą ar būklę";
        const combinedJSON = {
            encounterRef: encounterRef,
            patientRef: patientRef,
            patient: patientData,
            authorRef: authorRef,
            author: authorData,
            custodianRef: organizationRef,
            custodian: organizationData,
            cardName: cardName,
            documentType: this.documentType
        }
        return combinedJSON;
    }
}