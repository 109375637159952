// Lithuanian locale translations

export const AG_GRID_LOCALE_LT = {
    // Set Filter
    selectAll: '(Pasirinkti visus)',
    selectAllSearchResults: '(Pasirinkti visus paieškos rezultatus)',
    searchOoo: 'Ieškoti...',
    blanks: '(nenurodyta)',
    noMatches: 'Rezultatų nėra',

    // Number Filter & Text Filter
    filterOoo: 'Filtras...',
    equals: 'Yra lygus',
    notEqual: 'Nėra lygus',
    blank: 'Yra tuščias',
    notBlank: 'Nėra tuščias',
    empty: 'Pasirinkite vieną',

    // Number Filter
    lessThan: 'Mažiau nei',
    greaterThan: 'Daugiau nei',
    lessThanOrEqual: 'Mažiau nei, arba lygu',
    greaterThanOrEqual: 'Daugiau nei, arba lygu',
    inRange: 'Tarp',
    inRangeStart: 'Nuo',
    inRangeEnd: 'Iki',

    // Text Filter
    contains: 'Tekste yra',
    notContains: 'Tekste nėra',
    startsWith: 'Prasideda su',
    endsWith: 'Pasibaigia su',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'ir',
    orCondition: 'arba',

    // Filter Buttons
    applyFilter: 'Patvirtinti',
    resetFilter: 'Atstatyti',
    clearFilter: 'Išvalyti',
    cancelFilter: 'Atšaukti',

    // Filter Titles
    textFilter: 'Teksto filtras',
    numberFilter: 'Skaitmenų filtras',
    dateFilter: 'Datos filtras',
    setFilter: 'Nustatyti filtrą',

    // Group Column Filter
    groupFilterSelect: 'Pasirinkite lauką:',

    // Side Bar
    columns: 'Stulpeliai',
    filters: 'Filtrai',

    // columns tool panel
    pivotMode: 'Juodraščio režimas',
    groups: 'Eilutės grupės',
    rowGroupColumnsEmptyMessage: 'Tempti čia ir nustatyti eilutės grupes',
    values: 'Reikšmės',
    valueColumnsEmptyMessage: 'Tempti čia ir sujungti',
    pivots: 'Stulpelių etiketės',
    pivotColumnsEmptyMessage: 'Tempti čia ir nustatyti stulpelių etiketes',

    // Header of the Default Group Column
    group: 'Grupė',

    // Row Drag
    rowDragRow: 'eilutė',
    rowDragRows: 'eilutės',

    // Other
    loadingOoo: 'Kraunama...',
    loadingError: 'Klaida!',
    noRowsToShow: 'Duomenų nėra!',
    enabled: 'Įjungta',

    // Menu
    pinColumn: 'Prisegti stulpelį',
    pinLeft: 'Prisegti kairėje',
    pinRight: 'Prisegti dešinėje',
    noPin: 'Prisegimo nėra',
    valueAggregation: 'Reikšmės apskaičiavimas',
    noAggregation: 'Nėra',
    autosizeThiscolumn: 'Nustatyti šio stulpelio plotį',
    autosizeAllColumns: 'Nustatyti visų stulpelių plotį',
    groupBy: 'Grupuoti pagal',
    ungroupBy: 'Išgrupuoti pagal',
    addToValues: 'Pridėti ${variable} į reikšmes',
    removeFromValues: 'Pašalinti ${variable} iš reikšmių',
    addToLabels: 'Pridėti ${variable} į etiketes',
    removeFromLabels: 'Pašalinti ${variable} iš etikečių',
    resetColumns: 'Atstatyti stulpelius',
    expandAll: 'Išplėsti visus',
    collapseAll: 'Suspausti visus',
    copy: 'Kopijuoti',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Kopijuoti su antraštėmis',
    copyWithGroupHeaders: 'Kopijuoti su grupių antraštėmis',
    cut: 'Iškirpti',
    paste: 'Įklijuoti',
    ctrlV: 'Ctrl+V',
    export: 'Eksportuoti',
    csvExport: 'CSV eksportas',
    excelExport: 'Excel eksportas',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Pridėti',
    first: 'Pirmas',
    last: 'Paskutinis',
    min: 'Mažiausias',
    max: 'Didžiausias',
    none: 'Nėra',
    count: 'Viso',
    avg: 'Vidurkis',
    filteredRows: 'Išfiltruoti',
    selectedRows: 'Atrinkti',
    totalRows: 'Iš viso eilučių',
    totalAndFilteredRows: 'Eilutės',
    more: 'Daugiau',
    to: 'į',
    of: 'apie',
    page: 'Puslapis',
    nextPage: 'Kitas puslapis',
    lastPage: 'Paskutinis puslapis',
    firstPage: 'Pirmas puslapis',
    previousPage: 'Ankstesnis puslapis',

    // Pivoting
    pivotColumnGroupTotals: 'Viso',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Diagrama ir juodraščio režimas',
    pivotChart: 'Diagrama',
    chartRange: 'Diagramos rėžiai',

    columnChart: 'Stulpelis',
    groupedColumn: 'Sugrupuota',
    stackedColumn: 'Užpildyta',
    normalizedColumn: '100% užpildyta',

    barChart: 'Stulpelinė diagrama',
    groupedBar: 'Sugrupuota',
    stackedBar: 'Užpildyta',
    normalizedBar: '100% užpildyta',

    pieChart: 'Skritulinė diagrama',
    pie: 'Skritulys',
    doughnut: 'Skritulinė tuščiavidurė',

    line: 'Linija',

    xyChart: 'X Y sklaidos diagrama',
    scatter: 'Sklaida',
    bubble: 'Burbulinė',

    areaChart: 'Ploto diagrama',
    area: 'Plotas',
    stackedArea: 'Užpildyta',
    normalizedArea: '100% užpildyta',

    histogramChart: 'Histograma',
    histogramFrequency: "Dažnumas",

    combinationChart: 'Kombinacija',
    columnLineCombo: 'Stulpelis & Linija',
    AreaColumnCombo: 'Plotas & Stulpelis',

    // Charts
    pivotChartTitle: 'Diagrama',
    rangeChartTitle: 'Rėžių diagrama',
    settings: 'Nustatymai',
    data: 'Data',
    format: 'Formatas',
    categories: 'Kategorijos',
    defaultCategory: '(Nėra)',
    series: 'Eilutė',
    xyValues: 'X Y Reikšmės',
    paired: 'Surištas rėžimas',
    axis: 'Ašys',
    navigator: 'Navigatorius',
    color: 'Spalva',
    thickness: 'Storumas',
    xType: 'X tipas',
    automatic: 'Automatinis',
    category: 'Kategorija',
    number: 'Skaičius',
    time: 'Laikas',
    autoRotate: 'Automatiškai pasukti',
    xRotation: 'X pasukimas',
    yRotation: 'Y pasukimas',
    ticks: 'Taškeliai',
    width: 'Plotis',
    height: 'Aukštis',
    length: 'Ilgis',
    padding: 'Atitraukimas',
    spacing: 'Tarpai',
    chart: 'Diagrama',
    title: 'Pavadinimas',
    titlePlaceholder: 'Diagramos pavadinimas - paspauskite du kartus norėdami pakeisti',
    background: 'Fonas',
    font: 'Šriftas',
    top: 'Viršus',
    right: 'Dešinė',
    bottom: 'Apačia',
    left: 'Kairė',
    labels: 'Etiketės',
    size: 'Dydis',
    minSize: 'Mažiausias dydis',
    maxSize: 'Didžiausias dydis',
    legend: 'Legenda',
    position: 'Pozicija',
    markerSize: 'Markerio dydis',
    markerStroke: 'Markerio linija',
    markerPadding: 'Markerio atitraukimas',
    itemSpacing: 'Elemento atitraukimas',
    itemPaddingX: 'Elemento atitraukimas X',
    itemPaddingY: 'Elemento atitraukimas Y',
    layoutHorizontalSpacing: 'Horizontalus atitraukimas',
    layoutVerticalSpacing: 'Vertikalus atitraukimas',
    strokeWidth: 'Linijos plotis',
    lineDash: 'Taškinė linija',
    offset: 'Ofsetas',
    offsets: 'Ofsetai',
    tooltips: 'Paaiškinimai',
    callout: 'Iškviesti',
    markers: 'Markeriai',
    shadow: 'Šešėlis',
    blur: 'Sulieti',
    xOffset: 'X ofsetas',
    yOffset: 'Y ofsetas',
    lineWidth: 'Linijos plotis',
    normal: 'Normalus',
    bold: 'Paryškintas',
    italic: 'Pasviręs',
    boldItalic: 'Paryškintas pasviręs',
    predefined: 'Iš anksto nustatytas',
    fillOpacity: 'Užpildymo neskaidrumas',
    strokeOpacity: 'Linijos neskaidrumas',
    histogramBinCount: 'Bin kiekis',
    columnGroup: 'Stulpelis',
    barGroup: 'Stulpelinė grupė',
    pieGroup: 'Skritulinė grupė',
    lineGroup: 'Linijinė grupė',
    scatterGroup: 'X Y skaidos grupė',
    areaGroup: 'Ploto grupė',
    histogramGroup: 'Histogramos grupė',
    combinationGroup: 'Kombinacijos grupė',
    groupedColumnTooltip: 'Sugrupuota',
    stackedColumnTooltip: 'Užpildyta',
    normalizedColumnTooltip: '100% užpildyta',
    groupedBarTooltip: 'Sugrupuota',
    stackedBarTooltip: 'Užpildyta',
    normalizedBarTooltip: '100% užpildyta',
    pieTooltip: 'Skritulinė',
    doughnutTooltip: 'Skritulinė tuščiavidurė',
    lineTooltip: 'Linija',
    groupedAreaTooltip: 'Plotas',
    stackedAreaTooltip: 'Užpildyta',
    normalizedAreaTooltip: '100% užpildyta',
    scatterTooltip: 'Sklaidos',
    bubbleTooltip: 'Burbulinė',
    histogramTooltip: 'Histograma',
    columnLineComboTooltip: 'Stulpelis & Linija',
    areaColumnComboTooltip: 'Plotas & Stulpelis',
    customComboTooltip: 'Sava kombinacija',
    noDataToChart: 'Nėra duomenų, kurie galėtų būti atvaizduoti diagramoje',
    pivotChartRequiresPivotMode: 'Diagrama reikalauja, jog būtų įjungtas juodraščio režimas',
    chartSettingsToolbarTooltip: 'Meniu',
    chartLinkToolbarTooltip: 'Susietas su lentele',
    chartUnlinkToolbarTooltip: 'Atsietas su lentele',
    chartDownloadToolbarTooltip: 'Parsisiųsti diagramą',
    seriesChartType: 'Diagramos tipas',
    seriesType: 'Tipas',
    secondaryAxis: 'Antrinė ašis',

    // ARIA
    ariaChecked: 'pažymėta',
    ariaColumn: 'Stulpelis',
    ariaColumnGroup: 'Stulpelio grupė',
    ariaColumnList: 'Stulpelių sąrašas',
    ariaColumnSelectAll: 'Pažymėti/Atžymėti visus stulpelius',
    ariaDateFilterInput: 'Datos filtras',
    ariaDefaultListName: 'Sąrašas',
    ariaFilterColumnsInput: 'Stulpelio filtras',
    ariaFilterFromValue: 'Išfiltruoti pagal reikšmę',
    ariaFilterInput: 'Filtras',
    ariaFilterList: 'Sąrašo filtras',
    ariaFilterToValue: 'Filtro reikšmė',
    ariaFilterValue: 'Išfiltruota reikšmė',
    ariaFilteringOperator: 'Filtro operatorius',
    ariaHidden: 'paslėpta',
    ariaIndeterminate: 'neapibrėžtas',
    ariaInputEditor: 'Reikšmės redagavimas',
    ariaMenuColumn: 'Paspauskite CTRL ENTER, kad atidarytumėte stulpelių meniu.',
    ariaRowDeselect: 'Paspauskite SPACE, jei norite atžymėti eilutę',
    ariaRowSelectAll: 'Paspauskite Space, jei norite pažymėti/atžymėti visas eilutes',
    ariaRowToggleSelection: 'Paspauskite Space, jei norite pažymėti/atžymėti eilutę',
    ariaRowSelect: 'Paspauskite SPACE, jei norite pažymėti šią eilutę',
    ariaSearch: 'Paieška',
    ariaSortableColumn: 'Paspauskite ENTER, jei norite surūšiuoti',
    ariaToggleVisibility: 'Paspauskite SPACE, jei norite pakeisti matomumą',
    ariaUnchecked: 'nepažymėtas',
    ariaVisible: 'matomas',
    ariaSearchFilterValues: 'Paieškos filtro reikšmės',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Eilučių grupės',
    ariaValuesDropZonePanelLabel: 'Reikšmės',
    ariaPivotDropZonePanelLabel: 'Stulpelių etiketės',
    ariaDropZoneColumnComponentDescription: 'Paspauskite DELETE, jei norite pašalinti',
    ariaDropZoneColumnValueItemDescription: 'Paspauskite ENTER, jei norite pakeisti sujungimo tipą',
    ariaDropZoneColumnGroupItemDescription: 'Paspauskite ENTER, jei norite surūšiuoti',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' apie ',
    ariaDropZoneColumnComponentSortAscending: 'didėjantis',
    ariaDropZoneColumnComponentSortDescending: 'mažėjantis',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Stulpelio meniu',
    ariaLabelCellEditor: 'Langelio redagavimas',
    ariaLabelDialog: 'Dialogas',
    ariaLabelSelectField: 'Pasirinkti lauką',
    ariaLabelTooltip: 'Paaiškinimas',
    ariaLabelContextMenu: 'Kontekstinis meniu',
    ariaLabelSubMenu: 'Papildomas meniu',
    ariaLabelAggregationFunction: 'Suliejimo funkcija',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.'

}