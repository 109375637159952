ng1App.factory('TemplatesApiFactory', [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/templates';
    return $resource('', {}, {
        find: {
            method: 'GET',
            url: URL_BASE,
            isArray: true
        },
        findForList: {
            method: 'GET',
            url: URL_BASE + '/forList'
        },
        save: {
            method: 'POST',
            url: URL_BASE + '?type=:docType&title=:title'
        },
        update: {
            method: 'PUT',
            url: URL_BASE + '/:id?type=:docType&title=:title'
        },
        load: {
            method: 'POST',
            url: URL_BASE + '/:id/load'
        },
        countsByType: {
            method: 'GET',
            url: URL_BASE + '/countsByType',
            isArray: true
        },
        delete: {
            method: 'DELETE',
            url: URL_BASE
        }
    });
}]);