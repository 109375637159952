ng1App.controller('doctor.patient.cert.II.e027-1EditCtrl', 
    ['$scope', '$routeParams', '$filter', 'MessageService', 'CertificatePayment', 'EntitiesApiFactory', 'CertificateService', 'TasksApiFactory', '$q', 'CommonFunctionService',
     function ($scope, $routeParams, $filter, MessageService, CertificatePayment, EntitiesApiFactory, CertificateService, TasksApiFactory, $q, CommonFunctionService) {



    // Side content list
    $scope._shared = {
        // jus fill array with something
        contentList: [
            {
                title: $filter('translate')('cer.I0271.mai.inf'),
                id: 'cer_I0271_mai_inf'
            },
            {
                title: $filter('translate')('cer.I0271.doc'),
                id: 'cer_I0271_doc'
            }
        ]
    };

    //$scope.certificate2 = {}; //AS mod

    $scope.$emit('setHelpPage', 'kurti-pazyma-e027-1-mokinio-sveikatos-pazymejimas-ii-dantu-ir-zandikauliu-bkles-ivertinimas');

    var certificateType = $scope.certificateType;    
    $scope.onlyNumbersAndDash = /^\d+$|^-$|^-1$/;//2020-04-04 AS mod        
    
	//2020-04-14 AS modified
	//SP-06 KURTI PAŽYMĄ „E027-1 MOKINIO SVEIKATOS PAŽYMĖJIMAS: II. DANTŲ IR ŽANDIKAULIŲ BŪKLĖS ĮVERTINIMAS“.
	/*var e027_1_ICount = TasksApiFactory.getActiveCount({
        patientId: $routeParams.patientId,
        orderType: '10'
    });
    var e027_1_IICount = TasksApiFactory.getActiveCount({
        patientId: $routeParams.patientId,
        orderType: '11'
    });

    $q.all([e027_1_ICount.$promise, e027_1_IICount.$promise]).then(function (data) {
        //if there is no active task for this part, but there is active task 
        //for second part of certificate
        if (data[1].value == 0 && data[0].value > 0) {
            MessageService.error($filter('translate')('err.e027_1_II.alreadyCompleted'));
        }
    });*/

    CertificatePayment.showPaymentMessage(certificateType);

    //>>2020-04-15 AS
	//var minCreationDate = new Date();
	$scope.minCreationDate = moment().subtract(3, 'months').toDate();
    $scope.maxCreationDate = moment().endOf('day').toDate();
	//<<2020-04-15 AS
    $scope.maxCreationDate = new Date();  
      
    $scope.saveAndSign = function (doSign) {        
        CertificateService.saveAndSign(certificateType, $scope.certificate, $scope.certificateForm, null, null, doSign);
    };

    // TODO implement
    $scope.saveDraft = function () {
        alert('Not implemented');
    };

    $scope.malocclusions = EntitiesApiFactory.getList({
        classCode: 'obs-malocclusion-status'
    });

    $scope.showPermanentTeethError = function () {
        var certificateForm = $scope.certificateForm;        
        return (certificateForm.permanentTeethCountDecayed.$invalid || certificateForm.permanentTeethCountFilled.$invalid || certificateForm.permanentTeethCountMissing.$invalid
            //>>AS mod
            || !validPermanentTeeth()
            //<<            
            ) &&
                (certificateForm.submitted || certificateForm.permanentTeethCountDecayed.$dirty || certificateForm.permanentTeethCountFilled.$dirty || certificateForm.permanentTeethCountMissing.$dirty);
    };

    $scope.showPrimaryTeethError = function () {
        var certificateForm = $scope.certificateForm;
        return (certificateForm.primaryTeethCountDecayed.$invalid || certificateForm.primaryTeethCountFilled.$invalid || certificateForm.primaryTeethCountMissing.$invalid
            //>>AS mod
            || !validPrimaryTeeth()
            //<<
            ) &&
                (certificateForm.submitted || certificateForm.primaryTeethCountDecayed.$dirty || certificateForm.primaryTeethCountFilled.$dirty || certificateForm.primaryTeethCountMissing.$dirty);
    };

    $scope.malocclusions.$promise.then(function () {
        CommonFunctionService.getTrueEntityValue($scope.certificate, 'malocclusion', $scope.malocclusions);
    });
	
	//2020-04-26 AS modified
    $scope.calcValidUntilDate = function (){	
        CertificateService.calcValidUntilDate($scope);
    };
    $scope.calcValidUntilDate();

    function validPrimaryTeeth() {                     
        var k = $scope.certificate.primaryTeethCount.decayed;
        var p = $scope.certificate.primaryTeethCount.filled;
        var i = $scope.certificate.primaryTeethCount.missing;        
        return validateKPI(k,p,i);
    };

    function validPermanentTeeth() {                              
        var k = $scope.certificate.permanentTeethCount.decayed;
        var p = $scope.certificate.permanentTeethCount.filled;
        var i = $scope.certificate.permanentTeethCount.missing;        
        return validateKPI(k,p,i);
    };

    function validateKPI(k,p,i) {
        if (k == '-1' && p == '-1' && i == '-1'){                        
            return true;            
        }
        if (k != '-1' && p != '-1' && i != '-1'){                        
            return true;            
        }                
        return false;        
    };   
}]);