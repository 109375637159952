angular.module('medvais.api').factory('MedvaisImagingStudyApiFactory', ["$resource", function ($resource) {
    
    var URL_BASE = '/medvais-api/imaging/study';
    
    return $resource('', {}, {
        getImagingStudies: {
            method: 'GET',
            url: URL_BASE + '/'
        },
        
        getImagingStudyById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        
        getImagingStudyWithTelemedicineStatusById: {
            method: 'GET',
            url: URL_BASE + '/:id/:organizationId'
        },
        
        identifyPatient: {
            method: 'GET',
            url: URL_BASE + '/:id/identify/patient'
        },
        
        getImagingStudiesOfPatient: {
            method: 'GET',
            url: URL_BASE + '/patient/:patientId',
            isArray: true
        },
        
        updateAchiAndPhysician: {
            method: 'PUT',
            url: URL_BASE + '/:id/update/achi-and-physician?achiCode=:achiCode&perfPhysician=:perfPhysician',
            params: {
                id: '@id',
                achiCode : '@achiCode',
                perfPhysician: '@perfPhysician'
            }
        },
        
        getImagingStudyByRef: {
            method: 'GET',
            url: URL_BASE + '/byRef?imgStudyRef=:imgStudyRef'
        },
        
        getImagingStudyByIds: {
            method: 'GET',
            url: URL_BASE + '/byIds',
            isArray: true
        }
        
    });
    
}]);


