import {Component} from "@angular/core";
import {IFloatingFilterAngularComp} from "ag-grid-angular";
import {FilterChangedEvent, IFloatingFilterParams} from "ag-grid-community";

export interface ICustomFilterParams {
    title: string;
    doFilter: boolean;
}

@Component({
    selector: "ag-col-filter-button",
    templateUrl: "./ag-col-filter-button.component.html",
})
export class AgColFilterButton implements IFloatingFilterAngularComp {
    public params!: IFloatingFilterParams & ICustomFilterParams;

    agInit(params: IFloatingFilterParams & ICustomFilterParams): void {
        this.params = params;

        this.params.api.addEventListener('filterModified', (event) => {
            this.params.api.refreshServerSide({purge: false});
        });
    }


    onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {

    }

    onClick() {
        this.params.context.parent.setDoFilter(true);
        this.params.api.onFilterChanged();
    }
}
