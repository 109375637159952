import { HttpParams } from "@angular/common/http";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { CalendarOptions } from "@fullcalendar/core";
import ltLocale from "@fullcalendar/core/locales/lt";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, Observer, firstValueFrom, map, switchMap } from "rxjs";
import { ApiV2Medications } from "src/api/api-v2-medications";
import { ApiV2Patients } from "src/app/shared/services/api-v2-patients";
import { ApiV2Practitioners } from "src/api/api-v2-practitioners";
import {
    AspnPractitionierTimetableEvent,
    DpAspnContextService,
    NursingServiceF027Order,
    NursingServiceOrderClassifier,
    NursingServiceOrderClassifiers,
} from "src/app/doctor/aspn/services/dp-aspn-context.service";
import { DocumentTagEnum } from "../../../../../shared/models/document-tag.enum";
import { RouteParams } from "src/app/ng1.routeParams";
import { SelectOption } from "src/app/shared/components/select/select.component";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { MedicationModel } from "src/app/shared/models/medication.model";
import { PatientModel } from "src/app/shared/models/patient.model";
import { PractitionerModel } from "src/app/shared/models/practitioner.model";
import { ApiV2Documents } from "../../../../../../api/api-v2-documents";
import {
    NursingServiceOrderProcedureValue,
    NursingServiceOrderValue,
    fromNursingServiceOrderDto,
    toNursingServiceOrderDto,
} from "../../../model/nursingServiceOrder";

@Component({
    selector: "nursing-service-order-create",
    templateUrl: "./nursing-service-order-create.component.html",
})
export class NursingServiceOrderCreateComponent implements OnInit {
    documentTag: DocumentTagEnum = DocumentTagEnum.spu;
    prefillData: any;

    console = console;
    labelByStatus = {
        success: "Įvykęs",
        info: "Suplanuotas",
        extra: "Skubus",
    };

    badgeByStatus = {
        success: "bg-dark-subtle text-dark",
        info: "text-bg-success text-white",
        extra: "text-bg-danger text-white",
    };

    public nursingServiceOrder: FormGroup = new FormGroup({
        appointmentDate: new FormControl<string>(new Date().toISOString().substring(0, 10)),
        appointmentPractitioner: new FormControl<PractitionerModel>(null),
        appointmentPractitionerDisplay: new FormControl(""),
        procedures: new FormArray([]),
        state: new FormControl<boolean>(false),
    });

    public orderId: string;
    public withOrderId: boolean = false;
    public orderClassifiers: NursingServiceOrderClassifiers;
    public procedureGoupOpts: SelectOption[];
    public procedureOpts: NursingServiceOrderClassifier[][] = [];
    public placeInBodyProcedureOpts: NursingServiceOrderClassifier[];
    public medicinesOpts: Partial<MedicationModel>[];
    public periodicityOpts: SelectOption[];
    minDate: Date = new Date();
    itemsShowLimit: number = 99;
    events: AspnPractitionierTimetableEvent[] = [];
    public nursingServicePractitioniers: PractitionerModel[] = [];
    nursingServiceOrderF027Order: NursingServiceF027Order;
    nursingServicePractitionierOpts: PractitionerModel[] = [];
    practitionierSelected: boolean = false;
    patient: PatientModel;

    calendarOptions: CalendarOptions = {
        initialView: "timeGridWeek",
        plugins: [dayGridPlugin, timeGridPlugin],
        events: this.events,
        headerToolbar: {
            left: "prev,next",
            center: "title",
            right: "",
        },
        eventMinHeight: 80,
        weekends: true,
        allDaySlot: false,
        locale: ltLocale,
        height: 1000,
    };
    modalRef?: BsModalRef;

    //----- typeahead ----
    suggestions$?: any;
    practitionerArray: Partial<PractitionerModel>[];
    noResult = false;

    constructor(
        private dpAspnContextService: DpAspnContextService,
        private route: RouteParams,
        private modalService: BsModalService,
        private apiV2Practitioners: ApiV2Practitioners,
        private apiV2Medications: ApiV2Medications,
        private apiV2Patients: ApiV2Patients,
        private params: RouteParams,
        private apiV2Documents: ApiV2Documents,
    ) { }

    async ngOnInit(): Promise<void> {
        await firstValueFrom(this.apiV2Patients.getPatient(this.route.patientId)).then((val) => {
            this.patient = val;
        });

        this.orderClassifiers = await this.dpAspnContextService.getNursingServiceOrderClassifiers();

        if (this.route.orderId) {
            this.withOrderId = true;
            await this.dpAspnContextService.getNursingServiceOrderById(this.route.orderId).then(async (v) => {
                console.log(v);

                const vValue: NursingServiceOrderValue = fromNursingServiceOrderDto(v);
                console.log(vValue);
                this.nursingServiceOrder.patchValue(vValue);
                if (vValue.appointmentPractitioner) {
                    this.nursingServiceOrder.controls["appointmentPractitionerDisplay"].setValue(
                        v.appointmentPractitioner.givenName + " " + v.appointmentPractitioner.familyName
                    );
                    await this.dpAspnContextService
                        .getNursingServiceOrderPractTimetable(v.appointmentPractitioner.id)
                        .then((v) => (this.events = v));
                    this.calendarOptions.events = this.events;
                    this.practitionierSelected = true;
                }
                if (vValue.procedures) {
                    vValue.procedures.map((p, i) => {
                        this.addProcedureFormGroup(p, i);
                    });
                } else {
                    this.addProcedureFormGroup();
                }
            });
        } else {
            this.addProcedureFormGroup();
        }

        this.procedureGoupOpts = this.orderClassifiers.proceduresGroup.map((v) => {
            return {
                text: v.name,
                value: v.id,
            };
        });

        this.placeInBodyProcedureOpts = this.orderClassifiers.placeInBodyProcedure;

        firstValueFrom(this.apiV2Medications.search(new HttpParams())).then(
            (val) =>
            (this.medicinesOpts = val.map((m) => {
                return {
                    id: m.id,
                    name: m.name,
                } as Partial<MedicationModel>;
            }))
        );

        this.periodicityOpts = this.orderClassifiers.periodicity.map((v) => {
            return {
                text: v.name,
                value: v.id,
            };
        });

        this.calendarOptions = {
            initialView: "timeGridWeek",
            plugins: [dayGridPlugin, timeGridPlugin],
            //allDaySlot: false,
            events: this.events,

            headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "",
            },
            eventMinHeight: 80,
            // eventContent={renderEventContent}

            weekends: true,
            allDaySlot: false,
            locale: ltLocale,
            height: 1000,
        };

        this.nursingServiceOrderF027Order = await this.dpAspnContextService.getNursingServiceOrderF027Order(
            this.route.order27Id
            //this.f027OrderId
        );
        this.nursingServicePractitioniers =
            await this.dpAspnContextService.getNursingServicePractitioniersByOrganizationId(
                this.nursingServiceOrderF027Order.orderOrganizationID
            );

        this.nursingServicePractitionierOpts = this.nursingServicePractitioniers;

        // TODO  kaip gauti organizacija is siuntimo ?????????
        this.getPractitioniersByOrgId("123456789");

        this.getPrefillData();
    }

    getPrefillData() {
        this.apiV2Documents.getPrefilledForm(this.documentTag, this.documentTag, this.params.encounterId, this.params.patientId).pipe(map((response: any) => response)).subscribe({
            next: (response) => {
                this.prefillData = response;
            }
        });
    }

    getPractitioniersByOrgId(organizationId: string) {
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.nursingServiceOrder.get("appointmentPractitioner").value);
        }).pipe(
            switchMap((query: string) => {
                let params = new HttpParams();
                params = params.append("q", String((query ? query : "") + "*"));
                params = params.append("organization", organizationId);
                ["aspn_coordinator", "nurse", "LIC_specialistas", "KT_med"].map(
                    (r) => (params = params.append("role", r))
                );
                return this.apiV2Practitioners.find(params).pipe(
                    map((practitioniers: FilteredDataModel) => {
                        return practitioniers.items.map((p) => {
                            p.fullDisplay = p.givenName + " " + p.familyName;
                            return p;
                        });
                    })
                );
            })
        );
    }

    openModal(template: TemplateRef<any>) {
        const modOp: ModalOptions = {
            class: "modal-xxl",
        };
        this.modalRef = this.modalService.show(template, modOp);
    }

    get proceduresControls() {
        return (<FormGroup>this.nursingServiceOrder.get("procedures")).controls;
    }

    addProcedureFormGroup(procedure?: NursingServiceOrderProcedureValue, index?: number) {
        const fg = new FormGroup({
            procedureGroup: new FormControl("", [Validators.required]),
            procedures: new FormControl([], [Validators.required]),
            periodicity: new FormControl("", [Validators.required]),
            frequency: new FormControl<number>(null, [Validators.required]),
            placeInBodyProcedure: new FormControl([]),
            procedureFromDate: new FormControl(),
            medicines: new FormControl([]),
            procedureToDate: new FormControl(),
            recommendations: new FormControl(""),
            urgent: new FormControl(false),
        });

        if (procedure) {
            fg.patchValue(procedure);
            console.log(procedure);

            this.procedureOpts[index] = this.orderClassifiers.proceduresGroup.find(
                (p) => p.id === procedure.procedureGroup
            ).children;
        }
        (<FormArray>this.nursingServiceOrder.get("procedures")).push(fg);
    }

    removeProcedureFormGroup(index: number) {
        (<FormArray>this.nursingServiceOrder.get("procedures")).removeAt(index);
    }

    onSave() {
        console.log("Resultatai (formGroup): ", this.nursingServiceOrder.value);
        const dto = toNursingServiceOrderDto(this.nursingServiceOrder.value);
        dto.patient = this.patient;
        console.log("Resultatai dto: ", dto);
        if (this.orderId) {
            this.dpAspnContextService.updateOrder(this.orderId, dto).then((val) => {
                console.log(val);
            });
        } else {
            this.dpAspnContextService.createOrder(dto).then((val) => {
                console.log(val);
            });
        }
    }

    onProcedureGroupChange(event: any, procedureBlockIndex: number) {
        const procGroup = this.orderClassifiers.proceduresGroup.find((val) => val.code == event.target.value);
        // Clear values of procedures
        (<FormGroup>(<FormArray>this.nursingServiceOrder.get("procedures")).at(procedureBlockIndex))
            .get("procedures")
            .setValue([]);

        if (procGroup) {
            this.procedureOpts[procedureBlockIndex] = procGroup.children;
        } else {
            this.procedureOpts[procedureBlockIndex] = [];
        }
    }

    onPeriodicityChange(event: any, procedureBlockIndex: number) {
        // Clear values of procedures
        (<FormGroup>(<FormArray>this.nursingServiceOrder.get("procedures")).at(procedureBlockIndex))
            .get("frequency")
            .setValue("");
        const periodicityVal = (<FormGroup>(
            (<FormArray>this.nursingServiceOrder.get("procedures")).at(procedureBlockIndex)
        )).get("periodicity").value;
        if (["1", "2", "3"].includes(periodicityVal)) {
            (<FormControl>(
                (<FormGroup>(<FormArray>this.nursingServiceOrder.get("procedures")).at(procedureBlockIndex)).get(
                    "frequency"
                )
            )).disable({ emitEvent: true, onlySelf: true });
            (<FormControl>(
                (<FormGroup>(<FormArray>this.nursingServiceOrder.get("procedures")).at(procedureBlockIndex)).get(
                    "frequency"
                )
            )).setValidators([]);
        } else {
            (<FormControl>(
                (<FormGroup>(<FormArray>this.nursingServiceOrder.get("procedures")).at(procedureBlockIndex)).get(
                    "frequency"
                )
            )).enable();
        }
    }

    async typeaheadOnSelectP(e: TypeaheadMatch): Promise<void> {
        if (e) {
            this.nursingServiceOrder.controls["appointmentPractitioner"].setValue(e.item);

            await this.dpAspnContextService
                .getNursingServiceOrderPractTimetable(e.item.id)
                .then((v) => (this.events = v));
            this.calendarOptions.events = this.events;
            this.practitionierSelected = true;
        } else {
            this.nursingServiceOrder.controls["appointmentPractitioner"].setValue(null);
            this.events = [];
            this.calendarOptions.events = this.events;
            this.practitionierSelected = false;
        }
    }
    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    onBlurtypeahead(event) {
        if (
            !event ||
            event.item.givenName + " " + event.item.familyName !==
            this.nursingServiceOrder.controls["appointmentPractitionerDisplay"].value
        ) {
            this.nursingServiceOrder.controls["appointmentPractitionerDisplay"].setValue("");
            this.nursingServiceOrder.controls["appointmentPractitioner"].setValue(null);
            this.practitionierSelected = false;
        }
    }

    onFilterChange(event) {
        let params = new HttpParams();
        params = params.append("name", `${event}*`);

        firstValueFrom(this.apiV2Medications.search(params)).then(
            (val) =>
            (this.medicinesOpts = val.map((m) => {
                return {
                    id: m.id,
                    name: m.name,
                } as Partial<MedicationModel>;
            }))
        );
    }

    onClear() {
        this.nursingServiceOrder = new FormGroup({
            appointmentDate: new FormControl(new Date().toISOString().substring(0, 10)),
            appointmentPractitioner: new FormControl(""),
            appointmentPractitionerDisplay: new FormControl(""),
            procedures: new FormArray([]),
            state: new FormControl(""),
        });
        this.addProcedureFormGroup();
    }
}
