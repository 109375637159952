import { Component, Input } from '@angular/core';
import {
    PatientHealthAssessmentsFormRow
} from '../../../../../../../../shared/components/patient-health-assessments-reusable-form/patient-health-assessments-reusable-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';


@Component({
    selector: 'aspn-sbvf-digestive-system-form',
    templateUrl: './sbvf-digestive-system-form.component.html',
})
export class SbvfDigestiveSystemFormComponent {
    @Input() set answersVariants(value: {[key: string]: EntityModel[]}) {
        if (!value) return;
        this.generateRows(value);
    }
    @Input() set viewMode(value: boolean) {
        this._viewMode = value;
        if (value) {
            this.formGroup.disable();
        }
    };

    digestiveSystemRows: PatientHealthAssessmentsFormRow[] = [];
    selectOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'kartai per dieną'
        },
        {
            id: '1',
            code: '1',
            name: 'kartai per sav.'
        },
        {
            id: '2',
            code: '2',
            name: 'kartai per men.'
        },
    ];
    formGroup = this.fb.group({
        appetite: this.fb.control(null, [Validators.required]),
        swallowing: this.fb.control(null, [Validators.required]),
        feed: this.fb.control(null, [Validators.required]),
        belly: this.fb.control(null, [Validators.required]),
        stomachAche: this.fb.control(null, [Validators.required]),
        abdominalPainScale: this.fb.control({value: null, disabled: true}),
        nausea: this.fb.control(null, [Validators.required]),
        nauseaTime: this.fb.control(null, [Validators.required]),
        amountLiquidConsumed: this.fb.control(null, [Validators.required]),
        dehydration: this.fb.control(null, [Validators.required]),
        amountCaloriesConsumed: this.fb.control(null, [Validators.required]),
        vomiting: this.fb.control(null, [Validators.required]),
        frequency: this.fb.control(null, [Validators.required]),
        vomiting2: this.fb.control(null, [Validators.required]),
        stoma: this.fb.control(null, [Validators.required]),
        stomaType: this.fb.control(null, [Validators.required]),
        flatulence: this.fb.control(null, [Validators.required]),
        frequencyBowelMovements: this.fb.control(null, [Validators.required]),
        frequencyBowelMovementsSelect: this.fb.control(this.selectOptions[0].name, [Validators.required]),
        defecation: this.fb.control(null, [Validators.required]),
        antibiotics: this.fb.control(null, [Validators.required]),
        painDuringDefecation: this.fb.control(null, [Validators.required]),
        faecalIncontinence: this.fb.control(null, [Validators.required]),
        stoolColor: this.fb.control(null, [Validators.required]),
        stoolConsistency: this.fb.control(null, [Validators.required]),
        bloodInStool: this.fb.control(null, [Validators.required]),
        other: this.fb.control(null),
    });

    private _viewMode: boolean;
    constructor(
        private readonly fb: FormBuilder
    ) {
        this.formGroup.get('stomachAche').valueChanges.pipe(takeUntilDestroyed())
            .subscribe(value => {
                if (this._viewMode) {
                    this.formGroup.get('abdominalPainScale').disable();
                    return;
                }
                if (value === '0') {
                    this.formGroup.get('abdominalPainScale').enable();
                } else {
                    this.formGroup.get('abdominalPainScale').reset();
                    this.formGroup.get('abdominalPainScale').disable();
                }
            });
    }

    private generateRows(value: { [p: string]: EntityModel[] }) {
        this.digestiveSystemRows = [
            {
                title: 'Apetitas',
                type: 'choise',
                options: value['appetite'],
                formControl: this.formGroup.get('appetite')
            },
            {
                title: 'Rijimas',
                type: 'choise',
                options: value['swallowing'],
                formControl: this.formGroup.get('swallowing')
            },
            {
                title: 'Maitinimas',
                type: 'choise',
                options: value['feed'],
                formControl: this.formGroup.get('feed')
            },
            {
                title: 'Pilvas',
                type: 'choise',
                options: value['belly'],
                formControl: this.formGroup.get('belly')
            },
            {
                title: 'Pilvo skausmas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('stomachAche')
            },
            {
                title: 'Skausmo skalė',
                type: 'choise',
                options: value['scale'],
                customClass: 'w-auto',
                formControl: this.formGroup.get('abdominalPainScale')
            },
            {
                title: 'Pykinimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('nausea')
            },
            {
                title: 'Pykinimo laikas',
                type: 'choise',
                options: value['nauseaTime'],
                formControl: this.formGroup.get('nauseaTime')
            },
            {
                title: 'Suvartojamo skysčio kiekis',
                type: 'text',
                textInputLabel: 'ml/per parą',
                formControl: this.formGroup.get('amountLiquidConsumed')
            },
            {
                title: 'Dehidratacija',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('dehydration')
            },
            {
                title: 'Suvartojamų kalorijų kiekis per parą',
                type: 'choise',
                options: value['amountCalories'],
                formControl: this.formGroup.get('amountCaloriesConsumed')
            },
            {
                title: 'Vėmimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('vomiting')
            },
            {
                title: 'Vėmimo dažnis',
                type: 'text',
                textInputLabel: 'k. per parą',
                formControl: this.formGroup.get('frequency')
            },
            {
                title: 'Vėmalai',
                type: 'choise',
                options: value['vomiting'],
                formControl: this.formGroup.get('vomiting2')
            },
            {
                title: 'Stoma',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('stoma')
            },
            {
                title: 'Stomos tipas',
                type: 'choise',
                options: value['stomaType'],
                formControl: this.formGroup.get('stomaType')
            },
            {
                title: 'Vidurių pūtimas',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('flatulence')
            },
            {
                title: 'Tuštinimosi dažnis',
                type: 'text-select',
                options: this.selectOptions,
                formControl: this.formGroup.get('frequencyBowelMovements'),
                otherFormControl: this.formGroup.get('frequencyBowelMovementsSelect')
            },
            {
                title: 'Tuštinimasis',
                type: 'choise',
                options: value['defecation'],
                formControl: this.formGroup.get('defecation')
            },
            {
                title: 'Laisvinamieji vaistai',
                type: 'choise',
                options: value['releaseDrugs'],
                formControl: this.formGroup.get('antibiotics')
            },
            {
                title: 'Skausmingumas tuštinimosi metu',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('painDuringDefecation')
            },
            {
                title: 'Išmatų nelaikymas',
                type: 'choise',
                options: value['painDefecation'],
                formControl: this.formGroup.get('faecalIncontinence')
            },
            {
                title: 'Išmatų spalva',
                type: 'choise',
                options: value['fecalIncontinence'],
                formControl: this.formGroup.get('stoolColor')
            },
            {
                title: 'Išmatų konsistencija',
                type: 'choise',
                options: value['stoolConsistency'],
                formControl: this.formGroup.get('stoolConsistency')
            },
            {
                title: 'Kraujas išmatose',
                type: 'choise',
                options: value['thereNo'],
                formControl: this.formGroup.get('bloodInStool')
            },
            {
                title: 'Kita (Įrašyti)',
                type: 'text',
                formControl: this.formGroup.get('other')
            }
        ];
    }
}
