import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {ColDef, GridOptions, IServerSideDatasource} from "ag-grid-enterprise";
import {IServerSideGetRowsParams} from "ag-grid-community/dist/lib/interfaces/iServerSideDatasource";
import {isEmptyObject} from "jquery";
import {GridReadyEvent} from "ag-grid-community";
import {ModalComponent} from "../../modal/modal.component";
import {AgPaginationBarComponent} from "../../ag-grid/components/ag-pagination-bar/ag-pagination-bar.component";
import {AgColFilterText} from "../../ag-grid/components/ag-col-filter-text/ag-col-filter-text.component";
import {ErxFilterAdditionalStatusEnum} from "../../../models/erx-filter-additional-status.enum";
import {CellIconsComponent} from "../../cellIcons/cell-icons.component";
import {AgColFilterButton} from "../../ag-grid/components/ag-col-filter-button/ag-col-filter-button.component";
import {IconComponent} from "../../icon/icon.component";
import {ApiV2ErxMedications} from "../../../../../api/api-v2-erx-medications";
import {AgGridFilterValidationService} from "../../../services/ag-grid-filter-validation.service";
import {ApiV2Entities} from "../../../services/api-v2-entities";
import {ErxPrescriptionTypeEnum} from "../../../models/erx-prescription-type.enum";
import {ErxSupplyDisruptionTypeEnum} from "../../../models/erx-supply-disruption-type.enum";
import {ErxAdditionalStatusModel} from "../../../models/erx-additional-status.model";
import {AgGridCommonConfig} from "../../../configs/ag-grid-common.config";
import {ApiV2PharmMedications} from "../../../services/api-v2-pharm-medications";
import {Observable} from "rxjs";

@Component({
    selector: 'erx-filter-component',
    templateUrl: './erx-filter.component.html'
})
export class ErxFilterComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(AgGridAngular) agGrid: AgGridAngular;
    @ViewChild(ModalComponent) modal: ModalComponent;
    @ViewChild(AgPaginationBarComponent) paginationBar: AgPaginationBarComponent;

    @Input() searchPhrase: string = "";
    @Input() isCompensated: boolean = true;
    @Input() isDispense: boolean = false;
    @Input() medicationType: string = 'VA';
    @Input() modalId: string;
    @Input() modalCloseId: string;
    @Input() title: string;
    @Input() hasFooterButtons: boolean = false;
    @Output() onMedicationClick = new EventEmitter<any>();
    @Output() onMedicationDoubleClick = new EventEmitter<any>();

    context: any;
    data: any[] = [];
    doFilter: boolean = false;
    currentParams: any;
    initSearchPhrase: string;
    isClosing: boolean = false;
    restrictCheckboxTitle = "";
    modalSize: string = "modal-xl";
    paginationPageSize: number = 10;
    searchPhraseMin: number = 3;
    searchPhraseClasses: any = {};
    searchPhraseTooltip: string = null;

    colDefs: ColDef[] = [];
    colDefsVa: ColDef[] = [
        {
            colId: 'active_substances',
            field: 'activeIngredients',
            headerName: 'Bendrinis pavadinimas',
            headerTooltip: 'Bendrinis pavadinimas',
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                showTooltips: true,
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
            sort: 'asc'
        },
        {
            colId: 'product_name',
            field: 'productName',
            headerName: "Firminis pavadinimas",
            headerTooltip: "Firminis pavadinimas",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            }
        },
        {
            colId: 'strength',
            field: 'strength',
            headerName: "Stiprumas",
            headerTooltip: "Stiprumas",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            }
        },
        {
            colId: 'pharm_form_code',
            field: 'pharmForm.displayValue',
            headerName: "Farmacinė forma",
            headerTooltip: "Farmacinė forma",
            filter: 'agSetColumnFilter',
            filterParams: {
                convertValuesToStrings: true,
                values: params => {
                    this.apiV2Entities.getEntitiesList('pharmaceutical-form').subscribe(res => {
                        params.success(res);
                    });
                },
                valueFormatter: params => {
                    return params.value.name;
                },
                filterChangedCallback: (event) => {
                    this.agGrid.api.refreshHeader();
                },
                filterModifiedCallback: () => {
                },
                buttons: ['apply', 'cancel'],
                closeOnApply: true
            },
            floatingFilterComponentParams: {
                suppressFilterButton: false
            },
        },
        {
            colId: 'description',
            field: 'description',
            headerName: "Pakuotė",
            headerTooltip: "Pakuotė",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
            maxWidth: 125
        },
        {
            colId: 'npakid7',
            field: 'npakid7',
            headerName: "NPAKID/7",
            headerTooltip: "NPAKID/7",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
            maxWidth: 125
        },
        {
            colId: 'atc_code',
            field: 'atc.code',
            headerName: "ATC kodas",
            headerTooltip: "ATC kodas",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
            maxWidth: 125
        },
        {
            colId: 'compensated',
            field: 'additionalStatus',
            headerName: "Papildomi požymiai",
            headerTooltip: "Papildomi požymiai",
            filter: 'agSetColumnFilter',
            filterParams: {
                values: params => {
                    const values = [
                        "compensated",
                        "not_compensated",
                        "isBrand",
                        "biological",
                    ];
                    params.success(values);
                },
                valueFormatter: params => ErxFilterAdditionalStatusEnum[params.value],
                filterChangedCallback: (event) => {
                    this.agGrid.api.refreshHeader();
                },
                filterModifiedCallback: () => {
                },
                buttons: ['apply', 'cancel'],
                closeOnApply: true,
                defaultToNothingSelected: true
            },
            cellRenderer: CellIconsComponent,
            cellRendererParams: {
                icons: [
                    {key: 'compensated', icon: 'fa-solid fa-percent', tooltip: "Kompensuojamas"},
                    {key: 'biological', icon: 'fa-solid fa-leaf', tooltip: "Biologinis"},
                    {key: 'mti', icon: 'fa-solid fa-scale-balanced', tooltip: "MTI"},
                    {key: 'isBrand', icon: 'fa-solid fa-tag', tooltip: "Vardinis"},
                    {key: 'narcotic', icon: 'fa-solid fa-cannabis ', tooltip: "Narkotinis"},
                    {key: 'psychotropic', icon: 'fa-solid fa-brain', tooltip: "Psichotropinis"},
                    {key: 'disruption'},
                    {key: 'inr', icon: 'fa-solid fa-face-frown', tooltip: "ĮNR"},
                ],
                additionalClass: "fa-fw",
                dynamicIconKeys: ['disruption']
            },
            floatingFilterComponentParams: {
                suppressFilterButton: false
            },
            sortable: false,
            width: 50,
        },
        {
            field: 'detailedInfo',
            headerName: "",
            floatingFilterComponent: AgColFilterButton,
            floatingFilterComponentParams: {
                title: "Filtruoti"
            },
            filter: true,
            cellRenderer: IconComponent,
            cellRendererParams: {
                icon: 'fa-solid fa-info-circle fs-4 text-primary',
                tooltip: "Vaisto informacija"
            },
            onCellClicked: (event) => {
                return this.onInfoCellClick(event.data.ref);
            },
            minWidth: 115,
            maxWidth: 125,
            type: 'center',
        },
    ];
    colDefsMpp: ColDef[] = [
        {
            colId: 'active_substances',
            field: 'activeIngredients',
            headerName: 'Grupės pavadinimas',
            headerTooltip: 'Grupės pavadinimas',
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
            sort: 'asc',
            minWidth: 400
        },
        {
            colId: 'product_name',
            field: 'productName',
            headerName: "Firminis pavadinimas",
            headerTooltip: "Firminis pavadinimas",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
        },
        {
            colId: 'atc_code',
            field: 'atc.code',
            headerName: "Grupės kodas",
            headerTooltip: "Grupės kodas",
            filter: 'agTextColumnFilter',
            floatingFilterComponent: AgColFilterText,
            filterParams: {
                filterChangedCallback: (event) => {
                },
                filterModifiedCallback: () => {
                }
            },
            maxWidth: 200
        },
        {
            field: 'detailedInfo',
            headerName: "",
            floatingFilterComponent: AgColFilterButton,
            floatingFilterComponentParams: {
                title: "Filtruoti"
            },
            filter: true,
            minWidth: 115,
            maxWidth: 125,
            type: 'center',
        },
    ];
    defaultColDef: ColDef = {
        sortable: true,
        floatingFilter: true,
        resizable: true,
        autoHeight: true,
        wrapText: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true
        },
        flex: 1,
    };
    datasource: IServerSideDatasource;
    gridOptions: GridOptions;

    constructor(private apiV2ErxMedications: ApiV2ErxMedications, private apiV2PharmMedications: ApiV2PharmMedications,
                private apiV2Entities: ApiV2Entities, private validationService: AgGridFilterValidationService) {
        this.context = {
            parent: this,
            filtersErrors: [],
        }
    }

    ngAfterViewInit() {
        this.addModalEventListeners();
    }

    addModalEventListeners() {
        const modal = document.getElementById(this.modalId);
        if (modal) {
            modal.addEventListener('hidden.bs.modal', () => {
                this.resetAllFilters();
            });
            modal.addEventListener('show.bs.modal', () => {
                this.validateSearhPhraseInput();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchPhrase) {
            this.initSearchPhrase = this.searchPhrase;
        }
    }

    ngOnInit(): void {
        this.medicationType = this.medicationType.toUpperCase() === ErxPrescriptionTypeEnum.EV ?
            ErxPrescriptionTypeEnum.VA :
            this.medicationType.toUpperCase();

        if (ErxPrescriptionTypeEnum.VA !== this.medicationType.toUpperCase()) {
            this.colDefs = this.colDefsMpp;
            this.restrictCheckboxTitle = "Riboti MPP sąrašą pagal skyrimo diagnozę ir požymius";
        } else {
            this.colDefs = this.colDefsVa;
            this.restrictCheckboxTitle = "Riboti vaistų sąrašą pagal skyrimo diagnozę ir požymius";
            this.modalSize = 'modal-xxl';
        }

        this.colDefs = ErxPrescriptionTypeEnum.VA !== this.medicationType.toUpperCase() ? this.colDefsMpp : this.colDefsVa;

        this.datasource = {
            getRows: (params) => {
                this.agGrid.api.showLoadingOverlay();
                this.currentParams = params;
                if (this.getDoFilter() && (!isEmptyObject(params.request.filterModel) || this.searchPhrase.length !== 0)) {
                    const filterParams = this.makeFilterParams(params, this.medicationType, this.searchPhrase);
                    const apiService: Observable<any> = this.isDispense ?
                        this.apiV2PharmMedications.getAllMedications(filterParams) :
                        this.apiV2ErxMedications.getAllMedications(filterParams);
                    apiService.subscribe(res => {
                        if (this.medicationType !== ErxPrescriptionTypeEnum.MPP) {
                            res.map(r => {
                                Object.assign(r, {
                                    additionalStatus: {
                                        compensated: r.compensated,
                                        isBrand: false,
                                        biological: r.subgroup?.displayValue === ErxFilterAdditionalStatusEnum.biological,
                                        narcotic: r.controlledType?.code === ErxFilterAdditionalStatusEnum.narcotic,
                                        psychotropic: r.controlledType?.code === ErxFilterAdditionalStatusEnum.psychotropic,
                                        mti: false,
                                        disruption: {
                                            code: ErxSupplyDisruptionTypeEnum[r.supplyDisruptionType?.code],
                                            period: {
                                                start: r.supplyDisruptionFrom,
                                                end: r.supplyDisruptionTo
                                            }
                                        },
                                        inr: false
                                    } as ErxAdditionalStatusModel
                                })
                            });
                        }

                        this.data = res;
                        const dataCount: number = this.data.length;
                        if (dataCount === 0) {
                            this.agGrid.api.hideOverlay();
                            this.agGrid.api.showNoRowsOverlay();
                        } else {
                            this.agGrid.api.hideOverlay();
                        }

                        params.success({rowData: this.data, rowCount: dataCount});
                    });
                } else {
                    this.searchPhrase = this.isClosing ? (this.initSearchPhrase ? this.initSearchPhrase : "") : this.searchPhrase;
                    params.success({rowData: [], rowCount: this.data.length});
                    this.isClosing = false;
                    this.agGrid.api.hideOverlay();
                    this.agGrid.api.showNoRowsOverlay();
                }
                this.setDoFilter(false);
            }
        };

        this.gridOptions = {
            ...AgGridCommonConfig,
            onRowDoubleClicked: this.onRowDoubleClicked.bind(this),
            suppressServerSideInfiniteScroll: true,
            overlayNoRowsTemplate: "Duomenų nerasta"
        }
    }

    onRowDoubleClicked(event: any) {
        if (event.data.medicationKind === "PACKAGE") {
            const apiService: Observable<any> = this.isDispense ?
                this.apiV2PharmMedications.getCatalogue({ref: event.data.ref}) :
                this.apiV2ErxMedications.getByRefFromCatalogue(event.data.ref);

            apiService.subscribe(res => {
                this.onMedicationDoubleClick.emit(res);
                this.closeModal();
            });
        } else {
            this.onMedicationDoubleClick.emit(event.data);
            this.closeModal();
        }
    }

    onInfoCellClick(ref) {
        this.onMedicationClick.emit(ref);
    }

    makeFilterParams(params: IServerSideGetRowsParams, type: string, sharedSearch?: string): Object {
        let filter = {
            query: sharedSearch ? sharedSearch : "",
            kind: null,
            type: type
        };

        this.colDefs.forEach(col => {
            const filterModel = params.request.filterModel[col.colId];
            if (filterModel) {
                switch (filterModel.filterType) {
                    case "set":
                        let pharmFormCodes = [];
                        const nPrefix = "not_";
                        filterModel.values
                            .filter(value => !this.hasObjectingFilters(filterModel.values, value, nPrefix))
                            .forEach(value => {
                                if (col.field === 'additionalStatus') {
                                    if (value.startsWith(nPrefix)) {
                                        const result = value.replace(nPrefix, '');
                                        Object.assign(filter, {[result]: false});
                                    } else {
                                        Object.assign(filter, {[value]: true});
                                    }
                                } else if (col.field === 'pharmForm.displayValue') {
                                    pharmFormCodes.push(value.code);
                                }
                            });

                        if (pharmFormCodes.length !== 0) {
                            Object.assign(filter, {['pharmFormsCodes']: pharmFormCodes.join('|')});
                        }

                        break;
                    default:
                        let fieldName = col.field;
                        switch (fieldName) {
                            case 'atc.code':
                                fieldName = 'atc';
                                break;
                            default:
                                break;
                        }

                        Object.assign(filter, {[fieldName]: filterModel.filter});
                        break;
                }
            }

            params.request.sortModel.forEach(model => {
                Object.assign(filter, {"orderBy": model.colId});
                Object.assign(filter, {"orderType": model.sort});
            });
        });
        return filter;
    }

    hasObjectingFilters(values: any[], value: string, prefix: string) {
        if (value.startsWith(prefix)) {
            const valueWithoutPrefix: string = value.replace(prefix, '');
            return values.includes(valueWithoutPrefix);
        } else {
            const valueWithPrefix: string = prefix + value;
            return values.includes(valueWithPrefix);
        }
    }

    searchByString(event) {
        event.preventDefault();
        this.setDoFilter(true);
        this.agGrid.api.refreshServerSide(this.currentParams);
    }

    clearAgGridFilters() {
        this.agGrid.api.setFilterModel(null);
    }

    clearAllFilters(event?: Event) {
        event?.preventDefault();
        this.searchPhrase = "";
        this.clearAgGridFilters();
        this.agGrid.api.refreshServerSide(this.currentParams);
    }

    resetAllFilters() {
        this.isClosing = true;
        this.clearAllFilters();
    }

    closeModal() {
        this.resetAllFilters();
        const modal = document.getElementById(this.modalCloseId);
        modal.click();
    }

    onGridReady(params: GridReadyEvent) {
        this.paginationBar.initForAgGrid(params);
    }

    public getDoFilter() {
        return this.doFilter;
    }

    public setDoFilter(enable: boolean) {
        this.doFilter = enable;
    }

    validateSearhPhraseInput() {
        const isValid: boolean = this.searchPhrase === null ||
            this.searchPhrase.length >= this.searchPhraseMin ||
            this.searchPhrase.length === 0;
        this.validationService.validateFilter(isValid, this.context.filtersErrors, 'searchPhrase');

        if (isValid) {
            this.searchPhraseClasses = {};
            this.searchPhraseTooltip = null;
        } else {
            this.searchPhraseClasses = {'border-danger': true};
            this.searchPhraseTooltip = "Įveskite " + this.searchPhraseMin + " arba daugiau simbolių";
        }
    }
}