import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {ColDef, GridOptions, IDetailCellRendererParams, IServerSideDatasource} from "ag-grid-enterprise";
import {AgGridCommonConfig} from "../../../configs/ag-grid-common.config";
import {AgGridAngular} from "ag-grid-angular";
import {GridReadyEvent} from "ag-grid-community";
import {ApiV2ErxMedicationPrescriptions} from "../../../../../api/api-v2-erx-medication-prescriptions";
import moment from "moment";
import {FullNamePipe} from "../../../pipes/full-name.pipe";
import {MedicationPrescriptionStatusEnum} from "../../../models/medicationPrescriptionStatus.enum";
import {AgPillCell, PillCellModel} from "../../ag-grid/components/ag-pill-cell/ag-pill-cell.component";
import {AgLinkCell} from "../../ag-grid/components/ag-link-cell/ag-link-cell.component";
import {RouteParams} from "../../../../ng1.routeParams";
import {
    AgTextCell, KeyValueTextModel,
    TextCellLinkModel,
    TextCellModel
} from "../../ag-grid/components/ag-text-cell/ag-text-cell.component";
import {PractitionerModel} from "../../../models/practitioner.model";
import {OrganizationModel} from "../../../models/organization.model";
import {TranslateService} from "@ngx-translate/core";
import {CellIconsComponent} from "../../cellIcons/cell-icons.component";
import {RecipeFormEnum} from "../../../models/recipe-form.enum";
import {ApiV2MedicationPrescription} from "../../../../pharmacist/services/api-v2-medication-prescription";
import {EnvironmentSettings} from "../../../../../environments/env";
import {Environment} from "../../../../../environments/environment-properties";
import PortalType = Environment.PortalType;
import {ErecListFilterModel} from "../../../models/erec-list-filter.model";
import {PaginationBarComponent} from "../../list/pagination-bar/pagination-bar.component";
import {Pagination} from "../../list/pagination-bar/pagination-bar.constants";

@Component({
    selector: 'erec-list',
    templateUrl: './erec-list.component.html'
})
export class ErecListComponent implements OnInit {
    @ViewChild(AgGridAngular) agGrid: AgGridAngular;
    @ViewChild(PaginationBarComponent) paginationBar: PaginationBarComponent;
    @Input() showFilters = false;
    @Input() filters: any;
    patientId: string;
    dateFormat: string = 'YYYY-MM-DD';
    data: any[] = [];
    dataCount: number = 0;
    pagesCount: number = 0;
    lastFilters: any;
    colDefs: ColDef[] = [];
    defaultColDef: ColDef = {
        sortable: false,
        floatingFilter: false,
        resizable: false,
        autoHeight: true,
        wrapText: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true
        },
        flex: 1,
    };
    detailCellRendererParams: IDetailCellRendererParams;
    paginationPageSize: number = 10;
    datasource: IServerSideDatasource;
    gridOptions: GridOptions;
    detailGridOptions: GridOptions;
    isPharmacist: boolean = EnvironmentSettings.getPortalType() === PortalType.PHARMACIST;

    constructor(private apiV2ErxMedicationPrescriptions: ApiV2ErxMedicationPrescriptions, private apiV2MedicationPrescription: ApiV2MedicationPrescription,
                public fullNamePipe: FullNamePipe, private params: RouteParams, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.colDefs = this.getColumnDefs();
        this.updateResults();

        this.detailGridOptions = {
            ...AgGridCommonConfig,
            suppressServerSideInfiniteScroll: true,
            overlayNoRowsTemplate: "Duomenų nerasta",
            domLayout: 'autoHeight',
        }

        this.detailCellRendererParams = this.getDetailCellRendererParams();

        this.gridOptions = {
            ...this.detailGridOptions,
            masterDetail: true,
            groupDefaultExpanded: 1,
            detailCellRendererParams: this.detailCellRendererParams,
            detailRowAutoHeight: true
        }
    }

    onGridReady(params: GridReadyEvent) {

    }

    assignFilter(field: any, value: any, filter: any) {
        if (value) {
            Object.assign(filter, {[field]: value});
        }
    }

    updateResults(advancedFilter?: ErecListFilterModel) {
        this.datasource = {
            getRows: (params) => {
                this.agGrid.api.showLoadingOverlay();
                let filter = {
                    patientId: this.patientId,
                    order: 'DESC',
                    sort: 'NUMBER',
                    count: this.paginationPageSize,
                };

                this.assignFilter('query', advancedFilter?.prescribed, filter);
                this.assignFilter('byPrc', advancedFilter?.myPrescriptions, filter);
                this.assignFilter('type', advancedFilter?.type?.[0]?.id, filter);
                this.assignFilter('status', advancedFilter?.status?.[0]?.id, filter);
                this.assignFilter('id', advancedFilter?.id, filter);
                this.assignFilter('form', advancedFilter?.form?.[0]?.name, filter);
                this.assignFilter('fromDate', new Date(advancedFilter?.fromDate).getTime(), filter);
                this.assignFilter('toDate', new Date(advancedFilter?.toDate).getTime(), filter);
                this.assignFilter('isUsing', advancedFilter?.isUsing, filter);
                this.assignFilter('page', advancedFilter?.page, filter);

                let apiService: ApiV2ErxMedicationPrescriptions | ApiV2MedicationPrescription;
                if (this.isPharmacist) {
                    apiService = this.apiV2MedicationPrescription;
                } else {
                    apiService = this.apiV2ErxMedicationPrescriptions;
                }

                apiService.getDocuments(filter).subscribe(res => {
                    this.data = res.result;
                    this.dataCount = res.totalResults;
                    const pagesCount = res.totalResults / 10;
                    this.pagesCount = Math.ceil(pagesCount);
                    this.lastFilters = filter;

                    if (this.dataCount === 0) {
                        this.agGrid.api.hideOverlay();
                        this.agGrid.api.showNoRowsOverlay();
                    } else {
                        this.agGrid.api.hideOverlay();
                    }

                    params.success({rowData: this.data, rowCount: this.dataCount});
                });
            },
        }
    }

    getStatusPillColor(status: MedicationPrescriptionStatusEnum): string {
        switch (status) {
            case MedicationPrescriptionStatusEnum.ACTIVE:
                return 'text-bg-success';
            case MedicationPrescriptionStatusEnum.STOPPED:
                return 'text-bg-warning';
            case MedicationPrescriptionStatusEnum.COMPLETED:
                return 'text-bg-primary';
            case MedicationPrescriptionStatusEnum.ON_HOLD:
            case MedicationPrescriptionStatusEnum.ON_HOLD_FOR_REVIEW:
            case MedicationPrescriptionStatusEnum.ON_HOLD_RESERVED:
            case MedicationPrescriptionStatusEnum.ENTERED_IN_ERROR:
                return 'text-bg-danger';
            default:
                return undefined;
        }
    }

    getOnlyDate(value: any) {
        if (value) {
            return moment(value).format(this.dateFormat);
        }
        return "";
    }

    getColumnDefs(): ColDef[] {
        return [
            {
                colId: 'prescriptionData.dateWritten',
                field: 'prescriptionData.dateWritten',
                headerName: 'Data',
                headerTooltip: 'Data',
                valueFormatter: params => {
                    return this.getOnlyDate(params.value);
                },
                maxWidth: 110
            },
            {
                colId: 'prescriptionData.dispenseData.form',
                field: 'prescriptionData.dispenseData.form',
                headerName: 'Forma',
                headerTooltip: 'Forma',
                cellRenderer: CellIconsComponent,
                cellRendererParams: {
                    icons: [
                        {key: RecipeFormEnum.EL, icon: 'fa-solid fa-display', tooltip: "Elektroninis"},
                        {key: RecipeFormEnum.PP, icon: 'fa-regular fa-file-powerpoint', tooltip: "Popierinis"},
                    ],
                    additionalClass: "fa-fw"
                },
                valueGetter: params => {
                    const isEl: boolean = params.data.prescriptionData.dispenseData.form === undefined ||
                        params.data.prescriptionData.dispenseData.form.toLowerCase() === RecipeFormEnum.EL;
                    return {
                        [RecipeFormEnum.EL]: isEl,
                        [RecipeFormEnum.PP]: !isEl,
                    };
                },
                maxWidth: 60
            },
            {
                colId: 'prescriptionData',
                field: 'prescriptionData',
                headerName: 'Būsena',
                headerTooltip: 'Būsena',
                cellRenderer: AgPillCell,
                valueGetter: params => {
                    const prescriptionData = params.data.prescriptionData;
                    const statusCode = prescriptionData?.status?.code;
                    const validFrom = prescriptionData?.dispenseData?.validFrom;
                    const validUntil = prescriptionData?.dispenseData?.validUntil;
                    const isValid: boolean = statusCode === MedicationPrescriptionStatusEnum.ACTIVE;

                    const pillModel: PillCellModel = {
                        status: this.translateService.instant(statusCode),
                        pillColor: this.getStatusPillColor(statusCode),
                        validFrom: isValid && validFrom ? this.getOnlyDate(validFrom) : undefined,
                        validUntil: isValid && validUntil ? this.getOnlyDate(validUntil) : undefined,
                    };

                    return pillModel;
                },
                maxWidth: 150
            },
            {
                colId: 'metadata.id',
                field: 'metadata.id',
                headerName: 'Nr',
                headerTooltip: 'Nr',
                cellRenderer: AgLinkCell,
                cellRendererParams: {
                    urlPath: this.isPharmacist ?
                        `/patients/${this.patientId}/presc/` :
                        `dp/patients/${this.patientId}/erx/prs/`,
                    valueAsId: true,
                    pathAfterId: this.isPharmacist ? '' : '/doc'
                },
                valueFormatter: params => {
                    return params.data.metadata.docId;
                },
                maxWidth: 125
            },
            {
                colId: 'prescriptionData.reason.code',
                field: 'prescriptionData.reason.code',
                headerName: 'Diagnozė',
                headerTooltip: 'Diagnozė',
                maxWidth: 80
            },
            {
                colId: 'prescriptionData.prescriptionType.code',
                field: 'prescriptionData.prescriptionType.code',
                headerName: 'Tipas',
                headerTooltip: 'Tipas',
                cellRenderer: AgTextCell,
                valueGetter: params => {
                    return {
                        simpleText: params.data.prescriptionData?.prescriptionType?.code.toUpperCase(),
                        hasTooltip: true,
                    } as TextCellModel;
                },
                maxWidth: 60
            },
            {
                colId: 'prescriptionData.medicationData.activeIngredients',
                field: 'prescriptionData.medicationData.activeIngredients',
                headerName: 'Paskirta',
                headerTooltip: 'Paskirta',
                cellRenderer: AgTextCell,
                valueGetter: params => {
                    const medicationData = params.data.prescriptionData.medicationData;
                    const mainText: string[] = [
                        medicationData?.activeIngredients
                    ];
                    const additionalText: string[] = [
                        medicationData?.strength,
                        medicationData?.pharmForm?.displayValue,
                        medicationData?.dispenseData?.lenses?.opticalDevice ?
                            this.translateService.instant(medicationData?.dispenseData?.lenses?.opticalDevice) : "",
                    ];

                    const textCellModel: TextCellModel = {
                        mainText: mainText,
                        additionalText: additionalText
                    };

                    return textCellModel;
                },
            },
            {
                colId: 'dispenseInfo.dispenseQuantity',
                field: 'dispenseInfo.dispenseQuantity',
                headerName: 'Kiekis',
                headerTooltip: 'Kiekis',
                cellRenderer: AgTextCell,
                valueGetter: params => {
                    const dispenseInfo = params.data.dispenseInfo;
                    const dispensesWritten: number = params.data.prescriptionData.dispenseData?.dispenseQuantity?.value ?
                        params.data.prescriptionData.dispenseData?.dispenseQuantity?.value : 0;
                    const dispenseQuantity: number = dispenseInfo?.dispenseQuantity ? dispenseInfo?.dispenseQuantity : 0;
                    const dispenseLeft: number = dispenseInfo?.leftToDispense ? dispenseInfo?.leftToDispense :
                        params.data.prescriptionData?.status?.code === 'active' ? dispensesWritten : 0;
                    const mainText: KeyValueTextModel[] = [
                        {
                            key: "Išrašyta:",
                            value: dispensesWritten
                        },
                        {
                            key: "Išduota:",
                            value: dispenseQuantity
                        },
                        {
                            key: "Liko:",
                            value: dispenseLeft
                        },
                    ];

                    const dispensesCount: number = dispenseInfo?.dispensesCount ? dispenseInfo?.dispensesCount : 0;
                    const linkText: TextCellLinkModel[] = [
                        {
                            onClickExpand: true,
                            formattedText: {
                                key: "Išdavimų:",
                                value: dispensesCount
                            },
                        },
                    ];

                    const textCellModel: TextCellModel = {
                        mainText: mainText,
                        linkText: linkText
                    };

                    return textCellModel;
                },
                maxWidth: 125
            },
            {
                colId: 'lastDispenseDueDate',
                field: 'lastDispenseDueDate',
                headerName: 'Pakanka iki',
                headerTooltip: 'Pakanka iki',
                maxWidth: 110,
                valueFormatter: params => {
                    return this.getOnlyDate(params.value);
                }
            },
            {
                colId: 'practitioner',
                field: 'practitioner',
                headerName: 'Išdavė',
                headerTooltip: 'Išdavė',
                cellRenderer: AgTextCell,
                valueGetter: params => {
                    const practitioner: PractitionerModel = params.data.practitioner;
                    const mainText: string[] = [
                        this.fullNamePipe.transform(practitioner),
                        practitioner.speciality.displayValue
                    ];

                    const organization: OrganizationModel = params.data.organization;
                    const additionalText: string[] = [
                        organization?.name
                    ];

                    const textCellModel: TextCellModel = {
                        mainText: mainText,
                        additionalText: additionalText
                    };

                    return textCellModel;
                },
            },
        ];
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        const filter = {...this.lastFilters, page: pagination.page};

        this.updateResults(filter);
    }

    getDetailCellRendererParams(): IDetailCellRendererParams {
        return {
            detailGridOptions: {
                ...this.detailGridOptions,
                columnDefs: [
                    {
                        colId: 'dispenseData.dispense.whenHandedOver',
                        field: 'dispenseData.dispense.whenHandedOver',
                        headerName: 'Data',
                        headerTooltip: 'Data',
                        valueFormatter: params => {
                            return this.getOnlyDate(params.value);
                        },
                        maxWidth: 125
                    },
                    {
                        colId: 'metadata.id',
                        field: 'metadata.id',
                        headerName: 'Išdavimo dokumentas',
                        headerTooltip: 'Išdavimo dokumentas',
                        cellRenderer: AgLinkCell,
                        cellRendererParams: {
                            urlPath: this.isPharmacist ?
                                `/patients/${this.patientId}/disp/` :
                                `dp/patients/${this.patientId}/erx/dsp/`,
                            valueAsId: true,
                            pathAfterId: this.isPharmacist ? '/view' : ''
                        },
                        valueFormatter: params => {
                            return params.data.metadata.docId;
                        },
                        maxWidth: 200
                    },
                    {
                        colId: 'dispenseData.dispense.medication.productName',
                        field: 'dispenseData.dispense.medication.productName',
                        headerName: 'Išduota',
                        headerTooltip: 'Išduota'
                    },
                    {
                        colId: 'dispenseData.dispense.quantity.value',
                        field: 'dispenseData.dispense.quantity.value',
                        headerName: 'Išduotas kiekis',
                        headerTooltip: 'Išduotas kiekis',
                        maxWidth: 150
                    },
                ],
                defaultColDef: this.defaultColDef
            },
            getDetailRowData: (params) => {
                let filter = {
                    query: "",
                    patientId: this.patientId,
                    order: 'DESC',
                    sort: 'NUMBER',
                };

                let apiService: ApiV2ErxMedicationPrescriptions | ApiV2MedicationPrescription;
                if (this.isPharmacist) {
                    apiService = this.apiV2MedicationPrescription;
                } else {
                    apiService = this.apiV2ErxMedicationPrescriptions;
                }

                apiService
                    .getRelated(params.data.metadata.id, filter)
                    .subscribe(res => {
                        params.successCallback(res);
                    });
            },
        } as IDetailCellRendererParams;
    }
}