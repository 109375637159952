import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiV2Entities } from '../../../../../../shared/services/api-v2-entities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, forkJoin, take } from 'rxjs';
import { EntityModel } from '../../../../../../shared/models/entity.model';
import { updateFormArray, formatDateForDatePickers, clearFormArray } from '../../e096-utils/utils';
import {
    E096CpoGeneralInformationComponent
} from './e096-cpo-sub-forms/e096-ge-general-information/e096-cpo-general-information.component';
import {
    E096CpoCaesareanProcedureComponent
} from './e096-cpo-sub-forms/e096-cpo-caesarean-procedure/e096-cpo-caesarean-procedure.component';
import {
    E096CpoCaesareanParticipantsComponent
} from './e096-cpo-sub-forms/e096-cpo-caesarean-participants/e096-cpo-caesarean-participants.component';

@Component({
    selector: 'app-e096-cpo-form',
    templateUrl: './e096-cpo-form.component.html',
})
export class E096CpoFormComponent implements AfterViewInit {
    @Input() editable: boolean;
    @Input() set initialFormValue(value: {[key: string]: any}) {
        if (!value) return;
        this._initialFormValue = formatDateForDatePickers(value, this.dateFields);

        this.patchFormWithInitialData(this._initialFormValue);
    }

    @ViewChild(E096CpoGeneralInformationComponent) generalInfoComponent: E096CpoGeneralInformationComponent;
    @ViewChild(E096CpoCaesareanProcedureComponent) procedureComponent: E096CpoCaesareanProcedureComponent;
    @ViewChild(E096CpoCaesareanParticipantsComponent) participantsComponent: E096CpoCaesareanParticipantsComponent;

    formGroup: FormGroup;
    answersVariants: {[key: string]: EntityModel[]};
    _initialFormValue: {[key: string]: any};
    private dateFields: string[] = ['timeOfDischarge', 'initiationOfAnesthesia', 'startOfOperation', 'endOfOperation'];

    private updateFormArray = updateFormArray.bind(this) as typeof updateFormArray;
    private clearfArray = clearFormArray.bind(this) as typeof clearFormArray;

    constructor(
        private readonly fb: FormBuilder,
        private readonly apiV2Entities: ApiV2Entities,
    ) {
        forkJoin({
            robsonGroups: this.apiV2Entities.getEntitiesList('robson-groups'),
            repeatOperation: this.apiV2Entities.getEntitiesList('repeat-operation'),
            dystocia: this.apiV2Entities.getEntitiesList('dystocia'),
            fetusCondition: this.apiV2Entities.getEntitiesList('fetus-condition-issues'),
            fetalBreechPresentation: this.apiV2Entities.getEntitiesList('fetus-breech-presentation'),
            otherIndications: this.apiV2Entities.getEntitiesList('ngn-other-indications'),
            anesthesia: this.apiV2Entities.getEntitiesList('anesthesia'),
            lostBloodAmount: this.apiV2Entities.getEntitiesList('lost-blood-amount'),
            urinaryState: this.apiV2Entities.getEntitiesList('urinary-state'),
        }).pipe(takeUntilDestroyed())
            .subscribe(res => {
                this.answersVariants = res;
            });
    }

    ngAfterViewInit() {
        this.formGroup = this.fb.group({
            generalInformation: this.generalInfoComponent.formGroup,
            procedure: this.procedureComponent.formGroup,
            participants: this.participantsComponent.formGroup,
        });
        if (!this.editable) {
            this.formGroup.disable();
        }
        this._initialFormValue = this.formGroup.value;
    }

    save() {
        console.log(this.formGroup.getRawValue());
    }

    resetForm() {
        this.patchFormWithInitialData(this._initialFormValue);
    }

    private patchFormWithInitialData(_initialFormValue: {[key: string]: any}) {
        this.formGroup.valueChanges.pipe(
            debounceTime(10),
            take(1)
        ).subscribe(() => {
            this.updateParticipantsAndDiagnosisList({...this._initialFormValue});
            this.formGroup.patchValue(this._initialFormValue);
            if (!this.editable) {
                this.formGroup.disable();
            }
        });
        this.formGroup.patchValue(this._initialFormValue);
    }

    private updateParticipantsAndDiagnosisList(data: {[key: string]: any}): void {
        this.clearfArray('participants.operated');
        this.clearfArray('participants.assisted');
        this.clearfArray('participants.doctors');
        this.clearfArray('participants.nurse');
        this.clearfArray('generalInformation.diagnoses');
        this.updateFormArray(data, 'participants.operated');
        this.updateFormArray(data, 'participants.assisted');
        this.updateFormArray(data, 'participants.doctors');
        this.updateFormArray(data, 'participants.nurse');
        this.updateFormArray(data, 'generalInformation.diagnoses');
    }
}
