ng1App.directive('f025alkEncounterSurcharges', 
    ['SubFormListController', 'EntitiesApiFactory', 'CommonFunctionService', '$modalConfirm',
     function (SubFormListController, EntitiesApiFactory, CommonFunctionService, $modalConfirm) {
	var FLOAT_REGEXP = /^\-?\d{1,8}((\.|\,)\d{0,2})?$/;
	
	var initForm = function (scope) {
		scope.formCtrl = new SubFormListController(scope.encounter.surcharges, 'surcharges');
		scope.formCtrl._appendTop = true;
		scope.formCtrl._data.patientSurcharges = false;
		scope.formCtrl._data.paidServicesSurcharges = false;
		scope.formCtrl._data.valid = true;
		scope.formCtrl._data.fundSources = EntitiesApiFactory.getList({classCode: 'sveidra-fund-source'});
		scope.formCtrl.validateForm = function (form) {
			this.clear();
			
			if (!form.source) {
				this._validator.add('source');
			}
			if (form.materialsAndMedicine && !FLOAT_REGEXP.test(form.values.materialsAndMedicine)) {
				this._validator.add('materialsAndMedicine');
			}
			if (form.researshAndProcedures && !FLOAT_REGEXP.test(form.values.researshAndProcedures)) {
				this._validator.add('researshAndProcedures');
			}
			if (form.otherPatientCharges && !FLOAT_REGEXP.test(form.values.otherPatientCharges)) {
				this._validator.add('otherPatientCharges');
			}
			if (form.paidServices && !FLOAT_REGEXP.test(form.values.paidServices)) {
				this._validator.add('paidServices');
			}
			if (form.plannedOrUrgentServices && !FLOAT_REGEXP.test(form.values.plannedOrUrgentServices)) {
				this._validator.add('plannedOrUrgentServices');
			}
			if (form.otherServices && !FLOAT_REGEXP.test(form.values.otherServices)) {
				this._validator.add('otherServices');
			}
			
			form.invalid = this._validator.hasErrors();
            return !form.invalid;
		};
		scope.formCtrl.before = function (action) {
            if (action == 'cancel') {
                this.clear();
    			this._data.patientSurcharges = false;
    			this._data.paidServicesSurcharges = false;
            }
        };
        scope.formCtrl.after = function (action) {
        	if (action == 'add' || action == 'save') {
        		if (!scope.formCtrl._form.invalid) {
        			this._data.patientSurcharges = false;
        			this._data.paidServicesSurcharges = false;
        		}
        	} else if (action == 'edit') {
            	this._data.patientSurcharges = this._form.values.materialsAndMedicine || this._form.values.researshAndProcedures || this._form.values.otherPatientCharges;
            	this._data.paidServicesSurcharges = this._form.values.paidServices || this._form.values.plannedOrUrgentServices || this._form.values.otherServices;
            	for (var i = 0; i < this._data.fundSources.length; i++) {
            		if (this._form.source.code == this._data.fundSources[i].code) {
            			this._form.source= this._data.fundSources[i];
            		}
            	}
        	}
        };
        scope.formCtrl.editValue = function (index) {
        	this.edit(index);
        	CommonFunctionService.gotoAnchor('edit');
        };
        scope.formCtrl.deleteValue = function (index) {
            this.before('delete');
            var _this = this;
            $modalConfirm.show({content: 'Ar tikrai norite pašalinti?'}).then(function () {
            	if (_this._list[index].id) {
            		_this._list[index].removed = true;
            	} else {
            		var removed = _this._list.splice(index, 1);
            		_this.onDelete(removed[0]);
            		_this.after('delete');
            	}
            });
        };
        scope.formCtrl.patientSurchargesChanged = function () {
        	if (!scope.formCtrl._data.patientSurcharges) {
        		scope.formCtrl._form.values.materialsAndMedicine = null;
        		scope.formCtrl._form.values.researshAndProcedures = null;
        		scope.formCtrl._form.values.otherPatientCharges = null;
        	};
        };
        scope.formCtrl.paidServicesSurchargesChanged = function () {
        	if (!scope.formCtrl._data.paidServicesSurcharges) {
        		scope.formCtrl._form.values.paidServices = null;
        		scope.formCtrl._form.values.plannedOrUrgentServices = null;
        		scope.formCtrl._form.values.otherServices = null;
        	};
        };
        scope.formCtrl.init();
	};
	
	return {
		templateUrl: '/templates/portal/doctor/patient/aLK_forms/f025alk/parts/surcharges.html',
		restrict: 'E',
		scope: {
			encounter: '='
		},
		link: function (scope: any, element, attrs) {
			scope.encounter.$promise.then(function () {
				initForm(scope);
			});
			
			scope.$watch('encounter.service.type', function (newValue) {
				if (newValue && newValue.code != '5' && scope.encounter.surcharges) {
					// If it's not paid encounter clear all surcharges
					var index = 0;
					while (index < scope.encounter.surcharges.length) {
						if (scope.encounter.surcharges[index].id) {
							scope.encounter.surcharges[index++].removed = true;
						} else {
							scope.encounter.surcharges.splice(index, 1);
						}
					}
				}
			});
		}
	};
}]);