angular.module('medvais.documents').directive('medvaisEditRadiationData', function () {

    return {
        restrict: 'E',
        scope: true,
        transclude: true,
        templateUrl: 'modules/medvais/documents/components/edit-radiation-data.html',
        link: function (scope, element, attrs) {

        }
    };

});

angular.module('medvais.documents').directive('medvaisViewRadiationData', function () {

    return {
        restrict: 'E',
        scope: true,
        transclude: true,
        templateUrl: 'modules/medvais/documents/components/view-radiation-data.html',
        link: function (scope, element, attrs) {

        }
    };

});

