import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../../config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2MedicationPrescription extends ConfigService {
    path = this.apiUrl + '/MedicationPrescription';

    getRelated(id: any, query?: any): Observable<any[]> {
        const url = `${this.path}/${id}/related`;
        return this.http.get<any[]>(url, {params: query});
    }

    getDocuments(query?: any): Observable<any> {
        const url = `${this.path}/documents`;
        return this.http.get<any>(url, {params: query});
    }

}