<div [ngSwitch]="status">
    <span *ngSwitchCase="'null'">
    </span>
    <span *ngSwitchCase="'DRAFT'">
        <ng-container *ngIf="showTitle">
            {{ 'doc.draft' | translate }}
        </ng-container>
    </span>
    <span *ngSwitchCase="'PRELIMINARY'">
        <span class="fa fa-check-circle-o text-dark-emphasis" [title]="'doc.preliminary' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.preliminary' | translate }}</span>
    </span>
    <span *ngSwitchCase="'IN_PROGRESS'">
        <span class="fa fa-check-circle-o text-warning" [title]="'doc.in_progress' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.in_progress' | translate }}</span>
    </span>
    <span *ngSwitchCase="'COMPLETED'">
        <span class="fa fa-check-circle text-success" [title]="'doc.completed' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.completed' | translate }}</span>
    </span>
    <span *ngSwitchCase="'FINAL_UNSIGNED'">
        <span class="fa fa-check-circle-o text-dark-emphasis" [title]="'doc.final' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.final' | translate }}</span>
    </span>
    <span *ngSwitchCase="'FINAL_SIGNED'">
        <span class="fa fa-check-circle-o text-success"
              title="{{ portalType == 'patient' ? ('pp.ord.sta.act' | translate) : ('doc.signed' | translate)}}">
        </span>
        <span *ngIf="showTitle">{{ portalType == 'patient' ? ('pp.ord.sta.act' | translate) : ('doc.signed' | translate) }}</span>
    </span>
    <span *ngSwitchCase="'CANCELED_UNSIGNED'">
        <span class="fa fa-times-circle-o text-info-emphasis" [title]="'doc.entered_in_error_unsigned' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.entered_in_error_unsigned' | translate }}</span>
    </span>
    <span *ngSwitchCase="'CANCELED_SIGNED'">
        <span class="fa fa-times-circle-o text-danger" [title]="'doc.entered_in_error' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.entered_in_error' | translate }}</span>
    </span>
    <span *ngSwitchCase="'INACTIVE'">
        <span class="fa fa-check-circle text-info-emphasis" [title]="'doc.inactive' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.inactive' | translate }}</span>
    </span>
    <span *ngSwitchCase="'AMENDED_SIGNED'">
        <span class="fa fa-check-circle-o text-success" [title]="'doc.amended' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.amended' | translate }}</span>
    </span>
    <span *ngSwitchCase="'AMENDED_UNSIGNED'">
        <span class="fa fa-check-circle-o text-dark-emphasis" [title]="'doc.amended_unsigned' | translate"></span>
        <span *ngIf="showTitle">{{ 'doc.amended_unsigned' | translate }}</span>
    </span>
    <span *ngSwitchDefault>
        Unknown status [{{ status }}]
    </span>
</div>