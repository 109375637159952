<form [formGroup]="form">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
                <strong class="form-label">Dokumento data nuo</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateForm" [fControl]="form?.get('dateFrom')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
                <strong class="form-label">Dokumento data iki</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateTo" [fControl]="form?.get('dateTo')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Specialistas</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="practitioner" formControlName="practitioner"
                            typeaheadOptionField="practitioner"
                            [typeahead]="practitionerSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectPractitioner($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įvesti specialisto vardą, pavardę" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Profesinė kvalifikacija</strong>
                <div class="w-100">
                    <select id="qualification" class="form-select" formControlName="qualification">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>

                        <option *ngFor="let qualification of qualifications"
                            [value]="qualification.code">
                            {{qualification.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-xxl-6">
            <div class="form-group">
                <strong class="form-label">Dokumento būsena</strong>
                <div class="w-100">
                    <select id="status" class="form-select" formControlName="status">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>

                        <option *ngFor="let state of states | keyvalue" [value]="state.key">
                            {{state.value}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="d-flex justify-content-end gap-2">
    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="searchAdvanced()">{{'search' |
        translate}}</button>
    <button class="btn btn-secondary" (click)="clear()">{{ 'clean' | translate }}</button>
</div>
