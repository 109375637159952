import angular, {IModule} from "angular";
import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {FactoryProvider} from '@angular/core';


export interface IHybridHelper {
    downgradeComponent(componentClass: any, componentSelector?: string): IHybridHelper,

    downgradeProvider(providerClass: any, providerName?: string): IHybridHelper,
    buildProviderForUpgrade(ng1Name: string, ng2Name?: string): FactoryProvider
}

export class DowngradeAdapter implements IHybridHelper {
    private module: IModule;

    constructor(app: string | IModule) {
        if (typeof app === 'string') {
            this.module = angular.module(app)
        } else {
            this.module = app
        }
    }

    buildProviderForUpgrade(ng1Name: string, ng2Name?: string): FactoryProvider {
        ng2Name = ng2Name || ng1Name;

        return {
            provide: ng2Name,
            useFactory: this.buildFactoryForUpgradeProvider(ng1Name),
            deps: ['$injector']
        };

    }

    downgradeComponent(componentClass: any, componentSelector?: string): IHybridHelper {
        const component = componentClass;

        if (!componentSelector) {
            componentSelector = getComponentSelector(componentClass)
        }

        this.module.directive(componentSelector, downgradeComponent({
            component: component
        }) as angular.IDirectiveFactory);

        return this
    }

    downgradeProvider(providerClass: any, providerName?: string): IHybridHelper {
        if (providerName === undefined) {
            providerName = getComponentSelector(providerClass)
        }

        this.module.factory(providerName, downgradeInjectable(providerClass));
        return this;
    }

    private buildFactoryForUpgradeProvider(ng1Name: string): Function {
        // @ts-ignore
        return (injector: any) => injector.get(ng1Name);
    }
}

export function getTemplateComponent(component) {
    // if you do need it
    let name: string = component.name;
    const suffix = 'Component';
    if (name.endsWith(suffix)) {
        name = name.substring(0, name.length - suffix.length);
    }

    name = uncapitalize(name)

    let selector = name.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    return `<${selector}></${selector}>`
}

export function getComponentSelector(component) {
    // if you don't need AOT
    // return toCamelCase(component.__annotations__[0].selector);

    // if you do need it
    let name: string = component.name;
    const suffix = 'Component';
    if (name.endsWith(suffix)) {
        name = name.substring(0, name.length - suffix.length);
    }
    return uncapitalize(name);
}

export function toCamelCase(selector: string) {
    const splitted = selector.split('-');
    for (let i = 1; i < splitted.length; i++) {
        splitted[i] = capitalize(splitted[i]);
    }
    return splitted.join('');
}

function capitalize(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1);
}

function uncapitalize(name: string) {
    return name.charAt(0).toLowerCase() + name.slice(1);
}
