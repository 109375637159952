import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'notification-count',
    templateUrl: './notification-count.component.html'
})
export class NotificationCountComponent implements OnInit {

    @Input() visuallyHiddenTitle: string;
    @Input() count: number;

    constructor() {

    }

    ngOnInit(): void {

    }
}
