import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ApiV2AttachedUnsorted } from "src/api/api-v2-doc-ref-attached-unsorted";
import { ApiV2DocRefConsent } from "src/api/api-v2-doc-ref-consent";
import { ApiV2E110 } from "src/api/api-v2-doc-ref-e110";
import { RouteParams } from "src/app/ng1.routeParams";
import { DocRefAttachedUnsortedModel } from "src/app/shared/models/doc-ref-attached-unsorted.model";
import { DocRefConsentFilterEnum } from "src/app/shared/models/doc-ref-consent-filter.enum";
import { EntityModel } from "src/app/shared/models/entity.model";
import { FilterGroupModel } from "src/app/shared/models/filter-group.model";
import { FilterModel } from "src/app/shared/models/filter.model";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";
import { PortalTypeEnum } from "src/app/shared/models/portalType.enum";
import { QueryModel } from "src/app/shared/models/query.model";
import { FilterService } from "src/app/shared/services/filter.service";

@Component({
    selector: 'doc-ref-e110-list',
    templateUrl: './doc-ref-110-GMP-form-list.component.html'
})
export class DocRefE110ListComponent implements OnInit {

    backPath: string;
    @Input() docRefConsents: DocRefAttachedUnsortedModel[];
    @Input() filters: FilterModel[];
    @Input() currentFilter: FilterModel;
    @Input() portalType: PortalTypeEnum;
    @Input() patientId: string;
    @Input() filtersGroup$: Observable<FilterGroupModel[]>;
    filterValue = "";
    working: boolean = false;
    count: number;
    page: number = 1;
    loadMoreActive = false;

    constructor(public apiV2DocRefConsent: ApiV2DocRefConsent, public apiV2DocRefAttachedUnsorted: ApiV2AttachedUnsorted,
         private router: Router, private params: RouteParams, private apiV2E110: ApiV2E110) {
    }

    ngOnInit(): void {
        this.backPath = this.portalType + '/patients/' + this.params.patientId;
        this.setFilters();
        this.setPredefinedFilters();
        this.loadDocRefConsentList(this.currentFilter.query);
        this.patientId = this.params.patientId;
    }

    loadDocRefConsentList(query?: any, add?: boolean) {
        //console.log("query aaaaaaaaaaa",Date.parse(query.dateFrom));
        //this.filterValue = query;
        this.apiV2E110.getGMPList(query ? query : this.currentFilter.query).subscribe((data: DocRefAttachedUnsortedModel[] | FilteredDataModel) => {
            const filteredData: FilteredDataModel = data as FilteredDataModel;
            FilterService.setFilterCount(this.filters, filteredData);
            //console.log("filteredData",filteredData);
            filteredData.items.forEach(d => d.type = 'DocRefConsentModel');
            if (add) {
                filteredData.items.forEach(item => this.docRefConsents.push(item));
            } else {
                this.docRefConsents = filteredData.items;
            }
            this.count = this.docRefConsents.length;
            this.loadMoreActive = this.count < filteredData.total;
            this.working = false;
        });
    }

    setFilters() {
        this.filtersGroup$ = this.apiV2DocRefConsent.getFilters();
    }

    setPredefinedFilters() {
        this.filters = FilterService.createFilters(DocRefConsentFilterEnum);
        if (this.filters) {
            this.currentFilter = this.filters.find(filter => filter.title === DocRefConsentFilterEnum.TOTAL);
        }
    }

    canCreate(): boolean {
        return this.portalType === PortalTypeEnum.DP;
    }

    // createDocRefConsent() {
    //     this.router.navigate([this.portalType + '/doc-ref-consent/create']);
    // }

    onCardClick(id: string, documentType: EntityModel): void {
        this.router.navigate([this.portalType,'patients',this.patientId,documentType.name,'view',id]);
    }

    onEditClick(id: string, documentType: EntityModel): void {
        this.router.navigate([this.portalType,'patients',this.patientId,documentType.name,'edit',id]);
    }

    loadMore() {
        this.working = true;
        this.page++;
        this.loadDocRefConsentList({count: 5, page: this.page} as QueryModel, true);
    }
}