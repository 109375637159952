/**
 * @deprecated since migration to angular.
 * Now using doctor-context.component.ts downgraded component.
 */
ng1App.directive('_dpDoctorContext', 
    ['$rootScope', '$filter', 'AlertsApiFactory', 'AccountSettingsApiFactory', 'LinkApiFactory', 'DpSessionContext', 'ReportsApiFactory',
     function ($rootScope, $filter, AlertsApiFactory, AccountSettingsApiFactory,
                                               LinkApiFactory, DpSessionContext, ReportsApiFactory) {
    return {
        templateUrl: '/templates/portal/doctor/user/layout/doctor_context.html',
        restrict: 'E',
        replace: true,
        scope: true,
        link: function (scope: any) {
            scope.menu = [
                {text: $filter('translate')('dp.nav.current.menu.records'), url: '/dp/records', show: function () {
                        return $filter('acRead')('MyDocumentList');
                    }
                },
                {text: $filter('translate')('dp.nav.current.menu.patients'), url: '/dp/patients', show: function () {
                        return $filter('acRead')('AllPatientList')
                            || $filter('acRead')('OrganizationPatientList')
                            || $filter('acRead')('OrganizationTodayPatientList')
                            || $filter('acRead')('MyTodayPatientList')
                            || $filter('acRead')('MyPatientList');
                    }
                }];

            scope.showReport;

            scope.reloadUnreadCount = function () {
                AlertsApiFactory.getUnreadCount(function (data) {
                    scope.unreadCount = data.unread;
                });
            };
            scope.reloadUnreadCount();

            scope.onReportsClick = function (event) {
                var leftOffset = event.target.getBoundingClientRect().left;
                scope.reportClass = window.innerWidth - leftOffset > window.innerWidth / 2
                    ? '' : 'dropdown-to-left';
            };

            $rootScope.$on('updateUnreadMsgCount', function (event, data) {
                if (data) {
                    scope.unreadCount = data;
                } else {
                    scope.reloadUnreadCount();
                }
            });

            LinkApiFactory.getIPR(function(result){
                scope.iprUrl = result.value;
            });
            DpSessionContext.getSettings(function (data) {
                scope.isQualificationActive = function (q) {
                    return DpSessionContext.getSettings().active_qualification === q.licenseCode;
                };

                scope.updateActiveQualification = function (q) {
                    var setting = {active_qualification: q.licenseCode};
                    AccountSettingsApiFactory.save({}, setting, function () {
                        DpSessionContext.getSettings().active_qualification = q.licenseCode;
                    });
                };

                if(!scope.showReport) {
                    ReportsApiFactory.getDoctorInfo( 
                        function (res) {
                            if(res && res.isFamilyDoctor) {
                                scope.showReport = res.isFamilyDoctor;
                            }
    
                        });
                }

            });
        }
    };
}]);
