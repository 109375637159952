ng1App.factory('TemplateSaver', 
    ['$rootScope', '$modal', '$filter', 'TemplatesApiFactory', 'MessageService', 'DocumentCtx',
     function ($rootScope, $modal, $filter, TemplatesApiFactory, MessageService, DocumentCtx) {
    var modal = null;
    var scope = $rootScope.$new();
    var createModal = function (scope) {
        return $modal({
            scope: scope,
            title: $filter('translate')('mdl.sav.tem'),
            contentTemplate: '/templates/portal/doctor/patient/documents/modal/save_template.html'
        });
    };

    var onSuccess = function (res) {
        scope.spinner.loading = false;
        MessageService.clear();
        MessageService.registerSuccessEvent();
        modal.hide();
        MessageService.showSuccessEventMessage();
        MessageService.unregisterSuccessEvent();
    };

    var onError = function (res) {
        scope.spinner.loading = false;
        if (res.status == 400) {
            var globalErrors = res.data.globalErrors;
            var msg = (globalErrors && globalErrors.length > 0) ? globalErrors[0].msg : null;
            if (msg == 'duplicateTitle') {
                scope.saveTemplateError.msg = 'Ruošinys tokiu pavadinimu jau egzistuoja';
            } else if (msg == 'emptyTitle') {
                scope.saveTemplateError.msg = 'Nenurodytas pavadinimas';
            } else {
                scope.saveTemplateError.msg = 'Klaida';
            }
        } else {
            console.log(res);
        }
    };

    scope.doSaveTemplate = function () {
        scope.saveTemplateError = {};
        scope.spinner.loading = true;
        if (scope.data.mode == 'create') {
            TemplatesApiFactory.save({docType: scope._docType, title: scope.data.title}, scope.form, onSuccess, onError);
        } else {
            if (!scope.data.templateToUpdate) {
                scope.saveTemplateError.msg = 'Nepasirinktas ruošinys';
                scope.spinner.loading = false;
            } else {
                TemplatesApiFactory.update({docType: scope._docType, id: scope.data.templateToUpdate}, scope.form, onSuccess, onError);
            }
        }
    };

    return {save: function (form) {
            scope.form = form;
            scope._docType = DocumentCtx.getDocumentType();
            scope.data = {mode: 'create'};
            scope.saveTemplateError = {};
            scope.spinner = {loading: false};
            if (!modal) {
                modal = createModal(scope);
            }
            scope.templates = TemplatesApiFactory.find({type: scope._docType});
            modal.$promise.then(function () {
                modal.show();
            });
        }};
}]);
ng1App.directive('saveTemplate', 
    ['TemplateSaver',
     function (TemplateSaver) {

    return {
        scope: {form: '=saveTemplate'},
        link: function (scope: any, element, attrs) {
            element.on('click', function () {
                TemplateSaver.save(scope.form);
            });
        }
    };
}]);