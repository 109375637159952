<nhr-record-widget [iconBgClass]="'dark'" [iconClass]="'fa fa-stethoscope'" [showIcon]="false" [closable]="true"
    [closed]="false" [mixed]="true" [title]="(changedTitleText ? changedTitleText : 'doc.dia') | translate" [msgRegion]="messageRegion">
    <div class="widget-body p-0">
        <ol *ngIf="currentDiagnoses?.length" class="slats hover{{ (currentDiagnoses?.length ?? 0) > 0 ? ' border-bottom' : ''}} border-dark-subtle">
            <li
                *ngFor="let diagnose of currentDiagnoses; let i = index"
                class="pb-2 p-4{{ diagnose.type?.code === 'leading' ? ' bg-success-subtle' : '' }}"
                slat-item
                [edit]="!viewOnly"
                [editTitle]="'doc.dia.edt' | translate"
                [deleteTitle]="'doc.dia.dlt' | translate"
                [delete]="!viewOnly"
                (editButtonClick)="openEditDiagnoseModal(i)"
                (deleteButtonClick)="delete(i)"
            >
                <div class="d-flex gap-3">
                    <div class="flex-grow-1 mb-1">
                        <div class="row flex-wrap-reverse flex-md-wrap">
                            <div *ngIf="!!diagnose.onsetDate || !!diagnose.endDate" class="col-md-auto text-md-center text-secondary text-nowrap mb-1 mb-md-0">
                                <span *ngIf="!!diagnose.onsetDate">{{ diagnose.onsetDate | date: 'YYYY-MM-dd'}}</span>
                                <span *ngIf="!!diagnose.endDate"> - {{ diagnose.endDate | date: 'YYYY-MM-dd' }}</span>
                            </div>
                            <div class="col mb-md-1{{!!diagnose.onsetDate || !!diagnose.endDate ? ' ps-md-0' : '' }}">
                                <div class="d-flex flex-column flex-md-row justify-content-between">
                                    <strong>{{ diagnose.code?.code + ' ' + diagnose.code?.name }}</strong>
                                    <div *ngIf="!noType">
                                        <span *ngIf="diagnose.type?.code"
                                            class="badge rounded-pill{{diagnose.type?.code === 'leading' ? ' bg-success text-white' : ' bg-info-subtle text-dark'}}">
                                            {{ diagnose.type?.name | capitalize }} {{ 'doc.dia.dia' | translate | lowercase }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 row small">
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap d-flex align-items-center">
                                <span class="text-secondary me-2">{{'doc.dia.sts' | translate}}:</span>
                                <!-- TODO-design: what colors are for this badge, otherwise delete -->
                                <ng-container *ngIf="diagnose.clinicalStatus?.name == 'Aktyvi'">
                                    <div class="d-inline position-relative p-2">
                                        <span class="position-absolute p-2 translate-middle rounded-circle bg-success">
                                            <span
                                                class="visually-hidden">{{ diagnose.clinicalStatus?.name ?? '-' | capitalize }}</span>
                                        </span>
                                    </div>
                                    <!-- TODO-design: should we use an icon instead? If not delete this comment -->
                                    <!-- <i class="fa-regular fa-circle-dot text-success fs-3 my-n1"></i> -->
                                    <strong
                                        class="ps-1">{{ diagnose.clinicalStatus?.name ?? '-' | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="diagnose.clinicalStatus?.name != 'Aktyvi'">
                                    <strong>{{ diagnose.clinicalStatus?.name ?? '-' | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!diagnose.clinicalStatus">
                                    <strong>-</strong>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{'doc.dia.con' | translate}}: </span>
                                <ng-container *ngIf="diagnose.verificationStatus">
                                    <strong>{{ diagnose.verificationStatus.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!diagnose.verificationStatus">
                                    <strong>-</strong>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{'doc.dia.sev' | translate}}: </span>
                                <ng-container *ngIf="diagnose.severity">
                                    <strong>{{ diagnose.severity.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!diagnose.severity">
                                    <strong>-</strong>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                    <span class="text-secondary">{{'doc.dia.cat' | translate}}: </span>
                                    <ng-container *ngIf="diagnose.category">
                                        <strong>{{ diagnose.category.name | capitalize }}</strong>
                                    </ng-container>
                                    <ng-container *ngIf="!diagnose.category">
                                        <strong>-</strong>
                                    </ng-container>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl text-nowrap">
                                <span class="text-secondary">{{'doc.dia.dia.typ' | translate}}: </span>
                                <ng-container *ngIf="diagnose.type">
                                    <strong>{{ diagnose.type.name | capitalize }}</strong>
                                </ng-container>
                                <ng-container *ngIf="!diagnose.type">
                                    <strong>-</strong>
                                </ng-container>
                            </div>
                        </div>
                        <div class="description small mt-2">
                            <strong>
                                {{'doc.dia.desc' | translate}}:
                            </strong>
                            <span class="text-secondary">
                                {{ diagnose?.notes ?? '-' }}
                            </span>
                        </div>
                        <div class="circumstances small mb-2">
                            <strong>
                                {{'doc.dia.cir' | translate}}:
                            </strong>
                            <span class="text-secondary">
                                {{ diagnose?.decisionCircumstances ?? '-' }}
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ol>
        <div class="p-4" *ngIf="!viewOnly && !(singleDiagnosis && currentDiagnoses?.length === 1)">
            <div class="row mb-3 req">
                <div class="col-md-3">
                    <strong class="col-form-label text-md-end">{{'doc.dia.dia' | translate}}</strong>
                </div>
                <div class="col-md-9">
                    <div class="input-group">
                        <input
                            [formControl]="searchDiagnose"
                            [typeahead]="suggestions$"
                            [typeaheadAsync]="true"
                            [typeaheadItemTemplate]="customItemTemplate2"
                            [typeaheadMinLength]="0"
                            [typeaheadOptionsLimit]="10"
                            [typeaheadWaitMs]="200"
                            typeaheadOptionField="displayValue"
                            (typeaheadOnSelect)="selectDiagnose($event)"
                            [placeholder]="'doc.dia.diaFill' | translate"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': ((searchDiagnose?.invalid && searchDiagnose?.touched) || hasDiagnosesErrors()) && isRequiredFieldInForm }"
                        />
                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                        <ng-template #customItemTemplate2 let-model="item">
                            <span>{{model.code}} {{model.name}}</span>
                        </ng-template>
                    </div>
                    <div class="invalid-feedback" [class.d-block]="!!searchErr">{{ searchErr }}</div>
                </div>

            </div>
            <div class="row">
                <div class="offset-md-3 col">
                    <button class="btn btn-sm btn-secondary" (click)="openUploadDiagnoseModal()">{{'doc.dia.loadDia' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>
