import {Component, Input, OnInit} from "@angular/core";
import {SurgeriesSummaryModel} from "../../../models/surgeries-summary.model";

@Component({
    selector: 'surgeries-summary',
    templateUrl: './surgeries-summary.component.html'
})
export class SurgeriesSummaryComponent implements OnInit {

    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() surgeries: SurgeriesSummaryModel[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }
}