import { Component, Input } from '@angular/core';
import { E010_RowsGroup } from '../e010-forms.model';

@Component({
    selector: 'app-e010-form-data',
    templateUrl: './e010-form-data.component.html',
})
export class E010FormDataComponent {
    @Input() title: string;
    @Input() rows: E010_RowsGroup[] = [];

    constructor() {}

    valueIsArray(value: string | string[]): value is Array<string> {
        return Array.isArray(value);
    }
}
