import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from "../app/config/config.service";

@Injectable({
    providedIn: 'root'
})
export class ApiV2Links extends ConfigService {
    path = this.apiUrl + '/link';

    getIPR(): Observable<any> {
        const url = `${this.path}/ipr`;
        return this.http.get<any>(url);
    }

    getMenu(): Observable<any[]> {
        const url = `${this.path}/menu`;
        return this.http.get<any[]>(url);
    }

    getFullMenu(): Observable<any> {
        const url = `${this.path}/full-menu`;
        return this.http.get<any>(url);
    }
}
