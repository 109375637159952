<app-common-patient-widget *ngIf="prefillData" [modeModification]="false"
    [prefillData]="prefillData"></app-common-patient-widget>

<aspn-sbvf-measurements-form [viewMode]="viewMode" />
<aspn-sbvf-general-data-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-visual-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-ent-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-breath-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-cardio-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-digestive-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-renal-urinary-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-skeletal-muscular-system-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-joints-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-body-temp-form [viewMode]="viewMode" [answersVariants]="answersVariants" />
<aspn-sbvf-leather-form [viewMode]="viewMode" [answersVariants]="answersVariants" />

<div class="d-flex gap-3 mt-3" *ngIf="!viewMode">
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid"
        (click)="save()">{{'doc.aspn.assessment.submitForSignatureBtn' | translate}}</button>
    <button class="btn btn-primary flex-grow-1" [disabled]="formGroup?.invalid"
        (click)="save()">{{'doc.aspn.assessment.confirmBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid"
        (click)="save()">{{'doc.aspn.assessment.batchSignatureBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1" [disabled]="formGroup?.invalid"
        (click)="save()">{{'doc.aspn.assessment.submitBtn' | translate}}</button>
    <button class="btn btn-outline-primary flex-grow-1"
        (click)="save()">{{'doc.aspn.assessment.saveBtn' | translate}}</button>
    <button class="btn btn-danger flex-grow-1" (click)="clear()">{{'doc.aspn.assessment.clearBtn' | translate}}</button>
</div>