<modal [modalId]="orderSelectModalId" [modalCloseId]="orderSelectModalCloseId" [parentModalId]="parentModalId" [title]="title"
       (confirm)="createOrder()">
    <ng-container>
        <div class="alert alert-danger alert-dismissable mb-0" *ngIf="createDialogErr?.msg">
            <button aria-hidden="true" data-bs-dismiss="alert" class="btn-close" type="button" (click)="createDialogErr.msg = null">×</button>
            {{createDialogErr.msg}}
        </div>
        <div class="search">
            <div class="input-group">
                <input type="text" placeholder="{{'search'|translate}}" class="form-control" title="{{'quicksearch'|translate}}" [(ngModel)]="searchQuery"/>
                <div class="input-group-btn">
                    <button class="btn btn-secondary" type="button">
                        <span class="fa fa-search"></span>
                        <span class="sr-only"> {{'search'|translate}}</span>
                    </button>
                </div>
            </div>
        </div>
        <ol class="slats hover">
            <li *ngFor="let o of orderList | listFilter:searchQuery:filterPaths:orderListOps | slice:0:ordersLimit; let last = last"
                [ngClass]="{'last': last && !isMoreOrders()}" (click)="selected.order = o" (keypress)="selected.order = o" tabindex="0">

                <ng-container *ngIf="o.item.type.code === 4; else defaultOrder">
                    <app-order-with-metadata *ngIf="documentType === DocumentTagEnum.e027" [order]="o" inputType="checkbox"
                                             [(ngModel)]="selected.orders[o.item.fullId]" [newTab]="true"/>
                    <app-order-with-metadata *ngIf="documentType === DocumentTagEnum.e063" [order]="o" inputType="radio"
                                             [(ngModel)]="selected.order" [newTab]="true"/>
                </ng-container>

                <ng-template #defaultOrder>
                    <app-order-with-metadata [order]="o" inputType="radio" [(ngModel)]="selected.order" [newTab]="true"/>
                </ng-template>
            </li>

            <li class="last" *ngIf="orderList?.length == 0">
                <p class="text-center mb-0"><!--Įrašų nerasta-->{{'ale.rec.not.fou'| translate}}</p>
            </li>
            <li class="last hover" *ngIf="isMoreOrders()">
                <div tabindex="0" (click)="loadMoreOrders()" (keypress)="loadMoreOrders()" class="text-decoration-none text-center text-primary">{{orderLoadMoreTitle}}</div>
            </li>
        </ol>
    </ng-container>
</modal>