<app-title [backPath]="backPath">
    {{'025-5/a - ASIS klausimynas'| translate}}
</app-title>
<!--<h1 class="mb-0">{{'Naujas sutikimas'| translate}}</h1> -->
<!-- <app-common-patient-widget [documentType]="documentTag" [_modeModification]="true" (valueChange)="onChanges($event)"></app-common-patient-widget> -->
<app-common-patient-widget *ngIf="prefillData" [modeModification]="true" [prefillData]="prefillData"
    (valueChange)="onChanges($event)"></app-common-patient-widget>
<app-diagnoses-form (currentDiagnosesChange)="onDiagnosisChanges($event)"
    [isRequiredFieldInForm]=" true"></app-diagnoses-form>
<nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
    [closed]="false" [mixed]="true" [title]="'I. Bendrieji duomenys'">
    <div class="widget-body">
        <form [formGroup]="form1">
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Vizito data
                </label>
                <div class="col-md-6">
                    <app-date-picker inputId="visitDate" [maxDate]="currentDate"
                        [fControl]="form1?.get('visitDate')">
                    </app-date-picker>
                </div>
            </div>
            <div class="form-group row req">
                <label class="col-md-5 col-form-label pt-0 text-xs-start text-md-end" for="diseaseType">Formos tipas
                </label>
                <div class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseFormType" id="diseaseType"
                            formControlName="diseaseFormType" [value]="true" />
                        <label class="form-check-label" for="diseaseType">
                            Pirminė
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="diseaseFormType" id="diseaseType"
                            formControlName="diseaseFormType" [value]="false" />
                        <label class="form-check-label" for="diseaseType">
                            Patikslinta
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Kas nukreipė gydymo
                    paslaugoms? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="whoDirectedForTreatment"
                        [ngClass]="{ 'is-invalid': form1.get('whoDirectedForTreatment')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let whoDirectedForTreatment of myClassifierArrayOfClassifiers[0]"
                            [value]="whoDirectedForTreatment.code">
                            {{whoDirectedForTreatment.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form1.get('whoDirectedForTreatment').value === '9'" class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Jei pasirinkta
                    "Kitais" įrašykite</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="otherOptionsWhoDirectedForTreatment" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar nors kartą
                    gyvenime gydytas
                    bet kurioje sveikatos priežiūros įstaigoje
                    dėl narkotinių ir psichotropinių
                    medžiagų vartojimo? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="possibleRehabForSubstanceAbuse"
                        [ngClass]="{ 'is-invalid': form1.get('possibleRehabForSubstanceAbuse')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let possibleRehabForSubstanceAbuse of myClassifierArrayOfClassifiers[1]"
                            [value]="possibleRehabForSubstanceAbuse.code">
                            {{possibleRehabForSubstanceAbuse.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="mb-2 row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Pirmo gydymo
                    pradžios data</label>
                <div class="col-md-6">
                    <app-date-picker inputId="BeginningOfFirstTreatmentDate" [maxDate]="currentDate"
                        [fControl]="form1?.get('BeginningOfFirstTreatmentDate')">
                    </app-date-picker>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar nors kartą
                    gyvenime teiktas
                    pakaitinis gydymas opioidiniais
                    vaistiniais preparatais?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="opioidSubstitutionTherapy"
                        [ngClass]="{ 'is-invalid': form1.get('opioidSubstitutionTherapy')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let opioidSubstitutionTherapy of myClassifierArrayOfClassifiers[2]"
                            [value]="opioidSubstitutionTherapy.code">
                            {{opioidSubstitutionTherapy.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="mb-2 row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Pirmo gydymo
                    data</label>
                <div class="col-md-6">
                    <app-date-picker inputId="firstTreatmentDate" [maxDate]="currentDate"
                        [fControl]="form1?.get('firstTreatmentDate')">
                    </app-date-picker>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
    [closed]="false" [mixed]="true" [title]="'II. Socialinė demografinė informacija'">
    <div class="widget-body">
        <form [formGroup]="form2">
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Faktinė
                    gyvenamoji vieta
                    (savivaldybės kodas) </label>
                <div class="col-md-6">
                    {{emitedValues.patient.declaredAddress.municipalityId}}
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Duomenys apie
                    gyvenamąją vietą </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="dataAboutPlaceOfResidence"
                        [ngClass]="{ 'is-invalid': form2.get('dataAboutPlaceOfResidence')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let dataAboutPlaceOfResidence of myClassifierArrayOfClassifiers[3]"
                            [value]="dataAboutPlaceOfResidence.code">
                            {{dataAboutPlaceOfResidence.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar gyvena su
                    nepilnamečiais vaikais? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="livesWithMinors"
                        [ngClass]="{ 'is-invalid': form2.get('livesWithMinors')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let livesWithMinors of myClassifierArrayOfClassifiers[4]"
                            [value]="livesWithMinors.code">
                            {{livesWithMinors.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Su kuo šiuo metu
                    gyvena? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="livesWithWho"
                        [ngClass]="{ 'is-invalid': form2.get('livesWithWho')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let livesWithWho of myClassifierArrayOfClassifiers[5]"
                            [value]="livesWithWho.code">
                            {{livesWithWho.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Užimtumas šiuo metu
                </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="currentEmployment"
                        [ngClass]="{ 'is-invalid': form2.get('currentEmployment')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let currentEmployment of myClassifierArrayOfClassifiers[6]"
                            [value]="currentEmployment.code">
                            {{currentEmployment.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Įgytas išsilavinimas
                </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="receivedEducation"
                        [ngClass]="{ 'is-invalid': form2.get('receivedEducation')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let receivedEducation of myClassifierArrayOfClassifiers[7]"
                            [value]="receivedEducation.code">
                            {{receivedEducation.name}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
    [closed]="false" [mixed]="true" [title]="'III. Duomenys apie psichoaktyviųjų medžiagų vartojimą'">
    <div class="widget-body">
        <form [formGroup]="form3">
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pagrindinė
                    psichoaktyvioji medžiaga, dėl kurios asmuo kreipėsi į sveikatos priežiūros įstaigą </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="substanceForSeekingHelp"
                        [ngClass]="{ 'is-invalid': form3.get('substanceForSeekingHelp')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let substanceForSeekingHelp of myClassifierArrayOfClassifiers[8]"
                            [value]="substanceForSeekingHelp.code">
                            {{substanceForSeekingHelp.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pagrindinės
                    psichoaktyviosios medžiagos vartojimo būdas šiuo metu: </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="mainWayOfUsingSubstance"
                        [ngClass]="{ 'is-invalid': form3.get('mainWayOfUsingSubstance')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let mainWayOfUsingSubstance of myClassifierArrayOfClassifiers[9]"
                            [value]="mainWayOfUsingSubstance.code">
                            {{mainWayOfUsingSubstance.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kada pirmą
                    kartą gyvenime pavartojo
                    bet kokią narkotinę ar psichotropinę
                    medžiagą? (įrašykite amžių) </label>
                <div class="col-md-6">
                    <input type="number" class="form-control" id="templateTitle" formControlName="firstTimeUseAge"
                        [ngClass]="{ 'is-invalid': form3.get('firstTimeUseAge')?.invalid }" />
                </div>
            </div>
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kada pirmą
                    kartą gyvenime pavartojo
                    pagrindinę narkotinę ar psichotropinę
                    medžiagą? (įrašykite amžių) </label>
                <div class="col-md-6">
                    <input type="number" class="form-control" id="templateTitle"
                        formControlName="firstTimeUseAgeForMainSubstance"
                        [ngClass]="{ 'is-invalid': form3.get('firstTimeUseAgeForMainSubstance')?.invalid }" />
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Pagrindinės
                    psichoaktyviosios medžiagos vartojimo dažnumas per pastarąsias 30 dienų: </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="frequencyOfSubstanceUse"
                        [ngClass]="{ 'is-invalid': form3.get('frequencyOfSubstanceUse')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let frequencyOfSubstanceUse of myClassifierArrayOfClassifiers[10]"
                            [value]="frequencyOfSubstanceUse.code">
                            {{frequencyOfSubstanceUse.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar vartoja
                    skirtingas psichoaktyviąsias medžiagas? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="useOfDifferentSubstances"
                        [ngClass]="{ 'is-invalid': form3.get('useOfDifferentSubstances')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let useOfDifferentSubstances of myClassifierArrayOfClassifiers[4]"
                            [value]="useOfDifferentSubstances.code">
                            {{useOfDifferentSubstances.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kitos per
                    pastarąsias 30 dienų vartotos psichoaktyviosios medžiagos (pirma pagal dažnumą po pagrindinės
                    psichoaktyviosios medžiagos):</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="useOfOtherSubstancesFirst" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let useOfOtherSubstancesFirst of myClassifierArrayOfClassifiers[8]"
                            [value]="useOfOtherSubstancesFirst.code">
                            {{useOfOtherSubstancesFirst.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kitos per
                    pastarąsias 30 dienų vartotos psichoaktyviosios medžiagos (antra pagal dažnumą po pagrindinės
                    psichoaktyviosios medžiagos):</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="useOfOtherSubstancesSecond" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let useOfOtherSubstancesSecond of myClassifierArrayOfClassifiers[8]"
                            [value]="useOfOtherSubstancesSecond.code">
                            {{useOfOtherSubstancesSecond.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar kada nors
                    gyvenime švirkštėsi narkotines ir psichotropines medžiagas:</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="useOfInjectableSubstances" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let useOfInjectableSubstances of myClassifierArrayOfClassifiers[11]"
                            [value]="useOfInjectableSubstances.code">
                            {{useOfInjectableSubstances.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kiek laiko
                    praėjo nuo pirmojo gyvenime narkotinės ar psichotropinės medžiagos švirkštimosi?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="timeSpanFromLastInjection" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let timeSpanFromLastInjection of myClassifierArrayOfClassifiers[12]"
                            [value]="timeSpanFromLastInjection.code">
                            {{timeSpanFromLastInjection.name}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
    [closed]="false" [mixed]="true" [title]="'IV. Rizikingas elgesys/užkrečiamosios ligos'">
    <div class="widget-body">
        <form [formGroup]="form4">
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Ar kada nors
                    skolinosi iš kitų ar skolino kitiems naudotas adatas/švirkštus ar kitas švirkštimosi priemones?
                </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="useOfBorrowedEquipment"
                        [ngClass]="{ 'is-invalid': form4.get('useOfBorrowedEquipment')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let useOfBorrowedEquipment of myClassifierArrayOfClassifiers[13]"
                            [value]="useOfBorrowedEquipment.code">
                            {{useOfBorrowedEquipment.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar užsikrėtęs ŽIV?
                </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="HIVpositive"
                        [ngClass]="{ 'is-invalid': form4.get('HIVpositive')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let HIVpositive of myClassifierArrayOfClassifiers[4]"
                            [value]="HIVpositive.code">
                            {{HIVpositive.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar tyrėsi dėl
                    ŽIV?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="checkedOutForHIV" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let checkedOutForHIV of myClassifierArrayOfClassifiers[14]"
                            [value]="checkedOutForHIV.code">
                            {{checkedOutForHIV.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar buvo siųstas
                    tirtis dėl ŽIV?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="sentToCheckForHIV" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let sentToCheckForHIV of myClassifierArrayOfClassifiers[15]"
                            [value]="sentToCheckForHIV.code">
                            {{sentToCheckForHIV.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form4.get('sentToCheckForHIV').value === '3'" class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Jei pasirinkta
                    "Kitais" įrašykite</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="otherOptionsForHIV" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar šiuo metu serga
                    hepatitu B (HBV)? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="hepatitisB"
                        [ngClass]="{ 'is-invalid': form4.get('hepatitisB')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let hepatitisB of myClassifierArrayOfClassifiers[4]"
                            [value]="hepatitisB.code">
                            {{hepatitisB.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar tyrėsi dėl
                    hepatito B (HBV)?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="checkedOutForHepatitisB" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let checkedOutForHepatitisB of myClassifierArrayOfClassifiers[14]"
                            [value]="checkedOutForHepatitisB.code">
                            {{checkedOutForHepatitisB.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar buvo siųstas
                    tirtis dėl hepatito B (HBV)?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="sentToCheckForHepatitisB" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let sentToCheckForHepatitisB of myClassifierArrayOfClassifiers[15]"
                            [value]="sentToCheckForHepatitisB.code">
                            {{sentToCheckForHepatitisB.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form4.get('sentToCheckForHepatitisB').value === '3'" class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Jei pasirinkta
                    "Kitais" įrašykite</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="otherOptionsForHepatitisB" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar skiepytas nuo
                    hepatito B:</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="vaccinatedForHepatitisB" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let vaccinatedForHepatitisB of myClassifierArrayOfClassifiers[16]"
                            [value]="vaccinatedForHepatitisB.code">
                            {{vaccinatedForHepatitisB.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar šiuo metu serga
                    hepatitu C (HCV)? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="hepatitisC"
                        [ngClass]="{ 'is-invalid': form4.get('hepatitisC')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let hepatitisC of myClassifierArrayOfClassifiers[4]"
                            [value]="hepatitisC.code">
                            {{hepatitisC.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar tyrėsi dėl
                    hepatito C (HCV)?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="checkedOutForHepatitisC" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let checkedOutForHepatitisC of myClassifierArrayOfClassifiers[14]"
                            [value]="checkedOutForHepatitisC.code">
                            {{checkedOutForHepatitisC.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar buvo siųstas
                    tirtis dėl hepatito C (HCV)?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="sentToCheckForHepatitisC" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let sentToCheckForHepatitisC of myClassifierArrayOfClassifiers[15]"
                            [value]="sentToCheckForHepatitisC.code">
                            {{sentToCheckForHepatitisC.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form4.get('sentToCheckForHepatitisC').value === '3'" class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Jei pasirinkta
                    "Kitais" įrašykite</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="otherOptionsForHepatitisC" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row req">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar serga
                    tuberkulioze? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="tuberculosis"
                        [ngClass]="{ 'is-invalid': form4.get('tuberculosis')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let tuberculosis of myClassifierArrayOfClassifiers[4]"
                            [value]="tuberculosis.code">
                            {{tuberculosis.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar tyrėsi dėl
                    tuberkuliozės? </label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="checkedOutForTuberculosis" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let checkedOutForTuberculosis of myClassifierArrayOfClassifiers[14]"
                            [value]="checkedOutForTuberculosis.code">
                            {{checkedOutForTuberculosis.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label text-xs-start text-md-end">Ar buvo siųstas
                    tirtis dėl tuberkuliozės?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="sentToCheckForTuberculosis" required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let sentToCheckForTuberculosis of myClassifierArrayOfClassifiers[15]"
                            [value]="sentToCheckForTuberculosis.code">
                            {{sentToCheckForTuberculosis.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form4.get('sentToCheckForTuberculosis').value === '3'" class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Jei pasirinkta
                    "Kitais" įrašykite</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="otherOptionsForTuberculosis" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
    [closed]="false" [mixed]="true" [title]="'V. Diagnozė, gydymas, suteiktos paslaugos'">
    <div class="widget-body">
        <form [formGroup]="form5">
            <div class="form-group row req">
                <label class="col-md-5 col-form-label pt-0 text-xs-start text-md-end"
                    for="patientCareType">Pacientui teikiamų asmens sveikatos
                    priežiūros paslaugų tipas: </label>
                <div class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="patientCareServiceType"
                            id="patientCareType" formControlName="patientCareServiceType" [value]="true" />
                        <label class="form-check-label" for="patientCareType">
                            Ambulatorinės paslaugos
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="patientCareServiceType"
                            id="patientCareType" formControlName="patientCareServiceType" [value]="false" />
                        <label class="form-check-label" for="patientCareType">
                            Stacionarinės paslaugos
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kokios
                    suteiktos paslaugos?
                    (įrašykite paslaugos (-ų) kodą (-us)) </label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle" formControlName="providedServices"
                        maxlength="2000" rows="4"
                        [ngClass]="{ 'is-invalid': form5.get('providedServices')?.invalid }"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Kokiais
                    vaistiniais preparatais gydomas šiame gydymo epizode?</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="treatmentUsedAtThisStage"
                        [ngClass]="{ 'is-invalid': form5.get('treatmentUsedAtThisStage')?.invalid }">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let treatmentUsedAtThisStage of myClassifierArrayOfClassifiers[17]"
                            [value]="treatmentUsedAtThisStage.code">
                            {{treatmentUsedAtThisStage.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="form5.get('treatmentUsedAtThisStage').value === '5'" class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Jei pasirinkta
                    "Kitais" įrašykite</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle"
                        formControlName="otherTreatmentUsedAtThisStage" maxlength="2000" rows="4"></textarea>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<nhr-record-widget *ngIf="emitedValues" [iconBgClass]="'dark'" [iconClass]="'fa-list'" [closable]="true"
    [closed]="false" [mixed]="true"
    [title]="'VI. Išrašymo iš asmens sveikatos priežiūros įstaigos priežastis/gydymo baigtis'">
    <div class="widget-body">
        <form [formGroup]="form6">
            <div class="form-group row">
                <label for="category" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Išrašymo iš
                    asmens sveikatos
                    priežiūros įstaigos
                    priežastis/gydymo baigtis</label>
                <div class="col-md-6">
                    <select id="category" class="form-select" formControlName="dischargedFromHealthInstitution"
                        required>
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option *ngFor="let dischargedFromHealthInstitution of myClassifierArrayOfClassifiers[18]"
                            [value]="dischargedFromHealthInstitution.code">
                            {{dischargedFromHealthInstitution.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row req">
                <label for="templateTitle" class="col-md-5 col-form-label text-xs-start text-md-end">Formos
                    užpildymo data </label>
                <div class="col-md-6">
                    <app-date-picker inputId="formFillDate" [maxDate]="currentDate"
                        [fControl]="form6?.get('formFillDate')">
                    </app-date-picker>
                </div>
            </div>
            <div class="form-group row">
                <label for="templateTitle" class="col-md-5 col-form-label pt-0 text-xs-start text-md-end">Papildoma
                    informacija</label>
                <div class="col-md-6">
                    <textarea type="text" class="form-control" id="templateTitle" formControlName="otherInformation"
                        maxlength="2000" rows="4"></textarea>
                </div>
            </div>
        </form>
    </div>
</nhr-record-widget>
<app-modification-actions (sign)="signForm()" (sealConfirm)="sealConfirmForm()"
    (signingSubmit)="signingSubmitForm()" (submit)="submitForm()" (save)="saveForm()" (clear)="clearForm()"
    [signButtonDisabled]="true" [sealConfirmButtonDisabled]="true" [signingSubmitButtonDisabled]="true"
    [submitButtonDisabled]="true"></app-modification-actions>
