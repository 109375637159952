import { Component, DestroyRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityModel } from '../../../../../../../../shared/models/entity.model';
import {
    getControlValue,
    listenAndChangeStatusOfControl,
} from '../../../../../e096/e096-utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-e097-gd-appointments',
    templateUrl: './e097-gd-appointments.component.html',
})
export class E097GdAppointmentsComponent implements OnChanges {
    @Input() answersVariants: {[key: string]: EntityModel[]};
    @Input() editable: boolean = true;
    formGroup: FormGroup = this.fb.group({
        antibiotics: this.fb.array([])
    });
    currentTime = new Date();
    appointments: { [key: string]: any }[];
    anotherTreatmentOptions: EntityModel[] = [
        {
            id: '0',
            code: '0',
            name: 'Paskirta'
        },
        {
            id: '1',
            code: '1',
            name: 'Nepriskirta'
        }
    ]


    changeStatusOfControl = listenAndChangeStatusOfControl.bind(this) as typeof listenAndChangeStatusOfControl;
    controlValue = getControlValue.bind(this) as typeof getControlValue;

    constructor(
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.answersVariants) {
            this.createAppointments();
            this.appointments.forEach(appointment => {
                this.formGroup.addControl(appointment.formGroup, this.fb.group({
                    result: this.fb.control(appointment.type === 'radio' ? null : false),
                    comment: this.fb.control(null)
                }));
                if (appointment.required) {
                    this.formGroup.get(appointment.formGroup + '.result').addValidators([Validators.required]);
                }
                this.changeStatusOfControl({
                    controlNameForListen: `${appointment.formGroup}.result`,
                    controlNameForUpdate: `${appointment.formGroup}.comment`,
                    enableValue: true,
                    booleanCompare: true
                }).pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe();
            })
        }
    }

    private createAppointments(): void {
        this.appointments = [
            {
                type: 'radio',
                radioValues: this.answersVariants?.['newbornDiet'],
                formGroup: 'diet',
                label: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.diet'
            },
            {
                type: 'radio',
                radioValues: this.answersVariants?.['newbornRegimen'],
                required: true,
                formGroup: 'mode',
                label: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.mode'
            },
            {
                name: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.infusionTherapy',
                formGroup: 'infusionTherapy',
                label: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.treatment'
            },
            {
                name: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.phototherapy',
                formGroup: 'phototherapy'
            },
            {
                name: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.oxygenTherapy',
                formGroup: 'oxygenTherapy'
            },
            {
                label: 'doc.pregnancyDashboard.e097Gd.appointmentsForm.anotherTreatment',
                formGroup: 'anotherTreatment',
                type: 'radio',
                required: true,
                radioValues: this.anotherTreatmentOptions,
            }
        ];
    }
}
