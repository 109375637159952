<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.title' | translate"
    class="h-auto"
    [formGroup]="formGroup"
>
    <div class="card-body">
        <div class="row mx-auto">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.bleedingStoped' | translate}}:"
                inputId="bleedingStopped"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('bleedingStopped')"
            ></app-date-picker>
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.intensiveCare' | translate}}"
                inputId="takenToIntensiveCareUnit"
                class="form-group col-6 col-lg-3 flex-shrink-1"
                suffixIcon="fa fa-calendar"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('takenToIntensiveCareUnit')"
            ></app-date-picker>
        </div>
        <div class="row mx-auto">
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.totalBloodLoss' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="ml"
                type="number"
                [fControl]="formGroup.get('totalBloodLoss')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.crystalloidSolutions' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="ml"
                type="number"
                [fControl]="formGroup.get('amountCrystalloidSolutionsDispensed')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.massOfErythrocytes' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="vnt."
                type="number"
                [fControl]="formGroup.get('addedMassErythrocytes')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.freshFrozenPlasma' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="ml"
                type="number"
                [fControl]="formGroup.get('amountFreshFrozenPlasma')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.dosesCryoprecipitatePrescribed' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="vnt."
                type="number"
                [fControl]="formGroup.get('dosesCryoprecipitatePrescribed')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.colloidalSolutions' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="ml"
                type="number"
                [fControl]="formGroup.get('amountColloidalSolutionsDropped')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.amountOfOxytocin' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="vnt."
                type="number"
                [fControl]="formGroup.get('administeredAmountOxytocin')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.amountOfMisoprospol' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="µg"
                type="number"
                [fControl]="formGroup.get('prescribedAmountMisoprospol')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.amountOfMethylergome' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="mg"
                type="number"
                [fControl]="formGroup.get('amountMethylergometrinePrescribed')"
            ></app-input>
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.amountOfKarbetocine' | translate}}:"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column"
                units="µg"
                type="number"
                [fControl]="formGroup.get('amountCarbetocinPrescribed')"
            ></app-input>
        </div>
        <div class="row mx-auto">
            <app-input
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.otherPrescribed' | translate}}:"
                class="form-group col-12 flex-shrink-1"
                type="textarea"
                [fControl]="formGroup.get('otherPrescribedMedications')"
            ></app-input>
        </div>

        <div class="row mx-auto">
            <app-date-picker
                labelText="{{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.endActiveSupport' | translate}}:"
                inputId="endActiveSupport"
                class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column no-flex-child-important"
                labelClass="flex-grow-0"
                suffixIcon="fa fa-calendar"
                dateClass="h-[38px]"
                suffixClass="h-[38px]"
                inputGroupClass="flex-grow-1 align-items-center"
                dateInputFormat="YYYY-MM-DD HH:mm"
                [withTimepicker]="true"
                [maxDate]="maxDate"
                [fControl]="formGroup.get('endActiveSupport')"
            ></app-date-picker>

            <div class="form-group col-6 col-lg-3 flex-shrink-1 d-flex flex-column">
                <label for="durationActiveSupport" class="form-label flex-grow-1 col-form-label">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.durationActiveSupport' | translate}}:
                </label>
                <div class="input-group">
                    <timepicker
                        id="durationActiveSupport"
                        [showMeridian]="false"
                        [formControl]="formGroup.get('durationActiveSupport')"
                    ></timepicker>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row mx-auto mb-3">
                <span class="fw-bolder">
                {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.ledTheTeam' | translate}}:
            </span>
                <div class="col-12 col-lg-6 mt-2">
                    <app-find-specialists
                        [editable]="editable"
                        [customDisplaying]="false"
                        [formArray]="formGroup?.get('ledTheTeam')"
                    ></app-find-specialists>
                </div>
            </div>
            <div class="row mx-auto mb-3" formArrayName="teamMembers">
                <span class="fw-bolder">
                    {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.teamMembers' | translate}}:
                </span>
                <div class="col-12 col-lg-6 mt-2">
                    <app-find-specialists
                        [editable]="editable"
                        [customDisplaying]="false"
                        [formArray]="formGroup?.get('teamMembers')"
                    ></app-find-specialists>
                </div>
            </div>
            <div class="row mx-auto mb-3">
                <span class="fw-bolder">
                {{'doc.pregnancyDashboard.e096Ptkl.formActionsTaken.controlSheet' | translate}}:
            </span>
                <div class="col-12 col-lg-6 mt-2">
                    <app-find-specialists
                        [editable]="editable"
                        [customDisplaying]="false"
                        [formArray]="formGroup?.get('controlSheetWasFilledOut')"
                    ></app-find-specialists>
                </div>
            </div>
        </div>
    </div>
</nhr-record-widget>