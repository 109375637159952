<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096Ge.formAntibiotics.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row pb-4 border-bottom req">
            <app-antibiotics [editable]="editable" [formArray]="formGroup.get('substances')" />
        </div>
        <div class="row mt-3 req">
            <fieldset class="col-6 col-lg-3">
                <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.placentalPeriodCare' | translate}}:</legend>
                <ng-container *ngFor="let period of carePlacentalPeriodOptions; let i = index">
                    <app-radio-button
                        id="carePlacentalPeriod{{i}}"
                        radioName="carePlacentalPeriod{{ i }}"
                        labelText='{{ period.name | capitalize }}'
                        [radioValue]="period.name"
                        [fControl]="formGroup.get('carePlacentalPeriod')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.placentaSeparate' | translate}}:</legend>
                <ng-container *ngFor="let item of placentaSeparateOptions; let i = index">
                    <app-radio-button
                        id="placentaSeparate{{i}}"
                        radioName="placentaSeparate{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('placentaSeparate')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.placenta' | translate}}:</legend>
                <ng-container *ngFor="let item of placentaOptions; let i = index">
                    <app-radio-button
                        id="placenta{{i}}"
                        radioName="placenta{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('placenta')"
                    />
                </ng-container>
            </fieldset>
            <fieldset class="col-6 col-lg-3">
                <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.examinationOfPlacentaIsOrdered' | translate}}:</legend>
                <ng-container *ngFor="let item of yesNoOptions; let i = index">
                    <app-radio-button
                        id="examinationIsOrdered{{i}}"
                        radioName="examinationIsOrdered{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('examinationOfPlacentaIsOrdered')"
                    />
                </ng-container>
            </fieldset>
            <div class="col-6 col-lg-3">
                <fieldset>
                    <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.lostBloodAmount' | translate}}:</legend>
                    <ng-container *ngFor="let item of answersVariants?.['lostBloodAmount']; let i = index">
                        <app-radio-button
                            id="lostBloodAmount{{i}}"
                            radioName="lostBloodAmount{{ i }}"
                            labelText='{{ item.name | capitalize }}'
                            [radioValue]="item.name"
                            [fControl]="formGroup.get('lostBloodAmount')"
                        />
                    </ng-container>
                </fieldset>
                <ng-container *ngIf="getCntrlValue('lostBloodAmount') === 'daugiau kaip 1000 ml'">
                    <app-input
                        class="mt-1"
                        type="text"
                        units="ml"
                        [fControl]="formGroup.get('lostBloodAmountDetail')"
                    ></app-input>
                    <app-form-control-validation [fControl]="formGroup.get('lostBloodAmountDetail')" />
                </ng-container>
            </div>
            <fieldset class="col-6 col-lg-3">
                <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.interventions' | translate}}:</legend>
                <app-checkbox
                    *ngFor="let option of answersVariants?.['childbirthInterventions']; let i = index"
                    inputId="interventions{{i}}"
                    class="mt-1"
                    formClass="mb-0"
                    [labelText]="option.name"
                    [checkboxValue]="option.name"
                    [checkboxDisabled]="!(editable)"
                    [checkboxChecked]="checkboxIsChecked(option.name, 'interventions')"
                    (checkboxChange)="updateControl(option.name, 'interventions')"
                ></app-checkbox>
            </fieldset>
            <div class="col-6 col-lg-3">
                <fieldset>
                    <legend class="pt-0 float-none col-form-label">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.episiotomy' | translate}}:</legend>
                    <ng-container *ngFor="let item of episiotomyOptions; let i = index">
                        <app-radio-button
                            id="episiotomy{{i}}"
                            radioName="episiotomy{{ i }}"
                            labelText='{{ item.name | capitalize }}'
                            [radioValue]="item.name"
                            [fControl]="formGroup.get('episiotomy')"
                        />
                    </ng-container>
                    <app-form-control-validation [fControl]="formGroup.get('episiotomy')" />
                </fieldset>
                <fieldset *ngIf="getCntrlValue('episiotomy') === 'Atlikta'" class="ps-3">
                    <ng-container *ngFor="let item of answersVariants?.['performedEpisiotomy']; let i = index">
                        <app-radio-button
                            id="episiotomyType{{i}}"
                            radioName="episiotomyType{{ i }}"
                            labelText='{{ item.name | capitalize }}'
                            [radioValue]="item.name"
                            [fControl]="formGroup.get('episiotomyType')"
                        />
                    </ng-container>
                    <app-form-control-validation [fControl]="formGroup.get('episiotomyType')" />
                </fieldset>
            </div>
            <fieldset class="col-6 col-lg-3">
                <legend class="pt-0 float-none col-form-label norq">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.birthCanal' | translate}}:</legend>
                <ng-container *ngFor="let item of birthCanalOptions; let i = index">
                    <app-radio-button
                        id="birthCanal{{i}}"
                        radioName="birthCanal{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('birthCanal')"
                    />
                </ng-container>
            </fieldset>
        </div>

        <div class="row mt-3">
            <fieldset class="col-auto">
                <legend class="pt-0 float-none col-form-label">{{'doc.pregnancyDashboard.e096Ge.formAntibiotics.ruptureBirthCanal' | translate}}:</legend>
                <ng-container *ngFor="let item of ruptureBirthCanalOptions; let i = index">
                    <app-radio-button
                        id="ruptureBirthCanal{{i}}"
                        radioName="ruptureBirthCanal{{ i }}"
                        labelText='{{ item.name | capitalize }}'
                        [radioValue]="item.name"
                        [fControl]="formGroup.get('ruptureBirthCanal')"
                    />
                </ng-container>
            </fieldset>
        </div>

        <ng-container *ngIf="getCntrlValue('ruptureBirthCanal') === 'Rasta'">
            <div class="row mt-3">
                <div class="col-12 col-lg-6">
                    <span class="col-form-label">
                        {{'doc.pregnancyDashboard.e096Ge.formAntibiotics.crax' | translate}}:
                    </span>

                    <fieldset
                        *ngFor="let tear of answersVariants?.['childbirthRuptureArea']; let i = index"
                        class="col-auto d-flex align-items-center justify-content-between gap-3"
                        [class.mt-4]="i !== 0"
                    >
                        <app-checkbox
                            [labelText]="tear.name"
                            [inputId]="'ge-tears' + i"
                            [checkboxValue]="tear.name"
                            [checkboxDisabled]="!(editable)"
                            [checkboxChecked]="checkboxIsChecked(tear.name, 'tears')"
                            (checkboxChange)="updateControl(tear.name, 'tears')"
                        ></app-checkbox>

                        <ng-container *ngIf="tear.name !== 'Tarpvietė'">
                            <ng-container
                                *ngTemplateOutlet="ruptureLevelMultiselect; context: {parent: tear.name}"
                            ></ng-container>
                        </ng-container>

                        <ng-container *ngIf="tear.name === 'Tarpvietė' && checkboxIsChecked(tear.name, 'tears')">
                            <div class="w-50 me-5">
                                <div class="d-flex gap-3">
                                    <app-radio-button
                                        *ngFor="let item of interstitialSpaceOptions; let i = index"
                                        id="childbirthRuptureLevelSpace{{i}}"
                                        radioName="childbirthRuptureLevelSpace{{ i }}"
                                        [labelText]='item.name'
                                        [radioValue]="item.name"
                                        [fControl]="formGroup.get('childbirthRuptureLevelSpace')"
                                    />
                                </div>
                                <app-checkbox
                                    class="d-block mt-3"
                                    labelText="doc.pregnancyDashboard.e096Ge.formAntibiotics.episiotomyArea"
                                    [inputId]="'episiotomyArea'"
                                    [fControl]="formGroup.get('episiotomyArea')"
                                ></app-checkbox>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
                <div class="col-12 col-lg-6">
                    <span class="fw-bolder">
                        {{'doc.pregnancyDashboard.e096Ge.formAntibiotics.stitchTear' | translate}}:
                    </span>
                    <div class="mt-2">
                        <app-find-specialists
                            [editable]="editable"
                            [customDisplaying]="false"
                            [formArray]="formGroup?.get('doctorSuturedUterineRupture')"
                        ></app-find-specialists>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</nhr-record-widget>

<ng-template #ruptureLevelMultiselect let-parent="parent">
    <app-multiselect-dropdown
        *ngIf="checkboxIsChecked(parent, 'tears')"
        class="w-50 me-5"
        idField="id"
        textField="name"
        [allowSearchFilter]="false"
        [readonly]="!editable"
        [dropdownDataList]="answersVariants?.[ruptureLevelList(parent)]"
        [fControl]="formGroup.get(ruptureLevelList(parent))"
    ></app-multiselect-dropdown>
</ng-template>