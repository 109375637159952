angular.module('erx.api').factory('ErxPatientsApiFactory', ["$resource", "erxApiConfig", function ($resource, erxApiConfig) {
  var URL_BASE = erxApiConfig.options.portalBackendUrl + '/erx/doctor/Patient';
  return $resource('', {},
          {
            getAllergies: {
              method: 'GET',
              url: URL_BASE + '/:id/allergies'
            },
            getConditions: {
              method: 'GET',
              url: URL_BASE + '/:id/conditions'
            }
          }
  );
}]);