ng1App.factory("TasksApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/tasks';

    return $resource('', {}, {
        save: {
            method: 'POST',
            url: URL_BASE + '/save'
        },
        searchByPatient: {
            method: 'GET',
            url: URL_BASE + '/forList?patientId=:patientId',
            isArray: true
        },
        getById: {
            method: 'GET',
            url: URL_BASE + '/:id'
        },
        getList: {
            method: 'GET',
            url: URL_BASE + '/forList',
            isArray: false
        },
        getCount: {
            method: 'GET',
            url: URL_BASE + '/count'
        },
        getActiveCount: {
            method: 'GET',
            url: URL_BASE + '/count?statuses=SUBMITTED&statuses=PENDING&patientId=:patientId&orderType=:orderType'
        },
        cancel: {
            method: 'PUT',
            url: URL_BASE + '/:taskId/cancel?orderType=:orderType',
            params: {
                taskId: '@taskId',
                orderType : '@orderType'
            }
        },
        stop: {
            method: 'PUT',
            url: URL_BASE + '/:taskId/stop?comment=:comment&orderType=:orderType',
            params: {
                taskId: '@taskId',
                comment: '@comment',
                orderType: '@orderType'

            }
        },
        getByCompositionId: {
            method: 'GET',
            url: URL_BASE + '/forList?compositionId=:compositionId',
            isArray: false
        },
        getConsultationQualifications: {
            method: 'GET',
            url: URL_BASE + '/qualifications',
            isArray: true
        },
        hasRelatedDocuments: {
            method: 'GET',
            url: URL_BASE + '/:taskId/hasRelatedDocuments'
        }
    });
}]);