ng1App.factory('IndirectHealthInfoApiFactory', 
    ['$resource', '$routeParams', 'apiUrl',
     function ($resource, $routeParams, apiUrl) {

    var URL_BASE = apiUrl + '/indirectHealthInfo';
    return $resource('', {}, {
        isPatientDisagrees: {
            method: 'GET',
            url: URL_BASE + '/:id/isPatientDisagrees'
        },
        savePatientDisagree: {
            method: 'GET',
            url: URL_BASE + '/:id/save/?disagreement=:disagreement'
        }
    });
}]);