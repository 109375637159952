ng1App.controller('doctor.patient.DocumentsAmendCtrl',
    ['$scope', '$routeParams', '$modal', '$filter', 'DocumentCtx', 'DocumentsApiFactory', 'DocumentModificationActions', 'EncountersApiFactory', 'DocumentSpinnerHelper', 'MessageService',
     function ($scope, $routeParams, $modal, $filter, DocumentCtx, DocumentsApiFactory, DocumentModificationActions, EncountersApiFactory, DocumentSpinnerHelper, MessageService) {
    $scope.$emit('setHelpPage', 'pakeisti-elektronini-dokumenta');
    $scope._shared = {
        customValidator: null,
        // documents can override this to allow only save final (restrict sign)
        saveFinalOnly: function(){
            return false;
        }
    };

    DocumentCtx.reset();
    DocumentCtx.setMode('amend');
    DocumentCtx.setPatientId($routeParams.patientId);
    DocumentCtx.setDocumentId($routeParams.documentId);

    $scope._removed = {};
    $scope._modified = {};



    $scope.form = DocumentsApiFactory.getByCompositionForAmend({id:$routeParams.documentId}, function(d){
        DocumentCtx.setDocument(d);
        $scope.form = d;
            $scope.prefillData = d;
        $scope.patientData = d.metadata.patient;
        $scope.organization = d.metadata.custodian;
        $scope.practitioner = d.metadata.practitioner;
        $scope.encounterData = d.metadata.encounter;
        DocumentModificationActions.checkValidQualification($scope.practitioner);
      },
      function(err){
          if (err.status == 500) {
              MessageService.handleError(err.data, MessageService);
          } else if (err.status == 400) {
              _.each(err.data.globalErrors, function (i) {
                  MessageService.error(i.msg == 'incorrect_document_status' ? 'Bloga dokumento būsena' : 'incorrect_document_status');
              });
          }
      }
    );

    $scope.docCtx = DocumentCtx;

    var checkRemovedItems = function(callback){
        console.log('removed: ');
        var r = $scope._removed;
        var anyRemoved = r.allergies && r.allergies.length>0 || r.diagnosis && r.diagnosis.length>0 ;
        if(anyRemoved){
            $scope.addReason(callback);
        }else{
            callback();
        }
    };

    $scope.amendAndSign = function () {
        DocumentModificationActions.amendAndSign($routeParams.documentId, $scope.mainDocumentForm, $scope._shared.customValidator, checkRemovedItems);
    };

    $scope._spinner_loading = false;

    window.__log = function(){
        DocumentModificationActions.__log($scope.mainDocumentForm);
    };

    $scope.docSpinnerHelper = DocumentSpinnerHelper;

    var modalCancelResources = $modal({
        scope: $scope,
        title: $filter('translate')('mdl.add.rea.title'),
        contentTemplate: '/templates/portal/doctor/patient/documents/modal/modal_cancel_resources.html'
    });

         $scope.addReason = function (callback) {
        $scope._modalCancelCallback = function () {
            modalCancelResources.hide();
            callback();
        };
        modalCancelResources.$promise.then(function () {
            modalCancelResources.show();
        });
    }
}]);
