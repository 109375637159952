import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { Form090Model } from "src/app/shared/models/090.model";
import { FilteredDataModel } from "src/app/shared/models/filtered-data.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2F090 extends ConfigService {

    path = this.apiUrl + '/f090';

    getCancerList(query?: any): Observable<any[] | FilteredDataModel> {
        let params = new HttpParams();

        // // params = params.append('ftq', query);
        // // params = params.append('page', String(page));
        //console.log("query",query);
        if(query.quick_search){
            //console.log("bbbbb");
            params = params.append('ftq', query.quick_search);
            params = params.append('page', String(1)); // 1 = page
            return this.http.get<FilteredDataModel>(this.path + '/list', {params});
        } else if (query.dateFrom || query.dateTo || query.recipient || query.specialist || query.unsorted_document_type){
            //console.log("ccccc");
            params = params.append('dateFrom', Date.parse(query.dateFrom) ||"");
            params = params.append('dateTo', Date.parse(query.dateTo) ||"");
            params = params.append('recipient', query.recipient ||"");
            params = params.append('specialist', query.specialist ||"");
            //params = params.append('unsorted_document_type', query.unsorted_document_type ||"");
            params = params.append('page', String(1)); // 1 = page
            return this.http.get<FilteredDataModel>(this.path + '/list', {params});
        }
        //console.log("ddddd");
        return this.http.get<any[]>(this.path + '/list');
    }

    getCancerDiagnosisCircumstances(): Observable<any[]> {
        const url = `${this.path}/cancer-diagnosis-circumstances`;
        return this.http.get<any[]>(url);
    }

    getClinicalT(): Observable<any[]> {
        const url = `${this.path}/clinicalT`;
        return this.http.get<any[]>(url);
    }

    getClinicalN(): Observable<any[]> {
        const url = `${this.path}/clinicalN`;
        return this.http.get<any[]>(url);
    }

    getClinicalM(): Observable<any[]> {
        const url = `${this.path}/clinicalM`;
        return this.http.get<any[]>(url);
    }

    getPathologicalT(): Observable<any[]> {
        const url = `${this.path}/pathologicalT`;
        return this.http.get<any[]>(url);
    }

    getPathologicalN(): Observable<any[]> {
        const url = `${this.path}/pathologicalN`;
        return this.http.get<any[]>(url);
    }

    getPathologicalM(): Observable<any[]> {
        const url = `${this.path}/pathologicalM`;
        return this.http.get<any[]>(url);
    }

    getTumorStages(): Observable<any[]> {
        const url = `${this.path}/tumor-stages`;
        return this.http.get<any[]>(url);
    }

    getConfirmationMethod(): Observable<any[]> {
        const url = `${this.path}/confirmation-method`;
        return this.http.get<any[]>(url);
    }

    getf090FormView(id: string): Observable<Form090Model> {
        return this.http.get<Form090Model>(this.path + '/view/' + id);
    }

    submitf090Form(payload: Form090Model, id: string): Observable<Form090Model> {
        return this.http.post<Form090Model>(this.path + '/create/' + id, payload);
    }

    savef090Form(payload: Form090Model, id: string): Observable<Form090Model> {
        return this.http.post<Form090Model>(this.path + '/save/' + id, payload);
    }

    signf090Form(payload: Form090Model, id: string): Observable<Form090Model> {
        return this.http.post<Form090Model>(this.path + '/sign/' + id, payload);
    }

    signingSubmitf090Form(payload: Form090Model, id: string): Observable<Form090Model> {
        return this.http.post<Form090Model>(this.path + '/signingSubmit/' + id, payload);
    }

    sealf090Form(payload: Form090Model, id: string): Observable<Form090Model> {
        return this.http.post<Form090Model>(this.path + '/seal/' + id, payload);
    }
}