<nhr-record-widget
    [closed]="false"
    [title]="'Papildoma informacija' | translate"
    class="h-auto"
    bodyClass="form-horizontal"
>
    <div class="card-body">
        <div class="row mb-3">
            <div class="col-md-4 col-lg-3">
                <strong class="col-form-label pt-0">
                    {{'inr.addInfoForm.addInfo' | translate}}
                </strong>
            </div>
            <div class="col-md-8 col-lg-9">
                <app-input
                    inputGroupClass="mt-auto"
                    type="textarea"
                    textAreaRows="3"
                    class="w-100"
                    [fControl]="formGroup"
                ></app-input>
            </div>
        </div>
    </div>
    <div *ngIf="editable" class="card-footer mt-3 d-flex justify-content-end">
        <button class="btn btn-primary">{{'continue' | translate}}</button>
    </div>
</nhr-record-widget>