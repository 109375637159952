<form [formGroup]="form">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
                <strong class="form-label">{{'Pacientas' | translate}}</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="patient" formControlName="patient" typeaheadOptionField="patient"
                            [typeahead]="patientSuggestions$" [typeaheadAsync]="true"
                            (typeaheadOnSelect)="typeaheadOnSelectPatient($event)"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos P pavardę" class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
                <strong class="form-label">{{'Specialistas' | translate}}</strong>
                <div class="w-100">
                    <div class="input-group">
                        <input id="practitioner" formControlName="practitioner"
                            typeaheadOptionField="practitioner"
                            [typeahead]="practitionerSuggestions$" [typeaheadAsync]="true"
                            (typeaheadNoResults)="typeaheadNoResults($event)"
                            placeholder="Įveskite įstaigos Specialisto pavardę"
                            class="form-control">
                        <i class="input-group-text fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
                <strong class="form-label">{{'Būsena' | translate}}</strong>
                <div class="w-100">
                    <select id="category" class="form-select" formControlName="visitState">
                        <option [ngValue]="null" selected>--Pasirinkti--</option>
                        <option value="1" selected>{{'Suplanuotas' | translate}}</option>
                        <option value="2" selected>{{'Įvykęs' | translate}}</option>
                        <option value="3" selected>{{'Atšauktas' | translate}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="form-group">
                <strong class="form-label">{{'Data nuo' | translate}}</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateForm" [fControl]="form?.get('dateFrom')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="form-group">
                <strong class="form-label">{{'Data iki' | translate}}</strong>
                <div class="w-100">
                    <app-date-picker inputId="DateTo" [fControl]="form?.get('dateTo')">
                    </app-date-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 form-group">
            <strong class="form-label">{{'Procedūros' | translate}}</strong>
            <div class="w-100">
                <app-multiselect-dropdown placeholder="Pasirinkite"
                    [dropdownDataList]="procedureOpts" idField="id" textField="name"
                    elementId="procedures" itemsShowLimit="100"
                    [fControl]="form.get('procedures')" />
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-1 form-group">
            <strong class="form-label">{{'Skubūs' | translate}}</strong>
            <div class="w-100">
                <app-checkbox inputId="{{'urgent'}}" [fControl]="form.get('urgent')"></app-checkbox>
            </div>
        </div>
    </div>
</form>
<div class="d-flex justify-content-end gap-2">
    <button class="btn btn-primary" data-bs-dismiss="modal" (click)="searchAdvanced()">{{'search' |
        translate}}</button>
    <button class="btn btn-secondary" (click)="clear()">{{ 'clean' | translate }}</button>
</div>
<div class="modal fade" id="search" tabindex="-1" aria-labelledby="searchLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title fs-5" id="dpp.rea.res.mod.fil">{{'dp.rec.mod.fil' | translate}}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                </div>

                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</div>