ng1App.controller('doctor.user.medvais.ImagingStudyEditCtrl', 
    ['$scope', 'SessionApiFactory', 'DpSessionContext', 'MedvaisPaths', '$routeParams',
     function ($scope, SessionApiFactory, DpSessionContext, MedvaisPaths, $routeParams) {
    $scope.medvaisEditDoc027vaOptions = {

        getPractitioner: function(){
            return DpSessionContext.practitioner.$promise;
        },
        getOrganization: function(){
            return DpSessionContext.organization.$promise;
        },
        // return route to imaging study list of Doctor (User) portal
        getReturnImagingStudyListUrl: MedvaisPaths.list,

        // redirect to e027va document sign page
        redirectToSignPage: MedvaisPaths.sign,

        // return url to document
        getEdit027vaDocUrl: function (documentId) {
            return MedvaisPaths.edit(documentId);
        },
        // user info
        getUserInfo: function(){
            return $scope.token;
        },
        // to get active qualification
        getAccountSettings: function () {
            return DpSessionContext.getSettings();
        }
    }
}]);


