ng1App.service('CertificateCreation', 
    ['$modal', '$rootScope', 'DpPatientContext', '$location', '$filter', 'PractitionersApiFactory', 'PatientsApiFactory', 'SessionContext',
     function ($modal, $rootScope, DpPatientContext, $location, $filter, PractitionersApiFactory, PatientsApiFactory, SessionContext) {
	this.createWithConsultationSelect = function (type, additionalParams) {
		var scope = $rootScope.$new();
        scope.practitioners = [];
		PractitionersApiFactory.find({
                organization: SessionContext.organization.id,
                _excludeCurrentUser : true
        }, function(res){scope.practitioners = res.items});
		
        scope.finalCertificateAuthor = null;
        scope.finalAuthorSameAsCurrent = true;
		scope.patient = PatientsApiFactory.get({
                id: DpPatientContext.id
            });
		var modal = $modal({
            scope: scope,
            title: $filter('translate')('mdl.spe.sel'),/*'Išvadą teikiantis specialistas',*/
            contentTemplate: '/templates/portal/doctor/patient/certificates/modals/new_certificate.html'
        });
        
        scope.submitTasks = function () {
        	if (!scope.finalAuthorSameAsCurrent && (!scope.finalCertificateAuthor || !scope.finalCertificateAuthor.id)) {
        		scope.createDialogErr = {msg : 'Būtina nurodyti ištyrimą atliekantį specialistą'};
        		return;
        	}
            modal.hide();

            var searchParams: any = {};
            _.each(additionalParams, function (value, key) {
            	searchParams[key] = value;
            });
            if ((type === 'e048' || type === 'e047' || type === 'e049' || type === 'e083_1') && scope.finalCertificateAuthor) {
                searchParams.finalCertificateAuthor = scope.finalCertificateAuthor.id;
                searchParams.qualification = scope.finalCertificateAuthorQualification.code;
            }
           
            if (type === 'e047' || type === 'e048') {
            	$location.path('/dp/patients/' + DpPatientContext.id + '/certificates/' + type + '_practitioner/new').search(searchParams);
            } else {
            	$location.path('/dp/patients/' + DpPatientContext.id + '/certificates/' + type + '/new').search(searchParams);
            }
        };
        scope.finalCertificateAuthorChange = function (practitioner, qualification) {
            scope.finalCertificateAuthor = practitioner;
            scope.finalCertificateAuthorQualification = qualification;
        };
        scope.finalAuthorSameAsCurrentChange = function (value) {
        	scope.finalAuthorSameAsCurrent = value;
            scope.finalCertificateAuthor = null;
        };
        scope.searchPractitionersChange = function (value) {
             scope.practitioners = [];
             PractitionersApiFactory.find({
                search: value,
                organization: SessionContext.organization.id,
                _excludeCurrentUser : true
             }, function(res){
                 scope.practitioners = res.items;
             });
        };

        return modal;
    };
}]);