import {ConfigService} from "../app/config/config.service";
import {Observable} from "rxjs";
import {DocRefConsentModel} from "../app/shared/models/doc-ref-consent.model";
import {Injectable} from "@angular/core";
import {FilteredDataModel} from "../app/shared/models/filtered-data.model";
import {QueryModel} from "../app/shared/models/query.model";
import {FilterGroupModel} from "../app/shared/models/filter-group.model";

@Injectable({
    providedIn: 'root',
})
export class ApiV2DocRefConsent extends ConfigService {

    path = this.apiUrl + '/doc-ref-consent';

    getDocRefConsentList(query?: QueryModel): Observable<DocRefConsentModel[] | FilteredDataModel> {
        if (query) {
            return this.http
                .get<FilteredDataModel>(this.path + '/list', {params: this.getFilterParams(query)});
        }
        return this.http
            .get<DocRefConsentModel[]>(this.path + '/list');
    }

    getDocRefConsentView(id: string): Observable<DocRefConsentModel> {
        return this.http
            .get<DocRefConsentModel>(this.path + '/view/' + id);
    }

    createDocRefConsent(docRefConsent: DocRefConsentModel): Observable<DocRefConsentModel> {
        return this.http.post<DocRefConsentModel>(this.path + '/create', docRefConsent);
    }

    getFilters(): Observable<FilterGroupModel[]> {
        return this.http.get<FilterGroupModel[]>(this.path + '/filters');
    }

    getInitiators(params?: any): Observable<any[]> {
        return this.http.get<any[]>(this.path + '/initiators', {params: params});
    }

    getProcedures(params?: any): Observable<any[]> {
        return this.http.get<any[]>(this.path + '/procedures', {params: params});
    }

    changeDocRefConsentStatus(id: string, docStatus: string): Observable<DocRefConsentModel> {
        return this.http.post<DocRefConsentModel>(this.path + '/changeDocStatus/' + id, {status: docStatus});
    }
}