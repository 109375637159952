import {Component, Input, OnInit} from '@angular/core';
import {RouteParams} from '../../../../../ng1.routeParams';
import {E030GeneralDocumentData} from '../e030-forms/e030-general-data/e030-general-data.component';
import {Router} from "@angular/router";
import {DocumentTagEnum} from "../../../../../shared/models/document-tag.enum";
import {DocumentStatusTranslationsEnum} from "../../../../../shared/models/document-status-translations.enum";

@Component({
    selector: 'app-e030-additional',
    templateUrl: './e030-additional.component.html'
})
export class E030AdditionalComponent implements OnInit {
    @Input() editable: boolean = true;
    @Input() documentData: E030GeneralDocumentData | undefined;
    @Input() fromDoc: string;
    pathToDocument: string;
    patientId: string;
    encounterId: string;

    constructor(private params: RouteParams, private router: Router) {

    }

    ngOnInit(): void {
        this.patientId = this.params.patientId;
        this.encounterId = this.params.encounterId;
        this.pathToDocument = `/dp/patients/${this.patientId}/${DocumentTagEnum.e030}`;
    }

    redirectToAttachmentPage(view?: boolean) {
        const path = view ? `${this.pathToDocument}/view` : this.pathToDocument;
        this.router.navigate([`${path}`], {
            queryParams: {
                encounterId: this.encounterId,
                fromDoc: this.fromDoc
            }
        })
    }

    getDocStatus(docStatus: string) {
        return DocumentStatusTranslationsEnum[docStatus];
    }
}
