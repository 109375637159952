angular.module('erx.utils').directive('erxIsObject', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attr, ngModel) {

            ngModel.$parsers.unshift(function (value) {
                var valid = angular.isObject(value);
                ngModel.$setValidity('object', valid);
                return value;
            });

            //For model -> DOM validation
            ngModel.$formatters.unshift(function (value) {
                ngModel.$setValidity('object', angular.isObject(value));
                return value;
            });
        }
    };
});

angular.module('erx.utils').directive('erxTwoDecimalPoints', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
            scope.$watch(attrs.ngModel, function (v) {
                try {
                    if (element.val().split('.')[1].length > 2) {
                        var currentValue = Number(element.val());

                        var value = Math.floor(currentValue * 100) / 100;
                        ctrl.$setViewValue(value);
                        ctrl.$render();
                    }
                } catch (e) {
                }
            });
        }
    };
});

angular.module('erx.utils').directive('erxInteger', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
            scope.$watch(attrs.ngModel, function (v) {
                try {
                    if (element.val().indexOf('.') != -1) {
                        var currentValue = Number(element.val());
                        var value = Math.floor(currentValue);
                        ctrl.$setViewValue(value);
                        ctrl.$render();
                    }
                } catch (e) {
                }
            });
        }
    };
});