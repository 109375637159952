ng1App.factory('DpSessionContext', 
    ['SessionApiFactory', 'AccountSettingsApiFactory',
     function (SessionApiFactory, AccountSettingsApiFactory) {
    
    var settings;
    return {
        reloadSettings: function () {
            settings = AccountSettingsApiFactory.get();
        },
        practitioner: SessionApiFactory.getPractitioner({}, function (d) {
            d.fullName = _.getFullName(d);
        }),
        organization: SessionApiFactory.getOrganization({}),
        getSettings:function(callback){
            if(!settings){
              settings =  AccountSettingsApiFactory.get();
            }
            if(callback){
                settings.$promise.then(callback);
            }
            
            return settings;
        }
    };
}]);

ng1App.controller('doctor.user.MainCtrl', 
    ['$scope', 'SessionApiFactory', 'DpSessionContext',
     function ($scope, SessionApiFactory, DpSessionContext) {
    DpSessionContext.practitioner = SessionApiFactory.getPractitioner({}, function (d) {
        d.fullName = _.getFullName(d);
    });

    DpSessionContext.organization = SessionApiFactory.getOrganization({});

    $scope.sessionCtx = DpSessionContext;
}]);