<nhr-record-widget
    [closed]="false"
    [title]="'doc.pregnancyDashboard.e096N.formGeneral.title' | translate"
    class="h-auto"
>
    <div class="card-body">
        <div class="row req">
            <div class="form-group col-4 col-lg-3 flex-shrink-1 d-flex flex-column">
                <app-input
                    labelText="{{'doc.pregnancyDashboard.e096Nt.formCondition.fruitsNumber' | translate}}:"
                    type="number"
                    labelClass="col-form-label"
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('numberOfFruits')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('numberOfFruits')" />
            </div>
            <div class="col-4 col-lg-3">
                <span class="form-label flex-grow-1 col-form-label mt-3 mb-2 fw-bold">
                            {{'doc.pregnancyDashboard.e096N.formGeneral.placeOfBirth' | translate}}
                        </span>
                <div class="input-group">
                    <app-radio-button
                        *ngFor="let option of complaintsOptions; let i = index"
                        radioName="placeOfBirth-{{i}}"
                        [fControl]="formGroup.get('placeOfBirth')"
                        [radioValue]="option.name"
                        id="placeOfBirth-{{i}}"
                        class="col-6 mb-1"
                        formClass="mb-0"
                        [labelText]="option.name"
                    ></app-radio-button>
                    <app-form-control-validation [fControl]="formGroup.get('placeOfBirth')" />
                </div>
            </div>
            <div
                *ngIf="getControlValue('placeOfBirth') === 'Kitur'"
                class="form-group col-4 col-lg-3 flex-shrink-1 d-flex flex-column justify-content-center"
            >
                <app-input
                    inputGroupClass="mt-auto"
                    [fControl]="formGroup.get('elsewhere')"
                ></app-input>
                <app-form-control-validation [fControl]="formGroup.get('elsewhere')" [style.height]="0" />
            </div>
        </div>
    </div>
</nhr-record-widget>