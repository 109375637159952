ng1App.factory('CreatePaperEncounterOrderModal', 
    ['$modal', '$rootScope', 'TypeaheadSearchHandlers', 'EncountersApiFactory',
     function ($modal, $rootScope, TypeaheadSearchHandlers, EncountersApiFactory) {
    var modalInstance;

    function showModal(encounterId, callback) {
        if (!modalInstance) {
            modalInstance = $modal({
                title: 'Sistemoje neregistruoto konsultacijos, tyrimų, gydymo siuntimas',
                contentTemplate: '/templates/portal/doctor/patient/documents/modal/create_encounter_order.html'
            });
        }
        modalInstance.$promise.then(function () {
            initModal(modalInstance.$scope, encounterId, callback);
            modalInstance.show();
        });
    }

    function initModal(scope, encounterId, callback) {
        scope.loading = false;
        scope.form = {
            encounterId: encounterId
        };
        scope.searchOrganizations = TypeaheadSearchHandlers.ORGANIZATIONS;
        scope.searchPaperOrderOrganizationsChange = function (v) {
            if (v && v.id) {
                scope.form.paperOrderOrganizationRef = v.fullId;
                scope.form.paperOrderOrganization = v;
            }
        };
        scope.searchDiagnosis = TypeaheadSearchHandlers.ICD10;
        scope.searchPaperOrderDiagnosisChange = function (v) {
            if (v && v.code) {
                scope.form.paperOrderDiagnosis = v;
            }
        };
        scope.submit = function () {
            var htmlForm = scope.createPaperOrderEncounterForm;
            if (htmlForm.$valid) {
                createOrder().then(function (encounterData) {
                    encounterData.paperOrderOrganization = scope.form.paperOrderOrganization;
                    callback(encounterData);
                    modalInstance.hide();
                });
            } else {
                angular.forEach(htmlForm, function (field) {
                    if (field.$setViewValue) {
                        field.$setViewValue(field.$viewValue);
                    }
                });
            }

        };
        function createOrder() {
            scope.loading = true;
            var payload = {
                encounterId: scope.form.encounterId,
                paperOrderOrganizationRef: scope.form.paperOrderOrganizationRef,
                paperOrderDiagnosis: scope.form.paperOrderDiagnosis
            };
            return EncountersApiFactory.changeOrderToPaper(payload).$promise
                .finally(function () {
                    scope.loading = false;
                });
        }
        return scope;
    }

    return {
        show: showModal
    };
}]);

