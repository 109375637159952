import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { uniqueId } from 'underscore';

@Component({
  selector: 'app-double-input',
  templateUrl: './double-input.component.html',
})
export class DoubleInputComponent {
  @Input() id: string = uniqueId();
  @Input({required: true}) firstControl: FormControl;
  @Input({required: true}) secondControl: FormControl;
  @Input() labelText: string;
  @Input() labelClass: string;
  @Input() inputClass: string;
  @Input() type: string;
  @Input() readonly: boolean;
  @Input() viewMode: boolean;
  @Input() units: string;
  @Input() unitsClass: string;
  @Input() value: string;
  @Input() min: string;
  @Input() max: string;
  @Input() errorClass: string;
  @Input() inputGroupClass: string;
  @Input() pattern: string;
  @Input() numberStep: string;
  @Input() digitsInfo: string;
  @Input() placeholder: string = '';
  @Input() textAreaRows: number = 4;
  @Input() suffixIcon: string;
  @Input() listenEnterPress: boolean;
  @Input() textSeparator: string = '/';

  @Output() enterKeyPressed: EventEmitter<void> = new EventEmitter<void>();

  public val: string;
  public error: string;

  formatValue(fControl: FormControl) {
    if (this.digitsInfo) {
      const number = fControl.value.split('.')[0];
      let numberToTransform: string;

      if (number.length > 3) {
        numberToTransform = number.substring(0, 3) + '.' + number.substring(3);
      } else {
        numberToTransform = fControl.value;
      }

      fControl.setValue(this.decimalPipe.transform(Number(numberToTransform), this.digitsInfo));
    }

    if (fControl.value) {
      this.error = null;

      if (Number(fControl.value) > Number(this.max)) {
        this.error = `Reikšmė turi būti mežesnė nei ${this.max}`;
      }

      if (Number(fControl.value) < Number(this.min)) {
        this.error = `Reikšmė turi būti didesnė nei ${this.min}`;
      }
    }
    this.cdr.detectChanges();
  }

  onEnterKey(event: KeyboardEvent): void {
    event.preventDefault();
    this.enterKeyPressed.emit();
  }

  constructor(private decimalPipe: DecimalPipe, private cdr: ChangeDetectorRef) {}
}
