ng1App.factory("ERXRulesApiFactory", [ '$resource', 'apiUrl', function ($resource, apiUrl) {
    var URL_BASE = apiUrl + '/erxRules';

    return $resource('', {}, {
        enable: {
            method: 'POST',
            url: URL_BASE + '/:code/enable'
        },
        disable: {
            method: 'POST',
            url: URL_BASE + '/:code/disable'
        },
        search: {
            method: 'GET',
            url: URL_BASE + '/',
            isArray: false
        }
    });
}]);