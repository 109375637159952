import { Component, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BsModalService } from "ngx-bootstrap/modal";
import { map, Observable, Observer, of, switchMap, take } from "rxjs";
import { RouteParams } from "../../../../ng1.routeParams";
import { Pagination } from "../../../../shared/components/list/pagination-bar/pagination-bar.constants";
import { FilterOptionsInterface } from "../../../../shared/components/page-filters/page-filters.component";
import { ApiV2Entities } from "../../../../shared/services/api-v2-entities";
import { ApiV2Risks } from "../../../../shared/services/api-v2-risks";
import { RiskFactorModel } from "../../../models/risk-factor.model";
import { EditHealthFactorModalComponent } from "../assessment-of-health-factors-form/edit-health-factor-modal/edit-health-factor-modal.component";
import { FilteredDataModel } from "../../../../shared/models/filtered-data.model";
import { ApiV2Practitioners } from "../../../../../api/api-v2-practitioners";
import { PatientModel } from "../../../../shared/models/patient.model";
import { PractitionerModel } from "../../../../shared/models/practitioner.model";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
    selector: "app-assessment-of-health-factors-page",
    templateUrl: "./assessment-of-health-factors-page.component.html",
})
export class AssessmentOfHealthFactorsPageComponent implements OnInit {
    risksHistory: RiskFactorModel[] = [];
    risksFullHistory: RiskFactorModel[] = [];
    backUrl: string;
    filterOptions: FilterOptionsInterface[] = [];
    private lastFilters: { [key: string]: any } = {};
    private patientId: string;
    practitionerSuggestions$: Observable<any[]>;
    filterForm: FormGroup;

    count: number;
    pagesCount: number;
    page: number = 1;

    constructor(
        private formBuilder: FormBuilder,
        private params: RouteParams,
        private apiV2Risks: ApiV2Risks,
        private apiV2Entities: ApiV2Entities,
        private apiV2Practitioners: ApiV2Practitioners,
        private modalService: BsModalService
    ) {
        this.filterForm = this.formBuilder.group({
            practitionierQuery: [""],
        });

        this.apiV2Risks
            .getRisksList({ patient: this.params.patientId })
            .pipe(takeUntilDestroyed())
            .subscribe((res) => {
                this.risksHistory = res?.items;
                this.risksFullHistory = res?.items;
            });
        this.apiV2Entities
            .getEntitiesList("health-risk-factor")
            .pipe(takeUntilDestroyed())
            .subscribe((res) => {
                this.filterOptions = [
                    {
                        title: "pp.dia.sea.dat",
                        type: "date-range",
                    },
                    {
                        title: "doc.healthAssessment.healthRiskFactors",
                        formControlName: "riskFactors",
                        type: "multi-select",
                        placeholder: "dpp.all.filt.plc",
                        multiSelectOptions: res,
                    },
                    {
                        title: "doc.healthAssessment.assessmentScore",
                        formControlName: "assessmentScore",
                        placeholder: "doc.healthAssessment.score",
                        type: "input",
                        format: "number",
                    },
                    {
                        title: "dpp.cer.mod.spe",
                        type: "typeahead",
                        formControlName: "practitioner",
                        typeaheadSettings: {
                            suggestionsService: this.practitionerSuggestions$,
                            typeaheadformControl: this.filterForm.get("practitionierQuery"),
                            inputTextItem: "inputText",
                        },
                        placeholder: "dpp.pat.dra.sea.fill",
                    },
                ];
            });
    }

    async ngOnInit(): Promise<void> {
        this.patientId = this.params.patientId;
        this.backUrl = "/dp/patients/" + this.patientId;
        this.initializePractitionerSuggestions();
    }

    updateFilters(filters: { [key: string]: any }) {
        if (!filters) {
            return;
        }
        this.filterList(filters);
    }

    filterList(filters: { [key: string]: any }): void {
        const equal = _.isEqual(filters, this.lastFilters);
        if (equal) {
            return;
        }
        if (_.isEmpty(filters)) {
            this.risksHistory = [...this.risksFullHistory];
        }

        this.apiV2Risks
            .getRisksList({ patient: this.patientId, ...filters })
            .pipe(take(1))
            .subscribe((res) => {
                this.lastFilters = filters;

                this.risksHistory = [];

                const rowNumberToLoad = (this.page - 1) * 10;
                const repeating = rowNumberToLoad + 10;
                const repeatingTill = repeating > res.total ? res.total : repeating;

                for (let i = rowNumberToLoad; i < repeatingTill; i++) {
                    this.risksHistory.push(res?.items[i]);
                }

                this.count = res.total;

                const pagesCount = res.total / 10;
                this.pagesCount = Math.ceil(pagesCount);
            });
    }

    openEditHealthFactorModal(i: number) {
        const initialState = {
            risk: this.risksHistory[i],
            isEditMode: true,
        };

        const ref = this.modalService.show(EditHealthFactorModalComponent, {
            initialState,
            animated: true,
            class: "modal-xxl",
        });
        ref.content!.onClose.pipe(take(1)).subscribe((result: RiskFactorModel | void) => {
            if (!result) {
                return;
            }
            this.risksHistory[i] = { ...this.risksHistory[i], ...result };
        });
    }

    public updatePagination(pagination: Pagination) {
        if (!pagination) {
            return;
        }

        this.page = pagination.page;

        const filter = { ...this.lastFilters, page: pagination.page };

        this.filterList(filter);
    }

    initializePractitionerSuggestions() {
        this.practitionerSuggestions$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.filterForm.get("practitionierQuery").value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.apiV2Practitioners
                        .find({ q: query })
                        .pipe(
                            map(
                                (data: FilteredDataModel) =>
                                    (data && data.items.map((psm) => this.getPersonCredentials(psm))) || []
                            )
                        );
                }
                return of([]);
            })
        );
    }

    getPersonCredentials(person: PatientModel | PractitionerModel) {
        return (
            (person.namePrefix ? person.namePrefix + " " : "") +
            person.givenName +
            " " +
            person.familyName +
            " " +
            person.personalCode
        );
    }
}
